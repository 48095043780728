import React, { useState, useEffect} from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deployDialog, closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { getFormDataHook } from 'Functions/FormFunctions';

const UploadImage = ({callback}) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        link: '',
        image: null
    });

    const handleSubmit = () => {
        API.post(`/marketing/website/banners`, getFormDataHook(formData))
        .then(res => {
            if(res?.data?.success) {
                callback?.();
                dispatch(deploySnackBar(`success`, `The image has been successfully uploaded`))
                dispatch(closeDialog())
            }
        })
    }
    
    return (
        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DragFileInput
                        id="banner"
                        name="banner"
                        label="Choose Image (.png, .jpg, .jpeg) *"
                        icon="file-image"
                        file={formData.image}
                        onChange={(drop, name, file) => setFormData({...formData, image: file})}
                        emptyText='No file selected'
                        small
                    />
                    <Typography variant='body2' style={{marginBottom: 10}}>
                        <FAIcon icon="exclamation-circle" size={12.5} />
                        <strong>Recommended image resolution: 3840px x 640px</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="link"
                        label="Link Path"
                        value={formData.link}
                        onChange={e => setFormData({...formData, link: e.target.value})}
                        margin="none"
                        placeholder="/shop/path/to/link"
                        fullWidth
                    />
                    <Typography variant='body2' style={{marginBottom: 10}}>
                        <FAIcon icon="exclamation-circle" size={12.5} />
                        <strong>The link path should not include https://www.advanced-access.co.uk</strong>
                    </Typography>
                </Grid>
            </Grid>
            <div className='buttonRow'>
                <Button 
                    type='submit'
                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to upload this image?`, handleSubmit))}
                    variant="text" 
                    color="primary" 
                    disabled={!formData.image}
                >
                    <FAIcon icon="check" size={15} button />
                    Upload
                </Button>
            </div>
        </form>
    )
}

const UpdateLink = ({callback, id, currentValue}) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        link: currentValue ?? ''
    });

    const handleSubmit = () => {
        API.put(`/marketing/website/banners/${id}`, formData)
        .then(res => {
            if(res?.data?.success) {
                callback?.();
                dispatch(deploySnackBar(`success`, `The image link has been successfully updated`))
                dispatch(closeDialog())
            }
        })
    }
    
    return (
        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        name="link"
                        label="Link Path"
                        value={formData.link}
                        onChange={e => setFormData({...formData, link: e.target.value})}
                        margin="none"
                        placeholder="/shop/path/to/link"
                        fullWidth
                    />
                    <Typography variant='body2' style={{marginBottom: 10}}>
                        <FAIcon icon="exclamation-circle" size={12.5} />
                        <strong>The link path should not include https://www.advanced-access.co.uk</strong>
                    </Typography>
                </Grid>
            </Grid>
            <div className='buttonRow'>
                <Button 
                    type='submit'
                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to update the link?`, handleSubmit))}
                    variant="text" 
                    color="primary" 
                >
                    <FAIcon icon="check" size={15} button />
                    Update
                </Button>
            </div>
        </form>
    )
}

const BannerImages = () => {

    const [isLoading, setLoading]           = useState(true);
    const [images, setImages]               = useState([]);
    const [initialImages, setInitialImages] = useState([]);

    const dispatch = useDispatch();

    const loadComponentData = () => {
        !isLoading && setLoading(true);
        API.get(`/marketing/website/banners`)
        .then(res => {
            if(res?.data) {
                setImages(res.data);
                setInitialImages(res.data);
                setLoading(false);
            }
        })
    }

    const handleAddImage = () => {
        dispatch(deployDialog(
            <UploadImage callback={loadComponentData} />,
            false,
            "Upload Image",
            "standard",
            "sm"
        ))
    }

    const handleUpdateLink = (id, currentValue) => {
        dispatch(deployDialog(
            <UpdateLink callback={loadComponentData} id={id} currentValue={currentValue} />,
            false,
            "Update Image Link",
            "standard",
            "sm"
        ))
    }

    const handleRemoveImage = idx => {
        const newImages = Array.from(images);
        newImages.splice(idx, 1);
        setImages(newImages);
    };

    const handleSubmit = () => {
        setLoading(true);
        API.post(`/marketing/website/banners/order`, 
            {
                images: images.map((image) => image.hero_id)
            }
        )
        .then(res => {
            if(res?.data) {
                if(res?.data?.success) {
                    dispatch(deploySnackBar(`success`, `The website homepage images have been successfully updated`))
                } else {
                    dispatch(deploySnackBar(`error`, `There was an issue updating the homepage images`))
                }
                loadComponentData();
            }
        })
    }

    const disabled = JSON.stringify(images) === JSON.stringify(initialImages);

    /*
     * Drag and drop functionality
     */
    const onDragEnd = result => {

        if (!result.destination) {
          return;
        }

        const newImages = reorderDraggedItems(images, result.source.index, result.destination.index);

        setImages(newImages);

    }

    const getDragContainerStyle = isDraggingOver => ({
        backgroundColor: isDraggingOver ? '#ef3340' : undefined,
        display: 'flex',
        overflow: 'auto',
    });

    const getDragItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        ...draggableStyle
    });

    const reorderDraggedItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(() => {
        loadComponentData();/* eslint-disable-next-line */
    }, [])

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="vertical">
                        {(provided, snapshot) => (
                            <Grid
                                container
                                spacing={3}
                                ref={provided.innerRef}
                                style={getDragContainerStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {_.map(images, (hero, idx) => (
                                    <Draggable key={`hpi-${idx}`} draggableId={`hpi-${idx}`} index={idx}>
                                        {(provided, snapshot) => (
                                            <Grid 
                                                item 
                                                xs={12}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getDragItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <Card>
                                                    <CardContent>
                                                        <Grid container alignItems='center' spacing={1}>
                                                            <Grid item xs>
                                                                <Typography variant="body2">
                                                                    {hero.hero_link ? `Linked to: ${hero.hero_link}` : `Not Linked`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <Tooltip title='Modify Link'>
                                                                            <IconButton
                                                                                onClick={() => handleUpdateLink(hero.hero_id, hero.hero_link)}
                                                                            >
                                                                                <FAIcon icon="pencil-alt" size={15} button noMargin />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Tooltip title='Remove Image'>
                                                                            <IconButton
                                                                                onClick={() => handleRemoveImage(idx)}
                                                                                disabled={images.length === 1}
                                                                            >
                                                                                <FAIcon icon="trash-alt" size={15} button noMargin />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={hero.hero_img}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: 'auto',
                                                                        margin: '0 auto'
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
                <Box className="buttonRow">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddImage}
                    >
                        <FAIcon icon='plus-circle' size={15} buttonPrimary />
                        Upload Image
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to update the homepage images?`, handleSubmit))}
                    >
                        <FAIcon icon='check' size={15} disabled={disabled} buttonPrimary />
                        Update Homepage Banners
                    </Button>
                </Box>
            </>
        )
    )
}

export default BannerImages;