import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import Alert from "Components/Common/Alert/Alert";
import API from "API";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import AwaitingSubmission from "Components/Purchases/Actions/AwaitingSubmission";
import DataTable from "Components/Common/DataTables/DataTable";
import DatePicker from "Components/Common/DatePickers/DatePicker";
// import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import Highlight from "Components/Common/Highlight/Highlight";
import InsightChip from "Components/Common/Chips/InsightChip";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import logo from "Assets/Images/no-img.jpg";
import OrderSummary from "Components/Sales/Misc/OrderSummary";
import Textarea from "Components/Common/Inputs/Textarea";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import { handleChange, handleSelectChange } from "Functions/FormFunctions";
import { cloneObject, currencyFormat, getLeadTime, isEven, isOdd, momentFormatDate } from "Functions/MiscFunctions";
import { AA_ADDRESS_OBJECT, CLOUDFRONT_URL } from "Constants";
import ViewProduct from "Components/Products/ViewProduct/ViewProduct";
// import StatusChip from 'Components/Common/Chips/StatusChip';
import ResolveIssue from "../Misc/DetailedView/ResolveIssue";
import AddressLookupDialog from "Components/Common/AddressLookup/AddressLookupDialog";

const JoinOrderLine = ({ closeDialog, deployConfirmation, formData, fulfilment, idx, product, submit }) => {
    const [join, setJoin] = useState([]);
    const [lines, setLines] = useState([]);
    const [newQty, setNewQty] = useState(0);

    const handleCheckedChange = (lineIdx) => {
        let newJoin = [...join];

        if (join.includes(lineIdx)) {
            newJoin.splice(
                _.findIndex(newJoin, (lidx) => lidx === lineIdx),
                1
            );
        } else {
            newJoin.push(lineIdx);
        }

        setJoin(newJoin);
    };

    const handleSubmit = () => {
        let newLines = [...formData.lines],
            productLines = [],
            qtyToJoin = product.qty,
            fulfil = product.fulfil;

        lines.forEach((line) => {
            if (join.includes(line.lineIdx)) {
                qtyToJoin = qtyToJoin + line.qty;
            } else {
                productLines.push(line);
            }
        });

        if (fulfilment !== null) {
            newLines = _.filter(newLines, (el) => el.id !== product.id || (el.id === product.id && (el.fulfilment !== fulfilment || el.subTotal !== product.subTotal)));
        } else {
            newLines = _.filter(newLines, (el) => el.id !== product.id || el.subTotal !== product.subTotal);
        }

        if (fulfil === "wh") {
            if (
                qtyToJoin +
                    _.sumBy(
                        _.filter(productLines, (el) => el.fulfil === "wh"),
                        (el) => el.qty
                    ) >
                product.stockTotalAvailable
            ) {
                fulfil = "po";
            }
        }

        newLines.splice(idx, 0, {
            ...product,
            fulfil,
            qty: qtyToJoin,
            vat: (parseFloat(product.vat / product.qty) * parseInt(qtyToJoin)).toFixed(2),
        });

        _.each(productLines, (line) => {
            newLines.push(line);
        });

        _.each(newLines, (line, idx) => {
            newLines[idx].lineIdx = idx;
        });

        submit(newLines);
    };

    useEffect(() => {
        let newLines = [],
            preJoin = [],
            formLines = [];

        if (fulfilment !== null) {
            formLines = _.filter(formData.lines, (el) => el.lineStatus !== "Invoiced" && el.lineStatus !== "Despatched" && el.id === product.id && el.lineIdx !== idx && el.fulfilment === fulfilment && el.subTotal === product.subTotal);
        } else {
            formLines = _.filter(formData.lines, (el) => el.lineStatus !== "Invoiced" && el.lineStatus !== "Despatched" && el.id === product.id && el.lineIdx !== idx && el.subTotal === product.subTotal);
        }

        _.each(formLines, (el) => {
            newLines.push(el);
            if (el.delivery === product.delivery) {
                preJoin.push(el.lineIdx);
            }
        });

        setLines(newLines);
        setJoin(preJoin); //eslint-disable-next-line
    }, []);

    useEffect(() => {
        let qty = product.qty;

        lines.forEach((line) => {
            if (join.includes(line.lineIdx)) {
                qty = qty + line.qty;
            }
        });

        setNewQty(qty);
    }, [join, lines, product]);

    const cols = [];

    if (fulfilment !== null) {
        cols.push({
            heading: "Despatch with",
            field: (rowData) => `Delivery ${rowData.delivery + 1}`,
            sizeToContent: true,
        });
    } else {
        cols.push({
            heading: "Fulfilled by",
            field: (rowData) => (
                <>
                    <Typography variant="body2">{rowData.fulfil === "wh" ? "Advanced Access" : rowData.supplier}</Typography>
                    <Typography variant="caption">{rowData.fulfil === "wh" ? "Warehouse Fulfilment" : "Purchase Order"}</Typography>
                </>
            ),
            sizeToContent: true,
        });
    }

    cols.push(
        {
            heading: "Quantity",
            field: (rowData) => rowData.qty,
            alignment: "center",
            sizeToContent: true,
        },
        {
            heading: "Merge",
            field: (rowData) => <Checkbox color="primary" checked={join.includes(rowData.lineIdx)} onChange={() => handleCheckedChange(rowData.lineIdx)} />,
            aligment: "center",
            sizeToContent: true,
        }
    );

    return (
        (product && (
            <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                    <Box pt={4}>
                        <FAIcon icon="compress-arrows-alt" type="light" size={50} noMargin />
                    </Box>
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="h6">Merge Order Lines</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box ml={4} mr={4} mt={2} mb={3}>
                        <Box className="content-light-grey" p={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} align="center">
                                    <Typography variant="h6">{product.code}</Typography>
                                    <Typography variant="body2">{product.name}</Typography>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography variant="h6">{newQty}</Typography>
                                    <Typography variant="body2">New Quantity</Typography>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <DataTable
                                        config={{
                                            alternatingRowColours: true,
                                            responsiveImportance: true,
                                            noRowHover: true,
                                            inline: true,
                                        }}
                                        columns={cols}
                                        rows={lines}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} align="right">
                    <Grid container spacing={2}>
                        <Grid item xs />
                        <Grid item>
                            <Button onClick={closeDialog} variant="text">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => deployConfirmation("Are you sure you want to merge these order lines?", () => handleSubmit())} variant="text" color="primary" disabled={_.isEmpty(join)}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )) || <React.Fragment />
    );
};

const SplitOrderLine = ({ closeDialog, deployConfirmation, idx, product, submit }) => {
    const [qty, setQty] = useState("");
    return (
        (product && (
            <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                    <Box pt={4}>
                        <FAIcon icon="random" type="light" size={50} noMargin />
                    </Box>
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="h6">Split Order Line</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box ml={4} mr={4} mt={2} mb={3}>
                        <Box className="content-light-grey" p={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} align="center">
                                    <Typography variant="h6">{product.code}</Typography>
                                    <Typography variant="body2">{product.name}</Typography>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography variant="h4">{product.qty}</Typography>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <TextField
                                        inputProps={{
                                            step: 1,
                                            min: 1,
                                            max: qty - 1,
                                            style: {
                                                background: "#fff",
                                                textAlign: "center",
                                                width: 75,
                                            },
                                        }}
                                        onChange={(e) => setQty(parseInt(e?.target?.value ?? 1))}
                                        type="number"
                                        variant="outlined"
                                        value={qty}
                                        autoFocus
                                    />
                                    <Box pt={1}>
                                        <Typography variant="caption" color="textSecondary" component="div">
                                            Max. {product.qty - 1}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} align="right">
                    <Grid container spacing={2}>
                        <Grid item xs />
                        <Grid item>
                            <Button onClick={closeDialog} variant="text">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => deployConfirmation("Are you sure you want to split this order line?", () => submit(idx, qty))} variant="text" color="primary" disabled={qty === "" || qty < 1 || qty > product.qty - 1}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )) || <React.Fragment />
    );
};

/*
 * Initial State (For Processing Class)
 */
const initialState = () => ({
    addresses: {
        billing: [],
        shipping: [],
    },
    completed: {
        state: false,
        email: 0,
        po: [],
        cancelPo: [],
    },
    formData: {
        sendConfirmation: 0,
        fulfilment: [],
        lines: [],
    },
    delivery: {},
    initialFormData: {
        fulfilment: [],
        lines: [],
    },
    isLoading: true,
    origins: {},
    purchaseOrders: [],
    processingOption: null,
    reprocessing: false,
    suppliers: [],
    reviewViewAll: true,
    stockOnOrder: {},
});

class Processing extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.timeout = false;
    }

    /*
     * ComponentDidMount (Load data on mount)
     */
    componentDidMount = () => {
        this.loadComponentData(true);
    };

    /*
     * ComponentDidUpdate (Run some methods depending on tab selected)
     */
    componentDidUpdate = (prevProps) => {
        /* Generate Deliveries */
        // if(this.props.currentTab !== 2 && prevProps.currentTab === 2) {
        //     this.handleGenerateDeliveries();
        // }

        /* Check all deliveries have products */
        if (this.props.currentTab === 4 && prevProps.currentTab !== 4) {
            setTimeout(this.handleValidateReviewTab, 50);
        }

        if (this.props.currentTab === 5 && this.props.processingType !== null) {
            this.setState(
                {
                    processingOption: initialState().processingOption,
                },
                () => {
                    this.props.handleProcessingTypeChange(null);
                    this.loadComponentData();
                }
            );
        }
    };

    /*
     * ComponentWillUnmount (Clean timeout to avoid memory leak)
     */
    componentWillUnmount = () => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    };

    /*
     * LoadComponentData (Get lines / availability configuration from API on load)
     */
    loadComponentData = (initialLoad) => {
        const { order } = this.props;

        let isLoading = true;

        this.setState(
            {
                isLoading,
            },
            () => {
                API.get(`/sales/${this.props.id}/processing`).then((result) => {
                    if (result?.data) {
                        if (result?.data?.processing) {
                            this.props.deploySnackBar("error", "This order is still finishing a previous processing request, please try again shortly and if problems persist, contact Crushed Ice");
                            this.props.history.push(`/sales/${this.props.id}`);
                        } else if (result?.data?.reprocessing) {
                            let addresses = {
                                    billing: [],
                                    shipping: [],
                                },
                                delivery = order?.delivery_carriage ?? null,
                                fulfilment = result.data?.fulfilment,
                                lines = result.data?.lines,
                                origins = result.data?.origins,
                                purchaseOrders = result.data?.purchaseOrders,
                                reprocessing = true,
                                stockOnOrder = result.data?.onOrder ?? null,
                                suppliers = result.data?.suppliers;

                            isLoading = false;

                            _.each([order.order_address_line_one, order.order_address_line_two, order.order_address_line_three, order.order_address_town, order.order_address_county, order.order_address_postcode, order.order_address_country], (line) => {
                                if (line && line.length > 0) {
                                    addresses.billing.push(line);
                                }
                            });

                            _.each(
                                [
                                    order.order_delivery_address_line_one,
                                    order.order_delivery_address_line_two,
                                    order.order_delivery_address_line_three,
                                    order.order_delivery_address_town,
                                    order.order_delivery_address_county,
                                    order.order_delivery_address_postcode,
                                    order.order_delivery_address_country,
                                ],
                                (line) => {
                                    if (line && line.length > 0) {
                                        addresses.shipping.push(line);
                                    }
                                }
                            );

                            let formData = {
                                ...this.state.formData,
                                fulfilment,
                                lines,
                            };

                            let initialFormData = JSON.stringify(formData);

                            const processingOption = "MANUAL";
                            this.props.handleProcessingTypeChange("CF");

                            // if(_.isEmpty(lines) || _.isEmpty(fulfilment) || _.isEmpty(origins)) {
                            //     this.props.deploySnackBar("error", "Something went wrong, please try again");
                            //     this.props.history.push?.(`/sales/${this.props.id}`);
                            // } else {

                            this.setState(
                                {
                                    addresses,
                                    delivery,
                                    formData,
                                    initialFormData,
                                    isLoading,
                                    origins,
                                    purchaseOrders,
                                    processingOption,
                                    reprocessing,
                                    stockOnOrder,
                                    suppliers,
                                },
                                () => {
                                    this.handleGenerateDeliveries();
                                }
                            );

                            // }
                        } else {
                            let addresses = {
                                    billing: [],
                                    shipping: [],
                                },
                                lines = result?.data?.lines ?? [],
                                suppliers = result.data?.suppliers,
                                delivery = order?.delivery_carriage ?? null,
                                stockOnOrder = result.data.onOrder ?? null,
                                isLoading = false;

                            _.each([order.order_address_line_one, order.order_address_line_two, order.order_address_line_three, order.order_address_town, order.order_address_county, order.order_address_postcode, order.order_address_country], (line) => {
                                if (line && line.length > 0) {
                                    addresses.billing.push(line);
                                }
                            });

                            _.each(
                                [
                                    order.order_delivery_address_line_one,
                                    order.order_delivery_address_line_two,
                                    order.order_delivery_address_line_three,
                                    order.order_delivery_address_town,
                                    order.order_delivery_address_county,
                                    order.order_delivery_address_postcode,
                                    order.order_delivery_address_country,
                                ],
                                (line) => {
                                    if (line && line.length > 0) {
                                        addresses.shipping.push(line);
                                    }
                                }
                            );

                            if (_.isEmpty(lines)) {
                                this.props.deploySnackBar("error", "Something went wrong, please try again");
                                this.props.history.push?.(`/sales/${this.props.id}`);
                            } else {
                                _.each(lines, (line, idx) => {
                                    lines[idx].lineIdx = idx;
                                });

                                let formData = {
                                    ...this.state.formData,
                                    lines,
                                };

                                let initialFormData = JSON.stringify(formData);

                                this.setState(
                                    {
                                        addresses,
                                        formData,
                                        delivery,
                                        initialFormData,
                                        isLoading,
                                        stockOnOrder,
                                        suppliers,
                                    },
                                    () => {
                                        this.handleGenerateDeliveries(null, initialLoad);
                                    }
                                );
                            }
                        }
                    }
                });
            }
        );
    };

    /*
     * Delivery Methods (All, Remove, Date Change, Allocation Change)
     */
    handleDeliveryAdd = (idx) => {
        let {
                delivery,
                formData: { fulfilment },
            } = this.state,
            deliveries = [...this.state.formData.fulfilment?.[idx]?.deliveries],
            allocate = 1,
            date = moment().format("YYYY-MM-DD"),
            instock = true;

        if (this.state.formData?.fulfilment?.[idx]?.type === "po") {
            date = fulfilment?.[idx]?.defaultDate ?? moment().format("YYYY-MM-DD");
        } else {
            date = delivery?.defaultDate ?? moment().format("YYYY-MM-DD");
        }

        deliveries.push({
            allocate,
            date,
            instock,
        });

        fulfilment[idx].deliveries = deliveries;

        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment,
            },
        });
    };

    handleDeliveryRemove = (idx, didx) => {
        const deliveries = [...this.state.formData.fulfilment?.[idx]?.deliveries];
        let lines = [...this.state.formData.lines];

        deliveries.splice(didx, 1);

        _.each(lines, (line, idxx) => {
            if (line.fulfilment === idx) {
                lines[idxx] = {
                    ...lines[idxx],
                    delivery: line.delivery >= didx + 1 ? line.delivery - 1 : line.delivery,
                };
            }
        });

        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment: {
                    ...this.state.formData.fulfilment,
                    [idx]: {
                        ...this.state.formData.fulfilment[idx],
                        deliveries,
                    },
                },
                lines,
            },
        });
    };

    handleDeliveryAllocationChange = (idx, didx) => {
        let deliveries = [...this.state.formData.fulfilment?.[idx]?.deliveries];
        deliveries[didx] = {
            ...deliveries[didx],
            allocate: deliveries[didx].allocate === 1 ? 0 : 1,
        };
        // _.each(deliveries, (del, dix) => {
        //     if(del.locked !== 1) {
        //         deliveries[dix] = {
        //             ...deliveries[dix],
        //             modified: 1
        //         }
        //     }
        // })
        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment: {
                    ...this.state.formData.fulfilment,
                    [idx]: {
                        ...this.state.formData.fulfilment[idx],
                        deliveries,
                    },
                },
            },
        });
    };

    handleUnlockDelivery = (idx, didx) => {
        let deliveries = [...this.state.formData.fulfilment?.[idx]?.deliveries];
        deliveries[didx] = {
            ...deliveries[didx],
            locked: 0,
            odStatus: deliveries[didx]?.odStatus === "Awaiting Check" || deliveries[didx]?.odStatus === "Awaiting Despatch" || deliveries[didx]?.odStatus === "Ready for Collection" || deliveries[didx]?.odStatus === "Despatched" ? "Awaiting Picking" : deliveries[didx]?.odStatus ?? null,
        };
        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment: {
                    ...this.state.formData.fulfilment,
                    [idx]: {
                        ...this.state.formData.fulfilment[idx],
                        deliveries,
                    },
                },
            },
        });
    };

    handleDeliveryDateChange = (idx, didx, date) => {
        let deliveries = [...this.state.formData.fulfilment?.[idx]?.deliveries];
        deliveries[didx] = {
            ...deliveries[didx],
            date: moment(date).format("YYYY-MM-DD"),
            dateSet: true,
            // modified: 1
        };
        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment: {
                    ...this.state.formData.fulfilment,
                    [idx]: {
                        ...this.state.formData.fulfilment[idx],
                        deliveries,
                    },
                },
            },
        });
    };

    /*
     * Handle e-mail data (Captures data from the child email dialog components and stores in state until order is processed)
     */
    handleEmailData = (type, formData) => {
        this.setState({
            formData: {
                ...this.state.formData,
                emailData: {
                    ...this.state.formData.emailData,
                    [type]: {
                        ...this.state.formData.emailData[type],
                        ...formData,
                    },
                },
            },
        });
    };

    /*
     * Handle Email Send (Checkbox toggle function to send emails)
     */
    handleEmailSend = (type) => {
        this.setState({
            formData: {
                ...this.state.formData,
                emailData: {
                    ...this.state.formData.emailData,
                    [type]: {
                        ...this.state.formData.emailData?.[type],
                        send: this.state.formData.emailData?.[type]?.send === 1 ? 0 : 1,
                    },
                },
            },
        });
    };

    /*
     * Get initial form data
     */
    getInitialFormData = () => {
        return JSON.parse(this.state.initialFormData);
    };

    /*
     * Generate Deliveries (Uses system-defined [on load] / user-defined [via fulfilment tab] fulfilment choices to generate default deliveries)
     */
    handleGenerateDeliveries = (callback = null, initialLoad = false) => {
        const { order } = this.props;

        /* State */
        const { delivery, formData, reprocessing } = this.state;

        let { suppliers } = this.state;

        const initialFormData = reprocessing ? this.getInitialFormData() : null;

        /* Core data array */
        let fulfilment = [],
            lines = [...this.state.formData.lines];

        /* Are any warehouse deliveries required? */
        const wh = _.filter(formData.lines, (_el) => _el.fulfil === "wh");

        /* Are any purchase orders required? */
        const po = _.sortBy(
            _.filter(suppliers, (el) => _.some(formData.lines, (_el) => _el.supplierId === el.id && _el.fulfil === "po")),
            (el) => el.name
        );

        /* Store index for different origins */
        let origins = {},
            today = moment().format("YYYY-MM-DD");

        if (!_.isEmpty(wh)) {
            origins = {
                ...origins,
                wh: 0,
            };

            let deliveries = null,
                initial = null;

            if (reprocessing) {
                initial = _.find(initialFormData?.fulfilment, (el) => el.type === "wh");
                deliveries = initial?.deliveries ?? [];
                if (!_.isEmpty(deliveries)) {
                    [...deliveries].forEach((x, y) => {
                        if (x.locked === 1 && this.state.formData?.fulfilment?.[this.state.origins.wh]?.deliveries?.[y]?.locked === 0) {
                            deliveries[y] = {
                                ...deliveries[y],
                                locked: 0,
                            };
                        }
                    });
                }
                deliveries = !_.isEmpty(deliveries)
                    ? deliveries
                    : [
                          {
                              allocate: 1,
                              date: delivery?.defaultDate ?? today,
                              dateSet: true,
                          },
                      ];
            } else {
                deliveries = [
                    {
                        allocate: 1,
                        date: delivery?.defaultDate ?? today,
                    },
                ];
            }

            fulfilment.push({
                name: "Advanced Access",
                type: "wh",
                defaultDate: delivery?.defaultDate ?? today,
                defaultDateTooltip: delivery?.defaultDateTooltip,
                cutOffTime: delivery?.cutOff ?? "-",
                cutOffPast: delivery?.cutOffPast ?? false,
                missedReqDelivery: delivery?.missedReqDelivery ?? false,
                deliveries,
            });
        }

        if (!_.isEmpty(po)) {
            _.each(po, (p) => {
                origins = {
                    ...origins,
                    [p.id]: _.size(origins),
                };

                let deliveries = null,
                    initial = null,
                    { delivery } = this.state;

                if (reprocessing) {
                    initial = _.find(initialFormData?.fulfilment, (el) => el.supplier?.id === p.id);
                    deliveries = initial?.deliveries ?? [];
                    deliveries = !_.isEmpty(deliveries)
                        ? deliveries
                        : [
                              {
                                  allocate: 1,
                                  date: p.defaultDate ?? today,
                                  dateSet: true,
                              },
                          ];

                    _.each(deliveries, (del, ddIdx) => {
                        deliveries[ddIdx].locked = p.locked;
                    });

                    let existingPo = this.state.purchaseOrders?.[p.id] ?? null;

                    fulfilment.push({
                        name: p.name,
                        type: "po",
                        defaultDate: p.defaultDate,
                        defaultDateTooltip: p.defaultDateTooltip,
                        cutOffTime: p.cutOff,
                        cutOffPast: p.cutOffPast,
                        lead: p.lead,
                        mov: p.mov,
                        shipHere: existingPo?.shipHere ?? p.shipHere,
                        shipHereOnly: delivery?.courier_conf_collection === 1 ? 1 : 0,
                        missedReqDelivery: p.missedReqDelivery,
                        locked: p.locked,
                        totalCost: "0.00",
                        formData: {
                            collection: existingPo?.coll ?? 0,
                            submissionDate: today,
                            carriage: existingPo?.carr ?? p.defCarr,
                            shipComplete: existingPo?.shipComplete ?? null,
                            suppRef: existingPo?.suppRef ?? "",
                            notes: existingPo?.notes ?? "",
                            deliveryAddress: existingPo?.delAddr ??
                                initial?.formData?.deliveryAddress ?? {
                                    addressOne: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.addressOne ?? "" : order.order_delivery_address_line_one,
                                    addressTwo: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.addressTwo ?? "" : order.order_delivery_address_line_two,
                                    addressThree: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.addressThree ?? "" : order.order_delivery_address_line_three,
                                    town: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.town ?? "" : order.order_delivery_address_town,
                                    county: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.county ?? "" : order.order_delivery_address_county,
                                    postcode: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.postcode ?? "" : order.order_delivery_address_postcode,
                                    country: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.country_name ?? "" : order.order_delivery_address_country,
                                },
                        },
                        deliveries,
                        supplier: p,
                    });
                } else {
                    deliveries = [
                        {
                            allocate: 1,
                            date: p.defaultDate ?? today,
                        },
                    ];

                    fulfilment.push({
                        name: p.name,
                        type: "po",
                        defaultDate: p.defaultDate,
                        defaultDateTooltip: p.defaultDateTooltip,
                        cutOffTime: p.cutOff,
                        cutOffPast: p.cutOffPast,
                        lead: p.lead,
                        mov: p.mov,
                        shipHere: delivery?.courier_conf_collection === 1 ? 1 : p.shipHere,
                        shipHereOnly: delivery?.courier_conf_collection === 1 ? 1 : 0,
                        missedReqDelivery: p.missedReqDelivery,
                        locked: p.locked,
                        totalCost: "0.00",
                        formData: {
                            collection: 0,
                            submissionDate: today,
                            carriage: p.defCarr,
                            shipComplete: null,
                            suppRef: "",
                            notes: "",
                            deliveryAddress: initial?.formData?.deliveryAddress ?? {
                                addressOne: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.addressOne ?? "" : order.order_delivery_address_line_one,
                                addressTwo: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.addressTwo ?? "" : order.order_delivery_address_line_two,
                                addressThree: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.addressThree ?? "" : order.order_delivery_address_line_three,
                                town: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.town ?? "" : order.order_delivery_address_town,
                                county: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.county ?? "" : order.order_delivery_address_county,
                                postcode: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.postcode ?? "" : order.order_delivery_address_postcode,
                                country: p.shipHere === 1 ? AA_ADDRESS_OBJECT?.country_name ?? "" : order.order_delivery_address_country,
                            },
                        },
                        deliveries,
                        supplier: p,
                    });
                }
            });
        }

        _.each(lines, (line, idx) => {
            if (reprocessing) {
                if (initialFormData?.lines?.[line.defLineIdx]?.delivery !== null && lines[idx].fulfil === initialFormData?.lines?.[line.defLineIdx]?.fulfil) {
                    lines[idx].delivery = initialFormData?.lines?.[line.defLineIdx]?.delivery;
                    if (line.fulfil === "wh") {
                        if (fulfilment[origins.wh].deliveries[lines[idx].delivery].newLines) {
                            fulfilment[origins.wh].deliveries[lines[idx].delivery].newLines++;
                        } else {
                            fulfilment[origins.wh].deliveries[lines[idx].delivery].newLines = 1;
                        }
                    } else {
                        if (fulfilment[origins[line.supplierId]].deliveries[lines[idx].delivery].newLines) {
                            fulfilment[origins[line.supplierId]].deliveries[lines[idx].delivery].newLines++;
                        } else {
                            fulfilment[origins[line.supplierId]].deliveries[lines[idx].delivery].newLines = 1;
                        }
                    }
                } else {
                    lines[idx].delivery = -1;
                }
            } else {
                lines[idx].delivery = 0;
            }

            if (line.fulfil === "wh") {
                lines[idx].fulfilment = origins.wh;
            } else {
                lines[idx].fulfilment = origins[line.supplierId];
                let cc = fulfilment?.[origins?.[line.supplierId] ?? -1]?.totalCost ?? false;
                if (cc) {
                    let lt = line.qty * parseFloat(line.cost);
                    fulfilment[origins[line.supplierId]].totalCost = (parseFloat(cc) + lt).toFixed(2);
                }
                if (lines[idx].reqInHouseProg === 1) {
                    fulfilment[origins[line.supplierId]].shipHereOnly = 1;
                    if (fulfilment[origins[line.supplierId]].shipHere === 0) {
                        fulfilment[origins[line.supplierId]].shipHere = 1;
                        fulfilment[origins[line.supplierId]].formData.deliveryAddress = {
                            addressOne: fulfilment[origins[line.supplierId]].shipHere === 1 ? AA_ADDRESS_OBJECT?.addressOne ?? "" : order.order_delivery_address_line_one,
                            addressTwo: fulfilment[origins[line.supplierId]].shipHere === 1 ? AA_ADDRESS_OBJECT?.addressTwo ?? "" : order.order_delivery_address_line_two,
                            addressThree: fulfilment[origins[line.supplierId]].shipHere === 1 ? AA_ADDRESS_OBJECT?.addressThree ?? "" : order.order_delivery_address_line_three,
                            town: fulfilment[origins[line.supplierId]].shipHere === 1 ? AA_ADDRESS_OBJECT?.town ?? "" : order.order_delivery_address_town,
                            county: fulfilment[origins[line.supplierId]].shipHere === 1 ? AA_ADDRESS_OBJECT?.county ?? "" : order.order_delivery_address_county,
                            postcode: fulfilment[origins[line.supplierId]].shipHere === 1 ? AA_ADDRESS_OBJECT?.postcode ?? "" : order.order_delivery_address_postcode,
                            country: fulfilment[origins[line.supplierId]].shipHere === 1 ? AA_ADDRESS_OBJECT?.country_name ?? "" : order.order_delivery_address_country,
                        };
                    }
                }
            }

            if (lines[idx]?.delivery === -1) {
                if (fulfilment?.[lines[idx].fulfilment].type === "po") {
                    fulfilment[origins?.[line.supplierId]].locked = 0;
                    fulfilment[origins?.[line.supplierId]].wasLocked = 0;

                    _.each(fulfilment[origins?.[line.supplierId]].deliveries, (del, delIdx) => {
                        fulfilment[origins?.[line.supplierId]].deliveries[delIdx].locked = 0;
                    });

                    let supplierIdx = _.findIndex(suppliers, (el) => el.id === line.supplierId);
                    if (supplierIdx !== -1) {
                        suppliers[supplierIdx].locked = 0;
                    }
                }

                _.each(fulfilment?.[lines[idx].fulfilment]?.deliveries, (del, didx) => {
                    if (del.odStatus !== "Despatched" && del.odStatus !== "Invoiced" && (del?.wasLocked === 0 || !del?.wasLocked)) {
                        lines[idx].delivery = didx;
                        return false;
                    }
                });

                if (lines[idx]?.delivery === -1) {
                    let ff = fulfilment?.[lines[idx].fulfilment];

                    if (ff.type === "wh") {
                        fulfilment[lines[idx].fulfilment].deliveries.push({
                            allocate: 1,
                            date: delivery?.defaultDate ?? today,
                        });

                        lines[idx].delivery = _.size(fulfilment[lines[idx].fulfilment].deliveries) - 1;
                    } else {
                        fulfilment[lines[idx].fulfilment].deliveries.push({
                            allocate: 1,
                            date: fulfilment[lines[idx].fulfilment]?.defaultDate ?? today,
                        });

                        lines[idx].delivery = _.size(fulfilment[lines[idx].fulfilment].deliveries) - 1;
                    }
                }
            }

            if (line.fulfil === "wh" && line.qtyChanged === 1) {
                fulfilment[origins.wh].deliveries[lines[idx].delivery].mustModify = 1;
            }

            if (line.fulfil === "po" && line.qtyChanged === 1) {
                fulfilment[origins?.[line.supplierId]].locked = 0;
                fulfilment[origins?.[line.supplierId]].wasLocked = 0;

                _.each(fulfilment[origins?.[line.supplierId]].deliveries, (del, delIdx) => {
                    fulfilment[origins?.[line.supplierId]].deliveries[delIdx].locked = 0;
                });

                let supplierIdx = _.findIndex(suppliers, (el) => el.id === line.supplierId);
                if (supplierIdx !== -1) {
                    suppliers[supplierIdx].locked = 0;
                }
            }
        });

        // let initialLines = {}
        // _.each(lines, line => {
        //     initialLines = {
        //         ...initialLines,
        //         [line.fulfilment]: {
        //             ...initialLines.[line.fulfilment],
        //             [line.delivery]: {
        //                 ...initialLines.[line.fulfilment].[line.delivery],

        //             }
        //         }
        //     }
        // })

        _.each(fulfilment, (ff, idx) => {
            if (ff.type === "po" && ff.supplier?.focCarr !== "0.00" && ff.supplier?.defFocCarr > 0) {
                if (parseFloat(ff.totalCost) >= parseFloat(ff.supplier.focCarr)) {
                    fulfilment[idx].formData.carriage = ff.supplier.defFocCarr;
                }
            }
            _.each(ff.deliveries, (d, i) => {
                // if(d.locked === 1 && formData?.fulfilment[idx]?.deliveries?.[i]?.locked === 0) {
                //     fulfilment[idx].deliveries[i].locked = 0;
                // }
                if (_.isEmpty(_.filter(lines, (el) => el.delivery === i && el.fulfilment === idx))) {
                    // ff.deliveries.splice(i, 1);
                    if (ff.type === "po") {
                        fulfilment[idx].locked = 0;
                        fulfilment[idx].wasLocked = 0;

                        _.each(fulfilment[idx].deliveries, (del, delIdx) => {
                            fulfilment[idx].deliveries[delIdx].locked = 0;
                        });

                        let supplierIdx = _.findIndex(suppliers, (el) => el.id === ff?.supplier?.id);
                        if (supplierIdx !== -1) {
                            suppliers[supplierIdx].locked = 0;
                        }
                    }
                } else {
                    if (d?.lines && d?.newLines) {
                        if (d.lines !== d.newLines) {
                            fulfilment[idx].deliveries[i].mustModify = 1;
                        }
                    }
                }
            });
        });

        let { processingOption } = this.state;

        let skipProcessingOptions = false;

        if (initialLoad) {
            if (
                (order?.delivery_carriage?.courier_conf_default !== 1 && order?.delivery_carriage?.courier_conf_free_default !== 1) ||
                _.some(formData.lines, (el) => el.srv === 1 || el.reqTech === 1) ||
                ((_.size(formData.fulfilment) > 1 || formData.fulfilment[0]?.type !== "wh" || _.size(formData.fulfilment[0]?.deliveries) > 1 || !formData.fulfilment[0]?.deliveries[0]?.instock) &&
                    (_.size(formData.fulfilment) > 1 || formData.fulfilment[0]?.type !== "wh" || _.size(formData.fulfilment[0]?.deliveries) > 1 || formData.fulfilment[0]?.deliveries[0]?.instock) &&
                    !(_.size(suppliers) === 1 && _.isEmpty(_.filter(formData.lines, (el) => el.parent > 0))))
            ) {
                processingOption = "MANUAL";
                skipProcessingOptions = true;
            }
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    fulfilment,
                    lines,
                },
                processingOption,
                origins,
                suppliers,
                // initialLines
            },
            () => {
                if (skipProcessingOptions) {
                    // this.props?.handleTabChange?.(2);
                    this.props?.handleProcessingTypeChange?.("CF");
                }
                this.handleStockAvailabilityDateGeneration();
                this.handleCalculateFulfilmentTotals();
                callback?.();
            }
        );
    };

    /*
     * Stock Availability Date Generation (Calculates the expected possible despatch date for each delivery depending non stock availability)
     */
    handleStockAvailabilityDateGeneration = () => {
        let fulfilment = this.state.formData.fulfilment;
        let stockOnOrder = cloneObject(this.state.stockOnOrder);
        const custReqDate = this.props.order.order_requested_delivery_date !== "0000-00-00";

        _.each(fulfilment, (ff, idx) => {
            if (ff.type === "wh") {
                let available = {},
                    onOrder = {};

                ff.deliveries.forEach((del, didx) => {
                    let products = {},
                        instock = true,
                        stockAvailable = this.state.delivery?.defaultDate ?? moment().format("YYYY-MM-DD"),
                        stockAvailableTooltip = [];

                    _.each(
                        _.sortBy(
                            _.filter(
                                this.state.formData.lines,
                                (el) => el.fulfilment === parseInt(idx) && el.delivery === parseInt(didx) && !el.bundleId,
                                (el) => el.delivery
                            )
                        ),
                        (prd) => {
                            let qty = products?.[prd.id] ?? 0;

                            products = {
                                ...products,
                                [prd.id]: available?.[prd.id] ?? prd.qty + qty,
                            };

                            available = {
                                ...available,
                                [prd.id]: available?.[prd.id] ?? prd.stockAvailable + (prd.allocatedStock - prd.allocatedOnOrderStock),
                            };

                            onOrder = {
                                ...onOrder,
                                [prd.id]: onOrder?.[prd.id] ?? prd.stockOnOrderAvailable,
                            };

                            if (prd.qty > available[prd.id]) {
                                /* Line on order - get expected available despatch date */
                                if (stockOnOrder?.[prd.id]) {
                                    let req = prd.qty - available[prd.id];
                                    _.each(stockOnOrder?.[prd.id], (pod, i) => {
                                        if (req > 0) {
                                            if (pod.pod_on_order > 0) {
                                                if (pod.pod_on_order >= req) {
                                                    stockAvailableTooltip.push({
                                                        prd,
                                                        req,
                                                        pod,
                                                    });
                                                    stockOnOrder[prd.id][i].pod_on_order = stockOnOrder?.[prd.id]?.[i]?.pod_on_order - req;
                                                    req = 0;
                                                    let d = pod.pod_check_date !== "0000-00-00" ? moment(pod.pod_check_date).isAfter(pod.pod_expected_despatch_date) ? pod.pod_check_date : pod.pod_expected_despatch_date : pod.pod_expected_despatch_date;
                                                    if (moment(d, "YYYY-MM-DD").isAfter(stockAvailable)) {
                                                        stockAvailable = moment(d).add(1, "day");
                                                        if (stockAvailable.weekday() === 0) {
                                                            stockAvailable = stockAvailable.add(1, "day");
                                                        }
                                                        if (stockAvailable.weekday() === 6) {
                                                            stockAvailable = stockAvailable.add(2, "days");
                                                        }
                                                        stockAvailable = stockAvailable.format("YYYY-MM-DD");
                                                    }
                                                } else {
                                                    req = req - stockOnOrder?.[prd.id]?.[i]?.pod_on_order;
                                                    stockOnOrder[prd.id][i].pod_on_order = 0;
                                                    stockAvailableTooltip.push({
                                                        prd,
                                                        req,
                                                        pod,
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }

                                instock = false;

                                available = {
                                    ...available,
                                    [prd.id]: 0,
                                };

                                onOrder = {
                                    ...onOrder,
                                    [prd.id]: onOrder[prd.id] - (products[prd.id] - available[prd.id]),
                                };
                            } else {
                                /* Line instock - immediate despatch available */
                                available = {
                                    ...available,
                                    [prd.id]: available[prd.id] - prd.qty,
                                };
                            }
                        }
                    );

                    fulfilment[idx].deliveries[didx].instock = instock;
                    fulfilment[idx].deliveries[didx].date = fulfilment[idx].deliveries[didx].dateSet ? fulfilment[idx].deliveries[didx].date : stockAvailable;
                    if(custReqDate) {
                        if(moment(this.props.order.order_requested_delivery_date).isAfter(fulfilment[idx].deliveries[didx].date)) {
                            fulfilment[idx].deliveries[didx].date = this.props.order.order_requested_delivery_date;
                        }
                    }
                    fulfilment[idx].deliveries[didx].stockAvailable = stockAvailable;
                    fulfilment[idx].deliveries[didx].stockAvailableTooltip = stockAvailableTooltip;
                });
            }
        });

        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment,
            },
        });
    };

    /*
     * Calculate Fulfilment Totals (Calculates the estimated total buy price of each Purchase Order, so user can see if above min. order value)
     */
    handleCalculateFulfilmentTotals = () => {
        let {
                formData: { lines },
            } = this.state,
            fulfilment = this.state.formData?.fulfilment;

        _.each(fulfilment, (ff, idx) => {
            let cost = "0.00";

            _.each(
                _.filter(lines, (el) => el.fulfilment === idx && el.fulfil === "po"),
                (line, idx) => {
                    let lt = line.qty * parseFloat(line.cost);

                    cost = (parseFloat(cost) + lt).toFixed(2);
                }
            );

            fulfilment[idx].totalCost = cost;
        });

        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment,
            },
        });
    };

    /*
     * Handle Line Delivery Change (Handles the onChange when the user changes the despatch with X delivery drop down)
     */
    handleLineDeliveryChange = (idx, delivery) => {
        let lines = [...this.state.formData.lines];
        lines[idx] = {
            ...lines[idx],
            delivery: delivery.value ?? delivery ?? "",
        };
        if (lines[idx]?.bundleId > 0) {
            _.each(lines, (line, lidx) => {
                if (line.parent === lines[idx].op) {
                    lines[lidx] = {
                        ...lines[lidx],
                        delivery: delivery.value ?? delivery ?? "",
                    };
                }
            });
        }
        let deliveries = [...this.state.formData.fulfilment?.[lines[idx]?.fulfilment]?.deliveries];
        // _.each(deliveries, (del, dix) => {
        //     if(del.locked !== 1) {
        deliveries[lines[idx].delivery] = {
            ...deliveries[lines[idx].delivery],
            modified: 1,
        };
        // }
        // })
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    fulfilment: {
                        ...this.state.formData.fulfilment,
                        [lines[idx]?.fulfilment]: {
                            ...this.state.formData.fulfilment[lines[idx]?.fulfilment],
                            deliveries,
                        },
                    },
                    lines,
                },
            },
            () => this.handleStockAvailabilityDateGeneration()
        );
    };

    /*
     * Handle Line Fulfilment Method (Handles the onChange when user changes fulfilment from wh -> po and vice-versa)
     */
    handleLineFulfilmentMethod = (idx, fulfil) => {
        let lines = [...this.state.formData.lines],
            origins = this.state.origins;
        // line                        = lines[idx];
        // stock                       = this.state.stock,
        // totalRequiredFromWh         = _.sumBy(_.filter(this.state.formData.lines, el => el.id === line.id && el.fulfil === "wh"), el => el.qty);
        // lineStock                   = stock[line.id],
        // remaining                   = lineStock?.remaining ?? 0,
        // remainingIncoming           = lineStock?.remainingIncoming ?? 0,
        // productAvailable            = lineStock?.productAvailable ?? 0,
        // productAvailableIncoming    = lineStock?.productAvailableIncoming ?? 0;

        lines[idx] = {
            ...lines[idx],
            delivery: fulfil?.value === "po" ? null : 1,
            fulfil: fulfil.value ?? fulfil ?? "",
            fulfilment: fulfil?.value === "po" ? origins[lines[idx].supplierId] : origins.wh,
        };

        // if(fulfil?.value !== line.fulfil) {

        //     if(fulfil.value === "po") {
        //         totalRequiredFromWh = (totalRequiredFromWh - line.qty);
        //     } else {
        //         totalRequiredFromWh = (totalRequiredFromWh + line.qty);
        //     }

        //     if(productAvailable >= totalRequiredFromWh) {

        //         remaining           = (productAvailable - totalRequiredFromWh)
        //         remainingIncoming   = productAvailableIncoming

        //     } else {

        //         remaining           = 0
        //         remainingIncoming   = (productAvailableIncoming + productAvailable - totalRequiredFromWh)

        //     }

        //     // stock = {
        //     //     ...stock,
        //     //     [line.id]: {
        //     //         ...stock[line.id],
        //     //         remaining,
        //     //         remainingIncoming
        //     //     }
        //     // }

        // }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    lines,
                },
                // stock
            },
            () => {
                this.handleGenerateDeliveries();
            }
        );
    };

    /*
     * Split Order Lines
     */
    handleLineSplit = (idx) => this.props.deployDialog(<SplitOrderLine closeDialog={this.props.closeDialog} deployConfirmation={this.props.deployConfirmation} idx={idx} product={this.state.formData?.lines?.[idx] ?? false} submit={this.handleLineSplitSubmit} />, false, "", "standard", "sm");

    handleLineSplitSubmit = (idx, qty) => {
        let lines = [...this.state.formData.lines],
            currentQty = lines?.[idx]?.qty ?? 0,
            newQty = currentQty - qty,
            currentVat = lines?.[idx]?.vat ?? 0;

        if (newQty > 0) {
            lines[idx] = {
                ...lines[idx],
                qty: newQty,
                vat: ((parseFloat(currentVat) / parseInt(currentQty)) * parseInt(newQty)).toFixed(2),
            };
            lines.splice(idx + 1, 0, {
                ...lines[idx],
                qty,
                vat: ((parseFloat(currentVat) / parseInt(currentQty)) * parseInt(qty)).toFixed(2),
            });

            _.each(lines, (line, idx) => {
                lines[idx].lineIdx = idx;
            });

            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        lines,
                    },
                },
                () => {
                    this.props.closeDialog();
                    this.handleCalculateFulfilmentTotals();
                }
            );
        }
    };

    /*
     * Join Order Lines
     */
    handleLineJoin = (idx, fulfilment) =>
        this.props.deployDialog(
            <JoinOrderLine closeDialog={this.props.closeDialog} deployConfirmation={this.props.deployConfirmation} fulfilment={fulfilment} idx={idx} product={this.state.formData?.lines?.[idx] ?? false} formData={this.state.formData} submit={this.handleLineJoinSubmit} />,
            false,
            "",
            "standard",
            "sm"
        );

    handleLineJoinSubmit = (lines) => {
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    lines,
                },
            },
            () => {
                this.props.closeDialog();
                // this.handleLineStockCalculation();
                this.handleCalculateFulfilmentTotals();
            }
        );
    };

    /*
     * Handle Purchase Order Change (Handles changes to po fields on the review tab)
     */
    handlePurchaseOrderChange = (fidx, fieldName, value) => {
        let fulfilment = this.state.formData?.fulfilment;

        fulfilment[fidx].formData[fieldName] = value;

        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment,
            },
        });
    };

    handleUnlockPurchaseOrder = (fidx, suppId) => {
        let fulfilment = this.state.formData?.fulfilment;

        fulfilment[fidx].locked = 0;

        let deliveries = [...fulfilment?.[fidx]?.deliveries];

        _.each(deliveries, (del, idx) => {
            deliveries[idx].locked = 0;
        });

        let suppliers = this.state.suppliers,
            index = _.findIndex(suppliers, (el) => el.id === suppId);

        if (index !== -1) {
            suppliers[index].locked = 0;
        }

        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment: {
                    ...this.state.formData.fulfilment,
                    [fidx]: {
                        ...fulfilment[fidx],
                        deliveries,
                    },
                },
            },
            suppliers,
        });
    };

    handleShipPurchaseOrderHere = (fidx, value) => {
        const { order } = this.props;

        const { origins } = this.state;

        let fulfilment = this.state.formData?.fulfilment;

        fulfilment[fidx].shipHere = value;

        fulfilment[fidx].formData.deliveryAddress = {
            addressOne: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.addressOne ?? "" : order.order_delivery_address_line_one,
            addressTwo: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.addressTwo ?? "" : order.order_delivery_address_line_two,
            addressThree: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.addressThree ?? "" : order.order_delivery_address_line_three,
            town: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.town ?? "" : order.order_delivery_address_town,
            county: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.county ?? "" : order.order_delivery_address_county,
            postcode: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.postcode ?? "" : order.order_delivery_address_postcode,
            country: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.country_name ?? "" : order.order_delivery_address_country,
        };

        fulfilment[fidx].formData.shipComplete = null;
        if(typeof origins?.wh !== "undefined") {
            fulfilment?.[origins.wh]?.deliveries?.forEach((el, idx) => {
                if(!el.odStatus || (el.odStatus && el.odStatus !== "Despatched" && el.odStatus !== "Invoiced")) {
                    fulfilment[fidx].formData.shipComplete = idx;
                    return false;
                }
            })
        }

        if(fulfilment[fidx].formData.shipComplete === null) {
            if(fulfilment?.[origins?.wh]?.deliveries?.length > 0) {
                fulfilment[fidx].formData.shipComplete = fulfilment?.[origins?.wh]?.deliveries?.length;
            } else {
                fulfilment[fidx].formData.shipComplete = 0;
            }
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    fulfilment,
                },
            },
            () => {
                if (fulfilment[fidx].formData?.collection === 1 && fulfilment[fidx].shipHere >= 1) {
                    this.handleCollectPurchaseOrder(fidx, false);
                }
            }
        );
    };

    handleCollectPurchaseOrder = (fidx, value) => {
        const { order } = this.props;

        let fulfilment = this.state.formData?.fulfilment;

        fulfilment[fidx].formData.collection = value;
        fulfilment[fidx].formData.carriage = fulfilment[fidx].formData.collection === 1 ? 0 : fulfilment[fidx].supplier?.defCarr ?? 0;
        fulfilment[fidx].formData.notes =
            fulfilment[fidx].formData.collection === 1
                ? `Please note this purchase order will be collected directly by the customer\n\n${fulfilment[fidx].formData.notes}`
                : fulfilment[fidx].formData.notes.replace(`Please note this purchase order will be collected directly by the customer\n\n`, ``).replace(`Please note this purchase order will be collected directly by the customer`, ``);

        if (fulfilment[fidx].formData.collection === 1) {
            fulfilment[fidx].formData.deliveryAddress = {
                addressOne: fulfilment[fidx]?.supplier?.tradingAddress?.address_line_one ?? "",
                addressTwo: fulfilment[fidx]?.supplier?.tradingAddress?.address_line_two ?? "",
                addressThree: fulfilment[fidx]?.supplier?.tradingAddress?.address_line_three ?? "",
                town: fulfilment[fidx]?.supplier?.tradingAddress?.address_town ?? "",
                county: fulfilment[fidx]?.supplier?.tradingAddress?.county?.county_name ?? "",
                postcode: fulfilment[fidx]?.supplier?.tradingAddress?.address_postcode ?? "",
                country: fulfilment[fidx]?.supplier?.tradingAddress?.country?.country_name ?? "",
            };
        } else {
            fulfilment[fidx].formData.deliveryAddress = {
                addressOne: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.addressOne ?? "" : order.order_delivery_address_line_one,
                addressTwo: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.addressTwo ?? "" : order.order_delivery_address_line_two,
                addressThree: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.addressThree ?? "" : order.order_delivery_address_line_three,
                town: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.town ?? "" : order.order_delivery_address_town,
                county: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.county ?? "" : order.order_delivery_address_county,
                postcode: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.postcode ?? "" : order.order_delivery_address_postcode,
                country: fulfilment[fidx].shipHere >= 1 ? AA_ADDRESS_OBJECT?.country_name ?? "" : order.order_delivery_address_country,
            };
        }

        this.setState({
            formData: {
                ...this.state.formData,
                fulfilment,
            },
        });
    };

    handlePurchaseOrderCustomAddress = (fidx) => {
        this.props.deployDialog(
            <AddressLookupDialog
                key={fidx}
                open={true}
                onSubmit={(address) => {
                    if (address) {
                        this.handlePurchaseOrderChange(fidx, "deliveryAddress", address);
                    }
                    this.props.closeDialog();
                }}
            />,
            true,
            "",
            "standard",
            "xs"
        );
    };

    /*
     * Buy Price Changes (For PO Lines)
     */
    handleBuyPriceChange = (lineIdx, value) => {
        if (value < 0) return;
        let lines = [...this.state.formData.lines];
        lines[lineIdx].buyPrice = value;
        this.setState({
            formData: {
                ...this.state.formData,
                lines,
            },
        });
    };

    handleBuyPriceFloat = (lineIdx) => {
        let lines = [...this.state.formData.lines];
        if (!_.isEmpty(lines[lineIdx].buyPrice)) {
            lines[lineIdx].buyPrice = parseFloat(lines[lineIdx].buyPrice).toFixed(2);
            this.setState({
                formData: {
                    ...this.state.formData,
                    lines,
                },
            });
        }
    };

    /*
     * Checks all deliveries have lines assigned to them before allowing a user to confirm the sales order for processing
     */
    handleValidateReviewTab = () => {
        const { formData } = this.state;

        let error = false,
            duplicateDates = false;

        _.forEach(formData.fulfilment, (origin, i) => {
            const idx = parseInt(i);
            let dates = [];

            origin.deliveries.forEach((del, didx) => {
                if (!error) {
                    const rows = _.filter(formData.lines, (el) => el.fulfilment === idx && el.delivery === didx);

                    if (_.isEmpty(rows)) {
                        error = true;
                    } else {
                        if (origin.type === "wh" && del.stockAvailable && del.date !== del.stockAvailable) {
                            /* Despatch unknown as awaiting stock */
                        } else {
                            dates.push(moment(del.date).format("DDMMYYYY"));
                        }
                    }
                }
            });

            if (!duplicateDates) {
                if (_.some(dates, (el, idx) => dates.indexOf(el) !== idx)) {
                    duplicateDates = true;
                }
            }
        });

        if (error) {
            this.props.handleTabChange(3);
            this.props.deploySnackBar("error", "One or more deliveries contain no products, please check and try again");
        } else if (duplicateDates) {
            this.props.deployConfirmation(
                <>
                    <Typography variant="body2">Some deliveries from the same origin have the same expected date</Typography>
                    <Typography variant="body2">Do you want to continue?</Typography>
                </>,
                false,
                () => this.props.handleTabChange(3)
            );
        }
    };

    /* Fulfilment Tab Compoennts / Methods */
    renderFulfilmentLines = () => {
        const cols = [],
            { formData } = this.state,
            rows = formData.lines;

        /* Calculate running stock totals */
        let products = {},
            available = {},
            onOrder = {};

        _.each(rows, (prd, idx) => {
            if (prd.lineStatus !== "Despatched" && prd.lineStatus !== "Invoiced" && !prd.bundleId) {
                let qty = products?.[prd.id] ?? 0;

                products = {
                    ...products,
                    [prd.id]: available?.[prd.id] ?? prd.qty + qty,
                };

                available = {
                    ...available,
                    [prd.id]: available?.[prd.id] ?? prd.stockAvailable,
                };

                onOrder = {
                    ...onOrder,
                    [prd.id]: onOrder?.[prd.id] ?? prd.stockOnOrderAvailable,
                };

                if (prd.fulfil === "wh") {
                    //

                    if (prd.qty > available[prd.id]) {
                        if (onOrder[prd.id] + (available[prd.id] - prd.qty) < 0) {
                            rows[idx].dea = false;
                            rows[idx].oos = true;
                            rows[idx].stAv = available[prd.id];
                            rows[idx].stRe = available[prd.id];
                            rows[idx].onAv = onOrder[prd.id];
                            rows[idx].onRe = 0;
                            rows[idx].noA = rows[idx]?.srv === 1 ? 0 : 1;
                        } else {
                            rows[idx].dea = false;
                            rows[idx].oos = true;
                            rows[idx].stAv = available[prd.id];
                            rows[idx].stRe = 0;
                            rows[idx].onAv = onOrder[prd.id];
                            rows[idx].onRe = onOrder[prd.id] + (available[prd.id] - prd.qty);
                            rows[idx].noA = 0;

                            available = {
                                ...available,
                                [prd.id]: 0,
                            };

                            onOrder = {
                                ...onOrder,
                                [prd.id]: onOrder[prd.id] - (products[prd.id] - available[prd.id]),
                            };
                        }
                    } else {
                        rows[idx].oos = false;
                        rows[idx].stAv = available[prd.id];
                        rows[idx].stRe = available[prd.id] - prd.qty;
                        rows[idx].onAv = onOrder[prd.id];
                        rows[idx].onRe = onOrder[prd.id];
                        rows[idx].noA = 0;

                        available = {
                            ...available,
                            [prd.id]: available[prd.id] - prd.qty,
                        };
                    }
                } else {
                    rows[idx].oos = false;
                    rows[idx].stAv = available[prd.id];
                    rows[idx].stRe = available[prd.id];
                    rows[idx].onAv = onOrder[prd.id];
                    rows[idx].onRe = onOrder[prd.id];
                    rows[idx].noA = 0;
                }
            }

            rows[idx].locked = formData.fulfilment?.[rows[idx]?.fulfilment]?.deliveries?.[rows[idx]?.delivery]?.locked === 1 ? 1 : 0;
        });

        cols.push(
            {
                heading: "Product",
                field: (rowData) => (rowData?.parent > 0 ? <FAIcon type="thin" icon="arrow-turn-down-right" size={40} noMargin /> : <Avatar variant="square" alt={rowData.code} src={(rowData.img && `${CLOUDFRONT_URL}${rowData.img}`) || logo} />),
                sizeToContent: true,
            },
            {
                heading: "",
                field: (rowData) => (
                    <Box pt={rowData.fulfil === "wh" ? 0.5 : 0.5} pb={rowData.fulfil === "wh" ? 0.5 : 0.5}>
                        <Typography variant="body2" className="fw-400">
                            {rowData.code}
                        </Typography>
                        <Typography variant="caption" component="div">
                            {(rowData?.bundleId && (
                                <>
                                    {rowData?.name?.split?.("\n").map((item, key) => (
                                        <Typography key={key} variant="caption" component="div" className={isEven(key) ? `fw-400` : undefined} gutterBottom={isOdd(key)}>
                                            {item.substr(0, 65)}
                                            {item.length > 65 ? "..." : ""}
                                        </Typography>
                                    ))}
                                </>
                            )) || (
                                <>
                                    {rowData.name.substr(0, 65)}
                                    {rowData.name.length > 65 ? "..." : ""}
                                </>
                            )}
                        </Typography>
                        {!_.isEmpty(rowData.lineText) && (
                            <Box pt={0.5}>
                                <Typography variant="body2">
                                    <Box display="inline-block" pr={0.5}>
                                        <FAIcon icon="exclamation-circle" type="solid" className="textError" noMargin size={13.5} />
                                    </Box>
                                    {rowData.lineText}
                                </Typography>
                            </Box>
                        )}
                        {(rowData?.bundleId && (
                            <>
                                {((rowData?.lineStatus === "Despatched" || rowData?.lineStatus === "Invoiced") && (
                                    <Box pt={0.5}>
                                        <Typography variant="body2" className="fw-400">
                                            <Box display="inline-block" pr={0.5}>
                                                <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                            </Box>
                                            This line has been {rowData.lineStatus.toLowerCase()}
                                        </Typography>
                                    </Box>
                                )) ||
                                    (rowData?.qtyChanged === 1 && rowData?.parent === 0 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                {(rowData.fulfil === "wh" && ((rowData?.wasPicked === 1 && (rowData?.bundleId ? `This bundle must be re-picked` : `This line must be re-picked as the quantity has changed`)) || `The quantity has changed for this item`)) ||
                                                    `This line must be re-submitted as the quantity has changed`}
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData.fulfil === "wh" && rowData?.parent === 0 && this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.locked === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This {rowData?.bundleId ? "bundle" : "line"} has been picked, unlock the delivery to modify
                                            </Typography>
                                        </Box>
                                    ))}
                            </>
                        )) || (
                            <>
                                <Typography variant="caption" component="div">
                                    {(rowData.stockedItem && `${rowData.stockInStock} In Stock | ${rowData.stockAllocated} Allocated | ${rowData.stockAvailable} Available | ${rowData.stockOnOrder} On Order | ${rowData.stockPreOrdered} Pre-ordered`) ||
                                        (rowData.checkPrice ? "Check Price and Availability" : rowData.srv === 1 ? `Managed Service` : `This product is not stocked`)}
                                </Typography>
                                {((rowData?.lineStatus === "Despatched" || rowData?.lineStatus === "Invoiced") && (
                                    <Box pt={0.5}>
                                        <Typography variant="body2" className="fw-400">
                                            <Box display="inline-block" pr={0.5}>
                                                <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                            </Box>
                                            This line has been {rowData.lineStatus.toLowerCase()}
                                        </Typography>
                                    </Box>
                                )) ||
                                    (rowData?.noA === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                Not enough stock available or on order to allocate this line
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData?.qtyChanged === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                {(rowData.fulfil === "wh" && ((rowData?.wasPicked === 1 && (rowData?.bundleId ? `This bundle must be re-picked` : `This line must be re-picked as the quantity has changed`)) || `The quantity has changed for this item`)) ||
                                                    `This line must be re-submitted as the quantity has changed`}
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData.fulfil === "wh" && rowData?.parent === 0 && this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.locked === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This {rowData?.bundleId ? "bundle" : "line"} has been picked, unlock the delivery to modify
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData.fulfil === "po" &&
                                        rowData?.parent === 0 &&
                                        (this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Despatched" || this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Invoiced") && (
                                            <Box pt={0.5}>
                                                <Typography variant="body2" className="fw-400">
                                                    <Box display="inline-block" pr={0.5}>
                                                        <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                                    </Box>
                                                    This line has been despatched from the supplier
                                                </Typography>
                                            </Box>
                                        )) ||
                                    (rowData.fulfil === "po" && rowData?.parent === 0 && this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.locked === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This line has been submitted, unlock the delivery to modify
                                            </Typography>
                                        </Box>
                                    ))}
                            </>
                        )}
                    </Box>
                ),
                important: true,
                main: true,
                truncate: true,
            },
            {
                heading: (
                    <>
                        <div align="center">Unit</div>
                        <div align="center">Sell Price</div>
                    </>
                ),
                field: (rowData) => rowData.subTotal,
                tooltip: (rowData) => `Sold at £${rowData.subTotal} per unit`,
                fieldFormat: "currency",
                alignment: "center",
                sizeToContent: true,
                cellProps: (rowData) => ({
                    style: {
                        color: rowData?.parent !== 0 ? "rgba(0,0,0,0.6)" : undefined,
                        fontStyle: rowData?.parent !== 0 ? "italic" : undefined,
                        fontSize: rowData?.parent !== 0 ? "0.8rem" : undefined,
                    },
                }),
            },
            {
                heading: (
                    <>
                        <div align="center">Quantity</div>
                        <div align="center">Required</div>
                    </>
                ),
                field: (rowData) => (
                    <Tooltip placement="right" title={`${rowData.qty} Quantity Required`}>
                        <span>
                            <Highlight variant={rowData?.srv === 1 || rowData.bundleId ? "success" : rowData.fulfil === "po" ? "success" : rowData.oos ? "error" : "success"}>{rowData.qty}</Highlight>
                        </span>
                    </Tooltip>
                ),
                alignment: "center",
                sizeToContent: true,
            },
            {
                heading: (
                    <>
                        <div align="center">Stock</div>
                        <div align="center">Available</div>
                    </>
                ),
                field: (rowData) =>
                    ((rowData.lineStatus === "Invoiced" || rowData.lineStatus === "Despatched" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                        <Tooltip placement="right" title={`${rowData.stAv ?? 0} Stock Available`}>
                            <span>
                                <Highlight variant={rowData.oos ? "error" : "success"}>{rowData.stAv ?? 0}</Highlight>
                            </span>
                        </Tooltip>
                    ),
                alignment: "right",
                sizeToContent: true,
            },
            {
                heading: (
                    <>
                        <div align="center">Stock</div>
                        <div align="center">Remaining</div>
                    </>
                ),
                field: (rowData) =>
                    ((rowData.lineStatus === "Invoiced" || rowData.lineStatus === "Despatched" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                        <Tooltip placement="right" title={`${rowData.stRe ?? 0} Stock Remaining`}>
                            <span>
                                <Highlight variant={rowData.oos ? "error" : "success"}>{rowData.stRe ?? 0}</Highlight>
                            </span>
                        </Tooltip>
                    ),
                alignment: "right",
                sizeToContent: true,
            },
            {
                heading: (
                    <>
                        <div align="center">On Order</div>
                        <div align="center">Available</div>
                    </>
                ),
                field: (rowData) =>
                    ((rowData.lineStatus === "Invoiced" || rowData.lineStatus === "Despatched" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                        <Tooltip placement="right" title={`${rowData.onAv ?? 0} Stock On Order`}>
                            <span>
                                <Highlight variant={rowData.oos ? "error" : "success"}>{rowData.onAv ?? 0}</Highlight>
                            </span>
                        </Tooltip>
                    ),
                alignment: "right",
                sizeToContent: true,
            },
            {
                heading: (
                    <>
                        <div align="center">On Order</div>
                        <div align="center">Remaining</div>
                    </>
                ),
                field: (rowData) =>
                    ((rowData.lineStatus === "Invoiced" || rowData.lineStatus === "Despatched" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                        <Tooltip placement="right" title={`${rowData?.onRe ?? 0} Stock On Order`}>
                            <span>
                                <Highlight variant={rowData.oos ? "error" : "success"}>{rowData?.onRe ?? 0}</Highlight>
                            </span>
                        </Tooltip>
                    ),
                alignment: "right",
                sizeToContent: true,
            },
            {
                heading: "Fulfilled By",
                field: (rowData) => {
                    const disabled =
                        rowData?.parent > 0 ||
                        rowData?.bundleId ||
                        !rowData.supplierId ||
                        rowData.locked === 1 ||
                        rowData?.lineStatus === "Despatched" ||
                        rowData?.lineStatus === "Invoiced" ||
                        (rowData.fulfil === "po" && (this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Despatched" || this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Invoiced"));
                    // const disabled = ((rowData?.lineStatus === 'Despatched' || rowData?.lineStatus === 'Invoiced') || (rowData.fulfil === "po" && rowData.qty > rowData.stockTotalAvailable) || (rowData.fulfil === "po" && (rowData.qty + _.sumBy(_.filter(formData.lines, el => el.lineStatus !== "Invoiced" && el.lineStatus !== "Despatched" && el.id === rowData.id && el.fulfil === "wh"), el => el.qty)) > rowData.stockTotalAvailable));
                    return (
                        <Box width={300}>
                            <AutoCompleteSelect
                                formatOptionLabel={({ label, lineStatus, locked, subtitle }, props) => (
                                    <Box pt={0.5} pb={0.5}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item align="center">
                                                <FAIcon
                                                    type="thin"
                                                    icon={locked || disabled ? (lineStatus !== "Invoiced" && lineStatus !== "Despatched" ? "lock" : "check") : label === "Advanced Access" ? "inventory" : "truck"}
                                                    className={`ml-1 mr-1 ${
                                                        props.context !== "value" ? (disabled ? `textDisabled` : ``) : lineStatus === "Despatched" || lineStatus === "Invoiced" ? "textSuccess" : rowData.parent > 0 ? `textDisabled` : label === "Advanced Access" ? "textError" : "textInfo"
                                                    }`}
                                                    size={25}
                                                    noMargin
                                                    button
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography
                                                    variant="body2"
                                                    component="div"
                                                    className={locked || disabled ? (props.context === "value" ? `${rowData.parent > 0 ? `textDisabled` : `textDefault`} fw-400` : "textDisabled fw-400") : props.context !== "value" ? "fw-400" : label === "Advanced Access" ? "fw-400" : "fw-400"}
                                                >
                                                    {label?.substring?.(0, 30)}
                                                    {label?.length > 30 ? "..." : ""}
                                                </Typography>
                                                <Typography variant="caption" component="div" className={locked || disabled ? (props.context === "value" ? `${rowData.parent > 0 ? `textDisabled` : `textDefault`}` : "textDisabled") : undefined}>
                                                    {subtitle} {locked === 1 && lineStatus !== "Despatched" && lineStatus !== "Invoiced" ? ` (Locked)` : ``}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                disabled={disabled}
                                noClear
                                onChange={(v) => this.handleLineFulfilmentMethod(rowData.lineIdx, v)}
                                options={[
                                    {
                                        label: rowData.supplier,
                                        subtitle: `Purchase Order${rowData.lineStatus === "Invoiced" || rowData.lineStatus === "Despatched" ? ` (${rowData.lineStatus})` : ``}`,
                                        value: `po`,
                                        locked:
                                            (rowData.fulfil === "po" && (this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Despatched" || this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Invoiced")) ||
                                            (this.state.origins?.[rowData.supplierId] ? (this.state.formData.fulfilment?.[this.state.origins?.[rowData.supplierId]]?.locked === 1 ? 1 : 0) : 0),
                                        disabled: this.state.origins?.[rowData.supplierId] ? (this.state.formData.fulfilment?.[this.state.origins?.[rowData.supplierId]]?.locked === 1 ? true : false) : false,
                                        lineStatus: rowData?.lineStatus ?? null,
                                    },
                                    {
                                        label: `Advanced Access`,
                                        subtitle: `Warehouse Fulfilment${rowData.lineStatus === "Invoiced" || rowData.lineStatus === "Despatched" ? ` (${rowData.lineStatus})` : ``}${rowData.locked === 1 ? ` (Picked)` : ``}`,
                                        value: `wh`,
                                        lineStatus: rowData?.lineStatus ?? null,
                                    },
                                ]}
                                variant="filled"
                                value={rowData.fulfil}
                            />
                        </Box>
                    );
                },
                sizeToContent: true,
            },
            {
                actions: (rowData) => {
                    const disabled = rowData?.parent > 0 || rowData?.bundleId || rowData.locked === 1 || rowData?.lineStatus === "Despatched" || rowData?.lineStatus === "Invoiced" || (rowData.fulfil === "po" && rowData.stockTotalAvailable === 0);
                    return [
                        {
                            name: "Merge Lines",
                            icon: "compress-arrows-alt",
                            onClick: () => this.handleLineJoin(rowData.lineIdx, null),
                            disabled: disabled || _.size(_.filter(formData.lines, (el) => el.id === rowData.id && el.subTotal === rowData.subTotal)) === 1,
                        },
                        { name: "Split Line", icon: "random", onClick: () => this.handleLineSplit(rowData.lineIdx), disabled: disabled || rowData.qty < 2 },
                    ];
                },
            }
        );

        return (
            <DataTable
                config={{
                    alternatingRowColours: false,
                    responsiveImportance: true,
                    noRowHover: true,
                    inline: true,
                    rowProps: (rowData) => ({
                        style: {
                            backgroundColor: "#fff",
                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                        },
                    }),
                }}
                columns={cols}
                rows={rows}
            />
        );
    };

    /*
     * Deliveries Tab Components / Methods
     */
    renderDeliveriesTable = (idx, origin) => {
        const { formData } = this.state,
            { order } = this.props,
            cols = [];

        const custReqDate = this.props.order.order_requested_delivery_date !== "0000-00-00";

        cols.push(
            {
                heading: "Delivery",
                field: (rowData) => (
                    <>
                        <Typography variant="body2" className="fw-400">
                            {`Delivery ${rowData.rowNumber} of ${_.size(origin.deliveries)}`} -{" "}
                            {rowData.odStatus === 'Invoiced' ? 'Invoiced' : rowData.pickIssue ? `Picked [ON HOLD]` : rowData.checkIssue ? `Checked [ON HOLD]` : rowData?.wasLocked === 1 && rowData?.locked === 0 && origin.type !== "po" ? rowData.instock ? "Awaiting Picking" : "Awaiting Stock" : origin.type === 'wh' ? rowData.instock ? 'Awaiting Picking' : 'Awaiting Stock' : (rowData.odStatus ?? "New")}
                        </Typography>
                        {(rowData.locked === 1 && origin.type !== "po" && (
                            <>
                                {((!rowData?.mustModify || rowData?.mustModify === 0) && (
                                    <Box pt={0.5} pb={1}>
                                        <Typography variant="caption" className="fw-400">
                                            <Box display="inline-block" pr={0.5}>
                                                <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                            </Box>
                                            This delivery has been picked and is locked - only modify if necessary
                                        </Typography>
                                    </Box>
                                )) ||
                                    (rowData?.mustModify === 1 && (
                                        <Box pt={0.5} pb={1}>
                                            <Typography variant="caption" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This delivery has been picked but some quantities have changed - you must modify this delivery
                                            </Typography>
                                        </Box>
                                    ))}
                                <Box pb={1}>
                                    <Button
                                        color="primary"
                                        className="btn btn-warning"
                                        onClick={() =>
                                            this.props.deployConfirmation(
                                                <Box textAlign="center">
                                                    <Box p={3} textAlign="center">
                                                        <FAIcon icon="exclamation-circle" type="thin" className="textError" size={60} noMargin />
                                                    </Box>
                                                    <Typography variant="h4" className="fw-400 textError" paragraph>
                                                        Warning - {rowData?.odStatus}
                                                    </Typography>
                                                    <Typography variant="body2" className="fw-400" paragraph>
                                                        Please make stores aware that this delivery is changing.
                                                    </Typography>
                                                    <Typography variant="body2" className="fw-400" paragraph>
                                                        The affected order delivery needs to be retrieved from the{" "}
                                                        {rowData.odStatus === "Ready for Collection" ? "collection shelf" : rowData.odStatus === "Despatched" ? "despatched orders pallet" : rowData.odStatus === "Awaiting Despatch" ? "despatch area" : "check area"}
                                                        ; then dispose of the despatch note and use the stock to re-pick the order again when it shows on the PDA.
                                                        <br />
                                                        <br />
                                                        Any unused parts will need to be returned to the appropriate bin.
                                                    </Typography>
                                                </Box>,
                                                () => this.handleUnlockDelivery(idx, rowData.rowIdx),
                                                undefined,
                                                "Modify Delivery",
                                                "Cancel"
                                            )
                                        }
                                        variant="text"
                                    >
                                        <Box pl={0.5} pr={0.5}>
                                            <FAIcon icon="unlock" size={14} button />
                                            Modify
                                        </Box>
                                    </Button>
                                </Box>
                            </>
                        )) || (
                            <Typography variant="caption" className="fw-400">
                                <Box display="inline-block" mr={0.5}>
                                    {(origin.type === "po" &&
                                        ((origin.type === "po" && (this.state.formData.fulfilment[idx]?.deliveries[rowData.rowIdx]?.podStatus === "Despatched" || this.state.formData.fulfilment[idx]?.deliveries[rowData.rowIdx]?.podStatus === "Invoiced") && (
                                            <FAIcon icon="check-circle" className="textSuccess" size={12.5} />
                                        )) ||
                                            (rowData.odStatus && (((rowData.odStatus === "Invoiced" || rowData.odStatus === "Despatched") && <FAIcon icon="check-circle" className="textSuccess" size={12.5} />) || <FAIcon icon="info-circle" className="textError" size={12.5} />)) || (
                                                <FAIcon icon="info-circle" className="textError" size={12.5} />
                                            ))) ||
                                        (_.isEmpty(_.filter(formData.lines, (el) => el.fulfilment === idx && el.delivery === rowData.rowIdx)) && <FAIcon icon="times-circle" className="textError" size={12.5} />) ||
                                        ((rowData?.odStatus === "Invoiced" || rowData?.odStatus === "Despatched") && <FAIcon icon="check-circle" className="textSuccess" size={12.5} />) ||
                                        (rowData?.odStatus === "Awaiting Despatch" && !rowData.checkIssue && rowData.modified === 0 && <FAIcon icon="check-circle" className="textSuccess" size={12.5} />) ||
                                        (rowData?.odStatus === "Awaiting Despatch" && !rowData.checkIssue && rowData.modified === 1 && <FAIcon icon="exclamation-circle" className="textError" size={12.5} />) ||
                                        (_.some(
                                            _.filter(formData.lines, (el) => el.fulfilment === idx && el.delivery === rowData.rowIdx),
                                            (_el) => _el.noA === 1
                                        ) && <FAIcon icon="exclamation-circle" className="textError" size={12.5} />) ||
                                        (formData?.fulfilment?.[idx]?.type === "wh" && rowData.allocate === 0 && <FAIcon icon="exclamation-circle" className="textWarning" size={12.5} />) ||
                                        (rowData.instock && <FAIcon icon="check-circle" className="textSuccess" size={12.5} />) || <FAIcon icon="exclamation-circle" className="textError" size={12.5} />}
                                </Box>
                                {(origin.type === "po" &&
                                    ((origin.type === "po" &&
                                        (this.state.formData.fulfilment[idx]?.deliveries[rowData.rowIdx]?.podStatus === "Despatched" || this.state.formData.fulfilment[idx]?.deliveries[rowData.rowIdx]?.podStatus === "Invoiced") &&
                                        `This delivery has been despatched from the supplier`) ||
                                        (rowData.odStatus && (((rowData.odStatus === "Invoiced" || rowData.odStatus === "Despatched") && `This delivery has been ${rowData.odStatus.toLowerCase()}`) || `Despatch based on supplier confirmation`)) ||
                                        `Despatch based on supplier confirmation`)) ||
                                    (_.isEmpty(_.filter(formData.lines, (el) => el.fulfilment === idx && el.delivery === rowData.rowIdx)) && `No lines have been assigned to this delivery`) ||
                                    ((rowData?.odStatus === "Invoiced" || rowData?.odStatus === "Despatched") && `This delivery has been ${rowData?.odStatus.toLowerCase()}`) ||
                                    (rowData?.odStatus === "Awaiting Despatch" && !rowData.checkIssue && rowData.locked === 1 && `This delivery has been picked already and is ready for despatch`) ||
                                    (rowData?.odStatus === "Awaiting Despatch" && !rowData.checkIssue && rowData.locked !== 1 && `Changes to this delivery will require re-picking`) ||
                                    (_.some(
                                        _.filter(formData.lines, (el) => el.fulfilment === idx && el.delivery === rowData.rowIdx),
                                        (_el) => _el.noA === 1
                                    ) &&
                                        `This delivery cannot be fully allocated based on current stock availability`) ||
                                    (formData?.fulfilment?.[idx]?.type === "wh" && rowData.allocate === 0 && `Despatch subject to stock allocation on or before ${moment(rowData.date).format("DD/MM/YYYY")}`) ||
                                    (rowData.instock && (custReqDate ? `Available - Despatch: ${moment(rowData.date).format("DD/MM/YYYY")}` : `Available - Despatch: ASAP`)) ||
                                    `Despatch based on stock availability, ${custReqDate ? `customer requested after ${moment(this.props.order.order_requested_delivery_date).format("DD/MM/YYYY")}` : `stock expected after ${moment(rowData.stockAvailable).format("DD/MM/YYYY")}`}`}
                            </Typography>
                        )}
                        {!_.isEmpty(rowData.stockAvailableTooltip) && (
                            <>
                                <Typography variant="body2" className="fw-400 pt-2">
                                    Stock Availability
                                </Typography>
                                {_.map(rowData.stockAvailableTooltip, (av, idx) => (
                                    <Typography variant="caption" key={idx} component="div">
                                        <span className="fw-400">{av.prd?.code}:</span> PO #{av.pod.pod_reference}/{av.pod.pod_idx} (Expected Despatch: {moment(av.pod?.pod_expected_despatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                        {av.pod?.pod_check_date !== "0000-00-00" ? ` | Check Date: ${moment(av.pod?.pod_check_date, "YYYY-MM-DD").format("DD/MM/YYYY")}` : ""}) - {av.req} Available
                                    </Typography>
                                ))}
                            </>
                        )}
                    </>
                ),
                main: true,
                important: true,
                cellProps: (rowData) => ({
                    className: _.isEmpty(_.filter(formData.lines, (el) => el.fulfilment === idx && el.delivery === rowData.rowIdx)) ? "textError" : undefined,
                }),
            },
            {
                heading: "Lines",
                field: (rowData) => {
                    const count = _.size(_.filter(formData.lines, (el) => el?.parent === 0 && el.fulfilment === idx && el.delivery === rowData.rowIdx));
                    return <Highlight variant={count > 0 ? "success" : "error"}>{count}</Highlight>;
                },
                important: true,
                alignment: "center",
                sizeToContent: true,
                cellProps: (rowData) => ({
                    className: _.isEmpty(_.filter(formData.lines, (el) => el.fulfilment === idx && el.delivery === rowData.rowIdx)) ? "textError" : undefined,
                }),
            }
        );

        if (origin.type === "wh") {
            cols.push({
                heading: "Allocate Now",
                field: (rowData) => (
                    <Checkbox
                        color="primary"
                        checked={rowData.allocate === 1}
                        disabled={rowData.locked === 1 || rowData.odStatus === "Invoiced" || rowData.odStatus === "Despatched"}
                        onChange={() =>
                            this.props.deployConfirmation(
                                <>
                                    <Typography variant="body2" className="fw-400">{`Are you sure you ${rowData.allocate === 1 ? `don't want to allocate this delivery now?` : "want to allocate this delivery now?"}`}</Typography>
                                    <Typography variant="body2">{rowData.allocate === 1 ? "If stock is not available on the expected date, this order may be delayed!" : "This will ensure stock is available for this delivery on the expected despatch date"}</Typography>
                                </>,
                                () => this.handleDeliveryAllocationChange(idx, rowData.rowIdx)
                            )
                        }
                    />
                ),
                important: true,
                alignment: "center",
                sizeToContent: true,
            });
        }

        let dateDisabled = order.order_requested_delivery_date === "0000-00-00";
        cols.push({
            heading: `${origin.type === "po" ? `Expected ${origin?.formData?.collection ? "Collection" : "Despatch"} Date (From Supplier)` : `${custReqDate ? `Actual` : `Expected`} ${origin?.formData?.collection ? `Collection Date` : `Despatch Date`}`}${custReqDate ? ` (From ${moment(this.props.order.order_requested_delivery_date).format("DD/MM/YYYY")})` : ``}`,
            field: (rowData) => (
                <Box minWidth={250} pt={1} pb={1}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <FormControl fullWidth>
                                <DatePicker
                                    disabled={
                                        dateDisabled || (
                                        (origin.type === "po" && (this.state.formData.fulfilment[idx]?.deliveries[rowData.rowIdx]?.podStatus === "Despatched" || this.state.formData.fulfilment[idx]?.deliveries[rowData.rowIdx]?.podStatus === "Invoiced")) ||
                                        rowData.locked === 1 ||
                                        rowData.odStatus === "Invoiced" ||
                                        rowData.odStatus === "Despatched" ||
                                        (origin.type === "wh" && rowData.instock && rowData.allocate && rowData.order_requested_delivery_date === "0000-00-00")
                                    )}
                                    type="date"
                                    value={rowData.date}
                                    onChange={(date) => this.handleDeliveryDateChange(idx, rowData.rowIdx, date)}
                                    autoOk={true}
                                    disablePast={true}
                                    minDate={custReqDate ? moment(this.props.order.order_requested_delivery_date, "YYYY-MM-DD").toDate() : undefined}
                                    inputVariant="filled"
                                />
                            </FormControl>
                        </Grid>
                        {!dateDisabled ? (
                            <Grid item>
                                <Box minWidth={38}>
                                    {rowData.odStatus !== "Invoiced" && rowData.odStatus !== "Despatched" && (
                                        <>
                                            {order.order_requested_delivery_date !== "0000-00-00" && order.order_requested_delivery_date !== rowData.date && (
                                                <Tooltip title={!_.isEmpty(this.state.formData?.fulfilment?.[idx]?.defaultDateTooltip) ? _.map(this.state.formData?.fulfilment?.[idx]?.defaultDateTooltip.split("\n"), (r, idx) => <div key={idx}>{r}</div>) : "No factors affect despatch"}>
                                                    <span>
                                                        <FAIcon icon="exclamation-triangle" color="error" noMargin button />
                                                    </span>
                                                </Tooltip>
                                            )}
                                            {order.order_requested_delivery_date === "0000-00-00" && this.state.formData?.fulfilment?.[idx]?.cutOffPast && moment(rowData.date, "YYYY-MM-DD").isSame(moment().format("YYYY-MM-DD")) && (
                                                <Tooltip title={`Cut Off Time Today: ${this.state.formData?.fulfilment?.[idx]?.cutOffTime}`}>
                                                    <span>
                                                        <FAIcon icon="exclamation-triangle" color="error" noMargin button />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        ) : null}
                    </Grid>
                </Box>
            ),
            sizeToContent: true,
            important: true,
        });

        cols.push({
            actions: (rowData) => {
                return [
                    {
                        name: "Delete",
                        icon: "trash-alt",
                        onClick: () => this.handleDeliveryRemove(idx, rowData.rowIdx),
                        disabled: rowData.odStatus === "Invoiced" || rowData.odStatus === "Despatched" || _.some(formData.lines, (el) => el.fulfilment === idx && el.delivery === rowData.rowIdx),
                    },
                ];
            },
        });

        return (
            <DataTable
                config={{
                    alternatingRowColours: false,
                    responsiveImportance: true,
                    noRowHover: true,
                    inline: true,
                    rowProps: (rowData) => ({
                        style: {
                            backgroundColor: "#fff",
                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                        },
                    }),
                }}
                columns={cols}
                rows={origin.deliveries}
            />
        );
    };

    renderDeliveriesFulfilmentLines = (rws, deliveries, type, f) => {
        const cols = [],
            fulfilment = parseInt(f),
            { formData } = this.state,
            { order } = this.props,
            rows = _.sortBy(rws, (el) => el.delivery),
            dateActual = order.order_requested_delivery_date !== "0000-00-00";
        cols.push(
            {
                heading: "Product",
                field: (rowData) => (rowData?.parent > 0 ? <FAIcon type="thin" icon="arrow-turn-down-right" size={40} noMargin /> : <Avatar variant="square" alt={rowData.code} src={(rowData.img && `${CLOUDFRONT_URL}${rowData.img}`) || logo} />),
                sizeToContent: true,
            },
            {
                heading: "",
                field: (rowData) => (
                    <Box pt={type === "wh" ? 1 : 0.75} pb={type === "wh" ? 1 : 0.75}>
                        <Typography variant="body2" className="fw-400">
                            {rowData.code}
                        </Typography>
                        <Typography variant="caption" component="div">
                            {(rowData?.bundleId && (
                                <>
                                    {rowData?.name?.split?.("\n").map((item, key) => (
                                        <Typography key={key} variant="caption" component="div" className={isEven(key) ? `fw-400` : undefined} gutterBottom={isOdd(key)}>
                                            {item.substr(0, 65)}
                                            {item.length > 65 ? "..." : ""}
                                        </Typography>
                                    ))}
                                </>
                            )) || (
                                <>
                                    {rowData.name.substr(0, 65)}
                                    {rowData.name.length > 65 ? "..." : ""}
                                </>
                            )}
                        </Typography>
                        {!_.isEmpty(rowData.lineText) && (
                            <Box pt={0.5}>
                                <Typography variant="body2" className="fw-400">
                                    <Box display="inline-block" pr={0.5}>
                                        <FAIcon icon="exclamation-circle" type="solid" className="textError" noMargin size={13.5} />
                                    </Box>
                                    {rowData.lineText}
                                </Typography>
                            </Box>
                        )}
                        {(rowData?.bundleId && (
                            <>
                                {((rowData?.lineStatus === "Despatched" || rowData?.lineStatus === "Invoiced") && (
                                    <Box pt={0.5}>
                                        <Typography variant="body2" className="fw-400">
                                            <Box display="inline-block" pr={0.5}>
                                                <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                            </Box>
                                            This line has been {rowData.lineStatus.toLowerCase()}
                                        </Typography>
                                    </Box>
                                )) ||
                                    (rowData?.qtyChanged === 1 && rowData?.parent === 0 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                {(rowData.fulfil === "wh" && ((rowData?.wasPicked === 1 && (rowData?.bundleId ? `This bundle must be re-picked` : `This line must be re-picked as the quantity has changed`)) || `The quantity has changed for this item`)) ||
                                                    `This line must be re-submitted as the quantity has changed`}
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData.fulfil === "wh" && rowData?.parent === 0 && this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.locked === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This {rowData?.bundleId ? "bundle" : "line"} has been picked, unlock the delivery to modify
                                            </Typography>
                                        </Box>
                                    ))}
                            </>
                        )) || (
                            <>
                                {((rowData?.lineStatus === "Despatched" || rowData?.lineStatus === "Invoiced") && (
                                    <Box pt={0.5}>
                                        <Typography variant="caption" className="fw-400">
                                            <Box display="inline-block" pr={0.5}>
                                                <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                            </Box>
                                            This line has been {rowData.lineStatus.toLowerCase()}
                                        </Typography>
                                    </Box>
                                )) ||
                                    (type === "wh" && (
                                        <>
                                            <Typography variant="caption" component="div">
                                                {(rowData.stockedItem && `${rowData.stockInStock} In Stock | ${rowData.stockAllocated} Allocated | ${rowData.stockAvailable} Available | ${rowData.stockOnOrder} On Order | ${rowData.stockPreOrdered} Pre-ordered`) ||
                                                    (rowData.checkPrice ? "Check Price and Availability" : rowData.srv === 1 ? `Managed Service` : `This product is not stocked`)}
                                            </Typography>
                                            {(rowData?.noA === 1 && (
                                                <Box pt={0.5}>
                                                    <Typography variant="caption" className="fw-400 textError">
                                                        <Box display="inline-block" pr={0.5}>
                                                            <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                        </Box>
                                                        Not enough stock available or on order to allocate this line
                                                    </Typography>
                                                </Box>
                                            )) ||
                                                (rowData?.qtyChanged === 1 && rowData?.parent === 0 && (
                                                    <Box pt={0.5}>
                                                        <Typography variant="caption" className="fw-400 textError">
                                                            <Box display="inline-block" pr={0.5}>
                                                                <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                            </Box>
                                                            This {rowData?.bundleId ? "bundle" : "line"} must be re-picked as the quantity has changed
                                                        </Typography>
                                                    </Box>
                                                )) ||
                                                (deliveries[rowData?.delivery]?.locked === 1 && rowData?.parent === 0 && (
                                                    <Box pt={0.5}>
                                                        <Typography variant="caption" className="fw-400">
                                                            <Box display="inline-block" pr={0.5}>
                                                                <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                            </Box>
                                                            This {rowData?.bundleId ? "bundle" : "line"} has been picked, unlock the delivery to modify
                                                        </Typography>
                                                    </Box>
                                                ))}
                                        </>
                                    )) ||
                                    (rowData?.qtyChanged === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="caption" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This line must be re-submitted as the quantity has changed
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData.fulfil === "po" && (this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Despatched" || this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Invoiced") && (
                                        <Box pt={0.5}>
                                            <Typography variant="caption" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textSuccess" noMargin size={13.5} />
                                                </Box>
                                                This line has been despatched from the supplier
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData.fulfil === "po" && this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.locked === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="caption" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This line has been submitted, unlock the delivery to modify
                                            </Typography>
                                        </Box>
                                    ))}
                            </>
                        )}
                    </Box>
                ),
                important: true,
                main: true,
                truncate: true,
            },
            {
                heading: (
                    <>
                        <div align="center">Unit</div>
                        <div align="center">Sell Price</div>
                    </>
                ),
                field: (rowData) => rowData.subTotal,
                tooltip: (rowData) => `Sold at £${rowData.subTotal} per unit`,
                fieldFormat: "currency",
                alignment: "center",
                sizeToContent: true,
                cellProps: (rowData) => ({
                    style: {
                        color: rowData?.parent !== 0 ? "rgba(0,0,0,0.6)" : undefined,
                        fontStyle: rowData?.parent !== 0 ? "italic" : undefined,
                        fontSize: rowData?.parent !== 0 ? "0.8rem" : undefined,
                    },
                }),
            },
            {
                heading: (
                    <>
                        <div align="center">Quantity</div>
                        <div align="center">Required</div>
                    </>
                ),
                field: (rowData) => (
                    <Tooltip placement="right" title={`${rowData.qty} Quantity Required`}>
                        <span>
                            <Highlight variant={rowData?.srv === 1 || rowData.bundleId ? "success" : type === "po" ? "success" : rowData.dea ? undefined : rowData.oos ? "error" : "success"}>{rowData.qty}</Highlight>
                        </span>
                    </Tooltip>
                ),
                alignment: "center",
                sizeToContent: true,
            }
        );

        if (type === "wh") {
            /* Calculate running stock totals */
            let products = {},
                available = {},
                onOrder = {};

            _.each(rows, (prd, idx) => {
                if (prd.lineStatus !== "Despatched" && prd.lineStatus !== "Invoiced" && !prd?.bundleId) {
                    let qty = products?.[prd.id] ?? 0;

                    products = {
                        ...products,
                        [prd.id]: available?.[prd.id] ?? prd.qty + qty,
                    };

                    available = {
                        ...available,
                        [prd.id]: available?.[prd.id] ?? prd.stockAvailable,
                    };

                    onOrder = {
                        ...onOrder,
                        [prd.id]: onOrder?.[prd.id] ?? prd.stockOnOrderAvailable,
                    };

                    if (deliveries?.[prd.delivery]?.allocate === 1) {
                        if (prd.qty > available[prd.id]) {
                            if (onOrder[prd.id] + (available[prd.id] - prd.qty) < 0) {
                                rows[idx].dea = false;
                                rows[idx].oos = true;
                                rows[idx].stAv = available[prd.id];
                                rows[idx].stRe = available[prd.id];
                                rows[idx].onAv = onOrder[prd.id];
                                rows[idx].onRe = 0;
                                rows[idx].noA = rows[idx]?.srv === 1 ? 0 : 1;
                            } else {
                                rows[idx].dea = false;
                                rows[idx].oos = true;
                                rows[idx].stAv = available[prd.id];
                                rows[idx].stRe = 0;
                                rows[idx].onAv = onOrder[prd.id];
                                rows[idx].onRe = onOrder[prd.id] + (available[prd.id] - prd.qty);
                                rows[idx].noA = 0;

                                available = {
                                    ...available,
                                    [prd.id]: 0,
                                };

                                onOrder = {
                                    ...onOrder,
                                    [prd.id]: onOrder[prd.id] - (products[prd.id] - available[prd.id]),
                                };
                            }
                        } else {
                            rows[idx].dea = false;
                            rows[idx].oos = false;
                            rows[idx].stAv = available[prd.id];
                            rows[idx].stRe = available[prd.id] - prd.qty;
                            rows[idx].onAv = onOrder[prd.id];
                            rows[idx].onRe = onOrder[prd.id];
                            rows[idx].noA = 0;

                            available = {
                                ...available,
                                [prd.id]: available[prd.id] - prd.qty,
                            };
                        }
                    } else {
                        rows[idx].dea = true;
                        rows[idx].oos = false;
                        rows[idx].stAv = available[prd.id];
                        rows[idx].stRe = available[prd.id];
                        rows[idx].onAv = onOrder[prd.id];
                        rows[idx].onRe = onOrder[prd.id];
                        rows[idx].noA = 0;
                    }
                }
            });

            cols.push(
                {
                    heading: (
                        <>
                            <div align="center">Stock</div>
                            <div align="center">Available</div>
                        </>
                    ),
                    field: (rowData) =>
                        ((rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                            <Tooltip placement="right" title={`${rowData.stAv ?? 0} Stock Available`}>
                                <span>
                                    <Highlight variant={rowData.dea ? undefined : rowData.oos ? "error" : "success"}>{rowData.stAv ?? 0}</Highlight>
                                </span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                },
                {
                    heading: (
                        <>
                            <div align="center">Stock</div>
                            <div align="center">Remaining</div>
                        </>
                    ),
                    field: (rowData) =>
                        ((rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                            <Tooltip placement="right" title={`${rowData.stRe ?? 0} Stock Remaining`}>
                                <span>
                                    <Highlight variant={rowData.dea ? undefined : rowData.oos ? "error" : "success"}>{rowData.stRe ?? 0}</Highlight>
                                </span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                },
                {
                    heading: (
                        <>
                            <div align="center">On Order</div>
                            <div align="center">Available</div>
                        </>
                    ),
                    field: (rowData) =>
                        ((rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                            <Tooltip placement="right" title={`${rowData.onAv ?? 0} Stock On Order`}>
                                <span>
                                    <Highlight variant={rowData.dea ? undefined : rowData.oos ? "error" : "success"}>{rowData.onAv ?? 0}</Highlight>
                                </span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                },
                {
                    heading: (
                        <>
                            <div align="center">On Order</div>
                            <div align="center">Remaining</div>
                        </>
                    ),
                    field: (rowData) =>
                        ((rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                            <Tooltip placement="right" title={`${rowData?.onRe ?? 0} Stock On Order`}>
                                <span>
                                    <Highlight variant={rowData.dea ? undefined : rowData.oos ? "error" : "success"}>{rowData?.onRe ?? 0}</Highlight>
                                </span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                }
            );
        }

        cols.push(
            {
                heading: "Despatch with",
                field: (rowData) => (
                    <Box style={{ width: 300 }}>
                        <AutoCompleteSelect
                            disabled={
                                (rowData.fulfil === "po" && (this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Despatched" || this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Invoiced")) ||
                                formData.fulfilment[rowData.fulfilment]?.deliveries?.[rowData.delivery]?.locked === 1 ||
                                rowData.lineStatus === "Despatched" ||
                                rowData.lineStatus === "Invoiced" ||
                                rowData?.parent > 0
                            }
                            value={rowData.delivery}
                            onChange={(delivery) => this.handleLineDeliveryChange(rowData.lineIdx, delivery)}
                            options={_.map(deliveries, (el, didx) =>
                                _.assign({
                                    label: `Delivery ${didx + 1}${el.odStatus === "Invoiced" || el.odStatus === "Despatched" ? ` - ${el.odStatus}` : ``}`,
                                    date: moment(el.date).format("DD/MM/YYYY"),
                                    value: didx,
                                    disabled: el.locked === 1 || el.odStatus === "Invoiced" || el.odStatus === "Despatched",
                                })
                            )}
                            noClear
                            formatOptionLabel={({ label, date }) => (
                                <Box pt={0.5} pb={0.5}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item align="center">
                                            <FAIcon icon="shipping-fast" className="ml-1 mr-1" size={25} noMargin button />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body2" className="fw-400">
                                                {label}
                                            </Typography>
                                            <Typography variant="caption" component="div">
                                                {dateActual ? `Requested` : `Expected`} Despatch: {date}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                            variant="filled"
                        />
                    </Box>
                ),
                sizeToContent: true,
            },
            {
                actions: (rowData) => {
                    return [
                        {
                            name: "Merge Lines",
                            icon: "compress-arrows-alt",
                            onClick: () => this.handleLineJoin(rowData.lineIdx, fulfilment),
                            disabled:
                                (rowData.fulfil === "po" && (this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Despatched" || this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Invoiced")) ||
                                formData.fulfilment[rowData.fulfilment]?.deliveries?.[rowData.delivery]?.locked === 1 ||
                                rowData.lineStatus === "Despatched" ||
                                rowData.lineStatus === "Invoiced" ||
                                rowData?.parent > 0 ||
                                rowData?.bundleId ||
                                _.size(_.filter(formData.lines, (el) => el.id === rowData.id && el.fulfilment === fulfilment && el.subTotal === rowData.subTotal)) === 1,
                        },
                        {
                            name: "Split Line",
                            icon: "random",
                            onClick: () => this.handleLineSplit(rowData.lineIdx),
                            disabled:
                                (rowData.fulfil === "po" && (this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Despatched" || this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.podStatus === "Invoiced")) ||
                                formData.fulfilment[rowData.fulfilment]?.deliveries?.[rowData.delivery]?.locked === 1 ||
                                rowData.lineStatus === "Despatched" ||
                                rowData.lineStatus === "Invoiced" ||
                                rowData?.parent > 0 ||
                                rowData?.bundleId ||
                                rowData.qty < 2,
                        },
                    ];
                },
            }
        );

        return (
            <DataTable
                config={{
                    alternatingRowColours: false,
                    responsiveImportance: true,
                    noRowHover: true,
                    inline: true,
                    rowProps: (rowData) => ({
                        style: {
                            backgroundColor: "#fff",
                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                        },
                    }),
                }}
                columns={cols}
                rows={rows}
            />
        );
    };

    /*
     * Review Tab Components / Methods
     */
    renderReviewDeliveries = (renderAll) => {
        let deliveries = [];

        const { 
            formData, 
            origins 
        } = this.state;

        const shipCompleteIdx = [];

        _.each(_.filter(formData.fulfilment, el => el?.shipHere !== 2), (origin, idx) => {
            let available = {};
            let onOrder = {};

            origin.deliveries.forEach((del, didx) => {
                let delivery = {
                    ...del,
                    ffIdx: idx,
                    ffName: origin.name,
                    ffType: origin.type,
                    ddIdx: didx,
                    products: _.filter(formData.lines, (el) => el.fulfilment === parseInt(idx) && el.delivery === parseInt(didx)),
                };

                if (delivery.ffType === "wh") {
                    let products = {},
                        instock = true,
                        shipComplete = false,
                        weight = "0.00000",
                        allocate = delivery.allocate === 1;

                    _.each(delivery.products, (prd, idx) => {
                        if (prd.lineStatus !== "Despatched" && prd.lineStatus !== "Invoiced" && !prd.bundleId && prd?.srv !== 1) {
                            let qty = products?.[prd.id] ?? 0,
                                productWeight = (parseInt(prd.qty) * parseFloat(prd.weight)).toFixed(5);

                            weight = (parseFloat(weight) + parseFloat(productWeight)).toFixed(5);

                            products = {
                                ...products,
                                [prd.id]: available?.[prd.id] ?? prd.qty + qty,
                            };

                            available = {
                                ...available,
                                [prd.id]: available?.[prd.id] ?? prd.stockAvailable,
                            };

                            onOrder = {
                                ...onOrder,
                                [prd.id]: onOrder?.[prd.id] ?? prd.stockOnOrderAvailable,
                            };

                            if (allocate) {
                                if (prd.qty > available[prd.id]) {
                                    if (onOrder[prd.id] + (available[prd.id] - prd.qty) < 0) {
                                        allocate = 0;
                                        instock = false;
                                        delivery.products[idx].dea = false;
                                        delivery.products[idx].oos = true;
                                        delivery.products[idx].stAv = available[prd.id];
                                        delivery.products[idx].stRe = available[prd.id];
                                        delivery.products[idx].onAv = onOrder[prd.id];
                                        delivery.products[idx].onRe = 0;
                                        delivery.products[idx].noA = delivery.products[idx]?.srv === 1 ? 0 : 1;
                                    } else {
                                        instock = false;
                                        delivery.products[idx].dea = false;
                                        delivery.products[idx].oos = true;
                                        delivery.products[idx].stAv = available[prd.id];
                                        delivery.products[idx].stRe = 0;
                                        delivery.products[idx].onAv = onOrder[prd.id];
                                        delivery.products[idx].onRe = onOrder[prd.id] + (available[prd.id] - prd.qty);
                                        delivery.products[idx].noA = 0;

                                        available = {
                                            ...available,
                                            [prd.id]: 0,
                                        };

                                        onOrder = {
                                            ...onOrder,
                                            [prd.id]: onOrder[prd.id] - (products[prd.id] - available[prd.id]),
                                        };
                                    }
                                } else {
                                    delivery.products[idx].dea = false;
                                    delivery.products[idx].oos = false;
                                    delivery.products[idx].stAv = available[prd.id];
                                    delivery.products[idx].stRe = available[prd.id] - prd.qty;
                                    delivery.products[idx].onAv = onOrder[prd.id];
                                    delivery.products[idx].onRe = onOrder[prd.id];
                                    delivery.products[idx].noA = 0;

                                    available = {
                                        ...available,
                                        [prd.id]: available[prd.id] - prd.qty,
                                    };
                                }
                            } else {
                                delivery.products[idx].dea = true;
                                delivery.products[idx].oos = false;
                                delivery.products[idx].stAv = available[prd.id];
                                delivery.products[idx].stRe = available[prd.id];
                                delivery.products[idx].onAv = onOrder[prd.id];
                                delivery.products[idx].onRe = onOrder[prd.id];
                                delivery.products[idx].noA = 0;
                            }
                        }
                    });

                    let wasInStock = instock;
                    let scKey = `0:${didx}`;
                    shipCompleteIdx.push(scKey);
                    let _shipComplete = _.filter(formData.fulfilment, el => el?.shipHere === 2 && el?.formData?.shipComplete === scKey);
                    if(_shipComplete.length > 0) {
                        _shipComplete?.forEach?.(el => {
                            const suppId = el?.supplier?.id,
                                origin = origins?.[suppId] ?? null;
                            if(origin) {
                                for(let i = 0; i < el?.deliveries?.length; i++) {
                                    if(moment(delivery.date).isBefore(moment(el?.deliveries[i]?.date))) {
                                        delivery.date = el?.deliveries[i]?.date;
                                    }
                                }
                                delivery.date = moment().add(1, 'days').format("YYYY-MM-DD");
                                let shipCompleteProducts = _.map(_.filter(formData.lines, (el) => el.fulfil === "po" && el.fulfilment === parseInt(origin)), prd => ({...prd, shipComplete: true}));
                                shipCompleteProducts?.forEach?.(prd => {
                                    let productWeight = (parseInt(prd.qty) * parseFloat(prd.weight)).toFixed(5);
                                    weight = (parseFloat(weight) + parseFloat(productWeight)).toFixed(5);
                                });
                                instock = false;
                                shipComplete = true;
                                let supplierDetail = {
                                    nm: this.state.formData.fulfilment?.[origin]?.name ?? 'Unknown',
                                    prd: shipCompleteProducts
                                }
                                delivery.shipCompletePos = _.concat(delivery?.shipCompletePos ?? [], supplierDetail);
                            }
                        })
                    }

                    weight = parseFloat(weight).toFixed(2);

                    delivery = {
                        ...delivery,
                        allocate,
                        instock,
                        wasInStock,
                        shipComplete,
                        weight,
                    };
                } else {
                    let scKey = `${origin?.supplier?.id}:${didx}`;
                    shipCompleteIdx.push(scKey);
                    let _shipComplete = _.filter(formData.fulfilment, el => el?.shipHere === 2 && el?.formData?.shipComplete === scKey);
                    if(_shipComplete.length > 0) {
                        _shipComplete?.forEach?.(el => {
                            const suppId = el?.supplier?.id,
                                origin = origins?.[suppId] ?? null;
                            if(origin !== null) {
                                for(let i = 0; i < el?.deliveries?.length; i++) {
                                    if(moment(delivery.date).isBefore(moment(el?.deliveries[i]?.date))) {
                                        delivery.date = el?.deliveries[i]?.date;
                                    }
                                }
                                delivery.date = moment().add(1, 'days').format("YYYY-MM-DD");
                                let shipCompleteProducts = _.map(_.filter(formData.lines, (el) => el.fulfil === "po" && el.fulfilment === parseInt(origin)), prd => ({...prd, shipComplete: true}));
                                let supplierDetail = {
                                    nm: this.state.formData.fulfilment?.[origin]?.name ?? 'Unknown',
                                    prd: shipCompleteProducts
                                }
                                delivery.shipCompletePos = _.concat(delivery?.shipCompletePos ?? [], supplierDetail);
                            }
                        })
                        delivery = {
                            ...delivery,
                            instock: false,
                            shipComplete: true
                        };
                    }
                }

                deliveries.push(delivery);
            });
        });

        // let shipCompleteDeliveries = [];
        // _.each(_.filter(formData.fulfilment, el => el?.shipHere === 2 && !shipCompleteIdx.includes(el?.formData?.shipComplete)), el => {

        //     let idx = _.findIndex(shipCompleteDeliveries, {idx: el?.formData?.shipComplete});

        //     if(idx < 0) {
                    
        //         shipCompleteDeliveries.push({
        //             allocate: 1,
        //             date: moment().format("YYYY-MM-DD"),
        //             instock: false,
        //             shipComplete: true,
        //             ffName: "Advanced Access",
        //             ffType: "wh",
        //             idx: el?.formData?.shipComplete,
        //             products: [],
        //             weight: "0.00000"
        //         });

        //         idx = _.findIndex(shipCompleteDeliveries, {idx: el?.formData?.shipComplete});

        //     }

        //     for(let i = 0; i < el?.deliveries?.length; i++) {
        //         if(moment(shipCompleteDeliveries[idx].date).isBefore(moment(el?.deliveries[i]?.date))) {
        //             shipCompleteDeliveries[idx].date = el?.deliveries[i]?.date;
        //         }
        //     }
            
        //     const suppId = el?.supplier?.id,
        //         origin = origins?.[suppId] ?? null;
            
        //     let shipCompleteProducts = _.map(_.filter(formData.lines, (el) => el.fulfil === "po" && el.fulfilment === parseInt(origin)), prd => ({...prd, shipComplete: true}));
        //     shipCompleteProducts?.forEach?.(prd => {
        //         let productWeight = (parseInt(prd.qty) * parseFloat(prd.weight)).toFixed(5);
        //         shipCompleteDeliveries[idx].weight = (parseFloat(shipCompleteDeliveries[idx].weight) + parseFloat(productWeight)).toFixed(5);
        //     });

        //     shipCompleteDeliveries[idx].products = _.concat(shipCompleteDeliveries[idx].products, shipCompleteProducts);

        // });

        // deliveries = _.concat(deliveries, shipCompleteDeliveries);

        if (renderAll) {
            return _.map(deliveries, (del, didx) => {
                let origin = this.state.formData.fulfilment?.[del.ffIdx] ?? null;
                return (
                    <Grid item xs={12} key={didx}>
                        <Paper>
                            <Box p={2}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant="h6" className="fw-400">
                                            Delivery {didx + 1} of {_.size(deliveries)} by {del?.shipComplete ? `Advanced Access` : del.ffName}
                                        </Typography>
                                        {(del.ffType === "wh" &&
                                            (((del.odStatus === "Despatched" || del.odStatus === "Invoiced") && (
                                                <>
                                                    <Typography variant="body2" className="fw-300" gutterBottom>
                                                        Despatched: {momentFormatDate(del.odDespatch)}
                                                    </Typography>
                                                    <Box pt={1}>
                                                        <Alert severity={"info"}>
                                                            <strong>{del.odStatus}</strong>
                                                            <br />
                                                            This delivery has already been fulfilled by the warehouse
                                                        </Alert>
                                                    </Box>
                                                </>
                                            )) || (
                                                <>
                                                    {(!del.allocate && (
                                                        <>
                                                            <Typography variant="body2" className="fw-300" gutterBottom>
                                                                Despatch: To Be Confirmed{del.weight && <span> | Estimated Consignment Weight: {del.weight}kg</span>}
                                                            </Typography>
                                                            <Box pt={1}>
                                                                <Alert severity="error">
                                                                    <strong>Awaiting Allocation</strong>
                                                                    <br />
                                                                    Stock for this delivery {del.instock ? "is available but will not be picked until it is manually allocated" : "is not currently available and will require manual allocation"}
                                                                </Alert>
                                                            </Box>
                                                        </>
                                                    )) ||
                                                        (del.instock && (
                                                            <>
                                                                <Typography variant="body2" className="fw-300" gutterBottom>
                                                                    Confirmed Despatch: {moment(del.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                    {del.weight && <span> | Estimated Consignment Weight: {del.weight}kg</span>}
                                                                </Typography>
                                                                <Box pt={1}>
                                                                    <Alert severity="success">
                                                                        <strong>{del.modified === 0 && del.odStatus === "Awaiting Despatch" ? "Awaiting Despatch" : "Awaiting Picking"}</strong>
                                                                        <br />
                                                                        All items on this delivery are currently available for despatch
                                                                    </Alert>
                                                                </Box>
                                                            </>
                                                        )) || (
                                                            <>
                                                                <Typography variant="body2" className="fw-300" gutterBottom>
                                                                    Expected Despatch: {moment(del.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                    {del.weight && <span> | Estimated Consignment Weight: {del.weight}kg</span>}
                                                                </Typography>
                                                                <Box pt={1}>
                                                                    <Alert severity="warning">
                                                                        <strong>{del.shipComplete ? `Awaiting ${del?.wasInStock ? `Picking` : `Stock`} (Ship Complete)` : `Awaiting Stock`}</strong>
                                                                        <br />
                                                                        {del.shipComplete ? 
                                                                            `This delivery can only be picked after stock has been received from ${del?.shipCompletePos?.map?.(el => el.nm)?.join?.()}` 
                                                                        : 
                                                                            `Some items on this delivery are currently on back-order, this delivery will despatch once all items are received`
                                                                        }
                                                                    </Alert>
                                                                </Box>
                                                            </>
                                                        )}
                                                </>
                                            ))) ||
                                            ((del.odStatus === "Despatched" || del.odStatus === "Invoiced") && (
                                                <>
                                                    <Typography variant="body2" className="fw-300" gutterBottom>
                                                        Despatched: {momentFormatDate(del.odDespatch)}
                                                    </Typography>
                                                    <Box pt={1}>
                                                        <Alert severity="success">
                                                            <strong>{del.odStatus}</strong>
                                                            <br />
                                                            This delivery has already been fulfilled by {del.ffName}
                                                        </Alert>
                                                    </Box>
                                                </>
                                            )) || (del?.shipComplete && (
                                                <>
                                                    <Typography variant="body2" className="fw-300" gutterBottom>
                                                        Requested {origin?.formData?.collection === 1 ? "Collection Date" : "Despatch Date"}: {moment(del.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Box pt={1}>
                                                        <Alert severity='warning'>
                                                            <strong>Awaiting Stock (Ship Complete)</strong>
                                                            <br />
                                                            This delivery will be consolidated and shipped complete once goods have been received
                                                        </Alert>
                                                    </Box>
                                                </>
                                            )) || (
                                                <>
                                                    <Typography variant="body2" className="fw-300" gutterBottom>
                                                        Requested {origin?.formData?.collection === 1 ? "Collection Date" : "Despatch Date"}: {moment(del.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                    </Typography>
                                                    <Box pt={1}>
                                                        <Alert severity="success">
                                                            <strong>{origin?.shipHere && !origin?.formData?.collection ? (del.odStatus === "Awaiting Picking" ? "Awaiting Picking" : "Awaiting Stock") : "Awaiting Supplier"}</strong>
                                                            <br />
                                                            This delivery will be fulfilled by {del.ffName}
                                                        </Alert>
                                                    </Box>
                                                </>
                                            )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {del?.shipComplete ? (
                                            <Typography variant="body1" className="fw-400 mb-1 pt-1">
                                                Part 1 by {del.ffName} ({del?.products?.length ?? 0})
                                            </Typography>
                                        ) : null}                                        
                                        <Box className="content-light-grey" p={0.75} mt={1}>
                                            <DataTable
                                                config={{
                                                    alternatingRowColours: false,
                                                    noRowHover: true,
                                                    responsiveImportance: true,
                                                    inline: true,
                                                    rowProps: (rowData) => ({
                                                        style: {
                                                            backgroundColor: "#fff",
                                                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                        },
                                                    }),
                                                }}
                                                columns={this.renderReviewDeliveriesColumns(del.ffType, false, del.odStatus)}
                                                rows={del.products}
                                            />
                                        </Box>
                                    </Grid>
                                    {del?.shipComplete ? (
                                        _.map(del.shipCompletePos, (sc, scIdx) => (
                                            <Grid item xs={12}>
                                                <Typography variant="body1" className="fw-400 mb-1 pt-1">
                                                    Part {scIdx + 2} by {sc.nm} ({sc?.prd?.length ?? 0})
                                                </Typography>                                                
                                                <Box className="content-light-grey" p={0.75} mt={1}>
                                                    <DataTable
                                                        config={{
                                                            alternatingRowColours: false,
                                                            noRowHover: true,
                                                            responsiveImportance: true,
                                                            inline: true,
                                                            rowProps: (rowData) => ({
                                                                style: {
                                                                    backgroundColor: "#fff",
                                                                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                                },
                                                            }),
                                                        }}
                                                        columns={this.renderReviewDeliveriesColumns("po", false, del.odStatus)}
                                                        rows={sc?.prd ?? []}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))
                                    ) : null}
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                );
            });
        } else {
            return _.map(_.filter(this.state.formData.fulfilment, el => el?.shipHere !== 2), (origin, idx) => {
                const d = _.filter(deliveries, (el) => el.ffIdx === idx);
                return (
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={2}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant="h6">{origin.name}</Typography>
                                        <Typography variant="body2" className="fw-300">
                                            {origin.type === "po" ? "Purchase Order Fulfilment" : "Warehouse Fulfilment"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box py={1}>
                                    <Divider />
                                </Box>
                                <Grid container spacing={3}>
                                    {_.map(d, (del, didx) => {
                                        return (
                                            <Grid item xs={12} key={idx}>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs>
                                                        <Typography variant="body1" className="fw-400">
                                                            Delivery {didx + 1} of {_.size(d)}
                                                        </Typography>
                                                        {(del.ffType === "wh" &&
                                                            (((del.odStatus === "Despatched" || del.odStatus === "Invoiced") && (
                                                                <>
                                                                    <Typography variant="body2" className="fw-300" gutterBottom>
                                                                        Despatched: {momentFormatDate(del.odDespatch)}
                                                                    </Typography>
                                                                    <Box pt={1}>
                                                                        <Alert severity="info">
                                                                            <strong>{del.odStatus}</strong>
                                                                            <br />
                                                                            This delivery has already been fulfilled by the warehouse
                                                                        </Alert>
                                                                    </Box>
                                                                </>
                                                            )) || (
                                                                <>
                                                                    {(!del.allocate && (
                                                                        <>
                                                                            <Typography variant="body2" className="fw-300" gutterBottom>
                                                                                Despatch: To Be Confirmed{del.weight && <span> | Estimated Consignment Weight: {del.weight}kg</span>}
                                                                            </Typography>
                                                                            <Box pt={1}>
                                                                                <Alert severity="error">
                                                                                    <strong>Awaiting Allocation</strong>
                                                                                    <br />
                                                                                    Stock for this delivery {del.instock ? "is available but will not be picked until it is manually allocated" : "is not currently available and will require manual allocation"}
                                                                                </Alert>
                                                                            </Box>
                                                                        </>
                                                                    )) ||
                                                                        (del.instock && (
                                                                            <>
                                                                                <Typography variant="body2" className="fw-300" gutterBottom>
                                                                                    Confirmed Despatch: {moment(del.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    {del.weight && <span> | Estimated Consignment Weight: {del.weight}kg</span>}
                                                                                </Typography>
                                                                                <Box pt={1}>
                                                                                    <Alert severity="success">
                                                                                        <strong>{del.odStatus === "Awaiting Despatch" ? "Awaiting Despatch" : "Awaiting Picking"}</strong>
                                                                                        <br />
                                                                                        All items on this delivery are currently available for despatch
                                                                                    </Alert>
                                                                                </Box>
                                                                            </>
                                                                        )) || (
                                                                            <>
                                                                                <Typography variant="body2" className="fw-300" gutterBottom>
                                                                                    Expected Despatch: {moment(del.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    {del.weight && <span> | Estimated Consignment Weight: {del.weight}kg</span>}
                                                                                </Typography>
                                                                                <Box pt={1}>
                                                                                    <Alert severity="warning">
                                                                                        <strong>{del.shipComplete ? `Awaiting ${del?.wasInStock ? `Picking` : `Stock`} (Ship Complete)` : `Awaiting Stock`}</strong>
                                                                                        <br />
                                                                                        {del.shipComplete ? 
                                                                                            `This delivery can only be picked after stock has been received from ${del?.shipCompletePos?.map?.(el => el.nm)?.join?.()}` 
                                                                                        : 
                                                                                            `Some items on this delivery are currently on back-order, this delivery will despatch once all items are received`
                                                                                        }
                                                                                    </Alert>
                                                                                </Box>
                                                                            </>
                                                                        )}
                                                                </>
                                                            ))) ||
                                                            ((del.odStatus === "Despatched" || del.odStatus === "Invoiced") && (
                                                                <>
                                                                    <Typography variant="body2" className="fw-300" gutterBottom>
                                                                        Despatched: {momentFormatDate(del.odDespatch)}
                                                                    </Typography>
                                                                    <Box pt={1}>
                                                                        <Alert severity="info">
                                                                            <strong>{del.odStatus}</strong>
                                                                            <br />
                                                                            This delivery has already been fulfilled by {origin.name}
                                                                        </Alert>
                                                                    </Box>
                                                                </>
                                                            )) || (origin?.shipHere === 2 && (
                                                                <>
                                                                    <Typography variant="body2" className="fw-300" gutterBottom>
                                                                        Requested {origin?.formData?.collection === 1 ? "Collection Date" : "Despatch Date"}: {moment(del.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                    </Typography>
                                                                    <Box pt={1}>
                                                                        <Alert severity='warning'>
                                                                            <strong>Awaiting Stock (Ship Complete)</strong>
                                                                            <br />
                                                                            This delivery will be consolidated and shipped complete once goods have been received
                                                                        </Alert>
                                                                    </Box>
                                                                </>
                                                            )) || (
                                                                <>
                                                                    <Typography variant="body2" className="fw-300" gutterBottom>
                                                                        Requested {origin?.formData?.collection === 1 ? "Collection Date" : "Despatch Date"}: {moment(del.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                    </Typography>
                                                                    <Box pt={1}>
                                                                        <Alert severity="success">
                                                                            <strong>{origin?.shipHere && !origin?.formData?.collection ? (del.odStatus === "Awaiting Picking" ? "Awaiting Picking" : "Awaiting Stock") : "Awaiting Supplier"}</strong>
                                                                            <br />
                                                                            This delivery will be fulfilled by {origin.name}
                                                                        </Alert>
                                                                    </Box>
                                                                </>
                                                            )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {del?.shipComplete ? (
                                                            <Typography variant="body1" className="fw-300 mb-1">
                                                                Warehouse ({del?.products?.length ?? 0})
                                                            </Typography>
                                                        ) : null}
                                                        <Box className="content-light-grey" p={0.75} mt={1}>
                                                            <DataTable
                                                                config={{
                                                                    alternatingRowColours: false,
                                                                    noRowHover: true,
                                                                    responsiveImportance: true,
                                                                    inline: true,
                                                                    rowProps: (rowData) => ({
                                                                        style: {
                                                                            backgroundColor: "#fff",
                                                                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                                        },
                                                                    }),
                                                                }}
                                                                columns={this.renderReviewDeliveriesColumns(del.ffType, false, del.odStatus)}
                                                                rows={del.products}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {del?.shipComplete ? (
                                                        _.map(del.shipCompletePos, (sc, scIdx) => (
                                                            <Grid item xs={12}>
                                                                <Typography variant="body1" className="fw-300 mb-1">
                                                                    Part {scIdx + 2} by {sc.nm} ({sc?.prd?.length ?? 0})
                                                                </Typography>
                                                                <Box className="content-light-grey" p={0.75} mt={1}>
                                                                    <DataTable
                                                                        config={{
                                                                            alternatingRowColours: false,
                                                                            noRowHover: true,
                                                                            responsiveImportance: true,
                                                                            inline: true,
                                                                            rowProps: (rowData) => ({
                                                                                style: {
                                                                                    backgroundColor: "#fff",
                                                                                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                                                },
                                                                            }),
                                                                        }}
                                                                        columns={this.renderReviewDeliveriesColumns("po", false, del.odStatus)}
                                                                        rows={sc?.prd ?? []}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        ))
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                );
            });
        }
    };

    renderReviewDeliveriesColumns = (type, poReview = false, odStatus = null, f = null) => {
        const cols = [];

        cols.push(
            {
                heading: "Product",
                field: (rowData) => (rowData?.parent > 0 ? <FAIcon type="thin" icon="arrow-turn-down-right" size={40} noMargin /> : <Avatar variant="square" alt={rowData.code} src={(rowData.img && `${CLOUDFRONT_URL}${rowData.img}`) || logo} />),
                sizeToContent: true,
            },
            {
                heading: "",
                field: (rowData) => (
                    <Box pt={type === "wh" ? 0.5 : 0.5} pb={type === "wh" ? 0.5 : 0.5}>
                        <Typography variant="body2" className="fw-400">
                            {rowData.code}
                        </Typography>
                        <Typography variant="caption">
                            {(rowData?.bundleId && (
                                <>
                                    {rowData?.name?.split?.("\n").map((item, key) => (
                                        <Typography key={key} variant="caption" component="div" className={isEven(key) ? `fw-400` : undefined} gutterBottom={isOdd(key)}>
                                            {item.substr(0, 65)}
                                            {item.length > 65 ? "..." : ""}
                                        </Typography>
                                    ))}
                                </>
                            )) || (
                                <>
                                    {rowData.name.substr(0, 65)}
                                    {rowData.name.length > 65 ? "..." : ""}
                                </>
                            )}
                        </Typography>
                        {rowData.shipComplete ? (
                            <Box pt={0.5}>
                            <Typography variant="caption" className="fw-400">
                                <Box display="inline-block" pr={0.5}>
                                    <FAIcon icon="info-circle" className="textSuccess" noMargin size={13.5} />
                                </Box>
                                Ship complete from supplier
                            </Typography>
                        </Box>
                        ) : null}
                        {!_.isEmpty(rowData.lineText) && (
                            <Box pt={0.5}>
                                <Typography variant="body2" className="fw-400">
                                    <Box display="inline-block" pr={0.5}>
                                        <FAIcon icon="exclamation-circle" type="solid" className="textError" noMargin size={13.5} />
                                    </Box>
                                    {rowData.lineText}
                                </Typography>
                            </Box>
                        )}
                        {(rowData?.bundleId && (
                            <>
                                {((rowData?.lineStatus === "Despatched" || rowData?.lineStatus === "Invoiced") && (
                                    <Box pt={0.5}>
                                        <Typography variant="body2" className="fw-400">
                                            <Box display="inline-block" pr={0.5}>\sales\cancelled
                                                <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                            </Box>
                                            This line has been {rowData.lineStatus.toLowerCase()}
                                        </Typography>
                                    </Box>
                                )) ||
                                    (rowData?.qtyChanged === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                {(rowData.fulfil === "wh" && ((rowData?.wasPicked === 1 && (rowData?.bundleId ? `This bundle must be re-picked` : `This line must be re-picked as the quantity has changed`)) || `The quantity has changed for this item`)) ||
                                                    `This line must be re-submitted as the quantity has changed`}
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData.fulfil === "wh" && this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.locked === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="body2" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This {rowData?.bundleId ? "bundle" : "line"} has been picked, unlock the delivery to modify
                                            </Typography>
                                        </Box>
                                    ))}
                            </>
                        )) || (
                            <>
                                {((rowData?.lineStatus === "Despatched" || rowData?.lineStatus === "Invoiced") && (
                                    <Box pt={0.5}>
                                        <Typography variant="caption" className="fw-400">
                                            <Box display="inline-block" pr={0.5}>
                                                <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                            </Box>
                                            This line has been {rowData.lineStatus.toLowerCase()}
                                        </Typography>
                                    </Box>
                                )) ||
                                    (type === "wh" && rowData?.noA === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="caption" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                Not enough stock available or on order to allocate this line
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (rowData?.qtyChanged === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="caption" className="fw-400 textError">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                {(rowData.fulfil === "wh" && ((rowData?.wasPicked === 1 && (rowData?.bundleId ? `This bundle must be re-picked` : `This line must be re-picked as the quantity has changed`)) || `The quantity has changed for this item`)) ||
                                                    `This line must be re-submitted as the quantity has changed`}
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (type === "wh" && this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.locked === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="caption" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This {rowData?.bundleId ? "bundle" : "line"} has been picked, unlock the delivery to modify
                                            </Typography>
                                        </Box>
                                    )) ||
                                    (type === "po" && this.state.formData.fulfilment[rowData.fulfilment]?.deliveries[rowData?.delivery]?.locked === 1 && (
                                        <Box pt={0.5}>
                                            <Typography variant="caption" className="fw-400">
                                                <Box display="inline-block" pr={0.5}>
                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                </Box>
                                                This line has been submitted, unlock the delivery to modify
                                            </Typography>
                                        </Box>
                                    ))}
                                {poReview && rowData.reqInHouseProg === 1 && (
                                    <Box>
                                        <InsightChip color="#ef3340" icon="exclamation-triangle" label={`Requires in-house programming`} />
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>
                ),
                important: true,
                main: true,
                truncate: true,
            },
            {
                heading: (
                    <>
                        <div align="center">Unit</div>
                        <div align="center">Sell Price</div>
                    </>
                ),
                field: (rowData) => rowData.subTotal,
                tooltip: (rowData) => `Sold at £${rowData.subTotal} per unit`,
                fieldFormat: "currency",
                alignment: "center",
                sizeToContent: true,
                cellProps: (rowData) => ({
                    style: {
                        color: rowData?.parent !== 0 ? "rgba(0,0,0,0.6)" : undefined,
                        fontStyle: rowData?.parent !== 0 ? "italic" : undefined,
                        fontSize: rowData?.parent !== 0 ? "0.8rem" : undefined,
                    },
                }),
            },
            {
                heading: (
                    <>
                        <div align="center">Quantity</div>
                        <div align="center">{odStatus !== "Invoiced" && odStatus !== "Despatched" ? `Required` : ``}</div>
                    </>
                ),
                field: (rowData) => (
                    <Tooltip placement="right" title={`${rowData.qty} Quantity Required`}>
                        <span>
                            <Highlight variant={rowData?.srv === 1 || rowData.bundleId ? "success" : type === "po" ? "success" : rowData.oos ? "error" : "success"}>{rowData.qty}</Highlight>
                        </span>
                    </Tooltip>
                ),
                alignment: "center",
                sizeToContent: true,
            }
        );

        if (type === "wh" && odStatus !== "Invoiced" && odStatus !== "Despatched") {
            cols.push(
                {
                    heading: (
                        <>
                            <div align="center">Stock</div>
                            <div align="center">Available</div>
                        </>
                    ),
                    field: (rowData) =>
                        ((rowData.shipComplete || rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                            <Tooltip placement="right" title={`${rowData.stAv ?? 0} Stock Available`}>
                                <span>
                                    <Highlight variant={rowData.oos ? "error" : "success"}>{rowData.stAv ?? 0}</Highlight>
                                </span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                },
                {
                    heading: (
                        <>
                            <div align="center">Stock</div>
                            <div align="center">Remaining</div>
                        </>
                    ),
                    field: (rowData) =>
                        ((rowData.shipComplete || rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                            <Tooltip placement="right" title={`${rowData.stRe ?? 0} Stock Remaining`}>
                                <span>
                                    <Highlight variant={rowData.oos ? "error" : "success"}>{rowData.stRe ?? 0}</Highlight>
                                </span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                },
                {
                    heading: (
                        <>
                            <div align="center">On Order</div>
                            <div align="center">Available</div>
                        </>
                    ),
                    field: (rowData) =>
                        ((rowData.shipComplete || rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                            <Tooltip placement="right" title={`${rowData.onAv ?? 0} Stock On Order`}>
                                <span>
                                    <Highlight variant={rowData.oos ? "error" : "success"}>{rowData.onAv ?? 0}</Highlight>
                                </span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                },
                {
                    heading: (
                        <>
                            <div align="center">On Order</div>
                            <div align="center">Remaining</div>
                        </>
                    ),
                    field: (rowData) =>
                        ((rowData.shipComplete || rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced" || rowData?.bundleId || rowData?.srv === 1) && `-`) || (
                            <Tooltip placement="right" title={`${rowData?.onRe ?? 0} Stock On Order`}>
                                <span>
                                    <Highlight variant={rowData.oos ? "error" : "success"}>{rowData?.onRe ?? 0}</Highlight>
                                </span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                },
                {
                    heading: "Unit Weight",
                    field: (rowData) =>
                        rowData?.bundleId ? (
                            "-"
                        ) : (
                            <Tooltip placement="right" title={`${rowData.weight}kg Weight`}>
                                <span>{rowData.weight}kg</span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                },
                {
                    heading: "Total Weight",
                    field: (rowData) =>
                        rowData?.bundleId ? (
                            "-"
                        ) : (
                            <Tooltip placement="right" title={`${(parseInt(rowData.qty) * parseFloat(rowData.weight)).toFixed(2)}kg Weight`}>
                                <span>{(parseInt(rowData.qty) * parseFloat(rowData.weight)).toFixed(2)}kg</span>
                            </Tooltip>
                        ),
                    alignment: "right",
                    sizeToContent: true,
                }
            );
        }

        if (poReview) {
            cols.push(
                {
                    heading: "Buy Price",
                    field: (rowData) => (
                        <Box width={150}>
                            <TextField
                                variant="filled"
                                value={rowData.cost}
                                type="number"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                disabled
                            />
                        </Box>
                    ),
                    sizeToContent: true,
                },
                {
                    heading: "Special Buy Price",
                    field: (rowData) => (
                        <Box width={150}>
                            <TextField
                                variant="filled"
                                value={rowData.buyPrice}
                                onChange={(e) => this.handleBuyPriceChange(rowData.lineIdx, e?.target?.value)}
                                onBlur={(e) => this.handleBuyPriceFloat(rowData.lineIdx)}
                                type="number"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                disabled={f?.locked === 1 || rowData.lineStatus === "Despatched" || rowData.lineStatus === "Invoiced"}
                            />
                        </Box>
                    ),
                    sizeToContent: true,
                },
                {
                    heading: "Total Buy Price",
                    field: (rowData) => (
                        <Box width={150}>
                            <TextField
                                variant="filled"
                                value={(parseInt(rowData.qty) * parseFloat(rowData.buyPrice)).toFixed(2)}
                                type="number"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                disabled
                            />
                        </Box>
                    ),
                    sizeToContent: true,
                }
            );
        }
        cols.push({
            actions: (rowData) => {
                return [{ name: "View Product", icon: "binoculars", disabled: rowData.bundleId, onClick: () => this.handleProductInfoOpen(rowData.id) }];
            },
        });

        return cols;
    };

    handleProductInfoOpen = (productId) => {
        this.props.deployDialog(<ViewProduct id={productId} inDialog />, false, "Product Details", "standard", "xl", true);
    };

    handleProcessingOptionChange = (e) => {
        this.setState({
            processingOption: e?.target?.value ?? null,
        });
    };

    handleProcessingOptionConfirmation = () => {
        const { processingOption } = this.state;

        const { deployConfirmation } = this.props;

        let msg;

        switch (processingOption) {
            case "SHIP":
                msg = (
                    <>
                        <Typography variant="body2" className="fw-400">
                            You are about to process this sales order as a warehouse shipment
                            <br />→ Despatching today from Advanced Access
                        </Typography>
                        <Typography variant="body2">Are you sure you want to continue?</Typography>
                    </>
                );
                break;
            case "WAIT":
                msg = (
                    <>
                        <Typography variant="body2" className="fw-400">
                            You are about to process this sales order as a warehouse shipment
                            <br />→ Despatching when stock is available from Advanced Access
                        </Typography>
                        <Typography variant="body2">Are you sure you want to continue?</Typography>
                    </>
                );
                break;
            case "PO":
                msg = (
                    <>
                        <Typography variant="body2" className="fw-400">
                            You are about to fulfil this sales order by generating a new purchase order.
                            <br />→ {this.state.suppliers[0]?.shipHere === 1 ? `Shipping here` : `Despatching directly`} from {this.state.suppliers[0]?.name}
                        </Typography>
                        <Typography variant="body2">Are you sure you want to continue?</Typography>
                    </>
                );
                break;
            default:
                this.handleProcessingOptionSelection();
                return;
        }

        deployConfirmation(msg, this.handleProcessingOptionSelection);
    };

    handleProcessingOptionSelection = () => {
        const { handleTabChange, handleProcessingTypeChange } = this.props;

        const { processingOption } = this.state;

        switch (processingOption) {
            case "SHIP":
                this.handleEmailConfirmation();
                break;

            case "WAIT":
                this.handleEmailConfirmation();
                break;

            case "PO":
                let lines = [...this.state.formData.lines],
                    origins = this.state.origins;

                lines?.forEach?.((line, idx) => {
                    lines[idx] = {
                        ...lines[idx],
                        delivery: null,
                        fulfil: "po",
                        fulfilment: origins[lines[idx].supplierId],
                    };
                });

                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            lines,
                        },
                    },
                    () => {
                        this.handleGenerateDeliveries(() => this.handleEmailConfirmation());
                    }
                );

                break;
            default:
                handleTabChange?.(2);
                handleProcessingTypeChange?.("MANUAL");
                break;
        }
    };

    renderReviewDeliveriesView = (reviewViewAll) => {
        this.setState({
            reviewViewAll,
        });
    };

    renderReviewPurchaseOrders = () => {
        const {
            formData: { fulfilment },
            origins,
        } = this.state;
        const ff = _.sortBy(
            _.filter(
                fulfilment,
                (el) => el.type === "po",
                (_el) => _.el.name
            )
        );       

        if (!_.isEmpty(ff)) {
            return _.map(ff, (f, fidx) => {
                const shipCompleteOptions = [];
                if(typeof origins.wh !== "undefined") {
                    _.each(fulfilment?.[origins.wh]?.deliveries, (od, idx) => {
                        shipCompleteOptions.push({
                            label: `Warehouse - Delivery ${idx + 1}`,
                            disabled: od?.odStatus === "Invoiced" || od?.odStatus === "Despatched",
                            text: `Existing Delivery`,
                            value: `0:${idx}`
                        })
                    });
                } else {
                    _.each(_.filter(fulfilment, el => el?.supplier?.id !== f?.supplier?.id), (f) => {
                        if(f.type === "po" && f.shipHere === 1) {
                            _.each(f.deliveries, (od, idx) => {
                                shipCompleteOptions.push({
                                    label: `${f.name} - Delivery ${idx + 1}`,
                                    disabled: od?.odStatus === "Invoiced" || od?.odStatus === "Despatched",
                                    text: `Existing Delivery`,
                                    value: `${f?.supplier?.id}:${idx}`
                                })
                            });
                        }
                    });
                }
                return (
                    <Grid item key={fidx} xs={12}>
                        <Paper>
                            <Box p={2}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h6">{f.name}</Typography>
                                        {(_.every(
                                            _.filter(this.state.formData.lines, (el) => el.fulfilment === origins?.[f.supplier?.id]),
                                            (el) => el.lineStatus === "Invoiced"
                                        ) && (
                                            <Box>
                                                <Typography variant="caption">
                                                    <Box display="inline-block" pr={0.5}>
                                                        <FAIcon icon="exclamation-circle" className="textSuccess" noMargin size={13.5} />
                                                    </Box>
                                                    This purchase order is complete
                                                </Typography>
                                            </Box>
                                        )) ||
                                            (f.locked === 1 && (
                                                <Box pb={0.5}>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs>
                                                            <Typography variant="caption">
                                                                <Box display="inline-block" pr={0.5}>
                                                                    <FAIcon icon="exclamation-circle" className="textWarning" noMargin size={13.5} />
                                                                </Box>
                                                                This purchase order has been submitted, please unlock it to modify any details (this will require re-confirmation)
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                onClick={() =>
                                                                    this.props.deployConfirmation(
                                                                        <Box textAlign="center">
                                                                            <Box p={3} textAlign="center">
                                                                                <FAIcon icon="exclamation-circle" type="thin" className="textError" size={60} noMargin />
                                                                            </Box>
                                                                            <Typography variant="h4" className="fw-400 textError" paragraph>
                                                                                Warning - PO {this.state.purchaseOrders?.[origin.supplier?.id]?.status}
                                                                            </Typography>
                                                                            <Typography variant="body2" className="fw-400" paragraph>
                                                                                This purchase order has previously been submitted to the supplier
                                                                            </Typography>
                                                                            <Typography variant="body2" className="fw-400" paragraph>
                                                                                If you unlock it to make changes then you will need to re-submit it to the supplier and follow the confirmation process
                                                                            </Typography>
                                                                        </Box>,
                                                                        () => this.handleUnlockPurchaseOrder(origins?.[f.supplier?.id], f.supplier?.id),
                                                                        undefined,
                                                                        "Unlock",
                                                                        "Cancel"
                                                                    )
                                                                }
                                                                variant="contained"
                                                                className="btn btn-warning"
                                                            >
                                                                <FAIcon icon="check" button />
                                                                Modify
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            )) || (
                                                <Box>
                                                    <Typography variant="caption">
                                                        <Box display="inline-block" pr={0.5}>
                                                            <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                        </Box>
                                                        This purchase order is {_.isEmpty(this.state.purchaseOrders?.[f.supplier?.id]) ? "new to fulfil this sales order" : "currently in progress"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            
                                        <Box className="content-light-grey" p={0.75} mt={1}>
                                            <DataTable
                                                config={{                                            
                                                    alternatingRowColours: false,
                                                    noRowHover: true,
                                                    responsiveImportance: true,
                                                    inline: true,
                                                    rowProps: () => ({
                                                        style: {
                                                            backgroundColor: "#fff",
                                                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                        },
                                                    }),
                                                }}
                                                columns={this.renderReviewDeliveriesColumns("po", true, false, f)}
                                                rows={_.filter(this.state.formData.lines, (el) => el.fulfilment === origins?.[f.supplier?.id])}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Typography variant="h6" className="fw-300">
                                                Options
                                            </Typography> */}
                                        <Grid container spacing={3}>
                                            {/* {(f.formData?.collection === 1 && (
                                                    <Grid item xs={12} className={'pt-0 pb-0'}>
                                                        <Alert variant="outlined" severity="info">
                                                            <strong>Direct Collection -</strong> This purchase order will be collected by the customer directly from {f.name}
                                                        </Alert>
                                                    </Grid>
                                                )) || (f.shipHere === 1 && this.state.delivery?.courier_conf_collection === 1 && (
                                                    <Grid item xs={12} className={'pt-0 pb-0'}>
                                                        <Alert variant="outlined" severity="info">
                                                            <strong>Ship Here -</strong> This purchase order will be shipped here for collection by the customer
                                                        </Alert>
                                                    </Grid>
                                                )) || (f.shipHere === 1 && (
                                                    <Grid item xs={12} className={'pt-0 pb-0'}>
                                                        <Alert variant="outlined" severity="info">
                                                            <strong>Ship Here -</strong> This purchase order will be shipped here for onward shipping to the customer
                                                        </Alert>
                                                    </Grid>
                                                )) || (
                                                    (f.supplier?.shipHere === 1 && (
                                                        <Grid item xs={12} className={'pt-0 pb-0'}>
                                                            <Alert variant="outlined" severity="warning">
                                                                <strong>Warning -</strong> You have selected direct despatch for a <em>ship here</em> supplier - please check {f?.supplier?.name} will accept this before confirming
                                                            </Alert>
                                                        </Grid>
                                                    )) || (
                                                        
                                                    )
                                                )} */}
                                            <Grid item xs={12} lg={3}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1} alignItems="center" className="pb-1">
                                                            <Grid item>
                                                                <Typography variant="caption" color="textSecondary" component="div" className="fw-300">
                                                                    {f?.formData?.collection === 1 ? `Collection` : `Delivery`} Address
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title={`Change ${f?.formData?.collection === 1 ? `collection` : `delivery`} address`}>
                                                                    <IconButton
                                                                        disabled={f?.locked === 1 || (f?.shipHere >= 1 && f?.formData?.collection === 0)}
                                                                        onClick={() => this.props.deployConfirmation(`Are you sure you want to modify the ${f?.formData?.collection === 1 ? `collection` : `delivery`} address?`, () => this.handlePurchaseOrderCustomAddress(origins?.[f.supplier?.id]))}
                                                                        size="small"
                                                                    >
                                                                        <FAIcon icon="pencil" disabled={f?.locked === 1 || (f?.shipHere >= 1 && f?.formData?.collection === 0)} noMargin button size={15} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                        {_.map(
                                                            _.map([f.formData?.deliveryAddress?.addressOne, f.formData?.deliveryAddress?.addressTwo, f.formData?.deliveryAddress?.addressThree, f.formData?.deliveryAddress?.town, f.formData?.deliveryAddress?.county, f.formData?.deliveryAddress?.postcode], (row) => {
                                                                if (row?.length > 0) {
                                                                    return row;
                                                                }
                                                            }),
                                                            (line, idx) => (
                                                                <Typography variant="body2" key={idx} gutterBottom>
                                                                    {line}
                                                                </Typography>
                                                            )
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" color="textSecondary" component="div" className="pb-1">
                                                            Despatch Type *
                                                        </Typography>
                                                        <RadioGroup aria-label="Despatch Type *" onChange={(e) => this.handleCollectPurchaseOrder(origins?.[f.supplier?.id], e?.target?.value === "1" ? 1 : 0)}>
                                                            <FormControlLabel
                                                                className="mb-1"
                                                                disabled={f.locked === 1 || _.every(f?.deliveries, el => el.podStatus === 'Despatched')}
                                                                value="0"
                                                                checked={f?.formData?.collection === 0}
                                                                control={<Radio color="primary" />}
                                                                label={
                                                                    <>
                                                                        <Typography variant="body2" className="fw-400">
                                                                            Courier Shipment
                                                                        </Typography>
                                                                        <Typography variant="caption">
                                                                            <FAIcon icon="info-circle" className="mr-05" size={10} />
                                                                            The supplier will ship this purchase order via courier.
                                                                        </Typography>
                                                                    </>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                disabled={f.locked === 1 || _.every(f?.deliveries, el => el.podStatus === 'Despatched')}
                                                                value="1"
                                                                checked={f?.formData?.collection === 1}
                                                                control={<Radio color="primary" />}
                                                                label={
                                                                    <>
                                                                        <Typography variant="body2" className="fw-400">
                                                                            Collect From Supplier
                                                                        </Typography>
                                                                        <Typography variant="caption">
                                                                            <FAIcon icon="info-circle" className="mr-05" size={10} />
                                                                            The customer will collect directly from {f?.supplier?.name}.
                                                                        </Typography>
                                                                    </>
                                                                }
                                                            />
                                                        </RadioGroup>
                                                    </Grid>
                                                    {f?.formData?.collection !== 1 && (
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption" color="textSecondary" component="div" className="pb-1">
                                                                Shipment Type *
                                                            </Typography>
                                                            <RadioGroup aria-label="Shipment Type *" onChange={(e) => this.handleShipPurchaseOrderHere(origins?.[f.supplier?.id], parseInt(e?.target?.value))}>
                                                                <FormControlLabel
                                                                    className="mb-1"
                                                                    disabled={f.locked === 1 || f?.shipHereOnly === 1 || _.every(f?.deliveries, el => el.podStatus === 'Despatched')}
                                                                    value="0"
                                                                    checked={f?.shipHere === 0}
                                                                    control={<Radio color="primary" />}
                                                                    label={
                                                                        <>
                                                                            <Typography variant="body2" className="fw-400">
                                                                                Direct Despatch {f?.supplier?.shipHere === 1 ? <FAIcon icon="exclamation-triangle" className="textError mr-1" size={12} /> : ``}
                                                                            </Typography>
                                                                            <Typography variant="caption">
                                                                                <FAIcon icon="info-circle" className="mr-05" size={10} />
                                                                                Configured deliveries will be despatched direct to the delivery address on the sales order, unless changed here
                                                                                {f?.supplier?.shipHere === 1 ? ` - ship here is preferred for this supplier` : ``}.
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                />
                                                                {f?.deliveries?.length === 1 && _.size(origins) > 1 && shipCompleteOptions?.length > 0 && (
                                                                    <FormControlLabel
                                                                        className="mb-1"
                                                                        disabled={f.locked === 1 || _.every(f?.deliveries, el => el.podStatus === 'Despatched')}
                                                                        value="2"
                                                                        checked={f?.shipHere === 2}
                                                                        control={<Radio color="primary" />}
                                                                        label={
                                                                            <>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    Ship Complete
                                                                                </Typography>
                                                                                <Typography variant="caption">
                                                                                    <FAIcon icon="info-circle" className="mr-05" size={10} />
                                                                                    {shipCompleteOptions?.length > 0 ? 
                                                                                        `All lines will be consolidated into the selected delivery for a single despatch, when all stock has been received.` 
                                                                                        : 
                                                                                        `All lines will be merged into a new warehouse delivery which will be Awaiting Stock; and despatched complete when all stock is received.`
                                                                                    }
                                                                                </Typography>
                                                                            </>
                                                                        }
                                                                    />
                                                                )}
                                                                {f?.shipHere === 2 && (
                                                                    <Box my={2} style={{paddingLeft: 25, paddingRight: 25}}>
                                                                        <AutoCompleteSelect
                                                                            disabled={f.locked === 1 || shipCompleteOptions?.length === 0}
                                                                            label="Despatch With"
                                                                            onChange={(v) => this.handlePurchaseOrderChange(origins?.[f.supplier?.id], "shipComplete", v?.value ?? v ?? 0)}
                                                                            options={shipCompleteOptions}
                                                                            formatOptionLabel={({ label, text }) => (
                                                                                <Box pt={0.5} pb={0.5}>
                                                                                    <Grid container spacing={1} alignItems="center">
                                                                                        <Grid item align="center">
                                                                                            <FAIcon icon="shipping-fast" className="ml-1 mr-1" size={25} noMargin button />
                                                                                        </Grid>
                                                                                        <Grid item xs>
                                                                                            <Typography variant="body2" className="fw-400">
                                                                                                {label}
                                                                                            </Typography>
                                                                                            <Typography variant="caption" component="div">
                                                                                                {text}                                                                              
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            )}
                                                                            value={f.formData?.shipComplete}
                                                                            placeholder={shipCompleteOptions?.length === 0 ? `Unavailable` : `Please select a delivery`}
                                                                            noDefaultSort
                                                                            noClear
                                                                            variant="outlined"
                                                                        />
                                                                    </Box>
                                                                )}
                                                                <FormControlLabel
                                                                    className="mb-1"
                                                                    disabled={f.locked === 1 || _.every(f?.deliveries, el => el.podStatus === 'Despatched')}
                                                                    value="1"
                                                                    checked={f?.shipHere === 1}
                                                                    control={<Radio color="primary" />}
                                                                    label={
                                                                        <>
                                                                            <Typography variant="body2" className="fw-400">
                                                                                Ship Here
                                                                            </Typography>
                                                                            <Typography variant="caption">
                                                                                <FAIcon icon="info-circle" className="mr-05" size={10} />
                                                                                Configured deliveries will be shipped here and forwarded onto the customer when stock is received.
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={5}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={6}>
                                                        <Typography variant="caption" color="textSecondary" component="div" className="pb-1">
                                                            Delivery Method *
                                                        </Typography>
                                                        <AutoCompleteSelect
                                                            disabled={f.locked === 1}
                                                            onChange={(v) => this.handlePurchaseOrderChange(origins?.[f.supplier?.id], "carriage", v?.value ?? v ?? 0)}
                                                            error={f.formData?.carriage?.length === 0}
                                                            options={f.supplier?.delivery}
                                                            value={f.formData?.carriage}
                                                            noDefaultSort
                                                            noClear
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <Typography variant="caption" color="textSecondary" component="div" className="pb-1">
                                                            Supplier Reference
                                                        </Typography>
                                                        <TextField
                                                            disabled={f.locked === 1}
                                                            margin="none"
                                                            onChange={(e) => this.handlePurchaseOrderChange(origins?.[f.supplier?.id], "suppRef", e?.target?.value ?? "")}
                                                            value={f.formData?.suppRef}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    height: 12,
                                                                },
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" color="textSecondary" component="div" className="pb-1">
                                                            Notes
                                                        </Typography>
                                                        <Textarea
                                                            disabled={f.locked === 1}
                                                            margin="none"
                                                            onChange={(e) => this.handlePurchaseOrderChange(origins?.[f.supplier?.id], "notes", e?.target?.value ?? "")}
                                                            rows={4}
                                                            value={f.formData?.notes}
                                                            variant="outlined"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <Typography className="pt-1" variant="caption" color={f.locked ? `textSecondary` : `error`} paragraph>
                                                            <FAIcon type="solid" icon="info-circle" size={11} noMargin /> These notes will be visible to the supplier
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                )
            });
        } else {
            return <React.Fragment />;
        }
    };

    /*
     * Submission
     */
    handleEmailConfirmation = () => {
        const { deployConfirmation, order } = this.props;

        if (!_.isEmpty(order.order_email) && order.order_no_emails === 0) {
            const yes = () => {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            sendConfirmation: 1,
                        },
                    },
                    () => {
                        this.handleSubmit();
                    }
                );
            };

            const no = () => this.handleSubmit();

            deployConfirmation(
                <>
                    <Box align="center" pb={1.5}>
                        <FAIcon icon="exclamation-circle" className="textError" size={80} noMargin />
                    </Box>
                    <Typography variant="h6" className="fw-400" align="center" gutterBottom>
                        Order Confirmation
                    </Typography>
                    <Typography variant="body2" className="fw-400" align="center" gutterBottom>
                        Do you want to e-mail an order confirmation to the customer?
                    </Typography>
                    <Typography variant="caption" component="div" className="fw-400" align="center">
                        {order.order_email}
                    </Typography>
                </>,
                yes,
                no,
                "Yes",
                "No"
            );
        } else {
            this.handleSubmit();
        }
    };

    handleSubmit = () => {
        let isLoading = true,
            { formData } = this.state,
            { access } = this.props;

        this.setState(
            {
                isLoading,
            },
            () => {
                API.post(`/sales/${this.props.id}/processing`, formData).then((result) => {
                    if (result?.data) {
                        if (result?.data?.processing) {
                            this.props.deploySnackBar("error", "This order is still finishing a previous processing request, please try again shortly and if problems persist, contact Crushed Ice");
                            this.props.history.push(`/sales/${this.props.id}`);
                        } else if(result?.data?.completed) {
                            this.props.deploySnackBar("error", "This order has already been processed by another user");
                            this.props.callback();
                        } else if (result?.data?.errors) {
                            this.props.deploySnackBar("error", "This order could not be processed due to recent processing changes and/or stock availability issues, please review and try again");
                            this.loadComponentData();
                        } else {
                            this.props.processingComplete();

                            if ((!access.poSubmission || _.isEmpty(result.data.po)) && _.isEmpty(result.data.cancelPo)) {
                                this.handleFinishedProcessing();
                            } else {
                                isLoading = false;

                                let po = [],
                                    cancelPo = result.data.cancelPo;

                                if (!_.isEmpty(result.data.po)) {
                                    _.each(result.data.po, (p) => {
                                        po.push({
                                            id: p.id,
                                            ref: p.ref,
                                            supp: p.supp,
                                            email: p.email,
                                            pdf: p.pdf,
                                            net: p.net,
                                            gross: p.gross,
                                            submitted: 0,
                                            disabled: 0,
                                        });
                                    });
                                }

                                this.setState({
                                    completed: {
                                        ...this.state.completed,
                                        state: true,
                                        po,
                                        cancelPo,
                                    },
                                    isLoading,
                                });
                            }
                        }
                    }
                });
            }
        );
    };

    handleFinishedProcessing = () => {
        this.props.deploySnackBar?.("success", "This sales order has been successfully processed");
        this.props.history.push(`/sales/${this.props.order?.order_id}`);
    };

    /*
     * Post Processing - Next Steps Methods
     */
    // handleDeploySendEmail = () => {

    //     const { order } = this.props;

    //     let defaultFormData = {
    //         emailSubject: `Advanced Access | Order #${order.order_reference}/${order.order_revision}${!_.isEmpty(order.order_customer_reference) ? ` (${order.order_customer_reference})` : ''}`,
    //         emailTo: {
    //             label: order.order_email,
    //             value: order.order_email
    //         }
    //     }

    //     this.props.deployDialog(
    //         <EmailDialog
    //             callback={this.handleSetEmailSent}
    //             defaultFormData={defaultFormData}
    //             id={order.order_id}
    //             success='The order confirmation has been successfully sent'
    //             type='order'
    //         />, false, "Send Order Confirmation", "standard","md"
    //     );
    // }

    handleSetEmailSent = () => {
        this.setState({
            completed: {
                ...this.state.completed,
                email: 1,
            },
        });
    };

    handleDeployPurchaseOrderSubmission = (p, idx) => {
        let po = [...(this.state.completed?.po ?? [])];
        if (po[idx].disabled === 0) {
            this.setState(
                {
                    completed: {
                        ...this.state.completed,
                        po,
                    },
                },
                () => {
                    API.get(`/purchaseOrders/${p.id}`, {
                        props: {
                            noLoading: true,
                        },
                        params: {
                            withDefaultDate: true,
                        },
                    }).then((res) => {
                        if (res?.data) {
                            this.props.deployDialog(
                                <AwaitingSubmission
                                    id={p.id}
                                    callback={() => {
                                        this.props.closeDialog();
                                        this.handleSetPoSubmitted(idx);
                                    }}
                                    currentTab={4}
                                    deployConfirmation={this.props.deployConfirmation}
                                    deploySnackBar={this.props.deploySnackBar}
                                    po={res.data}
                                    showDeliveryConfiguration={po.po_type === "Stock" && po.po_ship_here_order === 0 && _.isEmpty(res.data.deliveries)}
                                />,
                                false,
                                `Submit Purchase Order #${p.ref} to ${p.supp}`,
                                "standard",
                                "lg",
                                true
                            );
                        }
                    });
                }
            );
        }
    };

    handleSetPoSubmitted = (idx) => {
        let po = [...(this.state.completed?.po ?? [])];
        po[idx].submitted = 1;
        this.setState({
            completed: {
                ...this.state.completed,
                po,
            },
        });
    };

    handleCancelPo = (idx) => {
        let cancelPo = [...(this.state.completed?.cancelPo ?? [])];
        cancelPo[idx].cancelled = 1;
        this.setState({
            completed: {
                ...this.state.completed,
                cancelPo,
            },
        });
    };

    /*
     * Render - Main Component
     */
    render = () => {
        const { access, currentTab, deployConfirmation, order } = this.props;
        const { completed, formData, isLoading, origins, reviewViewAll, purchaseOrders, processingOption, reprocessing, suppliers } = this.state;

        /* PO Minimum Order Value Alert on Tab 2 */
        let poMovAlert = 0;
        if (currentTab === 2) {
            _.each(suppliers, (supp) => {
                if (supp.mov !== "0.00") {
                    if (parseFloat(formData?.fulfilment?.[origins?.[supp.id]]?.totalCost) < parseFloat(supp.mov)) {
                        poMovAlert++;
                    }
                }
            });
        }

        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) ||
                    (completed?.state === true && (
                        <>
                            <Grid item xs={12} className="pt-1 pb-0">
                                <Typography variant="h6">Next Steps</Typography>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Tooltip title='Send order confirmation now'>
                                                    <IconButton 
                                                        disabled={completed.email === 1}
                                                        onClick={this.handleDeploySendEmail}
                                                    >
                                                        <FAIcon icon={completed.email === 0 ? 'circle' : 'check-circle'} className={completed.email === 0 ? 'textError' : 'textSuccess'} size={35} button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" className="fw-400">
                                                    Send order confirmation via e-mail to the customer
                                                </Typography>
                                                <Typography variant="body2">
                                                    {order.order_email ?? 'E-mail address unknown'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid> */}
                            {access.poSubmission &&
                                _.map(completed.po, (p, idx) => (
                                    <Grid item xs={12} key={idx}>
                                        <Paper>
                                            <Box p={3}>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <Tooltip title="Submit purchase order now">
                                                            <IconButton disabled={p.disabled === 1} onClick={() => this.handleDeployPurchaseOrderSubmission(p, idx)}>
                                                                <FAIcon icon={p.submitted === 0 ? "circle" : "check-circle"} className={p.submitted === 0 ? "textError" : "textSuccess"} size={35} button noMargin />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="body2" className="fw-400">
                                                            Submit Purchase Order #{p.ref} to {p.supp}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Net Total: {currencyFormat.format(p.net)} | Gross Total: {currencyFormat.format(p.gross)} |{" "}
                                                            <Link href={`${CLOUDFRONT_URL}${p.pdf}`} className="textDefault" target="_blank">
                                                                <FAIcon icon="file-pdf" size={13.5} button noMargin className="textError" /> View PDF
                                                            </Link>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            {!_.isEmpty(completed.cancelPo) &&
                                _.map(completed.cancelPo, (p, idx) => (
                                    <Grid item xs={12} key={idx}>
                                        <Paper>
                                            <Box p={3}>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <IconButton disabled={p.cancelled === 1} onClick={() => this.handleCancelPo(idx)}>
                                                            <FAIcon icon={p.cancelled === 0 ? "circle" : "check-circle"} className={p.cancelled === 0 ? "textError" : "textSuccess"} size={35} button noMargin />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="body2" className="fw-400">
                                                            Cancel Purchase Order #{p.ref} for {p.supp}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <FAIcon icon="phone" size={13.5} button noMargin /> {p.phone} |{" "}
                                                            <Link href={`mailto:${p.email}`} className="textDefault" target="_blank">
                                                                <FAIcon icon="envelope" size={13.5} button noMargin /> {p.email}
                                                            </Link>{" "}
                                                            |{" "}
                                                            <Link href={`${CLOUDFRONT_URL}${p.pdf}`} className="textDefault" target="_blank">
                                                                <FAIcon icon="file-pdf" size={13.5} button noMargin className="textError" /> View PDF
                                                            </Link>
                                                        </Typography>
                                                    </Grid>
                                                    {!_.isEmpty(p.podCancel) && (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Box pb={1}>
                                                                    <Divider />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" component="div" className="fw-400" paragraph>
                                                                    The following deliveries need to be cancelled:
                                                                </Typography>
                                                                {_.map(p.podCancel, (pc, pidx) => (
                                                                    <Typography variant="body2" component="div" key={pidx}>
                                                                        - Delivery #{p.ref}/{pc.idx} ({pc.req ? `Requested Despatch: ${momentFormatDate(pc.req)})` : `Expected Despatch: ${momentFormatDate(pc.exp)})`}
                                                                    </Typography>
                                                                ))}
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            <Grid item xs={12} align="right">
                                <Button color="primary" onClick={this.handleFinishedProcessing} variant="contained">
                                    <FAIcon icon="check" size="small" buttonPrimary />
                                    Continue
                                </Button>
                            </Grid>
                        </>
                    )) ||
                    (currentTab === 0 && (
                        <Grid item xs={12}>
                            <ViewNotes heading={<Typography variant="h6">Sales Order Notes</Typography>} highlightAfter={order.order_processed_datetime !== "0000-00-00 00:00:00" ? order.order_processed_datetime : null} highlightAfterText={`**** This note was added after this order was last processed / fulfilment changed ****`} notes={order.notes} hideDeleteButton />
                        </Grid>
                    )) ||
                    (currentTab === 1 && (
                        <Grid item xs={12}>
                            <OrderSummary order={order} access={{processOrder: true}} callback={this.props.callback} />
                        </Grid>
                    )) ||
                    (currentTab === 5 && (
                        <Grid item xs={12}>
                            <Box maxWidth={669}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant="h6">Processing Option</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box mr={1}>
                                            <RadioGroup aria-label="Fulfilment Type *" name="processingOption" onChange={this.handleProcessingOptionChange}>
                                                <FormControlLabel
                                                    disabled={_.size(formData.fulfilment) > 1 || formData.fulfilment[0]?.type !== "wh" || _.size(formData.fulfilment[0]?.deliveries) > 1 || !formData.fulfilment[0]?.deliveries[0]?.instock}
                                                    value="SHIP"
                                                    checked={processingOption === "SHIP" && true}
                                                    control={<Radio color="primary" />}
                                                    label={
                                                        <Box
                                                            mb={3}
                                                            ml={3}
                                                            width={"100%"}
                                                            style={{
                                                                opacity: _.size(formData.fulfilment) > 1 || formData.fulfilment[0]?.type !== "wh" || _.size(formData.fulfilment[0]?.deliveries) > 1 || !formData.fulfilment[0]?.deliveries[0]?.instock ? 0.5 : undefined,
                                                            }}
                                                        >
                                                            <Paper
                                                                style={{
                                                                    borderRadius: 15,
                                                                    boxShadow: processingOption === `SHIP` ? "0px 2px 1px -1px rgb(239 51 64 / 100%), 0px 1px 1px 0px rgb(239 51 64 / 100%), 0px 1px 3px 0px rgb(239 51 64 / 50%)" : undefined,
                                                                }}
                                                            >
                                                                <Box p={3}>
                                                                    <Grid container spacing={3} alignItems="center">
                                                                        <Grid item>
                                                                            <Box pt={0.75} pl={0.75} textAlign="center">
                                                                                <FAIcon type="thin" icon="box-check" size={40} button noMargin />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs className="disable-select">
                                                                            <Typography variant="body1" className={`fw-400 ${processingOption === `SHIP` ? `textError` : ``}`} gutterBottom>
                                                                                Despatch Now
                                                                            </Typography>
                                                                            <Typography variant="body2">All items are in-stock and available to despatch today from the warehouse as a single delivery</Typography>
                                                                            <Box pt={0.25}>
                                                                                <Typography variant="caption" color="textSecondary" className="fw-300">
                                                                                    <em>This option is not suitable for future dated / call off orders</em>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Paper>
                                                        </Box>
                                                    }
                                                />
                                                <FormControlLabel
                                                    disabled={_.size(formData.fulfilment) > 1 || formData.fulfilment[0]?.type !== "wh" || _.size(formData.fulfilment[0]?.deliveries) > 1 || formData.fulfilment[0]?.deliveries[0]?.instock}
                                                    value="WAIT"
                                                    checked={processingOption === "WAIT" && true}
                                                    control={<Radio color="primary" />}
                                                    label={
                                                        <Box
                                                            mb={3}
                                                            ml={3}
                                                            width={"100%"}
                                                            style={{
                                                                opacity: _.size(formData.fulfilment) > 1 || formData.fulfilment[0]?.type !== "wh" || _.size(formData.fulfilment[0]?.deliveries) > 1 || formData.fulfilment[0]?.deliveries[0]?.instock ? 0.5 : undefined,
                                                            }}
                                                        >
                                                            <Paper
                                                                style={{
                                                                    borderRadius: 15,
                                                                    boxShadow: processingOption === `WAIT` ? "0px 2px 1px -1px rgb(239 51 64 / 100%), 0px 1px 1px 0px rgb(239 51 64 / 100%), 0px 1px 3px 0px rgb(239 51 64 / 50%)" : undefined,
                                                                }}
                                                            >
                                                                <Box p={3}>
                                                                    <Grid container spacing={3} alignItems="center">
                                                                        <Grid item>
                                                                            <Box pt={0.75} pl={0.75} textAlign="center">
                                                                                <FAIcon type="thin" icon="hourglass" size={40} button noMargin />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs className="disable-select">
                                                                            <Typography variant="body1" className={`fw-400 ${processingOption === `WAIT` ? `textError` : ``}`} gutterBottom>
                                                                                Wait for Stock
                                                                            </Typography>
                                                                            <Typography variant="body2">Some items are currently on order, wait for stock to arrive and then despatch from the warehouse as a single delivery</Typography>
                                                                            <Box pt={0.25}>
                                                                                <Typography variant="caption" color="textSecondary" className="fw-300">
                                                                                    <em>This option is not suitable for future dated / call off orders</em>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Paper>
                                                        </Box>
                                                    }
                                                />
                                                {_.size(this.state.suppliers) === 1 && _.isEmpty(_.filter(formData.lines, (el) => el.parent > 0)) && (
                                                    <FormControlLabel
                                                        value="PO"
                                                        checked={processingOption === "PO" && true}
                                                        control={<Radio color="primary" />}
                                                        label={
                                                            <Box mb={3} ml={3} width={"100%"}>
                                                                <Paper
                                                                    style={{
                                                                        borderRadius: 15,
                                                                        boxShadow: processingOption === `PO` ? "0px 2px 1px -1px rgb(239 51 64 / 100%), 0px 1px 1px 0px rgb(239 51 64 / 100%), 0px 1px 3px 0px rgb(239 51 64 / 50%)" : undefined,
                                                                    }}
                                                                >
                                                                    <Box p={3}>
                                                                        <Grid container spacing={3} alignItems="center">
                                                                            <Grid item>
                                                                                <Box pt={0.75} pl={0.75} textAlign="center">
                                                                                    <FAIcon type="thin" icon="truck" size={40} button noMargin />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs className="disable-select">
                                                                                <Typography variant="body1" className={`fw-400 ${processingOption === `PO` ? `textError` : ``}`} gutterBottom>
                                                                                    {this.state.suppliers[0]?.shipHere === 1 ? "Ship Here" : "Direct Despatch"} from {this.state.suppliers[0]?.name}
                                                                                </Typography>
                                                                                <Typography variant="body2">
                                                                                    Create a purchase order for this order using the default carriage rate and {this.state.suppliers[0]?.shipHere === 1 ? `ship here to forward on to the customer` : `despatch direct to the customer`}
                                                                                </Typography>
                                                                                <Box pt={0.25}>
                                                                                    <Typography variant="caption" color="textSecondary" className="fw-300">
                                                                                        <em>This option is not suitable for future dated / call off orders</em>
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Paper>
                                                            </Box>
                                                        }
                                                    />
                                                )}
                                                <FormControlLabel
                                                    value="MANUAL"
                                                    checked={processingOption === "MANUAL" && true}
                                                    control={<Radio color="primary" />}
                                                    label={
                                                        <Box mb={1} ml={3} width={"100%"}>
                                                            <Paper
                                                                style={{
                                                                    borderRadius: 15,
                                                                    boxShadow: processingOption === `MANUAL` ? "0px 2px 1px -1px rgb(239 51 64 / 100%), 0px 1px 1px 0px rgb(239 51 64 / 100%), 0px 1px 3px 0px rgb(239 51 64 / 50%)" : undefined,
                                                                }}
                                                            >
                                                                <Box p={3}>
                                                                    <Grid container spacing={3} alignItems="center">
                                                                        <Grid item>
                                                                            <Box pt={0.75} pl={0.75} textAlign="center">
                                                                                <FAIcon type="thin" icon="user" size={40} button noMargin />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs className="disable-select">
                                                                            <Typography variant="body1" className={`fw-400 ${processingOption === `MANUAL` ? `textError` : ``}`} gutterBottom>
                                                                                Manual Processing
                                                                            </Typography>
                                                                            <Typography variant="body2">Split out items into multiple deliveries and despatch as required</Typography>
                                                                            <Box pt={0.25}>
                                                                                <Typography variant="caption" color="textSecondary" className="fw-300">
                                                                                    <em>This option should be used for part shipping available stock, processing larger / call-off orders, where the order will be fulfilled by multiple suppliers; or to change purchase order options</em>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Paper>
                                                        </Box>
                                                    }
                                                />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} align="right">
                                        <Button color="primary" variant="contained" disabled={!processingOption} onClick={this.handleProcessingOptionConfirmation}>
                                            <FAIcon disabled={!processingOption} icon="check" size="small" buttonPrimary />
                                            Continue
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )) ||
                    (currentTab === 2 && (
                        <>
                            {(!_.isEmpty(purchaseOrders) ||
                                !_.isEmpty(
                                    _.sortBy(
                                        _.filter(suppliers, (el) => _.some(formData.lines, (_el) => _el.supplierId === el.id && _el.fulfil === "po")),
                                        (el) => el.name
                                    )
                                )) && (
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                    <Typography variant="h6">Purchase Orders</Typography>
                                                </Grid>
                                                {(_.isEmpty(
                                                    _.sortBy(
                                                        _.filter(suppliers, (el) => _.some(formData.lines, (_el) => _el.supplierId === el.id && _el.fulfil === "po")),
                                                        (el) => el.name
                                                    )
                                                ) && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Box border={'1px solid #ddd'}>
                                                                <Alert severity="error">
                                                                    <strong>Purchase Order Fulfilment Changes</strong>
                                                                    <Typography variant="caption" component="div">
                                                                        You will need to cancel {_.size(purchaseOrders)} purchase order{_.size(purchaseOrders) > 1 ? "s" : ""} that {_.size(purchaseOrders) > 1 ? "are" : "is"} no longer required for this sales order
                                                                    </Typography>
                                                                </Alert>
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                )) || (
                                                    <>
                                                        {poMovAlert > 0 && (
                                                            <Grid item xs={12}>
                                                                <Box border={'1px solid #ddd'}>
                                                                    <Alert severity="warning">
                                                                        <strong>Below Minimum Order Value</strong>
                                                                        <Typography variant="caption" component="div">
                                                                            There {poMovAlert > 1 ? `are ${poMovAlert} purchase orders` : "is one purchase order"} that is below the minimum order value for that supplier - you can detach this and add more stock (if desired) before submission after processing
                                                                        </Typography>
                                                                    </Alert>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                         {_.some(order.deliveries, _od => _od?.parent?.length) ? 
                                                            (
                                                                <Grid item xs={12}>
                                                                    <Box border={'1px solid #ddd'}>
                                                                        <Alert severity="warning">
                                                                            <strong>Ship Complete Configured</strong>
                                                                            <Typography variant="caption" component="div">
                                                                                This order has been previously processed with ship complete deliveries which have not yet been delivered
                                                                            </Typography>
                                                                        </Alert>
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        : null }
                                                        <Grid item xs={12}>
                                                            <Box className="content-light-grey" p={0.75} mt={1}>
                                                                <DataTable
                                                                    config={{
                                                                        alternatingRowColours: false,
                                                                        responsiveImportance: true,
                                                                        inline: true,
                                                                        noRowHover: true,                                                                    
                                                                        rowProps: (rowData) => ({
                                                                            style: {
                                                                                backgroundColor: "#fff",
                                                                                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                                            },
                                                                        }),
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            heading: "New",
                                                                            field: (rowData) => _.isEmpty(purchaseOrders?.[rowData.id]),
                                                                            fieldFormat: "boolean",
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: "center",
                                                                        },
                                                                        {
                                                                            heading: "Supplier",
                                                                            field: (rowData) => (
                                                                                <>
                                                                                    <Typography variant="body2" className="fw-400">
                                                                                        {rowData.name}
                                                                                    </Typography>
                                                                                    <Typography variant="caption">
                                                                                        {(reprocessing && !_.isEmpty(purchaseOrders?.[rowData.id]) && (
                                                                                            <>
                                                                                                #{purchaseOrders?.[rowData.id]?.ref} - {purchaseOrders?.[rowData.id]?.status}
                                                                                            </>
                                                                                        )) || <>New Purchase Order</>}
                                                                                    </Typography>
                                                                                </>
                                                                            ),
                                                                            main: true,
                                                                            important: true,
                                                                        },
                                                                        // {
                                                                        //     heading: 'Lines',
                                                                        //     field: rowData => _.size(_.filter(formData.lines, el => el.supplierId === rowData.id && el.fulfil === 'po')),
                                                                        //     important: true,
                                                                        //     sizeToContent: true,
                                                                        //     alignment: 'center',
                                                                        // },
                                                                        {
                                                                            heading: `Expected Availability`,
                                                                            field: (rowData) => moment(rowData.defaultDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
                                                                            tooltip: (rowData) => (!_.isEmpty(rowData.defaultDateTooltip) ? _.map(rowData.defaultDateTooltip.split("\n"), (r, idx) => <div key={idx}>{r}</div>) : "No factors affect availability"),
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            cellProps: (rowData) => ({
                                                                                className: rowData.missedReqDelivery ? "textError" : undefined,
                                                                            }),
                                                                        },
                                                                        {
                                                                            heading: "Cut Off Time",
                                                                            field: (rowData) => rowData.cutOff,
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            cellProps: (rowData) => ({
                                                                                className: rowData.cutOffPast ? "textError" : undefined,
                                                                            }),
                                                                        },
                                                                        {
                                                                            heading: "Lead Time",
                                                                            field: (rowData) => (!_.isEmpty(rowData.lead) ? `${getLeadTime(rowData.lead, true)}` : "-"),
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: "Min Order Value",
                                                                            field: (rowData) => (rowData.mov === "0.00" ? "-" : `${currencyFormat.format(rowData.mov)}`),
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: "right",
                                                                        },
                                                                        {
                                                                            heading: "Total Buy Price",
                                                                            field: (rowData) => this.state.formData?.fulfilment?.[this.state.origins?.[rowData.id]]?.totalCost,
                                                                            fieldFormat: "currency",
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: "right",
                                                                            cellProps: (rowData) => ({
                                                                                className: rowData.mov !== "0.00" ? (parseFloat(this.state.formData?.fulfilment?.[this.state.origins?.[rowData.id]]?.totalCost) < parseFloat(rowData.mov) ? "textError" : undefined) : undefined,
                                                                            }),
                                                                        },
                                                                        // {
                                                                        //     heading: 'Required',
                                                                        //     field: rowData => rowData.po,
                                                                        //     fieldFormat: 'boolean',
                                                                        //     important: true,
                                                                        //     sizeToContent: true,
                                                                        //     alignment: 'center',
                                                                        //     tooltip: () => 'Required for this Sales Order'
                                                                        // },
                                                                        {
                                                                            heading: "Ship Complete",
                                                                            field: (rowData) => this.state.formData?.fulfilment?.[this.state.origins?.[rowData.id]]?.shipHere === 2,
                                                                            fieldFormat: "boolean",
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: "center",
                                                                        },
                                                                        {
                                                                            heading: "Ship Here",
                                                                            field: (rowData) => this.state.formData?.fulfilment?.[this.state.origins?.[rowData.id]]?.shipHere === 1,
                                                                            fieldFormat: "boolean",
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                            alignment: "center",
                                                                        },
                                                                        {
                                                                            heading: "Despatch Type",
                                                                            field: (rowData) => (
                                                                                <InsightChip
                                                                                    icon={this.state.formData?.fulfilment?.[this.state.origins?.[rowData.id]]?.collection === 1 ? "people-carry" : "truck"}
                                                                                    label={this.state.formData?.fulfilment?.[this.state.origins?.[rowData.id]]?.collection === 1 ? "Direct Collection" : "Courier Shipment"}
                                                                                />
                                                                            ),
                                                                            fieldFormat: "boolean",
                                                                            important: true,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: "Action",
                                                                            field: (rowData) => {
                                                                                if (rowData.locked === 1) {
                                                                                    return (
                                                                                        <Box pt={1} pb={1}>
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="btn btn-warning"
                                                                                                onClick={() =>
                                                                                                    this.props.deployConfirmation(
                                                                                                        <Box textAlign="center">
                                                                                                            <Box p={3} textAlign="center">
                                                                                                                <FAIcon icon="exclamation-circle" type="thin" className="textError" size={60} noMargin />
                                                                                                            </Box>
                                                                                                            <Typography variant="h4" className="fw-400 textError" paragraph>
                                                                                                                Warning - PO {this.state.purchaseOrders?.[rowData?.id]?.status}
                                                                                                            </Typography>
                                                                                                            <Typography variant="body2" className="fw-400" paragraph>
                                                                                                                This purchase order has previously been submitted to the supplier
                                                                                                            </Typography>
                                                                                                            <Typography variant="body2" className="fw-400" paragraph>
                                                                                                                If you unlock it to make changes then you will need to re-submit it to the supplier and follow the confirmation process
                                                                                                            </Typography>
                                                                                                        </Box>,
                                                                                                        () => this.handleUnlockPurchaseOrder(origins?.[rowData.id], rowData.id),
                                                                                                        undefined,
                                                                                                        "Unlock",
                                                                                                        "Cancel"
                                                                                                    )
                                                                                                }
                                                                                                variant="text"
                                                                                            >
                                                                                                <Box pl={0.5} pr={0.5}>
                                                                                                    <FAIcon icon="unlock" size={14} button />
                                                                                                    Modify
                                                                                                </Box>
                                                                                            </Button>
                                                                                        </Box>
                                                                                    );
                                                                                } else {
                                                                                    return "-";
                                                                                }
                                                                            },
                                                                            sizeToContent: true,
                                                                        },
                                                                    ]}
                                                                    rows={_.sortBy(
                                                                        _.filter(suppliers, (el) => _.some(formData.lines, (_el) => _el.supplierId === el.id && _el.fulfil === "po")),
                                                                        (el) => el.name
                                                                    )}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                            )}
                            {!_.isEmpty(order.deliveries) && _.every(order.deliveries, _od => !_od?.parent?.length) && (
                                <>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <Box p={2}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography variant="h6">Current Order Deliveries</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box className="content-light-grey" p={0.75}>
                                                            <DataTable
                                                                config={{
                                                                    key: "od_id",
                                                                    alternatingRowColours: false,
                                                                    isLoading: isLoading,
                                                                    responsiveImportance: true,
                                                                    inline: true,
                                                                    noRowHover: true,
                                                                    rowProps: (rowData) => ({
                                                                        style: {
                                                                            backgroundColor: "#fff",
                                                                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                                        },
                                                                    }),
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: "Alloc",
                                                                        field: (rowData) => (rowData.od_type === "WH" && rowData.od_status !== "Awaiting Allocation") || (rowData.od_type === "PO" && rowData.od_confirmed === 1),
                                                                        fieldFormat: "boolean",
                                                                        alignment: "center",
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: "Delivery",
                                                                        field: (rowData) => {
                                                                            const thisOdIdx = _.findIndex(this.state.formData?.fulfilment?.[origins?.[rowData?.purchase_order?.po_supp_id ?? "wh"] ?? -1]?.deliveries, (el) => el.odId === rowData.od_id),
                                                                                formData = thisOdIdx >= 0 ? this.state.formData?.fulfilment?.[origins?.[rowData?.purchase_order?.po_supp_id ?? "wh"] ?? -1]?.deliveries?.[thisOdIdx] : null;
                                                                            return (
                                                                                <>
                                                                                    <Typography variant="body2" className="fw-400">
                                                                                        {`Delivery ${rowData.rowNumber} of ${_.size(order.deliveries)}`}
                                                                                    </Typography>
                                                                                    <Typography variant="caption">
                                                                                        Fulfilled{" "}
                                                                                        {rowData.purchase_order
                                                                                            ? rowData.purchase_order?.po_ship_aa_only === 1 && rowData.purchase_order?.po_ifm_modified === 1
                                                                                                ? "by Advanced Access"
                                                                                                : rowData.purchase_order?.po_ship_aa_only === 1 && rowData.purchase_order?.po_ifm_modified === 0
                                                                                                ? `via Advanced Access by ${rowData.purchase_order?.supplier?.supp_company_name}`
                                                                                                : `direct from ${rowData.purchase_order?.supplier?.supp_company_name}`
                                                                                            : "by Advanced Access"}
                                                                                    </Typography>
                                                                                    {((rowData.od_status === "Invoiced" || rowData.od_status === "Despatched") && (
                                                                                        <Box pt={0.5}>
                                                                                            <Typography variant="body2" className="fw-400">
                                                                                                <Box display="inline-block" pr={0.5}>
                                                                                                    <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                                                                                </Box>
                                                                                                This delivery has been {rowData.od_status.toLowerCase()}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )) ||
                                                                                        (rowData?.purchase_order && (formData?.podStatus === "Invoiced" || formData?.podStatus === "Despatched") && (
                                                                                            <Box pt={0.5}>
                                                                                                <Typography variant="body2" className="fw-400">
                                                                                                    <Box display="inline-block" pr={0.5}>
                                                                                                        <FAIcon icon="check-circle" className="textSuccess" noMargin size={13.5} />
                                                                                                    </Box>
                                                                                                    This delivery has been despatched from the supplier
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )) ||
                                                                                        (!rowData?.purchase_order && formData?.locked === 1 && rowData.od_type === "WH" && (!formData?.mustModify || formData?.mustModify === 0) && (
                                                                                            <Box pt={0.5}>
                                                                                                <Typography variant="body2" className="fw-400">
                                                                                                    <Box display="inline-block" pr={0.5}>
                                                                                                        <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                                                                    </Box>
                                                                                                    This delivery has been picked and is locked - only modify if necessary
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )) ||
                                                                                        (!rowData?.purchase_order && formData?.locked === 1 && rowData.od_type === "WH" && formData?.mustModify === 1 && (
                                                                                            <Box pt={0.5}>
                                                                                                <Typography variant="body2" className="fw-400 textError">
                                                                                                    <Box display="inline-block" pr={0.5}>
                                                                                                        <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                                                                    </Box>
                                                                                                    This delivery has been picked but some quantities have changed - you must modify this delivery
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )) || (
                                                                                            <Box pt={0.5}>
                                                                                                <Typography variant="body2" className="fw-400">
                                                                                                    <Box display="inline-block" pr={0.5}>
                                                                                                        <FAIcon icon="exclamation-circle" className="textWarning" noMargin size={13.5} />
                                                                                                    </Box>
                                                                                                    This delivery is outstanding
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                </>
                                                                            );
                                                                        },
                                                                    },
                                                                    {
                                                                        heading: "Expected Despatch",
                                                                        field: (rowData) => rowData.od_schedule_date,
                                                                        fieldFormat: "date",
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: "Fulfilled By",
                                                                        field: (rowData) =>
                                                                            rowData.od_type === "PO"
                                                                                ? "Purchase Order"
                                                                                : rowData.purchase_order
                                                                                ? rowData.purchase_order?.po_ship_here_order === 1 && rowData.purchase_order?.po_ifm_modified === 1
                                                                                    ? "Warehouse Stock"
                                                                                    : "Purchase Order"
                                                                                : "Warehouse Stock",
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: "Status",
                                                                        field: (rowData) => {
                                                                            if (rowData.od_type === "WH") {
                                                                                const thisOdIdx = _.findIndex(this.state.formData?.fulfilment?.[origins?.wh ?? -1]?.deliveries, (el) => el.odId === rowData.od_id),
                                                                                    formData = thisOdIdx >= 0 ? this.state.formData?.fulfilment?.[origins?.wh ?? -1]?.deliveries?.[thisOdIdx] : null;
                                                                                return formData?.wasLocked === 1 && formData.locked === 0 ? "Awaiting Picking" : rowData.od_status;
                                                                            } else {
                                                                                return rowData.od_status;
                                                                            }
                                                                        },
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: "Action",
                                                                        field: (rowData) => {
                                                                            if (rowData.od_type === "WH") {
                                                                                const thisOdIdx = _.findIndex(this.state.formData?.fulfilment?.[origins?.wh ?? -1]?.deliveries, (el) => el.odId === rowData.od_id),
                                                                                    formData = thisOdIdx >= 0 ? this.state.formData?.fulfilment?.[origins?.wh ?? -1]?.deliveries?.[thisOdIdx] : null;
                                                                                return (
                                                                                    (thisOdIdx >= 0 &&
                                                                                        formData &&
                                                                                        ((formData?.locked === 1 && rowData.od_type === "WH" && (
                                                                                            <Box pt={1} pb={1}>
                                                                                                <Button
                                                                                                    color="primary"
                                                                                                    className="btn btn-warning"
                                                                                                    onClick={() =>
                                                                                                        this.props.deployConfirmation(
                                                                                                            <Box textAlign="center">
                                                                                                                <Box p={3} textAlign="center">
                                                                                                                    <FAIcon icon="exclamation-circle" type="thin" className="textError" size={60} noMargin />
                                                                                                                </Box>
                                                                                                                <Typography variant="h4" className="fw-400 textError" paragraph>
                                                                                                                    Warning - {formData?.odStatus}
                                                                                                                </Typography>
                                                                                                                <Typography variant="body2" className="fw-400" paragraph>
                                                                                                                    Please make stores aware that this delivery is changing.
                                                                                                                </Typography>
                                                                                                                <Typography variant="body2" className="fw-400" paragraph>
                                                                                                                    The affected order delivery needs to be retrieved from the{" "}
                                                                                                                    {formData.odStatus === "Ready for Collection"
                                                                                                                        ? "collection shelf"
                                                                                                                        : formData.odStatus === "Despatched"
                                                                                                                        ? "despatched orders pallet"
                                                                                                                        : formData.odStatus === "Awaiting Despatch"
                                                                                                                        ? "despatch area"
                                                                                                                        : "check area"}
                                                                                                                    ; then dispose of the despatch note and use the stock to re-pick the order again when it shows on the PDA.
                                                                                                                    <br />
                                                                                                                    <br />
                                                                                                                    Any unused parts will need to be returned to the appropriate bin.
                                                                                                                </Typography>
                                                                                                            </Box>,
                                                                                                            () => this.handleUnlockDelivery(origins?.wh, thisOdIdx),
                                                                                                            undefined,
                                                                                                            "Modify Delivery",
                                                                                                            "Cancel"
                                                                                                        )
                                                                                                    }
                                                                                                    variant="text"
                                                                                                >
                                                                                                    <Box pl={0.5} pr={0.5}>
                                                                                                        <FAIcon icon="unlock" size={14} button />
                                                                                                        Modify
                                                                                                    </Box>
                                                                                                </Button>
                                                                                            </Box>
                                                                                        )) ||
                                                                                            "-")) ||
                                                                                    "-"
                                                                                );
                                                                            } else {
                                                                                return "-";
                                                                            }
                                                                        },
                                                                        sizeToContent: true,
                                                                    },
                                                                ]}
                                                                rows={order.deliveries}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                {!_.isEmpty(_.filter(order.deliveries, (el) => el.od_on_hold === 1 && el.od_on_hold_datetime !== "0000-00-00 00:00:00")) && (
                                                    <Box mt={3}>
                                                        <Grid container spacing={3}>
                                                            {_.map(
                                                                _.filter(order.deliveries, (el) => el.od_type === "WH" && el.od_sys_hold === 0 && el.od_on_hold === 1 && el.od_on_hold_lock === 1 && (el.pick || el.despatch)),
                                                                (od, idx) => (
                                                                    <Grid item xs={12}>
                                                                        <Alert severity="error">
                                                                            <strong>Warning - Delivery {od.od_idx} is currently pending release!</strong>
                                                                        </Alert>
                                                                    </Grid>
                                                                )
                                                            )}
                                                            {_.map(
                                                                _.filter(order.deliveries, (el) => el.od_type === "WH" && el.od_sys_hold === 0 && el.od_on_hold === 1 && el.od_on_hold_lock === 0 && (el.pick || el.despatch)),
                                                                (od, idx) => (
                                                                    <Grid item xs={12}>
                                                                        <ResolveIssue key={idx} od={od} type={od.check?.check_status === "On Hold" ? "Check" : "Pick"} hideOptions />
                                                                    </Grid>
                                                                )
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={2}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h6" className="fw-400">
                                                    Order Fulfilment
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Box className="content-light-grey" p={0.75} mt={1}>
                                            {this.renderFulfilmentLines()}
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        </>
                    )) ||
                    (currentTab === 3 && (
                        <>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    {_.map(formData.fulfilment, (origin, i) => {
                                        const idx = parseInt(i);
                                        return (
                                            <>
                                                <Grid item xs={12} key={i}>
                                                    <Paper>
                                                        <Box p={2}>
                                                            <Box>
                                                                <Grid container spacing={1} alignItems="center">
                                                                    <Grid item>
                                                                        <Typography variant="h6">{origin?.shipHere === 2 ? `Ship Complete: ` : origin?.shipHere === 1 ? 'Ship Here: ' : origin?.shipHere === 0 ? 'Direct Despatch:' : 'Warehouse: '}{origin.name}</Typography>
                                                                        {/* <Typography variant="body2">{origin.type === `po` ? `Purchase Order Fulfilment - Total Cost: ${currencyFormat.format(origin?.totalCost)}` : `Warehouse Fulfilment`}</Typography> */}
                                                                    </Grid>
                                                                    <Grid item xs />
                                                                    {origin.type === "po" && (
                                                                        <>
                                                                            {!_.isEmpty(origin?.supplier?.lead) && (
                                                                                <Grid item>
                                                                                    <InsightChip
                                                                                        paddingLeft={8}
                                                                                        marginRight={8}
                                                                                        textDefault
                                                                                        icon="calendar"
                                                                                        label={`${getLeadTime(origin?.supplier?.lead, true)} Lead Time`}
                                                                                    />
                                                                                </Grid>
                                                                            )}
                                                                            {origin?.supplier?.cutOff !== "-" && (
                                                                                <Grid item>
                                                                                    <InsightChip
                                                                                        marginRight={8}
                                                                                        paddingLeft={8}
                                                                                        textDefault
                                                                                        icon="clock"
                                                                                        label={`${origin?.supplier?.cutOff} Cut Off Time`}
                                                                                    />
                                                                                </Grid>
                                                                            )}
                                                                            <Grid item>
                                                                                <InsightChip
                                                                                    paddingLeft={8}
                                                                                    textDefault
                                                                                    icon="coins"
                                                                                    label={`${origin?.supplier?.mov === "0.00" ? "No" : currencyFormat.format(origin.supplier.mov)} Minimum Order`}
                                                                                />
                                                                            </Grid>
                                                                            {origin?.locked && (
                                                                                <Grid item>
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            this.props.deployConfirmation(
                                                                                                <Box textAlign="center">
                                                                                                    <Box p={3} textAlign="center">
                                                                                                        <FAIcon icon="exclamation-circle" type="thin" className="textError" size={60} noMargin />
                                                                                                    </Box>
                                                                                                    <Typography variant="h4" className="fw-400 textError" paragraph>
                                                                                                        Warning - PO {this.state.purchaseOrders?.[origin.supplier?.id]?.status}
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2" className="fw-400" paragraph>
                                                                                                        This purchase order has previously been submitted to the supplier
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2" className="fw-400" paragraph>
                                                                                                        If you unlock it to make changes then you will need to re-submit it to the supplier and follow the confirmation process
                                                                                                    </Typography>
                                                                                                </Box>,
                                                                                                () => this.handleUnlockPurchaseOrder(origins?.[origin.supplier?.id], origin.supplier?.id),
                                                                                                undefined,
                                                                                                "Unlock",
                                                                                                "Cancel"
                                                                                            )
                                                                                        }
                                                                                        variant="contained"
                                                                                        className="btn btn-warning"
                                                                                    >
                                                                                        <FAIcon icon="check" button />
                                                                                        Modify
                                                                                    </Button>
                                                                                </Grid>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </Box>
                                                            {(origin.type === "po" &&
                                                                ((_.every(
                                                                    _.filter(formData.lines, (el) => el.fulfilment === idx),
                                                                    (el) => el.lineStatus === "Invoiced"
                                                                ) && (
                                                                    <Box pb={1}>
                                                                        <Typography variant="caption" className="fw-300">
                                                                            <Box display="inline-block" pr={0.5}>
                                                                                <FAIcon icon="exclamation-circle" className="textSuccess" noMargin size={13.5} />
                                                                            </Box>
                                                                            This purchase order is complete
                                                                        </Typography>
                                                                    </Box>
                                                                )) ||
                                                                    (origin.locked === 1 && (
                                                                        <Box pb={1}>
                                                                            <Typography variant="caption" className="fw-300">
                                                                                <Box display="inline-block" pr={0.5}>
                                                                                    <FAIcon icon="exclamation-circle" className="textWarning" noMargin size={13.5} />
                                                                                </Box>
                                                                                This purchase order has been submitted, please unlock it to modify any details (this will require re-confirmation)
                                                                            </Typography>
                                                                        </Box>
                                                                    )) || (
                                                                        <Box pb={1}>
                                                                            <Typography variant="caption" className="fw-300">
                                                                                <Box display="inline-block" pr={0.5}>
                                                                                    <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                                                </Box>
                                                                                This purchase order is {_.isEmpty(purchaseOrders?.[origin.supplier?.id]) ? "new" : "incomplete"}
                                                                                {origin?.shipHere === 2 ? `, and is shipping complete - no additional deliveries can be added`: null}
                                                                            </Typography>
                                                                        </Box>
                                                                    ))) ||
                                                                (_.every(
                                                                    _.filter(formData.lines, (el) => el.fulfil === "wh"),
                                                                    (el) => el.lineStatus === "Invoiced"
                                                                ) && (
                                                                    <Box pb={1}>
                                                                        <Typography variant="caption" className="fw-300">
                                                                            <Box display="inline-block" pr={0.5}>
                                                                                <FAIcon icon="exclamation-circle" className="textSuccess" noMargin size={13.5} />
                                                                            </Box>
                                                                            All items have shipped from the warehouse
                                                                        </Typography>
                                                                    </Box>
                                                                )) || (
                                                                    <Box pb={1}>
                                                                        <Typography variant="caption" className="fw-300">
                                                                            <Box display="inline-block" pr={0.5}>
                                                                                <FAIcon icon="exclamation-circle" className="textError" noMargin size={13.5} />
                                                                            </Box>
                                                                            {reprocessing ? `Some items have not yet shipped from the warehouse` : `The following items will be shipped from the warehouse`}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item xs>
                                                                    <Typography variant="body1" className="fw-400">
                                                                        Deliveries
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button disabled={origin.locked === 1 || origin?.shipHere === 2} variant="text" color="primary" size="small" onClick={() => this.handleDeliveryAdd(idx)}>
                                                                        <FAIcon type="light" icon="plus-circle" size={15} button />
                                                                        Additional Delivery
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                            <Box className="content-light-grey" p={0.75} mt={1} mb={1}>
                                                                {this.renderDeliveriesTable(idx, origin)}
                                                            </Box>
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item xs>
                                                                    <Typography variant="body1" className="fw-400">
                                                                        Lines
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Box className="content-light-grey" p={0.75} mt={1}>
                                                                {this.renderDeliveriesFulfilmentLines(
                                                                    _.filter(formData.lines, (el) => el.fulfilment === idx),
                                                                    origin.deliveries,
                                                                    origin.type,
                                                                    idx
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </>
                    )) || (
                        <>
                            <Grid item xs={12} className="pb-0">
                                <Grid container alignItems="center">
                                    <Grid item xs={12} lg={6}>
                                        <Typography variant="h6">Sales Order Deliveries</Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} align="right">
                                        <FormControlLabel control={<Checkbox color="primary" checked={reviewViewAll} onChange={() => this.renderReviewDeliveriesView(true)} />} label={<span className="fw-400">View By Despatch Date</span>} />
                                        <FormControlLabel control={<Checkbox color="primary" checked={!reviewViewAll} onChange={() => this.renderReviewDeliveriesView(false)} />} label={<span className="fw-400">Group By Supplier</span>} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {_.some(formData.fulfilment, (_el) => _.some(_el.deliveries, (el) => el?.mustModify === 1 && el?.locked === 1)) && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <strong>Important</strong>
                                        <br />
                                        There are some deliveries that must be modified as quantities have changed - you cannot <em>Confirm Fulfilment</em> until affected deliveries are unlocked
                                    </Alert>
                                </Grid>
                            )}
                            {(reviewViewAll && (
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        {this.renderReviewDeliveries(true)}
                                    </Grid>
                                </Grid>
                            )) || (
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        {this.renderReviewDeliveries(false)}
                                    </Grid>
                                </Grid>
                            )}
                            {!_.isEmpty(_.filter(formData.fulfilment, (el) => el.type === "po")) && (
                                <>
                                    <Grid item xs={12} className="pt-3 pb-1">
                                        <Typography variant="h6">Purchase Orders</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            {this.renderReviewPurchaseOrders()}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {!_.isEmpty(purchaseOrders) &&
                                _.map(
                                    purchaseOrders,
                                    (po, idx) =>
                                        (!_.some(formData.lines, (_el) => _el.supplierId === po?.supplier && _el.fulfil === "po") && (
                                            <Grid item xs={12} key={idx}>
                                                <Box pt={3} pb={3}>
                                                    <Divider />
                                                </Box>
                                                <Box pb={2}>
                                                    <Typography variant="h6">Purchase Order Cancellation</Typography>
                                                </Box>
                                                <Paper>
                                                    <Box p={2}>
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <Typography variant="h6">Purchase Order #{po.ref}</Typography>
                                                                <Typography variant="body2">{po.name}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box pt={1}>
                                                                    <Alert severity="error">
                                                                        <strong>Urgent Action Required - Live Purchase Order</strong>
                                                                        <br />
                                                                        Please cancel any outstanding deliveries with {po.name} before continuing!
                                                                    </Alert>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box className="content-light-grey" p={0.75} mt={1}>
                                                                    <DataTable
                                                                        config={{
                                                                            alternatingRowColours: false,
                                                                            noRowHover: true,
                                                                            responsiveImportance: true,
                                                                            inline: true,
                                                                            rowProps: () => ({
                                                                                style: {
                                                                                    backgroundColor: "#fff",
                                                                                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                                                },
                                                                            }),
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading: "Cancel",
                                                                                field: (rowData) => rowData.pod_status !== "Invoiced" || rowData.pod_status !== "Despatched",
                                                                                fieldFormat: "boolean",
                                                                                alignment: "center",
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading: "Delivery Reference",
                                                                                field: (rowData) => `${rowData.pod_reference}/${rowData.pod_idx}`,
                                                                                main: true,
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading: "Delivery Status",
                                                                                field: (rowData) => rowData.pod_status,
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading: "Check Date",
                                                                                field: (rowData) => rowData.pod_expected_despatch_date,
                                                                                fieldFormat: "date",
                                                                                main: true,
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading: "Expected Despatch Date",
                                                                                field: (rowData) => rowData.pod_expected_despatch_date,
                                                                                fieldFormat: "date",
                                                                                main: true,
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading: "Despatch Date",
                                                                                field: (rowData) => rowData.pod_despatch_date,
                                                                                fieldFormat: "date",
                                                                                main: true,
                                                                                sizeToContent: true,
                                                                            },
                                                                        ]}
                                                                        rows={po.pod}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        )) ||
                                        null
                                )}
                            <Grid item xs={12} align="right">
                                <Button
                                    color="primary"
                                    onClick={() => deployConfirmation("Are you sure you want to process this sales order?", this.handleEmailConfirmation)}
                                    variant="contained"
                                    disabled={
                                        _.some(formData.fulfilment, (_el) => _.some(_el.deliveries, (el) => el?.mustModify === 1 && el?.locked === 1)) ||
                                        _.some(
                                            _.filter(formData.fulfilment, (el) => el.type === "po"),
                                            (_el) => _el.formData?.carriage?.length === 0
                                        ) ||
                                        _.some(
                                            _.filter(formData.fulfilment, (el) => el.shipHere === 2),
                                            (_el) => !_el.formData?.shipComplete || _el.formData?.shipComplete === ''
                                        ) ||
                                        _.some(formData.lines, (_el) => _el.buyPrice === "")
                                    }
                                >
                                    <FAIcon
                                        disabled={
                                            _.some(formData.fulfilment, (_el) => _.some(_el.deliveries, (el) => el?.mustModify === 1 && el?.locked === 1)) ||
                                            _.some(
                                                _.filter(formData.fulfilment, (el) => el.type === "po"),
                                                (_el) => _el.formData?.carriage?.length === 0
                                            ) ||
                                            _.some(
                                                _.filter(formData.fulfilment, (el) => el.shipHere === 2),
                                                (_el) => !_el.formData?.shipComplete || _el.formData?.shipComplete === ''
                                            ) ||
                                            _.some(formData.lines, (_el) => _el.buyPrice === "")
                                        }
                                        icon="check"
                                        size="small"
                                        buttonPrimary
                                    />
                                    Confirm Fulfilment
                                </Button>
                            </Grid>
                        </>
                    )}
            </Grid>
        );
    };
}

export default Processing;
