import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { getFormData, handleChange, handleCheckedChange, handleSelectChange } from 'Functions/FormFunctions';

const initialState = () => ({
    access: {
        privateFiles: false
    },
    categoryList: [],
    confirmation: {
        submit: false
    },
    formData: {
        id: '',
        type: '',
        category: '',
        document: '',
        title: '',
        private: 0,
        ra: 0
    },
    formErrors: {},
    isLoading: true,
    uploading: false
})

//id relation toggleDialog
class FileForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.getFormData = getFormData.bind(this)
        this.handleChange = handleChange.bind(this)
        this.handleSelectChange = handleSelectChange.bind(this)
        this.handleCheckedChange = handleCheckedChange.bind(this)
    }

    componentDidMount = () => {
        if(this.props.allAllPrivate) {
            this.setState({
                access: {
                    privateFiles: true
                }
            }, this.loadComponentData)
        } else {
            API.access('private-files')
            .then(res => {
                this.setState({
                    access: {
                        privateFiles: res?.data?.has_access ?? false
                    }
                }, this.loadComponentData)
            })
        }
    }

    loadComponentData = () => {
        if(this.props.updateId) {
            Promise.all([
                API.get(`/misc/documents/${this.props.updateId}`),
                API.get(`/misc/documents/categories`, { params: { category: this.props.category } })
            ])
            .then(([result, categories]) => {
                if(result?.data && categories?.data) {
                    let categoryList = _.map(categories.data, cat => (
                        _.assign({
                            label: cat.dc_name,
                            options: _.map(_.filter(cat.children, el => el.dc_name !== "System"), subCat => (
                                _.assign({
                                    label: subCat.dc_name,
                                    value: subCat.dc_id,
                                    defaultPrivate: parseInt(subCat.dc_private)
                                })
                            ))
                        })
                    ))
                    this.setState({
                        categoryList, 
                        formData: {
                            ...this.state.formData,
                            category: result.data.doc_dc_id,
                            title: result.data.doc_title,
                            private: parseInt(result.data.doc_private),
                            ra: parseInt(result.data.doc_ra)
                        },
                        isLoading: false
                    })
                }
            })
        } else {                
            API.get(`/misc/documents/categories`, { params: { category: this.props.category } })
            .then(categories => {
                if(categories?.data) {
                    let categoryList = _.map(categories.data, cat => (
                        _.assign({
                            label: cat.dc_name,
                            options: _.map(_.filter(cat.children, el => el.dc_name !== "System"), subCat => (
                                _.assign({
                                    label: subCat.dc_name,
                                    value: subCat.dc_id,
                                    defaultPrivate: parseInt(subCat.dc_private),
                                })
                            ))
                        })
                    ))
                    this.setState({
                        categoryList,
                        formData: {
                            ...this.state.formData,
                            id: this.props.id,
                            type: this.props.type
                        },
                        isLoading: false
                    })
                }
            })
        }
    }

    handlePrivateByDefault = () => {
        let { access, formData, categoryList } = this.state;
        if(access.privateFiles) {
            if(formData.category && formData.category !== "") {
                let category = _.find(categoryList?.[0]?.options, el => el.value === formData.category);
                if(category?.defaultPrivate === 1) {
                    formData = {
                        ...formData,
                        private: 1
                    }
                } else {
                    formData = {
                        ...formData,
                        private: 0
                    }
                }
                this.setState({
                    formData
                })
            }
        }
    }

    handleFileChange = (drop, name, document) => {

        let fn = document.name.split("."),
            title = '';

        for(let i = 0; i < (fn.length - 1); i++) {
            title += fn[i]
        }

        this.setState({
            formData: {
                ...this.state.formData,
                document,
                title
            }
        });
    }
   
    cancel = () => {
        this.setState({
            formData: {
                ...initialState().formData,
                id: this.props.id,
                type: this.props.type
            }
        }, () => {
            if(this.props.cancel) {
                this.props.cancel();
            }
        })
    }
    
    handleSubmit = () => {
        if(this.props.updateId) {
            API.put(`/misc/documents/${this.props.updateId}`, this.state.formData)
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                                type: this.props.type
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully updated the file");
                            if(this.props.callback) {
                                this.props.callback();
                            }
                            if(this.props.cancel) {
                                this.props.cancel();
                            }
                        })
                    }
                }
            })
        } else {
            this.setState({
                uploading: true
            }, () => {
                API.post(`/misc/documents`, this.getFormData())
                .then(res => {
                    if(res.data) {
                        if(res.data.errors && res.data.errors.length > 0) {
                            this.setState({
                                uploading: false,
                                formErrors: formatValidationErrors(res.data.errors),
                            })
                        } else {
                            this.setState({
                                formData: { 
                                    ...initialState().formData,
                                    id: this.props.id,
                                    type: this.props.type
                                },
                                uploading: false
                            }, () => {
                                this.props.deploySnackBar("success", "You have successfully uploaded the file");
                                if(this.props.callback) {
                                    this.props.callback();
                                }
                                if(this.props.cancel) {
                                    this.props.cancel();
                                }
                            })
                        }
                    }
                })
            })
        }
    }

    render() {
        const { privateFileText, updateId } = this.props;
        const { access, categoryList, formErrors, formData, isLoading, uploading } = this.state;
        return (
            ((uploading || isLoading) && (
                <Grid container>
                    <Grid item xs={12} align='center'>
                        <br /><br />
                        <LoadingCircle />
                        {uploading && (
                            <Typography variant="body2" align='center'>
                                Please wait whilst the file is uploaded<br /><br /><br /><br />
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            )) || (
                <form noValidate autoComplete="off">
                    <Grid container>
                        <Grid item xs={12}>
                            {!updateId && (
                                <>
                                    <Box pb={1}>
                                        <DragFileInput
                                            id="document"
                                            name="document"
                                            label={`Upload File *`}
                                            file={formData.document}
                                            errorText={formErrors && formErrors['document']}
                                            onChange={this.handleFileChange}
                                            emptyText='No file selected'
                                        />
                                    </Box>
                                </>
                            )}
                            <TextField
                                id="title"
                                name="title"
                                label="Title *"
                                value={formData.title}
                                error={formErrors && formErrors['title'] && true}
                                helperText={formErrors && formErrors['title']}                                                
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                            <AutoCompleteSelect
                                isGrouped
                                options={categoryList}
                                name="category"
                                label={'Category *'}
                                value={formData.category}
                                error={formErrors && formErrors['category'] && true}
                                errorText={formErrors && formErrors['category']}                                                
                                onChange={v => this.handleSelectChange('category', v, false, this.handlePrivateByDefault)}
                                margin="none"
                                fullWidth
                            />
                            {access.privateFiles && (
                                <Box>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={formData.private === 1} />}
                                        label={<Typography variant="caption">{privateFileText ?? `Private File`}</Typography>}
                                        onChange={this.handleCheckedChange}
                                        name="private"
                                    />
                                </Box>
                            )}
                            <Box>
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={formData.ra === 1} />}
                                    label={<Typography variant="caption">Requires Action</Typography>}
                                    onChange={this.handleCheckedChange}
                                    name="ra"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button onClick={this.cancel} variant="text" color="primary" disabled={uploading}><FAIcon icon="times" size={15} button />Discard</Button>
                            <Button 
                                onClick={() => this.props.deployConfirmation(`Are you sure you want to ${updateId ? 'update' : 'upload'} this file?`, this.handleSubmit)} 
                                disabled={(uploading || (formData.title.length === 0 || (!updateId && formData.category === '')  || (!updateId && formData.document === ''))) ? true : false} 
                                variant="text" 
                                color="primary"
                            >
                                <FAIcon icon="check" size={15} button />
                                {updateId ? 'Update' : 'Upload File'}
                            </Button>
                        </Grid>   
                    </Grid>  
                </form>
            )
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(FileForm);