import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Prompt, withRouter } from "react-router";
import uuidv4 from "uuid/v4";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import MuiButton from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";

import API from "API";
import logo from "Assets/Images/no-img.jpg";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import AddressLookupDialog from "Components/Common/AddressLookup/AddressLookupDialog";
import Alert from "Components/Common/Alert/Alert";
import AppButton from "Components/Common/Buttons/AppButton";
import InsightChip from "Components/Common/Chips/InsightChip";
import ProductStockChip from "Components/Common/Chips/ProductStockChip";
import StatusChip from "Components/Common/Chips/StatusChip";
import DataTable from "Components/Common/DataTables/DataTable";
import Billing from "Components/Common/Forms/QuoteOrderForm/Billing";
import Button from "Components/Common/Forms/QuoteOrderForm/Button";
import CardDetailsForm from "Components/Common/Forms/QuoteOrderForm/CardDetailsForm";
import ContactUpdates from "Components/Common/Forms/QuoteOrderForm/ContactUpdates";
import DeliveryInstructions from "Components/Common/Forms/QuoteOrderForm/DeliveryInstructions";
import Frequent from "Components/Common/Forms/QuoteOrderForm/Frequent";
import Notes from "Components/Common/Forms/QuoteOrderForm/Notes";
import Preview from "Components/Common/Forms/QuoteOrderForm/Preview";
import Product from "Components/Common/Forms/QuoteOrderForm/Product";
import Products from "Components/Common/Forms/QuoteOrderForm/Products";
import Shipping from "Components/Common/Forms/QuoteOrderForm/Shipping";
import Tabs from "Components/Common/Forms/QuoteOrderForm/Tabs";
import Totals from "Components/Common/Forms/QuoteOrderForm/Totals";
import UpdateOrderDeliveryPurchaseOrders from "Components/Common/Forms/QuoteOrderForm/UpdateOrderDeliveryPurchaseOrders";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import Textarea from "Components/Common/Inputs/Textarea";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import Form from "Components/Common/Styles/Form";
import CustomerSearchSelector from "Components/Customers/Misc/CustomerSearchSelector";
import Block from "./Block";
import WildixContext from "Context/WildixContext";
import { AA_ADDRESS_OBJECT, CLOUDFRONT_URL, DEFAULT_VAT_PERCENT, DEFAULT_VAT_VALUE, MANUAL_DISCOUNT_THRESHOLD, VIEW_PAGE_OFFSET } from "Constants";
import { handleChange, handleCheckedChange, handleNull } from "Functions/FormFunctions";
import { currencyFormat, isEven, isNumeric, isOdd, TabPanel } from "Functions/MiscFunctions";
import { formatValidationErrors } from "Helpers/ErrorHelper";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { START_RECORDING, STOP_RECORDING } from "Helpers/WildixHelper";

const DiscountPasswordForm = ({ document, error, highestDiscount, handleSubmit, handleClose }) => {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmitHook = (e) => {
        e?.preventDefault?.();
        handleSubmit(password);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box p={3}>
            <form onSubmit={handleSubmitHook} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} align="center">
                        <Box mt={2}>
                            <FAIcon icon="lock" size={50} noMargin />
                        </Box>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="h6" gutterBottom>
                            {document} Discount Authorisation
                        </Typography>
                        <Typography className={error.substr(0, 6) !== "Please" ? "textError" : undefined} variant="body2">
                            {error}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Discount Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e?.target?.value ?? "")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" onClick={toggleShowPassword}>
                                        <FAIcon icon={showPassword ? "eye-slash" : "eye"} type="solid" noMargin button color="white" />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity={parseInt(highestDiscount ?? 0) > 15 ? 'error' : 'warning'}>
                            <Typography variant="body2">
                                <strong>{highestDiscount}% Discount Applied</strong><br />
                                Please check you are happy with the discount that has been applied to this {document.toLowerCase()}, use <em>Cancel</em> below to amend or review.
                            </Typography>
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <MuiButton className={`btn ${password === "" ? `btn-disabled` : `btn-success`} p-2`} type="submit" fullWidth disabled={password === ""}>
                            <FAIcon icon="check" size={15} disabled={password === ""} buttonPrimary />
                            Authorise
                        </MuiButton>
                    </Grid>
                    <Grid item xs={12}>
                        <MuiButton className="btn btn-error p-1" fullWidth onClick={handleClose}>
                            <FAIcon icon="times" size={15} buttonPrimary />
                            Cancel
                        </MuiButton>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

const QuoteOrderVariation = ({
    closeDialog,
    currentVat,
    currentVatValue,
    deployConfirmation,
    handleSetVariations,
    nextDialog,
    previousVat,
    previousVatValue,
    priceChanges,
    productRows,
    vatChange,
}) => {
    const initialState = () => ({
        customerVatPercent: currentVat,
        customerVatValue: currentVatValue,
        productRows: productRows,
        showHeadings: vatChange && !_.isEmpty(priceChanges),
    });

    const [state, setState] = useState(initialState());

    const handleSubmit = () => {
        deployConfirmation("Are you sure to want to apply selected changes?", () => {
            handleSetVariations(state.customerVatValue, state.customerVatPercent, state.productRows, nextDialog);
            closeDialog();
        });
    };

    const handlePriceChange = (idx, price) => {
        let newProductRows = [...state.productRows];
        newProductRows[idx].price = price;
        newProductRows[idx].discount = ((newProductRows[idx].price / 100) * newProductRows[idx].discountPercent).toFixed(2);
        newProductRows[idx].discountTotal = (parseFloat(newProductRows[idx].quantity) * parseFloat(newProductRows[idx].discount)).toFixed(2);
        newProductRows[idx].subTotal = (parseFloat(newProductRows[idx].price) - parseFloat(newProductRows[idx].discount)).toFixed(2);
        newProductRows[idx].total = (parseFloat(newProductRows[idx].quantity) * (parseFloat(newProductRows[idx].price) - parseFloat(newProductRows[idx].discount)).toFixed(2)).toFixed(2);
        setState((state) => ({
            ...state,
            productRows: newProductRows,
        }));
    };

    const handleVatChange = (customerVatPercent) => {
        setState((state) => ({
            ...state,
            customerVatPercent,
            customerVatValue: customerVatPercent === currentVat ? currentVatValue : previousVatValue,
        }));
    };

    const handleAll = (checked) => {
        let newProductRows = [...state.productRows];

        _.each(priceChanges, (row) => {
            const price = checked ? row.currentPrice : row.previousPrice;

            newProductRows[row.idx].price = price;
            newProductRows[row.idx].discount = ((newProductRows[row.idx].price / 100) * newProductRows[row.idx].discountPercent).toFixed(2);
            newProductRows[row.idx].discountTotal = (parseFloat(newProductRows[row.idx].quantity) * parseFloat(newProductRows[row.idx].discount)).toFixed(2);
            newProductRows[row.idx].subTotal = (parseFloat(newProductRows[row.idx].price) - parseFloat(newProductRows[row.idx].discount)).toFixed(2);
            newProductRows[row.idx].total = (
                parseFloat(newProductRows[row.idx].quantity) * (parseFloat(newProductRows[row.idx].price) - parseFloat(newProductRows[row.idx].discount)).toFixed(2)
            ).toFixed(2);
        });

        setState((state) => ({
            ...state,
            productRows: newProductRows,
            customerVatPercent: checked ? currentVat : previousVat,
            customerVatValue: checked ? currentVatValue : previousVatValue,
        }));
    };

    return (
        <Grid container spacing={3}>
            {!_.isEmpty(priceChanges) && (
                <Grid item xs={12}>
                    {state.showHeadings && (
                        <Typography variant="h6" gutterBottom className="mt-3">
                            Product Pricing Changes
                        </Typography>
                    )}
                    <DataTable
                        config={{
                            key: "idx",
                            alternatingRowColours: true,
                            responsiveImportance: true,
                            rowsPerPage: 100,
                            inline: state.showHeadings ? true : undefined,
                        }}
                        columns={[
                            {
                                heading: "Product",
                                field: (rowData) => (
                                    <>
                                        <Typography variant="body2" className="fw-400">
                                            {rowData.productCode}
                                        </Typography>
                                        <Typography variant="caption">{rowData.productName}</Typography>
                                    </>
                                ),
                                important: true,
                            },
                            {
                                heading: "Quantity",
                                field: (rowData) => rowData.quantity,
                                important: true,
                                alignment: "right",
                                sizeToContent: true,
                            },
                            {
                                heading: "Previous Price",
                                field: (rowData) => rowData.previousPrice,
                                fieldFormat: "currency",
                                sizeToContent: true,
                                alignment: "right",
                                cellProps: (rowData) => ({
                                    className: "textError",
                                    style: {
                                        textDecoration: state.productRows[rowData.idx]?.price !== rowData.previousPrice ? "line-through" : undefined,
                                    },
                                }),
                            },
                            {
                                heading: "Current Price",
                                field: (rowData) => rowData.currentPrice,
                                fieldFormat: "currency",
                                sizeToContent: true,
                                alignment: "right",
                                cellProps: (rowData) => ({
                                    className: "textSuccess",
                                    style: {
                                        textDecoration: state.productRows[rowData.idx]?.price !== rowData.currentPrice ? "line-through" : undefined,
                                    },
                                }),
                            },
                            {
                                heading: "Update Price?",
                                field: (rowData) => {
                                    const newPrice = state.productRows[rowData.idx]?.price === rowData.currentPrice,
                                        price = newPrice ? rowData.previousPrice : rowData.currentPrice;
                                    return <Checkbox color="primary" checked={newPrice} onChange={() => handlePriceChange(rowData.idx, price)} />;
                                },
                                sizeToContent: true,
                                alignment: "right",
                            },
                        ]}
                        rows={priceChanges}
                    />
                </Grid>
            )}
            {vatChange && (
                <Grid item xs={12}>
                    {state.showHeadings && (
                        <Typography variant="h6" gutterBottom>
                            VAT Rate Change
                        </Typography>
                    )}
                    <DataTable
                        config={{
                            alternatingRowColours: true,
                            responsiveImportance: true,
                            rowsPerPage: 100,
                            inline: state.showHeadings ? true : undefined,
                        }}
                        columns={[
                            {
                                heading: "Previous VAT",
                                field: (rowData) => `${rowData.previousVat}%`,
                                sizeToContent: true,
                                alignment: "right",
                                cellProps: (rowData) => ({
                                    className: "textError",
                                    style: {
                                        textDecoration: state.customerVatPercent !== rowData.previousVat ? "line-through" : undefined,
                                    },
                                }),
                            },
                            {
                                heading: "Current VAT",
                                field: (rowData) => `${rowData.currentVat}%`,
                                sizeToContent: true,
                                alignment: "right",
                                cellProps: (rowData) => ({
                                    className: "textSuccess",
                                    style: {
                                        textDecoration: state.customerVatPercent !== rowData.currentVat ? "line-through" : undefined,
                                    },
                                }),
                            },
                            {
                                heading: "Update VAT?",
                                field: (rowData) => {
                                    const newVat = state.customerVatPercent === rowData.currentVat,
                                        vat = newVat ? rowData.previousVat : rowData.currentVat;
                                    return <Checkbox color="primary" checked={newVat} onChange={() => handleVatChange(vat)} />;
                                },
                                alignment: "right",
                            },
                        ]}
                        rows={[
                            {
                                previousVat,
                                currentVat,
                            },
                        ]}
                    />
                </Grid>
            )}
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <MuiButton color="primary" onClick={() => handleAll(false)} variant="text">
                            De-select All
                        </MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton color="primary" onClick={() => handleAll(true)} variant="text">
                            Select All
                        </MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton color="primary" onClick={handleSubmit} variant="text">
                            Confirm
                        </MuiButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const QuoteOrderReplacements = ({ closeDialog, deployConfirmation, handleSetReplacements, productRows, replacements }) => {
    const initialState = () => ({
        productRows: productRows,
        initialProductRows: JSON.stringify(productRows),
    });

    const [state, setState] = useState(initialState());

    const handleSubmit = () => {
        deployConfirmation("Are you sure to want to replace selected products?", () => {
            const anyProductReplaced = _.some(state.productRows, (el) => el.productId === el.repId);
            handleSetReplacements(state.productRows, anyProductReplaced);
            closeDialog();
        });
    };

    const handleProductChange = (idx, useNewProduct) => {
        let newProductRows = [...state.productRows],
            initial = JSON.parse(state.initialProductRows),
            data = useNewProduct ? initial[idx]?.productData?.replacement : initial[idx]?.productData;

        let discP = data?.discount?.b ?? "0.00";

        if (data?.discount?.p) {
            _.each(data.discount?.p, (pd) => {
                if (productRows[idx].quantity >= pd?.q) {
                    if (parseFloat(pd?.p) > discP) {
                        discP = parseFloat(pd?.p);
                    }
                }
            });
        }

        discP = parseFloat(discP).toFixed(2);

        let price = useNewProduct ? data?.product_price : initial[idx].price;

        let discV = ((parseFloat(price) / 100) * discP).toFixed(2);

        newProductRows[idx].cost = data?.bundleData?.f?.co ?? data?.product_cost;
        newProductRows[idx].discountId = useNewProduct ? 0 : initial[idx].discountId;
        newProductRows[idx].discount = useNewProduct ? discV : initial[idx].discount;
        newProductRows[idx].discountPercent = useNewProduct ? discP : initial[idx].discountPercent;
        newProductRows[idx].discountTotal = useNewProduct ? (parseFloat(discV) * newProductRows[idx].quantity).toFixed(2) : initial[idx].discountTotal;
        newProductRows[idx].image = data?.product_img;
        newProductRows[idx].option = [];
        newProductRows[idx].price = price;
        newProductRows[idx].productCode = data?.product_code;
        newProductRows[idx].productData = data;
        newProductRows[idx].productId = data?.product_id;
        newProductRows[idx].productName = data?.product_name;
        newProductRows[idx].subTotal = useNewProduct ? (parseFloat(data?.product_price ?? 0.0) - parseFloat(discV)).toFixed(2) : initial[idx].subTotal;
        newProductRows[idx].total = useNewProduct ? (parseFloat(initial[idx].quantity) * (parseFloat(price) - parseFloat(discV))).toFixed(2) : initial[idx].total;

        setState((state) => ({
            ...state,
            productRows: newProductRows,
        }));
    };

    const handleAll = (checked) => {
        _.each(replacements, (rep) => {
            handleProductChange(rep.idx, checked);
        });
    };

    useEffect(() => {
        handleAll(true); /*eslint-disable-next-line*/
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <DataTable
                    config={{
                        key: "idx",
                        alternatingRowColours: true,
                        responsiveImportance: true,
                        rowsPerPage: 100,
                        inline: state.showHeadings ? true : undefined,
                    }}
                    columns={[
                        {
                            heading: "Existing Product",
                            field: (rowData) => {
                                const initialProductRows = JSON.parse(state.initialProductRows);
                                const src = initialProductRows[rowData.idx]?.productData?.product_img ? `${CLOUDFRONT_URL}${initialProductRows[rowData.idx]?.productData?.product_img}` : logo;
                                return (
                                    <>
                                        <Box width={400}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3}>
                                                    <Avatar variant="square" alt={"Product Image"} src={src} style={{ width: 75, height: 75 }} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2" className="fw-400">
                                                        {initialProductRows?.[rowData.idx]?.productData?.product_code} - Trade Price {currencyFormat.format(initialProductRows?.[rowData.idx]?.price)}
                                                    </Typography>
                                                    <Typography variant="caption">{initialProductRows?.[rowData.idx]?.productData?.product_name}</Typography>
                                                    <Typography variant="caption" component="div">
                                                        {`${initialProductRows?.[rowData.idx]?.productData?.stock_count} In Stock | ${
                                                            initialProductRows?.[rowData.idx]?.productData?.stock_allocated_count
                                                        } Allocated | ${initialProductRows?.[rowData.idx]?.productData?.stock_available_count} Available | ${
                                                            initialProductRows?.[rowData.idx]?.productData?.stock_on_order_count
                                                        } On Order | ${initialProductRows?.[rowData.idx]?.productData?.stock_on_order_allocated_count} Pre-ordered`}
                                                    </Typography>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <ProductStockChip
                                                                    rep={rowData.repId}
                                                                    status={initialProductRows?.[rowData.idx]?.productData?.product_status}
                                                                    stocked={initialProductRows?.[rowData.idx]?.productData?.product_stock_item === 1}
                                                                    stockAvailable={initialProductRows?.[rowData.idx]?.productData?.stock_available_count}
                                                                    stockOnOrderAvailable={
                                                                        initialProductRows?.[rowData.idx]?.productData?.stock_on_order_count -
                                                                        initialProductRows?.[rowData.idx]?.productData?.stock_on_order_allocated_count
                                                                    }
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                );
                            },
                            important: true,
                            truncate: true,
                            cellProps: (rowData) => ({
                                style: {
                                    textDecoration: state.productRows[rowData.idx].productId === rowData.repId ? "line-through" : undefined,
                                },
                            }),
                        },
                        {
                            heading: "Replacement Product",
                            field: (rowData) => {
                                const src = rowData.rep?.product_img ? `${CLOUDFRONT_URL}${rowData.rep?.product_img}` : logo;
                                return (
                                    <>
                                        <Box width={400}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3}>
                                                    <Avatar variant="square" alt={"Product Image"} src={src} style={{ width: 75, height: 75 }} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2" className="fw-400">
                                                        {rowData.rep?.product_code} - Trade Price {currencyFormat.format(rowData.rep?.product_price)}
                                                    </Typography>
                                                    <Typography variant="caption">{rowData.rep?.product_name}</Typography>
                                                    <Typography variant="caption" component="div">
                                                        {`${rowData.rep?.stock_count} In Stock | ${rowData.rep?.stock_allocated_count} Allocated | ${rowData.rep?.stock_available_count} Available | ${rowData.rep?.stock_on_order_count} On Order | ${rowData.rep?.stock_on_order_allocated_count} Pre-ordered`}
                                                    </Typography>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <ProductStockChip
                                                                    rep={0}
                                                                    status={rowData.rep?.product_status}
                                                                    stocked={rowData.rep?.product_stock_item === 1}
                                                                    stockAvailable={rowData.rep?.stock_available_count}
                                                                    stockOnOrderAvailable={rowData.rep?.stock_on_order_count - rowData.rep?.stock_on_order_allocated_count}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                );
                            },
                            important: true,
                            truncate: true,
                            cellProps: (rowData) => ({
                                style: {
                                    textDecoration: state.productRows[rowData.idx].productId !== rowData.repId ? "line-through" : undefined,
                                },
                            }),
                        },
                        {
                            heading: "Quantity",
                            field: (rowData) => productRows[rowData.idx].quantity,
                            sizeToContent: true,
                            alignment: "center",
                        },
                        {
                            heading: "Replace?",
                            field: (rowData) => {
                                const newProduct = state.productRows[rowData.idx].productId === rowData.repId;
                                return <Checkbox color="primary" checked={newProduct} onChange={() => handleProductChange(rowData.idx, newProduct ? false : true)} />;
                            },
                            sizeToContent: true,
                            alignment: "right",
                        },
                    ]}
                    rows={replacements}
                />
            </Grid>
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <MuiButton color="primary" onClick={() => handleAll(false)} variant="text">
                            De-select All
                        </MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton color="primary" onClick={() => handleAll(true)} variant="text">
                            Select All
                        </MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton color="primary" onClick={handleSubmit} variant="text">
                            Confirm
                        </MuiButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const initialState = () => ({
    access: {
        changePaymentTerms: false,
        manualDiscount: false,
        manualDiscountAny: false
    },
    apiRoute: "quotes",
    blanketDiscount: "",
    carriage: {
        data: {},
        options: [],
    },
    changes: {
        cardOrders: false,
        fulfilment: false,
    },
    containsCardDBProduct: false,
    currentTab: 0,
    customer: {
        contactList: [],
        addressList: [],
        data: {},
        id: 0,
    },
    defaults: {
        defaultCarriage: false,
        defaultFreeCarriage: false,
    },
    dialog: {
        addressLookup: false,
        addressLookupKey: uuidv4(),
        addressType: "delivery",
        useExistingAddress: false
    },
    disableCustomerTab: false,
    document: "Quote",
    docRef: null,
    existingNotes: [],
    formData: {
        blanketDiscount: "",
        carriage: {
            carriageId: "",
            carriageData: {},
            manual: false,
            dc: false,
        },
        contactEmail: "",
        contactPhone: "",
        customer: null,
        customerAddress: "",
        customerAddressCustom: {},
        customerContact: "",
        customerVatPercent: DEFAULT_VAT_PERCENT,
        customerVatValue: DEFAULT_VAT_VALUE,
        deliveryContact: "",
        deliveryAddress: "",
        deliveryAddressCustom: {},
        hot: 0,
        salesPerson: '',
        orderExpectedDate: null,
        deliveryCompany: "",
        deliveryRequestedDate: null,
        deliveryDespatchRef: "",
        deliveryInstructions: "",
        ndUpdates: 0,
        noe: 0,
        notes: "",
        docRef: "",
        paymentTerms: 1,
        productRows: [],
        costTotal: "0.00",
        subTotal: "0.00",
        discountTotal: "0.00",
        carriageTotal: "10.00",
        vatTotal: "0.00",
        netTotal: "0.00",
        grossTotal: "0.00",
        totalMargin: "",
        totalMarkup: "",
        internalNotes: [],
    },
    formDefaults: {
        note: {
            noteTeam: "",
            noteText: "",
        },
    },
    formErrors: [],
    forRMA: false,
    id: false,
    ifm: false,
    initialFormData: {},
    initialLoading: true,
    isLoading: false,
    isLoadingPreview: false,
    lists: {
        cardFormats: [],
        cardProgrammers: [],
        cardTypes: [],
        carriage: [],
        paymentTerms: [],
        teams: [],
    },
    password: "",
    placeOrder: false,
    products: [],
    primaryIdx: null,
    preview: {
        pdf: false,
        numPages: null,
        pageNumber: 1,
    },
    quote: null,
    removedRows: [],
    rma: null,
    section: "Quotes",
    ui: {
        height: 0,
        contentHeight: 0,
    },
    unsavedLine: false
});

class QuoteOrderForm extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleNull = handleNull.bind(this);
        this.poll = false;
    }

    componentDidMount = () => {
        window.addEventListener('keydown', this.handleEmergencyAction);
        Promise.all([API.get(`/accounts/paymentTerms`), API.get("/card-orders/formats"), API.get("/card-orders/programmers"), API.get("/card-orders/types"), API.access(`change-payment-terms`), API.access(`manual-discount-any`),  API.access(`manual-discount`)]).then(
            ([terms, formats, programmers, types, changePaymentTerms, manualDiscountAny, manualDiscount]) => {
                const paymentTerms = _.map(terms.data, (el) =>
                        _.assign({
                            value: el.pt_id,
                            label: el.pt_name,
                        })
                    ),
                    cardFormats = _.map(formats.data, (el) =>
                        _.assign({
                            value: el.format_id,
                            label: el.format_name,
                        })
                    ),
                    cardProgrammers = _.map(programmers.data, (el) =>
                        _.assign({
                            value: el.programmer_id,
                            label: el.programmer_name,
                        })
                    ),
                    cardTypes = _.map(types.data, (el) =>
                        _.assign({
                            value: el.type_id,
                            label: el.type_name,
                        })
                    );
                this.setState(
                    {
                        access: {
                            ...this.state.access,
                            changePaymentTerms: changePaymentTerms?.data?.has_access ?? false,
                            manualDiscount: manualDiscount?.data?.has_access ?? false,
                            manualDiscountAny: manualDiscountAny?.data?.has_access ?? false,
                        },
                        lists: {
                            ...this.state.lists,
                            cardFormats,
                            cardProgrammers,
                            cardTypes,
                            paymentTerms,
                        },
                        placeOrder: !!this.props.placeOrder
                    },
                    this.loadComponentData
                );
            }
        );
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.poll) {
                clearInterval(this.poll);
            }
            this.setState(
                {
                    ...initialState(),
                    access: this.state.access,
                    lists: this.state.lists,
                },
                () => this.loadComponentData()
            );
        }
        if (JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if (!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if (window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    };

    componentWillUnmount = () => {
        if (window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
        if (this.poll) {
            clearInterval(this.poll);
        }
        this.props.closeDialog();
        window.removeEventListener('keydown', this.handleEmergencyAction);
    };

    handleEmergencyAction = (event) => {
        // Check if CTRL + C + I is pressed
        if (event.ctrlKey && event.key === 'i') {
            this.setState({
                currentTab: 1,
                isLoading: false
            }, () => {
                const { formData, password, changes } = this.state;
                console.log({ ...formData, password, changes }); // Log the state
            })
        }
    };

    handlePoll = (initial = false) => {
        API.post(
            `/staff/my/view`,
            {
                quote: this.state.placeOrder ? (this.props.customer ? false : this.props.id ?? this.props.match?.params?.id ?? false) : this.state.document === "Quote" ? this.state.id : 0,
                order: this.state.placeOrder ? 0 : this.state.document !== "Quote" ? this.state.id : 0,
                type: "Update",
            },
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true,
                },
            }
        ).then((res) => {
            if (res?.data) {
                if (initial) {
                    const update = _.some(res.data, (el) => el.u === 1),
                        process = _.some(res.data, (el) => el.p === 1),
                        pick = _.some(res.data, (el) => el.pi === 1),
                        despatch = _.some(res.data, (el) => el.di === 1);

                    if (!_.isEmpty(res.data) && (update || process || pick || despatch)) {
                        this.props.deploySnackBar(
                            "error",
                            `Sorry, another user is currently ${update ? "modifying" : process ? "processing" : pick ? "picking" : "despatching"} this ${this.state.document.toLowerCase()}`
                        );
                        this.props.history.push(`/${this.state.apiRoute}/${this.state.id}`);
                    }
                } else {
                    if(this.state.initialLoading) {
                        this.setState({
                            initialLoading: false,
                        });
                    }
                }
            }
        });
    };

    loadComponentData = () => {
        const { apiRoute, document, section, ui } = this.props;
        const height = window.matchMedia("(display-mode: standalone)").matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight = window.matchMedia("(display-mode: standalone)").matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height - VIEW_PAGE_OFFSET}px)` : height - VIEW_PAGE_OFFSET;
        const id = this.props.customer || this.props.rma ? false : this.props.id ?? this.props.match?.params?.id ?? false;
        this.setState(
            {
                id,
                apiRoute,
                document,
                section,
                ui: {
                    height,
                    contentHeight,
                },
            },
            () => {
                this.getData(true);
                if (this.props.pageTitle) {
                    if (!this.props.replicate && !this.state.placeOrder && !this.props.match?.params?.rma) {
                        this.props.pageTitle([1, this.state.section, `${this.state.id ? "Update" : "New"} ${this.state.document}`]);
                    }
                }
            }
        );
    };

    getData = (initialLoad) => {
        Promise.all([
            // API.get('/customers/all',
            //     {
            //         params: {
            //             forList: true
            //         }
            //     }
            // ),
            API.get(`/couriers/carriageOptions`, {
                params: {
                    forQuoteOrderForm: true,
                },
            }),
        ]).then(([carriageRes]) => {
            if (carriageRes?.data) {
                const initialLoading = this.state.id || this?.props?.match?.params?.rma ? true : false,
                    carriage = {
                        data: carriageRes.data,
                        options: [],
                    };

                let defaultCarriage = false,
                    defaultFreeCarriage = false;

                _.each(carriageRes.data, (el) => {
                    _.each(el.configuration, (_el) => {
                        carriage.options.push({
                            id: _el.courier_conf_id,
                            service: `${el.courier_name} ${_el.courier_conf_service}${
                                _el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `${_el.courier_conf_custom_charge === "true" ? " - Varies" : " - Free"}`
                            }${_el.courier_conf_auth_required === "true" ? ` - Requires Approval` : ""}`,
                            name: `${el.courier_name} ${_el.courier_conf_service}${
                                _el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `${_el.courier_conf_custom_charge === "true" ? " - Varies" : " - Free"}`
                            }`,
                            free: _el.courier_conf_charge === "0.00",
                            varies: _el.courier_conf_custom_charge === "true" ? true : false,
                            requiresApproval: _el.courier_conf_auth_required === "true" ? true : false,
                            collection: _el.courier_conf_service === "Customer Collection",
                            data: {
                                ..._el,
                                courier: el.courier_name,
                            },
                        });
                        if (_el.courier_conf_default === 1) {
                            defaultCarriage = {
                                id: _el.courier_conf_id,
                                service: `${el.courier_name} ${_el.courier_conf_service}${
                                    _el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `${_el.courier_conf_custom_charge === "true" ? " - Varies" : " - Free"}`
                                }${_el.courier_conf_auth_required === "true" ? ` - Requires Approval` : ""}`,
                                name: `${el.courier_name} ${_el.courier_conf_service}${
                                    _el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `${_el.courier_conf_custom_charge === "true" ? " - Varies" : " - Free"}`
                                }`,
                                free: _el.courier_conf_charge === "0.00",
                                varies: _el.courier_conf_custom_charge === "true" ? true : false,
                                requiresApproval: _el.courier_conf_auth_required === "true" ? true : false,
                                collection: _el.courier_conf_service === "Customer Collection",
                                data: {
                                    ..._el,
                                    courier: el.courier_name,
                                },
                            };
                        }
                        if (_el.courier_conf_free_default === 1) {
                            defaultFreeCarriage = {
                                id: _el.courier_conf_id,
                                service: `${el.courier_name} ${_el.courier_conf_service}${
                                    _el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `${_el.courier_conf_custom_charge === "true" ? " - Varies" : " - Free"}`
                                }${_el.courier_conf_auth_required === "true" ? ` - Requires Approval` : ""}`,
                                name: `${el.courier_name} ${_el.courier_conf_service}${
                                    _el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `${_el.courier_conf_custom_charge === "true" ? " - Varies" : " - Free"}`
                                }`,
                                free: _el.courier_conf_charge === "0.00",
                                varies: _el.courier_conf_custom_charge === "true" ? true : false,
                                requiresApproval: _el.courier_conf_auth_required === "true" ? true : false,
                                collection: _el.courier_conf_service === "Customer Collection",
                                data: {
                                    ..._el,
                                    courier: el.courier_name,
                                },
                            };
                        }
                    });
                });

                let formData = initialState().formData,
                    initialFormData = {};

                if (defaultCarriage) {
                    formData = {
                        ...formData,
                        carriage: {
                            ...formData.carriage,
                            carriageId: defaultCarriage.id,
                            carriageData: defaultCarriage,
                        },
                    };
                }

                initialFormData = formData;

                const lists = {
                    ...this.state.lists,
                    carriage: [
                        {
                            label: "Free Delivery",
                            options: _.map(
                                _.filter(carriage.options, function (f) {
                                    return f.free;
                                }),
                                (_el) => {
                                    return _.assign({
                                        value: _el.id,
                                        label: _el.service,
                                    });
                                }
                            ),
                        },
                        {
                            label: "Standard Delivery",
                            options: _.map(
                                _.filter(carriage.options, function (f) {
                                    return !f.free;
                                }),
                                (_el) => {
                                    return _.assign({
                                        value: _el.id,
                                        label: _el.service,
                                    });
                                }
                            ),
                        },
                    ],
                    teams: this.props.teamList,
                };

                _.each(carriage.data, (el) => {
                    _.each(el.configuration, (_el) => {
                        carriage.options.push({
                            id: _el.courier_conf_id,
                            service: `${el.courier_name} ${_el.courier_conf_service}${_el.courier_conf_charge !== "0.00" ? ` (£${_el.courier_conf_charge})` : ""}`,
                            minOrder: _el.courier_conf_min_order_value,
                            data: _el,
                        });
                    });
                });

                let forRMA = this.props.match?.params?.rma ? true : false;

                this.setState(
                    {
                        carriage,
                        defaults: {
                            defaultCarriage,
                            defaultFreeCarriage,
                        },
                        formData,
                        forRMA,
                        initialFormData,
                        initialLoading,
                        lists,
                    },
                    () => {
                        if (initialLoad) {
                            if (this.props.match?.params?.rma) {
                                this.generateSOFromRMA(this.props.match?.params?.rma);
                            } else {
                                if (this.props.match?.params?.customer) {
                                    this.getCustomer(this.props.match.params.customer);
                                }
                                if (this.state.id) {
                                    if (this.props.replicate) {
                                        this.replicate();
                                    } else {
                                        this.getUpdateData(this.state.placeOrder);
                                    }
                                }
                            }
                        }
                    }
                );
            }
        });
    };

    generateSOFromRMA = (rmaId) => {
        API.get(`/rma/${rmaId}`).then((res) => {
            if (res?.data) {
                let rma = res.data,
                    productRows = [],
                    docRef = `RMA ${rma.rma_reference}`;

                if (!_.isEmpty(rma.rma_order_reference) || rma.rma_latest !== 1) {
                    this.props.deploySnackBar("error", `This RMA has already had a replacement sales order generated`);
                    this.props.history.push(`/rma/${res?.data?.rma_id}`);
                }

                _.each(rma.products, (rp) => {
                    if (rp?.rp_product_id > 0) {
                        if (rp?.rp_action_id === 4 || rp?.rp_action_id === 5 || rp?.rp_action_id === 6) {
                            //REPLACE
                            productRows.push({
                                productId: rp?.rp_product_id,
                                productName: rp?.rp_product_name,
                                productCode: rp?.rp_product_code,
                                quantity: rp?.rp_quantity,
                                cost: rp?.rp_cost,
                                price: rp?.rp_paid,
                                subTotal: rp?.rp_paid,
                                discount: "0.00",
                                discountId: "0",
                                discountPercent: "0.00",
                                discountTotal: "0.00",
                                total: (parseInt(rp?.rp_quantity) * rp?.rp_paid).toFixed(2),
                                image: rp?.rp_img,
                                cdbSite: "",
                                cdbIntRangeStart: "",
                                cdbIntRangeEnd: "",
                                cdbExtRangeStart: "",
                                cdbExtRangeEnd: "",
                                cdbComments: "",
                                cdbOrderNo: "",
                                cdbType: "",
                                cdbFormat: "",
                                cdbProgrammer: "",
                                productData: "",
                                rp: rp.rp_id,
                                option: {},
                            });
                        }
                    }
                });

                this.setState(
                    {
                        disableCustomerTab: true,
                        formData: {
                            ...this.state.formData,
                            productRows,
                            docRef,
                        },
                        initialLoading: false,
                        rma,
                    },
                    () => {
                        this.getCustomer(rma.rma_cust_id, true, true);
                    }
                );
            }
        });
    };

    getCustomer = (id, changed = false, rma = false) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                API.get(`/customers/${id}`).then((res) => {
                    if (res?.data) {
                        let customer = res.data,
                            addressList = [],
                            contactList = [],
                            isLoading = changed,
                            sites = [],
                            tradingAddressIdx = 0,
                            paymentTerms = customer?.payment_terms?.pt_id ?? 1,
                            carriage = this.state.formData.carriage;

                        if (customer.cust_deleted === 1) {
                            this.props.history.push(`/customers/${customer.cust_id}`);
                            this.props.deploySnackBar(`error`, `Legacy account deleted on import - please contact Sales Admin to re-instate`);
                        }

                        if (customer.addresses) {
                            tradingAddressIdx = _.findIndex(customer.addresses, (el) => el.t === "Trading");
                            if (tradingAddressIdx < 0) {
                                tradingAddressIdx = 0;
                            }

                            if (customer.addresses?.[tradingAddressIdx]?.dc > 0) {
                                let carriageData = _.find(this.state.carriage.options, (el) => el.id === customer.addresses?.[tradingAddressIdx]?.dc);
                                if (carriageData) {
                                    carriage = {
                                        ...carriage,
                                        carriageId: customer.addresses?.[tradingAddressIdx]?.dc,
                                        carriageData: carriageData,
                                        manual: true,
                                        dc: true,
                                    };
                                }
                            }
                            addressList = _.map(
                                _.filter(customer.addresses, (el) => (el.t === "Delivery" && el.l1 !== customer.addresses?.[tradingAddressIdx]?.l1) || el.t === "Trading"),
                                (el) =>
                                    _.assign({
                                        label: `${el.l1 && `${el.l1}, `}${el.l2 && `${el.l2}, `}${el.l3 && `${el.l3}, `}${el.to && `${el.to}, `}${el.cc && `${el.cc}, `}${el.po}`,
                                        value: el.i,
                                        data: el,
                                    })
                            );
                        }

                        if (customer.contacts) {
                            contactList = _.map(customer.contacts, (el) =>
                                _.assign({
                                    label: `${el.fn.length > 0 && `${el.fn} `}${el.ln}`,
                                    value: el.i,
                                })
                            );
                        }

                        if (customer.sites) {
                            sites = _.map(customer.sites, (el) =>
                                _.assign({
                                    label: `${el.site_code} - ${el.site_description}`,
                                    value: el.site_id,
                                    disabled: el.site_active === 0,
                                    latestOrder: el.latest_order,
                                })
                            );
                        }

                        let customerVatPercent = customer?.vat_type?.default_vat_value?.vat_value_percentage ?? DEFAULT_VAT_PERCENT ?? null,
                            customerVatValue = customer?.vat_type?.default_vat_value?.vat_value_id ?? DEFAULT_VAT_VALUE ?? null;

                        this.setState(
                            {
                                currentTab: 1,
                                customer: {
                                    addressList,
                                    contactList,
                                    data: customer,
                                    id: customer.cust_id,
                                    sites,
                                },
                                formData: {
                                    ...this.state.formData,
                                    carriage,
                                    customer: customer.cust_id,
                                    customerAddress: customer?.addresses?.[tradingAddressIdx]?.i ?? "",
                                    customerVatPercent,
                                    customerVatValue,
                                    paymentTerms,
                                    deliveryCompany: _.isEmpty(this.state.formData?.deliveryCompany) ? customer?.cust_company_name ?? "" : this.state.formData.deliveryCompany,
                                },
                                initialFormData: {
                                    ...this.state.formData,
                                    carriage,
                                    customer: customer.cust_id,
                                    customerAddress: customer?.addresses?.[tradingAddressIdx]?.i ?? "",
                                    customerVatPercent,
                                    customerVatValue,
                                    paymentTerms,
                                    deliveryCompany: _.isEmpty(this.state.formData?.deliveryCompany) ? customer?.cust_company_name ?? "" : this.state.formData.deliveryCompany,
                                },
                                isLoading,
                            },
                            () => {
                                if (this.props.pageTitle) {
                                    if (this.state.forRMA) {
                                        this.props.pageTitle([1, "RMA", `Replacement Sales Order`, customer.cust_company_name, this.state.rma?.rma_reference]);
                                    } else {
                                        this.props.pageTitle([1, this.state.section, `New ${this.state.document}`, customer.cust_company_name]);
                                    }
                                }

                                if (changed) {
                                    let { formData } = this.state;
                                    isLoading = false;

                                    if (!_.isEmpty(formData.productRows)) {
                                        this.handleGetProductData(formData, false, true, customerVatPercent, rma).then((res) => {
                                            let newFormData = res?.formData,
                                                remove = res?.remove,
                                                removed = res?.removed;

                                            if (!_.isEmpty(remove)) {
                                                _.each(remove, (idx) => {
                                                    let rows = [...newFormData?.productRows];
                                                    rows.splice(remove, 1);
                                                    newFormData = {
                                                        ...newFormData,
                                                        productRows: rows,
                                                    };
                                                });
                                                _.each(removed, (row) => {
                                                    this.props.deploySnackBar("error", `${row.productCode} has been removed as it is not valid for this customer`);
                                                });
                                            }

                                            formData = {
                                                ...formData,
                                                ...newFormData,
                                            };

                                            let initialFormData = {
                                                ...formData,
                                                productRows: this.state.initialFormData?.productRows ?? [],
                                            };

                                            this.setState(
                                                {
                                                    formData,
                                                    initialFormData,
                                                    isLoading,
                                                },
                                                this.handleCalculateTotals
                                            );
                                        });
                                    } else {
                                        this.setState({
                                            isLoading,
                                        });
                                    }
                                }
                            }
                        );
                    }
                });
            }
        );
    };

    getCustomerData = (id) => {
        API.get(`/customers/${id}`).then((res) => {
            if (res?.data) {
                let customer = res.data,
                    addressList = [],
                    contactList = [],
                    isLoading = false,
                    sites = [],
                    tradingAddressIdx = 0;

                if (customer.addresses) {
                    tradingAddressIdx = _.findIndex(customer.addresses, (el) => el.t === "Trading");
                    if (tradingAddressIdx < 0) {
                        tradingAddressIdx = 0;
                    }
                    addressList = _.map(
                        _.filter(customer.addresses, (el) => (el.t === "Delivery" && el.l1 !== customer.addresses?.[tradingAddressIdx]?.l1) || el.t === "Trading"),
                        (el) =>
                            _.assign({
                                label: `${el.l1 && `${el.l1}, `}${el.to && `${el.to}, `}${el.po}`,
                                value: el.i,
                                data: el,
                            })
                    );
                }

                if (customer.contacts) {
                    contactList = _.map(customer.contacts, (el) =>
                        _.assign({
                            label: `${el.fn.length > 0 && `${el.fn} `}${el.ln}`,
                            value: el.i,
                        })
                    );
                    if (!_.isEmpty(this.state.customer?.contactList)) {
                        _.each(this.state.customer?.contactList, (con) => {
                            if (!_.find(contactList, (el) => el?.label === con.label && el?.value === con.value)) {
                                contactList.push(con);
                            }
                        });
                    }
                }

                if (customer.sites) {
                    sites = _.map(customer.sites, (el) =>
                        _.assign({
                            label: `${el.site_code} - ${el.site_description}`,
                            value: el.site_id,
                            disabled: el.site_active === 0,
                            latestOrder: el.latest_order,
                        })
                    );
                }

                this.setState(
                    {
                        customer: {
                            addressList,
                            contactList,
                            data: customer,
                            id: customer.cust_id,
                            sites,
                        },
                        formData: {
                            ...this.state.formData,
                            deliveryCompany: _.isEmpty(this.state.formData?.deliveryCompany) ? customer?.cust_company_name ?? "" : this.state.formData.deliveryCompany,
                        },
                        isLoading
                    },
                    () => {
                        this.handleCalculateTotals();
                    }
                );
            }
        });
    };

    getUpdateData = (placeOrder) => {
        const { deploySnackBar, history } = this.props;

        const { apiRoute, id } = this.state;

        let params = {
            forUpdate: true,
        };

        if (placeOrder) {
            params = {
                ...params,
                placeOrder: true,
            };
        }

        API.get(`/${apiRoute}/${id}`, {
            params,
        }).then((res) => {
            if (res?.data) {
                if (apiRoute === "sales" && (res?.data?.order_status === "Cancelled" || res.data?.order_status === "Invoiced" || res.data?.order_status === "Despatched")) {
                    deploySnackBar("error", `This sales order can no longer be modified as it has been ${res.data?.order_status?.toLowerCase?.()}`);
                    history.push(`/sales/${res?.data?.order_id}`);
                    return;
                }

                if (placeOrder && res?.data?.customer?.cust_prospect === 1) {
                    deploySnackBar("error", `An account must be opened before this quote can be ordered`);
                    history.push(`/quotes/${res?.data?.quote_id}`);
                    return;
                }

                let ifm = false;
                if (apiRoute === "sales" && !_.isEmpty(res?.data?.deliveries)) {
                    ifm = true;
                }

                let quote = null,
                    order = null;

                if (apiRoute === "quotes") {
                    if (res.data.quote_status !== "Draft" && res.data.quote_status !== "Open") {
                        this.props.deploySnackBar(
                            "error",
                            `This quote cannot be ${this.state.placeOrder ? "ordered" : "modified"} ${
                                res.data.quote_status === "Awaiting Approval"
                                    ? `whilst it's in the process of being approved`
                                    : res.data.quote_status === "Lost" || res.data.quote_status === "Closed"
                                    ? `as it's been closed`
                                    : res.data.quote_status === "Ordered"
                                    ? `as it's been ordered already, please re-quote to continue`
                                    : ``
                            }`
                        );
                        this.props.history.push(`/quotes/${res.data.quote_id}`);
                    }
                    quote = res.data;
                } else {
                    order = res.data;
                }

                let customer = res.data.customer,
                    customerAddress = '',
                    existingNotes = res.data.notes,
                    formData = apiRoute === "sales" ? JSON.parse(res.data.order_data) : JSON.parse(res.data.quote_data),
                    docRef = apiRoute === "sales" ? JSON.parse(res.data.order_reference) : JSON.parse(res.data.quote_reference),
                    isLoading = true,
                    addressList = [],
                    contactList = [],
                    currentTab = 1,
                    id = placeOrder ? false : this.state.id,
                    tradingAddressIdx = 0,
                    initialProductRows = [...formData.productRows];

                if (customer.addresses) {
                    tradingAddressIdx = _.findIndex(customer.addresses, (el) => el.t === "Trading");
                    if (tradingAddressIdx < 0) {
                        tradingAddressIdx = 0;
                    } else {
                        customerAddress = customer.addresses?.[tradingAddressIdx]?.i ?? '';
                    }
                    addressList = _.map(
                        _.filter(customer.addresses, (el) => (el.t === "Delivery" && el.l1 !== customer?.addresses?.[tradingAddressIdx]?.l1) || el.t === "Trading"),
                        (el) =>
                            _.assign({
                                label: `${el.l1 && `${el.l1}, `}${el.to && `${el.to}, `}${el.po}`,
                                value: el.i,
                                data: el,
                            })
                    );
                }

                if (customer.contacts) {
                    contactList = _.map(customer.contacts, (el) => {
                        return _.assign({
                            label: `${el.fn.length > 0 && `${el.fn} `}${el.ln}`,
                            value: el.i,
                        });
                    });
                }

                if (!_.isEmpty(formData?.customerContact) && isNaN(formData?.customerContact)) {
                    if (_.findIndex(contactList, (el) => el.value === formData.customerContact) === -1) {
                        contactList.push({ label: formData.customerContact, value: formData.customerContact });
                    }
                }

                if (!_.isEmpty(formData?.deliveryContact) && isNaN(formData?.deliveryContact)) {
                    if (_.findIndex(contactList, (el) => el.value === formData.deliveryContact) === -1) {
                        contactList.push({ label: formData.deliveryContact, value: formData.deliveryContact });
                    }
                }

                this.handleGetProductData(formData, false, false, formData.customerVatPercent).then((res) => {
                    let currentVat = customer?.vat_type?.default_vat_value?.vat_value_percentage ?? DEFAULT_VAT_PERCENT ?? null,
                        currentVatValue = customer?.vat_type?.default_vat_value?.vat_value_id ?? DEFAULT_VAT_VALUE ?? null,
                        previousVat = res.formData.customerVatPercent,
                        previousVatValue = res.formData.customerVatValue,
                        vatChange = false,
                        priceChanges = res.priceChanges,
                        replacements = res.replacements,
                        remove = res.remove,
                        removedRows = res.removed,
                        nextDialog = null;

                    if (previousVat !== currentVat) {
                        vatChange = true;
                    }

                    formData = {
                        ...initialState().formData,
                        ...res.formData,
                        customerAddress,
                        blanketDiscount: !_.isEmpty(formData?.blanketDiscount) ? (parseFloat(formData.blanketDiscount) > 0 ? formData.blanketDiscount : "") : "",
                        customerVatPercent: currentVat,
                        customerVatValue: currentVatValue,
                        hot: quote?.quote_hot ?? formData?.hot ?? 0,
                        salesPerson: quote?.quote_sales_staff_id ?? formData?.salesPerson ?? '',
                        deliveryRequestedDate: order?.order_requested_delivery_date ? order?.order_requested_delivery_date !== "0000-00-00" ? order?.order_requested_delivery_date : null : formData?.deliveryRequestedDate ?? null,
                        noe: formData?.noe ?? 0,
                        deliveryCompany: formData?.deliveryCompany ?? "",
                        paymentTerms: formData?.paymentTerms ?? customer?.payment_terms?.pt_id ?? 0,
                        deliveryInstructions: formData?.deliveryInstructions ?? "",
                    };

                    formData.productRows?.forEach((row, idx) => {
                        if (row.productId > 0 || row?.bundleData) {
                            // if(parseFloat(row.subTotal) === parseFloat(row.price) && parseFloat(row.discount).toFixed(2) !== "0.00") {
                            formData.productRows[idx] = {
                                ...formData.productRows[idx],
                                subTotal: (row.price - row.discount).toFixed(2),
                            };
                            // }
                        }
                    });

                    if (!_.isEmpty(remove)) {
                        remove.forEach((remove, idx) => {
                            let rows = [...formData?.productRows];
                            rows.splice(remove, 1);
                            formData = {
                                ...formData,
                                productRows: rows,
                            };
                            priceChanges.forEach((pc, key) => {
                                if (pc.idx > remove) {
                                    priceChanges[key] = {
                                        ...priceChanges[key],
                                        idx: pc.idx - 1,
                                    };
                                } else if (pc.idx === remove) {
                                    priceChanges.splice(remove, 1);
                                }
                            });
                            replacements.forEach((pc, key) => {
                                if (pc.idx > remove) {
                                    replacements[key] = {
                                        ...replacements[key],
                                        idx: pc.idx - 1,
                                    };
                                } else if (pc.idx === remove) {
                                    replacements.splice(remove, 1);
                                }
                            });
                        });
                        _.each(removedRows, (row) => {
                            this.props.deploySnackBar("error", `${row.productCode} has been removed as it is no longer available`);
                        });
                    }

                    let initialFormData = {
                        ...formData,
                        productRows: initialProductRows,
                    };

                    this.setState(
                        {
                            apiRoute: placeOrder ? "sales" : this.state.apiRoute,
                            blanketDiscount: formData?.blanketDiscount ?? "",
                            currentTab,
                            customer: {
                                addressList,
                                contactList,
                                data: customer,
                                id: customer.cust_id,
                            },
                            disableCustomerTab: true,
                            docRef,
                            formData,
                            ifm,
                            initialFormData,
                            existingNotes,
                            id,
                            isLoading,
                            quote,
                            order,
                            removedRows,
                        },
                        () => {
                            if (this.props.pageTitle) {
                                if (placeOrder) {
                                    this.props.pageTitle([1, this.state.section, `Place Order`, customer.cust_company_name, `#${docRef}`]);
                                } else {
                                    this.props.pageTitle([1, this.state.section, `Update ${this.state.document}`, customer.cust_company_name, `#${docRef}`]);
                                }
                            }

                            this.handleCalculateTotals();

                            this.getCustomerData(customer.cust_id);

                            this.handlePoll(true);

                            this.poll = setInterval(this.handlePoll, 3000);

                            if (vatChange || (!_.isEmpty(priceChanges) && !this.state.forRMA)) {
                                if (!_.isEmpty(replacements)) {
                                    nextDialog = () =>
                                        this.props.deployDialog(
                                            <QuoteOrderReplacements
                                                closeDialog={this.props.closeDialog}
                                                deployConfirmation={this.props.deployConfirmation}
                                                handleSetReplacements={this.handleSetReplacements}
                                                productRows={this.state.formData.productRows}
                                                replacements={replacements}
                                            />,
                                            false,
                                            "Product Availability Changes",
                                            "error",
                                            "lg",
                                            false,
                                            true
                                        );
                                }

                                const title = vatChange && !_.isEmpty(priceChanges) ? "Product Pricing and VAT Rate Changes" : vatChange ? "VAT Rate Change" : "Product Pricing Changes";
                                this.props.deployDialog(
                                    <QuoteOrderVariation
                                        closeDialog={this.props.closeDialog}
                                        currentVat={currentVat}
                                        currentVatValue={currentVatValue}
                                        deployConfirmation={this.props.deployConfirmation}
                                        handleSetVariations={this.handleSetVariations}
                                        initialProductRows={JSON.stringify(this.state.formData.productRows)}
                                        nextDialog={nextDialog}
                                        previousVat={previousVat}
                                        previousVatValue={previousVatValue}
                                        priceChanges={priceChanges}
                                        productRows={this.state.formData.productRows}
                                        vatChange={vatChange}
                                    />,
                                    false,
                                    title,
                                    "error",
                                    "md",
                                    false,
                                    true
                                );
                            } else {
                                if (!_.isEmpty(replacements)) {
                                    this.props.deployDialog(
                                        <QuoteOrderReplacements
                                            closeDialog={this.props.closeDialog}
                                            deployConfirmation={this.props.deployConfirmation}
                                            handleSetReplacements={this.handleSetReplacements}
                                            productRows={this.state.formData.productRows}
                                            replacements={replacements}
                                        />,
                                        false,
                                        "Product Availability Changes",
                                        "error",
                                        "lg",
                                        false,
                                        true
                                    );
                                }
                            }
                        }
                    );
                });
            }
        });
    };

    replicate = () => {
        const { apiRoute, id } = this.state;
        API.get(`/${apiRoute}/${id}`, {
            params: {
                forUpdate: true,
            },
        }).then((res) => {
            if (res?.data) {
                let customer = res.data.customer,
                    customerAddress = '',
                    formData = apiRoute === "sales" ? JSON.parse(res.data.order_data) : JSON.parse(res.data.quote_data),
                    initialLoading = false,
                    isLoading = true,
                    addressList = [],
                    contactList = [],
                    currentTab = 1,
                    id = false,
                    tradingAddressIdx = 0;

                formData.productRows?.forEach((row, idx) => {
                    if (parseFloat(row.subTotal) === parseFloat(row.price) && parseFloat(row.discount).toFixed(2) !== "0.00") {
                        formData.productRows[idx] = {
                            ...formData.productRows[idx],
                            subTotal: (row.price - row.discount).toFixed(2),
                        };
                    }
                });

                formData.blanketDiscount = initialState().formData.blanketDiscount;

                if (customer.addresses) {
                    tradingAddressIdx = _.findIndex(customer.addresses, (el) => el.t === "Trading");
                    if (tradingAddressIdx < 0) {
                        tradingAddressIdx = 0;
                    } else {
                        customerAddress = customer.addresses?.[tradingAddressIdx]?.i ?? '';
                    }
                    addressList = _.map(
                        _.filter(customer.addresses, (el) => (el.t === "Delivery" && el.l1 !== customer?.addresses?.[tradingAddressIdx]?.l1) || el.t === "Trading"),
                        (el) =>
                            _.assign({
                                label: `${el.l1 && `${el.l1}, `}${el.to && `${el.to}, `}${el.po}`,
                                value: el.i,
                                data: el,
                            })
                    );
                }

                if (customer.contacts) {
                    contactList = _.map(customer.contacts, (el) => {
                        return _.assign({
                            label: `${el.fn.length > 0 && `${el.fn} `}${el.ln}`,
                            value: el.i,
                        });
                    });
                }

                if (!_.isEmpty(formData?.customerContact) && isNaN(formData?.customerContact)) {
                    if (_.findIndex(contactList, (el) => el.value === formData.customerContact) === -1) {
                        contactList.push({ label: formData.customerContact, value: formData.customerContact });
                    }
                }

                if (!_.isEmpty(formData?.deliveryContact) && isNaN(formData?.deliveryContact)) {
                    if (_.findIndex(contactList, (el) => el.value === formData.deliveryContact) === -1) {
                        contactList.push({ label: formData.deliveryContact, value: formData.deliveryContact });
                    }
                }

                let customerVatPercent = customer?.vat_type?.default_vat_value?.vat_value_percentage ?? DEFAULT_VAT_PERCENT ?? null,
                    customerVatValue = customer?.vat_type?.default_vat_value?.vat_value_id ?? DEFAULT_VAT_VALUE ?? null,
                    paymentTerms = customer?.payment_terms?.pt_id ?? 1;

                this.handleGetProductData(formData, true, false, customerVatPercent).then((res) => {
                    if (res) {
                        formData = {
                            ...initialState().formData,
                            ...res.formData,
                            blanketDiscount: formData.blanketDiscount,
                            customerVatPercent,
                            customerVatValue,
                            paymentTerms,
                            customerAddress,
                            deliveryRequestedDate: null,
                            orderExpectedDate: null,
                            quoteId: false,
                            internalNotes: [],
                            hot: 0,
                            salesPerson: '',
                            noe: formData?.noe ?? 0,
                            deliveryCompany: formData?.deliveryCompany ?? "",
                            docRef: "",
                            deliveryDespatchRef: "",
                            deliveryInstructions: formData?.deliveryInstructions ?? "",
                        };

                        // delivery change
                        // if (this.state.defaults?.defaultCarriage) {
                        if (this.state.defaults?.defaultCarriage && !formData.carriage?.dc) {
                            formData = {
                                ...formData,
                                carriage: {
                                    ...formData.carriage,
                                    carriageId: this.state.defaults?.defaultCarriage?.id,
                                    carriageData: this.state.defaults?.defaultCarriage,
                                },
                            };
                        }

                        let initialFormData = formData,
                            replacements = res.replacements,
                            remove = res.remove,
                            removedRows = res.removed;

                        if (!_.isEmpty(remove)) {
                            remove.forEach((remove, idx) => {
                                let rows = [...formData?.productRows];
                                rows.splice(remove, 1);
                                formData = {
                                    ...formData,
                                    productRows: rows,
                                };
                                replacements.forEach((pc, key) => {
                                    if (pc.idx > remove) {
                                        replacements[key] = {
                                            ...replacements[key],
                                            idx: pc.idx - 1,
                                        };
                                    } else if (pc.idx === remove) {
                                        replacements.splice(remove, 1);
                                    }
                                });
                            });
                            _.each(removedRows, (row) => {
                                this.props.deploySnackBar("error", `${row.productCode} has been removed as it is no longer available`);
                            });
                        }

                        let containsCardDBProduct = false;

                        let productRows = formData.productRows;

                        formData?.productRows.forEach((row, idx) => {
                            if (row?.cdbSite !== "") {
                                containsCardDBProduct = true;

                                let site = _.find(customer.sites, (el) => el.site_id === row.cdbSite);

                                if (site) {
                                    productRows[idx] = {
                                        ...productRows[idx],
                                        cdbIntRangeStart: this.handleCalculateCardOrderRange(site?.latest_order?.co_internal_range_end ?? 0, 1),
                                        cdbIntRangeEnd: this.handleCalculateCardOrderRange(site?.latest_order?.co_internal_range_end ?? 0, parseInt(row.quantity)),
                                        cdbExtRangeStart: this.handleCalculateCardOrderRange(site?.latest_order?.co_external_range_end ?? 0, 1),
                                        cdbExtRangeEnd: this.handleCalculateCardOrderRange(site?.latest_order?.co_external_range_end ?? 0, parseInt(row.quantity)),
                                        // cdbSite: '',
                                        // cdbIntRangeStart: '',
                                        // cdbIntRangeEnd: '',
                                        // cdbExtRangeStart: '',
                                        // cdbExtRangeEnd: '',
                                        // cdbComments :'',
                                        // cdbOrderNo: '',
                                        // cdbType: '',
                                        // cdbFormat: '',
                                        // cdbProgrammer: ''
                                    };
                                } else {
                                    productRows[idx] = {
                                        ...productRows[idx],
                                        cdbSite: "",
                                        cdbIntRangeStart: "",
                                        cdbIntRangeEnd: "",
                                        cdbExtRangeStart: "",
                                        cdbExtRangeEnd: "",
                                        cdbComments: "",
                                        cdbOrderNo: "",
                                        // cdbType: '',
                                        // cdbFormat: '',
                                        // cdbProgrammer: ''
                                    };
                                }
                            }
                        });

                        if (containsCardDBProduct) {
                            formData = {
                                ...formData,
                                productRows,
                            };
                        }

                        this.setState(
                            {
                                blanketDiscount: formData?.blanketDiscount ?? "",
                                containsCardDBProduct,
                                currentTab,
                                customer: {
                                    addressList,
                                    contactList,
                                    data: customer,
                                    id: customer.cust_id,
                                },
                                formData,
                                ifm: false,
                                initialFormData,
                                id,
                                initialLoading,
                                isLoading,
                                removedRows,
                            },
                            () => {
                                if (this.props.pageTitle) {
                                    this.props.pageTitle([1, this.state.section, `Re-${this.state.document.toLowerCase()}`, customer.cust_company_name]);
                                }

                                if (!_.isEmpty(replacements)) {
                                    this.props.deployDialog(
                                        <QuoteOrderReplacements
                                            closeDialog={this.props.closeDialog}
                                            deployConfirmation={this.props.deployConfirmation}
                                            handleSetReplacements={this.handleSetReplacements}
                                            productRows={this.state.formData.productRows}
                                            replacements={replacements}
                                        />,
                                        false,
                                        "Product Availability Changes",
                                        "error",
                                        "lg",
                                        false,
                                        true
                                    );
                                }

                                this.handleCalculateTotals();
                                this.getCustomerData(customer.cust_id);
                            }
                        );
                    }
                });
            }
        });
    };

    handleCalculateCardOrderRange = (rawValue, qty) => {
        let v = rawValue;
        if (_.isNumber(v)) {
            v = parseFloat(v).toFixed(0);
        }

        let value = [...v];

        if (!_.isEmpty(value)) {
            value.reverse();

            let int = [],
                len = 0;

            for (let i = 0; i < value.length; i++) {
                if (isNumeric(value[i])) {
                    int.push(value[i]);
                } else {
                    break;
                }
            }

            if (!_.isEmpty(int)) {
                value.splice(0, int.length);
            }

            value.reverse();

            if (!_.isEmpty(int)) {
                int = int.reverse().join("");
                len = int.length;
                int = String(parseInt(int) + qty).padStart(len, "0");
                value = value.join("") + int;
            } else {
                value = value.join("");
            }

            return value;
        } else {
            return qty;
        }
    };

    handleGetProductData = (fd, forceUpdate = false, customerChanged = false, vatPercent = null, rma = false) =>
        new Promise((resolve, reject) => {
            let productRows = fd.productRows,
                priceChanges = [],
                replacements = [],
                remove = [],
                removed = [];

            _.each(productRows, (row, idx) => {
                if (forceUpdate) {
                    delete row.efs;
                }

                if (row?.bundleId) {
                    API.post(`/products/bundles/${row.bundleId}/checkBundle`, {
                        customer: fd?.customer,
                        bundleData: row.bundleData ?? [],
                    }).then((res) => {
                        if (res?.data?.notActive || res?.data?.bundleChanged) {
                            remove.push(idx);
                            removed.push({ ...row, r: res?.data?.notActive ? "Expired" : "Modified" });

                            if (parseInt(idx) === productRows.length - 1) {
                                const formData = {
                                    ...fd,
                                    productRows,
                                };

                                setTimeout(() => resolve({ formData, priceChanges, replacements, remove, removed }), 250);
                            }
                        } else if (res?.data) {
                            let previousPrice = productRows[idx]?.price,
                                previousCost = productRows[idx]?.cost,
                                currentCost = res.data?.currentBuyPrice,
                                actualPrice = res.data?.actualTradePrice,
                                currentPrice = res.data?.currentTradePrice,
                                isKit = res.data?.isKit;

                            if (isKit === 1 && (customerChanged || forceUpdate)) {
                                
                                let discP = actualPrice > 0 ? parseFloat(((actualPrice - currentPrice) / actualPrice) * 100).toFixed(2) : 0.00;

                                let discV = parseFloat(actualPrice - currentPrice).toFixed(2);

                                productRows[idx] = {
                                    ...productRows[idx],
                                    discount: discV,
                                    discountId: 0,
                                    discountPercent: discP,
                                    discountTotal: parseFloat(discV) * productRows[idx].quantity,
                                    price: actualPrice,
                                    cost: currentCost,
                                    subTotal: currentPrice,
                                    total: (parseInt(productRows[idx].quantity) * parseFloat(currentPrice)).toFixed(2),
                                    image: res.data.img,
                                    productCode: res.data.pc + ' - ' + res.data.nm,
                                    bundle_check: res.data,
                                    productData: {},
                                    bundleData: {
                                        ...productRows[idx].bundleData,
                                        b: {
                                            ...productRows[idx].bundleData.b,
                                            nm: res.data.nm,
                                            img: res.data.img,
                                        },
                                        f: {
                                            ...productRows[idx].bundleData.f,
                                            co: currentCost,
                                            dc: discV,
                                            dp: discP,
                                            tp: actualPrice,
                                            bp: currentPrice,
                                            mr: parseFloat(((parseFloat(currentPrice) - parseFloat(currentCost)) / parseFloat(currentPrice)) * 100).toFixed(2),
                                        },
                                    },
                                };

                            } else if (isKit === 0 && customerChanged) {

                                remove.push(idx);
                                removed.push({ ...row, r: "Customer Changed" });
    
                                if (parseInt(idx) === productRows.length - 1) {
                                    const formData = {
                                        ...fd,
                                        productRows,
                                    };
    
                                    setTimeout(() => resolve({ formData, priceChanges, replacements, remove, removed }), 250);

                                }

                            } else if (forceUpdate && (parseFloat(previousPrice) !== parseFloat(currentPrice) || parseFloat(previousCost) !== parseFloat(currentCost))) {
                                let discP = res.data.discountPercent;

                                let discV = parseFloat((currentPrice / 100) * discP).toFixed(2);

                                let subTotal = parseFloat(currentPrice - discV).toFixed(2)

                                productRows[idx] = {
                                    ...productRows[idx],
                                    discount: discV,
                                    discountId: 0,
                                    discountPercent: discP,
                                    discountTotal: parseFloat(discV) * productRows[idx].quantity,
                                    price: currentPrice,
                                    cost: currentCost,
                                    subTotal: subTotal,
                                    total: (parseInt(productRows[idx].quantity) * parseFloat(subTotal)).toFixed(2),
                                    image: res.data.img,
                                    productCode: res.data.pc + ' - ' + res.data.nm,
                                    bundle_check: res.data,
                                    productData: {},
                                    bundleData: {
                                        ...productRows[idx].bundleData,
                                        b: {
                                            ...productRows[idx].bundleData.b,
                                            nm: res.data.nm,
                                            img: res.data.img,
                                        },
                                        f: {
                                            ...productRows[idx].bundleData.f,
                                            co: currentCost,
                                            dc: discV,
                                            dp: discP,
                                            tp: currentPrice,
                                            bp: subTotal,
                                            mr: parseFloat(((parseFloat(subTotal) - parseFloat(currentCost)) / parseFloat(subTotal)) * 100).toFixed(2),
                                        },
                                    },
                                };

                            } else {
                                productRows[idx] = {
                                    ...productRows[idx],
                                    image: res.data.img,
                                    productCode: res.data.pc + ' - ' + res.data.nm,
                                    bundle_check: res.data,
                                    productData: {},
                                    bundleData: {
                                        ...productRows[idx].bundleData,
                                        b: {
                                            ...productRows[idx].bundleData.b,
                                            nm: res.data.nm,
                                            img: res.data.img,
                                        },
                                    },
                                };
                            }

                            if (parseInt(idx) === productRows.length - 1) {
                                const formData = {
                                    ...fd,
                                    productRows,
                                };

                                setTimeout(() => resolve({ formData, priceChanges, replacements, remove, removed }), 250);
                            }
                        }
                    });
                } else if (row.productId <= 0) {
                    if (parseInt(idx) === productRows.length - 1) {
                        const formData = {
                            ...fd,
                            productRows,
                        };

                        setTimeout(() => resolve({ formData, priceChanges, replacements, remove, removed }), 250);
                    } else {
                        return;
                    }
                } else {
                    API.get(`/products/${row.productId}`, {
                        params: {
                            forQuoteOrderForm: true,
                            forCustomer: fd?.customer,
                        },
                    }).then((res) => {
                        if (res?.data?.invalid) {
                            remove.push(idx);
                            removed.push(row);

                            if (parseInt(idx) === productRows.length - 1) {
                                const formData = {
                                    ...fd,
                                    productRows,
                                };

                                setTimeout(() => resolve({ formData, priceChanges, replacements, remove, removed }), 250);
                            }
                        } else if (res?.data) {
                            const previousPrice = productRows[idx]?.price,
                                currentPrice = res?.data?.product_price,
                                previousCost = productRows[idx]?.cost,
                                currentCost = res?.data?.product_cost,
                                productName = res?.data?.product_name,
                                productCode = res?.data?.product_code,
                                quantity = productRows[idx]?.quantity,
                                repId = res?.data?.product_rep_product_id,
                                rep = res?.data?.replacement;

                            if (!customerChanged && (previousPrice !== currentPrice || previousCost !== currentCost) && row?.efs !== "Invoiced" && row?.efs !== "Despatched") {
                                if (forceUpdate) {
                                    let discP = res.data.discount?.b ?? "0.00";

                                    if (res.data.discount?.p) {
                                        _.each(res.data.discount?.p, (pd) => {
                                            if (productRows[idx].quantity >= pd?.q) {
                                                if (parseFloat(pd?.p) > discP) {
                                                    discP = parseFloat(pd?.p);
                                                }
                                            }
                                        });
                                    }

                                    discP = parseFloat(discP).toFixed(2);

                                    let discV = ((res.data.product_price / 100) * discP).toFixed(2);

                                    productRows[idx] = {
                                        ...productRows[idx],
                                        discount: discV,
                                        discountId: 0,
                                        discountPercent: discP,
                                        discountTotal: parseFloat(discV) * productRows[idx].quantity,
                                        price: res.data.product_price,
                                        cost: res.data.product_cost,
                                        subTotal: (res.data.product_price - discV).toFixed(2),
                                        total: (parseInt(productRows[idx].quantity) * parseFloat((res.data.product_price - discV).toFixed(2))).toFixed(2),
                                        image: res.data.product_img,
                                        productData: res.data,
                                        previousPrice
                                    };
                                } else {
                                    let discV = ((res.data.product_price / 100) * productRows[idx].discountPercent).toFixed(2);

                                    productRows[idx] = {
                                        ...productRows[idx],
                                        cost: res.data.product_cost,
                                        price: res.data.product_price,
                                        discount: discV,
                                        discountTotal: (parseFloat(productRows[idx].quantity) * parseFloat(discV)).toFixed(2),
                                        subTotal: (parseFloat(res.data.product_price) - parseFloat(discV)).toFixed(2),
                                        total: (parseFloat(productRows[idx].quantity) * (parseFloat(res.data.product_price) - parseFloat(discV))).toFixed(2),
                                        image: res.data.product_img,
                                        productData: res.data,
                                    };

                                    if(previousPrice !== currentPrice) {
                                        priceChanges.push({
                                            idx,
                                            productCode,
                                            productName,
                                            quantity,
                                            previousPrice,
                                            currentPrice,
                                        });
                                    }
                                }
                            } else {
                                // if((customerChanged || forceUpdate) && !rma) {
                                if (customerChanged || forceUpdate || rma) {
                                    let discP = res.data.discount?.b ?? "0.00";

                                    if (res.data.discount?.p) {
                                        _.each(res.data.discount?.p, (pd) => {
                                            if (productRows[idx].quantity >= pd?.q) {
                                                if (parseFloat(pd?.p) > discP) {
                                                    discP = parseFloat(pd?.p);
                                                }
                                            }
                                        });
                                    }

                                    discP = parseFloat(discP).toFixed(2);

                                    let discV = ((res.data.product_price / 100) * discP).toFixed(2);

                                    productRows[idx] = {
                                        ...productRows[idx],
                                        discount: discV,
                                        discountId: 0,
                                        discountPercent: discP,
                                        discountTotal: parseFloat(discV) * productRows[idx].quantity,
                                        cost: res.data.product_cost,
                                        price: res.data.product_price,
                                        previousPrice: productRows[idx]?.price ?? res.data.product_price,
                                        subTotal: (res.data.product_price - discV).toFixed(2),
                                        total: (parseFloat(productRows[idx].quantity) * parseFloat((res.data.product_price - discV).toFixed(2))).toFixed(2),
                                        image: res.data.product_img,
                                        productData: res.data,
                                    };
                                } else {
                                    productRows[idx] = {
                                        ...productRows[idx],
                                        image: res.data.product_img,
                                        productData: res.data,
                                    };
                                }
                            }

                            if (row?.efs !== "Invoiced" && row?.efs !== "Despatched" && res.data.product_rep_product_id > 0) {
                                replacements.push({
                                    idx,
                                    productCode,
                                    productName,
                                    repId,
                                    rep,
                                });
                            }

                            if (parseInt(idx) === productRows.length - 1) {
                                const formData = {
                                    ...fd,
                                    productRows,
                                };

                                setTimeout(() => resolve({ formData, priceChanges, replacements, remove, removed }), 250);
                            }
                        }
                    });
                }
            });
        });

    handleSwitchApiRoute = () => {
        let { id, placeOrder } = this.state;
        const { pageTitle, replicate } = this.props;
        const { apiRoute, currentTab } = this.state;

        if(placeOrder) {
            id = this.props.customer || this.props.rma ? false : this.props.id ?? this.props.match?.params?.id ?? false;
            placeOrder = false;
        } else if(apiRoute === "quotes" && id) {
            id = false;
            placeOrder = true;
        }
            
        this.setState(
            {
                section: apiRoute === "sales" ? "Quotes" : "Sales Orders",
                document: apiRoute === "sales" ? "Quote" : "Order",
                apiRoute: apiRoute === "sales" ? "quotes" : "sales",
                id,
                placeOrder
            },
            () => {
                if (currentTab === 5) {
                    this.handleSubmitPreview();
                }

                if (pageTitle) {
                    pageTitle([1, this.state.section, placeOrder ? `Place Order` : `${replicate ? `Re-${this.state.document?.toLowerCase()}` : `${id ? `Update` : `New`} ${this.state.document}`}`, this.state.customer.data?.cust_company_name ?? null, this.state.docRef ? `#${this.state.docRef}` : null]);
                }
            }
        );
    };

    handleTabChange = (event, tabIndex) => {
        const currentTab = !isNaN(event) ? event : tabIndex,
            { formData, document, unsavedLine } = this.state;

        if(unsavedLine) {
            this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to change tab?</Typography><Typography variant="body2">You have an unsaved line</Typography></>, () => {
                this.setState({ unsavedLine: false }, () => {
                    this.handleDoTabChange(currentTab);
                });
            });
        } else {
            if (currentTab === 0) {
                this.props.deployConfirmation("Are you sure you want to change the customer?", this.handleCustomerReset);
            } else {
                if (document === "Order" && currentTab === 4 && this.state.currentTab === 3) {
                    if (_.every(formData.productRows, (el) => !el.productData?.cDb || el.productData?.cDb === 0)) {
                        this.handleDoTabChange(5);
                    } else {
                        this.handleDoTabChange(4);
                    }
                } else if (document === "Order" && currentTab === 4 && this.state.currentTab === 5) {
                    if (_.every(formData.productRows, (el) => !el.productData?.cDb || el.productData?.cDb === 0)) {
                        this.handleDoTabChange(3);
                    } else {
                        this.handleDoTabChange(4);
                    }
                } else if (document === "Quote" && this.state.currentTab === 3 && currentTab === 4) {
                    this.handleDoTabChange(5);
                } else if (document === "Quote" && this.state.currentTab === 4 && currentTab === 5) {
                    this.handleDoTabChange(3);
                } else {
                    this.handleDoTabChange(currentTab);
                }
            }
        }
    };

    handleDoTabChange = (currentTab) => {
        const isLoading = currentTab === 5 ? true : this.state.isLoading;
        this.setState(
            {
                currentTab,
                isLoading,
            },
            () => {
                if (this.state.currentTab === 5) {
                    this.handleSubmitPreview();
                }
                if (this.mainContentArea && this.mainContentArea.current) this.mainContentArea.current.scrollTop = 0;
            }
        );
    };

    handleApplyOrderDiscount = (e) => {
        let { access, blanketDiscount } = this.state;

        if (!_.isEmpty(blanketDiscount)) {
            blanketDiscount = parseFloat(blanketDiscount);

            if (blanketDiscount > 0 && blanketDiscount <= 100) {
                blanketDiscount = blanketDiscount.toFixed(2);

                this.setState(
                    {
                        blanketDiscount: initialState().blanketDiscount,
                        formData: {
                            ...this.state.formData,
                            blanketDiscount,
                        },
                    },
                    () => {
                        let {
                            formData: { productRows },
                        } = this.state;

                        if (!_.isEmpty(productRows)) {
                            productRows.forEach((row, idx) => {
                                if (!row?.bundleId && row?.productData?.product_no_discount === 0) {

                                    let discountPercent = parseFloat(blanketDiscount);
                                    
                                    let supplierId = row?.productData?.product_supplier_id ?? null;
                                    let maxDiscount = access.manualDiscountAny ? 100 : access.manualDiscount ? MANUAL_DISCOUNT_THRESHOLD : 0;
                                    if(supplierId && this.props.discount?.[supplierId]) {
                                        maxDiscount = parseFloat(this.props.discount[supplierId]);
                                    }

                                    if(discountPercent > maxDiscount) {
                                        discountPercent = maxDiscount;
                                    }
                                    
                                    discountPercent = parseFloat(row?.productData?.discount?.b ?? 0) > discountPercent ? parseFloat(row?.productData?.discount?.b ?? 0) : discountPercent;

                                    let discount = ((row.price / 100) * discountPercent).toFixed(2),
                                        subTotal = (row.price - discount).toFixed(2),
                                        total = (row.quantity * (row.price - discount)).toFixed(2),
                                        discountTotal = (row.quantity * discount).toFixed(2);

                                    productRows[idx] = {
                                        ...productRows[idx],
                                        discount,
                                        subTotal,
                                        total,
                                        discountTotal,
                                        discountPercent,
                                    };
                                }
                            });
                        }

                        this.setState(
                            {
                                formData: {
                                    ...this.state.formData,
                                    productRows,
                                },
                            },
                            () => {
                                this.handleCalculateTotals();
                            }
                        );
                    }
                );
            } else {
                blanketDiscount = "";

                this.setState({
                    blanketDiscount,
                    formData: {
                        ...this.state.formData,
                        blanketDiscount,
                    },
                });
            }
        }
    };

    handleResetOrderDiscount = () => {
        let {
            formData: { productRows },
        } = this.state;

        if (!_.isEmpty(productRows)) {
            productRows.forEach((row, idx) => {
                if (!row?.bundleId || row?.productData?.product_no_discount === 0) {
                    let discountPercent = parseFloat(row?.productData?.discount?.b ?? 0);

                    let discount = ((row.price / 100) * discountPercent).toFixed(2),
                        subTotal = (row.price - discount).toFixed(2),
                        total = (row.quantity * (row.price - discount)).toFixed(2),
                        discountTotal = (row.quantity * discount).toFixed(2);

                    productRows[idx] = {
                        ...productRows[idx],
                        discount,
                        subTotal,
                        total,
                        discountTotal,
                        discountPercent,
                    };
                }
            });
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    productRows,
                    blanketDiscount: "",
                },
            },
            () => {
                this.handleCalculateTotals();
            }
        );
    };

    handleBlanketDiscountChange = (e) => {

        let blanketDiscount = e?.target?.value ?? "";

        if (!_.isEmpty(blanketDiscount)) {

            let parsedBlanketDiscount = parseFloat(blanketDiscount);

            if (parsedBlanketDiscount < 0 || parsedBlanketDiscount > 100) {
                blanketDiscount = "";
            }

        }

        this.setState({
            blanketDiscount,
        });
    };

    handleCalculateTotals = () => {
        const { customer, defaults, document, formData } = this.state;

        let costTotal = "0.00",
            subTotal = "0.00",
            discountTotal = "0.00",
            carriageTotal = "0.00",
            netTotal = "0.00",
            vatTotal = "0.00",
            linesTotal = "0.00",
            grossTotal = "0.00",
            totalMargin = 0,
            totalMarkup = 0,
            subDiscountTotal = 0;

        formData.productRows.forEach((row) => {
            if (row.productId > 0 || row?.bundleData) {
                costTotal = (parseFloat(costTotal) + parseFloat(row?.bundleData?.f?.co ?? row.productData?.product_cost) * row.quantity).toFixed(2);
                subTotal = (parseFloat(row.price) * parseFloat(row.quantity) + parseFloat(subTotal)).toFixed(2);
                discountTotal = (parseFloat(discountTotal) + parseFloat(row.discountTotal)).toFixed(2);
                netTotal = (parseFloat(netTotal) + parseFloat(row.total)).toFixed(2);
                subDiscountTotal = row.price * (row.discountPercent / 100) * row.quantity + parseFloat(subDiscountTotal);
            }
        });

        linesTotal = netTotal;

        if (formData.carriage.carriageId !== "") {
            carriageTotal = parseFloat(formData.carriage?.carriageData?.data?.courier_conf_charge).toFixed(2);
            netTotal = (parseFloat(netTotal) + parseFloat(carriageTotal)).toFixed(2);
        }

        if (formData.customerVatPercent !== null) {
            vatTotal = ((netTotal / 100) * formData.customerVatPercent).toFixed(2);
        }

        grossTotal = (parseFloat(netTotal) + parseFloat(vatTotal)).toFixed(2);

        totalMargin = (subTotal - discountTotal) === 0 ? "0.00" : (((subTotal - discountTotal - costTotal) / (subTotal - discountTotal)) * 100).toFixed(2);
        totalMarkup = (subTotal - discountTotal) === 0 ? "0.00" : (((subTotal - discountTotal - costTotal) / costTotal) * 100).toFixed(2);

        let {
            changes: { cardOrders, fulfilment },
            initialFormData,
        } = this.state;

        let initialRows = [],
            initialCardOrders = [];
        _.each(initialFormData.productRows, (el) => {
            initialRows.push({
                pid: el.productId,
                qty: el.quantity,
            });
        });

        let currentRows = [],
            currentCardOrders = [];

        let carriageZoneApplies = this.state.formData.carriage?.dc > 0 && parseFloat(carriageTotal).toFixed(2) !== parseFloat(this.state.formData.carriageTotal).toFixed(2);

        _.each(formData.productRows, (el) => {
            currentRows.push({
                pid: el.productId,
                qty: el.quantity,
            });

            if (el.productData?.cDb === 1) {
                currentCardOrders.push({
                    qty: el.quantity,
                    cdbSite: el.cdbSite,
                    cdbIntRangeStart: el.cdbIntRangeStart,
                    cdbIntRangeEnd: el.cdbIntRangeEnd,
                    cdbExtRangeStart: el.cdbExtRangeStart,
                    cdbExtRangeEnd: el.cdbExtRangeEnd,
                    cdbComments: el.cdbComments,
                    cdbOrderNo: el.cdbOrderNo,
                    cdbType: el.cdbType,
                    cdbFormat: el.cdbFormat,
                    cdbProgrammer: el.cdbProgrammer,
                });

                let or = _.find(initialFormData.productRows, (_el) => _el.op === el.op);

                if (or) {
                    initialCardOrders.push({
                        qty: or.quantity,
                        cdbSite: or.cdbSite,
                        cdbIntRangeStart: or.cdbIntRangeStart,
                        cdbIntRangeEnd: or.cdbIntRangeEnd,
                        cdbExtRangeStart: or.cdbExtRangeStart,
                        cdbExtRangeEnd: or.cdbExtRangeEnd,
                        cdbComments: or.cdbComments,
                        cdbOrderNo: or.cdbOrderNo,
                        cdbType: or.cdbType,
                        cdbFormat: or.cdbFormat,
                        cdbProgrammer: or.cdbProgrammer,
                    });
                }
            }
        });

        cardOrders = JSON.stringify(initialCardOrders) === JSON.stringify(currentCardOrders) ? false : true;

        fulfilment =
            formData.deliveryRequestedDate === initialFormData.deliveryRequestedDate &&
            JSON.stringify(initialRows) === JSON.stringify(currentRows) &&
            _.every(formData.productRows, (el) => typeof el.op !== "undefined")
                ? false
                : true;

        if (!fulfilment && _.some(formData.productRows, (el) => el.bundleId)) {
            fulfilment = true;
        }

        this.setState(
            {
                changes: {
                    ...this.state.changes,
                    cardOrders,
                    fulfilment,
                },
                formData: {
                    ...this.state.formData,
                    costTotal,
                    subTotal,
                    discountTotal,
                    carriageTotal,
                    vatTotal,
                    netTotal,
                    grossTotal,
                    totalMargin,
                    totalMarkup,
                    subDiscountTotal,
                },
            },
            () => {
                if (customer?.data?.cust_foc_shipping && !formData?.carriage?.manual) {
                    if (parseFloat(linesTotal) >= parseFloat(customer.data.cust_foc_shipping)) {
                        if(customer.data?.freeDelivery?.id) {
                            if(formData?.carriage?.carriageId !== customer.data.freeDelivery.id) {
                                const method = _.find(this.state.carriage.options, el => el.id === customer.data.freeDelivery.id);
                                if(method) {
                                    this.setState(
                                        {
                                            formData: {
                                                ...this.state.formData,
                                                carriage: {
                                                    ...this.state.formData.carriage,
                                                    carriageId: method.id,
                                                    carriageData: method,
                                                },
                                            },
                                        },
                                        () => {
                                            this.props.deploySnackBar(`success`, `This ${document.toLowerCase()} now qualifies for free delivery via ${method.service} (Delivery method updated)`);
                                            this.handleCalculateTotals();
                                        }
                                    );
                                }
                            }
                        } else if (defaults?.defaultFreeCarriage) {
                            if(formData?.carriage?.carriageId !== defaults.defaultFreeCarriage.id) {
                                this.setState(
                                    {
                                        formData: {
                                            ...this.state.formData,
                                            carriage: {
                                                ...this.state.formData.carriage,
                                                carriageId: defaults.defaultFreeCarriage.id,
                                                carriageData: defaults.defaultFreeCarriage,
                                            },
                                        },
                                    },
                                    () => {
                                        this.props.deploySnackBar(`success`, `This ${document.toLowerCase()} now qualifies for free delivery via ${defaults.defaultFreeCarriage.service} (Delivery method updated)`);
                                        this.handleCalculateTotals();
                                    }
                                );
                            }
                        }
                    } else {
                        if (defaults?.defaultCarriage && formData?.carriage?.carriageId !== defaults.defaultCarriage.id) {
                            this.setState(
                                {
                                    formData: {
                                        ...this.state.formData,
                                        carriage: {
                                            ...this.state.formData.carriage,
                                            carriageId: defaults.defaultCarriage.id,
                                            carriageData: defaults.defaultCarriage,
                                        },
                                    },
                                },
                                () => {
                                    this.props.deploySnackBar(`warning`, `This ${document.toLowerCase()} no longer qualifies for free delivery (Delivery method updated)`);
                                    this.handleCalculateTotals();
                                }
                            );
                        }
                    }
                } else if (carriageZoneApplies) {
                    this.props.deploySnackBar(`error`, `The selected delivery address has additional charges, the ${document.toLowerCase()} delivery method has been updated`);
                }
            }
        );
    };

    handleCalculateFormData = (formData) => {
        let costTotal = "0.00",
            subTotal = "0.00",
            discountTotal = "0.00",
            carriageTotal = "0.00",
            netTotal = "0.00",
            vatTotal = "0.00",
            grossTotal = "0.00",
            totalMargin = 0,
            totalMarkup = 0,
            subDiscountTotal = 0;

        formData.productRows.forEach((row) => {
            if (row.productId > 0 || row?.bundleData) {
                costTotal = (parseFloat(costTotal) + parseFloat(row?.bundleData?.f?.co ?? row.productData?.product_cost) * row.quantity).toFixed(2);
                subTotal = (parseFloat(row.price) * parseFloat(row.quantity) + parseFloat(subTotal)).toFixed(2);
                discountTotal = (parseFloat(discountTotal) + parseFloat(row.discountTotal)).toFixed(2);
                netTotal = (parseFloat(netTotal) + parseFloat(row.total)).toFixed(2);
                subDiscountTotal = row.price * (row.discountPercent / 100) * row.quantity + parseFloat(subDiscountTotal);
            }
        });

        if (formData.carriage.carriageId !== "") {
            carriageTotal = parseFloat(formData.carriage?.carriageData?.data?.courier_conf_charge).toFixed(2);
            netTotal = (parseFloat(netTotal) + parseFloat(carriageTotal)).toFixed(2);
        }

        if (formData.customerVatPercent !== null) {
            vatTotal = ((netTotal / 100) * formData.customerVatPercent).toFixed(2);
        }

        grossTotal = (parseFloat(netTotal) + parseFloat(vatTotal)).toFixed(2);

        totalMargin = (((subTotal - discountTotal - costTotal) / (subTotal - discountTotal)) * 100).toFixed(2);
        totalMarkup = (((subTotal - discountTotal - costTotal) / costTotal) * 100).toFixed(2);

        return {
            ...formData,
            costTotal,
            subTotal,
            discountTotal,
            carriageTotal,
            vatTotal,
            netTotal,
            grossTotal,
            totalMargin,
            totalMarkup,
            subDiscountTotal,
        };
    };

    handleCarriageChange = (carriage) => {
        let wasCollection = this.state.formData?.carriage?.carriageData?.collection;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    carriage: {
                        carriageId: carriage.carriageId,
                        carriageData: carriage.carriageData,
                        manual: true,
                        dc: false,
                    },
                    deliveryAddress: carriage.carriageData?.collection ? -1 : wasCollection ? initialState().formData.deliveryAddress : this.state.formData.deliveryAddress,
                    deliveryAddressCustom: carriage.carriageData?.collection
                        ? AA_ADDRESS_OBJECT
                        : wasCollection
                        ? initialState().formData.deliveryAddressCustom
                        : this.state.formData.deliveryAddressCustom,
                },
            },
            () => this.handleCalculateTotals()
        );
    };

    handleCustomerReset = () => {
        if (this.props.replicate) {
            this.setState(
                {
                    customer: initialState().customer,
                    existingNotes: initialState().formData.existingNotes,
                    formData: {
                        ...this.state.formData,
                        carriage: {
                            ...this.state.formData.carriage,
                            carriageId: this.state.defaults?.defaultCarriage?.id ?? initialState().formData.carriage?.carriageId,
                            carriageData: this.state.defaults?.defaultCarriage ?? initialState().formData.carriage?.carriageData,
                            manual: initialState().formData.carriage?.manual ?? null,
                            dc: initialState().formData.carriage?.dc ?? null,
                        },
                        contactEmail: initialState().formData.contactEmail,
                        contactPhone: initialState().formData.contactPhone,
                        customer: initialState().formData.customer,
                        customerAddress: initialState().formData.customerAddress,
                        customerAddressCustom: initialState().formData.customerAddressCustom,
                        customerContact: initialState().formData.customerContact,
                        customerVatPercent: DEFAULT_VAT_PERCENT,
                        customerVatValue: DEFAULT_VAT_VALUE,
                        deliveryCompany: initialState().formData.deliveryCompany,
                        deliveryContact: initialState().formData.deliveryContact,
                        deliveryAddress: initialState().formData.deliveryAddress,
                        deliveryAddressCustom: initialState().formData.deliveryAddressCustom,
                        deliveryDespatchRef: initialState().formData.deliveryDespatchRef,
                        deliveryInstructions: initialState().formData.deliveryInstructions,
                        deliveryRequestedDate: initialState().formData.deliveryRequestedDate,
                        docRef: initialState().formData.docRef,
                        hot: initialState().formData.hot,
                        salesPerson: '',
                        internalNotes: initialState().formData.internalNotes,
                        ndUpdates: initialState().formData.ndUpdates,
                        noe: initialState().formData.noe,
                        notes: initialState().formData.notes,
                        orderExpectedDate: initialState().formData.orderExpectedDate,
                        paymentTerms: initialState().formData.paymentTerms,
                    },
                },
                () => this.handleDoTabChange(0)
            );
        } else {
            this.setState(
                {
                    customer: initialState().customer,
                    formData: {
                        ...this.state.formData,
                        carriage: {
                            ...this.state.formData.carriage,
                            carriageId: this.state.defaults?.defaultCarriage?.id ?? initialState().formData.carriage?.carriageId,
                            carriageData: this.state.defaults?.defaultCarriage ?? initialState().formData.carriage?.carriageData,
                            manual: initialState().formData.carriage?.manual ?? null,
                            dc: initialState().formData.carriage?.dc ?? null,
                        },
                        contactEmail: initialState().formData.contactEmail,
                        contactPhone: initialState().formData.contactPhone,
                        customer: initialState().formData.customer,
                        customerAddress: initialState().formData.customerAddress,
                        customerAddressCustom: initialState().formData.customerAddressCustom,
                        customerContact: initialState().formData.customerContact,
                        customerVatPercent: DEFAULT_VAT_PERCENT,
                        customerVatValue: DEFAULT_VAT_VALUE,
                        deliveryCompany: initialState().formData.deliveryCompany,
                        deliveryContact: initialState().formData.deliveryContact,
                        deliveryAddress: initialState().formData.deliveryAddress,
                        deliveryAddressCustom: initialState().formData.deliveryAddressCustom,
                        deliveryDespatchRef: initialState().formData.deliveryDespatchRef,
                        paymentTerms: initialState().formData.paymentTerms,
                    },
                },
                () => this.handleDoTabChange(0)
            );
        }
    };

    handleCustomerChange = (id) => {
        if (id) {
            this.getCustomer(id, true);
        }
    };

    handleCustomCustomerAddress = (address) => {
        if (!address) {
            this.setState({
                dialog: initialState().dialog,
                formData: {
                    ...this.state.formData,
                    customerAddress: "",
                    customerAddressCustom: {},
                },
            });
        } else {
            this.setState({
                dialog: initialState().dialog,
                formData: {
                    ...this.state.formData,
                    customerAddress: -1,
                    customerAddressCustom: address,
                },
            });
        }
    };

    handleDeployAddressChange = () => {

        const {
            formData
        } = this.state;

        const success = () => {
            this.handleDeployAddressLookup(true)
        }

        if(formData.deliveryAddress !== "" && formData.deliveryAddress > 0) {
            
            let addr = _.find(this.state.customer?.data?.addresses, (el) => el.i === formData.deliveryAddress);

            if(addr) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        deliveryAddress: -1,
                        deliveryAddressCustom: {
                            addressComplete: true,
                            addressOne: addr.l1,
                            addressTwo: addr.l2,
                            addressThree: addr.l3,
                            country: addr.ci,
                            county: addr.cc,
                            dc: addr.dc,
                            postcode: addr.po,
                            selectedAddress: null,
                            showAddress: true,
                            showAddressLookup: false,
                            town: addr.to
                        }
                    }
                }, () => {
                    success();
                })
            }
            
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    deliveryAddress: -1,
                    deliveryAddressCustom: {
                        ...this.state.formData.deliveryAddressCustom,
                        showAddress: true,
                        showAddressLookup: false,
                    }
                }
            }, () => {
                success();
            })
        }

    }

    handleCustomDeliveryAddress = (address) => {
        if (!address) {
            this.setState({
                dialog: initialState().dialog,
                // formData: {
                //     ...this.state.formData,
                //     deliveryAddress: "",
                //     deliveryAddressCustom: {},
                //     deliveryInstructions: "",
                // },
            });
        } else {
            let {
                formData: { carriage },
            } = this.state;

            if (address?.dc > 0) {
                let carriageData = _.find(this.state.carriage.options, (el) => el.id === address?.dc);
                if (carriageData) {
                    carriage = {
                        ...carriage,
                        carriageId: address?.dc,
                        carriageData: carriageData,
                        manual: true,
                        dc: true,
                    };
                }
            } else {
                if (carriage?.dc || !carriage.manual) {
                    carriage = {
                        ...carriage,
                        carriageId: this.state.defaults.defaultCarriage?.id ?? "",
                        carriageData: this.state.defaults.defaultCarriage ?? null,
                        manual: false,
                        dc: false,
                    };
                }
            }

            this.setState(
                {
                    dialog: initialState().dialog,
                    formData: {
                        ...this.state.formData,
                        carriage,
                        deliveryAddress: -1,
                        deliveryAddressCustom: address,
                        deliveryInstructions: address?.notes ?? "",
                    },
                },
                () => {
                    this.handleCalculateTotals();
                }
            );
        }
    };

    handleDateChange = (fieldName, date) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: moment(date).format("YYYY-MM-DD"),
            },
        });
    };

    handleDeliveryCustomChargeChange = (charge) => {
        this.setState({
            formData: {
                ...this.state.formData,
                carriage: {
                    ...this.state.formData.carriage,
                    carriageData: {
                        ...this.state.formData.carriage.carriageData,
                        data: {
                            ...this.state.formData.carriage.carriageData.data,
                            courier_conf_charge: charge,
                        },
                    },
                },
            },
        });
    };

    handleDeliveryCustomChargeFloat = () => {
        let charge = this.state.formData?.carriage?.carriageData?.data?.courier_conf_charge ?? 10;
        if (!isNumeric(charge) || charge < 0) {
            charge = 0;
        }
        charge = parseFloat(charge).toFixed(2);
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    carriage: {
                        ...this.state.formData.carriage,
                        carriageData: {
                            ...this.state.formData.carriage.carriageData,
                            data: {
                                ...this.state.formData.carriage.carriageData.data,
                                courier_conf_charge: charge,
                            },
                        },
                    },
                },
            },
            () => this.handleCalculateTotals()
        );
    };

    handleDeployAddressLookup = (useExistingAddress = false) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                addressLookup: true,
                addressLookupKey: uuidv4(),
                addressType: "delivery",
                useExistingAddress
            },
        });
    };

    handleFrequentProduct = (productId) => {
        const { classes } = this.props;
        const { customer, formData, id, ifm, lists, products } = this.state;
        this.props.deployDialog(
            <Product
                cardFormats={lists.cardFormats}
                cardProgrammers={lists.cardProgrammers}
                cardTypes={lists.cardTypes}
                classes={classes}
                closeDialog={this.props.closeDialog}
                customer={customer?.id}
                paxtonHardwareExcluded={customer?.data?.cust_pax_excl_hw}
                customerVatPercent={formData.customerVatPercent}
                deployDialog={this.props.deployDialog}
                document={document}
                productId={productId}
                products={products}
                handleSubmit={this.handleProductAdd}
                handleSwitchApiRoute={this.handleSwitchApiRoute}
                getCustomerData={this.getCustomerData}
                inDialog
                sites={customer?.sites}
                isUpdate={id}
                ifm={ifm}
                id={id}
            />,
            false,
            "",
            "standard",
            "xl",
            false,
            true
        );
    };

    handleProductAdd = (productRow) => {
        let { primaryIdx } = this.state;
        let productRows = [...this.state.formData.productRows];
        if(primaryIdx !== null && primaryIdx >= productRows.length) {
            primaryIdx = null;
        }
        if(primaryIdx !== null) {
            productRows.splice(primaryIdx + 1, 0, productRow);
        } else {
            productRows.push(productRow);
        }
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    productRows,
                },
                primaryIdx,
                unsavedLine: false
            },
            () => this.handleCalculateTotals()
        );
    };

    handleSetPrimaryIdx = idx => {
        this.setState({ 
            primaryIdx: idx === this.state.primaryIdx ? null : idx,
        });
    }

    handleProductEdit = (productRow) => {
        let productRows = [...this.state.formData.productRows],
            fulfilment = this.state.changes.fulfilment;

        if (productRow?.bundleId) {
            fulfilment = true;
        } else {
            if (parseInt(productRows[productRow.rowIdx].quantity) !== parseInt(productRow.quantity) && !fulfilment) {
                fulfilment = true;
            }
        }

        productRows[productRow.rowIdx] = productRow;

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    productRows,
                },
            },
            () => this.handleCalculateTotals()
        );
    };

    handleDeployProductEdit = (editProductRow) => {
        const { classes, closeDialog, deployDialog } = this.props;
        const { customer, document, formData, id, ifm, lists, products } = this.state;

        if (editProductRow?.productId <= 0) {
            deployDialog(<Block closeDialog={closeDialog} formData={editProductRow} handleSubmit={this.handleProductEdit} />, false, "Modify Text", "standard", "xs");
        } else {
            deployDialog(
                <Product
                    cardFormats={lists.cardFormats}
                    cardProgrammers={lists.cardProgrammers}
                    cardTypes={lists.cardTypes}
                    getCustomerData={this.getCustomerData}
                    ifm={ifm}
                    classes={classes}
                    closeDialog={closeDialog}
                    customer={customer?.id}
                    paxtonHardwareExcluded={customer?.data?.cust_pax_excl_hw}
                    customerVatPercent={formData.customerVatPercent}
                    deployDialog={deployDialog}
                    document={document}
                    formData={editProductRow}
                    handleSubmit={this.handleProductEdit}
                    handleSwitchApiRoute={this.handleSwitchApiRoute}
                    products={products}
                    sites={customer.sites}
                    isUpdate
                    inDialog
                    id={id}
                />,
                false,
                "",
                "standard",
                "xl",
                false,
                true
            );
        }
    };

    handleProductRemove = (idx) => {
        const productRows = [...this.state.formData.productRows];
        productRows.splice(idx, 1);
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    productRows,
                },
            },
            () => this.handleCalculateTotals()
        );
    };

    handleProductDrag = (prevRows, startIndex, endIndex) => {
        const productRows = Array.from(prevRows);
        const [removed] = productRows.splice(startIndex, 1);
        productRows.splice(endIndex, 0, removed);
        let { primaryIdx } = this.state;
        if(primaryIdx === startIndex) {
            primaryIdx = endIndex;
        } else if(primaryIdx === endIndex) {
            primaryIdx = startIndex;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                productRows,
            },
            primaryIdx
        });
    };

    handleNoteAdd = (note) => {
        let internalNotes = [...this.state.formData.internalNotes];
        internalNotes.push(note);

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    internalNotes,
                },
            },
            () => this.handleCalculateTotals()
        );
    };

    handleNoteEdit = (note) => {
        let internalNotes = [...this.state.formData.internalNotes];
        internalNotes[note.rowIdx] = note;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    internalNotes,
                },
            },
            () => this.handleCalculateTotals()
        );
    };

    handleNoteRemove = (idx) => {
        const internalNotes = [...this.state.formData.internalNotes];
        internalNotes.splice(idx, 1);
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    internalNotes,
                },
            },
            () => this.handleCalculateTotals()
        );
    };

    handleSelectChange = (fieldName, selectedOption) => {
        if (fieldName === "deliveryAddress") {
            let id = selectedOption?.value ?? "";

            let deliveryInstructions = "";

            let deliveryAddress = _.find(this.state.customer?.data?.addresses, (el) => el.i === id);
            if (deliveryAddress) {
                if (!_.isEmpty(deliveryAddress?.no)) {
                    deliveryInstructions = deliveryAddress?.no;
                }
            }

            let newFormData;
            if (selectedOption?.data?.dc > 0) {
                let carriageData = _.find(this.state.carriage.options, (el) => el.id === selectedOption?.data?.dc);
                let wasCollection = this.state.formData?.carriage?.carriageData?.collection;
                if (carriageData) {
                    newFormData = {
                        carriage: {
                            ...this.state.formData.carriage,
                            carriageId: selectedOption?.data?.dc,
                            carriageData: carriageData,
                            manual: true,
                            dc: true,
                        },
                        deliveryAddress: wasCollection ? initialState().formData.deliveryAddress : this.state.formData.deliveryAddress,
                        deliveryAddressCustom: wasCollection ? initialState().formData.deliveryAddressCustom : this.state.formData.deliveryAddressCustom,
                    };
                }
            } else {
                if (this.state.formData?.carriage?.dc || !this.state.formData.carriage?.manual) {
                    let carriageData = _.find(this.state.carriage.options, (el) => el.id === this.state.defaults.defaultCarriage?.id);
                    if (carriageData) {
                        newFormData = {
                            carriage: {
                                ...this.state.formData.carriage,
                                carriageId: this.state.defaults.defaultCarriage?.id ?? "",
                                carriageData: this.state.defaults.defaultCarriage ?? null,
                                manual: false,
                                dc: false,
                            },
                        };
                    }
                }
            }

            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        ...newFormData,
                        [fieldName]: id,
                        deliveryAddressCustom: {},
                        deliveryInstructions,
                    },
                },
                () => {
                    this.handleCalculateTotals();
                }
            );
        } else if (fieldName === "customerContact" || fieldName === "deliveryContact") {
            let contact = selectedOption?.value ?? "";

            if (isNaN(contact)) {
                let contactList = this.state.customer?.contactList;

                if (_.findIndex(contactList, (el) => el.value === contact) === -1) {
                    contactList.push({ label: contact, value: contact });
                }

                this.setState({
                    customer: {
                        ...this.state.customer,
                        contactList,
                    },
                    formData: {
                        ...this.state.formData,
                        [fieldName]: contact,
                    },
                });
            } else {
                let contacts = this.state.customer?.data?.contacts,
                    {
                        formData: { contactEmail, contactPhone, ndUpdates, noe },
                    } = this.state,
                    idx = _.findIndex(contacts, (el) => el.i === contact);

                if (fieldName === "customerContact" && idx >= 0 && contacts?.[idx]) {
                    contactEmail = contacts?.[idx]?.e;
                    contactPhone = contacts?.[idx]?.m?.replace?.(/(\d)\s+(?=\d)/g, `$1`);
                    ndUpdates = contacts?.[idx]?.ndu;
                    noe = contacts?.[idx]?.noe;
                } else if (fieldName === "customerContact") {
                    contactEmail = "";
                    contactPhone = "";
                    ndUpdates = 0;
                    noe = 0;
                }

                this.setState({
                    formData: {
                        ...this.state.formData,
                        [fieldName]: contact,
                        contactEmail,
                        contactPhone,
                        ndUpdates,
                        noe,
                    },
                });
            }
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: selectedOption?.value ?? "",
                },
            });
        }
    };

    handleUseBillingForDeliveryAddress = () => {
        let {
            formData: { carriage },
        } = this.state;

        let deliveryAddress = this.state.formData.customerAddress ?? "";

        let deliveryInstructions = "";

        if (deliveryAddress !== "") {
            let deliveryAddressData = _.find(this.state.customer?.data?.addresses, (el) => el.i === deliveryAddress);
            if (deliveryAddressData) {
                if (!_.isEmpty(deliveryAddressData?.no)) {
                    deliveryInstructions = deliveryAddressData?.no;
                }
            }
            if (deliveryAddressData?.dc > 0) {
                let carriageData = _.find(this.state.carriage.options, (el) => el.id === deliveryAddressData?.dc);
                if (carriageData) {
                    carriage = {
                        ...carriage,
                        carriageId: deliveryAddressData?.dc,
                        carriageData: carriageData,
                        manual: true,
                        dc: true,
                    };
                }
            } else {
                if (this.state.formData?.carriage?.dc || !this.state.formData.carriage?.manual) {
                    carriage = {
                        ...carriage,
                        carriageId: this.state.defaults?.defaultCarriage?.id ?? "",
                        carriageData: this.state.defaults?.defaultCarriage ?? null,
                        manual: false,
                        dc: false,
                    };
                }
            }
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    carriage,
                    deliveryContact: _.isEmpty(this.state.formData.deliveryContact) ? this.state.formData.customerContact : this.state.formData.deliveryContact,
                    deliveryAddress,
                    deliveryAddressCustom: {},
                    deliveryInstructions,
                },
            },
            () => {
                this.handleCalculateTotals();
            }
        );
    };

    handlePreviewPagination = (offset) => {
        this.setState((prevState) => ({
            preview: {
                ...prevState.preview,
                pageNumber: (prevState?.preview?.pageNumber ?? 1) + offset,
            },
        }));
    };

    handlePreviewLoad = ({ numPages }) => {
        this.setState({
            preview: {
                ...this.state.preview,
                numPages,
            },
        });
    };

    handleSetVariations = (customerVatValue, customerVatPercent, productRows, nextDialog) => {
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    customerVatPercent,
                    customerVatValue,
                    productRows,
                },
            },
            () => {
                this.handleCalculateTotals();
                if (nextDialog) {
                    nextDialog();
                }
            }
        );
    };

    handleSetReplacements = (productRows, anyProductReplaced) => {
        this.setState(
            {
                changes: {
                    ...this.state.changes,
                    fulfilment: anyProductReplaced ? true : this.state.changes.fulfilment,
                },
                formData: {
                    ...this.state.formData,
                    productRows,
                },
            },
            () => this.handleCalculateTotals()
        );
    };

    handleDiscountPassword = (password) => {
        this.props.closeDialog();
        this.setState(
            {
                password,
            },
            () => {
                this.handleSubmitPreview();
            }
        );
    };

    handleDiscountPasswordCancel = () => {
        this.props.closeDialog();
        this.handleTabChange(1);
        this.setState({
            isLoading: false,
            isLoadingPreview: false,
        });
    };

    handleSubmitPreview = () => {
        const { placeOrder } = this.state;

        let { apiRoute, document, formData, id, password } = this.state;

        const resource = id ? `/${apiRoute}/${id}` : `/${apiRoute}`;

        if (placeOrder) {
            formData = {
                ...formData,
                quoteId: this.props.customer ? false : this.props.id ?? this.props.match?.params?.id ?? false,
            };
            if (JSON.stringify(this.state.initialFormData.productRows) !== JSON.stringify(formData.productRows)) {
                formData = {
                    ...formData,
                    quoteModified: true,
                };
            }
        }

        this.setState(
            {
                isLoading: true,
                isLoadingPreview: true,
            },
            () => {
                API.post(resource, { preview: true, ...formData, password }).then((result) => {
                    if(this.state.currentTab === 1) {
                        return false;
                    }
                    if (result.data.errors) {
                        let formErrors = formatValidationErrors(result.data.errors);
                        if (formErrors?.password) {
                            this.props.deployDialog(
                                <DiscountPasswordForm document={document} error={formErrors.password} handleSubmit={this.handleDiscountPassword} handleClose={this.handleDiscountPasswordCancel} highestDiscount={formErrors.discountLevel} />,
                                true,
                                "",
                                "standard",
                                "xs",
                                false,
                                true
                            );
                        } else {
                            this.setState({
                                formErrors,
                                formData: {
                                    ...formData,
                                    customer: (formErrors && formErrors["customer"] && initialState().formData.customer) || formData.customer,
                                    customerVatPercent: (formErrors && formErrors["customer"] && initialState().formData.customerVatPercent) || formData.customerVatPercent,
                                },
                                currentTab: 2,
                                isLoading: false,
                            });
                        }
                    } else {
                        if (result.data.pdf) {
                            this.setState({
                                formErrors: false,
                                isLoading: false,
                                isLoadingPreview: false,
                                preview: {
                                    ...this.state.preview,
                                    numPages: null,
                                    pageNumber: 1,
                                    pdf: result.data.pdf,
                                },
                            });
                        }
                    }
                });
            }
        );
    };

    handleConfirmation = () => {
        const { deployConfirmation } = this.props;

        const { document, formData, ifm } = this.state;

        const action = this.state.id ? "update" : "create";

        const hasSomeCardOrderLines = !ifm && _.some(formData?.productRows, (el) => el?.productData?.cDb === 1) && _.some(formData?.productRows, (el) => el?.productData?.cDb === 0);

        deployConfirmation(`Are you sure you want to ${action} this ${document.toLowerCase()}?`, () =>
            document === "Order" && action === "create" && hasSomeCardOrderLines
                ? deployConfirmation(
                      <>
                            <Box textAlign={"center"}>
                                <Box my={2}>
                                    <FAIcon icon="exclamation-circle" className="textError" size={70} noMargin />
                                </Box>
                                <Typography variant="body1" className="fw-400 textError">
                                    This order contains some lines which require card authorisation
                                </Typography>
                                <Typography variant="body1" className="fw-400 textError" paragraph>
                                    Do you want to split these into a separate sales order?
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Card authorisation may delay despatch, please split the order if the customer requires the non-programmable products urgently.
                                </Typography>
                                <Typography variant="body1">
                                    If split, delivery will only be charged once (if applicable)
                                </Typography>
                            </Box>
                      </>,
                      () => this.handleSplitAndSubmit(),
                      () => this.handlePrePayConfirmation(),
                      "Split Order",
                      "Leave as One"
                  )
                : this.handlePrePayConfirmation()
        );
    };

    handlePrePayConfirmation = () => {
        const { closeDialog, deployConfirmation, deployDialog } = this.props;

        const { document, formData, id, initialFormData } = this.state;

        const isCollection = formData?.carriage?.carriageData?.collection ? true : false;

        /* Customer paying prepay? */
        if (
            document === "Order" &&
            ((!id && formData.paymentTerms === initialState().formData?.paymentTerms) ||
                (id && formData.paymentTerms === initialState().formData?.paymentTerms && formData.grossTotal > initialFormData.grossTotal))
        ) {
            deployDialog(
                <Box p={3} pt={4} pb={4}>
                    <Typography variant="h6" className="fw-400" align="center" gutterBottom>
                        Prepayment Option
                    </Typography>
                    <Typography variant="body1" className="fw-300" align="center" gutterBottom>
                        Do you know how the customer wants to pay?
                    </Typography>
                    <Box pt={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={isCollection ? 3 : 4} align="left">
                                <ToggleButton
                                    style={{ width: "100%", height: 250 }}
                                    onClick={() =>
                                        deployConfirmation(`Are you sure want to select credit / debit card?`, () => {
                                            this.handleSubmit("CARD");
                                            closeDialog();
                                        })
                                    }
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FAIcon className="textDefault" type="thin" size="xlarge" icon="credit-card-front" button />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={1}>
                                                <Typography className="textDefault pl-1 fw-400" variant="h6" style={{ textTransform: "none" }}>
                                                    Credit / Debit Card
                                                </Typography>
                                                <Typography className="textDefault pl-1" variant="body2" style={{ textTransform: "none" }}>
                                                    <FAIcon icon="info-circle" className="mr-05" size={13} button /> Card details to be provided
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                            </Grid>
                            <Grid item xs={12} lg={isCollection ? 3 : 4} align="left">
                                <ToggleButton
                                    style={{ width: "100%", height: 250 }}
                                    onClick={() =>
                                        deployConfirmation(`Are you sure want to select proforma required?`, () => {
                                            this.handleSubmit("PROFORMA");
                                            closeDialog();
                                        })
                                    }
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FAIcon className="textDefault" type="thin" size="xlarge" icon="envelope" button />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={1}>
                                                <Typography className="textDefault pl-1 fw-400" variant="h6" style={{ textTransform: "none" }}>
                                                    Proforma Required
                                                </Typography>
                                                <Typography className="textDefault pl-1" variant="body2" style={{ textTransform: "none" }}>
                                                    <FAIcon icon="info-circle" className="mr-05" size={13} button /> Automated proforma to be e-mailed
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                            </Grid>
                            {isCollection && (
                                <Grid item xs={12} lg={isCollection ? 3 : 4} align="left">
                                    <ToggleButton
                                        style={{ width: "100%", height: 250 }}
                                        onClick={() =>
                                            deployConfirmation(`Are you sure you want to select cash on collection?`, () => {
                                                this.handleSubmit("CASH");
                                                closeDialog();
                                            })
                                        }
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FAIcon className="textDefault" type="thin" size="xlarge" icon="wallet" button />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box pt={1}>
                                                    <Typography className="textDefault pl-1 fw-400" variant="h6" style={{ textTransform: "none" }}>
                                                        Cash on Collection
                                                    </Typography>
                                                    <Typography className="textDefault pl-1" variant="body2" style={{ textTransform: "none" }}>
                                                        <FAIcon icon="info-circle" className="mr-05" size={13} button /> Customer to pay on collection
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </ToggleButton>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={isCollection ? 3 : 4} align="left">
                                <ToggleButton
                                    style={{ width: "100%", height: 250 }}
                                    onClick={() =>
                                        deployConfirmation(`Are you sure you want to select to be confirmed?`, () => {
                                            this.handleSubmit("TBC");
                                            closeDialog();
                                        })
                                    }
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FAIcon className="textDefault" type="thin" size="xlarge" icon="question-circle" button />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={1}>
                                                <Typography className="textDefault pl-1 fw-400" variant="h6" style={{ textTransform: "none" }}>
                                                    To Be Confirmed
                                                </Typography>
                                                <Typography className="textDefault pl-1" variant="body2" style={{ textTransform: "none" }}>
                                                    <FAIcon icon="info-circle" className="mr-05" size={13} button /> No card details / proforma required
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>,
                true,
                "",
                "standard",
                isCollection ? "lg" : "md"
            );
        } else {
            this.handleSubmit();
        }
    };

    handleDeployCardDetailsForm = (apiRoute, data) => {
        const { deployDialog } = this.props;

        const callback = () => {
            if(this.context?.dial === null) {
                this.context.useDial(START_RECORDING);
            }
            this.handleComplete(apiRoute, data.id);
        }

        if(this.context?.dial === null) {
            this.context.useDial(STOP_RECORDING);
        }

        deployDialog(
            <CardDetailsForm callback={callback} companyName={data.companyName} sopNumber={data.sopNumber} amount={data.amount} registeredAddress={data.registeredAddress} />,
            true,
            "",
            "standard",
            "md",
            false,
            true
        );
    };

    handleSplitAndSubmit = () => {
        const { placeOrder } = this.state;

        const { defaults, document, id, formData, rma, password } = this.state;

        const resource = `/sales`,
            action = placeOrder ? "placed" : "created";

        let stdLines = _.filter(formData.productRows, (el) => el?.productData?.cDb === 0),
            cardDbLines = _.filter(formData.productRows, (el) => el?.productData?.cDb === 1);

        if (id || _.isEmpty(stdLines) || _.isEmpty(cardDbLines) || document !== "Order") return;

        let data = formData;

        delete data.ifm;
        delete data.productRows;

        let stdOrder = data,
            cardDbOrder = data;

        stdOrder = {
            ...stdOrder,
            productRows: stdLines,
        };

        cardDbOrder = {
            ...cardDbOrder,
            productRows: cardDbLines,
        };

        if (defaults?.defaultFreeCarriage) {
            cardDbOrder = {
                ...cardDbOrder,
                carriage: {
                    ...cardDbOrder.carriage,
                    carriageId: defaults.defaultFreeCarriage.id,
                    carriageData: defaults.defaultFreeCarriage,
                },
            };
        }

        stdOrder = this.handleCalculateFormData(stdOrder);
        cardDbOrder = this.handleCalculateFormData(cardDbOrder);

        if (action === "placed") {
            cardDbOrder = {
                ...cardDbOrder,
                quoteId: this.props.customer ? false : this.props.id ?? this.props.match?.params?.id ?? false,
                quoteActivityId: this.props.customer ? false : this.props.id ?? this.props.match?.params?.id ?? false,
            };
            stdOrder = {
                ...stdOrder,
                quoteActivityId: this.props.customer ? false : this.props.id ?? this.props.match?.params?.id ?? false,
            }
        }

        if (rma?.rma_id) {
            cardDbOrder = {
                ...cardDbOrder,
                rmaId: rma.rma_id,
            };
        }

        stdOrder = {
            ...stdOrder,
            initialFormData: stdOrder,
        };

        cardDbOrder = {
            ...cardDbOrder,
            initialFormData: cardDbOrder,
        };

        this.setState(
            {
                isLoading: true,
            },
            () => {
                Promise.all([API.post(resource, { ...stdOrder, password }), API.post(resource, { ...cardDbOrder, password })]).then(([stdOrderRes, cardDbOrderRes]) => {
                    if (stdOrderRes?.data && cardDbOrderRes?.data) {
                        if (stdOrderRes?.data?.errors || cardDbOrderRes?.data?.errors) {
                            let formErrors = formatValidationErrors([
                                ...(!_.isEmpty(stdOrderRes.data.errors) && stdOrderRes.data.errors),
                                ...(!_.isEmpty(cardDbOrderRes.data.errors) && cardDbOrderRes.data.errors),
                            ]);
                            this.setState({
                                formErrors,
                                formData: {
                                    ...formData,
                                    customer: (formErrors && formErrors["customer"] && initialState().formData.customer) || formData.customer,
                                    customerVatPercent: (formErrors && formErrors["customer"] && initialState().formData.customerVatPercent) || formData.customer,
                                },
                                isLoading: false,
                            });
                        } else {
                            this.setState(
                                {
                                    ...initialState(),
                                },
                                () => {
                                    let orders = [
                                        {
                                            id: cardDbOrderRes?.data?.id,
                                            ref: cardDbOrderRes?.data?.ref,
                                            status: cardDbOrderRes?.data?.status,
                                            cRef: cardDbOrder?.docRef,
                                            name: "Card Order Products",
                                        },
                                        {
                                            id: stdOrderRes?.data?.id,
                                            ref: stdOrderRes?.data?.ref,
                                            status: stdOrderRes?.data?.status,
                                            cRef: stdOrder?.docRef,
                                            name: "Other Products",
                                        }
                                    ];

                                    this.props.deployDialog(
                                        <Box className="content-light-grey">
                                            <DialogTitle disableTypography style={{ paddingBottom: 0 }}>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item xs>
                                                        <Typography variant="h6" className="fw-400">
                                                            Sales Order Split Generated
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <Box p={3}>
                                                <Box className="content-light-white" p={3}>
                                                    <Grid container spacing={3}>
                                                        {_.map(orders, (o, idx) => (
                                                            <Grid item xs={12} key={idx}>
                                                                <Typography variant="h6" className="fw-400" align="center">
                                                                    #{o.ref}
                                                                </Typography>
                                                                <Typography variant="body2" className="fw-400" align="center" gutterBottom>
                                                                    ({o.name})
                                                                </Typography>
                                                                <Box pt={1} pb={2} align="center">
                                                                    <StatusChip status={o.status} />
                                                                </Box>
                                                                <AppButton
                                                                    className="btn btn-success"
                                                                    onClick={() => {
                                                                        this.props.history.push(`/sales/${o.id}`);
                                                                        this.props.closeDialog();
                                                                    }}
                                                                    text="View Order"
                                                                    icon="binoculars"
                                                                    light
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Box>,
                                        true,
                                        "",
                                        "standard",
                                        "xs",
                                        false,
                                        true
                                    );
                                }
                            );
                        }
                    }
                });
            }
        );
    };

    handleSubmit = (paymentType = null) => {
        let { placeOrder } = this.state;
        let { deployDialog, deploySnackBar } = this.props;

        let { apiRoute, changes, document, formData, id, ifm, initialFormData, password } = this.state;

        const resource = id ? `/${apiRoute}/${id}` : `/${apiRoute}`,
            action = placeOrder ? "placed" : this.state.id ? "updated" : "created";

        this.setState(
            {
                isLoading: true,
            },
            () => {
                if (action === "updated") {
                    formData = {
                        ...formData,
                        initialFormData,
                    };
                }
                if (action === "placed") {
                    formData = {
                        ...formData,
                        initialFormData,
                        quoteId: this.props.customer ? false : this.props.id ?? this.props.match?.params?.id ?? false,
                    };
                    if (JSON.stringify(this.state.initialFormData.productRows) !== JSON.stringify(formData.productRows)) {
                        formData = {
                            ...formData,
                            quoteModified: true,
                        };
                    }
                }
                if (this.state?.rma?.rma_id) {
                    formData = {
                        ...formData,
                        rmaId: this.state.rma?.rma_id ?? null,
                    };
                }
                if (ifm) {
                    formData = {
                        ...formData,
                        ifm: true,
                    };
                } else {
                    delete formData.ifm;
                }
                API.post(resource, { ...formData, password, changes, paymentType }).then((result) => {
                    if (result.data.errors) {
                        let formErrors = formatValidationErrors(result.data.errors);
                        this.setState({
                            formErrors,
                            formData: {
                                ...formData,
                                customer: (formErrors && formErrors["customer"] && initialState().formData.customer) || formData.customer,
                                customerVatPercent: (formErrors && formErrors["customer"] && initialState().formData.customerVatPercent) || formData.customer,
                            },
                            isLoading: false,
                        });
                    } else {
                        this.setState(
                            {
                                ...initialState(),
                            },
                            () => {
                                deploySnackBar("success", `The ${document.toLowerCase()} was ${action} successfully`);

                                let callback;

                                if (paymentType === "CARD") {
                                    callback = () => this.handleDeployCardDetailsForm(apiRoute, result.data);
                                } else {
                                    callback = () => this.handleComplete(apiRoute, result.data.id);
                                }

                                if (result.data?.rebase && !_.isEmpty(result.data?.rebase)) {
                                    deployDialog(<UpdateOrderDeliveryPurchaseOrders rebase={result.data.rebase} callback={callback} />, false, "Resend Purchase Orders", "standard", "md", false, true);
                                } else {
                                    callback();
                                }
                            }
                        );
                    }
                });
            }
        );
    };

    handleComplete = (apiRoute, id) => {
        this.setState(
            {
                ...initialState(),
            },
            () => {
                this.props.history.push({
                    pathname: `/${apiRoute}/${id}`,
                    state: { new: true },
                });
            }
        );
    };

    handleUnsavedLine = () => {
        this.setState({
            unsavedLine: true
        })
    }

    render = () => {
        const { placeOrder } = this.state;
        const { classes, deployConfirmation, history, replicate } = this.props;

        const {
            access,
            apiRoute,
            carriage,
            // containsCardDBProduct,
            changes,
            currentTab,
            customer,
            dialog,
            disableCustomerTab,
            document,
            existingNotes,
            formData,
            formErrors,
            forRMA,
            ifm,
            initialLoading,
            isLoading,
            isLoadingPreview,
            lists,
            order,
            preview,
            products,
            quote,
            removedRows,
            id,
            ui,
        } = this.state;

        const frequentProducts = ifm || placeOrder || forRMA ? false : !_.isEmpty(customer?.data?.fp);
        const placeOrderWarning = placeOrder ? JSON.stringify(this.state.initialFormData.productRows) !== JSON.stringify(formData.productRows) : null;
        const overlimit = (customer.data?.cust_has_credit === 1 && parseFloat(formData.grossTotal) > parseFloat(customer.data?.cust_credit_available) && true) || false;
        const deliveryApproval = formData?.carriage?.carriageData?.requiresApproval ? true : false ?? false;
        const requiresCardOrder = _.some(formData?.productRows, (el) => el?.productData?.cDb === 1);
        const priorityNotes = !_.isEmpty(customer?.data?.notes) ? _.filter(customer?.data?.notes, (el) => el.t === "Important") : [];

        return (
            <Grid container className={classes.container}>
                {(initialLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Prompt when={JSON.stringify(formData) !== JSON.stringify(this.state.initialFormData)} message="You have unsaved changes, are you sure you want to leave?" />
                        <Grid item className={`${classes.main} p-0`}>
                            {(isLoading && (
                                <>
                                    <LoadingCircle />
                                    {isLoadingPreview && (
                                        <Typography variant="h6" align="center">
                                            Generating Preview
                                        </Typography>
                                    )}
                                </>
                            )) || (
                                <>
                                    <Box top={0} left={0}>
                                        {(ifm && changes?.cardOrders && (
                                            <Alert severity="error" variant="standard" elevation={0} className={classes.alert}>
                                                <Typography variant="body2" className="fw-400">
                                                    Changes to card orders will require re-authorisation from the customer
                                                </Typography>
                                            </Alert>
                                        )) ||
                                            (ifm && changes?.fulfilment && (
                                                <Alert severity="error" variant="standard" elevation={0} className={classes.alert}>
                                                    <Typography variant="body2" className="fw-400">
                                                        Changes to this order will require re-processing
                                                    </Typography>
                                                </Alert>
                                            )) ||
                                            (ifm && (
                                                <Alert severity="success" variant="standard" elevation={0} className={classes.alert}>
                                                    <Typography variant="body2" className="fw-400">
                                                        Changes to this order will not require re-processing
                                                    </Typography>
                                                </Alert>
                                            )) ||
                                            (customer?.id > 0 && formData.paymentTerms !== customer?.data?.payment_terms?.pt_id && (
                                                <Alert severity="error" variant="standard" elevation={0} className={classes.alert}>
                                                    <Typography variant="body2" className="fw-400">
                                                        The payment terms for this {document.toLowerCase()} are different to the customer default, please review on the {document} Details tab and update if required back to {_.find(lists.paymentTerms, el => el.value === customer?.data?.payment_terms?.pt_id)?.label ?? 'the correct value'}.
                                                    </Typography>
                                                </Alert>
                                            )) || 
                                            (formData.paymentTerms !== initialState().formData?.paymentTerms &&
                                                overlimit &&
                                                ((quote?.latest_approval?.qa_status === "Approved" && (
                                                    <Alert severity="info" variant="standard" elevation={0} className={classes.alert}>
                                                        <Typography variant="body2" className="fw-400">
                                                            This quote has been pre-approved and will not require approval again unless modified now
                                                        </Typography>
                                                    </Alert>
                                                )) || (
                                                    <Alert severity="warning" variant="standard" elevation={0} className={classes.alert}>
                                                        <Typography variant="body2" className="fw-400">
                                                            This {document.toLowerCase()} exceeds the customer's available credit balance and will require approval
                                                            {document === "Quote" ? " (if ordered)" : ""}
                                                        </Typography>
                                                    </Alert>
                                                ))) ||
                                            (order?.rma && (
                                                <Alert severity="warning" variant="standard" elevation={0} className={classes.alert}>
                                                    <Typography variant="body2" className="fw-400">
                                                        Changes made to this replacement sales order will not affect RMA #{order?.rma?.rma_reference}
                                                    </Typography>
                                                </Alert>
                                            )) ||
                                            ((document === "Order" || placeOrder) &&
                                                formData.customer !== initialState().formData?.customer &&
                                                formData.paymentTerms === initialState().formData?.paymentTerms && (
                                                    <Alert severity="info" variant="standard" elevation={0} className={classes.alert}>
                                                        <Typography variant="body2" className="fw-400">
                                                            Payment required for this order
                                                        </Typography>
                                                    </Alert>
                                                )) ||
                                            (deliveryApproval && (
                                                <Alert severity="warning" variant="standard" elevation={0} className={classes.alert}>
                                                    <Typography variant="body2" className="fw-400">
                                                        Despatch via {formData?.carriage?.carriageData?.name ?? ""} requires approval
                                                    </Typography>
                                                </Alert>
                                            )) ||
                                            (placeOrder && placeOrderWarning && (
                                                <Alert severity="info" variant="standard" elevation={0} className={classes.alert}>
                                                    <Typography variant="body2" className="fw-400">
                                                        The order you are placing differs from the quote - please check before proceeding
                                                        {requiresCardOrder ? ` (this may be due to adding card details for this order)` : ``}
                                                    </Typography>
                                                </Alert>
                                            ))}
                                    </Box>
                                    <TabPanel value={currentTab} index={0}>
                                        <Box p={3}>
                                            <CustomerSearchSelector handleAction={this.handleCustomerChange} includeProspects={document === "Quote"} />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1}>
                                        <Box style={{ width: "100%", maxWidth: "100%" }}>
                                            <Box position="fixed" zIndex={999}>
                                                <Product
                                                    classes={classes}
                                                    cardFormats={lists.cardFormats}
                                                    cardProgrammers={lists.cardProgrammers}
                                                    cardTypes={lists.cardTypes}
                                                    document={document}
                                                    getCustomerData={this.getCustomerData}
                                                    customer={customer?.id}
                                                    paxtonHardwareExcluded={customer?.data?.cust_pax_excl_hw}
                                                    customerVatPercent={formData.customerVatPercent}
                                                    handleSubmit={this.handleProductAdd}
                                                    handleSwitchApiRoute={this.handleSwitchApiRoute}
                                                    handleUnsavedLine={this.handleUnsavedLine}
                                                    products={products}
                                                    sites={customer?.sites}
                                                    isUpdate={id}
                                                    id={id}
                                                    borderRadius={0}
                                                    width={"calc(100% - 230px)"}
                                                />
                                            </Box>
                                        </Box>
                                        <Grid
                                            container
                                            style={{
                                                height:
                                                    parseInt(ui?.contentHeight ?? 0) -
                                                    (ifm ||
                                                    overlimit ||
                                                    deliveryApproval ||
                                                    (placeOrder && placeOrderWarning) ||
                                                    ((document === "Order" || placeOrder) &&
                                                        formData.customer !== initialState().formData?.customer &&
                                                        formData.paymentTerms === initialState().formData?.paymentTerms)
                                                        ? 49
                                                        : 0),
                                                overflowY: "auto",
                                                overflowX: "hidden",
                                            }}
                                        >
                                            <Grid item xs={12} className={`h-100`}>
                                                <Box
                                                    p={3}
                                                    style={{
                                                        overflowY: "auto",
                                                        height:
                                                            parseInt(ui?.height) -
                                                            (ifm ||
                                                            overlimit ||
                                                            deliveryApproval ||
                                                            (placeOrder && placeOrderWarning) ||
                                                            ((document === "Order" || placeOrder) &&
                                                                formData.customer !== initialState().formData?.customer &&
                                                                formData.paymentTerms === initialState().formData?.paymentTerms)
                                                                ? 170
                                                                : 120) -
                                                            (frequentProducts ? 121.5 : 0),
                                                    }}
                                                >
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Box height={80} />
                                                        </Grid>
                                                        {!_.isEmpty(priorityNotes) && (
                                                            <Grid item xs={12}>
                                                                <ExpansionPanel disabled={_.isEmpty(priorityNotes)}>
                                                                    <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                                                                        <Typography variant="h6" component="div">
                                                                            <FAIcon icon="exclamation-circle" className="textError mr-1" size={20} button />
                                                                            {customer?.data?.cust_company_name} - Priority Notes ({_.size(priorityNotes)})
                                                                        </Typography>
                                                                    </ExpansionPanelSummary>
                                                                    <ExpansionPanelDetails>
                                                                        <ViewNotes noPriorityStyles inline hideDeleteButton notes={priorityNotes} />
                                                                    </ExpansionPanelDetails>
                                                                </ExpansionPanel>
                                                            </Grid>
                                                        )}
                                                        {customer?.data?.cust_pax_excl_hw === 1 && (
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Alert severity="error">
                                                                        <Typography variant="body2" className="fw-400">
                                                                            This customer is on the Paxton Hardware Exclusion List
                                                                        </Typography>
                                                                    </Alert>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                        {/* {containsCardDBProduct && (
                                                            <Grid item xs={12}>
                                                                <Alert severity="error">
                                                                    <strong>Important - Existing Card Order Lines Exist</strong><br />This {document.toLowerCase()} contains lines that requires card order authorisation; please ensure you edit these lines with updated information if they are being kept!
                                                                </Alert>
                                                            </Grid>
                                                        )} */}
                                                        {!_.isEmpty(removedRows) && (
                                                            <Grid item xs={12}>
                                                                <Box className="content-light-error" p={3} pb={1.5}>
                                                                    <Typography variant="body2" className="fw-400">
                                                                        The following items have been removed -
                                                                    </Typography>
                                                                    <ul>
                                                                        {_.map(removedRows, (row, idx) => (
                                                                            <li key={idx}>
                                                                                <Typography variant="body2" className="fw-400" gutterBottom>
                                                                                    {row.quantity} x {row.productCode} - {currencyFormat.format(row.subTotal)}{" "}
                                                                                    <span className="textError">({row.r ?? "No Longer Available"})</span>
                                                                                </Typography>
                                                                                {(row.bundleId && (
                                                                                    <Box
                                                                                        style={{
                                                                                            borderLeft: "1px solid #ef3340",
                                                                                            paddingLeft: 7.5,
                                                                                            marginLeft: 2,
                                                                                        }}
                                                                                    >
                                                                                        {row?.productName?.split?.("\n").map((item, key) => (
                                                                                            <Typography
                                                                                                key={key}
                                                                                                variant="caption"
                                                                                                component="div"
                                                                                                className={isEven(key) ? `fw-400` : undefined}
                                                                                                gutterBottom={isOdd(key)}
                                                                                            >
                                                                                                {item}
                                                                                            </Typography>
                                                                                        ))}
                                                                                    </Box>
                                                                                )) || (
                                                                                    <Typography variant="caption" className="fw-400">
                                                                                        {row.productName}
                                                                                    </Typography>
                                                                                )}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                        {replicate && _.some(formData.productRows, el =>  el.previousPrice && el.previousPrice !== el.price) && (
                                                            <Grid item xs={12}>
                                                                <Box className="content-light-info-white" p={3} pb={1.5}>
                                                                    <Typography variant="h6" gutterBottom>
                                                                        Price Changes
                                                                    </Typography>
                                                                    <Typography variant="body2" className="fw-400 text-error">
                                                                        The following prices have changed since this was last {document === 'Order' ? 'ordered' : 'quoted'} -
                                                                    </Typography>
                                                                    <ul>
                                                                        {_.map(_.filter(formData.productRows, el => !el.bundleId && el.previousPrice && el.previousPrice !== el.price), (row, idx) => (
                                                                            <li key={idx}>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    {row.quantity} x {row.productCode} - {row.productName}
                                                                                </Typography>
                                                                                <Typography variant="caption" paragraph>
                                                                                    Current Price: {currencyFormat.format(row.price)}{` | `}
                                                                                    Previous Price: {currencyFormat.format(row.previousPrice)}{` | `}
                                                                                    Difference: {currencyFormat.format(row.price - row.previousPrice)}{` | `}
                                                                                    Total Difference: {currencyFormat.format(parseInt(row.quantity) * (row.price - row.previousPrice))}
                                                                                </Typography>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <Products
                                                                access={access}
                                                                blanketDiscount={this.state.blanketDiscount}
                                                                classes={classes}
                                                                cardFormats={lists.cardFormats}
                                                                cardProgrammers={lists.cardProgrammers}
                                                                cardTypes={lists.cardTypes}
                                                                deployConfirmation={deployConfirmation}
                                                                document={document}
                                                                getCustomerData={this.getCustomerData}
                                                                customer={customer?.id}
                                                                customerVatPercent={formData.customerVatPercent}
                                                                formData={formData}
                                                                formErrors={formErrors}
                                                                handleApplyOrderDiscount={this.handleApplyOrderDiscount}
                                                                handleCheckedChange={this.handleCheckedChange}
                                                                handleResetOrderDiscount={this.handleResetOrderDiscount}
                                                                handleBlanketDiscountChange={this.handleBlanketDiscountChange}
                                                                handleProductAdd={this.handleProductAdd}
                                                                handleProductEdit={this.handleProductEdit}
                                                                handleProductRemove={this.handleProductRemove}
                                                                handleProductDrag={this.handleProductDrag}
                                                                handleSetPrimaryIdx={this.handleSetPrimaryIdx}
                                                                handleSwitchApiRoute={this.handleSwitchApiRoute}
                                                                ifm={ifm}
                                                                isUpdate={id}
                                                                products={products}
                                                                selectorIdxValue={this.state.primaryIdx}
                                                                sites={customer?.sites}
                                                                id={id}
                                                                inForm
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <PaddedPaper>
                                                                <Typography variant="h6" gutterBottom>
                                                                    {this.state.document} Notes
                                                                </Typography>
                                                                <Typography variant="caption" color="error" paragraph>
                                                                    <FAIcon type="solid" icon="info-circle" size={11} noMargin /> These notes will be visible to the customer
                                                                </Typography>
                                                                <Textarea name="notes" value={formData.notes} onChange={this.handleChange} margin="none" fullWidth variant="outlined" rows={5} />
                                                            </PaddedPaper>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            {frequentProducts && (
                                                <Grid item xs={12} className={`${classes.absoluteBottom} w-100`}>
                                                    <Frequent action={this.handleFrequentProduct} classes={classes} products={customer?.data?.fp ?? []} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={2}>
                                        <Box p={3}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Billing
                                                                access={access}
                                                                contactList={customer.contactList}
                                                                paymentTermsList={lists.paymentTerms}
                                                                customer={customer}
                                                                formData={formData}
                                                                formErrors={formErrors}
                                                                handleNull={this.handleNull}
                                                                handleChange={this.handleChange}
                                                                handleDateChange={this.handleDateChange}
                                                                handleSelectChange={this.handleSelectChange}
                                                                document={document}
                                                                order={order}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ContactUpdates
                                                                contacts={customer?.data?.contacts}
                                                                document={document}
                                                                formData={formData}
                                                                formErrors={formErrors}
                                                                handleChange={this.handleChange}
                                                                handleCheckedChange={this.handleCheckedChange}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Shipping
                                                                addressList={customer.addressList}
                                                                carriageList={lists.carriage}
                                                                carriageOptions={carriage.options}
                                                                contactList={customer.contactList}
                                                                customer={customer}
                                                                formData={formData}
                                                                formErrors={formErrors}
                                                                handleChange={this.handleChange}
                                                                handleCarriageChange={this.handleCarriageChange}
                                                                handleDateChange={this.handleDateChange}
                                                                handleDeployAddressChange={this.handleDeployAddressChange}
                                                                handleNull={this.handleNull}
                                                                handleSelectChange={this.handleSelectChange}
                                                                handleDeployAddressLookup={this.handleDeployAddressLookup}
                                                                handleUseBillingForDeliveryAddress={this.handleUseBillingForDeliveryAddress}
                                                                handleDeliveryCustomChargeChange={this.handleDeliveryCustomChargeChange}
                                                                handleDeliveryCustomChargeFloat={this.handleDeliveryCustomChargeFloat}
                                                                document={document}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <DeliveryInstructions formData={formData} handleChange={this.handleChange} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={3}>
                                        <Box p={3}>
                                            <Notes
                                                document={document}
                                                formData={formData}
                                                existingNotes={existingNotes}
                                                handleNoteAdd={this.handleNoteAdd}
                                                handleNoteEdit={this.handleNoteEdit}
                                                handleNoteRemove={this.handleNoteRemove}
                                                loggedInStaff={this.props.loggedInStaff}
                                                staff={this.props.staff}
                                                teams={lists.teams}
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={4}>
                                        {requiresCardOrder && (
                                            <Box p={3}>
                                                <Grid container spacing={3} alignItems="center">
                                                    {_.map(formData.productRows, (row, idx) => {
                                                        if (row?.productData?.cDb === 1) {
                                                            const rowData = row,
                                                                notes = _.size(rowData?.notes ?? rowData.productData?.notes ?? rowData?.productData?.notes ?? rowData?.product?.notes ?? []),
                                                                status =
                                                                    rowData.status ??
                                                                    rowData.productData?.status ??
                                                                    rowData.productData?.product_status ??
                                                                    rowData.product?.product_status ??
                                                                    "Unknown",
                                                                replacement =
                                                                    rowData.productRep > 0 ||
                                                                    rowData.productData?.productRep > 0 ||
                                                                    rowData.productData?.product_rep_product_id > 0 ||
                                                                    rowData.product?.product_rep_product_id > 0,
                                                                inStock = rowData.instock ?? rowData.productData?.instock ?? rowData.productData?.stock_count ?? rowData.product?.stock_count ?? 0,
                                                                preOrdered =
                                                                    rowData.preOrdered ??
                                                                    rowData.productData?.preOrdered ??
                                                                    rowData.productData?.stock_on_order_allocated_count ??
                                                                    rowData.product?.stock_on_order_allocated_count ??
                                                                    0,
                                                                available =
                                                                    rowData.available ??
                                                                    rowData.productData?.available ??
                                                                    rowData.productData?.stock_available_count ??
                                                                    rowData.product?.stock_available_count ??
                                                                    0,
                                                                allocated =
                                                                    rowData.allocated ??
                                                                    rowData.productData?.allocated ??
                                                                    rowData.productData?.stock_allocated_count ??
                                                                    rowData.product?.stock_allocated_count ??
                                                                    0,
                                                                onOrder =
                                                                    rowData.onOrder ??
                                                                    rowData.productData?.onOrder ??
                                                                    rowData.productData?.stock_on_order_count ??
                                                                    rowData.product?.stock_on_order_count ??
                                                                    0,
                                                                chkPr =
                                                                    rowData.chkPr ||
                                                                    rowData.productData?.chkPr ||
                                                                    rowData.productData?.product_check_price === 1 ||
                                                                    rowData.product?.product_check_price === 1,
                                                                stck =
                                                                    rowData.stkIt ||
                                                                    rowData.productData?.stkIt ||
                                                                    rowData.productData?.product_stock_item === 1 ||
                                                                    rowData.product?.product_stock_item === 1,
                                                                quantity = rowData.quantity,
                                                                cardOrder = rowData.productData?.cDb === 1 || rowData.cardDb === 1 || rowData.productData?.cardDb === 1,
                                                                site = _.find(customer.sites, (el) => el.value === row.cdbSite);

                                                            return (
                                                                <Grid item xs={12} key={idx}>
                                                                    <Paper>
                                                                        <Box p={3}>
                                                                            <Grid container justify="space-between" alignItems="center">
                                                                                <Grid item>
                                                                                    <Typography variant="h6" gutterBottom>
                                                                                        Card Order {idx + 1} of {_.size(_.filter(formData.productRows, (el) => el?.productData?.cDb === 1))}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item align="right">
                                                                                    <Tooltip title={row?.cdbSite === "" ? "Provide Card Details" : "Modify Card Details"}>
                                                                                        <IconButton onClick={() => this.handleDeployProductEdit({ ...row, rowIdx: idx })}>
                                                                                            <FAIcon icon="pencil" button noMargin />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={1} alignItems="center" style={{ marginTop: -12 }}>
                                                                                <Grid item className="pr-2">
                                                                                    <Avatar
                                                                                        src={rowData.image ? `${CLOUDFRONT_URL}${rowData.image}` : logo}
                                                                                        style={{ overflow: "visible", width: 75, height: 75 }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs>
                                                                                    <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                                        <Grid item>
                                                                                            <Typography variant="body2" className="fw-400">
                                                                                                {rowData.productCode}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={12}>
                                                                                            <Typography variant="caption" component="div">
                                                                                                {rowData.productName}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        {(rowData?.efs === "Invoiced" || rowData?.efs === "Despatched") && (
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant="caption" component="div">
                                                                                                    {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        )}
                                                                                        <Grid item>
                                                                                            {((rowData?.efs === "Invoiced" || rowData?.efs === "Despatched") && (
                                                                                                <InsightChip
                                                                                                    color="#ef3340"
                                                                                                    icon="exclamation-triangle"
                                                                                                    label={`${rowData.efs} - This line cannot be amended or removed`}
                                                                                                    marginRight={8}
                                                                                                    paddingLeft={8}
                                                                                                    paddingRight={4}
                                                                                                />
                                                                                            )) || (
                                                                                                <Box pt={1}>
                                                                                                    <ProductStockChip
                                                                                                        qty={quantity}
                                                                                                        rep={replacement}
                                                                                                        status={status}
                                                                                                        stocked={stck}
                                                                                                        stockAvailable={available}
                                                                                                        stockOnOrderAvailable={onOrder - preOrdered}
                                                                                                    />
                                                                                                </Box>
                                                                                            )}
                                                                                        </Grid>
                                                                                        {cardOrder && (
                                                                                            <Grid item>
                                                                                                <Box pt={1}>
                                                                                                    <InsightChip
                                                                                                        background="#ef3340"
                                                                                                        color="#fff"
                                                                                                        marginRight={8}
                                                                                                        paddingLeft={8}
                                                                                                        icon="exclamation-triangle"
                                                                                                        label={"Card Order Authorisation"}
                                                                                                        size={11}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        )}
                                                                                        {chkPr && (
                                                                                            <Grid item>
                                                                                                <Box pt={1}>
                                                                                                    <InsightChip
                                                                                                        background="#FF5722"
                                                                                                        color="#fff"
                                                                                                        marginRight={8}
                                                                                                        paddingLeft={8}
                                                                                                        icon="exclamation-triangle"
                                                                                                        label="Check Price and Availability"
                                                                                                    />
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        )}
                                                                                        {notes > 0 && (
                                                                                            <Grid item>
                                                                                                <Box pt={1}>
                                                                                                    <InsightChip
                                                                                                        background="#4CAF50"
                                                                                                        color="#fff"
                                                                                                        marginRight={8}
                                                                                                        paddingLeft={8}
                                                                                                        icon="comment-alt-lines"
                                                                                                        label={notes}
                                                                                                        onClick={() =>
                                                                                                            this.handleDeployNotes(
                                                                                                                rowData.productCode,
                                                                                                                rowData.notes ?? rowData.productData?.notes ?? []
                                                                                                            )
                                                                                                        }
                                                                                                        size={11}
                                                                                                        tooltip="View Notes"
                                                                                                    />
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        )}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item align="right">
                                                                                    <Typography variant="body1" className="fw-400 mr-3" paragraph>
                                                                                        Quantity: {row.quantity}
                                                                                    </Typography>
                                                                                    {site?.latestOrder && (
                                                                                        <>
                                                                                            {site?.latestOrder?.co_internal_range_end && (
                                                                                                <Typography variant="body2">
                                                                                                    <strong>Last Site Internal Range End:</strong> {site?.latestOrder?.co_internal_range_end ?? "N/A"}
                                                                                                </Typography>
                                                                                            )}
                                                                                            {site?.latestOrder?.co_external_range_end && (
                                                                                                <Typography variant="body2">
                                                                                                    <strong>Last Site External Range End:</strong> {site?.latestOrder?.co_external_range_end ?? "N/A"}
                                                                                                </Typography>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                            {(row.cdbSite === "" && (
                                                                                <Box pt={2} pb={2}>
                                                                                    <Alert severity="warning">Please provide card details for this line</Alert>
                                                                                </Box>
                                                                            )) || (
                                                                                <>
                                                                                    <Box pt={3} pb={2}>
                                                                                        <Divider />
                                                                                    </Box>
                                                                                    <Table>
                                                                                        <TableBody>
                                                                                            <TableRow className="pl-1">
                                                                                                <TableCell className={classes.td} style={{ width: "35%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbComments"
                                                                                                        label="Customer Site"
                                                                                                        value={
                                                                                                            customer?.sites?.[_.findIndex(customer.sites, (_el) => _el.value === row.cdbSite)]?.label
                                                                                                        }
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: "12.5%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbIntRangeStart"
                                                                                                        label="Internal Range Start"
                                                                                                        value={row.cdbIntRangeStart}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: "12.5%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbIntRangeEnd"
                                                                                                        label="Internal Range End"
                                                                                                        value={row.cdbIntRangeEnd}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: "12.5%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbExtRangeStart"
                                                                                                        label="External Range Start"
                                                                                                        value={row.cdbExtRangeStart}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: "12.5%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbExtRangeEnd"
                                                                                                        label="External Range End"
                                                                                                        value={row.cdbExtRangeEnd}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: 60 }}>
                                                                                                    <Box width={50} />
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                    <Table>
                                                                                        <TableBody>
                                                                                            <TableRow className="pl-1">
                                                                                                <TableCell className={classes.td} style={{ width: "50%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbComments"
                                                                                                        label="Comments"
                                                                                                        value={row.cdbComments}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: "17%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbComments"
                                                                                                        label="Card Format"
                                                                                                        value={
                                                                                                            lists?.cardFormats?.[_.findIndex(lists.cardFormats, (_el) => _el.value === row.cdbFormat)]
                                                                                                                ?.label
                                                                                                        }
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: "17%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbComments"
                                                                                                        label="Card Type"
                                                                                                        value={
                                                                                                            lists?.cardTypes?.[_.findIndex(lists.cardTypes, (_el) => _el.value === row.cdbType)]?.label
                                                                                                        }
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: "17%" }}>
                                                                                                    <TextField
                                                                                                        name="cdbComments"
                                                                                                        label="Programmer Type"
                                                                                                        value={
                                                                                                            lists?.cardProgrammers?.[
                                                                                                                _.findIndex(lists.cardProgrammers, (_el) => _el.value === row.cdbProgrammer)
                                                                                                            ]?.label
                                                                                                        }
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        disabled
                                                                                                        InputProps={{
                                                                                                            className: "textDefault",
                                                                                                            disableUnderline: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{ width: 60 }}>
                                                                                                    <Box width={50} />
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </>
                                                                            )}
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                            );
                                                        } else {
                                                            return <React.Fragment />;
                                                        }
                                                    })}
                                                </Grid>
                                            </Box>
                                        )}
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={5}>
                                        <Preview
                                            classes={classes}
                                            height={
                                                overlimit ||
                                                deliveryApproval ||
                                                (placeOrder && placeOrderWarning) ||
                                                ((document === "Order" || placeOrder) &&
                                                    formData.customer !== initialState().formData?.customer &&
                                                    formData.paymentTerms === initialState().formData?.paymentTerms)
                                                    ? ui.contentHeight - 49
                                                    : ui.contentHeight
                                            }
                                            preview={preview}
                                            handlePreviewLoad={this.handlePreviewLoad}
                                            handlePreviewPagination={this.handlePreviewPagination}
                                        />
                                    </TabPanel>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} lg={2} className={`${classes.side} h-100`}>
                            <Grid container className={`${classes.relative} h-100`}>
                                <Grid item xs={12}>
                                    <Tabs
                                        classes={classes}
                                        currentTab={currentTab}
                                        document={document}
                                        disableCustomerTab={disableCustomerTab}
                                        disablePreview={currentTab === 5}
                                        disabled={isLoading}
                                        formData={formData}
                                        handleTabChange={this.handleTabChange}
                                        placeOrder={placeOrder}
                                    />
                                </Grid>
                                <Grid item xs={12} className={`${classes.absoluteBottom} ${classes.totals} w-100`}>
                                    <Totals
                                        classes={classes}
                                        carriageId={formData.carriage?.carriageId}
                                        carriageTotal={formData.carriageTotal}
                                        customerVatPercent={formData.customerVatPercent}
                                        discountTotal={formData.discountTotal}
                                        grossTotal={formData.grossTotal}
                                        netTotal={formData.netTotal}
                                        subTotal={formData.subTotal}
                                        totalMargin={formData.totalMargin}
                                        vatTotal={formData.vatTotal}
                                        subDiscountTotal={formData.subDiscountTotal}
                                        inForm
                                    />
                                    {(!isLoading && currentTab === 5 && (
                                        <Button
                                            submit={this.handleConfirmation}
                                            disabled={(_.isEmpty(_.filter(formData.productRows, (el) => el.productId > 0 || el?.bundleId)) || currentTab !== 5 || isLoading) && true}
                                            text={id ? quote?.quote_status === 'Draft' ? `Finalise Quote` : `Update ${document}` : `Create ${document}`}
                                        />
                                    )) || (
                                        <>
                                            <Box mt={3}>
                                                <Grid container spacing={3} justify="center">
                                                    <Grid item>
                                                        <MuiButton
                                                            color="primary"
                                                            disabled={currentTab === 0 || (currentTab === 1 && (id || placeOrder)) || isLoading}
                                                            onClick={() => this.handleTabChange(currentTab - 1)}
                                                            variant="text"
                                                        >
                                                            <FAIcon icon="arrow-left" disabled={isLoading} size={15} button />
                                                            Back
                                                        </MuiButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <MuiButton
                                                            color="primary"
                                                            onClick={() => this.handleTabChange(currentTab + 1)}
                                                            disabled={
                                                                currentTab === 0 ||
                                                                isLoading ||
                                                                (currentTab === 3 && _.isEmpty(formData.productRows)) ||
                                                                (currentTab === 4 && _.some(formData?.productRows, (el) => el?.productData?.cDb === 1 && el?.cdbSite === ""))
                                                            }
                                                            variant="text"
                                                        >
                                                            <FAIcon icon="arrow-right" disabled={isLoading || (currentTab === 3 && _.isEmpty(formData.productRows))} size={15} button />
                                                            Next
                                                        </MuiButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    )}
                                    {id && apiRoute === "sales" && (
                                        <Box mt={1.25} align="center">
                                            <MuiButton
                                                color="primary"
                                                disabled={isLoading}
                                                onClick={() => deployConfirmation(`Are you sure you want to cancel changes?`, () => history.push(`/${apiRoute}/${id}`))}
                                                variant="text"
                                            >
                                                <FAIcon icon="times" size={15} button />
                                                Cancel
                                            </MuiButton>
                                        </Box>
                                    )}
                                    {customer?.data?.cust_prospect !== 1 && (!id || (id && apiRoute === "quotes" && quote?.quote_status !== 'Draft')) && !forRMA && (
                                        <Box mt={1.5} align="center">
                                            <MuiButton
                                                color="default"
                                                disabled={!_.isEmpty(_.filter(formData.productRows, (el) => el.cdbSite !== "" && el.productId > 0)) || isLoading}
                                                onClick={() => deployConfirmation(`Are you sure you want to ${apiRoute === "sales" ? placeOrder ? "switch to update the existing quote" : "switch to a quote" : id ? "switch to place an order" : "switch to a sales order"}?`, this.handleSwitchApiRoute)}
                                                variant="text"
                                            >
                                                <FAIcon icon="sync" size={15} button />
                                                {apiRoute === "sales" ? placeOrder ? "Update Existing Quote" : "Switch to Quote" : id ? "Switch to Place Order" : "Switch to Sales Order"}
                                            </MuiButton>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <AddressLookupDialog
                            key={dialog.addressLookupKey}
                            open={dialog.addressLookup}
                            formData={dialog?.useExistingAddress ? formData.deliveryAddressCustom : undefined}
                            onSubmit={dialog.addressType === "delivery" ? this.handleCustomDeliveryAddress : this.handleCustomCustomerAddress}
                        />
                    </>
                )}
            </Grid>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        discount: state.notifications.discount,
        loggedInStaff: state.staffAuth.staff,
        staff: state.notifications.status,
        teamList: state.notifications.teamList,
        ui: state.ui,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, yes, no, successText = null, closeText = null) => dispatch(deployConfirmation(message, yes, no, successText, closeText)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = "", variant = "standard", size = "md", fullscreen = false, disabled = false) =>
            dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disabled)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Form)(withRouter(QuoteOrderForm)));