import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

const TeamChip = ({team, label, size, style}) => {
    if(team?.name) {
        return (    
            <Chip 
                avatar={<Avatar alt={team?.name ?? "Team"} style={{backgroundColor: team?.color}} />}
                label={`${label ? label : ''}${team?.name ?? "Team"}`}
                size={size ?? 'small'}
                variant="outlined"
                style={{border: 0, fontWeight: 400, ...style}}
            />
        )
    } else {
        return <React.Fragment />
    }
}

export default TeamChip;