import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Textarea from 'Components/Common/Inputs/Textarea';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { Grid } from '@material-ui/core';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = () => ({
    isLoading: true,
    formData: {
        name: '',
        text: ''
    },
    formErrors: [],
    template: false
})

const SmsTemplateForm = ({id, callback, closeDialog, deployConfirmation, deploySnackBar}) => {

    const [state, setState] = useState(initialState());

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                [name]: value
            }
        }));
    }

    const handleCancel = () => {
        if(closeDialog) {
            closeDialog();
        }
    }

    const handleSubmit = () => {
        const apiRoute = id ? `/sms/templates/${id}` : `/sms/templates`;
        API.post(apiRoute, state.formData)
        .then(res => {
            if(res?.data) {
                if(res.data.errors) {
                    setState(state => ({
                        ...state,
                        formErrors: formatValidationErrors(res.data.errors)
                    }))
                } else {
                    if(deploySnackBar) {
                        deploySnackBar("success", `You have successfully ${id ? 'updated' : 'created'} the SMS template`);
                    }
                    if(callback) {
                        callback();
                    }
                    if(closeDialog) {
                        closeDialog();
                    }
                }
            }
        })
    }

    useEffect(() => {
        if(id) {
            API.get(`/sms/templates/${id}`)
            .then(res => {
                if(res?.data) {
                    setState({
                        isLoading: false,
                        formData: {
                            name: res.data.st_name,
                            text: res.data.st_text
                       
                        },
                        template: res.data
                    })
                }
            })
        } else {
            setState(state => ({
                ...state,
                isLoading: false
            }))
        }
    }, [id]);

    const isLoading = state.isLoading,
           formData = state.formData,
         formErrors = state.formErrors,
           template = state.template;
           
    return (
        isLoading && (
            <LoadingCircle />
        )) || (
            <form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3} className="pt-2">
                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                        <TextField
                            name="name"
                            label="SMS Template *"
                            value={formData.name}
                            error={formErrors && formErrors['name'] && true}
                            helperText={formErrors && formErrors['name']}
                            onChange={handleChange}
                            margin="none"
                            fullWidth
                            disabled={template?.st_system === 1}
                        /> 
                    </Grid>
                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                        <AutoCompleteSelect
                            options={[{label: 'AdvAccess', value: 'AdvAccess'}]} 
                            label='Default Sender *'
                            value={'AdvAccess'}
                            noClear
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea
                            variant="outlined"
                            name="text"
                            label="Default Content *"
                            value={formData.text}
                            error={formErrors && formErrors['text'] && true}
                            helperText={formErrors && formErrors['text']}
                            onChange={handleChange}
                            rows={7}
                            margin="none"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <div className='buttonRow'>
                    <Button 
                        onClick={handleCancel}
                        variant="text"
                        color="default"
                    >
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => deployConfirmation(`Are you sure you want to ${id ? 'update' : 'create'} this SMS template?`, handleSubmit)}
                        variant="text"
                        color="primary"
                    >
                        <FAIcon icon="check" size={15} button />
                        {id ? 'Update' : 'Create'}
                    </Button>
                </div>
            </form>
    )
}

export default SmsTemplateForm;