import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import ActionCreditReport from 'Components/Customers/Misc/ActionCreditReport';
import DataTable from 'Components/Common/DataTables/DataTable';
import InsightChip from 'Components/Common/Chips/InsightChip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { CLOUDFRONT_URL } from 'Constants';
import { currencyFormat } from 'Functions/MiscFunctions';

const ViewCreditReport = ({request}) => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Box mt={1.5}>  
            {!_.isEmpty(request?.reason?.crr_reason) && (
                <Box p={3} mb={3} className='content-light-grey'>
                    <Typography variant="h6" className="fw-400" gutterBottom>
                        Request Details
                    </Typography>
                    <Typography variant="caption">
                        Requested By: {staff?.[request.rc_staff_id]?.name ?? 'Unknown'} - {moment(request.rc_created_datetime, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}
                    </Typography>
                    <Typography variant="body2" className="fw-400 mt-2" gutterBottom>
                        Reason
                    </Typography>
                    <Typography variant="caption">
                        {!_.isEmpty(request?.reason?.crr_reason) ? request?.reason?.crr_reason : 'Routine'}
                    </Typography>
                    <Typography variant="body2" className="fw-400 mt-2" gutterBottom>
                        Comments
                    </Typography>
                    <Typography variant="caption">
                        {(!_.isEmpty(request.rc_request_comments) && (
                            _.map(request.rc_request_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)
                        )) || (
                            <em>No comments were made</em>
                        )}
                    </Typography>
                </Box>
            )}
            {request.quote && (
                <Box className="content-light-grey" p={3} pt={2} pb={2} mb={3}>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                            <Typography variant="body2" className="fw-400">
                                Quote #{request.quote?.quote_reference}/{request.quote.quote_revision} - Gross Total: {currencyFormat.format(request.quote.quote_total_gross)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="View Quote (In New Tab)">
                                <IconButton
                                    onClick={() => window.open(`/quotes/${request.quote.quote_id}`)}
                                >
                                    <FAIcon icon="external-link" size={12.5} noMargin button />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Box className={request.rc_status === 'Good' ? 'content-light-success' : (request.rc_status === 'Satisfactory' ? 'content-light-warning' : 'content-light-error')} p={3} pt={2} pb={2}>
                <Typography variant="h6" className="fw-400" gutterBottom>
                    Credit Rating
                </Typography>
                <Typography variant="caption">
                    Decision Maker: {staff?.[request.rc_actioned_staff_id]?.name ?? 'Unknown'} - {moment(request.rc_actioned_datetime, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}
                </Typography>
                <Box mt={2} mb={1.5}>
                    <Box pb={1}>
                        <Typography variant="body2" className="fw-400">
                            Outcome
                        </Typography>
                    </Box>
                    <InsightChip
                        label={`Credit ${request.rc_status}`}
                        icon={request.rc_status === 'Good' || request.rc_status === 'Satisfactory' ? 'check' : 'times'}
                        background={request.rc_status === 'Good' ? '#4CAF50' : (request.rc_status === 'Satisfactory' ? '#F57C00' : '#f44336')}
                        color='#fff'
                        paddingLeft={8}
                        marginRight={8}
                    />
                </Box>
                <Box mt={3} mb={1}>
                    <Typography variant="body2" className="fw-400" gutterBottom>
                        Comments
                    </Typography>
                    <Typography variant="caption">
                        {(!_.isEmpty(request.rc_comments) && (
                            _.map(request.rc_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)
                        )) || (
                            <em>No comments were made</em>
                        )}
                    </Typography>
                </Box>
            </Box>
            {request.credit_report && (
                <Box className='content-light-grey' p={3} pt={2} pb={2} mt={3}>
                     <Typography variant="h6" className="fw-400">
                        Credit Report
                    </Typography>
                    <Typography variant="caption" paragraph>
                        You can view the credit report used for this check below
                    </Typography>
                    <Link href={`${CLOUDFRONT_URL}${request.credit_report.doc_filekey}`} target="_blank" className="textDefault">
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item>
                                <FAIcon icon="file-pdf" className="textError" size={30} button noMargin />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2">
                                    {request.credit_report.doc_title}
                                </Typography>
                                <Typography variant="caption">
                                    Credit Report
                                </Typography>
                            </Grid>
                        </Grid>
                    </Link>
                </Box>
            )}
        </Box>
    )
}

const CreditOverview = ({access, callback, creditLoading, customer, closeDialog, deployConfirmation, deployDialog, deploySnackBar}) => { 
    const   staff       = useSelector(state => state.notifications.status),
            available   = customer.credit_limit ? (parseFloat(customer.credit_limit) - parseFloat(customer.credit_balance)) : null;
    return (
        <Grid container spacing={3}>
            {customer.prospect === 0 && (
                <Grid item xs={12} lg={6}>
                    <Paper>
                        <Box p={3}>
                            <Heading text={customer.has_credit === 1 ? 'Credit Account' : 'Prepay Account'} />
                            <Grid container spacing={1}>
                                {(customer.has_credit === 1 && (
                                    <>
                                        <Grid item xs={12}>
                                            <Box mb={2}>
                                                <Alert severity="success">
                                                    This customer has a formal credit facility
                                                </Alert>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardHeader
                                                action={
                                                    creditLoading && (
                                                        <LoadingCircle color='#fff' padding={8} size={20} />
                                                    )
                                                }
                                                title={<Typography variant='h3' style={{textAlign: 'center', color: '#fff'}}>{currencyFormat.format(customer.credit_limit)}</Typography>}
                                                subheader={<Typography variant="subtitle1" style={{textAlign: 'center', color: '#fff'}}>Credit Limit</Typography>}
                                                style={{backgroundColor: '#ef3340', borderRadius: 8}}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'check' : 'times'} className={customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'textSuccess' : 'textError'} noMargin size={35} />}
                                                title={<Typography variant='h5'>{customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'Yes' : 'No'}</Typography>}
                                                subheader={<Typography variant="caption">Terms Agreed</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'check' : 'exclamation-triangle'} className={customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'textSuccess' : 'textError'} noMargin size={35} />}
                                                title={<Typography variant='h5'>{customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'Yes' : 'No'}</Typography>}
                                                subheader={<Typography variant="caption">Terms Agreed</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={parseFloat(customer.credit_balance) < parseFloat(customer.credit_limit) ? 'check' : 'times'} className={parseFloat(customer.credit_balance) < parseFloat(customer.credit_limit) ? 'textSuccess' : 'textError'} size={35} noMargin />}
                                                title={<Typography variant='h5'>{currencyFormat.format(parseFloat(customer.credit_balance))}</Typography>}
                                                subheader={<Typography variant="caption">Balance</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={parseFloat(available) > 0 ? 'check' : 'times'} className={parseFloat(available) > 0 ? 'textSuccess' : 'textError'} size={35} noMargin />}
                                                title={<Typography variant='h5'>{available ? currencyFormat.format(available) : '£0.00'}</Typography>}
                                                subheader={<Typography variant="caption">Available Credit</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={parseFloat(customer.euler) > 0 ? 'check' : 'times'} className={parseFloat(customer.euler) > 0 ? 'textSuccess' : 'textError'} noMargin size={35} />}
                                                title={<Typography variant='h5'>{customer.euler !== "0.00" ? currencyFormat.format(customer.euler) : 'None'}</Typography>}
                                                subheader={<Typography variant="caption">Euler Cover</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={!_.isEmpty(customer.request_credit_report) ? (customer.request_credit_report?.[0]?.rc_status === 'Poor' ? 'times' : 'check') : 'question-circle'} className={!_.isEmpty(customer.request_credit_report) ? (customer.request_credit_report?.[0]?.rc_status === 'Poor' ? 'textError' : 'textSuccess') : undefined} size={35} noMargin />}
                                                title={<Typography variant='h5'>{!_.isEmpty(customer.request_credit_report) ? customer.request_credit_report?.[0]?.rc_status : 'No Data Available'}</Typography>}
                                                subheader={<Typography variant="caption">Last Credit Check</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                    </>
                                )) || (
                                    <>
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                This customer does not have a formal credit facility
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon='check' className="textSuccess" noMargin size={35} />}
                                                title={<Typography variant='h5'>{customer?.credit_data?.terms ?? '-'}</Typography>}
                                                subheader={<Typography variant="caption">Account Terms</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'check' : 'times'} className={customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'textSuccess' : 'textError'} noMargin size={35} />}
                                                title={<Typography variant='h5'>{customer?.credit_data?.termsAgreed && parseInt(customer?.credit_data?.termsAgreed) === 1 ? 'Yes' : 'No'}</Typography>}
                                                subheader={<Typography variant="caption">Terms Agreed</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={parseFloat(customer.credit_balance) > 0 ? 'times' : 'check'} className={parseFloat(customer.credit_balance) > 0 ? 'textError' : 'textSuccess'} size={35} noMargin />}
                                                title={<Typography variant='h5'>{currencyFormat.format(parseFloat(customer.credit_balance))}</Typography>}
                                                subheader={<Typography variant="caption">Balance</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={!_.isEmpty(customer.request_credit_report) ? (customer.request_credit_report?.[0]?.rc_status === 'Poor' ? 'times' : 'check') : 'question-circle'} className={!_.isEmpty(customer.request_credit_report) ? (customer.request_credit_report?.[0]?.rc_status === 'Poor' ? 'textError' : 'textSuccess') : undefined} size={35} noMargin />}
                                                title={<Typography variant='h5'>{!_.isEmpty(customer.request_credit_report) ? customer.request_credit_report?.[0]?.rc_status : 'No Data Available'}</Typography>}
                                                subheader={<Typography variant="caption">Last Credit Check</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            )}
            <Grid item xs={12} lg={6}>
                <Paper>
                    <Box p={3}>
                        <Heading text="Credit Checks" />
                        {!access.actionCreditReport && !_.isEmpty(customer.request_credit_report_outstanding) && (
                            <Box mb={3}>
                                <Alert severity="info">
                                    A credit check is outstanding for {customer.company_name}
                                </Alert>
                            </Box>
                        )}
                        {access.actionCreditReport && !_.isEmpty(customer.request_credit_report_outstanding) && (
                            _.map(customer.request_credit_report_outstanding, (req, idx) => (
                                <Box mb={3} key={idx}>
                                    <Alert severity={req.quote ? 'error' : 'warning'}>
                                        <Grid container alignItems='center'>
                                            <Grid item>
                                                {req.quote ? `A credit check is outstanding for Quote #${req.quote?.quote_reference}/${req.quote?.quote_revision}`: `A credit check is outstanding for ${customer.company_name}`}
                                            </Grid>
                                            <Grid item>
                                                <Box ml={3}>
                                                    <Button
                                                        onClick={() => deployDialog(<ActionCreditReport callback={callback} closeDialog={closeDialog} customer={customer} deployConfirmation={deployConfirmation} deploySnackBar={deploySnackBar} request={req} />, false, req.quote ? 'Quote Credit Check' : 'Credit Check', "standard", "sm", false, true)}
                                                        size="small"
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="check" button />
                                                        Action Now
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Alert>
                                </Box>
                            ))
                        )}
                        <DataTable
                            config={{
                                key: 'rc_id',
                                pagination: true,
                                alternatingRowColours: true,
                                // responsiveImportance: true,
                                plainPagination: true,
                                noResultsText: 'No credit checks have been completed for this account',
                                inline: true,
                                options: {
                                    dataRef: true,
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Completed',
                                    field: rowData => rowData.rc_actioned === 1 ? moment(rowData.rc_actioned_datetime).format("DD/MM/YYYY") : '-',
                                    dataRef: 'rc_actioned_datetime',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Requested By',
                                    field: rowData => staff?.[rowData.rc_staff_id]?.name ?? '-',
                                    dataRef: 'rc_staff_id',
                                },
                                {
                                    heading: 'Reason',
                                    field: rowData => !_.isEmpty(rowData.reason?.crr_reason) ? rowData.reason?.crr_reason : 'Routine',
                                },
                                {
                                    heading: 'Outcome',
                                    field: rowData => (
                                        <InsightChip
                                            label={rowData.rc_status}
                                            icon={rowData.rc_status === 'Good' || rowData.rc_status === 'Satisfactory' ? 'check' : (rowData.rc_status === 'No Data Available' ? 'question' : 'times')}
                                            background={rowData.rc_status === 'Good' ? '#4CAF50' : (rowData.rc_status === 'Satisfactory' ? '#F57C00' : (rowData.rc_status === 'No Data Available' ? '#aaa' : '#d9534f'))}
                                            color='#fff'
                                            paddingLeft={8}
                                            marginRight={8}
                                        />
                                    ),
                                    dataRef: 'rc_status',
                                    sizeToContent: true,
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View Request', icon: 'binoculars', onClick: () => deployDialog(<ViewCreditReport request={rowData} />, false, "Credit Check Details", "standard", "sm"), doubleClick: true},
                                        ]
                                    }
                                }
                            ]}
                            rows={customer.request_credit_report}
                        />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}   

export default CreditOverview;