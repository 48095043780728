import * as StatePersistenceActionTypes from './Types/Types';

export function setPersistence(key, state) {
    return (dispatch) => {
        dispatch({
            type: StatePersistenceActionTypes.SET_PERSISTENCE,
            payload: {
                [key]: state
            }
        });
    }
}

export function clearPersistence() {
    return (dispatch) => {
        dispatch({
            type: StatePersistenceActionTypes.CLEAR_PERSISTENCE
        });
    }
}

export function clearPersistenceKey(key) {
    return (dispatch) => {
        dispatch({
            type: StatePersistenceActionTypes.CLEAR_PERSISTENCE_KEY,
            payload: key
        });
    }
}