import toUint8Array from 'base64-to-uint8array';
import { isSafari } from 'Functions/MiscFunctions';

export function pdfFromBase64(base64Data, filename) {
    
    var blob = new Blob([new toUint8Array(base64Data)], {type: "application/pdf"})

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
    } 

    const url = window.URL.createObjectURL(blob);
    
    var a = document.createElement('a');

    a.href = url;

    if(!isSafari()) {
        a.target = '_blank'
    }

    a.click();

    setTimeout(function(){
        // window.URL.revokeObjectURL(url);
        a.remove();
    }, 100);
    
}