import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

class PaymentTermsList extends React.Component {
    render() {
        const { access, paymentTerms, isLoading } = this.props;
        return (
            <>
            {(isLoading && <LoadingCircle />) || (
                (paymentTerms && paymentTerms.length > 0 && (
                    <DataTable  
                        config={{
                            key: 'pt_id',
                            isLoading: isLoading,
                            options: {
                                minimalPadding: true
                            }
                        }}
                        columns={[
                            {
                                heading: 'Name',
                                field: rowData => rowData.pt_name,
                            },
                            {
                                heading: 'Payment Due Days',
                                field: rowData => rowData.pt_due_days,
                            },
                            {
                                actions: rowData => {
                                    return [
                                        {name: 'Update', icon: 'pencil', onClick: () => this.props.handleDialogOpen(rowData.pt_id), disabled: !access.updatePaymentTerm && true},
                                    ]
                                }
                            }
                        ]}
                        rows={paymentTerms}
                    />
                )) || (
                    <List>
                        <ListItem>
                            <ListItemText primary={<em>No payment terms to display</em>} />
                        </ListItem>
                    </List>
                )
            )}
            </>
        );
    }
}

export default PaymentTermsList;