import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handleChange, handleCheckedChange } from 'Functions/FormFunctions';

const initialState = () => ({
    breatheHrEmployees: [],
    initialFormData: {},
    formData: {
        breatheHrRef: '',
        firstName: '',
        lastName: '',
        email: '',
        status: '',
        role: '',
        phone: '01604 647555',
        mailingList: 1,
        notifications: 1
    },
    formErrors: {},
    confirmationOpen: false,
    isLoading: true,
})

class UpdateStaffDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        let initialDataFieldMapping = {
            'staff_breathe_hr_ref': 'breatheHrRef',
            'staff_first_name': 'firstName',
            'staff_last_name': 'lastName',
            'staff_email': 'email',
            'staff_status': 'status',
            'staff_role': 'role',
            'staff_phone': 'phone',
            'staff_inc_mailing_list': 'mailingList',
            'staff_notifications': 'notifications'
        }
        Promise.all([
            API.get('/staff/breatheHr'),
            API.get('/staff/' + this.props.staffId)
        ])
        .then(([breathe, result]) => {                 
            if(result.data) {
                let breatheHrAssigned = [];
                let breatheHrEmployees = [];
                if(breathe.data){
                    _.each(breathe.data.staff, (staff) => {
                        breatheHrAssigned.push(staff.staff_breathe_hr_ref)
                    })
                    breatheHrEmployees = _.map(breathe.data.employeeList, el => {
                        return _.assign({
                            value: el.ref,
                            label: el.name,
                            disabled: el.ref === 'None' ? false : (el.ref === result.data.staff_breathe_hr_ref ? false : (breatheHrAssigned.includes(el.ref) ? true : false))
                        });
                    });
                }
                let initialFormData = {};// eslint-disable-next-line
                for (const [key, value] of Object.entries(initialDataFieldMapping)) {
                    if(result.data[key] || result.data[key] === 0) {
                        initialFormData[value] = result.data[key];
                    }
                }
                if(initialFormData.breatheHrRef === 0)
                    initialFormData.breatheHrRef = 'None'
                this.setState({
                    breatheHrEmployees: breatheHrEmployees,
                    initialFormData: initialFormData,
                    formData: initialFormData,
                    isLoading: false
                })
            }
        })
    }

    handleSubmit = () => {
        API.put('/staff/' + this.props.staffId, this.state.formData).then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...this.state,
                    initialFormData: this.state.formData
                }, () => {
                    this.props.deploySnackBar("success", "The staff member's details have been updated")
                    this.props.toggleDialog()
                })
            }
        })
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    }

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    render() {
        const { breatheHrEmployees, formErrors, initialFormData, isLoading, formData } = this.state;
        return (
            <>                
                {(isLoading && (
                    <LoadingCircle />
                )) || (
                    <>
                        <form noValidate autoComplete="off">
                            <TextField
                                name="firstName"
                                label="First Name"
                                margin="none"
                                error={formErrors && formErrors['firstName'] && true}
                                helperText={formErrors && formErrors['firstName']}
                                value={formData.firstName}
                                InputLabelProps={{
                                    shrink: !!formData.firstName
                                }}
                                fullWidth
                                onChange={this.handleChange}
                            />
                            <TextField
                                name="lastName"
                                label="Last Name"
                                margin="none"
                                error={formErrors && formErrors['lastName'] && true}
                                helperText={formErrors && formErrors['lastName']}
                                value={formData.lastName}
                                InputLabelProps={{
                                    shrink: !!formData.lastName
                                }}
                                fullWidth
                                onChange={this.handleChange}
                            />
                            <TextField
                                name="email"
                                label="Email Address"
                                margin="none"
                                error={formErrors && formErrors['email'] && true}
                                helperText={formErrors && formErrors['email']}
                                value={formData.email}
                                InputLabelProps={{
                                    shrink: !!formData.email
                                }}
                                fullWidth
                                onChange={this.handleChange}
                            />
                            <TextField
                                name="role"
                                label="Job Role*"
                                margin="none"
                                autoComplete="off"
                                error={formErrors && formErrors['role'] && true}
                                helperText={formErrors && formErrors['role']}
                                value={formData.role || ''}
                                fullWidth
                                onChange={this.handleChange}
                            />
                            <TextField
                                name="phone"
                                label="Phone Number *"
                                margin="none"
                                autoComplete="off"
                                error={formErrors && formErrors['phone'] && true}
                                helperText={formErrors && formErrors['phone']}
                                value={formData.phone || ''}
                                fullWidth
                                onChange={this.handleChange}
                            />
                            <AutoCompleteSelect 
                                options={[
                                    {value: 'active', label: 'Active'},
                                    {value: 'inactive', label: 'Inactive'}
                                ]}
                                label='Status *'
                                onChange={this.handleSelectChange('status')}
                                error={formErrors && formErrors['status'] && true}
                                errorText={formErrors && formErrors['status']}
                                value={this.state.formData.status}
                            />
                            <AutoCompleteSelect 
                                options={breatheHrEmployees}
                                label='Breathe HR Employee *'
                                onChange={this.handleSelectChange('breatheHrRef')}
                                error={formErrors && formErrors['breatheHrRef'] && true}
                                errorText={formErrors && formErrors['breatheHrRef']}
                                value={this.state.formData.breatheHrRef}
                            />
                            <FormControlLabel
                                control={
                                    <Switch color="primary" checked={formData.mailingList} onChange={this.handleCheckedChange} name="mailingList" value={1} />
                                }
                                label={<Typography variant="body2" className="fw-400">Include on mailing list</Typography>}
                            />
                            <FormControlLabel
                                control={
                                    <Switch color="primary" checked={formData.notifications} onChange={this.handleCheckedChange} name="notifications" value={1} />
                                }
                                label={<Typography variant="body2" className="fw-400">Enable Notifications</Typography>}
                            />
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Update staff member?" 
                                message="Are you sure you want to update this staff member?"
                            />
                        </form>
                    </>
                )}
                <DialogActions className='pr-0 pb-0'>
                    <Button 
                        onClick={() => this.props.toggleDialog()} 
                        variant="text"
                    >
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                    <Button 
                        color="primary"
                        disabled={_.isEqual(initialFormData, formData)}
                        onClick={this.handleConfirmationOpen} 
                        variant="text" 
                    >
                        <FAIcon icon="check" size={15} button />
                        Update
                    </Button>
                </DialogActions>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(UpdateStaffDetailsForm);