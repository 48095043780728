import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { isTablet } from 'Functions/MiscFunctions';

import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        addOrder: false,
        viewOrder: false
    },
    dataLoading: true,
    searchFilter: '',
    searchResults: {},
    searchString: ''
});

class CardOrderSearch extends React.Component {
    constructor(props) {
        super(props);
        this.persistenceId = 'cardOrderSearch';
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Card DB'])
        if(!this.hasPageState()) {
            API.multiAccess([
                'add-card-order',
                'view-card-order',
            ])
            .then(([
                addOrder, 
                viewOrder
            ]) => {
                this.setState({
                    access: {
                        addOrder,
                        viewOrder
                    }
                }, () => this.getSearchData(true));
            })
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getSearchData = dataLoading => {
        const { searchFilter, searchString } = this.state;    
        // if(searchString.length >= 2 || searchFilter !== '') {
            this.setState({
                dataLoading
            }, () => {
                API.get('/card-orders/all', {
                    props: {
                        cancellation: true,
                    },
                    params: { 
                        searchFilter,
                        searchString
                    } 
                })
                .then(result => {
                    if(result && result.data) {
                        this.setState({
                            dataLoading: false,
                            searchResults: result.data
                        }, () => {
                            this.savePageState();
                        });
                    }
                });
            });
        // } else {
        //     this.setState({
        //         searchResults: []
        //     })
        // }
    }
    
    onSearchFilterChange = (selectedOption) => {
        this.setState({
            searchFilter: selectedOption?.value ?? ''
        },
        () => {
            this.setSearch();
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access
            }, () => {
                this.getSearchData(true);
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getSearchData(true), 250);
    }

    render() {
        const { dataLoading, searchFilter, searchString } = this.state;
        return (
            <Grid container spacing={1}>      
                <Grid container item xs={12} alignItems='center'>
                    <Grid item sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                        <TextField
                            fullWidth
                            className="pr-2"
                            onChange={this.onSearchStringChange}
                            placeholder='Search:'
                            value={searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                            }}
                            autoFocus={!isTablet()}
                        />
                    </Grid>   
                    <Grid item sm={4} md={3} xl={2} style={{marginBottom: 12}}>
                        <AutoCompleteSelect
                            onChange={this.onSearchFilterChange}
                            options={[
                                {
                                    label: '1 - Order Created - Awaiting Authorisation',
                                    value: 1
                                },
                                {
                                    label: '2 - Authorisation Received',
                                    value: 2
                                },
                                {
                                    label: '3 - Programmed',
                                    value: 3
                                },
                                {
                                    label: '4 - Checked',
                                    value: 4
                                },
                                {
                                    label: '5 - Despatched',
                                    value: 5
                                },
                                {
                                    label: '6 - Completed',
                                    value: 6
                                },
                                {
                                    label: '7 - Cancelled',
                                    value: 7
                                }
                            ]}
                            placeholder='Status:'
                            value={searchFilter} 
                            variant="filled"                                
                            adornment="filter"
                        />
                    </Grid>      
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable
                            config={{
                                key: 'i',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                noResultsText: searchString.length >= 2 ? 'No card orders were found' : 'Start typing to search card orders',
                                persistenceId: this.persistenceId,
                                options: {
                                    dataRef: true,
                                    defaultSort: 'st',
                                    reset: this.resetSearch,
                                    export: {
                                        title: `Card Orders`,
                                        name: `Card Orders`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'SO Number',
                                    field: rowData => rowData.so ?? '-',
                                    important: true,
                                    dataRef: 'so',
                                    sizeToContent: true
                                },
                                // {
                                //     heading: 'CardDB Ref',
                                //     field: rowData => (
                                //         <>
                                //             <Typography variant="body2">
                                //                 {rowData.ref}
                                //             </Typography>
                                //             <Typography variant="caption">
                                //                 {rowData.cref}
                                //             </Typography>
                                //         </>
                                //     ),
                                //     important: true, 
                                //     dataRef: 'ref',
                                //     sizeToContent: true
                                // },
                                {
                                    heading: 'Order Date',
                                    field: rowData => rowData.dt,
                                    fieldFormat: 'date',
                                    dataRef: 'dt',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Customer',
                                    field: rowData => (
                                        <>
                                            {rowData.c}
                                            <Typography variant="caption" component="div">{rowData.acc}</Typography>
                                        </>
                                    ),
                                    dataRef: 'c',
                                    important: true,
                                    sizeToContent: true
                                },
                                // {
                                //     heading: 'Customer Ref',
                                //     field: rowData => rowData.crf || '-',
                                //     truncate: true,
                                //     dataRef: 'crf',
                                //     sizeToContent: true,
                                // },
                                {
                                    heading: 'Programmer Type',
                                    field: rowData => rowData.typ || '-',
                                    truncate: true,
                                    dataRef: 'typ',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Site',
                                    field: rowData => rowData.s ?? '-',
                                    important: true,
                                    dataRef: 's',
                                    truncate: true
                                },
                                {
                                    heading: 'Order Qty',
                                    field: rowData => rowData.q,
                                    dataRef: 'q',
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Pack Size',
                                    field: rowData => rowData.p,
                                    dataRef: 'p',
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Total Qty',
                                    field: rowData => rowData.tq,
                                    dataRef: 'tq',
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Ext. Range Start',
                                    field: rowData => rowData.r.es,
                                    dataRef: 'r.es',
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Ext. Range End',
                                    field: rowData => rowData.r.ee,
                                    dataRef: 'r.ee',
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                
                                {
                                    heading: 'Order Status',
                                    field: rowData => <StatusChip status={rowData.st} />,
                                    dataRef: 'st',
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'binoculars', onClick: () => this.props.history.push({pathname: `/sales/${rowData.soId}`, state: { fromCardOrderSearch: true }}), disabled: !rowData.soId, doubleClick: true},
                                            {name: 'Process Customer Authorisation', icon: 'check', link: `/sales/process/${rowData.soId}`, disabled: !rowData.soId || rowData.st !== "1 - Order Created - Awaiting Authorisation", doubleClick: true},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </Paper>
                </Grid>
            </Grid>   
        );
    }
}
		
const mapStateToProps = state => ({
    statePersistence: state.statePersistence,
})

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(CardOrderSearch);