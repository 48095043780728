import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import CustomerSearchSelector from 'Components/Customers/Misc/CustomerSearchSelector';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { handleChange, handleCheckedChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = () => ({
    formData: {
        siteCode: '',
        siteName: '',
        customer: '',
        active: 1
    },
    formErrors: {},
})

class CardDbSiteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
    }

    componentDidMount = () => {
        
        const {
            site
        } = this.props;

        if(site) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    siteCode: site.site_code,
                    siteName: site.site_description,
                    active: parseInt(site.site_active),
                    customer: site.site_customer_id
                }
            })
        }
        
    }

    handleCustomerChange = customer => {
        this.setState({
            formData: {
                ...this.state.formData,
                customer
            }
        })
    }

    handleSubmitConfirmation = e => {

        const { 
            deployConfirmation, 
            site 
        } = this.props;

        e?.preventDefault?.();

        deployConfirmation(`Are you sure you want to ${site ? `update` : `create`} this site?`, this.handleSubmit)

    }

    handleSubmit = () => {

        const {
            callback,
            customer,
            deploySnackBar,
            site
        } = this.props;

        const {
            formData
        } = this.state;

        let apiRoute = `/customers/${customer}/sites`;

        if(site) {
            apiRoute += `/${site.site_id}`;
        }

        API.post(apiRoute, formData)
        .then(res => {
            if(res?.data) {
                if(res.data?.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors)
                    });
                } else {
                    this.setState(initialState(), () => {
                        deploySnackBar("success", `The site was successfully ${site ? 'updated' : 'created'}`)
                        callback?.();
                    })
                }
            }
        })
    }
    
    render() {
        const { closeDialog, site } = this.props;
        const { formData, formErrors } = this.state;
        return (
            <Paper>
                <Box p={3}>
                    <form noValidate autoComplete="off" onSubmit={this.handleSubmitConfirmation}>
                        <Typography variant="h6" paragraph>
                            {site ? 'Update' : 'Create'} Customer Site
                        </Typography>
                        <TextField
                            name="siteCode"
                            label="Site Code*"
                            margin="none" 
                            autoComplete="off"
                            error={formErrors && formErrors['siteCode'] && true}
                            helperText={formErrors && formErrors['siteCode']}
                            value={formData.siteCode}
                            onChange={this.handleChange}
                            fullWidth
                            autoFocus
                        />
                        <TextField
                            name="siteName"
                            label="Site Description *"
                            margin="none" 
                            autoComplete="off"
                            error={formErrors && formErrors['siteName'] && true}
                            helperText={formErrors && formErrors['siteName']}
                            value={formData.siteName}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        {site && (
                            <>
                                <Typography variant="caption" color="textSecondary">
                                    Change Site Customer
                                </Typography>
                                <Box mt={-2}>
                                    <CustomerSearchSelector
                                        basic
                                        label=''
                                        handleAction={this.handleCustomerChange}
                                        noFrequent
                                        noPaper
                                        noAutoFocus
                                    />
                                </Box>
                                <Box mt={2}>
                                    <FormControlLabel
                                        control={<Switch name="active" checked={formData.active === 1} color="primary" size="small" onChange={this.handleCheckedChange}/>}
                                        label={<Typography variant="body2"><Box pl={0.4}>{formData.active === 1 ? `Active` : `Inactive`}</Box></Typography>}
                                    />
                                </Box>
                            </>
                        )}
                        <div className="buttonRow">
                            {site && (
                                <Button 
                                    onClick={closeDialog} 
                                    variant="text" 
                                >
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                            )}
                            <Button 
                                color="primary"
                                disabled={formData.siteCode === "" || formData.siteName === ""}
                                type="submit"
                                variant="text" 
                            >
                                <FAIcon icon="check" size={15} button />
                                {site ? 'Update' : 'Create'} Site
                            </Button>
                        </div>
                    </form>
                </Box>
            </Paper>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(CardDbSiteForm);