import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import DataTable from 'Components/Common/DataTables/DataTable';
import { capitalize } from 'Functions/MiscFunctions';
import StaffChip from 'Components/Common/Chips/StaffChip';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { Typography } from '@material-ui/core';

const getColumns = ({access, staff, type}) => {
    switch(type) {
        case "picks":
            return [  
                {
                    heading: 'SO Number',
                    field: rowData => (
                            <>
                                <Typography variant="body2" className="fw-400">
                                    {rowData.ref}
                                </Typography>
                                <Typography variant="caption">
                                    {rowData.crf}
                                </Typography>
                            </>
                        ),
                    dataRef: 'ref',
                    main: true
                },   
                {
                    heading: 'Picked By',
                    field: rowData => <StaffChip staff={staff?.[rowData.staff]} />,
                    dataRef: 'staff',
                    fieldFormat: 'staff',
                },    
                {
                    heading: 'Pick Started',
                    field: rowData => rowData.started,
                    fieldFormat: 'datetime',
                    dataRef: 'started',
                },    
                {
                    heading: 'Pick Completed',
                    field: rowData => rowData.completed,
                    fieldFormat: 'datetime',
                    dataRef: 'completed',
                },
                {
                    heading: 'Pick Time',
                    field: rowData => rowData.durF,
                    dataRef: 'dur',
                },
                {
                    heading: 'Pick Quantity',
                    field: rowData => `${rowData.qty}/${rowData.exp}`,
                    dataRef: 'qty',
                    alignment: 'right'
                },
                {
                    heading: 'Order Lines',
                    field: rowData => rowData.ol,
                    dataRef: 'ol',
                    alignment: 'right'
                },  
                {
                    heading: 'Successful',
                    field: rowData => rowData.success,
                    fieldFormat: 'boolean',
                    dataRef: 'success',
                    alignment: 'center'
                },    
                {
                    heading: 'Pick Status',
                    field: rowData => <StatusChip status={rowData.status} />,
                    dataRef: 'status',
                },
                {
                    actions: rowData => ([
                        { name: 'View Delivery', disabled: !access.viewOrder, icon: 'binoculars',  link: `/sales/${rowData.order}:${rowData.del}` },
                    ])
                }
            ];
        case "checks":
            return [  
                {
                    heading: 'SO Number',
                    field: rowData => (
                        <>
                            <Typography variant="body2" className="fw-400">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.crf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true
                },     
                {
                    heading: 'Checked By',
                    field: rowData => <StaffChip staff={staff?.[rowData.staff]} />,
                    dataRef: 'staff',
                    fieldFormat: 'staff',
                },    
                {
                    heading: 'Check Started',
                    field: rowData => rowData.started,
                    fieldFormat: 'datetime',
                    dataRef: 'started',
                },    
                {
                    heading: 'Check Complete',
                    field: rowData => rowData.completed,
                    fieldFormat: 'datetime',
                    dataRef: 'completed',
                },
                {
                    heading: 'Check Time',
                    field: rowData => rowData.durF,
                    dataRef: 'dur',
                },
                {
                    heading: 'Check Quantity',
                    field: rowData => `${rowData.qty}/${rowData.exp}`,
                    dataRef: 'qty',
                    alignment: 'right'
                },
                {
                    heading: 'Order Lines',
                    field: rowData => rowData.ol,
                    dataRef: 'ol',
                    alignment: 'right'
                }, 
                {
                    heading: 'Successful',
                    field: rowData => rowData.success,
                    fieldFormat: 'boolean',
                    dataRef: 'success',
                    alignment: 'center'
                },    
                {
                    heading: 'Check Status',
                    field: rowData => <StatusChip status={rowData.status} />,
                    dataRef: 'status',
                },
                {
                    actions: rowData => ([
                        { name: 'View Delivery', disabled: !access.viewOrder, icon: 'binoculars',  link: `/sales/${rowData.order}:${rowData.del}` },
                    ])
                }
            ];
        case "despatches":
            return [  
                {
                    heading: 'SO Number',
                    field: rowData => (
                    <>
                        <Typography variant="body2" className="fw-400">
                            {rowData.ref}
                        </Typography>
                        <Typography variant="caption">
                            {rowData.crf}
                        </Typography>
                    </>
                ),
                    dataRef: 'ref',
                    main: true
                },      
                {
                    heading: 'Service',
                    field: rowData => rowData.service,
                    dataRef: 'service',
                },  
                {
                    heading: 'Product',
                    field: rowData => rowData.product,
                    dataRef: 'product',
                },
                {
                    heading: 'Boxes',
                    field: rowData => rowData.boxes,
                    dataRef: 'boxes',
                },
                {
                    heading: 'Despatch Date',
                    field: rowData => rowData.created,
                    fieldFormat: 'datetime',
                    dataRef: 'created',
                },
                {
                    heading: 'Tracking Number',
                    field: rowData => rowData.tracking,
                    dataRef: 'tracking',
                },
                {
                    heading: 'Picked By',
                    field: rowData => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || '-',
                    dataRef: 'psid',
                    fieldFormat: 'staff',
                }, 
                {
                    heading: 'Checked By',
                    field: rowData => (staff && staff[rowData.csid] && <StaffChip staff={staff[rowData.csid]} />) || '-',
                    dataRef: 'csid',
                    fieldFormat: 'staff',
                },
                {
                    heading: 'Despatched By',
                    field: rowData => <StaffChip staff={staff?.[rowData.staff]} />,
                    dataRef: 'staff',
                    fieldFormat: 'staff',
                }, 
                {
                    actions: rowData => ([
                        { name: 'View Delivery', disabled: !access.viewOrder, icon: 'binoculars',  link: `/sales/${rowData.order}:${rowData.del}` },
                        { name: 'Track Order', icon: 'location',  linkExternal: `https://track.dpdlocal.co.uk/search?reference=${rowData.tracking}&postcode=${rowData.postcode?.replace?.(' ', '')?.toLowerCase?.()}`, disabled: _.isEmpty(rowData.tracking) || rowData.tracking === '-'},
                    ])
                }
            ];
        case "collections":
            return [  
                {
                    heading: 'SO Number',
                    field: rowData => rowData.ref,
                    dataRef: 'ref',
                    main: true
                },
                {
                    heading: 'Order Date',
                    field: rowData => rowData.od,
                    dataRef: 'od',
                    sizeToContent: true
                }, 
                {
                    heading: 'Customer',
                    field: rowData => rowData.c,
                    dataRef: 'c',
                    main: true,
                    sizeToContent: true
                },
                {
                    heading: 'Account Number',
                    field: rowData => rowData.acc,
                    dataRef: 'acc',
                    main: true,
                    sizeToContent: true
                },
                {
                    heading: 'Customer Order Ref',
                    field: rowData => rowData.crf || '-',
                    truncate: true,
                    dataRef: 'crf',
                    main: true,
                    sizeToContent: true
                },
                {
                    heading: 'Collected',
                    field: rowData => rowData.cllectd,
                    fieldFormat: 'datetime',
                    dataRef: 'cllectd',
                },  
                {
                    heading: 'Collected By',
                    field: rowData => rowData.name,
                    dataRef: 'name',
                },   
                {
                    heading: 'Processed By',
                    field: rowData => <StaffChip staff={staff?.[rowData.staff]} />,
                    dataRef: 'staff',
                    fieldFormat: 'staff',
                },    
                {
                    heading: 'QR Scanned',
                    field: rowData => rowData.scanned,
                    fieldFormat: 'boolean',
                    dataRef: 'scanned',
                },    
                
                {
                    actions: rowData => ([
                        { name: 'View Delivery', disabled: !access.viewOrder, icon: 'binoculars',  link: `/sales/${rowData.order}:${rowData.del}` },
                    ])
                }
            ];
        default: 
            return []
    }
}

const SearchWarehouseTable = ({access, dataLoading, persistenceId, resetSearch, rows, type}) => {
    const staff = useSelector(state => state.notifications.status)
    return (
        <DataTable  
            config={{
                key: 'id',
                pagination: true,
                alternatingRowColours: true,
                isLoading: dataLoading,
                persistenceId: persistenceId ?? null,
                responsiveImportance: true,
                options: {
                    dataRef: true,
                    resetSearch: resetSearch ?? undefined,
                    export: {
                        title: capitalize(type),
                        name: capitalize(type),
                        excel: true,
                        pdf: true,
                        print: true
                    }
                }
            }}
            columns={getColumns({access, staff, type})}
            rows={rows}
        />
    )
}

export default SearchWarehouseTable;