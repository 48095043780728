import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handleAppError } from 'Redux/Actions/UI/UI';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import {
    hasStoredProfile, 
    getStoredProfile, 
} from 'Functions/AuthFunctions';

const initialState = {
    formData: {
        password: '',
        confirmPassword: ''
    },
    formErrors: [],
    storedProfile: {}
}

class ErrorUpdatePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        if(hasStoredProfile()) {
            this.setState({
                storedProfile: getStoredProfile(),
            });
        }
    }
    
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        })
    }

    handleSubmit = () => {
        API.put('/staff/my/password', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {                
                this.props.deploySnackBar("success", "Your password has been successfully updated")
                this.props.handleAppError(false, "");
            }
        })
    }

    render() {
        const { formData, formErrors } = this.state
        return (
            <Grid container justify="center">                
                <Grid item xs={12} lg={11} align="center">
                    <form noValidate autoComplete="off">    
                        <Grid container spacing={3}>     
                            {/* <Grid item xs={12}>
                                <StoredProfile staffData={storedProfile} />
                            </Grid>                     */}
                            <Grid item xs={12}>
                                <Typography variant="h6" className="fw-400" gutterBottom>
                                    Password Expired
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Please enter a new password to use RED
                                </Typography>
                                { this.state.formErrors.generic && 
                                    <Typography component="div" variant="caption" color="error"  style={{width: '100%', textAlign: 'center', marginBottom: 12}}>{this.state.formErrors.generic}</Typography>
                                }              
                            </Grid>  
                            <Grid item xs={12}>
                                <TextField
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="New password"
                                    error={formErrors && formErrors['password'] && true}
                                    helperText={formErrors && formErrors['password']}
                                    onChange={this.handleChange}
                                    margin="none"
                                    fullWidth
                                    autoComplete="newpassword"
                                    required
                                    value={formData.password}
                                    autoFocus  
                                    variant="filled"  
                                />                   
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirm password"
                                    error={formErrors && formErrors['confirmPassword'] && true}
                                    helperText={formErrors && formErrors['confirmPassword']}
                                    onChange={this.handleChange}
                                    margin="none"
                                    fullWidth
                                    required
                                    value={formData.confirmPassword}
                                    variant="filled"   
                                />                   
                            </Grid>
                            <Grid item xs={12}>    
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.formData.password.length > 0 ? false : true}
                                >                 
                                    <FAIcon icon="sign-in" type="solid" disabled={this.state.formData.password.length > 0 ? false : true} size={15} buttonPrimary />
                                    Update Password
                                </Button>   
                            </Grid>                             
                        </Grid>                                                 
                    </form>                    
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message))
    };
}

export default connect(null, mapDispatchToProps)(ErrorUpdatePassword);