import React from 'react';
import { CirclePicker } from 'react-color';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import * as Settings from 'Helpers/SettingsHelper';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = {
    formData: {
        qtLabel: '',
        qtMax: '',
        qtMin: '',
        qtColor: '',
    },
    updateData: {
        qtId: '',
        qtLabel: '',
        qtMax: '',
        qtMin: '',
        qtColor: '',
    },
    formErrors:[],
    updateErrors: [],
    dialogOpen: false,
    rows: [],
    confirmationOpen: {
        submit: false,
        update: false,
    },
    snackbarOpen: {
        submit: false,
        update: false,
    },
    isLoading: true,
    updateLoading: true
}

class QuoteThresholds extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getQuoteThresholds();
    }

    submit = () => {
        API.post('/quotes/quoteThresholds', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        update: false,
                        submit: true
                    }
                });
                this.getQuoteThresholds();
            }
            this.props.scrollToTop();
        });
    }

    update = () => {
        API.put('/quotes/quoteThresholds/'+this.state.updateData.qtId, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        update: true,
                        submit: false
                    }
                });
                this.getQuoteThresholds();
            }
            this.props.scrollToTop();
        });
    }

    getQuoteThresholds = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/quotes/quoteThresholds')
            .then((result) => {
                this.setState({
                    ...this.state,
                    rows: result.data,
                    isLoading: false
                });
            })
        })
    }
    
    getUpdateData = () => {
        API.get('/quotes/quoteThresholds/'+this.state.updateData.qtId)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose();
            } else {                
                this.setState({
                    updateLoading: false,
                    updateErrors: [],
                    updateData: {
                        ...this.state.updateData,
                        qtLabel: result.data.qt_label,
                        qtMin: result.data.qt_min,
                        qtMax: result.data.qt_max,
                        qtColor: result.data.qt_color,
                    }
                });
            }
        })
    }

    handleDeleteThreshold = id => {
        API.put(`/quotes/quoteThresholds/${id}/delete`)
        .then(result => {
            if(result?.data?.success) {
                this.props.deploySnackBar(`success`, `You have successfully deleted the requested quote threshold`)
                this.getQuoteThresholds();
            }
        })
    }
    
    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleColorChange = (colour, event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                qtColor: colour.hex
            }
        });
    };

    handleFloat = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: parseFloat(e.target.value).toFixed(2)
            }
        })
    }
    
    handleUpdateChange = e => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleUpdateColorChange = (colour, event) => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                qtColor: colour.hex
            }
        });
    };

    handleUpdateFloat = e => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                [e.target.name]: parseFloat(e.target.value).toFixed(2)
            }
        })
    }
    
    handleSnackbarClose = (type) => {
        this.setState({ 
            snackbarOpen: {
            ...this.state.snackbarOpen,
            [type]: false,
            } 
        });
    };

    handleConfirmationOpen = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: true,
            } 
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        }, () => {
            if(type === 'submit') {
                this.submit();
            } else {
                this.update();
            }
        });
    }

    handleDialogOpen = (id) => {
        this.setState({
            dialogOpen: true,
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                qtId: id
            }
        }, () => this.getUpdateData());
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpen: false,
            updateLoading: true,
            updateData: {
                ...initialState.updateData
            }
        });
    }

    render() {
        const { formData, formErrors, isLoading, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Quote Threshold Priorities
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'qt_id',
                                pagination: true,
                                isLoading: isLoading,
                                plainPagination: true,
                                options: {
                                    minimalPadding: true
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Threshold Label',
                                    field: rowData => rowData.qt_label,
                                    main: true
                                },                                
                                {
                                    heading: 'Minimum Value',
                                    field: rowData => `£${rowData.qt_min}`,
                                },
                                {
                                    heading: 'Maximum Value',
                                    field: rowData => `£${rowData.qt_max}`,
                                },
                                {
                                    heading: 'Color',
                                    field: rowData => <Avatar style={{width: 25, height: 25, backgroundColor: rowData.qt_color}} />,
                                    main: true
                                },     
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil',  onClick: () => this.handleDialogOpen(rowData.qt_id)},
                                            {name: 'Delete', icon: 'trash-alt',  onClick: () => this.props.deployConfirmation(`Are you sure you want to delete this threshold?`, () => this.handleDeleteThreshold(rowData.qt_id))},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.rows}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Threshold
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                name="qtLabel"
                                label="Threshold Label *"
                                value={formData.qtLabel}
                                error={formErrors && formErrors['qtLabel'] && true}
                                helperText={formErrors && formErrors['qtLabel']}
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                            <TextField
                                name="qtMin"
                                label="Minimum Value *"
                                value={formData.qtMin}
                                error={formErrors && formErrors['qtMin'] && true}
                                helperText={formErrors && formErrors['qtMin']}
                                onChange={this.handleChange}
                                onBlur={this.handleFloat}
                                margin="none"
                                fullWidth
                                type="number"
                                inputProps={{
                                    min: 0
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>
                                }}
                            />
                            <TextField
                                name="qtMax"
                                label="Maximum Value *"
                                value={formData.qtMax}
                                error={formErrors && formErrors['qtMax'] && true}
                                helperText={formErrors && formErrors['qtMax']}
                                onChange={this.handleChange}
                                onBlur={this.handleFloat}
                                margin="none"
                                fullWidth
                                type="number"
                                inputProps={{
                                    min: 0
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>
                                }}
                            /> 
                            <FormControl error={formErrors && formErrors['qtColor'] && true} fullWidth style={{marginTop: 8, marginBottom: 8}}>
                                <InputLabel shrink={true}>Colour *</InputLabel>
                                <br /><br />
                                <CirclePicker
                                    color={this.state.formData.qtColor}
                                    colors={Settings.colourSelection}
                                    onChangeComplete={this.handleColorChange}
                                />
                            </FormControl>
                            <div className='buttonRow'>
                                <Button 
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="text" 
                                    color="default" 
                                    disabled={!this.state.formData.qtLabel}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Add
                                </Button>
                            </div>
                        </form>
                        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={this.state.dialogOpen} onClose={this.handleDialogClose} fullWidth={true} maxWidth="sm">
                            <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Quote Threshold</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            name="qtLabel"
                                            label="Threshold Label *"
                                            value={updateData.qtLabel}
                                            error={updateErrors && updateErrors['qtLabel'] && true}
                                            helperText={updateErrors && updateErrors['qtLabel']}
                                            onChange={this.handleUpdateChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        <TextField
                                            name="qtMin"
                                            label="Minimum Value *"
                                            value={updateData.qtMin}
                                            error={updateErrors && updateErrors['qtMin'] && true}
                                            helperText={updateErrors && updateErrors['qtMin']}
                                            onChange={this.handleUpdateChange}
                                            onBlur={this.handleUpdateFloat}
                                            margin="none"
                                            fullWidth
                                            type="number"
                                            inputProps={{
                                                min: 0
                                            }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">£</InputAdornment>
                                            }}
                                        />
                                        <TextField
                                            name="qtMax"
                                            label="Maximum Value *"
                                            value={updateData.qtMax}
                                            error={updateErrors && updateErrors['qtMax'] && true}
                                            helperText={updateErrors && updateErrors['qtMax']}
                                            onChange={this.handleUpdateChange}
                                            onBlur={this.handleUpdateFloat}
                                            margin="none"
                                            fullWidth
                                            type="number"
                                            inputProps={{
                                                min: 0
                                            }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">£</InputAdornment>
                                            }}
                                        />                                       
                                        <FormControl error={updateErrors && updateErrors['qtColor'] && true} fullWidth style={{marginTop: 8, marginBottom: 8}}>
                                            <InputLabel shrink={true}>Colour *</InputLabel>
                                            <br /><br />
                                            <CirclePicker
                                                color={this.state.updateData.qtColor}
                                                colors={Settings.colourSelection}
                                                onChangeComplete={this.handleUpdateColorChange}
                                            />                                           
                                        </FormControl>
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text"><FAIcon icon="check" size={15} button />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            title="Update quote threshold?" 
                            message="Are you sure you want to update this quote priority threshold?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title="Add a new quote threshold?" 
                            message="Are you sure you want to add a new quote priority threshold?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the quote priority threshold'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new quote priority threshold'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    deployConfirmation: (message, success, cancel = false) => dispatch(deployConfirmation(message, success, cancel)),
})

export default connect(null, mapDispatchToProps)(QuoteThresholds);