import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        spacing: 3,
    },
    avatar: {
        width: 125,
        height: 125,
        marginBottom: 12,
    },
    [theme.breakpoints.down('sm')]: {
        root: {
            marginTop: 0,
            spacing: 2,
        },
        avatar: {
            width: 75,
            height: 75,
        },
    },
});

const StoredProfile = ({classes, darkTheme, staffData:{firstName}, staffData:{lastName}, staffData:{email}, staffData:{avatar}, ...props}) => (
    <Grid container className={classes.root}>
        <Grid item align="center" xs={12}>
            <Avatar alt={`${firstName} ${lastName}`} src={avatar} className={classes.avatar} />
        </Grid>
        <Grid item align="center" xs={12}>
            <Box pt={1}>
                <Typography variant="h5" className={`${darkTheme ? 'textLight' : ''} fw-400`} style={{fontSize: '1.5rem'}}>
                    {firstName} {lastName}
                </Typography>               
                <Typography variant="subtitle1" className={`${darkTheme ? 'textLight' : ''} fw-400`}>
                    {email}
                </Typography>
            </Box>
        </Grid>
    </Grid>
)

export default withStyles(styles)(StoredProfile);