import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import DocumentForm from 'Components/Common/Forms/DocumentForm';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { getPresignedUrl } from 'Functions/S3Functions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { Box, Chip } from '@material-ui/core';

const ViewDocuments = ({access, allAllPrivate, callback, category, disabled, documents, inline, privateFileChip, privateFileText, uploadFile}) => {
    const dispatch = useDispatch();
    
    const handleModify = id => dispatch(deployDialog(<DocumentForm cancel={() => dispatch(closeDialog())} callback={callback} category={category} privateFileText={privateFileText} updateId={id} allAllPrivate={allAllPrivate ? true : false} />, false, "Modify File", "standard","xs", false, true));
    
    const handleDelete = id => dispatch(deployConfirmation("Are you sure you want to delete this file?", () => confirmDelete(id)));

    const confirmDelete = id => {
        API.put(`/misc/documents/${id}/delete`)
        .then(res => {
            if(res?.data) {
                if(callback) {
                    callback();
                }
            }
        })
    }

    const staff = useSelector(state => state.notifications.status);
    return (
        (_.isEmpty(documents) && (
            <Typography variant="h5" className="fw-400" align='center'>There are no files uploaded yet</Typography>
        )) || (
            <Paper elevation={inline && 0}>
                <DataTable 
                    config={{
                        key: 'doc_id',
                        pagination: true,
                        alternatingRowColours: true,
                        // responsiveImportance: true,
                        plainPagination: true,
                        inline: inline ?? false,
                        options: {
                            headingInput: (
                                <>
                                    {access.uploadDoc && uploadFile && (
                                        <Button
                                            variant="text"
                                            color="primary"
                                            disabled={disabled}
                                            size="small"
                                            onClick={uploadFile}
                                        >
                                            <FAIcon type="light" icon="plus-circle" size={15} button />
                                            Upload New File
                                        </Button>
                                    )}
                                </>
                            ),
                            dataRef: true,
                            export: {
                                title: `Documents`,
                                name: `Docs`,
                                excel: true,
                                pdf: true,
                                print: true
                            }
                        }
                    }}
                    columns={[
                        {
                            heading: '',
                            field: rowData => <FAIcon icon={rowData.doc_ra === 1 ? 'exclamation-triangle' : 'check'} className={rowData.doc_ra === 1 ? 'textError' : 'textSuccess'} size={15} noMargin />,
                            tooltip: rowData => rowData.doc_ra === 1 ? 'Requires Action' : 'No Action Required',
                            dataRef: 'doc_ra',
                            sizeToContent: true,
                            alignment: 'center'
                        },
                        {
                            heading: 'File',
                            field: rowData => {
                                const privateSuffix = rowData.doc_private ? <Chip label={privateFileChip ?? `Private`} size="small" style={{background: '#f2dede', color: '#000', fontWeight: 400}} /> : '';
                                if(rowData.doc_title.includes('.')) {
                                    return <Box>{rowData.doc_title} {privateSuffix}</Box>
                                } else {
                                    let fn  = rowData.doc_filekey.split("."),
                                        ext = !_.isEmpty(fn) ? `.${fn[_.size(fn) - 1]}` : '';
                                    return <Box>{rowData.doc_title}{ext} {privateSuffix}</Box>
                                }
                            },
                            dataRef: 'doc_title'
                        },
                        {
                            heading: 'Category',
                            field: rowData => `${rowData.category?.parent?.dc_name ?? ''} - ${rowData.category?.dc_name ?? ''}`,
                            sizeToContent: true
                        },
                        {
                            heading: 'Uploaded',
                            field: rowData => rowData.doc_created_datetime,
                            dataRef: 'doc_created_datetime',
                            fieldFormat: "datetime",
                            sizeToContent: true
                        },
                        {
                            heading: 'Uploaded By',
                            field: rowData => staff?.[rowData.doc_staff_id]?.name ?? '-',
                            dataRef: 'doc_staff_id',
                            sizeToContent: true
                        },
                        {
                            actions: rowData => {
                                let fileName = rowData.doc_title,
                                    split = rowData.doc_filekey.split("."),
                                    ext = !_.isEmpty(split) ? split[_.size(split) - 1] : '';
                                if(!fileName.includes(ext)) {
                                    fileName = `${fileName}.${ext}`
                                }
                                return [
                                    {name: 'Modify', icon: 'pencil', onClick: () => handleModify(rowData.doc_id), disabled: (disabled || !access.modifyDoc || rowData.category?.dc_name === "System"), hideIfDisabled: true},
                                    {name: 'Delete', icon: 'trash-alt', onClick: () => handleDelete(rowData.doc_id), disabled: (disabled || !access.deleteDoc || rowData.category?.dc_name === "System"), hideIfDisabled: true},
                                    {name: 'Download', icon: 'arrow-to-bottom', onClick: () => getPresignedUrl(rowData.doc_filekey, fileName), disabled: (!rowData.doc_filekey), doubleClick: true},
                                ]
                            }
                        }      
                    ]}
                    rows={documents}
                />
            </Paper>
        )
    )
}

export default ViewDocuments;