import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import DatePicker from 'Components/Common/DatePickers/DatePicker';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { AA_ADDRESS } from 'Constants';

const ShippingAddress = ({formData, formErrors, handleChange, handleScheduleChange, ifm, supplier}) => {
    return (
        <PaddedPaper>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Order Details
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        // disabled={ifm}
                        name="suppRef"
                        // placeholder={ifm ? 'N/A' : undefined}
                        label={`Supplier Reference${supplier?.data?.supp_po_required === 1 ? ' *' : ''}`}
                        value={formData.suppRef}
                        onChange={handleChange}
                        margin="none"
                        error={formErrors && formErrors['suppRef'] && true}
                        helperText={formErrors && formErrors['suppRef']}
                        // InputLabelProps={{
                        //     shrink: ifm ? true : undefined
                        // }}
                        fullWidth
                    />
                </Grid>
                {!ifm && (
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <DatePicker
                                type="date"
                                label={'Send Purchase Order On *'}
                                value={formData.schedule}
                                onChange={handleScheduleChange}
                                autoOk={true}
                                disablePast={true}
                            />
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                        Billing Address
                    </Typography>
                    {!_.isEmpty(AA_ADDRESS) && (
                        <Grid item xs={12}>
                            {_.map(AA_ADDRESS, (line, idx) => (
                                <Typography variant="body2" key={idx} gutterBottom>
                                    {line}
                                </Typography>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </PaddedPaper>
    )
}

export default ShippingAddress;