import React from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleUpdateChange,
    handleCheckedChange,
    handleUpdateCheckedChange,
    handleSnackbarClose,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
    handleDialogClose
} from 'Functions/FormFunctions';

const initialState = {
        formData: {
            ttName: '',
            ttIcon: '',
            ttContact: 0,
            ttCustomer: 0,
            ttProduct: 0,
            ttPo: 0,
            ttQuote: 0,
            ttOrder: 0,
            ttRMA: 0,
            ttNotes: 0,
            ttReqNotes: 0,
            ttReminder: 0,
            ttRepeat: 0,
            ttAsap: 0
        },
        updateData: {
            ttId: 0,
            ttName: '',
            ttIcon: '',
            ttContact: 0,
            ttCustomer: 0,
            ttProduct: 0,
            ttPo: 0,
            ttQuote: 0,
            ttOrder: 0,
            ttRMA: 0,
            ttNotes: 0,
            ttReqNotes: 0,
            ttReminder: 0,
            ttRepeat: 0,
            ttAsap: 0
        },
        formErrors:[],
        updateErrors: [],
        dialog: {
            update: false
        },
        searchResults: [],
        confirmation: {
            submit: false,
            update: false,
        },
        snackbar: {
            submit: false,
            update: false,
        },
        updateLoading: true
    }

class ActionTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleUpdateChange = handleUpdateChange.bind(this);
        this.handleUpdateCheckedChange = handleUpdateCheckedChange.bind(this);
        this.handleSnackbarClose = handleSnackbarClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleDialogClose = handleDialogClose.bind(this);
    }

    componentDidMount = () => {
        this.getActionTypes();
    }

    submit = () => {
        const { formData } = this.state;
        API.post(`/misc/actions/types`, formData)
        .then(result => {
            if(result?.data) {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors)
                    });
                } else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getActionTypes();
                }
                this.props.scrollToTop();
            }
        });
    }

    update = () => {
        const { updateData } = this.state;
        API.put(`/misc/actions/types/${updateData.ttId}`, updateData)
        .then(result => {
            if(result?.data) {
                if(result.data.errors) {
                    this.setState({
                        updateErrors: formatValidationErrors(result.data.errors)
                    });
                } else {
                    this.setState({
                        ...initialState,
                        snackbar: {
                            update: true,
                            submit: false
                        }
                    });
                    this.getActionTypes();
                }
                this.props.scrollToTop();
            }
        });
    }
    
    getUpdateData = () => {
        const { updateData } = this.state;
        API.get(`/misc/actions/types/${updateData.ttId}`)
        .then(result => {
            if(result?.data) {
                if(result.data.errors) {
                    this.handleDialogClose('update');
                } else {
                    this.setState({
                        updateLoading: false,
                        updateErrors: [],
                        updateData: {
                            ...this.state.updateData,
                            ttName: result.data.tt_name,
                            ttIcon: result.data.tt_icon,
                            ttContact: result.data.tt_for_contact,
                            ttCustomer: result.data.tt_for_customer,
                            ttProduct: result.data.tt_for_product,
                            ttPo: result.data.tt_for_po,
                            ttQuote: result.data.tt_for_quote,
                            ttOrder: result.data.tt_for_order,
                            ttRMA: result.data.tt_for_rma,
                            ttSupplier: result.data.tt_for_supplier,
                            ttNotes: result.data.tt_allow_notes,
                            ttReqNotes: result.data.tt_require_notes,
                            ttReminder: result.data.tt_is_reminder,
                            ttRepeat: result.data.tt_allow_repeat,
                            ttAsap: result.data.tt_is_asap
                        }
                    });
                }
            }
        })
    }
    
    getActionTypes = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/misc/actions/types')
            .then(result => {
                if(result?.data) {
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    });
                }
            })
        })
    }
    
    handleDialogOpen = ttId => {
        this.setState({
            dialog: {
                update: true
            },
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                ttId
            }
        }, () => this.getUpdateData());
    }

    render() {
        const { dialog, formData, formErrors, searchResults, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Action Types
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'tt_id',
                                pagination: true,
                                isLoading: this.state.isLoading,
                                plainPagination: true,
                                options: {
                                    minimalPadding: true
                                }
                            }}
                            columns={[
                                {
                                    heading: '',
                                    field: rowData => <FAIcon icon={rowData.tt_icon} size={15} noMargin />,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.tt_name,
                                    truncate: true
                                },
                                {
                                    heading: 'Actions',
                                    field: rowData => rowData.actions_count,
                                    truncate: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil',  onClick: () => this.handleDialogOpen(rowData.tt_id)},
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Action Type
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <TextField
                                        id="ttName"
                                        name="ttName"
                                        label="Action Name *"
                                        value={formData.ttName}
                                        error={formErrors?.['ttName'] && true}
                                        helperText={formErrors?.['ttName']}
                                        onChange={this.handleChange}
                                        margin="none"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <TextField
                                        id="ttIcon"
                                        name="ttIcon"
                                        label="Action Icon *"
                                        value={formData.ttIcon}
                                        error={formErrors?.['ttIcon'] && true}
                                        helperText={formErrors?.['ttIcon']}
                                        onChange={this.handleChange}
                                        margin="none"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon icon={formData.ttIcon} size="small" /></InputAdornment>
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Tooltip title="Search Icons">
                                        <IconButton
                                            onClick={() => window.open('https://www.fontawesome.com')}
                                        >
                                            <FAIcon icon="external-link" size={15} noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttContact === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttContact"
                                            value={formData.ttContact}
                                        />
                                    }
                                    label={`Applicable for Contacts (Customer / Supplier)`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttCustomer === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttCustomer"
                                            value={formData.ttCustomer}
                                        />
                                    }
                                    label={`Applicable for Customers`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttProduct === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttProduct"
                                            value={formData.ttProduct}
                                        />
                                    }
                                    label={`Applicable for Products`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttPo === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttPo"
                                            value={formData.ttPo}
                                        />
                                    }
                                    label={`Applicable for Purchase Orders`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttQuote === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttQuote"
                                            value={formData.ttQuote}
                                        />
                                    }
                                    label={`Applicable for Quotes`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttOrder === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttOrder"
                                            value={formData.ttOrder}
                                        />
                                    }
                                    label={`Applicable for Sales Orders`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttRMA === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttRMA"
                                            value={formData.ttRMA}
                                        />
                                    }
                                    label={`Applicable for RMA`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttSupplier === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttSupplier"
                                            value={formData.ttSupplier}
                                        />
                                    }
                                    label={`Applicable for Suppliers`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttNotes === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttNotes"
                                            value={formData.ttNotes}
                                        />
                                    }
                                    label={`Show Notes Field`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttReqNotes === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttReqNotes"
                                            value={formData.ttReqNotes}
                                        />
                                    }
                                    disabled={formData.ttNotes === 0}
                                    label={`Require Notes Field`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttAsap === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttAsap"
                                            value={formData.ttAsap}
                                        />
                                    }
                                    label={`Immediate Action (Due ASAP)`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttReminder === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttReminder"
                                            value={formData.ttReminder}
                                        />
                                    }
                                    disabled={formData.ttAsap === 1}
                                    label={`Show Date/Time Field`}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.ttRepeat === 1 ? true : false} 
                                            color="primary"
                                            onChange={this.handleCheckedChange} 
                                            name="ttRepeat"
                                            value={formData.ttRepeat}
                                        />
                                    }
                                    disabled={formData.ttReminder === 0 || formData.ttAsap === 1}
                                    label={`Show Repeat Options`}
                                />
                            </FormControl>
                            <div className='buttonRow'>
                                <Button 
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="text" 
                                    color="primary" 
                                    disabled={_.isEmpty(formData.ttName) || _.isEmpty(formData.ttIcon)}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Add
                                </Button>
                            </div>
                        </form>
                        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={dialog.update} onClose={() => this.handleDialogClose('update')} fullWidth={true} maxWidth="sm">
                            <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Action Type</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="ttName"
                                                    name="ttName"
                                                    label="Action Name *"
                                                    value={updateData.ttName}
                                                    error={updateErrors?.['ttName'] && true}
                                                    helperText={updateErrors?.['ttName']}
                                                    onChange={this.handleUpdateChange}
                                                    margin="none"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} lg={6}>
                                                <TextField
                                                    id="ttIcon"
                                                    name="ttIcon"
                                                    label="Action Icon *"
                                                    value={updateData.ttIcon}
                                                    error={updateErrors?.['ttIcon'] && true}
                                                    helperText={updateErrors?.['ttIcon']}
                                                    onChange={this.handleUpdateChange}
                                                    margin="none"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><FAIcon icon={updateData.ttIcon} size="small" /></InputAdornment>
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4} lg={6}>
                                                <Tooltip title="Search Icons">
                                                    <IconButton
                                                        onClick={() => window.open('https://www.fontawesome.com')}
                                                    >
                                                        <FAIcon icon="external-link" size={15} noMargin button />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttContact === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttContact"
                                                        value={updateData.ttContact}
                                                    />
                                                }
                                                label={`Applicable for Contacts (Customer / Supplier)`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttCustomer === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttCustomer"
                                                        value={updateData.ttCustomer}
                                                    />
                                                }
                                                label={`Applicable for Customers`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttProduct === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttProduct"
                                                        value={updateData.ttProduct}
                                                    />
                                                }
                                                label={`Applicable for Products`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttPo === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttPo"
                                                        value={updateData.ttPo}
                                                    />
                                                }
                                                label={`Applicable for Purchase Orders`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttQuote === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttQuote"
                                                        value={updateData.ttQuote}
                                                    />
                                                }
                                                label={`Applicable for Quotes`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttOrder === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttOrder"
                                                        value={updateData.ttOrder}
                                                    />
                                                }
                                                label={`Applicable for Sales Orders`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttSupplier === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttSupplier"
                                                        value={updateData.ttSupplier}
                                                    />
                                                }
                                                label={`Applicable for Suppliers`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttNotes === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttNotes"
                                                        value={updateData.ttNotes}
                                                    />
                                                }
                                                label={`Show Notes Field`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttReqNotes === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttReqNotes"
                                                        value={updateData.ttReqNotes}
                                                    />
                                                }
                                                disabled={updateData.ttNotes === 0}
                                                label={`Require Notes Field`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttAsap === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttAsap"
                                                        value={updateData.ttAsap}
                                                    />
                                                }
                                                label={`Immediate Action (Due ASAP)`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttReminder === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttReminder"
                                                        value={updateData.ttReminder}
                                                    />
                                                }
                                                disabled={updateData.ttAsap === 1}
                                                label={`Show Date/Time Field`}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={updateData.ttRepeat === 1 ? true : false} 
                                                        color="primary"
                                                        onChange={this.handleUpdateCheckedChange} 
                                                        name="ttRepeat"
                                                        value={updateData.ttRepeat}
                                                    />
                                                }
                                                disabled={updateData.ttReminder === 0 || updateData.ttAsap === 1}
                                                label={`Show Repeat Options`}
                                            />
                                        </FormControl>
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleDialogClose('update')} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text" disabled={_.isEmpty(updateData.ttName) || _.isEmpty(updateData.ttIcon)}><FAIcon icon="check" size={15} button />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            open={this.state.confirmation.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            message="Are you sure you want to update this action type?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmation.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            message="Are you sure you want to add a new action type?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the specified action type'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new action type'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

export default ActionTypes;