import React from 'react';

import Search from 'Components/Common/Search/Search';

const ProductHistory = ({inline, customerId, productId}) => {
    let apiParams = {
        productId
    }
    if(customerId) {
        apiParams = {
            ...apiParams,
            customer: customerId,
        }
    }
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/productHistory',
                noAutoLoad: true,
                apiParams,
                columns: [
                    {
                        heading: 'Date',
                        field: rowData => rowData.d,
                        fieldFormat: 'date',
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true,
                        showTotalLabel: true,
                    },
                    {
                        heading: 'Sales Order',
                        field: rowData => rowData.so,
                        dataRef: 'so',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer',
                        field: rowData => rowData.an,
                        dataRef: 'an',
                        important: true,
                        truncate: true
                    },
                    // {
                    //     heading: 'Acc Number',
                    //     field: rowData => rowData.ac,
                    //     dataRef: 'ac',
                    //     important: true,
                    //     sizeToContent: true
                    // },
                    {
                        heading: 'Quantity',
                        field: rowData => rowData.qty,
                        alignment: 'right',
                        dataRef: 'qty',
                        important: true,
                        sizeToContent: true,
                        showTotal: true,
                    },
                    {
                        heading: 'Buy Price',
                        field: rowData => rowData.ubp,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        dataRef: 'ubp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Trade Price',
                        field: rowData => rowData.usp,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        dataRef: 'usp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Margin',
                        field: rowData => `${rowData.usp !== "0.00" ? ((((rowData.usp - rowData.ubp) / rowData.usp) * 100).toFixed(2)) : "0.00"}%`,
                        alignment: 'right',
                        dataRef: 'usp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Bundle / Kit',
                        field: rowData => rowData.bd,
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        dataRef: 'bd',
                        important: true,
                        sizeToContent: true
                    },
                ],
                dataRef: true,
                // defaultSort: customerId ? 'qty' : undefined,
                // defaultSortOrder: customerId ? 'ASC' : undefined,
                excel: true,
                inline,
                elevation: 0,
                key: 'i',
                name: 'products-sales-history',
                noResultsText: 'Sorry, no sales to display for this product',
                pagination: true,
                plainPagination: customerId,
                pageTitleData: [],
                pdf: true,
                persistenceId: null,
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showCustomer: !customerId,
                showDate: false,
                showDateRange: true,
                showFullTotals: !!inline,
                showSearch: false,
                showSupplier: false,
                showTotals: !!inline,
                title: 'Products Sales History Report'
            }}
        />
    )
}

export default ProductHistory;