import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import { CLOUDFRONT_URL } from 'Constants';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const StaffChip = ({staff, label, missingText, size, style, suffix}) => {
    if(staff?.name || staff?.staff_first_name) {
        return (    
            <Chip 
                avatar={<Avatar alt={staff.name ?? `${staff.staff_first_name} ${staff.staff_last_name}`} src={`${CLOUDFRONT_URL}${staff.picture ?? staff.staff_profile_photo}`} />}
                label={`${label ? label : ''}${staff.name ?? `${staff.staff_first_name} ${staff.staff_last_name}`}${suffix ? suffix : ''}`}
                size={size ?? 'small'}
                variant="outlined"
                style={{border: 0, fontWeight: 400, ...style}}
            />
        )
    } else {
        return (
            <Chip 
                avatar={<Avatar alt={`RED`}><FAIcon icon={missingText === 'E-commerce' ? 'globe' : 'check'} size={10} type='solid' color='white' noMargin /></Avatar>}
                label={missingText ?? `System`}
                size={size ?? 'small'}
                variant="outlined"
                style={{border: 0, fontWeight: 400, ...style}}
            />
        )
    }
}

export default StaffChip;