import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
// import AuthorisePayment from 'Components/Accounts/Misc/AuthorisePayment/AuthorisePayment';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DataTable from 'Components/Common/DataTables/DataTable';
import PaddedError from 'Components/Common/ErrorHandling/PaddedError';
import ViewPayment from 'Components/Accounts/Misc/ViewPayment/ViewPayment';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';

const initialState = {
    dialog: {
        authorise: false,
        decline: false,
        payment: false,
    },
    authorise: {},
    decline: {},
    payment: 0
}

class SearchTransactionsTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    putPaymentStatus = (id, formData, errorCallback) => {
        API.put(`/accounts/transactions/${id}`, formData)
        .then(res => {
            if(res.data) {
                if(res.data.errors) {
                    if(errorCallback)
                        errorCallback(res.data.errors);
                } else {
                    this.props.deploySnackBar("success", `The transaction was successfully marked as ${formData.status}`);
                    this.handleDialogClose();
                    if(this.props.getSearchData)
                        this.props.getSearchData();
                }
            }
        })
    }

    handleDialogOpen = (type, data) => {
        this.setState({
            [type]: data,
            dialog: {
                ...initialState.dialog,
                [type]: true
            }
        })
    }

    handleDialogClose = () => {
        this.setState({
            dialog: initialState.dialog,
            authorise: initialState.authorise,
            decline: initialState.decline,
            payment: initialState.payment
        })
    }

    render() {
        let { inline, noPaper, noExport, noPagination, rows, showRefundsOnly } = this.props;
        const { dialog, authorise, decline, payment } = this.state;
        let columns = [
            {
                heading: 'Transaction Amount',
                field: rowData => rowData.opp_txn_amount,
                fieldFormat: 'currency',
                dataRef: 'opp_txn_amount',
                important: true,
                showTotal: this.props.orderReference ? true : false
            },
            {
                heading: 'Transaction Method',
                field: rowData => rowData.opp_txn_method === "Cash" ? "Cash On Collection" : rowData.opp_txn_method,
                dataRef: 'opp_txn_method',
            },
            {
                heading: 'Processed By',
                field: rowData => (this.props.staff && <StaffChip staff={this.props.staff[rowData.opp_staff_id]} />) || '-',
                dataRef: 'opp_staff_id',
                fieldFormat: 'staff',
            },
            {
                heading: 'Processed On',
                field: rowData => rowData.opp_created_datetime,
                dataRef: 'opp_created_datetime',
                fieldFormat: 'datetime',
                sizeToContent: true,                                
            },
            {
                heading: 'Status',
                field: rowData => <StatusChip status={rowData.opp_txn_status} />,
                dataRef: 'opp_txn_status',
                sizeToContent: true,                                
            },
            // {
            //     heading: 'Refunded',
            //     field: rowData => rowData.opp_txn_refund_amount !== "0.00" ? true : false,
            //     dataRef: 'opp_txn_refund_amount',
            //     fieldFormat: 'boolean',
            //     sizeToContent: true,                                
            //     alignment: 'center'
            // }
        ];

        if(this.props.orderReference) {
            columns.unshift({
                heading: showRefundsOnly ? 'Processed' : 'Confirmed',
                field: rowData => rowData.opp_txn_status === "Authorised" ? true : false,
                fieldFormat: 'boolean',
                alignment: 'center',
                sizeToContent: true,
                showTotalLabel: this.props.orderReference ? true : false,
            });
            columns.push({
                actions: rowData => {
                    return [
                        {name: 'View Transaction', icon: 'binoculars', onClick: () => this.handleDialogOpen("payment", rowData.opp_id), doubleClick: true}
                    ]
                }
            });
        } else {
            columns.unshift(
                {
                    heading: 'Order Reference',
                    field: rowData => rowData.order && rowData.order,
                    dataRef: 'order',
                    sizeToContent: true
                }
            );
            if(this.props.paymentStatus === 'Pending') {
                columns.push({
                    actions: rowData => {
                        return [
                            {name: 'View Order', icon: 'order', onClick: () => this.props.history.push(`/sales/${rowData.opp_order_id}`)},
                            {name: 'View Transaction', icon: 'binoculars', onClick: () => this.handleDialogOpen("payment", rowData.opp_id), doubleClick: true},
                            {name: rowData.opp_txn_type === 'Payment' ? `Mark as cleared` : `Mark as refunded`, disabled: rowData.opp_txn_method === 'Credit Account' && true, icon: 'check', onClick: () => this.handleDialogOpen("authorise", this.props.rows[(rowData.rowNumber - 1)])},
                            {name: 'Mark as declined', disabled: rowData.opp_txn_method === 'Credit Account', icon: 'times', onClick: () => this.handleDialogOpen("decline", this.props.rows[(rowData.rowNumber - 1)]), hideIfDisabled: true}
                        ]
                    }
                });
            } else {
                columns.unshift(
                    {
                        heading: showRefundsOnly ? 'Processed' : 'Confirmed',
                        field: rowData => rowData.opp_txn_status === "Authorised" ? true : false,
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        sizeToContent: true
                    }
                );
                columns.push({
                    actions: rowData => {
                        return [
                            {name: 'View Order', icon: 'order', onClick: () => this.props.history.push(`/sales/${rowData.opp_order_id}`)},
                            {name: 'View Transaction', icon: 'binoculars', onClick: () => this.handleDialogOpen("payment", rowData.opp_id), doubleClick: true}
                        ]
                    }
                });
            }
        }

        const Element = noPaper ? Box : Paper;
        const ErrorElement = noPaper? Box : PaddedError;
        return (
            <>
                {(inline && _.isEmpty(rows) && (
                    <ErrorElement inline={inline}>No payments have been made</ErrorElement>
                )) || (
                    <Element elevation={inline && 0}>
                        <DataTable  
                            config={{
                                key: 'opp_id',
                                pagination: noPagination ? false : true,
                                alternatingRowColours: !inline && true,
                                isLoading: this.props.dataLoading,
                                // responsiveImportance: true,
                                noRowHover: inline && true,
                                inline: inline && true,
                                plainPagination: true,
                                rowsPerPage: inline ? 5 : (this.props.orderReference ? 50 : 10),
                                showFullTotals: this.props.orderReference ? true : false,
                                options: {
                                    dataRef: !noExport && true,
                                    export: {
                                        title: this.props.orderReference ? `Payments - Sales Order #${this.props.orderReference}` : 'Payments',
                                        name: this.props.orderReference ? `Payment-History-SOP-${this.props.orderReference}` : 'Payments',
                                        excel: !noExport && true,
                                        pdf: !noExport && true,
                                        print: !noExport && true
                                    }
                                }
                            }}
                            columns={columns}
                            rows={rows}
                        />
                    </Element>
                )}
                {this.props.paymentStatus === 'Pending' && (
                    <>
                        {dialog.authorise && (
                             <ConfirmationDialog 
                                open={true} 
                                close={this.handleDialogClose} 
                                message={`Are you sure you want to mark this ${authorise.opp_txn_type.toLowerCase()} as ${authorise.opp_txn_type === 'Payment' ? `cleared` : `processed`}?`}
                                success={() => this.putPaymentStatus(authorise.opp_id, { status: 'Authorised' })}  
                            />                        
                        )}
                        {dialog.decline && (
                            <ConfirmationDialog 
                                open={true} 
                                success={() => this.putPaymentStatus(decline.opp_id, { status: 'Declined' })} 
                                close={this.handleDialogClose} 
                                message={
                                    <>
                                        Are you sure you want to mark this payment as declined?
                                        <br /><br />
                                        <Typography variant="body2">
                                            <b>Txn Method:</b> {decline.opp_txn_method}<br />
                                            <b>Txn Amount:</b> £{decline.opp_txn_amount}
                                        </Typography>
                                    </>}
                            />        
                        )}
                    </>
                )}
                <ViewPayment open={dialog.payment} close={this.handleDialogClose} id={payment} />
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        staff: state.notifications.status
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchTransactionsTable));