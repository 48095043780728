import React from 'react';
import _ from 'lodash';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { currencyFormat } from 'Functions/MiscFunctions';

const useStyles = makeStyles({
    noBorder: {
        border: 0,
    },
    heading: {
        minWidth: 150,
        width: '1%',
        whiteSpace: 'nowrap',
        border: 0,
        paddingLeft: 24,
        paddingRight: 36
    },
    sizeToContent: {
        width: '1%'
    },
    value: {
        border: 0,
    }
});

const StackTable = ({elevation, heading, icon, noBg, padding, rows, sizeToContent, vertical}) => {
    const classes = useStyles();
    return (
        <Paper 
            elevation={elevation ?? undefined}
            style={{paddingBottom: padding ?? 16, background: noBg ? 'transparent' : undefined}}
        >
            <Table>
                <TableBody>
                    {(!_.isEmpty(heading) && (
                        <TableRow>
                            <TableCell className={classes.noBorder} style={{padding: padding ?? "24px 24px 4px 24px"}} colSpan="2">
                                <Grid container spacing={1} alignItems='center'>
                                    {icon && (
                                        <Grid item>
                                            <FAIcon icon={icon} type="light" size={25} noMargin fixedHeight />
                                        </Grid>
                                    )}
                                    <Grid item xs>
                                        <Typography variant="h6">
                                        {heading}
                                        </Typography>    
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ))}
                    {(!_.isEmpty(rows) && (
                        _.map(rows, (row, idx) => {
                            if(row.divider) {
                                return (
                                    <TableRow>
                                        <TableCell colSpan={2} className={`${classes.noBorder} pl-0 pr-0`}>
                                            <Divider />
                                        </TableCell>
                                    </TableRow>
                                )
                            } else if((row.value || row.value === 0) && !row.disabled) {
                                return (
                                    (vertical && (
                                        <React.Fragment key={idx}>
                                            <TableRow>
                                                <TableCell className={classes.heading} variant="head" style={{verticalAlign: 'top', paddingLeft: padding ?? undefined}}>
                                                    <Typography variant="caption" color="textSecondary">
                                                        {row.heading}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={`${classes.value} ${row.className ? row.className : ''} ${sizeToContent ? classes.sizeToContent : ''}`} style={{padding: '0 0 12px 0', color: typeof(row.color) !== undefined ? row.color : 'inherit'}}>
                                                    {(row.boolean && (
                                                        <FAIcon type="light" icon={row.value ? 'check' : 'times'} color={row.value ? 'success' : 'error'} noMargin />
                                                    )) || (row.currency && (
                                                        currencyFormat.format(row.value)
                                                    )) || (
                                                        row.value
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )) || (
                                        <TableRow key={idx}>
                                            <TableCell className={classes.heading} variant="head" style={{verticalAlign: 'top', paddingLeft: padding ?? undefined}}>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.heading}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={`${classes.value} ${row.className ? row.className : ''} ${sizeToContent ? classes.sizeToContent : ''}`} style={{padding: row.padding === 'none' ? '0 12px 0 12px' : 12, color: typeof(row.color) !== undefined ? row.color : 'inherit'}}>
                                                {(row.boolean && (
                                                    <FAIcon type="light" icon={row.value ? 'check' : 'times'} color={row.value ? 'success' : 'error'} noMargin />
                                                )) || (row.currency && (
                                                    currencyFormat.format(row.value)
                                                )) || (
                                                    row.value
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )
                            }
                        })
                    )) || (
                        <TableRow>
                            <TableCell colSpan={2} className={classes.value}>
                                No {heading.toLowerCase()} to show
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>
    )
}

export default StackTable;