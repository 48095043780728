import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Text from '@material-ui/core/Typography';

import QrReader from 'Components/Common/Qr/QrReader';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import AppButton from '../Buttons/AppButton';
import FAIcon from '../Icons/FontAwesome/FAIcon';

class QrReaderDialog extends React.Component {
    constructor(props) {
        super(props);
        this.scanning = false;
        this.scanData = '';
    }
    
    componentDidMount = () => {
        window.addEventListener('keydown', this.handlePdaScan);
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.handlePdaScan);
    }

    handleClose = () => this.props.closeDialog()

    handlePdaScan = e => {

        if(e.keyCode === 0) {
            return;
        }

        const {
            handleScan
        } = this.props;

        if(e.keyCode === 192) {
            if(this.scanning) {
                this.scanning = false;
                handleScan(this.scanData);
                this.scanData = '';
            } else {
                this.scanning = true;
            }
        } else {
            if(this.scanning) {
                this.scanData += e.key;
            }
        }
    }
    
    render = () => {
        const {app, className, delay, facingMode, handleScan, isPDA, showViewFinder, text, width} = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Text variant="body2">
                        {text}
                    </Text>
                </Grid>
                <Grid item xs={12} align='center'>
                    {(isPDA && (
                        <FAIcon icon="qrcode" size={150} noMargin />
                    )) || (
                        <QrReader
                            className={className}
                            delay={delay ?? 250}
                            facingMode={facingMode ?? 'environment'}
                            handleScan={handleScan}
                            showViewFinder={showViewFinder ?? false}
                            style={{width: width ?? '100%' }}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    {(app && (
                        <AppButton
                            className="btn btn-error"
                            back
                            onClick={this.handleClose}
                            text="Cancel"
                            icon="times"
                        />
                    )) || (
                        <Button
                            onClick={this.handleClose}
                            variant="contained"
                            color="primary"
                        >
                            <FAIcon icon='times' buttonPrimary />
                            Cancel
                        </Button>
                    )} 
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog())
    };
}

export default connect(null, mapDispatchToProps)(QrReaderDialog);