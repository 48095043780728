import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { DraggablePaper } from 'Functions/MiscFunctions';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const WildixCallDtmf = ({
    call,
    callData,
    getCallStatusClass,
    handleClose,
    handleSubmit
}) => { 
    const data = callData?.find?.((c) => c.ch === call.getChannel())?.dt ?? null;
    const values = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'
    ];
    const alphabetical = {
        2: ['a', 'b', 'c'],
        3: ['d', 'e', 'f'],
        4: ['g', 'h', 'i'],
        5: ['j', 'k', 'l'],
        6: ['m', 'n', 'o'],
        7: ['p', 'q', 'r', 's'],
        8: ['t', 'u', 'v'],
        9: ['w', 'x', 'y', 'z']
    }
    return (
        <Dialog
            disableEscapeKeyDown
            disableBackdropClick
            fullWidth
            maxWidth="xs"
            onClose={handleClose}
            open={true}
            PaperComponent={DraggablePaper}
        >
            <DialogTitle
                style={{ cursor: "move" }}
                id="draggable-control"
            >
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">Keypad</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose}>
                                <FAIcon
                                    icon="times"
                                    button
                                    noMargin
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <List dense className="pt-1 pb-1 pl-2 pr-1 content-light-white">
                    <ListItem disableGutters style={{ paddingRight: 90 }}>
                        {!call.isIncoming() ? (
                            <ListItemAvatar>
                                <FAIcon icon={`phone-arrow-up-right`} type="light" />
                            </ListItemAvatar>
                        ) : null}
                        <ListItemText
                            primary={
                                <>
                                    {data?.fn && (
                                        <Typography className={`fw-400`} variant="body1">
                                            {data.fn} {data.ln}
                                        </Typography>
                                    )}
                                    <Typography className={`fw-400`} variant="body1">
                                        {data?.cn ? data.cn : call.getCalleeName()}
                                        {data?.as ? ` (${data.as})` : ``}
                                    </Typography>
                                </>
                            }
                            secondary={
                                <Typography variant="body1" color="textSecondary">
                                    {call.getCalleeNumber()}
                                </Typography>
                            }
                        />
                        <ListItemSecondaryAction style={{ right: 8, width: 100 }}>
                            <Typography align="right" variant="body1" className={getCallStatusClass(call)}>
                                {call.getFormattedState()}
                            </Typography>
                            <Typography align="right" variant="body1">
                                {call.getFormattedDuration()}
                            </Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <Box my={2.5}>
                    <Grid container spacing={3} className="mt-1 mb-2">
                        {_.map(values, (value, index) => (
                            <Grid item xs={4} key={index} align="center">
                                <IconButton
                                    className="keypad"
                                    onClick={() => handleSubmit(call?.getChannel?.(), value)}
                                    >
                                    <Box width={50} height={50} display="flex" alignItems="center" justifyContent="center">
                                        <Typography component="div" className="keypad-value mb-0 pb-0">{value}
                                            {alphabetical[value] && (
                                                <Typography component="div" style={{fontSize: 8, textTransform: 'uppercase'}}>{alphabetical[value].join('')}</Typography>
                                            )}
                                        </Typography>
                                    </Box>
                                </IconButton>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default WildixCallDtmf;