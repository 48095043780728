import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import DataTable from 'Components/Common/DataTables/DataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import StaffAccessItems from 'Components/Staff/StaffAccess/StaffAccessItems';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deployDialog, closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { Hidden } from '@material-ui/core';

const StaffAccessUpdate = ({closeDialog, deployConfirmation, handleSubmit, leaders, members, name}) => {

    const [rows, setRows] = useState([]);
    const [staff, setStaff] = useState({});

    const submit = () => {
        handleSubmit(staff);
        closeDialog();
    }

    const handleAccessChange = (staffId, newAccess) => {

        let newStaff = {
            ...staff,
            [staffId]: newAccess
        }

        setStaff(newStaff)

    }

    useEffect(() => {

        let rows = []
        let newStaff = {}

        if(name === "Management" || name === "Crushed Ice Support") {
            _.each(leaders, ldr => {
                rows.push(ldr);
                newStaff = {
                    ...newStaff,
                    [ldr.staff_id]: 1
                }
            })
        }

        _.each(members, mbr => {
            rows.push(mbr);
            newStaff = {
                ...newStaff,
                [mbr.staff_id]: 1
            }
        })

        setRows(rows)
        setStaff(newStaff)

    }, [leaders, members, name]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} className="mt-3">
                <Alert severity="warning">
                    Selected staff will have their access updated to the team access
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <DataTable  
                    config={{
                        key: 'staff_id',
                        alternatingRowColours: true,
                        responsiveImportance: true,
                        rowsPerPage: 100,
                        inline: true
                    }}
                    columns={[
                        {
                            heading: 'Staff Member',
                            field: rowData => `${rowData.staff_first_name} ${rowData.staff_last_name}`,
                            important: true,
                        },
                        {
                            heading: 'Update Access?',
                            field: rowData => {
                                const newAccess = staff[rowData.staff_id] === 1
                                return (
                                    <Checkbox color="primary" checked={newAccess} onChange={() => handleAccessChange(rowData.staff_id, newAccess ? 0 : 1)} />
                                )
                            },
                            sizeToContent: true,
                            alignment: 'right',
                        }
                    ]}
                    rows={rows}
                />
            </Grid>
            <Grid item xs={12} align="right">
                <Button
                    className="mr-2"
                    onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to cancel?</Typography><Typography variant="body2">Your changes to team access will not be saved!</Typography></>, closeDialog)}
                    variant="text"
                >
                    <FAIcon icon="times" button />
                    Cancel
                </Button>
                <Button
                    color="primary" 
                    onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to confirm these changes?</Typography><Typography variant="body2">Team / selected staff access will be immediately updated</Typography></>, submit)}
                    variant="contained"
                >
                    <FAIcon icon="check" buttonPrimary />
                    Confirm
                </Button>
            </Grid>
        </Grid>
    )
}

function objectsHaveSameKeys(...objects) {
    const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
    const union = new Set(allKeys);
    return objects.every(object => union.size === Object.keys(object).length);
}

const styles = theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#ef3340',
        color: '#fff'
    },
    flex: {
        flex: 1,
    }
});

const initialState = {
    allAccess: {},
    teamAccess: {},
    parentId: 0,
    currentTeamAccess: {},
    confirmationOpen: false,
    snackbarOpen: false,
    viewPage: {
        dialogOpen: false,
        url: null
    },
    allAccessLoading: true,
    currentAccessLoading: true,
    savingAccess: false
}

class TeamAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        window.onpopstate = this.handleBackButton;
    }
    componentDidMount = () => {
        this.loadAllAccess();
        this.loadCurrentTeamAccess();
    }
    handleBackButton = () => {
        if(this.state.viewPage.dialogOpen) {
            // GET IT TO STOP PAGE CHANGE IF DIALOG OPEN
            this.props.history.go(1);
        }
    }
    loadAllAccess = () => {
        this.setState({
            allAccessLoading: true
        }, () => {
            API.get('/access/all').then(result => {
                this.setState({
                    allAccess: result.data,
                    allAccessLoading: false
                })
            })
        })
    }
    loadCurrentTeamAccess = () => {
        this.setState({
            currentAccessLoading: true
        }, () => {
            API.get(`/teams/${this.props.id}/access`, {
                params: {
                    noStructure: true
                }
            }).then(result => {
                _.map(result.data, access => {
                    this.setState({
                        currentTeamAccess: {
                            ...this.state.currentTeamAccess,
                            [access.access_id]: true
                        },
                        teamAccess: {
                            ...this.state.teamAccess,
                            [access.access_id]: true
                        }
                    })
                })
                this.setState({
                    currentAccessLoading: false
                });
            })
        })
    }
    checkAllToggle = event => {
        event.stopPropagation();

        let accessId = parseInt(event.target.name);
        let accessItem = this.getRecursiveAccessItem(accessId, this.state.allAccess)
        let allAccessItems = this.getRecursiveAccessChildren(accessItem);
        let newAccess = this.state.teamAccess;
        
        if(event.target.checked) {
            if(!this.state.teamAccess.hasOwnProperty(accessId)) {
                newAccess = {
                    ...newAccess,
                    [accessId]: true
                }
            }
            allAccessItems.forEach(accessItem => {
                if(!this.state.teamAccess.hasOwnProperty(accessItem.access_id)) {
                    newAccess = {
                        ...newAccess,
                        [accessItem.access_id]: true
                    }
                }
            });
            let parent =  _.find(this.state.allAccess, {'children': [{'access_id': parseInt(accessId)}] });
            // If a child element has been ticked then automatically check the parent
            if(parent && parent.access_id > 0){
                newAccess = {
                    ...newAccess,
                    [parent.access_id]: true
                }
            }
        } else {
            allAccessItems.forEach(accessItem => {
                if(newAccess.hasOwnProperty(accessId)) {
                    newAccess = _.omit(newAccess, accessId);
                }
                if(newAccess.hasOwnProperty(accessItem.access_id)) {
                    newAccess = _.omit(newAccess, accessItem.access_id);
                }
            });
        }
        this.setState({
            teamAccess: newAccess
        });
    }
    getRecursiveAccessItem = (accessId, allAccess) => {
        var result;
        allAccess.some(o => (o.access_id === accessId && (result = o)) || (result = this.getRecursiveAccessItem(accessId, o.children || [])));
        return result;
    }
    getRecursiveAccessChildren = (accessItem) => {
        let allChildren = [];
        _.map(accessItem.children, (child) => {
            allChildren.push(child);
            if(child.children) {
                let childrensChildren = this.getRecursiveAccessChildren(child);
                allChildren = [ ...allChildren, ...childrensChildren ]
            }
        });
        return allChildren;
    }
    isAllChecked = (accessId) => {
        let accessItem = _.find(this.state.allAccess, (accessItem) => {
            return accessItem.access_id === accessId
        });
        if(!this.state.teamAccess.hasOwnProperty(accessId)) {
            return false;
        }
        let allAccessItems = this.getRecursiveAccessChildren(accessItem);
        allAccessItems.forEach(_accessItem => {
            if(!this.state.teamAccess.hasOwnProperty(_accessItem.access_id)) {
                return false;
            }
        });
        return true;
    }
    toggleAccess = (event) => {
        let accessId = event.target.name;
        if(this.state.teamAccess.hasOwnProperty(accessId)) {
            this.setState({
                teamAccess: _.omit(this.state.teamAccess, accessId)
            })
        } else {
            let parent =  _.find(this.state.allAccess, {'children': [{'access_id': parseInt(accessId)}] });
            // If a child element has been ticked then automatically check the parent
            if(parent && parent.access_id > 0){
                this.setState({
                    teamAccess: {
                        ...this.state.teamAccess,
                        [accessId]: true,
                        [parent.access_id]: true,
                    }
                });
            }
            else {
                this.setState({
                    teamAccess: {
                        ...this.state.teamAccess,
                        [accessId]: true
                    }
                });
            }
        }
    };
    showPageExample = (url, title) => {
        this.setState({
            viewPage: {
                title: title,
                url: url,
                dialogOpen: true
            }
        })
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.updateAccess();
    }

    handleViewPageDialogClose = () => {
        this.setState({ 
            viewPage: {
                ...this.state.viewPage,
                dialogOpen: false
            }
        });
    }

    handleSetParentId = parentId => {
        this.setState({
            parentId
        }, this.props.scrollToTop)
    }

    updateAccess = () => {
        const { closeDialog, deployDialog, deployConfirmation, leaders, members, name } = this.props;
        deployDialog(
            <StaffAccessUpdate 
                closeDialog={closeDialog} 
                deployConfirmation={deployConfirmation} 
                handleSubmit={this.handleSubmit} 
                leaders={leaders} 
                members={members} 
                name={name} 
            />
        , false, "Update Staff Access", "error", "sm");
    }

    handleSubmit = updateStaff => {
        this.setState({
            savingAccess: true
        }, () => {
            let data = Object.keys(this.state.teamAccess);
            API.put(`/teams/${this.props.id}/access`, 
                {
                    data,
                    updateStaff
                }
            )
            .then(result => {
                if(result.data.errors) {
                } else {
                    this.setState({
                        savingAccess: false,
                        currentTeamAccess: {
                            ...this.state.teamAccess
                        },
                        snackbarOpen: true
                    });
                }
                this.props.scrollToTop();
            });
        });
    }

    render() {
        const { classes } = this.props;
        const { teamAccess, allAccess, parentId, snackbarOpen, viewPage, allAccessLoading, currentAccessLoading, savingAccess } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    {((allAccessLoading || currentAccessLoading || savingAccess) && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                            {savingAccess && (
                                 <Grid item xs={12} align='center'>
                                    <Typography variant="h5" gutterBottom>
                                        Updating Team Access
                                    </Typography>
                                    <Typography variant="body2">
                                        Please do not leave this page or close RED
                                    </Typography>
                             </Grid>
                            )}
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12} lg={7}>
                                <StaffAccessItems   
                                    parentId={parentId}
                                    toggleAccess={this.toggleAccess}
                                    checkAllToggle={this.checkAllToggle}
                                    staffAccess={teamAccess}
                                    accessItems={allAccess}
                                    isAllChecked={this.isAllChecked}
                                    showPageExample={this.showPageExample}
                                    dataLoading={((allAccessLoading || currentAccessLoading) && true) || false} 
                                />
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Update team access?" 
                                    message="Are you sure you want to update the default access for this team?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message="The team's default access access was successfully updated"
                                />
                            </Grid>
                            <Hidden mdDown>
                                <Grid item xs={12} lg={5}>
                                    <div style={{position: 'fixed', width: '29.5%'}}>
                                        <PaddedPaper>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>                                            
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <Typography variant="h6">
                                                                RED Sections
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='right'>
                                                            <Button type="submit"
                                                                    variant="text"
                                                                    color="primary"
                                                                    onClick={this.handleConfirmationOpen}
                                                                    disabled={objectsHaveSameKeys(this.state.currentTeamAccess, this.state.teamAccess)}>
                                                                <FAIcon icon="check" type="light" size={15} button />
                                                                Update Team Access
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>                                        
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" color={(parentId === 0 && 'primary') || 'initial'} style={{cursor: 'pointer'}} onClick={() => this.handleSetParentId(0)}>
                                                                <FAIcon type="light" icon='binoculars' size="small" color={(parentId === 0 && 'primary') || 'initial'} />
                                                                View All
                                                            </Typography>
                                                        </Grid>
                                                        {_.map(_.filter(allAccess, function(el) { return el.access_parent_id === 0 }), (item, idx) => (
                                                            <Grid item xs={6} key={idx}>
                                                                <Grid container alignItems='center'>
                                                                    <Grid item>
                                                                        <FAIcon type="light" icon={item.access_icon} color={(parentId === item.access_id && 'primary') || 'initial'} size="small" />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Typography variant="body2" color={(parentId === item.access_id && 'primary') || 'initial'} style={{cursor: 'pointer'}} onClick={() => this.handleSetParentId(item.access_id)}>
                                                                            {item.access_name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Dialog disableEscapeKeyDown={true}
                                    disableBackdropClick={true}
                                    open={viewPage.dialogOpen}
                                    onClose={this.handleViewPageDialogClose}
                                    fullScreen>
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <Typography variant="h6" color="inherit" className={classes.flex}>
                                            Viewing Page
                                        </Typography>
                                        <IconButton color="inherit" onClick={this.handleViewPageDialogClose} aria-label="Close">
                                            <FAIcon type="light" icon="times" buttonPrimary noMargin />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                {(viewPage.url && (
                                    <iframe src={viewPage.url} style={{height:'100%', border:'none', pointerEvents:'none'}} title="Preview Page" />
                                )) || (
                                    <DialogContent style={{marginTop:16}}>
                                        No page to show
                                    </DialogContent>
                                )}
                            </Dialog>
                        </>
                    )}
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md') => dispatch(deployDialog(content, disableDialogContent, title, variant, size)),
    }
)

export default connect(null, mapDispatchToProps)(withStyles(styles)(TeamAccess));