import React from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import logo from "Assets/Images/no-img.jpg";
import BooleanChip from "Components/Common/Chips/BooleanChip";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import InsightChip from "Components/Common/Chips/InsightChip";
import Product from "Components/Purchases/PurchaseOrderForm/Product";
import ProductStockChip from "Components/Common/Chips/ProductStockChip";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import ViewProduct from "Components/Products/ViewProduct/ViewProduct";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { CLOUDFRONT_URL } from "Constants";
import Highlight from "Components/Common/Highlight/Highlight";

const Products = ({ classes, deliveries, disableInvoicedOrDelivered, formData, formErrors, handleProductDrag, handleProductEdit, handleProductRemove, ifm, inline, noDraggable, pod, rows, products, readOnly, showDelivered, showAllocationStatus, status, vatPercent }) => {
    const dispatch = useDispatch();

    const dispCloseDialog = () => dispatch(closeDialog());

    const handleDeployNotes = (name, notes) => {
        dispatch(deployDialog(<ViewNotes hideDeleteButton notes={notes} inline />, false, `${name}`, "standard", "sm"));
    };

    const handleEditProductRow = (editProductRow, delivery = undefined) => {
        dispatch(deployDialog(<Product ifm={ifm} classes={classes} closeDialog={dispCloseDialog} deliveries={deliveries} formData={editProductRow} delivery={delivery} products={products} handleSubmit={handleProductEdit} vatPercent={vatPercent} inDialog />, false, "", "standard", "xl", false, true));
    };

    const handleProductInfoOpen = (productId) => {
        dispatch(deployDialog(<ViewProduct id={productId} inDialog />, false, "Product Details", "standard", "xl", true));
    };

    let cols = [
        {
            heading: "Product",
            field: (rowData) =>
                (formErrors && formErrors["productRows|" + rowData.rowNumber] && <FAIcon type="light" icon="exclamation-triangle" size="large" color="error" noMargin />) || (
                    <Avatar variant="square" alt={rowData.product && rowData.product.product_name} src={rowData.image ? `${CLOUDFRONT_URL}${rowData.image}` : logo} style={{ width: 75, height: 75 }} />
                ),
            important: true,
            hideInResponsiveDialog: true,
            sizeToContent: true,
        },
        {
            heading: "",
            field: (rowData) => {
                const notes = _.size(rowData.notes ?? rowData?.productData?.notes ?? rowData?.product?.notes),
                    rowStatus = rowData.status ?? rowData.productData?.product_status ?? rowData.product?.product_status ?? "Unknown",
                    replacement = rowData.productRep > 0 || rowData.productData?.product_rep_product_id > 0 || rowData.product?.product_rep_product_id > 0,
                    inStock = rowData.instock ?? rowData.productData?.stock_count ?? rowData.product?.stock_count ?? 0,
                    available = rowData.available ?? rowData.productData?.stock_available_count ?? rowData.product?.stock_available_count ?? 0,
                    allocated = rowData.allocated ?? rowData.productData?.stock_allocated_count ?? rowData.product?.stock_allocated_count ?? 0,
                    onOrder = rowData.onOrder ?? rowData.productData?.stock_on_order_count ?? rowData.product?.stock_on_order_count ?? 0,
                    preOrdered = rowData.preOrdered ?? rowData.productData?.stock_on_order_allocated_count ?? rowData.product?.stock_on_order_allocated_count ?? 0,
                    chkPr = rowData.chkPr || rowData.productData?.product_check_price === 1 || rowData.product?.product_check_price === 1,
                    stck = rowData.stkIt || rowData.productData?.product_stock_item === 1 || rowData.product?.product_stock_item === 1,
                    quantity = rowData.quantity,
                    stockStatus = rowData.stockDueIn ?? null;

                return (
                    <Grid container alignItems="flex-end" className="mt-1 mb-1">
                        <Grid item>
                            <Typography variant="body2" className="fw-400">
                                {rowData.productCode}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption" component="div">
                                {rowData.productName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption" component="div">
                                {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box pt={1}>
                                {(showAllocationStatus &&
                                    ((rowData.ls &&
                                        (rowData.ls === "Despatched" || rowData.ls === "Invoiced" || rowData.ls === "Cancelled") &&
                                        ((rowData.ls === "Cancelled" && (
                                            <Box mt={0.5} mr={1}>
                                                <BooleanChip success={false} label={"Cancelled"} />
                                            </Box>
                                        )) ||
                                            ((rowData.ls === "Invoiced" || pod?.pod_status === "Invoiced") && (
                                                <Box mt={0.5} mr={1}>
                                                    <BooleanChip success={true} label={"Invoiced"} />
                                                </Box>
                                            )) ||
                                            (rowData.ls === "Despatched" && (
                                                <Box mt={0.5} mr={1}>
                                                    <BooleanChip success={true} label={rowData.quantityInvoiced >= rowData.quantity ? "Invoiced" : rowData.quantityDelivered >= rowData.quantityDespatch ? "Delivered" : "Despatched"} />
                                                </Box>
                                            )))) || (
                                        <Box mr={1}>
                                            <BooleanChip warning={rowData.con === 1} label={rowData.con === 1 ? "Awaiting Supplier Despatch" : "Awaiting Supplier Confirmation"} />
                                        </Box>
                                    ))) || <ProductStockChip qty={quantity} rep={replacement} status={rowStatus} stocked={stck} stockAvailable={available} stockOnOrderAvailable={onOrder - preOrdered} />}
                            </Box>
                        </Grid>
                        {chkPr && (
                            <Grid item>
                                <Box pt={1}>
                                    <InsightChip background="#FF5722" color="#fff" marginRight={8} paddingLeft={8} icon="exclamation-triangle" label="Check Price and Availability" />
                                </Box>
                            </Grid>
                        )}
                        {notes > 0 && (
                            <Grid item>
                                <Box pt={1}>
                                    <InsightChip background="#4CAF50" color="#fff" marginRight={8} paddingLeft={8} icon="comment-alt-lines" label={notes} onClick={() => handleDeployNotes(rowData.productCode, rowData.notes ?? rowData.productData?.notes ?? [])} size={11} tooltip="View Notes" />
                                </Box>
                            </Grid>
                        )}
                        {!_.isEmpty(stockStatus) && (
                            <>
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        <Typography variant="caption" className="fw-400">
                                            This line has been pre-ordered for:
                                        </Typography>
                                    </Box>
                                </Grid>
                                {_.map(stockStatus, (row, idx) => (
                                    <Grid item xs={12} key={idx}>
                                        <Link className="textDefault" to={`/sales/${row.order}:${row.od}`} target="_blank">
                                            <Box pt={1}>
                                                <Grid container alignItems="center">
                                                    <Grid item>
                                                        <Box mb={0.6}>
                                                            <FAIcon icon="circle" size={5} type="solid" />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="body2" className="fw-400">
                                                            Qty - {row.qty} - Sales Order #{row.ref}/{row.idx}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Link>
                                    </Grid>
                                ))}
                            </>
                        )}
                    </Grid>
                );
            },
            important: true,
        },
    ];

    if (showDelivered) {
        cols.push({
            heading: "Ordered",
            field: (rowData) => <Highlight variant={"success"}>{rowData.quantity}</Highlight>,
            important: true,
            alignment: "right",
            sizeToContent: true,
        });
    } else {
        cols.push({
            heading: "Quantity",
            field: (rowData) => rowData.quantity,
            important: true,
            alignment: "right",
            sizeToContent: true,
        });
    }

    if (_.some(rows ?? formData.productRows, (el) => el.quantity !== el.quantityDespatch)) {
        if (showDelivered) {
            cols.push({
                heading: "Despatch",
                field: (rowData) => <Highlight variant={"success"}>{rowData.quantityDespatch}</Highlight>,
                important: true,
                alignment: "right",
                sizeToContent: true,
            });
        } else {
            cols.push({
                heading: "Despatch",
                field: (rowData) => rowData.quantityDespatch,
                important: true,
                alignment: "right",
                sizeToContent: true,
            });
        }
    }

    if (showDelivered) {
        cols.push({
            heading: "Delivered",
            field: (rowData) => <Highlight variant={rowData.quantityDespatch === rowData.quantityDelivered ? "success" : "error"}>{rowData?.quantityDelivered ?? 0}</Highlight>,
            alignment: "center",
            sizeToContent: true,
        });
        cols.push({
            heading: "Invoiced",
            field: (rowData) => <Highlight variant={rowData.quantity === rowData.quantityInvoiced ? "success" : "error"}>{rowData?.quantityInvoiced ?? 0}</Highlight>,
            alignment: "center",
            sizeToContent: true,
        });
    }

    // if(showInvoiced) {
    //     cols.push(
    //         {
    //             heading: 'Invoiced',
    //             field: rowData => <Highlight variant={rowData.quantity === rowData.quantityInv ? 'success' : 'error'}>{rowData.quantityInv}</Highlight>,
    //             alignment: 'center',
    //             sizeToContent: true
    //         }
    //     );
    // }

    cols.push(
        {
            heading: "Unit Buy Price",
            field: (rowData) => rowData.cost,
            fieldFormat: "currency",
            sizeToContent: true,
            alignment: "right",
            cellProps: (rowData) => ({
                className: rowData.cost !== (rowData.productData?.product_cost ?? rowData.currentCost) ? "textError" : undefined,
            }),
            tooltip: (rowData) => (
                <span className="fw-400">
                    Current Unit Buy Price
                    <br />£{rowData.productData?.product_cost ?? rowData.currentCost}
                </span>
            ),
        },
        {
            heading: "Total Buy Price",
            field: (rowData) => rowData.totalCost,
            fieldFormat: "currency",
            sizeToContent: true,
            alignment: "right",
        }
    );

    if (!readOnly) {
        if (pod) {
            cols.push({
                actions: (rowData) => {
                    return [
                        { name: "Modify", icon: "pencil", disabled: disableInvoicedOrDelivered && (rowData.quantityInvoiced >= rowData.quantity || rowData.quantityDelivered >= rowData.quantity), color: formErrors && formErrors["productRows|" + rowData.rowIdx] && "error", onClick: () => handleEditProductRow(rowData, ifm ? pod : undefined) },
                        { name: "Remove ", icon: "trash-alt", disabled: disableInvoicedOrDelivered && (rowData.quantityInvoiced >= rowData.quantity || rowData.quantityDelivered >= rowData.quantity), onClick: (rowData) => handleProductRemove(rowData.rowIdx, pod) },
                    ];
                },
            });
        } else {
            cols.push({
                actions: (rowData) => {
                    return [
                        { name: "View Product", icon: "binoculars", onClick: () => handleProductInfoOpen(rowData.productId) },
                        { name: "Modify", icon: "pencil", color: formErrors && formErrors["productRows|" + rowData.rowIdx] && "error", onClick: () => handleEditProductRow(rowData, ifm ? pod : undefined) },
                        { name: "Remove ", icon: "trash-alt", onClick: (rowData) => handleProductRemove(rowData.rowIdx, pod) },
                    ];
                },
            });
        }
    } else if (!pod) {
        cols.push({
            actions: (rowData) => {
                return [{ name: "View Product", icon: "binoculars", onClick: () => handleProductInfoOpen(rowData.productId) }];
            },
        });
    }

    const Element = ifm ? "div" : Paper;

    return (
        <Element elevation={inline && 0}>
            <DataTable
                config={{
                    key: "",
                    alternatingRowColours: true,
                    responsiveImportance: true,
                    rowsPerPage: 100,
                    pagination: false,
                    noResultsText: "No products have added yet",
                    inline: ifm ? true : undefined,
                    noRowHover: readOnly,
                    draggable: !readOnly && !noDraggable ? true : undefined,
                    draggableHandle: !readOnly && !noDraggable ? true : undefined,
                    draggableRearrange: handleProductDrag ?? undefined,
                }}
                columns={cols}
                rows={rows ?? formData?.productRows ?? []}
            />
        </Element>
    );
};

export default Products;
