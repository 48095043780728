import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AppButton from 'Components/Common/Buttons/AppButton';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';

const NotificationDialog = ({btnIcon, btnText, onClick, text, title, variant}) => {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state?.ui?.device?.isMobile ?? false)
    
    const handleDismiss = () => {
        dispatch(closeDialog());
        onClick?.();
    }

    return (
        <Box pt={2}>
            {title && (
                <Typography variant="h6" paragraph>
                    {title}
                </Typography>
            )}
            <Typography variant={isMobile ? 'h6' : 'body1'} className={`fw-300 ${title ? `` : `pt-1`}`}>
                {text}
            </Typography>
            {(isMobile && (
                <AppButton
                    className={`btn ${variant === 'error' ? `btn-error` : `btn-success`} mt-3`}
                    icon={btnIcon ?? 'check'}
                    onClick={handleDismiss}
                    onTouch
                    text={btnText ?? 'Continue'}
                />
            )) || (
                <div className="buttonRow">
                    <Button
                        color="primary"
                        onClick={handleDismiss}
                        variant="text"
                    >
                        <FAIcon icon={btnIcon ?? 'check'} size={15} />
                        {btnText ?? 'Continue'}
                    </Button>
                </div>
            )}
        </Box>
    )
}

export default NotificationDialog;