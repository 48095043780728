import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = () => ({
    isLoading: true,
    job: null
})

class PrintJobDialog extends React.Component {
    constructor(props) {
        super(props);
        this.check = null;
        this.state = initialState();
    }

    componentDidMount = () => {
        
        const { 
            printJob,
        } = this.props;

        API.get(`/print/job/${printJob}`)
        .then(res => {

            if(res?.data) {

                let job = res.data,
                    isLoading = false;

                this.setState({
                    isLoading,
                    job
                }, () => {
                    if(job.st !== "Completed" && job.st !== "Error") {
                        this.check = setInterval(this.checkJobStatus, 500);
                    }
                })

            }
            
        })

    }

    checkJobStatus = () => {

        const { 
            printJob,
        } = this.props;

        API.get(`/print/job/${printJob}`)
        .then(res => {
            if(res?.data) {
                let job = res.data;
                this.setState({
                    job,
                }, () => {
                    if(job.st === "Completed" || job.st === "Error") {
                        if(this.check) {
                            clearInterval(this.check);
                        }
                    }
                })
            }
        })

    }

    componentWillUnmount = () => {
        if(this.check) {
            clearInterval(this.check);
        }
    }

    handleClose = () => {

        const { 
            closeDialog,
            onClose
        } = this.props;

        closeDialog();
        onClose();

    }


    handleReprint = () => {

        const { 
            deploySnackBar,
            printJob
        } = this.props;

        API.get(`/print/job/${printJob}/reprint`)
        .then(res => {
            if(res?.data) {
                let job = res.data;
                this.setState({
                    job,
                }, () => {
                    if(job.st !== "Completed" && job.st !== "Error") {
                        this.check = setInterval(this.checkJobStatus, 500);
                    }
                    deploySnackBar("success", `You have successfully requested a re-print`)
                })
            }
        })

    }

    handleGetIcon = () => {
        
        const {
            job
        } = this.state;

        let icon;

        switch(job.st) {
            case "Completed":
                icon = "check"
            break;
            case "Error":
                icon = "exclamation-triangle"
            break;
            case "Printing":
                icon = "exclamation-circle"
            break;
            case "Sent":
                icon = "arrow-right"
            break;
            default: 
                icon = "hourglass-half"
            break;
        }

        return icon;

    }

    handleGetColor = () => {
        
        const {
            job
        } = this.state;

        let color;

        switch(job.st) {
            case "Completed":
                color = "#4CAF50"
            break;
            case "Error":
                color = "#f44336"
            break;
            case "Printing":
                color = "#03A9F4"
            break;
            case "Sent":
                color = "#FF5722"
            break;
            default: 
                color = "#111"
            break;
        }

        return color;

    }

    handleGetStatus = () => {
        
        const {
            job
        } = this.state;

        let status;

        switch(job.st) {
            case "Completed":
                status = "Completed"
            break;
            case "Error":
                status = "Error"
            break;
            case "Printing":
                status = "Printing..."
            break;
            case "Sent":
                status = "Sent To Printer"
            break;
            default: 
                status = "Queued"
            break;
        }

        return status;

    }

    handleGetPercent = () => {
        
        const {
            job
        } = this.state;

        let percent;

        switch(job.st) {
            case "Completed":
                percent = 100
            break;
            case "Error":
                percent = 100
            break;
            case "Printing":
                percent = 66
            break;
            case "Sent":
                percent = 33
            break;
            default: 
                percent = 0
            break;
        }

        return percent;

    }

    handleGetTextVariant = heading => {
        
        const { 
            warehouseMode
        } = this.props;

        if(warehouseMode && heading) {
            return 'h6';
        } else if(!warehouseMode && heading) {
            return 'body1';
        } else if(warehouseMode && !heading) {
            return 'body2';
        } else if(!warehouseMode && !heading) {
            return 'body2';
        }
    }

    render() {

        const { 
            isLoading,
            job,
        } = this.state;

        const { 
            deployConfirmation,
            docTitle,
            docSubtitle,
        } = this.props;

        return (
            (isLoading && (
                <LoadingCircle /> 
            )) || (
                <>
                    <Box className="content-light-grey" p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="pb-0">
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <Typography className="fw-400" variant="h6">
                                            Print Job #{job.job_id}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Close">
                                            <IconButton
                                                onClick={() => deployConfirmation(`Are you sure you want to contine?`, this.handleClose)}
                                            >
                                                <FAIcon icon="times" size={20} button noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="pt-0">
                                <Box display="flex" alignItems="center" pt={1}>
                                    <Box width="100%" mr={1}>
                                        <LinearProgress variant="determinate" value={this.handleGetPercent()} />
                                    </Box>
                                    <Box minWidth={35}>
                                        <Typography variant="body2" color="textSecondary" className="fw-400">{`${this.handleGetPercent()}%`}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="content-light-white" p={2.5}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center'>
                                                <Grid item>
                                                    <FAIcon type="thin" icon={this.handleGetIcon()} style={{color: this.handleGetColor()}} size={40} noMargin />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography className="fw-400" variant={this.handleGetTextVariant(true)} style={{color: this.handleGetColor()}}>
                                                        {this.handleGetStatus()}
                                                    </Typography>
                                                    <Typography variant={this.handleGetTextVariant(false)} className="fw-300">
                                                        {(job.st === 'Completed' && (
                                                            'Document printed'   
                                                        )) || (job.st === 'Error' && (
                                                            `Check printer for errors`
                                                        )) || (job.st === 'Sent' && (
                                                            `Please wait...`
                                                        )) || (job.st === 'Printing' && (
                                                            `Please wait...`
                                                        )) || (
                                                            `${job.q} documents waiting`
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3} alignItems='center'>
                                                <Grid item>
                                                    <FAIcon type="thin" icon="print" size={40} noMargin />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography className="fw-400" variant={this.handleGetTextVariant(true)}>
                                                        {job.printer.printer_name}
                                                    </Typography>
                                                    <Typography variant={this.handleGetTextVariant(false)} className="fw-300">
                                                        {job.printer.printer_location}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3} alignItems='center'>
                                                <Grid item>
                                                    <FAIcon type="thin" icon="file-alt" size={40} noMargin />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography className="fw-400" variant={this.handleGetTextVariant(true)}>
                                                        {docTitle}
                                                    </Typography>
                                                    <Typography variant={this.handleGetTextVariant(false)} className="fw-300">
                                                        {docSubtitle}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {job.st !== "Queued" && (
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            {job.st !== "Queued" && (
                                                <AppButton
                                                    className="btn btn-error"
                                                    onClick={() => deployConfirmation(`Are you sure you want to re-print this document?`, this.handleReprint)}
                                                    text="Re-print"
                                                    icon="redo"
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {job.st === "Completed" && (
                                                <AppButton
                                                    className="btn btn-success"
                                                    onClick={this.handleClose}
                                                    text="Continue"
                                                    icon="check"
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </>
            )
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
    };
}

export default connect(null, mapDispatchToProps)(PrintJobDialog);