import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleFileChange } from 'Functions/FormFunctions';
import { CLOUDFRONT_URL } from 'Constants';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Alert from 'Components/Common/Alert/Alert';
import { Box } from '@material-ui/core';

const initialState = () => ({
    currentProfilePhoto: null,
    formData: {},
    formErrors: {},
    confirmationOpen: false,
})

class UploadSupplierLogoMissingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleFileChange = handleFileChange.bind(this);
    }

    componentDidMount(){
        this.setState({
            currentProfilePhoto: this.props.current ? `${CLOUDFRONT_URL}${this.props.current}` : null
        });
    }

    handleSubmit = () => {
        let formData = new FormData();
        formData.append('photo', this.state.formData.photo);

        API.post('/suppliers/' + this.props.supplierId + '/logoMissing', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(result => {

            if(result.data.errors) {

                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });

            } else {

                this.setState({
                    ...initialState(),
                    currentProfilePhoto: `${CLOUDFRONT_URL}${result.data.key}`
                }, () => {
                    if(result.data.success) {
                        this.props.deploySnackBar("success", "The supplier's default missing image has been changed")
                        this.props.toggleDialog()
                    }
                })
                   
            }

        })
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    render() {
        const { formErrors, formData, currentProfilePhoto } = this.state;
        return (
            <Box mt={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} align='center'>
                        <Avatar variant="square" alt="Current Logo" src={(formData.photo && URL.createObjectURL(formData.photo)) || currentProfilePhoto} style={{height: 150, width: 150, margin: 0}} />
                    </Grid>
                    <Grid item xs={12}>
                        <DragFileInput
                            id="photoInput"
                            name="photo"
                            label="Choose Logo (.png, .jpg, .jpeg, .bmp, .gif) *"
                            file={formData.photo}
                            errorText={formErrors && formErrors['generic']}
                            onChange={this.handleFileChange}
                            emptyText='No photo selected'
                        />
                        <Box my={1}>
                            <Alert severity="error">This image will be visible on the website</Alert>
                        </Box>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Upload new default image?" 
                    message="Are you sure you want to upload a new default missing image for this supplier?"
                />
                <DialogActions className='pr-0 pb-0'>
                    <Button 
                        onClick={() => this.props.toggleDialog()} 
                        variant="text"
                    >
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                    <Button 
                        color="primary"
                        disabled={!(formData.photo && formData.photo.name)}
                        onClick={this.handleConfirmationOpen} 
                        variant="text" 
                    >
                        <FAIcon icon="check" size={15} button />
                        Change Image
                    </Button>
                </DialogActions>
            </Box>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    };
}

export default connect(null, mapDispatchToProps)(UploadSupplierLogoMissingForm);