import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { Box } from '@material-ui/core';
import _ from 'lodash';

const initialState = () => ({
  request: {}, 
});

class PrintableCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState();
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    const dataStringBase64 = this.props.match.params.data;
    const dataString = atob(dataStringBase64);
    const data = JSON.parse(dataString);
    this.setState(
      {
        request: data,
      },
      this.print
    );
  };

  print = () => {
    window.print();
    setTimeout(function () {
      window.location.replace('/');
      window.close();
    }, 500);
  };

  printFormFields = () => {
    const { request } = this.state;
    const fields = [];

    const addTitle = (title) => {
        fields.push(
            <TableRow key={title}>
                <TableCell style={{ border: 0, paddingLeft: 0, width: '1%', whiteSpace: 'nowrap' }} colSpan={2}><Typography variant="h6">{title}</Typography></TableCell>
            </TableRow>
        );
    }

    const addField = (label, value) => {
      fields.push(
        <TableRow key={label}>
          <TableCell style={{ border: 0, paddingLeft: 0, width: '1%', whiteSpace: 'nowrap', fontWeight: 500 }}>{label}</TableCell>
          <TableCell style={{ border: 0 }}>{value}</TableCell>
        </TableRow>
      );
    };

    if(request.trr_type === 'ONLINE_ACCOUNT') {
        
      // Add user details fields
      addTitle('User Details')
      addField('First Name', request.data.firstName || '-');
      addField('Surname', request.data.lastName || '-');
      addField('Position', request.data.position || '-');
      addField('Phone Number', request.data.phone || '-');
      addField('Email Address', request.data.email || '-');

      // Add company details fields
      addTitle('Company Details')
      addField('Company Name', request.data.companyName || '-');
      addField('Account Number', request.data.accountNumber || '-');
      addField('Account Postcode', request.data.postCode || '-');

      // Add portal administrator fields
      addTitle('Portal Administrator Details')
      addField('Use My Details?', request.data.useMyDetails ? 'Yes' : 'No');
      addField('Admin Email Address', request.data.useMyDetails ? request.data.email : request.data.adminEmail);
      addField('Admin First Name', request.data.useMyDetails ? request.data.firstName : request.data.adminFirstName);
      addField('Admin Surname', request.data.useMyDetails ? request.data.lastName : request.data.adminLastName);
      addField('Admin Position', request.data.useMyDetails ? request.data.position : request.data.adminPosition);

    } else if(request.trr_type === 'CREDIT_ACCOUNT') {

      const isLimitedCompany = request.data.type === 'Limited Company';

      const diffRegisteredAddress = request.data.ltdRegOffice.postcode === '' ? false : 
                                          request.data.address1 !== request.data.ltdRegOffice.address1 ||
                                          request.data.address2 !== request.data.ltdRegOffice.address2 || 
                                          request.data.address3 !== request.data.ltdRegOffice.address3 ||
                                          request.data.town !== request.data.ltdRegOffice.town || 
                                          request.data.county !== request.data.ltdRegOffice.county || 
                                          request.data.postcode !== request.data.ltdRegOffice.postcode || 
                                          request.data.country !== request.data.ltdRegOffice.country;
  
      const registeredAddress =   _.filter(diffRegisteredAddress ? [
                                      request.data.ltdRegOffice.address1,
                                      request.data.ltdRegOffice.address2,
                                      request.data.ltdRegOffice.address3,
                                      request.data.ltdRegOffice.town,
                                      request.data.ltdRegOffice.county,
                                      request.data.ltdRegOffice.postcode,
                                      request.data.ltdRegOffice.country
                                  ] : [
                                      request.data.address1,
                                      request.data.address2,
                                      request.data.address3,
                                      request.data.town,
                                      request.data.county,
                                      request.data.postcode,
                                      request.data.country
                                  ]).join(', ');

      // Add user details fields
      addTitle('User Details');
      _.forEach([
          { label: 'First Name', value: request.data.firstName || '-' },
          { label: 'Surname', value: request.data.lastName || '-' },
          { label: 'Position', value: request.data.position || '-' },
          { label: 'Email Address', value: request.data.email || '-' },
          { label: 'Phone Number', value: request.data.phone || '-'},
      ], (field) => addField(field.label, field.value, field.inputProps));

      // Add company details fields
      addTitle('Company Details');
      _.forEach([
          { label: 'Company Name', value: request.data.companyName || '-' },
          { label: 'Trading Name', value: request.data.tradingName || '-' },
          { label: 'Main Phone Number', value: request.data.companyPhone || '-' },
          { label: 'Main E-mail Address', value: request.data.companyEmail || '-' },
          { label: 'Company Legal Status', value: request.data.type || '-' },
          { label: 'VAT Number', value: request.data.vatNumber || '-' },
          { label: 'Address Line 1', value: request.data.address1 || '-' },
          { label: 'Address Line 2', value: request.data.address2 || '-' },
          { label: 'Address Line 3', value: request.data.address3 || '-' },
          { label: 'Town', value: request.data.town || '-' },
          { label: 'County', value: request.data.county || '-' },
          { label: 'Postcode', value: request.data.postcode || '-' },
          { label: 'Country', value: request.data.country || '-' },
          { label: 'Website', value: request.data.website || '-' },
          // { label: 'Type of Business', value: request.data.ctype || '-' },
          { label: 'How many engineers does your company have?', value: request.data.engineerCount || '-' },
      ], (field) => addField(field.label, field.value));

      // Add accounts details fields
      addTitle('Accounts Details');
      _.forEach([
          { label: 'Accounts E-mail Address', value: request.data.accountsEmail || '-' },
          { label: 'Accounts Phone Number', value: request.data.accountsPhone || '-' },
      ], (field) => addField(field.label, field.value));

      // Add limited company details fields if applicable
      if (isLimitedCompany) {
          addTitle('Limited Company Details');
          addField('Company Registration Number', request.data.ltdRegNumber || '-');
          addField('Registered Address', registeredAddress);
      }

      // Add credit facility fields
      addTitle('Credit Facility');
      addField('Requested Credit Limit', request.data.creditLimit ? `£${request.data.creditLimit}` : 'Not Specified');

      // Add directors/proprietors fields
      addTitle('Directors / Proprietors');
      _.forEach(request.data.directors, (director, index) => {
          addField(`Director #${index + 1}: First Name`, director.firstName || '-');
          addField(`Director #${index + 1}: Surname`, director.lastName || '-');
          addField(`Director #${index + 1}: Position`, director.position || '-');
          addField(`Director #${index + 1}: Email Address`, director.email || '-');
          addField(`Director #${index + 1}: Phone Number`, director.phone || '-');
          addField(`Director #${index + 1}: Subscribe to weekly emails?`, director.newsletter ? 'Yes' : 'No');
          addField(`Director #${index + 1}: Previous Relationship?`, director.prevRelationship || '-');
          if (director.prevRelationship === 'Yes') {
              addField(`Director #${index + 1}: Previous Relationship Details`, director.prevRelationshipDetails || '-');
          }
      });

      // Add accounts contacts fields
      addTitle('Accounts Contacts');
      _.forEach(request.data.accountsContacts, (contact, index) => {
          addField(`Contact #${index + 1}: First Name`, contact.firstName || '-');
          addField(`Contact #${index + 1}: Surname`, contact.lastName || '-');
          addField(`Contact #${index + 1}: Position`, contact.position || '-');
          addField(`Contact #${index + 1}: Email Address`, contact.email || '-');
          addField(`Contact #${index + 1}: Phone Number`, contact.phone || '-');
          addField(`Contact #${index + 1}: Subscribe to weekly emails?`, contact.newsletter ? 'Yes' : 'No');
      });

      // Add additional contact information fields
      addTitle('Additional Contact Information');
      _.forEach(request.data.contacts, (contact, index) => {
          addField(`Contact #${index + 1}: First Name`, contact.firstName || '-');
          addField(`Contact #${index + 1}: Surname`, contact.lastName || '-');
          addField(`Contact #${index + 1}: Position`, contact.position || '-');
          addField(`Contact #${index + 1}: Email Address`, contact.email || '-');
          addField(`Contact #${index + 1}: Phone Number`, contact.phone || '-');
          addField(`Contact #${index + 1}: Subscribe to weekly emails?`, contact.newsletter ? 'Yes' : 'No');
      });

      // Add business connections fields
      addTitle('Business Connections');
      addField('Associated with any other companies?', request.data.hasConnections || '-');
      if (request.data.hasConnections === 'Yes') {
          _.forEach(request.data.connections, (company, index) => {
              addField(`Associated Company #${index + 1}: Company Name`, company.company || '-');
              addField(`Associated Company #${index + 1}: Previous trading relationship?`, company.traded || '-');
          });
      }

      // Add trade references fields
      addTitle('Trade References');
      _.forEach(request.data.references, (reference, index) => {
          addField(`Trade Reference #${index + 1}: Company Name`, reference.company || '-');
          addField(`Trade Reference #${index + 1}: Period Of Trading`, reference.periodOfTrading || '-');
          addField(`Trade Reference #${index + 1}: Address`, reference.address || '-');
      });

    } else if(request.trr_type === 'TRADE_ACCOUNT') {

      const isLimitedCompany = request.data.type === 'Limited Company';

      const diffRegisteredAddress = request.data.ltdRegOffice.postcode === '' ? false : 
                                          request.data.address1 !== request.data.ltdRegOffice.address1 ||
                                          request.data.address2 !== request.data.ltdRegOffice.address2 || 
                                          request.data.address3 !== request.data.ltdRegOffice.address3 ||
                                          request.data.town !== request.data.ltdRegOffice.town || 
                                          request.data.county !== request.data.ltdRegOffice.county || 
                                          request.data.postcode !== request.data.ltdRegOffice.postcode || 
                                          request.data.country !== request.data.ltdRegOffice.country;
  
      const registeredAddress =   _.filter(diffRegisteredAddress ? [
                                      request.data.ltdRegOffice.address1,
                                      request.data.ltdRegOffice.address2,
                                      request.data.ltdRegOffice.address3,
                                      request.data.ltdRegOffice.town,
                                      request.data.ltdRegOffice.county,
                                      request.data.ltdRegOffice.postcode,
                                      request.data.ltdRegOffice.country
                                  ] : [
                                      request.data.address1,
                                      request.data.address2,
                                      request.data.address3,
                                      request.data.town,
                                      request.data.county,
                                      request.data.postcode,
                                      request.data.country
                                  ]).join(', ');

      // Add user details fields
      addTitle('User Details');
      _.forEach([
          { label: 'First Name', value: request.data.firstName || '-' },
          { label: 'Surname', value: request.data.lastName || '-' },
          { label: 'Position', value: request.data.position || '-' },
          { label: 'Email Address', value: request.data.email || '-' },
          { label: 'Phone Number', value: request.data.phone || '-'},
      ], (field) => addField(field.label, field.value, field.inputProps));

      // Add company details fields
      addTitle('Company Details');
      _.forEach([
          { label: 'Company Name', value: request.data.companyName || '-' },
          { label: 'Trading Name', value: request.data.tradingName || '-' },
          { label: 'Main Phone Number', value: request.data.companyPhone || '-' },
          { label: 'Main E-mail Address', value: request.data.companyEmail || '-' },
          { label: 'Company Legal Status', value: request.data.type || '-' },
          { label: 'VAT Number', value: request.data.vatNumber || '-' },
          { label: 'Address Line 1', value: request.data.address1 || '-' },
          { label: 'Address Line 2', value: request.data.address2 || '-' },
          { label: 'Address Line 3', value: request.data.address3 || '-' },
          { label: 'Town', value: request.data.town || '-' },
          { label: 'County', value: request.data.county || '-' },
          { label: 'Postcode', value: request.data.postcode || '-' },
          { label: 'Country', value: request.data.country || '-' },
          { label: 'Website', value: request.data.website || '-' },
          { label: 'Type of Business', value: request.data.ctype || '-' },
          { label: 'How many engineers does your company have?', value: request.data.engineerCount || '-' },
      ], (field) => addField(field.label, field.value));

      // Add accounts details fields
      addTitle('Accounts Details');
      _.forEach([
          { label: 'Accounts E-mail Address', value: request.data.accountsEmail || '-' },
          { label: 'Accounts Phone Number', value: request.data.accountsPhone || '-' },
      ], (field) => addField(field.label, field.value));

      // Add limited company details fields if applicable
      if (isLimitedCompany) {
          addTitle('Limited Company Details');
          addField('Company Registration Number', request.data.ltdRegNumber || '-');
          addField('Registered Address', registeredAddress);
      }

      // Add credit facility fields
      addTitle('Credit Facility');
      addField('Credit Account', request.data.creditFacility);
      addField('Requested Credit Limit', request.data.creditFacility === 'No' ? 'N/A' : request.data.creditLimit ? `£${request.data.creditLimit}` : 'Not Specified');

      // Add directors/proprietors fields
      addTitle('Directors / Proprietors');
      _.forEach(request.data.directors, (director, index) => {
          addField(`Director #${index + 1}: First Name`, director.firstName || '-');
          addField(`Director #${index + 1}: Surname`, director.lastName || '-');
          addField(`Director #${index + 1}: Position`, director.position || '-');
          addField(`Director #${index + 1}: Email Address`, director.email || '-');
          addField(`Director #${index + 1}: Phone Number`, director.phone || '-');
          addField(`Director #${index + 1}: Subscribe to weekly emails?`, director.newsletter ? 'Yes' : 'No');
          addField(`Director #${index + 1}: Previous Relationship?`, director.prevRelationship || '-');
          if (director.prevRelationship === 'Yes') {
              addField(`Director #${index + 1}: Previous Relationship Details`, director.prevRelationshipDetails || '-');
          }
      });

      // Add accounts contacts fields
      addTitle('Accounts Contacts');
      _.forEach(request.data.accountsContacts, (contact, index) => {
          addField(`Contact #${index + 1}: First Name`, contact.firstName || '-');
          addField(`Contact #${index + 1}: Surname`, contact.lastName || '-');
          addField(`Contact #${index + 1}: Position`, contact.position || '-');
          addField(`Contact #${index + 1}: Email Address`, contact.email || '-');
          addField(`Contact #${index + 1}: Phone Number`, contact.phone || '-');
          addField(`Contact #${index + 1}: Subscribe to weekly emails?`, contact.newsletter ? 'Yes' : 'No');
      });

      // Add additional contact information fields
      addTitle('Additional Contact Information');
      _.forEach(request.data.contacts, (contact, index) => {
          addField(`Contact #${index + 1}: First Name`, contact.firstName || '-');
          addField(`Contact #${index + 1}: Surname`, contact.lastName || '-');
          addField(`Contact #${index + 1}: Position`, contact.position || '-');
          addField(`Contact #${index + 1}: Email Address`, contact.email || '-');
          addField(`Contact #${index + 1}: Phone Number`, contact.phone || '-');
          addField(`Contact #${index + 1}: Subscribe to weekly emails?`, contact.newsletter ? 'Yes' : 'No');
      });

      // Add business connections fields
      addTitle('Business Connections');
      addField('Associated with any other companies?', request.data.hasConnections || '-');
      if (request.data.hasConnections === 'Yes') {
          _.forEach(request.data.connections, (company, index) => {
              addField(`Associated Company #${index + 1}: Company Name`, company.company || '-');
              addField(`Associated Company #${index + 1}: Previous trading relationship?`, company.traded || '-');
          });
      }

      // Add trade references fields
      addTitle('Trade References');
      _.forEach(request.data.references, (reference, index) => {
          addField(`Trade Reference #${index + 1}: Company Name`, reference.company || '-');
          addField(`Trade Reference #${index + 1}: Period Of Trading`, reference.periodOfTrading || '-');
          addField(`Trade Reference #${index + 1}: Address`, reference.address || '-');
      });

      // Add portal administrator fields
      addTitle('Portal Administrator Details')
        addField('Apply for Portal?', request.data.applyForTradeZone ? 'Yes' : 'No');
        if(request.data.applyForTradeZone) {
        addField('Use My Details?', request.data.useMyDetails ? 'Yes' : 'No');
        addField('Admin Email Address', request.data.useMyDetails ? request.data.email : request.data.adminEmail);
        addField('Admin First Name', request.data.useMyDetails ? request.data.firstName : request.data.adminFirstName);
        addField('Admin Surname', request.data.useMyDetails ? request.data.lastName : request.data.adminLastName);
        addField('Admin Position', request.data.useMyDetails ? request.data.position : request.data.adminPosition);
      }
    }
    
    return fields;
  };

  render() {
    const { request } = this.state;
    return (
      <>
      {/* {JSON.stringify(request)} */}
        <style type="text/css">
            @page &#123;
                size: portrait;
            &#125;
            @media print &#123;
                body &#123; 
                    margin: 1.3cm; 
                &#125;
            &#125;
        </style>
        <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item xs={12}>
                <Typography variant="h5">
                    <strong>
                        {request.title}
                    </strong>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Table size="small">
                    <TableBody>
                        {request?.title ? this.printFormFields() : null}
                    </TableBody>
                </Table>
            </Grid>
            {request.trr_type !== 'ONLINE_ACCOUNT' ? (
              <Grid item xs={12}>
                 <Typography variant="caption" component="div">
                    <em>Submitted from {request.trr_ip_address} at {moment(request.trr_created).format("HH:mm [on] DD/MM/YYYY")}</em>
                </Typography>
                <Typography variant="caption" component="div">
                    <em>Form printed by {request.staffMember} at {moment().format("HH:mm [on] DD/MM/YYYY")}</em>
                </Typography>
              </Grid>
            ) : null}
        </Grid>
        {request.trr_type === 'ONLINE_ACCOUNT' ? (
          <Box position='absolute' bottom={38}>
              <Typography variant="caption" component="div">
                  <em>Submitted from {request.trr_ip_address} at {moment(request.trr_created).format("HH:mm [on] DD/MM/YYYY")}</em>
              </Typography>
              <Typography variant="caption" component="div">
                  <em>Form printed by {request.staffMember} at {moment().format("HH:mm [on] DD/MM/YYYY")}</em>
              </Typography>
          </Box>
        ) : null}
      </>
    );
  }
}

export default withRouter(PrintableCardForm);