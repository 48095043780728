import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionChip from 'Components/Common/Chips/ActionChip';
import ActivityLog from 'Components/Common/Activity/ActivityLog';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import API from 'API';
import ColorAvatar from 'Components/Common/Avatars/Color';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedError from 'Components/Common/ErrorHandling/PaddedError';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import StaffCards from 'Components/Common/Staff/StaffCards';
import StaffSelector from 'Components/Common/Staff/StaffSelector';
import TeamAccess from 'Components/Teams/TeamAccess/TeamAccess';
import UpdateTeam from 'Components/Teams/UpdateTeam/UpdateTeam';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { deployDialog, closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { handleTabChange, TabPanel, toggleDialog } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';
import { Hidden } from '@material-ui/core';

const initialState = {
    access: {
        addNote: false,
        updateAccess: false,
        updateMembers: false,
        updateLeaders: false,
        updateTeam: false,
    }, 
    currentTab: 0,
    dialog: {
        updateTeam: false,
    },
    team: {},
    teamId: 0,
    initialLoading: true,
    isLoading: true,
    teamOrdersGraph: {
        labels: [
            "Jan 2019",
            "Feb 2019",
            "Mar 2019",
            "Apr 2019",
            "May 2019",
            "Jun 2019",
            "Jul 2019",
            "Aug 2019",
            "Sep 2019",
            "Oct 2019",
            "Nov 2019",
            "Dec 2019"
        ],
        datasets: [
            {
                label: "Team Orders",
                type:'line',
                lineTension: 0.1,
                fill: false,
                borderColor: '#00E676',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#00E676',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#00E676',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: [
                    "247",
                    "250",
                    "243",
                    "245",
                    "247",
                    "255",
                    "255",
                    "258",
                    "261",
                    "266",
                    "263",
                    "265"
                ]
            },
            {
                label: "Team Quotes",
                type:'line',
                lineTension: 0.1,
                fill: false,
                borderColor: '#ef5350',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ef5350',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#ef5350',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: [
                    "259",
                    "260",
                    "252",
                    "255",
                    "262",
                    "267",
                    "270",
                    "271",
                    "269",
                    "276",
                    "275",
                    "279"
                ]
            }
        ],
    },
    teamSalesGraph: {
        labels: [
            "Jan 2019",
            "Feb 2019",
            "Mar 2019",
            "Apr 2019",
            "May 2019",
            "Jun 2019",
            "Jul 2019",
            "Aug 2019",
            "Sep 2019",
            "Oct 2019",
            "Nov 2019",
            "Dec 2019"
        ],
        datasets: [
            {
                label: "Team Sales",
                type:'line',
                lineTension: 0.1,
                fill: false,
                borderColor: '#00E676',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#00E676',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#00E676',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: [
                    "115256.04",
                    "112864.96",
                    "118345.80",
                    "99845.22",
                    "115934.74",
                    "116245.35",
                    "118645.75",
                    "118345.28",
                    "115988.39",
                    "119868.17",
                    "117786.40",
                    "120015.69"
                ]
            },
            {
                label: "Team Target",
                type:'line',
                lineTension: 0.1,
                fill: false,
                borderColor: '#ef5350',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ef5350',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#ef5350',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: [
                    "114256.04",
                    "111864.96",
                    "117345.80",
                    "110845.22",
                    "113934.74",
                    "115245.35",
                    "119645.75",
                    "117345.28",
                    "113988.39",
                    "118868.17",
                    "119786.40",
                    "119891.45"
                ]
            }
        ],
    },
    viewing: {
        list: [],
        update: false,
    }
}

class ViewTeam extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.toggleDialog = toggleDialog.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
        this.poll = false;
    }

    componentDidMount = () => {
        API.multiAccess([
            'update-team',
            'team-access',
            'team-members',
            'team-leaders',
            'view-team:add-note'
        ])
        .then(([
            updateTeam, 
            updateAccess, 
            updateMembers, 
            updateLeaders, 
            addNote
        ]) => {  
            let teamId = this.props.id ? this.props.id : this.props.match.params.id;
            this.setState({
                ...this.state,
                access: {
                    addNote,
                    updateTeam,
                    updateAccess,
                    updateMembers,
                    updateLeaders,
                },
                teamId
            }, () => {
                this.poll = setInterval(this.handlePoll, 5000);
                this.loadComponentData();
            })
        })
    }

    componentWillUnmount = () => {
        if(this.poll) {
            clearInterval(this.poll);
        }
    }

    handlePoll = () => {

        if(!document.hidden) {

            API.post(`/staff/my/view`, 
                { 
                    team: this.state.teamId,
                    type: 'View'
                }, 
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true
                    }
                }
            )
            .then(res => {
                const { viewing } = this.state;
                if(res?.data) {
                    const list = res.data;
                    if(JSON.stringify(list) !==  JSON.stringify(viewing.list)) {
                        const update = _.some(list, el => el.u === 1);
                        this.setState({
                            viewing: {
                                list,
                                update
                            }
                        }, () => {
                            if(viewing.update && !update) {
                                const view = _.find(viewing.list, el => el.u === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished updating this team`);
                                }
                                this.loadComponentData();
                            }
                        })
                    }
                }
            })

        }
    }

    loadComponentData = () => {
        API.get('/teams/' + this.state.teamId)
        .then(result => {  
            if(result.data.errors) {
                this.props.history.push('/teams')
            } else {
                let team = result.data,
                    isLoading = false,
                    initialLoading = false;

                this.setState({
                    team,
                    initialLoading,
                    isLoading,
                }, () => this.props.pageTitle([1, 'Teams', team.team_name]));
            }
        })
    }

    handleAdd = type => formData => {
        this.setState({
            isLoading: true
        }, () => {
            const { team } = this.state;
            API.post(`/teams/${team.team_id}/${type}`, { id: formData.selected, access: formData.applyAccess })
            .then(res => {
                if(res && res.data) {
                    this.props.closeDialog?.();
                    this.loadComponentData()
                }
            })
        })
    }

    handleRemove = (type, id) => {
        this.setState({
            isLoading: true
        }, () => {
            const { team } = this.state;
            API.put(`/teams/${team.team_id}/${type}`, { id: id })
            .then(res => {
                if(res && res.data) {
                    this.loadComponentData()
                }
            })
        })
    }

    handleSwitch = (type, id) => {
        this.setState({
            isLoading: true
        }, () => {
            const { team } = this.state;
            API.put(`/teams/${team.team_id}/switch`, { id: id, current: type })
            .then(res => {
                if(res && res.data) {
                    this.loadComponentData()
                }
            })
        })
    }

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.teamId}
            relation="team"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
            standardOnly
        />, true, "", "standard","sm", false, true);

    handleDeployAddManager = () => {
        let options = this.getOptions();
        this.props.deployDialog(
            <StaffSelector 
                confirmation='Are you sure you want to add these team managers?'
                options={options.leaders}
                submit={this.handleAdd('leaders')}
                cancel={this.props.closeDialog}
            />
        , false, "Add Team Manager", "standard","xs");
    }

    handleDeployAddMember = () => {
        let options = this.getOptions();
        this.props.deployDialog(
            <StaffSelector 
                confirmation='Are you sure you want to add these team members?'
                options={options.members}
                submit={this.handleAdd('members')}
                cancel={this.props.closeDialog}
            />
        , false, "Add Team Member", "standard","xs");
    }

    getOptions = () => {

        const { staff } = this.props;
        const { access, team } = this.state;

        let options = {}
        if(access.updateMembers) {
            let members = _.map(_.filter(staff, el => el.act === 1), staff => (
                _.assign({
                    label: staff.name,
                    value: staff.id,
                    disabled: (team?.members && (_.findIndex(team.leaders, el => el.staff_id === staff.id) !== -1 || _.findIndex(team.members, el => el.staff_id === staff.id) !== -1) ? true : false) || false
                })
            ))
            
            options = {
                ...options,
                members
            }
        }

        if(access.updateLeaders) {
            let leaders = _.map(_.filter(staff, el => el.act === 1), staff => (
                _.assign({
                    label: staff.name,
                    value: staff.id,                    
                    disabled: (team?.leaders && (_.findIndex(team.leaders, el => el.staff_id === staff.id) !== -1 || _.findIndex(team.members, el => el.staff_id === staff.id) !== -1) ? true : false) || false
                })
            ))
            
            options = {
                ...options,
                leaders
            }
        }

        return options

    }

    render() {
        const { classes, deployConfirmation, scrollToTop, staff, ui} = this.props;
        const { access, currentTab, dialog, initialLoading, team, teamId, viewing } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(initialLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <ColorAvatar alt={team.team_name} color={team.team_color} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            {team.team_name.charAt(0).toUpperCase()}{team.team_name.slice(1)} Team
                                                        </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon type="light" icon="comment-alt-plus" size={17.5} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {/* {access.updateTeam && (
                                                <Grid item>
                                                    <Tooltip title="Modify Team" placement="top">
                                                        <IconButton disabled={viewing.update} onClick={() => this.toggleDialog('updateTeam')}>
                                                            <FAIcon type="light" icon="pencil" size={17.5} button noMargin disabled={viewing.update} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )} */}
                                             <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote,
                                                            divider: true
                                                        },
                                                        {
                                                            icon: "user-crown",
                                                            label: "Add Manager",
                                                            onClick: () => this.handleDeployAddManager(),
                                                            display: access.updateLeaders,
                                                        },
                                                        {
                                                            icon: "user",
                                                            label: "Add Member",
                                                            onClick: () => this.handleDeployAddMember(),
                                                            display: access.updateMembers,
                                                        },
                                                        // {
                                                        //     icon: "pencil",
                                                        //     label: "Modify Team",
                                                        //     onClick: () => this.toggleDialog('updateTeam'),
                                                        //     display: access.updateTeam,
                                                        //     disabled: viewing.update
                                                        // }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item xs={10} md={6}>
                                                    <Grid container spacing={2}>
                                                        {_.map(team.leaders, (manager, idx) => (
                                                            <Grid item className={idx === 0 ? 'pl-2' : undefined} key={idx}>
                                                                <InsightChip 
                                                                    icon="user-crown"
                                                                    label={`${manager.staff_first_name} ${manager.staff_last_name}`}
                                                                />
                                                            </Grid>
                                                        ))}
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="envelope"
                                                                label={`${team.team_email}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={6}>
                                                        <Grid container spacing={2} justify="flex-end">
                                                            <Grid item>
                                                                <ActionChip action={{u: 0, p: 0}} staff={staff[this.props.loggedInStaff.id]} page="team" />
                                                            </Grid>
                                                            {_.map(viewing.list, (view, idx) => {
                                                                if(staff[view.i]) {
                                                                    return (
                                                                        <Grid item key={idx}>
                                                                            <ActionChip action={view} staff={staff[view.i]} page="team" />
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Grid item xs={2} align='right'>
                                                        <ActionChip staff={{name: `${(_.size(viewing.list) + 1)} viewing`}} page="team" />
                                                    </Grid>
                                                </Hidden>
                                            </Grid> 
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={3}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Team Manager" />
                                                                    {(_.isEmpty(team.leaders) && (
                                                                        <PaddedError inline>No managers have been assigned to this team yet</PaddedError>
                                                                    )) || (
                                                                        <StaffCards
                                                                            elevation={0}
                                                                            lg={12}
                                                                            spacing={0}
                                                                            for={team.leaders}
                                                                            inlineContent={staff => (
                                                                                access.updateLeaders && (
                                                                                    <Grid item align='right'>
                                                                                        <Tooltip title="Remove Team Manager">
                                                                                            <IconButton
                                                                                                onClick={() => deployConfirmation(`Are you sure you want to remove ${staff.staff_first_name} ${staff.staff_last_name} as a manager?`, () => this.handleRemove('leaders', staff.staff_id))}
                                                                                            >
                                                                                                <FAIcon type="light" icon="times" noMargin button />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                )
                                                                            )}
                                                                        />
                                                                    )}
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Team Members" />
                                                                    {(_.isEmpty(team.members) && (
                                                                        <PaddedError inline>No members have been assigned to this team yet</PaddedError>
                                                                    )) || (
                                                                        <StaffCards
                                                                            elevation={0}
                                                                            lg={12}
                                                                            spacing={0}
                                                                            for={team.members}
                                                                            inlineContent={staff => (
                                                                                access.updateMembers && (
                                                                                    <>
                                                                                        {/* <Grid item align='right'>
                                                                                            <Tooltip title="Make Leader">
                                                                                                <IconButton
                                                                                                    onClick={() => this.handleSwitch('members', staff.staff_id)}
                                                                                                >
                                                                                                    <FAIcon type="light" icon="user-crown" noMargin button />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Grid> */}
                                                                                        <Grid item>
                                                                                            <Tooltip title="Remove Team Member">
                                                                                                <IconButton
                                                                                                    onClick={() => deployConfirmation(`Are you sure you want to remove ${staff.staff_first_name} ${staff.staff_last_name} as a member?`, () => this.handleRemove('members', staff.staff_id))}
                                                                                                >
                                                                                                    <FAIcon type="light" icon="times" noMargin button />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            )}
                                                                        />
                                                                    )}
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={9}>
                                                        <Grid container spacing={3}>
                                                            {/* <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Team Insight" />
                                                                    <Grid container spacing={3}>    
                                                                        <Grid item xs={12} lg={3}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="user-crown"
                                                                                title="Team Managers"
                                                                                content={team.leaders_count}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={3}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="users"
                                                                                title="Team Members"
                                                                                content={team.members_count}
                                                                            />
                                                                        </Grid>    
                                                                        <Grid item xs={12} lg={3}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="comment-alt-lines"
                                                                                title="Notes"
                                                                                content={_.size(team.notes)}
                                                                            />
                                                                        </Grid>      
                                                                        <Grid item xs={12} lg={3}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="chart-network"
                                                                                title="Last Activity"
                                                                                content={team.activity?.[0]?.d ? moment(team.activity?.[0]?.d).format("DD/MM/YY HH:mm") : '-'}
                                                                                onClick={() => this.handleTabChange(2)}
                                                                            />
                                                                        </Grid> 
                                                                    </Grid>    
                                                                </PaddedPaper>
                                                            </Grid> */}
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Team Activity" />
                                                                    <ActivityLog activity={team.activity}  />
                                                                </PaddedPaper>
                                                            </Grid>
                                                            {/* <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography variant="h6" paragraph>
                                                                        Team Sales vs Targets
                                                                    </Typography>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <Line 
                                                                                data={this.state.teamSalesGraph} 
                                                                                options={{
                                                                                    legend: {
                                                                                        display: false,
                                                                                    },
                                                                                    scales: {
                                                                                        xAxes: [{
                                                                                            gridLines: {
                                                                                                color: "rgba(0,0,0,.03)",
                                                                                            }
                                                                                        }],
                                                                                        yAxes: [{
                                                                                            gridLines: {
                                                                                                color: "rgba(0,0,0,.03)",
                                                                                            },
                                                                                            ticks: {
                                                                                                callback: (value, index, values) => {
                                                                                                    return value.toLocaleString("en-GB", {
                                                                                                        style: "currency",
                                                                                                        currency: "GBP"
                                                                                                    });
                                                                                                },
                                                                                                beginAtZero: true,
                                                                                                min: 0
                                                                                            }
                                                                                        }]
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            {access.updateAccess && (
                                                <TabPanel value={currentTab} index={1}>
                                                    <TeamAccess id={team.team_id} members={team.members} leaders={team.leaders} name={team.team_name} scrollToTop={scrollToTop} />
                                                </TabPanel>
                                            )}
                                            {/* <TabPanel value={currentTab} index={2}>
                                                <ActivityLog activity={team.activity} fullPage />
                                            </TabPanel> */}
                                            <TabPanel value={currentTab} index={3}>
                                                <ViewNotes notes={team.notes} />
                                            </TabPanel>  
                                        </Box>
                                    </Grid>
                                </Grid>
                                {access.updateTeam && (
                                    <SmallFormDialog
                                        open={dialog.updateTeam}
                                        onClose={() => this.toggleDialog('updateTeam')}
                                        title="Modify Team"
                                        content={<UpdateTeam teamId={teamId} toggleDialog={() => this.toggleDialog('updateTeam')} deploySnackBar={this.props.deploySnackBar} />}
                                        maxWidth='xs'
                                    />    
                                )}
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                    {access.updateAccess && (
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Team Access" />
                                    )}
                                    <Divider />
                                    {/* <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Timeline" /> */}
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="Notes" />
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        loggedInStaff: state.staffAuth.staff,
        ui: state.ui
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fs = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, de)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ViewTeam));