import React  from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { TabPanel, handleTabChange } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

import ShippingScheduleReport from 'Components/Reporting/Suppliers/ShippingScheduleReport/ShippingScheduleReport';
import PurchaseAccTurnover from 'Components/Reporting/Suppliers/PurchaseAccTurnover/PurchaseAccTurnover';
import PurchaseAccTurnoverByProduct from 'Components/Reporting/Suppliers/PurchaseAccTurnoverByProduct/PurchaseAccTurnoverByProduct';
import Supplier from 'Components/Reporting/Suppliers/Supplier/Supplier';
import SupplierSales from 'Components/Reporting/Suppliers/SupplierSales/SupplierSales';
import InvoicedSupplierSales from 'Components/Reporting/Suppliers/InvoicedSupplierSales/InvoicedSupplierSales';
import SupplierSalesByCustomer from 'Components/Reporting/Suppliers/SupplierSalesByCustomer/SupplierSalesByCustomer';
import PaxtonPOS from 'Components/Reporting/Suppliers/PaxtonPOS/PaxtonPOS';

const initialState = {
    access: {
        shippingSchedule: false,
        purchaseAccTurnover: false,
        purchaseAccTurnoverByProduct: false,
        supplierReport: false,
        supplierSalesReport: false,
        invSupplierSalesReport: false,
        paxtonPOSReport: false,
        supplierSalesByCustomerReport: false
    },
    currentTab: 0,
    isLoading: true,
    tabs: {
        0: "Shipping Schedule Report",
        1: "Purchase Account Turnover Analysis Report",
        2: "Supplier Report",
        3: "Supplier Sales Report",
        4: "Invoiced Supplier Sales Report",
        5: "Paxton POS Report",
        6: "Supplier Sales By Customer Report",
        7: "Purchase Account Turnover By Product Report"
    },
    tabText: {
        0: "Live shipping schedule for externally fulfilled sales orders",
        1: "Historic data on supplier financial year purchases",
        2: "Historic data on supplier purchases",
        3: "Historic data on supplier sales",
        4: "Historic data on invoiced supplier sales",
        5: "Historic point-of-sale data formatted to import into the Paxton Portal",
        6: "Historic data on supplier sales by product code and customer",
        7: "Historic data on supplier purchases by product code"
    },
}

class ReportingStock extends React.Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Reporting", "Suppliers"])
        this.loadComponentData()
    }

    loadComponentData = () => {
        API.multiAccess([
            'shipping-schedule-report',
            'pa-turnover-report',
            'supplier-report',
            'supplier-sales-report',
            'inv-supplier-sales-report',
            'pax-pos-report',
            'supplier-sales-customer-report',
            'patby-report'
        ])
        .then(([
            shippingSchedule, 
            purchaseAccTurnover, 
            supplierReport, 
            supplierSalesReport,
            invSupplierSalesReport,
            paxtonPOSReport,
            supplierSalesByCustomerReport,
            purchaseAccTurnoverByProduct
        ]) => {     
            this.setState({
                access: {
                    shippingSchedule,
                    purchaseAccTurnover,
                    supplierReport,
                    supplierSalesReport,
                    invSupplierSalesReport,
                    paxtonPOSReport,
                    supplierSalesByCustomerReport,
                    purchaseAccTurnoverByProduct
                },
                isLoading: false
            })
        })
    }

    render() {
        const { classes, ui } = this.props;
        const { access, currentTab, isLoading, tabs, tabText } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: 90}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabs[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        {access.shippingSchedule && (
                                            <TabPanel value={currentTab} index={0}>
                                                <ShippingScheduleReport />
                                            </TabPanel>
                                        )}
                                        {access.purchaseAccTurnover && (
                                            <TabPanel value={currentTab} index={1}>
                                                <PurchaseAccTurnover />
                                            </TabPanel>
                                        )}
                                        {access.supplierReport && (
                                            <TabPanel value={currentTab} index={2}>
                                                <Supplier />
                                            </TabPanel>
                                        )}
                                        {access.supplierSalesReport && (
                                            <TabPanel value={currentTab} index={3}>
                                                <SupplierSales />
                                            </TabPanel>
                                        )}
                                        {access.invSupplierSalesReport && (
                                            <TabPanel value={currentTab} index={4}>
                                                <InvoicedSupplierSales />
                                            </TabPanel>
                                        )}
                                        {access.supplierSalesByCustomerReport && (
                                            <TabPanel value={currentTab} index={6}>
                                                <SupplierSalesByCustomer />
                                            </TabPanel>
                                        )}
                                        {access.paxtonPOSReport && (
                                            <TabPanel value={currentTab} index={5}>
                                                <PaxtonPOS />
                                            </TabPanel>
                                        )}
                                        {access.purchaseAccTurnoverByProduct && (
                                            <TabPanel value={currentTab} index={7}>
                                                <PurchaseAccTurnoverByProduct />
                                            </TabPanel>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {access.shippingSchedule && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Shipping Schedule" />
                                )}
                                {access.purchaseAccTurnover && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Purchase Account Turnover" />
                                )}
                                {access.purchaseAccTurnoverByProduct && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={7} label="Purchase Account Turnover By Product" />
                                )}
                                {access.supplierReport && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Supplier" />
                                )}
                                {access.supplierSalesReport && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="Supplier Sales" />
                                )}
                                {access.invSupplierSalesReport && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} label="Invoiced Supplier Sales" />
                                )}
                                {access.supplierSalesByCustomerReport && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={6} label="Supplier Sales by Customer" />
                                )}
                                {access.paxtonPOSReport && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={5} label="Paxton POS" />
                                )}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps, null)(withStyles(ViewPage)(ReportingStock));