import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handleChange, handleCheckedChange } from 'Functions/FormFunctions';

const initialState = () => ({
    breatheHrEmployees: [],
    formData: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        breatheHrRef: '',
        role: '',
        team: '',
        phone: '01604 647555',
        mailingList: 1,
        notifications: 1
    },
    formErrors: {},
    confirmationOpen: false,
})

class AddStaffForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get('/staff/breatheHr')
        .then(result => {
            let breatheHrAssigned = [];
            let breatheHrEmployees = [];
            if(result.data){
                _.each(result.data.staff, (staff) => {
                    breatheHrAssigned.push(staff.staff_breathe_hr_ref)
                })
                breatheHrEmployees = _.map(result.data.employeeList, el => {
                    return _.assign({
                        value: el.ref,
                        label: el.name,
                        disabled: el.ref === 'None' ? false : (breatheHrAssigned.includes(el.ref) ? true : false)
                    });
                });
            }
            this.setState({
                breatheHrEmployees: breatheHrEmployees
            })            
        })
    }

    handleSubmit = () => {
        API.post('/staff', this.state.formData).then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                    snackbarOpen: true
                }, () => {
                    this.props.deploySnackBar("success", "The staff member was successfully created")
                    this.props.callback()
                    this.props.close()
                })
            }
        },
        err => API.handleError(err));
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    }

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    render() {
        const { teamList } = this.props;
        const { breatheHrEmployees, formData, formErrors } = this.state;
        return (
            <>
                <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
                    <TextField
                        name="firstName"
                        label="First Name *"
                        margin="none" 
                        autoComplete="off"
                        error={formErrors && formErrors['firstName'] && true}
                        helperText={formErrors && formErrors['firstName']}
                        value={formData.firstName || ''}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="lastName"
                        label="Last Name *"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['lastName'] && true}
                        helperText={formErrors && formErrors['lastName']}
                        value={formData.lastName || ''}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="email"
                        label="Email Address *"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['email'] && true}
                        helperText={formErrors && formErrors['email']}
                        value={formData.email || ''}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="role"
                        label="Job Role*"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['role'] && true}
                        helperText={formErrors && formErrors['role']}
                        value={formData.role || ''}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="phone"
                        label="Phone Number *"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['phone'] && true}
                        helperText={formErrors && formErrors['phone']}
                        value={formData.phone || ''}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="Password *"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['password'] && true}
                        helperText={formErrors && formErrors['password']}
                        value={formData.password || ''}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirm Password *"
                        margin="none"
                        autoComplete="off"
                        error={formErrors && formErrors['confirmPassword'] && true}
                        helperText={formErrors && formErrors['confirmPassword']}
                        value={formData.confirmPassword || ''}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    <AutoCompleteSelect
                        label="Team *"
                        options={teamList}
                        onChange={this.handleSelectChange('team')}
                        error={formErrors && formErrors['team'] && true}
                        errorText={formErrors && formErrors['team']}
                        value={formData.team}
                        required
                    />
                    <AutoCompleteSelect 
                        options={breatheHrEmployees}
                        label='Breathe HR Employee *'
                        onChange={this.handleSelectChange('breatheHrRef')}
                        error={formErrors && formErrors['breatheHrRef'] && true}
                        errorText={formErrors && formErrors['breatheHrRef']}
                        value={formData.breatheHrRef}
                    />
                    <FormControlLabel
                        control={
                            <Switch color="primary" checked={formData.mailingList} onChange={this.handleCheckedChange} name="mailingList" value={1} />
                        }
                        label={<Typography variant="body2" className="fw-400">Include on mailing list</Typography>}
                    />
                    <FormControlLabel
                        control={
                            <Switch color="primary" checked={formData.notifications} onChange={this.handleCheckedChange} name="notifications" value={1} />
                        }
                        label={<Typography variant="body2" className="fw-400">Enable Notifications</Typography>}
                    />
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Add a new staff member?" 
                        message="Are you sure you want to add this new staff member?"
                    />
                </form>
                <DialogActions className='pr-0 pb-0'>
                    <Button 
                        onClick={this.props.close} 
                        variant="text"
                    >
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                    <Button 
                        color="primary"
                        disabled={Object.values(formData).every(x => (x === null || x === ""))}
                        onClick={this.handleConfirmationOpen} 
                        variant="text" 
                    >
                        <FAIcon icon="check" size={15} button />
                        Add Staff
                    </Button>
                </DialogActions>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        teamList: state.notifications.teamList,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddStaffForm);