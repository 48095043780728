import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import Tile from 'Components/Common/Tiles/Tile';
import { momentFormatDate, momentFormatDateTime } from 'Functions/MiscFunctions';
import { CLOUDFRONT_URL } from 'Constants';

const DespatchDetails = ({closeDialog, od}) => { 
    const   despApi     = JSON.parse(od?.despatch?.desp_api_json),
            shipping    = od?.despatch?.desp_collection === 0;
    return (
        <Box className="content-light-grey">
            <DialogTitle disableTypography style={{paddingBottom: 0}}>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs>
                        <Typography variant="h6">
                            {shipping ? 'Despatch' : 'Collection'} Details
                        </Typography>
                        <Typography variant="body1" className="fw-400">
                            Sales Order Delivery #{od?.od_reference}/{od?.od_idx}
                        </Typography>
                    </Grid>
                    <Grid item align='right'>
                        <Tooltip title="Close">
                            <IconButton aria-label="Close" onClick={closeDialog}>
                                <FAIcon type="light" icon='times' button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Box p={3} pt={0}>
                <Box pt={3} pb={3}>
                    {(od?.despatch?.desp_label_qty === 0 && (
                        <Alert severity={'success'} elevation={0}>
                            This delivery has been consolidated and sent with another sales order
                        </Alert>
                    )) || (
                        <Alert severity={od?.despatch?.desp_successful === 1 ? (shipping ? 'success' : (od?.od_status === 'Despatched' || od?.od_status === 'Invoiced' ? 'success' : 'warning')) : 'error'} elevation={0}>
                            {od?.despatch?.desp_successful === 1 ? (shipping ? `This delivery was successfully despatched via ${od?.despatch?.courier?.courier_name} ${od?.despatch?.delivery?.courier_conf_service}` : (od?.od_status === 'Despatched' || od?.od_status === 'Invoiced' ? 'This delivery has been collected by the customer' : 'This delivery has not been collected yet')) : 'There was an issue despatching this delivery'}
                        </Alert>
                    )}
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box p={3} pt={3} pb={3} className="content-light-white">
                            <Grid container spacing={3}>
                                {shipping && (
                                <Grid item xs={12} lg={4}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'clock'}
                                        title="Completed"
                                        content={momentFormatDateTime(od?.despatch?.desp_completed_datetime, true)}
                                    />
                                </Grid>
                                )}
                                <Grid item xs={12} lg={4}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'calendar-alt'}
                                        title={od?.despatch?.desp_collection === 1 ? 'Ready for Collection' : 'Shipping Date'}
                                        content={momentFormatDate(od?.despatch?.desp_shipping_date)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <StaffTile
                                        noPaper
                                        title="Prepared By"
                                        staffId={od?.despatch?.desp_staff_id ?? null}
                                        missingText='N/A'
                                    />
                                </Grid>
                                {shipping && (
                                    <Grid item xs={12} lg={4}>
                                        <Tile
                                            noPaper
                                            color={'#000'}
                                            icon={'box-check'}
                                            title={'Product'}
                                            content={od?.despatch?.desp_product}
                                        />
                                    </Grid> 
                                )}
                                <Grid item xs={12} lg={4}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'tag'}
                                        title={'Number of Boxes'}
                                        content={od?.despatch?.desp_label_qty}
                                    />
                                </Grid>
                                {shipping && (
                                    <Grid item xs={12} lg={4}>
                                        <Tile
                                            noPaper
                                            color={!_.isEmpty(despApi) ? '#4CAF50' : '#f44336'}
                                            icon={!_.isEmpty(despApi) ? 'check' : 'times'}
                                            title={'Automated Despatch'}
                                            content={!_.isEmpty(despApi) ? `Yes` : `No`}
                                        />
                                    </Grid> 
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                    {od?.collection && (
                        <Grid item xs={12}>
                            <Box p={3} pt={3} pb={3} className="content-light-white">
                                <Typography variant="h6" paragraph>
                                    Customer Collection Details
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Alert severity={od?.collection?.collect_qr_scan === 1 ? 'success' : 'warning'} elevation={0}>
                                            Collected by <strong>{od?.collection?.collect_name}</strong> on <strong>{momentFormatDateTime(od?.collection?.collect_datetime)}</strong> {od?.collection?.collect_qr_scan === 1 ? '(QR Scanned)' : '(QR Not Scanned)'}
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tile
                                            noPaper
                                            color={'#000'}
                                            title={'Collection Signature'}
                                            content={<img src={`${CLOUDFRONT_URL}${od?.collection?.collect_sig}`} width={285} height={100} alt="Collection Signature" />}
                                        />
                                    </Grid> 
                                </Grid>
                            </Box>
                        </Grid>
                    )}
                    {!_.isEmpty(despApi) && (
                        <>
                            <Grid item xs={12}>
                                <Box p={3} pt={3} pb={3} className="content-light-white">
                                    <Typography variant="h6" paragraph>
                                        {od?.despatch?.courier?.courier_name} Shipment #{despApi?.shipment?.response?.data?.shipmentId ?? '-'} {despApi?.shipment?.response?.data?.consolidated ? `(Consolidated)` : ``}
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={4}>
                                            <Tile
                                                noPaper
                                                color={!despApi?.shipment?.response?.error ? '#4CAF50' : '#f44336'}
                                                icon={!despApi?.shipment?.response?.error ? 'check' : 'times'}
                                                title={'Successful'}
                                                content={!despApi?.shipment?.response?.error ? `Yes` : `No`}
                                            />
                                        </Grid> 
                                        <Grid item xs={12} lg={4}>
                                            <Tile
                                                noPaper
                                                color={!_.isEmpty(despApi?.shipment?.request?.delivery?.notifications?.email) ? '#4CAF50' : '#f44336'}
                                                icon={!_.isEmpty(despApi?.shipment?.request?.delivery?.notifications?.email) ? 'check' : 'times'}
                                                title={'E-mail Notifications'}
                                                content={!_.isEmpty(despApi?.shipment?.request?.delivery?.notifications?.email) ? `Yes` : `No`}
                                            />
                                        </Grid> 
                                        <Grid item xs={12} lg={4}>
                                            <Tile
                                                noPaper
                                                color={!_.isEmpty(despApi?.shipment?.request?.delivery?.notifications?.mobile) ? '#4CAF50' : '#f44336'}
                                                icon={!_.isEmpty(despApi?.shipment?.request?.delivery?.notifications?.mobile) ? 'check' : 'times'}
                                                title={'Mobile Notifications'}
                                                content={!_.isEmpty(despApi?.shipment?.request?.delivery?.notifications?.mobile) ? `Yes` : `No`}
                                            />
                                        </Grid> 
                                    </Grid>
                                </Box>
                            </Grid>
                            {_.map(despApi?.shipment?.response?.data?.consignmentDetail, (con, idx) => (
                                 <Grid item xs={12} key={idx}>
                                    <Box p={3} pt={3} pb={3} className="content-light-white">
                                        <Grid container spacing={3} alignItems='center'>
                                            <Grid item xs>
                                                <Typography variant="h6">
                                                    {od?.despatch?.courier?.courier_name} Consignment #{con?.consignmentNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Track Delivery">
                                                    <Button
                                                        variant="text"
                                                        onClick={() => window.open(`https://track.dpdlocal.co.uk/search?reference=${con?.consignmentNumber}&postcode=${od?.od_delivery_address_postcode.replace(" ", "").toLowerCase()}`)}
                                                    >
                                                        <FAIcon icon="location" size={15} button />
                                                        Track Delivery
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                            {_.map(con?.parcelNumbers, (pn, idx) => (
                                                <Grid item xs={9} key={idx}>
                                                    <Tile
                                                        noPaper
                                                        color={'#000'}
                                                        icon={'barcode'}
                                                        title={`Parcel ${(idx + 1)} of ${_.size(con?.parcelNumbers)}`}
                                                        content={`Parcel #${pn}`}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Grid>
                            ))}
                        </>
                    )}
                    {od?.despatch?.note && (
                        <Grid item xs={12}>
                            <Box p={3} pt={3} pb={1} className="content-light-white">
                                <Typography variant="h6" gutterBottom>
                                    Despatch Notes
                                </Typography>
                                {od?.despatch?.note?.note_value?.split?.('\n').map((item, key) => (
                                    <Typography 
                                        key={key} 
                                        variant="caption"
                                        component="div" 
                                        paragraph
                                    >
                                        {item}
                                    </Typography>
                                ))}
                            </Box>
                        </Grid>
                    )}
                      {od?.collection?.note && (
                        <Grid item xs={12}>
                            <Box p={3} pt={3} pb={3} className="content-light-white">
                                <Typography variant="h6" gutterBottom>
                                    Collection Notes
                                </Typography>
                                {od?.collection?.note?.note_value?.split?.('\n').map((item, key) => (
                                    <Typography 
                                        key={key} 
                                        variant="caption"
                                        component="div" 
                                        paragraph
                                    >
                                        {item}
                                    </Typography>
                                ))}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}

export default DespatchDetails;