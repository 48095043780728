import React, { useState, useEffect, useRef }  from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import AsyncSelect from 'Components/Common/Selects/AsyncSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { currencyFormat, getLeadTime } from 'Functions/MiscFunctions';

const Frequent = ({classes, frequent, handleAction}) => {
    const style = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre'
    }
    return (
        (!_.isEmpty(frequent) && (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" paragraph>
                        Frequent Suppliers
                    </Typography>
                    <Grid container spacing={3}>
                        {_.map(frequent, (supp, idx) => (
                            <Grid item xs={12} sm={6} md={6} xl={3} key={idx}>
                                <Card>
                                    <CardActionArea onClick={() => handleAction(supp.supp_id)}>
                                        <CardHeader
                                            title={<Typography variant="body1" className="fw-400">{supp.name.substring(0, 35)}{supp.name.length > 35 ? '...' : ''}</Typography>}
                                            subheader={<Typography variant="caption">{supp.account}</Typography>}
                                            disableTypography
                                            className="pl-3 pt-3 pr-3 pb-0"
                                        />
                                        <Box p={3} pt={2}>
                                            <Box pb={0.5}>
                                                <Grid container alignItems='center'>
                                                    <Grid item style={{width: 25}}>
                                                        <FAIcon type='thin' icon='phone' size='small' />
                                                    </Grid>
                                                    <Grid item xs style={{width: 'calc(100% - 25px)'}}>
                                                        <Typography variant="caption" component="div" style={style}>
                                                            {!_.isEmpty(supp.phone) ? supp.phone : '-'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box pb={0.5}>
                                                <Grid container alignItems='center'>
                                                    <Grid item style={{width: 25}}>
                                                        <FAIcon type='thin' icon='envelope' size='small' />
                                                    </Grid>
                                                    <Grid item xs style={{width: 'calc(100% - 25px)'}}>
                                                        <Typography variant="caption" component="div" style={style}>
                                                            {!_.isEmpty(supp.email) ? supp.email : '-'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box pb={0.5}>
                                                <Grid container alignItems='center'>
                                                    <Grid item style={{width: 25}}>
                                                        <FAIcon type='thin' icon='coins' size='small' />
                                                    </Grid>
                                                    <Grid item xs style={{width: 'calc(100% - 25px)'}}>
                                                        <Typography variant="caption" component="div" style={style}>
                                                            {!_.isEmpty(supp.mov) ? (supp.mov === "0.00" ? "No minimum order value" : `${currencyFormat.format(supp.mov)} minimum order value`) : '-'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Grid container alignItems='center'>
                                                <Grid item style={{width: 25}}>
                                                    <FAIcon type='thin' icon='calendar' size='small' />
                                                </Grid>
                                                <Grid item xs style={{width: 'calc(100% - 25px)'}}>
                                                    <Typography variant="caption" component="div" style={style}>
                                                        {!_.isEmpty(supp.lead) ? `${getLeadTime(supp.lead, true)} Lead Time` : 'No Lead Time'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )) || (
            null
        )
    )
}

const formatOptionLabel = ({label, img, subtitle1, subtitle2}, props) => {
    return (
        (props.context === 'value' && (
            <Box pt={1} pb={1}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item align='center'>
                        <FAIcon icon='truck' type='thin' className="ml-1 mr-1" size={25} noMargin button />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body2" className="fw-400">
                            {label.substr(0,40)}{label.length > 40 ? '...' : ''}
                        </Typography>
                        {subtitle1 && ( 
                            <Typography variant="caption" component="div" >
                                {subtitle1.substr(0,39)}{subtitle1.length > 39 ? '...' : ''}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        )) || (
            <Box pt={1} pb={1}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item align='center'>
                        <FAIcon icon='truck' type='thin' className="ml-1 mr-1" size={25} noMargin button />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body2" className="fw-400">
                            {label.substr(0,40)}{label.length > 40 ? '...' : ''}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" component="div">
                            Supplier
                        </Typography>
                        {subtitle1 && ( 
                            <Typography variant="caption" component="div" >
                                {subtitle1}
                            </Typography>
                        )}
                        {subtitle2 && ( 
                            <Typography variant="caption" component="div" >
                                {subtitle2}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        )
    )
}


const SupplierSearchSelector = ({noFocus, handleAction, placeholder, searchOnly}) => {

    const selectRef = useRef();
    const [frequent, setFrequent] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState([]);

    const loadCustomerOptions = (searchString, callback) => {
        let params = { 
            searchString,
        }
        API.get('/smartSearch/suppliers', 
            {
                props: {
                    cancellation: true,
                    noLoading: true
                },
                params
            }
        )
        .then(res => {

            let defaultOptions = [];

            if(res?.data && res.data.length > 0) {
                _.each(res.data, result => {
                    defaultOptions.push(
                        {
                            label: result.title,
                            value: result.action,
                            img: result.img,
                            subtitle1: result.subtitle1,
                            subtitle2: result.subtitle2
                        }
                    )
                })
            }
            
            callback(defaultOptions)
            setDefaultOptions(defaultOptions)

        })
    }

    useEffect(() => {
        if(!noFocus && frequent && selectRef?.current?.focus) {
            selectRef.current.focus()
        }/*eslint-disable-next-line*/
    }, [frequent])

    useEffect(() => {
        if(!searchOnly) {
            API.get('/smartSearch/suppliers', { params: { frequent: true }})
            .then(result => {
                if(result?.data) {
                    setFrequent(result.data);
                }
            })
        }/* eslint-disable-next-line */
    }, []);

    return (
        (searchOnly && (
            <AsyncSelect
                apiCall={_.debounce(loadCustomerOptions, 300)}
                formatOptionLabel={formatOptionLabel}
                innerRef={selectRef}
                placeholder={placeholder ?? undefined}
                defaultOptions={defaultOptions}
                onChange={v => handleAction(v?.value, v?.label)}
                variant="filled"
            />
        )) || (
            (!frequent && (
                <LoadingCircle />
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Typography variant="h6" paragraph>
                            Supplier
                        </Typography>
                        <AsyncSelect
                            apiCall={_.debounce(loadCustomerOptions, 300)}
                            formatOptionLabel={formatOptionLabel}
                            innerRef={selectRef}
                            defaultOptions={defaultOptions}
                            onChange={v => handleAction(v?.value)}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Frequent
                            frequent={frequent}
                            handleAction={handleAction}
                        />
                    </Grid>
                </Grid>
            )
        )
    )
}

export default withRouter(SupplierSearchSelector);