import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const AccContactActivity = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/accountContactActivity',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.sgr,
                        dataRef: 'sgr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.cac,
                        dataRef: 'cac',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Telephone',
                        field: rowData => rowData.t,
                        dataRef: 't',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Order',
                        field: rowData => rowData.od,
                        fieldFormat: 'date',
                        dataRef: 'od',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Call',
                        field: rowData => rowData.nd,
                        fieldFormat: 'date',
                        dataRef: 'nd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Call Type',
                        field: rowData => rowData.ty,
                        dataRef: 'ty',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Call Note',
                        field: rowData => rowData.cad,
                        dataRef: 'cad',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Call Handler',
                        field: rowData => <StaffChip staff={staff?.[rowData?.s]} />,
                        fieldFormat: 'staff',
                        dataRef: 's',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Proactive Responsibility',
                        field: rowData => rowData.am > 0 ? <StaffChip staff={staff?.[rowData?.am]} /> : '-',
                        fieldFormat: 'staff',
                        dataRef: 'am',
                        important: true,
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'acc-contact-activity',
                noResultsText: 'Sorry, no account contact activity is available to view',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Customers'],
                pdf: true,
                persistenceId: 'accContactActivity',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: true,
                showDateRange: false,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Account Contact Activity Report'
            }}
        />
    )
}

export default AccContactActivity;