import React, { useState, useEffect }  from 'react';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AsyncSelect from 'Components/Common/Selects/AsyncSelect';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import { CLOUDFRONT_URL } from 'Constants';

const OrderedSearchSelector = ({forCustomer, disableProductRows, handleProductChange, selectRef}) => {

    const   [defaultOptions, setDefaultOptions] = useState([]),
            [options, setOptions] = useState([]);
    
    const loadReturnOptions = (searchString, callback, selectRef) => {

        let forRMA = true,
            params = { 
                searchString,
                forCustomer,
                forRMA
            },
            props = {
                cancellation: true,
                noLoading: true
            };

        API.get('/smartSearch/products', 
            {
                props,
                params
            }
        )
        .then(res => {

            let results = [];

            if(res?.data && res.data.length > 0) {
                _.each(res.data, result => {
                    let disabled = result.d === 1 ? true : false;
                    if(!disabled) {
                        let ex = _.filter(disableProductRows, el => el.odp === result.odp);
                        if(!_.isEmpty(ex)) {
                            if(_.sumBy(ex, 'quantity') >= result.qty) {
                                disabled = true;
                            }
                        }
                    }
                    results.push(
                        {
                            label: result.title,
                            code: result.title.split('|')[0],
                            value: result.action,
                            img: result.img,
                            subtitle1: result.subtitle1,
                            subtitle2: result.subtitle2,
                            disabled
                        }
                    )
                })
            }
            
            callback(results)
            setOptions(results)


        })
    }

    const formatOptionLabel = ({action, label, img, subtitle1, subtitle2}) => {
        return (
            <Box pt={1} pb={1}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item style={{width: 40}}>
                        <Avatar variant="square" className="mr-1" style={{background: 'transparent', width: 30, height: 30}}><ImageWithError alt="Product Image" width="100%" height="100%" src={`${CLOUDFRONT_URL}${img}`} /></Avatar>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body2">
                            {label.substr(0,80)}{label.length > 80 ? '...' : ''}
                        </Typography>
                        {subtitle1 && ( 
                            <Typography variant="caption" component="div" >
                                {subtitle1.substr(0,80)}{subtitle1.length > 80 ? '...' : ''}
                            </Typography>
                        )}
                        {subtitle2 && ( 
                            <Typography variant="caption" component="div" >
                                {subtitle2}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        )
    }
    
    const handleQuickAddOrderedProduct = defaultData => {

        if(defaultData?.value) {
            handleProductChange(defaultData?.value)
        }

        setOptions(defaultOptions);

    }

    useEffect(() => {

        if(forCustomer > 0) {

            let forRMA = true,
                limit  = 100,
                searchString = '',
                params = { 
                    searchString,
                    forCustomer,
                    forRMA,
                    limit
                },
                props = {
                    cancellation: true,
                    noLoading: true
                };

            API.get('/smartSearch/products', 
                {
                    props,
                    params
                }
            )
            .then(res => {

                let results = [];

                if(res?.data && res.data.length > 0) {
                    _.each(res.data, result => {
                        let disabled = result.d === 1 ? true : false;
                        if(!disabled && !_.isEmpty(disableProductRows)) {
                            if(_.find(disableProductRows, el => el.odp === result.odp)) {
                                disabled = true;
                            }
                        }
                        results.push(
                            {
                                label: result.title,
                                code: result.title.split('|')[0],
                                value: result.action,
                                img: result.img,
                                subtitle1: result.subtitle1,
                                subtitle2: result.subtitle2,
                                disabled
                            }
                        )
                    })
                }
                
                setOptions(results)
                setDefaultOptions(results)

            });

        }

    }, [forCustomer, disableProductRows]);

    return (
        <AsyncSelect
            apiCall={_.debounce(loadReturnOptions, 250)}
            defaultOptions={options}
            formatOptionLabel={formatOptionLabel}
            innerRef={selectRef}
            onChange={handleQuickAddOrderedProduct}
        />
    );
}

export default OrderedSearchSelector;