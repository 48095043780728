import React from 'react';
import { useSelector } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

import Messages from 'Components/Messages/Messages';
import { isTablet } from 'Functions/MiscFunctions';

const useStyles = makeStyles({
    paper: {
        marginTop: 'calc(env(safe-area-inset-top) + 50px)',
        width: '100%',
        maxWidth: isTablet() ? '100%' : 750,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)'
    },
    smallHeaderPaper: {
        marginTop: 'calc(env(safe-area-inset-top) + 0px)!important',
        width: '100%',
        maxWidth: isTablet() ? '100%' : 750,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)'
    }
})

const MessageDrawer = ({open, smallHeader, toggleDrawer}) => {
    const ui = useSelector(state => state.ui);
    const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
    const classes = useStyles();
    return (
        <Drawer
            anchor="right"
            classes={{
                paper: smallHeader || ui?.device?.isTablet ? classes.smallHeaderPaper : classes.paper,
            }}
            open={open}
            style={{height: height, }}
            variant='temporary'
        >
            <Messages 
                toggleDrawer={toggleDrawer}
                open={open}
            />
        </Drawer>
    )
}

export default MessageDrawer;