import React from 'react';

import Search from 'Components/Common/Search/Search';

const ProductCheckPriceAndAvailability = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/productCheckPriceAndAvailability',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.s,
                        dataRef: 's',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.c,
                        dataRef: 'c',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.d,
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status',
                        field: rowData => rowData.a,
                        dataRef: 'a',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Replaced',
                        field: rowData => rowData.r,
                        dataRef: 'r',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'check-p-a-products',
                noResultsText: 'No products have been found using the search criteria provided.',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Products'],
                pdf: true,
                persistenceId: 'productCheckPriceAndAvailability',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: true,
                showTotals: false,
                title: 'Check Price and Availability Products Report'
            }}
        />
    )
}

export default ProductCheckPriceAndAvailability;