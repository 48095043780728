import React from 'react';
import moment from 'moment';
import _ from 'lodash';
// import { Prompt } from 'react-router';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
// import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Highlight from 'Components/Common/Highlight/Highlight';
import InsightChip from 'Components/Common/Chips/InsightChip';
import JoinOrderLine from 'Components/Purchases/Actions/JoinOrderLine';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import logo from 'Assets/Images/no-img.jpg';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import PurchaseOrderSummary from 'Components/Purchases/Misc/PurchaseOrderSummary';
import Preview from 'Components/Purchases/PurchaseOrderForm/Preview';
import SplitOrderLine from 'Components/Purchases/Actions/SplitOrderLine';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { getLeadTime, currencyFormat } from 'Functions/MiscFunctions';
import { handleChange } from 'Functions/FormFunctions';
import { CLOUDFRONT_URL } from 'Constants';
import { Button } from '@material-ui/core';

const formatOptionLabel = ({label, date}) => {
    return (
        <Box pt={0.5} pb={0.5}>
            <Grid container spacing={1} alignItems='center'>
                <Grid item align='center'>
                    <FAIcon icon='shipping-fast' className="ml-1 mr-1" size={25} noMargin button />
                </Grid>
                <Grid item xs>
                    <Typography variant="body2" className="fw-400">
                        {label}
                    </Typography>
                    <Typography variant="caption" component="div">
                        Requested Despatch: {date}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const initialState = () => ({
    formData: {
        deliveries: [],
        lines: [],
        type: 'E-mail Supplier'
    },
    initialFormData: {
        deliveries: [],
        lines: [],
        type: 'E-mail Supplier'
    },
    isLoading: false,
    isLoadingPreview: true,
    preview: {
        numPages: null,
        pageNumber: 1,
        pdf: false
    }
})

class AwaitingSubmission extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
    }

    componentDidMount = () => {

        const   lines       = [],
                { po }      = this.props,
                deliveries  = [
                    {
                        date: this.props.po?.supplier?.defaultDate ?? moment().format("YYYY-MM-DD")
                    }
                ];

        _.each(po.products, product => {
            lines.push(
                {
                    ...product,
                    delivery: 1
                }
            )
        })

        this.setState({
            formData: {
                ...this.state.formData,
                deliveries,
                lines
            },
            initialFormData: {
                ...this.state.formData,
                deliveries,
                lines
            }
        })

    }

    componentDidUpdate = prevProps => {
        if(prevProps.currentTab !== this.props.currentTab) {
            if(this.props.currentTab === 3) {
                const { formData } = this.state;
                let dates = [];
                let error = false;
                formData.deliveries.forEach((pod, idx) => {
                    if(!error) {
                        const   rows    =   _.filter(formData.lines, el => el.delivery === (idx + 1));
                        if(_.isEmpty(rows)) {
                            error = true;
                        } else {
                            dates.push(moment(pod.date).format("DDMMYYYY"))
                        }
                    }
                });
                if(error) {
                    this.props.handleTabChange(2);
                    this.props.deploySnackBar("error", "One or more deliveries contain no lines, please check and try again")
                } else if(_.some(dates, (el, idx) => dates.indexOf(el) !== idx)) {
                    this.setState({
                        preview: initialState().preview
                    }, () => {
                        this.props.deployConfirmation(<><div className="fw-400">Some deliveries have the same requested despatch date</div><div>Do you want to continue?</div></>, () => this.handlePreviewSubmit(), () => this.props.handleTabChange(2))
                    })
                } else {
                    this.handlePreviewSubmit();
                }
            }
        }
    }

    handleDeliveryAdd = () => {
        const deliveries = [...this.state.formData.deliveries];
        deliveries.push(
            {
                label: `Delivery ${(_.size(deliveries) + 1)}`,
                date: this.props.po?.supplier?.defaultDate ?? moment().format('YYYY-MM-DD')
            }
        )
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries,
            }
        })
    }
     
    handleDeliveryDateChange = (idx, date) => {
        let deliveries = [...this.state.formData.deliveries];
        deliveries[idx] = {
            ...deliveries[idx],
            date: moment(date).format('YYYY-MM-DD')
        }
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries,
            }
        })
    }

    
    handleDeliveryRemove = idx => {
        const deliveries = [...this.state.formData.deliveries]
        let lines = [...this.state.formData.lines];

        deliveries.splice(idx, 1)

        _.each(lines, (line, idxx) => {
            lines[idxx] = {
                ...lines[idxx],
                delivery: line.delivery >= (idx + 1) ? (line.delivery - 1) : line.delivery
            }
        })

        this.setState({
            formData:{
                ...this.state.formData,
                deliveries,
                lines
            }
        })
    }

    handleLineDeliveryChange = (idx, delivery) => {
        let lines = [...this.state.formData.lines];
        lines[idx] = {
            ...lines[idx],
            delivery: delivery.value ?? delivery ?? ''
        }
        lines = _.sortBy(lines, el => el.delivery);
        this.setState({
            formData: {
                ...this.state.formData,
                lines
            }
        })
    }
    
    handleLineSplit = idx => this.props.deployDialog(
        <SplitOrderLine
            closeDialog={this.props.closeDialog}
            deployConfirmation={this.props.deployConfirmation}
            idx={idx} 
            product={this.state.formData?.lines?.[idx] ?? false}
            submit={this.handleLineSplitSubmit}
        />
        , false, "", "standard", "sm"
    )

    handleLineSplitSubmit = (idx, qty) => {

        let lines       =   [...this.state.formData.lines],
            currentQty  =   lines?.[idx]?.quantity ?? 0,
            newQty      =   (currentQty - qty),
            currentVat =    lines?.[idx]?.vat;

        if(newQty > 0) {
            lines[idx] = {
                ...lines[idx],
                quantity: newQty,
                quantityDespatch: newQty,
                totalCost: (parseInt(newQty) * parseFloat(lines[idx]['cost'])).toFixed(2),
                vat: ((parseFloat(currentVat) / parseInt(currentQty)) * (parseInt(newQty))).toFixed(2)
            }
            lines.push(
                {
                    ...lines[idx],
                    quantity: qty,
                    quantityDespatch: qty,
                    totalCost: (parseInt(qty) * parseFloat(lines[idx]['cost'])).toFixed(2),
                    vat: ((parseFloat(currentVat) / parseInt(currentQty)) * (parseInt(qty))).toFixed(2)
                }
            )
            lines = _.sortBy(lines, el => el.delivery);
            this.setState({
                formData: {
                    ...this.state.formData,
                    lines,
                }
            }, () => {
                this.props.closeDialog()
            })
        }

    }
   
    handleLineJoin = idx => this.props.deployDialog(
        <JoinOrderLine
            closeDialog={this.props.closeDialog}
            deployConfirmation={this.props.deployConfirmation}
            idx={idx} 
            product={this.state.formData?.lines?.[idx] ?? false}
            formData={this.state.formData}
            submit={this.handleLineJoinSubmit}
        />
        , false, "", "standard", "sm"
    )

    handleLineJoinSubmit = lines => {
        this.setState({
            formData: {
                ...this.state.formData,
                lines,
            }
        }, () => {
            this.props.closeDialog()
        })
    }

    handleMethodChange = type => this.setState({
        formData: {
            ...this.state.formData,
            type
        }
    }, () => {
        if(type === "Manual Submission") {
            this.props.deployConfirmation(`Are you sure you want to ${type === "Manual Submission" ? `mark this purchase order as submitted` : `send this purchase order now`}?`, this.handleSubmit, () => this.handleMethodChange('E-mail Supplier'))
        }
    })

    handlePreviewLoad = ({numPages}) => {
        this.setState({
            preview: {
                ...this.state.preview,
                numPages 
            }
        });
    }

    handlePreviewPagination = offset => {
        this.setState(prevState => ({
            preview: {
                ...prevState.preview,
                pageNumber: ((prevState?.preview?.pageNumber ?? 1) + offset)
            }
        }))
    }

    handlePreviewSubmit = () => {

        const   { po }              =   this.props,
                { formData }        =   this.state;

        let     isLoadingPreview    =   true;

        this.setState({
            isLoadingPreview
        }, () => {
            API.post(`/purchaseOrders/${po.po_id}/process/submission`, { preview: true, ...formData } )
            .then(result => {                
                if(result?.data?.errors) {
                    this.props.callback();
                    this.props.handleTabChange(0);
                    this.props.deploySnackBar("error", "Something went wrong, please try again");
                } else {
                    if(result?.data?.pdf) {

                        const   pdf         = result.data.pdf,
                                pageNumber  =   1,
                                numPages    =   null;
                                isLoadingPreview = false;

                        this.setState({
                            isLoadingPreview,
                            preview: {
                                numPages,
                                pageNumber,
                                pdf
                            }
                        })
                        
                    }
                }
            })
        })
    }

    handleSubmit = (submit = false) => {
        const { history, id, po } = this.props;
        this.setState({
            isLoading: true
        }, () => {
            API.post(`/purchaseOrders/${id}/process/submission`, this.state.formData)
            .then(result => {
                if(result?.data) {
                    if(result?.data?.errors) {

                        if(result?.data?.errors?.[0]?.msg === "This purchase order cannot be submitted as the sales order has been modified") {
                            
                            this.props.deploySnackBar("error", result?.data?.errors?.[0]?.msg);
                            this.props.history.push(`/purchase-orders/${id}`)

                        } else {

                            if(po.po_type === "Stock") {
                                this.props.callback();
                                this.props.handleTabChange(0);
                            }

                            this.props.deploySnackBar("error", result?.data?.errors?.[0]?.msg);
                        }

                    } else {

                        if(typeof submit === "function") {
                            submit?.();
                        }

                        this.props.deploySnackBar(`success`, `You have successfully submitted this purchase order`);

                        if(history) {
                            history.push(`/purchase-orders/${id}`)
                        } else {
                            this.props.callback?.()
                        }

                    }
                }
            })
        })
    }

    render() {        
        const { contentHeight, currentTab, po, showDeliveryConfiguration } = this.props;
        const { formData, isLoading, isLoadingPreview, preview } = this.state;

        let defaultFormData = {
            emailSubject: `Purchase Order #${po.po_reference}`,
        }

        if(!_.isEmpty(po?.supplier?.supp_email)) {
            defaultFormData = {
                ...defaultFormData,
                emailTo: {
                    label:po.supplier.supp_email,
                    value:po.supplier.supp_email
                }
            }
        }

        if(!_.isEmpty(po?.supplier?.po_emails)) {
            let emailCc = [];
            po.supplier.po_emails.forEach((email) => {
                emailCc.push({
                    label: email.spoea_email_address,
                    value: email.spoea_email_address
                })
            });
            defaultFormData = {
                ...defaultFormData,
                emailCc
            }
        }

        return (
            <Grid container spacing={3}>
                {/* <Prompt
                    when={JSON.stringify(formData) !== JSON.stringify(initialFormData)}
                    message='You have unsaved changes, are you sure you want to leave?'
                /> */}
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    (!showDeliveryConfiguration) && (
                        <>
                            <Grid item xs={12} lg={4} xl={3}>
                                <PaddedPaper>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant="h6">
                                                Submission Type
                                            </Typography>    
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Please select how you want to submit this purchase order
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                checked={formData.type === 'E-mail Supplier' ? true : false}
                                                control={<Radio color="primary" />}
                                                label="E-mail Supplier"
                                                labelPlacement="end"
                                                onChange={() => this.handleMethodChange('E-mail Supplier')}
                                                margin="none"
                                                className="pl-1"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                checked={formData.type === 'Manual Submission' ? true : false}
                                                control={<Radio color="primary" />}
                                                label="Manual Submission"
                                                labelPlacement="end"
                                                onChange={() => this.handleMethodChange('Manual Submission')}
                                                margin="none"
                                                className="pl-1"
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12} lg={8} xl={9}>
                                <Grid container spacing={3}>
                                    {(formData.type === 'E-mail Supplier' && (
                                        <Grid item xs={12}>                                                        
                                            <PaddedPaper>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <Typography variant="h6">
                                                            E-mail Supplier
                                                        </Typography>    
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <EmailDialog
                                                            id={po.po_id}
                                                            type='po'
                                                            action='submission'
                                                            callback={this.props.callback}
                                                            defaultFormData={defaultFormData}
                                                            success='The purchase order has been successfully sent to the supplier'
                                                            confirmation='Are you sure you want to send this purchase order now?'
                                                            send='Submit Purchase Order'
                                                            parentSubmit={this.handleSubmit}
                                                            noCancel
                                                            noDialog
                                                            noSnackBar
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    ))}
                                    {/* // ) || (formData.type === 'Manual Submission' && (
                                    //     <Grid item xs={12}>                                                        
                                    //         <PaddedPaper>
                                    //             <Grid container spacing={1} alignItems='center'>
                                    //                 <Grid item>
                                    //                     <FAIcon icon="user" type="light" size={25} noMargin />
                                    //                 </Grid>
                                    //                 <Grid item xs>
                                    //                     <Typography variant="h6">
                                    //                         Manual Submission
                                    //                     </Typography>    
                                    //                 </Grid>
                                    //                 <Grid item xs={12}>
                                    //                     <Box pt={0.75}>
                                    //                         <Typography variant="body2">
                                    //                             This will mark the purchase order as manually submitted
                                    //                         </Typography>
                                    //                     </Box>
                                    //                 </Grid>
                                    //                 <Grid item xs={12} align='right'>
                                    //                     <Button
                                    //                         color="primary"
                                    //                         onClick={() => deployConfirmation("Are you sure you want to submit this purchase order?", this.handleSubmit)}
                                    //                         variant="text"
                                    //                     >
                                    //                         Submit
                                    //                     </Button>
                                    //                 </Grid>
                                    //             </Grid>
                                    //         </PaddedPaper>
                                    //     </Grid>
                                    // ))} */}
                                </Grid>
                            </Grid>
                        </>
                    )) || (
                        (currentTab === 0 && (
                            <Grid item xs={12}>
                                <ViewNotes heading={<Typography variant="h6">Purchase Order Notes</Typography>} notes={po.notes} hideDeleteButton />
                            </Grid>
                        )) || (currentTab === 1 && (
                            <>
                                <Grid item xs={12}>
                                    <PurchaseOrderSummary po={po} />
                                </Grid>
                            </>
                        )) || (currentTab === 2 && (
                            <>
                                <Grid item xs={12}>
                                    <Box pb={3}>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item xs={12} md>
                                                <Typography variant="h6">
                                                    {po?.supplier?.supp_company_name}
                                                </Typography>
                                                <Typography variant="body2" className="fw-400">
                                                    Purchase Order Submission
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <InsightChip
                                                    paddingLeft={8}
                                                    marginRight={8}
                                                    // background={po?.supplier?.supp_lead_time === "SAME" || po?.supplier?.supp_lead_time === "1:DAY" ? '#4CAF50' : (po?.supplier?.supp_lead_time === "CHECK" ? '#ef3340' : '#FF5722')}
                                                    // color="#fff"
                                                    icon="calendar"
                                                    label={`${getLeadTime(po?.supplier?.supp_lead_time, true)} Lead Time`}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <InsightChip
                                                    marginRight={8}
                                                    paddingLeft={8}
                                                    // background={po?.supplier?.cutOffPast ? '#ef3340' : '#4CAF50'}
                                                    // color="#fff"
                                                    icon="clock"
                                                    label={`${po?.supplier?.cutOffTime} Cut Off Time`}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <InsightChip
                                                    paddingLeft={8}
                                                    // background={po?.supplier?.supp_min_order_value === "0.00" ? '#4CAF50' : (parseFloat(po?.po_total_net) > parseFloat(po?.supplier?.supp_min_order_value) ? '#4caf50' : '#ef3340')}
                                                    // color="#fff"
                                                    icon="coins"
                                                    label={`${po?.supplier?.supp_min_order_value === "0.00" ? 'No' : currencyFormat.format(po.supplier.supp_min_order_value)} Minimum Order`}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Paper>
                                        <Box p={3}>
                                            <Grid container spacing={1} alignItems='center'>
                                                {/* <Grid item>
                                                    <FAIcon icon="truck-loading" type="light" size={25} noMargin />
                                                </Grid> */}
                                                <Grid item xs>
                                                    <Typography variant="h6" className="fw-400">
                                                        Deliveries
                                                    </Typography>    
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        onClick={this.handleDeliveryAdd}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Additional Delivery
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DataTable  
                                                        config={{
                                                            alternatingRowColours: true,
                                                            // responsiveImportance: true,
                                                            inline: true
                                                        }}
                                                        columns={[ 
                                                            {
                                                                heading: 'Delivery',
                                                                field: rowData => (
                                                                    <Box pt={1.75} pb={1.75}>
                                                                        <Typography variant="body2" className="fw-400">
                                                                            {`Delivery ${rowData.rowNumber} / ${_.size(formData.deliveries)}`}
                                                                        </Typography>
                                                                        <Typography variant="caption">
                                                                            Despatch subject to supplier confirmation
                                                                        </Typography>
                                                                    </Box>
                                                                ),
                                                                main: true,
                                                                important: true, 
                                                                cellProps: rowData => ({
                                                                    className: _.isEmpty(_.filter(formData.lines, el => el.delivery === rowData.rowNumber)) ? 'textError' : undefined
                                                                })
                                                            },
                                                            {
                                                                heading: 'Lines',
                                                                field: rowData => {
                                                                    const count = _.size(_.filter(formData.lines, el => el.delivery === rowData.rowNumber))
                                                                    return <Highlight variant={count > 0 ? 'success' : 'error'}>{count}</Highlight>
                                                                },
                                                                important: true, 
                                                                alignment: 'center',
                                                                sizeToContent: true,
                                                                cellProps: rowData => ({
                                                                    className: _.isEmpty(_.filter(formData.lines, el => el.delivery === rowData.rowNumber)) ? 'textError' : undefined
                                                                })
                                                            },
                                                            {
                                                                heading: 'Requested Despatch Date',
                                                                field: rowData => (
                                                                    <Box minWidth={252}>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item xs>
                                                                                <FormControl fullWidth>
                                                                                    <DatePicker
                                                                                        type="date"
                                                                                        value={rowData.date}
                                                                                        onChange={date => this.handleDeliveryDateChange(rowData.rowIdx, date)}
                                                                                        autoOk={true}
                                                                                        disablePast={true}
                                                                                        inputVariant="filled"
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Box minWidth={38}>
                                                                                    {moment(rowData.date, "YYYY-MM-DD").isBefore(moment(this.props.po?.supplier?.defaultDate).format("YYYY-MM-DD")) && (
                                                                                        <FAIcon icon="exclamation-triangle" color="error" noMargin button />
                                                                                    )}
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                ),
                                                                tooltip: () => this.props.po?.supplier?.defaultDateTooltip ? _.map(this.props.po?.supplier?.defaultDateTooltip.split("\n"), (r, idx) => <div key={idx}>{r}</div>) : 'No factors affect despatch',
                                                                sizeToContent: true,
                                                                important: true,
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'Delete Delivery', icon: 'trash-alt', onClick: () => this.handleDeliveryRemove(rowData.rowIdx), disabled: _.some(formData.lines, el => el.delivery === rowData.rowNumber)},
                                                                    ]
                                                                }
                                                            }
                                                        ]}
                                                        rows={formData.deliveries}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box mb={3} />
                                            <Grid container spacing={1} alignItems='center'>
                                                {/* <Grid item>
                                                    <FAIcon icon="box-check" type="light" size={25} noMargin />
                                                </Grid> */}
                                                <Grid item xs>
                                                    <Typography variant="h6" className="fw-400">
                                                        Ordered Lines
                                                    </Typography>    
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DataTable  
                                                        config={{
                                                            alternatingRowColours: true,
                                                            // responsiveImportance: true,
                                                            inline: true,
                                                            noRowHover: true
                                                        }}
                                                        columns={[ 
                                                            {
                                                                heading: 'Product',
                                                                field: rowData => <Avatar variant="square" alt={rowData.image} src={(rowData.image && `${CLOUDFRONT_URL}${rowData.image}`) || logo} />,
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: '',
                                                                field: rowData => (
                                                                    <Box pt={1.75} pb={1.75}>
                                                                        <Typography variant="body2" className="fw-400">
                                                                            {rowData.productCode}
                                                                        </Typography>
                                                                        <Typography variant="caption" component="div">
                                                                            {rowData.productName}
                                                                        </Typography>
                                                                    </Box>
                                                                ),
                                                                important: true, 
                                                                main: true,
                                                                truncate: true
                                                            },
                                                            {
                                                                heading: 'Buy Price',
                                                                field: rowData => rowData.cost,
                                                                tooltip: rowData => `Buy at £${rowData.cost} per unit`,
                                                                fieldFormat: 'currency',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Quantity',
                                                                field: rowData => <Tooltip placement="right" title={`${rowData.quantity} Quantity Required`}><span><Highlight variant="success">{rowData.quantity}</Highlight></span></Tooltip>,
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Despatch',
                                                                field: rowData => <Tooltip placement="right" title={`${rowData.quantityDespatch} Quantity To Despatch`}><span><Highlight variant="success">{rowData.quantityDespatch}</Highlight></span></Tooltip>,
                                                                alignment: 'center',
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: 'Despatch with',
                                                                field: rowData => (
                                                                    <Box style={{width: 275}}>
                                                                        <AutoCompleteSelect
                                                                            formatOptionLabel={formatOptionLabel}
                                                                            value={rowData.delivery} 
                                                                            onChange={delivery => this.handleLineDeliveryChange(rowData.rowIdx, delivery)}
                                                                            options={_.map(formData.deliveries, (el, idx) => _.assign({label: `Delivery ${idx+1}`, date: moment(el.date).format("DD/MM/YYYY"), value: (idx + 1)}))}
                                                                            noClear
                                                                            variant="filled"
                                                                            disabled={rowData.quantityDespatch === 0 || formData.deliveries?.[(rowData.delivery - 1)]?.despatched}
                                                                        />
                                                                    </Box>
                                                                ),
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'Merge Lines', icon: 'compress-arrows-alt', onClick: () => this.handleLineJoin(rowData.rowIdx), disabled: rowData.quantity !== rowData.quantityDespatch || _.size(_.filter(formData.lines, el => el.productId === rowData.productId && el.cost === rowData.cost)) === 1},
                                                                        {name: 'Split Line', icon: 'random', onClick: () => this.handleLineSplit(rowData.rowIdx), disabled: rowData.quantity !== rowData.quantityDespatch || rowData.quantity < 2},
                                                                    ]
                                                                }
                                                            }
                                                        ]}
                                                        rows={formData.lines}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </>
                        )) || (currentTab === 3 && (
                            (isLoadingPreview && (
                                <Grid item xs={12}>
                                    <Typography variant="h6" align='center'>
                                        <LoadingCircle />
                                        Generating Preview
                                    </Typography>
                                </Grid>
                            )) || (
                                <Grid item xs={12}>
                                    <Box style={{border: '1px solid #ddd', margin: -24}}>
                                        <Preview
                                            height={(contentHeight - 123)}
                                            preview={preview}
                                            handlePreviewLoad={this.handlePreviewLoad}
                                            handlePreviewPagination={this.handlePreviewPagination}
                                        />
                                    </Box>
                                </Grid>
                            )
                        )) || (currentTab === 4 && (
                            <>
                                <Grid item xs={12} lg={4} xl={3}>
                                    <PaddedPaper>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item xs>
                                                <Typography variant="h6">
                                                    Submission Type
                                                </Typography>    
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    Please select how you want to submit this purchase order
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    checked={formData.type === 'E-mail Supplier' ? true : false}
                                                    control={<Radio color="primary" />}
                                                    label="E-mail Supplier"
                                                    labelPlacement="end"
                                                    onChange={() => this.handleMethodChange('E-mail Supplier')}
                                                    margin="none"
                                                    className="pl-1"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    checked={formData.type === 'Manual Submission' ? true : false}
                                                    control={<Radio color="primary" />}
                                                    label="Manual Submission"
                                                    labelPlacement="end"
                                                    onChange={() => this.handleMethodChange('Manual Submission')}
                                                    margin="none"
                                                    className="pl-1"
                                                />
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={8} xl={9}>
                                    <Grid container spacing={3}>
                                        {(formData.type === 'E-mail Supplier' && (
                                            <Grid item xs={12}>                                                        
                                                <PaddedPaper>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs>
                                                            <Typography variant="h6">
                                                                E-mail Supplier
                                                            </Typography>    
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <EmailDialog
                                                                id={po.po_id}
                                                                type='po'
                                                                action='submission'
                                                                callback={this.props.callback}
                                                                defaultFormData={defaultFormData}
                                                                success='The purchase order has been successfully sent to the supplier'
                                                                confirmation='Are you sure you want to send this purchase order now?'
                                                                send='Submit Purchase Order'
                                                                parentSubmit={this.handleSubmit}
                                                                posPdf={preview.pdf}
                                                                posFileName={`AA-Purchase-Order-Request-${po.po_reference}.pdf`}
                                                                noCancel
                                                                noDialog
                                                                noSnackBar
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        ))}
                                         {/* || (formData.type === 'Manual Submission' && (
                                            <Grid item xs={12}>                                                        
                                                <PaddedPaper>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item>
                                                            <FAIcon icon="user" type="light" size={25} noMargin />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Typography variant="h6">
                                                                Manual Submission
                                                            </Typography>    
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box pt={0.75}>
                                                                <Typography variant="body2">
                                                                    This will mark the purchase order as manually submitted
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} align='right'>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => deployConfirmation("Are you sure you want to submit this purchase order?", this.handleSubmit)}
                                                                variant="text"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        ))} */}
                                    </Grid>
                                </Grid>
                            </>
                        )
                    )
                )}
            </Grid>
        );
    }
}

export default AwaitingSubmission;