import React from 'react';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { handleTabChange, TabPanel } from 'Functions/MiscFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import InsightChip from 'Components/Common/Chips/InsightChip';
import ViewCalendar from './ViewCalendar/ViewCalendar';
import CalendarEventSearch from './CalendarEventSearch/CalendarEventSearch';
import CalendarEventForm from './CalendarEventForm/CalendarEventForm';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const initialState = {
    access: {
        events: false,
        addEvent: false,
    },
    currentTab: 0,
    key: uuidv4(),
    tabTitle: {
        0: 'Company Calendar',
        1: 'My Calendar',
        2: 'Calendar Events',
    },
    tabText: {
        0: 'Showing all company calendar events',
        1: 'Showing my calendar events',
        2: 'Create and manage your calendar events',
    }
}

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Calendar']);
        Promise.all([
            API.access('search-calendar-events'),
            API.access('add-calendar-event')
        ])
        .then(([events, addEvent]) => {
            this.setState({
                ...this.state,
                access: {
                    addEvent: addEvent?.data?.has_access ?? false,
                    events: events?.data?.has_access ?? false,
                }
            })
        })
    }

    handleCallback = (type) => {
        this.setState({
            key: uuidv4()
        })
    }

    handleDeployAddEvent = () => {
        this.props.deployDialog(
            <CalendarEventForm callback={this.handleCallback} />,
            false,
            "Add Calendar Event",
            "standard",
            "sm"
        )
    }

    render() {
        const { classes, ui } = this.props;
        const { access, currentTab, key, isLoading, tabText, tabTitle } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            {tabTitle[currentTab]}
                                                        </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className="mr-1" style={{height: 41.49}}>
                                                <Button
                                                    variant="text"
                                                    disabled={!access.addEvent}
                                                    onClick={this.handleDeployAddEvent}
                                                >   
                                                    <FAIcon type="light" icon="plus-circle" size={15} button disabled={!access.addEvent} />
                                                    Add Calendar Event
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={0.5}>
                                                <Grid container justify="space-between" alignItems='center'>
                                                    <Grid item>
                                                        <InsightChip
                                                            icon="info-circle"
                                                            label={tabText[currentTab]}
                                                        />
                                                    </Grid>
                                                </Grid> 
                                            </Box>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <ViewCalendar key={key} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <ViewCalendar key={key} myEvents={true} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <CalendarEventSearch key={key} />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Calendar" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="My Calendar" />
                                    <Divider />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Calendar Events" />
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    dialog: state.dialog,
    ui: state.ui
})

const mapDispatchToProps = dispatch => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md') => dispatch(deployDialog(content, disableDialogContent, title, variant, size))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(Calendar));