import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'

import { 
    Box, 
    IconButton, 
    Grid,
} from '@material-ui/core';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

import { 
    base64ImgtoFile
} from 'Functions/MiscFunctions';

const Signature = ({fullWidth, handleSave, isSaved}) => {
    let ref = useRef();
    const handleSaveSignature = () => {
        if(ref?.current?.isEmpty()) {
            return;
        }
        handleSave(base64ImgtoFile(ref?.current?.getTrimmedCanvas?.()?.toDataURL?.('image/png')));
        ref?.current?.off?.();
    }
    const handleClearSignature = () => {
        ref?.current?.clear?.();
    }
    return (
        <>
            <Box bgcolor='#fff' border='1px solid #ddd' align='center'>
                <Box pt={1.5} pb={1} align='center'>
                    <SignatureCanvas
                        ref={ref}
                        clearOnResize={false}
                        canvasProps={{
                            width: '285',
                            height: 100,
                            style: {
                                border: '1px dashed #ddd',
                            }
                        }}
                    />
                </Box>
            </Box>
            <Box mt={2}>
                {!isSaved && (
                    <Grid container spacing={3}>
                        <Grid item xs/>
                        <Grid item>
                            <IconButton onClick={handleClearSignature} className="bg-error">
                                <FAIcon icon="times" button size={30} buttonPrimary noMargin />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleSaveSignature} className="bg-success">
                                <FAIcon icon="check" button size={30} buttonPrimary noMargin />
                            </IconButton>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                )}
            </Box>
        </>
    );
}

export default Signature;