import { isTablet } from 'Functions/MiscFunctions';

const offset = isTablet() ? 200 : 230;

const ViewPage = theme => ({
    main: {
        overflowY: 'auto',
        width: `calc(100% - ${offset}px)`
    },
    fullWidth: {
        overflowY: 'auto',
        width: '100%'
    },
    side: {
        backgroundColor: '#eee',
        borderLeft: '1px solid #ddd',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: offset
    },
    card: {
        background: 'transparent',
        width: 194,
        maxWidth: 194,
        overflow: 'hidden'
    },
    icons: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    overlay: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2)
    },
    stepLabel: {
        marginTop: '0!important',
        fontWeight: '400!important'
    },
    stepper: {
        padding: 0,
        backgroundColor: 'transparent!important'
    },
    tab: {
        color: '#000',
        opacity: 1,
        "&:hover": {
            background: '#fde9ea',
            cursor: 'pointer'
        }
    },
    tabs: {
        backgroundColor: '#eee',
        opacity: 1,
    },
    tabSelected: {
        color: '#ef3340'
    },
    td: {
        border: 'none',
        padding: 4
    },
});

export default ViewPage;