import React from 'react';

import Search from 'Components/Common/Search/Search';

const ProductBinLocations = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/productSales',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.desc,
                        dataRef: 'desc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Period Sales',
                        field: rowData => rowData.qty,
                        dataRef: 'qty',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Buy Price',
                        field: rowData => rowData.ubp,
                        fieldFormat: 'currency',
                        dataRef: 'ubp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Trade Price',
                        field: rowData => rowData.usp,
                        fieldFormat: 'currency',
                        dataRef: 'usp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.sup,
                        dataRef: 'sup',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Purchase Ref',
                        field: rowData => rowData.sgr,
                        dataRef: 'sgr',
                        important: true,
                        sizeToContent: true
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'products-sales',
                noResultsText: 'Sorry, no products to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Products'],
                pdf: true,
                persistenceId: 'productSales',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showBrand: true,
                showCategory: true,
                showCustomer: true,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showRange: true,
                showSearch: true,
                showSupplier: true,
                showTotals: false,
                title: 'Products Sales Report'
            }}
        />
    )
}

export default ProductBinLocations;