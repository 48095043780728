import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const NewAndLapsedOrders = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/newAndLapsedOrder',
                noAutoLoad: true,
                columns: [ 
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.cr,
                        dataRef: 'cr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.cc,
                        dataRef: 'cc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Proactive Responsibility',
                        field: rowData => (staff && staff[rowData.pr] && <StaffChip staff={staff[rowData.pr]} />) || '-',
                        dataRef: 'pr',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },
                    {
                        heading: 'Type',
                        field: rowData => rowData.ty,
                        dataRef: 'ty',
                        sizeToContent: true
                    },
                    {
                        heading: 'Order Number',
                        field: rowData => rowData.orf,
                        dataRef: 'orf',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Order Date',
                        field: rowData => rowData.oca,
                        dataRef: 'oca',
                        fieldFormat: 'date',
                        sizeToContent: true
                    },  
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.ont,
                        fieldFormat: 'currency',
                        dataRef: 'ont',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Ordered By',
                        field: rowData => (staff && staff[rowData.osi] && <StaffChip staff={staff[rowData.osi]} />) || '-',
                        dataRef: 'osi',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'new-lapsed-orders',
                noResultsText: 'No results were found for this date period',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Sales'],
                pdf: true,
                persistenceId: 'newAndLapsedOrders',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'New and Lapsed Orders'
            }}
        />
    )
}

export default NewAndLapsedOrders;