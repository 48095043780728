import React, { useState }from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';

import Search from 'Components/Common/Search/Search';

import API from 'API';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { capitalize } from 'lodash';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import ReviewOnline from './ReviewOnline';
import ReviewCredit from './ReviewCredit';
import ReviewTrade from './ReviewTrade';
import StatusChip from 'Components/Common/Chips/StatusChip';

const TradeZoneRequests = ({pageTitle, type}) => {
    
    const [key, setKey] = useState(uuidv4());

    const dispatch = useDispatch(); 

    const staff = useSelector(state => state.notifications.status);

    const handleReviewRequest = (id, type) => {
        API.get(`/customers/onboarding/${id}`)
        .then(res => {
            if(res?.data?.success) {
                const callback = () => {
                    dispatch(closeDialog());
                    setKey(uuidv4());
                }
                const Element = type === 'ONLINE_ACCOUNT' ? ReviewOnline : type === 'CREDIT_ACCOUNT' ? ReviewCredit : ReviewTrade;
                const title = type === 'ONLINE_ACCOUNT' ? 'Review Online Account Request' : type === 'CREDIT_ACCOUNT' ? 'Review Credit Account Request' : 'Review Portal Request';
                dispatch(deployDialog(<Element id={id} callback={callback} request={res.data} />, false, title, 'standard', 'md'))
            }
        })
    }

    const handleInProgressUser = (id, ps) => {
        dispatch(
            deployConfirmation(`Are you sure you want to ${ps > 0 ? `remove the review flag from this request?` : `mark this request as under review?`}`, () => {
                API.put(`/customers/onboarding/${id}/pending`).then((res) => {
                    if (res?.data?.success) {
                        dispatch(deploySnackBar(`success`, `You have successfully ${ps > 0 ? `cleared the review flag for this request` : `marked this request as under review`}`));
                        setKey(uuidv4());
                    }
                });
            })
        );
    };
    
    const handleDelete = (id) => {
        const action = () => {
            API.put(`/customers/onboarding/${id}/delete`)
            .then(res => {
                if(res?.data) {
                    dispatch(deploySnackBar(`success`, `You have successfully deleted this request`))
                    setKey(uuidv4());
                }
            })
        }
        dispatch(deployConfirmation(`Are you sure you want to delete this request?`, action))
    }

    const handleMigrate = (id) => {
        const action = () => {
            API.put(`/customers/onboarding/${id}/migrate`)
            .then(res => {
                if(res?.data) {
                    dispatch(deploySnackBar(`success`, `You have successfully changed this request to an online account`))
                    setKey(uuidv4());
                }
            })
        }
        dispatch(deployConfirmation(`Are you sure you want to change this request to an online account request?`, action))
    }

    const columns = type === 'TRADE_ACCOUNT' ? 
    [
        {
            heading: 'Actioned',
            field: rowData => rowData.cp,
            fieldFormat: 'boolean',
            important: true,
            alignment: 'center'
        },
        {
            heading: 'Accepted',
            field: rowData => rowData.ac,
            fieldFormat: 'boolean',
            important: true,
            alignment: 'center'
        },
        {
            heading: 'Requested',
            field: rowData => rowData.ca,
            fieldFormat: 'datetime',
        },
        {
            heading: 'Request Type',
            field: rowData => capitalize(rowData.ty.replace(/_/g, ' ')),
            important: true,
        },
        {
            heading: 'Credit Facility',
            field: rowData => rowData.cf,
            important: true,
        },
        {
            heading: 'Company',
            field: rowData => rowData.cn,
            important: true,
        },
        {
            heading: 'Name',
            field: rowData => rowData.un,
            important: true,
        },
        {
            heading: 'E-mail',
            field: rowData => rowData.ue,
            important: true,
        },
        {
            heading: 'IP Address',
            field: rowData => rowData.ip,
            important: true,
        },
        {
            heading: 'Under Review By',
            field: rowData => rowData.ps === 0 ? '-' : <StaffChip staff={staff[rowData.ps]} />,
            fieldFormat: 'staff',
            important: true,
        },
        {
            heading: 'Actioned By',
            field: rowData => rowData.as === 0 ? '-' : <StaffChip staff={staff[rowData.as]} />,
            fieldFormat: 'staff',
            important: true,
        },
        {
            heading: 'Status',
            field: rowData => <StatusChip status={rowData.de ? `Deleted` : rowData.cp ? rowData.ac ? 'Accepted' : 'Declined' : rowData.ci ? 'Account Opening' : 'New'} />
        },
        {
            actions: rowData => {
                return [
                    { name: rowData.ps > 0 ? "Remove Review Flag" : "Mark as Under Review", icon: rowData.ps > 0 ? "user-slash" : "user", onClick: (rowData) => handleInProgressUser(rowData.id, rowData.ps), disabled: rowData.cp === 1},
                    {name: 'Review', icon: 'binoculars', onClick: () => handleReviewRequest(rowData.id, rowData.ty)},
                    {name: 'Change to Online Request', icon: 'arrow-up-arrow-down', onClick: () => handleMigrate(rowData.id)},
                    {name: 'Delete', icon: 'trash-alt', onClick: () => handleDelete(rowData.id)},
                ]
            }
        }
    ] 
    : 
    [
        {
            heading: 'Actioned',
            field: rowData => rowData.cp,
            fieldFormat: 'boolean',
            important: true,
            alignment: 'center'
        },
        {
            heading: 'Accepted',
            field: rowData => rowData.ac,
            fieldFormat: 'boolean',
            important: true,
            alignment: 'center'
        },
        {
            heading: 'Requested',
            field: rowData => rowData.ca,
            fieldFormat: 'datetime',
        },
        {
            heading: 'Request Type',
            field: rowData => capitalize(rowData.ty.replace(/_/g, ' ')),
            important: true,
        },
        {
            heading: 'Company',
            field: rowData => rowData.cn,
            important: true,
        },
        {
            heading: 'Name',
            field: rowData => rowData.un,
            important: true,
        },
        {
            heading: 'E-mail',
            field: rowData => rowData.ue,
            important: true,
        },
        {
            heading: 'IP Address',
            field: rowData => rowData.ip,
            important: true,
        },
        {
            heading: 'Under Review By',
            field: rowData => rowData.ps === 0 ? '-' : <StaffChip staff={staff[rowData.ps]} />,
            fieldFormat: 'staff',
            important: true,
        },
        {
            heading: 'Actioned By',
            field: rowData => rowData.as === 0 ? '-' : <StaffChip staff={staff[rowData.as]} />,
            fieldFormat: 'staff',
            important: true,
        },
        {
            heading: 'Status',
            field: rowData => <StatusChip status={rowData.de ? `Deleted` : rowData.cp ? rowData.ac ? 'Accepted' : 'Declined' : 'New'} />
        },
        {
            actions: rowData => {
                return [
                    { name: rowData.ps > 0 ? "Remove Review Flag" : "Mark as Under Review", icon: rowData.ps > 0 ? "user-slash" : "user", onClick: (rowData) => handleInProgressUser(rowData.id, rowData.ps), disabled: rowData.cp === 1},
                    {name: 'Review', icon: 'binoculars', onClick: () => handleReviewRequest(rowData.id, rowData.ty)},
                    {name: 'Delete', icon: 'trash-alt', onClick: () => handleDelete(rowData.id)},
                ]
            }
        }
    ];

    return (
        <Search
            key={type}
            innerKey={key}
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiParams: {
                    type
                },
                apiRoute: '/customers/onboarding',
                columns,
                dataRef: false,
                excel: false,
                key: 'id',
                name: 'cust-onboarding',
                pagination: true,
                pageTitleData: type === 'ONLINE_ACCOUNT' ? [0, 'Marketing', 'Portal Requests'] : type === 'CREDIT_ACCOUNT' ? [0, 'Finance', 'Credit Applications'] : [0, 'Customers', 'Customer Onboarding'],
                pdf: false,
                persistenceId: null,
                placeholderData: false,
                print: false,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showActioned: true,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Customer Onboarding Requests'
            }}
        />
    )
}

export default TradeZoneRequests;