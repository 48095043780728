import * as SnackBarActionTypes from 'Redux/Actions/SnackBar/Types/Types';
import uuidv4 from 'uuid/v4';

const initialState = () => ({    
    key: uuidv4(),
    message: "",
    variant: ""
});

const SnackBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SnackBarActionTypes.DEPLOY_SNACKBAR:
            return {
                ...state,
                key: uuidv4(),
                message: action.payload.message,
                variant: action.payload.variant
            };
        default:
            return state;
    }
}

 export default SnackBarReducer;