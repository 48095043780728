import React  from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { handleChange } from 'Functions/FormFunctions';

const initialState = () => ({
    confirmation: {
        cancel: false
    },
    formData: {
        id: '',
        name: '',
    },
    formErrors: {}
})

class BrandDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
    }

    componentDidMount = () => {
        this.configureComponent();
    }
   
    componentDidUpdate = prevProps => {
        if(JSON.stringify(this.props.formData) !== JSON.stringify(prevProps.formData)) {
            this.configureComponent();
        }
    }

    configureComponent = () => {
        const { formData, formErrors } = this.props;
        this.setState({
            formData: {
                ...this.state.formData,
                ...(formData || {}),
            },
            formErrors: formErrors ?? initialState().formErrors
        })
    }
    
    cancel = () => {
        this.props.handleCancel(true)
    }
    
    submit = () => {
        this.props.handleSubmit(this.state.formData)
    }

    render() {
        const { open, idx } = this.props;
        const { formErrors, formData } = this.state;
        return (
            <Dialog
                disableEscapeKeyDown
                disableBackdropClick
                open={open}
                maxWidth='sm' 
                scroll="body"
            >               
                <DialogTitle disableTypography>
                    <Typography variant="h6">
                        Brand Details
                    </Typography>
                </DialogTitle>
                <DialogContent style={{padding: '0 24px 24px 24px', minWidth: 400, maxWidth: 500}}>
                    <form noValidate autoComplete="off" onSubmit={this.submit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Brand Name *"
                                    value={formData.name}
                                    error={formErrors?.['supplierBrands|name|'+idx] && true}
                                    helperText={formErrors?.['supplierBrands|name|'+idx]}                                                
                                    onChange={this.handleChange}
                                    margin="none"
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button 
                                    onClick={this.cancel} 
                                    variant="text" 
                                    color="primary"
                                >
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                                <Button 
                                    disabled={formData.name === ''} 
                                    type="submit" 
                                    variant="text" 
                                    color="primary"
                                >
                                    <FAIcon icon="check" size={15} button />
                                    {idx === -1 ? 'Add' : 'Update'} Brand
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>        
        )
    }
}

export default BrandDialog;