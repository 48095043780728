import React from 'react';

import Search from 'Components/Common/Search/Search';

const AllocatedStockReport = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/allocatedStock',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true,
                        showTotalLabel: true
                    },
                    {
                        heading: 'Product Description',
                        field: rowData => rowData.pd,
                        dataRef: 'pd',
                        important: true,
                    },
                    {
                        heading: 'Qty Allocated (In Stock)',
                        field: rowData => rowData.qa,
                        alignment: 'right',
                        dataRef: 'qa',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'Qty Allocated (On Order)',
                        field: rowData => rowData.qpa,
                        alignment: 'right',
                        dataRef: 'qpa',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'Qty Allocated (Total)',
                        field: rowData => rowData.qta,
                        alignment: 'right',
                        dataRef: 'qta',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'allocated-stock',
                noResultsText: 'Sorry, there is no allocated stock to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Stock'],
                pdf: true,
                persistenceId: 'allocatedStockReport',
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: true,
                showSearch: true,
                showStockStatus: true,
                showStockType: true,
                showSupplier: true,
                showTotals: false,
                title: 'Sales Order Margin'
            }}
        />
    )
}

export default AllocatedStockReport;