import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import DefaultAvatar from "Assets/Images/defaultAvatar.png";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import DeliverySummary from "Components/Sales/Misc/DeliverySummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Products from "Components/Common/Forms/QuoteOrderForm/Products";
import Textarea from "Components/Common/Inputs/Textarea";
import { CLOUDFRONT_URL } from "Constants";
import { closeDialog } from "Redux/Actions/Dialog/Dialog";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

const BuildOrderDelivery = ({ callback, id }) => {
    const initialState = () => ({
        isLoading: true,
        data: null,
        read: {
            notes: false,
            delivery: false,
            builds: false,
        },
        notes: "",
    });

    const [state, setState] = useState(initialState());

    const dispatch = useDispatch();

    const staff = useSelector((state) => state.notifications.status);

    const handleNotes = (e) => {
        let notes = e?.target?.value;
        setState((state) => ({
            ...state,
            notes,
        }));
    };

    const handleExpansionPanelClick = (type) => {
        if (!state.read[type]) {
            setState((state) => ({
                ...state,
                read: {
                    ...state.read,
                    [type]: true,
                },
            }));
        }
    };

    const handleSubmit = () => {
        API.put(`/technical/build/${id}`, { notes }).then((res) => {
            if (res?.data?.success) {
                callback?.();
                dispatch(deploySnackBar(`success`, `You have successfully marked this delivery as built and ready for despatch`));
                dispatch(closeDialog());
            }
        });
    };

    useEffect(() => {
        API.get(`/technical/build/${id}`).then((res) => {
            if (res?.data) {
                setState((state) => ({
                    ...state,
                    isLoading: false,
                    data: res.data,
                    read: {
                        ...state.read,
                        notes: _.isEmpty(res.data?.od?.order?.notes) ? true : false,
                    },
                }));
            }
        }); /* eslint-disable-next-line */
    }, []);

    const { data, isLoading, notes, read } = state;

    return (
        (isLoading && (
            <Box pt={10} pb={10}>
                <LoadingCircle />
            </Box>
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={9} xl={10} className="mt-1">
                    <Box style={{ height: "calc(100vh - 125px)", overflowY: "auto", overflowX: "hidden" }} p={0} pl={0} pr={0}>
                        <Box mr={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ExpansionPanel onClick={() => handleExpansionPanelClick("notes")} elevation={0} style={{ background: "#fff!important", opacity: "1!important", border: "1px solid #ddd", borderRadius: 0 }} disabled={_.isEmpty(_.filter(data?.od?.order?.notes))}>
                                        <ExpansionPanelSummary style={{ opacity: 1 }} expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                                            <Typography variant="h6" component="div">
                                                <FAIcon icon={read.notes ? "check-circle" : "exclamation-circle"} className={`mr-1 ${read.notes ? "textSuccess" : "textError"}`} size={20} button />
                                                Order Notes ({_.size(data?.od?.order?.notes)})
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className="pb-1">
                                            <Box>
                                                <Grid container spacing={3}>
                                                    {_.map(data?.od?.order?.notes, (note, idx) => (
                                                        <Grid item xs={12} key={idx}>
                                                            <Card
                                                                // classes={{ root: `${(note.t === "Important" && !noPriorityStyles && classes.cardPriority) || classes.card} ${note.for_team ? (userTeams.includes(note.for_team?.team_name ?? 0) ? classes.cardAttentionFao : "") : ""}` }}
                                                                elevation={0}
                                                            >
                                                                <Box>
                                                                    <CardHeader
                                                                        avatar={
                                                                            <Avatar alt={staff?.[note.s]?.name} src={staff?.[note.s]?.picture ? `${CLOUDFRONT_URL}${staff?.[note.s]?.picture}` : DefaultAvatar} style={{ width: 35, height: 35 }}>
                                                                                {staff?.[note.s]?.name?.charAt?.(0) ?? "A"}
                                                                            </Avatar>
                                                                        }
                                                                        className={"p-0"}
                                                                        subheader={undefined}
                                                                        title={
                                                                            <>
                                                                                {`${staff?.[note.s]?.name ?? "Unknown"} ${note.for_team ? `- FAO: ${note.for_team.team_name} Team` : ""} · `}
                                                                                {note.t === "Important" && (
                                                                                    <>
                                                                                        <Typography variant="caption" className="textError">
                                                                                            Priority Note
                                                                                        </Typography>
                                                                                        {` · `}
                                                                                    </>
                                                                                )}
                                                                                <Typography variant="caption" color="textSecondary">
                                                                                    {moment(note.c, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY HH:mm')}
                                                                                </Typography>
                                                                            </>
                                                                        }
                                                                    />
                                                                    <CardContent className="p-0 pt-2">
                                                                        {note?.v?.split?.("\n").map((item, key) => (
                                                                            <Typography key={key} variant="caption" component="div" paragraph>
                                                                                {item}
                                                                            </Typography>
                                                                        ))}
                                                                    </CardContent>
                                                                </Box>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                <Grid item xs={12}>
                                    <ExpansionPanel onClick={() => handleExpansionPanelClick("delivery")} elevation={0} style={{ background: "#fff!important", opacity: "1!important", border: "1px solid #ddd", borderRadius: 0 }}>
                                        <ExpansionPanelSummary style={{ opacity: 1 }} expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                                            <Typography variant="h6" component="div">
                                                <FAIcon icon={read.delivery ? "check-circle" : "exclamation-circle"} className={`mr-1 ${read.delivery ? "textSuccess" : "textError"}`} size={20} button />
                                                Delivery Overview
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Box>
                                                <DeliverySummary basic access={{}} od={data.od} order={data.od.order} idx={data.od.od_idx} />
                                            </Box>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                <Grid item xs={12}>
                                    <ExpansionPanel
                                        onClick={() => {
                                            if (!read.notes || !read.delivery) return;
                                            handleExpansionPanelClick("builds");
                                        }}
                                        elevation={0}
                                        style={{ background: "#fff!important", opacity: "1!important", border: "1px solid #ddd", borderRadius: 0 }}
                                        disabled={!read.notes || !read.delivery}
                                    >
                                        <ExpansionPanelSummary style={{ opacity: 1 }} expandIcon={read.notes && read.delivery ? <FAIcon type="light" icon="chevron-down" size="small" noMargin button /> : undefined}>
                                            <Typography variant="h6" component="div">
                                                <FAIcon icon={read.builds ? `check-circle` : read.notes && read.delivery ? "exclamation-circle" : "lock-alt"} className={`mr-1 ${read.builds ? `textSuccess` : read.notes && read.delivery ? "textInfo" : "textError"}`} size={20} button />
                                                Builds ({_.size(_.filter(data.od.products, (el) => el.reqTech === 1 && el.parent === 0))})
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Box>
                                                {_.map(
                                                    _.filter(data.od.products, (el) => el.reqTech === 1 && el.parent === 0),
                                                    (_el, idx) => (
                                                        <Box key={idx} p={2}>
                                                            <Typography variant="h6">
                                                                Build {idx + 1} of {_.size(_.filter(data.od.products, (el) => el.reqTech === 1 && el.parent === 0))}
                                                            </Typography>
                                                            <Products access={{}} basic context="build" inline={true} rows={_.filter(data.od.products, (__el) => __el.i === _el.i || __el.parent === _el.i)} od={data.od} readOnly />
                                                        </Box>
                                                    )
                                                )}
                                            </Box>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                {read.builds && (
                                    <>
                                        <Grid item xs={12}>
                                            <Box className="content-light-white" p={3}>
                                                <Typography variant="h6" paragraph>
                                                    Build Notes
                                                </Typography>
                                                <Textarea margin="none" onChange={handleNotes} value={notes} variant="outlined" rows={3} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} align="right">
                                            <Button
                                                className={`btn btn-success`}
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    dispatch(
                                                        deployConfirmation(
                                                            <>
                                                                <Typography variant="body2" className="fw-400">
                                                                    Are you sure you want to release this delivery for despatch?
                                                                </Typography>
                                                                <Typography variant="body2">Please return the parts to the warehouse for despatch</Typography>
                                                            </>,
                                                            handleSubmit
                                                        )
                                                    )
                                                }
                                                size="large"
                                            >
                                                <FAIcon icon="check" size={15} buttonPrimary />
                                                Build Complete
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={3} xl={2} className="content-light-white" style={{ marginTop: 19 }}>
                    <Box style={{ maxHeight: "calc(100vh - 125px)", overflowY: "auto", overflowX: "hidden" }} p={2} pt={1} pl={0} pr={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" className="fw-400">
                                    Sales Order #{data.od?.od_reference}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className="fw-400">
                                    Delivery
                                </Typography>
                                <Box pt={1}>
                                    <Typography variant="body2" gutterBottom>
                                        {data.od?.od_reference}/{data.od?.od_idx}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className="fw-400">
                                    Company
                                </Typography>
                                <Box pt={1}>
                                    <Typography variant="body2" gutterBottom>
                                        {data.od?.customer?.cust_company_name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className="fw-400">
                                    Customer Reference
                                </Typography>
                                <Box pt={1}>
                                    <Typography variant="body2" gutterBottom>
                                        {data.od?.od_customer_reference}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className="fw-400">
                                    Billing Contact
                                </Typography>
                                <Box pt={1}>
                                    <Typography variant="body2" gutterBottom>
                                        {data.od?.od_contact_name ?? "Not Set"}
                                    </Typography>
                                    {!_.isEmpty(data.od?.customer_contact?.contact_telephone) && (
                                        <Typography variant="caption" component="div" gutterBottom>
                                            {data.od?.customer_contact?.contact_telephone}
                                        </Typography>
                                    )}
                                    {!_.isEmpty(data.od?.customer_contact?.contact_mobile) && (
                                        <Typography variant="caption" component="div" gutterBottom>
                                            {data.od?.customer_contact?.contact_mobile}
                                        </Typography>
                                    )}
                                    {!_.isEmpty(data.od?.customer_contact?.contact_email) && (
                                        <Typography variant="caption" component="div">
                                            {data.od?.customer_contact?.contact_email}
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className="fw-400">
                                    Order Email
                                </Typography>
                                <Box pt={1}>
                                    <Typography variant="body2" gutterBottom>
                                        {!_.isEmpty(data.od?.order?.order_email) ? data.od?.order?.order_email : "Not Provided"}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className="fw-400">
                                    Order Phone
                                </Typography>
                                <Box pt={1}>
                                    <Typography variant="body2" gutterBottom>
                                        {!_.isEmpty(data.od?.order?.order_phone) ? data.od?.order?.order_phone : "Not Provided"}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    );
};

export default BuildOrderDelivery;
