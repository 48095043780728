import React, { useEffect, useState } from 'react';
// import _ from 'lodash';
import { useSelector } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import API from 'API';
// import Alert from 'Components/Common/Alert/Alert';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const initialState = {
    isLoading: true
}

const Support = ({pageTitle}) => {

    let [state, setState] = useState(initialState);
    const { isLoading } = state;
    const ui = useSelector(state => state.ui);
    const classes = makeStyles(ViewPage);

    useEffect(() => {
        pageTitle([0, 'Support']);
        API.get('/access/all', 
            { 
                params: { 
                    noChildren: true
                }
            }
        )
        .then(result => {
            if(result?.data) {
                setState(state => ({
                    ...state,
                    access: result.data,
                    isLoading: false
                }))
            }
        })
    }, [pageTitle]);

    const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
    const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);

    return (
        <Grid container style={{margin: -24, width: 'initial'}}>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>  
                    <Grid item xs={12}>
                        <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                            <Box pt={1} pr={1} pb={1} pl={3}>
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Typography variant="h4" className="textDefault">
                                                    Help and Support
                                                </Typography>  
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{height: 41.49}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={0.5}>
                                        <Grid container justify="space-between" alignItems='center'>
                                            <Grid item>
                                                <InsightChip
                                                    icon="info-circle"
                                                    label={'You can find useful how to guides below and contact details for support from Crushed Ice'}
                                                />
                                            </Grid>
                                        </Grid> 
                                    </Box>
                                </Grid>
                            </Box>
                        </AppBar>
                    </Grid>   
                    <Grid item className={classes.fullWidth} style={{height: (contentHeight - 90)}} xs={12}>
                        <Grid container>
                            <Grid item xs={12}>  
                                <Box p={3}>
                                    <Grid container spacing={3}>
                                        {/* <Grid item xs={12} md={6} lg={7}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>                                            
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs>
                                                                        <Typography variant="h6">
                                                                            Help Guides
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Alert severity="warning" variant="outlined">
                                                                    The help guides are not currently available to view
                                                                </Alert>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={3}>                                        
                                                                    {_.map(_.filter(access, function(el) { return el.access_parent_id === 0 }), (item, idx) => (
                                                                        <Grid item xs={6} key={idx}>
                                                                            <Grid container alignItems='center'>
                                                                                <Grid item>
                                                                                    <FAIcon type="light" icon={item.access_icon} size="small" />
                                                                                </Grid>
                                                                                <Grid item xs>
                                                                                    <Typography variant="body2">
                                                                                        {item.access_name}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                        {/* <Grid item xs={12} md={6} lg={5}> */}
                                            {/* <Grid container spacing={{3}> */}
                                                <Grid item xs={12} lg={6}>
                                                    <PaddedPaper>   
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Support Help Desk
                                                                </Typography>    
                                                                <Typography variant="body2" className="fw-400" component="div" gutterBottom>
                                                                    For help and support, please contact Crushed Ice
                                                                </Typography>   
                                                                <Typography variant="caption">
                                                                    <em>Available Monday to Friday between 09:00 - 17:30</em>
                                                                </Typography>    
                                                            </Grid>
                                                            <Grid item xs={12}> 
                                                                <Grid container spacing={1} alignItems="center">
                                                                    <Grid item>
                                                                        <FAIcon type="thin" icon="phone" noMargin />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Typography variant="body2">
                                                                            01933 664 344
                                                                        </Typography>  
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1} alignItems="center">
                                                                    <Grid item>
                                                                        <FAIcon type="thin" icon="envelope" noMargin />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Typography variant="body2">
                                                                            <Link href="mailto:support@crushedice.biz" target="_blank">support@crushedice.biz</Link>
                                                                        </Typography>  
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <PaddedPaper>   
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Out Of Hours Support
                                                                </Typography>    
                                                                <Typography variant="body2" className="fw-400" component="div" gutterBottom>
                                                                    For help and support, please contact Ryan Stanyer
                                                                </Typography>  
                                                                <Typography variant="caption">
                                                                    <em>Please leave a message if unavailable</em>
                                                                </Typography>   
                                                            </Grid>
                                                            <Grid item xs={12}> 
                                                                <Grid container spacing={1} alignItems="center">
                                                                    <Grid item>
                                                                        <FAIcon type="thin" icon="mobile" noMargin />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Typography variant="body2">
                                                                            07551 793616
                                                                        </Typography>  
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1} alignItems="center">
                                                                    <Grid item>
                                                                        <FAIcon type="thin" icon="envelope" noMargin />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Typography variant="body2">
                                                                            <Link href="mailto:support@crushedice.biz" target="_blank">support@crushedice.biz</Link>
                                                                        </Typography>  
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                {/* </Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>                    
                </>
            )}
        </Grid>
    )
}

export default Support;