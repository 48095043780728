import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import API from 'API';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import InsightChip from 'Components/Common/Chips/InsightChip';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Products from 'Components/Common/Forms/QuoteOrderForm/Products';
import StackTable from 'Components/Common/DataTables/StackTable';
import Totals from 'Components/Common/Forms/QuoteOrderForm/Totals';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';

import { isCrushedIce } from 'Functions/AuthFunctions';
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { FormControl } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { InputAdornment } from '@material-ui/core';

const useStyles = makeStyles({
    tab: {
        color: '#fff',
        opacity: 1
    },
    td: {
        border: 'none',
        padding: 4
    }
})

const ChangeCustomerDate = ({ callback, order }) => {
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        API.put(`/sales/${order.order_id}/changeRequestedDate`, { date }).then((res) => {
            if (res?.data) {
                if (res.data?.errors) {
                    dispatch(deploySnackBar("error", "Something went wrong, please try again"));
                } else {
                    dispatch(deploySnackBar("success", "The customer requested date for this order has been successfully been changed"));
                }

                callback?.();
                dispatch(closeDialog());
            }
        });
    };

    const hasCallOff = order.order_requested_delivery_date !== "0000-00-00";

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} align="center">
                <Box pt={4}>
                    <FAIcon icon="calendar-check" type="thin" size="xlarge" noMargin />
                </Box>
            </Grid>
            <Grid item xs={12} align="center">
                <Typography variant="h6">Change Customer Requested Date</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box ml={4} mr={4} mt={2} mb={3}>
                    <Box className="content-light-grey" p={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align="center">
                                <Typography variant="h6" paragraph>
                                    Sales Order #{order.order_reference}
                                </Typography>
                                <Typography variant="body2" className={`fw-400 ${hasCallOff ? 'textError' : ''}`}>
                                    Current customer requested date: 
                                    <br />
                                    {!hasCallOff ? "Not Set" : moment(order.order_requested_delivery_date).format("DD/MM/YYYY")}
                                </Typography>                            
                            </Grid>
                            <Grid item xs={12} align="center">
                                <FormControl fullWidth style={{ width: 165 }}>
                                    <DatePicker
                                        placeholder="None"
                                        type="date" 
                                        value={date} 
                                        onChange={(date) => setDate(moment(date).format("YYYY-MM-DD"))} 
                                        inputVariant="filled"
                                        disablePast
                                        autoOk 
                                        endAdornment={date && (
                                            <InputAdornment>
                                                <Tooltip title="Clear Date">
                                                    <IconButton onClick={(e) => { e?.stopPropagation?.(); setDate(null);}} size="small">
                                                        <FAIcon icon="times" size={15} type="solid" disabled noMargin button />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <Button onClick={() => dispatch(closeDialog())} variant="text">
                            <FAIcon icon="times" size={15} button />
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() =>
                                dispatch(
                                    deployConfirmation(
                                        <>
                                            <Typography variant="body2">Are you sure you want to {date === null ? 'remove' : 'set'} the customer requested date{date ? ` to ${moment(date).format("DD/MM/YYYY")}` : ``}?</Typography>
                                        </>,
                                        () => handleSubmit()
                                    )
                                )
                            }
                            variant="text"
                            color="primary"
                        >
                            <FAIcon icon="check" size={15} button />
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const OrderSummary = ({access, callback, inDialog, order}) => { 

    const history = useHistory();

    const dispatch = useDispatch();

    let customerAddress = [
            order.order_address_line_one,
            order.order_address_line_two,
            order.order_address_line_three,
            order.order_address_town,
            order.order_address_county,
            order.order_address_postcode,
            order.order_address_country
        ],
        deliveryAddress = [
            order.order_delivery_address_line_one,
            order.order_delivery_address_line_two,
            order.order_delivery_address_line_three,
            order.order_delivery_address_town,
            order.order_delivery_address_county,
            order.order_delivery_address_postcode,
            order.order_delivery_address_country
        ],
        customerAddressEmpty = true, 
        deliveryAddressEmpty = true,
        classes = useStyles();

    _.each(deliveryAddress, line => {
        if(line && line.length > 0) {
            deliveryAddressEmpty = false;
            return false;
        }
    })

    _.each(customerAddress, line => {
        if(line && line.length > 0) {
            customerAddressEmpty = false;
            return false;
        }
    })

    const handleChangeCustomerDate = () => {
        dispatch(deployDialog(<ChangeCustomerDate callback={callback} order={order} />, false, "", "standard", "xs"));
    };
    
    const handlePopUpToPage = () => {
        history.push(`/sales/${order.order_id}`)
        dispatch(closeDialog())        
    }

    return (
        <PaddedPaper>
            <Grid container alignItems='center'>
                <Grid item xs={12} lg>
                    <Heading text={`Sales Order #${order.order_reference}${isCrushedIce() ? ` [ci:order_id:${order.order_id}]` : ``}`} />
                </Grid>
                <Grid item>
                    <InsightChip 
                        // background='#ef3340'
                        // color='#fff'
                        icon="check"
                        label={order.order_status}
                        paddingLeft={8}
                        large
                    />
                </Grid>
                {inDialog && (
                    <>
                        <Grid item>
                            <Tooltip title="Open Sales Order">
                                <IconButton
                                    onClick={handlePopUpToPage}
                                >
                                    <FAIcon icon="binoculars" size={19} button noMargin />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Box width={50} />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Grid container justify="flex-end">
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                noBg
                                elevation={0}
                                padding={0}
                                rows={[ 
                                    {
                                        heading: "Company Name",
                                        value: order?.customer?.cust_company_name ?? '-'
                                    }, 
                                    {
                                        heading: "Order Contact",
                                        disabled: !order.order_contact_name,
                                        value: (
                                            <>
                                                <Typography variant="body2" gutterBottom>
                                                    {order.order_contact_name ?? 'Not Set'}
                                                </Typography>
                                                {!_.isEmpty(order.customer_contact?.contact_telephone) && (
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        <FAIcon icon="phone" size={12} />
                                                        {order.customer_contact?.contact_telephone}
                                                    </Typography>
                                                )}
                                                {!_.isEmpty(order.customer_contact?.contact_mobile) && (
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        <FAIcon icon="mobile" size={12} />
                                                        {order.customer_contact?.contact_mobile}
                                                    </Typography>
                                                )}
                                                {!_.isEmpty(order.customer_contact?.contact_email) && (
                                                    <Typography variant="caption" component="div">
                                                        <FAIcon icon="envelope" size={12} />
                                                        {order.customer_contact?.contact_email}
                                                    </Typography>
                                                )}
                                            </>
                                        )
                                    },
                                    {
                                        heading: "Billing Address",
                                        disabled: customerAddressEmpty,
                                        value: _.map(customerAddress, (line, idx) => {
                                            if(line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component='div'>
                                                        {line}
                                                    </Typography>
                                                )
                                            }
                                        })
                                    }
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                noBg
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: "Delivery Company",
                                        disabled: !order.order_delivery_company,
                                        value: order.order_delivery_company
                                    }, 
                                    {
                                        heading: "Delivery Contact",
                                        disabled: !order.order_delivery_contact_name,
                                        value: (
                                            <>
                                                <Typography variant="body2" gutterBottom>
                                                    {order.order_delivery_contact_name ?? 'Not Set'}
                                                </Typography>
                                                {!_.isEmpty(order.delivery_contact?.contact_telephone) && (
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        <FAIcon icon="phone" size={12} />
                                                        {order.delivery_contact?.contact_telephone}
                                                    </Typography>
                                                )}
                                                {!_.isEmpty(order.delivery_contact?.contact_mobile) && (
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        <FAIcon icon="mobile" size={12} />
                                                        {order.delivery_contact?.contact_mobile}
                                                    </Typography>
                                                )}
                                                {!_.isEmpty(order.delivery_contact?.contact_email) && (
                                                    <Typography variant="caption" component="div">
                                                        <FAIcon icon="envelope" size={12} />
                                                        {order.delivery_contact?.contact_email}
                                                    </Typography>
                                                )}
                                            </>
                                        )
                                    }, 
                                    {
                                        heading: "Delivery Address",
                                        disabled: deliveryAddressEmpty,
                                        value: _.map(deliveryAddress, (line, idx) => {
                                            if(line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component='div'>
                                                        {line}
                                                    </Typography>
                                                )
                                            }
                                        })
                                    },  
                                    {
                                        heading: "Delivery Instructions",
                                        disabled: _.isEmpty(order.order_delivery_address_notes),
                                        value: (
                                            <Typography variant="body2" component='div'>
                                                {order.order_delivery_address_notes}
                                            </Typography>
                                        )
                                    }    
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                noBg
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: "Customer Requested Date",
                                        value: (
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={12}>
                                                    {order.order_requested_delivery_date === "0000-00-00" ? 'N/A' : moment(order.order_requested_delivery_date).format("DD/MM/YYYY")}
                                                </Grid>
                                                {access?.processOrder && order.order_status !== "Invoiced" && order.order_status !== "Cancelled" && (
                                                    <>
                                                        <Grid item>
                                                            <InsightChip icon="calendar-day" label={`Change`} color="#ef3340" textDefault onClick={handleChangeCustomerDate} paddingLeft={8} />
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        ) 
                                    },
                                    {
                                        heading: "Delivery Method",
                                        disabled: !order.delivery_carriage,
                                        value: `${order.delivery_carriage?.courier?.courier_name} ${order.delivery_carriage?.courier_conf_service} - ${order.order_total_carriage === '0.00' ? 'Free' : `£${order.order_total_carriage}`}`
                                    },
                                    {
                                        heading: "Order E-mail Address",
                                        value: order?.order_email ?? '-'
                                    },
                                    {
                                        heading: "Order Phone",
                                        value: order?.order_phone ?? '-'
                                    }
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} justify="flex-end">
                                <Grid item xs={12}>
                                    <Products   
                                        classes={classes}
                                        inline={true}
                                        customer={order.order_customer_id}
                                        rows={order.products}
                                        discountTotal={order.order_total_discount}
                                        subTotal={order.order_total_sub}
                                        netTotal={order.order_total_net}
                                        carriageTotal={order.order_total_carriage}
                                        vatTotal={order.order_total_vat}
                                        grossTotal={order.order_total_gross}
                                        readOnly
                                        order={order}
                                        status={order.order_status}
                                        hasDeliveries={!_.isEmpty(order.deliveries)}
                                        showAllocationStatus
                                        showDespatchedAndInvoiced
                                        useAccurateMargins
                                        // showAssignedDelivery
                                    />
                                </Grid>
                                {!_.isEmpty(order.order_notes) && (
                                    <Grid item xs={12}>
                                        <Box pt={2} pl={2} pr={2}>
                                            {order.order_notes.split('\n').map((item, key) => (
                                                <Typography variant="body2" key={key} align="center" className="fw-400" gutterBottom>{item}</Typography>
                                            ))}
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item xs={12} lg={4}>
                                    <Totals
                                        classes={classes}
                                        subTotal={order.order_total_sub}
                                        discountTotal={order.order_total_discount}
                                        carriageTotal={order.order_total_carriage}
                                        netTotal={order.order_total_net}
                                        vatTotal={order.order_total_vat}
                                        grossTotal={order.order_total_gross}
                                        totalMargin={order.order_total_margin}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PaddedPaper>
    );
}

export default OrderSummary;