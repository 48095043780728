import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Heading from 'Components/Common/Heading/Heading';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Products from 'Components/Common/Forms/QuoteOrderForm/Products';
import StackTable from 'Components/Common/DataTables/StackTable';
import Totals from 'Components/Common/Forms/QuoteOrderForm/Totals';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

import { isCrushedIce } from 'Functions/AuthFunctions';
import { closeDialog } from "Redux/Actions/Dialog/Dialog";

const useStyles = makeStyles({
    tab: {
        color: '#fff',
        opacity: 1
    },
    td: {
        border: 'none',
        padding: 4
    }
})

const QuoteSummary = ({inDialog, quote}) => { 

    const history = useHistory();

    const dispatch = useDispatch();

    let customerAddress = [
            quote.quote_address_line_one,
            quote.quote_address_line_two,
            quote.quote_address_line_three,
            quote.quote_address_town,
            quote.quote_address_county,
            quote.quote_address_postcode,
            quote.quote_address_country
        ],
        deliveryAddress = [
            quote.quote_delivery_address_line_one,
            quote.quote_delivery_address_line_two,
            quote.quote_delivery_address_line_three,
            quote.quote_delivery_address_town,
            quote.quote_delivery_address_county,
            quote.quote_delivery_address_postcode,
            quote.quote_delivery_address_country
        ],
        customerAddressEmpty = true, 
        deliveryAddressEmpty = true,
        classes = useStyles();

    _.each(deliveryAddress, line => {
        if(line && line.length > 0) {
            deliveryAddressEmpty = false;
            return false;
        }
    })

    _.each(customerAddress, line => {
        if(line && line.length > 0) {
            customerAddressEmpty = false;
            return false;
        }
    })

    const handlePopUpToPage = () => {
        history.push(`/quotes/${quote.quote_id}`)
        dispatch(closeDialog())        
    }
    
    return (
        <PaddedPaper>
                <Grid container justify="flex-end">
                    <Grid item xs={12}>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Heading text={`Quote #${quote.quote_reference}${isCrushedIce() ? ` [ci:quote_id:${quote.quote_id}]` : ``}`} />
                            </Grid>
                            {inDialog && (
                                <>
                                    <Grid item>
                                        <Tooltip title="Open Quote">
                                            <IconButton
                                                onClick={handlePopUpToPage}
                                            >
                                                <FAIcon icon="binoculars" size={19} button noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Box width={50} />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <StackTable
                            elevation={0}
                            padding={0}
                            rows={[ 
                                {
                                    heading: "Company Name",
                                    value: quote?.customer?.cust_company_name ?? '-'
                                }, 
                                {
                                    heading: "Quote Contact",
                                    disabled: !quote.quote_contact_name,
                                    value: (
                                        <>
                                            <Typography variant="body2" gutterBottom>
                                                {quote.quote_contact_name ?? 'Not Set'}
                                            </Typography>
                                            {!_.isEmpty(quote.customer_contact?.contact_telephone) && (
                                                <Typography variant="caption" component="div" gutterBottom>
                                                    <FAIcon icon="phone" size={12} />
                                                    {quote.customer_contact?.contact_telephone}
                                                </Typography>
                                            )}
                                            {!_.isEmpty(quote.customer_contact?.contact_mobile) && (
                                                <Typography variant="caption" component="div" gutterBottom>
                                                    <FAIcon icon="mobile" size={12} />
                                                    {quote.customer_contact?.contact_mobile}
                                                </Typography>
                                            )}
                                            {!_.isEmpty(quote.customer_contact?.contact_email) && (
                                                <Typography variant="caption" component="div">
                                                    <FAIcon icon="envelope" size={12} />
                                                    {quote.customer_contact?.contact_email}
                                                </Typography>
                                            )}
                                        </>
                                    )
                                }, 
                                {
                                    heading: "Billing Address",
                                    disabled: customerAddressEmpty,
                                    value: _.map(customerAddress, (line, idx) => {
                                        if(line?.length > 0) {
                                            return (
                                                <Typography variant="body2" key={idx} component='div'>
                                                    {line}
                                                </Typography>
                                            )
                                        }
                                    })
                                }
                            ]}
                        /> 
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <StackTable
                            elevation={0}
                            padding={0}
                            rows={[
                                {
                                    heading: "Delivery Company",
                                    disabled: !quote.quote_delivery_company,
                                    value: quote.quote_delivery_company
                                }, 
                                {
                                    heading: "Delivery Contact",
                                    disabled: !quote.quote_delivery_contact_name,
                                    value: (
                                        <>
                                            <Typography variant="body2" gutterBottom>
                                                {quote.quote_delivery_contact_name ?? 'Not Set'}
                                            </Typography>
                                            {!_.isEmpty(quote.delivery_contact?.contact_telephone) && (
                                                <Typography variant="caption" component="div" gutterBottom>
                                                    <FAIcon icon="phone" size={12} />
                                                    {quote.delivery_contact?.contact_telephone}
                                                </Typography>
                                            )}
                                            {!_.isEmpty(quote.delivery_contact?.contact_mobile) && (
                                                <Typography variant="caption" component="div" gutterBottom>
                                                    <FAIcon icon="mobile" size={12} />
                                                    {quote.delivery_contact?.contact_mobile}
                                                </Typography>
                                            )}
                                            {!_.isEmpty(quote.delivery_contact?.contact_email) && (
                                                <Typography variant="caption" component="div">
                                                    <FAIcon icon="envelope" size={12} />
                                                    {quote.delivery_contact?.contact_email}
                                                </Typography>
                                            )}
                                        </>
                                    )
                                }, 
                                {
                                    heading: "Delivery Address",
                                    disabled: deliveryAddressEmpty,
                                    value: _.map(deliveryAddress, (line, idx) => {
                                        if(line?.length > 0) {
                                            return (
                                                <Typography variant="body2" key={idx} component='div'>
                                                    {line}
                                                </Typography>
                                            )
                                        }
                                    })
                                },
                                {
                                    heading: "Delivery Instructions",
                                    disabled: _.isEmpty(quote.quote_delivery_address_notes),
                                    value: (
                                        <Typography variant="body2" component='div'>
                                            {quote.quote_delivery_address_notes}
                                        </Typography>
                                    )
                                }    
                            ]}
                        /> 
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <StackTable
                            elevation={0}
                            padding={0}
                            rows={[
                                {
                                    heading: "Customer Requested Date",
                                    value: quote.quote_requested_delivery_date === "0000-00-00" ? 'N/A' : moment(quote.quote_requested_delivery_date).format("DD/MM/YYYY")
                                },
                                {
                                    heading: "Delivery Method",
                                    disabled: !quote.delivery_carriage,
                                    value: `${quote.delivery_carriage?.courier?.courier_name} ${quote.delivery_carriage?.courier_conf_service} (£${quote.quote_total_carriage})`
                                },
                                {
                                    heading: "Quote E-mail Address",
                                    value: quote?.quote_email ?? '-'
                                },
                                {
                                    heading: "Quote Phone",
                                    value: quote?.quote_phone ?? '-'
                                }
                            ]}
                        /> 
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} justify="flex-end">
                            <Grid item xs={12}>
                                <Products   
                                    classes={classes}
                                    customer={quote.quote_customer_id}
                                    inline={true}
                                    rows={quote.products}
                                    discountTotal={quote.quote_total_discount}
                                    subTotal={quote.quote_total_sub}
                                    netTotal={quote.quote_total_net}
                                    carriageTotal={quote.quote_total_carriage}
                                    vatTotal={quote.quote_total_vat}
                                    grossTotal={quote.quote_total_gross}
                                    readOnly
                                />
                            </Grid>
                            {!_.isEmpty(quote.quote_notes) && (
                                <Grid item xs={12}>
                                    <Box pt={2} pl={2} pr={2}>
                                        {quote.quote_notes.split('\n').map((item, key) => (
                                            <Typography variant="body2" key={key} align="center" gutterBottom>{item}</Typography>
                                        ))}
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={4}>
                                <Totals
                                    classes={classes}
                                    subTotal={quote.quote_total_sub}
                                    discountTotal={quote.quote_total_discount}
                                    carriageTotal={quote.quote_total_carriage}
                                    netTotal={quote.quote_total_net}
                                    vatTotal={quote.quote_total_vat}
                                    grossTotal={quote.quote_total_gross}
                                    totalMargin={quote.quote_total_margin}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </PaddedPaper>
    );
}

export default QuoteSummary;