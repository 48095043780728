import React  from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { handleSelectChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { Link } from '@material-ui/core';

const initialState = () => ({ 
    formData: {
        cancel: {},
        podAction: {},
        reason: '',
        type: 'order'
    },
    formErrors: [],
    isLoading: true,
    reasonList: []
})

class CancelOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleSelectChange = handleSelectChange.bind(this);
        this.poll = false;
    }

    componentDidMount = () => {
        
        this.handlePoll(true);
        this.poll = setInterval(this.handlePoll, 3000);

        const {
            order
        } = this.props;

        API.get('/sales/cancellation/reasons')
        .then(res => {

            let cancel          = {},
                reasonList      = [],
                isLoading       = false,
                { formData }    = this.state;

            if(res.data){
                reasonList = _.map(res.data, el => {
                    return _.assign({
                        value: el.or_id,
                        label: el.or_reason,
                    });
                });
            }

            if(!_.isEmpty(order.deliveries)) {
                _.forEach(order.deliveries, d => {
                    cancel = {
                        ...cancel,
                        [d.od_idx]: 0
                    }
                })
            }

            formData = {
                ...formData,
                cancel,
                type: _.isEmpty(order.deliveries) ? 'order' : order.order_ifm === 1 ? 'ifm' : 'deliveries'
            }

            this.setState({
                formData,
                reasonList,
                isLoading
            })

        });

    }

    handlePoll = initial => {
        API.post(`/staff/my/view`, 
            { 
                order: this.props.order?.order_id,
                type: 'Process'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {
                    const   update  = _.some(res.data, el => el.u === 1),
                            process = _.some(res.data, el => el.p === 1);

                    if(!_.isEmpty(res.data) && (update || process)) {
                        this.props.deploySnackBar("error", `Sorry, another user is currently ${update ? 'modifying' : 'processing'} this order`);
                        this.props.closeDialog();
                    }
                }
            }
        })
    }

    componentWillUnmount = () => {
        if(this.poll)
            clearInterval(this.poll);
    }

    handleCancelDelivery = odIdx => {

        const {
            deployConfirmation,
            order
        } = this.props;
        
        let cancel = {...this.state.formData?.cancel};
        let podAction = {...this.state.formData?.podAction};

        cancel[odIdx] = cancel?.[odIdx] === 1 ? 0 : 1;

        let od = _.find(order.deliveries, el => el.od_idx === odIdx);

        if(cancel[odIdx] === 1) {

            if(!od) {
                return;
            }

            if(od.pod && (!od?.pod?.pod_ship_aa_only || (od?.pod?.pod_ship_aa_only && !od?.pod?.pod_delivered))) {

                podAction = {
                    ...podAction,
                    [od.pod.pod_id]: 'Cancel'
                }

                if(od?.pod?.pod_status === 'Invoiced') {
                    deployConfirmation(
                        <Box p={1}>
                            <Typography variant="body1" className="fw-400">
                                Purchase Order Delivery #{od.pod?.pod_reference ?? ''}/{parseInt(od.pod?.pod_idx ?? 0) < 10 ? '0' : ''}{od.pod?.pod_idx ?? ''}
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Fulfilled by {od.purchase_order?.supplier?.supp_company_name} 
                            </Typography>
                            <Box pb={2}>
                                <Alert severity="error">
                                    <strong>This purchase order delivery has already been invoiced</strong>
                                </Alert>
                            </Box>
                            <Typography variant="body2" className="fw-400">
                                Do you want to continue?
                            </Typography>
                        </Box>
                    , () => {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                cancel,
                                podAction
                            }
                        })
                    }
                    )
                } else {
                    deployConfirmation(
                        <Box p={1}>
                            <Typography variant="body1" className="fw-400">
                                Purchase Order Delivery #{od.pod?.pod_reference ?? ''}/{parseInt(od.pod?.pod_idx ?? 0) < 10 ? '0' : ''}{od.pod?.pod_idx ?? ''}
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Fulfilled by {od.purchase_order?.supplier?.supp_company_name} 
                            </Typography>
                            {!_.isEmpty(od.purchase_order?.supplier?.supp_phone) && (
                                <Typography component="div" variant="body2">
                                    <FAIcon icon='phone' size={14} />
                                    <Link href={`tel:${od.purchase_order?.supplier?.supp_phone}`}>
                                        {od.purchase_order?.supplier?.supp_phone}
                                    </Link>
                                </Typography>
                            )}
                            <Typography variant="body2" component="div" paragraph>
                                {(!_.isEmpty(od.purchase_order?.supplier?.supp_email) && (
                                    <>
                                        <FAIcon icon='envelope' size={14} />
                                        <Link href={`mailto:${od.purchase_order?.supplier?.supp_email}`}>
                                            {od.purchase_order?.supplier?.supp_email}
                                        </Link>
                                    </>
                                )) || (
                                    ' '
                                )}
                            </Typography>
                            {od?.pod?.pod_status === 'Despatched' && !od?.pod?.pod_ship_aa_only && (
                                <Box pb={2}>
                                    <Alert severity="warning">
                                        <strong>This purchase order delivery has already been despatched!</strong>
                                    </Alert>
                                </Box>
                            )}
                            {!od?.pod?.pod_ship_aa_only ? 
                            (
                                <Typography variant="body2" className="fw-400 textError mb-1">
                                    This is shipping direct, if the order has left the supplier and they cannot cancel then it is too late to cancel here, the customer will need to RMA.
                                </Typography>
                            ) : od?.pod?.pod_status === 'Despatched' && od?.pod?.pod_ship_aa_only ? (
                                <Typography variant="body2" className="fw-400 textError mb-1">
                                    The related purchase order delivery is shipping here and marked as despatched. Please answer Yes if the supplier can re-call the delivery and cancel the despatch / invoice. If the shipment is on the way, further options will be available when selecting No.
                                </Typography>
                            ) : null}
                            <Typography variant="body2" className="fw-400">
                                Has confirmation of cancellation been received from the supplier; and they have confirmed no stock is shipping?
                            </Typography>
                        </Box>
                        , () => {
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    cancel,
                                    podAction
                                }
                            })
                        }, () => {
                            if(od.pod?.pod_ship_aa_only === 1 && (od.pod?.pod_status === "Despatched" || od.pod?.pod_status === "Invoiced")) {
                                deployConfirmation(
                                    <Box p={1}>
                                        <Typography variant="body1" className="fw-400">
                                            Purchase Order Delivery #{od.pod?.pod_reference ?? ''}/{parseInt(od.pod?.pod_idx ?? 0) < 10 ? '0' : ''}{od.pod?.pod_idx ?? ''}
                                        </Typography>
                                        <Typography variant="body2" paragraph>
                                            Fulfilled by {od.purchase_order?.supplier?.supp_company_name} 
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            The stock that is currently in transit can either be returned to the supplier on receipt (RMA) or kept for stock, please select the best option below:
                                        </Typography>
                                    </Box>
                                , () => {
                                    this.setState({
                                        formData: {
                                            ...this.state.formData,
                                            cancel,
                                            podAction: {
                                                ...podAction,
                                                [od.pod.pod_id]: 'Add To Stock'
                                            }
                                        }
                                    })
                                }, () => {
                                    this.setState({
                                        formData: {
                                            ...this.state.formData,
                                            cancel,
                                            podAction: {
                                                ...podAction,
                                                [od.pod.pod_id]: 'RMA'
                                            }
                                        }
                                    })
                                },
                                'Keep Stock',
                                'RMA'
                                )
                            } else {
                                deployConfirmation(
                                    <Box p={1}>
                                        <Typography variant="body1" className="fw-400">
                                            Purchase Order Delivery #{od.pod?.pod_reference ?? ''}/{parseInt(od.pod?.pod_idx ?? 0) < 10 ? '0' : ''}{od.pod?.pod_idx ?? ''}
                                        </Typography>
                                        <Typography variant="body2" paragraph>
                                            Fulfilled by {od.purchase_order?.supplier?.supp_company_name} 
                                        </Typography>
                                        {!_.isEmpty(od.purchase_order?.supplier?.supp_phone) && (
                                            <Typography component="div" variant="body2">
                                                <FAIcon icon='phone' size={14} />
                                                <Link href={`tel:${od.purchase_order?.supplier?.supp_phone}`}>
                                                    {od.purchase_order?.supplier?.supp_phone}
                                                </Link>
                                            </Typography>
                                        )}
                                        <Typography variant="body2" component="div" paragraph>
                                            {(!_.isEmpty(od.purchase_order?.supplier?.supp_email) && (
                                                <>
                                                    <FAIcon icon='envelope' size={14} />
                                                    <Link href={`mailto:${od.purchase_order?.supplier?.supp_email}`}>
                                                        {od.purchase_order?.supplier?.supp_email}
                                                    </Link>
                                                </>
                                            )) || (
                                                ' '
                                            )}
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            Please cancel this purchase order delivery with the supplier; and return to cancel this delivery once confirmation of cancellation has been received.
                                        </Typography>
                                    </Box>
                                    ,
                                    false,
                                    false,
                                    'Acknowledged',
                                    null,
                                    true
                                )
                            }
                        }
                    )
                }

            } else if(od?.od_status === 'Despatched') {
                    deployConfirmation(
                        <Box p={1}>
                            <Typography variant="body1" className="fw-400">
                                Sales Order Delivery #{od.od_reference ?? ''}/{od.od_idx ?? ''}
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Fulfilled by Advanced Access
                            </Typography>
                            <Box pb={2}>
                                <Alert severity="error">
                                    <strong>This sales order delivery has already been despatched!</strong>
                                </Alert>
                            </Box>
                            <Typography variant="body2" className="fw-400" paragraph>
                                Please ensure that this order has not been physically despatched and lock the order if required to prevent invoicing.
                            </Typography>
                            <Typography variant="body2" className="fw-400">
                                Do you want to continue?
                            </Typography>
                        </Box>
                    , () => {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                cancel,
                            }
                        })
                    })
            } else {
                
                this.setState({
                    formData: {
                        ...this.state.formData,
                        cancel
                    }
                })
            }

        } else {

            if(!_.isEmpty(podAction?.[od?.pod?.pod_id])) {
                delete podAction[od.pod.pod_id];
            }

            this.setState({
                formData: {
                    ...this.state.formData,
                    cancel,
                    podAction
                }
            })

        }

    }

    handleSubmit = () => {

        const {
            callback,
            closeDialog,
            deploySnackBar,
            id
        } = this.props;

        const {
            formData
        } = this.state;

        this.setState({
            isLoading: true
        }, () => {

            API.put(`/sales/${id}/cancellation`, formData)
            .then(res => {
                if(res?.data) {

                    if(res.data?.errors) {

                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                            isLoading: false
                        }, () => {
                            if(this.state.formErrors?.generic === "This sales order has now been processed, please check and try again") {
                                callback?.();
                                closeDialog();
                            }
                        })

                    } else {

                        deploySnackBar("success", `You have successfully cancelled ${formData.type === 'deliveries' ? 'selected sales order deliveries' : 'this sales order'}`);
                        callback?.();                 
                        closeDialog();

                    }

                }
            })

        })
    }
    
    render() {

        const {
            closeDialog,
            deployConfirmation,
            order
        } = this.props;
        
        const { 
            formData, 
            formErrors,
            isLoading, 
            reasonList 
        } = this.state;

        return (
            <Box className="content-light-grey" p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Typography variant="h6">
                                    Sales Order #{order.order_reference}
                                </Typography>
                                <Typography variant='body2' className="fw-400">
                                    Order Cancellation
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Abort Cancellation">
                                    <IconButton
                                        onClick={closeDialog}
                                    >
                                        <FAIcon icon="times" size={20} button noMargin />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <Grid item xs={12}>
                            <Box className="content-light-white" p={3}>
                                {formErrors?.generic && (
                                    <Typography variant="body2" className="fw-400 textError" paragraph>
                                        {formErrors?.generic} 
                                    </Typography>
                                )}
                                {(formData.type === 'order' && (
                                    <></>
                                )) || (formData.type === 'ifm' && (
                                    <>
                                        <Typography variant="body2" className="fw-400" paragraph>
                                            Deliveries 
                                        </Typography>
                                        <Box pb={3}>
                                            <Alert severity="error">
                                                You must cancel all deliveries to proceed as this order has an outstanding modification
                                            </Alert>
                                        </Box>
                                        <DataTable
                                            config={{
                                                key: 'od_id',
                                                alternatingRowColours: true,
                                                isLoading: isLoading,
                                                responsiveImportance: true,
                                                inline: true,
                                                rowProps: rowData => ({
                                                    style: {
                                                        textDecoration: rowData.od_status === "Cancelled" ? 'line-through' : undefined
                                                    }
                                                })
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Cancel',
                                                    field: rowData => <Checkbox disabled={rowData.od_status === "Cancelled" || rowData.od_status === "Invoiced"} color="primary" checked={formData?.cancel?.[rowData.od_idx]} onChange={() => this.handleCancelDelivery(rowData.od_idx)}/>,
                                                    alignment: 'center',
                                                    sizeToContent: true
                                                }, 
                                                {
                                                    heading: 'Delivery',
                                                    field: rowData => (
                                                        <Box pt={1} pb={1}>
                                                            <Typography variant="body2" className="fw-400">
                                                                {`Delivery ${rowData.rowNumber} of ${_.size(order.deliveries)}`}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                {`Fulfilled by ${rowData.purchase_order?.supplier?.supp_company_name ?? 'Advanced Access'}`}
                                                            </Typography>
                                                        </Box>
                                                    ),
                                                }, 
                                                {
                                                    heading: 'Delivery Expected Despatch',
                                                    field: rowData => rowData.od_schedule_date,
                                                    fieldFormat: 'date',
                                                },    
                                                {
                                                    heading: 'Delivery Status',
                                                    field: rowData => rowData.od_status,
                                                },
                                                {
                                                    heading: 'POD Reference',
                                                    field: rowData => rowData.pod ? `${rowData.pod?.pod_reference ?? ''}/${parseInt(rowData.pod?.pod_idx ?? 0) < 10 ? '0' : ''}${rowData.pod?.pod_idx ?? ''}` : `-`,
                                                },    
                                                {
                                                    heading: 'POD Status',
                                                    field: rowData => rowData.pod ? rowData.pod.pod_status : `-`,
                                                },   
                                                {
                                                    heading: 'POD Action',
                                                    field: rowData => formData.podAction?.[rowData.pod?.pod_id] ?? '-'
                                                },    
                                            ]}
                                            rows={order.deliveries}
                                        />
                                    </>
                                )) || (
                                    <>
                                        <Typography variant="body2" className="fw-400" paragraph>
                                            Deliveries 
                                        </Typography>
                                        <DataTable
                                            config={{
                                                key: 'od_id',
                                                alternatingRowColours: true,
                                                isLoading: isLoading,
                                                responsiveImportance: true,
                                                inline: true,
                                                rowProps: rowData => ({
                                                    style: {
                                                        textDecoration: rowData.od_status === "Cancelled" ? 'line-through' : undefined
                                                    }
                                                })
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Cancel',
                                                    field: rowData => <Checkbox disabled={rowData.od_status === "Cancelled" || rowData.od_status === "Invoiced"} color="primary" checked={formData?.cancel?.[rowData.od_idx]} onChange={() => this.handleCancelDelivery(rowData.od_idx)}/>,
                                                    alignment: 'center',
                                                    sizeToContent: true
                                                }, 
                                                {
                                                    heading: 'Delivery',
                                                    field: rowData => (
                                                        <Box pt={1} pb={1}>
                                                            <Typography variant="body2" className="fw-400">
                                                                {`Delivery ${rowData.rowNumber} of ${_.size(order.deliveries)}`}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                {`Fulfilled by ${rowData.purchase_order?.supplier?.supp_company_name ?? 'Advanced Access'}`}
                                                            </Typography>
                                                        </Box>
                                                    ),
                                                }, 
                                                {
                                                    heading: 'Delivery Expected Despatch',
                                                    field: rowData => rowData.od_schedule_date,
                                                    fieldFormat: 'date',
                                                },    
                                                {
                                                    heading: 'Delivery Status',
                                                    field: rowData => rowData.od_status,
                                                },
                                                {
                                                    heading: 'POD Reference',
                                                    field: rowData => rowData.pod ? `${rowData.pod?.pod_reference ?? ''}/${parseInt(rowData.pod?.pod_idx ?? 0) < 10 ? '0' : ''}${rowData.pod?.pod_idx ?? ''}` : `-`,
                                                },    
                                                {
                                                    heading: 'POD Status',
                                                    field: rowData => rowData.pod ? rowData.pod.pod_status : `-`,
                                                },   
                                                {
                                                    heading: 'POD Action',
                                                    field: rowData => formData.podAction?.[rowData.pod?.pod_id] ?? '-'
                                                },    
                                            ]}
                                            rows={order.deliveries}
                                        />
                                    </>
                                )}
                                <Box pt={formData.type === 'order' ? 1 : 3} maxWidth={400}>
                                    <Typography variant="body2" className="fw-400">
                                        Cancellation Reason
                                    </Typography>
                                    <Box mt={-1}>
                                        <AutoCompleteSelect 
                                            options={reasonList} 
                                            value={formData.reason}
                                            onChange={v => this.handleSelectChange('reason', v)}
                                        />
                                    </Box>
                                </Box>
                                <div className="buttonRow">
                                    <Button 
                                        disabled={(formData.type === 'deliveries' && _.every(formData.cancel, el => el === 0)) || (formData.type === 'ifm' && _.every(formData.cancel, el => el !== 1))}
                                        onClick={() => deployConfirmation(`Are you sure you want to cancel ${formData.type === 'deliveries' ? 'selected sales order deliveries' : 'this sales order'}?`, this.handleSubmit)}
                                        variant="text"
                                        color="primary"
                                    >
                                        <FAIcon icon="check" button size={15} />
                                        {formData.type === 'deliveries' ? `Cancel Selected Deliveries` : `Cancel Order`}
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
        );
    }
}

export default CancelOrder;