import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

import { 
    clearPersistence, 
    setPersistence 
} from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    supplierType: 2,
    keywords: '',
    searchResults: {},
    access: {
        viewSupplier: false,
        addSupplier: false
    },
    supplierTypeList: [],
    isLoading: true,
})

class SupplierSearch extends React.Component {
    constructor(props) {
        super(props);    
        this.persistenceId = 'supplierSearch';
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }

    componentDidMount = () => {

        this.props.pageTitle([0, 'Suppliers']);

        if(!this.hasPageState()) {
            Promise.all([             
                API.get('/suppliers/types/all'),
                API.access('view-supplier'), 
                API.access('add-supplier'),
            ])
            .then(([result, viewRes, addSuppRes]) =>  {

                let supplierTypeList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.type_id,
                        label: el.type_name,
                    });
                });

                this.setState({
                    supplierTypeList: supplierTypeList,
                    access: {
                        ...this.state.access,
                        viewSupplier: viewRes.data ? viewRes.data.has_access : false,                    
                        addSupplier: addSuppRes.data ? addSuppRes.data.has_access : false
                    }
                }, () => this.getSearchData(true))

            });
        }

    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    loadComponentData = () => {
       this.getSearchData(false)
    }

    getSearchData = isLoading => {
        this.setState({
            isLoading
        }, () => {
            API.get('/suppliers/all' , {
                props: {
                    cancellation: true
                },
                params: {
                    supplierType: this.state.supplierType,
                    keywords: this.state.keywords,
                }
            })
            .then((result) => {
                if(result && result.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    }, () => {
                        this.savePageState();
                    })
                }
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.getSearchData(true);
        });
    };

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.setSearch();
        });
    };

    reset = () => {
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access,
                supplierType: initialState().supplierType,
                supplierTypeList: this.state.supplierTypeList
            }, () => {
                this.getSearchData(true);
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getSearchData(true), 250);
    }

    render() {
        const { access, keywords } = this.state;
        return (
            <>
                <Grid container spacing={1}>      
                    <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                            <TextField 
                                fullWidth
                                onChange={this.handleSearchKeyword}
                                placeholder='Search:'
                                value={keywords} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                }}
                                autoFocus
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} xl={2} style={{marginBottom: 12, paddingLeft: 24}}>
                            <AutoCompleteSelect
                                options={this.state.supplierTypeList}
                                placeholder='Supplier Type:'
                                onChange={this.handleSelectChange('supplierType')}
                                value={this.state.supplierType} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                }}
                                adornment="filter"
                            /> 
                        </Grid>
                        {access.addSupplier && (
                            <Grid item xs={12} sm={3} md={5} xl={7} align='right'> 
                                <Button 
                                    color="primary" 
                                    size="small"
                                    variant="text"
                                    onClick={() => this.props.history.push(`/suppliers/new`)}
                                >   
                                    <FAIcon type="light" icon="plus-circle" size="small" button />
                                    Add Supplier
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'i',
                                    pagination: true,
                                    persistenceId: this.persistenceId,
                                    responsiveImportance: true,
                                    isLoading: this.state.isLoading,
                                    alternatingRowColours: true,
                                    noResultsText: keywords.length >= 1 ? 'No suppliers were found' : 'Start typing to search suppliers',
                                    options: {
                                        defaultSort: 'an',
                                        dataRef: true,
                                        reset: this.reset,                                   
                                        export: {
                                            title: `Suppliers`,
                                            name: `Suppliers`,
                                            excel: true,
                                            pdf: true,
                                            print: true
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Supplier',
                                        field: rowData => rowData.an,
                                        dataRef: 'an',
                                        important: true,
                                        main: true,
                                    },
                                    {
                                        heading: 'Acc Number',
                                        field: rowData => rowData.ac || '-',
                                        dataRef: 'ac',
                                        important: true,
                                        main: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Type',
                                        field: rowData => rowData.t,
                                        dataRef: 't',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Products',
                                        field: rowData => rowData.cpr,
                                        dataRef: 'cpr',
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'PO Qty',
                                        field: rowData => rowData.cpo,
                                        dataRef: 'cpo',
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Last PO',
                                        field: rowData => rowData?.lp ?? '-',
                                        fieldFormat: 'date',
                                        dataRef: 'lp',
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Main Contact Number',
                                        field: rowData => rowData.p || '-',
                                        dataRef: 'p',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Main Email Address',
                                        field: rowData => rowData.e || '-',
                                        dataRef: 'e',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Credit Limit',
                                        field: rowData => rowData.cl || '0.00',
                                        dataRef: 'cl',
                                        fieldFormat: 'currency',
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Credit Balance',
                                        field: rowData => rowData.cb || '0.00',
                                        dataRef: 'cb',
                                        fieldFormat: 'currency',
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Payment Terms',
                                        field: rowData => rowData.pt || '-',
                                        dataRef: 'pt',
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'View', icon: 'binoculars', link: '/suppliers/' + rowData.i, disabled: !access.viewSupplier}
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.searchResults}
                            />
                        </Paper>
                    </Grid>
                </Grid>  
            </>
        );
    }
}
				
const mapStateToProps = state => ({
    statePersistence: state.statePersistence,
})

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSearch);