import React, { useState, useEffect } from "react";
import MainStore from "Redux/MainStore";
import { useSelector, useDispatch } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import NotificationBadge from "Components/Common/Badges/NotificationBadge";

import { playSound } from "Redux/Actions/Sounds/Sounds";

const Chat = ({ device, smallIcon, toggleDrawer }) => {
    const dispatch = useDispatch();

    let [messageCount, setMessageCount] = useState(null);

    const badges = useSelector((state) => state.notifications.badges);
    const count = badges["unread-messages"];

    useEffect(() => {
        if (!document.hidden && MainStore.getState().sound.status !== "playing") {
            if (count !== messageCount) {
                let lastMsgCount = localStorage.getItem("msgs");
                lastMsgCount = lastMsgCount ? parseInt(lastMsgCount) : null;
                if (
                    messageCount !== null &&
                    parseInt(count) > parseInt(messageCount) &&
                    parseInt(count) !== lastMsgCount
                ) {
                    localStorage.setItem("msgs", count);
                    dispatch(playSound("msg", count));
                }
                setMessageCount(count);
            }
        } /*eslint-disable-next-line*/
    }, [count, messageCount]);

    if (badges) {
        return (
            <Tooltip title="Messages">
                <IconButton onClick={toggleDrawer} size={`small`}>
                    {(badges?.["unread-messages"] && (
                        <NotificationBadge badgeContent={count} variant="standard" color="secondary" showZero={false}>
                            <FAIcon
                                type="solid"
                                icon="comments"
                                size={smallIcon ? 17.5 : device?.isTablet ? 20.5 : 21.5}
                                noMargin
                                buttonPrimary
                            />
                        </NotificationBadge>
                    )) || (
                        <FAIcon
                            type="solid"
                            icon="comments"
                            size={smallIcon ? 17.5 : device?.isTablet ? 20.5 : 21.5}
                            noMargin
                            buttonPrimary
                        />
                    )}
                </IconButton>
            </Tooltip>
        );
    }
};

export default Chat;
