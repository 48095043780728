import React from 'react';
import _ from 'lodash';
import { CirclePicker } from 'react-color';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import * as Settings from 'Helpers/SettingsHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
} from 'Functions/FormFunctions';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = {
    formData: {
        teamName: '',
        teamEmail: '',
        teamColor: ''
    },
    formErrors:[],
    initialFormData: {},
    confirmation: {
        submit: false,
    },
    isLoading: true
}

class UpdateTeam extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;        
        this.handleChange = handleChange.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.poll = false;
    }

    componentDidMount = () => {
        this.handlePoll(true);
        this.poll = setInterval(this.poll, 1500);
        this.loadComponentData();
    }

    componentWillUnmount = () => {
        if(this.poll) {
            clearInterval(this.poll);
        }
    }

    handlePoll = (initial = false) => {
        API.post(`/staff/my/view`, 
            { 
                team: this.props.teamId,
                type: 'Update'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {
                    if(!_.isEmpty(res.data) && _.some(res.data, el => el.u === 1)) {
                        this.props.deploySnackBar("error", "Sorry, another user is currently modifying this team");
                        this.props.toggleDialog()
                    }
                }
            }
        })
    }

    loadComponentData = () => {
        API.get('/teams/' + this.props.teamId)
        .then((result) => {
            if(result.data.errors) {
                this.props.history.push('/teams')
            } else {                
                this.setState({
                    isLoading: false,
                    formData: {
                        ...this.state.formData,
                        teamName: result.data.team_name,
                        teamEmail: result.data.team_email,
                        teamColor: result.data.team_color,
                    },
                    initialFormData: {
                        ...this.state.formData,
                        teamName: result.data.team_name,
                        teamEmail: result.data.team_email,
                        teamColor: result.data.team_color,
                    }
                })
            }
        })
    }

    submit = () => {
        let { formData, initialFormData } = this.state;
        formData = {
            ...formData,
            initialFormData
        }
        API.put('/teams/' + this.props.teamId, formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                })
            } else {
                this.setState({
                    ...initialState
                }, () => {
                    this.props.deploySnackBar("success", "You have succesfully updated this team")
                    this.props.toggleDialog()
                })
            }
        })
    }

    handleColorChange = (colour, event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                teamColor: colour.hex
            }
        });
    };
    
    render() {
        const { formData, formErrors, isLoading } = this.state;
        return (
            <>
                {(isLoading && (                    
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <LoadingCircle />
                        </Grid>
                    </Grid>
                )) || (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                {/* <TextField
                                    name="teamName"
                                    label="Team Name *"
                                    value={formData.teamName}
                                    error={formErrors && formErrors['teamName'] && true}
                                    helperText={formErrors && formErrors['teamName']}
                                    onChange={this.handleChange}
                                    margin="none"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                /> */}
                                <TextField
                                    name="teamEmail"
                                    label="External E-mail Address *"
                                    value={formData.teamEmail}
                                    error={formErrors && formErrors['teamEmail'] && true}
                                    helperText={formErrors && formErrors['teamEmail']}
                                    onChange={this.handleChange}
                                    margin="none"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                />
                                <FormControl error={formErrors && formErrors['teamColor'] && true} fullWidth style={{marginTop: 8, marginBottom: 8}}>
                                    <InputLabel shrink={true}>Team Colour *</InputLabel>
                                    <br />
                                    {formErrors && formErrors['teamColor'] &&
                                        <FormHelperText>{formErrors['teamColor']}</FormHelperText>
                                    }
                                    <br />
                                    <CirclePicker
                                        color={this.state.formData.teamColor}
                                        colors={Settings.colourSelection}
                                        onChangeComplete={this.handleColorChange}
                                    />
                                
                                </FormControl>
                            </form>        
                            <ConfirmationDialog 
                                open={this.state.confirmation.submit} 
                                success={() => this.handleConfirmationSuccess('submit')} 
                                close={() => this.handleConfirmationClose('submit')} 
                                title="Update team?" 
                                message="Are you sure you want to update this team?"
                            />                            
                        </Grid>
                    </Grid>
                )}
                <DialogActions className='pr-0 pb-0'>
                    <Button 
                        onClick={() => this.props.toggleDialog()} 
                        variant="text"
                    >
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                    <Button 
                        color="primary"
                        disabled={!this.state.formData.teamName} 
                        onClick={() => this.handleConfirmationOpen('submit')} 
                        variant="text" 
                    >
                        <FAIcon icon="check" size={15} button />
                        Update
                    </Button>
                </DialogActions>
            </>
        );
    }
}

export default UpdateTeam;