import React from 'react';

import Search from 'Components/Common/Search/Search';

const ProductsNotOnWebsiteReport = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/productsNotOnWebsite',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'ID',
                        field: rowData => rowData.id,
                        dataRef: 'id',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.code,
                        dataRef: 'code',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.desc,
                        dataRef: 'desc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.supp,
                        dataRef: 'supp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Website Category',
                        field: rowData => rowData.webCat,
                        dataRef: 'webCat',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Product Category',
                        field: rowData => rowData.prdCat,
                        dataRef: 'prdCat',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Brand',
                        field: rowData => rowData.brand,
                        dataRef: 'brand',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Range',
                        field: rowData => rowData.range,
                        dataRef: 'range',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Stocked',
                        field: rowData => rowData.prdStock,
                        dataRef: 'prdStock',
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Consignment',
                        field: rowData => rowData.prdCon,
                        dataRef: 'prdCon',
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Check P&A',
                        field: rowData => rowData.checkP,
                        dataRef: 'checkP',
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Non-discountable',
                        field: rowData => rowData.nonDisc,
                        dataRef: 'nonDisc',
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Exclude from Website',
                        field: rowData => rowData.exWeb,
                        dataRef: 'exWeb',
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Image Count',
                        field: rowData => rowData.img,
                        dataRef: 'img',
                        important: true,
                        sizeToContent: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'id',
                name: 'products-not-on-website',
                noResultsText: 'No products found that are missing from the website.',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Marketing'],
                pdf: true,
                persistenceId: 'productsNotOnWebsite',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: false,
                showSupplier: true,
                showTotals: false,
                title: 'Products Not On Website Report'
            }}
        />
    )
}

export default ProductsNotOnWebsiteReport;