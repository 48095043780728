import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Fade from '@material-ui/core/Fade';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import AdminLayout from 'Components/Layout/AdminLayout/AdminLayout';
import WarehouseLayout from 'Components/Layout/WarehouseLayout/WarehouseLayout';
import Login from 'Components/Login/Login';
import MessageStandalone from 'Components/Messages/MessageStandalone';
import Theme from 'Components/Common/Theme/Theme';
import { loadPulseData, loadRtapiPulseData } from 'Redux/Actions/Notifications/Notifications';
import { loggedIn } from 'Functions/AuthFunctions';
import { DISABLE_POLLING } from 'Constants';
import PrintableCardForm from 'Components/CardForm/PrintableCardForm';
import Screen1 from 'Components/Screen/Screen1';
import PrintableView from 'Components/Customers/TradeZoneRequests/PrintableView';

const Layout = () => {

    const   mode        = useSelector(state => state.ui.mode),
            dispatch    = useDispatch();

    useEffect(() => {

        if(!DISABLE_POLLING) {
        
            const getPulseData = () => {
                dispatch(loadPulseData())
            }

            const getRtapiPulseData = () => {
                dispatch(loadRtapiPulseData())
            }

            let initialLoad = setTimeout(() => {
                getPulseData();
                getRtapiPulseData();
            }, 500);
            
            let pulseData = setInterval(getPulseData, 10000);
            let rtapiPulseData = setInterval(getRtapiPulseData, 5000);

            return () => {
                if(initialLoad)
                    clearTimeout(initialLoad);
                if(pulseData)
                    clearInterval(pulseData);
                if(rtapiPulseData)
                    clearInterval(rtapiPulseData);
            }

        }

    }, [dispatch]);

    return (
        <BrowserRouter>
            <CssBaseline />
            <div className='displayFlex'>
                <Switch>
                    <Route 
                        exact 
                        path="/" 
                        render={
                            () => (
                                loggedIn() ? (
                                    <Redirect 
                                        to={{
                                            pathname: "/dashboard",
                                            hash: window.location.hash,
                                            search: window.location.search
                                        }} 
                                    />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                    <Route 
                        exact 
                        path="/screen/:uuid"
                        render={props => (
                            <Screen1 {...props} />
                        )}
                    />
                    <Route 
                        exact 
                        path="/login"
                        render={
                            props => (
                                (loggedIn() && (
                                    <Redirect to="/dashboard" />
                                )) || (
                                    <Login {...props} /> 
                                )
                            )
                        }
                    />
                    <Route
                        exact
                        path="/messaging"
                        render={
                            props => (
                                (loggedIn() && (
                                    <MessageStandalone {...props} />
                                )) || (                                            
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                    <Route 
                        exact 
                        path="/card-form/:data" 
                        render={
                            () => (
                                (loggedIn() && (
                                    <PrintableCardForm />
                                )) || (
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                    <Route 
                        exact 
                        path="/print/:data" 
                        render={
                            () => (
                                (loggedIn() && (
                                    <PrintableView />
                                )) || (
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                    <Route 
                        render={
                            props => (
                                (loggedIn() && (
                                    <ThemeProvider theme={Theme}>
                                        <SnackbarProvider 
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            maxSnack={5}
                                            preventDuplicate={false}
                                            TransitionComponent={Fade}
                                        >
                                            {(mode === "Warehouse" && (
                                                <WarehouseLayout {...props} />
                                            )) || (
                                                <AdminLayout {...props} />
                                            )}
                                        </SnackbarProvider>
                                    </ThemeProvider>
                                )) || (                                            
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                </Switch>
            </div>
        </BrowserRouter>
    )
}

export default Layout;