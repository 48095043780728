import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import API from 'API';
import CalendarEvent from 'Components/Calendar/CalendarEvent/CalendarEvent';
import CiCalendar from 'Components/Calendar/ViewCalendar/CiCalendar';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = {
    activeCategories: [],
    dateRange: {
        from: moment().startOf("month").startOf("isoWeek").format("YYYY-MM-DD"),
        to: moment().endOf("month").endOf("isoWeek").format("YYYY-MM-DD")
    },
    events: []
}

class ViewCalendar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {    
        this.props.pageTitle?.([0, 'Calendar', `${this.props.myEvents ? `My` : `All`} Events`]);
        this.getEvents();
    }

    componentDidUpdate = prevProps => {
        if(prevProps.location?.pathname !== this.props.location?.pathname) {    
            this.props.pageTitle?.([0, 'Calendar', `${this.props.myEvents ? `My` : `All`} Events`]);
            this.getEvents();
        }
    }

    setActiveCategories = activeCategories => {
        this.setState({
            activeCategories: _.keys(_.pickBy(activeCategories))
        }, () => {
            this.getEvents();
        })
    }

    handleDateRangeChange = date => {

        const {
            activeCategories
        } = this.state;

        let from, to;

        if(typeof date === 'object' && date.start && date.end) {
            from = date.start;
            to = date.end;
        } else if(Array.isArray(date) && date.length === 7) {
            from = date[0];
            to = date[6];
        } else if(Array.isArray(date) && date.length === 1) {
            from = date[0];
            to = date[0];
        }

        this.setState({
            dateRange: {
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            }
        }, () => {
            if(!_.isEmpty(activeCategories)){
                this.getEvents();
            }
        });
    }
    
    getEvents = () => {

        const {
            myEvents
        } = this.props;

        const {
            dateRange,
            activeCategories
        } = this.state;
        
        let params = {
            dateFrom: dateRange?.from,
            dateTo: dateRange?.to,
            activeCategories: !_.isEmpty(activeCategories) ? activeCategories.join() : ""
        }

        if(myEvents) {
            params = {
                ...params, 
                mine: true
            }
        }

        API.get('/calendar/events/all', { params })
        .then(result => {

            if(result?.data) {

                this.setState({
                    events: _.map(result.data, event => (
                        _.assign({
                            id: event.calendar_event_id,
                            title: `${moment(event.calendar_event_start_datetime).format('H:mm')} ${event.sub_category.category_name}`,
                            altTitle: `${event.sub_category.category_name}`,
                            colour: event.sub_category.category_colour,
                            start: moment(event.calendar_event_start_datetime)._d,
                            end: moment(event.calendar_event_end_datetime)._d,
                            allDay: false,
                            cancelled: event.calendar_event_status === 'Cancelled'
                        })
                    ))
                });

            } else {
                this.setState({
                    events: []
                });
            }
        });

    }

    onSelectEvent = e => {

        if(e?.id) {

            const {
                deployDialog
            } = this.props;

            deployDialog(
                <CalendarEvent id={e?.id} />,
                true,
                "",
                "standard",
                "sm",
                false,
                true
            )
        }

    }

    render = () => {

        const { 
            myEvents 
        } = this.props;

        const { 
            events 
        } = this.state;

        return (
            <CiCalendar 
                events={events} 
                setActiveCategories={this.setActiveCategories} 
                getEvents={this.getEvents} 
                onSelectEvent={this.onSelectEvent} 
                handleDateRangeChange={this.handleDateRangeChange} 
                myEvents={myEvents}
            />
        )
        
    }
}

const mapDispatchToProps = dispatch => (
    {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    }
)

export default connect(null, mapDispatchToProps)(ViewCalendar);