import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import Textarea from "Components/Common/Inputs/Textarea";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { closeDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

const WildixCallNotes = ({ callback, id }) => {
    
    const dispatch = useDispatch(),
        [notes, setNotes] = useState(""),
        [call, setCall] = useState(null),
        [isLoading, setIsLoading] = useState(true);

    const handleSubmit = () => {
        setIsLoading(true);
        API.put(`/misc/calls/${id}/notes`, { notes })
        .then((res) => {
            if(res?.data?.success) {
                callback?.(notes);
                dispatch(deploySnackBar('success', 'Call notes updated successfully'));
                dispatch(closeDialog());
            }
        });
    }

    useEffect(() => {
        if (call) {
            setIsLoading(false);
        }
    }, [call])

    useEffect(() => {
        API.get(`/misc/calls/${id}`)
        .then((res) => {
            if (res?.data) {
                setCall(res?.data);
                setNotes(res?.data?.nt);
            }
        });
    }, []); /* eslint-disable-line */

    const incoming = call?.ic === 1,
        answered = call?.st === 1;

    return (
        (isLoading && (
            <LoadingCircle />  
        )) || (
            <Box>
                <Grid container>
                    {call && (
                        <Grid item xs={12}>
                            <List dense>
                                <ListItem disableGutters>
                                    {!incoming ? (
                                        <ListItemAvatar>
                                            <FAIcon className={answered ? '' : 'textError'} icon={`phone-arrow-up-right`} type="light" />
                                        </ListItemAvatar>
                                    ) : null}
                                    <ListItemText
                                        primary={
                                            <Typography className={`${answered ? '' : 'textError'} fw-400`} variant="body1">
                                                {call.nm}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography className={answered ? '' : 'textError'} variant="body1" color="textSecondary">
                                                {call.nu}
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Grid container alignItems='center'>
                                            <Grid item>
                                                <Typography variant="body2" component="div" align="right">
                                                    {call.ti}
                                                </Typography>
                                                <Typography variant="body2" component="div" align="right">
                                                    {call.dt}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Textarea
                            label="Notes:"
                            onChange={(e) => setNotes(e?.target?.value ?? "")}
                            rows={5}
                            value={notes}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <div className="buttonRow">
                    <Button color="primary" onClick={() => dispatch(closeDialog())} variant="text">
                        <FAIcon icon="times" button />
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={() =>
                            dispatch(deployConfirmation(`Are you sure you want to update notes for this call?`, handleSubmit))
                        }
                        variant="text"
                    >
                        <FAIcon icon="check" button />
                        Update Notes
                    </Button>
                </div>
            </Box>
        )
    );
};

export default WildixCallNotes;