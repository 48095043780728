import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { isTablet } from 'Functions/MiscFunctions';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Preview = ({classes, height, preview:{pdf,numPages,pageNumber}, handlePreviewLoad, handlePreviewPagination}) => {
    
    let [scale,setScale] = useState(isTablet() ? 1.00 : 1.50);
    // const previousPage = () => handlePreviewPagination(-1);
    // const nextPage = () => handlePreviewPagination(1);
    
    const handleScale = zoom => {
        let newScale = scale;
        if(zoom) {
            newScale = (parseFloat(scale) + parseFloat(0.25)).toFixed(2)
        } else {
            newScale = (parseFloat(scale) - parseFloat(0.25)).toFixed(2)
        }
        setScale(newScale);
    }

    return (
        (pdf && (
            <>
                <Paper style={{width: '100%', background: '#F5F5F5', height: 47, borderBottom: '1px solid #ddd', borderRadius: 0}} elevation={0}>
                    <Box p={0.5}>
                        <Grid container spacing={1} justify='center' alignItems='center'>
                            <Grid item xs>
                                <Typography variant="body2" className="ml-2">
                                    {`Pages: ${numPages ?? 1}`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <IconButton
                                            onClick={() => setScale("1.50")}
                                        >
                                            <FAIcon type="light" icon="search" size="small" button noMargin />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            onClick={() => handleScale(false)}
                                            disabled={scale === "0.75"}
                                        >
                                            <FAIcon type="light" icon="search-minus" size="small" button noMargin />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            onClick={() => handleScale(true)}
                                            disabled={scale === "2.00"}
                                        >
                                            <FAIcon type="light" icon="search-plus" size="small" button noMargin />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <Grid container justify="center" style={{height: (height - 47), background: '#777', position: 'relative', textAlign: 'center', overflowX: 'hidden', overflowY: 'auto'}}>
                    <Grid item>
                        <Box p={1}>
                            <Document
                                file={`data:application/pdf;base64,${pdf}`}
                                onLoadSuccess={handlePreviewLoad}
                                loading={(
                                    <>
                                    </>
                                )}
                            >
                                {Array.apply(null, Array(numPages))
                                            .map((x, i)=>i+1)
                                            .map(page => (
                                                <Page 
                                                    pageNumber={page} 
                                                    loading={(
                                                        <LoadingCircle />
                                                    )}
                                                    renderAnnotationLayer={false}
                                                    scale={scale}
                                                    style={{
                                                        width: '100%', 
                                                        position: 'absolute',
                                                    }}
                                                />
                                            )
                                )}
                            </Document>
                        </Box>
                    </Grid>
                </Grid>
            </>
        )) || (
            <React.Fragment />
        )
    )
}

export default Preview;