import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import OrderSummary from 'Components/Sales/Misc/OrderSummary';
import SearchTransactionsTable from 'Components/Accounts/SearchTransactions/SearchTransactionsTable';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { getFormData, handleChange, handleFileChange, handleFloat, handleSelectChange, handleSortCode } from 'Functions/FormFunctions';
import { currencyFormat, momentFormatDateTime } from 'Functions/MiscFunctions';
import Alert from 'Components/Common/Alert/Alert';

const initialState = {
    access: {
        card: false,
        cash: false,
        cheque: false,
        bankTransfer: false,
        sendProforma: false,
        skipPayment: false,
    },
    dialog: false,
    formData: {
        method: '',
        cardType: '',
        cardAuthNo: '',
        chequePayee: '',
        chequeNo: '',
        chequeImg: '',
        paymentAmount: '',
        payeeName: '',
        sortCode: '',
        accNo: ''
    },
    formErrors: [],
    orderPaid: false,
    hasPending: false,
    totalPaid: 0, 
    outstandingBalance: 0,
    authorisedTotal: 0,
    pendingTotal: 0,
    isLoading: true
}

class AwaitingPayment extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.getFormData = getFormData.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleFileChange = handleFileChange.bind(this);
        this.handleFloat = handleFloat.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleSortCode = handleSortCode.bind(this);
    }

    componentDidMount = () => {
        API.multiAccess([
            "process-order:card",
            "process-order:cash",
            "process-order:cheque",
            "process-order:bank-transfer",
            "process-order:send-proforma",
            "process-order:skip-payment"
        ])
        .then(([
            card,
            cash,
            cheque,
            bankTransfer,
            sendProforma,
            skipPayment
        ]) => {

            let { 
                order 
            } = this.props,
            
            orderPaid = false,
            hasPending = false,
            outstandingBalance = parseFloat(order.order_total_gross),
            totalPaid = 0.00, 
            authorisedTotal = 0.00,
            pendingTotal = 0.00;
        
            _.each(order.payments, payment => {
                if(payment.opp_txn_status !== "Declined") {
                    totalPaid = (parseFloat(totalPaid) + parseFloat(payment.opp_txn_amount)).toFixed(2);
                    outstandingBalance = (parseFloat(outstandingBalance) - parseFloat(payment.opp_txn_amount)).toFixed(2);
                    if(outstandingBalance === "0.00") {
                        orderPaid = true;
                    }
                    if(payment.opp_txn_status === "Authorised") {
                        authorisedTotal = (parseFloat(authorisedTotal) + parseFloat(payment.opp_txn_amount)).toFixed(2);  
                    } else {
                        pendingTotal = (parseFloat(pendingTotal) + parseFloat(payment.opp_txn_amount)).toFixed(2);  
                        hasPending = true;
                    }
                }
            })

            outstandingBalance = parseFloat(outstandingBalance).toFixed(2);
            
            if(order.order_paid === 1) {
                this.props.callback();
            }

            this.setState({
                access: {
                    card,
                    cash,
                    bankTransfer,
                    cheque,
                    sendProforma,
                    skipPayment
                },
                isLoading: false,
                orderPaid,
                hasPending,
                totalPaid, 
                outstandingBalance,
                authorisedTotal,
                pendingTotal,
                formData: {
                    ...initialState.formData,
                    paymentAmount: outstandingBalance,
                },
                formErrors: []
            })
        })
    }

    handleClearChequeImg = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                chequeImg: initialState.formData.chequeImg
            }
        });
    }

    handlePayment = method => {
        this.setState({
            dialog: true,
            formData: {
                ...initialState.formData,
                paymentAmount: this.state.outstandingBalance,
                method
            }
        })
    }

    handleDialogClose = () => {
        this.setState({
            formErrors: initialState.formErrors,
            dialog: initialState.dialog
        })
    }

    handleDeploySendProforma = () => {

        const {
            callback,
            closeDialog,
            order,
            deployDialog,
            scrollToTop
        } = this.props;

        deployDialog(
            <EmailDialog
                id={order.order_id}
                type='order'
                action='proforma'
                callback={() => { closeDialog(); callback(2);}}
                defaultFormData={{
                    emailSubject: `Order Proforma - #${order.order_reference}`,
                    emailTo: {
                        label: order?.order_email ?? '',
                        value: order?.order_email ?? ''
                    }
                }}
                scrollToTop={scrollToTop}
                success='The sales order proforma has been successfully sent'
                confirmation='Are you sure you want to send this proforma?'
                send='Send'
                noCancel
                noDialog
            />
            , false, "Send Proforma", "standard", false
        );
        scrollToTop();
    }

    submit = () => {
        this.setState({
            dialog: false,
            isLoading: true
        }, () => {
            API.post(`/sales/${this.props.id}/payment`, this.getFormData())
            .then(result => {
                if(result.data) {
                    if(result.data.errors && result.data.errors.length > 0) {
                        this.setState({
                            ...this.state,
                            dialog: true,
                            formErrors: formatValidationErrors(result.data.errors),
                            isLoading: false,
                        })
                    } else {
                        if(result?.data?.order?.order_paid === 1) {
                            this.props.deploySnackBar("success", "This sales order has been successfully marked as paid");
                        } else {
                            this.props.deploySnackBar("success", "The payment was successfully recorded against this order");
                        }
                        this.props.callback(2);
                    }
                }
            })
        })
    }

    skipPayment = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.put(`/sales/${this.props.id}/skipPayment`)
            .then(result => {
                if(result.data) {
                    if(result.data.errors && result.data.errors.length > 0) {
                        this.props.deploySnackBar("error", "There was an issue skipping payment for this order, please try again");
                    } else {
                        this.props.deploySnackBar("success", "This sales order has been marked as payment not required");
                    }
                    this.props.callback(2);
                }
            })
        })
    }

    render() {        
        const { currentTab, order } = this.props;
        const { access, dialog, formData, formErrors, isLoading, orderPaid, hasPending, outstandingBalance, pendingTotal } = this.state;
        return (
            <>
                <Grid container spacing={3}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                {(currentTab === 0 && (
                                    <Grid item xs={12}>
                                        <ViewNotes heading={<Typography variant="h6">Sales Order Notes</Typography>} notes={order.notes} hideDeleteButton />
                                    </Grid>
                                )) || (currentTab === 1 && (
                                    <OrderSummary order={order} />
                                )) || (currentTab === 2 && (
                                    <>
                                        <Grid container spacing={3}> 
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <Box p={3}>
                                                        <Grid container spacing={1} alignItems='center'>
                                                            <Grid item xs>
                                                                <Typography variant="h6">
                                                                    Process Payment
                                                                </Typography>    
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={0}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item>
                                                                            <Alert variant="outlined" severity="success">
                                                                                <strong>Sales Order Gross Total</strong><br />{currencyFormat.format(order.order_total_gross)}
                                                                            </Alert>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Alert variant="outlined" severity={orderPaid ? 'success' : 'warning'}>
                                                                                <strong>Outstanding Balance Due</strong><br />{outstandingBalance === "0.00" ? (hasPending ? `${currencyFormat.format(pendingTotal)} pending clearance` : 'None') : `${currencyFormat.format(outstandingBalance)}`}
                                                                            </Alert>
                                                                        </Grid>
                                                                        {/* {order.order_ifm_payment === 1 && (
                                                                            <Grid item>
                                                                                <Alert severity="warning" variant="outlined">
                                                                                    <strong>Order Modified</strong><br />
                                                                                    An additional payment is now required
                                                                                </Alert>
                                                                            </Grid>
                                                                        )} */}
                                                                        {order.order_payment_cash === 1 && (
                                                                            <Grid item>
                                                                                <Alert severity="info" variant="outlined">
                                                                                    <strong>Prepayment Option</strong><br />Cash on Collection
                                                                                </Alert>
                                                                            </Grid>
                                                                        )}
                                                                        {order.order_payment_card === 1 && (
                                                                            <Grid item>
                                                                                <Alert severity="info" variant="outlined">
                                                                                    <strong>Prepayment Option</strong><br />Credit / Debit Card
                                                                                </Alert>
                                                                            </Grid>
                                                                        )}
                                                                        {order.order_payment_proforma === 1 && (
                                                                            <Grid item>
                                                                                <Alert severity="info" variant="outlined">
                                                                                    <strong>Prepayment Option</strong><br />Proforma
                                                                                </Alert>
                                                                            </Grid>
                                                                        )}
                                                                        {order.order_payment_proforma === 0 && order.order_payment_card === 0 && order.order_payment_cash === 0 && (
                                                                            <Grid item>
                                                                                <Alert severity="info" variant="outlined">
                                                                                    <strong>Prepayment Option</strong><br />To be confirmed
                                                                                </Alert>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Grid container spacing={3} className="pt-1">
                                                                            {access.bankTransfer && (
                                                                                <Grid item xs={12} lg={3} align="left">
                                                                                    <ToggleButton
                                                                                        style={{width: '100%', height: 250}}
                                                                                        onClick={() => this.handlePayment("Bank Transfer")}
                                                                                        disabled={outstandingBalance === "0.00"}
                                                                                        className={outstandingBalance === "0.00" ? 'content-light-grey' : undefined}
                                                                                    >
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <FAIcon className="textDefault" type="thin" size="xlarge" icon="university" button />
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Box pt={1}>
                                                                                                    <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                                                                        Bank Transfer
                                                                                                    </Typography>
                                                                                                    <Typography className="textDefault pl-1" variant="body2" style={{textTransform: "none"}}>
                                                                                                        <FAIcon icon="info-circle" className="mr-05" size={13} button /> Customer has paid by bank transfer
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ToggleButton>
                                                                                </Grid>
                                                                            )}
                                                                            {access.card && (
                                                                                <Grid item xs={12} lg={3} align="left">
                                                                                    <ToggleButton
                                                                                        style={{width: '100%', height: 250}}
                                                                                        onClick={() => this.handlePayment("Card")}
                                                                                        disabled={outstandingBalance === "0.00"}
                                                                                        className={outstandingBalance === "0.00" ? 'content-light-grey' : undefined}
                                                                                    >
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <FAIcon className="textDefault" type="thin" size="xlarge" icon="credit-card-front" button />
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Box pt={1}>
                                                                                                    <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                                                                        Credit / Debit Card
                                                                                                    </Typography>
                                                                                                    <Typography className="textDefault pl-1" variant="body2" style={{textTransform: "none"}}>
                                                                                                        <FAIcon icon="info-circle" className="mr-05" size={13} button /> Customer is paying now by credit / debit card
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ToggleButton>
                                                                                </Grid>
                                                                            )}
                                                                            {access.cheque && (
                                                                                <Grid item xs={12} lg={3} align="left">
                                                                                    <ToggleButton
                                                                                        style={{width: '100%', height: 250}}
                                                                                        onClick={() => this.handlePayment("Cheque")}
                                                                                        disabled={outstandingBalance === "0.00"}
                                                                                        className={outstandingBalance === "0.00" ? 'content-light-grey' : undefined}
                                                                                    >
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <FAIcon className="textDefault" type="thin" size="xlarge" icon="money-check-edit" button />
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Box pt={1}>
                                                                                                    <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                                                                        Cheque
                                                                                                    </Typography>
                                                                                                    <Typography className="textDefault pl-1" variant="body2" style={{textTransform: "none"}}>
                                                                                                        <FAIcon icon="info-circle" className="mr-05" size={13} button /> Customer has issued a cheque to pay
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ToggleButton>
                                                                                </Grid>
                                                                            )}
                                                                            {access.cash && order?.delivery_carriage?.courier_conf_collection === 1 && (
                                                                                <Grid item xs={12} lg={3} align="left">
                                                                                    <ToggleButton
                                                                                        style={{width: '100%', height: 250}}
                                                                                        onClick={() => this.handlePayment("Cash")}
                                                                                        disabled={outstandingBalance === "0.00"}
                                                                                        className={outstandingBalance === "0.00" ? 'content-light-grey' : undefined}
                                                                                    >
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <FAIcon className="textDefault" type="thin" size="xlarge" icon="car-building" button />
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Box pt={1}>
                                                                                                    <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                                                                        Cash on Collection
                                                                                                    </Typography>
                                                                                                    <Typography className="textDefault pl-1" variant="body2" style={{textTransform: "none"}}>
                                                                                                        <FAIcon icon="info-circle" className="mr-05" size={13} button /> Customer is paying on collection
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ToggleButton>
                                                                                </Grid>
                                                                            )}
                                                                            {access.sendProforma && (
                                                                                <Grid item xs={12} lg={3} align="left">
                                                                                    <ToggleButton
                                                                                        style={{width: '100%', height: 250}}
                                                                                        onClick={this.handleDeploySendProforma}
                                                                                        disabled={outstandingBalance === "0.00"}
                                                                                        className={outstandingBalance === "0.00" ? 'content-light-grey' : undefined}
                                                                                    >
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <FAIcon className="textDefault" type="thin" size="xlarge" icon="envelope" button />
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Box pt={1}>
                                                                                                    <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                                                                        Send Proforma
                                                                                                    </Typography>
                                                                                                    <Typography className="textDefault pl-1" variant="body2" style={{textTransform: "none"}}>
                                                                                                        <FAIcon icon="info-circle" className="mr-05" size={13} button /> E-mail proforma with payment options
                                                                                                    </Typography>
                                                                                                    {_.some(order.emails, el => el.oe_type === "Proforma") && (
                                                                                                        <Box mb={-3}>
                                                                                                            <Typography className="pl-1 textSuccess fw-400 pt-1" variant="body2" style={{textTransform: "none"}}>
                                                                                                                <FAIcon icon="check" className="mr-05" size={13} button />Proforma Sent: {momentFormatDateTime(_.find(_.orderBy(order.emails, 'oe_id', 'desc'), _el => _el.oe_type === "Proforma")?.oe_created_datetime ?? null)}
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    )}
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ToggleButton>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <Box p={3}>
                                                        <Grid container spacing={1} alignItems='center'>
                                                            <Grid item xs>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Payments
                                                                </Typography>    
                                                            </Grid>
                                                            {(!_.isEmpty(order.payments) && (
                                                                <Grid item xs={12}>
                                                                    <SearchTransactionsTable 
                                                                        isLoading={isLoading} 
                                                                        orderReference={order.order_reference} 
                                                                        rows={order.payments} 
                                                                        inline 
                                                                    />
                                                                </Grid>
                                                            )) || (
                                                                <Grid item xs={12}>
                                                                    <Alert variant="outlined" severity="error">
                                                                        <strong>None -</strong> No payments have been made towards this sales order
                                                                    </Alert>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                            {_.isEmpty(order.payments) && access.skipPayment && (
                                                <Grid item xs={12} align='right'>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => this.props.deployConfirmation(<>Are you sure you want to skip payment for this order?<br />This will progress the order to processing...</>, () => this.skipPayment())}
                                                        variant="contained"
                                                        className={`btn-error textLight`}
                                                    >
                                                        <FAIcon icon="exclamation-triangle" size={15} buttonPrimary />
                                                        Skip Payment
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </>
                                ))}
                            </Grid> 
                        </>
                    )}
                </Grid>
                <Dialog
                    disableEscapeKeyDown={true} 
                    disableBackdropClick={true} 
                    maxWidth={'sm'}
                    open={dialog}
                    onClose={this.handleDialogClose}
                    fullWidth={true}
                >
                    <DialogContent className="pr-3 pb-3 pl-3">
                        <Grid container spacing={2}>
                            <Grid item xs={12} align='center'>
                                <Box pt={4}>
                                    <FAIcon
                                        noMargin 
                                        size="xlarge" 
                                        type="thin"
                                        icon={
                                            (formData.method === 'Bank Transfer' && (
                                                'university'
                                            )) || (formData.method === 'Card' && (
                                                'credit-card-front'
                                            )) || (formData.method === 'Cash' && (
                                                'coins'
                                            )) || (formData.method === 'Cheque' && (
                                                'money-check-edit'
                                            ))
                                        } 
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Typography variant="h6">
                                    {(formData.method === 'Bank Transfer' && (
                                        'Bank Transfer'
                                    )) || (formData.method === 'Card' && (
                                        'Credit / Debit Card'
                                    )) || (formData.method === 'Cash' && (
                                        'Cash On Collection'
                                    )) || (formData.method === 'Cheque' && (
                                        'Cheque'
                                    ))}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box ml={4} mr={4} mt={2} mb={3}>
                                    <Box className="content-light-grey" p={5}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} align='center'>
                                                {(formData.method === 'Bank Transfer' && (
                                                    <>
                                                        <TextField
                                                            name="payeeName"
                                                            label="Payee Name*"
                                                            margin="none" 
                                                            autoComplete="off"
                                                            error={formErrors && formErrors['payeeName'] && true}
                                                            helperText={formErrors && formErrors['payeeName']}
                                                            value={formData.payeeName}
                                                            onChange={this.handleChange}
                                                            fullWidth
                                                        />
                                                        {/* <TextField
                                                            name="sortCode"
                                                            label="Sort Code *"
                                                            margin="none" 
                                                            autoComplete="off"
                                                            error={formErrors && formErrors['sortCode'] && true}
                                                            helperText={formErrors && formErrors['sortCode']}
                                                            value={formData.sortCode}
                                                            onChange={this.handleSortCode}
                                                            inputProps={{
                                                                maxLength: 8
                                                            }}
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            name="accNo"
                                                            label="Account Number *"
                                                            margin="none" 
                                                            autoComplete="off"
                                                            error={formErrors && formErrors['accNo'] && true}
                                                            helperText={formErrors && formErrors['accNo']}
                                                            value={formData.accNo}
                                                            onChange={this.handleChange}
                                                            inputProps={{
                                                                maxLength: 8
                                                            }}
                                                            fullWidth
                                                        /> */}
                                                    </>
                                                )) || (formData.method === 'Card' && (
                                                    <>
                                                        <AutoCompleteSelect
                                                            options={[
                                                                {
                                                                    label: "Credit Card",
                                                                    value: "Credit Card"
                                                                },
                                                                {
                                                                    label: "Debit Card",
                                                                    value: "Debit Card"
                                                                }
                                                            ]}
                                                            adornment="credit-card-front"
                                                            label="Card Type *"
                                                            value={formData.cardType}
                                                            onChange={(v) => this.handleSelectChange("cardType", v)}
                                                            error={formErrors && formErrors['cardType'] && true}
                                                            errorText={formErrors && formErrors['cardType']}
                                                            margin="none"
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            name="cardAuthNo"
                                                            label="Authorisation Number *"
                                                            value={formData.cardAuthNo}
                                                            onChange={this.handleChange}
                                                            margin="none"
                                                            error={formErrors && formErrors['cardAuthNo'] && true}
                                                            helperText={formErrors && formErrors['cardAuthNo']}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="receipt" size="small" noMargin /></InputAdornment>
                                                            }}
                                                            fullWidth
                                                        />
                                                    </>
                                                )) || (formData.method === 'Cheque' && (
                                                    <>
                                                        <TextField
                                                            name="chequePayee"
                                                            label="Cheque Payee *"
                                                            value={formData.chequePayee}
                                                            onChange={this.handleChange}
                                                            error={formErrors && formErrors['chequePayee'] && true}
                                                            helperText={formErrors && formErrors['chequePayee']}
                                                            margin="none"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="user" size="small" noMargin /></InputAdornment>
                                                            }}
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            name="chequeNo"
                                                            label="Cheque Number *"
                                                            value={formData.chequeNo}
                                                            onChange={this.handleChange}
                                                            error={formErrors && formErrors['chequeNo'] && true}
                                                            helperText={formErrors && formErrors['chequeNo']}
                                                            margin="none"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="money-check-alt" size="small" noMargin /></InputAdornment>
                                                            }}
                                                            fullWidth
                                                        />
                                                        <DragFileInput
                                                            id="chequeImg"
                                                            name="chequeImg"
                                                            type="cheque image"
                                                            label={`Upload cheque image (.png, .jpg, .jpeg, .bmp, .gif)`}
                                                            icon="file-image"
                                                            file={formData.chequeImg}
                                                            errorText={formErrors && formErrors['chequeImg']}
                                                            onChange={this.handleFileChange}
                                                            cancelOnClick={this.handleClearChequeImg}
                                                            emptyText='No image(s) selected'
                                                        />
                                                        <br />
                                                        <br />
                                                    </>
                                                ))}
                                                <TextField
                                                    name="paymentAmount"
                                                    label="Payment Amount *"
                                                    value={formData.paymentAmount}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleFloat}
                                                    error={formErrors && formErrors['paymentAmount'] && true}
                                                    helperText={formErrors && formErrors['paymentAmount']}
                                                    type='number'
                                                    margin="none"
                                                    inputProps={{
                                                        min: 0,
                                                        max: outstandingBalance,
                                                        step: 0.01
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" noMargin /></InputAdornment>
                                                    }}
                                                    fullWidth
                                                />
                                                <Box pt={1}>
                                                    <Typography variant="caption" color="textSecondary" component="div">
                                                        Max. {currencyFormat.format(outstandingBalance)}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Grid container spacing={2}>
                                    <Grid item xs />
                                    <Grid item>
                                        <Button 
                                            onClick={this.handleDialogClose}
                                            variant="text"
                                        >
                                            <FAIcon icon="times" size={15} button />
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button 
                                            onClick={() => this.props.deployConfirmation("Are you sure you want to process this payment?", this.submit)}
                                            variant="text" 
                                            color="primary" 
                                            disabled={(formData.paymentAmount === "" || formData.paymentAmount === "0" || formData.paymentAmount === "0.00" || formData.paymentAmount < 0 || parseFloat(formData.paymentAmount) > parseFloat(order.order_total_gross))}
                                        >
                                            <FAIcon icon="check" size={15} button />
                                            Confirm
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default AwaitingPayment;