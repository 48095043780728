import _ from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import ViewContactCard from 'Components/Common/Activity/ViewContactCard';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

import WildixContext from "Context/WildixContext";

const ViewContacts = ({access, ContactForm, callback, contacts, disabled, forSupplier, handleContactSms, name}) => {

    const context = useContext(WildixContext);
    const canDial = context?.dial === null;

    const dispatch = useDispatch();

    const viewContact = (contact) => {
        dispatch(deployDialog(
            <ViewContactCard
                access={access}
                contact={contact}
                handleContactSms={handleContactSms}
                toggleDialog={() => dispatch(closeDialog())}
            />, true, "", "standard", "sm", false, true
        ))
    }

    const handleAdd = () => dispatch(deployDialog(React.cloneElement(ContactForm, { firstContact: _.isEmpty(contacts)}), false, "Add Contact", "standard","sm", false, true));

    const handleModify = id => dispatch(deployDialog(React.cloneElement(ContactForm, { updateId: id}), false, "Modify Contact", "standard","sm", false, true));

    const handleDelete = id => dispatch(deployConfirmation("Are you sure you want to delete this contact?", () => confirmDelete(id)));

    const confirmDelete = id => {
        API.put(`/misc/contacts/${id}/delete`)
        .then(res => {
            if(res?.data) {
                if(callback) {
                    callback();
                }
            }
        })
    }

    const cols = forSupplier ? [
        {
            heading: 'Primary',
            field: rowData => <FAIcon type="thin" icon={rowData.p === 1 ? 'check' : 'times'} noMargin />,
            alignment: 'center',
            sizeToContent: true
        },
        {
            heading: 'Accounts',
            field: rowData => <FAIcon type="thin" icon={rowData.a === 1 ? 'check' : 'times'} noMargin />,
            alignment: 'center',
            sizeToContent: true
        },
        {
            heading: 'Name',
            field: rowData => (`${rowData.fn && (rowData.fn + ' ')}${rowData.ln}`),
            dataRef: 'fn',
            important: true,
            main: true,
        },
        {
            heading: 'Position',
            field: rowData => (rowData.po && rowData.po) || `${forSupplier ? 'Supplier' : 'Customer'} Contact`,
            dataRef: 'po',
        },
        {
            heading: 'Telephone',
            field: rowData => (rowData.t.length > 0 && ( 
                                    canDial ? (
                                        <Link className="cursorPointer textDefault" onClick={() => context.useDial(rowData.t)}>
                                            {rowData.t}
                                            <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                        </Link>
                                    ) : rowData.t
                                )) || '-',
            dataRef: 't',
        },
        {
            heading: 'Mobile',
            field: rowData => (rowData.m.length > 0 && ( 
                                    canDial ? (
                                        <Link className="cursorPointer textDefault" onClick={() => context.useDial(rowData.m)}>
                                            {rowData.m}
                                            <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                        </Link>
                                    ) : rowData.m
                                )) || '-',
            dataRef: 'm',
        },
        {
            heading: 'Email',
            field: rowData => (rowData.e.length > 0 && ( <Link className="cursorPointer textDefault" href={`mailto:${rowData.e}`}>{`${rowData.e}`}</Link> )) || '-',
            dataRef: 'e',
        },
        {
            heading: 'GDPR Opt Out',
            field: rowData => !rowData.goi, //inverted as db is opt in
            dataRef: 'goi',
            fieldFormat: 'boolean',
            alignment: 'center'
        },
        {
            heading: 'Mailshot Bar',
            field: rowData => rowData.msb,
            dataRef: 'msb',
            fieldFormat: 'boolean',
            alignment: 'center'
        },
        {
            actions: rowData => {
                return [
                    {name: 'View', icon: 'binoculars', onClick: () => viewContact(rowData), doubleClick: true},
                    {name: 'Modify', icon: 'pencil', onClick: () => handleModify(rowData.i), disabled: disabled || !access.modifyContact},
                    {name: 'Delete', icon: 'trash-alt', onClick: () => handleDelete(rowData.i), disabled: disabled || !access.deleteContact}
                ]
            }
        }
    ] : [
        {
            heading: 'Primary',
            field: rowData => <FAIcon type="thin" icon={rowData.p === 1 ? 'check' : 'times'} noMargin />,
            alignment: 'center',
            sizeToContent: true
        },
        {
            heading: 'Accounts',
            field: rowData => <FAIcon type="thin" icon={rowData.a === 1 ? 'check' : 'times'} noMargin />,
            alignment: 'center',
            sizeToContent: true
        },
        {
            heading: 'Name',
            field: rowData => (`${rowData.fn && (rowData.fn + ' ')}${rowData.ln}`),
            dataRef: 'fn',
            important: true,
            main: true,
        },
        {
            heading: 'Position',
            field: rowData => (rowData.po && rowData.po) || `${forSupplier ? 'Supplier' : 'Customer'} Contact`,
            dataRef: 'po',
        },
        {
            heading: 'Telephone',
            field: rowData => (rowData.t.length > 0 && ( 
                                    canDial ? (
                                        <Link className="cursorPointer textDefault" onClick={() => context.useDial(rowData.t)}>
                                            {rowData.t}
                                            <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                        </Link>
                                    ) : rowData.t
                                )) || '-',
            dataRef: 't',
        },
        {
            heading: 'Mobile',
            field: rowData => (rowData.m.length > 0 && ( 
                                    canDial ? (
                                        <Link className="cursorPointer textDefault" onClick={() => context.useDial(rowData.m)}>
                                            {rowData.m}
                                            <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                        </Link>
                                    ) : rowData.m
                                )) || '-',
            dataRef: 'm',
        },
        {
            heading: 'Email',
            field: rowData => (rowData.e.length > 0 && ( <Link className="cursorPointer textDefault" href={`mailto:${rowData.e}`}>{`${rowData.e}`}</Link> )) || '-',
            dataRef: 'e',
        },
        {
            heading: 'Delivery Notifications',
            field: rowData => rowData.ndu === 0 ? true : false,
            dataRef: 'ndu',
            fieldFormat: 'boolean',
            alignment: 'center'
        },
        {
            heading: 'Order Emails',
            field: rowData => rowData.noe === 0 ? true : false,
            dataRef: 'noe',
            fieldFormat: 'boolean',
            alignment: 'center'
        },
        {
            heading: 'GDPR Opt Out',
            field: rowData => !rowData.goi, //inverted as db is opt in
            dataRef: 'goi',
            fieldFormat: 'boolean',
            alignment: 'center'
        },
        {
            heading: 'Mailshot Bar',
            field: rowData => rowData.msb,
            dataRef: 'msb',
            fieldFormat: 'boolean',
            alignment: 'center'
        },
        {
            actions: rowData => {
                return [
                    {name: 'View', icon: 'binoculars', onClick: () => viewContact(rowData), doubleClick: true},
                    {name: 'Modify', icon: 'pencil', onClick: () => handleModify(rowData.i), disabled: disabled || !access.modifyContact},
                    {name: 'Delete', icon: 'trash-alt', onClick: () => handleDelete(rowData.i), disabled: disabled || !access.deleteContact}
                ]
            }
        }
    ];
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper>
                    <DataTable  
                        config={{
                            key: 'i',
                            pagination: true,
                            alternatingRowColours: true,
                            // responsiveImportance: true,
                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="exclamation-circle" size={25} className="textWarning mr-1" />Please add a <span className="linkUnderline" onClick={!disabled ? handleAdd : undefined}>primary contact</span> for this account</Typography></Box>,
                            options: {
                                headingInput: (
                                    ContactForm && access.addContact && (
                                        <Button
                                            variant="text"
                                            color="primary"
                                            size="small"
                                            disabled={disabled}
                                            onClick={handleAdd}
                                        >
                                            <FAIcon type="light" icon="plus-circle" size={15} button />
                                            Add New Contact
                                        </Button>
                                    )
                                ),
                                dataRef: true,
                                export: {
                                    title: `Contacts - ${name}`,
                                    name: `${name}-Contact-List`,
                                    excel: true,
                                    pdf: true,
                                    print: true
                                }
                            }
                        }}
                        columns={cols}
                        rows={_.orderBy(contacts, el => el.p, 'desc')}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default ViewContacts;