import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DefaultAvatar from 'Assets/Images/defaultAvatar.png';
import { CLOUDFRONT_URL } from 'Constants';

const StaffTile = ({minimal, missingText, noPaper, onClick, staffId, title}) => {

    const history = useHistory();

    const staff = useSelector(state => state.notifications.status);

    const Element = noPaper ? Box : Paper;

    const handleClick = onClick => {
        if(typeof onClick === "function") {
            onClick();
        } else {
            history.push(onClick);
        }
    }
    
    return (
        <Element>
           <Grid container spacing={2} alignItems='center' className={`${onClick ? ' link' : ''}`} onClick={() => onClick && handleClick(onClick)}>      
                <Grid item>
                    {(staff[staffId] && (
                        <Avatar alt={staff[staffId].name} src={`${CLOUDFRONT_URL}${staff[staffId].picture}`} width={35} height={35} />
                    )) || (
                        <Avatar alt={'Unknown'} src={DefaultAvatar} width={35} height={35} />
                    )}
                </Grid>
                <Grid item xs>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} align='left'>
                            <Typography variant='h5' className="textDefault">
                                {staff?.[staffId]?.name ? (minimal ? `${staff[staffId].name.split?.(" ")[0]} ${staff[staffId].name.split?.(" ")[1].charAt(0)}`  : staff[staffId].name) : missingText ?? 'Unknown'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align='left'>
                            <Typography variant="caption">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>  
            </Grid>
        </Element>
    )
}

export default StaffTile;