import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import API from 'API';
import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { getPresignedUrl } from 'Functions/S3Functions';

const getLegacyConsignmentStockEditPackAwayReport = id => {
    API.get(`/inventory/adjustment/consignment/${id}/legacy/pdf`)
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const SearchConsignmentStockEdits = ({pageTitle}) => {
    
    const staff = useSelector(state => state.notifications.status);

    return (
        <Search
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/inventory/adjustment/consignment',
                columns: [
                    {
                        heading: 'Ref',
                        field: rowData => rowData.id,
                        dataRef: 'id',
                        important: true,
                        main: true
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.su,
                        dataRef: 'su',
                        important: true,
                    },
                    {
                        heading: 'Lines Received',
                        field: rowData => rowData.li,
                        dataRef: 'li',
                        important: true,
                    },
                    {
                        heading: 'Quantity Received',
                        field: rowData => rowData.qt,
                        dataRef: 'qt',
                        important: true,
                    },
                    {
                        heading: 'Total Buy Price',
                        field: rowData => rowData.co,
                        fieldFormat: 'currency',
                        dataRef: 'co',
                        important: true,
                    },
                    {
                        heading: 'Total Value',
                        field: rowData => rowData.pr,
                        fieldFormat: 'currency',
                        dataRef: 'pr',
                        important: true,
                    },
                    {
                        heading: 'Received By',
                        field: rowData => <StaffChip staff={staff[rowData.st]} />,
                        fieldFormat: 'staff',
                        dataRef: 'st',
                        important: true,
                    },
                    {
                        heading: 'Received',
                        field: rowData => rowData.cr,
                        fieldFormat: 'datetime',
                        dataRef: 'cr',
                        important: true,
                    },
                    {
                        actions: rowData => {
                            return [
                                { name: 'Download Pack Away PDF', icon: 'file-pdf', color: 'error',  onClick: () => _.isEmpty(rowData.fk) ? getLegacyConsignmentStockEditPackAwayReport(rowData.id) : getPresignedUrl(rowData.fk)}
                            ]
                        }
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'id',
                name: 'consignment-stock-edits',
                noResultsText: 'Sorry, no consignment stock edits were found using your search criteria',
                pagination: true,
                pageTitleData: [0, 'Warehouse', 'Consignment Stock Edits'],
                pdf: true,
                persistenceId: 'consignment-stock-edits',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: false,
                showSupplier: true,
                showStaff: false,
                showTotals: false,
                title: 'Consignment Stock Edits'
            }}
        />
    )
}

export default SearchConsignmentStockEdits;