import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import LinearProgress from '@material-ui/core/LinearProgress';

const LoadingBar = () => {
    const loading = useSelector(state => state.loading)
    return (
        <LinearProgress variant={_.isEmpty(loading) ? 'determinate' : 'indeterminate'} value={100} />
    )
}

export default LoadingBar;