import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Box } from "@material-ui/core";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { ENVIRONMENT } from "Constants";
import { setLastActivePage } from "Functions/AuthFunctions";
import { isTablet } from "Functions/MiscFunctions";
import { handleAppError } from "Redux/Actions/UI/UI";
import AdminRouteSwitcher from "../../Routes/AdminRoutes/AdminRouteSwitcher";

const offset = isTablet() ? 45 : 50;

const styles = (theme) => ({
    pwaBarSpacer: {
        minHeight: "env(safe-area-inset-top)",
    },
    appBarSpacer: {
        minHeight: isTablet() ? 85 : 90,
    },
    chevronIcon: {
        width: "12px!important",
        height: "12px!important",
        marginLeft: 7,
        marginRight: 7,
    },
    content: {
        width: "100%",
        height: "100vh",
        paddingBottom: 24,
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "initial!important",
        },
    },
    container: {
        padding: theme.spacing(3),
    },
    page: {
        position: "fixed",
        top: 0,
        left: 0,
        height: 40,
        marginTop: offset,
        padding: theme.spacing(1.25, 1, 1, 1),
        background: "#fff",
        borderBottom: "1px solid #ddd",
    },
    [theme.breakpoints.down("sm")]: {
        content: {
            marginLeft: 0,
        },
        page: {
            marginLeft: 0,
        },
        servicePane: {
            display: "none",
        },
    },
    [theme.breakpoints.down("xs")]: {
        container: {
            padding: theme.spacing(2.5),
            paddingLeft: "24px!important",
        },
        page: {
            paddingLeft: "24px!important",
        },
        content: {
            marginLeft: 0,
            webkitOverflowScrolling: "touch",
        },
    },
    // ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
    //     content: {
    //         marginLeft: 'env(safe-area-inset-left)',
    //         marginRight: 'env(safe-area-inset-right)',
    //     },
    //     container: {
    //         padding: theme.spacing(2.5),
    //         paddingLeft: '24px!important'
    //     },
    //     page: {
    //         paddingLeft: '24px!important'
    //     }
    // },
});

const initialState = {
    scrollHeight: window.innerHeight,
    scheduledUpdateColor: "#d32f2f",
    scheduledUpdateTime: false,
    scheduledUpdateTimeCountdown: false,
};

class AdminContentWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.countdown = false;
        this.main = null;
    }

    componentDidMount = () => {
        this.setState(
            {
                scrollHeight: this.props.scrollHeight,
                scheduledUpdateTime: this.props.ui.updateNotification,
            },
            () => {
                if (this.state.scheduledUpdateTime !== false) {
                    this.startScheduledUpdateTime();
                }
            }
        );
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.ui.updateNotification !== prevProps.ui.updateNotification) {
            this.setState(
                {
                    scheduledUpdateTime: this.props.ui.updateNotification,
                },
                () => {
                    if (this.state.scheduledUpdateTime !== false) {
                        this.startScheduledUpdateTime();
                    }
                }
            );
        }
        if (this.props.scrollHeight !== prevProps.scrollHeight) {
            this.setState({
                scrollHeight: this.props.scrollHeight,
            });
        }
        if (this.props.pagePath !== prevProps.pagePath) {
            this.main.scrollTop = 0;
        }
    };

    componentWillUnmount = () => {
        if (this.countdown) clearInterval(this.countdown);
    };

    startScheduledUpdateTime = () => {
        this.countdown = setInterval(this.getScheduledUpdateTimeCountdown, 1000);
    };

    getScheduledUpdateTimeCountdown = () => {
        var scheduled = moment(this.state.scheduledUpdateTime, "X").subtract(10, "seconds");
        const now = moment();
        if (now.unix() < scheduled.unix()) {
            const countdown = moment(scheduled - now);
            const mins = parseInt(countdown.format("m"));
            const countdownText = mins !== 0 ? countdown.format("m [ minutes] [and] s [ seconds]") : countdown.format("s[ seconds]");
            this.setState({
                scheduledUpdateColor: mins > 1 ? "#F4511E" : "#d32f2f",
                scheduledUpdateTimeCountdown: this.state.updateNotification !== false ? countdownText : "",
            });
        } else {
            if (this.countdown) {
                clearInterval(this.countdown);
            }
            setLastActivePage(this.props.location.pathname);
            this.setState(
                {
                    scheduledUpdateTimeCountdown: "",
                },
                () => this.props.handleAppError("APP_UPDATE", "")
            );
        }
    };

    scrollToTop = () => {
        if(this.main?.scrollTop) {
            this.main.scrollTop = 0;
        }
    };

    pageTitleLink = (idx, title, pageTitle) => {
        let { classes } = this.props,
            path,
            defaultText,
            icon,
            iconType = "thin",
            link = idx <= pageTitle[0],
            showDefaultText = pageTitle.length === 2 ? true : false,
            seperator = idx !== pageTitle.length - 1 ? true : false,
            chevron = <FAIcon type="thin" icon="chevron-right" className={classes.chevronIcon} />;

        switch (title) {
            case "Dashboard":
                icon = "home";
                path = "/dashboard";
                defaultText = false;
                break;
            case "Action Centre":
                icon = "bell";
                path = "/action-centre";
                defaultText = false;
                break;
            case "Calendar":
                icon = "calendar-alt";
                path = false;
                defaultText = false;
                break;
            case "Card DB":
                icon = "id-card";
                path = false;
                defaultText = "Orders";
                break;
            case "Customers":
                icon = "users";
                path = "/customers";
                defaultText = "Search";
                break;
            case "Communication":
                icon = "address-book";
                path = "/communication";
                defaultText = "Overview";
                break;
            case "Finance":
                icon = "coins";
                path = false;
                defaultText = false;
                break;
            case "Invoices":
                icon = "invoice";
                path = "/invoices";
                defaultText = "Search";
                break;
            case "My Account":
                icon = "user";
                path = false;
                defaultText = false;
                break;
            case "Marketing":
                icon = "bullseye-pointer";
                path = false;
                defaultText = "Overview";
                break;
            case "Meetings":
                icon = "webcam";
                path = "/meetings";
                defaultText = "Search";
                break;
            case "New Business":
                icon = "user-chart";
                path = "/new-business";
                defaultText = "Dashboard";
                break;
            case "Products":
                icon = "boxes";
                path = "/products";
                defaultText = "Search";
                break;
            case "Purchase Orders":
                icon = "file-contract";
                path = "/purchase-orders";
                defaultText = "Search";
                break;
            case "Quotes":
                iconType = "thin";
                icon = "file-contract";
                path = "/quotes";
                defaultText = "Search";
                break;
            case "RMA":
                icon = "exchange";
                path = "/rma";
                defaultText = "Search";
                break;
            case "Reporting":
                icon = "chart-line";
                path = false;
                defaultText = false;
                break;
            case "Sales Orders":
                iconType = "thin";
                icon = "file-contract";
                path = "/sales";
                defaultText = "Search";
                break;
            case "Settings":
                icon = "cog";
                path = false;
                defaultText = false;
                break;
            case "Staff":
                icon = "user";
                path = "/staff";
                defaultText = "Search";
                break;
            case "Stock":
                icon = "inventory";
                path = "/stock";
                defaultText = "Management";
                break;
            case "Suppliers":
                icon = "box-check";
                path = "/suppliers";
                defaultText = "Search";
                break;
            case "Support":
                icon = "life-ring";
                path = "/support";
                defaultText = false;
                break;
            case "Targets":
                icon = "chart-bar";
                path = "/targets";
                defaultText = false;
                break;
            case "Teams":
                icon = "users-class";
                path = "/teams";
                defaultText = "Search";
                break;
            case "Technical":
                icon = "screwdriver-wrench";
                path = false;
                defaultText = false;
                break;
            case "Warehouse":
                icon = "warehouse-alt";
                path = "/warehouse";
                defaultText = false;
                break;
            default:
                icon = "exclamation-triangle";
                path = false;
                defaultText = false;
        }
        return (
            <React.Fragment key={idx}>
                {idx === 1 && (
                    <Grid item>
                        <FAIcon type={iconType} icon={icon} size={15} button={(path && link && true) || false} />
                    </Grid>
                )}
                {(path && link && (
                    <>
                        <Grid item>
                            <Link
                                to={path}
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                }}
                            >
                                {title}
                            </Link>
                        </Grid>
                        {seperator && <Grid item>{chevron}</Grid>}
                        {showDefaultText && defaultText && (
                            <>
                                <Grid item>{chevron}</Grid>
                                <Grid item>{defaultText}</Grid>
                            </>
                        )}
                    </>
                )) || (
                    <>
                        <Grid item>{title}</Grid>
                        {seperator && <Grid item>{chevron}</Grid>}
                        {showDefaultText && defaultText && (
                            <>
                                <Grid item>{chevron}</Grid>
                                <Grid item>{defaultText}</Grid>
                            </>
                        )}
                    </>
                )}
            </React.Fragment>
        );
    };

    render() {
        const { scrollHeight, scheduledUpdateTime, scheduledUpdateColor, scheduledUpdateTimeCountdown } = this.state;
        const { classes, changePath, page, pageTitle, ui } = this.props;
        const isMobile = this.props.ui.device.isMobile;
        const _pageTitle = _.filter(page, (el, idx) => idx === 0 || el);
        return (
            <>
                <main ref={(el) => (this.main = el)} className={classes.content} style={{ height: window.matchMedia("(display-mode: standalone)").matches ? `100vh` : `${scrollHeight}px` }}>
                    <div className={classes.pwaBarSpacer} />
                    <div className={classes.appBarSpacer} />
                    <AppBar color="secondary" position="absolute" elevation={0} className={classes.page} style={{ paddingLeft: this.props.drawers?.nav ? (ui?.device?.isTablet ? 219 : 259) : 77 }}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} md={7}>
                                <Box pt={0.15}>
                                    <Grid container alignItems="center">
                                        {_.map(_pageTitle, (el, idx) => {
                                            if (idx >= 1) {
                                                return this.pageTitleLink(idx, el, _pageTitle);
                                            }
                                        })}
                                    </Grid>
                                </Box>
                            </Grid>
                            {(ENVIRONMENT !== "PRODUCTION" && (
                                <>
                                    <Grid item className={classes.servicePane} md={5} align="right">
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs />
                                            <Grid item>
                                                <Typography variant="body1" className="fw-300 textError">
                                                    ** Important ** You are using the{' '}
                                                    {ENVIRONMENT === "LOCAL"
                                                        ? "RED Local Environment"
                                                        : ENVIRONMENT === "DEVELOPMENT"
                                                        ? "RED Development Environment"
                                                        : ENVIRONMENT === "TRAINING"
                                                        ? "RED Training Environment"
                                                    : ""}
                                                </Typography>
                                            </Grid>
                                            </Grid>
                                    </Grid>
                                </>
                            )) || (
                                <>
                                    {scheduledUpdateTime !== false && scheduledUpdateTimeCountdown !== false && (
                                        <Grid item className={classes.servicePane} md={5} align="right" style={{ cursor: "pointer" }}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs />
                                                <Grid item style={{ paddingTop: 7.5 }}>
                                                    <FAIcon type="thin" icon="exclamation-triangle" size={isMobile ? "small" : ""} style={{ color: scheduledUpdateColor }} noMargin />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="caption" style={{ color: scheduledUpdateColor }}>
                                                        {(scheduledUpdateTimeCountdown !== "" && <>{`An update ${!isMobile ? `for RED ` : ``} will begin in ${scheduledUpdateTimeCountdown} ${(!isMobile && ` - please save your work before the update starts!`) || ``}`}</>) || (
                                                            <>An update for RED is starting now...</>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </AppBar>
                    <div className={classes.container} style={{ paddingLeft: this.props.drawers?.nav ? (ui?.device?.isTablet ? 219 : 259) : 77 }}>
                        <AdminRouteSwitcher changePath={changePath} pageTitle={pageTitle} scrollToTop={this.scrollToTop} deploying={this.props.deploying} deploymentId={this.props.deploymentId} />
                    </div>
                </main>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        ui: state.ui,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminContentWrapper));
