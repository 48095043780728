import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Details = ({classes, history, order}) => {
    let company = order?.customer?.cust_company_name, 
        contact = false, 
            tel = false, 
          email = false;

    if(order.customer_contact) {
        contact = `${order?.customer_contact?.contact_first_name} ${order?.customer_contact?.contact_last_name}`;
    } else {
        if(!_.isEmpty(order?.order_contact_name)) {
            contact = order.order_contact_name;
        } else {
            contact = order.customer?.cust_company_name;
        }
    }
    
    if(!_.isEmpty(order?.order_email))
        email = order.order_email;

    if(!_.isEmpty(order?.customer_contact?.contact_telephone))
        tel = order.order_phone;

    return (
        <Grid container spacing={2}>
            {company && (
                <Grid item xs={12} align='center'>
                    <Typography variant="caption" component="div" color="textSecondary">
                        Company
                    </Typography>
                    <Typography variant="caption" component="div">
                        {company}
                    </Typography>
                </Grid>
            )}
            {contact && (
                <Grid item xs={12} align='center'>
                    <Typography variant="caption" component="div" color="textSecondary">
                        Contact Name
                    </Typography>
                    <Typography variant="caption" component="div">
                        {contact}
                    </Typography>
                </Grid>
            )}
            {tel && (
                <Grid item xs={12} align='center'>
                    <Typography variant="caption" component="div" color="textSecondary">
                        Telephone
                    </Typography>
                    <Typography variant="caption" component="div">
                        {tel}
                    </Typography>
                </Grid>
            )}
            {email && (
                <Grid item xs={12} align='center'>
                    <Typography variant="caption" component="div" color="textSecondary">
                        E-mail
                    </Typography>
                    <Typography variant="caption" component="div">
                        {email}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}

export default Details;