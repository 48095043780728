import React from 'react';

import Search from 'Components/Common/Search/Search';

const SupplierSalesByCustomer = () => (
    <Search
        configuration={{
            alternatingRowColours: true,
            apiRoute: '/reporting/supplierSalesByCustomer',
            noAutoLoad: true,
            columns: [
                {
                    heading: 'Product Code',
                    field: rowData => rowData.pc,
                    dataRef: 'pc',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Description',
                    field: rowData => rowData.de,
                    dataRef: 'de',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Customer',
                    field: rowData => rowData.cn,
                    dataRef: 'cn',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Account Number',
                    field: rowData => rowData.cr,
                    dataRef: 'cr',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Period Sales',
                    field: rowData => rowData.ps,
                    dataRef: 'ps',
                    alignment: 'right',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Buy Price',
                    field: rowData => rowData.bp,
                    fieldFormat: 'currency',
                    dataRef: 'bp',
                    alignment: 'right',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Trade Price',
                    field: rowData => rowData.tp,
                    fieldFormat: 'currency',
                    dataRef: 'tp',
                    alignment: 'right',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Supplier',
                    field: rowData => rowData.sn,
                    dataRef: 'sn',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Purchase Ref',
                    field: rowData => rowData.sr,
                    dataRef: 'sr',
                    important: true,
                    sizeToContent: true
                },
            ],
            dataRef: true,
            excel: true,
            isMultiSupplier: false,
            key: 'id',
            name: 'supplier-sales-by-customer',
            noResultsText: 'There are no supplier sales by customer results that match your search criteria',
            pagination: true,
            pageTitleData: [0, 'Reporting', 'Suppliers'],
            pdf: true,
            persistenceId: null,
            placeholderData: false,
            print: true,
            pulseResults: 0,
            responsiveImportance: true,
            rowsPerPage: 50,
            showCategory: true,
            showCustomer: false,
            showDate: false,
            showDateRange: true,
            showFullTotals: false,
            showSearch: false,
            showShipType: false,
            showSupplier: true,
            showTotals: false,
            title: 'Supplier Sales By Customer Report'
        }}
    />
)

export default SupplierSalesByCustomer;