import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import DateSelect from "Components/Common/Selects/DateSelect";
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewAdjustment from 'Components/Stock/Misc/ViewAdjustment';
import { toggleDialog } from 'Functions/MiscFunctions';
import InsightChip from 'Components/Common/Chips/InsightChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    adjustment: {},
    allProducts: [],
    allTypes: [
        {
            label: 'IN',
            value: 'IN',
        },
        {
            label: 'OUT',
            value: 'OUT'
        },
        {
            label: 'NO CHANGE',
            value: 'NC'
        },
    ],
    allReasons: [
        {
            label: 'CONSIGNMENT DELIVERY',
            value: 'CONSIGNMENT DELIVERY'
        },
        {
            label: 'CONSIGNMENT REDUCTION',
            value: 'CONSIGNMENT REDUCTION'
        },
        {
            label: 'DAMAGED IN TRANSIT',
            value: 'DAMAGED IN TRANSIT'
        },
        {
            label: 'KIT BUILD',
            value: 'KIT BUILD'
        },
        {
            label: 'MANUAL RETURN TO STOCK',
            value: 'MANUAL RETURN TO STOCK'
        },
        {
            label: 'MANUAL SHIPMENT',
            value: 'MANUAL SHIPMENT'
        },
        {
            label: 'QUARANTINED',
            value: 'QUARANTINED'
        },
        {
            label: 'STOCK ADDITION',
            value: 'STOCK ADDITION'
        },
        {
            label: 'STOCK ALLOCATED',
            value: 'STOCK ALLOCATED'
        },
        {
            label: 'STOCK COUNT',
            value: 'STOCK COUNT'
        },
        {
            label: 'STOCK COUNT ERROR',
            value: 'STOCK COUNT ERROR'
        },
        {
            label: 'STOCK DE-ALLOCATED',
            value: 'STOCK DE-ALLOCATED'
        },
        {
            label: 'STOCK COLLECTED',
            value: 'STOCK COLLECTED'
        },
        {
            label: 'STOCK DESPATCHED',
            value: 'STOCK DESPATCHED'
        },
        {
            label: 'STOCK MISSING',
            value: 'STOCK MISSING'
        },
        {
            label: 'STOCK ON ORDER',
            value: 'STOCK ON ORDER'
        },
        {
            label: 'STOCK ORDER AMENDED',
            value: 'STOCK ORDER AMENDED'
        },
        {
            label: 'STOCK ORDER CANCELLED',
            value: 'STOCK ORDER CANCELLED'
        },
        {
            label: 'STOCK RECEIVED',
            value: 'STOCK RECEIVED'
        },
        {
            label: 'STOCK WRITE OFF',
            value: 'STOCK WRITE OFF'
        }
    ],
    access: {
        viewProduct: false,
    },
    dialog: {
        viewAdjustment: false,
    },
    dataLoading: true,
    isLoading: true,
    searchResults: [],
    searchString: {
        date: "oneMonth",
        product: '',
        type: '',
        reason: ''
    }
})

class SearchActivity extends React.Component {
    constructor(props) {
        super(props);
        this.persistenceId = this.props.noPersistence ? null : `searchActivity${this.props.productId ? `:p${this.props.productId}` : ``}`;
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = null;
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount = () => {

        this.props.pageTitle?.([0, 'Stock', 'Management']);

        if(!this.hasPageState()) {

            Promise.all([
                API.access('view-product'),
            ])
            .then(([viewProduct]) => {
                this.setState({
                    ...this.state,
                    access: {
                        viewProduct: (viewProduct.data && viewProduct.data.has_access) || false,
                    },
                    searchString: {
                        ...this.state.searchString,
                        product: this.props.productId ? this.props.productId : ''
                    }
                }, () => this.loadComponentData())
            })

        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.innerKey !== prevProps.innerKey) {
            this.getStock(false);
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout)
    }

    loadComponentData = () => {
        this.getStock(true);
    }

    getStock = dataLoading => {

        this.setState({
            dataLoading: dataLoading,
        }, () => {

            const {
                date,
                product,
                type,
                reason
            } = this.state.searchString;

            API.get('/inventory', {
                params: { 
                    date,
                    product,
                    type,
                    reason 
                } 
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        isLoading: false,
                        searchResults: result.data,
                    }, () => {
                        this.savePageState();
                    });
                }
            });
        });
    }

    handleReset = () => {
        this.clearPageState();
        this.timeout = setTimeout(() => {
            if(this.props.productId) {
                this.getStock(true);
            } else {
                this.setState({
                    date: initialState().date,
                    searchString: initialState().searchString
                }, () => this.getStock(true))
            }
        }, 250)
    }
    
    onSearchStringChange = (field, v) => {
        this.setState({
            searchString: {
                ...this.state.searchString,
                [field]: v ? v.value : ''
            }
        },
        () => this.getStock(true));
    }

    render() {
        const { 
            allProducts, 
            inDialog
        } = this.props;

        const {
            adjustment, 
            allReasons, 
            allTypes, 
            dataLoading, 
            isLoading, 
            dialog, 
            searchResults, 
            searchString 
        } = this.state;
     
        const cols = this.props.productId ? 
            [
                {
                    heading: '+/-/=',
                    field: rowData => {
                        return (
                            <>
                                <FAIcon icon={rowData.t === 'OUT' ? 'minus' : (rowData.t === 'IN' ? 'plus' : 'equals')} className={`${rowData.t === 'OUT' ? 'textError' : (rowData.t === 'NC' ? 'textInfo' :'textSuccess')} mr-1`} size={15} noMargin />
                            </>
                        )
                    },
                    alignment: 'right',
                    sizeToContent: true,
                    cellProps: rowData => ({
                        className: rowData.t === 'OUT' ? 'textError' : (rowData.t === 'NC' ? 'textInfo' : 'textSuccess')
                    })
                },
                {
                    heading: 'Reason',
                    field: rowData => rowData.r,
                    dataRef: 'r',
                },
                {
                    heading: 'Prev.',
                    field: rowData => (rowData.t === 'NC') ? '-' : rowData.pr,
                    dataRef: 'prev',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'New',
                    field: rowData => (rowData.t === 'NC') ? '-' : rowData.ne,
                    dataRef: 'new',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'Adj.',
                    field: rowData => (rowData.t === 'NC') ? rowData.ad : `${rowData.t === "IN" ? `+` : `-`}${rowData.ad}`,
                    dataRef: 'ad',
                    sizeToContent: true,
                    alignment: 'right',
                    cellProps: rowData => ({
                        className: rowData.t === 'OUT' ? 'textError' : (rowData.t === 'NC' ? 'textInfo' : 'textSuccess')
                    })
                },
                {
                    heading: 'In Stock',
                    field: rowData => rowData.ins,
                    dataRef: 'ins',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'Available',
                    field: rowData => rowData.ava,
                    dataRef: 'ava',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'Allocated',
                    field: rowData => rowData.all,
                    dataRef: 'all',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'On Order',
                    field: rowData => rowData.ono,
                    dataRef: 'ono',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'Pre-ordered',
                    field: rowData => rowData.pre,
                    dataRef: 'pre',
                    sizeToContent: true,
                    alignment: 'right'
                },
                // {
                //     heading: 'Total Cost',
                //     field: rowData => rowData.co,
                //     dataRef: 'co',
                //     fieldFormat: 'currency',
                //     sizeToContent: true,
                //     alignment: 'right',
                //     cellProps: rowData => ({
                //         className: rowData.t === 'OUT' ? 'textError' : (rowData.t === 'DEALLOCATED' ? 'textInfo' : (rowData.t === 'ALLOCATED' ? 'textInfo' : 'textSuccess'))
                //     })
                // },
                // {
                //     heading: 'Total Value',
                //     field: rowData => rowData.va,
                //     dataRef: 'va',
                //     fieldFormat: 'currency',
                //     sizeToContent: true,
                //     alignment: 'right',
                //     cellProps: rowData => ({
                //         className: rowData.t === 'OUT' ? 'textError' : (rowData.t === 'DEALLOCATED' ? 'textInfo' : (rowData.t === 'ALLOCATED' ? 'textInfo' : 'textSuccess'))
                //     })
                // },
                {
                    heading: 'Reference',
                    field: rowData => (
                        <>
                            {(rowData.o && (
                                <Tooltip title="View Sales Order">
                                    <Link to={`/sales/${rowData.oi}`} className="link">
                                        SO #{rowData.o}
                                    </Link>
                                </Tooltip>
                            )) || (rowData.p && (
                                <Tooltip title="View Purchase Order">
                                    <Link to={`/purchase-orders/${rowData.pi}`} className="link">
                                        PO #{rowData.p}
                                    </Link>
                                </Tooltip>
                            )) || (rowData.rm && (
                                <Tooltip title="View RMA">
                                    <Link to={`/rma/${rowData.rmi}`} className="link">
                                        RMA #{rowData.rm}
                                    </Link>
                                </Tooltip>
                            )) || (
                                <StaffChip staff={this.props.staff?.[rowData.s]} />
                            )}
                        </>
                    ),
                    sizeToContent: true,
                    dataRef: 'ref',
                },
                {
                    heading: 'Date / Time',
                    field: rowData => rowData.cre,
                    dataRef: 'cre',
                    fieldFormat: 'datetime',
                    sizeToContent: true,
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'View Adjustment', icon: 'binoculars', onClick: () => this.setState({ dialog: { ...this.state.dialog, viewAdjustment: true }, adjustment: rowData.id})}
                        ]
                    }
                }
            ]
        :
            [
                {
                    heading: '+/-/=',
                    field: rowData => {
                        return (
                            <>
                                <FAIcon icon={rowData.t === 'OUT' ? 'minus' : (rowData.t === 'IN' ? 'plus' : 'equals')} className={`${rowData.t === 'OUT' ? 'textError' : (rowData.t === 'NC' ? 'textInfo' :'textSuccess')} mr-1`} size={15} noMargin />
                            </>
                        )
                    },
                    sizeToContent: true,
                    alignment: 'right',
                    cellProps: rowData => ({
                        className: rowData.t === 'OUT' ? 'textError' : (rowData.t === 'NC' ? 'textInfo' : 'textSuccess')
                    })
                },
                {
                    heading: 'Product Code',
                    field: rowData => rowData.code || '-',
                    dataRef: 'code',
                    sizeToContent: true,
                },
                {
                    heading: 'Reason',
                    field: rowData => rowData.r,
                    dataRef: 'r',
                    sizeToContent: true,
                },
                {
                    heading: 'Prev.',
                    field: rowData => (rowData.t === 'NC') ? '-' : rowData.pr,
                    dataRef: 'prev',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'New',
                    field: rowData => (rowData.t === 'NC') ? '-' : rowData.ne,
                    dataRef: 'new',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'Adj.',
                    field: rowData => (rowData.t === 'NC') ? rowData.ad : `${rowData.t === "IN" ? `+` : `-`}${rowData.ad}`,
                    dataRef: 'ad',
                    sizeToContent: true,
                    alignment: 'right',
                    cellProps: rowData => ({
                        className: rowData.t === 'OUT' ? 'textError' : (rowData.t === 'NC' ? 'textInfo' : 'textSuccess')
                    })
                },
                {
                    heading: 'In Stock',
                    field: rowData => rowData.ins,
                    dataRef: 'ins',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'Available',
                    field: rowData => rowData.ava,
                    dataRef: 'ava',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'Allocated',
                    field: rowData => rowData.all,
                    dataRef: 'all',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'On Order',
                    field: rowData => rowData.ono,
                    dataRef: 'ono',
                    sizeToContent: true,
                    alignment: 'right'
                },
                {
                    heading: 'Pre-ordered',
                    field: rowData => rowData.pre,
                    dataRef: 'pre',
                    sizeToContent: true,
                    alignment: 'right'
                },
                // {
                //     heading: 'Total Cost',
                //     field: rowData => rowData.co,
                //     dataRef: 'co',
                //     fieldFormat: 'currency',
                //     sizeToContent: true,
                //     alignment: 'right',
                //     cellProps: rowData => ({
                //         className: rowData.t === 'OUT' ? 'textError' : (rowData.t === 'DEALLOCATED' ? 'textInfo' : (rowData.t === 'ALLOCATED' ? 'textInfo' : 'textSuccess'))
                //     })
                // },
                // {
                //     heading: 'Total Value',
                //     field: rowData => rowData.va,
                //     dataRef: 'va',
                //     fieldFormat: 'currency',
                //     sizeToContent: true,
                //     alignment: 'right',
                //     cellProps: rowData => ({
                //         className: rowData.t === 'OUT' ? 'textError' : (rowData.t === 'DEALLOCATED' ? 'textInfo' : (rowData.t === 'ALLOCATED' ? 'textInfo' : 'textSuccess'))
                //     })
                // },
                {
                    heading: 'Reference',
                    field: rowData => (
                        <>
                            {(rowData.o && (
                                <Tooltip title="View Sales Order">
                                    <Link to={`/sales/${rowData.oi}`}>
                                        <InsightChip
                                            background={'#28a745'}
                                            className="link"
                                            color={'#fff'}
                                            label={`SO #${rowData.o}`}
                                            paddingRight={4}
                                        />
                                    </Link>
                                </Tooltip>
                            )) || (rowData.p && (
                                <Tooltip title="View Purchase Order">
                                    <Link to={`/purchase-orders/${rowData.pi}`}>
                                        <InsightChip
                                            background={'#2196F3'}
                                            className="link"
                                            color={'#fff'}
                                            label={`PO #${rowData.p}`}
                                            paddingRight={4}
                                        />
                                    </Link>
                                </Tooltip>
                            )) || (rowData.rm && (
                                <Tooltip title="View RMA">
                                    <Link to={`/rma/${rowData.rmi}`}>
                                        <InsightChip
                                            background={'#d9534f'}
                                            className="link"
                                            color={'#fff'}
                                            label={`RMA #${rowData.rm}`}
                                            paddingRight={4}
                                        />
                                    </Link>
                                </Tooltip>
                            )) || (
                                <StaffChip staff={this.props.staff?.[rowData.s]} />
                            )}
                        </>
                    ),
                    sizeToContent: true,
                    dataRef: 'ref',
                },
                {
                    heading: 'Date / Time',
                    field: rowData => rowData.cre,
                    dataRef: 'cre',
                    fieldFormat: 'datetime',
                    sizeToContent: true,
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'View Adjustment', icon: 'binoculars', onClick: () => this.setState({ dialog: { ...this.state.dialog, viewAdjustment: true }, adjustment: rowData.id})}
                        ]
                    }
                }
            ];
        
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {(isLoading && (
                            <LoadingCircle />
                    )) || (
                        <>
                            <Grid container spacing={3}>      
                                <Grid item xs={12}>
                                    <Grid container spacing={3} alignItems='center'>
                                        {!this.props.productId && (
                                            <Grid item xs={12} sm={6} md={3} xl={3}>
                                                <AutoCompleteSelect 
                                                    placeholder='Product:'
                                                    options={allProducts}
                                                    value={searchString.product}
                                                    onChange={v => this.onSearchStringChange('product', v)}
                                                    margin="none"
                                                    variant="filled"
                                                    noOptionsMessage={({inputValue}) => _.isEmpty(inputValue) ? 'Start typing to search products...' : 'No Products Found'}
                                                    adornment="search"
                                                />
                                            </Grid>   
                                        )}
                                        <Grid item xs={12} sm={6} md={3} xl={2}>
                                            <AutoCompleteSelect 
                                                placeholder='Type:'
                                                options={allTypes}
                                                value={searchString.type}
                                                onChange={v => this.onSearchStringChange('type', v)}
                                                margin="none"
                                                variant="filled"
                                                adornment="filter"
                                            />
                                        </Grid>  
                                        <Grid item xs={12} sm={6} md={3} xl={2}>
                                            <AutoCompleteSelect 
                                                placeholder='Reason:'
                                                options={allReasons}
                                                value={searchString.reason}
                                                onChange={v => this.onSearchStringChange('reason', v)}
                                                margin="none"
                                                variant="filled"
                                                adornment="filter"
                                            />
                                        </Grid>  
                                        <Grid item xs={12} sm={6} md={3} xl={2}>
                                            <DateSelect onChange={v => this.onSearchStringChange("date", v)} value={searchString.date} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <DataTable
                                            config={{
                                                key: 'id',
                                                pagination: true,
                                                persistenceId: this.persistenceId,
                                                alternatingRowColours: true,
                                                isLoading: dataLoading.accounts,
                                                // responsiveImportance: true,
                                                options: {
                                                    dataRef: !inDialog ? true : false,
                                                    reset: !inDialog ? this.handleReset : undefined,
                                                    export: {
                                                        title: `Stock Activity`,
                                                        name: `Stock-Activity`,
                                                        excel: !inDialog ? true : false,
                                                        pdf: !inDialog ? true : false,
                                                        print: !inDialog ? true : false
                                                    }
                                                }
                                            }}
                                            columns={cols}
                                            rows={searchResults}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                            <ViewAdjustment 
                                open={dialog.viewAdjustment} 
                                close={() => this.toggleDialog('viewAdjustment')} 
                                id={adjustment} 
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    staff: state.notifications.status,
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchActivity);