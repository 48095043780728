import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Box, DialogTitle } from '@material-ui/core';
import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import StaffChip from 'Components/Common/Chips/StaffChip';
import StatusChip from 'Components/Common/Chips/StatusChip';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import Tile from 'Components/Common/Tiles/Tile';
import { momentFormatDateTime } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        action: false,
        viewCustomer: false
    },
    showActioned: false,
    searchResults: {},
    searchString: '',
    isLoading: true
})

class AccountApplicationFormRequests extends React.Component {
    constructor(props) {
        super(props);
        this.persistenceId = null;
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }

    componentDidMount(){
        this.props.pageTitle?.([0, 'Finance', 'Account Application Form Requests']);
        if(!this.hasPageState()) {
            API.multiAccess([
                'acc-app-form-requests:action',
                'view-customer'
            ])
            .then(([
                action,
                viewCustomer
            ]) => {
                this.setState({
                    access: {
                        action,
                        viewCustomer
                    }
                }, () => this.getSearchData());
            })
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    deployConfirmRequest = rowData => {

        const {
            closeDialog,
            deployConfirmation,
            deployDialog
        } = this.props;

        deployDialog(
            <Box className="content-light-grey">
                <DialogTitle disableTypography style={{paddingBottom: 0}}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs>
                            <Typography variant="h6">
                                Account Application Form Request
                            </Typography>
                            <Typography variant="body1" className="fw-400">
                                {rowData.customer?.cust_company_name}
                            </Typography>
                        </Grid>
                        <Grid item align='right'>
                            <Tooltip title="Close">
                                <IconButton aria-label="Close" onClick={closeDialog}>
                                    <FAIcon type="light" icon='times' button noMargin />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Box p={3} pt={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box p={3} pt={3} pb={3} className="content-light-white">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <Tile
                                            noPaper
                                            color={'#000'}
                                            icon={'clock'}
                                            title="Requested"
                                            content={momentFormatDateTime(rowData.raaf_created_datetime)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <StaffTile
                                            noPaper
                                            title="Requested By"
                                            staffId={rowData.raaf_staff_id ?? null}
                                            missingText='N/A'
                                        />
                                    </Grid> 
                                </Grid>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Box p={3} pt={3} pb={3} className="content-light-white">
                                <Typography variant="h6" gutterBottom>
                                    Address
                                </Typography>
                                <Typography variant="body2" component='div'>
                                    {}
                                    {_.map(rowData.address?.split?.(","), (line, idx) => {
                                        if(line?.length > 0) {
                                            return <div key={idx}>{line}</div>
                                        }
                                    })}
                                </Typography>
                            </Box>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Box p={3} pt={3} pb={3} className="content-light-white">
                                <Typography variant="h6" gutterBottom>
                                    Notes
                                </Typography>
                                {(rowData.raaf_notes && (
                                    rowData.raaf_notes?.split?.('\n').map((item, key) => (
                                        <Typography 
                                            key={key} 
                                            variant="caption"
                                            component="div" 
                                        >
                                            {item}
                                        </Typography>
                                    ))
                                )) || (
                                    <em>No notes added to this request</em>
                                )}
                            </Box>
                        </Grid>
                        {rowData.raaf_actioned === 0 && (
                            <Grid item xs={12} align='right'>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => deployConfirmation(`Are you sure you want to mark the account application form as sent?`, () => this.handleAction(rowData.raaf_id))}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Account Application Form Sent
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
            , true
            , ''
            , 'standard'
            , 'sm'
            , false
            , true
        )
    }

    getSearchData = (isLoading = true) => {
        const { searchString } = this.state;
        
        let params = { 
            searchString,
        }

        if(this.state.showActioned) {
            params = {
                ...params,
                showActioned: true
            }
        }
        
        if(this.props.showNewOnly) {
            params = {
                ...params,
                showNewOnly: true
            }
        }
        
        this.setState({
            isLoading
        }, () => {
            API.get('/accounts/accountApplicationForm/requests', 
                {
                    params,
                    props: { 
                        cancellation: true 
                    }
                }
            )
            .then(result => {
                if(result?.data) {
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    }, () => {
                        this.savePageState();
                    });
                }
            });
        })
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }
    
    resetSearch = () => {
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access
            }, () => {
                this.getSearchData();
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getSearchData(true), 200);
    }
    
    handleAction = id => API.put(`/accounts/accountApplicationForm/requests/${id}`)
    .then(res => {
        if(res?.data) {
            this.getSearchData(false);
            this.props.closeDialog();
            this.props.deploySnackBar("success", "The account application form request has been marked as actioned");
        }
    })

    toggleShowActioned = () => {
        this.setState({
            showActioned: !this.state.showActioned
        }, () => this.getSearchData())
    }

    render() {
        const { inline, showNewOnly, staff, ui } = this.props;
        const { access, isLoading, searchString, searchResults, showActioned } = this.state;

        let cols = [];

        if(showNewOnly) {
            cols = [ 
                {
                    heading: 'Customer',
                    field: rowData => rowData.customer?.cust_company_name ?? '-',
                    dataRef: 'customer.cust_company_name',
                    main: true,
                    important: true,
                    sizeToContent: true
                },
                // {
                //     heading: 'Account Number',
                //     field: rowData => rowData.customer?.cust_account_number ?? '-',
                //     dataRef: 'customer.cust_account_number',
                //     sizeToContent: true,
                //     important: true
                // },
                {
                    heading: 'Requested By',
                    field: rowData => (staff && <StaffChip staff={staff[rowData.raaf_staff_id]} />) || '-',
                    dataRef: 'raaf_staff_id',
                    sizeToContent: true,
                    fieldFormat: 'staff',
                },
                {
                    heading: 'Requested On',
                    field: rowData => rowData.raaf_created_datetime,
                    fieldFormat: 'datetime',
                    dataRef: 'raaf_created_datetime',
                    sizeToContent: true,
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'View Request', icon: 'binoculars', onClick: () => this.deployConfirmRequest(rowData), disabled: (rowData.raaf_actioned === 1 || !access.action) && true, doubleClick: true, hideIfDisabled: true},
                        ]
                    }
                }
            ];
        } else {
            cols = [ 
                {
                    heading: 'Customer',
                    field: rowData => rowData.customer?.cust_company_name ?? '-',
                    dataRef: 'customer.cust_company_name',
                    main: true,
                    important: true,
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     style: {
                    //         textDecoration: rowData.raaf_actioned === 1 ? 'line-through' : undefined
                    //     }
                    // })
                },
                {
                    heading: 'Account Number',
                    field: rowData => !_.isEmpty(rowData.customer?.cust_account_number) ? rowData.customer?.cust_account_number : '-',
                    dataRef: 'customer.cust_account_number',
                    sizeToContent: true,
                    important: true,
                    // cellProps: rowData => ({
                    //     style: {
                    //         textDecoration: rowData.raaf_actioned === 1 ? 'line-through' : undefined
                    //     }
                    // })
                },
                // {
                //     heading: 'Contact',
                //     field: rowData => '-',
                // // dataRef: '',
                //     sizeToContent: true,
                //     important: true,
                //     // cellProps: rowData => ({
                //     //     style: {
                //     //         textDecoration: rowData.raaf_actioned === 1 ? 'line-through' : undefined
                //     //     }
                //     // })
                // }
            ];

            // if(!showActioned) {
            //     cols.push(
            //         {
            //             heading: 'Address',
            //             field: rowData => rowData?.address,
            //             dataRef: 'address',
            //             truncate: true
            //         }
            //     );
            // }

            cols.push(
                {
                    heading: 'Requested By',
                    field: rowData => (staff && <StaffChip staff={staff[rowData.raaf_staff_id]} />) || '-',
                    dataRef: 'raaf_staff_id',
                    sizeToContent: true,
                    fieldFormat: 'staff',
                },
                {
                    heading: 'Actioned By',
                    field: rowData => rowData.raaf_actioned === 0 ? '-' : (rowData.raaf_actioned_staff_id > 0 ? <StaffChip staff={staff[rowData.raaf_actioned_staff_id]} /> ?? '-' : `Sales Order | Delivery #${rowData?.delivery?.od_ref ?? 'Unknown'}`),
                    dataRef: 'raaf_actioned_staff_id',
                    sizeToContent: true,
                    fieldFormat: 'staff',
                },
                {
                    heading: 'Created',
                    field: rowData => rowData.raaf_created_datetime,
                    fieldFormat: 'datetime',
                    dataRef: 'raaf_created_datetime',
                    sizeToContent: true,
                },
                {
                    heading: 'Status',
                    field: rowData => <StatusChip status={rowData.status} />,
                    sizeToContent: true,
                    dataRef: 'status',
                }
            );

            if(showActioned) {
                cols.push(
                    {
                        heading: 'Actioned',
                        field: rowData => rowData.raaf_actioned === 1 ? rowData.raaf_actioned_datetime : '-',
                        dataRef: 'raaf_actioned_datetime',
                        sizeToContent: true,
                        fieldFormat: 'datetime',
                    }
                )
            }
            cols.push(
                {
                    actions: rowData => {
                        return [
                            {name: 'View Customer', icon: 'user', link: `/customers/${rowData.raaf_cust_id}`, disabled: !access.viewCustomer && true, doubleClick: true},
                            {name: 'View Request', icon: 'binoculars', onClick: () => this.deployConfirmRequest(rowData), disabled: !access.action && true, doubleClick: true},
                        ]
                    }
                }
            )
        }

        return (
            <>
                <Grid container spacing={1}>    
                    {!showNewOnly && (
                        <Grid container item xs={12} alignItems='center'>
                            <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                                <TextField 
                                    fullWidth
                                    onChange={this.onSearchStringChange}
                                    className="pr-2"
                                    placeholder='Search:'
                                    value={searchString} 
                                    variant="filled"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                    }}
                                    autoFocus={!ui?.device?.isTablet}
                                /> 
                            </Grid>    
                            {/* <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                                <Tooltip title={`Show ${showActioned ? 'outstanding' : 'actioned'} requests`}>
                                    <IconButton
                                        onClick={this.toggleShowActioned}
                                    >
                                        <FAIcon icon={showActioned ? 'eye' : 'eye-slash'} type="light" color={showActioned === 1 ? 'primary' : undefined} noMargin />
                                    </IconButton>
                                </Tooltip>
                            </Grid>   */}
                        </Grid>       
                    )}    
                    <Grid item xs={12}>
                        <Paper elevation={inline ? 0 : undefined}>
                            {(!isLoading && showNewOnly && _.isEmpty(searchResults) && (
                                <Alert severity="success" variant="outlined">
                                    <strong>None - </strong> No account application form requests are currently outstanding
                                </Alert>
                            )) || (
                                <DataTable  
                                    config={{
                                        key: 'raaf_id',
                                        pagination: inline ? false : true,
                                        persistenceId: this.persistenceId,
                                        alternatingRowColours: true,
                                        isLoading: isLoading,
                                        responsiveImportance: true,
                                        inline: inline,
                                        plainPagination: inline ? true : false,
                                        options: {
                                            dataRef: true,
                                            reset: this.resetSearch,
                                            export: {
                                                title: `Account Application Form Requests`,
                                                name: `account-application-form-requests`,
                                                excel: true,
                                                pdf: true,
                                                print: true
                                            }
                                        }
                                    }}
                                    columns={cols}
                                    rows={searchResults}
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        staff: state.notifications.status,
        statePersistence: state.statePersistence,
        ui: state.ui
    };
}

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        clearPersistence: () => dispatch(clearPersistence()),
        setPersistence: (key, state) => dispatch(setPersistence(key, state)),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fs = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, de)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(AccountApplicationFormRequests);