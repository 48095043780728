import React from 'react';
import _ from 'lodash';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
// import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

class CustomerStatusesList extends React.Component {

    render() {
        const { statuses } = this.props;
        return (
            _.map(statuses, (status, idx) => {
                return (
                    <ExpansionPanel defaultExpanded key={idx}>
                        <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button /> }>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6">{status.status_name}</Typography>
                                </Grid>
                                <Grid item>
                                    {/* <Tooltip title="Update">
                                        <IconButton onClick={e => { e.stopPropagation(); this.props.handleDialogOpen(status.status_id)}}>
                                            <FAIcon type="light" icon="pencil" size="small" button noMargin />
                                        </IconButton>
                                    </Tooltip> */}
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {(_.size(status.children) > 0 && (
                                <DataTable  
                                    idx={idx}
                                    config={{
                                        key: 'status_id',
                                        options: {
                                            minimalPadding: true
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: 'Code',
                                            field: rowData => rowData.status_code === "0" ? '-' : rowData.status_code,
                                            sizeToContent: true
                                        },    
                                        {
                                            heading: 'Description',
                                            field: rowData => rowData.status_name
                                        },    
                                        {
                                            heading: 'Accounts',
                                            field: rowData => rowData.customers_count,
                                            alignment: 'center',
                                            sizeToContent: true
                                        },    
                                        // {
                                        //     actions: rowData => {
                                        //         return [
                                        //             {name: 'Update', icon: 'pencil',  onClick: () => this.props.handleDialogOpen(rowData.status_id)},
                                        //         ]
                                        //     }
                                        // }
                                    ]}
                                    rows={status.children}
                                />
                            )) || (
                                <List>
                                    <ListItem>
                                        <ListItemText primary={<em>No child customer statuses to show</em>} />
                                    </ListItem>
                                </List>
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            })
        );
    }
}

export default CustomerStatusesList;