import React from 'react';
import uuidv4 from 'uuid/v4';
import ReactHowler from 'react-howler';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Helmet } from "react-helmet";

import AdminContentWrapper from 'Components/Layout/AdminLayout/AdminContentWrapper';
import AdminFooter from 'Components/Layout/AdminLayout/AdminFooter';
import AdminHeader from 'Components/Layout/AdminLayout/AdminHeader';
import AdminNav from 'Components/Layout/AdminLayout/AdminNav';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import Dialog from 'Components/Common/Dialogs/Dialog';
import ErrorDialog from 'Components/Common/ErrorHandling/ErrorDialog'
import MessageDrawer from 'Components/Messages/MessageDrawer';
import Notification from 'Components/Common/Notifications/Notification';
import RequireAuth from 'Components/RequireAuth';
import ViewAction from 'Components/ActionCenter/ViewAction';
import { WildixProvider } from 'Context/WildixContext';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { handleAppError } from 'Redux/Actions/UI/UI';
import { stopSound } from 'Redux/Actions/Sounds/Sounds';
import { Paper } from '@material-ui/core';
import { putNotificationStatus } from 'Redux/Actions/Notifications/Notifications';
import { getPresignedUrl } from 'Functions/S3Functions';

const initialState = {
    appError: {
        key: uuidv4(),
        detail: "",
        state: false,
    },
    confirmation: {
        key: uuidv4(),
        open: false,
        message: '',
        close: () => {},
        success: () => {},
        successText: null,
        closeText: null,
        successOnly: false,
        disableExit: false
    }, 
    dialog: {
        key: uuidv4(),
        dialogOpen: false,
        disableDialogContent: false,
        dialogContent: '',
        dialogTitle: '',
        dialogVariant: '',
        dialogSize: '',
        dialogFullScreen: false,
        dialogDisableExit: false
    },  
    drawers: {
        nav: true,
        chat: false
    },
    isMobile: false,
    pagePath: '',
    pageTitle: []
}
class AdminLayout extends React.PureComponent {
    constructor() {
        super();
        this.state = initialState;
    }

    componentDidMount = () => {

        const {
            enqueueSnackbar
        } = this.props;
        
        const {
            ui,
            confirmation,
            dialog,
            history,
            snackbar
        } = this.props;

        if(!_.isEmpty(snackbar.message)) {
            enqueueSnackbar(snackbar.message, { variant: snackbar.variant })
        }

        this.setState({
            appError: {
                ...this.state.appError,
                detail: ui.appError.detail,
                state: ui.appError.state
            },
            confirmation: {
                ...this.state.confirmation,
                open: confirmation.open,
                message: confirmation.message,
                close: confirmation.close,
                success: confirmation.success,
                successText: confirmation.successText,
                closeText: confirmation.closeText,
                successOnly: confirmation.successOnly,
                disableExit: confirmation.disableExit
            },
            dialog: {
                ...this.state.dialog,
                dialogOpen: dialog.dialogOpen,
                dialogContent: dialog.dialogContent,
                dialogTitle: dialog.dialogTitle,
                disableDialogContent: dialog.disableDialogContent,
                dialogVariant: dialog.dialogVariant,
                dialogSize: dialog.dialogSize,
                dialogFullScreen: dialog.dialogFullScreen,
                dialogDisableExit: dialog.dialogDisableExit
            },
            isMobile: ui.device.isMobile,
            drawers: {
                ...this.state.drawers,
                nav: ui.device.isMobile ? false : true
            },
            pagePath: history.location.pathname
        })        
    }

    componentDidUpdate = prevProps => {

        const {
            ui,
            confirmation,
            dialog,
            enqueueSnackbar,
            notifications,
            snackbar
        } = this.props;

        if(notifications?.ln !== prevProps?.notifications?.ln && prevProps?.notifications?.ln > 0) {
            const nn = _.filter(notifications?.notifications, el => el.i > prevProps?.notifications?.ln);
            const autoHideDuration = 5000;
            if(!_.isEmpty(nn)) {
                _.each(nn, notification => {
                    let content = (
                        <Paper style={{background: '#212121'}} elevation={0}>
                            <Notification
                                alert
                                data={notification}
                                handleAction={this.handleNotificationClick}
                                handleDismiss={this.handleNotificationDismiss}
                            />
                        </Paper>
                    )
                    enqueueSnackbar(null, { content, autoHideDuration })
                })
            }
        }

        if(snackbar.key !== prevProps.snackbar.key) { 
            if(!_.isEmpty(snackbar.message)) {
                enqueueSnackbar(snackbar.message, { variant: snackbar.variant })
            }
        }

        if(ui.appError.state !== prevProps.ui.appError.state) {
            this.setState({
                appError: {
                    ...this.state.appError,
                    key: uuidv4(),
                    state: ui.appError.state,
                    detail: ui.appError.detail
                }
            });
        }

        if(confirmation.message !== prevProps.confirmation.message) {
            this.setState({
                confirmation: {
                    ...this.state.confirmation,
                    key: uuidv4(),
                    open: confirmation.open,
                    message: confirmation.message,
                    close: confirmation.close,
                    success: confirmation.success,
                    successText: confirmation.successText,
                    closeText: confirmation.closeText,
                    successOnly: confirmation.successOnly,
                    disableExit: confirmation.disableExit
                }
            });
        }

        if(dialog.dialogContent !== prevProps.dialog.dialogContent) {
            this.setState({
                dialog: {
                    ...this.state.dialog,
                    key: uuidv4(),
                    dialogOpen: dialog.dialogOpen,
                    dialogContent: dialog.dialogContent,
                    disableDialogContent: dialog.disableDialogContent,
                    dialogTitle: dialog.dialogTitle,
                    dialogVariant: dialog.dialogVariant,
                    dialogSize: dialog.dialogSize,
                    dialogFullScreen: dialog.dialogFullScreen,
                    dialogDisableExit: dialog.dialogDisableExit
                }
            });
        }

        if(ui.device.isMobile !== prevProps.ui.device.isMobile) {
            this.setState({
                isMobile: ui.device.isMobile,
                drawers: {
                    ...this.state.drawers,
                    nav: ui.device.isMobile ? false : true
                }
            });
        }

    }

    
    handleNotificationClick = (id, a) => {
        this.props.putNotificationStatus(id, "read");

        let type = a.split(':')[0],
            action = a.split(':').slice(1).join(':');

        switch(type) {
            case "action":
                this.props.deployDialog(<ViewAction id={action} closeDialog={this.props.closeDialog} />, true, "", "standard", "xs")
            break;
            case "url":
                this.props.history.push(action);
            break;
            case "external":
                window.open(action);
            break;
            case "s3":
                getPresignedUrl(action);
            break;
            default:
        }
    }

    handleNotificationDismiss = (id, e) => {
        e.stopPropagation();
        this.props.putNotificationStatus(id, "dismissed");
    }

    toggleDrawer = type => {
        this.setState({
            drawers: {
                ...this.state.drawers,
                [type]: !this.state.drawers[type]
            }
        });
    }

    changePath = path => {
        if(path !== this.state.pagePath){
            this.setState({
                drawers: {
                    ...this.state.drawers,
                    nav: this.state.isMobile === true ? false : this.state.drawers.nav
                },
                pagePath: path,
            });
        } else { 
            this.setState({
                drawers: {
                    ...this.state.drawers,
                    nav: this.state.isMobile === true ? false : this.state.drawers.nav
                }
            });
        }
    }

    handleDialogClose = () => {
        this.props.closeDialog();
    }

    pageTitle = title => {
        if(title !== this.state.pageTitle){
            this.setState({
                pageTitle: title
            }, () => {
                // update html page title
            });
        } 
    }

    render() {
        const { history, location, badges, ui, sound, stopSound } = this.props;
        const { appError, confirmation, dialog, drawers, pagePath, pageTitle } = this.state;

        let thisPage    = _.size(pageTitle) > 2 ? _.join(_.filter(pageTitle, (el, idx) => !_.isNumber(el) && idx !== 1), " > ") : _.join(_.filter(pageTitle, el => !_.isNumber(el)), " > "),
            ogTitle     = _.isEmpty(thisPage) ? `Loading | RED` : `${thisPage} | RED`;

        return (
            <WildixProvider>
                <Helmet>
                    <title>{ogTitle}</title>
                    <meta property="og:title" content={ogTitle} />
                </Helmet>
                <AdminHeader    
                    history={history}
                    location={location}
                    toggleDrawer={this.toggleDrawer}
                />
                <AdminNav   
                    history={history}
                    navOpen={drawers.nav}
                    notifications={badges}
                    toggleDrawer={this.toggleDrawer}
                    pagePath={pagePath} 
                    scrollHeight={ui.device.height}
                    ui={ui}
                />
                <MessageDrawer   
                    open={drawers.chat}
                    toggleDrawer={this.toggleDrawer}
                />
                <AdminContentWrapper    
                    changePath={this.changePath}
                    location={location}
                    history={history}
                    drawers={drawers}
                    page={pageTitle}
                    pageTitle={this.pageTitle}    
                    pagePath={pagePath}
                    scrollHeight={ui.device.height}
                    ui={ui}
                />
                <AdminFooter />
                <ConfirmationDialog 
                    open={confirmation.open} 
                    success={confirmation.success} 
                    close={confirmation.close} 
                    successText={confirmation.successText}
                    closeText={confirmation.closeText}
                    successOnly={confirmation.successOnly}
                    message={confirmation.message}
                    disableExit={confirmation.disableExit}
                /> 
                <Dialog 
                    key={dialog.key}
                    dialogOpen={dialog.dialogOpen}
                    dialogContent={dialog.dialogContent}
                    disableDialogContent={dialog.disableDialogContent}
                    dialogTitle={dialog.dialogTitle}
                    dialogVariant={dialog.dialogVariant}
                    dialogSize={dialog.dialogSize}
                    handleDialogClose={this.handleDialogClose}
                    dialogFullScreen={dialog.dialogFullScreen}
                    dialogDisableExit={dialog.dialogDisableExit}
                />
                {appError.state && (
                    <ErrorDialog
                        key={appError.key}
                        state={appError.state}
                        detail={appError.detail}
                        history={history}
                        closeError={() => this.props.handleAppError(false, "")}
                    />
                )}
                {sound.src !== null && (
                    <ReactHowler
                        src={sound.src}
                        playing={sound.status === 'playing'}
                        onEnd={stopSound}
                    />
                )}
            </WildixProvider>
        )
    }
}

const mapStateToProps = state => ({
        confirmation: state.confirmation,
        dialog: state.dialog,
        badges: state.notifications.badges,
        notifications: state.notifications,
        snackbar: state.snackbar,
        ui: state.ui,
        sound: state.sound,
});

const mapDispatchToProps = dispatch => ({
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
        closeDialog: () => dispatch(closeDialog()),
        stopSound: () => dispatch(stopSound()),
        putNotificationStatus: (id, action) => dispatch(putNotificationStatus(id, action))
})

export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(AdminLayout)));