import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const ReturnSummary = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/returnSummary',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Date',
                        field: rowData => rowData.d,
                        fieldFormat: 'date',
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'RMA',
                        field: rowData => rowData.rid,
                        dataRef: 'rid',
                        sizeToContent: true
                    },
                    {
                        heading: 'Product',
                        field: rowData => rowData.pid,
                        dataRef: 'pid',
                        sizeToContent: true
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.spn,
                        dataRef: 'spn',
                        sizeToContent: true
                    },
                    {
                        heading: 'Order Number',
                        field: rowData => rowData.sr,
                        dataRef: 'sr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Qty',
                        field: rowData => rowData.q,
                        dataRef: 'q',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status',
                        field: rowData => rowData.st,
                        dataRef: 'st',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Action',
                        field: rowData => rowData.act,
                        dataRef: 'act',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Reason',
                        field: rowData => rowData.rsn,
                        dataRef: 'rsn',
                        important: true,
                        sizeToContent: true
                    },
                    
                    {
                        heading: 'Restock',
                        field: rowData => rowData.rstk,
                        fieldFormat: 'boolean',
                        dataRef: 'rstk',
                        important: true,
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Return To Supplier',
                        field: rowData => rowData.rts,
                        fieldFormat: 'boolean',
                        dataRef: 'rts',
                        important: true,
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Return Date',
                        field: rowData => rowData.rdt,
                        fieldFormat: 'date',
                        dataRef: 'rdt',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supp Ref',
                        field: rowData => rowData.sup,
                        dataRef: 'sup',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supp Credit',
                        field: rowData => rowData.supcr,
                        fieldFormat: 'boolean',
                        dataRef: 'supcr',
                        important: true,
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'NFF',
                        field: rowData => rowData.nff,
                        fieldFormat: 'boolean',
                        dataRef: 'nff',
                        important: true,
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Repair',
                        field: rowData => rowData.rpr,
                        fieldFormat: 'boolean',
                        dataRef: 'rpr',
                        important: true,
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Replace',
                        field: rowData => rowData.rplc,
                        fieldFormat: 'boolean',
                        dataRef: 'rplc',
                        important: true,
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'No Action',
                        field: rowData => rowData.noact,
                        fieldFormat: 'boolean',
                        dataRef: 'noact',
                        important: true,
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Original Unit Price',
                        field: rowData => rowData.oup,
                        fieldFormat: 'currency',
                        dataRef: 'oup',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.cac,
                        dataRef: 'cac',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Returns Contact',
                        field: rowData => rowData.c,
                        dataRef: 'c',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Raised By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.cb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'cb',
                        important: true,
                        sizeToContent: true,
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'return-summary',
                noResultsText: 'Sorry, no return summary to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Sales'],
                pdf: true,
                // persistenceId: 'returnSummary',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: true,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Return Summary Report'
            }}
        />
    )
}

export default ReturnSummary;