import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import Tile from 'Components/Common/Tiles/Tile';
import DataTable from 'Components/Common/DataTables/DataTable';
import BooleanChip from 'Components/Common/Chips/BooleanChip';
import { momentFormatDateTime } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';

const GoodsReceivedDetails = ({closeDialog, pod, idx}) => {
    let gr = pod?.goods_received;
    if(typeof idx !== "undefined") {
        gr = pod?.goods_receiveds?.[idx] ?? null;
    }
    return (
        <Box className="content-light-grey">
            <DialogTitle disableTypography style={{paddingBottom: 0}}>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs>
                        <Typography variant="h6">
                            Goods Received Details
                        </Typography>
                        <Typography variant="body1" className="fw-400">
                            Purchase Order Delivery #{pod?.pod_reference}/{pod?.pod_idx}
                        </Typography>
                    </Grid>
                    <Grid item align='right'>
                        <Tooltip title="Download Pack Away Report">
                            <IconButton aria-label="Download" disabled={pod.pod_legacy === 1 || _.isEmpty(gr?.gr_pdf_filename)} onClick={() => getPresignedUrl(gr?.gr_pdf_filename)}>
                                <FAIcon type="light" icon='file-pdf' className={pod.pod_legacy === 1 || _.isEmpty(gr?.gr_pdf_filename) ? undefined : 'textError'} disabled={pod.pod_legacy === 1 || _.isEmpty(gr?.gr_pdf_filename)} button noMargin />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Close">
                            <IconButton aria-label="Close" onClick={closeDialog}>
                                <FAIcon type="light" icon='times' button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Box p={3} pt={0}>
                <Box pt={3} pb={3}>
                    <Alert severity={gr?.gr_successful === 1 ? 'success' : 'warning'} elevation={0}>
                        {gr?.gr_successful === 1 ? `All expected items were received` : `Some items were missing / damaged on receipt`}{pod.pod_rma === 1 ? ' - this delivery is no longer required and will be returned to the supplier' : ''}
                    </Alert>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box p={3} pt={3} pb={3} className="content-light-white">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'clock'}
                                        title="Goods Received Started"
                                        content={momentFormatDateTime(gr?.gr_started_datetime, true)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'clock'}
                                        title="Goods Received Finished"
                                        content={momentFormatDateTime(gr?.gr_completed_datetime, true)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <StaffTile
                                        noPaper
                                        title="Received By"
                                        staffId={gr?.gr_staff_id ?? null}
                                        missingText='N/A'
                                    />
                                </Grid> 
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={3} pt={3} pb={3} className="content-light-white">
                            <Typography variant="h6" gutterBottom>
                                Pack Away Report
                            </Typography>
                            <DataTable  
                                config={{
                                    key: 'grp_id',
                                    alternatingRowColours: true,
                                    // responsiveImportance: true,
                                    inline: true,
                                }}
                                columns={[
                                    {
                                        heading: 'Product',
                                        field: rowData => (
                                            <>
                                                <Typography variant="body2" className="fw-400">
                                                    {rowData?.grp_product_code ?? '-'}
                                                </Typography>
                                                <Typography variant="caption">
                                                    {rowData?.grp_product_name ?? '-'}
                                                </Typography>
                                            </>
                                        ),
                                        truncate: true
                                    }, 
                                    {
                                        heading: 'Expected',
                                        field: rowData => rowData.grp_expected_qty,
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }, 
                                    {
                                        heading: 'Received',
                                        field: rowData => rowData.grp_total_qty,
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }, 
                                    {
                                        heading: 'Missing',
                                        field: rowData => rowData.grp_missing_qty,
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }, 
                                    {
                                        heading: 'Damaged',
                                        field: rowData => rowData.grp_damaged_qty,
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Pristine',
                                        field: rowData => (parseInt(rowData.grp_total_qty) - parseInt(rowData.grp_damaged_qty)),
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Check Time',
                                        field: rowData => momentFormatDateTime(rowData.grp_check_datetime, true),
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => <BooleanChip success={rowData.grp_successful === 1} label={rowData.grp_successful === 1 ? 'Received' : 'Issue'} />,
                                        sizeToContent: true
                                    }
                                ]}
                                rows={gr?.products}
                            />
                        </Box>
                    </Grid>
                    {gr?.note && (
                        <Grid item xs={12}>
                            <Box p={3} pt={3} pb={3} className="content-light-white">
                                <Typography variant="h6" gutterBottom>
                                    Pick Notes
                                </Typography>
                                {gr?.note?.note_value?.split?.('\n').map((item, key) => (
                                    <Typography 
                                        key={key} 
                                        variant="caption"
                                        component="div" 
                                        paragraph
                                    >
                                        {item}
                                    </Typography>
                                ))}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}

export default GoodsReceivedDetails;