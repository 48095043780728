import * as UIActionTypes from './Types/Types';
import { setMode } from 'Functions/AuthFunctions';

export function handleWindowResize(innerWidth, innerHeight) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.WINDOW_RESIZE,
            payload: [innerWidth, innerHeight],
        });
    }
}

export function handleUpdateNotification(scheduledTimestamp) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.UPDATE_NOTIFICATION,
            payload: scheduledTimestamp,
        });
    }
}

export function handleError(error, message) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.HANDLE_ERROR,
            payload: [error, message]
        });
    }
}

export function handleUnauthorized(pathname) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.HANDLE_UNAUTHORIZED,
            payload: pathname
        });
    }
}

export function handleSetMode(mode) {
    setMode(mode);
    return dispatch => {          
        dispatch({
            type: UIActionTypes.CHANGE_MODE,
            payload: mode,
        });
    }
}

export function handleAppError(error, message) {
    return dispatch => {          
        dispatch(handleError(error, message));
    }
}