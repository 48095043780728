// import React from 'react';

// import Box from '@material-ui/core/Box';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';

const Calendar = () => (
    null
    // <Paper>
    //     <Box p={3}>
    //         <Typography variant="h6" paragraph>
    //             Calendar
    //         </Typography>
    //         [placeholder]
    //     </Box>
    // </Paper>
)

export default Calendar;