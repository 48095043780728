import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import MessagesLanding from 'Components/Messages/MessagesLanding';
import StaffMember from 'Components/Common/Staff/StaffMember';
import Thread from 'Components/Messages/Thread';
import { handleMinusMsgBadge } from 'Redux/Actions/Notifications/Notifications';
import { isTablet } from 'Functions/MiscFunctions';

const styles = () => ({
    paper: {
        marginTop: 'calc(env(safe-area-inset-top) + ' + isTablet() ? 0 : 47.5 + 'px)',
        width: '100%',
        maxWidth: 750,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)'
    },
    gridItem: {
        background: '#FFF',
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative'
    }
})

const initialState = {
    loading: true,
    selected: {
        staff: 0
    },
    threads: {
        staff: []
    }
}

class Messages extends React.PureComponent { 
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getMessageCounts();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.badges['unread-messages'] && (prevProps.badges['unread-messages'] !== this.props.badges['unread-messages'])) {
            this.getMessageCounts();
        }
        if(!prevProps.open && this.props.open) {
            this.getMessageCounts();
        }
        if(prevProps.open && !this.props.open) {
            this.setState({
                selected: initialState.selected
            })
        }
    }

    getMessageCounts = () => {
        API.get('/messaging/pulse', { props: { noLoading: true }})
        .then(result => {
            if(result?.data) {
                this.setState({
                    loading: false,
                    threads: {
                        ...this.state.threads,
                        staff: result.data
                    }
                })
            }
        })
    }

    handleOpenThread = (type, id) => {
        let count = 0, idx = -1, { threads } = this.state;
        if(type === "staff") {
            idx = _.findIndex(threads[type], el => el.i === id);
        }
        if(idx !== -1) {
            count = threads[type][idx].c;
            threads[type][idx].c = 0;
        }
        this.setState({
            selected: {
                ...initialState.selected,
                [type]: id
            },
            threads: threads
        }, () => {
            this.props.handleMinusMsgBadge(count);
        })
    }

    render = () => {
        const { loading, selected, threads } = this.state;
        const { classes, open, staff, toggleDrawer, ui } = this.props;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight = window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - (ui?.device?.isTablet ? 0 : 50))}px)` : (height - (ui?.device?.isTablet ? 0 : 50));
        const standalone = !toggleDrawer ? true : false;
        return (
            (loading && (
                <React.Fragment />
            )) || (
                <Grid container>
                    <Grid item xs={standalone ? 4 : 5} className={`${classes.gridItem} sideLeft`} style={{height: contentHeight}}>
                        <List disablePadding>
                            {_.map(threads.staff, (data, idx) => {
                                let thread = data;
                                let staffId = thread.i;
                                let badge = thread.c;
                                if(staff[staffId]) {
                                    let name = staff[staffId].name;
                                    if(name?.length > 0) {
                                        name = name.split(" ");
                                        name = name[0];
                                    }
                                    let inlineContent = (
                                        <>
                                            <Typography variant="body2" color="textSecondary" style={{position: 'absolute', right: 6, top: 14}}>{thread.d ? (moment(thread.d).isSame(moment(), 'day') ? moment(thread.d).format("HH:mm") : moment(thread.d).format("DD/MM/YYYY")) : ' '}</Typography>
                                            <Typography variant="body2" color="textSecondary">{thread.m ?? `Start a conversation with ${name?.substr?.(0, 8)}...`}</Typography>
                                        </>
                                    )
                                    return (
                                        <StaffMember
                                            key={idx}
                                            badge={badge}
                                            staff={staff[staffId]}
                                            handleAction={() => this.handleOpenThread("staff", staffId)}
                                            stacked
                                            size="sm"
                                            inlineContent={inlineContent}
                                        />
                                    )
                                }
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={standalone ? 8 : 7} className={classes.gridItem} style={{height: contentHeight}}>
                        {(!selected.staff && (
                            <MessagesLanding 
                                toggleDrawer={toggleDrawer} 
                            />
                        )) || (
                            <Thread
                                contentHeight={contentHeight} 
                                getMessageCounts={this.getMessageCounts}
                                open={open}
                                selectedStaff={selected.staff}
                                toggleDrawer={toggleDrawer} 
                            />
                        )}
                    </Grid>
                </Grid>
            )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        badges: state.notifications.badges,
        staff: state.notifications.status,
        ui: state.ui
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleMinusMsgBadge: (count) => dispatch(handleMinusMsgBadge(count))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Messages));