import React from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import ProductRangesList from './ProductRangesList';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    formData: {
        rangeId: '',
        rangeName: '',
        rangeSupplier: '',
    },
    updateData: {
        rangeId: '',
        rangeName: '',
        rangeSupplier: '',
    },
    formErrors:[],
    updateErrors: [],
    dialogOpen: false,
    suppliers: [],
    confirmationOpen: {
        submit: false,
        update: false,
    },
    snackbarOpen: {
        submit: false,
        update: false,
    },
    isLoading: true,
    updateLoading: true,
    suppliersList: []
}

class ProductRanges extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getProductRanges();
    }

    submit = () => {
        API.post('/products/ranges', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        update: false,
                        submit: true
                    }
                });
                this.getProductRanges();
            }
            this.props.scrollToTop();
        });
    }

    update = () => {
        API.put('/products/ranges/'+this.state.updateData.rangeId, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        update: true,
                        submit: false
                    }
                });
                this.getProductRanges();
            }
            this.props.scrollToTop();
        });
    }

    getProductRanges = () => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/products/ranges'),
                API.get('/suppliers/all', { params: { forList: true, forType: 2}})
            ])
            .then(([productRangesRes, allSuppliersRes]) =>  {
                let suppliers = []
                let suppliersList = []
                if(productRangesRes.data){
                    suppliers = productRangesRes.data;
                }
                if(allSuppliersRes.data){
                    suppliersList = _.map(allSuppliersRes.data, el => {
                        return _.assign({
                            label: el.l,
                            value: el.v,
                        });
                    });
                }
                this.setState({
                    ...this.state,
                    suppliers,
                    suppliersList,
                    isLoading: false
                });
            });
        })
    }
    
    getUpdateData = () => {
        API.get('/products/ranges/'+this.state.updateData.rangeId)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose();
            } else {
                this.setState({
                    updateLoading: false,
                    updateErrors: [],
                    updateData: {
                        ...this.state.updateData,
                        rangeName: result.data.range_name,
                        rangeSupplier: result.data.range_supplier_id,
                    }
                });
            }
        })
    }
    
    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    handleUpdateChange = e => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                [e.target.name]: e.target.value
            }
        });
    }
    
    handleUpdateSelectChange = fieldName => selectedOption => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };
    
    handleSnackbarClose = (type) => {
        this.setState({ 
            snackbarOpen: {
            ...this.state.snackbarOpen,
            [type]: false,
            } 
        });
    };

    handleConfirmationOpen = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: true,
            } 
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        }, () => {
            if(type === 'submit') {
                this.submit();
            } else {
                this.update();
            }
        });
    }

    handleDialogOpen = (id) => {
        this.setState({
            dialogOpen: true,
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                rangeId: id
            }
        }, () => this.getUpdateData());
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpen: false,
            updateLoading: true,
            updateData: {
                ...initialState.updateData
            }
        });
    }

    render() {
        const { formData, formErrors, isLoading, updateData, updateErrors, updateLoading, suppliers, suppliersList } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    {(isLoading && (
                        <PaddedPaper>
                            <LoadingCircle />
                        </PaddedPaper>
                    )) || (
                        <ProductRangesList suppliers={suppliers} handleDialogOpen={this.handleDialogOpen} />
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Product Range
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <AutoCompleteSelect
                                options={suppliersList}
                                label='Supplier *'
                                onChange={this.handleSelectChange('rangeSupplier')}
                                error={formErrors && formErrors['rangeSupplier'] && true}
                                errorText={formErrors && formErrors['rangeSupplier']}
                                value={formData.rangeSupplier}
                            />
                            <Box pt={1}>
                                <TextField
                                    name="rangeName"
                                    label="Name *"
                                    value={formData.rangeName}
                                    error={formErrors && formErrors['rangeName'] && true}
                                    helperText={formErrors && formErrors['rangeName']}
                                    onChange={this.handleChange}
                                    margin="none"
                                    fullWidth
                                />
                            </Box>
                            <div className='buttonRow'>
                                <Button 
                                    type='submit'
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="text" 
                                    color="primary"
                                    disabled={!this.state.formData.rangeName}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Add
                                </Button>
                            </div>
                        </form>
                        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={this.state.dialogOpen} onClose={this.handleDialogClose} fullWidth={true} maxWidth="sm" scroll="body">
                            <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Product Range</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <AutoCompleteSelect
                                            options={suppliersList}
                                            label='Supplier *'
                                            onChange={this.handleUpdateSelectChange('rangeSupplier')}
                                            error={updateErrors && updateErrors['rangeSupplier'] && true}
                                            errorText={updateErrors && updateErrors['rangeSupplier']}
                                            value={updateData.rangeSupplier}
                                            noClear
                                        />
                                        <TextField
                                            name="rangeName"
                                            label="Name *"
                                            value={updateData.rangeName}
                                            error={updateErrors && updateErrors['rangeName'] && true}
                                            helperText={updateErrors && updateErrors['rangeName']}
                                            onChange={this.handleUpdateChange}
                                            margin="none"
                                            fullWidth
                                        />
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text"><FAIcon icon="check" size={15} button />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            title="Update range?" 
                            message="Are you sure you want to update this product range?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title="Add a new range?" 
                            message="Are you sure you want to add a new product range?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the product range'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new product range'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

export default ProductRanges;