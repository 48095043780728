import _axios from 'axios';
import uuidv4 from 'uuid/v4';

import MainStore from 'Redux/MainStore';
import { finishLoading, startLoading } from 'Redux/Actions/Loading/Loading';
import { RTAPI_URL } from 'Constants';
import { handleRtApiPulse } from 'Redux/Actions/Notifications/Notifications';
import { getToken } from 'Functions/AuthFunctions';

const axios = _axios.create({
    baseURL: RTAPI_URL,
    withCredentials: true
})

axios.defaults.timeout = 90000;

axios.defaults.maxContentLength = Infinity;

axios.interceptors.request.use(request => {
    /*
     * Send token if getToken() returns one (user is logged in)
     */
    const token = getToken();
    if (token) {
        request.headers.Authorization = 'Bearer ' + token;
    }
    return request;
}, (error) => {
    return Promise.reject(error);
});

/*
 * Check for update
 */
axios.interceptors.response.use(response => {
    if(response?.config?.props?.handleRtApiPulse) {
        if(response.data) {
            MainStore.dispatch(handleRtApiPulse(response.data))
        }
    }
    return response;
}, (error) => {
    return Promise.reject(error);
});

/*
 * Handle Loading Bar
 */
axios.interceptors.request.use(config => {
    config.requestId = uuidv4()    
    if(config.props && config.props.noLoading) {
        config.loadingBar = false;
    } else {
        config.loadingBar = true;
        MainStore.dispatch(startLoading(config.requestId));
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
axios.interceptors.response.use(response => {
    if(response && response.config && response.config.loadingBar === true) {
        MainStore.dispatch(finishLoading(response.config.requestId));
    }
    return response;
}, (error) => {
    if(error && error.config && error.config.loadingBar === true) {
        MainStore.dispatch(finishLoading(error.config.requestId));
    }
    return Promise.reject(error);
});

class RTAPI {
    constructor() {
        this.lastPath = null;
        this.lastRequestCancellation = null;
    }
    
    url = RTAPI_URL;

    cancel = () => {
        if(this.lastRequestCancellation) {
            this.lastRequestCancellation.cancel('Duplicate Search Request Cancelled');
            this.lastRequestCancellation = null;
            this.lastPath = null;
        }
    }
    
    get = (path, config = {}) => {  
        if(config && config.props && config.props.cancellation) {

            /* Check for duplicate requests */
            if(path === this.lastPath) {
                this.lastRequestCancellation.cancel('Duplicate Search Request Cancelled');
            } 

            /* Set last request variables */
            this.lastPath = path
            this.lastRequestCancellation = _axios.CancelToken.source();

            /* Attach request cancellation token */
            config = {
                ...config,
                cancelToken: this.lastRequestCancellation.token
            }
        }        

        /* Send request */
        return axios.get(RTAPI_URL + path, config).catch(this.handleError);
    }
    
    // post = (path, data = {}, config = {}) => {        
    //     return axios.post(RTAPI_URL + path, data, config).catch(this.handleError);
    // }

    // put = (path, data = {}, config = {}) => {
    //     return axios.put(RTAPI_URL + path, data, config).catch(this.handleError);
    // }

    handleError = err => {
        if(!_axios.isCancel(err)) {
            console.log(`RTAPI Error:`, err);
        }
    }
}

export default new RTAPI();