import React from 'react';
import _ from 'lodash';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

class DocumentCategoriesList extends React.Component {

    render() {
        const { categories } = this.props;
        return (
            _.map(categories, (cat, idx) => {
                return (
                    <ExpansionPanel defaultExpanded key={idx}>
                        <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button /> }>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6">{cat.dc_name}</Typography>
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {(_.size(cat.children) > 0 && (
                                <DataTable  
                                    idx={idx}
                                    config={{
                                        key: 'dc_id',
                                        options: {
                                            minimalPadding: true
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: 'Name',
                                            field: rowData => rowData.dc_name,
                                            main: true
                                        },    
                                        {
                                            heading: 'Documents',
                                            field: rowData => rowData.documents_count,
                                            alignment: 'center',
                                            sizeToContent: true
                                        },   
                                        {
                                            heading: 'Private By Default',
                                            field: rowData => rowData.dc_private,
                                            fieldFormat: 'boolean',
                                            alignment: 'center',
                                            sizeToContent: true
                                        },     
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Update', icon: 'pencil',  onClick: () => this.props.handleDialogOpen(rowData.dc_id), disabled: rowData.dc_name === "System"},
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={cat.children}
                                />
                            )) || (
                                <List>
                                    <ListItem>
                                        <ListItemText primary={<em>No child document categories to show</em>} />
                                    </ListItem>
                                </List>
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            })
        );
    }
}

export default DocumentCategoriesList;