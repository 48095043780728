import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = {
    access: {
        sendSms: false
    },
    isLoading: true,
    sms: {}
}

const ViewSms = ({id, closeDialog, resend}) => {

    const [state, setState] = useState(initialState);

    const handleClose = () => {
        if(closeDialog) {
            closeDialog();
        }
    }

    useEffect(() => {
        Promise.all([
            API.get(`/sms/${id}`),
            API.access('sms-send')
        ])
        .then(([res, sendSms]) => {
            if(res?.data) {
                setState({
                    access: {
                        sendSms: sendSms?.data?.has_access || false
                    },
                    isLoading: false,
                    sms: res.data
                })
            }
        })
    }, [id]);

    const isLoading = state.isLoading,
                sms = state.sms,
             access = state.access;
         
    const handleResend = () => {
        const formData = {
            smsTo: {
                label: sms.sms_recipient,
                value: sms.sms_recipient
            },
            smsType: sms.sms_type,
            smsText: sms.sms_value
        }
        if(resend) {
            resend(false, formData)
        }
    }  
    return (
        isLoading && (
            <LoadingCircle />
        )) || (
            <form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <FAIcon type="light" icon="mobile" size={25} style={{paddingTop: 1}} noMargin />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6">
                            {sms.sms_type}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                            Sent
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {moment(sms.sms_created_datetime).format("LLLL")}
                        </Typography>
                         <Typography variant="caption" color="textSecondary">
                            Sender
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {sms.sms_from}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            Recipient
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {sms.sms_recipient}
                        </Typography>
                        {sms.customer && (
                            <>
                                <Typography variant="caption" color="textSecondary">
                                    Customer
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    {sms.customer.cust_company_name ?? '-'}
                                </Typography>
                            </>
                        )}
                        {sms.customer_contact && (
                            <>
                                <Typography variant="caption" color="textSecondary">
                                    Contact
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    {sms.customer_contact.contact_first_name ?? ''} {sms.customer_contact?.contact_last_name ?? ''}
                                </Typography>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className='pt-2'>
                        <Typography variant="body2" paragraph>
                            {(!_.isEmpty(sms.sms_value) && (
                                sms.sms_value.split('\n').map((item, key) => (
                                    <span key={key}>{item}<br/></span>
                                ))
                            )) || (
                                <em>This sms has no body</em>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='buttonRow'>
                            <Button 
                                onClick={handleClose}
                                variant="text"
                                color="default"
                            >
                                Close
                            </Button>
                            {access.sendSms && (
                                <Button 
                                    onClick={handleResend}
                                    variant="text"
                                    color="primary"
                                >
                                    Resend
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </form>
    )
}

export default ViewSms;