import React, { useEffect, useState } from 'react';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import WYSIWYG from 'Components/Common/WYSIWYG/WYSIWYG';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = () => ({
    isLoading: true,
    formData: {
        name: '',
        subject: '',
        type: 'None',
        sender: '',
        text: ''
    },
    formErrors: [],
    template: false,
    wysiwygEditor: EditorState.createEmpty()
})

const EmailTemplateForm = ({id, callback, closeDialog, deployConfirmation, deploySnackBar}) => {

    const [state, setState] = useState(initialState());
    const [senders, setSenders] = useState([]);

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                [name]: value
            }
        }));
    }
    
    const handleBodyChange = wysiwygEditor => {
        const text = draftToHtml(convertToRaw(wysiwygEditor.getCurrentContent()));
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                text
            },
            wysiwygEditor
        }));
    }

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData:{
                ...formData,
                [fieldName]: selectedOption?.value ?? ''
            }
        }));
    }

    const handleCancel = () => {
        if(closeDialog) {
            closeDialog();
        }
    }

    const handleSubmit = () => {
        const apiRoute = id ? `/email/templates/${id}` : `/email/templates`;
        API.post(apiRoute, state.formData)
        .then(res => {
            if(res?.data) {
                if(res.data.errors) {
                    setState(state => ({
                        ...state,
                        formErrors: formatValidationErrors(res.data.errors)
                    }))
                } else {
                    if(deploySnackBar) {
                        deploySnackBar("success", `You have successfully ${id ? 'updated' : 'created'} the e-mail template`);
                    }
                    if(callback) {
                        callback();
                    }
                    if(closeDialog) {
                        closeDialog();
                    }
                }
            }
        })
    }

    useEffect(() => {
        if(id) {
            API.get(`/email/templates/${id}`)
            .then(res => {
                if(res?.data) {
                    const description = htmlToDraft(res.data.et_text);
                    const descriptionState = ContentState.createFromBlockArray(
                        description.contentBlocks
                    );
                    setState({
                        isLoading: false,
                        formData: {
                            name: res.data.et_name,
                            subject: res.data.et_subject,
                            sender: res.data.et_send_from,
                            type: res.data.et_type,
                            text: res.data.et_text
                        },
                        template: res.data,
                        wysiwygEditor: EditorState.createWithContent(descriptionState)
                    })
                }
            })
        } else {
            setState(state => ({
                ...state,
                isLoading: false
            }))
        }
    }, [id]);

    useEffect(() => {
        API.get(`/teams`)
        .then(res => {
            if(res?.data) {
                const teams = _.map(res?.data, team => (
                    _.assign({
                        "label": team.team_email,
                        "value": team.team_email
                    })
                ))
                const options = [
                    {
                        label: "Staff",
                        options: [
                            {
                                label: "Active User",
                                value: "Active User"
                            }
                        ]
                    },
                    {
                        label: "Team",
                        options: teams
                    }
                ];

                setSenders(options)
            }
        })
    }, [])

    const   isLoading        = state.isLoading,
            formData         = state.formData,
            formErrors       = state.formErrors,
            template         = state.template,
             wysiwygEditor   = state.wysiwygEditor;
           
    return (
        isLoading && (
            <LoadingCircle />
        )) || (
            <form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3} className="pt-2">
                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                        <TextField
                            name="name"
                            label="Name *"
                            value={formData.name}
                            error={formErrors && formErrors['name'] && true}
                            helperText={formErrors && formErrors['name']}
                            onChange={handleChange}
                            margin="none"
                            fullWidth
                            disabled={template?.et_system === 1}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                        <TextField
                            name="subject"
                            label="Default Subject *"
                            value={formData.subject}
                            error={formErrors && formErrors['subject'] && true}
                            helperText={formErrors && formErrors['subject']}
                            onChange={handleChange}
                            margin="none"
                            fullWidth
                            disabled={template?.et_system === 1}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                        <AutoCompleteSelect
                            options={senders} 
                            label='Default From *'
                            value={formData.sender}
                            onChange={handleSelectChange('sender')}
                            error={formErrors && formErrors['sender'] && true}
                            errorText={formErrors && formErrors['sender'] && formErrors['sender']}
                            noClear
                            isGrouped
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                        <AutoCompleteSelect
                            options={[
                                {
                                    label: "Customers",
                                    value: "Customer"
                                },
                                {
                                    label: "Suppliers",
                                    value: "Supplier"
                                },
                                {
                                    label: "N/A",
                                    value: "None"
                                }
                            ]} 
                            label='Applicable To'
                            value={formData.type}
                            onChange={handleSelectChange('type')}
                            error={formErrors && formErrors['type'] && true}
                            errorText={formErrors && formErrors['type'] && formErrors['type']}
                            noClear
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <WYSIWYG
                            editorState={wysiwygEditor}
                            label='Template *'
                            margin='none'
                            onChange={v => handleBodyChange(v)}
                        />
                    </Grid>
                </Grid>
                <div className='buttonRow'>
                    <Button 
                        onClick={handleCancel}
                        variant="text"
                        color="default"
                    >
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => deployConfirmation(`Are you sure you want to ${id ? 'update' : 'create'} this e-mail template?`, handleSubmit)}
                        variant="text"
                        color="primary"
                    >
                        <FAIcon icon="check" size={15} button />
                        {id ? 'Update' : 'Create'}
                    </Button>
                </div>
            </form>
    )
}

export default EmailTemplateForm;