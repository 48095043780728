import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const Details = ({history, po}) => {
    let company = po?.supplier?.supp_company_name ?? '-', 
            tel = po?.supplier?.supp_phone ?? '-', 
          email = po?.supplier?.supp_email ?? '-';


    return (
        <Grid container spacing={2}>
            {company && (
                <Grid item xs={12} align='center'>
                    <Typography variant="caption" component="div" color="textSecondary">
                        Company
                    </Typography>
                    <Typography variant="caption" component="div">
                        {company}
                    </Typography>
                </Grid>
            )}
            {tel && (
                <Grid item xs={12} align='center'>
                    <Typography variant="caption" component="div" color="textSecondary">
                        Telephone
                    </Typography>
                    <Typography variant="caption" component="div">
                        {tel}
                    </Typography>
                </Grid>
            )}
            {email && (
                <Grid item xs={12} align='center'>
                    <Typography variant="caption" component="div" color="textSecondary">
                        E-mail
                    </Typography>
                    <Typography variant="caption" component="div">
                        {email}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} align='center'>
                <Tooltip title="Return to Purchase Order">
                    <IconButton
                        onClick={() => history.push(`/purchase-orders/${po.po_id}`)}
                    >
                        <FAIcon icon="undo" type="light" noMargin button />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

export default Details;