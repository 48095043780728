import thunk from 'redux-thunk';

import { createStore, applyMiddleware, compose } from 'redux';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';

import Reducers from 'Redux/Reducers/Reducers';
import * as NotificationActionTypes from 'Redux/Actions/Notifications/Types/Types';
import * as ServiceWorkerActionTypes from 'Redux/Actions/ServiceWorker/Types/Types';

/* Redux Compose */
const reduxCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* Redux State Sync */
const reduxSyncConfig = {
    whitelist: [
        NotificationActionTypes.CLEAR_USER_DATA,
        NotificationActionTypes.DISCARD_NOTIFICATION,
        NotificationActionTypes.DISCARD_ALL_NOTIFICATIONS,
        NotificationActionTypes.DISCARD_ACTION,
        NotificationActionTypes.HANDLE_API_PULSE,
        NotificationActionTypes.HANDLE_RTAPI_PULSE,
        NotificationActionTypes.I_AM_ACTIVE,
        NotificationActionTypes.MINUS_UNREAD_MESSAGE,
        NotificationActionTypes.LOAD_STAFF_CONFIG,
        ServiceWorkerActionTypes.STATE_CHANGE
    ]
};

/* Redux Middlewares */
const reduxMiddleware = [thunk, createStateSyncMiddleware(reduxSyncConfig)];

/* Redux Store */
export const store = createStore(Reducers, {}, reduxCompose(applyMiddleware(...reduxMiddleware)));

/* Broadcast Store Changes*/
initStateWithPrevTab(store);

export default store;