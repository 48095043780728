import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import PaymentTermsList from './PaymentTermsList';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleSelectChange,
    handleUpdateChange,
    handleUpdateSelectChange,
    handleSnackbarClose,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
    handleDialogClose
} from 'Functions/FormFunctions';

const initialState = {
        access: {
            addPaymentTerm: false,
            updatePaymentTerm: false
        },
        formData: {
            name: '',
            days: ''
        },
        updateData: {
            id: '',
            name: '',
            days: ''
        },
        formErrors:[],
        updateErrors: [],
        dialog: {
            update: false
        },
        rows: [],
        confirmation: {
            submit: false,
            update: false,
        },
        snackbar: {
            submit: false,
            update: false,
        },
        isLoading: true,
        updateLoading: true
    }

class PaymentTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleUpdateChange = handleUpdateChange.bind(this);
        this.handleUpdateSelectChange = handleUpdateSelectChange.bind(this);
        this.handleSnackbarClose = handleSnackbarClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleDialogClose = handleDialogClose.bind(this);
    }

    componentDidMount = () => {
        this.checkAccess();
        this.getPaymentTerms();
    }

    getPaymentTerms = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/accounts/paymentTerms')
            .then(result => {
                if(result.data) {
                    this.setState({
                        rows: result.data,
                        isLoading: false
                    });
                }
            });
        })
    }

    checkAccess = () => {
        Promise.all([ 
            API.access('payment-terms:add'), 
            API.access('payment-terms:update')
        ])
        .then(([accAddRes, accUpdateRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    addPaymentTerm: (accAddRes.data && accAddRes.data.has_access) || false,
                    updatePaymentTerm: (accUpdateRes.data && accUpdateRes.data.has_access) || false
                }
            })
        })
    }

    submit = () => {
        API.post('/accounts/paymentTerms', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbar: {
                        submit: true,
                        update: false
                    }
                });
                this.checkAccess()
                this.getPaymentTerms()
            }
            this.props.scrollToTop();
        })
    }

    update = () => {
        API.put(`/accounts/paymentTerms/${this.state.updateData.id}`, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbar: {
                        submit: false,
                        update: true,
                    }
                });
                this.checkAccess()
                this.getPaymentTerms()
            }
            this.props.scrollToTop();
        })
    }
        
    getUpdateData = () => {
        API.get(`/accounts/paymentTerms/${this.state.updateData.id}`)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose('update');
            } else {
                this.setState({
                    updateLoading: false,
                    updateErrors: [],
                    updateData: {
                        ...this.state.updateData,
                        name: result.data.pt_name,
                        days: result.data.pt_due_days
                    }
                });
            }
        })
    }
    
    handleDialogOpen = id => {
        this.setState({
            dialog: {
                update: true
            },
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                id
            }
        }, () => this.getUpdateData())
    }

    render() {
        const { access, formData, formErrors, isLoading, rows, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Payment Terms
                        </Typography>
                        <PaymentTermsList access={access} paymentTerms={rows} isLoading={isLoading} handleDialogOpen={this.handleDialogOpen} />
                    </PaddedPaper>
                </Grid>
                {(access.addPaymentTerm) && (
                    <Grid item xs={12} lg={6}>
                        <Grid item xs={12} xl={6}>
                            <PaddedPaper>
                                <Typography variant="h6" paragraph>
                                    Add Payment Term
                                </Typography>
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name *"
                                        value={formData.name}
                                        onChange={this.handleChange} 
                                        error={formErrors && formErrors['name'] && true}
                                        helperText={formErrors && formErrors['name']}
                                        margin="none"
                                        fullWidth
                                    />
                                    <TextField
                                        id="days"
                                        name="days"
                                        label="Payment Days *"
                                        value={formData.days}
                                        onChange={this.handleChange} 
                                        error={formErrors && formErrors['days'] && true}
                                        helperText={formErrors && formErrors['days']}
                                        margin="none"
                                        type="number"
                                        fullWidth
                                    />
                                    <div className='buttonRow'>
                                        <Button                                     
                                            variant="text" 
                                            color="primary" 
                                            disabled={!this.state.formData.name}
                                            onClick={() => this.handleConfirmationOpen('submit')}
                                        >
                                            <FAIcon icon="check" size={15} button />
                                            Add
                                        </Button>
                                    </div>
                                    <ConfirmationDialog 
                                        open={this.state.confirmation.update} 
                                        success={() => this.handleConfirmationSuccess('update')} 
                                        close={() => this.handleConfirmationClose('update')} 
                                        title="Update payment term?" 
                                        message="Are you sure you want to update this payment term?"
                                    />
                                    <ConfirmationDialog 
                                        open={this.state.confirmation.submit} 
                                        success={() => this.handleConfirmationSuccess('submit')} 
                                        close={() => this.handleConfirmationClose('submit')} 
                                        title="Add a new payment term?" 
                                        message="Are you sure you want to add a new payment term?"
                                    />
                                    <SnackBar
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={this.state.snackbar.update}
                                        onClose={() => this.handleSnackbarClose('update')}
                                        message='You have successfully updated the specified payment term'
                                    />
                                    <SnackBar
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={this.state.snackbar.submit}
                                        onClose={() => this.handleSnackbarClose('submit')}                                        
                                        message="You have successfully added a new payment term"
                                    />
                                    <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={this.state.dialog.update} onClose={() => this.handleDialogClose('update')} fullWidth={true} maxWidth="sm">
                                        <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Payment Term</DialogTitle>
                                        <DialogContent>
                                            {(updateLoading && (
                                                <LoadingCircle />
                                            )) || (
                                                <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                                    <TextField
                                                        name="name"
                                                        label="Name *"
                                                        value={updateData.name}
                                                        error={updateErrors && updateErrors['name'] && true}
                                                        helperText={updateErrors && updateErrors['name']}
                                                        onChange={this.handleUpdateChange}
                                                        margin="none"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        name="days"
                                                        label="Payment Days *"
                                                        value={updateData.days}
                                                        error={updateErrors && updateErrors['days'] && true}
                                                        helperText={updateErrors && updateErrors['days']}
                                                        onChange={this.handleUpdateChange}
                                                        margin="none"
                                                        type="number"
                                                        fullWidth
                                                    />
                                                </form>
                                            )}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => this.handleDialogClose('update')} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                            <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text"><FAIcon icon="check" size={15} button />Update</Button>
                                        </DialogActions>
                                    </Dialog>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default PaymentTerms;