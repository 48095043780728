import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const ViewDiscount = ({id}) => {

    const [isLoading, setLoading] = useState(true);
    const [model, setModel] = useState({});

    useEffect(() => {
        API.get(`/suppliers/discounts/${id}`)
        .then(result => {
            if(result?.data) {
                setModel(result.data);
                setLoading(false);
            }
        })
    }, [id])

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <Card>
                <Grid container>
                    <Grid item xs={2} align='left' className="sideLeft">
                        <FAIcon type="light" icon='percentage' className='mt-4' style={{marginLeft: 27}} size='xlarge' noMargin />
                    </Grid>
                    <Grid item xs={10}>
                        <CardHeader
                            title={<Typography variant='caption'>Supplier Discount</Typography>}
                            subheader={<Typography variant='h6' style={{color:'#000'}}>{model.supp_disc_name}</Typography>}
                            className='pt-3'
                            disableTypography
                        />
                        <CardContent className='pt-0'>
                            <Typography variant="body2">
                                {model.supp_disc_percentage}% product discount
                            </Typography>
                        </CardContent>
                        <Box pl={2} pr={2}>
                            <Divider />
                        </Box>
                        <CardContent>
                            <Typography variant="h6" paragraph>
                                Products Assigned
                            </Typography>
                            {(!_.isEmpty(model.products) && (
                                _.map(model.products, (product, idx) => (
                                    <Typography variant="body2" gutterBottom>
                                        {product.product_name}
                                    </Typography>
                                ))
                            )) || (
                                <Typography variant="body2" paragraph>
                                    This discount is not assigned to any products
                                </Typography>
                            )}
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        )
    )
}

export default ViewDiscount