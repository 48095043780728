import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import SupplierSearchSelector from 'Components/Suppliers/Misc/SupplierSearchSelector';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { momentFormatDate } from 'Functions/MiscFunctions';

const initialState = () => ({
    date: null,
    supplier: '',
    isLoading: true,
    searchResults: {},
    supplierName: '',
    type: ''
});

class MailingListExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.timeout = false;
    }

    componentDidMount(){
        this.props.pageTitle([0, 'Marketing', 'Mailing List Export']);
        API.get(`/suppliers`)
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getData = () => {

        const { 
            date,
            supplier
         } = this.state;

        this.setState({
            isLoading: true
        }, () => {
            API.get('/reporting/mailingListExport', 
                {
                    params: {
                        date,
                        supplier
                    }
                }
            )
            .then(result => {
                if(result?.data) {
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    });
                }
            });
        })
    }

    handleSetDate = date => {
        this.setState({
            date
        })
    }

    handleSetSupplier = (supplier, supplierName) => {
        this.setState({
            supplier,
            supplierName
        })
    }

    handleSetType = generic => {

        if(generic === 1) {

            this.setState({
                ...initialState(),
                type: 'generic'
            }, () => {
                this.getData();
            })

        } else {

            this.setState({
                type: 'supplier'
            }, () => {
                this.getData();
            })

        }

    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }
    
    reset = () => {
        this.setState({
            ...initialState()
        });
    }

    render() {

        const { 
            date,
            supplier,
            isLoading,
            searchResults,
            supplierName,
            type
        } = this.state;

        return (
            (!type && (
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" gutterBottom>
                                                    Standard Mailing List
                                                </Typography>
                                                <Typography variant="body2">
                                                    <FAIcon icon="info-circle" size={13.5} />
                                                    All applicable contacts will be exported
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button 
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => this.handleSetType(1)}
                                                >
                                                    <FAIcon icon="check" size={15} buttonPrimary />
                                                    Generate
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" gutterBottom>
                                                    Targeted Mailing List
                                                </Typography>
                                                <Typography variant="body2">
                                                    <FAIcon icon="info-circle" size={13.5} />
                                                    Specific contacts will be exported based on your criteria
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box className="content-light-grey">
                                                    <Box p={3}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" className="fw-400">
                                                                    Quoted / ordered products supplied by
                                                                </Typography>
                                                                <Box mt={1.75}>
                                                                    <SupplierSearchSelector 
                                                                        handleAction={this.handleSetSupplier}
                                                                        searchOnly
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" className="fw-400">
                                                                    Since
                                                                </Typography>
                                                                <Box mt={1}>
                                                                    <DatePicker
                                                                        name="date"
                                                                        type="date"
                                                                        onChange={date => this.handleSetDate(date ? moment(date).format('YYYY-MM-DD') : '')}
                                                                        value={date}
                                                                        inputVariant="filled"
                                                                        autoOk
                                                                        disableFuture
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button 
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={supplier === '' || date === ''}
                                                    onClick={() => this.handleSetType(0)}
                                                >
                                                    <FAIcon icon="check" size={15} disabled={supplier === '' || date === ''} buttonPrimary />
                                                    Generate
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>
            )) || (
                <Grid container spacing={1}>      
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {type === 'generic' ? 'Standard' : 'Targeted'} Mailing List
                        </Typography>
                        <Typography variant="body2" className="fw-400" paragraph>
                            <FAIcon icon="info-circle" size={13.5} />
                            {(type === 'generic' && (
                                `Showing all applicable contacts for the standard mailing list`
                            )) || (
                                `Showing contacts from customers who have quoted / ordered items supplied by ${supplierName} since ${momentFormatDate(date)}`
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'rowIdx',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: isLoading,
                                    rowsPerPage: 50,
                                    responsiveImportance: true,
                                    options: {
                                        dataRef: true,
                                        reset: this.reset,
                                        export: {
                                            title: `Mailing List Export`,
                                            name: `mailing-list-export`,
                                            excel: true,
                                            pdf: true,
                                            print: true
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Account Name',
                                        field: rowData => rowData.AccountName,
                                        dataRef: 'AccountName',
                                        main: true,
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Postcode',
                                        field: rowData => rowData.Postcode,
                                        dataRef: 'Postcode',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Prospect Status',
                                        field: rowData => rowData.ProspectStatus,
                                        dataRef: 'ProspectStatus',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Primary Contact',
                                        field: rowData => rowData.PrimaryContact,
                                        dataRef: 'PrimaryContact',
                                        alignment: 'center',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'First Name',
                                        field: rowData => rowData.FirstName,
                                        dataRef: 'FirstName',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Last Name',
                                        field: rowData => rowData.LastName,
                                        dataRef: 'LastName',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Email',
                                        field: rowData => rowData.Email,
                                        dataRef: 'Email',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Mailshot Bar',
                                        field: rowData => rowData.MailshotBar,
                                        dataRef: 'MailshotBar',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Type',
                                        field: rowData => rowData.Type,
                                        dataRef: 'Type',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Address1',
                                        field: rowData => rowData.Address1,
                                        dataRef: 'Address1',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Address2',
                                        field: rowData => rowData.Address2,
                                        dataRef: 'Address2',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Town',
                                        field: rowData => rowData.Town,
                                        dataRef: 'Town',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'County',
                                        field: rowData => rowData.County,
                                        dataRef: 'County',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Staff',
                                        field: rowData => rowData.Staff,
                                        dataRef: 'Staff',
                                        sizeToContent: true,
                                    },
                                ]}
                                rows={searchResults}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )
        )
    }
}

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fs = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, de)),
    }
)

export default connect(null, mapDispatchToProps)(MailingListExport);