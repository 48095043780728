import React, { Fragment, useContext } from 'react';
import Draggable from 'react-draggable';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import WildixContext from 'Context/WildixContext';
import { Grid, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

export const isSafari = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel'
    ].includes(navigator.platform)
}

export const excelColumnIndexToLetter = num => {

    let letters = ''

    while (num >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
        num = Math.floor(num / 26) - 1
    }

    return letters

}

export const excelFitToColumns = (data) => {
    return data[0].map((a, i) => ({ wch: Math.max(...data.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
}

export const isPortrait = () => {
    return window.innerHeight > window.innerWidth;
}

export const isTouchEnabled = () => {
    return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
}

export const isMobile = () => {
    return window.innerWidth < 600;
}

export const isTablet = () => {
    return (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase())) || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) || window.innerWidth < 1400;
}

export function cloneObject(obj) {
    var clone = {};
    for(var i in obj) {
        if(obj[i] != null &&  typeof(obj[i])=="object")
            clone[i] = cloneObject(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
}

export function handleStepChange(offset) {
    this.setState({
        currentStep: (this.state.currentStep + offset)
    })
}   

export function handleTabChange(event, tabIndex) {
    this.setState({
        currentTab: !isNaN(event) ? event : tabIndex 
    }, () => {
        this.savePageState?.();
        if(this.mainContentArea && this.mainContentArea.current) 
            this.mainContentArea.current.scrollTop = 0;
    })
}   

export const base64ImgtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

export const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
}


export const DraggablePaper = (props) => (
    <Draggable handle="#draggable-control" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
    </Draggable>
)

export const TabPanel = ({children, value, index, ...other}) => (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        {...other}
    >
        {value === index && (
            <Box>
                {children}
            </Box>
        )}
    </Typography>
)

export function momentDiffForHumans({a, b, short = true, showSeconds = false}) {
    let diff    = moment.duration(moment(b, "YYYY-MM-DD HH:mm:ss").diff(moment(a, "YYYY-MM-DD HH:mm:ss"))),
        value   = parseInt(diff.asMinutes());
    if(value === 0) {
        value = "< 1";
    }
    return `${value} ${short ? 'min' : 'minute'}${!isNaN(value) && value > 1 ? 's' : ''}${showSeconds ? `and ${parseInt(diff.asSeconds())%60} ${short ? 'secs' : 'seconds'}` : ``}`
}

export function momentFromNow(d, datetime = true) {
    return moment(d, datetime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD").fromNow();
}

export function momentFormatDate(d) {
    return moment(d, "YYYY-MM-DD").format("DD/MM/YYYY");
}

export function momentFormatDateTime(d, seconds = false) {
    return moment(d, "YYYY-MM-DD HH:mm:ss").format(`DD/MM/YYYY HH:mm${seconds ? `:ss` : ``}`);
}

export function toggleDialog(dialog, loadOnExit) {
    let closing = this.state.dialog[dialog]
    this.setState({
        dialog: {
            ...this.state.dialog,
            [dialog]: !this.state.dialog[dialog]
        }
    }, () => {
        if(closing && !loadOnExit) {
            this.loadComponentData(true)
        }
    })
}

export const currencyFormat = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
})

export const isNumeric = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const hex2rgba = (hex, alpha = 1) => {
    if(hex.length === 4) {
        hex = `${hex}${hex.substr(1,4)}`
    }
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
    return `rgba(${r},${g},${b}, ${alpha})`
}

export const getLeadTime = (leadTime, short = false) => {

    let leadTimeText = 'Unknown';

    if(leadTime && leadTime.length > 0) {
        switch(leadTime) {
            case "CHECK": 
                leadTimeText = `Check${!short ? ' Lead Time' : ''}`
            break;
            case "SAME": 
                leadTimeText = "Same Day"
            break;
            default:
                leadTimeText = leadTime.split(":");
                leadTimeText = `${leadTimeText[0]} ${leadTimeText[1].charAt(0)}${leadTimeText[1].slice(1).toLowerCase()}`
            break;
        }
    }

    return leadTimeText;

}

export const accountCreditPosition = idx => {
    idx = parseInt(idx)
    switch(idx) {
        case 0:
            return "Good"
        case 1:
            return "Task"
        case 2: 
            return "Warning"
        case 3:
            return "Legal"
        default:
            return "-"
    }
}

export const accountPaymentDueFrom = idx => {
    idx = parseInt(idx)
    switch(idx) {
        case 0:
            return "days"
        case 1:
            return "days month end"
        case 2: 
            return "days before the last day of the following month"
        case 3:
            return "day of the month"
        case 4:
            return "days from 1 month (from invoice)"
        default:
            return "-"
    }
}

export const accountStatus = idx => {
    idx = parseInt(idx)
    switch(idx) {
        case 0:
            return "Open"
        case 1:
            return "See Notes [On Hold]"
        case 2: 
            return "Closed [On Hold]"
        case 3:
            return "Liquidation [On Hold]"
        case 4:
            return "Expired Credit Limit [On Hold]"
        case 5:
            return "Exceeded Credit Limit [On Hold]"
        case 6:
            return "Bad Debt [On Hold]"
        case 7:
            return "Solicitors [On Hold]"
        case 8:
            return "New"
        case 9:
            return "Cash Only"
        case 10:
            return "Prospect"
        case 11:
            return "Proforma Account [On Hold]"
        case 12:
            return "Factored Account"
        default:
            return false
    }
}

export const accountHold = idx => {
    idx = parseInt(idx)
    switch(idx) {
        case 0:
            return false
        case 1:
            return false
        case 2: 
            return true
        case 3:
            return false
        case 4:
            return false
        case 5:
            return false
        case 6:
            return false
        case 7:
            return false
        case 8:
            return false
        case 9:
            return false
        case 10:
            return false
        case 11:
            return false
        case 12:
            return false
        default:
            return false
    }
}

export const accountStatusList = forClose => {
    return [
        {
            label: "Open",
            value: 0,
            disabled: forClose
        },
        {
            label: "See Notes [On Hold]",
            value: 1,
        },
        {
            label: "Closed [On Hold]",
            value: 2
        },
        {
            label: "Liquidation [On Hold]",
            value: 3
        },
        {
            label: "Expired Credit Limit [On Hold]",
            value: 4
        },
        {
            label: "Exceeded Credit Limit [On Hold]",
            value: 5
        },
        {
            label: "Bad Debt [On Hold]",
            value: 6
        },
        {
            label: "Solicitors [On Hold]",
            value: 7
        },
        {
            label: "New",
            value: 8,
            disabled: forClose
        },
        {
            label: "Cash Only",
            value: 9
        },
        {
            label: "Prospect",
            value: 10,
            disabled: forClose
        },
        {
            label: "Proforma Account [On Hold]",
            value: 11
        },
        {
            label: "Factored Account",
            value: 12
        }
    ]
}

export const cardOrderStatus = idx => {
    idx = parseInt(idx)
    switch(idx) {
        case 1:
            return "1 - Order Created - Awaiting Authorisation"
        case 2: 
            return "2 - Authorisation Received"
        case 3:
            return "3 - Programmed"
        case 4:
            return "4 - Checked"
        case 5:
            return "5 - Despatched"
        case 6:
            return "6 - Completed"
        case 7:
            return "7 - Cancelled"
        default:
            return "Unknown"
    }
}

export const getMonthDaysLeft = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - date.getDate();
}

export const setNestedObject = (ob, pa, v) => pa.split('.').reduce((o,p,i) => o[p] = pa.split('.').length === ++i ? v : o[p] || {}, ob)

export const isEven = x => {
    return !(x & 1);
}

export const isOdd = x => {
    return (x & 1);
}

export const MultiDial = ({numbers}) => (
    !numbers || !numbers?.length ? '-' : (
        <Grid container spacing={1}>
            {numbers.map((i, k) => (
                <Fragment key={k}>
                    <Grid item>
                        <Dial spacing={0} number={i} />
                    </Grid>
                    {k < numbers.length - 1 && (
                        <Grid item>
                            <Typography variant="body1">/</Typography>
                        </Grid>
                    )}
                </Fragment>
            ))}   
        </Grid>
    )
)

export const Dial = ({number, spacing = 1}) => {
    const context = useContext(WildixContext);
    const canDial = context?.dial === null;
    return (
        canDial && number && number.length && number !== '-' ? (
        <Grid container spacing={spacing} alignItems='center' wrap='nowrap'>
            <Grid item>
                <Tooltip title={`Dial ${number}`}>
                    <IconButton onClick={() => context.useDial(number)} size="small">
                        <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                {number}
            </Grid>
        </Grid>
        ) : number
    )
}

export const DialAdornment = ({number}) => {
    const context = useContext(WildixContext);
    const canDial = context?.dial === null;
    return (
        canDial && number && number.length && number !== '-' ? (
            <InputAdornment position="start">
                <Tooltip title={`Dial ${number}`}>
                    <IconButton onClick={() => context.useDial(number)} size="small">
                        <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        ) : null
    )
}