import React from 'react';

import Search from 'Components/Common/Search/Search';

const Supplier = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/supplier',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.an,
                        dataRef: 'an',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.ac,
                        dataRef: 'ac',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'PO Number',
                        field: rowData => rowData.r,
                        dataRef: 'r',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Type',
                        field: rowData => rowData.t,
                        dataRef: 't',
                        sizeToContent: true
                    },
                    // {
                    //     heading: 'Postcode',
                    //     field: rowData => rowData.pc,
                    //     dataRef: 'pc',
                    //     important: true,
                    //     sizeToContent: true
                    // },
                    {
                        heading: 'Despatch Date',
                        field: rowData => rowData.dd,
                        dataRef: 'dd',
                        important: true,
                        sizeToContent: true,
                        fieldFormat: 'date'
                    },
                    {
                        heading: 'Stock Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        important: true,
                    },
                    {
                        heading: 'Quantity',
                        field: rowData => rowData.q,
                        alignment: 'right',
                        dataRef: 'q',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'Unit Buy Price',
                        field: rowData => rowData.ubp,
                        fieldFormat: 'currency',
                        dataRef: 'ubp',
                        important: true,
                    },
                    {
                        heading: 'Unit Trade Price',
                        field: rowData => rowData.usp,
                        fieldFormat: 'currency',
                        dataRef: 'usp',
                        important: true,
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'supplier-report',
                noResultsText: 'There are no suppliers that match your search criteria',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Suppliers'],
                pdf: true,
                persistenceId: 'supplierReport',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: false,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showShipType: false,
                showSupplier: true,
                showTotals: false,
                title: 'Supplier Report'
            }}
        />
    )
}

export default Supplier;