import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { currencyFormat } from 'Functions/MiscFunctions';

const SalesDashboard = ({pageTitle, apiRoute, screen}) => {

    const [data, setData] = useState(null);

    const config = apiRoute ? 
        {
            props: {
                cancellation: true
            }
        } : 
        {    
            params: {
                d: 'sales'
            },
            props: {
                cancellation: true
            }
        };

        const loadComponentData = () => {
        API.get(apiRoute ?? `/dashboard`, config)
        .then(res => {
            if(res?.data) {

                let newData = res.data,
                    chartData = newData?.chart;
                
                newData = {
                    ...newData,
                    chart: {
                        dailyInvoiced: {
                            labels: chartData?.labels ?? [],
                            datasets: [
                                {
                                    label: 'Invoiced',
                                    data: chartData?.dailyInvoiced ?? [],
                                    backgroundColor: '#B3E5FC',
                                    borderColor: '#B3E5FC'
                                },
                            ]
                        },
                        totalVsTarget: {
                            labels: chartData?.labels ?? [],
                            datasets: [
                                {
                                    label: 'Running Target',
                                    data: chartData?.runningTarget ?? [],
                                    fill: false,
                                    backgroundColor: '#C8E6C9',
                                    borderColor: '#C8E6C9',
                                    pointRadius: 0
                                },
                                {
                                    label: 'Running Total',
                                    data: chartData?.runningTotal ?? [],
                                    fill: false,
                                    backgroundColor: '#FFCDD2',
                                    borderColor: '#FFCDD2',
                                    pointRadius: 0
                                },
                            ]
                        }
                    }
                }

                setData(newData);
            }
        });
    }

    useEffect(() => {
        if(pageTitle) {
            pageTitle?.([0, 'Dashboard', 'Sales']);
            loadComponentData();
        }
    }, [pageTitle]); /* eslint-disable-line */

    useEffect(() => {
        loadComponentData();
        let interval = null;
        if(screen) {
            interval = setInterval(loadComponentData, 30000);
        }
        return () => {
            if(interval) {
                clearInterval(interval);
            }
        }
    }, [screen]); /* eslint-disable-line */

    return (
        (!data && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Target
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {currencyFormat.format(data?.target)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Total Days
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {data?.totalDays}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Daily
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {currencyFormat.format(data?.daily)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{width: '20%'}} align="center" className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Date
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{width: '26.66%'}} align="right" className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Daily Invoiced
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{width: '26.66%'}} align="right" className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Running Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{width: '26.66%'}} align="right" className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Running Target
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        {_.map(data.workingDates, (d, i) => (
                                            <TableRow key={i}>
                                                <TableCell style={{padding: screen ? 1.1 : undefined, width: '20%'}} align="center" className="table-cell">
                                                    <Typography variant="body2" className="fw-300">
                                                        {d?.date}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{padding: screen ? 1.1 : undefined, width: '26.66%'}} align="right" className="table-cell">
                                                    <Typography variant="body2" className="fw-300">
                                                        {currencyFormat.format(d?.dailyInvoiced ?? 0)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{padding: screen ? 1.1 : undefined, width: '26.66%'}} align="right" className={d?.aboveTarget === 1 ? `table-cell-success` : `table-cell-error`}>
                                                    <Typography variant="body2" className="fw-300">
                                                        {currencyFormat.format(d?.runningTotal ?? 0)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{padding: screen ? 1.1 : undefined, width: '26.66%'}} align="right" className="table-cell">
                                                    <Typography variant="body2" className="fw-300">
                                                        {currencyFormat.format(d?.runningTarget ?? 0)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    MTD
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {currencyFormat.format(data?.monthToDate)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Total Remaining
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {currencyFormat.format(data?.totalRemaining)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    % To Target
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {data?.percentToTarget}%
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Margin
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {data?.margin}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Projected
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {currencyFormat.format(data?.projected)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Days Worked
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {data?.daysWorked}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Days Remaining
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {data?.daysRemaining}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Daily Achievement
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {currencyFormat.format(data?.dailyAchievement)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="table-cell-bg">
                                                <Typography variant="body2" className="fw-400">
                                                    Daily Requirement
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Typography variant="body2" className="fw-300">
                                                    {currencyFormat.format(data?.dailyRequirement)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Total vs Target
                                    </Typography>
                                    <Box height={screen ? 345 : 400} width={'100%'} maxWidth={'100%'} minHeight={screen ? 245 : 300}>
                                        <Line 
                                            data={data.chart.totalVsTarget} 
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom"
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0
                                                        }
                                                    }]
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Daily Invoiced
                                    </Typography>
                                    <Box height={screen ? 345 : 400} width={'100%'} maxWidth={'100%'} minHeight={screen ? 245 : 300}>
                                        <Bar 
                                            data={data.chart.dailyInvoiced} 
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom"
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        barThickness: 12,
                                                        maxBarThickness: 12,
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0
                                                        }
                                                    }]
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

export default SalesDashboard;