import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StackTable from 'Components/Common/DataTables/StackTable';
import { currencyFormat, DraggablePaper } from 'Functions/MiscFunctions';

const initialState = {
    id: 0,
    payment: {},
    isLoading: true
}

const ViewPayment = props => {

    let [state, setState] = useState(initialState);
    const { open, close} = props;
    const { id, payment, isLoading } = state;

    useEffect(() => {
        if(id > 0) {
            API.get(`/accounts/transactions/${id}`)
            .then(res => {
                if(res && res.data) {
                    setState(state => ({
                        ...state,
                        payment: res.data,
                        isLoading: false
                    }))
                }
            })
        }
    }, [id])

    useEffect(() => {
        if(open === true) {
            setState(state => ({
                ...state,
                id: props.id
            }))
        } else {    
            setState(state => ({
                ...state,
                payment: initialState.payment,
                isLoading: true,
                id: 0
            }))
        }
    }, [props.id, open])

    const rows = [ 
        {
            heading: "Customer",
            value: (payment && payment.order && payment.order.customer && payment.order.customer.cust_company_name) || '-',
        },  
        {
            heading: "Account Number",
            value: (payment && payment.order && payment.order.customer && payment.order.customer.cust_account_number) || '-',
        },  
        {
            heading: "Sage Reference",
            value: (payment && payment.order && payment.order.customer && payment.order.customer.cust_sage_ref) || '-',
        },  
        {
            heading: "Order",
            value: (payment && payment.order && payment.order.order_reference) || '-',
        }
    ];

    if(payment.opp_txn_automated === 1) {
       rows.push( 
            {
                heading: "Automated",
                value: true,
                boolean: true
            }
       )
    }

    rows.push(
        {
            heading: "Authorised",
            value: payment.opp_txn_status === "Authorised" ? true : false,
            boolean: true
        },  
        {
            heading: "Completed",
            value: (payment.opp_txn_status === "Authorised" || payment.opp_txn_status === "Declined") ? true : false,
            boolean: true
        }
    )
    
    if(payment.opp_txn_refund_amount !== "0.00") {
        rows.push( 
            {
                heading: "Refunded",
                value: payment.opp_txn_automated ? true : false,
                boolean: true
            }
        )
    }

    rows.push(
        {
            heading: `${payment.opp_txn_type === 'Payment' ? 'Payment' : 'Refund'} Status`,
            value: payment.opp_txn_status,
            color: payment.opp_txn_status === "Declined" ? "#d32f2f" : (payment.opp_txn_status === "Pending" ? "#FF6F00" : "#2E7D32")
        },   
        {
            heading: `${payment.opp_txn_type === 'Payment' ? 'Payment' : 'Refund'} Amount`,
            value: currencyFormat.format(payment.opp_txn_amount)
        }
    )
    
    if(payment.opp_txn_method === 'Bank Transfer') {
        rows.push( 
            {
                heading: "Payee Name",
                value: payment.opp_bank_transfer_payee || '-',
            },  
            {
                heading: "Payee Account No",
                value: payment.opp_bank_transfer_acc_no || '-',
            },     
            {
                heading: "Payee Sort Code",
                value: payment.opp_bank_transfer_sort_code || '-',
            },           
            {
                heading: "Reference",
                value: payment.opp_bank_transfer_ref,
            }
        )
    }
    
    if(payment.opp_txn_method === 'Card') {
        rows.push( 
            {
                heading: "Card Type",
                value: payment.opp_card_type,
            },     
            {
                heading: "Authorisation No",
                value: payment.opp_card_auth_no,
            }
        )
    }

    if(payment.opp_txn_method === 'Cash') {
        rows.push( 
            {
                heading: "Cash",
                value: "The customer paid cash on collection",
            }
        )
    }

    if(payment.opp_txn_method === 'Cheque') {
        rows.push( 
            {
                heading: "Payee",
                value: payment.opp_cheque_payee,
            },   
            {
                heading: "Cheque No",
                value: payment.opp_cheque_no,
            }
        )
        if(!_.isEmpty(payment.opp_cheque_img)) {
            rows.push(        
                {
                    heading: "Cheque Image",
                    value: <img width="200" src={payment.opp_cheque_img} alt="Cheque" />,
                }
            )
        }
    }

    if(payment.opp_txn_method === 'Credit Account') {
        rows.push( 
            {
                heading: "Credit Account",
                value: `${payment.opp_txn_status === 'Authorised' ? `RED has posted this order to the customer's account in Sage` : `RED will post this order to the customer's account in Sage to complete the transaction`}`,
            }
        )
    }

    if(payment.opp_txn_method === 'Stripe') {
        rows.push( 
            {
                heading: "Intent No",
                value: payment.opp_stripe_intent_id,
            },     
            {
                heading: "Card Type",
                value: payment.opp_stripe_card_type,
            },           
            {
                heading: "Card Number",
                value: payment.opp_stripe_card_no,
            } 
        )
    }

    rows.push(
        {
            heading: "Processed By",
            value: (payment.staff && `${payment.staff.staff_first_name} ${payment.staff.staff_last_name}`) || '-'
        },     
        {
            heading: "Processed On",
            value: moment(payment.opp_created_datetime).format("DD/MM/YYYY HH:mm:ss")
        }
    )

    return (
        (isLoading && (
            <React.Fragment />
        )) || (
            <Dialog
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                maxWidth={'sm'}
                open={open}
                onClose={close}
                fullWidth={true}
                PaperComponent={DraggablePaper}
            >
                <Box className="content-light-grey">
                    <DialogTitle disableTypography style={{paddingBottom: 0}}>
                        <Grid container spacing={3} alignItems='center' style={{cursor: 'move'}} id="draggable-control">
                            <Grid item xs>
                                <Typography variant="h6">
                                    {payment.opp_txn_method} {payment.opp_txn_type}
                                </Typography>
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <Typography variant="caption" className="fw-400">
                                            Reference {payment.opp_uuid}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item align='right'>
                                <IconButton aria-label="Close" onClick={props.close}>
                                    <FAIcon type="light" icon='times' button noMargin />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Box p={3}>
                            {payment.opp_txn_status === "Pending" && (
                                <Box pb={3}>
                                    <Alert severity="warning" variant="standard" elevation={0}>
                                        This transaction is pending completion
                                    </Alert>
                                </Box>
                            )}
                            {payment.opp_txn_status === "Declined" && (
                                <Box pb={3}>
                                    <Alert severity="error" variant="standard" elevation={0}>
                                        This transaction was declined
                                    </Alert>
                                </Box>
                            )}
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={12}>
                                <Box p={3} pt={2} pb={1} className="content-light-white">
                                    <StackTable
                                        heading={`Transaction Overview`}
                                        elevation={0}
                                        rows={rows}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        )
    )
}

export default ViewPayment;