import React from 'react';
import Paper from '@material-ui/core/Paper';

import Alert from 'Components/Common/Alert/Alert';

const PaddedError = ({inline, children}) => (
    <Paper elevation={inline ? 0 : 24}>
        <Alert severity="info">
            {children}
        </Alert>
    </Paper>
)

export default PaddedError;