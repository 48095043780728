import React from 'react';
import _ from 'lodash';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import Alert from 'Components/Common/Alert/Alert';

class NominalCodeList extends React.Component {

    render() {
        const { codes } = this.props;
        return (
            _.map(codes, (category, idx) => {
                return (
                    <ExpansionPanel defaultExpanded key={idx}>
                        <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                            <Typography variant="h6">{category.nom_cat_name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {(category.nominal_codes && category.nominal_codes.length > 0 && (
                                <DataTable  
                                    config={{
                                        key: 'nominal_id',
                                        responsiveImportance: true,
                                        options: {
                                            minimalPadding: true
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: '',
                                            field: rowData => <FAIcon icon='level-up' rotation={90} />,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Nominal Code',
                                            field: rowData => `${rowData.nominal_code} - ${rowData.nominal_name}`,
                                            important: true,
                                            main: true
                                        },
                                    ]}
                                    rows={category.nominal_codes}
                                />
                            )) || (
                                <Alert severity="info">
                                    No nominal codes to display
                                </Alert>
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            })
        );
    }
}

export default NominalCodeList;