import { Box } from "@material-ui/core";
import SalesDashboard from "Components/Dashboard/Types/Sales";

const Screen1 = ({match}) => {
    const uuid = match?.params?.uuid,
        apiRoute = `/screen/${uuid}`;
    return (
        <Box p={3}>
            <SalesDashboard screen apiRoute={apiRoute} />
        </Box>
    )    
}

export default Screen1;