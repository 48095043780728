import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// import Chat from 'Components/Layout/Common/Chat';
import LoadingBar from "Components/Layout/Common/LoadingBar";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import logo from "Assets/Images/logo.svg";
import { clearToken, clearLastActivePage } from "Functions/AuthFunctions";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { HEADER_COLOR, ENVIRONMENT } from "Constants";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "fixed",
        top: 0,
        webkitOverflowScrolling: "touch",
        webkitTransform: "translateZ(0)",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: "0 4px 2px -2px rgba(0,0,0,0.2)",
        backgroundColor: HEADER_COLOR,
    },
    toolbar: {
        height: 45,
        minHeight: 45,
        paddingTop: "env(safe-area-inset-top)",
        marginLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(0.5),
        backgroundColor: HEADER_COLOR,
    },
    logo: {
        width: "65px!important",
        height: "auto",
        marginTop: 5,
    },
    spacer: {
        marginLeft: theme.spacing(0.5),
    },
    user: {
        marginLeft: theme.spacing(0.75),
    },
}));

const WarehouseHeader = ({ toggleDrawer }) => {
    const classes = useStyles(),
        history = useHistory(),
        dispatch = useDispatch();

    const handleLogout = () => {
        clearToken();
        clearLastActivePage();
        history.push("/login");
    };

    return (
        <AppBar position="absolute" className={classes.appBar}>
            <Toolbar disableGutters={true} className={classes.toolbar}>
                <Grid container alignItems="center">
                    <Grid item>
                        <img alt="RED" className={classes.logo} src={logo} />
                    </Grid>
                    {ENVIRONMENT !== "PRODUCTION" && (
                        <>
                            <Grid item>
                                <Grid container alignItems="center" className="ml-3">
                                    <Grid item>
                                        <FAIcon
                                            type="thin"
                                            icon={
                                                ENVIRONMENT === "LOCAL"
                                                    ? "code"
                                                    : ENVIRONMENT === "DEVELOPMENT"
                                                    ? "user-check"
                                                    : ENVIRONMENT === "TRAINING"
                                                    ? "graduation-cap"
                                                    : ""
                                            }
                                            size={20}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className="fw-300 textLight">
                                            {ENVIRONMENT === "LOCAL"
                                                ? "Local"
                                                : ENVIRONMENT === "DEVELOPMENT"
                                                ? "Development"
                                                : ENVIRONMENT === "TRAINING"
                                                ? "Training"
                                                : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs />
                        </>
                    )}
                    <Grid item xs align="right">
                        <Grid container alignItems="center">
                            <Grid item xs />
                            <Grid item className={classes.spacer}>
                                <IconButton color="inherit" onClick={() => history.push("/dashboard")}>
                                    <FAIcon type="solid" icon="home" size={17.5} buttonPrimary noMargin />
                                </IconButton>
                            </Grid>
                            <Grid item className={classes.spacer}>
                                <IconButton color="inherit" onClick={() => toggleDrawer("analytics")}>
                                    <FAIcon type="solid" icon="analytics" size={17.5} buttonPrimary noMargin />
                                </IconButton>
                            </Grid>
                            {/* <Grid item className={classes.spacer}>
                                <Chat smallIcon toggleDrawer={() => toggleDrawer('chat')} />
                            </Grid> */}
                            <Grid item className={classes.spacer}>
                                <IconButton
                                    color="inherit"
                                    onClick={() =>
                                        dispatch(deployConfirmation(`Are you sure you want to logout?`, handleLogout))
                                    }
                                >
                                    <FAIcon type="solid" icon="sign-out-alt" size={17.5} buttonPrimary noMargin />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <LoadingBar />
        </AppBar>
    );
};

export default WarehouseHeader;
