import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import moment from 'moment';

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Alert from "Components/Common/Alert/Alert";
import ActionMenu from "Components/Common/Activity/ActionMenu";
import API from "API";
import BundleForm from "Components/Products/Bundles/BundleForm";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import InsightChip from "Components/Common/Chips/InsightChip";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Logo from "Assets/Images/no-img.jpg";
import SearchQuotes from "Components/Quotes/SearchQuotes/SearchQuotes";
import SearchOrders from "Components/Sales/SearchOrders/SearchOrders";
import ViewPage from "Components/Common/Styles/ViewPage";

import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";

import { deployDialog } from "Redux/Actions/Dialog/Dialog";

import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

import { currencyFormat, handleTabChange, TabPanel } from "Functions/MiscFunctions";

import { VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from "Constants";

import { momentFormatDate } from "Functions/MiscFunctions";

import { CLOUDFRONT_URL } from "Constants";

const initialState = () => ({
    access: {
        viewProduct: false,
        modifyBundle: false,
        searchQuotes: false,
        searchOrders: false,
    },
    currentTab: 0,
    data: {},
    id: 0,
    isLoading: true,
    kitContents: [],
});

const RenderGridDivider = () => (
    <Box pt={2} textAlign="center">
        <FAIcon icon="plus" type="thin" color="primary" size={40} />
    </Box>
);

class ViewBundle extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleTabChange = handleTabChange.bind(this);
        this.mainContentArea = React.createRef();
    }

    componentDidMount() {
        API.multiAccess(["view-product", "product-bundles:update", "view-bundle-quotes", "view-bundle-orders"]).then(([viewProduct, modifyBundle, searchQuotes, searchOrders]) => {
            let id = this.props.match?.params?.id ?? 0,
                currentTab = this.props.location?.state?.currentTab ?? 0;

            this.setState(
                {
                    access: {
                        ...this.state.access,
                        viewProduct,
                        modifyBundle,
                        searchQuotes,
                        searchOrders,
                    },
                    currentTab,
                    id,
                },
                () => {
                    this.loadComponentData();
                }
            );
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location?.pathname !== this.props.location?.pathname) {
            this.setState(
                {
                    currentTab: 0,
                    id: this.props.match.params.id,
                },
                () => {
                    this.setState(
                        {
                            isLoading: true,
                        },
                        () => {
                            this.loadComponentData();
                        }
                    );
                }
            );
        }
    }

    loadComponentData = () => {

        let { id, isLoading } = this.state,
            data = null,
            kitContents = [];

        API.get(`/products/bundles/${id}`).then((result) => {
            if (result && result?.data) {
                if (result.data?.errors) {
                    this.props.history.push("/products/bundles");
                } else {
                    data = result.data;

                    isLoading = false;

                    if(data.ty === "Fixed Kit") {
                        kitContents = _.map(data.cats, (c) => (
                            _.assign({
                                ...c.p[0],
                                qty: c.qty
                            })
                        ))
                    }
                    
                    this.setState(
                        {
                            data,
                            isLoading,
                            kitContents
                        },
                        () => {
                            this.props.pageTitle([1, "Products", "Bundles / Kits", `${data.nm}`]);
                        }
                    );
                }
            }
        });
    };

    handleDeployModifyBundle = () => {
        this.props.deployDialog(<BundleForm callback={this.loadComponentData} id={this.state.id} data={this.state.data} />, false, "Update Product Bundle", "standard", "xl", true, true);
    };

    render = () => {
        const { classes, history, ui } = this.props;

        const { access, currentTab, data, isLoading } = this.state;

        const height = window.matchMedia("(display-mode: standalone)").matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;

        const contentHeight = window.matchMedia("(display-mode: standalone)").matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height - VIEW_PAGE_OFFSET}px)` : height - VIEW_PAGE_OFFSET;

        return (
            <>
                <Grid container style={{ margin: -24, width: "initial" }}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{ backgroundColor: "#fff", borderBottom: "1px solid #ddd", height: VIEW_PAGE_APP_BAR_HEIGHT }} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item>
                                                <Avatar style={{ width: 60, height: 60 }} variant="square" alt={data.nm} src={!_.isEmpty(data.im) ? `${CLOUDFRONT_URL}${data.im}` : Logo} />
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container alignItems="center">
                                                    <Grid item xs>
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <Typography variant="h4" className="textDefault">
                                                                    {data.pc} - {data.nm}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <ActionMenu
                                                            rows={[
                                                                {
                                                                    icon: "pencil-alt",
                                                                    label: "Modify Bundle",
                                                                    onClick: this.handleDeployModifyBundle,
                                                                    display: true,
                                                                    disabled: !access.modifyBundle || data.del === 1,
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container justify="space-between" alignItems="center">
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item>
                                                                        <InsightChip
                                                                            icon={'cog'}
                                                                            label={`Type: ${data.ty}`}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <InsightChip
                                                                            icon={data.lv === 0 || data.nv === 0 ? "times-circle" : "check-circle"}
                                                                            label={data.lv === 0 || data.nv === 1 ? "Status: Inactive" : "Status: Active"}
                                                                        />
                                                                    </Grid>
                                                                    {data.ty === 'Fixed Kit' && (
                                                                        <Grid item>
                                                                            <InsightChip
                                                                                icon={'calendar-week'}
                                                                                label={`Valid: ${momentFormatDate(data.vf)} - ${momentFormatDate(data.vt)}`}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item>
                                                                        <InsightChip
                                                                            icon="truck"
                                                                            label={`Buy Price: ${currencyFormat.format(data?.minCost)}${data?.ty === 'Optional Bundle' ? ` - ${currencyFormat.format(data.maxCost)}` : ``}`}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <InsightChip
                                                                            icon="money-bills-simple"
                                                                            label={`Trade Price: ${currencyFormat.format(data?.ty === 'Optional Bundle' ? data?.minPrice : data?.maxPrice)}${data?.ty === 'Optional Bundle' ? ` - ${currencyFormat.format(data.maxPrice)}` : ``}`}
                                                                        />
                                                                    </Grid>
                                                                    {data?.ty === 'Fixed Kit' && (
                                                                        <Grid item>
                                                                            <InsightChip
                                                                                icon="tags"
                                                                                label={`Kit Price: ${currencyFormat.format(data.fp)}`}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item>
                                                                        <InsightChip icon="percentage" label={`Margin: ${data?.minMargin}%${data?.ty === 'Optional Bundle' ? ` - ${data.maxMargin}%` : ``}`} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <InsightChip icon={"calendar"} label={`Updated: ${momentFormatDate(data?.dt)}`} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{ height: contentHeight - VIEW_PAGE_APP_BAR_HEIGHT }}>
                                <Grid container>
                                    {data.del === 1 && (
                                        <Grid item xs={12}>
                                            <Box borderBottom="1px solid #ddd">
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <strong>Bundle Deleted</strong> - Please contact Sales Admin to re-instate this bundle...
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={2}>
                                                    {(data?.ty === 'Optional Bundle' && (
                                                        _.map(data.cats, (c, idx) => (
                                                            <Grid item xs={12} key={idx}>
                                                                <Paper>
                                                                    <Box p={3}>
                                                                        <Typography variant="h6">
                                                                            {c.text}
                                                                            {c.req === 0 ? " (Optional)" : ""} x {c.qty}
                                                                        </Typography>
                                                                        {/* <Typography variant="body2">
                                                                            Select {c.req === 0 ? 'any' : 'one'} of the following products -
                                                                        </Typography> */}
                                                                        <DataTable
                                                                            config={{
                                                                                key: "id",
                                                                                pagination: false,
                                                                                alternatingRowColours: true,
                                                                                noResultsText: "No products for this category",
                                                                            }}
                                                                            columns={[
                                                                                {
                                                                                    field: (rowData) => (
                                                                                        <Avatar variant="square" alt={rowData.nm} src={(rowData.im && `${CLOUDFRONT_URL}${rowData.im}`) || Logo} />
                                                                                    ),
                                                                                    sizeToContent: true,
                                                                                    important: true,
                                                                                },
                                                                                {
                                                                                    heading: "Product Code",
                                                                                    field: (rowData) => rowData.dc,
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "Description",
                                                                                    field: (rowData) => (
                                                                                        <>
                                                                                            {rowData.nm}
                                                                                            {rowData.rp === 1 && (
                                                                                                <Typography variant="caption" component="div" className="textError">
                                                                                                    This is a replacement product
                                                                                                </Typography>
                                                                                            )}
                                                                                        </>
                                                                                    ),
                                                                                    main: true,
                                                                                    truncate: true,
                                                                                },
                                                                                {
                                                                                    heading: "Supplier",
                                                                                    field: (rowData) => rowData.su,
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "Trade Price",
                                                                                    field: (rowData) => rowData.tp,
                                                                                    fieldFormat: "currency",
                                                                                    sizeToContent: true,
                                                                                    alignment: "right",
                                                                                },
                                                                                {
                                                                                    heading: "Available",
                                                                                    field: (rowData) => (rowData.su === "N/A" ? "-" : rowData.sa),
                                                                                    alignment: "right",
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "On Order",
                                                                                    field: (rowData) => (rowData.su === "N/A" ? "-" : rowData.oo),
                                                                                    alignment: "right",
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "Margin",
                                                                                    field: (rowData) => rowData.mr,
                                                                                    fieldFormat: "percentage:1",
                                                                                    alignment: "right",
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "Bin Location",
                                                                                    field: (rowData) => rowData.wb,
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    actions: (rowData) => [
                                                                                        {
                                                                                            name: "View Product",
                                                                                            icon: "binoculars",
                                                                                            onClick: () => history.push(`/products/${rowData.id}`),
                                                                                            disabled: rowData.id <= 0 || !access.viewProduct,
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ]}
                                                                            rows={c.p}
                                                                        />
                                                                    </Box>
                                                                </Paper>
                                                                {idx + 1 !== _.size(data.cats) && <RenderGridDivider />}
                                                            </Grid>
                                                        ))
                                                    )) || (
                                                        <>
                                                            {data.nv === 1 && (
                                                                <Grid item xs={12}>
                                                                    <Alert severity="info">
                                                                        <strong>Kit {moment(data.vt).isBefore(moment()) ? 'Expired' : 'Scheduled'}</strong><br />
                                                                        This kit is valid from {momentFormatDate(data.vf)} to {momentFormatDate(data.vt)}
                                                                    </Alert>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <Paper>
                                                                    <Box p={3}>
                                                                        <Typography variant="h6">
                                                                            Kit Contents
                                                                        </Typography>
                                                                        {/* <Typography variant="body2">
                                                                            Select {c.req === 0 ? 'any' : 'one'} of the following products -
                                                                        </Typography> */}
                                                                        <DataTable
                                                                            config={{
                                                                                key: "id",
                                                                                pagination: false,
                                                                                alternatingRowColours: true,
                                                                                noResultsText: "No products for this kit",
                                                                            }}
                                                                            columns={[
                                                                                {
                                                                                    heading: "Quantity",
                                                                                    field: (rowData) => rowData.qty,
                                                                                    sizeToContent: true,
                                                                                    alignment: 'center'
                                                                                },
                                                                                {
                                                                                    heading: "Product",
                                                                                    field: rowData => (
                                                                                        <Grid container spacing={1}>
                                                                                            <Grid item>
                                                                                                <Avatar variant="square" alt={rowData.nm} src={(rowData.im && `${CLOUDFRONT_URL}${rowData.im}`) || Logo} />
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <Typography variant="body2" className="fw-400">
                                                                                                    {rowData.dc}
                                                                                                </Typography>
                                                                                                <Typography variant="caption" component="div">
                                                                                                    {rowData.nm.substr(0, 65)}
                                                                                                    {rowData.nm.length > 65 ? "..." : ""}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    ),
                                                                                    important: true
                                                                                },
                                                                            
                                                                                {
                                                                                    heading: "Supplier",
                                                                                    field: (rowData) => rowData.su,
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "Trade Price",
                                                                                    field: (rowData) => rowData.tp,
                                                                                    fieldFormat: "currency",
                                                                                    sizeToContent: true,
                                                                                    alignment: "right",
                                                                                },
                                                                                {
                                                                                    heading: "Available",
                                                                                    field: (rowData) => (rowData.su === "N/A" ? "-" : rowData.sa),
                                                                                    alignment: "right",
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "On Order",
                                                                                    field: (rowData) => (rowData.su === "N/A" ? "-" : rowData.oo),
                                                                                    alignment: "right",
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "Margin",
                                                                                    field: (rowData) => rowData.mr,
                                                                                    fieldFormat: "percentage:1",
                                                                                    alignment: "right",
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    heading: "Bin Location",
                                                                                    field: (rowData) => rowData.wb,
                                                                                    sizeToContent: true,
                                                                                },
                                                                                {
                                                                                    actions: (rowData) => [
                                                                                        {
                                                                                            name: "View Product",
                                                                                            icon: "binoculars",
                                                                                            onClick: () => history.push(`/products/${rowData.id}`),
                                                                                            disabled: rowData.id <= 0 || !access.viewProduct,
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ]}
                                                                            rows={this.state.kitContents}
                                                                        />
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <SearchQuotes bundleId={data.id} noPersistence />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <SearchOrders bundleId={data.id} noPersistence />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{ height: contentHeight - VIEW_PAGE_APP_BAR_HEIGHT }}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        },
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={0} label="Overview" />
                                    <Divider />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={1} disabled={!access.searchQuotes} label={`Quotes`} />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={2} disabled={!access.searchOrders} label={`Orders`} />
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        );
    };
}

const mapStateToProps = (state) => ({
    staff: state.notifications.status,
    loggedInStaff: state.staffAuth.staff,
    statePersistence: state.statePersistence,
    ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
    deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
    deployDialog: (content, disableDialogContent = false, title = "", variant = "standard", size = "md", fs = false, de = false) =>
        dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, de)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ViewBundle));
