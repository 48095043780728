import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Prompt } from 'react-router';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import CustomerSearchSelector from 'Components/Customers/Misc/CustomerSearchSelector';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Form from 'Components/Common/Styles/Form';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Billing from 'Components/RMA/RMAForm/Billing';
import Button from 'Components/RMA/RMAForm/Button';
import ContactUpdates from 'Components/RMA/RMAForm/ContactUpdates';
import Defaults from 'Components/RMA/RMAForm/Defaults';
import Notes from 'Components/RMA/RMAForm/Notes';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Product from 'Components/RMA/RMAForm/Product';
import Products from 'Components/RMA/RMAForm/Products';
import Preview from 'Components/RMA/RMAForm/Preview';
import RMAOrderProductSelection from 'Components/RMA/RMAForm/RMAOrderProductSelection';
import Tabs from 'Components/RMA/RMAForm/Tabs';
import Textarea from 'Components/Common/Inputs/Textarea';
import Totals from 'Components/RMA/RMAForm/Totals';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleChange, handleFloat } from 'Functions/FormFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { TabPanel } from 'Functions/MiscFunctions';
import { VIEW_PAGE_OFFSET } from 'Constants';

const initialState = () => ({
    alertText: null,
    currentTab: 0,
    customer: {
        data: {},
        id: 0
    },
    disableCustomerTab: false,
    existingNotes: [],
    formData: {
        contactEmail: '',
        contactPhone: '',
        customer: null,
        customerContact: '',
        customerVatPercent: 0,
        customerVatValue: 0,
        defaultAction: null,
        defaultReason: null,
        defaultReStockingFee: null,
        notes: 'IF GOODS ARE NOT RETURNED WITHIN 30 DAYS THEY CANNOT BE ACCEPTED BACK & THE RETURN WILL BE CLOSED.\r\nFOR NO LONGER REQUIRED ITEMS, IF GOODS ARE NOT RETURNED WITHIN 21 DAYS OF INVOICE THEY CANNOT BE ACCEPTED BACK & THE RETURN WILL BE CLOSED',
        productRows: [],
        costTotal: "0.00",
        vatTotal: "0.00",
        netTotal: "0.00",
        grossTotal: "0.00",
        internalNotes: []
    },
    formErrors: [],
    id: false,
    initialLoading: true,
    initialFormData: {
        contactEmail: '',
        contactPhone: '',
        customer: null,
        customerContact: '',
        customerVatPercent: 0,
        customerVatValue: 0,
        defaultAction: null,
        defaultReason: null,
        defaultReStockingFee: null,
        notes: 'IF GOODS ARE NOT RETURNED WITHIN 30 DAYS THEY CANNOT BE ACCEPTED BACK & THE RETURN WILL BE CLOSED.\r\nFOR NO LONGER REQUIRED ITEMS, IF GOODS ARE NOT RETURNED WITHIN 21 DAYS OF INVOICE THEY CANNOT BE ACCEPTED BACK & THE RETURN WILL BE CLOSED',
        productRows: [],
        costTotal: "0.00",
        vatTotal: "0.00",
        netTotal: "0.00",
        grossTotal: "0.00",
        internalNotes: []
    },
    isLoading: false,
    isLoadingPreview: false,
    lists: {
        teams: []
    },
    products: [],
    preview: {
        pdf: false,
        numPages: null,
        pageNumber: 1
    },
    ui: {
        height: 0,
        contentHeight: 0
    },
    unsavedLine: false
})

class RMAForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
        this.handleFloat = handleFloat.bind(this)
        this.poll = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            if(this.poll) {
                clearInterval(this.poll);
            }
            this.setState({
                ...initialState()
            }, () => this.loadComponentData());
        }
        if(JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if(!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if(window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    }
    
    componentWillUnmount = () => {
        if(window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
        if(this.poll) {
            clearInterval(this.poll);
        }
        this.props.closeDialog()
    }

    handlePoll = (initial = false) => {
        API.post(`/staff/my/view`, 
            { 
                rma: this.state.id,
                type: 'Update'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {

                    const   update      = _.some(res.data, el => el.u === 1),
                            process     = _.some(res.data, el => el.p === 1),
                            received    = _.some(res.data, el => el.re === 1);

                    if(!_.isEmpty(res.data) && (_.some(res.data, el => el.u === 1) || _.some(res.data, el => el.p === 1 ) || _.some(res.data, el => el.re === 1 ))) {
                        this.props.deploySnackBar("error", `Sorry, another user is currently ${update ? 'modifying' : (process ? 'processing' : (received ? 'booking in' : 'working on'))} this RMA`);
                        this.props.history.push(`/rma/${this.state.id}`)
                    } else {
                        if(this.state.initialLoading) {
                            this.setState({
                                initialLoading: false
                            })
                        }
                    }
                }
            }
        })
    }

    loadComponentData = () => {
        const { ui } = this.props;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        const id = this.props.cistomer ? false : (this.props.id ?? this.props.match?.params?.id ?? false);
        this.setState({
            id,
            ui: {
                height,
                contentHeight
            }
        }, () => {
            this.getData(true);
            if(this.props.pageTitle) {
                if(!this.props.replicate) {
                    this.props.pageTitle([1, "RMA", `${this.state.id ? 'Update' : 'New'} RMA`])
                }
            }
        })
    }
    
    getData = initialLoad => {
        
        const initialLoading = this.state.id ? true : (this.props.match?.params?.orderId ? true : false);

        const lists = {
            teams: this.props.teamList
        };

        this.setState({
            initialLoading,
            lists
        }, () => {
            if(initialLoad) {
                if(this.props.match?.params?.customer) {
                    this.getCustomer(this.props.match.params.customer);
                } else if(this.props.match?.params?.orderId) {
                    this.generateRMAfromSO(this.props.match?.params?.orderId);
                } else if(this.state.id) {
                    this.getUpdateData();
                }
            }
        })
    }

    generateRMAfromSO = id => {

        const {
            closeDialog,
            deployConfirmation,
            deployDialog,
            deploySnackBar
        } = this.props;

        API.get(`/sales/${id}`)
        .then(res => {
            if(res?.data) {
                
                let order           = res.data,
                    orderProducts   = [];

                this.getCustomer(order.order_customer_id);

                _.each(order.deliveries, del => {
                    _.each(del.products, op => {
                        if(op.productId > 0) {
                            orderProducts.push({
                                ...op, 
                                od: del.od_id, 
                                ref: `${del.od_reference}/${del.od_idx}`, 
                                rtn: (parseInt(op.quantity) - parseInt(op.rma)) <= 0 ? 0 : 1, 
                                qty: (parseInt(op.quantity) - parseInt(op.rma)), 
                                action: order.order_pt_id === 1 ? 'CCR' : 'COA', 
                                reason: 'NO_LONGER_REQUIRED', 
                                restockingFee: "0.00", 
                                date: del.od_schedule_date
                            });
                        }
                    })
                })

                if(!_.isEmpty(orderProducts)) {
                    if(_.every(orderProducts, el => el.rtn === 0)) {
                        deploySnackBar(`error`, `All products have been previously returned from this sales order`)
                        this.setState({
                            initialLoading: false
                        })
                    } else {
                        deployDialog(
                            <RMAOrderProductSelection 
                                order={order} 
                                orderProducts={orderProducts} 
                                handleSubmit={this.handleSalesOrderReturn}
                                closeDialog={closeDialog}
                                deployConfirmation={deployConfirmation}
                            />, 
                            true, 
                            "", 
                            "standard", 
                            "xl", 
                            false, 
                            true
                        );
                    }
                } else {
                    this.setState({
                        initialLoading: false
                    })
                }

            }
        })
    }

    handleSalesOrderReturn = (order, rows) => {

        let products        = _.filter(rows, el => el.rtn === 1),
            productRows     = [],
            initialLoading  = false;

        _.each(products, row => {

            let restockFee = ((row.subTotal / 100) * row.restockingFee).toFixed(2);

            productRows.push({
                productId: row.productId,
                productName: row.productName,
                productCode: row.productCode,
                quantity: row.qty,
                cost: row.cost,
                price: row.subTotal,
                subTotal: (row.subTotal - restockFee).toFixed(2),
                restockFee: restockFee,
                restockFeeTotal: (row.qty * restockFee).toFixed(2),
                restockPercent: row.restockingFee,
                total: (row.qty * (row.subTotal - restockFee)).toFixed(2),
                originalQuantity: row.quantity,
                image: row?.image ?? '',
                productData: row.product,
                option: {},
                orderRef: row.ref,
                deliveryDate: row.date,
                serial: '',
                rmaNotes: '',
                sysNotes: '',
                action: row.action,
                reason: row.reason,
                odp: row.odp_id,
                od: row.od
            })
        })

        this.props.closeDialog();

        this.setState({
            formData: {
                ...this.state.formData,
                productRows
            },
            initialLoading
        }, () => {
            this.handleCalculateTotals();
        })

    }

    getCustomer = (id, forUpdate = false) => {

        const {
            formData
        } = this.state;

        this.setState({
            isLoading: true
        }, () => {

            API.get(`/customers/${id}`)
            .then(res => {

                if(res?.data) {

                    let customer            = res.data,
                        addressList         = [], 
                        contactList         = [],
                        isLoading           = false,
                        tradingAddressIdx   = 0;

                    if(customer.addresses) {
                        tradingAddressIdx = _.findIndex(customer.addresses, el => el.t === "Trading");
                        if(tradingAddressIdx < 0) {
                            tradingAddressIdx = 0;
                        }
                        addressList = _.map(_.filter(customer.addresses, el => (el.t === "Delivery" && el.l1 !== customer.addresses?.[tradingAddressIdx]?.l1) || el.t === "Trading"), el => (
                            _.assign({
                                label: `${el.l1 && `${el.l1}, `}${el.to && `${el.to}, `}${el.po}`,
                                value: el.i,
                            })
                        ));
                    }
                    
                    if(customer.contacts) {
                        contactList = _.map(customer.contacts, el => (
                            _.assign({
                                label: `${el.fn.length > 0 && `${el.fn} `}${el.ln}`,
                                value: el.i,
                            })
                        ));
                    }

                    if(!_.isEmpty(formData?.customerContact) && isNaN(formData?.customerContact)) {
                        if(_.findIndex(contactList, el => el.value === formData.customerContact) === -1) {
                            contactList.push({label: formData.customerContact, value: formData.customerContact})
                        }
                    }

                    let customerVatPercent = forUpdate ? this.state.formData.customerVatPercent : customer?.vat_type?.default_vat_value?.vat_value_percentage ?? null;

                    this.setState({
                        currentTab: 1,
                        customer: {
                            addressList,
                            contactList,
                            data: customer,
                            id: customer.cust_id,
                        },
                        formData: {
                            ...this.state.formData,
                            customer: customer.cust_id,
                            customerAddress: forUpdate ? this.state.formData.customerAddress : customer?.addresses?.[tradingAddressIdx]?.i ?? '',
                            customerVatValue: forUpdate ? this.state.formData.customerVatValue : customer?.vat_type?.default_vat_value?.vat_value_id ?? null,
                            customerVatPercent
                        },
                        isLoading
                    }, () => {

                        if(this.props.pageTitle && !forUpdate) {
                            this.props.pageTitle([1, 'RMA', `New RMA`, customer.cust_company_name])
                        }

                    });
                }
            })
        })
    }

    getUpdateData = () => {
        
        const { 
            deploySnackBar,
            history 
        } = this.props;

        const { 
            id 
        } = this.state;

        API.get(`/rma/${id}`, {
            params: { 
                forUpdate: true
            }
        })
        .then(res => {
            if(res?.data) {

                if(res?.data?.rma_status === 'Awaiting Approval' && !this.props.forApproval) {
                    deploySnackBar("error", `This RMA is still awaiting approval`)
                    history.push(`/rma/${id}`)
                    return;
                }

                if(res?.data?.rma_status !== 'Awaiting Approval' && this.props.forApproval) {
                    deploySnackBar("error", `A decision to approve / decline this RMA has already been made`)
                    history.push(`/rma/${id}`)
                    return;
                }

                if(res?.data?.rma_status === 'Declined' || res?.data?.rma_status === 'Cancelled' || res.data?.rma_status === 'Complete') {
                    deploySnackBar("error", `This RMA can no longer be modified as it's ${res.data?.rma_status?.toLowerCase?.()}`)
                    history.push(`/rma/${id}`)
                    return;
                }

                let currentTab          = 1,
                    alertText           = null,
                    disableCustomerTab  = true,
                    formData            = initialState().formData,
                    existingNotes       = res.data.notes,
                    productRows         = [];

                _.each(res.data.products, rp => {
                    const   rpId                = rp?.rp_id,
                            rpStatus            = rp?.rp_status,
                            productId           = rp?.rp_product_id,
                            productName         = rp?.rp_product_name,
                            productCode         = rp?.rp_product_code,
                            quantity            = rp?.rp_quantity,
                            recQuantity         = rp?.rp_rec_quantity,
                            recPreQuantity      = rp?.rp_rec_pristine_quantity,
                            cost                = rp?.rp_cost,
                            price               = rp?.rp_paid,
                            subTotal            = rp?.rp_credit,
                            restockFee          = rp?.rp_restock_fee,
                            restockFeeTotal     = rp?.rp_total_restock_fee,
                            restockPercent      = parseFloat(rp?.rp_restock_percent).toFixed(2),
                            total               = rp?.rp_total_credit,
                            originalQuantity    = rp?.rp_quantity,
                            image               = rp?.img,
                            productData         = rp?.product,
                            orderRef            = rp?.rp_order_ref,
                            deliveryDate        = rp?.rp_delivery_date,
                            serial              = rp?.rp_serial,
                            rmaNotes            = rp?.rp_notes,
                            sysNotes            = rp?.rp_sys_notes,
                            action              = rp?.action?.ra_code,
                            reason              = rp?.reason?.rr_code,
                            odId                = rp?.rp_od_id,
                            odpId               = rp?.rp_odp_id,
                            received            = rp?.rp_received,
                            receivedDatetime    = rp?.rp_received_datetime,
                            withTech            = rp?.rp_with_tech,
                            withSupplier        = rp?.rp_with_supplier,
                            rtnToSupplier       = rp?.rp_rtn_to_supplier,
                            rtnToSupplierRef    = rp?.rp_rtn_to_supplier_ref,
                            restock             = rp?.rp_restock,
                            noFault             = rp?.rp_no_fault,
                            actionDate          = rp?.rp_action_date,
                            suppCredit          = rp?.rp_supp_credit,
                            suppCreditRef       = rp?.rp_supp_credit_ref,
                            suppCreditDate      = rp?.rp_supp_credit_date,
                            repair              = rp?.rp_repair,
                            replace             = rp?.rp_replace,
                            aaCredit            = rp?.rp_aa_credit,
                            aaCreditRef         = rp?.rp_aa_credit_ref,
                            noAction            = rp?.rp_no_action,
                            closed              = rp?.rp_closed,
                            status              = rp?.rp_status,
                            option              = {};                          

                    productRows.push({
                        rpId,
                        rpStatus,
                        productId,
                        productName,
                        productCode,
                        quantity,
                        recQuantity,
                        recPreQuantity,
                        cost,
                        price,
                        subTotal,
                        restockFee,
                        restockFeeTotal,
                        restockPercent,
                        total,
                        originalQuantity,
                        image,
                        productData,
                        option,
                        orderRef,
                        deliveryDate,
                        serial,
                        rmaNotes,
                        sysNotes,
                        action,
                        reason,
                        odpId,
                        odId,
                        received,
                        receivedDatetime,
                        withTech,
                        withSupplier,
                        rtnToSupplier,
                        rtnToSupplierRef,
                        restock,
                        noFault,
                        actionDate,
                        suppCredit,
                        suppCreditRef,
                        suppCreditDate,
                        repair,
                        replace,
                        aaCredit,
                        aaCreditRef,
                        noAction,
                        closed,
                        status
                    })

                })

                formData = {
                    ...formData,
                    contactEmail: res.data.rma_email,
                    contactPhone: res.data.rma_phone,
                    customer: res.data.rma_cust_id,
                    customerContact: res.data.rma_contact_id > 0 ? res.data.rma_contact_id : res.data.rma_contact_name,
                    customerAddress: res.data.rma_address_id,
                    customerVatValue: res.data.rma_vat_value_id,
                    customerVatPercent: res.data?.vat?.vat_value_percentage,
                    notes: res.data.rma_notes,
                    costTotal: res.data.rma_total_cost,
                    netTotal: res.data.rma_total_net,
                    vatTotal: res.data.rma_total_vat,
                    grossTotal: res.data.rma_total_gross,
                    productRows
                }

                if(res?.data?.replacement_order) {
                    alertText = `Replacement Sales Order #${res?.data.replacement_order?.order_reference} has already been generated for this RMA`
                }

                this.setState({
                    alertText,
                    currentTab,
                    disableCustomerTab,
                    existingNotes,
                    formData,
                }, () => {

                    if(this.props.pageTitle) {
                        this.props.pageTitle([1, "RMA", `Update RMA`, `${res.data.customer?.cust_company_name}`, `#${res.data.rma_reference}`]);
                    }

                    this.getCustomer(res.data.rma_cust_id, true);

                    this.handleCalculateTotals(true);
                    
                    this.handlePoll(true);
                    this.poll = setInterval(this.handlePoll, 3000);
                });
            }
        })
    }

    handleTabChange = (event, tabIndex) => {

        const currentTab = !isNaN(event) ? event : tabIndex;

        const { 
            unsavedLine
        } = this.state;

        if(unsavedLine) {
            this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to change tab?</Typography><Typography variant="body2">You have an unsaved line</Typography></>, () => {
                this.setState({ unsavedLine: false }, () => {
                    this.handleDoTabChange(currentTab);
                });
            });
        } else {
            if(currentTab === 0) {
                this.props.deployConfirmation("Are you sure you want to change the customer?", this.handleCustomerReset)
            } else {
                this.handleDoTabChange(currentTab);
            }
        }
    }   

    handleDoTabChange = currentTab => {
        const isLoading = currentTab === 4 ? true : this.state.isLoading
        this.setState({
            currentTab,
            isLoading 
        }, () => {
            if(this.state.currentTab === 3) {
                this.handleSubmitPreview();
            }
            if(this.mainContentArea && this.mainContentArea.current) 
                this.mainContentArea.current.scrollTop = 0;
        })
    }
    
    handleCalculateTotals = () => {
        const {formData } = this.state;
        let costTotal       = "0.00",
            netTotal        = "0.00",
            grossTotal      = "0.00",
            vatTotal        = "0.00";

        formData.productRows.forEach(row => {
            costTotal = (parseFloat(costTotal) - (parseFloat(row.productData?.product_cost) * row.quantity)).toFixed(2)
            netTotal = (parseFloat(netTotal) - parseFloat(row.total)).toFixed(2)
        })
        
        if(formData.customerVatPercent !== null) {
            vatTotal = ((netTotal / 100) * formData.customerVatPercent).toFixed(2)
        }

        grossTotal = (parseFloat(netTotal) + parseFloat(vatTotal)).toFixed(2);

        this.setState({
            formData: {
                ...this.state.formData,
                costTotal,
                vatTotal,   
                netTotal,
                grossTotal
            }
        })
    }
    
    handleCustomerChange = id => {
        if(id) {
            this.getCustomer(id)
        }
    }

    handleCustomerReset = () => {
        this.setState({
            ...initialState()
        }, () => { 
            this.handleDoTabChange(0); 
            this.loadComponentData(); 
        })
    }

    handleProductAdd = productRow => {
        let productRows = [...this.state.formData.productRows];
        productRows.push(productRow)
    
        this.setState({
            formData: {
                ...this.state.formData,
                productRows,
            },
            unsavedLine: false
        }, () => this.handleCalculateTotals())
    }

    handleProductEdit = (idx, productRow) => {
        let productRows = [...this.state.formData.productRows];
        productRows[idx] = productRow;
        this.setState({
            formData: {
                ...this.state.formData,
                productRows,
            }
        }, () => this.handleCalculateTotals())
    }

    handleProductRemove = idx => {
        const productRows = [...this.state.formData.productRows]
        productRows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                productRows
            }
        }, ()=> this.handleCalculateTotals())
    }

    handleNoteAdd = note => {
        let internalNotes = [...this.state.formData.internalNotes];
        internalNotes.push(note)
    
        this.setState({
            formData: {
                ...this.state.formData,
                internalNotes,
            }
        }, () => this.handleCalculateTotals())
    }
  
    handleNoteEdit = note => {
        let internalNotes = [...this.state.formData.internalNotes];
        internalNotes[note.rowIdx] = note;
        this.setState({
            formData: {
                ...this.state.formData,
                internalNotes,
            }
        }, () => this.handleCalculateTotals())
    }

    handleNoteRemove = idx => {
        const internalNotes = [...this.state.formData.internalNotes]
        internalNotes.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                internalNotes
            }
        }, ()=> this.handleCalculateTotals())
    }
    
    handleSelectChange = (fieldName, selectedOption) => {

        if(fieldName === "customerContact") {
            
            let contact = selectedOption?.value ?? "";

            if(isNaN(contact)) {

                let contactList = this.state.customer?.contactList;

                if(_.findIndex(contactList, el => el.value === contact) === -1) {
                    contactList.push({label: contact, value: contact})
                }

                this.setState({
                    customer: {
                        ...this.state.customer,
                        contactList
                    },
                    formData: {
                        ...this.state.formData,
                        [fieldName]: contact
                    }
                });

            } else {

                let contacts                                    = this.state.customer?.data?.contacts,
                    { formData:{contactEmail, contactPhone} }   = this.state,
                    idx                                         = _.findIndex(contacts, el => el.i === contact);

                if(idx >= 0 && contacts?.[idx]) {
                    contactEmail = contacts?.[idx]?.e;
                    contactPhone = contacts?.[idx]?.m?.replace?.(/(\d)\s+(?=\d)/g, `$1`);
                }

                this.setState({
                    formData: {
                        ...this.state.formData,
                        [fieldName]: contact,
                        contactEmail,
                        contactPhone,
                    }
                });

            }

        } else {

            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: selectedOption?.value ?? ""
                }
            });

        }

    }

    handlePreviewPagination = offset => {
        this.setState(prevState => ({
            preview: {
                ...prevState.preview,
                pageNumber: ((prevState?.preview?.pageNumber ?? 1) + offset)
            }
        }))
    }

    handlePreviewLoad = ({numPages}) => {
        this.setState({
            preview: {
                ...this.state.preview,
                numPages 
            }
        });
    }
    
    handleSubmitPreview = () => {
        const { formData, id } = this.state;
        const resource = id ? `/rma/${id}` : `/rma`
        this.setState({
            isLoading: true,
            isLoadingPreview: true,
        }, () => {
            API.post(resource, { preview: true, ...formData } )
            .then(result => {                
                if(result.data.errors) {
                    let formErrors = formatValidationErrors(result.data.errors);
                    this.setState({
                        formErrors,
                        formData: {
                            ...formData,
                            customer: (formErrors && formErrors['customer'] && initialState().formData.customer) || formData.customer,
                            customerVatPercent: (formErrors && formErrors['customer'] && initialState().formData.customerVatPercent) || formData.customerVatPercent
                        },
                        currentTab: 2,
                        isLoading: false
                    })
                } else {
                    if(result.data.pdf) {
                        this.setState({
                            formErrors: false,
                            isLoading: false,
                            isLoadingPreview: false,
                            preview: {
                                ...this.state.preview,
                                numPages: null,
                                pageNumber: 1,
                                pdf: result.data.pdf
                            }
                        })
                    }
                }
            })
        })
    }

    handleConfirmation = () => {
        const action = this.state.id ? this.props.forApproval ? "approve" : "update" : "create";
        this.props.deployConfirmation(`Are you sure you want to ${action} this RMA?`, this.handleSubmit)
    }

    handleSubmit = (declined = false) => {

        let { 
            formData, 
            id, 
            initialFormData 
        } = this.state;

        const resource = id ? `/rma/${id}` : `/rma`,
              action = this.state.id ? this.props.forApproval ? (declined ? "declined" : "approved") : "updated" : "created";
              
        this.setState({
            isLoading: true
        }, () => {
            if(action === "updated") {
                formData = {
                    ...formData,
                    initialFormData
                }
            }
            if(action === "placed") {
                formData = {
                    ...formData,
                    initialFormData,
                }
            }
            formData = {
                ...formData,
                declined: declined ? 1 : 0
            }
            API.post(resource, formData)
            .then(result => {                
                if(result.data.errors) {
                    let formErrors = formatValidationErrors(result.data.errors);
                    this.setState({
                        formErrors,
                        formData: {
                            ...formData,
                            customer: (formErrors && formErrors['customer'] && initialState().formData.customer) || formData.customer,
                            customerVatPercent: (formErrors && formErrors['customer'] && initialState().formData.customerVatPercent) || formData.customer
                        },
                        isLoading: false
                    })
                } else {
                    this.setState({ 
                        ...initialState()
                    }, () => {
                        this.props.deploySnackBar("success", `The RMA was ${action} successfully`)
                        this.props.history.push({
                            pathname: `/rma/${result.data.id}`,
                            state: { new: action !== "declined" }
                        })
                    })
                }
            })
        })
    }
    
    handleUnsavedLine = () => {
        this.setState({
            unsavedLine: true
        })
    }

    render() {        

        const { 
            classes, 
            deployConfirmation, 
            forApproval,
            history 
        } = this.props;

        const { 
            alertText,
            currentTab,
            customer,
            disableCustomerTab,
            existingNotes,
            formData,
            formErrors,
            id,
            initialLoading,
            isLoading,
            isLoadingPreview,
            lists,
            preview,
            products,
            ui,
            formData: {
                defaultAction,
                defaultReason,
                defaultReStockingFee
            }
        } = this.state;     

        return (
            <Grid container className={classes.container}>
                {(initialLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Prompt
                            when={JSON.stringify(formData) !== JSON.stringify(this.state.initialFormData)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                        <Grid item className={`${classes.main} p-0`}>
                            {(isLoading && (
                                <>
                                    <LoadingCircle />
                                    {isLoadingPreview && (
                                        <Typography variant="h6" align='center'>
                                            Generating Preview
                                        </Typography>     
                                    )}    
                                </>                       
                            )) || (
                                <>
                                    {alertText && (
                                        <Alert severity="warning" variant="standard" elevation={0} className={classes.alert}>{alertText}</Alert>
                                    )}
                                    <TabPanel value={currentTab} index={0}>
                                        <Box p={3}>
                                            <CustomerSearchSelector
                                                handleAction={this.handleCustomerChange}
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1}>
                                        <Box style={{width: '100%', maxWidth: '100%'}}>
                                            <Box position='fixed' zIndex={999}>
                                                <Product
                                                    defaultAction={defaultAction}
                                                    defaultReason={defaultReason}
                                                    defaultReStockingFee={defaultReStockingFee}
                                                    classes={classes}
                                                    customer={customer?.id}
                                                    customerVatPercent={formData.customerVatPercent}
                                                    productRows={formData.productRows}
                                                    handleSubmit={this.handleProductAdd}
                                                    handleUnsavedLine={this.handleUnsavedLine}
                                                    products={products}
                                                    borderRadius={0}
                                                    width={'calc(100% - 230px)'}
                                                />
                                            </Box>
                                        </Box>
                                        <Box p={3} style={{overflowY: 'auto', height: (parseInt(ui?.height) - (alertText ? 170 : 120))}}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Box height={70} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Defaults
                                                        classes={classes}
                                                        defaultAction={defaultAction}
                                                        defaultReason={defaultReason}
                                                        defaultReStockingFee={defaultReStockingFee}
                                                        forCustomer={customer.id}
                                                        formData={formData}
                                                        handleChange={this.handleChange}
                                                        handleFloat={this.handleFloat}
                                                        handleSelectChange={this.handleSelectChange}
                                                        handleProductAdd={this.handleProductAdd}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {(!_.isEmpty(formData?.productRows) && ( 
                                                        <Products   
                                                            classes={classes}
                                                            customer={customer?.id}
                                                            customerVatPercent={formData.customerVatPercent}
                                                            defaultAction={defaultAction}
                                                            defaultReason={defaultReason}
                                                            defaultReStockingFee={defaultReStockingFee}
                                                            formData={formData}
                                                            formErrors={formErrors}
                                                            handleProductDrag={this.handleProductDrag}
                                                            handleProductEdit={this.handleProductEdit}
                                                            handleProductRemove={this.handleProductRemove}
                                                            products={products}
                                                        />
                                                    )) || (
                                                        <Box pt={5} pb={5}>
                                                            <Typography variant="h6" align='center'>
                                                                No lines have been added to this RMA yet
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        <Typography variant="h6" gutterBottom>
                                                            RMA Notes
                                                        </Typography>
                                                        <Typography variant="caption" color="error" paragraph>
                                                            <FAIcon type="solid" icon="info-circle" size={11} noMargin /> These notes will be visible to the customer
                                                        </Typography>
                                                        <Textarea
                                                            name="notes"
                                                            value={formData.notes}
                                                            onChange={this.handleChange}
                                                            margin="none"
                                                            fullWidth
                                                            variant="outlined"
                                                            rows={5}
                                                        />
                                                    </PaddedPaper>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={2}>
                                        <Box p={3}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Billing   
                                                                contactList={customer.contactList}
                                                                customer={customer}
                                                                formData={formData}
                                                                formErrors={formErrors}
                                                                handleNull={this.handleNull}
                                                                handleChange={this.handleChange}
                                                                handleSelectChange={this.handleSelectChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ContactUpdates
                                                                contacts={customer?.data?.contacts}
                                                                document={document}
                                                                formData={formData}
                                                                formErrors={formErrors}
                                                                handleChange={this.handleChange}
                                                                handleCheckedChange={this.handleCheckedChange}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Notes
                                                        formData={formData}
                                                        existingNotes={existingNotes}
                                                        handleNoteAdd={this.handleNoteAdd}
                                                        handleNoteEdit={this.handleNoteEdit}
                                                        handleNoteRemove={this.handleNoteRemove}
                                                        loggedInStaff={this.props.loggedInStaff}
                                                        staff={this.props.staff}
                                                        teams={lists.teams}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={3}>
                                        <Preview
                                            classes={classes}
                                            height={alertText ? (ui.contentHeight - 49) : ui.contentHeight}
                                            preview={preview}
                                            handlePreviewLoad={this.handlePreviewLoad}
                                            handlePreviewPagination={this.handlePreviewPagination}
                                        />
                                    </TabPanel>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} lg={2} className={`${classes.side} h-100`}>
                            <Grid container className={`${classes.relative} h-100`}>
                                <Grid item xs={12}>
                                    <Tabs
                                        classes={classes}
                                        currentTab={currentTab}
                                        disableCustomerTab={disableCustomerTab}
                                        formData={formData}
                                        handleTabChange={this.handleTabChange}
                                    />
                                </Grid>
                                <Grid item xs={12} className={`${classes.absoluteBottom} ${id ? classes.updateRmaTotals : classes.newRmaTotals} w-100`}>
                                    <Totals  
                                        classes={classes}
                                        customerVatPercent={formData.customerVatPercent}
                                        grossTotal={formData.grossTotal}
                                        netTotal={formData.netTotal}
                                        vatTotal={formData.vatTotal}
                                        inForm
                                    />
                                    {(!isLoading && currentTab === 3 && (
                                        <Button
                                            submit={this.handleConfirmation}
                                            disabled={(_.isEmpty(formData.productRows) || (currentTab !== 3 || isLoading)) && true}
                                            text={id ? forApproval ? 'Approve RMA' : 'Update RMA' : `Create RMA`}
                                        />
                                    )) || (
                                        <Box mt={3}>
                                            <Grid container spacing={3} justify="center">
                                                <Grid item>
                                                    <MuiButton
                                                        color="primary"
                                                        disabled={currentTab === 0 || (currentTab === 1 && id) || isLoading}
                                                        onClick={() => this.handleTabChange((currentTab - 1))}
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="arrow-left" disabled={isLoading} size={15} button />
                                                        Back
                                                    </MuiButton>
                                                </Grid>
                                                <Grid item>
                                                    <MuiButton
                                                        color="primary"
                                                        onClick={() => this.handleTabChange((currentTab + 1))}
                                                        disabled={isLoading || currentTab === 0 || (currentTab === 2 && _.isEmpty(formData.productRows))}
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="arrow-right" disabled={isLoading || (currentTab === 2 && _.isEmpty(formData.productRows))} size={15} button />
                                                        Next
                                                    </MuiButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                    {(id && forApproval && !isLoading && currentTab !== 3 && (
                                        <Box mt={1.25} pl={3} pr={3}>
                                            <MuiButton
                                                fullWidth
                                                color="primary"
                                                onClick={() => deployConfirmation(`Are you sure you want to decline this RMA?`, () => this.handleSubmit(true))}
                                                variant="contained"
                                                className="bg-light-error textDefault"

                                            >
                                                <FAIcon icon="times" size={15} button />
                                                Decline RMA
                                            </MuiButton>
                                        </Box>
                                        )) || (id && (!forApproval || (forApproval && (isLoading || currentTab === 3))) && (
                                        <Box mt={1.25} align='center'>
                                            <MuiButton
                                                color="primary"
                                                onClick={() => deployConfirmation(`Are you sure you want to cancel changes?`, () => history.push(`/rma/${id}`))}
                                                variant="text"
                                            >
                                                <FAIcon icon="times" size={15} button />
                                                Cancel
                                            </MuiButton>
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInStaff: state.staffAuth.staff,
        staff: state.notifications.status,
        teamList: state.notifications.teamList,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success, cancel = false) => dispatch(deployConfirmation(message, success, cancel)), 
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disabled = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disabled)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Form)(withRouter(RMAForm)));