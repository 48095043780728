import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';

import Search from 'Components/Common/Search/Search';

import Button from '@material-ui/core/Button';
import StaffChip from "Components/Common/Chips/StaffChip";
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DiscountTemplateForm from 'Components/Settings/Discounts/DiscountTemplates/DiscountTemplateForm';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import API from 'API';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const initialState = () => ({
    key: uuidv4()
})

const DiscountTemplates = () => {
    
    const [state, setState] = useState(initialState());

    const dispatch = useDispatch(); 

    const staff = useSelector(state => state.notifications.status);

    const handleDeployForm = (id) => {
        dispatch(deployDialog(
            <DiscountTemplateForm id={id} close={() => dispatch(closeDialog())} callback={refreshData} />,
            false,
            `${id ? 'Edit' : 'Add'} Discount Template`,
            "standard",
            "xl",
            true
        ))
    }
    
    const handleDeleteTemplate = (id) => {
        API.put(`/settings/discountTemplates/${id}/delete`)
        .then(res => {
            if(res?.data?.success) {
                refreshData();
                dispatch(deploySnackBar(`success`, `The discount template was successfully deleted`))
            }
        })
    }

    const refreshData = () => {
        setState(state => ({
            ...state,
            key: uuidv4()
        }))
    }

    const {
        key
    } = state;

    return (
        <Search
            innerKey={key}
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/settings/discountTemplates',
                columns: [
                    {
                        heading: 'Name',
                        field: rowData => rowData.nm,
                        dataRef: 'nm',
                        important: true,
                        main: true,
                    },
                    {
                        heading: 'Active',
                        field: rowData => rowData.ac,
                        fieldFormat: 'boolean',
                        dataRef: 'ac',
                        important: true,
                        alignment: 'center',
                        sizeToContent: true
                    },
                    {
                        heading: 'Valid',
                        field: rowData => rowData.vl,
                        fieldFormat: 'boolean',
                        dataRef: 'vl',
                        important: true,
                        alignment: 'center',
                        sizeToContent: true
                    },
                    {
                        heading: 'Apply to all customers',
                        field: rowData => rowData.gl,
                        fieldFormat: 'boolean',
                        dataRef: 'gl',
                        important: true,
                        alignment: 'center',
                        sizeToContent: true
                    },
                    {
                        heading: 'Scheduled',
                        field: rowData => rowData.sc,
                        fieldFormat: 'boolean',
                        dataRef: 'sc',
                        important: true,
                        alignment: 'center',
                        sizeToContent: true
                    },
                    {
                        heading: 'Effective Date',
                        field: rowData => rowData.ef,
                        fieldFormat: 'date',
                        dataRef: 'ef',
                        important: true,
                        alignment: 'center',
                        sizeToContent: true
                    },
                    {
                        heading: 'Expiry Date',
                        field: rowData => rowData.ex,
                        fieldFormat: 'date',
                        dataRef: 'ex',
                        important: true,
                        alignment: 'center',
                        sizeToContent: true
                    },
                    {
                        heading: 'Updated By',
                        field: rowData => <StaffChip staff={staff?.[rowData.ub]} />,
                        fieldFormat: 'staff',
                        dataRef: 'ub',
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Updated',
                        field: rowData => rowData.lu,
                        fieldFormat: 'datetime',
                        dataRef: 'lu',
                        sizeToContent: true
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'Modify', icon: 'pencil-alt', onClick: () => handleDeployForm(rowData.id)},
                                {name: 'Delete', icon: 'trash-alt', onClick: () => dispatch(deployConfirmation(`Are you sure you want to delete this discount template?`, () => handleDeleteTemplate(rowData.id)))},
                            ]
                        }
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'id',
                name: 'discount-template-list',
                noResultsText: 'Sorry, no discount templates were found using your search criteria',
                pagination: true,
                pdf: true,
                persistenceId: null,
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showButton: (
                    <Button 
                        color="primary" 
                        size="small"
                        variant="text"
                        onClick={() => handleDeployForm(null)}
                    >   
                        <FAIcon type="light" icon="plus-circle" size="small" button />
                        Add Discount Template
                    </Button>
                ),
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Discount Templates'
            }}
        />
    )
}

export default DiscountTemplates;