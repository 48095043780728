import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import API from 'API';
import DefaultAvatar from 'Assets/Images/defaultAvatar.png'
import DataTable from 'Components/Common/DataTables/DataTable';
import EditNoteForm from 'Components/Common/Activity/EditNoteForm';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { CLOUDFRONT_URL } from 'Constants';
import { 
    closeDialog,
    deployDialog
 } from 'Redux/Actions/Dialog/Dialog';

const useStyles = makeStyles({
    card: {
        borderLeft: '2px solid #fff',
    },
    cardAttentionFao: {
        background: '#ffebee',
        borderRadius: 0
    },
    cardPriority: {
        borderLeft: '2px solid #ef3340',
    },
    cardContent: {
        padding: '0 16px 0 16px!important'
    },
    slimCardContent: {
        // padding: '0!important'
    },
    slimCardHeader: {
        // padding: '4px 0 12px 0!important',
        fontSize: '1px!important'
    }
});

const initialState = {
    access: {
        editNote: false,
        editPriorityNote: false,
        deleteNotes: false
    },
    edited: {},
    deleted: []
}

const ViewNotes = ({button, heading, highlightAfter, highlightAfterText, inline, hideDeleteButton, noPriorityStyles, notes, slim}) => {

    const [state, setState] = useState(initialState);
    const classes           = useStyles();
    const dispatch          = useDispatch();
    const userTeams         = useSelector(state => state.staffAuth?.staff?.teams);
    const staff             = useSelector(state => state.notifications?.status);
    const staffId           = useSelector(state => state.staffAuth?.staff?.id);

    const handleDeployEditNote = note => {

        let v = note.v;

        if(edited?.[note.i]) {
            v = edited[note.i];
        }

        note.v = v;

        dispatch(deployDialog(
            <EditNoteForm
                close={() => dispatch(closeDialog())}
                note={note}
                submit={handleEditNote}
            />, true, "", "standard", "sm", false, true
        ))

    }

    const handleEditNote = (id, value)  => {

        API.put(`/misc/notes/${id}/update`, {
            value
        })
        .then(res => {
            if(res?.data) {

                let edited = state.edited;

                edited = {
                    ...edited,
                    [id]: value
                }

                setState(state => ({
                    ...state,
                    edited
                }))

                dispatch(deploySnackBar("success", "The note was successfully updated"));
                dispatch(closeDialog());

            }
        })

    }

    const handleDeleteNote = id => {

        API.put(`/misc/notes/${id}/delete`)
        .then(res => {
            if(res?.data) {

                let deleted = [...state.deleted];

                deleted.push(id)

                setState(state => ({
                    ...state,
                    deleted
                }))

                dispatch(deploySnackBar("success", "The note was successfully deleted"));

            }
        })

    }

    useEffect(() => {
        API.multiAccess([
            'edit-note',
            'edit-p-note',
            'delete-notes'
        ])
        .then(([
            editNote,
            editPriorityNote,
            deleteNotes
        ]) => {
            setState(state => ({
                ...state,
                access: {
                    editNote,
                    editPriorityNote,
                    deleteNotes
                }
            }))
        })

    }, []);

    const { 
        access: {
            editNote,
            editPriorityNote,
            deleteNotes
        }, 
        edited,
        deleted 
    } = state;

    const hasPriority = !_.isEmpty(_.filter(notes, _el => _el.t === 'Important' && !deleted.includes(_el.i)));
    return (
        (_.isEmpty(_.filter(notes, el => !deleted.includes(el.i) && el.t !== 'Deleted')) && (
            <Typography variant="h5" className="fw-400" align='center'>
                There are no notes to show
            </Typography>
        )) || (
            ((inline || button) && (
                <Grid container spacing={!inline ? 3 : undefined}>
                    {_.map(_.orderBy(_.filter(notes, _el => _el.t !== 'Deleted' && _el.t !== 'Legacy' && !deleted.includes(_el.i)), el => el.t), (note, idx) => (
                        <Grid item xs={12} key={idx}>                                
                            <Card 
                                classes={{root: `${(note.t === 'Important' && !noPriorityStyles && classes.cardPriority) || classes.card} ${note.for_team ? (userTeams.includes(note.for_team?.team_name ?? 0) ? classes.cardAttentionFao : '') : ''}`}} 
                                elevation={inline && 0}
                            >
                                <Box p={1.5} pb={1}>
                                    <CardHeader
                                        action={
                                            <>
                                                {!hideDeleteButton && ((note.t === "Important" && editPriorityNote) || (note.t !== "Important" && editNote)) && (
                                                    <Tooltip title="Edit Note">
                                                        <IconButton onClick={() => handleDeployEditNote(note)}>
                                                            <FAIcon icon="pencil-alt" size={15} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {!hideDeleteButton && (deleteNotes || (staffId === note.s && moment(note.c, "YYYY-MM-DD HH:mm:ss").isAfter(moment().subtract(30, 'minutes').toDate().getTime()))) && (
                                                    <Tooltip title="Delete Note">
                                                        <IconButton onClick={() => dispatch(deployConfirmation("Are you sure you want to delete this note?", () => handleDeleteNote(note.i)))}>
                                                            <FAIcon icon="trash-alt" size={15} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </>
                                        }
                                        avatar={
                                            <Avatar 
                                                alt={staff?.[note.s]?.name}
                                                src={staff?.[note.s]?.picture ? `${CLOUDFRONT_URL}${staff?.[note.s]?.picture}` : DefaultAvatar}
                                                style={{width: 35, height: 35}}
                                            >
                                                {staff?.[note.s]?.name?.charAt?.(0) ?? 'A'}
                                            </Avatar>
                                        }    
                                        className={slim ? classes.slimCardHeader : undefined}
                                        subheader={undefined}
                                        title={(
                                            <>
                                                {`${staff?.[note.s]?.name ?? 'Unknown'} · `}
                                                {note.t === 'Important' && (
                                                    <>
                                                        <Typography variant="caption" className="textError">
                                                            Priority Note
                                                        </Typography>
                                                        {` · `}
                                                    </>
                                                )}
                                                <Typography variant="caption" color="textSecondary">
                                                    {moment(note.c, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")} ({moment(note.c, "YYYY-MM-DD HH:mm:ss").fromNow()})
                                                </Typography>
                                            </>
                                        )}
                                    />
                                    <CardContent className={slim ? classes.slimCardContent : classes.cardContent}>
                                        {highlightAfter && moment(note.c).isAfter(moment(highlightAfter)) ? (
                                            <Box py={0.5}>
                                                <Typography variant="body2" color="error">
                                                    <strong>{highlightAfterText}</strong>
                                                </Typography>
                                            </Box>
                                        ) : null}
                                        {(note.sop || note.po) ? (
                                            <Typography variant="body2" color="error" className="fw-400" gutterBottom>
                                                This note was added to {note.sop ?? note.po}
                                            </Typography>
                                        ) : null}
                                        {note.for_team && (
                                            <Typography variant="body2" className="fw-400" gutterBottom>
                                                {`FAO: ${note.for_team.team_name} Team`}
                                            </Typography>
                                        )}
                                        {(edited?.[note.i] ?? note?.v)?.split?.('\n').map((item, key) => (
                                            <Typography 
                                                key={key} 
                                                variant="caption"
                                                component="div" 
                                                paragraph
                                            >
                                                {item}
                                            </Typography>
                                        ))}
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                    {!_.isEmpty(_.filter(notes, _el => _el.t === 'Legacy' && !deleted.includes(_el.i))) && (
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                                    <Typography variant="h6" component="div">
                                        <FAIcon icon="exclamation-circle" className="textInfo mr-1" size={20} button />
                                        Imported Notes ({_.size(_.filter(notes, _el => _el.t === 'Legacy' && !deleted.includes(_el.i)))})
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Box>
                                        <Grid container spacing={3}>
                                            {_.map(_.filter(notes, _el => _el.t === 'Legacy' && !deleted.includes(_el.i)), (note, idx) => (
                                                <Grid item xs={12} key={idx}>                                
                                                    <Card 
                                                        classes={{root: `${(note.t === 'Important' && !noPriorityStyles && classes.cardPriority) || classes.card} ${note.for_team ? (userTeams.includes(note.for_team?.team_name ?? 0) ? classes.cardAttentionFao : '') : ''}`}} 
                                                        elevation={0}
                                                    >
                                                        <Box>
                                                            <CardHeader
                                                                action={
                                                                    <>
                                                                        {!hideDeleteButton && ((note.t === "Important" && editPriorityNote) || (note.t !== "Important" && editNote)) && (
                                                                            <Tooltip title="Edit Note">
                                                                                <IconButton onClick={() => handleDeployEditNote(note)}>
                                                                                    <FAIcon icon="pencil-alt" size={15} button noMargin />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                        {!hideDeleteButton && (deleteNotes || (staffId === note.s && moment(note.c, "YYYY-MM-DD HH:mm:ss").isAfter(moment().subtract(30, 'minutes').toDate().getTime()))) && (
                                                                            <Tooltip title="Delete Note">
                                                                                <IconButton onClick={() => dispatch(deployConfirmation("Are you sure you want to delete this note?", () => handleDeleteNote(note.i)))}>
                                                                                    <FAIcon icon="trash-alt" size={15} button noMargin />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </>
                                                                }
                                                                avatar={
                                                                    <Avatar 
                                                                        alt={staff?.[note.s]?.name}
                                                                        src={staff?.[note.s]?.picture ? `${CLOUDFRONT_URL}${staff?.[note.s]?.picture}` : DefaultAvatar}
                                                                        style={{width: 35, height: 35}}
                                                                    >
                                                                        {staff?.[note.s]?.name?.charAt?.(0) ?? 'A'}
                                                                    </Avatar>
                                                                }    
                                                                className={slim ? classes.slimCardHeader : undefined}
                                                                subheader={undefined}
                                                                title={(
                                                                    <>
                                                                        {`${staff?.[note.s]?.name ?? 'Unknown'} ${note.for_team ? `- FAO: ${note.for_team.team_name} Team` : ''} · `}
                                                                        {note.t === 'Important' && (
                                                                            <>
                                                                                <Typography variant="caption" className="textError">
                                                                                    Priority Note
                                                                                </Typography>
                                                                                {` · `}
                                                                            </>
                                                                        )}
                                                                        <Typography variant="caption" color="textSecondary">
                                                                            {moment(note.c, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY HH:mm')}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            />
                                                            <CardContent className={slim ? classes.slimCardContent : classes.cardContent}>
                                                                {(edited?.[note.i] ?? note?.v)?.split?.('\n').map((item, key) => (
                                                                    <Typography 
                                                                        key={key} 
                                                                        variant="caption"
                                                                        component="div" 
                                                                        paragraph
                                                                    >
                                                                        {item}
                                                                    </Typography>
                                                                ))}
                                                            </CardContent>
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    )}
                    {button && (
                        <Grid item xs={12} align='right'>
                            {button}
                        </Grid>
                    )}
                </Grid>
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={hasPriority ? 8 : 12}>
                        {hasPriority && (
                            <Typography variant="h6" paragraph>
                                Notes
                            </Typography>
                        )}
                        <Paper>
                            <DataTable
                                config={{
                                    key: "i",
                                    pagination: true,
                                    alternatingRowColours: true,
                                    responsiveImportance: true,
                                    plainPagination: true,
                                    options: {
                                        headingInput: heading ?? null,
                                        dataRef: true,
                                        export: {
                                            excel: true,
                                            name: `Notes`,
                                            pdf: true,
                                            print: true,
                                            title: `Notes`,
                                        },
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Date / Time',
                                        field: note => note.c,
                                        dataRef: 'c',
                                        fieldFormat: 'datetime',
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Note',
                                        field: note => (
                                            <>
                                                {highlightAfter && moment(note.c).isAfter(moment(highlightAfter)) ? (
                                                    <Box py={0.5}>
                                                        <Typography variant="body2" color="error">
                                                            <strong>{highlightAfterText}</strong>
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                                {(note.sop || note.po) ? (
                                                    <Typography variant="body2" color="error" className="fw-400" gutterBottom>
                                                        This note was added to {note.sop ?? note.po}
                                                    </Typography>
                                                ) : null}
                                                {note.for_team && (
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        {`FAO: ${note.for_team.team_name} Team`}
                                                    </Typography>
                                                )}
                                                {(edited?.[note.i] ?? note?.v)?.split?.('\n').map((item, key) => (
                                                    <Typography 
                                                        key={key} 
                                                        variant="caption"
                                                        component="div" 
                                                        paragraph={key !== (_.size((edited?.[note.i] ?? note?.v)?.split?.('\n')) - 1)}
                                                    >
                                                        {item}
                                                    </Typography>
                                                ))}
                                            </>
                                        ),
                                        cellProps: () => ({
                                            style: {
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                overflow: 'hidden',
                                            }
                                        }),
                                        important: true,
                                        dataRef: 'v'
                                    },
                                    {
                                        heading: 'Created By',
                                        field: note => <StaffChip staff={staff?.[note.s] ?? null} />,
                                        fieldFormat: 'staff',
                                        dataRef: 's',
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                    actions: (rowData) => {
                                        return [
                                            {
                                                name: "Edit Note",
                                                icon: "pencil-alt",
                                                onClick: () => handleDeployEditNote(rowData),
                                                disabled: hideDeleteButton || ((rowData.t === "Important" && !editPriorityNote) || (rowData.t !== "Important" && !editNote)),
                                                hideIfDisabled: hideDeleteButton
                                            },
                                            {
                                                name: "Delete Note",
                                                icon: "trash",
                                                onClick: () => dispatch(deployConfirmation("Are you sure you want to delete this note?", () => handleDeleteNote(rowData.i))),
                                                disabled: hideDeleteButton || (!deleteNotes && (staffId !== rowData.s || moment(rowData.c, "YYYY-MM-DD HH:mm:ss").isBefore(moment().subtract(30, 'minutes').toDate().getTime()))),
                                                hideIfDisabled: hideDeleteButton
                                            },
                                        ];
                                    }
                                    },
                                ]}
                                rows={_.filter(notes, _el => _el.t !== 'Important' && !deleted.includes(_el.i))}
                            />
                        </Paper>
                    </Grid>
                    {hasPriority && (
                        <Grid item xs={12} lg={4}>
                            <Typography variant="h6" paragraph>
                                Priority Notes
                            </Typography>
                            <Grid container spacing={3}>
                                {_.map(_.orderBy(_.filter(notes, _el => _el.t === 'Important' && !deleted.includes(_el.i)), el => el.t), (note, idx) => (
                                    <Grid item xs={12} key={idx}>                                
                                        <Card 
                                            classes={{root: `${(note.t === 'Important' && !noPriorityStyles && classes.cardPriority) || classes.card} ${note.for_team ? (userTeams.includes(note.for_team?.team_name ?? 0) ? classes.cardAttentionFao : '') : ''}`}} 
                                            elevation={inline && 0}
                                        >
                                            <Box p={1.5} pb={1}>
                                                <CardHeader
                                                    action={
                                                        <>
                                                            {!hideDeleteButton && ((note.t === "Important" && editPriorityNote) || (note.t !== "Important" && editNote)) && (
                                                                <Tooltip title="Edit Note">
                                                                    <IconButton onClick={() => handleDeployEditNote(note)}>
                                                                        <FAIcon icon="pencil-alt" size={15} button noMargin />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            {!hideDeleteButton && (deleteNotes || (staffId === note.s && moment(note.c, "YYYY-MM-DD HH:mm:ss").isAfter(moment().subtract(30, 'minutes').toDate().getTime()))) && (
                                                                <Tooltip title="Delete Note">
                                                                    <IconButton onClick={() => dispatch(deployConfirmation("Are you sure you want to delete this note?", () => handleDeleteNote(note.i)))}>
                                                                        <FAIcon icon="trash-alt" size={15} button noMargin />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    }
                                                    avatar={
                                                        <Avatar 
                                                            alt={staff?.[note.s]?.name}
                                                            src={staff?.[note.s]?.picture ? `${CLOUDFRONT_URL}${staff?.[note.s]?.picture}` : DefaultAvatar}
                                                            style={{width: 35, height: 35}}
                                                        >
                                                            {staff?.[note.s]?.name?.charAt?.(0) ?? 'A'}
                                                        </Avatar>
                                                    }    
                                                    className={slim ? classes.slimCardHeader : undefined}
                                                    subheader={undefined}
                                                    title={(
                                                        <>
                                                            {`${staff?.[note.s]?.name ?? 'Unknown'} ${note.for_team ? `- FAO: ${note.for_team.team_name} Team` : ''} · `}
                                                            {note.t === 'Important' && (
                                                                <>
                                                                    <Typography variant="caption" className="textError">
                                                                        Priority Note
                                                                    </Typography>
                                                                    {` · `}
                                                                </>
                                                            )}
                                                            <Typography variant="caption" color="textSecondary">
                                                                {moment(note.c, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")} ({moment(note.c, "YYYY-MM-DD HH:mm:ss").fromNow()})
                                                            </Typography>
                                                        </>
                                                    )}
                                                />
                                                <CardContent className={slim ? classes.slimCardContent : classes.cardContent}>
                                                    {(edited?.[note.i] ?? note?.v)?.split?.('\n').map((item, key) => (
                                                        <Typography 
                                                            key={key} 
                                                            variant="caption"
                                                            component="div" 
                                                            paragraph
                                                        >
                                                            {item}
                                                        </Typography>
                                                    ))}
                                                </CardContent>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )
        )
    )
}

export default ViewNotes;