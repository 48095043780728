import _, { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import { Typography } from '@material-ui/core';
import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { useDispatch } from 'react-redux';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const ProductDiscountExclusions = ({scrollToTop}) => {

    const dispatch = useDispatch();
    
    const [formData, setFormData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [showAffected, setShowAffected] = useState(false);

    const [lastModified, setLastModified] = useState(null);

    const [lists, setLists] = useState({
        brands: [],
        categories: [],
        ranges: [],
        suppliers: []
    });

    const getCategoryExclusions = () => {
        return (
            <Grid container spacing={2}>
                {_.map(lists.categories, (el, idx) => {
                    const exclusions = _.filter(formData, e => e.supplier === '' && (_.isEmpty(e.categories) || _.find(e.categories, c => c?.value === el?.value)));
                    const isExcluded = !_.isEmpty(exclusions);
                    return (
                        <Grid item xs={12} key={idx}>
                            <PaddedPaper>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" className={isExcluded ? `textError` : `textDefault`}>
                                            {el.label}{isExcluded ? ` (#${exclusions?.[0]?.id})` : ``}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            {_.map(el.options, (_el, cIdx) => {
                                                const exclusions = _.filter(formData, e => e.supplier === '' && (_.isEmpty(e.categories) || _.find(e.categories, c => c?.value === el?.value ||  c?.value === _el?.value)));
                                                const isExcluded = !_.isEmpty(exclusions);
                                                return (
                                                    <Grid item xs={12} md={4} lg={3} xl={2} key={`cat-${idx}-sub-${cIdx}`}>
                                                        <Typography variant="body2" className={isExcluded ? `textError` : `textDefault`}>
                                                            <FAIcon icon="arrow-right" size={12.5} />
                                                            {_el.label}{isExcluded ? ` (#${exclusions?.[0]?.id})` : ``}
                                                        </Typography>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    );
                })}
            </Grid>
        )
    }

    const getSupplierExclusions = () => {
        return (
            <Grid container spacing={2}>
                {(!_.isEmpty(_.filter(lists.suppliers, e => _.find(formData, _e => _e?.supplier?.value === e?.value))) && (
                    _.map(_.filter(lists.suppliers, e => _.find(formData, _e => _e?.supplier?.value === e?.value)), (el, idx) => {

                        const categoryOptions = getCategoryOptions(el.value);
                        const brandOptions = el.brands;
                        const rangeOptions = el.ranges;

                        const global = _.filter(formData, e => e.supplier?.value === el.value && ((_.isEmpty(e.categories) && _.isEmpty(e.brands) && isEmpty(e.ranges))));
                        const categories = _.filter(formData, e => e.supplier?.value === el.value && !_.isEmpty(e.categories) && _.isEmpty(e.brands) && _.isEmpty(e.ranges));
                        const brands = _.filter(formData, e => e.supplier?.value === el.value && !_.isEmpty(e.brands) && _.isEmpty(e.categories) && _.isEmpty(e.ranges));
                        const ranges = _.filter(formData, e => e.supplier?.value === el.value && !_.isEmpty(e.ranges) && _.isEmpty(e.categories) && _.isEmpty(e.brands));
                        const combinations = _.filter(formData, e => e.supplier?.value === el.value && ((!_.isEmpty(e.categories) && (!_.isEmpty(e.brands) || !_.isEmpty(e.ranges))) || (!_.isEmpty(e.brands) && (!_.isEmpty(e.categories) || !_.isEmpty(e.ranges))) || (!_.isEmpty(e.ranges) && (!_.isEmpty(e.brands) || !_.isEmpty(e.categories)))));

                        const hasBrands = !_.isEmpty(brandOptions);
                        const hasCategories = !_.isEmpty(categoryOptions);
                        const hasCombinations = !_.isEmpty(combinations);
                        const hasRanges = !_.isEmpty(rangeOptions);

                        const isGlobalExcluded = !_.isEmpty(global);
                        return (
                            <Grid item xs={12} key={idx}>
                                <PaddedPaper>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" className={isGlobalExcluded ? `textError` : `textDefault`}>
                                                {el.label}{isGlobalExcluded ? ` (#${global?.[0]?.id})` : ``}
                                            </Typography>
                                        </Grid>
                                        {hasBrands && (
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom className={`fw-400 ${isGlobalExcluded ? `textError` : `textDefault`}`}>
                                                    Brands
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    {_.map(brandOptions, (e, bIdx) => {
                                                        const exclusions = isGlobalExcluded ? global : _.filter(brands, _e => _.find(_e.brands, b => b?.value === e?.value));
                                                        const isExcluded = isGlobalExcluded || !_.isEmpty(exclusions);
                                                        return (
                                                            <Grid item xs={12} md={4} lg={3} xl={2} key={`brand-${bIdx}`}>
                                                                <Typography variant="body2" className={isExcluded ? `textError` : `textDefault`}>
                                                                    <FAIcon icon="arrow-right" size={12.5} />
                                                                    {e.label}{isExcluded ? ` (#${exclusions?.[0]?.id})` : ``}
                                                                </Typography>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {hasRanges && (
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom className={`fw-400 ${isGlobalExcluded ? `textError` : `textDefault`}`}>
                                                    Ranges
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    {_.map(rangeOptions, (e, rIdx) => {
                                                        const exclusions = isGlobalExcluded ? global : _.filter(ranges, _e => _.find(_e.ranges, r => r?.value === e?.value));
                                                        const isExcluded = isGlobalExcluded || !_.isEmpty(exclusions);
                                                        return (
                                                            <Grid item xs={12} md={4} lg={3} xl={2} key={`range-${rIdx}`}>
                                                                <Typography variant="body2" className={isExcluded ? `textError` : `textDefault`}>
                                                                    <FAIcon icon="arrow-right" size={12.5} />
                                                                    {e.label}{isExcluded ? ` (#${exclusions?.[0]?.id})` : ``}
                                                                </Typography>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>                                    
                                        )}
                                        {hasCategories && (
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom className={`fw-400 ${isGlobalExcluded ? `textError` : `textDefault`}`}>
                                                    Categories
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    {_.map(categoryOptions, (e, bIdx) => {
                                                        const exclusions = isGlobalExcluded ? global : _.filter(categories, _e => _.find(_e.categories, c => c?.value === e?.value));
                                                        const isExcluded = isGlobalExcluded || !_.isEmpty(exclusions);
                                                        return (
                                                            <Grid item xs={12} key={bIdx}>
                                                                <Typography variant="body1" gutterBottom className={isExcluded ? `textError` : `textDefault`}>
                                                                    {e.label}{isExcluded ? ` (#${exclusions?.[0]?.id})` : ``}
                                                                </Typography>
                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={1}>
                                                                        {_.map(e.options, (_el, cIdx) => {
                                                                            const exclusions = isGlobalExcluded ? global : _.filter(categories, _e => _.find(_e.categories, c => c?.value === e?.value || c?.value === _el?.value));
                                                                            const isExcluded = isGlobalExcluded || !_.isEmpty(exclusions);
                                                                            return (
                                                                                <Grid item xs={12} md={4} lg={3} xl={2} key={`cat-${cIdx}`}>
                                                                                    <Typography variant="caption" className={`${isExcluded ? `textError` : `textDefault`}`}>
                                                                                        <FAIcon icon="arrow-right" size={12.5} />
                                                                                        {_el.label}{isExcluded ? ` (#${exclusions?.[0]?.id})` : ``}
                                                                                </Typography>
                                                                                </Grid>
                                                                            )
                                                                        })}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {hasCombinations && ( 
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom className={`fw-400 ${isGlobalExcluded ? `textError` : `textDefault`}`}>
                                                    Combined Rules
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    {_.map(combinations, (e, cIdx) => (
                                                        <Grid item xs={12} key={`combination-${cIdx}`}>
                                                            <Typography variant="body2" gutterBottom className={`textError`}>
                                                                <FAIcon icon="arrow-right" size={12.5} />
                                                                Any of the following product combinations (#{e.id})
                                                            </Typography>
                                                            <Typography variant="caption" component="div" className={`ml-2 textError`}>
                                                                {!_.isEmpty(e.brands) ? <>&bull; {_.size(e.brands) > 1 ? `Brands` : `Brand`}: {_.map(e.brands, (el, idx) => <span>{el?.label}{_.size(e.brands) > 1 && idx !== (_.size(e.brands) - 1) ? `, ` : ``}</span>)}</> : ``}
                                                            </Typography>
                                                            <Typography variant="caption" component="div" className={`ml-2 textError`}>
                                                                {!_.isEmpty(e.ranges) ? <>&bull; {_.size(e.ranges) > 1 ? `Ranges` : `Range`}: {_.map(e.ranges, (el, idx) => <span>{el?.label}{_.size(e.ranges) > 1 && idx !== (_.size(e.ranges) - 1) ? `, ` : ``}</span>)}</> : ``}
                                                            </Typography>
                                                            <Typography variant="caption" component="div" className={`ml-2 textError`}>
                                                                {!_.isEmpty(e.categories) ? <>&bull; {_.size(e.categories) > 1 ? `Categories ` : `Category`}: {_.map(e.categories, (el, idx) => <span>{el?.label}{_.size(e.categories) > 1 && idx !== (_.size(e.categories) - 1) ? `, ` : ``}</span>)}</> : ``}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>   
                                        )}
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        );
                    })
                )) || (
                    <Grid item xs={12}>
                        <Alert severity="success">
                            No supplier specific product discount exclusion rules have been added yet
                        </Alert>
                    </Grid>
                )}
            </Grid>
        )
    }

    const getCategoryOptions = (exSupplier) => {
        if(exSupplier === '') return lists.categories;
        const cat = [];
        const id = exSupplier?.value ?? exSupplier;
        const supplier = _.find(lists.suppliers, s => s.value === id);
        if(supplier) {
            _.forEach(_.filter(lists.categories, _c => supplier.cat.includes(_c.value)), c => {
                let o = _.filter(c.options, _o => supplier.cat.includes(_o.value));
                if (o.length > 0) {
                    cat.push({
                        label: c.label,
                        value: c.value,
                        options: o,
                    })
                }
            })
        }
        return cat;
    }

    const getBrandOptions = (exSupplier) => {
        if(exSupplier === '') return [];
        const brand = [];
        let b = _.find(lists.brands, el => el.id === exSupplier?.value ?? exSupplier);
        if(b) {
            brand.push(b);
        }
        return brand;
    }

    const getRangeOptions = (exSupplier) => {
        if(exSupplier === '') return [];
        const range = [];
        let c = _.find(lists.ranges, el => el.id === exSupplier?.value ?? exSupplier);
        if(c) {
            range.push(c);
        }
        return range;
    }

    const handleAddRule = () => {
        setFormData([...formData, { id: ((formData?.length ?? 0) + 1), supplier: '', categories: [], brands: [], ranges: [], saved: false}]);
    }

    const handleChangeRule = (index, field, value) => {
        let newFormData = [...formData];
        if(field === 'supplier' || field === 'saved') {
            if(value) {
                newFormData[index][field] = {
                    label: value.label,
                    value: value.value
                };
            } else {
                newFormData[index][field] = '';
            }
            if(field === 'supplier') {
                newFormData[index].brands = [];
                newFormData[index].categories = [];
                newFormData[index].ranges = [];
            }
        } else {
            newFormData[index][field] = value?.map?.(item => item) ?? [];
        }
        setFormData(newFormData);
    }

    const handleRemoveRule = (index) => {
        let newFormData = [...formData];
        newFormData.splice(index, 1);
        newFormData.forEach((e, i) => e.id = i + 1);
        setFormData(newFormData);
    }

    const handleConfirmChanges = () => {
        dispatch(deployConfirmation(`Are you sure you want to save these changes?`, handleSaveChanges))
    }

    const handleLoadData = () => {
        API.get('/settings/productDiscountExclusions')
        .then(res => {
            if(res?.data?.success) {
                setLastModified({
                    created: res?.data?.exclusion?.c,
                    staff: res?.data?.exclusion?.s,
                });
                setFormData(res?.data?.exclusion?.f ?? []);
            }
            setIsLoading(false);
        })
    }

    const handleSaveChanges = () => {
        API.put('/settings/productDiscountExclusions', { formData })
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `Your changes have been saved`));
                handleLoadData();
            }
        })
    }
    
    const handleToggleAffected = () => {
        setShowAffected(!showAffected);
    }

    useEffect(() => {
        Promise.all([
            API.get('/suppliers/all', { params: { active: true, forType: 2, withBrands: true, withCategories: true, withRanges: true } }),
            API.get('/smartSearch/categories', { params: { type: 'select' } })
        ])
        .then(([
            suppliers,
            categories
        ]) => {

            let lists = {
                brands: [],
                categories: [],
                ranges: [],
                suppliers: []
            }

            if(categories?.data) {
                lists.categories = categories.data;
                lists.categories.forEach(category => {
                    category.options.unshift({
                        label: `All ${category.label}`,
                        value: category.value,
                        isParent: true
                    })   
                })
            }

            if(suppliers?.data) {

                _.each(suppliers.data, supp => {
                    if(!_.isEmpty(supp.brands)) {
                        lists.brands.push({
                            id: supp.i,
                            label: supp.an,
                            options: _.map(supp.brands, brand => _.assign({
                                label: brand.brand_name,
                                value: brand.brand_id,
                                supplier: supp.i
                            }))             
                        })
                    }
                    
                    if(!_.isEmpty(supp.ranges)) {
                        lists.ranges.push({
                            id: supp.i,
                            label: supp.an,
                            options: _.map(supp.ranges, range => _.assign({
                                label: range.range_name,
                                value: range.range_id,
                                supplier: supp.i
                            }))             
                        })
                    }
                });

                lists.suppliers = _.map(suppliers.data, el => (
                    _.assign({
                        label: el.an,
                        value: el.i,
                        cat: el.cat,
                        brands: _.map(el.brands, brand => _.assign({
                            label: brand.brand_name,
                            value: brand.brand_id,
                        })),
                        ranges: _.map(el.ranges, range => _.assign({
                            label: range.range_name,
                            value: range.range_id,
                        })) 
                    })
                ));

            }

            setLists(lists);
            handleLoadData();

        });

    }, [])

    const isFormDisabled = _.some(formData, el => !el.saved);

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={1} alignItems='center'>
                {(!showAffected && (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justify="space-between" alignItems='center'>
                                <Grid item xs>
                                    {lastModified && (
                                        <Typography variant='body2' className="fw-400">
                                            Last modified: {lastModified.created} by {lastModified.staff}
                                        </Typography>  
                                    )}
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={2} justify="flex-end">
                                        <Grid item>
                                            <Button
                                                variant="text"
                                                onClick={handleAddRule}
                                            >
                                                <FAIcon icon="plus" size={15} button />
                                                Additional Rule
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="text"
                                                onClick={handleToggleAffected}
                                            >
                                                <FAIcon icon="binoculars" size={15} button />
                                                Show Impact
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                disabled={isFormDisabled}
                                                variant="contained"
                                                onClick={handleConfirmChanges}
                                            >
                                                <FAIcon disabled={isFormDisabled} icon="check" size={15} buttonPrimary/>
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={1}>
                                {(_.isEmpty(formData) && (
                                    <Paper elevation={0}>
                                        <Alert severity="success">
                                            No product discount exclusion rules have been added yet
                                        </Alert>
                                    </Paper>
                                )) || (
                                    <Grid container spacing={3}>
                                        {_.map(formData, (ex, idx) => (
                                            <Grid item xs={12} key={idx}>
                                                <Paper>
                                                    <Box p={3}>
                                                        <Grid container spacing={3} alignItems='flex-start'>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs>
                                                                        {(!ex.saved && (
                                                                            <Box mt={1}>
                                                                                <Typography variant="h6" paragraph>
                                                                                    Configure Rule #{ex.id || idx + 1}
                                                                                </Typography>
                                                                                <Grid container spacing={3} alignItems='center'>
                                                                                    <Grid item xs={12}>
                                                                                        <AutoCompleteSelect 
                                                                                            label='Supplier'
                                                                                            options={lists.suppliers}
                                                                                            onChange={v => handleChangeRule(idx, 'supplier', v ?? '')}
                                                                                            value={ex.supplier}
                                                                                            isGrouped
                                                                                            placeholder="All Suppliers"
                                                                                            shrink
                                                                                            variant="outlined"
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <AutoCompleteMultiSelect
                                                                                            label='Category'
                                                                                            options={getCategoryOptions(ex.supplier)}
                                                                                            onChange={v => handleChangeRule(idx, 'categories', v ?? '')}
                                                                                            value={ex.categories}
                                                                                            isGrouped
                                                                                            shrink
                                                                                            placeholder="All Categories"
                                                                                            variant="outlined"
                                                                                            cleanValue
                                                                                            noOptionsMessage={() =>
                                                                                                !ex.supplier
                                                                                                    ? "Please select a supplier to view available product categories..."
                                                                                                    : "The selected supplier does not have any product categories available"
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <AutoCompleteMultiSelect 
                                                                                            label='Brands'
                                                                                            options={getBrandOptions(ex.supplier)}
                                                                                            onChange={v => handleChangeRule(idx, 'brands', v ?? '')}
                                                                                            value={ex.brands}
                                                                                            isGrouped
                                                                                            shrink
                                                                                            placeholder="All Brands"
                                                                                            variant="outlined"
                                                                                            cleanValue
                                                                                            noOptionsMessage={() =>
                                                                                                !ex.supplier
                                                                                                    ? "Please select a supplier to view available product brands..."
                                                                                                    : "The selected supplier does not have any product brands available"
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <AutoCompleteMultiSelect 
                                                                                            label='Ranges'
                                                                                            options={getRangeOptions(ex.supplier)}
                                                                                            onChange={v => handleChangeRule(idx, 'ranges', v ?? '')}
                                                                                            value={ex.ranges}
                                                                                            isGrouped
                                                                                            shrink
                                                                                            placeholder="All Ranges"
                                                                                            variant="outlined"
                                                                                            cleanValue
                                                                                            noOptionsMessage={() =>
                                                                                                !ex.supplier
                                                                                                    ? "Please select a supplier to view available product ranges..."
                                                                                                    : "The selected supplier does not have any product ranges available"
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        )) || (
                                                                            <>
                                                                                <Typography variant="body2" className="fw-400 pb-1">
                                                                                    Rule #{ex.id || idx + 1}
                                                                                </Typography>
                                                                                <Typography variant="body2">
                                                                                    &bull; Products for <strong>{ex?.supplier?.label ?? 'all suppliers'}</strong>
                                                                                    {!_.isEmpty(ex.categories) ? <>{` for the ${_.size(ex.categories) > 1 ? `categories ` : `category `}`}{_.map(ex.categories, (el, idx) => <span><strong>{el?.label}</strong>{_.size(ex.categories) > 1 && idx !== (_.size(ex.categories) - 1) ? `, ` : ``}</span>)}</> : ``}
                                                                                    {!_.isEmpty(ex.brands) ? <>{` for the ${_.size(ex.brands) > 1 ? `brands ` : `brand `}`}{_.map(ex.brands, (el, idx) => <span><strong>{el?.label}</strong>{_.size(ex.brands) > 1 && idx !== (_.size(ex.brands) - 1) ? `, ` : ``}</span>)}</> : ``}
                                                                                    {!_.isEmpty(ex.ranges) ? <>{` for the ${_.size(ex.ranges) > 1 ? `ranges ` : `range `}`}{_.map(ex.ranges, (el, idx) => <span><strong>{el?.label}</strong>{_.size(ex.ranges) > 1 && idx !== (_.size(ex.ranges) - 1) ? `, ` : ``}</span>)}</> : ``}
                                                                                    {' '}will be set to non-discountable by default
                                                                                </Typography>
                                                                                {ex?.supplier === '' && _.isEmpty(ex.categories) && _.isEmpty(ex.brands) && _.isEmpty(ex.ranges) && (
                                                                                    <Typography variant="body2" className="fw-400 textError">
                                                                                        <strong>Warning:</strong> This rule will apply to all products, you probably don't want this! 👀 
                                                                                    </Typography>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item style={{maxWidth: !ex.saved ? 70 : 100}}>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={12} lg={ex.saved ? 6 : undefined}>
                                                                                <Tooltip title={ex.saved ? `Edit Rule` : `Save Rule`}>
                                                                                    <IconButton
                                                                                        onClick={() => handleChangeRule(idx, 'saved', !ex.saved)}
                                                                                    >
                                                                                        <FAIcon icon={ex.saved ? `pencil-alt` : `check`} size={17.5} button noMargin />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={ex.saved ? 6 : undefined}>
                                                                                <Tooltip title='Delete Rule'>
                                                                                    <IconButton
                                                                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to delete this rule?`, () => handleRemoveRule(idx)))}
                                                                                    >
                                                                                        <FAIcon icon='trash-alt' size={17.5} button noMargin />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        </Grid>
                    </>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        onClick={handleToggleAffected}
                                    >
                                        <FAIcon icon="times" size={15} button />
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" paragraph>
                                        Category Exclusions
                                    </Typography>
                                    {getCategoryExclusions()}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" paragraph>
                                        Supplier Exclusions
                                    </Typography>
                                    {getSupplierExclusions()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>  
                )}
            </Grid>
        )
    );
}

export default ProductDiscountExclusions;