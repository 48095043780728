import React from 'react';
import { connect } from 'react-redux';

import API from 'API';
import AdminRouteReferences from 'Components/Routes/AdminRoutes/AdminRouteReferences';
import WarehouseRouteReferences from 'Components/Routes/WarehouseRoutes/WarehouseRouteReferences';
import { authStaff } from 'Redux/Actions/Auth/Auth';
import { handleAppError } from 'Redux/Actions/UI/UI';
import { clearToken, forceStoredProfile, getMode, getToken, isTokenExpired, setLastActivePage } from 'Functions/AuthFunctions';

const RequireAuth = ChildComponent => {
    
    class ComposedComponent extends React.Component {

        componentDidMount() {
            this.shouldNavigateAway();
        }
        
        componentDidUpdate(prevProps){
            if(this.props.location.pathname !== prevProps.location.pathname){
                this.shouldNavigateAway();
            }
            return false;
        }
        
        shouldNavigateAway(){
            // Get login / token expiration status (arr [0] => token, arr [1] => expired?)
            const token = getToken();

            if (!token) {

                // User Not Logged In
                this.props.history.replace('/login')

            } else if (isTokenExpired(token)) {

                // Token Expired
                setLastActivePage(window.location.pathname);

                forceStoredProfile();

                clearToken();

                this.props.history.replace('/login/expired')

            } else if(this.props.loggedInStaff && this.props.loggedInStaff.id === 0) {

                API.get('/staff/my/password/requiresUpdate')
                .then(res => {

                    if(res?.data){
                        if(res.data.requires_update){
                            this.props.handleAppError("PASSWORD_UPDATE", "");
                        }
                    }

                    this.props.authStaff(getToken());

                })
            }
            
            let accessRef = '';
            
            if(this.props.location.pathname === '' || this.props.location.pathname === '/') {
                accessRef = 'dashboard';
            } else { // eslint-disable-next-line
                let routeReferences = getMode() === 'Warehouse' ? WarehouseRouteReferences : AdminRouteReferences;
                for (const [key, value] of Object.entries(routeReferences)) {
                    if(this.props.location.pathname.startsWith(key)) {
                        accessRef = value;
                        break;
                    }
                }
            }
            
            // If access ref is empty - page isn't mapped!
            if(accessRef !== true) {
                if(accessRef.length > 0) {
                        // Check staff access
                        API.access('' + accessRef)
                        .then(accessRes =>  {
                            if(accessRes && accessRes.data && !accessRes.data['has_access']){
                                if(!this.props.errorState) {
                                    this.props.handleAppError("UNAUTHORIZED", this.props.location.pathname)
                                }
                                this.props.history.replace('/dashboard')
                            }
                        });

                } else {
                    this.props.history.push('/404')
                }
            }
        }

        render() {
            return <ChildComponent {...this.props}  />;
        }    

    }

    const mapStateToProps = (state) => {
        return {
            errorState: state.ui.appError.state,
            loggedInStaff: state.staffAuth.staff
        };
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            authStaff: token => dispatch(authStaff(token)),
            handleAppError: (error, message) => dispatch(handleAppError(error, message))
        };
    }
    
    return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);

}

export default RequireAuth;