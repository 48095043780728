import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
// import moment from 'moment';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import IconButton  from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const initialState = {
    productName: '',
    // blockDate: null,
    productId: 0,
    rowIdx: 0
}

const Block = ({closeDialog, formData, handleSubmit}) => {

    const [state, setState] = useState(initialState);

    const { 
        productName, 
        // blockDate 
    } = state;

    const dispatch = useDispatch();

    const handleChange = e => {
        const { name, value } = e.target;
        setState(state => ({
            ...state,
            [name]: value
        }));
    }

    // const handleDateChange = (name, date) => {
    //     setState(state => ({
    //         ...state,
    //         [name]: date ? moment(date).format("YYYY-MM-DD") : null
    //     }));
    // }

    const submit = () => {
        handleSubmit(state);
        closeDialog();
    }

    useEffect(() => {

        if(formData) {

            const { 
                productName, 
                blockDate, 
                rowIdx
            } = formData;

            setState(state => ({
                ...state,
                blockDate,
                productName,
                rowIdx
            }))

        }
        
    }, [formData])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    margin="none"
                    name="productName"
                    value={productName}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="text" size="small" noMargin /></InputAdornment>
                    }}
                /> 
                <Typography variant="caption" color="error" paragraph>
                    <FAIcon type="solid" icon="info-circle" size={11} noMargin /> This text will be visible to the customer
                </Typography>
                {/* <DatePicker
                    name="blockDate"
                    type="date"
                    label={`Forward Date`}
                    onChange={date => handleDateChange("blockDate", date)}
                    value={blockDate}
                    endAdornment={(
                        <InputAdornment>
                            <Tooltip title="Clear Date">
                                <IconButton onClick={(e) => { e?.stopPropagation?.(); handleDateChange("blockDate", null);}} size="small">
                                    <FAIcon icon="times" size={11} type="solid" disabled noMargin button />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )}
                    autoOk={true}
                    disablePast={true}
                /> */}
            </Grid>
            <Grid item xs={12} sm={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <Button onClick={closeDialog}
                                variant="text"
                        >
                            <FAIcon icon='times' size={15} button />
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            onClick={() => dispatch(deployConfirmation(`Are you sure you want to ${formData ? 'modify' : 'add'} this text?`, submit))}
                            variant="text" 
                            color="primary" 
                            disabled={_.isEmpty(productName)}
                        >
                            <FAIcon icon='check' size={15} button />
                            {formData ? 'Modify' : 'Add'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Block;