import React from 'react';
import InsightChip from 'Components/Common/Chips/InsightChip';

const BooleanChip = ({color, textColor, icon, label, success, warning}) => {
    return (
        <InsightChip 
            background={color ? color : (success ? '#28a745' : (warning ? '#F57C00' : '#d9534f'))}
            color={textColor ?? '#fff'}
            icon={icon ? icon : (success ? 'check' : (warning ? 'exclamation-circle' : 'times'))}
            label={label}
            paddingLeft={8}
            paddingRight={8}
        />
    )
}

export default BooleanChip;