import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import AppStoreLogo from 'Assets/Logos/app-store.svg';
import GooglePlayLogo from 'Assets/Logos/google-play.svg';
import ViewStaffMfaStatus from '../ViewStaff/ViewStaffMfaStatus';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    action: 'status',
    mfaActivationQr: '',
    mfaActivationSecret: '',
    formData: {
        mfaCodeInd: {
            0: ' ',
            1: ' ',
            2: ' ',
            3: ' ',
            4: ' ',
            5: ' ',
        },
        mfaCode: '',
    },
    formErrors: [],
    confirmation: {
        revoke: false,
        submit: false,
    },
    snackbar: {
        revoke: false,
        submit: false,
    }
}

class UpdateStaffMfa extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    
    componentDidMount(){        
        this.mfaCode0 = React.createRef();
        this.mfaCode1 = React.createRef();
        this.mfaCode2 = React.createRef();
        this.mfaCode3 = React.createRef();
        this.mfaCode4 = React.createRef();
        this.mfaCode5 = React.createRef();
    
    }

    handleActivateMfa = () => {
        if(!this.props.myAccount) {
            API.get('/staff/' + this.props.staffId + '/mfa').then(result => {
                if(result.data){
                    this.setState({
                        action: 'activate',
                        mfaActivationQr: result.data.mfa_qr,
                        mfaActivationSecret: result.data.mfa_secret
                    }, () => this.mfaCode0.current.focus());              
                }
            });
        }
    }

    handleRevoke = () => {
        if(!this.props.myAccount) {
            API.get('/staff/' + this.props.staffId + '/mfa/revoke').then(result => {
                if(result.data.success){
                    this.setState({
                        action: 'status',
                        snackbar: {
                            ...this.state.snackbar,
                            revoke: true
                        },
                        formErrors: [],
                        formData: {
                            ...this.state.formData,
                            mfaCode: '',
                            mfaCodeInd: {
                                0: ' ',
                                1: ' ',
                                2: ' ',
                                3: ' ',
                                4: ' ',
                                5: ' '
                            }
                        }
                    }, () => {
                        if(this.props.callback)
                            this.props.callback()
                    });                
                }
            });
        }
    }

    handleVerifyMfa = () => { 
        if(!this.props.myAccount) {      
            const mfaCode = this.state.formData.mfaCodeInd;
            this.setState({
                formData: {
                    ...this.state.formData,
                    mfaCode: mfaCode[0]+mfaCode[1]+mfaCode[2]+mfaCode[3]+mfaCode[4]+mfaCode[5]
                }            
            }, () => this.handleVerifyMfaSubmit());
        }
    }

    handleVerifyMfaSubmit = () => {   
        if(!this.props.myAccount) {
            API.post('/staff/' + this.props.staffId + '/mfa/verify', this.state.formData)
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),                    
                        formData: {
                            ...this.state.formData,
                            mfaCode: '',
                            mfaCodeInd: {
                                0: ' ',
                                1: ' ',
                                2: ' ',
                                3: ' ',
                                4: ' ',
                                5: ' '
                            }
                        }
                    }, () => {
                        if(this.state.formErrors['mfaCode']){
                            this.mfaCode0.current.focus();
                        }
                    });
                } else {
                    this.setState({
                        ...initialState,
                        action: 'complete',
                        isLoading: false,
                        snackbar: {
                            submit: true
                        }
                    }, () => {
                        if(this.props.callback)
                            this.props.callback()
                    });
                }
            });
        }
    }

    handleMfaChange = (id, e) => {
        if(id === 0 && e.target.value.length >= 1) {
            this.mfaCode1.current.focus();
        } else if(id > 0 && id < 5 && e.target.value.length >= 1) {
            this['mfaCode'+(id+1)].current.focus();            
        } else if(id > 0) {            
            this['mfaCode'+(id-1)].current.focus();  
        }
        this.setState({
            formData: {
                ...this.state.formData,
                mfaCodeInd: {
                    ...this.state.formData.mfaCodeInd,
                    [id]: e.target.value.length > 0 ? (e.target.value.trim().length === 1 ? e.target.value.trim() : e.target.value.trim()[1]) : ' '
                },
            }
        }, () => {
            if(id === 5 && this.state.formData.mfaCodeInd[5].length > 0) { 
                this.handleVerifyMfa(false);
            }
        });
    }

    handleSnackbarClose = (type) => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                [type]: false
            }
        });
    };

    handleConfirmationOpen = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true,
            }
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false,
            }
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false,
            }
        }, () => {
            if(type === 'revoke') {
                this.handleRevoke();
            } else {
                this.handleActivateMfa();
            }
        });
    }

    render() {
        const { myAccount, staff } = this.props;
        const { action, formData, formErrors, mfaActivationQr, mfaActivationSecret, confirmation, snackbar } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {(action === 'complete' && (
                                <PaddedPaper>
                                    <Grid container spacing={3} alignItems='center'>
                                        <Grid item align='center'>
                                            <FAIcon type="light" icon='shield-check' size={60} className="textSuccess" />
                                        </Grid>
                                        <Grid item xs>          
                                            <Typography variant="subtitle1" className="fw-400">
                                                Multi-Factor Authentication Activated
                                            </Typography>       
                                            <Typography variant="caption">
                                                You have successfully activated Multi-Factor Authentication for {(staff && `${staff.staff_first_name} ${staff.staff_last_name}`) || 'this staff member'}!<br />
                                                Next time they login, they'll have to start using their authenticator app to get a code.
                                            </Typography>
                                        </Grid>
                                    </Grid>   
                                </PaddedPaper>   
                            )) || (action === 'status' && (
                                <PaddedPaper>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <ViewStaffMfaStatus status={[staff.staff_mfa, staff.staff_mfa_activation_datetime]} noPaper />
                                        </Grid>
                                        {!myAccount && (
                                            <Grid item>
                                                {(staff.staff_mfa === 1 && (
                                                    <Button 
                                                        variant="text" 
                                                        color="primary" 
                                                        onClick={() => this.handleConfirmationOpen('revoke')}
                                                    > 
                                                        <FAIcon icon="times" button />
                                                        Revoke
                                                    </Button>
                                                )) || (                          
                                                    <Button 
                                                        variant="text" 
                                                        color="primary" 
                                                        onClick={() => this.handleConfirmationOpen('submit')}
                                                    > 
                                                        <FAIcon icon="check" button />
                                                        Activate 
                                                    </Button>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                </PaddedPaper>
                            )) || (
                                <PaddedPaper>
                                    <Grid container spacing={3}>
                                        <Grid item align='center'>
                                            <img src={mfaActivationQr} width='150' height='150' alt='Mfa Qr Code' /><br />
                                            {mfaActivationSecret}
                                        </Grid>
                                        <Grid item xs>          
                                            <Typography variant="subtitle1" className="fw-400">
                                                Multi-Factor Authentication Activation
                                            </Typography>       
                                            <Typography variant="caption">
                                                Please scan the QR code or enter the code into your authenticator app and enter a one time code below to complete Multi-Factor Authentication activation.
                                            </Typography>
                                            {this.state.formErrors.mfaCode && 
                                                <Typography variant="body2" color="error">
                                                    {this.state.formErrors.mfaCode}
                                                </Typography>
                                            }    
                                            <Grid item container xs={12} spacing={window.innerWidth < 600 ? 1 : 3} style={{marginTop: 12, marginBottom: 12}} align='center'>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="mfa-0"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoComplete="off"
                                                        value={formData.mfaCodeInd[0]}
                                                        error={formErrors && formErrors['mfaCode'] && true}
                                                        onChange={(e) => this.handleMfaChange(0, e)} 
                                                        className='mfaInput'
                                                        inputProps={{
                                                            ref:this.mfaCode0,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="mfa-1"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoComplete="off"
                                                        className='mfaInput'
                                                        value={formData.mfaCodeInd[1]}
                                                        error={formErrors && formErrors['mfaCode'] && true}
                                                        onChange={(e) => this.handleMfaChange(1, e)} 
                                                        inputProps={{
                                                            ref:this.mfaCode1,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="mfa-2"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        name="mfaCode3"
                                                        autoComplete="off"
                                                        className='mfaInput'
                                                        value={formData.mfaCodeInd[2]}
                                                        error={formErrors && formErrors['mfaCode'] && true}
                                                        onChange={(e) => this.handleMfaChange(2, e)} 
                                                        inputProps={{
                                                            ref:this.mfaCode2,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="mfa-3"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoComplete="off"
                                                        className='mfaInput'
                                                        value={formData.mfaCodeInd[3]}
                                                        error={formErrors && formErrors['mfaCode'] && true}
                                                        onChange={(e) => this.handleMfaChange(3, e)} 
                                                        inputProps={{                                                                        
                                                            ref:this.mfaCode3,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="mfa-4"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoComplete="off"
                                                        className='mfaInput'
                                                        value={formData.mfaCodeInd[4]}
                                                        error={formErrors && formErrors['mfaCode'] && true}
                                                        onChange={(e) => this.handleMfaChange(4, e)} 
                                                        inputProps={{
                                                            ref:this.mfaCode4,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="mfa-5"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoComplete="off"
                                                        className='mfaInput'
                                                        value={formData.mfaCodeInd[5]}
                                                        error={formErrors && formErrors['mfaCode'] && true}
                                                        onChange={(e) => this.handleMfaChange(5, e)} 
                                                        inputProps={{
                                                            ref:this.mfaCode5,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div className="buttonRow">
                                                <Button 
                                                    variant="text" 
                                                    onClick={() => this.handleRevoke()}
                                                > 
                                                    <FAIcon icon="times" size={15} button />
                                                    Cancel
                                                </Button>
                                                <Button 
                                                    variant="text" 
                                                    color="primary" 
                                                    onClick={() => this.handleVerify()}
                                                >  
                                                    <FAIcon icon="check" size={15} button />
                                                    Confirm
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>                        
                                </PaddedPaper>
                            )}
                            <ConfirmationDialog 
                                open={confirmation['revoke']} 
                                success={() => this.handleConfirmationSuccess('revoke')} 
                                close={() => this.handleConfirmationClose('revoke')} 
                                title="Revoke Multi-Factor Authentication?" 
                                message="Are you sure you want to revoke Multi-Factor Authentication for this staff member?"
                            />                    
                            <ConfirmationDialog 
                                open={confirmation['submit']} 
                                success={() => this.handleConfirmationSuccess('submit')} 
                                close={() => this.handleConfirmationClose('submit')} 
                                title="Activate Multi-Factor Authentication?" 
                                message="Are you sure you want to enable Multi-Factor Authentication for this staff member?"
                            />                    
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={snackbar['revoke']}
                                onClose={() => this.handleSnackbarClose('revoke')}
                                message="The staff member's Multi-Factor Authentication has been revoked"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={snackbar['submit']}
                                onClose={() => this.handleSnackbarClose('submit')}
                                message="The staff member's Multi-Factor Authentication has been enabled"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Authy
                                        </Typography>
                                        <Typography variant="caption" component="div" color="primary">
                                            Compatible with RED Multi-Factor Authentication
                                        </Typography>
                                        <Typography variant="caption">
                                            Authy brings the future of strong authentication to your device.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://apps.apple.com/gb/app/authy/id494168017" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                            <img src={AppStoreLogo} alt="App Store" width={115} height={33}/>
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                            <img src={GooglePlayLogo} alt="Google Play" width={115} height={33}/>
                                        </a>
                                    </Grid>
                                    <Grid item xs />
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Google Authenticator
                                        </Typography>
                                        <Typography variant="caption" component="div" color="primary">
                                            Compatible with RED Multi-Factor Authentication
                                        </Typography>
                                        <Typography variant="caption">
                                            Google Authenticator generates 2-Step Verification codes on your phone.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://apps.apple.com/gb/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                            <img src={AppStoreLogo} alt="App Store" width={115} height={33}/>
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                            <img src={GooglePlayLogo} alt="Google Play" width={115} height={33}/>
                                        </a>
                                    </Grid>
                                    <Grid item xs />
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6" paragraph>
                                    What is Multi-Factor Authentication?
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    Multi-Factor Authentication is an additional layer of security that requires staff to enter a one-time code from a supported authenticator app when they login to RED.
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6" paragraph>
                                    Why use Multi-Factor Authentication?
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    If a user's password is compromised then Multi-Factor Authentication can help to keep the data stored in RED secure; as no one can login and access RED without having a one time code that is generated from a device that only the staff member has access to physically. Plus, the one-time code changes every 30 seconds, can only be used once and is relatively impossible to guess!
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}> 
                            <PaddedPaper style={{paddingBottom: 25}}>
                                <Typography variant="h6" paragraph>
                                    What if the staff member loses their device?
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    You can revoke and re-activate Multi-Factor Authentication from this page at any time; once Multi-Factor Authentication has been revoked no one will be able to use the old authenticator app to generate a code to login to RED.
                                </Typography>
                            </PaddedPaper>
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default UpdateStaffMfa;