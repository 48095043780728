import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChangeUcFirst, 
    handleCheckedChange, 
    handleConfirmationOpen, 
    handleConfirmationClose, 
    handleConfirmationSuccess,
    handleSelectChange
} from 'Functions/FormFunctions';
import FAIcon from '../Icons/FontAwesome/FAIcon';

const initialState = () => ({
    confirmation: {
        submit: false
    },
    formData: {
        id: '',
        type: '',
        firstName: '',
        lastName: '',
        position: '',
        telephone: '',
        mobile: '',
        email: '',
        primaryContact: 0,
        accounts: 0,
        gdprOptOut: 0,
        mailshotBar: 0,
        prefEmail: 0,
        prefMob: 0,
        prefTel: 0,
        noDelUpdates: 0,
        noOrderEmails: 0,
        notes: '',
    },
    formErrors: {},
    isLoading: true,
    positionList: []
})

class ContactForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.handleChangeUcFirst = handleChangeUcFirst.bind(this)
        this.handleCheckedChange = handleCheckedChange.bind(this)
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
        this.handleSelectChange = handleSelectChange.bind(this)
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        if(this.props.updateId) {
            Promise.all([
                API.get(`/misc/contacts/${this.props.updateId}`),
                API.get(`/misc/contacts/positions`)
            ])
            .then(([result, positions]) => {
                if(result?.data && positions?.data) {
                    let positionList = _.map(positions.data, position => (
                        _.assign({
                            label: position,
                            value: position
                        })
                    ))
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            id: this.props.updateId,
                            type: this.props.type,
                            firstName: result.data.contact_first_name,
                            lastName: result.data.contact_last_name,
                            position: result.data.contact_position,
                            telephone: result.data.contact_telephone,
                            mobile: result.data.contact_mobile,
                            email: result.data.contact_email,
                            primaryContact: result.data.contact_primary,
                            accounts: result.data.contact_accounts,
                            gdprOptOut: result.data.contact_gdpr_opt_in === 1 ? 0 : 1,
                            prefEmail: result.data.contact_pref_email,
                            prefMob: result.data.contact_pref_mob,
                            prefTel: result.data.contact_pref_tel,
                            mailshotBar: result.data.contact_mailshot_bar,
                            noDelUpdates: result.data.contact_no_del_updates,
                            noOrderEmails: result.data.contact_no_order_emails,
                            notes: result.data.contact_notes,
                        },
                        isLoading: false,
                        positionList
                    })
                }
            })
        } else {
            API.get(`/misc/contacts/positions`)
            .then(positions => {
                if(positions?.data) {
                    let positionList = _.map(positions.data, position => (
                        _.assign({
                            label: position,
                            value: position
                        })
                    ))
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            id: this.props.id,
                            type: this.props.type,
                            primaryContact: this.props.firstContact ? 1 : this.state.formData.primaryContact,
                            accounts: this.props.firstContact ? 1 : this.state.formData.accounts
                        },
                        isLoading: false,
                        positionList
                    })
                }
            })
        }
    }

    handleChange = e => {
        if(this.state.formData[e.target.name] !== e.target.value) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                }
            }, () => {
                if(this.state.formData.mobile === '' && this.state.formData.prefMob === 1) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            prefMob: 0
                        }
                    })
                }
                if(this.state.formData.telephone === '' && this.state.formData.prefTel === 1) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            prefTel: 0
                        }
                    })
                }
                if(this.state.formData.email === '' && this.state.formData.prefEmail === 1) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            prefEmail: 0
                        }
                    })
                }
            });
        }
    }
    
    handleSubmit = e => {
        e?.preventDefault?.();
        this.handleConfirmationOpen('submit');
    }
    
    cancel = () => {
        this.setState({
            formData: {
                ...initialState().formData,
                id: this.props.id,
                type: this.props.type
            }
        }, () => {
            if(this.props.cancel) {
                this.props.cancel();
            }
        })
    }
    
    submit = () => {
        if(this.props.updateId) {
            API.put(`/misc/contacts/${this.props.updateId}`, { formData: this.state.formData, type: this.props.type, id: this.props.id } )
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                                type: this.props.type
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully updated the contact");
                            if(this.props.callback) 
                                this.props.callback();
                        })
                    }
                }
            })
        } else {
            API.post(`/misc/contacts`, this.state.formData)
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                                type: this.props.type
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully added a new contact");
                            if(this.props.callback) 
                                this.props.callback();
                        })
                    }
                }
            })
        }
    }

    render() {
        const { customerForm, updateId } = this.props;
        const { formErrors, formData, isLoading, positionList } = this.state;
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} lg={6} style={{paddingRight: 12}}>
                            <TextField
                                id="firstName"
                                name="firstName"
                                label="First Name *"
                                value={formData.firstName}
                                error={formErrors && formErrors['firstName'] && true}
                                helperText={formErrors && formErrors['firstName']}                                                
                                onChange={this.handleChangeUcFirst}
                                margin="none"
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                id="lastName"
                                name="lastName"
                                label="Last Name *"
                                value={formData.lastName}
                                error={formErrors && formErrors['lastName'] && true}
                                helperText={formErrors && formErrors['lastName']}                                                
                                onChange={this.handleChangeUcFirst}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email Address"
                                value={formData.email}
                                error={formErrors && formErrors['email'] && true}
                                helperText={formErrors && formErrors['email']}                                                
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteSelect
                                creatable={true}
                                creatableMessage={userInput => `Add Position: ${userInput}`}
                                options={positionList}
                                name="position"
                                label="Position"
                                value={formData.position}
                                error={formErrors && formErrors['position'] && true}
                                helperText={formErrors && formErrors['position']}                                                
                                onChange={v => this.handleSelectChange('position', v, true)}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} style={{paddingRight: 12}}>
                            <TextField
                                id="telephone"
                                name="telephone"
                                label="Telephone"
                                value={formData.telephone}
                                error={formErrors && formErrors['telephone'] && true}
                                helperText={formErrors && formErrors['telephone']}                                                
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                id="mobile"
                                name="mobile"
                                label="Mobile"
                                value={formData.mobile}
                                error={formErrors && formErrors['mobile'] && true}
                                helperText={formErrors && formErrors['mobile']}                                                
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Textarea
                                id="notes"
                                name="notes"
                                label="Notes"
                                value={formData.notes}
                                error={formErrors && formErrors['notes'] && true}
                                helperText={formErrors && formErrors['notes']}                                                
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption" className="fw-400 textError" gutterBottom>
                                No Personal/Family data to be stored (GDPR)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                            Contact Settings
                                        </Typography>
                                        <FormControlLabel
                                            control={<Checkbox name="primaryContact" checked={!!formData.primaryContact} color="primary" onChange={this.handleCheckedChange}/>}
                                            label={<Typography variant="caption">Primary Contact</Typography>}
                                        />
                                        <br />
                                        <FormControlLabel
                                            control={<Checkbox name="accounts" checked={!!formData.accounts} color="primary" onChange={this.handleCheckedChange}/>}
                                            label={<Typography variant="caption">Accounts Contact</Typography>}
                                        />
                                        <br /><br />
                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                            Contact Preferences
                                        </Typography>
                                        <FormControlLabel
                                            control={<Checkbox name="gdprOptOut" checked={formData.gdprOptOut} color="primary" onChange={this.handleCheckedChange}/>}
                                            label={<Typography variant="caption">GDPR Opt Out</Typography>}
                                        />
                                        <br />
                                        <FormControlLabel
                                            control={<Checkbox name="mailshotBar" checked={!!formData.mailshotBar} color="primary" onChange={this.handleCheckedChange}/>}
                                            label={<Typography variant="caption">Mailshot Bar</Typography>}
                                        />
                                        {customerForm && (
                                            <>
                                                <br />
                                                <FormControlLabel
                                                    control={<Checkbox name="noDelUpdates" checked={formData.noDelUpdates} color="primary" onChange={this.handleCheckedChange}/>}
                                                    label={<Typography variant="caption">No Delivery Notifications</Typography>}
                                                />
                                                <br />
                                                <FormControlLabel
                                                    control={<Checkbox name="noOrderEmails" checked={formData.noOrderEmails} color="primary" onChange={this.handleCheckedChange}/>}
                                                    label={<Typography variant="caption">No Order Emails</Typography>}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                            Preferred Contact Methods
                                        </Typography>
                                        <FormControlLabel
                                            control={<Checkbox name="prefEmail" checked={formData.prefEmail} color="primary" onChange={this.handleCheckedChange}/>}
                                            label={<Typography variant="caption">E-mail</Typography>}
                                            disabled={formData.email === ''}
                                        />
                                        <br />
                                        <FormControlLabel
                                            control={<Checkbox name="prefMob" checked={formData.prefMob} color="primary" onChange={this.handleCheckedChange}/>}
                                            label={<Typography variant="caption">Mobile</Typography>}
                                            disabled={formData.mobile === ''}
                                        />
                                        <br />
                                        <FormControlLabel
                                            control={<Checkbox name="prefTel" checked={formData.prefTel} color="primary" onChange={this.handleCheckedChange}/>}
                                            label={<Typography variant="caption">Telephone</Typography>}
                                            disabled={formData.telephone === ''}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button
                            onClick={this.cancel}
                            variant="text"
                            color="primary"
                        >
                            <FAIcon icon="times" size={15} button />
                            {updateId ? 'Cancel' : 'Discard'}  
                        </Button>
                        <Button 
                            disabled={formData.firstName === '' || formData.lastName === ''} 
                            type="submit"
                            variant="text" color="primary"
                        >
                            <FAIcon icon="check" size={15} button />
                            {updateId ? 'Update' : 'Add Contact'}
                            </Button>
                    </Grid>     
                    <ConfirmationDialog 
                        open={this.state.confirmation.submit} 
                        success={() => this.handleConfirmationSuccess('submit')} 
                        close={() => this.handleConfirmationClose('submit')} 
                        message={`Are you sure you want to ${updateId ? 'update' : 'add'} this contact?`}
                    />
                </form>
            )
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(ContactForm);