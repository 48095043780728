import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
    
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const SplitOrderLine = ({closeDialog, deployConfirmation, idx, product, submit}) => {
    const [qty, setQty] = useState("");
    return (
        (product && (
            <Grid container spacing={2}>
                <Grid item xs={12} align='center'>
                    <Box pt={4}>
                        <FAIcon icon="random" type="thin" size={50} noMargin />
                    </Box>
                </Grid>
                <Grid item xs={12} align='center'>
                    <Typography variant="h6">
                        Split Order Line
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box ml={4} mr={4} mt={2} mb={3}>
                        <Box className="content-light-grey" p={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h6">
                                        {product.productCode ?? product.podp_product_code}
                                    </Typography>
                                    <Typography variant="body2">
                                        {product.productName ?? product.podp_product_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h4">
                                        {product.quantity ?? product.podp_qty}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <TextField
                                        inputProps={{
                                            step: 1,
                                            min: 1,
                                            max: ((product.quantity ?? product.podp_qty) - 1),
                                            style: {
                                                background: '#fff',
                                                textAlign: 'center',
                                                width: 75
                                            }
                                        }}
                                        onChange={e => setQty(parseInt(e?.target?.value ?? 1))}
                                        type="number"
                                        variant="outlined"
                                        value={qty}
                                        autoFocus
                                    />
                                    <Box pt={1}>
                                        <Typography variant="caption" color="textSecondary" component="div">
                                            Max. {((product.quantity ?? product.podp_qty) - 1)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} align="right">
                    <Grid container spacing={2}>
                        <Grid item xs />
                        <Grid item>
                            <Button onClick={closeDialog}
                                    variant="text"
                            >
                                <FAIcon icon="times" size={15} button />
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                onClick={() => deployConfirmation("Are you sure you want to split this order line?", () => submit(idx, qty))}
                                variant="text" 
                                color="primary" 
                                disabled={qty === "" || qty < 1 || qty > ((product.quantity ?? product.podp_qty) - 1)}
                            >
                                <FAIcon icon="check" size={15} button />
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )) || (
            <React.Fragment />
        )
    )
}

export default SplitOrderLine;