import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const IconText = ({children, icon, iconClass, iconType, pb, pl, pr, pt, textClass, textVariant}) => (
    <Box pt={pt} pb={pb} pl={pl} pr={pr}>
        <Grid container spacing={1} alignItems='center'>
            {icon && (
                <Grid item>
                    <FAIcon icon={icon} type={iconType ?? 'light'} size={13.5} className={iconClass} noMargin />
                </Grid>
            )}
            <Grid item xs>
                <Typography variant={textVariant ?? 'body2'} className={textClass}>
                    {children}
                </Typography>
            </Grid>
        </Grid>
    </Box>
)

export default IconText;