import { useEffect, useState } from 'react';
import { Line } from "react-chartjs-2";
import Box from '@material-ui/core/Box';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const ProductChart = ({productId}) => {
    const [chart, setChart] = useState(null);

    const loadChartData = () => {

        API.get(`/products/${productId}/sales`)
        .then(res => {
            if(res?.data?.sales) {
                let salesData = res.data.sales,
                    monthlySales = [],
                    sales = {},
                    labels = [],
                    data = [];

                for (let i = 0, j = 11; i <= j; i++) {
                    data.push(salesData[i].qty);
                    labels.push(salesData[i].label);
                    monthlySales.push({
                        heading: salesData[i].label,
                        value: salesData[i].qty,
                    });
                }

                sales = {
                    labels,
                    datasets: [
                        {
                            lineTension: 0.1,
                            backgroundColor: "transparent",
                            borderColor: "#FFCDD2",
                            borderCapStyle: "butt",
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: "miter",
                            pointBorderColor: "#EF3340",
                            pointBackgroundColor: "#fff",
                            pointBorderWidth: 4,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "#C62828",
                            pointHoverBorderWidth: 2,
                            pointRadius: 2,
                            pointHitRadius: 10,
                            data,
                        },
                    ],
                };

                setChart(sales);

            }
        })

    }

    useEffect(() => {
        loadChartData();
    }, []); /* eslint-disable-line */

    return (
        <Box maxWidth={"95%"} margin={"0 auto"}>
            {!chart ? (
                <LoadingCircle />
            ) : (
                <Line
                    data={chart}
                    options={{
                        legend: {
                            display: false,
                        },
                        scales: {
                            xAxes: [
                                {
                                    gridLines: {
                                        color: "rgba(0,0,0,.03)",
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    gridLines: {
                                        color: "rgba(0,0,0,.03)",
                                    },
                                    ticks: {
                                        userCallback: function (label, index, labels) {
                                            if (Math.floor(label) === label) {
                                                return label;
                                            }
                                        },
                                        beginAtZero: true,
                                        min: 0,
                                    },
                                },
                            ],
                        },
                    }}
                />
            )}
        </Box>
    )
}

export default ProductChart;