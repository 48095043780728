import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import DateSelect from "Components/Common/Selects/DateSelect";
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import ViewEmail from 'Components/Communication/Misc/ViewEmail';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';

const initialState = () => ({
    access: {
        emailSend: false,
    },
    date: 'oneMonth',
    searchResults: {},
    searchString: '',
    dataLoading: true,
})

class EmailLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.timeout = null;
    }

    componentDidMount = () => {

        const {
            filter,
            noSearch
        } = this.props;

        if(filter) {
            this.setState({
                date: noSearch ? 'allTime' : this.state.date
            }, () => {
                this.getEmailLog(true);
            })
        } else {
            API.access('email-send')
            .then(res => {
                this.setState({
                    access: {
                        emailSend: res?.data?.has_access ?? false
                    },
                    date: noSearch ? 'allTime' : this.state.date
                }, () => {
                    this.getEmailLog(true);
                })
            })
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getEmailLog = dataLoading => {
        let params = {
            d: this.state.date,
            keywords: this.state.searchString
        } 
        if(this.props.selectedStaff) {
            params = {
                ...params,
                staff: this.props.selectedStaff
            }
        }
        if(this.props.filter) {
            params = {
                ...params,
                filter: this.props.filter,
                filterId: this.props.filterId
            }
        }
        this.setState({
            dataLoading,
        }, () => {
            API.get('/email', {
                props: {
                    cancellation: true
                },
                params
            })
            .then(result => {
                if(result?.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data,
                    });
                }
            });
        });
    }

    handleDeployComposeEmail = (id, defaultFormData, resendStaffId = null) => this.props.deployDialog(
        <EmailDialog
            callback={this.getEmailLog}
            id={id ?? false}
            defaultFormData={defaultFormData}
            resendStaffId={resendStaffId}
            type='default'
        />, false, "Compose E-mail", "standard","md");
    
    handleDeployViewEmail = id => this.props.deployDialog(
        <ViewEmail
            id={id}
            closeDialog={this.props.closeDialog}
            resend={this.handleDeployComposeEmail}
        />, false, "", "standard","md", false, true);

    onSearchDateChange = option => {
        this.setState({
            date: option?.value ?? initialState().date
        },
        () => {
            if(this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => this.getEmailLog(true), 200);
        });
    }

    onSearchStringChange = event => {
        this.setState({
            searchString: event?.target?.value ?? ''
        },
        () => {
            if(this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => this.getEmailLog(true), 200);
        });
    }

    render() {
        const { noSearch, sendEmail } = this.props;
        const { access, date, dataLoading, searchResults, searchString } = this.state;
        return (
            <Grid container spacing={3}>      
                {!noSearch && (
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item>
                                <Box width={300}>
                                    <TextField
                                        fullWidth
                                        onChange={this.onSearchStringChange}
                                        placeholder='Search:'
                                        value={searchString} 
                                        variant="filled"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                        }}
                                    />
                                </Box>
                            </Grid>   
                            <Grid item>
                                <Box width={175}>
                                    <DateSelect onChange={v => this.onSearchDateChange(v)} value={date} />
                                </Box>
                            </Grid>
                            {access.emailSend && !this.props.filter && (
                                <Grid item xs align='right'>
                                    <Button 
                                        color="primary"
                                        size="small"
                                        variant="text"
                                        onClick={() => this.handleDeployComposeEmail(false, undefined)}
                                    >
                                        <FAIcon type="light" icon="envelope" size={15} button />
                                        Send E-mail
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Paper>
                        <DataTable
                            config={{
                                key: 'email_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                options: {
                                    headingInput: (
                                        sendEmail && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                onClick={sendEmail}
                                            >
                                                <FAIcon type="light" icon="plus-circle" size={15} button />
                                                Compose E-mail
                                            </Button>
                                        )
                                    ),
                                    dataRef: true,
                                    export: {
                                        title: `Email Log`,
                                        name: `Email-Log`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Recipient',
                                    field: rowData => rowData.email_to,
                                    dataRef: 'email_to',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Sender',
                                    field: rowData => rowData.email_from,
                                    dataRef: 'email_from',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Subject',
                                    field: rowData => rowData.email_subject,
                                    dataRef: 'email_subject',
                                    main: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Sent By',
                                    field: rowData => rowData.email_staff_id === 0 ? 'Automated' : <StaffChip staff={this.props.staff?.[rowData.email_staff_id] ?? 0} />,
                                    dataRef: 'email_staff_id',
                                    sizeToContent: true,
                                    fieldFormat: 'staff',
                                },
                                {
                                    heading: 'Sent',
                                    field: rowData => rowData.email_created_datetime,
                                    fieldFormat: 'datetime',
                                    dataRef: 'email_created_datetime',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => <StatusChip status={rowData.email_status} />,
                                    dataRef: 'email_status',
                                    sizeToContent: true,
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'binoculars', onClick: () => this.handleDeployViewEmail(rowData.email_id)}
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default EmailLog;