import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingScreen from 'Components/Common/LoadingScreen/LoadingScreen';
import { 
    handleSnackbarClose,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess
} from 'Functions/FormFunctions';

const initialState = {
    access: {
        manualSync: false
    },
    confirmation: {
        sync: false
    },
    searchResults: {},
    searchString: '',
    startDate: '',
    endDate: '',
    isSync: false,
    dataLoading: true,
    snackbar: {
        sync: false
    },
    snackbarText: ''
}

class BreatheHr extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;        
        this.handleSnackbarClose = handleSnackbarClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.timeout = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    loadComponentData = () => {
        API.access('adjusted-working-time:manual-sync')
        .then(result => {
            this.setState({
                access: {
                    manualSync: result.data ? result.data.has_access : false
                }
            }, () => this.getReportData());
        })
    }

    getReportData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/staff/breatheHr/absences', { 
                props: {
                    cancellation: true
                },
                params: { 
                    startDate: this.state.startDate, 
                    endDate: this.state.endDate, 
                    searchString: this.state.searchString 
                } 
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    });
                }
            });
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        this.setState({
            ...initialState
        }, 
        () => {
            this.getReportData();
        });
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getReportData, 200);
    }

    sync = () => {
        this.setState({
            isSync: true
        }, 
        () => {
            API.get('/staff/breatheHr/sync')
            .then(result => {
                if(result.data && result.data.success) {
                    this.setState({
                        ...initialState,
                        isSync: false,
                        snackbar: {
                            sync: true
                        },
                        snackbarText: `Imported data for ${result.data.total} staff from Breathe HR`
                    }, 
                    () => {
                        this.loadComponentData();
                    });
                }
            })
        });
    }


    render() {
    const { access, confirmation, isSync, snackbar, dataLoading } = this.state;
    return (
        <Grid container spacing={1}>      
            <Grid container item xs={12} alignItems='center'>
                <Grid item xs={12} sm={4} md={4} xl={3} style={{marginBottom: 12}}>
                    <TextField 
                        fullWidth
                        onChange={this.onSearchStringChange}
                        placeholder='Search:'
                        value={this.state.searchString} 
                        variant="filled"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                        }}
                    /> 
                </Grid> 
                {access.manualSync && (     
                    <Grid item xs={12} sm={8} md={8} xl={9} align='right'> 
                        <Button 
                            color="primary" 
                            size="small"
                            variant="text"
                            onClick={() => this.handleConfirmationOpen('sync')}
                        >   
                            <FAIcon type="light" icon="sync" size="small" button />
                            Manual Data Import
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <DataTable  
                        config={{
                            key: 'bhr_id',
                            pagination: true,
                            alternatingRowColours: true,
                            isLoading: dataLoading,
                            responsiveImportance: true,
                            noResultsText: 'No Breathe HR data has been imported yet',
                            options: {
                                dataRef: true,
                                reset: this.resetSearch,
                                export: {
                                    title: `Adjusted Working Time`,
                                    name: `AdjustedWorkingTime`,
                                    excel: true,
                                    pdf: true,
                                    print: true
                                }
                            }
                        }}
                        columns={[
                            {
                                heading: 'Staff Member',
                                field: rowData => (
                                    <>
                                        {rowData.staff === null ? 'Unknown' : ( `${rowData.staff && rowData.staff.staff_first_name} ${rowData.staff && rowData.staff.staff_last_name}`)}
                                    </>
                                ),
                                important: true,
                                dataRef: 'staff.staff_last_name',
                                sizeToContent: true,   
                            },
                            {
                                heading: 'Duration (Working Days)',
                                field: rowData => `-${rowData.bhr_work_units}`,
                                dataRef: 'bhr_work_units',
                                sizeToContent: true,
                            },
                            {
                                heading: 'Start Date',
                                field: rowData => rowData.bhr_start_date,
                                dataRef: 'bhr_start_date',
                                fieldFormat: 'date',
                                important: true,
                                sizeToContent: true,
                            },
                            {
                                heading: 'End Date',
                                field: rowData => rowData.bhr_end_date,
                                dataRef: 'bhr_end_date',
                                fieldFormat: 'date',
                                important: true,
                                sizeToContent: true,
                            }
                        ]}
                        rows={this.state.searchResults}
                    />
                </Paper>
            </Grid>   
            <LoadingScreen 
                open={isSync}
                title='Just a moment...'
                message={`RED is importing data from Breathe HR`}
            />
            
            <ConfirmationDialog 
                open={confirmation.sync} 
                success={() => this.handleConfirmationSuccess('sync')} 
                close={() => this.handleConfirmationClose('sync')} 
                title="Manually Import Data?" 
                message="This will import the latest data from Breathe HR for all staff"
            />       
            <SnackBar
                variant="success"
                anchorOriginVertical='bottom'
                anchorOriginHorizontal='right'
                open={snackbar.sync}
                onClose={() => this.handleSnackbarClose('sync')}
                message={this.state.snackbarText}
            />        
        </Grid>
    );
  }
}

export default BreatheHr;