import React  from 'react';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Couriers from './Couriers/Couriers';
import CourierConfiguration from './CourierConfiguration/CourierConfiguration';

import AddCourierConfiguration from './AddCourierConfiguration/AddCourierConfiguration';
import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import FullScreenDialog from 'Components/Common/Dialogs/FullScreenDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InsightChip from 'Components/Common/Chips/InsightChip';
import UpdateCourierConfiguration from './UpdateCourierConfiguration/UpdateCourierConfiguration';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { TabPanel, handleTabChange } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const initialState = {
    access: {
        couriers: false,
        configuration: false,
        addConfiguration: false,
        updateConfiguration: false,
        setDefaultDelivery: false
    },
    currentTab: 0,
    dialog: {
        addConfiguration: false,
        updateConfiguration: false,
        id: ""
    },
    isLoading: true,
    tabs: {
        0: "Sales Order Delivery",
        1: "DPD Local",
        2: "Advanced Access",
        3: "Crisis",
    },
    tabText: {
        0: "Manage default delivery options for Sales Orders and view courier contact information",
        1: "Create and manage existing DPD Local delivery methods",
        2: "Create and manage existing Advanced Access collection and delivery methods",
        3: "Create and manage existing Crisis delivery methods",
    },
    uuid: uuidv4()
}

class SettingsCarriage extends React.Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Settings", "Sales Order Delivery"])
        this.loadComponentData()
    }

    loadComponentData = () => {
        API.multiAccess([
            'couriers', 
            'courier-configuration-view', 
            'courier-configuration-add', 
            'courier-configuration-update', 
            'courier-configuration-default'
        ])
        .then(([
            couriers, 
            configuration,
            addConfiguration, 
            updateConfiguration, 
            setDefaultDelivery
        ]) => {     
            this.setState({
                access: {
                    couriers,
                    configuration,
                    addConfiguration,
                    updateConfiguration,
                    setDefaultDelivery
                },
               isLoading: false
            })
        })
    }

    handleGetCourierId = () => {
        /* DPD - 1 | AA - 2 | Crisis - 3 | Supplier Direct - 4 */
        if(this.state.currentTab === 1) {
            return 1
        } else if(this.state.currentTab === 2) {
            return 4
        } else if(this.state.currentTab === 3) {
            return 2
        } else {
            return ""
        }
    }

    toggleDialog = (dialog, id) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                [dialog]: !this.state.dialog[dialog],
                id
            },
            uuid: uuidv4()
        })
    }

    render() {
        const { classes, scrollToTop, ui } = this.props;
        const { access, currentTab, dialog, isLoading, tabs, tabText, uuid } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabs[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        {access.couriers && (
                                            <TabPanel value={currentTab} index={0}>
                                                <Couriers 
                                                    access={access} 
                                                    scrollToTop={scrollToTop} 
                                                    action={(
                                                        <Grid item>
                                                            {access.addConfiguration && (
                                                                <Button
                                                                    variant="text"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => this.toggleDialog('addConfiguration', this.handleGetCourierId())}
                                                                >
                                                                    <FAIcon type="light" icon="plus-circle" size={15} button />
                                                                    Add Delivery Method
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                    )} 
                                                />
                                            </TabPanel>
                                        )}
                                        {access.configuration && (
                                            <>
                                                <TabPanel value={currentTab} key={uuid} index={1}>
                                                    {/* DPD */}
                                                    <CourierConfiguration access={access} id={1} scrollToTop={scrollToTop} key={uuid} toggleDialog={this.toggleDialog} />
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={2}>
                                                    {/* Advanced Access */}
                                                    <CourierConfiguration access={access} id={2}  scrollToTop={scrollToTop} key={uuid} toggleDialog={this.toggleDialog} />
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={3}>
                                                    {/* Crisis */}
                                                    <CourierConfiguration access={access} id={3} scrollToTop={scrollToTop} key={uuid} toggleDialog={this.toggleDialog} />
                                                </TabPanel>
                                            </>
                                        )}
                                        <FullScreenDialog
                                            open={dialog.addConfiguration}
                                            title="Add Delivery Method"
                                            content={<AddCourierConfiguration id={dialog.id} toggleDialog={() => this.toggleDialog('addConfiguration')} />}
                                            toggleDialog={() => this.toggleDialog('addConfiguration')}
                                        />   
                                        <FullScreenDialog
                                            open={dialog.updateConfiguration}
                                            title="Update Delivery Method"
                                            content={<UpdateCourierConfiguration id={dialog.id} toggleDialog={() => this.toggleDialog('updateConfiguration')} />}
                                            toggleDialog={() => this.toggleDialog('updateConfiguration')}
                                        /> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {access.couriers && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                )}
                                {access.configuration && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="DPD Local" />
                                )}
                                {access.configuration && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Advanced Access" />
                                )}
                                {access.configuration && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="Crisis" />
                                )}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps, null)(withStyles(ViewPage)(SettingsCarriage));