import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import logo from "Assets/Images/no-img.jpg";
import BooleanChip from "Components/Common/Chips/BooleanChip";
import ConfigureProductBundle from "Components/Common/Forms/QuoteOrderForm/ConfigureProductBundle";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import Highlight from "Components/Common/Highlight/Highlight";
import IconText from "Components/Common/Text/IconText";
import InsightChip from "Components/Common/Chips/InsightChip";
import Block from "Components/Common/Forms/QuoteOrderForm/Block";
import Product from "Components/Common/Forms/QuoteOrderForm/Product";
import ProductStockChip from "Components/Common/Chips/ProductStockChip";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import ViewProduct from "Components/Products/ViewProduct/ViewProduct";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { CLOUDFRONT_URL } from "Constants";
import { currencyFormat, isEven, isOdd } from "Functions/MiscFunctions";

const Products = ({
    access,
    blanketDiscount,
    classes,
    cardFormats,
    cardProgrammers,
    cardTypes,
    context,
    customer,
    customerVatPercent,
    deliveries,
    deployConfirmation,
    document,
    formData,
    formErrors,
    getCustomerData,
    handleApplyOrderDiscount,
    handleCheckedChange,
    handleResetOrderDiscount,
    handleBlanketDiscountChange,
    handleProductAdd,
    handleProductEdit,
    handleProductRemove,
    handleProductDrag,
    handleSetPrimaryIdx,
    handleSwitchApiRoute,
    hasDeliveries,
    id,
    ifm,
    inForm,
    inline,
    isUpdate,
    od,
    odIdx,
    order,
    rows,
    products,
    readOnly,
    selectorIdxValue,
    sites,
    showAllocationStatus,
    showDespatchedAndInvoiced,
    showAssignedDelivery,
    status,
    useAccurateMargins
}) => {
    const dispatch = useDispatch();

    const isMobile = useSelector((state) => state.ui.device.isMobile);

    const dispCloseDialog = () => dispatch(closeDialog());

    const handleDeployNotes = (name, notes) => {
        dispatch(deployDialog(<ViewNotes hideDeleteButton notes={notes} inline />, false, `${name}`, "standard", "sm"));
    };

    const handleDeployAddText = () => {
        dispatch(deployDialog(<Block handleSubmit={handleProductAdd} closeDialog={dispCloseDialog} />, false, `Add Text`, "standard", "xs"));
    };

    const handleEditProductRow = (editProductRow, delivery = undefined) => {
        if (editProductRow?.bundleId) {
            dispatch(
                deployDialog(
                    <ConfigureProductBundle addBundleToForm={handleProductEdit} handleProductAdd={handleProductAdd} handleProductRemove={handleProductRemove} bundle={editProductRow?.bundleData?.b} bundleData={editProductRow?.bundleData} line={editProductRow} customer={customer} />,
                    false,
                    `${editProductRow?.bundleData?.b?.pc} - ${editProductRow?.bundleData?.b?.nm} ${editProductRow?.bundleData?.b?.tp ? ` (${editProductRow?.bundleData?.b?.tp})` : ``}`,
                    "error",
                    "lg",
                    false,
                    true
                )
            );
        } else if (editProductRow?.productId <= 0) {
            dispatch(deployDialog(<Block closeDialog={dispCloseDialog} formData={editProductRow} handleSubmit={handleProductEdit} />, false, "Modify Text", "standard", "xs"));
        } else {
            dispatch(
                deployDialog(
                    <Product
                        // blanketDiscount={formData?.blanketDiscount ?? ''}
                        cardFormats={cardFormats}
                        cardProgrammers={cardProgrammers}
                        cardTypes={cardTypes}
                        getCustomerData={getCustomerData}
                        ifm={ifm}
                        classes={classes}
                        closeDialog={dispCloseDialog}
                        customer={customer}
                        customerVatPercent={customerVatPercent}
                        delivery={delivery}
                        deliveries={deliveries}
                        document={document}
                        formData={editProductRow}
                        products={products}
                        handleSubmit={handleProductEdit}
                        handleSwitchApiRoute={handleSwitchApiRoute}
                        sites={sites}
                        isUpdate={isUpdate}
                        inDialog
                        id={id}
                    />,
                    false,
                    "",
                    "standard",
                    "xl",
                    false,
                    true
                )
            );
        }
    };

    const handleProductInfoOpen = (productId) => {
        dispatch(deployDialog(<ViewProduct id={productId} inDialog />, false, "Product Details", "standard", "xl", true));
    };

    const handleBundleOpen = (rowData) => {
        if (rowData.bundle && rowData.bundle_data) {
            dispatch(deployDialog(<ConfigureProductBundle bundle={rowData?.bundle} bundleData={rowData.bundle_data} customer={customer} line={rowData} />, false, `${rowData?.bundle?.nm}`, "error", "lg"));
        } else {
            dispatch(deploySnackBar(`error`, `Something went wrong, please refresh the page try again`));
        }
    };

    let cols;

    switch (context) {
        case "build":
            cols = [
                {
                    heading: "Product",
                    field: (rowData) => {
                        const src = rowData.image ? `${CLOUDFRONT_URL}${rowData.image}` : rowData.product?.default_image?.img_filekey ? `${CLOUDFRONT_URL}${rowData.product.default_image.img_filekey}` : logo;
                        return (
                            (rowData.productId <= 0 && !rowData?.bundleId && (
                                <Box ml={3} pt={1} pb={1}>
                                    <FAIcon type="thin" icon="text" size={40} noMargin />
                                </Box>
                            )) ||
                            (rowData?.parent > 0 && (
                                <Box ml={3}>
                                    <FAIcon type="thin" icon="arrow-turn-down-right" size={40} noMargin />
                                </Box>
                            )) ||
                            (formErrors && formErrors["productRows|" + rowData.rowNumber] && <FAIcon type="light" icon="exclamation-triangle" size="large" color="error" noMargin />) || (
                                <Avatar variant="square" alt={rowData.product?.product_name ?? "Product Image"} src={src} style={{ border: rowData?.parent > 0 ? "1px solid #ddd" : undefined, width: isMobile ? 30 : 75, height: isMobile ? 30 : 75 }} />
                            )
                        );
                    },
                    important: true,
                    hideInResponsiveDialog: true,
                    sizeToContent: true,
                },
                {
                    heading: "",
                    field: (rowData) => {
                        const notes = _.size(rowData.notes ?? rowData.productData?.notes ?? rowData?.productData?.notes ?? rowData?.product?.notes),
                            rowStatus = rowData.status ?? rowData.productData?.status ?? rowData.productData?.product_status ?? rowData.product?.product_status ?? "Unknown",
                            replacement = rowData.productRep > 0 || rowData.productData?.productRep > 0 || rowData.productData?.product_rep_product_id > 0 || rowData.product?.product_rep_product_id > 0,
                            inStock = rowData.instock ?? rowData.productData?.instock ?? rowData.productData?.stock_count ?? rowData.product?.stock_count ?? 0,
                            preOrdered = rowData.preOrdered ?? rowData.productData?.preOrdered ?? rowData.productData?.stock_on_order_allocated_count ?? rowData.product?.stock_on_order_allocated_count ?? 0,
                            available = rowData.available ?? rowData.productData?.available ?? rowData.productData?.stock_available_count ?? rowData.product?.stock_available_count ?? 0,
                            allocated = rowData.allocated ?? rowData.productData?.allocated ?? rowData.productData?.stock_allocated_count ?? rowData.product?.stock_allocated_count ?? 0,
                            onOrder = rowData.onOrder ?? rowData.productData?.onOrder ?? rowData.productData?.stock_on_order_count ?? rowData.product?.stock_on_order_count ?? 0,
                            chkPr = rowData.chkPr || rowData.productData?.chkPr || rowData.productData?.product_check_price === 1 || rowData.product?.product_check_price === 1,
                            stck = rowData.stkIt || rowData.productData?.stkIt || rowData.productData?.product_stock_item === 1 || rowData.product?.product_stock_item === 1,
                            quantity = rowData.quantity,
                            text = rowData.text,
                            srv = rowData.srv ?? rowData.productData?.srv ?? rowData?.productData?.product_service ?? rowData?.service ?? 0,
                            reqTech = rowData.reqTech ?? rowData.productData?.reqTech ?? rowData?.productData?.product_req_tech ?? 0,
                            stockStatus = rowData.stockStatus ?? null,
                            cardOrder = rowData.productData?.cDb === 1 || rowData.cardDb === 1 || rowData.productData?.cardDb === 1,
                            parent = rowData?.parent > 0 ? _.find(rows ?? formData?.newProductRows ?? formData?.productRows ?? [], (el) => el.id === rowData?.parent) : null;

                        return (
                            (rowData.productId <= 0 && !rowData?.bundleId && (
                                <Box whiteSpace="normal" style={{ wordBreak: "break-all" }}>
                                    <Typography variant="h5" className="fw-400">
                                        {rowData.productName}
                                    </Typography>
                                    {/* {rowData.blockDate && (
                                            <Typography variant="body2" className="fw-400">
                                                Forward Date: {momentFormatDate(rowData.blockDate)}
                                            </Typography>
                                        )} */}
                                </Box>
                            )) || (
                                <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                    {rowData?.parent > 0 && (
                                        <Grid item xs={12}>
                                            <IconText icon="exclamation-circle" iconClass="textSuccess" pb={1} textVariant="caption" textClass="fw-400">
                                                {parent?.productCode ? `${parent.productCode}` : `Bundled product`}
                                            </IconText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Typography variant="body2" className="fw-400">
                                            {rowData.productCode}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.25}>
                                            {(rowData?.bundleId && (
                                                <>
                                                    {rowData?.productName?.split?.("\n").map((item, key) => (
                                                        <Typography key={key} variant="caption" component="div" className={isEven(key) ? `fw-400` : undefined} gutterBottom={isOdd(key)}>
                                                            {item.substr(0, 65)}
                                                            {item.length > 65 ? "..." : ""}
                                                        </Typography>
                                                    ))}
                                                    {rowData.bundle_check && document === "Quote" && (
                                                        <Box pt={0.5}>
                                                            <ProductStockChip bundle status={rowData.bundle_check?.stockStatus} basedOnStatus />
                                                        </Box>
                                                    )}
                                                </>
                                            )) || (
                                                <>
                                                    {rowData.productName.substr(0, 65)}
                                                    {rowData.productName.length > 65 ? "..." : ""}
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                    {!rowData?.bundleId && (
                                        <>
                                            {srv !== 1 && (!rowData.efs || rowData?.efs === "Invoiced" || rowData?.efs === "Despatched") && (
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" component="div">
                                                        {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {srv === 1 && (
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" component="div">
                                                        Managed Service
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {srv !== 1 && rowData.allocationPending === 1 && (
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" className="fw-400">
                                                        <FAIcon icon="exclamation-circle" size={12} className="textSuccess mr-1" noMargin />
                                                        This line will be automatically allocated once the purchase order is confirmed
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {srv !== 1 && rowData?.productData?.newPrice && (
                                                <Grid item xs={12}>
                                                    <Box pt={0.5} pb={0.5}>
                                                        <Typography variant="caption" className="fw-400">
                                                            <FAIcon icon="exclamation-triangle" type="solid" className="textError mr-1" size={13.5} />
                                                            Trade price is changing to &pound;{rowData?.productData.newPrice} on {rowData?.productData.newPriceDate}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {((rowData?.efs === "Invoiced" || rowData?.efs === "Despatched") && (
                                                <Grid item xs={12}>
                                                    <InsightChip color="#ef3340" icon="exclamation-triangle" label={`${rowData.efs} - This line cannot be amended or removed`} marginRight={8} paddingLeft={8} paddingRight={4} />
                                                </Grid>
                                            )) || (
                                                <>
                                                    <Grid item>
                                                        <Box pt={1}>
                                                            {(showAllocationStatus &&
                                                                ((rowData.ls &&
                                                                    (rowData.ls === "Despatched" || rowData.ls === "Invoiced" || rowData.ls === "Cancelled") &&
                                                                    ((rowData.ls === "Cancelled" && (
                                                                        <Box mt={0.5} mr={1}>
                                                                            <BooleanChip success={false} label={"Cancelled"} />
                                                                        </Box>
                                                                    )) ||
                                                                        (rowData.ls === "Invoiced" && (
                                                                            <Box mt={0.5} mr={1}>
                                                                                <BooleanChip success={true} label={"Invoiced"} />
                                                                            </Box>
                                                                        )) ||
                                                                        (rowData.ls === "Despatched" && (
                                                                            <Box mt={0.5} mr={1}>
                                                                                <BooleanChip success={true} label={"Despatched"} />
                                                                            </Box>
                                                                        )))) ||
                                                                    ((srv === 1 || rowData.allocationPending === 1) && <React.Fragment />) ||
                                                                    (rowData.ft === "PO" && (
                                                                        <Box mr={1}>
                                                                            <BooleanChip success={rowData.con === 1} label={rowData.con === 1 ? "Awaiting Supplier Despatch" : "Awaiting Supplier Confirmation"} />
                                                                        </Box>
                                                                    )) || (
                                                                        <Box mr={1}>
                                                                            <BooleanChip success={rowData.isAllocated === 1} label={rowData.isAllocated === 1 ? (hasDeliveries ? "Allocated" : "Pre-Allocated") : "Not Allocated"} />
                                                                        </Box>
                                                                    ))) ||
                                                                (srv !== 1 && <ProductStockChip qty={quantity} rep={replacement} status={rowStatus} stocked={stck} stockAvailable={available} stockOnOrderAvailable={onOrder - preOrdered} />)}
                                                        </Box>
                                                    </Grid>
                                                    {notes > 0 && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip
                                                                    background="#4CAF50"
                                                                    color="#fff"
                                                                    marginRight={8}
                                                                    paddingLeft={8}
                                                                    icon="comment-alt-lines"
                                                                    label={notes}
                                                                    onClick={() => handleDeployNotes(rowData.productCode, rowData.notes ?? rowData.productData?.notes ?? [])}
                                                                    size={11}
                                                                    tooltip="View Notes"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {cardOrder && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip background="#ef3340" color="#fff" marginRight={8} paddingLeft={8} icon="exclamation-triangle" label={"Card Order Authorisation"} size={11} />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {chkPr && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip background="#FF5722" color="#fff" marginRight={8} paddingLeft={8} icon="exclamation-triangle" label="Check Price and Availability" />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                </>
                                            )}
                                            {!_.isEmpty(text) && (
                                                <Grid item xs={12}>
                                                    <Box pt={1} whiteSpace="normal" style={{ wordBreak: "break-all" }}>
                                                        <Typography variant="body2" className="fw-400">
                                                            <FAIcon icon="text" size={12} /> {text}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {!_.isEmpty(stockStatus) && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Box pt={1}>
                                                            <Typography variant="caption" className="fw-400">
                                                                This line is awaiting stock from:
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    {_.map(stockStatus, (row, idx) => (
                                                        <Grid item xs={12} key={idx}>
                                                            <Link className="textDefault" to={`/purchase-orders/${row.pop}:${row.pod}`} target="_blank">
                                                                <Box pt={1}>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item>
                                                                            <Box mb={0.6}>
                                                                                <FAIcon icon="circle" size={5} type="solid" />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <Typography variant="body2" className="fw-400">
                                                                                Qty - {row.qty} - PO #{row.ref}/{row.idx} ({row.sts})
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Box pl={1.6}>
                                                                                <Typography variant="body2">{(row.des && `Despatched on ${row.des}`) || `Despatch expected on ${row.exp}${row.chk ? ` | Check: ${row.chk}` : ``}`}</Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Link>
                                                        </Grid>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {(rowData?.ls !== "Despatched" || rowData?.ls !== "Invoiced" || rowData?.ls !== "Cancelled") && reqTech === 1 && (
                                        <Grid item xs={12}>
                                            <Box pt={1}>
                                                <Typography variant="caption" component="div">
                                                    <IconText icon="screwdriver-wrench" textVariant="caption" iconClass="textInfo" textClass="textDefault fw-400">
                                                        Requires technical before despatch which may affect despatch times
                                                    </IconText>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            )
                        );
                    },
                    colSpan: (rowData) => {
                        return rowData.productId <= 0 && !rowData?.bundleId ? 8 : 1;
                    },
                    important: true,
                },
                {
                    heading: "QTY",
                    field: (rowData) => <Highlight variant="success">{rowData.quantity}</Highlight>,
                    important: true,
                    alignment: "center",
                    sizeToContent: true,
                },
            ];
            break;

        default:
            cols = [
                {
                    heading: "Product",
                    field: (rowData) => {
                        const src = rowData.image ? `${CLOUDFRONT_URL}${rowData.image}` : rowData.product?.default_image?.img_filekey ? `${CLOUDFRONT_URL}${rowData.product.default_image.img_filekey}` : logo;
                        return (
                            (rowData.productId <= 0 && !rowData?.bundleId && (
                                <Box ml={3} pt={1} pb={1}>
                                    <FAIcon type="thin" icon="text" size={40} noMargin />
                                </Box>
                            )) ||
                            (rowData?.parent > 0 && (
                                <Box ml={3}>
                                    <FAIcon type="thin" icon="arrow-turn-down-right" size={40} noMargin />
                                </Box>
                            )) ||
                            (formErrors && formErrors["productRows|" + rowData.rowNumber] && <FAIcon type="light" icon="exclamation-triangle" size="large" color="error" noMargin />) || (
                                <Avatar variant="square" alt={rowData.product?.product_name ?? "Product Image"} src={src} style={{ border: rowData?.parent > 0 ? "1px solid #ddd" : undefined, width: isMobile ? 30 : 75, height: isMobile ? 30 : 75 }} />
                            )
                        );
                    },
                    important: true,
                    hideInResponsiveDialog: true,
                    sizeToContent: true,
                },
                {
                    heading: "",
                    field: (rowData) => {
                        const notes = _.size(rowData.notes ?? rowData?.productData?.notes ?? rowData?.product?.notes),
                            rowStatus = rowData.status ?? rowData.productData?.status ?? rowData.productData?.product_status ?? rowData.product?.product_status ?? "Unknown",
                            replacement = rowData.productRep > 0 || rowData.productData?.productRep > 0 || rowData.productData?.product_rep_product_id > 0 || rowData.product?.product_rep_product_id > 0,
                            inStock = rowData.instock ?? rowData.productData?.instock ?? rowData.productData?.stock_count ?? rowData.product?.stock_count ?? 0,
                            preOrdered = rowData.preOrdered ?? rowData.productData?.preOrdered ?? rowData.productData?.stock_on_order_allocated_count ?? rowData.product?.stock_on_order_allocated_count ?? 0,
                            available = rowData.available ?? rowData.productData?.available ?? rowData.productData?.stock_available_count ?? rowData.product?.stock_available_count ?? 0,
                            allocated = rowData.allocated ?? rowData.productData?.allocated ?? rowData.productData?.stock_allocated_count ?? rowData.product?.stock_allocated_count ?? 0,
                            onOrder = rowData.onOrder ?? rowData.productData?.onOrder ?? rowData.productData?.stock_on_order_count ?? rowData.product?.stock_on_order_count ?? 0,
                            chkPr = rowData.chkPr || rowData.productData?.chkPr || rowData.productData?.product_check_price === 1 || rowData.product?.product_check_price === 1,
                            chkWe = rowData.chkWe || rowData.productData?.chkWe || rowData.productData?.product_check_weight === 1 || rowData.product?.product_check_weight === 1,
                            chkWeQty = rowData.chkWeQty || rowData.productData?.chkWeQty || rowData.productData?.product_check_weight_qty_allowed || rowData.product?.product_check_weight_qty_allowed || null,
                            minOrderQty = rowData.minOrderQty || rowData.productData?.minOrderQty || rowData.productData?.product_min_order_qty || rowData.product?.product_min_order_qty,
                            orderMultiplesOf = rowData.ordMulOf || rowData.productData?.ordMulOf || rowData.productData?.product_order_multiples_of || rowData.product?.product_order_multiples_of,
                            packSize = rowData.packSize || rowData.productData?.packSize || rowData.productData?.product_pack_size || rowData.product?.product_pack_size,
                            stck = rowData.stkIt || rowData.productData?.stkIt || rowData.productData?.product_stock_item === 1 || rowData.product?.product_stock_item === 1,
                            quantity = rowData.quantity,
                            text = rowData.text,
                            cost = (rowData.productId <= 0 && !rowData.bundleId ? null  : (rowData.productData?.product_cost ?? rowData.productData?.default_rate?.rate_cost ?? rowData.currentCost ?? rowData.cost)),
                            price = (rowData.productId <= 0 && !rowData.bundleId ? null  : rowData.subTotal),
                            showDiscountWarning = rowData.productId <= 0 && !rowData.bundleId ? false : rowData?.ls !== "Invoiced" && rowData?.ls !== "Cancelled" && rowData?.efs !== "Invoiced" && rowData?.efs !== "Cancelled" && price && cost && parseFloat(price) < parseFloat(cost),
                            discountDiff = showDiscountWarning ? Math.abs(parseFloat(price) - parseFloat(cost)).toFixed(2) : null,
                            srv = rowData.srv ?? rowData.productData?.srv ?? rowData?.productData?.product_service ?? rowData?.service ?? 0,
                            reqTech = rowData.reqTech ?? rowData.productData?.reqTech ?? rowData?.productData?.product_req_tech ?? 0,
                            stockStatus = rowData.stockStatus ?? null,
                            cardOrder = rowData.productData?.cDb === 1 || rowData.cardDb === 1 || rowData.productData?.cardDb === 1,
                            parent = rowData?.parent > 0 ? _.find(rows ?? formData?.newProductRows ?? formData?.productRows ?? [], (el) => el.id === rowData?.parent) : null;
                        return (
                            (rowData.productId <= 0 && !rowData?.bundleId && (
                                <Box whiteSpace="normal" style={{ wordBreak: "break-all" }}>
                                    <Typography variant="h5" className="fw-400">
                                        {rowData.productName}
                                    </Typography>
                                    {/* {rowData.blockDate && (
                                            <Typography variant="body2" className="fw-400">
                                                Forward Date: {momentFormatDate(rowData.blockDate)}
                                            </Typography>
                                        )} */}
                                </Box>
                            )) || (
                                <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                    {rowData?.parent > 0 && (
                                        <Grid item xs={12}>
                                            <IconText icon="exclamation-circle" iconClass="textSuccess" pb={1} textVariant="caption" textClass="fw-400">
                                                {parent?.productCode ? `${parent.productCode}` : `Bundled product`}
                                            </IconText>
                                        </Grid>
                                    )}
                                    {showDiscountWarning && (
                                        <Grid item xs={12}>
                                            <IconText icon="exclamation-circle" iconClass="textError" pb={1} textVariant="caption" textClass="textError fw-400">
                                                This is being sold below the buy price by {currencyFormat.format(discountDiff)}
                                            </IconText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Typography variant="body2" className="fw-400">
                                            {rowData.productCode}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.25}>
                                            {(rowData?.bundleId && (
                                                <>
                                                    {rowData?.productName?.split?.("\n").map((item, key) => (
                                                        <Typography key={key} variant="caption" component="div" className={isEven(key) ? `fw-400` : undefined} gutterBottom={isOdd(key)}>
                                                            {item.substr(0, 65)}
                                                            {item.length > 65 ? "..." : ""}
                                                        </Typography>
                                                    ))}
                                                    {rowData.bundle_check && document === "Quote" && (
                                                        <Box pt={0.5}>
                                                            <ProductStockChip bundle status={rowData.bundle_check?.stockStatus} basedOnStatus />
                                                        </Box>
                                                    )}
                                                </>
                                            )) || (
                                                <>
                                                    {rowData.productName.substr(0, 65)}
                                                    {rowData.productName.length > 65 ? "..." : ""}
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                    {!rowData?.bundleId && (
                                        <>
                                            {srv !== 1 && (!rowData.efs || rowData?.efs === "Invoiced" || rowData?.efs === "Despatched") && (
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" component="div">
                                                        {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {srv === 1 && (
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" component="div">
                                                        Managed Service
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {srv !== 1 && rowData.allocationPending === 1 && (
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" className="fw-400">
                                                        <FAIcon icon="exclamation-circle" size={12} className="textSuccess mr-1" noMargin />
                                                        This line will be automatically allocated once the purchase order is confirmed
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {srv !== 1 && rowData?.productData?.newPrice && (
                                                <Grid item xs={12}>
                                                    <Box pt={0.5} pb={0.5}>
                                                        <Typography variant="caption" className="fw-400">
                                                            <FAIcon icon="exclamation-triangle" type="solid" className="textError mr-1" size={13.5} />
                                                            Trade price is changing to &pound;{rowData?.productData.newPrice} on {rowData?.productData.newPriceDate}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {((rowData?.efs === "Invoiced" || rowData?.efs === "Despatched") && (
                                                <Grid item xs={12}>
                                                    <InsightChip color="#ef3340" icon="exclamation-triangle" label={`${rowData.efs} - This line cannot be amended or removed`} marginRight={8} paddingLeft={8} paddingRight={4} />
                                                </Grid>
                                            )) || (
                                                <>
                                                    <Grid item>
                                                        <Box pt={1}>
                                                            {(showAllocationStatus &&
                                                                ((rowData.ls &&
                                                                    (rowData.ls === "Despatched" || rowData.ls === "Invoiced" || rowData.ls === "Cancelled") &&
                                                                    ((rowData.ls === "Cancelled" && (
                                                                        <Box mt={0.5} mr={1}>
                                                                            <BooleanChip success={false} label={"Cancelled"} />
                                                                        </Box>
                                                                    )) ||
                                                                        (rowData.ls === "Invoiced" && (
                                                                            <Box mt={0.5} mr={1}>
                                                                                <BooleanChip success={true} label={"Invoiced"} />
                                                                            </Box>
                                                                        )) ||
                                                                        (rowData.ls === "Despatched" && (
                                                                            <Box mt={0.5} mr={1}>
                                                                                <BooleanChip success={true} label={"Despatched"} />
                                                                            </Box>
                                                                        )))) ||
                                                                    ((srv === 1 || rowData.allocationPending === 1) && <React.Fragment />) ||
                                                                    (rowData.ft === "PO" && (
                                                                        <Box mr={1}>
                                                                            <BooleanChip success={rowData.con === 1} label={rowData.con === 1 ? "Awaiting Supplier Despatch" : "Awaiting Supplier Confirmation"} />
                                                                        </Box>
                                                                    )) || (
                                                                        <Box mr={1}>
                                                                            <BooleanChip success={rowData.isAllocated === 1} label={rowData.isAllocated === 1 ? (hasDeliveries ? "Allocated" : "Pre-Allocated") : "Not Allocated"} />
                                                                        </Box>
                                                                    ))) ||
                                                                (srv !== 1 && <ProductStockChip qty={quantity} rep={replacement} status={rowStatus} stocked={stck} stockAvailable={available} stockOnOrderAvailable={onOrder - preOrdered} />)}
                                                        </Box>
                                                    </Grid>
                                                    {notes > 0 && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip
                                                                    background="#4CAF50"
                                                                    color="#fff"
                                                                    marginRight={8}
                                                                    paddingLeft={8}
                                                                    icon="comment-alt-lines"
                                                                    label={notes}
                                                                    onClick={() => handleDeployNotes(rowData.productCode, rowData.notes ?? rowData.productData?.notes ?? [])}
                                                                    size={11}
                                                                    tooltip="View Notes"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {cardOrder && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip background="#ef3340" color="#fff" marginRight={8} paddingLeft={8} icon="exclamation-triangle" label={"Card Order Authorisation"} size={11} />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {chkPr && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip background="#FF5722" color="#fff" marginRight={8} paddingLeft={8} icon="exclamation-triangle" label="Check Price and Availability" />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {chkWe && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip
                                                                    background="#FF5722"
                                                                    color="#fff"
                                                                    marginRight={8}
                                                                    paddingLeft={8}
                                                                    icon="exclamation-triangle"
                                                                    label={`Check Weight ${chkWeQty > 0 ? `(> ${chkWeQty} Qty)` : ``}`}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {packSize > 1 && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip
                                                                    background="#e91e63"
                                                                    color="#fff"
                                                                    marginRight={8}
                                                                    paddingLeft={8}
                                                                    icon="exclamation-triangle"
                                                                    label={`Pack Size: ${packSize}`}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {minOrderQty > 1 && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip
                                                                    background="#e91e63"
                                                                    color="#fff"
                                                                    marginRight={8}
                                                                    paddingLeft={8}
                                                                    icon="exclamation-triangle"
                                                                    label={`Min Order Qty: ${minOrderQty}`}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {orderMultiplesOf > 1 && (
                                                        <Grid item>
                                                            <Box pt={1}>
                                                                <InsightChip
                                                                    background="#e91e63"
                                                                    color="#fff"
                                                                    marginRight={8}
                                                                    paddingLeft={8}
                                                                    icon="exclamation-triangle"
                                                                    label={`Order In Multiples Of: ${orderMultiplesOf}`}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                </>
                                            )}
                                            {!_.isEmpty(text) && (
                                                <Grid item xs={12}>
                                                    <Box pt={1} whiteSpace="normal" style={{ wordBreak: "break-all" }}>
                                                        <Typography variant="body2" className="fw-400">
                                                            <FAIcon icon="text" size={12} /> {text}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {!_.isEmpty(stockStatus) && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Box pt={1}>
                                                            <Typography variant="caption" className="fw-400">
                                                                This line is awaiting stock from:
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    {_.map(stockStatus, (row, idx) => (
                                                        <Grid item xs={12} key={idx}>
                                                            <Link className="textDefault" to={`/purchase-orders/${row.pop}:${row.pod}`} target="_blank">
                                                                <Box pt={1}>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item>
                                                                            <Box mb={0.6}>
                                                                                <FAIcon icon="circle" size={5} type="solid" />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <Typography variant="body2" className="fw-400">
                                                                                Qty - {row.qty} - PO #{row.ref}/{row.idx} ({row.sts})
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Box pl={1.6}>
                                                                                <Typography variant="body2">{(row.des && `Despatched on ${row.des}`) || `Despatch expected on ${row.exp}${row.chk ? ` | Check: ${row.chk}` : ``}`}</Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Link>
                                                        </Grid>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {(rowData?.ls !== "Despatched" || rowData?.ls !== "Invoiced" || rowData?.ls !== "Cancelled") && reqTech === 1 && (
                                        <Grid item xs={12}>
                                            <Box pt={1}>
                                                <Typography variant="caption" component="div">
                                                    <IconText icon="screwdriver-wrench" textVariant="caption" iconClass="textInfo" textClass="textDefault fw-400">
                                                        Requires technical before despatch which may affect despatch times
                                                    </IconText>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            )
                        );
                    },
                    colSpan: (rowData) => {
                        return rowData.productId <= 0 && !rowData?.bundleId ? 8 : 1;
                    },
                    important: true,
                },
            ];

            if (showDespatchedAndInvoiced) {
                cols.push(
                    {
                        heading: "QTY",
                        field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "" : <Highlight variant="success">{rowData.quantity}</Highlight>),
                        important: true,
                        alignment: "center",
                        sizeToContent: true,
                    },
                    {
                        heading: "ALLOC",
                        field: (rowData) =>
                            rowData.productId <= 0 || rowData?.bundleId || rowData?.srv === 1 ? (
                                rowData.bundleId ? (
                                    `-`
                                ) : (
                                    ``
                                )
                            ) : rowData.allocationPending === 1 ? (
                                <Highlight variant={"success"}>0</Highlight>
                            ) : rowData.productId <= 0 && !rowData?.bundleId ? (
                                ""
                            ) : (
                                <Highlight
                                    variant={rowData?.ls === "Invoiced" || rowData.ls === "Despatched" || rowData?.efs === "Invoiced" || rowData?.efs === "Despatched" || parseInt(rowData.quantityDespatched) + parseInt(rowData.qtyAllocated) === parseInt(rowData.quantity) ? "success" : "error"}
                                >
                                    {rowData.qtyAllocated}
                                </Highlight>
                            ),
                        important: true,
                        alignment: "center",
                        sizeToContent: true,
                    },
                    {
                        heading: "POP",
                        field: (rowData) =>
                            rowData.productId <= 0 || rowData?.bundleId || rowData?.srv === 1 ? (
                                rowData.bundleId ? (
                                    `-`
                                ) : (
                                    ``
                                )
                            ) : rowData.allocationPending === 1 ? (
                                <Highlight variant={"success"}>0</Highlight>
                            ) : rowData.productId <= 0 && !rowData?.bundleId ? (
                                ""
                            ) : (
                                <Highlight variant={rowData?.ls === "Invoiced" || rowData.ls === "Despatched" || rowData?.efs === "Invoiced" || rowData?.efs === "Despatched" || (parseInt(rowData.qtyPreOrdered) === 0 && parseInt(rowData.qtyAllocated) > 0) ? "success" : "error"}>
                                    {rowData.qtyPreOrdered}
                                </Highlight>
                            ),
                        important: true,
                        alignment: "center",
                        sizeToContent: true,
                    },
                    {
                        heading: "DESP",
                        field: (rowData) => (rowData.productId <= 0 || rowData?.bundleId || rowData?.srv === 1 ? rowData.bundleId ? `-` : `` : <Highlight variant={rowData.quantity === rowData.quantityDespatched ? "success" : "error"}>{rowData.quantityDespatched}</Highlight>),
                        important: true,
                        alignment: "center",
                        sizeToContent: true,
                    },
                    {
                        heading: "INV",
                        field: (rowData) => (rowData.productId <= 0 || rowData?.bundleId || rowData?.srv === 1 ? rowData.bundleId ? `-` : `` : <Highlight variant={rowData.quantity === rowData.quantityInvoiced ? "success" : "error"}>{rowData.quantityInvoiced}</Highlight>),
                        important: true,
                        alignment: "center",
                        sizeToContent: true,
                    }
                );
            } else {
                cols.push({
                    heading: "Quantity",
                    field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? `` : rowData.quantity),
                    important: true,
                    alignment: "right",
                    sizeToContent: true,
                });
            }

            cols.push({
                heading: "Trade Price",
                field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "" : currencyFormat.format(rowData.price)),
                sizeToContent: true,
                alignment: "right",
                tooltip: (rowData) =>
                    (rowData?.bundle_check?.actualTradePrice || rowData.productData?.product_price || rowData.currentPrice) && rowData.price !== (rowData?.bundle_check?.actualTradePrice ?? rowData.productData?.product_price ?? rowData.currentPrice)
                        ? `Current Trade Price: £${rowData?.bundle_check?.actualTradePrice ?? rowData.productData?.product_price ?? rowData.currentPrice}`
                        : undefined,
                cellProps: (rowData) => ({
                    className:
                        (rowData?.bundle_check?.actualTradePrice || rowData.productData?.product_price || rowData.currentPrice) && parseFloat(rowData?.bundle_data?.b?.ty === "Fixed Kit" ? rowData.subTotal : rowData.price) !== parseFloat(rowData?.bundle_check?.actualTradePrice ?? rowData.productData?.product_price ?? rowData.currentPrice)
                            ? "textError"
                            : undefined,
                    style: {
                        color: rowData?.parent > 0 ? "rgba(0,0,0,0.6)" : undefined,
                        fontStyle: rowData?.parent > 0 ? "italic" : undefined,
                        fontSize: rowData?.parent > 0 ? "0.8rem" : undefined,
                    },
                }),
            });

            if (!od) {
                cols.push({
                    heading: "Discount %",
                    field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? `` : `${parseFloat(rowData.discountPercent).toFixed(2)}%`),
                    sizeToContent: true,
                    alignment: "right",
                    cellProps: (rowData) => ({
                        style: {
                            color: rowData?.parent > 0 ? "rgba(0,0,0,0.6)" : undefined,
                            fontStyle: rowData?.parent > 0 ? "italic" : undefined,
                            fontSize: rowData?.parent > 0 ? "0.8rem" : undefined,
                        },
                    }),
                });
            }

            cols.push(
                {
                    heading: "Discount",
                    field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "" : currencyFormat.format(rowData.discount)),
                    tooltip: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? undefined : od ? <strong>Discounted by {`${parseFloat(rowData.discountPercent).toFixed(2)}%`}</strong> : undefined),
                    sizeToContent: true,
                    alignment: "right",
                    cellProps: (rowData) => ({
                        style: {
                            color: rowData?.parent > 0 ? "rgba(0,0,0,0.6)" : undefined,
                            fontStyle: rowData?.parent > 0 ? "italic" : undefined,
                            fontSize: rowData?.parent > 0 ? "0.8rem" : undefined,
                        },
                    }),
                },
                {
                    heading: "Unit Price",
                    field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "" : currencyFormat.format(rowData.subTotal)),
                    sizeToContent: true,
                    alignment: "right",
                    cellProps: (rowData) => ({
                        style: {
                            color: rowData?.parent > 0 ? "rgba(0,0,0,0.6)" : undefined,
                            fontStyle: rowData?.parent > 0 ? "italic" : undefined,
                            fontSize: rowData?.parent > 0 ? "0.8rem" : undefined,
                        },
                    }),
                },
                {
                    heading: "Net Total",
                    field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "" : currencyFormat.format(rowData.total)),
                    sizeToContent: true,
                    alignment: "right",
                    cellProps: (rowData) => ({
                        style: {
                            color: rowData?.parent > 0 ? "rgba(0,0,0,0.6)" : undefined,
                            fontStyle: rowData?.parent > 0 ? "italic" : undefined,
                            fontSize: rowData?.parent > 0 ? "0.8rem" : undefined,
                        },
                    }),
                }
            );

            if (!od) {
                cols.push({
                    heading: "Margin",
                    field: (rowData) =>
                        rowData.productId <= 0 && !rowData?.bundleId
                            ? ""
                            : useAccurateMargins ? `${parseFloat(rowData?.margin ?? 0).toFixed(2)}%` : `${rowData.subTotal !== "0.00" ? (((rowData.price - rowData.discount - (rowData.productData?.product_cost ?? rowData.productData?.default_rate?.rate_cost ?? rowData.currentCost ?? rowData.cost)) / (rowData.price - rowData.discount)) * 100).toFixed(2) : "0.00"}%`,
                    sizeToContent: true,
                    alignment: "right",
                    cellProps: (rowData) => ({
                        style: {
                            color: rowData?.parent > 0 ? "rgba(0,0,0,0.6)" : undefined,
                            fontStyle: rowData?.parent > 0 ? "italic" : undefined,
                            fontSize: rowData?.parent > 0 ? "0.8rem" : undefined,
                        },
                    }),
                });
            }

            if (od) {
                if (od?.od_type === "WH") {
                    cols.push({
                        heading: "Picked",
                        field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "-" : rowData?.pick?.s ?? 0),
                        // tooltip: rowData => rowData.productId <= 0 && !rowData?.bundleId ? undefined : <strong>{`${rowData?.pick ? `Picked - ${moment(rowData.pick?.d, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}${rowData.pick?.s === 1 ? ` (Successful)` : ` (Stock Availability Issue)`}` : `Not Picked Yet`}`}</strong>,
                        fieldFormat: "boolean",
                        sizeToContent: true,
                        alignment: "center",
                    });
                    // cols.push(
                    //     {
                    //         heading: 'Checked',
                    //         field: rowData => rowData.productId <= 0 && !rowData?.bundleId ? '-' : (rowData?.check?.s ?? 0),
                    //         tooltip: rowData => rowData.productId <= 0 && !rowData?.bundleId ? '-' : <strong>{`${rowData?.check ? `Checked - ${moment(rowData.check?.d, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}${rowData.check?.s === 1 ? ` (Successful)` : ` (Stock Availability Issue)`}` : `Not Checked Yet`}`}</strong>,
                    //         fieldFormat: 'boolean',
                    //         sizeToContent: true,
                    //         alignment: 'center'
                    //     }
                    // )
                    cols.push({
                        heading: "Checked",
                        field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "-" : rowData?.odp_status === "Invoiced" || rowData?.odp_status === "Despatched" ? true : false),
                        // tooltip: rowData => rowData.productId <= 0 && !rowData?.bundleId ? '-' : <strong>{`${rowData?.odp_status === "Invoiced" || rowData?.odp_status === "Despatched" ? `Despatched - ${moment(rowData.despatch?.desp_completed_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}` : `Not Despatched Yet`}`}</strong>,
                        fieldFormat: "boolean",
                        sizeToContent: true,
                        alignment: "center",
                    });
                    // cols.push(
                    //     {
                    //         heading: 'Invoiced',
                    //         field: rowData => rowData.productId <= 0 && !rowData?.bundleId ? '-' : (rowData?.odp_status === "Invoiced" ? true : false),
                    //         fieldFormat: 'boolean',
                    //         sizeToContent: true,
                    //         alignment: 'center'
                    //     }
                    // )
                } else {
                    cols.push({
                        heading: "Confirmed",
                        field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "-" : od?.od_type === "WH" ? rowData.isAllocated ?? 1 : od?.od_confirmed),
                        fieldFormat: "boolean",
                        sizeToContent: true,
                        alignment: "center",
                    });
                    //     cols.push(
                    //         {
                    //             heading: 'Despatched',
                    //             field: rowData => rowData.productId <= 0 && !rowData?.bundleId ? '-' : (rowData?.odp_status === "Invoiced" || rowData?.odp_status === "Despatched" ? true : false),
                    //             fieldFormat: 'boolean',
                    //             sizeToContent: true,
                    //             alignment: 'center'
                    //         }
                    //     )
                }
            }

            if (hasDeliveries && showAssignedDelivery) {
                cols.push({
                    heading: "Delivery",
                    field: (rowData) => (rowData.productId <= 0 && !rowData?.bundleId ? "" : rowData.odIdx ? `#${order?.order_reference}/${rowData.odIdx}` : `-`),
                    sizeToContent: true,
                });
            }

            if (!readOnly) {
                cols.push({
                    actions: (rowData) => {
                        return [
                            {
                                name: "View Product",
                                disabled: rowData.productId <= 0 || rowData?.bundleId || (ifm && (rowData?.efs === "Invoiced" || rowData?.efs === "Despatched")),
                                icon: "binoculars",
                                onClick: () => handleProductInfoOpen(rowData.productId),
                            },
                            {
                                name: "Modify",
                                disabled: ifm && (rowData?.efs === "Invoiced" || rowData?.efs === "Despatched"),
                                icon: "pencil",
                                color: formErrors && formErrors["productRows|" + rowData.rowIdx] && "error",
                                onClick: (rowData) => handleEditProductRow(rowData),
                                doubleClick: true,
                            },
                            { name: "Remove ", disabled: ifm && (rowData?.efs === "Invoiced" || rowData?.efs === "Despatched"), icon: "trash-alt", onClick: (rowData) => handleProductRemove(rowData.rowIdx) },
                        ];
                    },
                });
            } else if (!od && !odIdx) {
                cols.push({
                    actions: (rowData) => {
                        return [
                            {
                                name: `View ${rowData.bundleId ? `Bundle` : `Product`}`,
                                icon: "binoculars",
                                onClick: () => {
                                    rowData.bundleId ? handleBundleOpen(rowData) : handleProductInfoOpen(rowData.productId);
                                },
                                disabled: rowData.productId <= 0 && !rowData?.bundleId,
                            },
                        ];
                    },
                });
            }
    }

    return (
        <Grid container>
            {handleProductAdd && (
                <Grid item xs={12}>
                    <Box mb={3}>
                        <Grid container spacing={3} justify="space-between" alignItems="center">
                            <Grid item xs={6}>
                                <Button onClick={handleDeployAddText} variant="text">
                                    <FAIcon icon="text" size={13.5} button />
                                    Add Text
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container alignItems="center" justify="flex-end">
                                    {document === "Quote" && (
                                        <Grid item>
                                            <Box mr={(access.manualDiscount || access.manualDiscountAny) && 3}>
                                                <FormControlLabel
                                                    control={<Switch color="primary" checked={formData.hot} onChange={handleCheckedChange} value="1" name="hot" />}
                                                    label={
                                                        <Typography variant="body2" color="textSecondary" className="fw-400">
                                                            "Hot" Quote
                                                        </Typography>
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                    )}
                                    {(access.manualDiscount || access.manualDiscountAny) && (
                                        <>
                                            <Grid item>
                                                <Typography variant="body2" color="textSecondary" className="fw-400">
                                                    {document} Discount {!_.isEmpty(formData.blanketDiscount) && formData.blanketDiscount}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <FAIcon type="light" icon="percentage" size="small" className="ml-1" noMargin />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    className="ml-2"
                                                    name="blanketDiscount"
                                                    value={blanketDiscount}
                                                    onChange={handleBlanketDiscountChange}
                                                    error={formErrors && formErrors["blanketDiscount"] && true}
                                                    helperText={formErrors && formErrors["blanketDiscount"]}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        className: "p-0 pl-1",
                                                        size: 5,
                                                        style: {
                                                            height: 30,
                                                        },
                                                    }}
                                                    margin="none"
                                                    size="small"
                                                    variant="filled"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button className="ml-2" onClick={handleApplyOrderDiscount} variant="text">
                                                    <FAIcon icon="check" size={13.5} button />
                                                    Apply
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={() => deployConfirmation(`Are you sure you want to reset all lines to their default discount rate?`, handleResetOrderDiscount)} variant="text">
                                                    <FAIcon icon="times" size={13.5} button />
                                                    Reset
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
            <Grid item xs={12}>
                <Paper elevation={inline && 0}>
                    <DataTable
                        config={{
                            key: "",
                            alternatingRowColours: true,
                            responsiveImportance: true,
                            pagination: false,
                            noResultsText: "No products have added yet",
                            noRowHover: readOnly,
                            draggable: !readOnly ? true : undefined,
                            draggableHandle: !readOnly ? true : undefined,
                            draggableRearrange: handleProductDrag ?? undefined,
                            selector: handleSetPrimaryIdx,
                            selectorIdx: selectorIdxValue,
                            rowProps: (rowData) => ({
                                style: {
                                    backgroundColor: readOnly && rowData?.parent > 0 ? "#fff" : undefined,
                                },
                            }),
                        }}
                        columns={cols}
                        rows={rows ?? formData?.newProductRows ?? formData?.productRows ?? []}
                    />
                </Paper>
            </Grid>
            {handleProductAdd && _.size(rows ?? formData?.newProductRows ?? formData?.productRows ?? []) > 4 && (
                <Grid item xs={12}>
                    <Box mt={3}>
                        <Button onClick={handleDeployAddText} variant="text">
                            <FAIcon icon="text" size={13.5} button />
                            Add Text
                        </Button>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default Products;
