import React from 'react';

import Search from 'Components/Common/Search/Search';

const ProductBinLocations = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/productsBySupplier',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.sup,
                        dataRef: 'sup',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Purchase Ref',
                        field: rowData => rowData.sgr,
                        dataRef: 'sgr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supplier Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.desc,
                        dataRef: 'desc',
                        important: true,
                        truncate: true
                    },
                    {
                        heading: 'List Price',
                        field: rowData => rowData.lp,
                        fieldFormat: 'currency',
                        dataRef: 'lp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Discount %',
                        field: rowData => rowData.d,
                        fieldFormat: 'percentage:2',
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Buy Price',
                        field: rowData => rowData.ubp,
                        fieldFormat: 'currency',
                        dataRef: 'ubp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Trade Price',
                        field: rowData => rowData.usp,
                        fieldFormat: 'currency',
                        dataRef: 'usp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Margin',
                        field: rowData => rowData.m,
                        fieldFormat: 'percentage:2',
                        dataRef: 'm',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Check P&A',
                        field: rowData => rowData.cp,
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        dataRef: 'cp',
                        important: true,
                        sizeToContent: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'products-by-supplier',
                noResultsText: 'Sorry, no products to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Products'],
                pdf: true,
                persistenceId: 'productsBySupplier',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showBrand: true,
                showCategory: true,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showRange: true,
                showSearch: false,
                showSupplier: true,
                showTotals: false,
                title: 'Products By Supplier Report'
            }}
        />
    )
}

export default ProductBinLocations;