import React  from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { DraggablePaper } from 'Functions/MiscFunctions';

export const SmallFormDialog = props => (
    <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth={(props.maxWidth && props.maxWidth) || 'sm'}
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        scroll="body"
        PaperComponent={DraggablePaper}
    >
        {props.title && (
             <DialogTitle id="draggable-control" style={{cursor: 'move'}} disableTypography><Typography variant="h6" gutterBottom>{props.title}</Typography></DialogTitle>
        )}
        <DialogContent style={{padding: `${props.title ? '0' : '24px'} 24px 24px 24px`}}>
            {props.content}
        </DialogContent>
    </Dialog>
)