import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from 'Constants';
import { handleTabChange, TabPanel } from 'Functions/MiscFunctions';

import AccCodeHistory from 'Components/Reporting/Customers/AccCodeHistory/AccCodeHistory';
import AccContactActivity from 'Components/Reporting/Customers/AccContactActivity/AccContactActivity';
import AccManagers from 'Components/Reporting/Customers/AccManagers/AccManagers';
import AverageTimeToPay from 'Components/Reporting/Customers/AverageTimeToPay/AverageTimeToPay';
import EulerCover from 'Components/Reporting/Customers/EulerCover/EulerCover';
import InactiveCustomers from 'Components/Reporting/Customers/InactiveCustomers/InactiveCustomers';
import LapsedAccounts from 'Components/Reporting/Customers/LapsedAccounts/LapsedAccounts';
import MaturedAccounts from 'Components/Reporting/Customers/MaturedAccounts/MaturedAccounts';
import NewProspects from 'Components/Reporting/Customers/NewProspects/NewProspects';

const initialState = () => ({
    access: {
        accCodeHistory: false,
        accContactActivity: false,
        avgTimeToPay: false,
        eulerCover: false,
        inactiveCustomers: false,
        maturedAccounts: false,
        lapsedCustomers: false,
        accManagers: false,
        newProspects: false,
    },
    currentTab: 0,
    isLoading: true,
    tabs: {
        0: "Account Code History Report",
        1: "Account Contact Activity Report",
        2: "Average Time To Pay Report",
        3: "Euler Cover Report",
        4: "Inactive Customers Report",
        5: "Matured Customer Report",
        6: "Lapsed Customer Report",
        7: "Proactive Responsibility Report",
        8: "New Prospects Report"
    },
    tabText: {
        0: "Historic data on customer account code changes",
        1: "Accounts that have not been contacted since a set date (1 year by default)",
        2: "Historic data on the average time to pay by customers",
        3: "Live data on the current euler cover in place for credit accounts",
        4: "Live data on inactive prepay customers",
        5: "Live data on new business customers who placed their first order over 90 days ago",
        6: "Live data on credit customers who have not placed an order in the last year",
        7: "Live data on customer proactive responsibility",
        8: "New prospects which have been added in the date range specified"
    },
})

class ReportingCustomers extends React.Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Reporting", "Customers"])
        this.loadComponentData()
    }

    loadComponentData = () => {
        API.multiAccess([
            'acc-code-history-report',
            'acc-con-activity-report',
            'avg-time-to-pay-report',
            'euler-cover-report',
            'inactive-customer-report',
            'matured-acc-report',
            'lapsed-customer-report',
            'acc-managers-report',
            'new-prospect-report',
        ])
        .then(([
            accCodeHistory,
            accContactActivity,
            avgTimeToPay,
            eulerCover,
            inactiveCustomers,
            maturedAccounts,
            lapsedCustomers,
            accManagers,
            newProspects
        ]) => {     
            this.setState({
                access: {
                    accCodeHistory,
                    accContactActivity,
                    avgTimeToPay,
                    eulerCover,
                    inactiveCustomers,
                    maturedAccounts,
                    lapsedCustomers,
                    accManagers,
                    newProspects
                },
                isLoading: false
            })
        })
    }

    render = () => {

        const { 
            classes, 
            ui 
        } = this.props;

        const { 
            access, 
            currentTab, 
            isLoading, 
            tabs, 
            tabText 
        } = this.state;

        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabs[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        {access.accCodeHistory && (
                                            <TabPanel value={currentTab} index={0}>
                                                <AccCodeHistory />
                                            </TabPanel>
                                        )}
                                        {access.accContactActivity && (
                                            <TabPanel value={currentTab} index={1}>
                                                <AccContactActivity />
                                            </TabPanel>
                                        )}
                                        {access.avgTimeToPay && (
                                            <TabPanel value={currentTab} index={2}>
                                                <AverageTimeToPay />
                                            </TabPanel>
                                        )}
                                        {access.eulerCover && (
                                            <TabPanel value={currentTab} index={3}>
                                                <EulerCover />
                                            </TabPanel>
                                        )}
                                        {access.inactiveCustomers && (
                                            <TabPanel value={currentTab} index={4}>
                                                <InactiveCustomers />
                                            </TabPanel>
                                        )}
                                        {access.maturedAccounts && (
                                            <TabPanel value={currentTab} index={5}>
                                                <MaturedAccounts />
                                            </TabPanel>
                                        )}
                                        {access.lapsedCustomers && (
                                            <TabPanel value={currentTab} index={6}>
                                                <LapsedAccounts />
                                            </TabPanel>
                                        )}
                                        {access.accManagers && (
                                            <TabPanel value={currentTab} index={7}>
                                                <AccManagers />
                                            </TabPanel>
                                        )}
                                        {access.newProspects && (
                                            <TabPanel value={currentTab} index={8}>
                                                <NewProspects />
                                            </TabPanel>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={classes.side}
                            style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}
                        >
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {access.accCodeHistory && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={0} 
                                        label="Account Code History" 
                                    />
                                )}
                                {access.accContactActivity && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={1} 
                                        label="Account Contact Activity" 
                                    />
                                )}
                                {access.avgTimeToPay && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={2} 
                                        label="Average Time To Pay" 
                                    />
                                )}
                                {access.eulerCover && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={3} 
                                        label="Euler Cover" 
                                    />
                                )}
                                {access.inactiveCustomers && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={4} 
                                        label="Inactive Customers" 
                                    />
                                )}
                                {access.lapsedCustomers && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={6} 
                                        label="Lapsed Customers" 
                                    />
                                )}
                                {access.maturedAccounts && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={5} 
                                        label="Matured Accounts" 
                                    />
                                )}
                                {access.newProspects && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={8} 
                                        label="New Prospects" 
                                    />
                                )}
                                {access.accManagers && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={7} 
                                        label="Proactive Responsibility" 
                                    />
                                )}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps, null)(withStyles(ViewPage)(ReportingCustomers));