import React from 'react';

import Search from 'Components/Common/Search/Search';

const StockCalculator = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/stockCalculator',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        important: true,
                        sizeToContent: true,
                        showTotalLabel: true
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.de,
                        dataRef: 'de',
                        important: true,
                        sizeToContent: true,
                        truncate: true
                    },
                    {
                        heading: 'Stocked',
                        field: rowData => rowData.si,
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        dataRef: 'si',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Consignment',
                        field: rowData => rowData.co,
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        dataRef: 'co',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.sup,
                        dataRef: 'sup',
                        important: true,
                    },
                    {
                        heading: 'Lead',
                        field: rowData => rowData.le,
                        alignment: 'right',
                        dataRef: 'le',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Buy Price',
                        field: rowData => rowData.uc,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        dataRef: 'uc',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Min Stock Level',
                        field: rowData => rowData.ml,
                        alignment: 'right',
                        dataRef: 'ml',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Current Stock',
                        field: rowData => rowData.cs,
                        alignment: 'right',
                        dataRef: 'cs',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Current Value',
                        field: rowData => rowData.tv,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        dataRef: 'tv',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Period Sales',
                        field: rowData => rowData.sls,
                        alignment: 'right',
                        dataRef: 'sls',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Average Daily',
                        field: rowData => rowData.avd,
                        alignment: 'right',
                        dataRef: 'avd',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Lead Time Demand',
                        field: rowData => rowData.ld,
                        alignment: 'right',
                        dataRef: 'ld',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Average Weekly',
                        field: rowData => rowData.avw,
                        alignment: 'right',
                        dataRef: 'avw',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Safety Stock',
                        field: rowData => rowData.sfty,
                        alignment: 'right',
                        dataRef: 'sfty',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'New Level',
                        field: rowData => rowData.nl,
                        alignment: 'right',
                        dataRef: 'nl',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'New Potential Value',
                        field: rowData => rowData.nv,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        dataRef: 'nv',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Difference',
                        field: rowData => rowData.df,
                        alignment: 'right',
                        dataRef: 'df',
                        important: true,
                        sizeToContent: true,
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'stock-calculator',
                noResultsText: 'Sorry, no products were found matching your search criteria',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Stock'],
                pdf: true,
                persistenceId: 'stockCalculator',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: false,
                showDate: false,
                showDateRange: true,
                defaultDateRange: 'lastYear',
                showFullTotals: false,
                showSearch: false,
                showStockStatus: false,
                showStockType: true,
                showSupplier: true,
                showTotals: false,
                title: 'Stock Calculator Report'
            }}
        />
    )
}

export default StockCalculator;