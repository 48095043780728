import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Tiles/Tile';
import TaskList from 'Components/Common/Tasks/TaskList';
import { currencyFormat } from 'Functions/MiscFunctions';

import Bulletin from 'Components/Dashboard/Common/Bulletin';
import Calendar from 'Components/Dashboard/Common/Calendar';
import SearchOrders from 'Components/Sales/SearchOrders/SearchOrders';

const AccountsDashboard = ({pageTitle}) => {

    const [data, setData] = useState(null);

    const loadComponentData = () => {
        API.get(`/dashboard`, {
            params: {
                d: 'salesAdmin'
            },
            props: {
                cancellation: true
            }
        })
        .then(res => {
            if(res?.data) {

                let newData = res.data,
                    labels = [],
                    totalOrders = [],
                    totalPO = [];
                    // totalNetOrders = [],
                    // totalNetPO = [];

                if(newData.chart) {

                    for (let i = 0, j = 11; i <= j; i++) {
                        totalOrders.push(res.data.chart?.[i]?.orders?.t);
                        totalPO.push(res.data.chart?.[i]?.po?.t);
                        // totalNetOrders.push(res.data.chart?.[i]?.orders?.v);
                        // totalNetPO.push(res.data.chart?.[i]?.po?.v);
                        labels.push(res.data.chart?.[i]?.label);
                    }
                    newData.chart = {
                        labels,
                        datasets: [
                            {
                                label: 'Orders',
                                data: totalOrders,
                                fill: false,
                                backgroundColor: '#2196F3',
                                borderColor: '#B3E5FC',
                                lineTension: 0.25,
                            },
                            // {
                            //     label: 'Net Order Value',
                            //     data: totalNetOrders,
                            //     backgroundColor: '#4CAF50',
                            //     borderColor: '#C8E6C9',
                            //     fill: false,
                            //     lineTension: 0.25,
                            // },
                            {
                                label: 'PO',
                                data: totalPO,
                                fill: false,
                                backgroundColor: '#673AB7',
                                borderColor: '#D1C4E9',
                                lineTension: 0.25,
                            },
                            // {
                            //     label: 'Net PO Value',
                            //     data: totalNetPO,
                            //     fill: false,
                            //     backgroundColor: '#F44336',
                            //     borderColor: '#FFCDD2',
                            //     lineTension: 0.25,
                            // },
                        ]
                    }
    
                }

                setData(newData);
            }
        });
    }

    useEffect(() => {
        pageTitle?.([0, 'Dashboard', 'Sales Admin']);
        loadComponentData();
    }, [pageTitle]);
    
    return (
        (!data && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12} xl={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Weekly Sales
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='pound-sign'
                                                title="Target"
                                                content={currencyFormat.format(data?.stats?.ta?.w)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tooltip 
                                                title={(
                                                    <>
                                                        <Typography variant="body1" className="fw-400">
                                                            Breakdown
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Not Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.w?.ni)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.w?.to)}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <strong>Credits: </strong> {currencyFormat.format(data?.stats?.sa?.w?.cr)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Total: </strong> {currencyFormat.format(data?.stats?.sa?.w?.gr)}
                                                        </Typography>
                                                    </>
                                                )}
                                            >
                                                <span>
                                                    <Tile
                                                        noPaper
                                                        icon='pound-sign'
                                                        title="Sales"
                                                        content={currencyFormat.format(data?.stats?.sa?.w?.gr)}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon={data?.stats?.ta?.w - data?.stats?.sa?.w?.gr < 0 ? 'check-circle' : 'exclamation-circle'}
                                                color={data?.stats?.ta?.w - data?.stats?.sa?.w?.gr < 0 ? '#4CAF50' : '#F44336'}
                                                title="Sales To Target"
                                                content={currencyFormat.format((data?.stats?.ta?.w - data?.stats?.sa?.w?.gr).toFixed(2))}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='calendar-day'
                                                title="Days Left"
                                                content={data?.stats?.wd?.w}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Monthly Sales
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='pound-sign'
                                                title="Target"
                                                content={currencyFormat.format(data?.stats?.ta?.m)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tooltip 
                                                title={(
                                                    <>
                                                        <Typography variant="body1" className="fw-400">
                                                            Breakdown
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Not Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.m?.ni)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.m?.to)}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <strong>Credits: </strong> {currencyFormat.format(data?.stats?.sa?.m?.cr)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Total: </strong> {currencyFormat.format(data?.stats?.sa?.m?.gr)}
                                                        </Typography>
                                                    </>
                                                )}
                                            >
                                                <span>
                                                    <Tile
                                                        noPaper
                                                        icon='pound-sign'
                                                        title="Sales"
                                                        content={currencyFormat.format(data?.stats?.sa?.m?.gr)}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon={data?.stats?.ta?.m - data?.stats?.sa?.m?.gr < 0 ? 'check-circle' : 'exclamation-circle'}
                                                color={data?.stats?.ta?.m - data?.stats?.sa?.m?.gr < 0 ? '#4CAF50' : '#F44336'}
                                                title="Sales To Target"
                                                content={currencyFormat.format((data?.stats?.ta?.m - data?.stats?.sa?.m?.gr).toFixed(2))}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='calendar-day'
                                                title="Days Left"
                                                content={data?.stats?.wd?.m}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Sales Orders On Hold
                                    </Typography>
                                    <SearchOrders status='On Hold' noPersistence onHold inline compact dashboard />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Sales Orders To Chase
                                    </Typography>
                                    <SearchOrders status='Awaiting Supplier' noPersistence checkDatePast inline compact dashboard />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Outstanding Return Credits
                                    </Typography>
                                    {(!_.isEmpty(data?.returns) && (
                                        <DataTable
                                            config={{
                                                noResultsText: "No outstanding return credits to display",
                                                noRowHover: true
                                            }}
                                            columns={[
                                                {
                                                    heading: 'RMA Number',
                                                    field: rowData => rowData.r,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Company',
                                                    field: rowData => rowData.n,
                                                    truncate: true
                                                },
                                                {
                                                    heading: 'Product',
                                                    field: rowData => rowData.p,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Returned On',
                                                    field: rowData => rowData.c,
                                                    fieldFormat: 'date',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Total Credit',
                                                    field: rowData => rowData.t,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'View', icon: 'binoculars', link: `/rma/${rowData.i}`}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={data?.returns ?? []} 
                                        />
                                    )) || (
                                        <Alert severity="success" variant="outlined">
                                            <strong>None - </strong>There are currently no outstanding return credits
                                        </Alert>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Operational Insight
                                    </Typography>
                                    <Box height={400} width={'100%'} maxWidth={'100%'} minHeight={300}>
                                        <Line 
                                            data={data.chart} 
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom"
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0
                                                        }
                                                    }]
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Actions Due Today
                                    </Typography>
                                    <TaskList />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Bulletin />
                        </Grid>
                        <Grid item xs={12}>
                            <Calendar />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

export default AccountsDashboard;