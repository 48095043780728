import React from 'react';

import StatusChip from 'Components/Common/Chips/StatusChip';

import Search from 'Components/Common/Search/Search';

const LoginHistory = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/login',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Date',
                        dataRef: 'audit_date_time',
                        field: rowData => rowData.audit_date_time,
                        important: true,
                        sizeToContent: true,
                        fieldFormat: 'datetime'
                    },
                    {
                        heading: 'IP Address',
                        dataRef: 'audit_ip_address',
                        field: rowData => rowData.audit_ip_address,
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Email Address',
                        dataRef: 'audit_email',
                        field: rowData => rowData.audit_email,
                        important: true,
                        main: true,
                    },
                    {
                        heading: 'Type',
                        dataRef: 'audit_type',
                        field: rowData => <StatusChip status={rowData.audit_type} />,  
                        sizeToContent: true,  
                    },
                    {
                        heading: 'Login Success',
                        dataRef: 'audit_login_success',
                        field: rowData => rowData.audit_login_success,          
                        fieldFormat: 'boolean',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'IP Blocked?',
                        dataRef: 'audit_ip_blocked',
                        field: rowData => rowData.audit_ip_blocked,   
                        fieldFormat: 'boolean',                 
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'audit_id',
                name: 'login-history',
                noResultsText: 'Sorry, there is no login history to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Staff'],
                pdf: true,
                persistenceId: 'loginHistory',
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showTotals: false,
                title: 'Login History Report'
            }}
        />
    )
}

export default LoginHistory;