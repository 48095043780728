import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import SmsDialog from 'Components/Communication/Misc/SmsDialog';
import ViewSms from 'Components/Communication/Misc/ViewSms';
import StaffChip from 'Components/Common/Chips/StaffChip';

const initialState = {
    access: {
        smsSend: false,
    },
    searchResults: {},
    searchString: '',
    dataLoading: true,
}

class SmsLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentDidMount = () => {
        if(this.props.filter) {
            this.getSmsLog(true);
        } else {
            API.access('sms-send')
            .then(res => {
                this.setState({
                    access: {
                        smsSend: res?.data?.has_access ?? false
                    }
                }, () => {
                    this.getSmsLog(true);
                })
            })
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getSmsLog = dataLoading => {
        let params = {
            searchString: this.state.searchString
        } 
        if(this.props.selectedStaff) {
            params = {
                ...params,
                staff: this.props.selectedStaff
            }
        }
        if(this.props.filter) {
            params = {
                ...params,
                filter: this.props.filter,
                filterId: this.props.filterId
            }
        }
        this.setState({
            dataLoading,
        }, () => {
            API.get('/sms', {
                props: {
                    cancellation: true
                },
                params
            })
            .then(result => {
                if(result?.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data,
                    });
                }
            });
        });
    }
    
    handleDeployComposeSms = (id, defaultFormData) => this.props.deployDialog(
        <SmsDialog
            callback={this.getSmsLog}
            id={id ?? false}
            defaultFormData={defaultFormData}
            type='default'
        />, false, "Compose SMS", "standard","sm");

    handleDeployViewSms = id => this.props.deployDialog(
        <ViewSms
            id={id}
            closeDialog={this.props.closeDialog}
            resend={this.handleDeployComposeSms}
        />, false, "", "standard","sm");

    onSearchStringChange = event => {
        this.setState({
            searchString: event?.target?.value ?? ''
        },
        () => {
            if(this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => this.getSmsLog(true), 200);
        });
    }

    render() {
        const { sendSms } = this.props;
        const { access, dataLoading, searchResults, searchString } = this.state;
        return (
            <Grid container spacing={1}>      
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} sm={6} md={4} style={{marginBottom: 12}}>
                        <TextField
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder='Search:'
                            value={searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                            }}
                        />
                    </Grid>    
                    {access.smsSend && !this.props.filter && (
                        <Grid item xs={12} sm={6} md={8} align='right'>
                            <Button 
                                color="primary"
                                size="small"
                                variant="text"
                                onClick={() => this.handleDeployComposeSms(false, undefined)}
                            >
                                <FAIcon type="light" icon="mobile" size={15} button />
                                Send SMS
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable
                            config={{
                                key: 'sms_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                options: {
                                    headingInput: (
                                        sendSms && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                onClick={sendSms}
                                            >
                                                <FAIcon type="light" icon="plus-circle" size={15} button />
                                                Compose SMS
                                            </Button>
                                        )
                                    ),
                                    dataRef: true,
                                    export: {
                                        title: `SMS Log`,
                                        name: `SMS-Log`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Recipient',
                                    field: rowData => rowData.sms_recipient,
                                    dataRef: 'sms_to',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Sender',
                                    field: rowData => rowData.sms_from,
                                    dataRef: 'sms_from',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Subject',
                                    field: rowData => rowData.sms_type,
                                    dataRef: 'sms_type',
                                    main: true
                                },
                                {
                                    heading: 'Processed By',
                                    field: rowData => rowData.sms_author_staff_id === 0 ? 'Automated' : <StaffChip staff={this.props.staff?.[rowData.sms_author_staff_id] ?? '-'} />,
                                    dataRef: 'sms_author_staff_id',
                                    sizeToContent: true,
                                    fieldFormat: 'staff',
                                },
                                {
                                    heading: 'Sent',
                                    field: rowData => rowData.sms_created_datetime,
                                    fieldFormat: 'datetime',
                                    dataRef: 'sms_created_datetime',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'binoculars', onClick: () => this.handleDeployViewSms(rowData.sms_id)}
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default SmsLog;