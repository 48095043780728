import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";

import { Avatar, Box, Card, Grid, IconButton, Paper, TextField, Typography } from "@material-ui/core";

import Alert from "Components/Common/Alert/Alert";
import AppButton from "Components/Common/Buttons/AppButton";
import API from "API";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import DeliverySummary from "Components/Sales/Misc/DeliverySummary";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import logo from "Assets/Images/no-img.jpg";
import PrintJobDialog from "Components/Common/Dialogs/PrintJobDialog";
import Textarea from "Components/Common/Inputs/Textarea";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import StackTable from "Components/Common/DataTables/StackTable";
import { isNumeric } from "Functions/MiscFunctions";
import { CLOUDFRONT_URL } from "Constants";

const PickDialog = ({
    closeDialog,
    deployConfirmation,
    handlePickModification,
    handlePickIssue,
    handlePickNextLine,
    line,
    lines,
    pickQty,
}) => {
    const [status, setStatus] = useState("stockMissing");

    const [qtyPicked, setQtyPicked] = useState("");

    const pickActuallySuccessful = parseInt(lines[line].quantity) === parseInt(qtyPicked);

    const btnDisabled =
        status === "" || qtyPicked < 0 || qtyPicked > parseInt(lines[line].quantity) || qtyPicked === "";

    const handlePickQty = (e) => {
        if (_.isEmpty(e?.target?.value)) {
            setQtyPicked("");
            return;
        }

        const picked = parseInt(e?.target?.value ?? 0),
            required = parseInt(lines[line].quantity);

        if (picked > required || picked < 0) {
            setQtyPicked("");
        } else {
            setQtyPicked(picked);
        }
    };

    const handleSubmit = () => {
        if (handlePickModification) {
            let modifiedStatus = parseInt(lines[line].quantity) === qtyPicked ? "success" : status;
            deployConfirmation(`You have picked ${qtyPicked} for this line?`, () => {
                handlePickModification(line, qtyPicked, modifiedStatus);
                closeDialog();
            });
        } else {
            if (pickActuallySuccessful) {
                deployConfirmation(`You have picked ${lines[line].quantity} for this line?`, () => {
                    handlePickNextLine();
                    closeDialog();
                });
            } else {
                deployConfirmation(`You have picked ${qtyPicked} for this line?`, () => {
                    handlePickIssue(qtyPicked, status);
                    closeDialog();
                });
            }
        }
    };
    useEffect(() => {
        if (pickQty !== null && pickQty !== undefined && pickQty !== "") {
            const picked = parseInt(pickQty),
                required = parseInt(lines[line].quantity);

            if (picked > required || picked < 0) {
                setQtyPicked("");
            } else {
                setQtyPicked(picked);
            }
        }
    }, [pickQty, lines, line]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    variant="body1"
                    align="center"
                    className="fw-500"
                    style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                >
                    {lines[line].productName}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {(pickActuallySuccessful && (
                    <Alert severity="success">
                        <span className="fw-400">Pick Complete</span>
                    </Alert>
                )) || (
                    <Alert severity="error">
                        <span className="fw-400">
                            {qtyPicked !== ""
                                ? parseInt(lines[line].quantity) - parseInt(qtyPicked)
                                : lines[line].quantity}
                            x Not available ({lines[line].whBin})
                        </span>
                    </Alert>
                )}
            </Grid>
            <Grid item xs={5}>
                <TextField
                    label="Quantity Picked *"
                    value={qtyPicked}
                    onChange={handlePickQty}
                    margin="none"
                    fullWidth
                    // type="number"
                    inputProps={{
                        size: 4,
                        pattern: "[0-9]*",
                        inputMode: "none",
                        height: 42,
                    }}
                    InputProps={{
                        style: {
                            height: 42,
                        },
                    }}
                    autoFocus
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={7}>
                {(!pickActuallySuccessful && (
                    <AutoCompleteSelect
                        options={[
                            {
                                label: "Stock Missing",
                                value: "stockMissing",
                            },
                            {
                                label: "Stock Damaged",
                                value: "stockDamaged",
                            },
                            {
                                label: "Other",
                                value: "other",
                            },
                        ]}
                        label="Reason *"
                        isSearchable={false}
                        noDefaultSort
                        noClear
                        value={status}
                        onChange={(v) => setStatus(v?.value ?? "")}
                        margin="none"
                        variant="outlined"
                    />
                )) || (
                    <AutoCompleteSelect
                        options={[
                            {
                                label: "Pick Complete",
                                value: "success",
                            },
                        ]}
                        label="Status *"
                        isSearchable={false}
                        noDefaultSort
                        noClear
                        value={"success"}
                        margin="none"
                        variant="outlined"
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <AppButton
                    disabled={btnDisabled}
                    onClick={handleSubmit}
                    primary
                    color={btnDisabled ? `#bbb` : `#fff`}
                    className={btnDisabled ? `btn btn-disabled` : `btn btn-success`}
                    icon="check"
                    text={pickActuallySuccessful ? `Confirm Pick` : `Confirm Pick Issue`}
                />
            </Grid>
            <Grid item xs={12}>
                <AppButton
                    onClick={closeDialog}
                    back
                    color="#fff"
                    className="btn btn-error"
                    icon="times"
                    text="Cancel"
                />
            </Grid>
        </Grid>
    );
};

const initialState = {
    complete: false,
    currentPickQty: "",
    isLoading: false,
    line: 0,
    lines: [],
    notes: "",
    pickStarted: null,
    pickStatus: {},
    pickCompleted: null,
    printing: false,
};

class Picking extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.qtyInput = React.createRef();
    }

    componentDidMount = () => {
        const { od, currentTab } = this.props;

        this.setState({
            lines: _.orderBy(
                _.filter(od.products, (_el) => _el.productId > 0 && _el.service === 0),
                (el) => el.whBin
            ),
            pickStatus: {},
            pickStarted: currentTab === 2 ? moment().format("YYYY-MM-DD HH:mm:ss") : null,
        });
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.currentTab !== 2 && this.props.currentTab === 2 && !this.state.pickStarted) {
            this.setState({
                pickStarted: moment().format("YYYY-MM-DD HH:mm:ss"),
            });
        }
    };

    handleDeployPickIssue = (picked) => {
        const { line, lines } = this.state,
            { closeDialog, deployConfirmation, deployDialog } = this.props;

        this.props.playSound("pickError", this.state.line);

        deployDialog(
            <PickDialog
                closeDialog={closeDialog}
                deployConfirmation={deployConfirmation}
                handlePickNextLine={this.handlePickNextLine}
                handlePickIssue={this.handlePickIssue}
                line={line}
                lines={lines}
                pickQty={picked}
            />,
            false,
            `${lines[line].productCode}`,
            "standard",
            "xs",
            false,
            true
        );
    };

    handleDeployPickModification = (line) => {
        const { lines } = this.state,
            { closeDialog, deployConfirmation, deployDialog } = this.props;

        deployDialog(
            <PickDialog
                closeDialog={closeDialog}
                deployConfirmation={deployConfirmation}
                handlePickModification={this.handlePickModification}
                line={line}
                lines={lines}
            />,
            false,
            `${lines[line].productCode}`,
            "standard",
            "xs",
            false,
            true
        );
    };

    handlePickComplete = () => {
        const { deployConfirmation, playSound } = this.props;

        deployConfirmation(`Are you sure you want to confirm this pick?`, () => {
            playSound("pickComplete", this.state.line);
            this.handleSubmit();
        });
    };

    handlePickIssue = (qtyPicked, status) => {
        const { line, lines } = this.state,
            { playSound } = this.props;

        let { pickStatus } = this.state;

        pickStatus = {
            ...pickStatus,
            [line]: {
                ...pickStatus?.[line],
                idx: line,
                line: lines[line],
                pickTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                qtyPicked,
                status,
            },
        };

        this.setState(
            {
                currentPickQty: "",
                pickStatus,
            },
            () => {
                if (line + 1 === _.size(lines)) {
                    this.setState(
                        {
                            complete: true,
                        },
                        () => {
                            playSound("pickSuccess", this.state.line);
                        }
                    );
                } else {
                    this.setState(
                        {
                            line: line + 1,
                        },
                        () => {
                            this.handleFocusQtyField();
                            playSound("pickSuccess", this.state.line);
                        }
                    );
                }
            }
        );
    };

    handlePickModification = (line, qtyPicked, status) => {
        const { lines } = this.state;

        let { pickStatus } = this.state;

        pickStatus = {
            ...pickStatus,
            [line]: {
                ...pickStatus?.[line],
                idx: line,
                line: lines[line],
                qtyPicked,
                status,
            },
        };

        this.setState({
            pickStatus,
        });
    };

    handlePickNextLine = () => {
        const { line, lines } = this.state,
            { playSound } = this.props;

        let { pickStatus } = this.state;

        pickStatus = {
            ...pickStatus,
            [line]: {
                ...pickStatus?.[line],
                idx: line,
                line: lines[line],
                pickTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                qtyPicked: lines[line].quantity,
                status: "success",
            },
        };

        this.setState(
            {
                currentPickQty: "",
                pickStatus,
            },
            () => {
                if (line + 1 === _.size(lines)) {
                    this.setState(
                        {
                            complete: true,
                        },
                        () => {
                            playSound("pickSuccess", this.state.line);
                        }
                    );
                } else {
                    this.setState(
                        {
                            line: line + 1,
                        },
                        () => {
                            this.handleFocusQtyField();
                            playSound("pickSuccess", this.state.line);
                        }
                    );
                }
            }
        );
    };

    handlePickNotes = (e) => {
        this.setState({
            notes: e?.target?.value ?? "",
        });
    };

    handlePickPreviousLine = () => {
        const { complete, line } = this.state;

        if (line === 0 && !complete) {
            this.setState(
                {
                    currentPickQty: "",
                },
                () => {
                    this.props.handleTabChange(0);
                }
            );
        } else {
            this.setState(
                {
                    currentPickQty: "",
                    complete: false,
                    line: complete ? line : line - 1,
                },
                () => {
                    this.handleFocusQtyField();
                }
            );
        }
    };

    handlePickQuantity = (e) => {
        let value = e.target.value ?? "";
        if (!_.isEmpty(value)) {
            if (!isNumeric(value)) {
                value = "";
            }
        }
        this.setState({
            currentPickQty: value,
        });
    };

    handlePickInput = (expected, actual) => {
        if (actual === expected) {
            this.handlePickNextLine();
        } else {
            this.handleDeployPickIssue(actual);
        }
    };

    handleSubmit = () => {
        const { deployDialog, id, od } = this.props;

        this.setState(
            {
                isLoading: true,
                pickCompleted: moment().format("YYYY-MM-DD HH:mm:ss"),
            },
            () => {
                const { notes, pickStarted, pickStatus, pickCompleted } = this.state;

                API.put(`/warehouse/delivery/${id}/pick`, {
                    notes,
                    pickStatus,
                    pickStarted,
                    pickCompleted,
                }).then((res) => {
                    if (res?.data) {
                        if (res.data?.cancelled) {
                            this.handleErrorRedirect("cancelled");
                        } else if (res.data?.picked) {
                            this.handleErrorRedirect("picked by another user");
                        } else if (res.data?.hold) {
                            this.handleErrorRedirect("placed on hold");
                        } else {
                            this.setState(
                                {
                                    printing: true,
                                },
                                () => {
                                    if (res.data.onHold === 1) {
                                        deployDialog(
                                            <PrintJobDialog
                                                printJob={res.data.printJob}
                                                docTitle={`Hold Note`}
                                                docSubtitle={`Delivery #${od.od_reference}/${od.od_idx}`}
                                                onClose={this.handlePickingFailedComplete}
                                            />,
                                            true,
                                            "",
                                            "standard",
                                            "sm",
                                            false,
                                            true
                                        );
                                    } else {
                                        deployDialog(
                                            <PrintJobDialog
                                                printJob={res.data.printJob}
                                                docTitle={
                                                    res.data.collection === 1 ? "Collection Note" : "Despatch Note"
                                                }
                                                docSubtitle={`Delivery #${od.od_reference}/${od.od_idx}`}
                                                onClose={this.handlePickingComplete}
                                            />,
                                            true,
                                            "",
                                            "standard",
                                            "sm",
                                            false,
                                            true
                                        );
                                    }
                                }
                            );
                        }
                    }
                });
            }
        );
    };

    handlePickingComplete = () => {
        const { deploySnackBar, history, warehouseMode } = this.props;

        deploySnackBar(`success`, `Delivery successfully picked`);
        if (warehouseMode) {
            history.push(`/dashboard`);
        } else {
            history.push(`/warehouse/outgoing/awaiting-picking`);
        }
    };

    handlePickingFailedComplete = () => {
        const { deploySnackBar, history, warehouseMode } = this.props;

        deploySnackBar(`warning`, `Delivery referred to Sales Admin`);
        if (warehouseMode) {
            history.push(`/dashboard`);
        } else {
            history.push(`/warehouse/outgoing/awaiting-picking`);
        }
    };

    handleErrorRedirect = (reason) => {
        const { deploySnackBar, history, warehouseMode } = this.props;

        deploySnackBar(`error`, `This delivery has been ${reason}, contact Sales Admin for further advice`);
        if (warehouseMode) {
            history.push(`/dashboard`);
        } else {
            history.push(`/warehouse/outgoing/awaiting-picking`);
        }
    };

    handleFocusQtyField = () => {
        setTimeout(() => this.qtyInput.current.focus(), 50);
    };

    render = () => {
        const { complete, isLoading, line, lines, notes, pickStatus, printing } = this.state;
        const { currentTab, deployConfirmation, history, isPDA, order, od, warehouseMode } = this.props;
        return (
            (isLoading && ((printing && <React.Fragment />) || <LoadingCircle />)) ||
            (currentTab === 0 && (
                <Paper>
                    <Box p={3}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="h6">Notes</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <ViewNotes inline notes={order.notes} hideDeleteButton />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )) ||
            (currentTab === 1 && (
                <DeliverySummary
                    od={od}
                    order={order}
                    idx={_.findIndex(order.deliveries, (el) => el.od_id === od.od_id) + 1}
                />
            )) ||
            (warehouseMode && (
                <>
                    {(_.isEmpty(od.products) && (
                        <Grid item xs={12}>
                            <Alert severity="success" border>
                                No products to pick, please contact Sales Admin
                            </Alert>
                            <Box pt={3}>
                                <AppButton
                                    onClick={() => this.props.history.push("/dashboard")}
                                    text={`Main Menu`}
                                    back
                                />
                            </Box>
                        </Grid>
                    )) ||
                        (complete && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container justify="space-between">
                                        <Grid item xs={12} align="center">
                                            <Typography variant="h5" component="div" className="fw-500">
                                                Pick Overview
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {_.map(pickStatus, (p, idx) => (
                                    <Grid item xs={12} key={idx}>
                                        <Card>
                                            <Box p={2}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Avatar
                                                            variant="square"
                                                            alt={p.line?.product_name ?? "Product Image"}
                                                            src={
                                                                p.line?.image
                                                                    ? `${CLOUDFRONT_URL}${p.line?.image}`
                                                                    : p.line?.product?.default_image?.img_filekey
                                                                    ? `${CLOUDFRONT_URL}${p.line?.product.default_image.img_filekey}`
                                                                    : logo
                                                            }
                                                            style={{ width: 50, height: 50 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="h6" className="fw-400">
                                                            {p.line?.productCode}
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            className="fw-400"
                                                            component="div"
                                                        >
                                                            {p.line?.productName}
                                                        </Typography>
                                                        {p.line?.whBin && (
                                                            <Typography
                                                                variant="caption"
                                                                className="fw-300"
                                                                component="div"
                                                            >
                                                                Bin Location: {p.line?.whBin}
                                                            </Typography>
                                                        )}
                                                        {!_.isEmpty(p.line?.text) && (
                                                            <Box mt={0.5}>
                                                                <Typography variant="caption" component="div">
                                                                    <FAIcon
                                                                        icon="exclamation-circle"
                                                                        type="solid"
                                                                        className="textError fw-400 mr-1"
                                                                        size={13.5}
                                                                    />
                                                                    {p.line?.text}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                    <Grid item align="right">
                                                        <IconButton
                                                            onClick={() => this.handleDeployPickModification(p.idx)}
                                                        >
                                                            <FAIcon icon="pencil" size={15} button noMargin />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Alert severity={p.status === "success" ? `success` : `error`}>
                                                <Typography variant="body2" component="div" className="fw-500">
                                                    {/* Pick {p.status === 'success' ? `Successful` : `Issue`} */}
                                                    Picked {p.qtyPicked} / {p.line?.quantity}
                                                    {p.status !== "success"
                                                        ? ` - ${
                                                              p.status === "stockMissing"
                                                                  ? `Stock Missing`
                                                                  : p.status === "stockDamaged"
                                                                  ? "Stock Damaged"
                                                                  : "Other"
                                                          }`
                                                        : ``}
                                                </Typography>
                                                {/* <Typography variant="body1" component="div" className="fw-300">
                                                            Picked: {p.qtyPicked}/{p.line?.quantity}{p.status !== 'success' ? ` - ${p.status === 'stockMissing' ? `Stock Missing` : (p.status === 'stockDamaged' ? 'Stock Damaged' : 'Other')}` : ``}
                                                        </Typography> */}
                                            </Alert>
                                        </Card>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <Grid item xs={12} align="center">
                                        <Typography variant="body1" align="center" className="fw-400" paragraph>
                                            Notes
                                        </Typography>
                                        {_.some(pickStatus, (el) => el.status !== "success") && (
                                            <Box mb={2} align="left">
                                                <Alert severity="warning" variant="outlined">
                                                    <strong>Delivery On-Hold</strong>
                                                    <br />
                                                    Sales Admin will be notified to resolve availability issues with
                                                    this delivery.
                                                </Alert>
                                            </Box>
                                        )}
                                        <Paper elevation={0}>
                                            <Textarea
                                                margin="none"
                                                onChange={this.handlePickNotes}
                                                value={notes}
                                                variant="outlined"
                                                rows={1}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        className="btn btn-success"
                                        onClick={this.handlePickComplete}
                                        text="Confirm Pick"
                                        icon="check"
                                        primary
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        className="btn btn-error"
                                        onClick={() =>
                                            deployConfirmation(`Are you sure you want to cancel this pick?`, () =>
                                                history.push("/dashboard")
                                            )
                                        }
                                        text="Cancel Pick"
                                        icon="times"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={_.isEmpty(order.notes) && !complete && line === 0}
                                        onClick={this.handlePickPreviousLine}
                                        text="Back"
                                        icon="undo"
                                    />
                                </Grid>
                            </Grid>
                        )) ||
                        (lines[line] && (
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this.handlePickInput(
                                        parseInt(lines[line].quantity),
                                        parseInt(this.state.currentPickQty)
                                    );
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container justify="space-between">
                                            <Grid item xs={12} align="center">
                                                <Typography variant="h5" component="div" className="fw-500">
                                                    PICK {line + 1} / {_.size(lines)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card>
                                            <Box p={2}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} align="center">
                                                        <Avatar
                                                            variant="square"
                                                            alt={lines[line].product?.product_name ?? "Product Image"}
                                                            src={
                                                                lines[line].image
                                                                    ? `${CLOUDFRONT_URL}${lines[line].image}`
                                                                    : lines[line].product?.default_image?.img_filekey
                                                                    ? `${CLOUDFRONT_URL}${lines[line].product.default_image.img_filekey}`
                                                                    : logo
                                                            }
                                                            style={{ width: isPDA ? 50 : 75, height: isPDA ? 50 : 75 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant={isPDA ? "h4" : "h3"}
                                                            className={isPDA ? "fw-500" : "fw-300"}
                                                            align="center"
                                                        >
                                                            {lines[line].productCode}
                                                        </Typography>
                                                        <Box mt={!isPDA ? 2 : 0.5} mb={2}>
                                                            <Typography
                                                                variant={isPDA ? "body1" : "h6"}
                                                                align="center"
                                                                className="fw-300"
                                                                style={{
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                {lines[line].productName}
                                                            </Typography>
                                                        </Box>
                                                        {!_.isEmpty(lines[line]?.text) && (
                                                            <Box mt={-0.5}>
                                                                <Typography
                                                                    variant="caption"
                                                                    align="center"
                                                                    component="div"
                                                                >
                                                                    <FAIcon
                                                                        icon="exclamation-circle"
                                                                        type="solid"
                                                                        className="textError fw-400 mr-1"
                                                                        size={13.5}
                                                                    />
                                                                    {lines[line]?.text}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                        <Box mt={!isPDA ? 2 : 0.5} mb={2} textAlign="center">
                                                            <TextField
                                                                inputRef={this.qtyInput}
                                                                value={this.state.currentPickQty}
                                                                onChange={this.handlePickQuantity}
                                                                margin="none"
                                                                // type="number"
                                                                inputProps={{
                                                                    size: 4,
                                                                    pattern: "[0-9]*",
                                                                    style: {
                                                                        width: 61,
                                                                        height: 56,
                                                                        textAlign: "center",
                                                                        fontWeight: 400,
                                                                    },
                                                                    inputMode: "none",
                                                                }}
                                                                InputProps={{
                                                                    style: {
                                                                        width: 85,
                                                                        height: 80,
                                                                        fontSize: 26,
                                                                    },
                                                                }}
                                                                autoFocus
                                                                variant="filled"
                                                            />
                                                        </Box>
                                                        {lines[line].dt === 1 && (
                                                            <Box mt={-0.5}>
                                                                <Typography
                                                                    variant="caption"
                                                                    align="center"
                                                                    component="div"
                                                                >
                                                                    <FAIcon
                                                                        icon="exclamation-triangle"
                                                                        className="textWarning mr-1"
                                                                        size={13.5}
                                                                    />
                                                                    This stock was received today
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                    {(lines[line]?.bulky === 1 || lines[line]?.heavy === 1) && (
                                                        <Grid item xs={12}>
                                                            <Alert severity="warning" className="mb-1">
                                                                <strong>Please handle with care!</strong>
                                                                <br />
                                                                This product is{" "}
                                                                {lines[line]?.bulky === 1
                                                                    ? `large${lines[line]?.heavy === 1 ? ` and ` : ``}`
                                                                    : ``}
                                                                {lines[line]?.heavy === 1 ? `heavy` : ``}
                                                            </Alert>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Box>
                                            <Box p={isPDA ? 1.5 : 2} className="content-light-grey">
                                                <Grid container justify="space-between">
                                                    <Grid item>
                                                        <Typography
                                                            variant={isPDA ? "body1" : "h5"}
                                                            component="div"
                                                            className="fw-500"
                                                        >
                                                            QTY: {lines[line].quantity}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            variant={isPDA ? "body1" : "h5"}
                                                            component="div"
                                                            className="fw-500"
                                                        >
                                                            BIN: {lines[line].whBin}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {lines[line]?.card_order && (
                                                <>
                                                    <Box p={3}>
                                                        <StackTable
                                                            elevation={0}
                                                            padding={0}
                                                            heading={
                                                                <Alert severity="warning" className="mb-1">
                                                                    <strong>
                                                                        Card Order - CARD00
                                                                        {lines[line]?.card_order?.co_ref}
                                                                    </strong>
                                                                </Alert>
                                                            }
                                                            vertical
                                                            rows={[
                                                                {
                                                                    heading: "Customer Order No",
                                                                    value:
                                                                        lines[line]?.card_order?.co_cust_ref ?? false,
                                                                },
                                                                {
                                                                    heading: "Customer Site",
                                                                    value: `${
                                                                        lines[line]?.card_order?.site?.site_code ?? ""
                                                                    } - ${
                                                                        lines[line]?.card_order?.site
                                                                            ?.site_description ?? ""
                                                                    }`,
                                                                },
                                                                {
                                                                    heading: "Card Format",
                                                                    value:
                                                                        lines[line]?.card_order?.format?.format_name ??
                                                                        false,
                                                                },
                                                                {
                                                                    heading: "Card Type",
                                                                    value:
                                                                        lines[line]?.card_order?.type?.type_name ??
                                                                        false,
                                                                },
                                                                {
                                                                    heading: "Programmer Type",
                                                                    value:
                                                                        lines[line]?.card_order?.programmer
                                                                            ?.programmer_name ?? false,
                                                                },
                                                                {
                                                                    heading: "Internal Range Start",
                                                                    value:
                                                                        lines[line]?.card_order
                                                                            ?.co_internal_range_start ?? false,
                                                                },
                                                                {
                                                                    heading: "Internal Range End",
                                                                    value:
                                                                        lines[line]?.card_order
                                                                            ?.co_internal_range_end ?? false,
                                                                },
                                                                {
                                                                    heading: "External Range Start",
                                                                    value:
                                                                        lines[line]?.card_order
                                                                            ?.co_external_range_start ?? false,
                                                                },
                                                                {
                                                                    heading: "External Range End",
                                                                    value:
                                                                        lines[line]?.card_order
                                                                            ?.co_external_range_end ?? false,
                                                                },
                                                                {
                                                                    heading: "Card Order Notes",
                                                                    value: lines[line]?.card_order?.co_notes ?? false,
                                                                },
                                                            ]}
                                                        />
                                                    </Box>
                                                </>
                                            )}
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppButton
                                            disabled={
                                                this.state.currentPickQty === "" ||
                                                isNaN(this.state.currentPickQty) ||
                                                parseInt(this.state.currentPickQty) > parseInt(lines[line].quantity)
                                            }
                                            className="btn btn-success"
                                            type="submit"
                                            text="Confirm Pick"
                                            icon="check"
                                            primary
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                                    <AppButton
                                                        className="btn btn-success"
                                                        onClick={this.handlePickNextLine}
                                                        text="Confirm Pick"
                                                        icon="check"
                                                        primary
                                                    />
                                                </Grid> */}
                                    {/* <Grid item xs={12}>
                                                    <AppButton
                                                        className="btn btn-error"
                                                        back
                                                        onClick={this.handleDeployPickIssue}
                                                        text="Insufficient Stock Available"
                                                        icon="exclamation-triangle"
                                                    />
                                                </Grid> */}
                                    <Grid item xs={12}>
                                        <AppButton
                                            onClick={this.handlePickPreviousLine}
                                            disabled={_.isEmpty(order.notes) && !complete && line === 0}
                                            icon="undo"
                                            text="Back"
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        ))}
                </>
            ))
            // )) || (
            //     <Paper>
            //         <Box p={3}>
            //             <Grid container spacing={1} alignItems='flex-end'>
            //                 <Grid item xs>
            //                     <Typography variant="h6">
            //                         Picking List
            //                     </Typography>
            //                 </Grid>
            //                 <Grid item xs={12}>
            //                     <Box mt={1}>
            //                         <DataTable
            //                             config={{
            //                                 key: 'odp_id',
            //                                 alternatingRowColours: true,
            //                                 responsiveImportance: true,
            //                                 rowsPerPage: 100,
            //                                 pagination: false,
            //                                 inline: true,
            //                                 noResultsText: "No products to pick, please contact Sales Admin",
            //                             }}
            //                             columns={[
            //                                 {
            //                                     heading: 'Product',
            //                                     field: rowData => {
            //                                         const src = rowData.image ? `${CLOUDFRONT_URL}${rowData.image}` : (rowData.product?.default_image?.img_filekey ? `${CLOUDFRONT_URL}${rowData.product.default_image.img_filekey}` : logo)
            //                                         return <Avatar variant="square" alt={rowData.product?.product_name ?? 'Product Image'} src={src} style={{width: 75, height: 75}} />
            //                                     },
            //                                     important: true,
            //                                     hideInResponsiveDialog: true,
            //                                     sizeToContent: true
            //                                 },
            //                                 {
            //                                     heading: '',
            //                                     field: rowData => {
            //                                         return (
            //                                             <Grid container alignItems="flex-end" className="pt-2 pb-2">
            //                                                 <Grid item>
            //                                                     <Typography variant="body2" className="fw-400">
            //                                                         {rowData.productCode}
            //                                                     </Typography>
            //                                                 </Grid>
            //                                                 <Grid item xs={12}>
            //                                                     <Typography variant="caption" component="div">
            //                                                         {rowData.productName}
            //                                                     </Typography>
            //                                                 </Grid>
            //                                             </Grid>
            //                                         )
            //                                     },
            //                                     important: true,
            //                                 },
            //                                 {
            //                                     heading: 'Warehouse Bin Location',
            //                                     field: rowData => rowData.whBin,
            //                                     important: true,
            //                                     sizeToContent: true
            //                                 },
            //                                 {
            //                                     heading: 'Quantity To Pick',
            //                                     field: rowData => rowData.quantity,
            //                                     important: true,
            //                                     alignment: 'right',
            //                                     sizeToContent: true
            //                                 },
            //                                 {
            //                                     heading: 'Quantity Picked',
            //                                     field: rowData => <Box ml={5} width={50}><TextField margin="none" value={rowData.quantity} variant="outlined" disabled /></Box>,
            //                                     important: true,
            //                                     alignment: 'right',
            //                                     sizeToContent: true
            //                                 },
            //                                 {
            //                                     actions: rowData => {
            //                                         return [
            //                                             {name: 'Picked All', icon: 'check', onClick: () => {}, disabled: true},
            //                                         ]
            //                                     },
            //                                 }
            //                             ]}
            //                             rows={_.orderBy(od.products, el => el.whBin)}
            //                         />
            //                     </Box>
            //                     <div className="buttonRow">
            //                         <Button
            //                             className="btn btn-success"
            //                             onClick={() => deployConfirmation(`Are you sure you want to complete picking for this delivery?`, this.handleSubmit)}
            //                             variant="contained"
            //                         >
            //                             <FAIcon icon="check" button />
            //                             Confirm
            //                         </Button>
            //                     </div>
            //                 </Grid>
            //             </Grid>
            //         </Box>
            //     </Paper>
            // )
        );
    };
}

export default Picking;
