import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
// import StaffTile from 'Components/Common/Tiles/StaffTile';
// import Tile from 'Components/Common/Tiles/Tile';
// import { momentFormatDateTime } from 'Functions/MiscFunctions';
// import { getPresignedUrl } from 'Functions/S3Functions';
// import DataTable from 'Components/Common/DataTables/DataTable';
// import BooleanChip from 'Components/Common/Chips/BooleanChip';
import DataTable from 'Components/Common/DataTables/DataTable';
import BooleanChip from 'Components/Common/Chips/BooleanChip';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import API from 'API';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = () => ({
    resolve: '',
})

const ResolveIssue = ({callback, canCancel, canResolve, hideOptions, od, type}) => { 

    const [formData, setFormData] = useState(initialState());
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    
    const history = useHistory();

    const handleResolve = resolve => {
        setFormData(formData => ({
            ...formData,
            resolve
        }))
    }

    const handleSubmit = () => {

        switch(formData.resolve) {

            /* Part ship */
            case "partShip":

                setIsLoading(true);

                API.put(`/sales/delivery/${od?.od_id}/partShip`)
                .then(res => {
                    if(res?.data) {

                        if(res.data?.errors) {

                            setIsLoading(false);
                            dispatch(deploySnackBar("error", "Something went wrong, please try again"));

                        } else {

                            callback?.();                 
                            dispatch(closeDialog());
                            dispatch(deploySnackBar("success", "This delivery will be part shipped, outstanding items to follow when available"));

                        }

                    }
                })

            break;


            /* Wait for stock */
            case "wait":

                setIsLoading(true);

                API.put(`/sales/delivery/${od?.od_id}/waitForStock`)
                .then(res => {
                    if(res?.data) {

                        if(res.data?.errors) {

                            setIsLoading(false);
                            dispatch(deploySnackBar("error", "Something went wrong, please try again"));

                        } else {

                            callback?.();                 
                            dispatch(closeDialog());
                            dispatch(deploySnackBar("success", "This delivery is now awaiting stock"));

                        }

                    }
                })

            break;


            /* Cancel delivery */
            case "cancel":

                setIsLoading(true);

                API.put(`/sales/${od?.od_order_id}/cancellation`, {
                    cancel: {
                        [od?.od_idx]: 1
                    },
                    podAction: {},
                    reason: 4,
                    type: 'deliveries'
                })
                .then(res => {
                    if(res?.data) {

                        if(res.data?.errors) {

                            setIsLoading(false);
                            dispatch(deploySnackBar("error", "Something went wrong, please try again"));

                        } else {

                            callback?.(true);                 
                            dispatch(closeDialog());
                            dispatch(deploySnackBar("success", "This delivery has been successfully cancelled"));

                        }

                    }
                })

            break;


            /* Changer fulfilment */
            default:

                dispatch(closeDialog());
                history.push(`/sales/process/${od?.od_order_id}`)

            break;

        }

    }
    
    const showPartShip = _.every(od.products, el => el?.parent === 0) && _.some(od?.pick?.products, el => parseInt(type === "Pick" ? el.pp_pick_qty : el.cp_total_qty) > 0);
    
    return (
        <>
            <Box p={1} pb={0.1} style={{background: '#d9534f'}}>
                <Typography variant="h6" gutterBottom className="textLight">
                    {hideOptions ? `Delivery ${od.od_idx} - ${type} Issue` : `Resolve ${type} Issue`}
                </Typography>
            </Box>
            <Box p={3} className="content-error-paper" mb={hideOptions ? 3 : undefined}>
                {(isLoading && (
                    <>
                        <Box pt={1} pb={2.5}>
                            <Typography variant='h6' className='fw-400' align='center'>
                                <LoadingCircle />
                                Please wait whilst the resolution is actioned
                            </Typography>
                        </Box>
                    </>
                )) || ( 
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DataTable  
                                config={{
                                    key: type === 'Pick' ? 'pp_id' : 'cp_id',
                                    alternatingRowColours: true,
                                    // responsiveImportance: true,
                                    inline: true,
                                    noRowHover: true
                                }}
                                columns={[
                                    {
                                        heading: 'Product',
                                        field: rowData => (
                                            <>
                                                <Typography variant="body2" className="fw-400">
                                                    {type === 'Pick' ? (rowData?.pp_product_code ?? '-') : (rowData?.cp_product_code ?? '-')}
                                                </Typography>
                                                <Typography variant="caption">
                                                    {type === 'Pick' ? (rowData?.pp_product_name ?? '-') : (rowData?.cp_product_name ?? '-')}
                                                </Typography>
                                            </>
                                        ),
                                        truncate: true
                                    }, 
                                    {
                                        heading: 'Quantity',
                                        field: rowData => type === 'Pick' ? rowData.pp_expected_qty : rowData.cp_expected_qty,
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }, 
                                    {
                                        heading: type === 'Pick' ? 'Picked' : 'Quantity Present',
                                        field: rowData => type === 'Pick' ? rowData.pp_pick_qty : rowData.cp_total_qty,
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }, 
                                    {
                                        heading: 'Missing',
                                        field: rowData => type === 'Pick' ? (parseInt(rowData.pp_expected_qty) - parseInt(rowData.pp_pick_qty)) : (parseInt(rowData.cp_expected_qty) - parseInt(rowData.cp_total_qty)),
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }, 
                                    {
                                        heading: 'Status',
                                        field: rowData => <BooleanChip success={type === "Pick" ? rowData.pp_successful === 1 : rowData.cp_successful === 1} label={type === "Pick" ? rowData.pp_status : (rowData.cp_successful === 1 ? 'Checked' : 'Discrepancy')} />,
                                        sizeToContent: true
                                    }
                                ]}
                                rows={_.orderBy((type === 'Pick' ? od?.pick?.products : od?.check?.products), el => type === 'Pick' ? el.pp_successful === 0 : el.cp_successful === 0, 'desc')}
                                // rows={_.filter((type === 'Pick' ? od?.pick?.products : od?.check?.products), el => type === 'Pick' ? el.pp_successful === 0 : el.cp_successful === 0)}
                            />
                        </Grid>
                        {!hideOptions && (canResolve || canCancel) && (
                            <>
                                {showPartShip && (
                                    <Grid item xs={12} md={3}>
                                        <ToggleButton
                                            disabled={!canResolve}
                                            className={`textDefault`}
                                            onClick={() => handleResolve('partShip')}
                                            selected={formData?.resolve === 'partShip'}
                                            style={{background: '#fff', width: '100%', height: 100}} 
                                            value="partShip"
                                        >
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <FAIcon className="textDefault" type="light" size={25} icon="check-circle" button />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                        Part Ship
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ToggleButton>
                                    </Grid>
                                )}
                                <Grid item xs={12} md={showPartShip ? 3 : 4}>
                                    <ToggleButton 
                                        disabled={!canResolve}
                                        onClick={() => handleResolve('wait')}
                                        className={`textDefault`}
                                        selected={formData?.resolve === 'wait'}
                                        style={{background: '#fff', width: '100%', height: 100}} 
                                        value="wait"
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FAIcon className="textDefault" type="light" size={25} icon="pause-circle" button />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                    Wait For Stock
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={12} md={showPartShip ? 3 : 4}>
                                    <ToggleButton
                                        disabled={!canCancel}
                                        onClick={() => handleResolve('cancel')}
                                        className={`textDefault`}
                                        selected={formData?.resolve === 'cancel'}
                                        style={{background: '#fff', width: '100%', height: 100}} 
                                        value="cancel"
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FAIcon className="textDefault" type="light" size={25} icon="times-circle" button />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                    Cancel Delivery
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={12} md={showPartShip ? 3 : 4}>
                                    <ToggleButton
                                        disabled={!canResolve}
                                        onClick={() => handleResolve('changeFulfilment')}
                                        className={`textDefault`}
                                        selected={formData?.resolve === 'changeFulfilment'}
                                        style={{background: '#fff', width: '100%', height: 100}} 
                                        value="changeFulfilment"
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FAIcon className="textDefault" type="light" size={25} icon="question-circle" button />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="textDefault pl-1 fw-400" variant="h6" style={{textTransform: "none"}}>
                                                    Change Fulfilment
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={12} align='right'>
                                    <Button
                                        disabled={formData.resolve === ''}
                                        onClick={() => formData.resolve === 'changeFulfilment' ? handleSubmit() : dispatch(deployConfirmation(`Are you sure you want to ${formData.resolve === 'partShip' ? 'part ship' : (formData.resolve === 'wait' ? 'wait for stock for' : 'cancel')} this delivery?`, handleSubmit))}
                                        type="text"
                                        color="primary"
                                    >
                                        <FAIcon icon="check" size={15} button />
                                        Confirm Resolution
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </Box>
        </>
    )
}

export default ResolveIssue;