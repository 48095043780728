import { createMuiTheme } from '@material-ui/core/styles';
import { isTablet } from 'Functions/MiscFunctions';
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#ef3340',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            opaque: 'rgba(239,51,64,0.1)'
        },
        secondary: {
            main: '#b9b9b9'
        },
        error: {
            main: '#d9534f'
        }
    },
    props: {
        MuiDialog: {
            disableEnforceFocus: true
        },
        MuiTextField: {
            autoComplete: "off",
            InputLabelProps: {
                shrink: true
            },
            spellCheck: true
        },
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                padding: '24px 24px 8px 24px'
            }
        },
        MuiAlert: {
            filledWarning: {
                backgroundColor: '#FB8C00'
            },
            outlinedInfo: {
                border: 0
            },
            outlinedSuccess: {
                border: 0
            },
            outlinedError: {
                border: 0
            },
            outlinedWarning: {
                border: 0
            },
            standardInfo: {
                // color: '#004085',
                // backgroundColor: '#cce5ff',
                borderColor: '#b8daff'
            },
            standardSuccess: {
                // color: '#155724',
                // backgroundColor: '#d4edda',
                borderColor: '#c3e6cb'
            },
            standardError: {
                // color: '#721c24',
                // backgroundColor: '#f8d7da',
                borderColor: '#f5c6cb'
            },
            standardWarning: {
                // color: '#856404',
                // backgroundColor: '#fff3cd',
                borderColor: '#ffeeba'
            },
            message: {
                fontWeight: '300!important',
                fontSize: '13px'
            },
            root: {
                fontWeight: '300!important',
                padding: '5px 10px',
                border: '1px solid transparent',
                borderRadius: 1
            }
        },
        MuiAvatar: {
            fallback: {
                display: 'none'
            }
        },
        MuiBadge: {
            colorPrimary: {
                position: 'absolute',
                marginTop: 7.5,
                marginRight: -14
            },
            colorSecondary: {
                position: 'absolute',
                marginTop: -10,
                marginRight: -20,
                backgroundColor: '#D61F26',
                color: '#fff',
                boxShadow: '0 0 0 1px #fff',
                animation: 'shake 10s infinite',
                transform: 'translate3d(0, 0, 0)!important',
                transformOrigin: 'initial!important',
                backfaceVisibility: 'hidden',
            },
            colorError: {
                backgroundColor: '#ef3340',
            }
        },
        MuiChip: {
            avatarColorPrimary: {
                background: '#ef3340!important'
            }
        },
        MuiFormHelperText: {
            root: {
                marginTop: 0,
                marginBottom: '16px!important',
                color: '#d9534f!important'
            }
        },
        MuiFormLabel: {
            root: {
                fontWeight: 300
            }
        },
        MuiInputBase: {
            colorSecondary: {
                margin: '0!important',
                color: '#fff!important'
            }
        },
        MuiInput: {
            root: {
                marginTop: 16,
                marginBottom: 16,
                "&error": {
                    marginBottom: 8,
                }
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                },
                '&:after': {
                    borderBottom: '2px solid #ef3340'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #ef3340'
                }
            },
            colorSecondary: {
                '&::placeholder': {
                    color: '#fff',
                    opacity: 1,
                },
                '&:before': {
                    borderBottom: '1px solid rgba(255,255,255,0.5)!important'
                },
                '&:after': {
                    borderBottom: '1px solid #fff!important'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '1px solid #fff!important'
                },
            }
        },
        MuiFilledInput: {
            root: {
                border: '1px solid',
                borderColor: '#e0e0e0',
                borderBottom: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                marginTop: 0,
                marginBottom: 0,
            },
            adornedStart: {
                backgroundColor: '#fff!important',
                borderBottom: 'none'
            },
            input: {
                caretColor: '#ef3340',
                padding: '12px 12px 10px',
                backgroundColor: '#fff',
                '&::placeholder': {
                    fontWeight: 300,
                    fontSize: '1rem'
                },
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:after': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:hover:not($disabled):not($error):before': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:focus': {
                    borderBottom: '1px solid #e0e0e0'
                }
            }
        },
        MuiLinearProgress: {
            root: {
                height: 2.5
            }
        },
        MuiPaper: {
            elevation24: {
                boxShadow: '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12);',
            }
        },
        MuiTableCell: {
            root: {
                fontWeight: 300
            },
            head: {
                padding: '12px 8px'
            },
            body: {
                padding: '12px 8px'
            }
        },
        MuiTablePagination: {
            selectRoot: {
                marginRight: 12
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                backgroundColor: 'white',
                color:'#ef3340'
            },
            expandIcon: {
                color: '#ef3340'
            }
        },
        MuiCardContent: {
            root: {
                '&:last-child': {
                    paddingBottom: 16
                }
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: 12
            }
        },
        MuiTooltip: {
            tooltip: {
            }
        },
        MuiTypography: {
            root: {
                fontWeight: 300,
            },            
            subtitle1: {
                fontWeight: 300,
            },            
            subtitle2: {
                fontWeight: 300,
            },
            body2: {
                fontWeight: 300,
            },
            body1: {
                fontWeight: 300,
            },
            caption: {
                fontWeight: 300,
            },
            button: {
                fontWeight: 300,
            },
            h1: {
                fontWeight: 400,
            },
            h2: {
                fontWeight: 400,
            },
            h3: {
                fontWeight: 400,
            },
            h4: {
                fontSize: '1.50rem',
                fontWeight: 400,
            },
            h5: {
                fontWeight: 300,
                fontSize: isTablet() ? '1.1em' : '1.2em',
            },
            h6: {
                fontWeight: 400,
                color: '#000'
            },
            overline: {
                fontWeight: 300,
                lineHeight: "initial"
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#000'
            },
            contained: {
                fontSize: '0.833rem',
                transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                boxShadow: 'none!important',
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#ef3340',
                '&:hover': {
                    backgroundColor: '#c6101c',
                }
            },
            root: {
                borderRadius: '.25rem',
                fontWeight: 400,
                padding: '.375rem .75rem',
                lineHeight: 1.5
            }
        },
        MuiDialogActions: {
            root: {
                padding: '12px 24px 24px 24px'
            }
        },
        MuiTab: {
            root: {
                minWidth: '125px!important',
                minHeight: 35,
            },
            wrapper: {
                fontWeight: 400,
                color: '#222'
            },
        },
        MuiFormControl:{
            root: {
                marginTop: 0,
                marginBottom: 0,
                padding: 0
            }
        },
        MuiListItemAvatar: {
            root: {
                minWidth: 35
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 35
            }
        },
        MuiListItemText: {
            inset: {
                paddingLeft: 40
            }
        }
    }
});
export default theme;