const WarehouseRouteReferences = {
    "/dashboard": true,
    "/warehouse/incoming/purchase-order": "wh-process-grp",
    "/warehouse/incoming/rma": "wh-process-grp",
    "/warehouse/incoming": "wh-awaiting-delivery",
    "/warehouse/outgoing/awaiting-picking": "wh-awaiting-picking",
    "/warehouse/outgoing/awaiting-check": "wh-awaiting-check",
    "/warehouse/outgoing/awaiting-despatch": "wh-awaiting-despatch",
    "/warehouse/outgoing/ready-for-collection": "wh-ready-for-collection",
    "/warehouse/outgoing/on-hold": "wh-on-hold",
    "/warehouse/process/": "wh-processing",
    "/warehouse/stock/adjustment": "stock-adjustment",
    "/warehouse/stock/consignment": "consignment-stock-edit"
}

export default WarehouseRouteReferences;