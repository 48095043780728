import React from 'react';

import { 
    connect 
} from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { 
    withStyles 
} from '@material-ui/core/styles';

import DefaultAvatar from 'Assets/Images/defaultAvatar.png'
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Textarea from 'Components/Common/Inputs/Textarea';

import {
    CLOUDFRONT_URL
} from 'Constants';

import { 
    deployConfirmation 
} from 'Redux/Actions/Confirmation/Confirmation';

import { 
    handleChange
} from 'Functions/FormFunctions';

const styles = theme => ({
    card: {
        color: '#fff',
        height: '270px',
        padding: '20px'
    },
    cardHeader: {
        borderLeft: '2px solid #fff',
        borderRight: '3px solid #fff',
    },
    cardHeaderAction: {
        marginTop: -14,
        paddingRight: 10
    },
    cardHeaderPriority: {
        borderLeft: '2px solid #ef3340',
        borderRight: '3px solid #fff',
    },
    cardContent: {
        padding: '0 16px 16px 16px'
    },
    cardActionArea: {
        alignSelf: 'center',
        marginTop: 0,
        marginRight: 0
    },
})

const initialState = () => ({
    formData: {
        id: null,
        value: ''
    },
    isLoading: false
})

class EditNoteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();        
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount = () => {

        const {
            note
        } = this.props;

        this.setState({
            formData: {
                ...this.state.formData,
                id: note.i,
                value: note.v,
            }
        })
    }

    submit = () => {

        const {
            submit
        } = this.props;

        const {
            formData: {
                id, 
                value
            }
        } = this.state;

        submit?.(id, value)

    }

    cancel = () => {

        const {
            close
        } = this.props;

        close?.();

    }

    render = () => {

        const { 
            classes, 
            deployConfirmation,
            note, 
            status 
        } = this.props;

        const { 
            formData
        } = this.state;

        return (
            <Card classes={{root: (note.t === 'Important' && classes.cardHeaderPriority) || classes.cardHeader}}>
                <Box p={1.5} pb={1}>
                    <CardHeader
                        avatar={
                            <Avatar 
                                alt={status?.[note.s]?.name ?? 'Unknown'} 
                                src={status?.[note.s]?.picture ? `${CLOUDFRONT_URL}${status?.[note.s]?.picture}` : DefaultAvatar}
                                style={{width: 35, height: 35}}
                            >
                                {status?.[note.s]?.name?.charAt?.(0) ?? 'U'}
                            </Avatar>
                        }        
                        classes={{
                            root: (note.t === 'Important' && classes.cardHeaderPriorityHeader) || '', 
                            action: classes.cardHeaderAction, 
                            subheader: (note.t === 'Important' && classes.cardHeaderPriorityHeader) || ''
                        }}                                                            
                        title={(
                            <>
                                {status?.[note.s]?.name ?? 'Unknown'}
                                {` · `}
                                <Typography variant="caption" color={note.t === 'Standard' ? 'textSecondary' : undefined} className={note.t === 'Important' ? 'textError' : undefined}>
                                    Edit{note.t === 'Important' ? ' Priority' : ''} Note
                                </Typography>
                            </>
                        )}
                    />
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <Textarea
                                                placeholder='Notes:*'
                                                margin="none"
                                                name="value"
                                                value={formData.value}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                rows={5}
                                            /> 
                                        </Grid>
                                        <Grid item xs={12} align="right">
                                            <Box pt={1.5}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs />
                                                    <Grid item>
                                                        <Button onClick={() => this.cancel()}
                                                                variant="text"
                                                        >
                                                            <FAIcon icon="times" size={15} button />
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button 
                                                            onClick={() => deployConfirmation(`Are you sure you want to update this note?`, this.submit)}
                                                            variant="text" 
                                                            color="primary" 
                                                            disabled={!formData.value || formData.value === '' || formData.value === this.props.note?.v}
                                                        >
                                                            <FAIcon icon="check" size={15} button />
                                                            Update Note
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>  
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    status: state.notifications.status
});

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, callback) => dispatch(deployConfirmation(message, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditNoteForm));