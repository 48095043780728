import React, { useEffect, useState }from 'react';

import API from 'API';
import Search from 'Components/Common/Search/Search';

const initialState = () => ({
    access: {
        viewOrder: false
    },
})

const AllocatedStock = () => {
    
    const [state, setState] = useState(initialState());

    useEffect(() => {
        API.multiAccess([
            'view-order'
        ])
        .then(([
            viewOrder
        ]) =>  {
            setState(state => ({
                ...state,
                access: {
                    viewOrder
                }
            }));
        })
    }, []);

    const {
        access,
    } = state;

    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/inventory/stock/allocated',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Order Reference',
                        field: rowData => rowData.order || '-',
                        dataRef: 'order',
                        sizeToContent: true,
                    },
                    {
                        heading: 'Order Date',
                        field: rowData => rowData.orderDate || '-',
                        fieldFormat: 'datetime',
                        dataRef: 'orderDate',
                        sizeToContent: true,
                    },
                    {
                        heading: 'Customer',
                        field: rowData => rowData.customer || '-',
                        dataRef: 'customer',
                        sizeToContent: true,
                    },
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.code || '-',
                        dataRef: 'code',
                        sizeToContent: true,
                    },
                    {
                        heading: 'Product Name',
                        field: rowData => rowData.product || '-',
                        dataRef: 'product',
                        important: true,
                        sizeToContent: true,
                        main: true
                    },
                    {
                        heading: 'Quantity',
                        field: rowData => rowData.qty,
                        dataRef: 'qty',
                        alignment: 'right',
                        sizeToContent: true,
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Sales Order', icon: 'binoculars', link: `/sales/${rowData.orderId}`, disabled: !access.viewOrder}
                            ]
                        }
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'allocated-stock',
                noResultsText: 'Sorry, no stock is currently allocated',
                pagination: true,
                pageTitleData: [],
                pdf: true,
                persistenceId: 'allocatedStock',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showButton: null,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Allocated Stock'
            }}
        />
    )
}

export default AllocatedStock;