import React from 'react';
import uuidv4 from 'uuid';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DateSelect from 'Components/Common/Selects/DateSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import ProductSearchSelector from 'Components/Products/Misc/ProductSearchSelector';
import SearchQuotesTable  from './SearchQuotesTable';

import { 
    clearPersistence, 
    setPersistence 
} from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        addQuote: false,
        viewQuote: false,
    },
    dataLoading: true,
    date: 'allTime',
    defaultOptions: [],
    key: uuidv4(),
    productId: '',
    quoteStatus: '',
    quoteStatusList: [
        {
            label: 'Draft',
            value: 'Draft', 
        },
        {
            label: 'Awaiting Approval',
            value: 'Awaiting Approval', 
        },
        {
            label: 'Open',
            value: 'Open', 
        },
        {
            label: 'Ordered',
            value: 'Ordered', 
        },
        {
            label: 'Lost',
            value: 'Lost', 
        },
        {
            label: 'Declined',
            value: 'Declined', 
        },
    ],
    searchResults: [],
    searchString: ''
})

class SearchQuotes extends React.Component {
    constructor(props) {
        super(props);
    
        const {
            customerId,
            forProspects,
            forNewAccounts,
            hot,
            noPersistence,
            staffId,
            status,
        } = this.props;

        this.persistenceId = noPersistence ? null : `searchQuotes:${status ?? 'all'}${customerId ? `:c${customerId}` : ``}${staffId ? `:s${staffId}` : ``}${forNewAccounts ? `:fna` : ``}${forProspects ? `:fp` : ``}${hot ? ':hot' : ''}`;

        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
        
    }
    
    componentDidMount = () => {
        
        const {
            config,
            customerId,
            hot,
            myQuotes,
            pageTitle,
            status,
        } = this.props;

        pageTitle?.([1, 'Quotes', 'Search Quotes', myQuotes || (!status && !hot && config?.badges?.showAllQuotes === 0) ? 'My Quotes' : (status ?? `${hot ? 'Hot' : 'All'} Quotes`)])
        
        if (!this.persistenceId || !this.hasPageState()) {
            Promise.all([
                API.access('add-quote'),
                API.access('view-quote')
            ])
            .then(([
                accAddQuoteRes, 
                accViewQuoteRes
            ]) =>  {
                this.setState({
                    ...this.state,
                    access: {
                        addQuote: (accAddQuoteRes.data && accAddQuoteRes.data.has_access) || false,
                        viewQuote: (accViewQuoteRes.data && accViewQuoteRes.data.has_access) || false,
                    },
                    date: !status ? (customerId ? 'oneYear' : 'threeMonths') : (status === 'Open' || status === 'Ordered' || status === 'Lost' || status === 'Declined' ? 'sixMonths' : 'allTime'),
                    quoteStatus: customerId ? 'Open' : (status ?? initialState().quoteStatus),
                }, () => {
                    this.getSearchData();
                });
            })
        }
    }

    componentDidUpdate = prevProps => {

        const {
            config,
            customerId,
            hot,
            location,
            myQuotes,
            noPersistence,
            pageTitle,
            staffId,
            status,
        } = this.props;

        if(location?.pathname !== prevProps.location?.pathname) {

            API.cancel();

            pageTitle?.([1, 'Quotes', 'Search Quotes', myQuotes || (!status && !hot && config?.badges?.showAllQuotes === 0) ? 'My Quotes' : (status ?? `${hot ? 'Hot' : 'All'} Quotes`)])

            this.persistenceId = noPersistence ? null : `searchQuotes:${status ?? 'all'}${customerId ? `:c${customerId}` : ``}${staffId ? `:s${staffId}` : ``}`;

            if(this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(initialState())
                });
            } else {
                this.setState({
                    date: !status ? (customerId ? 'oneYear' : 'threeMonths') : (status === 'Open' || status === 'Ordered' || status === 'Lost' || status === 'Declined' ? 'sixMonths' : 'allTime'),
                    key: uuidv4(),
                    quoteStatus: customerId ? 'Open' : (this.props.status ?? initialState().quoteStatus),
                    productId: initialState().productId,
                    searchString: initialState().searchString,
                }, () => this.getSearchData())
            }

        }

        if(prevProps.config?.badges?.showAllQuotes !== config?.badges?.showAllQuotes) {
            pageTitle?.([1, 'Quotes', 'Search Quotes', myQuotes || (!status && !hot && config?.badges?.showAllQuotes === 0) ? 'My Quotes' : (status ?? `${hot ? 'Hot' : 'All'} Quotes`)])
            this.getSearchData();
        }
        
    }

    componentWillUnmount = () => {
        if(this.timeout) clearTimeout(this.timeout);
        API.cancel();
    }

    getSearchData = () => {

        const {
            date,
            quoteStatus,
            productId,
            searchString,
        } = this.state;

        const { 
            bundleId,
            customerId,
            forProspects,
            forNewAccounts,
            hot,
            myQuotes,
            noPersistence,
            staffId,
        } = this.props;
        
        let params = {
            d: date,
            searchString,
            quoteStatus
        }

        if(bundleId) {
            params = {
                ...params,
                b: bundleId
            }
        }

        if(customerId) {
            params = {
                ...params,
                c: customerId
            }
        }

        if(forProspects) {
            params = {
                ...params,
                forProspects: 1
            }
        }

        if(forNewAccounts) {
            params = {
                ...params,
                forNewAccounts: 1
            }
        }

        if(hot) {
            params = {
                ...params,
                hot: 1
            }
        }

        if(myQuotes) {
            params = {
                ...params,
                myQuotes: 1, 
            }
        }
        
        if(productId) {
            params = {
                ...params,
                p: productId
            }
        }

        if(staffId) {
            params = {
                ...params,
                s: staffId
            }
        }

        let props = {}

        if(!noPersistence) {
            props = {
                ...props,
                cancellation: true
            }
        }

        this.setState({
            dataLoading: true
        }, () => {
            API.get('/quotes', 
                { 
                    props, 
                    params 
                }
            )
            .then(result => {
                if(result?.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    }, this.savePageState)
                } 
            })
        })
    }
    
    handleSaveDefaultOptions = defaultOptions => {
        this.setState({
            defaultOptions
        }, this.savePageState)
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption?.value ?? initialState()[fieldName],
        }, 
        () => {
            this.getSearchData();
        });
    };

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        const {
            status,
        } = this.props;        
        API.cancel();
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access,
                date: !status ? 'sixMonths' : (status === 'Open' || status === 'Ordered' || status === 'Lost' || status === 'Declined' ? 'sixMonths' : 'allTime'),
            }, () => {
                this.getSearchData();
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    render() {

        const { 
            basic, 
            bundleId, 
            compact, 
            customerId, 
            dashboard, 
            inline, 
            status, 
            ui 
        } = this.props;

        const { 
            access, 
            dataLoading, 
            date, 
            defaultOptions,
            key,
            productId,
            searchResults, 
            searchString 
        } = this.state;

        return (
            <Grid container spacing={3}>   
                {!dashboard && (
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={12} sm={4} md={4} xl={3}>
                                <TextField 
                                    fullWidth
                                    onChange={this.onSearchStringChange}
                                    placeholder='Search:'
                                    value={searchString} 
                                    variant="filled"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                    }}
                                    autoFocus={!ui?.device?.isTablet}
                                /> 
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} xl={3}>
                               <ProductSearchSelector
                                    key={key}
                                    defaultOption={defaultOptions}
                                    passDefaultOptionsToParent={this.handleSaveDefaultOptions}
                                    placeholder={'Containing Product:'}
                                    noLabel
                                    variant="filled"
                                    value={productId}
                                    handleAction={this.handleSelectChange('productId')}
                                    hideStock
                                />
                            </Grid>
                            {!status && (
                                <Grid item>
                                    <Box width={175}>
                                        <AutoCompleteSelect
                                            options={this.state.quoteStatusList}
                                            placeholder='Status:'
                                            onChange={this.handleSelectChange('quoteStatus')}
                                            value={this.state.quoteStatus} 
                                            variant="filled"
                                            adornment="filter"
                                        /> 
                                    </Box>
                                </Grid>
                            )}
                            {status !== "Draft" && status !== "Awaiting Approval" && (
                                <Grid item>
                                    <Box width={175}>
                                        <DateSelect
                                            onChange={this.handleSelectChange('date')}
                                            value={date}
                                        />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>        
                )}     
                <Grid item xs={12}>
                    <Paper elevation={inline ? 0 : undefined}>
                        {(!dataLoading && dashboard && _.isEmpty(searchResults) && (
                            <Alert severity="success" variant="outlined">
                                <strong>None - </strong> You currently have no open hot quotes
                            </Alert>
                        )) || (
                            <SearchQuotesTable 
                                access={access}
                                basic={basic || customerId || bundleId}
                                customerId={customerId}
                                isCustomer={!!customerId}
                                callback={this.getSearchData}
                                inline={inline}
                                dataLoading={dataLoading} 
                                rows={searchResults} 
                                persistenceId={this.persistenceId}
                                resetSearch={this.resetSearch}
                                status={compact ? 'Compact' : status}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    config: state.notifications.config,
    statePersistence: state.statePersistence,
    ui: state.ui
})

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchQuotes);