import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar'; 
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
// import Rating from '@material-ui/lab/Rating';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import AsyncSelect from 'Components/Common/Selects/AsyncSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import ProductStockChip from 'Components/Common/Chips/ProductStockChip';
import logo from 'Assets/Images/no-img.jpg';
import { CLOUDFRONT_URL } from 'Constants';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    deployConfirmation
} from 'Redux/Actions/Confirmation/Confirmation';

import {
    deploySnackBar
} from 'Redux/Actions/SnackBar/SnackBar';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        // addProduct: false,
        viewProduct: false,
        reviewProduct: false,
        updateProduct: false
    },
    dataLoading: false,
    searchFilter: '',
    searchSupplierId: '',
    searchResults: {},
    searchString: '',
    supplierOption: null
})

class ProductSearch extends React.Component {
    constructor(props) {
        super(props);
        this.persistenceId = this.props.recentlyAdded ? 'recentlyAddedProducts' : 'productSearch';
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }

    componentDidMount = () => {

        if(this.props.recentlyAdded) {
            this.props.pageTitle?.([0, 'Products', 'Recently Added'])
        } else {
            this.props.pageTitle?.([0, 'Products', 'All Products'])
        }

        if(!this.hasPageState()) {
            API.multiAccess([
                // 'add-product',
                'view-product',
                'prd-review',
                'view-product:update-product'
            ])
            .then(([
                // addProduct, 
                viewProduct,
                reviewProduct,
                updateProduct
            ]) => {
                this.setState({
                    access: {
                        // addProduct,
                        viewProduct,
                        reviewProduct,
                        updateProduct
                    }
                }, () => this.getSearchData(true));
            })
        } else {
            this.getSearchData(false)
        }
        
    }

    componentDidUpdate = prevProps => {

        if(prevProps.location?.pathname !== this.props.location?.pathname) {
            API.cancel();
            if(this.props.recentlyAdded) {
                this.props.pageTitle?.([0, 'Products', 'Recently Added']);
                this.persistenceId = 'recentlyAddedProducts';
            } else {
                this.props.pageTitle?.([0, 'Products', 'All Products']);
                this.persistenceId = 'productSearch';
            }
            this.setState({
                ...initialState(),
                access: this.state.access
            }, () => {
                this.getSearchData(true);
            });
        }

    }


    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
        API.cancel();
    }

    getSearchData = dataLoading => {
        
        const { 
            searchFilter, 
            searchString,
            searchSupplierId
        } = this.state;    

        let params = {
            searchFilter,
            searchString
        }

        if(this.props.supplierId) {
            params = {
                ...params,
                s: this.props.supplierId
            }
        } else {
            params = {
                ...params,
                s: searchSupplierId,
            }
        }

        if(this.props.recentlyAdded) {
            params = {
                ...params,
                recentlyAdded: 1
            }
        }

        this.setState({
            dataLoading
        }, () => {
            API.get('/products/all', {
                props: {
                    cancellation: true,
                },
                params 
            })
            .then(result => {
                if(result?.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    }, () => {
                        this.savePageState();
                    });
                }
            });
        });
    }
    
    onSearchFilterChange = (selectedOption) => {
        this.setState({
            searchFilter: selectedOption?.value ?? ''
        },
        () => {
            this.setSearch();
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    onSearchSupplierIdChange = (option) => {
        this.setState({
            searchSupplierId: option?.value ?? '',
            supplierOption: option
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        API.cancel();
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access
            }, () => {
                this.getSearchData(true);
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getSearchData(true), 250);
    }

    getTableColumns = () => {

        const { 
            access
        } = this.state;

        const { 
            deployConfirmation,
            recentlyAdded
        } = this.props;


        const cols = [
            {
                field: rowData => <Avatar variant="square" alt={rowData.name} src={(rowData.im && `${CLOUDFRONT_URL}${rowData.im}`) || logo} />,
                sizeToContent: true,
                important: true, 
            },
            {
                heading: 'Product Code',
                field: rowData => rowData.c,
                important: true, 
                dataRef: 'c',
                sizeToContent: true
            },
            {
                heading: 'Description',
                field: rowData => rowData.d,
                dataRef: 'd',
                main: true,
                truncate: true
            },
            {
                heading: 'Buy Price',
                field: rowData => rowData.b,
                fieldFormat: 'currency',
                dataRef: 'b',
                important: true, 
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Trade Price',
                field: rowData => rowData.p,
                fieldFormat: 'currency',
                dataRef: 'p',
                important: true, 
                sizeToContent: true,
                alignment: 'right',
                cellProps: () => ({
                    style: {
                        color: '#388E3C'
                    }
                })
            },
            {
                heading: 'Bin Location',
                field: rowData => rowData.bl,
                important: true, 
                dataRef: 'bl',
                sizeToContent: true
            },
            {
                heading: 'Available',
                field: rowData => rowData.av,
                important: true, 
                dataRef: 'av',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'On Order',
                field: rowData => rowData.oo,
                important: true, 
                dataRef: 'oo',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Supplier',
                field: rowData => rowData.supp,
                important: true,
                dataRef: 'supp',
                main: true,
                truncate: true
            },
            {
                heading: 'Status',
                field: rowData => (
                    <ProductStockChip
                        status={rowData.s}
                        basedOnStatus
                    />
                ),
                dataRef: 'sTxt',
                sizeToContent: true
            }
        ];
        
        if(recentlyAdded) {
            cols.push(
                {
                    heading: 'Reviewed',
                    field: rowData => rowData.rev,
                    fieldFormat: 'boolean',
                    dataRef: 'rev',
                    alignment: 'center'
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'Mark as Reviewed', icon: 'check', onClick: () => deployConfirmation(`Are you sure you want to mark this product as reviewed?`, () => this.handleReviewProduct(rowData.i)), disabled: !access.reviewProduct || rowData.rev === 1},
                            {name: 'Modify Product', icon: 'pencil', link: `/products/update/${rowData.i}`, disabled: !access.updateProduct},
                            {name: 'View Product', icon: 'binoculars', link: `/products/${rowData.i}`, disabled: !access.viewProduct, doubleClick: true}
                        ]
                    }
                }
            )
        } else {
            cols.push(
                {
                    actions: rowData => {
                        return [
                            {name: 'View', icon: 'binoculars', link: `/products/${rowData.i}`, disabled: !access.viewProduct, doubleClick: true}
                        ]
                    }
                }
            )
        }

        return cols;
    }

    handleReviewProduct = id => {

        let  {
            deploySnackBar
        } = this.props;

        let {
            dataLoading
        } = this.state;

        dataLoading = true;

        this.setState({
            dataLoading
        }, () => {
                
            API.put(`/products/${id}/reviewed`)
            .then(result => {

                if(result && result.data) {

                    if(result.data?.errors) {

                        deploySnackBar(`error`, `There was an issue marking this product as reviewed, please try again`);

                    } else {

                        deploySnackBar(`success`, `The product was successfully marked as reviewed`);
                        this.getSearchData();

                    }

                }

            })

        })

    }
    
    searchSuppliers = (searchString, callback, selectRef) => {
        API.get('/smartSearch/suppliers', 
            {
                props: {
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'select'
                }
            }
        )
        .then(res => {
            
            if(res?.data) {
                callback(res?.data)
            } else {
                callback([])
            }

            if(selectRef?.current?.focus) {
                selectRef.current.focus()
            }

        })
    }
    
    render = () => {

        const {
            recentlyAdded,
            supplierId
        } = this.props;

        const { 
            dataLoading, 
            searchFilter, 
            searchString,
            supplierOption
        } = this.state;

        return (
            <Grid container spacing={1}>      
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                        <TextField
                            fullWidth
                            className="pr-2"
                            onChange={this.onSearchStringChange}
                            placeholder='Search:'
                            value={searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                            }}
                            autoFocus
                        />
                    </Grid>   
                    {!supplierId && (
                        <Grid item xs={12} sm={4} md={3} xl={2} style={{marginBottom: 7}} className="pr-2">
                            <AsyncSelect
                                apiCall={_.debounce(this.searchSuppliers, 250)}
                                placeholder={'Supplier:'}
                                onChange={option => this.onSearchSupplierIdChange(option)}
                                value={supplierOption}
                                variant="filled"
                            />
                        </Grid>
                    )}
                    {!recentlyAdded && (
                        <Grid item xs={12} sm={4} md={3} xl={2} style={{marginBottom: 12}}>
                            <AutoCompleteSelect
                                onChange={this.onSearchFilterChange}
                                options={[
                                    {
                                        label: 'Active',
                                        value: 'Active'
                                    },
                                    {
                                        label: 'Discontinued',
                                        value: 'Discontinued'
                                    },
                                    {
                                        label: 'Replaced',
                                        value: 'Replaced'
                                    },
                                    // {
                                    //     label: 'Deleted',
                                    //     value: 'Deleted'
                                    // }
                                ]}
                                placeholder='Status:'
                                value={searchFilter} 
                                variant="filled"
                                adornment="filter"
                            />
                        </Grid>
                    )}
                    {/* {access.addProduct && !this.props.hideAddProduct && (
                        <Grid item xs={12} sm={3} md={5} xl={7} align='right'>
                            <Button 
                                color="primary"
                                size="small"
                                variant="text"
                                onClick={() => this.props.history.push('/products/new')}
                            >
                                <FAIcon type="light" icon="plus-circle" size="small" button />
                                Add Product
                            </Button>
                        </Grid>
                    )} */}
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable
                            config={{
                                key: 'i',
                                pagination: true,
                                persistenceId: this.persistenceId,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                noResultsText: searchString.length >= 2 ? 'No products were found' : 'Start typing to search products',
                                options: {
                                    defaultSort: this.props.recentlyAdded ? null : 'c',
                                    dataRef: true,
                                    reset: this.resetSearch,
                                    export: {
                                        title: `Products`,
                                        name: `Products`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={this.getTableColumns()}
                            rows={this.state.searchResults}
                        />
                    </Paper>
                </Grid>
            </Grid>   
        );
    }
}
			
const mapStateToProps = state => ({
    statePersistence: state.statePersistence,
})

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (msg, success) => dispatch(deployConfirmation(msg, success)),
    deploySnackBar: (variant, msg) => dispatch(deploySnackBar(variant, msg)),
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);