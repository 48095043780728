import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Avatar from 'Components/Common/Avatars/Avatar';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const Notification = ({alert, data, enhanced, handleDismiss, handleAction}) => {
    if(data) {

        let icon = "info-circle", 
            color = "textInfo";

        if(data.b) {
            let b = data.b.split(":");
            if(b[0] && b[1]) {
                icon = b[0];
                color = b[1];
            }
        }

        return (
            <ListItem 
                button={data.a ? true : false}
                className={enhanced ? `list-item-borderless${!data.a ? ' list-disabled':''}` : `list-item${!data.a ? ' list-disabled':''}`}
                onClick={() => data.a && handleAction(data.i, data.a)} 
                disableGutters
                style={{borderLeft: (data.r && enhanced && ('2px solid #9E9E9E')) || (!data.r && enhanced && ('2px solid #ef3340')), border: alert ? 'none!important' : undefined}}
            >
                <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                        {(data.f && (
                            <Avatar
                                staffId={data.f}
                                size={32}
                            />
                        )) || (
                            <FAIcon type="thin" icon={icon} className={color} style={{filter: alert ? 'brightness(175%)' : undefined}} size={27.5} noMargin />
                        )}
                    </Grid>
                    <Grid item xs>
                        <Grid container alignItems={enhanced ? 'center' : 'flex-start'}>
                            <Grid item xs>
                                <Typography variant={alert ? 'h6' : 'body2'} className={`${alert ? 'textLight' : undefined} fw-400`}>
                                    {data.t}
                                </Typography>
                                <Typography variant={alert ? 'body2' : 'caption'} component="div" className={`pr-1 ${alert ? 'fw-400' : ''} ${alert ? 'textLight' : undefined}`}>
                                {data.c && data.c.split('\n').map((item, key) => (
                                    <span key={key}>{item}<br /></span>
                                ))}
                                </Typography>
                                {!alert && (
                                    <Typography variant="overline" color="textSecondary" component="div" style={{paddingTop: 8}} className={alert ? 'textLight' : undefined}>
                                        {data.w}
                                    </Typography>
                                )}
                            </Grid>
                            {!alert && (
                                <Grid item>
                                    {!data.r && (
                                        <Tooltip title="Dismiss">
                                            <IconButton
                                                onClick={(e) => handleDismiss(data.i, e)}
                                            >
                                                <FAIcon type="light" icon="times" noMargin button />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
        )

    } else {
        return <React.Fragment />
    }    
}

export default Notification;