import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// import 'Components/Common/Weather/css/weather-icons.min.css';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { isTablet } from 'Functions/MiscFunctions';

const weatherIcons = {
    "200": {
      "label": "thunderstorm with light rain",
      "icon": "cloud-showers"
    },
    "201": {
      "label": "thunderstorm with rain",
      "icon": "cloud-showers-heavy"
    },
    "202": {
      "label": "thunderstorm with heavy rain",
      "icon": "cloud-showers-heavy"
    },
    "210": {
      "label": "light thunderstorm",
      "icon": "cloud-showers"
    },
    "211": {
      "label": "thunderstorm",
      "icon": "cloud-bolt"
    },
    "212": {
      "label": "heavy thunderstorm",
      "icon": "cloud-bolt"
    },
    "221": {
      "label": "ragged thunderstorm",
      "icon": "cloud-bolt"
    },
    "230": {
      "label": "thunderstorm with light drizzle",
      "icon": "cloud-showers"
    },
    "231": {
      "label": "thunderstorm with drizzle",
      "icon": "cloud-showers"
    },
    "232": {
      "label": "thunderstorm with heavy drizzle",
      "icon": "cloud-showers"
    },
    "300": {
      "label": "light intensity drizzle",
      "icon": "cloud-drizzle"
    },
    "301": {
      "label": "drizzle",
      "icon": "cloud-drizzle"
    },
    "302": {
      "label": "heavy intensity drizzle",
      "icon": "cloud-drizzle"
    },
    "310": {
      "label": "light intensity drizzle rain",
      "icon": "cloud-drizzle"
    },
    "311": {
      "label": "drizzle rain",
      "icon": "cloud-rain"
    },
    "312": {
      "label": "heavy intensity drizzle rain",
      "icon": "cloud-rain"
    },
    "313": {
      "label": "shower rain and drizzle",
      "icon": "cloud-rain"
    },
    "314": {
      "label": "heavy shower rain and drizzle",
      "icon": "cloud-rain"
    },
    "321": {
      "label": "shower drizzle",
      "icon": "cloud-drizzle"
    },
    "500": {
      "label": "light rain",
      "icon": "cloud-drizzle"
    },
    "501": {
      "label": "moderate rain",
      "icon": "cloud-rain"
    },
    "502": {
      "label": "heavy intensity rain",
      "icon": "cloud-showers"
    },
    "503": {
      "label": "very heavy rain",
      "icon": "cloud-showers-heavy"
    },
    "504": {
      "label": "extreme rain",
      "icon": "cloud-showers-heavy"
    },
    "511": {
      "label": "freezing rain",
      "icon": "cloud-hail-mixed"
    },
    "520": {
      "label": "light intensity shower rain",
      "icon": "cloud-showers"
    },
    "521": {
      "label": "shower rain",
      "icon": "cloud-showers"
    },
    "522": {
      "label": "heavy intensity shower rain",
      "icon": "cloud-showers"
    },
    "531": {
      "label": "ragged shower rain",
      "icon": "cloud-showers"
    },
    "600": {
      "label": "light snow",
      "icon": "cloud-snow"
    },
    "601": {
      "label": "snow",
      "icon": "cloud-snow"
    },
    "602": {
      "label": "heavy snow",
      "icon": "cloud-snow"
    },
    "611": {
      "label": "sleet",
      "icon": "cloud-sleet"
    },
    "612": {
      "label": "shower sleet",
      "icon": "cloud-sleet"
    },
    "615": {
      "label": "light rain and snow",
      "icon": "cloud-sleet"
    },
    "616": {
      "label": "rain and snow",
      "icon": "cloud-sleet"
    },
    "620": {
      "label": "light shower snow",
      "icon": "cloud-snow"
    },
    "621": {
      "label": "shower snow",
      "icon": "cloud-snow"
    },
    "622": {
      "label": "heavy shower snow",
      "icon": "cloud-snow"
    },
    "701": {
      "label": "mist",
      "icon": "cloud-fog"
    },
    "711": {
      "label": "smoke",
      "icon": "smoke"
    },
    "721": {
      "label": "haze",
      "icon": "sun-haze"
    },
    "731": {
      "label": "sand, dust whirls",
      "icon": "sun-dust"
    },
    "741": {
      "label": "fog",
      "icon": "cloud-fog"
    },
    "751": {
      "label": "sand",
      "icon": "wind"
    },
    "761": {
      "label": "dust",
      "icon": "cloud-fog"
    },
    "762": {
      "label": "volcanic ash",
      "icon": "smog"
    },
    "771": {
      "label": "squalls",
      "icon": "wind"
    },
    "781": {
      "label": "tornado",
      "icon": "tornado"
    },
    "800": {
      "label": "clear sky",
      "icon": "sun"
    },
    "801": {
      "label": "few clouds",
      "icon": "clouds"
    },
    "802": {
      "label": "scattered clouds",
      "icon": "clouds"
    },
    "803": {
      "label": "broken clouds",
      "icon": "clouds"
    },
    "804": {
      "label": "overcast clouds",
      "icon": "clouds"
    },
    "900": {
      "label": "tornado",
      "icon": "tornado"
    },
    "901": {
      "label": "tropical storm",
      "icon": "hurricane"
    },
    "902": {
      "label": "hurricane",
      "icon": "hurricane"
    },
    "903": {
      "label": "cold",
      "icon": "temperature-snow"
    },
    "904": {
      "label": "hot",
      "icon": "temperature-sun"
    },
    "905": {
      "label": "windy",
      "icon": "wind"
    },
    "906": {
      "label": "hail",
      "icon": "cloud-hail"
    },
    "951": {
      "label": "calm",
      "icon": "sun-bright"
    },
    "952": {
      "label": "light breeze",
      "icon": "wind"
    },
    "953": {
      "label": "gentle breeze",
      "icon": "wind"
    },
    "954": {
      "label": "moderate breeze",
      "icon": "wind"
    },
    "955": {
      "label": "fresh breeze",
      "icon": "wind"
    },
    "956": {
      "label": "strong breeze",
      "icon": "windsock"
    },
    "957": {
      "label": "high wind, near gale",
      "icon": "wind-warning"
    },
    "958": {
      "label": "gale",
      "icon": "wind-warning"
    },
    "959": {
      "label": "severe gale",
      "icon": "wind-warning"
    },
    "960": {
      "label": "storm",
      "icon": "cloud-bolt"
    },
    "961": {
      "label": "violent storm",
      "icon": "cloud-bolt"
    },
    "962": {
      "label": "hurricane",
      "icon": "hurricane"
    }
}

const Weather = ({weather}) => {
    let label = weatherIcons?.[weather?.icon?.code]?.label?.split?.(" ") ?? [""];
    _.each(label, (word, idx) => {
        label[idx] = word.charAt(0).toUpperCase() + word.slice(1)
    });
    label = label.join(" ");
    return (
        <Grid container spacing={2} alignItems='center' justify="flex-end">
            <Grid item>
                <Tooltip title={label}>
                    <FAIcon
                        style={{color: '#333', fontSize: isTablet() ? 35 : 47.5, verticalAlign: 'middle'}} 
                        icon={`${weatherIcons?.[weather?.icon?.code]?.icon ?? ''}`}
                        type='thin' 
                        size={55}
                    />
                </Tooltip>
            </Grid>
            <Grid item>
                {weather.temp && (
                    <>
                        <Typography variant="h4" component="span" style={{fontSize: isTablet() ? '2em' : '2.25em'}} className="textDefault">{parseFloat(weather.temp).toFixed(1)}</Typography>
                        <Typography variant="caption" component="span" className="textDefault fw-400">&deg;C</Typography>
                        <Typography variant="h4" component="span" style={{fontSize: isTablet() ? '2em' : '2.25em'}} className="pl-1 textDefault">{weather.title}</Typography>
                    </>
                )}  
                {weather.location && (
                    weather?.location?.split?.('\n').map((item, key) => (
                        <Typography variant="caption" component="div" key={key} className={`textDefault ${key === 0 ? 'fw-400' : ''}`}>
                            {item}
                        </Typography>
                    ))
                )}
            </Grid>
        </Grid>
    )
}

export default Weather;