import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AutoCompleteSelect from '../Selects/AutoCompleteSelect';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import Textarea from '../Inputs/Textarea';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
} from 'Functions/FormFunctions';
import FAIcon from '../Icons/FontAwesome/FAIcon';

const styles = theme => ({
    card: {
        color: '#fff',
        height: '270px',
        padding: '20px'
    },
    cardHeader: {
        borderLeft: '4px solid #fff',
        borderRight: '3px solid #fff',
    },
    cardHeaderAction: {
        marginTop: -14,
        paddingRight: 10
    },
    cardContent: {
        padding: '0 16px 16px 16px'
    },
    cardActionArea: {
        alignSelf: 'center',
        marginTop: 0,
        marginRight: 0
    },
})

const initialState = () => ({
    contacts: [],
    formData: {
        smsRel: "",
        smsRelId: 0,
        smsRelContactId: 0,
        smsValue: "",
        smsRecipient: ""
    },
    formErrors:[],
    confirmation: {
        submit: false,
    }
})

class SendSMSForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState();        
        this.handleChange = handleChange.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
    }

    handleRecipientChange = selectedOption => {
        const smsRecipient = selectedOption && selectedOption.value;
        const smsRelContactId = (selectedOption && selectedOption.id) || 0;
        this.setState({
            formData: {
                ...this.state.formData,
                smsRecipient,
                smsRelContactId
            }
        });
    }

    componentDidMount = () => {
        this.setState({
            ...this.state,
            contacts: this.props.contacts,
            formData: {
                ...this.state.formData,
                smsRel: this.props.relation,
                smsRelId: this.props.id,
                smsRecipient: this.props.number ? this.props.number : initialState().smsRecipient,
                smsRelContactId: this.props.contactId ? this.props.contactId : initialState().smsRecipient,
            }
        })
    }

    submit = () => {
        const newData = {
            ...this.state.formData,
            smsRecipient: this.state.formData.smsRecipient.indexOf(":") > -1 ? this.state.formData.smsRecipient.substring(0, this.state.formData.smsRecipient.indexOf(":")) : this.state.formData.smsRecipient
        }
        API.post('/misc/sms', newData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    formData: {
                        ...initialState().formData
                    }
                }, () => {
                    this.props.deploySnackBar("success", "You have successfully sent an SMS")
                    if(this.props.toggleDialog)
                        this.props.toggleDialog();
                })
            }
        })
    }

    cancel = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                smsValue: ""
            }
        }, () => {
            if(this.props.toggleDialog)
                this.props.toggleDialog();
        })
    }

    render() {
        const { classes, creatable } = this.props;
        const { formData, formErrors, contacts } = this.state;
        return (
            <Card classes={{root: classes.cardHeader}}>
                <CardHeader
                    avatar={
                        <Avatar 
                            alt={this.props.loggedInStaff.name} 
                            src={this.props.loggedInStaff.picture}
                            style={{width: 35, height: 35}}
                        >
                            {this.props.loggedInStaff.firstName.charAt(0)}
                        </Avatar>
                    }        
                    classes={{
                        root: classes.cardHeader,
                        action: classes.cardHeaderAction
                    }}                                                            
                    title={(
                        <>
                            {this.props.loggedInStaff.name}
                            {` · `}
                            <Typography variant="caption" color={formData.noteType === 'Standard' ? 'textSecondary' : undefined} className={formData.noteType === 'Important' ? 'textError' : undefined}>
                                Send SMS
                            </Typography>
                        </>
                    )}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {((contacts.length > 0 || creatable) && (
                                <>
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={12}>
                                                <AutoCompleteSelect
                                                    creatableMessage={userInput => `Send to mobile number: ${userInput}`}
                                                    options={contacts}
                                                    noOptionsMessage={() => "There are no contacts available to SMS"}
                                                    label='Recipient *'
                                                    value={formData.smsRecipient}
                                                    onChange={this.handleRecipientChange}
                                                    error={formErrors && formErrors['smsRecipient'] && true}
                                                    errorText={formErrors && formErrors['smsRecipient']}
                                                    creatable={creatable}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Textarea
                                                    margin="none"
                                                    name="smsValue"
                                                    value={formData.smsValue}
                                                    error={formErrors && formErrors['smsValue']}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    rows={5}
                                                    placeholder={"Enter your message here..."}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="textSecondary" style={{marginTop: 10}}>
                                                    Characters: {formData.smsValue.length}/918 | SMS Parts: {formData.smsValue.length <= 0 ? 0 : (formData.smsValue.length > 160 ? Math.ceil(formData.smsValue.length / 153) : 1)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="right">
                                                <Grid container spacing={2}>
                                                    <Grid item xs />
                                                    <Grid item>
                                                        <Button onClick={() => this.cancel()}
                                                                variant="text"
                                                        >
                                                            <FAIcon icon="times" size={15} button />
                                                            Discard
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button 
                                                            onClick={() => this.handleConfirmationOpen('submit')}
                                                            variant="text" 
                                                            color="primary"
                                                            disabled={!this.state.formData.smsValue}
                                                        >
                                                            <FAIcon icon="check" size={15} button />
                                                            Send Message
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>  
                                    <ConfirmationDialog 
                                        open={this.state.confirmation.submit} 
                                        success={() => this.handleConfirmationSuccess('submit')} 
                                        close={() => this.handleConfirmationClose('submit')} 
                                        title="Send SMS?" 
                                        message="Are you sure you want to send this SMS?"
                                    />
                                </>
                            )) || (
                                <Typography variant="body2">
                                    Unable to send an SMS as there are no available recipients.
                                    <div className="buttonRow">
                                        <Button onClick={() => this.cancel()}
                                                variant="text"
                                        >
                                            <FAIcon icon="times" size={15} button />
                                            Close
                                        </Button>
                                    </div>
                                </Typography>
                            )}                       
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SendSMSForm));