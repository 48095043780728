import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import CustomerSearchSelector from "../Misc/CustomerSearchSelector";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { useDispatch, useSelector } from "react-redux";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { useState } from "react";
import { DialAdornment } from "Functions/MiscFunctions";
import API from "API";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { closeDialog } from "Redux/Actions/Dialog/Dialog";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";

const ReviewOnline = ({ callback, id, request }) => {

    const staff = useSelector(state => state.notifications.status);
    const staffMember = useSelector(state => state.staffAuth.staff.name);

    const [state, setState] = useState({
        email: request.data.useMyDetails ? request.data.email : request.data.adminEmail,
        firstName: request.data.useMyDetails ? request.data.firstName : request.data.adminFirstName,
        lastName: request.data.useMyDetails ? request.data.lastName : request.data.adminLastName,
        position: request.data.useMyDetails ? request.data.position : request.data.adminPosition,
        customer: ''
    });

    const [disabled, setDisabled] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleCustomerSelection = (customer) => {
        if(customer) {
            API.get(`/customers/${customer}/tradeZone/adminCheck`)
            .then(res => {
                if(res?.data) {
                    setDisabled(res.data.success)
                    if(!res.data.success) {
                        setState(prevState => ({
                            ...prevState,
                            customer: customer
                        }));
                    }
                }
            })
        } else {
            setState(prevState => ({
                ...prevState,
                customer: ''
            }));
            setDisabled(false);
        }
    }

    const handleDecline = () => {
        setIsLoading(true);
        API.put(`/customers/onboarding/${id}/decline`)
        .then(res => {
            if(res?.data) {
                dispatch(deploySnackBar(`success`, `You have successfully declined this request`))
                callback?.();
            }
            setIsLoading(false);
        })
    }

    const handleAccept = () => {
        setIsLoading(true);
        API.put(`/customers/onboarding/${id}/accept`, state)
        .then(res => {
            if(res?.data?.email) {
                dispatch(deploySnackBar(`error`, `The e-mail address is already in use by another Portal User`))
            } else if(res?.data?.customer) {
                dispatch(deploySnackBar(`error`, `This customer already has a Portal Administrator`))                
            } else if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `You have successfully accepted this request`))
                callback?.();
            }
            setIsLoading(false);
        })
    }

    const handlePrint = () => {
        const title = `Portal - ${request.trr_type === 'ONLINE_ACCOUNT' ? 'Online Account Request' : request.trr_type === 'CREDIT_ACCOUNT' ? 'Credit Account Application' : 'Trade Account Application'}`;
        window.open('/print/' + btoa(JSON.stringify({...request, title, staffMember})), 'Form', 'width=1280,height=720');
    }

    return (
        isLoading ? (
            <LoadingCircle />
        ) : (
            <Box mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            User Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            label="First Name"
                            value={request.data.firstName || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            label="Surname"
                            value={request.data.lastName || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            label="Position"
                            value={request.data.position || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            label="Phone Number"
                            value={request.data.phone || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                startAdornment: <DialAdornment number={request.data.phone || '-'} />
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            label="Email Address"
                            value={request.data.email || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Company Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Company Name"
                            value={request.data.companyName || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Account Number"
                            value={request.data.accountNumber || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Account Postcode"
                            value={request.data.postCode || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Portal Administrator
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            name="email"
                            label="Admin Email Address"
                            value={state.email}
                            fullWidth
                            disabled={request.trr_actioned}
                            onChange={handleChange}
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            name="firstName"
                            label="Admin First Name"
                            value={state.firstName}
                            fullWidth
                            disabled={request.trr_actioned}
                            onChange={handleChange}
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            name="lastName"
                            label="Admin Surname"
                            value={state.lastName}
                            fullWidth
                            disabled={request.trr_actioned}
                            onChange={handleChange}
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            name="position"
                            label="Admin Position"
                            value={state.position}
                            fullWidth
                            disabled={request.trr_actioned}
                            onChange={handleChange}
                            variant={'outlined'}
                        />
                    </Grid>
                    {!request.trr_actioned ? (
                        <>
                            <Grid item xs={12} className="pb-0 pt-0">
                                <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                                    Select Customer
                                </Typography>
                                <Box mt={-2}>
                                    <CustomerSearchSelector
                                        basic
                                        includeProspects
                                        label=''
                                        preloadedOptions={request.suggestions ?? []}
                                        handleAction={handleCustomerSelection}
                                        noFrequent
                                        noPaper
                                        noAutoFocus
                                    />
                                </Box>
                            </Grid>
                            {disabled && (
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="error" className="mt-1 fw-400">
                                        This customer already has a Portal Administrator, please decline and contact the customer to resolve this issue.
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    ) : null}
                    {request?.customer?.cust_company_name ? (
                        <Grid item xs={12}>
                            <Typography variant="body2" color="error" className="mt-1 fw-400">
                                Accepted for <strong>{request.customer.cust_company_name}</strong> by {staff?.[request.trr_action_staff_id]?.name}
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <Box mt={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Grid container>
                                        <Grid item>
                                            <Button
                                                onClick={() => dispatch(closeDialog())}
                                                variant="text"
                                            >
                                                <FAIcon icon="times-circle" size={15} button />
                                                Close
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={() => handlePrint()}
                                                variant="text"
                                            >
                                                <FAIcon icon="print" size={15} button />
                                                Print
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!request.trr_actioned ? (
                                    <>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item>
                                                    <Button
                                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to decline this request?`, handleDecline))}
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="exclamation-circle" size={15} button />
                                                        Decline
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button 
                                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to accept this request?`, handleAccept))}
                                                        variant="text" 
                                                        color="primary"
                                                        disabled={!state.customer || disabled}
                                                    >
                                                        <FAIcon icon="check-circle" size={15} button />
                                                        Accept
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    )
}

export default ReviewOnline;