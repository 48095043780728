import React  from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handleChange, handleNumberChange, handleFloat } from 'Functions/FormFunctions';

const initialState = () => ({
    formData: {
        id: '',
        name: '',
        percent: '',
    },
    formErrors: {}
})

class DiscountDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
        this.handleFloat = handleFloat.bind(this)
        this.handleNumberChange = handleNumberChange.bind(this)
    }

    componentDidMount = () => {
        const { formData, formErrors } = this.props;
        this.setState({
            formData,
            formErrors
        })
    }
   
    componentDidUpdate = (prevProps) => {
        if(this.props.formData !== prevProps.formData) {
            const { formData, formErrors } = this.props;
            this.setState({
                formData,
                formErrors
            })
        }
    }

    cancel = () => {
        this.props.handleCancel(true)
    }
    
    submit = () => {
        this.props.handleSubmit(this.state.formData)
    }

    render() {
        const { open, idx } = this.props;
        const { formErrors, formData } = this.state;
        return (
            <>
                <Dialog
                    disableEscapeKeyDown={true} 
                    disableBackdropClick={true} 
                    open={open}
                    maxWidth='sm' 
                    scroll="body"
                    onEscapeKeyDown={this.cancel}
                >               
                    <DialogTitle disableTypography>
                        <Typography variant="h6">
                            Discount Details
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{padding: '0 24px 24px 24px', minWidth: 400, maxWidth: 500}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name *"
                                        value={formData.name}
                                        error={formErrors && formErrors['supplierDiscounts|name|'+idx] && true}
                                        helperText={formErrors && formErrors['supplierDiscounts|name|'+idx]}                                                
                                        onChange={this.handleChange}
                                        margin="none"
                                        fullWidth
                                    />
                                    <TextField
                                        id="percent"
                                        name="percent"
                                        label="Percentage *"
                                        value={formData.percent}
                                        error={formErrors && formErrors['supplierDiscounts|percent|'+idx] && true}
                                        helperText={formErrors && formErrors['supplierDiscounts|percent|'+idx]}                                                
                                        onChange={this.handleNumberChange}
                                        onBlur={this.handleFloat}
                                        margin="none"
                                        type="number"
                                        inputProps={{
                                            step: 1,
                                            min: 0,
                                            max: 100
                                        }}
                                        fullWidth
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button onClick={this.cancel} variant="text" color="primary">
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                                <Button disabled={formData.name.length === 0 || formData.percent.length === 0 || formData.percent < 0 || formData.percent > 100} onClick={this.submit} variant="text" color="primary">
                                    <FAIcon icon="check" size={15} button />
                                    {idx === -1 ? 'Add' : 'Update'} Discount
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>        
            </>
        )
    }
}

export default DiscountDialog;