import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DateSelect from 'Components/Common/Selects/DateSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import SearchPurchaseOrdersTable from 'Components/Purchases/SearchPurchaseOrders/SearchPurchaseOrdersTable';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

/* PO Status will be the prop unless the user selects one from the dropdown menu when the state value is used */
const initialState = () => ({
    access: {
        // addPo: false,
        viewPo: false,
    },
    dataLoading: true,
    date: 'sixMonths',
    poStatus: '',
    poStatusList: [
        {value: 'Awaiting Submission', label: 'Awaiting Submission'},
        {value: 'Awaiting Confirmation', label: 'Awaiting Confirmation'},
        {value: 'Despatched', label: 'Despatched'},
        {value: 'Invoiced', label: 'Invoiced'},
        {value: 'Cancelled', label: 'Cancelled'},
    ],
    searchResults: [],
    searchString: ''
})

class SearchPurchaseOrders extends React.Component {
    constructor(props) {
        super(props);

        const {
            delivered,
            due,
            outstanding,
            shipHere,
            supplierId,
            poStatus,
            type,
            warehouseMode, 

        } = this.props;

        this.persistenceId = warehouseMode ? null : `searchPO:${poStatus ? poStatus : 'all'}${outstanding ? `-os` : ``}${delivered ? `:d` : ``}${due ? `:du` : ``}${shipHere ? `:sh` : ``}${supplierId ? `:s${supplierId}`: ``}${type ? `:t${type}` : ``}`;

        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }

    componentDidMount = () => {
        const { outstanding, pageTitle, poStatus, title, type, warehouse } = this.props;
        if(warehouse) {
            pageTitle?.([1, 'Warehouse', title])
        } else {
            pageTitle?.([1, 'Purchase Orders', poStatus ? poStatus : (type ? (type === "Stock" ? 'Stock Replenishment' : 'Sales Order Fulfilment'): 'All')])
        }
        if(!this.hasPageState()) {
            Promise.all([
                // API.access('add-po'),
                API.access('view-po')
            ])
            .then(([viewPo]) => {
                this.setState({
                    access: {
                        // addPo: (addPo.data && addPo.data.has_access) || false,
                        viewPo: (viewPo.data && viewPo.data.has_access) || false,
                    },
                    date: outstanding || warehouse ? 'allTime' : (!poStatus ? 'sixMonths' : (poStatus === 'Invoiced' || poStatus === 'Cancelled' ? 'sixMonths' : 'allTime')),
                }, () => {
                    this.getSearchData();
                });
            });
        }
    }

    componentDidUpdate = (prevProps) => {
        const { pageTitle, location, myOrders, poStatus, title, warehouse, warehouseMode } = this.props;
        if(poStatus !== prevProps.poStatus || myOrders !== prevProps.myOrders || location?.pathname !== prevProps.location?.pathname) {
            
            API.cancel();
            
            if(warehouse) {
                pageTitle?.([1, 'Warehouse', title])
            } else {
                pageTitle?.([1, 'Purchase Orders', poStatus ? poStatus : 'All'])
            }
            
            const {
                delivered,
                due,
                outstanding,
                shipHere,
                supplierId,
                type
            } = this.props;

            this.persistenceId = warehouseMode ? null : `searchPO:${poStatus ? poStatus : 'all'}${outstanding ? `-os` : ``}${delivered ? `:d` : ``}${due ? `:du` : ``}${shipHere ? `:sh` : ``}${supplierId ? `:s${supplierId}`: ``}${type ? `:t${type}` : ``}`;

            if(this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(initialState())
                });
            } else {
                this.setState({                
                    date: outstanding || warehouse ? 'allTime' : (!poStatus ? 'sixMonths' : (poStatus === 'Invoiced' || poStatus === 'Cancelled' ? 'sixMonths' : 'allTime')),
                    poStatus: '',
                    searchString: '',
                }, () => {
                    this.getSearchData();
                })
            }
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);            
        API.cancel();
    }

    getSearchData = () => {

        let params = {
            all: this.props.poStatus ? false : true,
            d: this.state.date,
            status: this.state.poStatus ? this.state.poStatus : this.props.poStatus,
            searchString: this.state.searchString
        }

        if(this.props.delivered) {
            params = {
                ...params,
                delivered: true
            }
        }

        if(this.props.due) {
            params = {
                ...params,
                due: true
            }
        }

        if(this.props.outstanding) {
            params = {
                ...params,
                showOutstanding: true
            }
        }

        if(this.props.shipHere) {
            params = {
                ...params,
                shipHere: true
            }
        }

        if(this.props.supplierId) {
            params = {
                ...params,
                s: this.props.supplierId
            }
        }

        if(this.props.type) {
            params = {
                ...params,
                t: this.props.type
            }
        }

        this.setState({
            dataLoading: true
        }, () => {
            API.get('/purchaseOrders', {
                params,
                props: {
                    cancellation: true
                }               
            })
            .then(result =>  {

                const searchResults = result?.data ?? [],
                        dataLoading = false;

                this.setState({
                    searchResults,
                    dataLoading
                }, () => {
                    this.savePageState();
                })

            })
        })

    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.getSearchData();
        });
    }
    
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        const { 
            outstanding,
            poStatus, 
            warehouse 
        } = this.props;       
        API.cancel();
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access,
                date: outstanding || warehouse ? 'allTime' : (!poStatus ? 'sixMonths' : (poStatus === 'Invoiced' || poStatus === 'Cancelled' ? 'sixMonths' : 'allTime')),
            }, () => {
                this.getSearchData();
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }
    
    render() {
        const { inline, outstanding, poStatus, warehouseMode } = this.props;
        let { access, dataLoading, date, searchResults, searchString } = this.state;
        return (
            <Grid container spacing={3}>      
                {(warehouseMode && (
                    <>
                        {(dataLoading && (
                            <Grid item xs={12}>
                                <LoadingCircle />
                            </Grid>
                        )) || (
                            (_.isEmpty(searchResults) && (
                                <Grid item xs={12}>
                                    <Alert severity="success" border>
                                        <span className="fw-400">
                                            There are no purchase orders that are {poStatus.toLowerCase()}
                                        </span>
                                    </Alert>
                                    <Box pt={3}>
                                        <AppButton
                                            onClick={() => this.props.history.push('/dashboard')}
                                            text={`Main Menu`}
                                            back
                                        />
                                    </Box>
                                </Grid>
                            )) || (
                                <>
                                    {_.map(searchResults, (pod, idx) => (
                                        <Grid item xs={12} key={idx}>
                                            <Card onClick={() => this.props.history.push(`/warehouse/received/purchase-order/${pod.id}`)}>
                                                <Box p={2}>
                                                    <Grid container alignItems='center' justify='space-between'>
                                                        <Grid item xs={12}>
                                                            <Box pb={0.5}>
                                                                <Typography variant="body1" className="fw-400 textDefault">
                                                                    Purchase Order Delivery #{pod.ref}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="caption">
                                                                Despatched: {pod.despatched}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="caption">
                                                                Supplier: {pod.supplier}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                        <AppButton
                                            onClick={() => this.props.history.push('/dashboard')}
                                            text={`Main Menu`}
                                            back
                                        />
                                    </Grid>
                                </>
                            )
                        )}
                    </>
                )) || (
                    <>
                        {!inline && (
                            <Grid container item xs={12} alignItems='center'>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5} md={4} xl={3}>
                                            <TextField 
                                                fullWidth
                                                onChange={this.onSearchStringChange}
                                                placeholder='Search:'
                                                value={searchString} 
                                                variant="filled"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                                }}
                                            /> 
                                        </Grid>   
                                        {!poStatus && (
                                            <Grid item xs={12} sm={4} md={3} xl={2}>
                                                <AutoCompleteSelect
                                                    options={this.state.poStatusList}
                                                    placeholder='Status:'
                                                    onChange={this.handleSelectChange('poStatus')}
                                                    value={this.state.poStatus} 
                                                    variant="filled"
                                                    adornment="filter"
                                                /> 
                                            </Grid>
                                        )} 
                                        {!outstanding && poStatus !== "Awaiting Submission" && poStatus !== "Awaiting Confirmation" && poStatus !== "Awaiting Despatch" && poStatus !== "Despatched" && poStatus !== "On Hold" && (
                                            <Grid item  xs={12} sm={3} md={3} xl={2}>
                                                <DateSelect
                                                    onChange={this.handleSelectChange('date')}
                                                    value={date}
                                                />
                                            </Grid>
                                        )}
                                        {/* {!poStatus && access.addPo && this.props.history && (
                                            <Grid item sm={!poStatus ? (poStatus !== "Awaiting Submission" && poStatus !== "Awaiting Confirmation" && poStatus !== "Awaiting Despatch" && poStatus !== "Despatched" ? 3 : 6) : 6} lg={!poStatus ? (poStatus !== "Awaiting Submission" && poStatus !== "Awaiting Confirmation" && poStatus !== "Awaiting Despatch" && poStatus !== "Despatched" ? 5 : 7) : 7} align='right'> 
                                                <Button 
                                                    color="primary" 
                                                    size="small"
                                                    variant="text"
                                                    onClick={() => this.props.history.push('/purchase-orders/new')}
                                                >   
                                                    <FAIcon type="light" icon="plus-circle" size="small" button />
                                                    New Purchase Order
                                                </Button>
                                            </Grid>
                                        )} */}
                                    </Grid>
                                </Grid>
                            </Grid>     
                        )} 
                        <Grid item xs={12}>
                            <Paper elevation={inline ? 0 : undefined}>
                                <SearchPurchaseOrdersTable 
                                    access={access}
                                    inline={inline}
                                    dataLoading={dataLoading}
                                    rows={searchResults}
                                    resetSearch={this.resetSearch}
                                    persistenceId={this.persistenceId}
                                    poStatus={this.props.poStatus}
                                    warehouse={this.props.warehouse}
                                    type={this.props.type}
                                    due={this.props.due}
                                    title={this.props.title}
                                />
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence,
})

const mapDispatchToProps = dispatch => ({
        clearPersistence: () => dispatch(clearPersistence()),
        setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchPurchaseOrders);
