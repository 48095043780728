import React from "react";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Alert from "Components/Common/Alert/Alert";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import { FormControlLabel, Switch } from "@material-ui/core";

const ContactUpdates = ({ contacts, document, formData, formErrors, handleChange, handleCheckedChange }) => (
    <PaddedPaper>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">{document} Updates</Typography>
                {!isNaN(formData.customerContact) && contacts?.[_.findIndex(contacts, (el) => el.i === formData.customerContact)]?.noe === 1 && (
                    <Alert severity="info" variant="outlined">
                        <strong>Important - </strong>This contact has opted out of receiving order e-mails
                    </Alert>
                )}
                {!isNaN(formData.customerContact) && contacts?.[_.findIndex(contacts, (el) => el.i === formData.customerContact)]?.ndu === 1 && (
                    <Alert severity="info" variant="outlined">
                        <strong>Important - </strong>This contact has opted out of receiving delivery notifications
                    </Alert>
                )}
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    name="contactEmail"
                    label="E-mail Address"
                    value={formData.contactEmail}
                    onChange={handleChange}
                    margin="none"
                    fullWidth
                    error={formErrors && formErrors["contactEmail"] && true}
                    helperText={formErrors && formErrors["contactEmail"]}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FAIcon type="thin" icon="envelope" size="small" noMargin />
                            </InputAdornment>
                        ),
                    }}
                    type="email"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    name="contactPhone"
                    label="Mobile Number"
                    value={formData.contactPhone}
                    onChange={handleChange}
                    margin="none"
                    fullWidth
                    error={formErrors && formErrors["contactPhone"] && true}
                    helperText={formErrors && formErrors["contactPhone"]}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FAIcon type="thin" icon="mobile-phone" size="small" noMargin />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Switch color="primary" checked={formData.ndUpdates} onChange={handleCheckedChange} value="1" name="ndUpdates" />}
                    label={<span className="fw-400">No delivery notifications</span>}
                />
                <FormControlLabel
                    control={<Switch color="primary" checked={formData.noe} onChange={handleCheckedChange} value="1" name="noe" />}
                    label={<span className="fw-400">No order emails</span>}
                />
            </Grid>
        </Grid>
    </PaddedPaper>
);

export default ContactUpdates;
