import React from "react";
import moment from "moment";
import uuidv4 from 'uuid';
import _ from "lodash";
import { connect } from "react-redux";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Alert from "Components/Common/Alert/Alert";
import API from "API";
import AppButton from "Components/Common/Buttons/AppButton";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import DateSelect from "Components/Common/Selects/DateSelect";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import SearchOrdersTable from "./SearchOrdersTable";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import QrReaderDialog from "Components/Common/Qr/QrReaderDialog";
import ProductSearchSelector from 'Components/Products/Misc/ProductSearchSelector';

import { 
    closeDialog, 
    deployDialog 
} from "Redux/Actions/Dialog/Dialog";

import { 
    deploySnackBar 
} from "Redux/Actions/SnackBar/SnackBar";

import { 
    playSound 
} from "Redux/Actions/Sounds/Sounds";

import { 
    clearPersistence, 
    setPersistence 
} from "Redux/Actions/StatePersistence/StatePersistence";

import { 
    clearPageState, 
    getInitialState,
    hasPageState, 
    savePageState 
} from "Functions/StatePersistence/StatePersistenceFunctions";

const initialState = () => ({
    access: {
        addOrder: false,
        viewOrder: false,
        processAa: false,
        processAu: false,
        processAp: false,
        processPo: false,
        lockDelivery: false,
        releaseDelivery: false
    },
    dataLoading: true,
    date: "allTime",
    defaultOptions: [],
    key: uuidv4(),
    orderStatus: "",
    orderStatusList: [
        {
            label: "Awaiting Approval",
            value: "Awaiting Approval",
        }, 
        {
            label: "Awaiting Payment",
            value: "Awaiting Payment",
        }, 
        {
            label: "Awaiting Authorisation",
            value: "Awaiting Authorisation",
        }, 
        {
            label: "Processing",
            value: "Processing",
        }, 
        {
            label: "Awaiting Allocation",
            value: "Awaiting Allocation",
        }, 
        {
            label: "Awaiting Stock",
            value: "Awaiting Stock",
        }, 
        {
            label: "Awaiting Supplier",
            value: "Awaiting Supplier",
        }, 
        {
            label: "Awaiting Picking",
            value: "Awaiting Picking",
        }, 
        {
            label: "Awaiting Despatch",
            value: "Awaiting Despatch",
        }, 
        {
            label: "Despatched",
            value: "Despatched",
        }, 
        {
            label: "Invoiced",
            value: "Invoiced",
        }, 
        {
            label: "Cancelled",
            value: "Cancelled",
        }, 
    ],
    paymentStatus: "",
    paymentStatusList: [
        { 
            label: "Outstanding Balance",
            value: "Outstanding Balance", 
        },
        { 
            label: "Fully Paid",
            value: "Fully Paid", 
        },
    ],
    productId: '',
    searchResults: [],
    searchString: "",
});

class SearchOrders extends React.Component {
    constructor(props) {
        super(props);
        
        const {
            customerId,
            noPersistence,
            status,
            staffId,
            warehouseMode
        } = this.props;

        this.persistenceId = warehouseMode ? null : (noPersistence ? null : `orderSearch:${status || "all"}${customerId ? `_c:${customerId}` : ``}${staffId ? `_s:${staffId}` : ``}`);

        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.pulseResults = null;
        this.state = this.getInitialState(initialState());
        this.timeout = false;
        
    }

    componentDidMount = () => {

        const {
            buildOrders,
            pageTitle,
            status,
            myOrders,
            outstanding,
            warehouse,
            warehouseMode
        } = this.props;

        if (buildOrders) {
            pageTitle?.([1, `Technical`, `Builds`, `Outstanding`]);
        } else {
            pageTitle?.([1, warehouse ? "Warehouse" : warehouseMode ? "WMS" : "Sales Orders", status ? status : myOrders ? "My Orders" : outstanding ? "Outstanding Orders" : "All"]);
        }

        if (!this.persistenceId || !this.hasPageState()) {
            Promise.all([
                API.access("add-order"), 
                API.access("view-order"), 
                API.access("process-order:awaiting-approval"), 
                API.access("process-order:awaiting-payment"), 
                API.access("process-order:awaiting-authorisation"), 
                API.access("process-order:processing"),
                API.access("lock-orders"),
                API.access("rel-locked-orders"),
            ]).then(
                ([
                    addOrder, 
                    viewOrder, 
                    processAa, 
                    processAp, 
                    processAu, 
                    processPo,
                    lockDelivery,
                    releaseDelivery
                ]) => {
                    this.setState(
                        {
                            ...this.getInitialState(initialState()),
                            access: {
                                addOrder: addOrder?.data?.has_access ?? false,
                                viewOrder: viewOrder?.data?.has_access ?? false,
                                processAa: processAa?.data?.has_access ?? false,
                                processAp: processAp?.data?.has_access ?? false,
                                processAu: processAu?.data?.has_access ?? false,
                                processPo: processPo?.data?.has_access ?? false,
                                lockDelivery: lockDelivery?.data?.has_access ?? false,
                                releaseDelivery: releaseDelivery?.data?.has_access ?? false,
                            },
                            date: warehouseMode || outstanding ? "allTime" : !status ? "sixMonths" : status === "Invoiced" || status === "Cancelled" ? "sixMonths" : "allTime",
                            orderStatus: status ?? initialState().orderStatus,
                        },
                        () => {
                            this.getSearchData();
                            if (warehouseMode) {
                                this.pulseResults = setInterval(() => this.getSearchData(true), 7500);
                            }
                        }
                    );
                }
            );
        } else {
            if(status) {
                this.getSearchData();
            }
        }
    };

    componentDidUpdate = (prevProps) => {

        const {
            buildOrders,
            customerId,
            location,
            myOrders,
            outstanding,
            noPersistence,
            status,
            pageTitle,
            staffId,
            warehouse,
            warehouseMode,
        } = this.props;

        if (location?.pathname !== prevProps.location?.pathname) {
            API.cancel();
            this.persistenceId = warehouseMode ? null : (noPersistence ? null : `orderSearch:${status || "all"}${customerId ? `_c:${customerId}` : ``}${staffId ? `_s:${staffId}` : ``}`);
            if (buildOrders) {
                pageTitle?.([1, `Technical`, `Builds`, `Outstanding`]);
            } else {
                pageTitle?.([1, warehouse ? "Warehouse" : warehouseMode ? "WMS" : "Sales Orders", status ? status : myOrders ? "My Orders" : outstanding ? "Outstanding Orders" : "All"]);
            }
            if (this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(initialState()),
                });
            } else {
                this.setState(
                    {
                        date: warehouseMode || outstanding ? "allTime" : !status ? "sixMonths" : status === "Invoiced" || status === "Cancelled" ? "sixMonths" : "allTime",
                        key: uuidv4(),
                        orderStatus: this.props.status ?? initialState().orderStatus,
                        productId: initialState().productId,
                        searchString: initialState().searchString,
                    },
                    () => {
                        this.getSearchData();
                        if (this.pulseResults) {
                            clearInterval(this.pulseResults);
                            this.pulseResults = setInterval(() => this.getSearchData(true), 5000);
                        }
                    }
                );
            }
        }
    };

    componentWillUnmount = () => {
        if (this.timeout) clearTimeout(this.timeout);
        if (this.pulseResults) clearInterval(this.pulseResults);
        API.cancel();
    };

    getSearchData = (noLoading = false) => {

        const {
            date,
            orderStatus,
            productId,
            searchString,
            showAll
        } = this.state;

        const { 
            bundleId,
            checkDatePast,
            customerId,
            dashboard,
            myOrders,
            outstanding,
            noPersistence,
            staffId,
            status,
            warehouse,
            warehouseMode
        } = this.props;

        let params = {
            checkDatePast: checkDatePast ? 1 : 0,
            d: date,
            orderStatus,
            searchString,
            showAll,
        };

        if (bundleId) {
            params = {
                ...params,
                b: bundleId,
            };
        }

        if (customerId) {
            params = {
                ...params,
                c: customerId,
            };
        }

        if (dashboard) {
            params = {
                ...params,
                dashboard: 1,
            };
        }

        if(myOrders) {
            params = {
                ...params,
                myOrders: 1
            }
        }

        if(outstanding) {
            params = {
                ...params,
                showOutstanding: 1
            }
        }

        if(productId) {
            params = {
                ...params,
                p: productId
            }
        }

        if (staffId) {
            params = {
                ...params,
                s: staffId,
            };
        }
        
        if(!status) {
            params = {
                ...params,
                all: true
            }
        }

        if (warehouse || warehouseMode) {
            params = {
                ...params,
                warehouse: 1,
            };
        }

        let props = {
            noLoading
        }

        if(!noPersistence) {
            props = {
                ...props,
                cancellation: true
            }
        }

        this.setState(
            {
                dataLoading: noLoading ? false : true,
            },
            () => {
                API.get("/sales", 
            {
                        props,
                        params
                    }
                ).then((result) => {
                    if (result?.data) {
                        this.setState(
                            {
                                dataLoading: false,
                                searchResults: result.data,
                            }, this.savePageState
                        );
                    }
                });
            }
        );
    };

    deployQrDialog = () => {
        const { deployDialog, ui } = this.props;
        deployDialog(<QrReaderDialog app delay={0} isPDA={ui?.device?.isPDA} handleScan={this.qrReaderScan} text="Please scan the despatch note QR code" />, false, "Despatch Order", "standard", "sm");
    };

    handleSaveDefaultOptions = defaultOptions => {
        this.setState({
            defaultOptions
        }, this.savePageState)
    }

    handleSelectChange = (fieldName) => (selectedOption) => {
        this.setState(
            {
                [fieldName]: selectedOption?.value ?? initialState()[fieldName],
            },
            () => {
                this.getSearchData();
            }
        );
    };

    onSearchStringChange = (event) => {
        this.setState(
            {
                searchString: event.target.value,
            },
            () => {
                this.setSearch();
            }
        );
    };

    onShowAllChange = () => {
        this.setState(
            {
                showAll: this.state.showAll === 0 ? 1 : 0,
            },
            () => {
                this.setSearch();
            }
        );
    };

    qrReaderScan = (scanData) => {
        if (scanData !== null) {
            const { searchResults } = this.state;
            const { closeDialog, deploySnackBar, history, playSound, status } = this.props;
            let od = _.find(searchResults, (el) => el.ref === scanData);
            if (od) {
                playSound("pickSuccess", true);
                if (status === "On Hold") {
                    if (od.ohd === "0000-00-00 00:00:00") {
                        if (od.status === "Awaiting Despatch") {
                            if (od.cd === 1) {
                                deploySnackBar("success", `Found sales order delivery #${od.ref}`);
                                history.push(`/warehouse/process/${od.id}`);
                                closeDialog();
                            } else {
                                deploySnackBar("error", `You cannot check this order as you picked it`);
                                closeDialog();
                            }
                        } else {
                            deploySnackBar("success", `Found sales order delivery #${od.ref}`);
                            history.push(`/warehouse/process/${od.id}`);
                            closeDialog();
                        }
                    } else {
                        deploySnackBar("error", `Delivery #${od.ref} is still on hold`);
                        closeDialog();
                    }
                } else {
                    deploySnackBar("success", `Found sales order delivery #${od.ref}`);
                    history.push({
                        pathname: `/warehouse/process/${od.id}`,
                        state: { qrScanned: 1 },
                    });
                    closeDialog();
                }
            } else {
                playSound("pickError", true);
                deploySnackBar("error", "The scanned delivery could not be found");
            }
        }
    };

    resetSearch = () => {
        const { outstanding, status, warehouseMode } = this.props;
        API.cancel();
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState(
                {
                    ...this.getInitialState(initialState()),
                    access: this.state.access,
                    date: warehouseMode || outstanding ? "allTime" : !status ? "sixMonths" : status === "Invoiced" || status === "Cancelled" ? "sixMonths" : "allTime",
                },
                () => {
                    this.getSearchData();
                }
            );
        }, 250);
    };

    setSearch = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    };

    render() {
        
        const { 
            buildOrders, 
            bundleId, 
            checkDatePast, 
            customerId, 
            compact, 
            dashboard, 
            history, 
            inline, 
            outstanding,
            staff,
            status, 
            warehouseMode, 
            warehouse
        } = this.props;

        let { 
            access, 
            dataLoading, 
            date, 
            defaultOptions,
            key,
            orderStatus, 
            orderStatusList,
            productId,
            searchResults, 
            searchString 
        } = this.state;

        return (
            <Grid container spacing={3}>   
                {(warehouseMode && (
                    (dataLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        (_.isEmpty(searchResults) && (
                            <Grid item xs={12}>
                                <Alert severity="success">
                                    <span className="fw-400">There are no sales order deliveries that are {status.toLowerCase()}</span>
                                </Alert>
                                <Box pt={3}>
                                    <AppButton onClick={() => this.props.history.push("/dashboard")} back text={`Main Menu`} />
                                </Box>
                            </Grid>
                        )) || (
                            <>
                                <Grid item xs={12}>
                                    {status === "Awaiting Picking" && <AppButton className="btn btn-success" icon="arrow-right" onClick={() => history.push(`/warehouse/process/${searchResults[0]?.id}`)} primary text={`Pick Next Delivery: #${searchResults[0]?.ref}`} />}
                                    {(status === "Awaiting Check" || status === "Awaiting Despatch" || status === "Ready for Collection" || status === "On Hold") && (
                                        <AppButton className="btn btn-success" icon="qrcode" onClick={this.deployQrDialog} primary text={`Scan QR Code`} />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                {_.map(searchResults, (od, idx) => (
                                    <Grid item xs={12} key={idx}>
                                        <Card
                                            onClick={() =>
                                                od?.cd === 0
                                                    ? undefined
                                                    : !od.ohd || od?.ohd === "0000-00-00 00:00:00"
                                                    ? od?.status === "Awaiting Despatch"
                                                        ? od?.cd === 1
                                                            ? this.props.history.push(`/warehouse/process/${od.id}`)
                                                            : this.props.deploySnackBar(`error`, `You cannot check this order as you picked it`)
                                                        : this.props.history.push(`/warehouse/process/${od.id}`)
                                                    : this.props.deploySnackBar(`error`, `Delivery #${od.ref} is still on hold`)
                                            }
                                            className={od.cd === 1 || typeof od.cd === "undefined" ? `link` : undefined}
                                        >
                                            {od?.status === "Awaiting Despatch" && od?.cd === 0 && <Alert severity="warning">You cannot check this order as you picked it</Alert>}
                                            <Box p={2}>
                                                <Grid container alignItems="center" justify={od.ohd === "0000-00-00 00:00:00" ? undefined : "space-between"}>
                                                    {(status === "On Hold" && (
                                                        <>
                                                            <Grid item>
                                                                <FAIcon type="thin" icon={od.ohd === "0000-00-00 00:00:00" ? "check" : od.hLck ? "lock" : "exclamation-triangle"} className={`${od.ohd === "0000-00-00 00:00:00" ? "textSuccess" : "textError"} pr-2`} noMargin size={50} />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Grid container justify="space-between">
                                                                    <Grid item xs={12}>
                                                                        <Box pb={0.5}>
                                                                            <Typography variant="body1" className="fw-400 textDefault">
                                                                                Sales Order Delivery #{od.ref}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="caption" component="div" className={`${od.ohd === "0000-00-00 00:00:00" ? "textSuccess" : "textError"} fw-400`}>
                                                                            {od.hLck ? `${od.hSts} - ${od.hRsn} (${staff?.[od?.hStf ?? -1]?.name ?? 'System'})` : (od.ohd === "0000-00-00 00:00:00" ? "Resolved" : `Awaiting Resolution ${moment(od.ohd, "YYYY-MM-DD HH:mm:ss").format("(HH:mm)")}`)}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="caption">{od.customer}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )) || (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Box pb={0.5}>
                                                                    <Typography variant="body1" className="fw-400 textDefault">
                                                                        Sales Order Delivery #{od.ref} {od.col === 1 ? ` (Collection)` : ``}
                                                                        {od.legacy === 2 && <FAIcon icon="hat-santa" size={15} />}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="caption">
                                                                    {moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY").isAfter(moment(od.despatch, "DD/MM/YYYY")) && <FAIcon icon="exclamation-triangle" size={13.5} className="textError mr-1" />}
                                                                    Expected Despatch {od.despatch}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="caption">{od.customer}</Typography>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <AppButton onClick={() => this.props.history.push("/dashboard")} back text={`Main Menu`} />
                                </Grid>
                            </>
                        )
                    )
                )) || (
                    <>
                        {!inline && (
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={12} sm={4} md={4} xl={3}>
                                        <TextField
                                            fullWidth
                                            onChange={this.onSearchStringChange}
                                            placeholder="Search:"
                                            value={searchString}
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FAIcon type="thin" icon="search" size="small" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} xl={3}>
                                        <ProductSearchSelector
                                            key={key}
                                            placeholder={'Containing Product:'}
                                            noLabel
                                            value={productId}
                                            defaultOption={defaultOptions}
                                            passDefaultOptionsToParent={this.handleSaveDefaultOptions}
                                            variant="filled"
                                            handleAction={this.handleSelectChange('productId')}
                                            hideStock
                                        />
                                    </Grid>
                                    {!status && (
                                        <Grid item>
                                            <Box width={250}>
                                                <AutoCompleteSelect 
                                                    options={orderStatusList} 
                                                    placeholder="Status:" 
                                                    onChange={this.handleSelectChange("orderStatus")} 
                                                    value={orderStatus} 
                                                    variant="filled"
                                                    adornment="filter" 
                                                />
                                            </Box>
                                       </Grid>
                                    )}
                                    {!outstanding && 
                                        status !== "Awaiting Approval" &&
                                        status !== "Awaiting Authorisation" &&
                                        status !== "Awaiting Payment" &&
                                        status !== "Processing" &&
                                        status !== "Awaiting Stock" &&
                                        status !== "Awaiting Supplier" &&
                                        status !== "Awaiting Picking" &&
                                        status !== "Awaiting Check" &&
                                        status !== "Awaiting Technical" &&
                                        status !== "Awaiting Despatch" &&
                                        status !== "On Hold" &&
                                        status !== "Locked" &&
                                        status !== "Despatched" && (
                                            <Grid item>
                                                <Box width={175}>
                                                    <DateSelect onChange={this.handleSelectChange("date")} value={date} />
                                                </Box>
                                            </Grid>
                                        )}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {(!dataLoading && dashboard && _.isEmpty(searchResults) && status === "On Hold" && (
                                <Alert severity="success" variant="outlined">
                                    <strong>None - </strong>There are currently no sales order deliveries on hold that require resolution
                                </Alert>
                            )) ||
                                (!dataLoading && dashboard && _.isEmpty(searchResults) && checkDatePast && (
                                    <Alert severity="success" variant="outlined">
                                        <strong>None - </strong>There are sales order deliveries to chase
                                    </Alert>
                                )) || (
                                    <Paper elevation={inline ? 0 : undefined}>
                                        <SearchOrdersTable
                                            access={access}
                                            buildOrders={buildOrders}
                                            compact={compact}
                                            basic={customerId || bundleId}
                                            callback={this.getSearchData}
                                            dashboard={dashboard}
                                            dataLoading={dataLoading}
                                            inline={inline}
                                            rows={searchResults}
                                            resetSearch={this.resetSearch}
                                            persistenceId={this.persistenceId}
                                            orderStatus={status}
                                            warehouse={warehouse}
                                        />
                                    </Paper>
                                )}
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    ui: state.ui,
    staff: state.notifications.status,
    statePersistence: state.statePersistence,
});

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployDialog: (content, disableDialogContent = false, title = "", variant = "standard", size = "md", fullscreen = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen)),
        playSound: (type, state = false) => dispatch(playSound(type, state)),
        clearPersistence: () => dispatch(clearPersistence()),
        setPersistence: (key, state) => dispatch(setPersistence(key, state)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrders);
