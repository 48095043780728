import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Alert from 'Components/Common/Alert/Alert';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import API from 'API';
import BooleanTile from 'Components/Common/Tiles/BooleanTile';
import DataTable from 'Components/Common/DataTables/DataTable';
import Heading from 'Components/Common/Heading/Heading';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import Tile from 'Components/Common/Tiles/Tile';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { handleTabChange, TabPanel } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';
import { setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

import {
    excelColumnIndexToLetter,
    momentFormatDate,
    momentFormatDateTime,
} from 'Functions/MiscFunctions';

import {
    CLOUDFRONT_URL,
} from 'Constants';

const initialState =() => ({
    access: {
        cancelPriceChanges: false,
        viewProduct: false
    },
    average: null,
    currentTab: 0,
    data: {},
    id: 0,
    isLoading: true,
    priceChangesLive: false,
    priceChangesNothingToDo: true,
    tabText: {
        0: 'Insight about this bulk product data import',
        1: 'Data that was imported from the mapped CSV document',
        2: 'Products that were created as a result of this import',
        3: 'Products that were replaced as a result of this import',
        4: 'Products that were deleted as a result of this import',
        5: 'Product details that were updated as a result of this import',
        6: 'Product pricing that was updated as a result of this import',
    }
})

class ViewBulkManagementBatch extends React.Component {
    constructor(props) {
        super(props);
        this.mainContentArea = React.createRef();
        this.persistenceId = `bulkProductImport:${this.props.match?.params?.id ?? 0}`;
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount() {

        if(!this.hasPageState()) {

            API.multiAccess([
                'view-product',
                'bulk-prd-price-cancel'
            ])
            .then(([
                viewProduct,
                cancelPriceChanges
            ]) =>  {

                let     id          = this.props.match?.params?.id ?? 0,
                        currentTab  = this.props.location?.state?.currentTab ?? 0;
            
                this.setState({
                    access: {
                        ...this.state.access,
                        cancelPriceChanges,
                        viewProduct
                    },
                    currentTab,
                    id,
                }, () => {
                    this.loadComponentData();
                });
                
            });

        } else {
            this.props.pageTitle?.([1, 'Products', 'Bulk Product Management', `Import ${this.state.data?.ref}`])
        }

    }
    
    componentDidUpdate(prevProps) {

        if(prevProps.location?.pathname !== this.props.location?.pathname) {

            this.persistenceId = `bulkProductImport:${this.props.match?.params?.id ?? 0}`;

            if(this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(initialState())
                }, () => {
                    if(this.props.pageTitle)
                        this.props.pageTitle([1, 'Products', 'Bulk Product Management', `Import ${this.state.data?.ref}`])
                });
            } else {
                this.setState({
                    currentTab: 0,
                    id: this.props.match.params.id
                }, () => {            
                    this.setState({
                        isLoading: true
                    }, () => {
                        this.loadComponentData();
                    })
                });
            }
        }
    }

    loadComponentData = () => {

        let {
            id,
            isLoading
        } = this.state;

        let data = null,
            cols = [],
            priceChangesLive = false,
            priceChangesNothingToDo = true;

        let average = {
            listPrice: 0.00,
            buyPrice: 0.00,
            tradePrice: 0.00
        }

        let listPrice = 0.00,
            buyPrice = 0.00,
            tradePrice = 0.00,
            oldListPrice = 0.00,
            newListPrice = 0.00,
            oldBuyPrice = 0.00,
            newBuyPrice = 0.00,
            oldTradePrice = 0.00,
            newTradePrice = 0.00;

        API.get(`/products/bulkManagement/${id}`)
        .then(result => {

            if(result && result.data) {

                if(result.data?.errors) {

                    this.props.history.push('/products/bulk-management')

                } else {

                    data = result.data;

                    isLoading = false;

                    _.each(data.cols, col => {
                        cols.push({
                            heading: col.heading,
                            field: rowData => rowData?.[col.field] ?? "",
                            fieldFormat: !_.isEmpty(col.fieldFormat) ? col.fieldFormat : undefined,
                            sizeToContent: col.sizeToContent
                        })
                    })
                    
                    data.mapping = data.cols;
                    data.cols = cols;

                    if(!_.isEmpty(data.price_changes)) {

                        data.price_changes.forEach(pc => {
                            oldListPrice = (parseFloat(oldListPrice) + parseFloat(pc.or)).toFixed(2);
                            newListPrice = (parseFloat(newListPrice) + parseFloat(pc.nr)).toFixed(2);
                            oldBuyPrice = (parseFloat(oldBuyPrice) + parseFloat(pc.ob)).toFixed(2);
                            newBuyPrice = (parseFloat(newBuyPrice) + parseFloat(pc.nb)).toFixed(2);
                            oldTradePrice = (parseFloat(oldTradePrice) + parseFloat(pc.ot)).toFixed(2);
                            newTradePrice = (parseFloat(newTradePrice) + parseFloat(pc.nt)).toFixed(2);
                        })

                        listPrice = parseFloat(((newListPrice - oldListPrice) / oldListPrice) * 100).toFixed(2);
                        buyPrice = parseFloat(((newBuyPrice - oldBuyPrice) / oldBuyPrice) * 100).toFixed(2);
                        tradePrice = parseFloat(((newTradePrice - oldTradePrice) / oldTradePrice) * 100).toFixed(2);

                        average = {
                            listPrice,
                            buyPrice,
                            tradePrice
                        }

                        priceChangesLive = _.every(_.filter(data.price_changes, el => el.ap === 1), el => el.ac === 1);
                        priceChangesNothingToDo = _.every(data.price_changes, el => el.ap === 0);

                    }

                    this.setState({
                        average,
                        data,
                        isLoading,
                        priceChangesLive,
                        priceChangesNothingToDo
                    }, () => {
                        this.props.pageTitle([1, 'Products', 'Bulk Product Management', `Import ${data?.ref}`])
                        this.savePageState();
                    });
                }

            }

        })
        
    }

    handleCancelPriceChanges = () => {

        let  {
            deploySnackBar
        } = this.props;

        let {
            id,
            isLoading
        } = this.state;

        isLoading = true;

        this.setState({
            isLoading
        }, () => {
                
            API.put(`/products/bulkManagement/${id}/cancelPriceChanges`)
            .then(result => {

                if(result && result.data) {

                    if(result.data?.errors) {

                        deploySnackBar(`error`, `There was an issue cancelling the scheduled price changes, please try again`);

                    } else {

                        deploySnackBar(`success`, `The scheduled price changes from this import have been successfully cancelled`);
                        this.loadComponentData();

                    }

                }

            })

        })

    }


    render = () => {

        const { 
            classes, 
            deployConfirmation,
            ui
        } = this.props;

        const { 
            access, 
            average,
            currentTab,
            data,
            isLoading,
            priceChangesLive,
            priceChangesNothingToDo
        } = this.state;

        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);

        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            Bulk Product Import {data.ref}
                                                        </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                             <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "times",
                                                            label: "Cancel Price Changes",
                                                            onClick: () => deployConfirmation(`Are you sure you want to cancel scheduled price changes?`, this.handleCancelPriceChanges),
                                                            disabled: !access.cancelPriceChanges || priceChangesNothingToDo || priceChangesLive,
                                                            display: !_.isEmpty(data.price_changes)
                                                        },
                                                        {
                                                            icon: "file-csv",
                                                            label: "Download CSV",
                                                            onClick: () => window.open(`${CLOUDFRONT_URL}${data?.pbm_file_key}`),
                                                            display: true
                                                        }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item xs={10} md={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InsightChip
                                                                icon="truck"
                                                                label={`${data?.supplier?.supp_company_name ?? 'Supplier Data'}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid> 
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={12}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={12} md={3}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                        <Heading text="Column Mapping" />
                                                                            <Grid container spacing={3}>   
                                                                                {_.map(_.sortBy(data.mapping, el => el.mapping), (col, idx) => (
                                                                                    <Grid item xs={12} key={idx}>                                                        
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="arrow-right"
                                                                                            title={col.heading}
                                                                                            content={`Column ${excelColumnIndexToLetter(col.mapping  ?? 0)}`}
                                                                                        />
                                                                                    </Grid>
                                                                                ))}
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={9}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12}>
                                                                                <PaddedPaper>
                                                                                    <Heading text="Import Details" />
                                                                                    <Grid container spacing={3}>
                                                                                        <Grid item xs={12} lg={6}>                                                        
                                                                                            <StaffTile
                                                                                                noPaper
                                                                                                title="Processed By"
                                                                                                staffId={data.pbm_staff_id}
                                                                                            />
                                                                                        </Grid> 
                                                                                        <Grid item xs={12} lg={6}>
                                                                                            <Tile
                                                                                                noPaper
                                                                                                icon="calendar-day"
                                                                                                title="Processed On"
                                                                                                content={momentFormatDateTime(data.pbm_created_datetime)}
                                                                                            />
                                                                                        </Grid> 
                                                                                        <Grid item xs={12} lg={6}>                                                        
                                                                                            <BooleanTile
                                                                                                noPaper
                                                                                                title="Includes Header Row"
                                                                                                content={data.pbm_includes_header === 1 ? 'Yes' : 'No'}
                                                                                                isTrue={data.pbm_includes_header === 1}
                                                                                            />
                                                                                        </Grid> 
                                                                                        <Grid item xs={12} lg={6}>
                                                                                            <Tile
                                                                                                noPaper
                                                                                                icon="exclamation-circle"
                                                                                                title="Prices Effective"
                                                                                                content={!_.isEmpty(data.pbm_prices_effective) ? momentFormatDate(data.pbm_prices_effective) : 'N/A'}
                                                                                            />
                                                                                        </Grid> 
                                                                                        <Grid item xs={12} lg={6}>                                                        
                                                                                            <BooleanTile
                                                                                                noPaper
                                                                                                title="Identify New Products"
                                                                                                content={data.pbm_show_new === 1 ? 'Yes' : 'No'}
                                                                                                isTrue={data.pbm_show_new === 1}
                                                                                            />
                                                                                        </Grid> 
                                                                                        <Grid item xs={12} lg={6}>                                                        
                                                                                            <BooleanTile
                                                                                                noPaper
                                                                                                title="Identify Deleted Products"
                                                                                                content={data.pbm_show_missing === 1 ? 'Yes' : 'No'}
                                                                                                isTrue={data.pbm_show_missing === 1}
                                                                                            />
                                                                                        </Grid> 
                                                                                    </Grid>    
                                                                                </PaddedPaper>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <PaddedPaper>
                                                                            <Heading text={`Applied Changes`} />
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        isTrue={true}
                                                                                        title="Total Products"
                                                                                        content={_.size(data.rows)}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        isTrue={data.anew === data.new}
                                                                                        title="New Products"
                                                                                        content={`${data.anew}/${data.new}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        isTrue={data.arep === data.rep}
                                                                                        title="Replaced Products"
                                                                                        content={`${data.arep}/${data.rep}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        isTrue={data.adel === data.del}
                                                                                        title="Deleted Products"
                                                                                        content={`${data.adel}/${data.del}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        isTrue={data.apdc === data.pdc}
                                                                                        title="Product Changes"
                                                                                        content={`${data.apdc}/${data.pdc}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        isTrue={data.aprc === data.prc}
                                                                                        title="Price Changes"
                                                                                        content={`${data.aprc}/${data.prc}`}
                                                                                    />
                                                                                </Grid> 
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    {average && (
                                                                        <Grid item xs={12}>
                                                                            <PaddedPaper>
                                                                                <Heading text="Average Price Changes" />
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item xs={12} lg={4}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            color={average.listPrice > 0 ? '#F44336' : (average.listPrice < 0 ? '#4CAF50' : '#03A9F4')}
                                                                                            icon={average.listPrice > 0 ? 'arrow-from-bottom' : (average.listPrice < 0 ? 'arrow-from-top' : 'minus')}
                                                                                            title="List Price"
                                                                                            content={`${average.listPrice}%`}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} lg={4}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            color={average.buyPrice > 0 ? '#F44336' : (average.buyPrice < 0 ? '#4CAF50' : '#03A9F4')}
                                                                                            icon={average.buyPrice > 0 ? 'arrow-from-bottom' : (average.buyPrice < 0 ? 'arrow-from-top' : 'minus')}
                                                                                            title="Buy Price"
                                                                                            content={`${average.buyPrice}%`}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} lg={4}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            color={average.tradePrice > 0 ? '#F44336' : (average.tradePrice < 0 ? '#4CAF50' : '#03A9F4')}
                                                                                            icon={average.tradePrice > 0 ? 'arrow-from-bottom' : (average.tradePrice < 0 ? 'arrow-from-top' : 'minus')}
                                                                                            title="Trade Price"
                                                                                            content={`${average.tradePrice}%`}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </PaddedPaper>
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Notes" />
                                                                            {(!_.isEmpty(data.pbm_notes) && (
                                                                                data.pbm_notes?.v?.split?.('\n').map((item, key) => (
                                                                                    <Typography 
                                                                                        key={key} 
                                                                                        variant="caption"
                                                                                        component="div" 
                                                                                        paragraph
                                                                                    >
                                                                                        {item}
                                                                                    </Typography>
                                                                                ))
                                                                            )) || (
                                                                                <Typography variant="caption">
                                                                                    No notes were added to this bulk product import
                                                                                </Typography>
                                                                            )}
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: 'i',
                                                            alternatingRowColours: true,
                                                        }}
                                                        columns={data.cols}
                                                        rows={data.rows}

                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: 'id',
                                                            alternatingRowColours: true,
                                                            pagination: true,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    excel: true,
                                                                    name: `batch-import-products`,
                                                                    pdf: true,
                                                                    print: true,
                                                                    title: 'New Products'
                                                                },
                                                            },
                                                            rowProps: rowData => ({
                                                                style: {
                                                                    background: rowData?.ap === 0 ? '#FFEBEE' : '#'
                                                                }
                                                            })
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Created',
                                                                field: rowData => rowData.ap,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'ap',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Product',
                                                                field: rowData => (
                                                                    <Box minWidth={300}>
                                                                        <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                            <Grid item>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    {rowData.ic}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption" component="div">
                                                                                    {rowData.de}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                ),
                                                                dataRef: 'ic',
                                                                main: true,
                                                                truncate: true
                                                            },
                                                            {
                                                                heading: 'Supplier Code',
                                                                field: rowData => rowData.sc,
                                                                dataRef: 'sc',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'List Price',
                                                                field: rowData => rowData.rr,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'rr',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Buy Price',
                                                                field: rowData => rowData.bp,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'bp',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Margin',
                                                                field: rowData => rowData.mg,
                                                                fieldFormat: 'percentage:2',
                                                                dataRef: 'mg',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Trade Price',
                                                                field: rowData => rowData.tp,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'tp',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'View Product', disabled: !rowData.id || !access.viewProduct, icon: 'binoculars', link: `/products/${rowData.id}`},
                                                                    ]
                                                                },
                                                            }
                                                        ]}
                                                        rows={data.new_products}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: 'id',
                                                            alternatingRowColours: true,
                                                            pagination: true,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    excel: true,
                                                                    name: `batch-import-replacements`,
                                                                    pdf: true,
                                                                    print: true,
                                                                    title: 'Replaced Products'
                                                                },
                                                            },
                                                            rowProps: rowData => ({
                                                                style: {
                                                                    background: rowData?.ap === 0 ? '#FFEBEE' : '#'
                                                                }
                                                            })
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Applied',
                                                                field: rowData => rowData.ap,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'ap',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Product',
                                                                field: rowData => (
                                                                    <Box minWidth={300}>
                                                                        <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                            <Grid item>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    {rowData.ic}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption" component="div">
                                                                                    {rowData.de}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                ),
                                                                dataRef: 'ic',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Replaced By',
                                                                field: rowData => (
                                                                    <Box minWidth={300}>
                                                                        <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                            <Grid item>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    {rowData.ri}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption" component="div">
                                                                                    {rowData.re}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                ),
                                                                dataRef: 'ri',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Check P&A',
                                                                field: rowData => rowData.cp,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'cp',
                                                                alignment: 'center',
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'View Product', disabled: !rowData.id || !access.viewProduct, icon: 'binoculars', link: `/products/${rowData.id}`},
                                                                    ]
                                                                },
                                                            }
                                                        ]}
                                                        rows={data.replacement_products}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: 'id',
                                                            alternatingRowColours: true,
                                                            pagination: true,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    excel: true,
                                                                    name: `batch-import-deleted`,
                                                                    pdf: true,
                                                                    print: true,
                                                                    title: 'Deleted Products'
                                                                },
                                                            },
                                                            rowProps: rowData => ({
                                                                style: {
                                                                    background: rowData?.ap === 0 ? '#FFEBEE' : '#'
                                                                }
                                                            })
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Applied',
                                                                field: rowData => rowData.ap,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'ap',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Product',
                                                                field: rowData => (
                                                                    <Box minWidth={300}>
                                                                        <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                            <Grid item>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    {rowData.ic}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption" component="div">
                                                                                    {rowData.de}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                ),
                                                                dataRef: 'ic',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Type',
                                                                field: rowData => rowData.ty,
                                                                dataRef: 'ty',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Discontinued',
                                                                field: rowData => rowData.di,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'di',
                                                                alignment: 'center',
                                                            },
                                                            {
                                                                heading: 'Deleted',
                                                                field: rowData => rowData.dd,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'dd',
                                                                alignment: 'center',
                                                            },
                                                            {
                                                                heading: 'Check P&A',
                                                                field: rowData => rowData.cp,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'cp',
                                                                alignment: 'center',
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'View Product', disabled: !rowData.id || !access.viewProduct, icon: 'binoculars', link: `/products/${rowData.id}`},
                                                                    ]
                                                                },
                                                            }
                                                        ]}
                                                        rows={data.deleted_products}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: 'id',
                                                            alternatingRowColours: true,
                                                            pagination: true,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    excel: true,
                                                                    name: `batch-import-changes`,
                                                                    pdf: true,
                                                                    print: true,
                                                                    title: 'Product Data Changes'
                                                                },
                                                            },
                                                            rowProps: rowData => ({
                                                                style: {
                                                                    background: rowData?.ap === 0 ? '#FFEBEE' : '#'
                                                                }
                                                            })
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Applied',
                                                                field: rowData => rowData.ap,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'ap',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Field',
                                                                field: rowData => rowData.fi,
                                                                dataRef: 'fi',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Product',
                                                                field: rowData => (
                                                                    <Box minWidth={300}>
                                                                        <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                            <Grid item>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    {rowData.ic}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption" component="div">
                                                                                    {rowData.de}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                ),
                                                                dataRef: 'ic',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Value',
                                                                field: rowData => (
                                                                    <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                        <Grid item>
                                                                            <Typography variant="body2" className="fw-400 textSuccess">
                                                                                {rowData.nv}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="body2" className="fw-400 textError">
                                                                                {rowData.pv}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                ),
                                                                dataRef: 'nv',
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'View Product', disabled: !rowData.id || !access.viewProduct, icon: 'binoculars', link: `/products/${rowData.id}`},
                                                                    ]
                                                                },
                                                            }
                                                        ]}
                                                        rows={data.product_changes}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={6}>
                                                <Box mb={3}>
                                                    <Alert severity={!priceChangesLive ? 'warning' : 'success'}>
                                                        <Typography variant="body2" className="fw-400">
                                                            {!priceChangesLive ? 'Price Changes Scheduled' : (priceChangesNothingToDo ? 'Nothing To Do' : 'Price Changes Live')}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            The following product price changes {!priceChangesLive ? 'will be automatically applied on' : (priceChangesNothingToDo ? 'were not applied at the time of import or cancelled' : 'were effective from')} {!priceChangesNothingToDo ? momentFormatDate(data.pbm_prices_effective) : ''}
                                                        </Typography>
                                                    </Alert>
                                                </Box>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: 'id',
                                                            alternatingRowColours: true,
                                                            pagination: true,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    excel: true,
                                                                    name: `batch-import-price-changes`,
                                                                    pdf: true,
                                                                    print: true,
                                                                    title: 'Product Price Changes'
                                                                },
                                                            },
                                                            rowProps: rowData => ({
                                                                style: {
                                                                    background: rowData?.ap === 0 ? '#FFEBEE' : '#'
                                                                }
                                                            })
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Applied',
                                                                field: rowData => rowData.ap,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'ap',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading: 'Product',
                                                                field: rowData => (
                                                                    <Box minWidth={400}>
                                                                        <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                            <Grid item>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    {rowData.ic}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption" component="div">
                                                                                    {rowData.de}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                ),
                                                                dataRef: 'ic',
                                                                main: true,
                                                                truncate: true
                                                            },
                                                            {
                                                                heading: 'List Price',
                                                                field: rowData => rowData.or,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'or',
                                                                sizeToContent: true,
                                                                alignment: 'right'
                                                            },
                                                            {
                                                                heading: 'Buy Price',
                                                                field: rowData => rowData.ob,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'ob',
                                                                sizeToContent: true,
                                                                alignment: 'right'
                                                            },
                                                            {
                                                                heading: 'Margin',
                                                                field: rowData => rowData.om,
                                                                fieldFormat: 'percentage:2',
                                                                dataRef: 'om',
                                                                sizeToContent: true,
                                                                alignment: 'right'
                                                            },
                                                            {
                                                                heading: 'Trade Price',
                                                                field: rowData => rowData.ot,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'ot',
                                                                sizeToContent: true,
                                                                alignment: 'right'
                                                            },
                                                            {
                                                                heading: 'New List Price',
                                                                field: rowData => rowData.nr,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'nr',
                                                                sizeToContent: true,
                                                                alignment: 'right'
                                                            },
                                                            {
                                                                heading: 'New Buy Price',
                                                                field: rowData => rowData.nb,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'nb',
                                                                sizeToContent: true,
                                                                alignment: 'right'
                                                            },
                                                            {
                                                                heading: 'New Margin',
                                                                field: rowData => rowData.nm,
                                                                fieldFormat: 'percentage:2',
                                                                dataRef: 'nm',
                                                                sizeToContent: true,
                                                                alignment: 'right'
                                                            },
                                                            {
                                                                heading: 'New Trade Price',
                                                                field: rowData => rowData.nt,
                                                                fieldFormat: 'currency',
                                                                dataRef: 'nt',
                                                                sizeToContent: true,
                                                                alignment: 'right'
                                                            },
                                                            {
                                                                heading: 'Check P&A',
                                                                field: rowData => rowData.cp,
                                                                fieldFormat: 'boolean',
                                                                dataRef: 'cp',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'View Product', disabled: !rowData.id || !access.viewProduct, icon: 'binoculars', link: `/products/${rowData.id}`},
                                                                    ]
                                                                },
                                                            }
                                                        ]}
                                                        rows={data.price_changes}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Imported Data" />
                                    <Divider />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} disabled={data.new === 0} label={`New Products (${data.new})`} />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} disabled={data.rep === 0} label={`Replaced Products (${data.rep})`} />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} disabled={data.del === 0} label={`Deleted Products (${data.del})`} />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={5} disabled={data.pdc === 0} label={`Product Changes (${data.pdc})`} />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={6} disabled={data.prc === 0} label={`Price Changes (${data.prc})`} />

                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        loggedInStaff: state.staffAuth.staff,
        statePersistence: state.statePersistence,
        ui: state.ui
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        setPersistence: (key, state) => dispatch(setPersistence(key, state))
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ViewBulkManagementBatch));