import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const Heading = ({icon, noMargin, secondary, text}) => (
    <Grid container spacing={1} alignItems='center' className={!noMargin ? 'mb-1' : ''}>
        {icon && (
            <Grid item>
                <FAIcon icon={icon} size={25} noMargin />
            </Grid>
        )}
        <Grid item xs>
            <Typography variant="h6" className={secondary ? 'fw-400' : undefined}>
                {text}
            </Typography>
        </Grid>
    </Grid>
)

export default Heading;