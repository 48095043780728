import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { useDispatch } from 'react-redux';
import Product from './Product';
import { isTablet } from 'Functions/MiscFunctions';

const Defaults = ({classes, defaultAction, defaultReason, defaultReStockingFee, forCustomer, formData, handleChange, handleFloat, handleProductAdd, handleSelectChange}) => {

    const dispatch = useDispatch();
    
    const dispatchDialogClose = () => dispatch(closeDialog())

    const handleAddManualLine = () => {

        dispatch(deployDialog(
            <Product
                closeDialog={dispatchDialogClose}
                defaultAction={defaultAction}
                defaultReason={defaultReason}
                defaultReStockingFee={defaultReStockingFee}
                classes={classes}
                customer={forCustomer}
                customerVatPercent={formData.customerVatPercent}
                handleSubmit={handleProductAdd}
                inDialog
                manual
            />, 
            false, 
            "", 
            "standard", 
            "xl",
            false,
            true
        ))

    }

    const handleAddCarriage = () => {
        dispatch(deployDialog(
            <Product
                closeDialog={dispatchDialogClose}
                defaultAction={defaultAction}
                defaultReason={defaultReason}
                defaultReStockingFee={defaultReStockingFee}
                classes={classes}
                customer={forCustomer}
                customerVatPercent={formData.customerVatPercent}
                handleSubmit={handleProductAdd}
                inDialog
                carriage
            />, 
            false, 
            "", 
            "standard", 
            "xl",
            false,
            true
        ))

    }

    return (
        <Grid container spacing={3} justify="flex-end" alignItems="center">
            <Grid item xs={isTablet() ? 12 : undefined} className="pl-1 pr-0">
                <Box pt={isTablet() ? 0 : 2}>
                    <Button 
                        onClick={handleAddManualLine}
                    >
                        <FAIcon icon="box" button />
                        Add Manual Line
                    </Button>
                    <Button 
                        onClick={handleAddCarriage}
                    >
                        <FAIcon icon="truck" button />
                        Credit Carriage
                    </Button>
                </Box>
            </Grid>
            {!isTablet() && (
                <Grid item xs />
            )}
            <Grid item xs={4} lg={2}>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                    Default Restock Fee
                </Typography>
                <TextField
                    name="defaultReStockingFee"
                    value={formData.defaultReStockingFee}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleFloat}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                    }}
                    inputProp={{
                        min: 0,
                        max: 100,
                        step: 0.50
                    }}
                    className="mb-0"
                    variant="filled"
                />
            </Grid>
            <Grid item xs={4} lg={3}>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                    Default Reason
                </Typography>
                <AutoCompleteSelect
                    options={[
                        {
                            label: "Damaged",
                            value: "DAMAGED"
                        },
                        {
                            label: "Faulty Goods",
                            value: "FAULTY_GOODS"
                        },
                        {
                            label: "No Longer Required",
                            value: "NO_LONGER_REQUIRED"
                        },
                        {
                            label: "Wrong Goods Ordered",
                            value: "WRONG_GOODS_ORDERED"
                        },
                        {
                            label: "Wrong Goods Supplied (AA)",
                            value: "WRONG_GOODS_SUPPLIED_AA"
                        },
                        {
                            label: "Wrong Goods Supplied (Supplier)",
                            value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                        }
                    ]} 
                    value={formData.defaultReason}                                
                    onChange={v => handleSelectChange('defaultReason', v)}
                    noDefaultSort
                    adornment="exclamation-triangle"
                    variant="filled"
                />
            </Grid>
            <Grid item xs={4} lg={3}>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                    Default Action
                </Typography>
                <AutoCompleteSelect
                    options={[
                        {
                            label: "COA - Credit On Account",
                            value: "COA"
                        },
                        {
                            label: "CCR - Credit Card Refund",
                            value: "CCR"
                        },
                        {
                            label: "QFR - Quote For Repair",
                            value: "QFR"
                        },
                        {
                            label: "RPL - Replace",
                            value: "RPL"
                        },
                        {
                            label: "COA/RPL - Credit On Account & Replace",
                            value: "COA_RPL"
                        },
                        {
                            label: "CCR/RPL - Credit Card Refund & Replace",
                            value: "CCR_RPL"
                        },
                        {
                            label: "TBC - To Be Confirmed",
                            value: "TBC"
                        }
                    ]} 
                    value={formData.defaultAction}                                
                    onChange={v => handleSelectChange('defaultAction', v)}
                    noDefaultSort
                    adornment="check"
                    variant="filled"
                />
            </Grid>
        </Grid>
    );
}

export default Defaults;