import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const QuoteTracker = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/quotations',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Quote Number',
                        field: rowData => rowData.r,
                        dataRef: 'r',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Quote Date',
                        field: rowData => rowData.c,
                        dataRef: 'c',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Order Expected',
                        field: rowData => rowData.e,
                        dataRef: 'c',
                        sizeToContent: true
                    }, 
                    {
                        heading: 'Lastest Activity',
                        field: rowData => rowData.la,
                        dataRef: 'la',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.a,
                        dataRef: 'a',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.ca,
                        dataRef: 'ca',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.n,
                        fieldFormat: 'currency',
                        dataRef: 'n',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Margin',
                        field: rowData => rowData.m,
                        fieldFormat: 'percentage:2',
                        dataRef: 'm',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Quoted By',
                        field: rowData => (staff && staff[rowData.s] && <StaffChip staff={staff[rowData.s]} />) || '-',
                        dataRef: 's',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },
                    {
                        heading: 'Salesperson',
                        field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                        dataRef: 'sp',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },
                    {
                        heading: 'Proactive Responsibility',
                        field: rowData => (staff && staff[rowData.pr] && <StaffChip staff={staff[rowData.pr]} />) || '-',
                        dataRef: 'pr',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },
                    {
                        heading: 'Status',
                        field: rowData => rowData.st,
                        dataRef: 'st',
                        important: true,
                        sizeToContent: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'quotations',
                noResultsText: 'Sorry, no quotations were found using your search criteria',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Sales'],
                pdf: true,
                persistenceId: 'quotations',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: true,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showStaff: true,
                showTotals: false,
                title: 'Quotations'
            }}
        />
    )
}

export default QuoteTracker;