import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedError from 'Components/Common/ErrorHandling/PaddedError';
import ViewDiscount from 'Components/Suppliers/Misc/ViewDiscount';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const ViewDiscounts = ({access, callback, discounts, DiscountForm}) => {
    const dispatch = useDispatch();

    const viewDiscount = id => {
        dispatch(deployDialog(
            <ViewDiscount
                id={id}
            />, true, "", "standard", "sm"
        ))
    }

    const handleAdd = () => dispatch(deployDialog(DiscountForm, false, "Add Product Discount", "standard", "xs"));

    const handleModify = id => dispatch(deployDialog(React.cloneElement(DiscountForm, { updateId: id}), false, "Modify Discount", "standard", "xs"));

    const handleDelete = id => dispatch(deployConfirmation("Are you sure you want to delete this discount?", () => confirmDelete(id)));

    const confirmDelete = id => {
        API.put(`/suppliers/discounts/${id}/delete`)
        .then(res => {
            if(res?.data) {
                if(callback) {
                    callback();
                }
            }
        })
    }
    
    return (
        (_.isEmpty(discounts) && (
            <PaddedError>
                There are no product discounts configured for this supplier
            </PaddedError>
        )) || (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable  
                            config={{
                                key: 'supp_disc_id',
                                pagination: true,
                                alternatingRowColours: true,
                                responsiveImportance: true,
                                options: {
                                    headingInput: (
                                        DiscountForm && access.addDiscount && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                onClick={handleAdd}
                                            >
                                                <FAIcon type="light" icon="plus-circle" size={15} button />
                                                Add Product Discount
                                            </Button>
                                        )
                                    ),
                                    dataRef: true,
                                    export: {
                                        title: `Product Discounts`,
                                        name: `supplier-product-discounts`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[  
                                {
                                    heading: 'Discount Name',
                                    field: rowData => rowData.supp_disc_name,
                                    dataRef: 'supp_disc_name',
                                    main: true
                                },   
                                {
                                    heading: 'Percentage',
                                    field: rowData => `${rowData.supp_disc_percentage}%`,
                                    dataRef: 'supp_disc_percent',
                                    alignment: 'right',
                                }, 
                                {
                                    heading: 'Products Assigned',
                                    field: rowData => rowData.products_count,
                                    dataRef: 'supp_disc_name',
                                    alignment: 'right'
                                },    
                                { 
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'binoculars', onClick: () => viewDiscount(rowData.supp_disc_id), doubleClick: true},
                                            {name: 'Modify', icon: 'pencil', onClick: () => handleModify(rowData.supp_disc_id), disabled: !access.modifyDiscount || rowData.products_count > 0},
                                            {name: 'Delete', icon: 'trash-alt', onClick: () => handleDelete(rowData.supp_disc_id), disabled: !access.deleteDiscount || rowData.products_count > 0}
                                        ]
                                    }
                                }
                            ]}
                            rows={discounts}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    )
}

export default ViewDiscounts;