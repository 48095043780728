import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

import {
    closeDialog
} from 'Redux/Actions/Dialog/Dialog';

import {
    deployConfirmation
} from 'Redux/Actions/Confirmation/Confirmation';

const initialState = () => ({
    companyName: 'XXXXX',
    sopNumber: 'XXXXX',
    cardType: null,
    panNumber: '1234 5678 9012 3451',
    startDate: 'DD/MM',
    endDate: 'DD/MM',
    cvv: '123',
    amount: '0.00',
    cardRegisteredToOffice: 'Yes',
    registeredAddress: 'Address 123, Another Line, Wellingborough, Northamptonshire, NN8 6AB',
    staffMember: ''
});

const CardDetailsForm = ({callback, companyName, sopNumber, amount, registeredAddress}) => {

    const [state, setState] = useState(initialState());

    const [showWarning, setShowWarning] = useState(true);

    const dispatch = useDispatch();

    const staff = useSelector(state => state.staffAuth.staff);
    
    const handleChange = e => {

        let { 
            name, 
            value
         } = e.target;

         if(name === 'panNumber') {
            if(state.panNumber.length === 3 && value.length === 4) {
                value = `${value} `;
            }
            if(state.panNumber.length === 8 && value.length === 9) {
                value = `${value} `;
            }
            if(state.panNumber.length === 13 && value.length === 14) {
                value = `${value} `;
            }
        }

        if(name === 'startDate') {
            if(state.startDate.length === 1 && value.length === 2) {
                value = `${value}/`;
            }
        }

        if(name === 'endDate') {
            if(state.endDate.length === 1 && value.length === 2) {
                value = `${value}/`;
            }
        }

        setState({
            ...state,
            [name]: value,
        });

    }

    const confirmation = () => {
        dispatch(deployConfirmation(<><div className="fw-400">Are you sure you want to print the card payment form?</div><div>Please ensure the card details are correct</div></>, submit))
    }

    const submit = () => {
        window.open('/card-form/' + btoa(JSON.stringify(state)), 'Card Payment Form', 'width=1280,height=720');
        dispatch(deployConfirmation(<><div className="fw-400">Did the card payment form print correctly?</div><div>Please select <em>No</em> to re-print the form</div></>, () => { callback?.(); dispatch(closeDialog()); }))
    }

    useEffect(() => {
        setState({
            ...state,
            registeredAddress
        });/* eslint-disable-next-line */
    }, [state.cardRegisteredToOffice])

    useEffect(() => {
        setState({
            ...state,
            companyName,
            sopNumber,
            amount,
            registeredAddress,
            panNumber: '',
            startDate: '',
            endDate: '',
            cvv: '',
            cardRegisteredToOffice: 'Yes',
            staffMember: staff.name
        });/* eslint-disable-next-line */
    }, []);

    const disabled = state.panNumber === '' || state.endDate === '' || state.registeredAddress === '' || state.cvv === '';

    return (
        <Box p={3} pt={4} pb={4}>
            <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                Card Payment Form
            </Typography>
            {showWarning ? (
                <Box>
                    <Box p={3} textAlign={"center"} className="content-light-error" mt={3}>
                        <Box my={2}>
                            <FAIcon icon="hand" className="textError" size={70} noMargin />
                        </Box>
                        <Typography variant="h4" className="fw-600 textError" gutterBottom>
                            ** STOP AND CHECK **
                        </Typography>
                        <Typography variant="h4" className="fw-400" gutterBottom>
                            Please check that call recording has stopped before taking card details
                        </Typography>
                        <Typography variant="h4" className="fw-400">
                            Call recording will resume automatically once the form has printed
                        </Typography>
                        <Box pt={3}>
                            <Button 
                                variant="contained" 
                                color="primary"
                                size="large"
                                onClick={() => setShowWarning(false)}
                            >
                                <FAIcon icon="arrow-right" size={15} />
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <>
                    <Box textAlign='center'>
                        <Typography variant="body2" className="fw-300" align='center' gutterBottom>
                            This document falls under our <strong>'Security policy'</strong> dated February 2016 which outlines how this information should be treated.<br />
                            Users completing this form must comply in full with this procedure.
                        </Typography>
                    </Box>
                    <Box pt={1} textAlign='center'>
                        <Typography variant="body2" gutterBottom align="center">
                            <strong>REMEMBER:</strong> Once completed, this form must be kept confidential and destroyed as soon as payment processing is completed.
                            <br />
                            Management Authorisation is required for payments over £5,000 or payments made using multiple cards.
                        </Typography>
                    </Box>
                    <Box pt={1} textAlign='center'>
                        <Typography variant="body2" gutterBottom align="center" className="fw-400">
                            <FAIcon icon="exclamation-triangle" size={12} className="textError" /> No data is stored electronically or transmitted over a network or other external means.
                        </Typography>
                    </Box>
                    <Box pt={3}>
                        <Table size="small" width="auto">
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>Company Name:</TableCell>
                                    <TableCell>
                                        <Box pt={1} pb={1}>
                                            {state.companyName}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>SOP Number:</TableCell>
                                    <TableCell>
                                        <Box pt={1} pb={1}>
                                            {state.sopNumber}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                        PAN Number:<br />
                                        <em>Long number on front of the card</em>
                                        {state.cardType && <span className="textError">{state.cardType}</span>}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="panNumber"
                                            onChange={handleChange}
                                            value={state.panNumber}
                                            variant="filled"
                                            placeholder={initialState().panNumber}
                                            inputProps={{
                                                maxLength: 19
                                            }}
                                            fullWidth
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                        Start Date:<br />
                                        <em>Debit Cards</em>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="startDate"
                                            onChange={handleChange}
                                            value={state.startDate}
                                            variant="filled"
                                            placeholder={initialState().startDate}
                                            fullWidth
                                            inputProps={{
                                                maxLength: 5
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>End Date:</TableCell>
                                    <TableCell>
                                        <TextField
                                            name="endDate"
                                            onChange={handleChange}
                                            value={state.endDate}
                                            variant="filled"
                                            placeholder={initialState().endDate}
                                            fullWidth
                                            inputProps={{
                                                maxLength: 5
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                        CAV2, CID, CVC2, CVV2:<br />
                                        <em>3 digit code on reverse of card</em>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="cvv"
                                            onChange={handleChange}
                                            value={state.cvv}
                                            variant="filled"
                                            placeholder={initialState().cvv}
                                            fullWidth
                                            inputProps={{
                                                maxLength: 4
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                        Amount (&pound;):
                                    </TableCell>
                                    <TableCell>                                
                                        <Box pt={1} pb={1}>
                                            {state.amount}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                        Is the card registered to the office address?<br />
                                        <em>Check against RED &amp; confirm with the customer</em>
                                    </TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={<Checkbox name="mailshotBar" checked={state.cardRegisteredToOffice === 'Yes'} color="primary" onChange={() => setState({...state, cardRegisteredToOffice: state.cardRegisteredToOffice === 'Yes' ? 'No' : 'Yes'})}/>}
                                            label={<Typography variant="caption">Yes</Typography>}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                        ↪ If not<br />
                                        <em>Address the card is registered to and postcode:</em>
                                    </TableCell>
                                    <TableCell>
                                        {(state.cardRegisteredToOffice === 'Yes' && (
                                            <Box pt={1} pb={1}>
                                                {state.registeredAddress}
                                            </Box>
                                        )) || (
                                            <TextField
                                                name="registeredAddress"
                                                onChange={handleChange}
                                                value={state.registeredAddress}
                                                variant="filled"
                                                fullWidth
                                            />    
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table> 
                    </Box>
                    <Box className='buttonRow'>
                        <Button 
                            disabled={disabled}
                            variant="contained" 
                            color="primary"
                            onClick={confirmation}
                        >
                            <FAIcon icon="check" size={15} />
                            Print
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default CardDetailsForm;