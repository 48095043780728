import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { accountCreditPosition, accountPaymentDueFrom, accountStatus, currencyFormat } from 'Functions/MiscFunctions';
import Textarea from 'Components/Common/Inputs/Textarea';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import API from 'API';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = () => ({
    addMemo: false,
    addCreditMemo: false,
    memo: `${moment().format("D/M/YY")} -`,
    creditMemo: `${moment().format("D/M/YY")} -`,
    updateMemo: false,
    updateCreditMemo: false
})

const AccountsOverview = ({callback, sageLoading, supplier, handleDeployCreditAccount}) => {

    const   dispatch                                = useDispatch(),
            [addMemo, setAddMemo]                   = useState(initialState().addMemo),
            [addCreditMemo, setAddCreditMemo]       = useState(initialState().addCreditMemo),
            [memo, setMemo]                         = useState(initialState().memo),
            [creditMemo, setCreditMemo]             = useState(initialState().creditMemo),
            [updateMemo, setUpdateMemo]             = useState(initialState().updateMemo),
            [updateCreditMemo, setUpdateCreditMemo] = useState(initialState().updateCreditMemo);

    const submitMemo = () => {
        setUpdateMemo(true);
        API.put(`/suppliers/${supplier.supp_id}/sage/memo`, { memo })
        .then(res => {
            if(res?.data) {
                callback?.();
                setUpdateMemo(initialState().updateMemo);
                setAddMemo(initialState().addMemo);
                setMemo(initialState().memo);
                dispatch(deploySnackBar(`success`, `You have successfully updated the supplier's memo...`))
            }
        })
    }

    const submitCreditMemo = () => {
        setUpdateCreditMemo(true);
        API.put(`/suppliers/${supplier.supp_id}/sage/creditMemo`, { creditMemo })
        .then(res => {
            if(res?.data) {
                callback?.();
                setUpdateCreditMemo(initialState().updateCreditMemo);
                setAddCreditMemo(initialState().addCreditMemo);
                setCreditMemo(initialState().creditMemo);
                dispatch(deploySnackBar(`success`, `You have successfully updated the supplier's credit review memo...`))
            }
        })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
                <Paper>
                    <Box p={3}>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Box pt={1}>
                                    <Heading text="Finance Overview" />
                                </Box>
                            </Grid>
                            {(sageLoading && (
                                <Grid item>
                                    <LoadingCircle padding={8} size={20} />
                                </Grid>
                            )) || (
                                <>
                                    {/* <Grid item>
                                        <Tooltip title={'Manage Credit'}>
                                            <IconButton
                                                onClick={handleDeployCreditAccount}
                                            >
                                                <FAIcon icon={'database'} button noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid> */}
                                </>
                            )}
                        </Grid>
                        {(supplier?.supp_sage_data && (
                            <Grid container spacing={1}>
                                {(supplier.supp_has_credit === 1 && (
                                    <>
                                        <Grid item xs={12}>
                                            <Box mb={2}>
                                                <Alert severity="success">
                                                    You have a formal credit facility with this supplier
                                                </Alert>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardHeader
                                                action={
                                                    (sageLoading && (
                                                        <LoadingCircle color='#fff' padding={8} size={20} />
                                                    )) || (
                                                        <Tooltip title="Refresh Credit Status">
                                                            <IconButton onClick={callback}>
                                                                <FAIcon type="light" icon="sync" size="small" noMargin buttonPrimary />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )
                                                }
                                                title={<Typography variant='h3' style={{textAlign: 'center', color: '#fff'}}>{currencyFormat.format(supplier.supp_credit_limit)}</Typography>}
                                                subheader={<Typography variant="subtitle1" style={{textAlign: 'center', color: '#fff'}}>Credit Limit</Typography>}
                                                style={{backgroundColor: '#ef3340', borderRadius: 8}}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon='signature' noMargin size={35} />}
                                                title={<Typography variant='h5'>{!_.isEmpty(supplier?.supp_sage_data?.terms) ? supplier?.supp_sage_data?.terms : 'Not Set'}</Typography>}
                                                subheader={<Typography variant="caption">Account Terms</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={supplier?.supp_sage_data?.termsAgreed && parseInt(supplier?.supp_sage_data?.termsAgreed) === 1 ? 'check' : 'times'} style={{color: supplier?.supp_sage_data?.termsAgreed && parseInt(supplier?.supp_sage_data?.termsAgreed) === 1 ? '#4CAF50' : '#ef3340'}} noMargin size={35} />}
                                                title={<Typography variant='h5'>{supplier?.supp_sage_data?.termsAgreed && parseInt(supplier?.supp_sage_data?.termsAgreed) === 1 ? 'Yes' : 'No'}</Typography>}
                                                subheader={<Typography variant="caption">Terms Agreed</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon='coins' noMargin size={35} />}
                                                title={<Typography variant='h5'>{(supplier.supp_credit_limit !== null && ( currencyFormat.format((parseFloat(supplier.supp_credit_limit) - parseFloat(supplier.supp_credit_balance))) )) || ('N/A')}</Typography>}
                                                subheader={<Typography variant="caption">Available Credit</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={parseFloat(supplier.supp_credit_balance) > parseFloat(supplier.supp_credit_limit) ? 'times' : 'check'} style={{color: (supplier && supplier.supp_credit_balance && supplier.supp_credit_limit && parseFloat(supplier.supp_credit_balance) > parseFloat(supplier.supp_credit_limit) && '#ef3340') || '#4CAF50'}} noMargin size={35} />}
                                                title={<Typography variant='h5'>{currencyFormat.format(parseFloat(supplier.supp_credit_balance))}</Typography>}
                                                subheader={<Typography variant="caption">Balance</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon='info-circle' noMargin size={35} />}
                                                title={<Typography variant='h5'>{supplier.supp_sage_data && supplier.supp_sage_data.paymentDueDays} {supplier.supp_sage_data && accountPaymentDueFrom(supplier.supp_sage_data.paymentDueFrom)}</Typography>}
                                                subheader={<Typography variant="caption">Payment Due</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={(supplier.supp_sage_data && supplier.supp_sage_data.accountStatus === "0") ? `check` : `times`} style={{color: (supplier.supp_sage_data && supplier.supp_sage_data.accountStatus === "0" && '#4CAF50') || '#ef3340'}} noMargin size={35} />}
                                                title={<Typography variant='h5'>{supplier.supp_sage_data && accountStatus(supplier.supp_sage_data.accountStatus)}</Typography>}
                                                subheader={<Typography variant="caption">Account Status</Typography>}
                                                disableTypography
                                            />
                                        </Grid> 
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon='calendar-day' noMargin size={35} />}
                                                title={<Typography variant='h5'>{supplier.supp_sage_data && moment(supplier.supp_sage_data.accountOpened, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY")}</Typography>}
                                                subheader={<Typography variant="caption">Account Opened</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={supplier.supp_sage_data && supplier.supp_sage_data.creditPosition === "0" ? 'check' : 'times'} style={{color: (supplier.supp_sage_data && supplier.supp_sage_data.creditPosition === "0" && '#4CAF50') || '#ef3340'}} noMargin size={35} />}
                                                title={<Typography variant='h5'>{supplier.supp_sage_data && accountCreditPosition(supplier.supp_sage_data.creditPosition)}</Typography>}
                                                subheader={<Typography variant="caption">Credit Position</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                    </>
                                )) || (
                                    <>
                                        <Grid item xs={12}>
                                            <Box mb={2}>
                                                <Alert severity="error">
                                                    You do not have a formal credit facility with this supplier
                                                </Alert>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon='signature' noMargin size={35} />}
                                                title={<Typography variant='h5'>{!_.isEmpty(supplier?.supp_sage_data?.terms) ? supplier?.supp_sage_data?.terms : 'Not Set'}</Typography>}
                                                subheader={<Typography variant="caption">Account Terms</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={supplier?.supp_sage_data?.termsAgreed && parseInt(supplier?.supp_sage_data?.termsAgreed) === 1 ? 'check' : 'times'} style={{color: supplier?.supp_sage_data?.termsAgreed && parseInt(supplier?.supp_sage_data?.termsAgreed) === 1 ? '#4CAF50' : '#ef3340'}} noMargin size={35} />}
                                                title={<Typography variant='h5'>{supplier?.supp_sage_data?.termsAgreed && parseInt(supplier?.supp_sage_data?.termsAgreed) === 1 ? 'Yes' : 'No'}</Typography>}
                                                subheader={<Typography variant="caption">Terms Agreed</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={parseFloat(supplier.supp_credit_balance) > parseFloat(supplier.supp_credit_limit) ? 'times' : 'check'} style={{color: (supplier && supplier.supp_credit_balance && supplier.supp_credit_limit && parseFloat(supplier.supp_credit_balance) > parseFloat(supplier.supp_credit_limit) && '#ef3340') || '#4CAF50'}} noMargin size={35} />}
                                                title={<Typography variant='h5'>{currencyFormat.format(parseFloat(supplier.supp_credit_balance))}</Typography>}
                                                subheader={<Typography variant="caption">Balance</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={(supplier.supp_sage_data && supplier.supp_sage_data.accountStatus === "0") ? `check` : `times`} style={{color: (supplier.supp_sage_data && supplier.supp_sage_data.accountStatus === "0" && '#4CAF50') || '#ef3340'}} noMargin size={35} />}
                                                title={<Typography variant='h5'>{supplier.supp_sage_data && accountStatus(supplier.supp_sage_data.accountStatus)}</Typography>}
                                                subheader={<Typography variant="caption">Account Status</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )) || (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        This supplier has no accounts data in Sage
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={3} pb={1}>
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <Box pt={1}>
                                            <Heading text="Credit Review Memo" />
                                        </Box>
                                    </Grid>
                                    {(sageLoading && (
                                        <Grid item>
                                            <LoadingCircle padding={8} size={20} />
                                        </Grid>
                                    )) || (
                                        <>
                                            <Grid item>
                                                <Tooltip title={addCreditMemo ? 'Cancel' : 'Add to credit memo'}>
                                                    <IconButton
                                                        disabled={addCreditMemo && updateMemo}
                                                        onClick={() => setAddCreditMemo(addCreditMemo ? false : true)}
                                                    >
                                                        <FAIcon icon={addCreditMemo ? 'times' : 'plus'} button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {addCreditMemo && (
                                                <Grid item>
                                                    <Tooltip title={'Confirm'}>
                                                        <IconButton
                                                            disabled={updateMemo || creditMemo === initialState().creditMemo || creditMemo === ""}
                                                            onClick={() => dispatch(deployConfirmation(`Are you sure you want to add the notes to the credit review memo?`, submitCreditMemo))}
                                                        >
                                                            <FAIcon icon={'check'} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                </Grid>
                                {addCreditMemo && (
                                    <>
                                        <Box pt={1} pb={3}>
                                            <Textarea
                                                disabled={updateCreditMemo}
                                                label="Notes:"
                                                onChange={e => setCreditMemo(e?.target?.value ?? '')}
                                                rows={1}
                                                value={creditMemo}
                                                variant="outlined"
                                                onFocus={() => setCreditMemo(`${creditMemo} `)} 
                                                autoFocus
                                            />
                                            {updateCreditMemo && (
                                                <Grid container>
                                                    <Grid item>
                                                        <LoadingCircle padding={6} size={10} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="caption" className="textError pt-1" component="div">
                                                            Please wait whilst the credit review memo is updated...
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Box>
                                    </>
                                )}
                                {(supplier.supp_sage_data && supplier.supp_sage_data.creditMemo && (
                                    supplier.supp_sage_data.creditMemo.split('\r\n').reverse().map((item, key) => {
                                        return <Typography variant="caption" key={key} paragraph>{item}</Typography>
                                    })
                                )) || (
                                    <Typography variant="caption" paragraph>The credit review memo for {supplier.supp_company_name} in Sage is empty</Typography>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={3} pb={1}>
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <Box pt={1}>
                                            <Heading text="Account Memo" />
                                        </Box>
                                    </Grid>
                                    {(sageLoading && (
                                        <Grid item>
                                            <LoadingCircle padding={8} size={20} />
                                        </Grid>
                                    )) || (
                                        <>
                                            <Grid item>
                                                <Tooltip title={addMemo ? 'Cancel' : 'Add to memo'}>
                                                    <IconButton
                                                        disabled={addMemo && updateMemo}
                                                        onClick={() => setAddMemo(addMemo ? false : true)}
                                                    >
                                                        <FAIcon icon={addMemo ? 'times' : 'plus'} button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {addMemo && (
                                                <>
                                                    <Grid item>
                                                        <Tooltip title={'Confirm'}>
                                                            <IconButton
                                                                disabled={updateMemo || memo === initialState().memo || memo === ""}
                                                                onClick={() => dispatch(deployConfirmation(`Are you sure you want to add the notes to the memo?`, submitMemo))}
                                                            >
                                                                <FAIcon icon={'check'} button noMargin />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid> 
                                {addMemo && (
                                    <>
                                        <Box pt={1} pb={3}>
                                            <Textarea
                                                disabled={updateMemo}
                                                label="Notes:"
                                                onChange={e => setMemo(e?.target?.value ?? '')}
                                                rows={1}
                                                value={memo}
                                                variant="outlined"
                                                onFocus={() => setMemo(`${memo} `)} 
                                                autoFocus
                                            />
                                            {updateMemo && (
                                                <Grid container>
                                                    <Grid item>
                                                        <LoadingCircle padding={6} size={10} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="caption" className="textError pt-1" component="div">
                                                            Please wait whilst the memo is updated...
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Box>
                                    </>
                                )}
                                {(supplier.supp_sage_data && supplier.supp_sage_data.memo && (
                                    supplier.supp_sage_data.memo.split('\r\n').reverse().map((item, key) => {
                                        return <Typography variant="caption" key={key} paragraph>{item}</Typography>
                                    })
                                )) || (
                                    <Typography variant="caption" paragraph>The memo for {supplier.supp_company_name} in Sage is empty</Typography>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AccountsOverview;