import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const WildixQueueDetails = ({
    queue
}) => {

    const getFormattedDuration = (e) => {
        if(isNaN(e))return"00:00";var t=parseInt(e/3600)%24,n=parseInt(e/60)%60,r="";return t>0&&(r+=(t<10?"0"+t:t)+":"),r+=(n<10?"0"+n:n)+":"+((e%=60)<10?"0"+e:e) /* eslint-disable-line */
    }

    return (
        _.size(queue.users) > 0 ? (
            <>
                <Box className={"bg-primary textLight"} p={2} textAlign="left" display="flex" alignItems="center">
                    <FAIcon icon={'exclamation-circle'} size={17.5} />
                    <Typography className={`fw-400`} variant="body1">
                        {queue.queue.getName()} Queue ({_.size(queue.users)})
                    </Typography>
                </Box>
                <Box className="content-light-white" p={1} borderTop={0}>
                    <DataTable 
                        config={{
                            key: 'ch',
                            isLoading: false,
                            inline: true,
                            plainPagination: true,
                            responsiveImportance: true,
                            noResultsText: 'No calls queued'
                        }}
                        columns={[
                            {
                                heading: 'Callee',
                                field: rowData => <Box className="fw-400">{rowData.getNumber()}</Box>,
                                main: true,
                            },
                            {
                                heading: 'Duration',
                                field: rowData => getFormattedDuration(rowData.getDuration()),
                                sizeToContent: true,
                            },
                        ]}
                        rows={queue.users}
                    />
                </Box>                             
            </>   
        )   
        : null                       
    )
}

export default WildixQueueDetails;