import _ from 'lodash';
import { closeDialog } from "Redux/Actions/Dialog/Dialog";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import API from "API";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import { Button, Checkbox, Grid, Typography } from "@material-ui/core";
import Textarea from "Components/Common/Inputs/Textarea";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";

const SendEmailNotifications = ({notifications}) => {

    const dispatch = useDispatch();

    const [formData, setFormData ] = useState(notifications);

    const [isLoading, setLoading] = useState(false);

    const handleChange = (idx, field, value) => {
        let temp = [...formData];
        temp[idx][field] = value;
        setFormData(temp);
    }

    const handleClose = () => {
        dispatch(closeDialog());
    }

    const handleSubmit = () => {
        setLoading(true);
        API.post(`/email/send`, {formData})
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `The email notifications have been sent successfully`));
                handleClose();
            }
        })
    }

    useEffect(() => {
        if(notifications.length === 0) {
            handleClose();
        }
    }, [notifications]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoading ? (
            <LoadingCircle />
        ) : (
            <Grid container spacing={3}>
                {formData.map((notification, idx) => (
                    <Grid item xs={12} key={idx} className="p-3">
                        <Grid container spacing={1} className="content-light-white p-1 rounded">
                            <Grid item>
                                <Checkbox color="primary" checked={notification.send === 1} onChange={(e) => handleChange(idx, 'send', notification.send === 1 ? 0 : 1)} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" className="fw-500">{notification.title}</Typography>
                                <Typography variant="body2" className="fw-400">Email Address: {notification.email}{notification.info ? ` • Additional Information: ${notification.info}` : ``}</Typography>
                                {notification.send === 1 ? (
                                    <Textarea 
                                        className="mt-2 mb-1 pr-1" 
                                        variant="outlined" 
                                        label="E-mail Body Text" 
                                        value={notification.text} 
                                        onChange={(e) => handleChange(idx, 'text', e.target.value)} 
                                    />
                                ) : (
                                    <Typography variant="textSecondary"><i>This notification will not be sent</i></Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>  
                ))}
                <Grid item xs={12} className="buttonRow">
                    <Button variant="text" color="primary" onClick={handleClose}>
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={_.some(formData, el => el.text === '') || _.every(formData, el => el.send === 0)}>
                        <FAIcon icon="paper-plane" size={15} button />
                        Send
                    </Button>
                </Grid>
            </Grid>
        )
    )

}

export default SendEmailNotifications;