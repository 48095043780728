import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import AdjustmentForm from "Components/Stock/Misc/AdjustmentForm";
import Dashboard from "Components/Warehouse/Components/Dashboard/Dashboard";
import Error404 from "Components/Common/ErrorHandling/Error404";
import ProcessSalesDelivery from "Components/Warehouse/Actions/ProcessSalesDelivery";
import ProcessPodReceived from "Components/Warehouse/Actions/ProcessPodReceived";
import ProcessRMAReceived from "Components/Warehouse/Actions/ProcessRMAReceived";
import SearchOrders from "Components/Sales/SearchOrders/SearchOrders";
import Incoming from "Components/Warehouse/Components/Incoming/Incoming";
import ConsignmentStockEdit from "Components/Warehouse/Components/ConsignmentStockEdit/ConsignmentStockEdit";

class WarehouseRouteSwitcher extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.changePath(this.props.location.pathname);
        }
    }
    render() {
        const { pageTitle, scrollToTop } = this.props;
        return (
            <Switch>
                <Route exact path="/" render={(props) => <Dashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/dashboard" render={(props) => <Dashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/warehouse/process/:id" render={(props) => <ProcessSalesDelivery {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode />} />
                <Route exact path="/warehouse/outgoing/awaiting-picking" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode status="Awaiting Picking" />} />
                <Route exact path="/warehouse/outgoing/awaiting-check" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode status="Awaiting Check" />} />
                <Route exact path="/warehouse/outgoing/awaiting-despatch" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode status="Awaiting Despatch" />} />
                <Route exact path="/warehouse/outgoing/on-hold" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode status="On Hold" />} />
                <Route exact path="/warehouse/outgoing/ready-for-collection" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode status="Ready for Collection" />} />
                <Route exact path="/warehouse/incoming/purchase-order/:id" render={(props) => <ProcessPodReceived {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode />} />
                <Route exact path="/warehouse/incoming/rma/:id" render={(props) => <ProcessRMAReceived {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode />} />
                <Route exact path="/warehouse/incoming/pod" render={(props) => <Incoming {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode type={"POD"} />} />
                <Route exact path="/warehouse/incoming/rma" render={(props) => <Incoming {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode type={"RMA"} />} />
                <Route exact path="/warehouse/stock/adjustment" render={(props) => <AdjustmentForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode />} />
                <Route exact path="/warehouse/stock/consignment" render={(props) => <ConsignmentStockEdit {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouseMode />} />
                <Route render={(props) => <Error404 {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
            </Switch>
        );
    }
}

export default withRouter(WarehouseRouteSwitcher);
