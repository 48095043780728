import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Alert from '../Alert/Alert';

const ViewContactCard = ({access, contact, handleContactSms, toggleDialog}) => (
    <Box className="content-light-grey" p={3}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs>
                        <Typography variant="h6" gutterBotttom>
                            {`${contact.fn && (contact.fn + ' ')}${contact.ln}`}
                        </Typography>
                        <Typography variant='body2' className="fw-400">
                            {(contact.po && contact.po) || 'Customer Contact'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Close">
                            <IconButton
                                onClick={toggleDialog}
                            >
                                <FAIcon icon="times" size={20} button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Box className="content-light-white" p={3}>
                    <Typography variant="body2" className="fw-400" gutterBottom>
                        Contact Details
                    </Typography>
                    {contact.t && (
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <FAIcon type="light" icon="phone" size="small" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" gutterBottom>
                                    {`${contact.t}`}{contact.prt === 1 ? ' (Preferred)' : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {contact.m && (
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <FAIcon type="light" icon="mobile" size="small" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" gutterBottom>
                                    <Link className="textDefault" disabled={!access.sendSms} href="#" onClick={() => handleContactSms(contact.i, contact.m)}>{`${contact.m}`}{contact.prm === 1 ? ' (Preferred)' : ''}</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {contact.e && (
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <FAIcon type="light" icon="envelope" size="small" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" gutterBottom>
                                    <Link className="textDefault" href={`mailto:${contact.e}`}>{`${contact.e}`}{contact.pre === 1 ? ' (Preferred)' : ''}</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {!contact.t && !contact.m && !contact.e && (
                        <em>No contact details for this contact</em>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Box className="content-light-white" p={3}>
                    <Typography variant="body2" className="fw-400" gutterBottom>
                        Notes
                    </Typography>
                    <Typography variant="body2">
                        {(!_.isEmpty(contact.n) && (
                            contact.n
                        )) || (
                            <em>No notes for this contact</em>
                        )}
                    </Typography>
                </Box>
            </Grid>
            {contact.goi === 0 && (
                <Grid item xs={12}>
                    <Box className="content-light-white">
                        <Alert severity="error">This contact is opted out of GDPR</Alert>
                    </Box>
                </Grid>
            )}
            {contact.msb === 1 && (
                <Grid item xs={12}>
                    <Box className="content-light-white">
                        <Alert severity="error">This contact is on the mailshot bar list!</Alert>
                    </Box>
                </Grid>
            )}
        </Grid>
    </Box>
)

export default ViewContactCard