import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffMember from 'Components/Common/Staff/StaffMember';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { handleCheckedChange } from 'Functions/FormFunctions';

const initialState = () => ({
    formData: {
        applyAccess: 1,
        selected: [],
    }
})

class StaffSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleCheckedChange = handleCheckedChange.bind(this);
    }

    handleMultiSelectChange = (selectedOptions) => {
        let selected = selectedOptions && selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                selected
            }
        })
    }

    handleMultiSelectRemove = (id) => {
        let { 
            formData: {
                selected 
            }
        } = this.state;
        _.remove(selected, function(e) {
            return e === id;
        });
        this.setState({
            formData: {
                ...this.state.formData,
                selected
            }
        })
    }

    handleReset = () => {
        this.setState({
            ...initialState()
        }, () => {
            this.props.cancel?.();
        })
    }

    handleSubmit = () => {
        const { formData } = this.state;
        if(this.props.submit) {
            this.props.submit(formData);
            this.setState({
                ...initialState()
            })
        }
    }

    render = () => {

        const { 
            confirmation, 
            deployConfirmation, 
            staff 
        } = this.props;

        const { 
            formData: {
                applyAccess, 
                selected
            }
         } = this.state;

        return (
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <AutoCompleteMultiSelect
                            label="Staff: "
                            options={this.props.options}
                            value={selected}
                            onChange={v => this.handleMultiSelectChange(v)}
                            hideSelectedOptions
                            noClear
                        />
                    </FormControl>
                    {!_.isEmpty(selected) && (
                        _.map(selected, (staffId, idx) => (
                            <StaffMember 
                                key={idx}
                                staff={staff[staffId]} 
                                size="sm" 
                                noPadding 
                                inlineContent={(
                                    <Grid item align='right'>
                                        <Tooltip title="Remove">
                                            <IconButton
                                                onClick={() => this.handleMultiSelectRemove(staffId)}
                                            >
                                                <FAIcon type="light" icon="times" noMargin button />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                            />
                        ))
                    )}
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch color="primary" checked={applyAccess} onChange={this.handleCheckedChange} name="applyAccess" value={1} />
                        }
                        label={<Typography variant="body2" className="fw-400">Override user access to the team default</Typography>}
                    />
                </Grid>
                <Grid item xs={12} align="right">
                    {/* <Button 
                        onClick={this.handleReset} 
                        variant="text" 
                        color="primary"
                    >
                        <FAIcon icon="times" size={15} button />
                        CANCEL
                    </Button> */}
                    <Button 
                        onClick={confirmation ? () => deployConfirmation(confirmation, this.handleSubmit) : () => this.handleSubmit()} 
                        disabled={selected?.length === 0} 
                        variant="text" 
                        color="primary"
                    >
                        <FAIcon icon="check" size={15} button />
                        Confirm
                    </Button>
                </Grid>  
            </Grid>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(StaffSelector);