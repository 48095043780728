import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedError from 'Components/Common/ErrorHandling/PaddedError';
// import ViewCarriage from 'Components/Suppliers/Misc/ViewCarriage';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const ViewCarriages = ({access, callback, carriage, CarriageForm, defaultOpt, defaultFoc}) => {
    const dispatch = useDispatch();

    // const viewCarriage = id => {
    //     dispatch(deployDialog(
    //         <ViewCarriage
    //             id={id}
    //         />, true, "", "standard", "sm"
    //     ))
    // }

    const handleAdd = () => dispatch(deployDialog(CarriageForm, false, "Add Delivery Method", "standard", "xs"));

    const handleModify = id => dispatch(deployDialog(React.cloneElement(CarriageForm, { updateId: id}), false, "Modify Delivery Method", "standard", "xs"));

    const handleDelete = id => dispatch(deployConfirmation("Are you sure you want to delete this delivery method?", () => confirmDelete(id)));

    const confirmDelete = id => {
        API.put(`/suppliers/carriage/${id}/delete`)
        .then(res => {
            if(res?.data) {
                if(callback) {
                    callback();
                }
            }
        })
    }
    
    return (
        (_.isEmpty(carriage) && (
            <PaddedError>
                There are no purchase order delivery methods configured for this supplier
            </PaddedError>
        )) || (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable  
                            config={{
                                key: 'supp_carr_id',
                                pagination: true,
                                alternatingRowColours: true,
                                responsiveImportance: true,
                                options: {
                                    headingInput: (
                                        CarriageForm && access.addContact && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                onClick={handleAdd}
                                            >
                                                <FAIcon type="light" icon="plus-circle" size={15} button />
                                                Add Delivery Method
                                            </Button>
                                        )
                                    ),
                                    dataRef: true,
                                    export: {
                                        title: `Purchase Order Delivery Methods`,
                                        name: `po-delivery-methods`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[    
                                {
                                    heading: 'Service',
                                    field: rowData => `${rowData.supp_carr_courier} ${rowData.supp_carr_service}${rowData.supp_carr_id === defaultOpt ? ' (Default)' : ''}${rowData.supp_carr_id === defaultFoc ? ' (Default)' : ''}`,
                                    dataRef: 'supp_carr_courier',
                                    important: true
                                },   
                                {
                                    heading: 'Cost',
                                    field: rowData => rowData.supp_carr_cost,
                                    fieldFormat: 'currency',
                                    dataRef: 'supp_carr_cost',
                                    important: true,
                                    sizeToContent: true
                                },
                                { 
                                    actions: rowData => {
                                        return [
                                            // {name: 'View', icon: 'binoculars', onClick: () => viewCarriage(rowData.supp_carr_id), doubleClick: true},
                                            {name: 'Modify', icon: 'pencil', onClick: () => handleModify(rowData.supp_carr_id), disabled: !access.modifyCarriage},
                                            {name: 'Delete', icon: 'trash-alt', onClick: () => handleDelete(rowData.supp_carr_id), disabled: !access.deleteCarriage}
                                        ]
                                    }
                                }
                            ]}
                            rows={carriage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    )
}

export default ViewCarriages;