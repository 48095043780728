import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Notification from 'Components/Common/Notifications/Notification';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewAction from 'Components/ActionCenter/ViewAction';
import { dismissAllNotifications } from 'Redux/Actions/Notifications/Notifications';
import { putNotificationStatus } from 'Redux/Actions/Notifications/Notifications';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { getPresignedUrl } from 'Functions/S3Functions';
import PaddedPaper from '../Paper/PaddedPaper';

const initialState = {
    data: {},
    isLoading: true,
}

class NotificationList extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState
        this.timeout = false;
    }

    componentDidMount = () => {
        this.loadComponentData(false);
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout)
    }

    refreshData = () => {
        this.timeout = setTimeout(() => this.loadComponentData(true), 250);
    }

    loadComponentData = (noLoadingBar) => {
        API.get('/misc/notifications', 
        {
            props: {
                noLoading: noLoadingBar 
            }
        })
        .then(res => {
            if(res.data) {
                this.setState({
                    data: res.data,
                    isLoading: false
                })
            }
        })
    }

    handleNotificationAction = (id, a) => {
        this.props.putNotificationStatus(id, "read");

        let type = a.split(':')[0],
            action = a.split(':').slice(1).join(':');

        switch(type) {
            case "action":
                this.props.deployDialog(<ViewAction id={action} closeDialog={this.props.closeDialog} inDialog />, false, "Action Details", "standard", "xs")
            break;
            case "url":
                this.props.history.push(action);
            break;
            case "external":
                window.open(action);
            break;
            case "s3":
                getPresignedUrl(action);
            break;
            default:
        }
    }

    handleNotificationDismiss = (id, e) => {
        e.stopPropagation();
        this.props.putNotificationStatus(id, "dismissed");
        this.refreshData();
    }

    handleNotificationDismissAll = () => {
        this.props.dismissAllNotifications();
        this.refreshData();
    }

  	render() {
        const { data, isLoading } = this.state;
        const { notifications, status } = this.props;
        let rows = {
            notifications: {
                completed: _.sortBy(data.notifications, function(el) { return el.i }).reverse(),
                outstanding: _.sortBy(notifications, function(el) { return el.i }).reverse()
            }
        }
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>   
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs>
                                        <Typography variant="h6">
                                            New Notifications ({_.size(notifications)})
                                        </Typography>
                                    </Grid>
                                    {!_.isEmpty(notifications) && (
                                        <Grid item>
                                            <Button 
                                                color="primary" 
                                                size="small"
                                                variant="text"
                                                onClick={() => this.handleNotificationDismissAll()}
                                            >   
                                                <FAIcon type="light" icon="times" size="small" buttonPrimary />
                                                Dismiss All 
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <DataTable  
                                            config={{
                                                key: 'i',
                                                noHeader: true,
                                                noPadding: true,
                                                isLoading: isLoading,
                                                noResultsText: "You have no new notifications right now"
                                            }}
                                            columns={[  
                                                {
                                                    heading: '',
                                                    field: rowData => (
                                                        <Notification
                                                            key={rowData.i}
                                                            data={rowData}
                                                            handleAction={this.handleNotificationAction} 
                                                            handleDismiss={this.handleNotificationDismiss}
                                                            status={status}
                                                            enhanced
                                                        />
                                                    ),
                                                },   
                                            ]}
                                            rows={rows.notifications.outstanding}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs>
                                        <Typography variant="h6">
                                            Past Notifications
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable  
                                            config={{
                                                key: 'i',
                                                pagination: true,
                                                noHeader: true,
                                                noPadding: true,
                                                plainPagination: true,
                                                isLoading: isLoading,
                                                rowsPerPage: 5,
                                                noResultsText: "You haven't had any notifications yet"
                                            }}
                                            columns={[  
                                                {
                                                    heading: '',
                                                    field: rowData => (
                                                        <Notification
                                                            key={rowData.i}
                                                            data={rowData}
                                                            handleAction={this.handleNotificationAction} 
                                                            handleDismiss={this.handleNotificationDismiss}
                                                            status={status}
                                                            enhanced
                                                        />
                                                    ),
                                                },   
                                            ]}
                                            rows={rows.notifications.completed}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
        notifications: state.notifications.notifications,
        status: state.notifications.status
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableContent, title, variant, size) => dispatch(deployDialog(content, disableContent, title, variant, size)),
        putNotificationStatus: (id, action) => dispatch(putNotificationStatus(id, action)),
        dismissAllNotifications: () => dispatch(dismissAllNotifications())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationList))