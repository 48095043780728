import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';

const initialState = () => ({
    customers: {},
    customerList: [],
    customerContacts: [],
    customerContactList: [],
    staff: {},
    staffList: [],
    suppliers: {},
    supplierList: [],
    supplierContacts: [],
    supplierContactList: [],
})

class MeetingInviteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        Promise.all([
            API.get('/staff/all', 
                {
                    params: {
                        active: true,
                        forMeeting: true
                    }
                }
            ),
            API.get('/customers/all', 
                {
                    params: {
                        forMeeting: true
                    }
                }
            ),
            API.get('/suppliers/all', 
                {
                    params: {
                        forMeeting: true
                    }
                }
            )
        ])
        .then(([staff, customers, suppliers]) => {
            if(staff?.data && customers?.data && suppliers?.data) {

                const {
                    formData
                } = this.props;

                let staffList = _.map(staff.data, el => {
                        return _.assign({
                            value: el.staff_id,
                            label: `${el.staff_first_name} ${el.staff_last_name}`,
                            disabled: false
                        });
                    }),
                    customerList = _.map(customers.data, el => {
                        return _.assign({
                            value: el.cust_id,
                            label: el.cust_company_name
                        });
                    }),
                    supplierList = _.map(suppliers.data, el => {
                        return _.assign({
                            value: el.supp_id,
                            label: el.supp_company_name
                        });
                    });

                this.setState({
                    customerList,
                    customers: customers.data,
                    staff: staff.data,
                    staffList,
                    supplierList,
                    suppliers: suppliers.data
                }, () => {
                    if(formData.customer) {
                        this.getCustomerContacts(formData.customer);
                    }
                    if(formData.supplier) {
                        this.getSupplierContacts(formData.supplier);
                    }
                })

            }
        })
    }

    getCustomerContacts = id => {
        let customer = _.find(this.state.customers, el => el.cust_id === id),
            customerContacts = customer?.contacts ?? [],
            customerContactList = _.map(customerContacts, el => (
                _.assign({
                    value: el.id,
                    label: `${el.fn} ${el.ln} (${el.mo || 'No mobile number'}) (${el.em || 'No email address'})`
                })
            ));
        this.setState({
            customerContactList,
            customerContacts
        })
    }

    getSupplierContacts = id => {
        let supplier = _.find(this.state.suppliers, el => el.supp_id === id),
            supplierContacts = supplier?.contacts ?? [],
            supplierContactList = _.map(supplierContacts, el => (
                _.assign({
                    value: el.id,
                    label: `${el.fn} ${el.ln} (${el.mo || 'No mobile number'}) (${el.em || 'No email address'})`
                })
            ));
        this.setState({
            supplierContactList,
            supplierContacts
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            let callback = null;
            if(fieldName === 'inviteType') {
                this.setState({
                    customerContacts: initialState().customerContacts,
                    customerContactList: initialState().customerContactList,
                    supplierContacts: initialState().supplierContacts,
                    supplierContactList: initialState().supplierContactList
                });
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: '',
                        staffContact: null,
                        supplier: null,
                        supplierContact: null,
                        customer: null,
                        customerContact: null
                    });
                }
            } else if(fieldName === 'staff') {
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: ''
                    });
                }
            } else if(fieldName === 'supplier') {
                this.getSupplierContacts(selectedOption?.value ?? false);
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: ''
                    });
                }
            } else if(fieldName === 'customer') {                
                this.getCustomerContacts(selectedOption?.value ?? false);
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: ''
                    });
                }
            } else if(fieldName === 'manual') {
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: ''
                    });
                }
            } else if(fieldName === 'supplierContact') {
                if(selectedOption?.value) {
                    const supplierContact = this.state.supplierContacts?.find?.(el => el.id === selectedOption.value);
                    callback = () => {
                        this.props.updateFormData({
                            smsName: `${supplierContact.fn} ${supplierContact.ln}`,
                            smsNumber: supplierContact.mo,
                            emailName: `${supplierContact.fn} ${supplierContact.ln}`,
                            emailAddress: supplierContact.em
                        });
                    }
                } else {
                    callback = () => {
                        this.props.updateFormData({
                            smsName: '',
                            smsNumber: '',
                            emailName: '',
                            emailAddress: ''
                        });
                    }
                }
            } else if(fieldName === 'customerContact') {
                if(selectedOption?.value) {
                    const customerContact = this.state.customerContacts?.find?.(el => el.id === selectedOption.value);
                    callback = () => {
                        this.props.updateFormData({
                            smsName: `${customerContact.fn} ${customerContact.ln}`,
                            smsNumber: customerContact.mo,
                            emailName: `${customerContact.fn} ${customerContact.ln}`,
                            emailAddress: customerContact.em
                        });
                    }
                } else {
                    callback = () => {
                        this.props.updateFormData({
                            smsName: '',
                            smsNumber: '',
                            emailName: '',
                            emailAddress: ''
                        });
                    }
                }
            } else if(fieldName === 'staffContact') {
                if(selectedOption) {
                    const staffContact = this.state.staff.find(obj => {
                        return obj.staff_id === selectedOption.value;
                    })
                    callback = () => {
                        this.props.updateFormData({
                            smsName: staffContact.staff_first_name + ' ' + staffContact.staff_last_name,
                            smsNumber: '',
                            emailName: staffContact.staff_first_name + ' ' + staffContact.staff_last_name,
                            emailAddress: staffContact.staff_email
                        });
                    }
                } else {
                    callback = () => {
                        this.props.updateFormData({
                            smsName: '',
                            smsNumber: '',
                            emailName: '',
                            emailAddress: ''
                        });
                    }
                }
            }

            this.props.handleSelectChange((fieldName === 'customerContact' || fieldName === 'supplierContact') ? 'contact' : fieldName, selectedOption, false, callback);

        })
    }

    render() {
        const { allowNone, formData, formErrors } = this.props;
        const { customerList, customerContactList, staffList, supplierList, supplierContactList } = this.state;

        const inviteTypeOpts = [
            {value: 'staff', label: 'Staff'},
            {value: 'supplier', label: 'Supplier'},
            {value: 'customer', label: 'Customer'},
            {value: 'manual', label: 'Manual'}
        ];

        if(allowNone) {
            inviteTypeOpts.push(
                {value: 'none', label: 'None'}
            );
        }

        return (
            <>
                <AutoCompleteSelect 
                    options={inviteTypeOpts} 
                    label='Invite Type *'
                    value={formData.inviteType}
                    onChange={this.handleSelectChange('inviteType')}
                    error={formErrors && formErrors['inviteType'] && true}
                    errorText={formErrors && formErrors['inviteType']}
                />
                {formData?.inviteType === 'supplier' && (
                <>
                    <AutoCompleteSelect 
                        options={supplierList} 
                        label='Supplier *'
                        value={formData.supplier}
                        onChange={this.handleSelectChange('supplier')}
                        error={formErrors && formErrors['supplier'] && true}
                        errorText={formErrors && formErrors['supplier'] && formErrors['supplier']}
                    />
                    <AutoCompleteSelect
                        options={supplierContactList} 
                        label='Contact *'
                        value={formData.contact}
                        onChange={this.handleSelectChange('supplierContact')}
                        error={formErrors && formErrors['supplierContact'] && true}
                        errorText={formErrors && formErrors['supplierContact']}
                    />
                </>
                )}
                {formData?.inviteType === 'customer' && (
                    <>
                        <AutoCompleteSelect 
                            options={customerList}
                            label='Customer *'
                            value={formData.customer}
                            noOptionsMessage={() => formData.customer?.length >= 1 ? 'No customers were found' : 'Start typing to search customers'}
                            onChange={this.handleSelectChange('customer')}
                            error={formErrors && formErrors['customer'] && true}
                            errorText={formErrors && formErrors['customer'] && formErrors['customer']}
                        />
                        <AutoCompleteSelect 
                            options={customerContactList} 
                            label='Contact *'
                            value={formData.contact}
                            onChange={this.handleSelectChange('customerContact')}
                            error={formErrors && formErrors['customerContact'] && true}
                            errorText={formErrors && formErrors['customerContact']}
                        />
                    </>
                )}
                {formData?.inviteType === 'staff' && (
                    <AutoCompleteSelect
                        options={staffList} 
                        label='Staff Member'
                        value={formData.staffContact}
                        onChange={this.handleSelectChange('staffContact')}
                        error={formErrors && formErrors['staffContact'] && true}
                        errorText={formErrors && formErrors['staffContact']}
                    />
                )}
                {formData.inviteType && formData.inviteType !== 'none' && (
                    <>
                        {formErrors['invitationMethod'] && (
                            <Typography>
                                <>
                                    <Typography variant="body2" color="error">
                                        {formErrors['invitationMethod']}
                                    </Typography>
                                </>
                            </Typography>
                        )}
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Box mt={1}>
                                    <FormControlLabel
                                        control={
                                            <Switch name="sendEmailInvite"
                                                    checked={formData.sendEmailInvite}
                                                    onChange={this.props.handleCheckedChange}
                                                    value={true}
                                                    color="primary"
                                                    margin="none" />
                                        }
                                        label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Send Email Invite</span>}
                                    />
                                </Box>
                                {formData.sendEmailInvite === 1 && (
                                    <>
                                        <Grid item xs={12} className="mt-2">
                                            <TextField
                                                id="emailName"
                                                name="emailName"
                                                label="Name *"
                                                value={formData.emailName}
                                                error={formErrors && formErrors['emailName']}
                                                helperText={formErrors && formErrors['emailName']}
                                                onChange={this.props.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="emailAddress"
                                                name="emailAddress"
                                                label="Email Address *"
                                                value={formData.emailAddress}
                                                error={formErrors && formErrors['emailAddress']}
                                                helperText={formErrors && formErrors['emailAddress']}
                                                onChange={this.props.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                margin="none"
                                                name="emailBody"
                                                value={formData.emailBody}
                                                error={formErrors && formErrors['emailBody']}
                                                onChange={this.props.handleChange}
                                                rows={5}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Box mt={1}>
                                    <FormControlLabel
                                        control={
                                            <Switch name="sendSmsInvite"
                                                    checked={formData.sendSmsInvite}
                                                    onChange={this.props.handleCheckedChange}
                                                    value={true}
                                                    color="primary"
                                                    margin="normal"
                                            />
                                        }
                                        label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Send SMS Invite</span>}
                                    />
                                </Box>
                                {formData.sendSmsInvite === 1 && (
                                    <>
                                        <Grid item xs={12} className="mt-2">
                                            <TextField
                                                id="smsName"
                                                name="smsName"
                                                label="Name *"
                                                value={formData.smsName}
                                                error={formErrors && formErrors['smsName']}
                                                helperText={formErrors && formErrors['smsName']}
                                                onChange={this.props.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="smsNumber"
                                                name="smsNumber"
                                                label="Mobile Number *"
                                                value={formData.smsNumber}
                                                error={formErrors && formErrors['smsNumber']}
                                                helperText={formErrors && formErrors['smsNumber']}
                                                onChange={this.props.handleChange}
                                                margin="none"
                                                type="number"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                margin="none"
                                                name="smsBody"
                                                value={formData.smsBody}
                                                error={formErrors && formErrors['smsBody']}
                                                onChange={this.props.handleChange}
                                                rows={5}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </>
        );
    }
}

export default MeetingInviteForm;