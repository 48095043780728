import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import createFilterOptions from "react-select-fast-filter-options";
import Creatable from 'react-select/creatable';

import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { isTablet } from 'Functions/MiscFunctions';

const components = {
    Control,
    NoOptionsMessage,
    SingleValue,
    ValueContainer,
};

const styles = theme => ({
    
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden'
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
        fontSize: 14
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        fontSize: 16
    }
});

function NoOptionsMessage(props) {
    return (
        <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div style={{height: 'auto', padding: props.variant === "filled" ? (isTablet() ? 3 : 4.5) : (props.variant === "outlined" ? (isTablet() ? 6 : 9) : 0)}} ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            error={props.selectProps.error}
            helperText={props.selectProps.errorText}
            label={props.selectProps.label}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    variant: props.selectProps.variant,
                    ...props.innerProps,
                },
            }}                    
            variant={props.selectProps.variant}
            disabled={props.selectProps.isDisabled}
            InputLabelProps={{
                shrink: !!props.hasValue || props.isFocused || props.selectProps.shrink
            }}
            {...props.selectProps.textFieldProps}
            fullWidth
        />
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <Typography component='div' className={props.selectProps.classes.valueContainer} style={{color: props.selectProps.isDisabled ? 'rgba(0,0,0,0.35)!important' : undefined}}>{props.children}</Typography>;
}

class AutoCompleteMultiSelect extends Component {

    render() {

        const { classes, theme } = this.props;
        let options = this.props.options !== undefined ? this.props.options : [{label:'No Options', value: 0}]

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
            menuList: base => ({
              ...base,
              padding: 0
            }),
            menu: base => ({
                ...base,
                borderRadius: 0,
                width: "max-content",
                minWidth: "100%",
            }),
            menuPortal: base => ({
                ...base,
                zIndex: 9999
            }),
            dropdownIndicator: (base, props) => ({
                ...base,
                paddingRight: 0,
                marginRight: 0,
                paddingLeft: 0,
                transform: 'scale(0.75)',
                height: '30px',
                color: (props.selectProps.error ? '#f44336' : (props.selectProps.isDisabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.54)'))
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: 'none'
            }),
            clearIndicator: (base) => ({
                ...base,
                paddingLeft: 5,
                paddingRight: 5,
                transform: 'scale(0.75)',
                height: '30px',
                cursor: 'pointer'
            }),
            option: (base, props) => ({
                ...base,
                backgroundColor: props.isSelected ? theme.palette.primary.main : (props.isFocused ? theme.palette.primary.opaque : '#ffffff')
            }),
            multiValueLabel: (base) => ({
                ...base,
                color: '#ffffff',
                backgroundColor: theme.palette.primary.main,
                borderRadius: 0,
                fontSize: '13px',
                padding: 3,
                paddingLeft: 8,
                paddingRight: 8
            }),
            multiValueRemove: (base) => ({
              ...base,
              backgroundColor: theme.palette.primary.dark,
              color: '#ffffff',
              borderRadius: 0,
              padding: 4,
              cursor: 'pointer',
              ':hover': {
                backgroundColor: theme.palette.primary.opaque,
                color: 'white',
              },
            }),
            placeholder: (base, props) => ({
                color: theme.palette.secondary.main
            })
        };

        const cleanValue = [];
        if(!this.props.cleanValue) {
            if(Array.isArray(this.props.value)) {
                this.props.value.forEach(val => {
                    const group = this.props.isGrouped ? _.findIndex(this.props.options, optGroup => { return _.find(optGroup.options, option => { return option.value === val }) }) : 0;
                    let realValue = this.props.isGrouped ? (group !== -1 ? this.props.options[group].options.find(option => option.value === val) : this.props.options.find(option => option.value === val)) : this.props.options.find(option => option.value === val);
                    cleanValue.push(realValue);
                });
            }
        }

        const filterOptions = createFilterOptions(
            this.props.options
        );

        const Element = this.props.creatable ? Creatable : Select;
        
        return (
            <FormControl fullWidth>
                <Element
                    ref={this.props.selectRef ? this.props.selectRef : null}
                    isMulti={true}
                    classes={classes}
                    styles={selectStyles}
                    filterOptions={filterOptions}
                    placeholder={this.props.placeholder ? this.props.placeholder : (this.props.creatable ? `Start typing to search or create an option...` : `Start typing to search...`)}
                    label={this.props.label}
                    components={components}
                    options={(options && !this.props.noDefaultSort && _.sortBy(options, function (opt) { if(opt.label && opt.label !== opt.value) { return opt.label.toLowerCase() } else { return '' } })) || options}
                    onChange={this.props.onChange}
                    defaultValue={this.props.defaultValue}
                    menuPortalTarget={document.body}
                    value={this.props.cleanValue ? this.props.value : cleanValue}
                    error={!!this.props.error}
                    errorText={this.props.errorText}
                    isOptionDisabled={(option) => option.disabled === true}
                    isDisabled={this.props.options === undefined || this.props.disabled}
                    noOptionsMessage={this.props.noOptionsMessage}
                    isClearable={!this.props.noClear}
                    shrink={true}
                    variant={this.props.variant}
                />
            </FormControl>
        );
    }
}
  
AutoCompleteMultiSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
  
export default withStyles(styles, { withTheme: true })(AutoCompleteMultiSelect);