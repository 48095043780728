import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import SearchPurchaseOrders from 'Components/Purchases/SearchPurchaseOrders/SearchPurchaseOrders';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Tiles/Tile';
import TaskList from 'Components/Common/Tasks/TaskList';

import Bulletin from 'Components/Dashboard/Common/Bulletin';
import Calendar from 'Components/Dashboard/Common/Calendar';

const WarehouseDashboard = ({pageTitle}) => {

    const [data, setData] = useState(null);

    const loadComponentData = () => {
        API.get(`/dashboard`, {
            params: {
                d: 'warehouse'
            },
            props: {
                cancellation: true
            }
        })
        .then(res => {
            if(res?.data) {

                let newData = res.data,
                    labels = [],
                    av = [],
                    rmaIn = [],
                    goodsIn = [],
                    goodsOut = [];

                if(newData.chart) {

                    for (let i = 0, j = 11; i <= j; i++) {
                        av.push(res.data.chart?.[i]?.av);
                        goodsIn.push(res.data.chart?.[i]?.goodsIn);
                        goodsOut.push(res.data.chart?.[i]?.goodsOut);
                        rmaIn.push(res.data.chart?.[i]?.rmaIn);
                        labels.push(res.data.chart?.[i]?.label);
                    }
                    newData.chart = {
                        labels,
                        datasets: [
                            {
                                label: 'Availability %',
                                data: av,
                                fill: false,
                                backgroundColor: '#673AB7',
                                borderColor: '#D1C4E9',
                                lineTension: 0.25,
                                borderDash: [2.5,2.5]
                            },
                            {
                                label: 'Goods Received',
                                data: goodsIn,
                                fill: false,
                                backgroundColor: '#2196F3',
                                borderColor: '#BBDEFB',
                                lineTension: 0.25,
                            },
                            {
                                label: 'RMA',
                                data: rmaIn,
                                fill: false,
                                backgroundColor: '#ef3340',
                                borderColor: '#FFCDD2',
                                lineTension: 0.25,
                            },
                            {
                                label: 'Boxes Shipped',
                                data: goodsOut,
                                fill: false,
                                backgroundColor: '#4CAF50',
                                borderColor: '#C8E6C9',
                                lineTension: 0.25,
                            }
                        ]
                    }
    
                }

                setData(newData);
            }
        });
    }

    useEffect(() => {
        pageTitle?.([0, 'Dashboard', 'Warehouse']);
        loadComponentData();
    }, [pageTitle]);

    return (
        (!data && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Box 
                                        width='100%'
                                        textAlign='center'
                                    >
                                        <Paper elevation={1} style={{background: data?.rag?.ap > 15 ? '#fff1f3' : (data?.rag?.ap > 3 ? '#fff6e9' : '#eef8ef')}}>
                                            <Box p={2}>
                                                <Typography variant="h3" className="fw-300">
                                                    {data?.rag?.ap}
                                                </Typography>
                                                <Box pt={0.5}>
                                                    <Typography variant="body2" className="fw-300">
                                                        Awaiting Picking
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box 

                                        width='100%'
                                        textAlign='center'
                                    >
                                        <Paper elevation={1} style={{background: data?.rag?.ad > 15 ? '#fff1f3' : (data?.rag?.ad > 3 ? '#fff6e9' : '#eef8ef')}}>
                                            <Box p={2}>
                                                <Typography variant="h3" className="fw-300">
                                                    {data?.rag?.ad}
                                                </Typography>
                                                <Box pt={0.5}>
                                                    <Typography variant="body2" className="fw-300">
                                                        Awaiting Despatch
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box 

                                        width='100%'
                                        textAlign='center'
                                    >
                                        <Paper elevation={1} style={{background: data?.rag?.rc > 15 ? '#fff1f3' : (data?.rag?.rc > 10 ? '#fff6e9' : '#eef8ef')}}>
                                            <Box p={2}>
                                                <Typography variant="h3" className="fw-300">
                                                    {data?.rag?.rc}
                                                </Typography>
                                                <Box pt={0.5}>
                                                    <Typography variant="body2" className="fw-300">
                                                        Ready for Collection
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box 

                                        width='100%'
                                        textAlign='center'
                                    >
                                        <Paper elevation={1} style={{background: data?.rag?.ex > 15 ? '#fff1f3' : (data?.rag?.ex > 10 ? '#fff6e9' : '#eef8ef')}}>
                                            <Box p={2}>
                                                <Typography variant="h3" className="fw-300">
                                                    {data?.rag?.ex}
                                                </Typography>
                                                <Box pt={0.5}>
                                                    <Typography variant="body2" className="fw-300">
                                                        Expected Deliveries
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <Typography variant="h6" paragraph>
                                                Goods Received
                                            </Typography>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6} lg={12}>
                                                    <Tile
                                                        noPaper
                                                        icon='arrow-to-bottom'
                                                        title="This Month"
                                                        content={data?.received?.thisMonth}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={12}>
                                                    <Tile
                                                        noPaper
                                                        icon='history'
                                                        title="Last Month"
                                                        content={data?.received?.lastMonth}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box ml={3} mr={3}>
                                            <Divider />
                                        </Box>
                                        <Box p={3}>
                                            <Typography variant="h5">
                                                This Week
                                            </Typography>
                                            <DataTable
                                                config={{
                                                    noResultsText: "No data to display",
                                                    showFullTotals: true,
                                                    noRowHover: true
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Day',
                                                        field: rowData => rowData.h,
                                                        sizeToContent: true,
                                                        showTotalLabel: true
                                                    },
                                                    {
                                                        heading: 'Deliveries',
                                                        field: rowData => rowData.v,
                                                        sizeToContent: true,
                                                        showTotal: true
                                                    }
                                                ]}
                                                rows={data?.received?.thisWeek ?? []} 
                                            />
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <Typography variant="h6" paragraph>
                                                Despatches
                                            </Typography>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6} lg={12}>
                                                    <Tile
                                                        noPaper
                                                        icon='arrow-from-bottom'
                                                        title="This Month"
                                                        content={data?.despatched?.thisMonth}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={12}>
                                                    <Tile
                                                        noPaper
                                                        icon='history'
                                                        title="Last Month"
                                                        content={data?.despatched?.lastMonth}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box ml={3} mr={3}>
                                            <Divider />
                                        </Box>
                                        <Box p={3}>
                                            <Typography variant="h5">
                                                This Week
                                            </Typography>
                                            <DataTable
                                                config={{
                                                    noResultsText: "No data to display",
                                                    showFullTotals: true,
                                                    noRowHover: true
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Day',
                                                        field: rowData => rowData.h,
                                                        sizeToContent: true,
                                                        showTotalLabel: true
                                                    },
                                                    {
                                                        heading: 'Deliveries',
                                                        field: rowData => rowData.v,
                                                        sizeToContent: true,
                                                        showTotal: true
                                                    }
                                                ]}
                                                rows={data?.despatched?.thisWeek ?? []} 
                                            />
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <Typography variant="h6" paragraph>
                                                Boxes Shipped
                                            </Typography>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6} lg={12}>
                                                    <Tile
                                                        noPaper
                                                        icon='arrow-from-bottom'
                                                        title="This Month"
                                                        content={data?.boxes?.thisMonth}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={12}>
                                                    <Tile
                                                        noPaper
                                                        icon='history'
                                                        title="Last Month"
                                                        content={data?.boxes?.lastMonth}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box ml={3} mr={3}>
                                            <Divider />
                                        </Box>
                                        <Box p={3}>
                                            <Typography variant="h5">
                                                This Week
                                            </Typography>
                                            <DataTable
                                                config={{
                                                    noResultsText: "No data to display",
                                                    showFullTotals: true,
                                                    noRowHover: true
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Day',
                                                        field: rowData => rowData.h,
                                                        sizeToContent: true,
                                                        showTotalLabel: true
                                                    },
                                                    {
                                                        heading: 'Boxes',
                                                        field: rowData => rowData.v,
                                                        sizeToContent: true,
                                                        showTotal: true
                                                    }
                                                ]}
                                                rows={data?.boxes?.thisWeek ?? []} 
                                            />
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Expected Deliveries
                                    </Typography>
                                    <SearchPurchaseOrders               
                                        poStatus='Despatched' 
                                        title='Awaiting Delivery' 
                                        warehouse 
                                        shipHere 
                                        due
                                        inline
                                        />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Operational Insight
                                    </Typography>
                                    <Box height={400} width={'100%'} maxWidth={'100%'} minHeight={300}>
                                        <Line 
                                            data={data.chart} 
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom"
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0,
                                                            stepSize: 200
                                                        }
                                                    }]
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Actions Due Today
                                    </Typography>
                                    <TaskList />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Bulletin />
                        </Grid>
                        <Grid item xs={12}>
                            <Calendar />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

export default WarehouseDashboard;