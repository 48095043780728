import React from 'react';

import Typography from '@material-ui/core/Typography';

import Search from 'Components/Common/Search/Search';

const EulerCover = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/eulerCover',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Customer',
                        field: rowData => rowData.name,
                        dataRef: 'name',
                        important: true,
                        main: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Euler Cover',
                        field: rowData => rowData.euler,
                        fieldFormat: 'currency',
                        dataRef: 'euler',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Net Total Orders',
                        field: rowData => rowData.total ?? '0',
                        fieldFormat: 'currency',
                        dataRef: 'total',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Net Average Order',
                        field: rowData => rowData.avg ?? '0',
                        fieldFormat: 'currency',
                        dataRef: 'avg',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Orders',
                        field: rowData => rowData.orders,
                        dataRef: 'orders',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Credit Customer',
                        field: rowData => rowData.credit,
                        dataRef: 'credit',
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Status',
                        field: rowData => (
                            <>
                                {rowData.team ?? '-'}
                                <Typography variant="caption" component="div">{rowData.status ?? '-'}</Typography>
                            </>
                        ),
                        dataRef: 'status',
                        important: true,
                        sizeToContent: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'euler-cover',
                noResultsText: 'Sorry, there is no credit accounts to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Customers'],
                pdf: true,
                persistenceId: 'eulerCover',
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: true,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Euler Cover Report'
            }}
        />
    )
}

export default EulerCover;