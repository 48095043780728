import React from "react";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import API from "API";
import Action from "Components/Common/Cards/Action";
import TaskForm from "Components/Common/Activity/TaskForm";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import ViewPage from "Components/Common/Styles/ViewPage";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import DataTable from "Components/Common/DataTables/DataTable";
import StaffChip from "Components/Common/Chips/StaffChip";
import TeamChip from "Components/Common/Chips/TeamChip";
import { VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from "Constants";
import { putActionStatus, updateStaffConfig } from "Redux/Actions/Notifications/Notifications";
import { toggleDialog, handleTabChange } from "Functions/MiscFunctions";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { updateAction } from "Redux/Actions/Dialog/Dialog";

const initialState = () => ({
    access: {
        deleteActions: false,
        editActions: false,
        manageActions: false,
    },
    accCodes: [],
    categories: [],
    completed: false,
    displayMy: true,
    displayTeam: true,
    displayAssigned: false,
    displayRange: "today",
    data: {},
    isLoading: true,
    filter: false,
    type: false,
    typeByCode: null
});

class ActionCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleTabChange = handleTabChange.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.timeout = null;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Action Centre"]);
        Promise.all([
            API.access("view-staff:manage-actions"), 
            API.access("actions:edit"), 
            API.access("actions:delete"), 
            API.get(`/misc/actions/types`),
            API.get('/customers/statuses')
        ]).then(
            ([manageActions, editActions, deleteActions, cat, statuses]) => {
                if (cat?.data && statuses?.data) {

                    let categories = {};
                    _.each(cat.data, (cat) => {
                        categories = {
                            ...categories,
                            [cat.tt_name]: {
                                name: cat.tt_name,
                                icon: cat.tt_icon,
                            },
                        };
                    });
                    
                    let accCodes = _.map(statuses.data, el => (
                        _.assign({
                            label: el.status_name,
                            options: _.map(el.children, _el => {
                                return _.assign({
                                    value: _el.status_id,
                                    label: `${_el.status_code} - ${_el.status_name}`
                                });
                            })
                        })
                    ))

                    this.setState(
                        {
                            access: {
                                ...this.state.access,
                                editActions: editActions?.data?.has_access ?? false,
                                deleteActions: deleteActions?.data?.has_access ?? false,
                                manageActions: manageActions?.data?.has_access ?? false,
                            },
                            accCodes,
                            categories,
                        },
                        () => this.loadComponentData(false)
                    );
                }
            }
        );
    };

    componentDidUpdate = (prevProps) => {
        if (
            !_.isEmpty(this.props.status?.[this.props?.loggedInStaff?.id]?.teams) &&
            _.isEmpty(prevProps.status?.[this.props?.loggedInStaff?.id]?.teams)
        ) {
            this.loadComponentData(true);
        }
        if (!this.props.dialog.dialogOpen && prevProps.dialog.dialogOpen) {
            this.loadComponentData(true);
        }
        if (this.props.notifications !== prevProps.notifications) {
            this.loadComponentData(true);
        }
    };

    componentWillUnmount = () => {
        if (this.timeout) clearTimeout(this.timeout);
    };

    refreshData = () => {
        this.timeout = setTimeout(() => this.loadComponentData(true), 250);
    };

    loadComponentData = (noLoadingBar) => {
        const { status, loggedInStaff } = this.props;

        let me = status?.[loggedInStaff?.id] ?? {};

        let t = [];

        if (me) {
            _.each(me.teams, (team) => {
                t.push(team.id);
            });
        }

        t = t.join();

        API.get("/misc/actions", {
            params: {
                t,
            },
            props: {
                noLoading: noLoadingBar,
            },
        }).then((res) => {
            if (res.data) {
                this.setState({
                    data: res.data,
                    isLoading: false,
                });
            }
        });
    };

    handleAction = (action) => {
        if (action) {
            action = action.split(":");
            switch (action[0]) {
                case "url":
                    this.props.history.push(action[1]);
                    break;
                default:
            }
        }
    };

    handleDeployAddTask = () =>
        this.props.deployDialog(
            <TaskForm
                all
                relation="generic"
                toggleDialog={() => {
                    this.props.closeDialog();
                    this.loadComponentData(true);
                }}
            />,
            true,
            "",
            "standard",
            "sm",
            false,
            true
        );

    handleDismiss = (id, e) => {
        e?.stopPropagation?.();
        this.props.putActionStatus(id);
        this.refreshData();
    };

    handleDelete = (id, e) => {
        e?.stopPropagation?.();
        this.props.putActionStatus(id, true);
        this.refreshData();
    }

    handleAccCodeChange = (selectedOption) => {
        this.setState({
            typeByCode: selectedOption?.value ?? null,
        });
    };

    handleDisplayRangeChange = (selectedOption) => {
        this.setState({
            displayRange: selectedOption?.value ?? "today",
        });
    };

    handleCategoryChange = (type, filter) => {
        this.setState({
            typeByCode: initialState().typeByCode,
            type,
            filter,
        });
    };

    handleCompletedChange = (completed) => {
        this.setState({
            completed,
        });
    };

    handleDisplayChange = (display) => {
        this.setState({
            displayMy: display === "assigned" ? false : display === "my" ? !this.state.displayMy : this.state.displayMy,
            displayTeam:
                display === "assigned" ? false : display === "team" ? !this.state.displayTeam : this.state.displayTeam,
            displayAssigned: display === "assigned" ? true : false,
        });
    };

    getRelation = (relation, data) => {
        let icon, prefix, ref, subRef;
        switch (relation) {
            case "contact":
                icon = "user";
                prefix = "Contact";
                ref = `${data.contact_first_name} ${data.contact_last_name} ${data.contact_mobile?.length > 0 ? `| ${data.contact_mobile} ` : (data.contact_telephone > 0 ? `| ${data.contact_telephone} ` : '')}`
                subRef = data.customer?.cust_company_name ?? data.supplier?.supp_company_name ?? "Unknown"
                break;
            case "customer":
                icon = "users";
                prefix = "Customer";
                ref = data?.cust_company_name ?? "Unknown";
                break;
            case "supplier":
                icon = "box-check";
                prefix = "Supplier";
                ref = data?.supp_company_name ?? "Unknown";
                break;
            case "product":
                icon = "boxes";
                prefix = "Product";
                ref = `#${data?.default_code?.code_value ?? data.product_name ?? "Unknown"}`;
                break;
            case "po":
                icon = "purchase";
                prefix = "Purchase Order";
                ref = data?.po_reference ?? "Unknown";
                ref = `Purchase Order #${ref}`;
                subRef = data?.supplier?.supp_company_name ?? null;
                break;
            case "quote":
                icon = "quote";
                prefix = "Quote";
                ref = data?.quote_reference ?? "Unknown";
                ref = `Quote #${ref}`;
                subRef = data?.customer?.cust_company_name ?? null;
                break;
            case "order":
                icon = "order";
                prefix = "Order";
                ref = data?.order_reference ?? "Unknown";
                ref = `Sales Order #${ref}`;
                subRef = data?.customer?.cust_company_name ?? null;
                break;
            case "rma":
                icon = "exchange-alt";
                prefix = "RMA";
                ref = data?.rma_reference ?? "Unknown";
                ref = `RMA #${ref}`;
                subRef = data?.customer?.cust_company_name ?? null;
                break;
            default:
                return false;
        }
        return {
            icon,
            prefix,
            ref,
            subRef,
        };
    };

    getTableViewColumns = () => {
        const { access, completed } = this.state;

        const { loggedInStaff, status, teams } = this.props;

        const staffId = loggedInStaff?.id ?? null;

        const cols = [];

        cols.push(
            {
                heading: "Action",
                field: (rowData) => rowData.t,
                important: true,
                sizeToContent: true,
                main: true,
                cellProps: () => ({
                    className: "fw-400",
                }),
                dataRef: "t",
            },
            {
                heading: 'Notes',
                field: rowData => (
                         _.isEmpty(rowData.c) ? 
                            `No notes added for this action`
                        : (
                            rowData.c?.split?.('\n').map((item, key) => (
                                <Box pb={key !== (_.size(rowData.c?.split?.('\n')) - 1) ? 1 : undefined} key={key}>
                                    {item}
                                </Box>
                            ))
                        )
                ),
                cellProps: () => ({
                    style: {
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        overflow: 'hidden',
                    }
                }),
                dataRef: 'c',
                important: true,
            },
            {
                heading: "For",
                field: (rowData) => {
                    return (
                        (rowData.fr && (
                            <>
                                {rowData.fr}
                                {rowData.sfr && (
                                    <Typography component="div" variant="caption">
                                        {rowData.sfr}
                                    </Typography>
                                )}
                            </>
                        )) ||
                        "-"
                    );
                },
                important: true,
                sizeToContent: true,
                dataRef: "fr",
            },
            {
                heading: "Due",
                field: (rowData) =>
                    (!rowData.w && `-`) || (
                        <>
                            {rowData.w}
                            {rowData.q && (
                                <Typography component="div" variant="caption">
                                    (Every {rowData.q} {rowData.u}
                                    {rowData.q > 1 ? `s` : ``})
                                </Typography>
                            )}
                        </>
                    ),
                sizeToContent: true,
                dataRef: "w",
            }
        );

        if (completed) {
            cols.push({
                heading: "Completed",
                field: (rowData) => rowData.z,
                sizeToContent: true,
                dataRef: "z",
            });
        }

        cols.push(
            {
                heading: "Created",
                field: (rowData) => rowData.wc,
                fieldFormat: 'datetime',
                sizeToContent: true,
                dataRef: "wc",
            },
            {
                heading: "Created By",
                field: (rowData) => (status?.[rowData.f]?.name ? <StaffChip staff={status?.[rowData.f]} /> : `-`),
                fieldFormat: "staff",
                important: true,
                sizeToContent: true,
                dataRef: "f",
            },
            {
                heading: "Assigned To",
                field: (rowData) => (
                    <>
                        {rowData?.tt > 0 && <TeamChip team={teams?.[rowData?.tt]} style={{ marginLeft: -2 }} />}
                        {rowData?.x > 0 && (
                            <StaffChip staff={status?.[rowData.x ?? 0] ?? false} style={{ marginLeft: -2 }} />
                        )}
                    </>
                ),
                important: true,
                sizeToContent: true,
                dataRef: "at",
            }
        );

        // if(completed) {
        //     cols.push(
        //         {
        //             heading: 'Status',
        //             field: rowData => <StatusChip status={!rowData.z || rowData.z === "0000-00-00 00:00:00" ? 'Outstanding' : 'Completed'} />,
        //             sizeToContent: true
        //         }
        //     )
        // }
        if (completed) {
            cols.push({
                actions: (rowData) => {
                    return [
                        {
                            name: "View",
                            icon: "binoculars",
                            onClick: () => this.handleAction(rowData.a),
                            doubleClick: true,
                        },
                    ];
                },
            });
        } else {
            cols.push({
                actions: (rowData) => {
                    return [
                        { name: "Mark as Completed", icon: "check", onClick: () => this.handleDismiss(rowData.i) },
                        {
                            name: "Edit",
                            icon: "pencil-alt",
                            onClick: () => this.handleUpdate(rowData.i),
                            disabled: !access.editActions && (staffId !== rowData.f && staffId !== rowData.x),
                        },
                        {
                            name: "Delete",
                            icon: "trash",
                            onClick: () => this.handleDelete(rowData.i),
                            disabled: !access.deleteActions && (staffId !== rowData.f && staffId !== rowData.x),
                        },
                        {
                            name: "View",
                            icon: "binoculars",
                            onClick: () => this.handleAction(rowData.a),
                            disabled: staffId !== rowData?.f && rowData?.x !== staffId && rowData.tt === 0,
                            doubleClick: true,
                        },
                    ];
                },
            });
        }

        return cols;
    };

    handleUpdate = (id) => {
        this.props.updateAction(id);
    };

    render = () => {

        const { 
            classes, 
            config, 
            loggedInStaff, 
            status, 
            teams, 
            updateStaffConfig, 
            ui 
        } = this.props;

        const {
            access,
            accCodes,
            categories,
            completed,
            data,
            displayAssigned,
            displayMy,
            displayTeam,
            displayRange,
            filter,
            isLoading,
            type,
            typeByCode
        } = this.state;

        const height = window.matchMedia("(display-mode: standalone)").matches
            ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)`
            : ui.device.height;
        const contentHeight = window.matchMedia("(display-mode: standalone)").matches
            ? `calc(env(safe-area-inset-bottom) + ${ui.device.height - VIEW_PAGE_OFFSET}px)`
            : height - VIEW_PAGE_OFFSET;

        let actions = {};

        if (displayAssigned) {
            if (completed) {
                actions = {
                    ...data.assigned_completed,
                };
            } else {
                actions = {
                    ...data.assigned_outstanding,
                };
            }
        } else {
            if (!displayMy && !displayTeam) {
                if (completed) {
                    actions = {
                        ...data.actions_completed,
                        ...data.assigned_completed,
                    };
                } else {
                    actions = {
                        ...this.props.actions,
                        ...data.assigned_outstanding,
                    };
                }
            } else {
                if (completed) {
                    actions = {
                        ...data.actions_completed,
                    };
                } else {
                    actions = {
                        ...this.props.actions,
                    };
                }
                if (displayMy && !displayTeam) {
                    actions = _.filter(actions, (el) => el.tt === -1);
                }
                if (displayTeam && !displayMy) {
                    actions = _.filter(actions, (el) => el.tt !== -1);
                }
            }
        }

        switch (displayRange) {
            case "today":
                actions = _.filter(
                    actions,
                    (el) => !el.w || moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment().endOf("day"))
                );
                break;
            case "thisWeek":
                actions = _.filter(
                    actions,
                    (el) => !el.w || moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment().endOf("isoWeek"))
                );
                break;
            case "thisMonth":
                actions = _.filter(
                    actions,
                    (el) => !el.w || moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment().endOf("month"))
                );
                break;
            default:
            /* No filtering - show all */
        }

        const menu = [
            {
                icon: "home",
                label: "All",
                type: false,
                filter: false,
                divider: false,
                selected: filter === false && type === false,
                badge: _.size(actions),
                obadge: _.size(
                    _.filter(actions, (el) => (el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                ),
            },
        ];

        let idx = 0;
        _.each(categories, (cat) => {
            menu.push({
                icon: cat?.icon ?? "question",
                label: cat?.name ?? "Unknown",
                type: cat?.name ?? "Unknown",
                filter: false,
                divider: _.size(categories) === idx + 1,
                selected: filter === false && type === cat?.name,
                badge: _.size(_.filter(actions, (el) => el.t === cat?.name)),
                obadge: _.size(
                    _.filter(
                        actions,
                        (el) =>
                            el.t === cat?.name &&
                            ((el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                    )
                ),
            });
            idx++;
        });

        menu.push(
            {
                icon: "user",
                label: "Contact",
                type: false,
                filter: "contact",
                divider: false,
                selected: filter === "contact" && type === false,
                badge: _.size(_.filter(actions, (el) => el.r === "contact")),
                obadge: _.size(
                    _.filter(
                        actions,
                        (el) =>
                            el.t === "contact" &&
                            ((el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                    )
                ),
            },
            {
                icon: "users",
                label: "Customer",
                type: false,
                filter: "customer",
                divider: false,
                selected: filter === "customer" && type === false,
                badge: _.size(_.filter(actions, (el) => el.r === "customer")),
                obadge: _.size(
                    _.filter(
                        actions,
                        (el) =>
                            el.t === "customer" &&
                            ((el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                    )
                ),
            },
            {
                icon: "boxes",
                label: "Product",
                type: false,
                filter: "product",
                divider: false,
                selected: filter === "product" && type === false,
                badge: _.size(_.filter(actions, (el) => el.r === "product")),
                obadge: _.size(
                    _.filter(
                        actions,
                        (el) =>
                            el.t === "product" &&
                            ((el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                    )
                ),
            },
            {
                icon: "purchase",
                label: "Purchase Order",
                type: false,
                filter: "po",
                divider: false,
                selected: filter === "po" && type === false,
                badge: _.size(_.filter(actions, (el) => el.r === "po")),
                obadge: _.size(
                    _.filter(
                        actions,
                        (el) =>
                            el.t === "po" && ((el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                    )
                ),
            },
            {
                icon: "quote",
                label: "Quote",
                type: false,
                filter: "quote",
                divider: false,
                selected: filter === "quote" && type === false,
                badge: _.size(_.filter(actions, (el) => el.r === "quote")),
                obadge: _.size(
                    _.filter(
                        actions,
                        (el) =>
                            el.t === "quote" && ((el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                    )
                ),
            },
            {
                icon: "order",
                label: "Sales Order",
                type: false,
                filter: "order",
                divider: false,
                selected: filter === "order" && type === false,
                badge: _.size(_.filter(actions, (el) => el.r === "order")),
                obadge: _.size(
                    _.filter(
                        actions,
                        (el) =>
                            el.t === "order" && ((el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                    )
                ),
            },
            {
                icon: "box-check",
                label: "Supplier",
                type: false,
                filter: "supplier",
                divider: false,
                selected: filter === "supplier" && type === false,
                badge: _.size(_.filter(actions, (el) => el.r === "supplier")),
                obadge: _.size(
                    _.filter(
                        actions,
                        (el) =>
                            el.t === "supplier" &&
                            ((el.w && moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment())) || !el.w)
                    )
                ),
            }
        );

        if (type) {
            actions = _.filter(actions, (el) => el.t === type);
            if(type === "Account Review" && typeByCode) {
                actions = _.filter(actions, el => el.d?.status?.status_id ? parseInt(el.d.status.status_id) === parseInt(typeByCode) : false);
            }
        }

        if (filter) {
            actions = _.filter(actions, (el) => el.r === filter);
        }

        if (completed) {
            actions = _.orderBy(
                actions,
                (el) => (el.w && moment(el.w, "DD-MM-YYYY HH:mm")) || moment(el.z, "DD-MM-YYYY HH:mm"),
                "desc"
            );
        } else {
            actions = _.orderBy(actions, (el) => !el.w || moment(el.w, "DD-MM-YYYY HH:mm"), "asc");
        }

        const grid = {
            1: [],
            2: [],
            3: [],
        };

        let i = 1;

        let tmpActions = [];

        _.each(actions, (action) => {
            const relation = this.getRelation(action.r, action.d);

            let act = {
                ...action,
                at:
                    action?.tt > 0
                        ? teams[action.tt]?.name ?? "-"
                        : action?.x > 0
                        ? status[action.x]?.name ?? "-"
                        : "-",
                fr: relation.ref,
                sfr: relation.subRef,
            };
            tmpActions.push(act);
            grid[i].push(act);
            if (i === 3) {
                i = 1;
            } else {
                i++;
            }
        });

        actions = tmpActions;

        return (
            <Grid container style={{ margin: -24, width: "initial" }}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar
                                style={{
                                    backgroundColor: "#fff",
                                    borderBottom: "1px solid #ddd",
                                    height: VIEW_PAGE_APP_BAR_HEIGHT,
                                }}
                                position="static"
                                elevation={0}
                            >
                                <Box pt={2.5} pr={1.75} pb={1} pl={3}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs>
                                            <Typography variant="h4" className="textDefault pt-1" gutterBottom>
                                                Action Centre
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Paper elevation={0}>
                                                <List
                                                    disablePadding
                                                    className="sm-scrollbar"
                                                    style={{ display: "flex", flexDirection: "row", padding: 0 }}
                                                >
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ fontWeight: 400, fontSize: 12 }}
                                                                >
                                                                    My Actions
                                                                </Typography>
                                                            }
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Checkbox
                                                                color="primary"
                                                                edge="end"
                                                                checked={displayMy}
                                                                onChange={() => this.handleDisplayChange("my")}
                                                                disableRipple
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ fontWeight: 400, fontSize: 12 }}
                                                                >
                                                                    Team Actions
                                                                </Typography>
                                                            }
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Checkbox
                                                                color="primary"
                                                                edge="end"
                                                                checked={displayTeam}
                                                                onChange={() => this.handleDisplayChange("team")}
                                                                disableRipple
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ fontWeight: 400, fontSize: 12 }}
                                                                >
                                                                    Assigned Actions
                                                                </Typography>
                                                            }
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Checkbox
                                                                color="primary"
                                                                edge="end"
                                                                checked={displayAssigned}
                                                                onChange={() => this.handleDisplayChange("assigned")}
                                                                disableRipple
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </List>
                                            </Paper>
                                        </Grid>
                                        <Grid item>
                                            <Box width={160}>
                                                <AutoCompleteSelect
                                                    options={[
                                                        {
                                                            label: "Grid",
                                                            value: "grid",
                                                            icon: "grid-2",
                                                        },
                                                        {
                                                            label: "List",
                                                            value: "list",
                                                            icon: "bars",
                                                        },
                                                        {
                                                            label: "Table",
                                                            value: "table",
                                                            icon: "table-list",
                                                        },
                                                    ]}
                                                    onChange={(e) => {
                                                        e?.stopPropagation?.();
                                                        updateStaffConfig({
                                                            ...config,
                                                            actionCentre: {
                                                                ...config?.actionCentre,
                                                                showAsList: e?.value === "list" ? 1 : 0, //for compatibility with old configs rather than specific value
                                                                showAsTable: e?.value === "table" ? 1 : 0, //for compatibility with old configs rather than specific value
                                                            },
                                                        });
                                                    }}
                                                    value={
                                                        config?.actionCentre?.showAsList !== 1 &&
                                                        config?.actionCentre?.showAsTable !== 1
                                                            ? "grid"
                                                            : config?.actionCentre?.showAsList === 1
                                                            ? "list"
                                                            : "table"
                                                    }
                                                    variant="filled"
                                                    noDefaultSort
                                                    noClear
                                                    small
                                                    formatOptionLabel={({ label, icon }, props) => (
                                                        <Box pt={1} pb={1} pl={1}>
                                                            <Grid container alignItems="center">
                                                                <Grid item>
                                                                    <FAIcon type="thin" icon={icon} size={15} button />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography variant="body2" component="div">
                                                                        <strong>Display: </strong>
                                                                        {label}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box width={200}>
                                                <AutoCompleteSelect
                                                    options={[
                                                        {
                                                            label: "Today",
                                                            value: "today",
                                                        },
                                                        {
                                                            label: "This Week",
                                                            value: "thisWeek",
                                                        },
                                                        {
                                                            label: "This Month",
                                                            value: "thisMonth",
                                                        },
                                                        {
                                                            label: "Anytime",
                                                            value: "all",
                                                        },
                                                    ]}
                                                    onChange={this.handleDisplayRangeChange}
                                                    value={displayRange}
                                                    variant="filled"
                                                    noDefaultSort
                                                    small
                                                    formatOptionLabel={({ label }) => (
                                                        <Box pt={1} pb={1} pl={1}>
                                                            <Grid container alignItems="center">
                                                                <Grid item>
                                                                    <FAIcon
                                                                        type="thin"
                                                                        icon="calendar-alt"
                                                                        size={15}
                                                                        button
                                                                    />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography variant="body2" component="div">
                                                                        <strong>Due: </strong>
                                                                        {label}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )}
                                                    noClear
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box width={200}>
                                                <AutoCompleteSelect
                                                    options={[
                                                        {
                                                            label: "Completed",
                                                            value: "complete",
                                                            icon: "check-circle",
                                                        },
                                                        {
                                                            label: "Outstanding",
                                                            value: "outstanding",
                                                            icon: "exclamation-circle",
                                                        },
                                                    ]}
                                                    onChange={(o) =>
                                                        this.handleCompletedChange(
                                                            o?.value === "complete" ? true : false
                                                        )
                                                    }
                                                    value={completed ? "complete" : "outstanding"}
                                                    variant="filled"
                                                    noDefaultSort
                                                    small
                                                    formatOptionLabel={({ label, icon }) => (
                                                        <Box pt={1} pb={1} pl={1}>
                                                            <Grid container alignItems="center">
                                                                <Grid item>
                                                                    <FAIcon type="thin" icon={icon} size={15} button />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography variant="body2" component="div">
                                                                        <strong>Status: </strong>
                                                                        {label}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )}
                                                    noClear
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>
                        <Grid item className={classes.fullWidth} style={{ height: contentHeight - 90 }}>
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4} md={3} lg={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <List disablePadding className="sm-scrollbar">
                                                        {_.map(menu, (item, idx) => (
                                                            <>
                                                                <ListItem
                                                                    button
                                                                    key={idx}
                                                                    onClick={() =>
                                                                        this.handleCategoryChange(
                                                                            item.type,
                                                                            item.filter
                                                                        )
                                                                    }
                                                                    selected={item.selected}
                                                                >
                                                                    <ListItemIcon>
                                                                        <FAIcon
                                                                            type="light"
                                                                            icon={item.icon ?? "home"}
                                                                            size={15}
                                                                            button
                                                                            noMargin
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                style={{
                                                                                    fontWeight: 400,
                                                                                    fontSize: 12,
                                                                                }}
                                                                            >
                                                                                {item.label}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                    <Badge
                                                                        badgeContent={item.obadge}
                                                                        color="primary"
                                                                        showZero={false}
                                                                        max={99}
                                                                        style={{ right: 50, bottom: 8 }}
                                                                    >
                                                                        <ListItemSecondaryAction />
                                                                    </Badge>
                                                                    <Badge
                                                                        badgeContent={item.badge}
                                                                        showZero={false}
                                                                        max={99}
                                                                        style={{ right: 8 }}
                                                                    >
                                                                        <ListItemSecondaryAction />
                                                                    </Badge>
                                                                </ListItem>
                                                                {item.divider && <Divider />}
                                                            </>
                                                        ))}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={9} lg={10}>
                                        <Grid container spacing={3}>
                                            {type === "Account Review" && (
                                                <Grid item xs={12}>
                                                    <Box maxWidth={350}>
                                                        <AutoCompleteSelect
                                                            options={accCodes}
                                                            onChange={
                                                                this.handleAccCodeChange
                                                            }
                                                            value={typeByCode}
                                                            variant="filled"
                                                            noDefaultSort
                                                            small
                                                            placeholder='Filter by account code:'
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                            {(!_.isEmpty(actions) &&
                                                (((config?.actionCentre?.showAsTable === 1 ||
                                                    ui?.device?.isTablet) && (
                                                    <Grid item xs={12}>
                                                        <Paper>
                                                            <DataTable
                                                                config={{
                                                                    key: "i",
                                                                    pagination: true,
                                                                    alternatingRowColours: true,
                                                                    responsiveImportance: true,
                                                                    plainPagination: true,
                                                                    rowsPerPage: 10,
                                                                    noResultsText: !completed
                                                                        ? "There are no outstanding actions to display"
                                                                        : "There are no actions to display",
                                                                    options: {
                                                                        dataRef: true,
                                                                        export: {
                                                                            excel: true,
                                                                            name: `${loggedInStaff?.name}-actions`,
                                                                            pdf: true,
                                                                            print: true,
                                                                            title: `Actions - ${loggedInStaff?.name}`,
                                                                        },
                                                                    },
                                                                }}
                                                                columns={this.getTableViewColumns()}
                                                                rows={actions}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                )) ||
                                                    (config?.actionCentre?.showAsList === 1 && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3}>
                                                                {_.map(actions, (action, key) => (
                                                                    <Grid item xs={12} key={key}>
                                                                        <Action
                                                                            noDivider
                                                                            inline
                                                                            compact
                                                                            xl
                                                                            elevation={1}
                                                                            categories={categories}
                                                                            data={action}
                                                                            handleAction={this.handleAction}
                                                                            handleDismiss={this.handleDismiss}
                                                                            handleDelete={this.handleDelete}
                                                                            status={status}
                                                                            staffId={
                                                                                (loggedInStaff &&
                                                                                    loggedInStaff.id) ||
                                                                                0
                                                                            }
                                                                            access={access}
                                                                            enhanced
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                    )) || (
                                                        <>
                                                            <Grid item xs={4}>
                                                                <Grid container spacing={3}>
                                                                    {_.map(grid[1], (action, idx) => (
                                                                        <Grid item xs={12}>
                                                                            <Action
                                                                                categories={categories}
                                                                                data={action}
                                                                                handleAction={this.handleAction}
                                                                                handleDismiss={
                                                                                    this.handleDismiss
                                                                                }
                                                                                handleDelete={this.handleDelete}
                                                                                status={status}
                                                                                staffId={
                                                                                    (loggedInStaff &&
                                                                                        loggedInStaff.id) ||
                                                                                    0
                                                                                }
                                                                                access={access}
                                                                                enhanced
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Grid container spacing={3}>
                                                                    {_.map(grid[2], (action, idx) => (
                                                                        <Grid item xs={12}>
                                                                            <Action
                                                                                categories={categories}
                                                                                data={action}
                                                                                handleAction={this.handleAction}
                                                                                handleDismiss={
                                                                                    this.handleDismiss
                                                                                }
                                                                                handleDelete={this.handleDelete}
                                                                                status={status}
                                                                                staffId={
                                                                                    (loggedInStaff &&
                                                                                        loggedInStaff.id) ||
                                                                                    0
                                                                                }
                                                                                access={access}
                                                                                enhanced
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Grid container spacing={3}>
                                                                    {_.map(grid[3], (action, idx) => (
                                                                        <Grid item xs={12}>
                                                                            <Action
                                                                                categories={categories}
                                                                                data={action}
                                                                                handleAction={this.handleAction}
                                                                                handleDismiss={
                                                                                    this.handleDismiss
                                                                                }
                                                                                handleDelete={this.handleDelete}
                                                                                status={status}
                                                                                staffId={
                                                                                    (loggedInStaff &&
                                                                                        loggedInStaff.id) ||
                                                                                    0
                                                                                }
                                                                                access={access}
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ))) || (
                                                <Grid item xs={12} align="center">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Box pt={5}>
                                                                <FAIcon
                                                                    icon="thumbs-up"
                                                                    type="thin"
                                                                    size={50}
                                                                    noMargin
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">Up To Date!</Typography>
                                                            <Typography variant="subtitle1">
                                                                {(!type &&
                                                                    !filter &&
                                                                    `You have no${
                                                                        displayAssigned ? "t assigned any" : ""
                                                                    } actions right now`) ||
                                                                    (!filter &&
                                                                        type &&
                                                                        `You have no${
                                                                            displayAssigned
                                                                                ? "t assigned any"
                                                                                : ""
                                                                        } ${_.find(
                                                                            menu,
                                                                            (el) => el.selected
                                                                        )?.label?.toLowerCase()} to action right now`) ||
                                                                    `You have no${
                                                                        displayAssigned ? "t assigned any" : ""
                                                                    } ${_.find(
                                                                        menu,
                                                                        (el) => el.selected
                                                                    )?.label?.toLowerCase()} actions right now`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        config: state.notifications.config,
        dialog: state.dialog,
        loggedInStaff: state.staffAuth.staff,
        actions: state.notifications.actions,
        notifications: state.notifications.notifications,
        status: state.notifications.status,
        teams: state.notifications.teams,
        ui: state.ui,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (
            content,
            disableDialogContent = false,
            title = "",
            variant = "standard",
            size = "md",
            fullscreen = false,
            de = false
        ) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, de)),
        putActionStatus: (id, del = false) => dispatch(putActionStatus(id, del)),
        updateStaffConfig: (config) => dispatch(updateStaffConfig(config)),
        updateAction: (id) => dispatch(updateAction(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ActionCenter));
