import React  from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import ActionTypes from 'Components/Settings/Actions/ActionTypes/ActionTypes';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InsightChip from 'Components/Common/Chips/InsightChip';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { TabPanel, handleTabChange } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const initialState = {
    access: {
        actionTypes: false,
    },
    currentTab: 0,
    isLoading: true,
    tabs: {
        0: "Action Types",
    },
    tabText: {
        0: "Create and manage existing action types",
    }
}

class SettingsActions extends React.Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Settings', 'Action Centre']);
        API.multiAccess([
            'settings-actions:types'
        ])
        .then(([
            actionTypes
        ]) => {
            this.setState({
                access: {
                    actionTypes
                },
                isLoading: false
            });
        })
    }

    render() {
        const { classes, scrollToTop, ui } = this.props;
        const { access, currentTab, isLoading, tabs, tabText } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabs[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        {access.actionTypes && (
                                            <TabPanel value={currentTab} index={0}>
                                                <ActionTypes scrollToTop={scrollToTop} />
                                            </TabPanel>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {access.actionTypes && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Action Types" />
                                )}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps, null)(withStyles(ViewPage)(SettingsActions));