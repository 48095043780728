import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
/* Dashboard */
import Dashboard from "Components/Dashboard/Dashboard";
/* Action Center */
import ActionCenter from "Components/ActionCenter/ActionCenter";
/* Accounts */
import AccountOpeningRequests from "Components/Accounts/AccountOpeningRequests/AccountOpeningRequests";
import AccountApplicationFormRequests from "Components/Accounts/AccountApplicationForm/AccountApplicationFormRequests";
import CreditReportRequests from "Components/Accounts/CreditReportRequests/CreditReportRequests";
import SageLog from "Components/Accounts/SageLog/SageLog";
import SearchCreditNotes from "Components/Accounts/SearchCreditNotes/SearchCreditNotes";
import SearchInvoices from "Components/Accounts/SearchInvoices/SearchInvoices";
import SearchTransactions from "Components/Accounts/SearchTransactions/SearchTransactions";
/* CardDB */
import CardOrderSearch from "Components/CardDB/CardOrderSearch";
/* Calendar */
import Calendar from "Components/Calendar/Calendar";
/* Common */
import QuoteOrderForm from "Components/Common/Forms/QuoteOrderForm/QuoteOrderForm";
/* Communication */
import Communication from "Components/Communication/Communication/Communication";
/* Customers */
import CustomerSearch from "Components/Customers/CustomerSearch/CustomerSearch";
import CustomerForm from "Components/Customers/Misc/CustomerForm";
import TradeZoneRequests from "Components/Customers/TradeZoneRequests/TradeZoneRequests";
import ProfileUpdate from "Components/Customers/ProfileUpdate/ProfileUpdate";
import ViewCustomer from "Components/Customers/ViewCustomer/ViewCustomer";
/* My Account */
import MyAccount from "Components/MyAccount/MyAccount";
/* Marketing */
import CustomiseCommunication from "Components/Marketing/Communication/Communication";
import CustomiseDocuments from "Components/Marketing/Documents/Documents";
import MailingListExport from "Components/Marketing/MailingListExport/MailingListExport";
import Catalogues from "Components/Marketing/Requests/Catalogues/Catalogues";
import CustomerSocialMedia from "Components/Marketing/Requests/CustomerSocialMedia/CustomerSocialMedia";
import Shortlinks from "Components/Marketing/Shortlinks/Shortlinks";
import Website from "Components/Marketing/Website/Website";
/* Meetings */
import MeetingSearch from "Components/Meetings/MeetingSearch/MeetingSearch";
/* New Business */
import NewBusinessDashboard from "Components/NewBusiness/Dashboard/Dashboard";
/* Products */
import NewBulkManagementBatch from "Components/Products/BulkManagement/NewBulkManagementBatch";
import SearchBulkManagementBatches from "Components/Products/BulkManagement/SearchBulkManagementBatches";
import ViewBulkManagementBatch from "Components/Products/BulkManagement/ViewBulkManagementBatch";
import SearchBundles from "Components/Products/Bundles/SearchBundles";
import ViewBundle from "Components/Products/Bundles/ViewBundle";
import ProductForm from "Components/Products/Misc/ProductForm";
import ProductSearch from "Components/Products/ProductSearch/ProductSearch";
import ViewProduct from "Components/Products/ViewProduct/ViewProduct";
/* Purchase Orders */
import ProcessPurchaseOrder from "Components/Purchases/Actions/ProcessPurchaseOrder";
import PurchaseOrderForm from "Components/Purchases/PurchaseOrderForm/PurchaseOrderForm";
import PurchaseOrderIfmForm from "Components/Purchases/PurchaseOrderForm/PurchaseOrderIfmForm";
import SearchPurchaseOrders from "Components/Purchases/SearchPurchaseOrders/SearchPurchaseOrders";
import ViewPurchaseOrder from "Components/Purchases/ViewPurchaseOrder/ViewPurchaseOrder";
/* Quotes */
import SearchQuotes from "Components/Quotes/SearchQuotes/SearchQuotes";
import ViewQuote from "Components/Quotes/ViewQuote/ViewQuote";
/* Reporting */
import ReportingCall from "Components/Reporting/Call/Call";
import ReportingCustomers from "Components/Reporting/Customers/Customers";
import ReportingMarketing from "Components/Reporting/Marketing/Marketing";
import ReportingMisc from "Components/Reporting/Misc/Misc";
import ReportingProducts from "Components/Reporting/Products/Products";
import ReportingSales from "Components/Reporting/Sales/Sales";
import ReportingStaff from "Components/Reporting/Staff/Staff";
import ReportingStock from "Components/Reporting/Stock/Stock";
import ReportingSuppliers from "Components/Reporting/Suppliers/Suppliers";
/* RMA */
import RMAForm from "Components/RMA/RMAForm/RMAForm";
import SearchRMA from "Components/RMA/SearchRMA/SearchRMA";
import ViewRMA from "Components/RMA/ViewRMA/ViewRMA";
/* Sales */
import ProcessSalesOrder from "Components/Sales/Actions/ProcessSalesOrder";
import SearchOrders from "Components/Sales/SearchOrders/SearchOrders";
import ViewOrder from "Components/Sales/ViewOrder/ViewOrder";
/* Settings */
import SettingsAccounts from "Components/Settings/Accounts/Accounts";
import SettingsActions from "Components/Settings/Actions/Actions";
import SettingsCalendar from "Components/Settings/Calendar/Calendar";
import SettingsCarriage from "Components/Settings/Carriage/Carriage";
import SettingsCustomers from "Components/Settings/Customers/Customers";
import SettingsDiscounts from "Components/Settings/Discounts/Discounts";
import SettingsDocuments from "Components/Settings/Documents/Documents";
import SettingsECommerce from "Components/Settings/Ecommerce/Ecommerce";
import SettingsOrders from "Components/Settings/Orders/Orders";
import SettingsProducts from "Components/Settings/Products/Products";
import SettingsPurchaseOrders from "Components/Settings/PurchaseOrders/PurchaseOrders";
import SettingsQuotes from "Components/Settings/Quotes/Quotes";
import SettingsSuppliers from "Components/Settings/Suppliers/Suppliers";
import SettingsWarehouse from "Components/Settings/Warehouse/Warehouse";
/* Staff */
import StaffSearch from "Components/Staff/StaffSearch/StaffSearch";
import ViewStaff from "Components/Staff/ViewStaff/ViewStaff";
/* Stock */
import StockManagement from "Components/Stock/Dashboard/Dashboard";
/* Suppliers */
import SupplierForm from "Components/Suppliers/Misc/SupplierForm";
import SupplierSearch from "Components/Suppliers/SupplierSearch/SupplierSearch";
import ViewSupplier from "Components/Suppliers/ViewSupplier/ViewSupplier";
/* Support  */
import Support from "Components/Support/Support";
/* Targets  */
import Targets from "Components/Targets/Targets";
/* Teams */
import TeamSearch from "Components/Teams/TeamSearch/TeamSearch";
import ViewTeam from "Components/Teams/ViewTeam/ViewTeam";
/* Technical  */
import CompletedBuilds from "Components/Technical/CompletedBuilds/CompletedBuilds";
/* Warehouse */
import ProcessSalesDelivery from "Components/Warehouse/Actions/ProcessSalesDelivery";
import SearchConsignmentStockEdits from "Components/Warehouse/SearchConsignmentStockEdits/SearchConsignmentStockEdits";
import SearchWarehouse from "Components/Warehouse/SearchWarehouse/SearchWarehouse";
/* Error Handling */
import Error404 from "Components/Common/ErrorHandling/Error404";
import { handleAppError } from "Redux/Actions/UI/UI";

class AdminRouteSwitcher extends React.Component {
    componentDidUpdate = (prevProps) => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.changePath(this.props.location.pathname);
        }
    };

    render() {
        const { pageTitle, scrollToTop } = this.props;
        return (
            <Switch>
                {/* Dashboard */}
                <Route exact path="/" render={(props) => <Dashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/dashboard" render={(props) => <Dashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Action Center */}
                <Route exact path="/action-centre" render={(props) => <ActionCenter {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Accounts */}
                <Route exact path="/accounts/account-opening/credit" render={(props) => <AccountOpeningRequests credit={true} {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/accounts/account-opening/prepay" render={(props) => <AccountOpeningRequests prepay={true} {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/accounts/profile-updates" render={(props) => <ProfileUpdate forAccounts {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/accounts/credit-notes" render={(props) => <SearchCreditNotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/accounts/invoices" render={(props) => <SearchInvoices {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/accounts/credit/all" render={(props) => <CustomerSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} showCreditOnly />} />
                <Route exact path="/accounts/credit/lapsed" render={(props) => <CustomerSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} showLapsedOnly />} />
                <Route exact path="/accounts/credit/over-limit" render={(props) => <CustomerSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} showOverlimitOnly />} />
                <Route exact path="/accounts/account-application-form/requests" render={(props) => <AccountApplicationFormRequests {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/accounts/online-credit-applications" render={(props) => <TradeZoneRequests type="CREDIT_ACCOUNT" {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/accounts/credit-reports/requests" render={(props) => <CreditReportRequests {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/accounts/payments/all" render={(props) => <SearchTransactions {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="" showPaymentsOnly />} />
                <Route exact path="/accounts/payments/pending" render={(props) => <SearchTransactions {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Pending" showPaymentsOnly />} />
                <Route exact path="/accounts/refunds/all" render={(props) => <SearchTransactions {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="" showRefundsOnly />} />
                <Route exact path="/accounts/refunds/pending" render={(props) => <SearchTransactions {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Pending" showRefundsOnly />} />
                <Route exact path="/accounts/sage" render={(props) => <SageLog {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Calendar */}
                <Route exact path="/calendar" render={(props) => <Calendar {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Card DB */}
                <Route exact path="/card-orders/new" render={(props) => <CustomerForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/card-orders/update/:id" render={(props) => <CustomerForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/card-orders/:id" render={(props) => <ViewCustomer {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/card-orders" render={(props) => <CardOrderSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Communication */}
                <Route exact path="/communication" render={(props) => <Communication {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Customers */}
                {/* <Route exact path='/customers/new'                                                  render={(props) => <CustomerForm                        {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} /> */}
                <Route exact path='/customers/onboarding/:requestId' render={(props) => <CustomerForm forProspect {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/customers/onboarding" render={(props) => <TradeZoneRequests type="TRADE_ACCOUNT" {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/customers/profile-updates" render={(props) => <ProfileUpdate forAdmin {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/customers/update/:id" render={(props) => <CustomerForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/customers/:id" render={(props) => <ViewCustomer {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/customers" render={(props) => <CustomerSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Marketing */}
                <Route exact path="/marketing/trade-zone-requests" render={(props) => <TradeZoneRequests type="ONLINE_ACCOUNT" {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/marketing/requests/catalogues" render={(props) => <Catalogues {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/marketing/requests/social-media" render={(props) => <CustomerSocialMedia {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/marketing/mailing-list-export" render={(props) => <MailingListExport {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/marketing/communication" render={(props) => <CustomiseCommunication {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/marketing/documents" render={(props) => <CustomiseDocuments {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/marketing/shortlinks" render={(props) => <Shortlinks {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/marketing/website" render={(props) => <Website {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Meetings */}
                <Route exact path="/meetings" render={(props) => <MeetingSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* New Business */}
                <Route exact path='/new-business/new' render={(props) => <CustomerForm forProspect {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/new-business/update/:id" render={(props) => <CustomerForm forProspect {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/new-business/:id" render={(props) => <ViewCustomer {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} isProspect />} />
                <Route exact path="/new-business" render={(props) => <NewBusinessDashboard {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Products */}
                <Route exact path="/products/recently-added" render={(props) => <ProductSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} recentlyAdded />} />
                <Route exact path="/products/bulk-management/new" render={(props) => <NewBulkManagementBatch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/products/bulk-management/:id" render={(props) => <ViewBulkManagementBatch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/products/bulk-management" render={(props) => <SearchBulkManagementBatches {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/products/bundles/:id" render={(props) => <ViewBundle {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/products/bundles" render={(props) => <SearchBundles {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/products/new" render={(props) => <ProductForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/products/update/:id" render={(props) => <ProductForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/products/:id" render={(props) => <ViewProduct {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/products" render={(props) => <ProductSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Purchase Orders */}
                <Route exact path="/purchase-orders/process/:id" render={(props) => <ProcessPurchaseOrder {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/purchase-orders/outstanding" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="" outstanding />} />
                <Route exact path="/purchase-orders/awaiting-submission" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="Awaiting Submission" />} />
                <Route exact path="/purchase-orders/awaiting-confirmation" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="Awaiting Confirmation" />} />
                <Route exact path="/purchase-orders/awaiting-despatch" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="Awaiting Despatch" />} />
                <Route exact path="/purchase-orders/despatched" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="Despatched" />} />
                <Route exact path="/purchase-orders/invoiced" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="Invoiced" />} />
                <Route exact path="/purchase-orders/cancelled" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="Cancelled" />} />
                <Route exact path="/purchase-orders/sales-order-fulfilment" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} type="Sales Order" />} />
                <Route exact path="/purchase-orders/stock-replenishment" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} type="Stock" />} />
                <Route exact path="/purchase-orders/on-hold" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="On Hold" onHold />} />
                <Route exact path="/purchase-orders/new/:supplier" render={(props) => <PurchaseOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/purchase-orders/new" render={(props) => <PurchaseOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/purchase-orders/replicate/:id" render={(props) => <PurchaseOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} replicate />} />
                <Route exact path="/purchase-orders/live-update/:id" render={(props) => <PurchaseOrderIfmForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/purchase-orders/update/:id" render={(props) => <PurchaseOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/purchase-orders/:id" render={(props) => <ViewPurchaseOrder {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/purchase-orders" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="" />} />

                {/* Quotes */}
                <Route exact path="/quotes/my" render={(props) => <SearchQuotes myQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/quotes/hot" render={(props) => <SearchQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} hot />} />
                <Route exact path="/quotes/draft" render={(props) => <SearchQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Draft" />} />
                <Route exact path="/quotes/awaiting-approval" render={(props) => <SearchQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Approval" />} />
                <Route exact path="/quotes/open" render={(props) => <SearchQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Open" />} />
                <Route exact path="/quotes/ordered" render={(props) => <SearchQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Ordered" />} />
                <Route exact path="/quotes/lost" render={(props) => <SearchQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Lost" />} />
                <Route exact path="/quotes/declined" render={(props) => <SearchQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Declined" />} />
                <Route exact path="/quotes/new/:customer" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Quotes" document="Quote" apiRoute="quotes" />} />
                <Route exact path="/quotes/new" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Quotes" document="Quote" apiRoute="quotes" />} />
                <Route exact path="/quotes/replicate/:id" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Quotes" document="Quote" apiRoute="quotes" replicate />} />
                <Route exact path="/quotes/order/:id" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Sales Orders" document="Order" apiRoute="quotes" placeOrder />} />
                <Route exact path="/quotes/update/:id" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Quotes" document="Quote" apiRoute="quotes" />} />
                <Route exact path="/quotes/:id" render={(props) => <ViewQuote {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/quotes" render={(props) => <SearchQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* My Account */}
                <Route exact path="/my-account/:tab" render={(props) => <MyAccount {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/my-account" render={(props) => <MyAccount {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Reporting */}
                <Route exact path="/reporting/call" render={(props) => <ReportingCall {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/reporting/customers" render={(props) => <ReportingCustomers {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/reporting/marketing" render={(props) => <ReportingMarketing {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/reporting/misc" render={(props) => <ReportingMisc {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/reporting/products" render={(props) => <ReportingProducts {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/reporting/sales" render={(props) => <ReportingSales {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/reporting/staff" render={(props) => <ReportingStaff {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/reporting/stock" render={(props) => <ReportingStock {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/reporting/suppliers" render={(props) => <ReportingSuppliers {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* RMA */}
                <Route exact path="/rma/awaiting-approval" render={(props) => <SearchRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status={"Awaiting Approval"} />} />
                <Route exact path="/rma/declined" render={(props) => <SearchRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status={"Declined"} />} />
                <Route exact path="/rma/open" render={(props) => <SearchRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status={"Open"} />} />
                <Route exact path="/rma/complete" render={(props) => <SearchRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status={"Complete"} />} />
                <Route exact path="/rma/goods-received" render={(props) => <SearchRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status={"Goods Received"} />} />
                <Route exact path="/rma/with-supplier" render={(props) => <SearchRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status={"With Supplier"} />} />
                <Route exact path="/rma/cancelled" render={(props) => <SearchRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status={"Cancelled"} />} />
                <Route exact path="/rma/new" render={(props) => <RMAForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/rma/new/sales-order/:orderId" render={(props) => <RMAForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/rma/new/:customer" render={(props) => <RMAForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/rma/replacement/:rma" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Sales Orders" document="Order" apiRoute="sales" />} />
                <Route exact path="/rma/approve/:id" render={(props) => <RMAForm forApproval {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/rma/update/:id" render={(props) => <RMAForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/rma/:id" render={(props) => <ViewRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/rma" render={(props) => <SearchRMA {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Sales */}
                <Route exact path="/sales/process/:id" render={(props) => <ProcessSalesOrder {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/sales/outstanding" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="" outstanding />} />
                <Route exact path="/sales/my" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="" myOrders />} />
                <Route exact path="/sales/awaiting-approval" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Approval" />} />
                <Route exact path="/sales/awaiting-payment" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Payment" />} />
                <Route exact path="/sales/awaiting-authorisation" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Authorisation" />} />
                <Route exact path="/sales/processing" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Processing" />} />
                <Route exact path="/sales/awaiting-allocation" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Allocation" />} />
                <Route exact path="/sales/awaiting-stock" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Stock" />} />
                <Route exact path="/sales/awaiting-supplier" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Supplier" />} />
                <Route exact path="/sales/awaiting-picking" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Picking" />} />
                <Route exact path="/sales/awaiting-check" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Check" />} />
                <Route exact path="/sales/awaiting-technical" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Technical" />} />
                <Route exact path="/sales/awaiting-despatch" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Despatch" />} />
                <Route exact path="/sales/ready-for-collection" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Ready for Collection" />} />
                <Route exact path="/sales/on-hold" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="On Hold" />} />
                <Route exact path="/sales/pending-release" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Locked" />} />
                <Route exact path="/sales/despatched" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Despatched" />} />
                <Route exact path="/sales/invoiced" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Invoiced" />} />
                <Route exact path="/sales/cancelled" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Cancelled" />} />
                <Route exact path="/sales/new/:customer" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Sales Orders" document="Order" apiRoute="sales" />} />
                <Route exact path="/sales/new" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Sales Orders" document="Order" apiRoute="sales" />} />
                <Route exact path="/sales/replicate/:id" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Sales Orders" document="Order" apiRoute="sales" replicate />} />
                <Route exact path="/sales/update/:id" render={(props) => <QuoteOrderForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} section="Sales Orders" document="Order" apiRoute="sales" />} />
                <Route exact path="/sales/:id" render={(props) => <ViewOrder {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/sales" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="" />} />

                {/* Settings */}
                <Route exact path="/settings/accounts" render={(props) => <SettingsAccounts {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/actions" render={(props) => <SettingsActions {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/calendar" render={(props) => <SettingsCalendar {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/customers" render={(props) => <SettingsCustomers {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/delivery" render={(props) => <SettingsCarriage {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/discounts" render={(props) => <SettingsDiscounts {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/e-commerce" render={(props) => <SettingsECommerce {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/files" render={(props) => <SettingsDocuments {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/products" render={(props) => <SettingsProducts {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/purchase-orders" render={(props) => <SettingsPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/sales-orders" render={(props) => <SettingsOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/quotes" render={(props) => <SettingsQuotes {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/suppliers" render={(props) => <SettingsSuppliers {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/settings/warehouse" render={(props) => <SettingsWarehouse {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Staff */}
                <Route exact path="/staff/:id" render={(props) => <ViewStaff {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/staff" render={(props) => <StaffSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Stock */}
                <Route exact path="/stock" render={(props) => <StockManagement {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Suppliers */}
                <Route exact path="/suppliers/new" render={(props) => <SupplierForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/suppliers/update/:id" render={(props) => <SupplierForm {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/suppliers/:id" render={(props) => <ViewSupplier {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/suppliers" render={(props) => <SupplierSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Support */}
                <Route exact path="/support" render={(props) => <Support {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Targets */}
                <Route exact path="/targets" render={(props) => <Targets {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Teams */}
                <Route exact path="/teams/:id" render={(props) => <ViewTeam {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/teams" render={(props) => <TeamSearch {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Technical */}
                <Route exact path="/technical/builds/completed" render={(props) => <CompletedBuilds {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/technical/builds/outstanding" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} status="Awaiting Technical" buildOrders />} />

                {/* Warehouse */}
                <Route exact path="/warehouse/process/:id" render={(props) => <ProcessSalesDelivery {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path="/warehouse/outgoing/awaiting-stock" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouse status="Awaiting Stock" />} />
                <Route exact path="/warehouse/outgoing/awaiting-picking" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouse status="Awaiting Picking" />} />
                <Route exact path="/warehouse/outgoing/awaiting-check" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouse status="Awaiting Check" />} />
                <Route exact path="/warehouse/outgoing/awaiting-technical" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouse status="Awaiting Technical" />} />
                <Route exact path="/warehouse/outgoing/awaiting-despatch" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouse status="Awaiting Despatch" />} />
                <Route exact path="/warehouse/outgoing/ready-for-collection" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouse status="Ready for Collection" />} />
                <Route exact path="/warehouse/outgoing/despatched" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouse status="Despatched" />} />
                <Route exact path="/warehouse/outgoing/on-hold" render={(props) => <SearchOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} warehouse status="On Hold" onHold />} />
                <Route exact path="/warehouse/incoming/awaiting-delivery" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="Despatched" title="Awaiting Delivery" warehouse shipHere due />} />
                <Route exact path="/warehouse/incoming/delivered" render={(props) => <SearchPurchaseOrders {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} poStatus="Despatched" title="Delivered" warehouse shipHere delivered />} />
                <Route exact path="/warehouse/picks" render={(props) => <SearchWarehouse {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} type="picks" />} />
                <Route exact path="/warehouse/checks" render={(props) => <SearchWarehouse {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} type="checks" />} />
                <Route exact path="/warehouse/despatches" render={(props) => <SearchWarehouse {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} type="despatches" />} />
                <Route exact path="/warehouse/collections" render={(props) => <SearchWarehouse {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} type="collections" />} />
                <Route exact path="/warehouse/consignment-stock-edits" render={(props) => <SearchConsignmentStockEdits {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* 404 - Page Not Found */}
                <Route render={(props) => <Error404 {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
            </Switch>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(AdminRouteSwitcher));
