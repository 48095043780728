import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = () => ({
    formData: {
        code: {
            0: ' ',
            1: ' ',
            2: ' ',
            3: ' ',
            4: ' ',
            5: ' ',
        },
    },
    formErrors: []
})

class UpdateStaffPin extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.timeout = null;
        this.code0 = React.createRef();
        this.code1 = React.createRef();
        this.code2 = React.createRef();
        this.code3 = React.createRef();
        this.code4 = React.createRef();
        this.code5 = React.createRef();
    }    

    componentWillUnmount = () => {
        if(this.timeout) {
            clearTimeout(this.timeout)
        }
    }
    
    handleCodeChange = (id, e) => {

        const { deployConfirmation } = this.props;

        if(id === 0 && e.target.value.length >= 1) {
            this.code1.current.focus();
        } else if(id > 0 && id < 5 && e.target.value.length >= 1) {
            this['code'+(id+1)].current.focus();            
        } else if(id > 0) {            
            this['code'+(id-1)].current.focus();  
        }

        this.setState({
            formData: {
                ...this.state.formData,
                code: {
                    ...this.state.formData.code,
                    [id]: e.target.value.length > 0 ? (e.target.value.trim().length === 1 ? e.target.value.trim() : e.target.value.trim()[1]) : ' '
                },
            }
        }, () => {
            if(id === 5 && this.state.formData.code[5].length > 0) { 
                deployConfirmation(`Are you sure you want to set this warehouse pin?`, this.handleSubmit, this.handleReset)
            }
        });

    }

    handleReset = () => {
        this.setState(initialState(), () => {
            this.timeout = setTimeout(() => {
                this.code0.current.focus()
            }, 75);
        })
    }

    handleSubmit = () => {

        const { staff, staffId } = this.props;
        const { formData:{code} } = this.state;

        API.put(`/staff/${staffId}/pin`, 
            {
                pin: _.join(_.map(code, el => el), "")
            }
        )
        .then(result => {
            if(result?.data){
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),                    
                        formData: initialState().formData
                    }, () => {
                        this.code0.current.focus();
                    });
                } else {
                    this.props.deploySnackBar?.(`success`, `You have successfully set a new warehouse pin for ${staff.staff_first_name} ${staff.staff_last_name}`)
                    this.props.closeDialog?.();
                }
            }
        });

    }

    render() {
        const { formData, formErrors } = this.state;
        const { closeDialog, staff, hasPin } = this.props;
        return (
            <>
                <Typography variant="body2" className="fw-400" gutterBottom>
                    Please enter a six-digit pin for this user to use to login to the warehouse
                </Typography>
                {(formErrors.code && (
                    <Typography variant="caption" className="textError">
                        {formErrors.code}
                    </Typography>
                )) || (
                    <Box pt={0.5}>
                        <Typography variant="caption" className="fw-400" >
                            <FAIcon icon={hasPin === 1 ? 'check' : 'exclamation-triangle'} size={12.5} className={hasPin === 1 ? 'textSuccess mr-1' : 'textError mr-1'} />
                            {staff.staff_first_name} {staff.staff_last_name} {hasPin === 1 ? `is able to login to the warehouse system` : `cannot currently login to the warehouse system`}
                        </Typography>
                    </Box>
                )}
                <Box mt={3} mb={1.5} ml={3} mr={3} align='center'>
                    <Grid item container xs={12} spacing={window.innerWidth < 600 ? 1 : 3} align='center'>
                        <Grid item xs={2}>
                            <TextField
                                id="code-0"
                                variant="outlined"
                                required
                                fullWidth
                                autoComplete="off"
                                value={formData.code[0]}
                                error={formErrors && formErrors['code'] && true}
                                onChange={(e) => this.handleCodeChange(0, e)} 
                                className='mfaInput'
                                inputProps={{
                                    ref: this.code0,
                                    size: 2,
                                    pattern: '[0-9]*',
                                    maxLength: 2,
                                    style: {
                                        textAlign: 'center'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="code-1"
                                variant="outlined"
                                required
                                fullWidth
                                autoComplete="off"
                                className='mfaInput'
                                value={formData.code[1]}
                                error={formErrors && formErrors['code'] && true}
                                onChange={(e) => this.handleCodeChange(1, e)} 
                                inputProps={{
                                    ref: this.code1,
                                    size: 2,
                                    pattern: '[0-9]*',
                                    maxLength: 2,
                                    style: {
                                        textAlign: 'center'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="code-2"
                                variant="outlined"
                                required
                                fullWidth
                                autoComplete="off"
                                className='mfaInput'
                                value={formData.code[2]}
                                error={formErrors && formErrors['code'] && true}
                                onChange={(e) => this.handleCodeChange(2, e)} 
                                inputProps={{
                                    ref: this.code2,
                                    size: 2,
                                    pattern: '[0-9]*',
                                    maxLength: 2,
                                    style: {
                                        textAlign: 'center'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="code-3"
                                variant="outlined"
                                required
                                fullWidth
                                autoComplete="off"
                                className='mfaInput'
                                value={formData.code[3]}
                                error={formErrors && formErrors['code'] && true}
                                onChange={(e) => this.handleCodeChange(3, e)} 
                                inputProps={{                                                                        
                                    ref: this.code3,
                                    size: 2,
                                    pattern: '[0-9]*',
                                    maxLength: 2,
                                    style: {
                                        textAlign: 'center'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="code-4"
                                variant="outlined"
                                required
                                fullWidth
                                autoComplete="off"
                                className='mfaInput'
                                value={formData.code[4]}
                                error={formErrors && formErrors['code'] && true}
                                onChange={(e) => this.handleCodeChange(4, e)} 
                                inputProps={{
                                    ref: this.code4,
                                    size: 2,
                                    pattern: '[0-9]*',
                                    maxLength: 2,
                                    style: {
                                        textAlign: 'center'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="code-5"
                                variant="outlined"
                                required
                                fullWidth
                                autoComplete="off"
                                className='mfaInput'
                                value={formData.code[5]}
                                error={formErrors && formErrors['code'] && true}
                                onChange={(e) => this.handleCodeChange(5, e)} 
                                inputProps={{
                                    ref: this.code5,
                                    size: 2,
                                    pattern: '[0-9]*',
                                    maxLength: 2,
                                    style: {
                                        textAlign: 'center'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <div className="buttonRow">
                    <Button 
                        variant="text" 
                        onClick={closeDialog}
                    > 
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                </div>
            </>                        
        )
    }
}

export default UpdateStaffPin;