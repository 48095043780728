import React from 'react';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { currencyFormat } from 'Functions/MiscFunctions';

const Shipping = ({
    addressList, 
    carriageList, 
    carriageOptions, 
    contactList, 
    customer, 
    disableMethod, 
    document, 
    formData, 
    formErrors, 
    handleChange,
    handleCarriageChange, 
    handleDateChange, 
    handleDeliveryCustomChargeChange, 
    handleDeliveryCustomChargeFloat, 
    handleDeployAddressLookup, 
    handleDeployAddressChange,
    handleNull, 
    handleSelectChange, 
    handleUseBillingForDeliveryAddress, 
}) => {

    const handleLocalCarriageChange = option => {
      
        let carriageId = option?.value ?? 0;

        if(carriageId > 0) {
            let carriageData = _.find(carriageOptions, (el) => el.id === carriageId);
            if(carriageData !== -1 && carriageData !== undefined) {
                handleCarriageChange({carriageId, carriageData})
            }
        }
    }

    const customAddress = !_.isEmpty(formData.deliveryAddressCustom);
    const address = [];

    if(customAddress) {
        _.each([
            formData.deliveryCompany,
            formData.deliveryAddressCustom.addressOne,
            formData.deliveryAddressCustom.addressTwo,
            formData.deliveryAddressCustom.addressThree,
            formData.deliveryAddressCustom.town,
            formData.deliveryAddressCustom.county,
            formData.deliveryAddressCustom.postcode], row => {
            if(row?.length > 0) {
                address.push(row)
            }
        })
    } else {
        if(customer?.data?.addresses) {
            const idx = _.findIndex(customer.data.addresses, el => el.i === formData.deliveryAddress);
            if(idx !== -1) {
                _.each([
                    formData.deliveryCompany,
                    customer.data.addresses[idx].l1,
                    customer.data.addresses[idx].l2,
                    customer.data.addresses[idx].l3,
                    customer.data.addresses[idx].to,
                    customer.data.addresses[idx].cc,
                    customer.data.addresses[idx].po,
                    customer.data.addresses[idx].co], row => {
                    if(row?.length > 0) {
                        address.push(row)
                    }
                })
            }
        }
    }

    const required = document === 'Order' ? (customAddress ? '' : ' *') : '';

    const carriageData = formData?.carriage?.carriageData ?? {};

    return (
        <PaddedPaper>
            <Typography variant="h6" paragraph>
                {carriageData.collection ? `Collection` : `Delivery`} Details
            </Typography>
            <Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-2">
                            <DatePicker
                                name="deliveryRequestedDate"
                                type="date"
                                label={`Forward ${carriageData.collection ? 'Collection' : 'Despatch'} Date`}
                                onChange={date => handleDateChange("deliveryRequestedDate", date)}
                                value={formData.deliveryRequestedDate}
                                endAdornment={(
                                    <InputAdornment>
                                        <Tooltip title="Clear Date">
                                            <IconButton onClick={(e) => { e?.stopPropagation?.(); handleNull("deliveryRequestedDate");}} size="small">
                                                <FAIcon icon="times" size={11} type="solid" disabled noMargin button />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )}
                                autoOk={true}
                                disablePast={true}
                            />
                        </FormControl>
                    </Grid>
                    {customer?.data?.cust_account_number === "ADDACARD" && (
                        <Grid item xs={12}>
                            <TextField
                                name="deliveryDespatchRef"
                                label={`AddaCard Despatch Note Reference: ${document === 'Order' ? '*' : ''}`}
                                value={formData.deliveryDespatchRef}
                                onChange={handleChange}
                                margin="none"
                                error={formErrors && formErrors['deliveryDespatchRef'] && true}
                                helperText={formErrors && formErrors['deliveryDespatchRef']}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="hashtag" size="small" noMargin /></InputAdornment>
                                }}
                                fullWidth
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <AutoCompleteSelect 
                                disabled={disableMethod}
                                label={`${carriageData?.collection ? `Collection Method` : `Delivery Method - ${customer?.data?.cust_foc_shipping ? `Free Delivery On Orders Over ${currencyFormat.format(customer.data.cust_foc_shipping)}` : 'No Free Delivery'}${required}`}`}
                                options={carriageList} 
                                value={formData.carriage.carriageId}
                                onChange={v => handleLocalCarriageChange(v)}
                                error={formErrors && formErrors['carriageId'] && true}
                                errorText={formErrors && formErrors['carriageId']}
                                isGrouped={true}
                                noClear
                                adornment='truck'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none">
                            <AutoCompleteSelect 
                                label={`${carriageData.collection ? `Collection By` : `Deliver To`}`}
                                options={contactList} 
                                value={formData.deliveryContact}                                
                                onChange={val => handleSelectChange('deliveryContact', val)}
                                error={formErrors && formErrors['deliveryContact'] && true}
                                errorText={formErrors && formErrors['deliveryContact']}
                                creatable
                                adornment='user'
                            />
                        </FormControl>
                    </Grid>
                    {carriageData?.varies && (
                        <Grid item xs={12}>
                            <TextField
                                label={`${carriageData.collection ? `Collection` : `Delivery`} Charge *`}
                                value={carriageData?.data?.courier_conf_charge}
                                onChange={e => handleDeliveryCustomChargeChange(e?.target?.value ?? 10.00)}
                                onBlur={handleDeliveryCustomChargeFloat}
                                margin="none"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" noMargin /></InputAdornment>
                                }}
                                inputProps={{
                                    min: 0,
                                    step: 1
                                }}
                                type="number"
                                fullWidth
                            />
                        </Grid>
                    )}
                    {!carriageData.collection && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    name="deliveryCompany"
                                    label={`Delivery Company${required}`}
                                    value={formData.deliveryCompany}
                                    onChange={handleChange}
                                    margin="none"
                                    error={formErrors && formErrors['deliveryCompany'] && true}
                                    helperText={formErrors && formErrors['deliveryCompany']}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="building" size="small" noMargin /></InputAdornment>
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={10}>
                                        <FormControl fullWidth margin="none">
                                            <AutoCompleteSelect 
                                                label={`Delivery Address${required}`}
                                                options={addressList} 
                                                value={formData.deliveryAddress !== -1 ? formData.deliveryAddress : null}
                                                onChange={val => handleSelectChange('deliveryAddress', val)}
                                                error={formErrors && formErrors['deliveryAddress'] && true}
                                                errorText={formErrors && formErrors['deliveryAddress']}
                                                adornment='location'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title="Lookup Address">
                                            <IconButton onClick={() => handleDeployAddressLookup()}>
                                                <FAIcon icon="map-marker-alt" type="light" button noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title="Use Billing Address">
                                            <IconButton onClick={handleUseBillingForDeliveryAddress} disabled={!formData.customerAddress || formData.customerAddress === -1}>
                                                <FAIcon icon="building" type="light" button noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {!_.isEmpty(address) && (
                        <Grid item xs={12}>
                            <FormLabel>
                                <Grid container alignItems='center'>
                                    <Grid item>
                                        <Typography variant="caption">
                                            {carriageData.collection ? `Collection` : `Delivery`} Address
                                        </Typography>  
                                    </Grid>
                                    <Grid item className="pl-1">
                                        {!carriageData.collection && (
                                            <Tooltip title="Modify Address">
                                                <IconButton
                                                    size="small"
                                                    onClick={handleDeployAddressChange}
                                                >
                                                    <FAIcon icon="pencil" type="light" className="p-05" size={15} button noMargin />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                            </FormLabel>
                            {_.map(address, (line, idx) => (
                                <Typography variant="body2" key={idx} gutterBottom>
                                    {line}
                                </Typography>
                            ))}
                        </Grid>
                    )}
                </Grid>  
            </Grid>
        </PaddedPaper>
    )
}

export default Shipping;