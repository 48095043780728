import React from 'react';
import _ from 'lodash';
import { CirclePicker } from 'react-color';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar'
import CalendarCategoriesList from './CalendarCategoriesList';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = {
    formData: {
        addCategory: {
            type: 'category',
            name: ''
        },
        addSubCategory: {
            type: 'subCategory',
            name: ''
        },
        categories: {}
    },
    formErrors: {
        addCategory: {},
        addSubCategory: {}
    },
    snackbarOpen: {
        addCategory: false,
        addSubCategory: false,
        disableCategory: false
    },
    confirmationOpen: {
        addCategory: false,
        addSubCategory: false
    },
    topCategoryList: [],
    currentTab: 0,
    access: {
        addCalendarCategory: false,
        disableCategory: false
    },
    snackbarMsg: '',
    isLoading: true
}

const colourSelection = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b"
];

class CalendarCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getCategories();
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/calendar-categories:add'), 
            API.get('/staff/my/access/check/calendar-categories:disable'),
        ])
        .then(([addCategoryRes, disableCategoryRes]) =>  {
            addCategoryRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addCalendarCategory: addCategoryRes.data.has_access,
                        disableCategory: disableCategoryRes.data.has_access,
                    }
                });
        });
    }

    getCategories = () => {
        API.get('/calendar/categories/all')
        .then(result => {
            if(result?.data) {
                let categoryList = _.map(result.data, (cat) => {
                    return _.assign({
                        value: cat.category_id,
                        label: cat.category_name
                    });
                });
                this.setState({
                    topCategoryList: categoryList,
                    formData: {
                        ...this.state.formData,
                        categories: result.data
                    },
                    isLoading: false
                });
            }
        });
    }

    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                addNominalCode: {
                    ...this.state.formData.addNominalCode,
                    [name]: res && res.value,
                }
            }
        });
    };

    /*
    * Add Category
    */
    addCategory = () => {
        API.post('/calendar/categories', this.state.formData.addCategory).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addCategory: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addCategory: true
                    }
                });
                this.checkAccess();
                this.getCategories();
            }
            this.props.scrollToTop();
        });
    }

    handleAddCategoryChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addCategory: {
                    ...this.state.formData.addCategory,
                    [e.target.name]: e.target.value
                }
            }
        });
    };

    handleAddCategorySnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addCategory: false
            }
        });
    };

    handleAddCategoryConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: true
            }
        });
    };

    handleAddCategoryConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: false
            }
        });
    };
    
    handleAddCategoryConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: false
            }
        });
        this.addCategory();
    };

    /*
    * Add Sub-Category
    */
    addSubCategory = () => {
        API.post('/calendar/categories', this.state.formData.addSubCategory).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addSubCategory: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    currentTab: 1,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addSubCategory: true
                    }
                });
                this.checkAccess();
                this.getCategories();
            }
            this.props.scrollToTop();
        });
    }

    handleAddSubCategoryColourChange = (colour, event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addSubCategory: {
                    ...this.state.formData.addSubCategory,
                    colour: colour.hex
                }
            }
        });
    };

    handleAddSubCategoryChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addSubCategory: {
                    ...this.state.formData.addSubCategory,
                    [e.target.name]: e.target.value
                }
            }
        });
    };

    handleAddSubCategorySnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addSubCategory: false
            }
        });
    };

    handleAddSubCategoryConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addSubCategory: true
            }
        });
    };

    handleAddSubCategoryConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addSubCategory: false
            }
        });
    };

    handleAddSubCategoryConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addSubCategory: false
            }
        });
        this.addSubCategory();
    };

    handleSubCategorySelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                addSubCategory: {
                    ...this.state.formData.addSubCategory,
                    [name]: res && res.value,
                }
            }
        });
    };

    handleCheckChange = (catIdx, subCatIdx, subCategoryId) => event => {
        let formData = {
            checked:event.target.checked
        }
 
        let newChildren = [...this.state.formData.categories[catIdx].children];
        newChildren[subCatIdx] = {
            ...newChildren[subCatIdx],
            category_enabled: (event.target.checked === true && "Yes") || "No"
        }

        this.setState({
            formData: {
                ...this.state.formData,
                categories: {
                    ...this.state.formData.categories,
                    [catIdx]: {
                        ...this.state.formData.categories[catIdx],
                        children: newChildren
                    }
                }
            }
        },
        () => {
            API.post(`/calendar/categories/${subCategoryId}/disable`, formData)
            .then((result) => {
                if(result.data.success && result.data.success === true) {
                    this.setState({
                        snackbarOpen: {
                            ...this.state.snackbarOpen,
                            disableCategory: true
                        },
                        snackbarMsg: result.data.msg
                    });
                }
            });
        })
    };

    handleDisableCategorySnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                disableCategory: false
            }
        });
    };

    render() {
        const { access, formData, formErrors, isLoading, snackbarOpen, confirmationOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    {(isLoading && (
                        <PaddedPaper>
                            <LoadingCircle />
                        </PaddedPaper>
                    )) || (
                        <>
                            <CalendarCategoriesList 
                                categories={formData.categories}
                                handleCheckChange={this.handleCheckChange}
                                disabledCategoryAccess={access.disableCategory}
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={snackbarOpen.disableCategory}
                                onClose={this.handleDisableCategorySnackbarClose}
                                message={this.state.snackbarMsg}
                            />
                        </>
                    )}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        {access.addCalendarCategory && (
                            <>
                                <Grid item xs={12} xl={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6" paragraph>
                                            Add Calendar Event Sub-Category
                                        </Typography>
                                        <form onChange={this.handleAddNominalCodeChange} noValidate autoComplete="off">
                                            <AutoCompleteSelect 
                                                options={this.state.topCategoryList}
                                                value={this.state.formData.addSubCategory.topCategory}
                                                label='Top Category *'
                                                error={formErrors.addSubCategory && formErrors.addSubCategory['topCategory'] && true}
                                                errorText={formErrors.addSubCategory && formErrors.addSubCategory['topCategory']}
                                                onChange={this.handleSubCategorySelectChange('topCategory')}
                                            />
                                            <TextField
                                                id="name"
                                                name="name"
                                                label="Name *"
                                                value={this.state.formData.addSubCategory.name}
                                                error={formErrors.addSubCategory && formErrors.addSubCategory['name'] && true}
                                                helperText={formErrors.addSubCategory && formErrors.addSubCategory['name']}
                                                margin="none"
                                                onChange={this.handleAddSubCategoryChange}
                                                fullWidth
                                            />
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 'No', label: 'No'},
                                                    {value: 'Yes', label: 'Yes'}
                                                ]}
                                                label='Prevent Double Booking *'
                                                onChange={this.handleSubCategorySelectChange('preventDoubleBooking')}
                                                error={formErrors.addSubCategory && formErrors.addSubCategory['preventDoubleBooking'] && true}
                                                errorText={formErrors.addSubCategory && formErrors.addSubCategory['preventDoubleBooking']}
                                                value={this.state.formData.addSubCategory.preventDoubleBooking}
                                            />
                                            <FormControl error={formErrors.addSubCategory && formErrors.addSubCategory['colour'] && true} fullWidth style={{marginTop: 16, marginBottom: 8}}>
                                                <InputLabel shrink={true}>Colour *</InputLabel>
                                                <br />
                                                <CirclePicker
                                                    color={this.state.formData.addSubCategory.colour}
                                                    colors={colourSelection}
                                                    onChangeComplete={this.handleAddSubCategoryColourChange}
                                                />
                                                {formErrors.addSubCategory && formErrors.addSubCategory['colour'] &&
                                                    <FormHelperText className="pt-2">{formErrors.addSubCategory['colour']}</FormHelperText>
                                                }
                                            </FormControl>
                                            <div className='buttonRow'>
                                                <Button 
                                                    onClick={this.handleAddSubCategoryConfirmationOpen}
                                                    variant="text" 
                                                    color="primary" 
                                                    disabled={!this.state.formData.addSubCategory.topCategory && !this.state.formData.addSubCategory.name && !this.state.formData.addSubCategory.colour && !this.state.formData.addSubCategory.preventDoubleBooking}
                                                >
                                                    <FAIcon icon="check" size={15} button />
                                                    Add
                                                </Button>
                                            </div>
                                            <ConfirmationDialog 
                                                open={confirmationOpen.addNominalCode} 
                                                success={this.handleAddNominalCodeConfirmationSuccess} 
                                                close={this.handleAddNominalCodeConfirmationClose} 
                                                message="Are you sure you want to add a new Nominal Code?"
                                            />
                                            <SnackBar
                                                variant="success"
                                                anchorOriginVertical='bottom'
                                                anchorOriginHorizontal='right'
                                                open={snackbarOpen.addNominalCode}
                                                onClose={this.handleNominalCodeSnackbarClose}
                                                message="You have successfully added a new Nominal Code"
                                            />
                                            <ConfirmationDialog 
                                                open={confirmationOpen.addSubCategory} 
                                                success={this.handleAddSubCategoryConfirmationSuccess} 
                                                close={this.handleAddSubCategoryConfirmationClose} 
                                                message="Are you sure you want to add a new calendar event sub-category?"
                                            />
                                            <SnackBar
                                                variant="success"
                                                anchorOriginVertical='bottom'
                                                anchorOriginHorizontal='right'
                                                open={snackbarOpen.addSubCategory}
                                                onClose={this.handleAddSubCategorySnackbarClose}
                                                message="You have successfully added a new calendar event sub-category"
                                            />
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} xl={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Add Calendar Event Category
                                        </Typography>
                                        <form onChange={this.handleAddCategoryChange} noValidate autoComplete="off">
                                            <TextField
                                                id="name"
                                                name="name"
                                                label="Name *"
                                                value={formData.addCategory.name}
                                                error={formErrors.addCategory && formErrors.addCategory['name'] && true}
                                                helperText={formErrors.addCategory && formErrors.addCategory['name']}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <div className='buttonRow'>
                                                <Button 
                                                    onClick={this.handleAddCategoryConfirmationOpen}
                                                    variant="text" 
                                                    color="primary" 
                                                    disabled={!this.state.formData.addCategory.name}
                                                >
                                                    <FAIcon icon="check" size={15} button />
                                                    Add
                                                </Button>
                                            </div>
                                            <ConfirmationDialog 
                                                open={confirmationOpen.addCategory} 
                                                success={this.handleAddCategoryConfirmationSuccess} 
                                                close={this.handleAddCategoryConfirmationClose} 
                                                message="Are you sure you want to add a new calendar event category?"
                                            />
                                            <SnackBar
                                                variant="success"
                                                anchorOriginVertical='bottom'
                                                anchorOriginHorizontal='right'
                                                open={snackbarOpen.addCategory}
                                                onClose={this.handleAddCategorySnackbarClose}
                                                message="You have successfully added a new calendar event category"
                                            />
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default CalendarCategories;