import React from 'react';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import SearchTransactionsTable from './SearchTransactionsTable';

const initialState = {
    access: {
        viewPayment: false,
    },
    dataLoading: true,
    status: '',
    statusList: [
        {value: 'Authorised', label: 'Authorised'},
        {value: 'Declined', label: 'Declined'},
        {value: 'Pending', label: 'Pending'},
    ],
    searchResults: [],
    searchString: '',
}

class SearchTransactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Finance', 'Transactions', this.props.showRefundsOnly ? 'Refunds' : 'Payments', this.props.status ? this.props.status : 'All'])
        this.getData();
    }

    componentDidUpdate = prevProps => {
        if(this.props.status !== prevProps.status || this.props.location?.pathname !== prevProps?.location?.pathname) {
            this.props.pageTitle([0, 'Finance', 'Transactions', this.props.showRefundsOnly ? 'Refunds' : 'Payments', this.props.status ? this.props.status : 'All'])
            if(this.state.status || this.state.searchString) {
                this.setState({
                    status: '',
                    searchString: '',
                }, () => {
                    this.getSearchData();
                })
            } else {
                this.getSearchData();
            }
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            Promise.all([
                API.get('/accounts/transactions', {
                    props: {
                        cancellation: true
                    },
                    params: {
                        showPaymentsOnly: this.props.showPaymentsOnly ? 1 : (!this.props.showRefundsOnly ? 1 : 0),
                        showRefundsOnly: this.props.showRefundsOnly ? 1 : 0,
                        status: this.state.status ? this.state.status : this.props.status,
                        searchString: this.state.searchString,
                    }
                }),
                API.access('view-payment')
            ])
            .then(([result, viewPayment]) =>  {
                if(result && result.data) {
                    let searchResults = []
                    if(result.data) {
                        searchResults = result.data
                    }
                    this.setState({
                        ...this.state,
                        access: {
                            viewPayment: (viewPayment.data && viewPayment.data.has_access) || false,
                        },
                        searchResults,
                        dataLoading: false
                    })
                }
            })
        })
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.getSearchData();
        });
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/accounts/transactions', {
                props: {
                    cancellation: true
                },
                params: {
                    showPaymentsOnly: this.props.showPaymentsOnly ? 1 : 0,
                    showRefundsOnly: this.props.showRefundsOnly ? 1 : 0,
                    status: this.state.status ? this.state.status : this.props.status,
                    searchString: this.state.searchString,
                }
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    })
                } 
            })
        })
    }
    
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch()
        });
    }

    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData()
        });
    }
    
    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    render() {
        const { dataLoading, searchResults, searchString } = this.state;
        return (
            <Grid container spacing={1}>      
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} sm={5} md={5} xl={3} style={{marginBottom: 12}}>
                        <TextField 
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder='Search:'
                            value={searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                            }}
                        /> 
                    </Grid>
                    {!this.props.status && (
                        <>
                            <Grid item xs={12} sm={4} md={3} xl={2} style={{marginBottom: 12, paddingLeft: 18}}>
                                <AutoCompleteSelect
                                    options={this.state.statusList}
                                    placeholder='Transaction Status:'
                                    onChange={this.handleSelectChange('status')}
                                    value={this.state.status} 
                                    variant="filled"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                    }}
                                    adornment="filter"
                                /> 
                            </Grid>
                        </>
                    )}
                </Grid>           
                <Grid item xs={12}>
                    <Paper>
                        <SearchTransactionsTable
                            showPaymentsOnly={this.props.showPaymentsOnly}
                            showRefundsOnly={this.props.showRefundsOnly}
                            dataLoading={dataLoading} 
                            rows={searchResults} 
                            resetSearch={this.resetSearch} 
                            paymentStatus={this.props.status}
                            getSearchData={this.getSearchData}
                        />
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default SearchTransactions;