import React from 'react';
import uuidv4 from 'uuid/v4';
import ReactHowler from 'react-howler';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import WarehouseAnalytics from 'Components/Layout/WarehouseLayout/WarehouseAnalytics';
import WarehouseContentWrapper from 'Components/Layout/WarehouseLayout/WarehouseContentWrapper';
import WarehouseHeader from 'Components/Layout/WarehouseLayout/WarehouseHeader';

import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import Dialog from 'Components/Common/Dialogs/Dialog';
import ErrorDialog from 'Components/Common/ErrorHandling/ErrorDialog'
import MessageDrawer from 'Components/Messages/MessageDrawer';
import RequireAuth from 'Components/RequireAuth';

import { 
    closeDialog 
} from 'Redux/Actions/Dialog/Dialog';

import { 
    handleAppError 
} from 'Redux/Actions/UI/UI';

import { 
    playSound, 
    stopSound 
} from 'Redux/Actions/Sounds/Sounds';

const initialState = {
    appError: {
        key: uuidv4(),
        detail: "",
        state: false,
    },
    confirmation: {
        key: uuidv4(),
        open: false,
        message: '',
        close: () => {},
        success: () => {},
        successText: null,
        closeText: null,
        successOnly: false,
        disableExit: false
    }, 
    dialog: {
        key: uuidv4(),
        dialogOpen: false,
        disableDialogContent: false,
        dialogContent: '',
        dialogTitle: '',
        dialogVariant: '',
        dialogSize: '',
        dialogFullScreen: false,
        dialogDisableExit: false
    },  
    drawers: {
        analytics: false,
        chat: false
    },
    isMobile: false,
    pagePath: '',
    pageTitle: []
}
class WarehouseLayout extends React.PureComponent {
    constructor() {
        super();
        this.state = initialState;
    }

    componentDidMount = () => {
        
        const {
            enqueueSnackbar
        } = this.props;
        
        const {
            ui,
            confirmation,
            dialog,
            history,
            snackbar
        } = this.props;

        if(!_.isEmpty(snackbar.message)) {
            enqueueSnackbar(snackbar.message, { variant: snackbar.variant })
        }

        this.setState({
            appError: {
                ...this.state.appError,
                detail: ui.appError.detail,
                state: ui.appError.state
            },
            confirmation: {
                ...this.state.confirmation,
                open: confirmation.open,
                message: confirmation.message,
                close: confirmation.close,
                success: confirmation.success,
                successText: confirmation.successText,
                closeText: confirmation.closeText,
                successOnly: confirmation.successOnly,
                disableExit: confirmation.disableExit
            },
            dialog: {
                ...this.state.dialog,
                dialogOpen: dialog.dialogOpen,
                dialogContent: dialog.dialogContent,
                dialogTitle: dialog.dialogTitle,
                disableDialogContent: dialog.disableDialogContent,
                dialogVariant: dialog.dialogVariant,
                dialogSize: dialog.dialogSize,
                dialogFullScreen: dialog.dialogFullScreen,
                dialogDisableExit: dialog.dialogDisableExit
            },
            isMobile: ui.device.isMobile,
            drawers: {
                ...this.state.drawers,
                nav: ui.device.isMobile ? false : true
            },
            pagePath: history.location.pathname
        })        

    }

    componentDidUpdate = prevProps => {
        
        const {
            ui,
            badges,
            confirmation,
            dialog,
            enqueueSnackbar,
            playSound,
            snackbar
        } = this.props;

        if(badges?.['wh-awaiting-picking'] !== prevProps?.badges?.['wh-awaiting-picking']) {
            if(badges?.['wh-awaiting-picking'] > prevProps?.badges?.['wh-awaiting-picking']) {
                playSound?.('pick', badges?.['wh-awaiting-picking']);
                if("vibrate" in navigator) {
                    navigator?.vibrate?.(0);
                    navigator?.vibrate?.([250, 75, 125, 75, 125, 75, 125, 75, 250]);
                }
            }
        }
        
        if(snackbar.key !== prevProps.snackbar.key) { 
            if(!_.isEmpty(snackbar.message)) {
                enqueueSnackbar(snackbar.message, { variant: snackbar.variant })
            }
        }
        
        if(ui.appError.state !== prevProps.ui.appError.state) {
            this.setState({
                appError: {
                    ...this.state.appError,
                    key: uuidv4(),
                    state: ui.appError.state,
                    detail: ui.appError.detail
                }
            });
        }

        if(confirmation.message !== prevProps.confirmation.message) {
            this.setState({
                confirmation: {
                    ...this.state.confirmation,
                    key: uuidv4(),
                    open: confirmation.open,
                    message: confirmation.message,
                    close: confirmation.close,
                    success: confirmation.success,
                    successText: confirmation.successText,
                    closeText: confirmation.closeText,
                    successOnly: confirmation.successOnly,
                    disableExit: confirmation.disableExit
                }
            });
        }

        if(dialog.dialogContent !== prevProps.dialog.dialogContent) {
            this.setState({
                dialog: {
                    ...this.state.dialog,
                    key: uuidv4(),
                    dialogOpen: dialog.dialogOpen,
                    dialogContent: dialog.dialogContent,
                    disableDialogContent: dialog.disableDialogContent,
                    dialogTitle: dialog.dialogTitle,
                    dialogVariant: dialog.dialogVariant,
                    dialogSize: dialog.dialogSize,
                    dialogFullScreen: dialog.dialogFullScreen,
                    dialogDisableExit: dialog.dialogDisableExit
                }
            });
        }

        if(ui.device.isMobile !== prevProps.ui.device.isMobile) {
            this.setState({
                isMobile: ui.device.isMobile,
                drawers: {
                    ...this.state.drawers,
                    nav: ui.device.isMobile ? false : true
                }
            });
        }

    }

    toggleDrawer = type => {
        this.setState({
            drawers: {
                ...this.state.drawers,
                [type]: !this.state.drawers[type]
            }
        });
    }

    changePath = path => {
        if(path !== this.state.pagePath){
            this.setState({
                pagePath: path,
            });
        }
    }

    handleDialogClose = () => {
        this.props.closeDialog();
    }

    handleSnackbarClose = () => {
        this.props.deploySnackBar("success", "")
    }

    pageTitle = title => {
        if(title !== this.state.pageTitle){
            this.setState({
                pageTitle: title
            });
        }
    }

    render() {
        const { history, location, ui, sound, stopSound } = this.props;
        const { appError, confirmation, dialog, drawers, pagePath, pageTitle } = this.state;
        return (
            <>
                <WarehouseHeader    
                    history={history}
                    location={location}
                    toggleDrawer={this.toggleDrawer}
                />
                <WarehouseAnalytics   
                    open={drawers.analytics}
                    toggleDrawer={this.toggleDrawer}
                    smallHeader
                />
                <MessageDrawer   
                    open={drawers.chat}
                    toggleDrawer={this.toggleDrawer}
                    smallHeader
                />
                <WarehouseContentWrapper    
                    changePath={this.changePath}
                    location={location}
                    history={history}
                    drawers={drawers}
                    page={pageTitle}
                    pageTitle={this.pageTitle}    
                    pagePath={pagePath}
                    scrollHeight={ui.device.height}
                />
                <ConfirmationDialog 
                    open={confirmation.open} 
                    success={confirmation.success} 
                    close={confirmation.close} 
                    successText={confirmation.successText}
                    closeText={confirmation.closeText}
                    successOnly={confirmation.successOnly}
                    message={confirmation.message}
                    disableExit={confirmation.disableExit}
                    warehouse
                /> 
                <Dialog 
                    key={dialog.key}
                    dialogOpen={dialog.dialogOpen}
                    dialogContent={dialog.dialogContent}
                    disableDialogContent={dialog.disableDialogContent}
                    dialogTitle={dialog.dialogTitle}
                    dialogVariant={dialog.dialogVariant}
                    dialogSize={dialog.dialogSize}
                    handleDialogClose={this.handleDialogClose}
                    dialogFullScreen={dialog.dialogFullScreen}
                    dialogDisableExit={dialog.dialogDisableExit}
                />
                {appError.state && (
                    <ErrorDialog
                        key={appError.key}
                        state={appError.state}
                        detail={appError.detail}
                        history={history}
                        closeError={() => this.props.handleAppError(false, "")}
                    />
                )}
                {sound.src !== null && (
                    <ReactHowler
                        src={sound.src}
                        playing={sound.status === 'playing'}
                        onEnd={stopSound}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = state => ({
    confirmation: state.confirmation,
    dialog: state.dialog,
    badges: state.notifications.badges,
    notifications: state.notifications,
    snackbar: state.snackbar,
    ui: state.ui,
    sound: state.sound
});

const mapDispatchToProps = dispatch => ({
    handleAppError: (error, message) => dispatch(handleAppError(error, message)),
    closeDialog: () => dispatch(closeDialog()),
    playSound: (type, state = false) => dispatch(playSound(type, state)),
    stopSound: () => dispatch(stopSound())
})

export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(WarehouseLayout)));