import React  from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange, 
    handleNumberChange,
    handleConfirmationOpen, 
    handleConfirmationClose, 
    handleConfirmationSuccess,
} from 'Functions/FormFunctions';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = () => ({
    confirmation: {
        submit: false
    },
    formData: {
        id: '',
        name: '',
        percent: '',
    },
    formErrors: {},
    isLoading: true,
})

class DiscountForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
        this.handleNumberChange = handleNumberChange.bind(this)
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        if(this.props.updateId) {
            API.get(`/suppliers/discounts/${this.props.updateId}`)
            .then(result => {
                if(result?.data) {
                    if(result.data.products_count > 0) {
                        this.cancel();
                    } else {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                name: result.data.supp_disc_name,
                                percent: result.data.supp_disc_percentage,
                            },
                            isLoading: false,
                        })
                    }
                }
            })
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    id: this.props.id,
                },
                isLoading: false,
            })
        }
    }
   
    cancel = () => {
        this.setState({
            formData: {
                ...initialState().formData,
                id: this.props.id,
            }
        }, () => {
            if(this.props.cancel) {
                this.props.cancel();
            }
        })
    }
    
    submit = () => {
        if(this.props.updateId) {
            API.put(`/suppliers/discounts/${this.props.updateId}`, this.state.formData)
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully updated the supplier discount");
                            if(this.props.callback) 
                                this.props.callback();
                        })
                    }
                }
            })
        } else {
            API.post(`/suppliers/discounts`, this.state.formData)
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully added a new supplier discount");
                            if(this.props.callback) 
                                this.props.callback();
                        })
                    }
                }
            })
        }
    }

    render() {
        const { updateId } = this.props;
        const { formErrors, formData, isLoading } = this.state;
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <form noValidate autoComplete="off">
                    <Grid container spacing={0}>
                        <TextField
                            id="name"
                            name="name"
                            label="Name *"
                            value={formData.name}
                            error={formErrors && formErrors['name'] && true}
                            helperText={formErrors && formErrors['name']}                                                
                            onChange={this.handleChange}
                            margin="none"
                            fullWidth
                        />
                        <TextField
                            id="percent"
                            name="percent"
                            label="Percentage *"
                            value={formData.percent}
                            error={formErrors && formErrors['percent'] && true}
                            helperText={formErrors && formErrors['percent']}                                                
                            onChange={this.handleNumberChange}
                            onBlur={this.handleFloat}
                            margin="none"
                            type="number"
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 100
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button onClick={this.cancel} variant="text" color="primary">
                            <FAIcon icon="times" size={15} button />
                            {updateId ? 'Cancel' : 'Discard'}
                        </Button>
                        <Button onClick={() => this.handleConfirmationOpen('submit')} disabled={formData.name.length === 0 || formData.percent.length === 0 || formData.percent < 0 || formData.percent > 100} variant="text" color="primary">
                            <FAIcon icon="check" size={15} button />
                            {updateId ? 'Update' : 'Add Discount'}
                        </Button>
                    </Grid>     
                    <ConfirmationDialog 
                        open={this.state.confirmation.submit} 
                        success={() => this.handleConfirmationSuccess('submit')} 
                        close={() => this.handleConfirmationClose('submit')} 
                        message={`Are you sure you want to ${updateId ? 'update' : 'add'} this discount?`}
                    />
                </form>
            )
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(DiscountForm);