import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import SmsTemplateForm from 'Components/Communication/Misc/SmsTemplateForm';

const initialState = {
    access: {
        addSmsTemplate: false,
        deleteSmsTemplate: false,
        updateSmsTemplate: false,
    },
    searchResults: {},
    searchString: '',
    dataLoading: true,
}

class SmsTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentDidMount = () => {
        Promise.all([
            API.access('sms-templates:add'),
            API.access('sms-templates:delete'),
            API.access('sms-templates:update')
        ])
        .then(([addSmsTemplate, deleteSmsTemplate, updateSmsTemplate]) => {
            this.setState({
                access: {
                    addSmsTemplate: addSmsTemplate?.data?.has_access ?? false,
                    deleteSmsTemplate: deleteSmsTemplate?.data?.has_access ?? false,
                    updateSmsTemplate: updateSmsTemplate?.data?.has_access ?? false,
                }
            }, () => {
                this.getSmsTemplates(true);
            })
        })
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getSmsTemplates = dataLoading => {
        this.setState({
            dataLoading,
        }, () => {
            API.get('/sms/templates', {
                params: { 
                    searchString: this.state.searchString
                } 
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    });
                }
            });
        });
    }

    handleDeleteSmsTemplate = id => {
        API.post(`/sms/templates/${id}/delete`)
        .then(res => {
            if(res?.data) {
                this.getSmsTemplates(false);
                this.props.deploySnackBar("success", "You have successfully deleted the SMS template")
            }
        })
    }

    handleDeploySmsTemplate = id => this.props.deployDialog(
        <SmsTemplateForm
            id={id}
            callback={this.getSmsTemplates}
            closeDialog={this.props.closeDialog}
            deployConfirmation={this.props.deployConfirmation}
            deploySnackBar={this.props.deploySnackBar}
        />, false, `${id ? 'Update' : 'Add'} SMS Template`, "standard","sm", false, true);

    onSearchStringChange = event => {
        this.setState({
            searchString: event?.target?.value ?? ''
        },
        () => {
            if(this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => this.getSmsTemplates(true), 200);
        })
    }

    render() {
        const { access, dataLoading, searchResults, searchString } = this.state;
        return (
            <Grid container spacing={1}>      
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                        <TextField
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder='Search:'
                            value={searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                            }}
                        />
                    </Grid>   
                    {access.addSmsTemplate && (
                    <Grid item xs={12} sm={7} md={8} xl={9} align='right'>
                            <Button 
                                color="primary"
                                size="small"
                                variant="text"
                                onClick={() => this.handleDeploySmsTemplate(false)}
                            >
                                <FAIcon type="light" icon="plus-circle" size="small" button />
                                Add Template
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable
                            config={{
                                key: 'st_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                options: {
                                    dataRef: true,
                                    export: {
                                        title: `SMS Templates`,
                                        name: `SMS-Templates`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.st_name,
                                    dataRef: 'st_name',
                                    important: true,
                                    main: true
                                },
                                {
                                    heading: 'Default Sender',
                                    field: rowData => rowData.st_send_from,
                                    dataRef: 'st_send_from',
                                },
                                {
                                    heading: 'System Template',
                                    field: rowData => rowData.st_system,
                                    fieldFormat: 'boolean',
                                    dataRef: 'st_system',
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Modify', icon: 'pencil', onClick: rowData => this.handleDeploySmsTemplate(rowData.st_id), doubleClick: true, disabled: !access.updateSmsTemplate},
                                            {name: 'Delete', icon: 'trash-alt', onClick: rowData => this.props.deployConfirmation("Are you sure you want to delete this SMS template?", () => this.handleDeleteSmsTemplate(rowData.st_id)), disabled: !access.deleteSmsTemplate || rowData.st_system === 1}
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default SmsTemplates;