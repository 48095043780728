import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';
import AuthReducer from 'Redux/Reducers/Auth/AuthReducer';
import ConfirmationReducer from 'Redux/Reducers/Confirmation/ConfirmationReducer';
import DialogReducer from 'Redux/Reducers/Dialog/DialogReducer';
import LoadingReducer from 'Redux/Reducers/Loading/LoadingReducer';
import NotificationsReducer from 'Redux/Reducers/Notifications/NotificationsReducer';
import SnackBarReducer from 'Redux/Reducers/SnackBar/SnackBarReducer';
import ServiceWorkerReducer from 'Redux/Reducers/ServiceWorker/ServiceWorkerReducer';
import SoundReducer from 'Redux/Reducers/Sounds/SoundReducer';
import UIReducer from 'Redux/Reducers/UI/UIReducer';
import StatePersistenceReducer from 'Redux/Reducers/StatePersistence/StatePersistenceReducer';

const rootReducer = combineReducers({
    staffAuth: AuthReducer,
    loading: LoadingReducer,
    notifications: NotificationsReducer,
    confirmation: ConfirmationReducer,
    dialog: DialogReducer,
    snackbar: SnackBarReducer,
    sound: SoundReducer,
    ui: UIReducer,
    serviceWorker: ServiceWorkerReducer,
    statePersistence: StatePersistenceReducer
});

export default withReduxStateSync(rootReducer);