import React from "react";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import CrushedIce from "Assets/Logos/crushedice.png";
import { CLIENT_VERSION } from "Constants";

const useStyles = makeStyles({
    footer: {
        position: "fixed",
        bottom: 0,
        height: 25,
        backgroundColor: "#F2F2F2",
        width: "100%",
        paddingRight: 8,
        boxShadow: "0 -6px 4px -4px rgba(0,0,0,0.075)",
        zIndex: 1001,
    },
    builtWith: {
        marginLeft: 4,
        marginRight: 4,
    },
    text: {
        color: "rgba(0,0,0,0.72)",
        fontSize: 12,
    },
});

const AdminFooter = () => {
    const classes = useStyles();
    return (
        <Grid container alignItems="center" justify="flex-end" className={`${classes.footer}`}>
            <Grid item>
                <Typography className={classes.text}>
                    RED v{CLIENT_VERSION}. Copyright &copy; {moment().year()} Advanced Access Ltd.
                </Typography>
            </Grid>
            <Grid item>
                <Link color="inherit" href="http://www.crushedice.biz" target="_blank" rel="noopener">
                    <Grid container alignItems="center">
                        <Grid item>
                            <img
                                className={classes.builtWith}
                                src={CrushedIce}
                                alt="Crushed Ice"
                                width={11}
                                height={11}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.text}>Built by Crushed Ice</Typography>
                        </Grid>
                    </Grid>
                </Link>
            </Grid>
        </Grid>
    );
};

export default AdminFooter;
