import * as StatePersistenceActionTypes from 'Redux/Actions/StatePersistence/Types/Types';

const initialState = () => ({
});

function StatePersistenceReducer(state = initialState, action) {
    switch (action.type) {
        case StatePersistenceActionTypes.SET_PERSISTENCE:
            return {
                ...state,
                ...action.payload
            }
        case StatePersistenceActionTypes.CLEAR_PERSISTENCE:
            return {
                ...initialState()
            }
        case StatePersistenceActionTypes.CLEAR_PERSISTENCE_KEY:
            delete state[action.payload];
            return state;
        default:
            return state;
    }
}

export default StatePersistenceReducer;