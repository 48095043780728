import React  from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import { 
    handleSelectChange, 
    handleChangeUcFirst, 
    handleCheckedChange, 
} from 'Functions/FormFunctions';

const initialState = () => ({
    confirmation: {
        cancel: false
    },
    formData: {
        id: '',
        firstName: '',
        lastName: '',
        position: '',
        telephone: '',
        mobile: '',
        email: '',
        primaryContact: 0,
        accounts: 0,
        gdprOptOut: 0,
        mailshotBar: 0,
        noDelUpdates: 0,
        noOrderEmails: 0,
        prefEmail: 0,
        prefMob: 0,
        prefTel: 0,
        notes: '',
    },
    formErrors: {}
})

class ContactDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.handleChangeUcFirst = handleChangeUcFirst.bind(this)
        this.handleCheckedChange = handleCheckedChange.bind(this)
        this.handleSelectChange = handleSelectChange.bind(this)
    }

    componentDidMount = () => {
        this.configureComponent();
    }
   
    componentDidUpdate = prevProps => {
        if(JSON.stringify(this.props.formData) !== JSON.stringify(prevProps.formData)) {
            this.configureComponent();
        }
    }

    configureComponent = () => {
        const { formData, formErrors, firstContact } = this.props;
        this.setState({
            formData: {
                ...this.state.formData,
                ...(formData || {}),
                primaryContact: firstContact ? 1 : (formData?.primaryContact ?? initialState().primaryContact),
                accounts: firstContact ? 1 : (formData?.accounts ?? initialState().accounts)
            },
            formErrors: formErrors ?? initialState().formErrors
        })
    }

    handleChange = e => {
        if(this.state.formData[e.target.name] !== e.target.value) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                }
            }, () => {
                if(this.state.formData.mobile === '' && this.state.formData.prefMob === 1) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            prefMob: 0
                        }
                    })
                }
                if(this.state.formData.telephone === '' && this.state.formData.prefTel === 1) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            prefTel: 0
                        }
                    })
                }
                if(this.state.formData.email === '' && this.state.formData.prefEmail === 1) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            prefEmail: 0
                        }
                    })
                }
            });
        }
    }
    
    cancel = () => {
        this.props.handleCancel(true)
    }
    
    submit = () => {
        this.props.handleSubmit(this.state.formData)
    }

    render() {
        const { open, idx, positionList } = this.props;
        const { formErrors, formData } = this.state;
        return (
            <Dialog
                disableEscapeKeyDown
                disableBackdropClick
                open={open}
                maxWidth='sm' 
                scroll="body"
            >               
                <DialogTitle disableTypography>
                    <Typography variant="h6">
                        Contact Details
                    </Typography>
                </DialogTitle>
                <DialogContent style={{padding: '0 24px 24px 24px', minWidth: 400, maxWidth: 500}}>
                    <form noValidate autoComplete="off" onSubmit={this.submit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            id="firstName"
                                            name="firstName"
                                            label="First Name *"
                                            value={formData.firstName}
                                            error={formErrors?.['customerContacts|firstName|'+idx] && true}
                                            helperText={formErrors?.['customerContacts|firstName|'+idx]}                                                
                                            onChange={this.handleChangeUcFirst}
                                            margin="none"
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            id="lastName"
                                            name="lastName"
                                            label="Last Name *"
                                            value={formData.lastName}
                                            error={formErrors?.['customerContacts|lastName|'+idx] && true}
                                            helperText={formErrors?.['customerContacts|lastName|'+idx]}                                                
                                            onChange={this.handleChangeUcFirst}
                                            margin="none"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="Email Address"
                                            value={formData.email}
                                            error={formErrors?.['customerContacts|email|'+idx] && true}
                                            helperText={formErrors?.['customerContacts|email|'+idx]}                                                
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            creatable={true}
                                            creatableMessage={userInput => `Add Position: ${userInput}`}
                                            options={positionList}
                                            name="position"
                                            label="Position"
                                            value={formData.position}
                                            error={formErrors && formErrors['position'] && true}
                                            helperText={formErrors && formErrors['position']}                                                
                                            onChange={v => this.handleSelectChange('position', v, true)}
                                            margin="none"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            id="telephone"
                                            name="telephone"
                                            label="Telephone"
                                            value={formData.telephone}
                                            error={formErrors?.['customerContacts|telephone|'+idx] && true}
                                            helperText={formErrors?.['customerContacts|telephone|'+idx]}                                                
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            id="mobile"
                                            name="mobile"
                                            label="Mobile"
                                            value={formData.mobile}
                                            error={formErrors?.['customerContacts|mobile|'+idx] && true}
                                            helperText={formErrors?.['customerContacts|mobile|'+idx]}                                                
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Textarea
                                            id="notes"
                                            name="notes"
                                            label="Notes"
                                            value={formData.notes}
                                            error={formErrors?.['customerContacts|notes|'+idx] && true}
                                            helperText={formErrors?.['customerContacts|notes|'+idx]}                                                
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" className="fw-400 textError" gutterBottom>
                                            No Personal/Family data to be stored (GDPR)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={2}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        Contact Settings
                                                    </Typography>
                                                    <FormControlLabel
                                                        control={<Checkbox name="primaryContact" checked={formData.primaryContact} color="primary" onChange={this.handleCheckedChange}/>}
                                                        label={<Typography variant="caption">Primary Contact</Typography>}
                                                    />
                                                    <br />
                                                    <FormControlLabel
                                                        control={<Checkbox name="accounts" checked={formData.accounts} color="primary" onChange={this.handleCheckedChange}/>}
                                                        label={<Typography variant="caption">Accounts Contact</Typography>}
                                                    />
                                                    <br /><br />
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        Contact Preferences
                                                    </Typography>
                                                    <FormControlLabel
                                                        control={<Checkbox name="gdprOptOut" checked={formData.gdprOptOut} color="primary" onChange={this.handleCheckedChange}/>}
                                                        label={<Typography variant="caption">GDPR Opt Out</Typography>}
                                                    />
                                                    <br />
                                                    <FormControlLabel
                                                        control={<Checkbox name="mailshotBar" checked={formData.mailshotBar} color="primary" onChange={this.handleCheckedChange}/>}
                                                        label={<Typography variant="caption">Mailshot Bar</Typography>}
                                                    />
                                                    {this.props.customerForm && (
                                                        <>
                                                            <br />
                                                            <FormControlLabel
                                                                control={<Checkbox name="noDelUpdates" checked={formData.noDelUpdates} color="primary" onChange={this.handleCheckedChange}/>}
                                                                label={<Typography variant="caption">No Delivery Notifications</Typography>}
                                                            />
                                                            <br />
                                                            <FormControlLabel
                                                                control={<Checkbox name="noOrderEmails" checked={formData.noOrderEmails} color="primary" onChange={this.handleCheckedChange}/>}
                                                                label={<Typography variant="caption">No Order Emails</Typography>}
                                                            />
                                                        </>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Typography variant="body2" className="fw-400" gutterBottom>
                                                        Preferred Contact Methods
                                                    </Typography>
                                                    <FormControlLabel
                                                        control={<Checkbox name="prefEmail" checked={formData.prefEmail} color="primary" onChange={this.handleCheckedChange}/>}
                                                        label={<Typography variant="caption">E-mail</Typography>}
                                                        disabled={formData.email === ''}
                                                    />
                                                    <br />
                                                    <FormControlLabel
                                                        control={<Checkbox name="prefMob" checked={formData.prefMob} color="primary" onChange={this.handleCheckedChange}/>}
                                                        label={<Typography variant="caption">Mobile</Typography>}
                                                        disabled={formData.mobile === ''}
                                                    />
                                                    <br />
                                                    <FormControlLabel
                                                        control={<Checkbox name="prefTel" checked={formData.prefTel} color="primary" onChange={this.handleCheckedChange}/>}
                                                        label={<Typography variant="caption">Telephone</Typography>}
                                                        disabled={formData.telephone === ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                onClick={this.cancel} 
                                variant="text" 
                                color="primary"
                            >
                                <FAIcon icon="times" size={15} button />
                                Cancel
                            </Button>
                            <Button 
                                disabled={formData.firstName === '' || formData.lastName === ''} 
                                type="submit" 
                                variant="text" 
                                color="primary"
                            >
                                <FAIcon icon="check" size={15} button />
                                {!_.isEmpty(this.props.formData?.firstName) ? 'Update Contact ' : 'Add Contact'}
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>        
        )
    }
}

export default ContactDialog;