import React from "react";
import moment from "moment";
import _ from "lodash";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import API from "API";
import Alert from "Components/Common/Alert/Alert";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import SupplierSearchSelector from "Components/Suppliers/Misc/SupplierSearchSelector";

import { excelColumnIndexToLetter, excelFitToColumns } from "Functions/MiscFunctions";

const initialState = () => ({
    code: "",
    data: [],
    isLoading: true,
    priceListData: [],
    supplier: null,
    suppliers: [],
    type: "ALL",
});

class CustomerPriceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.timeout = null;
    }

    componentDidMount = () => {
        Promise.all([
            API.get("/suppliers/all", 
                { 
                    params: { 
                        forList: true 
                    } 
                }
            ),
            API.get('/suppliers/priceListInformation',
                { 
                    params: { 
                        forPriceList: true 
                    } 
                }
            )
        ])
        .then(([res, plRes]) => {
            if (res?.data && plRes?.data) {
                
                let suppliers       = {},
                    priceListData   = {};

                _.each(res.data, (supp) => {
                    suppliers = {
                        ...suppliers,
                        [supp.v]: supp.l,
                    };
                });

                _.each(plRes.data, (spli) => {
                    priceListData = {
                        ...priceListData,
                        [spli.supplier.supp_company_name]: spli,
                    };
                });

                this.setState(
                    {
                        priceListData,
                        suppliers,
                    },
                    this.loadComponentData
                );

            }
        });
    };

    componentWillUnmount = () => {
        if (this.timeout) clearTimeout(this.timeout);
    };

    loadComponentData = () => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                const { code, supplier, type } = this.state;

                const { customer } = this.props;

                let params = {
                    type,
                };

                if (code) {
                    params = {
                        ...params,
                        code,
                    };
                }

                if (supplier) {
                    params = {
                        ...params,
                        supplier,
                    };
                }

                API.get(`/customers/${customer?.id}/priceList`, {
                    params,
                }).then((res) => {
                    if (res?.data) {
                        this.setState({
                            data: res.data?.results ?? [],
                            isLoading: false,
                        });
                    }
                });
            }
        );
    };

    handleExportPriceList = () => {
        const { supplier } = this.state;

        if (supplier) {
            this._handleExportPriceListSupplier();
        } else {
            this._handleExportPriceListAll();
        }
    };

    _handleExportPriceListAll = () => {
        const { customer } = this.props;

        let header = [],
            headerArr = [],
            exportData = [],
            supplierArr = [],
            headers = this.handleGetExportHeaders(),
            wb = XLSX?.utils?.book_new();

        /* Get prices to export */
        _.each(this.state.data, (row) => {
            let exportRow = [];
            _.each(headers, (field) => {
                exportRow.push(row[field.key]);
            });
            exportRow.push(row.s);
            exportData.push(exportRow);
            if (!supplierArr.includes(row.s)) {
                supplierArr.push(row.s);
            }
        });

        /* Configure headers and header width */
        _.each(headers, (field, idx) => {
            header.push(field.label);
            headerArr.push(`${field.label}XXX`);
        });

        /* Flag to ensure document is viable */
        let hasSheet = false;

        /* Loop suppliers */
        _.each(_.orderBy(supplierArr), (supplier) => {

            /* Filter data for this supplier */
            let data = _.map(
                _.filter(exportData, (el) => el[4] === supplier),
                (el) => [el[0], el[1], parseFloat(el[2]), el[3]]
            );

            /* Check the supplier is viable (at lease one product to export into the price list */
            if (!_.isEmpty(data)) {

                /* Create work sheet */
                const ws = XLSX.utils.json_to_sheet([]);
                
                /* Add header text */
                let headerTextCount = 6;
                let priceListInfo = this.state.priceListData[supplier];
                XLSX.utils.sheet_add_aoa(ws, [[`${supplier}`]], { origin: "A1" });
                XLSX.utils.sheet_add_aoa(ws, [[``]], { origin: "A2" });
                XLSX.utils.sheet_add_aoa(ws, [[`Prices last updated: ${priceListInfo?.lastPriceChange ?? 'Contact us for details'}`]], { origin: "A3" });
                XLSX.utils.sheet_add_aoa(ws, [[`Date for next price changes: ${priceListInfo?.nextPriceChange ?? 'None due'}`]], { origin: "A4" });
                XLSX.utils.sheet_add_aoa(ws, [[priceListInfo?.spli_text ?? ``]], { origin: "A5" });
                XLSX.utils.sheet_add_aoa(ws, [[``]], { origin: "A6" });
                        
                /* Merge header text rows */
                ws['!merges'] = [];
                for (let i = 0; i < headerTextCount; ++i) {
                    ws['!merges'].push({ s: {r:i, c:0}, e: {r:i, c:3} });
                }
                
                /* Add headers to sheet */
                XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A7" });

                /* Add data to sheet */
                XLSX.utils.sheet_add_json(ws, data, { origin: "A8", skipHeader: true });

                /* Add filters to columns */
                ws['!autofilter'] = {
                    ref: "A7:D7"
                }

                /* Set column with to data set size */
                ws["!cols"] = excelFitToColumns([...data, headerArr]);

                /* Handle cell formatting */
                const currencyFormat = "£0.00";
                const range = XLSX.utils.decode_range(ws["!ref"]);

                /* Loop rows for formatting */
                for (let x = (range.s.r + 1); x <= (range.e.r + 1); ++x) {

                    /* Loop columns for formatting */
                    for (let y = range.s.c; y <= range.e.c; ++y) {

                        const ref = `${excelColumnIndexToLetter(y)}${x}`

                        /* Check reference exists */
                        if(ws[ref]) {

                            /* Set cell styles */
                            ws[ref].s = {
                                font: {
                                    bold: x <= 7 ? true : false,
                                    color: {
                                        rgb: ref === "A5" && priceListInfo?.spli_color === 1 ? "FFEF3340" : undefined
                                    },
                                    name: 'Arial',
                                    sz: 10,
                                },
                                border: x === 7 ? {
                                    bottom: {
                                        style: "thin"
                                    }
                                } : undefined
                            }

                            /* Add currency to "your price" */
                            if(x > 7 && excelColumnIndexToLetter(y) === "C") {
                                if(ws[ref].t === "n") {
                                    ws[ref].z = currencyFormat;
                                }
                            }

                        }

                    }

                }

                 /* Add sheet to book */
                XLSX.utils.book_append_sheet(
                    wb,
                    ws,
                    `${supplier.replace("/", "").replace(":", "").replace("?", "").replace("*", "").substr(0, 27)}${
                        supplier.length > 26 ? "..." : ""
                    }`
                );

                /* Mark export as viable */
                hasSheet = true;

            }

        });

        /* Check if export is viable */
        if (hasSheet) {
            const excelBuffer = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array",
            });

            const data = new Blob([excelBuffer], {
                type: "xlsx",
            });

            FileSaver.saveAs(
                data,
                `${customer?.account_number?.toLowerCase?.()}-price-list-${moment().format("DD-MM-YYYY")}.xlsx`
            );
        }
    };

    _handleExportPriceListSupplier = () => {
        const { customer } = this.props;

        const { supplier, suppliers } = this.state;

        const supplierName = suppliers[supplier] || "";

        let header = [],
            headerArr = [],
            exportData = [],
            headers = this.handleGetExportHeaders(),
            wb = XLSX?.utils?.book_new();

        /* Get prices to export */
        _.each(this.state.data, (row) => {
            let exportRow = [];
            _.each(headers, (field) => {
                if(field.label === "Your Price") {
                    exportRow.push(parseFloat(row[field.key]));
                } else {
                    exportRow.push(row[field.key]);
                }
            });
            exportData.push(exportRow);
        });

        /* Configure headers and header width */
        _.each(headers, (field, idx) => {
            header.push(field.label);
            headerArr.push(`${field.label}XXX`);
        });

        /* Create work sheet */
        const ws = XLSX.utils.json_to_sheet([]);

        /* Add header text */
        let headerTextCount = 6;
        let priceListInfo = this.state.priceListData[supplierName];
        XLSX.utils.sheet_add_aoa(ws, [[`${supplierName}`]], { origin: "A1" });
        XLSX.utils.sheet_add_aoa(ws, [[``]], { origin: "A2" });
        XLSX.utils.sheet_add_aoa(ws, [[`Prices last updated: ${priceListInfo?.lastPriceChange ?? 'Contact us for details'}`]], { origin: "A3" });
        XLSX.utils.sheet_add_aoa(ws, [[`Date for next price changes: ${priceListInfo?.nextPriceChange ?? 'None due'}`]], { origin: "A4" });
        XLSX.utils.sheet_add_aoa(ws, [[priceListInfo?.spli_text ?? ``]], { origin: "A5" });
        XLSX.utils.sheet_add_aoa(ws, [[``]], { origin: "A6" });

        /* Merge header text rows */
        ws['!merges'] = [];
        for (let i = 0; i < headerTextCount; ++i) {
            ws['!merges'].push({ s: {r:i, c:0}, e: {r:i, c:3} });
        }

        /* Add headers to sheet */
        XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A7" });

        /* Add data to sheet */
        XLSX.utils.sheet_add_json(ws, exportData, { origin: "A8", skipHeader: true });

        /* Add filters to columns */
        ws['!autofilter'] = {
            ref: "A7:D7"
        }

        /* Set column with to data set size */
        ws["!cols"] = excelFitToColumns([...exportData, headerArr]);

        /* Handle cell formatting */
        const currencyFormat = "£0.00";
        const range = XLSX.utils.decode_range(ws["!ref"]);

        /* Loop rows for formatting */
        for (let x = (range.s.r + 1); x <= (range.e.r + 1); ++x) {

            /* Loop columns for formatting */
            for (let y = range.s.c; y <= range.e.c; ++y) {

                const ref = `${excelColumnIndexToLetter(y)}${x}`

                /* Check reference exists */
                if(ws[ref]) {

                    /* Set cell styles */
                    ws[ref].s = {
                        font: {
                            bold: x <= 7 ? true : false,
                            color: {
                                rgb: ref === "A5" && priceListInfo?.spli_color === 1 ? "FFEF3340" : undefined
                            },
                            name: 'Arial',
                            sz: 10,
                        },
                        border: x === 7 ? {
                            bottom: {
                                style: "thin"
                            }
                        } : undefined
                    }

                    /* Add currency to "your price" */
                    if(x > 7 && excelColumnIndexToLetter(y) === "C") {
                        if(ws[ref].t === "n") {
                            ws[ref].z = currencyFormat;
                        }
                    }

                }

            }

        }

        /* Add sheet to book */
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            `${supplierName.replace("/", "").replace(":", "").replace("?", "").replace("*", "").substr(0, 27)}${
                supplierName.length > 26 ? "..." : ""
            }`
        );

        /* Create xlsx object */
        const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
        });

        /* Convert xlsx object to blob */
        const data = new Blob([excelBuffer], {
            type: "xlsx",
        });

        /* Output file to browser */
        FileSaver.saveAs(
            data,
            `${customer?.account_number?.toLowerCase?.()}-${supplierName?.toLowerCase?.()}-price-list-${moment().format(
                "DD-MM-YYYY"
            )}.xlsx`
        );

    };

    handleChange = (field, value) => {
        this.setState(
            {
                [field]: value,
            },
            () => {
                if (this.timeout) clearTimeout(this.timeout);

                this.timeout = setTimeout(this.loadComponentData, field === "code" ? 400 : 200);
            }
        );
    };

    handleGetExportHeaders = () => {
        // const {
        //     supplier
        // } = this.state;

        const headers = [
            {
                label: "Part Code",
                key: "c",
            },
            {
                label: "Description",
                key: "d",
            },
        ];

        // if(!supplier) {
        //     headers.push(
        //         {
        //             label: 'Supplier',
        //             key: 's'
        //         }
        //     )
        // }

        headers.push(
            {
                label: "Your Price",
                key: "p",
            },
            {
                label: "Stocked",
                key: "w",
            }
        );

        return headers;
    };

    handleDataTableColumns = () => {
        const { supplier } = this.state;

        const cols = [
            {
                heading: "Part Code",
                field: (rowData) => rowData.c,
                sizeToContent: true,
                dataRef: "c",
            },
            {
                heading: "Description",
                field: (rowData) => rowData.d,
                sizeToContent: true,
                dataRef: "d",
            },
        ];

        if (!supplier) {
            cols.push({
                heading: "Supplier",
                field: (rowData) => rowData.s,
                sizeToContent: true,
                dataRef: "s",
            });
        }

        cols.push(
            {
                heading: "Your Price",
                field: (rowData) => rowData.p,
                fieldFormat: "currency",
                sizeToContent: true,
                dataRef: "p",
            },
            {
                heading: "Stocked",
                field: (rowData) => rowData.w,
                fieldFormat: "boolean",
                sizeToContent: true,
                dataRef: "w",
            }
        );

        return cols;
    };

    render = () => {
        const { code, data, isLoading, type } = this.state;

        const { customer, dataLoading } = this.props;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {(dataLoading && <LoadingCircle />) || (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={12} sm={3}>
                                            <Box minWidth={300}>
                                                <TextField
                                                    fullWidth
                                                    onChange={(e) => this.handleChange("code", e?.target?.value ?? "")}
                                                    placeholder="Search:"
                                                    value={code}
                                                    variant="filled"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <FAIcon type="thin" icon="search" size="small" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={3} lg={4}>
                                            <Box mt={0.4}>
                                                <SupplierSearchSelector
                                                    placeholder="Supplier: All"
                                                    handleAction={(v) => this.handleChange("supplier", v ?? null)}
                                                    searchOnly
                                                    noFocus
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <AutoCompleteSelect
                                                placeholder="Type:"
                                                options={[
                                                    {
                                                        label: "Complete Price List",
                                                        value: "ALL",
                                                    },
                                                    {
                                                        label: "Bought Items Only",
                                                        value: "BOUGHT",
                                                    },
                                                ]}
                                                value={type}
                                                onChange={(v) => this.handleChange("type", v?.value ?? "ALL")}
                                                margin="none"
                                                variant="filled"
                                                adornment="filter"
                                                noClear
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} lg={2} align="right">
                                            <Button
                                                disabled={isLoading}
                                                onClick={this.handleExportPriceList}
                                                variant="text"
                                            >
                                                <FAIcon
                                                    icon="file-excel"
                                                    disabled={isLoading}
                                                    size={15}
                                                    className={`${isLoading ? "" : "textSuccess"} mr-1`}
                                                    button
                                                />
                                                Download Price List
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {customer?.pax_excl_hw === 1 && (
                                    <Grid item xs={12}>
                                        <Alert severity="error">
                                            <Typography variant="body2" className="fw-400">
                                                This customer is on the Paxton Hardware Exclusion List
                                            </Typography>
                                        </Alert>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Paper>
                                        <DataTable
                                            config={{
                                                key: "i",
                                                pagination: true,
                                                rowsPerPage: 10,
                                                alternatingRowColours: true,
                                                isLoading: isLoading,
                                                noResultsText:
                                                    "No price list is available based on your selected filters - please try again",
                                                options: {
                                                    dataRef: true,
                                                    export: {
                                                        title: `Price List`,
                                                        name: `price-list`,
                                                        excel: true,
                                                    }
                                                },
                                            }}
                                            columns={this.handleDataTableColumns()}
                                            rows={data}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    };
}

export default CustomerPriceList;
