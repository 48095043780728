import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';

import Messages from 'Components/Messages/Messages';
import theme from 'Components/Common/Theme/Theme';
import { loadStaffStatus } from 'Redux/Actions/Notifications/Notifications';

const MessageStandalone = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(loadStaffStatus())
    }, [dispatch])

    return (
        <ThemeProvider theme={theme}>
            <Messages open={true} />
        </ThemeProvider>
    )
}

export default MessageStandalone;