import React, { useState, useEffect} from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import logo from 'Assets/Images/no-img.jpg';
import ProductSearchSelector from 'Components/Products/Misc/ProductSearchSelector';
import { deployDialog, closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { CLOUDFRONT_URL } from 'Constants';

const FeaturedProducts = () => {

    /*
     * Component functionality
     */
    const [isLoading, setLoading]               = useState(true);
    const [products, setProducts]               = useState([]);
    const [initialProducts, setInitialProducts] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        loadComponentData();/* eslint-disable-next-line */
    }, [])

    const loadComponentData = () => {

        !isLoading && setLoading(true);

        API.get(`/eCommerce/products/featured`)
        .then(res => {

            if(res?.data) {
                const newProducts = [];

                _.each(res.data, el => {
                    newProducts.push(el);
                })

                for(let i = 0; i < 4; i++) {
                    if(_.isEmpty(newProducts[i])) {
                        newProducts.push({
                            id: 0
                        })
                    }
                }

                setProducts(newProducts);
                setInitialProducts(newProducts);
                setLoading(false);

            }

        })

    }

    const handleAddProduct = idx => {
        dispatch(deployDialog(
            <Box pt={2} pb={2}>
                <ProductSearchSelector
                    label=''
                    forECommerce
                    handleAction={e => e ? _handleAddProduct(idx, e) : undefined}
                />
            </Box>,
            false,
            "Select Featured Product",
            "standard",
            "sm"
        ))
    }

    const _handleAddProduct = (idx, fp) => {

        dispatch(closeDialog())

        const newProducts = Array.from(products);

        newProducts.splice(idx, 1, {
            id: fp.value,
            d: fp.d,
            idx: 0,
            slug: fp.slug,
            c: fp.code,
            img: fp?.img !== "aa" ? fp.img : null
        });

        setProducts(newProducts);
        
    };

    const handleRemoveProduct = idx => {

        const newProducts = Array.from(products);

        newProducts.splice(idx, 1, {
            id: 0
        });

        setProducts(newProducts);
        
    };

    const handleSubmit = () => {

        setLoading(true);

        API.put(`/eCommerce/products/featured`, 
            {
                products
            }
        )
        .then(res => {
            if(res?.data) {
                
                if(res?.data?.success) {
                    dispatch(deploySnackBar(`success`, `The website featured products have been successfully updated`))
                } else {
                    dispatch(deploySnackBar(`error`, `There was an issue updating the website featured products, please try again`))
                }

                loadComponentData();
                
            }
        })

    }

    const disabled = JSON.stringify(products) === JSON.stringify(initialProducts);

    /*
     * Drag and drop functionality
     */
    const onDragEnd = result => {

        if (!result.destination) {
          return;
        }

        const newProducts = reorderDraggedItems(products, result.source.index, result.destination.index);

        setProducts(newProducts);

    }

    const getDragContainerStyle = isDraggingOver => ({
        backgroundColor: isDraggingOver ? '#ef3340' : undefined,
        display: 'flex',
        overflow: 'auto',
    });

    const getDragItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        ...draggableStyle
    });

    const reorderDraggedItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    /*
     * Render component
     */

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <Grid
                                container
                                spacing={3}
                                ref={provided.innerRef}
                                style={getDragContainerStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {_.map(products, (fp, idx) => (
                                    <Draggable key={`fp-${idx}`} draggableId={`fp-${idx}`} index={idx}>
                                        {(provided, snapshot) => (
                                            <Grid 
                                                item 
                                                xs={12}
                                                md={6} 
                                                lg={3}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getDragItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <Card style={{height: '100%'}}>
                                                    {(fp.id > 0 && (
                                                        <>
                                                            <Box pt={5} pb={5} position="relative">
                                                                <Box position="absolute" top={12} right={12}>
                                                                    <Tooltip title='Remove Product'>
                                                                        <IconButton
                                                                            onClick={() => handleRemoveProduct(idx)}
                                                                        >
                                                                            <FAIcon icon="trash-alt" size={15} button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={fp.img ? `${CLOUDFRONT_URL}${fp.img}` : logo}
                                                                    alt={fp.d}
                                                                    style={{
                                                                        width: 150,
                                                                        height: 150,
                                                                        margin: '0 auto'
                                                                    }}
                                                                />
                                                            </Box>
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h6" component="div" className="fw-400">
                                                                    {fp.c}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary" style={{minHeight: 50}}>
                                                                    {fp.d}
                                                                </Typography>
                                                            </CardContent>
                                                        </>
                                                    )) || (
                                                        <Box height='100%' onClick={() => handleAddProduct(idx)}>
                                                            <Box pt={10} pb={10} textAlign='center'>
                                                                <Box pb={3}>
                                                                    <FAIcon icon='box-check' size={125} noMargin />
                                                                </Box>
                                                                <Typography variant="h6">
                                                                    Select Featured Product
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Card>
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
                <Box className="buttonRow">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to update featured products?`, handleSubmit))}
                    >
                        <FAIcon icon='check' size={15} disabled={disabled} buttonPrimary />
                        Update Featured Products
                    </Button>
                </Box>
            </>
        )
    )
}

export default FeaturedProducts;