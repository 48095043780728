import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleUpdateChange,
    handleSnackbarClose,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
    handleDialogClose
} from 'Functions/FormFunctions';

const initialState = {
        formData: {
            supplierType: '',
        },
        updateData: {
            supplierTypeId: '',
            supplierType: '',
        },
        formErrors:[],
        updateErrors: [],
        dialog: {
            update: false
        },
        rows: [],
        confirmation: {
            submit: false,
            update: false,
        },
        snackbar: {
            submit: false,
            update: false,
        },
        updateLoading: true
    }

class SupplierTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleChange = handleChange.bind(this);
        this.handleUpdateChange = handleUpdateChange.bind(this);
        this.handleSnackbarClose = handleSnackbarClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleDialogClose = handleDialogClose.bind(this);
    }

    componentDidMount = () => {
        this.getSupplierTypeList();
    }

    submit = () => {
        API.post('/suppliers/types', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.getSupplierTypeList();
            }
            this.props.scrollToTop();
        });
    }

    update = () => {
        API.put('/suppliers/types/' + this.state.updateData.supplierTypeId, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbar: {
                        update: true,
                        submit: false
                    }
                });
                this.getSupplierTypeList();
            }
            this.props.scrollToTop();
        });
    }
    
    getUpdateData = () => {
        API.get('/suppliers/types/'+this.state.updateData.supplierTypeId)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose('update');
            } else {
                this.setState({
                    updateLoading: false,
                    updateErrors: [],
                    updateData: {
                        ...this.state.updateData,
                        supplierType: result.data.type_name,
                    }
                });
            }
        })
    }
    
    getSupplierTypeList = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/suppliers/types/all')
            .then((result) => {
                this.setState({
                    ...this.state,
                    rows: result.data,
                    isLoading: false
                });
            })
        })
    }
    
    handleDialogOpen = (id) => {
        this.setState({
            dialog: {
                update: true
            },
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                supplierTypeId: id
            }
        }, () => this.getUpdateData());
    }

    render() {
        const { formData, formErrors, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Supplier Types
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'type_id',
                                pagination: true,
                                isLoading: this.state.isLoading,
                                plainPagination: true,
                                options: {
                                    minimalPadding: true
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.type_name,
                                    truncate: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil',  onClick: () => this.handleDialogOpen(rowData.type_id), disabled: rowData.type_id === 1 || rowData.type_id === 2}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.rows}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Supplier Type
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                id="supplierType"
                                name="supplierType"
                                label="Supplier Type *"
                                value={formData.supplierType}
                                error={formErrors && formErrors['supplierType'] && true}
                                helperText={formErrors && formErrors['supplierType']}
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                            <div className='buttonRow'>
                                <Button 
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="text" 
                                    color="primary" 
                                    
                                    disabled={!this.state.formData.supplierType}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Add
                                </Button>
                            </div>
                        </form>
                        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={this.state.dialog.update} onClose={() => this.handleDialogClose('update')} fullWidth={true} maxWidth="sm">
                            <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Supplier Type</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            name="supplierType"
                                            label="Supplier Type *"
                                            value={updateData.supplierType}
                                            error={updateErrors && updateErrors['supplierType'] && true}
                                            helperText={updateErrors && updateErrors['supplierType']}
                                            onChange={this.handleUpdateChange}
                                            margin="none"
                                            fullWidth
                                        />
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleDialogClose('update')} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text"><FAIcon icon="check" size={15} button />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            open={this.state.confirmation.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            title="Update supplier type?" 
                            message="Are you sure you want to update this supplier type?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmation.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title="Add a new supplier type?" 
                            message="Are you sure you want to add a new supplier type?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the specified supplier type'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new supplier type'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

export default SupplierTypes;