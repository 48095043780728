import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffMember from 'Components/Common/Staff/StaffMember';

const ThreadHeader = ({staff, toggleDrawer}) => (
    <Grid container alignItems='center'>
        <Grid item xs>
            {staff && (
                <StaffMember staff={staff} noPadding size={20} bold />
            )}
        </Grid>
        {toggleDrawer && (
            <Grid item>
                <IconButton onClick={() => toggleDrawer('chat')} size={`small`}>
                    <FAIcon type="light" icon="times" noMargin size={15} buttonPrimary />
                </IconButton>
            </Grid>
        )}
    </Grid>
)

export default ThreadHeader;