import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Calendar, momentLocalizer } from 'react-big-calendar'

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import 'Components/Calendar/ViewCalendar/CalendarStyles.css';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Checkbox, Collapse, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper } from '@material-ui/core';

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});

const localizer = momentLocalizer(moment);

const initialState = {
    categories: {},
    activeCategories: {},
    topCategoryList: [],
    dateRange: {
        from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    },
    categoriesLoading: true
}

class CiCalendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getCategories(); 
    }

    eventStyleGetter = (event, start, end, select) => {
        return {
            style: {
                color: ((event.cancelled && '#999999') || 'white'),
                backgroundColor: ((event.cancelled && '#eeeeee') || event.colour),
                borderRadius: 0,
                fontSize: 12,
                marginBottom: 0,
                textDecoration: (event.cancelled && 'line-through')
            }
        }
    }

    getCategories = () => {

        this.setState({
            categoriesLoading: true
        }, () => {

            API.get('/calendar/categories/all', { params: { enabledOnly: true } })
            .then(result => {

                if(result?.data) {

                    let categoryList = _.map(result.data, (cat) => {
                        return _.assign({
                            value: cat.category_id,
                            label: cat.category_name
                        });
                    });

                    let activeCategories = {};

                    _.map(result.data, (cat) => {
                        if(cat.children) {
                            _.map(cat.children, (child) => {
                            if(cat.children) {
                                activeCategories[child.category_id] = true
                            }
                            });
                        }
                    });

                    this.setState({
                        topCategoryList: categoryList,
                        categories: result.data,
                        activeCategories: activeCategories,
                        categoriesLoading: false
                    }, () => {
                        this.props.setActiveCategories?.(this.state.activeCategories);
                    });

                } else {
                    this.setState({
                        categoriesLoading: false
                    });
                }
            })
        })

    }

    toggleParentCategory = id => {

        const {
            setActiveCategories
        } = this.props;

        const {
            activeCategories,
            categories
        } = this.state;

        let parent = categories.find(x => x.category_id === id);

        let anyActive = false;
        _.map(parent.children, child => {
            if(activeCategories[child.category_id] === true)
                anyActive = true;
        });

        let childrenIds = {};
        _.map(parent.children, child => {
            childrenIds[child.category_id] = !anyActive;
        });

        this.setState({
            activeCategories: {
                ...activeCategories,
                ...childrenIds
            }
        },
        () => {
            setActiveCategories?.(this.state.activeCategories);
        })

    }

    toggleCategory = id => {

        const {
            setActiveCategories
        } = this.props;

        const { 
            activeCategories
        } = this.state;

        this.setState({
            activeCategories: {
                ...activeCategories,
                [id]: !activeCategories[id]
            }
        },
        () => {
            setActiveCategories?.(this.state.activeCategories);
        })

    }

    render = () => {

        const {
            events,
            onSelectEvent,
            handleDateRangeChange,
        } = this.props;

        const {
            activeCategories,
            categories,
            categoriesLoading
        } = this.state;

        return (
            (categoriesLoading && (
                <LoadingCircle padding='large' />
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={9}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} className='calendar-grid'>
                                <PaddedPaper>
                                    <div className='calendar-wrapper'>
                                        <Calendar  
                                            popup={true}
                                            localizer={localizer}
                                            startAccessor="start"
                                            endAccessor="end"
                                            events={events}
                                            onSelectEvent={onSelectEvent}
                                            eventPropGetter={this.eventStyleGetter}
                                            onRangeChange={handleDateRangeChange} 
                                        />
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Grid container spacing={3}>
                            {_.map(categories, category => (
                                (category.children && category.children.length > 0 && (
                                    <Grid item xs={12} key={category.category_id}>
                                        <Paper>
                                            <List disablePadding className='sm-scrollbar'>
                                                <ListItem
                                                    button
                                                    onClick={() => this.toggleParentCategory(category.category_id)}
                                                    // selected={activeCategories[category.category_id]}
                                                >
                                                    <ListItemText primary={<Typography variant="body1" style={{fontWeight: 400, fontSize: 12}} className="pt-1 pb-1">{category.category_name}</Typography>}/>
                                                </ListItem>
                                                <Collapse in={true} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {_.map(category.children, childCategory => {
                                                            return (
                                                                <ListItem
                                                                    button
                                                                    key={childCategory.category_id}
                                                                    onClick={() => this.toggleCategory(childCategory.category_id)}
                                                                >
                                                                    <ListItemIcon>
                                                                        <Avatar
                                                                            style={{
                                                                                width: 10, 
                                                                                height: 10,
                                                                                backgroundColor: childCategory.category_colour
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={<Typography variant="body2" style={{fontWeight: 400, fontSize: 12}}>{childCategory.category_name}</Typography>}/>
                                                                    <ListItemSecondaryAction>
                                                                        <Checkbox
                                                                            color="primary"
                                                                            edge="end"
                                                                            checked={activeCategories[childCategory.category_id]}
                                                                            onChange={() => this.toggleCategory(childCategory.category_id)}
                                                                            disableRipple
                                                                        />
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </List>
                                                </Collapse>
                                            </List>
                                        </Paper>
                                    </Grid>
                                )) || (
                                    null
                                )
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            )
        )
    }
}

export default CiCalendar;