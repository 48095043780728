import React from 'react';
import _ from 'lodash';
import uuidv4 from 'uuid';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionMenu from 'Components/Common/Activity/ActionMenu';
import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import DocumentForm from 'Components/Common/Forms/DocumentForm';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tabs from 'Components/Purchases/Actions/Tabs';
import TaskForm from 'Components/Common/Activity/TaskForm';
import ViewPage from 'Components/Common/Styles/ViewPage';

import RMAReceived from 'Components/Warehouse/Actions/RMAReceived';
import Details from 'Components/Purchases/Actions/Details';

import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { handleStepChange, TabPanel } from 'Functions/MiscFunctions';
import { playSound } from 'Redux/Actions/Sounds/Sounds';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

function RedStepIcon(props) {
    const { active, completed } = props;
    return (
        (completed && ( 
            <FAIcon type='light' icon="check" size={17.5} className="textSuccess" />
        )) || (active && (
            <FAIcon type='light' icon="exclamation-triangle" size={17.5} className="textError" />
        )) || (
            <FAIcon type='light' icon="circle" size={17.5} style={{color: 'rgba(0, 0, 0, 0.54)'}} />
        )
    );
}

const RedStepConnector = withStyles({
    active: {
        '& $line': {
            borderColor: '#000',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#000',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 1,
        borderRadius: 1,
    }
})(StepConnector);

const initialState = () => ({
    access: {
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        viewCustomer: false
    },
    currentStep: 0,
    currentTab: null,
    id: 0,
    initialLoading: true,
    rma: {},
    status: null,
    tabs: [],
    uuid: uuidv4()
})

class ProcessRMAReceived extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState()
        this.mainContentArea = React.createRef();
        this.handleStepChange = handleStepChange.bind(this);
        this.timeout = false;
        this.poll = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                ...initialState()
            }, () => this.loadComponentData());
        }
    }

    componentWillUnmount = () => {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        if(this.poll) {
            clearInterval(this.poll);
        }
    }
    
    handlePoll = (initial = false) => {
        const { warehouseMode } = this.props;
        API.post(`/staff/my/view`, 
            { 
                rma: this.state.rma.rma_id,
                type: 'Received'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {
                    const   update      = _.some(res.data, el => el.u === 1),
                            process     = _.some(res.data, el => el.p === 1),
                            received    = _.some(res.data, el => el.re === 1);

                    if(!_.isEmpty(res.data) && (update || process || received)) {
                        this.props.deploySnackBar("error", `Sorry, another user is currently ${update ? 'modifying' : (process ? 'processing' : 'booking in a delivery for')} this RMA`);
                        this.props.history.push(warehouseMode ? `/dashboard` : `/rma/${this.state.id}`)
                    }
                } else {
                    if(this.state.initialLoading) {
                        this.setState({
                            initialLoading: false
                        })
                    }
                }
            }
        })
    }

    loadComponentData = () => {

        let id                  =   this.props.id ?? this.props.match?.params?.id ?? false,
            currentTab          =   null;

        if(id) {
            API.multiAccess([
                'view-rma:add-call',
                'view-rma:add-note',   
                'view-rma:add-document',     
                'view-customer'  
            ])
            .then(([
                addCallLog,
                addNote,
                uploadDoc,
                viewCustomer
            ]) => {
                this.setState({
                    ...this.state,
                    access: {
                        addCallLog,
                        addNote,
                        uploadDoc,
                        viewCustomer
                    },
                    currentTab,
                    id
                }, () => this.getData());
            });
        }
    }

    getData = (changeUuid = true) => {

        const   { id }                        = this.state,
                { pageTitle, warehouseMode }  = this.props;

        API.get(`/rma/${id}`, { props: { noLoading: true } })
        .then(result => {

            if(result?.data) {
                
                let rma         = result.data,
                    status      = rma.rma_status,
                    uuid        = changeUuid ? uuidv4() : this.state.uuid,
                    currentTab  = this.state.currentTab ?? warehouseMode ? (_.isEmpty(rma?.notes) ? 2 : 0) : (_.isEmpty(rma?.notes) ? 1 : 0),
                    products    = [];

                _.each(rma.products, rp => {
                    const   rpId                = rp?.rp_id,
                            rpStatus            = rp?.rp_status,
                            productId           = rp?.rp_product_id,
                            productName         = rp?.rp_product_name,
                            productCode         = rp?.rp_product_code,
                            quantity            = rp?.rp_quantity,
                            recQuantity         = rp?.rp_rec_quantity,
                            recPreQuantity      = rp?.rp_rec_pristine_quantity,
                            cost                = rp?.rp_cost,
                            price               = rp?.rp_paid,
                            subTotal            = rp?.rp_credit,
                            restockFee          = rp?.rp_restock_fee,
                            restockFeeTotal     = rp?.rp_total_restock_fee,
                            restockPercent      = parseFloat(rp?.rp_restock_percent).toFixed(2),
                            total               = rp?.rp_total_credit,
                            vat                 = rp?.rp_total_vat,
                            originalVat         = rp?.rp_total_vat,
                            originalQuantity    = rp?.rp_quantity,
                            image               = rp?.img,
                            productData         = rp?.product,
                            orderRef            = rp?.rp_order_ref,
                            deliveryDate        = rp?.rp_delivery_date,
                            serial              = rp?.rp_serial,
                            rmaNotes            = rp?.rp_notes,
                            sysNotes            = rp?.rp_sys_notes,
                            action              = rp?.action?.ra_code,
                            reason              = rp?.reason?.rr_code,
                            odId                = rp?.rp_od_id,
                            odpId               = rp?.rp_odp_id,
                            received            = rp?.rp_received,
                            receivedDatetime    = rp?.rp_received_datetime,
                            withTech            = rp?.rp_with_tech,
                            withSupplier        = rp?.rp_with_supplier,
                            rtnToSupplier       = rp?.rp_rtn_to_supplier,
                            rtnToSupplierRef    = rp?.rp_rtn_to_supplier_ref,
                            restock             = rp?.rp_restock,
                            noFault             = rp?.rp_no_fault,
                            actionDate          = rp?.rp_action_date,
                            suppCredit          = rp?.rp_supp_credit,
                            suppCreditRef       = rp?.rp_supp_credit_ref,
                            suppCreditDate      = rp?.rp_supp_credit_date,
                            repair              = rp?.rp_repair,
                            replace             = rp?.rp_replace,
                            aaCredit            = rp?.rp_aa_credit,
                            aaCreditRef         = rp?.rp_aa_credit_ref,
                            noAction            = rp?.rp_no_action,
                            closed              = rp?.rp_closed,
                            status              = rp?.rp_status,
                            option              = {};                          

                    products.push({
                        rpId,
                        rpStatus,
                        productId,
                        productName,
                        productCode,
                        quantity,
                        recQuantity,
                        recPreQuantity,
                        cost,
                        price,
                        subTotal,
                        restockFee,
                        restockFeeTotal,
                        restockPercent,
                        total,
                        vat,
                        originalVat,
                        originalQuantity,
                        image,
                        productData,
                        option,
                        orderRef,
                        deliveryDate,
                        serial,
                        rmaNotes,
                        sysNotes,
                        action,
                        reason,
                        odpId,
                        odId,
                        received,
                        receivedDatetime,
                        withTech,
                        withSupplier,
                        rtnToSupplier,
                        rtnToSupplierRef,
                        restock,
                        noFault,
                        actionDate,
                        suppCredit,
                        suppCreditRef,
                        suppCreditDate,
                        repair,
                        replace,
                        aaCredit,
                        aaCreditRef,
                        noAction,
                        closed,
                        status
                    })

                });

                rma.products = products;

                this.setState({
                    currentTab,
                    rma,
                    status,
                    uuid
                }, () => {

                    pageTitle([1, "Warehouse", "Goods In - RMA", `#${rma.rma_reference}`])

                    if(!this.poll) {
                        this.handlePoll(true);
                        this.poll = setInterval(this.handlePoll, 3000);
                    }
                })

            }
        })
    }

    getSteps = () => {
        return ['RMA Identified','Check Lines','Complete'];
    }

    getComponent = () => {
        let { closeDialog, deployConfirmation, deployDialog, deploySnackBar, history, playSound, scrollToTop, warehouseMode, ui } = this.props;
        let { currentTab, id, rma, uuid } = this.state;
        let callback = () => this.getData();
        let currentStep = null;
        let component = null
        let tabs = [];
        let noNotes = _.isEmpty(rma.notes);

        switch(rma?.rma_status) {
            case "Open":
                currentStep = 1;
                tabs = [
                    {
                        label: 'Notes',
                        index: 0,
                        disabled: noNotes
                    },
                    {
                        label: 'RMA Summary',
                        index: 1
                    },
                    {
                        label: 'Confirm Goods Received',
                        index: 2
                    },
                ];
                component = <RMAReceived 
                                id={id} 
                                key={uuid}
                                callback={callback}
                                contentHeight={ui.contentHeight}
                                currentTab={currentTab}
                                closeDialog={closeDialog} 
                                deployDialog={deployDialog} 
                                deployConfirmation={deployConfirmation} 
                                deploySnackBar={deploySnackBar}
                                history={history}
                                rma={rma}
                                playSound={playSound}
                                processingComplete={this.processingComplete}
                                scrollToTop={scrollToTop}
                                warehouseMode={warehouseMode}
                            />
            break;
            default: 
                /* Nothing */
        }

        if(component === null) {

            if(warehouseMode) {
                this.props.history.push(`/dashboard`)
            } else {
                this.props.history.push(`/warehouse/incoming/awaiting-delivery`)
            }

        } else {
            
            if(this.state.currentStep !== 3) {
                if(this.state.currentStep !== currentStep || JSON.stringify(this.state.tabs) !== JSON.stringify(tabs)) {
                    currentTab    =   this.state.currentStep !== currentStep ? (noNotes ? (warehouseMode ? 2 : 1) : 0) : this.state.currentTab;
                    this.setState({
                        currentTab,
                        currentStep,
                        tabs
                    })
                }
            }

            return component;

        }

    }
    
    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            id={this.state.rma?.rma_id}
            relation="rma"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
        />, true, "", "standard","sm");

    handleDeployAddCall = () => this.props.deployDialog(
        <AddCallLogForm
            id={this.state.rma?.rma_id}
            relation="rma"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
        />, true, "", "standard","xs");

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.rma?.rma_id}
            relation="rma"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
            standardOnly
            withFao
        />, true, "", "standard","sm");


    handleDeployFileUpload = () => this.props.deployDialog(
        <DocumentForm 
            id={this.state.rma?.rma_id}
            type="rma"
            category="RMA"
            callback={() => this.getData(false)} 
            cancel={this.props.closeDialog} 
        />, false, "File Upload", "standard","xs");

    handleTabChange = currentTab => {
        this.setState({
            currentTab,
        }, () => {
            if(this.mainContentArea && this.mainContentArea.current) 
                this.mainContentArea.current.scrollTop = 0;
        })
    }

    processingComplete = () => {
        this.setState({
            currentStep: 3
        })
    }

    render() {        
        const { classes, history, warehouseMode, ui } = this.props;
        const { access, currentStep, currentTab, initialLoading, rma, tabs } = this.state;     
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(initialLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        (warehouseMode && (
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        <TabPanel>
                                            {this.getComponent()}
                                        </TabPanel>
                                        {currentTab === 0 && (
                                            <Box pt={3}>
                                                <AppButton
                                                    className="btn btn-success"
                                                    primary
                                                    onClick={() => this.handleTabChange(2)}
                                                    icon="check"
                                                    text="Acknowledged"
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        )) || (
                            <>
                                <Grid item xs={12}>
                                    <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                        <Box pt={1} pr={1} pb={1} pl={3}>
                                            <Grid container alignItems='center'>
                                                <Grid item xs>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item>
                                                            <Typography variant="h4" className="textDefault">
                                                                RMA #{rma.rma_reference}
                                                            </Typography>  
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Add Action" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddTask()}>
                                                            <FAIcon type="light" icon="layer-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                {access.addNote && (
                                                    <Grid item>
                                                        <Tooltip title="Add Note" placement="top">
                                                            <IconButton onClick={() => this.handleDeployAddNote()}>
                                                                <FAIcon type="light" icon="comment-alt-plus" button noMargin size={17.5} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                                {access.addCallLog && (
                                                    <Grid item>
                                                        <Tooltip title="Log Call" placement="top">
                                                            <IconButton onClick={() => this.handleDeployAddCall()}>
                                                                <FAIcon type="light" icon="phone-plus" button noMargin size={17.5} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                                {access.uploadDoc && (
                                                    <Grid item>
                                                        <Tooltip title="Upload File" placement="top">
                                                            <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                                <FAIcon type="light" icon="file-plus" button noMargin size={17.5} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                                {access.viewCustomer && (
                                                    <Grid item>
                                                        <Tooltip title="View Supplier" placement="top">
                                                            <IconButton onClick={() => history.push(`/customers/${rma.rma_cust_id}`)}>
                                                                <FAIcon type="light" icon="user" button noMargin size={17.5} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Tooltip title="View RMA" placement="top">
                                                        <IconButton onClick={() => history.push(`/rma/${rma.rma_id}`)}>
                                                            <FAIcon type="light" icon="exchange-alt" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <ActionMenu
                                                        rows={[
                                                            {
                                                                icon: "layer-plus",
                                                                label: "Add Action",
                                                                onClick: () => this.handleDeployAddTask(),
                                                                display: true
                                                            },
                                                            {
                                                                icon: "comment-alt-plus",
                                                                label: "Add Note",
                                                                onClick: () => this.handleDeployAddNote(),
                                                                display: access.addNote
                                                            },
                                                            {
                                                                icon: "phone-plus",
                                                                label: "Log Call",
                                                                onClick: () => this.handleDeployAddCall(),
                                                                display: access.addCallLog
                                                            },
                                                            {
                                                                icon: "file-plus",
                                                                label: "Upload File",
                                                                onClick: () => this.handleDeployFileUpload(),
                                                                display: access.uploadDoc
                                                            },
                                                            {
                                                                icon: "user",
                                                                label: "View Customer",
                                                                onClick: () => this.props.history.push(`/customers/${rma.rma_cust_id}`),
                                                                display: access.viewCustomer
                                                            },
                                                            {
                                                                icon: "exchange-alt",
                                                                label: "View RMA",
                                                                onClick: () => this.props.history.push(`/rma/${rma.rma_id}`),
                                                                display: true
                                                            }
                                                        ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box pt={0.5}>
                                                    <Grid container justify="space-between" alignItems='center'>
                                                        <Grid item xs={12} lg={5}>
                                                            <Stepper activeStep={currentStep} classes={{root: classes.stepper}} connector={<RedStepConnector />}>
                                                                {this.getSteps().map(label => (
                                                                    <Step key={label}>
                                                                        <StepLabel classes={{label: classes.stepLabel}} StepIconComponent={RedStepIcon}>{label}</StepLabel>
                                                                    </Step>
                                                                ))}
                                                            </Stepper>
                                                        </Grid>
                                                    </Grid> 
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </AppBar>
                                </Grid>   
                                <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                    <Grid container>
                                        <Grid item xs={12}>  
                                            <Box p={3}>
                                                <TabPanel>
                                                    {this.getComponent()}
                                                </TabPanel>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.side} style={{position: 'relative', height: (contentHeight - 90)}}>
                                    <Tabs
                                        classes={classes}
                                        currentTab={currentTab}
                                        rma={rma}
                                        tabs={tabs}
                                        handleTabChange={this.handleTabChange}
                                    />
                                    <Box style={{position: 'absolute', bottom: 24}}>
                                        <Details
                                            classes={classes}
                                            rma={rma}
                                        />
                                    </Box>
                                </Grid>
                            </>
                        )
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, yes, no = false, yesText = null, noText = null, yesOnly = false) => dispatch(deployConfirmation(message, yes, no, yesText, noText, yesOnly)), 
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
        playSound: (type, state = false) => dispatch(playSound(type, state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ProcessRMAReceived));