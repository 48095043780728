import React from 'react';

import Search from 'Components/Common/Search/Search';

const StockBuyingReport = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/stockBuying',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true,
                        showTotalLabel: true
                    },
                    {
                        heading: 'Product Description',
                        field: rowData => rowData.pd,
                        dataRef: 'pd',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Stock Type',
                        field: rowData => rowData.st,
                        dataRef: 'st',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Stock Available',
                        field: rowData => rowData.q,
                        dataRef: 'q',
                        alignment: 'right',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Sales (Last 12 Months)',
                        field: rowData => rowData.lq,
                        dataRef: 'lq',
                        alignment: 'right',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Last Invoice Date',
                        field: rowData => rowData.li,
                        fieldFormat: 'date',
                        dataRef: 'li',
                        alignment: 'right',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Sold Last 3M',
                        field: rowData => rowData.l3,
                        fieldFormat: 'boolean',
                        dataRef: 'l3',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Sold Last 6M',
                        field: rowData => rowData.l6,
                        fieldFormat: 'boolean',
                        dataRef: 'l6',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Sold Last 9M',
                        field: rowData => rowData.l9,
                        fieldFormat: 'boolean',
                        dataRef: 'l9',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Sold Last 12M',
                        field: rowData => rowData.l12,
                        fieldFormat: 'boolean',
                        dataRef: 'l12',
                        alignment: 'center',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Buy Price',
                        field: rowData => rowData.c,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        dataRef: 'c',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Stock Value',
                        field: rowData => rowData.v,
                        fieldFormat: 'currency',
                        alignment: 'right',
                        dataRef: 'v',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'id',
                name: 'stock-buying',
                noResultsText: 'There is no available stock with your search criteria',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Stock'],
                pdf: true,
                persistenceId: 'stockBuying',
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: false,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showStockStatus: false,
                showStockType: false,
                showSupplier: true,
                showTotals: false,
                title: 'Stock Buying Report'
            }}
        />
    )
}

export default StockBuyingReport;