import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import DataTable from 'Components/Common/DataTables/DataTable';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { currencyFormat } from 'Functions/MiscFunctions';

import { 
    excelColumnIndexToLetter,
    isNumeric
} from 'Functions/MiscFunctions';

import {
    getFormData,
    handleChange,
    handleCheckedChange,
    handleSelectChange
} from 'Functions/FormFunctions';

const REDStepConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#E57373',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#ef3340',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 1,
      borderRadius: 1,
    }
})(StepConnector);

const useREDStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#ef3340',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#ef3340',
        zIndex: 1,
        width: 15,
        height: 15,
    }
})

const REDStepIcon = ({active, completed}) => {
    const classes = useREDStepIconStyles();
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <FAIcon icon="check" className={classes.completed} /> : <div className={classes.circle} />}
      </div>
    );
}

const styles = () => ({
    stepper: {
        padding: 0,
        backgroundColor: 'transparent!important'
    },
})

const initialState = () => ({
    completionStep: 9,
    currentStep: 0,
    data: [],
    formData: {
        csv: "",
        columns: {
            internalCode: '',
            supplierCode: '',
            productName: '',
            rrp: '',
            buyPrice: '',
            margin: '',
            tradePrice: '',
            replacement: '',
            delete: ''
        },
        data: {
            add: [],
            replaced: [],
            deleted: [],
            changes: [],
            prices: []
        },
        notes: '',
        priceChangesEffective: null,
        supplier: "",
        includesHeader: 1,
        showMissing: 1,
        showNew: 1
    },
    formErrors: [],
    importId: null,
    isLoading: false,
    lists: {
        columnValues: [],
        suppliers: [],
        identifierColumns: [
            {
                name: 'Internal Code',
                column: 'internalCode'
            },
            {
                name: 'Supplier Code',
                column: 'supplierCode'
            }
        ],
        productColumns: [
            {
                name: 'List Price',
                column: 'rrp'
            },
            {
                name: 'Buy Price',
                column: 'buyPrice'
            },
            // {
            //     name: 'Margin',
            //     column: 'margin'
            // },
            {
                name: 'Trade Price',
                column: 'tradePrice'
            },
            {
                name: 'Description',
                column: 'productName'
            },
            {
                name: 'Replacement Product',
                column: 'replacement'
            },
            {
                name: 'Delete Product',
                column: 'delete'
            }
        ],
    },
    mappingPrefilledWarning: false,
    nothingToDo: 0,
    preview: {
        cols: [],
        rows: []
    }
})

class NewBulkManagementBatch extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState();
        this.getFormData = getFormData.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {

        const {
            pageTitle
        } = this.props;

        pageTitle?.([1, 'Products', 'Bulk Product Management', 'New Bulk Product Import']);

        this.loadComponentData();
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/suppliers/all', 
                {
                    params: {
                        forList: 1,
                        forType: 2,
                        withLatestBulkImport: 1
                    }
                }
            )
        ])
        .then(([
            supplierRes
        ]) => {

            if(supplierRes?.data) {

                let suppliers = _.map(supplierRes.data, supp => (
                                    _.assign({
                                        label: supp.l,
                                        value: supp.v,
                                        latest: supp.latest_bulk_import ?? null
                                    })
                                ));

                let columnValues = this.handleGetColumnValues();
                           
                this.setState({
                   isLoading: false,
                   lists: {
                       ...this.state.lists,
                       suppliers,
                       columnValues
                   }
                })

            }
        })
    }

    handleColumnChange = (field, value) => {
        
        this.setState({
            formData: {
                ...this.state.formData,
                columns: {
                    ...this.state.formData.columns,
                    [field]: value
                }
            }
        }, () => {
            
            let columnValues = this.handleGetColumnValues();

            this.setState({
                lists: {
                    ...this.state.lists,
                    columnValues
                }
            });
            
        });

    }

    handleGetColumnValues = (columns = null) => {

        let {
            formData
        } = this.state;

        if(columns) {
            formData = {
                ...formData,
                columns
            }
        }
        
        let columnValues = [];
        let inUse = [];

        Object.keys(formData.columns).forEach((key) => {
            if(formData.columns[key] !== '') {
                inUse.push(formData.columns[key])
            }
        })

        for (var i = 0; i < 52; i++) {
            columnValues.push({
                label: excelColumnIndexToLetter(i),
                value: i,
                disabled: inUse.includes(i)
            })
        }

        return columnValues;

    }
    
    handleDateChange = (fieldName, date) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: moment(date).format('YYYY-MM-DD'),
            }
        });
    }

    handleFileChange = (drop, name, file) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }
    
    handleFileClear = field => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: ""
            }
        })
    }

    handleRowChange = (type, idx, field, value) => {

        let array = [...this.state.formData?.data?.[type]]

        array[idx][field] = value;

        this.setState({
            formData: {
                ...this.state.formData,
                data: {
                    ...this.state.formData.data,
                    [type]: array
                }
            }
        })

    }

    handleRowNumberChange = (type, idx, field, v) => {

        let value = v;
        let array = [...this.state.formData?.data?.[type]]

        if(isNumeric(parseFloat(value)) && parseFloat(value) < 0) {
            value = '';
        }

        array[idx][field] = value;

        this.setState({
            formData: {
                ...this.state.formData,
                data: {
                    ...this.state.formData.data,
                    [type]: array
                }
            }
        })

    }

    handleUnlockRow = (type, idx) => {

        let array = [...this.state.formData?.data?.[type]]

        array[idx].locked = 0;

        this.setState({
            formData: {
                ...this.state.formData,
                data: {
                    ...this.state.formData.data,
                    [type]: array
                }
            }
        })

    }
    
    handleRowPriceChange = (type, idx, field) => {

        let array = [...this.state.formData?.data?.[type]]

        let {
            rrp,
            buyPrice,
            margin,
            tradePrice,
        } = array[idx];

        switch(field) {  
            case 'rrp':
                if(isNumeric(rrp)) {
                    rrp = parseFloat(rrp).toFixed(2);
                }
            break;
            case 'buyPrice':
                if(isNumeric(buyPrice)) {
                    buyPrice = parseFloat(buyPrice).toFixed(2);
                }
            break;     
            case 'margin':
                if(isNumeric(margin)) {
                    margin = (!margin || isNaN(margin) ? '0.00' : parseFloat(margin).toFixed(4));
                    if(isNumeric(buyPrice)) {
                        tradePrice = ((100 / (100 - margin)) * buyPrice).toFixed(2);
                    }
                }
            break;
            case 'tradePrice':
                if(isNumeric(tradePrice)) {
                    tradePrice = (!tradePrice || isNaN(tradePrice) ? '0.00' : parseFloat(tradePrice)).toFixed(2);
                }
                if(isNumeric(tradePrice) && isNumeric(buyPrice)) {
                    margin = tradePrice > 0.00 && buyPrice > 0.00 && tradePrice !== '' && buyPrice !== '' ? (((tradePrice - buyPrice) / tradePrice) * 100).toFixed(4) : parseFloat(0).toFixed(4);
                }
            break;
            default:
        }

        array[idx].rrp = rrp;
        array[idx].buyPrice = buyPrice;
        array[idx].margin = margin;
        array[idx].tradePrice = tradePrice;

        this.setState({
            formData: {
                ...this.state.formData,
                data: {
                    ...this.state.formData.data,
                    [type]: array
                }
            }
        })

    }

    getSteps = () => {
        return ['Select CSV', 'Map Identifiers', 'Map Columns', 'Check', 'New Products', 'Replaced Products', 'Deleted Products', 'Product Changes', 'Price Changes', 'Review'];
    }

    handleNext = () => {

        let isLoading = true;

        if(this.state.currentStep === 0) {
            
            let {
                currentStep,
                formData: {
                    supplier
                },
                lists: {
                    suppliers
                }
            } = this.state;

            let idx                     = _.findIndex(suppliers, el => el.value === supplier),
                data                    = idx !== -1 ? suppliers[idx] : null,
                columns                 = {...this.state.formData.columns},
                prev                    = !_.isEmpty(data.latest?.pbm_columns) ? JSON.parse(data.latest?.pbm_columns) : [],
                mappingPrefilledWarning = false;

            currentStep++;

            if(!_.isEmpty(prev)) {

                prev.forEach(col => {
                    if(typeof columns?.[col.field] !== "undefined" && col?.mapping) {
                        columns[col.field] = parseInt(col.mapping);
                        mappingPrefilledWarning = true;
                    }
                });
                
                let columnValues = this.handleGetColumnValues(columns);
                this.setState({
                    currentStep,
                    formData: {
                        ...this.state.formData,
                        columns,
                    },
                    lists: {
                        ...this.state.lists,
                        columnValues
                    },
                    mappingPrefilledWarning
                })
                    
            } else {
                this.setState({
                    currentStep,
                })
            }

        } else if(this.state.currentStep === 2) {

            this.setState({
                isLoading
            }, () => {

                API.post('/products/bulkManagement/prepare?preview=true', this.getFormData())
                .then(res => {

                    if(res?.data) {

                        isLoading = false;

                        if(res.data?.errors) {

                            let formErrors  = formatValidationErrors(res.data.errors),
                                currentStep = 0; 
                            
                            this.setState({
                                currentStep,
                                formErrors,
                                isLoading
                            });

                        } else {

                            let currentStep = 3,
                                preview     = {
                                    cols: [],
                                    rows: res.data?.rows ?? []
                                };

                            _.each(res.data.cols, col => {
                                preview.cols.push({
                                    heading: col.heading,
                                    field: rowData => rowData?.[col.field] ?? "",
                                    fieldFormat: !_.isEmpty(col.fieldFormat) ? col.fieldFormat : undefined,
                                    sizeToContent: col.sizeToContent
                                })
                            })

                            this.setState({
                                currentStep,                               
                                isLoading,
                                preview
                            })

                        }

                    }

                })  

            })       

        } else if(this.state.currentStep === 3) {

            this.setState({
                isLoading
            }, () => {

                API.post('/products/bulkManagement/prepare', this.getFormData())
                .then(res => {

                    if(res?.data) {

                        isLoading = false;

                        if(res.data?.errors) {

                            let formErrors  = formatValidationErrors(res.data.errors),
                                currentStep = 0; 
                            
                            this.setState({
                                currentStep,
                                formErrors,
                                isLoading
                            });

                        } else {

                            let currentStep = res?.data?.isComplete === 1 ? this.state.completionStep : 4,
                                nothingToDo = currentStep === this.state.completionStep ? 1 : 0,
                                data        = res?.data,
                                add         = data?.add ?? [],
                                replaced    = data?.replaced ?? [],
                                deleted     = data?.deleted ?? [],
                                changes     = data?.changes ?? [],
                                prices      = data?.prices ?? [];

                            this.setState({
                                currentStep,
                                formData: {
                                    ...this.state.formData,
                                    data: {
                                        ...this.state.formData.data,
                                        add,
                                        replaced,
                                        deleted,
                                        changes,
                                        prices
                                    }
                                },
                                isLoading,
                                nothingToDo
                            })

                        }

                    }

                })  

            })
            
        } else if(this.state.currentStep === 9) {

            const {
                deployConfirmation
            } = this.props;

            deployConfirmation(`Are you sure you want to apply this bulk product data import?`, this.handleSubmit)
            return;

        } else {

            let {
                currentStep
            } = this.state;
    
            currentStep++;

            this.setState({
                currentStep
            })

        }
    }

    handleBack = () => {

        let {
            currentStep
        } = this.state;

        currentStep--;

        this.setState({
            currentStep
        })

    }

    handleReset = () => {
        this.setState({
            ...initialState()
        }, () => this.loadComponentData())
    }

    handleSubmit = () => {

        const {
            deploySnackBar 
        } = this.props;

        let isLoading = true;

        this.setState({
            isLoading,
        }, () => {

            API.post('/products/bulkManagement/apply', this.getFormData())
            .then(res => {

                if(res?.data) {

                    isLoading       = false;

                    if(res.data?.errors) {

                        let formErrors  = formatValidationErrors(res.data.errors),
                            currentStep = 0; 
                        
                        this.setState({
                            currentStep,
                            formErrors,
                            isLoading
                        });

                    } else {

                        let currentStep = this.state.completionStep,
                            nothingToDo = 0,
                            importId    = res?.data?.importId ?? null;

                        this.setState({
                            currentStep,
                            importId,
                            isLoading,
                            nothingToDo
                        }, () => {
                            deploySnackBar(`success`, `The bulk product data import has been successfully applied`)
                        })

                    }

                }

            })  

        })

    }
    
    Welcome = () => {

        const { 
            formData, 
            formErrors,
            lists
        } = this.state;

        return (
            <Paper style={{maxWidth: 500, margin: '0 auto'}}>
                <Box p={3} pb={4} maxWidth={500}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} align='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FAIcon icon="arrow-to-bottom" size={50} noMargin />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">
                                        Product Data Import
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        Please drag and drop or select a csv file containing product data
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {formErrors?.generic && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {formErrors.generic}
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12} align='center'>
                            <Box maxWidth={500}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            options={lists?.suppliers}
                                            value={formData.supplier}
                                            onChange={o => this.handleSelectChange('supplier', {value: o?.value ?? ''})}
                                            error={formErrors?.['supplier'] && true}
                                            errorText={formErrors?.['supplier'] ?? undefined}
                                            margin="none"
                                            variant="filled"
                                            placeholder="Supplier: *"
                                            autoFocus
                                        />
                                        <DragFileInput
                                            id="csv"
                                            name="csv"
                                            primary="csv"
                                            icon="file-csv"
                                            file={formData.csv}
                                            errorText={formErrors?.['csv'] ?? undefined}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={() => this.handleFileClear('csv')}
                                        />
                                        <FormControlLabel
                                            checked={formData.includesHeader} 
                                            control={<Checkbox name="includesHeader" value="1" color="primary" onChange={this.handleCheckedChange} />}
                                            label="Includes Header Row"  
                                        />
                                        <br />
                                        <FormControlLabel
                                            checked={formData.showMissing} 
                                            control={<Checkbox name="showMissing" value="1" color="primary" onChange={this.handleCheckedChange} />}
                                            label="Identify Deleted Products"  
                                        />
                                        <FormControlLabel
                                            checked={formData.showNew} 
                                            control={<Checkbox name="showNew" value="1" color="primary" onChange={this.handleCheckedChange} />}
                                            label="Identify New Products"  
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button 
                                            className={`pt-2 pb-2 btn ${formData.csv === "" || formData.supplier === "" ? 'btn-disabled' : 'btn-success'}`}
                                            onClick={this.handleNext}
                                            disabled={formData.csv === "" || formData.supplier === ""}
                                            fullWidth
                                        >
                                            <FAIcon disabled={formData.csv === "" || formData.supplier === ""} icon="check" size={13.5} buttonPrimary />
                                            Continue
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )

    }

    MapIdentifiers = () => {

        const { 
            formData, 
            lists,
            mappingPrefilledWarning
        } = this.state;

        return (
            <Paper style={{maxWidth: 500, margin: '0 auto'}}>
                <Box p={3} pb={4} maxWidth={500}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} align='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FAIcon icon="table" size={50} noMargin />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">
                                        Map Product Identifiers
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        Please select the columns to identify products
                                    </Typography>
                                </Grid>
                                {mappingPrefilledWarning && (
                                    <Grid item xs={12}>
                                        <Box pb={1} pt={1} textAlign='left'>
                                            <Alert severity="warning">
                                                <Typography variant="body2" className="fw-400">
                                                    Check Mapping
                                                </Typography>
                                                <Typography variant="body2">
                                                    The mapping has been pre-filled from the last bulk product import
                                                </Typography>
                                            </Alert>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Box maxWidth={500}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <DataTable
                                            config={{
                                                key: 'i',
                                                rowsPerPage: 25,
                                                alternatingRowColours: true,
                                                inline: true,
                                                options: {
                                                    plainPagination: true
                                                }
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Field',
                                                    field: rowData => <strong>{rowData.name}</strong>,
                                                    important: true,
                                                    main: true
                                                },
                                                {
                                                    heading: 'Column',
                                                    field: rowData => (
                                                        <Box width={100}>
                                                            <AutoCompleteSelect
                                                                options={lists?.columnValues}
                                                                value={formData.columns?.[rowData.column]}
                                                                onChange={o => this.handleColumnChange(rowData.column, o?.value ?? '')}
                                                                margin="none"
                                                                variant="filled"
                                                                noDefaultSort
                                                                placeholder='Ignore'
                                                            />
                                                        </Box>
                                                    ),
                                                    important: true,
                                                    sizeToContent: true
                                                }
                                            ]}
                                            rows={lists?.identifierColumns}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button 
                                            className={`pt-2 pb-2 btn ${formData?.columns?.internalCode === '' && formData?.columns?.supplierCode === '' ? 'btn-disabled' : 'btn-success'}`}
                                            onClick={this.handleNext}
                                            disabled={formData?.columns?.internalCode === '' && formData?.columns?.supplierCode === ''}
                                            fullWidth
                                        >
                                            <FAIcon disabled={formData?.columns?.internalCode === '' && formData?.columns?.supplierCode === ''} icon="check" size={13.5} buttonPrimary />
                                            Continue
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )

    }

    MapColumns = () => {

        const { 
            formData, 
            lists,
        } = this.state;

        return (
            <Paper style={{maxWidth: 500, margin: '0 auto'}}>
                <Box p={3} pb={4} maxWidth={500}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} align='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FAIcon icon="table" size={50} noMargin />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">
                                        Map Product Data
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        Please select the data to update with this import
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pb={1} pt={1} textAlign='left'>
                                        <Alert severity="warning">
                                            <Typography variant="body2" className="fw-400">
                                                Price Changes
                                            </Typography>
                                            <Typography variant="body2">
                                                List Price, Buy Price and Trade Price are required for price changes
                                            </Typography>
                                        </Alert>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Box maxWidth={500}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <DataTable
                                            config={{
                                                key: 'i',
                                                rowsPerPage: 25,
                                                alternatingRowColours: true,
                                                inline: true,
                                                options: {
                                                    plainPagination: true
                                                }
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Field',
                                                    field: rowData => <strong>{rowData.name}</strong>,
                                                    important: true,
                                                    main: true
                                                },
                                                {
                                                    heading: 'Column',
                                                    field: rowData => (
                                                        <Box width={100}>
                                                            <AutoCompleteSelect
                                                                options={lists?.columnValues}
                                                                value={formData.columns?.[rowData.column]}
                                                                onChange={o => this.handleColumnChange(rowData.column, o?.value ?? '')}
                                                                margin="none"
                                                                variant="filled"
                                                                noDefaultSort
                                                                placeholder='Ignore'
                                                            />
                                                        </Box>
                                                    ),
                                                    important: true,
                                                    sizeToContent: true
                                                }
                                            ]}
                                            rows={lists?.productColumns}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button                                             
                                            className={`pt-2 pb-2 btn ${_.size(_.filter(formData?.columns, el => el !== '')) <= (formData?.columns?.internalCode !== '' && formData?.columns?.supplierCode !== '' ? 2 : 1) ? 'btn-disabled' : 'btn-success'}`}
                                            onClick={this.handleNext}
                                            disabled={_.size(_.filter(formData?.columns, el => el !== '')) <= (formData?.columns?.internalCode !== '' && formData?.columns?.supplierCode !== '' ? 2 : 1)}
                                            fullWidth
                                        >
                                            <FAIcon disabled={_.size(_.filter(formData?.columns, el => el !== '')) <= (formData?.columns?.internalCode !== '' && formData?.columns?.supplierCode !== '' ? 2 : 1)} icon="check" size={13.5} buttonPrimary />
                                            Continue
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )

    }
    
    ReviewData = () => {

        const { 
            preview
        } = this.state;

        return (
            <Paper style={{margin: '0 auto'}}>
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} align='center'>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FAIcon icon="user-check" size={50} noMargin />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">
                                        Check Data Mapping
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        Please check the data is mapped to the correct fields
                                    </Typography>
                                    <Typography variant="body2" className="fw-400">
                                        {_.size(preview.rows)} products were processed from the spreadsheet
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <DataTable
                                            config={{
                                                key: 'i',
                                                alternatingRowColours: true,
                                                inline: true,
                                            }}
                                            columns={preview.cols}
                                            rows={preview.rows}

                                        />
                                    </Grid>                                    
                                    <Grid item xs={12} align='right'>
                                        <Button 
                                            className={`pt-2 pb-2 btn btn-success`}
                                            onClick={this.handleNext}
                                        >
                                            <FAIcon icon="check" size={13.5} buttonPrimary />
                                            Continue
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )

    }

    Completed = () => (
        (this.state.nothingToDo === 1 && (
            <Paper style={{maxWidth: 500, margin: '0 auto'}}>
                <Box p={3} maxWidth={500} margin='0 auto'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} align='center'>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FAIcon icon="check" size={50} noMargin />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">
                                        Nothing To Do
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        All imported data matches the current product data in RED
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Button 
                                className={`pt-2 pb-2 btn btn-warning`}
                                color="primary" 
                                onClick={this.handleReset}
                                fullWidth
                            >
                                <FAIcon icon="undo" size={13.5} button />
                                New Import
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )) || (
            <Paper style={{maxWidth: 500, margin: '0 auto'}}>
                <Box p={3} maxWidth={500} margin='0 auto'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} align='center'>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FAIcon icon="check" size={50} noMargin />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">
                                        Product Data Imported
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        All product data has been imported and updated successfully
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} align='center'>
                            <Button 
                                className={`pt-2 pb-2 btn btn-success`}
                                color="primary" 
                                onClick={this.handleReset}
                                fullWidth
                            >
                                <FAIcon icon="undo" size={13.5} button />
                                New Import
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} align='center'>
                            <Button 
                                className={`pt-2 pb-2 btn ${!this.state.importId ? 'btn-disabled' : 'btn-success'}`}
                                color="primary"
                                disabled={!this.state.importId} 
                                onClick={() => this.props.history.push(`/products/bulk-management/${this.state.importId}`)}
                                fullWidth
                            >
                                <FAIcon icon="binoculars" disabled={!this.state.importId} size={13.5} buttonPrimary />
                                View Import
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )
    )

    NothingToDo = ({step}) => (
        <Paper style={{maxWidth: 500, margin: '0 auto'}}>
            <Box p={3} maxWidth={500} margin='0 auto'>
                <Grid container spacing={3}>
                    <Grid item xs={12} align='center'>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FAIcon icon="info-circle" size={50} noMargin />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    Nothing To Do
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    There are no {step} to review
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align='center'>
                        <Button 
                            className={`pt-2 pb-2 btn btn-success`}
                            color="primary" 
                            onClick={this.handleNext}
                            fullWidth
                        >
                            <FAIcon icon="check" size={13.5} buttonPrimary />
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )

    NewProducts = () => {

        const {
            formData
        } = this.state;

        const disabled = !_.isEmpty(formData?.data?.add) && _.some(_.filter(formData?.data?.add, el => el.apply === 1), _el => _el.internalCode === '' || _el.supplierCode === '' || _el.productName === '' || _el.rrp === '' || _el.buyPrice === '' || _el.margin === '' || _el.tradePrice === '');

        return (
            (_.isEmpty(formData?.data?.add) && (
                this.NothingToDo({step: 'new products'})
            )) || (
                <Paper style={{margin: '0 auto'}}>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FAIcon icon="plus-circle" size={50} noMargin />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">
                                            New Products
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            The following products do not current exist in RED
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            {_.size(formData?.data?.add)} new products
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <DataTable
                                                config={{
                                                    key: 'idx',
                                                    alternatingRowColours: true,
                                                    noRowHover: true,
                                                    inline: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Add',
                                                        field: rowData => (
                                                            <FormControlLabel
                                                                checked={rowData.apply} 
                                                                control={<Checkbox name="apply" value="1" color="primary" onChange={() => this.handleRowChange('add', rowData.rowIdx, 'apply', rowData?.apply === 1 ? 0 : 1)} />}
                                                            />
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Internal Code',
                                                        field: rowData => (
                                                            <Box width={200}>
                                                                <TextField
                                                                    name="internalCode"
                                                                    margin="none" 
                                                                    autoComplete="off"
                                                                    value={rowData.internalCode}
                                                                    onChange={e => this.handleRowChange('add', rowData.rowIdx, 'internalCode', e?.target?.value)}
                                                                    disabled={rowData.apply === 0}
                                                                    inputProps={{
                                                                        style: {
                                                                            border: rowData.internalCode === '' && rowData.apply === 1 ? '2px solid #ef3340' : undefined
                                                                        }
                                                                    }}
                                                                    required
                                                                    variant="filled"
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Supplier Code',
                                                        field: rowData => (
                                                            <Box width={200}>
                                                                <TextField
                                                                    name="supplierCode"
                                                                    margin="none" 
                                                                    autoComplete="off"
                                                                    value={rowData.supplierCode}
                                                                    onChange={e => this.handleRowChange('add', rowData.rowIdx, 'supplierCode', e?.target?.value)}
                                                                    disabled={rowData.apply === 0}
                                                                    required
                                                                    inputProps={{
                                                                        style: {
                                                                            border: rowData.supplierCode === '' && rowData.apply === 1 ? '2px solid #ef3340' : undefined
                                                                        }
                                                                    }}
                                                                    variant="filled"
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Description',
                                                        field: rowData => (
                                                            <TextField
                                                                name="productName"
                                                                margin="none" 
                                                                autoComplete="off"
                                                                value={rowData.productName}
                                                                onChange={e => this.handleRowChange('add', rowData.rowIdx, 'productName', e?.target?.value)}
                                                                disabled={rowData.apply === 0}
                                                                required
                                                                inputProps={{
                                                                    style: {
                                                                        border: rowData.productName === '' && rowData.apply === 1 ? '2px solid #ef3340' : undefined
                                                                    }
                                                                }}
                                                                fullWidth
                                                                variant="filled"
                                                            />
                                                        ),
                                                    },
                                                    {
                                                        heading: 'List Price',
                                                        field: rowData => (
                                                            <Box width={150}>
                                                                <TextField
                                                                    name="rrp"
                                                                    margin="none" 
                                                                    autoComplete="off"
                                                                    value={rowData.rrp}
                                                                    type="number"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                £
                                                                            </InputAdornment>
                                                                        ),
                                                                        endAdornment: (
                                                                            (rowData.rrpCalc === 1 && (
                                                                                <InputAdornment position="start">
                                                                                    <Tooltip title="The List Price has been set to the Buy Price (No List Price column was specified)">
                                                                                        <Box>
                                                                                            <FAIcon icon="exclamation-triangle" className="textError" button noMargin />
                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                </InputAdornment>
                                                                            )) || (
                                                                                null
                                                                            )
                                                                        ),
                                                                        style: {
                                                                            border: rowData.rrp === '' && rowData.apply === 1 ? '2px solid #ef3340' : undefined
                                                                        }
                                                                    }}   
                                                                    onChange={e => this.handleRowNumberChange('add', rowData.rowIdx, 'rrp', e?.target?.value)}
                                                                    onBlur={() => this.handleRowPriceChange('add', rowData.rowIdx, 'rrp')}
                                                                    disabled={rowData.apply === 0}
                                                                    required
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Buy Price',
                                                        field: rowData => (
                                                            <Box width={150}>
                                                                <TextField
                                                                    name="buyPrice"
                                                                    margin="none" 
                                                                    autoComplete="off"
                                                                    value={rowData.buyPrice}
                                                                    type="number"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                £
                                                                            </InputAdornment>
                                                                        ),
                                                                        style: {
                                                                            border: rowData.buyPrice === '' && rowData.apply === 1 ? '2px solid #ef3340' : undefined
                                                                        }
                                                                    }}  
                                                                    onChange={e => this.handleRowNumberChange('add', rowData.rowIdx, 'buyPrice', e?.target?.value)}
                                                                    onBlur={() => this.handleRowPriceChange('add', rowData.rowIdx, 'buyPrice')}
                                                                    disabled={rowData.apply === 0}
                                                                    required
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Margin',
                                                        field: rowData => (
                                                            <Box width={100}>
                                                                <TextField
                                                                    name="margin"
                                                                    margin="none" 
                                                                    autoComplete="off"
                                                                    value={rowData.margin}
                                                                    type="number"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                %
                                                                            </InputAdornment>
                                                                        ), 
                                                                        style: {
                                                                            border: rowData.margin === '' && rowData.apply === 1 ? '2px solid #ef3340' : undefined
                                                                        }
                                                                    }}  
                                                                    onChange={e => this.handleRowNumberChange('add', rowData.rowIdx, 'margin', e?.target?.value)}
                                                                    onBlur={() => this.handleRowPriceChange('add', rowData.rowIdx, 'margin')}
                                                                    disabled={rowData.apply === 0}
                                                                    required
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Trade Price',
                                                        field: rowData => (
                                                            <Box width={150}>
                                                                <TextField
                                                                    name="tradePrice"
                                                                    margin="none" 
                                                                    autoComplete="off"
                                                                    value={rowData.tradePrice}
                                                                    type="number"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                £
                                                                            </InputAdornment>
                                                                        ),
                                                                        endAdornment: (
                                                                            (rowData.priceCalc === 1 && (
                                                                                <InputAdornment position="start">
                                                                                    <Tooltip title="This trade price was calculated based on the buy price plus a 25.00% margin (No trade price column was specified)">
                                                                                        <Box>
                                                                                            <FAIcon icon="exclamation-triangle" className="textError" button noMargin />
                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                </InputAdornment>
                                                                            )) || (
                                                                                null
                                                                            )
                                                                        ),
                                                                        style: {
                                                                            border: rowData.tradePrice === '' && rowData.apply === 1 ? '2px solid #ef3340' : undefined
                                                                        }
                                                                    }}   
                                                                    onChange={e => this.handleRowNumberChange('add', rowData.rowIdx, 'tradePrice', e?.target?.value)}
                                                                    onBlur={() => this.handleRowPriceChange('add', rowData.rowIdx, 'tradePrice')}
                                                                    disabled={rowData.apply === 0}
                                                                    required
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        ),
                                                        sizeToContent: true
                                                    }
                                                ]}
                                                rows={formData?.data?.add}

                                            />
                                        </Grid>                                    
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                className={`pt-2 pb-2 btn ${disabled ? `btn-disabled` : `btn-success`}`}
                                                onClick={this.handleNext}
                                                disabled={disabled}
                                            >
                                                <FAIcon icon="check" disabled={disabled} size={13.5} buttonPrimary />
                                                Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )
        )
    }
    
    ReplacedProducts = () => {

        const {
            formData
        } = this.state;

        const disabled = false;

        return (
            (_.isEmpty(formData?.data?.replaced) && (
                this.NothingToDo({step: 'replaced products'})
            )) || (
                <Paper style={{margin: '0 auto'}}>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FAIcon icon="stop-circle" size={50} noMargin />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">
                                            Replaced Products
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            The following products will be replaced
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            {_.size(formData?.data?.replaced)} replaced products
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <DataTable
                                                config={{
                                                    key: 'idx',
                                                    alternatingRowColours: true,
                                                    noRowHover: true,
                                                    inline: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Apply',
                                                        field: rowData => (
                                                            <FormControlLabel
                                                                checked={rowData.apply} 
                                                                control={<Checkbox name="apply" value="1" color="primary" onChange={() => this.handleRowChange('replaced', rowData.rowIdx, 'apply', rowData?.apply === 1 ? 0 : 1)} />}
                                                            />
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Product Code',
                                                        field: rowData => rowData.pc,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Description',
                                                        field: rowData => rowData.pn,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'In Stock',
                                                        field: rowData => rowData.is,
                                                        alignment: 'right',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Replacement Code',
                                                        field: rowData => rowData.rep?.default_code?.code_value ?? 'Unknown',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Replacement Description',
                                                        field: rowData => rowData.rep?.product_name ?? 'Unknown',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Check P&A',
                                                        field: rowData => (
                                                            <FormControlLabel
                                                                checked={rowData.chkPrice} 
                                                                control={<Checkbox disabled={rowData.apply === 0} name="chkPrice" value="1" color="primary" onChange={() => this.handleRowChange('replaced', rowData.rowIdx, 'chkPrice', rowData?.chkPrice === 1 ? 0 : 1)} />}
                                                            />
                                                        ),
                                                        sizeToContent: true,
                                                        alignment: 'center'
                                                    },
                                                ]}
                                                rows={formData?.data?.replaced}

                                            />
                                        </Grid>                                    
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                className={`pt-2 pb-2 btn ${disabled ? `btn-disabled` : `btn-success`}`}
                                                onClick={this.handleNext}
                                                disabled={disabled}
                                            >
                                                <FAIcon icon="check" disabled={disabled} size={13.5} buttonPrimary />
                                                Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )
        )
    }

    DeletedProducts = () => {

        const {
            formData
        } = this.state;

        const disabled = !_.isEmpty(formData?.data?.deleted) && _.some(_.filter(formData?.data?.deleted, el => el.apply === 1), _el => _el.delete === 0 && _el.discont === 0);

        return (
            (_.isEmpty(formData?.data?.deleted) && (
                this.NothingToDo({step: 'deleted products'})
            )) || (
                <Paper style={{margin: '0 auto'}}>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FAIcon icon="times-circle" size={50} noMargin />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">
                                            Deleted Products
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            The following products are deleted
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            {_.size(formData?.data?.deleted)} deleted products
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <DataTable
                                                config={{
                                                    key: 'idx',
                                                    alternatingRowColours: true,
                                                    noRowHover: true,
                                                    inline: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Apply',
                                                        field: rowData => (
                                                            <FormControlLabel
                                                                checked={rowData.apply} 
                                                                control={<Checkbox name="apply" value="1" color="primary" onChange={() => this.handleRowChange('deleted', rowData.rowIdx, 'apply', rowData?.apply === 1 ? 0 : 1)} />}
                                                            />
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Product Code',
                                                        field: rowData => rowData.pc,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Description',
                                                        field: rowData => rowData.pn,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'In Stock',
                                                        field: rowData => rowData.is,
                                                        alignment: 'right',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Status',
                                                        field: rowData => rowData.st,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Reason',
                                                        field: rowData => rowData.type,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Check P&A',
                                                        field: rowData => (
                                                            <Checkbox disabled={rowData.apply === 0} checked={rowData.chkPrice === 1} name="chkPrice" value="1" color="primary" onChange={() => this.handleRowChange('deleted', rowData.rowIdx, 'chkPrice', rowData?.chkPrice === 1 ? 0 : 1)} />
                                                        ),
                                                        alignment: 'center',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Discontinued',
                                                        field: rowData => (
                                                            <Checkbox disabled={rowData.apply === 0} style={{border: rowData.delete === 0 && rowData.discont === 0 ? '2px solid #ef3340' : undefined}} checked={rowData.discont === 1} name="chkPrice" value="1" color="primary" onChange={() => { let v = rowData?.discont; this.handleRowChange('deleted', rowData.rowIdx, 'discont', v === 1 ? 0 : 1); if(v === 0 && rowData.delete === 1) { this.handleRowChange('deleted', rowData.rowIdx, 'delete', 0) }}} />
                                                        ),
                                                        alignment: 'center',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Delete',
                                                        field: rowData => (
                                                            <Checkbox disabled={rowData.apply === 0} style={{border: rowData.delete === 0 && rowData.discont === 0 ? '2px solid #ef3340' : undefined}} checked={rowData.delete === 1} name="chkPrice" value="1" color="primary" onChange={() => { let v = rowData?.delete; this.handleRowChange('deleted', rowData.rowIdx, 'delete', v === 1 ? 0 : 1); if(v === 0 && rowData.discont === 1) { this.handleRowChange('deleted', rowData.rowIdx, 'discont', 0) }}} />
                                                        ),
                                                        alignment: 'center',
                                                        sizeToContent: true
                                                    },
                                                ]}
                                                rows={formData?.data?.deleted}

                                            />
                                        </Grid>                                    
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                className={`pt-2 pb-2 btn ${disabled ? `btn-disabled` : `btn-success`}`}
                                                onClick={this.handleNext}
                                                disabled={disabled}
                                            >
                                                <FAIcon icon="check" disabled={disabled} size={13.5} buttonPrimary />
                                                Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )
        )
    }

    ProductChanges = () => {

        const {
            formData
        } = this.state;

        const disabled = !_.isEmpty(formData?.data?.changes) && _.some(_.filter(formData?.data?.changes, el => el.apply === 1), _el => _el.required === 1 && _el.value === '');

        return (
            (_.isEmpty(formData?.data?.changes) && (
                this.NothingToDo({step: 'product data changes'})
            )) || (
                <Paper style={{margin: '0 auto'}}>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FAIcon icon="exclamation-circle" size={50} noMargin />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">
                                            Product Data Changes
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            The following product details have changed
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            {_.size(formData?.data?.changes)} product detail changes
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <DataTable
                                                config={{
                                                    key: 'idx',
                                                    alternatingRowColours: true,
                                                    noRowHover: true,
                                                    inline: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Apply',
                                                        field: rowData => (
                                                            <FormControlLabel
                                                                checked={rowData.apply} 
                                                                control={<Checkbox name="apply" value="1" color="primary" onChange={() => this.handleRowChange('changes', rowData.rowIdx, 'apply', rowData?.apply === 1 ? 0 : 1)} />}
                                                            />
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Product Code',
                                                        field: rowData => rowData.pc,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Field',
                                                        field: rowData => rowData.field,
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Current Value',
                                                        field: rowData => rowData.oldValue,
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'New Value',
                                                        field: rowData => (
                                                            <Box width={500}>
                                                                <TextField
                                                                    name="value"
                                                                    margin="none" 
                                                                    autoComplete="off"
                                                                    value={rowData.newValue}
                                                                    onChange={e => this.handleRowChange('changes', rowData.rowIdx, 'newValue', e?.target?.value ?? '')}
                                                                    required={rowData.required === 1}
                                                                    variant="filled"
                                                                    fullWidth
                                                                    disabled={rowData.apply === 0}
                                                                />
                                                            </Box>
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Required',
                                                        field: rowData => rowData.required,
                                                        fieldFormat: 'boolean',
                                                        alignment: 'center',
                                                        sizeToContent: true
                                                    }
                                                ]}
                                                rows={formData?.data?.changes}
                                            />
                                        </Grid>                                    
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                className={`pt-2 pb-2 btn ${disabled ? `btn-disabled` : `btn-success`}`}
                                                onClick={this.handleNext}
                                                disabled={disabled}
                                            >
                                                <FAIcon icon="check" disabled={disabled} size={13.5} buttonPrimary />
                                                Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )
        )
    }

    PriceChanges = () => {

        const {
            formData
        } = this.state;

        const disabled = !_.isEmpty(formData?.data?.prices) && _.some(_.filter(formData?.data?.prices, el => el.apply === 1), _el => _el.rrp === '' || _el.buyPrice === '' || _el.margin === '' || _el.tradePrice === '');

        return (
            (_.isEmpty(formData?.data?.prices) && (
                this.NothingToDo({step: 'price changes'})
            )) || (
                <Paper style={{margin: '0 auto'}}>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FAIcon icon="exclamation-triangle" size={50} noMargin />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">
                                            Price Changes
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            The following product pricing rates have changed
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            {_.size(formData?.data?.prices)} price changes
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Box mb={2} maxWidth={300}>
                                    <DatePicker
                                        name="priceChangesEffective"
                                        type="date"
                                        label={`Price Changes Apply:`}
                                        onChange={date => this.handleDateChange('priceChangesEffective', date)}
                                        value={formData.priceChangesEffective}
                                        autoOk={true}
                                        inputVariant="outlined"
                                        disablePast={true}
                                        noAdornment
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <DataTable
                                                config={{
                                                    key: 'idx',
                                                    alternatingRowColours: true,
                                                    noRowHover: true,
                                                    inline: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Apply',
                                                        field: rowData => (
                                                            <FormControlLabel
                                                                checked={rowData.apply} 
                                                                control={<Checkbox name="apply" value="1" color="primary" onChange={() => this.handleRowChange('prices', rowData.rowIdx, 'apply', rowData?.apply === 1 ? 0 : 1)} />}
                                                            />
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Product Code',
                                                        field: rowData => rowData.pc,
                                                        sizeToContent: true
                                                    },
                                                    // {
                                                    //     heading: 'Description',
                                                    //     field: rowData => rowData.pn,
                                                    //     sizeToContent: true
                                                    // },
                                                    {
                                                        heading: 'List Price',
                                                        field: rowData => rowData.oldRrp,
                                                        fieldFormat: 'currency',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Discount',
                                                        field: rowData => 100 - (100 * (rowData.oldBuyPrice / rowData.oldRrp)),
                                                        fieldFormat: 'percentage:2',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Buy Price',
                                                        field: rowData => rowData.oldBuyPrice,
                                                        fieldFormat: 'currency',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Margin',
                                                        field: rowData => rowData.oldMargin,
                                                        fieldFormat: 'percentage:4',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Trade Price',
                                                        field: rowData => rowData.oldTradePrice,
                                                        fieldFormat: 'currency',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'New List Price',
                                                        field: rowData => (
                                                            (rowData.locked === 1 && (
                                                                currencyFormat.format(rowData.rrp)
                                                            )) || (
                                                                <Box width={125}>
                                                                    <TextField
                                                                        name="rrp"
                                                                        margin="none" 
                                                                        autoComplete="off"
                                                                        value={rowData.rrp}
                                                                        type="number"
                                                                        InputProps={{
                                                                            startAdornment: 
                                                                                <InputAdornment position="start">
                                                                                    £
                                                                                </InputAdornment>
                                                                        }}  
                                                                        onChange={e => this.handleRowNumberChange('prices', rowData.rowIdx, 'rrp', e?.target?.value)}
                                                                        onBlur={() => this.handleRowPriceChange('prices', rowData.rowIdx, 'rrp')}
                                                                        disabled={rowData.apply === 0}
                                                                        required
                                                                        variant="filled"
                                                                    />
                                                                </Box>
                                                            )
                                                        ),
                                                        sizeToContent: true,
                                                        cellProps: rowData => ({
                                                            style: {
                                                                backgroundColor: rowData.apply === 1 && parseFloat(rowData.oldRrp) !== parseFloat(rowData.rrp) ? '#FFCDD2' : undefined,
                                                            }
                                                        })
                                                    },
                                                    {
                                                        heading: 'New Discount',
                                                        field: rowData => rowData.buyPrice === '' || rowData.rrp === '' ? 0 : (100 - (100 * (rowData.buyPrice / rowData.rrp))),
                                                        fieldFormat: 'percentage:2',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'New Buy Price',
                                                        field: rowData => (
                                                            (rowData.locked === 1 && (
                                                                currencyFormat.format(rowData.buyPrice)
                                                            )) || (
                                                                <Box width={125}>
                                                                    <TextField
                                                                        name="buyPrice"
                                                                        margin="none" 
                                                                        autoComplete="off"
                                                                        value={rowData.buyPrice}
                                                                        type="number"
                                                                        InputProps={{
                                                                            startAdornment: 
                                                                                <InputAdornment position="start">
                                                                                    £
                                                                                </InputAdornment>
                                                                        }}  
                                                                        onChange={e => this.handleRowNumberChange('prices', rowData.rowIdx, 'buyPrice', e?.target?.value)}
                                                                        onBlur={() => this.handleRowPriceChange('prices', rowData.rowIdx, 'buyPrice')}
                                                                        disabled={rowData.apply === 0}
                                                                        required
                                                                        variant="filled"
                                                                    />
                                                                </Box>
                                                            )
                                                        ),
                                                        sizeToContent: true,
                                                        cellProps: rowData => ({
                                                            style: {
                                                                backgroundColor: rowData.apply === 1 && parseFloat(rowData.oldBuyPrice) !== parseFloat(rowData.buyPrice) ? '#FFCDD2' : undefined,
                                                            }
                                                        })
                                                    },
                                                    {
                                                        heading: 'New Margin',
                                                        field: rowData => (
                                                            (rowData.locked === 1 && (
                                                                `${rowData.margin}%`
                                                            )) || (
                                                                <Box width={100}>
                                                                    <TextField
                                                                        name="margin"
                                                                        margin="none" 
                                                                        autoComplete="off"
                                                                        value={rowData.margin}
                                                                        type="number"
                                                                        InputProps={{
                                                                            endAdornment: 
                                                                                <InputAdornment position="end">
                                                                                    %
                                                                                </InputAdornment>
                                                                        }}  
                                                                        onChange={e => this.handleRowNumberChange('prices', rowData.rowIdx, 'margin', e?.target?.value)}
                                                                        onBlur={() => this.handleRowPriceChange('prices', rowData.rowIdx, 'margin')}
                                                                        disabled={rowData.apply === 0}
                                                                        required
                                                                        variant="filled"
                                                                    />
                                                                </Box>
                                                            )
                                                        ),
                                                        sizeToContent: true,
                                                        cellProps: rowData => ({
                                                            style: {
                                                                backgroundColor: rowData.apply === 1 && parseFloat(rowData.oldMargin) !== parseFloat(rowData.margin) ? '#FFCDD2' : undefined,
                                                            }
                                                        })
                                                    },
                                                    {
                                                        heading: 'New Trade Price',
                                                        field: rowData => (
                                                            (rowData.locked === 1 && (
                                                                currencyFormat.format(rowData.tradePrice)
                                                            )) || (
                                                                <Box width={125}>
                                                                    <TextField
                                                                        name="tradePrice"
                                                                        margin="none" 
                                                                        autoComplete="off"
                                                                        value={rowData.tradePrice}
                                                                        type="number"
                                                                        InputProps={{
                                                                            startAdornment: 
                                                                                <InputAdornment position="start">
                                                                                    £
                                                                                </InputAdornment>
                                                                        }}   
                                                                        onChange={e => this.handleRowNumberChange('prices', rowData.rowIdx, 'tradePrice', e?.target?.value)}
                                                                        onBlur={() => this.handleRowPriceChange('prices', rowData.rowIdx, 'tradePrice')}
                                                                        disabled={rowData.apply === 0}
                                                                        required
                                                                        variant="filled"
                                                                    />
                                                                </Box>
                                                            )
                                                        ),
                                                        sizeToContent: true,
                                                        cellProps: rowData => ({
                                                            style: {
                                                                backgroundColor: rowData.apply === 1 && parseFloat(rowData.oldTradePrice) !== parseFloat(rowData.tradePrice) ? '#FFCDD2' : undefined,
                                                            }
                                                        })
                                                    },
                                                    {
                                                        heading: 'Check P&A',
                                                        field: rowData => (
                                                            (rowData.locked === 1 && (
                                                                <FAIcon icon={rowData.chkPrice === 1 ? 'check' : 'times'} className={rowData.chkPrice === 1 ? 'textSuccess' : 'textError'} size={17.5} noMargin />
                                                            )) || (
                                                                <Checkbox disabled={rowData.apply === 0} checked={rowData.chkPrice === 1} name="chkPrice" value="1" color="primary" onChange={() => this.handleRowChange('prices', rowData.rowIdx, 'chkPrice', rowData?.chkPrice === 1 ? 0 : 1)} />
                                                            )
                                                        ),
                                                        alignment: 'center',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: '',
                                                        field: rowData => (
                                                            <Tooltip title="Modify">
                                                                <IconButton disabled={rowData.locked === 0 || rowData.apply === 0} onClick={() => this.handleUnlockRow('prices', rowData.rowIdx)}>
                                                                    <FAIcon icon="pencil" noMargin button size={17.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ),
                                                        alignment: 'center',
                                                        sizeToContent: true
                                                    },
                                                ]}
                                                rows={formData?.data?.prices}
                                            />
                                        </Grid>                               
                                        <Grid item xs={12} align='right'>
                                            <Button 
                                                className={`pt-2 pb-2 btn ${disabled ? `btn-disabled` : `btn-success`}`}
                                                onClick={this.handleNext}
                                                disabled={disabled}
                                            >
                                                <FAIcon icon="check" disabled={disabled} size={13.5} buttonPrimary />
                                                Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )
        )
    }

    Review = () => {

        const {
            formData: {
                data: {
                    add,
                    replaced,
                    deleted,
                    changes,
                    prices
                },
                notes
            }
        } = this.state;

        let newProducts         = _.filter(add, el => el.apply === 1),
            replacedProducts    = _.filter(replaced, el => el.apply === 1),
            deletedProducts     = _.filter(deleted, el => el.apply === 1),
            changedProducts     = _.filter(changes, el => el.apply === 1),
            priceChanges        = _.filter(prices, el => el.apply === 1),
            disabled            = _.isEmpty(newProducts) && _.isEmpty(replacedProducts) && _.isEmpty(deletedProducts) && _.isEmpty(changedProducts) && _.isEmpty(priceChanges);

        return (
            <Paper>
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} align='center'>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FAIcon icon="search" size={50} noMargin />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">
                                        Bulk Product Import Review
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        Please check the following changes carefully before proceeding
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{margin: '0 auto'}} elevation={0}>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        New Products
                                    </Typography>
                                    {(_.isEmpty(newProducts) && (
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <FAIcon icon="info-circle" className="textInfo" size={15} type="solid" />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" className="fw-400">
                                                    There are no new products to import
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )) || (
                                        <DataTable
                                            config={{
                                                key: 'idx',
                                                alternatingRowColours: true,
                                                noRowHover: true,
                                                inline: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Internal Code',
                                                    field: rowData => rowData.internalCode,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Supplier Code',
                                                    field: rowData => rowData.supplierCode,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Description',
                                                    field: rowData => rowData.productName,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'List Price',
                                                    field: rowData => rowData.rrp,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Buy Price',
                                                    field: rowData => rowData.buyPrice,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Margin',
                                                    field: rowData => rowData.margin,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Trade Price',
                                                    field: rowData => rowData.tradePrice,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                }
                                            ]}
                                            rows={newProducts}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{margin: '0 auto'}} elevation={0}>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Replaced Products
                                    </Typography>
                                    {(_.isEmpty(replacedProducts) && (
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <FAIcon icon="info-circle" className="textInfo" size={15} type="solid" />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" className="fw-400">
                                                    There are no new replaced products to import
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )) || (
                                        <DataTable
                                            config={{
                                                key: 'idx',
                                                alternatingRowColours: true,
                                                noRowHover: true,
                                                inline: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Product Code',
                                                    field: rowData => rowData.pc,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Description',
                                                    field: rowData => rowData.pn,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'In Stock',
                                                    field: rowData => rowData.is,
                                                    alignment: 'right',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Replacement Code',
                                                    field: rowData => rowData.rep?.default_code?.code_value ?? 'Unknown',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Replacement Description',
                                                    field: rowData => rowData.rep?.product_name ?? 'Unknown',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Check P&A',
                                                    field: rowData => rowData.chkPrice,
                                                    fieldFormat: 'boolean',
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                }
                                            ]}
                                            rows={replacedProducts}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{margin: '0 auto'}} elevation={0}>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Deleted Products
                                    </Typography>
                                    {(_.isEmpty(deletedProducts) && (
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <FAIcon icon="info-circle" className="textInfo" size={15} type="solid" />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" className="fw-400">
                                                    There are no discontinued / deleted products to import
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )) || (
                                        <DataTable
                                            config={{
                                                key: 'idx',
                                                alternatingRowColours: true,
                                                noRowHover: true,
                                                inline: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Product Code',
                                                    field: rowData => rowData.pc,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Description',
                                                    field: rowData => rowData.pn,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Status',
                                                    field: rowData => rowData.st,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'In Stock',
                                                    field: rowData => rowData.is,
                                                    alignment: 'right',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Reason',
                                                    field: rowData => rowData.type,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Check P&A',
                                                    field: rowData => rowData.chkPrice,
                                                    fieldFormat: 'boolean',
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'Discontinued',
                                                    field: rowData => rowData.discont,
                                                    fieldFormat: 'boolean',
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'Delete',
                                                    field: rowData => rowData.delete,
                                                    fieldFormat: 'boolean',
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                }
                                            ]}
                                            rows={deletedProducts}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{margin: '0 auto'}} elevation={0}>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Product Data Changes
                                    </Typography>
                                    {(_.isEmpty(changedProducts) && (
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <FAIcon icon="info-circle" className="textInfo" size={15} type="solid" />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" className="fw-400">
                                                    There are no products data changes to import
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )) || (
                                        <DataTable
                                            config={{
                                                key: 'idx',
                                                alternatingRowColours: true,
                                                noRowHover: true,
                                                inline: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Product Code',
                                                    field: rowData => rowData.pc,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Field',
                                                    field: rowData => rowData.field,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Current Value',
                                                    field: rowData => rowData.oldValue,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'New Value',
                                                    field: rowData => rowData.newValue,
                                                    sizeToContent: true,
                                                },
                                            ]}
                                            rows={changedProducts}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{margin: '0 auto'}} elevation={0}>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Product Price Changes
                                    </Typography>
                                    {(_.isEmpty(priceChanges) && (
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <FAIcon icon="info-circle" className="textInfo" size={15} type="solid" />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" className="fw-400">
                                                    There are no product price changes to import
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )) || (
                                        <DataTable
                                            config={{
                                                key: 'idx',
                                                alternatingRowColours: true,
                                                noRowHover: true,
                                                inline: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Product Code',
                                                    field: rowData => rowData.pc,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Description',
                                                    field: rowData => rowData.pn,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Old List Price',
                                                    field: rowData => rowData.oldRrp,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Old Buy Price',
                                                    field: rowData => rowData.oldBuyPrice,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Old Margin',
                                                    field: rowData => rowData.oldMargin,
                                                    fieldFormat: 'percentage:2',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Old Trade Price',
                                                    field: rowData => rowData.oldTradePrice,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'New List Price',
                                                    field: rowData => rowData.rrp,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'New Buy Price',
                                                    field: rowData => rowData.buyPrice,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'New Margin',
                                                    field: rowData => rowData.margin,
                                                    fieldFormat: 'percentage:2',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'New Trade Price',
                                                    field: rowData => rowData.tradePrice,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true
                                                }
                                            ]}
                                            rows={priceChanges}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{margin: '0 auto'}} elevation={0}>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Notes
                                    </Typography>
                                    <Textarea 
                                        name="notes"
                                        value={notes}
                                        onChange={this.handleChange}
                                        rows={2}
                                        variant="outlined"
                                    />
                                </Box>
                            </Paper>
                        </Grid>   
                        <Grid item xs={12} align='center'>
                            <Button 
                                className={`pl-3 pr-3 pt-2 pb-2 btn ${disabled ? 'btn-disabled' : 'btn-success'}`}
                                onClick={this.handleNext}
                                disabled={disabled}
                        >
                                <FAIcon icon="check" size={13.5} buttonPrimary />
                                Apply Product Changes
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )
    }

    render = () => {

        const { 
            classes,
            deployConfirmation
        } = this.props;

        const {
            completionStep,
            currentStep,
            importId,
            isLoading, 
            nothingToDo
        } = this.state;
        
        const steps = this.getSteps();
        
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>   
                                <Hidden mdDown>
                                    <Grid item xs={12}>
                                        <Stepper activeStep={currentStep === completionStep ? (completionStep + 1) : currentStep} classes={{root: classes.stepper}} connector={<REDStepConnector />}>
                                            {steps.map(label => (
                                                <Step key={label}>
                                                    <StepLabel StepIconComponent={REDStepIcon}>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12}>  
                                    {currentStep === 0 && (
                                        this.Welcome()
                                    )}
                                    {currentStep === 1 && (
                                        this.MapIdentifiers()
                                    )}
                                    {currentStep === 2 && (
                                        this.MapColumns()
                                    )}
                                    {currentStep === 3 && (
                                        this.ReviewData()
                                    )}
                                    {currentStep === 4 && (
                                        this.NewProducts({})
                                    )}
                                    {currentStep === 5 && (
                                        this.ReplacedProducts()
                                    )}
                                    {currentStep === 6 && (
                                        this.DeletedProducts()
                                    )}
                                    {currentStep === 7 && (
                                        this.ProductChanges()
                                    )}
                                    {currentStep === 8 && (
                                        this.PriceChanges()
                                    )}
                                    {currentStep === completionStep && (
                                        (nothingToDo === 0 && !importId && (
                                            this.Review()
                                        )) || (
                                            this.Completed()
                                        )
                                    )}
                                </Grid>
                                <Grid item xs={12} align="center">
                                    {(currentStep === 1 && (
                                        <Button 
                                            onClick={() => deployConfirmation(`Are you sure you want to restart this bulk product data import?`, this.handleReset)}
                                            variant="text"
                                        >
                                            <FAIcon icon="undo" size={13.5} button />
                                            Restart
                                        </Button>
                                    )) || (currentStep > 1 && (currentStep !== completionStep || (currentStep === completionStep && nothingToDo === 0 && !importId)) && (
                                        <>
                                            <Button 
                                                variant="text" 
                                                color="primary" 
                                                onClick={() => deployConfirmation(`Are you sure you want to restart this bulk product data import?`, this.handleReset)}
                                            >
                                                <FAIcon icon="undo" size={13.5} button />
                                                Restart
                                            </Button>
                                            <Button
                                                disabled={currentStep === 0}
                                                onClick={this.handleBack}
                                            >
                                                <FAIcon icon="arrow-left" size={13.5} button />
                                                Back
                                            </Button>
                                        </>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(NewBulkManagementBatch));