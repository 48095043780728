import React from 'react';
import _ from 'lodash';

import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const Tabs = ({btnUpdateDisabled, classes, currentTab, disableCustomerTab, formData, handleTabChange, id}) => (
    <MuiTabs
        color="primary"
        onChange={handleTabChange}
        orientation="vertical"
        TabIndicatorProps={{
            style: {
                display: 'none'
            }
        }}
        value={currentTab}
        variant="scrollable"
    >
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={0} disabled={id || disableCustomerTab} label="Customer" />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={1} disabled={!formData.customer && true} label="Return Items" />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={2} disabled={!formData.customer && true} label="Details" />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={3} disabled={(btnUpdateDisabled !== undefined ? btnUpdateDisabled : (!formData.customer || _.isEmpty(formData.productRows))) && true} label="Preview" />
    </MuiTabs>
)

export default Tabs;