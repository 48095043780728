import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const WildixCallCentre = ({
    handleClose,
    queues
}) => {

    const getFormattedDuration = (e) => {
        if(isNaN(e))return"00:00";var t=parseInt(e/3600)%24,n=parseInt(e/60)%60,r="";return t>0&&(r+=(t<10?"0"+t:t)+":"),r+=(n<10?"0"+n:n)+":"+((e%=60)<10?"0"+e:e) /* eslint-disable-line */
    }

    // const getFormattedState = (state) => {
    //     switch(state) {

    //         case "ring":
    //             return "Ringing";

    //         case "onhold":
    //         case "hold":
    //             return "On Hold";

    //         case "inuse":
    //         case "up":
    //             return "Connected"

    //         default: 
    //             return state;
    //     }
    // }

    return (
        <Dialog
            disableEscapeKeyDown
            disableBackdropClick
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            open
        >
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">
                            Queued Calls
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose}>
                                <FAIcon
                                    icon="times"
                                    button
                                    noMargin
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box mb={2.5}>
                    <Grid container spacing={3}>
                        {_.map(queues, (data, idx) => (
                            <Grid item xs={12} key={idx}>
                                <Typography className={`fw-400 ${_.size(data.users) > 10 ? 'textError' : _.size(data.users) > 0 ? 'textWarning' : 'textSuccess'}`} variant="body1" paragraph>
                                    <FAIcon icon={_.size(data.users) > 10 ? 'exclamation-circle' : 'check-circle'} size={15} />
                                    {data.queue.getName()} Queue ({_.size(data.users)})
                                </Typography>
                                {/* <DataTable 
                                    config={{
                                        key: 'ch',
                                        alternatingRowColours: true,
                                        isLoading: false,
                                        responsiveImportance: true,
                                        noResultsText: 'This call group has no calls'
                                    }}
                                    columns={[
                                        {
                                            heading: 'Extension',
                                            field: rowData => <Box className="fw-400">{rowData.member.getName()} ({rowData.member.getExtension()})</Box>,
                                            main: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Callee',
                                            field: rowData => <Box className="fw-400">{rowData.call.getCalleeNumber()}</Box>,
                                            main: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData => getFormattedState(rowData.member.getStatus()),
                                            main: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Channel',
                                            field: rowData => rowData.call.getChannel(),
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Duration',
                                            field: rowData => getFormattedDuration(rowData.call.getDuration()),
                                            sizeToContent: true,
                                        },
                                        // {
                                        //     actions: rowData => getCallActions(rowData)
                                        // }
                                    ]}
                                    rows={data.calls}
                                /> */}
                                <DataTable 
                                    config={{
                                        key: 'ch',
                                        alternatingRowColours: true,
                                        isLoading: false,
                                        inline: true,
                                        responsiveImportance: true,
                                        noResultsText: 'No calls queued'
                                    }}
                                    columns={[
                                        {
                                            heading: 'Callee',
                                            field: rowData => <Box className="fw-400">{rowData.getNumber()}</Box>,
                                            main: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Status',
                                            field: () => 'Queued',
                                        },
                                        // {
                                        //     heading: 'Channel',
                                        //     field: rowData => rowData.getChannel(),
                                        //     sizeToContent: true,
                                        // },
                                        {
                                            heading: 'Duration',
                                            field: rowData => getFormattedDuration(rowData.getDuration()),
                                            sizeToContent: true,
                                        },
                                        // {
                                        //     actions: rowData => getCallActions(rowData)
                                        // }
                                    ]}
                                    rows={data.users}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default WildixCallCentre;