import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Document, Page, pdfjs } from 'react-pdf';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { getFormDataHook } from 'Functions/FormFunctions';
import { isTablet } from 'Functions/MiscFunctions';
import { CLOUDFRONT_URL } from 'Constants';
import { currencyFormat } from 'Functions/MiscFunctions';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PurchaseOrderMultiInvoiceComparison = ({category, file, existingFile, title, closeDialog, content, deployConfirmation, handleSubmit, id, po, reference}) => {

    const [ checked, setChecked ] = useState(false);

    const [ duplicateDialog, setDuplicateDialog ] = useState(false);

    const [ duplicateDetails, setDuplicateDetails ] = useState(null);

    const [isLoading,setLoading] = useState(false);

    const [state, setState] = useState({
        category,
        existingFile,
        file,
        title,
        date: null,
        ref: '',
        totalCost: 0,
        totalCarriage: 0,
        totalNet: 0,
        totalVat:0,
        totalGross: 0,
        exTotalCost: "0.00",
        exTotalCarriage: "0.00",
        exTotalNet: "0.00",
        exTotalVat: "0.00",
        exTotalGross: "0.00",
        disputeCarr: 0,
        disputeReason: '',
        lines: [],
        actualLines: []
    });

    const handleBlur = fieldName => {
        let net = fieldName !== "totalNet" && fieldName !== "totalGross" && fieldName !== "totalVat" ? (!_.isEmpty(state.totalCost) && !_.isEmpty(state.totalCarriage) ? (parseFloat(state.totalCost) + parseFloat(state.totalCarriage)).toFixed(2) : '') : state.totalNet;
        setState(state => ({
            ...state,
            [fieldName]: !_.isEmpty(state?.[fieldName]) ? parseFloat(state?.[fieldName]).toFixed(2) : '',
            totalNet: net,
            totalGross: fieldName !== "totalNet" && fieldName !== "totalGross" ? (!_.isEmpty(net) && !_.isEmpty(state.totalVat) ? (parseFloat(net) + parseFloat(state.totalVat)).toFixed(2) : '') : state.totalGross
        }))    
    }

    const handleChange = (fieldName, value) => {
        setState(state => ({
            ...state,
            [fieldName]: value
        }))    
    }

    const handleCheckDetails = () => {
        API.post(`/purchaseOrders/invoice/check`, {
            ref: state.ref,
            date: state.date,
            poId: po.po_id
        })
        .then(res => {
            if(res?.data?.success) {
                setChecked(true);
            } else {
                setDuplicateDetails(res.data);
                setDuplicateDialog(true);
            }
        })
    }

    const handleCloseDuplicateDialog = ignore => {
        if(ignore) {
            setDuplicateDialog(false);
            setChecked(true);
        } else {
            closeDialog();
        }
    }

    const handleLineBlur = (idx, fieldName) => {
        let newLines = [...state.actualLines];
        newLines[idx] = {
            ...newLines[idx],
            [fieldName]: fieldName !== "quantity" ? (isNaN(parseFloat(newLines[idx]?.[fieldName] ?? 0).toFixed(2)) ? "" : parseFloat(newLines[idx]?.[fieldName] ?? 0).toFixed(2)) : parseInt(newLines[idx]?.[fieldName])
        }
        if(fieldName !== "totalCost") {
            newLines[idx] = {
                ...newLines[idx],
                totalCost: isNaN((parseInt(newLines[idx]?.quantity) * parseFloat(newLines[idx]?.cost)).toFixed(2)) ? "" : (parseInt(newLines[idx]?.quantity) * parseFloat(newLines[idx]?.cost)).toFixed(2)
            }
        }
        setState(state => ({
            ...state,
            actualLines: newLines,
        }))    
    }

    const handleLineChange = (idx, fieldName, value) => {
        let newLines = [...state.actualLines];
        newLines[idx] = {
            ...newLines[idx],
            [fieldName]: value
        }
        setState(state => ({
            ...state,
            actualLines: newLines
        }))    
    }

    const submit = () => {
        const formData = getFormDataHook(state);
        setLoading(true);
        handleSubmit(id, formData)
    }

    const [pdf, setPdf] = useState({
        numPages: '',
        pageNumber: 1,
        scale: isTablet() ? 0.75 : 1.5
    });

    const handlePdfLoad = ({numPages}) => {
        setPdf(pdf => ({
            ...pdf,
            numPages
        }))
    }

    // const handlePdfPagination = offset => {
    //     setPdf(pdf => ({
    //         ...pdf,
    //         pageNumber: ((pdf?.pageNumber ?? 1) + offset)
    //     }))
    // }

    const handlePdfScale = zoom => {
        let newScale = pdf.scale;
        if(zoom) {
            newScale = (parseFloat(pdf.scale) + parseFloat(0.25)).toFixed(2)
        } else {
            newScale = (parseFloat(pdf.scale) - parseFloat(0.25)).toFixed(2)
        }
        setPdf(pdf => ({
            ...pdf,
            scale: newScale
        }))
    }

    const handleSetAllZero = () => {
        
        let newLines = [],
            newTotalCost = "0.00",
            newTotalCarriage = _.some(po.products, el => parseInt(el.quantityInvoiced) > 0) ? "0.00" : po.po_total_carriage;

        _.each(po.products, prd => {
            let quantity = "0";

            let totalCost = "0.00"
            newLines.push({
                popId: prd.popId,
                productCode: prd.productCode,
                productName: prd.productName,
                quantityDespatch: prd.quantityDespatch,
                quantityDelivered: prd.quantityDelivered,
                quantityInvoiced: prd.quantityInvoiced,
                cost: prd.cost,
                originalCost: totalCost,
                originalQty: prd.quantity,
                totalCost: totalCost,
                productId: prd.productId,
                currentCost: prd.currentCost,
                disputed: 0,
                quantity
            })
            newTotalCost = (parseFloat(newTotalCost) + parseFloat(totalCost)).toFixed(2);
        })

        let net = (parseFloat(newTotalCost) + parseFloat(newTotalCarriage)).toFixed(2);
        let vat = "0.00";
        let vatPercent = po?.vat?.vat_value_percentage ?? 0;

        if(vatPercent > 0) {
            vat = parseFloat((net / 100) * vatPercent).toFixed(2)
        }
        
        let totalGross = (parseFloat(net) + parseFloat(vat)).toFixed(2)

        setState(state => ({
            ...state,
            lines: newLines,
            actualLines: newLines,
            totalCost: newTotalCost,
            totalCarriage: newTotalCarriage,
            totalNet: net,
            totalVat: vat,
            totalGross: totalGross,
        }))/* eslint-disable-next-line */

    }
    
    // const previousPage = () => handlePdfPagination(-1);

    // const nextPage = () => handlePdfPagination(1);
    
    useEffect(() => {

        if(!file && !existingFile) {
            closeDialog();
        }

        let newLines = [],
            newTotalCost = "0.00",
            newTotalCarriage = po.po_total_carriage;
            newTotalCarriage = _.some(po.products, el => parseInt(el.quantityInvoiced) > 0) ? "0.00" : po.po_total_carriage;

        _.each(po.products, prd => {
            let quantity = parseFloat(prd.quantity).toFixed(0);
            if(parseFloat(prd.quantityInvoiced) > 0) {
                quantity = (parseFloat(prd.quantity) - parseFloat(prd.quantityInvoiced)).toFixed(0);
            }
            // } else if(parseFloat(prd.quantityDelivered) > 0) {
            //     quantity = parseFloat(prd.quantityDelivered).toFixed(0);
            // }

            if(parseFloat(quantity) < 0) {
                quantity = "0";
            }
            
            let totalCost = (parseFloat(quantity) * parseFloat(prd.cost)).toFixed(2);
            newLines.push({
                popId: prd.popId,
                productCode: prd.productCode,
                productName: prd.productName,
                quantityDespatch: prd.quantityDespatch,
                quantityDelivered: prd.quantityDelivered,
                quantityInvoiced: prd.quantityInvoiced,
                cost: prd.cost,
                originalCost: totalCost,
                originalQty: prd.quantity,
                totalCost: totalCost,
                productId: prd.productId,
                currentCost: prd.currentCost,
                disputed: 0,
                quantity
            })
            newTotalCost = (parseFloat(newTotalCost) + parseFloat(totalCost)).toFixed(2);
        })

        let net = (parseFloat(newTotalCost) + parseFloat(newTotalCarriage)).toFixed(2);
        let vat = "0.00";
        let vatPercent = po?.vat?.vat_value_percentage ?? 0;

        if(vatPercent > 0) {
            vat = parseFloat((net / 100) * vatPercent).toFixed(2)
        }
        
        let totalGross = (parseFloat(net) + parseFloat(vat)).toFixed(2)

        setState(state => ({
            ...state,
            lines: newLines,
            actualLines: newLines,
            totalCost: newTotalCost,
            totalCarriage: newTotalCarriage,
            totalNet: net,
            totalVat: vat,
            totalGross: totalGross,
            exTotalCost: newTotalCost,
            exTotalCarriage: newTotalCarriage,
            exTotalNet: net,
            exTotalVat: vat,
            exTotalGross: totalGross,
        }))/* eslint-disable-next-line */
    }, [existingFile, file, closeDialog])

    useEffect(() => {
        let newTotalCost = "0.00";
        state.actualLines.forEach(pop => {
            newTotalCost = (parseFloat(newTotalCost) + parseFloat(pop.totalCost)).toFixed(2)
        })
        setState(state => ({
            ...state,
            totalCost: newTotalCost,
        }))    
    }, [state.actualLines])

    useEffect(() => {
        let net = (!_.isEmpty(state.totalCost) && !_.isEmpty(state.totalCarriage) ? (parseFloat(state.totalCost) + parseFloat(state.totalCarriage)).toFixed(2) : '');
        let vat = "0.00";
        let vatPercent = po?.vat?.vat_value_percentage ?? 0;
        if(vatPercent > 0) {
            vat = parseFloat((net / 100) * vatPercent).toFixed(2)
        }
        setState(state => ({
            ...state,
            totalNet: net,
            totalVat: vat,
            totalGross: (parseFloat(net) + parseFloat(vat)).toFixed(2)
        }))    /* eslint-disable-next-line */
    }, [state.totalCost, state.totalCarriage])

    const disabled = !state.date || state.date === '' || state.ref === '' || state.totalCost === '' || state.totalCarriage === '' || state.totalNet === '' || state.totalVat === '' || state.totalGross === '' || _.some(state.actualLines, el => el.quantity < 0 || el.quantity === "" || el.cost < 0 || el.cost === "" || isNaN(el.cost) || el.totalCost < 0 || el.totalCost === "" || isNaN(el.totalCost)) || state.totalGross === "0.00";

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <Box ml={-4} mr={-4} mt={-3} height={`calc(${window.innerHeight}px - 64px)`} style={{backgroundColor: '#fafafa'}}>
                <Grid container style={{height: '100%'}}>
                    <Grid item xs={12} lg={4} style={{background: '#ddd', height: '100%', borderRight: '1px solid #ddd'}}>
                        <Paper style={{position: 'relative', borderRadius: 0, height: '100%', overflowY: 'auto', backgroundColor: '#fafafa'}}>
                            {_.some(po.products, el => parseInt(el.quantityInvoiced) > 0) && (
                                <Box style={{borderBottom: '1px solid #ddd'}}>
                                    <Alert severity="warning">
                                        This purchase order has already been partially invoiced
                                    </Alert>
                                </Box>
                            )}
                            <Box p={3} pt={3}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" align='center' className="fw-400" paragraph>
                                            Invoice Details
                                        </Typography>  
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={3} pb={1.5} className="content-light-white">
                                            <Grid container spacing={3} alignItems='center'>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField
                                                        label="Invoice Reference *"
                                                        value={state.ref}
                                                        onChange={e => handleChange('ref', e?.target?.value)}
                                                        margin="none"
                                                        variant="standard"
                                                        fullWidth
                                                        inputProps={{
                                                            maxLength: 8
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment>
                                                                    #
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        disabled={checked}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <FormControl fullWidth margin="none" className="pb-2">
                                                        <DatePicker
                                                            name="date"
                                                            type="date"
                                                            label="Invoice Date *"
                                                            onChange={date => handleChange('date', moment(date).format("YYYY-MM-DD"))}
                                                            value={state.date}
                                                            autoOk={true}
                                                            disabled={checked}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} className="pt-0 pb-3">
                                                    {(!checked && (
                                                        <Button
                                                            onClick={handleCheckDetails}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={state.ref === '' || state.date === null}
                                                            fullWidth
                                                        >
                                                            <FAIcon icon="check" type="solid" size={15} button />
                                                            Continue
                                                        </Button>
                                                    )) || (
                                                        <Button
                                                            onClick={() => setChecked(false)}
                                                            variant="text"
                                                            color="primary"
                                                            fullWidth
                                                        >
                                                            <FAIcon icon="redo" type="solid" size={15} button />
                                                            Amend Details
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    {checked && (
                                        <>
                                            <Grid item xs>
                                                <Box pl={20}>
                                                    <Typography variant="h6" className="fw-400 mt-2" align='center' paragraph>
                                                        Invoice Lines
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => handleSetAllZero()}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <FAIcon icon="exclamation-triangle" type="solid" size={15} button />
                                                    Zero Quantities
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                {checked && (
                                    <>
                                        <Grid container spacing={3}>
                                            {_.map(state.lines, (pop, idx) => {
                                                let stillToInvoice = (pop.originalQty - pop.quantityInvoiced);
                                                if(stillToInvoice < 0) {
                                                    stillToInvoice = 0;
                                                }
                                                return ( 
                                                    <Grid item xs={12} key={idx}>
                                                        <Grid container spacing={1} className="content-light-white p-2">
                                                            <Grid item xs={12}>
                                                                <Typography variant="body1">
                                                                    <span className="fw-400">Line {idx + 1} - {pop.productCode}</span>
                                                                </Typography>
                                                                <Typography variant="caption" className="fw-400" component="div">
                                                                    {pop.productName}
                                                                </Typography>
                                                                <Typography variant="caption" component="div" paragraph>
                                                                    Ordered: {pop.originalQty} | Delivered: {pop.quantityDelivered} | Invoiced: {pop.quantityInvoiced}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                <Grid container spacing={1} key={idx} alignItems='center'>
                                                                    <Grid item xs={12} className="p-0">
                                                                        <Typography variant="caption" align='center'>
                                                                            Still To Invoice
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TextField
                                                                            label="Quantity"
                                                                            value={stillToInvoice}
                                                                            margin="none"
                                                                            variant="standard"
                                                                            fullWidth
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item><Typography color="textSecondary">x</Typography></Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            label="Unit Price"
                                                                            value={pop.cost}
                                                                            margin="none"
                                                                            variant="standard"
                                                                            fullWidth
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item><Typography color="textSecondary">=</Typography></Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            label="Total Price"
                                                                            value={(parseFloat(stillToInvoice) * pop.cost).toFixed(2)}
                                                                            margin="none"
                                                                            variant="standard"
                                                                            fullWidth
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                <Grid container spacing={1} key={idx} alignItems='center'>
                                                                    <Grid item xs={12} className="p-0">
                                                                        <Typography variant="caption" align='center'>
                                                                            Invoice Now
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TextField
                                                                            label="Quantity"
                                                                            value={state.actualLines[idx].quantity}
                                                                            onBlur={() => handleLineBlur(idx, 'cost')}
                                                                            onChange={e => handleLineChange(idx, 'quantity', e?.target?.value)}
                                                                            margin="none"
                                                                            variant="standard"
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>x</Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            label="Unit Price"
                                                                            value={state.actualLines[idx].cost}
                                                                            onChange={e => handleLineChange(idx, 'cost', e?.target?.value)}
                                                                            onBlur={() => handleLineBlur(idx, 'cost')}
                                                                            margin="none"
                                                                            variant="standard"
                                                                            fullWidth
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>=</Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            label="Total Price"
                                                                            value={state.actualLines[idx].totalCost}
                                                                            onChange={e => handleLineChange(idx, 'totalCost', e?.target?.value)}
                                                                            onBlur={() => handleLineBlur(idx, 'totalCost')}
                                                                            margin="none"
                                                                            variant="standard"
                                                                            fullWidth
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {/* {(parseFloat(pop.totalCost) !== parseFloat(state.actualLines[idx].totalCost) || parseInt(pop.quantityDelivered) !== parseInt(state.actualLines[idx].quantity)) && (
                                                                <>
                                                                    {_.isEmpty(pod.invoice) && pop.quantity !== pop.quantityDespatch && (
                                                                        <Grid item xs={12} sm={6} className="pt-0">
                                                                            <Box mt={-1}>
                                                                                <Alert severity="error" variant="outlined" className="p-0">
                                                                                    <Typography variant="body2">
                                                                                        <strong>Qty To Despatch:</strong> {pop.quantityDespatch}
                                                                                    </Typography>
                                                                                </Alert>
                                                                            </Box>
                                                                        </Grid>
                                                                    )}
                                                                    {!_.isEmpty(pod.invoices) && pop.quantityDespatch !== pop.quantityInvoiced && (
                                                                        <Grid item xs={12} sm={6} className="pt-0">
                                                                            <Box mt={-1}>
                                                                                <Alert severity="error" variant="outlined" className="p-0">
                                                                                    <Typography variant="body2">
                                                                                        <strong>Total Delivered:</strong> {pop.quantityDelivered}<br />
                                                                                        <strong>Previously Invoiced:</strong> {pop.quantityInvoiced}
                                                                                    </Typography>
                                                                                </Alert>
                                                                            </Box>
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item xs={12} sm={6} className="pt-0" align="right">
                                                                        <FormControlLabel
                                                                            className="pr-2"
                                                                            control={<Radio className="p-0 mr-1" size="small" checked={state.actualLines[idx].disputed === 0} color="primary" onChange={() => handleLineChange(idx, 'disputed', 0)} />}
                                                                            label={<Typography variant="caption" className="fw-400">Accept</Typography>}
                                                                        />
                                                                        <FormControlLabel
                                                                            control={<Radio className="p-0 mr-1" size="small" checked={state.actualLines[idx].disputed === 1} color="primary" onChange={() =>handleLineChange(idx, 'disputed', 1)} />}
                                                                            label={<Typography variant="caption" className="fw-400">Dispute</Typography>}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )} */}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" className="fw-400 mt-3" align='center' paragraph>
                                                Totals
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={3} className="content-light-white">
                                                <Grid container spacing={2} className="pt-1">
                                                    <Grid item xs={12} lg={6} className="p-0" align='center'>
                                                        <Typography variant="caption" align='center'>
                                                            Still To Invoice
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} className="p-0" align='center'>
                                                        <Typography variant="caption" align='center'>
                                                            Invoice Now
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <TextField
                                                            label="Total Buy Price *"
                                                            value={state.exTotalCost}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            disabled
                                                        />
                                                        <TextField
                                                            label="Delivery *"
                                                            value={state.exTotalCarriage}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <TextField
                                                            label="Total Buy Price *"
                                                            value={state.totalCost}
                                                            onChange={e => handleChange('totalCost', e?.target?.value ?? '')}
                                                            onBlur={() => handleBlur('totalCost')}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            type='number'
                                                            disabled
                                                        />
                                                        <TextField
                                                            label="Delivery *"
                                                            value={state.totalCarriage}
                                                            onChange={e => handleChange('totalCarriage', e?.target?.value ?? '')}
                                                            onBlur={() => handleBlur('totalCarriage')}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            type='number'
                                                        />
                                                    </Grid> 
                                                    {/* {(_.isEmpty(pod.invoice) || (!_.isEmpty(pod.invoice) && parseFloat(state.totalCarriage) !== 0)) && parseFloat(pod.pod_total_carriage) !== parseFloat(state.totalCarriage) && (
                                                        <Grid item xs={12} className="pt-0 pb-2" align="right">
                                                            <FormControlLabel
                                                                className="pr-2"
                                                                control={<Radio className="p-0 mr-1" size="small" checked={state.disputeCarr === 0} color="primary" onChange={() =>  handleChange('disputeCarr', 0)} />}
                                                                label={<Typography variant="caption" className="fw-400">Accept Cost</Typography>}
                                                            />
                                                            <FormControlLabel
                                                                control={<Radio className="p-0 mr-1" size="small" checked={state.disputeCarr === 1} color="primary" onChange={() => handleChange('disputeCarr', 1)} />}
                                                                label={<Typography variant="caption" className="fw-400">Dispute</Typography>}
                                                            />
                                                        </Grid>
                                                    )} */}
                                                    <Grid item xs={12} lg={6} className={parseFloat(state.exTotalCarriage) === parseFloat(state.totalCarriage) ? "pt-0" : ""}>
                                                        <TextField
                                                            label="Net Total *"
                                                            value={state.exTotalNet}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            disabled
                                                        />
                                                        <TextField
                                                            label="VAT *"
                                                            value={state.exTotalVat}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            disabled
                                                        />
                                                        <TextField
                                                            className="mt-1"
                                                            label="Gross Total *"
                                                            value={state.exTotalGross}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} className={parseFloat(state.exTotalCarriage) === parseFloat(state.totalCarriage) ? "pt-0" : ""}>
                                                        <TextField
                                                            label="Net Total *"
                                                            value={state.totalNet}
                                                            onChange={e => handleChange('totalNet', e?.target?.value ?? '')}
                                                            onBlur={() => handleBlur('totalNet')}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            type='number'
                                                            disabled
                                                        />
                                                        <TextField
                                                            label="VAT *"
                                                            value={state.totalVat}
                                                            onChange={e => handleChange('totalVat', e?.target?.value ?? '')}
                                                            onBlur={() => handleBlur('totalVat')}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            type='number'
                                                        />
                                                        <TextField
                                                            className="mt-1"
                                                            label="Gross Total *"
                                                            value={state.totalGross}
                                                            onChange={e => handleChange('totalGross', e?.target?.value ?? '')}
                                                            onBlur={() => handleBlur('totalGross')}
                                                            margin="none"
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                            }}
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            type='number'
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        {(state.disputeCarr === 1 || _.some(state.actualLines, el => el.disputed === 1)) && (
                                            <Box pt={3}>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" align='center' className="fw-400" paragraph>
                                                            Dispute Memo
                                                        </Typography>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box p={3} pb={3} className="content-light-white">
                                                            <Box pb={3}>
                                                                <Alert severity="warning">
                                                                    <strong>Important - </strong>
                                                                    This will be posted into the supplier's memo by RED, only include the reason for the dispute rather than the date, your name and total credit amount.
                                                                </Alert>
                                                            </Box>
                                                            <TextField
                                                                label="Reason"
                                                                value={state.disputeReason}
                                                                placeholder="Price was incorrect on price list"
                                                                onChange={e => handleChange('disputeReason', e?.target?.value)}
                                                                margin="none"
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                fullWidth
                                                                rows={2}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                        <div className="buttonRow">
                                            <Button
                                                color="primaryf"
                                                onClick={() => deployConfirmation(`Are you sure you want to cancel?`, closeDialog)}
                                                variant="text"
                                            >
                                                <FAIcon icon="times" button />
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn btn-success"
                                                disabled={disabled}
                                                onClick={() => deployConfirmation(`Are you sure you want to finalise invoicing for this delivery?`, submit)}
                                                variant="contained"
                                            >
                                                <FAIcon icon="check" disabled={disabled} buttonPrimary />
                                                Confirm
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={8} style={{height: '100%'}}>
                        {(!_.isEmpty(po.notes)) && (
                            <Grid item xs={12}>
                                <ExpansionPanel elevation={0} style={{borderBottom: '1px solid #ddd'}}>
                                    <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                                        <Typography variant="h6" component="div">
                                            <FAIcon icon="exclamation-circle" className="textError mr-1" size={20} button />
                                            Purchase Order Notes ({_.size(po.notes)})
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <ViewNotes noPriorityStyles inline hideDeleteButton notes={po.notes} />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        )}
                        <Paper style={{width: '100%', background: '#F5F5F5', height: 47, borderBottom: '1px solid #ddd', borderRadius: 0}} elevation={0}>
                            <Box p={0.5}>
                                <Grid container spacing={1} justify='center' alignItems='center'>
                                    <Grid item xs>
                                        <Typography variant="body2" className="ml-2">
                                            {`Pages: ${pdf.numPages ?? 1}`}
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item style={{width: 125}} />
                                    <Grid item xs>
                                        <Grid container spacing={1} justify='center' alignItems='center'>
                                            <Grid item>
                                                <IconButton
                                                    disabled={!pdf.numPages || pdf.pageNumber <= 1}
                                                    onClick={previousPage}
                                                >
                                                    <FAIcon type="light" icon="chevron-left" size="small" disabled={!pdf.numPages || pdf.pageNumber <= 1} button noMargin />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    {`Page ${pdf.pageNumber} of ${pdf.numPages ?? 1}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    disabled={!pdf.numPages || pdf.pageNumber >= pdf.numPages}
                                                    onClick={nextPage}
                                                >
                                                    <FAIcon type="light" icon="chevron-right" size="small" disabled={!pdf.numPages || pdf.pageNumber >= pdf.numPages} button noMargin />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>  */}
                                    <Grid item>
                                        <Grid container>
                                            <Grid item>
                                                <IconButton
                                                    onClick={() => setPdf(pdf => ({ ...pdf, scale: isTablet() ? 0.75 : 1.50}))}
                                                >
                                                    <FAIcon type="light" icon="search" size="small" button noMargin />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    onClick={() => handlePdfScale(false)}
                                                    disabled={pdf.scale === "0.75"}
                                                >
                                                    <FAIcon type="light" icon="search-minus" size="small" button noMargin />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    onClick={() => handlePdfScale(true)}
                                                    disabled={pdf.scale === "2.00"}
                                                >
                                                    <FAIcon type="light" icon="search-plus" size="small" button noMargin />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        <Grid container justify="center" style={{height: `calc(100% - 47px ${!_.isEmpty(po.notes) ? ` - 64px` : ``})`, background: '#777', position: 'relative', textAlign: 'center', overflowX: 'hidden', overflowY: 'auto'}}>
                            <Grid item>
                                <Box p={3}>
                                    <Document
                                        file={state.existingFile ? `${CLOUDFRONT_URL}${_.find(po.documents, el => el.doc_id === state.existingFile)?.doc_filekey}` : state.file}
                                        onLoadSuccess={handlePdfLoad}
                                        loading={(
                                            <>
                                            </>
                                        )}
                                    >
                                        {Array.apply(null, Array(pdf.numPages))
                                                .map((x, i)=>i+1)
                                                .map(page => (
                                                    <Page 
                                                        pageNumber={page} 
                                                        loading={(
                                                            <LoadingCircle />
                                                        )}
                                                        renderAnnotationLayer={false}
                                                        scale={pdf.scale}
                                                        style={{
                                                            width: '100%', 
                                                            position: 'absolute',
                                                        }}
                                                    />
                                                )
                                        )}
                                        {/* <Page 
                                            pageNumber={pdf.pageNumber} 
                                            loading={(
                                                <LoadingCircle />
                                            )}
                                            scale={pdf.scale}
                                            style={{
                                                width: '100%', 
                                                position: 'absolute'
                                            }}
                                        >
                                        </Page> */}
                                    </Document>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={duplicateDialog}>
                    <DialogTitle>
                        Duplicate Invoice Detected
                    </DialogTitle>
                    {duplicateDetails && (
                        <DialogContent dividers>
                            <Box mb={3} borderRadius={4} py={3} p={2} align='center' className="bg-error">
                                <Typography gutterBottom className="textLight fw-400">
                                    An invoice was processed previously that matches the details of this invoice.<br />
                                    Please check that this invoice has not been posted to Sage before continuing!
                                </Typography>
                            </Box>
                            <Typography paragraph className="fw-400">
                                Previous Invoice Details -
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <strong>Supplier:</strong> {duplicateDetails.supp}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <strong>PO Reference:</strong> {duplicateDetails.po}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <strong>Invoice Reference:</strong> {duplicateDetails.ref}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <strong>Invoice Date:</strong> {duplicateDetails.date}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <strong>Net Total:</strong> {currencyFormat.format(duplicateDetails.net)}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <strong>Gross Total:</strong> {currencyFormat.format(duplicateDetails.gross)}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <strong>Processed:</strong> {duplicateDetails.posted}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <strong>Processed By:</strong> {duplicateDetails.user}
                            </Typography>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button color="primary" onClick={() => handleCloseDuplicateDialog(false)}>
                            <FAIcon icon="times" button />
                            Cancel
                        </Button>
                        <Button autoFocus color="primary" onClick={() => handleCloseDuplicateDialog(true)}>
                            <FAIcon icon="exclamation-circle" button />
                            Continue Anyway
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    )

}
                

const PurchaseOrderMultiInvoice = ({closeDialog, content, deployConfirmation, deployDialog, handleSubmit, id, po, reference}) => {
    
    const [state, setState] = useState({
        file: null,
        existingFile: null,
        title: '',
        category: 10
    })
    
    const handleChange = (fieldName, v) => {
        setState(state => ({
            ...state,
            [fieldName]: v?.value ?? v ?? ''
        }))
    }

    const handleFileChange = (drop, name, file) => {

        let fn = file.name.split("."),
            title = '';

        for(let i = 0; i < (fn.length - 1); i++) {
            title += fn[i]
        }

        setState(state => ({
            ...state,
            file,
            title
        }))
    }

    const submit = () => {
        deployDialog(
            <PurchaseOrderMultiInvoiceComparison
                category={state.category}
                existingFile={state.existingFile}
                file={state.file}
                title={state.title}
                closeDialog={closeDialog}
                content={content}
                deployConfirmation={deployConfirmation}
                deployDialog={deployDialog}
                handleSubmit={handleSubmit}
                id={id}
                po={po}
                reference={reference}
            />
        , false, `Purchase Order #${po.po_reference} - Invoicing`, "standard", "xl", true)
    }

    return (
        <form noValidate autoComplete="off">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" paragraph className="pt-3">
                        Select Invoice
                    </Typography>
                    <AutoCompleteSelect
                        options={[
                            {
                                label: "Supplier Invoices - Requires Action",
                                options: _.map(_.filter(po.documents, _el => _el.doc_dc_id === 10 && _el.doc_ra === 1), el => (
                                    _.assign({
                                        label: el.doc_title,
                                        value: el.doc_id
                                    })
                                ))
                            },
                            {
                                label: "Supplier Invoices - All",
                                options: _.map(_.filter(po.documents, _el => _el.doc_dc_id === 10), el => (
                                    _.assign({
                                        label: el.doc_title,
                                        value: el.doc_id
                                    })
                                ))
                            }
                        ]}
                        disabled={_.isEmpty(_.filter(po.documents, _el => _el.doc_dc_id === 10)) || state.file}
                        name="existingFile"
                        label={'Existing file *'}
                        value={state.existingFile}
                        onChange={v => handleChange('existingFile', v?.value ?? null)}
                        margin="none"
                        noDefaultSort
                        fullWidth
                    />
                    <Box pt={4} pb={4}>
                        <Divider />
                    </Box>
                    <Typography variant="h6">
                        Upload Invoice
                    </Typography>
                    <Box pb={1}>
                        <DragFileInput
                            id="file"
                            name="file"
                            disabled={state.existingFile}
                            label={`Upload File *`}
                            file={state.file}
                            primary="pdf"
                            icon="file-pdf"
                            onChange={handleFileChange}
                            emptyText='No file selected'
                        />
                    </Box>
                    <TextField
                        id="title"
                        name="title"
                        label="Title *"
                        disabled={state.existingFile}
                        value={state.title}
                        onChange={v => handleChange('title', v?.target?.value)}
                        margin="none"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} className="buttonRow" align="right">
                    <Button onClick={closeDialog} variant="text" color="primary"><FAIcon icon="times" button />Cancel</Button>
                    <Button onClick={() => deployConfirmation(`Are you sure you want to process this invoice?`, submit)} disabled={(!state.existingFile && (state.title === '' || state.category === ''  || state.file === null)) ? true : false} variant="text" color="primary"><FAIcon icon="check" button />Confirm</Button>
                </Grid>   
            </Grid>
        </form>
    )

}

export default PurchaseOrderMultiInvoice;