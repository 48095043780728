import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import MeetingInviteForm from 'Components/Meetings/MeetingInviteForm/MeetingInviteForm';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleChange, handleCheckedChange, handleSelectChange } from 'Functions/FormFunctions';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = {
    formData: {
        meetingName: '',
        inviteType: null,
        staffContact: null,
        supplier: null,
        customer: null,
        contact: null,
        sendSmsInvite: false,
        smsName: '',
        smsNumber: '',
        smsBody: 'Please click the following link to join your Advanced Access Meeting:',
        sendEmailInvite: false,
        emailName: '',
        emailAddress: '',
        emailBody: 'Please click the following link to join your Advanced Access Meeting:'
    },
    formErrors: [],
    isLoading: false
}

class NewMeeting extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    handleSubmit = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.post('/meetings', this.state.formData)
            .then(result => {
                if(result?.data) {
                    if(result.data.errors){           
                        this.setState({
                            formErrors: formatValidationErrors(result.data.errors),
                            isLoading: false
                        });
                    } else {
                        this.props.deploySnackBar("success", "Your meeting has been successfully created");
                        this.props.callback();
                    }
                }
            });
        })
    }

    updateFormData = formData => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...formData
            }
        })
    }

    render() {
        const { closeDialog, deployConfirmation } = this.props;
        const { formData, formErrors, isLoading } = this.state;
        return (
            <Box mt={1}>
                {(isLoading && (
                    <LoadingCircle />
                )) || (
                    <form noValidate autoComplete="off">
                        <TextField
                            id="meetingName"
                            name="meetingName"
                            label="Meeting Name *"
                            value={formData.meetingName}
                            error={formErrors && formErrors['meetingName']}
                            helperText={formErrors && formErrors['meetingName']}
                            onChange={this.handleChange}
                            margin="none"
                            fullWidth
                            autoFocus
                        />
                        <MeetingInviteForm
                            allowNone={true}
                            formData={formData}
                            formErrors={formErrors}
                            handleChange={this.handleChange}
                            handleCheckedChange={this.handleCheckedChange}
                            handleSelectChange={this.handleSelectChange}
                            updateFormData={this.updateFormData}
                        />
                        <div className='buttonRow'>
                            <Button 
                                onClick={closeDialog} 
                                variant="text" 
                            >
                                <FAIcon icon="times" size={15} button />
                                Cancel                        
                            </Button>
                            <Button 
                                color="primary"
                                disabled={formData.meetingName === '' || formData.inviteType === null}
                                onClick={() => deployConfirmation("Are you sure you want to create a new meeting?", this.handleSubmit)}
                                variant="text"
                            >
                                <FAIcon icon="check" size={15} button />
                                Create    
                            </Button>
                        </div>
                    </form>
                )}
            </Box>
        )
    }
}

export default NewMeeting;