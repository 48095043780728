import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import { currencyFormat } from 'Functions/MiscFunctions';
import { getFormDataHook } from 'Functions/FormFunctions';

const ActionCreditReport = ({callback, closeDialog, customer, deployConfirmation, deploySnackBar, id, request}) => {

    const   staff                           = useSelector(state => state.notifications.status),
            [comments, setComments]         = useState(''),
            [mgtComments, setMgtComments]   = useState(''),
            [status, setStatus]             = useState(''),
            [quote, setQuote]               = useState({decision: '', prepayValue: '', prepayPercent: ''}),
            [creditReport, setCreditReport] = useState(null);

    const handlePrePayValue = (fieldName, value) => {

        let prepayPercent   = '',
            prepayValue     = '';

        if(parseFloat(value) > 0) {
            if(fieldName === 'prepayPercent') {
                if(parseFloat(value) <= 100) {
                    prepayPercent = value
                    prepayValue = value !== '' ? ((parseFloat(request.quote.quote_total_gross) / 100) * parseFloat(prepayPercent)).toFixed(2) : ''
                }
            } else {
                if(parseFloat(value) <= parseFloat(request.quote.quote_total_gross)) {
                    prepayValue = value
                    prepayPercent = value !== '' ? ((parseFloat(prepayValue) / parseFloat(request.quote.quote_total_gross)) * 100).toFixed(2) : ''
                }
            }
        }

        setQuote({
            ...quote,
            prepayPercent,
            prepayValue
        })
    }

    const handlePrePayFloat = () => {

        let prepayValue     = quote.prepayValue !== '' ? parseFloat(quote.prepayValue).toFixed(2) : '',
            prepayPercent   = prepayValue !== '' ? ((parseFloat(prepayValue) / parseFloat(request.quote.quote_total_gross)) * 100).toFixed(2) : ''
        
        setQuote({
            ...quote,
            prepayPercent,
            prepayValue
        })

    }

    const handleSubmit = () => {
        if(request) {
            API.post(`/accounts/creditReports/requests/${request.rc_id}`, getFormDataHook({status, comments, mgtComments, quote, creditReport}))
            .then(res => {
                if(res?.data) {
                    deploySnackBar("success", "The credit check has been successfully completed");
                    callback();
                    closeDialog();
                }
            })
        } else {
            API.post(`/accounts/creditReports`, getFormDataHook({id, status, comments, creditReport}))
            .then(res => {
                if(res?.data) {
                    deploySnackBar("success", "The credit check has been successfully attached to the customer's record");
                    callback();
                    closeDialog();
                }
            })
        }
    }

    useEffect(() => {
        if(quote.decision === "With Terms") {
            setComments(`This order will be subject to an upfront payment of ${currencyFormat.format(quote.prepayValue)} (${quote.prepayPercent}%)`)
        } else {
            setComments('')
        }
    }, [quote.decision, quote.prepayValue, quote.prepayPercent])

    return (
        <Box mt={1.5}>  
            {request && (
                <>
                    <Box pb={3}>
                        <Typography variant="h6" gutterBottom>
                            Requested by {staff?.[request.rc_staff_id]?.name ?? 'Unknown'}
                        </Typography>
                        <Typography variant="body2" component="span" className="mt-2" gutterBottom>
                            <strong>Reason</strong><br />
                            {request?.reason?.crr_reason}
                        </Typography>
                        {!_.isEmpty(request.rc_request_comments) && (
                            <>
                                <Typography variant="body2" className="fw-400 mt-2" gutterBottom>
                                    Comments
                                </Typography>
                                <Typography variant="body2">
                                    {_.map(request.rc_request_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)}
                                </Typography>
                            </>
                        )}
                    </Box>
                    {request.quote && (
                        <Box className={quote.decision === '' ? 'content-light-grey' : (quote.decision === 'Yes' ? 'content-light-success' : quote.decision === 'No' ? 'content-light-error' : 'content-light-warning')} p={3} pt={2} pb={2} mb={3}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <Typography variant="body2" className="fw-400">
                                        Quote #{request.quote?.quote_reference}/{request.quote.quote_revision} - Gross Total: {currencyFormat.format(request.quote.quote_total_gross)}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="View Quote (In New Tab)">
                                        <IconButton
                                            onClick={() => window.open(`/quotes/${request.quote.quote_id}`)}
                                        >
                                            <FAIcon icon="external-link" size={12.5} noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Box>
                                <Typography variant="caption">
                                    If this quote was ordered today, would it be accepted on account?
                                </Typography>
                                <Grid container spacing={1} className="mt-1">
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Radio color="primary" checked={quote.decision === 'Yes'} onChange={() => setQuote({...quote, decision: 'Yes'})} />}
                                            label={<Typography variant="caption" className="fw-400">Yes</Typography>}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Radio color="primary" checked={quote.decision === 'With Terms'} onChange={() => setQuote({...quote, decision: 'With Terms'})} />}
                                            label={<Typography variant="caption" className="fw-400">With Terms</Typography>}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Radio color="primary" checked={quote.decision === 'No'} onChange={() => setQuote({...quote, decision: 'No'})} />}
                                            label={<Typography variant="caption" className="fw-400">No</Typography>}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            {quote.decision === 'With Terms' && (
                                <Box mt={2}>
                                    <Divider />
                                    <Grid container spacing={1} className="mt-3">
                                        <Grid item xs={6}>
                                            <TextField 
                                                label="Upfront Payment Percentage"
                                                margin="none" 
                                                autoComplete="off"
                                                value={quote.prepayPercent}
                                                onChange={e => handlePrePayValue('prepayPercent', e?.target?.value ?? '')}
                                                onBlur={handlePrePayFloat}
                                                inputProps={{
                                                    min: 0,
                                                    max: 100,
                                                    step: 1
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"><FAIcon type="light" icon="percent" size="small" /></InputAdornment>
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                type="number"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField 
                                                label="Upfront Payment Value"
                                                margin="none" 
                                                autoComplete="off"
                                                value={quote.prepayValue}
                                                onChange={e => handlePrePayValue('prepayValue', e?.target?.value ?? '')}
                                                onBlur={handlePrePayFloat}
                                                inputProps={{
                                                    min: 0,
                                                    max: request.quote.quote_total_gross,
                                                    step: 0.01
                                                }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><FAIcon type="light" icon="pound-sign" size="small" /></InputAdornment>
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                type="number"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Box>
                    )}
                </>
            )}
            <Box mb={3}>
                <Typography variant="h6">
                    Credit Rating
                </Typography>
                <Typography variant="body2" paragraph>
                    Please rate the customer's credit status
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <ToggleButton 
                            onClick={() => setStatus('Good')}
                            selected={status === 'Good'}
                            style={{width: '100%', height: 125}} 
                            value="1"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <FAIcon className="textSuccess" type="thin" icon="check-circle" size={25} button />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        <Typography className="fw-400" variant="body2">
                                            Credit Good
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ToggleButton>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ToggleButton
                            onClick={() => setStatus('Satisfactory')}
                            selected={status === 'Satisfactory'}
                            style={{width: '100%', height: 125}} 
                            value="0"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <FAIcon className="textWarning" type="thin" icon="check-circle" size={25} button />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        <Typography className="fw-400" variant="body2">
                                            Credit Satisfactory
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ToggleButton>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ToggleButton
                            onClick={() => setStatus('Poor')}
                            selected={status === 'Poor'}
                            style={{width: '100%', height: 125}} 
                            value="0"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <FAIcon className="textError" type="thin" icon="times-circle" size={25} button />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        <Typography className="fw-400" variant="body2">
                                            Credit Poor
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ToggleButton>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ToggleButton
                            onClick={() => setStatus('No Data Available')}
                            selected={status === 'No Data Available'}
                            style={{width: '100%', height: 125}} 
                            value="0"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <FAIcon className="textDefault" type="thin" icon="question-circle" size={25} button />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        <Typography className="fw-400" variant="body2">
                                            No Data Available
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ToggleButton>
                    </Grid>
                </Grid>
                {status === 'Poor' && (
                    <Box mt={3}>
                        <Alert severity="error">
                            This customer will {customer.cust_monitored === 1 ? 'continue to be' : 'be automatically'} monitored and all orders will require approval
                        </Alert>
                    </Box>
                )}
            </Box>
            <Typography variant="h6">
                Comments
            </Typography>
            <Typography variant="body2" paragraph>
                Here you can add any comments about the credit rating / status of the customer (optional)
            </Typography>
            {request?.quote && (
                <Box mb={2}>
                    <Textarea
                        label="Management Comments (visible to management only)"
                        onChange={e => setMgtComments(e?.target?.value ?? '')}
                        rows={1}
                        value={mgtComments}
                        variant="outlined"
                    />
                </Box>
            )}
            <Box pt={1}>
                <Textarea
                    label="Public Comments (visible to all)"
                    onChange={e => setComments(e?.target?.value ?? '')}
                    rows={1}
                    value={comments}
                    variant="outlined"
                />
            </Box>
            <Box pt={3}>
                <Typography variant="h6">
                    Credit Report
                </Typography>
                <Typography variant="body2">
                    You can upload a copy of the credit report below (optional)
                </Typography>
            </Box>
            <DragFileInput
                name="creditReport"
                file={creditReport}
                onChange={(drop, name, file) => setCreditReport(file)}
                placeholder="Choose a credit report or drag it here"
            />
            <div className="buttonRow">
                <Button
                    color="primary"
                    onClick={() => closeDialog()}
                    variant="text"
                >
                    <FAIcon icon="times" button />
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={status === '' || (request?.quote && quote?.decision === '') || (request?.quote && quote?.decision === 'With Terms' && (quote?.prepayPercent === '' && quote?.prepayValue === ''))}
                    onClick={() => deployConfirmation(`Are you sure you want to ${request ? 'action this credit check?' : 'create a new credit check for this customer?'} `, handleSubmit)}
                    variant="text"
                >
                    <FAIcon icon="check" button />
                    Confirm
                </Button>
            </div>
        </Box>
    )
}

export default ActionCreditReport;