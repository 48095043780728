import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { 
    Avatar, 
    Box, 
    Card, 
    Grid, 
    IconButton, 
    Paper, 
    TextField, 
    Tooltip, 
    Typography 
} from '@material-ui/core';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import PurchaseOrderDeliverySummary from 'Components/Purchases/Misc/PurchaseOrderDeliverySummary';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import logo from 'Assets/Images/no-img.jpg';
import NotificationDialog from 'Components/Common/Dialogs/NotificationDialog';
import PrintJobDialog from 'Components/Common/Dialogs/PrintJobDialog';
import Textarea from 'Components/Common/Inputs/Textarea';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { CLOUDFRONT_URL } from 'Constants';
import { isNumeric } from 'Functions/MiscFunctions';

const CheckDialog = ({check, closeDialog, deployConfirmation, handleCheckIssue}) => {

    const [qtyTotal, setQtyTotal] = useState('');
    const [qtyDamaged, setQtyDamaged] = useState('');
    const [qtyMissing, setQtyMissing] = useState('');
    const [qtyPristine, setQtyPristine] = useState('');

    const btnDisabled = qtyTotal === "" || qtyDamaged === "";

    const handleQtyTotal = e => {

        if(_.isEmpty(e?.target?.value) || e?.target?.value < 0 || isNaN(e?.target?.value) || e?.target?.value > check?.qtyExpected) {
            setQtyTotal('');
            setQtyDamaged(0);
            setQtyMissing(0);
            setQtyPristine(0);
            return;
        }
        
        let total       =   parseInt(e?.target?.value ?? 0),
            expected    =   parseInt(check.qtyExpected);
            
        setQtyTotal(total);
        setQtyMissing((expected - total));
        setQtyPristine((total - 0));
        setQtyDamaged(0);
    }

    const handleQtyDamaged = e => {

        if(_.isEmpty(e?.target?.value)) {
            setQtyDamaged('');
            return;
        }

        let damaged     = parseInt(e?.target?.value ?? 0);

        setQtyDamaged(damaged < 0 || damaged > qtyTotal || !isNumeric(damaged) ? '' : damaged)
        setQtyPristine(damaged < 0 || damaged > qtyTotal || !isNumeric(damaged) ? qtyTotal : (qtyTotal - damaged))

    }
    
    const handleSubmit = () => {
        deployConfirmation(`You have ${qtyTotal} on-hand, ${qtyDamaged} damaged and ${qtyMissing} missing?`, () => {
            handleCheckIssue(check.idx, qtyMissing, qtyDamaged === "" ? 0 : qtyDamaged, qtyTotal);
            closeDialog();
        })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="body1" className="fw-500" style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                    {check?.line?.productName}
                </Typography>
            </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Received *"
                        value={qtyTotal}
                        onChange={handleQtyTotal}
                        margin="none"
                        fullWidth
                        inputProps={{
                            size: 4,
                            pattern: '[0-9]*',
                            inputMode: 'none'
                        }}
                        size="small"
                        autoFocus
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Damaged *"
                        value={qtyDamaged}
                        onChange={handleQtyDamaged}
                        margin="none"
                        fullWidth
                        inputProps={{
                            size: 4,
                            pattern: '[0-9]*',
                            inputMode: 'none'
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label="Pristine"
                        value={qtyPristine}
                        margin="none"
                        fullWidth
                        inputProps={{
                            size: 4,
                            pattern: '[0-9]*',
                            inputMode: 'none'
                        }}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label="Missing"
                        value={qtyMissing}
                        margin="none"
                        fullWidth
                        inputProps={{
                            size: 4,
                            pattern: '[0-9]*',
                            inputMode: 'none'
                        }}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <AppButton
                        disabled={btnDisabled}
                        onClick={handleSubmit}
                        primary
                        color={btnDisabled ? `#bbb` : `#fff`}
                        className={btnDisabled ? `btn btn-disabled` : `btn btn-success`}
                        icon="check"
                        text='Confirm'
                    />
                </Grid>
        </Grid>
    )
}

const initialState = {
    checkStarted: null,
    checkStatus: {},
    checkCompleted: null,
    isLoading: false,
    printing: false,
    notes: ''
}

class PodReceived extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = (restartCheck = false) => {
        const { 
            currentTab,
            deploySnackBar,
            history,
            pod,
            scrollToTop
        } = this.props;

        let { 
            checkStatus 
        } = this.state;

        _.each(pod.products, (podp, idx) => {
            if(parseInt(podp.quantityDelivered) !== parseInt(podp.quantityDespatch)) {
                let expQty = (parseInt(podp.quantityDespatch) - parseInt(podp.quantityDelivered));
                checkStatus = {
                    ...checkStatus,
                    [idx]: {
                        idx,
                        line: podp,
                        checkTime: null,
                        qtyExpected: expQty,
                        qtyMissing: 0,
                        qtyDamaged: 0,
                        qtyTotal: expQty,
                        complete: 1,
                        status: null
                    }
                }
            }
        })

        if(_.isEmpty(checkStatus)) {
            
            deploySnackBar(`error`, `No products are expected for this delivery`);
            history?.push?.('/')
            
        } else {

            this.setState({
                checkStatus,
                checkStarted: restartCheck || currentTab === 2 ? moment().format('YYYY-MM-DD HH:mm:ss') : null
            }, scrollToTop);

        }

    }

    componentDidUpdate = (prevProps) => {
        const { 
            checkStarted
        } = this.state;

        if(checkStarted === null) {
            if(prevProps.currentTab !== 2 && this.props.currentTab === 2) {
                this.setState({
                    checkStarted: moment().format('YYYY-MM-DD HH:mm:ss')
                });
            }
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout)
    }

    handleCheckIssue = (idx, qtyMissing, qtyDamaged, qtyTotal) => {
        let {
            checkStatus
        } = this.state;

        checkStatus = {
            ...checkStatus,
            [idx]: {
                ...checkStatus?.[idx],
                checkTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                qtyMissing,
                qtyDamaged,
                qtyTotal,
                complete: qtyMissing === 0 && qtyDamaged === 0 && checkStatus?.[idx]?.qtyExpected === qtyTotal ? 1 : 0,
                status: qtyMissing === 0 && qtyDamaged === 0 && checkStatus?.[idx]?.qtyExpected === qtyTotal ? 'success' : 'issue',
            }
        }

        this.setState({
            checkStatus
        })
    }
    
    handleCheckOkay = idx => {

        const { 
            playSound 
        } = this.props;

        let { 
            checkStatus 
        } = this.state;

        let qty = parseInt(checkStatus?.[idx]?.qtyExpected);

        checkStatus = {
            ...checkStatus,
            [idx]: {
                ...checkStatus?.[idx],
                checkTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                qtyExpected: qty,
                qtyMissing: 0,
                qtyDamaged: 0,
                qtyTotal: qty,
                complete: 1,
                status: 'success'
            }
        }

        this.setState({
            checkStatus
        }, () => {
            playSound('pickSuccess', this.state.line)
        })

    }

    handleSkipItem = (idx, qtyExpected) => {
        this.handleCheckIssue(idx, qtyExpected, 0, 0)
    }
    
    handleDeployCheckIssue = idx => {
        const {
            checkStatus
        } = this.state;
        
        const {
            closeDialog,
            deployConfirmation,
            deployDialog,
            playSound
        } = this.props;

        playSound('pickError', this.state.line);

        deployDialog(
            <CheckDialog 
                closeDialog={closeDialog} 
                deployConfirmation={deployConfirmation} 
                handleCheckOkay={this.handleCheckOkay}
                handleCheckIssue={this.handleCheckIssue} 
                check={checkStatus?.[idx]}
            />, 
            false, 
            `${checkStatus?.[idx]?.line?.productCode}`, 
            "standard", 
            "xs",
            false,
            true
        );
    }

    handleNotes = notes => {
        this.setState({
            notes
        })
    }

    handleSubmit = () => {

        this.setState({
            checkCompleted: moment().format("YYYY-MM-DD HH:mm:ss")
        }, () => {

            this.setState({
                isLoading: true
            }, () => {

                const { deployDialog, id } = this.props;
                const { notes, checkStarted, checkStatus, checkCompleted } = this.state;

                API.put(`/warehouse/pod/${id}/received`,
                    {
                        notes,
                        checkStarted,
                        checkStatus,
                        checkCompleted
                    }
                )
                .then(res => {
                    if(res?.data) {
                        if(res?.data.printJob) {
                            this.setState({
                                printing: true
                            }, () => {
                                deployDialog(
                                    <PrintJobDialog
                                        printJob={res.data.printJob}
                                        docTitle={res.data.printDoc}
                                        docSubtitle={res.data.printSub}
                                        onClose={() => this.handleCompletedAction(res.data)}
                                    />,
                                    true,
                                    "",
                                    "standard",
                                    "sm",
                                    false,
                                    true
                                );
                            })
                        } else {
                            this.handleCompletedAction(res.data);
                        }
                    }
                })

            })

        })

    }

    handleCompletedAction = res => {

        const {
            deployDialog,
            pod
        } = this.props; 

        const {
            checkStatus
        } = this.state;

        if(res.rma === 1) {
            deployDialog(
                <NotificationDialog
                    title={`Purchase Order Delivery #${pod.pod_reference}/${pod.pod_idx}`}
                    text={`This delivery is no longer required and is due to be returned to the supplier - goods are excluded from stock levels so will not need adjusting out!`}
                    onClick={this.handleCheckFailCompleted}
                    variant="error"
                />,
                false,
                "RMA Required",
                "error",
                "sm",
                false,
                true
            )
        } else {
            if(res.shipHere === 1) {
                if(res.onHold === 1) {
                    deployDialog(
                        <NotificationDialog
                            title={`Sales Order Delivery #${res.orderRef}`}
                            text={`This delivery is pending release before it can be despatched`}
                            onClick={this.handleCheckFailCompleted}
                            variant="error"
                        />,
                        false,
                        "Pending Release",
                        "error",
                        "sm",
                        false,
                        true
                    )
                } else {
                    deployDialog(
                        <NotificationDialog
                            title={`Sales Order Delivery #${res.orderRef}`}
                            text={`This delivery is now Awaiting Check, please place the despatch note and items together ready for checking`}
                            onClick={this.handleCheckComplete}
                        />,
                        false,
                        "Ship Here Order",
                        "success",
                        "sm",
                        false,
                        true
                    )
                }
            } else {
                if(_.some(checkStatus, el => el.complete === 0)) {
                    this.handleCheckFailCompleted()
                } else {
                    this.handleCheckComplete()
                }
            }
        }
    }

    handleCheckComplete = () => {

        const { 
            deploySnackBar, 
            history, 
            processingComplete,
            warehouseMode
        } = this.props;

        processingComplete();
        deploySnackBar(`success`, `You have successfully booked in this delivery`)
        let redirect =  warehouseMode ? `/dashboard` : `/warehouse/incoming/awaiting-delivery`;
        this.timeout = setTimeout(() => history.push(redirect), 750)

    }

    handleCheckFailCompleted = () => {
        
        const { 
            deploySnackBar, 
            history, 
            warehouseMode
        } = this.props;

        deploySnackBar(`warning`, `This delivery has been referred to Sales Admin`)
        if(warehouseMode) {
            history.push(`/dashboard`)
        } else {
            history.push(`/warehouse/outgoing/awaiting-delivery`)
        }

    }

    render = () => {
        const { checkStatus, isLoading, printing, notes } = this.state;
        const { currentTab, deployConfirmation, po, pod } = this.props;
        return (
            (isLoading && (
                (!printing && (
                    <LoadingCircle />
                )) || (
                    <React.Fragment />
                )
            )) || (
                (currentTab === 0 && (
                    <Paper>
                        <Box p={3}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        Notes
                                    </Typography>    
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt={1}>
                                        <ViewNotes inline notes={po.notes} hideDeleteButton />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                )) || (currentTab === 1 && (
                    <PurchaseOrderDeliverySummary pod={pod} po={po} idx={(_.findIndex(po.deliveries, el => el.pod_id === pod.pod_id) + 1)} />
                )) || (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justify="space-between">
                                <Grid item xs={12} align="center">
                                    <Typography variant="h5" component="div" className="fw-500">
                                        Goods Received Check
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {_.map(checkStatus, (p, idx) => (
                            <Grid item xs={12} key={idx}>
                                <Card>
                                    <Box p={2}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <Avatar variant="square" alt={p.line?.productName ?? 'Product Image'} src={p.line?.image ? `${CLOUDFRONT_URL}${p.line?.image}` : (p.line?.product?.default_image?.img_filekey ? `${CLOUDFRONT_URL}${p.line?.product.default_image.img_filekey}` : logo)} style={{width: 50, height: 50}} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="h6" className="fw-400">
                                                    {p.line?.productCode}
                                                </Typography>
                                                <Typography variant="caption" className="fw-300">
                                                    {p.line?.productName}
                                                </Typography>
                                            </Grid>
                                            {p.status !== null && (
                                                <Grid item align='right'>
                                                    <Tooltip title="Modify Check">
                                                        <IconButton
                                                            onClick={() => this.handleDeployCheckIssue(p.idx)}
                                                        >
                                                            <FAIcon icon="pencil" size={15} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                    {(p.status === null && (
                                        <Box className="content-light-grey" p={1}>
                                            <Grid container spacing={3} alignItems='center'>
                                                <Grid item xs={3}>
                                                    <Typography variant="body1" className="fw-400 pl-1">
                                                        QTY {p.qtyExpected}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs={4}>
                                                            <AppButton
                                                                small
                                                                back
                                                                className="btn btn-warning"
                                                                icon="exclamation-circle"
                                                                text="Skip"
                                                                onClick={() => this.handleSkipItem(p.idx, p.qtyExpected)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <AppButton
                                                                small
                                                                back
                                                                className="btn btn-error"
                                                                icon="exclamation-triangle"
                                                                text="Issue"
                                                                onClick={() => this.handleDeployCheckIssue(p.idx)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <AppButton
                                                                small
                                                                back
                                                                className="btn btn-success"
                                                                icon="check"
                                                                text="Confirm"
                                                                onClick={() => this.handleCheckOkay(p.idx)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )) || (
                                        <Alert severity={p.status === 'success' ? `success` : `error`}>
                                            <Typography variant="body2" component="div" className="fw-500">
                                                {/* Check {p.status === 'success' ? `Successful` : `Issue`} */}
                                                Received {p.qtyTotal} / {p.qtyExpected}
                                            </Typography>
                                            {/* <Typography variant="body2" component="div" className="fw-300">
                                            </Typography> */}
                                        </Alert>
                                    )}
                                </Card>
                            </Grid> 
                        ))}
                        <Grid item xs={12}>
                            <Grid item xs={12} align='center'>
                                <Typography variant="body1" align='center' className="fw-400" paragraph>
                                    Notes
                                </Typography>
                                <Paper elevation={0}>
                                    <Textarea
                                        margin="none"
                                        onChange={e => this.handleNotes(e?.target?.value ?? '')}
                                        value={notes}
                                        variant="outlined"
                                        rows={2}
                                    /> 
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <AppButton
                                className="btn btn-success"
                                disabled={_.some(checkStatus, el => el.status === null)}
                                onClick={() => deployConfirmation(`Are you sure you want to book this delivery in?`, this.handleSubmit)}
                                text="Confirm Goods Received"
                                icon="check"
                                primary
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppButton
                                back
                                className="btn btn-error"
                                onClick={() => deployConfirmation(`Are you sure you want to restart this check?`, () => this.loadComponentData(true))}
                                text="Restart"
                                icon="redo"
                            />
                        </Grid>
                    </Grid>
                )
            )
        )
    }
}

export default PodReceived;