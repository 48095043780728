/*
    show outstanding orders on accounts approval
*/

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Prompt } from 'react-router';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Typography from '@material-ui/core/Typography';

import AgedBalances from 'Components/Customers/Misc/AgedBalances';
import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import BooleanTile from 'Components/Common/Tiles/BooleanTile';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import OrderSummary from 'Components/Sales/Misc/OrderSummary';
import SearchOrdersTable from 'Components/Sales/SearchOrders/SearchOrdersTable';
import Textarea from 'Components/Common/Inputs/Textarea';
import Tile from 'Components/Common/Tiles/Tile';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { handleChange, handleFloat } from 'Functions/FormFunctions';
import { currencyFormat, isNumeric } from 'Functions/MiscFunctions';
import SearchTransactionsTable from 'Components/Accounts/SearchTransactions/SearchTransactionsTable';
import { Divider } from '@material-ui/core';

const initialState = {
    delivery: {
        data: {},
        options: []
    },
    formData: {
        comments: '',
        credit: '',
        upfront: '',
        decision: '',
    },
    initialFormData: {
        comments: '',
        credit: '',
        upfront: '',
        decision: '',
    },
    isLoading: true,
    orderPaid: false,
    hasPending: false,
    totalPaid: 0, 
    outstandingBalance: 0,
    authorisedTotal: 0,
    pendingTotal: 0,
    creditTotal: 0,
    prepayTotal: 0
}

class AwaitingApproval extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState
        this.handleChange = handleChange.bind(this)
        this.handleFloat = handleFloat.bind(this)
    }

    componentDidMount = () => {       

        if(_.isEmpty(this.props.order?.approvals_outstanding)) {

            this.props.callback();

        } else {

            API.get('/couriers/carriageOptions', 
                {
                    params: {
                        forQuoteOrderForm: true
                    }
                }
            )
            .then(res => {

                if(res?.data) {

                    let isLoading   =   false,
                        orderData   =   JSON.parse(this.props.order.order_data),
                        delivery    =   {
                                            data: res.data,
                                            options: []
                                        },
                        lists       =   {
                                            delivery:[]
                                        },
                        { 
                            order 
                        }               = this.props,
                        orderPaid = false,
                        hasPending = false,
                        outstandingBalance = parseFloat(order.order_total_gross),
                        totalPaid = 0.00, 
                        authorisedTotal = 0.00,
                        pendingTotal = 0.00,
                        creditTotal = 0.00,
                        prepayTotal = 0.00;
                
                    _.each(order.payments, payment => {
                        if(payment.opp_txn_status !== "Declined") {
                            totalPaid = (parseFloat(totalPaid) + parseFloat(payment.opp_txn_amount)).toFixed(2);
                            outstandingBalance = (parseFloat(outstandingBalance) - parseFloat(payment.opp_txn_amount)).toFixed(2);
                            if(outstandingBalance === "0.00") {
                                orderPaid = true;
                            }
                            if(payment.opp_txn_status === "Authorised") {
                                authorisedTotal = (parseFloat(authorisedTotal) + parseFloat(payment.opp_txn_amount)).toFixed(2);  
                                if(payment.opp_txn_method === "Credit Account") {
                                    creditTotal = (parseFloat(creditTotal) + parseFloat(payment.opp_txn_amount)).toFixed(2);  
                                } else {
                                    prepayTotal = (parseFloat(prepayTotal) + parseFloat(payment.opp_txn_amount)).toFixed(2);  
                                }
                            } else {
                                pendingTotal = (parseFloat(pendingTotal) + parseFloat(payment.opp_txn_amount)).toFixed(2);  
                                hasPending = true;
                            }
                        }
                    })
        
                    outstandingBalance = parseFloat(outstandingBalance).toFixed(2);
                        
                    _.each(res.data, el => {
                        _.each(el.configuration, _el => {
                            delivery.options.push({
                                id: _el.courier_conf_id,
                                service: `${el.courier_name} ${_el.courier_conf_service}${_el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `${_el.courier_conf_custom_charge === "true" ? ' - Varies' : ' - Free'}`}${_el.courier_conf_auth_required === "true" ? ` - Requires Approval` : ''}`,
                                name: `${el.courier_name} ${_el.courier_conf_service}${_el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `${_el.courier_conf_custom_charge === "true" ? ' - Varies' : ' - Free'}`}`,
                                free: _el.courier_conf_charge === "0.00",
                                varies: _el.courier_conf_custom_charge === "true" ? true : false,
                                requiresApproval: _el.courier_conf_auth_required === "true" ? true : false,
                                data: {
                                    ..._el,
                                    courier: el.courier_name
                                }
                            })
                        })
                    })
                    
                    lists = {
                        delivery: [
                            {
                                label: "Free Delivery",
                                options: _.map(_.filter(delivery.options, function(f) { return f.free }), _el => {
                                    return _.assign({
                                        value: _el.id,
                                        label: _el.service
                                    })
                                })
                            },
                            {
                                label: "Standard Delivery",
                                options: _.map(_.filter(delivery.options, function (f) { return !f.free}), _el => {
                                    return _.assign({
                                        value: _el.id,
                                        label: _el.service
                                    })
                                })
                            }
                        ],
                    };
    
                    this.setState({
                        delivery,
                        isLoading,
                        orderData,
                        lists,
                        orderPaid,
                        hasPending,
                        totalPaid, 
                        outstandingBalance,
                        authorisedTotal,
                        pendingTotal,
                        creditTotal,
                        prepayTotal
                    })

                }

            })

        }

    }

    componentDidUpdate = () => {
        if(JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if(!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if(window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    }

    componentWillUnmount = () => {
        if(window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
        if(this.poll) {
            clearInterval(this.poll);
        }
    }
    
    handleDeliveryChange = option => {
        let carriageId = option?.value ?? 0;
        if(carriageId > 0) {
            let carriageData = _.find(this.state.delivery?.options, (el) => el.id === carriageId);
            if(carriageData !== -1 && carriageData !== undefined) {
                this.setState({
                    orderData: {
                        ...this.state.orderData,
                        carriage: {
                            carriageId,
                            carriageData
                        },
                    },
                }, this.handleCalculateTotals)
            }
        }

    }
    
    handleDeliveryCustomChargeFloat = () => {
        let charge = this.state.orderData?.carriage?.carriageData?.data?.courier_conf_charge ?? 10;
        if(!isNumeric(charge) || charge < 0) {
            charge = 0;
        }
        charge = parseFloat(charge).toFixed(2);
        this.setState({
            orderData: {
                ...this.state.orderData,
                carriage: {
                    ...this.state.orderData.carriage,
                    carriageData: {
                        ...this.state.orderData.carriage.carriageData,
                        data: {
                            ...this.state.orderData.carriage.carriageData.data,
                            courier_conf_charge: charge
                        }
                    }
                }
            }
        })
    }

    handleDeliveryCustomChargeChange = charge => {
        this.setState({
            orderData: {
                ...this.state.orderData,
                carriage: {
                    ...this.state.orderData.carriage,
                    carriageData: {
                        ...this.state.orderData.carriage.carriageData,
                        data: {
                            ...this.state.orderData.carriage.carriageData.data,
                            courier_conf_charge: charge
                        }
                    }
                }
            }
        })
    }

    handleUpfrontPayment = e => {

        let upfront = '',
            credit  = '',
            total   = this.state.outstandingBalance,
            value   = e?.target?.value ?? 0;
        
        if(!_.isEmpty(value) && !isNaN(value)) {
            if(e?.target?.name === "credit") {
                credit  = value;
                upfront = (parseFloat(total) - parseFloat(credit)).toFixed(2)
            } else {
                upfront = value;
                credit  = (parseFloat(total) - parseFloat(upfront)).toFixed(2)
            }

        }

        this.setState({
            formData: {
                ...this.state.formData,
                credit,
                upfront
            }
        })

    }

    handleDecision = decision => {
        let credit  = '',
            upfront = '';

        if(decision === "custom") {
            credit  = "0.00"
            upfront = this.state.outstandingBalance
        }

        this.setState({
            formData: {
                ...this.state.formData,
                credit,
                upfront,
                decision
            }
        }, () => {
            this.props.scrollToBottom?.();
        });
    }

    submit = () => {

        const { formData, orderData } = this.state;
        const { id, order } = this.props;

        let data = {
            ...formData,
            orderData
        }

        this.setState({
            isLoading: true
        }, () => {

            API.post(`/sales/${id}/approval/${order?.approvals_outstanding?.[0]?.oa_id}`, data)
            .then(result => {

                if(result.data) {

                    let decision = formData.decision !== "rejected" ? (formData.decision === 'approved' ? 'approved' : 'approved (with terms)') : 'rejected';
                    this.props.deploySnackBar("success", `You have successfully ${decision} this sales order`);

                    if(decision === 'rejected') {
                        this.setState({
                            ...initialState
                    }, () => this.props.history.push('/sales/awaiting-approval'));
                    } else {
                        this.props.callback?.()
                    }

                }

            })

        })

    }

    render() {   

        const { 
            currentTab, 
            deployConfirmation, 
            order 
        } = this.props;

        const { 
            formData, 
            isLoading, 
            lists, 
            orderData,
            outstandingBalance,
            creditTotal,
            prepayTotal
        } = this.state;

        const approval = order?.approvals_outstanding?.[0];

        return (
            <Grid container spacing={3}>
                <Prompt
                    when={JSON.stringify(formData) !== JSON.stringify(this.state.initialFormData)}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            {(currentTab === 0 && (
                                <Grid item xs={12}>
                                    <ViewNotes heading={<Typography variant="h6">Sales Order Notes</Typography>} notes={order.notes} hideDeleteButton />
                                </Grid>
                            )) || (currentTab === 1 && (
                                <OrderSummary order={order} />
                            )) || (currentTab === 2 && (
                                <>
                                    {(approval.oa_type === 'Delivery' && (
                                        <Grid container spacing={3}> 
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <Box p={3}>
                                                        <Grid container spacing={1} alignItems='center'>
                                                            <Grid item xs>
                                                                <Typography variant="h6">
                                                                    Approval Details
                                                                </Typography>    
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={3} className="pt-1 pl-3"> 
                                                                    <Grid item xs={12} sm={6} lg={3}>
                                                                        <Tile
                                                                            icon="exclamation-triangle"
                                                                            color="#ef3340"
                                                                            title="Approval Reason"
                                                                            content={(approval.oa_reason) || 'Unknown'}
                                                                            noPaper
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} lg={3}>
                                                                        <Tile
                                                                            icon="calendar-day"
                                                                            color="#03A9F4"
                                                                            title="Order Date"
                                                                            content={moment(order.order_created_datetime).format("DD/MM/YYYY")}
                                                                            noPaper
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} lg={3}>
                                                                        <Tile
                                                                            icon="pound-sign"
                                                                            color="#F57C00"
                                                                            title="Sales Order | Net Total"
                                                                            content={currencyFormat.format(order.order_total_net)}
                                                                            noPaper
                                                                        />
                                                                    </Grid>  
                                                                    <Grid item xs={12} sm={6} lg={3}>
                                                                        <Tile
                                                                            icon="pound-sign"
                                                                            color="#7B1FA2"
                                                                            title="Sales Order | Gross Total"
                                                                            content={currencyFormat.format(order.order_total_gross)}
                                                                            noPaper
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box p={3}>
                                                        <Grid container spacing={1} alignItems='center'>
                                                            <Grid item xs>
                                                                <Typography variant="h6">
                                                                    Delivery Method
                                                                </Typography>    
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={3} className="pt-1 pl-3">
                                                                    <Grid item xs={12}>
                                                                        <Tile
                                                                            icon="truck"
                                                                            color="#4CAF50"
                                                                            title="Current Delivery Method"
                                                                            content={order?.delivery_carriage?.courier ? `${order.delivery_carriage.courier.courier_name} ${order.delivery_carriage.courier_conf_service} - ${currencyFormat.format(order.delivery_carriage.courier_conf_charge)}` : '-'}
                                                                            noPaper
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box p={3}>
                                                        <Grid container spacing={1} alignItems='center'>
                                                            <Grid item xs>
                                                                <Typography variant="h6">
                                                                    Decision
                                                                </Typography>    
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box pt={1}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} lg={order.customer.cust_has_credit === 1 ? 4 : 6}>
                                                                            <ToggleButton 
                                                                                onClick={() => this.handleDecision("approved")}
                                                                                selected={formData.decision === "approved"}
                                                                                style={{width: '100%', height: 175}} 
                                                                                value="approved"
                                                                            >
                                                                                <Grid container>
                                                                                    <Grid item xs={12}>
                                                                                        <FAIcon className="textSuccess" type="light" icon="check-circle" size={65} button />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Box pt={1}>
                                                                                            <Typography className="fw-400" variant="body2">
                                                                                                Approve Delivery
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </ToggleButton>
                                                                        </Grid>
                                                                        {order.customer.cust_has_credit === 1 && (
                                                                            <Grid item xs={12} lg={4}>
                                                                                <ToggleButton 
                                                                                    onClick={() => this.handleDecision("custom")}
                                                                                    selected={formData.decision === "custom"}
                                                                                    style={{width: '100%', height: 175}} 
                                                                                    value="custom"
                                                                                >
                                                                                    <Grid container>
                                                                                        <Grid item xs={12}>
                                                                                            <FAIcon className="textWarning" type="light" icon="exclamation-circle" size={65} button />
                                                                                        </Grid>
                                                                                        <Grid item xs={12}>
                                                                                            <Box pt={1}>
                                                                                                <Typography className="fw-400" variant="body2">
                                                                                                    Approve Delivery With Terms
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </ToggleButton>
                                                                            </Grid>
                                                                        )}
                                                                        <Grid item xs={12} lg={order.customer.cust_has_credit === 1 ? 4 : 6}>
                                                                            <ToggleButton
                                                                                onClick={() => this.handleDecision("rejected")}
                                                                                selected={formData.decision === "rejected"}
                                                                                style={{width: '100%', height: 175}} 
                                                                                value="rejected"
                                                                            >
                                                                                <Grid container>
                                                                                    <Grid item xs={12}>
                                                                                        <FAIcon className="textError" type="light" icon="times-circle" size={65} button />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Box pt={1}>
                                                                                            <Typography className="fw-400" variant="body2">
                                                                                                Decline Order
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </ToggleButton>
                                                                        </Grid>
                                                                        {(formData.decision === "custom" && (
                                                                            <>
                                                                                <Grid item xs={12}>
                                                                                    <Grid container spacing={3}>
                                                                                        <Grid item xs={12}>
                                                                                            <Typography variant="h6">
                                                                                                Delivery Terms
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} lg={4}>
                                                                                            <FormControl fullWidth>
                                                                                                <AutoCompleteSelect 
                                                                                                    label={`Change Delivery Method`}
                                                                                                    options={lists.delivery} 
                                                                                                    value={orderData?.carriage?.carriageId}
                                                                                                    onChange={v => this.handleDeliveryChange(v)}
                                                                                                    isGrouped={true}
                                                                                                    noClear
                                                                                                />
                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} lg={4}>
                                                                                            <TextField
                                                                                                label='Delivery Charge: *'
                                                                                                value={orderData?.carriage?.carriageData?.data?.courier_conf_charge}
                                                                                                onChange={e => this.handleDeliveryCustomChargeChange(e?.target?.value ?? 10.00)}
                                                                                                onBlur={this.handleDeliveryCustomChargeFloat}
                                                                                                margin="none"
                                                                                                InputProps={{
                                                                                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" noMargin /></InputAdornment>
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    min: 0,
                                                                                                    step: 1
                                                                                                }}
                                                                                                type="number"
                                                                                                fullWidth
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        ))}
                                                                        {formData.decision !== "" && (
                                                                            <>
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant="h6" paragraph>
                                                                                        Comments
                                                                                    </Typography>
                                                                                    <Box mb={3}>
                                                                                        {(formData.decision === "approved" && (
                                                                                            <Alert severity="success">
                                                                                                This delivery for this sales order will be approved and it will be released for payment / processing
                                                                                            </Alert>
                                                                                        )) || (formData.decision === "custom" && (
                                                                                            <Alert severity="warning">
                                                                                                This delivery for this sales order will be amended as per the above terms
                                                                                            </Alert>
                                                                                        )) || (
                                                                                            <Alert severity="error">
                                                                                                This sales order will be cancelled immediately on confirmation
                                                                                            </Alert>
                                                                                        )}
                                                                                    </Box>
                                                                                    <Textarea
                                                                                        margin="none"
                                                                                        name="comments"
                                                                                        value={formData.comments}
                                                                                        onChange={this.handleChange}
                                                                                        variant="outlined"
                                                                                        rows={5}
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                    /> 
                                                                                </Grid>
                                                                                <Grid item xs={12} align='right'>
                                                                                    <Button
                                                                                        color="primary"
                                                                                        disabled={(_.isEmpty(formData.decision) || (formData.decision === "custom" && (formData.credit === '' || formData.upfront === '' || formData.credit < 0 || formData.upfront < 0 || parseFloat(formData.credit) > parseFloat(this.state.outstandingBalance) || parseFloat(formData.upfront) > parseFloat(this.state.outstandingBalance)))) ? true : false}
                                                                                        onClick={() => deployConfirmation(`Are you sure you want to ${formData.decision === "rejected" ? "decline" : "approve"} this ${approval.oa_type === 'Accounts' ? 'order' : 'delivery'} ${formData.decision === "custom" ? " with the terms specified" : ""}?`, this.submit)}
                                                                                        variant="text"
                                                                                    >
                                                                                        Confirm
                                                                                    </Button>
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    )) || (
                                        <Grid container spacing={3}> 
                                            {(order.order_ifm_approval === 1 || order.customer.cust_has_credit === 1) && (
                                                <Grid item xs={12} className="pb-0">
                                                    {order.order_ifm_approval === 1 && (
                                                        <Box pb={2}>
                                                            <Alert severity="error">
                                                                This sales order {_.size(order.approvals > 1) ? 'was previously approved but has now been modified' : 'has been modified and now requires approval'} for the outstanding balance of <strong>{currencyFormat.format(outstandingBalance)}</strong>
                                                            </Alert>
                                                        </Box>
                                                    )}
                                                    {order.customer.cust_has_credit === 0 && (
                                                        <Box pb={2}>
                                                            <Alert severity="info">
                                                                This is a prepay customer, however, credit terms have been selected for this sales order ({order.payment_terms?.pt_name ?? 'Unknown'})
                                                            </Alert>
                                                        </Box>
                                                    )}
                                                </Grid>
                                            )}
                                            <Grid item xs={12} lg={6}>
                                                <Paper>
                                                    <Box p={3}>
                                                        <Grid container spacing={2} alignItems='center'>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6">
                                                                    Approval Details
                                                                </Typography>    
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Tile
                                                                    icon="exclamation-triangle"
                                                                    title="Approval Reason"
                                                                    content={(approval.oa_reason) || 'Unknown'}
                                                                    noPaper
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Tile
                                                                    icon="calendar-day"
                                                                    title="Order Date"
                                                                    content={moment(order.order_created_datetime).format("DD/MM/YYYY")}
                                                                    noPaper
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Tile
                                                                    icon="pound-sign"
                                                                    title="Sales Order | Net Total"
                                                                    content={currencyFormat.format(order.order_total_net)}
                                                                    noPaper
                                                                />
                                                            </Grid>  
                                                            <Grid item xs={12} lg={6}>
                                                                <Tile
                                                                    icon="pound-sign"
                                                                    title="Sales Order | Gross Total"
                                                                    content={currencyFormat.format(order.order_total_gross)}
                                                                    noPaper
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Paper>
                                                    <Box p={3}>
                                                        <Grid container spacing={2} alignItems='center'>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6">
                                                                    {order.customer.cust_company_name}
                                                                </Typography>    
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <BooleanTile
                                                                    title="Credit Limit"
                                                                    content={currencyFormat.format(parseFloat(order.customer.cust_credit_limit))}
                                                                    isTrue={true}
                                                                    noPaper
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <BooleanTile
                                                                    title="Current Balance"
                                                                    content={currencyFormat.format(parseFloat(order.customer.cust_credit_balance ?? 0))}
                                                                    isTrue={parseFloat(order.customer.cust_credit_balance) > parseFloat(order.customer.cust_credit_limit) ? false : true}
                                                                    noPaper
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <BooleanTile
                                                                    title="Available Credit"
                                                                    content={(currencyFormat.format(order.customer.cust_credit_available)) || '-'}
                                                                    isTrue={parseFloat(order.customer.cust_credit_available) > 0 ? true : false}
                                                                    noPaper
                                                                />
                                                            </Grid> 
                                                            <Grid item xs={12} lg={6}>
                                                                <BooleanTile
                                                                    title="Available Credit (If Approved)"
                                                                    content={(currencyFormat.format((parseFloat(order.customer.cust_credit_available ?? 0) - parseFloat(outstandingBalance)).toFixed(2))) || '-'}
                                                                    isTrue={(parseFloat(order.customer.cust_credit_available) - parseFloat(outstandingBalance)) > 0 ? true : false}
                                                                    noPaper
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <AgedBalances customer={order.customer} withIcons />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <Box p={3}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6">
                                                                    Outstanding Orders
                                                                </Typography>    
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {(_.isEmpty(order.outstanding_orders) && (
                                                                    <Alert variant="outlined" severity="success">
                                                                        <strong>None -</strong> This customer has no outstanding orders
                                                                    </Alert>
                                                                )) || (
                                                                    <>
                                                                        <SearchOrdersTable
                                                                            dataLoading={false} 
                                                                            inline={true}
                                                                            rows={order.outstanding_orders}
                                                                            orderStatus={'ApprovalDetails'}
                                                                            showFullTotals
                                                                            plainPagination
                                                                        />
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                            {!_.isEmpty(order.payments) && (  
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <Box p={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6">
                                                                        Order Payments
                                                                    </Typography>    
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} lg={9}>
                                                                            <SearchTransactionsTable 
                                                                                isLoading={isLoading} 
                                                                                orderReference={order.order_reference} 
                                                                                rows={order.payments} 
                                                                                noPaper
                                                                                noExport
                                                                                noPagination
                                                                                inline
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={3}>
                                                                            <Box pt={3} pl={3} pb={3} className="content-light-grey">
                                                                                <Grid container spacing={3}>
                                                                                    {/* <Grid item xs={12}>
                                                                                        <Typography variant="h6">
                                                                                            Totals
                                                                                        </Typography>
                                                                                    </Grid> */}
                                                                                    <Grid item xs={12}>
                                                                                        <Tile
                                                                                            icon="check"
                                                                                            color="#4CAF50"
                                                                                            title="Credit Account"
                                                                                            content={currencyFormat.format(creditTotal)}
                                                                                            noPaper
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Tile
                                                                                            icon="check"
                                                                                            color="#4CAF50"
                                                                                            title="Prepaid Amount"
                                                                                            content={currencyFormat.format(prepayTotal)}
                                                                                            noPaper
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Divider />
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Tile
                                                                                            icon="exclamation-triangle"
                                                                                            color="#ef3340"
                                                                                            title="Outstanding Balance"
                                                                                            content={currencyFormat.format(outstandingBalance)}
                                                                                            noPaper
                                                                                        />
                                                                                    </Grid>
                                                                                    {/* <Grid item xs={12}>
                                                                                        <Tile
                                                                                            icon="pound-sign"
                                                                                            title="Sales Order | Gross Total"
                                                                                            content={currencyFormat.format(order.order_total_gross)}
                                                                                            noPaper
                                                                                        />
                                                                                    </Grid> */}
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <Box p={3}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">
                                                                Decision
                                                            </Typography>    
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box pt={2}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} md={4}>
                                                                        <ToggleButton 
                                                                            onClick={() => this.handleDecision("approved")}
                                                                            selected={formData.decision === "approved"}
                                                                            style={{width: '100%', height: 70}} 
                                                                            value="approved"
                                                                        >
                                                                            <Grid container alignItems='center' justify='center'>
                                                                                <Grid item>
                                                                                    <FAIcon className="textSuccess mr-1" type="light" icon="check-circle" size={25} button />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography className="fw-400 textDefault" variant="body2">
                                                                                        Approve Order
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ToggleButton>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <ToggleButton 
                                                                            onClick={() => this.handleDecision("custom")}
                                                                            selected={formData.decision === "custom"}
                                                                            style={{width: '100%', height: 70}} 
                                                                            value="custom"
                                                                        >
                                                                            <Grid container alignItems='center' justify='center'>
                                                                                <Grid item>
                                                                                    <FAIcon className="textWarning mr-1" type="light" icon="exclamation-circle" size={25} button />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography className="fw-400 textDefault" variant="body2">
                                                                                        Approve Order With Terms
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ToggleButton>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <ToggleButton
                                                                            disabled={order.order_ifm_approval === 1}
                                                                            onClick={() => this.handleDecision("rejected")}
                                                                            selected={formData.decision === "rejected"}
                                                                            style={{width: '100%', height: 70}} 
                                                                            value="rejected"
                                                                        >
                                                                            <Grid container alignItems='center' justify='center'>
                                                                                <Grid item>
                                                                                    <FAIcon className={`${order.order_ifm_approval === 0 ? `textError mr-1` : `mr-1`}`} type="light" icon="times-circle" size={25} button disabled={order.order_ifm_approval === 1} />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography className="fw-400 textDefault" variant="body2">
                                                                                        Decline Order
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ToggleButton>
                                                                    </Grid>
                                                                    {(formData.decision === "custom" && (
                                                                        <>
                                                                            <Grid item xs={12}>
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="h6">
                                                                                            Order Terms
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <TextField
                                                                                            label="Upfront Payment"
                                                                                            margin="none"
                                                                                            name="upfront"
                                                                                            value={formData.upfront}
                                                                                            onChange={this.handleUpfrontPayment}
                                                                                            onBlur={this.handleFloat}
                                                                                            error={(formData.upfront < 0 || parseFloat(formData.upfront) > parseFloat(this.state.outstandingBalance)) ? true : false}
                                                                                            helperText={(formData.upfront < 0 || parseFloat(formData.upfront) > parseFloat(this.state.outstandingBalance)) ? `Please enter an amount between £0.01 and £${this.state.outstandingBalance}` : undefined}
                                                                                            InputProps={{
                                                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                                                            }}
                                                                                            inputProps={{
                                                                                                min: 0,
                                                                                                max: this.state.outstandingBalance,
                                                                                                step: 0.01,
                                                                                            }}
                                                                                            style={{
                                                                                                width: 200,
                                                                                                maxWidth: 200
                                                                                            }}
                                                                                            type="number"
                                                                                            fullWidth
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <TextField
                                                                                            label="Credit Offered"
                                                                                            margin="none"
                                                                                            name="credit"
                                                                                            value={formData.credit}
                                                                                            onChange={this.handleUpfrontPayment}
                                                                                            onBlur={this.handleFloat}
                                                                                            error={(formData.credit < 0 || parseFloat(formData.credit) > parseFloat(this.state.outstandingBalance)) ? true : false}
                                                                                            helperText={(formData.credit < 0 || parseFloat(formData.credit) > parseFloat(this.state.outstandingBalance)) ? `Please enter an amount between £0.01 and £${this.state.outstandingBalance}` : undefined}
                                                                                            InputProps={{
                                                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                                                                            }}
                                                                                            inputProps={{
                                                                                                min: 0,
                                                                                                max: this.state.outstandingBalance,
                                                                                                step: 0.01,
                                                                                            }}
                                                                                            style={{
                                                                                                width: 200,
                                                                                                maxWidth: 200
                                                                                            }}
                                                                                            type="number"
                                                                                            fullWidth
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Box mb={0.75}>
                                                                                            <Typography variant="caption" component="div" color="textSecondary">
                                                                                                New Available Credit
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Typography variant="body2" className={order.customer.cust_has_credit ? ((parseFloat(order.customer.cust_credit_available) - (parseFloat(this.state.outstandingBalance) - parseFloat(formData.upfront))) < 0 ? 'textError' : undefined) : undefined} paragraph>
                                                                                            <FAIcon type="light" icon="pound-sign" size="small" />{(formData.credit || formData.upfront) === '' ? '-' : ((order.customer.cust_has_credit && (parseFloat(order.customer.cust_credit_available) - parseFloat(formData.credit)).toFixed(2)) || '-')}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    // )) || (order.customer.cust_has_credit === 1 && formData.decision === "approved" && (
                                                                    //     <>
                                                                    //         <Grid item xs={12} lg={6}>
                                                                    //             <Typography variant="caption" component="div" color="textSecondary">
                                                                    //                 Gross Total
                                                                    //             </Typography>
                                                                    //             <Typography variant="body2" paragraph>
                                                                    //                 {currencyFormat.format(order.order_total_gross)}
                                                                    //             </Typography>
                                                                    //             <Typography variant="caption" component="div" color="textSecondary">
                                                                    //                 Current Available Credit
                                                                    //             </Typography>
                                                                    //             <Typography variant="body2" paragraph>
                                                                    //                 {(order.customer.cust_has_credit && currencyFormat.format(parseFloat(order.customer.cust_credit_available).toFixed(2))) || '-'}
                                                                    //             </Typography>
                                                                    //             <Typography variant="caption" component="div" color="textSecondary">
                                                                    //                 New Available Credit
                                                                    //             </Typography>
                                                                    //             <Typography variant="body2" className={order.customer.cust_has_credit ? ((parseFloat(order.customer.cust_credit_available) - parseFloat(order.order_total_gross)) < 0 ? 'textError' : 'textSuccess') : undefined} paragraph>
                                                                    //                 {(order.customer.cust_has_credit && currencyFormat.format((parseFloat(order.customer.cust_credit_available) - parseFloat(order.order_total_gross)).toFixed(2))) || '-'}
                                                                    //             </Typography>
                                                                    //         </Grid>
                                                                    //     </>
                                                                    // ))}
                                                                    ))}
                                                                    {formData.decision !== "" && (
                                                                        <>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="h6" paragraph>
                                                                                    Comments
                                                                                </Typography>
                                                                                <Box mb={3}>
                                                                                    {(formData.decision === "approved" && (
                                                                                        <Alert severity="success">
                                                                                            <strong>Order Approved</strong><br />
                                                                                            This sales order will be approved and released immediately for processing
                                                                                        </Alert>
                                                                                    )) || (formData.decision === "custom" && (
                                                                                        <Alert severity="warning">
                                                                                            <strong>Order Approved With Terms</strong><br />
                                                                                            This sales order will be released subject to the terms specified above
                                                                                        </Alert>
                                                                                    )) || (
                                                                                        <Alert severity="error">
                                                                                            <strong>Order Cancellation</strong><br />
                                                                                            This option should be used if you want to cancel the order with immediate effect
                                                                                        </Alert>
                                                                                    )}
                                                                                </Box>
                                                                                <Textarea
                                                                                    margin="none"
                                                                                    name="comments"
                                                                                    value={formData.comments}
                                                                                    onChange={this.handleChange}
                                                                                    variant="outlined"
                                                                                    rows={5}
                                                                                    InputLabelProps={{
                                                                                        shrink: true
                                                                                    }}
                                                                                /> 
                                                                            </Grid>
                                                                            <Grid item xs={12} align='right'>
                                                                                <Button
                                                                                    color="primary"
                                                                                    disabled={(_.isEmpty(formData.decision) || (formData.decision === "custom" && (formData.credit === '' || formData.upfront === '' || formData.credit < 0 || formData.upfront < 0 || parseFloat(formData.credit) > parseFloat(order.order_total_gross) || parseFloat(formData.upfront) > parseFloat(order.order_total_gross)))) ? true : false}
                                                                                    onClick={() => deployConfirmation(`Are you sure you want to ${formData.decision === "rejected" ? "decline" : "approve"} this order${formData.decision === "custom" ? " with the terms specified" : ""}?`, this.submit)}
                                                                                    variant="text"
                                                                                >
                                                                                    <FAIcon icon="check" size={15} buton />
                                                                                    Confirm Decision
                                                                                </Button>
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            ))}     
                        </Grid> 
                    </>
                )}
            </Grid>
        );
    }
}

export default AwaitingApproval;