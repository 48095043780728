import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

import API from 'API';
import Search from 'Components/Common/Search/Search';

import Button from '@material-ui/core/Button';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffChip from 'Components/Common/Chips/StaffChip';

import {
    momentFormatDate
} from 'Functions/MiscFunctions';

import {
    CLOUDFRONT_URL
} from 'Constants';

const initialState = () => ({
    access: {
        newBatch: false,
        viewBatch: false
    },
    key: uuidv4()
})

const SearchBulkManagementBatches = ({pageTitle, history}) => {
    
    const [state, setState] = useState(initialState());

    const staff = useSelector(state => state.notifications.status);

    const handleNewBatch = () => {
        history.push(`/products/bulk-management/new`)
    }

    useEffect(() => {
        API.multiAccess([
            'bulk-prd-batch',
            'bulk-prd-view'
        ])
        .then(([
            newBatch, 
            viewBatch
        ]) =>  {
            setState(state => ({
                ...state,
                access: {
                    newBatch, 
                    viewBatch
                }
            }));
        })
    }, []);

    const {
        access,
        key
    } = state;

    return (
        <Search
            innerKey={key}
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/products/bulkManagement',
                columns: [
                    {
                        heading: 'Batch',
                        field: rowData => rowData.re,
                        dataRef: 're',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.su,
                        dataRef: 'su',
                    },
                    // {
                    //     heading: 'Notifications Sent',
                    //     field: rowData => rowData.sn,
                    //     fieldFormat: 'boolean',
                    //     dataRef: 'sn',
                    //     sizeToContent: true,                                
                    // },
                    {
                        heading: 'Prices Effective',
                        field: rowData => rowData.pe ? momentFormatDate(rowData.pe) : (rowData.prc > 0 ? momentFormatDate(rowData.dt) : 'N/A'),
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'New Products',
                        field: rowData => rowData.new,
                        sizeToContent: true,  
                        alignment: 'right'
                    },
                    {
                        heading: 'Replaced Products',
                        field: rowData => rowData.rep,
                        sizeToContent: true,  
                        alignment: 'right'
                    },
                    {
                        heading: 'Deleted Products',
                        field: rowData => rowData.del,
                        sizeToContent: true,  
                        alignment: 'right'
                    },
                    {
                        heading: 'Product Changes',
                        field: rowData => rowData.pdc,
                        sizeToContent: true,  
                        alignment: 'right'
                    },
                    {
                        heading: 'Price Changes',
                        field: rowData => rowData.prc,
                        sizeToContent: true,  
                        alignment: 'right'
                    },
                    {
                        heading: 'Processed By',
                        field: rowData => <StaffChip staff={staff?.[rowData.st]} />,
                        fieldFormat: 'staff',
                        dataRef: 'st',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Processed',
                        field: rowData => rowData.dt,
                        fieldFormat: 'datetime',
                        dataRef: 'dt',
                        sizeToContent: true,                                
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'Download CSV', icon: 'file-csv', color: !_.isEmpty(rowData.fk) ? 'success' : undefined, disabled: _.isEmpty(rowData.fk), linkExternal: `${CLOUDFRONT_URL}${rowData.fk}`},
                                {name: 'View', icon: 'binoculars', link: `/products/bulk-management/${rowData.id}`, disabled: !access.viewBatch, doubleClick: true}
                            ]
                        }
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'bulk-product-management',
                noResultsText: 'No bulk product management updates have been created',
                pagination: true,
                pageTitleData: [0, 'Products', 'Bulk Product Management'],
                pdf: true,
                persistenceId: 'bulkProductManagement',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showButton: (
                    access.newBatch && (
                        <Button 
                            color="primary" 
                            size="small"
                            variant="text"
                            onClick={handleNewBatch}
                        >   
                            <FAIcon type="light" icon="plus-circle" size="small" button />
                            New Bulk Product Import
                        </Button>
                    )
                ),
                showCustomer: false,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showSupplier: true,
                showStaff: false,
                showTotals: false,
                title: 'Bulk Product Management'
            }}
        />
    )
}

export default SearchBulkManagementBatches;