import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DateSelect from 'Components/Common/Selects/DateSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import SearchRMATable from 'Components/RMA/SearchRMA/SearchRMATable';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        approveRMA: false,
        viewRMA: false,
    },
    dataLoading: true,
    date: 'allTime',
    status: '',
    statusList: [
        {value: 'Open', label: 'Open'},
        {value: 'Complete', label: 'Complete'},
        {value: 'Cancelled', label: 'Cancelled'},
        {value: 'Goods Received', label: 'Goods Received'},
        {value: 'With Supplier', label: 'With Supplier'},
        // {value: 'With Customer', label: 'With Customer'},
    ],
    searchResults: [],
    searchString: ''
})

class SearchRMA extends React.Component {
    constructor(props) {
        super(props);

        const {
            customerId,
            status
        } = this.props;

        this.persistenceId = `rmaSearch:${status ?? `all`}${customerId ? `:c${customerId}` : ``}`;

        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }
    
    componentDidMount = () => {

        const { 
            status 
        } = this.props;

        this.props.pageTitle?.([1, 'RMA', status ?? 'All'])

        if(!this.hasPageState()) {
            Promise.all([
                API.access('rma-approval'),
                API.access('view-rma')
            ])
            .then(([approveRMA, viewRma]) =>  {
                this.setState({
                    ...this.getInitialState(initialState()),
                    access: {
                        approveRMA: approveRMA?.data?.has_access ?? false,
                        viewRMA: viewRma?.data?.has_access ?? false,
                    },
                    date: !status || status === 'Complete' || status === 'Cancelled' ? 'sixMonths' : 'allTime'
                }, () => {
                    this.getSearchData();
                })
            })
        }

    }

    componentDidUpdate = prevProps => {

        if((this.props.location?.pathname !== prevProps.location?.pathname) || (this.props.status !== prevProps.status)) {
            
            API.cancel();
            
            const {
                customerId,
                status 
            } = this.props;

            this.persistenceId = `rmaSearch:${status ?? `all`}${customerId ? `:c${customerId}` : ``}`;

            this.props.pageTitle?.([1, 'RMA', status ?? `All`])

            if(this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(initialState())
                });
            } else {
                this.setState({
                    date: !status || status === 'Complete' || status === 'Cancelled' ? 'sixMonths' : 'allTime',
                    status: '',
                    searchString: '',
                }, () => {
                    this.getSearchData();
                })
            }

        }

    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);            
        API.cancel();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.getSearchData();
        });
    }

    getSearchData = () => {

        let params = {
            d: this.state.date,
            status: !_.isEmpty(this.state.status) ? this.state.status : this.props.status,
            searchString: this.state.searchString,
        }
        
        if(this.props.customerId) {
            params = {
                ...params,
                c: this.props.customerId
            }
        }

        this.setState({
            dataLoading: true
        }, () => {
            API.get('/rma', {
                props: {
                    cancellation: true
                },
                params
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    }, () => {
                        this.savePageState();
                    })
                } 
            })
        })
    }
    
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {        
        const { 
            status 
        } = this.props;        
        API.cancel();
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access,
                date: !status || status === 'Complete' || status === 'Cancelled' ? 'sixMonths' : 'allTime'
            }, () => {
                this.getSearchData();
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }
    
    render() {
        const { customerId, status } = this.props;
        let { access, dataLoading, date, searchResults, searchString, statusList } = this.state;
        return (
            <Grid container spacing={1}>      
                <Grid item xs={12} alignItems='center'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4} xl={3} style={{marginBottom: 12}}>
                            <TextField 
                                fullWidth
                                onChange={this.onSearchStringChange}
                                placeholder='Search:'
                                value={searchString} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                }}
                            /> 
                        </Grid>
                        {!status && (
                            <Grid item xs={12} sm={4} md={4} xl={2} style={{marginBottom: 12}}>
                                <AutoCompleteSelect
                                    options={statusList}
                                    placeholder='Status:'
                                    onChange={this.handleSelectChange('status')}
                                    value={this.state.orderStatus} 
                                    variant="filled"
                                    adornment="filter"
                                /> 
                            </Grid>
                        )}
                        <Grid item xs={12} sm={4} md={4} xl={2} style={{marginBottom: 12}}>
                            <DateSelect
                                onChange={this.handleSelectChange('date')}
                                value={date}
                            />
                        </Grid>
                    </Grid>
                </Grid>           
                <Grid item xs={12}>
                    <Paper>
                        <SearchRMATable 
                            access={access}
                            basic={customerId}
                            callback={this.getSearchData}
                            dataLoading={dataLoading} 
                            rows={searchResults} 
                            persistenceId={this.persistenceId}
                            resetSearch={this.resetSearch} 
                            status={status}
                        />
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    ui: state.ui,
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => {
    return {
        clearPersistence: () => dispatch(clearPersistence()),
        setPersistence: (key, state) => dispatch(setPersistence(key, state)),
        closeDialog: () => dispatch(closeDialog()),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchRMA);