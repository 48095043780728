import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";

import { Avatar, Box, Card, Grid, IconButton, Paper, TextField, Tooltip, Typography } from "@material-ui/core";

import Alert from "Components/Common/Alert/Alert";
import API from "API";
import AppButton from "Components/Common/Buttons/AppButton";
import DeliverySummary from "Components/Sales/Misc/DeliverySummary";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import logo from "Assets/Images/no-img.jpg";
import PrintJobDialog from "Components/Common/Dialogs/PrintJobDialog";
import Textarea from "Components/Common/Inputs/Textarea";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import StackTable from "Components/Common/DataTables/StackTable";

import { CLOUDFRONT_URL, ENVIRONMENT } from "Constants";

const CheckDialog = ({ check, closeDialog, deployConfirmation, handleCheckIssue, handleCheckOkay }) => {
    const [qtyConfirmed, setQtyConfirmed] = useState(false);

    const [qtyChecked, setQtyChecked] = useState("");

    const [qtyFound, setQtyFound] = useState("");

    const diff = qtyChecked !== "" && parseInt(parseInt(check?.line?.quantity) - qtyChecked);

    const btnDisabled = qtyChecked < 0 || qtyChecked === "";

    const btn2Disabled = diff < 0 ? false : diff > 0 && qtyFound === "" ? true : false;

    const handleCheckQty = (e) => {
        if (_.isEmpty(e?.target?.value)) {
            setQtyChecked("");
            return;
        }
        setQtyChecked(parseInt(e?.target?.value ?? 0));
    };

    const handleFoundQty = (e) => {
        if (_.isEmpty(e?.target?.value) || parseInt(e?.target?.value) < 0 || parseInt(e?.target?.value) > diff) {
            setQtyFound("");
            return;
        }
        setQtyFound(parseInt(e?.target?.value ?? 0));
    };

    const handleCheckContinue = () => {
        if (qtyChecked === parseInt(check?.line?.quantity)) {
            handleCheckOkay(check.idx);
            closeDialog();
        } else {
            setQtyConfirmed(true);
        }
    };

    const handleSubmit = () => {
        let total = qtyFound === "" ? check?.line?.quantity : qtyChecked + qtyFound;
        // if(handlePickModification) {
        //     let modifiedStatus = parseInt(lines[line].quantity) === qtyPicked ? 'success' : status;
        //     deployConfirmation(`You have picked a quantity of ${qtyPicked} for this line?`, () => {
        //         handlePickModification(line, qtyPicked, modifiedStatus);
        //         closeDialog();
        //     })
        // } else {
        deployConfirmation(`You have a total of ${total} ready to despatch?`, () => {
            handleCheckIssue(check.idx, qtyChecked, qtyFound, total);
            closeDialog();
        });
        // }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    variant="body1"
                    className="fw-500"
                    style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                >
                    {check?.line?.productName}
                </Typography>
            </Grid>
            {(qtyConfirmed && (
                <>
                    {(diff < 0 && (
                        <>
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <strong>
                                        Please return {Math.abs(diff)} to bin {check?.line?.whBin}
                                    </strong>
                                </Alert>
                            </Grid>
                        </>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <strong>
                                        Please pick {diff} from bin {check?.line?.whBin}
                                    </strong>
                                </Alert>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Picked *"
                                    value={qtyFound}
                                    onChange={handleFoundQty}
                                    margin="none"
                                    fullWidth
                                    type="number"
                                    inputProps={{
                                        size: 4,
                                        pattern: "[0-9]*",
                                    }}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled
                                    label="Total Present *"
                                    value={qtyFound === "" ? qtyChecked : qtyChecked + qtyFound}
                                    margin="none"
                                    fullWidth
                                    type="number"
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <AppButton
                            disabled={btn2Disabled}
                            onClick={handleSubmit}
                            primary
                            color={btn2Disabled ? `#bbb` : `#fff`}
                            className={btn2Disabled ? `btn btn-disabled` : `btn btn-success`}
                            icon="check"
                            text={diff < 0 ? "Stock Returned" : "Confirm"}
                        />
                    </Grid>
                </>
            )) || (
                <>
                    <Grid item xs={12}>
                        <TextField
                            label="Qty Picked *"
                            value={qtyChecked}
                            onChange={handleCheckQty}
                            margin="none"
                            fullWidth
                            type="number"
                            inputProps={{
                                size: 4,
                                pattern: "[0-9]*",
                            }}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AppButton
                            disabled={btnDisabled}
                            onClick={handleCheckContinue}
                            primary
                            color={btnDisabled ? `#bbb` : `#fff`}
                            className={btnDisabled ? `btn btn-disabled` : `btn btn-success`}
                            icon="check"
                            text="Continue"
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const initialState = {
    checkStarted: null,
    checkStatus: {},
    checkCompleted: null,
    isLoading: false,
    printing: false,
    notes: {
        check: "",
    },
};

class Check extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentDidMount = () => {
        const { od, loggedInStaffId, deploySnackBar, history } = this.props;

        if (od?.pick?.pick_staff_id === loggedInStaffId && ENVIRONMENT === "PRODUCTION") {
            deploySnackBar(`error`, `You cannot check this order as you picked it`);
            history.push(`/`);
        } else {
            this.loadComponentData();
        }
    };

    loadComponentData = (restartCheck = false) => {
        const { currentTab, od, scrollToTop } = this.props;

        let { checkStatus } = this.state;

        _.each(
            _.orderBy(
                _.filter(od.products, (_el) => _el.productId > 0 && _el.service === 0),
                (el) => el.whBin
            ),
            (odp, idx) => {
                checkStatus = {
                    ...checkStatus,
                    [idx]: {
                        idx,
                        line: odp,
                        checkTime: null,
                        qtyExpected: odp.quantity,
                        qtyChecked: 0,
                        qtyFound: 0,
                        qtyTotal: odp.quantity,
                        pickError: 0,
                        pickComplete: 1,
                        status: null,
                    },
                };
            }
        );

        this.setState(
            {
                checkStatus,
                checkStarted: restartCheck || currentTab === 2 ? moment().format("YYYY-MM-DD HH:mm:ss") : null,
            },
            scrollToTop
        );
    };

    componentDidUpdate = (prevProps) => {
        const { checkStarted } = this.state;

        if (checkStarted === null) {
            if (prevProps.currentTab !== 2 && this.props.currentTab === 2) {
                this.setState({
                    checkStarted: moment().format("YYYY-MM-DD HH:mm:ss"),
                });
            }
        }
    };

    componentWillUnmount = () => {
        if (this.timeout) clearTimeout(this.timeout);
    };

    handleCheckIssue = (idx, qtyChecked, qtyFound, qtyTotal) => {
        let { checkStatus } = this.state;

        checkStatus = {
            ...checkStatus,
            [idx]: {
                ...checkStatus?.[idx],
                checkTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                qtyChecked,
                qtyFound,
                qtyTotal,
                pickError: 1,
                pickComplete: checkStatus?.[idx]?.qtyExpected === qtyTotal ? 1 : 0,
                status: "error",
            },
        };

        this.setState({
            checkStatus,
        });
    };

    handleCheckOkay = (idx) => {
        const { playSound } = this.props;

        let { checkStatus } = this.state;

        let qty = parseInt(checkStatus?.[idx]?.line?.quantity);

        checkStatus = {
            ...checkStatus,
            [idx]: {
                ...checkStatus?.[idx],
                checkTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                qtyChecked: qty,
                qtyFound: 0,
                qtyTotal: qty,
                pickError: 0,
                pickComplete: 1,
                status: "success",
            },
        };

        this.setState(
            {
                checkStatus,
            },
            () => {
                playSound("pickSuccess", this.state.line);
            }
        );
    };

    handleDeployCheckIssue = (idx) => {
        const { checkStatus } = this.state;

        const { closeDialog, deployConfirmation, deployDialog, playSound } = this.props;

        playSound("pickError", this.state.line);

        deployDialog(
            <CheckDialog
                closeDialog={closeDialog}
                deployConfirmation={deployConfirmation}
                handleCheckOkay={this.handleCheckOkay}
                handleCheckIssue={this.handleCheckIssue}
                check={checkStatus?.[idx]}
            />,
            false,
            `${checkStatus?.[idx]?.line?.productCode}`,
            "standard",
            "xs",
            false,
            true
        );
    };

    handleNotes = (type, value) => {
        this.setState({
            notes: {
                ...this.state.notes,
                [type]: value,
            },
        });
    };

    handleCheckComplete = () => {
        const { checkStatus } = this.state;

        this.setState(
            {
                checkCompleted: moment().format("YYYY-MM-DD HH:mm:ss"),
            },
            () => {
                const failed = _.some(checkStatus, (el) => el.pickComplete === 0);

                this.setState(
                    {
                        isLoading: true,
                    },
                    () => {
                        const { deployDialog, id } = this.props;
                        const { notes, checkStarted, checkStatus, checkCompleted } = this.state;

                        API.put(`/warehouse/delivery/${id}/check`, {
                            notes: notes?.check ?? "",
                            status: failed ? 0 : 1,
                            checkStarted,
                            checkStatus,
                            checkCompleted,
                        }).then((res) => {
                            if (res?.data) {
                                if (res.data?.checked) {
                                    this.handleErrorRedirect("has been checked by another user");
                                } else if (res.data?.pendingRelease === 1) {
                                    this.handleErrorRedirect("is pending release");
                                } else if (res.data?.cancelled) {
                                    this.handleErrorRedirect("has been cancelled");
                                } else if (res.data?.hold) {
                                    this.handleErrorRedirect("has been placed on hold");
                                } else if (res.data?.notAwaitingCheck) {
                                    this.handleErrorRedirect("has been modified and is no longer awaiting check");
                                } else if (failed) {
                                    this.setState(
                                        {
                                            printing: true,
                                        },
                                        () => {
                                            deployDialog(
                                                <PrintJobDialog
                                                    printJob={res.data.printJob}
                                                    docTitle={`Hold Note`}
                                                    docSubtitle={`Delivery #${this.props.od.od_reference}/${this.props.od.od_idx}`}
                                                    onClose={this.handleCheckFailCompleted}
                                                />,
                                                true,
                                                "",
                                                "standard",
                                                "sm",
                                                false,
                                                true
                                            );
                                        }
                                    );
                                } else {
                                    this.handleCheckCompleted(res.data.odStatus === "Awaiting Technical");
                                }
                            }
                        });
                    }
                );
            }
        );
    };

    handleCheckCompleted = (requiresTechnical) => {
        const { callback, deployConfirmation, deploySnackBar, history } = this.props;

        deploySnackBar(`success`, `Delivery successfully checked`);

        if (requiresTechnical) {
            deployConfirmation(
                <>
                    <Typography variant="body2" className="fw-400">
                        This order requires technical before despatch
                    </Typography>
                    <Typography variant="body2">
                        Please take the despatch note and goods to technical for processing
                    </Typography>
                </>,
                () => history.push("/warehouse/outgoing/awaiting-check"),
                false,
                "Done",
                null,
                true
            );
        } else {
            deployConfirmation(
                `Do you want to despatch this delivery now?`,
                () => callback?.(),
                () => history.push("/warehouse/outgoing/awaiting-check")
            );
        }
    };

    handleCheckFailCompleted = () => {
        const { deploySnackBar, history } = this.props;

        deploySnackBar(`warning`, `Delivery referred to Sales Admin`);

        history.push(`/warehouse/outgoing/awaiting-check`);
    };

    handleErrorRedirect = (reason) => {
        const { deploySnackBar, history, warehouseMode } = this.props;

        deploySnackBar(`error`, `This delivery ${reason}, contact Sales Admin for further advice`);
        if (warehouseMode) {
            history.push(`/dashboard`);
        } else {
            history.push(`/warehouse/outgoing/awaiting-despatch`);
        }
    };

    render = () => {
        const { checkStatus, isLoading, printing, notes } = this.state;
        const { currentTab, deployConfirmation, isPDA, order, od } = this.props;
        return (
            (isLoading && ((!printing && <LoadingCircle />) || <React.Fragment />)) ||
            (currentTab === 0 && (
                <Paper>
                    <Box p={3}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="h6">Notes</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <ViewNotes inline notes={order.notes} hideDeleteButton />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )) ||
            (currentTab === 1 && (
                <DeliverySummary
                    od={od}
                    order={order}
                    idx={_.findIndex(order.deliveries, (el) => el.od_id === od.od_id) + 1}
                />
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container justify="space-between">
                            <Grid item xs={12} align="center">
                                <Typography variant="h5" component="div" className="fw-500">
                                    Check Order
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {_.map(checkStatus, (p, idx) => (
                        <Grid item xs={12} key={idx}>
                            <Card>
                                <Box p={2}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Avatar
                                                variant="square"
                                                alt={p.line?.productName ?? "Product Image"}
                                                src={
                                                    p.line?.image
                                                        ? `${CLOUDFRONT_URL}${p.line?.image}`
                                                        : p.line?.product?.default_image?.img_filekey
                                                        ? `${CLOUDFRONT_URL}${p.line?.product.default_image.img_filekey}`
                                                        : logo
                                                }
                                                style={{ width: 50, height: 50 }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body1" className="fw-400">
                                                {p.line?.productCode}
                                            </Typography>
                                            <Typography variant="caption" className="fw-400">
                                                {p.line?.productName}
                                            </Typography>
                                            {p.line?.whBin && (
                                                <Typography variant="caption" className="fw-300" component="div">
                                                    Bin Location: {p.line?.whBin}
                                                </Typography>
                                            )}
                                            {!_.isEmpty(p.line?.text) && (
                                                <Box mt={0.5}>
                                                    <Typography variant="caption" component="div">
                                                        <FAIcon
                                                            icon="exclamation-circle"
                                                            type="solid"
                                                            className="textError fw-400 mr-1"
                                                            size={13.5}
                                                        />
                                                        {p.line?.text}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                        {p.status !== null && (
                                            <Grid item align="right">
                                                <Tooltip title="Modify Check">
                                                    <IconButton onClick={() => this.handleDeployCheckIssue(p.idx)}>
                                                        <FAIcon icon="pencil" size={15} button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {(p.line?.bulky === 1 || p.line?.heavy === 1) && (
                                        <Grid item xs={12}>
                                            <Box pt={2} pl={1} pr={1}>
                                                <Alert severity="warning" className="mb-1">
                                                    <strong>Please handle with care!</strong>
                                                    <br />
                                                    This product is{" "}
                                                    {p.line?.bulky === 1
                                                        ? `large${p.line?.heavy === 1 ? ` and ` : ``}`
                                                        : ``}
                                                    {p.line?.heavy === 1 ? `heavy` : ``}
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                </Box>
                                {p.line?.card_order && (
                                    <>
                                        <Box p={3}>
                                            <StackTable
                                                elevation={0}
                                                padding={0}
                                                heading={
                                                    <Alert severity="warning" className="mb-1">
                                                        <strong>Card Order - CARD00{p.line?.card_order?.co_ref}</strong>
                                                    </Alert>
                                                }
                                                vertical
                                                rows={[
                                                    {
                                                        heading: "Customer Order No",
                                                        value: p.line?.card_order?.co_cust_ref ?? false,
                                                    },
                                                    {
                                                        heading: "Customer Site",
                                                        value: `${p.line?.card_order?.site?.site_code ?? ""} - ${
                                                            p.line?.card_order?.site?.site_description ?? ""
                                                        }`,
                                                    },
                                                    {
                                                        heading: "Card Format",
                                                        value: p.line?.card_order?.format?.format_name ?? false,
                                                    },
                                                    {
                                                        heading: "Card Type",
                                                        value: p.line?.card_order?.type?.type_name ?? false,
                                                    },
                                                    {
                                                        heading: "Programmer Type",
                                                        value: p.line?.card_order?.programmer?.programmer_name ?? false,
                                                    },
                                                    {
                                                        heading: "Internal Range Start",
                                                        value: p.line?.card_order?.co_internal_range_start ?? false,
                                                    },
                                                    {
                                                        heading: "Internal Range End",
                                                        value: p.line?.card_order?.co_internal_range_end ?? false,
                                                    },
                                                    {
                                                        heading: "External Range Start",
                                                        value: p.line?.card_order?.co_external_range_start ?? false,
                                                    },
                                                    {
                                                        heading: "External Range End",
                                                        value: p.line?.card_order?.co_external_range_end ?? false,
                                                    },
                                                    {
                                                        heading: "Card Order Notes",
                                                        value: p.line?.card_order?.co_notes ?? false,
                                                    },
                                                ]}
                                            />
                                        </Box>
                                    </>
                                )}
                                {(p.status === null && (
                                    <>
                                        <Box className="content-light-grey" p={1}>
                                            <Grid container spacing={3} alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography variant={isPDA ? "body1" : "h5"} className="fw-400">
                                                        QTY: {p.line.quantity}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={6}>
                                                            <AppButton
                                                                small
                                                                back
                                                                className="btn btn-error"
                                                                icon="exclamation-triangle"
                                                                text="Issue"
                                                                onClick={() => this.handleDeployCheckIssue(p.idx)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <AppButton
                                                                small
                                                                back
                                                                className="btn btn-success"
                                                                icon="check"
                                                                text="Confirm"
                                                                onClick={() => this.handleCheckOkay(p.idx)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )) || (
                                    <Alert severity={p.status === "success" ? `success` : `error`}>
                                        <Typography variant="body2" component="div" className="fw-500">
                                            Despatching {p.qtyTotal} / {p.qtyExpected}
                                            {/* Check {p.status === 'success' ? `Successful` : `Issue`} */}
                                        </Typography>
                                        {/* <Typography variant="body1" component="div" className="fw-300">
                                                Quantity: {p.qtyTotal}/{p.qtyExpected}
                                            </Typography> */}
                                    </Alert>
                                )}
                            </Card>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Grid item xs={12} align="center">
                            <Typography variant="body1" align="center" className="fw-400" paragraph>
                                Check Notes
                            </Typography>
                            {_.some(checkStatus, (el) => el.pickComplete === 0) && (
                                <Box mb={2} align="left">
                                    <Alert severity="warning" variant="outlined">
                                        <strong>Delivery On-Hold</strong>
                                        <br />
                                        Sales Admin will be notified to resolve availability issues with this delivery.
                                    </Alert>
                                </Box>
                            )}
                            <Paper elevation={0}>
                                <Textarea
                                    margin="none"
                                    onChange={(e) => this.handleNotes("check", e?.target?.value ?? "")}
                                    value={notes.check}
                                    variant="outlined"
                                    rows={1}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <AppButton
                            className="btn btn-success"
                            disabled={_.some(checkStatus, (el) => el.status === null)}
                            onClick={() =>
                                deployConfirmation(`Are you sure you want to continue?`, this.handleCheckComplete)
                            }
                            text="Confirm Check"
                            icon="check"
                            primary
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AppButton
                            back
                            className="btn btn-error"
                            onClick={() =>
                                deployConfirmation(`Are you sure you want to restart this check?`, () =>
                                    this.loadComponentData(true)
                                )
                            }
                            text="Restart Check"
                            icon="redo"
                        />
                    </Grid>
                </Grid>
            )
        );
    };
}

export default Check;
