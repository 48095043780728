import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { ADDRESS_LOOKUP_API_KEY, ADDRESS_LOOKUP_URL } from 'Constants';
import _ from 'lodash';
import React, { Component } from 'react';

const styles = theme => ({
    buttons: {        
        textTransform: 'none',
        textAlign: 'left',
        flex: 1,
    },
    address: {
        border: '1px solid #ddd',
        borderRadius: '3px',
        '&:hover': {
            backgroundColor: '#fafafa',
            cursor: 'pointer'
        }
    }
});

class AddressLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addressList: [],
            postcode: '',
            dialogOpen: false,
            addressOptionOne: '',
            addressOptionTwo: ''
        };
    }

    addressLookup = () => {
        axios.get(`${ADDRESS_LOOKUP_URL}/?publicApiKey=${ADDRESS_LOOKUP_API_KEY}&postcode=${this.state.postcode.replace(/ /g, '')}`)
        .then((result) => {            
            if(!result.data.invalidPostcode && !result.data.missingPostcode && result.data.addresses){
                let addressList = _.map(result.data.addresses, (el, idx) => {
                    return _.assign({
                        value: el,
                        label: `${el.line1}, ${el.line2 && el.line2 + ','}  ${el.town}, ${el.postcode}`
                    });
                });
                this.setState({
                    addressList: addressList
                });
            }
            else {
                this.setState({
                    addressList: [],
                });
            }
        });
    }

    handleChange = name => (e) => {
        const { value } = e.target;
        this.setState({
            [e.target.name]: value
        },
        () => {
            if(name === 'postcode'){
                if(value.length > 3){
                    this.addressLookup();
                }
                else {
                    this.setState({
                        addressList: [],
                    });
                    this.props.handleSelectedAddress(null);
                }
            }
        });
    }

    handleSelectChange = e => {
        let address = _.find(this.state.addressList, {'label': e && e.label });

        if(address && (address.value.line2 || address.value.line3)){
            this.setState({
                dialogOpen: true,
                addressOptionOne:   address.value.line1 + "\n\r" +
                                    (address.value.line2 ? address.value.line2 + "\n\r" : '') +
                                    (address.value.line3 ? address.value.line3  + "\n\r" : '') +
                                    (address.value.town ? address.value.town + "\n\r" : '') +
                                    (address.value.county ? address.value.county + "\n\r" : '') +
                                    (address.value.postcode),
                addressOptionTwo:   (address.value.line2 ? address.value.line2 + "\n\r" : '') +
                                    (address.value.line3 ? address.value.line3  + "\n\r" : '') +
                                    (address.value.town ? address.value.town + "\n\r" : '') +
                                    (address.value.county ? address.value.county + "\n\r" : '') +
                                    (address.value.postcode),
                address: address
            });
        }
        else {
            this.props.handleSelectedAddress(address);
            if(this.props.selectAddressCallBack) {
                this.props.selectAddressCallBack();
            }
        } 
    }

    selectAddress = addressOption => (e) => {
       this.setState({
            dialogOpen: false,
            address: {
                ...this.state.address,
                value: {
                    ...this.state.address.value,
                    line1: addressOption === 'addressOptionTwo' ? this.state.address.value.line2 : this.state.address.value.line1,
                    line2: addressOption === 'addressOptionTwo' ? this.state.address.value.line3 : this.state.address.value.line2,
                    line3: addressOption === 'addressOptionTwo' ? '' : this.state.address.value.line3,
                }
            }
        }, 
        () => {
            this.props.handleSelectedAddress(this.state.address);
            if(this.props.selectAddressCallBack) {
                this.props.selectAddressCallBack();
            }
        });
    }
    
    render() {
        const { inlineLayout, value, classes, manualEntry, visible } = this.props;
        return (
            <>
                {(visible && (
                    <Grid container spacing={1}>
                        <Grid item xs={inlineLayout ? 4 : 12}>
                            <TextField
                                name="postcode"
                                label="Enter a postcode to find an address *"
                                value={this.state.postcode}
                                onChange={this.handleChange('postcode')}
                                margin="none"
                                fullWidth
                            />
                            {this.state.addressList.length > 0 && (
                                <AutoCompleteSelect
                                    value={value}
                                    options={this.state.addressList} 
                                    label={this.state.addressList.length > 0 ? 'Please select an address*' : '-'}
                                    onChange={this.handleSelectChange}
                                    noDefaultSort
                                />
                            )}
                            {/* <FormControl> */}
                                {manualEntry}
                            {/* </FormControl> */}
                        </Grid>
                    </Grid>
                )) || (
                    <Grid container spacing={1}>
                        <Grid item xs={inlineLayout ? 4 : 12}>                           
                            {/* <FormControl> */}
                                {manualEntry}
                            {/* </FormControl>                           */}
                        </Grid>
                    </Grid>
                )}
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    open={this.state.dialogOpen}
                    fullWidth={true}
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography variant="h6">
                            Preferred Address
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box pb={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <FAIcon icon="info-circle" size={12.5} />
                                        Please select the preferred address from the options below
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} onClick={this.selectAddress('addressOptionOne')}>
                                    <Box className={classes.address} p={3} m={0.5}>
                                        {this.state.addressOptionOne.split('\n').map((item, key) => (
                                            <Typography variant="caption" component="div" className="fw-400" key={key}>{item}</Typography>
                                        ))}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} onClick={this.selectAddress('addressOptionTwo')}>
                                    <Box className={classes.address} p={3} m={0.5}>
                                        {this.state.addressOptionTwo.split('\n').map((item, key) => (
                                            <Typography variant="caption" component="div" className="fw-400" key={key}>{item}</Typography>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default withStyles(styles)(AddressLookup);