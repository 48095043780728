import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

const ThresholdChip = ({threshold}) => (
    <Chip 
        avatar={<Avatar style={{width: 8, height: 8, backgroundColor: threshold.qt_color, marginLeft: 5}} />} 
        label={threshold.qt_label} 
        variant="default" 
        size="small"
        style={{backgroundColor: `rgba(${ parseInt(threshold.qt_color.substring(1,3),16) }, ${ parseInt(threshold.qt_color.substring(3,5),16) }, ${ parseInt(threshold.qt_color.substring(5,7),16) }, 0.1)`, color: '#000', fontWeight: 400}} 
    />
)

export default ThresholdChip;