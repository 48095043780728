import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = () => ({
    formData: {
        supplier: '',
        text: '',
        color: ''
    },
    formErrors: [],
    isLoading: true,
    suppliers: []
})

const SupplierPriceListInfoForm = ({allData, callback, data}) => {

    const [state,setState] = useState(initialState());

    const {
        formData,
        formErrors,
        isLoading,
        suppliers
    } = state;

    const dispatch = useDispatch();

    const createOrUpdate = () => {
        API.post('/suppliers/priceListInformation', formData)
        .then(res => {
            if(res?.data) {
                if(res.data.errors) {
                    setState({
                        ...state,
                        formErrors: formatValidationErrors(res.data.errors)
                    });
                } else {
                    setState({
                        ...initialState(),
                    });
                    dispatch(deploySnackBar(`success`, `You have successfully ${data ? 'updated' : 'added'} supplier price list information`));
                    dispatch(closeDialog())
                    callback();
                }
            }
        })
    }

    const handleChange = e => {
        setState({
            ...state,
            formData: {
                ...state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    useEffect(() => {
        if(data) {
            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    supplier: data.spli_supp_id,
                    text: data.spli_text,
                    color: data.spli_color
                }
            }));
        }
        API.get("/suppliers/all", { params: { forList: true } }).then((res) => {
            if (res?.data) {
                let suppliers = _.map(res?.data ?? [], supp => (
                    _.assign({
                        label: supp.l,
                        value: supp.v
                    })
                ))
                setState(state => ({
                    ...state,
                    isLoading: false,
                    suppliers
                }));
            }
        });
        /* eslint-disable-next-line */
    }, [])

    let existingData = null;
    if(!data && formData.supplier !== '') {
        existingData = _.find(allData, el => el.supplier?.supp_id === formData.supplier);
    }

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <AutoCompleteSelect
                    name="supplier"
                    label="Supplier: *"
                    value={formData.supplier}
                    error={formErrors && formErrors['supplier'] && true}
                    helperText={formErrors && formErrors['supplier']}
                    onChange={v => handleChange({ target: { name: 'supplier', value: v?.value ?? '' } })}
                    fullWidth
                    options={suppliers}
                    noClear
                    disabled={data}
                />
                {existingData && (
                    <Box className="content-light-error" p={2} mb={2}>
                        <Typography variant="caption" component="div" className="fw-400">
                            This supplier already has price list information, this will overwrite the existing information
                        </Typography>
                        <Typography variant="caption" component="div">
                            <i>
                                {existingData.spli_text}
                            </i>
                        </Typography>
                    </Box>
                )}
                <TextField
                    name="text"
                    label="Text:"
                    value={formData.text}
                    error={formErrors && formErrors['text'] && true}
                    helperText={formErrors && formErrors['text']}
                    onChange={handleChange}
                    fullWidth
                />
                <Typography variant="caption" component="div" className="fw-400 textError pb-1" >
                    This text will be visible to external companies
                </Typography>
                <FormControlLabel
                    checked={formData.color}
                    control={<Checkbox name="color" value="1" color="primary" onChange={() => handleChange({ target: { name: 'color', value: formData.color === 1 ? 0 : 1 } })} />}
                    label="Display text in red"
                />
                <div className='buttonRow'>
                    <Button 
                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to ${data ? 'update' : 'add'} this supplier price list information?`, () => createOrUpdate()))}
                        variant="text" 
                        color="primary" 
                        disabled={formData.supplier === ''}
                    >
                        <FAIcon icon="check" size={15} button />
                        {data ? `Update` : `Add`}
                    </Button>
                </div>
            </form>
        )
    )

}

export default SupplierPriceListInfoForm;