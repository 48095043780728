import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AsyncSelect from 'Components/Common/Selects/AsyncSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AttachmentFileUpload from 'Components/Common/Inputs/AttachmentFileUpload';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import WYSIWYG from 'Components/Common/WYSIWYG/WYSIWYG';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { getPresignedUrl } from 'Functions/S3Functions';
import { getFormDataHook } from 'Functions/FormFunctions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { Grid } from '@material-ui/core';

const EmailDialog = ({defaultFormData, type, action, id, callback, confirmation, resendStaffId, send, success, noSnackBar, noCancel, noDialog, noSubmit, posPdf, posFileName, parentSubmit, readOnly, scrollToTop, stateSubmit, onStateChange}) => {

    const dispatch = useDispatch();
    const staff = useSelector(state => state.notifications.status);
    const loggedInStaff = useSelector(state => state.staffAuth.staff);
    const loggedInEmail = loggedInStaff.email ?? '';
    const loggedInTeams = loggedInStaff?.teams ?? [];
    const initialState = {
        async: true,
        defaultOptions: [],
        emailTemplates: [],
        formData: {
            type: '',
            id: '',
            emailTemplate: 0,
            emailFrom: {
                label: loggedInEmail,
                value: loggedInEmail
            },
            emailTo: '',
            emailCc: [],
            emailBcc: [],
            emailSubject: '',
            emailText: '',
            emailPlainText: '',
            attachments: [],
            attachedManually: [],
        },
        formErrors: {},
        key: uuidv4(),
        hideTemplateOptions: false,
        isLoading: true,
        wysiwygEditor: EditorState.createEmpty()
    };

    let [oldState, setOldState] = useState(initialState);
    let [state, setState] = useState(initialState);
    let [senders, setSenders] = useState([]);

    const {
        hideTemplateOptions,
        emailTemplates,
        formErrors,
        formData, 
        formData:{attachedManually, attachments, emailTemplate, emailFrom, emailTo, emailCc, emailBcc, emailSubject, emailPlainText}, 
        key,
        isLoading,
        wysiwygEditor
    } = state;

    const getAttachmentPreview = (type, id) => {

        switch(type) {

            case "proforma": 
                API.get(`/sales/${id}/proforma/pdf`)
                .then(res => {
                    if(res?.data?.pdf) {
                        pdfFromBase64(res.data.pdf, res.data.pdfName);
                    }
                })
            break;

            case "pos": 
                if(posPdf) {
                    pdfFromBase64(posPdf, posFileName);
                }
            break;

            default: 
                /* No action */
            break;

        }

    }

    const handleAttachmentToggle = idx => {
        let newAttachments = attachments;
        newAttachments[idx].send = newAttachments[idx].send === 1 ? 0 : 1;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                attachments: newAttachments
            }
        }));
    }
        
    const handleManualAttachments = files => {
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                key: uuidv4(),
                attachedManually: Array.prototype.slice.call(attachedManually).concat(Array.prototype.slice.call(files))
            }
        }))
    }

    const handleManualAttachmentRemove = idx => {
        let files = Array.from(attachedManually);
        files.splice(idx, 1);
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                attachedManually: files
            }, 
            key: uuidv4()
        }))
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }
    
    const handleBodyChange = wysiwygEditor => {

        const   emailText = draftToHtml(convertToRaw(wysiwygEditor.getCurrentContent())),
                emailPlainText = wysiwygEditor.getCurrentContent().getPlainText();
                
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                emailText,
                emailPlainText
            },
            wysiwygEditor
        }));
    }

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData:{
                ...formData,
                [fieldName]: selectedOption
            }
        }));
    }

    const handleEmailDialogClose = () => dispatch(closeDialog());

    const handleSubmit = () => dispatch(deployConfirmation(confirmation ?? 'Are you sure you want to send this email?', () => parentSubmit ? parentSubmit(() => submit()) : (stateSubmit ? stateSubmit(state.formData) : submit())));

    const loadEmailOptions = (searchString, callback, selectRef) => {
        API.get('/smartSearch', 
            {
                props: {
                    cancellation: true,
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'email'
                }
            }
        )
        .then(res => {
            
            setState(state => ({
                ...state,
                defaultOptions: res?.data?.results ?? []
            }))

            if(res?.data) {
                callback(res?.data?.results ?? [])
            } else {
                callback([])
            }

            if(selectRef.current.focus) {
                selectRef.current.focus()
            }

        })
    }

    const submit = () => {
        setState(state => ({...state, isLoading: true}));
        API.post(`/email`, getFormDataHook({...action, ...formData}))
        .then(result => {
            if(result?.data?.errors) {
                setState(state => ({
                    ...state, 
                    formErrors: formatValidationErrors(result.data.errors), 
                    isLoading: false
                }));
            } else if(result.data.success) {
                if(callback) {
                    callback();
                }
                if(!noDialog) {
                    handleEmailDialogClose();
                }
                if(!noSnackBar) {
                    dispatch(deploySnackBar('success', success ?? 'Your e-mail message has been successfully sent'));
                }
            }
        });
    }

    useEffect(() => {
        if(!_.isEmpty(emailTemplates) && emailTemplate !== null) {
            let idx = _.findIndex(emailTemplates, el => el.value === emailTemplate?.value);
            if(idx !== -1) {
                const description = htmlToDraft(emailTemplates[idx].text);
                const descriptionState = ContentState.createFromBlockArray(description.contentBlocks);
                let emailSubject = idx === 0 ? '' : emailTemplates[idx].subject;
                const   wysiwygEditor   = descriptionState ? EditorState.createWithContent(descriptionState) : state.wysiwygEditor,
                        emailPlainText  = wysiwygEditor.getCurrentContent().getPlainText();
                let attachments = [];
                _.each(emailTemplates[idx].att, at => {
                    attachments.push(at)
                })
                setState(state => ({
                    ...state,
                    formData: {
                        ...state.formData,
                        emailFrom: idx === 0 ? '' : { label: emailTemplates[idx].sender, value: emailTemplates[idx].sender},
                        emailText: emailTemplates[idx].text,
                        emailPlainText,
                        emailSubject,
                        attachments
                    },
                    wysiwygEditor,
                }));
            }
        }
/* eslint-disable-next-line */
    }, [emailTemplate, emailTemplates, type]);
    
    useEffect(() => {
        let params = { 
            forEmail: true 
        }
        
        if(type === 'customer' || type === 'supplier') {
            params = {
                ...params,
                type,
                customer: type === "customer" ? id : null,
                supplier: type === "supplier" ? id : null
            }
        }

        Promise.all([
            API.get('/email/templates', { params }),
            API.get('/email/data', { params: { id, action, type } })
        ])
        .then(([emailRes, email]) => {

            let async           = true,
                defaultOptions  = [],
                descriptionState = null,
                formData        = {
                    type,
                    id
                };

            if(email?.data && emailRes.data) {

                if(!_.isEmpty(email.data.attachments)) {
                    let attachments = email.data.attachments;
                    _.each(attachments, (at, idx) => {
                        attachments[idx].send = parseInt(at?.send ?? 1)
                    })
                    formData = {
                        ...formData,
                        attachments
                    }
                }

                if(!_.isEmpty(email.data.contacts)) {
                    async = false;
                    defaultOptions = email.data.contacts;
                }

                formData = {
                    ...formData,
                    emailTemplate: email.data.template.id
                }

                if(!_.isEmpty(email.data.template.to)) {
                    formData = {
                        ...formData,
                        emailTo: {
                            label: email.data.template.to,
                            value: email.data.template.to,
                        }
                    }
                }

                if(email.data?.template?.id > 0) {
                    const description = htmlToDraft(email.data.template.text);
                    descriptionState = ContentState.createFromBlockArray(description.contentBlocks);
                    formData = {
                        ...formData,
                        emailFrom: {
                            label: email.data.template.from,
                            value: email.data.template.from
                        },
                        emailText: email.data.template.text,
                        emailPlainText: email.data.template.text
                    }

                }
                
            }

            let emailTemplates = _.map(emailRes?.data ?? [], el => (
                _.assign({
                    value: el.et_id,
                    label: el.et_name,
                    subject: el.et_subject,
                    text: el.et_text,
                    sender: el.et_send_from,
                    type: el.et_type,
                    att: el.attachments
                })
            ));

            const defaultText = `<br /><p><strong>${staff[loggedInStaff?.id ?? 0]?.name ?? ''} - ${staff[loggedInStaff?.id ?? 0]?.role ?? ''}</strong> - Tel: ${staff[loggedInStaff?.id ?? 0]?.tel ?? ''}</p>`;
            emailTemplates.unshift(
                {
                    value: 0,
                    label: 'None',
                    text: defaultText
                }
            )

            if(email.data?.template?.id === 0) {
                const text = htmlToDraft(defaultText)
                descriptionState = ContentState.createFromBlockArray(text.contentBlocks);
            }

            const   wysiwygEditor   = descriptionState ? EditorState.createWithContent(descriptionState) : state.wysiwygEditor,
                    emailPlainText  = wysiwygEditor.getCurrentContent().getPlainText();

            if(defaultFormData?.emailText) {
                setState(state => ({
                    ...state,
                    async,
                    defaultOptions,
                    emailTemplates,
                    hideTemplateOptions: true,
                    isLoading: false
                }));
            } else {
                setState(state => ({
                    ...state,
                    async,
                    defaultOptions,
                    emailTemplates,
                    hideTemplateOptions: email?.data?.template?.id > 0 ? true : false ?? false,
                    formData: {
                        ...state.formData,
                        ...formData,
                        emailPlainText
                    },
                    wysiwygEditor,
                    isLoading: false
                }));
            }

        });
/* eslint-disable-next-line */
    }, [id, action, type]);

    useEffect(() => {
        if(defaultFormData) {

            let description         = null,
                descriptionState    = null,
                attachments         = defaultFormData?.attachments ?? [];

            if(!_.isEmpty(attachments)) {
                _.each(attachments, (at, idx) => {
                    attachments[idx].send = parseInt(at?.send ?? 1)
                })
            }
            
            if(defaultFormData.emailText) {
                description = htmlToDraft(defaultFormData.emailText);
                descriptionState = ContentState.createFromBlockArray(description.contentBlocks);
            }
            
            const   wysiwygEditor   = descriptionState ? EditorState.createWithContent(descriptionState) : state.wysiwygEditor,
                    emailPlainText  = wysiwygEditor.getCurrentContent().getPlainText();
            
            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    ...defaultFormData,
                    emailPlainText,
                    attachments
                },
                wysiwygEditor
            }))
        }//eslint-disable-next-line
    }, []);

    useEffect(() => {
        API.get(`/teams`, { params: { forMe: true }})
        .then(res => {
            if(res?.data) {

                const options = [];
                
                _.each(res?.data, team => {
                    if(loggedInTeams.includes(team.team_name)) {
                        options.push({
                            label: team.team_email,
                            value: team.team_email
                        })
                    }
                })

                if(loggedInEmail) {
                    options.push({
                        label: loggedInEmail,
                        value: loggedInEmail
                    })
                }
                setSenders(options)
            }
        })/* eslint-disable-next-line*/
    }, [loggedInEmail])
    
    useEffect(() => {
        if(!isLoading) {
            if(scrollToTop) {
                scrollToTop();
            }
        }
    }, [isLoading, scrollToTop]);

    useEffect(() => {
        if(onStateChange) {
            if(JSON.stringify(state.formData) !== JSON.stringify(oldState)) {
                setOldState(state.formData);
                onStateChange(state.formData);
            }
        }/*eslint-disable-next-line*/
    }, [state.formData])
    
    return (
        <>
            {(isLoading && (
                <LoadingCircle />
            )) || (
                <>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3} className="pt-2">
                            <Grid item xs={12} lg={6} className="pt-0 pb-0">
                                <AutoCompleteSelect
                                    options={senders} 
                                    label='From *'
                                    value={emailFrom}
                                    onChange={handleSelectChange('emailFrom')}
                                    error={formErrors && formErrors['emailFrom'] && true}
                                    errorText={formErrors && formErrors['emailFrom'] && formErrors['emailFrom']}
                                    noClear
                                />
                            </Grid>
                            {(state.async && (
                                <>
                                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                                        <AsyncSelect
                                            apiCall={_.debounce(loadEmailOptions, 250)}
                                            creatable
                                            creatableMessage={userInput => `Send to e-mail address: ${userInput}`}
                                            defaultOptions={state.defaultOptions}
                                            error={formErrors && formErrors['emailTo'] && true}
                                            errorText={formErrors?.['emailTo']}
                                            label='To *'
                                            onChange={handleSelectChange('emailTo')}
                                            value={emailTo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                                        <AsyncSelect
                                            apiCall={_.debounce(loadEmailOptions, 250)}
                                            creatable
                                            creatableMessage={userInput => `Send to e-mail address: ${userInput}`}
                                            defaultOptions={state.defaultOptions}
                                            error={formErrors?.['emailCc'] && true}
                                            errorText={formErrors?.['emailCc']}
                                            isMulti
                                            onChange={handleSelectChange('emailCc')}
                                            label='CC'
                                            value={emailCc}
                                            shrink
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                                        <AsyncSelect
                                            apiCall={_.debounce(loadEmailOptions, 250)}
                                            creatable
                                            creatableMessage={userInput => `Send to e-mail address: ${userInput}`}
                                            defaultOptions={state.defaultOptions}
                                            error={formErrors?.['emailBcc'] && true}
                                            errorText={formErrors?.['emailBcc']}
                                            isMulti
                                            onChange={handleSelectChange('emailBcc')}
                                            label='BCC'
                                            value={emailBcc}
                                            shrink
                                        />
                                    </Grid>
                                </>
                            )) || (
                                <>
                                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                                        <FormControl fullWidth>
                                            <AutoCompleteSelect
                                                creatable
                                                creatableMessage={userInput => `Send to e-mail address: ${userInput}`}
                                                error={formErrors?.['emailTo'] && true}
                                                errorText={formErrors?.['emailTo']}
                                                isGrouped
                                                label='To *'
                                                onChange={handleSelectChange('emailTo')}
                                                options={state.defaultOptions}
                                                value={emailTo}
                                                cleanValue
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                                        <FormControl fullWidth>
                                            <AutoCompleteMultiSelect
                                                creatable
                                                creatableMessage={userInput => `Send to e-mail address: ${userInput}`}
                                                error={formErrors?.['emailCc'] && true}
                                                errorText={formErrors?.['emailCc']}
                                                isGrouped
                                                label='CC'
                                                onChange={handleSelectChange('emailCc')}
                                                options={state.defaultOptions}
                                                value={emailCc}
                                                cleanValue
                                                shrink
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6} className="pt-0 pb-0">
                                        <FormControl fullWidth>
                                            <AutoCompleteMultiSelect
                                                creatable
                                                creatableMessage={userInput => `Send to e-mail address: ${userInput}`}
                                                error={formErrors?.['emailBcc'] && true}
                                                errorText={formErrors?.['emailBcc']}
                                                isGrouped
                                                label='BCC'
                                                onChange={handleSelectChange('emailBcc')}
                                                options={state.defaultOptions}
                                                value={emailBcc}
                                                cleanValue
                                                shrink
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            {!hideTemplateOptions && (
                                <Grid item xs={12} lg={6} className="pt-0 pb-0">
                                    <AutoCompleteSelect
                                        options={emailTemplates} 
                                        label='Email Template'
                                        value={emailTemplate}
                                        onChange={handleSelectChange('emailTemplate')}
                                        error={formErrors && formErrors['emailTemplate'] && true}
                                        errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                                        noClear
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} lg={!hideTemplateOptions ? 6 : 12} className="pt-0 pb-0">
                                <TextField
                                    name="emailSubject"
                                    label="Subject *"
                                    value={emailSubject}
                                    error={formErrors && formErrors['emailSubject']}
                                    errorText={formErrors && formErrors['emailSubject'] && formErrors['emailSubject']}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Box mb={2} pt={1.5}>
                            <Box mb={1}>
                                <Typography variant="caption" color="textSecondary">
                                    Attachments
                                </Typography>
                            </Box>
                            <List dense disablePadding className="content-light-grey">
                                {!_.isEmpty(attachments) && _.map(attachments, (file, idx) => (
                                    <ListItem 
                                        key={idx} 
                                        button
                                        className="pt-1 pb-1"
                                        onClick={() => attachments[idx].send !== 0 ? handleAttachmentToggle(idx) : dispatch(deployConfirmation(`Are you sure you want to attach ${file.name}?`, () => handleAttachmentToggle(idx)))}
                                    >
                                        <ListItemAvatar>
                                            <FAIcon
                                                icon={file.icon}
                                                type="light"
                                                size={20}
                                                style={{color: file.iconColor ?? undefined}}
                                                className={file.icon === 'file-pdf' ? 'textError' : undefined}
                                                noMargin
                                            />
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={file.name} 
                                        />
                                        <Box position='absolute' right={24}>
                                            <ListItemSecondaryAction>
                                                <Tooltip title="Preview File">
                                                    <IconButton onClick={e => { e.stopPropagation(); if(!_.isEmpty(file.key)) { getPresignedUrl(file.key) } else { getAttachmentPreview(file.type, file.id) } }}>
                                                        <FAIcon icon="binoculars" size={15} button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </Box>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                color="primary"
                                                edge="end"
                                                onChange={() => attachments[idx].send !== 0 ? handleAttachmentToggle(idx) : dispatch(deployConfirmation(`Are you sure you want to attach ${file.name}?`, () => handleAttachmentToggle(idx)))}
                                                checked={attachments[idx].send !== 0}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                                {!_.isEmpty(attachedManually) && _.map(Array.from(attachedManually), (file, idx) => (
                                    <ListItem 
                                        key={idx} 
                                        button
                                        className="pt-1 pb-1"
                                    >
                                        <ListItemAvatar>
                                            <FAIcon
                                                icon='file'
                                                type="light"
                                                size={20}
                                                noMargin
                                            />
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={file.name}
                                        />
                                        <ListItemSecondaryAction>
                                            <Tooltip title="Remove File">
                                                <IconButton onClick={() => dispatch(deployConfirmation(`Are you sure you want to remove ${file.name}?`, () => handleManualAttachmentRemove(idx)))}>
                                                    <FAIcon icon="trash-alt" size={15} button noMargin />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                                <AttachmentFileUpload
                                    id="attachedManually"
                                    key={key}
                                    name="attachedManually[]"
                                    files={formData.manualAttachments}
                                    onChange={handleManualAttachments}
                                />
                            </List>
                        </Box>
                        {(resendStaffId === 0 && (
                            <Box className="content-light-grey" p={3}>
                                <Box className="content-light-white" p={3}>
                                    <Typography variant="body2" paragraph>
                                        {(!_.isEmpty(formData.emailText) && (
                                            <div 
                                                dangerouslySetInnerHTML={{__html: formData.emailText}}
                                            />
                                        )) || (
                                            <em>This e-mail has no body</em>
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        )) || (
                            readOnly ? (
                                <Box className="content-light-grey" p={3}>
                                    <Box className="content-light-white">
                                        <Box p={3} pt={2}>
                                            <div
                                                dangerouslySetInnerHTML={{__html: formData.emailText}}
                                            /> 
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <WYSIWYG
                                    editorState={wysiwygEditor}
                                    margin='none'
                                    onChange={v => handleBodyChange(v)}
                                />
                            )
                        )}
                    </form>
                    <div className="buttonRow">
                        {!noCancel && (
                            <Button 
                                onClick={() => dispatch(deployConfirmation(`Are you sure you want to discard this email?`, handleEmailDialogClose))} 
                                variant="text" 
                            >
                                <FAIcon icon="times" size={15} button />
                                Discard
                            </Button>
                        )}
                        {!noSubmit && (
                            <Button 
                                onClick={handleSubmit} 
                                disabled={(typeof emailTo === 'object' ? _.isEmpty(emailTo?.label) : _.isEmpty(emailTo)) || _.isEmpty(emailSubject) || _.isEmpty(emailFrom) || _.isEmpty(emailPlainText)}
                                variant="text"                                 
                                color="primary" 
                                >
                                <FAIcon icon="check" size={15} button />
                                {send ?? 'Send E-mail'}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
}

export default EmailDialog;