import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { useDispatch, useSelector } from "react-redux";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { DialAdornment } from "Functions/MiscFunctions";
import API from "API";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { closeDialog } from "Redux/Actions/Dialog/Dialog";
import _ from "lodash";
import React, { useState } from "react";
import Textarea from "Components/Common/Inputs/Textarea";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";

const ReviewCredit = ({ callback, id, request }) => {

    const staffMember = useSelector(state => state.staffAuth.staff.name);

    const dispatch = useDispatch();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const handleViewCustomer = () => {
        history.push(`/customers/${request.customer.cust_id}`)
        dispatch(closeDialog())
    }

    const handleDecline = () => {
        setIsLoading(true);
        API.put(`/customers/onboarding/${id}/decline`)
        .then(res => {
            if(res?.data) {
                dispatch(deploySnackBar(`success`, `You have successfully declined this credit application`))
                callback?.();
            }
            setIsLoading(false);
        })
    }

    const handlePrint = () => {
        const title = `Portal - ${request.trr_type === 'ONLINE_ACCOUNT' ? 'Online Account Request' : request.trr_type === 'CREDIT_ACCOUNT' ? 'Credit Account Application' : 'Trade Account Application'}`;
        window.open('/print/' + btoa(JSON.stringify({...request, title, staffMember})), 'Form', 'width=1280,height=720');
    }

    const isLimitedCompany = request.data.type === 'Limited Company';

    const diffRegisteredAddress = request.data.ltdRegOffice.postcode === '' ? false : 
                                        request.data.address1 !== request.data.ltdRegOffice.address1 ||
                                        request.data.address2 !== request.data.ltdRegOffice.address2 || 
                                        request.data.address3 !== request.data.ltdRegOffice.address3 ||
                                        request.data.town !== request.data.ltdRegOffice.town || 
                                        request.data.county !== request.data.ltdRegOffice.county || 
                                        request.data.postcode !== request.data.ltdRegOffice.postcode || 
                                        request.data.country !== request.data.ltdRegOffice.country;

    const registeredAddress =   _.filter(diffRegisteredAddress ? [
                                    request.data.ltdRegOffice.address1,
                                    request.data.ltdRegOffice.address2,
                                    request.data.ltdRegOffice.address3,
                                    request.data.ltdRegOffice.town,
                                    request.data.ltdRegOffice.county,
                                    request.data.ltdRegOffice.postcode,
                                    request.data.ltdRegOffice.country
                                ] : [
                                    request.data.address1,
                                    request.data.address2,
                                    request.data.address3,
                                    request.data.town,
                                    request.data.county,
                                    request.data.postcode,
                                    request.data.country
                                ]).join(', ');

    return (
        isLoading ? (
            <LoadingCircle />
        ) : (
            <Box mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            User Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            label="First Name"
                            value={request.data.firstName || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            label="Surname"
                            value={request.data.lastName || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            label="Position"
                            value={request.data.position || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Email Address"
                            value={request.data.email || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Phone Number"
                            value={request.data.phone || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                startAdornment: <DialAdornment number={request.data.phone || '-'} />
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Company Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Company Name"
                            value={request.data.companyName || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Trading Name"
                            value={request.data.tradingName || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Main Phone Number"
                            value={request.data.companyPhone || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Main E-mail Address"
                            value={request.data.companyEmail || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Legal Entity"
                            value={request.data.type || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="VAT Number"
                            value={request.data.vatNumber || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Address Line 1"
                            value={request.data.address1 || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Address Line 2"
                            value={request.data.address2 || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Address Line 3"
                            value={request.data.address3 || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Town"
                            value={request.data.town || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="County"
                            value={request.data.county || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Postcode"
                            value={request.data.postcode || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Country"
                            value={request.data.country || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label="Website"
                            value={request.data.website || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    {/* <Grid item xs={12} lg={6}>
                        <TextField
                            label="Type of Business"
                            value={request.data.ctype || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid> */}
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label={`How many engineers does your company have?`}
                            value={request.data.engineerCount || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Accounts Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Accounts E-mail Address"
                            value={request.data.accountsEmail || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            label="Accounts Phone Number"
                            value={request.data.accountsPhone || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    {isLimitedCompany ? (
                        <>
                            <Grid item xs={12} className="pb-0 pt-0">
                                <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                                    Limited Company Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label="Company Registration Number"
                                    value={request.data.ltdRegNumber || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Registered Address"
                                    value={registeredAddress}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Credit Facility
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            label="Requested Credit Limit"
                            value={request.data.creditLimit ? `£${request.data.creditLimit}` : 'Not Specified'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Directors / Proprietors
                        </Typography>
                    </Grid>
                    {_.map(request.data.directors, (director, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Director #${index + 1}: First Name`}
                                    value={director.firstName || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Director #${index + 1}: Surname`}
                                    value={director.lastName || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Director #${index + 1}: Position`}
                                    value={director.position || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Director #${index + 1}: Email Address`}
                                    value={director.email || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Director #${index + 1}: Phone Number`}
                                    value={director.phone || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <DialAdornment number={director.phone || '-'} />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Director #${index + 1}: Subscribe to weekly emails?`}
                                    value={director.newsletter ? 'Yes' : 'No'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <TextField
                                    label={`Director #${index + 1}: Previous Relationship with Advanced Access?`}
                                    value={director.prevRelationship || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            {director.prevRelationship === 'Yes' ? (
                                <Grid item xs={12} lg={12}>
                                    <Textarea
                                        label={`Director #${index + 1}: Previous Relationship Details`}
                                        value={director.prevRelationshipDetails || '-'}
                                        disabled={request.trr_actioned}
                                        variant="outlined"
                                        fullWidth
                                        minRows={3}
                                    />
                                </Grid>
                            ) : null}
                        </React.Fragment>
                    ))}
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Accounts Contacts
                        </Typography>
                    </Grid>
                    {request.data.accountsContacts.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography variant="body2" color={request.trr_actioned ? `textSecondary` : ``} className="pb-1">
                                No accounts contacts have been added.
                            </Typography>
                        </Grid>
                    ) : null}
                    {_.map(request.data.accountsContacts, (contact, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: First Name`}
                                    value={contact.firstName || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Surname`}
                                    value={contact.lastName || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Position`}
                                    value={contact.position || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Email Address`}
                                    value={contact.email || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Phone Number`}
                                    value={contact.phone || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <DialAdornment number={contact.phone || '-'} />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Subscribe to weekly emails?`}
                                    value={contact.newsletter ? 'Yes' : 'No'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Additional Contact Information
                        </Typography>
                    </Grid>
                    {request.data.contacts.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography variant="body2" color={request.trr_actioned ? `textSecondary` : ``} className="pb-1">
                                No contacts have been added.
                            </Typography>
                        </Grid>
                    ) : null}
                    {_.map(request.data.contacts, (contact, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: First Name`}
                                    value={contact.firstName || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Surname`}
                                    value={contact.lastName || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Position`}
                                    value={contact.position || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Email Address`}
                                    value={contact.email || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Phone Number`}
                                    value={contact.phone || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <DialAdornment number={contact.phone || '-'} />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label={`Contact #${index + 1}: Subscribe to weekly emails?`}
                                    value={contact.newsletter ? 'Yes' : 'No'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Business Connections
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={`Are you, or any other director(s)/proprietor(s), associated with any other companies?`}
                            value={request.data.hasConnections || '-'}
                            disabled={request.trr_actioned}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    {request.data.hasConnections === 'Yes' ? _.map(request.data.connections, (company, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    label={`Associated Company #${index + 1}: Company Name`}
                                    value={company.company || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    label={`Associated Company #${index + 1}: Previous trading relationship?`}
                                    value={company.traded || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </React.Fragment>
                    )) : null}
                    <Grid item xs={12} className="pb-0 pt-0">
                        <Typography variant="h6" color="primary" gutterBottom className="pt-1">
                            Trade References
                        </Typography>
                    </Grid>
                    {request.data.references.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography variant="body2" color={request.trr_actioned ? `textSecondary` : ``} className="pb-1">
                                No references have been added.
                            </Typography>
                        </Grid>
                    ) : null}
                    {_.map(request.data.references, (reference, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    label={`Trade Reference #${index + 1}: Company Name`}
                                    value={reference.company || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    label={`Trade Reference #${index + 1}: Period Of Trading`}
                                    value={reference.periodOfTrading || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={`Trade Reference #${index + 1}: Address`}
                                    value={reference.address || '-'}
                                    disabled={request.trr_actioned}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </React.Fragment>
                    ))}
                    {!request.trr_actioned ? (
                        <>
                            <Grid item xs={12}>
                                <Box className="content-light-grey" p={2}>
                                    To approve this credit application, please open a Credit Account using the normal process and this request will be automatically accepted.<br />
                                    To decline this credit application, please click the button below.
                                </Box>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={12}>
                        <Box mt={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Grid container>
                                        <Grid item>
                                            <Button
                                                onClick={() => dispatch(closeDialog())}
                                                variant="text"
                                            >
                                                <FAIcon icon="times-circle" size={15} button />
                                                Close
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={() => handlePrint()}
                                                variant="text"
                                            >
                                                <FAIcon icon="print" size={15} button />
                                                Print
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!request.trr_actioned ? (
                                    <>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item>
                                                    <Button
                                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to decline this credit application?`, handleDecline))}
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="exclamation-circle" size={15} button />
                                                        Decline
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : null}
                                <Grid item>
                                    <Grid container>
                                        <Grid item>
                                            <Button
                                                onClick={() => handleViewCustomer()}
                                                variant="text"
                                            >
                                                <FAIcon icon="binoculars" size={15} button />
                                                View Customer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    )
}

export default ReviewCredit;