import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import DiscountTemplates from 'Components/Settings/Discounts/DiscountTemplates/DiscountTemplates';
import FocShipping from 'Components/Settings/Discounts/FocShipping/FocShipping';
import ProductDiscountExclusions from 'Components/Settings/Discounts/ProductDiscountExclusions/ProductDiscountExclusions';

import API from 'API';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from 'Constants';
import { handleTabChange, TabPanel } from 'Functions/MiscFunctions';

const initialState = {
    access: {
        focShipping: false,
        productExclusion: false,
        discountTemplates: false
    },
    currentTab: 0,
    isLoading: true,
    tabs: {
        0: "Customer Discount Templates",
        1: "Customer Free Delivery Bulk Management",
        2: "Product Discount Exclusion Rules",
    },
    tabText: {
        0: "Create and manage product discount templates for customers",
        1: "Manage existing customer free delivery discount order thresholds (based on Net Order Value)",
        2: "Manage rules for automatically applying the product 'No Sales Discount' setting",
    }
}

class SettingsDiscounts extends React.Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Settings", "Discounts"])
        this.loadComponentData()
    }

    loadComponentData = () => {
        Promise.all([
            API.access('settings-discounts:foc-shipping'), 
            API.access('settings-discounts:exclusions'), 
            API.access('settings-discounts:templates'),
        ])
        .then(([focShipping, productExclusion, discountTemplates]) => {     
            this.setState({
                access: {
                    ...this.state.access,
                    focShipping: (focShipping.data && focShipping.data.has_access) || false,
                    productExclusion: (productExclusion.data && productExclusion.data.has_access) || false,
                    discountTemplates: (discountTemplates.data && discountTemplates.data.has_access) || false,
                },
                isLoading: false
            }, () => {
                if(!this.state.access.focShipping && !this.state.access.productExclusion && !this.state.access.discountTemplates) {
                    this.props.history.push('/')
                }
            })
        })
    }

    render() {
        const { classes, scrollToTop, ui } = this.props;
        const { access, currentTab, isLoading, tabs, tabText } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabs[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        {access.discountTemplates && (
                                            <TabPanel value={currentTab} index={0}>
                                                <DiscountTemplates />
                                            </TabPanel>
                                        )}
                                        {access.focShipping && (
                                            <TabPanel value={currentTab} index={1}>
                                                <FocShipping scrollToTop={scrollToTop} />
                                            </TabPanel>
                                        )}
                                        {access.productExclusion && (
                                            <TabPanel value={currentTab} index={2}>
                                                <ProductDiscountExclusions scrollToTop={scrollToTop} />
                                            </TabPanel>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {access.discountTemplates && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Discount Templates" />
                                )}  
                                {access.focShipping && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Free Customer Delivery" />
                                )}     
                                {access.productExclusion && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Product Discount Exclusions" />
                                )}  
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps, null)(withStyles(ViewPage)(SettingsDiscounts));