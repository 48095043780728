import React from 'react';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar'; 
import Box from '@material-ui/core/Box'; 
import Button from '@material-ui/core/Button'; 
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import ProductStockChip from 'Components/Common/Chips/ProductStockChip';
import logo from 'Assets/Images/no-img.jpg';
import OrderedSearchSelector from 'Components/RMA/RMAForm/OrderedSearchSelector';
import ProductSearchSelector from 'Components/Products/Misc/ProductSearchSelector';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { CLOUDFRONT_URL } from 'Constants';
import { handleDateChange, handleFloat, handleSelectChange } from 'Functions/FormFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const initialState = () => ({
    formData: {
        productId: '',
        productName: '',
        productCode: '',
        quantity: '',
        cost: '',
        price: '',
        subTotal: '',
        restockFee: '',
        restockFeeTotal: '',
        restockPercent: '',
        total: '',
        originalQuantity: null,
        image: '',
        productData: {},
        option: {},
        orderRef: '',
        deliveryDate: null,
        serial: '',
        rmaNotes: '',
        sysNotes: '',
        action: '',
        reason: '',
        odp: '',
        od: ''
    },
    formErrors: [],
    isLoading: false,
    key: uuidv4()
})

class Product extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleDateChange = handleDateChange.bind(this);
        this.handleFloat = handleFloat.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.quantityInput = React.createRef();
        this.productSelect = React.createRef();
    }

    componentDidMount = () => {
        if(!this.props.formData) {
            if(this.props.productId) { 
                if(this.props.productState) {
                    this.handleProductState(this.props.productState);
                } else {
                    this.handleProductChange(true, this.props.productId);
                }
            } else {
                this.productSelect?.current?.focus?.()
            }
        } else {
            this.setState({
                formData: this.props.formData
            })
        }
    }

    handleCalculateTotals = field => {

        const { formData } = this.state;
        const { carriage } = this.props;
        let restockFee, restockPercent, subTotal, total, restockFeeTotal, price = parseFloat(formData.price), qty = parseInt(formData.quantity);

        switch(field) {
            case "price":
                restockPercent = parseFloat(formData.restockPercent).toFixed(2);
                restockFee = ((price / 100) * restockPercent).toFixed(2);
                subTotal = (price - restockFee).toFixed(2);
            break;
            case "restockFee":
                restockFee = parseFloat(formData.restockFee);
                restockPercent = ((restockFee / price) * 100).toFixed(2);
                subTotal = (price - restockFee).toFixed(2);
            break;
            case "restockPercent":
                restockPercent = parseFloat(formData.restockPercent);
                restockFee = ((price / 100) * restockPercent).toFixed(2);
                subTotal = (price - restockFee).toFixed(2);
            break;
            case "quantity":
                restockPercent = parseFloat(formData.restockPercent).toFixed(2);
                restockFee = ((price / 100) * restockPercent).toFixed(2);
                subTotal = (price - restockFee).toFixed(2);
            break;
            case "subTotal":
                if(carriage) {
                    restockFee = "0.00";
                    restockPercent = "0.00";
                    subTotal = parseFloat(formData.subTotal);
                    price = subTotal;
                } else {
                    subTotal = parseFloat(formData.subTotal);
                    restockFee = (price - subTotal).toFixed(2);
                    restockPercent = ((restockFee / price) * 100).toFixed(2);
                }
            break;
            default:
                subTotal = parseFloat(formData.subTotal);
                restockFee = (price - subTotal).toFixed(2);
                restockPercent = ((restockFee / price) * 100).toFixed(2);
            break;
        }

        total = (qty * (price - restockFee)).toFixed(2);
        
        if(parseFloat(restockFee) < 0) {
            restockFee = "0.00";
            restockFeeTotal = "0.00";
            restockPercent = "0.00";
        } else {
            restockFeeTotal = (qty * restockFee).toFixed(2); 
        }

        this.setState({
            formData: {
                ...this.state.formData,
                price,
                restockFee,
                restockFeeTotal,
                restockPercent,
                subTotal,    
                total
            }
        })

    }

    handleProductChange = (isId, option) => {
        const { customer }  = this.props;

        let initialFormData = {},
            productId       = isId ? (option?.value ?? option) : option?.prd;

        if(!isId) {
            initialFormData = {
                deliveryDate: option?.date ?? null,
                orderRef: option?.ref ?? '',
                od: option?.od ?? '',
                odp: option?.odp ?? '',
                quantity: option?.qty ?? '',
                cost: option?.cost ?? '',
                price: option?.paid ?? '',
                originalQuantity: option?.qty ?? '',
            };
        }

        if(productId) {
            this.setState({
                isLoading: true
            }, () => {
                API.get(`/products/${productId}`, 
                    {
                        params: {
                            forCustomer: customer,
                            forRMAForm: true
                        } 
                    }
                )
                .then(res => {
                    if(res?.data) {
                        this.handleProductState(res.data, option, false, initialFormData);
                    }
                })
            })
        }
    }

    handleProductState = (data, option, closeDialog, initialFormData = {}) => {
        this.setState({
            formData: {
                ...this.state.formData,
                productId: data.product_id,
                productName: data.product_name,
                productCode: data.product_code || '-',
                quantity: this.props.formData ? this.props.formData.quantity : "",
                cost: parseFloat(data.product_cost).toFixed(2),
                price: parseFloat(data.product_price).toFixed(2),
                subTotal: "0.00",
                restockFee: "0.00",
                restockFeeTotal: "0.00", 
                restockPercent: this.props.defaultReStockingFee ? parseFloat(this.props.defaultReStockingFee).toFixed(2) : "0.00",
                action: this.props.defaultAction ?? '',
                reason: this.props.defaultReason ?? '',
                total: "0.00",
                image: data.product_img,
                productData: data,
                option,
                ...initialFormData
            },
            isLoading: false
        }, () => {
            this.props.handleUnsavedLine?.();
            if(!this.props.manual && !this.props.carriage) {
                this.handleCalculateTotals('quantity');
            }
            if(!this.props.formData) {
                setTimeout(() => this.quantityInput.current.focus(), 50);
            }
        });
    }

    handleCarriageChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                productId: -1,
                productName: e?.target?.value,
                productCode: 'Carriage',
                quantity: 1,
                cost: "0.00",
                price: "0.00",
                subTotal: "0.00",
                restockFee: "0.00",
                restockFeeTotal: "0.00", 
                restockPercent: "0.00",
                action: "",
                reason: "",
                total: "0.00",
                image: null,
                productData: null,
            },
            isLoading: false
        });
    }

    handleChange = e => {

        const   { target: { name, value, type } } = e;

        if(type === "number" && value < 0) return;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: name === "quantity" ? parseInt(parseFloat(value).toFixed(0)) : (type === "number" ? parseFloat(value) : value)
            }
        }, () => {
            type === "number" && this.handleCalculateTotals(name);
        })

    }

    handleDeployNotes = () => {
        const {
            formData,
        } = this.state;
        this.props.deployDialog(
            <ViewNotes hideDeleteButton notes={formData?.productData?.notes} inline button={<Button variant="contained" color="primary" onClick={this.props.closeDialog}><FAIcon icon="check" buttonPrimary />Acknowledged</Button>} />, false, `${formData?.productCode}`, "standard", "sm", false, true
        )
    }

    handleReset = () => {
        this.setState({
            ...initialState(),
            access: this.state.access
        })
    }

    handleSubmit = (e, qtyOverride = false) => {

        if(e) {
            e.preventDefault();
        }

        const {
            deployConfirmation
        } = this.props;
        
        const { 
            formData,
        } = this.state;

        let formErrors = {};

        if(formData.productId === "") {
            formErrors.productId = "Product cannot be left blank"
        }

        if(formData.quantity === "" || isNaN(formData.quantity)) {
            formErrors.quantity = "Quantity cannot be left blank"
        }

        if(!formErrors.quantity && formData.quantity < 1 ) {
            formErrors.quantity = "Quantity must be at least 1"
        }

        if(formData.price === "" || isNaN(formData.price)) {
            formErrors.price = "Price cannot be left blank"
        }

        if(!formErrors.price && formData.price <= 0 ) {
            formErrors.price = "Price must be above 0"
        }
        
        if(formData.originalQuantity && !this.props.formData) {
            let warn = false, ex = _.filter(this.props.productRows, el => el.odp === formData?.option?.odp), total = (parseInt(_.sumBy(ex, 'quantity')) + parseInt(formData.quantity));
            if(!_.isEmpty(ex)) {
                if(total > formData.originalQuantity) {
                    warn = true;
                }
            }
            if(warn && !qtyOverride) {
                deployConfirmation(<><Typography variant="body2" className="fw-400">The return quantity of {total} exceeds the non-returned quantity of {formData.originalQuantity}</Typography><Typography variant="body2">Are you sure you want to continue?</Typography></>, () => this.handleSubmit(null, true))
                return;
            }
        }

        if(_.isEmpty(formErrors)) {

            this.setState({
                ...initialState(),
                key: uuidv4(),
                access: this.state.access
            }, () => {

                this.props.handleSubmit(formData);

                if(this.props.formData) {
                    this.props.deploySnackBar("success", formData.productName + " has been updated");
                } else {
                    this.props.deploySnackBar("success", formData.productName + " has been added");
                }

                if(this.props.inDialog) {
                    this.props.closeDialog()
                } 

                if(!this.props.inDialog) {
                    this.productSelect?.current?.focus?.()
                }

            })

        } else {
            this.setState({
                formErrors
            })
        }
        
    }

    render() {
        const   { carriage, classes, customer, inDialog, manual, productRows, borderRadius, width}  = this.props,
                { formData, formErrors, isLoading, key }                                            = this.state,
                notes                                                                               = _.size(formData?.productData?.notes),
                productSelected                                                                     = !_.isEmpty(formData.productName);

        return (
            <Paper 
                elevation={inDialog && 0} 
                className={!inDialog ? `pt-1 pl-1` : 'pt-3'}
                style={{
                    borderRadius: borderRadius ?? undefined,
                    minHeight: !inDialog ? 95 : 120, 
                    width: width ?? undefined
                }}
            >
                <form onSubmit={this.handleSubmit} noValidate>
                    {carriage && (
                        <>
                            <Typography variant="h6" className="fw-400" gutterBottom>
                                Carriage Credit
                            </Typography>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <FAIcon type="light" icon="exclamation-triangle" className="textError" size={13.5} noMargin /> 
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" className="fw-400">
                                        Please ensure you only enter carriage related credits as these will post under the 4095 - Distribution and Carriage nominal code
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {!carriage && manual && (
                        <>
                            <Typography variant="h6" className="fw-400" gutterBottom>
                                Manual Line
                            </Typography>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <FAIcon type="light" icon="exclamation-triangle" className="textWarning" size={13.5} noMargin /> 
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" className="fw-400">
                                        Please note that the amount paid and delivery details will not be pre-filled - please check the credit amount carefully
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Table style={{marginTop: !productSelected ? 12  : (manual || carriage ? 12 : undefined)}}>
                        <TableBody>
                            <TableRow className='pl-1'>
                                <TableCell className={classes.td} style={{width: carriage ? '25%' : '42.5%'}}>
                                    {(isLoading && (
                                        <CircularProgress 
                                            size={40}
                                        />
                                    )) || (!carriage && productSelected && (
                                        <>
                                            <Grid container spacing={1} alignItems='center' style={{marginTop: -12}}>
                                                <Grid item className='pr-2'>
                                                    <Avatar src={formData.image ? `${CLOUDFRONT_URL}${formData.image}` : logo} style={{overflow:'visible', width: 75, height: 75}} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Box pb={2} pt={2}>
                                                        <Grid container alignItems="flex-end">
                                                            <Grid item>
                                                                <Typography variant="body2" className="fw-400">
                                                                    {formData.productCode}
                                                                </Typography>
                                                            </Grid>
                                                            {!inDialog && ( 
                                                                <Grid item>
                                                                    <Tooltip title="Modify Product">
                                                                        <IconButton 
                                                                            className='ml-1'
                                                                            onClick={this.handleReset}
                                                                            size="small"
                                                                        >
                                                                            <FAIcon type="light" icon="times-circle" size={12.5} noMargin button />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" component="div">
                                                                    {formData.productName}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" component="div">
                                                                    {`${formData.productData?.stock_count ?? 0} In Stock | ${formData.productData?.stock_allocated_count ?? 0} Allocated | ${formData.productData?.stock_available_count ?? 0} Available | ${formData.productData?.stock_on_order_count ?? 0} On Order | ${formData.productData?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Box pt={1}>
                                                                    <ProductStockChip 
                                                                        qty={formData.quantity}
                                                                        rep={formData.productData?.product_rep_product_id}
                                                                        status={formData.productData?.product_status}
                                                                        stocked={formData.productData?.product_stock_item}
                                                                        stockAvailable={formData.productData?.stock_available_count}
                                                                        stockOnOrderAvailable={(formData.productData?.stock_on_order_count - formData.productData?.stock_on_order_allocated_count)}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            {formData.productData?.product_check_price === 1 && (
                                                                <Grid item>
                                                                    <Box pt={1}>
                                                                        <InsightChip 
                                                                            background="#FF5722"
                                                                            color="#fff"
                                                                            marginRight={8}
                                                                            paddingLeft={8}
                                                                            icon="exclamation-triangle"
                                                                            label='Check Price and Availability'
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                            {notes > 0 && (
                                                                <Grid item>
                                                                    <Box pt={1}>
                                                                        <InsightChip 
                                                                            background={'#4CAF50'}
                                                                            color="#fff"
                                                                            marginRight={8}
                                                                            paddingLeft={8}
                                                                            icon="comment-alt-lines"
                                                                            label={notes}
                                                                            onClick={this.handleDeployNotes}
                                                                            size={11}
                                                                            tooltip='View Notes'
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )) || (
                                        (!carriage && !manual && (
                                            <OrderedSearchSelector
                                                key={key}
                                                selectRef={this.productSelect}
                                                disableProductRows={productRows}
                                                handleProductChange={d => this.handleProductChange(false, d)}
                                                forCustomer={customer}
                                            />
                                        )) || (!carriage && (
                                            <ProductSearchSelector
                                                key={key}
                                                selectRef={this.productSelect}
                                                handleAction={id => this.handleProductChange(true, id)}
                                                forCustomer={customer}
                                            />
                                        )) || (
                                            <TextField
                                                name="quantity"
                                                label="Description"
                                                value={formData.productName}
                                                onChange={this.handleCarriageChange}
                                                error={formErrors?.['productName'] && true}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        )
                                    )}
                                </TableCell>
                                {!carriage && (
                                    <>
                                        <TableCell className={classes.td} style={{width: '7%'}}>
                                            <TextField
                                                inputRef={this.quantityInput}
                                                name="quantity"
                                                label="Quantity"
                                                value={formData.quantity}
                                                onChange={this.handleChange}
                                                error={formErrors?.['quantity'] && true}
                                                type="number"
                                                fullWidth
                                                inputProps={{
                                                    min: 0
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                disabled={!productSelected}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{width: '8%'}}>
                                            <TextField
                                                name="price"
                                                label="Amount Paid"
                                                value={formData.price}
                                                type="number"
                                                onChange={this.handleChange}
                                                onBlur={this.handleFloat}
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                                inputProps={{
                                                    min: 0,
                                                    step: 0.01
                                                }}
                                                disabled={!productSelected}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{width: '8%'}}>
                                            <TextField
                                                name="restockPercent"
                                                label="Restock Fee"
                                                value={formData.restockPercent}
                                                type="number"
                                                onChange={this.handleChange}
                                                onBlur={this.handleFloat}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                }}
                                                inputProp={{
                                                    min: 0,
                                                    max: 100,
                                                    step: 0.50
                                                }}
                                                disabled={!productSelected}
                                                className="mb-0"
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{width: '8%'}}>
                                            <TextField
                                                name="restockFee"
                                                label="Restock Fee"
                                                value={formData.restockFee}
                                                type="number"
                                                onChange={this.handleChange}
                                                onBlur={this.handleFloat}
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                                disabled={!productSelected}
                                            />
                                        </TableCell>
                                    </>
                                )}
                                <TableCell className={classes.td} style={{width: '10%'}}>
                                    <TextField
                                        name="subTotal"
                                        label={carriage ? `Net Credit Amount` : `Credit Amount`}
                                        value={formData.subTotal}
                                        onChange={this.handleChange}
                                        onBlur={this.handleFloat}
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                        }}
                                        disabled={!productSelected}
                                    />
                                </TableCell>
                                {(carriage && (
                                    <>
                                        <TableCell className={classes.td} style={{width: '20%'}}>
                                            <TextField
                                                name="rmaNotes"
                                                label="Notes (These will be visible to the customer)"
                                                value={formData.rmaNotes}
                                                onChange={this.handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment>
                                                            <FAIcon icon='comment-alt' size={15} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                disabled={!productSelected}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{width: '20%'}}>
                                            <AutoCompleteSelect
                                                disabled={!productSelected}
                                                label={`Reason *`}
                                                options={[
                                                    {
                                                        label: "Refund Return Carriage",
                                                        value: "CARRIAGE_REFUND"
                                                    },
                                                    {
                                                        label: "Refund Order Carriage (Courier)",
                                                        value: "CARRIAGE_ISSUE"
                                                    }
                                                ]} 
                                                value={formData.reason}                                
                                                onChange={v => this.handleSelectChange('reason', v)}
                                                noDefaultSort
                                                adornment="exclamation-triangle"
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{width: '20%'}}>
                                            <AutoCompleteSelect
                                                disabled={!productSelected}
                                                label={`Action *`}
                                                options={[
                                                    {
                                                        label: "COA - Credit On Account",
                                                        value: "COA"
                                                    },
                                                    {
                                                        label: "CCR - Credit Card Refund",
                                                        value: "CCR"
                                                    }
                                                ]} 
                                                value={formData.action}                                
                                                onChange={v => this.handleSelectChange('action', v)}
                                                noDefaultSort
                                                adornment="check"
                                            />
                                        </TableCell>
                                    </>
                                )) || (
                                    <TableCell className={classes.td} style={{width: '8%'}}>
                                        <TextField
                                            name="total"
                                            label="Net Credit Total"
                                            value={formData.total}
                                            type="number"
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                            }}
                                            disabled
                                        />
                                    </TableCell>
                                )}
                                <TableCell className={classes.td}>
                                    <Box minWidth={128} pl={1}>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Tooltip title={`${this.props.formData ? "Apply Changes" : "Add line to RMA"}`}>
                                                    <IconButton 
                                                        disabled={formData.action === "" || formData.reason === "" || !formData.action|| !formData.reason || formData.price === "" || formData.price < 0 || isNaN(formData.total) || parseFloat(formData.total).toFixed(2) === "0.00" || formData.productId === "" || formData.quantity === "" || formData.quantity < 0 }
                                                        type='submit'
                                                        variant="outlined"
                                                        color="primary"
                                                    >
                                                        <FAIcon 
                                                            type="thin" 
                                                            size={25} 
                                                            icon='check-circle' 
                                                            disabled={formData.action === "" || formData.reason === "" || !formData.action|| !formData.reason || formData.price === "" || formData.price < 0 || isNaN(formData.total) || parseFloat(formData.total).toFixed(2) === "0.00" || formData.productId === "" || formData.quantity === "" || formData.quantity < 0 }
                                                            className={formData.action === "" || formData.reason === "" || !formData.action|| !formData.reason || formData.price === "" || formData.price < 0 || isNaN(formData.total) || parseFloat(formData.total).toFixed(2) === "0.00" || formData.productId === "" || formData.quantity === "" || formData.quantity < 0  ? undefined : 'textDefault'}
                                                            button 
                                                            noMargin 
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {inDialog && (
                                                <Grid item>
                                                    <Tooltip title="Discard Changes">
                                                        <IconButton 
                                                            onClick={this.props.closeDialog}
                                                        >
                                                            <FAIcon type="thin" size={25} className="textDefault" icon='times-circle' button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </TableCell>
                            </TableRow>  
                        </TableBody>
                    </Table>
                    {!carriage && productSelected && (
                        <Table>
                            <TableBody>
                                <TableRow className='pl-1'>
                                    <TableCell className={classes.td} style={{width: '10%'}}>
                                        <Box mt={-2}>
                                            <DatePicker
                                                name="deliveryDate"
                                                type="date"
                                                label={`Delivery Date`}
                                                onChange={date => this.handleDateChange("deliveryDate", date)}
                                                value={formData.deliveryDate}
                                                autoOk={true}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell className={classes.td} style={{width: '10%'}}>
                                        <TextField
                                            name="orderRef"
                                            label="Sales Order"
                                            value={formData.orderRef}
                                            onChange={this.handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment>
                                                        <FAIcon icon='hashtag' size={15} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={!productSelected}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.td} style={{width: '10%'}}>
                                        <TextField
                                            name="serial"
                                            label="Serial"
                                            value={formData.serial}
                                            onChange={this.handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment>
                                                        <FAIcon icon='hashtag' size={15} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={!productSelected}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.td} style={{width: '30%'}}>
                                        <TextField
                                            name="rmaNotes"
                                            label="Notes (These will be visible to the customer)"
                                            value={formData.rmaNotes}
                                            onChange={this.handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment>
                                                        <FAIcon icon='comment-alt' size={15} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={!productSelected}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.td} style={{width: '20%'}}>
                                        <AutoCompleteSelect
                                            disabled={!productSelected}
                                            label={`Reason *`}
                                            options={[
                                                {
                                                    label: "Damaged",
                                                    value: "DAMAGED"
                                                },
                                                {
                                                    label: "Faulty Goods",
                                                    value: "FAULTY_GOODS"
                                                },
                                                {
                                                    label: "No Longer Required",
                                                    value: "NO_LONGER_REQUIRED"
                                                },
                                                {
                                                    label: "Wrong Goods Ordered",
                                                    value: "WRONG_GOODS_ORDERED"
                                                },
                                                {
                                                    label: "Wrong Goods Supplied (AA)",
                                                    value: "WRONG_GOODS_SUPPLIED_AA"
                                                },
                                                {
                                                    label: "Wrong Goods Supplied (Supplier)",
                                                    value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                                                },
                                            ]} 
                                            value={formData.reason}                                
                                            onChange={v => this.handleSelectChange('reason', v)}
                                            noDefaultSort
                                            adornment="exclamation-triangle"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.td} style={{width: '20%'}}>
                                        <AutoCompleteSelect
                                            disabled={!productSelected}
                                            label={`Action *`}
                                            options={[
                                                {
                                                    label: "COA - Credit On Account",
                                                    value: "COA"
                                                },
                                                {
                                                    label: "CCR - Credit Card Refund",
                                                    value: "CCR"
                                                },
                                                {
                                                    label: "QFR - Quote For Repair",
                                                    value: "QFR"
                                                },
                                                {
                                                    label: "RPL - Replace",
                                                    value: "RPL"
                                                },
                                                {
                                                    label: "COA/RPL - Credit On Account & Replace",
                                                    value: "COA_RPL"
                                                },
                                                {
                                                    label: "CCR/RPL - Credit Card Refund & Replace",
                                                    value: "CCR_RPL"
                                                },
                                                {
                                                    label: "TBC - To Be Confirmed",
                                                    value: "TBC"
                                                }
                                            ]} 
                                            value={formData.action}                                
                                            onChange={v => this.handleSelectChange('action', v)}
                                            noDefaultSort
                                            adornment="check"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.td} style={{width: 60}}>
                                        <Box width={50} />
                                    </TableCell>
                                </TableRow>  
                            </TableBody>
                        </Table>
                    )}
                </form>
            </Paper>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disabled = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disabled)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(Product);