import React from 'react';
import _ from 'lodash';

import Badge from '@material-ui/core/Badge';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const Tabs = ({classes, currentTab, disableCustomerTab, disablePreview, disabled, document, formData, handleTabChange, placeOrder}) => (
    <MuiTabs
        color="primary"
        onChange={handleTabChange}
        orientation="vertical"
        TabIndicatorProps={{
            style: {
                display: 'none'
            }
        }}
        value={currentTab}
        variant="scrollable"
    >
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={0} value={0} disabled={placeOrder || disableCustomerTab || disabled} label="Customer Selection" />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={1} value={1} disabled={disabled || !formData.customer} label={`${document} Lines`} />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={2} value={2} disabled={disabled || !formData.customer} label={`${document} Details`} />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={3} value={3} disabled={disabled || !formData.customer} label="Internal Notes" />
        {document === 'Order' && (            
            <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} disabled={disabled || _.every(formData.productRows, el => !el?.productData?.cDb || el?.productData?.cDb === 0)} label={<Badge color="primary" className="badge" badgeContent={_.size(_.filter(formData.productRows, el => el?.productData?.cDb === 1 && el?.cdbSite === ''))} showZero={false}>Card Orders</Badge>} />
        )}
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={5} value={5} disabled={disabled || disablePreview || ((!formData.customer || _.isEmpty(_.filter(formData.productRows, el => el.productId > 0 || el.bundleData)) || (document === 'Order' && !_.isEmpty(_.filter(formData.productRows, el => el?.productData?.cDb === 1 && el.cdbSite === '')))))} label="Preview" />
    </MuiTabs>
)

export default Tabs;