import React from 'react';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { useSelector } from 'react-redux';
import { isManagement } from 'Functions/AuthFunctions';

const Billing = ({
    access,
    contactList, 
    customer, 
    document, 
    formData, 
    formErrors, 
    handleChange, 
    handleDateChange, 
    handleNull, 
    handleSelectChange,
    order,
    paymentTermsList,
    ifm
}) => {
    
    const staffList = useSelector((state) => state.notifications.staffList);
    const staffName = useSelector((state) => state?.staffAuth?.staff?.name);

    const customAddress = !_.isEmpty(formData.customerAddressCustom);
    const address = [];
    if(customAddress) {
        _.each([formData.customerAddressCustom.addressOne,
            formData.customerAddressCustom.addressTwo,
            formData.customerAddressCustom.addressThree,
            formData.customerAddressCustom.town,
            formData.customerAddressCustom.county,
            formData.customerAddressCustom.postcode], row => {
            if(row?.length > 0) {
                address.push(row)
            }
        })
    } else {
        if(customer?.data?.addresses) {
            const idx = _.findIndex(customer.data.addresses, el => el.i === formData.customerAddress);
            if(idx !== -1) {
                _.each([
                    customer.data.addresses[idx].l1,
                    customer.data.addresses[idx].l2,
                    customer.data.addresses[idx].l3,
                    customer.data.addresses[idx].to,
                    customer.data.addresses[idx].cc,
                    customer.data.addresses[idx].po,
                    customer.data.addresses[idx].co], row => {
                    if(row?.length) {
                        address.push(row)
                    }
                })
            }
        }
    }

    const required = document === 'Order'

    const isManagementTeam = isManagement();

    return (
        <PaddedPaper>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" paragraph>
                        {document} Details
                    </Typography>
                </Grid>
                {document === "Quote" && (
                    <>
                        <Grid item xs={12}>
                            <AutoCompleteSelect
                                label='Salesperson'
                                placeholder={staffName}
                                options={staffList}
                                value={formData.salesPerson}
                                onChange={v => handleChange({ target: { name: 'salesPerson', value: v?.value ?? '' } })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth className="mb-2">
                                <DatePicker
                                    name="orderExpectedDate"
                                    type="date"
                                    label={`Expected Order Date`}
                                    onChange={date => handleDateChange("orderExpectedDate", date)}
                                    value={formData.orderExpectedDate}
                                    endAdornment={(
                                        <InputAdornment>
                                            <Tooltip title="Clear Date">
                                                <IconButton onClick={(e) => { e?.stopPropagation?.(); handleNull("orderExpectedDate");}} size="small">
                                                    <FAIcon icon="times" size={11} type="solid" disabled noMargin button />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )}
                                    autoOk={true}
                                    disablePast={true}
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <TextField
                        name="docRef"
                        label={`${customer?.data?.cust_po_required === 1 ? `Customer PO Number` : `Customer Reference`}${required ? ' *' : ''}`}
                        value={formData.docRef}
                        onChange={handleChange}
                        margin="none"
                        error={formErrors && formErrors['docRef'] && true}
                        helperText={formErrors && formErrors['docRef']}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="hashtag" size="small" noMargin /></InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid> 
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <AutoCompleteSelect 
                            label={`Payment Terms *`}
                            options={paymentTermsList} 
                            value={formData.paymentTerms}                                
                            onChange={val => handleSelectChange('paymentTerms', val)}
                            error={formErrors && formErrors['paymentTerms'] && true}
                            errorText={formErrors && formErrors['paymentTerms']}
                            disabled={(!isManagementTeam && customer?.data?.cust_monitored) || !access?.changePaymentTerms || (order && (order?.order_status !== 'Awaiting Approval' && order?.order_status !== 'Awaiting Authorisation' && order?.order_status !== 'Awaiting Payment'))}
                            noClear
                            adornment='coins'
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <AutoCompleteSelect 
                            label={`${document} Contact ${required ? `*` : ``}`}
                            options={contactList} 
                            value={formData.customerContact}                                
                            onChange={val => handleSelectChange('customerContact', val)}
                            error={formErrors && formErrors['customerContact'] && true}
                            errorText={formErrors && formErrors['customerContact']}
                            creatable
                            adornment='user'
                        />
                    </FormControl>
                </Grid>
                {!_.isEmpty(address) && (
                    <Grid item xs={12}>
                        <FormLabel>
                            <Typography variant="caption">
                                 Billing Address
                            </Typography>
                        </FormLabel>
                        {_.map(address, (line, idx) => (
                            <Typography variant="body2" key={idx} gutterBottom>
                                {line}
                            </Typography>
                        ))}
                    </Grid>
                )}
            </Grid>
        </PaddedPaper>
    )
}

export default Billing;