import React  from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import API from 'API';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AccountsDashboard from 'Components/Dashboard/Types/Accounts.js';
import ManagementDashboard from 'Components/Dashboard/Types/Management.js';
import MarketingDashboard from 'Components/Dashboard/Types/Marketing.js';
import MySalesDashboard from 'Components/Dashboard/Types/MySales.js';
import SalesDashboard from 'Components/Dashboard/Types/Sales.js';
import SalesAdminDashboard from 'Components/Dashboard/Types/SalesAdmin.js';
import WarehouseDashboard from 'Components/Dashboard/Types/Warehouse.js';

import Avatar from 'Components/Common/Avatars/Avatar';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewPage from 'Components/Common/Styles/ViewPage';
import Weather from 'Components/Common/Weather/Weather';
import { handleTabChange } from 'Functions/MiscFunctions';
import { isCrushedIce, isManagement } from 'Functions/AuthFunctions.js';
import { VIEW_PAGE_OFFSET, VIEW_PAGE_APP_BAR_HEIGHT } from 'Constants';

const initialState = () => ({
    currentTab: 0,
    isLoading: false,
    datetime: moment().format("LLLL"),
    weather: null
})

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState();
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this);
        this.interval = setInterval(() => {
            let datetime = moment().format("LLLL");
            if(datetime !== this.state.datetime) {
                this.setState({ 
                    datetime 
                })
            }
        }, 5000);
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Dashboard']);
        this.getLocationWeather();
    }

    componentWillUnmount = () => {
        clearInterval(this.interval);
    }

    getLocationWeather = () => {
        API.get('/weather')
        .then(res => {
            if(res?.data) {
                const weather = res.data;
                this.setState({
                    weather
                })
            }
        })
    }
    
    renderManagementDashboard = () => {
        const { pageTitle } = this.props;
        const { currentTab } = this.state;
        switch(currentTab) {
            case 0:
                return <ManagementDashboard pageTitle={pageTitle} />
            case 1:
                return <AccountsDashboard pageTitle={pageTitle} />
            case 2:
                return <MarketingDashboard pageTitle={pageTitle} />
            case 3:
                return <MySalesDashboard pageTitle={pageTitle} />
            case 4:
                return <SalesDashboard pageTitle={pageTitle} />
            case 5:
                return <SalesAdminDashboard pageTitle={pageTitle} />
            case 6:
                return <WarehouseDashboard pageTitle={pageTitle} />
            default:
                return <MySalesDashboard pageTitle={pageTitle} />
        }
    }

    renderDashboards = team => {
        const { pageTitle } = this.props;
        const { currentTab } = this.state;
        if(currentTab === 0) {
            switch(team) {
                case "Accounts":
                    return <AccountsDashboard pageTitle={pageTitle} />
                case "Marketing":
                    return <MarketingDashboard pageTitle={pageTitle} />
                case "Sales":
                    return <MySalesDashboard pageTitle={pageTitle} />
                case "Sales Admin":
                    return <SalesAdminDashboard pageTitle={pageTitle} />
                case "Warehouse":
                    return <WarehouseDashboard pageTitle={pageTitle} />
                default:
                    return <MySalesDashboard pageTitle={pageTitle} />
            }
        } else {
            switch(currentTab) {
                default:
                    return <SalesDashboard pageTitle={pageTitle} />
            }
        }
    }

  	render() {
        const { currentTab, datetime, isLoading, weather } = this.state;
        const { classes, loggedInStaff, ui } = this.props;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        const isManagementTeam = isManagement();
        const isCrushedIceTeam = isCrushedIce();
		return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={8}>
                                                <Grid container alignItems='center'>
                                                    <Grid item xs>
                                                        <Box pt={1}>
                                                            <Grid container spacing={1} alignItems='center'>
                                                                <Grid item>
                                                                    <Avatar
                                                                        staffId={loggedInStaff?.id}
                                                                        size={36}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="h4" className="textDefault">
                                                                        {loggedInStaff?.name ?? ''}
                                                                    </Typography>                                                  
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box pt={0.5}>
                                                            <Grid container alignItems='center' spacing={2}>
                                                                {(isCrushedIceTeam && (
                                                                    <Grid item>
                                                                        <InsightChip
                                                                            icon="users-class"
                                                                            label='Crushed Ice Support Team'
                                                                        />
                                                                    </Grid>
                                                                )) || (isManagementTeam && (
                                                                    <Grid item>
                                                                        <InsightChip
                                                                            icon="users-class"
                                                                            label='Management Team'
                                                                        />
                                                                    </Grid>
                                                                )) || (
                                                                    <Grid item>
                                                                        <InsightChip
                                                                            icon="users-class"
                                                                            label={_.isEmpty(loggedInStaff?.teams?.[0]) ? 'TBC' : `${loggedInStaff?.teams?.[0]} Team`}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="clock"
                                                                        label={datetime}
                                                                    />
                                                                </Grid>
                                                            </Grid> 
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {weather && (
                                                <Grid item xs={4} align='right'>
                                                    <Box minWidth={'100%'} pr={2.5} mt={1.5} mb={0.5}>
                                                        <Weather weather={weather} />
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            {(isManagementTeam && (
                                                this.renderManagementDashboard()
                                            )) || (
                                                this.renderDashboards(loggedInStaff?.teams?.[0] ?? null)
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(isManagementTeam && (
                                <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                    <Tabs
                                        color="primary"
                                        onChange={this.handleTabChange}
                                        orientation="vertical"
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none'
                                            }
                                        }}
                                        value={currentTab}
                                        variant="scrollable"
                                    >
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Management" />
                                        <Divider />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Accounts" />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Marketing" />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="My Sales" />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} label="Sales" />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={5} label="Sales Admin" />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={6} label="Warehouse" />
                                    </Tabs>
                                </Grid>
                            )) || (
                                <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                    <Tabs
                                        color="primary"
                                        onChange={this.handleTabChange}
                                        orientation="vertical"
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none'
                                            }
                                        }}
                                        value={currentTab}
                                        variant="scrollable"
                                    >
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label={loggedInStaff?.teams?.[0] ? (loggedInStaff?.teams?.[0] === 'Sales' ? 'My Sales' : loggedInStaff?.teams?.[0]) : 'My Sales'} />
                                        <Divider />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Sales" />
                                    </Tabs>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </>
        );
      }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        ui: state.ui
    };
}

export default connect(mapStateToProps)(withStyles(ViewPage)(Dashboard));