import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import API from "API";
import Alert from "Components/Common/Alert/Alert";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import BooleanChip from "Components/Common/Chips/BooleanChip";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import logo from "Assets/Images/no-img.jpg";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { CLOUDFRONT_URL } from "Constants";

const OrderAllocation = ({ access, callback, order }) => {
    const [state, setState] = useState({
        deliveries: [],
        isLoading: false,
        products: [],
    });

    useEffect(() => {
        let newProducts = _.filter(order.products, (el) => el.productId > 0 && el?.srv !== 1);
        let newDeliveries = [];
        _.each(order.deliveries, (del) => {
            newDeliveries.push({
                ...del,
                products: _.filter(del.products, (el) => el.productId > 0 && el?.srv !== 1),
            });
        });
        setState({
            deliveries: newDeliveries,
            products: newProducts,
        });
    }, [order]);

    const dispatch = useDispatch();

    const handleChange = (delivery, idx) => {
        if (delivery !== false) {
            let deliveries = [...state.deliveries],
                od = _.findIndex(deliveries, (el) => el.od_id === delivery);

            if (od !== -1) {
                deliveries[od].products[idx].isAllocated = deliveries[od].products[idx].isAllocated === 0 ? 1 : 0;

                setState((state) => ({
                    ...state,
                    deliveries,
                }));
            }
        } else {
            let products = [...state.products];

            products[idx].isAllocated = products[idx].isAllocated === 0 ? 1 : 0;

            setState((state) => ({
                ...state,
                products,
            }));
        }
    };

    const handleAll = (delivery, checked) => {
        if (delivery !== false) {
            let deliveries = [...state.deliveries],
                od = _.findIndex(deliveries, (el) => el.od_id === delivery);

            if (od !== -1) {
                _.each(deliveries?.[od]?.products, (prd, idx) => {
                    const multiDeliveryProductCheck = [];
                    _.each(deliveries, (od) => {
                        _.each(od.products, (odp) => {
                            if (odp.isAllocated === 1 && odp.productId === prd.productId && odp.odp_id !== prd.odp_id) {
                                multiDeliveryProductCheck.push(odp);
                            }
                        });
                    });

                    if (checked) {
                        deliveries[od].products[idx].isAllocated =
                            deliveries[od].products[idx].isAllocated === 1 ||
                            parseInt(deliveries[od].products[idx].totalAv) >= _.sumBy(multiDeliveryProductCheck, (el) => parseInt(el.quantity)) + parseInt(prd.quantity)
                                ? 1
                                : 0;
                    } else {
                        deliveries[od].products[idx].isAllocated = 0;
                    }
                });

                setState((state) => ({
                    ...state,
                    deliveries,
                }));
            }
        } else {
            let products = [...state.products];

            _.each(products, (prd, idx) => {
                if (checked) {
                    products[idx].isAllocated =
                        prd.isAllocated === 1 ||
                        parseInt(prd.totalAv) >=
                            _.sumBy(
                                _.filter(state.products, (_el) => _el.isAllocated === 1 && _el.productId === prd.productId && _el.id !== prd.id),
                                (el) => parseInt(el.quantity)
                            ) +
                                parseInt(prd.quantity)
                            ? 1
                            : 0;
                } else {
                    products[idx].isAllocated = 0;
                }
            });

            setState((state) => ({
                ...state,
                products,
            }));
        }
    };

    const handleSubmit = (delivery) => {
        setState((state) => ({
            ...state,
            isLoading: true,
        }));

        API.post(`/inventory/stock/allocation`, {
            orderId: order.order_id,
            delivery: delivery,
            products: delivery === false ? state.products : state.deliveries?.[_.findIndex(state.deliveries, (el) => el.od_id === delivery)]?.products ?? [],
        }).then((res) => {
            if (res?.data) {
                if (res?.data.errors) {
                    dispatch(deploySnackBar("error", `Stock is no longer available for your selected ${delivery ? `sales order delivery` : `sales order`} allocation`));
                    callback();
                } else {
                    dispatch(deploySnackBar("success", `The ${delivery ? `sales order delivery` : `sales order`} has been allocated as selected`));
                    callback();
                }
            }
        });
    };

    return (
        (state.isLoading && <LoadingCircle />) ||
        ((order.order_status === "Despatched" || order.order_status === "Invoiced") && (
            <Paper>
                <Box p={3}>
                    <Typography variant="h6" paragraph>
                        Order Allocation
                    </Typography>
                    <Alert severity="success" variant="outlined">
                        <Typography variant="span" className="fw-400">
                            Not Required -{" "}
                        </Typography>
                        This sales order has been fulfilled
                    </Alert>
                </Box>
            </Paper>
        )) ||
        (order.order_status === "Cancelled" && (
            <Paper>
                <Box p={3}>
                    <Typography variant="h6" paragraph>
                        Order Allocation
                    </Typography>
                    <Alert severity="success" variant="outlined">
                        <Typography variant="span" className="fw-400">
                            Not Required -{" "}
                        </Typography>
                        This sales order has been cancelled
                    </Alert>
                </Box>
            </Paper>
        )) ||
        (_.isEmpty(order.deliveries) && (
            <PaddedPaper>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">Order Allocation</Typography>
                    </Grid>
                    {access.deallocateStock && (
                        <>
                            <Grid item>
                                <Button size="small" onClick={() => handleAll(false, false)} variant="text">
                                    <FAIcon icon="times" type="light" size={15} button />
                                    De-Select All
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button size="small" onClick={() => handleAll(false, true)} variant="text">
                                    <FAIcon icon="check" type="light" size={15} button />
                                    Select All
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    onClick={() => dispatch(deployConfirmation("Are you sure you want to allocate as selected for this order?", () => handleSubmit(false)))}
                                    size="small"
                                    variant="contained"
                                >
                                    <FAIcon icon="check" size={15} buttonPrimary />
                                    Save Changes
                                </Button>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Alert severity="warning" variant="outlined">
                            <strong>Pre-allocation - </strong> This sales order has not been processed
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            config={{
                                key: "id",
                                alternatingRowColours: true,
                                // responsiveImportance: true,
                                inline: true,
                                options: {
                                    dataRef: true,
                                },
                            }}
                            columns={[
                                {
                                    heading: "Product",
                                    field: (rowData) => {
                                        const src = rowData.image
                                            ? `${CLOUDFRONT_URL}${rowData.image}`
                                            : rowData.product?.default_image?.img_filekey
                                            ? `${CLOUDFRONT_URL}${rowData.product.default_image.img_filekey}`
                                            : logo;
                                        return <Avatar variant="square" alt={rowData.product?.product_name ?? "Product Image"} src={src} style={{ width: 75, height: 75 }} />;
                                    },
                                    important: true,
                                    hideInResponsiveDialog: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "",
                                    field: (rowData) => {
                                        const inStock = rowData.instock ?? rowData.productData?.stock_count ?? 0,
                                            preOrdered = rowData.preOrdered ?? rowData.productData?.stock_on_order_allocated_count ?? 0,
                                            available = rowData.available ?? rowData.productData?.stock_available_count ?? 0,
                                            allocated = rowData.allocated ?? rowData.productData?.stock_allocated_count ?? 0,
                                            onOrder = rowData.onOrder ?? rowData.productData?.stock_on_order_count ?? 0;

                                        return (
                                            <Grid container alignItems="flex-end" className="pt-2 pb-2">
                                                <Grid item>
                                                    <Typography variant="body2" className="fw-400">
                                                        {rowData.productCode}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" component="div">
                                                        {rowData.productName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" component="div">
                                                        {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Box pt={1}>
                                                        {(rowData.ls &&
                                                            (rowData.ls === "Despatched" || rowData.ls === "Invoiced") &&
                                                            ((rowData.ls === "Invoiced" && (
                                                                <Box mt={0.5} mr={1}>
                                                                    <BooleanChip success={true} label={"Invoiced"} />
                                                                </Box>
                                                            )) ||
                                                                (rowData.ls === "Despatched" && (
                                                                    <Box mt={0.5} mr={1}>
                                                                        <BooleanChip success={true} label={"Despatched"} />
                                                                    </Box>
                                                                )))) ||
                                                            (rowData.ft === "PO" && (
                                                                <Box mr={1}>
                                                                    <BooleanChip
                                                                        success={rowData.con === 1}
                                                                        label={rowData.con === 1 ? "Awaiting Supplier Despatch" : "Awaiting Supplier Confirmation"}
                                                                    />
                                                                </Box>
                                                            )) || (
                                                                <Box mr={1}>
                                                                    <BooleanChip success={rowData.isAllocated === 1} label={rowData.isAllocated === 1 ? "Pre-Allocated" : "Not Allocated"} />
                                                                </Box>
                                                            )}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        );
                                    },
                                    important: true,
                                },
                                {
                                    heading: "Quantity",
                                    field: (rowData) => rowData.quantity,
                                    important: true,
                                    alignment: "right",
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Allocated",
                                    field: (rowData) => {
                                        return (
                                            <Tooltip title={rowData.isAllocated === 0 ? "Allocate Stock" : "De-Allocate Stock"}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={rowData.isAllocated === 1}
                                                    disabled={(
                                                        !access.deallocateStock ||
                                                        (rowData.isAllocated === 1 ||
                                                        parseInt(rowData.totalAv) >=
                                                            _.sumBy(
                                                                _.filter(state.products, (el) => el.isAllocated === 1 && el.productId === rowData.productId && el.id !== rowData.id),
                                                                (el) => parseInt(el.quantity)
                                                            ) +
                                                                parseInt(rowData.quantity)
                                                            ? false
                                                            : true))
                                                    }
                                                    onChange={() => handleChange(false, rowData.rowIdx)}
                                                />
                                            </Tooltip>
                                        );
                                    },
                                    alignment: "right",
                                    sizeToContent: true,
                                    important: true,
                                },
                            ]}
                            rows={state.products}
                        />
                    </Grid>
                </Grid>
            </PaddedPaper>
        )) || (
            <Grid container spacing={3}>
                {(!_.isEmpty(
                    _.filter(
                        state.deliveries,
                        (el) =>
                            el.od_type === "WH" &&
                            (el.od_status === "Awaiting Picking" ||
                                el.od_status === "Awaiting Check" ||
                                el.od_status === "Awaiting Technical" ||
                                el.od_status === "Awaiting Stock" ||
                                el.od_status === "Awaiting Allocation")
                    )
                ) &&
                    _.map(
                        _.filter(
                            state.deliveries,
                            (el) =>
                                el.od_type === "WH" &&
                                (el.od_status === "Awaiting Picking" ||
                                    el.od_status === "Awaiting Check" ||
                                    el.od_status === "Awaiting Technical" ||
                                    el.od_status === "Awaiting Stock" ||
                                    el.od_status === "Awaiting Allocation")
                        ),
                        (od, didx) => {
                            const pendingAllocation = _.some(od.products, (el) => el.allocationPending);
                            return (
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Grid container key={didx} alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h6">
                                                    Delivery #{od.od_reference}/{od.od_idx} Allocation
                                                </Typography>
                                            </Grid>
                                            {od.od_on_hold === 0 && od.od_sys_hold === 0 && access.deallocateStock && !pendingAllocation && (
                                                <>
                                                    <Grid item>
                                                        <Button size="small" onClick={() => handleAll(od.od_id, false)} variant="text">
                                                            <FAIcon icon="times" type="light" size={15} button />
                                                            De-Select All
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button size="small" onClick={() => handleAll(od.od_id, true)} variant="text">
                                                            <FAIcon icon="check" type="light" size={15} button />
                                                            Select All
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => dispatch(deployConfirmation("Are you sure you want to allocate as selected for this delivery?", () => handleSubmit(od.od_id)))}
                                                            size="small"
                                                            variant="contained"
                                                        >
                                                            <FAIcon icon="check" size={15} buttonPrimary />
                                                            Save Changes
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )}
                                            {(pendingAllocation && (
                                                 <Grid item xs={12}>
                                                    <Alert severity="success" variant="outlined">
                                                        <strong>Purchase Order Awaiting Confirmation</strong> - This delivery will be automatically allocated once the purchase order is confirmed
                                                    </Alert>
                                                </Grid>
                                            )) || ((od.od_on_hold === 1 || od.od_sys_hold === 1) && (
                                                <Grid item xs={12}>
                                                    <Alert severity="warning" variant="outlined">
                                                        {od.od_on_hold === 1
                                                            ? od.od_on_hold_datetime === "0000-00-00 00:00:00"
                                                                ? `The warehouse have not yet acknowledged the on-hold resolution for this delivery`
                                                                : `This delivery is currently on hold, please resolve availability issues with this delivery to manage allocation`
                                                            : `This delivery is currently on hold, please finalise order modification to manage allocation for this delivery`}
                                                    </Alert>
                                                </Grid>
                                            )) || (
                                                <Grid item xs={12}>
                                                    <Alert severity={od.od_status === "Awaiting Allocation" ? "error" : "success"} variant="outlined">
                                                        <strong>{od.od_status === "Awaiting Allocation" ? "Not Allocated" : "Allocated"} - </strong>{" "}
                                                        {od.od_status === "Awaiting Allocation" ? "This delivery must be allocated before it can be picked and despatched" : `This delivery is allocated ${!_.isEmpty(od.parent) ? ` - stock required for ship complete consolidation` : ``}`}
                                                    </Alert>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <DataTable
                                                    config={{
                                                        key: "id",
                                                        alternatingRowColours: true,
                                                        // responsiveImportance: true,
                                                        inline: true,
                                                        options: {
                                                            dataRef: true,
                                                        },
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: "Product",
                                                            field: (rowData) => {
                                                                const src = rowData.image
                                                                    ? `${CLOUDFRONT_URL}${rowData.image}`
                                                                    : rowData.product?.default_image?.img_filekey
                                                                    ? `${CLOUDFRONT_URL}${rowData.product.default_image.img_filekey}`
                                                                    : logo;
                                                                return <Avatar variant="square" alt={rowData.product?.product_name ?? "Product Image"} src={src} style={{ width: 75, height: 75 }} />;
                                                            },
                                                            important: true,
                                                            hideInResponsiveDialog: true,
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: "",
                                                            field: (rowData) => {
                                                                const inStock = rowData.instock ?? rowData.productData?.stock_count ?? 0,
                                                                    preOrdered = rowData.preOrdered ?? rowData.productData?.stock_on_order_allocated_count ?? 0,
                                                                    available = rowData.available ?? rowData.productData?.stock_available_count ?? 0,
                                                                    allocated = rowData.allocated ?? rowData.productData?.stock_allocated_count ?? 0,
                                                                    onOrder = rowData.onOrder ?? rowData.productData?.stock_on_order_count ?? 0;

                                                                return (
                                                                    <Grid container alignItems="flex-end" className="pt-2 pb-2">
                                                                        <Grid item>
                                                                            <Typography variant="body2" className="fw-400">
                                                                                {rowData.productCode}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="caption" component="div">
                                                                                {rowData.productName}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="caption" component="div">
                                                                                {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            {(pendingAllocation && (
                                                                                <Typography variant="caption" className="fw-400">
                                                                                    <FAIcon icon="exclamation-circle" size={12} className="textSuccess mr-1" noMargin />
                                                                                    This line will be automatically allocated once the purchase order is confirmed
                                                                                </Typography>
                                                                            )) || (
                                                                                <Box pt={1}>
                                                                                    {(rowData.ls &&
                                                                                        (rowData.ls === "Despatched" || rowData.ls === "Invoiced") &&
                                                                                        ((rowData.ls === "Invoiced" && (
                                                                                            <Box mt={0.5} mr={1}>
                                                                                                <BooleanChip success={true} label={"Invoiced"} />
                                                                                            </Box>
                                                                                        )) ||
                                                                                            (rowData.ls === "Despatched" && (
                                                                                                <Box mt={0.5} mr={1}>
                                                                                                    <BooleanChip success={true} label={"Despatched"} />
                                                                                                </Box>
                                                                                            )))) ||
                                                                                        (rowData.ft === "PO" && (
                                                                                            <Box mr={1}>
                                                                                                <BooleanChip
                                                                                                    success={rowData.con === 1}
                                                                                                    label={rowData.con === 1 ? "Awaiting Supplier Despatch" : "Awaiting Supplier Confirmation"}
                                                                                                />
                                                                                            </Box>
                                                                                        )) || (
                                                                                            <Box mr={1}>
                                                                                                <BooleanChip
                                                                                                    success={rowData.isAllocated === 1}
                                                                                                    label={rowData.isAllocated === 1 ? "Allocated" : "Not Allocated"}
                                                                                                />
                                                                                            </Box>
                                                                                        )}
                                                                                </Box>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            },
                                                            important: true,
                                                        },
                                                        {
                                                            heading: "Quantity",
                                                            field: (rowData) => rowData.quantity,
                                                            important: true,
                                                            alignment: "right",
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: "Allocated",
                                                            field: (rowData) => {
                                                                const multiDeliveryProductCheck = [];
                                                                _.each(state.deliveries, (od) => {
                                                                    _.each(od.products, (odp) => {
                                                                        if (odp.isAllocated === 1 && odp.productId === rowData.productId && odp.odp_id !== rowData.odp_id) {
                                                                            multiDeliveryProductCheck.push(odp);
                                                                        }
                                                                    });
                                                                });
                                                                return (
                                                                    pendingAllocation ? `-` : (
                                                                        <Tooltip title={rowData.isAllocated === 0 ? "Allocate Stock" : "De-Allocate Stock"}>
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={rowData.isAllocated === 1}
                                                                                disabled={(
                                                                                    !_.isEmpty(od.parent) ||
                                                                                    od.od_on_hold === 1 ||
                                                                                    od.od_sys_hold === 1 ||
                                                                                    !access.deallocateStock ||
                                                                                    (rowData.isAllocated === 1 ||
                                                                                    rowData.totalAv >= _.sumBy(multiDeliveryProductCheck, (el) => parseInt(el.quantity)) + parseInt(rowData.quantity)
                                                                                        ? false
                                                                                        : true))
                                                                                }
                                                                                onChange={() => handleChange(od.od_id, rowData.rowIdx)}
                                                                            />
                                                                        </Tooltip>
                                                                    )
                                                                );
                                                            },
                                                            alignment: "right",
                                                            sizeToContent: true,
                                                        },
                                                    ]}
                                                    rows={od.products}
                                                />
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            )
                        }
                    )) || (
                    <Grid item xs={12}>
                        {(!_.isEmpty(_.filter(order.deliveries, (el) => el.od_type === "WH")) && (
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Order Allocation
                                    </Typography>
                                    <Alert severity="success" variant="outlined">
                                        <Typography variant="span" className="fw-400">
                                            Not Required -{" "}
                                        </Typography>
                                        All warehouse deliveries have been picked and/or despatched and remaining lines are being fulfiled by a supplier
                                    </Alert>
                                </Box>
                            </Paper>
                        )) || (
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Order Allocation
                                    </Typography>
                                    <Alert severity="success" variant="outlined">
                                        <Typography variant="span" className="fw-400">
                                            Not Required -{" "}
                                        </Typography>
                                        All lines on this sales order are being fulfilled by a supplier
                                    </Alert>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                )}
            </Grid>
        )
    );
};

export default OrderAllocation;
