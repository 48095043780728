import React, { useEffect, useState }  from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux'; 

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import StatusChip from 'Components/Common/Chips/StatusChip';

import CallRecordings from 'Components/Common/Activity/CallRecordings';
import DataTable from 'Components/Common/DataTables/DataTable';
import StaffChip from 'Components/Common/Chips/StaffChip';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { getPresignedUrl } from 'Functions/S3Functions';

const styles = () => ({
    card: {
        borderLeft: '4px solid #fff',
    },
    cardPriority: {
        borderLeft: '4px solid #ef3340',
    },
    cardContent: {
        padding: '0 16px 16px 16px'
    }
});

const initialAccess = () => ({
    recordings: false
})

const ViewCallLogs = ({ apiRoute, calls, hasEmailLogs }) => {

    const   [access, setAccess]     =   useState(initialAccess()),
            [data, setData]         =   useState([]),
            [loading, setLoading]   =   useState(apiRoute ? true : false);

    const dispatch = useDispatch();

    const staff = useSelector(state => state.notifications.status);
    
    const handleDespatchCallRecordings = (id, ca) => {
        dispatch(deployDialog(<CallRecordings id={id} />, false, `Call Recordings (${moment(ca).format("DD/MM/YYYY HH:mm")})`, 'standard', 'xs'))
    }

    const getColumns = () => {
        if(hasEmailLogs) {

            return [
                {
                    heading: 'Date / Time',
                    field: rowData => rowData.e ? moment(rowData.call_created_datetime).format("DD/MM/YYYY HH:mm:ss") : (`${!rowData.children ? `Transfer: ` : ``}${moment(rowData.call_start_datetime !== "0000-00-00 00:00:00" ? rowData.call_start_datetime : rowData.call_created_datetime).format(!rowData.children ? 'HH:mm:ss' : 'DD/MM/YYYY HH:mm:ss')}`),
                    dataRef: 'call_created_datetime',
                    important: true,
                    sizeToContent: true,
                    nestingDropdown: true,
                },
                {
                    heading: 'Duration',
                    field: (rowData) => rowData.e ? '-' : (parseInt(rowData.call_duration) > 0 ? moment.utc(parseInt(rowData.call_duration) * 1000).format(rowData.call_duration > 3600 ? "hh:mm:ss" : "mm:ss") : "-"),
                    fieldFormat: 'int',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Direction',
                    field: rowData => rowData.call_type,
                    dataRef: 'call_type',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Spoke To',
                    field: rowData => rowData.e ? (rowData.call_name || '-') : rowData.call_name ? rowData.call_name : rowData.contact ? `${rowData.contact?.contact_first_name} ${rowData.contact?.contact_last_name}` : <em>No contact</em>,
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Phone Number',
                    field: rowData => rowData.call_number || '-',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Notes',
                    field: rowData => (
                            _.isEmpty(rowData.call_note) ? 
                                <em>No notes added for this {rowData.e ? 'e-mail' : 'call'}</em>
                            : (
                                rowData.call_note?.split?.('\n').map((item, key) => (
                                    <Box pb={key !== (_.size(rowData.call_note?.split?.('\n')) - 1) ? 1 : undefined} key={key}>
                                        {item}
                                    </Box>
                                ))
                            )
                    ),
                    cellProps: () => ({
                        style: {
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            overflow: 'hidden',
                        }
                    }),
                    important: true,
                },
                {
                    heading: 'Staff',
                    field: rowData => <StaffChip suffix={rowData.call_destination ? ` (${rowData.call_destination})` : null} staff={staff?.[rowData.call_staff_id] ?? null} />,
                    fieldFormat: 'staff',
                    dataRef: 'call_staff_id',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Status',
                    field: rowData => <Box py={0.85}><StatusChip status={rowData.call_status || 'Answered'} /></Box>,
                    dataRef: 'call_status',
                    important: true,
                    sizeToContent: true,
                    cellProps: rowData => ({
                        style: {
                            color: rowData.call_status === 'Answered' ? '#4CAF50' : '#d32f2f',
                        }
                    })
                },
                {
                    actions: rowData => {
                        return [
                            {
                                name: "View Recordings",
                                icon: "cassette-tape",
                                onClick: () => handleDespatchCallRecordings(rowData.recordings_id ?? rowData.call_id, rowData.call_created_datetime),
                                disabled: !access.recordings || rowData.recordings_count === 0 || rowData.e,
                                hideIfDisabled: !access.recordings || rowData.recordings_count === 0 || rowData.e
                            },
                            {
                                name: "Download E-mail",
                                icon: "download",
                                onClick: () => getPresignedUrl(rowData.file, rowData.file.split('/').pop()),
                                disabled: !rowData.e || !rowData.file,
                                hideIfDisabled: true
                            },
                        ]
                    }
                }
            ]

        } else {
            
            return [
                {
                    heading: 'Date / Time',
                    field: rowData => `${!rowData.children ? `Transfer: ` : ``}${moment(rowData.call_start_datetime !== "0000-00-00 00:00:00" ? rowData.call_start_datetime : rowData.call_created_datetime).format(!rowData.children ? 'HH:mm:ss' : 'DD/MM/YYYY HH:mm:ss')}`,
                    dataRef: 'call_created_datetime',
                    important: true,
                    sizeToContent: true,
                    nestingDropdown: true,
                },
                {
                    heading: 'Duration',
                    field: (rowData) => (parseInt(rowData.call_duration) > 0 ? moment.utc(parseInt(rowData.call_duration) * 1000).format(rowData.call_duration > 3600 ? "hh:mm:ss" : "mm:ss") : "-"),
                    dataRef: 'call_duration',
                    fieldFormat: 'int',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Direction',
                    field: rowData => rowData.call_type,
                    dataRef: 'call_type',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Spoke To',
                    field: rowData => rowData.call_name ? rowData.call_name : rowData.contact ? `${rowData.contact?.contact_first_name} ${rowData.contact?.contact_last_name}` : <em>No contact</em>,
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Phone Number',
                    field: rowData => rowData.call_number || '-',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Notes',
                    field: rowData => (
                            _.isEmpty(rowData.call_note) ? 
                                <em>No notes added for this call</em>
                            : (
                                rowData.call_note?.split?.('\n').map((item, key) => (
                                    <Box pb={key !== (_.size(rowData.call_note?.split?.('\n')) - 1) ? 1 : undefined} key={key}>
                                        {item}
                                    </Box>
                                ))
                            )
                    ),
                    cellProps: () => ({
                        style: {
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            overflow: 'hidden',
                        }
                    }),
                    important: true,
                },
                {
                    heading: 'Staff',
                    field: rowData => <StaffChip suffix={rowData.call_destination ? ` (${rowData.call_destination})` : null} staff={staff?.[rowData.call_staff_id] ?? null} />,
                    fieldFormat: 'staff',
                    dataRef: 'call_staff_id',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Status',
                    field: rowData => <Box py={0.85}><StatusChip status={rowData.call_status || 'Answered'} /></Box>,
                    dataRef: 'call_status',
                    important: true,
                    sizeToContent: true,
                    cellProps: rowData => ({
                        style: {
                            color: rowData.call_status === 'Answered' ? '#4CAF50' : '#d32f2f',
                        }
                    })
                },
                {
                    actions: rowData => {
                        return [
                            {
                                name: "View Recordings",
                                icon: "cassette-tape",
                                onClick: () => handleDespatchCallRecordings(rowData.recordings_id ?? rowData.call_id, rowData.call_created_datetime),
                                disabled: !access.recordings || rowData.recordings_count === 0,
                                hideIfDisabled: !access.recordings || rowData.recordings_count === 0
                            },
                        ]
                    }
                }
            ]

        }

    }

    const handleLoadData = () => {
        setLoading(true);
        API.get(apiRoute)
        .then(res => {
            if(res?.data) {
                setData(res.data);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        if(apiRoute) {
            handleLoadData();
        }
    }, [apiRoute]); /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
        setData(calls ?? [])
    }, [calls]);

    useEffect(() => {
        API.multiAccess([
            'call-rec'
        ])
        .then(([recordings]) => {
            setAccess(access => ({
                ...access,
                recordings
            }))
        });
    }, [])

    return (
        <>
            {(loading && (
                <LoadingCircle />
            )) || (
                (_.isEmpty(data) && (
                    <Typography variant="h5" className="fw-400" align='center'>
                        No calls have been logged yet
                    </Typography>
                )) || (
                    <Paper>
                        <DataTable
                            config={{
                                key: "i",
                                pagination: true,
                                alternatingRowColours: false,
                                responsiveImportance: true,
                                plainPagination: true,
                                nesting: true,
                                options: {
                                    dataRef: true,
                                    export: {
                                        excel: true,
                                        name: `Calls`,
                                        pdf: true,
                                        print: true,
                                        title: `Calls`,
                                    },
                                },
                                rowProps: rowData => ({
                                    style: {
                                        backgroundColor: !rowData.children ? '#fbfbfb' : undefined
                                    }
                                })
                            }}
                            columns={getColumns()}
                            rows={data}
                        />
                    </Paper>
                )
            )}
        </>
    )
}


export default withStyles(styles)(ViewCallLogs);