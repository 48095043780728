import React from 'react';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const Billing = ({
    contactList, 
    customer, 
    document, 
    formData, 
    formErrors, 
    handleChange, 
    handleNull, 
    handleSelectChange,
}) => {
    const customAddress = !_.isEmpty(formData.customerAddressCustom);
    const address = [];
    if(customAddress) {
        _.each([formData.customerAddressCustom.addressOne,
            formData.customerAddressCustom.addressTwo,
            formData.customerAddressCustom.addressThree,
            formData.customerAddressCustom.town,
            formData.customerAddressCustom.county,
            formData.customerAddressCustom.postcode], row => {
            if(row?.length > 0) {
                address.push(row)
            }
        })
    } else {
        if(customer?.data?.addresses) {
            const idx = _.findIndex(customer.data.addresses, el => el.i === formData.customerAddress);
            if(idx !== -1) {
                _.each([customer.data.addresses[idx].n,
                    customer.data.addresses[idx].l1,
                    customer.data.addresses[idx].l2,
                    customer.data.addresses[idx].l3,
                    customer.data.addresses[idx].to,
                    customer.data.addresses[idx].cc,
                    customer.data.addresses[idx].po,
                    customer.data.addresses[idx].co], row => {
                    if(row?.length) {
                        address.push(row)
                    }
                })
            }
        }
    }

    return (
        <PaddedPaper>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Customer Details
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <AutoCompleteSelect 
                            label={`Customer Contact *`}
                            options={contactList} 
                            value={formData.customerContact}                                
                            onChange={val => handleSelectChange('customerContact', val)}
                            error={formErrors && formErrors['customerContact'] && true}
                            errorText={formErrors && formErrors['customerContact']}
                            creatable
                        />
                    </FormControl>
                </Grid>
                {!_.isEmpty(address) && (
                    <Grid item xs={12}>
                        <FormLabel>
                            <Typography variant="caption">
                                 Billing Address
                            </Typography>
                        </FormLabel>
                        {_.map(address, (line, idx) => (
                            <Typography variant="body2" key={idx} gutterBottom>
                                {line}
                            </Typography>
                        ))}
                    </Grid>
                )}
            </Grid>
        </PaddedPaper>
    )
}

export default Billing;