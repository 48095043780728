import decode from 'jwt-decode';
import MainStore from 'Redux/MainStore';
import * as authActionTypes from 'Redux/Actions/Auth/Types/Types';
import * as notificationActionTypes from 'Redux/Actions/Notifications/Types/Types';

// Staff token authorisation functions
export function loggedIn() {
    const token = getToken();
    return !!token && !isTokenExpired(token);
}

export function getToken() {
    return localStorage.getItem('staff_auth_token');
}

export function setToken(token) {
    localStorage.setItem('staff_auth_token', token);
}

export function clearToken() {
    localStorage.removeItem('staff_auth_token');
    MainStore.dispatch({
        type: notificationActionTypes.CLEAR_USER_DATA
    })
    MainStore.dispatch({
        type: authActionTypes.ACCESS,
        payload: {}
    })
    MainStore.dispatch({
        type: authActionTypes.EXTRACT_TOKEN,
        payload: {
            token: '',
            exp: 0,
            id: 0,
            email: '',
            firstName: '',
            lastName: '',
            name: '',
            picture: '',
            teams: []
        }
    })
}

export function getDecodedToken(){
    try {
        return decode(getToken());
    } catch(err) {
        return {}
    }
}

export function isCrushedIce(){    
    const token = getDecodedToken();
    return token.ci === 1;
}

export function isManagement(){    
    const token = getDecodedToken();
    return token.management;
}

export function isTokenExpired(token) {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
            return true;
        }
        else
            return false;           
    }
    catch (err) {
        return true;
    }
}

// Login staff profile storage functions
export function hasStoredProfile(){
    const profile = getStoredProfile();
    return !!profile;
}

export function getStoredProfile(){
    return JSON.parse(localStorage.getItem('staff_stored_profile'));
}

export function setStoredProfile(profile) {
    localStorage.setItem('staff_stored_profile', JSON.stringify(profile));
}

export function forceStoredProfile(){
    if(hasStoredProfile()) {
        const profile = getStoredProfile();
        if(profile.active === false){
            const savedProfile = {
                ...profile,
                temp: true,
            }
            setStoredProfile(savedProfile);
        }        
    }
}

export function clearStoredProfile() {
    localStorage.removeItem('staff_stored_profile');
}

// Last active page
export function hasLastActivePage(){
    const path = getLastActivePage();
    return !!path;
}

export function getLastActivePage(){
    return localStorage.getItem('staff_last_page');
}

export function setLastActivePage(path) {
    if(path !== '/messaging') {
        localStorage.setItem('staff_last_page', path);
    }
}

export function clearLastActivePage() {
    localStorage.removeItem('staff_last_page');
}

export function setMode(mode) {
    localStorage.setItem('RED_MODE', mode);
}

export function getMode() {
    let mode = localStorage.getItem('RED_MODE');
    if(!mode) {
        mode =  navigator.userAgent.indexOf("PDA") > -1 ? 'Warehouse' : 'Main';
    } 
    return mode;
}