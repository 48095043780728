import React from "react";
import _ from "lodash";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Alert from "Components/Common/Alert/Alert";
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from "Components/Common/Chips/InsightChip";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import Products from "Components/Purchases/PurchaseOrderForm/Products";
import StackTable from "Components/Common/DataTables/StackTable";
import Totals from "Components/Purchases/PurchaseOrderForm/Totals";

import { isCrushedIce } from 'Functions/AuthFunctions';
import { closeDialog } from "Redux/Actions/Dialog/Dialog";

const useStyles = makeStyles({
    tab: {
        color: "#fff",
        opacity: 1,
    },
    td: {
        border: "none",
        padding: 4,
    },
});

const PurchaseOrderSummary = ({ inDialog, po }) => {
    const   history     = useHistory(),
            ui          = useSelector(state => state.ui),
            dispatch    = useDispatch();

    let invoiceTo = [
            po.po_contact_name,
            "Advanced Access Ltd",
            po.po_address_line_one,
            po.po_address_line_two,
            po.po_address_line_three,
            po.po_address_town,
            po.po_address_county,
            po.po_address_postcode,
            po.po_address_country,
        ],
        deliveryTo = [
            `${po.po_ship_aa_only === 1 ? "Advanced Access Ltd" : po?.so?.order_delivery_company ?? ""}`,
            po.po_delivery_address_line_one,
            po.po_delivery_address_line_two,
            po.po_delivery_address_line_three,
            po.po_delivery_address_town,
            po.po_delivery_address_county,
            po.po_delivery_address_postcode,
            po.po_delivery_address_country,
        ],
        suppAddress = [
            po.supplier?.trading_address?.address_line_one,
            po.supplier?.trading_address?.address_line_two,
            po.supplier?.trading_address?.address_line_three,
            po.supplier?.trading_address?.address_town,
            po.supplier?.trading_address?.county?.county_name,
            po.supplier?.trading_address?.address_postcode,
            po.supplier?.trading_address?.country?.country_name,
        ],
        invoiceToEmpty = true,
        deliveryToEmpty = true,
        suppAddressEmpty = true,
        classes = useStyles();

    _.each(deliveryTo, (line) => {
        if (line && line.length > 0) {
            deliveryToEmpty = false;
            return false;
        }
    });

    _.each(invoiceTo, (line) => {
        if (line && line.length > 0) {
            invoiceToEmpty = false;
            return false;
        }
    });

    _.each(suppAddress, (line) => {
        if (line && line.length > 0) {
            suppAddressEmpty = false;
            return false;
        }
    });

    const handlePopUpToPage = () => {
        history.push(`/purchase-orders/${po.po_id}`)
        dispatch(closeDialog())        
    }

    return (
        <PaddedPaper>
            <Grid container alignItems='center'>
                <Grid item xs={12} lg>
                    <Typography variant="h6">Purchase Order #{po.po_reference}{isCrushedIce() ? ` [ci:po_id:${po.po_id}]` : ``}</Typography>
                </Grid>
                <Grid item>
                    <InsightChip icon="check" label={po.po_status} paddingLeft={8} marginRight={8} large />
                </Grid>
                <Grid item>
                    <InsightChip
                        icon="truck-loading"
                        label={po.po_type === "Sales Order" || (po.po_type === "Stock" && po.po_order_id > 0 && po.po_ifm_modified === 0) ? "Sales Order Fulfilment" : "Warehouse Stock"}
                        paddingLeft={8}
                        marginRight={8}
                        large
                    />
                </Grid>
                {(po.po_collection === 1 && (
                    <Grid item>
                        <InsightChip icon="user-check" label={"Direct Collection"} paddingLeft={8} marginRight={8} large />
                    </Grid>
                )) ||
                    (po.po_ship_aa_only === 0 && (
                        <Grid item>
                            <InsightChip icon="user-check" label={"Direct Despatch"} paddingLeft={8} marginRight={8} large />
                        </Grid>
                    )) || (
                        <Grid item>
                            <InsightChip icon="building" label={"Ship Here"} paddingLeft={8} marginRight={8} large />
                        </Grid>
                    )}
                {po?.deliveries?.[0]?.od && (
                    <Grid item>
                        <InsightChip
                            border={"1px solid rgba(0, 0, 0, 0.1)"}
                            background="rgba(0, 0, 0, 0.035)"
                            icon="external-link"
                            label={`${ui?.device.isPortrait ? "SO" : "Sales Order"} #${po?.deliveries?.[0]?.od?.od_reference}`}
                            onClick={() => inDialog ? window.open(`/sales/${po?.deliveries?.[0]?.od?.od_order_id}`) : history.push(`/sales/${po?.deliveries?.[0]?.od?.od_order_id}`)}
                            paddingLeft={8}
                            large
                        />
                    </Grid>
                )}
                {inDialog && (
                    <>
                        <Grid item>
                            <Box ml={2}>
                                <Tooltip title="Open Purchase Order">
                                    <IconButton
                                        onClick={handlePopUpToPage}
                                    >
                                        <FAIcon icon="binoculars" size={19} button noMargin />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box width={50} />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Grid container justify="flex-end">
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                noBg
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: "Supplier",
                                        value: po.supplier?.supp_company_name,
                                    },
                                    {
                                        heading: "Trading Address",
                                        disabled: suppAddressEmpty,
                                        value: _.map(suppAddress, (line, idx) => {
                                            if (line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component="div">
                                                        {line}
                                                    </Typography>
                                                );
                                            }
                                        }),
                                    },
                                    {
                                        heading: "Contact Phone",
                                        value: po.supplier?.supp_phone,
                                    },
                                    {
                                        heading: "Contact E-mail",
                                        value: po.supplier?.supp_email,
                                    },
                                    {
                                        heading: "Supplier Reference",
                                        value: !_.isEmpty(po.po_supp_reference) ? po.po_supp_reference : "N/A",
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: "Invoice To",
                                        disabled: invoiceToEmpty,
                                        value: _.map(invoiceTo, (line, idx) => {
                                            if (line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component="div">
                                                        {line}
                                                    </Typography>
                                                );
                                            }
                                        }),
                                    },
                                    {
                                        heading: "Deliver To",
                                        disabled: deliveryToEmpty,
                                        value: _.map(deliveryTo, (line, idx) => {
                                            if (line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component="div">
                                                        {line}
                                                    </Typography>
                                                );
                                            }
                                        }),
                                    },
                                    {
                                        heading: "Delivery Instructions",
                                        disabled: _.isEmpty(po.po_delivery_address_notes),
                                        value: (
                                            <Typography variant="body2" component="div">
                                                {po.po_delivery_address_notes}
                                            </Typography>
                                        ),
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: `Despatch Method`,
                                        value: po.carriage ? `${po.carriage?.supp_carr_courier} ${po.carriage?.supp_carr_service}` : "Manual | See Notes",
                                    },
                                    {
                                        heading: `Despatch Type`,
                                        value: po.po_collection === 1 ? `Direct Collection` : po.po_ship_aa_only === 1 ? `Ship Here` : `Direct Despatch`,
                                    },
                                    {
                                        heading: po.po_collection === 1 ? "Ready To Collect" : "Delivered",
                                        value: po.po_delivered === 1 ? "Yes" : "No",
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {(po.po_status === "Cancelled" && (
                                <Box pb={1} width="100%">
                                    <Alert severity="error">
                                        <strong>Cancelled</strong><br />
                                        This purchase order delivery for {po.po_type === 'Sales Order' ? (po.so?.order_reference ? `sales order ${po.so?.order_reference}` : `sales order fulfilment`) : 'warehouse stock'} has been cancelled
                                    </Alert>
                                </Box>
                            )) || (po.po_type === "Stock" && po.po_order_id > 0 && po.po_ship_here_order === 1 && (
                                <Box pb={1} width="100%">
                                    <Alert severity={po?.deliveries?.[0]?.od?.od_sys_hold === 1 ? "error" : "warning"}>
                                        <strong>Sales Order {po?.deliveries?.[0]?.od?.od_reference ? `#${po?.deliveries?.[0]?.od?.od_reference} ` : ""}Fulfilment - Ship Here</strong>
                                        <br />
                                        {(po?.deliveries?.[0]?.od?.od_sys_hold === 1 &&
                                            `The sales order has been modified, this process must be completed prior to interacting with the purchase order`) ||
                                            `Stock from this purchase order will be forwarded to the customer delivery address once received`}
                                    </Alert>
                                </Box>
                            )) ||
                                (po.po_order_id > 0 && po.po_ship_aa_only === 0 && (
                                    <Box pb={1} width="100%">
                                        <Alert severity={po?.deliveries?.[0]?.od?.od_sys_hold === 1 ? "error" : "info"}>
                                            <strong>
                                                Sales Order {po?.deliveries?.[0]?.od?.od_reference ? `#${po?.deliveries?.[0]?.od?.od_reference} ` : ""}Fulfilment -{" "}
                                                {po.po_collection === 1 ? `Collection` : `Direct Delivery`}
                                            </strong>
                                            <br />
                                            {(po?.deliveries?.[0]?.od?.od_sys_hold === 1 &&
                                                `The sales order has been modified, this process must be completed prior to interacting with the purchase order`) ||
                                                `This purchase order will be ${po.po_collection === 1 ? `collected direct from the supplier` : `delivered directly to the customer`}`}
                                        </Alert>
                                    </Box>
                                )) ||
                                (po.po_type === "Stock" && (
                                    <Box pb={1} width="100%">
                                        <Alert severity="info">
                                            <strong>Warehouse Stock</strong>
                                            <br />
                                            This purchase order has been placed to replenish warehouse stock
                                        </Alert>
                                    </Box>
                                ))}
                            <Grid container spacing={0} justify="flex-end">
                                {!_.isEmpty(po.po_notes) && (
                                    <Grid item xs={12}>
                                        <Box pt={3} pl={2} pr={2}>
                                            {po.po_notes.split("\n").map((item, key) => (
                                                <Typography variant="body2" key={key} align="center" className="fw-400" gutterBottom>
                                                    {item}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Products
                                        classes={classes}
                                        inline={true}
                                        rows={po.products}
                                        discountTotal={po.po_total_discount}
                                        subTotal={po.po_total_sub}
                                        netTotal={po.po_total_net}
                                        carriageTotal={po.po_total_carriage}
                                        vatTotal={po.po_total_vat}
                                        grossTotal={po.po_total_gross}
                                        readOnly
                                        showDelivered
                                        showAllocationStatus
                                        status={po.po_status}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Box pt={1}>
                                        <Totals
                                            classes={classes}
                                            aaVatPercent={po.vat?.vat_value_percentage}
                                            subTotal={po.po_total_cost}
                                            carriageTotal={po.po_total_carriage}
                                            netTotal={po.po_total_net}
                                            vatTotal={po.po_total_vat}
                                            grossTotal={po.po_total_gross}
                                            inForm={false}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PaddedPaper>
    );
};

export default PurchaseOrderSummary;
