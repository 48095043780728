import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import ManualDateInput from 'Components/Common/Inputs/ManualDateInput';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AsyncSelect from 'Components/Common/Selects/AsyncSelect';
import { CLOUDFRONT_URL } from 'Constants';
import { handleChange, handleCheckedChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { loadPulseData } from 'Redux/Actions/Notifications/Notifications';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import FAIcon from '../Icons/FontAwesome/FAIcon';
import Textarea from '../Inputs/Textarea';
import AutoCompleteSelect from '../Selects/AutoCompleteSelect';
// import { TextField } from '@material-ui/core';
import { bankHolidays } from '../DatePickers/BankHolidays';

const formatOptionLabel = ({context, label, c, p, co, s, subtitle1, subtitle2, am}, props) => {
    if(props?.context === "value") {
        return (
            <Box pt={0.5} pb={0.5}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item align='center'>
                        <FAIcon type="thin" icon={c === 1 ? 'users' : (s === 1 ? 'truck' : (co === 1 || co === 2 ? (p === 1 ? 'star' : 'user') : 'arrow-right'))} className="mr-1" size={25} noMargin button />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" className="fw-400">
                            {label}
                        </Typography>
                        {subtitle1 && ( 
                            <Typography variant="caption" component="div" >
                                {subtitle1}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        )
    } else {
        return (
            <Box pt={0.5} pb={0.5}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item align='center'>
                        <FAIcon type="thin" icon={c === 1 ? 'users' : (s === 1 ? 'truck' : (co === 1 || co === 2 ? (p === 1 ? 'star' : 'user') : 'arrow-right'))} className="mr-1" size={25} noMargin button />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" className="fw-400">
                            {label}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" component="div">
                            {p === 1 ? 'Primary ' : ''}{c === 1 ? 'Customer' : (s === 1 ? 'Supplier' : (co === 1 ? 'Customer Contact' : 'Supplier Contact'))}
                        </Typography>
                        {subtitle1 && ( 
                            <Typography variant="caption" component="div" >
                                {subtitle1}
                            </Typography>
                        )}
                        {subtitle2 && ( 
                            <Typography variant="caption" component="div" >
                                {subtitle2}
                            </Typography>
                        )}
                        {c === 1 && !_.isEmpty(am) && (
                            <Typography variant="caption" component="div" >
                                Managed by {am}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

const styles = theme => ({
    card: {
        color: '#fff',
        height: '270px',
        padding: '20px'
    },
    cardHeader: {
        borderLeft: '4px solid #fff',
        borderRight: '3px solid #fff',
    },
    cardHeaderAction: {
        marginTop: -14,
        paddingRight: 10
    },
    cardHeaderPriority: {
        borderLeft: '4px solid #ef3340',
        borderRight: '3px solid #fff',
    },
    cardContent: {
        padding: '0 16px 16px 16px'
    },
    cardActionArea: {
        alignSelf: 'center',
        marginTop: 0,
        marginRight: 0
    },
})

const initialState = (defaultType = null) => ({
    access: {
        assignActions: false
    },
    asyncValue: '',
    defaultOptions: [],
    dateCheck: null,
    dateCheckLoading: false,
    dateStrings: {
        taskDateTime: ''
    },
    formData: {
        taskType: defaultType ?? "",
        taskRel: "",
        taskRelId: 0,
        taskAssignee: "",
        taskMessage: "",
        taskRemindMe: "hour",
        taskDateTime: moment().add(1, 'hours').format('YYYY-MM-DD HH:mm') + ':00',
        taskRepeat: 0,
        taskRepeatOccurance: 1,
        taskRepeatUnit: 'day',
    },
    formErrors:[],
    isLoading: true,
    isFriday: moment().day() === 5,
    isTomorrowBh: bankHolidays?.includes?.(moment().add(moment().day() === 5 ? 3 : 1, 'days').format('YYYY-MM-DD')),
    types: []
})

class TaskForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(this.props.defaultType);        
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        
        if(this.props.defaultSearch) {
            this.getActionSearch();
        }

        let params = {}
        if(this.props.all) {
            params = {
                forAll: true
            }
        } else if(!this.props.update) {
            params = {
                forRelation: this.props.relation
            }
        }

        Promise.all([
            API.access('action-center:assign-actions'),
            API.get(`/misc/actions/types`, 
                {
                    params
                }
            )
        ])
        .then(([
            assignActions,
            actionTypes
        ]) => {

            if(actionTypes?.data) {

                const { update } = this.props;

                this.setState({
                    access: {
                        assignActions: (assignActions.data && assignActions.data.has_access) || false
                    },
                    asyncValue: this.props.defaultOption ?? '',
                    formData: {
                        ...this.state.formData,
                        taskRel: this.props.relation,
                        taskRelId: this.props.id,
                        taskAssignee: {
                            label: this.props.staff[this.props.assignee ? parseInt(this.props.assignee) : (parseInt(this.props.loggedInStaff?.id ?? 0))]?.name ?? null,
                            value: this.props.staff[this.props.assignee ? parseInt(this.props.assignee) : (parseInt(this.props.loggedInStaff?.id ?? 0))]?.id ?? null,
                            isTeam: 0
                        },
                        taskRemindMe: this.props?.defaultType === 3 ? 'specific' : this.state.formData.taskRemindMe,
                        taskDateTime: this.props?.defaultType === 3 ? null : this.state.formData.taskDateTime,
                    },
                    isLoading: update ? true : false,
                    types: actionTypes.data
                }, () => {
                    if(update) {
                        this.getUpdateData(update);
                    }
                })
                
            }

        })
    }

    getActionSearch = () => {
        API.get('/smartSearch', 
            {
                props: {
                    cancellation: true,
                    noLoading: true
                },
                params: { 
                    searchString: this.props.defaultSearch,
                    type: 'actions'
                }
            }
        )
        .then(res => {
            
            this.setState({
                defaultOptions: res?.data?.results ?? []
            })

        })
    }

    getActionTypes = () => {

        const { formData:{taskRel} } = this.state;
        let params = {}

        if(taskRel === 'generic') {
            params = {
                forAll: true
            }
        } else {
            params = {
                forRelation: taskRel
            }
        }

        API.get(`/misc/actions/types`, 
            {
                params
            }
        )
        .then(actionTypes => {
            if(actionTypes?.data) {
                this.setState({
                    types: actionTypes.data
                })
            }
        })

    }

    getUpdateData = id => {
        API.get(`/misc/actions/${id}`)
        .then(result => {
            if(result?.data) {
                let taskRemindMe = "hour";
                let taskDateTime = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm') + ':00';
                if(result.data.task_reminder_datetime !== "0000-00-00 00:00:00") {
                    if(moment(result.data.task_reminder_datetime).isAfter(moment())) {
                        taskRemindMe = "specific";
                        taskDateTime = moment(result.data.task_reminder_datetime).format('YYYY-MM-DD HH:mm') + ':00';
                    }
                }
                let taskAssignee = {
                    label: null,
                    value: null,
                    isTeam: 0
                }

                if(result.data.task_assigned_staff_id > 0) {
                    taskAssignee = {
                        label: this.props.staff?.[result.data.task_assigned_staff_id]?.name ?? null,
                        value: this.props.staff?.[result.data.task_assigned_staff_id]?.id ?? null,
                        isTeam: 0
                    }
                } else {
                    taskAssignee = {
                        label: this.props.teams?.[result.data.task_team_id]?.name ?? null,
                        value: this.props.teams?.[result.data.task_team_id]?.id ?? null,
                        isTeam: 1
                    }
                }

                this.setState({
                    dateStrings: {
                        taskDateTime: moment(taskDateTime, 'YYYY-MM-DD HH:mm:ss').format('DDMMYYYYHHmm')
                    },
                    formData: {
                        ...this.state.formData,
                        taskType: result.data.task_type,
                        taskAuthor: result.data.task_author_staff_id,
                        taskMessage: result.data.task_message,
                        taskRepeat: parseInt(result.data.trs),
                        taskRepeatOccurance: parseInt(result.data.tro),
                        taskRepeatUnit: result.data.tru,
                        taskNextDue: result.data.rnd,
                        taskAssignee,
                        taskRemindMe,
                        taskDateTime
                    },
                    isLoading: false
                });
            }
        })
    }

    handleAsyncSelectChange = option => {

        if(option?.value) {

            let selected = option.value.split(":");

            this.setState({
                asyncValue: option,
                formData: {
                    ...this.state.formData,
                    taskRel: selected[0],
                    taskRelId: selected[1]
                }
            }, this.getActionTypes)

        } else {

            this.setState({
                asyncValue: option,
                formData: {
                    ...this.state.formData,
                    taskRel: 'generic',
                    taskRelId: ''
                }
            }, this.getActionTypes)

        }

    }

    handleDateChange = (fieldName, value) => {
        this.setState({
            ...this.state,
            dateStrings: {
                ...this.state.dateStrings,
                [fieldName]: value
            },
            formData: {
                ...this.state.formData,
                [fieldName]: value.length === 12 ? (value.substr(4, 4) + "-" + value.substr(2, 2) + "-" + value.substr(0, 2) + " " + value.substr(8, 2) + ":" + value.substr(10, 2)) : ''
            }
        }, () => {
            const {
                formData
            } = this.state;
            /* Account reviews on this date check */
            if(formData.taskType === 3 && fieldName === 'taskDateTime' && value.length === 12) {
                this.setState({
                    dateCheckLoading: true
                }, () => {
                    API.put(`/misc/actions/accountReviews/checkDate`, { date: formData.taskDateTime.substr(0, 10) })
                    .then(res => {
                        if(res?.data) {
                            this.setState({
                                dateCheckLoading: false,
                                dateCheck: res.data.c
                            })
                        }
                    });
                })
            }
        })
    }

    handleRemindMeChange = selectedOption => {

        const {
            isFriday,
            isTomorrowBh
        } = this.state;

        const selection = selectedOption && selectedOption.value;
        let overwriteDateTime;

        switch(selection) {
            case 'specific':
                overwriteDateTime = null;
            break;
            case 'now':
                overwriteDateTime = moment().format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'hour':
                overwriteDateTime = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'day':
                overwriteDateTime = moment().add(1, 'days').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'week':
                overwriteDateTime = moment().add(1, 'weeks').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'tomorrow-morning':

                if(isTomorrowBh) {

                    let i = 2;

                    do {

                        let d   = moment().add(i, 'days'),
                            day = d?.day?.() ?? 0;

                        if(day !== 0 && day !== 6 && !bankHolidays.includes(d.format('YYYY-MM-DD'))) {
                            overwriteDateTime = d.format('YYYY-MM-DD') + ' 09:00:00';
                        }
                        
                        i++
                    } while (_.isEmpty(overwriteDateTime))

                } else {
                    overwriteDateTime = moment().add(isFriday ? 3 : 1, 'days').format('YYYY-MM-DD') + ' 09:00:00';
                }

            break;
            case 'tomorrow-afternoon':

                if(isTomorrowBh) {

                    let i = 2;

                    do {

                        let d   = moment().add(i, 'days'),
                            day = d?.day?.() ?? 0;

                        if(day !== 0 && day !== 6 && !bankHolidays.includes(d.format('YYYY-MM-DD'))) {
                            overwriteDateTime = d.format('YYYY-MM-DD') + ' 13:00:00';
                        }
                        
                        i++
                    } while (_.isEmpty(overwriteDateTime))

                } else {
                    overwriteDateTime = moment().add(isFriday ? 3 : 1, 'days').format('YYYY-MM-DD') + ' 13:00:00';
                }

            break;
            default:
                overwriteDateTime = null;
            break;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                taskRemindMe: selectedOption && selectedOption.value,
                taskDateTime: overwriteDateTime
            }
        });
    }

    submit = () => {

        const   { update }  = this.props,
                route   = update ? `/misc/actions/${update}` : `/misc/actions`;

        API.post(route, this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    formData: initialState().formData
                }, () => {
                    this.loadComponentData();
                    this.props.loadPulseData()
                    this.props.deploySnackBar(`success`, `You have successfully ${update ? `updated this` : `created a new`} action`)
                    this.props.callback?.();
                    this.props.toggleDialog?.();
                })
            }
        })
        
    }

    cancel = () => {
        this.setState({
            formData: {
                ...initialState().formData
            },
            isLoading: true
        }, () => {
            if(this.props.toggleDialog) {
                this.props.toggleDialog();
            } else {
                this.loadComponentData();
            }
        })
    }

    loadEmailOptions = (searchString, callback, selectRef) => {
        API.get('/smartSearch', 
            {
                props: {
                    cancellation: true,
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'actions'
                }
            }
        )
        .then(res => {
            
            this.setState({
                defaultOptions: res?.data?.results ?? []
            })

            if(res?.data) {
                callback(res?.data?.results ?? [])
            } else {
                callback([])
            }

            if(selectRef?.current?.focus) {
                selectRef.current.focus()
            }

        })
    }

    handleSelectChange = (fieldName, selectedOption) => {
        let value = selectedOption && selectedOption.value;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [fieldName]: value,
                },
            },
            () => {
                /* Account review preset specific date */
                if (fieldName === "taskType" && selectedOption?.value === 3) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            taskRemindMe: 'specific',
                            taskDateTime: null
                        }
                    })
                }
            }
        );
    }
    
    handleAssignmentChange = taskAssignee => {
        this.setState({
            formData: {
                ...this.state.formData,
                taskAssignee: {
                    ...taskAssignee,
                    isTeam: !taskAssignee?.isTeam ? 0 : taskAssignee.isTeam
                },
            }
        })
    }

    render = () => {
        const { classes, defaultType, update, noElevation, loggedInStaff, mandatory, staffList, staff, teamList } = this.props;
        const { access, asyncValue, dateCheck, defaultOptions, formData, formErrors, isFriday, isTomorrowBh, isLoading, types } = this.state;

        const action = types?.[_.findIndex(types, el => el.value === formData.taskType || el.tt_name === formData.taskType)] ?? null;
        
        const assignmentList = [
            {
                label: 'Teams',
                options: teamList
            },
            {
                label: 'Staff Members',
                options: staffList
            }
        ];

        let dateHelperText = undefined;

        if(this.state.dateStrings.taskDateTime?.length >= 8 && this.state.dateStrings.taskDateTime?.length < 12) {
            
            const   d   = moment(this.state.dateStrings.taskDateTime, "DDMMYYYY"),
                    day = d?.day?.() ?? 0;

                    if(d.isValid()) {

                const   dD = this.state.dateStrings.taskDateTime?.substr(0,2),
                        dM = this.state.dateStrings.taskDateTime?.substr(2,2),
                        dY = this.state.dateStrings.taskDateTime?.substr(4,4);

                if(parseInt(dD) < 1 || parseInt(dD) > 31 || parseInt(dM) < 1 || parseInt(dM) > 12 || parseInt(dY) < 1900) { 
                    dateHelperText = `Please enter a valid date`

                } else {

                    if(day === 0 || day === 6 || bankHolidays?.includes?.(d.format('YYYY-MM-DD'))) {
                        dateHelperText = `The date you have entered is a ${(day === 0 && 'Sunday') || (day === 6 && 'Saturday') || 'Bank Holiday'}`
                    }

                    if(d.isBefore(moment())) {
                        dateHelperText = `Please enter a date in the future`
                    }

                }

            } else {
                dateHelperText = `Please enter a valid date / time`
            }
        } else if(this.state.dateStrings.taskDateTime?.length === 12) {
            
            const   d   = moment(this.state.dateStrings.taskDateTime, "DDMMYYYYHHmm"),
                    day = d?.day?.() ?? 0;

                    if(d.isValid()) {

                const   dD = this.state.dateStrings.taskDateTime?.substr(0,2),
                        dM = this.state.dateStrings.taskDateTime?.substr(2,2),
                        dY = this.state.dateStrings.taskDateTime?.substr(4,4),
                        tH = this.state.dateStrings.taskDateTime?.substr(8,2),
                        tM = this.state.dateStrings.taskDateTime?.substr(10,2);

                if(parseInt(dD) < 1 || parseInt(dD) > 31 || parseInt(dM) < 1 || parseInt(dM) > 12 || parseInt(dY) < 1900 || parseInt(tH) < 0 || parseInt(tH) > 23 || parseInt(tM) < 0 || parseInt(tM) > 59) { 
                    dateHelperText = `Please enter a valid date / time`

                } else {

                    if(day === 0 || day === 6 || bankHolidays?.includes?.(d.format('YYYY-MM-DD'))) {
                        dateHelperText = `The date you have entered is a ${(day === 0 && 'Sunday') || (day === 6 && 'Saturday') || 'Bank Holiday'}`
                    }

                    if(d.isBefore(moment())) {
                        dateHelperText = `Please enter a date in the future`
                    }

                }

            } else {
                dateHelperText = `Please enter a valid date / time`
            }
        }

        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <Card elevation={noElevation ? 0 : undefined} classes={classes.cardHeader}>
                    <Box p={1.5} pb={1}>
                        <CardHeader
                            avatar={
                                <Avatar alt={staff?.[formData?.taskAuthor]?.name ?? loggedInStaff?.name ?? ''} src={staff?.[formData?.taskAuthor]?.picture ? `${CLOUDFRONT_URL}${staff?.[formData?.taskAuthor]?.picture}` : loggedInStaff?.picture ?? ''} style={{width: 35, height: 35}}>
                                    {staff?.[formData?.taskAuthor] ? staff[formData?.taskAuthor].name.charAt(0) : loggedInStaff?.firstName?.charAt(0)}
                                </Avatar>
                            }        
                            classes={{action: classes.cardHeaderAction}}                                                            
                            title={(
                                <>
                                    {staff?.[formData?.taskAuthor]?.name ?? loggedInStaff?.name ?? ''}
                                    {` · `}
                                    <Typography variant="caption" color={'textSecondary'}>
                                        {`${update ? 'Modify' : 'Create'} Action`}
                                    </Typography>
                                </>
                            )}
                        />
                        <CardContent className={classes.cardContent}>
                            <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                {!update && (this.props.relation === 'generic' || this.props.relation === 'customer' || this.props.relation === 'supplier') && (
                                    <AsyncSelect
                                        formatOptionLabel={formatOptionLabel}
                                        apiCall={_.debounce(this.loadEmailOptions, 250)}
                                        defaultOptions={defaultOptions}
                                        label={formData.taskRel === 'generic' ? 'Customer / Supplier *' : (formData.taskRel === 'customer' ? 'Customer *' : (formData.taskRel === 'supplier' ? 'Supplier *' : 'Contact *'))}
                                        onChange={this.handleAsyncSelectChange}
                                        value={asyncValue}
                                        style={{height: 100}}
                                        disabled={defaultType ? true : false}
                                    />
                                )}
                                {(update || (!update && this.props.relation !== 'generic') || (!update && this.props.relation === 'generic' && formData.taskRel !== 'generic')) && (
                                    <>
                                        {access.assignActions && (
                                            <FormControl fullWidth margin={formData.taskRemindMe === 'specific' ? 'normal' : 'none'}>
                                                <AutoCompleteSelect
                                                    label='Assign To:'
                                                    options={assignmentList}
                                                    value={formData.taskAssignee}
                                                    onChange={this.handleAssignmentChange}
                                                    error={formErrors && formErrors['taskAssignee'] && true}
                                                    noClear
                                                    cleanValue
                                                />
                                            </FormControl>
                                        )}
                                        {!update && (
                                            <FormControl fullWidth>
                                                <AutoCompleteSelect
                                                    label='Action *'
                                                    options={types} 
                                                    value={formData.taskType}
                                                    onChange={v => this.handleSelectChange('taskType', v)}
                                                    disabled={defaultType}
                                                    noClear
                                                />
                                            </FormControl>
                                        )}
                                        {action?.reminder === 1 && (
                                            <>
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect
                                                        label='When'
                                                        options={formData.taskType === 3 ? [
                                                            {label: 'Specify date & time', value: 'specific'},
                                                        ] : [
                                                            {label: 'In an hour', value: 'hour'},
                                                            {label: 'In a day', value: 'day'},
                                                            {label: 'In a week', value: 'week'},
                                                            {label: `${isTomorrowBh ? 'Next working' : (isFriday ? 'Monday' : 'Tomorrow')} morning (9am)`, value: 'tomorrow-morning'},
                                                            {label: `${isTomorrowBh ? 'Next working' : (isFriday ? 'Monday' : 'Tomorrow')} afternoon (1pm)`, value: 'tomorrow-afternoon'},
                                                            {label: 'Specify date & time', value: 'specific'},
                                                        ]} 
                                                        noDefaultSort
                                                        noClear
                                                        value={formData.taskRemindMe}
                                                        onChange={this.handleRemindMeChange}
                                                        error={formErrors && formErrors['taskRemindMe'] && true}
                                                    />
                                                </FormControl>
                                                {formData.taskRemindMe === 'specific' && (
                                                    <Box width='75%' mb={1}>
                                                        <ManualDateInput
                                                            error={!_.isEmpty(dateHelperText)}
                                                            helperText={dateHelperText}
                                                            handleChange={v => this.handleDateChange('taskDateTime', v)}
                                                            value={this.state.dateStrings.taskDateTime}
                                                            variant="date-time"
                                                            placeholder={{
                                                                1: 'DD',
                                                                2: 'MM',
                                                                3: 'YYYY',
                                                                4: 'HH',
                                                                5: 'MM'
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                {formData.taskType === 3 && dateCheck !== null && dateCheck > 0 && (
                                                    <Box pt={2} mb={2}>
                                                        <Alert severity="warning">
                                                            <Typography variant="caption">
                                                                You have {dateCheck} account review{dateCheck > 1 ? 's' : ''} due for this date already
                                                            </Typography>
                                                        </Alert>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                        {action?.notes === 1 && (
                                            <FormControl fullWidth>
                                                <Typography variant="caption" color="textSecondary" className="mb-1">
                                                    {`${action?.reqNotes === 1 ? 'Notes *' : 'Notes'}`}
                                                </Typography>
                                                <Textarea
                                                    margin="none"
                                                    name="taskMessage"
                                                    value={formData.taskMessage}
                                                    error={formErrors && formErrors['taskMessage']}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    rows={formData.taskType === 'Note' ? 5 : 6}
                                                    style={{height: '100%'}}
                                                />
                                            </FormControl>
                                        )}
                                        {action?.repeat === 1 && (
                                            <Box pt={1}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            checked={formData.taskRepeat === 1 ? true : false} 
                                                            onChange={this.handleCheckedChange} 
                                                            value={formData.taskRepeat}
                                                            name="taskRepeat"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={`Repeat ${action?.label ?? action?.tt_name}`}
                                                />
                                                {formData.taskRepeat === 1 && (
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item>
                                                            <Typography variant="body2">
                                                                Repeat Every
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <AutoCompleteSelect
                                                                options={[
                                                                    {label: '1', value: 1},
                                                                    {label: '2', value: 2},
                                                                    {label: '3', value: 3},
                                                                    {label: '4', value: 4},
                                                                    {label: '5', value: 5},
                                                                    {label: '6', value: 6},
                                                                    {label: '7', value: 7},
                                                                    {label: '8', value: 8},
                                                                    {label: '9', value: 9},
                                                                    {label: '10', value: 10},
                                                                    {label: '11', value: 11},
                                                                    {label: '12', value: 12},
                                                                ]} 
                                                                value={formData.taskRepeatOccurance}
                                                                onChange={v => this.handleSelectChange('taskRepeatOccurance', v)}
                                                                error={formErrors && formErrors['taskRepeatOccurance'] && true}
                                                                noDefaultSort
                                                                noClear
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <AutoCompleteSelect
                                                                options={[
                                                                    {label: `day${formData.taskRepeatOccurance > 1 ? 's' : ''}`, value: `day`},
                                                                    {label: `week${formData.taskRepeatOccurance > 1 ? 's' : ''}`, value: `week`},
                                                                    {label: `month${formData.taskRepeatOccurance > 1 ? 's' : ''}`, value: `month`},
                                                                    {label: `year${formData.taskRepeatOccurance > 1 ? 's' : ''}`, value: `year`},
                                                                ]} 
                                                                disabled={!formData.taskRepeatOccurance}
                                                                value={formData.taskRepeatUnit}
                                                                onChange={v => this.handleSelectChange('taskRepeatUnit', v)}
                                                                error={formErrors && formErrors['taskRepeatUnit'] && true}
                                                                noDefaultSort
                                                                noClear
                                                            />
                                                        </Grid>
                                                        <Grid item xs />
                                                    </Grid>
                                                )}
                                            </Box>
                                        )}
                                    </>
                                )}
                                <div className="buttonRow">
                                    {!mandatory && (
                                        <Button 
                                            onClick={() => this.cancel()}
                                            variant="text"
                                        >
                                            <FAIcon icon="times" size={15} button />
                                            {update ? 'Cancel' : 'Discard'}
                                        </Button>
                                    )}
                                    <Button 
                                        onClick={() => this.props.deployConfirmation(`Are you sure you want to ${update ? 'update' : 'add'} this action?`, this.submit)}
                                        variant="text" 
                                        color="primary" 
                                        disabled={!formData.taskType || formData?.taskType === '' || formData?.taskRel === 'generic' || (action?.reminder === 1 && formData.taskRemindMe === 'specific' && (formData.taskDateTime === null || formData.taskDateTime === "" || dateHelperText)) || (action?.reqNotes === 1 && _.isEmpty(formData.taskMessage))}
                                    >
                                        <FAIcon icon="check" size={15} button />
                                        {update ? `Update` : `Add Action`}
                                    </Button>
                                </div>                     
                            </form>  
                        </CardContent>
                    </Box>
                </Card>
            )
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        staffList: state.notifications.staffList,
        staff: state.notifications.status,
        teams: state.notifications.teams,
        teamList: state.notifications.teamList
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadPulseData: () => dispatch(loadPulseData()),
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskForm));