import _ from "lodash";
import { useHistory } from 'react-router-dom';

import { 
    Box,
    Grid,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    Tooltip,
    Typography
} from "@material-ui/core";

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { useDispatch } from "react-redux";
import { deployDialog } from "Redux/Actions/Dialog/Dialog";
import WildixCallNotes from "./WildixCallNotes";

const WildixRecentCalls = ({calls, excludeRecentInternal, onDial, refresh, toggleExcludeInternal}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const handleUpdateNotes = id => {
        dispatch(deployDialog(
            <WildixCallNotes callback={refresh} id={id} />,
            false,
            "Update Call Notes",
            "standard",
            "xs",
            false,
            true
        ))
    }
    
    return (
        <>
            <Grid container justify="space-between" alignItems="center" className="pb-1">
                <Grid item xs>
                    <Typography variant="h6" className="fw-400" align="left">
                        Recents
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        className="m-0"
                        checked={!!excludeRecentInternal} 
                        control={
                            <Switch color="primary" onChange={toggleExcludeInternal} value="1" />
                        }
                        label={<Typography variant="body2" className="fw-400">Exclude Internal</Typography>}
                    />
                </Grid>
            </Grid>
            <Box style={{height: '100%', overflowY: 'auto', border: '1px solid #ddd'}}>
                <List dense className="p-0">
                    {(_.isEmpty(calls) && (
                        <Box className="mt-2">
                            <Typography variant="body2" align="center">
                                <em>No recent calls to display</em>
                            </Typography>
                        </Box>
                    )) || (
                        _.map(calls, (call, index) => {
                            const incoming = call.ic === 1,
                                answered = call.st === 1,
                                backgroundColor = index % 2 === 0 ? '#fbfbfb' : '#fff';
                            return (
                                <ListItem disableGutters divider={index !== (_.size(calls) - 1)} key={index} style={{backgroundColor}}>
                                    <ListItemAvatar style={{minWidth: 30}}>
                                        {!incoming ? (
                                            <FAIcon className={`${answered ? '' : 'textError'} pl-1`} style icon={`phone-arrow-up-right`} type="light" size={12.5} />
                                        ) : <Box />}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography className={`${answered ? '' : 'textError'} fw-400`} variant="body2">
                                                    {call.nm}
                                                </Typography>
                                                {call.cn ? 
                                                    <Typography className={`${answered ? '' : 'textError'} fw-400`} variant="body2">
                                                        {call.cn}
                                                    </Typography>
                                                : null }
                                            </>
                                        }
                                        secondary={
                                            <Typography className={answered ? '' : 'textError'} component="div" variant="body2">
                                                {call.nu}
                                                {(call.si || call.ci) && (
                                                    <Tooltip title="Update Notes" enterDelay={1000}>
                                                        <Typography variant="caption" component="div" className="cursor-pointer" style={{border: '1px solid #ddd', borderRadius: 10, background: '#fcfcfc',padding: '2px 7px 2px 5px', width: 'fit-content', marginTop: 4}} onClick={() => handleUpdateNotes(call.id)}>
                                                            <FAIcon icon={'pencil-alt'} size={10} button noMargin className="pr-05" />
                                                            <em>{call.nt ?? `No notes added`}</em>
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                            </Typography>
                                        }
                                    />
                                    <Box>
                                        <Typography variant="caption" component="div">
                                            {call.ti}
                                        </Typography>
                                        <Typography variant="caption" component="div">
                                            {call.dt}
                                        </Typography>
                                    </Box>
                                    <ListItemSecondaryAction>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Tooltip title="Call Back">
                                                    <IconButton onClick={() => onDial(call.nu)} size="small">
                                                        <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={20} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {(call.si || call.ci) && (
                                                <Grid item xs={12}>
                                                    <Grid item>
                                                        <Tooltip title={`View ${call.si ? 'supplier' : 'customer'}`}>
                                                            <IconButton disabled={!call.si && !call.ci} onClick={() => history.push(call.si ? `/suppliers/${call.si}` : `/customers/${call.ci}`)} size="small">
                                                                <FAIcon icon="user-circle" type="light" button noMargin size={20} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                        )})
                    )}
                </List>
            </Box>
        </>
    )
}


export default WildixRecentCalls;