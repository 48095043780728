import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { getPresignedUrl } from 'Functions/S3Functions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const getColumns = ({access, basic, compact, customer, dispatch, handlePayCreditNote, staff}) => {
    if(basic || compact) {
        return [
            {
                heading: 'Credit Note',
                field: rowData => !_.isEmpty(rowData.ref) ? rowData.ref : '-',
                sizeToContent: true,
                dataRef: 'ref'
            },
            {
                heading: 'Date',
                field: rowData => rowData.date,
                fieldFormat: 'date',
                sizeToContent: true,
                dataRef: 'date'
            },
            {
                heading: 'Net Credit',
                field: rowData => rowData.credit ?? 0,
                sizeToContent: true,
                fieldFormat: 'currency',
                alignment: 'right',
                dataRef: 'credit'
            },
            // {
            //     heading: 'VAT',
            //     field: rowData => rowData.vat ?? 0,
            //     sizeToContent: true,
            //     fieldFormat: 'currency',
            //     alignment: 'right',
            //     dataRef: 'vat'
            // },
            // {
            //     heading: 'Gross Total',
            //     field: rowData => rowData.gross ?? 0,
            //     sizeToContent: true,
            //     fieldFormat: 'currency',
            //     alignment: 'right',
            //     dataRef: 'gross'
            // },
            {
                heading: 'Raised By',
                field: rowData => <StaffChip staff={staff[rowData.staff]} />,
                fieldFormat: 'staff',
                sizeToContent: true,
                dataRef: 'staff'
            },
            {
                heading: 'Credit Customer',
                field: rowData => rowData.cc,
                fieldFormat: 'boolean',
                sizeToContent: true,
                alignment: 'center',
                dataRef: 'cc'
            },
            {
                heading: 'Paid',
                field: rowData => rowData.re,
                fieldFormat: 'boolean',
                sizeToContent: true,
                alignment: 'center',
                dataRef: 're'
            },
            {
                heading: 'Status',
                field: rowData => rowData.status === 'Posted' ? <StatusChip status={'Posted'} /> : <CircularProgress className="ml-1" size={15} />,
                sizeToContent: true,
                dataRef: 'status'
            },
            {
                heading: 'Raised',
                field: rowData => rowData.created,
                fieldFormat: 'datetime',
                sizeToContent: true,
                dataRef: 'created'
            },
            {
                actions: rowData => {
                    return [
                        { name: 'Mark as Paid', icon: 'check',  onClick: () => dispatch(deployConfirmation(`Are you sure you want to mark this credit note as paid?`, () => handlePayCreditNote(rowData.id))), disabled: !access?.refund || rowData.re === 1},
                        { name: 'Download Credit Note PDF', disabled: rowData.posted === 0, icon: 'file-pdf', color: rowData.posted === 1 ? 'error' : undefined,  onClick: () => getPresignedUrl(rowData.pdf)}
                    ]
                }
            }
        ]
    } else if(customer) {
        return [
            {
                heading: 'Credit Note',
                field: rowData => !_.isEmpty(rowData.ref) ? rowData.ref : '-',
                sizeToContent: true,
                dataRef: 'ref'
            },
            {
                heading: 'Supplier #',
                field: rowData => !_.isEmpty(rowData.suppRef) ? rowData.suppRef : '-',
                sizeToContent: true,
                dataRef: 'suppRef'
            },
            {
                heading: 'RMA #',
                field: rowData => !_.isEmpty(rowData.rmaRef) ? rowData.rmaRef : '-',
                sizeToContent: true,
                dataRef: 'rmaRef'
            },
            {
                heading: 'Date',
                field: rowData => rowData.date,
                fieldFormat: 'date',
                sizeToContent: true,
                dataRef: 'date'
            },
            {
                heading: 'Supplier',
                field: rowData => rowData.s,
                sizeToContent: true,
                dataRef: 's'
            },
            {
                heading: 'Net Credit',
                field: rowData => rowData.credit ?? 0,
                sizeToContent: true,
                fieldFormat: 'currency',
                alignment: 'right',
                dataRef: 'credit'
            },
            // {
            //     heading: 'VAT',
            //     field: rowData => rowData.vat ?? 0,
            //     sizeToContent: true,
            //     fieldFormat: 'currency',
            //     alignment: 'right',
            //     dataRef: 'vat'
            // },
            // {
            //     heading: 'Gross Total',
            //     field: rowData => rowData.gross ?? 0,
            //     sizeToContent: true,
            //     fieldFormat: 'currency',
            //     alignment: 'right',
            //     dataRef: 'gross'
            // },
            {
                heading: 'Raised By',
                field: rowData => <StaffChip staff={staff[rowData.staff]} />,
                fieldFormat: 'staff',
                sizeToContent: true,
                dataRef: 'staff'
            },
            {
                heading: 'Credit',
                field: rowData => rowData.cc,
                fieldFormat: 'boolean',
                sizeToContent: true,
                alignment: 'center',
                dataRef: 'cc'
            },
            {
                heading: 'Paid',
                field: rowData => rowData.re,
                fieldFormat: 'boolean',
                sizeToContent: true,
                alignment: 'center',
                dataRef: 're'
            },
            {
                heading: 'Status',
                field: rowData => rowData.status === 'Posted' ? <StatusChip status={'Posted'} /> : <CircularProgress className="ml-1" size={15} />,
                sizeToContent: true,
                dataRef: 'status'
            },
            {
                heading: 'Raised',
                field: rowData => rowData.created,
                fieldFormat: 'datetime',
                sizeToContent: true,
                dataRef: 'created'
            },
            {
                actions: rowData => {
                    return [
                    { name: 'Download Credit Note PDF', disabled: rowData.posted === 0, icon: 'file-pdf', color: rowData.posted === 1 ? 'error' : undefined,  onClick: () => getPresignedUrl(rowData.pdf)}
                    ]
                }
            }
        ]
    } else {
        return [
            {
                heading: 'Credit Note',
                field: rowData => !_.isEmpty(rowData.ref) ? rowData.ref : '-',
                sizeToContent: true,
                dataRef: 'ref'
            },
            {
                heading: 'Supplier #',
                field: rowData => !_.isEmpty(rowData.suppRef) ? rowData.suppRef : '-',
                sizeToContent: true,
                dataRef: 'suppRef'
            },
            {
                heading: 'RMA #',
                field: rowData => !_.isEmpty(rowData.rmaRef) ? rowData.rmaRef : '-',
                sizeToContent: true,
                dataRef: 'rmaRef'
            },
            {
                heading: 'Date',
                field: rowData => rowData.date,
                fieldFormat: 'date',
                sizeToContent: true,
                dataRef: 'date'
            },
            {
                heading: 'Customer',
                field: rowData => rowData.c,
                sizeToContent: true,
                dataRef: 'c'
            },
            {
                heading: 'Supplier',
                field: rowData => rowData.s,
                sizeToContent: true,
                dataRef: 's'
            },
            {
                heading: 'Net Credit',
                field: rowData => rowData.credit ?? 0,
                sizeToContent: true,
                fieldFormat: 'currency',
                alignment: 'right',
                dataRef: 'credit'
            },
            // {
            //     heading: 'VAT',
            //     field: rowData => rowData.vat ?? 0,
            //     sizeToContent: true,
            //     fieldFormat: 'currency',
            //     alignment: 'right',
            //     dataRef: 'vat'
            // },
            // {
            //     heading: 'Gross Total',
            //     field: rowData => rowData.gross ?? 0,
            //     sizeToContent: true,
            //     fieldFormat: 'currency',
            //     alignment: 'right',
            //     dataRef: 'gross'
            // },
            {
                heading: 'Raised By',
                field: rowData => <StaffChip staff={staff[rowData.staff]} />,
                fieldFormat: 'staff',
                sizeToContent: true,
                dataRef: 'staff'
            },
            {
                heading: 'Credit',
                field: rowData => rowData.cc,
                fieldFormat: 'boolean',
                sizeToContent: true,
                alignment: 'center',
                dataRef: 'cc'
            },
            {
                heading: 'Paid',
                field: rowData => rowData.re,
                fieldFormat: 'boolean',
                sizeToContent: true,
                alignment: 'center',
                dataRef: 're'
            },
            {
                heading: 'Status',
                field: rowData => rowData.status === 'Posted' ? <StatusChip status={'Posted'} /> : <CircularProgress className="ml-1" size={15} />,
                sizeToContent: true,
                dataRef: 'status'
            },
            {
                heading: 'Raised',
                field: rowData => rowData.created,
                fieldFormat: 'datetime',
                sizeToContent: true,
                dataRef: 'created'
            },
            {
                actions: rowData => {
                    return [
                        { name: 'Mark as Paid', icon: 'check',  onClick: () => dispatch(deployConfirmation(`Are you sure you want to mark this credit note as paid?`, () => handlePayCreditNote(rowData.id))), disabled: !access?.refund || rowData.re === 1},
                        { name: 'View RMA', icon: 'binoculars',  link: `/rma/${rowData.rma}`, disabled: !access?.viewRMA || !rowData.rma, doubleClick: true, hideIfDisabled: true},
                        { name: 'Download Credit Note PDF', disabled: rowData.posted === 0, icon: 'file-pdf', color: rowData.posted === 1 ? 'error' : undefined,  onClick: () => getPresignedUrl(rowData.pdf)}
                    ]
                }
            }
        ]
    }
}

const SearchCreditNotesTable = ({access, callback, customer, dataLoading, basic, compact, persistenceId, resetSearch, rows}) => {

    const dispatch = useDispatch();

    const staff = useSelector(state => state.notifications.status);

    const handlePayCreditNote = id => {
        API.put(`/accounts/creditNotes/${id}/paid`)
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `You have successfully marked this credit note as paid`));
                callback?.();
                resetSearch?.();
            } else {
                dispatch(deploySnackBar(`error`, `Something went wrong, please try again`));
            }
        })
    }
    
    return (
        <DataTable
            config={{
                key: 'id',
                pagination: true,
                alternatingRowColours: true,
                isLoading: dataLoading,
                inline: basic,
                plainPagination: basic,
                persistenceId: persistenceId ?? null,
                options: {
                    dataRef: true,
                    reset: resetSearch || false,
                    export: {
                        title: `Credit Notes`,
                        name: `aa-credit-notes`,
                        excel: true,
                        pdf: true,
                        print: true
                    }
                }
            }}
            columns={getColumns({access, basic, compact, customer, dispatch, handlePayCreditNote, staff})}
            rows={rows}
        />
    )
}

export default SearchCreditNotesTable;