import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import DefaultAvatar from 'Assets/Images/defaultAvatar.png';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { CLOUDFRONT_URL } from 'Constants';

const ActionChip = ({action, page, staff}) => {
    if(staff && staff.name) {
        let name        = staff.name,
            picture     = staff.picture ? `${CLOUDFRONT_URL}${staff.picture}` : DefaultAvatar,
            update      = action?.u === 1,
            process     = action?.p === 1,
            pick        = action?.pi === 1,
            check       = action?.ch === 1,
            build       = action?.bu === 1,
            despatch    = action?.de === 1,
            received    = action?.re === 1;
        return (  
            <Tooltip title={`${name} is currently ${update ? `modifying` : (process ? `processing` : (pick ? `picking` : (despatch ? `despatching` : (received ? `booking in` : (check ? 'checking' : (build ? 'building' : `viewing`))))))} this ${page}`}>
                <div>
                    <Chip
                        avatar={
                            <Avatar 
                                alt={name}
                                src={update || process || pick || check || build || despatch || received ? undefined : picture}
                                style={{
                                    background: '#fff', 
                                    // border: update || process || pick || despatch || received ? '1px solid': undefined,
                                    borderColor: update ? '#E91E63' : (process ? '#2196F3' : (pick ? '#F57C00' : (check ? '#f44336' : (despatch ? '#4CAF50' : (build ? '#3F51B5' : (received ? '#4CAF50' : '#000'))))))
                                }}
                                variant={update || process || pick || despatch || received ? 'square' : undefined}
                            >
                                <FAIcon 
                                    icon={update ? 'pencil' : (process ? 'check' : (pick ? 'scanner-keyboard' : (check ? 'check-double' : (despatch ? 'pallet-boxes' : (build ? 'screwdriver-wrench' : (received ? 'box-check' : 'cog'))))))} 
                                    size={17.5}
                                    style={{
                                        color: update ? '#E91E63' : (process ? '#2196F3' : (pick ? '#F57C00' : (check ? '#f44336' : (despatch ? '#4CAF50' : (build ? '#3F51B5' : (received ? '#4CAF50' : '#000'))))))
                                    }}
                                    noMargin
                                /> 
                            </Avatar>
                        }
                        label={name}
                        style={{
                            opacity: 1, 
                            backgroundColor: 'transparent'
                        }}
                    />
                </div>
            </Tooltip>
        )
    } else {
        return null
    }    
}

export default ActionChip;