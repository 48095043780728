import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Tiles/Tile';
import TaskList from 'Components/Common/Tasks/TaskList';
import { currencyFormat } from 'Functions/MiscFunctions';

import Bulletin from 'Components/Dashboard/Common/Bulletin';
import Calendar from 'Components/Dashboard/Common/Calendar';
import Catalogues from 'Components/Marketing/Requests/Catalogues/Catalogues';
import CustomerSocialMedia from 'Components/Marketing/Requests/CustomerSocialMedia/CustomerSocialMedia';
import CustomerSearch from 'Components/Customers/CustomerSearch/CustomerSearch';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const MarketingDashboard = ({pageTitle}) => {

    const [data, setData] = useState(null);

    const loadComponentData = () => {
        API.get(`/dashboard`, {
            params: {
                d: 'marketing'
            },
            props: {
                cancellation: true
            }
        })
        .then(res => {
            if(res?.data) {

                let newData = res.data,
                    labels = [],
                    shortLinks = [],
                    shortLinkClicks = [],
                    catalogues = [];

                if(newData.chart) {

                    for (let i = 0, j = 11; i <= j; i++) {
                        catalogues.push(res.data.chart?.[i]?.catalogues);
                        shortLinks.push(res.data.chart?.[i]?.shortLinks);
                        shortLinkClicks.push(res.data.chart?.[i]?.shortLinkClicks);
                        labels.push(res.data.chart?.[i]?.label);
                    }
                    newData.chart = {
                        labels,
                        datasets: [
                            {
                                label: 'Catalogues Sent',
                                data: catalogues,
                                fill: false,
                                backgroundColor: '#4CAF50',
                                borderColor: '#C8E6C9',
                                lineTension: 0.25,
                            },
                            {
                                label: 'Short Links',
                                data: shortLinks,
                                fill: false,
                                backgroundColor: '#ef3340',
                                borderColor: '#FFCDD2',
                                lineTension: 0.25,
                            },
                            {
                                label: 'Unique Clicks',
                                data: shortLinkClicks,
                                fill: false,
                                backgroundColor: '#ef3340',
                                borderColor: '#FFCDD2',
                                lineTension: 0.25,
                                borderDash: [2.5,2.5]
                            },
                        ]
                    }
    
                }

                setData(newData);
            }
        });
    }

    useEffect(() => {
        pageTitle?.([0, 'Dashboard', 'Marketing']);
        loadComponentData();
    }, [pageTitle]);

    return (
        (!data && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12} xl={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Monthly Sales
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='pound-sign'
                                                title="Target"
                                                content={currencyFormat.format(data?.stats?.ta?.m)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tooltip 
                                                title={(
                                                    <>
                                                        <Typography variant="body1" className="fw-400">
                                                            Breakdown
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Not Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.m?.ni)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.m?.to)}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <strong>Credits: </strong> {currencyFormat.format(data?.stats?.sa?.m?.cr)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Total: </strong> {currencyFormat.format(data?.stats?.sa?.m?.gr)}
                                                        </Typography>
                                                    </>
                                                )}
                                            >
                                                <span>
                                                    <Tile
                                                        noPaper
                                                        icon='pound-sign'
                                                        title="Sales"
                                                        content={currencyFormat.format(data?.stats?.sa?.m?.gr)}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon={data?.stats?.ta?.m - data?.stats?.sa?.m?.gr < 0 ? 'check-circle' : 'exclamation-circle'}
                                                color={data?.stats?.ta?.m - data?.stats?.sa?.m?.gr < 0 ? '#4CAF50' : '#F44336'}
                                                title="Sales To Target"
                                                content={currencyFormat.format((data?.stats?.ta?.m - data?.stats?.sa?.m?.gr).toFixed(2))}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='calendar-day'
                                                title="Days Left"
                                                content={data?.stats?.wd?.m}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Weekly Sales
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='pound-sign'
                                                title="Target"
                                                content={currencyFormat.format(data?.stats?.ta?.w)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tooltip 
                                                title={(
                                                    <>
                                                        <Typography variant="body1" className="fw-400">
                                                            Breakdown
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Not Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.w?.ni)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.w?.to)}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <strong>Credits: </strong> {currencyFormat.format(data?.stats?.sa?.w?.cr)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Total: </strong> {currencyFormat.format(data?.stats?.sa?.w?.gr)}
                                                        </Typography>
                                                    </>
                                                )}
                                            >
                                                <span>
                                                    <Tile
                                                        noPaper
                                                        icon='pound-sign'
                                                        title="Sales"
                                                        content={currencyFormat.format(data?.stats?.sa?.w?.gr)}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon={data?.stats?.ta?.w - data?.stats?.sa?.w?.gr < 0 ? 'check-circle' : 'exclamation-circle'}
                                                color={data?.stats?.ta?.w - data?.stats?.sa?.w?.gr < 0 ? '#4CAF50' : '#F44336'}
                                                title="Sales To Target"
                                                content={currencyFormat.format((data?.stats?.ta?.w - data?.stats?.sa?.w?.gr).toFixed(2))}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='calendar-day'
                                                title="Days Left"
                                                content={data?.stats?.wd?.w}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Catalogues Sent
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='envelope-open-text'
                                                title="Total"
                                                content={data?.stats?.ca?.t}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='check'
                                                title={`This Month`}
                                                content={data?.stats?.ca?.m}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        SEO Short Links ({moment().format('MMM YYYY')})
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='globe-europe'
                                                title="Active Links"
                                                content={data?.stats?.sl?.a}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='check'
                                                title={`Unique Clicks`}
                                                content={data?.stats?.cl?.m}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        New Catalogue Requests
                                    </Typography>
                                    <Catalogues
                                        inline
                                        showNewOnly
                                        dashboard
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" gutterBottom>
                                        New Customer Social Media
                                    </Typography>
                                    <Typography variant="body2" paragraph>
                                        <FAIcon icon="info-circle" size={12.5} />
                                        These social media accounts have been provided from account updates.
                                    </Typography>
                                    <Divider />
                                    <Box className="mt-1">
                                        <CustomerSocialMedia
                                            inline
                                            showNewOnly
                                            dashboard
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" gutterBottom>
                                        Onboarding - Upgraded Accounts
                                    </Typography>
                                    <Typography variant="body2" paragraph>
                                        <FAIcon icon="info-circle" size={12.5} />
                                        These accounts were previously a credit card account and have upgraded to a credit account.
                                    </Typography>
                                    <Divider />
                                    <Box className="mt-1">
                                        <CustomerSearch
                                            dashboard
                                            inline
                                            showOnboarding={3}
                                            onboardingType="upgraded credit accounts"
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" gutterBottom>
                                        Onboarding - New Credit Accounts
                                    </Typography>
                                    <Typography variant="body2" paragraph>
                                        <FAIcon icon="info-circle" size={12.5} />
                                        These accounts were previously a prospect and have now opened a credit account.
                                    </Typography>
                                    <Divider />
                                    <Box className="mt-1">
                                        <CustomerSearch
                                            dashboard
                                            inline
                                            showOnboarding={2}
                                            onboardingType="new credit accounts"
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" gutterBottom>
                                        Onboarding - New Credit Card Accounts
                                    </Typography>
                                    <Typography variant="body2" paragraph>
                                        <FAIcon icon="info-circle" size={12.5} />
                                        These accounts were previously a prospect and have now opened a credit card account.
                                    </Typography>
                                    <Divider />
                                    <Box className="mt-1">
                                        <CustomerSearch
                                            dashboard
                                            inline
                                            showOnboarding={1}
                                            onboardingType="new credit card accounts"
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Operational Insight
                                    </Typography>
                                    <Box height={400} width={'100%'} maxWidth={'100%'} minHeight={300}>
                                        <Line 
                                            data={data.chart} 
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom"
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0
                                                        }
                                                    }]
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Actions Due Today
                                    </Typography>
                                    <TaskList />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Bulletin />
                        </Grid>
                        <Grid item xs={12}>
                            <Calendar />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

export default MarketingDashboard;