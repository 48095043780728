import React from 'react';
import _ from 'lodash';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

class ProductRangesList extends React.PureComponent {

    render() {
        const { suppliers } = this.props;
        return (
            (_.isEmpty(suppliers) && (
                <Typography variant="h5" className="fw-400" align='center'>
                    There are no product ranges to show
                </Typography>
            )) || (
                _.map(suppliers, (supplier, idx) => {
                    return (
                        <ExpansionPanel key={idx}>
                            <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant="h6">{supplier.supp_company_name}</Typography>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {(_.size(supplier.ranges) > 0 && (
                                    <DataTable  
                                        idx={idx}
                                        config={{
                                            key: 'crs_id',
                                            options: {
                                                minimalPadding: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Name',
                                                field: rowData => rowData.range_name,
                                                main: true
                                            },
                                            {
                                                heading: 'Products',
                                                field: rowData => rowData.products_count,
                                                main: true
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Update', icon: 'pencil',  onClick: () => this.props.handleDialogOpen(rowData.range_id)},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={supplier.ranges}
                                    />
                                )) || (
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={<em>No ranges to show</em>} />
                                        </ListItem>
                                    </List>
                                )}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                })
            )
        );
    }
}

export default ProductRangesList;