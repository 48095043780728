import React, { useState } from 'react';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import EmailTemplates from 'Components/Marketing/Communication/EmailTemplates/EmailTemplates';
import SmsTemplates from 'Components/Marketing/Communication/SMSTemplates/SMSTemplates';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deployDialog, closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { getFormData } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleTabChange, TabPanel } from 'Functions/MiscFunctions';
import { CLOUDFRONT_URL, VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from 'Constants';

const PreviewEmail = ({close, html, send}) => {

    const   [emailAddress,setEmailAddress]  = useState(""),
            [error,setError]                = useState(false);
    
    const validateEmail = () => {
        const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(validation.test(String(emailAddress).toLowerCase())) {
            setError(false);
            send(emailAddress);
            close();
        } else {
            setError(true);
        }
    }

    return (
        <Box>
            <Box style={{background: '#fafafa'}} border='1px solid #ddd' mt={1.5}>
                <div dangerouslySetInnerHTML={{__html: `${html}`}} />
            </Box>
            <Box pt={2} style={{background: '#fff'}}>
                <Grid container alignItems='center'>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    onChange={e => setEmailAddress(e?.target?.value)}
                                    placeholder='E-mail Address:'
                                    value={emailAddress} 
                                    error={error}
                                    helperText={error ? 'Please enter a valid e-mail address' : undefined}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="envelope" size="small" /></InputAdornment>
                                    }}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    className="btn btn-success"
                                    disabled={emailAddress === ""}
                                    onClick={validateEmail}
                                    variant="contained"
                                >
                                    <FAIcon icon="paper-plane" button size={15} />
                                    E-mail Preview
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    onClick={close}
                                    variant="contained"
                                >
                                    <FAIcon icon="times" button size={15} />
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

const initialState = () => ({
    currentTab: 0,
    customisation: {
        email: {
            preheader: '',
            logoImage: '',
            signatureImage: '',
            url: '',
            outlookSignatureImage: '',
            outlookUrl: ''
        },
    },
    formData: {
        banner: '',
        motd: '',
        type:''
    },
    formErrors: [],
    keys: {
        emailLogo: uuidv4(),
        email: uuidv4(),
        outlookEmail: uuidv4()
    },
    initialCustomisation: {
        email: {
            preheader: '',
            logoImage: '',
            signatureImage: '',
            outlookSignatureImage: '',
            outlookUrl: ''
        },
    },
    tabs: [],
    isLoading: true
})

class Customisation extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.mainContentArea = React.createRef();
        this.getFormData = getFormData.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Marketing', 'Communication Settings']);
            API.multiAccess([
                'email-templates',
                'sms-templates'
            ])
            .then(([
                emailTemplates, 
                smsTemplates
            ]) => {
                this.setState({
                    isLoading: false,
                    tabs: [
                        {
                            name: 'E-mail Settings',
                            type: 'email',
                            access: true
                        },
                        {
                            divider: true
                        },
                        {
                            name: 'E-mail Templates',
                            type: 'email-templates',
                            access: emailTemplates
                        },
                        {
                            name: 'SMS Templates',
                            type: 'sms-templates',
                            access: smsTemplates
                        },
                    ]
                }, this.loadComponentData);
            })
    }

    loadComponentData = () => {

        API.get(`/settings/customisation`)
        .then(res => {

            if(res?.data) {
                let customisation           = res?.data?.customisation,
                    initialCustomisation    = res?.data?.initialCustomisation,
                    isLoading               = false,
                    keys                    = {
                        emailLogo: uuidv4(),
                        email: uuidv4(),
                        outlookEmail: uuidv4()
                    };

                this.setState({
                    customisation,
                    initialCustomisation,
                    isLoading,
                    keys
                })
            }

        })

    }

    handleChange = (name, e) => {
        const { customisation } = this.state;
        this.setState({
            customisation: {
                ...customisation,
                [e.target.name]: {
                    ...customisation[name],
                    [name]: e.target.value
                }
            }
        })
    }

    handleImageChange = (name, file) => {
        const { customisation } = this.state;
        this.setState({
            customisation: {
                ...customisation,
                banner: {
                    ...customisation.banner,
                    [name]: file
                }
            }
        })
    }

    handleEmailChange = e => {
        const { customisation } = this.state;
        this.setState({
            customisation: {
                ...customisation,
                email: {
                    ...customisation.email,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    handleEmailImageChange = (name, file) => {
        const { customisation } = this.state;
        this.setState({
            customisation: {
                ...customisation,
                email: {
                    ...customisation.email,
                    [name]: file
                }
            }
        })
    }

    handleReset = () => {
        const { initialCustomisation } = this.state;
        let customisation = {...this.state.customisation};
        customisation = {
            ...customisation,
            email: {
                preheader: initialCustomisation?.email?.preheader,
                logoImage: '',
                signatureImage: '',
                outlookSignatureImage: '',
                url: initialCustomisation?.email?.url,
                outlookUrl: initialCustomisation?.email?.outlookUrl,
            }
        }
        this.setState({
            customisation,
            keys: {
                emailLogo: uuidv4(),
                email: uuidv4(),
                outlookEmail: uuidv4()

            }
        })
    }

    handlePreview = (name, sendEmail = 0, emailAddress = null) => {
        const { customisation } = this.state;
        this.setState({
            formData: {
                emailAddress,
                sendEmail,
                preheader: customisation?.email?.preheader,
                logoImage: customisation?.email?.logoImage,
                signatureImage: customisation?.email?.signatureImage,
                url: customisation?.email?.url,
                outlookSignatureImage: customisation?.email?.outlookSignatureImage,
                outlookUrl: customisation?.email?.outlookUrl,
                type: 'email'
            }
        }, () => {
            API.post(`/settings/customisation/preview`, this.getFormData())
            .then(res => {
                if(res?.data) {
                    if(res.data?.errors) {
                        if(sendEmail === 0) {
                            this.setState({
                                formErrors: formatValidationErrors(res.data.errors)
                            });
                        } else {
                            this.props.deploySnackBar("error", "There was an issue sending the e-mail, please try again")
                        }
                    } else {
                        if(sendEmail === 0) {
                            this.setState({
                                formErrors: []
                            }, () => {
                                this.props.deployDialog(
                                    <PreviewEmail
                                        html={res.data}
                                        close={this.props.closeDialog}
                                        send={(email) => this.handlePreview('email', 1, email)}
                                    />, false, "E-mail Customisation Preview", "standard", "md"
                                )
                            })
                        } else {
                            this.props.deploySnackBar("success", "The e-mail customisation preview e-mail was successfully sent")
                        }
                    }
                }
            })
        })
    }

    handleSubmit = () => {
        const { customisation } = this.state;
        this.setState({
            formData: {
                preheader: customisation?.email?.preheader,
                logoImage: customisation?.email?.logoImage,
                signatureImage: customisation?.email?.signatureImage,
                url: customisation?.email?.url,
                outlookSignatureImage: customisation?.email?.outlookSignatureImage,
                outlookUrl: customisation?.email?.outlookUrl,
                type: 'email'
            }
        }, () => {
            API.post(`/settings/customisation`, this.getFormData())
            .then(res => {
                if(res?.data) {
                    if(res.data?.errors) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors)
                        });
                    } else {
                        this.loadComponentData();
                        this.props.deploySnackBar("success", `You have successfully updated e-mail customisation settings `)
                    }
                }
            })
        })
    }

    render() {
        const { classes, deployConfirmation, ui } = this.props;
        const { currentTab, customisation, formErrors, isLoading, initialCustomisation, keys, tabs } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            {tabs?.[currentTab]?.name ?? 'Communication Settings'}
                                                        </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Box height={41.5} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={0.25}>
                                                <Grid container justify="space-between" alignItems='center'>
                                                    <Grid item>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InsightChip
                                                                    icon="exclamation-triangle"
                                                                    label={`Any changes made will be effective immediately, please ensure you preview and check changes before updating!`}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> 
                                            </Box>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            {_.map(tabs, (tab, idx) => (
                                                <TabPanel value={currentTab} key={idx} index={idx}>
                                                    {(tab.divider && (
                                                        <React.Fragment />
                                                    )) || (tab.type === "email-templates" && (  
                                                        <EmailTemplates
                                                            deployConfirmation={this.props.deployConfirmation}
                                                            deploySnackBar={this.props.deploySnackBar}
                                                            deployDialog={this.props.deployDialog}
                                                            closeDialog={this.props.closeDialog}
                                                        />
                                                    )) || (tab.type === "sms-templates" && (
                                                        <SmsTemplates
                                                            deployConfirmation={this.props.deployConfirmation}
                                                            deploySnackBar={this.props.deploySnackBar}
                                                            deployDialog={this.props.deployDialog}
                                                            closeDialog={this.props.closeDialog}
                                                        />
                                                    )) || (tab.type === "email" && (
                                                        <>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} align='center'>
                                                                    <Paper>
                                                                        <Box p={3} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20}>
                                                                            <Box mb={3}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    E-mail Preheader
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center'>
                                                                                    This text will show under the e-mail subject in most e-mail clients
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box textAlign="center" pl={7.5} pr={7.5} pb={1}>
                                                                                <Textarea
                                                                                    name="preheader"
                                                                                    onChange={this.handleEmailChange}
                                                                                    value={customisation?.email?.preheader}
                                                                                    variant="outlined"
                                                                                    style={{textAlign: 'center'}}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Paper>
                                                                        <Box p={3} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20} align='center'>
                                                                            <Box mb={3}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    {customisation?.email?.logoImage !== '' ? 'Proposed' : 'Current'} System E-mail Logo Image
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center' gutterBottom>
                                                                                    The e-mail logo image shows at the top of the e-mail message
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box textAlign="center" width={131}>
                                                                                <Box border='1px dashed #ddd' p={1}>
                                                                                    {(customisation?.email?.logoImage !== '' && (
                                                                                        <img alt={`E-mail Signature`} src={URL.createObjectURL(customisation?.email?.logoImage)} style={{width: 115, height: 'auto'}} />
                                                                                    )) || (!_.isEmpty(initialCustomisation?.email?.logoImage) && (
                                                                                        <img alt={`E-mail Signature`} src={`${CLOUDFRONT_URL}${initialCustomisation?.email?.logoImage}`} style={{width: 115, height: 'auto'}} />
                                                                                    ))}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box p={3} pt={0} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20}>
                                                                            <Box textAlign="center" pl={7.5} pr={7.5} pb={1.25}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    Select New E-mail Logo Image
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center' gutterBottom>
                                                                                    Please drop or choose a file with a resolution of 250px x 110px
                                                                                </Typography>
                                                                                <DragFileInput
                                                                                    key={keys.emailLogo}
                                                                                    name="logoImage"
                                                                                    file={customisation?.email?.logoImage}
                                                                                    onChange={(drop, name, file) => this.handleEmailImageChange('logoImage', file)}
                                                                                    errorText={formErrors && formErrors['generic']}
                                                                                    emptyText='No image selected'
                                                                                    icon='file-image'
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Paper>
                                                                        <Box p={3} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20}>
                                                                            <Box mb={3}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    {customisation?.email?.signatureImage !== '' ? 'Proposed' : 'Current'} System E-mail Signature Image
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center' gutterBottom>
                                                                                    The e-mail signature image shows under the body of the e-mail message
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box align="center" pb={1}>
                                                                                <Box border='1px dashed #ddd' width={616} p={1}>
                                                                                    {(customisation?.email?.signatureImage !== '' && (
                                                                                        <img alt={`E-mail Signature`} src={URL.createObjectURL(customisation?.email?.signatureImage)} style={{width: 600, height: 'auto'}} />
                                                                                    )) || (!_.isEmpty(initialCustomisation?.email?.signatureImage) && (
                                                                                        <img alt={`E-mail Signature`} src={`${CLOUDFRONT_URL}${initialCustomisation?.email?.signatureImage}`} style={{width: 600, height: 'auto'}} />
                                                                                    ))}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box p={3} pt={0} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20}>
                                                                            <Box textAlign="center" pl={7.5} pr={7.5} pb={1.25}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    Select New System E-mail Signature Image
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center' gutterBottom>
                                                                                    Please drop or choose a file (Recommended width: 600px)
                                                                                </Typography>
                                                                                <DragFileInput
                                                                                    key={keys.email}
                                                                                    name="signatureImage"
                                                                                    file={customisation?.email?.signatureImage}
                                                                                    onChange={(drop, name, file) => this.handleEmailImageChange('signatureImage', file)}
                                                                                    errorText={formErrors && formErrors['generic']}
                                                                                    emptyText='No image selected'
                                                                                    icon="file-image"
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Paper>
                                                                        <Box p={3} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20}>
                                                                            <Box mb={3}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    System E-mail Signature Image Link
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center'>
                                                                                    This will add a link to the specified URL when the user clicks the signature image
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box textAlign="center" pl={7.5} pr={7.5} pb={1}>
                                                                                <Textarea
                                                                                    name="url"
                                                                                    onChange={this.handleEmailChange}
                                                                                    value={customisation?.email?.url}
                                                                                    variant="outlined"
                                                                                    style={{textAlign: 'center'}}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Paper>
                                                                        <Box p={3} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20}>
                                                                            <Box mb={3}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    {customisation?.email?.outlookSignatureImage !== '' ? 'Proposed' : 'Current'} Outlook E-mail Signature Image
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center' gutterBottom>
                                                                                    The e-mail signature image is displayed in the footer of messages sent from Outlook
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box align="center" pb={1}>
                                                                                <Box border='1px dashed #ddd' width={386} p={1}>
                                                                                    {(customisation?.email?.outlookSignatureImage !== '' && (
                                                                                        <img alt={`E-mail Signature`} src={URL.createObjectURL(customisation?.email?.outlookSignatureImage)} style={{width: 375, height: 'auto'}} />
                                                                                    )) || (!_.isEmpty(initialCustomisation?.email?.outlookSignatureImage) && (
                                                                                        <img alt={`E-mail Signature`} src={`${CLOUDFRONT_URL}${initialCustomisation?.email?.outlookSignatureImage}`} style={{width: 375, height: 'auto'}} />
                                                                                    ))}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box p={3} pt={0} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20}>
                                                                            <Box textAlign="center" pl={7.5} pr={7.5} pb={1.25}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    Select New Outlook E-mail Signature Image
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center' gutterBottom>
                                                                                    Please drop or choose a file (Recommended width: 375px)
                                                                                </Typography>
                                                                                <DragFileInput
                                                                                    key={keys.email}
                                                                                    name="outlookSignatureImage"
                                                                                    file={customisation?.email?.outlookSignatureImage}
                                                                                    onChange={(drop, name, file) => this.handleEmailImageChange('outlookSignatureImage', file)}
                                                                                    errorText={formErrors && formErrors['generic']}
                                                                                    emptyText='No image selected'
                                                                                    icon="file-image"
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Paper>
                                                                        <Box p={3} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0: 20}>
                                                                            <Box mb={3}>
                                                                                <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                    Outlook E-mail Signature Image Link
                                                                                </Typography>
                                                                                <Typography variant="body2" align='center'>
                                                                                    This will add a link to the specified URL when the user clicks the outlook signature image
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box textAlign="center" pl={7.5} pr={7.5} pb={1}>
                                                                                <Textarea
                                                                                    name="outlookUrl"
                                                                                    onChange={this.handleEmailChange}
                                                                                    value={customisation?.email?.outlookUrl}
                                                                                    variant="outlined"
                                                                                    style={{textAlign: 'center'}}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Box className="buttonRow" style={{paddingTop: 0}}>
                                                                <Button
                                                                    color="primary"
                                                                    disabled={customisation?.email?.url === initialCustomisation?.email?.url && customisation?.email?.outlookUrl === initialCustomisation?.email?.outlookUrl && customisation?.email?.preheader === initialCustomisation?.email?.preheader && customisation?.email?.logoImage === '' && customisation?.email?.signatureImage === '' && customisation?.email.outlookSignatureImage === ''}
                                                                    onClick={() => this.handleReset(null)}
                                                                    variant="contained"
                                                                >
                                                                    <FAIcon icon="times" button size={15} disabled={customisation?.email?.url === initialCustomisation?.email?.url && customisation?.email?.outlookUrl === initialCustomisation?.email?.outlookUrl && customisation?.email?.preheader === initialCustomisation?.email?.preheader && customisation?.email?.logoImage === '' && customisation?.email?.signatureImage === '' && customisation?.email.outlookSignatureImage === ''} />
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    disabled={customisation?.email?.url === initialCustomisation?.email?.url && customisation?.email?.outlookUrl === initialCustomisation?.email?.outlookUrl && customisation?.email?.preheader === initialCustomisation?.email?.preheader && customisation?.email?.logoImage === '' && customisation?.email?.signatureImage === '' && customisation?.email.outlookSignatureImage === ''}
                                                                    onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">Are sure you want to update e-mail customisation?</Typography><Typography variant="body2">These changes will take effect immediately</Typography></>, () => this.handleSubmit('email'))}
                                                                    variant="contained"
                                                                >
                                                                    <FAIcon icon="exclamation-triangle" buttonPrimary size={15} disabled={customisation?.email?.url === initialCustomisation?.email?.url && customisation?.email?.outlookUrl === initialCustomisation?.email?.outlookUrl && customisation?.email?.preheader === initialCustomisation?.email?.preheader && customisation?.email?.logoImage === '' && customisation?.email?.signatureImage === '' && customisation?.email.outlookSignatureImage === ''} />
                                                                    Update
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    className="btn btn-success"
                                                                    onClick={() => this.handlePreview('email')}
                                                                    variant="contained"
                                                                >
                                                                    <FAIcon icon="binoculars" size={15} button />
                                                                    Preview
                                                                </Button>
                                                            </Box>
                                                        </>
                                                    ))}
                                                </TabPanel>
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    {_.map(tabs, (tab, idx) => (
                                        (tab.divider && (
                                            <Divider />
                                        )) || (
                                            <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} disabled={!tab.access} key={idx} value={idx} label={`${tab.name}`} />
                                        )
                                    ))}
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        ui: state.ui
    }
)

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fs = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, disableExit)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(Customisation));