import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Button,
    Checkbox,
    Grid
} from '@material-ui/core';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const UpdateDuplicateAddresses = ({counties, countries, duplicates, handleRowAdd, postData}) => {

    const [formData,setFormData] = useState(duplicates);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const toggleUpdate = (idx) => {
        let newFormData = [...formData]
        newFormData[idx].update = newFormData[idx].update === 1 ? 0 : 1
        setFormData(newFormData)
    }

    const handleUpdate = () => {

        let count = 0;

        setIsLoading(true);

        _.each(formData, el => {

            if(el.update === 1) {

                let data = {
                    ...postData,
                    id: el?.addr?.id ?? 0,
                    idx: el?.addr?.idx ?? 0
                }

                setTimeout(() => handleRowAdd(el.fieldName, el.idx, data, false), 200);
                
            }
        });

        dispatch(deploySnackBar(`success`, `${count > 0 ? count : 'No'} duplicate addresses were updated`))
      
        dispatch(closeDialog())

    }

    return (
        <Grid container spacing={3}>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>
                    <Grid item xs={12}>
                        <DataTable 
                            config={{
                                key: 'idx',
                                pagination: false,
                                alternatingRowColours: true
                            }}
                            columns={[
                                {
                                    heading: '',
                                    field: rowData => <Checkbox checked={rowData.update === 1} value={1} onChange={() => toggleUpdate(rowData.rowIdx)} color="primary" />,
                                    sizeToContent: true,
                                    alignment: 'right'
                                },
                                {
                                    heading: 'Address Line 1',
                                    field: rowData => rowData?.addr?.addressOne || '-',
                                },
                                {
                                    heading: 'Address Line 2',
                                    field: rowData => rowData?.addr?.addressTwo || '-',
                                },
                                {
                                    heading: 'Town',
                                    field: rowData => rowData?.addr?.town || '-',
                                },
                                {
                                    heading: 'Postcode',
                                    field: rowData => rowData?.addr?.postcode || '-',
                                },
                                {
                                    heading: 'County',
                                    field: rowData => counties?.[_.findIndex(counties, el => el.value === rowData?.addr?.county)]?.label ?? '-',
                                },
                                {
                                    heading: 'Country',
                                    field: rowData => countries?.[_.findIndex(countries, el => el.value === rowData?.addr?.country)]?.label ?? '-',
                                },
                                {
                                    heading: 'Type',
                                    field: rowData => rowData?.addr?.t,
                                },
                            ]}
                            rows={formData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} justify="flex-end">
                            <Grid item>
                                <Button
                                    onClick={() => dispatch(closeDialog())}
                                >
                                    <FAIcon icon="times" size={15} button />
                                    Close
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to update these addresses?`, handleUpdate))}
                                    variant="contained"
                                >
                                    <FAIcon icon="check" size={15} buttonPrimary />
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    )

}

export default UpdateDuplicateAddresses;