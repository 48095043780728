import React from 'react';

import Search from 'Components/Common/Search/Search';

const ProductPricing = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/productPricing',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.dc,
                        dataRef: 'dc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.an,
                        dataRef: 'an',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Status',
                        field: rowData => rowData.st,
                        dataRef: 'st',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Buy Price',
                        field: rowData => rowData.bp,
                        fieldFormat: 'currency',
                        dataRef: 'bp',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Trade Price',
                        field: rowData => rowData.pr,
                        fieldFormat: 'currency',
                        dataRef: 'pr',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Discount Percent',
                        field: rowData => rowData.dp,
                        fieldFormat: 'percentage:2',
                        dataRef: 'dp',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Discount Amount',
                        field: rowData => rowData.da,
                        fieldFormat: 'currency',
                        dataRef: 'da',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Your Price',
                        field: rowData => rowData.yp,
                        fieldFormat: 'currency',
                        dataRef: 'yp',
                        important: true,
                        sizeToContent: true,
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'id',
                name: 'product-pricing',
                noResultsText: 'No results have been found using the search criteria provided.',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Products'],
                pdf: true,
                persistenceId: 'productPricing',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: false,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showProduct: true,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Product Pricing Report'
            }}
        />
    )
}

export default ProductPricing;