import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { DraggablePaper } from 'Functions/MiscFunctions';

const WildixPhoneBook = ({
    extension,
    handleClose,
    handleGetPresenceColor,
    handleGetPresenceClassName,
    handleGetPresenceIcon,
    handleGetPresenceText,
    handleGetPresencePriority,
    onDial,
    presence,
    roster
}) => {

    const handleDial = to => {
        onDial(to);
        handleClose();
    }

    return (
        <Dialog
            disableEscapeKeyDown
            disableBackdropClick
            PaperComponent={DraggablePaper}
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            open
        >
            <DialogTitle
                style={{ cursor: "move" }}
                id="draggable-control"
            >
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">
                            Colleagues
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose}>
                                <FAIcon
                                    icon="times"
                                    button
                                    noMargin
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box mb={2.5}>
                    <Grid container spacing={3}>
                        {(!_.isEmpty(roster) && (
                            <Grid item xs={12}>
                                <DataTable 
                                    config={{
                                        key: '_extension',
                                        alternatingRowColours: true,
                                        isLoading: false,
                                        inline: true,
                                        responsiveImportance: true,
                                    }}
                                    columns={[
                                        {
                                            heading: 'Name',
                                            field: rowData => <Box className="fw-400">{rowData?.getName()}</Box>,
                                            main: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Extension',
                                            field: rowData => <Box className="fw-400">{rowData?.getExtension()}</Box>,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData => {
                                                const p     = presence?.[rowData.getExtension()] ?? null;
                                                const icon  = p ? handleGetPresenceIcon(p, false) : 'phone-slash';
                                                const color = p ? handleGetPresenceColor(p, false) : '#d32f2f';
                                                const cn    =  p ? handleGetPresenceClassName(p, false) : '';
                                                const text  =  p ? handleGetPresenceText(p, false) : 'Unavailable';
                                                return (
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <FAIcon type="solid" icon={icon ?? 'phone-arrow-up-right'} color={color ?? '#d32f2f'} size={15} noMargin />
                                                        </Grid>
                                                        <Grid item>
                                                            <Box className={cn}>
                                                                <strong>{text}</strong>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        },
                                        {
                                            actions: rowData => ([
                                                {
                                                    name: 'Call',
                                                    icon: 'circle-phone',
                                                    type: 'solid',
                                                    className: 'textSuccess',
                                                    size: 25,
                                                    onClick: () => handleDial(rowData.getExtension()),
                                                }
                                            ])
                                        }
                                    ]}
                                    rows={_.orderBy(_.filter(roster, r => r.getExtension() !== extension), [el => handleGetPresencePriority(presence?.[el.getExtension()] ?? null), el => el.getName()], ['asc', 'asc'])}
                                />
                            </Grid>
                        )) || (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    No other colleagues available to dial
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default WildixPhoneBook;