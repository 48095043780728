import React from 'react';
import InsightChip from 'Components/Common/Chips/InsightChip';
import { hex2rgba } from 'Functions/MiscFunctions';

const StatusChip = ({status}) => {

    const config = {
        'account opening': {
            icon: 'user',
            color: '#F57C00'
        },
        accepted: {
            icon: 'check',
            color: '#4CAF50'
        },
        following: {
            icon: 'thumbs-up',
            color: '#4CAF50'
        },
        'dismissed': {
            icon: 'thumbs-down',
            color: '#d9534f'
        },
        'voicemail left': {
            icon: 'phone',
            color: '#F57C00'
        },
        answered: {
            icon: 'phone',
            color: '#4CAF50'
        },
        busy: {
            icon: 'phone-slash',
            color: '#d32f2f'
        },
        'no answer': {
            icon: 'phone-slash',
            color: '#d32f2f'
        },
        'voicemail': {
            icon: 'phone-slash',
            color: '#d9534f'
        },
        unanswered: {
            icon: 'phone-slash',
            color: '#d32f2f'
        },
        active: {
            icon: 'check',
            color: '#4CAF50'
        },
        authorised: {
            icon: 'check',
            color: '#4CAF50'
        },
        completed: {
            icon: 'check',
            color: '#4CAF50'
        },
        resolved: {
            icon: 'check',
            color: '#4CAF50'
        },
        outstanding: {
            icon: 'times',
            color: '#d9534f'
        },
        'discrepancy': {
            icon: 'exclamation-triangle',
            color: '#d9534f'
        },
        'awaiting resolution': {
            icon: 'exclamation-circle',
            color: '#d9534f'
        },
        'pending release': {
            icon: 'exclamation-circle',
            color: '#d9534f'
        },
        released: {
            icon: 'check',
            color: '#4CAF50'
        },
        complete: {
            icon: 'check',
            color: '#4CAF50'
        },
        email: {
            icon: 'at',
            color: '#4CAF50'
        },
        read: {
            icon: 'check',
            color: '#4CAF50'
        },
        success: {
            icon: 'check',
            color: '#4CAF50'
        },
        posted: {
            icon: 'check',
            color: '#4CAF50'
        },
        posting: {
            icon: 'times',
            color: '#F57C00'
        },
        raised: {
            icon: 'check',
            color: '#d9534f'
        },
        new: {
            icon: 'exclamation-circle',
            color: '#F57C00'
        },
        pending: {
            icon: 'hourglass',
            color: '#F57C00'
        },
        'no data available': {
            icon: 'question',
            color: '#757575'
        },
        queued: {
            icon: 'hourglass-start',
            color: '#757575'
        },
        error: {
            icon: 'exclamation-triangle',
            color: '#d9534f'
        },
        'on hold': {
            icon: 'exclamation-triangle',
            color: '#d9534f'
        },
        inactive: {
            icon: 'times',
            color: '#d9534f'
        },
        finished: {
            icon: 'times',
            color: '#d9534f'
        },
        sent: {
            icon: 'paper-plane',
            color: '#d9534f'
        },
        timeout: {
            icon: 'times',
            color: '#d9534f'
        },
        'required_fields': {
            icon: 'times',
            color: '#d9534f'
        },
        'required_captcha': {
            icon: 'times',
            color: '#d9534f'
        },
        'required_mfa': {
            icon: 'times',
            color: '#d9534f'
        },
        'replay_mfa': {
            icon: 'exclamation-triangle',
            color: '#d9534f'
        },
        'incorrect_mfa': {
            icon: 'times',
            color: '#d9534f'
        },
        'incorrect_captcha': {
            icon: 'times',
            color: '#d9534f'
        },
        'incorrect_login': {
            icon: 'times',
            color: '#d9534f'
        },
        'ip_blocked': {
            icon: 'exclamation-triangle',
            color: '#d9534f'
        },
        'draft': {
            icon: 'hourglass',
            color: '#F57C00'
        },
        'awaiting approval': {
            icon: 'exclamation-triangle',
            color: '#d9534f'
        },
        'awaiting payment': {
            icon: 'exclamation-triangle',
            color: '#F57C00'
        },
        'awaiting authorisation': {
            icon: 'exclamation-triangle',
            color: '#d9534f'
        },
        'processing': {
            icon: 'exclamation-circle',
            color: '#d9534f'
        },
        'awaiting allocation': {
            icon: 'times',
            color: '#d9534f'
        },
        'open': {
            icon: 'check',
            color: '#4CAF50'
        },
        'ordered': {
            icon: 'check',
            color: '#03A9F4'
        },
        'lost': {
            icon: 'times',
            color: '#d9534f'
        },
        'declined': {
            icon: 'exclamation-triangle',
            color: '#d9534f'
        },
        'deleted': {
            icon: 'trash-alt',
            color: '#d9534f'
        },
        'awaiting submission': {
            icon: 'exclamation-circle',
            color: '#C2185B'
        },
        'awaiting confirmation': {
            icon: 'user-check',
            color: '#d9534f'
        },
        'in progress': {
            icon: 'check',
            color: '#F57C00'
        },
        'awaiting stock': {
            icon: 'box',
            color: '#d9534f'
        },
        'awaiting picking': {
            icon: 'scanner-touchscreen',
            color: '#F57C00'
        },
        'awaiting allocation - on hold': {
            icon: 'times',
            color: '#d9534f'
        },
        'awaiting stock - on hold': {
            icon: 'times',
            color: '#d9534f'
        },
        'awaiting supplier - on hold': {
            icon: 'times',
            color: '#d9534f'
        },
        'awaiting picking - on hold': {
            icon: 'times',
            color: '#d9534f'
        },
        'awaiting despatch - on hold': {
            icon: 'times',
            color: '#d9534f'
        },
        'ready for collection - on hold': {
            icon: 'times',
            color: '#d9534f'
        },
        'despatched - on hold': {
            icon: 'times',
            color: '#d9534f'
        },
        'goods received': {
            icon: 'arrow-to-bottom',
            color: '#C2185B'
        },
        'with supplier': {
            icon: 'arrow-from-bottom',
            color: '#F57C00'
        },
        'with customer': {
            icon: 'arrow-from-bottom',
            color: '#03A9F4'
        },
        'awaiting supplier': {
            icon: 'truck',
            color: '#F57C00'
        },
        'awaiting despatch': {
            icon: 'truck-loading',
            color: '#F57C00'
        },
        'awaiting check': {
            icon: 'check-double',
            color: '#F57C00'
        },
        'awaiting technical': {
            icon: 'screwdriver-wrench',
            color: '#F57C00'
        },
        'ready for collection': {
            icon: 'check',
            color: '#4CAF50'
        },
        'delivered': {
            icon: 'box-check',
            color: '#4CAF50'
        },
        'despatched': {
            icon: 'check',
            color: '#4CAF50'
        },
        'invoiced': {
            icon: 'check',
            color: '#4CAF50'
        },
        'closed': {
            icon: 'check',
            color: '#d9534f'
        },
        'cancelled': {
            icon: 'times',
            color: '#d9534f'
        },
        'ready to invoice': {
            icon: 'check',
            color: '#4CAF50'
        },
        'awaiting delivery': {
            icon: 'times',
            color: '#d9534f'
        },
        'upcoming event': {
            icon: 'arrow-right',
            color: '#F57C00'
        },
        'past event': {
            icon: 'check',
            color: '#4CAF50'
        },
        'card order check / creation required': {
            icon: 'exclamation-circle',
            color: '#d9534f'
        },
        'order created - awaiting authorisation': {
            icon: 'check',
            color: '#F57C00'
        },
        '1 - order created - awaiting authorisation': {
            icon: 'user-check',
            color: '#d9534f'
        },
        '2 - authorisation received': {
            icon: 'user-check',
            color: '#F57C00'
        },
        '3 - programmed': {
            icon: 'laptop-code',
            color: '#F57C00'
        },
        '4 - checked': {
            icon: 'laptop-code',
            color: '#F57C00'
        },
        '5 - despatched': {
            icon: 'box',
            color: '#F57C00'
        },
        '6 - completed': {
            icon: 'check',
            color: '#4CAF50'
        },
        '7 - cancelled': {
            icon: 'times',
            color: '#d9534f'
        }
    },
    color = status ? config?.[status.toLowerCase?.() ?? -1]?.color ?? '#607D8B' : '#607D8B',
    icon  = status ? config?.[status.toLowerCase?.() ?? -1]?.icon ?? 'question' : 'question';

    return (
        <InsightChip 
            background={hex2rgba(color, 0.9)}
            color={'#fff'}
            icon={icon}
            type="light"
            label={status}
            size={11}
            paddingLeft={7}
            paddingRight={4}
        />
    )
}

export default StatusChip;