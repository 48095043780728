import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const Widget = ({content, header, icon, onClick, type}) => {
    return (
        <Paper component="div" onClick={onClick} style={{cursor: onClick ? 'pointer' : undefined}}>
            <Box pt={3} pr={3} pl={3}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <FAIcon icon={icon} type="light" size={25} noMargin />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6">
                            {header}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box p={3} pt={2}>
                <Box p={3} className={type ? (type === "error" ? 'content-light-error' : 'content-light-success') : 'content-light-grey'}>
                    <Grid container align='center'>
                        {_.map(content, row => (
                            <Grid item xs={6}>
                                <Box p={1.5}>
                                    <Typography variant="h5">
                                        {row.content}
                                    </Typography>
                                    <Typography variant="caption">
                                        {row.heading}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Paper>
    )
}

export default Widget;