import _ from 'lodash';
import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AddressLookup from 'Components/Common/AddressLookup/AddressLookup';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { handleConfirmationClose, handleConfirmationOpen, handleConfirmationSuccess } from 'Functions/FormFunctions';
import { DraggablePaper } from 'Functions/MiscFunctions';
import FAIcon from '../Icons/FontAwesome/FAIcon';

const initialState = (defaultFormData = null) => ({
    confirmation: {
        submit: false
    },
    countyList: [],
    countryList: [],
    formData: {
        addressOne: '',
        addressTwo:  '',
        addressThree:  '',
        town:  '',
        county: '',
        country:  234,
        postcode: '',
        showAddress: false,
        showAddressLookup: true,
        addressComplete: false,
    },
    isLoading: true
})

class AddressLookupDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        if(this.props.formData) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    ...this.props.formData
                }
            }, this.loadComponentData);
        } else {
            this.loadComponentData();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.formData.country !== this.state.formData.country) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    county: ''
                }
            })
        }
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/misc/counties/all'),
            API.get('/misc/countries/all'),
        ])
        .then(([countyRes, countryRes]) => {
            let countyList = []
            let countryList = []

            if(countyRes.data){
                countyList = _.map(countyRes.data, el => {
                    return _.assign({
                        value: el.county_id,
                        label: el.county_name,
                        dc: el.county_del_conf_id
                    });
                });
            }

            if(countryRes.data){
                countryList = _.map(countryRes.data, el => {
                    return _.assign({
                        value: el.country_id,
                        label: el.country_name,
                        dc: el.country_del_conf_id
                    });
                });
            }

            this.setState({
                countyList,
                countryList
            })
        })
    }

    handleChange = e => {
        let name = e.target.name,
            value = e.target.value,
            length = value.length;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
                addressComplete: name === "addressOne" ? (length === 0 ? false : true) : this.state.formData.addressComplete,
            }
        })
    }

    handleManuallyEnterAddress = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                showAddress:        this.state.formData.showAddress && this.state.formData.showAddressLookup ? true : !this.state.formData.showAddress,
                showAddressLookup:  this.state.formData.showAddress && this.state.formData.showAddressLookup ? false : !this.state.formData.showAddressLookup,
                addressOne:         this.state.formData.showAddress ? '' : this.state.formData.addressOne,
                addressTwo:         this.state.formData.showAddress ? '' : this.state.formData.addressTwo,
                addressThree:       this.state.formData.showAddress ? '' : this.state.formData.addressThree,
                town:               this.state.formData.showAddress ? '' : this.state.formData.town,
                county:             this.state.formData.showAddress ? '' : this.state.formData.county,
                postcode:           this.state.formData.showAddress ? '' : this.state.formData.postcode,
                country:            initialState().formData.country,
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    } 
    
    handleSelectedAddress = e => {
        if(e && e.value){
            this.setState({
                formData: {
                    ...this.state.formData,
                    addressOne: e.value.line1,
                    addressTwo:  e.value.line2,
                    addressThree:  e.value.line3,
                    town:  e.value.town,
                    county: e.value.county,
                    postcode: e.value.postcode,
                    selectedAddress: e,
                    addressComplete: true,
                    showAddressLookup: false
                }
            }, () => {
                this.close();
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    selectedAddress: null,
                    addressComplete: false,
                    showAddressLookup: true
                }
            });
        }
    }

    close = () => {

        let dc = 0,
            county = _.find(this.state.countyList, {label: this.state.formData.county}),
            country = _.find(this.state.countryList, {value: this.state.formData.country});

        if(county && county?.dc > 0) {
            dc = county.dc;
        }

        if(dc === 0 && country && country?.dc > 0) {
            dc = country.dc;
        }
        
        this.props.onSubmit({...this.state.formData, dc});

    }

    submit = () => {
        /* This cancels the dialog with a false value */
        this.props.onSubmit(false)
    }

    render() {
        const { open, formErrors } = this.props;
        const { formData, countyList, countryList } = this.state;
        return (
            <>
                <Dialog 
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    open={open}
                    maxWidth='xs' 
                    scroll="body"
                    PaperComponent={DraggablePaper}
                >               
                    <DialogTitle disableTypography>
                        <Typography variant="h6" id="draggable-control">
                            Select Address
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{padding: '0 24px 24px 24px', minWidth: 400, maxWidth: 500}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <form noValidate autoComplete="off" onSubmit={e => e.preventDefault?.()}>
                                    {formData.country === 234 && formData.showAddressLookup && ( // United Kingdom
                                        <>
                                            <AddressLookup                                     
                                                handleSelectedAddress={this.handleSelectedAddress}
                                                inlineLayout={false}
                                                value={formData.selectedAddress}
                                                visible={formData.showAddressLookup}
                                                manualEntry={
                                                    <Box pb={2} textAlign='center' onClick={this.handleManuallyEnterAddress}>
                                                        <Typography variant="body2" className='blueLink'>
                                                            {formData.showAddressLookup ? 'Manually enter address': 'Use address lookup (UK Only)' }
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </>
                                    )}
                                    {(formData.country !== 234 || (formData.country === 234 && formData.showAddress) || formData.showAddress) && (
                                        <>
                                            <Box pb={2} textAlign='center' onClick={this.handleManuallyEnterAddress}>
                                                <Typography variant="body2" className='blueLink'>
                                                    Use address lookup (UK Only)
                                                </Typography>
                                            </Box>
                                            <TextField
                                                id="addressOne"
                                                name="addressOne"
                                                label="Address Line 1 *"
                                                value={formData.addressOne}
                                                error={formErrors && formErrors['addressOne'] && true}
                                                helperText={formErrors && formErrors['addressOne']}                                                
                                                onChange={this.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                            <TextField
                                                id="addressTwo"
                                                name="addressTwo"
                                                label="Address Line 2"
                                                value={formData.addressTwo}
                                                error={formErrors && formErrors['addressTwo'] && true}
                                                helperText={formErrors && formErrors['addressTwo']}
                                                onChange={this.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                            <TextField
                                                id="addressThree"
                                                name="addressThree"
                                                label="Address Line 3"
                                                value={formData.addressThree}
                                                error={formErrors && formErrors['addressThree'] && true}
                                                helperText={formErrors && formErrors['addressThree']}
                                                onChange={this.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                            <TextField
                                                id="town"
                                                name="town"
                                                label="City / Town *"
                                                value={formData.town}
                                                error={formErrors && formErrors['town'] && true}
                                                helperText={formErrors && formErrors['town']}
                                                onChange={this.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                            <TextField
                                                id="postcode"
                                                name="postcode"
                                                label="Postcode *"
                                                value={formData.postcode}
                                                error={formErrors && formErrors['postcode'] && true}
                                                helperText={formErrors && formErrors['postcode']}
                                                onChange={this.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                            {formData.country === 234 && (                                    
                                                <AutoCompleteSelect 
                                                    options={countyList} 
                                                    label='County *'
                                                    onChange={this.handleSelectChange('county')}
                                                    error={formErrors && formErrors['county'] && true}
                                                    errorText={formErrors && formErrors['county']}
                                                    value={formData.county}
                                                    noClear
                                                />
                                            )}
                                            <AutoCompleteSelect 
                                                options={countryList} 
                                                label='Country *'
                                                onChange={this.handleSelectChange('country')}
                                                error={formErrors && formErrors['country'] && true}
                                                helperText={formErrors && formErrors['country']}
                                                value={formData.country}
                                                noClear
                                            />
                                        </>
                                    )}
                                </form>
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button onClick={() => this.handleConfirmationOpen('submit')} variant="text" color="primary">
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                                <Button disabled={formData.addressOne.length === 0 || formData.town.length === 0 || formData.postcode.length === 0 || (formData.country === 234 && formData.county.length === 0) || formData.country.length === 0} onClick={this.close} variant="text" color="primary">
                                    <FAIcon icon="check" size={15} button />
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>   
                <ConfirmationDialog 
                    open={this.state.confirmation.submit} 
                    success={() => this.handleConfirmationSuccess('submit')} 
                    close={() => this.handleConfirmationClose('submit')} 
                    title="Address Incomplete" 
                    message="Are you sure you want to cancel?"
                />
            </>
        )
    }
}

export default AddressLookupDialog;