import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import CalendarEvent from 'Components/Calendar/CalendarEvent/CalendarEvent';
import CalendarEventForm from 'Components/Calendar/CalendarEventForm/CalendarEventForm';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import DataTable from 'Components/Common/DataTables/DataTable';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import StaffChip from 'Components/Common/Chips/StaffChip';

const initialState = {
    searchCriteria: {
        searchString: '',
        dateFrom: null,
        dateTo: null,
    },
    searchResults: {},
    access: {
        addEvent: false,
        updateEvent: false,
        cancelEvent: false
    },
    isLoading: true
}

class CalendarSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState; 
        this.timeout = false
    }

    componentDidMount = () => {
        this.props.pageTitle?.([0, 'Calendar', 'Events']);
        this.checkAccess();
        this.getEvents();
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    checkAccess = () => {
        Promise.all([
            API.access('update-calendar-event'), 
            API.access('cancel-calendar-event'),
            API.access('add-calendar-event')
        ])
        .then(([updateEventRes, cancelEventRes, addEventRes]) =>  {
            this.setState({
                access: {
                    addEvent: (addEventRes.data && addEventRes.data.has_access) || false,
                    updateEvent: (updateEventRes.data && updateEventRes.data.has_access) || false,
                    cancelEvent: (cancelEventRes.data && cancelEventRes.data.has_access) || false
                }
            });
        })
    }

    getEvents = () => {

        const props = {
            cancellation: true
        }
        const params = {
            searchString: this.state.searchCriteria.searchString,
            dateFrom: this.state.searchCriteria.dateFrom !== null ? moment(this.state.searchCriteria.dateFrom).format('YYYY-MM-DD') : null,
            dateTo: this.state.searchCriteria.dateTo !== null ? moment(this.state.searchCriteria.dateTo).format('YYYY-MM-DD') : null,
            orderBy: 'calendar_event_start_datetime:desc'
        };
        
        this.setState({
            isLoading: true
        }, () => {
            API.get('/calendar/events/all', { params, props }).then(result => {
                if(result?.data) {
                    result.data.forEach((event) => {
                        event.status = '';
                        event.status_value = '';
                        if(event.calendar_event_status === 'Active' && moment(event.calendar_event_start_datetime) > moment()){
                            event.status_value = 'Upcoming Event';
                            event.status = <StatusChip status='Upcoming Event' />
                        }
                        else if(event.calendar_event_status === 'Active'){
                            event.status_value = 'Past Event';
                            event.status = <StatusChip status='Past Event' />
                        }
                        else {
                            event.status_value = 'Cancelled';
                            event.status = <StatusChip status='Cancelled' />
                        }
                        event.export_category = `${event.parent_category.category_name} | ${event.sub_category.category_name}`;
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    });
                } else {
                    /* Error */
                }
            });
        })

    }

    cancelEvent = id => {
        API.post(`/calendar/events/${id}/cancel`)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0) {
                alert('Unable to cancel event');
            } else {
                let rowIndex = this.state.searchResults.findIndex(x => x.calendar_event_id === id);
                let newSearchResults = [...this.state.searchResults];
                newSearchResults[rowIndex].status = <StatusChip status='Cancelled' />
                newSearchResults[rowIndex].calendar_event_status = 'Cancelled';
                newSearchResults[rowIndex].can_edit = false;
                this.setState({
                    ...this.state,
                    cancelEventSnackbarOpen: true,
                    searchResults: newSearchResults
                });
            }
        });
    }

    handleDeployAddEvent = () => {
        this.props.deployDialog(
            <CalendarEventForm callback={this.getEvents} />,
            false,
            "Add Calendar Event",
            "standard",
            "sm"
        )
    }

    handleDeployUpdateEvent = id => {
        this.props.deployDialog(
            <CalendarEventForm callback={this.getEvents} id={id} />,
            false,
            "Update Calendar Event",
            "standard",
            "sm"
        )
    }

    handleDeployViewEvent = id => {
        this.props.deployDialog(
            <CalendarEvent id={id} />,
            true,
            "",
            "standard",
            "sm",
            false,
            true
        )
    }

    handleDateChange = name => date => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [name]: date
            }
        }, 
        () => {
            this.setSearch();
        });
    };

    onSearchStringChange = (event) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                searchString: event.target.value
            },
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        this.setState({
            searchCriteria: initialState.searchCriteria
        }, 
        () => {
            this.getEvents();
            this.setSearch();
        });
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getEvents(), 250);
    }

    render() {
        const { staff, ui } = this.props;
        const { access, isLoading, searchCriteria, searchResults } = this.state;
        return (
            <>
                <Grid container spacing={3}>      
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'> 
                            <Grid item xs={12} sm={6} xl={3}>
                                <TextField 
                                    fullWidth
                                    onChange={this.onSearchStringChange}
                                    placeholder='Search:'
                                    value={searchCriteria?.searchString} 
                                    variant="filled"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                    }}
                                autoFocus={!ui?.device?.isTablet}
                                /> 
                            </Grid> 
                            <Grid item xs={12} sm={3} xl={2}>
                                <DatePicker
                                    type='date'
                                    id="dateFrom"
                                    name="dateFrom"
                                    placeholder="Date From"
                                    autoOk={true}
                                    value={searchCriteria?.dateFrom}
                                    onChange={this.handleDateChange('dateFrom')}
                                    inputVariant="filled"
                                />
                            </Grid>      
                            <Grid item xs={12} sm={3} xl={2}>
                                <DatePicker
                                    type='date'
                                    id="dateTo"
                                    name="dateTo"
                                    placeholder="Date To"
                                    autoOk={true}
                                    value={searchCriteria?.dateTo}
                                    onChange={this.handleDateChange('dateTo')}  
                                    inputVariant="filled"                                          
                                />
                            </Grid>       
                        </Grid>    
                    </Grid>           
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'calendar_event_id',
                                    pagination: true,
                                    isLoading: isLoading,
                                    alternatingRowColours: true,
                                    responsiveImportance: true,
                                    options: {
                                        dataRef: true,
                                        reset: this.resetSearch,
                                        export: {
                                            title: `Calendar Events List`,
                                            name: `Calendar-Events`,
                                            excel: true,
                                            pdf: true,
                                            print: true
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Meeting Name',
                                        field: rowData => rowData.calendar_event_name,
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    textDecoration: rowData.calendar_event_status === 'Cancelled' && 'line-through'
                                                }
                                            });
                                        },
                                        dataRef: 'calendar_event_name',
                                        main: true,
                                        important: true,
                                    },
                                    {
                                        heading: 'Start',
                                        field: rowData => rowData.calendar_event_start_datetime,
                                        fieldFormat: 'datetime',
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    textDecoration: rowData.calendar_event_status === 'Cancelled' && 'line-through'
                                                }
                                            });
                                        },
                                        dataRef: 'calendar_event_start_datetime'
                                    },
                                    {
                                        heading: 'Type',
                                        field: rowData => (
                                            <>
                                                {rowData.parent_category.category_name}
                                                <Typography variant="caption" component="div">{rowData.sub_category.category_name}</Typography>
                                            </>
                                        ),
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    textDecoration: rowData.calendar_event_status === 'Cancelled' && 'line-through'
                                                }
                                            });
                                        },
                                        dataRef: 'type',
                                        main: true,
                                        important: true,
                                    },
                                    {
                                        heading: 'Meeting Host',
                                        field: rowData => <StaffChip staff={staff[rowData.leader?.staff_id ?? 0]} />,
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    textDecoration: rowData.calendar_event_status === 'Cancelled' && 'line-through'
                                                }
                                            });
                                        },
                                        dataRef: 'leader.staff_id',
                                        fieldFormat: 'staff',
                                    },
                                    {
                                        heading: 'Attendees Emailed',
                                        field: rowData => rowData.calendar_event_email,
                                        fieldFormat: 'boolean',
                                        important: true,
                                        alignment: 'center',
                                        dataRef: 'calendar_event_email'
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => rowData.status,
                                        important: true,
                                        dataRef: 'status_value'
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'View', icon: 'search', onClick: () => { this.handleDeployViewEvent(rowData.calendar_event_id) }},
                                                {name: 'Update', icon: 'pencil-alt', onClick: () => this.handleDeployUpdateEvent(rowData.calendar_event_id), disabled: (!rowData.can_edit || !access.updateEvent)},
                                                {name: 'Cancel', icon: 'times-circle', onClick: () => this.props.deployConfirmation(`Are you sure you want to cancel this event?`, () => this.cancelEvent(rowData.calendar_event_id)), disabled: (!rowData.can_edit || !access.cancelEvent)}
                                            ]
                                        }
                                    }
                                ]}
                                rows={searchResults}
                            />
                        </Paper>
                    </Grid>               
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        ui: state.ui
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success, cancel = false, successText = null, cancelText = null, successOnly = false) => dispatch(deployConfirmation(message, success, cancel, successText, cancelText, successOnly)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSearch);