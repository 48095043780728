import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Main from "Components/Login/Main/Main";
import Warehouse from "Components/Login/Warehouse/Warehouse";
import { handleSetMode } from "Redux/Actions/UI/UI";
import { HEADER_COLOR, ENVIRONMENT } from "Constants";

/* Component */
const Login = () => {
    const mode = useSelector((state) => state.ui.mode),
        dispatch = useDispatch(),
        [switchable, setSwitchable] = useState(null),
        handleModeChange = (mode) => dispatch(handleSetMode(mode));

    useEffect(() => {
        API.get(`/auth/check`).then((res) => {
            if (res?.data) {
                if (res.data?.c) {
                    setSwitchable(true);
                } else {
                    if (mode === "Warehouse") {
                        handleModeChange("Main");
                    }
                    setSwitchable(false);
                }
            }
        });
    });

    return (
        (switchable === null && (
            <Box margin={"0 auto"} mt="50%">
                <LoadingCircle size={75} color={"#ef3340"} />
            </Box>
        )) || (
            <>
                {ENVIRONMENT !== "PRODUCTION" && (
                    <Box
                        position="absolute"
                        left={0}
                        top={0}
                        width="100%"
                        style={{ backgroundColor: HEADER_COLOR }}
                        zIndex={9999}
                    >
                        <Grid container alignItems="center" justify="center" className="pb-1 pt-1">
                            <Grid item>
                                <FAIcon
                                    type="thin"
                                    icon={
                                        ENVIRONMENT === "LOCAL"
                                            ? "code"
                                            : ENVIRONMENT === "DEVELOPMENT"
                                            ? "user-check"
                                            : ENVIRONMENT === "TRAINING"
                                            ? "graduation-cap"
                                            : ""
                                    }
                                    color="white"
                                    size={25}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" className="fw-300 textLight">
                                    {ENVIRONMENT === "LOCAL"
                                        ? "RED Local Environment"
                                        : ENVIRONMENT === "DEVELOPMENT"
                                        ? "RED Development Environment"
                                        : ENVIRONMENT === "TRAINING"
                                        ? "RED Training Environment"
                                        : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {(mode === "Warehouse" && <Warehouse handleModeChange={handleModeChange} />) || (
                    <Main switchable={switchable} handleModeChange={handleModeChange} />
                )}
            </>
        )
    );
};

export default Login;
