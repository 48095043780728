import _ from 'lodash';
import React from 'react';
import { CSVReader } from 'react-papaparse';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import logo from 'Assets/Images/no-img.jpg';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import Alert from 'Components/Common/Alert/Alert';
import InsightChip from 'Components/Common/Chips/InsightChip';
import ProductStockChip from 'Components/Common/Chips/ProductStockChip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ProductSearchSelector from 'Components/Products/Misc/ProductSearchSelector';
import { CLOUDFRONT_URL } from 'Constants';
import { handleFloat } from 'Functions/FormFunctions';
import { isTablet } from 'Functions/MiscFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const ChooseProduct = ({original, replacement, handleSelectOriginal, handleSelectReplacement}) => (
    <Grid container spacing={3} className="mt-2">
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems='center' justify="flex-end">
                <Grid item xs={12}>
                    <Box p={3} className="content-light-error content-light-error-clickable" onClick={handleSelectOriginal}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <Avatar src={original?.default_image?.img_filekey ? `${CLOUDFRONT_URL}${original.default_image.img_filekey}` : logo} style={{overflow:'visible', width: 50, height: 50}} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" className="fw-400">
                                    {original.product_code ?? ''}
                                </Typography>
                                <Typography variant="body2">
                                    {original.product_name}
                                </Typography>
                                <Typography variant="caption">
                                    {`${original?.stock_count ?? 0} In Stock | ${original?.stock_allocated_count ?? 0} Allocated | ${original?.stock_available_count ?? 0} Available | ${original?.stock_on_order_count ?? 0} On Order | ${original?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                </Typography>
                                <Box mt={1}>
                                    <ProductStockChip 
                                        rep={original.product_rep_product_id}
                                        status={original.product_status}
                                        stocked={original.product_stock_item}
                                        stockAvailable={original.stock_available_count}
                                        stockOnOrderAvailable={(original.stock_on_order_count - original.stock_on_order_allocated_count)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems='center' justify="flex-end">
                <Grid item xs={12}>
                    <Typography variant="body1" className="fw-400">
                        This product was replaced by:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box p={3} className="content-light-success content-light-success-clickable" onClick={handleSelectReplacement}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <Avatar src={replacement?.default_image?.img_filekey ? `${CLOUDFRONT_URL}${replacement.default_image.img_filekey}` : logo} style={{overflow:'visible', width: 50, height: 50}} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" className="fw-400">
                                    {replacement.product_code ?? ''}
                                </Typography>
                                <Typography variant="body2">
                                    {replacement.product_name}
                                </Typography>
                                <Typography variant="caption">
                                    {`${replacement?.stock_count ?? 0} In Stock | ${replacement?.stock_allocated_count ?? 0} Allocated | ${replacement?.stock_available_count ?? 0} Available | ${replacement?.stock_on_order_count ?? 0} On Order | ${replacement?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                </Typography>
                                <Box mt={1}>
                                    <ProductStockChip 
                                        rep={replacement.product_rep_product_id}
                                        status={replacement.product_status}
                                        stocked={replacement.product_stock_item}
                                        stockAvailable={replacement.stock_available_count}
                                        stockOnOrderAvailable={(replacement.stock_on_order_count - replacement.stock_on_order_allocated_count)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

const initialState = {
    delivery: '',
    oldIdx: '',
    formData: {
        productId: '',
        productName: '',
        productCode: '',
        quantity: '',
        quantityDespatch: '',
        cost: '',
        totalCost: '',
        image: '',
        productData: {}
    },
    formErrors: [],
    isLoading: false,
    notesRead: false,
    key: uuidv4()
}

class Product extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleFloat = handleFloat.bind(this);
        this.quantityInput = React.createRef();
        this.productSelect = React.createRef();
        this.timeout = false;
    }

    componentDidMount = () => {
        if(!this.props.formData) {
            if(this.props.productId) {
                this.handleProductChange(this.props.productId)
            } else {
                this.productSelect.current.focus()
            }
        } else {
            this.setState({
                formData: this.props.formData,
                delivery: this.props.delivery,
                oldIdx: this.props.formData?.rowIdx
            })
        }
    }

    componentWillUnmount = () => {
        if(this.timeout) {
            clearTimeout(this.timeout)
        }
    }

    handleCalculateTotals = (submit = null) => {

        const { formData } = this.state;

        let quantity    = parseInt(formData.quantity),
            totalCost   = (quantity * parseFloat(formData.cost)).toFixed(2);

        this.setState({
            formData: {
                ...this.state.formData,
                totalCost,    
            }
        }, () => {
            if(submit) {
                this.handleSubmit(false, true);
            }
        })
    }

    handleCsvOnDrop = data => {
        const { supplier } = this.props;

        this.props.deployDialog(
            <Box pb={4} pt={2}>
                <Typography variant="h6" align='center'>
                    <LoadingCircle />
                    Processing Import
                </Typography>
                <Typography variant="body1" align='center'>
                    Importing {_.size(data)} lines
                </Typography>
            </Box>, false, "", "standard", "sm");
        
        const   success = [],
                 errors = [];

        const loop = new Promise((resolve, reject) => {
            _.each(data, (row, idx) => {

                if(_.isEmpty(row.errors)) {
                    if(_.size(row.data) === 2) {

                        const   searchString = row.data[0],
                                        qty = row.data[1];

                        if(!isNaN(qty)) {

                            let params = { 
                                searchString,
                                exactMatch: true
                            }

                            if(supplier) {
                                params = {
                                    ...params,
                                    supplier
                                }
                            }

                            API.get('/smartSearch/products', 
                                {
                                    props: {
                                        noLoading: true
                                    },
                                    params
                                }
                            )
                            .then(res => {
                                if(res?.data) {
                                    if(_.size(res.data) === 1) {

                                        const id = res.data?.[0]?.action ?? false;

                                        if(id) {
                                            success.push({...row, ...res.data[0]});
                                            this.handleProductChange(id, qty, true, idx);
                                        } else {
                                            errors.push(idx);
                                        }

                                    } else {
                                        errors.push(idx);
                                    }
                                } else {
                                    errors.push(idx);
                                }
                                                
                                if(idx === (data.length -1)) {
                                    /* Calculate duration based on number of rows with a min of 1500, avg 92 / 4968 on 54 */
                                    let duration = (_.size(data) * 92);
                                    if(duration < 1500) {
                                        duration = 1500;
                                    }
                                    this.timeout = setTimeout(() => resolve(), duration);
                                }

                            })

                        } else {
                            errors.push(idx);
                        }

                    } else {
                        errors.push(idx);
                    }

                } else {
                    errors.push(idx);
                }

            });
        });

        loop.then(() => {
            this.timeout = setTimeout(() => {
                this.props.closeDialog();
                this.props.csvReOrder();
                if(_.isEmpty(errors)) {
                    this.props.deploySnackBar("success", "Your csv file has been processed successfully");
                } else {
                    this.props.deployDialog(
                        <>
                            <Typography variant="body2" color="error" paragraph>
                                The following rows could not be added to the purchase order:
                            </Typography>
                            {_.map(errors, (line, key) => (
                                <Typography key={key} variant="body2" gutterBottom>
                                    - Line {(line + 1)}: {data?.[line]?.[0] ?? 'Unknown'}
                                </Typography>
                            ))}
                        </>, false, "Product Import", "standard", "sm");
                }
            }, 500);
        });
    }
    
    handleCsvOnError = (err, file, inputElem, reason) => {
        this.props.closeDialog();
        this.props.deploySnackBar("error", "Something went wrong, please try to import your csv file again");
    }
    
    handleCsvImport = () => {
        this.props.deployDialog(
            <>
                <Box pb={1}>
                    <Typography variant="body2" className="fw-400">
                        This will add products from your CSV file to this purchase order
                    </Typography>
                    <Alert severity="info" variant="outlined">
                        Your csv file should be formatted as Column A: Product Code | B: Quantity
                    </Alert>
                </Box>
                <CSVReader
                    onDrop={this.handleCsvOnDrop}
                    onError={this.handleCsvOnError}
                    style={{dropArea: { borderRadius: 4 }}}
                    config={{
                        dynamicTyping: true,
                        skipEmptyLines: true
                    }}
                >
                    <span>Drop a CSV file here or click to upload</span>
                </CSVReader>
            </>, false, "Product Import", "standard", "sm");
    }
    
    handleDeployNotes = () => {

        const { 
            delivery,
            formData,
            oldIdx
        } = this.state;

        this.setState({
            notesRead: true
        }, () => {
            this.props.deployDialog(
                <ViewNotes hideDeleteButton notes={formData?.productData?.notes} inline button={<Button variant="contained" color="primary" onClick={() => { this.props.closeDialog(); if(this.props.inDialog) { this.props.handleSubmit(formData, delivery, this.props.delivery, oldIdx); } else { this.handleSubmit(null, false)}}}><FAIcon icon="check" buttonPrimary />Acknowledged</Button>} />, false, `${formData?.productCode}`, "standard", "sm", false, true
            )
        })
    }
    
    handleProductChange = (option, qty = null, submit = false, displayOrder = null) => {
        let productId = option?.value ?? option;
        if(productId) {
            this.setState({
                isLoading: true
            }, () => {
                API.get(`/products/${productId}`, 
                    {
                        params: {
                            forPurchaseOrderForm: true
                        } 
                    }
                )
                .then(res => {

                    if(res?.data) {

                        if(res.data.product_rep_product_id > 0 && !res.data.product_rep_unsuitable) {

                            API.get(`/products/${res.data.product_rep_product_id}`, 
                                {
                                    params: {
                                        forQuoteOrderForm: true
                                    } 
                                }
                            )
                            .then(rep => {

                                if(rep?.data && !submit) {

                                    this.setState({
                                        isLoading: false,
                                        key: uuidv4()
                                    }, () => {

                                        const handleSelectOriginal = () => this.handleProductState(res.data, option, true, qty, submit, displayOrder);
                                        const handleSelectReplacement = () => this.handleProductState(rep.data, option, true, qty, submit, displayOrder);

                                        this.props.deployDialog(
                                            <ChooseProduct
                                                original={res.data} 
                                                replacement={rep.data}
                                                handleSelectOriginal={handleSelectOriginal}
                                                handleSelectReplacement={handleSelectReplacement}
                                            />
                                        , false, `Product ${res.data.product_status === 'Active' ? 'Temporarily Unavailable' : res.data.product_status}`, 'error');

                                    });

                                }

                            })

                        } else {
                            this.handleProductState(res.data, option, false, qty, submit, displayOrder);
                        }

                    }

                })
            })
        }
    }

    handleProductState = (data, option, closeDialog = false, qty = null, submit = false, displayOrder = null) => {
        this.setState({
            formData: {
                ...this.state.formData,
                productId: data.product_id,
                productName: data.product_name,
                productCode: data.product_code || '-',
                quantity: this.props.formData ? this.props.formData.quantity : (qty ?? ""),
                quantityDespatch: this.props.formData ? this.props.formData.quantityDespatch : (qty ?? ""),
                cost: parseFloat(data.product_cost).toFixed(2),
                totalCost: "0.00",
                image: data.product_img,
                productData: data,
                displayOrder: displayOrder ?? null,
                option
            },
            isLoading: false
        }, () => {
            this.props.handleUnsavedLine?.();
            if(closeDialog) {
                this.props.closeDialog();
            }
            if(submit) {
                this.handleCalculateTotals(true);
            } else {
                if(!this.props.formData) {
                    setTimeout(() => this.quantityInput.current.focus(), 50);
                }
            }
        });
    }

    handleChange = e => {

        const   { target: { name, value } } = e;

        if(value < 0) return;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: name === "quantity" ? parseInt(parseFloat(value).toFixed(0)) : parseFloat(value),
                quantityDespatch: name === "quantity" || name === "quantityDespatch" ? parseInt(parseFloat(value).toFixed(0)) : this.state.formData.quantityDespatch
            }
        }, () => {
            this.handleCalculateTotals();
        })

    }

    handleDeliveryChange = option => {
        this.setState({
            delivery: option?.value ?? ''
        })
    }

    handleReset = () => {
        this.setState({
            ...initialState
        })
    }

    handleSubmit = (e, noClose = false) => {

        // let fromCsvDrop = true;
        if(e) {
            // fromCsvDrop = false;
            e?.preventDefault?.();
        }

        const { 
            delivery, 
            formData,
            oldIdx
        } = this.state;

        let formErrors = {};

        if(formData.productId === "") {
            formErrors.productId = "Product cannot be left blank"
        }

        if(formData.quantity === "" || isNaN(formData.quantity)) {
            formErrors.quantity = "Quantity cannot be left blank"
        }

        if(formData.quantity < 0) {
            formErrors.quantity = "Quantity must be at least 1"
        }

        if(_.isEmpty(formErrors)) {

            // if(!this.props.inDialog && !_.isEmpty(formData?.productData?.notes) && !this.state.notesRead && !fromCsvDrop) {

            //     this.handleDeployNotes(true);

            // } else {

                this.setState({
                    ...initialState
                }, () => {
                    this.props.handleSubmit(formData, delivery, this.props.delivery, oldIdx);

                    if(this.props.formData) {
                        this.props.deploySnackBar("success", formData.productName + " has been updated");
                    } else {
                        this.props.deploySnackBar("success", formData.productName + " has been added");
                    }

                    if(this.props.inDialog && !noClose) {
                        this.props.closeDialog()
                    } 

                    if(!this.props.inDialog) {
                        this.productSelect.current.focus()
                    }

                })

            // }

        } else {
            this.setState({
                formErrors
            })
        }
    }

    render() {
        const   { classes, deliveries, ifm, inDialog, supplier, borderRadius, width }   = this.props,
                { delivery, formData, formErrors, isLoading, notesRead, key }           = this.state,
                notes                                                                   = _.size(formData?.productData?.notes),
                productSelected                                                         = !_.isEmpty(formData.productName);

        return (
            <Paper 
                elevation={inDialog && 0} 
                className={!inDialog ? `pt-1 pl-1` : 'pt-3'}
                style={{
                    borderRadius: borderRadius ?? undefined,
                    minHeight: !inDialog ? 95 : 120, 
                    width: width ?? undefined
                }}
            >
                <form onSubmit={this.handleSubmit} noValidate>
                    <Table style={{marginTop: !productSelected ? 11  : undefined}}>
                        <TableBody>
                            <TableRow className='pl-1'>
                                <TableCell className={classes.td} style={{width: ifm ? '37%' : '47%'}}>
                                    {(isLoading && (
                                        <CircularProgress 
                                            size={40}
                                        />
                                    )) || (productSelected && (
                                        <>
                                            <Grid container spacing={1} alignItems='center' style={{marginTop: -12}}>
                                                <Grid item className='pr-2'>
                                                    <Avatar src={formData.image ? `${CLOUDFRONT_URL}${formData.image}` : logo} style={{overflow:'visible', width: 75, height: 75}} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Box pb={2} pt={2}>
                                                        <Grid container alignItems="flex-end">
                                                            <Grid item>
                                                                <Typography variant="body2" className="fw-400">
                                                                    {formData.productCode}
                                                                </Typography>
                                                            </Grid>
                                                            {!inDialog && ( 
                                                                <Grid item>
                                                                    <Tooltip title="Modify Product">
                                                                        <IconButton 
                                                                            className='ml-1'
                                                                            onClick={this.handleReset}
                                                                            size="small"
                                                                        >
                                                                            <FAIcon type="light" icon="times-circle" size={12.5} noMargin button />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" component="div">
                                                                    {formData.productName.substr(0,65)}{formData.productName.length > 65 ? '...' : ''}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" component="div">
                                                                    {`${formData.productData?.stock_count ?? 0} In Stock | ${formData.productData?.stock_allocated_count ?? 0} Allocated | ${formData.productData?.stock_available_count ?? 0} Available | ${formData.productData?.stock_on_order_count ?? 0} On Order | ${formData.productData?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Box pt={1}>
                                                                    <ProductStockChip 
                                                                        qty={formData.quantity}
                                                                        rep={formData.productData?.product_rep_product_id}
                                                                        status={formData.productData?.product_status}
                                                                        stocked={formData.productData?.product_stock_item}
                                                                        stockAvailable={formData.productData?.stock_available_count}
                                                                        stockOnOrderAvailable={(formData.productData?.stock_on_order_count - formData.productData?.stock_on_order_allocated_count)}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            {formData.productData?.product_check_price === 1 && (
                                                                <Grid item>
                                                                    <Box pt={1}>
                                                                        <InsightChip 
                                                                            background="#FF5722"
                                                                            color="#fff"
                                                                            marginRight={8}
                                                                            paddingLeft={8}
                                                                            icon="exclamation-triangle"
                                                                            label='Check Price and Availability'
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                            {notes > 0 && (
                                                                <Grid item>
                                                                    <Box pt={1}>
                                                                        <InsightChip 
                                                                            background={notesRead ? '#4CAF50' : '#f44336'}
                                                                            color="#fff"
                                                                            marginRight={8}
                                                                            paddingLeft={8}
                                                                            icon="comment-alt-lines"
                                                                            label={notes}
                                                                            onClick={() => this.handleDeployNotes()}
                                                                            size={11}
                                                                            tooltip='View Notes'
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )) || (
                                        <ProductSearchSelector
                                            key={key}
                                            selectRef={this.productSelect}
                                            handleAction={this.handleProductChange}
                                            supplier={supplier}
                                        />
                                    )}
                                </TableCell>
                                {ifm && (
                                    <TableCell className={classes.td} style={{width: '10%'}}>
                                        <Box minWidth={200}>
                                            <AutoCompleteSelect
                                                selectRef={this.quantityInput}
                                                options={_.map(deliveries, (pod, didx) => (
                                                    _.assign({
                                                        label: `#${pod.pod_reference}/${pod.pod_idx}`,
                                                        value: didx,
                                                        disabled: (pod.pod_status === "Despatched" && pod.pod_delivered === 1) || pod.pod_status === "Invoiced" || pod.pod_status === "Cancelled"
                                                    })
                                                ))}
                                                label='Delivery *'
                                                onChange={this.handleDeliveryChange}
                                                value={delivery}
                                            />
                                        </Box>
                                    </TableCell>
                                )}
                                <TableCell className={classes.td} style={{width: '6%'}}>
                                    <TextField
                                        inputRef={!ifm ? this.quantityInput : undefined}
                                        name="quantity"
                                        label="Quantity *"
                                        value={formData.quantity}
                                        onChange={this.handleChange}
                                        error={formErrors && formErrors['quantity'] && true}
                                        type="number"
                                        fullWidth
                                        inputProps={{
                                            min: 0
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        disabled={!productSelected}
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{width: '6%'}}>
                                    <TextField
                                        name="quantityDespatch"
                                        label="Despatch *"
                                        value={formData.quantityDespatch}
                                        onChange={this.handleChange}
                                        error={formErrors && formErrors['quantityDespatch'] && true}
                                        type="number"
                                        fullWidth
                                        inputProps={{
                                            min: 0
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        disabled={!productSelected}
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{width: '10%'}}>
                                    <TextField
                                        label="Buy Price"
                                        value={formData.productData?.product_cost}
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                        disabled
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{width: '10%'}}>
                                    <TextField
                                        name="cost"
                                        label="Special Buy Price"
                                        value={formData.cost}
                                        onChange={this.handleChange}
                                        onBlur={this.handleFloat}
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                        disabled={!productSelected}
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{width: '10%'}}>
                                    <TextField
                                        name="totalCost"
                                        label='Total Buy Price'
                                        value={formData.totalCost}
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                        }}
                                        disabled
                                    />
                                </TableCell>
                                <TableCell className={classes.td}>
                                    <Box pl={1}>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Tooltip title={`${this.props.formData ? "Apply Changes" : "Add to Purchase Order"} `}>
                                                    <IconButton 
                                                        disabled={!productSelected || formData.quantity === "" || formData.totalCost === "" || isNaN(formData.totalCost) || (ifm && delivery === "")}
                                                        type='submit'
                                                        variant="outlined"
                                                    >
                                                        <FAIcon 
                                                            type="thin"
                                                            icon='check-circle' 
                                                            size={25} 
                                                            disabled={!productSelected || formData.quantity === "" || formData.totalCost === "" || isNaN(formData.totalCost) || (ifm && delivery === "")}
                                                            className={!productSelected || formData.quantity === "" || formData.totalCost === "" || isNaN(formData.totalCost) || (ifm && delivery === "") ? undefined : 'textDefault'}
                                                            button 
                                                            noMargin 
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {inDialog && (
                                                <Grid item>
                                                    <Tooltip title="Discard Changes">
                                                        <IconButton 
                                                            onClick={this.props.closeDialog}
                                                        >
                                                            <FAIcon type="thin" size={25} className="textDefault" icon='times-circle' button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {!ifm && !this.props.formData && !isTablet() && (
                                                <Grid item>
                                                    <Tooltip title="Import Lines from CSV">
                                                        <IconButton 
                                                            onClick={() => this.handleCsvImport()}
                                                            color="primary"
                                                        >
                                                            <FAIcon type="thin" size={25} icon='file-csv' className="textDefault" button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </TableCell>
                            </TableRow>  
                        </TableBody>
                    </Table>
                </form>
            </Paper>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disabled = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disabled)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(Product);