import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import CalendarEventForm from '../CalendarEventForm/CalendarEventForm';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { CLOUDFRONT_URL } from 'Constants';
import Alert from 'Components/Common/Alert/Alert';

const styles = theme => ({
    dialogPaper: {
        width: '75vh',
        maxWidth: '75vh',
        height: '75vh',
        maxHeight: '75vh',
    },
    profileContainer: {
        width: 115,
    },
    centered: {
        height: '100%',
        marginTop: '40%',
        textAlign: 'center',
    },
    staffProfile: {
        fontSize: '0.7em'
    },
    badge: {
        marginBottom: theme.spacing(1)
    },
    darr: {
        display: 'none',
        margin: 0,
    },
    rarr: {
        margin: '0 25px',
    },
    [theme.breakpoints.down('sm')]: {
        darr: {
            display: 'block',
        },
        rarr: {
            display: 'none',
        }
    },
});

const initialState = () => ({
    leader: {},
    creator: {},
    event: {},
    showCancelledMsg: false,
    showPastMsg: false,
    showEditBtn: false,
    isLoading: true,
})

class CalendarEvent extends React.Component {

    constructor(props) {
        super(props);        
        this.state = initialState();
    }

    componentDidMount = () => {
        this.getEvent();
    }

    componentDidUpdate = prevProps => {
        if (this.props.id !== prevProps.id) {
            this.setState({
                isLoading: true,
            }, () => this.getEvent());
        }
    }

    getEvent = () => {

        API.get(`/calendar/events/${this.props.id}`)
        .then(result => {
            if(result?.data) {
                let staffAttendees = _.map(result.data.staff_attendees, (staff) => {
                    return _.assign({
                        id: staff.staff_id,
                        name: `${staff.staff_first_name} ${staff.staff_last_name}`,
                        avatar: `${CLOUDFRONT_URL}${staff.staff_profile_photo}`
                    });
                });
                staffAttendees = _.filter(staffAttendees, el => el.id !== result?.data?.leader?.staff_id);
                let customerAttendees = _.map(result.data.customer_attendees, (cust) => {
                    return _.assign({
                        id: cust.customer_attendee_contact_id,
                        name: `${cust.contact_first_name} ${cust.contact_last_name}`
                    });
                });
                let supplierAttendees = _.map(result.data.supplier_attendees, (supp) => {
                    return _.assign({
                        id: supp.supplier_attendee_contact_id,
                        name: `${supp.contact_first_name} ${supp.contact_last_name}`
                    });
                });
                this.setState({
                    showSupplier: result.data.supplier ? true : false,
                    showCustomer: result.data.customer ? true : false,
                    showEditBtn: result.data.can_edit && (!moment().isAfter(moment(result.data.calendar_event_start_datetime)) && result.data.calendar_event_status !== 'Cancelled') ? true : false,
                    showPastMsg: moment().isAfter(moment(result.data.calendar_event_start_datetime)) && result.data.calendar_event_status !== 'Cancelled' ? true : false,                
                    showCancelledMsg: result.data.calendar_event_status === 'Cancelled' ? true : false,
                    event: {
                        title: `${result.data.sub_category.category_name} (${result.data.parent_category.category_name})`,
                        startsAtTime: moment(result.data.calendar_event_start_datetime).format('HH:mm'),
                        endsAtTime: moment(result.data.calendar_event_end_datetime).format('HH:mm'),                    
                        startsAtDate: moment(result.data.calendar_event_start_datetime).format('ddd MMM Do YYYY'),
                        endsAtDate: moment(result.data.calendar_event_end_datetime).format('ddd MMM Do YYYY'),
                        details: result.data.calendar_event_notes,
                        name: result.data.calendar_event_name,
                        link: result.data.calendar_event_link,
                        supplierName: result.data.supplier ? result.data.supplier.supp_company_name : null,
                        customerName: result.data.customer ? result.data.customer.cust_company_name : null,
                        staffAttendees: staffAttendees,
                        customerAttendees: customerAttendees,
                        supplierAttendees: supplierAttendees,
                        email: result.data.calendar_event_email
                    },
                    leader: result.data.leader,
                    creator: result.data.creator,
                    isLoading: false,
                });
            }
        });
    };

    handleDeployUpdateEvent = id => {
        this.props.deployDialog(
            <CalendarEventForm callback={this.props.callback ?? undefined} id={this.props.id} />,
            false,
            "Update Calendar Event",
            "standard",
            "sm"
        )
    }

    render = () => {

        const { 
            closeDialog
        } = this.props;
        
        const { 
            event,
            isLoading
        } = this.state;

        return (
            (isLoading && (
                <LoadingCircle />    
            )) || (   
                <>     
                    <Box className="content-light-grey">
                        <DialogTitle disableTypography style={{paddingBottom: 0}}>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        {event.title}
                                    </Typography>
                                    <Grid container alignItems='center' className="pb-2">
                                        <Grid item>
                                            <FAIcon 
                                                type="light" 
                                                size={15}
                                                icon='clock'
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body2" className="fw-400">
                                                {event.startsAtDate} {event.startsAtTime} <strong>&rarr;</strong> {event.endsAtDate} {event.endsAtTime}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item align='right'>
                                    <Grid container spacing={1}>
                                        {this.state.showEditBtn && (
                                            <Grid item>
                                                <Tooltip title="Edit Event">
                                                    <IconButton onClick={this.handleDeployUpdateEvent}>
                                                        <FAIcon icon="pencil" button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Tooltip title="Close">
                                                <IconButton onClick={closeDialog}>
                                                    <FAIcon icon="times" button noMargin />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <Box p={3} pt={0.5}>
                            <Box pb={3}>
                                <Alert severity={event.email === 1 ? 'success' : 'warning'}>
                                    {event.email === 1 ? 'This event was emailed to attendees' : 'This event was not emailed to attendees'}
                                </Alert>
                            </Box>
                            <Box pb={3}>
                                <Box className="content-light-white" p={3}>
                                    <Typography variant="h6" paragraph>
                                        {event.name}
                                    </Typography>
                                    <Typography variant="caption">
                                        {(event.details && (
                                            event.details.split('\n').map((item, key) => (
                                                <div key={key}>{item}</div>
                                            ))
                                        )) || (
                                            <em>No notes were specified for this event</em>
                                        )}
                                    </Typography>
                                    {event.link && (
                                        <Box pt={2}>
                                            <Button component={Link} to={event.link} variant="outlined" color="primary">View Details</Button>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box pb={3}>
                                <Box className="content-light-white" p={3}>
                                    <Typography variant="h6" paragraph>
                                        Meeting With
                                    </Typography>
                                    <Grid container spacing={3} alignItems='center'>
                                        <Grid item xs>
                                            <Chip
                                                avatar={<FAIcon icon="check" className="textSuccess" size={32} noMargin />}
                                                label={<><Typography variant="body1" className="fw-400">{event.customerName ?? event.supplierName ?? 'Internal'}</Typography><Typography variant="caption">{event.customerName ? 'Customer' : (event.supplierName ? 'Supplier' : 'Advanced Access')}</Typography></>}
                                                variant="outlined"
                                                style={{border: 0, height: 40, marginRight: 10}}
                                            />     
                                        </Grid>
                                        <Grid item>
                                            <StatusChip status={this.state.showPastMsg ? 'Past Event' : (this.state.showCancelledMsg ? 'Cancelled' : 'Upcoming Event')} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box pb={3}>
                                <Box className="content-light-white" p={3}>
                                    <Typography variant="h6" paragraph>
                                        Attendees
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Chip
                                                avatar={<Avatar alt={`${this.state.leader?.staff_first_name} ${this.state.leader?.staff_last_name}`} src={`${CLOUDFRONT_URL}${this.state.leader?.staff_profile_photo}`} style={{width: 32, height: 32}} />}
                                                label={<><Typography variant="body1" className="fw-400">{`${this.state.leader?.staff_first_name} ${this.state.leader?.staff_last_name}`}</Typography><Typography variant="caption">Leader</Typography></>}
                                                variant="outlined"
                                                style={{border: 0, height: 40}}
                                            />
                                        </Grid>
                                        {_.map(event.staffAttendees, attendee => (
                                            <Grid item>
                                                <Chip
                                                    avatar={<Avatar alt={attendee.name} src={attendee.avatar} style={{width: 32, height: 32}} />}
                                                    label={<><Typography variant="body1" className="fw-400">{attendee.name}</Typography><Typography variant="caption">Advanced Access</Typography></>}
                                                    variant="outlined"
                                                    style={{border: 0, height: 40}}
                                                    key={attendee.id}
                                            />
                                            </Grid>
                                        ))}
                                        {this.state.showCustomer && _.map(event.customerAttendees, attendee => (
                                            <Grid item>
                                                <Chip
                                                    avatar={<FAIcon icon="user-check" size={32} noMargin />}
                                                    label={<><Typography variant="body1" className="fw-400">{attendee.name}</Typography><Typography variant="caption">{event.customerName}</Typography></>}
                                                    variant="outlined"
                                                    style={{border: 0, height: 40}}
                                                    key={attendee.id}
                                            />
                                            </Grid>
                                        ))}
                                        {this.state.showSupplier && _.map(event.supplierAttendees, attendee => (
                                            <Grid item>
                                                <Chip
                                                    avatar={<FAIcon icon="user-check" size={32} noMargin />}
                                                    label={<><Typography variant="body1" className="fw-400">{attendee.name}</Typography><Typography variant="caption">{event.supplierName}</Typography></>}
                                                    variant="outlined"
                                                    style={{border: 0, height: 40}}
                                                    key={attendee.id}
                                                />     
                                            </Grid>                                          
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Box>     
                </>
            )
        )
    }
}

const mapDispatchToProps = dispatch => (
    {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    }
)

export default connect(null, mapDispatchToProps)(withStyles(styles)(CalendarEvent));