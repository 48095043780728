import React from 'react';
import _ from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import StaffAccessItems from './StaffAccessItems';
import { Hidden } from '@material-ui/core';

function objectsHaveSameKeys(...objects) {
    const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
    const union = new Set(allKeys);
    return objects.every(object => union.size === Object.keys(object).length);
}

const styles = theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#ef3340',
        color: '#fff'
    },
    flex: {
        flex: 1,
    }
});

const initialState = {
    allAccess: {},
    staffAccess: {},
    staffData: {},
    parentId: 0,
    currentStaffAccess: {},
    confirmationOpen: false,
    snackbarOpen: false,
    viewPage: {
        dialogOpen: false,
        url: null
    },
    allAccessLoading: true,
    currentAccessLoading: true
}

class StaffAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        window.onpopstate = this.handleBackButton;
    }
    componentDidMount = () => {
        this.setState({
            ...this.state,
            staffData: this.props.staff
        }, () => {
            this.loadAllAccess();
            this.loadCurrentStaffAccess();
        })
    }
    componentDidUpdate = (prevProps) => {
        if(this.props.staff !== prevProps.staff) {
            this.setState({
                ...this.state,
                staffData: this.props.staff
            }, () => this.loadCurrentStaffAccess())
        }
    }
    handleBackButton = () => {
        if(this.state.viewPage.dialogOpen) {
            // GET IT TO STOP PAGE CHANGE IF DIALOG OPEN
            this.props.history.go(1);
        }
    }
    loadAllAccess = () => {
        this.setState({
            allAccessLoading: true
        }, () => {
            API.get('/access/all').then(result => {
                this.setState({
                    allAccess: result.data,
                    allAccessLoading: false
                })
            })
        })
    }
    loadCurrentStaffAccess = () => {
        this.setState({
            currentAccessLoading: true
        }, () => {
            API.get('/staff/' + this.props.id + '/access', {
                params: {
                    noStructure: true
                }
            }).then(result => {
                _.map(result.data, access => {
                    this.setState({
                        currentStaffAccess: {
                            ...this.state.currentStaffAccess,
                            [access.access_id]: true
                        },
                        staffAccess: {
                            ...this.state.staffAccess,
                            [access.access_id]: true
                        }
                    })
                })
                this.setState({
                    currentAccessLoading: false
                });
            })
        })
    }
    updateAccess = () => {
        let accessIds = Object.keys(this.state.staffAccess);
        API.put('/staff/' + this.props.id + '/access', accessIds).then(result => {
            if(result.data.errors) {
            } else {
                this.setState({
                    ...this.state,
                    currentStaffAccess: {
                        ...this.state.staffAccess
                    },
                    snackbarOpen: true
                });
            }
            this.props.scrollToTop();
        });
    }
    checkAllToggle = event => {
        event.stopPropagation();

        let accessId = parseInt(event.target.name);
        let accessItem = this.getRecursiveAccessItem(accessId, this.state.allAccess)
        let allAccessItems = this.getRecursiveAccessChildren(accessItem);
        let newAccess = this.state.staffAccess;
        
        if(event.target.checked) {
            if(!this.state.staffAccess.hasOwnProperty(accessId)) {
                newAccess = {
                    ...newAccess,
                    [accessId]: true
                }
            }
            allAccessItems.forEach(accessItem => {
                if(!this.state.staffAccess.hasOwnProperty(accessItem.access_id)) {
                    newAccess = {
                        ...newAccess,
                        [accessItem.access_id]: true
                    }
                }
            });
            let parent =  _.find(this.state.allAccess, {'children': [{'access_id': parseInt(accessId)}] });
            // If a child element has been ticked then automatically check the parent
            if(parent && parent.access_id > 0){
                newAccess = {
                    ...newAccess,
                    [parent.access_id]: true
                }
            }
        } else {
            allAccessItems.forEach(accessItem => {
                if(newAccess.hasOwnProperty(accessId)) {
                    newAccess = _.omit(newAccess, accessId);
                }
                if(newAccess.hasOwnProperty(accessItem.access_id)) {
                    newAccess = _.omit(newAccess, accessItem.access_id);
                }
            });
        }
        this.setState({
            staffAccess: newAccess
        });
    }
    getRecursiveAccessItem = (accessId, allAccess) => {
        var result;
        allAccess.some(o => (o.access_id === accessId && (result = o)) || (result = this.getRecursiveAccessItem(accessId, o.children || [])));
        return result;
    }
    getRecursiveAccessChildren = (accessItem) => {
        let allChildren = [];
        _.map(accessItem.children, (child) => {
            allChildren.push(child);
            if(child.children) {
                let childrensChildren = this.getRecursiveAccessChildren(child);
                allChildren = [ ...allChildren, ...childrensChildren ]
            }
        });
        return allChildren;
    }
    isAllChecked = (accessId) => {
        let accessItem = _.find(this.state.allAccess, (accessItem) => {
            return accessItem.access_id === accessId
        });
        if(!this.state.staffAccess.hasOwnProperty(accessId)) {
            return false;
        }
        let allAccessItems = this.getRecursiveAccessChildren(accessItem);
        allAccessItems.forEach(_accessItem => {
            if(!this.state.staffAccess.hasOwnProperty(_accessItem.access_id)) {
                return false;
            }
        });
        return true;
    }
    toggleAccess = (event) => {
        let accessId = event.target.name;
        if(this.state.staffAccess.hasOwnProperty(accessId)) {
            this.setState({
                staffAccess: _.omit(this.state.staffAccess, accessId)
            })
        } else {
            let parent =  _.find(this.state.allAccess, {'children': [{'access_id': parseInt(accessId)}] });
            // If a child element has been ticked then automatically check the parent
            if(parent && parent.access_id > 0){
                this.setState({
                    staffAccess: {
                        ...this.state.staffAccess,
                        [accessId]: true,
                        [parent.access_id]: true,
                    }
                });
            }
            else {
                this.setState({
                    staffAccess: {
                        ...this.state.staffAccess,
                        [accessId]: true
                    }
                });
            }
        }
    };
    showPageExample = (url, title) => {
        this.setState({
            viewPage: {
                title: title,
                url: url,
                dialogOpen: true
            }
        })
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.updateAccess();
    }

    handleViewPageDialogClose = () => {
        this.setState({ 
            viewPage: {
                ...this.state.viewPage,
                dialogOpen: false
            }
        });
    }

    handleSetParentId = parentId => {
        this.setState({
            parentId
        }, this.props.scrollToTop)
    }

    render() {
        const { classes } = this.props;
        const { staffAccess, allAccess, parentId, snackbarOpen, viewPage, allAccessLoading, currentAccessLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    {((allAccessLoading || currentAccessLoading) && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12} lg={7}>
                                <StaffAccessItems   
                                    parentId={parentId}
                                    toggleAccess={this.toggleAccess}
                                    checkAllToggle={this.checkAllToggle}
                                    staffAccess={staffAccess}
                                    accessItems={allAccess}
                                    isAllChecked={this.isAllChecked}
                                    showPageExample={this.showPageExample}
                                    dataLoading={((allAccessLoading || currentAccessLoading) && true) || false} 
                                />
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Update staff access?" 
                                    message="Are you sure you want to update the access for this staff member?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message="The staff member's access was successfully updated"
                                />
                            </Grid>
                            <Hidden mdDown>
                                <Grid item xs={12} lg={5}>
                                    <div style={{position: 'fixed', width: '29.5%'}}>
                                        <PaddedPaper>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>                                            
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <Typography variant="h6">
                                                                RED Sections
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='right'>
                                                            <Button type="submit"
                                                                    variant="text"
                                                                    color="primary"
                                                                    onClick={this.handleConfirmationOpen}
                                                                    disabled={objectsHaveSameKeys(this.state.currentStaffAccess, this.state.staffAccess)}>
                                                                <FAIcon icon="check" disabled={objectsHaveSameKeys(this.state.currentStaffAccess, this.state.staffAccess)} size={15} button />
                                                                Update Staff Access
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>                                        
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" color={(parentId === 0 && 'primary') || 'initial'} style={{cursor: 'pointer'}} onClick={() => this.handleSetParentId(0)}>
                                                                <FAIcon type="light" icon='binoculars' size="small" color={(parentId === 0 && 'primary') || 'initial'} />
                                                                View All
                                                            </Typography>
                                                        </Grid>
                                                        {_.map(_.filter(allAccess, function(el) { return el.access_parent_id === 0 }), (item, idx) => (
                                                            <Grid item xs={6} key={idx}>
                                                                <Grid container alignItems='center'>
                                                                    <Grid item>
                                                                        <FAIcon type="light" icon={item.access_icon} color={(parentId === item.access_id && 'primary') || 'initial'} size="small" />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Typography variant="body2" color={(parentId === item.access_id && 'primary') || 'initial'} style={{cursor: 'pointer'}} onClick={() => this.handleSetParentId(item.access_id)}>
                                                                            {item.access_name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Dialog disableEscapeKeyDown={true}
                                    disableBackdropClick={true}
                                    open={viewPage.dialogOpen}
                                    onClose={this.handleViewPageDialogClose}
                                    fullScreen>
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <Typography variant="h6" color="inherit" className={classes.flex}>
                                            Viewing Page
                                        </Typography>
                                        <IconButton color="inherit" onClick={this.handleViewPageDialogClose} aria-label="Close">
                                            <FAIcon type="light" icon="times" buttonPrimary noMargin />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                {(viewPage.url && (
                                    <iframe src={viewPage.url} style={{height:'100%', border:'none', pointerEvents:'none'}} title="Preview Page" />
                                )) || (
                                    <DialogContent style={{marginTop:16}}>
                                        No page to show
                                    </DialogContent>
                                )}
                            </Dialog>
                        </>
                    )}
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(StaffAccess);