import React from 'react';
import { useSelector } from 'react-redux';

import StaffChip from 'Components/Common/Chips/StaffChip';
import Search from 'Components/Common/Search/Search';

const NewProspects = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/newProspects',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Status',
                        field: rowData => rowData.cs,
                        dataRef: 'cs',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Call',
                        field: rowData => rowData.lc,
                        fieldFormat: 'datetime',
                        dataRef: 'lc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Quote',
                        field: rowData => rowData.lq,
                        fieldFormat: 'datetime',
                        dataRef: 'lq',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Proactive Responsibility',
                        field: rowData => rowData.am ? <StaffChip missingText='Not Set' staff={staff?.[rowData?.am]} /> : '-',
                        fieldFormat: 'staff',
                        dataRef: 'am',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Created',
                        field: rowData => rowData.ca,
                        fieldFormat: 'datetime',
                        dataRef: 'ca',
                        important: true,
                        sizeToContent: true
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'new-prospects',
                noResultsText: 'Sorry, no new prospects to show',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Customers'],
                pdf: true,
                persistenceId: 'newProspects',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'New Prospects'
            }}
        />
    )
}

export default NewProspects;