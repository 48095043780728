import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { getPresignedUrl } from 'Functions/S3Functions';

const initialState = {
    access: {
        sendEmail: false
    },
    isLoading: true,
    email: {}
}

const ViewEmail = ({id, closeDialog, resend}) => {

    const [state, setState] = useState(initialState);

    const handleClose = () => {
        if(closeDialog) {
            closeDialog();
        }
    }
    
    useEffect(() => {
        Promise.all([
            API.get(`/email/${id}`),
            API.access('email-send')
        ])
        .then(([res, sendEmail]) => {
            if(res?.data) {
                setState({
                    access: {
                        sendEmail: sendEmail?.data?.has_access || false
                    },
                    isLoading: false,
                    email: res.data
                })
            }
        })
    }, [id]);

    const isLoading = state.isLoading,
              email = state.email,
             access = state.access;
         
    const handleResend = () => {
        if(resend) {
            resend(false, JSON.parse(email.email_data), email.email_staff_id)
        }
    }  
    return (
        isLoading && (
            <LoadingCircle />
        )) || (
            <form noValidate autoComplete="off" onSubmit={e => e.preventDefault()} className="pt-1">
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs>
                        <Typography variant="h6">
                            {email.email_subject}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {access.sendEmail && (
                            <Tooltip title="Resend">
                                <IconButton 
                                    onClick={handleResend}
                                >
                                    <FAIcon icon="repeat" noMargin button />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Close">
                            <IconButton 
                                onClick={handleClose}
                            >
                                <FAIcon icon="times" noMargin button />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                            Sent
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {moment(email.email_created_datetime).format("LLLL")}
                        </Typography>
                        {email.email_opened_datetime !== "0000-00-00 00:00:00" && (
                            <>
                                <Typography variant="caption" color="textSecondary">
                                    Opened
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    {moment(email.email_opened_datetime).format("LLLL")}
                                </Typography>
                            </>
                        )}
                         <Typography variant="caption" color="textSecondary">
                            Sender
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {email.email_from}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            Recipient
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {email.email_to}
                        </Typography>
                        {!_.isEmpty(email.email_cc) && (
                            <>
                                <Typography variant="caption" color="textSecondary">
                                    CC
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    {_.map(email.email_cc.split(','), (cc, idx) => (
                                        <div key={idx}>
                                            {cc}
                                        </div>
                                    ))}
                                </Typography>
                            </>
                        )}
                        <Typography variant="caption" color="textSecondary">
                            Status
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {email.email_status}
                        </Typography>
                        {!_.isEmpty(email.attachments) && (
                            <>
                                <Box mb={2}>
                                    <Box mb={1}>
                                        <Typography variant="caption" color="textSecondary">
                                            Attachments
                                        </Typography>
                                    </Box>
                                    <List dense disablePadding className="content-light-grey">
                                        {_.map(email.attachments, (file, idx) => (
                                            <ListItem 
                                                key={idx} 
                                                button
                                                className="pt-1 pb-1"
                                                disabled
                                                style={{opacity: 1}}
                                            >
                                                <ListItemAvatar>
                                                    <FAIcon
                                                        icon='file'
                                                        type="light"
                                                        size={20}
                                                        noMargin
                                                        className="textDefault" 
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText 
                                                    className="textDefault" 
                                                    primary={file.doc_title} 
                                                />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title="View File">
                                                        <IconButton onClick={e => { e.stopPropagation(); getPresignedUrl(file.doc_filekey)}}>
                                                            <FAIcon icon="binoculars" size={15} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                        </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className='pt-2'>
                        <Typography variant="body2" paragraph>
                            {(!_.isEmpty(email.email_body) && (
                                <div 
                                    dangerouslySetInnerHTML={{__html: email.email_body}}
                                />
                            )) || (
                                <em>This e-mail has no body</em>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </form>
    )
}

export default ViewEmail;