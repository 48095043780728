import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import logo from 'Assets/Images/no-img.jpg';
import SearchCreditNotesTable from 'Components/Accounts/SearchCreditNotes/SearchCreditNotesTable';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import Alert from 'Components/Common/Alert/Alert';
import InsightChip from 'Components/Common/Chips/InsightChip';
import ProductStockChip from 'Components/Common/Chips/ProductStockChip';
import DataTable from 'Components/Common/DataTables/DataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import ViewProduct from 'Components/Products/ViewProduct/ViewProduct';
import Product from 'Components/RMA/RMAForm/Product';
import { CLOUDFRONT_URL } from 'Constants';
import { momentFormatDate } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

const Products = ({access, callback, classes, customer, defaultAction, defaultReason, defaultReStockingFee, formData, handleChange, key,  handleProductEdit, handleProductRemove, handleSubmit, inline, manage, rma, rmaModified, rows, products, readOnly}) => {

    const dispatch = useDispatch();

    const dispatchDialogClose = () => dispatch(closeDialog())

    const handleDeployNotes = (name, notes) => {
        dispatch(deployDialog(
            <ViewNotes hideDeleteButton notes={notes} inline />, false, `${name}`, "standard", "sm"
        ))
    }

    const handleEditProductRow = (key, editProductRow) => {

        const   submit          = _.curry(handleProductEdit),
                handleSubmit    = productData => submit(key, productData);

        dispatch(deployDialog(
            <Product
                closeDialog={dispatchDialogClose}
                defaultAction={defaultAction}
                defaultReason={defaultReason}
                defaultReStockingFee={defaultReStockingFee}
                classes={classes}
                customer={customer?.id}
                customerVatPercent={formData.customerVatPercent}
                formData={editProductRow}
                productRows={formData.productRows}
                products={products}
                handleSubmit={handleSubmit}
                inDialog
                carriage={editProductRow?.productId === -1 ? true : undefined}
                manual={!editProductRow?.odId ? true : undefined}
            />, 
            false, 
            "", 
            "standard", 
            "xl",
            false,
            true
        ))

    }

    const handleProductInfoOpen = productId => {
        dispatch(deployDialog(
            <ViewProduct id={productId} inDialog />, 
            false, 
            "Product Details", 
            "standard", 
            "xl",
            true
        ))
    }

    let proposedCreditNotes = {};

    // _.each(rows ?? formData?.productRows, (row, idx) => {

    //     if(row.aaCredit === 1 && row.closed === 1 && row.status !== 'Closed') {

    //         let ref = !_.isEmpty(row.suppCreditRef) ? row.suppCreditRef : 'AA',
    //             total = proposedCreditNotes[ref]?.total ?? 0,
    //             date = !_.isEmpty(row.suppCreditDate) && row.suppCreditDate !== "0000-00-00" ? moment(row.suppCreditDate).format("DD/MM/YYYY") : moment().format('DD/MM/YYYY');
           
    //         total = (parseFloat(total) + parseFloat(row.total)).toFixed(2);

    //         proposedCreditNotes = {
    //             ...proposedCreditNotes,
    //             [ref]: {
    //                 ...proposedCreditNotes[ref],
    //                 date,
    //                 total,
    //                 ref
    //             }
    //         }

    //     }

    // });

    return (
        <>
            {rma?.rma_status !== 'Complete' && rma?.rma_status !== 'Closed' && rma?.rma_status !== 'Cancelled' && manage && (
                <Box mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs md={7}>
                            {!_.isEmpty(proposedCreditNotes) && (
                                <Box className="content-error-paper">
                                    <Box p={1} pb={0.1} style={{background: '#d9534f'}}>
                                        <Typography variant="h6" gutterBottom className="textLight">
                                            Proposed Credit Notes
                                        </Typography>
                                    </Box>
                                    <Paper>
                                        <Box p={2} pb={3}>
                                            <Box mb={1}>
                                                <Alert severity="error" variant="outlined">
                                                    The following credit notes will be created when changes are processed, please ensure this is correct before processing changes. If you want to manually create the credit note instead, please untick AA credit for each line.
                                                </Alert>
                                            </Box>
                                            <DataTable
                                                config={{
                                                    key: 'idx',
                                                    inline: true,
                                                    noRowHover: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Supplier Reference',
                                                        field: rowData => rowData.ref === 'AA' ? 'N/A' : rowData.ref,
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Credit Amount',
                                                        field: rowData => rowData.total,
                                                        fieldFormat: 'currency',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Date',
                                                        field: rowData => rowData.date,
                                                        sizeToContent: true,
                                                    }
                                                ]}
                                                rows={proposedCreditNotes}
                                            />
                                        </Box>
                                    </Paper>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs align='right'>
                            <Button onClick={() => dispatch(deployConfirmation(`Are you sure you want to save these changes?`, handleSubmit))} variant="contained" color="primary">
                                <FAIcon icon="check" buttonPrimary />
                                Process Changes
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}       
            {!_.isEmpty(rma?.credit_notes) && (
                <Box mb={3}>
                    <Paper>
                        <Box p={3} pb={1}>
                            <Typography variant="h6" gutterBottom>
                                Credit Notes
                            </Typography>
                            <SearchCreditNotesTable access={access} callback={callback} rows={rma?.credit_notes} basic />
                        </Box>
                    </Paper>
                </Box>
            )}
            {_.map(rows ?? formData?.productRows ?? [], (row, key) => (
                <Box mb={(key + 1) !== _.size(rows ?? formData?.productRows ?? []) ? 3 : undefined}>
                    <Paper elevation={inline && 0} className={inline ? 'content-light-white' : undefined} key={key}>
                        <Box p={3} pb={_.isEmpty(row.rmaNotes) && _.isEmpty(row.sysNotes) ? 0 : 3} key={key}>
                            <Grid container justify="space-between">
                                {!readOnly && !manage && (
                                    <>
                                        <Grid item xs>
                                            <Typography variant="h6" gutterBottom>
                                                Line {(key + 1)} of {_.size(rows ?? formData?.productRows)} - {row.productId < 0 ? 'Carriage Credit' : 'Product Return'}
                                            </Typography>
                                        </Grid>
                                        {row.productId > 0 && (
                                            <Grid item>
                                                <Grid container spacing={1} justify="flex-end">
                                                    <Grid item>
                                                        <Tooltip title="View Product">
                                                            <IconButton onClick={() => handleProductInfoOpen(row.productId)}>
                                                                <FAIcon icon="binoculars" noMargin size={16} button />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Grid container spacing={1} justify="flex-end">
                                                <Grid item>
                                                    <Tooltip title="Modify">
                                                        <IconButton disabled={row.status === "Closed"} onClick={() => handleEditProductRow(key, row)}>
                                                            <FAIcon icon="pencil" noMargin button size={15} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1} justify="flex-end">
                                                <Grid item>
                                                    <Tooltip title="Remove">
                                                        <IconButton disabled={row.status === "Closed"} onClick={() => dispatch(deployConfirmation(`Are you sure you want to remove this line?`, () => handleProductRemove(key)))}>
                                                            <FAIcon icon="trash-alt" noMargin button size={15} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            {(!manage && !readOnly && row.status === "Closed" && (
                                <Grid item xs={12}>
                                    <Box mb={2.5} mt={1}>
                                        <Alert severity="error">
                                            This line cannot be modified as it has been actioned
                                        </Alert>
                                    </Box>
                                </Grid>
                            )) || (!manage && !readOnly && row.recQuantity > 0 && (
                                <Grid item xs={12}>
                                    <Box mb={2.5} mt={1}>
                                        <Alert severity="info">
                                            Goods have already been returned for this line - {row.recQuantity} received <em>({row.recPreQuantity} pristine)</em>
                                        </Alert>
                                    </Box>
                                </Grid>
                            ))}
                            {row.productId < 0 && (
                                <Grid container spacing={1} alignItems='center' className="pb-1">
                                    <Grid item>
                                        <FAIcon type="light" icon="exclamation-triangle" className="textError" size={13.5} noMargin /> 
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className="fw-400">
                                            This line must be for carriage credit only as it will be posted into Sage under the 4095 - Distribution and Carriage nominal code
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Table className="mb-1">
                                <TableBody>
                                    <TableRow className='pl-1'>
                                        <TableCell className={classes.td} style={{width: row.productId < 0 ? '30%' : '45%'}}>
                                            {(row.productId > 0 && (
                                                <Grid container spacing={1} alignItems='center' style={{marginTop: -12}}>
                                                        <Grid item className='pr-2'>
                                                            <Avatar src={row.image ? `${CLOUDFRONT_URL}${row.image}` : logo} style={{overflow:'visible', width: 75, height: 75}} />
                                                        </Grid>
                                                    <Grid item xs>
                                                        <Grid container alignItems="flex-end">
                                                            <Grid item>
                                                                <Typography variant="body2" className="fw-400">
                                                                    {row.productCode}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" component="div">                                                                    
                                                                    {row.productName.substr(0,65)}{row.productName.length > 65 ? '...' : ''}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" component="div">
                                                                    {`${row.productData?.stock_count ?? 0} In Stock | ${row.productData?.stock_allocated_count ?? 0} Allocated | ${row.productData?.stock_available_count ?? 0} Available | ${row.productData?.stock_on_order_count ?? 0} On Order | ${row.productData?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                                                </Typography>
                                                            </Grid>
                                                            {row.supplier && (
                                                                <Grid item xs={12}>
                                                                    <Typography variant="caption" component="em">
                                                                        Supplied by {row.supplier}
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                            <Grid item>
                                                                <Box pt={1}>
                                                                    <ProductStockChip 
                                                                        qty={row.quantity}
                                                                        rep={row.productData?.product_rep_product_id}
                                                                        status={row.productData?.product_status}
                                                                        stocked={row.productData?.product_stock_item}
                                                                        stockAvailable={row.productData?.stock_available_count}
                                                                        stockOnOrderAvailable={(row.productData?.stock_on_order_count - row.productData?.stock_on_order_allocated_count)}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            {row.productData?.product_check_price === 1 && (
                                                                <Grid item>
                                                                    <Box pt={1}>
                                                                        <InsightChip 
                                                                            background="#FF5722"
                                                                            color="#fff"
                                                                            marginRight={8}
                                                                            paddingLeft={8}
                                                                            icon="exclamation-triangle"
                                                                            label='Check Price and Availability'
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                            {_.size(row.notes ?? row?.productData?.notes ?? row?.product?.notes) > 0 && (
                                                                <Grid item>
                                                                    <Box pt={1}>
                                                                        <InsightChip 
                                                                            background="#4CAF50"
                                                                            color="#fff"
                                                                            marginRight={8}
                                                                            paddingLeft={8}
                                                                            icon="comment-alt-lines"
                                                                            label={_.size(row.notes ?? row?.productData?.notes ?? row?.product?.notes)}
                                                                            onClick={() => handleDeployNotes(row.productCode, row.notes ?? row.productData?.notes ?? [])}
                                                                            size={11}
                                                                            tooltip='View Notes'
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )) || (
                                                <TextField
                                                    label="Description"
                                                    value={row.productName}
                                                    fullWidth
                                                    InputProps={{
                                                        className: "textDefault",
                                                        disableUnderline: true,
                                                    }}
                                                    InputLabelProps={{
                                                        className: "textLabel",
                                                        shrink: true
                                                    }}
                                                    disabled
                                                />
                                            )}
                                        </TableCell>
                                        {row.productId > 0 && (
                                            <>
                                                <TableCell className={classes.td} style={{width: '7%'}}>
                                                    <TextField
                                                        name="quantity"
                                                        label="Quantity"
                                                        value={row.quantity}
                                                        fullWidth
                                                        InputProps={{
                                                            className: "textDefault",
                                                            disableUnderline: true,
                                                        }}
                                                        InputLabelProps={{
                                                            className: "textLabel",
                                                            shrink: true
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width: '8%'}}>
                                                    <TextField
                                                        name="price"
                                                        label="Amount Paid"
                                                        value={row.price}
                                                        type="number"
                                                        fullWidth
                                                        InputProps={{
                                                            className: "textDefault",
                                                            disableUnderline: true,
                                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                        }}
                                                        InputLabelProps={{
                                                            className: "textLabel",
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            min: 0,
                                                            step: 0.01
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width: '16%'}}>
                                                    <TextField
                                                        name="restockFee"
                                                        label="Restock Fee"
                                                        value={`${row.restockFee}  (${row.restockPercent}%)`}
                                                        fullWidth
                                                        InputProps={{
                                                            className: "textDefault",
                                                            disableUnderline: true,
                                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                        }}
                                                        InputLabelProps={{
                                                            className: "textLabel",
                                                            shrink: true
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width: '10%'}}>
                                                    <TextField
                                                        name="subTotal"
                                                        label='Credit Amount'
                                                        value={row.subTotal}
                                                        type="number"
                                                        fullWidth
                                                        InputProps={{
                                                            className: "textDefault",
                                                            disableUnderline: true,
                                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                        }}
                                                        InputLabelProps={{
                                                            className: "textLabel",
                                                            shrink: true
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell className={classes.td} style={{width: '10%'}}>
                                            <TextField
                                                name="total"
                                                label="Net Credit Total"
                                                value={row.total}
                                                type="number"
                                                fullWidth
                                                InputProps={{
                                                    className: "textDefault",
                                                    disableUnderline: true,
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                                InputLabelProps={{
                                                    className: "textLabel",
                                                    shrink: true
                                                }}
                                                disabled
                                            />
                                        </TableCell>
                                        {row.productId < 0 && (
                                            <>
                                                <TableCell className={classes.td} style={{width: '20%'}}>
                                                    <TextField
                                                        label="Reason"
                                                        value={_.find([
                                                            {
                                                                label: "Refund Return Carriage",
                                                                value: "CARRIAGE_REFUND"
                                                            },
                                                            {
                                                                label: "Refund Order Carriage (Courier)",
                                                                value: "CARRIAGE_ISSUE"
                                                            }
                                                        ], el => el.value === row.reason)?.label}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            className: "textLabel",
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            className: "textDefault",
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment>
                                                                    <FAIcon icon='exclamation-triangle' size={15} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width: '20%'}}>
                                                    <TextField
                                                        label="Action"
                                                        value={_.find([
                                                            {
                                                                label: "COA - Credit On Account",
                                                                value: "COA"
                                                            },
                                                            {
                                                                label: "CCR - Credit Card Refund",
                                                                value: "CCR"
                                                            }
                                                        ], el => el.value === row.action)?.label}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            className: "textLabel",
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            className: "textDefault",
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment>
                                                                    <FAIcon icon='check' size={15} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>  
                                </TableBody>
                            </Table>
                            {row.productId > 0 && (
                                <Table>
                                    <TableBody>
                                        <TableRow className='pl-1'>
                                            <TableCell className={classes.td} style={{width: '10%'}}>
                                                <TextField
                                                    label="Delivery Date"
                                                    value={!_.isEmpty(row.deliveryDate) ? momentFormatDate(row.deliveryDate) : 'N/A'}
                                                    fullWidth
                                                    InputProps={{
                                                        className: "textDefault",
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment>
                                                                <FAIcon icon='calendar-alt' size={15} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    InputLabelProps={{
                                                        className: "textLabel",
                                                        shrink: true
                                                    }}
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell className={classes.td} style={{width: '10%'}}>
                                                {(row.orderId && (
                                                    <Box mt={-2.5}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <InputLabel shrink color="textSecondary">
                                                                    Sales Order
                                                                </InputLabel>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box pt={0.5}>
                                                                    <Tooltip title="View Sales Order">
                                                                        <Link to={row.odId ? `/sales/${row.orderId}:${row.odId}` : `/sales/${row.orderId}`} target="_blank">
                                                                            <InsightChip
                                                                                icon='external-link'
                                                                                className="link"
                                                                                label={`#${row.orderRef}`}
                                                                                paddingRight={4}
                                                                                paddingLeft={8}
                                                                            />
                                                                        </Link>
                                                                    </Tooltip>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                )) || (
                                                    <TextField
                                                        label="Sales Order"
                                                        value={!_.isEmpty(row.orderRef) ? row.orderRef : 'N/A'}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            className: "textLabel",
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            className: "textDefault",
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment>
                                                                    <FAIcon icon='hashtag' size={15} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.td} style={{width: '10%'}}>
                                                <TextField
                                                    label="Serial"
                                                    value={!_.isEmpty(row.serial) ? row.serial : 'N/A'}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        className: "textLabel",
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        className: "textDefault",
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment>
                                                                <FAIcon icon='hashtag' size={15} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell className={classes.td} style={{width: '20%'}}>
                                                <TextField
                                                    label="Reason"
                                                    value={_.find([
                                                        {
                                                            label: "Damaged",
                                                            value: "DAMAGED"
                                                        },
                                                        {
                                                            label: "Faulty Goods",
                                                            value: "FAULTY_GOODS"
                                                        },
                                                        {
                                                            label: "No Longer Required",
                                                            value: "NO_LONGER_REQUIRED"
                                                        },
                                                        {
                                                            label: "Wrong Goods Ordered",
                                                            value: "WRONG_GOODS_ORDERED"
                                                        },
                                                        {
                                                            label: "Wrong Goods Supplied (AA)",
                                                            value: "WRONG_GOODS_SUPPLIED_AA"
                                                        },
                                                        {
                                                            label: "Wrong Goods Supplied (Supplier)",
                                                            value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                                                        }
                                                    ], el => el.value === row.reason)?.label}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        className: "textLabel",
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        className: "textDefault",
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment>
                                                                <FAIcon icon='exclamation-triangle' size={15} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell className={classes.td} style={{width: '20%'}}>
                                                <TextField
                                                    label="Action"
                                                    value={_.find([
                                                        {
                                                            label: "COA - Credit On Account",
                                                            value: "COA"
                                                        },
                                                        {
                                                            label: "CCR - Credit Card Refund",
                                                            value: "CCR"
                                                        },
                                                        {
                                                            label: "QFR - Quote For Repair",
                                                            value: "QFR"
                                                        },
                                                        {
                                                            label: "RPL - Replace",
                                                            value: "RPL"
                                                        },
                                                        {
                                                            label: "COA/RPL - Credit On Account & Replace",
                                                            value: "COA_RPL"
                                                        },
                                                        {
                                                            label: "CCR/RPL - Credit Card Refund & Replace",
                                                            value: "CCR_RPL"
                                                        },
                                                        {
                                                            label: "TBC - To Be Confirmed",
                                                            value: "TBC"
                                                        }
                                                    ], el => el.value === row.action)?.label}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        className: "textLabel",
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        className: "textDefault",
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment>
                                                                <FAIcon icon='check' size={15} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    disabled
                                                />
                                            </TableCell>
                                        </TableRow>  
                                    </TableBody>
                                </Table>
                            )}
                            {!_.isEmpty(row.rmaNotes) && (
                                 <Box mt={row.productId < 0 ? -2 : 0} pl={0.5}>
                                    <Typography component="div" variant="caption" className="textLabel">
                                        Notes
                                    </Typography>
                                    {row.rmaNotes}
                                </Box>
                            )}
                            {!_.isEmpty(row.sysNotes) && (
                                 <Box mt={row.productId < 0 ? -2 : 0} pl={0.5}>
                                    <Typography component="div" variant="caption" className="textLabel">
                                        Important Notes
                                    </Typography>
                                    {row.sysNotes}
                                </Box>
                            )}
                        </Box>
                        {manage && (
                            <Box pl={3} pr={3} pb={2}>
                                <Box pb={2}>
                                    <Divider />
                                </Box>
                                {(row.closed === 1 && row.status !== "Closed" && (
                                    <Box pb={2}>
                                        <Alert severity="error">
                                            This line will be finalised on save and will not able able to be modified
                                            {/* {row.aaCredit === 2 && (<Typography variant="body2" className="fw-400">A credit of £{row.total} will be automatically posted to Sage {!_.isEmpty(rma.rma_email) ? ` and e-mailed to ${rma.rma_email}` : ``}</Typography>)} */}
                                        </Alert>
                                    </Box>
                                )) || (row.status === "Closed" && (
                                    <Box pb={2}>
                                        <Alert severity="success">
                                            This line has been completed and can no longer be modified
                                        </Alert>
                                    </Box>
                                ))}
                                {/* // )) || (row.aaCredit === 1 && row.closed === 0 && (
                                //     <Box pb={2}>
                                //         <Alert severity="warning">
                                //             Please note that the credit note will not be generated until this line is marked as complete
                                //         </Alert>
                                //     </Box>
                                // ))} */}
                                <Grid container spacing={3}>
                                    {row.productId > 0 && (
                                        <>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Typography variant="body1" className="fw-400" gutterBottom>
                                                    Stage
                                                </Typography>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox name="received" checked={row.received === 1} color="primary" onChange={() => handleChange?.(key, "received", row?.received === 1 ? 0 : 1)} />}
                                                        label={<Typography variant="caption">Goods Received</Typography>}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                    />
                                                </Box>
                                                <Box maxWidth={200} pt={1} pb={1}>
                                                    <DatePicker
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                        name="receivedDatetime"
                                                        type="date"
                                                        label={`Date`}
                                                        onChange={date => handleChange?.(key, "receivedDatetime", moment(date))}
                                                        value={row.receivedDatetime}
                                                        autoOk={true}
                                                        inputVariant="outlined"
                                                    />
                                                </Box>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Box maxWidth={92} pt={1} pb={1}>
                                                            <TextField
                                                                label="Received"
                                                                value={row.recQuantity}
                                                                onChange={e => handleChange(key, "recQuantity", e?.target?.value < 0 ? '' : e?.target?.value)}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    className: parseInt(row.recQuantity) === parseFloat(row.quantity) ? 'textSuccess fw-500' : 'textError fw-500',
                                                                }}
                                                                type="number"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box maxWidth={92} pt={1} pb={1}>
                                                            <TextField
                                                                label="Pristine"
                                                                value={row.recPreQuantity}
                                                                onChange={e => handleChange(key, "recPreQuantity", e?.target?.value < 0 ? '' : e?.target?.value)}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                                variant="outlined"
                                                                type="number"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                {/* <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox name="withTech" checked={row.withTech === 1} color="primary" onChange={() => handleChange?.(key, "withTech", row?.withTech === 1 ? 0 : 1)} />}
                                                        label={<Typography variant="caption">With Tech</Typography>}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                    />
                                                </Box> */}
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox name="withSupplier" checked={row.withSupplier === 1} color="primary" onChange={() => handleChange?.(key, "withSupplier", row?.withSupplier === 1 ? 0 : 1)} />}
                                                        label={<Typography variant="caption">With Supplier</Typography>}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Typography variant="body1" className="fw-400" gutterBottom>
                                                    Actions Completed
                                                </Typography>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox name="rtnToSupplier" checked={row.rtnToSupplier === 1} color="primary" onChange={() => handleChange?.(key, "rtnToSupplier", row?.rtnToSupplier === 1 ? 0 : 1)} />}
                                                        label={<Typography variant="caption">Return To Supplier</Typography>}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                    />
                                                </Box>
                                                <Box maxWidth={200} pt={1} pb={1}>
                                                    <TextField
                                                        label="Ref"
                                                        value={row.rtnToSupplierRef}
                                                        onChange={e => handleChange(key, "rtnToSupplierRef", e?.target?.value ?? '')}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox name="restock" checked={row.restock === 1} color="primary" onChange={() => handleChange?.(key, "restock", row?.restock === 1 ? 0 : 1)} />}
                                                        label={<Typography variant="caption">Restock</Typography>}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                    />
                                                </Box>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox name="noFault" checked={row.noFault === 1} color="primary" onChange={() => handleChange?.(key, "noFault", row?.noFault === 1 ? 0 : 1)} />}
                                                        label={<Typography variant="caption">No Fault</Typography>}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                    />
                                                </Box>
                                                {(row.rtnToSupplier === 1 || row.restock === 1 || row.noFault === 1) && (
                                                    <Box maxWidth={200} pt={1} pb={1}>
                                                        <DatePicker
                                                            name="actionDate"
                                                            type="date"
                                                            label={`Date`}
                                                            onChange={date => handleChange?.(key, "actionDate", moment(date))}
                                                            value={row.actionDate}
                                                            autoOk={true}
                                                            disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                            inputVariant="outlined"
                                                        />
                                                    </Box>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="body1" className="fw-400" gutterBottom>
                                            Outcome
                                        </Typography>
                                        <Grid container spacing={3}>
                                            {row.productId > 0 && (
                                                <>
                                                    <Grid item xs={12} sm={6}>
                                                        <Box>
                                                            <FormControlLabel
                                                                control={<Checkbox name="suppCredit" checked={row.suppCredit === 1} color="primary" onChange={() => handleChange?.(key, "suppCredit", row?.suppCredit === 1 ? 0 : 1)} />}
                                                                label={<Typography variant="caption">Supplier Credit</Typography>}
                                                                disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed" || (row.productId > 0 && row.rtnToSupplier === 0 && row.restock === 0 && row.noFault === 0)}
                                                            />
                                                        </Box>
                                                        {row.suppCredit === 1 && (
                                                            <Box maxWidth={200} pt={1} pb={1}>
                                                                <TextField
                                                                    label="Credit Note Ref *"
                                                                    value={row.suppCreditRef}
                                                                    onChange={e => handleChange(key, "suppCreditRef", e?.target?.value ?? '')}
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed" || (row.productId > 0 && row.rtnToSupplier === 0 && row.restock === 0 && row.noFault === 0)}
                                                                    variant="outlined"
                                                                />
                                                            </Box>
                                                        )}
                                                        {row.suppCredit === 1 && (
                                                            <Box maxWidth={200} pt={1} pb={1}>
                                                                <DatePicker
                                                                    name="suppCreditDate"
                                                                    type="date"
                                                                    label={`Credit Note Date *`}
                                                                    onChange={date => handleChange?.(key, "suppCreditDate", moment(date))}
                                                                    value={row.suppCreditDate}
                                                                    autoOk={true}
                                                                    disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed" || (row.productId > 0 && row.rtnToSupplier === 0 && row.restock === 0 && row.noFault === 0)}
                                                                    inputVariant="outlined"
                                                                />
                                                            </Box>
                                                        )}
                                                        <Box>
                                                            <FormControlLabel
                                                                control={<Checkbox name="repair" checked={row.repair === 1} color="primary" onChange={() => handleChange?.(key, "repair", row?.repair === 1 ? 0 : 1)} />}
                                                                label={<Typography variant="caption">Repair</Typography>}
                                                                disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed" || (row.productId > 0 && row.rtnToSupplier === 0 && row.restock === 0 && row.noFault === 0)}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <FormControlLabel
                                                                control={<Checkbox name="replace" checked={row.replace === 1} color="primary" onChange={() => handleChange?.(key, "replace", row?.replace === 1 ? 0 : 1)} />}
                                                                label={<Typography variant="caption">Replace</Typography>}
                                                                disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed" || (row.productId > 0 && row.rtnToSupplier === 0 && row.restock === 0 && row.noFault === 0)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox name="aaCredit" checked={row.aaCredit === 1} color="primary" onChange={() => handleChange?.(key, "aaCredit", row?.aaCredit === 1 ? 0 : 1)} />}
                                                        label={<Typography variant="caption">AA Credit</Typography>}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed" || (row.productId > 0 && row.rtnToSupplier === 0 && row.restock === 0 && row.noFault === 0)}
                                                    />
                                                </Box>
                                                {row.aaCredit === 1 && (
                                                    <Box maxWidth={200} pt={1} pb={1}>
                                                        <TextField
                                                            label="Ref"
                                                            value={row.aaCreditRef}
                                                            onChange={e => handleChange(key, "aaCreditRef", e?.target?.value ?? '')}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed"}
                                                            variant="outlined"
                                                        />
                                                    </Box>
                                                )}
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox name="noAction" checked={row.noAction === 1} color="primary" onChange={() => handleChange?.(key, "noAction", row?.noAction === 1 ? 0 : 1)} />}
                                                        label={<Typography variant="caption">No Action</Typography>}
                                                        disabled={rma.rma_status === "Cancelled" || row.closed === 1 || row.status === "Closed" || (row.productId > 0 && row.rtnToSupplier === 0 && row.restock === 0 && row.noFault === 0)}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="body1" className="fw-400" gutterBottom>
                                            Finalise
                                        </Typography>
                                        <Box>
                                            <FormControlLabel
                                                control={<Checkbox name="closed" checked={row.closed === 1} color="primary" onChange={() => row?.closed === 1 ? handleChange?.(key, "closed", 0) : (dispatch(deployConfirmation(`Are you sure you want to mark this line as complete?`, () => handleChange?.(key, "closed", 1))))} />}
                                                // control={<Checkbox name="closed" checked={row.closed === 1} color="primary" onChange={() => row?.closed === 1 ? handleChange?.(key, "closed", 0) : (row.status !== "Closed" && row.aaCredit === 1 ? dispatch(deployConfirmation(<>{parseInt(row.quantity) !== parseInt(row.recQuantity) && <Typography variant="body2" className="pb-1 fw-400">Warning - The quantity returned ({row.recQuantity}) differs from the quantity expected ({row.quantity}). Please modify this RMA before completing this line if you need to adjust the quantity / value of the credit due to the customer.</Typography>}<Typography variant="body2">Are you sure you want to mark this line as complete?</Typography><Typography variant="body2" className="textError">Customer Credit Total: £{row.total}</Typography></>, () => handleChange?.(key, "closed", 1))) : dispatch(deployConfirmation(`Are you sure you want to mark this line as complete?`, () => handleChange?.(key, "closed", 1))))} />}
                                                label={<Typography variant="caption">Complete</Typography>}
                                                disabled={row.status === "Closed" || (row.suppCredit === 0 && row.repair === 0 && row.replace === 0 && row.aaCredit === 0 && row.noAction === 0) || (row.suppCredit === 1 && (_.isEmpty(row.suppCreditRef) || !row.suppCreditDate))}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Paper>
                </Box>
            ))}
        </>
    )
}

export default Products;