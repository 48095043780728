import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StaffMember from 'Components/Common/Staff/StaffMember';
import ViewStaff from 'Components/Staff/ViewStaff/ViewStaff';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = {
    access: {
        viewStaff: false
    },
    data: {},
    isLoading: true
}

const Actions = () => {

    const [state, setState] = useState(initialState);
    const { access, data, isLoading } = state;
    
    const dispatch = useDispatch();
    const status = useSelector(state => state.notifications.status);
    
    const viewStaff = staffId => dispatch(deployDialog(
        <ViewStaff 
            id={staffId} 
            defaultTab={7}
            noActionClick
        />, false, status?.[staffId]?.name ?? "Action Management" , "standard", "xl", true
    ))

    useEffect(() => {
        Promise.all([
            API.access('view-staff:manage-actions'),
            API.get('/misc/actions/staffOverview')
        ])
        .then(([viewStaff, res]) => {
            if(res?.data) {
                const data = res.data, isLoading = false;
                setState({
                    access: {
                        viewStaff: viewStaff?.data?.has_access ?? false
                    },
                    data,
                    isLoading
                })
            }
        })
    }, [])

    return (
        <Grid container spacing={3} alignItems='center'>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                _.map(data, (staff, idx) => {
                    let overdue = parseInt(staff.overdue) > 0;
                    return (status && status[staff.staff_id] && (
                        <Grid item key={idx} xs={12} md={6} xl={3}>
                            <Paper>
                                <StaffMember    
                                    staff={status[staff.staff_id]}
                                    disabled={!access.viewStaff}
                                    handleAction={() => viewStaff(staff.staff_id)}
                                    className='sm-list-item-borderless-white'
                                    content={(
                                        <>
                                            <Box p={1}>
                                                <Box p={3} className={overdue ? 'content-light-error' : 'content-light-success'}>
                                                    <Grid container>
                                                        <Grid item xs={6}><Typography variant="h5">{staff.outstanding}</Typography></Grid>
                                                        <Grid item xs={6}><Typography variant="h5" color={overdue ? 'error' : 'default'}>{staff.overdue}</Typography></Grid>
                                                        <Grid item xs={6}><Typography variant="caption">Assigned</Typography></Grid>
                                                        <Grid item xs={6}><Typography variant="caption" color={overdue ? 'error' : 'default'}>Overdue</Typography></Grid>
                                                        <Grid item xs={12} className='gap-3' />
                                                        <Grid item xs={6}><Typography variant="h5">{staff.today}</Typography></Grid>
                                                        <Grid item xs={6}><Typography variant="h5">{staff.total}</Typography></Grid>
                                                        <Grid item xs={6}><Typography variant="caption">Total Today</Typography></Grid>
                                                        <Grid item xs={6}><Typography variant="caption">Total Completed</Typography></Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                />
                            </Paper>
                        </Grid>
                    )) || (
                        <React.Fragment />
                    )
                })
            )}
        </Grid>
    )
}

export default Actions;