import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { CLOUDFRONT_URL } from 'Constants';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { currencyFormat } from 'Functions/MiscFunctions';

const UpdateOrderDeliveryPurchaseOrders = ({rebase, callback}) => {

    const dispatch = useDispatch();

    const [po, setPo] = useState([]);

    const [sending, setSending] = useState(null);

    const handleClose = () => {
        callback?.();
        dispatch(closeDialog());
    }

    const handleSendPo = (p, idx) => {
        let newPos = [...po];
        newPos[idx].submitted = 1;
        setSending(p);
        setPo(newPos)
    }

    useEffect(() => {
        setPo(rebase);
    }, [rebase])

    return (
        (sending && (
            <>
                 <EmailDialog
                    id={sending.id}
                    type='po'
                    action='resubmission'
                    defaultFormData={{
                        emailSubject: `REVISED - Purchase Order #${sending.ref}`,
                        emailTo: {
                            label: sending.email,
                            value: sending.email
                        }
                    }}
                    success='The purchase order was re-sent to the supplier successfully'
                    confirmation='Are you sure you want to re-send this purchase order?'
                    send='Send'
                    noCancel
                    noDialog
                    callback={() => setSending(null)}
                />
            </>
        )) || (
            <>
                <Box pb={3}>
                    <Typography variant="body2" className="fw-400">
                        <FAIcon icon="exclamation-circle" className="textWarning mr-1" size={15} />
                        The following purchase orders may need to be re-sent due to the delivery address being modified, this will not require re-confirmation. 
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    {_.map(po, (p, idx) => (
                        <Grid item xs={12} key={idx}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <Tooltip title='Re-send purchase order now'>
                                        <IconButton 
                                            disabled={p.submitted === 1}
                                            onClick={() => handleSendPo(p, idx)}
                                        >
                                            <FAIcon icon={p.submitted === 0 ? 'circle' : 'check-circle'} className={p.submitted === 0 ? 'textError' : 'textSuccess'} size={35} button noMargin />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" className="fw-400">
                                        Purchase Order #{p.ref} for {p.supp}
                                    </Typography>
                                    <Typography variant="body2">
                                        Net Total: {currencyFormat.format(p.net)} | Gross Total: {currencyFormat.format(p.gross)} | <Link href={`${CLOUDFRONT_URL}${p.pdf}`} className='textDefault' target='_blank'><FAIcon icon='file-pdf' size={13.5} button noMargin className="textError" /> View PDF</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Box className="buttonRow">
                    <Button
                        color="primary"
                        variant="contained" 
                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to continue?`, handleClose))}
                    >
                        <FAIcon icon="check" button size={15} />
                        Continue
                    </Button>
                </Box>
            </>
        )
    )
}

export default UpdateOrderDeliveryPurchaseOrders;