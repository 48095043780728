import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const CallOffOrders = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/callOffOrder',
                noAutoLoad: true,
                columns: [  
                    {
                        heading: 'Scheduled Date',
                        field: rowData => rowData.oca,
                        dataRef: 'oca',
                        fieldFormat: 'date',
                        sizeToContent: true
                    },
                    {
                        heading: 'Delivery Reference',
                        field: rowData => rowData.orf,
                        dataRef: 'orf',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Delivery Status',
                        field: rowData => rowData.ost,
                        dataRef: 'ost',
                        sizeToContent: true
                    },
                    {
                        heading: 'Delivery Locked',
                        field: rowData => rowData.olk,
                        dataRef: 'olk',
                        fieldFormat: 'boolean',
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.cr,
                        dataRef: 'cr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.cc,
                        dataRef: 'cc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Proactive Responsibility',
                        field: rowData => (staff && staff[rowData.pr] && <StaffChip staff={staff[rowData.pr]} />) || '-',
                        dataRef: 'pr',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'call-off-orders',
                noResultsText: 'No results were found using your search criteria',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Sales'],
                pdf: true,
                persistenceId: 'callOffOrders',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Call Off Orders'
            }}
        />
    )
}

export default CallOffOrders;