import { isTablet } from 'Functions/MiscFunctions';

/* RED Configuration */
export const API_URL = 'https://api.advanced-access.co.uk/v1';
export const RTAPI_URL = 'https://rtapi.advanced-access.co.uk';
export const CLIENT_VERSION = '1.80';
export const DISABLE_POLLING = false;
export const HEADER_COLOR = '#ef3340';
export const ENVIRONMENT = "PRODUCTION";
export const APP_NAME = 'RED by Advanced Access';
export const APP_SHORT = 'RED';
export const CLIENT_NAME = 'Advanced Access Ltd';
export const DEFAULT_VAT_PERCENT = "20.00";
export const DEFAULT_VAT_VALUE = 1;
export const MANUAL_DISCOUNT_THRESHOLD = 10;
export const VOIP_DEBUG = false;
export const VOIP_DEBUG_NUMBER = "07709080000";
export const VOIP_APP_ID = "oauth2-advancedaccessintegrator-0124044001677753756";
export const VOIP_REDIRECT_URL = "https://red.advanced-access.co.uk/dashboard";
export const VOIP_PBX_URL = "https://advancedaccess.wildixin.com";
export const VOIP_PRIORITY_QUEUE = "Overflow";
export const VOIP_ENABLE_RECORDING = true;

/* Advanced Access */
export const AA_ADDRESS = [
    "Advanced Access Ltd",
    "Unit 1b",
    "Spinney View",
    "Round Spinney Industrial Estate",
    "Northampton",
    "NN3 8RF"
]

export const AA_ADDRESS_OBJECT = {
    addressComplete: true,
    addressOne: "Advanced Access Ltd",
    addressTwo: "Unit 1b, Spinney View",
    addressThree: "Round Spinney Industrial Estate",
    town: "Northampton",
    county: "Northamptonshire",
    postcode: "NN3 8RF",
    country: 234,
    countryName: 'United Kingdom',
    showAddress: false,
    showAddressLookup: false,
    selectedAddress: {
        label: "Advanced Access Ltd",
        value: {
            line1: "Advanced Access Ltd",
            line2: "Unit 1b, Spinney View",
            line3: "Round Spinney Industrial Estate",
            town: "Northampton",
            county: "Northamptonshire",
            postcode: "NN3 8RF",
            country: "England",
        }
    }
}

export const DPD_CUT_OFF = "17:00";

/* Crushed Ice Address Lookup */
export const ADDRESS_LOOKUP_URL = 'https://address-lookup.crushedice.biz';
export const ADDRESS_LOOKUP_API_KEY = 'NGXSBA45WgKa5kVJXHQuxfSVv5nMCF2vrkHY7ZBbWzN32nEGxREaDANnaZdYgCAXK3hMhnaq52s6NrkRfstYwtmXSPNqCWeBNK7shHMvkVWgtxwNyS8cQphFHs888GVV';

/* Google Maps */
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBdMAuM3q0ZITknoZiSHelTzJBLrHBmFrM';

/* Google reCaptcha */
export const RECAPTCHA_SITE_KEY = '6LdOb68UAAAAAIgrC5vTu1mqvMCeW2QmkfMBS0EN';

/* AWS Cloud Front */
export const CLOUDFRONT_URL = 'https://files.advanced-access.co.uk/';
export const MAX_UPLOAD_LIMIT_SIZE = '1.5GB';

/* Giphy */
export const GIPHY_API_KEY = 'HmxaePDRvV6kqBEwn4jRmrc1YPA6eSOM';
export const GIPHY_RATING = 'R';

/* Shortlinks */
export const SHORTLINK_BASE_URL = 'https://aaccess.uk/';

/* Theme */
export const VIEW_PAGE_OFFSET = isTablet() ? 113 : 118;
export const VIEW_PAGE_APP_BAR_HEIGHT = 90;

/* Website */
export const WEBSITE_BASE_URL = 'https://www.advanced-access.co.uk/';