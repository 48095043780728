import React  from 'react';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { handleConfirmationClose, handleConfirmationOpen, handleConfirmationSuccess } from 'Functions/FormFunctions';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = { 
    confirmation: {
        submit: false
    },
    formData: {
        reason: ''
    },
    formId: uuidv4(), 
    isLoading: true,
    reasonList: []
}

class LostOpportunity extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
    }

    componentDidMount(){
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get('/quotes/cancellation/reasons')
        .then(res => {

            let reasonList = [];

            if(res.data){
                reasonList = _.map(res.data, el => {
                    return _.assign({
                        value: el.qr_id,
                        label: el.qr_reason,
                    });
                });
            }

            this.setState({
                reasonList,
                isLoading: false
            })

        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    } 

    submit = () => {
        API.put(`/quotes/${((this.props.match && this.props.match.params && this.props.match.params.id) || this.props.id) || this.props.id}/lost`, this.state.formData)
        .then((result) => {
            this.setState({
                ...initialState,
                formId: (initialState.formId + 1),
            }, () => {
                this.props.deploySnackBar("success", "You have successfully marked this quote as lost")
                this.props.toggleDialog()                    
            });
        });
    }
    
    render() {
        const { confirmation, formData, isLoading, reasonList } = this.state;
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={3}> 
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect 
                                                options={reasonList} 
                                                label='Why was this quote lost?'
                                                value={formData.reason}
                                                onChange={this.handleSelectChange('reason')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button 
                                            onClick={() => this.props.toggleDialog()} 
                                            variant="text"
                                        >
                                            <FAIcon icon="times" size={15} button />
                                            Cancel
                                        </Button>
                                        <Button 
                                            color="primary"
                                            onClick={() => this.handleConfirmationOpen('submit')} 
                                            variant="text" 
                                        >
                                            <FAIcon icon="check" size={15} button />
                                            Close Quote
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog 
                            open={confirmation.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title={`Close quote?`}
                            message={`Are you sure you want to close this quote?`}
                        />
                    </>
                )}
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(LostOpportunity);