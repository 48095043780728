import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import * as Settings from 'Helpers/SettingsHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleSelectChange,
    handleUpdateChange,
    handleUpdateSelectChange,
    handleSnackbarClose,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
    handleDialogClose
} from 'Functions/FormFunctions';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const initialState = {
        warehouseBinSizes: _.map(Settings.warehouseBinSizes, el => {
            return _.assign({
                value: el,
                label: el,
            });
        }),
        formData: {
            binId: '',
            binLocation: '',
            binSize: '',
        },
        updateData: {
            binId: '',
            binLocation: '',
            binSize: '',
        },
        formErrors:[],
        updateErrors: [],
        dialog: {
            update: false
        },
        rows: [],
        confirmation: {
            submit: false,
            update: false,
        },
        snackbar: {
            submit: false,
            update: false,
            delete: false,
        },
        isLoading: true,
        updateLoading: true
    }

class WarehouseBins extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleUpdateChange = handleUpdateChange.bind(this);
        this.handleUpdateSelectChange = handleUpdateSelectChange.bind(this);
        this.handleSnackbarClose = handleSnackbarClose.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleDialogClose = handleDialogClose.bind(this);
    }

    componentDidMount = () => {
        this.getWarehouseBins();
    }

    submit = () => {
        API.post('/warehouse/warehouseBins', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbar: {
                        update: false,
                        submit: true
                    }
                });
                this.getWarehouseBins();
            }
            this.props.scrollToTop();
        });
    }

    update = () => {
        API.put('/warehouse/warehouseBins/'+this.state.updateData.binId, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbar: {
                        update: true,
                        submit: false
                    }
                });
                this.getWarehouseBins();
            }
            this.props.scrollToTop();
        });
    }

    getWarehouseBins = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/warehouse/warehouseBins')
            .then((result) => {
                this.setState({
                    ...this.state,
                    rows: result.data,
                    isLoading: false
                });
            })
        })
    }
    
    getUpdateData = () => {
        API.get('/warehouse/warehouseBins/'+this.state.updateData.binId)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose('update');
            } else {
                this.setState({
                    updateLoading: false,
                    updateErrors: [],
                    updateData: {
                        ...this.state.updateData,
                        binLocation: result.data.wh_bin_location,
                        binSize: result.data.wh_bin_size,
                    }
                });
            }
        })
    }
    
    handleDialogOpen = (id) => {
        this.setState({
            dialog: {
                update: true
            },
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                binId: id
            }
        }, () => this.getUpdateData());
    }

    handleDeleteBin = id => {
        API.put(`/warehouse/warehouseBins/${id}/delete`)
        .then((result) => {
            if(result?.data?.success) {
                this.setState({
                    snackbar: {
                        delete: true
                    }
                }, () => this.getWarehouseBins());
            }
        })
    }

    render() {
        const { warehouseBinSizes, formData, formErrors, isLoading, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Warehouse Bins
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'wh_bin_id',
                                pagination: true,
                                plainPagination: true,
                                isLoading: isLoading,
                                options: {
                                    minimalPadding: true
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Bin Location',
                                    field: rowData => rowData.wh_bin_location,
                                    main: true
                                },        
                                {
                                    heading: 'Bin Size',
                                    field: rowData => rowData.wh_bin_size,
                                },       
                                {
                                    heading: 'Products',
                                    field: rowData => rowData.products_count,
                                    alignment: 'right'
                                },       
                                {
                                    heading: 'Stock Count',
                                    field: rowData => rowData.stock_items_count,
                                    alignment: 'right'
                                },    
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil', onClick: () => this.handleDialogOpen(rowData.wh_bin_id)},
                                            {name: 'Delete', icon: 'trash-alt', onClick: () => this.props.deployConfirmation(`Are you sure you want to delete bin location ${rowData.wh_bin_location}?`, () => this.handleDeleteBin(rowData.wh_bin_id))},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.rows}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Bin Location
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                name="binLocation"
                                label="Bin Location *"
                                value={formData.binLocation}
                                error={formErrors && formErrors['binLocation'] && true}
                                helperText={formErrors && formErrors['binLocation']}
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                            <AutoCompleteSelect                                
                                options={warehouseBinSizes}
                                label='Bin Size *'
                                onChange={o => this.handleSelectChange('binSize', o)}
                                error={formErrors && formErrors['binSize'] && true}
                                errorText={formErrors && formErrors['binSize']}
                                value={formData.binSize}
                            />
                            <div className='buttonRow'>
                                <Button 
                                    type='submit'
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="text" 
                                    color="primary" 
                                    disabled={!this.state.formData.binLocation}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Add
                                </Button>
                            </div>
                        </form>
                        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={this.state.dialog.update} onClose={() => this.handleDialogClose('update')} fullWidth={true} maxWidth="sm">
                            <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Warehouse Bin</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            name="binLocation"
                                            label="Bin Location *"
                                            value={updateData.binLocation}
                                            error={updateErrors && updateErrors['binLocation'] && true}
                                            helperText={updateErrors && updateErrors['binLocation']}
                                            onChange={this.handleUpdateChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        <AutoCompleteSelect                                
                                            options={warehouseBinSizes}
                                            label='Bin Size *'
                                            onChange={o => this.handleUpdateSelectChange('binSize', o)}
                                            error={updateErrors && updateErrors['binSize'] && true}
                                            errorText={updateErrors && updateErrors['binSize']}
                                            value={updateData.binSize}
                                        />
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleDialogClose('update')} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text"><FAIcon icon="check" size={15} button />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            open={this.state.confirmation.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            title="Update bin location?" 
                            message="Are you sure you want to update this warehouse bin location?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmation.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title="Add a new warehouse bin location?" 
                            message="Are you sure you want to add a new warehouse bin location?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the specified warehouse bin'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new warehouse bin'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbar.delete}
                            onClose={() => this.handleSnackbarClose('delete')}
                            message='You have successfully deleted the warehouse bin'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
})

export default connect(null, mapDispatchToProps)(WarehouseBins);