import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import InsightChip from 'Components/Common/Chips/InsightChip';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import StackTable from 'Components/Common/DataTables/StackTable';
import StatusChip from 'Components/Common/Chips/StatusChip';
import Totals from 'Components/RMA/RMAForm/Totals';
import ViewProduct from 'Components/Products/ViewProduct/ViewProduct';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { currencyFormat, momentFormatDate } from 'Functions/MiscFunctions';

const useStyles = makeStyles({
    tab: {
        color: '#fff',
        opacity: 1
    },
    td: {
        border: 'none',
        padding: 4
    }
})

const RMASummary = ({rma}) => { 

    const dispatch = useDispatch();

    const handleProductInfoOpen = productId => {
        dispatch(deployDialog(
            <ViewProduct id={productId} inDialog />, 
            false, 
            "Product Details", 
            "standard", 
            "xl",
            true
        ))
    }

    let customerAddress = [
            rma.rma_address_line_one,
            rma.rma_address_line_two,
            rma.rma_address_line_three,
            rma.rma_address_town,
            rma.rma_address_county,
            rma.rma_address_postcode,
            rma.rma_address_country
        ],
        customerAddressEmpty = true, 
        classes = useStyles();

    _.each(customerAddress, line => {
        if(line && line.length > 0) {
            customerAddressEmpty = false;
            return false;
        }
    })

    return (
        <PaddedPaper>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Heading text="RMA Summary" />
                </Grid>
                <Grid item>
                    <InsightChip 
                        icon="check"
                        label={rma.rma_status}
                        paddingLeft={8}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} justify="flex-end">
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                noBg
                                elevation={0}
                                padding={0}
                                rows={[ 
                                    {
                                        heading: "Company Name",
                                        value: rma?.customer?.cust_company_name ?? '-'
                                    }, 
                                    {
                                        heading: "Billing Address",
                                        disabled: customerAddressEmpty,
                                        value: _.map(customerAddress, (line, idx) => {
                                            if(line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component='div'>
                                                        {line}
                                                    </Typography>
                                                )
                                            }
                                        })
                                    }
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                noBg
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: "Customer Contact",
                                        disabled: !rma.rma_contact_name,
                                        value: (
                                            <>
                                                <Typography variant="body2" gutterBottom>
                                                    {rma.rma_contact_name ?? 'Not Set'}
                                                </Typography>
                                                {!_.isEmpty(rma.customer_contact?.contact_telephone) && (
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        <FAIcon icon="phone" size={12} />
                                                        {rma.customer_contact?.contact_telephone}
                                                    </Typography>
                                                )}
                                                {!_.isEmpty(rma.customer_contact?.contact_mobile) && (
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        <FAIcon icon="mobile" size={12} />
                                                        {rma.customer_contact?.contact_mobile}
                                                    </Typography>
                                                )}
                                                {!_.isEmpty(rma.customer_contact?.contact_email) && (
                                                    <Typography variant="caption" component="div">
                                                        <FAIcon icon="envelope" size={12} />
                                                        {rma.customer_contact?.contact_email}
                                                    </Typography>
                                                )}
                                            </>
                                        )
                                    }
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                noBg
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: "RMA Contact E-mail Address",
                                        value: rma?.rma_email ?? '-'
                                    },
                                    {
                                        heading: "RMA Contact Phone",
                                        value: rma?.rma_phone ?? '-'
                                    }
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justify="flex-end">
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            {/* <Products   
                                                classes={classes}
                                                inline={true}
                                                rows={rma.products}
                                                readOnly
                                            /> */}
                                            <DataTable 
                                                config={{
                                                    key: 'rp_id',
                                                    alternatingRowColours: true,
                                                    inline: true,
                                                    noRowHover: true
                                                }}
                                                columns={[
                                                    {
                                                        heading: '',
                                                        field: rowData => (
                                                            <Box pt={1} pb={1} whiteSpace="normal" style={{wordBreak: 'break-all'}}>
                                                                <Typography variant="body2" className="fw-400">
                                                                    {rowData.productCode}
                                                                </Typography>
                                                                <Typography variant="caption" component="div">
                                                                    {rowData.productName}
                                                                </Typography>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="caption" component="em">
                                                                        Supplied by {rowData.supplier}
                                                                    </Typography>
                                                                </Grid>
                                                                {!_.isEmpty(rowData.rmaNotes) && (
                                                                    <Box pt={0.5}>
                                                                        <Grid container alignItems='center'>
                                                                            <Grid item>
                                                                                <Box mb={0.6}>
                                                                                    <FAIcon icon="circle" className="mr-1" size={5} type="solid" />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <Typography variant="caption">
                                                                                    {rowData.rmaNotes}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                )}
                                                                {!_.isEmpty(rowData.sysNotes) && (
                                                                    <Box>
                                                                        <Grid container alignItems='center'>
                                                                            <Grid item>
                                                                                <Box mb={0.6}>
                                                                                    <FAIcon icon="circle" className="textError mr-1" size={5} type="solid" />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <Typography variant="caption" className="textError fw-400">
                                                                                    {rowData.sysNotes}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                )}
                                                                <Box>
                                                                    <Grid container alignItems='center'>
                                                                        <Grid item>
                                                                            <Box mb={0.6}>
                                                                                <FAIcon icon="circle" className={parseInt(rowData.recQuantity) !== parseInt(rowData.quantity) ? 'textError mr-1' : 'textSuccess mr-1'} size={5} type="solid" />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <Typography variant="caption" className={parseInt(rowData.recQuantity) !== parseInt(rowData.quantity) ? 'textError fw-400' : 'textSuccess fw-400'}>
                                                                                {rowData.recQuantity} / {rowData.quantity} returned
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    },
                                                    {
                                                        heading: 'Qty',
                                                        field: rowData => rowData.quantity,
                                                        sizeToContent: true,
                                                        alignment: 'right'
                                                    },   
                                                    {
                                                        heading: 'Paid',
                                                        field: rowData => rowData.price,
                                                        sizeToContent: true,
                                                        fieldFormat: 'currency',
                                                        alignment: 'right'
                                                    },    
                                                    {
                                                        heading: 'Re-stock Fee',
                                                        field: rowData => `${currencyFormat.format(rowData.restockFee)} (${rowData.restockPercent}%)`,
                                                        sizeToContent: true,
                                                        alignment: 'right'
                                                    },   
                                                    {
                                                        heading: 'Credit',
                                                        field: rowData => rowData.subTotal,
                                                        sizeToContent: true,
                                                        fieldFormat: 'currency',
                                                        alignment: 'right',
                                                        cellProps: rowData => ({
                                                            className: (parseFloat(rowData.subTotal) + parseFloat(rowData.restockFee)) !== parseFloat(rowData.price) ? 'textError' : undefined
                                                        })
                                                    },    
                                                    {
                                                        heading: 'Total Credit',
                                                        field: rowData => rowData.total,
                                                        sizeToContent: true,
                                                        fieldFormat: 'currency',
                                                        alignment: 'right',
                                                        cellProps: rowData => ({
                                                            className: (parseFloat(rowData.subTotal) + parseFloat(rowData.restockFee)) !== parseFloat(rowData.price) ? 'textError' : undefined
                                                        })
                                                    },   
                                                    {
                                                        heading: 'Reason / Action',
                                                        field: rowData => (
                                                            <>
                                                                <Typography variant="body2" component="div" className="fw-400" gutterBottom >
                                                                    {_.find([
                                                                        {
                                                                            label: "Damaged",
                                                                            value: "DAMAGED"
                                                                        },
                                                                        {
                                                                            label: "Faulty Goods",
                                                                            value: "FAULTY_GOODS"
                                                                        },
                                                                        {
                                                                            label: "No Longer Required",
                                                                            value: "NO_LONGER_REQUIRED"
                                                                        },
                                                                        {
                                                                            label: "Wrong Goods Ordered",
                                                                            value: "WRONG_GOODS_ORDERED"
                                                                        },
                                                                        {
                                                                            label: "Wrong Goods Supplied (AA)",
                                                                            value: "WRONG_GOODS_SUPPLIED_AA"
                                                                        },
                                                                        {
                                                                            label: "Wrong Goods Supplied (Supplier)",
                                                                            value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                                                                        },
                                                                        {
                                                                            label: "Refund Return Carriage",
                                                                            value: "CARRIAGE_REFUND"
                                                                        },
                                                                        {
                                                                            label: "Refund Order Carriage (Courier)",
                                                                            value: "CARRIAGE_ISSUE"
                                                                        }
                                                                    ], el => el.value === rowData.reason)?.label}
                                                                </Typography> 
                                                                <Typography variant="body2" component="div" className="fw-300">
                                                                    <FAIcon icon="arrow-right" size={12} />
                                                                    {_.find([
                                                                        {
                                                                            label: "COA - Credit On Account",
                                                                            value: "COA"
                                                                        },
                                                                        {
                                                                            label: "CCR - Credit Card Refund",
                                                                            value: "CCR"
                                                                        },
                                                                        {
                                                                            label: "QFR - Quote For Repair",
                                                                            value: "QFR"
                                                                        },
                                                                        {
                                                                            label: "RPL - Replace",
                                                                            value: "RPL"
                                                                        },
                                                                        {
                                                                            label: "COA/RPL - Credit On Account & Replace",
                                                                            value: "COA_RPL"
                                                                        },
                                                                        {
                                                                            label: "CCR/RPL - Credit Card Refund & Replace",
                                                                            value: "CCR_RPL"
                                                                        },
                                                                        {
                                                                            label: "TBC - To Be Confirmed",
                                                                            value: "TBC"
                                                                        }
                                                                ], el => el.value === rowData.action)?.label}
                                                                </Typography>
                                                            </>
                                                        ),
                                                        sizeToContent: true
                                                    },   
                                                    {
                                                        heading: 'Sales Order',
                                                        field: rowData => !_.isEmpty(rowData.orderRef) ? (
                                                            (rowData.orderId && (
                                                                <Tooltip title="View Sales Order">
                                                                    <Link to={rowData.odId ? `/sales/${rowData.orderId}:${rowData.odId}` : `/sales/${rowData.orderId}`} target="_blank">
                                                                        <InsightChip
                                                                            icon='external-link'
                                                                            className="link"
                                                                            label={`#${rowData.orderRef}`}
                                                                            paddingRight={4}
                                                                            paddingLeft={8}
                                                                        />
                                                                    </Link>
                                                                </Tooltip>
                                                            )) || (
                                                                rowData.orderRef
                                                            )
                                                        ) : '-',
                                                        sizeToContent: true
                                                    },    
                                                    {
                                                        heading: 'Delivery Date',
                                                        field: rowData => !_.isEmpty(rowData.deliveryDate) ? momentFormatDate(rowData.deliveryDate) : '-',
                                                        sizeToContent: true
                                                    }, 
                                                    {
                                                        heading: 'Status',
                                                        field: rowData => <StatusChip status={rma.rma_status === 'Awaiting Approval' ? 'Awaiting Approval' : rowData.rpStatus} />,
                                                        sizeToContent: true
                                                    },   
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'View Product', disabled: rowData.productId <= 0, icon: 'binoculars', onClick: () => handleProductInfoOpen(rowData.productId)},
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={rma.products}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!_.isEmpty(rma.rma_notes) && (
                                    <Grid item xs={12}>
                                        <Box pt={2} pl={2} pr={2}>
                                            {rma.rma_notes.split('\n').map((item, key) => (
                                                <Typography variant="body2" key={key} align="center" paragraph>{item}</Typography>
                                            ))}
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item xs={12} lg={4}>
                                    <Totals
                                        classes={classes}
                                        netTotal={rma.rma_total_net}
                                        vatTotal={rma.rma_total_vat}
                                        grossTotal={rma.rma_total_gross}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PaddedPaper>
    );
}

export default RMASummary;