import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InsightChip from 'Components/Common/Chips/InsightChip';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import ViewEmail from 'Components/Communication/Misc/ViewEmail';
import { deployDialog, closeDialog } from 'Redux/Actions/Dialog/Dialog';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import API from 'API';

const ActivityLog = ({activity, apiRoute, fullPage}) => {
    const   [data, setData]         =   useState([]),
            [display, setDisplay]   =   useState(fullPage ? _.size(activity ?? []) : 4),
            [loading, setLoading]   =   useState(apiRoute ? true : false),
            ui                      =   useSelector(state => state.ui),
            staff                   =   useSelector(state => state.notifications.status),
            history                 =   useHistory(),
            dispatch                =   useDispatch(),
            handleOnClick           =   log => {

                let path = null;

                if(log?.re > 0) {

                    dispatch(deployDialog(
                        <ViewEmail 
                            id={log.re} 
                            closeDialog={() => dispatch(closeDialog())}
                            resend={(id, defaultFormData, resendStaffId) => dispatch(deployDialog(
                                <EmailDialog
                                    id={id ?? false}
                                    defaultFormData={defaultFormData}
                                    resendStaffId={resendStaffId}
                                    type='default'
                                />, false, "Compose E-mail", "standard", "md"))
                            }
                        />
                    , false, "", "standard","sm", false , true));

                } else if(log?.rq > 0) {

                    path = `/quotes/${log.rq}`;
                    
                } else if(log?.ro > 0) {

                    path = `/sales/${log.ro}`;

                } else if(log?.rp > 0) {

                    path = `/purchase-orders/${log.rp}`;

                }

                if(path) {
                    history.push(path);
                }
                
            };
    
    const handleLoadData = () => {
        setLoading(true);
        API.get(apiRoute)
        .then(res => {
            if(res?.data) {
                setData(res.data);
                if(fullPage) {
                    setDisplay(_.size(res.data))
                }
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        if(apiRoute) {
            handleLoadData();
        }
    }, [apiRoute]); /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
        setData(activity ?? [])
        if(fullPage) {
            setDisplay(_.size(activity ?? []))
        }
    }, [activity, fullPage]);

    return (
        <>
            {(loading && (
                <LoadingCircle />
            )) || (
                <>
                    {(_.isEmpty(data) && (
                        <Typography variant="h5" className="fw-400" align='center'>There is no activity to show yet</Typography>
                    )) || (
                        <Box pb={fullPage ? undefined : 1.5}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <VerticalTimeline
                                        className={_.size(data) > display ? (fullPage ? '' : 'vetical-timeline-show-more--one-column') : (fullPage && !ui?.device?.isTablet ? 'vetical-timeline-completed--two-columns' : 'vetical-timeline-completed--one-column')}
                                        layout={'1-column-right'}
                                    >
                                        {_.map(_.filter(data, (el, idx) => idx < display), (log, idx) => (
                                            <VerticalTimelineElement
                                                key={idx}
                                                className="vertical-timeline-element--work"
                                                contentStyle={{
                                                    boxShadow: fullPage ? undefined : 'none', 
                                                    padding: 0
                                                }}
                                                iconStyle={{
                                                    backgroundColor: `#fafafa`,
                                                    boxShadow: 'none',
                                                    border: `0.5px solid #111`
                                                }}
                                                icon={
                                                    <FAIcon 
                                                        icon={log.i} 
                                                        style={{color: `${log.c}`, paddingLeft: 2, paddingTop: 2}}
                                                        size={15}
                                                        noMargin
                                                    />
                                                }
                                            >
                                                <div 
                                                    onClick={() => ((fullPage && (log?.rq > 0 || log?.ro > 0 || log?.rp > 0 || log?.re > 0)) || (!fullPage && (log?.re > 0))) && handleOnClick(log)}
                                                    style={{
                                                        cursor: ((fullPage && (log?.rq > 0 || log?.ro > 0 || log?.rp > 0 || log?.re > 0)) || (!fullPage && (log?.re > 0))) ? 'pointer' : undefined,
                                                    }}
                                                >
                                                    <Box pb={0.75} borderBottom={'1px solid #ddd'}>
                                                        <Grid container alignItems='center' justify='space-between'>
                                                            <Grid item>
                                                                <Typography 
                                                                    style={{
                                                                        color: `#${log.c}`, 
                                                                    }}
                                                                    variant="body2"
                                                                    className="fw-400"
                                                                >
                                                                    {log.t}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container spacing={1}>
                                                                    <Grid item>
                                                                        <InsightChip 
                                                                            icon="calendar-alt"
                                                                            type="thin"
                                                                            label={moment(log.d).format("DD/MM/YYYY HH:mm")}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <InsightChip 
                                                                            icon="user"
                                                                            type="thin"
                                                                            label={staff[log.s]?.name ?? 'System'}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box pt={1.25 }>
                                                        {(log.v?.length > 0 && (
                                                            log.v && log.v.split('\n').map((item, key) => (
                                                                <Typography 
                                                                    key={key} 
                                                                    component="div"
                                                                    variant="caption" 
                                                                    style={{
                                                                        fontWeight: 300, 
                                                                        margin: 0,
                                                                        marginBottom: key !== (_.size(log.v.split('\n')) - 1) ? 12 : 0
                                                                    }}
                                                                >
                                                                    {item}
                                                                </Typography>
                                                            ))
                                                        )) || (
                                                            <Typography 
                                                                component="div"
                                                                variant="caption"  
                                                                style={{
                                                                    fontWeight: 300, 
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                No details available
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </div>
                                            </VerticalTimelineElement>
                                        ))}
                                    </VerticalTimeline>
                                </Grid>
                                {_.size(data) > display && (
                                    <Grid item xs={12} align="right">
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => setDisplay((display + 5))}
                                        >
                                            Show More
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    )}
                </>
            )}
        </>
    )
}

export default ActivityLog;