import React  from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { handleSelectChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = () => ({ 
    formData: {
        cancel: {},
        type: 'order'
    },
    formErrors: [],
    isLoading: true,
    reasonList: []
})

class CancelPurchaseOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleSelectChange = handleSelectChange.bind(this);
        this.poll = false;
    }

    componentDidMount = () => {
        
        this.handlePoll(true);
        this.poll = setInterval(this.handlePoll, 3000);

        const {
            po
        } = this.props;

        API.get('/purchaseOrders/cancellation/reasons')
        .then(res => {

            let cancel          = {},
                reasonList      = [],
                isLoading       = false,
                { formData }    = this.state;

            if(res.data){
                reasonList = _.map(_.filter(res.data, _el => _el.or_reason !== "Sales Order Cancelled"), el => {
                    return _.assign({
                        value: el.or_id,
                        label: el.or_reason,
                    });
                });
            }

            if(!_.isEmpty(po.deliveries)) {
                _.forEach(po.deliveries, d => {
                    cancel = {
                        ...cancel,
                        [d.pod_idx]: 0
                    }
                })
            }

            formData = {
                ...formData,
                cancel,
                type: _.isEmpty(po.deliveries) ? 'order' : 'deliveries'
            }

            this.setState({
                formData,
                reasonList,
                isLoading
            })

        });

    }

    handlePoll = initial => {
        API.post(`/staff/my/view`, 
            { 
                po: this.props.po?.po_id,
                type: 'Process'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {
                    const   update  = _.some(res.data, el => el.u === 1),
                            process = _.some(res.data, el => el.p === 1);

                    if(!_.isEmpty(res.data) && (update || process)) {
                        this.props.deploySnackBar("error", `Sorry, another user is currently ${update ? 'modifying' : 'processing'} this purchase order`);
                        this.props.closeDialog();
                    }
                }
            }
        })
    }

    componentWillUnmount = () => {
        if(this.poll)
            clearInterval(this.poll);
    }

    handleCancelDelivery = podIdx => {

        const {
            deployConfirmation,
            po
        } = this.props;
        
        let cancel = {...this.state.formData?.cancel};

        cancel[podIdx] = cancel?.[podIdx] === 1 ? 0 : 1;

        let pod = _.find(po.deliveries, el => el.pod_idx === podIdx);

        if(cancel[podIdx] === 1) {

            if(!pod) {
                return;
            }
            
            deployConfirmation(
                <Box p={1}>
                    <Typography variant="body1" className="fw-400">
                        Purchase Order Delivery #{pod?.pod_reference ?? ''}/{parseInt(pod?.pod_idx ?? 0) < 10 ? '0' : ''}{pod?.pod_idx ?? ''}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        Fulfilled by {po?.supplier?.supp_company_name} 
                    </Typography>
                    {!_.isEmpty(po?.supplier?.supp_phone) && (
                        <Typography component="div" variant="caption">
                            <FAIcon icon='phone' size={13} />
                            <Link href={`tel:${po.supplier?.supp_phone}`}>
                                {po.supplier?.supp_phone}
                            </Link>
                        </Typography>
                    )}
                    <Typography variant="caption" component="div" paragraph>
                        {(!_.isEmpty(po.supplier?.supp_email) && (
                            <>
                                <FAIcon icon='envelope' size={13} />
                                <Link href={`mailto:${po.supplier?.supp_email}`}>
                                    {po.supplier?.supp_email}
                                </Link>
                            </>
                        )) || (
                            ' '
                        )}
                    </Typography>
                    <Typography variant="body2" className="fw-400">
                        Has this purchase order delivery been cancelled with the supplier?
                    </Typography>
                </Box>
                , () => {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            cancel
                        }
                    })
                }, () => {
                    deployConfirmation(
                        <Box p={1}>
                            <Typography variant="body1" className="fw-400">
                                Purchase Order Delivery #{pod?.pod_reference ?? ''}/{parseInt(pod?.pod_idx ?? 0) < 10 ? '0' : ''}{pod?.pod_idx ?? ''}
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Fulfilled by {po?.supplier?.supp_company_name} 
                            </Typography>
                            {!_.isEmpty(po?.supplier?.supp_phone) && (
                                <Typography component="div" variant="caption">
                                    <FAIcon icon='phone' size={13} />
                                    <Link href={`tel:${po?.supplier?.supp_phone}`}>
                                        {po?.supplier?.supp_phone}
                                    </Link>
                                </Typography>
                            )}
                            <Typography variant="caption" component="div" paragraph>
                                {(!_.isEmpty(po?.supplier?.supp_email) && (
                                    <>
                                                    <FAIcon icon='envelope' size={13} />
                                        <Link href={`mailto:${po?.supplier?.supp_email}`}>
                                            {po?.supplier?.supp_email}
                                        </Link>
                                    </>
                                )) || (
                                    ' '
                                )}
                            </Typography>
                            <Typography variant="body2" className="fw-400">
                                Please cancel this purchase order delivery to proceed
                            </Typography>
                        </Box>
                        ,
                        false,
                        false,
                        'Acknowledged',
                        null,
                        true
                    )
                }
            )

        } else {

            this.setState({
                formData: {
                    ...this.state.formData,
                    cancel
                }
            })

        }

    }

    handleSubmit = () => {

        const {
            callback,
            closeDialog,
            deploySnackBar,
            id
        } = this.props;

        const {
            formData
        } = this.state;

        this.setState({
            isLoading: true
        }, () => {

            API.put(`/purchaseOrders/${id}/cancellation`, formData)
            .then(res => {
                if(res?.data) {

                    if(res.data?.errors) {

                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                            isLoading: false
                        }, () => {
                            if(this.state.formErrors?.generic === "This purchase order has now been submitted, please check and try again") {
                                callback?.();
                                closeDialog();
                            }
                        })

                    } else {

                        deploySnackBar("success", `You have successfully cancelled ${formData.type === 'order' ? 'this purchase order' : 'selected purchase order deliveries'}`);
                        callback?.();                 
                        closeDialog();

                    }

                }
            })

        })
    }
    
    render() {

        const {
            closeDialog,
            deployConfirmation,
            po
        } = this.props;
        
        const { 
            formData, 
            formErrors,
            isLoading, 
            reasonList
        } = this.state;

        return (
            <Box className="content-light-grey" p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Typography variant="h6">
                                    Purchase Order #{po.po_reference}
                                </Typography>
                                <Typography variant='body2' className="fw-400">
                                    Order Cancellation
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Abort Cancellation">
                                    <IconButton
                                        onClick={closeDialog}
                                    >
                                        <FAIcon icon="times" size={20} button noMargin />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <Grid item xs={12}>
                            <Box className="content-light-white" p={3}>
                                {formErrors?.generic && (
                                    <Typography variant="body2" className="fw-400 textError" paragraph>
                                        {formErrors?.generic} 
                                    </Typography>
                                )}
                                <Typography variant="body2" className="fw-400" paragraph>
                                    Deliveries 
                                </Typography>
                                {(formData.type === 'order' && (
                                    <Alert severity="error">
                                        This will cancel the entire purchase order as it has not yet been submitted
                                    </Alert>
                                )) || (
                                    <DataTable
                                        config={{
                                            key: 'pod_id',
                                            alternatingRowColours: true,
                                            isLoading: isLoading,
                                            responsiveImportance: true,
                                            inline: true,
                                            rowProps: rowData => ({
                                                style: {
                                                    textDecoration: rowData.od_status === "Cancelled" ? 'line-through' : undefined
                                                }
                                            })
                                        }}
                                        columns={[
                                            {
                                                heading: 'Cancel?',
                                                field: rowData => <Checkbox disabled={rowData.pod_status === "Cancelled" || (rowData.pod_status === 'Despatched' && rowData.pod_ship_aa_only === 1 && rowData.pod_delivered === 1) || rowData.pod_status === "Invoiced"} color="primary" checked={formData?.cancel?.[rowData.pod_idx]} onChange={() => this.handleCancelDelivery(rowData.pod_idx)}/>,
                                                alignment: 'center',
                                                sizeToContent: true
                                            }, 
                                            {
                                                heading: 'Delivery',
                                                field: rowData => (
                                                    <Box pt={1} pb={1}>
                                                        <Typography variant="body2" className="fw-400">
                                                            {`Delivery ${rowData.rowNumber} of ${_.size(po.deliveries)}`}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            Reference: #{`${rowData?.pod_reference ?? ''}/${parseInt(rowData.pod_idx ?? 0) < 10 ? '0' : ''}${rowData.pod_idx ?? ''}`}
                                                        </Typography>
                                                    </Box>
                                                ),
                                            }, 
                                            {
                                                heading: 'Affected Sales Orders',
                                                field: rowData => (
                                                    <>
                                                        {_.size(rowData.orders)}
                                                        <FAIcon icon="exclamation-circle" size={15} className="ml-1" />
                                                    </>
                                                ),
                                                tooltip: rowData => _.isEmpty(rowData.orders) ? 'No sales orders are affected by this cancellation' : `The following sales orders are affected by this cancellation - ${_.map(rowData.orders, order => ` #${order.order_reference}`)}`,
                                                cellProps: rowData => ({
                                                    className: _.isEmpty(rowData.orders) ? undefined : `textError`
                                                }),
                                                alignment: 'center'
                                            },  
                                            {
                                                heading: 'Check Date',
                                                field: rowData => rowData.pod_check_date,
                                                fieldFormat: 'date',
                                            },  
                                            {
                                                heading: 'Expected Despatch',
                                                field: rowData => rowData.pod_expected_despatch_date,
                                                fieldFormat: 'date',
                                            },    
                                            {
                                                heading: 'Status',
                                                field: rowData => (
                                                    (rowData.pod_status === 'Despatched' && rowData.pod_delivered === 1 && (
                                                        <StatusChip status='Delivered' />
                                                    )) || (
                                                        <StatusChip status={rowData.pod_status} />
                                                    )
                                                ),
                                            }  
                                        ]}
                                        rows={po.deliveries}
                                    />
                                )}
                                {_.some(po.deliveries, el => !_.isEmpty(el.orders) && formData.cancel?.[el.pod_idx] === 1) && (
                                    <Box pt={3}>
                                        <Alert severity="warning">
                                            RED will automatically de-allocate pre-ordered stock for affected sales orders
                                        </Alert>
                                    </Box>
                                )}
                                {po.po_reason_id === 0 && (
                                    <Box pt={3} maxWidth={400}>
                                        <Typography variant="body2" className="fw-400">
                                            Cancellation Reason
                                        </Typography>
                                        <Box mt={-1}>
                                            <AutoCompleteSelect 
                                                options={reasonList} 
                                                value={formData.reason}
                                                onChange={v => this.handleSelectChange('reason', v)}
                                            />
                                        </Box>
                                    </Box>
                                )}
                                <div className="buttonRow">
                                    <Button 
                                        disabled={formData.type === 'deliveries' && _.every(formData.cancel, el => el === 0)}
                                        onClick={() => deployConfirmation(`Are you sure you want to cancel ${formData.type === 'order' ? 'this purchase order' : 'selected purchase order deliveries'}?`, this.handleSubmit)}
                                        variant="text"
                                        color="primary"
                                    >
                                        <FAIcon icon="check" button size={15} />
                                        {formData.type === 'order' ? `Cancel Order` : `Cancel Selected Deliveries`}
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
        );
    }
}

export default CancelPurchaseOrder;