import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const ContactUpdates = ({formData, formErrors, handleChange}) => (
    <PaddedPaper>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    Contact Details
                </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    name="contactEmail"
                    label='E-mail Address'
                    value={formData.contactEmail}
                    onChange={handleChange}
                    error={formErrors && formErrors['contactEmail'] && true}
                    helperText={formErrors && formErrors['contactEmail']}
                    margin="none"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    name="contactPhone"
                    label='Mobile Number'
                    value={formData.contactPhone}
                    onChange={handleChange}
                    error={formErrors && formErrors['contactPhone'] && true}
                    helperText={formErrors && formErrors['contactPhone']}
                    margin="none"
                    type="tel"
                    fullWidth
                />
            </Grid>
        </Grid>
    </PaddedPaper>
)

export default ContactUpdates;