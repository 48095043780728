import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
// import StaffTile from 'Components/Common/Tiles/StaffTile';
// import Tile from 'Components/Common/Tiles/Tile';
// import { momentFormatDateTime } from 'Functions/MiscFunctions';
// import { getPresignedUrl } from 'Functions/S3Functions';
// import DataTable from 'Components/Common/DataTables/DataTable';
// import BooleanChip from 'Components/Common/Chips/BooleanChip';
import DataTable from 'Components/Common/DataTables/DataTable';
import BooleanChip from 'Components/Common/Chips/BooleanChip';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { momentFormatDate, momentFormatDateTime } from 'Functions/MiscFunctions';

const ResolveIssue = ({callback, canUpdate, canProcess, hideOptions, po, pod}) => { 

    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    // const history = useHistory();

    const staff = useSelector(state => state.notifications.status);

    const handleChangeResolution = (grpId, value, field) => {
        let newFormData = {...formData};
        newFormData = {
            ...newFormData,
            [grpId]: {
                ...newFormData[grpId],
                [field]: value
            }
        }
        setFormData(newFormData);
    }
    
    useEffect(() => {
        let newFormData = {};
        _.each(_.filter(pod?.goods_received?.products, el => el.grp_successful === 0), grp => {
            newFormData = {
                ...newFormData,
                [grp.grp_id]: grp
            }
        })
        setFormData(newFormData);
    }, [pod])

    const handleSubmit = () => {

        setIsLoading(true);

        API.put(`/purchaseOrders/delivery/${pod?.pod_id}/resolve`, { formData })
        .then(res => {
            if(res?.data) {

                if(res.data?.errors) {

                    setIsLoading(false);
                    dispatch(deploySnackBar("error", "Something went wrong, please try again"));

                } else {

                    callback?.();                 
                    dispatch(closeDialog());
                    dispatch(deploySnackBar("success", "This delivery will be part shipped, outstanding items to follow when available"));

                }

            }
        })

    }

    const markPodIssueResolved = () => {

        setIsLoading(true);

        API.put(`/purchaseOrders/delivery/${pod?.pod_id}/resolved`)
        .then(res => {
            if(res?.data) {

                if(res.data?.errors) {

                    setIsLoading(false);
                    callback?.();                 
                    dispatch(deploySnackBar("error", "Something went wrong, please try again"));

                } else {

                    callback?.();                 
                    dispatch(deploySnackBar("success", "The issue with this delivery has been marked as resolved"));

                }

            }
        })
        
    }
    
    return (
        <>
            <Box p={1} pb={0.1} style={{background: '#d9534f'}}>
                <Typography variant="h6" gutterBottom className="textLight">
                    {po?.po_type === "Sales Order" || hideOptions ? `Delivery ${pod.pod_idx} - Goods Received Issue` : `Resolve Issue`}
                </Typography>
            </Box>
            <Box p={3} className="content-error-paper" mb={hideOptions ? 3 : undefined}>
                {(isLoading && (
                    <>
                        <Box pt={1} pb={2.5}>
                            <Typography variant='h6' className='fw-400' align='center'>
                                <LoadingCircle />
                                Please wait whilst your resolution is actioned
                            </Typography>
                        </Box>
                    </>
                )) || ( 
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Box p={1} pr={3}>
                                        <Typography variant="h6" gutterBottom>
                                            Supplier Details
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Name -</strong> {po?.supplier?.supp_company_name}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Phone - </strong> {po?.supplier?.supp_phone}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>E-mail - </strong> {po?.supplier?.supp_email}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box p={1} pr={3}>
                                        <Typography variant="h6" gutterBottom>
                                            Delivery Details
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Our Ref -</strong> {po?.po_reference}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Their Ref - </strong> {!_.isEmpty(po?.po_supp_reference) ? po?.po_supp_reference : 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Despatched - </strong> {momentFormatDate(pod?.pod_despatch_date)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box p={1} pr={3}>
                                        <Typography variant="h6" gutterBottom>
                                            Goods Received
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Issues - </strong> {_.size(_.filter(pod?.goods_received?.products, el => el.grp_successful === 0))}/{_.size(pod?.products)}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Received - </strong> {momentFormatDate(pod?.pod_despatch_date)}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Received By - </strong> <StaffChip staff={staff?.[pod?.goods_received?.gr_staff_id ?? 0]} />
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {po?.po_type === "Sales Order" && pod.pod_ship_aa_only === 1 && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    <strong>Replacement Stock Required</strong><br />
                                    Sales order delivery {pod?.od?.od_reference}/{pod?.od?.od_idx} is affected by this goods received discrepancy, when replacement goods arrive this issue will be automatically resolved                                
                                    <Box mt={1}>
                                        <Button 
                                            onClick={() => dispatch(deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to mark this issue as manually resolved?</Typography><Typography variant="body2">This will mark the delivery as despatched, delivered and ready for invoicing</Typography></>, markPodIssueResolved))} 
                                        >
                                            <FAIcon icon="exclamation-triangle" button size={15} />
                                            Mark as manually resolved
                                        </Button>
                                    </Box>
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box pl={1}>
                                <Typography variant="h6" paragraph>
                                    Discrepancies
                                </Typography>
                            </Box>
                            <DataTable  
                                config={{
                                    key: 'grp_id',
                                    alternatingRowColours: true,
                                    // responsiveImportance: true,
                                    inline: true,
                                    noRowHover: true
                                }}
                                columns={
                                    (po?.po_type === "Stock" && !hideOptions && (canUpdate || canProcess) && (
                                        [
                                            {
                                                heading: 'Product',
                                                field: rowData => (
                                                    <>
                                                        <Typography variant="body2" className="fw-400">
                                                            {rowData?.grp_product_code ?? '-'}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            {rowData?.grp_product_name ?? '-'}
                                                        </Typography>
                                                    </>
                                                ),
                                                truncate: true
                                            }, 
                                            {
                                                heading: 'Expected',
                                                field: rowData => rowData.grp_expected_qty,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            }, 
                                            {
                                                heading: 'Received',
                                                field: rowData => rowData.grp_total_qty,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            }, 
                                            {
                                                heading: 'Missing',
                                                field: rowData => rowData.grp_missing_qty,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            }, 
                                            {
                                                heading: 'Damaged',
                                                field: rowData => rowData.grp_damaged_qty,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            },
                                            {
                                                heading: 'Pristine',
                                                field: rowData => (parseInt(rowData.grp_total_qty) - parseInt(rowData.grp_damaged_qty)),
                                                sizeToContent: true,
                                                alignment: 'right'
                                            },
                                            {
                                                heading: 'Check Time',
                                                field: rowData => momentFormatDateTime(rowData.grp_check_datetime, true),
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Status',
                                                field: rowData => <BooleanChip success={rowData.grp_successful === 1} label={rowData.grp_successful === 1 ? 'Received' : 'Issue'} />,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Issue Qty',
                                                field: rowData => (
                                                    <Box width={50}>
                                                        {rowData.issueQty}
                                                    </Box>
                                                ),
                                                sizeToContent: true,
                                                alignment: 'right'
                                            }, 
                                            {
                                                heading: 'Replace Qty',
                                                field: rowData => (
                                                    <Box width={50}>
                                                        <TextField
                                                            onChange={e => handleChangeResolution(rowData.grp_id, e?.target?.value, 'replaceQty')}
                                                            value={rowData.replaceQty}
                                                            margin="none"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{
                                                                min: 0,
                                                                step: 1
                                                            }}
                                                            type="number"
                                                        />
                                                    </Box>
                                                ),
                                                sizeToContent: true,
                                            }, 
                                            {
                                                heading: 'Cancel Qty',
                                                field: rowData => (
                                                    <Box width={50}>
                                                        <TextField
                                                            onChange={e => handleChangeResolution(rowData.grp_id, e?.target?.value, 'cancelQty')}
                                                            value={rowData.cancelQty}
                                                            margin="none"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{
                                                                min: 0,
                                                                step: 1
                                                            }}
                                                            type="number"
                                                        />
                                                    </Box>
                                                ),
                                                sizeToContent: true,
                                            }, 
                                        ]
                                    )) || (
                                        [
                                            {
                                                heading: 'Product',
                                                field: rowData => (
                                                    <>
                                                        <Typography variant="body2" className="fw-400">
                                                            {rowData?.grp_product_code ?? '-'}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            {rowData?.grp_product_name ?? '-'}
                                                        </Typography>
                                                    </>
                                                ),
                                                truncate: true
                                            }, 
                                            {
                                                heading: 'Expected',
                                                field: rowData => rowData.grp_expected_qty,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            }, 
                                            {
                                                heading: 'Received',
                                                field: rowData => rowData.grp_total_qty,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            }, 
                                            {
                                                heading: 'Missing',
                                                field: rowData => rowData.grp_missing_qty,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            }, 
                                            {
                                                heading: 'Damaged',
                                                field: rowData => rowData.grp_damaged_qty,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            },
                                            {
                                                heading: 'Pristine',
                                                field: rowData => (parseInt(rowData.grp_total_qty) - parseInt(rowData.grp_damaged_qty)),
                                                sizeToContent: true,
                                                alignment: 'right'
                                            },
                                            {
                                                heading: 'Check Time',
                                                field: rowData => momentFormatDateTime(rowData.grp_check_datetime, true),
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Status',
                                                field: rowData => <BooleanChip success={rowData.grp_successful === 1} label={rowData.grp_successful === 1 ? 'Received' : 'Issue'} />,
                                                sizeToContent: true
                                            }
                                        ]
                                    )
                                }
                                rows={formData}
                            />
                        </Grid>
                        {po?.po_type === "Stock" && !hideOptions && (canUpdate || canProcess) && (
                            <>
                                <Grid item xs={12} align='right'>
                                    <Button
                                        disabled={_.some(formData, el => (parseInt(el.replaceQty) + parseInt(el.cancelQty)) !== parseInt(el.issueQty))}
                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to resolve this delivery?`, handleSubmit))}
                                        type="text"
                                        color="primary"
                                    >
                                        <FAIcon icon="check" size={15} button />
                                        Confirm Resolution
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </Box>
        </>
    )
}

export default ResolveIssue;