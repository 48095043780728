import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import { Provider } from 'react-redux';

import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Layout from 'Components/Layout/Layout';
import MainStore from 'Redux/MainStore';
import { handleAppError, handleWindowResize } from 'Redux/Actions/UI/UI';
import { handleServiceWorker } from 'Redux/Actions/ServiceWorker/ServiceWorker';

/* Moment Configuration */
moment.updateLocale(moment.locale(), { invalidDate: "Unknown" })

/* Service Worker Callback */
const serviceWorkerCallback = installed => {
    MainStore.dispatch(handleServiceWorker(installed));
}

/* Window Event Listeners */
window.addEventListener('online', () => MainStore.dispatch(handleAppError(false, "")))
window.addEventListener('offline', () => MainStore.dispatch(handleAppError("NETWORK_OFFLINE", "")))
window.addEventListener('resize', _.debounce(() => MainStore.dispatch(handleWindowResize(window.innerWidth, window.innerHeight)), 200));

/* Create Application */
const App = () => (
    <Provider store={MainStore}>
        <Layout />
    </Provider>
)

/* Render */
ReactDOM.render(<App />, document.getElementById('Red'));

/* Register Service Worker */
serviceWorkerRegistration.register({
    serviceWorkerCallback,
    onUpdate: registration => {

        MainStore.dispatch(handleServiceWorker(true));   
        setTimeout(() => { 
            MainStore.dispatch(handleServiceWorker(false));
        }, 2500);

        /* Get waiting service worker process */
        const waitingServiceWorker = registration.waiting

        /* Check for process */
        if (waitingServiceWorker) {

            /* Add event listener to fire on service worker state change */
            waitingServiceWorker.addEventListener("statechange", e => {
                /* If service worker has activated */
                if(e.target.state === "activated") {
                    window.location.reload(true);
                }
            });

            /* Message the waiting service worker to skip waiting */
            waitingServiceWorker.postMessage({ 
                type: "SKIP_WAITING"
            });

        }

    }
});