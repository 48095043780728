import _ from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import AsyncSelect from 'Components/Common/Selects/AsyncSelect';
import { CLOUDFRONT_URL } from 'Constants';

const formatOptionLabel = (hideStock, basic, minimal) => ({code, label, img, subtitle1, subtitle2, cp, cw, cwq, dim, mno, omo, pc, pcd, ps, wei}) => {
    return (
        <Box pt={1} pb={1}>
            <Grid container spacing={1} alignItems='center'>
                {!basic && !minimal && (
                    <Grid item style={{width: 50}}>
                        <Avatar variant="square" className="mr-1" style={{background: 'transparent', width: 40, height: 40}}><ImageWithError alt="Product Image" width="100%" height="100%" src={`${CLOUDFRONT_URL}${img}`} /></Avatar>
                    </Grid>
                )}
                <Grid item xs style={{width: 'calc(100% - 50px)'}}>
                    <Typography variant="body2">
                        {(basic && (
                            code
                        )) || (minimal && (
                            <>
                                <span className="fw-400">{code}- </span>{subtitle1}
                            </>
                        )) || (
                            <span className="fw-400">
                                {label.substr(0,80)}{label.length > 80 ? '...' : ''}
                            </span>
                        )}
                    </Typography>
                    {!basic && !minimal && subtitle1 && ( 
                        <Typography variant="caption" component="div" className="fw-400">
                            {subtitle1.substr(0,80)}{subtitle1.length > 80 ? '...' : ''}
                        </Typography>
                    )}
                    {!basic && !minimal && !hideStock && subtitle2 && ( 
                        <Typography variant="caption" component="div">
                            {subtitle2}
                        </Typography>
                    )}
                    {!hideStock && (cp === 1 || cw === 1 || ps || mno > 1 || omo > 1 || dim || wei) ? (
                        <Box className="pt-1">
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs={12} className="pt-0 pb-05">
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item className="pt-0 pb-0">
                                            <Typography variant="caption" style={{color: '#111111' }}>
                                                <FAIcon icon="box" size={11.5} style={{color: '#777'}} noMargin />{' '}Pack Size: {ps}
                                            </Typography>
                                        </Grid>
                                        {wei && (
                                            <Grid item className="pt-0 pb-0">
                                                <Typography variant="caption" style={{color: '#111111' }}>
                                                    <FAIcon icon="weight-hanging" size={11.5} style={{color: '#777'}} noMargin />{' '}Weight: {wei}kg
                                                </Typography>
                                            </Grid>
                                        )}
                                        {dim && (
                                            <Grid item className="pt-0 pb-0">
                                                <Typography variant="caption" style={{color: '#111111' }}>
                                                    <FAIcon icon="ruler" size={11.5} style={{color: '#777'}} noMargin />{' '}Dimensions: {dim}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {cp === 1 && ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#FF5722' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}Check Price and Availability</strong>
                                        </Typography>
                                    </Grid>
                                )}
                                {cw === 1 && ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#FF5722' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}Check Weight{cwq > 0 ? ` (> ${cwq} Qty)` : ``}</strong>
                                        </Typography>
                                    </Grid>
                                )}
                                {mno > 1 && ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#e91e63' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}Min Order Qty: {mno}</strong>
                                        </Typography>
                                    </Grid>
                                )}
                                {omo > 1 && ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#e91e63' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}Order In Multiples Of: {omo}</strong>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    ) : null}
                    {!basic && !minimal && pc && (
                        <Grid item xs={12}>
                            <Box pt={0.5} pb={0.5}>
                                <Typography variant="caption" className="fw-400">
                                    <FAIcon icon="exclamation-triangle" type="solid" className="textError mr-1" size={13.5} /> 
                                    Trade price is changing to &pound;{pc} on {pcd}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}


const ProductSearchSelector = ({basic, brand, category, forCustomer, forECommerce, exclude, handleAction, includeBundles, defaultOption, hideStock, isMulti, label, menuPlacement, minimal, noLabel, passDefaultOptionsToParent, paxtonHardwareExcluded, placeholder, range, selectRef, stocked, supplier, value, variant }) => {

    const [defaultOptions, setDefaultOptions] = useState(defaultOption ?? []);
    const loadProductOptions = (stocked, supplier, searchString, callback, selectRef) => {
        let params = { 
            searchString,
        }
        if(brand) {
            params = {
                ...params,
                brand
            }
        }
        if(category) {
            params = {
                ...params,
                category
            }
        }
        if(exclude) {
            params = { 
                ...params,
                exclude
            }
        }
        if(forCustomer) {
            params = {
                ...params,
                forCustomer
            }
        }
        if(forECommerce) {
            params = {
                ...params,
                forECommerce
            }
        }
        if(includeBundles) {
            params = {
                ...params,
                includeBundles
            }
        }
        if(range) {
            params = {
                ...params,
                range
            }
        }
        if(stocked) {
            params = {
                ...params,
                stocked
            }
        }
        if(supplier) {
            params = {
                ...params,
                supplier
            }
        }
        if(paxtonHardwareExcluded === 1) {
            params ={
                ...params,
                paxtonHardwareExcluded
            }
        }
        API.get('/smartSearch/products', 
            {
                props: {
                    cancellation: true,
                    noLoading: true
                },
                params
            }
        )
        .then(res => {

            let defaultOptions = [];

            if(res?.data && res.data.length > 0) {
                _.each(res.data, result => {
                    defaultOptions.push(
                        {
                            label: result.title,
                            code: result.title.split('|')[0],
                            value: result.action,
                            img: result.img,
                            subtitle1: result.subtitle1,
                            subtitle2: result.subtitle2,
                            tp: result.tp,
                            bp: result.bp,
                            mr: result.mr,
                            pc: result.pc,
                            pcd: result.pcd,
                            d: result.d ?? null,
                            cp: result.cp ?? 0,
                            cw: result.cw ?? 0,
                            cwq: result.cwq ?? 0,
                            dim: result.dim ?? null,
                            mno: result.mno ?? null,
                            omo: result.omo ?? null,
                            ps: result.ps ?? null,
                            wei: result.wei ?? null,
                        }
                    )
                })
            }
            
            callback?.(defaultOptions)
            setDefaultOptions?.(defaultOptions)
            passDefaultOptionsToParent?.(defaultOptions)

        })
    }

    return (
        <div style={{position: 'relative', marginTop: hideStock ? (noLabel ? 5 : 27) : (noLabel ? 5 : undefined)}}>
            {!noLabel && (
                <FormLabel style={{position: 'absolute', top: hideStock ? -25 : -19, left: 0}}>
                    <Typography variant="caption" color="textSecondary">
                        {label ?? 'Product *'}
                    </Typography>
                </FormLabel>
            )}
            <AsyncSelect
                apiCall={_.debounce((searchString, callback, selectRef) => loadProductOptions(stocked, supplier, searchString, callback, selectRef), 450)}
                isMulti={isMulti}
                formatOptionLabel={formatOptionLabel(hideStock ? true : false, basic, minimal)}
                innerRef={selectRef}
                menuPlacement={menuPlacement}
                placeholder={placeholder ?? undefined}
                defaultOptions={defaultOptions}
                onChange={v => handleAction(v)}
                value={_.find(defaultOptions, el => el.value === value)}
                variant={variant ?? undefined}
            />
        </div>
    )
}

export default withRouter(ProductSearchSelector);