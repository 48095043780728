import React from 'react';
import * as dialogActionTypes from 'Redux/Actions/Dialog/Types/Types';
import TaskForm from 'Components/Common/Activity/TaskForm';

export const deployDialog = (content, disableDialogContent, title, variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => {
    return dispatch => {
        dispatch({
            type: dialogActionTypes.DIALOG_OPEN, 
            content, 
            disableDialogContent, 
            title, 
            variant, 
            size, 
            fullscreen,
            disableExit
        });
    }
}

export const closeDialog = () => {
    return dispatch => {
        dispatch({type: dialogActionTypes.DIALOG_CLOSE});
    }
}

export function updateAction(id) {
    return dispatch => {    
        dispatch(deployDialog(<TaskForm update={id} toggleDialog={() => dispatch(closeDialog())} />, true, false, "standard", "sm"));
    }
}