import { isTablet } from 'Functions/MiscFunctions';

const   offset          = isTablet() ? 200 : 230,
        heightOffset    = isTablet() ? 85 : 90;

const Form = theme => ({
    container: {
        margin: -24,
        width: 'initial',
    },
    absoluteBottom: {
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    absoluteTop: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    alert: {
        borderRadius: 0, 
        borderBottom: '1px solid #ddd!important'
    },
    card: {
        background: 'transparent',
        width: 194,
        maxWidth: 194,
        overflow: 'hidden'
    },
    frequentPaper: {
        background: '#F5F5F5', 
        borderTop: '1px solid #ddd', 
        borderRadius: 0
    },
    main: {
        width: `calc(100% - ${offset}px)`,
        position: 'relative',
        height: '100%',
        padding: 24,
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    overflow: {
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    overflowText: {
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis'
    },
    overlay: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2)
    },
    padding: {
        padding: theme.spacing(3)
    },
    preview: {
        position: 'relative',
        textAlign: 'center',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    relative: {
        position: 'relative',
    },
    side: {
        width: offset,
        backgroundColor: '#eee',
        position: 'fixed',
        top: 0,
        right: 0,
        paddingTop: heightOffset,
        borderLeft: '1px solid #ddd',
        zIndex: 1000
    },
    tab: {
        opacity: 1,
        "&:hover": {
            background: '#e9e9e9',
            cursor: 'pointer'
        }
    },
    tabs: {
        backgroundColor: '#eee',
        opacity: 1,
    },
    tabSelected: {
        color: '#ef3340'
    },
    td: {
        border: 'none',
        padding: 4
    },
    totals: {
        height: 355
    },
    newProductTotals: {
        height: 126.5
    },
    updateProductTotals: {
        height: 157.5
    },
    newCustomerTotals: {
        height: 126.5
    },
    updateCustomerTotals: {
        height: 157.5
    },
    newPoTotals: {
        height: 245
    },
    updatePoTotals: {
        height: 276
    },
    poIfmTotals: {
        height: 275
    },
    newRmaTotals: {
        height: 194
    },
    updateRmaTotals: {
        height: 225
    }
});

export default Form;