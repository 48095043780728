import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Action from 'Components/Common/Cards/Action';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import NotificationBadge from 'Components/Common/Badges/NotificationBadge';
import { putActionStatus } from 'Redux/Actions/Notifications/Notifications';
import { handleClick, handleClose } from 'Functions/PopperFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = {
    anchorEl: null
}

class AdminCallBacks extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleClick = handleClick.bind(this);
        this.handleClose = handleClose.bind(this);
    }

    handleAction = (action) => {
        if(action) {
            this.handleClose();
            action = action.split(":");
            switch(action[0]) {
                case "url":
                    this.props.history.push(action[1]);
                break;
                default:
            }
        }
    }

    handleDismiss = (id, e) => {
        e.stopPropagation();
        this.props.putActionStatus(id);
    }

    handleDelete = (id, e) => {
        e.stopPropagation();
        this.props.putActionStatus(id, true);
    }

    render() {
        let { actions, device, loggedInStaff, status } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
            actions = _.sortBy(_.filter(actions, el => el.t === 'Call Back' && (!el.w || (el.w && moment(el.w, "DD/MM/YYYY HH:mm").isBefore(moment())))), function(el) { return !el.w || moment(el.w, "DD/MM/YYYY HH:mm") });
        return (
            <>
                <Grid container alignItems='center'>
                    <Grid item>
                        <ClickAwayListener onClickAway={this.handleClose}>
                            <div>
                                <Tooltip title="Outstanding Call Backs">
                                    <IconButton
                                        aria-controls='call-back-menu'
                                        aria-haspopup="true"
                                        disabled={!actions}
                                        onClick={this.handleClick}
                                        size='small'
                                    >
                                         {(actions && _.size(actions) > 0 && ( 
                                            <NotificationBadge
                                                badgeContent={_.size(actions)}
                                                variant='standard'
                                                color='secondary'
                                                showZero={false}
                                            >
                                                <FAIcon type="solid" icon="phone" size={device?.isTablet ? 17 : 18} noMargin buttonPrimary/>
                                            </NotificationBadge>
                                        )) || (
                                            <FAIcon type="solid" icon="phone" size={device?.isTablet ? 17 : 18} noMargin buttonPrimary/>
                                        )}
                                    </IconButton>
                                </Tooltip>
                                <Popper 
                                    id={open ? 'call-back-menu' : undefined} 
                                    open={open}
                                    anchorEl={anchorEl}
                                    className="dd-menu"
                                    disablePortal={true}
                                    style={{marginBottom: -12}}
                                >
                                    <Paper elevation={0}>
                                        {(!_.isEmpty(actions) && (
                                            <List 
                                                disablePadding 
                                            >
                                                {_.map(actions, (action, idx) => (
                                                    <Action
                                                        elevation={0}
                                                        noXlDivider
                                                        noAuthor
                                                        xl
                                                        compact
                                                        inline
                                                        key={idx}
                                                        data={action}
                                                        handleAction={this.handleAction}
                                                        handleDismiss={this.handleDismiss}
                                                        handleDelete={this.handleDelete}
                                                        status={status}
                                                        staffId={(loggedInStaff && loggedInStaff.id) || 0}
                                                    />
                                                ))}
                                            </List>
                                        )) || (
                                            <Grid container alignItems='center' className='menu-text' style={{background: !_.isEmpty(actions) ? '#fafafa' : undefined}}>
                                                <Grid item xs={12}>
                                                    <Typography component="div" variant="caption" paragraph>
                                                        You have no call backs at this time
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Paper>
                                </Popper>
                            </div>
                        </ClickAwayListener>
                    </Grid>
                </Grid>
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        actions: state.notifications.actions,
        status: state.notifications.status
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, de)),
        putActionStatus: (id, del = false) => dispatch(putActionStatus(id, true))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminCallBacks));