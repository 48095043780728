import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TaskList from 'Components/Common/Tasks/TaskList';
import Tile from 'Components/Common/Tiles/Tile';
import CustomerSearch from 'Components/Customers/CustomerSearch/CustomerSearch';
import { currencyFormat } from 'Functions/MiscFunctions';

import Bulletin from 'Components/Dashboard/Common/Bulletin';
import Calendar from 'Components/Dashboard/Common/Calendar';

const ManagementDashboard = ({pageTitle}) => {

    const [data, setData] = useState(null);

    const loadComponentData = () => {
        API.get(`/dashboard`, {
            params: {
                d: 'management'
            },
            props: {
                cancellation: true
            }
        })
        .then(res => {
            if(res?.data) {

                let newData = res.data,
                    labels = [],
                    sales = [],
                    purchases = [],
                    credit = [],
                    target = [],
                    totalSales = [],
                    totalQuotes = [];

                if(newData.chart) {

                    for (let i = 0, j = 11; i <= j; i++) {
                        target.push(res.data.chart?.[i]?.target?.v);
                        sales.push(res.data.chart?.[i]?.sales?.v);
                        purchases.push(res.data.chart?.[i]?.purchases?.v);
                        credit.push(res.data.chart?.[i]?.credit?.v);
                        totalSales.push(res.data.chart?.[i]?.orders?.t);
                        totalQuotes.push(res.data.chart?.[i]?.quotes?.t);
                        labels.push(res.data.chart?.[i]?.label);
                    }

                    newData.financialChart = {
                        labels,
                        datasets: [
                            {
                                label: 'Target',
                                data: target,
                                backgroundColor: '#03A9F4',
                                borderColor: '#B3E5FC',
                                fill: false,
                                lineTension: 0.25,
                                borderDash: [2.5,2.5]
                            },
                            {
                                label: 'Sales',
                                data: sales,
                                backgroundColor: '#4CAF50',
                                borderColor: '#C8E6C9',
                                fill: false,
                                lineTension: 0.25,
                            },
                            {
                                label: 'Purchases',
                                data: purchases,
                                fill: false,
                                backgroundColor: '#FFC107',
                                borderColor: '#FFECB3',
                                lineTension: 0.25,
                            },
                            {
                                label: 'Credits',
                                data: credit,
                                fill: false,
                                backgroundColor: '#F44336',
                                borderColor: '#FFCDD2',
                                lineTension: 0.25,
                            }
                        ]
                    }

                    newData.salesChart = {
                        labels,
                        datasets: [
                            {
                                label: 'Orders',
                                data: totalSales,
                                backgroundColor: '#E91E63',
                                borderColor: '#FCE4EC',
                                fill: false,
                                lineTension: 0.25,
                            },
                            {
                                label: 'Quotes',
                                data: totalQuotes,
                                fill: false,
                                backgroundColor: '#607D8B',
                                borderColor: '#ECEFF1',
                                lineTension: 0.25,
                            },
                        ]
                    }

                    delete newData.chart;

                }

                setData(newData);
            }
        });
    }

    useEffect(() => {
        pageTitle?.([0, 'Dashboard', 'Management']);
        loadComponentData();
    }, [pageTitle]);

    return (
        (!data && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12} xl={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Weekly Sales
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='pound-sign'
                                                title="Target"
                                                content={currencyFormat.format(data?.stats?.ta?.w)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tooltip 
                                                title={(
                                                    <>
                                                        <Typography variant="body1" className="fw-400">
                                                            Breakdown
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Not Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.w?.ni)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.w?.to)}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <strong>Credits: </strong> {currencyFormat.format(data?.stats?.sa?.w?.cr)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Total: </strong> {currencyFormat.format(data?.stats?.sa?.w?.gr)}
                                                        </Typography>
                                                    </>
                                                )}
                                            >
                                                <span>
                                                    <Tile
                                                        noPaper
                                                        icon='pound-sign'
                                                        title="Sales"
                                                        content={currencyFormat.format(data?.stats?.sa?.w?.gr)}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon={data?.stats?.ta?.w - data?.stats?.sa?.w?.gr < 0 ? 'check' : 'times'}
                                                color={data?.stats?.ta?.w - data?.stats?.sa?.w?.gr < 0 ? '#4CAF50' : '#F44336'}
                                                title="Sales To Target"
                                                content={currencyFormat.format((data?.stats?.ta?.w - data?.stats?.sa?.w?.gr).toFixed(2))}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='calendar-day'
                                                title="Days Left"
                                                content={data?.stats?.wd?.w}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Monthly Sales
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='pound-sign'
                                                title="Target"
                                                content={currencyFormat.format(data?.stats?.ta?.m)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tooltip 
                                                title={(
                                                    <>
                                                        <Typography variant="body1" className="fw-400">
                                                            Breakdown
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Not Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.m?.ni)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.m?.to)}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <strong>Credits: </strong> {currencyFormat.format(data?.stats?.sa?.m?.cr)}
                                                        </Typography>
                                                        <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                        <Typography variant="body2">
                                                            <strong>Total: </strong> {currencyFormat.format(data?.stats?.sa?.m?.gr)}
                                                        </Typography>
                                                    </>
                                                )}
                                            >
                                                <span>
                                                    <Tile
                                                        noPaper
                                                        icon='pound-sign'
                                                        title="Sales"
                                                        content={currencyFormat.format(data?.stats?.sa?.m?.gr)}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon={data?.stats?.ta?.m - data?.stats?.sa?.m?.gr < 0 ? 'check' : 'times'}
                                                color={data?.stats?.ta?.m - data?.stats?.sa?.m?.gr < 0 ? '#4CAF50' : '#F44336'}
                                                title="Sales To Target"
                                                content={currencyFormat.format((data?.stats?.ta?.m - data?.stats?.sa?.m?.gr).toFixed(2))}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={12} xl={6}>
                                            <Tile
                                                noPaper
                                                icon='calendar-day'
                                                title="Days Left"
                                                content={data?.stats?.wd?.m}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {_.map(data?.stats?.qt, (row, idx) => (
                                    <Grid item xs={12} lg={6} key={idx}>
                                        <Paper>
                                            <Box p={3}>
                                                <Typography variant="h6" paragraph>
                                                    Q{row?.f?.q}/{moment(row?.f?.y, "YYYY").format("YY")}: {row?.mn} {row?.y?.y}
                                                </Typography>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={6}>
                                                        <Tile
                                                            noPaper
                                                            icon="arrow-to-bottom"
                                                            title="Net Sales"
                                                            content={currencyFormat.format(row?.r?.s?.v)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <Tile
                                                            noPaper
                                                            icon="arrow-from-bottom"
                                                            title="Net Purchases"
                                                            content={currencyFormat.format(row?.r?.p?.v)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <Tile
                                                            noPaper
                                                            icon="chart-bar"
                                                            title="Sales Target"
                                                            content={currencyFormat.format(row?.r?.t?.v)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <Tile
                                                            noPaper
                                                            icon="minus-circle"
                                                            title="Credit Issued"
                                                            content={currencyFormat.format(row?.r?.c?.v)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <Tile
                                                            className="fw-400"
                                                            noPaper
                                                            icon={row?.r?.n > 0 ? 'plus' : 'minus'}
                                                            color={row?.r?.n > 0 ? '#4CAF50' : '#F44336'}
                                                            title="Sales vs Purchases"
                                                            content={currencyFormat.format(row?.r?.n)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <Tile
                                                            className="fw-400"
                                                            noPaper
                                                            icon={row?.r?.a > 0 ? 'plus' : 'minus'}
                                                            color={row?.r?.a > 0 ? '#4CAF50' : '#F44336'}
                                                            title="Sales vs Target"
                                                            content={currencyFormat.format(row?.r?.a)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {/* <Box p={3}>
                                                <Typography variant="h5">
                                                    This Week
                                                </Typography>
                                                <DataTable
                                                    config={{
                                                        noResultsText: "No data to display",
                                                        showFullTotals: true,
                                                        noRowHover: true
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: 'Day',
                                                            field: rowData => rowData.h,
                                                            sizeToContent: true,
                                                            showTotalLabel: true
                                                        },
                                                        {
                                                            heading: 'Deliveries',
                                                            field: rowData => rowData.v,
                                                            sizeToContent: true,
                                                            showTotal: true
                                                        }
                                                    ]}
                                                    rows={data?.despatched?.thisWeek ?? []} 
                                                />
                                            </Box> */}
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid> 
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Credit Accounts
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='check'
                                                title="Open"
                                                content={data?.stats?.open}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='exclamation-triangle'
                                                title="Monitored"
                                                content={data?.stats?.mon}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='info-circle'
                                                title="Total"
                                                content={data?.stats?.total}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='arrow-to-top'
                                                title="Combined Credit Limit"
                                                content={currencyFormat.format(data?.stats?.li)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='arrow-to-bottom'
                                                title="Combined Credit Balance"
                                                content={currencyFormat.format(data?.stats?.ba)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='expand-alt'
                                                title="Combined Credit Available"
                                                content={currencyFormat.format(data?.stats?.av)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Monitored Accounts
                                    </Typography>
                                    <CustomerSearch
                                        dashboard
                                        inline
                                        showMonitored
                                        showPagination
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Financial Insight
                                    </Typography>
                                    <Box height={400} width={'100%'} maxWidth={'100%'} minHeight={300}>
                                        <Line 
                                            data={data.financialChart} 
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom"
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0
                                                        }
                                                    }]
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Quotes vs Sales
                                    </Typography>
                                    <Box height={400} width={'100%'} maxWidth={'100%'} minHeight={300}>
                                        <Line 
                                            data={data.salesChart} 
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom"
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0
                                                        }
                                                    }]
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Actions Due Today
                                    </Typography>
                                    <TaskList />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Bulletin />
                        </Grid>
                        <Grid item xs={12}>
                            <Calendar />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

export default ManagementDashboard;