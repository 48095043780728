import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import {
    Avatar,
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    Paper,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Logo from 'Assets/Images/no-img.jpg';

import { 
    CLOUDFRONT_URL
} from 'Constants';

import {
    deployConfirmation
} from 'Redux/Actions/Confirmation/Confirmation';

import {
    deploySnackBar
} from 'Redux/Actions/SnackBar/SnackBar';

import {
    closeDialog
} from 'Redux/Actions/Dialog/Dialog';

const KitPricing = ({callback}) => {

    const [loading, setLoading] = useState(true);

    const [kits, setKits] = useState([]);

    const dispatch = useDispatch();

    const handleChange = (idx, e) => {

        const {
            name, 
            value 
        } = e.target;

        let newKits = _.cloneDeep(kits);

        newKits[idx][name] = value;

        if(name === 'fp' && value !== '') {
            newKits[idx].mr = (((parseFloat(value) - parseFloat(newKits[idx].bp)) / parseFloat(value)) * 100).toFixed(1);
        }

        setKits(newKits);
    }

    const handleConfirmation = () => {
        dispatch(deployConfirmation(`Are you sure you want to save your changes?`, handleSubmit))
    }

    const handleSubmit = () => {
        setLoading(true);
        API.put('/products/bundles/kits/pricing', { kits } )
        .then(res => {
            if(res?.data?.success) {
                callback?.();
                dispatch(deploySnackBar('success', 'The kits were successfully updated'))
                dispatch(closeDialog());
            }
        })
    }

    useEffect(() => {
        setLoading(true);
        API.get('/products/bundles/kits/pricing')
        .then(res => {
            if(res?.data) {
                if(_.isEmpty(res.data)) {
                    dispatch(deploySnackBar('error', 'No active kits were found'))
                    dispatch(closeDialog())
                }
                setKits(res.data);
                setLoading(false);
            }
        }) /* eslint-disable-next-line */
    }, [])
    
    return (
        (loading && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12}> 
                    <Paper>
                        <DataTable
                            config={{
                                key: "id",
                                alternatingRowColours: true,
                                noResultsText: "No products for this category",
                            }}
                            columns={
                                [
                                    {
                                        heading: 'Active',
                                        field: rowData => (
                                            <Switch
                                                color="primary"
                                                checked={rowData.lv}
                                                onChange={e => handleChange(rowData.rowIdx, {target: {name: 'lv', value: e.target.checked ? 1 : 0}})}
                                                value="1"
                                                name="lv"
                                            />
                                        ),
                                        fieldFormat: 'boolean',
                                        sizeToContent: true,  
                                        dataRef: 'lv',
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Description',
                                        field: rowData => (
                                            <Box pt={1} pb={1}>
                                                <Grid container spacing={3} alignItems='center'>
                                                    <Grid item>
                                                        <Avatar variant="square" alt={rowData.nm} src={!_.isEmpty(rowData.im) ? `${CLOUDFRONT_URL}${rowData.im}` : Logo} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="body2" className="fw-400">
                                                            {rowData.pc}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {rowData.nm}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            {rowData.desc}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ),
                                        dataRef: 'nm',
                                    },
                                    {
                                        heading: 'Valid From',
                                        field: rowData => (
                                            <Box minWidth={140}>
                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        name="vf"
                                                        type="date"
                                                        onChange={date => handleChange(rowData.rowIdx, {target: { name: 'vf', value: moment(date).format('YYYY-MM-DD')}})}
                                                        value={rowData.vf}
                                                        autoOk={true}
                                                        disablePast={true}
                                                        maxDate={rowData.vt ? rowData.vt : undefined}
                                                        minDateMessage={``}
                                                        maxDateMessage={``}
                                                        inputVariant="filled"
                                                    />
                                                </FormControl>
                                            </Box>
                                        ),
                                        sizeToContent: true,  
                                        dataRef: 'vf',
                                    },
                                    {
                                        heading: 'Valid To',
                                        field: rowData => (
                                            <Box minWidth={140}>
                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        name="vt"
                                                        type="date"
                                                        onChange={date => handleChange(rowData.rowIdx, {target: { name: 'vt', value: moment(date).format('YYYY-MM-DD')}})}
                                                        value={rowData.vt}
                                                        autoOk={true}
                                                        disablePast={true}
                                                        minDate={rowData.vf ? rowData.vf : undefined}
                                                        minDateMessage={``}
                                                        maxDateMessage={``}
                                                        inputVariant="filled"
                                                    />
                                                </FormControl>
                                            </Box>
                                        ),
                                        sizeToContent: true,  
                                        dataRef: 'vt',
                                    },                                    
                                    {
                                        heading: 'Buy Price',
                                        field: rowData => rowData.bp,
                                        fieldFormat: 'currency',
                                        sizeToContent: true,  
                                        dataRef: 'bp',
                                        alignment: 'right',
                                    },
                                    {
                                        heading: 'Trade Price',
                                        field: rowData => rowData.tp,
                                        fieldFormat: 'currency',
                                        sizeToContent: true,  
                                        dataRef: 'tp',
                                        alignment: 'right',
                                    },
                                    {
                                        heading: 'Current Price',
                                        field: rowData => rowData.op,
                                        fieldFormat: 'currency',
                                        sizeToContent: true,  
                                        dataRef: 'op',
                                        alignment: 'right',
                                    },
                                    {
                                        heading: 'New Price',
                                        field: rowData => (
                                            <TextField
                                                name="fp"
                                                value={rowData.fp}
                                                onBlur={() => handleChange(rowData.rowIdx, {target: { name: 'fp', value: rowData.fp === '' ? '' : parseFloat(rowData.fp).toFixed(2)}})}
                                                onChange={e => handleChange(rowData.rowIdx, e)}
                                                margin="none"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment>
                                                            <FAIcon icon="pound-sign" size={12} type="light" noMargin button />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                fullWidth
                                                variant="filled"
                                            />
                                        ),
                                        sizeToContent: true,  
                                        dataRef: 'fp',
                                        alignment: 'right',
                                    },
                                    {
                                        heading: 'New Margin',
                                        field: rowData => rowData.mr,
                                        fieldFormat: 'percentage:1',
                                        sizeToContent: true,  
                                        dataRef: 'mr',
                                        alignment: 'right',
                                        cellProps: rowData => ({
                                            className: rowData.mr < 0 ? 'textError' : 'textSuccess'
                                        })
                                    }
                                ]}
                            rows={kits}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} align='right'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmation}
                    >
                        <FAIcon icon="check" size={15} button />
                        Update Kits
                    </Button>
                </Grid>
            </Grid>
        )
    )
}

export default KitPricing;