import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Alert from 'Components/Common/Alert/Alert';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import InsightChip from 'Components/Common/Chips/InsightChip';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Products from 'Components/Purchases/PurchaseOrderForm/Products';
import StackTable from 'Components/Common/DataTables/StackTable';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import Tile from 'Components/Common/Tiles/Tile';
import Totals from 'Components/Purchases/PurchaseOrderForm/Totals';

import GoodsReceivedDetails from './DetailedView/GoodsReceivedDetails';
import PodInvoiceDetails from './DetailedView/PodInvoiceDetails';
import ResolveIssue from './DetailedView/ResolveIssue';

import { currencyFormat, isTablet, momentDiffForHumans } from 'Functions/MiscFunctions';
import { momentFormatDate } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

import { isCrushedIce } from 'Functions/AuthFunctions';

const useStyles = makeStyles({
    tab: {
        color: '#fff',
        opacity: 1
    },
    td: {
        border: 'none',
        padding: 4
    }
})

const PurchaseOrderDeliverySummary = ({access, callback, inDialog, po, pod}) => { 

    const   history     = useHistory(),
            classes     = useStyles(),
            dispatch    = useDispatch(),
            staff       = useSelector(state => state.notifications.status),
            ui          = useSelector(state => state.ui); 

    const deployDetailedView = (type, idx) => {

        let Component = null;

        switch(type) {
            case "goodsReceived":
                Component = GoodsReceivedDetails;
            break;
            case "invoice":
                Component = PodInvoiceDetails;
            break;
            default:
                return;
        }

        if(Component) {

            dispatch(deployDialog(
                <Component
                    closeDialog={() => dispatch(closeDialog())}
                    pod={pod}
                    idx={idx}
                />
                , 
                true, 
                '', 
                'standard', 
                'md',
                false,
                true
            ))

        } else {
            return;
        }

    }

    let invoiceTo = [
            po.po_contact_name,
            'Advanced Access Ltd',
            po.po_address_line_one,
            po.po_address_line_two,
            po.po_address_line_three,
            po.po_address_town,
            po.po_address_county,
            po.po_address_postcode,
            po.po_address_country
        ],
        deliveryTo = [
            `${po.po_type === 'Stock' ? `Goods In - Purchase Order` : (po.po_ship_aa_only === 1 ? 'Ship Here - Purchase Order' : 'Purchase Order')} #${po.po_reference}`,
            `${po.po_type === 'Stock' || po.po_ship_aa_only === 1 ? 'Advanced Access Ltd' : ''}`,
            po.po_delivery_address_line_one,
            po.po_delivery_address_line_two,
            po.po_delivery_address_line_three,
            po.po_delivery_address_town,
            po.po_delivery_address_county,
            po.po_delivery_address_postcode,
            po.po_delivery_address_country
        ],
        suppAddress = [
            po.supplier?.trading_address?.address_line_one,
            po.supplier?.trading_address?.address_line_two,
            po.supplier?.trading_address?.address_line_three,
            po.supplier?.trading_address?.address_town,
            po.supplier?.trading_address?.county?.county_name,
            po.supplier?.trading_address?.address_postcode,
            po.supplier?.trading_address?.country?.country_name
        ],
        invoiceToEmpty = true, 
        deliveryToEmpty = true,
        suppAddressEmpty = true;

    _.each(deliveryTo, line => {
        if(line && line.length > 0) {
            deliveryToEmpty = false;
            return false;
        }
    })

    _.each(invoiceTo, line => {
        if(line && line.length > 0) {
            invoiceToEmpty = false;
            return false;
        }
    })

    _.each(suppAddress, line => {
        if(line && line.length > 0) {
            suppAddressEmpty = false;
            return false;
        }
    })

    const handlePopUpToPage = () => {
        history.push(`/purchase-orders/${pod.pod_po_id}:${pod.pod_id}`)
        dispatch(closeDialog())        
    }

    return (
        <Grid container spacing={3} alignItems='center'>
            <Grid item xs={12} lg>
                <Typography variant="h6">
                    {inDialog ? `Purchase Order Delivery #${pod.pod_reference}/${pod.pod_idx}` : `Delivery ${pod.pod_idx} of ${_.size(po.deliveries)}`}{isCrushedIce() ? ` [ci:pod_id:${pod.pod_id}] [ci:pod_idx:${pod.pod_idx}] [ci:pod_delivered:${pod.pod_delivered}]` : ``}
                </Typography>    
                <Typography variant="body2" className="fw-400">
                    Fulfilled by {po.supplier?.supp_company_name ?? 'Unknown'}
                </Typography>    
            </Grid>
            <Grid item>
                <InsightChip 
                    icon="check"
                    label={po?.po_status === 'Awaiting Submission' && pod?.pod_status === 'Awaiting Confirmation' ? 'Awaiting Submission' : pod.pod_status}
                    paddingLeft={8}
                    marginRight={8}
                    large
                />
            </Grid>
            <Grid item>
                <InsightChip 
                    icon="truck-loading"
                    label={po.po_type === "Sales Order" || (po.po_type === "Stock" && po.po_order_id > 0 && po.po_ifm_modified === 0) ? "Sales Order Fulfilment" : "Warehouse Stock"}
                    paddingLeft={8}
                    marginRight={8}
                    large
                />
            </Grid>
            <Grid item>
                <InsightChip 
                    icon="truck-loading"
                    label={pod.pod_collect === 1 ? `Direct Collection` : (pod.pod_ship_aa_only === 1 ? `Ship Here` : `Direct Despatch`)}
                    paddingLeft={8}
                    large
                />
            </Grid>
            {po?.so && (
                <Grid item>
                    <InsightChip 
                        icon="external-link"
                        label={`${ui?.device.isPortrait ? 'SO' : 'Sales Order'} #${po?.so?.order_reference}`}
                        onClick={() => inDialog ? window.open(`/sales/${po?.so?.order_id}`) : history.push(`/sales/${po?.so?.order_id}`)}
                        paddingLeft={8}
                        marginLeft={8}
                        large
                    />
                </Grid>
            )}
            {pod?.pod_status === 'Invoiced' && pod.pod_legacy === 0 && !_.isEmpty(pod.pod_pdf_filename) && (
                <Grid item>
                    <InsightChip 
                        icon="file-pdf"
                        color="#000"
                        label={`Download Invoice`}
                        onClick={() => getPresignedUrl(pod?.pod_pdf_filename)}
                        paddingLeft={8}
                        large
                    />
                </Grid>
            )}
            {inDialog && (
                <>
                    <Grid item>
                        <Tooltip title="Open Delivery">
                            <IconButton
                                onClick={handlePopUpToPage}
                            >
                                <FAIcon icon="binoculars" size={19} button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Box width={25} />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                {(pod?.od?.od_sys_hold === 1 && (
                    <Box width="100%">
                        <Alert severity='error'>
                            <strong>On Hold - Subject To Change</strong><br />
                            The sales order has been modified, this process must be completed prior to interacting with the purchase order
                        </Alert>
                    </Box>
                )) || (pod.pod_rma === 1 && (
                    <Box width="100%">
                        <Alert severity='error'>
                            <strong>Return To Supplier</strong><br />
                            These goods are no longer required and will be returned to the supplier once received (excluded from stock levels)
                        </Alert>
                    </Box>
                )) || (pod.pod_add_to_stock === 1 && (
                    <Box width="100%">
                        <Alert severity='info'>
                            <strong>Add To Stock</strong><br />
                            This stock is longer required for a sales order and will be added to stock once received
                        </Alert>
                    </Box>
                )) || (pod.pod_status === 'Invoiced' && (
                    <Box width="100%">
                        <Alert severity="success">
                            <strong>Complete</strong><br />
                            This purchase order delivery for {po.po_type === 'Sales Order' ? (po.so?.order_reference ? `sales order ${po.so?.order_reference}` : `sales order fulfilment`) : 'warehouse stock'} has been invoiced
                        </Alert>
                    </Box>
                )) || (pod.pod_status === 'Despatched' && pod.pod_delivered === 1 && (
                    <Box width="100%">
                        <Alert severity="warning">
                            <strong>{pod.pod_collection === 1 ? 'Ready To Collect' : (pod.pod_issue === 0 && pod.pod_issue_datetime !== "0000-00-00 00:00:00" ? `Despatched (Short Delivered)` : `Despatched (Delivered)`)}</strong><br />
                            This purchase order delivery for {po.po_type === 'Sales Order' ? (po.so?.order_reference ? `sales order ${po.so?.order_reference}` : `sales order fulfilment`) : 'warehouse stock'} has been fulfilled and is now ready to be invoiced{pod.pod_issue === 0 && pod.pod_issue_datetime !== "0000-00-00 00:00:00" ? `, however, some line(s) were not fully delivered so please check carefully vs. the supplier invoice and ensure that any credits due have been raised accordingly` : ``}
                        </Alert>
                    </Box>
                )) || (pod.pod_status === 'Despatched' && pod.pod_delivered === 0 && (
                    <Box width="100%">
                        <Alert severity="warning">
                            <strong>{pod.pod_collection === 1 ? 'Ready To Collect' : (pod.pod_issue === 0 && pod.pod_issue_datetime !== "0000-00-00 00:00:00" ? `Despatched (Awaiting Replacement Stock)` : `Despatched (Awaiting Delivery)`)}</strong><br />
                            This purchase order delivery for {po.po_type === 'Sales Order' ? (po.so?.order_reference ? `sales order ${po.so?.order_reference}` : `sales order fulfilment`) : 'warehouse stock'} {pod.pod_issue === 0 && pod.pod_issue_datetime !== "0000-00-00 00:00:00" ? `has been partially delivered (awaiting replacement stock) and can be invoiced once fully received` : `has been despatched and can be invoiced once received`}
                        </Alert>
                    </Box>
                )) || (pod.pod_status === 'Cancelled' && (
                    <Box width="100%">
                        <Alert severity="error">
                            <strong>Cancelled</strong><br />
                            This purchase order delivery for {po.po_type === 'Sales Order' ? (po.so?.order_reference ? `sales order ${po.so?.order_reference}` : `sales order fulfilment`) : 'warehouse stock'} has been cancelled
                        </Alert>
                    </Box>
                )) || (po.po_type === 'Stock' && po.po_ship_aa_only === 1 && po.po_ship_here_order === 1 && po.po_order_id > 0 && (
                    <Box width="100%">
                        <Alert severity="info">
                            <strong>Sales Order #{pod.od?.od_reference} Fulfilment - Ship Here</strong><br />
                            This purchase order delivery will be forwarded to the customer once received
                        </Alert>
                    </Box>
                )) || (po.po_type === 'Sales Order' && po.po_ship_aa_only === 0 && (
                    <Box width="100%">
                        <Alert severity="info">
                            <strong>Sales Order #{pod.od?.od_reference} Fulfilment - {po.po_collection === 1 ? `Collection` : `Direct Delivery`}</strong><br />
                            This purchase order will be {po.po_collection === 1 ? `collected direct from the supplier` : `delivered directly to the customer site`}
                        </Alert>
                    </Box>
                )) || (po.po_order_id > 0 && po.so && (
                    <Box width="100%">
                        <Alert severity="warning">
                            <strong>Detached / Ship Complete - Sales Order #{po.so.order_reference}</strong>
                        </Alert>
                    </Box>
                )) || (po.po_type === 'Stock' && (
                    <Box width="100%">
                        <Alert severity="info">
                            <strong>Warehouse Stock</strong><br />
                            This purchase order delivery is for warehouse stock
                        </Alert>
                    </Box>
                ))}
            </Grid>
            {(!inDialog && pod?.pod_issue === 1 && !_.isEmpty(pod.goods_received) && (
                <Grid item xs={12}>
                    <ResolveIssue 
                        callback={callback} 
                        canUpdate={access?.updatePo ?? false}
                        canProcess={access?.processProcessing ?? false}
                        po={po}
                        pod={pod}
                    />
                </Grid>
            )) || (!inDialog && pod?.pod_issue === 0 && pod?.pod_issue_datetime !== "0000-00-00 00:00:00" && (
                <Grid item xs={12}>
                    <Alert severity={pod.pod_delivered === 0 ? 'error' : 'info'}>
                        <strong>Goods Received Discrepancy {pod.pod_delivered === 0 ? '(Awaiting Replacement Stock)' : '(Resolution Complete)'}</strong><br />
                        There were issues with the goods received for this delivery which have now been resolved, check the <i>Goods Received - Detailed View</i> below for more information
                    </Alert>
                </Grid>
            ))}
            {((pod.pod_status !== 'Awaiting Submission' && pod.pod_status !== 'Awaiting Confirmation') && (
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <PaddedPaper style={{height: '100%'}}>
                                <Box mb={2.5}>
                                    <Heading text={`Supplier Despatch`} />
                                </Box>
                                <Grid container spacing={3}>  
                                    {((pod.pod_status === 'Awaiting Submission' || pod.pod_status === 'Awaiting Confirmation') && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Not Confirmed Yet
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is subject to supplier confirmation
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <Tile
                                                    noPaper
                                                    color={pod.pod_expected_despatch_date === "0000-00-00" ? '#f44336' : '#4CAF50'}
                                                    icon={pod.pod_expected_despatch_date === "0000-00-00" ? 'exclamation-triangle' : 'check'}
                                                    title={isTablet() ? 'Expected' : 'Expected Despatch'}
                                                    content={pod.pod_expected_despatch_date === "0000-00-00" ? 'TBC' : momentFormatDate(pod.pod_expected_despatch_date)}
                                                />
                                            </Grid> 
                                            <Grid item xs={12} sm={6}>
                                                <Tile
                                                    noPaper
                                                    color={pod.pod_despatch_date === "0000-00-00" ? '#f44336' : '#4CAF50'}
                                                    icon={pod.pod_despatch_date === "0000-00-00" ? 'exclamation-triangle' : 'check'}
                                                    title="Despatch Date"
                                                    content={pod.pod_despatch_date === "0000-00-00" ? 'N/A' : momentFormatDate(pod.pod_despatch_date)}
                                                />
                                            </Grid> 
                                            <Grid item xs={12} sm={6}>
                                                <Tile
                                                    noPaper
                                                    color={'#4CAF50'}
                                                    icon={'check'}
                                                    title="Check Date"
                                                    content={pod.pod_check_date !== "0000-00-00" ? momentFormatDate(pod.pod_check_date) : 'No'}
                                                />
                                            </Grid> 
                                            <Grid item xs={12} sm={6}>
                                                <Tile
                                                    noPaper
                                                    color={pod.pod_delivered === 0 ? '#f44336' : '#4CAF50'}
                                                    icon={pod.pod_delivered === 0 ? 'times' : 'check'}
                                                    title="Delivered"
                                                    content={pod.pod_delivered === 0 ? 'No' : 'Yes'}
                                                />
                                            </Grid> 
                                        </>
                                    )}
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <PaddedPaper style={{height: '100%'}}>  
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={7}>
                                        <Heading text={`Goods Received`} noMargin />
                                    </Grid>
                                    {((pod.pod_status === 'Awaiting Submission' || pod.pod_status === 'Awaiting Confirmation') && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Not Confirmed Yet
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is subject to supplier confirmation
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.pod_legacy === 1 && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='exclamation-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            No Data Available
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            Legacy delivery goods received data is not available
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.pod_manual_received === 1 && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Goods Received Skipped
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery was manually marked as received
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.pod_collection === 1 && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Collection
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is being collected from the supplier
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.pod_ship_aa_only === 0 && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Direct Despatch
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is being despatched to the customer
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.pod_status === 'Awaiting Despatch' && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Not {pod?.pod_collection === 1 ? `Ready` : `Despatched`} Yet
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is {pod?.pod_collection === 1 ? 'being prepared' : 'awaiting despatch'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.pod_delivered === 0 && _.isEmpty(pod.goods_received) && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Awaiting Delivery
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is still in transit from the supplier
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.pod_legacy === 0 && (pod.pod_status === "Invoiced" || pod.pod_delivered === 1) && !pod.goods_received && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            No Despatch
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery had no items to despatch 
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (
                                        (_.size(pod.goods_receiveds) > 1 && (
                                            <Grid item xs={12}>
                                                <DataTable  
                                                    config={{
                                                        key: 'od_id',
                                                        alternatingRowColours: true,
                                                        inline: true,
                                                        noRowHover: true
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: 'Goods Received',
                                                            field: rowData => (
                                                                <Box pt={1} pb={1}>
                                                                    <Typography variant="body2" className="fw-400">
                                                                        {`Goods Received ${rowData.rowNumber}`}
                                                                    </Typography>
                                                                    <Typography variant="caption" component="div">
                                                                        {`Received by ${staff?.[rowData.gr_staff_id]?.name ?? 'Unknown'}`}
                                                                    </Typography>
                                                                </Box>
                                                            ),
                                                            truncate: true
                                                        }, 
                                                        {
                                                            heading: 'Completed On',
                                                            field: rowData => rowData.gr_completed_datetime,
                                                            fieldFormat: 'datetime',
                                                            sizeToContent: true
                                                        },      
                                                        {
                                                            actions: rowData => {
                                                                return [
                                                                    {name: 'View Goods Received', icon: 'eye', onClick: () => deployDetailedView('goodsReceived', rowData.rowIdx), doubleClick: true},
                                                                ]
                                                            }
                                                        }
                                                    ]}
                                                    rows={pod.goods_receiveds}
                                                />
                                            </Grid>
                                        )) || (
                                            <>
                                                <Grid item xs={5} align='right'>
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => deployDetailedView('goodsReceived')}
                                                    >
                                                        <FAIcon icon="eye" size={15} button />
                                                        {ui?.device?.isTablet ? `Details` : `Detailed View`}
                                                    </Button>
                                                </Grid>   
                                                <Grid item xs={12} sm={6}>
                                                    <Tile
                                                        noPaper
                                                        color={pod.goods_received?.gr_successful === 0 ? '#f44336' : '#4CAF50'}
                                                        icon={pod.goods_received?.gr_successful === 0 ? 'exclamation-triangle' : 'check'}
                                                        title={'All Received'}
                                                        content={pod.goods_received?.gr_successful === 0 ? 'No' : 'Yes'}
                                                    />
                                                </Grid> 
                                                <Grid item xs={12} sm={6}>
                                                    <Tile
                                                        noPaper
                                                        color={'#000'}
                                                        icon={'clock'}
                                                        title="Booking In Time"
                                                        content={momentDiffForHumans({a: pod?.goods_received?.gr_started_datetime, b: pod?.goods_received?.gr_completed_datetime, short: false})}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Tile
                                                        noPaper
                                                        color={'#4CAF50'}
                                                        icon={'check'}
                                                        title={'Lines'}
                                                        content={_.size(pod.goods_received?.products)}
                                                    />
                                                </Grid>  
                                                <Grid item xs={12} sm={6}> 
                                                    <StaffTile
                                                        minimal
                                                        noPaper
                                                        title="Received By"
                                                        staffId={pod?.goods_received?.gr_staff_id ?? null}
                                                        missingText='N/A'
                                                    />
                                                </Grid> 
                                            </>
                                        )
                                    )}
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <PaddedPaper style={{height: '100%'}}>
                                <Grid container spacing={3}> 
                                    <Grid item xs={7}>
                                        <Heading text={`Invoicing`} noMargin />
                                    </Grid>
                                    {(pod.pod_legacy === 1 && (_.isEmpty(pod.invoices)) && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            No Data Available
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            Legacy delivery invoice data is not available
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || ((pod.pod_status === 'Awaiting Submission' || pod.pod_status === 'Awaiting Confirmation') && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Not Confirmed Yet
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is subject to supplier confirmation
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.pod_status === 'Awaiting Despatch' && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Not {pod?.pod_collection === 1 ? `Ready` : `Despatched`} Yet
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is {pod?.pod_collection === 1 ? 'being prepared' : 'awaiting despatch'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (!pod?.invoice && pod.pod_delivered === 0 && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Not Delivered Yet
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery has not been received yet
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || ((_.isEmpty(po.invoices) && !pod?.invoice) && pod.pod_status === 'Invoiced' && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            {pod.pod_manual_complete === 1 ? `Manually Completed` : `Invoicing Skipped`}
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            {pod.pod_manual_complete === 1 ? `This delivery was manually completed` : `Invoicing was skipped for this delivery`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (_.isEmpty(po.invoices) && !pod?.invoice && (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='exclamation-circle' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            Not Invoiced Yet
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            This delivery is ready for invoicing
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )) || (pod.invoice && (
                                        (_.size(pod.invoices) > 1 && (
                                            <Grid item xs={12}>
                                                <DataTable  
                                                    config={{
                                                        key: 'poi_id',
                                                        alternatingRowColours: true,
                                                        inline: true,
                                                        noRowHover: true
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: 'Invoice',
                                                            field: rowData => (
                                                                <Box pt={1} pb={1}>
                                                                    <Typography variant="body2" className="fw-400">
                                                                        {`Invoice ${rowData.rowNumber}`}
                                                                    </Typography>
                                                                    <Typography variant="caption" component="div">
                                                                        {`Invoiced by ${staff?.[rowData.poi_staff_id]?.name ?? 'Unknown'}`}
                                                                    </Typography>
                                                                </Box>
                                                            ),
                                                            truncate: true
                                                        }, 
                                                        {
                                                            heading: 'Completed On',
                                                            field: rowData => rowData.poi_created_datetime,
                                                            fieldFormat: 'datetime',
                                                            sizeToContent: true
                                                        },      
                                                        {
                                                            actions: rowData => {
                                                                return [
                                                                    {name: 'View Invoice', icon: 'eye', onClick: () => deployDetailedView('invoice', rowData.rowIdx), doubleClick: true},
                                                                ]
                                                            }
                                                        }
                                                    ]}
                                                    rows={pod.invoices}
                                                />
                                            </Grid>
                                        )) || (
                                            <>
                                                <Grid item xs={5} align='right'>
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => deployDetailedView('invoice')}
                                                    >
                                                        <FAIcon icon="eye" size={15} button />
                                                        {ui?.device?.isTablet ? `Details` : `Detailed View`}
                                                    </Button>
                                                </Grid>   
                                                <Grid item xs={12} sm={6}>
                                                    <Tile
                                                        noPaper
                                                        color={'#4CAF50'}
                                                        icon={'check'}
                                                        title="Invoice Reference"
                                                        content={pod.invoice?.poi_supp_ref}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Tile
                                                        noPaper
                                                        color={'#4CAF50'}
                                                        icon={'check'}
                                                        title="Invoice Date"
                                                        content={momentFormatDate(pod.invoice?.poi_supp_date)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Tile
                                                        noPaper
                                                        color={pod.invoice?.poi_credit === "0.00" ? '#4CAF50' : '#f44336'}
                                                        icon={pod.invoice?.poi_credit === "0.00" ? 'check' : 'exclamation-triangle'}
                                                        title="Gross Variation"
                                                        content={currencyFormat.format(pod.invoice?.poi_credit)}
                                                    />
                                                </Grid> 
                                                <Grid item xs={12} sm={6}>
                                                    <StaffTile
                                                        minimal
                                                        noPaper
                                                        title="Invoiced By"
                                                        staffId={pod?.invoice?.poi_staff_id ?? null}
                                                        missingText='N/A'
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    )) || (
                                        <Grid item xs={12}>
                                            <Box mb={2} align='center'>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <FAIcon type="thin" icon='check' size={30} className="mb-1" noMargin />
                                                        <Typography variant="h6" className="fw-400">
                                                            {_.some(pod.products, el => parseInt(el.quantityInvoiced) > 0) ? `Group Invoiced` : `Not Invoiced Yet`}
                                                        </Typography>
                                                        <Typography variant="body1" className="fw-400">
                                                            {_.some(pod.products, el => parseInt(el.quantityInvoiced) > 0) ? `Please check the invoicing tab for more details` : `This delivery is ready for invoicing`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12}>
                <PaddedPaper>
                    <Heading text="Consignment Details" />
                    <Grid container justify="flex-end">
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                noBg
                                elevation={0}
                                padding={0}
                                rows={[ 
                                    {
                                        heading: "Supplier",
                                        value: po.supplier?.supp_company_name
                                    },
                                    {
                                        heading: "Trading Address",
                                        disabled: suppAddressEmpty,
                                        value: _.map(suppAddress, (line, idx) => {
                                            if(line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component='div'>
                                                        {line}
                                                    </Typography>
                                                )
                                            }
                                        })
                                    },
                                    {
                                        heading: "Contact Phone",
                                        value: po.supplier?.supp_phone
                                    },
                                    {
                                        heading: "Contact E-mail",
                                        value: po.supplier?.supp_email
                                    },
                                    {
                                        heading: "Supplier Reference",
                                        value: !_.isEmpty(po.po_supp_reference) ? po.po_supp_reference : 'N/A'
                                    },
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: "Invoice To",
                                        disabled: invoiceToEmpty,
                                        value: _.map(invoiceTo, (line, idx) => {
                                            if(line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component='div'>
                                                        {line}
                                                    </Typography>
                                                )
                                            }
                                        })
                                    },
                                    {
                                        heading: "Deliver To",
                                        disabled: deliveryToEmpty,
                                        value: _.map(deliveryTo, (line, idx) => {
                                            if(line?.length > 0) {
                                                return (
                                                    <Typography variant="body2" key={idx} component='div'>
                                                        {line}
                                                    </Typography>
                                                )
                                            }
                                        })
                                    },
                                    {
                                        heading: "Delivery Instructions",
                                        disabled: _.isEmpty(po.po_delivery_address_notes),
                                        value: (
                                            <Typography variant="body2" component='div'>
                                                {po.po_delivery_address_notes}
                                            </Typography>
                                        )
                                    }   
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StackTable
                                elevation={0}
                                padding={0}
                                rows={[
                                    {
                                        heading: `Despatch Method`,
                                        value: po.carriage? `${po.carriage?.supp_carr_courier} ${po.carriage?.supp_carr_service}` : 'Manual | See Notes'
                                    },
                                    {
                                        heading: `Despatch Type`,
                                        value: po.po_collection === 1 ? `Direct Collection` : (po.po_ship_aa_only === 1 ? `Ship Here` : `Direct Delivery`)
                                    },
                                    {
                                        heading: po.po_collection === 1 ? 'Ready To Collect' : 'Delivered',
                                        value: po.po_delivered === 1 ? 'Yes' : 'No'
                                    },
                                    {
                                        heading: 'Required For',
                                        value: po.po_type !== "Stock" || pod.delivered === 1 ? false : (!_.isEmpty(pod.orders) ? _.map(pod.orders, el => (<Link to={`/sales/${el.order_id}`} target="_blank"><Typography variant="caption" component="div" gutterBottom>Sales Order #{el.order_reference}</Typography></Link>)) : 'None')
                                    }
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} justify="flex-end">
                                {!_.isEmpty(pod.pod_notes) && (
                                    <Grid item xs={12}>
                                        <Box pt={2} pl={2} pr={2}>
                                            {pod.pod_notes.split('\n').map((item, key) => (
                                                <Typography variant="body2" key={key} align="center" className="fw-400" gutterBottom>{item}</Typography>
                                            ))}
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Products   
                                        classes={classes}
                                        inline={true}
                                        rows={pod.products}
                                        discountTotal={pod.pod_total_discount}
                                        subTotal={pod.pod_total_sub}
                                        netTotal={pod.pod_total_net}
                                        carriageTotal={pod.po_total_carriage}
                                        vatTotal={pod.pod_total_vat}
                                        grossTotal={pod.pod_total_gross}
                                        readOnly
                                        showDelivered
                                        showAllocationStatus
                                        status={pod.pod_status}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Totals
                                        classes={classes}
                                        aaVatPercent={po.vat?.vat_value_percentage}
                                        subTotal={pod.pod_total_cost}
                                        carriageTotal={pod.pod_total_carriage}
                                        netTotal={pod.pod_total_net}
                                        vatTotal={pod.pod_total_vat}
                                        grossTotal={pod.pod_total_gross}
                                        inForm={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Grid>
        </Grid>
    );
}

export default PurchaseOrderDeliverySummary;