import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import API from 'API';
import Action from 'Components/Common/Cards/Action';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { putActionStatus } from 'Redux/Actions/Notifications/Notifications';

const initialState = {
    action: {},
    isLoading: true
}

const ViewAction = ({id, callback, closeDialog, inDialog}) => {

    const [state, setState] = useState(initialState);

    const dispatch = useDispatch();
    const history = useHistory(); 
    const status = useSelector(state => state.notifications.status);
    const loggedInStaff = useSelector(state => state.staffAuth.staff);

    const isLoading = state.isLoading,
             action = state.action;

    const handleAction = (action) => {
        if(action) {
            action = action.split(":");
            switch(action[0]) {
                case "url":
                    history.push(action[1]);
                    closeDialog?.();
                break;
                default:
            }
        }
    }

    const handleDismiss = (id, e) => {
        e.stopPropagation();
        dispatch(putActionStatus(id));
        setTimeout(() => callback?.(), 500);
        closeDialog?.();
    }

    const handleDelete = (id, e) => {
        e.stopPropagation();
        dispatch(putActionStatus(id, true));
        setTimeout(() => callback?.(), 500);
        closeDialog?.();
    }

    useEffect(() => {
        API.get(`/misc/actions/${id}`, 
            {
                params: {
                    forView: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                setState({
                    action: res.data,
                    isLoading: false
                })
            }
        })
    }, [id]);

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <Action
                closeDialog={closeDialog}
                data={action}
                status={status}
                handleAction={handleAction}
                handleDismiss={handleDismiss}
                handleDelete={handleDelete}
                staffId={loggedInStaff?.id ?? 0}
                inDialog={inDialog}
                enhanced
            />
        )
    )
}

export default ViewAction;