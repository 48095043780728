import React from "react";
import moment from "moment";
import uuidv4 from "uuid/v4";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";

import API from "API";
import CrushedIce from "Assets/Logos/crushedice-light.png";
import ErrorDialog from "Components/Common/ErrorHandling/ErrorDialog";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Logo from "Assets/Images/logo_red.svg";
import Background from "Assets/Images/background.png";
import StoredProfile from "Components/Login/Main/StoredProfile";
import Theme from "Components/Common/Theme/Login";

import { API_URL, ENVIRONMENT, CLIENT_VERSION, RECAPTCHA_SITE_KEY } from "Constants";
import { formatValidationErrors } from "Helpers/ErrorHelper";
import { authStaff } from "Redux/Actions/Auth/Auth";
import { handleAppError } from "Redux/Actions/UI/UI";
import {
    setToken,
    getDecodedToken,
    hasStoredProfile,
    getStoredProfile,
    setStoredProfile,
    clearStoredProfile,
    setLastActivePage,
} from "Functions/AuthFunctions";

const styles = (theme) => ({
    root: {
        background: "#000",
        overflow: "hidden",
    },
    background: {
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    container: {
        background: "rgba(0,0,0,0.55)",
        position: "relative",
        padding: theme.spacing(15, 4),
    },
    logo: {
        paddingTop: ENVIRONMENT !== "PRODUCTION" ? 25 : undefined,
        width: 125,
        height: "auto",
        marginBottom: theme.spacing(5),
    },
    loading: {
        marginTop: "calc(50% - 35px)",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        maxWidth: 500,
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
    application: {
        position: "absolute",
        bottom: 24,
        color: "#fff",
        width: "calc(100% - 64px)",
        textAlign: "center",
    },
    builtByCi: {
        color: "#fff",
        fontSize: "0.8em",
        fontWeight: 300,
    },
    copyright: {
        color: "#fff",
        fontSize: "0.95em",
        paddingBottom: 2,
    },
    builtWith: {
        marginLeft: 4,
        marginRight: 4,
    },
    leftText: {
        width: "100%",
        textAlign: "left",
    },
    rightText: {
        width: "100%",
        textAlign: "right",
    },
    [theme.breakpoints.down("md")]: {
        rightText: {
            width: "100%",
            textAlign: "left",
        },
    },
    [theme.breakpoints.down("sm")]: {
        container: {
            padding: theme.spacing(5, 4, 0, 4),
        },
        form: {
            maxWidth: 320,
        },
        logo: {
            width: 115,
            marginBottom: theme.spacing(3),
        },
    },
});

const initialState = {
    appError: {
        key: uuidv4(),
        state: false,
        detail: "",
    },
    formData: {
        email: "",
        password: "",
        mfaCodeInd: {
            0: " ",
            1: " ",
            2: " ",
            3: " ",
            4: " ",
            5: " ",
        },
        mfaCode: "",
        storeProfile: false,
        googleRecaptchaToken: "",
    },
    formErrors: [],
    disableSubmit: false,
    storedProfile: false,
    isLoading: false,
    ipBanned: false,
    apiOffline: false,
    networkOffline: !navigator.onLine,
    action: "login",
    scheduledUpdateTime: false,
    updateCountdown: false,
    showPassword: false,
};

const LoginError = ({ title, message, action, ...props }) => (
    <Grid container spacing={1} style={{ marginTop: "20%" }}>
        <Grid item xs={12} align="center">
            <FAIcon
                type="light"
                icon="exclamation-triangle"
                size="xlarge"
                style={{ color: "#c62828" }}
                noMargin
                heading
            />
        </Grid>
        <Grid item xs={12} align="center">
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <Typography variant="caption" component="div" gutterBottom>
                {message}
            </Typography>
            <Typography variant="caption" style={{ fontSize: "0.75em" }} component="div" paragraph>
                For help and support, please contact Crushed Ice on 01933 664344
            </Typography>
            <Typography variant="caption" style={{ fontSize: "0.75em" }} component="div">
                <Button margin="normal" variant="text" color="secondary" onClick={action}>
                    Retry
                </Button>
            </Typography>
        </Grid>
    </Grid>
);

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.update = false;
        this.mfaCode0 = React.createRef();
        this.mfaCode1 = React.createRef();
        this.mfaCode2 = React.createRef();
        this.mfaCode3 = React.createRef();
        this.mfaCode4 = React.createRef();
        this.mfaCode5 = React.createRef();
        this.googleRecaptcha = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("online", this.handleNetworkOnline);
        window.addEventListener("offline", this.handleNetworkOffline);

        if (this.props.ui.updateNotification !== false) {
            this.setState(
                {
                    scheduledUpdateTime: this.props.ui.updateNotification,
                },
                () => {
                    this.startUpdateCountdown();
                }
            );
        }

        if (hasStoredProfile()) {
            const storedProfile = getStoredProfile();
            if (storedProfile.active || storedProfile.temp) {
                this.setState({
                    storedProfile: storedProfile,
                    formData: {
                        ...this.state.formData,
                        email: storedProfile.email,
                        storeProfile: storedProfile.active,
                    },
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.updateNotification !== prevProps.ui.updateNotification) {
            this.setState(
                {
                    scheduledUpdateTime: this.props.ui.updateNotification,
                },
                () => {
                    if (this.state.scheduledUpdateTime !== false) this.startUpdateCountdown();
                }
            );
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener("online", this.handleNetworkOnline);
        window.removeEventListener("offline", this.handleNetworkOffline);
        if (this.update) clearInterval(this.update);
    };

    startUpdateCountdown = () => {
        this.update = setInterval(this.updateCountdown, 1000);
    };

    updateCountdown = () => {
        const now = moment(),
            scheduled = moment(this.state.scheduledUpdateTime, "X").subtract(10, "seconds");

        if (now.unix() < scheduled.unix()) {
            const countdown = moment(scheduled - now),
                mins = parseInt(countdown.format("m")),
                countdownText =
                    mins !== 0 ? countdown.format("m [ minutes] [and] s [ seconds]") : countdown.format("s[ seconds]");
            this.setState({
                updateCountdown: this.state.updateNotification !== false ? countdownText : "",
            });
        } else {
            if (this.update) clearInterval(this.update);
            if (this.state.appError.state !== "APP_UPDATE") {
                setLastActivePage("/login");
                this.setState({
                    updateCountdown: "",
                    appError: {
                        ...this.state.appError,
                        state: "APP_UPDATE",
                        detail: "",
                    },
                });
            }
        }
    };

    serviceHealthCheck = () => {
        fetch(`${API_URL}/health-check`)
            .then((result) => {
                if (result.status === 200) {
                    if (this.state.apiOffline === true) {
                        this.setState({
                            apiOffline: false,
                        });
                    }
                } else {
                    if (this.state.apiOffline === false) {
                        this.setState({
                            apiOffline: true,
                        });
                    }
                }
            })
            .catch(() => {
                if (this.state.apiOffline === false) {
                    this.setState({
                        apiOffline: true,
                    });
                }
            });
    };

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleCheckedChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.checked,
            },
        });
    };

    handleTogglePassword = () => this.setState({ showPassword: !this.state.showPassword });

    handleClearStoredProfile = () => {
        clearStoredProfile();
        this.setState(
            {
                storedProfile: false,
                action: "login",
            },
            () => document.getElementById("email").focus()
        );
    };

    handleCloseErrorDialog = () => {
        this.setState({
            appError: {
                ...this.state.appError,
                state: false,
            },
        });
    };

    handleMfa = (e) => {
        if (e) e.preventDefault();
        const mfaCode = this.state.formData.mfaCodeInd;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    mfaCode: mfaCode[0] + mfaCode[1] + mfaCode[2] + mfaCode[3] + mfaCode[4] + mfaCode[5],
                },
            },
            () => this.handleSubmit()
        );
    };

    handleMfaChange = (id, e) => {
        if (id === 0 && e.target.value.length >= 1) {
            this.mfaCode1.current.focus();
        } else if (id > 0 && id < 5 && e.target.value.length >= 1) {
            this["mfaCode" + (id + 1)].current.focus();
        } else if (id > 0) {
            this["mfaCode" + (id - 1)].current.focus();
        }
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    mfaCodeInd: {
                        ...this.state.formData.mfaCodeInd,
                        [id]:
                            e.target.value.length > 0
                                ? e.target.value.trim().length === 1
                                    ? e.target.value.trim()
                                    : e.target.value.trim()[1]
                                : " ",
                    },
                },
            },
            () => {
                if (id === 5 && this.state.formData.mfaCodeInd[5] !== " ") {
                    this.handleMfa(false);
                }
            }
        );
    };

    handleNetworkOnline = () => {
        if (this.state.networkOffline === true) {
            if (hasStoredProfile()) {
                const storedProfile = getStoredProfile();
                if (storedProfile.active || storedProfile.temp) {
                    this.setState(
                        {
                            storedProfile: storedProfile,
                            formData: {
                                email: storedProfile.email,
                                storeProfile: storedProfile.active,
                            },
                            networkOffline: false,
                        },
                        () => this.serviceHealthCheck()
                    );
                }
            } else {
                this.setState(
                    {
                        networkOffline: false,
                    },
                    () => {
                        this.serviceHealthCheck();
                    }
                );
            }
        }
    };

    handleNetworkOffline = () => {
        if (this.state.networkOffline === false) {
            this.setState({
                networkOffline: true,
                formData: {
                    email: "",
                    password: "",
                    googleRecaptchaToken: "",
                },
            });
        }
    };

    handleNetworkRetry = () => {
        if (navigator.onLine) {
            this.handleNetworkOnline();
        }
    };

    handleRecaptcha = async () => {
        const googleRecaptchaToken = await this.googleRecaptcha.current.executeAsync();
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    googleRecaptchaToken,
                },
                disableSubmit: true,
            },
            () => {
                this.handleSubmit();
            }
        );
    };

    handleRetryIpBan = () => {
        this.setState({
            ipBanned: false,
        });
    };

    handleSubmit = () => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                API.post("/auth/login", this.state.formData).then((result) => {
                    if (result && result.data) {
                        if (result.data.errors) {
                            this.setState(
                                {
                                    formErrors: formatValidationErrors(result.data.errors),
                                    disableSubmit: false,
                                    isLoading: false,
                                    formData: {
                                        ...this.state.formData,
                                        mfaCode: "",
                                        mfaCodeInd: {
                                            0: " ",
                                            1: " ",
                                            2: " ",
                                            3: " ",
                                            4: " ",
                                            5: " ",
                                        },
                                    },
                                },
                                () => {
                                    if (this.state.formErrors["mfaCode"]) {
                                        this.mfaCode0.current.focus();
                                    }
                                }
                            );
                        } else if (result.data.ip_locked) {
                            this.setState({
                                action: "login",
                                ipBanned: true,
                                isLoading: false,
                                disableSubmit: false,
                                formErrors: [],
                            });
                        } else if (result.data.mfa_required) {
                            this.setState(
                                {
                                    action: "mfa",
                                    isLoading: false,
                                    disableSubmit: false,
                                    formErrors: [],
                                },
                                () => this.mfaCode0.current.focus()
                            );
                        } else {
                            const token = result.data.token;
                            setToken(token);

                            API.get("/staff/my/password/requiresUpdate").then((res) => {
                                const profile = getDecodedToken();

                                setStoredProfile({
                                    active: this.state.formData.storeProfile ? true : false,
                                    temp: false,
                                    firstName: profile.firstName,
                                    lastName: profile.lastName,
                                    email: profile.email,
                                    avatar: profile.securePicture,
                                });

                                if (res && res.data) {
                                    if (res.data.requires_update) {
                                        this.props.handleAppError("PASSWORD_UPDATE", "");
                                    }
                                }

                                this.props.authStaff(token);

                                // if(hasLastActivePage()) {
                                //     const path = getLastActivePage()
                                //     clearLastActivePage()
                                //     this.props.history.replace(path)
                                // } else {
                                this.props.history.replace("/dashboard");
                                // }
                            });
                        }
                    }
                });
            }
        );
    };

    render() {
        const {
            classes,
            ui,
            ui: {
                device: { isMobile },
            },
            switchable,
        } = this.props;
        const {
            action,
            apiOffline,
            appError,
            formData,
            formErrors,
            ipBanned,
            isLoading,
            networkOffline,
            showPassword,
            storedProfile,
            scheduledUpdateTime,
            updateCountdown,
        } = this.state;
        return (
            <ThemeProvider theme={Theme}>
                <CssBaseline />
                <Grid
                    container
                    className={`${classes.root} ${(!isMobile || ui?.device?.isTablet) && classes.background}`}
                    style={{ height: ui?.device?.height }}
                >
                    {!isMobile && window.innerHeight < window.innerWidth && (
                        <Grid item md={ui?.device?.isTablet ? 8 : 7} lg={8} xl={9} />
                    )}
                    <Grid
                        item
                        xs={12}
                        md={window.innerHeight < window.innerWidth ? (ui?.device?.isTablet ? 4 : 5) : 12}
                        lg={window.innerHeight < window.innerWidth ? 4 : 12}
                        xl={window.innerHeight < window.innerWidth ? 3 : 12}
                        className={classes.container}
                        style={{ paddingTop: ui?.device?.isTablet ? 36 : undefined }}
                        component={Paper}
                        align="center"
                    >
                        <img
                            alt="RED"
                            src={Logo}
                            className={classes.logo}
                            style={{
                                width: ui?.device?.isTablet ? 100 : undefined,
                                marginBottom: ui?.device?.isTablet ? 0 : undefined,
                            }}
                        />
                        {(isLoading && <LoadingCircle size="large" className={classes.loading} />) || (
                            <>
                                {scheduledUpdateTime !== false && updateCountdown !== false && (
                                    <Grid container spacing={1} style={{ maxWidth: 500 }} align="center">
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                style={{ color: "#ef3340" }}
                                                gutterBottom
                                            >
                                                <FAIcon
                                                    type="light"
                                                    icon="exclamation-triangle"
                                                    size="small"
                                                    style={{ color: "#ef3340" }}
                                                    heading
                                                />
                                                {(updateCountdown !== "" &&
                                                    `An update ${
                                                        !isMobile ? `for RED ` : ``
                                                    } will begin in ${updateCountdown}!`) || (
                                                    <>An update for RED is currently in progress!</>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {(apiOffline && (
                                    <LoginError
                                        title="Temporarily Unavailable"
                                        message="RED is currently undergoing maintenance, please try again soon!"
                                        action={this.serviceHealthCheck}
                                    />
                                )) ||
                                    (networkOffline && (
                                        <LoginError
                                            title="Network Offline"
                                            message="Please check your internet connection and try again"
                                            action={this.handleNetworkRetry}
                                        />
                                    )) ||
                                    (ipBanned && (
                                        <LoginError
                                            title="IP Address Banned"
                                            message="You cannot login at this time due to multiple failed attempts"
                                            action={this.handleRetryIpBan}
                                        />
                                    )) || (
                                        <>
                                            {(action === "login" && (
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        this.handleRecaptcha();
                                                    }}
                                                    className={classes.form}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <Grid container>
                                                        {storedProfile && (
                                                            <Grid item xs={12}>
                                                                <StoredProfile darkTheme staffData={storedProfile} />
                                                            </Grid>
                                                        )}
                                                        {this.state.formErrors.googleRecaptchaToken && (
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" color="error" paragraph>
                                                                    {this.state.formErrors.googleRecaptchaToken}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {this.state.formErrors.generic && (
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" color="error" paragraph>
                                                                    {this.state.formErrors.generic}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                autoComplete="off"
                                                                autoFocus
                                                                error={formErrors && formErrors["email"] && true}
                                                                fullWidth
                                                                helperText={formErrors && formErrors["email"]}
                                                                id="email"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label="Email Address"
                                                                margin="normal"
                                                                name="email"
                                                                onChange={this.handleChange}
                                                                style={{
                                                                    display: storedProfile && "none",
                                                                }}
                                                                value={formData.email}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                autoComplete="new-password"
                                                                fullWidth
                                                                error={formErrors && formErrors["password"] && true}
                                                                helperText={formErrors && formErrors["password"]}
                                                                id="password"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment
                                                                            position="end"
                                                                            onClick={this.handleTogglePassword}
                                                                        >
                                                                            <FAIcon
                                                                                icon={
                                                                                    showPassword ? "eye-slash" : "eye"
                                                                                }
                                                                                type="solid"
                                                                                noMargin
                                                                                button
                                                                                color="white"
                                                                            />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                label="Password"
                                                                margin="normal"
                                                                name="password"
                                                                onChange={this.handleChange}
                                                                type={showPassword ? "text" : "password"}
                                                                value={formData.password}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} align="left">
                                                            <ReCAPTCHA
                                                                ref={this.googleRecaptcha}
                                                                size="invisible"
                                                                sitekey={RECAPTCHA_SITE_KEY}
                                                            />
                                                            {!storedProfile && (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            color="primary"
                                                                            name="storeProfile"
                                                                            onChange={this.handleCheckedChange}
                                                                            value="remember"
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Typography variant="caption">
                                                                            Remember me
                                                                        </Typography>
                                                                    }
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                className={classes.button}
                                                                color="primary"
                                                                fullWidth
                                                                type="submit"
                                                                variant="contained"
                                                            >
                                                                <FAIcon
                                                                    icon="sign-in"
                                                                    type="solid"
                                                                    size={15}
                                                                    buttonPrimary
                                                                />
                                                                Sign In
                                                            </Button>
                                                        </Grid>
                                                        {switchable && (
                                                            <Grid item xs={12} lg={6} align="left">
                                                                <Link
                                                                    onClick={() =>
                                                                        this.props.handleModeChange("Warehouse")
                                                                    }
                                                                    variant="body2"
                                                                    className="link"
                                                                >
                                                                    <Typography variant="caption" component="div">
                                                                        Warehouse Login
                                                                    </Typography>
                                                                </Link>
                                                            </Grid>
                                                        )}
                                                        {storedProfile && (
                                                            <Grid item xs={12} lg={6}>
                                                                <Link
                                                                    href="#"
                                                                    onClick={this.handleClearStoredProfile}
                                                                    variant="body2"
                                                                >
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="div"
                                                                        className={
                                                                            switchable
                                                                                ? classes.rightText
                                                                                : classes.leftText
                                                                        }
                                                                    >
                                                                        {`Not ${storedProfile.firstName} ${storedProfile.lastName}? Logout`}
                                                                    </Typography>
                                                                </Link>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </form>
                                            )) || (
                                                <form
                                                    onSubmit={this.handleMfa}
                                                    className={classes.form}
                                                    autoComplete="off"
                                                    noValidate
                                                >
                                                    {storedProfile && (
                                                        <StoredProfile darkTheme staffData={storedProfile} />
                                                    )}
                                                    <Grid container spacing={isMobile ? 1 : 3}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption">
                                                                Please enter your multi-factor authentication code
                                                            </Typography>
                                                        </Grid>
                                                        {formErrors.mfaCode && (
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" color="error">
                                                                    {formErrors.generic && (
                                                                        <>
                                                                            {formErrors.generic}
                                                                            <br />
                                                                        </>
                                                                    )}
                                                                    {formErrors.mfaCode}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-0"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className="mfaInput"
                                                                value={formData.mfaCodeInd[0]}
                                                                error={formErrors && formErrors["mfaCode"] && true}
                                                                onChange={(e) => this.handleMfaChange(0, e)}
                                                                inputProps={{
                                                                    pattern: "[0-9]*",
                                                                    ref: this.mfaCode0,
                                                                    size: 2,
                                                                    maxLength: 2,
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-1"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className="mfaInput"
                                                                value={formData.mfaCodeInd[1]}
                                                                error={formErrors && formErrors["mfaCode"] && true}
                                                                onChange={(e) => this.handleMfaChange(1, e)}
                                                                inputProps={{
                                                                    pattern: "[0-9]*",
                                                                    ref: this.mfaCode1,
                                                                    size: 2,
                                                                    maxLength: 2,
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-2"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                name="mfaCode3"
                                                                autoComplete="off"
                                                                className="mfaInput"
                                                                value={formData.mfaCodeInd[2]}
                                                                error={formErrors && formErrors["mfaCode"] && true}
                                                                onChange={(e) => this.handleMfaChange(2, e)}
                                                                inputProps={{
                                                                    pattern: "[0-9]*",
                                                                    ref: this.mfaCode2,
                                                                    size: 2,
                                                                    maxLength: 2,
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-3"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className="mfaInput"
                                                                value={formData.mfaCodeInd[3]}
                                                                error={formErrors && formErrors["mfaCode"] && true}
                                                                onChange={(e) => this.handleMfaChange(3, e)}
                                                                inputProps={{
                                                                    pattern: "[0-9]*",
                                                                    ref: this.mfaCode3,
                                                                    size: 2,
                                                                    maxLength: 2,
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-4"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className="mfaInput"
                                                                value={formData.mfaCodeInd[4]}
                                                                error={formErrors && formErrors["mfaCode"] && true}
                                                                onChange={(e) => this.handleMfaChange(4, e)}
                                                                inputProps={{
                                                                    pattern: "[0-9]*",
                                                                    ref: this.mfaCode4,
                                                                    size: 2,
                                                                    maxLength: 2,
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="mfa-5"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                className="mfaInput"
                                                                value={formData.mfaCodeInd[5]}
                                                                error={formErrors && formErrors["mfaCode"] && true}
                                                                onChange={(e) => this.handleMfaChange(5, e)}
                                                                inputProps={{
                                                                    pattern: "[0-9]*",
                                                                    ref: this.mfaCode5,
                                                                    size: 2,
                                                                    maxLength: 2,
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Button
                                                        className={classes.button}
                                                        color="primary"
                                                        fullWidth
                                                        onClick={this.handleSubmit}
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        <FAIcon icon="sign-in" type="solid" size={15} buttonPrimary />
                                                        Authenticate
                                                    </Button>
                                                    <Grid container>
                                                        {switchable && (
                                                            <Grid item xs={12} lg={6} align="left">
                                                                <Link
                                                                    onClick={() =>
                                                                        this.props.handleModeChange("Warehouse")
                                                                    }
                                                                    variant="body2"
                                                                    className="link"
                                                                >
                                                                    <Typography variant="caption" component="div">
                                                                        Warehouse Login
                                                                    </Typography>
                                                                </Link>
                                                            </Grid>
                                                        )}
                                                        {storedProfile && (
                                                            <Grid item xs={12} lg={6}>
                                                                <Link
                                                                    href="#"
                                                                    onClick={this.handleClearStoredProfile}
                                                                    variant="body2"
                                                                >
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="div"
                                                                        className={
                                                                            switchable
                                                                                ? classes.rightText
                                                                                : classes.leftText
                                                                        }
                                                                    >
                                                                        {`Not ${storedProfile.firstName} ${storedProfile.lastName}? Logout`}
                                                                    </Typography>
                                                                </Link>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </form>
                                            )}
                                        </>
                                    )}
                                <Box className={classes.application}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.copyright}>
                                                RED v{CLIENT_VERSION}. Copyright &copy; {moment().year()} Advanced
                                                Access Ltd
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Link
                                                color="inherit"
                                                href="http://www.crushedice.biz"
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                <Grid container alignItems="center" justify="center">
                                                    <Grid item>
                                                        <img
                                                            className={classes.builtWith}
                                                            src={CrushedIce}
                                                            alt="Crushed Ice"
                                                            width={11}
                                                            height={11}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.builtByCi}>
                                                            Built by Crushed Ice
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>
                {appError.state !== false && (
                    <ErrorDialog
                        key={appError.key}
                        state={appError.state}
                        detail={appError.detail}
                        history={this.props.history}
                        closeError={() => this.handleCloseErrorDialog()}
                    />
                )}
            </ThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        ui: state.ui,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        authStaff: (token) => dispatch(authStaff(token)),
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Login)));
