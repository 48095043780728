import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import RagTile from 'Components/Common/Tiles/RagTile';

const useStyles = makeStyles({
    paper: {
        marginTop: 'env(safe-area-inset-top)',
        width: '100%',
        maxWidth: 750,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)'
    },
    smallHeaderPaper: {
        marginTop: 'env(safe-area-inset-top)',
        width: '100%',
        maxWidth: 750,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)'
    }
})

const WarehouseAnalytics = ({open, smallHeader, toggleDrawer}) => {

    const badges = useSelector(state => state.notifications.badges);
    const ui = useSelector(state => state.ui);
    const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
    const classes = useStyles();

    const Statistic = ({content, title, rag}) => (
        <Grid item xs={12}>
            <Box pl={3}>
                <RagTile
                    rag={rag}
                    title={title}
                    content={content}
                />
            </Box>
        </Grid>
    )
    return (
        <Drawer
            anchor="right"
            classes={{
                paper: smallHeader ? classes.smallHeaderPaper : classes.paper,
            }}
            open={open}
            style={{height: height, position: 'relative'}}
            variant='temporary'
        >
            <div style={{position: 'absolute', right: 15, top: 13}}>
                <IconButton
                    onClick={() => toggleDrawer('analytics')}
                >
                    <FAIcon icon="times" noMargin />
                </IconButton>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} align='center'>
                    <Box p={3} pb={0}>
                        <Typography variant="h4">
                            Warehouse Insight
                        </Typography>
                    </Box>
                </Grid>
                {(!_.isEmpty(badges) && (
                    ((typeof badges['wh-awaiting-picking'] !== "undefined" && typeof badges['wh-awaiting-despatch'] !== "undefined" && typeof badges['wh-ready-for-collection-orders'] !== "undefined" && typeof badges['wh-awaiting-delivery'] !== "undefined") && (
                        <>
                            <Grid item xs={12}>
                                <Box pl={2}>
                                    <Typography variant="h6">
                                        Outgoing Deliveries
                                    </Typography>
                                </Box>
                            </Grid>
                            <Statistic 
                                content={`${badges['wh-awaiting-picking']}`} 
                                title="Awaiting Picking"
                                rag={badges['wh-awaiting-picking'] > 15 ? 'red' : (badges['wh-awaiting-picking'] > 0 ? 'amber' : 'green')}
                            />
                            <Statistic 
                                content={`${badges['wh-awaiting-check']}`} 
                                title="Awaiting Check"
                                rag={badges['wh-awaiting-check'] > 15 ? 'red' : (badges['wh-awaiting-check'] > 0 ? 'amber' : 'green')}
                            />
                            <Statistic 
                                content={`${badges['wh-awaiting-despatch']}`} 
                                title="Awaiting Despatch"
                                rag={badges['wh-awaiting-despatch'] > 15 ? 'red' : (badges['wh-awaiting-despatch'] > 0 ? 'amber' : 'green')}
                            />
                            <Statistic 
                                content={`${badges['wh-ready-for-collection-orders']}`} 
                                title="Ready for Collection"
                                rag={badges['wh-ready-for-collection-orders'] > 20 ? 'red' : (badges['wh-ready-for-collection-orders'] > 15 ? 'amber' : 'green')}
                            />
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Box pl={2}>
                                    <Typography variant="h6">
                                        Incoming Deliveries
                                    </Typography>
                                </Box>
                            </Grid>
                            <Statistic 
                                content={`${badges['wh-awaiting-delivery']}`} 
                                title="Expected"
                                rag={'green'}
                                // rag={badges['wh-awaiting-delivery'] > 20 ? 'red' : (badges['wh-awaiting-delivery'] > 0 ? 'amber' : 'green')}
                            />
                        </>
                    )) || (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Sorry, you do not have access to warehouse statistics
                            </Alert>
                        </Grid>
                    )
                )) || (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )}
            </Grid>
        </Drawer>
    )

}

export default WarehouseAnalytics;