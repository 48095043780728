import _ from "lodash";
import * as NotificationActionTypes from "Redux/Actions/Notifications/Types/Types";
import { ENVIRONMENT } from "Constants";
import { isCrushedIce } from "Functions/AuthFunctions";

const initialState = () => ({
    la: 0,
    ac: [],
    ln: 0,
    no: [],
    actions: {},
    notifications: {},
    badges: {},
    config: null,
    discount: null,
    status: {},
    staffList: [],
    teams: {},
    teamList: [],
});

function NotificationsReducer(state = initialState(), action) {
    switch (action.type) {
        case NotificationActionTypes.CLEAR_USER_DATA:
            return initialState();
        case NotificationActionTypes.LOAD_STAFF_CONFIG:
            return {
                ...state,
                config: action.payload,
            };
        case NotificationActionTypes.LOAD_STAFF_DISCOUNT:
                return {
                    ...state,
                    discount: action.payload,
                };
        case NotificationActionTypes.LOAD_STAFF_STATUS:
            const isCI = isCrushedIce();
            return {
                ...state,
                status: action.payload,
                staffList: _.map(
                    _.orderBy(_.filter(
                        action.payload,
                        (el) =>
                            el.act === 1 && 
                            ((ENVIRONMENT === "PRODUCTION" && el.ci !== 1) ||
                            (ENVIRONMENT === "PRODUCTION" && isCI) ||
                            ENVIRONMENT !== "PRODUCTION")
                    ), ['name'], ['asc']),
                    (staff) =>
                        _.assign({
                            label: staff.name,
                            value: staff.id,
                        })
                ),
            };
        case NotificationActionTypes.LOAD_TEAMS:
            let teams = {};
            _.each(action.payload, (team) => {
                teams = {
                    ...teams,
                    [team.team_id]: {
                        id: team.team_id,
                        name: team.team_name,
                        email: team.team_email,
                        color: team.team_color,
                    },
                };
            });
            return {
                ...state,
                teams,
                teamList: _.map(action.payload, (team) => {
                    return _.assign({
                        label: team.team_name,
                        value: team.team_id,
                        isTeam: 1,
                    });
                }),
            };
        case NotificationActionTypes.I_AM_ACTIVE:
            if (state.status[action.payload]?.status !== 2) {
                return {
                    ...state,
                    status: {
                        ...state.status,
                        [action.payload]: {
                            ...state.status[action.payload],
                            status: 2,
                        },
                    },
                };
            } else {
                return state;
            }
        case NotificationActionTypes.MINUS_UNREAD_MESSAGE:
            let count = state.badges["unread-messages"] - action.payload;
            if (count < 0) {
                count = 0;
            }
            return {
                ...state,
                badges: {
                    ...state.badges,
                    "unread-messages": count,
                },
            };
        case NotificationActionTypes.HANDLE_API_PULSE:
                if (action.payload.la !== state.la) {
                    state = {
                        ...state,
                        la: action.payload.la,
                    };
                }
                if (action.payload.ln !== state.ln) {
                    state = {
                        ...state,
                        ln: action.payload.ln,
                    };
                }
                if (!_.isEmpty(action.payload.notifications)) {
                    state = {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            ...action.payload.notifications,
                        },
                    };
                }
                if (JSON.stringify(action.payload.no) !== JSON.stringify(state.no)) {
                    state = {
                        ...state,
                        no: action.payload.no,
                    };
                    if (!_.isEmpty(state.notifications)) {
                        _.each(state.notifications, (n) => {
                            if (!action.payload?.no?.includes?.(n.i)) {
                                state = {
                                    ...state,
                                    notifications: _.omit(state.notifications, n.i),
                                };
                            }
                        });
                    }
                }
                if (!_.isEmpty(action.payload?.actions)) {
                    state = {
                        ...state,
                        actions: {
                            ...state.actions,
                            ...action.payload.actions,
                        },
                    };
                }
                if (JSON.stringify(action.payload?.ac) !== JSON.stringify(state.ac)) {
                    state = {
                        ...state,
                        ac: action.payload.ac,
                    };
                    if (!_.isEmpty(state.actions)) {
                        _.each(state.actions, (a) => {
                            if (!action.payload.ac?.includes?.(a.i)) {
                                state = {
                                    ...state,
                                    actions: _.omit(state.actions, a.i),
                                };
                            }
                        });
                    }
                }
                return state;
            case NotificationActionTypes.HANDLE_RTAPI_PULSE:
                if (JSON.stringify(action.payload.badges) !== JSON.stringify(state.badges)) {
                    state = {
                        ...state,
                        badges: action.payload.badges,
                    };
                }
                Object.keys(action.payload.status).forEach((staffId) => {
                    if (state.status?.[staffId]?.status !== action.payload?.status?.[staffId]) {
                        state = {
                            ...state,
                            status: {
                                ...state.status,
                                [staffId]: {
                                    ...state.status[staffId],
                                    status: action.payload.status[staffId],
                                },
                            },
                        };
                    }
                });
            return state;
        case NotificationActionTypes.DISCARD_NOTIFICATION:
            if (state.notifications[action.payload]) {
                state = {
                    ...state,
                    notifications: _.omit(state.notifications, action.payload),
                };
            }
            return state;
        case NotificationActionTypes.DISCARD_ALL_NOTIFICATIONS:
            state = {
                ...state,
                notifications: initialState().notifications,
            };
            return state;
        case NotificationActionTypes.DISCARD_ACTION:
            if (state.actions[action.payload]) {
                let ac = state.ac.split(",");
                let idx = _.findIndex(ac, function (el) {
                    return el === action.payload.toString();
                });
                if (idx >= 0) {
                    ac.splice(idx, 1);
                    state = {
                        ...state,
                        ac: ac.join(","),
                        actions: _.omit(state.actions, action.payload),
                    };
                }
            }
            return state;
        default:
            return state;
    }
}

export default NotificationsReducer;
