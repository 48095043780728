import _axios from 'axios';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

import MainStore from 'Redux/MainStore';
import { finishLoading, startLoading } from 'Redux/Actions/Loading/Loading';
import { API_URL } from 'Constants';
import { handleError } from 'Redux/Actions/UI/UI';
import { handleApiPulse, handleIamActive } from 'Redux/Actions/Notifications/Notifications';
import { clearLastActivePage, clearToken, forceStoredProfile, getDecodedToken, loggedIn, getToken, setLastActivePage } from 'Functions/AuthFunctions';

const axios = _axios.create({
    baseURL: API_URL,
    withCredentials: true
})

axios.defaults.timeout = 600000;

axios.defaults.maxContentLength = Infinity;

axios.interceptors.request.use(request => {
    /*
     * Send token if getToken() returns one (user is logged in)
     */
    const token = getToken();
    if (token) {
        request.headers.Authorization = 'Bearer ' + token;
    }
    return request;
}, (error) => {
    return Promise.reject(error);
});

/*
 * Check for update
 */
axios.interceptors.response.use(response => {
    if(response?.config?.props?.handleApiPulse) {
        if(response.data) {
            let errorState = MainStore.getState().ui?.appError?.state ?? false;
            if(errorState && errorState === "TOKEN_EXPIRED") {
                MainStore.dispatch(handleError(false, ""))
            }
            MainStore.dispatch(handleApiPulse(response.data))
        }
    } else {
        if(loggedIn()) {
            const decodedToken = getDecodedToken();
            if(MainStore.getState().notifications?.status?.[decodedToken.id]?.status !== 2) {
                MainStore.dispatch(handleIamActive(decodedToken.id));
            }
        }
    }
    return response;
}, (error) => {
    return Promise.reject(error);
});

/*
 * Handle Loading Bar
 */
axios.interceptors.request.use(config => {
    config.requestId = uuidv4()    
    if(config.props && config.props.noLoading) {
        config.loadingBar = false;
    } else {
        config.loadingBar = true;
        MainStore.dispatch(startLoading(config.requestId));
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
axios.interceptors.response.use(response => {
    if(response && response.config && response.config.loadingBar === true) {
        MainStore.dispatch(finishLoading(response.config.requestId));
    }
    return response;
}, (error) => {
    if(error && error.config && error.config.loadingBar === true) {
        MainStore.dispatch(finishLoading(error.config.requestId));
    }
    return Promise.reject(error);
});

class API {
    constructor() {
        this.lastPath = null;
        this.lastRequestCancellation = null;
    }
    
    url = API_URL;

    cancel = () => {
        if(this.lastRequestCancellation) {
            this.lastRequestCancellation.cancel('Duplicate Search Request Cancelled');
            this.lastRequestCancellation = null;
            this.lastPath = null;
        }
    }
    
    get = (path, config = {}) => {  
        if(config && config.props && config.props.cancellation) {

            /* Check for duplicate requests */
            if(path === this.lastPath) {
                this.lastRequestCancellation.cancel('Duplicate Search Request Cancelled');
            } 

            /* Set last request variables */
            this.lastPath = path
            this.lastRequestCancellation = _axios.CancelToken.source();

            /* Attach request cancellation token */
            config = {
                ...config,
                cancelToken: this.lastRequestCancellation.token
            }
        }        

        /* Send request */
        return axios.get(API_URL + path, config).catch(this.handleError);
    }
    
    post = (path, data = {}, config = {}) => {        
        return axios.post(API_URL + path, data, config).catch(this.handleError);
    }

    put = (path, data = {}, config = {}) => {
        return axios.put(API_URL + path, data, config).catch(this.handleError);
    }

    access = (reference, accessState = false, bool = false) => {
        const self = this;
        return new Promise(function(resolve) {
            let access = accessState;
            if(!access) {
                access = MainStore.getState().staffAuth?.access;
            }
            if(!_.isEmpty(access)) {
                if(access.includes(reference)) {
                    if(bool) {
                        resolve(true)
                    } else {
                        resolve({
                            data: {
                                has_access: true
                            }
                        })
                    }
                } else {
                    if(bool) {
                        resolve(false)
                    } else {
                        resolve({
                            data: {
                                has_access: false
                            }
                        })
                    }
                }
            } else {
                self.get(`/staff/my/access/check/${reference}`)
                .then(res => {
                    if(res?.data) {
                        if(res.data?.has_access) {
                            if(bool) {
                                resolve(true)
                            } else {
                                resolve({
                                    data: {
                                        has_access: true
                                    }
                                })
                            }
                        } else {
                            if(bool) {
                                resolve(false)
                            } else {
                                resolve({
                                    data: {
                                        has_access: false
                                    }
                                })

                            }
                        }
                    } else {
                        if(bool) {
                            resolve(false)
                        } else {
                            resolve({
                                data: {
                                    has_access: false
                                }
                            })
                        }
                    }
                })
            }
        })
    }

    multiAccess = references => {
        const self = this;
        const access = MainStore.getState().staffAuth?.access;
        return Promise.all(_.map(references, ref => self.access(ref, access, true)));
    }

    handleError = err => {
        if(!_axios.isCancel(err)) {
            forceStoredProfile();
            setLastActivePage(window.location.pathname);
            let errorState = MainStore.getState().ui?.appError?.state ?? false;
            if(!errorState) {
                if((err.config && err.config.props && !err.config.props.ignoreErrors) || (err.config && !err.config.props) || err?.response?.status === 409) {
                    if(err.response) {
                        switch(err.response.status) {
                            /* Bad request */
                            case 400:
                                MainStore.dispatch(handleError("BAD_REQUEST", err))
                            break;
                            /* Unauthorised */
                            case 401:
                                clearToken();
                                window.location = '/login'       
                            break;
                            /* Forbidden */
                            case 403:
                                MainStore.dispatch(handleError("FORBIDDEN", err))
                            break;
                            /* Endpoint not found */
                            case 404:
                                clearLastActivePage();
                                MainStore.dispatch(handleError("API_NOT_FOUND", err))
                            break;
                            /* Conflict */
                            case 409:
                                MainStore.dispatch(handleError("APP_UPDATE", err))
                            break;
                            /* Internal server error */
                            case 500:
                                MainStore.dispatch(handleError("INTERNAL_SERVER_ERROR", err))
                            break;
                            /* Service temporarily unavailable */
                            case 503:
                                MainStore.dispatch(handleError("SERVICE_UNAVAILABLE", err))
                            break;
                            /* Other http error */
                            default:
                                /* No action */
                            break;
                        }
                    } else {
                        MainStore.dispatch(handleError("UNKNOWN_ERROR", err))
                    }
                } else {
                    if(err.response && err.response.status && err.response.status === 401) {
                        MainStore.dispatch(handleError("TOKEN_EXPIRED", ""))    
                    }
                    if(err?.config?.props?.handleApiPulse && err?.response?.status === 400) {
                        clearToken();
                        window.location = '/login' 
                    }
                }
            }
        }
    }
}

export default new API();