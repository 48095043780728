import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import logo from 'Assets/Images/no-img.jpg';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import Alert from 'Components/Common/Alert/Alert';
import StatusChip from 'Components/Common/Chips/StatusChip';
import DataTable from 'Components/Common/DataTables/DataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Highlight from 'Components/Common/Highlight/Highlight';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PurchaseOrderInvoice from 'Components/Purchases/Actions/PurchaseOrderInvoice';
import PurchaseOrderSummary from 'Components/Purchases/Misc/PurchaseOrderSummary';
import SendEmailNotifications from 'Components/Communication/Misc/SendEmailNotifications';
import { CLOUDFRONT_URL } from 'Constants';
import {
    momentFormatDate
} from 'Functions/MiscFunctions';

const CheckDateDialog = ({cancel, deployConfirmation, pod, submit}) => {
    const [date, setDate] = useState(null);
    const [initialDate, setInitialDate] = useState(null);
    useEffect(() => {
        if(pod.pod_check_date !== "0000-00-00") {
            setDate(pod.pod_check_date === "0000-00-00" ? null : pod.pod_check_date);
            setInitialDate(pod.pod_check_date);
        }
    }, [pod]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} align='center'>
                <Box pt={4}>
                    <FAIcon icon="calendar-exclamation" type="thin" size={50} noMargin />
                </Box>
            </Grid>
            <Grid item xs={12} align='center'>
                <Typography variant="h6">
                    Manage Check Date
                </Typography>
            </Grid>
            <Grid item xs={12}>                
                <Box ml={4} mr={4} mt={2} mb={3}>
                    <Box className="content-light-grey" p={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <Typography variant="h6">
                                    #{pod.pod_reference}/{pod.pod_idx}
                                </Typography>
                                <Typography variant="body2">
                                    {(pod.pod_check_date === "0000-00-00" && (
                                        'No check date is currently set'
                                    )) || (
                                        `Check due on ${moment(pod.pod_check_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Grid container spacing={1} alignItems="center" justify="center">
                                    <Grid item>
                                        <FormControl fullWidth style={{width: 135}}>
                                            <DatePicker
                                                type="date"
                                                value={date}
                                                onChange={date => setDate(moment(date).format('YYYY-MM-DD'))}
                                                autoOk={true}
                                                disablePast={true}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Remove">
                                            <IconButton
                                                onClick={() => setDate(null)}
                                                size="small"
                                            >
                                                <FAIcon icon="times" size={15} noMargin button />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <Button onClick={cancel}
                                variant="text"
                        >
                            <FAIcon icon="times" size={15} button />
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to {date ? 'set' : 'remove'} the check date?</Typography>{(!date && (<Typography variant="body2">This will be marked as despatched on the expected despatch date</Typography>)) || (<Typography variant="body2">This will require another review on the check date chosen</Typography>)}</>, () => submit(pod.pod_id, date))}
                            variant="text" 
                            color="primary" 
                            disabled={initialDate === date}
                        >
                            <FAIcon icon="check" size={15} button />
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


const ExpectedDespatchDateDialog = ({cancel, deployConfirmation, pod, submit}) => {
    const [date, setDate] = useState(null);
    const [initialDate, setInitialDate] = useState(null);
    const handleSubmit = () => {
        if(moment(date).isBefore(moment()) && (!pod?.od || (pod?.od && pod?.od?.od_on_hold === 0))) {
            deployConfirmation(<><Typography variant="body2">This Purchase Order Delivery will be marked as Despatched</Typography><Typography variant="body2" className="textError">Are you sure you want to continue?</Typography></>, () => submit(pod.pod_id, date))
        } else {
            submit(pod.pod_id, date)
        }
    }
    useEffect(() => {
        setDate(pod.pod_expected_despatch_date === "0000-00-00" ? null : pod.pod_expected_despatch_date);
        setInitialDate(pod.pod_expected_despatch_date);
    }, [pod]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} align='center'>
                <Box pt={4}>
                    <FAIcon icon="calendar-check" type="thin" size={50} noMargin />
                </Box>
            </Grid>
            <Grid item xs={12} align='center'>
                <Typography variant="h6">
                    Update Expected {pod.pod_collection === 1 ? `Collection` : `Despatch`} Date
                </Typography>
            </Grid>
            <Grid item xs={12}>                
                <Box ml={4} mr={4} mt={2} mb={3}>
                    <Box className="content-light-grey" p={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <Typography variant="h6">
                                    #{pod.pod_reference}/{pod.pod_idx}
                                </Typography>
                                <Typography variant="body2">
                                    {(pod.pod_expected_despatch_date === "0000-00-00" && (
                                        `No expected ${pod.pod_collection === 1 ? `ready to collect` : `despatched`} date is currently set`
                                    )) || (
                                        `Currently expected to be ${pod.pod_collection === 1 ? `ready to collect` : `despatched`} on ${moment(pod.pod_expected_despatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <FormControl fullWidth style={{width: 135}}>
                                    <DatePicker
                                        type="date"
                                        value={date}
                                        onChange={date => setDate(moment(date).format('YYYY-MM-DD'))}
                                        autoOk={true}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <Button onClick={cancel}
                                variant="text"
                        >
                            <FAIcon icon="times" size={15} button />
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            onClick={() => deployConfirmation(<><Typography variant="body2">Are you sure you want to update the expected despatch date?</Typography></>, () => handleSubmit())}
                            variant="text" 
                            color="primary" 
                            disabled={initialDate === date || date === null}
                        >
                            <FAIcon icon="check" size={15} button />
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const initialState = {
    isLoading: false
}

class DeliveryStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    
    getSalesAdminTasks = (podData = null) => {
        let { po }  =   this.props,
            actions =   [];

        _.each(podData ? [podData] : po.deliveries, (pod, idx) => {
            if(pod.pod_status === "Awaiting Despatch") {
                if(moment(pod.pod_check_date, "YYYY-MM-DD").isBefore(moment())) {
                    actions.push(
                        {
                            id: pod.pod_id,
                            idx: (idx + 1),
                            ref: `${pod.pod_reference}/${pod.pod_idx}`,
                            heading: 'Check Date',
                            content: `This delivery requires a review as the check date ${moment(pod.pod_check_date, "YYYY-MM-DD").isSame(moment().format("YYYY-MM-DD")) ? `is set for today` : `was set for ${moment(pod.pod_check_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`}`,
                            type: 'warning',
                        }
                    )
                }
            } else if(pod.pod_status === "Despatched" && (pod.pod_ship_aa_only === 0 || (pod.pod_delivered === 1 && pod.pod_issue_datetime === "0000-00-00 00:00:00"))) {
                actions.push(
                    {
                        id: pod.pod_id,
                        idx: (idx + 1),
                        ref: `${pod.pod_reference}/${pod.pod_idx}`,
                        heading: 'Ready to Invoice',
                        content: 'This delivery has been fulfilled and is now ready for invoicing',
                        type: 'info',
                    }
                )
            } else if(pod.pod_status === "Despatched" && pod.pod_delivered === 1 && pod.pod_issue_datetimetime !== "0000-00-00 00:00:00") {
                actions.push(
                    {
                        id: pod.pod_id,
                        idx: (idx + 1),
                        ref: `${pod.pod_reference}/${pod.pod_idx}`,
                        heading: 'Ready to Invoice (Short Delivered)',
                        content: 'This delivery has been fulfilled and is now ready for invoicing, however, some line(s) were not fully delivered so please check carefully vs. the supplier invoice and ensure that any credits due have been raised accordingly',
                        type: 'warning',
                    }
                )
            }
        })

        if(podData && _.isEmpty(actions)) {
            if(podData.pod_status === "Awaiting Despatch") {
                if(podData?.od?.od_sys_hold === 1) {
                    actions.push(
                        {
                            id: podData.pod_id,
                            ref: `${podData.pod_reference}/${podData.pod_idx}`,
                            heading: 'On Hold - Subject To Change',
                            content: `The sales order has been modified, this process must be completed prior to interacting with the purchase order`,
                            type: 'error',
                        }
                    )
                } else {
                    if(podData.pod_check_date === "0000-00-00") {
                        actions.push(
                            {
                                id: podData.pod_id,
                                ref: `${podData.pod_reference}/${podData.pod_idx}`,
                                heading: `${podData.pod_collection === 1 ? `Collection` : `Delivery`} Confirmed`,
                                content: `This delivery will be automatically marked as despatched on ${moment(podData.pod_expected_despatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`,
                                type: 'success',
                            }
                        )
                    } else {
                        actions.push(
                            {
                                id: podData.pod_id,
                                ref: `${podData.pod_reference}/${podData.pod_idx}`,
                                heading: `Check Date Set - ${moment(podData.pod_check_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`,
                                content: `This despatch status for this delivery will be checked on the above date`,
                                type: 'warning',
                            }
                        )
                    }
                }
            } else if(podData.pod_status === "Invoiced") {
                actions.push(
                    {
                        id: podData.pod_id,
                        ref: `${podData.pod_reference}/${podData.pod_idx}`,
                        heading: 'Invoiced',
                        content: `This delivery has been invoiced and is complete${_.some(podData.products, el => el.quantity !== el.quantityInvoiced) ? ` (some items have not been fully invoiced)` : ``}`,
                        type: 'success',
                    }
                )
            }
        }

        if(!_.isEmpty(actions)) {
            return _.map(actions, (action, idx) => (
                <Box pt={3} pb={podData ? 1 : undefined} width="100%">
                    <Alert severity={action.type} onClick={() => this.props.handleTabChange(action.idx, action.id)}>
                        <strong>{!podData && `Delivery #${action.ref} - `}{action.heading}</strong><br />
                        {action.content}
                    </Alert>
                </Box>
            ));
        } else {
            return (
                (!podData && (
                    // <Box pt={3}>
                    //     <Alert severity="info">
                    //         <strong>Despatch Confirmed</strong><br />
                    //         This purchase order requires no action  
                    //     </Alert>
                    // </Box>
                    null
                )) || (
                    null
                )
            )
        }
    }

    handleDeliveryDespatched = id => this.setState({isLoading: true}, () => {
        API.put(`/purchaseOrders/delivery/${id}/despatched`)
        .then(res => {
            if(res?.data?.errors) {
                this.props.deploySnackBar("error", res.data.errors?.[0]?.msg);
                this.props.callback();
            } else { 
                this.props.deploySnackBar("success", "You have successfully marked this delivery as despatched");
                this.props.callback();
            }
        });
    });

    handleDeliveryInvoice = pod => {
        this.props.deployDialog(
            <PurchaseOrderInvoice
                content={`Despatched On ${moment(pod.pod_despatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`}
                closeDialog={this.props.closeDialog}
                deployDialog={this.props.deployDialog}
                deployConfirmation={this.props.deployConfirmation}
                handleSubmit={this.handleSubmitDeliveryInvoice}
                id={pod.pod_id}
                pod={pod}
                po={this.props.po}
                reference={`Delivery #${pod.pod_reference}/${pod.pod_idx}`}
            />
        , false, "", "standard", "xs", false, true);
    }

    handleSubmitDeliveryInvoice = (id, formData) => {
        this.setState({isLoading: true}, () => {
            API.post(`/purchaseOrders/delivery/${id}/invoice`, formData)
            .then(res => {
                if(res?.data?.errors) {
                    this.props.deploySnackBar("error", "Something went wrong, please try again");
                } else {
                    this.props.deploySnackBar("success", "You have successfully invoiced this delivery");
                    this.props.closeDialog();
                    this.props.callback();
                }
            });
        });
    }
    
    handleSubmitDeliveryComplete = id => {
        this.setState({isLoading: true}, () => {
            API.put(`/purchaseOrders/delivery/${id}/complete`)
            .then(res => {
                if(res?.data?.errors) {
                    this.props.deploySnackBar("error", "Something went wrong, please try again");
                } else {
                    this.props.deploySnackBar("success", "You have successfully marked this delivery as complete");
                }
                this.props.callback();
            });
        });
    }

    handleDeliveryCheckDateChange = (id, date) => this.setState({isLoading: true}, () => {
        this.props.closeDialog();
        API.put(`/purchaseOrders/delivery/${id}/checkDate`, { date })
        .then(res => {
            if(res?.data?.success) {
                const action = date ? "set" : "removed";
                this.props.deploySnackBar("success", `You have successfully ${action} the check date for this delivery${date ? ` as ${moment(date).format("DD/MM/YYYY")}` : ''}`);
                this.props.callback();
                if(res?.data?.notifications?.length > 0) {
                    const deployEmailNotifications = () => {
                        this.props.deployDialog(
                            <SendEmailNotifications notifications={res.data.notifications} />
                        , false, "E-mail Notifications", "standard", "md", false, true);
                    }
                    this.props.deployConfirmation(`Do you want to send the customer an update email?`, deployEmailNotifications)
                }
            }
        });
    });

    handleDeliveryExpectedDespatchDateChange = (id, date) => this.setState({isLoading: true}, () => {
        this.props.closeDialog();
        API.put(`/purchaseOrders/delivery/${id}/expectedDespatchDate`, { date })
        .then(res => {
            if(res?.data?.success) {
                this.props.deploySnackBar("success", `You have successfully updated the expected despatch date for this delivery to ${moment(date).format("DD/MM/YYYY")}`);
                this.props.callback();
                if(res?.data?.notifications?.length > 0) {
                    const deployEmailNotifications = () => {
                        this.props.deployDialog(
                            <SendEmailNotifications notifications={res.data.notifications} />
                        , false, "E-mail Notifications", "standard", "md", false, true);
                    }
                    this.props.deployConfirmation(`Do you want to send the customer an update email?`, deployEmailNotifications)
                }
            }
        });
    });

    handleDeployCheckDate = pod => this.props.deployDialog(
        <CheckDateDialog 
            pod={pod} 
            cancel={this.props.closeDialog}
            submit={this.handleDeliveryCheckDateChange}
            deployConfirmation={this.props.deployConfirmation}
        />
    , false, "", "standard", "sm", false, true);

    handleDeployExpectedDespatchDate = pod => this.props.deployDialog(
        <ExpectedDespatchDateDialog 
            pod={pod} 
            cancel={this.props.closeDialog}
            submit={this.handleDeliveryExpectedDespatchDateChange}
            deployConfirmation={this.props.deployConfirmation}
        />
    , false, "", "standard", "sm", false, true);

    render() {

        const { 
            currentTab, 
            delivery, 
            po, 
            deployConfirmation 
        } = this.props;

        const { 
            isLoading 
        } = this.state;

        let pod = null;

        if(delivery) {
            const idx = _.findIndex(po.deliveries, el => el.pod_id === delivery);
            if(idx !== -1) {
                pod = po.deliveries[idx];
            }
        }

        const checkDateAction = pod ? (pod.pod_check_date === "0000-00-00" ? "Set" : "Modify") : "";

        const disabled = pod?.od?.od_sys_hold === 1;

        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    (pod && (
                        <>
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={3} alignItems='center'>
                                                    <Grid item xs={12} sm>
                                                        <Typography variant="h6" className="fw-400">
                                                            Delivery #{pod.pod_reference}/{pod.pod_idx}
                                                        </Typography>  
                                                    </Grid>
                                                    <Grid item>
                                                        <Alert variant="outlined" severity="success">
                                                            <strong>Expected Despatch</strong>: {momentFormatDate(pod.pod_expected_despatch_date)}
                                                        </Alert>
                                                    </Grid>
                                                    <Grid item>
                                                        <Alert variant="outlined" severity={pod.pod_check_date !== "0000-00-00" ? (pod.pod_status !== "Awaiting Despatch" ? 'success' : 'warning') : 'success'}>
                                                            <strong>Check Date</strong>: {pod.pod_check_date !== "0000-00-00" ? momentFormatDate(pod.pod_check_date) : `Not Set`}
                                                        </Alert>
                                                    </Grid>
                                                    <Grid item>
                                                        <Alert variant="outlined" severity={pod.pod_status !== "Awaiting Despatch" ? 'success' : 'warning'}>
                                                            <strong>Despatched On</strong>: {pod.pod_status !== "Awaiting Despatch" ? momentFormatDate(pod.pod_despatch_date) : `N/A`}
                                                        </Alert>
                                                    </Grid>
                                                </Grid>
                                                {pod.pod_rma === 1 && (
                                                    <Box pt={3} width="100%">
                                                        <Alert severity='error'>
                                                            <strong>Delivery #{pod.pod_reference}/{pod.pod_idx} - Return To Supplier</strong><br />
                                                            This stock is no longer required and will be returned to the supplier once received
                                                        </Alert>
                                                    </Box>
                                                )}
                                                {pod.pod_add_to_stock === 1 && (
                                                    <Box pt={3} width="100%">
                                                        <Alert severity='info'>
                                                            <strong>Delivery #{pod.pod_reference}/{pod.pod_idx} - Add To Stock</strong><br />
                                                            This stock is longer required for a sales order and will be added to stock once received
                                                        </Alert>
                                                    </Box>
                                                )}
                                                {pod.pod_delivered === 0 && pod.pod_status === 'Despatched' && (
                                                    <Box pt={3} width="100%">
                                                        <Alert severity='info'>
                                                            <strong>Delivery #{pod.pod_reference}/{pod.pod_idx} - Awaiting Delivery</strong><br />
                                                            This delivery has not yet been received by the warehouse - invoicing is not available until the goods received process has been completed
                                                        </Alert>
                                                    </Box>
                                                )}
                                                {(pod?.od?.od_on_hold === 1 && (
                                                    <Box pt={3} width="100%">
                                                        <Alert severity="error">
                                                            <strong>Warning - Pending Release</strong><br />
                                                            This delivery cannot be despatched as the order is {pod?.od?.od_on_hold_lock === 1 ? `pending release` : `on hold`}
                                                        </Alert>
                                                    </Box>
                                                )) || (
                                                    this.getSalesAdminTasks(pod)
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DataTable  
                                                    config={{
                                                        alternatingRowColours: true,
                                                        // responsiveImportance: true,
                                                        noRowHover: true
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: 'Product',
                                                            field: rowData => <Avatar variant="square" alt={rowData.default_image?.img_filekey ?? 'AA'} src={(rowData.default_image && `${CLOUDFRONT_URL}${rowData.default_image?.img_filekey}`) || logo} />,
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: '',
                                                            field: rowData => (
                                                                <Box pt={1.75} pb={1.75}>
                                                                    <Typography variant="body2" className="fw-400">
                                                                        {rowData.productCode}
                                                                    </Typography>
                                                                    <Typography variant="caption" component="div">
                                                                        {rowData.productName}
                                                                    </Typography>
                                                                </Box>
                                                            ),
                                                            important: true, 
                                                            main: true,
                                                            truncate: true
                                                        },
                                                        {
                                                            heading: 'Ordered',
                                                            field: rowData => <Highlight variant="success">{rowData.quantity}</Highlight>,
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        },
                                                        {
                                                            heading: 'Despatch',
                                                            field: rowData => <Highlight variant="success">{rowData.quantityDespatch}</Highlight>,
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        },
                                                        {
                                                            heading: 'Delivered',
                                                            field: rowData => <Highlight variant={parseInt(rowData.quantityDespatch) === parseInt(rowData.quantityDelivered) ? "success" : "error"}>{rowData.quantityDelivered}</Highlight>,
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        },
                                                        {
                                                            heading: 'Invoiced',
                                                            field: rowData => <Highlight variant={rowData.quantity === rowData.quantityInvoiced ? 'success' : 'error'}>{rowData.quantityInvoiced}</Highlight>,
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        }
                                                    ]}
                                                    rows={pod.products}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={3} justify="flex-end" className="pt-2">
                                                    {pod.pod_status === "Awaiting Despatch" && (
                                                        <>
                                                            <Grid item>
                                                                <Button
                                                                    disabled={disabled}
                                                                    color="primary"
                                                                    onClick={() => this.handleDeployCheckDate(pod)}
                                                                    variant="text"
                                                                    className='btn btn-info'
                                                                >
                                                                    <FAIcon icon="calendar-exclamation" disabled={disabled} size={15} button />
                                                                    {checkDateAction} Check Date
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    disabled={disabled}
                                                                    color="primary"
                                                                    onClick={() => this.handleDeployExpectedDespatchDate(pod)}
                                                                    variant="text"
                                                                    className="btn btn-error textLight"
                                                                >
                                                                    <FAIcon icon="calendar-check" disabled={disabled} size={15} button />
                                                                    Change Expected Date
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    disabled={disabled || pod?.od?.od_on_hold === 1}
                                                                    color="primary"
                                                                    onClick={() => deployConfirmation(`Are you sure you want to mark this delivery as ${pod.pod_collection === 1 ? `ready to collect` : `despatched`}?`, () => this.handleDeliveryDespatched(pod.pod_id))}
                                                                    variant="text"
                                                                    className={`btn ${disabled || pod?.od?.od_on_hold === 1 ? 'btn-disabled' : 'btn-success'} textLight`}
                                                                >
                                                                    <FAIcon icon="check" disabled={disabled || pod?.od?.od_on_hold === 1} size={15} buttonPrimary />
                                                                    Mark as {po.po_collection === 1 ? `ready to collect` : `despatched`}
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {/* {(pod.pod_status === "Despatched" || pod.pod_status === "Invoiced") && _.some(pod.products, el => el.quantity !== el.quantityInvoiced) && ((pod.pod_ship_aa_only === 0 && pod.pod_delivered === 1) || pod.pod_ship_aa_only === 1) && ( */}
                                                    {(pod.pod_status === "Despatched" || pod.pod_status === "Invoiced") && _.some(pod.products, el => el.quantity !== el.quantityInvoiced) && ((pod.pod_delivered === 1 && pod.pod_ship_aa_only === 0) || (pod.pod_ship_aa_only === 1 && _.some(pod.products, el => parseInt(el.quantityDelivered) > 0))) && (
                                                        <> 
                                                            {pod.pod_status !== 'Invoiced' && (
                                                                 <Grid item>
                                                                    <Button
                                                                        disabled={disabled || pod.pod_delivered === 0}
                                                                        color="primary"
                                                                        onClick={() => deployConfirmation(<>Are you sure you want to skip invoicing for this delivery?<br />This will mark the delivery as invoiced and complete</>, () => this.handleSubmitDeliveryComplete(pod.pod_id))}
                                                                        variant="contained"
                                                                        className={`btn ${disabled || pod.pod_delivered === 0 ? 'btn-disabled' : 'btn-error'} textLight`}
                                                                    >
                                                                        <FAIcon icon="exclamation-triangle" disabled={disabled || pod.pod_delivered === 0} size={15} buttonPrimary />
                                                                        Skip Invoicing
                                                                    </Button>
                                                                </Grid>
                                                            )}
                                                            <Grid item>
                                                                <Button
                                                                    disabled={disabled}
                                                                    color="primary"
                                                                    onClick={() => deployConfirmation("Are you sure you want to upload the invoice for this delivery?", () => this.handleDeliveryInvoice(pod))}
                                                                    variant="contained"
                                                                    className={`btn ${disabled ? 'btn-disabled' : 'btn-success'} textLight`}
                                                                >
                                                                    <FAIcon icon="check" disabled={disabled} size={15} buttonPrimary />
                                                                    Invoice Delivery
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                        </>
                    )) || (
                        (currentTab === 0 && (
                            <Grid item xs={12}>
                                <ViewNotes heading={<Typography variant="h6">Purchase Order Notes</Typography>} notes={po.notes} hideDeleteButton />
                            </Grid>
                        )) || (currentTab === 1 && (
                            <Grid item xs={12}>
                                <PurchaseOrderSummary po={po} />
                            </Grid>
                        )) || (currentTab === 2 && (
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        {/* <Box pb={3}>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item xs>
                                                    <Typography variant="h6">
                                                        Purchase Order #{po.po_reference}
                                                    </Typography>
                                                    <Typography variant="body2" className="fw-400">
                                                        {po?.supplier?.supp_company_name}
                                                    </Typography>
                                                    {this.getSalesAdminTasks()}
                                                </Grid>
                                            </Grid>
                                        </Box> */}
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Box pt={1}>
                                                    <Typography variant="h6" className="fw-400" paragraph>
                                                        All Deliveries
                                                    </Typography>    
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DataTable  
                                                    config={{
                                                        alternatingRowColours: true,
                                                        // responsiveImportance: true,
                                                        inline: true,
                                                        noRowHover: true
                                                    }}
                                                    columns={[    
                                                        {
                                                            heading: 'Delivery',
                                                            field: rowData => (
                                                                <Box pt={1.75} pb={1.75}>
                                                                    <Typography variant="body2" className="fw-400">
                                                                        {`Delivery ${rowData.pod_idx} of ${_.size(po.deliveries)} (Ref #${rowData.pod_reference}/${rowData.pod_idx}`})
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        {rowData.pod_check_date === "0000-00-00" ? `${rowData.pod_collection ? `Collection` : `Despatch`} confirmed by supplier for ${moment(rowData.pod_expected_despatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")}` : `Despatch status to be checked on ${moment(rowData.pod_check_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`}
                                                                    </Typography>
                                                                </Box>
                                                            ),
                                                            main: true,
                                                            important: true
                                                        },
                                                        {
                                                            heading: `Expected ${po.po_collection === 1 ? `Ready To Collect` : `Despatch`}`,
                                                            field: rowData => rowData.pod_expected_despatch_date,
                                                            fieldFormat: 'date',
                                                            alignment: 'center',
                                                            cellProps: rowData => ({
                                                                className: rowData.pod_status === "Awaiting Despatch" && moment(rowData.pod_expected_despatch_date, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD")) ? 'textError' : undefined
                                                            })
                                                        },  
                                                        {
                                                            heading: 'Check Date',
                                                            field: rowData => rowData.pod_check_date,
                                                            fieldFormat: 'date',
                                                            alignment: 'center',
                                                            cellProps: rowData => ({
                                                                className: rowData.pod_status === "Awaiting Despatch" && rowData.pod_check_date !== "0000-00-00" && moment(rowData.pod_check_date, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD")) ? 'textError' : undefined
                                                            })
                                                        },      
                                                        {
                                                            heading: `${po.po_collection === 1 ? `Ready On` : `Despatched On`}`,
                                                            field: rowData => rowData.pod_despatch_date,
                                                            fieldFormat: 'date',
                                                            alignment: 'center',
                                                        },   
                                                        {
                                                            heading: 'Direct Delivery',
                                                            field: rowData => rowData.pod_ship_aa_only === 0 && rowData.pod_collection === 0,
                                                            fieldFormat: 'boolean',
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        },  
                                                        {
                                                            heading: 'Ship Here',
                                                            field: rowData => rowData.pod_ship_aa_only === 1,
                                                            fieldFormat: 'boolean',
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        },  
                                                        {
                                                            heading: 'Supplier Collection',
                                                            field: rowData => rowData.pod_collection === 1,
                                                            fieldFormat: 'boolean',
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        },   
                                                        {
                                                            heading: po.po_collection === 1 ? `Ready To Collect` : `Delivered`,
                                                            field: rowData => rowData.pod_delivered === 1,
                                                            fieldFormat: 'boolean',
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        },   
                                                        {
                                                            heading: 'Discrepancy',
                                                            field: rowData => _.some(rowData.products, el => parseInt(el.quantityDelivered) > 0 && parseInt(el.quantityDelivered) !== parseInt(el.quantity)),
                                                            fieldFormat: 'boolean',
                                                            alignment: 'center',
                                                            sizeToContent: true
                                                        },   
                                                        {
                                                            heading: 'Status',
                                                            field: rowData => <StatusChip status={po?.po_status === 'Awaiting Submission' ? 'Awaiting Submission' : (rowData.pod_status ?? '-')} />,
                                                        },   
                                                        {
                                                            actions: rowData => {
                                                                return [
                                                                    {name: 'View Delivery', icon: 'binoculars', onClick: () => this.props.handleTabChange((rowData.rowNumber + 2), rowData.pod_id), doubleClick: true},
                                                                ]
                                                            }
                                                        }
                                                    ]}
                                                    rows={po.deliveries}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))
                    )
                )}
            </Grid>
        )
    }
}

export default DeliveryStatus;