import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import API from "API";
import Alert from "Components/Common/Alert/Alert";
import StaffChip from "Components/Common/Chips/StaffChip";
import DataTable from "Components/Common/DataTables/DataTable";
import DatePicker from "Components/Common/DatePickers/DatePicker";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";

import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";

import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

import { clearPersistence, setPersistence } from "Redux/Actions/StatePersistence/StatePersistence";

import { clearPageState, getInitialState, hasPageState, savePageState } from "Functions/StatePersistence/StatePersistenceFunctions";

const initialState = () => ({
    searchResults: {},
    searchString: "",
    access: {
        // addCustomer: false,
        monitorCustomer: false,
        viewCustomer: false,
    },
    accountCode: "",
    accountManager: "",
    accountCodes: [],
    dataLoading: false,
    isCustomerSearch: false,
    lapsedDate: null,
    showMonitored: false,
    showCreditOnly: false,
    showLapsedOnly: false,
    showOverlimitOnly: false,
});

class CustomerSearch extends React.Component {
    constructor(props) {
        super(props);
        this.persistenceId = this.props.dashboard
            ? null
            : this.props.showOverlimitOnly
            ? "custOverLimit"
            : this.props.showCreditOnly
            ? "custCredit"
            : this.props.showLapsedOnly
            ? "custLapsed"
            : "custSearch";
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }

    componentDidMount = () => {
        if (this.props.showCreditOnly) {
            this.props?.pageTitle?.([0, "Finance", "Credit Accounts", "All"]);
        } else if (this.props.showOverlimitOnly) {
            this.props?.pageTitle?.([0, "Finance", "Credit Accounts", "Over-limit"]);
        } else if (this.props.showLapsedOnly) {
            this.props?.pageTitle?.([0, "Finance", "Lapsed Accounts", `No Orders In The Last Year`]);
        } else {
            this.props?.pageTitle?.([0, "Customers"]);
        }

        if (!this.hasPageState()) {
            Promise.all([
                // 'add-customer',
                API.access("monitor-customer"),
                API.access("view-customer"),
                API.get("/customers/statuses", { params: { forSearch: true } }),
            ]).then(
                ([
                    // addCustomer,
                    monitorCustomer,
                    viewCustomer,
                    statuses,
                ]) => {
                    let accountCodes = _.map(statuses.data, (el) =>
                        _.assign({
                            label: el.status_name,
                            options: _.map(el.children, (_el) => {
                                return _.assign({
                                    value: _el.status_id,
                                    label: `${_el.status_code} - ${_el.status_name}`,
                                });
                            }),
                        })
                    );

                    this.setState(
                        {
                            access: {
                                // addCustomer,
                                monitorCustomer: monitorCustomer?.data?.has_access ?? false,
                                viewCustomer: viewCustomer?.data?.has_access ?? false,
                            },
                            accountCodes,
                            showOverlimitOnly: this.props.showOverlimitOnly ? true : false,
                            showCreditOnly: this.props.showCreditOnly ? true : false,
                            showLapsedOnly: this.props.showLapsedOnly ? true : false,
                            isCustomerSearch: !this.props.showCreditOnly && !this.props.showLapsedOnly && !this.props.showOverlimitOnly ? true : false,
                        },
                        () => {
                            this.getSearchData(true);
                        }
                    );
                }
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.location?.pathname !== prevProps.location?.pathname) {
            API.cancel();

            this.persistenceId = this.props.dashboard
                ? null
                : this.props.showOverlimitOnly
                ? "custOverLimit"
                : this.props.showCreditOnly
                ? "custCredit"
                : this.props.showLapsedOnly
                ? "custLapsed"
                : "custSearch";

            if (this.props.showCreditOnly) {
                this.props?.pageTitle?.([0, "Finance", "Credit Accounts", "All"]);
            } else if (this.props.showOverlimitOnly) {
                this.props?.pageTitle?.([0, "Finance", "Credit Accounts", "Over-limit"]);
            } else if (this.props.showLapsedOnly) {
                this.props?.pageTitle?.([0, "Finance", "Lapsed Accounts", `No Orders In The Last Year`]);
            } else {
                this.props?.pageTitle?.([0, "Customers"]);
            }

            if (this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(initialState()),
                });
            } else {
                this.setState(
                    {
                        isCustomerSearch: !this.props.showCreditOnly && !this.props.showLapsedOnly && !this.props.showOverlimitOnly ? true : false,
                        searchResults: [],
                        searchString: "",
                        accountCode: "",
                        accountManager: "",
                        showMonitored: false,
                        showCreditOnly: this.props.showCreditOnly ? true : false,
                        showLapsedOnly: this.props.showLapsedOnly ? true : false,
                        showOverlimitOnly: this.props.showOverlimitOnly ? true : false,
                        lapsedDate: null,
                    },
                    () => {
                        this.getSearchData(true);
                    }
                );
            }
        }

        if (this.state.isCustomerSearch && prevProps.config?.badges?.showAllCustomers !== this.props.config?.badges?.showAllCustomers) {
            this.getSearchData(true);
        }
    };

    componentWillUnmount = () => {
        if (this.timeout) clearTimeout(this.timeout);
        API.cancel();
    };

    getColumns = () => {
        const cols = [],
            { access, isCustomerSearch } = this.state,
            { dashboard, inline, showCreditOnly, showLapsedOnly, showOnboarding, showOverlimitOnly, showMonitored, staff } = this.props;

        cols.push(
            {
                heading: "Customer",
                field: (rowData) => rowData.an,
                dataRef: "an",
                important: true,
                main: true,
                truncate: true,
                cellProps: (rowData) => ({
                    style: {
                        color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                    },
                }),
            },
            {
                heading: "Acc Number",
                field: (rowData) => rowData.ac,
                dataRef: "ac",
                important: true,
                sizeToContent: true,
                cellProps: (rowData) => ({
                    style: {
                        color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                    },
                }),
            }
        );

        // if(isCustomerSearch) {
        //     cols.push(
        //         {
        //             heading: 'Type',
        //             field: rowData => rowData.cust_type,
        //             dataRef: 'cust_type',
        //             sizeToContent: true,
        //             cellProps: rowData => ({
        //                 style: {
        //                     color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? '#ef3340' : undefined
        //                 }
        //             })
        //         }
        //     )
        // }

        if (showCreditOnly || showOverlimitOnly || showMonitored) {
            cols.push({
                heading: "Credit Limit",
                field: (rowData) => rowData.ccl,
                fieldFormat: "currency",
                dataRef: "ccl",
                alignment: "right",
                important: true,
                sizeToContent: true,
                cellProps: (rowData) => ({
                    style: {
                        color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                    },
                }),
            });
            if (!inline) {
                cols.push(
                    {
                        heading: "Credit Balance",
                        field: (rowData) => rowData.ccb,
                        fieldFormat: "currency",
                        dataRef: "ccb",
                        alignment: "right",
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Credit Available",
                        field: (rowData) => rowData.cca,
                        fieldFormat: "currency",
                        dataRef: "cca",
                        alignment: "right",
                        important: true,
                        sizeToContent: true,
                        cellProps: (rowData) => ({
                            style: {
                                color: rowData.cca < 0 ? "#ef3340" : "#4CAF50",
                            },
                        }),
                    },
                    {
                        heading: "Credit Utilisation",
                        field: (rowData) => rowData.ccu,
                        dataRef: "ccu",
                        fieldFormat: "percentage:0",
                        alignment: "right",
                        important: true,
                        sizeToContent: true,
                        cellProps: (rowData) => ({
                            style: {
                                color: parseInt(parseFloat(rowData.ccu).toFixed(0)) > 100 ? "#ef3340" : "#4CAF50",
                            },
                        }),
                    },
                    {
                        heading: "Due to be Invoiced",
                        field: (rowData) => "",
                        //fieldFormat: 'currency',
                        //dataRef: 'ccb',
                        alignment: "right",
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Euler Cover",
                        field: (rowData) => rowData.eul ?? "0.00",
                        fieldFormat: "currency",
                        dataRef: "eul",
                        alignment: "right",
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Overdue Balance",
                        field: (rowData) => "",
                        //fieldFormat: 'currency',
                        //dataRef: 'ccb',
                        alignment: "right",
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Payment Terms",
                        field: (rowData) => rowData.pt,
                        dataRef: "pt",
                        important: true,
                        sizeToContent: true,
                        cellProps: (rowData) => ({
                            style: {
                                color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                            },
                        }),
                    },
                    {
                        heading: "Last Ordered",
                        field: (rowData) => rowData.lo,
                        fieldFormat: "date",
                        dataRef: "lo",
                        alignment: "right",
                        important: true,
                        sizeToContent: true,
                        cellProps: (rowData) => ({
                            style: {
                                color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                            },
                        }),
                    }
                );
            }
        }

        if (showLapsedOnly) {
            cols.push(
                {
                    heading: "Credit Limit",
                    field: (rowData) => rowData.ccl,
                    fieldFormat: "currency",
                    dataRef: "ccl",
                    alignment: "right",
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: "Euler Cover",
                    field: (rowData) => rowData.eul ?? "0.00",
                    fieldFormat: "currency",
                    dataRef: "eul",
                    alignment: "right",
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: "Payment Terms",
                    field: (rowData) => rowData.pt,
                    dataRef: "pt",
                    important: true,
                    sizeToContent: true,
                    cellProps: (rowData) => ({
                        style: {
                            color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                        },
                    }),
                },
                {
                    heading: "Last Ordered",
                    field: (rowData) => rowData.lo,
                    fieldFormat: "date",
                    dataRef: "lo",
                    alignment: "right",
                    important: true,
                    sizeToContent: true,
                    cellProps: (rowData) => ({
                        style: {
                            color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                        },
                    }),
                }
            );
        }

        if (isCustomerSearch && !dashboard) {
            cols.push(
                {
                    heading: "Postcode",
                    field: (rowData) => rowData.ap || "-",
                    dataRef: "ap",
                    sizeToContent: true,
                    cellProps: (rowData) => ({
                        style: {
                            color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                        },
                    }),
                },
                {
                    heading: "Payment Terms",
                    field: (rowData) => rowData.pt,
                    dataRef: "pt",
                    important: true,
                    sizeToContent: true,
                    cellProps: (rowData) => ({
                        style: {
                            color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                        },
                    }),
                },
                {
                    heading: "Main Contact Number",
                    field: (rowData) => (rowData.p && rowData.p) || "-",
                    dataRef: "p",
                    sizeToContent: true,
                    cellProps: (rowData) => ({
                        style: {
                            color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                        },
                    }),
                },
                {
                    heading: "Primary Contact",
                    field: (rowData) => rowData.pc,
                    dataRef: "pc",
                    important: true,
                    sizeToContent: true,
                    cellProps: (rowData) => ({
                        style: {
                            color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                        },
                    }),
                },
                {
                    heading: "Customer Type",
                    field: (rowData) => rowData.typ,
                    dataRef: "typ",
                    important: true,
                    sizeToContent: true,
                    cellProps: (rowData) => ({
                        style: {
                            color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                        },
                    }),
                },
                {
                    heading: "Proactive Responsibility",
                    field: (rowData) => (staff && staff[rowData.am] && <StaffChip staff={staff[rowData.am]} />) || "-",
                    dataRef: "am",
                    sizeToContent: true,
                    fieldFormat: "staff",
                }
            );
        }

        if (!inline) {
            cols.push(
                {
                    heading: "Account Code",
                    field: (rowData) => (
                        <>
                            {rowData.sc} - {rowData.t}
                            <Typography variant="caption" component="div">
                                {rowData.st}
                            </Typography>
                        </>
                    ),
                    dataRef: "code",
                    important: true,
                    sizeToContent: true,
                    cellProps: (rowData) => ({
                        style: {
                            color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                        },
                    }),
                },
                {
                    actions: (rowData) => {
                        return [
                            { name: "View", icon: "binoculars", color: rowData.m === 1 ? "primary" : undefined, link: "/customers/" + rowData.i, disabled: !access.viewCustomer, doubleClick: true },
                            {
                                name: `${rowData.m === 1 ? "Remove" : "Add"} monitoring`,
                                icon: rowData.m === 1 ? "eye" : "eye-slash",
                                color: rowData.m === 1 ? "primary" : undefined,
                                onClick: () =>
                                    this.props.deployConfirmation(`Are you sure you want to ${rowData.m === 1 ? "remove" : "add"} monitoring for this account?`, () =>
                                        this.handleToggleMonitor(rowData.i, rowData.an)
                                    ),
                                disabled: !access.monitorCustomer,
                                hideIfDisabled: true,
                            },
                        ];
                    },
                }
            );
        } else {
            if (showMonitored) {
                cols.push(
                    {
                        heading: "Account Code",
                        field: (rowData) => (
                            <>
                                {rowData.sc} - {rowData.t}
                                <Typography variant="caption" component="div">
                                    {rowData.st}
                                </Typography>
                            </>
                        ),
                        dataRef: "code",
                        important: true,
                        sizeToContent: true,
                        cellProps: (rowData) => ({
                            style: {
                                color: !showMonitored && access.monitorCustomer && rowData.m === 1 ? "#ef3340" : undefined,
                            },
                        }),
                    },
                    {
                        actions: (rowData) => {
                            return [{ name: "View", icon: "binoculars", link: "/customers/" + rowData.i, disabled: !access.viewCustomer, doubleClick: true }];
                        },
                    }
                );
            } else {
                if(showOnboarding) {
                    cols.push({
                        actions: (rowData) => {
                            return [
                                {
                                    name: "Dismiss",
                                    icon: "check",                                                                                                   
                                    onClick: () => this.props.deployConfirmation(`Are you sure you want to remove this company?`, () => this.dismissOnboarding(rowData.i)),
                                },
                                { name: "View", icon: "binoculars", color: rowData.m === 1 ? "primary" : undefined, link: "/customers/" + rowData.i, disabled: !access.viewCustomer, doubleClick: true },
                            ];
                        },
                    });
                } else {
                    cols.push({
                        actions: (rowData) => {
                            return [
                                {
                                    name: "Dismiss",
                                    icon: "check",
                                    onClick: () => this.props.deployConfirmation(`Are you sure you want to remove this company from new credit accounts?`, () => this.dismissNewAccount(rowData.i)),
                                },
                                { name: "View", icon: "binoculars", color: rowData.m === 1 ? "primary" : undefined, link: "/customers/" + rowData.i, disabled: !access.viewCustomer, doubleClick: true },
                            ];
                        },
                    });
                }
            }
        }

        return cols;
    };

    dismissNewAccount = (id) => {
        API.put(`/customers/${id}/dismissNewCreditAccount`).then((res) => {
            if (res?.data) {
                this.props.deploySnackBar(`success`, `The company has been removed from the new credit accounts list`);
                this.getSearchData();
            }
        });
    };

    dismissOnboarding = (id) => {
        API.put(`/customers/${id}/dismissOnboarding`).then((res) => {
            if (res?.data) {
                this.props.deploySnackBar(`success`, `The company has been removed successfully from the onboarding list`);
                this.getSearchData();
            }
        });
    };

    getSearchData = (dataLoading) => {
        // if(this.state.searchString.length >= 1 || this.state.showMonitored || this.state.showCreditOnly || this.state.showLapsedOnly || this.state.showOverlimitOnly) {
        this.setState(
            {
                dataLoading,
            },
            () => {
                let params = {
                    searchString: this.state.searchString,
                    accountCode: this.state.accountCode,
                    accountManager: this.state.accountManager,
                };
                if (this.props.dashboard) {
                    params = {
                        ...params,
                        dashboard: 1,
                    };
                }
                if (this.state.showMonitored || this.props.showMonitored) {
                    params = {
                        ...params,
                        showMonitored: true,
                    };
                }
                if (this.state.showCreditOnly) {
                    params = {
                        ...params,
                        showCreditOnly: true,
                    };
                    if (this.props.showNewOnly) {
                        params = {
                            ...params,
                            showNewOnly: true,
                        };
                    }
                }
                if (this.props.showOnboarding) {
                    params = {
                        ...params,
                        showOnboarding: this.props.showOnboarding,
                    };
                }
                if (this.state.showLapsedOnly) {
                    params = {
                        ...params,
                        showLapsedOnly: true,
                    };
                    if (this.state.lapsedDate) {
                        params = {
                            ...params,
                            lapsedDate: this.state.lapsedDate,
                        };
                    }
                }
                if (this.state.showOverlimitOnly) {
                    params = {
                        ...params,
                        showOverlimitOnly: true,
                    };
                }
                if (this.state.isCustomerSearch && this.props?.config?.badges?.showAllCustomers === 0) {
                    params = {
                        ...params,
                        showMyCustomers: true,
                    };
                }
                API.get("/customers/all", {
                    props: {
                        cancellation: !this.props.showOnboarding && true,
                    },
                    params,
                }).then((result) => {
                    if (result && result.data) {
                        this.setState(
                            {
                                dataLoading: false,
                                searchResults: result.data,
                            },
                            () => {
                                this.savePageState();
                            }
                        );
                    }
                });
            }
        );
        // } else {
        //     this.setState({
        //         searchResults: []
        //     })
        // }
    };

    handleDateChange = (date) => {
        this.setState(
            {
                lapsedDate: moment(date).format("YYYY-MM-DD"),
            },
            () => {
                this.props?.pageTitle?.([0, "Accounts", "Lapsed Accounts", `No Orders Since ${moment(this.state.lapsedDate).format("DD/MM/YYYY")}`]);
                this.getSearchData(true);
            }
        );
    };

    handleToggleMonitor = (id, company) => {
        API.put(`/customers/${id}/toggleMonitoring`).then((res) => {
            if (res?.data) {
                this.getSearchData(false);
                const message = res.data?.monitored === 1 ? `You have added monitoring for ${company}` : `You have removed monitoring for ${company}`;
                this.props.deploySnackBar("success", message);
            }
        });
    };

    onAccountManagerChange = (o) => {
        this.setState(
            {
                accountManager: o?.value ?? "",
            },
            () => {
                this.setSearch();
            }
        );
    };

    onAccountCodeChange = (o) => {
        this.setState(
            {
                accountCode: o?.value ?? "",
            },
            () => {
                this.setSearch();
            }
        );
    };

    onSearchStringChange = (event) => {
        this.setState(
            {
                searchString: event.target.value,
            },
            () => {
                this.setSearch();
            }
        );
    };

    resetSearch = () => {
        API.cancel();
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState(
                {
                    ...this.getInitialState(initialState()),
                    access: this.state.access,
                    accountCodes: this.state.accountCodes,
                    isCustomerSearch: this.state.isCustomerSearch,
                },
                () => {
                    this.getSearchData(true);
                }
            );
        }, 250);
    };

    setSearch = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getSearchData(true), 200);
    };

    toggleShowMonitored = () => {
        this.setState(
            {
                showMonitored: !this.state.showMonitored,
            },
            () => this.getSearchData()
        );
    };

    render() {
        const { dashboard, inline, onboardingType, showNewOnly, showLapsedOnly, showOnboarding, showPagination, staffList, ui } = this.props;
        const { access, accountCode, accountManager, accountCodes, dataLoading, isCustomerSearch, lapsedDate, searchString, showMonitored } = this.state;
        return (
            <>
                <Grid container spacing={1}>
                    {!inline && (
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={12} sm={5} md={4} xl={3} style={{ marginBottom: 12 }}>
                                <TextField
                                    className="pr-2"
                                    fullWidth
                                    onChange={this.onSearchStringChange}
                                    placeholder="Search:"
                                    value={searchString}
                                    variant="filled"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FAIcon type="thin" icon="search" size="small" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    autoFocus={!ui?.device?.isTablet}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} xl={2} className="pr-2" style={{ marginBottom: 12 }}>
                                <AutoCompleteSelect
                                    options={accountCodes}
                                    placeholder="Account Code:"
                                    onChange={(o) => this.onAccountCodeChange(o)}
                                    value={accountCode}
                                    variant="filled"
                                    adornment="filter"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} xl={2} className="pr-2" style={{ marginBottom: 12 }}>
                                <AutoCompleteSelect
                                    options={staffList}
                                    placeholder="Proactive Responsibility:"
                                    onChange={(o) => this.onAccountManagerChange(o)}
                                    value={accountManager}
                                    variant="filled"
                                    adornment="user"
                                />
                            </Grid>
                            {showLapsedOnly && (
                                <Grid item xs={12} sm={4} md={3} xl={2} style={{ marginBottom: 12 }}>
                                    <FormControl fullWidth className="pr-2">
                                        <DatePicker
                                            name="lapsedDate"
                                            type="date"
                                            placeholder="No Orders Since: "
                                            value={lapsedDate}
                                            onChange={this.handleDateChange}
                                            autoOk={true}
                                            disableFuture={true}
                                            inputVariant="filled"
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            {access.monitorCustomer && (
                                <Grid item xs={2} style={{ marginBottom: 12 }}>
                                    <Tooltip title={`${showMonitored ? "Show All Customers" : "Show Monitored Customers"}`}>
                                        <IconButton onClick={this.toggleShowMonitored}>
                                            <FAIcon icon={showMonitored ? "eye" : "eye-slash"} type="light" color={showMonitored ? "primary" : undefined} noMargin />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                            {/* {isCustomerSearch && (
                                <Grid item xs={!access.monitorCustomer ? 8 : 4} align='right'> 
                                    <Grid container spacing={3}>
                                        <Grid item xs />
                                        {access.addCustomer && (
                                            <Grid item>
                                                <Button 
                                                    color="primary"
                                                    size="small"
                                                    variant="text"
                                                    onClick={() => this.props.history.push('/customers/new')}
                                                >
                                                    <FAIcon type="light" icon="plus-circle" size="small" button />
                                                    Add Customer
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )} */}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Paper elevation={inline ? 0 : undefined}>
                            {(!dataLoading &&
                                dashboard &&
                                _.isEmpty(this.state.searchResults) &&
                                ((this.props.showMonitored && (
                                    <Alert severity="success" variant="outlined">
                                        <strong>None - </strong>There are currently no monitored accounts
                                    </Alert>
                                )) ||
                                    (showNewOnly && (
                                        <Alert severity="success" variant="outlined">
                                            <strong>None - </strong>There are no new accounts under review
                                        </Alert>
                                    )) || (showOnboarding && (
                                        <Alert severity="success" variant="outlined">
                                            <strong>None - </strong>There are no {onboardingType} to review
                                        </Alert>
                                    )) || (
                                        <Alert severity="success" variant="outlined">
                                            <strong>None - </strong>There are no accounts to display
                                        </Alert>
                                    ))) || (
                                <DataTable
                                    config={{
                                        key: "i",
                                        pagination: !inline ? true : showPagination ? true : false,
                                        plainPagination: inline,
                                        alternatingRowColours: true,
                                        isLoading: dataLoading,
                                        responsiveImportance: true,
                                        inline: inline,
                                        noResultsText: showNewOnly
                                            ? `No new credit accounts have been created`
                                            : searchString.length >= 1
                                            ? "No customers were found"
                                            : "Start typing to search customers",
                                        persistenceId: this.persistenceId,
                                        options: {
                                            defaultSort: isCustomerSearch ? "an" : undefined,
                                            dataRef: true,
                                            reset: this.resetSearch,
                                            export: {
                                                title: `Customers`,
                                                name: `Customers`,
                                                excel: true,
                                                pdf: true,
                                                print: true,
                                            },
                                        },
                                    }}
                                    columns={this.getColumns()}
                                    rows={this.state.searchResults}
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    config: state.notifications.config,
    statePersistence: state.statePersistence,
    staff: state.notifications.status,
    staffList: state.notifications.staffList,
    ui: state.ui,
});

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        clearPersistence: () => dispatch(clearPersistence()),
        setPersistence: (key, state) => dispatch(setPersistence(key, state)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch);
