import _ from "lodash";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import uuidv4 from "uuid/v4";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import API from "API";
import logo from "Assets/Images/no-img.jpg";
import CardDbSiteForm from "Components/CardDB/Misc/CardDbSiteForm";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import InsightChip from "Components/Common/Chips/InsightChip";
import ProductStockChip from "Components/Common/Chips/ProductStockChip";
import ConfigureProductBundle from "Components/Common/Forms/QuoteOrderForm/ConfigureProductBundle";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import Textarea from "Components/Common/Inputs/Textarea";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import ProductSearchSelector from "Components/Products/Misc/ProductSearchSelector";
import { CLOUDFRONT_URL, MANUAL_DISCOUNT_THRESHOLD } from "Constants";
import { handleFloat } from "Functions/FormFunctions";
import { currencyFormat, isNumeric } from "Functions/MiscFunctions";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

const ChooseProduct = ({ original, replacement, handleSelectOriginal, handleSelectReplacement }) => (
    <Grid container spacing={3} className="mt-2">
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center" justify="flex-end">
                <Grid item xs={12}>
                    <Box p={3} className="content-light-error content-light-error-clickable" onClick={handleSelectOriginal}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                <Avatar src={original?.product_img ? `${CLOUDFRONT_URL}${original.product_img}` : logo} style={{ overflow: "visible", width: 75, height: 75 }} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" className="fw-400">
                                    {original.product_code ?? ""}
                                </Typography>
                                <Typography variant="body2">{original.product_name}</Typography>
                                <Typography variant="caption">
                                    {`${original?.stock_count ?? 0} In Stock | ${original?.stock_allocated_count ?? 0} Allocated | ${original?.stock_available_count ?? 0} Available | ${
                                        original?.stock_on_order_count ?? 0
                                    } On Order | ${original?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                </Typography>
                                <Box mt={1}>
                                    <ProductStockChip
                                        rep={original.product_rep_product_id}
                                        status={original.product_status}
                                        stocked={original.product_stock_item}
                                        stockAvailable={original.stock_available_count}
                                        stockOnOrderAvailable={original.stock_on_order_count - original.stock_on_order_allocated_count}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center" justify="flex-end">
                <Grid item xs>
                    <Typography variant="body1" className="fw-400">
                        This product was replaced by:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box p={3} className="content-light-success content-light-success-clickable" onClick={handleSelectReplacement}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                <Avatar src={replacement?.product_img ? `${CLOUDFRONT_URL}${replacement.product_img}` : logo} style={{ overflow: "visible", width: 75, height: 75 }} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" className="fw-400">
                                    {replacement.product_code ?? ""}
                                </Typography>
                                <Typography variant="body2">{replacement.product_name}</Typography>
                                <Typography variant="caption">
                                    {`${replacement?.stock_count ?? 0} In Stock | ${replacement?.stock_allocated_count ?? 0} Allocated | ${replacement?.stock_available_count ?? 0} Available | ${
                                        replacement?.stock_on_order_count ?? 0
                                    } On Order | ${replacement?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                </Typography>
                                <Box mt={1}>
                                    <ProductStockChip
                                        rep={replacement.product_rep_product_id}
                                        status={replacement.product_status}
                                        stocked={replacement.product_stock_item}
                                        stockAvailable={replacement.stock_available_count}
                                        stockOnOrderAvailable={replacement.stock_on_order_count - replacement.stock_on_order_allocated_count}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

const ChooseProductBundleOption = ({ addBundleToForm, addProductToForm, customer, product }) => {
    const [selectedBundle, setBundle] = useState(null);

    const dispatch = useDispatch();

    const handleAddProductToForm = () => {
        addProductToForm();
        dispatch(closeDialog());
    };

    const handleChooseProductBundle = () => {
        const selectedBundleIdx = _.findIndex(product.bundles, (el) => el?.id?.toString?.() === selectedBundle);
        dispatch(
            deployDialog(
                <ConfigureProductBundle addBundleToForm={addBundleToForm} addProductToForm={addProductToForm} bundle={product.bundles[selectedBundleIdx]} customer={customer} product={product} />,
                false,
                `${product.bundles[selectedBundleIdx]?.pc} - ${product.bundles[selectedBundleIdx]?.nm} (${product.bundles[selectedBundleIdx]?.tp})`,
                "error",
                "lg",
                false,
                true
            )
        );
    };

    return (
        <Grid container spacing={1} className="pt-1">
            <Grid item xs={12}>
                <Box className="content-light-grey" p={2}>
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item>
                                    <Avatar src={product?.product_img ? `${CLOUDFRONT_URL}${product.product_img}` : logo} style={{ overflow: "visible", width: 75, height: 75 }} />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" className="fw-400">
                                        {product.product_code ?? ""}
                                    </Typography>
                                    <Typography variant="caption" component="div">
                                        {product.product_name}
                                    </Typography>
                                    {product.product_service === 0 && (
                                        <>
                                            <Typography variant="caption" component="div">
                                                {`${product?.stock_count ?? 0} In Stock | ${product?.stock_allocated_count ?? 0} Allocated | ${product?.stock_available_count ?? 0} Available | ${
                                                    product?.stock_on_order_count ?? 0
                                                } On Order | ${product?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                            </Typography>
                                            <Box mt={1}>
                                                <ProductStockChip
                                                    rep={product.product_rep_product_id}
                                                    status={product.product_status}
                                                    stocked={product.product_stock_item}
                                                    stockAvailable={product.stock_available_count}
                                                    stockOnOrderAvailable={product.stock_on_order_count - product.stock_on_order_allocated_count}
                                                />
                                            </Box>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box style={{ maxHeight: 350, overflowY: "auto", overflowX: "hidden" }} p={2} pt={1} pl={0} pr={0}>
                    <Grid container spacing={2}>
                        {_.map(product.bundles, (bundle, index) => {
                            const selected = selectedBundle === bundle.id?.toString?.();
                            return (
                                <Grid item xs={12} key={index}>
                                    <RadioGroup aria-label="Bundle *" name="bundle" onChange={(e) => setBundle(e.target.value)}>
                                        <FormControlLabel
                                            value={bundle.id}
                                            checked={selected}
                                            control={<Radio color="primary" />}
                                            label={
                                                <Box pt={1} pl={1}>
                                                    <Grid container spacing={3} alignItems="center">
                                                        <Grid item xs className="disable-select">
                                                            <Typography variant="body2" className={`${selected ? `textError fw-400` : `fw-400`}`}>
                                                                {bundle.pc}
                                                            </Typography>
                                                            <Typography variant="caption" className="fw-400" component="div">
                                                                {bundle.nm}
                                                            </Typography>
                                                            <Typography variant="caption" className="fw-400" component="div" color="textSecondary" gutterBottom>
                                                                {bundle.ty} | {bundle.ty === 'Fixed Kit' ? 'Kit' : 'Customer'} Price: {bundle.tp} | Margin: {bundle.mr}
                                                            </Typography>
                                                            {_.map(bundle.cat, (c, idx) => (
                                                                <Typography variant="caption" component="div" key={idx}>
                                                                    &#8226; {c.text} x {c.qty} {c.req === 0 ? `(Optional)` : ``}
                                                                </Typography>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            }
                                        />
                                    </RadioGroup>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Button className="btn btn-error" variant="contained" color="primary" fullWidth onClick={handleAddProductToForm} size="large">
                    <FAIcon icon="basket-shopping" size={15} buttonPrimary />
                    Standalone Purchase
                </Button>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Button
                    className={`btn ${selectedBundle ? `btn-success` : `btn-disabled`}`}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleChooseProductBundle}
                    size="large"
                    disabled={!selectedBundle}
                >
                    <FAIcon icon="cog" disabled={!selectedBundle} size={15} buttonPrimary />
                    Configure Bundle
                </Button>
            </Grid>
        </Grid>
    );
};

const initialState = () => ({
    access: {
        manualDiscount: false,
        manualDiscountAny: false
    },
    delivery: "",
    oldIdx: "",
    formData: {
        bundleId: null,
        productId: "",
        productName: "",
        productCode: "",
        quantity: "",
        cost: "",
        price: "",
        subTotal: "",
        discount: "",
        discountId: "0",
        discountPercent: "",
        discountTotal: "",
        total: "",
        image: "",
        text: "",
        cdbSite: "",
        cdbIntRangeStart: "",
        cdbIntRangeEnd: "",
        cdbExtRangeStart: "",
        cdbExtRangeEnd: "",
        cdbComments: "",
        cdbOrderNo: "",
        cdbType: "",
        cdbFormat: "",
        cdbProgrammer: "",
        productData: {},
        option: {},
    },
    maxDiscount: MANUAL_DISCOUNT_THRESHOLD,
    formErrors: [],
    isLoading: false,
    notesRead: false,
    key: uuidv4(),
    showText: 0,
});

class Product extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleFloat = handleFloat.bind(this);
        this.quantityInput = React.createRef();
        this.productSelect = React.createRef();
    }

    componentDidMount = () => {
        API.multiAccess([
            'manual-discount',
            'manual-discount-any'
        ])
        .then(([manualDiscount, manualDiscountAny]) => {
            this.setState(
                {
                    access: {
                        manualDiscount,
                        manualDiscountAny,
                    },
                    maxDiscount: manualDiscountAny ? 100 : manualDiscount ? MANUAL_DISCOUNT_THRESHOLD : 0,
                },
                () => {
                    if (!this.props.formData) {
                        if (this.props.productId) {
                            if (this.props.productState) {
                                this.handleProductState(this.props.productState);
                            } else {
                                this.handleProductChange(this.props.productId);
                            }
                        } else {
                            this.productSelect.current.focus();
                        }
                    } else {
                        let maxDiscount = manualDiscountAny ? 100 : manualDiscount ? MANUAL_DISCOUNT_THRESHOLD : 0;
                        if(this.props.discount?.[this.props.formData?.productData?.product_supplier_id]) {
                            maxDiscount = parseFloat(this.props.discount?.[this.props.formData?.productData?.product_supplier_id]);
                        }
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                ...this.props.formData,
                            },
                            delivery: this.props.delivery,
                            oldIdx: this.props.formData?.rowIdx,
                            showText: !_.isEmpty(this.props.formData?.text) ? 1 : 0,
                            maxDiscount
                        });
                    }
                }
            );
        });
    };

    handleCalculateTotals = (field) => {
        const { 
            formData 
        } = this.state;

        let discount,
            discountPercent,
            subTotal,
            total,
            discountTotal,
            price = parseFloat(formData.price),
            qty = parseInt(formData.quantity),
            cdbIntRangeStart = formData.cdbIntRangeStart,
            cdbIntRangeEnd = formData.cdbIntRangeEnd,
            cdbExtRangeStart = formData.cdbExtRangeStart,
            cdbExtRangeEnd = formData.cdbExtRangeEnd,
            productData = formData?.productData ?? null;

        switch (field) {
            case "discount":
                discount = parseFloat(formData.discount);
                discountPercent = ((discount / price) * 100).toFixed(2);
                subTotal = (price - discount).toFixed(2);
                break;
            case "discountPercent":
                discountPercent = parseFloat(formData.discountPercent);
                discount = ((price / 100) * discountPercent).toFixed(2);
                subTotal = (price - discount).toFixed(2);
                break;
            case "quantity":
                let productDiscount = formData.discountPercent;

                if (!_.isEmpty(formData?.productData?.discount?.p)) {
                    let qd = 0,
                        pqd = 0;
                    _.each(formData?.productData?.discount?.p, (pd) => {
                        if(parseFloat(productDiscount) === parseFloat(pd?.p)) {
                            pqd = pd.q;
                        }
                        if (formData.quantity >= pd?.q) {
                            if(pd.q > qd) {
                                productDiscount = parseFloat(pd?.p);
                                qd = pd.q;
                            }
                        }
                    });
                    if(qd === 0 && pqd > 0) {
                        productDiscount = parseFloat(formData?.productData?.discount?.b ?? 0);
                    }
                }

                discountPercent = parseFloat(productDiscount).toFixed(2);

                discount = (price * (discountPercent / 100)).toFixed(2);

                subTotal = (price - discount).toFixed(2);

                let site = null;
                if (productData?.cDb && isNumeric(qty) && formData.cdbSite !== "") {
                    site = _.find(this.props.sites, (el) => el.value === formData.cdbSite);
                    cdbIntRangeStart = this.handleCalculateCardOrderRange(site?.latestOrder?.co_internal_range_end ?? 0, 1);
                    cdbIntRangeEnd = this.handleCalculateCardOrderRange(site?.latestOrder?.co_internal_range_end ?? 0, parseInt(qty) * parseInt(formData?.productData?.product_pack_size ?? 1));
                    cdbExtRangeStart = this.handleCalculateCardOrderRange(site?.latestOrder?.co_external_range_end ?? 0, 1);
                    cdbExtRangeEnd = this.handleCalculateCardOrderRange(site?.latestOrder?.co_external_range_end ?? 0, parseInt(qty) * parseInt(formData?.productData?.product_pack_size ?? 1));
                }

                break;
            default:
                subTotal = parseFloat(formData.subTotal);
                discount = (price - subTotal).toFixed(2);
                discountPercent = ((discount / price) * 100).toFixed(2);
                break;
        }

        total = (qty * (price - discount)).toFixed(2);

        discountTotal = (qty * discount).toFixed(2);

        // if(discountPercent < 0) {
        //     discountTotal = "0.00";
        //     discount = "0.00";
        //     discountPercent = "0.00";
        //

        this.setState({
            formData: {
                ...this.state.formData,
                discount,
                discountPercent,
                subTotal,
                discountTotal,
                total,
                cdbIntRangeStart,
                cdbIntRangeEnd,
                cdbExtRangeStart,
                cdbExtRangeEnd,
            },
        });
    };

    handleDeliveryChange = (option) => {
        this.setState({
            delivery: option?.value ?? "",
        });
    };

    handleDeployNotes = () => {
        const { delivery, formData, oldIdx } = this.state;

        this.setState(
            {
                notesRead: true,
            },
            () => {
                this.props.deployDialog(
                    <ViewNotes
                        hideDeleteButton
                        notes={formData?.productData?.notes}
                        inline
                        button={
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.props.closeDialog();
                                    if (this.props.inDialog) {
                                        this.props.handleSubmit(formData, delivery, this.props.delivery, oldIdx);
                                    } else {
                                        this.handleSubmit(null, false);
                                    }
                                }}
                            >
                                <FAIcon icon="check" buttonPrimary />
                                Acknowledged
                            </Button>
                        }
                    />,
                    false,
                    `${formData?.productCode}`,
                    "standard",
                    "sm",
                    false,
                    true
                );
            }
        );
    };

    handleProductChange = (option) => {
        const { customer } = this.props;
        let productId = option?.value ?? option;
        
        if(option?.value?.startsWith?.('bundle:')) {

            const bundleId = option?.value?.split(':')[1];
            
            this.setState({
                key: uuidv4()
            }, () => {
                this.props.deployDialog(
                    <ConfigureProductBundle addBundleToForm={this.addBundleToForm} addProductToForm={this.addProductToForm} bundle={{id: bundleId}} customer={customer} product={{id: -1}} />,
                    false,
                    `${option.code} - ${option.subtitle1}${option.tp ? ` (Trade Price ${currencyFormat.format(option.tp)})` : ''}`,
                    "error",
                    "lg",
                    false,
                    true
                )
            });

            return;
            
        }

        if (productId) {
            this.setState(
                {
                    isLoading: true,
                },
                () => {
                    API.get(`/products/${productId}`, {
                        params: {
                            forCustomer: customer,
                            forQuoteOrderForm: true,
                        },
                    }).then((res) => {
                        if (res?.data) {
                            const addProductToForm = () => {
                                if (res.data.product_rep_product_id > 0) {
                                    API.get(`/products/${res.data.product_rep_product_id}`, {
                                        params: {
                                            forCustomer: customer,
                                            forQuoteOrderForm: true,
                                        },
                                    }).then((rep) => {
                                        if (rep?.data) {
                                            this.setState(
                                                {
                                                    isLoading: false,
                                                    key: uuidv4(),
                                                },
                                                () => {
                                                    let handleSelectOriginal, handleSelectReplacement;

                                                    if (this.props.productId) {
                                                        handleSelectOriginal = () =>
                                                            this.props.deployDialog(
                                                                <Product
                                                                    classes={this.props.classes}
                                                                    closeDialog={this.props.closeDialog}
                                                                    deployDialog={this.props.deployDialog}
                                                                    deploySnackBar={this.props.deploySnackBar}
                                                                    productId={res.data.product_id}
                                                                    productState={res.data}
                                                                    handleSubmit={this.props.handleSubmit}
                                                                    inDialog
                                                                />,
                                                                false,
                                                                "",
                                                                "standard",
                                                                "xl"
                                                            );
                                                        handleSelectReplacement = () =>
                                                            this.props.deployDialog(
                                                                <Product
                                                                    classes={this.props.classes}
                                                                    closeDialog={this.props.closeDialog}
                                                                    deployDialog={this.props.deployDialog}
                                                                    deploySnackBar={this.props.deploySnackBar}
                                                                    productId={rep.data.product_id}
                                                                    productState={rep.data}
                                                                    handleSubmit={this.props.handleSubmit}
                                                                    inDialog
                                                                />,
                                                                false,
                                                                "",
                                                                "standard",
                                                                "xl"
                                                            );
                                                    } else {
                                                        handleSelectOriginal = () => this.handleProductState(res.data, option, true);
                                                        handleSelectReplacement = () => this.handleProductState(rep.data, option, true);
                                                    }

                                                    this.props.deployDialog(
                                                        <ChooseProduct
                                                            original={res.data}
                                                            replacement={rep.data}
                                                            handleSelectOriginal={handleSelectOriginal}
                                                            handleSelectReplacement={handleSelectReplacement}
                                                        />,
                                                        false,
                                                        `Product ${res.data.product_status === "Active" ? "Temporarily Unavailable" : res.data.product_status}`,
                                                        "error"
                                                    );
                                                }
                                            );
                                        }
                                    });
                                } else {
                                    this.handleProductState(res.data, option);
                                }
                            };

                            if (!_.isEmpty(res.data.bundles) && !this.props.inDialog) {
                                this.props.deployDialog(
                                    <ChooseProductBundleOption addProductToForm={addProductToForm} addBundleToForm={this.addBundleToForm} product={res.data} customer={customer} />,
                                    false,
                                    `Product Bundle / Kit Available`,
                                    "standard",
                                    "sm",
                                    false,
                                    true
                                );
                            } else {
                                addProductToForm();
                            }
                        }
                    });
                }
            );
        }
    };

    addBundleToForm = (formData) => {
        this.setState(
            {
                isLoading: false,
                key: uuidv4(),
            },
            () => {
                this.props.handleSubmit(formData);
            }
        );
    };

    handleProductState = (data, option, closeDialog) => {

        const { discount } = this.props;
        const { formData } = this.state;
        let {
            maxDiscount 
        } = this.state;

        let supplierId = data?.product_supplier_id ?? null;
        if(supplierId && discount?.[supplierId]) {
            maxDiscount = parseFloat(discount[supplierId]);
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    bundleId: null,
                    productId: data.product_id,
                    productName: data.product_name,
                    productCode: data.product_code || "-",
                    quantity: this.props.formData ? this.props.formData.quantity : "",
                    cost: parseFloat(data.product_cost).toFixed(2),
                    price: parseFloat(data.product_price).toFixed(2),
                    subTotal: "0.00",
                    discount: "0.00",
                    discountId: "0",
                    discountPercent:
                        !_.isEmpty(this.props.blanketDiscount) && data?.product_no_discount === 0
                            ? parseFloat(this.props.blanketDiscount) > parseFloat(data.discount?.b ?? "0.00")
                                ? parseFloat(this.props.blanketDiscount).toFixed(2)
                                : parseFloat(data.discount?.b ?? "0.00").toFixed(2)
                            : data.discount?.b ?? "0.00",
                    discountTotal: "0.00",
                    total: "0.00",
                    image: data.product_img,
                    productData: data,
                    cdbFormat: !_.isEmpty(formData.cdbFormat) ? formData.cdbFormat : data.cDbF,
                    cdbType: !_.isEmpty(formData.cdbType) ? formData.cdbType : data.cDbT,
                    cdbProgrammer: !_.isEmpty(formData.cdbProgrammer) ? formData.cdbProgrammer : data.cDbP,
                    option,
                },
                maxDiscount,
                isLoading: false,
            },
            () => {
                this.props.handleUnsavedLine?.();
                if (closeDialog) {
                    this.props.closeDialog();
                }
                if (!this.props.formData) {
                    setTimeout(() => this.quantityInput.current.focus(), 50);
                }
            }
        );
    };

    handleShowText = () => {
        this.setState({
            showText: 1,
        });
    };

    handleAddSite = () =>
        this.props.deployDialog(
            <CardDbSiteForm
                customer={this.props.customer}
                callback={() => {
                    this.props.closeDialog();
                    this.props.getCustomerData(this.props.customer);
                }}
                site={false}
            />,
            true,
            "",
            "standard",
            "xs"
        );

    handleChange = (e) => {
        const {
            target: { name, value },
        } = e;

        if (value < 0) return;

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: name === "quantity" ? parseInt(parseFloat(value).toFixed(0)) : parseFloat(value),
                },
            },
            () => {
                this.handleCalculateTotals(name);
            }
        );
    };

    handleSelectChange = (fieldName, selectedOption) => {
        const { formData } = this.state;

        let value = selectedOption?.value ?? "";

        if (fieldName === "cdbSite") {
            let site = _.find(this.props.sites, (el) => el.value === value);

            let cdbIntRangeStart = this.handleCalculateCardOrderRange(site?.latestOrder?.co_internal_range_end ?? 0, 1);
            let cdbIntRangeEnd = this.handleCalculateCardOrderRange(site?.latestOrder?.co_internal_range_end ?? 0, parseInt(formData.quantity) * parseInt(formData?.productData?.product_pack_size ?? 1));

            let cdbExtRangeStart = this.handleCalculateCardOrderRange(site?.latestOrder?.co_external_range_end ?? 0, 1);
            let cdbExtRangeEnd = this.handleCalculateCardOrderRange(site?.latestOrder?.co_external_range_end ?? 0, parseInt(formData.quantity) * parseInt(formData?.productData?.product_pack_size ?? 1));

            let cdbType = site?.latestOrder?.co_type_id ?? this.state.formData?.cdbType ?? "";
            let cdbFormat = site?.latestOrder?.co_format_id ?? this.state.formData?.cdbFormat ?? "";
            let cdbProgrammer = site?.latestOrder?.co_programmer_id ?? this.state.formData?.cdbProgrammer ?? "";

            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: value,
                    cdbIntRangeStart,
                    cdbIntRangeEnd,
                    cdbExtRangeStart,
                    cdbExtRangeEnd,
                    cdbType,
                    cdbFormat,
                    cdbProgrammer,
                    cdbComments: site?.latestOrder?.co_notes ?? "",
                },
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: value,
                },
            });
        }
    };

    handleCalculateCardOrderRange = (rawValue, qty) => {
        let v = rawValue;
        if (_.isNumber(v)) {
            v = parseFloat(v).toFixed(0);
        }

        let value = [...v];

        if (!_.isEmpty(value)) {
            value.reverse();

            let int = [],
                len = 0;

            for (let i = 0; i < value.length; i++) {
                if (isNumeric(value[i])) {
                    int.push(value[i]);
                } else {
                    break;
                }
            }

            if (!_.isEmpty(int)) {
                value.splice(0, int.length);
            }

            value.reverse();

            if (!_.isEmpty(int)) {
                int = int.reverse().join("");
                len = int.length;
                int = String(parseInt(int) + qty).padStart(len, "0");
                value = value.join("") + int;
            } else {
                value = value.join("");
            }

            return value;
        } else {
            return qty;
        }
    };

    handleTextChange = (e) => {
        const {
            target: { name, value },
        } = e;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    handleReset = () => {
        const {
            access 
        } = this.state;
        this.setState({
            ...initialState(),
            access: this.state.access,
            maxDiscount: access.manualDiscountAny ? 100 : access.manualDiscount ? MANUAL_DISCOUNT_THRESHOLD : 0,
        });
    };

    handleSubmit = (e, noClose = false) => {
        if (e) {
            e.preventDefault();
        }

        const { access, delivery, formData, oldIdx } = this.state;

        let formErrors = {};

        if (formData.productId === "") {
            formErrors.productId = "Product cannot be left blank";
        }

        if (formData.quantity === "" || isNaN(formData.quantity)) {
            formErrors.quantity = "Quantity cannot be left blank";
        }

        if (!formErrors.quantity && formData.quantity < 1) {
            formErrors.quantity = "Quantity must be at least 1";
        }

        if(parseFloat(formData.discountPercent) > this.state.maxDiscount && parseFloat(formData.productData?.discount?.b ?? 0) < parseFloat(formData.discountPercent)) {
            formErrors.discount = `Discount must be under ${this.state.maxDiscount}%`;
        }

        if (_.isEmpty(formErrors)) {
            if (!_.isEmpty(formData?.productData?.notes) && !this.state.notesRead) {
                this.handleDeployNotes(true);
            } else {
                this.setState(
                    {
                        ...initialState(),
                        access,
                        maxDiscount: access.manualDiscountAny ? 100 : access.manualDiscount ? MANUAL_DISCOUNT_THRESHOLD : 0,
                    },
                    () => {
                        this.props.handleSubmit(formData, delivery, this.props.delivery, oldIdx);

                        if (this.props.formData) {
                            this.props.deploySnackBar("success", formData.productName + " has been updated");
                        } else {
                            this.props.deploySnackBar("success", formData.productName + " has been added");
                        }

                        if (this.props.inDialog && !noClose) {
                            this.props.closeDialog();
                        }

                        if (!this.props.inDialog) {
                            this.productSelect.current.focus();
                        }
                    }
                );
            }
        } else {
            this.setState({
                formErrors,
            });
        }
    };

    render() {
        const { classes, cardFormats, cardTypes, cardProgrammers, customer, paxtonHardwareExcluded, document, ifm, inDialog, borderRadius, sites, width } = this.props,
            { access, delivery, formData, formErrors, isLoading, notesRead, showText, key, maxDiscount } = this.state,
            notes = _.size(formData?.productData?.notes),
            productSelected = !_.isEmpty(formData.productName);

        let site = null;
        if (formData?.productData?.cDb) {
            site = _.find(this.props.sites, (el) => el.value === formData.cdbSite);
        }

        const discountBlocked = productSelected && parseFloat(formData.discountPercent) > maxDiscount && parseFloat(formData.productData?.discount?.b ?? 0) < parseFloat(formData.discountPercent);

        const isNotDiscountable = !productSelected || (!access.manualDiscount && !access.manualDiscountAny) || (!access.manualDiscountAny && formData?.productData?.product_no_discount === 1 && formData?.productData?.product_no_discount_override === 0);
        
        return (
            <Paper
                elevation={inDialog && 0}
                className={!inDialog ? `pt-1 pl-1` : "pt-3"}
                style={{
                    borderRadius: borderRadius ?? undefined,
                    minHeight: !inDialog ? 95 : 120,
                    width: width ?? undefined,
                }}
            >
                <form onSubmit={this.handleSubmit} noValidate>
                    {discountBlocked && (
                        <Typography className={`bg-error p-1 textLight ${!inDialog ? `mr-1` : ``}`} align="center" variant="body2">
                            <strong>You only have authorisation to discount up to {parseFloat(formData.productData?.discount?.b ?? 0) > maxDiscount ? formData.productData?.discount?.b : maxDiscount}%</strong>
                        </Typography>
                    )}
                    <Table style={{ marginTop: !productSelected ? 12 : undefined }}>
                        <TableBody>
                            <TableRow className="pl-1">
                                <TableCell className={classes.td} style={{ width: ifm ? "38%" : "42.5%" }}>
                                    {(isLoading && <CircularProgress size={40} />) ||
                                        (productSelected && (
                                            <>
                                                <Grid container spacing={1} alignItems="center" style={{ marginTop: -12 }}>
                                                    <Grid item className="pr-2">
                                                        <Avatar src={formData.image ? `${CLOUDFRONT_URL}${formData.image}` : logo} style={{ overflow: "visible", width: 75, height: 75 }} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Box pb={2} pt={2}>
                                                            <Grid container alignItems="flex-end">
                                                                <Grid item>
                                                                    <Typography variant="body2" className="fw-400">
                                                                        {formData.productCode}
                                                                    </Typography>
                                                                </Grid>
                                                                {!inDialog && (
                                                                    <Grid item>
                                                                        <Tooltip title="Modify Product">
                                                                            <IconButton className="ml-1" onClick={this.handleReset} size="small">
                                                                                <FAIcon type="light" icon="times-circle" size={12.5} noMargin button />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={12}>
                                                                    <Typography variant="caption" component="div">
                                                                        {formData.productName}
                                                                    </Typography>
                                                                </Grid>                                                                
                                                                {!formData.bundleId && (
                                                                    <>
                                                                        {(formData?.productData?.srv === 1 && (
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption" component="div">
                                                                                    Managed Service{formData?.productData?.product_supplier_id === -1 ? " by Advanced Access" : ""}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )) || (
                                                                            <>
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant="caption" component="div">
                                                                                        {`${formData.productData?.stock_count ?? 0} In Stock | ${
                                                                                            formData.productData?.stock_allocated_count ?? 0
                                                                                        } Allocated | ${formData.productData?.stock_available_count ?? 0} Available | ${
                                                                                            formData.productData?.stock_on_order_count ?? 0
                                                                                        } On Order | ${formData.productData?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                {formData?.productData?.newPrice && (
                                                                                    <Grid item xs={12}>
                                                                                        <Box pt={0.5} pb={0.5}>
                                                                                            <Typography variant="caption" className="fw-400">
                                                                                                <FAIcon icon="exclamation-triangle" type="solid" className="textError mr-1" size={13.5} />
                                                                                                Trade price is changing to &pound;{formData?.productData.newPrice} on{" "}
                                                                                                {formData?.productData.newPriceDate}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {formData?.productData?.srv !== 1 && (
                                                                            <Grid item>
                                                                                <Box pt={1}>
                                                                                    <ProductStockChip
                                                                                        qty={formData.quantity}
                                                                                        rep={formData.productData?.product_rep_product_id}
                                                                                        status={formData.productData?.product_status}
                                                                                        stocked={formData.productData?.product_stock_item}
                                                                                        stockAvailable={formData.productData?.stock_available_count}
                                                                                        stockOnOrderAvailable={
                                                                                            formData.productData?.stock_on_order_count - formData.productData?.stock_on_order_allocated_count
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        )}
                                                                        {formData.productData?.product_check_price === 1 && (
                                                                            <Grid item>
                                                                                <Box pt={1}>
                                                                                    <InsightChip
                                                                                        background="#FF5722"
                                                                                        color="#fff"
                                                                                        marginRight={8}
                                                                                        paddingLeft={8}
                                                                                        icon="exclamation-triangle"
                                                                                        label="Check Price and Availability"
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        )}
                                                                        {formData.productData?.product_check_weight === 1 && (
                                                                            <Grid item>
                                                                                <Box pt={1}>
                                                                                    <InsightChip
                                                                                        background="#FF5722"
                                                                                        color="#fff"
                                                                                        marginRight={8}
                                                                                        paddingLeft={8}
                                                                                        icon="exclamation-triangle"
                                                                                        label={`Check Weight ${formData?.productData?.product_check_weight_qty_allowed > 0 ? `(> ${formData.productData.product_check_weight_qty_allowed} Qty)` : ``}`}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        )}
                                                                        {formData?.productData?.product_pack_size > 1 && (
                                                                            <Grid item>
                                                                                <Box pt={1}>
                                                                                    <InsightChip
                                                                                        background="#e91e63"
                                                                                        color="#fff"
                                                                                        marginRight={8}
                                                                                        paddingLeft={8}
                                                                                        icon="exclamation-triangle"
                                                                                        label={`Pack Size: ${formData?.productData?.product_pack_size}`}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        )}
                                                                        {formData?.productData?.product_min_order_qty > 1 && (
                                                                            <Grid item>
                                                                                <Box pt={1}>
                                                                                    <InsightChip
                                                                                        background="#e91e63"
                                                                                        color="#fff"
                                                                                        marginRight={8}
                                                                                        paddingLeft={8}
                                                                                        icon="exclamation-triangle"
                                                                                        label={`Min Order Qty: ${formData?.productData?.product_min_order_qty}`}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        )}
                                                                        {formData?.productData?.product_order_multiples_of > 1 && (
                                                                            <Grid item>
                                                                                <Box pt={1}>
                                                                                    <InsightChip
                                                                                        background="#e91e63"
                                                                                        color="#fff"
                                                                                        marginRight={8}
                                                                                        paddingLeft={8}
                                                                                        icon="exclamation-triangle"
                                                                                        label={`Order In Multiples Of: ${formData?.productData?.product_order_multiples_of}`}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        )}
                                                                        {notes > 0 && (
                                                                            <Grid item>
                                                                                <Box pt={1}>
                                                                                    <InsightChip
                                                                                        background={inDialog || notesRead ? "#4CAF50" : "#f44336"}
                                                                                        color="#fff"
                                                                                        marginRight={8}
                                                                                        paddingLeft={8}
                                                                                        icon="comment-alt-lines"
                                                                                        label={notes}
                                                                                        onClick={!inDialog && this.handleDeployNotes}
                                                                                        size={11}
                                                                                        tooltip={!inDialog ? `View Notes` : undefined}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {(showText === 0 && (
                                                                    <Grid item>
                                                                        <Button onClick={this.handleShowText} variant="text" size="small">
                                                                            <FAIcon icon="text" size={12.5} button />
                                                                            Add {formData.productData?.reqTech === 1 ? `Build Requirements` : `Text`}
                                                                        </Button>
                                                                    </Grid>
                                                                )) || (
                                                                    <Grid item xs={12}>
                                                                        <Box mt={2} mr={3}>
                                                                            <Textarea
                                                                                name="text"
                                                                                label="Text"
                                                                                value={formData.text}
                                                                                onChange={this.handleTextChange}
                                                                                disabled={!productSelected}
                                                                                variant="outlined"
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                                {formData.productData?.cDb === 1 && document === "Order" && (
                                                                    <Grid item xs={12}>
                                                                        <Box mt={2}>
                                                                            <Grid container alignItems="center">
                                                                                <Grid item>
                                                                                    <FAIcon icon="exclamation-triangle" className="textWarning mr-1" size={15} />
                                                                                </Grid>
                                                                                <Grid item xs>
                                                                                    <Typography variant="caption" className="fw-400">
                                                                                        This line will require authorisation from the customer once the order has been placed
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                                {formData.productData?.cDb === 1 &&
                                                                    document === "Quote" &&
                                                                    ((this.props.isUpdate && (
                                                                        <Grid item xs={12}>
                                                                            <Box mt={2}>
                                                                                <Grid container alignItems="center">
                                                                                    <Grid item>
                                                                                        <FAIcon icon="exclamation-triangle" className="textWarning mr-1" size={15} />
                                                                                    </Grid>
                                                                                    <Grid item xs>
                                                                                        <Typography variant="caption" className="fw-400">
                                                                                            You can only provide card details when{" "}
                                                                                            <span
                                                                                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                                                                                onClick={() => {
                                                                                                    this.props.closeDialog();
                                                                                                    this.props?.history?.push?.(`/quotes/order/${this.props.id}`);
                                                                                                }}
                                                                                            >
                                                                                                placing an order
                                                                                            </span>{" "}
                                                                                            for this quote
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    )) || (
                                                                        <Grid item xs={12}>
                                                                            <Box mt={2}>
                                                                                <Grid container alignItems="center">
                                                                                    <Grid item>
                                                                                        <FAIcon icon="exclamation-triangle" className="textWarning mr-1" size={15} />
                                                                                    </Grid>
                                                                                    <Grid item xs>
                                                                                        <Typography variant="caption" className="fw-400">
                                                                                            To provide card details now please{" "}
                                                                                            <span
                                                                                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                                                                                onClick={() => this.props?.handleSwitchApiRoute?.()}
                                                                                            >
                                                                                                switch to a sales order
                                                                                            </span>
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    ))}
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )) || (
                                            <ProductSearchSelector
                                                includeBundles
                                                key={key}
                                                selectRef={this.productSelect}
                                                handleAction={this.handleProductChange}
                                                forCustomer={customer}
                                                paxtonHardwareExcluded={paxtonHardwareExcluded}
                                            />
                                        )}
                                </TableCell>
                                <TableCell className={classes.td} style={{ width: "7%" }}>
                                    <TextField
                                        inputRef={this.quantityInput}
                                        name="quantity"
                                        label="Quantity"
                                        value={formData.quantity}
                                        onChange={this.handleChange}
                                        error={(formErrors?.["quantity"] || (ifm && parseInt(formData?.quantity) > parseInt(this.props?.formData?.quantity))) && true}
                                        // helperText={(ifm && (parseInt(formData?.quantity) > parseInt(this.props?.formData?.quantity)))) ? '' : ''}
                                        type="number"
                                        fullWidth
                                        inputProps={{
                                            min: 0,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={!productSelected}
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{ width: "8%" }}>
                                    <TextField
                                        name="price"
                                        label="Trade Price"
                                        value={formData.price}
                                        type="number"
                                        onChange={this.handleChange}
                                        onBlur={this.handleFloat}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        }}
                                        inputProps={{
                                            min: 0,
                                            step: 0.01,
                                        }}
                                        disabled
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{ width: "8%" }}>
                                    <TextField
                                        name="discountPercent"
                                        label="Discount"
                                        value={formData.discountPercent}
                                        error={formData.discountPercent > 100}
                                        type="number"
                                        onChange={this.handleChange}
                                        onBlur={this.handleFloat}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        inputProp={{
                                            min: 0,
                                            max: 100,
                                            step: 0.5,
                                        }}
                                        disabled={isNotDiscountable}
                                        className="mb-0"
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{ width: "8%" }}>
                                    <TextField
                                        name="discount"
                                        label="Discount"
                                        value={formData.discount}
                                        type="number"
                                        onChange={this.handleChange}
                                        onBlur={this.handleFloat}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        }}
                                        disabled={isNotDiscountable}
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{ width: "10%" }}>
                                    <TextField
                                        name="subTotal"
                                        label="Unit Price"
                                        value={formData.subTotal}
                                        onChange={this.handleChange}
                                        onBlur={this.handleFloat}
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        }}
                                        disabled={isNotDiscountable}
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{ width: "10%" }}>
                                    <TextField
                                        name="total"
                                        label="Net Total"
                                        value={formData.total}
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        }}
                                        disabled
                                    />
                                </TableCell>
                                <TableCell className={classes.td}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Tooltip title={`${this.props.formData ? "Apply Changes" : `Add line to ${document}`}`}>
                                                <IconButton
                                                    disabled={
                                                        formData.productId === "" ||
                                                        formData.quantity === "" ||
                                                        formData.quantity < 0 ||
                                                        isNaN(formData.subTotal) ||
                                                        formData.subTotal === "" ||
                                                        formData.subTotal < 0 ||
                                                        formData.discountPercent > 100 ||
                                                        (ifm && delivery === "") ||
                                                        (document === "Order" &&
                                                            formData.productData?.cDb === 1 &&
                                                            (!formData.cdbProgrammer ||
                                                                formData.cdbProgrammer === "" ||
                                                                !formData.cdbType ||
                                                                formData.cdbType === "" ||
                                                                !formData.cdbFormat ||
                                                                formData.cdbFormat === "" ||
                                                                formData.cdbExtRangeEnd === "" ||
                                                                formData.cdbExtRangeStart === "" ||
                                                                formData.cdbIntRangeEnd === "" ||
                                                                formData.cdbIntRangeStart === "" ||
                                                                formData.cdbSite === ""))
                                                    }
                                                    type="submit"
                                                    variant="outlined"
                                                    color="primary"
                                                >
                                                    <FAIcon
                                                        type="thin"
                                                        icon="check-circle"
                                                        size={25}
                                                        className={
                                                            discountBlocked || 
                                                            formData.productId === "" ||
                                                            formData.quantity === "" ||
                                                            formData.quantity < 1 ||
                                                            isNaN(formData.subTotal) ||
                                                            formData.subTotal === "" ||
                                                            formData.subTotal < 0 ||
                                                            formData.discountPercent > 100 ||
                                                            (ifm && delivery === "") ||
                                                            (document === "Order" &&
                                                                formData.productData?.cDb === 1 &&
                                                                (!formData.cdbProgrammer ||
                                                                    formData.cdbProgrammer === "" ||
                                                                    !formData.cdbType ||
                                                                    formData.cdbType === "" ||
                                                                    !formData.cdbFormat ||
                                                                    formData.cdbFormat === "" ||
                                                                    formData.cdbExtRangeEnd === "" ||
                                                                    formData.cdbExtRangeStart === "" ||
                                                                    formData.cdbIntRangeEnd === "" ||
                                                                    formData.cdbIntRangeStart === "" ||
                                                                    formData.cdbSite === ""))
                                                                ? undefined
                                                                : "textDefault"
                                                        }
                                                        button
                                                        noMargin
                                                        disabled={
                                                            discountBlocked || 
                                                            formData.productId === "" ||
                                                            formData.quantity === "" ||
                                                            formData.quantity < 1 ||
                                                            isNaN(formData.subTotal) ||
                                                            formData.subTotal === "" ||
                                                            formData.subTotal < 0 ||
                                                            formData.discountPercent > 100 ||
                                                            (ifm && delivery === "") ||
                                                            (document === "Order" &&
                                                                formData.productData?.cDb === 1 &&
                                                                (!formData.cdbProgrammer ||
                                                                    formData.cdbProgrammer === "" ||
                                                                    !formData.cdbType ||
                                                                    formData.cdbType === "" ||
                                                                    !formData.cdbFormat ||
                                                                    formData.cdbFormat === "" ||
                                                                    formData.cdbExtRangeEnd === "" ||
                                                                    formData.cdbExtRangeStart === "" ||
                                                                    formData.cdbIntRangeEnd === "" ||
                                                                    formData.cdbIntRangeStart === "" ||
                                                                    formData.cdbSite === ""))
                                                        }
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        {inDialog && (
                                            <Grid item>
                                                <Tooltip title="Discard Changes">
                                                    <IconButton onClick={this.props.closeDialog}>
                                                        <FAIcon type="thin" className="textDefault" size={25} icon="times-circle" button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {formData.productData?.cDb === 1 && document === "Order" && (
                        <>
                            <Box pt={1} pb={2}>
                                <Divider />
                            </Box>
                            <Box pb={2}>
                                <Grid container alignItems="center" spacing={3}>
                                    <Grid item xs>
                                        <Typography variant="h6">Card Order Details</Typography>
                                    </Grid>
                                    {site?.latestOrder && (
                                        <Grid item>
                                            <Box pr={3}>
                                                {site?.latestOrder?.co_internal_range_end && (
                                                    <Typography variant="body2">
                                                        <strong>Last Site Internal Range End:</strong> {site?.latestOrder?.co_internal_range_end ?? "N/A"}
                                                    </Typography>
                                                )}
                                                {site?.latestOrder?.co_external_range_end && (
                                                    <Typography variant="body2">
                                                        <strong>Last Site External Range End:</strong> {site?.latestOrder?.co_external_range_end ?? "N/A"}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Table>
                                <TableBody>
                                    <TableRow className="pl-1">
                                        <TableCell className={classes.td} style={{ width: "35%" }}>
                                            <AutoCompleteSelect
                                                disabled={formData.quantity === "" || !formData.quantity}
                                                label="Site *"
                                                options={sites}
                                                value={formData.cdbSite}
                                                onChange={(v) => this.handleSelectChange("cdbSite", v)}
                                                noClear
                                            />
                                        </TableCell>
                                        {!inDialog && (
                                            <TableCell className={classes.td} style={{ width: 32 }}>
                                                <Tooltip title="Add Site">
                                                    <IconButton disabled={formData.quantity === "" || !formData.quantity} onClick={this.handleAddSite}>
                                                        <FAIcon icon="plus" noMargin button />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                        {/* <TableCell className={classes.td} style={{width: '15%'}}>
                                            <TextField
                                                name="cdbOrderNo"
                                                label="Cust Order Reference"
                                                value={formData.cdbOrderNo}
                                                onChange={this.handleTextChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                            />
                                        </TableCell> */}
                                        <TableCell className={classes.td} style={{ width: "12.5%" }}>
                                            <TextField
                                                name="cdbIntRangeStart"
                                                label="Internal Range Start *"
                                                value={formData.cdbIntRangeStart}
                                                onChange={this.handleTextChange}
                                                placeholder={`Last End: ${site?.latestOrder?.co_internal_range_end ?? "N/A"}`}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{ width: "12.5%" }}>
                                            <TextField
                                                name="cdbIntRangeEnd"
                                                label="Internal Range End *"
                                                value={formData.cdbIntRangeEnd}
                                                onChange={this.handleTextChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{ width: "12.5%" }}>
                                            <TextField
                                                name="cdbExtRangeStart"
                                                label="External Range Start *"
                                                value={formData.cdbExtRangeStart}
                                                onChange={this.handleTextChange}
                                                placeholder={`Last End: ${site?.latestOrder?.co_external_range_end ?? "N/A"}`}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{ width: "12.5%" }}>
                                            <TextField
                                                name="cdbExtRangeEnd"
                                                label="External Range End *"
                                                value={formData.cdbExtRangeEnd}
                                                onChange={this.handleTextChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{ width: 60 }}>
                                            <Box width={50} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table>
                                <TableBody>
                                    <TableRow className="pl-1">
                                        <TableCell className={classes.td} style={{ width: "50%" }}>
                                            <TextField
                                                name="cdbComments"
                                                label="Comments"
                                                value={formData.cdbComments}
                                                onChange={this.handleTextChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{ width: "17%" }}>
                                            <AutoCompleteSelect
                                                label="Card Format *"
                                                options={cardFormats}
                                                value={formData.cdbFormat}
                                                onChange={(v) => this.handleSelectChange("cdbFormat", v)}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                                noClear
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{ width: "17%" }}>
                                            <AutoCompleteSelect
                                                label="Card Type *"
                                                options={cardTypes}
                                                value={formData.cdbType}
                                                onChange={(v) => this.handleSelectChange("cdbType", v)}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                                noClear
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{ width: "17%" }}>
                                            <AutoCompleteSelect
                                                label="Programmer Type *"
                                                options={cardProgrammers}
                                                value={formData.cdbProgrammer}
                                                onChange={(v) => this.handleSelectChange("cdbProgrammer", v)}
                                                disabled={!formData.cdbSite || formData.dbSite === ""}
                                                noClear
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{ width: 60 }}>
                                            <Box width={50} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </>
                    )}
                </form>
            </Paper>
        );
    }
}

const mapStateToProps = (state) => ({
    discount: state.notifications.discount
})

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = "", variant = "standard", size = "md", fullscreen = false, disabled = false) =>
            dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disabled)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Product));
