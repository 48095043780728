import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import Textarea from '../Inputs/Textarea';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleSelectChange,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
} from 'Functions/FormFunctions';
import FAIcon from '../Icons/FontAwesome/FAIcon';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    card: {
        color: '#fff',
        height: '270px',
        padding: '20px'
    },
    cardHeader: {
        borderLeft: '2px solid #fff',
        borderRight: '3px solid #fff',
    },
    cardHeaderAction: {
        marginTop: -14,
        paddingRight: 10
    },
    cardHeaderPriority: {
        borderLeft: '2px solid #ef3340',
        borderRight: '3px solid #fff',
    },
    cardContent: {
        padding: '0 16px 16px 16px'
    },
    cardActionArea: {
        alignSelf: 'center',
        marginTop: 0,
        marginRight: 0
    },
})

const initialState = () => ({
    confirmation: {
        submit: false,
    },
    formData: {
        noteRel: "",
        noteRelId: 0,
        noteType: "Standard",
        noteValue: "",
        noteTeam: '',
    },
    formErrors:[],
    isLoading: false
})

class AddNoteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();        
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                noteRel: this.props.relation,
                noteRelId: this.props.id,
            },
            isLoading: this.props.withFao ? true : false
        })
    }

    submit = () => {
        API.post('/misc/notes', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        noteType: "Standard",
                        noteTeam: "",
                        noteValue: ""
                    }
                }, () => {
                    this.props.deploySnackBar("success", "You have successfully added a new note")
                    if(this.props.toggleDialog)
                        this.props.toggleDialog();
                })
            }
        })
    }

    cancel = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                noteType: "Standard",
                noteTeam: "",
                noteValue: ""
            }
        }, () => {
            if(this.props.toggleDialog)
                this.props.toggleDialog();
        })
    }

    render() {
        const { classes, standardOnly, withFao, teamList } = this.props;
        const { formData, formErrors } = this.state;
        return (
            <Card classes={{root: (formData.noteType === 'Important' && classes.cardHeaderPriority) || classes.cardHeader}}>
                <Box p={1.5} pb={1}>
                    <CardHeader
                        avatar={
                            <Avatar 
                                alt={this.props.loggedInStaff.name} 
                                src={this.props.loggedInStaff.picture}
                                style={{width: 35, height: 35}}
                            >
                                {this.props.loggedInStaff.firstName.charAt(0)}
                            </Avatar>
                        }        
                        classes={{
                            root: (formData.noteType === 'Important' && classes.cardHeaderPriorityHeader) || '', 
                            action: classes.cardHeaderAction, 
                            subheader: (formData.noteType === 'Important' && classes.cardHeaderPriorityHeader) || ''
                        }}                                                            
                        title={(
                            <>
                                {this.props.loggedInStaff.name}
                                {` · `}
                                <Typography variant="caption" color={formData.noteType === 'Standard' ? 'textSecondary' : undefined} className={formData.noteType === 'Important' ? 'textError' : undefined}>
                                    New{formData.noteType === 'Important' ? ' Priority' : ''} Note
                                </Typography>
                            </>
                        )}
                    />
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                    <Grid container spacing={1} alignItems="center">
                                        {withFao && (
                                            <Grid item xs={12}>
                                                <AutoCompleteSelect
                                                    label="FAO:"
                                                    options={teamList}
                                                    onChange={v => this.handleSelectChange('noteTeam', v)}
                                                    value={formData.noteTeam}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Textarea
                                                placeholder='Notes:*'
                                                margin="none"
                                                name="noteValue"
                                                value={formData.noteValue}
                                                error={formErrors && formErrors['noteValue']}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                rows={5}
                                            /> 
                                        </Grid>
                                        {!standardOnly && (
                                            <Grid item xs={12} sm={6} align='center'>
                                                <RadioGroup
                                                    aria-label="Note Type *"
                                                    name="noteType"
                                                    onChange={this.handleChange}
                                                    >                      
                                                    <FormControlLabel value="Standard" checked={formData.noteType === 'Standard' && true} control={<Radio color='primary' />} label={<Typography variant="capion" className="fw-400">Standard Note</Typography>} />
                                                    <FormControlLabel value="Important" checked={formData.noteType === 'Important' && true} control={<Radio color='primary' />} label={<Typography variant="capion" className="fw-400">Priority Note</Typography>} />
                                                </RadioGroup>     
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={standardOnly ? 12 : 6} align="right">
                                            <Box pt={1.5}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs />
                                                    <Grid item>
                                                        <Button onClick={() => this.cancel()}
                                                                variant="text"
                                                        >
                                                            <FAIcon icon="times" size={15} button />
                                                            Discard
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button 
                                                            onClick={() => this.handleConfirmationOpen('submit')}
                                                            variant="text" 
                                                            color="primary" 
                                                            disabled={!this.state.formData.noteValue}
                                                        >
                                                            <FAIcon icon="check" size={15} button />
                                                            Add Note
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>  
                                <ConfirmationDialog 
                                    open={this.state.confirmation.submit} 
                                    success={() => this.handleConfirmationSuccess('submit')} 
                                    close={() => this.handleConfirmationClose('submit')} 
                                    title="Add a new note?" 
                                    message="Are you sure you want to add this note?"
                                />                            
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </Card>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        teamList: state.notifications.teamList
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddNoteForm));