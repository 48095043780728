import React from 'react';
import AaLogo from 'Assets/Images/logo_icon.png';
import ProductLogo from 'Assets/Images/no-img.jpg';

const ImageWithError = ({ fallBackToAA, alt, src, width, height, style, className, onClick}) => (
    <img 
        alt={alt ? alt : 'Image'}
        src={src ? src : 'NoImgSrcDeclaredFallback'}
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        onError={e => {
            e.target.onerror = null;
            e.target.src = fallBackToAA ? AaLogo : ProductLogo;
        }}
    />
)

export default ImageWithError;