import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const SnackBar = ({anchorOriginHorizontal, anchorOriginVertical, autoHideDuration, message, onClose, open, variant}) => (
    <MuiSnackbar
        autoHideDuration={autoHideDuration ?? 1000}
        anchorOrigin={{
            vertical: anchorOriginVertical ?? undefined, 
            horizontal: anchorOriginHorizontal ?? undefined
        }}
        open={open}
        onClose={onClose}
    >
        <Alert
            onClose={onClose}
            severity={variant}
            variant="filled"
        >
            <span className="fw-400">{message}</span>
        </Alert>
    </MuiSnackbar>
)

export default SnackBar;