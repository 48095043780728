import React, { useContext } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
// import { useDispatch } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { fade, withStyles } from '@material-ui/core/styles';

import API from 'API';
// import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import InsightChip from 'Components/Common/Chips/InsightChip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import WildixContext from 'Context/WildixContext';
// import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { CLOUDFRONT_URL } from 'Constants';
import { isTablet } from 'Functions/MiscFunctions';

const styles = theme => ({
    container: {
        width: isTablet() ? 500 : 575, 
        marginLeft: -42.5
    },
    paper: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: 16,
        textAlign: 'left',
        maxHeight: isTablet() ? 500 : 575,
        overflowY: 'scroll',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
    },
    header: {
        backgroundColor: '#eee'
    },
    icon: {
        border: '1px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            borderColor: '#ddd'
        }
    },
    result: {
        overflowX: 'hidden',
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        }
    },
    resultGrid: {
        padding: theme.spacing(0, 2, 0, 2)
    },
    popper: {
        zIndex: 99999,
    },
    search: {
        width: 300,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.5, 2, 0.5, 2),
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        transition: '0.2s'
    },
})

const initialState = {
    access: {
        customer: false,
        product: false,
        quotes: false,
        addQuote: false,
        addOrder: false,
        addPo: false,
        orders: false,
        newBiz: false,
        emailSend: false
    },
    active: false,
    anchorEl: null,
    categories: {
        quotes: false,
        orders: false,
        bundles: false,
        products: false,
        rma: false,
        customers: false,
        suppliers: false,
        contacts: false
    },
    filter: null,
    isLoading: false,
    initialLoad: true,
    searchResults: [],
    searchString: ''
}

// const handleDeployComposeEmail = (dispatch, emailAddress) => {
//     const defaultFormData = {
//         emailTo: {
//             label: emailAddress,
//             value: emailAddress
//         }
//     }
//     dispatch(deployDialog(
//         <EmailDialog
//             id={false}
//             type='default'
//             defaultFormData={defaultFormData}
//         />, false, "Compose E-mail", "standard","md"));
// }

const Welcome = ({access, classes, handleRedirect, isLoading}) => (
    <Box p={3}>
        {(isLoading && (
            <LoadingCircle />
        )) || (
            <>
                <Typography variant="h4" align='center' gutterBottom>
                    Start typing to search...
                </Typography>
                <Box pt={2} pb={1}>
                    <Divider />
                </Box>
                <Grid container justify='center'>
                    {access.customer && (
                        <Grid item align='center'>
                            <Box p={2.13} className={classes.icon} onClick={() => handleRedirect('/customers')}>
                                <Typography variant="caption" align="center">
                                    <FAIcon type="thin" icon='users' size="large" noMargin button /><br />
                                    Customers
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {access.newBiz && (
                        <Grid item align='center'>
                            <Box p={2.13} className={classes.icon} onClick={() => handleRedirect('/new-business')}>
                                <Typography variant="caption" align="center">
                                    <FAIcon type="thin" icon='user-chart' size="large" noMargin button /><br />
                                    New Business
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {access.product && (
                        <Grid item align='center'>
                            <Box p={2.13} className={classes.icon} onClick={() => handleRedirect('/products')}>
                                <Typography variant="caption" align="center">
                                    <FAIcon type="thin" icon='boxes' size="large" noMargin button /><br />
                                    Products
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {access.quotes && (
                        <Grid item align='center'>
                            <Box p={2.13} className={classes.icon} onClick={() => handleRedirect('/quotes')}>
                                <Typography variant="caption" align="center">
                                    <FAIcon type="thin" icon='quote' size={30} button style={{marginRight: 18, marginBottom: 8.5}} />
                                    Quotes
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {access.orders && (
                        <Grid item align='center'>
                            <Box p={2.13} className={classes.icon} onClick={() => handleRedirect('/sales')}>
                                <Typography variant="caption" align="center">
                                    <FAIcon type="thin" icon='order' size={30} button style={{marginRight: 18, marginBottom: 8.5}} />
                                    Orders
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </>
        )}
    </Box>
)

const Results = ({access, categories, classes, filter, handleAction, handleClose, handleFilterResults, history, searchResults, searchString}) => (
    (!_.isEmpty(searchResults) && (
        <>
            <Box pl={2} pr={2} pt={2} pb={1}>
                <Typography variant="h6" gutterBottom>
                    Search Results ({_.size(searchResults)})
                </Typography>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs>
                        {categories.customers && (
                            <InsightChip
                                color={filter === "Customer" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Customer')} 
                                icon="users"
                                label={`Customers`}
                            />
                        )}
                        {categories.quotes && (
                            <InsightChip
                                color={filter === "Quote" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Quote')} 
                                icon="quote"
                                label={`Quotes`}
                            />
                        )}
                        {categories.po && (
                            <InsightChip
                                color={filter === "Purchase Order" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Purchase Order')} 
                                icon="purchase"
                                label={`Purchase Orders`}
                            />
                        )}
                        {categories.orders && (
                            <InsightChip
                                color={filter === "Sales Order" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Sales Order')} 
                                icon="order"
                                label={`Orders`}
                            />
                        )}
                        {categories.rma && (
                            <InsightChip
                                color={filter === "RMA" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('RMA')} 
                                icon="arrow-to-bottom"
                                label={`RMA`}
                            />
                        )}
                        {categories.bundles && (
                            <InsightChip
                                color={filter === "Bundle/Kit" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Bundle/Kit')} 
                                icon="box-check"
                                label={`Bundles/Kits`}
                            />
                        )}
                        {categories.products && (
                            <InsightChip
                                color={filter === "Product" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Product')} 
                                icon="boxes"
                                label={`Products`}
                            />
                        )}
                        {categories.suppliers && (
                            <InsightChip
                                color={filter === "Supplier" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Supplier')} 
                                icon="box-check"
                                label={`Suppliers`}
                            />
                        )}
                        {categories.contacts && (
                            <InsightChip
                                color={filter === "Contacts" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Contacts')} 
                                icon="user"
                                label={`Contacts`}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
            {_.map(searchResults, (result, idx) => (
                    <Result 
                        key={idx} 
                        access={access}
                        classes={classes} 
                        handleAction={handleAction}
                        handleClose={handleClose}
                        history={history}
                        {...result} 
                    />
            ))}
        </>
    )) || (
        <Box pl={3} pr={3} pt={2} pb={2.5}>
            <Grid container alignItems='center'>
                <Grid item>
                    <Box pt={1}>
                        <FAIcon icon="times" className="textError" size={35} />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box pl={2}>
                        <Typography variant="body1" className="fw-400">
                            Sorry, there were no results found matching:
                        </Typography>
                        <Typography variant="body2" component="em">
                            {searchString}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
)

const Result = ({access, action, d, email, history, icon, id, img, handleAction, handleClose, p, title, type, subtitle1, subtitle2, classes, cp, cw, cwq, ps, mno, omo, dim, wei, pc, pcd, st}) => {
    // const dispatch = useDispatch();
    const context = useContext(WildixContext);
    const canDial = context?.dial === null;
    return (
        <Box pt={1.75} pb={1.75} className={classes.result} onClick={() => handleAction(action)}>
            <Grid container spacing={1} alignItems='center' className={classes.resultGrid}>
                <Grid item>
                    {(img && (
                        <Avatar variant="square" className="mr-1" style={{background: 'transparent', width: 40, height: 40}}><ImageWithError alt="Product Image" width="100%" height="100%" src={`${CLOUDFRONT_URL}${img}`} /></Avatar>
                    )) || (
                        <FAIcon type="thin" icon={icon} className="mr-1" style={{background: 'transparent', width: 25, height: 25, marginLeft: 4}} noMargin />
                    )}
                </Grid>
                <Grid item xs>
                    <Typography variant="body2" className="fw-400">
                        {title.substr(0,80)}{title.length > 80 ? '...' : ''} 
                    </Typography>
                    <Typography variant="caption" component="div" color="textSecondary">
                        {type} 
                    </Typography>
                    {subtitle1 && ( 
                        <Typography variant="caption" component="div" >
                            {subtitle1.substr(0,80)}{subtitle1.length > 80 ? '...' : ''}
                        </Typography>
                    )}
                    {subtitle2 && ( 
                        <Typography variant="caption" component="div" >
                            {subtitle2.substr(0,80)}{subtitle2.length > 80 ? '...' : ''}
                        </Typography>
                    )}
                    {(cp === 1 || cw === 1 || ps || mno > 1 || omo > 1 || dim || wei) ? (
                        <Box className="pt-1">
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs={12} className="pt-0 pb-05">
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item className="pt-0 pb-0">
                                            <Typography variant="caption" style={{color: '#111111' }}>
                                                <FAIcon icon="box" size={11.5} style={{color: '#777'}} noMargin />{' '}Pack Size: {ps}
                                            </Typography>
                                        </Grid>
                                        {wei && (
                                            <Grid item className="pt-0 pb-0">
                                                <Typography variant="caption" style={{color: '#111111' }}>
                                                    <FAIcon icon="weight-hanging" size={11.5} style={{color: '#777'}} noMargin />{' '}Weight: {wei}kg
                                                </Typography>
                                            </Grid>
                                        )}
                                        {dim && (
                                            <Grid item className="pt-0 pb-0">
                                                <Typography variant="caption" style={{color: '#111111' }}>
                                                    <FAIcon icon="ruler" size={11.5} style={{color: '#777'}} noMargin />{' '}Dimensions: {dim}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {cp === 1 && ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#FF5722' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}Check Price and Availability</strong>
                                        </Typography>
                                    </Grid>
                                )}
                                {st ? ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#ef3340' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}{st}</strong>
                                        </Typography>
                                    </Grid>
                                ) : null}
                                {cw === 1 && ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#FF5722' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}Check Weight{cwq > 0 ? ` (> ${cwq} Qty)` : ``}</strong>
                                        </Typography>
                                    </Grid>
                                )}
                                {mno > 1 && ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#e91e63' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}Min Order Qty: {mno}</strong>
                                        </Typography>
                                    </Grid>
                                )}
                                {omo > 1 && ( 
                                    <Grid item className="pt-0 pb-0">
                                        <Typography variant="caption" style={{color: '#e91e63' }}>
                                            <strong><FAIcon icon="exclamation-triangle" size={11.5} noMargin />{' '}Order In Multiples Of: {omo}</strong>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    ) : null}
                    {pc && (
                        <Grid item xs={12}>
                            <Box pt={0.5} pb={0.5}>
                                <Typography variant="caption" className="fw-400">
                                    <FAIcon icon="exclamation-triangle" type="solid" className="textError mr-1" size={13.5} /> 
                                    Trade price is changing to &pound;{pc} on {pcd}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Grid item align='right'>
                    <Grid container>
                        {p && canDial ? (
                            <Grid item>
                                <Tooltip title={`Call ${title}`}>
                                    <IconButton onClick={(e) => {e.stopPropagation(); context.useDial(p); }}>
                                        <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        ) : null}
                        {type === 'Customer' && (
                            <>
                                {access.addQuote && !d && (
                                <Grid item>
                                    <Tooltip title="New Quote">
                                        <IconButton
                                            onClick={e => {e.stopPropagation(); handleClose(); history.push(`/quotes/new/${id}`)}}
                                        >
                                            <FAIcon icon="quote" noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                )}
                                {access.addOrder && !d && (
                                    <Grid item>
                                        <Tooltip title="New Order">
                                            <IconButton
                                                onClick={e => {e.stopPropagation(); handleClose(); history.push(`/sales/new/${id}`)}}
                                            >
                                                <FAIcon icon="order" noMargin button />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </>
                        )}
                        {type === 'Supplier' && (
                            access.addPo && (
                                <Grid item>
                                    <Tooltip title="New Purchase Order">
                                        <IconButton
                                            onClick={e => {e.stopPropagation(); handleClose(); history.push(`/purchase-orders/new/${id}`)}}
                                        >
                                            <FAIcon icon="purchase" noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )
                        )}
                        {/* {access.emailSend && !_.isEmpty(email) && (
                            <Grid item>
                                <Tooltip title="Send E-mail">
                                    <IconButton
                                        onClick={e => {e.stopPropagation(); handleClose(); handleDeployComposeEmail(dispatch, email)}}
                                    >
                                        <FAIcon icon="envelope" noMargin button />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )} */}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

class AdminSmartSearch extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
        this.popper = false;
    }

    componentDidMount = () => {
        Promise.all([
            API.access('customer-search'),    
            API.access('product-search'),    
            API.access('search-quotes'),    
            API.access('add-quote'),    
            API.access('add-order'),    
            API.access('add-po'),    
            API.access('all-orders'),    
            API.access('new-business'),    
            API.access('email-send'),    
        ])
        .then(([customer, product, quotes, addQuote, addOrder, addPo, orders, newBiz, emailSend]) => {
            this.setState({
                access: {
                    ...this.state.access,
                    customer: (customer.data && customer.data.has_access) || false,
                    product: (product.data && product.data.has_access) || false,
                    quotes: (quotes.data && quotes.data.has_access) || false,
                    addQuote: (addQuote.data && addQuote.data.has_access) || false,
                    addOrder: (addOrder.data && addOrder.data.has_access) || false,
                    addPo: (addPo.data && addPo.data.has_access) || false,
                    orders: (orders.data && orders.data.has_access) || false,
                    newBiz: (newBiz.data && newBiz.data.has_access) || false,
                    emailSend: (emailSend.data && emailSend.data.has_access) || false,
                },
                initialLoad: false
            })
        })
    }

    componentWillUnmount = () => {
        if(this.popper)
            clearTimeout(this.popper);
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    handleAction = action => {
        if(!_.isEmpty(action)) {
            Object.keys(action).forEach(key => {
                if(key === "href") {
                    this.handleRedirect(action[key])
                }
            })
        }
    }

    handleRedirect = href => {
        this.handleClose()
        this.props.history.push(href)
    }

    getSearchResults = () => {
        const { searchString } = this.state;
        if(searchString.length >= 2) {
            API.get('/smartSearch', { 
                props: {
                    cancellation: true
                },
                params: { 
                    searchString: searchString
                } 
            })
            .then(result => {
                if(result && result.data) {
                    let isLoading = false,
                        categories = result.data?.categories ?? [],
                        searchResults = result.data?.results ?? [];
                    this.setState({
                        isLoading,
                        categories,
                        searchResults
                    });
                } 
            });
        }
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchResults, 200);
    }

    onSearchStringChange = (event) => {
        const searchString = event.target.value,
              searchResults = [],
              filter = null,
              isLoading = searchString.length >= 2 ? true : false;
        this.setState({
            isLoading,
            filter,
            searchString,
            searchResults
        }, () => {
            this.setSearch();
        });
    }
    
    handleOpen = event => {
        const anchorEl = event.currentTarget;
        this.setState({
            active: true
        }, () => {
            this.popper = setTimeout(() => {
                this.setState({
                    anchorEl
                })
            }, 200);   
        });
    }

    handleClose = () => {
        if(document?.activeElement?.id === 'search') {
            return;
        }
        const   active = false,
                anchorEl = null,
                filter = null,
                searchResults = initialState.searchResults,
                searchString = initialState.searchString;

        this.setState({
            active,
            anchorEl,
            filter,
            searchResults,
            searchString
        });
    }

    handleFilterResults = filter => {
        this.setState({
            filter: this.state.filter === filter ? null : filter
        })
    }

    render() {
        const { 
            classes,
            device
        } = this.props;

        const { 
            active, 
            access, 
            anchorEl, 
            categories, 
            filter, 
            initialLoad, 
            isLoading, 
            searchString 
        } = this.state;

        let { 
            searchResults
        } = this.state;
            
        if(filter) {
            if(filter === "Contacts") {
                searchResults = _.filter(searchResults, el => el.type === "Customer Contact" || el.type === "Supplier Contact");
            } else {
                searchResults = _.filter(searchResults, el => el.type === filter);
            }
        }  

        return (
            (!device?.isPortrait && (
                <ClickAwayListener onClickAway={this.handleClose}>
                    <div>
                        <TextField
                            id='search'
                            classes={{
                                root: classes.search,
                                underline: classes.searchUnderline
                            }}
                            color="secondary"
                            disabled={initialLoad}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size={17.5} className="textLight" /></InputAdornment>,
                                disableUnderline: true
                            }}
                            onChange={this.onSearchStringChange}
                            onFocus={this.handleOpen}
                            style={{
                                width: active ? device?.isTablet ? 500 : 575 : undefined,
                                padding: 2,
                                paddingLeft: device?.isTablet ? 8 : undefined,
                                marginLeft: device?.isTablet ? -28.5 : 28.5,
                                marginTop: 1.5
                            }}
                            value={searchString}
                            autoComplete='off'
                            // placeholder="Search"
                        /> 
                        <Popper 
                            open={anchorEl ?? false} 
                            anchorEl={anchorEl} 
                            className={classes.popper}
                            disablePortal={true} 
                            style={{marginTop: device?.isTablet ? -7.5 : -5, marginLeft: device?.isTablet ? 10 : 0}}
                            id={active ? 'smartSearchResults' : undefined} 
                        >
                            <div className={classes.container}>
                                <Paper className={classes.paper}>
                                    {(!isLoading && _.size(searchString) >= 2 && (
                                        <Results
                                            access={access}
                                            categories={categories}
                                            classes={classes} 
                                            filter={filter}
                                            history={this.props.history}
                                            searchResults={searchResults} 
                                            searchString={searchString}
                                            handleAction={this.handleAction}
                                            handleClose={this.handleClose}
                                            handleFilterResults={this.handleFilterResults}
                                        />
                                    )) || (
                                        <Welcome
                                            access={access}
                                            classes={classes}
                                            isLoading={isLoading}
                                            handleRedirect={this.handleRedirect}
                                        />
                                    )}
                                </Paper>
                            </div>
                        </Popper>
                    </div>
                </ClickAwayListener>
            )) || (
                null
            )
        )
    }
}

export default withRouter(withStyles(styles)(AdminSmartSearch));