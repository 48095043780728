import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { Dial } from 'Functions/MiscFunctions';
import { Chip } from '@material-ui/core';

const CallHistory = () => {
    const staff = useSelector(state => state.notifications.status);
    
    const getIcon = (status) => {
        switch (status) {
            case "Answered":
                return <FAIcon icon="phone-volume" type="light" size={15} noMargin style={getStyle(status)} />;
            case "No Answer":
                return <FAIcon icon="phone-slash" type="light" size={15} noMargin style={getStyle(status)} />;
            default:
                return <FAIcon icon="voicemail" type="light" size={15} noMargin style={getStyle(status)} />;
        }
    };

    const getStyle = (status) => {
        let style = {
            paddingLeft: 8,
            fontWeight: 400,
        };
        switch (status) {
            case "Answered":
                style.backgroundColor = "#d9eeda";
                style.color = "#000";
                break;
            case "No Answer":
                style.backgroundColor = "#f2dede";
                style.color = "#000";
                break;
            default:
                style.backgroundColor = "#fff";
                style.color = "#000";
                break;
        }
        return style;
    };

    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/callHistory',
                noAutoLoad: true,
                columns: [
                    {
                        heading: "Date / Time",
                        field: (rowData) => rowData.dt,
                        fieldFormat: 'datetime-full',
                        dataRef: "dt",
                        important: true,
                        sizeToContent: true,
                        nestingDropdown: true,
                    },
                    {
                        heading: "Duration",
                        field: (rowData) => (parseInt(rowData.du) > 0 ? moment.utc(parseInt(rowData.du) * 1000).format(rowData.du > 3600 ? "hh:mm:ss" : "mm:ss") : "-"),
                        dataRef: "du",
                        fieldFormat: 'int',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Direction",
                        field: (rowData) => rowData.ic,
                        dataRef: "ic",
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Contact",
                        field: (rowData) => (rowData.nm.replace(/\s/g, "") === "" ? "-" : rowData.nm),
                        fieldFormat: 'string',
                        dataRef: 'nm',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Company",
                        field: (rowData) => rowData.cn || "-",
                        fieldFormat: 'string',
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Phone Number",
                        field: (rowData) => <Dial number={rowData.nu} />,
                        fieldFormat: 'phone',
                        dataRef: 'nu',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Staff",
                        field: (rowData) => <StaffChip suffix={rowData.de ? ` (${rowData.de})` : null} staff={staff?.[rowData.us] ?? null} />,
                        fieldFormat: "staff",
                        dataRef: "us",
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Status",
                        field: (rowData) => <Chip icon={getIcon(rowData.st)} label={rowData.st || "Answered"} style={getStyle(rowData.st)} />,
                        dataRef: "st",
                        important: true,
                        sizeToContent: true,
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'call-history',
                noResultsText: 'No call history found for the selected criteria',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Call Reports'],
                pdf: true,
                persistenceId: null,
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: true,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showStaff: true,
                showSupplier: true,
                showTotals: false,
                title: 'Call History Report'
            }}
        />
    )
}

export default CallHistory;