import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Line } from 'react-chartjs-2';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import CustomerSearch from 'Components/Customers/CustomerSearch/CustomerSearch';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Tiles/Tile';
import TaskList from 'Components/Common/Tasks/TaskList';
import { currencyFormat, momentFormatDateTime } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

import Bulletin from 'Components/Dashboard/Common/Bulletin';
import Calendar from 'Components/Dashboard/Common/Calendar';

const AccountsDashboard = ({pageTitle}) => {

    const [data, setData] = useState(null);

    
    const [scsChange, setScsChange] = useState(false);
    
    const [access, setAccess] = useState({
        manageSageConnector: false
    });

    const dispatch = useDispatch();

    const loadComponentData = () => {

        API.access('sage-connector-state')
        .then(res => {
            if(res?.data?.has_access) {
                setAccess({
                    manageSageConnector: true
                });
            }
        })

        API.get(`/dashboard`, {
            params: {
                d: 'accounts'
            },
            props: {
                cancellation: true
            }
        })
        .then(res => {
            if(res?.data) {

                let newData = res.data,
                    labels = [],
                    orderApprovals = [],
                    newAccounts = [],
                    closeAccounts = [];

                if(newData.chart) {

                    for (let i = 0, j = 11; i <= j; i++) {
                        orderApprovals.push(res.data.chart?.[i]?.orderApprovals);
                        newAccounts.push(res.data.chart?.[i]?.newAccounts);
                        closeAccounts.push(res.data.chart?.[i]?.closeAccounts);
                        labels.push(res.data.chart?.[i]?.label);
                    }
                    newData.chart = {
                        labels,
                        datasets: [
                            // {
                            //     label: 'Order Approvals',
                            //     data: orderApprovals,
                            //     fill: false,
                            //     backgroundColor: '#FF9800',
                            //     borderColor: '#FFE0B2',
                            //     lineTension: 0.25,
                            // },
                            {
                                label: 'Closed Accounts',
                                data: closeAccounts,
                                fill: false,
                                backgroundColor: '#ef3340',
                                borderColor: '#FFCDD2',
                                lineTension: 0.25,
                            },
                            {
                                label: 'New Accounts',
                                data: newAccounts,
                                fill: false,
                                backgroundColor: '#4CAF50',
                                borderColor: '#C8E6C9',
                                lineTension: 0.25,
                            }
                        ]
                    }
    
                }

                setData(newData);
                setScsChange(false);

            }
        });
    }

    const toggleSageConnectorState = () => {
        setScsChange(true);
        API.put(`/settings/sageConnectorState`)
        .then(res => {
            if(res?.data) {
                loadComponentData();
            }
        })
    }

    useEffect(() => {
        pageTitle?.([0, 'Dashboard', 'Accounts']);
        loadComponentData();
    }, [pageTitle]);

    return (
        (!data && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12} xl={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="h6" paragraph>
                                                Sage Connector
                                            </Typography>
                                        </Grid>
                                        {access.manageSageConnector && data?.connector?.on && (
                                            <Grid item>
                                                {(scsChange && (
                                                    <LoadingCircle padding={8} size={20} />
                                                )) || ( 
                                                    <Tooltip title={data?.connector?.st === 1 ? 'Pause Sage Connector' : 'Resume Sage Connector'}>
                                                        <IconButton onClick={() => dispatch(deployConfirmation(`Are you sure you want to ${data?.connector?.st === 1 ? `pause` : `resume`} the sage connector?`, toggleSageConnectorState))}>
                                                            <FAIcon icon={data?.connector?.st === 1 ? 'pause-circle' : 'play-circle'} size={20} noMargin button />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            <Tile
                                                noPaper
                                                color={data?.connector?.on ? (data?.connector?.st === 1 ? '#4CAF50' : '#FF6F00') : '#F44336'}
                                                icon={data?.connector?.on ? (data?.connector?.st === 1 ? 'check' : 'exclamation-circle') : 'exclamation-triangle'}
                                                content={data?.connector?.on ? (data?.connector?.st === 1 ? 'Online' : 'Disconnected') : 'Offline'}
                                                title={'Status'}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Tile
                                                noPaper
                                                icon={'clock'}
                                                content={momentFormatDateTime(data?.connector?.lo)}
                                                title={'Last Online'}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Tile
                                                noPaper
                                                icon={'check'}
                                                content={momentFormatDateTime(data?.connector?.lr)}
                                                title={'Last Request'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Credit Accounts
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='check'
                                                title="Open"
                                                content={data?.stats?.open}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='exclamation-triangle'
                                                title="Monitored"
                                                content={data?.stats?.mon}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='info-circle'
                                                title="Total"
                                                content={data?.stats?.total}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='arrow-to-top'
                                                title="Combined Credit Limit"
                                                content={currencyFormat.format(data?.stats?.li)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='arrow-to-bottom'
                                                title="Combined Credit Balance"
                                                content={currencyFormat.format(data?.stats?.ba)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <Tile
                                                noPaper
                                                icon='expand-alt'
                                                title="Combined Credit Available"
                                                content={currencyFormat.format(data?.stats?.av)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        New Credit Accounts
                                    </Typography>
                                    <CustomerSearch
                                        dashboard
                                        inline
                                        showNewOnly
                                        showCreditOnly 
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Outstanding Proformas Sent
                                    </Typography>
                                    {(!_.isEmpty(data?.proformas) && (
                                        <DataTable
                                            config={{
                                                noResultsText: "No outstanding proformas to display",
                                                noRowHover: true,
                                                inline: true
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Order Number',
                                                    field: rowData => rowData.r,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Customer',
                                                    field: rowData => rowData.cu,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Date',
                                                    field: rowData => rowData.c,
                                                    fieldFormat: 'date',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Gross Total',
                                                    field: rowData => rowData.t,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'View', icon: 'binoculars', link: `/sales/${rowData.i}`}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={data?.proformas ?? []} 
                                        />
                                    )) || (
                                        <Alert severity="success" variant="outlined">
                                            <strong>None - </strong>There are currently no outstanding proformas sent
                                        </Alert>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Outstanding Return Credits
                                    </Typography>
                                    {(!_.isEmpty(data?.returns) && (
                                        <DataTable
                                            config={{
                                                noResultsText: "No outstanding return credits to display",
                                                noRowHover: true,
                                                inline: true
                                            }}
                                            columns={[
                                                {
                                                    heading: 'RMA Number',
                                                    field: rowData => rowData.r,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Company',
                                                    field: rowData => rowData.n,
                                                    truncate: true
                                                },
                                                {
                                                    heading: 'Product',
                                                    field: rowData => rowData.p,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Returned On',
                                                    field: rowData => rowData.c,
                                                    fieldFormat: 'date',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Total Credit',
                                                    field: rowData => rowData.t,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'View', icon: 'binoculars', link: `/rma/${rowData.i}`}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={data?.returns ?? []} 
                                        />
                                    )) || (
                                        <Alert severity="success" variant="outlined">
                                            <strong>None - </strong>There are currently no outstanding return credits
                                        </Alert>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Operational Insight
                                    </Typography>
                                    <Box height={400} width={'100%'} maxWidth={'100%'} minHeight={300}>
                                        <Line 
                                            data={data.chart} 
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom"
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,.03)",
                                                        },
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0
                                                        }
                                                    }]
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    <Typography variant="h6" paragraph>
                                        Actions Due Today
                                    </Typography>
                                    <TaskList />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Bulletin />
                        </Grid>
                        <Grid item xs={12}>
                            <Calendar />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

export default AccountsDashboard;