import React, { useEffect, useState }from 'react';
import { useDispatch } from 'react-redux';
import uuidv4 from 'uuid/v4';

import API from 'API';
import Search from 'Components/Common/Search/Search';

import AddStaffForm from 'Components/Staff/AddStaff/AddStaffForm';
import Avatar from 'Components/Common/Avatars/Avatar';
import Button from '@material-ui/core/Button';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { capitalize } from 'Functions/MiscFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = () => ({
    access: {
        updateStaff: false,
        staffAccess: false,
        viewStaff: false,
        addStaff: false
    },
    key: uuidv4()
})

const StaffSearch = ({pageTitle}) => {
    
    const [state, setState] = useState(initialState());

    const dispatch = useDispatch(); 

    // const staff = useSelector(state => state.notifications.status);

    const handleDeployAddStaff = () => {
        dispatch(deployDialog(
            <AddStaffForm close={() => dispatch(closeDialog())} callback={refreshData} />,
            false,
            "Add Staff Member",
            "standard",
            "xs"
        ))
    }

    const refreshData = () => {
        setState(state => ({
            ...state,
            key: uuidv4()
        }))
    }

    useEffect(() => {
        API.multiAccess([
            'add-staff',
            'update-staff', 
            'view-staff',
            'staff-access'
        ])
        .then(([
            addStaff, 
            updateStaff, 
            viewStaff, 
            staffAccess
        ]) =>  {
            setState(state => ({
                ...state,
                access: {
                    addStaff, 
                    updateStaff, 
                    viewStaff, 
                    staffAccess
                }
            }));
        })
    }, []);

    const {
        access,
        key
    } = state;

    return (
        <Search
            innerKey={key}
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/staff/all',
                columns: [
                    {
                        heading: '',
                        field: rowData => <Avatar staffId={rowData.staff_id} />,
                        sizeToContent: true
                    },
                    {
                        heading: 'Name',
                        field: rowData => (
                            <>
                                {rowData.staff_first_name} {rowData.staff_last_name}
                            </>
                        ),
                        dataRef: 'staff_first_name',
                        important: true,
                        main: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Email',
                        field: rowData => rowData.staff_email,
                        dataRef: 'staff_email',
                        important: true,
                        truncate: true
                    },
                    {
                        heading: 'Breathe HR',
                        field: rowData => rowData.staff_breathe_hr_ref,
                        dataRef: 'staff_breathe_hr_ref',
                        fieldFormat: 'boolean',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'Mailing List',
                        field: rowData => rowData.ml,
                        dataRef: 'ml',
                        fieldFormat: 'boolean',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'MFA',
                        field: rowData => rowData.staff_mfa,
                        dataRef: 'staff_mfa',
                        fieldFormat: 'boolean',
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Notifications',
                        field: rowData => rowData.nt,
                        dataRef: 'nt',
                        fieldFormat: 'boolean',
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Accounts',
                        field: rowData => rowData.acc === 0 ? `-` : rowData.acc,
                        dataRef: 'acc',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'Quotes',
                        field: rowData => rowData.cq === 0 ? `-` : rowData.cq,
                        dataRef: 'cq',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'Orders',
                        field: rowData => rowData.co === 0 ? `-` : rowData.co,
                        dataRef: 'co',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    // {
                    //     heading: 'Outstanding Actions',
                    //     field: rowData => rowData.outstanding,
                    //     dataRef: 'outstanding',
                    //     sizeToContent: true,                                
                    //     alignment: 'center'
                    // },
                    // {
                    //     heading: 'Overdue Actions',
                    //     field: rowData => rowData.overdue,
                    //     dataRef: 'overdue',
                    //     sizeToContent: true,                                
                    //     alignment: 'center',
                    //     cellProps: rowData => ({
                    //         className: rowData.overdue > 0 && 'textError'
                    //     })
                    // },
                    {
                        heading: 'Last Quote',
                        field: rowData => rowData?.lq ?? '-',
                        fieldFormat: 'date',
                        dataRef: 'lq',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'Last Order',
                        field: rowData => rowData?.lo ?? '-',
                        fieldFormat: 'date',
                        dataRef: 'lo',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'Last Active',
                        field: rowData => rowData.staff_last_active_datetime,
                        dataRef: 'staff_last_active_datetime',
                        fieldFormat: 'datetime',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'Status',
                        field: rowData => <StatusChip status={capitalize(rowData.staff_status)} />,
                        dataRef: 'staff_status',
                        sizeToContent: true,                                
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'View', icon: 'binoculars', link: `/staff/${rowData.staff_id}`, disabled: !access.viewStaff}
                            ]
                        }
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'staff-list',
                noResultsText: 'Sorry, no staff were found using your search criteria',
                pagination: true,
                pageTitleData: [0, 'Staff'],
                pdf: true,
                persistenceId: 'staff',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showButton: (
                    access.addStaff && (
                        <Button 
                            color="primary" 
                            size="small"
                            variant="text"
                            onClick={handleDeployAddStaff}
                        >   
                            <FAIcon type="light" icon="plus-circle" size="small" button />
                            Add Staff Member
                        </Button>
                    )
                ),
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Staff'
            }}
        />
    )
}

export default StaffSearch;