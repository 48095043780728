import React, { useState }  from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { isNumeric } from 'Functions/MiscFunctions';

const RMAOrderProductSelection = ({deployConfirmation, order, orderProducts, handleSubmit, closeDialog}) => {

    const [rows, setRows] = useState([...orderProducts]);

    const handleReturnItem = odpId => {
        let idx = _.findIndex(rows, row => row.odp_id === odpId);
        if(idx !== -1) {
            let newRows = [...rows];
            newRows[idx].rtn = newRows[idx].rtn === 1 ? 0 : 1;
            setRows(newRows);
        }
    }

    const handleChange = (odpId, field, value) => {
        let idx = _.findIndex(rows, row => row.odp_id === odpId);
        if(field === "qty" && (parseInt(value) < 0)) value = '';
        if(field === "restockingFee" && (parseInt(value) < 0 || parseInt(value) > 100)) value = '';
        if(idx !== -1) {
            let newRows = [...rows];
            newRows[idx][field] = value;
            setRows(newRows);
        }
    }

    const handleFloat = (odpId, field) => {
        let idx = _.findIndex(rows, row => row.odp_id === odpId);
        if(idx !== -1) {
            let newRows = [...rows];
            newRows[idx][field] = !_.isEmpty(newRows[idx]?.[field]) ? parseFloat(newRows[idx]?.[field]).toFixed(2) : '';
            setRows(newRows);
        }
    }

    const handleToggle = rtn => {
        let newRows = [...rows];
        newRows.forEach((row, idx) => {
            newRows[idx].rtn = rtn;
        })
        setRows(newRows);
    }

    return (
        <Box className="content-light-grey" p={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item xs>
                            <Typography variant="h6">
                                Return Product Selection
                            </Typography>
                            <Typography variant='body2' className="fw-400">
                                Sales Order #{order.order_reference} - {order?.customer?.cust_company_name ?? 'Unknown'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Close">
                                <IconButton
                                    onClick={closeDialog}
                                >
                                    <FAIcon icon="times" size={20} button noMargin />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box className="content-light-white" p={3}>
                        <DataTable
                            config={{
                                key: 'od_id',
                                alternatingRowColours: true,
                                isLoading: false,
                                responsiveImportance: true,
                                inline: true,
                                rowProps: rowData => ({
                                    style: {
                                        textDecoration: rowData.od_status === "Cancelled" ? 'line-through' : undefined
                                    }
                                })
                            }}
                            columns={[
                                {
                                    heading: '',
                                    field: rowData => <Checkbox disabled={rowData.quantity === rowData.rma} color="primary" checked={rowData?.rtn === 1} onChange={() => handleReturnItem(rowData?.odp_id)}/>,
                                    alignment: 'center',
                                    sizeToContent: true
                                }, 
                                {
                                    heading: 'Product',
                                    field: rowData => (
                                        <Box pt={1} pb={1}>
                                            <Typography variant="body2" className="fw-400">
                                                {rowData.productCode}
                                            </Typography>
                                            <Typography variant="caption">
                                                {rowData.productName}
                                            </Typography>
                                        </Box>
                                    ),
                                    truncate: true,
                                    main: true
                                }, 
                                {
                                    heading: 'SO',
                                    field: rowData => rowData.ref,
                                    sizeToContent: true
                                }, 
                                {
                                    heading: 'Date',
                                    field: rowData => rowData.date,
                                    sizeToContent: true,
                                    fieldFormat: 'date',
                                },    
                                {
                                    heading: 'Ordered',
                                    field: rowData => rowData.quantity,
                                    sizeToContent: true,
                                    alignment: 'right'
                                },     
                                {
                                    heading: 'Returned',
                                    field: rowData => rowData.rma,
                                    sizeToContent: true,
                                    alignment: 'right'
                                },     
                                {
                                    heading: 'Quantity',
                                    field: rowData => (
                                        <Box width={100}>
                                            <TextField
                                                name="qty"
                                                // label="Quantity"
                                                value={rowData.qty}
                                                onChange={e => handleChange(rowData.odp_id, 'qty', e?.target?.value)}
                                                type="number"
                                                fullWidth
                                                inputProps={{
                                                    min: 0
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                disabled={rowData?.rtn === 0}
                                            />
                                        </Box>
                                    ),
                                    sizeToContent: true
                                },      
                                {
                                    heading: 'Reason',
                                    field: rowData => (
                                        <Box width={250}>
                                            <AutoCompleteSelect
                                                // label={`Reason *`}
                                                options={[
                                                    {
                                                        label: "Damaged",
                                                        value: "DAMAGED"
                                                    },
                                                    {
                                                        label: "Faulty Goods",
                                                        value: "FAULTY_GOODS"
                                                    },
                                                    {
                                                        label: "No Longer Required",
                                                        value: "NO_LONGER_REQUIRED"
                                                    },
                                                    {
                                                        label: "Wrong Goods Ordered",
                                                        value: "WRONG_GOODS_ORDERED"
                                                    },
                                                    {
                                                        label: "Wrong Goods Supplied (AA)",
                                                        value: "WRONG_GOODS_SUPPLIED_AA"
                                                    },
                                                    {
                                                        label: "Wrong Goods Supplied (Supplier)",
                                                        value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                                                    },
                                                ]} 
                                                value={rowData.reason}                                
                                                onChange={v => handleChange(rowData.odp_id, 'reason', v?.value)}
                                                noDefaultSort
                                                adornment="exclamation-triangle"
                                                noClear
                                                disabled={rowData?.rtn === 0}
                                            />
                                        </Box>
                                    ),
                                    sizeToContent: true
                                },      
                                {
                                    heading: 'Action',
                                    field: rowData => (
                                        <Box width={250}>
                                            <AutoCompleteSelect
                                                // label={`Action *`}
                                                options={[
                                                    {
                                                        label: "COA - Credit On Account",
                                                        value: "COA"
                                                    },
                                                    {
                                                        label: "CCR - Credit Card Refund",
                                                        value: "CCR"
                                                    },
                                                    {
                                                        label: "QFR - Quote For Repair",
                                                        value: "QFR"
                                                    },
                                                    {
                                                        label: "RPL - Replace",
                                                        value: "RPL"
                                                    },
                                                    {
                                                        label: "COA/RPL - Credit On Account & Replace",
                                                        value: "COA_RPL"
                                                    },
                                                    {
                                                        label: "CCR/RPL - Credit Card Refund & Replace",
                                                        value: "CCR_RPL"
                                                    },
                                                    {
                                                        label: "TBC - To Be Confirmed",
                                                        value: "TBC"
                                                    }
                                                ]} 
                                                value={rowData.action}                                
                                                onChange={v => handleChange(rowData.odp_id, 'action', v?.value)}
                                                noDefaultSort
                                                adornment="check"
                                                noClear
                                                disabled={rowData?.rtn === 0}
                                            />
                                        </Box>
                                    ),
                                    sizeToContent: true
                                },      
                                {
                                    heading: 'Restock Fee %',
                                    field: rowData => (
                                        <Box width={60}>
                                            <TextField
                                                name="restockingFee"
                                                // label="Restock Fee"
                                                value={rowData.restockingFee}
                                                type="number"
                                                onChange={e => handleChange(rowData.odp_id, 'restockingFee', e?.target?.value)}
                                                onBlur={e => handleFloat(rowData.odp_id, 'restockingFee')}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                }}
                                                inputProp={{
                                                    min: 0,
                                                    max: 100,
                                                    step: 0.50
                                                }}
                                                className="mb-0"
                                                disabled={rowData?.rtn === 0}
                                            />
                                        </Box>
                                    ),
                                    sizeToContent: true
                                },      
                                {
                                    heading: 'Amount Paid',
                                    field: rowData => rowData.subTotal,
                                    sizeToContent: true,
                                    fieldFormat: 'currency',
                                    alignment: 'right'
                                },   
                                {
                                    heading: 'Credit Amount',
                                    field: rowData => {
                                        let percent = rowData.restockingFee;
                                        if(isNumeric(percent)) {
                                            return (rowData.subTotal - ((rowData.subTotal / 100) * percent)).toFixed(2)
                                        } else {
                                            return '-'
                                        }
                                    },
                                    fieldFormat: 'currency',
                                    sizeToContent: true,
                                    alignment: 'right'
                                },   
                                {
                                    heading: 'Net Credit Total',
                                    field: rowData => {
                                        let percent = rowData.restockingFee;
                                        if(isNumeric(percent) && isNumeric(rowData.qty)) {
                                            return (rowData.qty * (rowData.subTotal - ((rowData.subTotal / 100) * percent))).toFixed(2)
                                        } else {
                                            return '-'
                                        }
                                    },
                                    fieldFormat: 'currency',
                                    sizeToContent: true,
                                    alignment: 'right'
                                },   
                            ]}
                            rows={rows}
                        />
                        <Box mt={2}>
                            <Grid container spacing={3} justify="space-between">
                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Button 
                                                disabled={_.every(rows, row => row.rtn === 0)}
                                                variant="text"
                                                color="primary"
                                                onClick={() => handleToggle(0)}
                                            >
                                                <FAIcon icon="minus-circle" button size={13.5} />
                                                De-select All
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                disabled={_.every(rows, row => row.rtn === 1)}
                                                variant="text"
                                                color="primary"
                                                onClick={() => handleToggle(1)}
                                            >
                                                <FAIcon icon="plus-circle" button size={13.5} />
                                                Select All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item align='right'>
                                    <Button 
                                        disabled={_.every(rows, row => row.rtn === 0) || _.some(rows, row => row.rtn === 1 && ((parseInt(isNumeric(row.qty) ? row.qty : 0) === 0) || row.restockingFee === ''))}
                                        variant="text"
                                        color="primary"
                                        onClick={() => deployConfirmation(`Are you sure you want to generate a return for the selected items?`, () => handleSubmit(order, rows))}
                                    >
                                        <FAIcon icon="check" button size={13.5} />
                                        Generate RMA
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default RMAOrderProductSelection;