import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AsyncSelect from 'Components/Common/Selects/AsyncSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DateSelect from 'Components/Common/Selects/DateSelect';

import { 
    connect 
} from 'react-redux';

import {
    Box,
    FormControl,
    Grid,
    Button,
    Paper,
    InputAdornment,
    TextField,
    Typography,
    Tooltip,
    IconButton,
} from '@material-ui/core';

import {
    clearPersistence,
    setPersistence
} from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    filter: {
        actioned: 0,
        brand: 0,
        brandOption: null,
        category: 0,
        categoryOption: null,
        customer: 0,
        customerOption: null,
        product: 0,
        productOption: null,
        regionOption: null,
        date: null,
        dateFrom: null,
        dateTo: null,
        dateSelect: '',
        range: 0,
        rangeOption: null,
        searchString: '',
        shipType: '',
        stockStatus: '',
        stockType: '',
        supplier: 0,
        staff: 0,
        supplierOption: null 
    },
    regionList: [],
    hasLoaded: false,
    isLoading: true,
    searchResults: {}
})

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     =   clearPageState.bind(this);
        this.getInitialState    =   getInitialState.bind(this);
        this.hasPageState       =   hasPageState.bind(this);
        this.savePageState      =   savePageState.bind(this);
        this.persistenceId      =   null;
        // this.persistenceId      =   this.props.configuration?.persistenceId ?? null;
        this.state              =   this.getInitialState(this.getInitialSearchState());
        this.pulseResults       =   null;
        this.timeout            =   null;
    }

    componentDidMount = () => {
        
        const {
            configuration: {
                pageTitleData
            },
            pageTitle
        } = this.props;
        
        if(!_.isEmpty(pageTitleData)) {
            pageTitle?.(pageTitleData);
        }

        !this.hasPageState() && this.configureSearch();

    }

    componentDidUpdate = prevProps => {

        const {
            innerKey,
            location,
            configuration: {
                // persistenceId,
                pageTitleData
            },
            pageTitle
        } = this.props;

        if(location?.pathname !== prevProps.location?.pathname) {
            
            // this.persistenceId = persistenceId ?? null;
            this.persistenceId = null;

            if(!_.isEmpty(pageTitleData)) {
                pageTitle?.(pageTitleData);
            }

            if(this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(this.getInitialSearchState())
                });
            } else {
                this.configureSearch();
            }

        }

        if(innerKey !== prevProps?.innerKey) {
            this.getData();
        }

    }

    componentWillUnmount = () => {
        this.timeout && clearTimeout(this.timeout);
        this.pulseResults && clearInterval(this.pulseResults);
    }
    
    configureSearch = () => {

        const {
            configuration: {
                noAutoLoad,
                pageTitleData,
                pulseResults
            },
            pageTitle
        } = this.props;

        this.setState({
            ...this.getInitialState(this.getInitialSearchState()),
        }, () => {
                
            if(!_.isEmpty(pageTitleData)) {
                pageTitle?.(pageTitleData);
            }

            if(!noAutoLoad) {

                this.getData();

                if(pulseResults > 0) {
                    this.pulseResults = setInterval(() => this.getData(false), pulseResults);
                }

            }

        })

    }

    getInitialSearchState = () => {
        
        const { 
            configuration: {
                showDateSelect,
                defaultDateSelect,
                defaultDateRange
            },
        } = this.props;

        let state = {
            ...initialState(), 
        };

        if(showDateSelect) {
            state = {
                ...state,
                filter: {
                    ...state.filter,
                    dateSelect: showDateSelect ? (defaultDateSelect ?? 'allTime') : null,
                }
            }
        }

        if(defaultDateRange) {
            state = {
                ...state,
                filter: {
                    ...state.filter,
                    dateFrom: defaultDateRange === 'lastYear' ? moment().subtract(364, 'days').format('YYYY-MM-DD') : null,
                    dateTo: defaultDateRange === 'lastYear' ? moment().format('YYYY-MM-DD') : null,
                }
            }
        }

        return state;

    }

    getData = (isLoading = true) => {

        const {
            configuration: {
                apiRoute,
                apiParams,
                showActioned,
                showBrand,
                showCategory,
                showCustomer, 
                showProduct,
                showRegion,
                showSearch,
                showDate,
                showDateRange,
                showDateSelect,
                showRange,
                showShipType,
                showStockStatus,
                showStockType,
                showSupplier,
                showStaff
            }
        } = this.props;

        const {
            filter: {
                actioned,
                brand,
                category,
                customer,
                product,
                region,
                date,
                dateSelect,
                dateFrom,
                dateTo,
                range,
                searchString,
                shipType,
                stockStatus,
                stockType,
                supplier,
                staff
            }
        } = this.state;

        this.setState({
            isLoading,
            hasLoaded: true,
        }, () => {
            
            let params  = apiParams ?? {},
                props   = {
                    cancellation: true
                };

            if(showActioned) {
                params = {
                    ...params,
                    actioned
                }
            }
            
            if(showSearch) {
                params = {
                    ...params,
                    searchString
                }
            }

            if(showDate) {
                params = {
                    ...params,
                    date
                }
            }

            if(showDateSelect) {
                params = {
                    ...params,
                    d: dateSelect
                }
            }

            if(showDateRange) {
                params = {
                    ...params,
                    dateFrom,
                    dateTo
                }
            }

            if(showBrand) {
                params = {
                    ...params,
                    brand
                }    
            }

            if(showCategory) {
                params = {
                    ...params,
                    category
                }
            }

            if(showCustomer) {
                params = {
                    ...params,
                    customer,
                }
            }

            if(showProduct) {
                params = {
                    ...params,
                    product,
                }
            }
          
            if(showRange) {
                params = {
                    ...params,
                    range,
                }
            }

            if(showRegion) {
                params = {
                    ...params,
                    region
                }
            }

            if(showSupplier) {
                params = {
                    ...params,
                    supplier,
                }
            }

            if(showStaff) {
                params = {
                    ...params,
                    staff,
                }
            }

            if(showStockStatus) {
                params = {
                    ...params,
                    stockStatus,
                }
            }

            if(showShipType) {
                params = {
                    ...params,
                    shipType,
                }
            }

            if(showStockType) {
                params = {
                    ...params,
                    stockType,
                }
            }

            if(!isLoading) {
                props = {
                    ...props,
                    noLoading: true
                }
            }

            if(showRegion) {

                API.get('/customers/regions')
                .then(result => {
    
                    if(result?.data) {
    
                        let regionList = _.map(result.data, (region) => {
                            return _.assign({
                                value: region.cr_id,
                                label: region.cr_name
                            });
                        });

                        this.setState({
                            regionList
                        });
    
                    }
    
                });

            }

            API.get(apiRoute, 
                { 
                    params,
                    props
                }
            )
            .then(result => {

                if(result?.data) {

                    this.setState({
                        isLoading: false,
                        searchResults: result.data
                    }, () => {
                        this.savePageState();
                    });

                }

            });
        });

    }

    onSearchChange = (n, v, o = {}) => {

        const {
            configuration: {
                noAutoLoad
            }
        } = this.props;

        let {
            filter: {
                brand,
                brandOption,
                categoryOption,
                customerOption,
                productOption,
                range,
                rangeOption,
                regionOption,
                supplierOption
            }
        } = this.state;

        if(n === "brand") {
            brand = v;
            brandOption = o;
        }
        
        if(n === "category") {
            categoryOption = o;
        }

        if(n === "customer") {
            customerOption = o;
        }

        if(n === "product") {
            productOption = o;
        }

        if(n === "range") {
            range = v;
            rangeOption = o;
        }
      
        if(n === "region") {
            regionOption = o;
        }

        if(n === "supplier") {
            brand           = initialState().filter.brand;
            brandOption     = initialState().filter.brandOption;
            range           = initialState().filter.range;
            rangeOption     = initialState().filter.rangeOption;
            supplierOption  = o;
        }

        let value = '';
        if(!v && Array.isArray(o)) {
            value = _.map(o, el => el.value)
        }

        this.setState({
            filter: {
                ...this.state.filter,
                [n]: v ?? value,
                brand,
                brandOption,
                categoryOption,
                customerOption,
                productOption,
                range,
                rangeOption,
                regionOption,
                supplierOption
            }
        },
        () => {
            if(!noAutoLoad) {
                this.setSearch();
            }
        });

    }

    resetSearch = () => {
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(this.getInitialSearchState()),
            }, () => {
                this.getData();
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getData, 200);
    }

    searchBrands = (searchString, callback, selectRef) => {
        
        const {
            filter: {
                supplier
            }
        } = this.state;

        API.get('/smartSearch/brands', 
            {
                props: {
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'select',
                    supplier
                }
            }
        )
        .then(res => {
            
            if(res?.data) {
                callback(res?.data)
            } else {
                callback([])
            }

            if(selectRef?.current?.focus) {
                selectRef.current.focus()
            }

        })
    }

    searchCategories = (searchString, callback, selectRef) => {
        API.get('/smartSearch/categories', 
            {
                props: {
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'select'
                }
            }
        )
        .then(res => {
            
            if(res?.data) {
                callback(res?.data)
            } else {
                callback([])
            }

            if(selectRef?.current?.focus) {
                selectRef.current.focus()
            }

        })
    }

    searchCustomers = (searchString, callback, selectRef) => {
        API.get('/smartSearch/customers', 
            {
                props: {
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'select'
                }
            }
        )
        .then(res => {
            
            if(res?.data) {
                callback(res?.data)
            } else {
                callback([])
            }

            if(selectRef?.current?.focus) {
                selectRef.current.focus()
            }

        })
    }

    searchProducts = (searchString, callback, selectRef) => {
        API.get('/smartSearch/products', 
            {
                props: {
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'select'
                }
            }
        )
        .then(res => {
            
            if(res?.data) {
                callback(res?.data)
            } else {
                callback([])
            }

            if(selectRef?.current?.focus) {
                selectRef.current.focus()
            }

        })
    }
    
    searchRanges = (searchString, callback, selectRef) => {

        const {
            filter: {
                supplier
            }
        } = this.state;

        API.get('/smartSearch/ranges', 
            {
                props: {
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'select',
                    supplier
                }
            }
        )
        .then(res => {
            
            if(res?.data) {
                callback(res?.data)
            } else {
                callback([])
            }

            if(selectRef?.current?.focus) {
                selectRef.current.focus()
            }

        })
    }

    searchSuppliers = (searchString, callback, selectRef) => {
        API.get('/smartSearch/suppliers', 
            {
                props: {
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'select'
                }
            }
        )
        .then(res => {
            
            if(res?.data) {
                callback(res?.data)
            } else {
                callback([])
            }

            if(selectRef?.current?.focus) {
                selectRef.current.focus()
            }

        })
    }

    render = () => {

        const {
            configuration: {
                alternatingRowColours,
                columns,
                defaultSort,
                defaultSortOrder,
                disableEnhancedExport,
                elevation,
                excel,
                isMultiSupplier,
                responsiveImportance,
                dashboard,
                dataRef,
                inline,
                key,
                labels,
                name,
                nesting,
                noAutoLoad,
                noPadding,
                noResultsText,
                pagination,
                plainPagination,
                pdf,
                persistenceId,
                placeholderData,
                print,
                rowsPerPage,
                showActioned,
                showBrand,
                showButton,
                showCategory,
                showCustomer,
                showProduct,
                showRegion,
                showDate,
                showDateRange,
                showDateSelect,
                showRange,
                showSearch,
                showShipType,
                showStaff,
                showStockStatus,
                showStockType,
                showSupplier,
                showTotals,
                showFullTotals,
                title
            },
            staffList,
            ui: {
                device: {
                    isTablet
                }
            }
        } = this.props;

        const { 
            filter: {
                actioned,
                brandOption,
                categoryOption,
                customerOption,
                productOption,
                region,
                date,
                dateFrom,
                dateTo,
                dateSelect,
                rangeOption,
                searchString,
                shipType,
                staff,
                stockStatus,
                stockType,
                supplierOption
            },
            regionList,
            hasLoaded,
            isLoading,
            searchResults
        } = this.state;

        return (
            <Grid container spacing={noPadding ? 0 : 3}>    
                {(showButton || showSearch || showDate || showDateRange || showDateSelect || showBrand || showCategory ||  showCustomer || showProduct || showRange || showRegion || showSupplier || showStaff || showShipType || showStockStatus || showStockType || noAutoLoad) && (
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems='center'>
                            {showSearch && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 300 : undefined}>
                                        <TextField 
                                            fullWidth
                                            onChange={e => this.onSearchChange("searchString", e?.target?.value)}
                                            placeholder='Search:'
                                            value={searchString} 
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                            }}
                                        /> 
                                    </Box>
                                </Grid>
                            )}
                            {showDateSelect && (
                                <DateSelect
                                    onChange={o => this.onSearchChange('dateSelect', o?.value ?? '')}
                                    value={dateSelect}
                                />
                            )}
                            {showDate && (
                                <Grid item>
                                    <FormControl fullWidth>
                                        <DatePicker
                                            name="date"
                                            type="date"
                                            placeholder={labels?.date ?? "Date: "}
                                            value={date}
                                            onChange={date => this.onSearchChange('date', moment(date).format("YYYY-MM-DD"))}
                                            autoOk={true}
                                            inputVariant="filled"
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            {showDateRange && (
                                <>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <DatePicker
                                                name="dateFrom"
                                                type="date"
                                                placeholder="Date From: "
                                                maxDate={dateTo ? dateTo : undefined}
                                                value={dateFrom}
                                                onChange={date => this.onSearchChange('dateFrom', moment(date).format("YYYY-MM-DD"))}
                                                autoOk={true}
                                                inputVariant="filled"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <DatePicker
                                                name="dateTo"
                                                type="date"
                                                placeholder="Date To: "
                                                minDate={dateFrom ? dateFrom : undefined}
                                                value={dateTo}
                                                onChange={date => this.onSearchChange('dateTo', moment(date).format("YYYY-MM-DD"))}
                                                autoOk={true}
                                                inputVariant="filled"
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            {showCustomer && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined} mt={0.7}>
                                        <AsyncSelect
                                            apiCall={_.debounce(this.searchCustomers, 250)}
                                            placeholder={'Customer:'}
                                            onChange={option => this.onSearchChange('customer', option?.value, option)}
                                            value={customerOption}
                                            variant="filled"
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showProduct && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined} mt={0.7}>
                                        <AsyncSelect
                                            apiCall={_.debounce(this.searchProducts, 250)}
                                            placeholder={'Product:'}
                                            onChange={option => this.onSearchChange('product', option?.value, option)}
                                            value={productOption}
                                            variant="filled"
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showRegion && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined}>
                                        <AutoCompleteSelect
                                            placeholder={'Region:'}
                                            onChange={option => this.onSearchChange('region', option?.value)}
                                            options={regionList}
                                            value={region}
                                            variant="filled"
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showSupplier && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined} mt={0.7}>
                                        <AsyncSelect
                                            apiCall={_.debounce(this.searchSuppliers, 250)}
                                            placeholder={'Supplier:'}
                                            onChange={option => this.onSearchChange('supplier', option?.value, option)}
                                            value={supplierOption}
                                            variant="filled"
                                            isMulti={isMultiSupplier}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showBrand && supplierOption && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined} mt={0.7}>
                                        <AsyncSelect
                                            apiCall={_.debounce(this.searchBrands, 250)}
                                            defaultOptions={true}
                                            placeholder={'Brand:'}
                                            onChange={option => this.onSearchChange('brand', option?.value, option)}
                                            value={brandOption}
                                            variant="filled"
                                            disabled={!supplierOption}
                                            key={supplierOption?.value ?? supplierOption}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showRange && supplierOption && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined} mt={0.7}>
                                        <AsyncSelect
                                            apiCall={_.debounce(this.searchRanges, 250)}
                                            defaultOptions={true}
                                            placeholder={'Range:'}
                                            onChange={option => this.onSearchChange('range', option?.value, option)}
                                            value={rangeOption}
                                            variant="filled"
                                            key={supplierOption?.value ?? supplierOption}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showCategory && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined} mt={0.7}>
                                        <AsyncSelect
                                            apiCall={_.debounce(this.searchCategories, 250)}
                                            defaultOptions={true}
                                            isMulti
                                            placeholder={'Category:'}
                                            onChange={option => this.onSearchChange('category', option?.value, option)}
                                            value={categoryOption}
                                            variant="filled"
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showStockType && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined}>
                                        <AutoCompleteSelect
                                            placeholder={'Stock Type:'}
                                            onChange={option => this.onSearchChange('stockType', option?.value)}
                                            options={[
                                                {
                                                    label: "Stocked",
                                                    value: "stocked",
                                                },
                                                {
                                                    label: "Not Stocked",
                                                    value: "not-stocked",
                                                },
                                                {
                                                    label: "Consignment",
                                                    value: "consignment",
                                                }
                                            ]}
                                            value={stockType}
                                            variant="filled"
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showStockStatus && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined}>
                                        <AutoCompleteSelect
                                            placeholder={'Stock Status:'}
                                            onChange={option => this.onSearchChange('stockStatus', option?.value)}
                                            options={[
                                                {
                                                    label: "Available",
                                                    value: "available",
                                                },
                                                {
                                                    label: "Allocated",
                                                    value: "allocated",
                                                }
                                            ]}
                                            value={stockStatus}
                                            variant="filled"
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showShipType && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined}>
                                        <AutoCompleteSelect
                                            placeholder={'Shipping Type:'}
                                            onChange={option => this.onSearchChange('shipType', option?.value)}
                                            options={[
                                                {
                                                    label: "Direct Despatch",
                                                    value: "direct",
                                                },
                                                {
                                                    label: "Ship Here",
                                                    value: "here",
                                                }
                                            ]}
                                            value={shipType}
                                            variant="filled"
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showStaff && (
                                <Grid item>
                                    <Box minWidth={!isTablet ? 250 : undefined}>
                                        <AutoCompleteSelect
                                            placeholder={'Staff:'}
                                            onChange={option => this.onSearchChange('staff', option?.value)}
                                            options={staffList}
                                            value={staff}
                                            variant="filled"
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {showButton && (
                                <Grid item xs align='right'>
                                    <Box pt={1.25}>
                                        {showButton}
                                    </Box>
                                </Grid>
                            )}
                            {noAutoLoad && (    
                                <Grid item>
                                    <Button
                                        onClick={this.setSearch}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        <FAIcon icon="search" size={15} className="mr-1" button noMargin/>
                                        Go
                                    </Button>
                                </Grid>
                            )}
                            {showActioned ? (
                                <Grid item>
                                    <Tooltip title={`Show ${actioned ? 'outstanding' : 'actioned'}`}>
                                        <IconButton
                                            onClick={() => this.onSearchChange('actioned', actioned === 1 ? 0 : 1)}
                                        >
                                            <FAIcon icon={actioned ? 'eye' : 'eye-slash'} type="light" color={actioned ? 'primary' : undefined} noMargin />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>  
                            ) : null}
                        </Grid>    
                    </Grid> 
                )}
                {placeholderData && (
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Please note that this page has been created using placeholder data from the API, any search criteria / filters will not currently apply.
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {(noAutoLoad && !hasLoaded && (
                        <Typography variant="h5" className="fw-400 pt-3" align='center'>
                            Please enter any filters and click the Go button to load data
                        </Typography>
                    )) || (dashboard && searchResults?.length === 0 && (
                        <Alert severity="success" variant="outlined">
                            {dashboard}
                        </Alert>
                    )) || (
                        <Paper elevation={elevation ?? (inline ? 0 : undefined)}>
                            <DataTable  
                                config={{
                                    alternatingRowColours,
                                    isLoading,
                                    key,
                                    noResultsText,
                                    inline,
                                    nesting,
                                    options: {
                                        dataRef,
                                        defaultSort,
                                        defaultSortOrder,
                                        disableEnhancedExport,
                                        export: {
                                            excel,
                                            name,
                                            pdf,
                                            print,
                                            title
                                        },
                                        reset: this.resetSearch,
                                    },
                                    pagination,
                                    plainPagination: plainPagination || inline ? true : false,
                                    persistenceId: persistenceId ? `dataTable:${persistenceId}` : null,
                                    responsiveImportance,
                                    rowsPerPage,
                                    showFullTotals,
                                    showTotals
                                }}
                                columns={columns}
                                rows={searchResults}
                            />
                        </Paper>
                    )}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    staffList: state.notifications.staffList,
    statePersistence: state.statePersistence,
    ui: state.ui
})

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(Search);