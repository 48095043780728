import React from 'react';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link } from 'react-router-dom';

import Alert from 'Components/Common/Alert/Alert';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionChip from 'Components/Common/Chips/ActionChip';
import API from 'API';
import ActivityLog from 'Components/Common/Activity/ActivityLog';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import BooleanChip from 'Components/Common/Chips/BooleanChip';
import CancelRMA from 'Components/RMA/Misc/CancelRMA';
import DataTable from 'Components/Common/DataTables/DataTable';
import DocumentForm from 'Components/Common/Forms/DocumentForm';
import EmailLog from 'Components/Communication/Communication/EmailLog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ManageRMA from 'Components/RMA/Misc/ManageRMA';
import RMASummary from 'Components/RMA/Misc/RMASummary';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SearchCreditNotesTable from 'Components/Accounts/SearchCreditNotes/SearchCreditNotesTable';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import TaskForm from 'Components/Common/Activity/TaskForm';
import Tile from 'Components/Common/Tiles/Tile';
import ViewCallLogs from 'Components/Common/Activity/ViewCallLogs';
import ViewDocuments from 'Components/Common/Activity/ViewDocuments';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import ViewPage from 'Components/Common/Styles/ViewPage';
import StatusChip from 'Components/Common/Chips/StatusChip';
import ViewActions from 'Components/Common/Activity/ViewActions';
import WildixContext from "Context/WildixContext";
import {  VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from 'Constants';
import { isTablet } from 'Functions/MiscFunctions';
import { momentFormatDate, momentFormatDateTime } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { currencyFormat, isNumeric, TabPanel } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getLegacyReturnPDF = id => {
    if(id) {
        API.get(`/rma/${id}/legacy/pdf`)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        })
    }
}

const initialState = {
    access: {
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        updateRMA: false,
        viewCustomer: false,
        cancelRMA: false,
        addOrder: false,
        refund: false,
        reOpen: false,
        approveRMA: false,
        emailForm: false
    },
    initialAccess: {
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        updateRMA: false,
        viewCustomer: false,
        cancelRMA: false,
        addOrder: false,
        refund: false,
        reOpen: false,
        approveRMA: false,
        emailForm: false
    },
    currentTab: 0,
    replaceable: false,
    rmaPercent: 0,
    rma: {},
    rmaId: 0,
    rmaCancellable: false,
    rmaModified: false,
    isLoading: true,
    key: uuidv4(),
    pdf: {
        numPages: '',
        pageNumber: 1,
        scale: isTablet() ? 0.75 : 1.5
    },
    pulseLoading: false,
    revision: false,
    viewing: {
        list: [],
        process: false,
        update: false,
        received: false
    }
}

class ViewRMA extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props);
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.poll = false;
        this.fetch = false;
    }

    componentDidMount = () => {
        API.multiAccess([
            'view-rma:add-call',
            'view-rma:add-note',     
            'view-rma:add-document',     
            'view-rma:modify-document',     
            'view-rma:delete-document',
            'update-rma',
            'view-customer',
            'cancel-rma',
            'add-order',
            'view-order',
            'credit-note-refund',
            'reopen-rma',
            'rma-approval',
            'view-rma:email-form'
        ])
        .then(([
            addCallLog, 
            addNote, 
            uploadDoc, 
            modifyDoc, 
            deleteDoc, 
            updateRMA,
            viewCustomer,
            cancelRMA,
            addOrder,
            viewOrder,
            refund,
            reOpen,
            approveRMA,
            emailForm
        ]) =>  {
            const rmaId = this.props.id ? this.props.id : this.props.match.params.id;
            this.setState({ 
                access: {
                    addCallLog, 
                    addNote, 
                    uploadDoc, 
                    modifyDoc, 
                    deleteDoc, 
                    updateRMA,
                    viewCustomer,
                    cancelRMA,
                    addOrder,
                    viewOrder,
                    refund,
                    reOpen,
                    approveRMA,
                    emailForm
                },
                initialAccess: {
                    addCallLog, 
                    addNote, 
                    uploadDoc, 
                    modifyDoc, 
                    deleteDoc, 
                    updateRMA,
                    viewCustomer,
                    cancelRMA,
                    addOrder,
                    viewOrder,
                    refund,
                    reOpen,
                    approveRMA,
                    emailForm
                },
                rmaId
            }, () => {
                this.poll = setInterval(this.handlePoll, 5000);
                this.loadComponentData(true)
            });
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match?.params?.id !== this.props.match?.params?.id) {
            this.setState({
                currentTab: 0,
                rmaId: this.props.match.params.id,
            }, () => {            
                this.setState({
                    isLoading: true
                }, () => {
                    this.loadComponentData(true);
                })
            });
        }
    }

    componentWillUnmount = () => {
        if(this.poll) {
            clearInterval(this.poll);
        }
        if(this.fetch) {
            clearTimeout(this.fetch);
        }
    }

    handlePoll = () => {

        const {
            rma
        } = this.state;

        const { 
            currentTab
        } = this.state;

        if(!document.hidden) {

            API.post(`/staff/my/view`, 
                { 
                    rma: this.state.rmaId,
                    type: currentTab === 9 && (rma?.rma_status !== 'Complete' && rma?.rma_status !== 'Closed' && rma?.rma_status !== 'Cancelled' && rma?.rma_status !== 'Declined') ? 'Process' : 'View'
                }, 
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true
                    }
                }
            )
            .then(res => {
                const { viewing } = this.state;
                if(res?.data) {

                    const   list            = res.data,
                            pulseLoading    = false,
                            update          = _.some(list, el => el.u === 1),
                            process         = _.some(list, el => el.p === 1),
                            received        = _.some(list, el => el.re === 1);

                    if(JSON.stringify(list) !==  JSON.stringify(viewing.list)) {
                    
                        this.setState({
                            pulseLoading,
                            viewing: {
                                list,
                                update,
                                process,
                                received
                            }
                        }, () => {
                            if(currentTab === 9 && (update || process || received)) {
                                this.handleTabChange(0);
                                this.props.deploySnackBar("error", `Sorry, another user is currently ${update ? 'modifying' : (process ? 'managing' : 'booking in')} this RMA`);
                            }
                            if(viewing.process && !process) {
                                const view = _.find(viewing.list, el => el.p === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished managing this RMA`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.update && !update) {
                                const view = _.find(viewing.list, el => el.u === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished updating this RMA`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.received && !received) {
                                const view = _.find(viewing.list, el => el.re === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished booking in this RMA`);
                                }
                                this.loadComponentData();
                            }
                        })
                    } else if(this.state.pulseLoading) {
                        this.setState({
                            pulseLoading
                        }, () => {
                            if(currentTab === 9 && (update || process || received)) {
                                this.handleTabChange(0);
                                this.props.deploySnackBar("error", `Sorry, another user is currently ${update ? 'modifying' : (process ? 'managing' : 'booking in')} this RMA`);
                            }
                        })
                    }
                }

            })

        }

    }

    loadComponentData = (initial = false, creditOnly = false) => { 

        let params      = {},
            rmaId       = this.state.rmaId,
            currentTab  = initial ? 0 : this.state.currentTab;
        
        API.get(`/rma/${rmaId}`, { params })
        .then(result => {

            if(result?.data && !result?.data?.errors) {

                if(creditOnly) {
                    this.setState({
                        rma: {
                            ...this.state.rma,
                            'credit_notes': result?.data?.credit_notes
                        }
                    }, () => {
                        if(_.some(result?.data?.credit_notes, el => el.posted === 0)) {
                            this.fetch = setTimeout(() => this.loadComponentData(false, true), 3000);
                        }      
                    });

                } else {

                    let   { initialAccess }     =   this.state,
                            isLoading           =   false,
                            rmaPercent          =   0,
                            rma                 =   result.data,
                            revision            =   result?.data?.rma_latest === 1 ? false : true,
                            key                 =   uuidv4(),
                            products            =   [],
                            rmaCancellable      =   true,
                            rmaModified         =   false,
                            replaceable         =   false;
                    
                    let totalLines      = _.size(rma.products),
                        closedLines     = _.size(_.filter(rma.products, el => el.rp_status === "Closed"));

                    if(totalLines === closedLines) {
                        rmaPercent = 100;
                    } else {
                        rmaPercent = Math.round((closedLines / totalLines) * 100);
                    }

                    _.each(rma.products, rp => {
                        
                        const   rpId                = rp?.rp_id,
                                rpStatus            = rp?.rp_status,
                                productId           = rp?.rp_product_id,
                                productName         = rp?.rp_product_name,
                                productCode         = rp?.rp_product_code,
                                quantity            = rp?.rp_quantity,
                                recQuantity         = rp?.rp_rec_quantity,
                                recPreQuantity      = rp?.rp_rec_pristine_quantity,
                                cost                = rp?.rp_cost,
                                price               = rp?.rp_paid,
                                subTotal            = rp?.rp_credit,
                                restockFee          = rp?.rp_restock_fee,
                                restockFeeTotal     = rp?.rp_total_restock_fee,
                                restockPercent      = parseFloat(rp?.rp_restock_percent).toFixed(2),
                                total               = rp?.rp_total_credit,
                                vat                 = rp?.rp_total_vat,
                                originalVat         = rp?.rp_total_vat,
                                originalQuantity    = rp?.rp_quantity,
                                image               = rp?.img,
                                productData         = rp?.product,
                                orderId             = rp?.order_id,
                                orderRef            = rp?.order_reference ?? rp?.rp_order_ref ?? null,
                                deliveryDate        = rp?.rp_delivery_date,
                                serial              = rp?.rp_serial,
                                rmaNotes            = rp?.rp_notes,
                                sysNotes            = rp?.rp_sys_notes,
                                action              = rp?.action?.ra_code,
                                reason              = rp?.reason?.rr_code,
                                odId                = rp?.rp_od_id,
                                odpId               = rp?.rp_odp_id,
                                received            = rp?.rp_received,
                                receivedDatetime    = rp?.rp_received_datetime,
                                withTech            = rp?.rp_with_tech,
                                withSupplier        = rp?.rp_with_supplier,
                                rtnToSupplier       = rp?.rp_rtn_to_supplier,
                                rtnToSupplierRef    = rp?.rp_rtn_to_supplier_ref,
                                restock             = rp?.rp_restock,
                                noFault             = rp?.rp_no_fault,
                                actionDate          = rp?.rp_action_date,
                                suppCredit          = rp?.rp_supp_credit,
                                suppCreditRef       = rp?.rp_supp_credit_ref,
                                suppCreditDate      = rp?.rp_supp_credit_date,
                                repair              = rp?.rp_repair,
                                replace             = rp?.rp_replace,
                                aaCredit            = rp?.rp_aa_credit,
                                aaCreditRef         = rp?.rp_aa_credit_ref,
                                noAction            = rp?.rp_no_action,
                                closed              = rp?.rp_closed,
                                status              = rp?.rp_status,
                                supplier            = rp?.product?.supplier?.supp_company_name ?? '',
                                option              = {};                          

                        if(productId > 0 && (received === 1 || withTech === 1 || withSupplier === 1 || rtnToSupplier === 1 || restock === 1 || noFault === 1)) {
                            rmaCancellable = false;
                        } else if(productId < 0 && (aaCredit === 1 || noAction === 1)) {
                            rmaCancellable = false;
                        }

                        if(rp.rp_action_id === 4 || rp.rp_action_id === 5 || rp.rp_action_id === 6) { //REPLACE
                            replaceable = true;
                        }

                        products.push({
                            rpId,
                            rpStatus,
                            productId,
                            productName,
                            productCode,
                            quantity,
                            recQuantity,
                            recPreQuantity,
                            cost,
                            price,
                            subTotal,
                            restockFee,
                            restockFeeTotal,
                            restockPercent,
                            total,
                            vat,
                            originalVat,
                            originalQuantity,
                            image,
                            productData,
                            option,
                            orderId,
                            orderRef,
                            deliveryDate,
                            serial,
                            rmaNotes,
                            sysNotes,
                            action,
                            reason,
                            odpId,
                            odId,
                            received,
                            receivedDatetime,
                            withTech,
                            withSupplier,
                            rtnToSupplier,
                            rtnToSupplierRef,
                            restock,
                            noFault,
                            actionDate,
                            suppCredit,
                            suppCreditRef,
                            suppCreditDate,
                            repair,
                            replace,
                            aaCredit,
                            aaCreditRef,
                            noAction,
                            closed,
                            status,
                            supplier
                        })

                    });

                    rma.products = products;

                    this.setState({ 
                        access: {
                            ...this.state.access,
                            addCallLog: revision ? false : initialAccess.addCallLog,
                            addNote: revision ? false : initialAccess.addNote,
                            uploadDoc: revision ? false : initialAccess.uploadDoc,
                            modifyDoc: revision ? false : initialAccess.modifyDoc,
                            deleteDoc: revision ? false : initialAccess.deleteDoc,
                            updateRMA: revision ? false : initialAccess.updateRMA,
                            cancelRMA: revision ? false : initialAccess.cancelRMA,
                            addOrder: revision ? false : initialAccess.addOrder,
                        },
                        currentTab,
                        isLoading,
                        key,
                        replaceable,
                        revision,
                        rmaPercent,
                        rmaCancellable,
                        rmaModified,
                        rma,
                        rmaId
                    }, () => {
                        
                        if(this.props.pageTitle)
                            this.props.pageTitle([1, "RMA", result?.data?.customer?.cust_company_name, `RMA #${result.data.rma_reference}`])

                        if(_.some(rma?.credit_notes, el => el.posted === 0)) {
                            this.fetch = setTimeout(() => this.loadComponentData(false, true), 3000);
                        }                        
                        
                        if(this.props.location?.state?.new) {
                            this.props.history.replace({state: { new: false }})
                            let callback = null;
                            if(initialAccess.addOrder) {
                                if(replaceable && !rma.replacement_order) {
                                    callback = () => this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Would you like to create a replacement sales order now?</Typography></>, () => this.props.history.push(`/rma/replacement/${rma.rma_id}`))
                                }
                            }
                            if(initialAccess.emailForm) {
                                const success = () => {
                                    this.handleDeploySendEmail(callback);
                                }
                                this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Would you like to email the returns form now?</Typography></>, success, callback ?? false)
                            } else {
                                callback?.();
                            }
                            
                        }

                    })

                }
                
            } else {
                if(this.props.history) {
                    this.props.history.push('/rma');
                }
            }

        })
    }

    handleTabChange = (event, tabIndex, force = false) => {

        const {
            rma 
        } = this.state;

        const {
            deployConfirmation 
        } = this.props;

        let currentTab      = !isNaN(event) ? event : tabIndex,
            pulseLoading    = currentTab === 9 && (rma?.rma_status !== 'Complete' && rma?.rma_status !== 'Closed' && rma?.rma_status !== 'Cancelled' && rma?.rma_status !== 'Declined'),
            { rmaModified } = this.state,
            wasModified     = rmaModified,
            prevTab         = this.state.currentTab,
            isLoading       = prevTab === 9 && wasModified;

        if(rmaModified && this.state.currentTab === 9 && !force) {
            deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to navigate away from this tab?</Typography><Typography variant="body2">You have unsaved changes</Typography></>, () => this.handleTabChange(currentTab, null, true));
            return;
        }

        rmaModified = false;

        this.setState({
            currentTab,
            isLoading,
            pulseLoading,
            rmaModified
        }, () => {
            
            if(this.mainContentArea && this.mainContentArea.current) 
                this.mainContentArea.current.scrollTop = 0;

            if(isLoading)
                this.loadComponentData();
            
            if((rma?.rma_status !== 'Complete' && rma?.rma_status !== 'Closed' && rma?.rma_status !== 'Cancelled' && rma?.rma_status !== 'Declined') && (prevTab === 9 || currentTab === 9))
                this.handlePoll();
        })

    }   
    
    handleDeployAddCall = () => this.props.deployDialog(
        <AddCallLogForm
            id={this.state.rmaId}
            relation="rma"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard", "xs", false, true);

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.rmaId}
            relation="rma"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
            standardOnly
            withFao
        />, true, "", "standard","sm", false, true);

    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            id={this.state.rmaId}
            relation="rma"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","sm", false, true);

                
    handleDeployCancelRMA = () => {
        
        const {
            closeDialog,
            deployDialog,
            deployConfirmation,
            deploySnackBar
        } = this.props;

        const { 
            rmaId
        } = this.state;
        
        deployDialog(
            <CancelRMA
                id={rmaId}
                callback={() => this.loadComponentData(true)}
                closeDialog={closeDialog}
                deployConfirmation={deployConfirmation}
                deploySnackBar={deploySnackBar}
            />, false, "Close RMA", "standard","xs", false, true);
    
    }

    handleDeploySendEmail = (callback) => {
        let defaultFormData = {
            emailSubject: `RMA #${this.state.rma.rma_reference}`,
            emailTo: {
                label: this.state.rma.rma_email,
                value: this.state.rma.rma_email
            }
        }
        this.props.deployDialog(
            <EmailDialog
                callback={() => {
                    this.loadComponentData();
                    callback?.();
                }}
                id={this.state.rmaId}
                type='rma'
                success='The RMA authorisation form has been successfully sent'
                defaultFormData={defaultFormData}
                readOnly
            />, false, "E-mail Return Form", "standard","md"
        );
    }

    handleDeployReOpenRMA = () => {
        API.put(`/rma/${this.state.rmaId}/open`)
        .then(res => {
            if(res?.data?.success) {
                this.props.deploySnackBar(`success`, `RMA #${this.state.rma?.rma_reference} has been re-opened`);
                this.loadComponentData();
            }
        })
    }

    handleDeployFileUpload = () => this.props.deployDialog(
        <DocumentForm 
            id={this.state.rmaId} 
            type="rma"
            category="RMA"
            callback={this.loadComponentData}
            cancel={this.props.closeDialog} 
        />, false, "File Upload", "standard","xs", false, true);

    handleDeployViewCustomer = () => this.props.history.push(`/customers/${this.state.rma?.rma_cust_id}`);
    
    handlePdfLoad = ({numPages}) => {
        this.setState({
            pdf: {
                ...this.state.pdf,
                numPages
            }
        })
    }

    handlePdfPagination = offset => {
        this.setState({
            pdf: {
                ...this.state.pdf,
                pageNumber: ((this.state.pdf?.pageNumber ?? 1) + offset)
            }
        })
    }

    handlePdfScale = zoom => {
        let scale = this.state.pdf?.scale ?? 1;
        if(isNumeric(zoom)) {
            scale = zoom;
        } else {
            if(zoom) {
                scale = (parseFloat(scale) + parseFloat(0.25)).toFixed(2)
            } else {
                scale = (parseFloat(scale) - parseFloat(0.25)).toFixed(2)
            }
        }
        this.setState({
            pdf: {
                ...this.state.pdf,
                scale
            }
        })
    }

    handleManageRMAChange = (idx, field, value) => {

        let products    = [...this.state.rma.products],
            rmaModified = true;

        if(field === "received" && value === 1) {
            products[idx] = {
                ...products[idx],
                receivedDatetime: moment()
            };
        }

        if(field === "received" && value === 0) {
            products[idx] = {
                ...products[idx],
                receivedDatetime: null
            };
        }

        if((field === "rtnToSupplier" || field === "restock" || field === "noFault") && value === 1) {
            products[idx] = {
                ...products[idx],
                actionDate: moment()
            };
        }

        if(field === "suppCredit" && value === 1) {
            products[idx] = {
                ...products[idx],
                aaCredit: 1,
                suppCreditDate: moment()
            };
        }

        if(field === "suppCredit" && value === 0) {
            products[idx] = {
                ...products[idx],
                aaCredit: 0,
                suppCreditRef: '',
                suppCreditDate: null
            };
        }

        products[idx] = {
            ...products[idx],
            [field]: value
        };

        if((field === "rtnToSupplier" || field === "restock" || field === "noFault") && value === 0) {
            products[idx] = {
                ...products[idx],
                actionDate: products[idx]?.rtnToSupplier === 0 && products[idx]?.restock === 0 && products[idx]?.noFault === 0 ? null : products[idx]?.actionDate
            };
        }

        this.setState({
            rma: {
                ...this.state.rma,
                products,
            },
            rmaModified
        })

    }

    handleSubmitRMAChanges = () => {

        this.setState({
            isLoading: true
        }, () => {

            const {
                rma
            } = this.state;
    
            let formData = rma.products;
    
            API.put(`/rma/${rma.rma_id}/manage`, { formData })
            .then(result => {                
                this.loadComponentData();
                if(result.data.errors) {
                    this.props.deploySnackBar("error", `Something went wrong, please try again`)
                } else {
                    this.props.deploySnackBar("success", `The RMA was successfully updated`)
                }
            })

        });
        
    }

    render() {        
        const { classes, closeDialog, deployConfirmation, deployDialog, deploySnackBar, staff, ui } = this.props;
        const { access, currentTab, rmaPercent, isLoading, key, rma, pdf, pulseLoading, replaceable, revision, rmaCancellable, rmaModified, viewing } = this.state;
        const canDial = this.context?.dial === null;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        const tabs = [
            {
                label: `Overview`,
                delivery: null,
                index: 0
            },
            {
                label: `Return Summary`,
                delivery: null,
                index: 1
            },
            {
                label: rma?.rma_status !== 'Complete' && rma?.rma_status !== 'Closed' && rma?.rma_status !== 'Cancelled' && rma?.rma_status !== 'Declined' ? `Process Return` : `Return Details`,
                delivery: null,
                index: 9,
                disabled: rma.rma_status === 'Awaiting Approval' || (rma?.rma_status !== 'Complete' && rma?.rma_status !== 'Closed' && rma?.rma_status !== 'Cancelled' && rma?.rma_status !== 'Declined' && (!access.updateRMA || viewing.update || viewing.process || viewing.received)) 
            },
            {
                label: `PDF Document`,
                delivery: null,
                index: 2,
                disabled: rma.rma_legacy === 1 || rma.rma_status === "Awaiting Approval",
            },
            {
                divider: true
            },
            {
                label: `Credit Notes`,
                delivery: null,
                disabled: _.isEmpty(rma.credit_notes),
                index: 10,
            },
            {
                label: `Goods Returned`,
                delivery: null,
                disabled: _.isEmpty(rma.goods_received),
                index: 11,
            },
            {
                label: <Badge color="primary" className="badge" badgeContent={rma.all_files_require_action_count ?? rma.files_require_action_count ?? 0} showZero={false}>Files</Badge>,
                delivery: null,
                disabled: _.isEmpty(rma.documents),
                index: 3,
            }
        ];
        
        if(rma.rma_latest === 1) {
            tabs.push(
                {
                    divider: true
                },
                {
                    label: `Actions ${rma.actions_count > 0 ? `(${rma.actions_count})` : ``}`, 
                    delivery: null,
                    index: 4,
                },
                {
                    label: `Timeline ${_.size(rma.activity) > 0 ? `(${_.size(rma.activity)})` : ``}`, 
                    delivery: null,
                    index: 5,
                },
                {
                    label: `Calls ${_.size(rma.call_logs) > 0 ? `(${_.size(rma.call_logs)})` : ``}`,
                    delivery: null,
                    index: 6,
                },
                {
                    label: `Emails ${_.size(rma.emails) > 0 ? `(${_.size(rma.emails)})` : ``}`,
                    delivery: null,
                    index: 7,
                },
                {
                    label: `Notes ${_.size(rma.notes) > 0 ? `(${_.size(rma.notes)})` : ``}`,
                    delivery: null,
                    index: 8,
                }
            )
        }

        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (rma?.rma_status !== 'Complete' && rma?.rma_status !== 'Closed' && rma?.rma_status !== 'Cancelled' && rma?.rma_status !== 'Declined') && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" component={rma.rma_status === 'Cancelled' || revision ? 'strike' : undefined} className="textDefault">
                                                            RMA #{rma.rma_reference}
                                                            {/* RMA #{rma.rma_reference}/{rma.rma_revision} */}
                                                        </Typography>  
                                                    </Grid>                                                    
                                                    {rma?.rma_phone?.length > 0 && canDial ? (
                                                        <Grid item>
                                                            <Tooltip title="Call Customer">
                                                                <IconButton onClick={() => this.context.useDial(rma.rma_phone)} size="small">
                                                                    <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={27.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>  
                                            </Grid>
                                            {!revision && (
                                                <Grid item>
                                                    <Tooltip title="Add Action" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddTask()}>
                                                            <FAIcon type="light" icon="layer-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon type="light" icon="comment-alt-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addCallLog && (
                                                <Grid item>
                                                    <Tooltip title="Log Call" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddCall()}>
                                                            <FAIcon type="light" icon="phone-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.uploadDoc && (
                                                <Grid item>
                                                    <Tooltip title="Upload File" placement="top">
                                                        <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                            <FAIcon type="light" icon="file-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title="Download Return Form" placement="top">
                                                    <IconButton disabled={rma.rma_status === 'Awaiting Approval'} onClick={() => rma.rma_legacy === 1? getLegacyReturnPDF(rma.rma_id) : getPresignedUrl(rma.rma_pdf_filename)}>
                                                        <FAIcon type="light" icon="file-pdf" style={{color: rma.rma_status === 'Awaiting Approval' ? '#ddd' : '#d32f2f'}} button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "layer-plus",
                                                            label: "Add Action",
                                                            onClick: () => this.handleDeployAddTask(),
                                                            display: !revision
                                                        },
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote
                                                        },
                                                        {
                                                            icon: "phone-plus",
                                                            label: "Log Call",
                                                            onClick: () => this.handleDeployAddCall(),
                                                            display: access.addCallLog
                                                        },
                                                        {
                                                            icon: "file-plus",
                                                            label: "Upload File",
                                                            onClick: () => this.handleDeployFileUpload(),
                                                            display: access.uploadDoc,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: "Create Replacement SO",
                                                            onClick: () => this.props.history.push(`/rma/replacement/${rma.rma_id}`),
                                                            display: access.addOrder && !rma.replacement_order && replaceable,
                                                            disabled: rma.rma_status === 'Cancelled' || rma.rma_status === 'Declined' || rma.rma_status === 'Closed',
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "file-pdf",
                                                            label: "Download Return Form",
                                                            onClick: () => rma.rma_legacy === 1 ? getLegacyReturnPDF(rma.rma_id) : getPresignedUrl(rma.rma_pdf_filename),
                                                            display: true,
                                                            disabled: rma.rma_status === 'Awaiting Approval' || rma.rma_status === 'Declined' || rma.rma_status === 'Cancelled' || rma.rma_status === 'Closed' || !access.emailForm
                                                        },
                                                        {
                                                            icon: "envelope-open-text",
                                                            label: "E-mail Return Form",
                                                            onClick: () => this.handleDeploySendEmail(),
                                                            display: true,
                                                            disabled: rma.rma_status === 'Awaiting Approval' || rma.rma_status === 'Declined' || rma.rma_status === 'Cancelled' || rma.rma_status === 'Closed' || !access.emailForm
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: "Approve / Decline RMA",
                                                            onClick: () => this.props.history.push(`/rma/approve/${rma.rma_id}`),
                                                            display: rma.rma_status === 'Awaiting Approval',
                                                            disabled: !access.approveRMA || viewing.update || viewing.process || viewing.received
                                                        },
                                                        {
                                                            icon: "times",
                                                            label: "Cancel RMA",
                                                            onClick: this.handleDeployCancelRMA,
                                                            display: true,
                                                            disabled: !access.cancelRMA || rma.rma_status === 'Complete' || rma.rma_status === 'Cancelled' || rma.rma_status === 'Declined' || rma.rma_status === 'Closed' || viewing.update || viewing.process || viewing.received || !rmaCancellable
                                                        },
                                                        {
                                                            icon: "pencil",
                                                            label: "Modify RMA",
                                                            onClick: () => this.props.history.push(`/rma/update/${rma.rma_id}`),
                                                            display: rma.rma_status !== "Awaiting Approval" && access.updateRMA,
                                                            disabled: rma.rma_status === 'Complete' || rma.rma_status === 'Cancelled' || rma.rma_status === 'Declined' || rma.rma_status === 'Closed' || viewing.update || viewing.process || viewing.received
                                                        },
                                                        {
                                                            icon: "undo",
                                                            label: "Re-open RMA",
                                                            onClick: () => deployConfirmation(`Are you sure you want to re-open this RMA?`, this.handleDeployReOpenRMA),
                                                            display: true,
                                                            disabled: !access.reOpen || viewing.update || viewing.process || viewing.received || (rma.rma_status !== 'Complete' && rma.rma_status !== 'Cancelled' && rma.rma_status !== 'Declined'),
                                                            divider: true
                                                        },
                                                        {
                                                            icon: "binoculars",
                                                            label: "View Customer",
                                                            onClick: () => this.handleDeployViewCustomer(),
                                                            display: access.viewCustomer
                                                        },
                                                        {
                                                            icon: "binoculars",
                                                            label: "View Replacement Order",
                                                            onClick: () => this.props.history.push(`/sales/${rma.replacement_order?.order_id}`),
                                                            display: access.viewOrder && rma.replacement_order,
                                                        },
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item xs={10} md={8}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InsightChip 
                                                                disabled={!access.viewCustomer}
                                                                icon="external-link"
                                                                label={`Customer: ${rma.customer?.cust_company_name}`}
                                                                onClick={() => this.handleDeployViewCustomer()}
                                                                paddingLeft={8}
                                                                tooltip="View Customer"
                                                            />
                                                        </Grid>
                                                        {!ui.device?.isTablet && !ui.device?.isMobile && (
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="user"
                                                                    label={`Contact: ${rma.rma_contact_name}${!_.isEmpty(rma.rma_phone) ? ` - ${rma.rma_phone}` : ``}`}
                                                                />
                                                            </Grid>
                                                        )}
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="info-circle"
                                                                label={`Status: ${rma.rma_status}`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="calendar-day"
                                                                label={`Raised: ${moment(rma.rma_created_datetime).format("DD/MM/YYYY")}`}
                                                            />
                                                        </Grid>
                                                        {!ui.device?.isPortrait && (
                                                            <>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="pound-sign"
                                                                        label={`Net Total: -${currencyFormat.format(rma.rma_total_net)}`}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={2} justify="flex-end">
                                                            <Grid item>
                                                                <ActionChip action={{u: 0, p: 0}} staff={staff[this.props.loggedInStaff.id]} page="RMA" />
                                                            </Grid>
                                                            {_.map(viewing.list, (view, idx) => {
                                                                if(staff[view.i]) {
                                                                    return (
                                                                        <Grid item key={idx}>
                                                                            <ActionChip action={view} staff={staff[view.i]} page="RMA" />
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Grid item xs={2} align='right'>
                                                        <ActionChip staff={{name: `${(_.size(viewing.list) + 1)} viewing`}} page="RMA" />
                                                    </Grid>
                                                </Hidden>
                                            </Grid> 
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid> 
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    {rma.rma_status === 'Cancelled' && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    This RMA has been cancelled - {rma.cancellation_reason?.rr_reason ?? 'No Reason Given'}
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={8}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={12} md={5}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text={rma.customer?.cust_company_name} />
                                                                            <Grid container spacing={3} className="pt-1">
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="user"
                                                                                        title="Contact Name"
                                                                                        content={rma.rma_contact_name}
                                                                                    />
                                                                                </Grid>
                                                                                {!_.isEmpty(rma.rma_phone) && (
                                                                                    <Grid item xs={12}>                                                        
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="phone"
                                                                                            title="Contact Phone"
                                                                                            content={rma.rma_phone}
                                                                                        />
                                                                                    </Grid>
                                                                                )}
                                                                                {!_.isEmpty(rma.rma_email) && (
                                                                                    <Grid item xs={12}>                                                        
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="envelope"
                                                                                            title="Contact E-mail"
                                                                                            content={rma.rma_email}
                                                                                        />
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                        <Heading text="Financials" />
                                                                            <Grid container spacing={3} className="pt-1">  
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pound-sign"
                                                                                        title="Net Total"
                                                                                        content={`${currencyFormat.format(-rma.rma_total_net)}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pound-sign"
                                                                                        title="VAT"
                                                                                        content={`${currencyFormat.format(-rma.rma_total_vat)}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pound-sign"
                                                                                        title="Gross Total"
                                                                                        content={`${currencyFormat.format(-rma.rma_total_gross)}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pound-sign"
                                                                                        title="Estimated Cost"
                                                                                        content={`${currencyFormat.format(rma.rma_total_cost)}`}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={7}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="RMA Status" />
                                                                            <Grid container spacing={3}>   
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={'#4CAF50'}
                                                                                        icon={'check'}
                                                                                        title="RMA Status"
                                                                                        content={rma.rma_status}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>   
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={rmaCancellable ? '#f44336' : '#4CAF50'}
                                                                                        icon={rmaCancellable ? 'times' : 'check'}
                                                                                        title="Goods Returned"
                                                                                        content={rmaCancellable ? 'No' : 'Yes'}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                        <Heading text="Insight" />
                                                                            <Grid container spacing={3}>  
                                                                                <Grid item xs={12} md={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={'#4CAF50'}
                                                                                        content={momentFormatDate(rma.rma_created_datetime)}
                                                                                        icon={'check'}
                                                                                        title="Raised On"
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} md={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={rma.rma_received_date === '0000-00-00' ? '#f44336' : '#4CAF50'}
                                                                                        content={rma.rma_received_date === '0000-00-00' ? 'N/A' : momentFormatDate(rma.rma_received_date)}
                                                                                        icon={rma.rma_received_date === '0000-00-00' ? 'times' : 'check'}
                                                                                        title="Received On"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} md={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={rma.rma_processed_date === '0000-00-00' ? '#f44336' : '#4CAF50'}
                                                                                        content={rma.rma_processed_date === '0000-00-00' ? 'N/A' : momentFormatDate(rma.rma_processed_date)}
                                                                                        icon={rma.rma_processed_date === '0000-00-00' ? 'times' : 'check'}
                                                                                        title="Processed On"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} md={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={rma.rma_completed_date === '0000-00-00' ? '#f44336' : '#4CAF50'}
                                                                                        content={rma.rma_completed_date === '0000-00-00' ? 'N/A' : momentFormatDate(rma.rma_completed_date)}
                                                                                        icon={rma.rma_completed_date === '0000-00-00' ? 'times' : 'check'}
                                                                                        title="Closed On"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} md={6}>                                                        
                                                                                    <StaffTile
                                                                                        noPaper
                                                                                        missingText={rma.rma_staff_id === 0 ? 'Installer Portal' : undefined}
                                                                                        title="Raised By"
                                                                                        staffId={rma.rma_staff_id}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} md={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="chart-network"
                                                                                        title="Last Activity"
                                                                                        content={rma.activity?.[0]?.d ? moment(rma.activity?.[0]?.d).format("DD/MM/YY HH:mm") : '-'}
                                                                                        onClick={() => this.handleTabChange(5)}
                                                                                    />
                                                                                </Grid> 
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    {!_.isEmpty(rma.revisions) && (
                                                                        <Grid item xs={12}>
                                                                            <PaddedPaper>
                                                                                <Grid container spacing={1} alignItems='center'>
                                                                                    <Grid item xs>
                                                                                        <Typography variant="h6">
                                                                                            Revisions
                                                                                        </Typography>    
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <DataTable
                                                                                            config={{
                                                                                                key: 'order_id',
                                                                                                inline: true,
                                                                                            }}
                                                                                            columns={[
                                                                                                {
                                                                                                    heading: 'Revision',
                                                                                                    field: rowData => `${rowData.rma_revision}`,
                                                                                                    sizeToContent: true,
                                                                                                    cellProps: rowData => ({
                                                                                                        className: rowData.rma_latest === 1 ? 'fw-400' : undefined
                                                                                                    })
                                                                                                },
                                                                                                {
                                                                                                    heading: 'Created By',
                                                                                                    field: rowData => staff?.[rowData?.rma_staff_id ?? 0]?.name ?? 'Unknown',
                                                                                                    sizeToContent: true,
                                                                                                    cellProps: rowData => ({
                                                                                                        className: rowData.rma_latest === 1 ? 'fw-400' : undefined
                                                                                                    })
                                                                                                },
                                                                                                {
                                                                                                    heading: 'Created On',
                                                                                                    field: rowData => moment(rowData.rma_created_datetime).format("DD/MM/YYYY"),
                                                                                                    sizeToContent: true,
                                                                                                    cellProps: rowData => ({
                                                                                                        className: rowData.rma_latest === 1 ? 'fw-400' : undefined
                                                                                                    })
                                                                                                },
                                                                                                {
                                                                                                    actions: rowData => {
                                                                                                        return [
                                                                                                            {name: 'View', icon: 'binoculars', link: '/rma/' + rowData.rma_id, disabled: rowData.rma_id === rma.rma_id}
                                                                                                        ]
                                                                                                    }
                                                                                                }
                                                                                            ]}
                                                                                            rows={rma.revisions}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </PaddedPaper>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Grid container spacing={3}>
                                                            {(rma?.replacement_order) && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item xs>
                                                                                <Typography variant="h6">
                                                                                    Replacement Order
                                                                                </Typography>    
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="body2" gutterBottom>
                                                                                    The following sales order has been generated as a replacement order for this RMA:
                                                                                </Typography>                                                                                    
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tooltip title="View Replacement Sales Order">
                                                                                    <Link to={`/sales/${rma.replacement_order?.order_id}`} target="_blank">
                                                                                        <InsightChip
                                                                                            icon='external-link'
                                                                                            className="link"
                                                                                            label={`Sales Order #${rma.replacement_order?.order_reference} (${rma.replacement_order?.order_status})`}
                                                                                            paddingRight={4}
                                                                                            paddingLeft={8}
                                                                                        />
                                                                                    </Link>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Grid container spacing={1} alignItems='center'>
                                                                        <Grid item xs>
                                                                            <Typography variant="h6">
                                                                                Return Progress
                                                                            </Typography>    
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Button
                                                                                size="small"
                                                                                variant="text"
                                                                                onClick={() => this.handleTabChange(9)}
                                                                            >
                                                                                <FAIcon icon="eye" size={15} button />
                                                                                Detailed View
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid item xs={12} className="pt-0">
                                                                            <Box display="flex" alignItems="center" pt={1}>
                                                                                <Box width="100%" mr={1}>
                                                                                    <LinearProgress variant="determinate" value={rmaPercent} />
                                                                                </Box>
                                                                                <Box minWidth={35}>
                                                                                    <Typography variant="body2" color="textSecondary" className="fw-400">{rmaPercent}%</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <DataTable  
                                                                                config={{
                                                                                    key: 'rp_id',
                                                                                    alternatingRowColours: true,
                                                                                    isLoading: isLoading,
                                                                                    inline: true,
                                                                                    noRowHover: true
                                                                                }}
                                                                                columns={[
                                                                                    {
                                                                                        heading: '',
                                                                                        field: rowData => (
                                                                                            <Box pt={1} pb={1}>
                                                                                                <Typography variant="body2" className="fw-400">
                                                                                                    {rowData.productCode}
                                                                                                </Typography>
                                                                                                <Typography variant="caption" component="div" gutterBottom>
                                                                                                    {rowData.productName}
                                                                                                </Typography>
                                                                                                <Typography variant="caption" className={parseInt(rowData.recQuantity) !== parseInt(rowData.quantity) ? 'textError fw-400' : 'textSuccess fw-400'}>
                                                                                                    {rowData.recQuantity} / {rowData.quantity} returned
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        ),
                                                                                        truncate: true
                                                                                    }, 
                                                                                    {
                                                                                        heading: '',
                                                                                        field: rowData => <StatusChip status={rma.rma_status === 'Awaiting Approval' ? 'Awaiting Approval' : rowData.rpStatus} />,
                                                                                        sizeToContent: true
                                                                                    },   
                                                                                ]}
                                                                                rows={rma.products}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <RMASummary rma={rma} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <Box m={-3}>
                                                    <Paper style={{width: '100%', background: '#F5F5F5', height: 47, borderBottom: '1px solid #ddd', borderRadius: 0}} elevation={0}>
                                                        <Box p={0.5}>
                                                            <Grid container spacing={1} justify='center' alignItems='center'>
                                                                <Grid item xs>
                                                                    <Typography variant="body2" className="ml-2">
                                                                        {`Pages: ${pdf.numPages ?? 1}`}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(1.5)}
                                                                            >
                                                                                <FAIcon type="light" icon="search" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(false)}
                                                                                disabled={pdf.scale === "0.75"}
                                                                            >
                                                                                <FAIcon type="light" icon="search-minus" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(true)}
                                                                                disabled={pdf.scale === "2.00"}
                                                                            >
                                                                                <FAIcon type="light" icon="search-plus" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                    <Grid container justify="center" style={{height: 'calc(100% - 47px)', background: '#777', position: 'relative', textAlign: 'center', overflowX: 'hidden', overflowY: 'auto'}}>
                                                        <Grid item>
                                                            <Box p={3}>
                                                                <Document
                                                                    file={rma.signedPdf}
                                                                    onLoadSuccess={this.handlePdfLoad}
                                                                    loading={(
                                                                        <>
                                                                        </>
                                                                    )}
                                                                >
                                                                    {Array.apply(null, Array(pdf.numPages))
                                                                            .map((x, i)=>i+1)
                                                                            .map(page => (
                                                                                <Page 
                                                                                    pageNumber={page} 
                                                                                    loading={(
                                                                                        <LoadingCircle />
                                                                                    )}
                                                                                    renderAnnotationLayer={false}
                                                                                    scale={pdf.scale}
                                                                                    style={{
                                                                                        width: '100%', 
                                                                                        position: 'absolute',
                                                                                    }}
                                                                                />
                                                                            )
                                                                    )}
                                                                </Document>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <ViewDocuments 
                                                            access={access}
                                                            callback={this.loadComponentData}
                                                            category="RMA"
                                                            documents={rma.documents}
                                                            uploadFile={this.handleDeployFileUpload}
                                                            legacyDocBox={`C:/DATA/Advance/Documents/RMADocs/RMA${rma.rma_reference}`}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <ViewActions
                                                    key={key}
                                                    filter="rma"
                                                    filterId={rma.rma_id}
                                                    staff={staff}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    callback={this.loadComponentData}
                                                    noPagination
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <ActivityLog activity={rma.activity} fullPage />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={6}>
                                                <ViewCallLogs calls={rma.call_logs} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={7}>
                                                <EmailLog
                                                    key={key}
                                                    filter="rma"
                                                    filterId={rma.rma_id}
                                                    staff={this.props.staff}
                                                    deployConfirmation={deployConfirmation}
                                                    deploySnackBar={deploySnackBar}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    noSearch
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={8}>
                                                <ViewNotes notes={rma.notes} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={9}>
                                                <ManageRMA 
                                                    access={access}
                                                    callback={this.loadComponentData}
                                                    rma={rma} 
                                                    handleManageRMAChange={this.handleManageRMAChange}
                                                    handleSubmitRMAChanges={this.handleSubmitRMAChanges}
                                                    isLoading={pulseLoading} 
                                                    rmaModified={rmaModified}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={10}>
                                                <Paper>
                                                    <SearchCreditNotesTable compact rows={rma?.credit_notes} />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={11}>
                                                {_.map(rma.goods_received, (gr, idx) => (
                                                    <Box key={idx} className="content-light-white" p={3} mb={3}>
                                                        <Grid container spacing={3} alignItems='center'>
                                                            <Grid item xs>
                                                                <Typography variant="h6">
                                                                    Goods Returned Details
                                                                </Typography>
                                                                <Typography variant="body1" className="fw-400">
                                                                    RMA #{rma?.rma_reference} - Return {(idx + 1)}/{(_.size(rma.goods_received))}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item align='right'>
                                                                <Tooltip title="Download Report">
                                                                    <IconButton aria-label="Download" disabled={_.isEmpty(gr?.gr_pdf_filename)} onClick={() => getPresignedUrl(gr?.gr_pdf_filename)}>
                                                                        <FAIcon type="light" icon='file-pdf' className={_.isEmpty(gr?.gr_pdf_filename) ? undefined : 'textError'} disabled={_.isEmpty(gr?.gr_pdf_filename)} button noMargin />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Box pt={3} pb={3}>
                                                            <Alert severity={gr?.gr_successful === 1 ? 'success' : 'warning'} elevation={0}>
                                                                {gr?.gr_successful === 1 ? `All items were returned in the condition expected` : `There were issues with the contition or quantity of goods returned`}
                                                            </Alert>
                                                        </Box>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Box p={3} pt={3} pb={3} className="content-light-white">
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <Tile
                                                                                noPaper
                                                                                color={'#000'}
                                                                                icon={'clock'}
                                                                                title="Check Started"
                                                                                content={momentFormatDateTime(gr?.gr_started_datetime, true)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <Tile
                                                                                noPaper
                                                                                color={'#000'}
                                                                                icon={'clock'}
                                                                                title="Check Completed"
                                                                                content={momentFormatDateTime(gr?.gr_completed_datetime, true)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <StaffTile
                                                                                noPaper
                                                                                title="Checked By"
                                                                                staffId={gr?.gr_staff_id ?? null}
                                                                                missingText='N/A'
                                                                            />
                                                                        </Grid> 
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box p={3} pt={3} pb={3} className="content-light-white">
                                                                    <Typography variant="h6" gutterBottom>
                                                                        Goods Returned
                                                                    </Typography>
                                                                    <DataTable  
                                                                        config={{
                                                                            key: 'grp_id',
                                                                            alternatingRowColours: true,
                                                                            // responsiveImportance: true,
                                                                            inline: true,
                                                                            noRowHover: true
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading: 'Product',
                                                                                field: rowData => (
                                                                                    <>
                                                                                        <Typography variant="body2" className="fw-400">
                                                                                            {rowData?.grp_product_code ?? 'Removed from RMA'}
                                                                                        </Typography>
                                                                                        <Typography variant="caption">
                                                                                            {rowData?.grp_product_name ?? 'Check previous revision'}
                                                                                        </Typography>
                                                                                    </>
                                                                                ),
                                                                                truncate: true
                                                                            }, 
                                                                            {
                                                                                heading: 'Return Reason',
                                                                                field: rowData => {
                                                                                    let part = _.find(rma.products, rp => rowData.grp_rp_id === rp.rpId);
                                                                                    return _.find([
                                                                                        {
                                                                                            label: "Damaged",
                                                                                            value: "DAMAGED"
                                                                                        },
                                                                                        {
                                                                                            label: "Faulty Goods",
                                                                                            value: "FAULTY_GOODS"
                                                                                        },
                                                                                        {
                                                                                            label: "No Longer Required",
                                                                                            value: "NO_LONGER_REQUIRED"
                                                                                        },
                                                                                        {
                                                                                            label: "Wrong Goods Ordered",
                                                                                            value: "WRONG_GOODS_ORDERED"
                                                                                        },
                                                                                        {
                                                                                            label: "Wrong Goods Supplied (AA)",
                                                                                            value: "WRONG_GOODS_SUPPLIED_AA"
                                                                                        },
                                                                                        {
                                                                                            label: "Wrong Goods Supplied (Supplier)",
                                                                                            value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                                                                                        },
                                                                                        {
                                                                                            label: "Refund Return Carriage",
                                                                                            value: "CARRIAGE_REFUND"
                                                                                        },
                                                                                        {
                                                                                            label: "Refund Order Carriage (Courier)",
                                                                                            value: "CARRIAGE_ISSUE"
                                                                                        },
                                                                                        {
                                                                                            label: "-",
                                                                                            value: "UNKNOWN"
                                                                                        }
                                                                                    ], el => el.value === part?.reason ?? 'UNKNOWN')?.label ?? '-'
                                                                                },
                                                                                sizeToContent: true
                                                                            }, 
                                                                            {
                                                                                heading: 'Expected Condition',
                                                                                field: rowData => {
                                                                                    let part = _.find(rma.products, rp => rowData.grp_rp_id === rp.rpId);
                                                                                    return _.find([
                                                                                        {
                                                                                            label: "Not Pristine",
                                                                                            value: "DAMAGED"
                                                                                        },
                                                                                        {
                                                                                            label: "Not Pristine",
                                                                                            value: "FAULTY_GOODS"
                                                                                        },
                                                                                        {
                                                                                            label: "Pristine",
                                                                                            value: "NO_LONGER_REQUIRED"
                                                                                        },
                                                                                        {
                                                                                            label: "Pristine",
                                                                                            value: "WRONG_GOODS_ORDERED"
                                                                                        },
                                                                                        {
                                                                                            label: "Pristine",
                                                                                            value: "WRONG_GOODS_SUPPLIED_AA"
                                                                                        },
                                                                                        {
                                                                                            label: "Pristine",
                                                                                            value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                                                                                        },
                                                                                        {
                                                                                            label: "-",
                                                                                            value: "CARRIAGE_REFUND"
                                                                                        },
                                                                                        {
                                                                                            label: "-",
                                                                                            value: "CARRIAGE_ISSUE"
                                                                                        },
                                                                                        {
                                                                                            label: "-",
                                                                                            value: "UNKNOWN"
                                                                                        }
                                                                                    ], el => el.value === part?.reason ?? 'UNKNOWN')?.label ?? '-'
                                                                                },
                                                                                sizeToContent: true
                                                                            }, 
                                                                            {
                                                                                heading: 'Expected',
                                                                                field: rowData => rowData.grp_expected_qty,
                                                                                sizeToContent: true,
                                                                                alignment: 'right',
                                                                                cellProps: () => ({
                                                                                    className: 'fw-400'
                                                                                })
                                                                            }, 
                                                                            {
                                                                                heading: 'Received',
                                                                                field: rowData => rowData.grp_total_qty,
                                                                                sizeToContent: true,
                                                                                alignment: 'right',
                                                                                cellProps: rowData => ({
                                                                                    className: rowData.grp_total_qty !== rowData.grp_expected_qty ? 'textError fw-400' : 'textSuccess fw-400'
                                                                                })
                                                                            }, 
                                                                            {
                                                                                heading: 'Missing',
                                                                                field: rowData => rowData.grp_missing_qty,
                                                                                sizeToContent: true,
                                                                                alignment: 'right',
                                                                                cellProps: rowData => ({
                                                                                    className: rowData.grp_missing_qty > 0 ? 'textError fw-400' : 'textSuccess fw-400'
                                                                                })
                                                                            }, 
                                                                            {
                                                                                heading: 'Not Pristine',
                                                                                field: rowData => rowData.grp_non_pristine_qty,
                                                                                sizeToContent: true,
                                                                                alignment: 'right'
                                                                            },
                                                                            {
                                                                                heading: 'Pristine',
                                                                                field: rowData => (parseInt(rowData.grp_total_qty) - parseInt(rowData.grp_non_pristine_qty)),
                                                                                sizeToContent: true,
                                                                                alignment: 'right'
                                                                            },
                                                                            {
                                                                                heading: 'Check Time',
                                                                                field: rowData => momentFormatDateTime(rowData.grp_check_datetime, true),
                                                                                sizeToContent: true
                                                                            },
                                                                            {
                                                                                heading: 'Status',
                                                                                field: rowData => <BooleanChip success={rowData.grp_successful === 1} label={rowData.grp_successful === 1 ? 'Returned' : 'Issue'} />,
                                                                                sizeToContent: true
                                                                            }
                                                                        ]}
                                                                        rows={gr?.products}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            {gr?.note && (
                                                                <Grid item xs={12}>
                                                                    <Box p={3} pt={3} pb={2} className="content-light-white">
                                                                        <Typography variant="h6" gutterBottom>
                                                                            Warehouse Notes
                                                                        </Typography>
                                                                        {gr?.note?.note_value?.split?.('\n').map((item, key) => (
                                                                            <Typography 
                                                                                key={key} 
                                                                                variant="caption"
                                                                                component="div" 
                                                                                paragraph
                                                                            >
                                                                                {item}
                                                                            </Typography>
                                                                        ))}
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    {_.map(tabs, (tab, idx) => (
                                        (tab.divider && (
                                            <Divider key={idx} />
                                        )) || (
                                            <Tab 
                                                classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                                disabled={tab.disabled}
                                                index={tab.index}
                                                value={tab.index}
                                                key={idx} 
                                                label={tab.label} 
                                                onClick={() => this.handleTabChange(tab.index, tab.delivery ?? false)} 
                                            />
                                        )
                                    ))}
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        loggedInStaff: state.staffAuth.staff,
        ui: state.ui
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success, cancel = false, successText = null, cancelText = null, successOnly = false) => dispatch(deployConfirmation(message, success, cancel, successText, cancelText, successOnly)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(withRouter(ViewRMA)));