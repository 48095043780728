import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import Tile from 'Components/Common/Tiles/Tile';
import { currencyFormat, momentFormatDate, momentFormatDateTime } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';

const PodInvoiceDetails = ({closeDialog, invoice, pod, idx}) => { 

    let poi = null;

    if(invoice) {
        poi = invoice;
    } else {
        poi = pod?.invoice ?? null;
        if(typeof idx !== "undefined") {
            poi = pod?.invoices?.[idx] ?? null;
        }
    }

    if(!poi) {
        closeDialog?.();
        return null;
    }

    const   sageRequest = JSON.parse(poi?.sage_request?.sdr_json ?? null),
            podLines    = JSON.parse(poi?.poi_pod_lines ?? null),
            suppLines   = JSON.parse(poi?.poi_supp_lines ?? null);
    return (
        <Box className="content-light-grey">
            <DialogTitle disableTypography style={{paddingBottom: 0}}>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs>
                        <Typography variant="h6">
                            Invoice Details
                        </Typography>
                        <Typography variant="body1" className="fw-400">
                            {(invoice && (
                                `Purchase Order Group Invoice`
                            )) || (
                                `Purchase Order Delivery #${pod?.pod_reference}/${pod?.pod_idx}`
                            )}
                        </Typography>
                    </Grid>
                    <Grid item align='right'>
                        <Tooltip title="Download Supplier Invoice">
                            <IconButton aria-label="Download" disabled={!poi?.poi_pdf_filename} onClick={() => getPresignedUrl(poi?.poi_pdf_filename)}>
                                <FAIcon type="light" icon='file-pdf' className={!poi?.poi_pdf_filename ? `` : `textError`} disabled={!poi?.poi_pdf_filename} button noMargin />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Close">
                            <IconButton aria-label="Close" onClick={closeDialog}>
                                <FAIcon type="light" icon='times' button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Box p={3} pt={0}>
                {/* <Box pt={3} pb={3}>
                    <Alert severity={poi?.poi_disputed === 1 ? 'warning' : 'success'} elevation={0}>
                        {poi?.poi_disputed === 1 ? `This invoice has been disputed with the supplier` : `This invoice has been processed by ${staff[poi?.poi_staff_id ?? 0]?.name ?? 'the staff member who processed it'}`}
                    </Alert>
                </Box> */}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box p={3} pt={3} pb={3} className="content-light-white">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'file-spreadsheet'}
                                        title="Invoice Reference"
                                        content={poi?.poi_supp_ref}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'calendar-alt'}
                                        title="Invoice Date"
                                        content={momentFormatDate(poi?.poi_supp_date)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'clock'}
                                        title="Processed On"
                                        content={momentFormatDateTime(poi?.poi_created_datetime)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <StaffTile
                                        noPaper
                                        title="Processed By"
                                        staffId={poi?.poi_staff_id ?? null}
                                        missingText='N/A'
                                    />
                                </Grid> 
                                <Grid item xs={12} sm={3}>
                                    <Tile
                                        noPaper
                                        color={'#4CAF50'}
                                        icon={'check'}
                                        title="Expected Gross Total"
                                        content={currencyFormat.format(poi?.poi_pod_total_gross)}
                                    />
                                </Grid> 
                                <Grid item xs={12} sm={3}>
                                    <Tile
                                        noPaper
                                        color={'#4CAF50'}
                                        icon={'check'}
                                        title="Actual Gross Total"
                                        content={currencyFormat.format(poi?.poi_supp_total_gross)}
                                    />
                                </Grid> 
                                <Grid item xs={12} sm={3}>
                                    <Tile
                                        noPaper
                                        color={poi?.poi_credit === "0.00" ? '#4CAF50' : '#f44336'}
                                        icon={poi?.poi_credit === "0.00" ? 'check' : 'exclamation-triangle'}
                                        title="Gross Variation"
                                        content={currencyFormat.format(poi?.poi_credit)}
                                    />
                                </Grid> 
                                <Grid item xs={12} sm={3}>
                                    <Tile
                                        noPaper
                                        color={sageRequest?.uniqueRef ? '#4CAF50' : '#f44336'}
                                        icon={sageRequest?.uniqueRef ? 'check' : 'exclamation-triangle'}
                                        title="Unique Reference"
                                        content={`${sageRequest?.uniqueRef ?? 'TBC'}${poi?.poi_disputed !== 0 ? ` (Disputed)` : ``}`}
                                    />
                                </Grid> 
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Invoice Comparison
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {_.map(podLines, (podp, idx) => (
                                <Grid item xs={12} key={idx}>
                                    <Grid container spacing={1} className="content-light-white p-2">
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                <span className="fw-400">Line {idx + 1} - {podp.productCode}</span>
                                            </Typography>
                                            <Typography variant="caption" component="div" gutterBottom>
                                                {podp.productName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} align='center'>
                                            <Grid container spacing={1} key={idx} alignItems='center'>
                                                <Grid item xs={12} className="p-0">
                                                    <Typography variant="caption" align='center'>
                                                        Expected Cost
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        label="Quantity"
                                                        value={podp.quantity}
                                                        margin="none"
                                                        variant="standard"
                                                        fullWidth
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item><Typography color="textSecondary">x</Typography></Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label="Unit Price"
                                                        value={podp.cost}
                                                        margin="none"
                                                        variant="standard"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                        }}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item><Typography color="textSecondary">=</Typography></Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label="Total Price"
                                                        value={podp.totalCost}
                                                        margin="none"
                                                        variant="standard"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                        }}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6} align='center'>
                                            <Grid container spacing={1} key={idx} alignItems='center'>
                                                <Grid item xs={12} className="p-0">
                                                    <Typography variant="caption" align='center'>
                                                        Actual Cost
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        disabled
                                                        label="Quantity"
                                                        value={suppLines[idx].quantity}
                                                        inputProps={{
                                                            style: {
                                                                color: suppLines[idx].quantity !== podp.quantity ? '#ef3340' : undefined
                                                            }
                                                        }}
                                                        margin="none"
                                                        variant="standard"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item>x</Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        disabled
                                                        label="Unit Price"
                                                        value={suppLines[idx].cost}
                                                        margin="none"
                                                        variant="standard"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                color: poi.pod_ !== poi.supp_ ? '#ef3340' : undefined
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>=</Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        disabled
                                                        label="Total Price"
                                                        value={suppLines[idx].totalCost}
                                                        margin="none"
                                                        variant="standard"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                color: suppLines[idx].totalCost !== podp.totalCost ? '#ef3340' : undefined
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {parseInt(podp.quantityInvoiced) === 0 && (podp.originalQty ?? podp.quantity) !== podp.quantityDespatch && (
                                            <Grid item xs={12} className="pt-0">
                                                <Alert severity="error" variant="outlined" className="p-0">
                                                    <strong>Consignment -</strong> Despatch: {podp.quantityDespatch} | Invoice: {podp.quantity}
                                                </Alert>
                                            </Grid>
                                        )}
                                        {parseInt(podp.quantityInvoiced) > 0 && (
                                            <Grid item xs={12} className="pt-0">
                                                <Alert severity={(parseInt(podp.quantityInvoiced) + parseInt(podp.quantity)) === parseInt(podp.originalQty ?? podp.quantity) ? 'success' : 'error'} variant="outlined" className="p-0">
                                                    <strong>Part Invoiced - </strong> Previous: {parseInt(podp.quantityInvoiced)} | This Time: {parseInt(podp.quantity)} | Total Invoiced: {parseInt(podp.quantityInvoiced) + parseInt(podp.quantity)}
                                                </Alert>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" className="fw-400">
                            Invoice Totals
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={3} className="content-light-white">
                            <Grid container spacing={2} className="pt-3">
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" component="div" align='center'>
                                        Expected Totals
                                    </Typography>
                                    <TextField
                                        label="Total Buy Price *"
                                        value={poi?.poi_pod_total_cost}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        disabled
                                    />
                                    <TextField
                                        label="Delivery *"
                                        value={poi?.poi_pod_total_carriage}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        disabled
                                    />
                                    <TextField
                                        label="Net Total *"
                                        value={poi?.poi_pod_total_net}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        disabled
                                    />
                                    <TextField
                                        label="VAT *"
                                        value={poi?.poi_pod_total_vat}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        disabled
                                    />
                                    <TextField
                                        className="mt-1"
                                        label="Gross Total *"
                                        value={poi?.poi_pod_total_gross}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" component="div" align='center'>
                                        Actual Totals
                                    </Typography>
                                    <TextField
                                        label="Total Buy Price *"
                                        value={poi?.poi_supp_total_cost}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        inputProps={{
                                            style: {
                                                color: poi.poi_pod_total_cost !== poi.poi_supp_total_cost ? '#ef3340' : undefined
                                            }
                                        }}
                                        disabled
                                    />
                                    <TextField
                                        label="Delivery *"
                                        value={poi?.poi_supp_total_carriage}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        inputProps={{
                                            style: {
                                                color: poi.poi_pod_total_carriage !== poi.poi_supp_total_carriage ? '#ef3340' : undefined
                                            }
                                        }}
                                        disabled
                                    />
                                    <TextField
                                        label="Net Total *"
                                        value={poi?.poi_supp_total_net}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        inputProps={{
                                            style: {
                                                color: poi.poi_pod_total_net !== poi.poi_supp_total_net ? '#ef3340' : undefined
                                            }
                                        }}
                                        disabled
                                    />
                                    <TextField
                                        label="VAT *"
                                        value={poi?.poi_supp_total_vat}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        inputProps={{
                                            style: {
                                                color: poi.poi_pod_total_vat !== poi.poi_supp_total_vat ? '#ef3340' : undefined
                                            }
                                        }}
                                        disabled
                                    />
                                    <TextField
                                        className="mt-1"
                                        label="Gross Total *"
                                        value={poi?.poi_supp_total_gross}
                                        margin="none"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" /></InputAdornment>
                                        }}
                                        inputProps={{
                                            style: {
                                                color: poi.poi_pod_total_gross !== poi.poi_supp_total_gross ? '#ef3340' : undefined
                                            }
                                        }}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default PodInvoiceDetails;