import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar'
import VATRateList from './VATRateList';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = {
    formData: {
        addVatType: {
            type: ''
        },
        addVatValue: {
            type: '',
            startDate: null,
            vatValue: '',
        }
    },
    formErrors: {
        addVatType: {},
        addVatValue: {}
    },
    snackbarOpen: {
        addVatType: false,
        addVatValue: false
    },
    confirmationOpen: {
        addVatType: false,
        addVatValue: false
    },
    vatTypes: {},
    vatTypeList: [],
    access: {
        addVATType: false,
        addVatValue: false
    },
    vatRates: {},
    isLoading: true
}

class VATRates extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getVatTypes();
        this.getVatValues();
    }

    getVatValues = () => {
        API.get('/accounts/vatRates/byType')
        .then(result => {
            if(result.data) {
                this.setState({
                    vatRates: result.data,
                    isLoading: false
                });
            }
        });
    }

    getVatTypes = () => {
        API.get('/accounts/vatRates/types').then(result => {
            if(result.data) {
                let vatTypeList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.vat_type_id,
                        label: el.vat_type_name
                    });
                });
                this.setState({
                    vatTypes: result.data,
                    vatTypeList: vatTypeList
                });
            }
        });
    }

    checkAccess = () => {
        Promise.all([ 
            API.access('vat-rates:add-vat-type'), 
            API.access('vat-rates:add-vat-value'),
        ])
        .then(([typeRes, vatRes]) =>  {
            typeRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addVATType: typeRes.data.has_access
                    }
                });
            vatRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addVatValue: vatRes.data.has_access
                    }
                });
        });
    }

    /*
     * Add VAT Type
     */
    addVatType = () => {
        API.post('/accounts/vatRates/types', this.state.formData.addVatType).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addVatType: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addVatType: true
                    }
                });
                this.checkAccess();
                this.getVatTypes();
                this.getVatValues();
            }
            this.props.scrollToTop();
        });
    }

    handleVatTypeChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addVatType: {
                    ...this.state.formData.addVatType,
                    [e.target.name]: e.target.value
                }
            }
        });
    }

    handleVatTypeSnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addVatType: false
            }
        });
    }

    handleVatTypeConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatType: true
            }
        });
    }

    handleVatTypeConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatType: false
            }
        });
    }

    handleVatTypeConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatType: false
            }
        });
        this.addVatType();
    }

    /*
     * Add VAT Value
     */
    addVatValue = () => {
        const formData = {
            ...this.state.formData.addVatValue,
            startDate: this.state.formData.addVatValue.startDate && (moment(new Date(this.state.formData.addVatValue.startDate)).format('DD/MM/YYYY'))
        }
        API.post('/accounts/vatRates', formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addVatValue: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addVatValue: true
                    }
                });
                this.checkAccess();
                this.getVatTypes();
                this.getVatValues();
            }
            this.props.scrollToTop();
        });
    }

    handleVatValueChange = (decimals) => (e) => {
        let newVal = decimals ? parseFloat(e.target.value).toFixed(decimals) : e.target.value;
        this.setState({
            formData: {
                ...this.state.formData,
                addVatValue: {
                    ...this.state.formData.addVatValue,
                    [e.target.name]: newVal
                }
            }
        });
    }
    
    handleVatValueSnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addVatValue: false
            }
        });
    }

    handleVatValueConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatValue: true
            }
        });
    }

    handleVatValueConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatValue: false
            }
        });
    }

    handleVatValueConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatValue: false
            }
        },
        () => {
            this.addVatValue();
        });
    }

    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                addVatValue: {
                    ...this.state.formData.addVatValue,
                    [name]: res && res.value,
                }
            }
        });
    }

    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                addVatValue: {
                    ...this.state.formData.addVatValue,
                    [name]: date,
                }
            }
        });
    }

    handleCheckChange = name => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                addVatValue: {
                    ...this.state.formData.addVatValue,
                    [name]: event.target.checked
                }
            }
        })
    }

    render() {
        const { access, vatRates, formData, formErrors, isLoading, snackbarOpen, confirmationOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    {(isLoading && (
                        <PaddedPaper>
                            <LoadingCircle />
                        </PaddedPaper>
                    )) || (
                        <VATRateList vatRates={vatRates} />
                    )}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        {access.addVatValue && (
                            <Grid item xs={12} xl={6}>
                                <PaddedPaper>
                                    <Typography variant="h6" paragraph>
                                        Add VAT Value
                                    </Typography>
                                    <form noValidate autoComplete="off">
                                        <AutoCompleteSelect 
                                            options={this.state.vatTypeList} 
                                            label='VAT Rate Type *'
                                            onChange={this.handleSelectChange('type')}
                                            error={formErrors.addVatValue && formErrors.addVatValue['type'] && true}
                                            errorText={formErrors.addVatValue && formErrors.addVatValue['type']}
                                            adornment='crown'
                                        />
                                        <DatePicker
                                            type="date"
                                            id="startDate"
                                            name="startDate"
                                            label="VAT Rate Start Date *"
                                            value={formData.addVatValue.startDate}
                                            errorText={formErrors.addVatValue && formErrors.addVatValue['startDate']}
                                            onChange={this.handleDateChange('startDate')}
                                            autoOk={true}
                                            disablePast={true}
                                        />
                                        <TextField
                                            id="vatValue"
                                            name="vatValue"
                                            label="VAT Rate Percentage *"
                                            value={formData.addVatValue.vatValue}
                                            error={formErrors.addVatValue && formErrors.addVatValue['vatValue'] && true}
                                            helperText={formErrors.addVatValue && formErrors.addVatValue['vatValue']}
                                            margin="normal"
                                            type="number"
                                            onChange={this.handleVatValueChange()}
                                            onBlur={this.handleVatValueChange(2)}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (<InputAdornment position="end"><strong>%</strong></InputAdornment>)
                                            }}
                                        />                                                
                                        <div className='buttonRow'>
                                            <Button 
                                                onClick={this.handleVatValueConfirmationOpen}
                                                variant="text" 
                                                color="primary" 
                                                disabled={!formData.addVatValue.vatValue && !formData.addVatValue.type && !formData.addVatValue.startDate}
                                            >
                                                <FAIcon icon="check" size={15} button />
                                                Add
                                            </Button>
                                        </div>
                                        <ConfirmationDialog 
                                            open={confirmationOpen.addVatValue} 
                                            success={this.handleVatValueConfirmationSuccess} 
                                            close={this.handleVatValueConfirmationClose} 
                                            title="Add a new VAT value?"
                                            message="Are you sure you want to add a new VAT value?"
                                        />
                                        <SnackBar
                                            variant="success"
                                            anchorOriginVertical='bottom'
                                            anchorOriginHorizontal='right'
                                            open={snackbarOpen.addVatValue}
                                            onClose={this.handleVatValueSnackbarClose}
                                            message="You have successfully added a new VAT value"
                                        />
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        )}
                        {(access.addVATType || access.addVatValue) && (
                            <>
                                {access.addVATType && (
                                    <Grid item xs={12} xl={6}>
                                        <PaddedPaper>
                                            <Typography variant="h6" paragraph>
                                                Add VAT Type
                                            </Typography>
                                            <form onChange={this.handleVatTypeChange} noValidate autoComplete="off">
                                                <TextField
                                                    id="type"
                                                    name="type"
                                                    label="Type Description *"
                                                    value={formData.addVatType.type}
                                                    error={formErrors.addVatType && formErrors.addVatType['type'] && true}
                                                    helperText={formErrors.addVatType && formErrors.addVatType['type']}
                                                    margin="none"
                                                    fullWidth
                                                />
                                                <div className='buttonRow'>
                                                    <Button 
                                                        onClick={this.handleVatTypeConfirmationOpen}
                                                        variant="tect" 
                                                        color="primary" 
                                                        disabled={!this.state.formData.addVatType.type}
                                                    >
                                                        <FAIcon icon="check" size={15} button />
                                                        Add
                                                    </Button>
                                                </div>
                                                <ConfirmationDialog 
                                                    open={confirmationOpen.addVatType} 
                                                    success={this.handleVatTypeConfirmationSuccess} 
                                                    close={this.handleVatTypeConfirmationClose} 
                                                    title="Add a new VAT Type?" 
                                                    message="Are you sure you want to add a new VAT type?"
                                                />
                                                <SnackBar
                                                    variant="success"
                                                    anchorOriginVertical='bottom'
                                                    anchorOriginHorizontal='right'
                                                    open={snackbarOpen.addVatType}
                                                    onClose={this.handleVatTypeSnackbarClose}
                                                    message="You have successfully added a new VAT type"
                                                />
                                            </form>
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default VATRates;