import React from 'react';
import Search from 'Components/Common/Search/Search';

const SupplierSales = () => (
    <Search
        configuration={{
            alternatingRowColours: true,
            apiRoute: '/reporting/invoicedSupplierSalesReport',
            apiParams: {
                supplier: [116]
            },
            noAutoLoad: true,
            columns: [
                {
                    heading: 'Unique Company ID',
                    field: rowData => rowData.acno,
                    dataRef: 'acno',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Company Name',
                    field: rowData => rowData.an,
                    dataRef: 'an',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Postcode',
                    field: rowData => rowData.pc,
                    dataRef: 'pc',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Transaction Date',
                    field: rowData => rowData.inv,
                    dataRef: 'inv',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Paxton Part Number',
                    field: rowData => rowData.sc,
                    dataRef: 'sc',
                    important: true,
                    sizeToContent: true
                },
                {
                    heading: 'Quantity Sold',
                    field: rowData => rowData.qo,
                    dataRef: 'qo',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Unit Selling price',
                    field: rowData => rowData.usp,
                    fieldFormat: 'currency',
                    dataRef: 'usp',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Country Code',
                    field: rowData => rowData.cc,
                    dataRef: 'cc',
                    important: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Currency Code',
                    field: rowData => rowData.mc,
                    dataRef: 'mc',
                    important: true,
                    sizeToContent: true,
                },
            ],
            dataRef: true,
            disableEnhancedExport: true,
            excel: true,
            key: 'i',
            name: 'paxton-pos',
            noResultsText: 'There are no paxton sales that match your search criteria',
            pagination: true,
            pageTitleData: [0, 'Reporting', 'Suppliers'],
            pdf: true,
            persistenceId: null,
            placeholderData: false,
            print: true,
            pulseResults: 0,
            responsiveImportance: true,
            rowsPerPage: 50,
            showCategory: true,
            showCustomer: true,
            showDate: false,
            showDateRange: true,
            showFullTotals: false,
            showSearch: false,
            showShipType: false,
            showSupplier: false,
            showTotals: false,
            title: 'Paxton POS Report'
        }}
    />
)

export default SupplierSales;