import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Typography } from "@material-ui/core";
import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

const UpdateSupplierCategories = ({id}) => {
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
        
    const dispatch = useDispatch();
    
    const loadCategories = () => {
        API.get(`/products/categories`)
        .then((res) => {
            if(res?.data) {

                const newCategories = _.map(res.data, (el) => {
                    return _.assign({
                        nm: el.category_name,
                        id: el.category_id,
                        subCategories: _.map(el.children, (_el) => {
                            return _.assign({
                                nm: _el.category_name,
                                id: _el.category_id,
                            });
                        }),
                    })
                });
                
                setCategories(newCategories);
                setIsLoading(false);

            }
        });
    }

    const loadSupplierCategories = () => {
        API.get(`/suppliers/${id}/categories`)
        .then(res => {
            if(res?.data) {
                setFormData(res.data);
            }
        });
    }

    const handleChange = (parentId, childId = null, subCategories = null) => {
        const newFormData = _.cloneDeep(formData);

        if(childId) {
            
            const childIdx = _.findIndex(newFormData, (el) => el === childId);

            if(childIdx > -1) {
                newFormData.splice(childIdx, 1);
                const parentIndex = _.findIndex(newFormData, (el) => el === parentId);
                if(parentIndex > -1) {
                    newFormData.splice(parentIndex, 1);
                }
            } else {
                newFormData.push(childId);
                let allChecked = true;
                _.forEach(subCategories, (el) => {
                    if(_.findIndex(newFormData, (id) => id === el.id) === -1) {
                        allChecked = false;
                    }
                });
                if(allChecked) {
                    newFormData.push(parentId);
                }
            }

        } else {

            const parentIndex = _.findIndex(newFormData, (el) => el === parentId);

            if(parentIndex > -1) {
                newFormData.splice(parentIndex, 1);
                _.forEach(subCategories, c => {
                    const childIndex = _.findIndex(newFormData, (el) => el === c.id);
                    if(childIndex > -1) {
                        newFormData.splice(childIndex, 1);
                    }
                });
            } else {
                newFormData.push(parentId);
                _.forEach(subCategories, c => {
                    const childIndex = _.findIndex(newFormData, (el) => el === c.id);
                    if(childIndex === -1) {
                        newFormData.push(c.id);
                    }
                });
            }
            
        }

        setFormData(newFormData);

    }

    const handleConfirm = () => {
        dispatch(deployConfirmation(`Are you sure you want to update the categories for this supplier?`, handleSubmit));
    }

    const handleSubmit = () => {
        setIsLoading(true);
        API.put(`/suppliers/${id}/categories`, { categories: formData }) 
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `Supplier categories updated successfully`));
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {
        loadCategories();
        loadSupplierCategories();
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={3}>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>
                    <Grid item xs={12} align="right">
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                        >   
                            <FAIcon icon="check" size={15} buttonPrimary />
                            Save Changes
                        </Button>
                    </Grid>
                    {_.map(categories, (cat, idx) => (
                        <Grid item xs={12} key={`cat-${idx}`}>
                            <Paper>
                                <Box p={3}>
                                    <Grid container spacing={3} alignItems='center'>
                                        <Grid item xs>
                                            <Typography variant="h6">
                                                {cat.nm}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox
                                                color="primary"
                                                checked={_.includes(formData, cat.id)}
                                                onChange={() => handleChange(cat.id, null, cat.subCategories)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box my={1.5}>
                                        <Divider />
                                    </Box>
                                    <Grid container spacing={1}>
                                        {_.map(cat.subCategories, (sCat, sIdx) => (
                                            <Grid item xs={12} md={4} lg={3} xl={2} key={`cat-${idx}-sub-${sIdx}`}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            checked={_.includes(formData, sCat.id)}
                                                            onChange={() => handleChange(cat.id, sCat.id, cat.subCategories)}
                                                            label={sCat.nm}
                                                        />
                                                    }
                                                    label={sCat.nm}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </>
            )}
        </Grid>
    )
}

export default UpdateSupplierCategories;