import React from 'react';
import { useSelector } from 'react-redux';

import StaffChip from 'Components/Common/Chips/StaffChip';
import Search from 'Components/Common/Search/Search';

const SupplierSales = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/invoicedSupplierSalesReport',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Invoice Date',
                        field: rowData => rowData.inv,
                        fieldFormat: 'date',
                        dataRef: 'inv',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Despatch Date',
                        field: rowData => rowData.d,
                        fieldFormat: 'date',
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Order Date',
                        field: rowData => rowData.oc,
                        fieldFormat: 'date',
                        dataRef: 'oc',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.an,
                        dataRef: 'an',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.acno,
                        dataRef: 'acno',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.cac,
                        dataRef: 'cac',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Post Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Reference',
                        field: rowData => rowData.on,
                        dataRef: 'on',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'PO',
                        field: rowData => rowData.r,
                        dataRef: 'r',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Stock Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.desc,
                        dataRef: 'desc',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.sup,
                        dataRef: 'sup',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Purchase Ref',
                        field: rowData => rowData.supR,
                        dataRef: 'supR',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Quantity',
                        field: rowData => rowData.qo,
                        alignment: 'right',
                        dataRef: 'qo',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'Unit Buy Price',
                        field: rowData => rowData.ubp,
                        fieldFormat: 'currency',
                        dataRef: 'ubp',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Total Buy Price',
                        field: rowData => rowData.tpc,
                        fieldFormat: 'currency',
                        dataRef: 'tpc',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Unit Selling Price',
                        field: rowData => rowData.usp,
                        fieldFormat: 'currency',
                        dataRef: 'usp',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Total Selling Price',
                        field: rowData => rowData.tps,
                        fieldFormat: 'currency',
                        dataRef: 'tps',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Taken By',
                        field: rowData => rowData.sp ? <StaffChip staff={staff?.[rowData?.sp]} /> : '-',
                        fieldFormat: 'staff',
                        dataRef: 'sp',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Proactive Responsibility',
                        field: rowData => rowData.mgr ? <StaffChip staff={staff?.[rowData?.mgr]} /> : '-',
                        fieldFormat: 'staff',
                        dataRef: 'mgr',
                        important: true,
                        sizeToContent: true,
                    }
                    // {
                    //     heading: 'Source',
                    //     field: rowData => rowData.src,
                    //     dataRef: 'src',
                    //     important: true,
                    //     sizeToContent: true,
                    // },
                    // {
                    //     heading: 'Area',
                    //     field: rowData => rowData.ar,
                    //     dataRef: 'ar',
                    //     important: true,
                    //     sizeToContent: true,
                    // },
                ],
                dataRef: true,
                excel: true,
                isMultiSupplier: true,
                key: 'i',
                name: 'supplier-sales',
                noResultsText: 'There are no supplier sales that match your search criteria',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Suppliers'],
                pdf: true,
                persistenceId: null,
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: true,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showShipType: false,
                showSupplier: true,
                showTotals: false,
                title: 'Supplier Sales Report'
            }}
        />
    )
}

export default SupplierSales;