import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Heading from 'Components/Common/Heading/Heading';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Tile from 'Components/Common/Tiles/Tile';
import { currencyFormat } from 'Functions/MiscFunctions';

const AgedBalances = ({customer, withIcons}) => {
    
    const   [toggle, setToggle] = useState(true),
            abDateTime          = toggle ? (customer?.credit_data?.abDateTime ?? 'Unknown') : (customer?.credit_data?.abgDateTime ?? 'Unknown'),
            ab1                 = toggle ? (customer?.credit_data?.ab1 ?? 0) : (customer?.credit_data?.abg1 ?? 0),
            ab2                 = toggle ? (customer?.credit_data?.ab2 ?? 0) : (customer?.credit_data?.abg2 ?? 0),
            ab3                 = toggle ? (customer?.credit_data?.ab3 ?? 0) : (customer?.credit_data?.abg3 ?? 0),
            abTotal             = toggle ? (customer?.credit_data?.abTotal ?? 0) : (customer?.credit_data?.abgTotal ?? 0),
            abFuture            = toggle ? (customer?.credit_data?.abFuture ?? 0) : (customer?.credit_data?.abgFuture ?? 0),
            abOlder             = toggle ? (customer?.credit_data?.abOlder ?? 0) : (customer?.credit_data?.abgOlder ?? 0),
            abCurrent           = toggle ? (customer?.credit_data?.abCurrent ?? 0) : (customer?.credit_data?.abgCurrent ?? 0);

    return (
        <Box p={3} pb={3}>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Box pt={1}>
                        <Heading text={`Aged Balances ${!toggle ? ` - Including Grace Period` : ` - Current`}`} />
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color="textSecondary">
                        Balances as of {abDateTime}
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title={toggle ? 'Show aged balances with grace' : 'Show current aged balances'}>
                        <FormControlLabel
                            control={
                                <Switch 
                                    disabled={!abDateTime}
                                    name="toggle"
                                    checked={toggle}
                                    onChange={() => setToggle(!toggle)}
                                    value={true}
                                    color="primary"
                                />
                            }
                            label={toggle ? `` : ``}
                            style={{marginLeft: 0}}
                        />
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={6} xl={3}>
                    <Tile
                        icon={withIcons ? 'check' : undefined}
                        color="#4CAF50"
                        noPaper
                        title="1 Month"
                        content={currencyFormat.format(ab1 ?? 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} xl={3}>
                    <Tile
                        icon={withIcons ? (parseFloat(ab2) > 0 && customer?.payment_terms?.pt_due_days === 30 ? 'times' : 'check') : undefined}
                        color={withIcons ? (parseFloat(ab2) > 0 && customer?.payment_terms?.pt_due_days === 30 ? '#F44336' : '#4CAF50') : undefined}
                        noPaper
                        title="2 Months"
                        content={currencyFormat.format(ab2 ?? 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} xl={3}>
                    <Tile
                        icon={withIcons ? (parseFloat(ab3) > 0 && (customer?.payment_terms?.pt_due_days === 30 || customer?.payment_terms?.pt_due_days === 60) ? 'times' : 'check') : undefined}
                        color={withIcons ? (parseFloat(ab3) > 0 && (customer?.payment_terms?.pt_due_days === 30 || customer?.payment_terms?.pt_due_days === 60) ? '#F44336' : '#4CAF50') : undefined}
                        noPaper
                        title="3 Months"
                        content={currencyFormat.format(ab3 ?? 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} xl={3}>
                    <Tile
                        icon={withIcons ? (parseFloat(abOlder) > 0 && (customer?.payment_terms?.pt_due_days === 30 || customer?.payment_terms?.pt_due_days === 60 || customer?.payment_terms?.pt_due_days === 90) ? 'times' : 'check') : undefined}
                        color={withIcons ? (parseFloat(abOlder) > 0 && (customer?.payment_terms?.pt_due_days === 30 || customer?.payment_terms?.pt_due_days === 60 || customer?.payment_terms?.pt_due_days === 90) ? '#F44336' : '#4CAF50') : undefined}
                        noPaper
                        title="Older"
                        content={currencyFormat.format(abOlder ?? 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} xl={3}>
                    <Tile
                        icon={withIcons ? 'info-circle' : undefined}
                        noPaper
                        title="Total"
                        content={currencyFormat.format(abTotal ?? 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} xl={3}>
                    <Tile
                        icon={withIcons ? 'info-circle' : undefined}
                        noPaper
                        title="Future"
                        content={currencyFormat.format(abFuture ?? 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} xl={3}>
                    <Tile
                        icon={withIcons ? 'info-circle' : undefined}
                        noPaper
                        title="Current"
                        content={currencyFormat.format(abCurrent ?? 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} xl={3}>
                    <Tile
                        icon={withIcons ? 'user' : undefined}
                        noPaper
                        title="Payment Terms"
                        content={customer?.payment_terms?.pt_name}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default AgedBalances;