import React  from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import CustomerReferralSources from './CustomerReferralSources/CustomerReferralSources';
import CustomerStatuses from './CustomerStatuses/CustomerStatuses';
import CustomerTypes from './CustomerTypes/CustomerTypes';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InsightChip from 'Components/Common/Chips/InsightChip';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { TabPanel, handleTabChange } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const initialState = {
    access: {
        referralSources: false,
        statuses: false,
        types: false
    },
    currentTab: 0,
    isLoading: true,
    tabs: {
        0: "Customer Referral Sources",
        1: "Customer Status Codes",
        2: "Customer Account Types"
    },
    tabText: {
        0: "Create and manage existing customer referral sources",
        1: "Create and manage existing customer status codes",
        2: "Create and manage existing customer account types"
    }
}

class SettingsCustomers extends React.Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Settings", "Customers"])
        this.loadComponentData()
    }

    loadComponentData = () => {
        Promise.all([
            API.access('settings-customers:customer-referral-sources'), 
            API.access('settings-customers:customer-statuses'), 
            API.access('settings-customers:customer-types'), 
        ])
        .then(([referralSources, statuses, types]) => {     
            this.setState({
                access: {
                    ...this.state.access,
                    referralSources: (referralSources.data && referralSources.data.has_access) || false,
                    statuses: (statuses.data && statuses.data.has_access) || false,
                    types: (types.data && types.data.has_access) || false,
                },
                currentTab: (referralSources.data && !referralSources.data.has_access) ?
                                ((statuses.data && !statuses.data.has_access) ?
                                    2 : 1
                                )
                            : this.state.currentTab,
                isLoading: false
            })
        })
    }

    render() {
        const { classes, scrollToTop, ui } = this.props;
        const { access, currentTab, isLoading, tabs, tabText } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabs[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}> 
                                        {access.referralSources && (
                                            <TabPanel value={currentTab} index={0}>
                                                <CustomerReferralSources scrollToTop={scrollToTop} />
                                            </TabPanel>
                                        )}
                                        {access.statuses && (
                                            <TabPanel value={currentTab} index={1}>
                                                <CustomerStatuses scrollToTop={scrollToTop} />
                                            </TabPanel>
                                        )}
                                        {access.types && (
                                            <TabPanel value={currentTab} index={2}>
                                                <CustomerTypes scrollToTop={scrollToTop} />
                                            </TabPanel>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {access.referralSources && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Referral Sources" />
                                )}
                                {access.statuses && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Status Codes" />
                                )}
                                {access.types && (
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Account Types" />
                                )}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps, null)(withStyles(ViewPage)(SettingsCustomers));