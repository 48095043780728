import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    cc1: {
        marginRight: 5,
        paddingLeft: '8px',
        borderRight: 'none',
        border:'1px solid rgba(209, 210, 230, 1)',
        background:'rgba(209, 210, 230, 0.75)',
        '&:before': {
            borderBottom: '1px solid rgba(209, 210, 230, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(209, 210, 230, 1)'
        }
    },
    cc2: {
        marginRight: 5,
        paddingLeft: '8px',
        borderRight: 'none',
        border: '1px solid rgba(179, 217, 238, 1)',
        background: 'rgba(179, 217, 238, 0.75)',
        '&:before': {
            borderBottom: '1px solid rgba(179, 217, 238, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(179, 217, 238, 1)'
        }
    },
    cc3: {
        marginRight: 5,
        paddingLeft: '8px',
        border:'1px solid rgba(171, 234, 229, 1)',
        background:'rgba(171, 234, 229, 0.75)',
        '&:before': {
            borderBottom: '1px solid rgba(171, 234, 229, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(171, 234, 229, 1)'
        }
    }
});

const initialState = () => ({
    focus: {
        commodity1: false,
        commodity2: false,
        commodity3: false,
        commodity4: false,
        commodity5: false
    },
    formData: {
        commodity1: '',
        commodity2: '',
        commodity3: '',
        commodity4: '',
        commodity5: ''
    }
});

const CommodityCodeInput = ({disabled, error, forExport, handleChange, label, noLabel, value}) => {

    const classes = useStyles();
    const [state,setState] = useState(initialState());
    const { formData } = state;
    const commodity1 = useRef(null);
    const commodity2 = useRef(null);
    const commodity3 = useRef(null);
    const commodity4 = useRef(null);
    const commodity5 = useRef(null);

    const commodityCodeFocus = fieldName => {
        setState(state => ({
            ...state,
            focus: {
                ...state.focus,
                [fieldName]: true
            }
        }))
    }

    const commodityCodeBlur = fieldName => {
        setState(state => ({
            ...state,
            focus: {
                [fieldName]: false
            }
        }))
    }

    const hocChange = fieldName => {

        const value = fieldName === 'commodity1' ? commodity1.current.value : (fieldName === 'commodity2' ? commodity2.current.value : (fieldName === 'commodity3' ? commodity3.current.value : (fieldName === 'commodity4' ? commodity4.current.value : commodity5.current.value)))
        
        if(fieldName === 'commodity1' && value.length === 2) {
            commodity2.current.focus();
        } else if(fieldName === 'commodity2') {
            if(value.length === 0) {
                commodity1.current.focus();
            } else if(value.length === 2) {
                commodity3.current.focus();
            }
        } else if(fieldName === 'commodity3') {
            if(value.length === 0) {
                commodity2.current.focus();
            } else if(value.length === 2) {
                commodity4.current.focus();
            }
        } else if(fieldName === 'commodity4') {
            if(value.length === 0) {
                commodity3.current.focus();
            } else if(value.length === 2) {
                if(!forExport) {
                    commodity5.current.focus();
                } else {
                    commodity4.current.blur();
                }
            }
        } else if(fieldName === 'commodity5') {
            if(value.length === 0) {
                commodity4.current.focus();
            } else if(value.length === 2) {
                commodity5.current.blur();
            }
        }

        setState(state => ({
            ...state,
            formData:{
                ...state.formData,
                [fieldName]: value
            }
        }))

        if(!forExport) {
            handleChange(`${commodity1.current.value}${commodity2.current.value}${commodity3.current.value}${commodity4.current.value}${commodity5.current.value}`)
        } else {
            handleChange(`${commodity1.current.value}${commodity2.current.value}${commodity3.current.value}${commodity4.current.value}`)
        }
    }

    useEffect(() => {
        if(!_.isEmpty(value)) {
            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    commodity1: value.substr(0,2) ?? '',
                    commodity2: value.substr(2,2) ?? '',
                    commodity3: value.substr(4,2) ?? '',
                    commodity4: value.substr(6,2) ?? '',
                    commodity5: value.substr(8,2) ?? ''
                }
            }))
        }/* eslint-disable-next-line */
    }, [])
    return (
        <FormControl fullWidth margin="none">
            {!noLabel && (
                <InputLabel htmlFor="commodity1" shrink>{label ?? 'Commodity Code'}</InputLabel>
            )}
            <div style={{display:'flex-inline'}} className={noLabel ? undefined : 'pt-1'}>
                
                    <Input
                        disabled={disabled}
                        name="commodity1"
                        className={`${classes.cc1} ${noLabel ? `m-0 mr-1` : ``}`}
                        value={formData.commodity1}
                        onChange={() => hocChange('commodity1')}
                        onFocus={() => commodityCodeFocus('commodity1')}
                        onBlur={() => commodityCodeBlur('commodity1')}
                        variant="filled"
                        margin={noLabel ? 'none' : undefined}
                        inputProps={{
                            ref: commodity1,
                            size: 2,
                            maxLength: 2,
                            pattern: '[0-9]*',
                            style: {
                                letterSpacing: 8,
                                fontWeight: 400,
                            }
                        }}
                    />
                    <Input
                        disabled={disabled}
                        name="commodity2"
                        className={`${classes.cc2} ${noLabel ? `m-0 mr-1` : ``}`}
                        value={formData.commodity2}
                        onChange={() => hocChange('commodity2')}
                        onFocus={() => commodityCodeFocus('commodity2')}
                        onBlur={() => commodityCodeBlur('commodity2')}
                        variant="filled"
                        margin={noLabel ? 'none' : undefined}
                        inputProps={{
                            ref: commodity2,
                            size: 2,
                            maxLength: 2,
                            pattern: '[0-9]*',
                            style: {
                                letterSpacing: 8,
                                fontWeight: 400,
                            }
                        }}
                    />
                    <Input
                        disabled={disabled}
                        name="commodity3"
                        className={`${classes.cc3} ${noLabel ? `m-0 mr-1` : ``}`}
                        value={formData.commodity3}
                        onChange={() => hocChange('commodity3')}
                        onFocus={() => commodityCodeFocus('commodity3')}
                        onBlur={() => commodityCodeBlur('commodity3')}
                        variant="filled"
                        margin={noLabel ? 'none' : undefined}
                        inputProps={{
                            ref: commodity3,
                            size: 2,
                            maxLength: 2,
                            pattern: '[0-9]*',
                            style: {
                                letterSpacing: 8,
                                fontWeight: 400,
                            }
                        }}
                    />
                    <Input
                        disabled={disabled}
                        name="commodity4"
                        className={`${classes.cc3} ${noLabel ? `m-0 mr-1` : ``}`}
                        value={formData.commodity4}
                        onChange={() => hocChange('commodity4')}
                        onFocus={() => commodityCodeFocus('commodity4')}
                        onBlur={() => commodityCodeBlur('commodity4')}
                        variant="filled"
                        margin={noLabel ? 'none' : undefined}
                        inputProps={{
                            ref: commodity4,
                            size: 2,
                            maxLength: 2,
                            pattern: '[0-9]*',
                            style: {
                                letterSpacing: 8,
                                fontWeight: 400,
                            }
                        }}
                        />
                {!forExport && (
                        <Input
                            disabled={disabled}
                            name="commodity5"
                            className={`${classes.cc3} ${noLabel ? `m-0 mr-1` : ``}`}
                            value={formData.commodity5}
                            onChange={() => hocChange('commodity5')}
                            onFocus={() => commodityCodeFocus('commodity5')}
                            onBlur={() => commodityCodeBlur('commodity5')}
                            variant="filled"
                            margin={noLabel ? 'none' : undefined}
                            inputProps={{
                                ref: commodity5,
                                size: 2,
                                maxLength: 2,
                                pattern: '[0-9]*',
                                style: {
                                    letterSpacing: 8,
                                    fontWeight: 400,
                                }
                            }}
                        />
                )}
                {error && (
                    <FormHelperText>Please enter a valid commodity code</FormHelperText>
                )}
            </div>
        </FormControl>
    )
}

export default CommodityCodeInput;