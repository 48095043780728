import React  from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionMenu from 'Components/Common/Activity/ActionMenu';
import Avatar from 'Components/Common/Avatars/Avatar';
import API from 'API';
import ChangeStaffPasswordForm from 'Components/Staff/UpdateStaff/ChangeStaffPasswordForm';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SearchOrders from 'Components/Sales/SearchOrders/SearchOrders';
import SearchQuotes  from 'Components/Quotes/SearchQuotes/SearchQuotes';
import TaskForm from 'Components/Common/Activity/TaskForm';
import Tile from 'Components/Common/Tiles/Tile';
import UpdateStaffMfa from 'Components/Staff/UpdateStaff/UpdateStaffMfa';
import UploadStaffProfilePhotoForm from 'Components/Staff/UpdateStaff/UploadStaffProfilePhotoForm';
import ViewStaffAuditLog from 'Components/Staff/ViewStaff/ViewStaffAuditLog';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { putActionStatus } from 'Redux/Actions/Notifications/Notifications';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { handleTabChange, TabPanel, toggleDialog } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import NotificationList from 'Components/Common/Notifications/NotificationList';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const initialState = {
    actions: {
        1: [],
        2: [],
        3: []
    },
    access: {
        addNote: false,
        updateStaff: false,
        staffAccess: false,
        updateMfa: false,
        updatePassword: false,
        setPin: false,
        updateAvatar: false,
        manageActions: false,
        assignActions: false
    }, 
    chart: {
        totals: null,
        values: null
    },
    chartData: null,
    currentTab: 0,
    dialog: {
        updateStaff: false,
        updatePassword: false,
        updateAvatar: false 
    },
    staff: {},
    staffId: -1,
    isLoading: true
}

class ViewStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.toggleDialog = toggleDialog.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
        this.timeout = false;
    }
    componentDidMount() {  
        let staffId =  this.props.loggedInStaff?.id ?? -1;
        staffId = staffId > 0 ? staffId : -1;
        if(this.props.match?.params?.tab) {
            let currentTab = 0;
            if(this.props.match.params.tab === 'notifications') {
                currentTab = 1
                this.setState({
                    currentTab,
                    staffId
                }, () => {
                    this.props.pageTitle([0, 'My Account']);
                    if(this.state.staffId !== -1) {
                        this.loadComponentData()
                    }
                })
            }
        } else {
            this.setState({
                staffId
            }, () => {
                this.props.pageTitle([0, 'My Account']);
                if(this.state.staffId !== -1) {
                    this.loadComponentData()
                }
            });
        }

    }

    componentDidUpdate = (prevProps) => {

        if((this.props.loggedInStaff?.id !== prevProps.loggedInStaff?.id) && this.state.staffId === -1) {
        this.setState({
                staffId: this.props.loggedInStaff?.id ?? -1
            }, () => {
                if(this.state.staffId !== -1) {
                    this.loadComponentData()
                }
            });
        }
        if(!this.props.dialog.dialogOpen && prevProps.dialog.dialogOpen) {
            this.loadComponentData();
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout)
    }

    refreshData = () => {
        this.timeout = setTimeout(() => this.loadComponentData(), 250);
    }

    loadComponentData = () => {
        API.get('/staff/' + this.state.staffId, { params: { viewStaff: true } })
        .then(result => {  

            if(result && result.data.errors) {

                this.props.history.push('/staff')

            } else if(result && result.data) {

                let staff        = result.data,
                    outstanding  = _.sortBy(_.filter(staff.actions, e => e.read === 1), el => moment(el.w, "DD-MM-YYYY HH:mm")),
                    completed    = _.sortBy(_.filter(staff.actions, e => e.read === 0), el => moment(el.w, "DD-MM-YYYY HH:mm")),
                    values       = {},
                    totals       = {},
                    labels       = [],
                    orders       = [],
                    quotes       = [],
                    totalOrders  = [],
                    totalQuotes  = [],
                    chartData    = result?.data?.chart ?? null;

                for (let i = 0, j = 11; i <= j; i++) {
                    orders.push(chartData?.[i]?.orders?.v);
                    totalOrders.push(chartData?.[i]?.orders?.t);
                    quotes.push(chartData?.[i]?.quotes?.v);
                    totalQuotes.push(chartData?.[i]?.quotes?.t);
                    labels.push(chartData?.[i]?.label);
                }

                values = {
                    labels,
                    datasets: [
                        {
                            label: 'Sales',
                            data: orders,
                            backgroundColor: '#4CAF50',
                            borderColor: '#C8E6C9',
                            fill: false,
                            lineTension: 0.25,
                        },
                        {
                            label: 'Quotes',
                            data: quotes,
                            fill: false,
                            backgroundColor: '#F44336',
                            borderColor: '#FFCDD2',
                            lineTension: 0.25,
                        }
                    ]
                }

                totals = {
                    labels,
                    datasets: [
                        {
                            label: 'Sales',
                            data: totalOrders,
                            backgroundColor: '#4CAF50',
                            borderColor: '#C8E6C9',
                            fill: false,
                            lineTension: 0.25,
                        },
                        {
                            label: 'Quotes',
                            data: totalQuotes,
                            fill: false,
                            backgroundColor: '#F44336',
                            borderColor: '#FFCDD2',
                            lineTension: 0.25,
                        }
                    ]
                }

                const actions = {
                    1: [],
                    2: [],
                    3: []
                }
        
                let i = 1;
        
                _.each(completed, action => {
                    actions[i].push(action);
                    if(i === 3) {
                        i = 1;
                    } else {
                        i++;
                    }
                })

                _.each(outstanding, action => {
                    actions[i].push(action);
                    if(i === 3) {
                        i = 1;
                    } else {
                        i++;
                    }
                })

                this.setState({
                    actions,
                    chart: {
                        totals,
                        values
                    },
                    chartData,
                    staff,
                    isLoading: false,
                });

            }
        })
    }

    handleAction = (action) => {
        if(action) {
            action = action.split(":");
            switch(action[0]) {
                case "url":
                    this.props.history.push(action[1]);
                break;
                default:
            }
        }
    }

    handleDismiss = (id, e) => {
        e.stopPropagation();
        this.props.putActionStatus(id);
        this.refreshData();
    }

    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            all
            relation="generic"
            toggleDialog={() => { this.props.closeDialog(); }}
        />, true, "", "standard","sm", false, true);


    render() {
        const { classes, defaultTab, scrollToTop, ui } = this.props;
        const { chart, chartData, currentTab, dialog, isLoading, staff, staffId } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            {!defaultTab && (
                                <Grid item xs={12}>
                                    <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                        <Box pt={1} pr={1} pb={1} pl={3}>
                                            <Grid container alignItems='center'>
                                                <Grid item xs>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item>
                                                            <Avatar
                                                                staffId={staffId}
                                                                size={40}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h4" className="textDefault">
                                                                {staff.staff_first_name} {staff.staff_last_name}
                                                            </Typography>  
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Add Action" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddTask()}>
                                                            <FAIcon type="light" icon="layer-plus" size={17.5} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Change Avatar" placement="top">
                                                        <IconButton onClick={() => this.toggleDialog('updateAvatar')}>
                                                            <FAIcon type="light" icon="portrait" size={17.5} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Change Password" placement="top">
                                                        <IconButton onClick={() => this.toggleDialog('updatePassword')}>
                                                            <FAIcon type="light" icon="key" size={17.5} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <ActionMenu
                                                        rows={[
                                                            {
                                                                icon: "layer-plus",
                                                                label: "Add Action",
                                                                onClick: () => this.handleDeployAddTask(),
                                                                display: true
                                                            },
                                                            {
                                                                icon: "portrait",
                                                                label: "Change Avatar",
                                                                onClick: () => this.toggleDialog('updateAvatar'),
                                                                display: true
                                                            },
                                                            {
                                                                icon: "key",
                                                                label: "Change Password",
                                                                onClick: () => this.toggleDialog('updatePassword'),
                                                                display: true
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box pt={0.5}>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="calendar-day"
                                                                        label={`Started ${moment(staff.staff_created_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="user-crown"
                                                                        label={`${staff.staff_role}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="envelope"
                                                                        label={`${staff.staff_email}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="phone"
                                                                        label={`${staff.staff_phone}`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> 
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </AppBar>
                                </Grid>   
                            )}   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} lg={5}>
                                                                <PaddedPaper>
                                                                    <Heading text="Account Overview" />
                                                                    <Grid container spacing={3} className="pl-1">    
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='user'
                                                                                // color={staff.staff_status === 'active' ? '#4CAF50' : '#f44336'}
                                                                                title="Account Status"
                                                                                content={`${staff.staff_status === 'active' ? 'Active' : 'Inactive'}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="shield-check"
                                                                                title="Multi-factor Authentication"
                                                                                content={`${staff.staff_mfa === 1 ? 'Activated' : 'Not Set'}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="scanner-touchscreen"
                                                                                title="Warehouse Pin"
                                                                                content={`${staff.staff_has_pin === 1 ? 'Activated' : 'Not Set'}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="key"
                                                                                title="Password Change Required"
                                                                                content={staff.spcr === '1' ? 'Yes' : 'No'}
                                                                            />
                                                                        </Grid>   
                                                                    </Grid>    
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12} lg={4}>
                                                                <PaddedPaper>
                                                                    <Heading text="Actions Insight" />
                                                                    <Grid container spacing={3}>  
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                icon={staff.actions_outstanding === 0 ? 'check' : 'exclamation-triangle'}
                                                                                color={staff.actions_outstanding === 0 ? '#4CAF50' : '#f44336'}
                                                                                noPaper
                                                                                title="Outstanding"
                                                                                content={staff.actions_outstanding}
                                                                            />
                                                                        </Grid>  
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                icon={staff.actions_overdue === 0 ? 'check' : 'exclamation-triangle'}
                                                                                color={staff.actions_overdue === 0 ? '#4CAF50' : '#f44336'}
                                                                                noPaper
                                                                                title="Overdue"
                                                                                content={staff.actions_overdue}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="check"
                                                                                color="#4CAF50"
                                                                                title="Completed | Today"
                                                                                content={staff.actions_completed_today}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="check"
                                                                                color="#4CAF50"
                                                                                title="Completed | All Time"
                                                                                content={staff.actions_total}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>    
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={6} lg={3}>
                                                                <PaddedPaper style={{height: '100%'}}>
                                                                    <Heading text="Activity" />
                                                                    <Grid container spacing={3} className="pl-1">    
                                                                        <Grid item xs={12}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="user-clock"
                                                                                title="Last Active"
                                                                                content={staff.staff_last_active_datetime === '0000-00-00 00:00:00' ? 'Never' : moment(staff.staff_last_active_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm")}
                                                                            />
                                                                        </Grid>  
                                                                        <Grid item xs={12}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="user-check"
                                                                                title="Last Logged In"
                                                                                content={staff.staff_last_login_datetime === '0000-00-00 00:00:00' ? 'Never' : moment(staff.staff_last_login_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm")}
                                                                            />
                                                                        </Grid>  
                                                                    </Grid>    
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} lg={6}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={6} lg={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Quotes" />
                                                                            <Grid container spacing={3} className="pl-1">   
                                                                                <Grid item xs={12} lg={4}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="This Week"
                                                                                        content={staff.quotesThisWeek}
                                                                                    />
                                                                                </Grid>  
                                                                                <Grid item xs={12} lg={4}>                            
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="This Month"
                                                                                        content={chartData?.[11]?.quotes?.t ?? 0}
                                                                                    />                          
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={4}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="All Time"
                                                                                        content={_.size(staff.quotes)}
                                                                                    />
                                                                                </Grid>   
                                                                            </Grid>    
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={6} lg={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Sales Orders" />
                                                                            <Grid container spacing={3} className="pl-1">   
                                                                                <Grid item xs={12} lg={4}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="This Week"
                                                                                        content={staff.ordersThisWeek}
                                                                                    />
                                                                                </Grid>  
                                                                                <Grid item xs={12} lg={4}>                            
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="This Month"
                                                                                        content={chartData?.[11]?.orders?.t ?? 0}
                                                                                    />                          
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={4}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="All Time"
                                                                                        content={_.size(staff.orders)}
                                                                                    />
                                                                                </Grid>    
                                                                            </Grid>    
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <PaddedPaper>
                                                                            <Box pb={3}>
                                                                                <Box pb={3}>
                                                                                    <Heading text="Net Quotes vs Orders" />
                                                                                </Box>
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item xs={12}>
                                                                                        <Box maxWidth={'80%'} margin='0 auto'>
                                                                                            <Line 
                                                                                                data={chart?.values} 
                                                                                                options={{
                                                                                                    legend: {
                                                                                                        display: false,
                                                                                                    },
                                                                                                    scales: {
                                                                                                        xAxes: [{
                                                                                                            gridLines: {
                                                                                                                color: "rgba(0,0,0,.03)",
                                                                                                            }
                                                                                                        }],
                                                                                                        yAxes: [{
                                                                                                            gridLines: {
                                                                                                                color: "rgba(0,0,0,.03)",
                                                                                                            },
                                                                                                            ticks: {
                                                                                                                callback: (value, index, values) => {
                                                                                                                    return value.toLocaleString("en-GB", {
                                                                                                                        style: "currency",
                                                                                                                        currency: "GBP"
                                                                                                                    });
                                                                                                                },
                                                                                                                beginAtZero: true,
                                                                                                                min: 0
                                                                                                            }
                                                                                                        }]
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <PaddedPaper>
                                                                            <Box pb={3}>
                                                                                <Box pb={3}>
                                                                                    <Heading text="Total Quotes vs Orders" />
                                                                                </Box>
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item xs={12}>
                                                                                        <Box maxWidth={'80%'} margin='0 auto'>
                                                                                            <Line 
                                                                                                data={chart?.totals} 
                                                                                                options={{
                                                                                                    legend: {
                                                                                                        display: false,
                                                                                                    },
                                                                                                    scales: {
                                                                                                        xAxes: [{
                                                                                                            gridLines: {
                                                                                                                color: "rgba(0,0,0,.03)",
                                                                                                            }
                                                                                                        }],
                                                                                                        yAxes: [{
                                                                                                            gridLines: {
                                                                                                                color: "rgba(0,0,0,.03)",
                                                                                                            },
                                                                                                            ticks: {
                                                                                                                beginAtZero: true,
                                                                                                                min: 0
                                                                                                            }
                                                                                                        }]
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <NotificationList />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <Paper>
                                                    <ViewStaffAuditLog staff={staff} isLoading={isLoading} />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <UpdateStaffMfa myAccount={true} staff={staff} staffId={staff.staff_id} scrollToTop={scrollToTop} callback={() => this.componentDidMount()} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <SearchQuotes dataLoading={isLoading} staffId={staffId} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <SearchOrders dataLoading={isLoading} staffId={staffId} />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <SmallFormDialog
                                    open={dialog.updateAvatar}
                                    onClose={() => this.toggleDialog('updateAvatar')}
                                    title="Change Avatar"
                                    content={<UploadStaffProfilePhotoForm staffId={staff.staff_id} toggleDialog={() => this.toggleDialog('updateAvatar')} />}
                                    maxWidth='xs'
                                />
                                <SmallFormDialog
                                    open={dialog.updatePassword}
                                    onClose={() => this.toggleDialog('updatePassword')}
                                    title="Change Password"
                                    content={<ChangeStaffPasswordForm myPassword={true} toggleDialog={() => this.toggleDialog('updatePassword')} />}
                                    maxWidth='xs'
                                />
                            </Grid>
                            {!defaultTab && (
                                <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                    <Tabs
                                        color="primary"
                                        onChange={this.handleTabChange}
                                        orientation="vertical"
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none'
                                            }
                                        }}
                                        value={currentTab}
                                        variant="scrollable"
                                    >
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Notifications" />
                                        <Divider />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Login History" />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="MFA" />
                                        <Divider />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} label="Quotes" />
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={5} label="Orders" />
                                    </Tabs>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => ({
    dialog: state.dialog,
    loggedInStaff: state.staffAuth.staff,
    actions: state.notifications.actions,
    notifications: state.notifications.notifications,
    status: state.notifications.status,
    ui: state.ui

})

const mapDispatchToProps = dispatch => {
    return {
        putActionStatus: (id) => dispatch(putActionStatus(id)),
        closeDialog: () => dispatch(closeDialog()),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, success, cancel = false) => dispatch(deployConfirmation(message, success, cancel)),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fs = false, de = true) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, de))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ViewStaff));