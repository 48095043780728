import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import { CLOUDFRONT_URL } from 'Constants';
import { currencyFormat } from 'Functions/MiscFunctions';

const Frequent = ({classes, products, action = e => false}) => (
    (!_.isEmpty(products) && (
        <>
            <Box pt={3}>
                <Paper elevation={0} className={classes.frequentPaper}>
                    <Tabs
                        value={0}
                        variant="scrollable"
                        TabIndicatorProps={{
                            style: {
                                display: 'none'
                            }
                        }}
                    >
                        {_.map(products, (product, idx) => (
                            <Tab 
                                key={idx}
                                index={idx}
                                className={classes.tab}
                                onClick={() => action(product.id)}
                                label={(
                                    <Tooltip title={`${product.inStock} In Stock | ${product.allocated} Allocated | ${product.available} Available | ${product.onOrder} On Order | ${product.preOrdered} Pre-ordered`}>
                                        <Card 
                                            elevation={0} 
                                            className={classes.card}
                                        >   
                                            <CardContent className="p-1">
                                                <ImageWithError 
                                                    product 
                                                    src={`${CLOUDFRONT_URL}${product.image}`} 
                                                    width={50} 
                                                    height={50} 
                                                    alt={product.name} 
                                                />
                                                <Typography variant="caption" className="fw-400" component="div">
                                                    {product.code} | {currencyFormat.format(product.price)}
                                                </Typography>
                                                <Typography variant="caption" component="div" className={classes.overflowText}>
                                                    {product.name}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Tooltip>
                                )}
                            />
                        ))}
                    </Tabs>
                </Paper>
            </Box>
        </>
    )) || (
        <React.Fragment />
    )
)

export default Frequent;