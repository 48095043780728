import React  from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import { handleChange, handleNumberChange, handleFloat, handleConfirmationOpen, handleConfirmationClose, handleConfirmationSuccess } from 'Functions/FormFunctions';

const initialState = {
    confirmation: {
        cancel: false
    },
    formData: {
        id: '',
        courier: '',
        service: '',
        cost: '',
        notes: ''
    },
    formErrors: {}
}

class CarriageDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState
        this.handleChange = handleChange.bind(this)
        this.handleFloat = handleFloat.bind(this)
        this.handleNumberChange = handleNumberChange.bind(this)
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        const { formData, formErrors } = this.props;
        this.setState({
            formData,
            formErrors
        })
    }
   
    componentDidUpdate = (prevProps) => {
        if(this.props.formData !== prevProps.formData) {
            const { formData, formErrors } = this.props;
            this.setState({
                formData,
                formErrors
            })
        }
    }

    handleCheckedChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target?.name]: e.target?.checked ?? 'false'
            }
        })
    }

    cancel = () => {
        this.props.handleCancel(true)
    }
    
    submit = () => {
        this.props.handleSubmit(this.state.formData)
    }

    render() {
        const { open, idx } = this.props;
        const { formErrors, formData } = this.state;
        return (
            <>
                <Dialog
                    disableEscapeKeyDown={true} 
                    disableBackdropClick={true} 
                    open={open}
                    maxWidth='sm' 
                    scroll="body"
                    onEscapeKeyDown={this.cancel}
                >               
                    <DialogTitle disableTypography>
                        <Typography variant="h6">
                            Carriage Details
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{padding: '0 24px 24px 24px', minWidth: 400, maxWidth: 500}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <form noValidate autoComplete="off">
                                    <TextField
                                        name="courier"
                                        label="Courier *"
                                        value={formData.courier}
                                        error={formErrors && formErrors['supplierCarriage|courier|'+idx] && true}
                                        helperText={formErrors && formErrors['supplierCarriage|courier|'+idx]}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                    <TextField
                                        name="service"
                                        label="Service *"
                                        value={formData.service}
                                        error={formErrors && formErrors['supplierCarriage|service|'+idx] && true}
                                        helperText={formErrors && formErrors['supplierCarriage|service|'+idx]}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                    <TextField
                                        name="cost"
                                        label="Cost *"
                                        value={formData.cost}
                                        error={formErrors && formErrors['supplierCarriage|cost|'+idx] && true}
                                        helperText={formErrors && formErrors['supplierCarriage|cost|'+idx]}
                                        onChange={this.handleNumberChange}
                                        onBlur={this.handleFloat}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                                        }}
                                        fullWidth
                                    />
                                    <Textarea
                                        label="Default Purchase Order Notes"
                                        name="notes"
                                        margin="normal"
                                        value={formData.notes}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button onClick={this.cancel} variant="text" color="primary">
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                                <Button onClick={this.submit} variant="text" color="primary" disabled={_.isEmpty(formData.courier) || _.isEmpty(formData.service) || _.isEmpty(formData.cost) || formData.cost < 0}>
                                    <FAIcon icon="check" size={15} button />
                                    {idx === -1 ? 'Add' : 'Update'} Carriage
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>   
            </>
        )
    }
}

export default CarriageDialog;