import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import SupplierPriceListInfoForm from 'Components/Settings/Suppliers/SupplierPriceListInfo/SupplierPriceListInfoForm';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = () => ({
    data: [],
    isLoading: true
})

const SupplierPriceListInfo = ({scrollToTop}) => {

    const [state, setState] = useState(initialState());

    const dispatch = useDispatch();

    const callback = () => {
        API.get('/suppliers/priceListInformation')
        .then(res => {
            setState({
                data: res.data,
                isLoading: false
            })
            scrollToTop?.();
        })
    }

    const handleDeployForm = rowData => {
        dispatch(deployDialog(
            <SupplierPriceListInfoForm
                allData={data}
                callback={callback}
                data={rowData}
            />,
            false,
            `${rowData ? `Update` : `Add`} Supplier Price List Information`,
            'standard',
            'sm'
        ))
    }

    const handleDelete = rowData => {
        API.put(`/suppliers/priceListInformation/${rowData.spli_id}/delete`)
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `You have successfully deleted price list information for ${rowData.supplier?.supp_company_name ?? 'the supplier'}`));
                callback();
            }
        })
    }

    useEffect(() => {
        callback(); /* eslint-disable-next-line */
    }, [])

    const {
        data,
        isLoading
    } = state;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PaddedPaper>
                    <Grid container alignItems='center' justify='space-between'>
                        <Grid item>
                            <Typography variant="h6" paragraph>
                                Supplier Price List Information
                            </Typography>
                        </Grid>
                        <Grid item align='right'>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleDeployForm(null)}
                            >
                                <FAIcon icon='plus' size={15} button />
                                Add Info
                            </Button>
                        </Grid>
                    </Grid>
                    <DataTable  
                        config={{
                            key: 'spli_id',
                            pagination: true,
                            isLoading: isLoading,
                            plainPagination: true,
                            options: {
                                minimalPadding: true
                            }
                        }}
                        columns={[
                            {
                                heading: 'Supplier',
                                field: rowData => rowData.supplier?.supp_company_name ?? '-',
                                sizeToContent: true
                            },
                            {
                                heading: 'Text',
                                field: rowData => rowData.spli_text?.length === 0 ? `-` : rowData.spli_text,
                                truncate: true
                            },
                            {
                                heading: 'Red Text',
                                field: rowData => rowData.spli_color,
                                fieldFormat: 'boolean',
                                truncate: true,
                                alignment: 'center',
                                sizeToContent: true
                            },
                            {
                                actions: rowData => {
                                    return [
                                        {name: 'Update', icon: 'pencil',  onClick: () => handleDeployForm(rowData)},
                                        {name: 'Delete', icon: 'trash-alt',  onClick: () => dispatch(deployConfirmation(`Are you sure you want to delete this supplier price list information?`, () => handleDelete(rowData)))}
                                    ]
                                }
                            }
                        ]}
                        rows={data}
                    />
                </PaddedPaper>
            </Grid>                
        </Grid>
    )

}

export default SupplierPriceListInfo;