import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import TeamChip from '../Chips/TeamChip';
import WildixContext from 'Context/WildixContext';
import { updateAction } from 'Redux/Actions/Dialog/Dialog';
import { momentFormatDateTime } from 'Functions/MiscFunctions';

const getRelation = (relation, data) => {
    let icon, prefix, ref, subRef;
    switch(relation) {
        case "contact":
            icon = "user"
            prefix = "Contact"
            ref = `${data.contact_first_name} ${data.contact_last_name} ${data.contact_mobile?.length > 0 ? `| ${data.contact_mobile} ` : (data.contact_telephone > 0 ? `| ${data.contact_telephone} ` : '')}| ${data.customer?.cust_company_name ?? data.supplier?.supp_company_name ?? 'Unknown'}`
        break;
        case "customer":
            icon = "users"
            prefix = "Customer"
            ref = data?.cust_company_name ?? "Unknown"
        break;
        case "supplier":
            icon = "box-check"
            prefix = "Supplier"
            ref = data?.supp_company_name ?? "Unknown"
        break;
        case "product":
            icon = "boxes"
            prefix = "Product"
            ref = data?.default_code?.code_value ?? data.product_name ?? "Unknown"
            ref = `#${ref}`
        break;
        case "po":
            icon = "purchase"
            prefix = "Purchase Order"
            ref = data?.po_reference ?? "Unknown"
            ref = `Purchase Order #${ref}`
            subRef = data?.supplier?.supp_company_name ?? null
        break;
        case "quote":
            icon = "quote"
            prefix = "Quote"
            ref = data?.quote_reference ?? "Unknown"
            ref = `Quote #${ref}`
            subRef = data?.customer?.cust_company_name ?? null
        break;
        case "order":
            icon = "order"
            prefix = "Order"
            ref = data?.order_reference ?? "Unknown"
            ref = `Sales Order #${ref}`
            subRef = data?.customer?.cust_company_name ?? null
        break;
        case "rma":
            icon = "exchange-alt"
            prefix = "RMA"
            ref = data?.rma_reference ?? "Unknown"
            ref = `RMA #${ref}`
            subRef = data?.customer?.cust_company_name ?? null
        break;
        default:
            return false
    }
    return {
        icon,
        prefix,
        ref,
        subRef
    }
}

const initialState = () => ({
    deleteActions: false,
    editActions: false,
    manageActions: false
})

const Action = ({
    categories, 
    compact, 
    data, 
    elevation,
    inline, 
    inDialog,
    handleAction, 
    handleDismiss, 
    handleDelete, 
    noXlDivider,
    noAuthor,
    noDivider, 
    read, 
    staffId, 
    status, 
    updateAction,
    xl
}) => {

    const context = useContext(WildixContext);
    const canDial = context?.dial === null;

    const [access, setAccess] = useState(initialState());

    const teams = useSelector(state => state.notifications.teams);
    
    const handleUpdate = (id, e) => {
        e.stopPropagation();
        updateAction(id);
    }

    useEffect(() => {
        Promise.all([
            API.access("view-staff:manage-actions"),
            API.access("actions:edit"),
            API.access("actions:delete")
        ])
        .then(([
            manageActions,
            editActions,
            deleteActions
        ]) => {
            setAccess({
                deleteActions: deleteActions?.data?.has_access ?? false,
                editActions: editActions?.data?.has_access ?? false,
                manageActions: manageActions?.data?.has_access ?? false,
            })
        })
    }, []);

    if(data) {

        let actionStatus = null,
            overdue      = false;

        if(data.z) {
            actionStatus = 'textSuccess';
        } 
        else if(moment(data.w, "DD/MM/YYYY HH:mm").isBefore(moment())) {
            actionStatus = 'textError';
            overdue      = true;
        } 
        else if(moment(data.w, "DD/MM/YYYY HH:mm").isBefore(moment().add(30, 'minute'))) {
            actionStatus = 'textWarning';
        }

        const CardContainer = data.r && handleAction ? CardActionArea : "div";
        const relation = getRelation(data.r, data.d);
        const completed = data.z && data.w !== "0000-00-00 00:00:00";
        return (
            <>
                <Card 
                    onClick={() => handleAction && handleAction(data.a)}
                    elevation={elevation ?? (inDialog ? 0 : 1)}
                    style={{border: inDialog && !actionStatus ? '1px solid #ddd' : undefined, maxWidth: compact && noAuthor ? 450 : undefined}}
                >
                    <CardContainer style={{position: 'relative', borderRadius: 3, border: inline ? undefined : (completed ? '1px solid #C8E6C9': (overdue ? '1px solid #FFCDD2': (!data.w ? undefined : (actionStatus === 'textWarning' ? '1px solid #FFE0B2' : undefined))))}}>
                        <Box p={2.5}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box pb={!inline ? 2 : 1}>
                                        {(inline || (!inline && _.isEmpty(data.c))) && (
                                            <Typography className="fw-400" variant={compact ? 'body1' : 'h6'} gutterBottom={!_.isEmpty(data.c)}>{data.t}</Typography>
                                        )}
                                        {!_.isEmpty(data.c) && data.c.split('\n').map((item, key) => (
                                            <Typography className="fw-400" variant={compact ? 'body2' : (data.c.length < 100 ? 'h6': (data.c.length < 150 ? 'body1' : (data.c.length < 200 ? 'body2' : data.c.length < 300 ? 'body2' : 'caption')))} style={{fontWeight: !compact ? 400 : undefined}} component="div" key={key}>{item}</Typography>
                                        ))}
                                    </Box>
                                </Grid>
                                {xl && !noXlDivider && (
                                    <Grid item xs={12} className="pb-1">
                                        <Divider />
                                    </Grid>
                                )}
                                {!inline && categories?.[data.t]?.name && (
                                    <Grid item xs={xl ? false : 12}>
                                        <InsightChip
                                            icon={categories?.[data.t]?.icon ?? 'question'}
                                            label={categories?.[data.t]?.name ?? 'Unknown'}
                                            size={18}
                                            type="thin"
                                        />
                                    </Grid>
                                )}
                                {relation && (
                                    <>
                                        <Grid item xs={12}>
                                            <InsightChip
                                                icon={relation.icon}
                                                label={relation.ref}
                                                size={18}
                                                type="thin"
                                            />
                                        </Grid>
                                        {relation.subRef && (
                                            <Grid item xs={12}>
                                                <InsightChip
                                                    icon={'user'}
                                                    label={relation.subRef}
                                                    size={18}
                                                    type="thin"
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {(data.w && (
                                    <Grid item xs={xl && !inline ? false : 12}>
                                        <InsightChip
                                            className={!read && actionStatus && actionStatus}
                                            icon='bell-exclamation'
                                            label={`Due: ${data.w}${data.w && data.q ? ` (Every ${data.q} ${data.u}${data.q > 1 ? 's' : ''})` : ``}`}
                                            size={18}
                                            type="thin"
                                        />
                                    </Grid>
                                )) || (
                                    <Grid item xs={xl && !inline ? false : 12}>
                                        <InsightChip
                                            icon='bell-exclamation'
                                            label={`No deadline set`}
                                            size={18}
                                            type="thin"
                                        />
                                    </Grid>
                                )}
                                {completed && (
                                    <Grid item xs={xl && !inline ? false : 12}>
                                        <InsightChip
                                            className={actionStatus}
                                            icon='check'
                                            label={`Completed: ${data.z}`}
                                            size={18}
                                            type="thin"
                                        />
                                    </Grid>
                                )}
                                {data.wc && (
                                    <Grid item xs={xl ? false : 12}>
                                        <InsightChip
                                            icon='calendar-day'
                                            label={`Created: ${momentFormatDateTime(data.wc)}`}
                                            size={18}
                                            type="thin"
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={xl && !inline ? false : 12}>
                                    {data?.f > 0 && (
                                        <StaffChip
                                            staff={status?.[data.f ?? 0] ?? false}
                                            label='Created By: '
                                            style={{marginLeft: -2}}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={xl ? false : 12}>
                                    {data?.tt > 0 && (
                                        <TeamChip
                                            team={teams?.[data?.tt]}
                                            style={{marginLeft: -2}}
                                            label='Assigned To: '
                                        />
                                    )}
                                    {data?.x > 0 && (
                                        <StaffChip
                                            staff={status?.[data.x ?? 0] ?? false}
                                            label='Assigned To: '
                                            style={{marginLeft: -2}}
                                        />
                                    )}
                                </Grid>
                                {!read && (
                                    <Grid item xs={12} style={{position: 'absolute', bottom: 8, right: 8}}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs align='right'>
                                                {canDial && data.p && (
                                                    <Tooltip title={`Call ${data.p}`}>
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e?.stopPropagation?.();
                                                                context.useDial(data.p)
                                                            }}
                                                        >
                                                            <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={15} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {!completed && (access.editActions || (staffId && staffId === data.f)) && (
                                                    <Tooltip title="Modify Action">
                                                        <IconButton
                                                            onClick={e => handleUpdate(data.i, e)}
                                                        >
                                                            <FAIcon type="light" icon="pencil" size={15} noMargin button />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {!completed && (access.deleteActions || (staffId && staffId === data.f)) && (
                                                    <Tooltip title="Delete Action">
                                                        <IconButton
                                                            onClick={e => handleDelete(data.i, e)}
                                                        >
                                                            <FAIcon type="light" icon="trash" size={15} noMargin button />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {!completed && handleDismiss && (access.manageActions || ((data?.f === staffId || data?.x === staffId) || data.tt > 0)) && (
                                                    <Tooltip title="Mark as Completed">
                                                        <IconButton
                                                            onClick={e => handleDismiss(data.i, e)}
                                                        >
                                                            <FAIcon type="light" icon="check" size={15} noMargin button />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {/* {closeDialog && (
                                                    <Tooltip title="Close">
                                                        <IconButton
                                                            onClick={() => closeDialog()}
                                                        >
                                                            <FAIcon type="light" icon="times" size={15} noMargin button />
                                                        </IconButton>
                                                    </Tooltip>
                                                )} */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </CardContainer>
                </Card>
                {inline && !noDivider && (
                    <Divider />
                )}
            </>
        )

    } else {
        return <React.Fragment />
    }    
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateAction: (id) => dispatch(updateAction(id))
    };
}

export default connect(null, mapDispatchToProps)(Action);