import React from 'react';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Tiles/Tile';

const ViewStaffMfaStatus = ({isLoading, status, noPaper}) => (
    (isLoading && (
        <LoadingCircle />
    )) || (              
        <Tile
            icon="shield-check"
            color={status[0] === 1 ? '#8BC34A' : '#f44336'}
            title="Multi-Factor Authentication Status"
            content={(status[0] === 1 && ( `Activated` )) || ( `Not Activated` )}
            noPaper={noPaper}
            textClass="fw-400"
        />
    )
)

export default ViewStaffMfaStatus;