import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import EmailLog from 'Components/Communication/Communication/EmailLog';
import InsightChip from 'Components/Common/Chips/InsightChip';
import SmsLog from 'Components/Communication/Communication/SmsLog';
import StaffMember from 'Components/Common/Staff/StaffMember';
import Widget from 'Components/Common/Tiles/Widget';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { TabPanel, handleTabChange } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const initialState = {
    access: {
        switchStaff: false,
        emailLog: false,
        smsLog: false,
    },
    currentTab: 1,
    insight: {},
    isLoading: true,
    staff: 0,
    tabTitle: {
        0: "Overview",
        1: "E-mails",
        2: "Text Messages",
    },
    tabText: {
        0: "Live communication data and insights",
        1: "Search, view and re-send previous e-mails sent by RED",
        2: "Search and view previous text messages sent by RED",
    },
}

class Communication extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        API.multiAccess([
            'communication-switch',
            'email-log',
            'sms-log',
        ])
        .then(([
            switchStaff,
            emailLog, 
            smsLog
        ]) => {
            this.setState({
                access: {
                    switchStaff,
                    emailLog,
                    smsLog
                },
                staff: this.props.loggedInStaff?.id ?? 0
            }, () => {
                this.props.pageTitle([0, 'Communication', 'Dashboard']);
                if(this.state.staff > 0) {
                    this.loadComponentData();
                }
            });
        })
    }

    componentDidUpdate = prevProps => {
        if(prevProps.loggedInStaff?.id !== this.props.loggedInStaff?.id && this.state.staff === 0) {
            this.setState({
                staff: this.props.loggedInStaff?.id ?? 0
            }, () => {
                if(this.state.staff > 0) {
                    this.loadComponentData();
                    // this.props.pageTitle([0, 'Communication', `${this.props.loggedInStaff.firstName} ${this.props.loggedInStaff.lastName}`, 'Overview']);
                }
            });
        }
    }

    // handleTabChange = (event, tabIndex) => {
    //     const currentTab = !isNaN(event) ? event : tabIndex;
    //     let staff = this.state.staff === 0 ? 'User' : this.props.staff?.[this.state.staff]?.name ?? 'User';
    //     this.setState({
    //         currentTab
    //     }, () => {
    //         this.props.pageTitle([0, "Communication", staff, this.state?.tabTitle?.[currentTab] ?? 'Overview'])
    //     })
    // }

    handleUserChange = selectedOption => {
        if(selectedOption?.value) {
            this.setState({
                isLoading: true,
                staff: selectedOption.value
            }, () => {
                if(this.state.staff > 0) {
                    this.loadComponentData();
                    // this.props.pageTitle([0, 'Communication', staff, this.state?.tabTitle?.[currentTab] ?? 'Overview']);
                }
            });
        } else {
            this.setState({
                isLoading: true,
                staff: false
            }, () => {
                this.loadComponentData();
                // this.props.pageTitle([0, 'Communication', this.state?.tabTitle?.[currentTab] ?? 'Overview']);
            });
        }
    }

    loadComponentData = () => {
        const { staff } = this.state;
        let params = {}
        if(staff) {
            params = {
                staff
            }
        }
        API.get('/insight/communication',
            {
                params
            }
        )
        .then(res => {
            if(res?.data) {
                this.setState({
                    isLoading: false,
                    insight: res.data
                })
            }
        })
    }

    render() {
        const { classes, staffList, staff, ui } = this.props;
        const { access, currentTab, insight, isLoading, tabTitle, tabText } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabTitle[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        <TabPanel value={currentTab} index={0}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} lg={6}>
                                                            <Widget 
                                                                onClick={() => this.handleTabChange(1)}
                                                                icon="envelope"
                                                                header="E-mail"
                                                                content={[
                                                                    {
                                                                        heading: "Today",
                                                                        content: insight?.email?.today ?? 0
                                                                    },
                                                                    {
                                                                        heading: "This Week",
                                                                        content: insight?.email?.week ?? 0
                                                                    },
                                                                    {
                                                                        heading: "This Month",
                                                                        content: insight?.email?.month ?? 0
                                                                    },
                                                                    {
                                                                        heading: "Total",
                                                                        content: insight?.email?.total ?? 0
                                                                    }
                                                                ]} 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <Widget 
                                                                onClick={() => this.handleTabChange(2)}
                                                                icon="mobile"
                                                                header="SMS"
                                                                content={[
                                                                    {
                                                                        heading: "Today",
                                                                        content: insight?.sms?.today ?? 0
                                                                    },
                                                                    {
                                                                        heading: "This Week",
                                                                        content: insight?.sms?.week ?? 0
                                                                    },
                                                                    {
                                                                        heading: "This Month",
                                                                        content: insight?.sms?.month ?? 0
                                                                    },
                                                                    {
                                                                        heading: "Total",
                                                                        content: insight?.sms?.total ?? 0
                                                                    }
                                                                ]} 
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        {access.emailLog && (
                                            <TabPanel value={currentTab} index={1}>
                                                <EmailLog
                                                    staff={this.props.staff}
                                                    deployConfirmation={this.props.deployConfirmation}
                                                    deploySnackBar={this.props.deploySnackBar}
                                                    deployDialog={this.props.deployDialog}
                                                    closeDialog={this.props.closeDialog}
                                                    selectedStaff={this.state.staff}
                                                />
                                            </TabPanel>
                                        )}
                                        {access.smsLog && (
                                            <TabPanel value={currentTab} index={2}>
                                                <SmsLog
                                                    staff={this.props.staff}
                                                    deployConfirmation={this.props.deployConfirmation}
                                                    deploySnackBar={this.props.deploySnackBar}
                                                    deployDialog={this.props.deployDialog}
                                                    closeDialog={this.props.closeDialog}
                                                    selectedStaff={this.state.staff}
                                                />
                                            </TabPanel>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <>
                                <Box p={3}>
                                    <Grid container justify="center">
                                        <Grid item>
                                            <StaffMember 
                                                vertical 
                                                staff={staff?.[this.state.staff] ?? null} 
                                                minimal
                                                size="lg"
                                                noPadding
                                            />
                                        </Grid>
                                    </Grid>
                                    {access.switchStaff && (
                                        <AutoCompleteSelect
                                            options={staffList}
                                            placeholder="Filter: "
                                            onChange={v => this.handleUserChange(v)}
                                            value={this.state.staff}
                                            variant="filled"
                                        /> 
                                    )}
                                </Box>
                                <Box pl={3} pr={3} pb={1}>
                                    <Divider />
                                </Box>
                            </>
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {/* <Tab 
                                    classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                    value={0} 
                                    label="Overview" 
                                /> */}
                                {access.emailLog && (
                                    <Tab 
                                        classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                        value={1} 
                                        label="E-mails" 
                                    />
                                )}
                                {access.smsLog && (
                                    <Tab 
                                        classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                        value={2} 
                                        label="Text Messages" 
                                    />
                                )}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        staff: state.notifications.status,
        staffList: state.notifications.staffList,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(Communication));