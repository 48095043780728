import React from 'react';
import _ from 'lodash';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import moment from 'moment';
import DataTable from 'Components/Common/DataTables/DataTable';
import Alert from 'Components/Common/Alert/Alert';

class VATRateList extends React.Component {

    render() {
        const { vatRates } = this.props;
        return (
            _.map(vatRates, (type, idx) => {
                return (
                    <ExpansionPanel defaultExpanded key={idx}>
                        <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                            <Typography variant="h6">{type.vat_type_name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {(type.vat_values && type.vat_values.length > 0 && (
                                <DataTable  
                                    config={{
                                        key: 'vat_value_id',
                                        responsiveImportance: true,
                                        options: {
                                            minimalPadding: true
                                        },
                                        rowProps: row => {
                                            return ({
                                                style: {
                                                    color: row.current === true && '#ef3340'
                                                }
                                            });
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: 'Percentage',
                                            field: rowData => rowData.vat_value_percentage,
                                            fieldSuffix: '%',
                                        },
                                        {
                                            heading: 'Start Date',
                                            field: rowData => moment(new Date(rowData.vat_value_start_date)).format('DD/MM/YYYY'),
                                            sizeToContent: true,
                                            important: true,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'End Date',
                                            field: rowData =>   (rowData.vat_value_end_date === '0000-00-00' && !rowData.current) ?
                                                                    '-'
                                                                    :
                                                                    (rowData.vat_value_end_date === '0000-00-00' ? 'Current' : moment(new Date(rowData.vat_value_end_date)).format('DD/MM/YYYY')),
                                            sizeToContent: true,
                                            important: true,
                                            alignment: 'center'
                                        }
                                    ]}
                                    rows={type.vat_values}
                                />
                            )) || (
                                <Alert severity="info">
                                    No VAT Rates to display
                                </Alert>
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            })
        );
    }
}

export default VATRateList;