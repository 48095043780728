import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
// import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';

import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = () => ({
    access: {
        processIn: false,
        processOut: false,
        searchAwaitingDelivery: false,
        searchDelivered: false,
        searchAwaitingStock: false,
        searchAwaitingPicking: false,
        searchAwaitingCheck: false,
        searchAwaitingCollection: false,
        searchAwaitingDespatch: false,
        searchReadyForCollection: false,
        searchOnHold: false,
        searchDespatched: false,
        stockAdjustment: false,
        consignmentStockEdit: false
    },
    isLoading: true,
    menu: null,
    picks: 0
})

const Dashboard = ({pageTitle}) => {

    const   [state,setState]    = useState(initialState()),
            badges              = useSelector(state => state.notifications.badges),
            history             = useHistory(),
            {isLoading, menu}   = state;

    const handleSelectMenu = menu => {
        
        let title = [1, "WMS"];

        // if(menu === "in") {
        //     title.push('Goods In');
        // } else if(menu === "stock") {
        if(menu === "stock") {
            title.push('Stock Management');
        } else {
            title.push('Main Menu')
        }

        pageTitle(title);

        setState(state => ({
            ...state,
            menu
        }))

    }

    const handleRouteLink = route => {
        history.push(route)
    }

    useEffect(() => {
        API.multiAccess([
            "wh-process-grp",
            "wh-processing",
            "wh-awaiting-delivery",
            "wh-delivered",
            "wh-awaiting-stock",
            "wh-awaiting-picking",
            "wh-awaiting-collection",
            "wh-awaiting-check",
            "wh-awaiting-despatch",
            'wh-ready-for-collection',
            "wh-on-hold",
            "wh-despatched",
            "stock-adjustment",
            "consignment-stock-edit",
        ])
        .then(([
            processIn,
            processOut,
            searchAwaitingDelivery,
            searchDelivered,
            searchAwaitingStock,
            searchAwaitingPicking,
            searchAwaitingCollection,
            searchAwaitingCheck,
            searchAwaitingDespatch,
            searchReadyForCollection,
            searchOnHold,
            searchDespatched,
            stockAdjustment,
            consignmentStockEdit
        ]) => {

            let isLoading = false;
            
            pageTitle([1, "WMS", "Main Menu"]);

            setState(state => ({
                access: {
                    ...state.access,
                    processIn,
                    processOut,
                    searchAwaitingDelivery,
                    searchDelivered,
                    searchAwaitingStock,
                    searchAwaitingPicking,
                    searchAwaitingCheck,
                    searchAwaitingCollection,
                    searchAwaitingDespatch,
                    searchReadyForCollection,
                    searchOnHold,
                    searchDespatched,
                    stockAdjustment,
                    consignmentStockEdit
                },
                isLoading
            }))

        });
    }, [pageTitle]);

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            // (isMobile && (
                <Box>
                    <Grid container spacing={3}>
                        {(!menu && (
                            <>
                                <Grid item xs={12}>
                                    <AppButton
                                        onClick={() => handleSelectMenu(`goodsIn`)}
                                        primary
                                        icon="box-check"
                                        text="Goods In"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.searchAwaitingPicking ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/outgoing/awaiting-picking`)}
                                        primary
                                        icon="inventory"
                                        text="Pick Order"
                                        badge={badges?.['wh-awaiting-picking']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.searchAwaitingCheck ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/outgoing/awaiting-check`)}
                                        primary
                                        icon="check-double"
                                        text="Check Order"
                                        badge={badges?.['wh-awaiting-check']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.searchAwaitingDespatch ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/outgoing/awaiting-despatch`)}
                                        primary
                                        icon="truck-loading"
                                        text="Despatch Order"
                                        badge={badges?.['wh-awaiting-despatch']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.searchOnHold ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/outgoing/on-hold`)}
                                        primary
                                        icon="ban"
                                        text="Orders On Hold"
                                        badge={badges?.['wh-on-hold']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.searchReadyForCollection ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/outgoing/ready-for-collection`)}
                                        primary
                                        icon="user-check"
                                        text="Customer Collection"
                                        badge={badges?.['wh-ready-for-collection']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        onClick={() => handleSelectMenu(`stock`)}
                                        primary
                                        icon="boxes"
                                        text="Stock Management"
                                    />
                                </Grid>
                            </>
                        )) || (menu === 'stock' && (
                            <>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.consignmentStockEdit ?? false}
                                        onClick={() => handleRouteLink('/warehouse/stock/consignment')}
                                        primary
                                        icon="edit"
                                        text="Consignment Stock Edit"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.stockAdjustment ?? false}
                                        onClick={() => handleRouteLink('/warehouse/stock/adjustment')}
                                        primary
                                        icon="edit"
                                        text="Stock Adjustment"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        back
                                        onClick={() => handleSelectMenu(null)}
                                        text="Main Menu"
                                    />
                                </Grid>
                            </>
                        )) || (menu === 'goodsIn' && (
                            <>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.searchAwaitingDelivery ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/incoming/pod`)}
                                        primary
                                        icon="truck"
                                        text="Purchase Orders"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.searchAwaitingDelivery ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/incoming/rma`)}
                                        primary
                                        icon="user"
                                        text="Customer Returns"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        back
                                        onClick={() => handleSelectMenu(null)}
                                        text="Main Menu"
                                    />
                                </Grid>
                            </>
                        ))}
                        {/* {(!menu && (
                            <>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.processIn ?? false}
                                        onClick={() => handleSelectMenu('in')}
                                        text="Goods Received"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.processOut ?? false}
                                        onClick={() => handleSelectMenu('out')}
                                        text="Goods Out"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={false}
                                        onClick={() => handleSelectMenu('stock')}
                                        text="Stock Management"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}
                                        onClick={() => handleRouteLink('/customers')}
                                        text="Customers"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}
                                        onClick={() => handleRouteLink('/products')}
                                        text="Products"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}
                                        onClick={() => handleRouteLink('/purchase-orders')}
                                        text="Purchase Orders"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}
                                        onClick={() => handleRouteLink('/sales')}
                                        text="Sales Orders"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}
                                        onClick={() => handleRouteLink('/suppliers')}
                                        text="Suppliers"
                                    />
                                </Grid>
                            </>
                        )) || (menu === 'in' && (
                            <>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}//{!state.access?.processIn ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/incoming/goods-received`)}
                                        primary
                                        text="Book Delivery In"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}//{!state.access?.processOut ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/incoming/awaiting-delivery`)}
                                        text="Expected Deliveries"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}//{!state.access?.processOut ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/incoming/delivered`)}
                                        text="Received Deliveries"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        back
                                        onClick={() => handleSelectMenu(null)}
                                        text="Main Menu"
                                    />
                                </Grid>
                            </>
                        )) || (menu === 'out' && (
                            <>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.processIn ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/outgoing/awaiting-picking`)}
                                        primary
                                        text="Pick Order"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={!state.access?.processIn ?? false}
                                        onClick={() => handleRouteLink(`/warehouse/outgoing/awaiting-despatch`)}
                                        primary
                                        text="Despatch Order"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        back
                                        onClick={() => handleSelectMenu(null)}
                                        text="Main Menu"
                                    />
                                </Grid>
                            </>
                        )) || (menu === 'stock' && (
                            <>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}
                                        onClick={() => handleRouteLink('/tbc')}
                                        text="New Stock Adjustment"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={true}
                                        onClick={() => handleRouteLink('/tbc')}
                                        text="New Stock Count"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        back
                                        onClick={() => handleSelectMenu(null)}
                                        text="Main Menu"
                                    />
                                </Grid>
                            </>
                        ))} */}
                    </Grid>
                </Box>
            // )) || (
            //     <React.Fragment />
            // )
        )
    )
}

export default Dashboard;