import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const JoinOrderLine = ({closeDialog, confirmation, deployConfirmation, formData, idx, product, submit}) => {
    const   [join, setJoin]     = useState([]);
    const   [lines, setLines]   = useState([]);
    const   [newQty, setNewQty] = useState(0);

    const handleCheckedChange = idxx => {
        let newJoin = [...join];
        if(newJoin.includes(idxx)) {
            newJoin.splice(idxx, 1);
        } else {
            newJoin.push(idxx);
        }
        setJoin(newJoin);
    }

    const handleSubmit = () => {

        let newLines        = [...formData.lines],
            productLines    = [],
            qtyToJoin       = product.quantity ?? product.podp_qty;

        lines.forEach(line => {
            if(join.includes(line.rowIdx)) {
                qtyToJoin = (qtyToJoin + (line.quantity ?? line.podp_qty));
            } else {
                productLines.push(line);
            }
        })

        newLines = _.filter(newLines, el => el?.ls === "Invoiced" || el?.ls === "Despatched" || el.productId !== product.productId || el.cost !== product.cost);
        
        newLines.push({
            ...product,
            quantity: qtyToJoin,
            quantityDespatch: qtyToJoin,
            totalCost: (parseFloat(product.cost) * parseInt(qtyToJoin)).toFixed(2),
            vat: (parseFloat(product.vat / product.quantity) * parseInt(qtyToJoin)).toFixed(2)
        });

        _.each(productLines, line => {
            newLines.push(line)
        })

        newLines = _.sortBy(newLines, el => el.delivery);

        submit(newLines);
    }

    useEffect(() => {
        let newLines    =   [],
            preJoin     =   [],
            formLines   =   _.filter(formData.lines, (el, idxxx) => el.productId === product.productId && idxxx !== idx && el.cost === product.cost && el?.ls !== "Invoiced" && el?.ls !== "Despatched");
        _.each(formLines, (el, idxx) => {
            newLines.push({
                ...el,
                rowIdx: undefined,
                rowNumber: undefined,
                rowNumberReversed: undefined
            })
            if(el.delivery === product.delivery) {
                preJoin.push(idxx);
            }
        })
        setLines(newLines);
        setJoin(preJoin); //eslint-disable-next-line
    }, []);

    useEffect(() => {
        let qty = product.quantity ?? product.podp_qty;
        
        lines.forEach((line, idxx) => {
            if(join.includes(idxx)) {
                qty = (qty + (line.quantity ?? line.podp_qty));
            }
        })

        setNewQty(qty);

    }, [join, lines, product]);

    return (
        (product && (
            <Grid container spacing={2}>
                <Grid item xs={12} align='center'>
                    <Box pt={4}>
                        <FAIcon icon="compress-arrows-alt" type="thin" size={50} noMargin />
                    </Box>
                </Grid>
                <Grid item xs={12} align='center'>
                    <Typography variant="h6">
                        Merge Order Lines
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box ml={4} mr={4} mt={2} mb={3}>
                        <Box className="content-light-grey" p={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h6">
                                        {product.productCode ?? product.podp_product_code}
                                    </Typography>
                                    <Typography variant="body2">
                                        {product.productName ?? product.podp_product_name}
                                    </Typography>
                                    <Typography variant="body2">
                                        Delivery {product.delivery} of {_.size(formData.deliveries)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h6">
                                        {newQty}
                                    </Typography>
                                    <Typography variant="body2">
                                        New Quantity
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <DataTable  
                                        config={{
                                            alternatingRowColours: true,
                                            responsiveImportance: true,
                                            inline: true
                                        }}
                                        columns={[ 
                                            {
                                                heading: 'Delivery',
                                                field: rowData => `Delivery ${rowData.delivery} of ${_.size(formData.deliveries)}`,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Quantity',
                                                field: rowData => rowData.quantity ?? rowData.podp_qty,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Merge',
                                                field: rowData => <Checkbox color="primary" checked={join.includes(rowData.rowIdx)} onChange={() => handleCheckedChange(rowData.rowIdx)} />,
                                                aligment: 'center',
                                                sizeToContent: true,
                                            },
                                        ]}
                                        rows={lines}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} align="right">
                    <Grid container spacing={2}>
                        <Grid item xs />
                        <Grid item>
                            <Button onClick={closeDialog}
                                    variant="text"
                            >
                                <FAIcon icon="times" size={15} button />
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                onClick={() => deployConfirmation("Are you sure you want to merge these order lines?", () => handleSubmit())}
                                variant="text" 
                                color="primary" 
                                disabled={_.isEmpty(join)}
                            >
                                <FAIcon icon="check" size={15} button />
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )) || (
            <React.Fragment />
        )
    )
}

export default JoinOrderLine;