import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleChange, handleNumberChange, handleConfirmationOpen, handleConfirmationClose, handleConfirmationSuccess } from 'Functions/FormFunctions';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = () => ({
    confirmation: {
        submit: false
    },
    formData: {
        id: '',
        courier: '',
        service: '',
        cost: '',
        notes: ''
    },
    formErrors: {},
    initialFormData: {
        id: '',
        courier: '',
        service: '',
        cost: '',
        notes: ''
    },
    isLoading: true,
})

class CarriageForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
        this.handleNumberChange = handleNumberChange.bind(this)
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentWillUnmount = () => {
        if(window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
    }
    
    componentDidUpdate = () => {
        if(JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if(!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if(window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    }

    loadComponentData = () => {
        if(this.props.updateId) {
            API.get(`/suppliers/carriage/${this.props.updateId}`)
            .then(result => {
                if(result?.data) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            courier: result.data.supp_carr_courier,
                            service: result.data.supp_carr_service,
                            cost: result.data.supp_carr_cost,
                            notes: result.data.supp_carr_notes
                        },
                        initialFormData: {
                            ...this.state.formData,
                            courier: result.data.supp_carr_courier,
                            service: result.data.supp_carr_service,
                            cost: result.data.supp_carr_cost,
                            notes: result.data.supp_carr_notes
                        },
                        isLoading: false,
                    })
                }
            })
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    id: this.props.id,
                },
                initialFormData: {
                    ...this.state.formData,
                    id: this.props.id,
                },
                isLoading: false,
            })
        }
    }
   
    handleCheckedChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target?.name]: e.target?.checked ?? 'false'
            }
        })
    }

    cancel = () => {
        this.setState({
            formData: {
                ...initialState().formData,
                id: this.props.id,
            }
        }, () => {
            if(this.props.cancel) {
                this.props.cancel();
            }
        })
    }
    
    submit = () => {
        if(this.props.updateId) {
            API.put(`/suppliers/carriage/${this.props.updateId}`, this.state.formData)
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully updated the supplier delivery method");
                            if(this.props.callback) 
                                this.props.callback();
                        })
                    }
                }
            })
        } else {
            API.post(`/suppliers/carriage`, this.state.formData)
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully added a new supplier delivery method");
                            if(this.props.callback) 
                                this.props.callback();
                        })
                    }
                }
            })
        }
    }

    render() {
        const { updateId } = this.props;
        const { formErrors, formData, initialFormData, isLoading } = this.state;
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <form noValidate autoComplete="off">
                    <Prompt
                        when={JSON.stringify(formData) !== JSON.stringify(initialFormData)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                    <Grid container spacing={0}>
                        <TextField
                            name="courier"
                            label="Courier *"
                            value={formData.courier}
                            error={formErrors && formErrors['courier'] && true}
                            helperText={formErrors && formErrors['courier']}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <TextField
                            name="service"
                            label="Service *"
                            value={formData.service}
                            error={formErrors && formErrors['service'] && true}
                            helperText={formErrors && formErrors['service']}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <TextField
                            name="cost"
                            label="Cost *"
                            value={formData.cost}
                            error={formErrors && formErrors['cost'] && true}
                            helperText={formErrors && formErrors['cost']}
                            onChange={this.handleNumberChange}
                            onBlur={this.handleFloat}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>
                            }}
                            fullWidth
                        />
                        <Textarea
                            label="Default Purchase Order Notes"
                            name="notes"
                            margin="normal"
                            value={formData.notes}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} align="right" className="pt-2">
                        <Button onClick={this.cancel} variant="text" color="primary">
                            <FAIcon icon="times" size={15} button />
                            {updateId ? 'Cancel' : 'Discard'}
                        </Button>
                        <Button onClick={() => this.handleConfirmationOpen('submit')} variant="text" color="primary" disabled={_.isEmpty(formData.courier) || _.isEmpty(formData.service) || _.isEmpty(formData.cost) || formData.cost < 0}>
                            <FAIcon icon="check" size={15} button />
                            {updateId ? 'Update Delivery Method' : 'Add Delivery Method'}
                        </Button>
                    </Grid>     
                    <ConfirmationDialog 
                        open={this.state.confirmation.submit} 
                        success={() => this.handleConfirmationSuccess('submit')} 
                        close={() => this.handleConfirmationClose('submit')} 
                        message={`Are you sure you want to ${updateId ? 'update' : 'add'} this delivery method?`}
                    />
                </form>
            )
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(CarriageForm);