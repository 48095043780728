import React  from 'react';

import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handleConfirmationClose, handleConfirmationOpen, handleConfirmationSuccess } from 'Functions/FormFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    dialogPaper: {
        backgroundColor: '#fafafa'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: '#fff'
    },
    container: {
        marginTop: 88,
    },
})

const initialState = {
    confirmation: {
        submit: false
    }
}

class FullScreenDialog extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = initialState
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    submit = () => {
        this.props.toggleDialog()
    }

    render() {
        const { confirmation } = this.state;
        return (
            <>
                <Dialog
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    fullScreen
                    open={this.props.open}
                    PaperProps={{
                        className: this.props.classes.dialogPaper
                    }}
                    TransitionComponent={Transition}
                >
                    <AppBar>
                        <Toolbar>
                            <Tooltip title="Cancel">
                                <IconButton edge="start" color="inherit" onClick={() => this.handleConfirmationOpen('submit')}>
                                    <FAIcon type="light" icon='times' buttonPrimary noMargin />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" className={this.props.classes.title}>
                                {this.props.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="xl" className={this.props.classes.container}>
                        {this.props.content}
                    </Container>
                </Dialog>
                <ConfirmationDialog 
                    open={confirmation.submit} 
                    success={() => this.handleConfirmationSuccess('submit')} 
                    close={() => this.handleConfirmationClose('submit')} 
                    message={`Are you sure you want to leave this form?`}
                />
            </>
        )
    }
}

export default withStyles(styles)(FullScreenDialog)