import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const FilesRequiringAction = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/filesRequireAction',
                columns: [
                    {
                        heading: 'File Name',
                        field: rowData => rowData.fn,
                        dataRef: 'fn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Category',
                        field: rowData => rowData.ca,
                        dataRef: 'ca',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Related To',
                        field: rowData => rowData.re,
                        dataRef: 're',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Uploaded',
                        field: rowData => rowData.co,
                        fieldFormat: 'datetime',
                        dataRef: 'co',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Uploaded By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.cb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'cb',
                        sizeToContent: true
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'View', icon: 'binoculars', link: rowData.li ?? undefined}
                            ]
                        }
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'files-requring-action',
                noResultsText: 'There are no files that require action',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Misc'],
                pdf: true,
                persistenceId: null,
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Files Requiring Action Report'
            }}
        />
    )
}

export default FilesRequiringAction;