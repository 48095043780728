import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { Box, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { CLOUDFRONT_URL } from 'Constants';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Alert from 'Components/Common/Alert/Alert';

const InvoiceDelivery = ({callback, carriage, order, od, delivery, despatchedOn, despatchDue, net}) => {

    const [state, setState] = useState(null);
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const interval = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    
    const submit = () => {
        API.put(`/sales/${order}/invoice/${od}`, { date })
        .then(res => {
            if(res?.data?.success) {
                interval.current = setInterval(poll, 500);
                setState(res.data);
            } else if(res?.data?.errors) {
                callback?.();
                dispatch(deploySnackBar('error', res.data.errors[0].msg));
                dispatch(closeDialog());
            }
        })
    }

    const moveToInvoiced = () => {
        API.put(`/sales/${order}/status/${od}`, { status: 'Invoiced' })
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar('success', `The status of delivery #${delivery} has been successfully changed to Invoiced`));
                callback?.();
                dispatch(closeDialog());
            }
        })
    }

    const poll = () => {
        API.get(`/sales/${order}/invoice/${od}`)
        .then(res => {
            if(res?.data?.success) {
                if(res?.data?.complete === 1) {
                    clearInterval(interval.current);
                    callback?.();
                }
                setState(res.data);
            }
        })
    }

    useEffect(() => {
        return () => {
            if(interval.current) 
                clearInterval(interval.current);
        }
    }, [])
    
    return (
        <Box className="content-light-grey">
            <DialogTitle disableTypography style={{paddingBottom: 0}}>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs>
                        <Typography variant="h6">
                            Sales Order Invoicing
                        </Typography>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Typography variant="body2" className="fw-400">
                                    Delivery #{delivery}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item align='right'>
                        <IconButton aria-label="Close" onClick={() => dispatch(closeDialog())}>
                            <FAIcon type="light" icon='times' button noMargin />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Box p={3}>
                <Box className="content-light-white" p={3}>
                    {(state?.complete === 1 && (
                        <Grid container spacing={1}>
                            <Grid item xs={12} align='center'>
                                <FAIcon icon="check" size={55} noMargin className="textSuccess" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" className="fw-400" align='center'>
                                    Invoice Generated
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="fw-400" align='center'>
                                    Sage Invoice #{state?.invoice}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <AppButton
                                                className="btn btn-success"
                                                onClick={() => { history.push(`/sales/${order}:${od}`); dispatch(closeDialog()); }}
                                                text="View Delivery"
                                                icon="binoculars"
                                                light
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <AppButton
                                                className="btn btn-success"
                                                onClick={() => window.open(`${CLOUDFRONT_URL}${state?.pdf}`)}
                                                text="View PDF"
                                                icon="file-pdf"
                                                light
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    )) || (state && (
                        <Grid container>
                            <Grid item xs={12}>
                                <LoadingCircle size={55} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" className="fw-400" align='center'>
                                    {state?.status ?? `Requesting Invoice`}
                                </Typography>
                            </Grid>
                        </Grid>
                    )) || (parseFloat(net).toFixed(2) === "0.00" && (
                        <Grid container spacing={1}>
                            <Grid item xs={12} align='center'>
                                <FAIcon icon="exclamation-triangle" size={55} noMargin className="textError" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" className="fw-400" align='center'>
                                    Net Items - Zero Value
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="fw-400" align='center'>
                                    This delivery cannot be invoiced to Sage as it has a zero net item value
                                </Typography>
                            </Grid>
                            {parseFloat(carriage).toFixed(2) !== "0.00" && (
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Alert severity="warning">
                                            There is a delivery charge of <strong>&pound;{carriage}</strong> for this delivery, please ask <em>Accounts</em> to charge this value to the customers account
                                        </Alert>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box pt={3}>
                                    <AppButton
                                        className="btn btn-warning"
                                        onClick={() => dispatch(deployConfirmation(<><Typography className="fw-400" variant="body2">Are you sure you want to move this delivery to Invoiced now?</Typography><Typography variant="body2" className="textError">An invoice will not be posted to Sage</Typography></>, moveToInvoiced))}
                                        text="Change status to Invoiced"
                                        icon="cog"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )) || (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body2" className="fw-400">
                                    Despatch Due
                                </Typography>
                                <Typography variant="body2">
                                    {despatchDue}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className="fw-400">
                                    Despatched On
                                </Typography>
                                <Typography variant="body2">
                                    {despatchedOn}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    type={'date'}
                                    id="date"
                                    name="date"
                                    label={"Select Invoice Date *"}
                                    disableFuture={true}
                                    onChange={date => setDate(moment(date).format("YYYY-MM-DD"))}
                                    minDate={moment().subtract(1, 'week').format('YYYY-MM-DD')}
                                    minDateMessage={'An invoice can only be back-dated 7 days from now'}
                                    autoOk={true}
                                    value={date}
                                />
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button 
                                    variant="text"
                                    color="primary"
                                    disabled={!date || moment(date).isBefore(moment().subtract(8, 'days'))}
                                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to invoice this delivery for ${moment(date).format("DD/MM/YYYY")}?`, submit))}
                                >
                                    <FAIcon icon="check" size={15} />
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Box>
        </Box>
    )

}

export default InvoiceDelivery;