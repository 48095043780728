import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import ProductSearchSelector from 'Components/Products/Misc/ProductSearchSelector';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleChange, handleNumberChange, handleSelectChange } from 'Functions/FormFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = () => ({
    formData: {
        productId: '',
        type: '',
        reason: '',
        qty: '',
        notes: '',
    },
    formErrors:[],
    isLoading: false,
    reasons: [
        {
            label: 'Stock Additions',
            options: [
                {
                    label: 'Consignment Delivery',
                    value: 'CONSIGNMENT DELIVERY',
                    type: 'IN'
                },
                {
                    label: 'Manual Return To Stock',
                    value: 'MANUAL RETURN TO STOCK',
                    type: 'IN'
                },
                {
                    label: 'Other Stock Addition',
                    value: 'STOCK ADDITION',
                    type: 'IN'
                },
                {
                    label: 'Stock Received',
                    value: 'STOCK RECEIVED',
                    type: 'IN'
                }
            ]
        },
        {
            label: 'Stock Write Offs',
            options: [
                {
                    label: 'Consignment Reduction',
                    value: 'CONSIGNMENT REDUCTION',
                    type: 'OUT'
                },
                {
                    label: 'Damaged In Transit',
                    value: 'DAMAGED IN TRANSIT',
                    type: 'OUT'
                },
                {
                    label: 'Kit Build',
                    value: 'KIT BUILD',
                    type: 'OUT'
                },
                {
                    label: 'Manual Shipment',
                    value: 'MANUAL SHIPMENT',
                    type: 'OUT'
                },
                {
                    label: 'Quarantined',
                    value: 'QUARANTINED',
                    type: 'OUT'
                },
                {
                    label: 'Other Stock Write Off',
                    value: 'STOCK WRITE OFF',
                    type: 'OUT'
                },
                {
                    label: 'Stock Missing',
                    value: 'STOCK MISSING',
                    type: 'OUT'
                },
            ]
        }
    ]
})

class AdjustmentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();        
        this.handleChange = handleChange.bind(this);
        this.handleNumberChange = handleNumberChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {

        const { 
            pageTitle, 
            warehouseMode 
        }  = this.props;

        if(warehouseMode) {
            pageTitle([1, "Warehouse", "Stock Adjustment"]);
        }

        if(this.props.productId) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    productId: this.props.productId
                }
            })
        }
    }

    handleAdjustmentReason = option => {
        if(option?.value) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    type: option.type,
                    reason: option.value
                }
            })
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    type: '',
                    reason: ''
                }
            })
        }
    }
    
    handleSubmit = () => {

        const {
            callback,
            closeDialog,
            deploySnackBar,
            history,
            warehouseMode
        } = this.props;

        this.setState({
            isLoading: true
        }, () => {

            API.post('/inventory/adjustment', this.state.formData)
            .then((result) => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                } else {
                    this.setState({
                        ...initialState()
                    }, () => {
                        deploySnackBar("success", "Your stock adjustment was successfully applied")
                        callback?.();
                        if(closeDialog) {
                            closeDialog();
                        } else if(warehouseMode) {
                            history.push('/dashboard')
                        }
                    })
                }
            })

        })

    }

    render() {
        const { formData, formErrors, isLoading, reasons } = this.state;
        const { closeDialog, deployConfirmation, history, warehouseMode } = this.props;
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                {!this.props.productId && (
                                    <>
                                        {warehouseMode && (
                                            <Typography variant="body1" align='center' className="fw-400">
                                                Product
                                            </Typography>
                                        )}
                                        <Box pt={3} pb={warehouseMode ? 1 : 2}>
                                            <ProductSearchSelector
                                                label={!warehouseMode ? 'Product *' : ''}
                                                handleAction={v => this.handleSelectChange('productId', v)}
                                                variant={warehouseMode ? 'filled' : undefined}
                                                minimal={!warehouseMode ? true : false}
                                                basic={warehouseMode ? true : false}
                                            />
                                        </Box>
                                    </>
                                )}
                                {warehouseMode && (
                                    <Box pt={3} pb={2.5}>
                                        <Typography variant="body1" align='center' className="fw-400">
                                            Reason
                                        </Typography>
                                    </Box>
                                )}
                                <AutoCompleteSelect 
                                    options={reasons}
                                    label={!warehouseMode ? 'Reason *' : undefined}
                                    onChange={this.handleAdjustmentReason}
                                    error={formErrors && formErrors['reason'] && true}
                                    errorText={formErrors && formErrors['reason']}
                                    value={formData.reason}
                                    variant={warehouseMode ? 'filled' : undefined}
                                    isGrouped
                                    isSearchable={warehouseMode ? false : true}
                                />
                                {warehouseMode && (
                                    <Box pt={3} pb={2.5}>
                                        <Typography variant="body1" align='center' className="fw-400">
                                            Quantity
                                        </Typography>
                                    </Box>
                                )}
                                <TextField
                                    name="qty"
                                    label={!warehouseMode ? 'Quantity *' : undefined}
                                    value={formData.qty}
                                    error={formErrors && formErrors['qty'] && true}
                                    helperText={formErrors && formErrors['qty']}
                                    onChange={this.handleNumberChange}
                                    margin="none"
                                    type={warehouseMode ? undefined : 'number'}
                                    inputProps={{
                                        step: 0,
                                        min: 1,
                                        inputMode: warehouseMode ? 'none' : undefined
                                    }}
                                    fullWidth
                                    variant={warehouseMode ? 'outlined' : undefined}
                                    className={warehouseMode ? 'bg-light' : undefined}
                                />
                                {warehouseMode && (
                                    <Box pt={3} pb={2.5}>
                                        <Typography variant="body1" align='center' className="fw-400">
                                            Notes
                                        </Typography>
                                    </Box>
                                )}
                                <Box pt={1}>
                                    <Textarea
                                        name="notes"
                                        label={!warehouseMode ? 'Notes' : undefined}
                                        value={formData.notes}
                                        error={formErrors && formErrors['notes'] && true}
                                        helperText={formErrors && formErrors['notes']}
                                        onChange={this.handleChange}
                                        margin="none"
                                        variant="outlined"
                                        className={warehouseMode ? 'bg-light' : undefined}
                                        rows={2}
                                    />
                                </Box>
                            </form>           
                        </Grid>
                    </Grid>
                    {(!warehouseMode && (
                        <div className="buttonRow">
                            <Button 
                                onClick={() => closeDialog()} 
                                variant="text"
                            >
                                <FAIcon icon="times" size={15} button />
                                Discard
                            </Button>
                            <Button 
                                color="primary"
                                onClick={() => deployConfirmation(`Are you sure you want to apply this stock adjustment?`, this.handleSubmit)} 
                                variant="text" 
                            >
                                <FAIcon icon="check" size={15} button />
                                Adjust Stock
                            </Button>
                        </div>
                    )) || (
                        <Box pt={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <AppButton
                                        disabled={_.some([formData.productId,formData.type,formData.qty,formData.reason], el => !el || el === "")}
                                        className="btn btn-success"
                                        icon="check"
                                        onClick={() => deployConfirmation(`Are you sure you want to apply this stock adjustment?`, this.handleSubmit)} 
                                        primary
                                        text="Apply"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppButton
                                        back
                                        className="btn btn-error"
                                        onClick={() => history.push('/dashboard')}
                                        text="Cancel"
                                        icon="times"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </>
            )
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, submit) => dispatch(deployConfirmation(message, submit)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(AdjustmentForm);