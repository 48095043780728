import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Tiles/Tile';
import TaskList from 'Components/Common/Tasks/TaskList';
import { currencyFormat } from 'Functions/MiscFunctions';

import Bulletin from 'Components/Dashboard/Common/Bulletin';
import Calendar from 'Components/Dashboard/Common/Calendar';
import SearchQuotes from 'Components/Quotes/SearchQuotes/SearchQuotes';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const MySalesDashboard = ({pageTitle}) => {

    const [data, setData] = useState(null);

    const loggedInStaff = useSelector(state => state.staffAuth?.staff?.id ?? 0);

    const loadComponentData = () => {
        API.get(`/dashboard`, {
            params: {
                d: 'mySales'
            },
            props: {
                cancellation: true
            }
        })
        .then(res => {
            if(res?.data) {

                let newData = res.data,
                    labels = [],
                    totalQuotes = [],
                    totalNetQuotes = [],
                    totalOrders = [],
                    totalNetOrders = [];

                if(newData.chart) {

                    for (let i = 0, j = 11; i <= j; i++) {
                        totalQuotes.push(res.data.chart?.[i]?.quotes?.t);
                        totalNetQuotes.push(res.data.chart?.[i]?.quotes?.v);
                        totalOrders.push(res.data.chart?.[i]?.orders?.t);
                        totalNetOrders.push(res.data.chart?.[i]?.orders?.v);
                        labels.push(res.data.chart?.[i]?.label);
                    }
                    newData.chart = {
                        labels,
                        datasets: [
                            // {
                            //     label: 'Quotes',
                            //     data: totalQuotes,
                            //     fill: false,
                            //     backgroundColor: '#FF9800',
                            //     borderColor: '#FFE0B2',
                            //     lineTension: 0.25,
                            // },
                            // {
                            //     label: 'Orders',
                            //     data: totalOrders,
                            //     fill: false,
                            //     backgroundColor: '#2196F3',
                            //     borderColor: '#B3E5FC',
                            //     lineTension: 0.25,
                            // },
                            {
                                label: 'Net Quote Total',
                                data: totalNetQuotes,
                                fill: false,
                                backgroundColor: '#F44336',
                                borderColor: '#FFCDD2',
                                lineTension: 0.25,
                            },
                            {
                                label: 'Net Order Total',
                                data: totalNetOrders,
                                fill: false,
                                backgroundColor: '#4CAF50',
                                borderColor: '#C8E6C9',
                                lineTension: 0.25,
                            },
                        ]
                    }
    
                }

                setData(newData);
            }
        });
    }

    useEffect(() => {
        pageTitle?.([0, 'Dashboard', 'My Sales']);
        loadComponentData();
    }, [pageTitle]);
    
    return (
        (!data && (
            <LoadingCircle />
        )) || (
            (data?.notAv && (
                <Grid container spacing={3}>
                    <Grid item xs={12} align="center">
                        <Box pt={10} pb={1}>
                            <FAIcon type="duo" icon="cogs" size={75} />
                        </Box>
                        <Typography variant="h4" className="fw-500" gutterBottom>
                            Your sales dashboard will be ready shortly
                        </Typography>
                        <Typography variant="h6" className="fw-300">
                            Please check back in a few minutes...
                        </Typography>
                    </Grid>
                </Grid>
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12} xl={7}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant="h6" paragraph>
                                            Weekly Sales
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon='pound-sign'
                                                    title="Target"
                                                    content={currencyFormat.format(data?.stats?.ta?.w)}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tooltip 
                                                    title={(
                                                        <>
                                                            <Typography variant="body1" className="fw-400">
                                                                Breakdown
                                                            </Typography>
                                                            <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                            <Typography variant="body2">
                                                                <strong>Not Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.w?.ni)}
                                                            </Typography>
                                                            <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                            <Typography variant="body2">
                                                                <strong>Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.w?.to)}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                <strong>Credits: </strong> {currencyFormat.format(data?.stats?.sa?.w?.cr)}
                                                            </Typography>
                                                            <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                            <Typography variant="body2">
                                                                <strong>Total: </strong> {currencyFormat.format(data?.stats?.sa?.w?.gr)}
                                                            </Typography>
                                                        </>
                                                    )}
                                                >
                                                    <span>
                                                        <Tile
                                                            noPaper
                                                            icon='pound-sign'
                                                            title="Sales"
                                                            content={currencyFormat.format(data?.stats?.sa?.w?.gr)}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon={data?.stats?.ta?.w - data?.stats?.sa?.w?.gr < 0 ? 'check-circle' : 'exclamation-circle'}
                                                    color={data?.stats?.ta?.w - data?.stats?.sa?.w?.gr < 0 ? '#4CAF50' : '#F44336'}
                                                    title="Sales To Target"
                                                    content={currencyFormat.format((data?.stats?.ta?.w - data?.stats?.sa?.w?.gr).toFixed(2))}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon='calendar-day'
                                                    title="Days Left"
                                                    content={data?.stats?.wd?.w}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant="h6" paragraph>
                                            Monthly Sales
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon='pound-sign'
                                                    title="Target"
                                                    content={currencyFormat.format(data?.stats?.ta?.m)}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tooltip 
                                                    title={(
                                                        <>
                                                            <Typography variant="body1" className="fw-400">
                                                                Breakdown
                                                            </Typography>
                                                            <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                            <Typography variant="body2">
                                                                <strong>Not Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.m?.ni)}
                                                            </Typography>
                                                            <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                            <Typography variant="body2">
                                                                <strong>Invoiced: </strong> {currencyFormat.format(data?.stats?.sa?.m?.to)}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                <strong>Credits: </strong> {currencyFormat.format(data?.stats?.sa?.m?.cr)}
                                                            </Typography>
                                                            <Divider style={{backgroundColor: '#fff', marginTop: 4, marginBottom: 4}} />
                                                            <Typography variant="body2">
                                                                <strong>Total: </strong> {currencyFormat.format(data?.stats?.sa?.m?.gr)}
                                                            </Typography>
                                                        </>
                                                    )}
                                                >
                                                    <span>
                                                        <Tile
                                                            noPaper
                                                            icon='pound-sign'
                                                            title="Sales"
                                                            content={currencyFormat.format(data?.stats?.sa?.m?.gr)}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon={data?.stats?.ta?.m - data?.stats?.sa?.m?.gr < 0 ? 'check-circle' : 'exclamation-circle'}
                                                    color={data?.stats?.ta?.m - data?.stats?.sa?.m?.gr < 0 ? '#4CAF50' : '#F44336'}
                                                    title="Sales To Target"
                                                    content={currencyFormat.format((data?.stats?.ta?.m - data?.stats?.sa?.m?.gr).toFixed(2))}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon='calendar-day'
                                                    title="Days Left"
                                                    content={data?.stats?.wd?.m}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant="h6" paragraph>
                                            My Quotes
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon='check'
                                                    title="This Month"
                                                    content={data?.quotes?.thisMonth}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon='history'
                                                    title="Last Month"
                                                    content={data?.quotes?.lastMonth}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box ml={3} mr={3}>
                                        <Divider />
                                    </Box>
                                    <Box p={3}>
                                        <Typography variant="h5">
                                            This Week
                                        </Typography>
                                        <DataTable
                                            config={{
                                                noResultsText: "No data to display",
                                                showFullTotals: true,
                                                noRowHover: true
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Day',
                                                    field: rowData => rowData.h,
                                                    sizeToContent: true,
                                                    showTotalLabel: true
                                                },
                                                {
                                                    heading: 'Quotes',
                                                    field: rowData => rowData.c,
                                                    sizeToContent: true,
                                                    showTotal: true
                                                },
                                                {
                                                    heading: 'Net Total',
                                                    field: rowData => rowData.v,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true,
                                                    showTotal: true
                                                }
                                            ]}
                                            rows={data?.quotes?.thisWeek ?? []} 
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant="h6" paragraph>
                                            My Orders
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon='check'
                                                    title="This Month"
                                                    content={data?.orders?.thisMonth}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={12} xl={6}>
                                                <Tile
                                                    noPaper
                                                    icon='history'
                                                    title="Last Month"
                                                    content={data?.orders?.lastMonth}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box ml={3} mr={3}>
                                        <Divider />
                                    </Box>
                                    <Box p={3}>
                                        <Typography variant="h5">
                                            This Week
                                        </Typography>
                                        <DataTable
                                            config={{
                                                noResultsText: "No data to display",
                                                showFullTotals: true,
                                                noRowHover: true
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Day',
                                                    field: rowData => rowData.h,
                                                    sizeToContent: true,
                                                    showTotalLabel: true
                                                },
                                                {
                                                    heading: 'Orders',
                                                    field: rowData => rowData.c,
                                                    sizeToContent: true,
                                                    showTotal: true
                                                },
                                                {
                                                    heading: 'Net Total',
                                                    field: rowData => rowData.v,
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true,
                                                    showTotal: true
                                                }
                                            ]}
                                            rows={data?.orders?.thisWeek ?? []} 
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant="h6" paragraph>
                                            My Hot Quotes
                                        </Typography>
                                        <SearchQuotes dashboard hot staffId={loggedInStaff} compact noPersistence inline />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant="h6" paragraph>
                                            My Open Quotes
                                        </Typography>
                                        <SearchQuotes dashboard myQuotes showOpenOnly compact noPersistence inline />
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} xl={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant="h6" paragraph>
                                            My Sales Performance
                                        </Typography>
                                        <Box height={400} width={'100%'} maxWidth={'100%'} minHeight={300}>
                                            <Line 
                                                data={data.chart} 
                                                options={{
                                                    legend: {
                                                        display: true,
                                                        position: "bottom"
                                                    },
                                                    maintainAspectRatio: false,
                                                    scales: {
                                                        xAxes: [{
                                                            gridLines: {
                                                                color: "rgba(0,0,0,.03)",
                                                            }
                                                        }],
                                                        yAxes: [{
                                                            gridLines: {
                                                                color: "rgba(0,0,0,.03)",
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                min: 0
                                                            }
                                                        }]
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant="h6" paragraph>
                                            Actions Due Today
                                        </Typography>
                                        <TaskList />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Bulletin />
                            </Grid>
                            <Grid item xs={12}>
                                <Calendar />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        )
    )
}

export default MySalesDashboard;