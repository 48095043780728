import React from 'react';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { useSelector } from 'react-redux';

const PickDiscrepancyReport = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/pickDiscrepancy',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Date / Time',
                        field: rowData => rowData.ca,
                        fieldFormat: 'datetime',
                        dataRef: 'ca',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Sales Order',
                        field: rowData => rowData.rf,
                        dataRef: 'rf',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.pd,
                        dataRef: 'pd',
                        important: true,
                    },
                    {
                        heading: 'Expected',
                        field: rowData => rowData.qe,
                        alignment: 'right',
                        dataRef: 'qe',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Picked',
                        field: rowData => rowData.qp,
                        alignment: 'right',
                        dataRef: 'qp',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Adjusted',
                        field: rowData => rowData.qf,
                        alignment: 'right',
                        dataRef: 'qf',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Despatch',
                        field: rowData => rowData.qt,
                        alignment: 'right',
                        dataRef: 'qt',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Resolved',
                        field: rowData => rowData.sc,
                        alignment: 'center',
                        fieldFormat: 'boolean',
                        dataRef: 'sc',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Picked By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.pb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'pb',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Checked By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.cb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'cb',
                        important: true,
                        sizeToContent: true,
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'pick-discrepancy-report',
                noResultsText: 'No pick discrepancies found',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Stock'],
                pdf: true,
                persistenceId: 'pickDiscrepancyReport',
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: false,
                showCustomer: false,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showStockStatus: false,
                showStockType: false,
                showSupplier: false,
                showTotals: false,
                title: 'Pick Discrepancy Report',
            }}
        />
    )
}

export default PickDiscrepancyReport;