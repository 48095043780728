import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import API from "API";
import Search from "Components/Common/Search/Search";

import StaffChip from "Components/Common/Chips/StaffChip";
import StatusChip from "Components/Common/Chips/StatusChip";

const initialState = () => ({
    access: {
        viewOrder: false,
    },
});

const CompletedBuilds = ({ pageTitle }) => {
    const [state, setState] = useState(initialState());

    const staff = useSelector((state) => state.notifications.status);

    useEffect(() => {
        API.multiAccess(["view-order"]).then(([viewOrder]) => {
            setState((state) => ({
                ...state,
                access: {
                    viewOrder,
                },
            }));
        });
    }, []);

    const { access } = state;

    return (
        <Search
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiRoute: "/technical/builds",
                columns: [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.rf,
                        dataRef: "rf",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.cn,
                        dataRef: "cn",
                        sizeToContent: true,
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.an,
                        dataRef: "an",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.cr,
                        dataRef: "cr",
                        sizeToContent: true,
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.nt,
                        fieldFormat: "currency",
                        dataRef: "nt",
                        sizeToContent: true,
                    },
                    {
                        heading: "Build By",
                        field: (rowData) => <StaffChip staff={staff?.[rowData.st] ?? null} />,
                        dataRef: "st",
                        fieldFormat: "staff",
                        sizeToContent: true,
                    },
                    {
                        heading: "Status",
                        field: (rowData) => <StatusChip status={rowData.os} />,
                        dataRef: "os",
                        sizeToContent: true,
                    },
                    {
                        heading: "Completed",
                        field: (rowData) => rowData.ca,
                        fieldFormat: "datetime",
                        dataRef: "ca",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => {
                            return [
                                {
                                    name: "View Delivery",
                                    icon: "binoculars",
                                    link: `/sales/${rowData.or}:${rowData.od}`,
                                    disabled: !access.viewOrder,
                                },
                            ];
                        },
                    },
                ],
                dataRef: true,
                excel: true,
                key: "id",
                name: "completed-builds",
                noResultsText: "Sorry, no completed builds were found",
                pagination: true,
                pageTitleData: [0, "Technical", "Builds", "Completed"],
                pdf: true,
                persistenceId: null,
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: "Completed Builds",
            }}
        />
    );
};

export default CompletedBuilds;
