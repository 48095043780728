import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar'; 
import Box from '@material-ui/core/Box'; 
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton  from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { CLOUDFRONT_URL } from 'Constants';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const initialState = {
    noteTeam: '',
    noteText: ''
}

const NotesForm = ({cancel, submit, formData, loggedInStaff, staff, type = 'Add'}) => {
    const [state, setState] = useState(initialState);
    const { noteText, noteTeam } = state;

    const dispatch = useDispatch();

    const teams = useSelector(state => state.notifications.teamList);

    const handleChange = e => {
        const { name, value } = e.target;
        setState(state => ({
            ...state,
            [name]: value
        }));
    }

    const handleSelectChange = (name, option) => {
        setState(state => ({
            ...state,
            [name]: option?.value ?? ''
        }));
    }

    const handleSubmit = () => {
        submit(state);
        cancel();
    }

    useEffect(() => {
        if(formData) {
            const { noteTeam, noteText, rowIdx } = formData;
            setState({
                noteTeam,
                noteText,
                rowIdx
            })
        }
    }, [formData])

    return (
        <Card>
            <Box p={1.5} pb={1}>
                <CardHeader
                    avatar={
                        <Avatar 
                            alt={staff[loggedInStaff.id].name} 
                            src={`${CLOUDFRONT_URL}${staff[loggedInStaff.id].picture}`}
                            style={{width: 35, height: 35}}
                        >
                            {staff[loggedInStaff.id].name.charAt(0)}
                        </Avatar>
                    }        
                    title={(
                        <>
                            {staff[loggedInStaff.id].name}
                            {` · `}
                            <Typography variant="caption" color={'textSecondary'}>
                                {type === 'Add' ? 'New' : 'Modify'} Internal Note
                            </Typography>
                        </>
                    )}
                />
                <CardContent className="pt-0">
                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                <Box pb={1}>
                                    <AutoCompleteSelect
                                        placeholder="FAO:"
                                        options={teams}
                                        onChange={v => handleSelectChange('noteTeam', v)}
                                        value={noteTeam}
                                        variant="filled"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Textarea
                                    placeholder='Notes:*'
                                    margin="none"
                                    name="noteText"
                                    value={noteText}
                                    onChange={handleChange}
                                    variant="outlined"
                                    rows={5}
                                /> 
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Box pt={1.5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs />
                                        <Grid item>
                                            <Button onClick={() => cancel()}
                                                    variant="text"
                                            >                                        
                                                <FAIcon icon="times" size={15} button />
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                onClick={() => dispatch(deployConfirmation(`Are you sure you want to ${type === 'Add' ? 'add' : 'modify'} this note?`, handleSubmit))}
                                                variant="text" 
                                                color="primary" 
                                                disabled={!noteText}
                                            >
                                                <FAIcon icon="check" size={15} button />
                                                {type} Note
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>  
                </CardContent>
            </Box>
        </Card>
    )
}

const Notes = ({document = 'document', existingNotes, formData, formErrors, handleNoteAdd, handleNoteEdit, handleNoteRemove, loggedInStaff, staff}) => {

    const dispatch = useDispatch();

    const teams = useSelector(state => state.notifications.teamList);

    const dispCloseDialog = () => dispatch(closeDialog())

    const addNote = () => dispatch(deployDialog(<NotesForm 
                                                    cancel={dispCloseDialog} 
                                                    submit={handleNoteAdd} 
                                                    loggedInStaff={loggedInStaff} 
                                                    staff={staff} 
                                                    teams={teams} 
                                                    type='Add' 
                                                />, true, "", "standard", "sm", false, true));
    
    const modifyNote = (rowIdx, note) => dispatch(deployDialog(<NotesForm 
                                                        cancel={dispCloseDialog} 
                                                        submit={handleNoteEdit} 
                                                        formData={{...note, rowIdx}}
                                                        loggedInStaff={loggedInStaff} 
                                                        staff={staff} 
                                                        teams={teams} 
                                                        type='Modify' 
                                                    />, true, "", "standard", "sm", false, true));

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
                <PaddedPaper>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Typography variant="h6">
                                New Internal Notes
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="New Note">
                                <Button 
                                    color="primary"
                                    variant="text"
                                    size="small"
                                    onClick={() => addNote()}
                                >
                                    <FAIcon icon="plus" size="small" button />
                                    Add Internal Note
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            {(_.isEmpty(formData.internalNotes) && (
                                <Typography variant="body2">
                                    You have not added any internal notes to this {document.toLowerCase()}
                                </Typography>
                            )) || (
                                <Grid container>
                                    {_.map(formData.internalNotes, (note, idx) => (
                                        <Grid item xs={12}>                                         
                                            <Card key={idx} elevation={0}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar alt={`${staff?.[loggedInStaff?.id ?? 0]?.name ?? ''}`} src={staff?.[loggedInStaff?.id ?? 0]?.picture ? `${CLOUDFRONT_URL}${staff?.[loggedInStaff?.id ?? 0]?.picture}` : ''}>
                                                            {staff?.[loggedInStaff?.id ?? 0]?.name ? staff?.[loggedInStaff?.id ?? 0]?.name.charAt(0) : ''}
                                                        </Avatar>
                                                    }        
                                                    title={(
                                                        <>
                                                            {`${staff?.[loggedInStaff?.id ?? 0]?.name ?? 'Unknown'} ${note.noteTeam !== '' ? `- FAO: ${_.find(teams, el => el.value === note.noteTeam)?.label} Team` : ''} · `}
                                                            <Typography variant="caption" color="textSecondary">
                                                                Draft
                                                            </Typography>
                                                        </>
                                                    )}
                                                    subheader={undefined}
                                                    action={
                                                        <Grid container>
                                                            <Grid item>
                                                                <Tooltip title="Modify Note">
                                                                    <IconButton onClick={() => modifyNote(idx, note)}>
                                                                        <FAIcon icon="pencil" size="small" noMargin button />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Remove Note">
                                                                    <IconButton onClick={() => handleNoteRemove(idx)}>
                                                                        <FAIcon icon="trash-alt" size="small" noMargin button />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                                <CardContent className="pt-0 ">
                                                    {note.noteText.split('\n').map((item, key) => {
                                                        return <Typography variant="caption" component="div" key={key} paragraph>{item}</Typography>
                                                    })}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Grid>
            {!_.isEmpty(existingNotes) && (
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Typography variant="h6">
                                    Previous Internal Notes
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    {_.map(existingNotes, (note, idx) => (
                                        <Grid item xs={12}>                                         
                                            <Card key={idx} elevation={0}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar 
                                                            alt={staff?.[note.s]?.name}
                                                            src={`${CLOUDFRONT_URL}${staff?.[note.s]?.picture}`}
                                                            style={{width: 35, height: 35}}
                                                        >
                                                            {staff?.[note.s]?.name?.charAt?.(0) ?? 'A'}
                                                        </Avatar>
                                                    }    
                                                    subheader={undefined}
                                                    title={(
                                                        <>
                                                            {`${staff?.[note.s]?.name ?? 'Unknown'} ${note.for_team ? `- FAO: ${note.for_team.team_name} Team` : ''} · `}
                                                            {note.t === 'Important' && (
                                                                <>
                                                                    <Typography variant="caption" className="textError">
                                                                        Priority Note
                                                                    </Typography>
                                                                    {` · `}
                                                                </>
                                                            )}
                                                            <Typography variant="caption" color="textSecondary">
                                                                {moment(note.c, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY HH:mm')} ({moment(note.c, "YYYY-MM-DD HH:mm:ss").fromNow()})
                                                            </Typography>
                                                        </>
                                                    )}
                                                />
                                                <CardContent className="pt-0 ">
                                                    {note.v.split('\n').map((item, key) => {
                                                        return <Typography variant="caption" component="div" key={key} paragraph>{item}</Typography>
                                                    })}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            )}
        </Grid>
    )
}

export default Notes;