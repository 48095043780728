import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Creatable from 'react-select/creatable';
import WindowedSelect from "react-windowed-select";

import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { isTablet } from 'Functions/MiscFunctions';

const components = {
    Control,
    SingleValue,
    ValueContainer
}

const styles = theme => ({
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        // flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        color: '#000'
    },
    noOptionsMessage: {
        padding: `${theme.spacing(0.5, 1)}`,
    },
    singleValue: {
        maxWidth: '97%',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
    },
    placeholder: {
        position: 'absolute',
    }
})

const groupStyles = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
}

const groupBadgeStyles = {
    backgroundColor: '#ef3340',
    borderRadius: '2em',
    color: '#fff',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
}

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>
            {_.size(data.options)}
        </span>
    </div>
)

function inputComponent({ inputRef, ...props }) {
    return <div style={{height: 'auto', padding: props.variant === "filled" ? (isTablet() ? 3 : 4.5) : (props.variant === "outlined" ? (isTablet() ? 6 : 9) : 0)}} ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            error={props.selectProps.error}
            helperText={props.selectProps.errorText}
            label={props.selectProps.label}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputMode: props.selectProps.inputMode,
                    inputRef: props.innerRef,
                    children: props.children,
                    variant: props.selectProps.variant,
                    ...props.innerProps,
                },
                startAdornment: props.selectProps.adornment ? <InputAdornment position="start"><FAIcon type="thin" className={props.selectProps.adornmentClass} icon={props.selectProps.adornment} size={props.selectProps.adornmentSize ? props.selectProps.adornmentSize : 'small'} noMargin /></InputAdornment> : ''
            }}
            variant={props.selectProps.variant}
            style={{
                margin: 0, 
            }}
            disabled={props.selectProps.isDisabled}
            InputLabelProps={{
                style: {
                    color: props.selectProps.standardLabel && '#000'
                },
                shrink: !!props.hasValue || props.isFocused || props.selectProps.adornment || props.selectProps.shrink
            }}
            {...props.selectProps.textFieldProps}
            fullWidth
        />
    );
}

function SingleValue(props) {
    return (
        <Typography component='div' className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <Typography component='div' className={props.selectProps.classes.valueContainer} style={{color: props.selectProps.isDisabled ? 'rgba(0,0,0,0.35)' : undefined}}>{props.children}</Typography>;
}

class AutoCompleteSelect extends React.Component {

    render() {
        
        const { classes, theme } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary, 
                '& input': {
                    font: 'inherit',
                },
            }),
            menuList: base => ({
              ...base,
              padding: 0,
            }),
            menu: base => ({
                ...base,
                borderRadius: 0,
                width: "max-content",
                minWidth: "100%",
            }),
            menuPortal: base => ({
                ...base,
                zIndex: 9999
            }),
            dropdownIndicator: (base, props) => ({
                ...base,
                margin: 0,
                padding: `3.3px 0 3.3px 0`,
                transform: 'scale(0.75)',
                color: (props.selectProps.error ? '#f44336' : (props.selectProps.isDisabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.54)'))
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: 'none'
            }),
            clearIndicator: (base) => ({
                ...base,
                margin: 0,
                padding: `3.3px 0 3.3px 0`,
                transform: 'scale(0.75)',
                cursor: 'pointer'
            }),
            option: (base, props) => ({
                ...base,
                backgroundColor: props.isSelected ? theme.palette.primary.main : (props.isFocused ? theme.palette.primary.opaque : '#ffffff'),  
                textOverflow: 'ellipsis',
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
            }),
            placeholder: (base, props) => ({
                color: theme.palette.secondary.main
            })
        };

        let options = this.props.options !== undefined && !_.isEmpty(this.props.options) ? this.props.options : []
        
        const group = this.props.isGrouped ? _.findIndex(this.props.options, optGroup => { return _.find(optGroup.options, option => { return option.value === this.props.value }) }) : 0;

        const cleanValue = this.props.isGrouped ? (group !== -1  ? ((((typeof this.props.value === 'string' && this.props.value.length) || (typeof this.props.value === 'number') || (typeof this.props.value === 'boolean')) ? _.find(this.props.options[group].options, option => option.value === this.props.value) : this.props.value)) : this.props.value) : (((typeof this.props.value === 'string' && this.props.value.length) || (typeof this.props.value === 'number') || (typeof this.props.value === 'boolean')) ? this.props.options.find(option => option.value === this.props.value) : this.props.value);
        
        const customSearch = (option, searchText) => {
            if((this.props.options.length < 1000 || this.props.showAll) || searchText.length >= 2) {
                if (option.data && option.data.search) {
                    if(option.data.search.toLowerCase().includes(searchText.toLowerCase())) {
                        return true
                    } else {
                        if(option.label.toLowerCase().replace('ë', 'e').includes(searchText.toLowerCase())) {
                            return true
                        } else {
                            return false
                        }
                    }
                } else {
                    if(option.label.toLowerCase().includes(searchText.toLowerCase()) || option.label.toLowerCase().replace('ë', 'e').includes(searchText.toLowerCase())) {
                        return true
                    } else {
                        return false
                    }
                }
            } else {
                return false
            }
        }

        const Element = this.props.creatable ? Creatable : WindowedSelect;

        return (
            <>
                <FormControl fullWidth>
                    <Element
                        autoFocus={this.props.autoFocus ?? undefined}
                        adornment={this.props.adornment ? this.props.adornment : null}
                        adornmentClass={this.props.adornmentClass ? this.props.adornmentClass : null}
                        adornmentSize={this.props.adornmentSize ? this.props.adornmentSize : null}
                        classes={classes}
                        components={components}
                        defaultValue={this.props.defaultValue}
                        error={!!this.props.error}
                        errorText={this.props.errorText}
                        filterOption={customSearch}
                        formatCreateLabel={this.props.creatableMessage}
                        formatGroupLabel={formatGroupLabel}
                        formatOptionLabel={this.props.formatOptionLabel}
                        IconComponent={false}
                        inputMode={this.props.inputMode ?? undefined}
                        isClearable={!this.props.noClear}
                        isSearchable={this.props.isSearchable ?? undefined}
                        isDisabled={this.props.options === undefined || this.props.disabled}
                        isOptionDisabled={this.props.isOptionDisabled ? this.props.isOptionDisabled : (option) => option.disabled === true}
                        label={this.props.label}
                        menuPortalTarget={this.props.isSearchable === false ? undefined : document.body}
                        menuPlacement={this.props.menuPlacement ?? 'auto'}
                        noOptionsMessage={this.props.noOptionsMessage}
                        onChange={this.props.onChange}
                        options={(options && !this.props.noDefaultSort && _.sortBy(options, function (opt) { if(opt.label && opt.label !== opt.value) { return opt.label.toLowerCase() } else { return '' } })) || options}
                        placeholder={this.props.noPlaceholder ? '' : (this.props.placeholder ? this.props.placeholder : (this.props.creatable ? `Start typing to search or create an option...` : `Start typing to search...`))}
                        ref={this.props.selectRef ? this.props.selectRef : null}
                        shrink={this.props.noShrink ? false : true}
                        standardLabel={this.props.standardLabel}
                        styles={selectStyles}
                        small={this.props.small ?? undefined}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'rgba(239, 51, 64, 1)',
                                primary25:'rgba(239, 51, 64, 0.25)',
                                primary50:'rgba(239, 51, 64, 0.50)',
                                primary75:'rgba(239, 51, 64, 0.75)'
                            }
                        })}
                        value={cleanValue}
                        variant={this.props.variant}
                    />
                </FormControl>
            </>
        );
    }
}
  
AutoCompleteSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
  
export default withStyles(styles, { withTheme: true })(AutoCompleteSelect);