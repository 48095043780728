import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import InsightChip from 'Components/Common/Chips/InsightChip';
import PaddedError from 'Components/Common/ErrorHandling/PaddedError';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { currencyFormat } from 'Functions/MiscFunctions';
import { getFormDataHook } from 'Functions/FormFunctions';

const ViewQuoteApproval = ({callback, request, quote}) => {
    const   dispatch    = useDispatch(),
            staff       = useSelector(state => state.notifications.status);

    const notifyCustomerByCall = () => {
        dispatch(deployDialog(<AddCallLogForm toggleDialog={() => dispatch(closeDialog())} id={quote.quote_id} relation="quote" stateSubmit={handleCustomerNotification("Call")} />, true, "", "standard","xs"));
    }

    const notifyCustomerByEmail = () => {
        let defaultFormData = {
            emailSubject: `Advanced Access | Quote #${quote.quote_reference}/${quote.quote_revision}${!_.isEmpty(quote.quote_customer_reference) ? ` (${quote.quote_customer_reference})` : ''}`,
        }
        if(!_.isEmpty(quote.quote_email)) {
            defaultFormData = {
                ...defaultFormData,
                emailTo: {
                    label: quote.quote_email,
                    value: quote.quote_email
                }
            }
        }
        dispatch(deployDialog(<EmailDialog id={quote.quote_id} type='quoteDeclined' defaultFormData={defaultFormData} stateSubmit={handleCustomerNotification("E-mail")} />, false, "Send Quote Approval Decision", "standard","md"))
    }

    const handleCustomerNotification = type => formData => {
        API.get(`/quotes/${quote.quote_id}/approval/${request.qa_id}/check  `)
        .then(res => {
            if(res?.data) {
                if(res.data.success) {
                    let comments = '';
                    switch(type) {

                        case "E-mail": 
                            comments = formData.emailPlainText;
                            API.post(`/email`, getFormDataHook({...formData}))
                            .then(result => {
                                if(result?.data?.errors) {
                                    dispatch(deploySnackBar("error", "Something went wrong, please try again"))
                                    dispatch(closeDialog())
                                } else if(result.data.success) {
                                    handleSubmitNotification("E-mail", comments);
                                }
                            });
                        break;

                        case "Call":
                            comments = formData.callNote;
                            API.post('/misc/calls', formData)
                            .then((result) => {
                                if(result.data.errors) {
                                    dispatch(deploySnackBar("error", "Something went wrong, please try again"))
                                    dispatch(closeDialog())
                                } else {
                                    handleSubmitNotification("Call", comments);
                                }
                            })
                        break;

                        default:
                            dispatch(deploySnackBar("error", "Something went wrong, please try again"))
                        break;
                    }
                } else {
                    dispatch(deploySnackBar("error", "Another staff member has already notified this customer of the quote being declined"))
                    dispatch(closeDialog())
                    callback?.();
                }
            }
        })
    }

    const handleSubmitNotification = (type, comments) => {
        API.put(`/quotes/${quote.quote_id}/approval/${request.qa_id}`, { type, comments })
        .then(res => {
            if(res?.data) {
                dispatch(deploySnackBar("success", "You have successfully notified this customer of the quote approval decision"))
                dispatch(closeDialog())
                callback?.();
            }
        })
    }

    return (
        <Box mt={3}>  
            {request.qa_actioned === 0 && (
                <Box mb={3}>
                    <Alert severity='info'>
                        This quote approval is {request.qa_status === 'Awaiting Credit Report' ? 'awaiting a credit report from accounts' : (request.qa_rc_id > 0 ? 'awaiting a final decision' : 'awaiting the initial review')}
                    </Alert> 
                </Box>
            )}
            <Box p={3} mb={3} className='content-light-grey'>
                <Typography variant="body2" className="fw-400">
                    Approval Request
                </Typography>
                <Typography variant="caption">
                    Completed by {staff?.[request.qa_req_staff_id]?.name ?? 'Unknown'} on {moment(request.qa_created_datetime, "YYYY-MM-DD HH:mm:ss").format("LLLL")}
                </Typography>
                {!_.isEmpty(request.qa_req_comments) && (
                    <>
                        <Typography variant="body2" className="fw-400 mt-2">
                            Comments
                        </Typography>
                        <Typography variant="caption">
                            {_.map(request.qa_req_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)}
                        </Typography>
                    </>
                )}
            </Box>
            {request?.credit_report && (
                <Box p={3} mb={request.qa_actioned === 1 ? 3 : undefined} className={request?.credit_report?.rc_status === 'Good' || request?.credit_report?.rc_status === 'Satisfactory' ? 'content-light-success' : 'content-light-error'}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Typography variant="body2" className="fw-400">
                                Credit Report Check
                            </Typography>
                            <Typography variant="caption">
                                Completed by {staff?.[request?.credit_report?.rc_actioned_staff_id]?.name ?? 'Unknown'} on {moment(request?.credit_report?.rc_created_datetime, "YYYY-MM-DD HH:mm:ss").format("LLLL")}
                            </Typography>
                        </Grid>
                        <Grid item xs align="right">
                            <InsightChip
                                label={`Credit ${request?.credit_report?.rc_status}`}
                                icon={request?.credit_report?.rc_status === 'Good' || request?.credit_report?.rc_status === 'Satisfactory' ? 'check' : 'times'}
                                background={request?.credit_report?.rc_status === 'Good' || request?.credit_report?.rc_status === 'Satisfactory' ? '#4CAF50' : '#f44336'}
                                color='#fff'
                                paddingLeft={8}
                                marginRight={8}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <InsightChip
                            label={request?.qa_rc_decision === 'Yes' ? 'Acceptable On Account' : (request?.qa_rc_decision === 'No' ? 'Not Acceptable On Account' : 'Acceptable On Account (Subject To Terms)')}
                            icon={request?.qa_rc_decision === 'Yes' ? 'check' : (request?.qa_rc_decision === 'No' ? 'times' : 'exclamation-triangle')}
                            background={request?.qa_rc_decision === 'Yes' ? '#4CAF50' : (request?.qa_rc_decision === 'No' ? '#f44336' : '#F57C00')}
                            color='#fff'
                            paddingLeft={8}
                            marginRight={8}
                        />
                        {request?.qa_rc_decision === 'With Terms' && (
                            <>
                                {request?.qa_prepay_percent !== 0 && (
                                    <InsightChip
                                        label={`${request?.qa_prepay_percent} Upfront`}
                                        icon='percent'
                                        background='#f44336'
                                        color='#fff'
                                        paddingLeft={8}
                                        marginRight={8}
                                    />
                                )}
                                {request?.qa_prepay_value !== "0.00" && (
                                    <InsightChip
                                        label={`${currencyFormat.format(request?.qa_prepay_value).replace('£','')} Upfront`}
                                        icon='pound-sign'
                                        background='#f44336'
                                        color='#fff'
                                        paddingLeft={8}
                                    />
                                )}
                            </>
                        )}
                    </Box>
                    {!_.isEmpty(request?.credit_report?.rc_comments) && (
                        <Box mt={3}>
                            <Typography variant="body2" className="fw-400">
                                Comments
                            </Typography>
                            <Typography variant="caption">
                                {_.map(request?.credit_report?.rc_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
            {request.qa_actioned === 1 && (
                <>
                    <Box p={3} mb={3} className={request.qa_approved === 1 ? 'content-light-success' : 'content-light-error'}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Typography variant="body2" className="fw-400">
                                    Approval Decision
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    Completed by {staff?.[request.qa_actioned_staff_id]?.name ?? 'Unknown'} on {moment(request.qa_decision_datetime, "YYYY-MM-DD HH:mm").format("LLLL")}
                                </Typography>
                            </Grid>
                            <Grid item xs align="right">
                                <InsightChip
                                    label={request?.qa_approved === 1 ? 'Quote Approved' : 'Quote Declined'}
                                    icon={request?.qa_approved === 1 ? 'check' : 'times'}
                                    background={request?.qa_approved === 1 ? '#4CAF50' : '#f44336'}
                                    color='#fff'
                                    paddingLeft={8}
                                    marginRight={8}
                                />
                            </Grid>
                        </Grid>
                        {!_.isEmpty(request.qa_actioned_management_comments) && (
                            <Box mb={2}>
                                <Typography variant="body2" className="fw-400">
                                    Management Comments
                                </Typography>
                                <Typography variant="caption">
                                    {_.map(request.qa_actioned_management_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)}
                                </Typography>
                            </Box>
                        )}
                        {!_.isEmpty(request.qa_actioned_comments) && (
                            <Box>
                                <Typography variant="body2" className="fw-400">
                                    Comments
                                </Typography>
                                <Typography variant="caption">
                                    {_.map(request.qa_actioned_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </>
            )}
            {request.qa_actioned === 1 && request.qa_approved === 0 && (
                <>
                    <Box p={3} className={request.qa_cust_notification !== 'None' ? 'content-light-success' : 'content-light-error'}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Typography variant="body2" className="fw-400">
                                    Customer Notification
                                </Typography>
                                {(request.qa_cust_notification !== "None" && (
                                <>
                                    <Typography variant="caption" paragraph>
                                        Completed by {staff?.[request.qa_cust_notification_staff_id]?.name ?? 'Unknown'} on {moment(request.qa_cust_notification_datetime, "YYYY-MM-DD HH:mm").format("LLLL")}
                                    </Typography>
                                    {!_.isEmpty(request.qa_cust_notification_comments) && (
                                        <Box>
                                            <Typography variant="body2" className="fw-400">
                                                {request.qa_cust_notification === 'E-mail' ? 'E-mail Message' : 'Call Comments'}
                                            </Typography>
                                            <Typography variant="caption">
                                                {_.map(request.qa_cust_notification_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            )) || (
                                <>
                                    <Typography variant="caption" gutterBottom>
                                        Do you want to notify the customer about this quote being declined now?
                                    </Typography>
                                    <Box mt={2}>
                                        <Box component="span" mr={3}>
                                            <Button
                                                color="primary"
                                                onClick={notifyCustomerByCall}
                                                variant="contained"
                                            >
                                                Notify by Call
                                            </Button>
                                        </Box>
                                        <Button
                                            color="primary"
                                            onClick={notifyCustomerByEmail}
                                            variant="contained"
                                        >
                                            Notify by E-mail
                                        </Button>
                                    </Box>
                                </>
                            )}
                            </Grid>
                            <Grid item xs align="right">
                                {request.qa_cust_notification !== "None" && (
                                    <InsightChip
                                        label={`Via ${request.qa_cust_notification}`}
                                        icon={'check'}
                                        background={'#4CAF50'}
                                        color='#fff'
                                        paddingLeft={8}
                                        marginRight={8}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Box>
    )

}

const SearchApprovalsTable = ({callback, inline, rows, quote}) => {

    const   dispatch    = useDispatch(),
            status      = useSelector(state => state.notifications.status);

    return (
        (_.isEmpty(rows) && (
            <PaddedError inline={inline}>There are no approvals required for this order</PaddedError>
        )) || (
            <Paper elevation={inline && 0}>
                <DataTable  
                    config={{
                        key: 'oa_id',
                        pagination: true,
                        alternatingRowColours: true,
                        // responsiveImportance: true,
                        options: {
                            dataRef: true,
                            export: {
                                title: `Sales Order Approvals`,
                                name: `SOP-Approvals`,
                                excel: true,
                                pdf: true,
                                print: true
                            }
                        }
                    }}
                    columns={[ 
                        {
                            heading: 'Approval Status',
                            field: rowData => (
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item>
                                        <FAIcon icon={rowData.qa_approved === 1 ? 'check' : (rowData.qa_approved === 0 && rowData.qa_actioned === 1 ? 'times' : 'info')} className={rowData.qa_approved === 1 ? 'textSuccess' : (rowData.qa_approved === 0 && rowData.qa_actioned === 1 ? 'textError' : 'textWarning')} size={25} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" className="fw-400">
                                            {rowData.qa_status === 'New' && rowData.qa_rc_id > 0 ? 'Awaiting Final Review' : rowData.qa_status}
                                        </Typography>
                                        <Typography variant="caption">
                                            {(rowData.qa_status === 'New' && rowData.qa_rc_id === 0 ? 'Awaiting Review' : (rowData.qa_status === 'Awaiting Credit Report' ? 'Review In Progress' : (rowData.qa_status === 'Declined' ? `This quote has been declined` :  (rowData.qa_status === 'Approved' && rowData.qa_rc_decision !== "With Terms" ? `This quote can be sent to the customer` : (rowData.qa_rc_decision === 'Yes' ? 'Acceptable On Account' : (rowData.qa_rc_decision === 'No' ? 'Not Acceptable On Account' : (rowData.qa_rc_decision === 'With Terms' ? ` Subject To Upfront Payment ${currencyFormat.format(rowData.qa_prepay_value)} (${rowData.qa_prepay_percent}%)` : `-`)))))))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ),
                            dataRef: 'qa_status',
                        },   
                        {
                            heading: 'Requested By',
                            field: rowData => (
                                <>
                                    <Typography variant="body2" className="fw-400">
                                        {status?.[rowData.qa_req_staff_id]?.name ?? '-'}
                                    </Typography>
                                    <Typography variant="caption">
                                        {moment(rowData.qa_created_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY [at] HH:mm")}
                                    </Typography>
                                </>
                            ),
                            dataRef: 'qa_created_datetime',
                        },
                        {
                            heading: 'Decision Maker',
                            field: rowData => (
                                <>
                                    <Typography variant="body2" className="fw-400">
                                        {rowData.qa_actioned ? (status?.[rowData.qa_actioned_staff_id]?.name ?? '-') : '-'}
                                    </Typography>
                                    <Typography variant="caption">
                                        {rowData.qa_decision_datetime === "0000-00-00 00:00:00" ? '-' : moment(rowData.qa_decision_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY [at] HH:mm")}
                                    </Typography>
                                </>
                            ),
                            dataRef: 'qa_decision_datetime',
                        },
                        {
                            heading: 'Customer Notified',
                            field: rowData => rowData.qa_cust_notification === "None" ? (rowData.qa_actioned ? (rowData.qa_approved === 0 ? "No" : "Not Applicable") : "-") : `Via ${rowData.qa_cust_notification}`,
                            dataRef: 'qa_cust_notification',
                        },
                        {
                            actions: rowData => {
                                return [
                                    {name: 'View', icon: 'binoculars', onClick: () => dispatch(deployDialog(<ViewQuoteApproval callback={callback} request={rowData} quote={quote} />, false, 'Quote Approval', rowData.qa_approved === 1 ? 'success' : 'error', 'md'))},
                                ]
                            }
                        }
                    ]}
                    rows={rows}
                />
            </Paper>
        )
    )
}

export default SearchApprovalsTable;