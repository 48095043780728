import React from 'react';

import Search from 'Components/Common/Search/Search';

const PurchaseAccTurnover = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/purchaseAccountTurnoverByProduct',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Acc Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Acc Number',
                        field: rowData => rowData.sgr,
                        dataRef: 'sgr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.de,
                        dataRef: 'de',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'YE 2021',
                        field: rowData => rowData.yre?.['2020'],
                        dataRef: 'yre.2020',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'YE 2022',
                        field: rowData => rowData.yre?.['2021'],
                        dataRef: 'yre.2021',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'YE 2023',
                        field: rowData => rowData.yre?.['2022'],
                        dataRef: 'yre.2022',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'YE 2024',
                        field: rowData => rowData.yre?.['2023'],
                        dataRef: 'yre.2023',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'YE 2025',
                        field: rowData => rowData.ytd,
                        dataRef: 'ytd',
                        important: true,
                        sizeToContent: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'purchase-account-turnover-by-product',
                noResultsText: 'Sorry, no results found for your search criteria',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Suppliers'],
                pdf: true,
                persistenceId: 'purchaseAccTurnoverByProduct',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: true,
                showTotals: false,
                title: 'Purchase Account Turnover Analysis Report'
            }}
        />
    )
}

export default PurchaseAccTurnover;