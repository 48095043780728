import React from 'react';

import Search from 'Components/Common/Search/Search';

const ShippingScheduleReport = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/shippingSchedule',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        showTotalLabel: true,
                        truncate: true
                    },
                    {
                        heading: 'Sales Order',
                        field: rowData => rowData.so,
                        dataRef: 'so',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Purchase Order',
                        field: rowData => rowData.po,
                        dataRef: 'po',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Stock Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.s,
                        dataRef: 's',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Quantity',
                        field: rowData => rowData.qo,
                        alignment: 'right',
                        dataRef: 'qo',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    // {
                    //     heading: 'Qty Delivered',
                    //     field: rowData => rowData.qd,
                    //     alignment: 'right',
                    //     dataRef: 'qd',
                    //     important: true,
                    //     sizeToContent: true,
                    //     showTotal: true
                    // },
                    {
                        heading: 'Expected Date',
                        field: rowData => rowData.sd,
                        dataRef: 'sd',
                        important: true,
                        sizeToContent: true,
                        fieldFormat: 'date'
                    },
                    {
                        heading: 'Check Date',
                        field: rowData => rowData.cd,
                        dataRef: 'cd',
                        important: true,
                        sizeToContent: true,
                        fieldFormat: 'date'
                    },
                    {
                        heading: 'Ship Here',
                        field: rowData => rowData.sh,
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        dataRef: 'sh',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Direct',
                        field: rowData => rowData.dd,
                        fieldFormat: 'boolean',
                        alignment: 'center',
                        dataRef: 'dd',
                        important: true,
                        sizeToContent: true,
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                labels: {
                    date: 'Check Date'
                },
                name: 'shipping-schedule',
                noResultsText: 'There are no upcoming shipments to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Suppliers'],
                pdf: true,
                persistenceId: 'shippingScheduleReport',
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: true,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showShipType: true,
                showSupplier: true,
                showTotals: false,
                title: 'Shipping Schedule Report'
            }}
        />
    )
}

export default ShippingScheduleReport;