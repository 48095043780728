import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = {
    defaultConf: {
        options: [],
        standard: null,
        free: null
    },
    searchResults: {},
    isLoading: true
}

class Couriers extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/couriers'),
            API.get('/couriers/default')
        ])
        .then(([result, defaultConf]) => {

            if(result?.data && defaultConf?.data) {

                this.setState({
                    defaultConf: {
                        standard: defaultConf.data.standard,
                        free: defaultConf.data.free,
                        collection: defaultConf.data.collection,
                        options: defaultConf.data.options
                    },
                    searchResults: result.data,
                    isLoading: false
                })

            }
        });
    }
    
    handleDefaultChange = (type, selectedOption) => {
        if(selectedOption?.value) {
            this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to set the default {type === `collection` ? `collection method` : `${type} delivery`} to:</Typography><Typography variant="body2">{selectedOption.label}</Typography></>, () => this.handleDefaultSubmit(type, selectedOption.value))
        }
    }

    handleDefaultSubmit = (type, id) => {
        API.put(`/couriers/default`, { type, id })
        .then(res => {
            if(res?.data?.success) {
                this.props.deploySnackBar("success", `The default ${type === `collection` ? `collection method` : `${type} delivery`} has been successfully updated`)
                this.loadComponentData();
            } else {
                this.props.deploySnackBar("error", "Something went wrong, please try again")
                this.loadComponentData();
            }
        })
    }

    render() {
        const { access, action } = this.props;
        const { defaultConf, isLoading, searchResults } = this.state;
        const HelpAndSupport = () => (
            <>
                <Typography variant="h6" paragraph>
                    <FAIcon type="light" icon="life-ring" style={{color: '#ef3340'}} />
                    Help and Support
                </Typography>         
                <Typography color="primary" variant="body2"> 
                    Delivery Methods
                </Typography>
                <Typography variant="caption">
                    You can add and manage delivery methods offered by these couriers within the configuration page for each courier.
                </Typography>  
            </>
        )
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <Grid container spacing={3}> 
                    <Grid item xs={12} lg={8}>         
                        <PaddedPaper>
                            <Grid container spacing={3} className="mb-1">
                                <Grid item xs>
                                    <Typography variant="h6" gutterBottom>
                                        Delivery Partners
                                    </Typography>
                                </Grid>                            
                               {action}
                            </Grid>
                            <DataTable  
                                config={{
                                    key: 'courier_id',
                                    responsiveImportance: true,
                                    isLoading: isLoading,
                                    noRowHover: true,
                                    inline: true,
                                    options: {
                                        dataRef: true,                               
                                        reset: this.loadComponentData,
                                        helpAndSupport: <HelpAndSupport />,
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Courier',
                                        field: rowData => <Avatar src={rowData.courier_logo} style={{marginTop: 6, marginBottom: 6, borderRadius: 0, backgroundColor: '#fff', color: '#000', width: 125, height: 71, textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>{rowData.courier_name}</Avatar>,
                                        important: true,
                                        hideInResponsiveDialog: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'API',
                                        dataRef: 'courier_api_ref',
                                        field: rowData => rowData.courier_api_ref !== '' ? <FAIcon type="light" icon="check-circle" size="small" style={{color: '#2E7D32'}} /> : <FAIcon type="light" icon="times-circle" size="small" style={{color: '#c62828'}} />,
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Delivery Methods',
                                        dataRef: 'configuration_count',
                                        field: rowData => rowData.configuration_count,
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Collection Time',
                                        dataRef: 'courier_collection_time',
                                        field: rowData => rowData.courier_collection_time,
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Telephone',
                                        dataRef: 'courier_telephone',
                                        field: rowData => rowData.courier_telephone,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Account Number',
                                        dataRef: 'courier_account_number',
                                        field: rowData => rowData.courier_account_number,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },                         
                                    {
                                        heading: 'Tracking Website',
                                        actionsCustomHeader: true,                
                                        actions: rowData => {
                                            return [
                                                {name: 'Visit Tracking Website', icon: 'location', linkExternal: rowData.courier_tracking_url},
                                                {label: rowData.courier_tracking_url === '' ? '-' : false}
                                            ]
                                        }
                                    }
                                ]}
                                rows={searchResults}
                            />
                        </PaddedPaper>    
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h6">
                                        Default Delivery Method
                                    </Typography>
                                    <AutoCompleteSelect
                                        disabled={!access.setDefaultDelivery}                                
                                        options={defaultConf.options}
                                        onChange={v => this.handleDefaultChange('standard', v)}
                                        value={defaultConf.standard}
                                        noClear
                                    />   
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h6">
                                        Default Free Delivery Method
                                    </Typography>
                                    <AutoCompleteSelect
                                        disabled={!access.setDefaultDelivery}                                
                                        options={defaultConf.options}
                                        onChange={v => this.handleDefaultChange('free', v)}
                                        value={defaultConf.free}
                                        noClear
                                    />      
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h6">
                                        Collection Method
                                    </Typography>
                                    <AutoCompleteSelect
                                        disabled={!access.setDefaultDelivery}                                
                                        options={defaultConf.options}
                                        onChange={v => this.handleDefaultChange('collection', v)}
                                        value={defaultConf.collection}
                                        noClear
                                    />      
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(Couriers);