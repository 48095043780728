import React, { useEffect, useState }from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
    Grid,
    Paper,
    Typography
} from '@material-ui/core';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StaffChip from 'Components/Common/Chips/StaffChip';
import VisitForm from 'Components/Customers/Misc/VisitForm';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = () => ({
    access: {
        modifyVisit: false,
        deleteVisit: false
    },
    isLoading: true,
    results: []
})

const CustomerVisits = ({callback, customerId, dataLoading}) => {

    const dispatch = useDispatch();

    const staff = useSelector(state => state.notifications.status);

    const [state, setState] = useState(initialState());

    const {
        access,
        isLoading, 
        results
    } = state;

    const handleModifyVisit = rowData => {
        dispatch(deployDialog(
            <VisitForm callback={() => { dispatch(closeDialog()); callback?.(); }} customer={customerId} visit={rowData} />,
            true,
            ``,
            "standard",
            "sm",
            false,
        ));
    }

    const handleDeleteVisit = rowData => {
        API.put(`/customers/${customerId}/visits/${rowData.i}/delete`)
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `The visit was successfully deleted`))
                callback();
            }
        })
    }

    const handleLoadData = () => {

        setState(state => ({
            ...state,
            isLoading: true,
        }))

        API.get(`/customers/${customerId}/visits`)
        .then(res => {
            if(res?.data) {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    results: res.data
                }))
            }
        })

    }

    useEffect(() => {
        API.multiAccess([
            'visit:modify',
            'visit:delete'
        ])
        .then(([
            modifyVisit,
            deleteVisit
        ]) => {
            setState(state => ({
                ...state,
                access: {
                    modifyVisit,
                    deleteVisit
                }
            }))
            handleLoadData();
        })
    }, []);/* eslint-disable-line */
    
    return (
        ((dataLoading || isLoading) && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                {(!_.isEmpty(results) && (
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable
                                config={{
                                    key: "i",
                                    pagination: true,
                                    rowsPerPage: 10,
                                    alternatingRowColours: true,
                                    plainPagination: true,
                                    options: {
                                        dataRef: true,
                                        export: {
                                            excel: true,
                                            name: 'CustomerVisits',
                                            pdf: true,
                                            print: true,
                                            title: 'Customer Visits'
                                        },
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Visit Date',
                                        field: rowData => rowData.d,
                                        dataRef: 'd',
                                        fieldFormat: 'date',
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Contact',
                                        field: rowData => rowData.cn,
                                        dataRef: 'cn',
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Note',
                                        field: rowData => (
                                            <>
                                                {rowData?.n?.split?.('\n').map((item, key) => (
                                                    <Typography 
                                                        key={key} 
                                                        variant="caption"
                                                        component="div" 
                                                        paragraph={key !== (_.size(rowData?.n?.split?.('\n')) - 1)}
                                                    >
                                                        {item}
                                                    </Typography>
                                                ))}
                                            </>
                                        ),
                                        cellProps: () => ({
                                            style: {
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                overflow: 'hidden',
                                            }
                                        }),
                                        important: true,
                                        dataRef: 'n'
                                    },
                                    {
                                        heading: 'Visited By',
                                        field: rowData => <StaffChip staff={staff?.[rowData.s] ?? null} />,
                                        fieldFormat: 'staff',
                                        dataRef: 's',
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        actions: rowData => ([
                                            {
                                                name: 'Modify Visit',
                                                icon: 'pencil',
                                                disabled: !access.modifyVisit,
                                                onClick: () => handleModifyVisit(rowData)
                                            },
                                            {
                                                name: 'Delete Visit',
                                                icon: 'trash-alt',
                                                disabled: !access.deleteVisit,
                                                onClick: () => dispatch(deployConfirmation(`Are you sure you want to delete this visit?`, () => handleDeleteVisit(rowData)))
                                            }
                                        ])
                                    }
                                ]}
                                rows={results}
                            />
                        </Paper>
                    </Grid>
                )) || (
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ textAlign: "center" }}>
                            No visits have been recorded for this customer
                        </Typography>
                    </Grid>
                )}
            </Grid>
        )
    )
}

export default CustomerVisits;