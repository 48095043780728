import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const LoadingScreen = ({open, title, message, additional}) => (
    <Dialog
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        open={open}
        maxWidth='md' 
        style={{
            backgroundColor: 'rgba(0,0,0,0.5)'
        }}
    >
        <DialogContent style={{padding: '100px 50px'}}>
            <Grid container spacing={3}>
                <Grid item xs={12} align='center'>
                    <CircularProgress size={50} />
                </Grid>
                <Grid item xs={12} align='center'>
                    <Typography variant="h6" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {message}
                    </Typography>                            
                    {additional && (
                        <Typography variant="caption">
                            {additional}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
)

export default LoadingScreen;