import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Switch from "@material-ui/core/Switch";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddressDialog from 'Components/Common/Dialogs/AddressDialog';
import AliasDialog from 'Components/Common/Dialogs/AliasDialog';
import BrandDialog from 'Components/Common/Dialogs/BrandDialog';
import CarriageDialog from 'Components/Common/Dialogs/CarriageDialog';
import DiscountDialog from 'Components/Common/Dialogs/DiscountDialog';
import RangeDialog from 'Components/Common/Dialogs/RangeDialog';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import ContactDialog from 'Components/Common/Dialogs/ContactDialog';
import UpdateDuplicateAddresses from 'Components/Common/Forms/UpdateDuplicateAddresses';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Form from 'Components/Common/Styles/Form';

import { handleChange, handleChangeUc, handleCheckedChange, handleFloat, handleNumberChange, handleSelectChange } from 'Functions/FormFunctions';
import { handleTabChange, TabPanel } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { clearPersistenceKey } from 'Redux/Actions/StatePersistence/StatePersistence';
import Alert from 'Components/Common/Alert/Alert';
import InvoiceEmailDialog from 'Components/Common/Dialogs/InvoiceEmailDialog';

const initialState = () => ({ 
    currentTab: 0,
    dialog: {
        addresses: {
            open: false,
            fieldName: "",
            idx: 0
        },
        aliases: {
            open: false,
            fieldName: "",
            idx: 0
        },
        brands: {
            open: false,
            fieldName: "",
            idx: 0
        },
        carriage: {
            open: false,
            fieldName: "",
            idx: 0
        },
        contacts: {
            open: false,
            fieldName: "",
            idx: 0
        },
        discounts: {
            open: false,
            fieldName: "",
            idx: 0
        },
        ranges: {
            open: false,
            fieldName: "",
            idx: 0
        },
        poEmails: {
            open: false,
            fieldName: "",
            idx: 0
        },
        submit: false,
        cancel: false
    },
    displayAddAddressMenu: false,
    formId: uuidv4(), 
    formData: {
        supplierType: '',
        companyName: '',
        tradingName: '',        
        phone: '',
        email:  '',
        website:  '',
        companyVATNumber: '',   
        vatType: '', 
        companyRegistrationNumber: '',
        legalStatus: '',
        leadTime: '',
        paymentTerm: '',
        accountNumber: '',
        minOrderValue: '',
        defSuppCarr: '',
        defFocSuppCarr: '',
        sendDespatchNote: 0,
        focCarr: '0.00',
        poRequired: 0,
        shipHere: 0,
        cutOffData: {
            mon: "",
            tue: "",
            wed: "",
            thu: "",
            fri: ""
        },
        cutOffVaries: 0,
        tradingAddresses: [],
        registeredAddresses: [],
        accountsAddresses: [],
        deliveryAddresses: [],
        supplierAliases: [],
        supplierBrands: [],
        supplierCarriage: [],
        supplierContacts: [],
        supplierDiscounts: [{name: 'Standard Discount', percent: '25.00', products_count: 0}],
        supplierPoEmails: [],
        supplierRanges: [],
    },
    formDefaults: {
        alias: {
            name: ''
        },
        address: {
            id: '',
            name: '',
            addressOne: '',
            addressTwo:  '',
            addressThree:  '',
            town:  '',
            county: '',
            country:  234,
            postcode: '',
            notes: '',
            showAddress: false,
            showAddressLookup: true,
            addressComplete: false
        },
        brand: {
            name: ''
        },
        carriage: {
            id: 0,
            courier: '',
            service: '',
            cost: '',
            notes: ''
        },
        contact: {
            id: '',
            firstName: '',
            lastName: '',
            position: '',
            telephone: '',
            mobile: '',
            email: '',
            primaryContact: 0,
            accounts: 0,
            gdprOptOut: 0,
            mailshotBar: 0,
            noDelUpdates: 0,
            noOrderEmails: 0,
            prefEmail: 0,
            prefMob: 0,
            prefTel: 0,
            notes: '',
        },
        discount: {
            name: '',
            percent: ''
        },
        email: {
            emailAddress: '',
        },
        range: {
            name: ''
        },
    },
    formErrors: [],
    id: null,
    initialFormData: {
        supplierType: '',
        companyName: '',
        tradingName: '',        
        phone: '',
        email:  '',
        website:  '',
        companyVATNumber: '',   
        vatType: '', 
        companyRegistrationNumber: '',
        legalStatus: '',
        leadTime: '',
        paymentTerm: '',
        accountNumber: '',
        minOrderValue: '',
        defSuppCarr: '',
        defFocSuppCarr: '',
        sendDespatchNote: 0,
        focCarr: '0.00',
        poRequired: 0,
        shipHere: 0,
        cutOffData: {
            mon: "",
            tue: "",
            wed: "",
            thu: "",
            fri: ""
        },
        cutOffVaries: 0,
        tradingAddresses: [],
        registeredAddresses: [],
        accountsAddresses: [],
        deliveryAddresses: [],
        supplierAliases: [],
        supplierBrands: [],
        supplierCarriage: [],
        supplierContacts: [],
        supplierDiscounts: [{name: 'Standard Discount', percent: '25.00', products_count: 0}],
        supplierPoEmails: [],
        supplierRanges: [],
    },
    isLoading: true,
    lists: {
        counties: [],
        countries: [],
        leadTimes: [],
        paymentTerms: [],
        positions: [],
        types: [],
        vat: [],
    }
})

class SupplierForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.addAddressBtn = React.createRef();
        this.mainContentArea = React.createRef();
        this.handleChange = handleChange.bind(this);
        this.handleChangeUc = handleChangeUc.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleFloat = handleFloat.bind(this);
        this.handleNumberChange = handleNumberChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
        this.poll = null;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = () => {
        if(JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if(!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if(window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    }

    componentWillUnmount = () => {
        if(window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
        if(this.poll) {
            clearInterval(this.poll);
        }
    } 
    
    handlePoll = (initial = false) => {

        const {
            id
        } = this.state;

        if(id) {

            API.post(`/staff/my/view`, 
                { 
                    supp: id,
                    type: 'Update'
                }, 
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true
                    }
                }
            )
            .then(res => {
                if(res?.data) {
                    if(initial) {
                        if(!_.isEmpty(res.data) && _.some(res.data, el => el.u === 1)) {
                            this.props.deploySnackBar("error", "Sorry, another user is currently modifying this supplier");
                            this.props.history.push(`/suppliers/${id}`)
                        } else {
                            this.setState({
                                isLoading: false
                            })
                        }
                    }
                }
            })

        }

    }

    loadComponentData = () => {

        const id = this.props.match?.params?.id ?? this.props.id ?? null;

        Promise.all([
            API.get('/misc/counties/all'),
            API.get('/misc/countries/all'),
            API.get('/accounts/vatRates/byType'),
            API.get('/accounts/paymentTerms'),
            API.get('/suppliers/types/all'), 
            API.get(`/misc/contacts/positions`)
        ])
        .then(([
            counties,
            countries, 
            vat, 
            paymentTerms, 
            types, 
            positions
        ]) => {

            let lists = {  
                counties: [],
                countries: [],
                leadTimes: [],
                paymentTerms: [],
                positions: [],
                types: [],
                vat: [],
            }

            if(counties?.data) {
                lists.counties = _.map(counties.data , el => (
                    _.assign({
                        value: el.county_id,
                        label: el.county_name
                    })
                ));
            }

            if(countries?.data) {
                lists.countries = _.map(countries.data , el => (
                    _.assign({
                        value: el.country_id,
                        label: el.country_name
                    })
                ));
            }

            lists.leadTimes.push(
                {
                    label: "Check Lead Time",
                    value: "CHECK"
                },
                {
                    label: "Same Day",
                    value: "SAME"
                },
                {
                    label: "1 Day",
                    value: "1:DAY"
                }
            )

            for(let i = 2; i <= 31; i++) {
                lists.leadTimes.push(
                    {
                        label: `${i} Days`,
                        value: `${i}:DAYS`
                    }
                )
            }

            if(paymentTerms?.data) {
                lists.paymentTerms = _.map(paymentTerms.data, el => (
                    _.assign({
                        value: el.pt_id,
                        label: el.pt_name
                    })
                ));
            }

            if(positions?.data) {
                lists.positions = _.map(positions.data, position => (
                    _.assign({
                        label: position,
                        value: position
                    })
                ))
            }

            if(types?.data) {
                lists.types = _.map(types.data, el => (
                    _.assign({
                        value: el.type_id,
                        label: el.type_name
                    })
                ));
            }

            if(vat?.data) {
                vat.data.forEach((type) => {
                    let currentValue = _.find(type.vat_values, {current: true});
                    if(currentValue) {
                        lists.vat.push({
                            value: currentValue.vat_value_type_id,
                            label: type.vat_type_name + ' (' + currentValue.vat_value_percentage + '%)'
                        });
                    }
                });
            }

            this.setState({
                id: id ?? null,
                isLoading: id ? true : false,
                lists
            }, () => {

                if(id) {
                    this.loadUpdateData();
                } else {
                    this.props.pageTitle([1, 'Suppliers', 'New Supplier']);
                }
            })

        });
    }

    loadUpdateData = () => {

        const { 
            id 
        } = this.state;

        API.get(`/suppliers/${id}`, 
            {
                params: {
                    forUpdate: true
                }
            }
        )
        .then(result => {

            if(result?.data) {
                
                let supplier            = result.data,
                    tradingAddresses    = [],
                    registeredAddresses = [],
                    accountsAddresses   = [],
                    deliveryAddresses   = [],
                    supplierAliases     = [],
                    supplierBrands      = [],
                    supplierCarriage    = [],
                    supplierContacts    = [],
                    supplierDiscounts   = [],
                    supplierPoEmails    = [],
                    supplierRanges      = [],
                    cutOffData          = _.isEmpty(supplier.supp_cut_off_data) ? initialState().formData?.cutOffData : JSON.parse(supplier.supp_cut_off_data),
                    defSuppCarr         = '',
                    defFocSuppCarr      = '';

                if(supplier.trading_address) {
                    tradingAddresses.push(
                        {
                            ...initialState().formDefaults.address,
                            idx: 0,
                            id: supplier.trading_address.address_id,
                            name: supplier.trading_address.address_name,
                            addressOne: supplier.trading_address.address_line_one,
                            addressTwo: supplier.trading_address.address_line_two,
                            addressThree: supplier.trading_address.address_line_three,
                            town: supplier.trading_address.address_town,                            
                            county: supplier.trading_address.address_county_id,
                            country: supplier.trading_address.address_country_id,
                            postcode: supplier.trading_address.address_postcode,
                            notes: supplier.trading_address.address_notes,
                            showAddress: true,
                            showAddressLookup: false,
                            addressComplete: true
                        }
                    )
                }

                if(supplier.registered_address) {
                    registeredAddresses.push(
                        {
                            ...initialState().formDefaults.address,
                            idx: 0,
                            id: supplier.registered_address.address_id,
                            name: supplier.registered_address.address_name,
                            addressOne: supplier.registered_address.address_line_one,
                            addressTwo: supplier.registered_address.address_line_two,
                            addressThree: supplier.registered_address.address_line_three,
                            town: supplier.registered_address.address_town,                            
                            county: supplier.registered_address.address_county_id,
                            country: supplier.registered_address.address_country_id,
                            postcode: supplier.registered_address.address_postcode,
                            notes: supplier.registered_address.address_notes,
                            showAddress: true,
                            showAddressLookup: false,
                            addressComplete: true
                        }
                    )
                }
                
                if(!_.isEmpty(supplier.account_addresses)) {
                    _.each(supplier.account_addresses, (address, idx) => {
                        accountsAddresses.push(
                            {
                                ...initialState().formDefaults.address,
                                idx: idx,
                                id: address.address_id,
                                name: address.address_name,
                                addressOne: address.address_line_one,
                                addressTwo: address.address_line_two,
                                addressThree: address.address_line_three,
                                town: address.address_town,                            
                                county: address.address_county_id,
                                country: address.address_country_id,
                                postcode: address.address_postcode,
                                notes: address.address_notes,
                                showAddress: true,
                                showAddressLookup: false,
                                addressComplete: true
                            }
                        )
                    })
                }
                
                if(!_.isEmpty(supplier.delivery_addresses)) {
                    _.each(supplier.delivery_addresses, (address, idx) => {
                        deliveryAddresses.push(
                            {
                                ...initialState().formDefaults.address,
                                idx: idx,
                                id: address.address_id,
                                name: address.address_name,
                                addressOne: address.address_line_one,
                                addressTwo: address.address_line_two,
                                addressThree: address.address_line_three,
                                town: address.address_town,                        
                                county: address.address_county_id,
                                country: address.address_country_id,
                                postcode: address.address_postcode,
                                notes: address.address_notes,
                                showAddress: true,
                                showAddressLookup: false,
                                addressComplete: true
                            }
                        )
                    })
                }

                if(!_.isEmpty(supplier.aliases)) {
                    _.each(supplier.aliases, alias => {
                        supplierAliases.push(
                            {
                                ...initialState().formDefaults.alias,
                                id: alias.alias_id,
                                name: alias.alias_name,
                            }
                        )
                    })
                }
                
                if(!_.isEmpty(supplier.discounts)) {
                    _.each(supplier.discounts, discount => {
                        supplierDiscounts.push({
                            ...initialState().formDefaults.discount,
                            id: discount.supp_disc_id,
                            name: discount.supp_disc_name,
                            percent: discount.supp_disc_percentage,
                            products_count: discount.products_count
                        })
                    })
                }

                if(!_.isEmpty(supplier.carriage)) {
                    supplier.carriage.forEach((carriage, idx) => {
                        if(carriage.supp_carr_id === supplier.supp_def_supp_carr_id) {
                            defSuppCarr = idx
                        }
                        if(carriage.supp_carr_id === supplier.supp_def_foc_supp_carr_id) {
                            defFocSuppCarr = idx
                        }
                        supplierCarriage.push({
                            ...initialState().formDefaults.carriage,
                            id: carriage.supp_carr_id,
                            courier: carriage.supp_carr_courier,
                            service: carriage.supp_carr_service,
                            cost: carriage.supp_carr_cost,
                            notes: carriage.supp_carr_notes,
                        })
                    })
                }

                if(!_.isEmpty(supplier.contacts)) {
                    _.each(supplier.contacts, contact => {
                        supplierContacts.push(
                            {
                                ...initialState().formDefaults.contact,
                                id: contact.i,
                                firstName: contact.fn,
                                lastName: contact.ln,
                                position: contact.po,
                                telephone: contact.t,
                                mobile: contact.m,
                                email: contact.e,
                                notes: contact.n,
                                primaryContact: contact.p,
                                accounts: contact.a,
                                mailshotBar: contact.msb,
                                prefEmail: contact.pre,
                                prefMob: contact.prm,
                                prefTel: contact.prt,
                                noDelUpdates: contact.ndu,
                                noOrderEmails: contact.noe,
                                gdprOptOut: contact.goi === 1 ? 0 : 1 //inverted in db
                            }
                        )
                    })
                }

                if(!_.isEmpty(supplier.brands)) {
                    _.each(supplier.brands, brand => {
                        supplierBrands.push({
                            ...initialState().formDefaults.brand,
                            id: brand.brand_id,
                            name: brand.brand_name,
                            c: brand.products_count
                        })
                    })
                }

                if(!_.isEmpty(supplier.ranges)) {
                    _.each(supplier.ranges, range => {
                        supplierRanges.push({
                            ...initialState().formDefaults.range,
                            id: range.range_id,
                            name: range.range_name,
                            c: range.products_count
                        })
                    })
                }

                if(!_.isEmpty(supplier.po_emails)) {
                    _.each(supplier.po_emails, email => {
                        supplierPoEmails.push(
                            {
                                ...initialState().formDefaults.email,
                                id: email.spoea_id,
                                emailAddress: email.spoea_email_address,
                            }
                        )
                    })
                }
                
                let cutOffVaries = 0, 
                    time;

                Object.keys(cutOffData).forEach((key, idx) => {
                    if(idx === 0) {
                        time = cutOffData[key];
                    } else {
                        if(cutOffData[key] !== time) {
                            cutOffVaries = 1;
                        }
                    }
                })

                this.setState({
                    formData: {
                        ...this.state.formData,
                        supplierType: supplier.supp_type_id,
                        companyName: supplier.supp_company_name,
                        tradingName: supplier.supp_trading_name,
                        phone: supplier.supp_phone,
                        email: supplier.supp_email,
                        website: supplier.supp_website,
                        companyVATNumber: supplier.supp_vat_number,
                        vatType: supplier.supp_vat_type_id,
                        companyRegistrationNumber: supplier.supp_reg_number,
                        legalStatus: supplier.supp_legal_status,
                        leadTime: supplier.supp_lead_time,
                        paymentTerm: supplier?.payment_terms?.pt_id ?? '',
                        poRequired: supplier.supp_po_required,
                        accountNumber: supplier.supp_account_number,
                        minOrderValue: supplier.supp_min_order_value,
                        shipHere: supplier.supp_ship_here,
                        focCarr: supplier.supp_foc_min_order_value,
                        sendDespatchNote: supplier.supp_send_despatch_note,
                        defSuppCarr,
                        defFocSuppCarr,
                        cutOffData,
                        cutOffVaries,
                        tradingAddresses,
                        registeredAddresses,
                        accountsAddresses,
                        deliveryAddresses,
                        supplierAliases,
                        supplierBrands,
                        supplierCarriage,
                        supplierContacts,
                        supplierDiscounts,
                        supplierPoEmails,
                        supplierRanges
                    },
                    initialFormData: {
                        ...this.state.formData,
                        supplierType: supplier.supp_type_id,
                        companyName: supplier.supp_company_name,
                        tradingName: supplier.supp_trading_name,
                        phone: supplier.supp_phone,
                        email: supplier.supp_email,
                        website: supplier.supp_website,
                        companyVATNumber: supplier.supp_vat_number,
                        vatType: supplier.supp_vat_type_id,
                        companyRegistrationNumber: supplier.supp_reg_number,
                        legalStatus: supplier.supp_legal_status,
                        leadTime: supplier.supp_lead_time,
                        paymentTerm: supplier?.payment_terms?.pt_id ?? '',
                        poRequired: supplier.supp_po_required,
                        accountNumber: supplier.supp_account_number,
                        minOrderValue: supplier.supp_min_order_value,
                        shipHere: supplier.supp_ship_here,
                        focCarr: supplier.supp_foc_min_order_value,
                        sendDespatchNote: supplier.supp_send_despatch_note,
                        defSuppCarr,
                        defFocSuppCarr,
                        cutOffData,
                        cutOffVaries,
                        tradingAddresses,
                        registeredAddresses,
                        accountsAddresses,
                        deliveryAddresses,
                        supplierAliases,
                        supplierBrands,
                        supplierCarriage,
                        supplierContacts,
                        supplierDiscounts,
                        supplierPoEmails,
                        supplierRanges
                    }
                }, () => {
                    this.props.pageTitle([1, 'Suppliers', supplier?.supp_company_name ?? 'Unknown', 'Update']);
                    this.handlePoll(true);
                    this.poll = setInterval(this.handlePoll, 3000);
                })
            }
        })
    }

    /*
     * Addresses
     */
   handleAddAddressMenuToggle = () => {
        this.setState({
            displayAddAddressMenu: !this.state.displayAddAddressMenu
        })
    }

    handleShowAllAddresses = addresses => {
        addresses.forEach((add, idx) => {
            addresses[idx] = {
                ...addresses[idx],
                showAddress: true,
                showAddressLookup: false
            }
        })
        return addresses
    } 

    /*
     * Cut-off Times
     */
    handleCutOffChange = e => {
        const { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                cutOffData: {
                    ...this.state.formData.cutOffData,
                    [name]: value
                }
            }
        });
    }

    handleCutOffAllChange = e => {
        const { value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                cutOffData: {
                    mon: value,
                    tue: value,
                    wed: value,
                    thu: value,
                    fri: value
                }
            }
        });
    }

    /*
     * Dialogs
     */
    handleDialogClose = (type, exec) => {
        if(exec) {
            this.setState({
                dialog: {
                    ...this.state.dialog,
                    [type]: {
                        ...initialState().dialog[type],
                    }
                }
            })
        }
    }

    handleDialogToggle = (type, fieldName, idx) => {  
        let submit =  _.curry(this.handleRowAdd);
        let cancel =  _.curry(this.handleDialogClose);
        submit = submit(fieldName, idx);
        cancel = cancel(type);
        if(type === "addresses" && this.state.displayAddAddressMenu && idx === -1) {
            this.handleAddAddressMenuToggle();
        }
        this.setState({
            dialog: {
                ...this.state.dialog,
                [type]: {
                    ...initialState().dialog[type],
                    open: true,
                    fieldName,
                    idx
                },
                submit: submit,
                cancel: cancel
            }
        });
    }

    /*
     * Datasets (Addresses, Aliases, Contacts, etc...)
     */
    handleRowAdd = (fieldName, idx, postData, duplicateCheck = true) => {

        let rows = !_.isEmpty(this.state.formData[fieldName]) ? [...this.state.formData[fieldName]] : []

        if(fieldName === 'supplierContacts' && postData.primaryContact) {
            rows.forEach((row, ridx) => {
                if(row.primaryContact && idx !== ridx) {
                    rows[ridx].primaryContact = 0
                }
            })
        }

        if(fieldName === 'supplierContacts' && postData.accounts) {
            rows.forEach((row, ridx) => {
                if(row.accounts && idx !== ridx) {
                    rows[ridx].accounts = 0
                }
            })
        }

        let currentValue = null;

        if(rows && rows[idx]) {
            currentValue = {...rows[idx]}
            rows[idx] = postData
        } else {
            rows.push(postData)
        }

        if(fieldName === 'supplierContacts' && postData.primaryContact) {
            rows = _.orderBy(rows, ['primaryContact'], ['desc'])
        }

        _.each(rows, (row, idx) => {
            rows[idx].idx = idx
        })
        
        this.setState({
            dialog: initialState().dialog,
            formData:{
                ...this.state.formData,
                [fieldName]: rows
            }
        }, () => {

            if(duplicateCheck && currentValue && (fieldName === 'tradingAddresses' || fieldName === 'accountsAddresses' || fieldName === 'registeredAddresses' || fieldName === 'deliveryAddresses')) {
                
                const {
                    formData,
                    lists
                } = this.state;

                const duplicates = [];

                _.each([
                    ...(_.map(formData.tradingAddresses,    (el, idx) => ({...el, idx, t: 'Trading',    type: 'tradingAddresses'}))), 
                    ...(_.map(formData.registeredAddresses, (el, idx) => ({...el, idx, t: 'Registered', type: 'registeredAddresses'}))), 
                    ...(_.map(formData.accountsAddresses,   (el, idx) => ({...el, idx, t: 'Accounts',   type: 'accountsAddresses'}))), 
                    ...(_.map(formData.deliveryAddresses,   (el, idx) => ({...el, idx, t: 'Delivery',   type: 'deliveryAddresses'}))), 
                ], addr => {
                    if(JSON.stringify({
                        addressOne: currentValue.addressOne,
                        addressTwo: currentValue.addressTwo,
                        addressThree: currentValue.addressThree,
                        town: currentValue.town,
                        county: currentValue.county,
                        postcode: currentValue.postcode,
                        country: currentValue.country,
                    }) === JSON.stringify({
                        addressOne: addr.addressOne,
                        addressTwo: addr.addressTwo,
                        addressThree: addr.addressThree,
                        town: addr.town,
                        county: addr.county,
                        postcode: addr.postcode,
                        country: addr.country,
                    })) {
                        duplicates.push({fieldName: addr.type, idx: addr.idx, update: 1, addr})
                    }
                });

                if(!_.isEmpty(duplicates)) {
                    this.props.deployDialog(
                        <UpdateDuplicateAddresses
                            counties={lists.counties}
                            countries={lists.countries}
                            duplicates={duplicates}
                            handleRowAdd={this.handleRowAdd}
                            postData={postData}
                        />,
                        false,
                        "Update Existing Addresses", 
                        "standard",
                        "lg"
                    )
                }

            }
        })
    }
  
    handleRowRemove = (fieldName, idx) => {
        const rows = [...this.state.formData[fieldName]]
        rows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: rows
            }
        })
    }

    /*
     * Form Submission
     */
    handleSubmit = () => {

        const { 
            formData, 
            id 
        } = this.state;
        
        let error = false;

        if(_.isEmpty(formData.tradingAddresses)) {
           this.handleTabChange(1);
           this.props.deploySnackBar("error", "Please enter a trading address for this supplier");
            error = true
        }
        
        if(!error) {

            this.setState({
                isLoading: true
            },
            () => {
                if(id) {
                    this.submitUpdateRecord()
                } else {
                    this.submitAddRecord()
                }
            });

        }

    }

    submitAddRecord = () => {

        let {
            formData
        } = this.state;

        if(_.isEmpty(formData.accountsAddresses)) {
            formData.accountsAddresses.push(formData.tradingAddresses[0])
        }
        if(_.isEmpty(formData.registeredAddresses)) {
            formData.registeredAddresses.push(formData.tradingAddresses[0])
        }
        if(_.isEmpty(formData.deliveryAddresses)) {
            formData.deliveryAddresses.push(formData.tradingAddresses[0])
        }

        API.post(`/suppliers`, formData)
        .then(result => {

            if(result?.data?.errors) {

                let tradingAddresses    = this.handleShowAllAddresses([...formData.tradingAddresses]),
                    registeredAddresses = this.handleShowAllAddresses([...formData.registeredAddresses]),
                    accountsAddresses   = this.handleShowAllAddresses([...formData.accountsAddresses]),
                    deliveryAddresses   = this.handleShowAllAddresses([...formData.deliveryAddresses]);
               
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false,
                    formData: {
                        ...this.state.formData,
                        tradingAddresses,
                        registeredAddresses,
                        accountsAddresses,
                        deliveryAddresses
                    }
                }, () => {
                    this.handleTabChange(0)
                })

            } else {
                this.props.deploySnackBar("success", `You have successfully added a new supplier account`)
                this.props.history.push({
                    pathname: `/suppliers/${result.data.id}`,
                })
            }
        });
    }

    submitUpdateRecord = () => {

        let {
            formData
        } = this.state;

        const { 
            id,
            initialFormData,
        } = this.state;

        formData = {
            ...formData,
            initialFormData
        }

        API.put(`/suppliers/${id}`, formData)
        .then(result => {

            if(result?.data?.errors) {
                
                let tradingAddresses    = this.handleShowAllAddresses([...formData.tradingAddresses]),
                    registeredAddresses = this.handleShowAllAddresses([...formData.registeredAddresses]),
                    accountsAddresses   = this.handleShowAllAddresses([...formData.accountsAddresses]),
                    deliveryAddresses   = this.handleShowAllAddresses([...formData.deliveryAddresses]);

                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false,
                    formData: {
                        ...this.state.formData,
                        tradingAddresses,
                        registeredAddresses,
                        accountsAddresses,
                        deliveryAddresses
                    }
                }, () => {
                    this.handleTabChange(0)
                })

            } else {
                this.props.clearPersistenceKey(`supplier:${id}`);
                this.props.deploySnackBar(`success`, `You have successfully modified this supplier`)
                this.props.history.push(`/suppliers/${id}`)
            }

        });
    }

    /*
     * Render
     */
    render = () => {

        const { 
            classes,
        } = this.props;

        const { 
            currentTab, 
            dialog, 
            displayAddAddressMenu, 
            formData, 
            formErrors, 
            id, 
            initialFormData, 
            isLoading, 
            lists 
        } = this.state;

        return (
            <Grid container className={classes.container}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Prompt
                            when={JSON.stringify(formData) !== JSON.stringify(initialFormData)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                        <Grid item ref={this.mainContentArea} className={classes.main}>
                            <TabPanel value={currentTab} index={0}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">
                                                                Supplier Details
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="companyName"
                                                                name="companyName"
                                                                label="Supplier Name *"
                                                                value={formData.companyName}
                                                                error={formErrors && formErrors['companyName'] && true}
                                                                helperText={formErrors && formErrors['companyName']}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="tradingName"
                                                                name="tradingName"
                                                                label="Supplier Trading Name"
                                                                value={formData.tradingName}
                                                                error={formErrors && formErrors['tradingName'] && true}
                                                                helperText={formErrors && formErrors['tradingName']}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <AutoCompleteSelect 
                                                                options={[
                                                                    {value: 'Limited Company', label: 'Limited Company'},
                                                                    {value: 'Public Limited Company', label: 'Public Limited Company'},
                                                                    {value: 'Sole Trader', label: 'Sole Trader'},
                                                                    {value: 'Partnership', label: 'Partnership'},
                                                                    {value: 'Consumer', label: 'Consumer'},
                                                                    {value: 'Other', label: 'Other'}
                                                                ]} 
                                                                label='Company Legal Entity'
                                                                value={formData.legalStatus}
                                                                onChange={v => this.handleSelectChange('legalStatus', v)}
                                                                error={formErrors && formErrors['legalStatus'] && true}
                                                                errorText={formErrors && formErrors['legalStatus']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="companyRegistrationNumber"
                                                                name="companyRegistrationNumber"
                                                                label="Company Registration Number"
                                                                value={formData.companyRegistrationNumber}
                                                                error={formErrors && formErrors['companyRegistrationNumber'] && true}
                                                                helperText={formErrors && formErrors['companyRegistrationNumber']}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <AutoCompleteSelect 
                                                                options={lists.types}
                                                                label='Account Type *'
                                                                value={formData.supplierType}
                                                                onChange={v => this.handleSelectChange('supplierType', v)}
                                                                error={formErrors?.['supplierType'] && true}
                                                                errorText={formErrors?.['supplierType']}
                                                                noClear
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="phone"
                                                                name="phone"
                                                                label="Phone Number"
                                                                value={formData.phone}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="email"
                                                                name="email"
                                                                label="E-mail Address *"
                                                                value={formData.email}
                                                                onChange={this.handleChange}
                                                                error={formErrors && formErrors['email'] && true}
                                                                helperText={formErrors && formErrors['email']}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="website"
                                                                name="website"
                                                                label="Website"
                                                                value={formData.website}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" gutterBottom>
                                                                Finance
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="accountNumber"
                                                                name="accountNumber"
                                                                label="Account Number *"
                                                                value={formData.accountNumber}
                                                                error={formErrors?.['accountNumber'] && true}
                                                                helperText={formErrors?.['accountNumber']}
                                                                onChange={this.handleChangeUc}
                                                                margin="none"
                                                                inputProps={{
                                                                    maxLength: 8
                                                                }}
                                                                fullWidth
                                                                disabled={id}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <FormControl fullWidth>
                                                                <AutoCompleteSelect 
                                                                    options={lists.paymentTerms}
                                                                    label='Payment Terms'
                                                                    value={formData.paymentTerm}
                                                                    onChange={v => this.handleSelectChange('paymentTerm', v)}
                                                                    error={formErrors?.['paymentTerm'] && true}
                                                                    errorText={formErrors?.['paymentTerm']}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="companyVATNumber"
                                                                name="companyVATNumber"
                                                                label="VAT Number"
                                                                value={formData.companyVATNumber}
                                                                error={formErrors && formErrors['companyVATNumber'] && true}
                                                                helperText={formErrors && formErrors['companyVATNumber']}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <FormControl fullWidth>
                                                                <AutoCompleteSelect 
                                                                    options={lists.vat}
                                                                    label='VAT Rate *'
                                                                    value={formData.vatType}
                                                                    onChange={v => this.handleSelectChange('vatType', v)}
                                                                    error={formErrors?.['vatType'] && true}
                                                                    errorText={formErrors?.['vatType']}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">
                                                                Account Settings
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch color="primary" checked={formData.poRequired} onChange={this.handleCheckedChange} value="1" name="poRequired" />
                                                                }
                                                                label={<Typography variant="body2" className="fw-400">PO Required</Typography>}
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch color="primary" checked={formData.shipHere} onChange={this.handleCheckedChange} value="1" name="shipHere" />
                                                                }
                                                                label={<Typography variant="body2" className="fw-400">Ship Here Preferred</Typography>}
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch color="primary" checked={formData.sendDespatchNote} onChange={this.handleCheckedChange} value="1" name="sendDespatchNote" />
                                                                }
                                                                label={<Typography variant="body2" className="fw-400">E-mail Despatch Notes (On Confirmation)</Typography>}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">
                                                                Purchase Orders
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} lg={formData.cutOffVaries === 0 ? 4 : 6}>
                                                                    <TextField
                                                                        id="minOrderValue"
                                                                        name="minOrderValue"
                                                                        label="Minimum Order Value (Net)"
                                                                        value={formData.minOrderValue}
                                                                        error={formErrors && formErrors['minOrderValue'] && true}
                                                                        helperText={formErrors && formErrors['minOrderValue']}
                                                                        onChange={this.handleNumberChange}
                                                                        margin="none"
                                                                        onBlur={this.handleFloat}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={formData.cutOffVaries === 0 ? 4 : 6}>
                                                                    <AutoCompleteSelect 
                                                                        options={lists.leadTimes} 
                                                                        label='Standard Lead Time'
                                                                        value={formData.leadTime}
                                                                        onChange={v => this.handleSelectChange('leadTime', v)}
                                                                        error={formErrors && formErrors['leadTime'] && true}
                                                                        errorText={formErrors && formErrors['leadTime']}
                                                                        noDefaultSort
                                                                        noClear
                                                                        noMargin
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={formData.cutOffVaries === 0 ? 4 : 12}>
                                                                    {(formData.cutOffVaries === 0 && (
                                                                        <TextField
                                                                            type="time"
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            name="mon"
                                                                            label="PO Cut Off Time"
                                                                            value={formData.cutOffData?.mon}
                                                                            error={formErrors && formErrors['cutOffData|mon'] && true}
                                                                            helperText={formErrors && formErrors['cutOffData|mon']}
                                                                            onChange={this.handleCutOffAllChange}
                                                                            fullWidth
                                                                        />
                                                                    )) || (
                                                                        <Grid item xs={12}>
                                                                            <Grid container spacing={1} alignItems='center'>
                                                                                <Grid item style={{width: '20%'}}>
                                                                                    <TextField
                                                                                        type="time"
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        name="mon"
                                                                                        label="Monday"
                                                                                        value={formData.cutOffData?.mon}
                                                                                        error={formErrors && formErrors['cutOffData|mon'] && true}
                                                                                        helperText={formErrors && formErrors['cutOffData|mon']}
                                                                                        onChange={this.handleCutOffChange}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item style={{width: '20%'}}>
                                                                                    <TextField
                                                                                        type="time"
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        name="tue"
                                                                                        label="Tuesday"
                                                                                        value={formData.cutOffData?.tue}
                                                                                        error={formErrors && formErrors['cutOffData|tue'] && true}
                                                                                        helperText={formErrors && formErrors['cutOffData|tue']}
                                                                                        onChange={this.handleCutOffChange}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item style={{width: '20%'}}>
                                                                                    <TextField
                                                                                        type="time"
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        name="wed"
                                                                                        label="Wednesday"
                                                                                        value={formData.cutOffData?.wed}
                                                                                        error={formErrors && formErrors['cutOffData|wed'] && true}
                                                                                        helperText={formErrors && formErrors['cutOffData|wed']}
                                                                                        onChange={this.handleCutOffChange}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item style={{width: '20%'}}>
                                                                                    <TextField
                                                                                        type="time"
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        name="thu"
                                                                                        label="Thursday"
                                                                                        value={formData.cutOffData?.thu}
                                                                                        error={formErrors && formErrors['cutOffData|thu'] && true}
                                                                                        helperText={formErrors && formErrors['cutOffData|thu']}
                                                                                        onChange={this.handleCutOffChange}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item style={{width: '20%'}}>
                                                                                    <TextField
                                                                                        type="time"
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        name="fri"
                                                                                        label="Friday"
                                                                                        value={formData.cutOffData?.fri}
                                                                                        error={formErrors && formErrors['cutOffData|fri'] && true}
                                                                                        helperText={formErrors && formErrors['cutOffData|fri']}
                                                                                        onChange={this.handleCutOffChange}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>  
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={formData.cutOffVaries} onChange={this.handleCheckedChange} value="1" name="cutOffVaries" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">PO Cut Off Times Vary By Day</Typography>}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                Copy Purchase Order E-mail Addresses
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Tooltip title="Purchase order submissions by e-mail will be copied to these e-mail addresses">
                                                                <span>
                                                                    <FAIcon type="solid" icon="info-circle" size={12.5} />
                                                                </span>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                size="small"
                                                                variant="text"
                                                                onClick={() => this.handleDialogToggle('poEmails', 'supplierPoEmails', -1)}
                                                            >
                                                                <FAIcon icon="plus" size={15} button />
                                                                Add CC
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {(!_.isEmpty(formData.supplierPoEmails) && (
                                                                <Box pt={1}>
                                                                    <DataTable  
                                                                        config={{
                                                                            key: 'rowIdx',
                                                                            alternatingRowColours: true,
                                                                            pagination: false,
                                                                            inline: true,
                                                                            options: {
                                                                                dataRef: true,
                                                                            }
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading: 'E-mail Address',
                                                                                field: rowData => rowData.emailAddress,
                                                                                dataRef: 'emailAddress',
                                                                                important: true,
                                                                                main: true
                                                                            },
                                                                            {
                                                                                actions: rowData => {
                                                                                    return [
                                                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('poEmails', 'supplierPoEmails', rowData.rowIdx)},
                                                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('supplierPoEmails', rowData.rowIdx)}
                                                                                    ]
                                                                                }
                                                                            }
                                                                        ]}
                                                                        rows={formData.supplierPoEmails}
                                                                    />
                                                                </Box>
                                                            )) || (
                                                                <Alert severity="success" variant="outlined">
                                                                    <strong>None</strong>
                                                                </Alert>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">
                                                                Default Carriage
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4}>
                                                            <TextField
                                                                name="focCarr"
                                                                label="Free Delivery Threshold (Net)"
                                                                onChange={this.handleNumberChange}
                                                                value={formData.focCarr}
                                                                margin="none"
                                                                onBlur={this.handleFloat}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                                }}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} lg={6}>
                                                                    <AutoCompleteSelect 
                                                                        options={_.map(formData.supplierCarriage, (el, idx) => _.assign({label: `${el.courier} ${el.service} £${el.cost}`, value: idx, disabled: formData.defFocSuppCarr === idx}))} 
                                                                        label='Default Delivery Method'
                                                                        value={formData.defSuppCarr}
                                                                        onChange={v => this.handleSelectChange('defSuppCarr', v)}
                                                                        noDefaultSort
                                                                    />
                                                                </Grid>
                                                                {formData.focCarr !== "0.00" && (
                                                                    <Grid item xs={12} lg={6}>
                                                                        <AutoCompleteSelect
                                                                            options={_.map(formData.supplierCarriage, (el, idx) => _.assign({label: `${el.courier} ${el.service} £${el.cost}`, value: idx, disabled: formData.defSuppCarr === idx}))} 
                                                                            label='Default FOC Delivery Method'
                                                                            value={formData.defFocSuppCarr}
                                                                            onChange={v => this.handleSelectChange('defFocSuppCarr', v)}
                                                                            noDefaultSort
                                                                        />
                                                                    </Grid>  
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={currentTab} index={1}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            pagination: false,
                                            alternatingRowColours: true,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="exclamation-circle" size={25} className="textError mr-1" />Please add a <span className="linkUnderline" onClick={() => this.handleDialogToggle('addresses', 'tradingAddresses', -1)}>trading address</span> for this supplier</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <>
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            variant="text"
                                                            ref={this.addAddressBtn}
                                                            onClick={this.handleAddAddressMenuToggle}
                                                            aria-controls={displayAddAddressMenu ? 'addBtnMenu' : undefined}
                                                            aria-expanded={displayAddAddressMenu ? 'true' : undefined}
                                                            aria-haspopup="menu"
                                                        >
                                                            <FAIcon icon="plus-circle" size={15} button />
                                                            Add New Address
                                                        </Button>
                                                        <Menu 
                                                            id="addBtnMenu"
                                                            anchorEl={this.addAddressBtn.current}
                                                            open={displayAddAddressMenu}
                                                            onClose={this.handleAddAddressMenuToggle}
                                                            style={{marginTop: 50}}
                                                        >
                                                            {(_.isEmpty(formData.tradingAddresses) && (
                                                                <MenuItem onClick={() => this.handleDialogToggle('addresses', 'tradingAddresses', -1)}>
                                                                    <ListItemIcon>
                                                                        <FAIcon type="light" icon="briefcase" size={15} button noMargin />
                                                                    </ListItemIcon>
                                                                    <ListItemText>
                                                                        <Typography variant="caption">
                                                                            Trading Address
                                                                        </Typography>
                                                                    </ListItemText>
                                                                </MenuItem>
                                                            )) || (
                                                                <>
                                                                    {_.isEmpty(formData.registeredAddresses) && (
                                                                        <MenuItem onClick={() => this.handleDialogToggle('addresses', 'registeredAddresses', -1)}>
                                                                            <ListItemIcon>
                                                                                <FAIcon type="light" icon="briefcase" size={15} button noMargin />
                                                                            </ListItemIcon>
                                                                            <ListItemText>
                                                                                <Typography variant="caption">
                                                                                    Registered Address
                                                                                </Typography>
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    )}
                                                                    {_.isEmpty(formData.registeredAddresses) && (
                                                                        <MenuItem onClick={() => this.handleDialogToggle('addresses', 'accountsAddresses', -1)}>
                                                                            <ListItemIcon>
                                                                                <FAIcon type="light" icon="coins" size={15} button noMargin />
                                                                            </ListItemIcon>
                                                                            <ListItemText>
                                                                                <Typography variant="caption">
                                                                                    Accounts Address
                                                                                </Typography>
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    )}
                                                                    <MenuItem onClick={() => this.handleDialogToggle('addresses', 'deliveryAddresses', -1)}>
                                                                        <ListItemIcon>
                                                                            <FAIcon type="light" icon="box-check" size={15} button noMargin />
                                                                        </ListItemIcon>
                                                                        <ListItemText>
                                                                            <Typography variant="caption">
                                                                                Delivery Address
                                                                            </Typography>
                                                                        </ListItemText>
                                                                    </MenuItem>
                                                                </>
                                                            )}
                                                        </Menu>
                                                    </>
                                                ),
                                                dataRef: true,
                                            },
                                            rowProps: rowData => ({
                                                className: (formErrors?.[`${rowData.type}|addressOne|${rowData.idx}`] || formErrors?.[`${rowData.type}|town|${rowData.idx}`] || formErrors?.[`${rowData.type}|postcode|${rowData.idx}`]) ? 'textError fw-400' : undefined
                                            })
                                        }}
                                        columns={[
                                            {
                                                heading: '',
                                                field: rowData => (
                                                    ((formErrors?.[`${rowData.type}|addressOne|${rowData.idx}`] || formErrors?.[`${rowData.type}|town|${rowData.idx}`] || formErrors?.[`${rowData.type}|postcode|${rowData.idx}`]) && (
                                                        <FAIcon type="solid" icon="exclamation-triangle" className="textError" />
                                                    )) || (
                                                        <FAIcon icon={rowData.type === 'tradingAddresses' ? 'star' : 'check'} noMargin />
                                                    )
                                                ),
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Address Line 1',
                                                field: rowData => rowData?.addressOne || '-',
                                                dataRef: 'addressOne',
                                            },
                                            {
                                                heading: 'Address Line 2',
                                                field: rowData => rowData?.addressTwo || '-',
                                                dataRef: 'addressTwo',
                                            },
                                            {
                                                heading: 'Town',
                                                field: rowData => rowData?.town || '-',
                                                dataRef: 'town',
                                            },
                                            {
                                                heading: 'Postcode',
                                                field: rowData => rowData?.postcode || '-',
                                                dataRef: 'postcode',
                                            },
                                            {
                                                heading: 'County',
                                                field: rowData => lists.counties?.[_.findIndex(lists.counties, el => el.value === rowData.county)]?.label ?? '-',
                                                dataRef: 'county',
                                            },
                                            {
                                                heading: 'Country',
                                                field: rowData => lists.countries?.[_.findIndex(lists.countries, el => el.value === rowData.country)]?.label ?? '-',
                                                dataRef: 'country',
                                            },
                                            {
                                                heading: 'Type',
                                                field: rowData => rowData.t,
                                                dataRef: 't',          
                                            },
                                            {
                                                actions: rowData => ([
                                                    {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle("addresses", rowData.type, rowData.idx)},
                                                    {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove(rowData.type, rowData.idx), disabled: rowData.id, hideIfDisabled: true}
                                                ])
                                            }
                                        ]}
                                        rows={
                                            [
                                                ...(_.map(formData.tradingAddresses,    el => ({...el, t: 'Trading',    type: 'tradingAddresses'}))), 
                                                ...(_.map(formData.registeredAddresses, el => ({...el, t: 'Registered', type: 'registeredAddresses'}))), 
                                                ...(_.map(formData.accountsAddresses,   el => ({...el, t: 'Accounts',   type: 'accountsAddresses'}))), 
                                                ...(_.map(formData.deliveryAddresses,   el => ({...el, t: 'Delivery',   type: 'deliveryAddresses'}))), 
                                            ]
                                        }
                                    />
                                </Paper>
                            </TabPanel>
                            
                            <TabPanel value={currentTab} index={2}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            pagination: false,
                                            alternatingRowColours: true,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="exclamation-circle" size={25} className="textWarning mr-1" />Please add a <span className="linkUnderline" onClick={() => this.handleDialogToggle('contacts', 'supplierContacts', -1)}>primary contact</span> for this supplier</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => this.handleDialogToggle('contacts', 'supplierContacts', -1)}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Add New Contact
                                                    </Button>
                                                ),
                                                dataRef: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Primary',
                                                field: rowData => <FAIcon type="thin" icon={rowData.primaryContact ? 'check' : 'times'} noMargin />,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Accounts',
                                                field: rowData => <FAIcon type="thin" icon={rowData.accounts ? 'check' : 'times'} noMargin />,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Name',
                                                field: rowData => `${rowData?.firstName ?? ''} ${rowData?.lastName ?? ''}`,
                                                dataRef: 'firstName',
                                                important: true,
                                                main: true,
                                            },
                                            {
                                                heading: 'Position',
                                                field: rowData => rowData?.position || 'Customer Contact',
                                                dataRef: 'position',
                                            },
                                            {
                                                heading: 'Telephone',
                                                field: rowData => rowData?.telephone || '-',
                                                dataRef: 'telephone',
                                            },
                                            {
                                                heading: 'Mobile',
                                                field: rowData => rowData?.mobile || '-',
                                                dataRef: 'mobile',
                                            },
                                            {
                                                heading: 'Email',
                                                field: rowData => rowData?.email?.length > 0 ? <Link href={`mailto:${rowData.email}`}>{`${rowData.email}`}</Link> : '-',
                                                dataRef: 'email',
                                            },
                                            {
                                                heading: 'GDPR Opt Out',
                                                field: rowData => !rowData.gdprOptOut, //inverted as db is opt in
                                                dataRef: 'gdprOptOut',
                                                fieldFormat: 'boolean',
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'Mailshot Bar',
                                                field: rowData => rowData.mailshotBar,
                                                dataRef: 'mailshotBar',
                                                fieldFormat: 'boolean',
                                                alignment: 'center'
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('contacts', 'supplierContacts', rowData.rowIdx)},
                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('supplierContacts', rowData.rowIdx), disabled: id, hideIfDisabled: true}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={formData.supplierContacts}
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={currentTab} index={3}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            alternatingRowColours: true,
                                            pagination: false,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="check-circle" size={25} className="textSuccess mr-1" />No aliases have been added for this supplier</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => this.handleDialogToggle('aliases', 'supplierAliases', -1)}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Add New Alias
                                                    </Button>
                                                ),
                                                dataRef: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Company Name',
                                                field: rowData => rowData.name,
                                                dataRef: 'name',
                                                important: true,
                                                main: true,
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('aliases', 'supplierAliases', rowData.rowIdx)},
                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('supplierAliases', rowData.rowIdx), disabled: rowData.id, hideIfDisabled: true}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={formData.supplierAliases}
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={currentTab} index={4}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            alternatingRowColours: true,
                                            pagination: false,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="check-circle" size={25} className="textSuccess mr-1" />No purchase order carriage options have been added for this supplier</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => this.handleDialogToggle('carriage', 'supplierCarriage', -1)}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Add New Carriage Method
                                                    </Button>
                                                ),
                                                dataRef: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Service',
                                                field: rowData => `${rowData.courier} ${rowData.service} ${rowData.rowIdx === formData.defSuppCarr || rowData.rowIdx === formData.defFocSuppCarr ? '(Default)' : ''}`,
                                                dataRef: 'courier',
                                                important: true,
                                                main: true,
                                            },
                                            {
                                                heading: 'Cost',
                                                field: rowData => rowData.cost,
                                                fieldFormat: 'currency',
                                                dataRef: 'cost',
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('carriage', 'supplierCarriage', rowData.rowIdx)},
                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('supplierCarriage', rowData.rowIdx), disabled: rowData.id, hideIfDisabled: true}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={formData.supplierCarriage}
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={currentTab} index={5}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            alternatingRowColours: true,
                                            pagination: false,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="check-circle" size={25} className="textSuccess mr-1" />No default product discounts have been added for this supplier</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => this.handleDialogToggle('discounts', 'supplierDiscounts', -1)}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Add New Discount
                                                    </Button>
                                                ),
                                                dataRef: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Name',
                                                field: rowData => rowData.name,
                                                dataRef: 'name',
                                                important: true,
                                                main: true,
                                            },
                                            {
                                                heading: 'Percentage',
                                                field: rowData => rowData.percent,
                                                fieldFormat: 'percentage:2',
                                                dataRef: 'percent',
                                            },
                                            {
                                                heading: 'Products Assigned',
                                                field: rowData => rowData.products_count ?? 0,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('discounts', 'supplierDiscounts', rowData.rowIdx)},
                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('supplierDiscounts', rowData.rowIdx), disabled: rowData.id, hideIfDisabled: true}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={formData.supplierDiscounts}
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={currentTab} index={6}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            alternatingRowColours: true,
                                            pagination: false,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="check-circle" size={25} className="textSuccess mr-1" />No brands have been added for this supplier</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => this.handleDialogToggle('brands', 'supplierBrands', -1)}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Add New Brand
                                                    </Button>
                                                ),
                                                dataRef: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Brand Name',
                                                field: rowData => rowData.name,
                                                dataRef: 'name',
                                                important: true,
                                                main: true,
                                            },                                            
                                            {
                                                heading: 'Products Assigned',
                                                field: rowData => rowData.c ?? 0,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('brands', 'supplierBrands', rowData.rowIdx)},
                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('supplierBrands', rowData.rowIdx), disabled: rowData.id, hideIfDisabled: true}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={formData.supplierBrands}
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={currentTab} index={7}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            alternatingRowColours: true,
                                            pagination: false,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="check-circle" size={25} className="textSuccess mr-1" />No product ranges have been added for this supplier</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => this.handleDialogToggle('ranges', 'supplierRanges', -1)}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Add New Product Range
                                                    </Button>
                                                ),
                                                dataRef: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Range Name',
                                                field: rowData => rowData.name,
                                                dataRef: 'name',
                                                important: true,
                                                main: true,
                                            },
                                            {
                                                heading: 'Products Assigned',
                                                field: rowData => rowData.c ?? 0,
                                                sizeToContent: true,
                                                alignment: 'right'
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('ranges', 'supplierRanges', rowData.rowIdx)},
                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('supplierRanges', rowData.rowIdx), disabled: rowData.id, hideIfDisabled: true}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={formData.supplierRanges}
                                    />
                                </Paper>
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12} lg={2} className={`${classes.side} h-100`}>
                            <Grid container className={`${classes.relative} h-100`}>
                                <Grid item xs={12}>
                                    <Tabs
                                        color="primary"
                                        onChange={this.handleTabChange}
                                        orientation="vertical"
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none'
                                            }
                                        }}
                                        value={currentTab}
                                    >
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={0} 
                                            label="Details" 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={1}
                                            label="Addresses" 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={2} 
                                            label="Contacts" 
                                        />
                                        <Divider />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={3} 
                                            label={(
                                                <Grid container spacing={1} justify="center">
                                                    <Grid item>
                                                        <Box ml={3.5}>
                                                            Aliases
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Aliases allow you specify additional company names that are searchable">
                                                            <span>
                                                                <FAIcon type="solid" icon="info-circle" size={12.5} />
                                                            </span>    
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            )} 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={4} 
                                            label="Carriage" 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={5} 
                                            label="Discounts"
                                        />
                                        <Divider />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={6} 
                                            label="Brands" 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={7} 
                                            label="Ranges" 
                                        />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12} className={`${classes.absoluteBottom} ${id ? classes.updateCustomerTotals: classes.newCustomerTotals} w-100`}>
                                    <Box mb={2}>
                                        <Grid container spacing={3} justify="center">
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    disabled={isLoading || currentTab === 0}
                                                    onClick={() => this.handleTabChange((currentTab - 1))}
                                                    variant="text"
                                                >
                                                    <FAIcon icon="arrow-left" disabled={isLoading} size={15} button />
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.handleTabChange((currentTab + 1))}
                                                    disabled={isLoading || currentTab === 7}
                                                    variant="text"
                                                >
                                                    <FAIcon icon="arrow-right" size={15} button />
                                                    Next
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} className="pt-0">
                                                <Grid container spacing={3} justify="center">
                                                    <Grid item>
                                                        <Button 
                                                            onClick={() => this.props.deployConfirmation(`Are you sure you want to ${id ? 'update' : 'create'} this supplier account?`, this.handleSubmit)}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isLoading}
                                                            fullWidth
                                                        >
                                                            <FAIcon icon="check" size={15} buttonPrimary />
                                                            {id ? `Update` : `Add`} Supplier
                                                        </Button>
                                                        {id && (
                                                            <Box mt={1.25} align='center'>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => this.props.deployConfirmation(`Are you sure you want to cancel changes?`, () => this.props.history.push(`/suppliers/${id}`))}
                                                                    variant="text"
                                                                >
                                                                    <FAIcon icon="times" size={15} button />
                                                                    Cancel
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {dialog.addresses.open && (
                            <AddressDialog
                                countyList={lists.counties}
                                countryList={lists.countries}
                                open={dialog.addresses.open}
                                idx={dialog.addresses.idx}
                                formData={(formData[dialog.addresses.fieldName] && formData[dialog.addresses.fieldName][dialog.addresses.idx]) ? formData[dialog.addresses.fieldName][dialog.addresses.idx] : initialState().formDefaults.address}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                                title={dialog.addresses.fieldName === 'tradingAddresses' ? 'Trading' : (dialog.addresses.fieldName === 'registeredAddresses' ? 'Registered' : (dialog.addresses.fieldName === 'accountsAddresses' ? 'Accounts' : 'Delivery'))}
                            />
                        )}
                        {dialog.aliases.open && (
                            <AliasDialog
                                open={dialog.aliases.open}
                                idx={dialog.aliases.idx}
                                formData={(formData[dialog.aliases.fieldName] && formData[dialog.aliases.fieldName][dialog.aliases.idx]) ? formData[dialog.aliases.fieldName][dialog.aliases.idx] : initialState().formDefaults.alias}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                            />
                        )}
                        {dialog.brands.open && (
                            <BrandDialog
                                open={dialog.brands.open}
                                idx={dialog.brands.idx}
                                formData={(formData[dialog.brands.fieldName] && formData[dialog.brands.fieldName][dialog.brands.idx]) ? formData[dialog.brands.fieldName][dialog.brands.idx] : initialState().formDefaults.brand}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                            />
                        )}
                        {dialog.carriage.open && (
                            <CarriageDialog
                                open={dialog.carriage.open}
                                idx={dialog.carriage.idx}
                                formData={(formData[dialog.carriage.fieldName] && formData[dialog.carriage.fieldName][dialog.carriage.idx]) ? formData[dialog.carriage.fieldName][dialog.carriage.idx] : initialState().formDefaults.carriage}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                            />
                        )}
                        {dialog.contacts.open && (
                            <ContactDialog
                                open={dialog.contacts.open}
                                idx={dialog.contacts.idx}
                                firstContact={_.isEmpty(formData.supplierContacts)}
                                formData={(formData[dialog.contacts.fieldName] && formData[dialog.contacts.fieldName][dialog.contacts.idx]) ? formData[dialog.contacts.fieldName][dialog.contacts.idx] : initialState().formDefaults.contact}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                                positionList={lists.positions}
                            />
                        )}
                        {dialog.discounts.open && (
                            <DiscountDialog
                                open={dialog.discounts.open}
                                idx={dialog.discounts.idx}
                                formData={(formData[dialog.discounts.fieldName] && formData[dialog.discounts.fieldName][dialog.discounts.idx]) ? formData[dialog.discounts.fieldName][dialog.discounts.idx] : initialState().formDefaults.discount}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                            />
                        )}
                        {dialog.ranges.open && (
                            <RangeDialog
                                open={dialog.ranges.open}
                                idx={dialog.ranges.idx}
                                formData={(formData[dialog.ranges.fieldName] && formData[dialog.ranges.fieldName][dialog.ranges.idx]) ? formData[dialog.ranges.fieldName][dialog.ranges.idx] : initialState().formDefaults.range}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                            />
                        )}
                        {dialog.poEmails.open && (
                            <InvoiceEmailDialog
                                forPurchaseOrder
                                open={dialog.poEmails.open}
                                idx={dialog.poEmails.idx}
                                formData={(formData[dialog.poEmails.fieldName] && formData[dialog.poEmails.fieldName][dialog.poEmails.idx]) ? formData[dialog.poEmails.fieldName][dialog.poEmails.idx] : initialState().formDefaults.email}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                            />
                        )}
                    </>
                )}
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, yes, no = false) => dispatch(deployConfirmation(message, yes, no)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    closeDialog: () => dispatch(closeDialog()),
    deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen)),
    clearPersistenceKey: (key) => dispatch(clearPersistenceKey(key))
})

export default connect(null, mapDispatchToProps)(withRouter(withStyles(Form)(SupplierForm)));