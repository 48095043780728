import { createMuiTheme } from '@material-ui/core/styles';
const Theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#ef3340',
        },
        error: {
            main: '#ef3340'
        }
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                padding: '24px 24px 8px 24px'
            }
        },
        MuiAvatar: {
            fallback: {
                display: 'none'
            }
        },
        MuiFormHelperText: {
            root: {
                marginTop: 0,
                marginBottom: '16px!important',
                color: '#000!important'
            }
        },
        MuiInputLabel: {
            root: {
                color: '#fff'
            }
        },
        MuiCheckbox: {
            root: {
                color: '#ef3340'
            }
        },
        MuiInput: {
            root: {
                marginTop: 16,
                marginBottom: 16,
                color: '#fff'
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid #eee'
                },
                '&:after': {
                    borderBottom: '2px solid #ef3340'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #ef3340'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: '#ef3340!important'
                }
            },
            notchedOutline: {
                borderColor: '#fff',
            },
            input: {
                color: '#fff!important'
            },
            disabled: {},
            focused: {},
            error: {},
        },
        MuiTypography: {
            h6: {
                color: '#fff'
            },
            caption: {
                color: '#fff'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#000'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '12px 24px 24px 24px'
            }
        },
    }
});
export default Theme;