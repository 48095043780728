import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const AccCodeHistory = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/accountCodeHistory',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.sgr,
                        dataRef: 'sgr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Date / Time',
                        field: rowData => rowData.d,
                        fieldFormat: 'datetime',
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Previous Code',
                        field: rowData => rowData.pcac,
                        dataRef: 'pcac',
                        alignment: 'right',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'New Code',
                        field: rowData => rowData.ncac,
                        dataRef: 'ncac',
                        alignment: 'right',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Changed By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.cb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'cb',
                        important: true,
                        sizeToContent: true,
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'acc-code-history',
                noResultsText: 'Sorry, no account code history is available to view',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Customers'],
                pdf: true,
                persistenceId: 'accCodeHistory',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: true,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Account Code History Report'
            }}
        />
    )
}

export default AccCodeHistory;