import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';

const DeliverySelectionDialog = ({action, handleSubmit, order}) => {

    const [ odId, setOdId ] = useState(null);

    const [ deliveries, setDeliveries ] = useState([]);

    const [ isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleSelectDelivery = id => {
        setOdId(id);
    }
    
    const handleClose = () => {
        dispatch(closeDialog());
    }

    const handleSubmitForm = () => {
        setIsLoading(true);
        handleSubmit(action, odId);
    }

    const welcomeText = () => {
        switch(action) {
            case 'cancelDespatch':
                return 'Cancel Despatch'
            case 'manualDespatch':
                return 'Manual Despatch'
            case 'regenerateInvoicePdf':
                return 'Re-generate Order Delivery Invoice PDF'
            default: 
                return 'Invalid action type - this will not work... hmm :|';
        }
    }

    const descriptionText = () => {
        switch(action) {
            case 'cancelDespatch':
                return 'This will revert a despatched delivery back to Awaiting Supplier / Awaiting Despatch (depending on the fulfilment method). Linked Purchase Orders will also be reverted to the appropriate status with a check date of today for further action.'
            case 'manualDespatch':
                return 'This is useful if a delivery needs to be put into despatched status manually. Note: Ship here PODs must be marked as delivered prior to being eligible for manual despatch.'
            case 'regenerateInvoicePdf':
                return 'This is useful if some order details have been updated in the database since the invoice was generated.'
            default: 
                return '';
        }
    }

    const getExpectedStatus = () => {
        switch(action) {
            case 'cancelDespatch':
                return ['Despatched']
            case 'manualDespatch':
                return ['Awaiting Allocation', 'Awaiting Stock', 'Awaiting Picking', 'Awaiting Check', 'Awaiting Technical', 'Awaiting Despatch', 'Ready for Collection']
            case 'regenerateInvoicePdf':
                return ['Invoiced']
            default: 
                return '';
        }
    }

    useEffect(() => {
        
        const newDeliveries = [];
        
        const status = getExpectedStatus();
        
        _.forEach(order.deliveries, od => {
            if(!status || (status && status.includes(od.od_status))) {
                if(action === 'manualDespatch') {
                    if(od.pod && od.pod.pod_delivered === 0) {
                        return
                    }
                }
                newDeliveries.push(_.assign({
                    label: `${od.od_reference}/${od.od_idx} (${od.od_status})`,
                    value: od.od_id
                }));
            }
        })

        setDeliveries(newDeliveries);

    }, []); /*eslint-disable-line*/
    
    return (
        <Box mt={1}>
            {(isLoading && (
                <LoadingCircle />
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box className="bg-light-error" p={2} borderRadius={4}>
                            <Typography variant="body2" gutterBottom>
                                <strong>{welcomeText()}</strong>
                            </Typography>
                            <Typography variant="body2">
                                {descriptionText()}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {(_.isEmpty(deliveries) && (
                            <Box className="content-light-grey" p={2}>
                                <Typography variant="body1">
                                    <FAIcon icon="exclamation-circle" type="light" size={17.5}/>
                                    No deliveries are eligible for this action, please contact Crushed Ice for assistance.
                                </Typography>
                            </Box>  
                        )) || (
                            <>
                                <Typography variant="body1" paragraph>
                                    Please select the affected delivery:
                                </Typography>
                                <List disablePadding style={{border: '1px solid #ddd'}}>
                                    {_.map(deliveries, (od, idx) => (
                                        <ListItem 
                                            key={idx} 
                                            role={undefined}
                                            dense
                                            button 
                                            style={{background: odId === od.value ? '#eee' : '#fafafa'}}
                                            onClick={() => handleSelectDelivery(od.value)}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    color="primary"
                                                    checked={odId === od.value}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={od.label} />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Button
                                    color="primary"
                                    variant="text"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    <FAIcon icon="times" button size={15} />
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Button
                                    className={`btn ${odId === null || _.isEmpty(deliveries) ? 'btn-disabled' : 'btn-success'}`}
                                    color="primary"
                                    fullWidth
                                    onClick={handleSubmitForm}
                                    disabled={!odId || _.isEmpty(deliveries)}
                                >
                                    <FAIcon icon="check" button size={15} />
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Box>
    );

}

export default DeliverySelectionDialog;