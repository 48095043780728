import React from 'react';
import { useHistory } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const RagTile = ({content, onClick, rag, title}) => {

    const history = useHistory();
    
    const handleClick = onClick => {
        if(typeof onClick === "function") {
            onClick();
        } else {
            history.push(onClick);
        }
    }
    
    return (
        <Grid container spacing={2} alignItems='center' className={`${onClick ? ' link' : ''}`} onClick={() => onClick && handleClick(onClick)}>      
            <Grid item>
                <FAIcon 
                    type="thin" 
                    icon={rag === 'green' ? 'check-circle' : (rag === 'amber' ? 'exclamation-circle' : 'times-circle')}
                    style={{color: rag === 'green' ? '#4CAF50' : (rag === 'amber' ? '#FF9800' : '#f44336')}} 
                    size={35} 
                    noMargin 
                />
            </Grid>
            <Grid item xs>
                <Grid container alignItems='center'>
                    <Grid item xs={12} align='left'>
                        <Typography variant='h5' className="textDefault fw-300">
                            {content} {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>  
        </Grid>
    )

}

export default RagTile;