import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Prompt } from 'react-router';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ShippingAddress from 'Components/Purchases/PurchaseOrderForm/ShippingAddress';
import Button from 'Components/Purchases/PurchaseOrderForm/Button';
import Notes from 'Components/Purchases/PurchaseOrderForm/Notes';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Product from 'Components/Purchases/PurchaseOrderForm/Product';
import Products from 'Components/Purchases/PurchaseOrderForm/Products';
import Preview from 'Components/Purchases/PurchaseOrderForm/Preview';
import Shipping from 'Components/Purchases/PurchaseOrderForm/Shipping';
import SupplierSearchSelector from 'Components/Suppliers/Misc/SupplierSearchSelector';
import Tabs from 'Components/Purchases/PurchaseOrderForm/Tabs';
import Textarea from 'Components/Common/Inputs/Textarea';
import Totals from 'Components/Purchases/PurchaseOrderForm/Totals';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleChange, handleCheckedChange } from 'Functions/FormFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { currencyFormat, momentFormatDate, TabPanel } from 'Functions/MiscFunctions';
import { Paper } from '@material-ui/core';
import InsightChip from 'Components/Common/Chips/InsightChip';
import { VIEW_PAGE_OFFSET } from 'Constants';
import Form from 'Components/Common/Styles/Form';

const PurchaseOrderVariation = ({closeDialog, costChanges, currentVat, currentVatValue, deliveries, deployConfirmation, handleSetVariations, previousVat, previousVatValue, vatChange}) => {

    const initialState = () => ({
        vatPercent: previousVat,
        vatValue: previousVatValue,
        deliveries: deliveries,
        showHeadings: vatChange && !_.isEmpty(costChanges)
    })
    
    const [state, setState] = useState(initialState());

    const handleSubmit = () => {
        deployConfirmation(
            'Are you sure you want to apply selected changes?',
            () => {
                handleSetVariations(state.vatValue, state.vatPercent, state.deliveries);
                closeDialog();
            }
        )
    }

    const handleCostChange = (didx, idx, cost) => {
        let newProductRows = [...state.deliveries[didx].productRows];
        newProductRows[idx].cost = cost;
        newProductRows[idx].totalCost = (parseFloat(newProductRows[idx].quantity) * parseFloat(newProductRows[idx].cost)).toFixed(2)
        setState(state => ({
            ...state,
            deliveries: {
                ...state.deliveries,
                [didx]: {
                    ...state.deliveries[didx],
                    productRows: newProductRows
                }
            }
        }))
    }

    const handleAll = checked => {

        let newDeliveries = {...state.deliveries}

        _.each(costChanges, row => {

            const cost = checked ? row.currentCost : row.previousCost;

            let newProductRows = [...deliveries[row.didx].productRows];
            newProductRows[row.idx].cost = cost;
            newProductRows[row.idx].totalCost = (parseFloat(newProductRows[row.idx].quantity) * parseFloat(newProductRows[row.idx].cost)).toFixed(2)
            
            newDeliveries = {
                ...newDeliveries,
                [row.didx]: {
                    ...newDeliveries[row.didx],
                    productRows: newProductRows
                }
            }
        
        })

        setState(state => ({
            ...state,
            deliveries: newDeliveries,
            vatPercent: checked ? currentVat : previousVat,
            vatValue: checked ? currentVatValue : previousVatValue
        }))
    }

    const handleVatChange = vatPercent => {
        setState(state => ({
            ...state,
            vatPercent,
            vatValue: vatPercent === currentVat ? currentVatValue : previousVatValue
        }))
    }

    return (
        <Grid container spacing={3}>
            {!_.isEmpty(costChanges) && (
                <Grid item xs={12}>
                    {state.showHeadings && (
                        <Typography variant="h6" gutterBottom className="mt-3">
                            Product Cost Changes
                        </Typography>
                    )}
                    <DataTable  
                        config={{
                            key: 'idx',
                            alternatingRowColours: true,
                            responsiveImportance: true,
                            rowsPerPage: 100,
                            inline: state.showHeadings ? true : undefined
                        }}
                        columns={[
                            {
                                heading: 'Product',
                                field: rowData => (
                                    <>
                                        <Typography variant="body1" className="fw-400">
                                            {rowData.productCode}
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData.productName.substr(0,50)}{rowData.productName.length > 50 ? '...' : ''}
                                        </Typography>
                                    </>
                                ),
                                important: true,
                            },
                            {
                                heading: 'Delivery',
                                field: rowData => rowData.pod,
                                important: true,
                                sizeToContent: true
                            },
                            {
                                heading: 'Quantity',
                                field: rowData => rowData.quantity,
                                important: true,
                                alignment: 'right',
                                sizeToContent: true
                            },
                            {
                                heading: 'Previous Cost',
                                field: rowData => rowData.previousCost,
                                fieldFormat: 'currency',
                                sizeToContent: true,
                                alignment: 'right',
                                cellProps: rowData => ({
                                    className: 'textError',
                                    style: {
                                        textDecoration: state.deliveries[rowData.didx].productRows[rowData.idx].cost !== rowData.previousCost ? 'line-through' : undefined
                                    }
                                })
                            },
                            {
                                heading: 'Current Cost',
                                field: rowData => rowData.currentCost,
                                fieldFormat: 'currency',
                                sizeToContent: true,
                                alignment: 'right',
                                cellProps: rowData => ({
                                    className: 'textSuccess',
                                    style: {
                                        textDecoration: state.deliveries[rowData.didx].productRows[rowData.idx].cost !== rowData.currentCost ? 'line-through' : undefined
                                    }
                                })
                            },
                            {
                                heading: 'Update Cost?',
                                field: rowData => {
                                    const   newCost     = state.deliveries[rowData.didx].productRows[rowData.idx].cost === rowData.currentCost,
                                            cost        = newCost ? rowData.previousCost : rowData.currentCost;
                                    return (
                                        <Checkbox color="primary" checked={newCost} onChange={() => handleCostChange(rowData.didx, rowData.idx, cost)} />
                                    )
                                },
                                sizeToContent: true,
                                alignment: 'right',
                            }
                        ]}
                        rows={costChanges}
                    />
                </Grid>
            )}
            {vatChange && (
                <Grid item xs={12}>
                    {state.showHeadings && (
                        <Typography variant="h6" gutterBottom>
                            VAT Rate Change
                        </Typography>
                    )}
                    <DataTable  
                        config={{
                            alternatingRowColours: true,
                            responsiveImportance: true,
                            rowsPerPage: 100,
                            inline: state.showHeadings ? true : undefined
                        }}
                        columns={[
                            {
                                heading: 'Previous VAT',
                                field: rowData => `${rowData.previousVat}%`,
                                sizeToContent: true,
                                alignment: 'right',
                                cellProps: rowData => ({
                                    className: 'textError',
                                    style: {
                                        textDecoration: state.vatPercent !== rowData.previousVat ? 'line-through' : undefined
                                    }
                                })
                            },
                            {
                                heading: 'Current VAT',
                                field: rowData => `${rowData.currentVat}%`,
                                sizeToContent: true,
                                alignment: 'right',
                                cellProps: rowData => ({
                                    className: 'textSuccess',
                                    style: {
                                        textDecoration: state.vatPercent !== rowData.currentVat ? 'line-through' : undefined
                                    }
                                })
                            },
                            {
                                heading: 'Update VAT?',
                                field: rowData => {
                                    const   newVat  = state.vatPercent === rowData.currentVat,
                                            vat     = newVat ? rowData.previousVat : rowData.currentVat;
                                    return (
                                        <Checkbox color="primary" checked={newVat} onChange={() => handleVatChange(vat)} />
                                    )
                                },
                                alignment: 'right',
                            }
                        ]}
                        rows={[
                            {
                                previousVat,
                                currentVat,
                            }
                        ]}
                    />
                </Grid>
            )}
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <MuiButton
                            color="primary"
                            onClick={() => handleAll(false)}
                            variant="text"
                        >
                            De-select All
                        </MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton
                            color="primary"
                            onClick={() => handleAll(true)}
                            variant="text"
                        >
                            Select All
                        </MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton
                            color="primary"
                            onClick={handleSubmit}
                            variant="text"
                        >
                            Confirm
                        </MuiButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const initialState = () => ({
    alertText: null,
    carriage: [],
    carriageManual: false,
    currentTab: 0,
    supplier: {
        id: 0,
        data: {}
    },
    disableSupplierTab: false,
    existingNotes: [],
    formData: {
        supplier: null,
        suppRef: '',
        carriage: '',
        deliveryAddressNotes: '',
        subTotal: "0.00",
        carriageTotal: "0.00",
        netTotal: "0.00",
        vatTotal: "0.00",
        grossTotal: "0.00",
        notes: '',
        schedule: moment().format("YYYY-MM-DD"),
        deliveries: {},
        internalNotes: [],
        vatPercent: 0,
        vatValue: 0,
        preConfirmed: 0
    },
    formErrors: [],
    id: false,
    initialLoading: true,
    initialFormData: {
        supplier: null,
        suppRef: '',
        carriage: '',
        deliveryAddressNotes: '',
        subTotal: "0.00",
        carriageTotal: "0.00",
        netTotal: "0.00",
        vatTotal: "0.00",
        grossTotal: "0.00",
        notes: '',
        schedule: moment().format("YYYY-MM-DD"),
        deliveries: {},
        internalNotes: [],
        vatPercent: 0,
        vatValue: 0,
        preConfirmed: 0
    },
    isLoading: false,
    isLoadingPreview: false,
    lists: {
        carriage: [],
        suppliers: [],
        teams: []
    },
    products: [],
    preview: {
        pdf: false,
        numPages: null,
        pageNumber: 1
    },
    po: {},
    ui: {
        height: 0,
        contentHeight: 0
    },
    vatPercent: 0,
    vatValue: 0,
})

class PurchaseOrderIfmForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
        this.handleCheckedChange = handleCheckedChange.bind(this)
        this.poll = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            if(this.poll) {
                clearInterval(this.poll);
            }
            this.setState({
                ...initialState()
            }, () => this.loadComponentData());
        }
        if(JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if(!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if(window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    }
    
    componentWillUnmount = () => {
        if(window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
        if(this.poll) {
            clearInterval(this.poll);
        }
        this.props.closeDialog()
    }

    handlePoll = (initial = false) => {
        API.post(`/staff/my/view`, 
            { 
                po: this.state.id,
                type: 'Update'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {
                    if(!_.isEmpty(res.data) && (_.some(res.data, el => el.u === 1) || _.some(res.data, el => el.p === 1))) {
                        this.props.deploySnackBar("error", `Sorry, another user is currently modifying this purchase order`);
                        this.props.history.push(`/purchase-orders/${this.state.id}`)
                    } else {
                        if(this.state.initialLoading) {
                            this.setState({
                                initialLoading: false
                            })
                        }
                    }
                }
            }
        })
    }

    loadComponentData = () => {
        const { ui } = this.props;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        const id = this.props.supplier ? false : (this.props.id ?? this.props.match?.params?.id ?? false);
        
        this.setState({
            id,
            ui: {
                height,
                contentHeight
            }
        }, () => {
            this.getData(true);
        })
    }
    
    getData = initialLoad => {
        Promise.all([
            API.get('/suppliers/all', { 
                params: { 
                    forList: true 
                }
            }),
        ])
        .then(([result]) => {

            if(result?.data) {
                
                const   initialLoading  =   this.state.id ? true : false,
                        formData        =   {
                                                ...this.state.formData,
                                            },
                        lists           =   {
                                                ...this.state.lists,
                                                suppliers: _.map(result.data, el => (
                                                    _.assign({
                                                        value: el.v,
                                                        label: el.l,
                                                    })
                                                )),
                                                teams: this.props.teamList
                                            };

                this.setState({
                    formData,
                    initialLoading,
                    lists,
                }, () => {
                    if(initialLoad) {
                        if(this.state.id) {
                            this.getUpdateData();
                        }
                    }
                })
            }
        })
    }

    getUpdateData = () => {
        
        const {
            deploySnackBar,
            history
        } = this.props;

        const { 
            id 
        } = this.state;

        API.get(`/purchaseOrders/${id}`, { 
            params: { 
                forUpdate: true 
            }
        })
        .then(res => {
            if(res?.data) {

                if(res?.data?.po_status === 'Cancelled' || res.data?.po_status === 'Invoiced' || (res.data?.po_status === 'Despatched' && res?.data?.po_delivered === 1)) {
                    deploySnackBar("error", `This purchase order can no longer be modified as it has been ${res.data?.po_status?.toLowerCase?.()}`)
                    history.push(`/purchase-orders/${res?.data?.po_id}`)
                    return;
                }

                if(res?.data?.po_type === 'Sales Order') {
                    deploySnackBar("error", `Please modify the sales order to update this purchase order`)
                    history.push(`/purchase-orders/${res?.data?.po_id}`)
                    return;
                }

                let currentTab          = 1,
                    alertText           = res.data.po_status === 'Awaiting Confirmation' ? `This purchase order was submitted to ${res.data?.supplier?.supp_company_name} on ${momentFormatDate(res.data?.po_submission_date)}` : null,
                    disableSupplierTab  = true,
                    formData            = initialState().formData,
                    existingNotes       = res.data.notes,
                    deliveries          = {},
                    previousVat         = res.data.vat?.vat_value_percentage ?? 0,
                    previousVatValue    = res.data.vat?.vat_value_id ?? 0,
                    costChanges         = [],
                    po                  = res.data,
                    supplier            = res.data?.supplier,
                    products            = res.data?.products, 
                    carriage            = res.data?.supplier?.carriage,
                    isLoading           = false,
                    lists               = {...this.state.lists},
                    vatPercent          = parseFloat(supplier?.vat_type?.default_vat_value?.vat_value_percentage ?? 20).toFixed(2),
                    vatValue            = parseFloat(supplier?.vat_type?.default_vat_value?.vat_value_id ?? 1).toFixed(2),
                    currentVat          = vatPercent,
                    currentVatValue     = vatValue,
                    vatChange           = previousVat !== currentVat;

                if(!_.isEmpty(carriage)) {
                    lists = {
                        ...lists,
                        carriage: _.map(_.sortBy(carriage, ['supp_carr_courier', 'supp_carr_service']), el => (
                            _.assign({
                                label: `${el.supp_carr_courier} ${el.supp_carr_service} - £${el.supp_carr_cost}`,
                                value: el.supp_carr_id,
                            })
                        ))
                    }
                }

                if(!_.some(lists.carriage, el => el.value === 0)) {
                    lists.carriage.push(
                        {
                            label: 'Other - See Notes',
                            value: 0
                        }
                    )
                }

                _.each(po.deliveries, (pod, didx) => {
                    
                    let productRows = [];

                    _.each(pod.products, (product, idx) => {
                        const   productId           = product.product?.product_id ?? product.podp_product_id,
                                productName         = product.product?.product_name ?? product?.podp_product_name,
                                productCode         = product?.product_code ?? product.podp_product_code,
                                quantity            = product?.podp_qty,
                                quantityDespatch    = product?.podp_qty_despatch,
                                quantityDelivered   = product?.podp_qty_delivered,
                                quantityInvoiced    = product?.podp_qty_invoiced,
                                cost                = product?.podp_buy_price,
                                image               = product?.product_img,
                                totalCost           = product?.podp_total_buy_price,
                                productData         = product?.product ?? {},
                                previousCost        = product.podp_buy_price,
                                currentCost         = product?.product?.product_cost,
                                popId               = product?.podp_pop_id,
                                popStatus           = product?.podp_status,
                                qtyInvoiced         = product?.podp_qty_invoiced,
                                vat                 = product?.podp_total_vat;

                        productRows.push({
                            popId,
                            popStatus,
                            qtyInvoiced,
                            productId,
                            productName,
                            productCode,
                            quantity,
                            quantityDespatch,
                            quantityDelivered,
                            quantityInvoiced,
                            cost: currentCost,
                            totalCost,
                            vat,
                            image,
                            productData
                        })

                        if(cost !== currentCost) {
                            costChanges.push(
                                {
                                    pod: `#${pod.pod_reference}/${pod.pod_idx}`,
                                    didx,
                                    idx,
                                    productName,
                                    productCode,
                                    quantity,
                                    previousCost,
                                    currentCost,
                                }
                            )
                        }
                    });

                    let date            = moment(pod.pod_req_despatch_date === '0000-00-00' ? (pod.pod_despatch_date === '0000-00-00' ? pod.pod_expected_despatch_date : pod.pod_despatch_date) : pod.pod_req_despatch_date).format("YYYY-MM-DD"),
                        check           = pod.pod_check_date !== "0000-00-00" ? moment(pod.pod_check_date).format("YYYY-MM-DD") : null,
                        changed         = false;

                    deliveries = {
                        ...deliveries,
                        [didx]: {
                            ...pod,
                            changed,
                            date,
                            check,
                            productRows
                        }
                    }
                    
                });

                formData = {
                    ...initialState().formData,
                    ...formData,
                    supplier: res.data.po_supp_id,
                    carriage: res.data.po_supp_carr_id,
                    carriageTotal: res.data.po_total_carriage,
                    deliveryAddressNotes: res.data.po_delivery_address_notes,
                    notes: res.data.po_notes,
                    schedule: res.data.po_submission_date !== "0000-00-00" ? moment(res.data.po_submission_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    suppRef: res.data.po_supp_reference,
                    vatValue: previousVatValue,
                    vatPercent: previousVat,
                    deliveries
                }

                let initialFormData = formData;

                this.setState({
                    alertText,
                    currentTab,
                    carriage,
                    disableSupplierTab,
                    existingNotes,
                    formData,
                    initialFormData,
                    isLoading,
                    lists,
                    po,
                    products,
                    supplier: {
                        id: supplier.supp_id,
                        data: supplier
                    },
                    vatValue: previousVatValue,
                    vatPercent: previousVat,
                }, () => {

                    if(this.props.pageTitle) {
                        this.props.pageTitle([1, "Purchase Orders", `Update Purchase Order`, `${supplier?.supp_company_name}`, `#${res.data.po_reference}`]);
                    }

                    if(vatChange || !_.isEmpty(costChanges)) {
                        const title = (vatChange && !_.isEmpty(res.costChanges)) ? 'Product Cost and VAT Rate Changes' : (vatChange ? 'VAT Rate Change' : 'Product Cost Changes')
                        this.props.deployDialog(
                            <PurchaseOrderVariation
                                closeDialog={this.props.closeDialog}
                                costChanges={costChanges}
                                currentVat={currentVat}
                                currentVatValue={currentVatValue}
                                deployConfirmation={this.props.deployConfirmation}
                                handleSetVariations={this.handleSetVariations}
                                initialDeliveries={JSON.stringify(this.state.formData.deliveries)}
                                previousVat={previousVat}
                                previousVatValue={previousVatValue}
                                deliveries={this.state.formData.deliveries}
                                vatChange={vatChange}
                            />, 
                            false,
                            title,
                            "error",
                            "md",
                            false,
                            true
                        );
                    }

                    this.handleCalculateTotals(true);
                    
                    this.handlePoll(true);
                    this.poll = setInterval(this.handlePoll, 3000);

                });
            }
        })
    }
    
    handleTabChange = (event, tabIndex) => {
        const currentTab = !isNaN(event) ? event : tabIndex;
        if(currentTab === 0) {
            this.props.deployConfirmation("Are you sure you want to change the supplier?", this.handleSupplierReset)
        } else {
            this.handleDoTabChange(currentTab);
        }
    }   

    handleDoTabChange = currentTab => {
        const isLoading = currentTab === 4 ? true : this.state.isLoading
        this.setState({
            currentTab,
            isLoading 
        }, () => {
            if(this.state.currentTab === 4) {
                this.handleSubmitPreview();
            }
            if(this.mainContentArea && this.mainContentArea.current) 
                this.mainContentArea.current.scrollTop = 0;
        })
    }

    handleCalculateTotals = (setInitial = false) => {
        const { carriage, carriageManual, formData, supplier:{data} } = this.state;

        let subTotal = "0.00"
        let carriageTotal = "0.00"
        let netTotal = "0.00"
        let vatTotal = "0.00"
        let grossTotal = "0.00"

        _.each(formData.deliveries, pod => {
            _.each(pod.productRows, row => {
                subTotal = (parseFloat(subTotal) + parseFloat(row.totalCost)).toFixed(2)
            });
        });

        carriageTotal = parseFloat(formData.carriageTotal).toFixed(2)

        netTotal = (parseFloat(subTotal) + parseFloat(carriageTotal)).toFixed(2)
        vatTotal = ((netTotal / 100) * formData.vatPercent).toFixed(2)
        grossTotal = (parseFloat(netTotal) + parseFloat(vatTotal)).toFixed(2)

        let { initialFormData } = this.state;

        if(setInitial) {
            initialFormData = {
                ...this.state.formData,
                subTotal,    
                carriageTotal,
                netTotal,
                vatTotal,   
                grossTotal
            }
        }
        
        this.setState({
            formData: {
                ...this.state.formData,
                subTotal,    
                carriageTotal,
                netTotal,
                vatTotal,   
                grossTotal
            },
            initialFormData
        }, () => {
            if(data?.supp_foc_min_order_value !== "0.00" && !carriageManual) {
                if(parseFloat(subTotal) >= parseFloat(data?.supp_foc_min_order_value)) {
                    if(data?.supp_def_foc_supp_carr_id > 0 && formData.carriage !== data?.supp_def_foc_supp_carr_id) {
                        const idx = _.findIndex(carriage, el => el.supp_carr_id === data.supp_def_foc_supp_carr_id);
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                carriage: data.supp_def_foc_supp_carr_id,
                                carriageTotal: carriage[idx].supp_carr_cost
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "This purchase order now qualifies for free delivery, the delivery method has been updated");
                            this.handleCalculateTotals();
                        })
                    }
                } else {
                    if(formData.carriage === data?.supp_def_foc_supp_carr_id) {
                        const idx = _.findIndex(carriage, el => el.supp_carr_id === data.supp_def_supp_carr_id);
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                carriage: data?.supp_def_supp_carr_id,
                                carriageTotal: carriage[idx].supp_carr_cost
                            }
                        }, () => {
                            this.props.deploySnackBar("warning", "This purchase order no longer qualifies for free delivery, the delivery method has been updated");
                            this.handleCalculateTotals();
                        })
                    }
                }
            }
        })
    }
    
    handleDeliveryAdd = () => {
        let deliveries = {...this.state.formData.deliveries}
        const idx = _.size(deliveries);
        deliveries = {
            ...deliveries,
            [idx]: {
                pod_id: 0,
                pod_idx: (idx + 1),
                pod_status: 'New Delivery',
                pod_reference: this.state.po?.po_reference,
                pod_pdf_filename: '',
                pod_expected_despatch_date: '0000-00-00',
                pod_check_date: '0000-00-00',
                pod_confirmed_datetime: '0000-00-00',
                pod_despatch_date: '0000-00-00',
                pod_delivered: 0,
                pod_rma: 0,
                pod_issue: 0,
                pod_add_to_stock: 0,
                pod_legacy: 0,
                pod_invoice_due: 0,
                changed: true,
                date: moment().format('YYYY-MM-DD'),
                check: null,
                productRows: []
            }
        }

        this.setState({
            formData: {
                ...this.state.formData,
                deliveries
            }
        }, () => { 
            this.handleCalculateTotals()
            this.props.deploySnackBar("success", `Delivery #${this.state.po?.po_reference}/${(idx + 1)} has been generated, you can now add products to this delivery`)
        })
    }
  
    handleDeliveryDateEdit = (delivery, date) => {
        let deliveries = {...this.state.formData.deliveries}
        
        deliveries = {
            ...deliveries,
            [delivery]: {
                ...deliveries[delivery],
                changed: true,
                date: moment(date).format("YYYY-MM-DD")
            }
        }

        this.setState({
            formData: {
                ...this.state.formData,
                deliveries
            }
        }, () => this.handleCalculateTotals())
    }

    handleDeliveryCheckDateEdit = (delivery, date) => {
        let deliveries = {...this.state.formData.deliveries}
        
        deliveries = {
            ...deliveries,
            [delivery]: {
                ...deliveries[delivery],
                changed: true,
                check: moment(date).format("YYYY-MM-DD")
            }
        }

        this.setState({
            formData: {
                ...this.state.formData,
                deliveries
            }
        }, () => this.handleCalculateTotals())
    }

    handleDeliveryPreConfirmed = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                preConfirmed: this.state.formData.preConfirmed === 1 ? 0 : 1
            }
        })
    }

    handleDeliveryRemove = delivery => {
        let productRows = [...this.state.formData.deliveries[delivery]?.productRows];
        if(_.isEmpty(productRows)) {
            let deliveries = {...this.state.formData.deliveries}
            let i = 1;
            _.each(deliveries, (d, idx) => {
                if(idx === delivery) {
                    delete deliveries[delivery];
                } else {
                    deliveries = {
                        ...deliveries,
                        [idx]: {
                            ...deliveries[idx],
                            pod_idx: i
                        }
                    }
                    i++;
                }
            })
            this.setState({
                formData: {
                    ...this.state.formData,
                    changed: true,
                    deliveries
                }
            }, ()=> this.handleCalculateTotals())
        }
    }
    
    handleProductAdd = (productRow, delivery) => {
        let productRows = [...this.state.formData.deliveries[delivery]?.productRows];
        productRows.push(productRow)
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: {
                    ...this.state.formData.deliveries,
                    [delivery]: {
                        ...this.state.formData.deliveries[delivery],
                        changed: true,
                        productRows
                    }
                },
            }
        }, () => this.handleCalculateTotals())
    }
  
    handleProductEdit = (productRow, delivery, oldDelivery, oldIdx) => {

        let productRows = [...this.state.formData.deliveries[delivery]?.productRows];

        if(parseInt(delivery) !== parseInt(oldDelivery)) {

            productRows.push(productRow);

            let oldProductRows = [...this.state.formData.deliveries[oldDelivery]?.productRows];
            oldProductRows.splice(oldIdx, 1)

            this.setState({
                formData: {
                    ...this.state.formData,
                    deliveries: {
                        ...this.state.formData.deliveries,
                        [delivery]: {
                            ...this.state.formData.deliveries[delivery],
                            changed: true,
                            productRows
                        },
                        [oldDelivery]: {
                            ...this.state.formData.deliveries[oldDelivery],
                            changed: true,
                            productRows: oldProductRows
                        }
                    },
                }
            }, () => this.handleCalculateTotals())

        } else {
            
            productRows[productRow.rowIdx] = productRow;
            
            this.setState({
                formData: {
                    ...this.state.formData,
                    deliveries: {
                        ...this.state.formData.deliveries,
                        [delivery]: {
                            ...this.state.formData.deliveries[delivery],
                            changed: true,
                            productRows
                        }
                    },
                }
            }, () => this.handleCalculateTotals())
        }
    }

    handleProductRemove = (idx, delivery) => {
        let productRows = [...this.state.formData.deliveries[delivery]?.productRows];
        productRows.splice(idx, 1)
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: {
                    ...this.state.formData.deliveries,
                    [delivery]: {
                        ...this.state.formData.deliveries[delivery],
                        changed: true,
                        productRows
                    }
                },
            }
        }, ()=> this.handleCalculateTotals())
    }

    handleNoteAdd = note => {
        let internalNotes = [...this.state.formData.internalNotes];
        internalNotes.push(note)
    
        this.setState({
            formData: {
                ...this.state.formData,
                internalNotes,
            }
        }, () => this.handleCalculateTotals())
    }
  
    handleNoteEdit = note => {
        let internalNotes = [...this.state.formData.internalNotes];
        internalNotes[note.rowIdx] = note;
        this.setState({
            formData: {
                ...this.state.formData,
                internalNotes,
            }
        }, () => this.handleCalculateTotals())
    }

    handleNoteRemove = idx => {
        const internalNotes = [...this.state.formData.internalNotes]
        internalNotes.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                internalNotes
            }
        }, ()=> this.handleCalculateTotals())
    }
    
    handleSelectChange = (fieldName, selectedOption) => {
       if(fieldName === "carriage") {
            const { carriage } = this.state;
            const value = selectedOption?.value ?? "";
            const idx = _.findIndex(carriage, el => el.supp_carr_id === value);
            let carriageTotal = "0.00"
            if(idx !== -1) {
                carriageTotal = parseFloat(carriage[idx].supp_carr_cost).toFixed(2)
            }
            this.setState({
                carriageManual: true,
                formData: {
                    ...this.state.formData,
                    [fieldName]: value,
                    carriageTotal
                }
            }, () => this.handleCalculateTotals());
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: selectedOption?.value ?? ""
                }
            });
        }
    }
  
    handlePreviewPagination = offset => {
        this.setState(prevState => ({
            preview: {
                ...prevState.preview,
                pageNumber: ((prevState?.preview?.pageNumber ?? 1) + offset)
            }
        }))
    }

    handlePreviewLoad = ({numPages}) => {
        this.setState({
            preview: {
                ...this.state.preview,
                numPages 
            }
        });
    }
    
    handleSubmitPreview = () => {
        const { formData, id } = this.state;
        const resource = id ? `/purchaseOrders/${id}/ifm` : `/purchaseOrders`
        this.setState({
            isLoading: true,
            isLoadingPreview: true,
        }, () => {
            API.post(resource, { preview: true, ...formData } )
            .then(result => {                
                if(result.data.errors) {
                    let formErrors = formatValidationErrors(result.data.errors);
                    this.setState({
                        formErrors,
                        formData: {
                            ...formData,
                            supplier: (formErrors && formErrors['supplier'] && initialState().formData.supplier) || formData.supplier,
                        },
                        currentTab: 2,
                        isLoading: false
                    })
                } else {
                    if(result.data.pdf) {
                        this.setState({
                            formErrors: false,
                            isLoading: false,
                            isLoadingPreview: false,
                            preview: {
                                ...this.state.preview,
                                numPages: null,
                                pageNumber: 1,
                                pdf: result.data.pdf
                            }
                        })
                    }
                }
            })
        })
    }

    handleConfirmation = () => {
        const action = this.state.id ? "update" : "create";
        this.props.deployConfirmation(`Are you sure you want to ${action} this purchase order?`, this.handleSubmit)
    }

    handleSetVariations = (vatValue, vatPercent, deliveries) => {
        this.setState({
            formData: {
                ...this.state.formData,
                vatPercent,
                vatValue,
                deliveries
            }
        }, () => this.handleCalculateTotals())
    }

    handleSubmit = () => {
        let { formData, id, initialFormData } = this.state;
        const resource = id ? `/purchaseOrders/${id}/ifm` : `/purchaseOrders`,
              action = this.state.id ? "updated" : "created";
        this.setState({
            isLoading: true
        }, () => {
            if(action === "updated") {
                formData = {
                    ...formData,
                    initialFormData
                }
            }
            API.post(resource, formData)
            .then(result => {                
                if(result.data.errors) {
                    let formErrors = formatValidationErrors(result.data.errors);
                    this.setState({
                        formErrors,
                        formData: {
                            ...formData,
                            supplier: (formErrors && formErrors['supplier'] && initialState().formData.supplier) || formData.supplier,
                        },
                        isLoading: false
                    })
                } else {
                    this.setState({ 
                        ...initialState()
                    }, () => {
                        this.props.deploySnackBar("success", `The purchase order was ${action} successfully`)
                        this.props.history.push({
                            pathname: `/purchase-orders/${result.data.id}`,
                            state: { ifm: true }
                        })
                    })
                }
            })
        })
    }
    
    render() {        
        let   { alertText, po } = this.state;
        const { classes, deployConfirmation, history } = this.props;
        const { currentTab, disableSupplierTab, existingNotes, formData, formErrors, id, initialLoading, isLoading, isLoadingPreview, lists, preview, products, supplier, ui } = this.state;     
        
        if(po.po_ship_here_order === 0 && po.po_order_id > 0) {
            if(po.po_ifm_modified === 1) {
                alertText = `This is a back-to-back ship here purchase order which has been detached and modified - may contain additional stock items`;
            } else {
                alertText = `This is a back-to-back ship here purchase order which has been detached - you can now add additional stock lines`;
            }
        }

        if(supplier?.data?.supp_min_order_value !== "0.00") {
            if(parseFloat(formData.netTotal) < parseFloat(supplier.data.supp_min_order_value)) {
                alertText = `The minimum order value of ${currencyFormat.format(supplier.data.supp_min_order_value)} has not been met`
            }
        }

        let btnUpdateDisabled = _.isEmpty(formData.deliveries);
        if(!btnUpdateDisabled) {
            _.each(formData.deliveries, pod => {
                if(_.isEmpty(pod.productRows)) {
                    btnUpdateDisabled = true;
                }
            })
        }
        
        return (
            <Grid container className={classes.container}>
                {(initialLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Prompt
                            when={JSON.stringify(formData) !== JSON.stringify(this.state.initialFormData)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                        <Grid item className={`${classes.main} p-0`}>
                            {(isLoading && (
                                <>
                                    <LoadingCircle />
                                    {isLoadingPreview && (
                                        <Typography variant="h6" align='center'>
                                            Generating Preview
                                        </Typography>     
                                    )}    
                                </>                       
                            )) || (
                                <>
                                    {alertText && (
                                        <Alert severity="warning" variant="standard" elevation={0} className={classes.alert}>{alertText}</Alert>
                                    )}
                                    <TabPanel value={currentTab} index={0}>
                                        <Box p={3}>
                                            <SupplierSearchSelector
                                                handleAction={this.handleSupplierChange}
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1}>
                                        <Box style={{width: '100%', maxWidth: '100%'}}>
                                            <Box position='fixed' zIndex={999}> 
                                                <Product   
                                                    ifm
                                                    classes={classes}
                                                    deliveries={formData.deliveries}
                                                    handleSubmit={this.handleProductAdd}
                                                    products={products}
                                                    supplier={supplier.id}
                                                    vatPercent={formData.vatPercent}
                                                    borderRadius={0}
                                                    width={'calc(100% - 230px)'}
                                                />
                                            </Box>
                                        </Box>
                                        <Grid container style={{height: (parseInt(ui?.contentHeight ?? 0) - (alertText ? 49 : 0)), overflowY: 'auto', overflowX: 'hidden'}}>
                                            <Grid item xs={12}>
                                                <Box height={90} />
                                            </Grid>
                                            <Grid item xs={12} className={`h-100`}>
                                                <Box p={3} style={{height: (parseInt(ui?.height) - (alertText ? 165 : 115))}}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3} justify="space-between" alignItems="center">
                                                                <Grid item>
                                                                    <MuiButton
                                                                        color="primary"
                                                                        variant="text"
                                                                        onClick={this.handleDeliveryAdd}
                                                                    >
                                                                        <FAIcon icon="plus" size={15} button />
                                                                        Add New Delivery
                                                                    </MuiButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="preConfirmed" checked={formData.preConfirmed} color="primary" onChange={this.handleCheckedChange}/>}
                                                                        label={<Typography variant="caption" className="fw-400">Change without supplier confirmation</Typography>}
                                                                    />                                                            
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {_.map(formData.deliveries, (pod, key) => (
                                                            <Grid item xs={12} key={key}>
                                                                <Paper>
                                                                    <Box p={3}>
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12}>
                                                                                <Grid container spacing={3} alignItems='center'>
                                                                                    <Grid item xs>
                                                                                        <Typography variant="h6" gutterBottom>
                                                                                            Purchase Order Delivery #{pod.pod_reference}/{pod.pod_idx}
                                                                                            <InsightChip 
                                                                                                background='#ef3340'
                                                                                                color='#fff'
                                                                                                icon="check"
                                                                                                label={pod.pod_status}
                                                                                                paddingLeft={8}
                                                                                                marginRight={8}
                                                                                                marginLeft={24}
                                                                                            />
                                                                                        </Typography>     
                                                                                        <Typography variant="body2" className="fw-400">
                                                                                            <FAIcon icon={pod.pod_status === 'Cancelled' || pod.pod_status === 'Invoiced' || (pod.changed && pod.preConfirmed === 0) ? 'exclamation-triangle' : 'check'} className={`${pod.pod_status === 'Cancelled' || pod.pod_status === 'Invoiced' || pod.pod_status === 'Despatched' || (pod.changed && pod.preConfirmed === 0) ? 'textError' : 'textSuccess'} mr-1`} size={15} />
                                                                                            {((pod.pod_status === 'Cancelled' || pod.pod_status === 'Invoiced' || (pod.pod_status === 'Despatched' && pod.pod_delivered === 1)) && (
                                                                                                `You cannot modify this delivery as it has been ${pod.pod_status?.toLowerCase?.()}${pod.pod_status === 'Despatched' ? ` and delivered` : ``}`
                                                                                            )) || (pod.changed && (
                                                                                                (formData.preConfirmed === 0 && (
                                                                                                    `Changes to this delivery will require re-confirmation`
                                                                                                )) || (
                                                                                                    `Changes will be immediately reflected in RED`
                                                                                                )
                                                                                            )) || (
                                                                                                `No changes require supplier confirmation`
                                                                                            )}
                                                                                        </Typography>                                                             
                                                                                    </Grid>
                                                                                    {(pod.pod_status !== 'Cancelled' && pod.pod_status !== 'Invoiced' && (pod.pod_status !== 'Despatched' || (pod.pod_status === 'Despatched' && pod.pod_delivered === 0))) && (
                                                                                        (_.isEmpty(pod.productRows) && (
                                                                                            <Grid item>
                                                                                                <MuiButton
                                                                                                    color="primary"
                                                                                                    variant="text"
                                                                                                    onClick={() => this.handleDeliveryRemove(key)}
                                                                                                >
                                                                                                    <FAIcon icon="trash-alt" size={15} button />
                                                                                                    Delete Delivery
                                                                                                </MuiButton>
                                                                                            </Grid>
                                                                                        )) || (pod.pod_status !== "Invoiced" && pod.pod_delivered === 0 && (
                                                                                            <>
                                                                                                <Grid item>
                                                                                                    <Box maxWidth={200}>
                                                                                                        <DatePicker
                                                                                                            type="date"
                                                                                                            label={'Requested Despatch Date *'}
                                                                                                            value={pod.date}
                                                                                                            onChange={date => this.handleDeliveryDateEdit(key, date)}
                                                                                                            autoOk={true}
                                                                                                            inputVariant="outlined"
                                                                                                        />            
                                                                                                    </Box> 
                                                                                                </Grid>
                                                                                                {pod.pod_status !== "Despatched" && (
                                                                                                    <Grid item>
                                                                                                        <Box maxWidth={200}>
                                                                                                            <DatePicker
                                                                                                                type="date"
                                                                                                                label={'Check Despatch Date'}
                                                                                                                value={pod.check}
                                                                                                                onChange={date => this.handleDeliveryCheckDateEdit(key, date)}
                                                                                                                autoOk={true}
                                                                                                                disablePast
                                                                                                                inputVariant="outlined"
                                                                                                            />            
                                                                                                        </Box> 
                                                                                                    </Grid>
                                                                                                )}
                                                                                            </>
                                                                                        ))
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                            {_.some(pod.productRows, p => (p?.quantityInvoiced > 0 || p?.quantityDelivered > 0)) && (
                                                                                <Grid item xs={12}>
                                                                                    <Alert severity="warning">
                                                                                        <Typography variant="body2" className="fw-400">
                                                                                            Some lines have been delivered and/or invoiced - please take care in modifying this delivery
                                                                                        </Typography>
                                                                                    </Alert>
                                                                                </Grid>
                                                                            )}
                                                                            <Grid item xs={12}>
                                                                                <Products
                                                                                    ifm
                                                                                    classes={classes}
                                                                                    deliveries={formData.deliveries}
                                                                                    rows={pod.productRows}
                                                                                    formErrors={formErrors}
                                                                                    pod={key}
                                                                                    handleProductEdit={this.handleProductEdit}
                                                                                    handleProductRemove={this.handleProductRemove}
                                                                                    products={products}
                                                                                    readOnly={pod?.pod_status === "Cancelled" || (pod?.pod_status === "Despatched" && pod?.pod_delivered === 1) || pod.pod_status === "Invoiced"}
                                                                                    vatPercent={formData.vatPercent}
                                                                                    showDelivered
                                                                                    disableInvoicedOrDelivered
                                                                                    noDraggable
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        ))}
                                                        <Grid item xs={12}>
                                                            <PaddedPaper>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Purchase Order Notes
                                                                </Typography>
                                                                <Typography variant="caption" color="error" paragraph>
                                                                    <FAIcon type="solid" icon="info-circle" size={11} noMargin /> These notes will be visible to the supplier
                                                                </Typography>
                                                                <Textarea
                                                                    name="notes"
                                                                    value={formData.notes}
                                                                    onChange={this.handleChange}
                                                                    margin="none"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    rows={5}
                                                                />
                                                            </PaddedPaper>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={2}>
                                        <Box p={3}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <ShippingAddress 
                                                        ifm
                                                        formData={formData}
                                                        formErrors={formErrors}
                                                        handleChange={this.handleChange}
                                                        supplier={supplier}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Shipping
                                                        carriageList={lists.carriage}
                                                        formData={formData}
                                                        formErrors={formErrors}
                                                        handleSelectChange={this.handleSelectChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={3}>
                                        <Box p={3}>
                                            <Notes
                                                formData={formData}
                                                existingNotes={existingNotes}
                                                handleNoteAdd={this.handleNoteAdd}
                                                handleNoteEdit={this.handleNoteEdit}
                                                handleNoteRemove={this.handleNoteRemove}
                                                loggedInStaff={this.props.loggedInStaff}
                                                staff={this.props.staff}
                                                teams={lists.teams}
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={4}>
                                        <Preview
                                            classes={classes}
                                            height={alertText ? (ui.contentHeight - 49) : ui.contentHeight}
                                            preview={preview}
                                            handlePreviewLoad={this.handlePreviewLoad}
                                            handlePreviewPagination={this.handlePreviewPagination}
                                        />
                                    </TabPanel>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} lg={2} className={`${classes.side} h-100`}>
                            <Grid container className={`${classes.relative} h-100`}>
                                <Grid item xs={12}>
                                    <Tabs
                                        ifm
                                        btnUpdateDisabled={btnUpdateDisabled}
                                        classes={classes}
                                        currentTab={currentTab}
                                        disableSupplierTab={disableSupplierTab}
                                        formData={formData}
                                        handleTabChange={this.handleTabChange}
                                        id={id}
                                    />
                                </Grid>
                                <Grid item xs={12} className={`${classes.absoluteBottom} ${classes.poIfmTotals} w-100`}>
                                    <Totals   
                                        classes={classes}
                                        aaVatPercent={formData.vatPercent}
                                        subTotal={formData.subTotal}
                                        carriageTotal={formData.carriageTotal}
                                        netTotal={formData.netTotal}
                                        vatTotal={formData.vatTotal}
                                        grossTotal={formData.grossTotal}
                                        inForm
                                    />
                                    {(!isLoading && currentTab === 4 && (
                                        <Button
                                            submit={this.handleConfirmation}
                                            disabled={(btnUpdateDisabled || (currentTab !== 4 || isLoading)) && true}
                                            text={`Update`}
                                        />
                                    )) || (
                                        <Box mt={3}>
                                            <Grid container spacing={3} justify="center">
                                                <Grid item>
                                                    <MuiButton
                                                        color="primary"
                                                        disabled={currentTab === 0 || (currentTab === 1 && id) || isLoading}
                                                        onClick={() => this.handleTabChange((currentTab - 1))}
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="arrow-left" disabled={isLoading} size={15} button />
                                                        Back
                                                    </MuiButton>
                                                </Grid>
                                                <Grid item>
                                                    <MuiButton
                                                        color="primary"
                                                        onClick={() => this.handleTabChange((currentTab + 1))}
                                                        disabled={isLoading || currentTab === 0 || (currentTab === 3 && btnUpdateDisabled)}
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="arrow-right" disabled={isLoading || (currentTab === 3 && btnUpdateDisabled)} size={15} button />
                                                        Next
                                                    </MuiButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                    {id && (
                                        <Box mt={1.25} align='center'>
                                            <MuiButton
                                                color="primary"
                                                onClick={() => deployConfirmation(`Are you sure you want to cancel changes?`, () => history.push(`/purchase-orders/${id}`))}
                                                variant="text"
                                            >
                                                <FAIcon icon="times" size={15} button />
                                                Cancel
                                            </MuiButton>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInStaff: state.staffAuth.staff,
        staff: state.notifications.status,
        teamList: state.notifications.teamList,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success, cancel = false) => dispatch(deployConfirmation(message, success, cancel)), 
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disabled = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disabled)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Form)(withRouter(PurchaseOrderIfmForm)));