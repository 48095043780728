import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Prompt } from 'react-router';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Highlight from 'Components/Common/Highlight/Highlight';
import InsightChip from 'Components/Common/Chips/InsightChip';
import JoinOrderLine from 'Components/Purchases/Actions/JoinOrderLine';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import logo from 'Assets/Images/no-img.jpg';
import PurchaseOrderSummary from 'Components/Purchases/Misc/PurchaseOrderSummary';
import SplitOrderLine from 'Components/Purchases/Actions/SplitOrderLine';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { getLeadTime, currencyFormat, momentFormatDate } from 'Functions/MiscFunctions';
import { handleChange, handleCheckedChange } from 'Functions/FormFunctions';
import { CLOUDFRONT_URL } from 'Constants';
import SendEmailNotifications from 'Components/Communication/Misc/SendEmailNotifications';

const formatOptionLabel = ({label, date}) => {
    return (
        <Box pt={0.5} pb={0.5}>
            <Grid container spacing={1} alignItems='center'>
                <Grid item align='center'>
                    <FAIcon icon='shipping-fast' className="ml-1 mr-1" size={25} noMargin button />
                </Grid>
                <Grid item xs>
                    <Typography variant="body2" className="fw-400">
                        {label}
                    </Typography>
                    <Typography variant="caption" component="div">
                        Expected Despatch: {date}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const initialState = () => ({
    formData: {
        deliveries: [],
        lines: [],
        sendDespatchNotes: 0
    },
    initialFormData: {
        deliveries: [],
        lines: []
    },
    isLoading: false
})

class AwaitingConfirmation extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState();
        this.handleChange = handleChange.bind(this)
        this.handleCheckedChange = handleCheckedChange.bind(this)
    }

    componentDidMount = () => {

        const   lines               =   [],
                deliveries          =   [],
                { po }              =   this.props,
                sendDespatchNotes   =   po?.po_type === 'Sales Order' && po?.supplier?.supp_send_despatch_note === 1 ? 1 : 0;

        _.each(po.deliveries, (pod, idx) => {
            deliveries.push(
                {
                    id: pod.pod_id,
                    requestedDate: moment(pod.pod_req_despatch_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
                    date: pod.pod_despatch_date !== "0000-00-00" ? pod.pod_despatch_date : pod.pod_req_despatch_date,
                    status: pod.pod_status,
                    despatched: pod.pod_status === "Despatched" || pod.pod_status === "Invoiced",
                    delivered: pod.pod_delivered,
                    checkDate: pod.pod_check_date !== "0000-00-00" ? pod.pod_check_date : null,
                }
            )
            _.each(pod.products, podp => {
                lines.push(
                    {
                        ...podp,
                        delivery: (idx + 1)
                    }
                )
            })
        })

        this.setState({
            formData: {
                ...this.state.formData,
                deliveries,
                lines,
                sendDespatchNotes
            },
            initialFormData: {
                ...this.state.formData,
                deliveries,
                lines
            }
        })

    }

    componentDidUpdate = prevProps => {
        if(prevProps.currentTab !== this.props.currentTab) {
            if(this.props.currentTab === 3) {
                const { formData } = this.state;
                let dates = [];
                let error = false;
                formData.deliveries.forEach((pod, idx) => {
                    if(!error) {
                        const   rows    =   _.filter(formData.lines, el => el.delivery === (idx + 1));
                        if(_.isEmpty(rows)) {
                            error = true;
                        } else {
                            if(!pod.despatched) {
                                dates.push(pod.date)
                            }
                        }
                    }
                });
                if(error) {
                    this.props.handleTabChange(2);
                    this.props.deploySnackBar("error", "One or more deliveries contain no lines, please check and try again")
                }
                if(_.some(dates, (el, idx) => dates.indexOf(el) !== idx)) {
                    this.props.deployConfirmation(<><div className="fw-400">Some deliveries have the same expected despatch date</div><div>Do you want to continue?</div></>, false, () => this.props.handleTabChange(2))
                }
            }
        }
    }

    handleDeliveryAdd = () => {
        const   deliveries  =   [...this.state.formData.deliveries];
        deliveries.push(
            {
                requestedDate: null,
                date: this.props.po?.supplier?.defaultDate ?? moment().format('YYYY-MM-DD'),
                checkDate: null,
                despatched: false,
                delivered: 0
            }
        )
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries,
            }
        })
    }
     
    handleDateChange = (idx, fieldName, date) => {
        let deliveries = [...this.state.formData.deliveries];
        deliveries[idx] = {
            ...deliveries[idx],
            [fieldName]: date ? moment(date).format('YYYY-MM-DD') : null
        }
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries,
            }
        })
    }

    handleDeliveryRemove = idx => {
        const deliveries = [...this.state.formData.deliveries]
        let lines = [...this.state.formData.lines];

        deliveries.splice(idx, 1)

        _.each(lines, (line, idxx) => {
            lines[idxx] = {
                ...lines[idxx],
                delivery: line.delivery >= (idx + 1) ? (line.delivery - 1) : line.delivery
            }
        })

        this.setState({
            formData:{
                ...this.state.formData,
                deliveries,
                lines
            }
        })
    }

    handleLineDeliveryChange = (idx, delivery) => {
        let lines = [...this.state.formData.lines];
        lines[idx] = {
            ...lines[idx],
            delivery: delivery.value ?? delivery ?? ''
        }
        lines = _.sortBy(lines, el => el.delivery);
        this.setState({
            formData: {
                ...this.state.formData,
                lines
            }
        })
    }
    
    handleLineSplit = idx => this.props.deployDialog(
        <SplitOrderLine
            closeDialog={this.props.closeDialog}
            deployConfirmation={this.props.deployConfirmation}
            idx={idx} 
            product={this.state.formData?.lines?.[idx] ?? false}
            submit={this.handleLineSplitSubmit}
        />
        , false, "", "standard", "sm"
    )

    handleLineSplitSubmit = (idx, qty) => {

        let lines       =   [...this.state.formData.lines],
            currentQty  =   (lines?.[idx]?.quantity) ?? 0,
            newQty      =   (currentQty - qty),
            currentVat =    lines?.[idx]?.vat;

        if(newQty > 0) {
            lines[idx] = {
                ...lines[idx],
                quantity: newQty,
                quantityDespatch: newQty,
                totalCost: (parseInt(newQty) * parseFloat(lines[idx]['cost'])).toFixed(2),
                vat: ((parseFloat(currentVat) / parseInt(currentQty)) * (parseInt(newQty))).toFixed(2)
            }
            lines.push(
                {
                    ...lines[idx],
                    quantity: qty,
                    quantityDespatch: qty,
                    totalCost: (parseInt(qty) * parseFloat(lines[idx]['cost'])).toFixed(2),
                    vat: ((parseFloat(currentVat) / parseInt(currentQty)) * (parseInt(qty))).toFixed(2)
                }
            )
            lines = _.sortBy(lines, el => el.delivery);
            this.setState({
                formData: {
                    ...this.state.formData,
                    lines,
                }
            }, () => {
                this.props.closeDialog()
            })
        }

    }

    handleLineJoin = idx => this.props.deployDialog(
        <JoinOrderLine
            confirmation
            closeDialog={this.props.closeDialog}
            deployConfirmation={this.props.deployConfirmation}
            idx={idx} 
            product={this.state.formData?.lines?.[idx] ?? false}
            formData={this.state.formData}
            submit={this.handleLineJoinSubmit}
        />
        , false, "", "standard", "sm"
    )

    handleLineJoinSubmit = lines => {
        this.setState({
            formData: {
                ...this.state.formData,
                lines,
            }
        }, () => {
            this.props.closeDialog()
        })
    }

    handleSubmit = () => this.setState({
        isLoading: true
    }, () => {
        API.post(`/purchaseOrders/${this.props.id}/process/confirmation`, this.state.formData)
        .then(result => {
            if(result?.data) {
                if(result?.data?.errors) {
                    if(result?.data?.errors?.[0]?.msg === "This purchase order cannot be confirmed as the sales order has been modified") {
                        this.props.deploySnackBar("error", result?.data?.errors?.[0]?.msg);
                        this.props.history.push(`/purchase-orders/${this.props.id}`)
                    } else {
                        this.props.callback();
                        this.props.handleTabChange(2);
                            this.props.deploySnackBar("error", result?.data?.errors?.[0]?.msg);
                    }
                } else {
                    this.props.deploySnackBar(`success`, `You have successfully confirmed this purchase order`);
                    this.props.callback()
                    if(result?.data?.notifications?.length > 0) {
                        const deployEmailNotifications = () => {
                            this.props.deployDialog(
                                <SendEmailNotifications notifications={result.data.notifications} />
                            , false, "E-mail Notifications", "standard", "md", false, true);
                        }
                        this.props.deployConfirmation(`Do you want to send the customer an update email?`, deployEmailNotifications)
                    }
                }
            }
        })
    })

    render() {        
        const { currentTab, deployConfirmation, po } = this.props;
        const { formData, initialFormData, isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Prompt
                    when={JSON.stringify(formData) !== JSON.stringify(initialFormData)}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    (currentTab === 0 && (
                        <Grid item xs={12}>
                            <ViewNotes heading={<Typography variant="h6">Purchase Order Notes</Typography>} notes={po.notes} hideDeleteButton />
                        </Grid>
                    )) || (currentTab === 1 && (
                        <Grid item xs={12}>
                            <PurchaseOrderSummary po={po} />
                        </Grid>
                    )) || (currentTab === 2 && (
                        <Grid item xs={12}>
                            <Box pb={3}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={12} md>
                                        <Typography variant="h6">
                                            {po?.supplier?.supp_company_name}
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            Purchase Order Confirmation
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <InsightChip
                                            paddingLeft={8}
                                            marginRight={8}
                                            // background={po?.supplier?.supp_lead_time === "SAME" || po?.supplier?.supp_lead_time === "1:DAY" ? '#4CAF50' : (po?.supplier?.supp_lead_time === "CHECK" ? '#ef3340' : '#FF5722')}
                                            // color="#fff"
                                            icon="calendar"
                                            label={`${getLeadTime(po?.supplier?.supp_lead_time, true)} Lead Time`}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InsightChip
                                            paddingLeft={8}
                                            marginRight={8}
                                            // background={po?.supplier?.cutOffPast ? '#ef3340' : '#4CAF50'}
                                            // color="#fff"
                                            icon="clock"
                                            label={`${po?.supplier?.cutOffTime} Cut Off Time`}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InsightChip
                                            paddingLeft={8}
                                            // background={po?.supplier?.supp_min_order_value === "0.00" ? '#4CAF50' : (parseFloat(po?.po_total_net) > parseFloat(po?.supplier?.supp_min_order_value) ? '#4caf50' : '#ef3340')}
                                            // color="#fff"
                                            icon="coins"
                                            label={`${po?.supplier?.supp_min_order_value === "0.00" ? 'No' : currencyFormat.format(po.supplier.supp_min_order_value)} Minimum Order`}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Paper>
                                <Box p={3}>
                                    <Grid container spacing={1} alignItems='center'>
                                        {/* <Grid item>
                                            <FAIcon icon="truck-loading" type="light" size={25} noMargin />
                                        </Grid> */}
                                        <Grid item xs>
                                            <Typography variant="h6" className="fw-400">
                                                Deliveries
                                            </Typography>    
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={this.handleDeliveryAdd}
                                            >
                                                <FAIcon icon="plus-circle" size={15} button />
                                                Additional Delivery
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataTable  
                                                config={{
                                                    alternatingRowColours: true,
                                                    // responsiveImportance: true,
                                                    inline: true,
                                                    noRowHover: true,
                                                    rowProps: rowData => ({
                                                        style: {
                                                            background: rowData.despatched ? '#ffebee' : undefined
                                                        }
                                                    })
                                                }}
                                                columns={[ 
                                                    {
                                                        heading: 'Delivery',
                                                        field: rowData => (
                                                            <Box pt={1.75} pb={1.75}>
                                                                <Typography variant="body2" className="fw-400">
                                                                    {`Delivery ${rowData.rowNumber} / ${_.size(formData.deliveries)}`}
                                                                </Typography>
                                                                <Typography variant="caption">
                                                                    {(rowData.despatched && (
                                                                        `This delivery has already been ${rowData.status.toLowerCase?.()}`
                                                                    )) || (
                                                                        !rowData.checkDate ? `${po.po_collection === 1 ? 'Collection' : 'Despatch'} confirmed for ${moment(rowData.date).format("DD/MM/YYYY")}` : `${po.po_collection === 1 ? 'Collection' : 'Despatch'} status to be checked on ${moment(rowData.checkDate).format("DD/MM/YYYY")}`
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        ),
                                                        main: true,
                                                        important: true, 
                                                        cellProps: rowData => ({
                                                            className: _.isEmpty(_.filter(formData.lines, el => el.delivery === rowData.rowNumber)) ? 'textError' : undefined
                                                        })
                                                    },
                                                    {
                                                        heading: 'Lines',
                                                        field: rowData => {
                                                            const count = _.size(_.filter(formData.lines, el => el.delivery === rowData.rowNumber))
                                                            return <Highlight variant={count > 0 ? 'success' : 'error'}>{count}</Highlight>
                                                        },
                                                        important: true, 
                                                        alignment: 'left',
                                                        sizeToContent: true,
                                                        cellProps: rowData => ({
                                                            className: _.isEmpty(_.filter(formData.lines, el => el.delivery === rowData.rowNumber)) ? 'textError pr-5' : 'pr-5'
                                                        })
                                                    },
                                                    {
                                                        heading: `Requested ${po.po_collection === 1 ? `Collection` : `Despatch`} Date`,
                                                        field: rowData => (
                                                            <Box minWidth={252}>
                                                                {rowData.despatched ? '-' : rowData.requestedDate ?? '-'}
                                                            </Box>
                                                        ),
                                                        important: true, 
                                                        sizeToContent: true,
                                                        cellProps: rowData => ({
                                                            className: _.isEmpty(_.filter(formData.lines, el => el.delivery === rowData.rowNumber)) ? 'textError' : undefined
                                                        })
                                                    },
                                                    {
                                                        heading: `Expected ${po.po_collection === 1 ? `Collection` : `Despatch`} Date`,
                                                        field: rowData => (
                                                            <Box minWidth={252}>
                                                                {(rowData.despatched && (
                                                                    `-`
                                                                )) || (
                                                                    <Grid container spacing={1} alignItems='center'>
                                                                        <Grid item xs>
                                                                            <FormControl fullWidth>
                                                                                <DatePicker
                                                                                    type="date"
                                                                                    value={rowData.date}
                                                                                    onChange={date => this.handleDateChange(rowData.rowIdx, 'date', date)}
                                                                                    autoOk={true}
                                                                                    inputVariant="filled"
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Box minWidth={38}>
                                                                                {!rowData.date && (
                                                                                    <FAIcon icon="exclamation-triangle" color="error" noMargin button />
                                                                                )}
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Box>
                                                        ),
                                                        sizeToContent: true,
                                                        important: true,
                                                    },
                                                    {
                                                        heading: 'Check Date',
                                                        field: rowData => (
                                                            <Box minWidth={250}>
                                                                {(rowData.despatched && (
                                                                    `-`
                                                                )) || (
                                                                    <Grid container spacing={1} alignItems="center" justify="center">
                                                                        <Grid item xs={10}>
                                                                            <FormControl fullWidth>
                                                                                <DatePicker
                                                                                    type="date"
                                                                                    value={rowData.checkDate}
                                                                                    onChange={date => this.handleDateChange(rowData.rowIdx, 'checkDate', date)}
                                                                                    autoOk={true}
                                                                                    disablePast={true}
                                                                                    inputVariant="filled"
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <Tooltip title="Remove Check Date">
                                                                                <IconButton
                                                                                    disabled={!rowData.checkDate}
                                                                                    onClick={() => this.handleDateChange(rowData.rowIdx, 'checkDate', null)}
                                                                                    size="small"
                                                                                >
                                                                                    <FAIcon icon="times" size={15} noMargin button />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Box>
                                                        ),
                                                        important: true,
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'Delete Delivery', icon: 'trash-alt', onClick: () => this.handleDeliveryRemove(rowData.rowIdx), disabled: rowData.despatched || _.some(formData.lines, el => el.delivery === rowData.rowNumber)},
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={formData.deliveries}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box mb={3} />
                                    <Grid container spacing={1} alignItems='center'>
                                        {/* <Grid item>
                                            <FAIcon icon="box-check" type="light" size={25} noMargin />
                                        </Grid> */}
                                        <Grid item xs>
                                            <Typography variant="h6" className="fw-400">
                                                Ordered Lines
                                            </Typography>    
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataTable  
                                                config={{
                                                    alternatingRowColours: true,
                                                    // responsiveImportance: true,
                                                    inline: true,
                                                    noRowHover: true,
                                                    rowProps: rowData => ({
                                                        style: {
                                                            background: formData.deliveries?.[(rowData.delivery - 1)]?.despatched ? '#ffebee' : undefined
                                                        }
                                                    })
                                                }}
                                                columns={[ 
                                                    {
                                                        heading: 'Product',
                                                        field: rowData => <Avatar variant="square" alt={rowData.image} src={(rowData.image && `${CLOUDFRONT_URL}${rowData.image}`) || logo} />,
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: '',
                                                        field: rowData => (
                                                            <Box pt={1.75} pb={1.75}>
                                                                <Typography variant="body2" className="fw-400">
                                                                    {rowData.productCode}
                                                                </Typography>
                                                                <Typography variant="caption" component="div">
                                                                    {rowData.productName}
                                                                </Typography>
                                                            </Box>
                                                        ),
                                                        important: true, 
                                                        main: true,
                                                        truncate: true
                                                    },
                                                    {
                                                        heading: 'Buy Price',
                                                        field: rowData => rowData.cost,
                                                        tooltip: rowData => `Buy at £${rowData.cost} per unit`,
                                                        fieldFormat: 'currency',
                                                        alignment: 'center',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Quantity',
                                                        field: rowData => <Tooltip placement="right" title={`${rowData.quantity} Quantity Required`}><span><Highlight variant="success">{rowData.quantity}</Highlight></span></Tooltip>,
                                                        alignment: 'center',
                                                        sizeToContent: true
                                                    },                                                    
                                                    {
                                                        heading: 'Despatch',
                                                        field: rowData => <Tooltip placement="right" title={`${rowData.quantityDespatch} Quantity To Despatch`}><span><Highlight variant="success">{rowData.quantityDespatch}</Highlight></span></Tooltip>,
                                                        alignment: 'center',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Despatch with',
                                                        field: rowData => (
                                                            <Box style={{width: 300}}>
                                                                <AutoCompleteSelect
                                                                    disabled={rowData.quantityDespatch === 0 || formData.deliveries?.[(rowData.delivery - 1)]?.despatched}
                                                                    value={rowData.delivery} 
                                                                    formatOptionLabel={formatOptionLabel}
                                                                    onChange={delivery => this.handleLineDeliveryChange(rowData.rowIdx, delivery)}
                                                                    options={_.map(formData.deliveries, (el, idx) => _.assign({label: `Delivery ${idx+1}`, date: `${el.date ? ` - ${el.despatched ? el.status : moment(el.date).format("DD/MM/YYYY")}` : ''}`, value: (idx + 1), disabled: el.despatched}))}
                                                                    noClear
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        ),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'Merge Lines', icon: 'compress-arrows-alt', onClick: () => this.handleLineJoin(rowData.rowIdx), disabled: rowData.quantity !== rowData.quantityDespatch || formData.deliveries?.[(rowData.delivery - 1)]?.despatched || _.size(_.filter(formData.lines, el => !formData.deliveries?.[rowData.delivery]?.despatched && el.productId === rowData.productId && el.cost === rowData.cost && el?.ls !== "Invoiced" && el?.ls !== "Despatched")) === 1},
                                                                {name: 'Split Line', icon: 'random', onClick: () => this.handleLineSplit(rowData.rowIdx), disabled: rowData.quantity !== rowData.quantityDespatch || formData.deliveries?.[(rowData.delivery - 1)]?.despatched || rowData.quantity < 2},
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={formData.lines}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    )) || (currentTab === 3 && (
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {_.map(formData.deliveries, (pod, idx) => {
                                    const   rows    =   _.filter(formData.lines, el => el.delivery === (idx + 1));
                                    return (
                                        <Grid item xs={12} key={idx}>
                                            <Paper>
                                                <Box p={3}>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3} alignItems='center'>
                                                                <Grid item xs={12} sm>
                                                                    <Typography variant="h6" className="fw-400">
                                                                        Delivery {(idx + 1)} of {_.size(formData.deliveries)} by {po?.supplier?.supp_company_name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Alert variant="outlined" severity="success">
                                                                        <strong>Expected {po.po_collection === 1 ? 'Collection' : 'Despatch'}</strong>: {momentFormatDate(pod.date)}
                                                                    </Alert>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Alert variant="outlined" severity={pod.checkDate ? (pod.despatched ? 'success' : 'warning') : 'success'}>
                                                                        <strong>Check Date</strong>: {pod.checkDate ? momentFormatDate(pod.checkDate) : `Not Set`}
                                                                    </Alert>
                                                                </Grid>
                                                            </Grid>
                                                            <Box pt={2} pb={2}>
                                                                {(pod.despatched && (
                                                                    <Alert severity="error">
                                                                        <strong>Delivery {pod.status}</strong><br />
                                                                        No changes will be made to this delivery as a result of this confirmation
                                                                    </Alert>
                                                                )) || (
                                                                    <>
                                                                        {(pod.checkDate && (
                                                                            <Alert severity="warning">
                                                                                <strong>Check Date Set</strong><br />
                                                                                This {po.po_collection === 1 ? 'collection' : 'despatch'} status for this delivery will be checked on {moment(pod.checkDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                            </Alert>
                                                                        )) || (
                                                                            (moment(pod.date, "YYYY-MM-DD").isBefore(moment()) && (
                                                                                <Alert severity="success">
                                                                                    <strong>Immediate{po.po_collection === 1 ? 'ly Ready To Collect' : ' Despatch'}</strong><br />
                                                                                    This delivery will be marked as {po.po_collection === 1 ? 'ready to collect' : 'despatched'} immediately on confirmation
                                                                                </Alert>
                                                                            )) || (
                                                                                <Alert severity="success">
                                                                                    <strong>{po.po_collection === 1 ? 'Collection' : 'Despatch'} Confirmed</strong><br />
                                                                                    This delivery will be automatically marked as despatched on {moment(pod.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                </Alert>
                                                                            )
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <DataTable  
                                                                config={{
                                                                    alternatingRowColours: true,
                                                                    // responsiveImportance: true,
                                                                    inline: true,
                                                                    noRowHover: true
                                                                }}
                                                                columns={[ 
                                                                    {
                                                                        heading: 'Product',
                                                                        field: rowData => <Avatar variant="square" alt={rowData.image} src={(rowData.image && `${CLOUDFRONT_URL}${rowData.image}`) || logo} />,
                                                                        sizeToContent: true,
                                                                        noRoverHover: true
                                                                    },
                                                                    {
                                                                        heading: '',
                                                                        field: rowData => (
                                                                            <>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    {rowData.productCode}
                                                                                </Typography>
                                                                                <Typography variant="caption" component="div">
                                                                                    {rowData.productName}
                                                                                </Typography>
                                                                            </>
                                                                        ),
                                                                        important: true, 
                                                                        main: true,
                                                                        truncate: true
                                                                    },
                                                                    {
                                                                        heading: 'Quantity',
                                                                        field: rowData => <Tooltip placement="right" title={`${rowData.quantity} Quantity Required`}><span><Highlight variant="success">{rowData.quantity}</Highlight></span></Tooltip>,
                                                                        alignment: 'center',
                                                                        sizeToContent: true
                                                                    },
                                                                    {
                                                                        heading: 'Despatch',
                                                                        field: rowData => <Tooltip placement="right" title={`${rowData.quantityDespatch} Quantity To Despatch`}><span><Highlight variant="success">{rowData.quantityDespatch}</Highlight></span></Tooltip>,
                                                                        alignment: 'center',
                                                                        sizeToContent: true,
                                                                    }
                                                                ]}
                                                                rows={rows}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    )
                                })}
                                <Grid item xs />
                                <Grid item>
                                    {this.props.po?.po_type === 'Sales Order' && this.props.po?.supplier?.supp_send_despatch_note === 1 && (
                                        <FormControlLabel
                                            control={<Switch name="sendDespatchNotes" checked={formData.sendDespatchNotes === 1} color="primary" size="small" onChange={this.handleCheckedChange}/>}
                                            label={<Typography variant="body2"><Box pl={0.4}>E-mail despatch notes to supplier</Box></Typography>}
                                        />
                                    )}
                                </Grid>
                                <Grid item align='right'>
                                    <Button
                                        className="btn btn-success"
                                        variant="contained"
                                        onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to confirm this purchase order?</Typography>{formData.sendDespatchNotes === 1 && <Typography variant="body2" className="textError fw-400">Despatch notes will be automatically e-mailed to the supplier</Typography>}</>, this.handleSubmit)}
                                    >
                                        <FAIcon icon="check" size={13.5} buttonPrimary />
                                        Confirm Purchase Order
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                )}
            </Grid>
        );
    }
}

export default AwaitingConfirmation;