import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { AA_ADDRESS } from 'Constants';

const Shipping = ({carriageList, formData, formErrors, handleSelectChange}) => (
    <PaddedPaper>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Delivery Details
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <AutoCompleteSelect 
                    label='Delivery Method *'
                    options={carriageList} 
                    value={formData.carriage}
                    onChange={v => handleSelectChange('carriage', v)}
                    error={formErrors && formErrors['carriage'] && true}
                    errorText={formErrors && formErrors['carriage']}
                    noClear
                    noDefaultSort
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                    Delivery Address
                </Typography>
                {!_.isEmpty(AA_ADDRESS) && (
                    <Grid item xs={12}>
                        {_.map(AA_ADDRESS, (line, idx) => (
                            <Typography variant="body2" key={idx} gutterBottom>
                                {line}
                            </Typography>
                        ))}
                    </Grid>
                )}
            </Grid>
        </Grid>
    </PaddedPaper>
)

export default Shipping;