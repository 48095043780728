import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StackTable from 'Components/Common/DataTables/StackTable';
import { DraggablePaper } from 'Functions/MiscFunctions';
import StaffChip from 'Components/Common/Chips/StaffChip';

const initialState = {
    id: 0,
    adjustment: false,
    isLoading: true
}

const ViewAdjustment = props => {

    let [state, setState] = useState(initialState);
    const staff = useSelector(state => state.notifications.status);
    const { open, close} = props;
    const { id, adjustment, isLoading } = state;

    useEffect(() => {
        if(id > 0) {
            API.get(`/inventory/adjustment/${id}`)
            .then(res => {
                if(res && res.data) {
                    setState(state => ({
                        ...state,
                        adjustment: res.data,
                        isLoading: false
                    }))
                }
            })
        }
    }, [id])

    useEffect(() => {
        if(open === true) {
            setState(state => ({
                ...state,
                id: props.id
            }))
        } else {    
            setState(state => ({
                ...state,
                adjustment: initialState.adjustment,
                isLoading: true,
                id: 0
            }))
        }
    }, [props.id, open])

    const className = !adjustment ? '' : (adjustment.sa_type === 'OUT' ? 'textError' : (adjustment.sa_type === 'DEALLOCATED' ? 'textInfo' : (adjustment.sa_type === 'ALLOCATED' ? 'textInfo' : 'textSuccess')));

    return (
        (isLoading && (
            <React.Fragment />
        )) || (
            <Dialog
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                maxWidth={'md'}
                open={open}
                onClose={close}
                fullWidth={true}
                PaperComponent={DraggablePaper}
            >
                <Box className="content-light-grey">
                    <DialogTitle disableTypography style={{paddingBottom: 0, cursor: 'move'}} id="draggable-control">
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs>
                                <Typography variant="h6">
                                    Stock Adjustment #{adjustment.sa_id}
                                </Typography>
                                {/* <Grid container alignItems='center'>
                                    <Grid item>
                                        <FAIcon type="light" 
                                            size={15}
                                            icon={adjustment.sa_type === 'IN' ? 'check' : 'exclamation-circle'}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className="fw-400">
                                            STOCK {adjustment.sa_type}
                                        </Typography>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                            <Grid item align='right'>
                                <IconButton aria-label="Close" onClick={props.close}>
                                    <FAIcon type="light" icon='times' button noMargin />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Box p={3}>
                        {adjustment.sa_staff_id > 0 && !adjustment.po && !adjustment.rma && !adjustment.order && (
                            <Box pb={3}>
                                <Alert severity="info" elevation={0}>
                                    This was a manual stock adjustment
                                </Alert>
                            </Box>
                        )}
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={8}>
                                <Box p={3} pt={2} pb={1} className="content-light-white">
                                    <StackTable
                                        elevation={0}
                                        padding={0}
                                        heading='Adjustment Overview'
                                        rows={[ 
                                            {
                                                heading: "Product",
                                                value: (adjustment.product && adjustment.product.product_name) || '-',
                                            },
                                            {
                                                heading: "Code",
                                                value: (adjustment.product && adjustment.product.default_code && adjustment.product.default_code.code_value) || '-',
                                            },   
                                            {
                                                heading: "Adjustment",
                                                value: `${adjustment.sa_type !== "NC" ? `${adjustment.sa_type} - ` : ``}${adjustment.sa_reason}`,
                                                className: className
                                            },   
                                            {
                                                heading: "Previous Qty",
                                                value: adjustment.sa_type === 'NC' ? '-' : adjustment.sa_prev_qty,
                                            },    
                                            {
                                                heading: "Adjusted Qty",
                                                value: adjustment.sa_adjusted_qty,
                                                className: className
                                            },    
                                            {
                                                heading: "New Qty",
                                                value: adjustment.sa_type === 'NC' ? '-' : adjustment.sa_new_qty,
                                            },      
                                            {
                                                heading: "Notes",
                                                value: adjustment.sa_notes,
                                                disabled: _.isEmpty(adjustment.sa_notes)
                                            },    
                                            {
                                                heading: "Date / Time",
                                                value: moment(adjustment.sa_created_datetime).format("DD/MM/YYYY [at] HH:mm:ss"),
                                            },     
                                            {
                                                heading: "Staff Member",
                                                value: <StaffChip staff={staff?.[adjustment.sa_staff_id]} />,
                                            },                                                   
                                        ]}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Box p={3} pt={2} pb={1} className="content-light-white">
                                            <StackTable
                                                elevation={0}
                                                padding={0}
                                                heading='Stock Status'
                                                rows={[
                                                    {
                                                        heading: "In Stock",
                                                        value: adjustment.sa_instock,
                                                    },         
                                                    {
                                                        heading: "Available",
                                                        value: adjustment.sa_available,
                                                    },         
                                                    {
                                                        heading: "Allocated",
                                                        value: adjustment.sa_allocated,
                                                    },         
                                                    {
                                                        heading: "On Order",
                                                        value: adjustment.sa_on_order,
                                                    },         
                                                    {
                                                        heading: "Pre-ordered",
                                                        value: adjustment.sa_preordered,
                                                    },      
                                                    // {
                                                    //     heading: "Total Cost",
                                                    //     value: adjustment.sa_total_cost,
                                                    //     currency: true,
                                                    //     className: className
                                                    // },    
                                                    // {
                                                    //     heading: "Total Value",
                                                    //     value: adjustment.sa_total_value,
                                                    //     currency: true,
                                                    //     className: className
                                                    // },                                                     
                                                ]}
                                            /> 
                                        </Box>
                                    </Grid>
                                    {(adjustment.order || adjustment.po || adjustment.rma) && (
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                {adjustment.order && (
                                                    <Grid item xs={12}>
                                                        <Box p={3} pt={2} pb={1} className="content-light-white">
                                                            <StackTable
                                                                elevation={0}
                                                                padding={0}
                                                                heading="Sales Order"
                                                                rows={[ 
                                                                    {
                                                                        heading: "Reference",
                                                                        value: <Link className="blueLink" to={`/sales/${adjustment.order.order_id}`}>{adjustment.order.order_reference}</Link>
                                                                    }                                                   
                                                                ]}
                                                            /> 
                                                        </Box>
                                                    </Grid>
                                                )}
                                                {adjustment.po && (
                                                    <Grid item xs={12}>
                                                        <Box p={3} pt={2} pb={1} className="content-light-white">
                                                            <StackTable
                                                                elevation={0}
                                                                padding={0}
                                                                heading="Purchase Order"
                                                                rows={[ 
                                                                    {
                                                                        heading: "Reference",
                                                                        value: <Link className="blueLink" to={`/purchase-orders/${adjustment.po.po_id}`}>{adjustment.po.po_reference}</Link>
                                                                    }                                                   
                                                                ]}
                                                            /> 
                                                        </Box>
                                                    </Grid>
                                                )}
                                                {adjustment.rma && (
                                                    <Grid item xs={12}>
                                                        <Box p={3} pt={2} pb={1} className="content-light-white">
                                                            <StackTable
                                                                elevation={0}
                                                                padding={0}
                                                                heading="RMA"
                                                                rows={[ 
                                                                    {
                                                                        heading: "Reference",
                                                                        value: <Link className="blueLink" to={`/rma/${adjustment.rma.rma_id}`}>{adjustment.rma.rma_reference}</Link>
                                                                    }                                                   
                                                                ]}
                                                            /> 
                                                        </Box>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        )
    )
}

export default ViewAdjustment;