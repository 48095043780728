import React from 'react';
import _ from 'lodash';

import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const Tabs = ({classes, currentStep, currentTab, handleTabChange, tabs}) => (
    <MuiTabs
        color="primary"
        orientation="vertical"
        TabIndicatorProps={{
            style: {
                display: 'none'
            }
        }}
        value={currentTab}
        variant="scrollable"
    >
        {_.map(_.filter(tabs, el => !el.hide), (tab, idx) => (
            <Tab 
                classes={{root: classes.tabs, selected: classes.tabSelected}} 
                disabled={tab.disabled || currentStep === 5}
                index={tab.index} 
                value={tab.index}
                key={idx} 
                label={(
                    <>
                        {tab.label}
                        {tab.subheading && (
                            <Typography variant="caption" component="div" style={{fontWeight: 400}}>
                                {tab.subheading}
                            </Typography>
                        )}
                    </>
                )} 
                onClick={() => handleTabChange(tab.index, tab.delivery ?? false)} 
            />
        ))}
    </MuiTabs>
)

export default Tabs;