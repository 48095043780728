import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import uuidv4 from 'uuid';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import DocumentForm from 'Components/Common/Forms/DocumentForm';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TaskForm from 'Components/Common/Activity/TaskForm';
import ViewPage from 'Components/Common/Styles/ViewPage';

import AwaitingConfirmation from 'Components/Purchases/Actions/AwaitingConfirmation';
import AwaitingSubmission from 'Components/Purchases/Actions/AwaitingSubmission';
import DeliveryStatus from 'Components/Purchases/Actions/DeliveryStatus';
import Details from 'Components/Purchases/Actions/Details';
import Tabs from 'Components/Purchases/Actions/Tabs';
import Unauthorized from 'Components/Purchases/Actions/Unauthorized';
import WildixContext from "Context/WildixContext";

import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { TabPanel } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const getLegacyPurchaseOrderPDF = id => {
    API.get('/purchaseOrders/' + id + '/legacy/pdf')
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

function RedStepIcon(props) {
    const { active, completed } = props;
    return (
        (completed && ( 
            <FAIcon type='light' icon="check" size={17.5} className="textSuccess" />
        )) || (active && (
            <FAIcon type='light' icon="exclamation-circle" size={17.5} className="textError" />
        )) || (
            <FAIcon type='light' icon="circle" size={17.5} style={{color: 'rgba(0, 0, 0, 0.54)'}} />
        )
    );
}

const RedStepConnector = withStyles({
    active: {
        '& $line': {
            borderColor: '#000',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#000',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 1,
        borderRadius: 1,
    }
})(StepConnector);

const initialState = {
    access: {
        awaitingSubmission: false,
        awaitingConfirmation: false,
        awaitingDespatch: false,
        despatched: false,
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        viewSupplier: false
    },
    currentStep: 1,
    currentTab: null,
    delivery: null,
    id: 0,
    initialLoading: true,
    order: {},
    tabs: [],
    uuid: uuidv4()
}

class ProcessPurchaseOrder extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props)
        this.state = initialState
        this.mainContentArea = React.createRef();
        this.timeout = false;
        this.poll = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                ...initialState
            }, () => this.loadComponentData());
        }
    }

    componentWillUnmount = () => {

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        if(this.poll) {
            clearInterval(this.poll);
        }

    }
    
    handlePoll = (initial = false) => {
        API.post(`/staff/my/view`, 
            { 
                po: this.state.id,
                type: 'Process'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {
                    const   update  = _.some(res.data, el => el.u === 1),
                            process = _.some(res.data, el => el.p === 1);

                    if(!_.isEmpty(res.data) && (update || process)) {
                        this.props.deploySnackBar("error", `Sorry, another user is currently ${update ? 'modifying' : 'processing'} this purchase order`);
                        this.props.history.push(`/purchase-orders/${this.state.id}`)
                    }
                } else {
                    if(this.state.initialLoading) {
                        this.setState({
                            initialLoading: false
                        })
                    }
                }
            }
        })
    }

    loadComponentData = () => {
        let currentTab  =   null,
            id          =   this.props.id ?? this.props.match?.params?.id ?? false;

            
        if(isNaN(id) && id.includes(":")) {
            id = id.split(":");
            currentTab = parseInt(id[1]);
            id = parseInt(id[0]);
        } else {
            currentTab = null;
        }

        if(id) {

            API.multiAccess([
                'process-po:awaiting-submission',
                'process-po:awaiting-confirmation',
                'process-po:awaiting-despatch',
                'process-po:despatched',
                'view-po:add-call',
                'view-po:add-note',   
                'view-po:add-document',     
                'view-supplier'  
            ])
            .then(([
                awaitingSubmission, 
                awaitingConfirmation, 
                awaitingDespatch,
                despatched,
                addCallLog,
                addNote,
                uploadDoc,
                viewSupplier
            ]) => {
                this.setState({
                    ...this.state,
                    access: {
                        awaitingSubmission, 
                        awaitingConfirmation, 
                        awaitingDespatch,
                        despatched,
                        addCallLog,
                        addNote,
                        uploadDoc,
                        viewSupplier
                    },
                    currentTab,
                    id
                }, () => this.getData());
            });

        }
    }

    getData = (changeUuid = true) => {
        const { id } = this.state;
        const { pageTitle } = this.props;
        API.get(`/purchaseOrders/${id}`, 
            { 
                props: { 
                    noLoading: true 
                }, 
                params: { 
                    withDefaultDate: true 
                } 
            }
        )
        .then(result => {

            if(result?.data) {
                
                const   po          = result.data,
                        currentTab  = this.state.currentTab ? this.state.currentTab : (_.isEmpty(po.notes) ? 1 : 0),
                        uuid        = changeUuid ? uuidv4() : this.state.uuid;

                this.setState({
                    currentTab,
                    po,
                    uuid
                }, () => {

                    if(pageTitle) {
                        pageTitle([1, "Purchase Orders", po?.supplier?.supp_company_name, `Purchase Order #${po.po_reference}`, po?.po_status])
                    }

                    if(po.po_status === 'Awaiting Submission' && moment(po.po_submission_date, "YYYY-MM-DD").isAfter(moment())) {
                        this.props.deployConfirmation(<><div>{`This purchase order is not due for submission until ${moment(po.po_submission_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`}</div><div>Are you sure you want to submit it now?</div></>, false, () => this.props.history.goBack())
                    }

                    if(!this.poll) {
                        this.handlePoll(true);
                        this.poll = setInterval(this.handlePoll, 3000);
                    }

                })

            }
        })
    }

    getSteps = () => {
        const { po } = this.state;
        const { staff, ui } = this.props;
        return [`Order Placed ${!ui?.device?.isPortrait ? `by ${staff?.[po.po_staff_id]?.name ?? '-'}` : ``}`, 'Awaiting Submission','Awaiting Confirmation','Awaiting Despatch','Despatched'];
    }

    getComponent = () => {
        const { closeDialog, deployConfirmation, deployDialog, deploySnackBar, history, scrollToTop, ui } = this.props;
        let { access, currentTab, delivery, id, po, uuid } = this.state;
        let callback    = () => this.getData(),
            authorised  = false,
            currentStep = null,
            component   = <Unauthorized status={po?.po_status} />,
            tabs        = [],
            noNotes     = _.isEmpty(po.notes);

        const showDeliveryConfiguration = po.po_type === "Stock" && po.po_ship_here_order === 0 && _.isEmpty(po.deliveries);

        switch(po?.po_status) {
            case "Awaiting Submission":
                currentStep = 1;
                if(access.awaitingSubmission) {
                    authorised = true;
                    if(!showDeliveryConfiguration) {
                        tabs = [
                            {
                                label: 'Submit',
                                index: 4,
                            }
                        ];
                    } else {
                        tabs = [
                            {
                                label: 'Notes',
                                index: 0,
                                disabled: noNotes
                            },
                            {
                                label: 'Purchase Order',
                                index: 1
                            },
                            {
                                label: 'Deliveries',
                                index: 2
                            },
                            {
                                label: 'Preview',
                                index: 3
                            },
                            {
                                label: 'Submit',
                                index: 4,
                                disabled: currentTab !== 3
                            }
                        ];
                    }
                    component = <AwaitingSubmission 
                                    id={id} 
                                    key={uuid}
                                    callback={callback}
                                    closeDialog={closeDialog}
                                    contentHeight={ui.contentHeight}
                                    currentTab={currentTab}
                                    deployConfirmation={deployConfirmation} 
                                    deployDialog={deployDialog}
                                    deploySnackBar={deploySnackBar}
                                    handleTabChange={this.handleTabChange}
                                    po={po}
                                    history={history}
                                    inPPO
                                    scrollToTop={scrollToTop}
                                    showDeliveryConfiguration={showDeliveryConfiguration}
                                />;
                }
            break;
            case "Awaiting Confirmation":
                currentStep = 2;
                if(access.awaitingConfirmation) {
                    authorised = true;
                    tabs = [
                        {
                            label: 'Notes',
                            index: 0,
                            disabled: noNotes
                        },
                        {
                            label: 'Purchase Order',
                            index: 1
                        },
                        {
                            label: 'Deliveries',
                            index: 2
                        },
                        {
                            label: 'Review',
                            index: 3,
                        }
                    ];
                    component = <AwaitingConfirmation 
                                    id={id} 
                                    key={uuid}
                                    callback={callback}
                                    closeDialog={closeDialog}
                                    contentHeight={ui.contentHeight}
                                    currentTab={currentTab}
                                    deployConfirmation={deployConfirmation} 
                                    deployDialog={deployDialog}
                                    deploySnackBar={deploySnackBar}
                                    handleTabChange={this.handleTabChange}
                                    po={po}
                                    history={history}
                                    scrollToTop={scrollToTop}
                                />;
                }
            break;
            case "Invoiced":
            case "Despatched":
            case "Awaiting Despatch":

                if(po.po_status === 'Invoiced') {
                        if(_.every(po.products, el => el.quantity === el.quantityInvoiced)) {
                        this.props.deploySnackBar("success", "This purchase order is complete and requires no further action");
                        this.props.history.push(`/purchase-orders/${id}`);
                    }
                }

                currentStep = po.po_status === "Awaiting Despatch" ? 3 : 4;

                if((po.po_status === "Awaiting Despatch" && access.awaitingDespatch) || ((po.po_status === "Despatched" || po.po_status === "Invoiced") && access.despatched)) {
                    authorised = true;
                    tabs = [
                        {
                            label: `Notes`,
                            delivery: null,
                            disabled: noNotes,
                            index: 0
                        },
                        {
                            label: `Purchase Order`,
                            delivery: null,
                            index: 1
                        },
                        {
                            label: `Deliveries`,
                            delivery: null,
                            divider: true,
                            index: 2
                        }
                    ]
                    _.each(po.deliveries, (el, idx) => {
                        tabs.push(
                            {
                                label: `Delivery #${el.pod_reference}/${el.pod_idx}`,
                                subheading: el.pod_status,
                                delivery: el.pod_id,
                                index: (idx + 3)
                            }
                        )
                    })
                    component = <DeliveryStatus 
                                    id={id} 
                                    key={uuid}
                                    callback={callback}
                                    closeDialog={closeDialog}
                                    contentHeight={ui.contentHeight}
                                    currentTab={currentTab}
                                    delivery={delivery}
                                    deployConfirmation={deployConfirmation} 
                                    deployDialog={deployDialog}
                                    deploySnackBar={deploySnackBar}
                                    handleTabChange={this.handleTabChange}
                                    po={po}
                                    history={history}
                                    scrollToTop={scrollToTop}
                                />;
                }
            break;
            // case "Invoiced":
            //     this.props.deploySnackBar("success", "This purchase order is complete and requires no further action");
            //     this.props.history.push(`/purchase-orders/${id}`);
            // break;
            default: 
                /* Nothing */
        }

        if(!authorised || currentStep === null) {

            switch(this.state.currentStep) {
                case 1:
                    this.props.history.push(`/purchase-orders/awaiting-submission`)
                break;
                case 2:
                case 3:
                default:
                    this.props.history.push(`/purchase-orders/${id}`)
                break;
            }

            return null;

        } else {

            if(this.state.currentStep !== 5) {

                if(this.state.currentStep !== currentStep || JSON.stringify(this.state.tabs) !== JSON.stringify(tabs)) {

                    if(isNaN(this.props.match?.params?.id) && this.props.match?.params?.id.includes(":")) {

                        let { id, currentTab }  =   this.state,
                            delivery            =   currentTab;

                        currentTab = _.findIndex(tabs, el => el.delivery === currentTab)

                        if(currentTab === -1) {
                            currentTab = noNotes ? 0 : 1;
                        }

                        this.setState({
                            currentTab,
                            currentStep,
                            delivery,
                            tabs
                        }, () => window.history.replaceState(null, "RED | Advanced Access", `/purchase-orders/process/${id}`))

                    } else {

                        let currentTab  =   this.state.currentStep !== currentStep ? (this.state.currentStep === 3 && currentStep === 4 ? this.state.currentTab : (noNotes ? 1 : 0)) : this.state.currentTab;

                        this.setState({
                            currentTab,
                            currentStep,
                            tabs
                        })

                    }

                }

            }

            return component;

        }

    }

    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            id={this.state.po?.po_id}
            relation="po"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
        />, true, "", "standard","sm", false, true);

    handleDeployAddCall = () => this.props.deployDialog(
        <AddCallLogForm
            id={this.state.po?.po_id}
            relation="po"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
        />, true, "", "standard","xs", false, true);

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.po?.po_id}
            relation="po"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
            standardOnly
            withFao
        />, true, "", "standard","sm", false, true);


    handleDeployFileUpload = () => this.props.deployDialog(
        <DocumentForm 
            id={this.state.po?.po_id}
            type="po"
            category="Purchase Orders"
            callback={() => this.getData(false)} 
            cancel={this.props.closeDialog} 
        />, false, "File Upload", "standard","xs", false, true);

    handleTabChange = (currentTab, delivery = null) => {
        this.setState({
            currentTab,
            delivery
        }, () => {
            if(this.mainContentArea && this.mainContentArea.current) 
                this.mainContentArea.current.scrollTop = 0;
        })
    }

    render() {        
        const { classes, history, ui } = this.props;
        const { access, currentStep, currentTab, initialLoading, po, tabs } = this.state;     
        const canDial = this.context?.dial === null;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(initialLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            Purchase Order #{po.po_reference}
                                                        </Typography>  
                                                    </Grid>
                                                    {po.supplier?.supp_phone?.length > 0 && canDial ? (
                                                        <Grid item>
                                                            <Tooltip title={`Call ${po.supplier.supp_company_name}`}>
                                                                <IconButton onClick={() => this.context.useDial(po.supplier.supp_phone)} size="small">
                                                                    <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={27.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Add Action" placement="top">
                                                    <IconButton onClick={() => this.handleDeployAddTask()}>
                                                        <FAIcon type="light" icon="layer-plus" button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon type="light" icon="comment-alt-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addCallLog && (
                                                <Grid item>
                                                    <Tooltip title="Log Call" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddCall()}>
                                                            <FAIcon type="light" icon="phone-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.uploadDoc && (
                                                <Grid item>
                                                    <Tooltip title="Upload File" placement="top">
                                                        <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                            <FAIcon type="light" icon="file-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title="Download PDF" placement="top">
                                                    <IconButton onClick={() => po.po_legacy ? getLegacyPurchaseOrderPDF(po.po_id) : getPresignedUrl(po.po_pdf_filename)}>
                                                        <FAIcon type="light" icon="file-pdf" style={{color:'#d32f2f'}} button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {/* {access.viewSupplier && (
                                                <Grid item>
                                                    <Tooltip title="View Supplier" placement="top">
                                                        <IconButton onClick={() => history.push(`/suppliers/${po.po_supp_id}`)}>
                                                            <FAIcon type="light" icon="truck" button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )} */}
                                            <Grid item>
                                                <Tooltip title="View Purchase Order" placement="top">
                                                    <IconButton onClick={() => history.push(`/purchase-orders/${po.po_id}`)}>
                                                        <FAIcon type="light" icon="binoculars" button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "layer-plus",
                                                            label: "Add Action",
                                                            onClick: () => this.handleDeployAddTask(),
                                                            display: true
                                                        },
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote
                                                        },
                                                        {
                                                            icon: "phone-plus",
                                                            label: "Log Call",
                                                            onClick: () => this.handleDeployAddCall(),
                                                            display: access.addCallLog
                                                        },
                                                        {
                                                            icon: "file-plus",
                                                            label: "Upload File",
                                                            onClick: () => this.handleDeployFileUpload(),
                                                            display: access.uploadDoc
                                                        },
                                                        {
                                                            icon: "file-pdf",
                                                            label: "Download PDF",
                                                            onClick: () => po.po_legacy ? getLegacyPurchaseOrderPDF(po.po_id) : getPresignedUrl(po.po_pdf_filename),
                                                            display: true,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "binoculars",
                                                            label: "View Purchase Order",
                                                            onClick: () => this.props.history.push(`/purchase-orders/${po.po_id}`),
                                                            display: true
                                                        },
                                                        // {
                                                        //     icon: "truck",
                                                        //     label: "View Supplier",
                                                        //     onClick: () => this.props.history.push(`/suppliers/${po.po_supp_id}`),
                                                        //     display: access.viewSupplier
                                                        // }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={0.5}>
                                                <Grid container justify="space-between" alignItems='center'>
                                                    <Grid item xs={12} lg={10}>
                                                        <Stepper activeStep={currentStep} classes={{root: classes.stepper}} connector={<RedStepConnector />}>
                                                            {this.getSteps().map(label => (
                                                                <Step key={label}>
                                                                    <StepLabel classes={{label: classes.stepLabel}} StepIconComponent={RedStepIcon}>{label}</StepLabel>
                                                                </Step>
                                                            ))}
                                                        </Stepper>
                                                    </Grid>
                                                </Grid> 
                                            </Box>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel>
                                                {this.getComponent()}
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{position: 'relative', height: (contentHeight - 90)}}>
                                <Tabs
                                    classes={classes}
                                    currentTab={currentTab}
                                    po={po}
                                    tabs={tabs}
                                    handleTabChange={this.handleTabChange}
                                />
                                <Box style={{marginTop: _.size(po.deliveries) > 5 ? 24 : undefined, position: _.size(po.deliveries) > 5 ? 'relative' : 'absolute', bottom: 24}}>
                                    <Details
                                        classes={classes}
                                        history={history}
                                        po={po}
                                    />
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        staff: state.notifications.status,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success, cancel = false) => dispatch(deployConfirmation(message, success, cancel)), 
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, de)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ProcessPurchaseOrder));