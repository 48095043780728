const AdminRouteReferences = {
    /* Dashboard */
    "/dashboard": true,
    /* Action Centre */
    "/action-centre": "action-center",
    /* Accounts */
    "/accounts/invoices": "finance-invoices",
    "/accounts/credit-notes": "finance-credit-notes",
    "/accounts/payments/all": "all-payments",
    "/accounts/payments/pending": "pending-payments",
    "/accounts/refunds/all": "all-refunds",
    "/accounts/refunds/pending": "pending-refunds",
    // "/accounts/payments/authorised": "authorised-payments",
    // "/accounts/payments/declined": "declined-payments",
    "/accounts/credit/all": "credit-accounts",
    "/accounts/credit/lapsed": "lapsed-accounts",
    "/accounts/credit/over-limit": "overlimit-accounts",
    "/accounts/account-application-form/requests": "acc-app-form-requests",
    "/accounts/online-credit-applications": "tz-crapp",
    "/accounts/credit-reports/requests": "credit-report-requests",
    "/accounts/sage": "sage-log",
    "/accounts/profile-updates": "cpu-accounts",
    "/accounts/account-opening/prepay": "acc-op-req-pp",
    "/accounts/account-opening/credit": "acc-op-req-ca",
    /* Calendar */
    "/calendar": "calendar",
    /* Card DB */
    "/card-orders/new": "add-card-order",
    "/card-orders/update/": "view-card-order:update-card-order",
    "/card-orders": "card-order-search",
    /* Communication */
    "/communication": "communication",
    /* Customers */
    // "/customers/new": "add-customer",
    "/customers/onboarding": "cust-onb",
    "/customers/profile-updates": "cpu-admin",
    "/customers/update/": "update-customer",
    "/customers/": "view-customer",
    "/customers": "customer-search",
    /* Marketing */
    "/marketing/trade-zone-requests": "tz-onaccreq",
    "/marketing/requests/catalogues": "catalogue-requests",
    "/marketing/requests/social-media": "sm-req",
    "/marketing/mailing-list-export": "mailing-list-export",
    "/marketing/communication": "settings-communication",
    "/marketing/documents": "settings-customisation",
    "/marketing/shortlinks": "shortlink-search",
    "/marketing/website": "marketing:website",
    /* Meetings */
    "/meetings": "meeting-search",
    /* New Business */
    "/new-business/new": "add-prospect",
    "/new-business/update/": "update-prospect",
    "/new-business/": "view-new-business",
    "/new-business": "new-business",
    /* Products */
    "/products/recently-added": "product-search-recent",
    "/products/bundles/": "product-bundles:view",
    "/products/bundles": "product-bundles:search",
    "/products/bulk-management/new": "bulk-prd-batch",
    "/products/bulk-management/": "bulk-prd-view",
    "/products/bulk-management": "bulk-prd-mgt",
    "/products/new": "add-product",
    "/products/update/": "view-product:update-product",
    "/products/": "view-product",
    "/products": "product-search",
    /* Purchase Orders */
    "/purchase-orders/outstanding": "po-os",
    "/purchase-orders/awaiting-submission": "po-awaiting-submission",
    "/purchase-orders/awaiting-confirmation": "po-awaiting-confirmation",
    "/purchase-orders/awaiting-despatch": "po-awaiting-despatch",
    "/purchase-orders/despatched": "po-despatched",
    "/purchase-orders/on-hold": "po-on-hold",
    "/purchase-orders/invoiced": "po-invoiced",
    "/purchase-orders/cancelled": "po-cancelled",
    "/purchase-orders/sales-order-fulfilment": "so-po",
    "/purchase-orders/warehouse-replenishment": "stock-po",
    "/purchase-orders/new": "add-po",
    "/purchase-orders/process/": "process-po",
    "/purchase-orders/replicate/": "replicate-po",
    "/purchase-orders/live-update/": "update-po",
    "/purchase-orders/update/": "update-po",
    "/purchase-orders/": "view-po",
    "/purchase-orders": "all-po",
    /* Quotes */
    "/quotes/hot": "hot-quotes",
    "/quotes/my": "my-quotes",
    "/quotes/draft": "draft-quotes",
    "/quotes/awaiting-approval": "awaiting-approval-quotes",
    "/quotes/open": "open-quotes",
    "/quotes/ordered": "ordered-quotes",
    "/quotes/lost": "lost-quotes",
    "/quotes/declined": "declined-quotes",
    "/quotes/new": "add-quote",
    "/quotes/replicate/": "replicate-quote",
    "/quotes/update/": "update-quote",
    "/quotes/": "view-quote",
    "/quotes": "search-quotes",
    /* Reporting */
    "/reporting/call": "reporting-voip",
    "/reporting/customers": "reporting-customers",
    "/reporting/marketing": "reporting-marketing",
    "/reporting/misc": "reporting-misc",
    "/reporting/products": "reporting-products",
    "/reporting/sales": "reporting-sales",
    "/reporting/staff": "reporting-staff",
    "/reporting/stock": "reporting-stock",
    "/reporting/suppliers": "reporting-suppliers",
    /* RMA */
    "/rma/new": "add-rma",
    "/rma/awaiting-approval": "search-rma-approval",
    "/rma/open": "search-rma-open",
    "/rma/declined": "search-rma-declined",
    "/rma/complete": "search-rma-complete",
    "/rma/cancelled": "search-rma-cancelled",
    "/rma/goods-received": "search-rma-goods-received",
    "/rma/with-supplier": "search-rma-with-supplier",
    // "/rma/with-customer": "search-rma-with-customer",
    "/rma/approve/": "rma-approval",
    "/rma/update/": "update-rma",
    "/rma/replacement/": "add-order",
    "/rma/": "view-rma",
    "/rma": "search-rma",
    /* Sales */
    "/sales/my": "my-orders",
    "/sales/outstanding": "os-orders",
    "/sales/awaiting-approval": "awaiting-approval-orders",
    "/sales/awaiting-payment": "awaiting-payment-orders",
    "/sales/awaiting-authorisation": "awaiting-authorisation-orders",
    "/sales/processing": "processing-orders",
    "/sales/awaiting-allocation": "awaiting-allocation-orders",
    "/sales/awaiting-stock": "awaiting-stock-orders",
    "/sales/awaiting-supplier": "awaiting-supplier-orders",
    "/sales/awaiting-picking": "awaiting-picking-orders",
    "/sales/ready-for-collection": "ready-for-collection-orders",
    "/sales/awaiting-check": "awaiting-check-orders",
    "/sales/awaiting-technical": "awaiting-technical-orders",
    "/sales/awaiting-despatch": "awaiting-despatch-orders",
    "/sales/pending-release": "locked-orders",
    "/sales/on-hold": "on-hold-orders",
    "/sales/despatched": "despatched-orders",
    "/sales/invoiced": "invoiced-orders",
    "/sales/cancelled": "cancelled-orders",
    "/sales/new": "add-order",
    "/sales/process/": "process-order",
    "/sales/replicate/": "replicate-order",
    "/sales/update/": "update-order",
    "/sales/": "view-order",
    "/sales": "all-orders",
    /* Settings */
    "/settings/accounts": "settings-accounts",
    "/settings/actions": "settings-actions",
    "/settings/calendar": "calendar-categories",
    "/settings/customers": "settings-customers",
    "/settings/delivery": "settings-carriage",
    "/settings/discounts": "settings-discounts",
    "/settings/e-commerce": "settings-ecommerce",
    "/settings/files": "settings-documents",
    "/settings/products": "settings-products",
    "/settings/purchase-orders": "settings-po",
    "/settings/quotes": "settings-quotes",
    "/settings/sales-orders": "settings-orders",
    "/settings/suppliers": "settings-suppliers",
    "/settings/warehouse": "settings-warehouse",
    /* Staff */
    "/staff/": "view-staff",
    "/staff": "staff",
    /* Stock */
    "/stock": "stock",
    /* Suppliers */
    "/suppliers/new": "add-supplier",
    "/suppliers/update/": "update-supplier",
    "/suppliers/": "view-supplier",
    "/suppliers": "suppliers",
    /* Support */
    "/support": "support",
    /* Targets */
    "/targets": "targets",
    /* Teams */
    "/teams/": "view-team",
    "/teams": "teams",
    /* Technical */
    "/technical/builds/completed": "tech-build-history",
    "/technical/builds/outstanding": "tech-builds",
    /* Warehouse */
    "/warehouse/process/": "wh-processing",
    "/warehouse/outgoing/awaiting-stock": "wh-awaiting-stock",
    "/warehouse/outgoing/awaiting-picking": "wh-awaiting-picking",
    "/warehouse/outgoing/ready-for-collection": "wh-ready-for-collection",
    "/warehouse/outgoing/awaiting-check": "wh-awaiting-check",
    "/warehouse/outgoing/awaiting-technical": "wh-awaiting-technical",
    "/warehouse/outgoing/awaiting-despatch": "wh-awaiting-despatch",
    "/warehouse/outgoing/despatched": "wh-despatched",
    "/warehouse/outgoing/on-hold": "wh-on-hold",
    "/warehouse/incoming/awaiting-delivery": "wh-awaiting-delivery",
    "/warehouse/incoming/delivered": "wh-delivered",
    "/warehouse/incoming/": "wh-process-grp",
    "/warehouse/picks": "wh-picks",
    "/warehouse/checks": "wh-checks",
    "/warehouse/despatches": "wh-despatches",
    "/warehouse/collections": "wh-collections",
    "/warehouse/consignment-stock-edits": "con-stock-edits",
    /* Pre-authorized */
    "/my-account/": true,
    "/my-account": true,
};

export default AdminRouteReferences;
