import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { Hidden } from '@material-ui/core';

const initialState = { 
    customers: [],  
    defaultQat: "0.00",
    formData: {},
    initalFormData: {},
    isLoading: true,
    isSaving: false
}

class QuoteApproval extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getQuoteApproval();
    }

    getQuoteApproval = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/settings/quoteApproval')
            .then(result => {

                const   customers           = result.data.customers,
                        defaultQat          = result.data.default,
                        initialDefaultQat   = defaultQat;

                let formData = {}, isLoading = false;

                _.each(customers, cust => {
                    formData = {
                        ...formData,
                        [cust.cust_id]: cust.qat ? cust.qat : ''
                    }
                })

                let initialFormData = formData;

                this.setState({
                    ...this.state,
                    customers,
                    defaultQat,
                    formData,
                    initialDefaultQat,
                    initialFormData,
                    isLoading,
                })

            })
        })
    }

    handleChange = (id, e) => {
        this.setState({
            formData: {
                ...this.state.formData, 
                [id]: e.target.value
            }
        })
    }

    handleBlur = (id, e) => {
        if(this.state.formData[id] < 0) { 
            this.setState({
                formData: {
                    ...this.state.formData, 
                    [id]: ""
                }
            })
        } else { 
            if(!_.isEmpty(this.state.formData[id])) { 
                this.setState({
                    formData: {
                        ...this.state.formData, 
                        [id]: parseFloat(this.state.formData[id]).toFixed(2)
                    }
                })
            } 
        }
    }

    handleDefaultChange = e => {
        this.setState({
            defaultQat: e.target.value
        })
    }

    handleDefaultBlur = e => {
        if(this.state.defaultQat < 0) { 
            this.setState({
                defaultQat: "0.00"
            })
        } else { 
            if(!_.isEmpty(this.state.defaultQat)) { 
                this.setState({
                    defaultQat: parseFloat(this.state.defaultQat).toFixed(2)
                })
            } 
        }
    }

    submit = () => {
        this.setState({
            isLoading: true,
            isSaving: true
        }, () => {

            let formData = {}

            Object.keys(this.state.formData).forEach(custId => {
                if(this.state.formData[custId] !== this.state.initialFormData[custId]) {
                    formData = {
                        ...formData,
                        [custId]: this.state.formData[custId]
                    }
                }
            })

            API.put('/settings/quoteApproval', { customers: formData })
            .then(result => {
                if(result?.data) {
                    this.setState({
                        isSaving: false
                    }, () => {
                        this.getQuoteApproval();
                        this.props.scrollToTop();
                        this.props.deploySnackBar("success", "Customer quote approval thresholds have been successfully updated")
                    })
                }
            });
        })
    }

    updateDefault = () => {
        const { defaultQat } = this.state;
        this.setState({
            isLoading: true
        }, () => {
            API.put('/settings/quoteApproval/default', { defaultQat })
            .then(result => {
                if(result?.data) {
                    this.getQuoteApproval();
                    this.props.scrollToTop();
                    this.props.deploySnackBar("success", "The default quote approval threshold has been successfully updated")
                }
            });
        })
    }

    render() {
        const { customers, defaultQat, formData, isLoading, isSaving } = this.state;
        return (
            <Grid container spacing={!isLoading ? 3 : 0}>
                {(isLoading && (
                    <>
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                        {isSaving && (
                            <Grid item xs={12} align='center'>
                                <Typography variant="h5" gutterBottom>
                                    Saving Quote Approval Configuration
                                </Typography>
                                <Typography variant="body2">
                                    Please do not leave this page or close RED
                                </Typography>
                            </Grid>
                        )}
                    </>
                )) || (
                    <>
                        <Hidden mdDown>
                            <Grid item xs={false} lg={8} />
                        </Hidden>
                        <Grid item xs={12} lg={4}>
                            <PaddedPaper>
                                <Typography variant="h6" gutterBottom>
                                    Default Quote Approval Threshold
                                </Typography>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs>
                                        <TextField
                                            value={defaultQat}
                                            onChange={this.handleDefaultChange}
                                            onBlur={this.handleDefaultBlur}
                                            margin="none"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" noMargin /></InputAdornment>
                                            }}
                                            inputProps={{
                                                step: 1,
                                                min: 0
                                            }}
                                            type="number"
                                            fullWidth
                                        />
                                    </Grid>                       
                                    <Grid item>
                                        <Button
                                            disabled={this.state.initialDefaultQat === defaultQat || _.isEmpty(defaultQat)}
                                            variant="text"
                                            color="primary"
                                            size="small"
                                            onClick={() => this.props.deployConfirmation("Are you sure you want to update the default Quote Approval Threshold?", () => this.updateDefault())}
                                        >
                                            <FAIcon type="light" icon="check" size="small" button />
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={3} className="mb-1">
                                    <Grid item xs>
                                        <Typography variant="h6" gutterBottom>
                                            Customer Quote Approval Threshold
                                        </Typography>
                                    </Grid>                            
                                    <Grid item>
                                        <Button
                                            disabled={JSON.stringify(this.state.initialFormData) === JSON.stringify(formData)}
                                            variant="text"
                                            color="primary"
                                            size="small"
                                            onClick={() => this.props.deployConfirmation("Are you sure you want to update Customer Quote Approval Thresholds?", () => this.submit())}
                                        >
                                            <FAIcon type="light" icon="check" size="small" button />
                                            Save Changes
                                        </Button>
                                    </Grid>
                                </Grid>
                                <DataTable
                                    config={{
                                        key: 'cust_id',
                                        pagination: true,
                                        rowsPerPage: 50,
                                        alternatingRowColours: true,
                                        responsiveImportance: true,
                                        inline: true,
                                        options: {
                                            plainPagination: true,
                                            dataRef: true,
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: 'Customer',
                                            field: rowData => (
                                                <>
                                                    {rowData.name}
                                                    <Typography variant="caption" component="div">{rowData.acc}</Typography>
                                                </>
                                            ),
                                            dataRef: 'name',
                                            main: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Credit Limit',
                                            field: rowData => rowData.credit_l,
                                            fieldFormat: 'currency',
                                            dataRef: 'credit_l',
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Credit Balance',
                                            field: rowData => rowData.credit_b,
                                            fieldFormat: 'currency',
                                            dataRef: 'credit_b',
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Net Total',
                                            field: rowData => rowData.total ?? '0',
                                            fieldFormat: 'currency',
                                            dataRef: 'total',
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Net Average',
                                            field: rowData => rowData.avg ?? '0',
                                            fieldFormat: 'currency',
                                            dataRef: 'avg',
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Orders',
                                            field: rowData => rowData.orders,
                                            dataRef: 'orders',
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Euler Cover',
                                            field: rowData => rowData.elr,
                                            fieldFormat: 'currency',
                                            dataRef: 'elr',
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Payment Terms',
                                            field: rowData => rowData.pt,
                                            dataRef: 'pt',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Account Code',
                                            field: rowData => (
                                                <>
                                                    {rowData.code} - {rowData.team}
                                                    <Typography variant="caption" component="div">{rowData.status ?? '-'}</Typography>
                                                </>
                                            ),
                                            dataRef: 'code',
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Quote Approval (Net Total) Threshold',
                                            field: rowData => (
                                                <TextField
                                                    label="Net Total"
                                                    placeholder="No Quote Approval Threshold"
                                                    value={formData[rowData.cust_id]}
                                                    onChange={e => this.handleChange(rowData.cust_id, e)}
                                                    onBlur={e => this.handleBlur(rowData.cust_id, e)}
                                                    margin="none"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size="small" noMargin /></InputAdornment>
                                                    }}
                                                    inputProps={{
                                                        step: 1,
                                                        min: 0
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            ),
                                            dataRef: 'qat',
                                            important: true,
                                        }
                                    ]}
                                    rows={customers}
                                />
                            </PaddedPaper>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(QuoteApproval);