import _ from 'lodash';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { capitalize } from 'Functions/MiscFunctions';

const WildixCallDetails = ({
    call, 
    callData,
    callLogs,
    getCallActions,
    getCallStatusClass,
}) => {

    const staff = useSelector(state => state.notifications.status);

    const getIcon = () => {
        return call.isRinging() ? 'phone-volume' : call.isOnHold() ? 'pause-circle' : call.isIncoming() ? 'phone-arrow-down-left' : 'phone-arrow-up-right';
    }

    const getIconColor = () => {
        return call.isOnHold() ? '#d32f2f' : '#000000'
    }

    const data = callData?.find?.(c => c.ch === call.getChannel())?.dt ?? null;

    const log = callLogs?.find?.(c => c.ch === call.getChannel()) ?? null;

    return (
        <Box>
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Box className="content-light-grey" p={1}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <Box minWidth={50} textAlign="center">
                                    <FAIcon className={call.isRinging() ? `icon-shake` : ``} icon={getIcon()} type="thin" color={getIconColor()} size={30} />
                                </Box>
                            </Grid>
                            <Grid item xs align="left">
                                {((data?.fn || data?.ln) && (
                                    <>
                                        <Typography variant="body1" className="fw-400">
                                            {data.fn} {data.ln}
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            {data.cn ?? 'Unknown Company'}{data?.as ? ` (${data.as})` : ``}
                                        </Typography>
                                        <Typography variant="body2">
                                            {call.isRinging() ? call.isIncoming () ? `${capitalize(call.getDirection())} call ` : `Dialling `  : ``}
                                            {call.getCalleeNumber() === 'anonymous' ? 'Anonymous' : call.getCalleeNumber()}
                                        </Typography>
                                        {data?.am && staff?.[data.am] && (
                                            <StaffChip label="A/C: " staff={staff[data.am]} />
                                        )}
                                    </>
                                )) || (data?.cn && (
                                    <>
                                        <Typography variant="body1" className="fw-400">
                                            {data.cn}{data?.as ? ` (${data.as})` : ``}
                                        </Typography>
                                        <Typography variant="body2">
                                            {call.isRinging() ? call.isIncoming () ? `${capitalize(call.getDirection())} call ` : `Dialling `  : ``}
                                            {call.getCalleeNumber() === 'anonymous' ? 'Anonymous' : call.getCalleeNumber()}
                                        </Typography>
                                        {data?.am && (
                                            <StaffChip label="A/C: " staff={staff[data.am]} />
                                        )}
                                    </>
                                )) || (
                                    <>
                                        <Typography variant="body1" className="fw-400">
                                            {call.isRinging() ? call.isIncoming () ? `${capitalize(call.getDirection())} call ` : `Dialling `  : ``}
                                            {call.getCalleeNumber() === 'anonymous' ? 'Anonymous' : call.getCalleeName()}
                                        </Typography>
                                        <Typography variant="body2">
                                            {call.getCalleeNumber() === 'anonymous' ? 'Anonymous' : call.getCalleeNumber()}
                                        </Typography>
                                        {data?.mc && (
                                            <Typography variant="body2" className="textError">
                                                Multiple account matches found
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="content-light-white" p={1} borderTop={0}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <span className={getCallStatusClass(call)}>
                                    {call.getFormattedState()}
                                </span>
                            </Grid>
                            {!call.isRinging() && (
                                <Grid item>
                                    {call.getFormattedDuration()}      
                                </Grid>
                            )}
                            {!call.isRinging() && log && (data?.ci || data?.si) && (
                                <Grid item xs={12} align="left">
                                    {(log.nt === '' && (
                                        <Typography variant="body2">
                                            <em>No notes added yet</em>
                                        </Typography>
                                    )) || (
                                        log.nt?.split?.(`\n`).map?.((line, idx) => (
                                            <Typography key={idx} variant="body2">
                                                {line}&nbsp;
                                            </Typography>
                                        ))
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="content-light-white" p={1} borderTop={0}>
                        <Grid container justify="center">
                            {_.map(getCallActions(call, data?.ci ?? null, data?.ca ?? false), (action, idx) => (
                                <Grid item key={idx}>
                                    <Tooltip title={action.name}>
                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                e.stopPropagation();
                                                action.onClick();
                                            }}
                                        >
                                            <FAIcon 
                                                icon={action.icon} 
                                                className={action.className ?? ''}
                                                type={action.type ?? 'thin'} 
                                                size={22.5} 
                                                noMargin
                                                button
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            ))} 
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default WildixCallDetails;
