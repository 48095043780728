import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StackTable from 'Components/Common/DataTables/StackTable';
import StaffChip from 'Components/Common/Chips/StaffChip';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { currencyFormat, getMonthDaysLeft, momentFormatDateTime, TabPanel } from 'Functions/MiscFunctions';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import InsightChip from 'Components/Common/Chips/InsightChip';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const TargetTotals = ({classes, targets}) => (
    <TableFooter>
        <TableRow className='pl-1'>
            <TableCell className={`${classes.td} fw-400`}>
                Totals
            </TableCell>
            <TableCell className={`${classes.td} fw-400`} align='right'>
                {currencyFormat.format(_.sumBy(_.map(targets, el => el.value), _el => parseFloat(_el || 0)))}
            </TableCell>
            <TableCell className={`${classes.td} fw-400`} align='right'>
                {currencyFormat.format(_.sumBy(_.map(targets, el => el.grossSales), _el => parseFloat(_el || 0)))}
            </TableCell>
            <TableCell className={`${classes.td} fw-400`} align='right'>
                {currencyFormat.format(_.sumBy(_.map(targets, el => el.sales), _el => parseFloat(_el || 0)))}
            </TableCell>
            <TableCell className={`${classes.td} fw-400`} align='right'>
                {currencyFormat.format(_.sumBy(_.map(targets, el => el.credit), _el => parseFloat(_el || 0)))}
            </TableCell>
            <TableCell className={`${classes.td} fw-400`} align='right'>
                {currencyFormat.format(_.sumBy(_.map(targets, el => el.notInvoiced), _el => parseFloat(_el || 0)))}
            </TableCell>
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
        </TableRow>  
    </TableFooter>
)

const initialState = () => ({
    currentTab: 1,
    changed: false,
    daysLeft: getMonthDaysLeft(),
    initialLoading: true,
    isLoading: true,
    targets: [],
    type: 'month',
    year: moment().format('M') <= 3 ? moment().subtract(1, 'year').format('YYYY') : moment().format('YYYY'),
    years: [
        {
            label: "2021",
            value: 2021
        },
        {
            label: "2022",
            value: 2022
        },
        {
            label: "2023",
            value: 2023
        },
        {
            label: "2024",
            value: 2024
        },
        {
            label: "2025",
            value: 2025
        },
        {
            label: "2026",
            value: 2026
        },
        {
            label: "2027",
            value: 2027
        },
        {
            label: "2028",
            value: 2028
        },
        {
            label: "2029",
            value: 2029
        },
        {
            label: "2030",
            value: 2030
        },
    ]
});

class Targets extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.mainContentArea = React.createRef();
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        this.setState({
            isLoading: true
        }, () => {

            const {
                year,
                type
            } = this.state;

            API.get(`/targets/sales`, {
                params: {
                    year,
                    type
                }
            }) 
            .then(res => {
                if(res?.data) {

                    const {
                        year
                    } = this.state;

                    const   targets         = res?.data ?? [],
                            isLoading       = false,
                            initialLoading  = false,
                            changed         = false;

                    this.props.pageTitle([0, 'Targets', `Sales Targets ${year} - ${(parseInt(year) + 1)}`, `${this.state.type === 'month' ? 'Monthly' : 'Weekly'}`]);

                    this.setState({
                        changed,
                        initialLoading,
                        isLoading,
                        targets
                    })
                }
            })
        })
    }

    handleYearChange = o => {
        if(this.state.changed) {
            this.props.deployConfirmation(`You have unsaved changes, do you want to switch financial year?`, () => this.handleDoYearChange(o))
        } else {
            this.handleDoYearChange(o);
        }
    }

    handleDoYearChange = o => {
        this.setState({
            year: o?.value ?? moment().format("YYYY"),
        }, this.loadComponentData)
    }

    handleTabChange = (event, value) => {
        if(this.state.changed) {
            this.props.deployConfirmation(`You have unsaved changes, do you want to switch tab?`, () => this.handleDoTabChange(value))
        } else {
            this.handleDoTabChange(value);
        }
    }

    handleDoTabChange = (value) => {
        this.setState({
            currentTab: value,
            type: value === 1 ? 'month' : 'week'
        }, this.loadComponentData)
    }
    
    handleTargetChange = (idx, v) => {
        
        let {
            targets
        } = this.state;

        let value = v, 
            changed = true;

        if(!_.isEmpty(value)) {
            if(value < 0) {
                value = '';
            }
        }

        targets = {
            ...targets,
            [idx]: {
                ...targets[idx],
                value
            }
        }

        this.setState({
            changed,
            targets
        })

    }

    handleTargetBlur = (idx) => {
        
        let {
            targets
        } = this.state;

        targets = {
            ...targets,
            [idx]: {
                ...targets[idx],
                value: !_.isEmpty(targets[idx].value) ? parseFloat(targets[idx].value ?? 0).toFixed(2) : targets[idx].value
            }
        }

        this.setState({
            targets
        })

    }

    handleLockTarget = (year, month) => {

        const { 
            deploySnackBar
        } = this.props;
        
        const formData = {
            year,
            month
        }

        API.put(`/targets/sales/lock`, formData) 
        .then(res => {
            if(res?.data) {
                if(res?.data?.errors) {

                    deploySnackBar("error", "This month has already been locked");
                    this.loadComponentData();

                } else {
                    
                    deploySnackBar(`success`, `You have successfully locked the sales target for ${moment.months(month - 1)} ${year}`);
                    this.loadComponentData();

                }
            }
        })
    }

    handleSubmit = () => {

        this.setState({
            isLoading: true
        }, () => {

            const { 
                deploySnackBar
            } = this.props;

            const { 
                type,
                targets,
                year
            } = this.state;

            API.put(`/targets/sales`, {
                type,
                year,
                targets
            })
            .then(res => {
                if(res?.data) {
                    if(res?.data?.errors) {

                        deploySnackBar("error", "This month has already been locked");
                        this.loadComponentData();

                    } else {

                        deploySnackBar(`success`, `You have successfully updated ${type}ly targets for ${year} - ${(parseInt(year) + 1)}`);
                        this.loadComponentData();

                    }
                }
            })

        })
    }

    render = () => {

        const { 
            classes,
            deployConfirmation,
            staff,
            ui
        } = this.props;

        const { 
            currentTab,
            daysLeft,
            initialLoading,
            isLoading, 
            targets, 
            type,
            year,
            years
        } = this.state;

        const   height          =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : `${ui.device.height}`,
                contentHeight   =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(initialLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            {currentTab === 0 ? 'Sales Target Breakdown' : `Sales Targets ${year} / ${(parseInt(year) + 1)}`}
                                                        </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {(currentTab === 0 && (
                                                <Grid item>
                                                    <Box p={1.25}>
                                                        &nbsp;
                                                    </Box>
                                                </Grid>
                                            )) || (
                                                <>
                                                    <Grid item>
                                                        <Box p={1.25} className="textDefault">
                                                            Select year
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box width={200}>
                                                            <AutoCompleteSelect
                                                                options={years}
                                                                onChange={this.handleYearChange}
                                                                value={parseInt(year)}
                                                                variant="filled"
                                                                small
                                                                formatOptionLabel={({label}) => (
                                                                    <Box pt={1} pb={1} pl={1}>
                                                                        <Grid container alignItems='center'>
                                                                            <Grid item>
                                                                                <FAIcon icon="calendar-alt" size={13.5} button />
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <Typography variant="body2" component="div">
                                                                                    {label} - {(parseInt(label) + 1)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                )}
                                                                noClear
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box>
                                                <Grid container spacing={1} alignItems='center'>
                                                    {currentTab === 0 && (
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="calendar-alt"
                                                                label={`${moment().format("MMMM YYYY")}`}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {currentTab === 0 && (
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="user"
                                                                label={`X days worked`}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {currentTab === 0 && (
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon={daysLeft > 7 ? 'check' : (daysLeft > 1 ? 'exclamation-circle' : 'exclamation-triangle')}
                                                                label={`${daysLeft > 0 ? `${daysLeft} day${daysLeft > 1 ? `s` : ``} remaining` : `Last day of ${moment().format("MMMM")}`}`}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {currentTab !== 0 && (
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="calendar-alt"
                                                                label={`${type === 'month' ? 'Monthly Target Breakdown' : 'Weekly Target Breakdown'}`}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid> 
                                            </Box>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                [placeholder]
                                            </TabPanel>  
                                            <TabPanel value={currentTab} index={1}>
                                                {(isLoading && (
                                                    <LoadingCircle />
                                                )) || (
                                                    <Grid container spacing={3}> 
                                                        <Grid item xs={12}>
                                                            <Paper>
                                                                <Box p={3}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <Table>
                                                                                <TableBody>
                                                                                    <TableRow className='pl-1'>
                                                                                        <TableCell className={classes.td} style={{width: '20%'}} />
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Target
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Progress
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Total Net Invoiced
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Total Net Credits
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Total Net Not Invoiced
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: 20}} />
                                                                                        <TableCell className={classes.td} style={{width: 43}} />
                                                                                        <TableCell className={classes.td} />
                                                                                    </TableRow>  
                                                                                    {_.map(targets, (month, idx) => (
                                                                                        <TableRow className='pl-1' ley={idx}>
                                                                                            <TableCell className={classes.td}>
                                                                                                <Typography variant="body2" className="fw-400">
                                                                                                    {moment.months(month.tsm_month - 1)} {month.tsm_year}
                                                                                                </Typography>
                                                                                                <Typography variant="caption">
                                                                                                    {month.st} - {month.et}
                                                                                                </Typography>
                                                                                                <Typography variant="caption" component={ui?.device?.isTablet ? 'div' : 'span'}>
                                                                                                    ({month.wd} {!ui?.device?.isPortrait && `working`} days)
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled={parseInt(month?.tsm_locked ?? 1) === 1}
                                                                                                    name="value"
                                                                                                    value={month?.value}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    onChange={e => this.handleTargetChange(month.idx, e?.target?.value ?? "")}
                                                                                                    onBlur={() => this.handleTargetBlur(month.idx, month?.tsm_month)}
                                                                                                    inputProps={{
                                                                                                        min: 0
                                                                                                    }}
                                                                                                    InputProps={{
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                        endAdornment: (<InputAdornment position="end"><FAIcon icon={parseInt(month?.tsm_locked ?? 0) === 1 ? `lock-alt` : `pencil`} className={parseInt(month?.tsm_locked ?? 0) === 1 ? `textError` : ``} size={15} noMargin /></InputAdornment>)
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled
                                                                                                    name="value"
                                                                                                    value={month?.grossSales}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                        endAdornment: (<InputAdornment position="end"><span className={month?.value > 0 ? (parseInt(((parseFloat(month?.grossSales) / parseFloat(month?.value)) * 100).toFixed(0)) > 100 ? `textSuccess`: `textError`) : ``}>{month?.value > 0 ? `${((parseFloat(month?.grossSales) / parseFloat(month?.value)) * 100).toFixed(0)}%` : '-'}</span></InputAdornment>)
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled
                                                                                                    name="value"
                                                                                                    value={month?.sales}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled
                                                                                                    name="value"
                                                                                                    value={month?.credit}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled
                                                                                                    name="value"
                                                                                                    value={month?.notInvoiced}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td} />
                                                                                            <TableCell className={classes.td}>
                                                                                                <Tooltip 
                                                                                                    disabled={_.isEmpty(month?.audit)}
                                                                                                    title={(
                                                                                                        <Box p={0} width={500} position='absolute' marginTop={-1} marginLeft={-50}>
                                                                                                            <StackTable
                                                                                                                heading="Target History"
                                                                                                                rows={_.map(month?.audit, a => (
                                                                                                                    _.assign({
                                                                                                                        heading: `${momentFormatDateTime(a?.audit_created_at)}`,
                                                                                                                        value: (<Grid container alignItems='center'><Grid item><Typography variant="caption" className="fw-400 mr-1">{a.audit_comments}</Typography></Grid><Grid item xs><StaffChip staff={staff[a?.audit_staff_id ?? 0]} /></Grid></Grid>),
                                                                                                                    })
                                                                                                                ))}
                                                                                                            />
                                                                                                        </Box>
                                                                                                    )}
                                                                                                >
                                                                                                    <IconButton>
                                                                                                        <FAIcon icon="eye" noMargin button size={20} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <Tooltip title='Lock Target'>
                                                                                                    <IconButton
                                                                                                        onClick={() => deployConfirmation(`Are you sure you want to lock the monthly target for ${moment.months(((month?.tsm_month ?? 1) - 1))} ${year}?`, () => this.handleLockTarget(month?.tsm_year, month?.tsm_month))}
                                                                                                        disabled={parseInt(month?.tsm_locked ?? 1) === 1 || moment().isBefore(moment(month.et, "DD/MM/YYYY"))}
                                                                                                    >
                                                                                                        <FAIcon icon="lock" noMargin button size={20} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                        </TableRow>  
                                                                                    ))}
                                                                                </TableBody>
                                                                                <TargetTotals classes={classes} targets={targets} />
                                                                            </Table>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <div className="buttonRow">
                                                                        <Button
                                                                            color="primary"
                                                                            disabled={_.every(targets, el => parseFloat(el.value) === parseFloat(el.tsm_target)) || _.some(targets, el => el?.value === "")}
                                                                            onClick={() => deployConfirmation(<>Are you sure you want to update monthly targets for {year} - {(parseInt(year) + 1)}?</>, () => this.handleSubmit('month'))}
                                                                            variant="contained"
                                                                        >
                                                                            <FAIcon icon="check" size={15} button />
                                                                            Update Monthly Targets
                                                                        </Button>
                                                                    </div>
                                                                </Box>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                {(isLoading && (
                                                    <LoadingCircle />
                                                )) || (
                                                    <Grid container spacing={3}> 
                                                        <Grid item xs={12}>
                                                            <Paper>
                                                                <Box p={3}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <Table>
                                                                                <TableBody>
                                                                                    <TableRow className='pl-1'>
                                                                                        <TableCell className={classes.td} style={{width: '20%'}} />
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Target
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Progress
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Total Net Invoiced
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Total Net Credits
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                            <Typography variant="h5" className="fw-400" align="center" paragraph>
                                                                                                Total Net Not Invoiced
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td} style={{width: 9}} />
                                                                                        <TableCell className={classes.td} style={{width: 43}} />
                                                                                    </TableRow>  
                                                                                    {_.map(targets, (week, idx) => (
                                                                                        <TableRow className='pl-1' ley={idx}>
                                                                                            <TableCell className={classes.td}>
                                                                                                <Typography variant="body2" className="fw-400">
                                                                                                    Week {week.tsw_week}/{year} - {week?.month}
                                                                                                </Typography>
                                                                                                <Typography variant="caption">
                                                                                                    {week.st} - {week.et} 
                                                                                                </Typography>
                                                                                                <Typography variant="caption" component={ui?.device?.isTablet ? 'div' : 'span'}>
                                                                                                    ({week.wd} {!ui?.device?.isPortrait && `working`} days)
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    name="value"
                                                                                                    value={week?.value}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    onChange={e => this.handleTargetChange(week.idx, e?.target?.value ?? "")}
                                                                                                    onBlur={() => this.handleTargetBlur(week.idx, week?.tsw_week)}
                                                                                                    inputProps={{
                                                                                                        min: 0
                                                                                                    }}
                                                                                                    InputProps={{
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>)
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled
                                                                                                    name="value"
                                                                                                    value={week?.grossSales}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        // className: "textDefault",
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                        endAdornment: (<InputAdornment position="end"><span className={week?.value > 0 ? (parseInt(((parseFloat(week?.grossSales) / parseFloat(week?.value)) * 100).toFixed(0)) > 100 ? `textSuccess`: `textError`) : ``}>{week?.value > 0 ? `${((parseFloat(week?.grossSales) / parseFloat(week?.value)) * 100).toFixed(0)}%` : '-'}</span></InputAdornment>)
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled
                                                                                                    name="value"
                                                                                                    value={week?.sales}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        // className: "textDefault",
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled
                                                                                                    name="value"
                                                                                                    value={week?.credit}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        // className: "textDefault",
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td}>
                                                                                                <TextField
                                                                                                    disabled
                                                                                                    name="value"
                                                                                                    value={week?.notInvoiced}
                                                                                                    type="number"
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        // className: "textDefault",
                                                                                                        startAdornment: (<InputAdornment position="start">&pound;</InputAdornment>),
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    size='small'
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.td} />
                                                                                            <TableCell className={classes.td}>
                                                                                                <Tooltip 
                                                                                                    disabled={_.isEmpty(week?.audit)}
                                                                                                    title={(
                                                                                                        <Box p={0} width={500} position='absolute' marginTop={-1} marginLeft={-50}>
                                                                                                            <StackTable
                                                                                                                heading="Target History"
                                                                                                                rows={_.map(week?.audit, a => (
                                                                                                                    _.assign({
                                                                                                                        heading: `${momentFormatDateTime(a?.audit_created_at)}`,
                                                                                                                        value: (<Grid container alignItems='center'><Grid item><Typography variant="caption" className="fw-400 mr-1">{a.audit_comments}</Typography></Grid><Grid item xs><StaffChip staff={staff[a?.audit_staff_id ?? 0]} /></Grid></Grid>),
                                                                                                                    })
                                                                                                                ))}
                                                                                                            />
                                                                                                        </Box>
                                                                                                    )}
                                                                                                >
                                                                                                    <IconButton>
                                                                                                        <FAIcon icon="eye" noMargin button size={20} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                        </TableRow>  
                                                                                    ))}
                                                                                </TableBody>
                                                                                <TargetTotals classes={classes} targets={targets} />
                                                                            </Table>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <div className="buttonRow">
                                                                        <Button
                                                                            color="primary"
                                                                            disabled={_.every(targets, el => parseFloat(el.value) === parseFloat(el.tsw_target)) || _.some(targets, el => el?.value === "")}
                                                                            onClick={() => deployConfirmation(<>Are you sure you want to update weekly targets for {year} - {(parseInt(year) + 1)}?</>, () => this.handleSubmit('week'))}
                                                                            variant="contained"
                                                                        >
                                                                            <FAIcon icon="check" size={15} button />
                                                                            Update Weekly Targets
                                                                        </Button>
                                                                    </div>
                                                                </Box>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    {/* <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Sales Breakdown" />
                                    <Divider /> */}
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Monthly Sales" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Weekly Sales" />
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => ({
    staff: state?.notifications?.status ?? [],
    ui: state.ui
});

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(closeDialog()),
    deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(Targets));