import React, { useState } from 'react';

import MaterialAlert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

 const Alert = ({action, children, className, closeable, margin, severity, style, variant}) => {
    const [open, setOpen] = useState(true);
    return (
        <Collapse in={open}>
            <MaterialAlert 
                action={action}
                classes={{root: className}}
                elevation={0}
                onClose={closeable ? () => setOpen(false) : undefined}
                severity={severity}
                style={{
                    ...style,
                    paddingLeft: variant === "outlined" ? 0 : undefined,
                    marginBottom: margin ? margin : undefined
                }}
                variant={variant}
            >
                <span>
                    {children}
                </span>
            </MaterialAlert>
        </Collapse>
    )
}

export default Alert;