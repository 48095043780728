import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import PurchaseOrderDeliverySummary from "Components/Purchases/Misc/PurchaseOrderDeliverySummary";
import PurchaseOrderSummary from "Components/Purchases/Misc/PurchaseOrderSummary";
import StatusChip from 'Components/Common/Chips/StatusChip';

import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { getPresignedUrl } from 'Functions/S3Functions';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import StaffChip from 'Components/Common/Chips/StaffChip';

const getLegacyPurchaseOrderPDF = (id) => {
    API.get('/purchaseOrders/' + id + '/legacy/pdf')
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const deployPreview = (dispatch, type, poId, podId = null) => {
    switch(type) {
        case "po":
            API.get(`/purchaseOrders/${poId}`)
            .then(res => {
                if (res?.data) {
                    const po = res.data;
                    dispatch(
                        deployDialog(
                            <PurchaseOrderSummary 
                                inDialog
                                po={po} 
                            />
                        , 
                        true, 
                        ``, 
                        "standard", 
                        "xl",
                        )
                    );
                }
            });
        break;
        case "pod": 
            API.get(`/purchaseOrders/${poId}`)
            .then(res => {
                if (res?.data) {
                    const   po      = res.data,
                            pod     = _.find(po.deliveries, { pod_id: podId }),
                            access  = {};

                    if(po && pod) {
                        dispatch(     
                            deployDialog(
                                <Box p={3} style={{backgroundColor: '#fafafa'}}>
                                    <PurchaseOrderDeliverySummary 
                                        access={access}
                                        inDialog
                                        po={po} 
                                        pod={pod}
                                    />
                                </Box>
                            , 
                            true, 
                            ``, 
                            "standard", 
                            "xl",
                            )
                        );
                    }
                }
            });
        break;
        default: 
            /* No action */
    }
};

const getColumns = (dispatch, poStatus, staff) => {
    switch(poStatus) {
        case "supplier":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                    important: true
                },            
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true,
                    important: true
                },    
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true,
                    important: true
                },       
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    fieldFormat: 'date',
                    dataRef: 'created',
                    sizeToContent: true
                },     
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true
                },       
                {
                    heading: 'Gross Total',
                    field: rowData => rowData.gross,
                    dataRef: 'gross',
                    fieldFormat: 'currency',
                    alignment: 'right',
                    sizeToContent: true
                },         
                {
                    heading: 'Despatched',
                    field: rowData => rowData.status === 'Despatched' || rowData.status === 'Invoiced' ? true : false,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    sizeToContent: true
                }, 
                {
                    heading: 'Invoiced',
                    field: rowData => rowData.status === 'Invoiced' ? true : false,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    sizeToContent: true
                },                              
                {
                    heading: 'Status',
                    field: rowData => <StatusChip status={rowData.status} />,
                    dataRef: 'status',
                    sizeToContent: true,
                    important: true
                },  
                {
                    actions: rowData => {
                        return [
                            { name: 'View Purchase Order', icon: 'binoculars',  link: `/purchase-orders/${rowData.id}`, doubleClick: true},
                            { name: "Preview Purchase Order", icon: "eye", onClick: () => deployPreview(dispatch, 'po', rowData.id) },
                        ]
                    }
                }
            ];
        case "Awaiting Submission":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.schedule, "DD/MM/YYYY").isBefore(moment()) ? 'textError' : undefined
                    // })
                },                
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                },     
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.schedule, "DD/MM/YYYY").isBefore(moment()) ? 'textError' : undefined
                    // })
                },     
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.schedule, "DD/MM/YYYY").isBefore(moment()) ? 'textError' : undefined
                    // })
                },   
                // {
                //     heading: 'Submit On',
                //     field: rowData => rowData.schedule,
                //     dataRef: 'created',
                //     sizeToContent: true,
                //     // cellProps: rowData => ({
                //     //     className: moment(rowData.schedule, "DD/MM/YYYY").isBefore(moment()) ? 'textError' : undefined
                //     // })
                // },     
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    dataRef: 'created',
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.schedule, "DD/MM/YYYY").isBefore(moment()) ? 'textError' : undefined
                    // })
                },
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.schedule, "DD/MM/YYYY").isBefore(moment()) ? 'textError' : undefined
                    // })
                },
                {
                    heading: 'Direct Delivery',
                    field: rowData => !rowData.sh,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'sh',
                    sizeToContent: true,
                },         
                // {
                //     heading: 'Gross Total',
                //     field: rowData => rowData.gross,
                //     dataRef: 'gross',
                //     fieldFormat: 'currency',
                //     alignment: 'right',
                //     sizeToContent: true,
                //     // cellProps: rowData => ({
                //     //     className: moment(rowData.schedule, "DD/MM/YYYY").isBefore(moment()) ? 'textError' : undefined
                //     // })
                // },      
                                     
                {
                    actions: rowData => {
                        return [
                            { name: 'View Purchase Order', icon: 'binoculars',  link: `/purchase-orders/${rowData.id}`},
                            { name: "Preview Purchase Order", icon: "eye", onClick: () => deployPreview(dispatch, 'po', rowData.id) },
                            { name: 'Submit Purchase Order', icon: 'check', link: '/purchase-orders/process/' + rowData.id, disabled: rowData?.shd === 1, doubleClick: true},
                        ]
                    }
                }
            ];
        case "Awaiting Confirmation":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                },                
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                },     
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true,
                },    
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Requested Despatch',
                    field: rowData =>   (rowData.dlvrs)?.length === 1 ? 
                                            rowData.dlvrs[0].dt 
                                        : 
                                        ((rowData.dlvrs)?.length > 0 ? 
                                                <InsightChip
                                                    icon="info-circle"
                                                    label={(rowData.dlvrs).length + ' Deliveries'}
                                                    className="fw-300"
                                                />
                                            : 
                                                '-'
                                        ),
                    dataRef: 'dlvrsL',
                    sizeToContent: true,
                    tooltip: rowData => rowData?.dlvrs ? _.map(rowData.dlvrs, (d, idx) => (
                                                            <Typography variant="body2" key={idx} gutterBottom>
                                                                Delivery #{d.ref} - {d.dt}
                                                            </Typography>
                                                        )) 
                    : 
                    null,
                },
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    dataRef: 'created',
                    sizeToContent: true,
                },
                {
                    heading: 'Submitted',
                    field: rowData => rowData.sub,
                    dataRef: 'sub',
                    sizeToContent: true
                },
                {
                    heading: 'Method',
                    field: rowData => rowData.smt,
                    dataRef: 'smt',
                    sizeToContent: true
                },
                {
                    heading: 'Staff',
                    field: rowData => <StaffChip staff={staff[rowData.ssm]} />,
                    fieldFormat: 'staff',
                    dataRef: 'ssm',
                    sizeToContent: true
                },
                {
                    heading: 'Direct Delivery',
                    field: rowData => !rowData.sh,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'sh',
                    sizeToContent: true,
                },  
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true,
                },                       
                {
                    actions: rowData => {
                        return [
                            { name: 'View Purchase Order', icon: 'binoculars',  link: `/purchase-orders/${rowData.id}`},
                            { name: "Preview Purchase Order", icon: "eye", onClick: () => deployPreview(dispatch, 'po', rowData.id) },
                            { name: 'Confirm Purchase Order', icon: 'check', link: '/purchase-orders/process/' + rowData.id, disabled: rowData?.shd === 1, doubleClick: true},
                        ]
                    }
                }
            ];
        case "Awaiting Despatch":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                    // })
                },                
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                },     
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                    // })
                },     
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                    // })
                },
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    fieldFormat: 'date',
                    dataRef: 'created',
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                    // })
                },
                {
                    heading: 'Expected Despatch',
                    field: rowData => rowData.expected,
                    dataRef: 'expected',
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                    // })
                }, 
                {
                    heading: 'Check Date',
                    field: rowData => rowData.check,
                    fieldFormat: 'date',
                    dataRef: 'check',
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                    // })
                },   
                  
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true,
                    // cellProps: rowData => ({
                    //     className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                    // })
                },
                // {
                //     heading: 'Submitted',
                //     field: rowData => rowData.schedule,
                //     dataRef: 'schedule',
                //     sizeToContent: true,
                //     // cellProps: rowData => ({
                //     //     className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                //     // })
                // },     
                // {
                //     heading: 'Gross Total',
                //     field: rowData => rowData.gross,
                //     dataRef: 'gross',
                //     fieldFormat: 'currency',
                //     alignment: 'right',
                //     sizeToContent: true,
                //     cellProps: rowData => ({
                //         className: moment(rowData.expected, "DD/MM/YYYY").isBefore(moment()) || (rowData.check && moment(rowData.check, "DD/MM/YYYY").isBefore(moment())) ? 'textError' : undefined
                //     })
                // },             
                {
                    heading: 'Direct Delivery',
                    field: rowData => !rowData.sh,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'sh',
                    sizeToContent: true,
                },                   
                {
                    actions: rowData => {
                        return [
                            { name: 'View Delivery', icon: 'binoculars',  link: `/purchase-orders/${rowData.po}:${rowData.id}`},
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'pod', rowData.po, rowData.id) },
                            { name: 'Manage Delivery', icon: 'check', link: `/purchase-orders/process/${rowData.po}:${rowData.id}`, disabled: rowData?.shd === 1, doubleClick: true},
                        ]
                    }
                }
            ];
        case "On Hold":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                },            
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                },     
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true,
                },  
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    fieldFormat: 'date',
                    dataRef: 'created',
                    sizeToContent: true
                },  
                {
                    heading: 'Despatched On',
                    field: rowData => rowData.despatched,
                    dataRef: 'despatched',
                    sizeToContent: true,
                },   
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true,
                },       
                // {
                //     heading: 'Gross Total',
                //     field: rowData => rowData.gross,
                //     dataRef: 'gross',
                //     fieldFormat: 'currency',
                //     alignment: 'right',
                //     sizeToContent: true,
                // },      
                {
                    heading: 'Delivered',
                    field: rowData => rowData.del === 1 || rowData.iss === 1,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'del',
                    sizeToContent: true,
                },          
                {
                    heading: 'Issue',
                    field: rowData => rowData.iss,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'iss',
                    sizeToContent: true,
                },           
                {
                    actions: rowData => {
                        return [
                            { name: 'View Delivery', icon: 'binoculars',  link: `/purchase-orders/${rowData.po}:${rowData.id}`, doubleClick: true},
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'pod', rowData.po, rowData.id) },
                        ]
                    }
                }
            ];
        case "Despatched":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                },            
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                },     
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true,
                },  
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    fieldFormat: 'date',
                    dataRef: 'created',
                    sizeToContent: true
                },  
                {
                    heading: 'Despatched On',
                    field: rowData => rowData.despatched,
                    dataRef: 'despatched',
                    sizeToContent: true,
                },  
                {
                    heading: 'Expected On',
                    field: rowData => rowData.dueOn,
                    dataRef: 'dueOn',
                    sizeToContent: true,
                },   
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true,
                },       
                // {
                //     heading: 'Gross Total',
                //     field: rowData => rowData.gross,
                //     dataRef: 'gross',
                //     fieldFormat: 'currency',
                //     alignment: 'right',
                //     sizeToContent: true,
                // },      
                {
                    heading: 'Delivered',
                    field: rowData => rowData.del === 1 || rowData.iss === 1,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'del',
                    sizeToContent: true,
                },          
                {
                    heading: 'Issue',
                    field: rowData => rowData.iss,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'iss',
                    sizeToContent: true,
                },    
                {
                    heading: 'Direct Delivery',
                    field: rowData => !rowData.sh,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'did',
                    sizeToContent: true,
                },                  
                {
                    heading: 'Status',
                    field: rowData => <StatusChip status={rowData.desStatus} />,
                    sizeToContent: true,
                    dataRef: 'desStatus'
                },                  
                {
                    actions: rowData => {
                        return [
                            { name: 'View Delivery', icon: 'binoculars',  link: `/purchase-orders/${rowData.po}:${rowData.id}`},
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'pod', rowData.po, rowData.id) },
                            { name: 'Upload Invoice', icon: 'check', link: `/purchase-orders/process/${rowData.po}:${rowData.id}`, disabled: rowData?.shd === 1 || rowData?.del === 0, doubleClick: true},
                        ]
                    }
                }
            ];
        case "Invoiced":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                },           
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                }, 
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true,
                },   
                {
                    heading: 'Invoice Number',
                    field: rowData => rowData.inv ?? '-',
                    dataRef: 'inv',
                    sizeToContent: true
                },
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                },   
                {
                    heading: 'Despatched On',
                    field: rowData => rowData.despatched,
                    dataRef: 'despatched',
                    sizeToContent: true,
                },      
                {
                    heading: 'Expected On',
                    field: rowData => rowData.dueOn,
                    dataRef: 'dueOn',
                    sizeToContent: true,
                },   
                {
                    heading: 'Invoiced On',
                    field: rowData => rowData.invoiced ?? '-',
                    dataRef: 'invoiced',
                    sizeToContent: true,
                    fieldFormat: 'datetime',
                },     
                {
                    heading: 'Gross Total',
                    field: rowData => rowData.gross,
                    dataRef: 'gross',
                    fieldFormat: 'currency',
                    alignment: 'right',
                    sizeToContent: true,
                },                             
                {
                    actions: rowData => {
                        return [
                            { name: 'View Purchase Order', icon: 'binoculars', link: `/purchase-orders/${rowData.po}:${rowData.id}`, doubleClick: true},
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'pod', rowData.po, rowData.id) },
                        ]
                    }
                }
            ];
        case "Cancelled":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                },         
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                },  
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true,
                },  
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                },
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    fieldFormat: 'date',
                    dataRef: 'created',
                    sizeToContent: true
                },
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true,
                },
                {
                    heading: 'Direct Delivery',
                    field: rowData => !rowData.sh,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'sh',
                    sizeToContent: true,
                },
                {
                    actions: rowData => {
                        return [
                            { name: 'View Purchase Order', icon: 'binoculars', link: `/purchase-orders/${rowData.po}`, doubleClick: true},
                            { name: "Preview Purchase Order", icon: "eye", onClick: () => deployPreview(dispatch, 'po', rowData.po) },
                        ]
                    }
                }
            ];
        case "Stock":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true
                },
                {
                    heading: 'SO Numbers Allocated',
                    field: rowData =>   (rowData.soa)?.length > 0 ? 
                                                <InsightChip
                                                    icon="info-circle"
                                                    label={(rowData.soa).length}
                                                    className="fw-300"
                                                />
                                            : 
                                                '-'
                                        ,
                    dataRef: 'soaL',
                    sizeToContent: true,
                    tooltip: rowData => (rowData.soa)?.length > 0 ? 
                                        _.map(rowData.soa, (so, idx) => (
                                            <Typography variant="body2" key={idx} gutterBottom>
                                                SO #{so.ref}
                                            </Typography>
                                        )) 
                                        : 
                                        null,
                }, 
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    sizeToContent: true
                },          
                  
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    fieldFormat: 'date',
                    dataRef: 'created',
                    sizeToContent: true
                },
                {
                    heading: 'Due Despatch',
                    field: rowData =>   (rowData.dlvrs)?.length === 1 ? 
                                            rowData.dlvrs[0].dt 
                                        : 
                                        ((rowData.dlvrs)?.length > 0 ? 
                                                <InsightChip
                                                    icon="info-circle"
                                                    label={(rowData.dlvrs).length + ' Deliveries'}
                                                    className="fw-300"
                                                />
                                            : 
                                                '-'
                                        ),
                    dataRef: 'dlvrsL',
                    sizeToContent: true,
                    tooltip: rowData => rowData?.dlvrs ? 
                                        _.map(rowData.dlvrs, (d, idx) => (
                                            <Typography variant="body2" key={idx} gutterBottom>
                                                Delivery #{d.ref} - {d.dt}
                                            </Typography>
                                        )) 
                                        : 
                                        null,
                },
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true
                },     
                // {
                //     heading: 'Gross Total',
                //     field: rowData => rowData.gross,
                //     dataRef: 'gross',
                //     fieldFormat: 'currency',
                //     alignment: 'right',
                //     sizeToContent: true
                // },
                // {
                //     heading: 'Submitted',
                //     field: rowData => rowData.schedule,
                //     dataRef: 'schedule',
                //     sizeToContent: true
                // }, 
                {
                    heading: 'Despatched',
                    field: rowData => rowData.status === 'Despatched' || rowData.status === 'Invoiced' ? true : false,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    sizeToContent: true,
                    dataRef: 'des',
                }, 
                {
                    heading: 'Delivered',
                    field: rowData => rowData.del,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'del',
                    sizeToContent: true,
                },   
                {
                    heading: 'Invoiced',
                    field: rowData => rowData.status === 'Invoiced' ? true : false,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    sizeToContent: true,
                    dataRef: 'inv',
                },       
                {
                    heading: 'Status',
                    field: rowData => <StatusChip status={rowData.status} />,
                    dataRef: 'status',
                    sizeToContent: true
                }, 
                {
                    actions: rowData => {
                        return [
                            { name: 'View Purchase Order', icon: 'binoculars',  link: `/purchase-orders/${rowData.id}`, doubleClick: true},
                            { name: "Preview Purchase Order", icon: "eye", onClick: () => deployPreview(dispatch, 'po', rowData.id) },
                            { name: 'Download Purchase Order PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyPurchaseOrderPDF(rowData.id) : getPresignedUrl(rowData.pdf)}
                        ]
                    }
                }
            ];
        case "Sales Order":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true
                },   
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                }, 
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    sizeToContent: true
                },        
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    fieldFormat: 'date',
                    dataRef: 'created',
                    sizeToContent: true
                },
                {
                    heading: 'Due Despatch',
                    field: rowData =>   (rowData.dlvrs)?.length === 1 ? 
                                            rowData.dlvrs[0].dt 
                                        : 
                                        ((rowData.dlvrs)?.length > 0 ? 
                                                <InsightChip
                                                    icon="info-circle"
                                                    label={(rowData.dlvrs).length + ' Deliveries'}
                                                    className="fw-300"
                                                />
                                            : 
                                                '-'
                                        ),
                    dataRef: 'dlvrsL',
                    sizeToContent: true,
                    tooltip: rowData => rowData?.dlvrs ? 
                                        _.map(rowData.dlvrs, (d, idx) => (
                                            <Typography variant="body2" key={idx} gutterBottom>
                                                Delivery #{d.ref} - {d.dt}
                                            </Typography>
                                        )) 
                                        : 
                                        null,
                },
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true
                },
                // {
                //     heading: 'Submitted',
                //     field: rowData => rowData.schedule,
                //     dataRef: 'schedule',
                //     sizeToContent: true
                // }, 
                // {
                //     heading: 'Gross Total',
                //     field: rowData => rowData.gross,
                //     dataRef: 'gross',
                //     fieldFormat: 'currency',
                //     alignment: 'right',
                //     sizeToContent: true
                // },  
                {
                    heading: 'Despatched',
                    field: rowData => rowData.status === 'Despatched' || rowData.status === 'Invoiced' ? true : false,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    sizeToContent: true,
                    dataRef: 'des',
                }, 
                {
                    heading: 'Delivered',
                    field: rowData => rowData.del,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'del',
                    sizeToContent: true,
                },   
                {
                    heading: 'Invoiced',
                    field: rowData => rowData.status === 'Invoiced' ? true : false,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    sizeToContent: true,
                    dataRef: 'inv',
                },       
                {
                    heading: 'Direct Delivery',
                    field: rowData => !rowData.sh,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'did',
                    sizeToContent: true,
                },                                     
                {
                    heading: 'Status',
                    field: rowData => <StatusChip status={rowData.status} />,
                    dataRef: 'status',
                    sizeToContent: true
                },  
                {
                    actions: rowData => {
                        return [
                            { name: 'View Purchase Order', icon: 'binoculars',  link: `/purchase-orders/${rowData.id}`, doubleClick: true},
                            { name: "Preview Purchase Order", icon: "eye", onClick: () => deployPreview(dispatch, 'po', rowData.id) },
                            { name: 'Download Purchase Order PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyPurchaseOrderPDF(rowData.id) : getPresignedUrl(rowData.pdf)}
                        ]
                    }
                }
            ];
        default:
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true
                },   
                {
                    heading: 'SO Number',
                    field: rowData => rowData.sop ? <Link className="blueLink" to={`/sales/${rowData.so}`}>{rowData.sop}</Link> : '-',
                    dataRef: 'sop',
                    tooltip: rowData => rowData.sop ? <><Typography component="div" variant="caption" className="fw-400">View Sales Order</Typography><Typography component="div" variant="caption">#{rowData.sop}</Typography></> : null,
                    sizeToContent: true
                },  
                {
                    heading: 'Type',
                    field: rowData => rowData.type,
                    dataRef: 'type',
                    sizeToContent: true
                },   
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    sizeToContent: true
                },        
                     
                {
                    heading: 'Raised On',
                    field: rowData => rowData.created,
                    fieldFormat: 'date',
                    dataRef: 'created',
                    sizeToContent: true
                }, 
                {
                    heading: 'Due Despatch',
                    field: rowData =>   (rowData.dlvrs)?.length === 1 ? 
                                            rowData.dlvrs[0].dt 
                                        : 
                                        ((rowData.dlvrs)?.length > 0 ? 
                                                <InsightChip
                                                    icon="info-circle"
                                                    label={(rowData.dlvrs).length + ' Deliveries'}
                                                    className="fw-300"
                                                />
                                            : 
                                                '-'
                                        ),
                    dataRef: 'dlvrsL',
                    sizeToContent: true,
                    tooltip: rowData => rowData?.dlvrs ? 
                                        _.map(rowData.dlvrs, (d, idx) => (
                                            <Typography variant="body2" key={idx} gutterBottom>
                                                Delivery #{d.ref} - {d.dt}
                                            </Typography>
                                        )) 
                                        : 
                                        null,
                },
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true
                }, 
                // {
                //     heading: 'Gross Total',
                //     field: rowData => rowData.gross,
                //     dataRef: 'gross',
                //     fieldFormat: 'currency',
                //     alignment: 'right',
                //     sizeToContent: true
                // },  
                {
                    heading: 'Despatched',
                    field: rowData => rowData.status === 'Despatched' || rowData.status === 'Invoiced' ? true : false,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    sizeToContent: true,
                    dataRef: 'des',
                }, 
                {
                    heading: 'Delivered',
                    field: rowData => rowData.del,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'del',
                    sizeToContent: true,
                },   
                {
                    heading: 'Invoiced',
                    field: rowData => rowData.status === 'Invoiced' ? true : false,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    sizeToContent: true,
                    dataRef: 'inv',
                },       
                {
                    heading: 'Direct Delivery',
                    field: rowData => !rowData.sh,
                    fieldFormat: 'boolean',
                    alignment: 'center',
                    dataRef: 'did',
                    sizeToContent: true,
                },                                  
                {
                    heading: 'Status',
                    field: rowData => <StatusChip status={rowData.status} />,
                    dataRef: 'status',
                    sizeToContent: true
                },   
                {
                    actions: rowData => {
                        return [
                            { name: 'View Purchase Order', icon: 'binoculars',  link: `/purchase-orders/${rowData.id}`, doubleClick: true},
                            { name: "Preview Purchase Order", icon: "eye", onClick: () => deployPreview(dispatch, 'po', rowData.id) },
                            { name: 'Download Purchase Order PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyPurchaseOrderPDF(rowData.id) : getPresignedUrl(rowData.pdf)}
                        ]
                    }
                }
            ];
    }
}

const getWarehouseColumns = (dispatch, status, staff) => {
    switch(status) {
        case "Awaiting Delivery":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                },       
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                },   
                {
                    heading: 'Type',
                    field: rowData => rowData.type === 'Sales Order' ? 'Sales Order (Ship Here)' : (rowData.type === 'Stock' ? 'Warehouse Stock' : rowData.type),
                    dataRef: 'type',
                    sizeToContent: true,
                },       
                {
                    heading: 'Expected Despatch',
                    field: rowData => rowData.expected,
                    dataRef: 'expected',
                    sizeToContent: true,
                },   
                {
                    heading: 'Despatched On',
                    field: rowData => rowData.despatched,
                    dataRef: 'despatched',
                    sizeToContent: true,
                },      
                {
                    heading: 'Expected On',
                    field: rowData => rowData.dueOn,
                    dataRef: 'dueOn',
                    sizeToContent: true,
                },  
                {
                    heading: 'On Time',
                    field: rowData => rowData.onTime,
                    dataRef: 'onTime',
                    fieldFormat: 'boolean',
                    sizeToContent: true,
                    alignment: 'center'
                },
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true,
                },       
                {
                    heading: 'Gross Total',
                    field: rowData => rowData.gross,
                    dataRef: 'gross',
                    fieldFormat: 'currency',
                    alignment: 'right',
                    sizeToContent: true,
                },                             
                {
                    actions: rowData => {
                        return [
                            { name: 'View Delivery', icon: 'binoculars', link: `/purchase-orders/${rowData.po}:${rowData.id}`, doubleClick: true},
                        ]
                    }
                }
            ];
            
        case "Awaiting Delivery Basic":
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                },       
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                },   
                {
                    heading: 'Type',
                    field: rowData => rowData.type === 'Sales Order' ? 'Sales Order (Ship Here)' : (rowData.type === 'Stock' ? 'Warehouse Stock' : rowData.type),
                    dataRef: 'type',
                    sizeToContent: true,
                },       
                // {
                //     heading: 'Expected Despatch',
                //     field: rowData => rowData.expected,
                //     dataRef: 'expected',
                //     sizeToContent: true,
                // },   
                {
                    heading: 'Expected On',
                    field: rowData => rowData.dueOn,
                    dataRef: 'dueOn',
                    sizeToContent: true,
                },                       
                {
                    actions: rowData => {
                        return [
                            { name: 'View Delivery', icon: 'binoculars', link: `/purchase-orders/${rowData.po}:${rowData.id}`, doubleClick: true},
                        ]
                    }
                }
            ];
        default:
            return [  
                {
                    heading: 'PO Number',
                    field: rowData => (
                        <>
                            <Typography className="fw-400" variant="body2">
                                {rowData.ref}
                            </Typography>
                            <Typography variant="caption">
                                {rowData.srf}
                            </Typography>
                        </>
                    ),
                    dataRef: 'ref',
                    main: true,
                    sizeToContent: true,
                },  
                {
                    heading: 'Supplier',
                    field: rowData => rowData.supplier,
                    dataRef: 'supplier',
                    main: true,
                    sizeToContent: true,
                },   
                {
                    heading: 'Type',
                    field: rowData => rowData.type === 'Sales Order' ? 'Sales Order (Ship Here)' : (rowData.type === 'Stock' ? 'Warehouse Stock' : rowData.type),
                    dataRef: 'type',
                    sizeToContent: true,
                },       
                {
                    heading: 'Despatched On',
                    field: rowData => rowData.despatched,
                    dataRef: 'despatched',
                    sizeToContent: true,
                },      
                {
                    heading: 'Expected On',
                    field: rowData => rowData.dueOn,
                    dataRef: 'dueOn',
                    sizeToContent: true,
                },   
                {
                    heading: 'Net Total',
                    field: rowData => rowData.net,
                    fieldFormat: 'currency',
                    dataRef: 'net',
                    alignment: 'right',
                    sizeToContent: true,
                },       
                {
                    heading: 'Gross Total',
                    field: rowData => rowData.gross,
                    dataRef: 'gross',
                    fieldFormat: 'currency',
                    alignment: 'right',
                    sizeToContent: true,
                },                 
                {
                    actions: rowData => {
                        return [
                            { name: 'View Delivery', icon: 'binoculars', link: `/purchase-orders/${rowData.po}:${rowData.id}`, doubleClick: true},
                        ]
                    }
                }
            ];
    }
}

const SearchPuchaseOrdersTable = ({dataLoading, due, inline, newPo, persistenceId, poStatus, resetSearch, rows, type, warehouse}) => {
    const dispatch = useDispatch();
    const staff = useSelector(state => state.notifications.status)
    return (
        <DataTable  
            config={{
                key: 'id',
                pagination: true,
                persistenceId: persistenceId ?? null,
                alternatingRowColours: true,
                isLoading: dataLoading,
                inline: inline,
                plainPagination: inline, 
                rowsPerPage: inline ? 5 : 10,
                // responsiveImportance: true,
                options: {
                    headingInput: (
                        newPo && (
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={newPo}
                            >#
                                <FAIcon type="light" icon="plus-circle" size={15} button />
                                Create New Purchase Order
                            </Button>
                        )
                    ),
                    dataRef: true,
                    reset: resetSearch || false,
                    export: {
                        title: (warehouse ? 'Warehouse - ' + (due ? 'Awaiting Delivery' : 'Delivered') : 'Purchase Orders - ' + ((type ? type : poStatus) || 'All')),
                        name: `aa-purchase-orders`,
                        excel: true,
                        pdf: true,
                        print: true
                    }
                }
            }}
            columns={warehouse ? getWarehouseColumns(due ? (inline ? 'Awaiting Delivery Basic' : 'Awaiting Delivery') : 'Delivered', staff) : getColumns(dispatch, type ? type : poStatus, staff)}
            rows={rows}
        />
    )
}

export default SearchPuchaseOrdersTable;