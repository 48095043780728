import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { 
    Avatar, 
    Box, 
    Card, 
    Grid, 
    IconButton, 
    Paper, 
    TextField, 
    Tooltip, 
    Typography 
} from '@material-ui/core';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import RMASummary from 'Components/RMA/Misc/RMASummary';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import logo from 'Assets/Images/no-img.jpg';
import PrintJobDialog from 'Components/Common/Dialogs/PrintJobDialog';
import Textarea from 'Components/Common/Inputs/Textarea';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { CLOUDFRONT_URL } from 'Constants';
import { isNumeric } from 'Functions/MiscFunctions';

const CheckDialog = ({check, closeDialog, deployConfirmation, handleCheckIssue}) => {

    const [qtyTotal, setQtyTotal] = useState('');
    const [qtyNonPristine, setQtyNonPristine] = useState('');
    const [qtyMissing, setQtyMissing] = useState('');
    const [qtyPristine, setQtyPristine] = useState('');

    const btnDisabled = qtyTotal === "" || qtyNonPristine === "";

    const handleQtyTotal = e => {

        if(_.isEmpty(e?.target?.value) || e?.target?.value < 0 || isNaN(e?.target?.value)) {
            setQtyTotal('');
            setQtyNonPristine(0);
            setQtyMissing(0);
            setQtyPristine(0);
            return;
        }
        
        let total       =   parseInt(e?.target?.value ?? 0),
            expected    =   parseInt(check.qtyExpected);

        setQtyTotal(total);
        setQtyMissing((expected - total));
        setQtyPristine(check?.reason === 'FAULTY_GOODS' || check?.reason === 'DAMAGED' ? 0 : (total - qtyNonPristine));
        setQtyNonPristine(check?.reason === 'FAULTY_GOODS' || check?.reason === 'DAMAGED' ? (total - qtyNonPristine) : 0);
    
    }

    const handleQtyNonPristine = e => {

        if(_.isEmpty(e?.target?.value)) {
            setQtyNonPristine('');
            return;
        }

        let nonPristine = parseInt(e?.target?.value ?? 0);

        setQtyNonPristine(nonPristine < 0 || nonPristine > qtyTotal || !isNumeric(nonPristine) ? '' : nonPristine)
        setQtyPristine(nonPristine < 0 || nonPristine > qtyTotal || !isNumeric(nonPristine) ? qtyTotal : (qtyTotal - nonPristine))

    }
    
    const handleSubmit = () => {
        deployConfirmation(`You have ${qtyTotal} on-hand (${qtyPristine} pristine, ${qtyNonPristine} non-pristine, ${qtyMissing} missing)?`, () => {
            handleCheckIssue(check.idx, qtyMissing, qtyNonPristine === "" ? 0 : qtyNonPristine, qtyTotal);
            closeDialog();
        })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="body1" className="fw-500" style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                    {check?.line?.productName}
                </Typography>
            </Grid>            
            <Grid item xs={12} className="pt-1">
                <Box textAlign='center' className="bg-dark textLight" p={1}>
                    <Typography variant="body1" className="fw-500 pl-1">
                        Expected Condition -{` `}
                        {_.find([
                        {
                            label: "Not Pristine (Damaged)",
                            value: "DAMAGED"
                        },
                        {
                            label: "Not Pristine (Faulty)",
                            value: "FAULTY_GOODS"
                        },
                        {
                            label: "Pristine (No Longer Required)",
                            value: "NO_LONGER_REQUIRED"
                        },
                        {
                            label: "Pristine (Wrong Goods Ordered)",
                            value: "WRONG_GOODS_ORDERED"
                        },
                        {
                            label: "Pristine (Wrong Goods Supplied)",
                            value: "WRONG_GOODS_SUPPLIED_AA"
                        },
                        {
                            label: "Pristine (Wrong Goods Supplied)",
                            value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                        }
                    ], el => el.value === check.reason)?.label}
                    </Typography>
                </Box>
            </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Received *"
                        value={qtyTotal}
                        onChange={handleQtyTotal}
                        margin="none"
                        fullWidth
                        inputProps={{
                            size: 4,
                            pattern: '[0-9]*',
                            inputMode: 'none'
                        }}
                        size="small"
                        autoFocus
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Not Pristine *"
                        value={qtyNonPristine}
                        onChange={handleQtyNonPristine}
                        margin="none"
                        fullWidth
                        inputProps={{
                            size: 4,
                            pattern: '[0-9]*',
                            inputMode: 'none'
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label="Pristine"
                        value={qtyPristine}
                        margin="none"
                        fullWidth
                        inputProps={{
                            size: 4,
                            pattern: '[0-9]*',
                            inputMode: 'none'
                        }}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label="Missing"
                        value={qtyMissing}
                        margin="none"
                        fullWidth
                        inputProps={{
                            size: 4,
                            pattern: '[0-9]*',
                            inputMode: 'none'
                        }}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <AppButton
                        disabled={btnDisabled}
                        onClick={handleSubmit}
                        primary
                        color={btnDisabled ? `#bbb` : `#fff`}
                        className={btnDisabled ? `btn btn-disabled` : `btn btn-success`}
                        icon="check"
                        text='Confirm'
                    />
                </Grid>
        </Grid>
    )
}

const initialState = {
    checkStarted: null,
    checkStatus: {},
    checkCompleted: null,
    isLoading: false,
    printing: false,
    notes: ''
}

class RMAReceived extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = (restartCheck = false) => {
        const { 
            currentTab,
            rma,
            scrollToTop
        } = this.props;

        let { 
            checkStatus 
        } = this.state;

        _.each(_.filter(rma.products, el => parseInt(el.recQuantity) < parseInt(el.quantity)), (rp, idx) => {
            checkStatus = {
                ...checkStatus,
                [idx]: {
                    idx,
                    line: rp,
                    checkTime: null,
                    qtyExpected: (parseInt(rp.quantity) - parseInt(rp.recQuantity)),
                    qtyMissing: 0,
                    qtyNonPristine: rp.reason === "DAMAGED" || rp.reason === "FAULTY_GOODS" ? (parseInt(rp.quantity) - parseInt(rp.recQuantity)) : 0,
                    qtyTotal: (parseInt(rp.quantity) - parseInt(rp.recQuantity)),
                    complete: 1,
                    reason: rp.reason,
                    status: null
                }
            }
        })

        this.setState({
            checkStatus,
            checkStarted: restartCheck || currentTab === 2 ? moment().format('YYYY-MM-DD HH:mm:ss') : null
        }, scrollToTop);
    }

    componentDidUpdate = (prevProps) => {
        const { 
            checkStarted
        } = this.state;

        if(checkStarted === null) {
            if(prevProps.currentTab !== 2 && this.props.currentTab === 2) {
                this.setState({
                    checkStarted: moment().format('YYYY-MM-DD HH:mm:ss')
                });
            }
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout)
    }

    handleCheckIssue = (idx, qtyMissing, qtyNonPristine, qtyTotal) => {

        let {
            checkStatus
        } = this.state;
        
        let qtyPristine = (qtyTotal - qtyNonPristine);
        
        checkStatus = {
            ...checkStatus,
            [idx]: {
                ...checkStatus?.[idx],
                checkTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                qtyMissing,
                qtyNonPristine,
                qtyTotal,
                complete: checkStatus[idx].reason === "DAMAGED" || checkStatus[idx].reason === "FAULTY_GOODS" ? (qtyNonPristine === checkStatus[idx]?.qtyExpected ? 1 : 0) : (qtyPristine === checkStatus[idx]?.qtyExpected ? 1 : 0),
                status: checkStatus[idx].reason === "DAMAGED" || checkStatus[idx].reason === "FAULTY_GOODS" ? (qtyNonPristine === checkStatus[idx]?.qtyExpected ? 'success' : 'issue') : (qtyPristine === checkStatus[idx]?.qtyExpected ? 'success' : 'issue'),
            }
        }

        this.setState({
            checkStatus
        })
    }
    
    handleCheckOkay = idx => {

        const { 
            playSound 
        } = this.props;

        let { 
            checkStatus 
        } = this.state;

        checkStatus = {
            ...checkStatus,
            [idx]: {
                ...checkStatus?.[idx],
                checkTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                complete: 1,
                status: 'success'
            }
        }

        this.setState({
            checkStatus
        }, () => {
            playSound('pickSuccess', this.state.line)
        })

    }
    
    handleDeployCheckIssue = idx => {
        const {
            checkStatus
        } = this.state;
        
        const {
            closeDialog,
            deployConfirmation,
            deployDialog,
            playSound
        } = this.props;

        playSound('pickError', this.state.line);

        deployDialog(
            <CheckDialog 
                closeDialog={closeDialog} 
                deployConfirmation={deployConfirmation} 
                handleCheckOkay={this.handleCheckOkay}
                handleCheckIssue={this.handleCheckIssue} 
                check={checkStatus?.[idx]}
            />, 
            false, 
            `${checkStatus?.[idx]?.line?.productCode}`, 
            "standard", 
            "xs",
            false,
            true
        );
    }

    handleNotes = notes => {
        this.setState({
            notes
        })
    }

    handleSubmit = () => {

        this.setState({
            checkCompleted: moment().format("YYYY-MM-DD HH:mm:ss")
        }, () => {

            this.setState({
                isLoading: true
            }, () => {

                const { deployDialog, id } = this.props;
                const { notes, checkStarted, checkStatus, checkCompleted } = this.state;

                API.put(`/warehouse/rma/${id}/received`,
                    {
                        notes,
                        checkStarted,
                        checkStatus,
                        checkCompleted
                    }
                )
                .then(res => {
                    if(res?.data) {
                        if(res?.data.printJob) {
                            this.setState({
                                printing: true
                            }, () => {
                                deployDialog(
                                    <PrintJobDialog
                                        printJob={res.data.printJob}
                                        docTitle={res.data.printDoc}
                                        docSubtitle={res.data.printSub}
                                        onClose={() => this.handleCompletedAction()}
                                    />,
                                    true,
                                    "",
                                    "standard",
                                    "sm",
                                    false,
                                    true
                                );
                            })
                        } else {
                            this.handleCompletedAction();
                        }
                    }
                })

            })

        })

    }

    handleCompletedAction = () => {

        const {
            checkStatus
        } = this.state;

        if(_.some(checkStatus, el => el.complete === 0)) {
            this.handleCheckFailCompleted()
        } else {
            this.handleCheckComplete()
        }

    }

    handleCheckComplete = () => {

        const { 
            deploySnackBar, 
            history, 
            processingComplete,
            warehouseMode
        } = this.props;

        processingComplete();
        deploySnackBar(`success`, `You have successfully booked in this RMA`)
        let redirect =  warehouseMode ? `/dashboard` : `/warehouse/incoming/awaiting-delivery`;
        this.timeout = setTimeout(() => history.push(redirect), 750)

    }

    handleCheckFailCompleted = () => {
        
        const { 
            deploySnackBar, 
            history, 
            warehouseMode
        } = this.props;

        deploySnackBar(`warning`, `This RMA has been referred to Sales Admin`)
        if(warehouseMode) {
            history.push(`/dashboard`)
        } else {
            history.push(`/warehouse/outgoing/awaiting-delivery`)
        }

    }

    render = () => {
        const { checkStatus, isLoading, notes } = this.state;
        const { currentTab, deployConfirmation, rma } = this.props;
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                (currentTab === 0 && (
                    <Paper>
                        <Box p={3}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        Notes
                                    </Typography>    
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt={1}>
                                        <ViewNotes inline notes={rma.notes} hideDeleteButton />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                )) || (currentTab === 1 && (
                    <RMASummary rma={rma} />
                )) || (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justify="space-between">
                                <Grid item xs={12} align="center">
                                    <Typography variant="h5" component="div" className="fw-500">
                                        RMA Check
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {_.map(checkStatus, (p, idx) => (
                            <Grid item xs={12} key={idx}>
                                <Card>
                                    <Box textAlign='center' className="bg-dark textLight" p={1}>
                                        <Typography variant="body1" className="fw-500 pl-1">
                                            Expected Condition -{` `}
                                            {_.find([
                                            {
                                                label: "Not Pristine (Damaged)",
                                                value: "DAMAGED"
                                            },
                                            {
                                                label: "Not Pristine (Faulty)",
                                                value: "FAULTY_GOODS"
                                            },
                                            {
                                                label: "Pristine (No Longer Required)",
                                                value: "NO_LONGER_REQUIRED"
                                            },
                                            {
                                                label: "Pristine (Wrong Goods Ordered)",
                                                value: "WRONG_GOODS_ORDERED"
                                            },
                                            {
                                                label: "Pristine (Wrong Goods Supplied)",
                                                value: "WRONG_GOODS_SUPPLIED_AA"
                                            },
                                            {
                                                label: "Pristine (Wrong Goods Supplied)",
                                                value: "WRONG_GOODS_SUPPLIED_SUPPLIER"
                                            }
                                        ], el => el.value === p.reason)?.label}
                                        </Typography>
                                    </Box>
                                    <Box p={1}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <Avatar variant="square" alt={p?.line?.productName} src={p?.line?.image ? `${CLOUDFRONT_URL}${p?.line?.image}` : logo} style={{width: 50, height: 50}} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="h6" className="fw-400">
                                                    {p?.line?.productCode}
                                                </Typography>
                                                <Typography variant="caption" className="fw-300">
                                                    {p?.line?.productName}
                                                </Typography>
                                            </Grid>
                                            {p.status !== null && (
                                                <Grid item align='right'>
                                                    <Tooltip title="Modify Check">
                                                        <IconButton
                                                            onClick={() => this.handleDeployCheckIssue(p.idx)}
                                                        >
                                                            <FAIcon icon="pencil" size={15} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                    {(p.status === null && (
                                        <Box className="content-light-grey" p={1}>
                                            <Grid container spacing={3} alignItems='center'>
                                                <Grid item xs={3}>
                                                    <Typography variant="body1" className="fw-400 pl-1">
                                                        QTY {p?.qtyExpected}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs={6}>
                                                            <AppButton
                                                                small
                                                                back
                                                                className="btn btn-error"
                                                                icon="exclamation-triangle"
                                                                text="Issue"
                                                                onClick={() => this.handleDeployCheckIssue(p.idx)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <AppButton
                                                                small
                                                                back
                                                                className="btn btn-success"
                                                                icon="check"
                                                                text="Confirm"
                                                                onClick={() => this.handleCheckOkay(p.idx)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )) || (
                                        <Alert severity={p.status === 'success' ? `success` : `error`}>
                                            <Typography variant="body2" component="div" className="fw-500">
                                                {/* Check {p.status === 'success' ? `Successful` : `Issue`} */}
                                                Received {p.qtyTotal} / {p.qtyExpected}
                                            </Typography>
                                            {/* <Typography variant="body2" component="div" className="fw-300">
                                            </Typography> */}
                                        </Alert>
                                    )}
                                </Card>
                            </Grid> 
                        ))}
                        <Grid item xs={12}>
                            <Grid item xs={12} align='center'>
                                <Typography variant="body1" align='center' className="fw-400" paragraph>
                                    Notes
                                </Typography>
                                <Paper elevation={0}>
                                    <Textarea
                                        margin="none"
                                        onChange={e => this.handleNotes('check', e?.target?.value ?? '')}
                                        value={notes.check}
                                        variant="outlined"
                                        rows={2}
                                    /> 
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <AppButton
                                className="btn btn-success"
                                disabled={_.some(checkStatus, el => el.status === null)}
                                onClick={() => deployConfirmation(`Are you sure you want to book this RMA in?`, this.handleSubmit)}
                                text="Confirm RMA"
                                icon="check"
                                primary
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppButton
                                back
                                className="btn btn-error"
                                onClick={() => deployConfirmation(`Are you sure you want to restart this RMA check?`, () => this.loadComponentData(true))}
                                text="Restart"
                                icon="redo"
                            />
                        </Grid>
                    </Grid>
                )
            )
        )
    }
}

export default RMAReceived;