import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const SalesOrderMargin = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/salesOrderMargin',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Acc Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Order Number',
                        field: rowData => rowData.on,
                        dataRef: 'on',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Created By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.tb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'tb',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Ordered By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.ob]} />,
                        fieldFormat: 'staff',
                        dataRef: 'ob',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'DPD Carriage Cost',
                        field: rowData => rowData.ccdc,
                        fieldFormat: 'currency',
                        dataRef: 'ccdc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supplier Carriage Cost',
                        field: rowData => rowData.ccsc,
                        fieldFormat: 'currency',
                        dataRef: 'ccsc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Total Carriage Cost',
                        field: rowData => rowData.ccst,
                        fieldFormat: 'currency',
                        dataRef: 'ccst',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Carriage Charge',
                        field: rowData => rowData.cchg,
                        fieldFormat: 'currency',
                        dataRef: 'cchg',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Invoice Date',
                        field: rowData => rowData.invd,
                        fieldFormat: 'date',
                        dataRef: 'invd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Invoice Number',
                        field: rowData => rowData.invn,
                        dataRef: 'invn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Total Buy Price',
                        field: rowData => rowData.tbp,
                        fieldFormat: 'currency',
                        dataRef: 'tbp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Total Invoice',
                        field: rowData => rowData.ti,
                        fieldFormat: 'currency',
                        dataRef: 'ti',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Acc Code',
                        field: rowData => rowData.cac,
                        dataRef: 'cac',
                        important: true,
                        sizeToContent: true
                    },
                    // {
                    //     heading: 'Staff',
                    //     field: rowData => <StaffChip staff={staff?.[rowData?.st]} />,
                    //     fieldFormat: 'staff',
                    //     dataRef: 'st',
                    //     important: true,
                    //     sizeToContent: true,
                    // },
                    {
                        heading: 'Margin',
                        field: rowData => rowData.m,
                        fieldFormat: 'percentage:2',
                        dataRef: 'm',
                        important: true,
                        sizeToContent: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'sales-order-margin',
                noResultsText: 'Sorry, no sales order margin data is available to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Sales'],
                pdf: true,
                persistenceId: 'salesOrderMargin',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: true,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Sales Order Margin Report'
            }}
        />
    )
}

export default SalesOrderMargin;