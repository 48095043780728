import React from 'react';
import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import DataTable from 'Components/Common/DataTables/DataTable';

import PaddedError from 'Components/Common/ErrorHandling/PaddedError';
import MainStore from 'Redux/MainStore';
import { Typography } from '@material-ui/core';

function getColumns(orderReference, customer) {

    const staff = MainStore.getState().notifications?.status ?? [];

    let cols = [                 
        {
            heading: 'Actioned',
            field: rowData => rowData.oa_actioned,
            fieldFormat: 'boolean',
            dataRef: 'oa_approved',
            alignment: 'center',
            sizeToContent: true
        },             
        {
            heading: 'Approved',
            field: rowData => rowData.oa_approved,
            fieldFormat: 'boolean',
            dataRef: 'oa_approved',
            alignment: 'center',
            sizeToContent: true
        },  
        {
            heading: 'Reason',
            field: rowData => rowData.oa_reason,
            main: true,
        },   
        {
            heading: 'Comments',
            field: rowData => (
                <>
                    {(rowData.oa_comment)?.split?.('\n').map((item, key) => (
                        <Typography
                            key={key} 
                            variant="body2"
                            component="div" 
                            paragraph={key !== (_.size((rowData.oa_comment)?.split?.('\n')) - 1)}
                        >
                            {item}
                        </Typography>
                    ))}
                </>
            ),
            cellProps: () => ({
                style: {
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    overflow: 'hidden',
                }
            }),
            important: true,
            dataRef: 'oa_comment'
        },
        {
            heading: 'Decision Maker',
            field: rowData => rowData.oa_actioned ? staff?.[rowData.oa_staff_id]?.name : '-',
            fieldFormat: 'oa_staff_id',
            dataRef: 'oa_staff_id',
            alignment: 'center',
        }, 
        {
            heading: 'Reviewed On',
            field: rowData => rowData.oa_decision_datetime,
            fieldFormat: 'datetime',
            dataRef: 'oa_decision_datetime',
            alignment: 'center',
        }, 
    ];

    if(typeof(customer) == undefined) {
        cols.push(    
            {
                actions: rowData => {
                    return [
                        { name: 'View', icon: 'binoculars',  link: `/sales${rowData.order && `/${rowData.order.order_id}`}`}
                    ]
                }
            });
    }

    return cols;

}

export const SearchApprovalsTable = ({customer, dataLoading, inline, orderReference, rows}) => (
    (_.isEmpty(rows) && (
        <PaddedError inline={inline}>There are no approvals required for this order</PaddedError>
    )) || (
        <Paper elevation={inline && 0}>
            <DataTable  
                config={{
                    key: 'oa_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: dataLoading,
                    // responsiveImportance: true,
                    inline: inline ?? false,
                    plainPagination: inline && true,
                    options: {
                        dataRef: true,
                        export: {
                            title: `Sales Order Approvals`,
                            name: `SOP-Approvals`,
                            excel: true,
                            pdf: true,
                            print: true
                        }
                    }
                }}
                columns={getColumns(orderReference, customer)}
                rows={rows}
            />
        </Paper>
    )
)