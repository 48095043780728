import React, { useEffect, useState }from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';

import API from 'API';
import Search from 'Components/Common/Search/Search';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { Grid } from '@material-ui/core';
import { capitalize } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const initialState = () => ({
    access: {
        viewCustomer: false
    },
    key: uuidv4()
})

const CustomerSocialMedia = ({inline, showNewOnly, dashboard, pageTitle}) => {

    const dispatch = useDispatch();
    
    const [state, setState] = useState(initialState());

    const staff = useSelector(state => state.notifications.status);

    const refreshData = () => {
        setState(state => ({
            ...state,
            key: uuidv4()
        }))
    }

    const handleSubmit = (id, followed) => {
        API.put(`/marketing/socialMedia/${id}`, {followed: followed ? 1 : 0})
        .then(() => {
            refreshData();
        })
    }

    useEffect(() => {
        API.multiAccess([
            'view-customer'
        ])
        .then(([
            viewCustomer, 
        ]) =>  {
            setState(state => ({
                ...state,
                access: {
                    viewCustomer, 
                }
            }));
        })
    }, []);

    const {
        access,
        key
    } = state;

    return (
        <Search
            innerKey={key}
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiParams: {
                    showNewOnly: showNewOnly ? 1 : 0
                },
                apiRoute: '/marketing/socialMedia',
                columns: showNewOnly ? [
                    {
                        heading: 'Customer',
                        field: rowData => rowData.nm,
                        dataRef: 'nm',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Platform',
                        field: rowData => (
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <FAIcon icon={rowData.pf} type="brand" noMargin />
                                </Grid>
                                <Grid item xs>
                                    {rowData.ac}
                                </Grid>
                            </Grid>
                        ),
                        dataRef: 'pf',
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Customer', icon: 'binoculars', link: `/customers/${rowData.ci}`, disabled: !access.viewCustomer},
                                {name: `View on ${rowData.pf}`, icon: 'external-link', onClick: () => window.open(rowData.ac), disabled: !rowData.ac.startsWith('http')},
                                {name: `Dismiss`, icon: 'thumbs-down', onClick: () => dispatch(deployConfirmation(`Are you sure you want to dismiss this social media account?`, () => handleSubmit(rowData.id, false))), disabled: rowData.st === 0},
                                {name: `Mark as followed`, icon: 'thumbs-up', onClick: () => dispatch(deployConfirmation(`Are you sure you want to mark this social media account as followed?`, () => handleSubmit(rowData.id, true))), disabled: rowData.st === 0}
                            ]
                        }
                    }
                ] : [
                    {
                        heading: 'Customer',
                        field: rowData => rowData.nm,
                        dataRef: 'nm',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.cc,
                        dataRef: 'cc',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Platform',
                        field: rowData => (
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <FAIcon icon={rowData.pf} type="brand" noMargin />
                                </Grid>
                                <Grid item xs>
                                    {rowData.ac}
                                </Grid>
                            </Grid>
                        ),
                        dataRef: 'pf',
                    },
                    {
                        heading: 'Created By',
                        field: rowData => <StaffChip staff={staff[rowData.cb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'cb',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Created',
                        field: rowData => rowData.ca,
                        fieldFormat: 'datetime',
                        dataRef: 'ca',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Reviewed By',
                        field: rowData => rowData.st ? '-' : <StaffChip staff={staff[rowData.ub]} />,
                        fieldFormat: 'staff',
                        dataRef: 'ub',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Reviewed',
                        field: rowData => rowData.ua,
                        fieldFormat: 'datetime',
                        dataRef: 'ua',
                        sizeToContent: true,                                
                    },
                    {
                        heading: 'Status',
                        field: rowData => <StatusChip status={rowData.fw ? 'Following' : rowData.st ? 'New' : 'Dismissed'} />,
                        sizeToContent: true,                                
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Customer', icon: 'binoculars', link: `/customers/${rowData.ci}`, disabled: !access.viewCustomer},
                                {name: `View on ${capitalize(rowData.pf)}`, icon: 'external-link', onClick: () => window.open(rowData.ac), disabled: !rowData.ac.startsWith('http')},
                                {name: `Dismiss`, icon: 'thumbs-down', onClick: () => dispatch(deployConfirmation(`Are you sure you want to dismiss this social media account?`, () => handleSubmit(rowData.id, false))), disabled: rowData.st === 0},
                                {name: `Mark as followed`, icon: 'thumbs-up', onClick: () => dispatch(deployConfirmation(`Are you sure you want to mark this social media account as followed?`, () => handleSubmit(rowData.id, true))), disabled: rowData.st === 0}
                            ]
                        }
                    }
                ],
                dataRef: true,
                dashboard: dashboard ? <><strong>None - </strong>There are no new customer social media accounts to review</> : null,
                excel: true,
                inline: inline,
                key: 'id',
                name: 'social-media-list',
                noResultsText: showNewOnly ? 'No new social media accounts to review' : 'No social media account notifications found',
                pagination: true,
                pageTitleData: [0, 'Marketing', 'Social Media'],
                pdf: true,
                persistenceId: 'socialMedia',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: !inline && true,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Customer Social Media Accounts'
            }}
        />
    )
}

export default CustomerSocialMedia;