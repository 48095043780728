import * as SoundActionTypes from './Types/Types';
import Message from 'Assets/Sounds/Message.mp3';
import PickComplete from 'Assets/Sounds/PickComplete.mp3';
import PickSuccess from 'Assets/Sounds/PickSuccess.mp3';
import PickError from 'Assets/Sounds/PickError.mp3';
import NewPick from 'Assets/Sounds/NewPick.mp3';

export function handlePlaySound(src, state) {
    return (dispatch, getState) => {
        if(getState().sound.status !== 'playing') {
            dispatch({
                type: SoundActionTypes.PLAY_SOUND,
                payload: {
                    src,
                    state,
                    status: 'playing'
                }
            });
        }
    }
}

export function playSound(type, state = false) {
    let src = null;
    switch(type) {
        case 'msg':
            src = Message;
        break;
        case 'pick':
            src = NewPick;
        break;
        case 'pickError':
            src = PickError;
        break;
        case 'pickSuccess':
            src = PickSuccess;
        break;
        case 'pickComplete':
            src = PickComplete;
        break;
        default:
            src = null;
        break;
    }
    return dispatch => {
        dispatch(handlePlaySound(src, state));
    }
}

export function handleStopSound() {
    return dispatch => {
        dispatch({
            type: SoundActionTypes.STOP_SOUND,
            payload: {
                src: null,
                status: 'stop'
            }
        });
    }
}

export function stopSound() {
    return dispatch => {
        dispatch(handleStopSound());
    }
}