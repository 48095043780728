import React from 'react';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AllocatedStock from 'Components/Stock/Misc/AllocatedStock';
import API from 'API';
import AdjustmentForm from 'Components/Stock/Misc/AdjustmentForm';
import PreallocatedStock from 'Components/Stock/Misc/PreallocatedStock';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import SearchActivity from 'Components/Stock/Misc/SearchActivity';
import Tile from 'Components/Common/Tiles/Tile';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { handleTabChange, TabPanel } from 'Functions/MiscFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import InsightChip from 'Components/Common/Chips/InsightChip';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';
import { setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    allProducts: [],
    access: {
        stockAdjustment: false,
        preallocatedStock: false,
    },
    currentTab: 0,
    insight: {},
    isLoading: true,
    key: {
        movement: uuidv4()
    },
    tabTitle: {
        0: 'Stock Overview',
        1: 'Movement History',
        2: 'Pre-Allocated Stock',
        3: 'Allocated Stock'
    },
    tabText: {
        0: 'Live stock holding and insight data',
        1: 'Stock movement activity for all stocked products',
        2: 'The following stock has been allocated to orders that have not yet been processed',
        3: 'The following stock has been allocated to orders that have been processed'
    },
    stockHoldingChart: {}
})

class StockManagement extends React.Component {
    constructor(props) {
        super(props);
        this.mainContentArea = React.createRef();
        this.persistenceId = 'stockManagement';
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {

        this.props.pageTitle([0, 'Stock', 'Management']);

        if(!this.hasPageState()) {

            Promise.all([
                API.get('/products/all', { params: { forList: true }}),
                API.access('stock-adjustment'),
                API.access('preallocated-stock')
            ])
            .then(([products, stockAdjustment, preallocatedStock]) => {

                let allProducts = _.map(products.data, product => (
                    _.assign({
                        label: product.c,
                        value: product.v,
                        search: product.l
                    })
                ))

                this.setState({
                    ...this.state,
                    access: {
                        stockAdjustment: stockAdjustment?.data?.has_access ?? false,
                        preallocatedStock: preallocatedStock?.data?.has_access ?? false,
                    },
                    allProducts,
                }, () => this.loadComponentData())
            })

        }
    }

    loadComponentData = () => {
        API.get('/inventory/stats')
        .then(stats => {

            let insight = stats && stats.data, 
                isLoading = false, 
                periods = [], 
                stockHoldingIn = [], 
                stockHoldingOut = [],
                stockHoldingChart = {};
            
            if(insight) {

                for (let i = 0, j = 11; i <= j; i++) {
                    stockHoldingIn.push(insight.historic[i].in.value)
                    stockHoldingOut.push(insight.historic[i].out.value)
                    periods.push(insight.historic[i].label)
                }

                stockHoldingChart = {
                    labels: periods,
                    datasets: [
                        {
                            label: "Stock In",
                            type:'line',
                            lineTension: 0.1,
                            fill: false,
                            borderColor: '#00E676',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: '#00E676',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 4,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: '#00E676',
                            pointHoverBorderWidth: 2,
                            pointRadius: 2,
                            pointHitRadius: 10,
                            data: stockHoldingIn
                        },
                        {
                            label: "Stock Out",
                            type:'line',
                            lineTension: 0.1,
                            fill: false,
                            borderColor: '#ef5350',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: '#ef5350',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 4,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: '#ef5350',
                            pointHoverBorderWidth: 2,
                            pointRadius: 2,
                            pointHitRadius: 10,
                            data: stockHoldingOut
                        }
                    ]
                }

            }

            this.setState({
                ...this.state,
                insight,
                isLoading,
                stockHoldingChart
            })
        })
    }

    handleCallback = (type) => {
        this.setState({
            key: {
                ...this.state.key,
                [type]: uuidv4()
            }
        }, () => this.loadComponentData())
    }

    render() {
        const { classes, closeDialog, deployDialog, ui } = this.props;
        const { access, allProducts, currentTab, key, insight, isLoading, stockHoldingChart, tabText, tabTitle } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            {tabTitle[currentTab]}
                                                        </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className="mr-1" style={{height: 41.49}}>
                                                <Button
                                                    variant="text"
                                                    disabled={!access.stockAdjustment}
                                                    onClick={() => deployDialog(<AdjustmentForm callback={() => this.handleCallback('movement')} closeDialog={closeDialog} />, false, "Stock Adjustment", "standard", "sm")}
                                                >   
                                                    <FAIcon type="light" icon="plus-circle" size={15} button disabled={!access.stockAdjustment} />
                                                    New Stock Adjustment
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={0.5}>
                                                <Grid container justify="space-between" alignItems='center'>
                                                    <Grid item>
                                                        <InsightChip
                                                            icon="info-circle"
                                                            label={tabText[currentTab]}
                                                        />
                                                    </Grid>
                                                </Grid> 
                                            </Box>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={4}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6} lg={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="In Stock" />
                                                                    <Grid container spacing={3} className="pl-1">    
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='check'
                                                                                color={'#4CAF50'}
                                                                                title="In Stock Value"
                                                                                content={`£${insight.value && insight.value.InStock}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='check'
                                                                                color={'#4CAF50'}
                                                                                title="In Stock Quantity"
                                                                                content={insight.counts && insight.counts.InStock}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={6} lg={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Available Stock" />
                                                                    <Grid container spacing={3} className="pl-1">   
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='check'
                                                                                color={'#4CAF50'}
                                                                                title="Available Stock Value"
                                                                                content={`£${insight.value && insight.value.Available}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='check'
                                                                                color={'#4CAF50'}
                                                                                title="Available Stock Quantity"
                                                                                content={insight.counts && insight.counts.Available}
                                                                            />
                                                                        </Grid>   
                                                                    </Grid>   
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={6} lg={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Allocated Stock" />
                                                                    <Grid container spacing={3} className="pl-1">   
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='check'
                                                                                color={'#4CAF50'}
                                                                                title="Allocated Stock Value"
                                                                                content={`£${insight.value && insight.value.Allocated}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='check'
                                                                                color={'#4CAF50'}
                                                                                title="Allocated Stock Quantity"
                                                                                content={insight.counts && insight.counts.Allocated}
                                                                            />
                                                                        </Grid>  
                                                                    </Grid>  
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={6} lg={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Stock On Order" />
                                                                    <Grid container spacing={3} className="pl-1"> 
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='check'
                                                                                color={'#4CAF50'}
                                                                                title="Stock On Order Value"
                                                                                content={`£${insight.value && insight.value.OnOrder}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon='check'
                                                                                color={'#4CAF50'}
                                                                                title="Stock On Order Quantity"
                                                                                content={insight.counts && insight.counts.OnOrder}
                                                                            />
                                                                        </Grid>    
                                                                    </Grid>    
                                                                </PaddedPaper>
                                                            </Grid>    
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={8}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Box pb={3}>
                                                                        <Box pb={3}>
                                                                            <Heading text="Stock Movement Trend" />
                                                                        </Box>
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12}>
                                                                                <Line 
                                                                                    data={stockHoldingChart} 
                                                                                    options={{
                                                                                        legend: {
                                                                                            display: false,
                                                                                        },
                                                                                        scales: {
                                                                                            xAxes: [{
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                }
                                                                                            }],
                                                                                            yAxes: [{
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                },
                                                                                                ticks: {
                                                                                                    callback: (value, index, values) => {
                                                                                                        return value.toLocaleString("en-GB", {
                                                                                                            style: "currency",
                                                                                                            currency: "GBP"
                                                                                                        });
                                                                                                    },   
                                                                                                    beginAtZero: true,
                                                                                                    min: 0
                                                                                                }
                                                                                            }]
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <SearchActivity innerKey={key.movement} allProducts={allProducts} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <PreallocatedStock access={access} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <AllocatedStock access={access} />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Movement History" />
                                    <Divider />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="Allocated Stock" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Pre-Allocated Stock" />
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    dialog: state.dialog,
    statePersistence: state.statePersistence,
    ui: state.ui
})

const mapDispatchToProps = dispatch => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md') => dispatch(deployDialog(content, disableDialogContent, title, variant, size)),
        setPersistence: (key, state) => dispatch(setPersistence(key, state)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(StockManagement));