import React from 'react';

import Search from 'Components/Common/Search/Search';

const InStockOrShouldBe = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/inStockOrShouldBe',
                noAutoLoad: true,
                apiParams: {
                    forStockCount: true
                },
                columns: [
                    {
                        heading: 'Bin Location',
                        field: rowData => rowData.b,
                        dataRef: 'b',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Stock Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        important: true,
                        sizeToContent: true,   
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.d,
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true,   
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.su,
                        dataRef: 'su',
                        important: true,
                        sizeToContent: true,   
                    },
                    {
                        heading: 'In Stock',
                        field: rowData => rowData.s,
                        alignment: 'right',
                        dataRef: 's',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'sc',
                name: 'manual-stock-count',
                noResultsText: 'There are no stocked items to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Stock'],
                pdf: true,
                persistenceId: 'manualStockCount',
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showStockStatus: true,
                showStockType: true,
                showSupplier: true,
                showTotals: false,
                title: 'Stock Count Check'
            }}
        />
    )
}

export default InStockOrShouldBe;