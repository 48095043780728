import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { currencyFormat } from 'Functions/MiscFunctions';

const Totals = ({classes, carriageId, carriageTotal, customerVatPercent, discountTotal, grossTotal, netTotal, subDiscountTotal, subTotal, totalMargin, vatTotal, inForm}) => (
    <Table>
        <TableBody>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        Subtotal
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} style={{width: inForm ? '45%' : '1%'}} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {currencyFormat.format(subTotal)}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        Discount
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {parseFloat(discountTotal).toFixed(2) !== "0.00" ? currencyFormat.format(discountTotal < 0 ? Math.abs(discountTotal) : `-${discountTotal}`) : '-'}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        Delivery
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {((carriageId === "" || subTotal === "0.00") ? "-" : (carriageTotal !== "0.00" ? `${currencyFormat.format(carriageTotal)}` : 'Free')) || '-'}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="subtitle1" className="fw-500">
                        Net Total
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="subtitle1" className="fw-500">
                        {currencyFormat.format(netTotal)}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        VAT {customerVatPercent > 0 ? `(${customerVatPercent}%)` : ''}
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {(vatTotal > 0.00 && currencyFormat.format(vatTotal)) || '-'}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        Gross Total
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {currencyFormat.format(grossTotal)}
                    </Typography>
                </TableCell>
            </TableRow>
            {inForm && (
                <>
                    <TableRow>
                        <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                            <Typography variant="body2" className='fw-400'>
                                Total Margin
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                            <Typography variant="body2">
                                {subTotal > 0.00 && totalMargin.length > 0 ? `${parseFloat(totalMargin).toFixed(2)}%` : '-'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>  
                        <TableCell align="right" className={classes.td}>
                            <Typography variant="body2" className='fw-400'>
                                Total Discount
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                            <Typography variant="body2">
                                {discountTotal !== 0.00 && subTotal > 0.00 ? `${parseFloat((subDiscountTotal / subTotal) * 100).toFixed(2)}%` : '-'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </>
            )}
        </TableBody>
    </Table>
)

export default Totals;