import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { 
    Avatar, 
    Box, 
    Card, 
    Grid, 
    // Paper, 
    TextField,
    Typography 
} from '@material-ui/core';

import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import logo from 'Assets/Images/no-img.jpg';
import PrintJobDialog from 'Components/Common/Dialogs/PrintJobDialog';
// import Textarea from 'Components/Common/Inputs/Textarea';
import { CLOUDFRONT_URL } from 'Constants';
import { isNumeric } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = () => ({
    formData: {
        rows: {},
        // notes: ''
    },
    isLoading: true,
    supplier: null,
    suppliers: [],
})

class ConsignmentStockEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        this.props.pageTitle?.([1, 'Warehouse', 'Consignment Stock Edit'])
        this.loadComponentData();
    }

    loadComponentData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/suppliers/all`, 
                { 
                    params: {
                        forConsignment: true 
                    }
                }
            )
            .then(res => {
                if(res?.data) {

                    let isLoading = false,
                        supplier  = null,
                        suppliers = res.data;

                    this.setState({
                        isLoading,
                        suppliers,
                        supplier
                    })

                }
            })
        })
    }

    handleSupplierChange = supplier => {

        let isLoading = true;

        this.setState({
            isLoading
        }, () => {

            let rows = {};

            _.each(_.sortBy(this.state.suppliers[supplier]?.products, ['c']), (row, rowIdx) => {
                rows = {
                    ...rows,
                    [rowIdx]: row
                }
            })

            isLoading = false; 

            this.setState({
                formData: {
                    ...this.state.formData,
                    rows
                },
                isLoading,
                supplier
            })

        })

    }

    handleDeliveryQty = (idx, sd) => {

        if((!isNumeric(sd) || parseInt(sd) < 0) && sd !== '') {
            return;
        }

        let { rows } = this.state.formData;

        rows = {
            ...rows,
            [idx]: {
                ...rows[idx],
                sd
            }
        }

        this.setState({
            formData: {
                ...this.state.formData,
                rows
            }
        })

    }

    // handleNotes = notes => {
    //     this.setState({
    //         formData: {
    //             ...this.state.formData,
    //             notes
    //         }
    //     })
    // }

    handleSubmit = () => {

        const {
            deployDialog,
            deploySnackBar,
            history
        } = this.props;

        this.setState({
            isLoading: true
        }, () => {

            let { 
                formData,
                supplier,
                suppliers
            } = this.state;

            formData = {
                ...formData,
                supplier: suppliers?.[supplier]?.v
            }

            API.post(`/inventory/adjustment/consignment`, formData)
            .then(res => {

                if(res?.data) {

                    if(res.data.errors) {

                        deploySnackBar('error', 'There was an issue processing your request, please try again')

                    } else {

                        deploySnackBar('success', 'You have successfully processed this consignment stock delivery')
                        
                        if(res?.data.printJob) {
                            
                            deployDialog(
                                <PrintJobDialog
                                    printJob={res.data.printJob}
                                    docTitle={res.data.printDoc}
                                    docSubtitle={res.data.printSub}
                                    onClose={() => history.push(`/dashboard`)}
                                />,
                                true,
                                "",
                                "standard",
                                "sm",
                                false,
                                true
                            );

                        } else {
                            history.push(`/dashboard`)
                        }

                    }

                }
                
            })

        })

    }

    render = () => {

        const { 
            formData,
            isLoading,
            supplier,
            suppliers,
        } = this.state;

        const { 
            deployConfirmation
         } = this.props;

        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container justify="space-between">
                            <Grid item xs={12} align="center">
                                <Typography variant="h5" component="div" className="fw-500" gutterBottom={supplier !== null}>
                                    Consignment Stock Edit
                                </Typography>
                                {supplier !== null && (
                                    <Typography variant="body2" component="div" className="fw-400" gutterBottom>
                                        {suppliers?.[supplier]?.l}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {(supplier === null && (
                        <>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    {_.map(suppliers, (supp, idx) => (
                                        <Grid item xs={12} key={idx}>
                                            <Card onClick={() => this.handleSupplierChange(idx)} className="link">
                                                <Box p={2}>
                                                    <Grid container alignItems='center' justify='space-between'>
                                                        <Grid item xs={12}>
                                                            <Box pb={0.5}>
                                                                <Typography variant="body2" className="fw-400 textDefault">
                                                                    {supp.l}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="caption">
                                                                {_.size(supp.products)} consignment products
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <AppButton
                                    onClick={() => this.props.history.push('/dashboard')}
                                    back
                                    text={`Main Menu`}
                                />
                            </Grid>
                        </>
                    )) || (
                        <Grid container spacing={3}>
                            {_.map(formData.rows, (p, idx) => (
                                <Grid item xs={12} key={idx}>
                                    <Card>
                                        <Box p={2}>
                                            <Grid container spacing={2} alignItems='center'>
                                                <Grid item>
                                                    <Avatar variant="square" alt={p.c ?? 'Product Image'} src={p.im ? `${CLOUDFRONT_URL}${p.im}` : logo} style={{width: 50, height: 50}} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="h6" className="fw-400">
                                                        {p.c}
                                                    </Typography>
                                                    <Typography variant="caption" className="fw-300">
                                                        {p.d}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="content-light-grey" p={1}>
                                            <Grid container alignItems='center'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1" className="fw-400 pl-1">
                                                        In Stock: {p.s} ({p.b})
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item xs={8} align='right'>
                                                            <Typography variant="body1" className="fw-400">
                                                                Delivered:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField 
                                                                value={formData?.rows?.[idx]?.sd}
                                                                onChange={e => this.handleDeliveryQty(idx, e?.target?.value ?? '')}
                                                                margin="none"
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                className="bg-light"
                                                                inputProps={{
                                                                    size: 2,
                                                                    pattern: '[0-9]*',
                                                                    inputMode: 'none'
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Grid> 
                            ))}
                            {/* <Grid item xs={12}>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="body1" align='center' className="fw-400" paragraph>
                                        Notes
                                    </Typography>
                                    <Paper elevation={0}>
                                        <Textarea
                                            margin="none"
                                            onChange={e => this.handleNotes(e?.target?.value ?? '')}
                                            value={formData.notes}
                                            variant="outlined"
                                            rows={2}
                                        /> 
                                    </Paper>
                                </Grid>
                            </Grid> */}
                            <Grid item xs={12}>
                                <AppButton
                                    className="btn btn-success"
                                    disabled={_.every(formData.rows, el => el.sd === '' || parseInt(el.sd) === 0)}
                                    onClick={() => deployConfirmation(`Are you sure you want to update stock levels?`, this.handleSubmit)}
                                    text="Update Stock Levels"
                                    icon="check"
                                    primary
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AppButton
                                    back
                                    className="btn btn-error"
                                    onClick={() => deployConfirmation(`Are you sure you want to start again?`, this.loadComponentData)}
                                    text="Reset"
                                    icon="redo"
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, yes, no = false, yesText = null, noText = null, yesOnly = false) => dispatch(deployConfirmation(message, yes, no, yesText, noText, yesOnly)), 
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, de)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(ConsignmentStockEdit);