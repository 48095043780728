import React, { useState } from 'react';
import moment from 'moment';
import uuidv4 from 'uuid';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Prompt } from 'react-router';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AddressLookupDialog from 'Components/Common/AddressLookup/AddressLookupDialog';
import Alert from 'Components/Common/Alert/Alert';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Form from 'Components/Common/Styles/Form';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ShippingAddress from 'Components/Purchases/PurchaseOrderForm/ShippingAddress';
import Button from 'Components/Purchases/PurchaseOrderForm/Button';
import Notes from 'Components/Purchases/PurchaseOrderForm/Notes';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Product from 'Components/Purchases/PurchaseOrderForm/Product';
import Products from 'Components/Purchases/PurchaseOrderForm/Products';
import Preview from 'Components/Purchases/PurchaseOrderForm/Preview';
import Shipping from 'Components/Purchases/PurchaseOrderForm/Shipping';
import SupplierSearchSelector from 'Components/Suppliers/Misc/SupplierSearchSelector';
import Tabs from 'Components/Purchases/PurchaseOrderForm/Tabs';
import Textarea from 'Components/Common/Inputs/Textarea';
import Totals from 'Components/Purchases/PurchaseOrderForm/Totals';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleChange } from 'Functions/FormFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { currencyFormat, getLeadTime, TabPanel } from 'Functions/MiscFunctions';
import { VIEW_PAGE_OFFSET } from 'Constants';

const PurchaseOrderVariation = ({closeDialog, costChanges, currentVat, currentVatValue, deployConfirmation, handleSetVariations, initialProductRows, previousVat, previousVatValue, productRows, vatChange}) => {

    const initialState = {
        vatPercent: previousVat,
        vatValue: previousVatValue,
        productRows: productRows,
        showHeadings: vatChange && !_.isEmpty(costChanges)
    }  
    
    const [state, setState] = useState(initialState);

    const handleSubmit = () => {
        deployConfirmation(
            'Are you sure you want to apply selected changes?',
            () => {
                handleSetVariations(state.vatValue, state.vatPercent, state.productRows);
                closeDialog();
            }
        )
    }

    const handleCostChange = (idx, cost) => {
        let newProductRows = [...state.productRows];
        newProductRows[idx].cost = cost;
        newProductRows[idx].totalCost = (parseFloat(newProductRows[idx].quantity) * parseFloat(newProductRows[idx].cost)).toFixed(2)
        setState(state => ({
            ...state,
            productRows: newProductRows
        }))
    }

    const handleAll = checked => {

        let newProductRows = [...state.productRows];

        _.each(costChanges, row => {

            const cost = checked ? row.currentCost : row.previousCost;

            newProductRows[row.idx].cost = cost;
            newProductRows[row.idx].totalCost = (parseFloat(newProductRows[row.idx].quantity) * parseFloat(newProductRows[row.idx].cost)).toFixed(2)
        
        })

        setState(state => ({
            ...state,
            productRows: newProductRows,
            vatPercent: checked ? currentVat : previousVat,
            vatValue: checked ? currentVatValue : previousVatValue
        }))
    }


    const handleVatChange = vatPercent => {

        let newProductRows = [...state.productRows];
        
        _.each(newProductRows, row => {

            let vat = 0;
            if(vatPercent && vatPercent !== null) {
                vat = (parseFloat(newProductRows[row.idx].quantity) * ((parseFloat(newProductRows[row.idx].cost) / 100) * vatPercent).toFixed(2)).toFixed(2);
            }
            
            newProductRows[row.idx].vat = vat;
        
        })

        setState(state => ({
            ...state,
            vatPercent,
            productRows: newProductRows,
            vatValue: vatPercent === currentVat ? currentVatValue : previousVatValue
        }))
    }

    return (
        <Grid container spacing={3}>
            {!_.isEmpty(costChanges) && (
                <Grid item xs={12}>
                    {state.showHeadings && (
                        <Typography variant="h6" gutterBottom className="mt-3">
                            Product Cost Changes
                        </Typography>
                    )}
                    <DataTable  
                        config={{
                            key: 'idx',
                            alternatingRowColours: true,
                            responsiveImportance: true,
                            rowsPerPage: 100,
                            inline: state.showHeadings ? true : undefined
                        }}
                        columns={[
                            {
                                heading: 'Product',
                                field: rowData => (
                                    <>
                                        <Typography variant="body1" className="fw-400">
                                            {rowData.productCode}
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData.productName}
                                        </Typography>
                                    </>
                                ),
                                important: true,
                            },
                            {
                                heading: 'Quantity',
                                field: rowData => rowData.quantity,
                                important: true,
                                alignment: 'right',
                                sizeToContent: true
                            },
                            {
                                heading: 'Previous Cost',
                                field: rowData => rowData.previousCost,
                                fieldFormat: 'currency',
                                sizeToContent: true,
                                alignment: 'right',
                                cellProps: rowData => ({
                                    className: 'textError',
                                    style: {
                                        textDecoration: state.productRows[rowData.idx].cost !== rowData.previousCost ? 'line-through' : undefined
                                    }
                                })
                            },
                            {
                                heading: 'Current Cost',
                                field: rowData => rowData.currentCost,
                                fieldFormat: 'currency',
                                sizeToContent: true,
                                alignment: 'right',
                                cellProps: rowData => ({
                                    className: 'textSuccess',
                                    style: {
                                        textDecoration: state.productRows[rowData.idx].cost !== rowData.currentCost ? 'line-through' : undefined
                                    }
                                })
                            },
                            {
                                heading: 'Update Cost?',
                                field: rowData => {
                                    const   newCost     = state.productRows[rowData.idx].cost === rowData.currentCost,
                                            cost        = newCost ? rowData.previousCost : rowData.currentCost;
                                    return (
                                        <Checkbox color="primary" checked={newCost} onChange={() => handleCostChange(rowData.idx, cost)} />
                                    )
                                },
                                sizeToContent: true,
                                alignment: 'right',
                            }
                        ]}
                        rows={costChanges}
                    />
                </Grid>
            )}
            {vatChange && (
                <Grid item xs={12}>
                    {state.showHeadings && (
                        <Typography variant="h6" gutterBottom>
                            VAT Rate Change
                        </Typography>
                    )}
                    <DataTable  
                        config={{
                            alternatingRowColours: true,
                            responsiveImportance: true,
                            rowsPerPage: 100,
                            inline: state.showHeadings ? true : undefined
                        }}
                        columns={[
                            {
                                heading: 'Previous VAT',
                                field: rowData => `${rowData.previousVat}%`,
                                sizeToContent: true,
                                alignment: 'right',
                                cellProps: rowData => ({
                                    className: 'textError',
                                    style: {
                                        textDecoration: state.vatPercent !== rowData.previousVat ? 'line-through' : undefined
                                    }
                                })
                            },
                            {
                                heading: 'Current VAT',
                                field: rowData => `${rowData.currentVat}%`,
                                sizeToContent: true,
                                alignment: 'right',
                                cellProps: rowData => ({
                                    className: 'textSuccess',
                                    style: {
                                        textDecoration: state.vatPercent !== rowData.currentVat ? 'line-through' : undefined
                                    }
                                })
                            },
                            {
                                heading: 'Update VAT?',
                                field: rowData => {
                                    const   newVat  = state.vatPercent === rowData.currentVat,
                                            vat     = newVat ? rowData.previousVat : rowData.currentVat;
                                    return (
                                        <Checkbox color="primary" checked={newVat} onChange={() => handleVatChange(vat)} />
                                    )
                                },
                                alignment: 'right',
                            }
                        ]}
                        rows={[
                            {
                                previousVat,
                                currentVat,
                            }
                        ]}
                    />
                </Grid>
            )}
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <MuiButton
                            color="primary"
                            onClick={() => handleAll(false)}
                            variant="text"
                        >
                            De-select All
                        </MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton
                            color="primary"
                            onClick={() => handleAll(true)}
                            variant="text"
                        >
                            Select All
                        </MuiButton>
                    </Grid>
                    <Grid item>
                        <MuiButton
                            color="primary"
                            onClick={handleSubmit}
                            variant="text"
                        >
                            Confirm
                        </MuiButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const initialState = () => ({
    alertText: null,
    carriage: [],
    carriageManual: false,
    currentTab: 0,
    supplier: {
        id: 0,
        data: {}
    },
    dialog: {
        addressLookup: false,
        addressLookupKey: uuidv4(),
        addressType: ''
    },
    disableSupplierTab: false,
    existingNotes: [],
    formData: {
        supplier: null,
        suppRef: '',
        carriage: '',
        deliveryAddressNotes: '',
        subTotal: "0.00",
        carriageTotal: "0.00",
        netTotal: "0.00",
        vatTotal: "0.00",
        grossTotal: "0.00",
        notes: '',
        schedule: moment().format("YYYY-MM-DD"),
        productRows: [],
        internalNotes: [],
        vatPercent: 20,
        vatValue: 1
    },
    formErrors: [],
    id: false,
    initialLoading: true,
    initialFormData: {
        supplier: null,
        suppRef: '',
        carriage: '',
        deliveryAddressNotes: '',
        subTotal: "0.00",
        carriageTotal: "0.00",
        netTotal: "0.00",
        vatTotal: "0.00",
        grossTotal: "0.00",
        notes: '',
        schedule: moment().format("YYYY-MM-DD"),
        productRows: [],
        internalNotes: [],
        vatPercent: 20,
        vatValue: 1
    },
    isLoading: false,
    isLoadingPreview: false,
    lists: {
        carriage: [],
        suppliers: [],
        teams: []
    },
    products: [],
    preview: {
        pdf: false,
        numPages: null,
        pageNumber: 1
    },
    ui: {
        height: 0,
        contentHeight: 0
    },
    unsavedLine: false,
    vatPercent: 0,
    vatValue: 0,
})

class PurchaseOrderForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
        this.poll = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            if(this.poll) {
                clearInterval(this.poll);
            }
            this.setState({
                ...initialState()
            }, () => this.loadComponentData());
        }
        if(JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if(!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if(window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    }
    
    componentWillUnmount = () => {
        if(window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
        if(this.poll) {
            clearInterval(this.poll);
        }
        this.props.closeDialog()
    }

    handlePoll = (initial = false) => {
        API.post(`/staff/my/view`, 
            { 
                po: this.state.id,
                type: 'Update'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {
                    if(!_.isEmpty(res.data) && (_.some(res.data, el => el.u === 1) || _.some(res.data, el => el.p === 1))) {
                        this.props.deploySnackBar("error", `Sorry, another user is currently modifying this purchase order`);
                        this.props.history.push(`/purchase-orders/${this.state.id}`)
                    } else {
                        if(this.state.initialLoading) {
                            this.setState({
                                initialLoading: false
                            })
                        }
                    }
                }
            }
        })
    }

    loadComponentData = () => {
        const { ui } = this.props;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        const id = this.props.supplier ? false : (this.props.id ?? this.props.match?.params?.id ?? false);
        this.setState({
            id,
            ui: {
                height,
                contentHeight
            }
        }, () => {
            this.getData(true);
            if(this.props.pageTitle) {
                if(!this.props.replicate) {
                    this.props.pageTitle([1, "Purchase Orders", `${this.state.id ? 'Update' : 'New'} Purchase Order`])
                }
            }
        })
    }
    
    getData = initialLoad => {
        Promise.all([
            API.get('/suppliers/all', { 
                params: { 
                    forList: true 
                }
            })
        ])
        .then(([result]) => {

            if(result?.data) {
                
                const   initialLoading  =   this.state.id ? true : false,
                        formData        =   {
                                                ...this.state.formData,
                                            },
                        lists           =   {
                                                ...this.state.lists,
                                                suppliers: _.map(result.data, el => (
                                                    _.assign({
                                                        value: el.v,
                                                        label: el.l,
                                                    })
                                                )),
                                                teams: this.props.teamList
                                            },
                        initialFormData =   formData;

                this.setState({
                    formData,
                    initialFormData,
                    initialLoading,
                    lists,
                }, () => {
                    if(initialLoad) {
                        if(this.props.match?.params?.supplier) {
                            this.getSupplier(this.props.match.params.supplier);
                        }
                        if(this.state.id) {
                            if(this.props.replicate) {
                                this.replicate();
                            } else {
                                this.getUpdateData();
                            }
                        }
                    }
                })
            }
        })
    }

    getSupplier = (id, setPageTitle = true) => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/suppliers/${id}`, { params: { forPo: true } })
            .then(res => {
                if(res?.data) {

                    let     supplier    = res.data?.supplier,
                            products    = res.data?.products, 
                            carriage    = res.data?.carriage,
                            isLoading   = false,
                            lists       = {...this.state.lists},
                            vatPercent  = supplier?.vat_type?.default_vat_value?.vat_value_percentage ?? 20,
                            vatValue    = supplier?.vat_type?.default_vat_value?.vat_value_id ?? 1;

                    if(!_.isEmpty(carriage)) {
                        lists = {
                            ...lists,
                            carriage: _.map(_.sortBy(carriage, ['supp_carr_courier', 'supp_carr_service']), el => (
                                _.assign({
                                    label: `${el.supp_carr_courier} ${el.supp_carr_service} - £${el.supp_carr_cost}`,
                                    value: el.supp_carr_id,
                                })
                            ))
                        }
                    }

                    if(!_.some(lists.carriage, el => el.value === 0)) {
                        lists.carriage.push(
                            {
                                label: 'Other - See Notes',
                                value: 0
                            }
                        )
                    }
                    this.setState({
                        carriage,
                        currentTab: 1,
                        formData: {
                            ...this.state.formData,
                            carriage: supplier.supp_def_supp_carr_id !== 0 ? (carriage[_.findIndex(carriage, el => el.supp_carr_id === supplier.supp_def_supp_carr_id)]?.supp_carr_id ?? '') : '',
                            carriageTotal: supplier.supp_def_supp_carr_id !== 0 ? (carriage[_.findIndex(carriage, el => el.supp_carr_id === supplier.supp_def_supp_carr_id)]?.supp_carr_cost ?? '0.00') : '0.00',
                            supplier: supplier.supp_id,
                            vatPercent,
                            vatValue
                        },
                        isLoading,
                        lists,
                        products,
                        supplier: {
                            id: supplier.supp_id,
                            data: supplier
                        },
                        vatPercent,
                        vatValue
                    }, () => {
                        if(this.props.pageTitle && setPageTitle) {
                            this.props.pageTitle([1, "Purchase Orders", `${this.props.replicate ? 'Re-order' : 'New'} Purchase Order`, `${supplier.supp_company_name} ${!_.isEmpty(supplier.supp_lead_time) ? ` (${getLeadTime(supplier.supp_lead_time, true)} Lead)` : ``}`])
                        }
                        this.handleCalculateTotals(true);
                    });
                }
            })
        })
    }

    getUpdateData = () => {
        
        const { 
            deploySnackBar,
            history 
        } = this.props;

        const { 
            id 
        } = this.state;

        API.get(`/purchaseOrders/${id}`, { 
            params: { 
                forUpdate: true 
            }
        })
        .then(res => {
            if(res?.data) {

                if(res?.data?.po_status === 'Cancelled' || res.data?.po_status === 'Invoiced' || res.data?.po_status === 'Despatched') {
                    deploySnackBar("error", `This purchase order can no longer be modified as it has been ${res.data?.po_status?.toLowerCase?.()}`)
                    history.push(`/purchase-orders/${id}`)
                    return;
                }

                if(res.data?.po_status !== "Awaiting Submission") {
                    history.push(`/purchase-orders/live-update/${id}`)
                    return;
                }

                let currentTab          = 1,
                    alertText           = res.data.po_status === 'Awaiting Confirmation' ? 'This purchase order has been submitted to the supplier already' : '',
                    disableSupplierTab  = true,
                    formData            = initialState().formData,
                    existingNotes       = res.data.notes,
                    productRows         = [],
                    currentVat          = res.data.supplier?.vat_type?.default_vat_value?.vat_value_percentage ?? this.state.formData.vatPercent,
                    currentVatValue     = res.data.supplier?.vat_type?.default_vat_value?.vat_value_id ?? this.state.formData.vatValue,
                    previousVat         = res.data.vat?.vat_value_percentage ?? 0,
                    previousVatValue    = res.data.vat?.vat_value_id ?? 0,
                    vatChange           = false,
                    costChanges         = [];

                if(previousVat !== currentVat) {
                    vatChange = true;
                }

                _.each(res.data.products, (product, idx) => {
                    const   productId           = product.product?.product_id ?? product.pop_product_id,
                            productName         = product.product?.product_name ?? product?.pop_product_name,
                            productCode         = product?.product_code ?? product.pop_product_code,
                            quantity            = product?.pop_qty,
                            quantityDespatch    = product?.pop_qty_despatch,
                            cost                = product?.pop_buy_price,
                            totalCost           = product?.pop_total_buy_price,
                            image               = product?.product_img,
                            productData         = product?.product ?? {},
                            previousCost        = product?.pop_buy_price,
                            currentCost         = product?.product?.product_cost,
                            vat                 = product?.pop_vat;

                    productRows.push({
                        productId,
                        productName,
                        productCode,
                        quantity,
                        quantityDespatch,
                        cost: currentCost,
                        totalCost,
                        image,
                        productData,
                        vat
                    })

                    if(cost !== currentCost) {
                        costChanges.push(
                            {
                                idx,
                                productName,
                                productCode,
                                quantity,
                                previousCost,
                                currentCost
                            }
                        )
                    }
                })

                formData = {
                    ...initialState().formData,
                    ...formData,
                    supplier: res.data.po_supp_id,
                    carriage: res.data.po_supp_carr_id,
                    deliveryAddressNotes: res.data.po_delivery_address_notes,
                    notes: res.data.po_notes,
                    schedule: res.data.po_submission_date !== "0000-00-00" ? moment(res.data.po_submission_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    suppRef: res.data.po_supp_reference,
                    vatValue: previousVatValue,
                    vatPercent: previousVat,
                    productRows
                }

                this.setState({
                    alertText,
                    currentTab,
                    disableSupplierTab,
                    existingNotes,
                    formData,
                }, () => {

                    if(this.props.pageTitle) {
                        this.props.pageTitle([1, "Purchase Orders", `Update Purchase Order`, `#${res.data.po_reference}`]);
                    }

                    if(vatChange || !_.isEmpty(costChanges)) {
                        const title = (vatChange && !_.isEmpty(res.costChanges)) ? 'Product Cost and VAT Rate Changes' : (vatChange ? 'VAT Rate Change' : 'Product Cost Changes')
                        this.props.deployDialog(
                            <PurchaseOrderVariation
                                closeDialog={this.props.closeDialog}
                                costChanges={costChanges}
                                currentVat={currentVat}
                                currentVatValue={currentVatValue}
                                deployConfirmation={this.props.deployConfirmation}
                                handleSetVariations={this.handleSetVariations}
                                initialProductRows={JSON.stringify(this.state.formData.productRows)}
                                previousVat={previousVat}
                                previousVatValue={previousVatValue}
                                productRows={this.state.formData.productRows}
                                vatChange={vatChange}
                            />, 
                            false,
                            title,
                            "error",
                            "md",
                            false,
                            true
                        );
                    }

                    this.getSupplier(res.data.po_supp_id, false);

                    this.handleCalculateTotals(true);
                    
                    this.handlePoll(true);
                    this.poll = setInterval(this.handlePoll, 3000);
                });
            }
        })
    }
    
    replicate = () => {
        const { id } = this.state;
        API.get(`/purchaseOrders/${id}`, { 
            params: { 
                forUpdate: true 
            }
        })
        .then(res => {
            if(res?.data) {

                if(res.data.po_type !== "Stock") {
                    this.props.history.push(`/purchase-orders/${res.data.po_id}`)
                }

                let initialLoading            = false,
                    currentTab                = 1,
                    formData                  = initialState().formData,
                    productRows               = [],
                    id                        = false,
                    carriage                  = res.data.po_supp_carr_id,
                    carriageTotal             = res.data.po_total_carriage,
                    { vatPercent, vatValue}   = this.state.formData;

                _.each(res.data.products, product => {
                    productRows.push({
                        productId: product.product?.product_id ?? product.pop_product_id,
                        productName: product.product?.product_name ?? product?.pop_product_name,
                        productCode: product?.product_code ?? product.pop_product_code,
                        quantity: product?.pop_qty,
                        quantityDespatch: product?.pop_qty,
                        cost: product?.product?.product_cost,
                        totalCost: parseFloat(product.pop_qty * (product?.product?.product_cost ?? 0)).toFixed(2),
                        image: product?.product_img,
                        productData: product?.product ?? {}
                    })
                })

                formData = {
                    ...initialState().formData,
                    ...formData,
                    supplier: res.data.po_supp_id,
                    carriage,
                    carriageTotal,
                    deliveryAddressNotes: res.data.po_delivery_address_notes,
                    notes: res.data.po_notes,
                    schedule: moment().format("YYYY-MM-DD"),
                    productRows,
                    vatPercent,
                    vatValue
                }

                this.setState({
                    currentTab,
                    disableSupplierTab: true,
                    formData,
                    initialLoading,
                    id
                }, () => {
                    this.getSupplier(res.data.po_supp_id);
                    this.handleCalculateTotals(true);
                });
            }  
        })
    }

    handleTabChange = (event, tabIndex) => {

        const currentTab = !isNaN(event) ? event : tabIndex;

        const {
            unsavedLine
        } = this.state;
        
        if(unsavedLine) {
            this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to change tab?</Typography><Typography variant="body2">You have an unsaved line</Typography></>, () => {
                this.setState({ unsavedLine: false }, () => {
                    this.handleDoTabChange(currentTab);
                });
            });
        } else {
            if(currentTab === 0) {
                this.props.deployConfirmation("Are you sure you want to change the supplier?", this.handleSupplierReset)
            } else {
                this.handleDoTabChange(currentTab);
            }
        }
    }   

    handleDoTabChange = currentTab => {
        const isLoading = currentTab === 4 ? true : this.state.isLoading
        this.setState({
            currentTab,
            isLoading 
        }, () => {
            if(this.state.currentTab === 4) {
                this.handleSubmitPreview();
            }
            if(this.mainContentArea && this.mainContentArea.current) 
                this.mainContentArea.current.scrollTop = 0;
        })
    }

    handleCalculateTotals = (setInitial = false) => {
        const { carriage, carriageManual, formData, supplier:{data} } = this.state;
        let subTotal = "0.00"
        let carriageTotal = "0.00"
        let netTotal = "0.00"
        let vatTotal = "0.00"
        let grossTotal = "0.00"

        formData.productRows.forEach(row => {
            subTotal = (parseFloat(subTotal) + parseFloat(row.totalCost)).toFixed(2)
        })

        carriageTotal = parseFloat(formData.carriageTotal).toFixed(2)

        netTotal = (parseFloat(subTotal) + parseFloat(carriageTotal)).toFixed(2)

        if(formData.vatPercent !== null) {
            vatTotal = ((netTotal / 100) * formData.vatPercent).toFixed(2)
        }
        
        grossTotal = (parseFloat(netTotal) + parseFloat(vatTotal)).toFixed(2)

        let { initialFormData } = this.state;
        if(setInitial) {
            initialFormData = {
                ...this.state.formData,
                subTotal,    
                carriageTotal,
                netTotal,
                vatTotal,   
                grossTotal
            }
        }

        this.setState({
            formData: {
                ...this.state.formData,
                subTotal,    
                carriageTotal,
                netTotal,
                vatTotal,   
                grossTotal
            },
            initialFormData
        }, () => {
            if(data?.supp_foc_min_order_value !== "0.00" && !carriageManual) {
                if(parseFloat(subTotal) >= parseFloat(data?.supp_foc_min_order_value)) {
                    if(data?.supp_def_foc_supp_carr_id > 0 && formData.carriage !== data?.supp_def_foc_supp_carr_id) {
                        const idx = _.findIndex(carriage, el => el.supp_carr_id === data.supp_def_foc_supp_carr_id);
                        if(idx !== -1) {
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    carriage: data.supp_def_foc_supp_carr_id,
                                    carriageTotal: carriage[idx].supp_carr_cost
                                }
                            }, () => {
                                this.props.deploySnackBar("success", "This purchase order now qualifies for free delivery, the delivery method has been updated");
                                this.handleCalculateTotals();
                            })
                        }
                    }
                } else {
                    if(formData.carriage === data?.supp_def_foc_supp_carr_id) {
                        const idx = _.findIndex(carriage, el => el.supp_carr_id === data.supp_def_supp_carr_id);
                        if(idx !== -1) {
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    carriage: data?.supp_def_supp_carr_id,
                                    carriageTotal: carriage?.[idx]?.supp_carr_cost ?? '0.00'
                                }
                            }, () => {
                                this.props.deploySnackBar("warning", "This purchase order no longer qualifies for free delivery, the delivery method has been updated");
                                this.handleCalculateTotals();
                            })
                        } else {
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    carriage: '',
                                    carriageTotal: '0.00'
                                }
                            }, () => {
                                this.props.deploySnackBar("warning", "This purchase order no longer qualifies for free delivery, the delivery method has been updated");
                                this.handleCalculateTotals();
                            })
                        }
                    }
                }
            }
        })
    }
    
    handleSupplierChange = id => {
        if(id) {
            this.getSupplier(id)
        }
    }

    handleSupplierReset = () => {
        const { ui } = this.state;
        this.setState({
            ...initialState(),
            ui
        }, () => {
            this.getData();
            this.handleDoTabChange(0)
        })
    }

    handleCustomDeliveryAddress = address => {
        if(!address) {
            this.setState({
                dialog: initialState().dialog,
                formData: {
                    ...this.state.formData,
                    deliveryAddress: "",
                    deliveryAddressCustom: {},
                    deliveryAddressNotes: ""
                }
            })
        } else {
            this.setState({
                dialog: initialState().dialog,
                formData: {
                    ...this.state.formData,
                    deliveryAddress: -1,
                    deliveryAddressCustom: address,
                    deliveryAddressNotes: ""
                }
            })
        }
    }

    handleScheduleChange = schedule => {
        this.setState({
            formData: {
                ...this.state.formData,
                schedule
            }
        })
    }

    handleCsvReOrder = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                productRows: _.orderBy(this.state.formData.productRows, ['displayOrder'], ['asc'])
            }
        }, () => {
            this.props.deploySnackBar("success", "Imported products successfully re-ordered according to the CSV file")
        });
    }

    handleProductAdd = productRow => {
        let productRows = [...this.state.formData.productRows];
        productRows.push(productRow)
    
        this.setState({
            formData: {
                ...this.state.formData,
                productRows,
            },
            unsavedLine: false
        }, () => this.handleCalculateTotals())
    }
  
    handleProductDrag = (prevRows, startIndex, endIndex) => {
        const productRows = Array.from(prevRows);
        const [removed] = productRows.splice(startIndex, 1);
        productRows.splice(endIndex, 0, removed);
        this.setState({
            formData:{
                ...this.state.formData,
                productRows
            }
        })
    };
    
    handleProductEdit = productRow => {
        let productRows = [...this.state.formData.productRows];
        productRows[productRow.rowIdx] = productRow;
        this.setState({
            formData: {
                ...this.state.formData,
                productRows,
            }
        }, () => this.handleCalculateTotals())
    }

    handleProductRemove = idx => {
        const productRows = [...this.state.formData.productRows]
        productRows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                productRows
            }
        }, ()=> this.handleCalculateTotals())
    }

    handleNoteAdd = note => {
        let internalNotes = [...this.state.formData.internalNotes];
        internalNotes.push(note)
    
        this.setState({
            formData: {
                ...this.state.formData,
                internalNotes,
            }
        }, () => this.handleCalculateTotals())
    }
  
    handleNoteEdit = note => {
        let internalNotes = [...this.state.formData.internalNotes];
        internalNotes[note.rowIdx] = note;
        this.setState({
            formData: {
                ...this.state.formData,
                internalNotes,
            }
        }, () => this.handleCalculateTotals())
    }

    handleNoteRemove = idx => {
        const internalNotes = [...this.state.formData.internalNotes]
        internalNotes.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                internalNotes
            }
        }, ()=> this.handleCalculateTotals())
    }
    
    handleSelectChange = (fieldName, selectedOption) => {
       if(fieldName === "carriage") {
            const { carriage } = this.state;
            const value = selectedOption?.value ?? "";
            const idx = _.findIndex(carriage, el => el.supp_carr_id === value);
            let carriageTotal = "0.00"
            if(idx !== -1) {
                carriageTotal = parseFloat(carriage[idx].supp_carr_cost).toFixed(2)
            }
            this.setState({
                carriageManual: true,
                formData: {
                    ...this.state.formData,
                    [fieldName]: value,
                    carriageTotal
                }
            }, () => this.handleCalculateTotals());
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: selectedOption?.value ?? ""
                }
            });
        }
    }
  
    handlePreviewPagination = offset => {
        this.setState(prevState => ({
            preview: {
                ...prevState.preview,
                pageNumber: ((prevState?.preview?.pageNumber ?? 1) + offset)
            }
        }))
    }

    handlePreviewLoad = ({numPages}) => {
        this.setState({
            preview: {
                ...this.state.preview,
                numPages 
            }
        });
    }
    
    handleSubmitPreview = () => {
        const { formData, id } = this.state;
        const resource = id ? `/purchaseOrders/${id}` : `/purchaseOrders`
        this.setState({
            isLoading: true,
            isLoadingPreview: true,
        }, () => {
            API.post(resource, { preview: true, ...formData } )
            .then(result => {                
                if(result.data.errors) {
                    let formErrors = formatValidationErrors(result.data.errors);
                    this.setState({
                        formErrors,
                        formData: {
                            ...formData,
                            supplier: (formErrors && formErrors['supplier'] && initialState().formData.supplier) || formData.supplier,
                        },
                        currentTab: 2,
                        isLoading: false
                    })
                } else {
                    if(result.data.pdf) {
                        this.setState({
                            formErrors: false,
                            isLoading: false,
                            isLoadingPreview: false,
                            preview: {
                                ...this.state.preview,
                                numPages: null,
                                pageNumber: 1,
                                pdf: result.data.pdf
                            }
                        })
                    }
                }
            })
        })
    }

    handleConfirmation = () => {
        const action = this.state.id ? "update" : "create";
        this.props.deployConfirmation(`Are you sure you want to ${action} this purchase order?`, this.handleSubmit)
    }

    handleSetVariations = (vatValue, vatPercent, productRows) => {
        this.setState({
            formData: {
                ...this.state.formData,
                vatPercent,
                vatValue,
                productRows
            }
        }, () => this.handleCalculateTotals())
    }

    handleSubmit = () => {
        let { formData, id, initialFormData } = this.state;
        const resource = id ? `/purchaseOrders/${id}` : `/purchaseOrders`,
              action = this.state.id ? "updated" : "created";
        this.setState({
            isLoading: true
        }, () => {
            if(action === "updated") {
                formData = {
                    ...formData,
                    initialFormData
                }
            }
            API.post(resource, formData)
            .then(result => {                
                if(result.data.errors) {
                    let formErrors = formatValidationErrors(result.data.errors);
                    this.setState({
                        formErrors,
                        formData: {
                            ...formData,
                            supplier: (formErrors && formErrors['supplier'] && initialState().formData.supplier) || formData.supplier,
                        },
                        isLoading: false
                    })
                } else {
                    this.setState({ 
                        ...initialState()
                    }, () => {
                        this.props.deploySnackBar("success", `The purchase order was ${action} successfully`)
                        this.props.history.push({
                            pathname: `/purchase-orders/${result.data.id}`,
                            state: { new: true }
                        })
                    })
                }
            })
        })
    }
    
    handleUnsavedLine = () => {
        this.setState({
            unsavedLine: true
        })
    }

    render() {        
        let   { alertText } = this.state;
        const { classes, deployConfirmation, history } = this.props;
        const { currentTab, dialog, disableSupplierTab, existingNotes, formData, formErrors, id, initialLoading, isLoading, isLoadingPreview, lists, preview, products, supplier, ui } = this.state;     
        
        if(supplier?.data?.supp_min_order_value !== "0.00") {
            if(parseFloat(formData.netTotal) < parseFloat(supplier.data.supp_min_order_value)) {
                alertText = `The minimum order value of ${currencyFormat.format(supplier.data.supp_min_order_value)} has not been met`
            }
        }
        
        return (
            <Grid container className={classes.container}>
                {(initialLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Prompt
                            when={JSON.stringify(formData) !== JSON.stringify(this.state.initialFormData)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                        <Grid item className={`${classes.main} p-0`}>
                            {(isLoading && (
                                <>
                                    <LoadingCircle />
                                    {isLoadingPreview && (
                                        <Typography variant="h6" align='center'>
                                            Generating Preview
                                        </Typography>     
                                    )}    
                                </>                       
                            )) || (
                                <>
                                    <Box top={0} left={0}>
                                        {alertText && (
                                            <Alert severity="warning" variant="standard" elevation={0} className={classes.alert}>{alertText}</Alert>
                                        )}
                                    </Box>
                                    <TabPanel value={currentTab} index={0}>
                                        <Box p={3}>
                                            <SupplierSearchSelector
                                                handleAction={this.handleSupplierChange}
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1}>
                                        <Box style={{width: '100%', maxWidth: '100%'}}>
                                            <Box position='fixed' zIndex={999}>
                                                <Product   
                                                    classes={classes}
                                                    csvReOrder={this.handleCsvReOrder}
                                                    handleSubmit={this.handleProductAdd}
                                                    handleUnsavedLine={this.handleUnsavedLine}
                                                    products={products}
                                                    supplier={supplier.id}
                                                    vatPercent={formData.vatPercent}
                                                    borderRadius={0}
                                                    width={'calc(100% - 230px)'}
                                                />
                                            </Box>
                                        </Box>
                                        <Grid container style={{height: (parseInt(ui?.contentHeight ?? 0) - (alertText ? 49 : 0)), overflowY: 'auto', overflowX: 'hidden'}}>
                                            <Grid item xs={12} className={`h-100`}>
                                                <Box p={3} style={{overflowY: 'auto', height: (parseInt(ui?.height) - (alertText ? 170 : 120))}}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Box height={80} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Products   
                                                                classes={classes}
                                                                formData={formData}
                                                                formErrors={formErrors}
                                                                handleProductDrag={this.handleProductDrag}
                                                                handleProductEdit={this.handleProductEdit}
                                                                handleProductRemove={this.handleProductRemove}
                                                                products={products}
                                                                vatPercent={formData.vatPercent}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <PaddedPaper>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Purchase Order Notes
                                                                </Typography>
                                                                <Typography variant="caption" color="error" paragraph>
                                                                    <FAIcon type="solid" icon="info-circle" size={11} noMargin /> These notes will be visible to the supplier
                                                                </Typography>
                                                                <Textarea
                                                                    name="notes"
                                                                    value={formData.notes}
                                                                    onChange={this.handleChange}
                                                                    margin="none"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    rows={5}
                                                                />
                                                            </PaddedPaper>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={2}>
                                        <Box p={3}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <ShippingAddress 
                                                        formData={formData}
                                                        formErrors={formErrors}
                                                        handleChange={this.handleChange}
                                                        handleScheduleChange={this.handleScheduleChange}
                                                        supplier={supplier}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Shipping
                                                        carriageList={lists.carriage}
                                                        formData={formData}
                                                        formErrors={formErrors}
                                                        handleSelectChange={this.handleSelectChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={3}>
                                        <Box p={3}>
                                            <Notes
                                                formData={formData}
                                                existingNotes={existingNotes}
                                                handleNoteAdd={this.handleNoteAdd}
                                                handleNoteEdit={this.handleNoteEdit}
                                                handleNoteRemove={this.handleNoteRemove}
                                                loggedInStaff={this.props.loggedInStaff}
                                                staff={this.props.staff}
                                                teams={lists.teams}
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={4}>
                                        <Preview
                                            classes={classes}
                                            height={alertText ? (ui.contentHeight - 49) : ui.contentHeight}
                                            preview={preview}
                                            handlePreviewLoad={this.handlePreviewLoad}
                                            handlePreviewPagination={this.handlePreviewPagination}
                                        />
                                    </TabPanel>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} lg={2} className={`${classes.side} h-100`}>
                            <Grid container className={`${classes.relative} h-100`}>
                                <Grid item xs={12}>
                                    <Tabs
                                        classes={classes}
                                        currentTab={currentTab}
                                        disableSupplierTab={disableSupplierTab}
                                        formData={formData}
                                        handleTabChange={this.handleTabChange}
                                        id={id}
                                    />
                                </Grid>
                                <Grid item xs={12} className={`${classes.absoluteBottom} ${id ? classes.updatePoTotals : classes.newPoTotals} w-100`}>
                                    <Totals   
                                        classes={classes}
                                        aaVatPercent={formData.vatPercent}
                                        subTotal={formData.subTotal}
                                        carriageTotal={formData.carriageTotal}
                                        netTotal={formData.netTotal}
                                        vatTotal={formData.vatTotal}
                                        grossTotal={formData.grossTotal}
                                        inForm
                                    />
                                    {(!isLoading && currentTab === 4 && (
                                        <Button
                                            submit={this.handleConfirmation}
                                            disabled={(_.isEmpty(formData.productRows) || (currentTab !== 4 || isLoading)) && true}
                                            text={id ? `Update` : `Create`}
                                        />
                                    )) || (
                                        <Box mt={3}>
                                            <Grid container spacing={3} justify="center">
                                                <Grid item>
                                                    <MuiButton
                                                        color="primary"
                                                        disabled={currentTab === 0 || (currentTab === 1 && id) || isLoading}
                                                        onClick={() => this.handleTabChange((currentTab - 1))}
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="arrow-left" disabled={isLoading} size={15} button />
                                                        Back
                                                    </MuiButton>
                                                </Grid>
                                                <Grid item>
                                                    <MuiButton
                                                        color="primary"
                                                        onClick={() => this.handleTabChange((currentTab + 1))}
                                                        disabled={isLoading || currentTab === 0 || (currentTab === 3 && _.isEmpty(formData.productRows))}
                                                        variant="text"
                                                    >
                                                        <FAIcon icon="arrow-right" disabled={isLoading || (currentTab === 3 && _.isEmpty(formData.productRows))} size={15} button />
                                                        Next
                                                    </MuiButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                    {id && (
                                        <Box mt={1.25} align='center'>
                                            <MuiButton
                                                color="primary"
                                                onClick={() => deployConfirmation(`Are you sure you want to cancel changes?`, () => history.push(`/purchase-orders/${id}`))}
                                                variant="text"
                                            >
                                                <FAIcon icon="times" size={15} button />
                                                Cancel
                                            </MuiButton>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <AddressLookupDialog
                            key={dialog.addressLookupKey}
                            open={dialog.addressLookup}
                            onSubmit={this.handleCustomDeliveryAddress}
                        />
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInStaff: state.staffAuth.staff,
        staff: state.notifications.status,
        teamList: state.notifications.teamList,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success, cancel = false) => dispatch(deployConfirmation(message, success, cancel)), 
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disabled = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disabled)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Form)(withRouter(PurchaseOrderForm)));