import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import StaffMember from 'Components/Common/Staff/StaffMember';

const StaffCards = props => {
    const data = useSelector(state => state.notifications.status)
    return (
        <Grid container spacing={props.spacing ?? 3} alignItems='center'>
            {_.map(props.for, (staff, idx) => (
                <Grid item key={idx} xs={props.xs ?? 12} lg={props.lg ?? 4}>
                    <Paper elevation={props.elevation}>
                        <StaffMember    
                            staff={data[staff.staff_id]}
                            className={props.className ? props.className : 'sm-list-item-borderless-white'}
                            content={props.content ? (typeof props.content === "function" ? props.content(staff) : props.content) : false}
                            inlineContent={props.inlineContent ? (typeof props.inlineContent === "function" ? props.inlineContent(staff) : props.inlineContent) : false}
                        />
                    </Paper>
                </Grid>
            ))}
        </Grid>
    )
}

export default StaffCards;