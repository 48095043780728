import _ from 'lodash';
import * as NotificationActionTypes from 'Redux/Actions/Notifications/Types/Types';
import API from 'API';
import RTAPI from 'RTAPI';
import { loggedIn } from 'Functions/AuthFunctions';

export function handleIamActive(staffId) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.I_AM_ACTIVE,
            payload: staffId
        });
    }
}

export function handleLoadStaffConfig(config) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.LOAD_STAFF_CONFIG,
            payload: config
        });
    }
}

export function handleLoadStaffDiscount(discount) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.LOAD_STAFF_DISCOUNT,
            payload: discount
        });
    }
}

export function handleLoadStaffStatus(status) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.LOAD_STAFF_STATUS,
            payload: status
        });
    }
}

export function handleLoadTeams(teams) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.LOAD_TEAMS,
            payload: teams
        });
    }
}

export function handleApiPulse(result) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.HANDLE_API_PULSE,
            payload: result
        });
    }
}

export function handleRtApiPulse(result) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.HANDLE_RTAPI_PULSE,
            payload: result
        });
    }
}

export function handleMinusMsgBadge(count) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.MINUS_UNREAD_MESSAGE,
            payload: count
        });
    }
}

export function handleDiscardNotification(id) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.DISCARD_NOTIFICATION,
            payload: id
        });
    }
}

export function handleDiscardAllNotifications() {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.DISCARD_ALL_NOTIFICATIONS
        });
    }
}

export function handleDiscardAction(id) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.DISCARD_ACTION,
            payload: id
        });
    }
}

export function loadPulseData() {
    return (dispatch, getState) => {  
        if(loggedIn() && !document.hidden) { 
            const state = getState().notifications,
                la = state.la,
                ac = state.ac,
                ln = state.ln,
                no = state.no,
                me = state.status?.[getState()?.staffAuth?.staff?.id] ?? {};
                
            let t = [];

            if(me) {
                _.each(me.teams, team => {
                    t.push(team.id)
                })
            }

            t = t.join();

            API.get('/pulse',
            {
                params: {
                    la,
                    ln,
                    ac,
                    no,
                    t
                },
                props: {
                    handleApiPulse: true,
                    ignoreErrors: true,
                    noLoading: true
                }
            })
        }
    }
}

export function loadRtapiPulseData () { 
    return (dispatch, getState) => {  
        if(loggedIn() && !document.hidden) { 
            RTAPI.get('/pulse',
            {
                props: {
                    handleRtApiPulse: true,
                    ignoreErrors: true,
                    noLoading: true
                }
            })
        }
    }
}

export function loadStaffConfig() {
    return dispatch => {    
        API.get('/staff/my/config')
        .then(res => {
            if(res && res.data) {
                dispatch(handleLoadStaffConfig(res.data.config))
                dispatch(handleLoadStaffDiscount(res.data.discount))
            }
        })
    }
}

export function updateStaffConfig(config) {
    return dispatch => {    
        API.put('/staff/my/config', { config })
        .then(res => {
            if(res && res.data) {
                dispatch(handleLoadStaffConfig(res.data))
            }
        })
    }
}

export function loadStaffStatus() {
    return dispatch => {    
        API.get('/staff/status')
        .then(res => {
            if(res && res.data) {
                dispatch(handleLoadStaffStatus(res.data))
            }
        })
    }
}

export function loadTeams() {
    return dispatch => {    
        API.get('/teams')
        .then(res => {
            if(res && res.data) {
                dispatch(handleLoadTeams(res.data))
            }
        })
    }
}

export function dismissAllNotifications() {
    return dispatch => {    
        API.put(`/misc/notifications/dismiss`, 
            {
                props: {
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res && res.data) {      
                dispatch(handleDiscardAllNotifications())
            }
        })
    }
}

export function putNotificationStatus(id, action) {
    return dispatch => {    
        API.put(`/misc/notifications/${id}`, 
            { 
                status: action 
            },
            {
                props: {
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res && res.data) {      
                dispatch(handleDiscardNotification(id))
            }
        })
    }
}

export function putActionStatus(id, deleted = false) {
    let params = {}
    if(deleted) {
        params = {
            ...params,
            delete: 1
        }
    }
    return dispatch => {    
        API.put(`/misc/actions/${id}`, 
            params
        )
        .then(res => {
            if(res && res.data) {      
                dispatch(handleDiscardAction(id))
            }
        })
    }
}