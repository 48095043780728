import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";
import { connect, useSelector } from 'react-redux';

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import API from "API";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import StaffChip from "Components/Common/Chips/StaffChip";

import { currencyFormat, excelColumnIndexToLetter, excelFitToColumns } from "Functions/MiscFunctions";
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const handleExportPriceList = (customer, prices) => {

    let header = [],
        headerArr = [],
        exportData = [],
        wb = XLSX?.utils?.book_new(),
        headers = [
            {
                label: "Part Code",
                key: "c",
            },
            {
                label: "Description",
                key: "d",
            },
            {
                label: 'Supplier',
                key: 's'
            },
            {
                label: "Quantity",
                key: "q",
            },
            {
                label: "Existing Price",
                key: "o",
            },
            {
                label: "New Price",
                key: "p",
            },
            {
                label: "Increase %",
                key: "x",
            },
            {
                label: "Stocked",
                key: "w",
            },
            {
                label: "Future Price",
                key: "f",
            },
            {
                label: "Future Price Effective",
                key: "e",
            }
        ];

    /* Get prices to export */
    _.each(prices, (row) => {
        let exportRow = [];
        _.each(headers, (field) => {
            exportRow.push(row[field.key]);
        });
        exportData.push(exportRow);
    });

    /* Configure headers and header width */
    _.each(headers, (field, idx) => {
        header.push(field.label);
        headerArr.push(`${field.label}XXX`);
    });
    
    /* Flag to ensure document is viable */
    let hasSheet = false;

    /* Filter data types */
    let data = _.map(exportData, (el) => [el[0], el[1], el[2], parseInt(el[3]), parseFloat(el[4]), parseFloat(el[5]), parseFloat(el[6]), el[7], el[8] !== "0.00" ? parseFloat(el[8]) : "", el[9]]);

    /* Check the data is viable (at lease one product to export into the price list */
    if (!_.isEmpty(data)) {

        /* Create work sheet */
        const ws = XLSX.utils.json_to_sheet([]);
        
        /* Add headers to sheet */
        XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });

        /* Add data to sheet */
        XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });

        /* Add filters to columns */
        ws['!autofilter'] = {
            ref: "A1:H1"
        }

        /* Set column with to data set size */
        ws["!cols"] = excelFitToColumns([...data, headerArr]);

        /* Handle cell formatting */
        const currencyFormat = "£0.00";
        const range = XLSX.utils.decode_range(ws["!ref"]);

        /* Loop rows for formatting */
        for (let x = (range.s.r + 1); x <= (range.e.r + 1); ++x) {

            /* Loop columns for formatting */
            for (let y = range.s.c; y <= range.e.c; ++y) {

                const ref = `${excelColumnIndexToLetter(y)}${x}`

                /* Check reference exists */
                if(ws[ref]) {

                    /* Set cell styles */
                    ws[ref].s = {
                        font: {
                            bold: x === 1 ? true : false,
                            name: 'Arial',
                            sz: 10,
                        },
                        border: x === 1 ? {
                            bottom: {
                                style: "thin"
                            }
                        } : undefined
                    }

                    /* Add currency to "new price" */
                    if(x > 1 && excelColumnIndexToLetter(y) === "E") {
                        if(ws[ref].t === "n") {
                            ws[ref].z = currencyFormat;
                        }
                    }

                    /* Add currency to "old price" */
                    if(x > 1 && excelColumnIndexToLetter(y) === "F") {
                        if(ws[ref].t === "n") {
                            ws[ref].z = currencyFormat;
                        }
                    }

                    /* Add currency to "future price" */
                    if(x > 1 && excelColumnIndexToLetter(y) === "I") {
                        if(ws[ref].t === "n") {
                            ws[ref].z = currencyFormat;
                        }
                    }

                }

            }

        }

        /* Add sheet to book */
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            `Price List`
        );

        /* Mark export as viable */
        hasSheet = true;

    }

    /* Check if export is viable */
    if (hasSheet) {
        const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
        });

        const data = new Blob([excelBuffer], {
            type: "xlsx",
        });

        FileSaver.saveAs(
            data,
            `${customer?.account_number?.toLowerCase?.()}-price-list-${moment().format("DD-MM-YYYY")}.xlsx`
        );
    }
};

const ViewTrackedPriceList = ({customer, priceListId}) => {

    const [priceList, setPriceList] = useState(null);

    const staff = useSelector(state => state.notifications.status);

    const handleDownloadPriceList = () => {
        handleExportPriceList(customer, priceList.pr)
    }

    useEffect(() => {
        API.get(`/customers/${customer.id}/trackedPriceList/${priceListId}`)
        .then(res => {
            if(res?.data) {
                setPriceList(res.data);
            }
        })/* eslint-disable-next-line */
    }, [])

    return (
        (!priceList && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>
                            <Box pt={0.3}>
                                Generated by <StaffChip staff={staff[priceList.st]} />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleDownloadPriceList}
                                variant="text"
                                size="small"
                            >
                                <FAIcon
                                    icon="file-excel"
                                    size={15}
                                    className="textSuccess mr-1"
                                    button
                                />
                                Download Price List
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} align='right'>
                    <DataTable
                        config={{
                            key: "i",
                            pagination: true,
                            rowsPerPage: 10,
                            alternatingRowColours: true,
                            noResultsText:
                                "No products were added to this price list",
                            plainPagination: true,
                        }}
                        columns={[
                            {
                                heading: "Part Code",
                                field: (rowData) => rowData.c,
                                sizeToContent: true,
                                dataRef: "c",
                            },
                            {
                                heading: "Description",
                                field: (rowData) => rowData.d,
                                sizeToContent: true,
                                dataRef: "d",
                            },
                            {
                                heading: "Supplier",
                                field: (rowData) => rowData.s,
                                sizeToContent: true,
                                dataRef: "s",
                            },
                            {
                                heading: "Qty",
                                field: (rowData) => rowData.q,
                                sizeToContent: true,
                                dataRef: "q",
                                alignment: 'right'
                            },
                            {
                                heading: "Old Price",
                                field: (rowData) => rowData.o,
                                fieldFormat: "currency",
                                sizeToContent: true,
                                dataRef: "o",
                                alignment: 'right'
                            },
                            {
                                heading: "New Price",
                                field: (rowData) => rowData.p,
                                fieldFormat: "currency",
                                sizeToContent: true,
                                dataRef: "p",
                                alignment: 'right'
                            },
                            {
                                heading: "Increase %",
                                field: (rowData) => rowData.x,
                                fieldFormat: "percentage:2",
                                sizeToContent: true,
                                dataRef: "x",
                                alignment: 'right'
                            },
                            {
                                heading: "Future Price",
                                field: (rowData) => rowData.f !== "0.00" ? currencyFormat.format(rowData.f) : '-',
                                // fieldFormat: "currency",
                                sizeToContent: true,
                                dataRef: "f",
                                alignment: 'right'
                            },
                            {
                                heading: "Future Price Effective",
                                field: (rowData) => rowData.e,
                                sizeToContent: true,
                                dataRef: "e",
                                alignment: 'right'
                            },
                            {
                                heading: "Stocked",
                                field: (rowData) => rowData.w,
                                fieldFormat: "boolean",
                                sizeToContent: true,
                                dataRef: "w",
                                alignment: 'center'
                            }
                        ]}
                        rows={priceList.pr}
                    />
                </Grid>
            </Grid>
        )
    )
}

const initialState = () => ({
    priceLists: [],
    isLoading: true,
});

class CustomerPriceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        this.loadComponentData();
    };

    loadComponentData = () => {
        const { customer } = this.props;
        API.get(`/customers/${customer.id}/trackedPriceList`)
        .then(res => {
            if(res?.data) {
                this.setState({
                    priceLists: res.data,
                    isLoading: false,
                });
            }
        });
    };

    handleGeneratePriceList = () => {
        const { customer, deploySnackBar } = this.props;
        deploySnackBar(`warning`, `Please wait whilst the price list is generated...`)
        API.get(`/customers/${customer.id}/trackedPriceList/new`)
        .then(res => {
            if(res?.data) {
                deploySnackBar(`success`, `A new tracked price list has been generated for this customer`)
                this.loadComponentData();
                this.handleDeployViewPriceList(res.data.id, res.data.ca);
            }
        })
    }

    handleDeployViewPriceList = (id, ca) => {
        const { customer, deployDialog } = this.props;
        deployDialog(<ViewTrackedPriceList customer={customer} priceListId={id} />, false, `${customer.company_name} - Tracked Price List - ${ca}`, "standard", "xl");
    }

    render = () => {
        const { isLoading, priceLists } = this.state;
        const { dataLoading, deployConfirmation, staff } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {(dataLoading && <LoadingCircle />) || (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} align='right'>
                                    <Button
                                        disabled={isLoading}
                                        onClick={() => deployConfirmation(`Are you sure you want to generate a new tracked price list?`, this.handleGeneratePriceList)}
                                        variant="text"
                                    >
                                        <FAIcon
                                            icon="file-check"
                                            disabled={isLoading}
                                            size={15}
                                            className={`mr-1`}
                                            button
                                        />
                                        Generate Price List
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <DataTable
                                            config={{
                                                key: "id",
                                                pagination: true,
                                                rowsPerPage: 10,
                                                alternatingRowColours: true,
                                                isLoading: isLoading,
                                                noResultsText:
                                                    "No tracked price lists have been generated for this customer",
                                                options: {
                                                    dataRef: true,
                                                    export: {
                                                        title: `Tracked Price Lists`,
                                                        name: `TrackedPriceLists`,
                                                        excel: true,
                                                        pdf: true,
                                                        print: true
                                                    }
                                                },
                                            }}
                                            columns={[
                                                {
                                                    heading: "Reference",
                                                    field: (rowData) => `#${rowData.id.toString().padStart(5, 0)}`,
                                                    sizeToContent: true,
                                                    dataRef: "id",
                                                },
                                                {
                                                    heading: "Generated",
                                                    field: (rowData) => rowData.ca,
                                                    sizeToContent: true,
                                                    dataRef: "ca",
                                                },
                                                {
                                                    heading: "Generated By",
                                                    field: (rowData) => (staff && staff[rowData.st] && <StaffChip staff={staff[rowData.st]} />) || "-",
                                                    dataRef: "st",
                                                    fieldFormat: "staff",
                                                    main: true
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'View', icon: 'binoculars', onClick: () => this.handleDeployViewPriceList(rowData.id, rowData.ca), doubleClick: true}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={priceLists}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    };
}

const mapStateToProps = state => ({
    staff: state.notifications.status
})

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeDialog()),
    deployConfirmation: (msg, success) => dispatch(deployConfirmation(msg, success)),
    deploySnackBar: (variant, msg) => dispatch(deploySnackBar(variant, msg)),
    deployDialog: (
        content,
        disableDialogContent = false,
        title = "",
        variant = "standard",
        size = "md",
        fullscreen = false,
        disableExit = false
    ) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPriceList);
