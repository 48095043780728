import React from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/DataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import * as Settings from 'Helpers/SettingsHelper';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
        referralTypes: _.map(Settings.customerReferralTypes, el => {
            return _.assign({
                value: el,
                label: el,
            });
        }),
        formData: {
            sourceId: '',
            sourceName: '',
            sourceType: '',
        },
        updateData: {
            sourceId: '',
            sourceName: '',
            sourceType: '',
        },
        formErrors:[],
        updateErrors: [],
        dialogOpen: false,
        rows: [],
        confirmationOpen: {
            submit: false,
            update: false,
        },
        snackbarOpen: {
            submit: false,
            update: false,
        },
        isLoading: true,
        updateLoading: true
    }

class CustomerReferralSources extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getCustomerReferralSources();
    }

    submit = () => {
        API.post('/customers/referralSources', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        update: false,
                        submit: true
                    }
                });
                this.getCustomerReferralSources();
            }
            this.props.scrollToTop();
        });
    }

    update = () => {
        API.put('/customers/referralSources/'+this.state.updateData.sourceId, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        update: true,
                        submit: false
                    }
                });
                this.getCustomerReferralSources();
            }
            this.props.scrollToTop();
        });
    }

    getCustomerReferralSources = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/customers/referralSources')
            .then((result) => {
                this.setState({
                    ...this.state,
                    rows: result.data,
                    isLoading: false
                });
            })
        })
    }
    
    getUpdateData = () => {
        API.get('/customers/referralSources/'+this.state.updateData.sourceId)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose();
            } else {
                this.setState({
                    updateLoading: false,
                    updateErrors: [],
                    updateData: {
                        ...this.state.updateData,
                        sourceName: result.data.crs_name,
                        sourceType: result.data.crs_type,
                    }
                });
            }
        })
    }
    
    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    handleUpdateChange = e => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                [e.target.name]: e.target.value
            }
        });
    }
    
    handleUpdateSelectChange = fieldName => selectedOption => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };
    
    handleSnackbarClose = (type) => {
        this.setState({ 
            snackbarOpen: {
            ...this.state.snackbarOpen,
            [type]: false,
            } 
        });
    };

    handleConfirmationOpen = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: true,
            } 
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        }, () => {
            if(type === 'submit') {
                this.submit();
            } else {
                this.update();
            }
        });
    }

    handleDialogOpen = (id) => {
        this.setState({
            dialogOpen: true,
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                sourceId: id
            }
        }, () => this.getUpdateData());
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpen: false,
            updateLoading: true,
            updateData: {
                ...initialState.updateData
            }
        });
    }

    render() {
        const { referralTypes, formData, formErrors, isLoading, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Customer Referral Sources
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'crs_id',
                                pagination: true,
                                isLoading: isLoading,
                                plainPagination: true,
                                options: {
                                    minimalPadding: true,
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Referrer',
                                    field: rowData => rowData.crs_name,
                                    main: true
                                },     
                                {
                                    heading: 'Type',
                                    field: rowData => rowData.crs_type,
                                    main: true
                                },       
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil',  onClick: () => this.handleDialogOpen(rowData.crs_id)},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.rows}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Referral Source
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                name="sourceName"
                                label="Referrer *"
                                value={formData.sourceName}
                                error={formErrors && formErrors['sourceName'] && true}
                                helperText={formErrors && formErrors['sourceName']}
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                            <AutoCompleteSelect                                
                                options={referralTypes}
                                label='Type *'
                                onChange={this.handleSelectChange('sourceType')}
                                error={formErrors && formErrors['sourceType'] && true}
                                errorText={formErrors && formErrors['sourceType']}
                                value={formData.sourceType}
                            />
                            <div className='buttonRow'>
                                <Button 
                                    type='submit'
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="text" 
                                    color="primary" 
                                    disabled={!this.state.formData.sourceName}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Add
                                </Button>
                            </div>
                        </form>
                        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={this.state.dialogOpen} onClose={this.handleDialogClose} fullWidth={true} maxWidth="sm">
                            <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Customer Referral Source</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            name="sourceName"
                                            label="Referrer *"
                                            value={updateData.sourceName}
                                            error={updateErrors && updateErrors['sourceName'] && true}
                                            helperText={updateErrors && updateErrors['sourceName']}
                                            onChange={this.handleUpdateChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        <AutoCompleteSelect
                                            options={referralTypes}
                                            label='Type *'
                                            onChange={this.handleUpdateSelectChange('sourceType')}
                                            error={updateErrors && updateErrors['sourceType'] && true}
                                            errorText={updateErrors && updateErrors['sourceType']}
                                            value={updateData.sourceType}
                                        />
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text"><FAIcon icon="check" size={15} button />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog
                            open={this.state.confirmationOpen.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            title="Update referral source?" 
                            message="Are you sure you want to update this customer referral source?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title="Add a new referral source?" 
                            message="Are you sure you want to add a new customer referral source?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the customer referral source'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new customer referral source'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

export default CustomerReferralSources;