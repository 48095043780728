import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import API from 'API';
import ViewAddressCard from 'Components/Common/Activity/ViewAddressCard';
import DataTable from 'Components/Common/DataTables/DataTable';
import PaddedError from 'Components/Common/ErrorHandling/PaddedError';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

const ViewAddresses = ({access, AddressForm, addresses, callback, disabled, name}) => {

    const [ trading, setTrading ] = useState({});

    const dispatch = useDispatch();

    const viewAddress = (address) => {
        dispatch(deployDialog(
            <ViewAddressCard
                access={access}
                address={address}
                name={name}
                toggleDialog={() => dispatch(closeDialog())}
            />, true, "", "standard", "sm", false, true
        ))
    }

    const handleAdd = () => dispatch(deployDialog(React.cloneElement(AddressForm, { addressType: 'Delivery'}), false, "Add Delivery Address", "standard","xs", false, true));

    const handleModify = id => dispatch(deployDialog(React.cloneElement(AddressForm, { updateId: id}), false, "Modify Address", "standard","xs", false, true));
    
    const handleDelete = id => dispatch(deployConfirmation("Are you sure you want to delete this address?", () => confirmDelete(id)));

    const confirmDelete = id => {
        API.put(`/misc/addresses/${id}/delete`)
        .then(res => {
            if(res?.data) {
                if(callback) {
                    callback();
                }
            }
        })
    }

    useEffect(() => {
        if(!_.isEmpty(addresses)) {
            let tradingAddress = _.find(addresses, el => el.t === 'Trading');
            if(tradingAddress) {
                setTrading(tradingAddress);
            }
        }
    }, [addresses])
    
    return (
        (_.isEmpty(addresses) && (
            <PaddedError>
                There are no addresses stored for {name}
            </PaddedError>
        )) || (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable  
                            config={{
                                key: 'i',
                                pagination: true,
                                alternatingRowColours: true,
                                // responsiveImportance: true,
                                options: {
                                    headingInput: (
                                        AddressForm && access.addContact && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                disabled={disabled}
                                                onClick={handleAdd}
                                            >
                                                <FAIcon type="light" icon="plus-circle" size={15} button />
                                                Add New Address
                                            </Button>
                                        )
                                    ),
                                    dataRef: true,
                                    export: {
                                        title: `Addresses - ${name}`,
                                        name: `${name}-Address-List`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Default',
                                    field: rowData => rowData.t === 'Trading' && (<FAIcon icon='check' noMargin />),
                                    alignment: 'center',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Address Line 1',
                                    field: rowData => rowData?.l1 || '-',
                                    dataRef: 'l1',
                                },
                                {
                                    heading: 'Address Line 2',
                                    field: rowData => rowData?.l2 || '-',
                                    dataRef: 'l2',
                                },
                                {
                                    heading: 'Town',
                                    field: rowData => rowData?.to || '-',
                                    dataRef: 'to',
                                },
                                {
                                    heading: 'Postcode',
                                    field: rowData => rowData?.po || '-',
                                    dataRef: 'po',
                                },
                                {
                                    heading: 'County',
                                    field: rowData => rowData?.cc || '-',
                                },
                                {
                                    heading: 'Country',
                                    field: rowData => rowData?.co || '-',
                                },
                                {
                                    heading: 'Type',
                                    field: rowData => rowData.t,
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'binoculars', onClick: () => viewAddress(rowData), doubleClick: true},
                                            {name: 'Modify', icon: 'pencil', onClick: () => handleModify(rowData.i), disabled: disabled || !access.modifyAddress || (rowData.l1 === trading.l1 && rowData.l2 === trading.l2 && rowData.to === trading.to && rowData.po === trading.po && rowData.t !== 'Trading')},
                                            {name: 'Delete', icon: 'trash-alt', onClick: () => handleDelete(rowData.i), disabled: disabled || !access.deleteAddress || rowData.t === 'Trading' || rowData.t === 'Registered' || (rowData.l1 === trading.l1 && rowData.l2 === trading.l2 && rowData.to === trading.to && rowData.po === trading.po)},
                                        ]
                                    }
                                }
                            ]}
                            rows={
                                [
                                    ...(_.map(_.filter(addresses, _el => _el.t === 'Trading'),      el => el)), 
                                    ...(_.map(_.filter(addresses, _el => _el.t === 'Registered'),   el => el)), 
                                    ...(_.map(_.filter(addresses, _el => _el.t === 'Accounts'),     el => el)), 
                                    ...(_.map(_.filter(addresses, _el => _el.t === 'Delivery'),     el => el)), 
                                ]
                            }
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    )
}

export default ViewAddresses;