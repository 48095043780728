import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AutoCompleteSelect from '../Selects/AutoCompleteSelect';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import Textarea from '../Inputs/Textarea';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationSuccess,
    handleSelectChange,
    getFormData
} from 'Functions/FormFunctions';
import FAIcon from '../Icons/FontAwesome/FAIcon';
import { FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import DragFileInput from '../Inputs/DragFileInput';

const styles = theme => ({
    card: {
        color: '#fff',
        height: '270px',
        padding: '20px'
    },
    cardHeader: {
        borderLeft: '4px solid #fff',
        borderRight: '3px solid #fff',
    },
    cardHeaderAction: {
        paddingRight: 10
    },
    cardHeaderPriority: {
        borderLeft: '4px solid #ef3340',
        borderRight: '3px solid #fff',
    },
    cardContent: {
        padding: '0 16px 16px 16px'
    },
    cardActionArea: {
        alignSelf: 'center',
        marginTop: 0,
        marginRight: 0
    },
})

const initialState = () => ({
    formData: {
        emailRel: "",
        emailRelId: 0,
        emailContactId: "",
        emailIncoming: 1,
        emailName: "",
        emailAddress: "",
        emailNote: "",
        file: null,
    },
    contactList: [],
    formErrors:[],
    confirmation: {
        submit: false,
    }
})

class AddEmailLogForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();        
        this.getFormData = getFormData.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                emailRel: this.props.relation,
                emailRelId: this.props.id,
            }
        }, 
        () => {
           this.getContacts(); 
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.formData.emailContactId !== this.state.formData.emailContactId) {
            if(this.state.formData.emailContactId) {
                let contact = _.find(this.state.contactList, {value: this.state.formData.emailContactId});
                if(contact) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            emailName: contact.name,
                            emailAddress: contact.email
                        }
                    });
                
                }
            }
        }
    }

    getContacts = () => {
        API.post('/misc/email/contacts', this.state.formData)
        .then((result) => {
            if(result?.data) {
                let contactList  = [];

                _.each(result.data, el => {
                    contactList.push({
                        value: el.contact_id,
                        label: `${el.contact_first_name} ${el.contact_last_name} <${el.contact_email}>`,
                        name: `${el.contact_first_name} ${el.contact_last_name}`,
                        email: el.contact_email
                    })
                });
                this.setState({
                    contactList
                });
            }
        })
    }

    submit = () => {
        if(this.props.stateSubmit) {
            this.props.stateSubmit(this.state.formData)
        } else {
            API.post('/misc/email', this.getFormData())
            .then((result) => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors)
                    });
                } else {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            emailContactId: "",
                            emailIncoming: 1,
                            emailName: "",
                            emailAddress: "",
                            emailNote: "",
                            file: null,
                        }
                    }, () => {
                        this.props.deploySnackBar("success", "You have successfully added a new e-mail")
                        if(this.props.toggleDialog)
                            this.props.toggleDialog();
                    })
                }
            })
        }
    }

    cancel = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                emailContactId: "",
                emailIncoming: 1,
                emailName: "",
                emailAddress: "",
                emailNote: "",
                file: null,
            }
        }, () => {
            if(this.props.toggleDialog)
                this.props.toggleDialog();
        })
    }

    render() {
        const { classes } = this.props;
        const { formData, contactList } = this.state;
        return (
            <Card classes={{root: classes.cardHeader}}>
                <Box p={1.5} pb={1}>
                    <CardHeader
                        avatar={
                            <Avatar 
                                alt={this.props.loggedInStaff.name} 
                                src={this.props.loggedInStaff.picture}
                                style={{width: 35, height: 35}}
                            >
                                {this.props.loggedInStaff.firstName.charAt(0)}
                            </Avatar>
                        }        
                        classes={{
                            action: classes.cardHeaderAction, 
                        }}                    
                        title={(
                            <>
                                {this.props.loggedInStaff.name}
                                {` · `}
                                <Typography variant="caption" color="textSecondary">
                                    New E-mail
                                </Typography>
                            </>
                        )}                                        
                    />
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect
                                                label='Contact'
                                                value={formData.emailContactId}
                                                onChange={v => this.handleSelectChange('emailContactId', v)}
                                                options={contactList}
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Name"
                                                margin="none"
                                                name="emailName"
                                                value={formData.emailName}
                                                onChange={this.handleChange}
                                                fullWidth
                                            /> 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="E-mail Address"
                                                margin="none"
                                                name="emailAddress"
                                                value={formData.emailAddress}
                                                onChange={this.handleChange}
                                                fullWidth
                                            /> 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                label='Incoming E-mail'
                                                margin="none"
                                                control={
                                                    <Switch
                                                        color="primary"
                                                        checked={formData.emailIncoming === 1}
                                                        onChange={v => this.handleChange({target: {name: 'emailIncoming', value: formData.emailIncoming === 1 ? 0 : 1}})}
                                                        value="1"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DragFileInput
                                                id="file"
                                                name="file"
                                                label="Upload e-mail"
                                                file={formData.file}
                                                onChange={(drop, name, file) => this.handleChange({target: {name: 'file', value: file}})}
                                                emptyText='No file selected'
                                                small
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                placeholder="Notes"
                                                margin="none"
                                                name="emailNote"
                                                value={formData.emailNote}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                rows={2}
                                            /> 
                                        </Grid>
                                        <Grid item xs={12} align="right" className="pt-2">
                                            <Grid container spacing={2}>
                                                <Grid item xs />
                                                <Grid item>
                                                    <Button onClick={() => this.cancel()}
                                                            variant="text"
                                                    >
                                                        <FAIcon icon="times" size={15} button />
                                                        Discard
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button 
                                                        onClick={() => this.handleConfirmationOpen('submit')}
                                                        variant="text" 
                                                        color="primary" 
                                                    >
                                                        <FAIcon icon="check" size={15} button />
                                                        Add E-mail
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>  
                                <ConfirmationDialog 
                                    open={this.state.confirmation.submit} 
                                    success={() => this.handleConfirmationSuccess('submit')} 
                                    close={() => this.handleConfirmationClose('submit')} 
                                    title="Add a new e-mail?" 
                                    message="Are you sure you want to add this e-mail?"
                                />                            
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </Card>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddEmailLogForm));