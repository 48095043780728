import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { getPresignedUrl } from 'Functions/S3Functions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

const getLegacyOrderDeliveryInvoicePDF = deliveryId => {
    API.get('/sales/delivery/' + deliveryId + '/legacy/pdf', { params: { invoice: true }})
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const handleSendCopyInvoice = (rowData, deployDialog) => {

    API.get(`/customers/${rowData.ci}`, 
        {
            params: {
                forInvoiceEmail: true
            }
        }
    )
    .then(res => {

        if(res?.data) {

            let defaultFormData = {
                emailSubject: `Advanced Access | Copy Invoice #${rowData.in}`,
            }
        
            if(!_.isEmpty(res.data.email)) {
                defaultFormData = {
                    ...defaultFormData,
                    emailTo: {
                        label: res.data.email,
                        value: res.data.email
                    }
                }
            }
        
            if(!_.isEmpty(res.data.cc)) {
                let emailCc = [];
                _.each(res.data.cc, cc => {
                    emailCc.push({
                        label: cc,
                        value: cc
                    })
                })
                if(!_.isEmpty(emailCc)) {
                    defaultFormData = {
                        ...defaultFormData,
                        emailCc
                    }
                }
            }

            deployDialog(
                <EmailDialog
                    id={rowData.id}
                    type='invoice'
                    success='The copy invoice has been successfully sent'
                    defaultFormData={defaultFormData}
                />, false, "E-mail Copy Invoice", "standard","md", false, true
            );

        }

    })

}

const getColumns = ({access, basic, compact, customer, deployDialog, staff}) => {
    if(basic || compact) {
        return [ 
            {
                heading: 'Invoice Number',
                field: rowData => rowData.in ?? '-',
                dataRef: 'in',
                sizeToContent: true
            },  
            {
                heading: 'SO Number',
                field: rowData => rowData.r,
                dataRef: 'r',
                sizeToContent: true
            },
            {
                heading: 'Items Cost',
                field: rowData => rowData.co,
                dataRef: 'co',
                fieldFormat: 'currency',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Items Total',
                field: rowData => rowData.to,
                dataRef: 'to',
                fieldFormat: 'currency',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Raised By',
                field: rowData => (staff && staff[rowData.st] && <StaffChip staff={staff[rowData.st]} />) || '-',
                dataRef: 'st',
                fieldFormat: 'staff',
                sizeToContent: true
            }, 
            {
                heading: 'Raised',
                field: rowData => rowData.cr,
                fieldFormat: 'date',
                dataRef: 'cr',
                sizeToContent: true
            }, 
            {
                heading: 'Due',
                field: rowData => rowData.ip,
                fieldFormat: 'date',
                dataRef: 'ip',
                sizeToContent: true
            },
            {
                actions: rowData => ([
                    { name: 'View Delivery', icon: 'binoculars',  link: `/sales/${rowData.o}:${rowData.od}`, disabled: !access.viewOrder, doubleClick: true },
                    { name: 'View Invoice', icon: 'file-pdf', disabled: !rowData.ik && rowData.l === 0, color: rowData.ik || rowData.l === 1 ? 'error' : undefined,  onClick: () => rowData.l === 1 ? getLegacyOrderDeliveryInvoicePDF(rowData.od) : getPresignedUrl(rowData.ik)}
                ])
            }
        ]
    } else if(customer) {
        return [ 
            {
                heading: 'Invoice Number',
                field: rowData => rowData.in ?? '-',
                dataRef: 'in',
                sizeToContent: true,
                showTotalLabel: true
            },  
            {
                heading: 'SO Number',
                field: rowData => rowData.r,
                dataRef: 'r',
                sizeToContent: true
            },
            {
                heading: 'Items Cost',
                field: rowData => rowData.co,
                dataRef: 'co',
                fieldFormat: 'currency',
                alignment: 'right',
                sizeToContent: true,
                showTotal: true
            },
            {
                heading: 'Items Total',
                field: rowData => rowData.to,
                dataRef: 'to',
                fieldFormat: 'currency',
                alignment: 'right',
                sizeToContent: true,
                showTotal: true
            },
            {
                heading: 'Raised By',
                field: rowData => (staff && staff[rowData.st] && <StaffChip staff={staff[rowData.st]} />) || '-',
                dataRef: 'st',
                fieldFormat: 'staff',
                sizeToContent: true
            }, 
            {
                heading: 'Raised',
                field: rowData => rowData.cr,
                fieldFormat: 'date',
                dataRef: 'cr',
                sizeToContent: true
            }, 
            {
                heading: 'Due',
                field: rowData => rowData.ip,
                fieldFormat: 'date',
                dataRef: 'ip',
                sizeToContent: true
            },
            {
                actions: rowData => ([
                    { name: 'View Delivery', icon: 'binoculars',  link: `/sales/${rowData.o}:${rowData.od}`, disabled: !access.viewOrder, doubleClick: true },
                    { name: 'E-mail Copy', icon: 'envelope', onClick: () => handleSendCopyInvoice(rowData, deployDialog), disabled: !access.copyInvoice, hideIfDisabled: true},
                    { name: 'View Invoice', icon: 'file-pdf', disabled: !rowData.ik && rowData.l === 0, color: rowData.ik || rowData.l === 1 ? 'error' : undefined,  onClick: () => rowData.l === 1 ? getLegacyOrderDeliveryInvoicePDF(rowData.od) : getPresignedUrl(rowData.ik)},
                ])
            }
        ]
    } else {
        return [ 
            {
                heading: 'Invoice Number',
                field: rowData => rowData.in ?? '-',
                dataRef: 'in',
                sizeToContent: true
            },  
            {
                heading: 'SO Number',
                field: rowData => rowData.r,
                dataRef: 'r',
                sizeToContent: true
            },
            {
                heading: 'Customer',
                field: rowData => rowData.c,
                dataRef: 'c',
                truncate: true,
                sizeToContent: true
            },
            {
                heading: 'Acc Number',
                field: rowData => rowData.a,
                dataRef: 'a',
                sizeToContent: true
            },
            {
                heading: 'Items Cost',
                field: rowData => rowData.co,
                dataRef: 'co',
                fieldFormat: 'currency',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Items Total',
                field: rowData => rowData.to,
                dataRef: 'to',
                fieldFormat: 'currency',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Raised By',
                field: rowData => (staff && staff[rowData.st] && <StaffChip staff={staff[rowData.st]} />) || '-',
                dataRef: 'st',
                fieldFormat: 'staff',
                sizeToContent: true
            }, 
            {
                heading: 'Raised',
                field: rowData => rowData.cr,
                fieldFormat: 'date',
                dataRef: 'cr',
                sizeToContent: true
            }, 
            {
                heading: 'Due',
                field: rowData => rowData.ip,
                fieldFormat: 'date',
                dataRef: 'ip',
                sizeToContent: true
            },
            {
                actions: rowData => ([
                    { name: 'View Delivery', icon: 'binoculars',  link: `/sales/${rowData.o}:${rowData.od}`, disabled: !access.viewOrder, doubleClick: true },
                    { name: 'E-mail Copy', icon: 'envelope', onClick: () => handleSendCopyInvoice(rowData, deployDialog), disabled: !access.copyInvoice, hideIfDisabled: true},
                    { name: 'View Invoice', icon: 'file-pdf', disabled: !rowData.ik && rowData.l === 0, color: rowData.ik || rowData.l === 1 ? 'error' : undefined,  onClick: () => rowData.l === 1 ? getLegacyOrderDeliveryInvoicePDF(rowData.od) : getPresignedUrl(rowData.ik)},
                ])
            }
        ]
    }
}

const SearchInvoicesTable = ({access, callback, customer, dataLoading, deployDialog, basic, compact, persistenceId, resetSearch, rows}) => {

    const staff = useSelector(state => state.notifications.status);
    
    return (
        <DataTable
            config={{
                key: 'id',
                pagination: true,
                alternatingRowColours: true,
                isLoading: dataLoading,
                inline: basic,
                plainPagination: basic,
                persistenceId: persistenceId ?? null,
                showFullTotals: customer ? true : false,
                options: {
                    dataRef: true,
                    reset: resetSearch || false,
                    export: {
                        title: `Invoices`,
                        name: `aa-invoices`,
                        excel: true,
                        pdf: true,
                        print: true
                    }
                }
            }}
            columns={getColumns({access, basic, compact, customer, deployDialog, staff})}
            rows={rows}
        />
    )
}

export default SearchInvoicesTable;