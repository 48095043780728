import React from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const DeliveryInstructions = ({
    formData, 
    handleChange
}) => {

    const carriageData = formData?.carriage?.carriageData ?? {};

    return (
        <PaddedPaper>
            <Typography variant="h6" paragraph>
                {carriageData.collection ? `Collection` : `DPD Delivery`} Instructions
            </Typography>
            <TextField
                name="deliveryInstructions"
                value={formData.deliveryInstructions}
                onChange={handleChange}
                margin="none"
                variant="outlined"
                inputProps={{
                    maxLength: 50
                }}
                fullWidth
                paragraph
            />
            <Box mt={1.5}>
                <Typography variant="caption">
                    <FAIcon icon="info-circle" size={12.5} />
                    Max length 50 characters
                </Typography>
            </Box>
        </PaddedPaper>
    )
}

export default DeliveryInstructions;