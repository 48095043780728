import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Map from 'Components/Common/Map/Map';

const ViewAddressCard = ({access, address, name, toggleDialog}) => (
    <Box className="content-light-grey" p={3}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs>
                        <Typography variant="h6">
                            {address.t} Address
                        </Typography>
                        <Typography variant='body2' className="fw-400">
                            {(address.n && address.n) || name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Close">
                            <IconButton
                                onClick={toggleDialog}
                            >
                                <FAIcon icon="times" size={20} button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box className="content-light-white" p={3}>
                    <Typography variant="body2" className="fw-400" gutterBottom>
                        Location
                    </Typography>
                    {_.map([
                        address.l1,
                        address.l2,
                        address.l3,
                        address.to,
                        address.cc,
                        address.po,
                        address.co
                    ], (line, idx) => {
                        if(line?.length > 0) {
                            return (
                                <Typography variant="body2" key={idx} component='div'>
                                    {line}
                                </Typography>
                            )
                        }
                    })}
                    <Typography variant="caption" component="div" className="textError pt-2">
                        <Link href={`https://maps.google.com/?q=${address.lt},${address.lg}`} target={`_blank`}>
                            <Grid container alignItems='center'>
                                <Grid item>
                                    <FAIcon icon="directions" size={15} button /> 
                                </Grid>
                                <Grid item xs>
                                    <Box pb={0.5} fontWeight={400}>
                                        Get Directions via Google Maps
                                    </Box>
                                </Grid>
                            </Grid>
                        </Link>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box className="content-light-white" p={3}>
                    <Typography variant="body2" className="fw-400" gutterBottom>
                        Delivery Instructions
                    </Typography>
                    <Typography variant="body2">
                        {(!_.isEmpty(address.no) && (
                            address.no
                        )) || (
                            <em>N/A</em>
                        )}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className="content-light-white">
                    <Map
                        controllable
                        height={250}
                        lat={address.lt}
                        long={address.lg}
                        style={{cursor: 'default !important'}}
                    />
                </Box>
            </Grid>
        </Grid>
    </Box>
)

export default ViewAddressCard