import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import DateSelect from 'Components/Common/Selects/DateSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import SearchInvoicesTable from 'Components/Accounts/SearchInvoices/SearchInvoicesTable';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { playSound } from 'Redux/Actions/Sounds/Sounds';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        copyInvoice: false,
        viewOrder: false,
    },
    dataLoading: true,
    date: 'threeMonths',
    searchResults: [],
    searchString: ''
})

class SearchInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.persistenceId = `invoiceSearch:${this.props.customerId ?? 'all'}`
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = null;
    }

    componentDidMount = () => {
        this.props.pageTitle?.([0, 'Finance', 'Invoices']);
        if(!this.hasPageState()) {
            Promise.all([
                API.access('copy-invoice'),
                API.access('view-order')
            ])
            .then(([
                copyInvoice,
                viewOrder
            ]) =>  {
                this.setState({
                    ...this.getInitialState(),
                    access: {
                        copyInvoice: copyInvoice?.data?.has_access ?? false,
                        viewOrder: viewOrder?.data?.has_access ?? false
                    },
                }, () => {
                    this.getSearchData();
                })
            })
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.getSearchData();
        });
    }

    getSearchData = (noLoading = false) => {

        let params = {
            d: this.state.date,
            searchString: this.state.searchString,
        }

        if(this.props.customerId) {
            params = {
                ...params,
                c: this.props.customerId
            }
        }
        
        this.setState({
            dataLoading: noLoading ? false : true
        }, () => {
            API.get('/accounts/invoices', {
                props: {
                    cancellation: true,
                    noLoading,
                },
                params
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data ?? []
                    }, () => {
                        this.savePageState();
                    })
                } 
            })
        })
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access,
                date: 'threeMonths'
            }, () => {
                this.getSearchData();
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }
    
    render() {
        const { customerId, deployDialog } = this.props;
        let { access, dataLoading, date, searchResults, searchString } = this.state;
        return (
            <Grid container spacing={1}>      
                <Grid item xs={12} alignItems='center'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4} xl={3} style={{marginBottom: 12}}>
                            <TextField 
                                fullWidth
                                onChange={this.onSearchStringChange}
                                placeholder='Search:'
                                value={searchString} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                }}
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} xl={2} style={{marginBottom: 12}}>
                            <DateSelect
                                onChange={this.handleSelectChange('date')}
                                value={date}
                            />
                        </Grid>
                    </Grid>
                </Grid>           
                <Grid item xs={12}>
                    <Paper>
                        <SearchInvoicesTable 
                            access={access}
                            customer={customerId}
                            dataLoading={dataLoading} 
                            deployDialog={deployDialog}
                            persistenceId={this.persistenceId}
                            rows={searchResults} 
                            resetSearch={this.resetSearch} 
                        />
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    ui: state.ui,
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen)),
        playSound: (type, state = false) => dispatch(playSound(type, state)),
        setPersistence: (key, state) => dispatch(setPersistence(key, state)),
        clearPersistence: () => dispatch(clearPersistence())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchInvoices);