import React from "react";
import uuidv4 from "uuid/v4";
import _ from "lodash";
import moment from 'moment';

import { connect } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import API from "API";
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import DragFileInput from "Components/Common/Inputs/DragFileInput";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import IconText from "Components/Common/Text/IconText";
import Logo from "Assets/Images/no-img.jpg";
import ProductSearchSelector from "Components/Products/Misc/ProductSearchSelector";

import { getFormData, handleChange, handleFileChange, handleCheckedChange } from "Functions/FormFunctions";

import { currencyFormat, isNumeric } from "Functions/MiscFunctions";

import { formatValidationErrors } from "Helpers/ErrorHelper";

import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";

import { closeDialog } from "Redux/Actions/Dialog/Dialog";

import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

import { CLOUDFRONT_URL } from "Constants";

const getDragContainerStyle = (isDraggingOver) => ({
    backgroundColor: isDraggingOver ? "#ef3340" : undefined,
    display: "flex",
    overflow: "auto",
});

const getDragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
});

const reorderDraggedItems = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const initialState = () => ({
    financial: {
        minPrice: "0.00",
        maxPrice: "0.00",
        minCost: "0.00",
        maxCost: "0.00",
        minMargin: "0.0",
        maxMargin: "0.0",
    },
    formData: {
        id: 0,
        type: "",
        code: "",
        name: "",
        img: null,
        newImg: null,
        live: 1,
        vf: null,
        vt: null,
        fp: '',
        uuid: uuidv4(),
        categories: [],
    },
    formDefaults: {
        category: {
            text: "",
            qty: "",
            req: 1,
            uuid: null,
            products: [],
        },
    },
    formErrors: [],
});

class BundleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.getFormData = getFormData.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleFileChange = handleFileChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
    }

    componentDidMount = () => {
        if (this.props.id && this.props.data) {
            const categories = [];

            _.each(this.props.data?.cats, (c) => {
                const products = [];

                _.each(c.p, (p) => {
                    products.push({
                        id: p.id,
                        img: !_.isEmpty(p.im) && p.im !== "x" ? p.img : null,
                        code: `${p.dc} | Trade Price: £${p.tp} | Buy Price: £${p.bp} | Margin: ${p.mr}%`,
                        tp: p.tp,
                        bp: p.bp,
                        mr: p.mr,
                        name: p.nm,
                    });
                });

                categories.push({
                    text: c.text,
                    qty: c.qty,
                    req: c.req,
                    products,
                });
            });

            this.setState({
                financial: {
                    ...this.state.financial,
                    minPrice: this.props.data?.minPrice,
                    maxPrice: this.props.data?.maxPrice,
                    minCost: this.props.data?.minCost,
                    maxCost: this.props.data?.maxCost,
                    minMargin: this.props.data?.minMargin,
                    maxMargin: this.props.data?.maxMargin,
                },
                formData: {
                    ...this.state.formData,
                    id: this.props.data?.id ?? 0,
                    type: this.props.data?.ty ?? 'Optional Bundle',
                    name: this.props.data?.nm ?? "",
                    code: this.props.data?.pc ?? '',
                    img: !_.isEmpty(this.props.data?.im) ? this.props.data?.im : null,
                    vf: this.props.data?.vf ?? null,
                    vt: this.props.data?.vt ?? null,
                    fp: this.props.data?.fp ?? '',
                    live: this.props.data?.lv ?? 1,
                    uuid: this.props.data?.uuid ?? uuidv4(),
                    categories,
                },
            }, () => {
                this.handleCalculateFinancial()
            });
        }
    };

    handleCategoryAdd = () => {
        let categories = [...this.state.formData.categories];

        categories.push({
            ...this.state.formDefaults.category,
            uuid: uuidv4(),
        });

        this.setState({
            formData: {
                ...this.state.formData,
                categories,
            },
        });
    };

    handleCategoryDelete = (idx) => {
        let categories = [...this.state.formData.categories];

        categories.splice(idx, 1);

        this.setState({
            formData: {
                ...this.state.formData,
                categories,
            },
        });
    };

    handleCategoryChange = (idx, field, value) => {
        let categories = [...this.state.formData.categories];

        if (field === "qty" && !isNumeric(value)) {
            categories[idx][field] = "";
        } else {
            categories[idx][field] = value;
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    categories,
                },
            },
            () => {
                if (field === "qty" || field === "req") {
                    this.handleCalculateFinancial();
                }
            }
        );
    };

    handleCategoryAddProduct = (idx, product) => {

        let categories = [...this.state.formData.categories],
            products = [...categories[idx].products];

        products.push({
            id: product.value,
            img: product.img && product.img !== "x" ? product.img : null,
            code: `${product.label} | Buy Price: £${product.bp} | Margin: ${product.mr}%`,
            tp: product.tp,
            bp: product.bp,
            mr: product.mr,
            name: product.subtitle1,
        });

        categories[idx].products = products;

        categories[idx].uuid = uuidv4();

        if(this.state.formData.type === "Fixed Kit") {
            categories[idx].text = product.code;
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    categories,
                },
            },
            () => this.handleCalculateFinancial()
        );
    };

    handleCategoryDeleteProduct = (idx, productIdx) => {
        let categories = [...this.state.formData.categories],
            products = [...categories[idx].products];

        products.splice(productIdx, 1);

        categories[idx].products = products;

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    categories,
                },
            },
            () => this.handleCalculateFinancial()
        );
    };

    handleCalculateFinancial = () => {
        let minPrice = "0.00",
            maxPrice = "0.00",
            minCost = "0.00",
            maxCost = "0.00",
            minMargin = "0.0",
            maxMargin = "0.0";

        if (
            _.some(this.state.formData.categories, (el) => el.req === 1) &&
            _.every(this.state.formData.categories, (el) => el.qty !== "" && !isNaN(el.qty))
        ) {
            this.state.formData.categories.forEach((c) => {
                let catMinPrice = "0.00",
                    catMaxPrice = "0.00",
                    catMinCost = "0.00",
                    catMaxCost = "0.00";

                c.products.forEach((p) => {
                    let price = parseFloat(parseFloat(p.tp) * parseInt(c.qty)).toFixed(2),
                        cost = parseFloat(parseFloat(p.bp) * parseInt(c.qty)).toFixed(2);

                    if (c.req === 1) {
                        catMinPrice =
                            catMinPrice === "0.00"
                                ? price
                                : parseFloat(price) < parseFloat(catMinPrice)
                                ? price
                                : catMinPrice;
                        catMinCost =
                            catMinCost === "0.00"
                                ? cost
                                : parseFloat(cost) < parseFloat(catMinCost)
                                ? cost
                                : catMinCost;
                    }

                    catMaxPrice =
                        catMaxPrice === "0.00"
                            ? price
                            : parseFloat(price) > parseFloat(catMaxPrice)
                            ? price
                            : catMaxPrice;
                    catMaxCost =
                        catMaxCost === "0.00" ? cost : parseFloat(cost) > parseFloat(catMaxCost) ? cost : catMaxCost;
                });

                minPrice = parseFloat(parseFloat(minPrice) + parseFloat(catMinPrice)).toFixed(2);
                maxPrice = parseFloat(parseFloat(maxPrice) + parseFloat(catMaxPrice)).toFixed(2);

                minCost = parseFloat(parseFloat(minCost) + parseFloat(catMinCost)).toFixed(2);
                maxCost = parseFloat(parseFloat(maxCost) + parseFloat(catMaxCost)).toFixed(2);
            });

            let mnMargin, mxMargin;
            
            if(this.state.formData.type === "Fixed Kit") {
                mnMargin = (((parseFloat(this.state.formData.fp) - parseFloat(minCost)) / parseFloat(this.state.formData.fp)) * 100).toFixed(1);
                mxMargin = (((parseFloat(this.state.formData.fp) - parseFloat(maxCost)) / parseFloat(this.state.formData.fp)) * 100).toFixed(1);
            } else {
                mnMargin = (((parseFloat(minPrice) - parseFloat(minCost)) / parseFloat(minPrice)) * 100).toFixed(1);
                mxMargin = (((parseFloat(maxPrice) - parseFloat(maxCost)) / parseFloat(maxPrice)) * 100).toFixed(1);
            }

            if (parseFloat(mnMargin) > parseFloat(mxMargin)) {
                minMargin = mxMargin;
                maxMargin = mnMargin;
            } else {
                minMargin = mnMargin;
                maxMargin = mxMargin;
            }
        }

        this.setState({
            financial: {
                ...this.state.financial,
                minPrice,
                maxPrice,
                minCost,
                maxCost,
                minMargin,
                maxMargin,
            },
        });
    };

    handleReOrderCategories = (result) => {
        if (!result.destination) {
            return;
        }

        const categories = reorderDraggedItems(
            this.state.formData.categories,
            result.source.index,
            result.destination.index
        );

        this.setState({
            formData: {
                ...this.state.formData,
                categories,
            },
        });
    };

    handleReOrderCategoryProducts = (idx, result) => {
        if (!result.destination) {
            return;
        }

        let categories = [...this.state.formData.categories],
            products = [...categories[idx].products];

        products = reorderDraggedItems(products, result.source.index, result.destination.index);

        categories[idx].products = products;

        this.setState({
            formData: {
                ...this.state.formData,
                categories,
            },
        });
    };

    handleSubmit = () => {
        API.post(`/products/bundles`, this.getFormData()).then((res) => {
            if (res?.data) {
                if (res?.data?.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                    });
                } else {
                    this.props.deploySnackBar(
                        `success`,
                        `You have successfully ${this.state.formData.id > 0 ? "updated the" : "created a new"} bundle`
                    );

                    this.props.callback?.();

                    this.props.closeDialog();
                }
            }
        });
    };

    render = () => {
        const { financial, formData, formErrors } = this.state;

        let disabled =
            formData.code === '' ||
            (formData.type === 'Fixed Kit' && formData.fp === '') ||
            (formData.type === 'Fixed Kit' && formData.vf === null) ||
            (formData.type === 'Fixed Kit' && formData.vt === null) ||
            formData.name.length === 0 ||
            _.isEmpty(formData.categories) ||
            _.every(formData.categories, (c) => c.req === 0) ||
            _.some(
                formData.categories,
                (c, elIdx) =>
                    (formData.type === 'Optional Bundle' && c.text.length === 0) ||
                    c.qty.length === 0 ||
                    c.qty === "0" ||
                    _.isEmpty(c.products) ||
                    (formData.type === 'Optional Bundle' && _.some(formData.categories, (el, selIdx) => elIdx !== selIdx && el.text === c.text))
            );

        return (
            (formData.type === '' && (
                <Box textAlign={"center"} pt={"11%"}>
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12}>
                            <Box pb={5}>
                                <Typography variant="h4" className="fw-300">
                                    What type of bundle do you want to create?
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item onClick={() => this.handleChange({target: { name: 'type', value: 'Fixed Kit'}})}>
                            <Box borderRadius={24} style={{width: 350}} className="content-light-white content-light-white-clickable" p={5}>
                                <Box pb={2.5}>
                                    <FAIcon icon="box-circle-check" type="thin" size={100} noMargin />
                                </Box>
                                <Typography variant="h4" className="fw-300" gutterBottom>
                                    Fixed Kit
                                </Typography>
                                <Typography variant="body2" className="fw-300">
                                    This is a bundle of products that is fixed and <br />
                                    cannot be customised, with a set price.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item onClick={() => this.handleChange({target: { name: 'type', value: 'Optional Bundle'}})}>
                            <Box borderRadius={24} style={{width: 350}} className="content-light-white content-light-white-clickable" p={5}>
                                <Box pb={2.5}>
                                    <FAIcon icon="user-gear" type="thin" size={100} noMargin />
                                </Box>
                                <Typography variant="h4" className="fw-300" gutterBottom>
                                    Optional Bundle
                                </Typography>
                                <Typography variant="body2" className="fw-300">
                                    The customer can choose from a list of <br />
                                    products, based on the customer's price.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )) || (
                <Grid container spacing={3}>
                    <Grid
                        item
                        style={{
                            width: 450,
                            position: "fixed",
                            height: "calc(100% - 64px)",
                            overflowX: "hidden",
                            overflowY: "auto",
                            top: 64,
                            left: 0,
                            backgroundColor: "#EEE",
                            borderRight: "1px solid #ddd",
                        }}
                    >
                        <Box p={1.5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3} pb={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" gutterBottom>
                                                        {formData.type === 'Optional Bundle' ? 'Bundle' : 'Kit'} Details
                                                    </Typography>
                                                    <TextField
                                                        id="name"
                                                        label={`${formData.type === 'Optional Bundle' ? 'Bundle' : 'Kit'} Name *`}
                                                        name="name"
                                                        value={formData.name}
                                                        error={formErrors && formErrors["name"] && true}
                                                        helperText={formErrors && formErrors["name"]}
                                                        onChange={this.handleChange}
                                                        margin="none"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        id="code"
                                                        label={`${formData.type === 'Optional Bundle' ? 'Bundle' : 'Kit'} Code *`}
                                                        name="code"
                                                        value={formData.code}
                                                        error={formErrors && formErrors["code"] && true}
                                                        helperText={formErrors && formErrors["code"]}
                                                        onChange={this.handleChange}
                                                        margin="none"
                                                        fullWidth
                                                    />
                                                    {formData.type === 'Fixed Kit' && (
                                                        <>
                                                            <TextField
                                                                id="fp"
                                                                label={`Kit Trade Price *`}
                                                                name="fp"
                                                                value={formData.fp}
                                                                error={formErrors && formErrors["fp"] && true}
                                                                helperText={formErrors && formErrors["fp"]}
                                                                onChange={this.handleChange}
                                                                onBlur={this.handleCalculateFinancial}
                                                                margin="none"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment>
                                                                            <FAIcon icon="pound-sign" size={12} type="light" noMargin button />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                fullWidth
                                                            />
                                                            <Box mb={1}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={6}>
                                                                        <FormControl fullWidth>
                                                                            <DatePicker
                                                                                name="vf"
                                                                                type="date"
                                                                                label={`Valid From *`}
                                                                                onChange={date => this.handleChange({target: { name: 'vf', value: moment(date).format('YYYY-MM-DD')}})}
                                                                                value={formData.vf}
                                                                                autoOk={true}
                                                                                disablePast={true}
                                                                                maxDate={formData.vt ? formData.vt : undefined}
                                                                                minDateMessage={``}
                                                                                maxDateMessage={``}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <FormControl fullWidth>
                                                                            <DatePicker
                                                                                name="vt"
                                                                                type="date"
                                                                                label={`Valid To *`}
                                                                                onChange={date => this.handleChange({target: { name: 'vt', value: moment(date).format('YYYY-MM-DD')}})}
                                                                                value={formData.vt}
                                                                                autoOk={true}
                                                                                disablePast={true}
                                                                                minDate={formData.vf ? formData.vf : undefined}
                                                                                minDateMessage={``}
                                                                                maxDateMessage={``}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </>
                                                    )}
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                checked={formData.live}
                                                                onChange={this.handleCheckedChange}
                                                                value="1"
                                                                name="live"
                                                            />
                                                        }
                                                        label={
                                                            <span>
                                                                {formData.live === 1
                                                                    ? `${formData.type === "Optional Bundle" ? 'Bundle' : 'Kit'} is active during the validity period`
                                                                    : `${formData.type === "Optional Bundle" ? 'Bundle' : 'Kit'} is in-active and cannot be quoted / ordered`}
                                                            </span>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3} pb={2}>
                                            <Typography variant="h6">
                                                {formData.type === 'Optional Bundle' ? 'Bundle' : 'Kit'} Image
                                            </Typography>
                                            <Box mt={-3}>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item>
                                                        <Box mt={2}>
                                                            <Avatar
                                                                alt="Bundle Image"
                                                                variant="square"
                                                                src={
                                                                    formData.newImg
                                                                        ? URL.createObjectURL(formData.newImg)
                                                                        : !_.isEmpty(formData.img)
                                                                        ? `${CLOUDFRONT_URL}${formData.img}`
                                                                        : Logo
                                                                }
                                                                style={{ height: 60, width: 60, margin: 0 }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <DragFileInput
                                                            name="newImg"
                                                            file={formData.newImg}
                                                            errorText={formErrors && formErrors["generic"]}
                                                            onChange={this.handleFileChange}
                                                            emptyText="No image selected"
                                                            small
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3} pb={2}>
                                            <Typography variant="h6" gutterBottom>
                                                {formData.type === 'Optional Bundle' ? 'Bundle' : 'Kit'} Status
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        color="primary"
                                                        checked={formData.live}
                                                        onChange={this.handleCheckedChange}
                                                        value="1"
                                                        name="live"
                                                    />
                                                }
                                                label={
                                                    <span>
                                                        {formData.live === 1
                                                            ? `${formData.type === "Optional Bundle" ? 'Bundle' : 'Kit'} is active and can be quoted / ordered`
                                                            : `${formData.type === "Optional Bundle" ? 'Bundle' : 'Kit'} is in-active and cannot be quoted / ordered`}
                                                    </span>
                                                }
                                            />
                                        </Box>
                                    </Paper>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3} pb={2.5}>
                                            <Typography variant="h6" gutterBottom>
                                                Financial
                                            </Typography>
                                            {(formData.type === 'Fixed Kit' && (
                                                <Grid container>
                                                    <Grid item xs={5} className="pr-1" align="right">
                                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                                            Buy Price:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {currencyFormat.format(financial.minCost)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} className="pr-1" align="right">
                                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                                            Trade Price:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {currencyFormat.format(financial.maxPrice)} 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} className="pr-1" align="right">
                                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                                            Kit Price:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {formData.fp !== '' ? `${currencyFormat.format(formData.fp)} (Saving ${currencyFormat.format(parseFloat(financial.maxPrice) - parseFloat(formData.fp))})` : 'TBC'}
                                                        </Typography>
                                                    </Grid>                                                    
                                                    <Grid item xs={5} className="pr-1" align="right">
                                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                                            Margin:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {formData.fp !== '' ? `${financial.minMargin}%` : 'TBC'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )) || (
                                                <Grid container spacing={1}>
                                                    <Grid item xs={5} align="right">
                                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                                            Buy Price:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {currencyFormat.format(financial.minCost)} -{" "}
                                                            {currencyFormat.format(financial.maxCost)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} align="right">
                                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                                            Trade Price:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {currencyFormat.format(financial.minPrice)} -{" "}
                                                            {currencyFormat.format(financial.maxPrice)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} align="right">
                                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                                            Margin:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {financial.minMargin}% - {financial.maxMargin}%
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        disabled={disabled}
                                        fullWidth
                                        className="btn btn-error"
                                        size="large"
                                        variant="contained"
                                        onClick={() =>
                                            this.props.deployConfirmation(
                                                `Are you sure you want to ${
                                                    formData.id === 0 ? "create" : "update"
                                                } this ${formData.type === 'Optional Bundle' ? `bundle` : `kit`}?`,
                                                this.handleSubmit
                                            )
                                        }
                                    >
                                        <FAIcon disabled={disabled} icon="check" size={13.5} buttonPrimary />
                                        {formData.id === 0 ? `Create ${formData.type === 'Optional Bundle' ? 'Bundle' : 'Kit'}` : `Update ${formData.type === 'Optional Bundle' ? 'Bundle' : 'Kit'}`}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        className="btn"
                                        size="large"
                                        variant="contained"
                                        onClick={this.handleCategoryAdd}
                                    >
                                        <FAIcon icon="plus-circle" size={13.5} button />
                                        Add {formData.type === 'Optional Bundle' ? 'Category' : 'Product'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs style={{ marginLeft: 450 }}>
                        {(!_.isEmpty(formData.categories) && (
                            <DragDropContext onDragEnd={this.handleReOrderCategories}>
                                <Droppable droppableId="droppable" direction="vertical" type="CATEGORIES">
                                    {(provided, snapshot) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            ref={provided.innerRef}
                                            style={getDragContainerStyle(snapshot.isDraggingOver)}
                                            {...provided.droppableProps}
                                        >
                                            {_.map(formData.categories, (c, idx) => (
                                                <Draggable key={`c-${idx}`} draggableId={`c-${idx}`} index={idx}>
                                                    {(provided, snapshot) => (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            style={getDragItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            {(formData.type === 'Optional Bundle' && (
                                                                <Paper>
                                                                    <Box p={3}>
                                                                        <Grid container justify="space-between">
                                                                            <Grid item>
                                                                                <Grid container spacing={2} alignItems="center">
                                                                                    <Grid item {...provided.dragHandleProps}>
                                                                                        <Tooltip title="Move Category">
                                                                                            <Box pt={0.5}>
                                                                                                <FAIcon
                                                                                                    icon="grid-2"
                                                                                                    size={25}
                                                                                                    type="thin"
                                                                                                />
                                                                                            </Box>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Box
                                                                                            width={400}
                                                                                            mt={
                                                                                                c.text !== "" &&
                                                                                                _.some(
                                                                                                    formData.categories,
                                                                                                    (el, elIdx) =>
                                                                                                        elIdx !== idx &&
                                                                                                        el.text === c.text
                                                                                                )
                                                                                                    ? 4.375
                                                                                                    : undefined
                                                                                            }
                                                                                        >
                                                                                            <TextField
                                                                                                value={c.text}
                                                                                                helperText={
                                                                                                    c.text !== "" &&
                                                                                                    _.some(
                                                                                                        formData.categories,
                                                                                                        (el, elIdx) =>
                                                                                                            elIdx !== idx &&
                                                                                                            el.text === c.text
                                                                                                    )
                                                                                                        ? `Please enter a unique name for this category`
                                                                                                        : undefined
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    this.handleCategoryChange(
                                                                                                        idx,
                                                                                                        "text",
                                                                                                        e?.target?.value ?? ""
                                                                                                    )
                                                                                                }
                                                                                                margin="none"
                                                                                                variant="filled"
                                                                                                placeholder="Name"
                                                                                                fullWidth
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Box pt={0.5}>
                                                                                            <FAIcon
                                                                                                icon="xmark"
                                                                                                size={30}
                                                                                                type="thin"
                                                                                                noMargin
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Box width={85}>
                                                                                            <TextField
                                                                                                value={c.qty}
                                                                                                error={
                                                                                                    formErrors &&
                                                                                                    formErrors[
                                                                                                        `categories|${idx}|qty`
                                                                                                    ] &&
                                                                                                    true
                                                                                                }
                                                                                                helperText={
                                                                                                    formErrors &&
                                                                                                    formErrors[
                                                                                                        `categories|${idx}|qty`
                                                                                                    ]
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    this.handleCategoryChange(
                                                                                                        idx,
                                                                                                        "qty",
                                                                                                        e?.target?.value ?? ""
                                                                                                    )
                                                                                                }
                                                                                                margin="none"
                                                                                                variant="filled"
                                                                                                placeholder="Quantity"
                                                                                                // type="number"
                                                                                                fullWidth
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container spacing={2} alignItems="center">
                                                                                    <Grid item>
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Switch
                                                                                                    color="primary"
                                                                                                    checked={c.req}
                                                                                                    onChange={() =>
                                                                                                        this.handleCategoryChange(
                                                                                                            idx,
                                                                                                            "req",
                                                                                                            c.req === 1 ? 0 : 1
                                                                                                        )
                                                                                                    }
                                                                                                    value="1"
                                                                                                    name="req"
                                                                                                />
                                                                                            }
                                                                                            label={
                                                                                                <span className="fw-400">
                                                                                                    {c.req === 1
                                                                                                        ? "Required"
                                                                                                        : "Optional"}
                                                                                                </span>
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Tooltip title="Delete Category">
                                                                                            <IconButton
                                                                                                onClick={() =>
                                                                                                    this.props.deployConfirmation(
                                                                                                        `Are you sure you want to delete this category?`,
                                                                                                        () =>
                                                                                                            this.handleCategoryDelete(
                                                                                                                idx
                                                                                                            )
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <FAIcon
                                                                                                    icon="trash-alt"
                                                                                                    size={20}
                                                                                                    type="thin"
                                                                                                    noMargin
                                                                                                    button
                                                                                                />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box pl={3} pr={3}>
                                                                        <Divider />
                                                                    </Box>
                                                                    <Box p={3}>
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12} lg={6} xl={7}>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    className="fw-400"
                                                                                    paragraph
                                                                                >
                                                                                    Available Products
                                                                                </Typography>
                                                                                {(!_.isEmpty(c.products) && (
                                                                                    <Box zIndex={9999}>
                                                                                        <DragDropContext
                                                                                            onDragEnd={(res) =>
                                                                                                this.handleReOrderCategoryProducts(
                                                                                                    idx,
                                                                                                    res
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Droppable
                                                                                                droppableId={`droppable-${idx}`}
                                                                                                direction="vertical"
                                                                                                type="PRODUCTS"
                                                                                            >
                                                                                                {(provided, snapshot) => (
                                                                                                    <Grid
                                                                                                        container
                                                                                                        spacing={3}
                                                                                                        ref={provided.innerRef}
                                                                                                        style={getDragContainerStyle(
                                                                                                            snapshot.isDraggingOver
                                                                                                        )}
                                                                                                        {...provided.droppableProps}
                                                                                                    >
                                                                                                        {_.map(
                                                                                                            c.products,
                                                                                                            (p, pidx) => (
                                                                                                                <Draggable
                                                                                                                    key={`c-${idx}-p-${pidx}`}
                                                                                                                    draggableId={`c-${idx}-p-${pidx}`}
                                                                                                                    index={pidx}
                                                                                                                >
                                                                                                                    {(
                                                                                                                        provided,
                                                                                                                        snapshot
                                                                                                                    ) => (
                                                                                                                        <Grid
                                                                                                                            item
                                                                                                                            xs={
                                                                                                                                12
                                                                                                                            }
                                                                                                                            ref={
                                                                                                                                provided.innerRef
                                                                                                                            }
                                                                                                                            {...provided.draggableProps}
                                                                                                                            {...provided.dragHandleProps}
                                                                                                                            style={getDragItemStyle(
                                                                                                                                snapshot.isDragging,
                                                                                                                                provided
                                                                                                                                    .draggableProps
                                                                                                                                    .style
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            <Box
                                                                                                                                className="content-light-grey"
                                                                                                                                p={
                                                                                                                                    2
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Grid
                                                                                                                                    container
                                                                                                                                    spacing={
                                                                                                                                        3
                                                                                                                                    }
                                                                                                                                    alignItems="center"
                                                                                                                                >
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                    >
                                                                                                                                        <Box border="1px solid #e7e7e7">
                                                                                                                                            <Avatar
                                                                                                                                                variant="square"
                                                                                                                                                src={
                                                                                                                                                    p.img
                                                                                                                                                        ? `${CLOUDFRONT_URL}${p.img}`
                                                                                                                                                        : Logo
                                                                                                                                                }
                                                                                                                                                style={{
                                                                                                                                                    overflow:
                                                                                                                                                        "visible",
                                                                                                                                                    width: 50,
                                                                                                                                                    height: 50,
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        </Box>
                                                                                                                                    </Grid>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs
                                                                                                                                    >
                                                                                                                                        <Typography
                                                                                                                                            variant="body2"
                                                                                                                                            className="fw-400"
                                                                                                                                        >
                                                                                                                                            {
                                                                                                                                                p.code
                                                                                                                                            }
                                                                                                                                        </Typography>
                                                                                                                                        <Typography
                                                                                                                                            variant="caption"
                                                                                                                                            component="div"
                                                                                                                                        >
                                                                                                                                            {
                                                                                                                                                p.name
                                                                                                                                            }
                                                                                                                                        </Typography>
                                                                                                                                    </Grid>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                    >
                                                                                                                                        <Tooltip title="Delete Product">
                                                                                                                                            <IconButton
                                                                                                                                                onClick={() =>
                                                                                                                                                    this.handleCategoryDeleteProduct(
                                                                                                                                                        idx,
                                                                                                                                                        pidx
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <FAIcon
                                                                                                                                                    icon="times-circle"
                                                                                                                                                    size={
                                                                                                                                                        25
                                                                                                                                                    }
                                                                                                                                                    type="thin"
                                                                                                                                                    noMargin
                                                                                                                                                    button
                                                                                                                                                />
                                                                                                                                            </IconButton>
                                                                                                                                        </Tooltip>
                                                                                                                                    </Grid>
                                                                                                                                </Grid>
                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                    )}
                                                                                                                </Draggable>
                                                                                                            )
                                                                                                        )}
                                                                                                        {provided.placeholder}
                                                                                                    </Grid>
                                                                                                )}
                                                                                            </Droppable>
                                                                                        </DragDropContext>
                                                                                    </Box>
                                                                                )) || (
                                                                                    <IconText
                                                                                        icon="exclamation-circle"
                                                                                        iconClass="textError"
                                                                                    >
                                                                                        No products have been added to this
                                                                                        category yet
                                                                                    </IconText>
                                                                                )}
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6} xl={5}>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    className="fw-400"
                                                                                    paragraph
                                                                                >
                                                                                    Add Product
                                                                                </Typography>
                                                                                <Box pt={0.7}>
                                                                                    <ProductSearchSelector
                                                                                        key={c.uuid}
                                                                                        label=""
                                                                                        variant="filled"
                                                                                        handleAction={(v) =>
                                                                                            this.handleCategoryAddProduct(
                                                                                                idx,
                                                                                                v
                                                                                            )
                                                                                        }
                                                                                        exclude={_.map(c.products, (p) => p.id)}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Paper>
                                                            )) || (
                                                                <Paper>
                                                                    <Box p={3}>
                                                                        <Grid container justify="space-between">
                                                                            <Grid item>
                                                                                <Grid container spacing={2} alignItems="center">
                                                                                    <Grid item {...provided.dragHandleProps}>
                                                                                        <Tooltip title="Move Product">
                                                                                            <Box pt={0.5}>
                                                                                                <FAIcon
                                                                                                    icon="grid-2"
                                                                                                    size={25}
                                                                                                    type="thin"
                                                                                                />
                                                                                            </Box>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Box
                                                                                            width={600}
                                                                                            mt={0.5}
                                                                                        >
                                                                                            {(!_.isEmpty(c.products) && (
                                                                                                _.map(c.products, (p, pidx) => (
                                                                                                    <Grid
                                                                                                        container
                                                                                                        spacing={
                                                                                                            3
                                                                                                        }
                                                                                                        key={pidx}
                                                                                                        alignItems="center"
                                                                                                    >
                                                                                                        <Grid
                                                                                                            item
                                                                                                        >
                                                                                                            <Box border="1px solid #e7e7e7">
                                                                                                                <Avatar
                                                                                                                    variant="square"
                                                                                                                    src={
                                                                                                                        p.img
                                                                                                                            ? `${CLOUDFRONT_URL}${p.img}`
                                                                                                                            : Logo
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        overflow:
                                                                                                                            "visible",
                                                                                                                        width: 50,
                                                                                                                        height: 50,
                                                                                                                    }}
                                                                                                                />
                                                                                                            </Box>
                                                                                                        </Grid>
                                                                                                        <Grid
                                                                                                            item
                                                                                                            xs
                                                                                                        >
                                                                                                            <Typography
                                                                                                                variant="body2"
                                                                                                                className="fw-400"
                                                                                                            >
                                                                                                                {
                                                                                                                    p.code
                                                                                                                }
                                                                                                            </Typography>
                                                                                                            <Typography
                                                                                                                variant="caption"
                                                                                                                component="div"
                                                                                                            >
                                                                                                                {
                                                                                                                    p.name
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                ))
                                                                                            )) || (
                                                                                                <ProductSearchSelector
                                                                                                    key={c.uuid}
                                                                                                    label=""
                                                                                                    variant="filled"
                                                                                                    handleAction={(v) =>
                                                                                                        this.handleCategoryAddProduct(
                                                                                                            idx,
                                                                                                            v
                                                                                                        )
                                                                                                    }
                                                                                                    exclude={_.map(c.products, (p) => p.id)}
                                                                                                />
                                                                                            )}
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Box pt={0.5}>
                                                                                            <FAIcon
                                                                                                icon="xmark"
                                                                                                size={30}
                                                                                                type="thin"
                                                                                                noMargin
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Box width={85}>
                                                                                            <TextField
                                                                                                value={c.qty}
                                                                                                error={
                                                                                                    formErrors &&
                                                                                                    formErrors[
                                                                                                        `categories|${idx}|qty`
                                                                                                    ] &&
                                                                                                    true
                                                                                                }
                                                                                                helperText={
                                                                                                    formErrors &&
                                                                                                    formErrors[
                                                                                                        `categories|${idx}|qty`
                                                                                                    ]
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    this.handleCategoryChange(
                                                                                                        idx,
                                                                                                        "qty",
                                                                                                        e?.target?.value ?? ""
                                                                                                    )
                                                                                                }
                                                                                                margin="none"
                                                                                                variant="filled"
                                                                                                placeholder="Quantity"
                                                                                                // type="number"
                                                                                                fullWidth
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container spacing={2} alignItems="center">
                                                                                    {!_.isEmpty(c.products) && (
                                                                                        <Grid item>
                                                                                            <Tooltip title="Change Product">
                                                                                                <IconButton
                                                                                                    onClick={() =>
                                                                                                        this.handleCategoryDeleteProduct(
                                                                                                            idx,
                                                                                                            0
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <FAIcon
                                                                                                        icon="times-circle"
                                                                                                        size={
                                                                                                            25
                                                                                                        }
                                                                                                        type="thin"
                                                                                                        noMargin
                                                                                                        button
                                                                                                    />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Grid>
                                                                                    )}
                                                                                    <Grid item>
                                                                                        <Tooltip title="Delete Product">
                                                                                            <IconButton
                                                                                                onClick={() =>
                                                                                                    this.props.deployConfirmation(
                                                                                                        `Are you sure you want to delete this product?`,
                                                                                                        () =>
                                                                                                            this.handleCategoryDelete(
                                                                                                                idx
                                                                                                            )
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <FAIcon
                                                                                                    icon="trash-alt"
                                                                                                    size={20}
                                                                                                    type="thin"
                                                                                                    noMargin
                                                                                                    button
                                                                                                />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Paper>
                                                            )}
                                                            {idx + 1 !== _.size(formData.categories) && formData.type === "Optional Bundle" && (
                                                                <Box pt={2} textAlign="center">
                                                                    <FAIcon
                                                                        icon="plus"
                                                                        type="thin"
                                                                        color="primary"
                                                                        size={40}
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )) || (
                            <Box textAlign={"center"} pt={"22.5%"}>
                                <Box pb={2.5}>
                                    <FAIcon icon="cart-flatbed-boxes" type="thin" size={100} />
                                </Box>
                                <Typography variant="h4" className="fw-300">
                                    Please{" "}
                                    <span
                                        onClick={this.handleCategoryAdd}
                                        style={{ borderBottom: "1px solid #000", cursor: "pointer" }}
                                    >
                                        add a {formData.type === 'Optional Bundle' ? 'category' : 'product'}
                                    </span>{" "}
                                    to get started
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            )
        );
    };
}

const mapStateToProps = (state) => ({
    staff: state.notifications.status,
    loggedInStaff: state.staffAuth.staff,
    statePersistence: state.statePersistence,
    ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(closeDialog()),
    deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BundleForm);
