import React from 'react';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar'; 
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import logo from 'Assets/Images/no-img.jpg';
import OrderSummary from 'Components/Sales/Misc/OrderSummary';
import ProductStockChip from 'Components/Common/Chips/ProductStockChip';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import InsightChip from 'Components/Common/Chips/InsightChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import CardDbSiteForm from 'Components/CardDB/Misc/CardDbSiteForm';
import StackTable from 'Components/Common/DataTables/StackTable';
import StatusChip from 'Components/Common/Chips/StatusChip';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { cardOrderStatus, momentFormatDateTime } from 'Functions/MiscFunctions';
import { getFormDataHook } from 'Functions/FormFunctions';
import { CLOUDFRONT_URL } from 'Constants';

const styles = theme => ({
    td: {
        border: 'none',
        padding: 4
    }
});

const initialState = () => ({
    cardDb: {
        formats: [],
        programmers: [],
        types: []
    },
    cardData: {},
    formData: {},
    formErrors: [],
    formDefault: {
        opId: '',
        opdId: '',
        lineNo: 0,
        email: '',
        cdbSite: '',
        cdbIntRangeStart: '',
        cdbIntRangeEnd: '',
        cdbExtRangeStart: '',
        cdbExtRangeEnd: '',
        cdbComments :'',
        cdbOrderNo: '',
        cdbType: '',
        cdbFormat: '',
        cdbProgrammer: ''
    },
    isLoading: true,
    key: uuidv4(),
    sites: []
})

class AwaitingAuthorisation extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState();
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {

        const {
            order
        } = this.props;

        let {
            cardData,
            formData,
            isLoading
        } = this.state;

        if(!isLoading) {
            this.setState({
                isLoading: true
            })
        }

        Promise.all([
            API.get('/card-orders/formats'),
            API.get('/card-orders/programmers'),
            API.get('/card-orders/types'),
            API.get(`/customers/${order?.order_customer_id}`)
        ])
        .then(([
            cardFormats,
            cardProgrammers,
            cardTypes,
            customer
        ]) =>  {

            let formats                 = [],
                programmers             = [],
                sites                   = [],
                types                   = [],
                isLoading               = false;

            formats = _.map(cardFormats?.data, el => {
                return _.assign({
                    value: el.format_id,
                    label: el.format_name,
                });
            });

            programmers = _.map(cardProgrammers?.data, el => {
                return _.assign({
                    value: el.programmer_id,
                    label: el.programmer_name,
                });
            });

            types = _.map(cardTypes?.data, el => {
                return _.assign({
                    value: el.type_id,
                    label: el.type_name,
                });
            });

            sites = _.map(customer?.data?.sites, el => (
                _.assign({
                    label: `${el.site_code} - ${el.site_description}`,
                    value: el.site_id,
                    disabled: el.site_active === 0,
                    latestOrder: el.latest_order
                })
            ));

            if(order.order_co_sent === 0) {
                let i = 1;
                _.each(order.products, op => {
                    if(op?.cd) {
                        cardData = {
                            ...cardData,
                            [op.id]: op
                        }
                        formData = {
                            ...formData,
                            [op.id]: {
                                ...initialState().formDefault,
                                opId: op.id,
                                lineNo: i++,
                                email: !_.isEmpty(order.order_email) ? order.order_email : "",
                                cdbSite: op?.cd?.siteId,
                                cdbIntRangeStart: op?.cd?.intStart,
                                cdbIntRangeEnd: op?.cd?.intEnd,
                                cdbExtRangeStart: op?.cd?.extStart,
                                cdbExtRangeEnd: op?.cd?.extEnd,
                                cdbComments: op?.cd?.comments,
                                cdbOrderNo: op?.cd?.orderRef,
                                cdbType: op?.cd?.typeId,
                                cdbFormat: op?.cd?.formatId,
                                cdbProgrammer: op?.cd?.programmerId,
                            }
                        }
                    }
                })
            }

            const initialFormData = JSON.stringify(formData);

            this.setState({
                cardDb: {
                    formats,
                    programmers,
                    types
                },
                cardData,
                formData,
                initialFormData,
                isLoading,
                sites
            })
        });

    }

    handleDeployNotes = (name, notes) => {
        this.props.deployDialog(
            <ViewNotes hideDeleteButton notes={notes} inline />, false, `${name}`, "standard", "sm"
        )
    }

    handleChange = (opId, fieldName, value) => {

        let {
            formData 
        } = this.state;

        formData = {
            ...formData,
            [opId]: {
                ...formData[opId],
                [fieldName]: value
            }
        }

        this.setState({
            formData
        })
    }

    handleFileChange = (name, file, opId) => {

        if(opId > 0) {

            this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to upload this file?</Typography><Typography variant="body2">This will mark this card order as authorised</Typography></>,
            () => {

                this.setState({
                    isLoading: true
                }, () => {

                    let fn = file.name.split("."),
                        title = '';

                    for(let i = 0; i < (fn.length - 1); i++) {
                        title += fn[i]
                    }

                    let formData = getFormDataHook({
                        opId,
                        title,
                        file
                    });

                    API.post(`/sales/${this.props.id}/authorisation/file`, formData)
                    .then(res => {
                        if(res?.data) {
                            if(res.data.errors) {
                                this.props.deploySnackBar("error", "There was an issue uploading the file, please try again")
                            } else {
                                this.props.deploySnackBar("success", "You have successfully uploaded the customers authorisation for this card order");
                                if(res?.data?.complete === 1) {
                                    this.props.deploySnackBar("success", "This order has now been fully authorised and has progressed to Processing");
                                }
                                this.props.callback(2);
                            }
                        }
                    })
                });
            },
            () => {
                this.setState({
                    key: uuidv4()
                })
            })

        }

    }

    handleAddSite = () => this.props.deployDialog(
        <CardDbSiteForm
            customer={this.props.order?.order_customer_id}
            callback={() => { this.props.closeDialog(); this.loadComponentData(); }}
            site={false}
        />, true, "", "standard","xs");

    handleChangeOrder = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/sales/${this.props.id}/authorisation/modify`)
            .then(result => {
                if(result.data) {
                    this.props.deploySnackBar("success", "You can now modify this card order to re-send to the customer");
                    this.props.callback(2);
                }
            })
        })
    }

    handleSubmit = () => {

        let formData = {
            cardOrders: this.state.formData
        }

        this.setState({
            isLoading: true
        }, () => {
            API.post(`/sales/${this.props.id}/authorisation`, formData)
            .then(result => {
                if(result.data) {
                    if(result.data.errors && result.data.errors.length > 0) {
                        this.setState({
                            ...this.state,
                            formErrors: formatValidationErrors(result.data.errors),
                            isLoading: false
                        })
                    } else {
                        this.props.deploySnackBar("success", "The card order has been raised for this sales order");
                        this.props.callback(2);
                    }
                }
            })
        })
    }

    render() {        
        const { classes, currentTab, deployConfirmation, order, staff } = this.props;
        const { cardDb, cardData, formData, initialFormData, isLoading, key, sites } = this.state;
        let disabled = false;
        _.each(formData, op => {
            if(!op.cdbProgrammer || op.cdbProgrammer === "" || !op.cdbType || op.cdbType === "" || !op.cdbFormat || op.cdbFormat === "" || op.cdbExtRangeEnd === "" || op.cdbExtRangeStart === "" || op.cdbIntRangeEnd === "" || op.cdbIntRangeStart === "" || op.cdbSite === "") {
                disabled = true;
                return;
            }
        });
        return (
            <>
                <Grid container spacing={3}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        (order.order_co_sent && (
                            <>
                                <Grid item xs={12}>
                                    {(currentTab === 0 && (
                                        <Grid item xs={12}>
                                            <ViewNotes heading={<Typography variant="h6">Sales Order Notes</Typography>} notes={order.notes} hideDeleteButton />
                                        </Grid>
                                    )) || (currentTab === 1 && (
                                        <OrderSummary order={order} />
                                    )) || (currentTab === 2 && (
                                        <>
                                            <Grid container spacing={3}> 
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <Box p={3}>
                                                            <Grid container spacing={1} alignItems='center'>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6">
                                                                        Awaiting Customer Response
                                                                    </Typography>  
                                                                    <Box pb={1}>
                                                                        <Grid container alignItems='center'>
                                                                            <Grid item>
                                                                                <FAIcon icon="exclamation-triangle" className="textWarning mr-1" size={15} />
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <Typography variant="caption">
                                                                                    This order will progress to processing once all responses are uploaded
                                                                                    {_.some(order.emails, el => el.oe_type === "Authorisation") && (
                                                                                        `, authorisation letters previously sent : ${momentFormatDateTime(_.find(_.orderBy(order.emails, 'oe_id', 'desc'), _el => _el.oe_type === "Authorisation")?.oe_created_datetime ?? null)}`
                                                                                    )}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                {_.map(order.products, (op, idx) => {
                                                                    if(op.card_order) {
                                                                        const   rowData     =   op,
                                                                                notes       =   _.size(rowData?.notes ?? rowData.productData?.notes ?? rowData?.productData?.notes ?? rowData?.product?.notes ?? []),
                                                                                status      =   rowData.status ?? rowData.productData?.status ?? rowData.productData?.product_status ?? rowData.product?.product_status ?? 'Unknown',
                                                                                replacement =   (rowData.productRep > 0 || rowData.productData?.productRep > 0 || rowData.productData?.product_rep_product_id > 0 || rowData.product?.product_rep_product_id > 0),
                                                                                inStock     =   rowData.instock ?? rowData.productData?.instock ?? rowData.productData?.stock_count ?? rowData.product?.stock_count ?? 0,
                                                                                preOrdered  =   rowData.preOrdered ?? rowData.productData?.preOrdered ?? rowData.productData?.stock_on_order_allocated_count ?? rowData.product?.stock_on_order_allocated_count ?? 0,
                                                                                available   =   rowData.available ?? rowData.productData?.available ?? rowData.productData?.stock_available_count ?? rowData.product?.stock_available_count ?? 0,
                                                                                allocated   =   rowData.allocated ?? rowData.productData?.allocated ?? rowData.productData?.stock_allocated_count ?? rowData.product?.stock_allocated_count ?? 0,
                                                                                onOrder     =   rowData.onOrder ?? rowData.productData?.onOrder ?? rowData.productData?.stock_on_order_count ?? rowData.product?.stock_on_order_count ?? 0,
                                                                                chkPr       =   (rowData.chkPr || rowData.productData?.chkPr || rowData.productData?.product_check_price === 1 || rowData.product?.product_check_price === 1),
                                                                                stck        =   (rowData.stkIt || rowData.productData?.stkIt || rowData.productData?.product_stock_item === 1 || rowData.product?.product_stock_item === 1),
                                                                                quantity    =   rowData.quantity,
                                                                                cardOrder   =   rowData.productData?.cDb === 1 || rowData.cardDb === 1 || rowData.productData?.cardDb === 1;

                                                                        return (
                                                                            <Grid item xs={12} key={idx}>
                                                                                <Box className="content-light-grey" p={3} mb={2}>
                                                                                    <Box pb={3}>
                                                                                        <Grid container spacing={1} justify="space-between" alignItems='center'>
                                                                                            <Grid item>
                                                                                                <Typography variant="h6">
                                                                                                    Card Order - CARD00{op?.card_order?.co_ref}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <Grid container alignItems='center'>
                                                                                                    <Grid item xs align='right'>
                                                                                                        <Typography variant="caption" className="fw-400 pr-1">
                                                                                                            Order Created By 
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item>
                                                                                                        <StaffChip staff={staff?.[rowData?.card_order?.co_created_by_staff_id] ?? null} />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Box>
                                                                                    <Box className="content-light-white" style={{borderBottom: 0}}>
                                                                                        <Alert severity={op?.card_order?.co_status_id === 1 ? 'warning' : 'success'}>
                                                                                            {op?.card_order?.co_status_id === 1 ? 'This card order has not yet been authorised by the customer' : 'This card order has been authorised by the customer'}
                                                                                        </Alert>
                                                                                    </Box>
                                                                                    <Box className="content-light-white" p={3}>
                                                                                        <Table>
                                                                                            <TableBody>
                                                                                                <TableRow className='pl-1'>
                                                                                                    <TableCell className={classes.td} style={{width: '65%'}}>
                                                                                                        <Grid container spacing={1} alignItems='center' style={{marginTop: -12}}>
                                                                                                            <Grid item className='pr-2'>
                                                                                                                <Avatar src={rowData.image ? `${CLOUDFRONT_URL}${rowData.image}` : logo} style={{overflow:'visible', width: 75, height: 75}} />
                                                                                                            </Grid>
                                                                                                            <Grid item xs>
                                                                                                                <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                                                                    <Grid item>
                                                                                                                        <Typography variant="body2" className="fw-400">
                                                                                                                            {rowData.productCode}
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    <Grid item xs={12}>
                                                                                                                        <Typography variant="caption" component="div">
                                                                                                                            {rowData.productName}
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    {(rowData?.efs === 'Invoiced' || rowData?.efs === 'Despatched') && (
                                                                                                                        <Grid item xs={12}>
                                                                                                                            <Typography variant="caption" component="div">
                                                                                                                                {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                                                                                                                            </Typography>
                                                                                                                        </Grid>
                                                                                                                    )}
                                                                                                                    <Grid item>
                                                                                                                        {((rowData?.efs === 'Invoiced' || rowData?.efs === 'Despatched') && (
                                                                                                                            <InsightChip 
                                                                                                                                color='#ef3340'
                                                                                                                                icon='exclamation-triangle'
                                                                                                                                label={`${rowData.efs} - This line cannot be amended or removed`}
                                                                                                                                marginRight={8}
                                                                                                                                paddingLeft={8}
                                                                                                                                paddingRight={4}
                                                                                                                            />
                                                                                                                        )) || (
                                                                                                                            <Box pt={1}>
                                                                                                                                <ProductStockChip
                                                                                                                                        qty={quantity} 
                                                                                                                                        rep={replacement}
                                                                                                                                        status={status}
                                                                                                                                        stocked={stck}
                                                                                                                                        stockAvailable={available}
                                                                                                                                        stockOnOrderAvailable={(onOrder - preOrdered)}
                                                                                                                                    />
                                                                                                                            </Box>
                                                                                                                        )}
                                                                                                                    </Grid>
                                                                                                                    {cardOrder && (
                                                                                                                        <Grid item>
                                                                                                                            <Box pt={1}>
                                                                                                                                <InsightChip 
                                                                                                                                    background="#ef3340"
                                                                                                                                    color="#fff"
                                                                                                                                    marginRight={8}
                                                                                                                                    paddingLeft={8}
                                                                                                                                    icon="exclamation-triangle"
                                                                                                                                    label={'Card Order Authorisation'}
                                                                                                                                    size={11}
                                                                                                                                />
                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                    )}
                                                                                                                    {chkPr && (
                                                                                                                        <Grid item>
                                                                                                                            <Box pt={1}>
                                                                                                                                <InsightChip 
                                                                                                                                    background="#FF5722"
                                                                                                                                    color="#fff"
                                                                                                                                    marginRight={8}
                                                                                                                                    paddingLeft={8}
                                                                                                                                    icon="exclamation-triangle"
                                                                                                                                    label='Check Price and Availability'
                                                                                                                                />
                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                    )}
                                                                                                                    {notes > 0 && (
                                                                                                                        <Grid item>
                                                                                                                            <Box pt={1}>
                                                                                                                                <InsightChip 
                                                                                                                                    background="#4CAF50"
                                                                                                                                    color="#fff"
                                                                                                                                    marginRight={8}
                                                                                                                                    paddingLeft={8}
                                                                                                                                    icon="comment-alt-lines"
                                                                                                                                    label={notes}
                                                                                                                                    onClick={() => this.handleDeployNotes(rowData.productCode, rowData.notes ?? rowData.productData?.notes ?? [])}
                                                                                                                                    size={11}
                                                                                                                                    tooltip='View Notes'
                                                                                                                                />
                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                    )}
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </TableCell>
                                                                                                    <TableCell className={classes.td} style={{width: '35%'}}>
                                                                                                        <DragFileInput
                                                                                                            key={key}
                                                                                                            id="document"
                                                                                                            name="document"
                                                                                                            label={`Upload Customer Authorisation *`}
                                                                                                            file={formData.document}
                                                                                                            onChange={(drop, name, file) => this.handleFileChange(name, file, op?.id ?? 0)}
                                                                                                            emptyText='No file selected'
                                                                                                            primary="pdf"
                                                                                                            icon="file-pdf"
                                                                                                            small
                                                                                                            border={'#ef3340'}
                                                                                                        />
                                                                                                    </TableCell>
                                                                                                </TableRow>  
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                        <Box pt={3} pb={2}>
                                                                                            <Divider />
                                                                                        </Box>
                                                                                        <Grid container spacing={3}>
                                                                                            <Grid item xs={5}>
                                                                                                <StackTable
                                                                                                    elevation={0}
                                                                                                    padding={0}
                                                                                                    heading="Card Order Details"
                                                                                                    rows={[ 
                                                                                                        {
                                                                                                            heading: 'Status',
                                                                                                            value: <StatusChip status={cardOrderStatus(op?.card_order?.co_status_id) ?? "Unknown"} />
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Customer Order No",
                                                                                                            value: op?.card_order?.co_cust_ref ?? false
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Customer Site",
                                                                                                            value: `${op.card_order?.site?.site_code ?? ""} - ${op.card_order?.site?.site_description ?? ""}`
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Card Format",
                                                                                                            value: op?.card_order?.format?.format_name ?? false
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Card Type",
                                                                                                            value: op?.card_order?.type?.type_name ?? false
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Programmer Type",
                                                                                                            value: op?.card_order?.programmer?.programmer_name ?? false
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Quantity",
                                                                                                            value: rowData.quantity
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Internal Range Start",
                                                                                                            value: op?.card_order?.co_internal_range_start ?? false
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Internal Range End",
                                                                                                            value: op?.card_order?.co_internal_range_end ?? false
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "External Range Start",
                                                                                                            value: op?.card_order?.co_external_range_start ?? false
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "External Range End",
                                                                                                            value: op?.card_order?.co_external_range_end ?? false
                                                                                                        },
                                                                                                        {
                                                                                                            heading: "Card Order Notes",
                                                                                                            value: op?.card_order?.co_notes ?? false
                                                                                                        }
                                                                                                    ]}
                                                                                                /> 
                                                                                            </Grid>
                                                                                            <Grid item xs={7}>
                                                                                                <Grid container spacing={3}>
                                                                                                    <Grid item xs={12}>
                                                                                                        <StackTable
                                                                                                            elevation={0}
                                                                                                            padding={0}
                                                                                                            heading="Card Order Files"
                                                                                                            rows={_.map(op?.card_order?.files, f => (
                                                                                                                _.assign({
                                                                                                                    heading: `${f.file_type === 'ORDER_AUTH' ? 'Authorisation Letter' : 'Customer Response'}`,
                                                                                                                    value: (
                                                                                                                        <Grid container spacing={1} alignItems='center'>
                                                                                                                            <Grid item>
                                                                                                                                <FAIcon icon="file-pdf" className="textError" size={15} button noMargin />
                                                                                                                            </Grid>
                                                                                                                            <Grid item xs>
                                                                                                                                <Typography variant="body2">
                                                                                                                                    <Link className="textDefault" href={`${CLOUDFRONT_URL}${f.file_key}`} target='_blank'>{f.file_name}</Link>
                                                                                                                                </Typography>
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                    )
                                                                                                                })
                                                                                                            ))}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12}>
                                                                                                        <StackTable
                                                                                                            elevation={0}
                                                                                                            padding={0}
                                                                                                            heading="Card Order Audit"
                                                                                                            rows={_.map(op?.card_order?.audits, a => (
                                                                                                                _.assign({
                                                                                                                    heading: `${momentFormatDateTime(a?.audit_created_datetime)}`,
                                                                                                                    value: (<Grid container alignItems='center'><Grid item><Typography variant="caption" className="fw-400 mr-1">{cardOrderStatus(a?.audit_status_id)}</Typography></Grid><Grid item xs><StaffChip staff={staff[a?.audit_created_by_staff_id ?? 0]} /></Grid></Grid>),
                                                                                                                })
                                                                                                            ))}
                                                                                                        /> 
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                })}
                                                                <Grid item xs={12} align='right'>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to modify the card order?</Typography><Typography variant="body2">This will require re-authorisation by the customer</Typography></>, this.handleChangeOrder)}
                                                                        variant="text"
                                                                    >
                                                                        <FAIcon icon="exclamation-triangle" className="textError mr-1" size={15} button />
                                                                        Modify Card Order
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))}
                                </Grid> 
                            </>
                        )) || (
                            <>
                                <Grid item xs={12}>
                                    {(currentTab === 0 && (
                                        <Grid item xs={12}>
                                            <ViewNotes heading={<Typography variant="h6">Sales Order Notes</Typography>} notes={order.notes} hideDeleteButton />
                                        </Grid>
                                    )) || (currentTab === 1 && (
                                        <OrderSummary order={order} />
                                    )) || (currentTab === 2 && (
                                        <>
                                            <Grid container spacing={3}> 
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <Box p={3}>
                                                            <Grid container spacing={1} alignItems='center'>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6">
                                                                        Card Order Check / Creation
                                                                    </Typography>  
                                                                    <Box pb={1}>
                                                                        <Grid container alignItems='center'>
                                                                            <Grid item>
                                                                                <FAIcon icon="exclamation-triangle" className="textWarning mr-1" size={15} />
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <Typography variant="caption">
                                                                                    This sales order requires card authorisation for {_.size(formData)} line{_.size(formData) > 1 ? 's' : ''}
                                                                                    {_.some(order.emails, el => el.oe_type === "Authorisation") && (
                                                                                        `, authorisation letter previously sent : ${momentFormatDateTime(_.find(_.orderBy(order.emails, 'oe_id', 'desc'), _el => _el.oe_type === "Authorisation")?.oe_created_datetime ?? null)}`
                                                                                    )}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                {_.map(formData, (op, idx) => {
                                                                    const   rowData     =   cardData?.[op?.opId],
                                                                            notes       =   _.size(rowData?.notes ?? rowData.productData?.notes ?? rowData?.productData?.notes ?? rowData?.product?.notes ?? []),
                                                                            status      =   rowData.status ?? rowData.productData?.status ?? rowData.productData?.product_status ?? rowData.product?.product_status ?? 'Unknown',
                                                                            replacement =   (rowData.productRep > 0 || rowData.productData?.productRep > 0 || rowData.productData?.product_rep_product_id > 0 || rowData.product?.product_rep_product_id > 0),
                                                                            inStock     =   rowData.instock ?? rowData.productData?.instock ?? rowData.productData?.stock_count ?? rowData.product?.stock_count ?? 0,
                                                                            preOrdered  =   rowData.preOrdered ?? rowData.productData?.preOrdered ?? rowData.productData?.stock_on_order_allocated_count ?? rowData.product?.stock_on_order_allocated_count ?? 0,
                                                                            available   =   rowData.available ?? rowData.productData?.available ?? rowData.productData?.stock_available_count ?? rowData.product?.stock_available_count ?? 0,
                                                                            allocated   =   rowData.allocated ?? rowData.productData?.allocated ?? rowData.productData?.stock_allocated_count ?? rowData.product?.stock_allocated_count ?? 0,
                                                                            onOrder     =   rowData.onOrder ?? rowData.productData?.onOrder ?? rowData.productData?.stock_on_order_count ?? rowData.product?.stock_on_order_count ?? 0,
                                                                            chkPr       =   (rowData.chkPr || rowData.productData?.chkPr || rowData.productData?.product_check_price === 1 || rowData.product?.product_check_price === 1),
                                                                            stck        =   (rowData.stkIt || rowData.productData?.stkIt || rowData.productData?.product_stock_item === 1 || rowData.product?.product_stock_item === 1),
                                                                            quantity    =   rowData.quantity,
                                                                            cardOrder   =   rowData.productData?.cDb === 1 || rowData.cardDb === 1 || rowData.productData?.cardDb === 1;

                                                                    return (
                                                                        <Grid item xs={12} key={idx}>
                                                                            <Box className="content-light-grey" p={3} mb={2}>
                                                                                <Box pb={3}>
                                                                                    <Grid container spacing={1} justify="space-between" alignItems='center'>
                                                                                        <Grid item>
                                                                                            <Typography variant="h6">
                                                                                                Card Order {op?.lineNo} / {_.size(formData)}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Grid container alignItems='center'>
                                                                                                <Grid item xs align='right'>
                                                                                                    <Typography variant="caption" className="fw-400 pr-1">
                                                                                                        Data Prefilled By 
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item>
                                                                                                    <StaffChip staff={staff?.[rowData?.cd?.staffId] ?? null} />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                                <Box className="content-light-white" p={3}>
                                                                                    <Table>
                                                                                        <TableBody>
                                                                                            <TableRow className='pl-1'>
                                                                                                <TableCell className={classes.td} style={{width: '65%'}}>
                                                                                                    <Grid container spacing={1} alignItems='center' style={{marginTop: -12}}>
                                                                                                        <Grid item className='pr-2'>
                                                                                                            <Avatar src={rowData.image ? `${CLOUDFRONT_URL}${rowData.image}` : logo} style={{overflow:'visible', width: 75, height: 75}} />
                                                                                                        </Grid>
                                                                                                        <Grid item xs>
                                                                                                            <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                                                                <Grid item>
                                                                                                                    <Typography variant="body2" className="fw-400">
                                                                                                                        {rowData.productCode}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography variant="caption" component="div">
                                                                                                                        {rowData.productName}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                {(rowData?.efs === 'Invoiced' || rowData?.efs === 'Despatched') && (
                                                                                                                    <Grid item xs={12}>
                                                                                                                        <Typography variant="caption" component="div">
                                                                                                                            {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                )}
                                                                                                                <Grid item>
                                                                                                                    {((rowData?.efs === 'Invoiced' || rowData?.efs === 'Despatched') && (
                                                                                                                        <InsightChip 
                                                                                                                            color='#ef3340'
                                                                                                                            icon='exclamation-triangle'
                                                                                                                            label={`${rowData.efs} - This line cannot be amended or removed`}
                                                                                                                            marginRight={8}
                                                                                                                            paddingLeft={8}
                                                                                                                            paddingRight={4}
                                                                                                                        />
                                                                                                                    )) || (
                                                                                                                        <Box pt={1}>
                                                                                                                            <ProductStockChip
                                                                                                                                    qty={quantity} 
                                                                                                                                    rep={replacement}
                                                                                                                                    status={status}
                                                                                                                                    stocked={stck}
                                                                                                                                    stockAvailable={available}
                                                                                                                                    stockOnOrderAvailable={(onOrder - preOrdered)}
                                                                                                                                />
                                                                                                                        </Box>
                                                                                                                    )}
                                                                                                                </Grid>
                                                                                                                {cardOrder && (
                                                                                                                    <Grid item>
                                                                                                                        <Box pt={1}>
                                                                                                                            <InsightChip 
                                                                                                                                background="#ef3340"
                                                                                                                                color="#fff"
                                                                                                                                marginRight={8}
                                                                                                                                paddingLeft={8}
                                                                                                                                icon="exclamation-triangle"
                                                                                                                                label={'Card Order Authorisation'}
                                                                                                                                size={11}
                                                                                                                            />
                                                                                                                        </Box>
                                                                                                                    </Grid>
                                                                                                                )}
                                                                                                                {chkPr && (
                                                                                                                    <Grid item>
                                                                                                                        <Box pt={1}>
                                                                                                                            <InsightChip 
                                                                                                                                background="#FF5722"
                                                                                                                                color="#fff"
                                                                                                                                marginRight={8}
                                                                                                                                paddingLeft={8}
                                                                                                                                icon="exclamation-triangle"
                                                                                                                                label='Check Price and Availability'
                                                                                                                            />
                                                                                                                        </Box>
                                                                                                                    </Grid>
                                                                                                                )}
                                                                                                                {notes > 0 && (
                                                                                                                    <Grid item>
                                                                                                                        <Box pt={1}>
                                                                                                                            <InsightChip 
                                                                                                                                background="#4CAF50"
                                                                                                                                color="#fff"
                                                                                                                                marginRight={8}
                                                                                                                                paddingLeft={8}
                                                                                                                                icon="comment-alt-lines"
                                                                                                                                label={notes}
                                                                                                                                onClick={() => this.handleDeployNotes(rowData.productCode, rowData.notes ?? rowData.productData?.notes ?? [])}
                                                                                                                                size={11}
                                                                                                                                tooltip='View Notes'
                                                                                                                            />
                                                                                                                        </Box>
                                                                                                                    </Grid>
                                                                                                                )}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{width: '10%'}}>
                                                                                                    <TextField
                                                                                                        name="quantity"
                                                                                                        label="Quantity Ordered"
                                                                                                        value={rowData.quantity}
                                                                                                        type="number"
                                                                                                        fullWidth
                                                                                                        inputProps={{
                                                                                                            min: 0
                                                                                                        }}
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{width: '25%'}}>
                                                                                                    <Box pb={op.email === "" ? 1 : 0}>
                                                                                                        <TextField
                                                                                                            name="email"
                                                                                                            label="Authorisation Email"
                                                                                                            value={op.email}
                                                                                                            error={op.email === "" && true}
                                                                                                            onChange={e => this.handleChange(op.opId, "email", e?.target?.value ?? "")}
                                                                                                            fullWidth
                                                                                                            InputProps={{
                                                                                                                startAdornment: (<InputAdornment position="start"><FAIcon icon="envelope" size={15} /></InputAdornment>)
                                                                                                            }}
                                                                                                        />
                                                                                                    </Box>
                                                                                                </TableCell>
                                                                                            </TableRow>  
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                    <Box pt={3} pb={2}>
                                                                                        <Divider />
                                                                                    </Box>
                                                                                    <Table>
                                                                                        <TableBody>
                                                                                            <TableRow className='pl-1'>
                                                                                                <TableCell className={classes.td} style={{width: '35%'}}>
                                                                                                    <AutoCompleteSelect
                                                                                                        label="Site *"
                                                                                                        options={sites}
                                                                                                        value={op.cdbSite}                                
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbSite", e?.value ?? "")}
                                                                                                        noClear
                                                                                                    />
                                                                                                </TableCell>
                                                                                                    <TableCell className={classes.td} style={{width: 32}}>
                                                                                                        <Tooltip title="Add Site">
                                                                                                            <IconButton
                                                                                                                onClick={this.handleAddSite}
                                                                                                            >
                                                                                                                <FAIcon icon="plus" noMargin button />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </TableCell>
                                                                                                {/* <TableCell className={classes.td} style={{width: '15%'}}>
                                                                                                    <TextField
                                                                                                        name="cdbOrderNo"
                                                                                                        label="Cust Order Reference"
                                                                                                        value={op.cdbOrderNo}
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbOrderNo", e?.target?.value ?? "")}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell> */}
                                                                                                <TableCell className={classes.td} style={{width: '12.5%'}}>
                                                                                                    <TextField
                                                                                                        name="cdbIntRangeStart"
                                                                                                        label="Internal Range Start *"
                                                                                                        value={op.cdbIntRangeStart}
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbIntRangeStart", e?.target?.value ?? "")}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{width: '12.5%'}}>
                                                                                                    <TextField
                                                                                                        name="cdbIntRangeEnd"
                                                                                                        label="Internal Range End *"
                                                                                                        value={op.cdbIntRangeEnd}
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbIntRangeEnd", e?.target?.value ?? "")}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{width: '12.5%'}}>
                                                                                                    <TextField
                                                                                                        name="cdbExtRangeStart"
                                                                                                        label="External Range Start *"
                                                                                                        value={op.cdbExtRangeStart}
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbExtRangeStart", e?.target?.value ?? "")}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{width: '12.5%'}}>
                                                                                                    <TextField
                                                                                                        name="cdbExtRangeEnd"
                                                                                                        label="External Range End *"
                                                                                                        value={op.cdbExtRangeEnd}
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbExtRangeEnd", e?.target?.value ?? "")}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                    <Table>
                                                                                        <TableBody>
                                                                                            <TableRow className='pl-1'>
                                                                                                <TableCell className={classes.td} style={{width: '40%'}}>
                                                                                                    <TextField
                                                                                                        name="cdbComments"
                                                                                                        label="Comments"
                                                                                                        value={op.cdbComments}
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbComments", e?.target?.value ?? "")}
                                                                                                        fullWidth
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{width: '20%'}}>
                                                                                                    <AutoCompleteSelect
                                                                                                        label="Card Format *"
                                                                                                        options={cardDb.formats}
                                                                                                        value={op.cdbFormat}                                
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbFormat", e?.value ?? "")}
                                                                                                        noClear
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{width: '20%'}}>
                                                                                                    <AutoCompleteSelect
                                                                                                        label="Card Type *"
                                                                                                        options={cardDb.types}
                                                                                                        value={op.cdbType}                                
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbType", e?.value ?? "")}
                                                                                                        noClear
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.td} style={{width: '20%'}}>
                                                                                                    <AutoCompleteSelect
                                                                                                        label="Programmer Type *"
                                                                                                        options={cardDb.programmers}
                                                                                                        value={op.cdbProgrammer}                                
                                                                                                        onChange={e => this.handleChange(op.opId, "cdbProgrammer", e?.value ?? "")}
                                                                                                        noClear
                                                                                                    />
                                                                                                </TableCell>
                                                                                            </TableRow>  
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                    )
                                                                })}
                                                                <Grid item xs={12} align='right'>
                                                                    <Button
                                                                        color="primary"
                                                                        disabled={JSON.stringify(formData) === initialFormData}
                                                                        onClick={() => deployConfirmation(`Are you sure you want to start again?`, this.loadComponentData)}
                                                                        variant="text"
                                                                    >
                                                                        <FAIcon icon="times" size={15} className="mr-1" button />
                                                                        Discard Changes
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        disabled={disabled}
                                                                        onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to generate this card order?</Typography><Typography variant="body2">The authorisation letter will be automatically sent to the customer unless you have left the e-mail field blank</Typography></>, this.handleSubmit)}
                                                                        variant="text"
                                                                    >
                                                                        <FAIcon icon="check" size={15} button />
                                                                        Generate Card Order
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))}
                                </Grid> 
                            </>
                        )
                    )}
                </Grid>
            </>
        )
    }
}

export default withStyles(styles)(AwaitingAuthorisation);