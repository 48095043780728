import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from 'moment';
import uuidv4 from 'uuid/v4';

import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import DiscountConfiguration from 'Components/Customers/Misc/DiscountConfiguration';
import { isNumeric } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialFormData = () => ({
    category: {
        percent: '',
        category: '',
        isParent: false,
        excludedCategories: [],
        excludedSuppliers: [],
        excludedBrands: [],
        excludedRanges: [],
        excludedProducts: []
    },
    supplier: {
        percent: '',
        supplier: '',
        excludedCategories: [],
        excludedBrands: [],
        excludedRanges: [],
        excludedProducts: []
    },
    brand: {
        percent: '',
        brand: '',
        supplier: '',
        excludedCategories: [],
        excludedRanges: [],
        excludedProducts: []
    },
    range: {
        percent: '',
        minQty: 0,
        range: '',
        supplier: '',
        excludedCategories: [],
        excludedProducts: []
    },
    product: {
        percent: '',
        minQty: 0,
        product: []
    }
})

const initialState = () => ({
    name: '',
    active: 1,
    global: 0,
    scheduled: 0,
    effective: null,
    expiry: null,
    discounts: {
        category: [],
        supplier: [],
        brand: [],
        range: [],
        product: []
    }
})

const initialFormErrors = () =>(
    []
)

const initialKeys = () => ({
    category: uuidv4(),
    supplier: uuidv4(),
    brand: uuidv4(),
    range: uuidv4(),
    product: uuidv4()
})

const initialLists = () => ({
    brands: [],
    categories: [],
    ranges: [],
    suppliers: [],
})

const DiscountTemplateForm = ({id, close, callback}) => {

    const dispatch = useDispatch();

    const [formData, setFormData] = useState(initialState()),
            [formErrors, setFormErrors] = useState(initialFormErrors()),
            [key, setKeys] = useState(initialKeys()),
            [lists, setLists] = useState(initialLists()),
            [loading, setLoading] = useState(true);

    const handleConfirmation = () => {
        dispatch(deployConfirmation(`Are you sure you want to ${id ? `update` : `create`} this discount template?`, handleSubmit))
    }

    const handleSubmit = () => {
        setLoading(true);
        let apiRoute = `/settings/discountTemplates${id ? `/${id}` : ``}`;
        API.put(apiRoute, formData) 
        .then(res => {
            if(res?.data?.errors) {
                setFormErrors(formatValidationErrors(res.data.errors));
                dispatch(deploySnackBar(`error`, `There was an error ${id ? `updating` : `creating`} the discount template`))
                setLoading(false);
            } else if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `The discount template has been successfully ${id ? `updated` : `created`}`))
                callback();
                close();
            } else {
                setLoading(false);
            }
        })
    }

    const handleChange = e => {
        setFormData(formData => ({
            ...formData,
            [e.target.name]: e.target.value
        }))
    }

    const handleDiscountChange = (type, fieldName, idx, selectedOption, supplierOption = null) => {

        let acrossBoard = type === "all",
            discounts;

        if(acrossBoard) {
            discounts = {...formData.discounts?.all}
        } else {
            discounts = [...formData.discounts?.[type]];
        }

        if(type === "product" && fieldName === "product") {
            if(selectedOption && !_.isEmpty(selectedOption)) {
                let products = discounts[idx].product;
                if(_.findIndex(products, el => el.value === selectedOption?.value) === -1) {
                    products.push(
                        {
                            label: selectedOption?.code,
                            value: selectedOption?.value
                        }
                    )
                    if(acrossBoard) {
                        discounts = {
                            ...discounts,
                            [fieldName]: products
                        }
                    } else {
                        discounts[idx] = {
                            ...discounts[idx],
                            [fieldName]: products
                        }
                    }
                }
            }
        } else if(fieldName === "isInclusion" || fieldName === "incNoDiscount" || fieldName === "minQty") {
            discounts[idx] = {
                ...discounts[idx],
                [fieldName]: selectedOption
            }
        } else if(type === fieldName) {
            if(!acrossBoard) {
                discounts[idx] = {
                    ...initialFormData()[type],
                    [fieldName]: selectedOption?.value ?? '',
                    label: selectedOption?.label ?? '',
                    percent: discounts[idx].percent,
                    isInclusion: discounts[idx]?.isInclusion ?? 0
                }
                if(type === "category") {
                    if(selectedOption?.value && _.find(lists.categories, el => el.value === selectedOption?.value)) {
                        discounts[idx].isParent = true;
                    }
                }
                if(supplierOption) {
                    discounts[idx] = {
                        ...discounts[idx],
                        supplier: supplierOption
                    }
                }
            }
        } else {
            if(selectedOption && !_.isEmpty(selectedOption)) {
                let excluded = acrossBoard ? [...discounts[fieldName]] : [...discounts[idx][fieldName]];
                if(_.findIndex(excluded, el => el.value === selectedOption?.value) === -1) {
                    excluded.push(
                        {
                            label: selectedOption?.code ?? selectedOption?.label,
                            value: selectedOption?.value
                        }
                    )
                    if(acrossBoard) {
                        discounts = {
                            ...discounts,
                            [fieldName]: excluded
                        }
                    } else {
                        discounts[idx] = {
                            ...discounts[idx],
                            [fieldName]: excluded
                        }
                    }
                }
            }
        }

        setFormData(formData => ({
            ...formData,
            discounts: {
                ...formData.discounts,
                [type]: discounts
            }
        }))

        setKeys(key => ({
            ...key,
            [type]: uuidv4()
        }));

    }

    const handleDiscountPercentChange = (type, idx, p) => {
        let discounts, percent = p;
        if(type === "all") {
            discounts = {...formData.discounts.all};
            discounts = {
                ...discounts,
                percent
            }
        } else {
            discounts = [...formData.discounts?.[type]];
            discounts[idx] = {
                ...discounts[idx],
                percent
            }
        }

        setFormData(formData => ({
            ...formData,
            discounts: {
                ...formData.discounts,
                [type]: discounts
            }
        }))
    }

    const handleDiscountPercentFloat = (type, idx) => {
        let discounts;
        if(type === "all") {
            discounts = {...formData.discounts.all};
            discounts = {
                ...discounts,
                percent: !isNumeric(discounts.percent) || discounts.percent > 100 ? '' : parseFloat(discounts.percent).toFixed(2)
            }
            if(discounts.percent === "0.00") {
                discounts = {
                    ...discounts,
                    excludedSuppliers: [],
                    excludedProducts: [],
                    excludedRanges: []
                }
            }
        } else {
            discounts = [...formData.discounts?.[type]];
            discounts[idx] = {
                ...discounts[idx],
                percent: !isNumeric(discounts[idx].percent) || discounts[idx].percent > 100 ? '' : parseFloat(discounts[idx].percent).toFixed(2)
            }
        }

        setFormData(formData => ({
            ...formData,
            discounts: {
                ...formData.discounts,
                [type]: discounts
            }
        }))
    }

    const handleDiscountAddRow = (type) => {
        let discounts = [...formData.discounts?.[type]];
        discounts.push({...initialFormData()?.[type]});
        setFormData(formData => ({
            ...formData,
            discounts: {
                ...formData.discounts,
                [type]: discounts
            }
        }))
    }

    const handleDiscountRemoveRow = (type, idx) => {
        let discounts = [...formData.discounts?.[type]];
        discounts.splice(idx, 1);
        setFormData(formData => ({
            ...formData,
            discounts: {
                ...formData.discounts,
                [type]: discounts
            }
        }))
    }

    const handleRemoveDiscount = (type, fieldName, idx, pidx) => {
        let discounts, rows;
        if(type === "all") {
            discounts = {...formData.discounts?.all}
            rows = [...discounts?.[fieldName]];
            rows.splice(pidx, 1);
            discounts = {
                ...discounts,
                [fieldName]: rows
            }
        } else {
            discounts = [...formData.discounts?.[type]];
            rows = [...discounts?.[idx]?.[fieldName]];
            rows.splice(pidx, 1);
            discounts[idx] = {
                ...discounts[idx],
                [fieldName]: rows
            }
        }
        setFormData(formData => ({
            ...formData,
            discounts: {
                ...formData.discounts,
                [type]: discounts
            }
        }))
    }


    const handleDiscountMinQtyChange = (idx, minQty) => {
        let discounts = [...formData.discounts?.product];
        discounts[idx] = {
            ...discounts[idx],
            minQty
        }
        setFormData(formData => ({
            ...formData,
            discounts: {
                ...formData.discounts,
                product: discounts
            }
        }))
    }

    const handleDiscountMinQtyFloat = idx => {
        let discounts = [...formData.discounts?.product];
        discounts[idx] = {
            ...discounts[idx],
            minQty: !isNumeric(discounts[idx].minQty) || discounts[idx].minQty < 0 ? 0 : discounts[idx].minQty
        }
        setFormData(formData => ({
            ...formData,
            discounts: {
                ...formData.discounts,
                product: discounts
            }
        }))
    }

    const isFormValid = () => {
        
        if(formData.name === '') {
            return false;
        }

        if(formData.scheduled && (!formData.effective || !formData.expiry)) {
            return false;
        }

        if(_.every(Object.keys(formData.discounts), el => formData.discounts[el].length === 0)) {
            return false;
        }
        
        let discPercent = [],
            error = false;

        _.each(formData.discounts?.category, disc => {
            if(disc.category === "" || !isNumeric(disc.category)) {
                error = true
            }
            if(!error && disc?.isInclusion === 1 && _.isEmpty(disc?.excludedCategories) && _.isEmpty(disc?.excludedSuppliers) && _.isEmpty(disc?.excludedBrands) && _.isEmpty(disc?.excludedRanges)) {
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(formData.discounts?.supplier, disc => {
            if(disc.supplier === "" || !isNumeric(disc.supplier)) {
                error = true
            }
            if(!error && disc?.isInclusion === 1 && _.isEmpty(disc?.excludedCategories) && _.isEmpty(disc?.excludedBrands) && _.isEmpty(disc?.excludedRanges)) {
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(formData.discounts?.brand, disc => {
            if(disc.brand === "" || !isNumeric(disc.brand)) {
                error = true
            }
            if(!error && disc?.isInclusion === 1 && _.isEmpty(disc?.excludedCategories) && _.isEmpty(disc?.excludedRanges)) {
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(formData.discounts?.range, disc => {
            if(disc.supplier === "" || !isNumeric(disc.supplier) || disc.range === "" || !isNumeric(disc.range)) {
                error = true
            }
            if(!error && disc?.isInclusion === 1 && _.isEmpty(disc?.excludedCategories)) {
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(formData.discounts?.product, disc => {
            if(_.isEmpty(disc.product)) {
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(discPercent, p => {
            if(_.isEmpty(p) || !isNumeric(p) || p > 100) {
                error = true
            }
        })

        return !error;
        
    }

    useEffect(() => {
        if(id) {
            setLoading(true);
            API.get(`/settings/discountTemplates/${id}`)
            .then(res => {
                if(res?.data) {
                    setFormData(formData => ({
                        ...formData,
                        name: res.data.nm,
                        active: res.data.ac,
                        global: res.data.gl,
                        scheduled: res.data.sc,
                        effective: res.data.sc === 1 ? res.data.ef : null,
                        expiry: res.data.sc === 1 ? res.data.ex : null,
                        ub: res.data.ub,
                        lu: res.data.lu,
                        discounts: {
                            ...formData.discounts,
                            ...res.data.js
                        }
                    }));
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
        }
    }, [id]) /* eslint-disable-line */

    useEffect(() => {
            
        Promise.all([
            API.get('/suppliers/all', { params: { active: true, forType: 2, withBrands: true, withRanges: true } }),
            API.get('/smartSearch/categories', { params: { type: 'select' } }),
        ])
        .then(([
            suppliers,
            categories,
        ]) => {

            let _lists = {
                brands: [],
                categories: [],
                ranges: [],
                suppliers: [],
            }

            if(categories?.data) {
                _lists.categories = categories.data;
                _lists.categories.forEach(category => {
                    category.options.unshift({
                        label: `All ${category.label}`,
                        value: category.value
                    })   
                })
            }

            if(suppliers?.data) {

                _.each(suppliers.data, supp => {
                    if(!_.isEmpty(supp.brands)) {
                        _lists.brands.push({
                            id: supp.i,
                            label: supp.an,
                            options: _.map(supp.brands, brand => _.assign({
                                label: brand.brand_name,
                                value: brand.brand_id,
                                supplier: supp.i
                            }))             
                        })
                    }
                    
                    if(!_.isEmpty(supp.ranges)) {
                        _lists.ranges.push({
                            id: supp.i,
                            label: supp.an,
                            options: _.map(supp.ranges, range => _.assign({
                                label: range.range_name,
                                value: range.range_id,
                                supplier: supp.i
                            }))             
                        })
                    }
                });

                _lists.suppliers = _.map(suppliers.data, el => (
                    _.assign({
                        label: el.an,
                        value: el.i
                    })
                ));

            }

            setLists(_lists);

        });

    }, [])

    return (
        (loading && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3} alignItems='center'>
                <Grid item xs>
                    <TextField
                        name="name"
                        placeholder="Template name *"
                        margin="none" 
                        autoComplete="off"
                        error={formErrors && formErrors['name'] && true}
                        helperText={formErrors && formErrors['name']}
                        value={formData.name}
                        onChange={e => handleChange({target: {name: 'name', value: e.target.value}})}
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch color="primary" checked={formData.active === 1} onChange={() => handleChange({target: {name: 'active', value: formData.active === 1 ? 0 : 1}})} value="1" name="active" />
                        }
                        label={<span className="fw-400">Active</span>}
                        disabled={!id}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch color="primary" checked={formData.global === 1} onChange={() => handleChange({target: {name: 'global', value: formData.global === 1 ? 0 : 1}})} value="1" name="global" />
                        }
                        label={<span className="fw-400">Apply to all customers</span>}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch color="primary" checked={formData.scheduled === 1} onChange={() => handleChange({target: {name: 'scheduled', value: formData.scheduled === 1 ? 0 : 1}})} value="1" name="scheduled" />
                        }
                        label={<span className="fw-400">Schedule discount validity period</span>}
                    />
                </Grid>
                <Grid item xs />
                <Grid item align="right">
                    <Button
                        disabled={!isFormValid()}
                        variant="contained"
                        color="primary"
                        style={{
                            height: 39
                        }}
                        onClick={handleConfirmation}
                    >
                        <FAIcon icon="check" />
                        <span>{!id ? `Create` : `Update`} Template</span>
                    </Button>
                </Grid>
                {(formData.active === 0 && (
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            <strong>Important Infomation</strong><br />
                            This template will be disabled, any customers with this template assigned will lose this discount when you save.
                        </Alert>
                    </Grid>
                )) || (formData.global === 1 && (
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            <strong>Important Infomation</strong><br />
                            This template will be applied to all customers.
                        </Alert>
                    </Grid>
                ))}
                {formData.scheduled === 1 && (
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={3}>
                                <Typography variant="h6" gutterBottom>
                                    Discount Validity Period
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    <FAIcon icon="info-circle" size={15} />
                                    Set the effective and expiry dates for this discount template, it will only be valid in the period specified.
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <DatePicker
                                                type="date"
                                                label="Effective Date *"
                                                value={formData.effective}
                                                onChange={e => handleChange({target: {name: 'effective', value: e ? moment(e).format('YYYY-MM-DD') : null}})}
                                                autoOk={true}
                                                minDate={moment().format('YYYY-MM-DD')}
                                                maxDate={formData.expiry ? moment(formData.expiry).format('YYYY-MM-DD') : null}
                                                minDateMessage={''}
                                                maxDateMessage={''}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <DatePicker
                                                type="date"
                                                label="Expiry Date *"
                                                value={formData.expiry}
                                                onChange={e => handleChange({target: {name: 'expiry', value: e ? moment(e).format('YYYY-MM-DD') : null}})}
                                                minDate={formData.effective ? moment(formData.effective).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                                autoOk={true}
                                                minDateMessage={''}
                                                maxDateMessage={''}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>   
                )}
                <Grid item xs={12}>
                    <DiscountConfiguration 
                        formData={formData}
                        formErrors={formErrors}
                        lists={lists}
                        innerKey={key}
                        handleDiscountChange={handleDiscountChange}
                        handleDiscountPercentChange={handleDiscountPercentChange}
                        handleDiscountPercentFloat={handleDiscountPercentFloat}
                        handleDiscountAddRow={handleDiscountAddRow}
                        handleDiscountRemoveRow={handleDiscountRemoveRow}
                        handleRemoveDiscount={handleRemoveDiscount}
                        handleDiscountMinQtyChange={handleDiscountMinQtyChange}
                        handleDiscountMinQtyFloat={handleDiscountMinQtyFloat}
                    />
                </Grid>
            </Grid>
        )
    )
}

export default DiscountTemplateForm;