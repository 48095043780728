import _ from 'lodash';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import InsightChip from 'Components/Common/Chips/InsightChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import DataTable from 'Components/Common/DataTables/DataTable';
import Heading from 'Components/Common/Heading/Heading';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ViewPage from 'Components/Common/Styles/ViewPage';
import Tile from 'Components/Common/Tiles/Tile';
import SearchQuotes from 'Components/Quotes/SearchQuotes/SearchQuotes';
import { VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from 'Constants';
import { currencyFormat, handleTabChange, TabPanel } from 'Functions/MiscFunctions';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    accountCodes: {
        new: [],
        prospect: [],
    },
    chart: {
        totals: null,
        values: null
    },
    currentTab: 0,
    searchResults: {
        accounts: '',
        prospects: ''
    },
    accountCode: {
        accounts: '',
        prospects: ''
    },
    accountManager: {
        accounts: '',
        prospects: ''
    },
    searchString: {
        accounts: '',
        prospects: ''
    },
    access: {
        viewCustomer: false,
        addProspect: false
    },
    isLoading: true,
    insight: {},
    key: uuidv4(),
    dataLoading: {
        accounts: true,
        prospects: true,
    },
    tabTitle: {
        0: "New Business Overview",
        1: "New Accounts",
        2: "Prospect Accounts",
        3: "Prospect Quotes",
        4: "New Account Quotes"
    },
    tabText: {
        0: "Live new business data and insights",
        1: "New accounts that are still with the new business team",
        2: "Prospect accounts that have not yet opened an account to order",
        3: "The following quotes have been created for prospect accounts",
        4: "The following quotes have been created for new accounts"
    }
})

class NewBusinessDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.mainContentArea = React.createRef();
        this.persistenceId = 'newBusinessDashboard';
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.handleTabChange = handleTabChange.bind(this);
        this.timeout = false;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'New Business']);
        if(!this.hasPageState()) {
            Promise.all([
                API.access('add-prospect'),
                API.access('view-customer'),
                API.get('/customers/prospects/insight'),
                API.get('/customers/statuses', { params: { prospect: true } }),
                API.get('/customers/statuses', { params: { new: true } })
            ])
            .then(([addProspect, viewCustomer, insight, prospectStatuses, newStatuses]) => {

                if(insight?.data) {

                    let values          =   {},
                        totals          =   {},
                        labels          =   [],
                        orders          =   [],
                        quotes          =   [],
                        totalOrders     =   [],
                        totalQuotes     =   [],
                        accCodeNew      =   _.map(newStatuses.data, el => (
                                                _.assign({
                                                    label: el.status_name,
                                                    options: _.map(el.children, _el => (
                                                        _.assign({
                                                            value: _el.status_id,
                                                            label: `${_el.status_code} - ${_el.status_name}`
                                                        })
                                                    ))
                                                })
                                            )),
                        accCodeProspect =   _.map(prospectStatuses.data, el => (
                                                _.assign({
                                                    label: el.status_name,
                                                    options: _.map(el.children, _el => (
                                                        _.assign({
                                                            value: _el.status_id,
                                                            label: `${_el.status_code} - ${_el.status_name}`
                                                        })
                                                    ))
                                                })
                                            ));
                                            
                    for (let i = 0, j = 11; i <= j; i++) {
                        orders.push(insight?.data?.charts?.[i]?.orders?.v);
                        totalOrders.push(insight?.data?.charts?.[i]?.orders?.t);
                        quotes.push(insight?.data?.charts?.[i]?.quotes?.v);
                        totalQuotes.push(insight?.data?.charts?.[i]?.quotes?.t);
                        labels.push(insight?.data?.charts?.[i]?.label);
                    }

                    values = {
                        labels,
                        datasets: [
                            {
                                label: 'Sales',
                                data: orders,
                                backgroundColor: '#4CAF50',
                                borderColor: '#C8E6C9',
                                fill: false,
                                lineTension: 0.25,
                            },
                            {
                                label: 'Quotes',
                                data: quotes,
                                fill: false,
                                backgroundColor: '#F44336',
                                borderColor: '#FFCDD2',
                                lineTension: 0.25,
                            }
                        ]
                    }

                    totals = {
                        labels,
                        datasets: [
                            {
                                label: 'Sales',
                                data: totalOrders,
                                backgroundColor: '#4CAF50',
                                borderColor: '#C8E6C9',
                                fill: false,
                                lineTension: 0.25,
                            },
                            {
                                label: 'Quotes',
                                data: totalQuotes,
                                fill: false,
                                backgroundColor: '#F44336',
                                borderColor: '#FFCDD2',
                                lineTension: 0.25,
                            }
                        ]
                    }


                    this.setState({
                        accountCodes: {
                            new: accCodeNew,
                            prospect: accCodeProspect
                        },
                        access: {
                            addProspect: (addProspect.data && addProspect.data.has_access) || false,
                            viewCustomer: (viewCustomer.data && viewCustomer.data.has_access) || false,
                        },
                        chart: {
                            totals,
                            values
                        },
                        insight: insight?.data,
                        isLoading: false
                    });

                }

            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

        if((prevState.currentTab !== this.state.currentTab) || (prevProps.config?.badges?.showAllProspects !== this.props.config?.badges?.showAllProspects)) {
            const action = () => {
                this.clearPageState();
                if(this.state.currentTab === 1) {
                    this.getAccounts(true);
                }
                if(this.state.currentTab === 2) {
                    this.getProspects(true);
                }
            }
            if(this.props.config?.badges?.showAllProspects === 0) {
                this.setState({
                    accountManager: initialState().accountManager
                }, action)
            } else {
                action();
            }
        }

    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getAccounts = dataLoading => {
        this.setState({
            dataLoading: {
                ...this.state.dataLoading,
                accounts: dataLoading
            },
        }, () => {
            API.get('/customers/prospects', {
                props: {
                    cancellation: true
                },
                params: { 
                    my: this.props?.config?.badges?.showAllProspects === 0 ? 1 : 0,
                    searchString: this.state.searchString.accounts,
                    accountCode: this.state.accountCode.accounts,
                    accountManager: this.state.accountManager.accounts, 
                    type: 'accounts'
                } 
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: {
                            ...this.state.dataLoading,
                            accounts: false
                        },
                        searchResults: {
                            ...this.state.searchResults,
                            accounts: result.data
                        },
                    }, () => {
                        this.savePageState();
                    });
                }
            });
        });
    }

    getProspects = dataLoading => {
        this.setState({
            dataLoading: {
                ...this.state.dataLoading,
                prospects: dataLoading
            },
        }, () => {
            API.get('/customers/prospects', {
                params: { 
                    my: this.props?.config?.badges?.showAllProspects === 0 ? 1 : 0,
                    searchString: this.state.searchString.prospects, 
                    accountCode: this.state.accountCode.prospects,
                    accountManager: this.state.accountManager.prospects, 
                    type: 'prospects' 
                } 
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: {
                            ...this.state.dataLoading,
                            prospects: false
                        },
                        searchResults: {
                            ...this.state.searchResults,
                            prospects: result.data
                        },
                    }, () => {
                        this.savePageState();
                    });
                }
            });
        });
    }

    onSearchStringChange = type => (event) => {
        this.setState({
            searchString: {
                ...this.state.searchString,
                [type]: event.target.value ?? ''
            }
        },
        () => {
            this.setSearch(type);
        });
    }

    onChange = (type, field, o) => {
        this.setState({
            [field]: {
                ...this.state[field],
                [type]: o?.value ?? ''
            }
        },
        () => {
            this.setSearch(type);
        });
    }

    setSearch = (type) => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        if(type === "prospects") {
            this.timeout = setTimeout(() => this.getProspects(true), 200);
        } else {
            this.timeout = setTimeout(() => this.getAccounts(true), 200);
        }
    }

    render() {
        const { classes, history, staff, staffList, ui } = this.props;
        const { access, chart, currentTab, dataLoading, isLoading, insight, tabTitle, tabText } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabTitle[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}}>
                                            <Button
                                                variant="text"
                                                onClick={() => history.push(`/new-business/new`)}
                                            >   
                                                <FAIcon type="light" icon="plus-circle" size={15} button />
                                                Add Prospect Account
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        <TabPanel value={currentTab} index={0}> 
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6} lg={4}>
                                                            <PaddedPaper>
                                                                <Heading text="Opportunity" />
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} lg={6}>                        
                                                                        <Tile
                                                                            noPaper
                                                                            icon="users-class"
                                                                            title="Accounts"
                                                                            content={insight?.totals?.accounts ?? 0}
                                                                            onClick={() => this.handleTabChange(1)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>                           
                                                                        <Tile
                                                                            noPaper
                                                                            icon="users-class"
                                                                            title="Prospects"
                                                                            content={insight?.totals?.prospects ?? 0}
                                                                            onClick={() => this.handleTabChange(2)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid item xs={6} lg={4}>
                                                            <PaddedPaper>
                                                                <Heading text="New Prospect Accounts" />
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} lg={6}>                              
                                                                        <Tile
                                                                            noPaper
                                                                            icon="user-plus"
                                                                            title="This Week"
                                                                            content={insight?.prospects?.week}
                                                                        />                     
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>                                
                                                                    <Tile
                                                                        noPaper
                                                                        icon="user-plus"
                                                                        title="This Month"
                                                                        content={insight?.prospects?.month}
                                                                    />                       
                                                                    </Grid>
                                                                </Grid>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid item xs={6} lg={4}>
                                                            <PaddedPaper style={{height: '100%'}}>
                                                                <Heading text="New Accounts Opened" />
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} lg={6}>                                 
                                                                        <Tile
                                                                            noPaper
                                                                            icon="user-check"
                                                                            title="This Week"
                                                                            content={insight?.accounts?.week}
                                                                        />                   
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>                                  
                                                                        <Tile
                                                                            noPaper
                                                                            icon="user-check"
                                                                            title="This Month"
                                                                            content={insight?.accounts?.month}
                                                                        />                       
                                                                    </Grid>
                                                                </Grid>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} lg={6}>
                                                                <PaddedPaper>
                                                                <Heading text="Quotes" />
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} lg={4}>                                 
                                                                        <Tile
                                                                            noPaper
                                                                            icon="check"
                                                                            title="Total Quotes | This Week"
                                                                            content={insight?.quotes?.week}
                                                                        />                    
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={4}>                            
                                                                        <Tile
                                                                            noPaper
                                                                            icon="check"
                                                                            title="Total Quotes | This Month"
                                                                            content={insight?.quotes?.month}
                                                                        />                          
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={4}>                            
                                                                        <Tile
                                                                            noPaper
                                                                            icon="pound-sign"
                                                                            title="Net Total | This Month"
                                                                            content={currencyFormat.format(insight?.charts?.[11]?.quotes?.v ?? 0)}
                                                                        />                          
                                                                    </Grid>
                                                                </Grid>
                                                            </PaddedPaper>
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <PaddedPaper>
                                                                        <Heading text="Sales Orders" />
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12} lg={4}>                            
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="check"
                                                                                    title="Total Orders | This Week"
                                                                                    content={insight?.orders?.week}
                                                                                />                     
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={4}>                                  
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="check"
                                                                                    title="Total Orders | This Month"
                                                                                    content={insight?.orders?.month}
                                                                                />                    
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={4}>                            
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="pound-sign"
                                                                                    title="Net Total | This Month"
                                                                                    content={currencyFormat.format(insight?.charts?.[11]?.orders?.v ?? 0)}
                                                                                />                          
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} lg={6}>
                                                            <PaddedPaper>
                                                                <Box pb={3}>
                                                                    <Box pb={3}>
                                                                        <Heading text="Activity" />
                                                                    </Box>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <Box maxWidth={'87.5%'} margin='0 auto'>
                                                                                <Line 
                                                                                    data={chart?.totals} 
                                                                                    options={{
                                                                                        legend: {
                                                                                            display: false,
                                                                                        },
                                                                                        scales: {
                                                                                            xAxes: [{
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                }
                                                                                            }],
                                                                                            yAxes: [{
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                },
                                                                                                ticks: {
                                                                                                    beginAtZero: true,
                                                                                                    min: 0
                                                                                                }
                                                                                            }]
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <PaddedPaper>
                                                                <Box pb={3}>
                                                                    <Box pb={3}>
                                                                        <Heading text="Net Totals" />
                                                                    </Box>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <Box maxWidth={'87.5%'} margin='0 auto'>
                                                                                <Line 
                                                                                    data={chart?.values} 
                                                                                    options={{
                                                                                        legend: {
                                                                                            display: false,
                                                                                        },
                                                                                        scales: {
                                                                                            xAxes: [{
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                }
                                                                                            }],
                                                                                            yAxes: [{
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                },
                                                                                                ticks: {
                                                                                                    callback: (value, index, values) => {
                                                                                                        return value.toLocaleString("en-GB", {
                                                                                                            style: "currency",
                                                                                                            currency: "GBP"
                                                                                                        });
                                                                                                    },
                                                                                                    beginAtZero: true,
                                                                                                    min: 0
                                                                                                }
                                                                                            }]
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </PaddedPaper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={1}>
                                            <Grid container spacing={1}>      
                                                <Grid container item xs={12} alignItems='center'>
                                                    <Grid item xs={12} sm={5} md={4} xl={3} className="pr-2" style={{marginBottom: 12}}>
                                                        <TextField
                                                            fullWidth
                                                            onChange={this.onSearchStringChange('accounts')}
                                                            placeholder='Search:'
                                                            value={this.state.searchString.accounts} 
                                                            variant="filled"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                                            }}
                                                        />
                                                    </Grid>  
                                                    <Grid item xs={12} sm={3} xl={3} className="pr-2" style={{marginBottom: 12}}>
                                                        <AutoCompleteSelect
                                                            options={this.state.accountCodes.new}
                                                            placeholder='Account Code:'
                                                            onChange={o => this.onChange('accounts', 'accountCode', o)}
                                                            value={this.state.accountCode.accounts} 
                                                            variant="filled"
                                                            adornment="filter"
                                                            isGrouped
                                                        /> 
                                                    </Grid>
                                                    {this.props.config?.badges?.showAllProspects !== 0 && (
                                                        <Grid item xs={12} sm={3} xl={3} className="pr-2" style={{marginBottom: 12}}>
                                                            <AutoCompleteSelect
                                                                options={staffList}
                                                                placeholder='Proactive Responsibility:'
                                                                onChange={o => this.onChange('accounts', 'accountManager', o)}
                                                                value={this.state.accountManager.accounts} 
                                                                variant="filled"
                                                                adornment="user"
                                                            /> 
                                                        </Grid> 
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <DataTable
                                                            config={{
                                                                key: 'customer_id',
                                                                persistenceId: `newBusinessAccounts`,
                                                                pagination: true,
                                                                alternatingRowColours: true,
                                                                isLoading: dataLoading.accounts,
                                                                responsiveImportance: true,
                                                                options: {
                                                                    defaultSort: 'nm',
                                                                    dataRef: true,
                                                                    export: {
                                                                        title: `New Accounts`,
                                                                        name: `new-accounts`,
                                                                        excel: true,
                                                                        pdf: true,
                                                                        print: true
                                                                    }
                                                                }
                                                            }}
                                                            columns={[
                                                                {
                                                                    heading: 'Company',
                                                                    field: rowData => rowData.nm,
                                                                    dataRef: 'nm',
                                                                    important: true,
                                                                    main: true,
                                                                    truncate: true
                                                                },
                                                                {
                                                                    heading: 'Acc Number',
                                                                    field: rowData => rowData.ac,
                                                                    dataRef: 'ac',
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Postcode',
                                                                    field: rowData => rowData.ap ?? '-',
                                                                    dataRef: 'ap',
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Payment Terms',
                                                                    field: rowData => rowData.pt,
                                                                    dataRef: 'pt',
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Main Contact Number',
                                                                    field: rowData => rowData.pn ?? '-',
                                                                    dataRef: 'pn',
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Primary Contact',
                                                                    field: rowData => rowData.pc ?? '-',
                                                                    dataRef: 'pc',
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Type',
                                                                    field: rowData => rowData.typ,
                                                                    dataRef: 'typ',
                                                                    sizeToContent: true
                                                                },
                                                                // {
                                                                //     heading: 'Quotes',
                                                                //     field: rowData => rowData.quotes_count,
                                                                //     dataRef: 'quotes_count',
                                                                //     alignment: 'right',
                                                                //     sizeToContent: true
                                                                // },
                                                                // {
                                                                //     heading: 'Orders',
                                                                //     field: rowData => rowData.orders_count,
                                                                //     dataRef: 'orders_count',
                                                                //     alignment: 'right',
                                                                //     sizeToContent: true
                                                                // },
                                                                {
                                                                    heading: 'Proactive Responsibility',
                                                                    field: rowData => (staff && staff[rowData.am] && <StaffChip staff={staff[rowData.am]} />) || '-',
                                                                    dataRef: 'am',
                                                                    sizeToContent: true,
                                                                    fieldFormat: 'staff',
                                                                },  
                                                                {
                                                                    heading: 'Account Code',
                                                                    field: rowData => (
                                                                        <>
                                                                            {rowData.sc} - {rowData.t}
                                                                            <Typography variant="caption" component="div">{rowData.st}</Typography>
                                                                        </>
                                                                    ),
                                                                    dataRef: 'sc',
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    actions: rowData => {
                                                                        return [
                                                                            {name: 'View', icon: 'binoculars', link: '/customers/' + rowData.id, disabled: !access.viewCustomer}
                                                                        ]
                                                                    }
                                                                }
                                                            ]}
                                                            rows={this.state.searchResults.accounts}
                                                        />
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={2}>
                                            <Grid container spacing={1}>      
                                                <Grid container item xs={12} alignItems='center'>
                                                    <Grid item xs={12} sm={5} md={4} xl={3} className="pr-2" style={{marginBottom: 12}}>
                                                        <TextField
                                                            fullWidth
                                                            onChange={this.onSearchStringChange('prospects')}
                                                            placeholder='Search:'
                                                            value={this.state.searchString.prospects} 
                                                            variant="filled"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                                            }}
                                                        />
                                                    </Grid>   
                                                    <Grid item xs={12} sm={3} xl={3} className="pr-2" style={{marginBottom: 12}}>
                                                        <AutoCompleteSelect
                                                            options={this.state.accountCodes.prospect}
                                                            placeholder='Account Code:'
                                                            onChange={o => this.onChange('prospects', 'accountCode', o)}
                                                            value={this.state.accountCode.prospects} 
                                                            variant="filled"
                                                            adornment="filter"
                                                            isGrouped
                                                        /> 
                                                    </Grid>
                                                    {this.props.config?.badges?.showAllProspects !== 0 && (
                                                        <Grid item xs={12} sm={3} xl={3} className="pr-2" style={{marginBottom: 12}}>
                                                            <AutoCompleteSelect
                                                                options={staffList}
                                                                placeholder='Proactive Responsibility:'
                                                                onChange={o => this.onChange('prospects', 'accountManager', o)}
                                                                value={this.state.accountManager.prospects} 
                                                                variant="filled"
                                                                adornment="user"
                                                            /> 
                                                        </Grid> 
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <DataTable
                                                            config={{
                                                                key: 'customer_id',
                                                                persistenceId: `newBusinessProspects`,
                                                                pagination: true,
                                                                alternatingRowColours: true,
                                                                isLoading: dataLoading.prospects,
                                                                responsiveImportance: true,
                                                                options: {
                                                                    defaultSort: 'nm',
                                                                    dataRef: true,
                                                                    export: {
                                                                        title: `Prospect Accounts`,
                                                                        name: `Prospect-accounts`,
                                                                        excel: true,
                                                                        pdf: true,
                                                                        print: true
                                                                    }
                                                                }
                                                            }}
                                                            columns={[
                                                                {
                                                                    heading: 'Company',
                                                                    field: rowData => rowData.nm,
                                                                    dataRef: 'nm',
                                                                    important: true,
                                                                    main: true,
                                                                    truncate: true
                                                                },
                                                                {
                                                                    heading: 'Postcode',
                                                                    field: rowData => rowData.ap ?? '-',
                                                                    dataRef: 'ap',
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Main Contact Number',
                                                                    field: rowData => rowData.pn ?? '-',
                                                                    dataRef: 'pn',
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Main Email Address',
                                                                    field: rowData => rowData.em ?? '-',
                                                                    dataRef: 'em',
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Primary Contact',
                                                                    field: rowData => rowData.pc ?? '-',
                                                                    dataRef: 'pc',
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Type',
                                                                    field: rowData => rowData.typ,
                                                                    dataRef: 'typ',
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Proactive Responsibility',
                                                                    field: rowData => (staff && staff[rowData.am] && <StaffChip staff={staff[rowData.am]} />) || '-',
                                                                    dataRef: 'am',
                                                                    sizeToContent: true,
                                                                    fieldFormat: 'staff',
                                                                },  
                                                                {
                                                                    heading: 'Account Code',
                                                                    field: rowData => (
                                                                        <>
                                                                            {rowData.sc} - {rowData.t}
                                                                            <Typography variant="caption" component="div">{rowData.st}</Typography>
                                                                        </>
                                                                    ),
                                                                    dataRef: 'sc',
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    actions: rowData => {
                                                                        return [
                                                                            {name: 'View', icon: 'binoculars', link: '/new-business/' + rowData.id, disabled: !access.viewCustomer}
                                                                        ]
                                                                    }
                                                                }
                                                            ]}
                                                            rows={this.state.searchResults.prospects}
                                                        />
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={3}>
                                            <SearchQuotes 
                                                forProspects
                                            />
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={4}>
                                            <SearchQuotes 
                                                forNewAccounts
                                            />
                                        </TabPanel>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                <Divider />
                                <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="New Accounts" />
                                <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} label="New Account Quotes" />
                                <Divider />
                                <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Prospect Accounts" />
                                <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="Prospect Quotes" />
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    config: state.notifications.config,
    staff: state.notifications.status,
    staffList: state.notifications.staffList,
    statePersistence: state.statePersistence,
    ui: state.ui
})

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(NewBusinessDashboard));