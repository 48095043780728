import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import BundleForm from 'Components/Products/Bundles/BundleForm';
import KitPricing from 'Components/Products/Bundles/KitPricing';
import Search from 'Components/Common/Search/Search';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffChip from 'Components/Common/Chips/StaffChip';
import logo from 'Assets/Images/no-img.jpg';

import { 
    deployConfirmation 
} from 'Redux/Actions/Confirmation/Confirmation';

import { 
    deployDialog 
} from 'Redux/Actions/Dialog/Dialog';

import { 
    deploySnackBar 
} from 'Redux/Actions/SnackBar/SnackBar';

import {
    CLOUDFRONT_URL
} from 'Constants';

import { 
    momentFormatDate 
} from 'Functions/MiscFunctions';

const initialState = () => ({
    access: {
        newBundle: false,
        viewBundle: false,
        deleteBundle: false,
        kitPricing: false
    },
    key: uuidv4()
})

const SearchBundles = ({pageTitle, history}) => {
    
    const [state, setState] = useState(initialState());

    const dispatch = useDispatch();

    const staff = useSelector(state => state.notifications.status);

    const handleNewBundle = () => {
        dispatch(deployDialog(
            <BundleForm
                callback={handleRefresh}
            />,
            false,
            'Create Product Bundle',
            'standard',
            'xl',
            true,
            true
        ))
    }

    const handleKitPricing = () => {
        dispatch(deployDialog(
            <KitPricing
                callback={handleRefresh}
            />,
            false,
            'Manage Kit Pricing',
            'standard',
            'xl',
            true,
            true
        ))
    }

    const handleDeleteBundleConfirmation = id => {
        dispatch(deployConfirmation(`Are you sure you want to delete this product bundle?`, () => handleDeleteBundle(id)));
    }

    const handleDeleteBundle = id => {
        API.put(`/products/bundles/${id}/delete`)
        .then(res => {
            if(res?.data?.success) {
                handleRefresh();
                dispatch(deploySnackBar(`success`, `You have successfully deleted the product bundle`))
            } else {
                dispatch(deploySnackBar(`error`, `The product bundle could not be deleted, please try again`))
            }
        })
    }

    const handleRefresh = () => {
        setState(state => ({
            ...state,
            key: uuidv4()
        }));
    }

    useEffect(() => {
        API.multiAccess([
            'product-bundles:create',
            'product-bundles:view',
            'product-bundles:delete',
            'product-bundles:kit-pricing'
        ])
        .then(([
            newBundle, 
            viewBundle,
            deleteBundle,
            kitPricing
        ]) =>  {
            setState(state => ({
                ...state,
                access: {
                    newBundle, 
                    viewBundle,
                    deleteBundle,
                    kitPricing
                }
            }));
        })
    }, []);

    const {
        access,
        key
    } = state;

    return (
        <Search
            innerKey={key}
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/products/bundles',
                columns: [
                    {
                        heading: 'Active',
                        field: rowData => rowData.lv,
                        fieldFormat: 'boolean',
                        sizeToContent: true,  
                        dataRef: 'lv',
                        alignment: 'center'
                    },
                    {
                        heading: 'Description',
                        field: rowData => (
                            <Box pt={1} pb={1}>
                                <Grid container spacing={3} alignItems='center' wrap="nowrap">
                                    <Grid item>
                                        <Avatar variant="square" alt={rowData.nm} src={!_.isEmpty(rowData.im) ? `${CLOUDFRONT_URL}${rowData.im}` : logo} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className="fw-400">
                                            {rowData.pc}
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData.nm}
                                        </Typography>
                                        <Typography variant="caption">
                                            {rowData.desc}
                                        </Typography>
                                        {rowData.ty === 'Fixed Kit' && (
                                            <Typography variant="caption" component="div" color={rowData.lv === 1 ? 'error' : undefined}>
                                                Valid from {momentFormatDate(rowData.vf)} to {momentFormatDate(rowData.vt)}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        ),
                        dataRef: 'nm',
                    },
                    {
                        heading: 'Type',
                        field: rowData => rowData.ty,
                        sizeToContent: true,  
                        dataRef: 'ty',
                    },
                    {
                        heading: 'Buy Price',
                        field: rowData => rowData.bp,
                        sizeToContent: true,  
                        dataRef: 'bp',
                        alignment: 'right',
                    },
                    {
                        heading: 'Trade Price',
                        field: rowData => rowData.tp,
                        sizeToContent: true,  
                        dataRef: 'tp',
                        alignment: 'right',
                        cellProps: () => ({
                            style: {
                                color: 'rgb(56, 142, 60)'
                            }
                        })
                    },
                    {
                        heading: 'Margin',
                        field: rowData => rowData.mr,
                        sizeToContent: true,  
                        dataRef: 'mr',
                        alignment: 'right'
                    },
                    {
                        heading: 'Updated By',
                        field: rowData => <StaffChip staff={staff?.[rowData.st]} />,
                        fieldFormat: 'staff',
                        dataRef: 'st',
                        sizeToContent: true                                
                    },
                    {
                        heading: 'Updated',
                        field: rowData => rowData.dt,
                        fieldFormat: 'datetime',
                        dataRef: 'dt',
                        sizeToContent: true                           
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'View', icon: 'binoculars', link: `/products/bundles/${rowData.id}`, disabled: !access.viewBundle, doubleClick: true},
                                {name: 'Delete', icon: 'trash-alt', onClick: () => handleDeleteBundleConfirmation(rowData.id), disabled: !access.deleteBundle}
                            ]
                        }
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'id',
                name: 'product-bundles',
                noResultsText: 'No product bundles were found',
                pagination: true,
                pageTitleData: [0, 'Products', 'Bundles / Kits'],
                pdf: true,
                persistenceId: null,
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showButton: (
                    <>
                        {access.newBundle && (
                            <Button 
                                color="primary" 
                                size="small"
                                variant="text"
                                onClick={handleNewBundle}
                            >   
                                <FAIcon type="light" icon="plus-circle" size="small" button />
                                Create Bundle
                            </Button>
                        )}
                        {access.kitPricing && (
                            <Button 
                                color="primary" 
                                size="small"
                                variant="text"
                                onClick={handleKitPricing}
                            >   
                                <FAIcon type="light" icon="coins" size="small" button />
                                Manage Kit Pricing
                            </Button>
                        )}
                    </>
                ),
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showProduct: true,
                showSearch: true,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Product Bundles'
            }}
        />
    )
}

export default SearchBundles;