import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useEffect, useState } from 'react';
import API from 'API';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import { CLOUDFRONT_URL } from 'Constants';

const CallRecordings = ({ id }) => { 

    const   [data, setData]         =   useState([]),
            [loading, setLoading]   =   useState(true);

    const handleLoadData = () => {
        setLoading(true);
        API.get(`/misc/calls/${id}/recordings`)
        .then(res => {
            if(res?.data) {
                setData(res.data);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        handleLoadData();
    }, [id]) /* eslint-disable-line */
    
    return (
        <>
            {(loading && (
                <LoadingCircle />
            )) || (
                <>
                    {_.map(data, (recording, i) => (
                        <Box key={i} mb={i !== (data.length - 1) ? 3 : 0}>
                            <Typography variant="subtitle2" paragraph>
                                Recording {i + 1}
                            </Typography>
                             <audio src={`${CLOUDFRONT_URL}${recording.fk}`} controls>
                                Your browser does not support the audio element.
                            </audio>
                        </Box>
                    ))}
                </>
            )}
        </>
    )
}

export default CallRecordings;