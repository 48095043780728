import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import User from 'Components/Layout/Common/User';
import UserDetails from 'Components/Layout/Common/UserDetails';
import { withStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import WarehouseRouteSwitcher from 'Components/Routes/WarehouseRoutes/WarehouseRouteSwitcher';
import { setLastActivePage } from 'Functions/AuthFunctions';
import { handleAppError } from 'Redux/Actions/UI/UI';

const styles = theme => ({
    appBarSpacer: {
        minHeight: 82.5
    },
    pwaBarSpacer: {
        minHeight: 'env(safe-area-inset-top)',
    },
    chevronIcon: {
        width: '12px!important', 
        height: '12px!important', 
        marginLeft: 7,
        marginRight: 7
    },
    container: {
        padding: theme.spacing(1)
    },
    content: {
        width: '100%',
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    page: {
        height: 35,
        marginTop: 47.5,
        padding: theme.spacing(1.1, 1.5, 1.5, 1.5),
        background: '#fff',
        borderBottom: '1px solid #ddd'
    },
    pageIcon: {
        width: '17.5px!important', 
        height: '17.5px!important', 
        marginRight: 7
    },
    [theme.breakpoints.down('xs')]: {
        content: {
            webkitOverflowScrolling: 'touch',
        }
    },
    ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
        content: {
            marginLeft: 'env(safe-area-inset-left)',
            marginRight: 'env(safe-area-inset-right)',
        }
    },
});

const initialState = () => ({
    scrollHeight: window.innerHeight,
    scheduledUpdateColor: '#d32f2f',
    scheduledUpdateTime: false,
    scheduledUpdateTimeCountdown: false,
});

class WarehouseContentWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.countdown = false;
        this.main = null;
    }

    componentDidMount = () => {
        this.setState({
            scrollHeight: this.props.scrollHeight,
            scheduledUpdateTime: this.props.ui.updateNotification
        }, () => {
            if(this.state.scheduledUpdateTime !== false) {
                this.startScheduledUpdateTime();
            }
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.ui.updateNotification !== prevProps.ui.updateNotification) {
            this.setState({
                scheduledUpdateTime: this.props.ui.updateNotification
            }, () => {                
                if(this.state.scheduledUpdateTime !== false) {
                    this.startScheduledUpdateTime();
                }
            });
        }        
        if(this.props.scrollHeight !== prevProps.scrollHeight){
            this.setState({
                scrollHeight: this.props.scrollHeight,
            });
        }
        if(this.props.pagePath !== prevProps.pagePath && this.main?.scrollTop){
            this.main.scrollTop = 0;
        }
    }

    componentWillUnmount = () => {
        if(this.countdown)          
            clearInterval(this.countdown);
    }
    
    startScheduledUpdateTime = () => {
        this.countdown = setInterval(this.getScheduledUpdateTimeCountdown, 1000);
    }

    getScheduledUpdateTimeCountdown = () => {
        var scheduled = moment(this.state.scheduledUpdateTime, 'X').subtract(10, 'seconds');
        const now = moment();
        if(now.unix() < scheduled.unix()) {
            const countdown = moment(scheduled - now);
            const mins = parseInt(countdown.format('m'));
            const countdownText = mins !== 0 ? countdown.format("m [ minutes] [and] s [ seconds]") : countdown.format("s[ seconds]");
            this.setState({                
                scheduledUpdateColor: mins > 1 ? '#F4511E' : '#d32f2f',
                scheduledUpdateTimeCountdown: this.state.updateNotification !== false ? countdownText : ''
            });
        } else {      
            if(this.countdown) {      
                clearInterval(this.countdown);
            }
            setLastActivePage(this.props.location.pathname);
            this.setState({                
                scheduledUpdateTimeCountdown: ''
            }, () => this.props.handleAppError("APP_UPDATE", ""))    
        }
    }

    scrollToTop = () => {
        if(this.main?.scrollTop)
            this.main.scrollTop = 0;
    }

    pageTitleLink = (idx, title, pageTitle) => {
        let { classes } = this.props,
            path, 
            defaultText, 
            // icon, 
            // iconType = 'light',
            link = idx <= pageTitle[0],
            showDefaultText = pageTitle.length === 2 ? true : false,
            separator = idx !== (pageTitle.length - 1) ? true : false,
            chevron = <FAIcon type="light" icon="chevron-right" className={classes.chevronIcon} />

        switch (title) {  
            default:
                path = '/dashboard';
                defaultText = false;
        }
        
        return (
            <React.Fragment key={idx}>
                {/* {idx === 1 && (
                    <Grid item>
                        <FAIcon type={iconType} icon={icon} className={classes.pageIcon} button={(path && link && true) || false} />
                    </Grid>
                )} */}
                {idx > 1 && (
                    (path && link && (
                        <>
                            <Grid item>
                                <Link 
                                    to={path} 
                                    style={{
                                        color: '#000',
                                        textDecoration: 'none'
                                    }}
                                >
                                    {title}
                                </Link>
                            </Grid>
                            {separator && (                            
                                <Grid item>
                                    {chevron}
                                </Grid>
                            )}
                            {showDefaultText && defaultText && (
                                <>
                                    <Grid item>
                                        {chevron}
                                    </Grid>
                                    <Grid item>
                                        {defaultText}
                                    </Grid>
                                </>
                            )}
                        </>
                    )) || (
                        <>
                            <Grid item>
                                {title}
                            </Grid>
                            {separator && (                            
                                <Grid item>
                                    {chevron}
                                </Grid>
                            )}
                            {showDefaultText && defaultText && (
                                <>
                                    <Grid item>
                                        {chevron}
                                    </Grid>
                                    <Grid item>
                                        {defaultText}
                                    </Grid>
                                </>
                            )}
                        </>
                    )
                )}
            </React.Fragment>
        )
    }

    render() {
        const { classes, changePath, page, pageTitle, ui } = this.props;
        const { scrollHeight, scheduledUpdateTime, scheduledUpdateColor, scheduledUpdateTimeCountdown } = this.state;
        const isMobile = ui.device.isMobile;
        return (
            <main ref={el => this.main = el} className={classes.content} style={{height: window.matchMedia('(display-mode: standalone)').matches ? `100vh` : `${scrollHeight}px`}}>
                <div className={classes.pwaBarSpacer} />
                <div className={classes.appBarSpacer} />
                <div className={classes.container}>
                    <AppBar color="secondary" position="fixed" elevation={0} className={classes.page}>
                        <Box mt={-0.5}>
                            <Grid container spacing={3} alignItems="center" className="mb-2">
                                {(scheduledUpdateTime !== false && scheduledUpdateTimeCountdown !== false && (
                                    <Grid item xs={8}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item style={{paddingTop: 7.5}}>
                                                <FAIcon type="light" icon='exclamation-triangle' size={isMobile ? 'small' : ''} style={{color: scheduledUpdateColor}} noMargin /> 
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='caption' style={{color : scheduledUpdateColor}}>
                                                    {(scheduledUpdateTimeCountdown !== '' && ( 
                                                        <>
                                                            {`An update ${!isMobile ? `for RED ` : ``} will begin in ${scheduledUpdateTimeCountdown} ${(!isMobile && (` - please save your work before the update starts!`)) || (``)}`}
                                                        </>
                                                    )) || (
                                                        <>
                                                            An update for RED is starting now...
                                                        </>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )) || (
                                    <Grid item xs={8}>
                                        <Grid container alignItems='center'>
                                            {_.map(page, (el, idx) => {
                                                if(idx >= 1) {                                    
                                                    return (
                                                        this.pageTitleLink(idx, el, page)
                                                    )
                                                }
                                            })}
                                        </Grid>
                                    </Grid> 
                                )}
                                <Grid item xs={4} align="right">
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs />
                                        <Grid item>
                                            <User size={20} static />
                                        </Grid>
                                        <Grid item>            
                                            <UserDetails small textDefault />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </AppBar>
                    <Box p={1.5}>
                        <WarehouseRouteSwitcher changePath={changePath} pageTitle={pageTitle} scrollToTop={this.scrollToTop} />
                    </Box>
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => ({
    notifications: state.notifications,
    ui: state.ui
})

const mapDispatchToProps = dispatch => ({
    handleAppError: (error, message) => dispatch(handleAppError(error, message))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WarehouseContentWrapper));