import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { currencyFormat } from 'Functions/MiscFunctions';

const Totals = ({classes, carriageId, carriageTotal, aaVatPercent, grossTotal, netTotal, subTotal, vatTotal, inForm}) => (
    <Table>
        <TableBody>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        Subtotal
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} style={{width: inForm ? '45%' : '1%'}} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {currencyFormat.format(subTotal)}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        Delivery
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {(((inForm && carriageId === "") || (inForm && subTotal === "0.00")) ? "-" : (carriageTotal !== "0.00" ? `${currencyFormat.format(carriageTotal)}` : 'Free')) || '-'}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="subtitle1" className="fw-500">
                        Net Total
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="subtitle1" className="fw-500">
                        {currencyFormat.format(netTotal)}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        VAT {aaVatPercent ? `(${aaVatPercent}%)` : ''}
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {(vatTotal > 0.00 && currencyFormat.format(vatTotal)) || '-'}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" className={`${classes.td} ${inForm ? '' : 'pr-2'}`}>
                    <Typography variant="body2" className='fw-400'>
                        Gross Total
                    </Typography>
                </TableCell>
                <TableCell className={classes.td} align={inForm ? undefined : 'right'}>
                    <Typography variant="body2">
                        {currencyFormat.format(grossTotal)}
                    </Typography>
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
)

export default Totals;