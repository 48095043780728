import React, { useEffect, useState } from 'react';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActivityLog from 'Components/Common/Activity/ActivityLog';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import TaskForm from 'Components/Common/Activity/TaskForm';
import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DataTable from 'Components/Common/DataTables/DataTable';
import DocumentForm from 'Components/Common/Forms/DocumentForm';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import QuoteSummary from 'Components/Quotes/Misc/QuoteSummary';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import QuoteLost from 'Components/Quotes/Misc/QuoteLost';
import ActionChip from 'Components/Common/Chips/ActionChip';
import InsightChip from 'Components/Common/Chips/InsightChip';
import SearchApprovalsTable from 'Components/Quotes/ViewQuote/SearchApprovalsTable';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import Textarea from 'Components/Common/Inputs/Textarea';
import Tile from 'Components/Common/Tiles/Tile';
import ViewActions from 'Components/Common/Activity/ViewActions';
import ViewCallLogs from 'Components/Common/Activity/ViewCallLogs';
import ViewDocuments from 'Components/Common/Activity/ViewDocuments';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import ViewPage from 'Components/Common/Styles/ViewPage';
import WildixContext from "Context/WildixContext";
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { currencyFormat, handleTabChange, isTablet, TabPanel } from 'Functions/MiscFunctions';
import { handleChange, handleSelectChange } from 'Functions/FormFunctions';
import { isNumeric, toggleDialog } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';
import { Hidden } from '@material-ui/core';
import BooleanTile from 'Components/Common/Tiles/BooleanTile';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RequestQuoteApproval = ({handleSubmit}) => {

    const   dispatch                = useDispatch(),
            [comments,setComments]  = useState('');

    return (
        <Box mt={1.5} pt={2}> 
            <Textarea
                label="Comments:"
                onChange={e => setComments(e?.target?.value ?? '')}
                rows={2}
                value={comments}
                variant="outlined"
            />
            <div className="buttonRow">
                <Button
                    color="primary"
                    onClick={() => dispatch(closeDialog())}
                    variant="text"
                >
                    <FAIcon icon="times" button />
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to submit this quote for approval?`, () => handleSubmit(comments)))}
                    variant="text"
                >
                    <FAIcon icon="check" button />
                    Send Request
                </Button>
            </div>
        </Box>
    )

}

const ReviewQuoteApproval = ({quote, handleSubmit}) => {

    const   staff                           = useSelector(state => state.notifications.status),
            dispatch                        = useDispatch(),
            [decision ,setDecision]         = useState(''),
            [mgtComments, setMgtComments]   = useState(''),
            [comments, setComments]         = useState('');

    useEffect(() => {
        if(decision === "approve") {
            if(quote.outstanding_approval?.qa_rc_decision === 'With Terms') {
                setComments(`This order will be subject to an upfront payment of ${currencyFormat.format(quote.outstanding_approval?.qa_prepay_value)} (${quote.outstanding_approval?.qa_prepay_percent}%)`)
            }
        }
    }, [decision, quote])

    return (
        <Box mt={1.5} pt={1}>
            <Box className='content-light-grey' p={3} pt={2} pb={2} mb={3}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <Typography variant="body2" className="fw-400">
                            Customer - {quote?.customer?.cust_company_name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="View Customer Record (In New Tab)">
                            <IconButton
                                onClick={() => window.open(`/customers/${quote?.customer?.cust_id}`)}
                            >
                                <FAIcon icon="external-link" size={12.5} noMargin button />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
             <Box p={3} mb={3} className='content-light-grey'>
                <Typography variant="body2" className="fw-400" gutterBottom>
                    Approval Request
                </Typography>
                <Typography variant="caption">
                    Completed by {staff?.[quote.outstanding_approval?.qa_req_staff_id]?.name ?? 'Unknown'} on {moment(quote.outstanding_approval?.qa_created_datetime, "YYYY-MM-DD HH:mm:ss").format("LLLL")}
                </Typography>
                {!_.isEmpty(quote.outstanding_approval?.qa_req_comments) && (
                    <>
                        <Typography variant="body2" className="fw-400 mt-2" gutterBottom>
                            Comments
                        </Typography>
                        <Typography variant="caption">
                            {_.map(quote.outstanding_approval?.qa_req_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)}
                        </Typography>
                    </>
                )}
            </Box>
            {quote.outstanding_approval?.credit_report && (
                <Box p={3} mb={3} className={quote.outstanding_approval?.credit_report?.rc_status === 'Good' || quote.outstanding_approval?.credit_report?.rc_status === 'Satisfactory' ? 'content-light-success' : 'content-light-error'}>
                    <Typography variant="body2" className="fw-400" gutterBottom>
                        Credit Report Check
                    </Typography>
                    <Typography variant="caption">
                        Completed by {staff?.[quote?.outstanding_approval?.credit_report?.rc_actioned_staff_id]?.name ?? 'Unknown'} on {moment(quote.outstanding_approval?.credit_report?.rc_created_datetime, "YYYY-MM-DD HH:mm:ss").format("LLLL")}
                    </Typography>
                    <Box mt={2}>
                        <InsightChip
                            label={quote.outstanding_approval?.credit_report?.rc_status}
                            icon={quote.outstanding_approval?.credit_report?.rc_status === 'Good' || quote.outstanding_approval?.credit_report?.rc_status === 'Satisfactory' ? 'check' : 'times'}
                            background={quote.outstanding_approval?.credit_report?.rc_status === 'Good' || quote.outstanding_approval?.credit_report?.rc_status === 'Satisfactory' ? '#4CAF50' : '#f44336'}
                            color='#fff'
                            paddingLeft={8}
                            marginRight={8}
                        />
                        <InsightChip
                            label={quote.outstanding_approval?.qa_rc_decision === 'Yes' ? 'Acceptable On Account' : (quote.outstanding_approval?.qa_rc_decision === 'No' ? 'Not Acceptable On Account' : 'Acceptable (Subject To Terms)')}
                            icon={quote.outstanding_approval?.qa_rc_decision === 'Yes' ? 'check' : (quote.outstanding_approval?.qa_rc_decision === 'No' ? 'times' : 'exclamation-triangle')}
                            background={quote.outstanding_approval?.qa_rc_decision === 'Yes' ? '#4CAF50' : (quote.outstanding_approval?.qa_rc_decision === 'No' ? '#f44336' : '#F57C00')}
                            color='#fff'
                            paddingLeft={8}
                            marginRight={8}
                        />
                        {quote.outstanding_approval?.qa_rc_decision === 'With Terms' && (
                            <Box mt={1.5}>
                                {quote.outstanding_approval?.qa_prepay_percent !== 0 && (
                                    <InsightChip
                                        label={`${quote.outstanding_approval?.qa_prepay_percent} Upfront`}
                                        icon='percent'
                                        background='#f44336'
                                        color='#fff'
                                        paddingLeft={8}
                                        marginRight={8}
                                    />
                                )}
                                {quote.outstanding_approval?.qa_prepay_value !== "0.00" && (
                                    <InsightChip
                                        label={`${currencyFormat.format(quote.outstanding_approval?.qa_prepay_value).replace('£','')} Upfront`}
                                        icon='pound-sign'
                                        background='#f44336'
                                        color='#fff'
                                        paddingLeft={8}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                    {!_.isEmpty(quote.outstanding_approval?.credit_report?.rc_comments) && (
                        <Box mt={3}>
                            <Typography variant="body2" className="fw-400" gutterBottom>
                                Comments
                            </Typography>
                            <Typography variant="caption">
                                {_.map(quote.outstanding_approval?.credit_report?.rc_comments.split("\n"), (r, idx) => <div key={idx}>{r}</div>)}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
            <Grid container spacing={3}>
                <Grid item xs={12} lg={quote.outstanding_approval?.qa_rc_id === 0 ? 4 : 6}>
                    <ToggleButton 
                        onClick={() => setDecision("approve")}
                        selected={decision === "approve"}
                        style={{width: '100%', height: 125}} 
                        value="approve"
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <FAIcon className="textSuccess" type="light" icon="check-circle" size={25} button />
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={1}>
                                    <Typography className="fw-400" variant="body2">
                                        Approve Quote
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </ToggleButton>
                </Grid>
                {quote.outstanding_approval?.qa_rc_id === 0 && (
                    <Grid item xs={12} lg={4}>
                        <ToggleButton 
                            onClick={() => setDecision("refer")}
                            selected={decision === "refer"}
                            style={{width: '100%', height: 125}} 
                            value="refer"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <FAIcon className="textWarning" type="light" icon="exclamation-circle" size={25} button />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        <Typography className="fw-400" variant="body2">
                                            Refer to Accounts
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ToggleButton>
                    </Grid>
                )}
                <Grid item xs={12} lg={quote.outstanding_approval?.qa_rc_id === 0 ? 4 : 6}>
                    <ToggleButton
                        onClick={() => setDecision("decline")}
                        selected={decision === "decline"}
                        style={{width: '100%', height: 125}} 
                        value="decline"
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <FAIcon className="textError" type="light" icon="times-circle" size={25} button />
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={1}>
                                    <Typography className="fw-400" variant="body2">
                                        Decline Quote
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </ToggleButton>
                </Grid>
                {decision !== '' && (
                    <>
                        {decision !== 'refer' && (
                            <Grid item xs={12}>
                                <Textarea
                                    label="Management Comments"
                                    onChange={e => setMgtComments(e?.target?.value ?? '')}
                                    rows={2}
                                    value={mgtComments}
                                    variant="outlined"
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Textarea
                                label={decision === 'refer' ? 'Request Comments' : 'Comments'}
                                onChange={e => setComments(e?.target?.value ?? '')}
                                rows={2}
                                value={comments}
                                variant="outlined"
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <div className="buttonRow">
                <Button
                    color="primary"
                    onClick={() => dispatch(closeDialog())}
                    variant="text"
                >
                    <FAIcon icon="times" button />
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={decision === ''}
                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to ${decision === 'approve' ? 'approve this quote?' : (decision === 'decline' ? 'decline this quote?' : 'check with accounts?')}`, () => handleSubmit(decision, mgtComments, comments)))}
                    variant="text"
                >
                    <FAIcon icon="check" button />
                    Confirm
                </Button>
            </div>
        </Box>
    )

}

const getLegacyQuotationPDF = (quoteId) => {
    API.get('/quotes/' + quoteId + '/legacy/pdf')
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const initialState = {
    access: {
        addCallLog: false,
        addNote: false,
        closeQuote: false,
        openQuote: false,
        orderQuote: false,
        reQuote: false,
        updateQuote: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        viewCustomer: false,
        quoteApproval: false
    },
    initialAccess: {
        addCallLog: false,
        addNote: false,
        closeQuote: false,
        openQuote: false,
        orderQuote: false,
        reQuote: false,
        updateQuote: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        viewCustomer: false,
        quoteApproval: false
    },
    confirmationOpen: {
        openQuote: false
    },
    currentTab: 0,
    dialog: {
        addCallLog: false,
        addNote: false,
        closeQuote: false,
    },
    key: uuidv4(),
    revision: false,
    pdf: {
        numPages: '',
        pageNumber: 1,
        scale: isTablet() ? 0.75 : 1.5
    },
    quote: {},
    quoteId: 0,
    isLoading: true,
    viewing: {
        list: [],
        update: false,
    }
}

class ViewQuote extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props)
        this.state = initialState
        this.mainContentArea = React.createRef();
        this.handleChange = handleChange.bind(this)
        this.handleSelectChange = handleSelectChange.bind(this)
        this.toggleDialog = toggleDialog.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
        this.poll = false;
    }

    componentDidMount = () => {

        API.multiAccess([
            'view-quote:add-call',
            'view-quote:add-note',
            'view-quote:close-quote',
            'view-quote:open-quote',
            'view-quote:order-quote',
            'replicate-quote',     
            'update-quote',     
            'view-quote:add-document',     
            'view-quote:modify-document',     
            'view-quote:delete-document',
            'view-customer',
            'awaiting-approval-quotes:process'
        ])
        .then(([
            addCallLog, 
            addNote, 
            closeQuote, 
            openQuote, 
            orderQuote, 
            reQuote,
            updateQuote, 
            uploadDoc, 
            modifyDoc, 
            deleteDoc,
            viewCustomer,
            quoteApproval
        ]) =>  {
            const quoteId = this.props.match.params.id;
            this.setState({
                access: {
                    addCallLog,
                    addNote,
                    closeQuote,
                    openQuote,
                    orderQuote,
                    reQuote,
                    updateQuote,
                    uploadDoc,
                    modifyDoc,
                    deleteDoc,
                    viewCustomer,
                    quoteApproval
                },
                initialAccess: {
                    addCallLog,
                    addNote,
                    closeQuote,
                    openQuote,
                    orderQuote,
                    reQuote,
                    updateQuote,
                    uploadDoc,
                    modifyDoc,
                    deleteDoc,
                    viewCustomer,
                    quoteApproval
                },
                quoteId
            }, () => {
                this.poll = setInterval(this.handlePoll, 5000);
                this.loadComponentData()
            });
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match?.params?.id !== this.props.match?.params?.id) {
            this.setState({
                currentTab: 0,
                quoteId: this.props.match.params.id
            }, () => {            
                this.setState({
                    isLoading: true
                }, () => {
                    this.loadComponentData();
                })
            });
        }
    }
    
    componentWillUnmount = () => {
        if(this.poll) {
            clearInterval(this.poll);
        }
    }

    handlePoll = () => {

        if(!document.hidden) {

            API.post(`/staff/my/view`, 
                { 
                    quote: this.state.quoteId,
                    type: 'View'
                }, 
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true
                    }
                }
            )
            .then(res => {
                const { viewing } = this.state;
                if(res?.data) {
                    const list = res.data;
                    if(JSON.stringify(list) !==  JSON.stringify(viewing.list)) {
                        const update = _.some(list, el => el.u === 1);
                        this.setState({
                            viewing: {
                                list,
                                update
                            }
                        }, () => {
                            if(viewing.update && !update) {
                                const view = _.find(viewing.list, el => el.u === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished updating this quote`);
                                }
                                this.loadComponentData();
                            }
                        })
                    }
                }
            })

        }
        
    }

    loadComponentData = () => { 
        API.get(`/quotes/${this.state.quoteId}`)
        .then(result => {
            if(result?.data && !result?.data?.errors) {
                const   { initialAccess }   =   this.state,
                        isLoading           =   false,
                        quote               =   result.data,
                        revision            =   result?.data?.quote_latest === 1 ? false : true,
                        key                 =   uuidv4();

                this.setState({
                    access: {
                        ...this.state.access,
                        addCallLog: revision ? false : initialAccess.addCallLog,
                        addNote: revision ? false : initialAccess.addNote,
                        closeQuote: revision ? false : initialAccess.closeQuote,
                        openQuote: revision ? false : initialAccess.openQuote,
                        orderQuote: revision ? false : initialAccess.orderQuote,
                        updateQuote: revision ? false : initialAccess.updateQuote,
                        uploadDoc: revision ? false : initialAccess.uploadDoc,
                        modifyDoc: revision ? false : initialAccess.modifyDoc,
                        deleteDoc: revision ? false : initialAccess.deleteDoc
                    },
                    isLoading,
                    key,
                    revision,
                    quote
                }, () => {

                    if(this.props.pageTitle) {
                        this.props.pageTitle([1, "Quotes", quote.customer?.cust_company_name, `#${result.data.quote_reference}`])
                    }

                    let fromNewOrderPage = this.props.location?.state?.new ?? false;

                    if(fromNewOrderPage) {
                        this.props.history.replace({state: { new: false }})
                        if(quote.quote_status === 'Open' && !_.isEmpty(quote.quote_email)) {
                            this.props.deployConfirmation("Would you like to e-mail this quote to the customer now?", this.handleDeploySendEmail);
                        } else if(quote.quote_status !== 'Open') {
                            this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">This quote requires approval before it can be sent to the customer</Typography><Typography variant="body2">Would you like to submit this quote for approval now?</Typography></>, this.handleDeployQuoteApproval);
                        }
                    }
                    
                })
            } else {
                if(this.props.history) {
                    this.props.history.push('/quotes');
                }
            }
        })
    }

    handleConfirmationOpen = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: true,
            } 
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        }, () => {
            if(type === 'openQuote') {
                this.reOpenQuote();
            }
        });
    }

    reOpenQuote = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.put(`/quotes/${this.state.quoteId}/open`)
            .then(res => {
                if(res.data) {
                    this.loadComponentData();
                }
            })
        })
    }

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.quoteId}
            relation="quote"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
            standardOnly
            withFao
            />, true, "", "standard","sm", false, true);

    handleDeployAddCall = () => this.props.deployDialog(
        <AddCallLogForm
            id={this.state.quoteId}
            relation="quote"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","xs", false, true);

    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            id={this.state.quoteId}
            relation="quote"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","sm", false, true);
        
    handleDeployCloseQuote = () => this.props.deployDialog(
        <QuoteLost
            id={this.state.quoteId}
            relation="quote"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, false, "Close Quote", "standard","xs", false, true);

    handleToggleHotQuote = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.put(`/quotes/${this.state.quoteId}/hot`)
            .then(res => {
                if(res.data) {
                    this.props.deploySnackBar('success', 'You have successfully updated the "hot" quote marker for this quote')
                    this.loadComponentData();
                }
            })
        })
    }
    handleDeployFileUpload = () => this.props.deployDialog(
        <DocumentForm 
            id={this.state.quoteId} 
            type="quote" 
            category="Quotes"
            callback={this.loadComponentData} 
            cancel={this.props.closeDialog} 
        />, false, "File Upload", "standard","xs", false, true);

    handleDeploySendEmail = () => {
        let defaultFormData = {
            emailSubject: `Advanced Access | Quote #${this.state.quote.quote_reference}/${this.state.quote.quote_revision}${!_.isEmpty(this.state.quote.quote_customer_reference) ? ` (${this.state.quote.quote_customer_reference})` : ''}`,
            emailTo: {
                label: this.state.quote.quote_email,
                value: this.state.quote.quote_email
            }
        }
        this.props.deployDialog(
            <EmailDialog
                callback={this.loadComponentData}
                id={this.state.quoteId}
                type='quote'
                success='The quote has been successfully sent'
                defaultFormData={defaultFormData}
            />, false, "Send Quote", "standard","md", false, true
        );
    }

    handleDeployQuoteApproval = () => this.props.deployDialog(
        <RequestQuoteApproval 
            handleSubmit={this.handleSubmitApprovalRequest}
        />, false, "Request Quote Approval", "success","sm", false, true);

    handleDeployReviewQuoteApproval = () => this.props.deployDialog(
        <ReviewQuoteApproval
            quote={this.state.quote}
            handleSubmit={this.handleSubmitApprovalReview}
        />, false, "Review Quote Approval", "success","sm", false, true);
    
    handleDeployViewCustomer = () => this.props.history.push(`/customers/${this.state.quote?.customer?.cust_id}`);

    handleSubmitApprovalRequest = comments => {
        API.post(`/quotes/${this.state.quote?.quote_id}/approval`, { comments })
        .then(res => {
            if(res?.data) {
                this.props.deploySnackBar("success", "You have successfully sent this quote for approval");
                this.loadComponentData();
                this.props.closeDialog();
            }
        })
    }

    handleSubmitApprovalReview = (decision, mgtComments, comments) => {
        API.put(`/quotes/${this.state.quote?.quote_id}/approval`, { decision, mgtComments, comments })
        .then(res => {
            if(res?.data) {
                this.props.deploySnackBar("success", `You have successfully ${decision === 'approve' ? 'approved this quote' : (decision === "refer" ? 'requested credit report information from accounts' : 'declined this quote')}`);
                this.loadComponentData();
                this.props.closeDialog();
            }
        })
    }

    handlePdfLoad = ({numPages}) => {
        this.setState({
            pdf: {
                ...this.state.pdf,
                numPages
            }
        })
    }

    handlePdfPagination = offset => {
        this.setState({
            pdf: {
                ...this.state.pdf,
                pageNumber: ((this.state.pdf?.pageNumber ?? 1) + offset)
            }
        })
    }

    handlePdfScale = zoom => {
        let scale = this.state.pdf?.scale ?? 1;
        if(isNumeric(zoom)) {
            scale = zoom;
        } else {
            if(zoom) {
                scale = (parseFloat(scale) + parseFloat(0.25)).toFixed(2)
            } else {
                scale = (parseFloat(scale) - parseFloat(0.25)).toFixed(2)
            }
        }
        this.setState({
            pdf: {
                ...this.state.pdf,
                scale
            }
        })
    }

    render = () => {  
        const { classes, staff, ui } = this.props;
        const { access, confirmationOpen, currentTab, isLoading, key, pdf, quote, revision, viewing } = this.state;
        const canDial = this.context?.dial === null;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);

        let     quoteCreated, 
                quoteExpiry, 
                quoteValidCheck,
                quoteValid          = true,
                quoteAge            = null,
                alert               = null;

        if(!isLoading) {
            quoteCreated = moment(quote.quote_created_datetime);
            quoteExpiry = moment(quote.quote_created_datetime).add(30, "days");
            quoteValidCheck = quoteExpiry.diff(moment(), 'days');
            quoteAge = quoteCreated.diff(moment(), 'days');
            if(quote.quote_status === 'Draft' || quote.quote_status === 'Open') {
                if(quoteValidCheck < 0) {
                    quoteValid = false;
                }
            }
            quoteCreated = quoteCreated.format("DD/MM/YY");
            quoteExpiry = quoteExpiry.format("DD/MM/YY");
        }

        if(quote?.customer?.status?.status_parent_id === 4) {
            alert = `${quote?.customer?.cust_company_name}'s account has been closed, this quote cannot currently be ordered`
        }
        
        if(quote.customer?.cust_prospect === 1) {
            alert = `This quote is for a prospective customer and cannot be ordered until an account is opened`
        }
        
        if(quote.quote_status === "Open" && !quoteValid) {
            alert = `This quote was raised ${Math.abs(quoteAge)} days ago`
        }

        if(quote.quote_status === "Lost") {
            alert = `Quote Lost - ${quote?.reason?.qr_reason ?? 'No reason given'}`
        }

        if(quote.quote_order_modified === 1) {
            alert = `An order was placed that differs from this quote`
        }

        if(quote.quote_status === 'Draft') {
            // alert = (
            //     <Grid container alignItems='center'>
            //         <Grid item>
            //             This quote requires approval before it can be sent to the customer
            //         </Grid>
            //         <Grid item>
            //             <Box ml={3}>
            //                 <Button
            //                     onClick={this.handleDeployQuoteApproval}
            //                     size="small"
            //                     style={{height: 22.5}}
            //                     variant="text"
            //                 >
            //                     <FAIcon icon="check" button />
            //                     Request Approval
            //                 </Button>
            //             </Box>
            //         </Grid>
            //         <Grid item>
            //             <Box ml={3}>
            //                 <Button
            //                     onClick={() => this.props.history.push(`/quotes/update/${quote.quote_id}`)}
            //                     size="small"
            //                     style={{height: 22.5}}
            //                     variant="text"
            //                 >
            //                     <FAIcon icon="pencil" size={16} button />
            //                     Modify Quote
            //                 </Button>
            //             </Box>
            //         </Grid>
            //     </Grid>   
            // )
            alert = (
                <Grid container alignItems='center'>
                    <Grid item>
                        <b>This quote has been requested via the Installer Portal - please review notes (additional details / requirements) and modify as required, before e-mailing it.</b>
                    </Grid>
                    <Grid item>
                        <Box ml={3}>
                            <Button
                                onClick={() => this.props.history.push(`/quotes/update/${quote.quote_id}`)}
                                size="small"
                                style={{height: 22.5}}
                                variant="text"
                            >
                                <FAIcon icon="pencil" size={16} button />
                                Modify Quote
                            </Button>
                        </Box>
                    </Grid>
                </Grid>   
            )
        }

        if(quote.quote_status === 'Awaiting Approval') {
            if(access.quoteApproval) {
                alert = (
                    <Grid container alignItems='center'>
                        {(quote?.outstanding_approval?.qa_status !== 'Awaiting Credit Report' && (
                            <>
                                <Grid item>
                                    {(quote?.outstanding_approval?.qa_rc_decision === 'Not Applicable' && (
                                        `This quote has been submitted for approval${quote.quote_review_staff_id > 0 && staff[quote.quote_review_staff_id] ? ` and is under review by ${staff[quote.quote_review_staff_id]?.name}` : ``}`
                                    )) || (
                                        `This quote is ready for an approval decision - credit report information is now available. ${quote.quote_review_staff_id > 0 && staff[quote.quote_review_staff_id] ? `Under review by ${staff[quote.quote_review_staff_id]?.name}` : ``}`
                                    )}
                                </Grid>
                                <Grid item>
                                    <Box ml={3}>
                                        <Button
                                            onClick={this.handleDeployReviewQuoteApproval}
                                            size="small"
                                            style={{height: 22.5}}
                                            variant="text"
                                        >
                                            <FAIcon icon="check" button />
                                            Review Quote
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                        )) || (
                            <Grid item>
                                This quote has been referred to accounts for approval
                            </Grid>
                        )}
                    </Grid>   
                )
            } else {
                alert = `This quote has been submitted for approval and is currently under review${quote.quote_review_staff_id > 0 && staff[quote.quote_review_staff_id] ? `by ${staff[quote.quote_review_staff_id]?.name}` : ``}`
            }
        }

        const phoneNumber = !_.isEmpty(quote.quote_phone) ? quote.quote_phone : !_.isEmpty(quote.customer_contact?.contact_telephone) ? quote.customer_contact?.contact_telephone : !_.isEmpty(quote.customer?.cust_phone) ? quote.customer?.cust_phone : null;
        
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" component={quote.quote_status === 'Lost' || quote.quote_status === 'Declined' || revision ? 'strike' : undefined} className="textDefault">
                                                            Quote #{quote.quote_reference}{!_.isEmpty(quote.quote_customer_reference) ? ` (${quote.quote_customer_reference})` : ''}
                                                            {/* Quote #{quote.quote_reference}/{quote.quote_revision}{!_.isEmpty(quote.quote_customer_reference) ? ` (${quote.quote_customer_reference})` : ''} */}
                                                        </Typography>  
                                                    </Grid>                                                    
                                                    {phoneNumber && canDial ? (
                                                        <Grid item>
                                                            <Tooltip title={`Dial ${phoneNumber}`}>
                                                                <IconButton onClick={() => this.context.useDial(phoneNumber)} size="small">
                                                                    <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={27.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                            {!revision && (
                                                <Grid item>
                                                    <Tooltip title="Add Action" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddTask()}>
                                                            <FAIcon type="light" icon="layer-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon type="light" icon="comment-alt-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addCallLog && (
                                                <Grid item>
                                                    <Tooltip title="Log Call" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddCall()}>
                                                            <FAIcon type="light" icon="phone-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.uploadDoc && quote.quote_status !== 'Ordered' && (
                                                <Grid item>
                                                    <Tooltip title="Upload File" placement="top">
                                                        <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                            <FAIcon type="light" icon="file-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title="E-mail Quote" placement="top">
                                                    <IconButton disabled={quote.quote_status === 'Draft' || quote.quote_status === 'Awaiting Approval' || quote.quote_status === 'Declined' || quote.quote_status === 'Lost'} onClick={() => this.handleDeploySendEmail()}>
                                                        <FAIcon disabled={quote.quote_status === 'Draft' || quote.quote_status === 'Awaiting Approval' || quote.quote_status === 'Declined' || quote.quote_status === 'Lost'} type="light" icon="envelope-open-text" button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Download PDF" placement="top">
                                                    <IconButton disabled={quote.quote_status === 'Draft' || quote.quote_status === 'Awaiting Approval' || quote.quote_status === 'Declined' || quote.quote_status === 'Lost'} onClick={() => quote.quote_legacy === 1 ? getLegacyQuotationPDF(quote.quote_id) : getPresignedUrl(quote.quote_pdf_filename)}>
                                                        <FAIcon type="light" icon="file-pdf" style={{color: quote.quote_status === 'Draft' || quote.quote_status === 'Awaiting Approval' || quote.quote_status === 'Declined' || quote.quote_status === 'Lost' ? 'rgba(0, 0, 0, 0.26)' : '#d32f2f'}} button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {access.orderQuote && (quote.quote_status === 'Open' && quote.quote_requires_approval === 0) && !viewing.update && quote.customer.cust_prospect === 0 && (
                                                <Grid item>
                                                    <Tooltip title="Place Order" placement="top">
                                                        <IconButton disabled={quote?.customer?.status?.status_parent_id === 4} onClick={() => this.props.history.push(`/quotes/order/${quote.quote_id}`)}>
                                                            <FAIcon type="light" icon="check" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "layer-plus",
                                                            label: "Add Action",
                                                            onClick: () => this.handleDeployAddTask(),
                                                            display: !revision
                                                        },
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote
                                                        },
                                                        {
                                                            icon: "phone-plus",
                                                            label: "Log Call",
                                                            onClick: () => this.handleDeployAddCall(),
                                                            display: access.addCallLog
                                                        },
                                                        {
                                                            icon: "file-plus",
                                                            label: "Upload File",
                                                            onClick: () => this.handleDeployFileUpload(),
                                                            display: access.uploadDoc && quote.quote_status !== 'Ordered'
                                                        },
                                                        {
                                                            icon: "envelope-open-text",
                                                            label: "E-mail Quote",
                                                            onClick: () => this.handleDeploySendEmail(),
                                                            display: true,
                                                            disabled: quote.quote_status === 'Draft' || quote.quote_status === 'Awaiting Approval' || quote.quote_status === 'Declined' || quote.quote_status === 'Lost'
                                                        },
                                                        {
                                                            icon: "file-pdf",
                                                            label: "Download PDF",
                                                            onClick: () => quote.quote_legacy === 1 ? getLegacyQuotationPDF(quote.quote_id) : getPresignedUrl(quote.quote_pdf_filename),
                                                            display: true,
                                                            divider: true,
                                                            disabled: quote.quote_status === 'Draft' || quote.quote_status === 'Awaiting Approval' || quote.quote_status === 'Declined' || quote.quote_status === 'Lost'
                                                        },
                                                        {
                                                            icon: "times",
                                                            label: "Close Quote",
                                                            onClick: () => this.handleDeployCloseQuote(),
                                                            display: access.closeQuote && (quote.quote_status === 'Draft' || quote.quote_status === 'Open'),
                                                            disabled: viewing.update || quote.quote_status === 'Awaiting Approval'
                                                        },
                                                        {
                                                            icon: "pencil",
                                                            label: "Modify Quote",
                                                            onClick: () => this.props.history.push(`/quotes/update/${quote.quote_id}`),
                                                            display: access.updateQuote && (quote.quote_status === 'Draft' || quote.quote_status === 'Open'),
                                                            disabled: viewing.update || quote.quote_status === 'Awaiting Approval'
                                                        },
                                                        {
                                                            icon: "fire",
                                                            label: quote.quote_hot === 1 ? 'Remove "Hot" Quote Marker' : 'Set "Hot" Quote Marker',
                                                            onClick: () => this.handleToggleHotQuote(),
                                                            display: access.updateQuote && (quote.quote_status === 'Draft' || quote.quote_status === 'Open'),
                                                            disabled: viewing.update || quote.quote_status === 'Awaiting Approval'
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: "Open Quote",
                                                            onClick: () => this.handleConfirmationOpen('openQuote'),
                                                            display: access.openQuote && quote.quote_status === 'Lost',
                                                            disabled: viewing.update
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: "Place Order",
                                                            onClick: () => this.props.history.push(`/quotes/order/${quote.quote_id}`),
                                                            display: quote.customer.cust_prospect !== 1 && access.orderQuote && (quote.quote_status === 'Open' && quote.quote_requires_approval === 0),
                                                            disabled: viewing.update || quote?.customer?.status?.status_parent_id === 4
                                                        },
                                                        {
                                                            icon: "redo",
                                                            label: "Re-quote",
                                                            onClick: () => this.props.history.push(`/quotes/replicate/${quote.quote_id}`),
                                                            display: access.reQuote,
                                                            disabled: quote?.customer?.status?.status_parent_id === 4 || quote.quote_status === 'Draft'
                                                        },
                                                        {
                                                            icon: "users",
                                                            label: "View Customer",
                                                            onClick: () => this.handleDeployViewCustomer(),
                                                            display: access.viewCustomer
                                                        }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item xs={10} md={8}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="external-link"
                                                                label={`Customer: ${quote.customer?.cust_company_name}${quote.customer?.cust_on_hold === 1 ? ` [ON HOLD]` : ``}`}
                                                                disabled={!access.viewCustomer}
                                                                onClick={() => this.handleDeployViewCustomer()}
                                                                paddingLeft={8}
                                                                tooltip="View Customer"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="info-circle"
                                                                label={`Status: ${revision ? 'Revised' : quote.quote_status}`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="pound-sign"
                                                                label={`Net Total: ${currencyFormat.format(quote.quote_total_net)}`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="pound-sign"
                                                                label={`Gross Total: ${currencyFormat.format(quote.quote_total_gross)}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={2} justify="flex-end">
                                                            <Grid item>
                                                                <ActionChip action={{u: 0, p: 0}} staff={staff[this.props.loggedInStaff.id]} page="quote" />
                                                            </Grid>
                                                            {_.map(viewing.list, (view, idx) => {
                                                                if(staff[view.i]) {
                                                                    return (
                                                                        <Grid item key={idx}>
                                                                            <ActionChip action={view} staff={staff[view.i]} page="quote" />
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Grid item xs={2} align='right'>
                                                        <ActionChip staff={{name: `${(_.size(viewing.list) + 1)} viewing`}} page="quote" />
                                                    </Grid>
                                                </Hidden>
                                            </Grid> 
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    {alert && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <Typography variant="body2">
                                                        {alert}
                                                    </Typography>
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={8}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={12} md={4}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                        <Heading text="Financials" />
                                                                            <Grid container spacing={2}>   
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pound-sign"
                                                                                        title="Subtotal"
                                                                                        content={`${currencyFormat.format(quote.quote_total_sub)}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="minus"
                                                                                        title="Discount"
                                                                                        content={`${currencyFormat.format(quote.quote_total_discount)}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="plus"
                                                                                        title="Delivery"
                                                                                        content={`${currencyFormat.format(quote.quote_total_carriage)}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Box pt={0.4} pb={0.4}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="pound-sign"
                                                                                            title="Net Total"
                                                                                            content={`${currencyFormat.format(quote.quote_total_net)}`}
                                                                                        />
                                                                                    </Box>
                                                                                </Grid> 
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="plus"
                                                                                        title="VAT"
                                                                                        content={`${currencyFormat.format(quote.quote_total_vat)}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pound-sign"
                                                                                        title="Gross Total"
                                                                                        content={`${currencyFormat.format(quote.quote_total_gross)}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="percent"
                                                                                        title="Estimated Margin"
                                                                                        content={`${parseFloat(quote.quote_total_margin).toFixed(2)}%`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                           
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="Blanket Quote Discount"
                                                                                        content={`${quote.quote_blanket_discount > 0 ? `${parseFloat(quote.quote_blanket_discount).toFixed(2)}%` : `Not Used`}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="Payment Terms"
                                                                                        content={quote?.payment_terms?.pt_name ?? 'Unknown'}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Opportunity" />
                                                                            <Grid container spacing={3}>    
                                                                                <Grid item xs={12} lg={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="circle"
                                                                                        color={quote.qt_color ?? '#000'}
                                                                                        title={quote.qt_label ? `${currencyFormat.format(quote.qt_min)} - ${currencyFormat.format(quote.qt_max)}` : `Net Total`}
                                                                                        content={quote.qt_label ? quote.qt_label : currencyFormat.format(quote.quote_total_net)}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>                                                           
                                                                                    <Tile
                                                                                        color={quote.quote_hot ? '#FB8C00' : '#000'}
                                                                                        noPaper
                                                                                        icon="fire"
                                                                                        title='Hot Quote'
                                                                                        content={quote.quote_hot ? 'Yes' : 'No'}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Status" />
                                                                            <Grid container spacing={3}>    
                                                                                <Grid item xs={12} lg={6}>      
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Quote Status"
                                                                                        content={quote.quote_status}
                                                                                        isTrue={quote.quote_status === 'Open' || quote.quote_status === 'Ordered'}
                                                                                    />     
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>    
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Approval Status"
                                                                                        content={quote.quote_requires_approval ? 'Approval Required' : (quote.quote_status === 'Declined' ? 'Declined' : 'Approved')}
                                                                                        isTrue={!quote.quote_requires_approval}
                                                                                    />     
                                                                                </Grid>  
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        isTrue
                                                                                        noPaper
                                                                                        title="Expected Order Date"
                                                                                        content={quote.quote_expected_order_date !== "0000-00-00" ? moment(quote.quote_expected_order_date).format("DD/MM/YY") : 'Not Provided'}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        isTrue={quoteValid}
                                                                                        noPaper
                                                                                        title="Validity"
                                                                                        content={`${quoteCreated} - ${quoteExpiry}`}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Insight" />
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="chart-network"
                                                                                        title="Last Activity"
                                                                                        content={quote.activity?.[0]?.d ? moment(quote.activity?.[0]?.d).format("DD/MM/YY HH:mm") : '-'}
                                                                                        onClick={() => this.handleTabChange(2)}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pencil-alt"
                                                                                        title="Last Updated"
                                                                                        content={quote.quote_updated_datetime !== "0000-00-00 00:00:00" ? moment(quote.quote_updated_datetime).format("DD/MM/YY HH:mm") : '-'}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>                                                        
                                                                                    <StaffTile
                                                                                        noPaper
                                                                                        title="Quoted By"
                                                                                        staffId={quote.quote_staff_id}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>                                                        
                                                                                    <StaffTile
                                                                                        noPaper
                                                                                        title="Salesperson"
                                                                                        staffId={quote.quote_sales_staff_id}
                                                                                    />
                                                                                </Grid> 
                                                                            </Grid>    
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Grid container spacing={1} alignItems='center'>
                                                                        <Grid item xs>
                                                                            <Typography variant="h6">
                                                                                {((quote.quote_status === 'Awaiting Approval' || quote.quote_status === 'Ordered' || quote.quote_status === 'Lost' || quote.quote_status === 'Declined') && (
                                                                                    'Nothing To Do'
                                                                                )) || (quote.quote_status === 'Draft' && (
                                                                                    'Finalise Quote'
                                                                                )) || (
                                                                                    'Chase Quote'
                                                                                )}
                                                                            </Typography>    
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {(quote.quote_status === 'Ordered' && (
                                                                                <>
                                                                                    <Typography variant="body2" style={{color: '#000'}} gutterBottom>
                                                                                        This quote was ordered!
                                                                                    </Typography>
                                                                                    <Button onClick={() => this.props.history.push(`/sales/${quote.order.order_id}`)} color="primary">
                                                                                        View Order
                                                                                    </Button>
                                                                                </>
                                                                            )) || (quote.quote_status === 'Lost' && (
                                                                                <Typography variant="body2" style={{color: '#000'}}>
                                                                                    This opportunity has been lost!
                                                                                </Typography>
                                                                            )) || (quote.quote_status === 'Declined' && (
                                                                                <Typography variant="body2" style={{color: '#000'}}>
                                                                                    This quote has been declined
                                                                                </Typography>
                                                                            )) || ((quote.quote_status === 'Draft' || quote.quote_status === 'Awaiting Approval') && (
                                                                                <>
                                                                                    <Typography variant="body2" style={{color: '#000'}}>
                                                                                        This quote is not visible to the customer in the Installer Portal until it has been e-mailed per the usual process.
                                                                                        {quote.quote_staff_id === 0 ? 'The first user to modify this quote will take ownership.' : ''}
                                                                                    </Typography>
                                                                                </>
                                                                            )) || (
                                                                                <>
                                                                                    <Typography variant="body2" style={{color: '#000'}} gutterBottom>
                                                                                        Call {!_.isEmpty(quote.quote_contact_name) ? quote.quote_contact_name : quote?.customer?.cust_company_name} {phoneNumber ? `on ${phoneNumber}` : null}
                                                                                    </Typography>
                                                                                    <Box mr={3} display="inline-block">
                                                                                        <Button onClick={this.handleDeployAddNote} color="primary">
                                                                                            <FAIcon icon="comment-alt-plus" size={12.5} button />
                                                                                            Add Note
                                                                                        </Button>
                                                                                    </Box>
                                                                                    <Button onClick={this.handleDeployAddCall} color="primary">
                                                                                        <FAIcon icon="phone-plus" size={12.5} button />
                                                                                        Log Call
                                                                                    </Button>
                                                                                </>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="E-mail" />
                                                                    {(quote.latest_email && (
                                                                        <Alert severity="success" variant="outlined">
                                                                            <Typography variant="body2" className="textDefault">
                                                                                <span className="fw-400">This quote was last e-mailed on {moment(quote.latest_email.qe_created_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY [at] HH:mm")}</span><br />
                                                                                {quote.latest_email?.email?.email_to}
                                                                            </Typography>
                                                                        </Alert>
                                                                    )) || (
                                                                        <Alert severity="info" variant="outlined">
                                                                            <Typography variant="body2" className="fw-400 textDefault">
                                                                                This quote has not been e-mailed to the customer yet
                                                                            </Typography>
                                                                        </Alert>
                                                                    )}
                                                                </PaddedPaper>
                                                            </Grid>
                                                            {!_.isEmpty(quote.revisions) && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item xs>
                                                                                <Typography variant="h6">
                                                                                    Revisions
                                                                                </Typography>    
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <DataTable
                                                                                    config={{
                                                                                        key: 'quote_id',
                                                                                        inline: true,
                                                                                    }}
                                                                                    columns={[
                                                                                        {
                                                                                            heading: 'Revision',
                                                                                            field: rowData => `${rowData.quote_revision}`,
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.quote_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            heading: 'Created By',
                                                                                            field: rowData => staff?.[rowData?.quote_revision_staff_id ?? 0]?.name ?? 'Unknown',
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.quote_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            heading: 'Created On',
                                                                                            field: rowData => moment(rowData.quote_updated_datetime).format("DD/MM/YYYY"),
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.quote_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            actions: rowData => {
                                                                                                return [
                                                                                                    {name: 'View', icon: 'binoculars', link: '/quotes/' + rowData.quote_id, disabled: rowData.quote_id === quote.quote_id}
                                                                                                ]
                                                                                            }
                                                                                        }
                                                                                    ]}
                                                                                    rows={quote.revisions}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <QuoteSummary quote={quote} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <ActivityLog activity={quote.activity} fullPage />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <ViewCallLogs calls={quote.call_logs} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <ViewNotes notes={quote.notes} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <Paper>
                                                    <SearchApprovalsTable 
                                                        inline
                                                        callback={this.loadComponentData}
                                                        customer={quote.customer} 
                                                        quoteReference={quote.quote_reference} 
                                                        quote={quote}
                                                        rows={quote.approvals} 
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <ViewDocuments 
                                                            access={access}
                                                            callback={this.loadComponentData}
                                                            category="Quotes"
                                                            documents={quote.documents}
                                                            uploadFile={this.handleDeployFileUpload} 
                                                            legacyDocBox={`C:/DATA/Advance/Documents/SOPDocs/SOP${quote.quote_reference}`}
                                                        />
                                                    </Grid>
                                                    {/* {access.uploadDoc && (
                                                        <Grid item xs={12} lg={4}>
                                                            <PaddedPaper>
                                                                <Typography variant="h6" paragraph>
                                                                    Upload File
                                                                </Typography>
                                                                <DocumentForm
                                                                    id={customer.cust_id}
                                                                    type="customer"
                                                                    category="Customers"
                                                                    callback={this.loadComponentData}
                                                                />
                                                            </PaddedPaper>
                                                        </Grid>
                                                    )} */}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={7}>
                                                <Box m={-3}>
                                                    <Paper style={{width: '100%', background: '#F5F5F5', height: 47, borderBottom: '1px solid #ddd', borderRadius: 0}} elevation={0}>
                                                        <Box p={0.5}>
                                                            <Grid container spacing={1} justify='center' alignItems='center'>
                                                                <Grid item xs>
                                                                    <Typography variant="body2" className="ml-2">
                                                                        {`Pages: ${pdf.numPages ?? 1}`}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(1.5)}
                                                                            >
                                                                                <FAIcon type="light" icon="search" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(false)}
                                                                                disabled={pdf.scale === "0.75"}
                                                                            >
                                                                                <FAIcon type="light" icon="search-minus" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(true)}
                                                                                disabled={pdf.scale === "2.00"}
                                                                            >
                                                                                <FAIcon type="light" icon="search-plus" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                    <Grid container justify="center" style={{height: 'calc(100% - 47px)', background: '#777', position: 'relative', textAlign: 'center', overflowX: 'hidden', overflowY: 'auto'}}>
                                                        <Grid item>
                                                            <Box p={3}>
                                                                <Document
                                                                    file={quote.signedPdf}
                                                                    onLoadSuccess={this.handlePdfLoad}
                                                                    loading={(
                                                                        <>
                                                                        </>
                                                                    )}
                                                                >
                                                                     {Array.apply(null, Array(pdf.numPages))
                                                                            .map((x, i)=>i+1)
                                                                            .map(page => (
                                                                                <Page 
                                                                                    pageNumber={page} 
                                                                                    loading={(
                                                                                        <LoadingCircle />
                                                                                    )}
                                                                                    renderAnnotationLayer={false}
                                                                                    scale={pdf.scale}
                                                                                    style={{
                                                                                        width: '100%', 
                                                                                        position: 'absolute',
                                                                                    }}
                                                                                />
                                                                            )
                                                                    )}
                                                                </Document>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={8}>
                                                <ViewActions
                                                    key={key}
                                                    filter="quote"
                                                    filterId={quote.quote_id}
                                                    staff={staff}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    callback={this.loadComponentData}
                                                    noPagination
                                                />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <ConfirmationDialog 
                                    open={confirmationOpen.openQuote} 
                                    success={() => this.handleConfirmationSuccess('openQuote')} 
                                    close={() => this.handleConfirmationClose('openQuote')} 
                                    title="Open quote?" 
                                    message="Are you sure you want to open this quote?"
                                />
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Quote Summary" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={7} label="PDF Document" disabled={!quote.signedPdf || quote.quote_status === 'Draft'} />
                                    <Divider />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={5} label="Approvals" disabled={_.isEmpty(quote.approvals)} />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={6} label={<Badge color="primary" className="badge" badgeContent={quote.all_files_require_action_count ?? quote.files_require_action_count ?? 0} showZero={false}>Files</Badge>} disabled={_.isEmpty(quote.documents)} />
                                    {quote.revisions?.[0]?.quote_revision === quote.quote_revision && (
                                        <Divider />
                                    )}
                                    {quote.revisions?.[0]?.quote_revision === quote.quote_revision && (
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={8} label={`Actions ${quote.actions_count > 0 ? `(${quote.actions_count})` : ``}`} />
                                    )}
                                    {quote.revisions?.[0]?.quote_revision === quote.quote_revision && (
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label={`Timeline ${_.size(quote.activity) > 0 ? `(${_.size(quote.activity)})` : ``}`} />
                                    )}
                                    {quote.revisions?.[0]?.quote_revision === quote.quote_revision && (
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label={`Calls ${_.size(quote.call_logs) > 0 ? `(${_.size(quote.call_logs)})` : ``}`} />
                                    )}
                                    {quote.revisions?.[0]?.quote_revision === quote.quote_revision && (
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} label={`Notes ${_.size(quote.notes) > 0 ? `(${_.size(quote.notes)})` : ``}`} />
                                    )}
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        loggedInStaff: state.staffAuth.staff,
        ui: state.ui
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableClose = true) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableClose)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(withRouter(ViewQuote)));