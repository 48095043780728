import React from 'react';

import { withRouter } from 'react-router-dom';

import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

const initialState = () => ({
    cardDetails: {}
})

class PrintableCardForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        const dataStringBase64 = this.props.match.params.data;
        const dataString = atob(dataStringBase64);
        const data = JSON.parse(dataString);
        this.setState({
            cardDetails: data
        }, this.print);
    }

    print = () => {
        window.print();
        setTimeout(function() {
            window.location.replace('/');
            window.close();
        }, 500);
    }

    render() {
        const {
            cardDetails
        } = this.state;
        return (
            <>
                <style type="text/css">
                    body &#123;
                        transform: scale(1.5);
                    &#125;
                    @page &#123;
                        size: landscape;
                    &#125;
                    @media print &#123;
                        @page  &#123;
                            margin: 0;
                        &#125;
                        body &#123; 
                            margin: 1.6cm; 
                        &#125;
                    &#125;
                </style>
                <div style={{position: 'absolute', top: 0}}>
                    <Grid container spacing={3} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h3" gutterBottom align="center">
                                CARD PAYMENT FORM
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom align="center">
                                This document falls under our <strong>'Security policy'</strong> dated February 2016 which outlines how this information should be treated.<br />
                                Users completing this form must comply in full with this procedure.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>Company Name:</TableCell>
                                        <TableCell>{cardDetails.companyName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>SOP Number:</TableCell>
                                        <TableCell>{cardDetails.sopNumber}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                            PAN Number:<br />
                                            <em>Long number on front of the card</em>
                                        </TableCell>
                                        <TableCell>{cardDetails.panNumber}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                            Start Date:<br />
                                            <em>Debit Cards</em>
                                        </TableCell>
                                        <TableCell>{cardDetails.startDate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>End Date:</TableCell>
                                        <TableCell>{cardDetails.endDate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                            CAV2, CID, CVC2, CVV2:<br />
                                            <em>3 digit code on reverse of card</em>
                                        </TableCell>
                                        <TableCell>{cardDetails.cvv}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>Amount (&pound;):</TableCell>
                                        <TableCell>{cardDetails.amount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                            Is the card registered to the office address?<br />
                                            <em>Check against RED &amp; confirm with the customer</em>
                                        </TableCell>
                                        <TableCell>{cardDetails.cardRegisteredToOffice}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{width: '1%', whiteSpace: 'nowrap', fontWeight: 500}}>
                                            ↪ If not<br />
                                            <em>Address the card is registered to and postcode:</em>
                                        </TableCell>
                                        <TableCell>{cardDetails.registeredAddress}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom align="center">
                                <strong>REMEMBER:</strong> Once completed, this form must be kept confidential and destroyed as soon as payment processing is completed.
                                <br /><br/>
                                Management Authorisation is required for payments over £5,000 or payments made using multiple cards.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom align="center">
                                <em>Payment form generated &amp; printed by {cardDetails.staffMember} at {moment().format("HH:mm [on] DD/MM/YYYY")}</em>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
}

export default withRouter(PrintableCardForm);