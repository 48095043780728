import { Box, Paper } from "@material-ui/core";
import API from "API";
import Alert from "Components/Common/Alert/Alert";
import DataTable from "Components/Common/DataTables/DataTable";
import Heading from "Components/Common/Heading/Heading";
import Highlight from "Components/Common/Highlight/Highlight";
import { useEffect, useState } from "react";
import StatusChip from "Components/Common/Chips/StatusChip";
import _ from 'lodash';
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";

const initialState = () => ({
    isLoading: true,
    allocations: [],
})

const StockAllocation = ({productId}) => {

    const [access, setAccess] = useState(null);
    const [state, setState] = useState(initialState());

    const handleLoadAccess = () => {
        API.multiAccess([
            'view-order'
        ]).then(([viewOrder]) => {
            setAccess({
                viewOrder
            })
        });
    }

    const handleLoadData = () => {
        API.get(`/products/${productId}/allocations`)
        .then (res => {
            if(res?.data) {
                setState(prevState => ({
                    ...prevState,
                    isLoading: false,
                    allocations: res.data
                }))
            }
        })
    }

    useEffect(() => {
        handleLoadData();
    }, [productId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleLoadAccess();
    }, [])

    return (
        state.isLoading ? (
            <LoadingCircle />
        ) : (
            <Paper>
                <Box p={3}>
                    <Heading text="Stock Allocations" />
                    {(_.isEmpty(state.allocations) && (
                        <Alert severity="success" variant="outlined">
                            <strong>None -</strong> This product is not currently allocated to any sales orders
                        </Alert>
                    )) || (
                        <DataTable
                            config={{
                                key: "odId",
                                inline: true,
                            }}
                            columns={[
                                {
                                    heading: "Customer",
                                    field: (rowData) => rowData.cu,
                                    main: true,
                                    truncate: true,
                                },
                                {
                                    heading: "SO Number",
                                    field: (rowData) => `${rowData.or}${rowData.od ? `/${rowData.od}` : ``}`,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "SO Status",
                                    field: (rowData) => <StatusChip status={rowData.os} />,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Pre-Allocated",
                                    field: (rowData) => rowData.at,
                                    fieldFormat: "boolean",
                                    alignment: "center",
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Processed",
                                    field: (rowData) => !rowData.at,
                                    fieldFormat: "boolean",
                                    alignment: "center",
                                    sizeToContent: true,
                                },
                                {
                                    heading: "ALLOC",
                                    field: (rowData) => <Highlight variant={"success"}>{rowData.sa}</Highlight>,
                                    sizeToContent: true,
                                    alignment: "center",
                                },
                                {
                                    heading: "POP",
                                    field: (rowData) => <Highlight variant={"success"}>{rowData.sp}</Highlight>,
                                    sizeToContent: true,
                                    alignment: "center",
                                },
                                // {
                                //     heading: 'Total Allocated',
                                //     field: rowData => (
                                //         <Highlight variant={'success'}>
                                //             {rowData.st}
                                //         </Highlight>
                                //     ),
                                //     sizeToContent: true,
                                //     alignment: 'center',
                                // }
                                {
                                    heading: "PO Number",
                                    field: (rowData) => `${rowData.pr}${rowData.pd ? `/${rowData.pd}` : ``}`,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "PO Status",
                                    field: (rowData) => (rowData.ps ? <StatusChip status={rowData.ps} /> : '-'),
                                    sizeToContent: true,
                                },
                                {
                                    actions: (rowData) => {
                                        return [
                                            {
                                                name: "View Sales Order",
                                                icon: "binoculars",
                                                link: `/sales/${rowData.oi}:${rowData.di}`,
                                                disabled: !access.viewOrder,
                                            },
                                        ];
                                    },
                                },
                            ]}
                            rows={state.allocations}
                        />
                    )}
                </Box>
            </Paper>
        )
    )
}

export default StockAllocation;