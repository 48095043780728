import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import DateSelect from 'Components/Common/Selects/DateSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';

const initialState = {
    searchResults: {},
    dataLoading: true,
    date: 'thisWeek',
    type: '',
    typeList: [
        {value: 'CreateAccount', label: 'Create Account'},
        {value: 'ChangeAccountCode', label: 'Change Account Code'},
        {value: 'UpdateAccount', label: 'Update Account'},
        {value: 'GetAccount', label: 'Get Account'},
        {value: 'OpenAccount', label: 'Open Account'},
        {value: 'CloseAccount', label: 'Close Account'},
        {value: 'CreditMemo', label: 'Add Credit Memo'},
        {value: 'Memo', label: 'Add Memo'},
        {value: 'InvoicePost', label: 'Sales Order Invoice'},
        {value: 'PurchaseInvoice', label: 'Purchase Order Invoice'},
        {value: 'CreditNote', label: 'Credit Note'},
        {value: 'OpenCreditAccount', label: 'Open Credit Account'},
        {value: 'ChangeCreditLimit', label: 'Change Credit Limit'},
        {value: 'CloseCreditAccount', label: 'Close Credit Account'},
        {value: 'AccountOnHold', label: 'Account On Hold'},
        {value: 'RemoveAccountHold', label: 'Remove Account Hold'},
        {value: 'GetPurchaseAccount', label: 'Get Supplier Account'},
        {value: 'CreatePurchaseAccount', label: 'Create Supplier Account'},
        {value: 'UpdatePurchaseAccount', label: 'Update Supplier Account'},
    ],
}

class SageLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Finance', 'Sage Connector Log']);
        this.getSearchData();
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/accounts/logs/sage', { 
                props: {
                    cancellation: true
                },
                params: { 
                    d: this.state.date,
                    t: this.state.type
                } 
            })
            .then(res => {
                if(res?.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: res.data
                    });
                } 
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.getSearchData();
        });
    }

    render() {

        const {
            staff
        } = this.props;

        const { 
            dataLoading, 
            date, 
            searchResults, 
            type, 
            typeList 
        } = this.state;

        return (
            <>
                <Grid container spacing={1}>      
                    <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={12} sm={4} md={3} xl={2} style={{marginBottom: 12}} className="pr-3">
                            <DateSelect
                                onChange={this.handleSelectChange('date')}
                                value={date}
                            />
                        </Grid>    
                        <Grid item xs={12} sm={4} md={3} xl={2} style={{marginBottom: 12}} className="pr-3">
                            <AutoCompleteSelect
                                options={typeList}
                                placeholder='Type:'
                                onChange={this.handleSelectChange('type')}
                                value={type} 
                                variant="filled"
                                adornment="filter"
                            /> 
                        </Grid>  
                    </Grid>           
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: dataLoading,
                                    responsiveImportance: true,
                                    options: {
                                        dataRef: true,
                                        export: {
                                            title: `Sage Connector Log`,
                                            name: `Sage-Log`,
                                            excel: true,
                                            pdf: true,
                                            print: true
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Accepted',
                                        field: rowData => rowData.d,
                                        fieldFormat: 'boolean',
                                        sizeToContent: true,
                                        alignment: 'center',
                                        dataRef: 'd',
                                    },
                                    {
                                        heading: 'Transaction',
                                        field: rowData => _.find(typeList, 
                                            el => el.value === rowData.t)?.label,
                                        dataRef: 't',
                                        main: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Account',
                                        field: rowData => rowData.c ?? rowData.su ?? '-',
                                        dataRef: 'c',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Account Number',
                                        field: rowData => rowData.ac ?? '-',
                                        dataRef: 'c',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Details',
                                        field: rowData => rowData.r ?? '-',
                                        dataRef: 'r',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Staff',
                                        field: rowData => (staff && <StaffChip staff={staff[rowData.a]} />) || '-',
                                        dataRef: 'a',
                                        sizeToContent: true,
                                        fieldFormat: 'staff',
                                    },
                                    {
                                        heading: 'Processed',
                                        field: rowData => rowData.p,
                                        fieldFormat: 'datetime',
                                        dataRef: 'p',
                                        sizeToContent: true,                                
                                    },
                                    {
                                        heading: 'Generated',
                                        field: rowData => rowData.g,
                                        fieldFormat: 'datetime',
                                        dataRef: 'g',
                                        sizeToContent: true,                                
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => <StatusChip status={rowData.s} />,
                                        dataRef: 's',
                                        sizeToContent: true
                                    },
                                ]}
                                rows={searchResults}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    staff: state.notifications.status
})

export default connect(mapStateToProps, null)(SageLog);