import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';

import Alert from 'Components/Common/Alert/Alert';
import TaskForm from 'Components/Common/Activity/TaskForm';
import Action from 'Components/Common/Cards/Action';
import { putActionStatus } from 'Redux/Actions/Notifications/Notifications';
import { handleClick, handleClose } from 'Functions/PopperFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = {
    anchorEl: null
}

class AdminActions extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleClick = handleClick.bind(this);
        this.handleClose = handleClose.bind(this);
    }

    handleAction = (action) => {
        if(action) {
            this.handleClose();
            action = action.split(":");
            switch(action[0]) {
                case "url":
                    this.props.history.push(action[1]);
                break;
                default:
            }
        }
    }

    handleDeployAddAction = () => {
        this.handleClose();
        this.props.deployDialog(
            <TaskForm
                all
                relation="generic"
                toggleDialog={() => this.props.closeDialog()}
            />, true, "", "standard", "sm");
    }

    handleDismiss = (id, e) => {
        e.stopPropagation();
        this.props.putActionStatus(id);
    }

    handleDelete = (id, e) => {
        e.stopPropagation();
        this.props.putActionStatus(id, true);
    }

    render() {
        let { actions, loggedInStaff, status } = this.props;

        actions = _.filter(actions, el => !el.w || moment(el.w, "DD-MM-YYYY HH:mm").isBefore(moment().endOf('day')));

        return (
            (!_.isEmpty(actions) && (
                <List 
                    disablePadding 
                >
                    {_.map(actions, (action, idx) => (
                        <Action
                            elevation={0}
                            noXlDivider
                            noAuthor
                            xl
                            compact
                            inline
                            key={idx}
                            data={action}
                            handleAction={this.handleAction}
                            handleDismiss={this.handleDismiss}
                            handleDelete={this.handleDelete}
                            status={status}
                            staffId={(loggedInStaff && loggedInStaff.id) || 0}
                        />
                    ))}
                </List>
            )) || (
                <Alert severity="success" variant="outlined">
                    <strong>None - </strong>No tasks due to be completed today
                </Alert>
            )                  
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        actions: state.notifications.actions,
        status: state.notifications.status
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, de)),
        putActionStatus: (id, del = false) => dispatch(putActionStatus(id, del))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminActions));