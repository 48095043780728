import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { SHORTLINK_BASE_URL } from 'Constants';

const initialState = () => ({
    formData: {
        code: '',
        target: ''
    },
    formErrors: [],
})

class ShortlinkForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.setState({
            formData: {
                code: this.generateCode()
            }
        })
    }

    handleSubmit = () => {
        API.post('/marketing/shortlinks', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.props.deploySnackBar("success", "You have successfully created a new shortlink");
                this.props.closeDialog();
            }
        });
    }
    
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    generateCode = () => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for(let i = 0; i < 6; i++) {
           result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    render() {
        const { formData, formErrors } = this.state;
        return (
            <form noValidate autoComplete="off">
                <Box pt={1} pb={1}>
                    <Alert severity="warning">
                        Please ensure the Target URL starts with <em>http://</em> or <em>https://</em>
                    </Alert>
                </Box>
                <FormControl fullWidth>
                    <TextField
                        id="code"
                        name="code"
                        label="Shortlink URL *"
                        value={formData.code}
                        error={formErrors && formErrors['code']}
                        helperText={formErrors && formErrors['code']}
                        onChange={this.handleChange}
                        margin="normal"
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{SHORTLINK_BASE_URL}</InputAdornment>,
                        }}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        id="target"
                        name="target"
                        label="Target URL *"
                        value={formData.target}
                        error={formErrors && formErrors['target']}
                        helperText={formErrors && formErrors['target']}
                        onChange={this.handleChange}
                        margin="normal"
                        fullWidth
                    />
                </FormControl>
                <div className='buttonRow'>
                    <Button onClick={() => this.props.closeDialog()}
                        variant="text"
                    >
                        <FAIcon icon="times" size={15} button />
                        Cancel
                    </Button>
                    <Button onClick={() => this.props.deployConfirmation("Are you sure you want to add this shortlink?", this.handleSubmit)}
                        color="primary"
                        disabled={Object.values(formData).every(x => (x === null || x === ""))}
                        variant="text"
                    >
                        <FAIcon icon="check" size={15} button />
                        Create
                    </Button>
                </div>
            </form>
        );
    }
}

export default ShortlinkForm;