import * as FileSaver from "file-saver";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuidv4 from "uuid/v4";
import * as XLSX from "xlsx-js-style";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MaterialLink from "@material-ui/core/Link";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Pagination from "./Pagination";

import API from "API";
import CopyToClipboardDatagrid from "Components/Common/CopyToClipboard/CopyToClipboardDatagrid";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import { API_URL, CLOUDFRONT_URL } from "Constants";
import { getToken } from "Functions/AuthFunctions";
import { cloneObject, currencyFormat, DraggablePaper, excelColumnIndexToLetter, excelFitToColumns, isNumeric, isSafari, setNestedObject } from "Functions/MiscFunctions";

import { setPersistence } from "Redux/Actions/StatePersistence/StatePersistence";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

const styles = (theme) => ({
    "@global": {
        ".a, .a:active, a:visited": {
            textDecoration: "none",
        },
    },
    alternatingRowColours: {
        "&:nth-of-type(even)": {
            backgroundColor: "#fafafa",
        },
        "&:nth-of-type(odd)": {
            backgroundColor: "#fff",
        },
    },
    listIcon: {
        minWidth: 24,
        marginLeft: 8,
    },
    searchTextField: {
        marginLeft: 6,
        marginTop: 8,
        borderColor: theme.palette.grey[100],
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const getDraggableRowStyle = (isDragging, draggableStyle) => ({
    /*
     * Use isDragging to apply styles on the row during the dragging process
     */
    // background: isDragging && 'blue',
    ...draggableStyle,
    userSelect: "none",
    backgroundColor: "#fff!important",
});

const getDraggableRowContainerStyle = (isDraggingOver) => ({
    /*
     * Use isDraggingOver to apply styles on the container during the dragging process
     */
    backgroundColor: isDraggingOver ? "#ef3340" : undefined,
    // display: 'flex',
    // overflow: 'auto',
});

const TableHeaderCell = withStyles((theme) => ({
    head: {
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: "bold",
        padding: "0 12px 10px 12px",
    },
    body: {
        fontSize: 14,
        whiteSpace: "nowrap",
    },
}))(TableCell);

const SortableTableHeaderCell = withStyles((theme) => ({
    head: {
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: "bold",
        padding: "0 12px 10px 12px",
        "&:hover": {
            color: "#000",
            cursor: "pointer",
        },
    },
    body: {
        fontSize: 14,
        whiteSpace: "nowrap",
    },
}))(TableCell);

const TableBodyCell = withStyles((theme) => ({
    body: {
        padding: "12px 12px",
        whiteSpace: "nowrap",
        color: "inherit",
    },
}))(TableCell);

const TableBodyCellNoPadding = withStyles((theme) => ({
    body: {
        padding: 0,
        color: "inherit",
    },
}))(TableCell);

const TableBodyCellDense = withStyles((theme) => ({
    body: {
        padding: "4px 12px",
        whiteSpace: "nowrap",
        color: "inherit",
    },
}))(TableCell);

const initialState = () => ({
    activeColumns: [],
    contextMenu: {
        columnMenu: false,
        filterMenu: false,
        printMenu: false,
    },
    exportData: {
        allRows: [],
        displayedRows: [],
        headers: [],
        excelMenu: false,
        pdfMenu: false,
    },
    pagination: {
        page: 0,
        rowsPerPage: 10,
    },
    responsiveActionsColumns: null,
    responsiveActionsRow: null,
    rows: [],
    sort: {
        dataRef: "",
        dataOrder: "",
    },
});

class DataTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.columnMenu = React.createRef();
        this.excelMenu = React.createRef();
        this.filterMenu = React.createRef();
        this.pdfMenu = React.createRef();
        this.printMenu = React.createRef();
        this.state = this.getInitialTableState();
    }

    getInitialTableState = () => ({
        ...initialState(),
        ...(this.hasTableState() && this.props.statePersistence?.[`dataTable:${this.props?.config?.persistenceId}`]),
    });

    hasTableState = () => {
        return this.props?.config?.persistenceId
            ? !!this.props.statePersistence?.[`dataTable:${this.props?.config?.persistenceId}`]
            : false;
    };

    saveTableState = () => {
        this.props?.config?.persistenceId !== null &&
            this.props.setPersistence(`dataTable:${this.props?.config?.persistenceId}`, this.state);
    };

    componentDidMount = () => {
        !this.hasTableState() && this.configureDataTable();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.config?.persistenceId !== prevProps.config?.persistenceId) {
            if (!this.hasTableState()) {
                this.configureDataTable();
            } else {
                this.setState({
                    ...this.getInitialTableState(),
                });
            }
            return;
        }

        if (
            JSON.stringify(prevState.pagination) !== JSON.stringify(this.state.pagination) ||
            JSON.stringify(prevState.sort) !== JSON.stringify(this.state.sort)
        ) {
            this.saveTableState();
        }

        // this crashes order processing!
        // if (JSON.stringify(this.props.columns) !== JSON.stringify(prevProps.columns)) {
        //     this.configureDataTable();
        // }

        if (
            JSON.stringify(this.props.rows) !== JSON.stringify(prevProps.rows) &&
            JSON.stringify(this.props.rows) !== JSON.stringify(this.state.rows)
        ) {
            let { rows } = this.props;

            let {
                sort: { dataRef, dataOrder },
            } = this.state;

            dataOrder = dataOrder === "" ? "DESC" : dataOrder;

            if (dataRef !== "") {
                if (!_.isEmpty(rows)) {
                    const sortRef = dataRef.split(".");
                    rows.sort((a, b) => {
                        let sourceA = a[sortRef[0]];
                        let sourceB = b[sortRef[0]];
                        if (sortRef.length > 1) {
                            for (let i = 1; i < sortRef.length; i++) {
                                sourceA = sourceA[sortRef[i]];
                                sourceB = sourceB[sortRef[i]];
                            }
                        }
                        sourceA = sourceA === null || sourceA === "" ? "-" : sourceA;
                        sourceB = sourceB === null || sourceB === "" ? "-" : sourceB;
                        if (!isNaN(sourceA) && !isNaN(sourceB)) {
                            return parseInt(sourceA) - parseInt(sourceB);
                        } else {
                            return sourceA.toString().localeCompare(sourceB);
                        }
                    });
                }

                if (dataOrder === "ASC") rows.reverse();
            }

            // const is = this.getInitialState();
            // if(is.rows?.length !== this.props.rows?.length) {
            this.setState(
                {
                    sort: {
                        ...this.state.sort,
                        dataOrder,
                        dataRef:
                            this.props.config?.options?.defaultSort !== prevProps?.config?.options?.defaultSort
                                ? this.state.sort?.dataRef === prevProps?.config?.options?.defaultSort
                                    ? this.props.config?.options?.defaultSort ?? ""
                                    : initialState().sort.dataRef
                                : this.state.sort?.dataRef,
                    },
                    pagination: {
                        ...this.state.pagination,
                        page: 0,
                        // page: (Math.ceil(_.size(rows) / this.state.pagination.rowsPerPage) < (this.state.pagination.page + 1)) ? 0 : this.state.pagination.page
                    },
                    rows,
                },
                () => {
                    // this.updateLocationState();
                    this.saveTableState();
                    if (this.props.config.options && this.props.config.options.export) this.handleExportData();
                }
            );
            // } else {
            //     this.setState(is);
            // }
        }
    };

    configureDataTable = () => {
        let activeColumns = [],
            exportHeaders = [];

        _.each(this.props.columns, (column) => {
            if (!column.actions && column.heading && column.dataRef) {
                exportHeaders.push({
                    label: column.heading,
                    key: column.dataRef,
                    format: column?.fieldFormat ?? null,
                    enabled: true,
                });

                activeColumns[column.dataRef] = true;
            }
        });

        this.setState(
            {
                ...this.getInitialTableState(),
                activeColumns,
                exportData: {
                    ...this.state.exportData,
                    headers: exportHeaders,
                },
                pagination: {
                    ...this.state.pagination,
                    rowsPerPage: this.props.config.rowsPerPage
                        ? this.props.config.rowsPerPage
                        : this.state.pagination.rowsPerPage,
                },
                rows: this.props.rows,
                sort: {
                    ...this.state.sort,
                    dataRef: this.state.sort.dataRef === "" ? this.props.config?.options?.defaultSort ?? "" : "",
                    dataOrder: this.props.config?.options?.defaultSortOrder ?? this.state.sort.dataOrder
                },
            },
            () => {
                if (this.props.config.options && this.props.config.options.export) this.handleExportData();
            }
        );
    };

    handleRowDragEnd = (result) => {
        const { config } = this.props;
        if (!result.destination) {
            return;
        }
        config?.draggableRearrange?.(this.state.rows, result.source.index, result.destination.index);
    };
    handleChangePage = (event, page) => {
        this.setState(
            {
                pagination: {
                    ...this.state.pagination,
                    page: page,
                },
            },
            () => {
                if (this.props.config.options && this.props.config.options.export) this.handleExportData();
            }
        );
    };
    handleChangeRowsPerPage = (event) => {
        this.setState(
            {
                pagination: {
                    ...this.state.pagination,
                    rowsPerPage: event.target.value,
                    page: 0,
                },
            },
            () => {
                this.handleExportData();
            }
        );
    };
    handleResponsiveActionsOpen = (columns, row) => {
        this.setState({
            ...this.state,
            responsiveActionsColumns: columns,
            responsiveActionsRow: row,
        });
    };
    handleResponsiveActionsClose = (e) => {
        this.setState({
            ...this.state,
            responsiveActionsColumns: null,
            responsiveActionsRow: null,
        });
    };
    handleColumnToggle = (dataRef) => {
        let exportHeaders = [];
        let activeColumns = this.state.activeColumns;
        activeColumns[dataRef] = !activeColumns[dataRef];
        _.each(this.props.columns, (column) => {
            if (activeColumns[column.dataRef]) exportHeaders.push({ label: column.heading, key: column.dataRef, format: column?.fieldFormat ?? null });
        });
        this.setState({
            activeColumns: activeColumns,
            exportData: {
                ...this.state.exportData,
                headers: exportHeaders,
            },
        });
    };
    handleExportData = () => {
        const { config, columns } = this.props;
        const { pagination } = this.state;
        let allRows = [];
        let displayedRows = [];
        let rows = _.values(cloneObject(this.state.rows));
        if (typeof rows !== "undefined" || _.size(rows) > 0) {
            allRows = rows;
        }
        _.each(columns, (field) => {
            allRows = _.map(allRows, (row) => {

                if (field?.fieldFormat) {

                    if (field.dataRef?.includes?.(".")) {
                        let m = field.dataRef.split(".").reduce(function (o, x) {
                            return o[x];
                        }, row);
                        setNestedObject(row, field.dataRef, this.handleExportFormat(field.fieldFormat, m));
                    } else {
                        row[field.dataRef] = this.handleExportFormat(field.fieldFormat, row[field.dataRef]);
                    }

                } else {
                    
                    let rowType;

                    if (field.dataRef?.includes?.(".")) {

                        let m = field.dataRef.split(".").reduce(function (o, x) {
                            return o[x];
                        }, row);

                        rowType = typeof m;

                        if(rowType === "number" || isNumeric(m)) {
                            setNestedObject(row, field.dataRef, parseInt(m));
                        } else if (rowType === "string") {
                            setNestedObject(row, field.dataRef, m?.replace?.(/"/g, '""'));
                        }

                    } else {
                    
                        rowType = typeof row[field.dataRef];

                        if(rowType === "number" || isNumeric(row[field.dataRef])) {
                            row[field.dataRef] = parseInt(row[field.dataRef]);
                        } else if (rowType === "string") {
                            row[field.dataRef] = row[field.dataRef].replace(/"/g, '""');
                        }

                    }

                }
                
                return row;
            });
        });

        displayedRows =
            (config.pagination &&
                allRows.slice(
                    pagination.page * pagination.rowsPerPage,
                    pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
                )) ||
            allRows;

        this.setState({
            exportData: {
                ...this.state.exportData,
                allRows: allRows,
                displayedRows: displayedRows,
            },
        });

    };
    handleExportFormat = (format, data) => {
        if (format === "boolean") {
            if (data === "active") data = "Active";
            if (data === "inactive") data = "Inactive";
            if (data === "true" || data === true) data = "Y";
            if (data === "false" || data === false) data = "N";
            if (data > 0) data = "Y";
            if (data === 0) data = "N";
        } else if (format === "datetime") {
            data = !moment(data).isValid() ? "-" : moment(data).format("DD/MM/YYYY HH:mm");
        } else if (format === "datetime-full") {
            data = !moment(data).isValid() ? "-" : moment(data).format("DD/MM/YYYY HH:mm:ss");
        } else if (format === "date") {
            data = !moment(data, data?.includes?.("/") ? "DD/MM/YYYY" : undefined).isValid()
                ? "-"
                : moment(data, data?.includes?.("/") ? "DD/MM/YYYY" : undefined).format("DD/MM/YYYY");
        }
        else if (format === "staff") {
            data = this.props.staff?.[data]?.name ?? "-";
        }
        return data;
    };
    handleExportMenuClick = (menu, type, exportType) => {
        /* Close Export Menu */
        this.setState(
            {
                exportData: {
                    ...this.state.exportData,
                    [menu]: !this.state.exportData[menu],
                },
            },
            () => {
                const { config } = this.props;

                if (type === "pdf") {
                    this.handleExportPDF(
                        exportType,
                        `${config.options.export.name}-${
                            exportType === "allRows" ? "All" : `Page${this.state.pagination.page + 1}`
                        }-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.pdf`
                    );
                } else {
                    this.handleExportExcel(
                        exportType,
                        `${config.options.export.name}-${
                            exportType === "allRows" ? "All" : `Page${this.state.pagination.page + 1}`
                        }-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.xlsx`
                    );
                }
            }
        );
    };
    handleExportMenuToggle = (menu) => {
        this.setState({
            exportData: {
                ...this.state.exportData,
                [menu]: !this.state.exportData[menu],
            },
        });
    };
    handleExportExcel = (type, fileName) => {
        const { config } = this.props;

        let header = [],
            headerArr = [],
            headerIdx = {},
            exportData = [],
            headers = this.state.exportData?.headers ?? [],
            wb = XLSX?.utils?.book_new();

        /* Get data */
        _.each(this.state.exportData[type], (row) => {

            let exportRow = [];

            _.each(headers, (field) => {

                let cellData = null;

                if (field?.key?.includes?.(".")) {
                    cellData = field.key.split(".").reduce(function (o, x) {
                        return o[x];
                    }, row);
                } else {
                    cellData = row[field.key];
                }

                if(cellData || cellData === 0) {

                    if(field?.format) {
                        if (field.format.startsWith('percentage') || field.format.startsWith('decimal') || field.format.startsWith('currency')) {
                            exportRow.push(isNumeric(cellData) ? parseFloat(cellData) : cellData);
                        } else {
                            exportRow.push(cellData);
                        }
                    } else {
                        exportRow.push(cellData);
                    }

                } else {
                    exportRow.push("")
                }

            });

            exportData.push(exportRow);

        });

        /* Get headings */
        _.each(headers, (field, idx) => {

            header.push(field.label);

            headerArr.push(`${field.label}XXX`);

            let fmt = null;

            if(field?.format?.startsWith?.('percentage')) {
                fmt = 'percentage';
            } else if(field?.format?.startsWith?.('decimal')) {
                fmt = 'decimal';
            } else if(field?.format?.startsWith?.('currency')) {
                fmt = 'currency';
            }

            headerIdx = {
                ...headerIdx,
                [idx]: fmt
            }

        });

        /* Create worksheet */
        const ws = XLSX.utils.json_to_sheet([]);

        /* Add headers to sheet */
        XLSX.utils.sheet_add_aoa(ws, [header]);

        /* Add data to sheet */
        XLSX.utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });

        /* Get range of cells */
        const range = XLSX.utils.decode_range(ws["!ref"]);

        /* Add filters to columns */
        if(!config.options?.disableEnhancedExport) {

            ws['!autofilter'] = {
                ref: `A1:${excelColumnIndexToLetter(range.e.c)}1`,
            }
            
            /* Set column with to data set size */
            ws["!cols"] = excelFitToColumns([...exportData, headerArr]);
            
            /* Format string configuration */
            const formatStrings = {
                currency: "£0.00",
                decimal: "0.00",
                percentage: "0.00"
            }

        /* Loop rows for formatting */
            for (let x = (range.s.r + 1); x <= (range.e.r + 1); ++x) {

                /* Loop columns for formatting */
                for (let y = range.s.c; y <= range.e.c; ++y) {

                    const ref = `${excelColumnIndexToLetter(y)}${x}`;

                    /* Check reference exists */
                    if(ws[ref]) {

                        /* Set cell styles */
                        ws[ref].s = {
                            font: {
                                bold: x === 1 ? true : false,
                                name: 'Arial',
                                sz: 10,
                            },
                            border: x === 1 ? {
                                bottom: {
                                    style: "thin"
                                }
                            } : undefined
                        }

                        if(headerIdx[y]) {

                            if(ws[ref].t === "n") {

                                if(headerIdx[y] === "percentage") {
                                    ws[ref].z = formatStrings.percentage;
                                } else if(headerIdx[y] === "decimal") {
                                    ws[ref].z = formatStrings.decimal;
                                } else if(headerIdx[y] === "currency") {
                                    ws[ref].z = formatStrings.currency;
                                }

                            }

                        }

                    }

                }

            }

        }

        /* Add sheet to workbook */
        let sheetName = config.options.export.name;
        if (sheetName.length > 31) {
            sheetName = sheetName.substring(0, 31);
        }
        XLSX.utils.book_append_sheet(wb, ws, sheetName);

        /* Convert to an excel object */
        const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
        });

        /* Convert excel object to a blob */
        const data = new Blob([excelBuffer], {
            type: "xlsx",
        });

        /* Output blob to browser */
        FileSaver.saveAs(data, fileName?.toLowerCase?.());
    };

    handleExportPDF = (type, filename) => {
        const { config } = this.props;
        API.post("/reporting/pdf", {
            title: config.options.export.title,
            rows: this.state.exportData[type],
            headers: this.state.exportData.headers,
        }).then((result) => {
            if (result.data?.url) {
                let a = document.createElement("a");

                a.href = `${CLOUDFRONT_URL}${result.data.url}`;

                if (!isSafari()) {
                    a.target = "_blank";
                }

                a.click();
            } else {
                this.props.deploySnackBar("error", "There was an issue generating your report, please try again");
            }
        });
    };
    handleMenuToggle = (menu) => {
        this.setState({
            contextMenu: {
                ...this.state.contextMenu,
                [menu]: !this.state.contextMenu[menu],
            },
        });
    };
    handlePrint = (type) => {
        const { config } = this.props;
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", API_URL + "/reporting/print");
        form.setAttribute("target", "printReport");

        var token = document.createElement("input");
        token.setAttribute("type", "hidden");
        token.setAttribute("name", "token");
        token.setAttribute("value", btoa(getToken()));
        form.appendChild(token);

        var title = document.createElement("input");
        title.setAttribute("type", "hidden");
        title.setAttribute("name", "title");
        title.setAttribute("value", btoa(config.options.export.title));
        form.appendChild(title);

        var headers = document.createElement("input");
        headers.setAttribute("type", "hidden");
        headers.setAttribute("name", "headers");
        headers.setAttribute("value", JSON.stringify(this.state.exportData.headers));
        form.appendChild(headers);

        var rows = document.createElement("input");
        rows.setAttribute("type", "hidden");
        rows.setAttribute("name", "rows");
        rows.setAttribute("value", JSON.stringify(this.state.exportData[type]));
        form.appendChild(rows);

        document.body.appendChild(form);
        window.open("", "printReport");
        form.submit();
        document.body.removeChild(form);
    };
    handleReset = () => {
        let activeColumns = [];
        _.each(this.props.columns, (column) => {
            if (!column.actions) {
                activeColumns[column.dataRef] = true;
            }
        });
        this.setState(
            {
                activeColumns: activeColumns,
                sort: {
                    dataRef: "",
                    dataOrder: "",
                },
            },
            () => {
                const { config } = this.props;
                config.options.reset();
            }
        );
    };
    handleSort = (column) => {
        const dataRef = column.sortRef ?? column.dataRef;
        let rows = this.state.rows;
        let dataOrder = this.state.sort.dataOrder !== "" ? this.state.sort.dataOrder : "DESC";
        if (dataRef === this.state.sort.dataRef) {
            dataOrder = dataOrder === "DESC" ? "ASC" : "DESC";
            rows.reverse();
        } else {
            const sortRef = dataRef.split(".");
            if (!_.isEmpty(rows)) {
                rows.sort((a, b) => {
                    let sourceA = a[sortRef[0]];
                    let sourceB = b[sortRef[0]];
                    if (sortRef.length > 1) {
                        for (let i = 1; i < sortRef.length; i++) {
                            sourceA = sourceA[sortRef[i]];
                            sourceB = sourceB[sortRef[i]];
                        }
                    }
                    sourceA = sourceA === null || sourceA === "" ? "" : sourceA;
                    sourceB = sourceB === null || sourceB === "" ? "" : sourceB;
                    if (column.fieldFormat === "date") {
                        if (sourceA.includes("/")) {
                            sourceA = moment(sourceA, "DD/MM/YYYY").format("YYYY-MM-DD");
                            sourceB = moment(sourceB, "DD/MM/YYYY").format("YYYY-MM-DD");
                        } else {
                            if (sourceA === "0000-00-00") {
                                sourceA = "";
                            }
                            if (sourceB === "0000-00-00") {
                                sourceB = "";
                            }
                        }
                    }
                    if (column.fieldFormat === "datetime" || column.fieldFormat === "datetime-full") {
                        if (sourceA === "0000-00-00 00:00:00") {
                            sourceA = "";
                        }
                        if (sourceB === "0000-00-00 00:00:00") {
                            sourceB = "";
                        }
                    }
                    if (
                        (column.fieldFormat === "date" || column.fieldFormat === "datetime" || column.fieldFormat === 'datetime-full') &&
                        !_.isEmpty(sourceA) &&
                        !_.isEmpty(sourceB)
                    ) {
                        return new Date(sourceA) - new Date(sourceB);
                    } else if(column.fieldFomrat === 'int') {
                        return parseInt(sourceA) - parseInt(sourceB);
                    } else if (!isNaN(sourceA) && !isNaN(sourceB)) {
                        return sourceA - sourceB;
                    } else {
                        return sourceA.toString().localeCompare(sourceB);
                    }
                });
                if (column.fieldFormat === "date" || column.fieldFormat === "datetime" || column.fieldFormat === 'datetime-full') {
                    rows.reverse();
                }
            }
        }
        this.setState(
            {
                sort: {
                    dataRef: dataRef,
                    dataOrder: dataOrder,
                },
                rows: rows,
                pagination: {
                    ...this.state.pagination,
                    page: 0,
                },
            },
            () => {
                this.saveTableState();
                if (this.props.config.options && this.props.config.options.export) this.handleExportData();
            }
        );
    };
    renderOptions = () => {
        const { classes, columns, config } = this.props;
        const { activeColumns, contextMenu, exportData } = this.state;
        return (
            <Grid container alignItems="center">
                {config.options && config.options.headingInput && (
                    <Grid
                        item
                        xs={12}
                        lg={8}
                        align={!this.props.ui.device.isMobile ? "left" : "center"}
                        style={{
                            padding: config.options ? (config.options.minimalPadding ? "0 12px 36px 12px" : 12) : 12,
                        }}
                    >
                        {config.options.headingInput}
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    lg={(config.options && config.options.headingInput && 4) || 12}
                    align={!this.props.ui.device.isMobile ? "right" : "center"}
                    style={{ padding: config.options ? (config.options.minimalPadding ? "0 12px 6px 12px" : 12) : 12 }}
                >
                    <>
                        {config.options && config.options.reset && (
                            <Tooltip title="Reset">
                                <IconButton onClick={() => this.handleReset()} style={{ marginLeft: 12 }}>
                                    <FAIcon type="light" icon="undo" size="small" noMargin button />
                                </IconButton>
                            </Tooltip>
                        )}
                        {config.options && config.options.dataRef && (
                            <>
                                <Tooltip title="Display Columns">
                                    <IconButton
                                        ref={this.columnMenu}
                                        onClick={() => this.handleMenuToggle("columnMenu")}
                                        style={{ marginLeft: 12 }}
                                    >
                                        <FAIcon type="light" icon="line-columns" size="small" noMargin button />
                                    </IconButton>
                                </Tooltip>
                                <Popper open={contextMenu.columnMenu} anchorEl={this.columnMenu.current}>
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => this.handleMenuToggle("columnMenu")}>
                                            <MenuList>
                                                <Typography
                                                    variant="body2"
                                                    className="fw-400"
                                                    style={{ padding: "9px 24px" }}
                                                >
                                                    Display Columns
                                                </Typography>
                                                {_.map(columns, (column, idx) => {
                                                    if (!column.actions && column.heading && column.dataRef)
                                                        return (
                                                            <MenuItem key={idx}>
                                                                <Box pl={1}>
                                                                    <FormControlLabel
                                                                        checked={activeColumns[column.dataRef]}
                                                                        control={
                                                                            <Checkbox
                                                                                color="primary"
                                                                                onChange={() =>
                                                                                    this.handleColumnToggle(
                                                                                        column.dataRef
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                        key={idx}
                                                                        label={
                                                                            <Typography variant="caption">
                                                                                {column.heading}
                                                                            </Typography>
                                                                        }
                                                                        style={{ display: "block", fontSize: 5 }}
                                                                        disableTypography
                                                                    />
                                                                </Box>
                                                            </MenuItem>
                                                        );
                                                })}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Popper>
                            </>
                        )}
                        {config.options && config.options.export && config.options.export.print && (
                            <>
                                <Tooltip title="Print">
                                    <span>
                                        <IconButton
                                            ref={this.printMenu}
                                            disabled={_.isEmpty(this.state.rows) || this.props.config?.isLoading}
                                            onClick={() => this.handleMenuToggle("printMenu")}
                                            style={{ marginLeft: 12 }}
                                        >
                                            <FAIcon
                                                type="light"
                                                icon="print"
                                                size="small"
                                                disabled={_.isEmpty(this.state.rows) || this.props.config?.isLoading}
                                                noMargin
                                                button
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Popper open={contextMenu.printMenu} anchorEl={this.printMenu.current}>
                                    <Paper style={{ width: 200, maxWidth: 300 }}>
                                        <ClickAwayListener onClickAway={() => this.handleMenuToggle("printMenu")}>
                                            <MenuList>
                                                <Typography
                                                    variant="body2"
                                                    className="fw-400"
                                                    style={{ padding: "9px 24px" }}
                                                >
                                                    Print
                                                </Typography>
                                                <MenuItem
                                                    onClick={() => {
                                                        this.handleMenuToggle("printMenu");
                                                        this.handlePrint("allRows");
                                                    }}
                                                >
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <FAIcon type="light" icon="print" size={15} noMargin />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography>
                                                        <Typography variant="caption">All Results</Typography>
                                                    </ListItemText>
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        this.handleMenuToggle("printMenu");
                                                        this.handlePrint("displayedRows");
                                                    }}
                                                >
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <FAIcon type="light" icon="print" size={15} noMargin />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography>
                                                        <Typography variant="caption">Current Page</Typography>
                                                    </ListItemText>
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Popper>
                            </>
                        )}
                        {config.options && config.options.export && config.options.export.pdf && (
                            <>
                                <Tooltip title="Export to PDF">
                                    <span>
                                        <IconButton
                                            ref={this.pdfMenu}
                                            disabled={_.isEmpty(this.state.rows) || this.props.config?.isLoading}
                                            onClick={() => this.handleExportMenuToggle("pdfMenu")}
                                            style={{ marginLeft: 12 }}
                                        >
                                            <FAIcon
                                                type="light"
                                                icon="file-pdf"
                                                noMargin
                                                button
                                                size={17}
                                                style={{
                                                    color:
                                                        !_.isEmpty(this.state.rows) && !this.props.config?.isLoading
                                                            ? "#d32f2f"
                                                            : undefined,
                                                }}
                                                disabled={_.isEmpty(this.state.rows) || this.props.config?.isLoading}
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Popper open={exportData.pdfMenu} anchorEl={this.pdfMenu.current}>
                                    <Paper style={{ width: 200, maxWidth: 300 }}>
                                        <ClickAwayListener onClickAway={() => this.handleExportMenuToggle("pdfMenu")}>
                                            <MenuList>
                                                <Typography
                                                    variant="body2"
                                                    className="fw-400"
                                                    style={{ padding: "9px 24px" }}
                                                >
                                                    Export to PDF
                                                </Typography>
                                                {/* <MenuItem disabled onClick={() => this.handleExportMenuClick('pdfMenu', 'pdf', 'allRows')}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FAIcon type="light" icon="file-pdf" size={15} disabled noMargin />
                                                        </ListItemIcon>
                                                        <ListItemText disableTypography>
                                                            <Typography variant="caption">
                                                                All Results
                                                            </Typography>
                                                        </ListItemText>
                                                    </MenuItem> */}
                                                <MenuItem
                                                    onClick={() =>
                                                        this.handleExportMenuClick("pdfMenu", "pdf", "displayedRows")
                                                    }
                                                >
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <FAIcon type="light" icon="file-pdf" size={15} noMargin />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography>
                                                        <Typography variant="caption">Current Page</Typography>
                                                    </ListItemText>
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Popper>
                            </>
                        )}
                        {config.options && config.options.export && config.options.export.excel && (
                            <>
                                <Tooltip title="Export to Excel">
                                    <span>
                                        <IconButton
                                            ref={this.excelMenu}
                                            disabled={_.isEmpty(this.state.rows) || this.props.config?.isLoading}
                                            onClick={() => this.handleExportMenuToggle("excelMenu")}
                                            style={{ marginLeft: 12 }}
                                        >
                                            <FAIcon
                                                type="light"
                                                icon="file-excel"
                                                noMargin
                                                button
                                                size={17}
                                                style={{
                                                    color:
                                                        !_.isEmpty(this.state.rows) && !this.props.config?.isLoading
                                                            ? "#388E3C"
                                                            : undefined,
                                                }}
                                                disabled={_.isEmpty(this.state.rows) || this.props.config?.isLoading}
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Popper open={exportData.excelMenu} anchorEl={this.excelMenu.current}>
                                    <Paper style={{ width: 200, maxWidth: 300 }}>
                                        <ClickAwayListener onClickAway={() => this.handleExportMenuToggle("excelMenu")}>
                                            <MenuList>
                                                <Typography
                                                    variant="body2"
                                                    className="fw-400"
                                                    style={{ padding: "9px 24px" }}
                                                >
                                                    Export to Excel
                                                </Typography>
                                                <MenuItem
                                                    style={{ color: "#000" }}
                                                    onClick={() =>
                                                        this.handleExportMenuClick("excelMenu", "excel", "allRows")
                                                    }
                                                >
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <FAIcon type="light" icon="file-excel" size={15} noMargin />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography>
                                                        <Typography variant="caption">All Results</Typography>
                                                    </ListItemText>
                                                </MenuItem>
                                                <MenuItem
                                                    style={{ color: "#000" }}
                                                    onClick={() =>
                                                        this.handleExportMenuClick(
                                                            "excelMenu",
                                                            "excel",
                                                            "displayedRows"
                                                        )
                                                    }
                                                >
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <FAIcon type="light" icon="file-excel" size={15} noMargin />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography>
                                                        <Typography variant="caption">Current Page</Typography>
                                                    </ListItemText>
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Popper>
                            </>
                        )}
                    </>
                </Grid>
            </Grid>
        );
    };
    renderHeader() {
        const { config, columns, rows } = this.props;
        return (
            <>
                <TableHead>
                    <TableRow>
                        {config.draggable && config.draggableHandle && (
                            <TableHeaderCell key={uuidv4()}></TableHeaderCell>
                        )}
                        {_.map(columns, (column) => {
                            const HeaderCellElement = config.plainHeader
                                ? TableCell
                                : column.dataRef
                                ? SortableTableHeaderCell
                                : TableHeaderCell;
                            return (
                                <>
                                    {(((this.props.ui.device.isMobile &&
                                        (column.actions ||
                                            (config.responsiveImportance && column.important !== true))) ||
                                        column.hidden === true ||
                                        this.state.activeColumns[column.dataRef] === false) && (
                                        <React.Fragment key={uuidv4()} />
                                    )) ||
                                        (column.dataRef && (
                                            <HeaderCellElement
                                                key={uuidv4()}
                                                onClick={() => this.handleSort(column)}
                                                style={{
                                                    color: this.state.sort.dataRef === column.dataRef && "#ef3340",
                                                    width: (column.actions || column.sizeToContent) && "1%",
                                                    whiteSpace: (column.actions || column.sizeToContent) && "nowrap",
                                                    textAlign:
                                                        (column.actions && "center") ||
                                                        (column.alignment && column.alignment),
                                                }}
                                                {...(typeof config.headerCellProps === "function" &&
                                                    config.headerCellProps(column, rows))}
                                            >
                                                {(column.actions && !column.actionsCustomHeader && "Actions") ||
                                                    column.heading}
                                                <FAIcon
                                                    type="duo"
                                                    icon={
                                                        this.state.sort.dataOrder === "DESC" ? "sort-down" : "sort-up"
                                                    }
                                                    style={{
                                                        color: this.state.sort.dataRef === column.dataRef && "#ef3340",
                                                        marginLeft: 3,
                                                        width: 13,
                                                        height: 13,
                                                    }}
                                                />
                                            </HeaderCellElement>
                                        )) || (
                                            <HeaderCellElement
                                                key={uuidv4()}
                                                style={{
                                                    width: (column.actions || column.sizeToContent) && "1%",
                                                    whiteSpace: (column.actions || column.sizeToContent) && "nowrap",
                                                    textAlign:
                                                        (column.actions && "center") ||
                                                        (column.alignment && column.alignment),
                                                }}
                                                {...(typeof config.headerCellProps === "function" &&
                                                    config.headerCellProps(column, rows))}
                                            >
                                                {(column.actions && !column.actionsCustomHeader && "Actions") ||
                                                    column.heading}
                                            </HeaderCellElement>
                                        )}
                                </>
                            );
                        })}
                    </TableRow>
                </TableHead>
            </>
        );
    }
    renderBody() {
        const { pagination } = this.state;
        const { config, columns } = this.props;
        let rows = _.values(this.state.rows);
        if (typeof rows === "undefined" || _.size(rows) === 0) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={columns.length} style={{ textAlign: "center" }}>
                            <Typography variant="body2">
                                {(config.noResultsText && config.noResultsText) || "No results were found"}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        } else {
            for (let i = 0, l = _.size(rows); i < l; i++) {
                rows[i]["rowIdx"] = i;
                rows[i]["rowNumber"] = i + 1;
                rows[i]["rowNumberReversed"] = _.size(rows) - i;
            }

            rows =
                (config.pagination &&
                    rows.slice(
                        pagination.page * pagination.rowsPerPage,
                        pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
                    )) ||
                rows;

            return config.draggable ? (
                <DragDropContext onDragEnd={this.handleRowDragEnd} style={{ width: "100%" }}>
                    <Droppable droppableId="rowsDroppable">
                        {(draggableRowContainerProvided, draggableRowContainerSnapshot) => (
                            <TableBody
                                ref={draggableRowContainerProvided?.innerRef}
                                style={{
                                    ...getDraggableRowContainerStyle(draggableRowContainerSnapshot?.isDraggingOver),
                                }}
                            >
                                {_.map(rows, (row, bodyIdx) => {
                                    return this.renderRow(row, bodyIdx);
                                })}
                                {config.draggable && draggableRowContainerProvided.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <TableBody>
                    {_.map(rows, (row, bodyIdx) => {
                        return this.renderRow(row, bodyIdx);
                    })}
                </TableBody>
            );
        }
    }
    renderRow(row, bodyIdx, indentDepth = 0) {
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense
            ? TableBodyCellDense
            : config.noPadding
            ? TableBodyCellNoPadding
            : TableBodyCell;
        if (!this.props.ui.device.isMobile) {
            _.map(columns, (column, colIdx) => {
                if (column.actions && config.dense !== false) {
                    TableCellElement = TableBodyCellDense;
                }
            });
        }
        let skipColumns = 0;
        let onDoubleClick = false;
        _.each(columns, (column) => {
            if (column.actions) {
                if (column.actions(row).length === 1) {
                    let action = column.actions(row)[0];
                    if (!action.disabled) {
                        if (action.link) {
                            onDoubleClick = () => this.props.history.push(action.link);
                        } else if (action.onClick) {
                            onDoubleClick = () => action.onClick(row);
                        }
                    }
                } else {
                    _.each(column.actions(row), (action) => {
                        if (action.doubleClick) {
                            if (!action.disabled) {
                                if (action.link) {
                                    onDoubleClick = () => this.props.history.push(action.link);
                                } else if (action.onClick) {
                                    onDoubleClick = () => action.onClick(row);
                                }
                            }
                        }
                    });
                }
            }
        });

        // const Wrapper = ({children}) => config.draggable ? (
        //     <Draggable
        //         key={`draggable-row-${bodyIdx}`}
        //         draggableId={`draggable-row-${bodyIdx}`}
        //         index={bodyIdx}
        //     >
        //         {(provided, snapshot) => ({children})}
        //     </Draggable>
        // ) : (
        //     <React.Fragment>
        //         {children}
        //     </React.Fragment>
        // );

        const TestEl = config.draggable ? Draggable : Box;

        return (
            <TestEl
                key={config.draggable ? `draggable-row-${bodyIdx}` : `dt-row-${bodyIdx}`}
                draggableId={config.draggable && `draggable-row-${bodyIdx}`}
                index={config.draggable && bodyIdx}
            >
                {(provided, snapshot) => (
                    <>
                        <TableRow
                            ref={config.draggable && provided?.innerRef}
                            key={`body2${bodyIdx}`}
                            className={`${config.alternatingRowColours && classes.alternatingRowColours}${
                                onDoubleClick ? " link" : ""
                            }`}
                            onClick={() => {
                                this.props.ui.device.isMobile && this.handleResponsiveActionsOpen(columns, row);
                            }}
                            onDoubleClick={() => {
                                onDoubleClick && onDoubleClick();
                            }}
                            hover={config?.noRowHover ? false : undefined}
                            style={
                                config.draggable &&
                                getDraggableRowStyle(snapshot?.isDragging, provided?.draggableProps?.style, true)
                            }
                            {...provided?.draggableProps}
                            {...(config.draggable && !config.draggableHandle && provided?.dragHandleProps)}
                            {...(config.rowProps && !config.draggable && config.rowProps(row))}
                        >
                            {((config?.draggable && config?.draggableHandle) || config?.selector) && (
                                <TableCellElement
                                    style={{ textAlign: "center" }}
                                >
                                    {config.draggable && config.draggableHandle ? (
                                        <div
                                            className="pt-1"
                                            style={{ textAlign: "center" }}
                                            key={`${bodyIdx}-drag-handle`}
                                            {...provided?.dragHandleProps}
                                        >
                                            <FAIcon type="light" icon="bars" noMargin />
                                        </div>   
                                    ) : null}
                                    {config?.selector ? (
                                        <div className="pt-1">
                                            <Tooltip enterDelay={600} title={config?.selectorToolTip ?? 'Insert after this row'}>
                                                <div onClick={() => config.selector(bodyIdx)}>
                                                <FAIcon type={config?.selectorIdx === bodyIdx ? 'solid' : 'light'} className={config?.selectorIdx === bodyIdx ? 'textError' : ''} icon={config?.selectorIdx === bodyIdx ? 'circle-plus' : 'circle'} noMargin />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : null}
                                </TableCellElement>
                            )}
                            {_.map(columns, (column, colIdx) => {
                                if (skipColumns > 0) {
                                    skipColumns = skipColumns - 1;
                                    return <React.Fragment key={`skip-${bodyIdx}-${colIdx}`} />;
                                } else {
                                    let cellProps = column.cellProps && column.cellProps(row);
                                    if (cellProps) {
                                        cellProps = {
                                            ...cellProps,
                                            style: {
                                                ...cellProps.style,
                                                textAlign: column.alignment && column.alignment,
                                            },
                                        };
                                    } else {
                                        cellProps = {
                                            style: {
                                                textAlign: column.alignment && column.alignment,
                                            },
                                        };
                                    }
                                    if (column.truncate) {
                                        cellProps.style = {
                                            ...cellProps.style,
                                            textOverflow: "ellipsis",
                                            overflowX: "hidden",
                                            whiteSpace: "nowrap",
                                            maxWidth: 1,
                                        };
                                    }
                                    if (
                                        column.colSpan &&
                                        column.colSpan(row) > 1 &&
                                        !(
                                            this.props.ui.device.isMobile &&
                                            config.responsiveImportance &&
                                            column.important !== true
                                        )
                                    ) {
                                        skipColumns = column.colSpan(row) - 1;
                                    }

                                    return (
                                        (column.actions &&
                                            ((this.props.ui.device.isMobile && <React.Fragment key={`hide-${bodyIdx}-${colIdx}`} />) || (
                                                <TableCellElement style={{ textAlign: "center" }} key={`col${colIdx}`}>
                                                    {column.actions(row).map((action, actnIdx) => {
                                                        let ButtonElement = (action.label && Button) || IconButton;
                                                        return (
                                                            (action.type && action.type === "copyToClipboard" && (
                                                                <CopyToClipboardDatagrid
                                                                    key={`col${colIdx}${actnIdx}`}
                                                                    text={action.data}
                                                                    iconSize="medium"
                                                                    disabled={action.disabled}
                                                                />
                                                            )) ||
                                                            (action.disabled &&
                                                                ((!action.hideIfDisabled && (
                                                                    <ButtonElement
                                                                        size={action.btnSize}
                                                                        disabled
                                                                        key={`col${colIdx}${actnIdx}`}
                                                                    >
                                                                        {action.icon && (
                                                                            <FAIcon
                                                                                type={action.type ?? "light"}
                                                                                icon={action.icon}
                                                                                color={action.color}
                                                                                className={action.className}
                                                                                size={action.size ?? 17}
                                                                                button
                                                                                noMargin={!action.label}
                                                                                disabled
                                                                            />
                                                                        )}{" "}
                                                                        {action.label && action.label}
                                                                    </ButtonElement>
                                                                )) || (
                                                                    <React.Fragment key={`col-${bodyIdx}-${colIdx}-${actnIdx}`} />
                                                                ))) ||
                                                            (action.link && (
                                                                <Link
                                                                    to={`${action.link}`}
                                                                    style={{ textDecoration: "none" }}
                                                                    key={`col${colIdx}${actnIdx}`}
                                                                >
                                                                    <Tooltip title={action.name}>
                                                                        <ButtonElement size={action.btnSize}>
                                                                            {action.icon && (
                                                                                <FAIcon
                                                                                    type={action.type ?? "light"}
                                                                                    icon={action.icon}
                                                                                    color={action.color}
                                                                                    className={action.className}
                                                                                    size={action.size ?? 17}
                                                                                    button
                                                                                    noMargin={!action.label}
                                                                                />
                                                                            )}{" "}
                                                                            {action.label && action.label}
                                                                        </ButtonElement>
                                                                    </Tooltip>
                                                                </Link>
                                                            )) ||
                                                            (action.linkExternal && (
                                                                <MaterialLink
                                                                    href={`${action.linkExternal}`}
                                                                    target="_blank"
                                                                    style={{ textDecoration: "none" }}
                                                                    key={`col${colIdx}${actnIdx}`}
                                                                >
                                                                    <Tooltip title={action.name}>
                                                                        <ButtonElement size={action.btnSize}>
                                                                            {action.icon && (
                                                                                <FAIcon
                                                                                    type={action.type ?? "light"}
                                                                                    icon={action.icon}
                                                                                    color={action.color}
                                                                                    className={action.className}
                                                                                    size={action.size ?? 17}
                                                                                    button
                                                                                    noMargin={!action.label}
                                                                                />
                                                                            )}{" "}
                                                                            {action.label && action.label}
                                                                        </ButtonElement>
                                                                    </Tooltip>
                                                                </MaterialLink>
                                                            )) ||
                                                            (action.onClick && (
                                                                <i
                                                                    onClick={() => action.onClick(row)}
                                                                    key={`col${colIdx}${actnIdx}`}
                                                                >
                                                                    <Tooltip title={action.name}>
                                                                        <ButtonElement size={action.btnSize}>
                                                                            {action.icon && (
                                                                                <FAIcon
                                                                                    type={action.type ?? "light"}
                                                                                    icon={action.icon}
                                                                                    color={action.color}
                                                                                    className={action.className}
                                                                                    size={action.size ?? 17}
                                                                                    button
                                                                                    noMargin={!action.label}
                                                                                />
                                                                            )}{" "}
                                                                            {action.label && action.label}
                                                                        </ButtonElement>
                                                                    </Tooltip>
                                                                </i>
                                                            )) ||
                                                            (action.label && action.label)
                                                        );
                                                    })}
                                                </TableCellElement>
                                            ))) ||
                                        (((this.props.ui.device.isMobile &&
                                            config.responsiveImportance &&
                                            column.important !== true) ||
                                            column.hidden === true ||
                                            this.state.activeColumns[column.dataRef] === false) && (
                                            <React.Fragment key={uuidv4()} />
                                        )) || (
                                            <TableCellElement
                                                colSpan={
                                                    (column.colSpan &&
                                                        column.colSpan(row) > 1 &&
                                                        column.colSpan(row)) ||
                                                    1
                                                }
                                                {...cellProps}
                                                key={`col${colIdx}`}
                                            >
                                                {config.nesting &&
                                                    column.nestingDropdown &&
                                                    ((row.children && (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: "inline-block",
                                                                    width: 17.5 * indentDepth + "px",
                                                                }}
                                                            />
                                                        </>
                                                    )) || (
                                                        <span
                                                            style={{
                                                                display: "inline-block",
                                                                width: 17.5 * indentDepth + "px",
                                                            }}
                                                        >
                                                            <FAIcon icon="arrow-turn-down-right" type="thin" size={15} button />
                                                        </span>
                                                    ))}
                                                {this.renderCellData(column, row)}
                                            </TableCellElement>
                                        )
                                    );
                                }
                            })}
                        </TableRow>
                        {row.children && this.renderNestedChildren(row.children, indentDepth + 1)}
                    </>
                )}
            </TestEl>
        );
    }
    renderNestedChildren(children, depth = 0) {
        return (
            <>
                {_.map(children, (row, bodyIdx) => {
                    return <>{this.renderRow(row, bodyIdx, depth)}</>;
                })}
            </>
        );
    }
    renderTotals() {
        const { pagination } = this.state;
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : TableBodyCell;
        if (config.showTotals) {
            let rows = _.values(this.state.rows);
            if (typeof rows === "undefined" || _.size(rows) === 0) {
                return <React.Fragment key={uuidv4()}/>;
            } else {
                rows =
                    (config.pagination &&
                        rows.slice(
                            pagination.page * pagination.rowsPerPage,
                            pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
                        )) ||
                    rows;
                let totals = [];
                _.each(rows, function (row) {
                    _.each(columns, function (column, idx) {
                        if (column.showTotal) {
                            if (column.totalFormat === "decimal") {
                                let total = (idx in totals && totals[idx]) || 0;
                                let colTotal =
                                    (typeof column.totalField === "function" && Number(column.totalField(row))) ||
                                    Number(column.field(row));
                                totals[idx] = (parseFloat(total) + parseFloat(colTotal)).toFixed(2);
                            } else {
                                totals[idx] =
                                    ((idx in totals && totals[idx]) || 0) +
                                    ((typeof column.totalField === "function" && Number(column.totalField(row))) ||
                                        Number(column.field(row)));
                            }
                        } else {
                            if (column.showTotalLabel) {
                                totals[idx] = "Total";
                            } else {
                                totals[idx] = "";
                            }
                        }
                    });
                });
                return (
                    <>
                        <TableBody>
                            <TableRow
                                className={config.alternatingRowColours && classes.alternatingRowColours}
                                key="totals"
                            >
                                {_.map(columns, (column, idx) => {
                                    return (
                                        (this.props.ui.device.isMobile &&
                                            config.responsiveImportance &&
                                            column.important !== true && <React.Fragment key={idx} />) || (
                                            <TableCellElement
                                                key={`totals-${idx}`}
                                                style={{
                                                    textAlign: column.alignment && column.alignment,
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {(column.showTotal && (
                                                    <>
                                                        {(column.overwriteTotal && <>{column.overwriteTotal}</>) || (
                                                            <>
                                                                {column.fieldPrefix}
                                                                {(column.fieldFormat === "currency" &&
                                                                    (isNumeric(totals[idx])
                                                                        ? currencyFormat.format(totals[idx])
                                                                        : totals[idx])) ||
                                                                    (column.fieldFormat &&
                                                                        column.fieldFormat.startsWith("percentage") &&
                                                                        parseFloat(totals[idx]).toFixed(
                                                                            column.fieldFormat.includes(":")
                                                                                ? column.fieldFormat.split(":")[1]
                                                                                : 2
                                                                        ) + "%") ||
                                                                    (column.fieldFormat &&
                                                                        column.fieldFormat.startsWith("decimal") &&
                                                                        parseFloat(totals[idx]).toFixed(
                                                                            column.fieldFormat.includes(":")
                                                                                ? column.fieldFormat.split(":")[1]
                                                                                : 2
                                                                        )) ||
                                                                    (column.totalFormatText &&
                                                                        `${totals[idx]}${column.totalFormatText}`) ||
                                                                    totals[idx]}
                                                                {column.fieldSuffix}
                                                            </>
                                                        )}
                                                    </>
                                                )) ||
                                                    (column.showTotalLabel && <>{totals[idx]}</>)}
                                            </TableCellElement>
                                        )
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </>
                );
            }
        }
    }
    renderFullTotals() {
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : TableBodyCell;
        if (config.showFullTotals) {
            let rows = _.values(this.state.rows);
            if (typeof rows === "undefined" || _.size(rows) === 0) {
                return <React.Fragment />;
            } else {
                let totals = [];
                _.each(rows, function (row) {
                    _.each(columns, function (column, idx) {
                        if (column.showTotal) {
                            totals[idx] =
                                ((idx in totals && totals[idx]) || 0) +
                                ((typeof column.totalField === "function" && Number(column.totalField(row))) ||
                                    Number(column.field(row)));
                        } else {
                            if (column.showTotalLabel) {
                                totals[idx] = "Total";
                            } else {
                                totals[idx] = "";
                            }
                        }
                    });
                });
                return (
                    <>
                        <TableBody>
                            <TableRow
                                className={config.alternatingRowColours && classes.alternatingRowColours}
                                key="totals"
                            >
                                {_.map(columns, (column, idx) => {
                                    return (
                                        <TableCellElement
                                            style={{
                                                textAlign: column.alignment && column.alignment,
                                                fontWeight: "bold",
                                                borderBottom: config.plainPagination ? 0 : undefined,
                                            }}
                                        >
                                            {(column.showTotal && (
                                                <>
                                                    {(column.overwriteTotal && <>{column.overwriteTotal}</>) || (
                                                        <>
                                                            {column.fieldPrefix}
                                                            {(column.fieldFormat === "currency" &&
                                                                (isNumeric(totals[idx])
                                                                    ? currencyFormat.format(totals[idx])
                                                                    : totals[idx])) ||
                                                                (column.fieldFormat &&
                                                                    column.fieldFormat.startsWith("percentage") &&
                                                                    parseFloat(totals[idx]).toFixed(
                                                                        column.fieldFormat.includes(":")
                                                                            ? column.fieldFormat.split(":")[1]
                                                                            : 2
                                                                    ) + "%") ||
                                                                (column.fieldFormat &&
                                                                    column.fieldFormat.startsWith("decimal") &&
                                                                    parseFloat(totals[idx]).toFixed(
                                                                        column.fieldFormat.includes(":")
                                                                            ? column.fieldFormat.split(":")[1]
                                                                            : 2
                                                                    )) ||
                                                                totals[idx]}
                                                            {column.fieldSuffix}
                                                        </>
                                                    )}
                                                </>
                                            )) ||
                                                (column.showTotalLabel && <>{totals[idx]}</>)}
                                        </TableCellElement>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </>
                );
            }
        }
    }
    renderFooter() {
        const { config, rows } = this.props;
        const { pagination } = this.state;
        return (
            <>
                {config.pagination && _.size(rows) > 0 && (
                    <Table>
                        <TableFooter>
                            <Pagination
                                count={_.size(rows)}
                                rowsPerPage={
                                    (config.defaultRowsPerPage && config.defaultRowsPerPage) || pagination.rowsPerPage
                                }
                                page={pagination.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                isMobile={this.props.ui.device.isMobile}
                                noRowSelector={config.noRowSelector}
                                plainPagination={config.plainPagination}
                                basicPagination={config.basicPagination}
                            />
                        </TableFooter>
                    </Table>
                )}
            </>
        );
    }
    renderTooltip(field, text) {
        return (
            <Tooltip title={<Typography variant="subtitle">{text}</Typography>} placement="left">
                <div>{field}</div>
            </Tooltip>
        );
    }
    renderCellData(column, row, responsiveDialog = false) {
        let field = (responsiveDialog && (
            <>
                <Typography variant="body2">{column.heading}:</Typography>
                <Typography variant="body1" style={{ marginBottom: 16 }}>
                    {column.fieldPrefix}
                    {(column.fieldFormat === "currency" &&
                        (isNumeric(column.field(row))
                            ? currencyFormat.format(column.field(row))
                            : column.field(row))) ||
                        (column.fieldFormat === "boolean" &&
                            ((column.field(row) === "active" && "Active") ||
                                (column.field(row) === "inactive" && "Inactive") ||
                                ((column.field(row) === "true" || column.field(row) === true) && "Y") ||
                                ((column.field(row) === "false" || column.field(row) === false) && "N") ||
                                (column.field(row) > 0 && "Y") ||
                                (column.field(row) === 0 && "N") ||
                                column.field(row))) ||
                        (column.fieldFormat === "datetime" &&
                            (!moment(column.field(row)).isValid()
                                ? "-"
                                : moment(column.field(row)).format("DD/MM/YYYY HH:mm"))) ||
                        (column.fieldFormat === "datetime-full" &&
                            (!moment(column.field(row)).isValid()
                                ? "-"
                                : moment(column.field(row)).format("DD/MM/YYYY HH:mm:ss"))) ||
                        (column.fieldFormat === "date" &&
                            (!moment(
                                column.field(row),
                                column.field(row)?.includes?.("/") ? "DD/MM/YYYY" : undefined
                            ).isValid()
                                ? "-"
                                : moment(
                                      column.field(row),
                                      column.field(row)?.includes?.("/") ? "DD/MM/YYYY" : undefined
                                  ).format("DD/MM/YYYY"))) ||
                        (column.fieldFormat &&
                            column.fieldFormat.startsWith("percentage") &&
                            parseFloat(column.field(row)).toFixed(
                                column.fieldFormat.includes(":") ? column.fieldFormat.split(":")[1] : 2
                            ) + "%") ||
                        (column.fieldFormat &&
                            column.fieldFormat.startsWith("decimal") &&
                            parseFloat(column.field(row)).toFixed(
                                column.fieldFormat.includes(":") ? column.fieldFormat.split(":")[1] : 2
                            )) ||
                        column.field(row)}
                    {column.fieldSuffix}
                </Typography>
            </>
        )) || (
            <>
                {column.fieldPrefix}
                {(column.fieldFormat === "boolean" &&
                    ((column.field(row) === "Y" && (
                        <FAIcon type="light" icon="check" size="small" style={{ color: "#2E7D32" }} />
                    )) ||
                        (column.field(row) === "N" && (
                            <FAIcon type="light" icon="times" size="small" style={{ color: "#c62828" }} />
                        )) ||
                        (column.field(row) === "active" && (
                            <FAIcon type="light" icon="check" size="small" style={{ color: "#2E7D32" }} />
                        )) ||
                        (column.field(row) === "inactive" && (
                            <FAIcon type="light" icon="times" size="small" style={{ color: "#c62828" }} />
                        )) ||
                        ((column.field(row) === "true" || column.field(row) === true) && (
                            <FAIcon type="light" icon="check" size="small" style={{ color: "#2E7D32" }} />
                        )) ||
                        ((column.field(row) === "false" || column.field(row) === false) && (
                            <FAIcon type="light" icon="times" size="small" style={{ color: "#c62828" }} />
                        )) ||
                        (column.field(row) > 0 && (
                            <FAIcon type="light" icon="check" size="small" style={{ color: "#2E7D32" }} />
                        )) ||
                        (column.field(row) === 0 && (
                            <FAIcon type="light" icon="times" size="small" style={{ color: "#c62828" }} />
                        )) ||
                        column.field(row))) ||
                    (column.fieldFormat === "currency" &&
                        (isNumeric(column.field(row))
                            ? currencyFormat.format(column.field(row))
                            : column.field(row))) ||
                    (column.fieldFormat === "datetime" &&
                        (!moment(column.field(row)).isValid()
                            ? "-"
                            : moment(column.field(row)).format("DD/MM/YYYY HH:mm"))) ||
                    (column.fieldFormat === "datetime-full" &&
                        (!moment(column.field(row)).isValid()
                            ? "-"
                            : moment(column.field(row)).format("DD/MM/YYYY HH:mm:ss"))) ||
                    (column.fieldFormat === "date" &&
                        (!moment(
                            column.field(row),
                            column.field(row)?.includes?.("/") ? "DD/MM/YYYY" : undefined
                        ).isValid()
                            ? "-"
                            : moment(
                                  column.field(row),
                                  column.field(row)?.includes?.("/") ? "DD/MM/YYYY" : undefined
                              ).format("DD/MM/YYYY"))) ||
                    (column.fieldFormat &&
                        column.fieldFormat.startsWith("percentage") &&
                        parseFloat(column.field(row)).toFixed(
                            column.fieldFormat.includes(":") ? column.fieldFormat.split(":")[1] : 2
                        ) + "%") ||
                    (column.fieldFormat &&
                        column.fieldFormat.startsWith("decimal") &&
                        parseFloat(column.field(row)).toFixed(
                            column.fieldFormat.includes(":") ? column.fieldFormat.split(":")[1] : 2
                        )) ||
                    column.field(row)}
                {column.fieldSuffix}
            </>
        );
        if (column.tooltip) {
            if (column.tooltip(row)) {
                return this.renderTooltip(field, column.tooltip(row));
            } else {
                return field;
            }
        } else {
            return field;
        }
    }
    renderResponsiveDialog = () => {
        let mainColumnFound = false;
        return (
            <Dialog
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                open={Boolean(this.state.responsiveActionsRow)}
                onClose={this.handleResponsiveActionsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                PaperComponent={DraggablePaper}
            >
                <DialogTitle id="draggable-control" style={{ cursor: "move" }}>
                    {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                        if (column.main) mainColumnFound = true;
                        return column.main && this.renderCellData(column, this.state.responsiveActionsRow);
                    })}
                    {!mainColumnFound &&
                        this.renderCellData(this.state.responsiveActionsColumns[0], this.state.responsiveActionsRow)}
                    <IconButton
                        aria-label="Close"
                        className={this.props.classes.closeButton}
                        onClick={this.handleResponsiveActionsClose}
                        onTouchStart={this.handleResponsiveActionsClose}
                    >
                        <FAIcon type="light" icon="times" noMargin />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component="div">
                        {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                            return (
                                !column.actions &&
                                !column.hideInResponsiveDialog &&
                                this.renderCellData(column, this.state.responsiveActionsRow, true)
                            );
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                        return (
                            column.actions && (
                                <>
                                    {column.actions(this.state.responsiveActionsRow).map((action, actnIdx) => {
                                        return (
                                            <>
                                                {(action.disabled && (
                                                    <Button disabled key={`col${colIdx}${actnIdx}`}>
                                                        {action.icon && (
                                                            <FAIcon
                                                                type="light"
                                                                icon={action.icon}
                                                                size={action.size ?? 17}
                                                                button
                                                                disabled
                                                            />
                                                        )}{" "}
                                                        {action.name}
                                                    </Button>
                                                )) ||
                                                    (action.link && (
                                                        <Link
                                                            to={`${action.link}`}
                                                            style={{ textDecoration: "none" }}
                                                            key={`col${colIdx}${actnIdx}`}
                                                        >
                                                            <Button>
                                                                {action.icon && (
                                                                    <FAIcon
                                                                        type="light"
                                                                        icon={action.icon}
                                                                        size={action.size ?? 17}
                                                                        button
                                                                    />
                                                                )}{" "}
                                                                {(action.label && action.label) || action.name}
                                                            </Button>
                                                        </Link>
                                                    )) ||
                                                    (action.onClick && (
                                                        <i
                                                            onClick={() => {
                                                                this.handleResponsiveActionsClose();
                                                                action.onClick(this.state.responsiveActionsRow);
                                                            }}
                                                            key={`col${colIdx}${actnIdx}`}
                                                        >
                                                            <Button>
                                                                {action.icon && (
                                                                    <FAIcon
                                                                        type="light"
                                                                        icon={action.icon}
                                                                        size={action.size ?? 17}
                                                                        button
                                                                    />
                                                                )}{" "}
                                                                {(action.label && action.label) || action.name}
                                                            </Button>
                                                        </i>
                                                    ))}
                                            </>
                                        );
                                    })}
                                </>
                            )
                        );
                    })}
                </DialogActions>
            </Dialog>
        );
    };
    render() {
        const { config } = this.props;
        return (
            <div style={{width: '100%'}}>
                <div>
                    {!config.noHeader && !config.inline && this.renderOptions()}
                    {config?.options?.action ?? ""}
                </div>
                <div style={{ maxWidth: "100%", overflowX: config.draggable ? undefined : "auto" }}>
                    <div
                        style={{
                            border: config.withBorder && "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: config.withBorderRadius && "4px",
                        }}
                    >
                        {(config.isLoading === true && <LoadingCircle />) || (
                            <Typography variant="body2" component={Table}>
                                {!config.noHeader && this.renderHeader()}
                                {this.renderBody()}
                                {this.renderTotals()}
                                {this.renderFullTotals()}
                            </Typography>
                        )}
                        {Boolean(this.state.responsiveActionsRow) && this.renderResponsiveDialog()}
                    </div>
                </div>
                <div style={{ marginTop: 8, width: "100%" }}>{this.renderFooter()}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        staff: state.notifications.status,
        ui: state.ui,
        statePersistence: state.statePersistence,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, msg) => dispatch(deploySnackBar(variant, msg)),
        setPersistence: (key, state) => dispatch(setPersistence(key, state)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(DataTable)));
