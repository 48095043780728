import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AsyncSelect from 'Components/Common/Selects/AsyncSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { TextField } from '@material-ui/core';

const SmsDialog = ({defaultFormData, type, id, callback}) => {

    const initialState = {
        defaultOptions: [],
        smsTemplates: [],
        formData: {
            type: '',
            id: '',
            smsTemplate: 0,
            smsFrom: 'AdvAccess',
            smsTo: '',
            smsType: '',
            smsText: ''
        },
        formErrors: {},
        isLoading: true,
    };

    const dispatch = useDispatch();

    const inputType = useRef();
    const inputBody = useRef();

    let [state, setState] = useState(initialState);

    const {
        smsTemplates, 
        formErrors,
        formData, 
        formData:{smsTemplate, smsFrom, smsTo, smsType, smsText}, 
        isLoading
    } = state;
        
    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData:{
                ...formData,
                [fieldName]: selectedOption
            }
        }));
    }

    const handleSmsDialogClose = () => dispatch(closeDialog());

    const handleSubmit = () => dispatch(deployConfirmation('Are you sure you want to send this sms?', submit));

    const loadSmsOptions = (searchString, callback, selectRef) => {
        API.get('/smartSearch', 
            {
                props: {
                    cancellation: true,
                    noLoading: true
                },
                params: { 
                    searchString: searchString,
                    type: 'sms'
                }
            }
        )
        .then(res => {
            
            setState(state => ({
                ...state,
                defaultOptions: res?.data?.results ?? []
            }))

            if(res?.data) {
                callback(res?.data?.results ?? [])
            } else {
                callback([])
            }

            if(selectRef.current.focus) {
                selectRef.current.focus()
            }

        })
    }

    const submit = () => {
        setState(state => ({...state, isLoading: true}));
        API.post(`/sms`, formData)
        .then(result => {
            if(result?.data?.errors) {
                setState(state => ({
                    ...state, 
                    formErrors: formatValidationErrors(result.data.errors), 
                    isLoading: false
                }));
            } else if(result.data.success) {
                if(callback) callback();
                handleSmsDialogClose();
                dispatch(deploySnackBar('success', 'Your SMS has been successfully sent'));
            }
        });
    }

    useEffect(() => {
        if(!_.isEmpty(smsTemplates) && smsTemplate !== null) {
            let idx = _.findIndex(smsTemplates, el => el.value === smsTemplate?.value);
            if(idx !== -1) {
                setState(state => ({
                    ...state,
                    formData: {
                        ...state.formData,
                        smsType: idx === 0 ? 'Custom Message' : smsTemplates[idx].label,
                        smsText: smsTemplates[idx].text
                    }
                }));
                if(idx === 0) {
                    if(inputType?.current?.focus) {
                        inputType.current.focus()
                    }
                } else {    
                    if(inputBody?.current?.focus) {
                        inputBody.current.focus()
                    }
                }
            }
        }

    }, [smsTemplate, smsTemplates]);
    
    useEffect(() => {
        API.get('/sms/templates', { params: { forSms: true } })
        .then(smsRes => {

            let smsTemplates = _.map(smsRes?.data ?? [], el => (
                _.assign({
                    value: el.st_id,
                    label: el.st_name,
                    text: el.st_text,
                })
            ));

            smsTemplates.unshift(
                {
                    value: 0,
                    label: 'None',
                    text: ''
                }
            )

            let smsTemplateId = null;
            switch(type) {
                default:
                    smsTemplateId = 0;
                break;
            }

            setState(state => ({
                ...state, 
                smsTemplates,
                formData: {
                    ...state.formData,
                    type,
                    id,
                    smsTemplate: smsTemplateId,
                },
                isLoading: false
            }));

        });

    }, [id, type]);

    useEffect(() => {
        if(defaultFormData) {
            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    ...defaultFormData
                }
            }))
        }
    }, [defaultFormData]);

    return (
        <>
            {(isLoading && (
                <LoadingCircle />
            )) || (
                <>
                    <form noValidate autoComplete="off">
                        <AsyncSelect
                            apiCall={_.debounce(loadSmsOptions, 250)}
                            creatable
                            creatableMessage={userInput => `Send to mobile number: ${userInput}`}
                            defaultOptions={state.defaultOptions}
                            error={formErrors && formErrors['smsTo'] && true}
                            errorText={formErrors && formErrors['smsTo'] && formErrors['smsTo']}
                            label='Recipient *'
                            onChange={handleSelectChange('smsTo')}
                            value={smsTo}
                        />
                        <AutoCompleteSelect
                            options={smsTemplates} 
                            label='SMS Template'
                            value={smsTemplate}
                            onChange={handleSelectChange('smsTemplate')}
                            error={formErrors && formErrors['smsTemplate'] && true}
                            errorText={formErrors && formErrors['smsTemplate'] && formErrors['smsTemplate']}
                            noClear
                        />
                        <TextField
                            inputRef={inputType}
                            name="smsType"
                            label="Subject"
                            value={smsType}
                            error={formErrors && formErrors['smsType']}
                            errorText={formErrors && formErrors['smsType'] && formErrors['smsType']}
                            onChange={handleChange}
                            fullWidth
                        />
                        <AutoCompleteSelect
                            options={[{label: 'AdvAccess', value: 'AdvAccess'}]} 
                            label='Sender *'
                            value={smsFrom}
                            noClear
                        />
                        <Textarea
                            inputRef={inputBody}
                            name="smsText"
                            className='pt-2'
                            placeholder="Message Body *"
                            variant="outlined"
                            value={smsText}
                            rows={8}
                            error={formErrors && formErrors['smsText']}
                            onChange={handleChange}
                        />
                        <Typography variant="body2" color="textSecondary" style={{marginTop: 10}}>
                            Characters: {smsText.length}/918 | SMS Parts: {smsText.length <= 0 ? 0 : (smsText.length > 160 ? Math.ceil(smsText.length / 153) : 1)}
                        </Typography>
                    </form>
                    <div className="buttonRow">
                        <Button 
                            onClick={handleSmsDialogClose} 
                            variant="text" 
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleSubmit} 
                            autoFocus 
                            variant="text" 
                            color="primary" 
                        >
                            Send
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}

export default SmsDialog;