import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    dateInput: {
        paddingLeft: '8px',
        borderLeft: '7.5px solid #fff',
        borderRight: '7.55px solid #fff',
        background: 'rgba(0, 0, 0, 0.05)',
        '&:before': {
            borderBottom: '1px solid rgba(250,250,250, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(250,250,250, 1)'
        }
    }
});

const initialState = () => ({
    focus: {
        date1: false,
        date2: false,
        date3: false,
        time1: false,
        time2: false
    },
    formData: {
        date1: '',
        date2: '',
        date3: '',
        time1: '',
        time2: ''
    }
});

const ManualDateInput = ({disabled, error, handleChange, helperText, label, noLabel, value, placeholder, variant}) => {

    const classes = useStyles();
    const [state,setState] = useState(initialState());
    const { formData } = state;
    const date1 = useRef(null);
    const date2 = useRef(null);
    const date3 = useRef(null);
    const time1 = useRef(null);
    const time2 = useRef(null);

    const focus = fieldName => {
        setState(state => ({
            ...state,
            focus: {
                ...state.focus,
                [fieldName]: true
            }
        }))
    }

    const blur = fieldName => {
        setState(state => ({
            ...state,
            focus: {
                [fieldName]: false
            }
        }))
    }

    const hocChange = fieldName => {

        const value = fieldName === 'date1' ? date1.current.value : (fieldName === 'date2' ? date2.current.value : (fieldName === 'date3' ? date3.current.value : (fieldName === 'time1' ? time1.current.value : time2.current.value)))
        
        if(fieldName === 'date1' && value.length === 2) {
            date2.current.focus();
        } else if(fieldName === 'date2') {
            if(value.length === 0) {
                date1.current.focus();
            } else if(value.length === 2) {
                date3.current.focus();
            }
        } else if(fieldName === 'date3') {
            if(value.length === 0) {
                date2.current.focus();
            } else if(value.length === 4 && variant === "date-time") {
                time1.current.focus();
            }
        } else if(fieldName === 'time1') {
            if(value.length === 0) {
                date3.current.focus();
            } else if(value.length === 2) {
                time2.current.focus();
            }
        } else if(fieldName === 'time2') {
            if(value.length === 0) {
                time1.current.focus();
            }
        }

        setState(state => ({
            ...state,
            formData:{
                ...state.formData,
                [fieldName]: value
            }
        }))

        if(variant === "date-time") {
            handleChange(`${date1.current.value.padStart(2, '0')}${date2.current.value.padStart(2, '0')}${date3.current.value}${time1.current.value}${time2.current.value}`)
        } else {
            handleChange(`${date1.current.value.padStart(2, '0')}${date2.current.value.padStart(2, '0')}${date3.current.value}`)
        }
    }

    useEffect(() => {
        if(!_.isEmpty(value)) {
            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    date1: value.substr(0,2) ?? '',
                    date2: value.substr(2,2) ?? '',
                    date3: value.substr(4,4) ?? '',
                    time1: value.substr(8,2) ?? '',
                    time2: value.substr(10,2) ?? '',
                }
            }))
        }/* eslint-disable-next-line */
    }, [])
    return (
        <>
            {!noLabel && (
                <InputLabel htmlFor="date1" shrink>{label ?? (variant === "date-time" ? `Date / Time` : `Date`)}</InputLabel>
            )}
            <FormControl fullWidth margin="none">
                <Box pt={1.5}>
                    <Input
                        disabled={disabled}
                        name="date1"
                        className={`${classes.dateInput} ${noLabel ? `m-0 mr-1` : ``}`}
                        value={formData.date1}
                        onChange={() => hocChange('date1')}
                        onFocus={() => focus('date1')}
                        onBlur={() => blur('date1')}
                        variant="filled"
                        margin={noLabel ? 'none' : undefined}
                        placeholder={placeholder?.[1] || ''}
                        inputProps={{
                            ref: date1,
                            maxLength: 2,
                            pattern: '[0-9]*',
                            style: {
                                letterSpacing: 4,
                                fontWeight: 400
                            }
                        }}
                        style={{
                            width: variant === "date-time" ? '15%' : '25%',
                            margin: 0
                        }}
                    />
                    <Input
                        disabled={disabled}
                        name="date2"
                        className={`${classes.dateInput} ${noLabel ? `m-0 mr-1` : ``}`}
                        value={formData.date2}
                        onChange={() => hocChange('date2')}
                        onFocus={() => focus('date2')}
                        onBlur={() => blur('date2')}
                        variant="filled"
                        margin={noLabel ? 'none' : undefined}
                        placeholder={placeholder?.[2] || ''}
                        inputProps={{
                            ref: date2,
                            maxLength: 2,
                            pattern: '[0-9]*',
                            style: {
                                letterSpacing: 4,
                                fontWeight: 400
                            }
                        }}
                        style={{
                            width: variant === "date-time" ? '15%' : '25%',
                            margin: 0
                        }}
                    />
                    <Input
                        disabled={disabled}
                        name="date3"
                        className={`${classes.dateInput} ${noLabel ? `m-0` : ``}`}
                        value={formData.date3}
                        onChange={() => hocChange('date3')}
                        onFocus={() => focus('date3')}
                        onBlur={() => blur('date3')}
                        variant="filled"
                        margin={noLabel ? 'none' : undefined}
                        placeholder={placeholder?.[3] || ''}
                        inputProps={{
                            ref: date3,
                            maxLength: 4,
                            pattern: '[0-9]*',
                            style: {
                                letterSpacing: 4,
                                fontWeight: 400
                            }
                        }}
                        style={{
                            width: variant === "date-time" ? '40%' : '50%',
                            margin: 0
                        }}
                    />
                    {variant === "date-time" && (
                        <>
                            <Input
                                disabled={disabled}
                                name="time1"
                                className={`${classes.dateInput} ${noLabel ? `m-0 mr-1` : ``}`}
                                value={formData.time1}
                                onChange={() => hocChange('time1')}
                                onFocus={() => focus('time1')}
                                onBlur={() => blur('time1')}
                                variant="filled"
                                margin={noLabel ? 'none' : undefined}
                                placeholder={placeholder?.[4] || ''}
                                inputProps={{
                                    ref: time1,
                                    maxLength: 2,
                                    pattern: '[0-9]*',
                                    style: {
                                        letterSpacing: 4,
                                        fontWeight: 400
                                    }
                                }}
                                style={{
                                    width: '15%',
                                    margin: 0
                                }}
                            />
                            <Input
                                disabled={disabled}
                                name="time2"
                                className={`${classes.dateInput} ${noLabel ? `m-0` : ``}`}
                                value={formData.time2}
                                onChange={() => hocChange('time2')}
                                onFocus={() => focus('time2')}
                                onBlur={() => blur('time2')}
                                variant="filled"
                                margin={noLabel ? 'none' : undefined}
                                placeholder={placeholder?.[5] || ''}
                                inputProps={{
                                    ref: time2,
                                    maxLength: 2,
                                    pattern: '[0-9]*',
                                    style: {
                                        letterSpacing: 4,
                                        fontWeight: 400
                                    }
                                }}
                                style={{
                                    width: '15%',
                                    margin: 0
                                }}
                            />
                        </>
                    )}
                    {(helperText || error) && (
                        <Box pt={2}>
                            <FormHelperText>
                                {helperText ?? `Please enter a valid date${variant === "date-time" && ` / time`}`}
                            </FormHelperText>
                        </Box>
                    )}
                </Box>
            </FormControl>
        </>
    )
}

export default ManualDateInput;