import React  from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handleSelectChange } from 'Functions/FormFunctions';

const initialState = () => ({ 
    formData: {
        reason: ''
    },
    isLoading: true,
    reasonList: []
});

class CancelRMA extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount(){
        this.loadComponentData();
    }

    loadComponentData = () => {

        API.get('/rma/cancellation/reasons')
        .then(res => {

            if(res?.data) {

                let isLoading = false,
                    reasonList = _.map(res.data, el => (
                        _.assign({
                            value: el.rr_id,
                            label: el.rr_reason,
                        })
                    ));

                this.setState({
                    reasonList,
                    isLoading
                })

            }

        });

    }

    handleSubmit = () => {

        const {
            formData
        } = this.state;

        const {
            callback,
            closeDialog,
            deploySnackBar,
            id
        } = this.props;

        API.put(`/rma/${id}/cancel`, formData)
        .then(res => {
            if(res?.data) {
                deploySnackBar(`success`, `You have successfully cancelled this RMA`);
                callback();
                closeDialog();
            }
        });

    }
    
    render() {
        const { closeDialog, deployConfirmation } = this.props;
        const { formData, isLoading, reasonList } = this.state;
        return (
            <Grid container spacing={3} className="pt-1">
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect 
                                            options={reasonList} 
                                            label='Why is this RMA being cancelled?'
                                            value={formData.reason}
                                            onChange={o => this.handleSelectChange('reason', o)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <DialogActions className='pr-0 pb-0'>
                                    <Button 
                                        onClick={closeDialog} 
                                        variant="text"
                                    >
                                        <FAIcon icon="times" size={15} button />
                                        Cancel
                                    </Button>
                                    <Button 
                                        color="primary"
                                        onClick={() => deployConfirmation(`Are you sure you want to cancel this RMA?`, this.handleSubmit)} 
                                        variant="text" 
                                    >
                                        <FAIcon icon="check" size={15} button />
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default CancelRMA;