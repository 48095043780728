import React from 'react';
import _ from 'lodash';

import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const Tabs = ({btnUpdateDisabled, classes, currentTab, disableSupplierTab, formData, handleTabChange, id, ifm}) => (
    <MuiTabs
        color="primary"
        onChange={handleTabChange}
        orientation="vertical"
        TabIndicatorProps={{
            style: {
                display: 'none'
            }
        }}
        value={currentTab}
        variant="scrollable"
    >
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={0} disabled={id || disableSupplierTab} label="Supplier" />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={1} disabled={!formData.supplier && true} label="Purchase Order" />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={2} disabled={!formData.supplier && true} label="Order / Delivery" />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={3} disabled={!formData.supplier && true} label="Internal Notes" />
        <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={4} disabled={(btnUpdateDisabled !== undefined ? btnUpdateDisabled : (!formData.supplier || _.isEmpty(formData.productRows))) && true} label="Preview" />
        {/* {ifm && (
            <Tab classes={{root: classes.tabs, selected: classes.tabSelected}} index={5} disabled={currentTab !== 4 || currentTab !== 5} label="Submission" />
        )} */}
    </MuiTabs>
)

export default Tabs;