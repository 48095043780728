import React from 'react';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deployDialog, closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { getFormData } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleTabChange, TabPanel } from 'Functions/MiscFunctions';
import { CLOUDFRONT_URL, VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from 'Constants';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

const initialState = () => ({
    currentTab: 0,
    customisation: {
        banner: {
            quote: '',
            order: '',
            po: ''
        },
        motd: {
            quote: '',
            order: '',
            po: ''
        }
    },
    formData: {
        banner: '',
        motd: '',
        type:''
    },
    formErrors: [],
    keys: {
        quote: uuidv4(),
        order: uuidv4(),
        po: uuidv4()
    },
    initialCustomisation: {
        banner: {
            quote: '',
            order: '',
            po: ''
        },
        motd: {
            quote: '',
            order: '',
            po: ''
        }
    },
    tabs: [
        {
            name: 'Quote PDF',
            shortName: 'Quote',
            data: 'quote',
            type: 'pdf',
            access: true
        },
        {
            name: 'Sales Order PDF',
            shortName: 'Sales Order',
            data: 'order',
            type: 'pdf',
            access: true
        },
        {
            name: 'Purchase Order PDF',
            shortName: 'Purchase Order',
            data: 'po',
            type: 'pdf',
            access: true
        }
    ],
    isLoading: true
})

class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.mainContentArea = React.createRef();
        this.getFormData = getFormData.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Marketing','Document Settings']);
        this.loadComponentData();
    }

    loadComponentData = () => {

        API.get(`/settings/customisation`)
        .then(res => {

            if(res?.data) {
                let customisation           = res?.data?.customisation,
                    initialCustomisation    = res?.data?.initialCustomisation,
                    isLoading               = false,
                    keys                    = {
                        quote: uuidv4(),
                        order: uuidv4(),
                        po: uuidv4()
                    };

                this.setState({
                    customisation,
                    initialCustomisation,
                    isLoading,
                    keys
                })
            }

        })

    }

    handleChange = (name, e) => {
        const { customisation } = this.state;
        this.setState({
            customisation: {
                ...customisation,
                [e.target.name]: {
                    ...customisation[name],
                    [name]: e.target.value
                }
            }
        })
    }

    handleImageChange = (name, file) => {
        const { customisation } = this.state;
        this.setState({
            customisation: {
                ...customisation,
                banner: {
                    ...customisation.banner,
                    [name]: file
                }
            }
        })
    }

    handleEmailChange = e => {
        const { customisation } = this.state;
        this.setState({
            customisation: {
                ...customisation,
                email: {
                    ...customisation.email,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    handleEmailImageChange = (name, file) => {
        const { customisation } = this.state;
        this.setState({
            customisation: {
                ...customisation,
                email: {
                    ...customisation.email,
                    [name]: file
                }
            }
        })
    }

    handleReset = name => {
        const { initialCustomisation } = this.state;
        let customisation = {...this.state.customisation};
        if(name) {
            customisation = {
                ...customisation,
                banner: {
                    ...customisation.banner,
                    [name]: ''
                },
                motd: {
                    ...customisation.motd,
                    [name]: initialCustomisation.motd[name]
                }
            }
        }
        this.setState({
            customisation,
            keys: {
                quote: uuidv4(),
                order: uuidv4(),
                po: uuidv4()
            }
        })
    }

    handlePreview = name => {
        const { customisation } = this.state;
        this.setState({
            formData: {
                banner: customisation?.banner?.[name],
                motd: customisation?.motd?.[name],
                type: name
            }
        }, () => {
            API.post(`/settings/customisation/preview`, this.getFormData())
            .then(res => {
                if(res?.data) {
                    if(res.data?.errors) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors)
                        });
                    } else {
                        this.setState({
                            formErrors: []
                        }, () => {
                            pdfFromBase64(res?.data?.pdf, `AA-${this.state.tabs[this.state.currentTab]?.shortName}-Preview.pdf`)
                        })
                    }
                }
            })
        });
    }

    handleSubmit = name => {
        const { customisation } = this.state;
        this.setState({
            formData: {
                banner: customisation?.banner?.[name],
                motd: customisation?.motd?.[name],
                type: name
            }
        }, () => {
            API.post(`/settings/customisation`, this.getFormData())
            .then(res => {
                if(res?.data) {
                    if(res.data?.errors) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors)
                        });
                    } else {
                        this.loadComponentData();
                        this.props.deploySnackBar("success", `You have successfully updated ${name === 'quote' ? 'quote pdf' : (name === 'order' ? 'sales order pdf' : 'purchase order pdf')} customisation settings`)
                    }
                }
            })
        });
    }

    render() {
        const { classes, deployConfirmation, ui } = this.props;
        const { currentTab, customisation, formErrors, isLoading, initialCustomisation, keys, tabs } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            {tabs?.[currentTab]?.name ?? 'Communication Settings'}
                                                        </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Box height={41.5} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={0.25}>
                                                <Grid container justify="space-between" alignItems='center'>
                                                    <Grid item>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InsightChip
                                                                    icon="exclamation-triangle"
                                                                    label={`Any changes made will be effective immediately, please ensure you preview and check changes before updating!`}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> 
                                            </Box>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            {_.map(tabs, (tab, idx) => (
                                                <TabPanel value={currentTab} key={idx} index={idx}>
                                                    <>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} align='center'>
                                                                <Paper>
                                                                    <Box p={3} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0 : 20}>
                                                                        <Box mb={3}>
                                                                            <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                {tab.shortName} Message
                                                                            </Typography>
                                                                            <Typography variant="body2" align='center'>
                                                                                The {tab.shortName.toLowerCase()} message appears under the address tables at the top of a {tab.shortName.toLowerCase()} and can be used to provide key information to customers<br />(e.g. seasonal opening times or details of upcoming events).
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box textAlign="center" pl={7.5} pr={7.5} pb={1}>
                                                                            <Textarea
                                                                                name="motd"
                                                                                onChange={e => this.handleChange(tab.data, e)}
                                                                                value={customisation?.motd?.[tab.data]}
                                                                                variant="outlined"
                                                                                style={{textAlign: 'center'}}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Paper>
                                                                    <Box p={3} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0 : 20}>
                                                                        <Box mb={3}>
                                                                            <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                {customisation?.banner?.[tab.data] !== '' ? 'Proposed' : 'Current'} {tab.shortName} Banner
                                                                            </Typography>
                                                                            <Typography variant="body2" align='center' gutterBottom>
                                                                                The {tab.shortName.toLowerCase()} banner appears under the footer on each page of a {tab.shortName.toLowerCase()} {tab.shortName !== 'Purchase Order' ? `and can be used to enhance the current promotional message` : `and can be used to provide information to suppliers`}<br />{tab.shortName !== 'Purchase Order' ? `(e.g. promotional campaigns or new product launches)` : `(e.g. key contacts or information)`}.
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box textAlign="center" pl={7.5} pr={7.5} pb={1}>
                                                                            <Box border='1px dashed #ddd' p={1}>
                                                                                {(customisation?.banner?.[tab.data] !== '' && (
                                                                                    <img alt={`${tab.name} Banner`} src={URL.createObjectURL(customisation?.banner?.[tab.data])} style={{width: '100%', height: 'auto'}} />
                                                                                )) || (!_.isEmpty(initialCustomisation?.banner?.[tab.data]) && (
                                                                                    <img alt={`${tab.name} Banner`} src={`${CLOUDFRONT_URL}${initialCustomisation?.banner?.[tab.data]}`} style={{width: '100%', height: 'auto'}} />
                                                                                ))}
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box p={3} pt={0} pl={ui?.device?.isTablet ? 0 : 20} pr={ui?.device?.isTablet ? 0 : 20}>
                                                                        <Box textAlign="center" pl={7.5} pr={7.5} pb={1.25}>
                                                                            <Typography variant="h6" className="fw-400" align='center' gutterBottom>
                                                                                Select New {tab.shortName} Banner
                                                                            </Typography>
                                                                            <Typography variant="body2" align='center' gutterBottom>
                                                                                Please drop or choose a file with a resolution of 2480px x 151px
                                                                            </Typography>
                                                                            <DragFileInput
                                                                                key={keys[tab.data]}
                                                                                name="banner"
                                                                                file={customisation?.banner?.[tab.data]}
                                                                                onChange={(drop, name, file) => this.handleImageChange(tab.data, file)}
                                                                                errorText={formErrors && formErrors['generic']}
                                                                                emptyText='No banner selected'
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                        <Box className="buttonRow" style={{paddingTop: 0}}>
                                                            <Button
                                                                color="primary"
                                                                disabled={customisation?.motd?.[tab.data] === initialCustomisation?.motd?.[tab.data] && customisation?.banner?.[tab.data] === ''}
                                                                onClick={() => this.handleReset(tab.data)}
                                                                variant="contained"
                                                            >
                                                                <FAIcon icon="times" button size={15} disabled={customisation?.motd?.[tab.data] === initialCustomisation?.motd?.[tab.data] && customisation?.banner?.[tab.data] === ''} />
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                disabled={customisation?.motd?.[tab.data] === initialCustomisation?.motd?.[tab.data] && customisation?.banner?.[tab.data] === ''}
                                                                onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">Are sure you want to update {tab.name.toLowerCase()} customisation?</Typography><Typography variant="body2">These changes will take effect immediately</Typography></>, () => this.handleSubmit(tab.data))}
                                                                variant="contained"
                                                            >
                                                                <FAIcon icon="exclamation-triangle" button size={15} disabled={customisation?.motd?.[tab.data] === initialCustomisation?.motd?.[tab.data] && customisation?.banner?.[tab.data] === ''} />
                                                                Update
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-success"
                                                                onClick={() => this.handlePreview(tab.data)}
                                                                variant="contained"
                                                            >
                                                                <FAIcon icon="binoculars" size={15} button />
                                                                Preview
                                                            </Button>
                                                        </Box>
                                                    </>
                                                </TabPanel>
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    {_.map(tabs, (tab, idx) => (
                                        (tab.divider && (
                                            <Divider />
                                        )) || (
                                            <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} disabled={!tab.access} key={idx} value={idx} label={`${tab.name}`} />
                                        )
                                    ))}
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        ui: state.ui
    }
)

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fs = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, disableExit)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(Documents));