import { Box, Paper, Typography } from "@material-ui/core";
import API from "API";
import Alert from "Components/Common/Alert/Alert";
import DataTable from "Components/Common/DataTables/DataTable";
import Heading from "Components/Common/Heading/Heading";
import Highlight from "Components/Common/Highlight/Highlight";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { useEffect, useState } from "react";
import moment from 'moment';
import StatusChip from "Components/Common/Chips/StatusChip";
import _ from 'lodash';
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";

const initialState = () => ({
    isLoading: true,
    stockDueIn: [],
    stockNextDueIn: null,
    stockOverDue: 0
})

const StockDueIn = ({productId}) => {

    const [access, setAccess] = useState(null);
    const [state, setState] = useState(initialState());

    const handleLoadAccess = () => {
        API.multiAccess([
            'view-po'
        ]).then(([viewPurchaseOrder]) => {
            setAccess({
                viewPurchaseOrder
            })
        });
    }

    const handleLoadData = () => {
        API.get(`/products/${productId}/availability`)
        .then (res => {
            if(res?.data) {
                setState(prevState => ({
                    ...prevState,
                    ...res.data,
                    isLoading: false
                }))
            }
        })
    }

    useEffect(() => {
        handleLoadData();
    }, [productId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleLoadAccess();
    }, [])

    return (
        state.isLoading ? (
            <LoadingCircle />
        ) : (
            <Paper>
                <Box p={3}>
                    <Heading text="Expected Stock Deliveries" />
                    {(_.isEmpty(state.stockDueIn) && (
                        <Alert severity="success" variant="outlined">
                            <strong>None -</strong> This product is not currently on-order
                        </Alert>
                    )) || (
                        <>
                            <Typography variant="body2" className="fw-400" paragraph>
                                {state.stockNextDueIn ? `Next expected delivery of this product: ${state.stockNextDueIn}` : null}
                                {state.stockOverDue ? <span className="textError"> ** Delivery Overdue **</span> : null}
                            </Typography>
                            <DataTable
                                config={{
                                    key: "rate_id",
                                    inline: true,
                                }}
                                columns={[
                                    {
                                        heading: "",
                                        field: (rowData) => (
                                            <Box width={20}>
                                                <FAIcon
                                                    icon="circle"
                                                    type="duo"
                                                    size={17.5}
                                                    noMargin
                                                    className={
                                                        ((rowData.pod_status === "Despatched" || rowData.pod_status === "Invoiced") && "textSuccess") ||
                                                        (moment(rowData.pod_expected_despatch_date).isSame(moment()) && "textWarning") ||
                                                        (moment(rowData.pod_expected_despatch_date).isBefore(moment()) && "textError") ||
                                                        "textSuccess"
                                                    }
                                                />
                                            </Box>
                                        ),
                                        sizeToContent: true,
                                        alignment: "center",
                                    },
                                    {
                                        heading: "PO Number",
                                        field: (rowData) => `${rowData.pod_reference}/${rowData.pod_idx}`,
                                        main: true,
                                    },
                                    {
                                        heading: "Qty Ordered",
                                        field: (rowData) => <Highlight variant={"success"}>{rowData.stock_ordered_count}</Highlight>,
                                        sizeToContent: true,
                                        alignment: "center",
                                    },
                                    {
                                        heading: "Qty Outstanding",
                                        field: (rowData) => <Highlight variant={"success"}>{rowData.stock_on_order_count}</Highlight>,
                                        sizeToContent: true,
                                        alignment: "center",
                                    },
                                    {
                                        heading: "Qty Pre-ordered",
                                        field: (rowData) => (
                                            <Highlight variant={rowData.stock_on_order_allocated_count > 0 ? undefined : "success"}>
                                                {rowData.stock_on_order_allocated_count}
                                            </Highlight>
                                        ),
                                        sizeToContent: true,
                                        alignment: "center",
                                    },
                                    {
                                        heading: "Qty Available",
                                        field: (rowData) => {
                                            const av = rowData.stock_on_order_count - rowData.stock_on_order_allocated_count;
                                            return <Highlight variant={av === 0 ? "error" : "success"}>{av}</Highlight>;
                                        },
                                        sizeToContent: true,
                                        alignment: "center",
                                    },
                                    {
                                        heading: "Check Date",
                                        field: (rowData) => rowData.pod_check_date,
                                        fieldFormat: "date",
                                        sizeToContent: true,
                                        alignment: "center",
                                        cellProps: (rowData) => ({
                                            className:
                                                rowData.pod_check_date === "0000-00-00"
                                                    ? undefined
                                                    : moment(rowData.pod_check_date).isBefore(moment())
                                                    ? "textError"
                                                    : undefined,
                                        }),
                                    },
                                    {
                                        heading: "Expected Despatch Date",
                                        field: (rowData) => rowData.pod_expected_despatch_date,
                                        fieldFormat: "date",
                                        sizeToContent: true,
                                        alignment: "center",
                                        cellProps: (rowData) => ({
                                            className: moment(rowData.pod_expected_despatch_date).isBefore(moment()) ? "textError" : undefined,
                                        }),
                                    },
                                    {
                                        heading: "Despatch Date",
                                        field: (rowData) => rowData.pod_despatch_date,
                                        fieldFormat: "date",
                                        sizeToContent: true,
                                        alignment: "center",
                                    },
                                    {
                                        heading: "Status",
                                        field: (rowData) => <StatusChip status={rowData.pod_status} />,
                                        sizeToContent: true,
                                    },
                                    {
                                        actions: (rowData) => {
                                            return [
                                                {
                                                    name: "View Purchase Order",
                                                    icon: "binoculars",
                                                    link: `/purchase-orders/${rowData.pod_po_id}:${rowData.pod_id}`,
                                                    disabled: !access.viewPurchaseOrder,
                                                },
                                            ];
                                        },
                                    },
                                ]}
                                rows={state.stockDueIn}
                            />
                        </>
                    )}
                </Box>
            </Paper>
        )
    )
}

export default StockDueIn;