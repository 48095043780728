import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import ShortlinkForm from 'Components/Marketing/Shortlinks/ShortlinkForm';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import StatusChip from 'Components/Common/Chips/StatusChip';
import { getFormDataHook } from 'Functions/FormFunctions';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { Typography } from '@material-ui/core';

const FileForm = ({callback}) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        file: null
    });

    const handleSubmit = () => {
        API.post(`/marketing/website/files`, getFormDataHook(formData))
        .then(res => {
            if(res?.data?.success) {
                callback?.();
                dispatch(deploySnackBar(`success`, `The file has been successfully uploaded`))
                dispatch(closeDialog())
            }
        })
    }
    
    return (
        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DragFileInput
                        id="file"
                        name="file"
                        label="Choose File *"
                        file={formData.file}
                        onChange={(drop, name, file) => setFormData({...formData, file})}
                        emptyText='No file selected'
                    />
                </Grid>
            </Grid>
            <div className='buttonRow'>
                <Button 
                    type='submit'
                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to upload this file?`, handleSubmit))}
                    variant="text" 
                    color="primary" 
                    disabled={!formData.file}
                >
                    <FAIcon icon="check" size={15} button />
                    Upload
                </Button>
            </div>
        </form>
    )
}

const initialState = {
    access: {
        addShortlink: false,
        deactivateShortlink: false,
        marketingWebsite: false
    },
    searchResults: {},
    searchString: '',
    isLoading: true
}

class Shortlinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentDidMount(){
        this.props.pageTitle?.([0, 'Marketing', 'SEO Shortlinks']);
        API.multiAccess([
            'shortlink-add',
            'shortlink-deactivate',
            'marketing:website'
        ])
        .then(([
            addShortlink,
            deactivateShortlink,
            marketingWebsite
         ]) => {
            this.setState({
                access: {
                    addShortlink,
                    deactivateShortlink,
                    marketingWebsite
                }
            }, () => this.getSearchData());
        })
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.files !== this.props.files) {
            this.getSearchData();
            this.props.pageTitle?.([0, 'Marketing', 'SEO Shortlinks']);
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getSearchData = (isLoading = true) => {
        const { files } = this.props;
        const { searchString } = this.state;
        let params = {
            searchString
        }
        if(files) {
            params = {
                ...params,
                files: 1
            }
        }
        this.setState({
            isLoading
        }, () => {
            API.get('/marketing/shortlinks/all', 
                {
                    params,
                    props: { 
                        cancellation: true 
                    }
                }
            )
            .then(result => {
                if(result?.data) {
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    });
                }
            });
        })
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }
    
    resetSearch = () => {
        this.setState({
            searchString: '',
            dateFrom: null,
            dateTo: null
        }, 
        () => {
            this.getSearchData();
        });
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getSearchData(true), 200);
    }
    
    handleDeactivate = id => API.put(`/marketing/shortlinks/${id}/deactivate`)
    .then(res => {
        if(res?.data) {
            this.getSearchData(false);
            this.props.deploySnackBar("success", "The shortlink has been de-activated");
        }
    })

    handleDeployAddShortlink = () => this.props.deployDialog(
        <ShortlinkForm
            id={false}
            closeDialog={() => {
                this.props.closeDialog();
                this.getSearchData(false);
            }}
            deployConfirmation={this.props.deployConfirmation}
            deploySnackBar={this.props.deploySnackBar}
        />
        , false, "Add Shortlink", "standard", "xs", false, true
    )

    handleDeployUploadFile = () => this.props.deployDialog(
        <FileForm
            callback={() => {
                this.getSearchData(false);
            }}
        />, false, "Upload File", "standard", "xs"
    )

    render() {
        const { files } = this.props;
        const { access, isLoading, searchString, searchResults } = this.state;
        
    const columns = files ? 
                            [
                                {
                                    heading: 'URL',
                                    field: rowData => (
                                        <>
                                            <Typography variant='body2' className="fw-400">{rowData.url}</Typography>
                                            <Typography variant='caption'>{rowData.sl_target}</Typography>
                                        </>
                                    ),
                                    dataRef: 'url',
                                    main: true,
                                    important: true,
                                    sizeToContent: true,
                                    cellProps: rowData => ({
                                        style: {
                                            color: rowData.sl_active ? undefined : '#999'
                                        }
                                    })
                                },
                                {
                                    heading: 'Clicks',
                                    field: rowData => rowData.clicks_count,
                                    important: true,
                                    dataRef: 'clicks_count',
                                    sizeToContent: true,
                                    alignment: 'right',
                                },
                                {
                                    heading: 'Unique Clicks',
                                    field: rowData => rowData.unique_clicks_count,
                                    important: true,
                                    dataRef: 'unique_clicks_count',
                                    sizeToContent: true,
                                    alignment: 'right',
                                },
                                {
                                    heading: 'Created',
                                    field: rowData => rowData.sl_created_datetime,
                                    fieldFormat: 'date',
                                    dataRef: 'sl_created_datetime',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => <StatusChip status={rowData.sl_active === 1 ? 'Active' : 'Inactive'} />,
                                    sizeToContent: true,
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'De-activate', icon: 'trash-alt', onClick: () => this.props.deployConfirmation(`Are you sure you want to de-activate this ${files ? `download` : `shortlink`}?`, () => this.handleDeactivate(rowData.sl_id)), disabled: !rowData.sl_active || (rowData.sl_active && !access.deactivateShortlink && !access.marketingWebsite)},
                                            {name: 'Copy to Clipboard', type: 'copyToClipboard', data: rowData.url, disabled: !rowData.sl_active},
                                            {name: 'Open Link', icon: 'external-link', onClick: () => window.open(rowData.url), disabled: !rowData.sl_active, doubleClick: true}
                                        ]
                                    }
                                }
                            ] : [
                                {
                                    heading: 'Code',
                                    field: rowData => rowData.sl_code,
                                    dataRef: 'sl_code',
                                    main: true,
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Short URL',
                                    field: rowData => rowData.url,
                                    dataRef: 'url',
                                    main: true,
                                    important: true,
                                    sizeToContent: true,
                                    cellProps: rowData => ({
                                        style: {
                                            textDecoration: rowData.sl_active === 0 ? 'line-through' : undefined
                                        }
                                    })
                                },
                                {
                                    heading: 'Target URL',
                                    field: rowData => rowData.sl_target,
                                    truncate: true,
                                    important: true,
                                    dataRef: 'sl_target',
                                },
                                {
                                    heading: 'Clicks',
                                    field: rowData => rowData.clicks_count,
                                    important: true,
                                    dataRef: 'clicks_count',
                                    sizeToContent: true,
                                    alignment: 'right',
                                },
                                {
                                    heading: 'Unique Clicks',
                                    field: rowData => rowData.unique_clicks_count,
                                    important: true,
                                    dataRef: 'unique_clicks_count',
                                    sizeToContent: true,
                                    alignment: 'right',
                                },
                                {
                                    heading: 'Created',
                                    field: rowData => rowData.sl_created_datetime,
                                    fieldFormat: 'date',
                                    dataRef: 'sl_created_datetime',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => <StatusChip status={rowData.sl_active === 1 ? 'Active' : 'Inactive'} />,
                                    sizeToContent: true,
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'De-activate', icon: 'trash-alt', onClick: () => this.props.deployConfirmation(`Are you sure you want to de-activate this ${files ? `download` : `shortlink`}?`, () => this.handleDeactivate(rowData.sl_id)), disabled: !rowData.sl_active || (rowData.sl_active && !access.deactivateShortlink && !access.marketingWebsite)},
                                            {name: 'Copy to Clipboard', type: 'copyToClipboard', data: rowData.url, disabled: !rowData.sl_active},
                                            {name: 'Open Link', icon: 'external-link', onClick: () => window.open(rowData.url), disabled: !rowData.sl_active, doubleClick: true}
                                        ]
                                    }
                                }
                            ];

        return (
            <Grid container spacing={1}>      
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                        <TextField 
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder='Search:'
                            value={searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                            }}
                        /> 
                    </Grid>      
                    {access.addShortlink && !files ? (
                        <Grid item xs={12} sm={7} md={8} xl={9} align='right'> 
                            <Button 
                                color="primary" 
                                size="small"
                                variant="text"
                                onClick={() => this.handleDeployAddShortlink()}
                            >   
                                <FAIcon type="light" icon="plus-circle" size="small" button />
                                Add Shortlink
                            </Button>
                        </Grid>
                    ) : files && access.marketingWebsite ? (
                        <Grid item xs={12} sm={7} md={8} xl={9} align='right'> 
                            <Button 
                                color="primary" 
                                size="small"
                                variant="text"
                                onClick={() => this.handleDeployUploadFile()}
                            >   
                                <FAIcon type="light" icon="plus-circle" size="small" button />
                                Upload File
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>           
                <Grid item xs={12}>
                    <Paper>
                        <DataTable  
                            config={{
                                key: 'sl_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: isLoading,
                                responsiveImportance: true,
                                options: {
                                    dataRef: true,
                                    reset: this.resetSearch,
                                    export: {
                                        title: files ? `Website Downloads` : `Short Links`,
                                        name: files ? `downloads` : `short-links`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={columns}
                            rows={searchResults}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fs = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, de)),
    }
)

export default connect(null, mapDispatchToProps)(Shortlinks);