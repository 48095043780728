import React from "react";
import _ from "lodash";

import { Box, Grid, Paper, TextField, Typography } from "@material-ui/core";

import Alert from "Components/Common/Alert/Alert";
import API from "API";
import AppButton from "Components/Common/Buttons/AppButton";
import DeliverySummary from "Components/Sales/Misc/DeliverySummary";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import NotificationDialog from "Components/Common/Dialogs/NotificationDialog";
import Textarea from "Components/Common/Inputs/Textarea";
import ViewNotes from "Components/Common/Activity/ViewNotes";
// import Camera from 'Components/Common/Camera/Camera';
import Signature from "Components/Common/Signature/Signature";

import { getFormDataHook } from "Functions/FormFunctions";
import { base64ImgtoFile } from "Functions/MiscFunctions";

const initialState = () => ({
    isLoading: false,
    name: "",
    img: null,
    sig: null,
    notes: "",
});

class Collection extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        const { deployDialog, od } = this.props;

        if (od?.cashOutstanding > 0) {
            deployDialog(
                <NotificationDialog
                    title={`Sales Order #${od.od_reference}`}
                    text={`This order has an outstanding balance due on collection of £${od.cashOutstanding} before you release it`}
                    variant={`error`}
                />,
                false,
                "Payment Outstanding",
                "error",
                "sm"
            );
        } else {
            deployDialog(
                <NotificationDialog
                    title={`Sales Order #${od.od_reference}`}
                    text={`This order has been paid and can be released to the customer`}
                />,
                false,
                "Order Paid",
                "success",
                "sm"
            );
        }
    };

    handleCollectionName = (e) => {
        let name = e?.target?.value ?? "";
        this.setState({
            name,
        });
    };

    handleCollectionSignature = (sig) => {
        this.setState({
            sig,
        });
    };

    handleCollectionPhoto = (uri) => {
        let img = base64ImgtoFile(uri);
        this.setState({
            img,
        });
    };

    handleNotes = (notes) => {
        this.setState({
            notes,
        });
    };

    handleSubmit = () => {
        const { deploySnackBar, history, id, processingComplete, qrScanned, warehouseMode } = this.props;

        this.setState(
            {
                isLoading: true,
            },
            () => {
                const { img, name, notes, sig } = this.state;

                let formData = {
                    name,
                    notes,
                    qrScanned,
                };

                if (img) {
                    formData = {
                        ...formData,
                        img,
                    };
                }

                if (sig) {
                    formData = {
                        ...formData,
                        sig,
                    };
                }

                formData = getFormDataHook(formData);

                API.post(`/warehouse/delivery/${id}/collected`, formData).then((res) => {
                    if (res?.data) {
                        if (res.data?.cancelled) {
                            this.handleErrorRedirect("cancelled");
                        } else if (res.data?.hold) {
                            this.handleErrorRedirect("placed on hold");
                        } else if (res.data?.despatched) {
                            processingComplete();
                            deploySnackBar(`error`, `Delivery already collected`);
                            let redirect = warehouseMode ? `/dashboard` : `/warehouse/outgoing/ready-for-collection`;
                            this.timeout = setTimeout(() => history.push(redirect), 750);
                        } else {
                            processingComplete();
                            deploySnackBar(`success`, `Delivery successfully collected`);
                            let redirect = warehouseMode ? `/dashboard` : `/warehouse/outgoing/ready-for-collection`;
                            this.timeout = setTimeout(() => history.push(redirect), 750);
                        }
                    }
                });
            }
        );
    };

    render = () => {
        const { img, isLoading, name, notes, sig } = this.state;
        const { currentTab, deployConfirmation, history, order, od, qrScanned } = this.props;
        return (
            (isLoading && <LoadingCircle />) ||
            (currentTab === 0 && (
                <Paper>
                    <Box p={3}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="h6">Notes</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <ViewNotes inline notes={order.notes} hideDeleteButton />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )) ||
            (currentTab === 1 && (
                <DeliverySummary
                    od={od}
                    order={order}
                    idx={_.findIndex(order.deliveries, (el) => el.od_id === od.od_id) + 1}
                />
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12} align="center">
                        <Typography variant="h5" component="div" className="fw-500">
                            Customer Collection
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {(qrScanned === 1 && (
                            <Alert severity="success">The customer collection code was scanned</Alert>
                        )) || <Alert severity="warning">Customer collection code not scanned</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" className="fw-400" align="center" paragraph>
                            Collected By
                        </Typography>
                        <Paper elevation={0}>
                            <TextField
                                className="bg-light"
                                name="name"
                                value={name}
                                onChange={this.handleCollectionName}
                                margin="none"
                                variant="outlined"
                                fullWidth
                            />
                        </Paper>
                    </Grid>
                    {/* <Grid item xs={12}>
                            <Typography variant="body1" align='center' className="fw-400">
                                Collection Photo
                            </Typography>
                            <Box mt={2}>
                                <Camera fullWidth handleSave={this.handleCollectionPhoto} isSaved={img} />
                            </Box>
                        </Grid> */}
                    <Grid item xs={12}>
                        <Typography variant="body1" align="center" className="fw-400">
                            Collection Signature
                        </Typography>
                        <Box mt={2}>
                            <Signature fullWidth handleSave={this.handleCollectionSignature} isSaved={sig} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="body1" align="center" className="fw-400" paragraph>
                            Collection Notes
                        </Typography>
                        <Paper elevation={0}>
                            <Textarea
                                margin="none"
                                onChange={(e) => this.handleNotes(e?.target?.value ?? "")}
                                value={notes}
                                variant="outlined"
                                rows={1}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <AppButton
                            disabled={!name || (!img && !sig)}
                            className="btn btn-success"
                            icon="check"
                            onClick={() => deployConfirmation(`Are you sure you want to continue?`, this.handleSubmit)}
                            primary
                            text="Mark as Collected"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AppButton
                            back
                            className="btn btn-error"
                            onClick={() =>
                                deployConfirmation(`Are you sure you want to cancel this collection?`, () =>
                                    history.push("/dashboard")
                                )
                            }
                            text="Cancel"
                            icon="times"
                        />
                    </Grid>
                </Grid>
            )
        );
    };
}

export default Collection;
