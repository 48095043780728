import { START_RECORDING, STOP_RECORDING } from 'Helpers/WildixHelper';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const WildixContext = React.createContext();

const WildixProvider = ({children}) => {
    const [dial, setDial] = React.useState(false);
    const dispatch = useDispatch();

    const useDial = (phoneNumber) => {
        if(dial === null) {
            if(phoneNumber === START_RECORDING || phoneNumber === STOP_RECORDING) {
                setDial(phoneNumber)
            } else {
                dispatch(deployConfirmation(`Are you sure you want to dial ${phoneNumber}?`, () => setDial(phoneNumber.replace(/\s/g, ''))))
            }
        } else {
            dispatch(deploySnackBar(`error`, `Could not connect to the VOIP service`))
        }
    }

    const value = {
        dial, 
        setDial,
        useDial
    }

    return (
        <WildixContext.Provider value={value}>
            {children}
        </WildixContext.Provider>
    )
}

export { WildixProvider };

export default WildixContext;