import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import { formatValidationErrors, formatValidationWarnings } from 'Helpers/ErrorHelper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { CLOUDFRONT_URL } from 'Constants';

const initialState = () => ({
    leaderStaffList: [],
    staffList: [],
    categories: {},
    categoryList: [],
    subCategories: {},
    subCategoryList: [],
    customerList: [],
    customerContacts: {},
    customerContactList: [],
    supplierList: [],
    supplierContacts: {},
    supplierContactList: [],
    formData: {
        eventAllDay: false,
        ignoreWarnings: false,
        eventStart: null,
        eventFinish: null,
        category: null,
        subCategory: null,
        leader: null,
        meetingWith: null,
        staffAttendees: [],
        supplierAttendees: [],
        customerAttendees: [],
        name: '',
        notes: '',
        email: 0
    },
    formErrors: [],
    formWarnings: [],
    isLoading: true,
    wasEmailed: false
})

class CalendarEventForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount(){
        this.getCategories();
        this.getCustomers();
        this.getStaff();
        this.getSuppliers();
    }

    getCategories = () => {
        API.get('/calendar/categories/all', { params: { enabledOnly: true } }).then(result => {
            if(result?.data) {
                let categoryList = _.map(result.data, (cat) => {
                    return _.assign({
                        value: cat.category_id,
                        label: cat.category_name
                    });
                });
                this.setState({
                    categoryList: categoryList,
                    categories: result.data,
                    isLoading: !this.props.id ? false : true
                }, () => {
                    if(this.props.id) {
                        API.get(`/calendar/events/${this.props.id}`)
                        .then(result => {
                            if(result?.data) {
                                if (result.data.errors) {
                                    this.props.closeDialog();
                                } else if (result.data) {
                                    let staffAttendees = [];
                                    _.map(result.data.staff_attendees, (staff) => {
                                        staffAttendees.push(staff.staff_id);
                                    });
                                    let customerAttendees = [];
                                    _.map(result.data.customer_attendees, (contact) => {
                                        customerAttendees.push(contact.customer_attendee_contact_id);
                                    });
                                    let supplierAttendees = [];
                                    _.map(result.data.supplier_attendees, (contact) => {
                                        supplierAttendees.push(contact.supplier_attendee_contact_id);
                                    });
                                    this.setState({
                                        formData: {
                                            eventStart: result.data.calendar_event_start_datetime,
                                            eventFinish: result.data.calendar_event_end_datetime,
                                            category: result.data.calendar_event_parent_category_id,
                                            subCategory: result.data.calendar_event_child_category_id,
                                            leader: result.data.calendar_event_leading_staff_id,
                                            meetingWith: result.data.calendar_event_with,
                                            staffAttendees: staffAttendees,
                                            customer: result.data.customer && result.data.customer.cust_id,
                                            customerAttendees: customerAttendees,
                                            supplier: result.data.supplier && result.data.supplier.supp_id,
                                            supplierAttendees: supplierAttendees,
                                            name: result.data.calendar_event_name,
                                            notes: result.data.calendar_event_notes,
                                            eventAllDay: (result.data.calendar_event_start_datetime.endsWith('09:00:00') && result.data.calendar_event_end_datetime.endsWith('17:30:00')),
                                            email: result.data.calendar_event_email === 1 ? true : false
                                        },
                                        isLoading: false,
                                        wasEmailed: result.data.calendar_event_email === 1 ? true : false
                                    }, () => {
                                        this.getStaff();
                                        this.getSubCategories(this.state.formData.category);
                                        this.getCustomerContacts(this.state.formData.customer, true);
                                        this.getSupplierContacts(this.state.formData.supplier, true);
                                    });
                                }
                            }
                        });
                    }
                });
            }
        });
    };
    getSubCategories = categoryId => {
        if(categoryId) {
            let catList = _.find(this.state.categories, {'category_id': categoryId});
            let subCategoryList = _.map(catList.children, (cat) => (
                _.assign({
                value: cat.category_id,
                label: cat.category_name
                })
            ));
            this.setState({
                subCategoryList: subCategoryList
            });
        } else {
            this.setState({
                subCategoryList: initialState().subCategoryList,
                formData: {
                    ...this.state.formData,
                    subCategory: null
                }
            });
        }
    };

    getCustomers = () => {
        API.get('/customers/all', { params: { forList: true }})
        .then(result => {
            if(result?.data) {
                let customerList = _.map(result.data, el => (
                    _.assign({
                        value: el.v,
                        label: el.l
                    })
                ));
                this.setState({
                    customerList
                });
            }
        });
    }

    getStaff = () => {
        API.get('/staff/all', { params: { active: true } })
        .then(result => {
            if(result?.data) {
                let staffList = _.map(result.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name,
                        disabled: false
                    });
                });
                let leaderStaffList = _.map(result.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name
                    });
                });
                this.setState({
                    staffList: staffList,
                    leaderStaffList: leaderStaffList
                });
            }
        });
    }

    getSuppliers = () => {
        API.get('/suppliers/all', { params: { forList: true }})
        .then(result => {
            if(result?.data) {
                let supplierList = _.map(result.data, el => (
                    _.assign({
                        value: el.v,
                        label: el.l
                    })
                ));
                this.setState({
                    supplierList
                });
            }
        });
    }

    getSupplierContacts = (supplierId, saveState = false) => {
        if(supplierId) {
            API.get(`/suppliers/${supplierId}`)
            .then(res => {
                if(res?.data) {
                    let supplierContactList = _.map(res?.data?.contacts, cont => {
                        return _.assign({
                            value: cont.i,
                            label: cont.fn + ' ' + cont.ln
                        });
                    });
                    this.setState({
                        supplierContactList: supplierContactList,
                        formData: {
                            ...this.state.formData,
                            supplierAttendees: saveState ? this.state.formData.supplierAttendees : []
                        }                            
                    });
                }
            })
        } else {
            this.setState({
                supplierContactList: initialState().supplierContactList,
                formData: {
                    ...this.state.formData,
                    supplierAttendees: []
                }
            });
        }
    }
    getCustomerContacts = (customerId, saveState = false) => {
        if(customerId) {
            API.get(`/customers/${customerId}`)
            .then(res => {
                if(res?.data) {
                    let customerContactList = _.map(res?.data?.contacts, cont => {
                        return _.assign({
                            value: cont.i,
                            label: cont.fn + ' ' + cont.ln
                        });
                    });
                    this.setState({
                        customerContactList: customerContactList,
                        formData: {
                            ...this.state.formData,
                            customerAttendees: saveState ? this.state.formData.customerAttendees : []
                        }
                    });
                }
            })
        } else {
            this.setState({
                customerContactList: initialState().customerContactList,
                formData: {
                    ...this.state.formData,
                    customerAttendees: []
                }
            });
        }
    }

    handleSubmit = (ignoreWarnings) => {

        let newFormData = new FormData();

        Object.keys(this.state.formData).forEach(key => {
            if(key === "eventStart" || key === "eventFinish") {
                newFormData.append(key, moment(this.state.formData[key]).format(this.state.formData.eventAllDay ? "YYYY-MM-DD" : "YYYY-MM-DD H:mm"));
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });

        if(ignoreWarnings) {
            newFormData.append("ignoreWarnings",  true);
        }

        API.post(`/calendar/events${this.props.id ? `/${this.props.id}` : ``}`, newFormData)
        .then(result => {
            if(result?.data) {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors)
                    });
                } else if(result.data.warnings && result.data.warnings.length > 0){           
                    this.setState({
                        formWarnings: formatValidationWarnings(result.data.warnings)
                    }, () => {
                        this.props.deployConfirmation(
                            <>
                                <Box pb={2}>
                                    <Alert severity="warning">
                                        The following people are already attending an event
                                    </Alert>
                                </Box>
                                {Object.values(this.state.formWarnings).map(value => {
                                    let staffValues = value.split("|");
                                    return (
                                        <React.Fragment>
                                            <Chip
                                                avatar={<Avatar alt={staffValues[0]} src={`${CLOUDFRONT_URL}${staffValues[1]}`} />}
                                                label={staffValues[0]}
                                                size="small"
                                                variant="outlined"
                                                style={{border: 0, fontWeight: 400}}
                                            />
                                        </React.Fragment>
                                    )
                                })}
                                <Box pt={2}>
                                    <Typography variant="body2">
                                        Would you like to proceed with this event anyway?
                                    </Typography>
                                </Box>
                            </>
                            , () => this.handleSubmit(true)
                        )
                    });
                } else {
                    this.props.deploySnackBar("success", "You have successfully added the calendar event");
                    this.props.callback?.();
                    this.props.closeDialog?.();
                }
            }
        });
    }
    
    handleCheckChange = name => event => {
        this.setState({
            formData: {
            ...this.state.formData,
            [name]: event.target.checked
            }
        });
    };

    handleDateChange = name => date => {
        this.setState({
        formData: {
            ...this.state.formData,
            [name]: date
        }
        }, () => {
            if(name === "eventStart" && this.state.formData.eventAllDay) {
                this.handleDateChange("eventFinish")(date);
            } else {
                if(name === "eventStart" && this.state.formData.eventFinish && moment(this.state.formData.eventFinish) < moment(this.state.formData.eventStart))
                    this.handleDateChange("eventFinish")(moment(date).add(1, 'hour'));
                else if(name === "eventStart" && !this.state.formData.eventFinish)
                    this.handleDateChange("eventFinish")(moment(date).add(1, 'hour'));
                else if(name === "eventFinish" && this.state.formData.eventFinish && moment(this.state.formData.eventFinish) < moment(this.state.formData.eventStart))
                    this.handleDateChange("eventStart")(moment(date).subtract(1, 'hour'));
                else if(name === "eventFinish" && !this.state.formData.eventStart)
                    this.handleDateChange("eventStart")(moment(date).subtract(1, 'hour'));
            }
        });
    };

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            if(fieldName === 'supplier'){
                this.getSupplierContacts(selectedOption && selectedOption.value);
            }
            else if(fieldName === 'customer'){
                this.getCustomerContacts(selectedOption && selectedOption.value);
            }
            else if(fieldName === 'category'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        subCategory: null
                    }
                },
                () => {
                    this.getSubCategories(selectedOption && selectedOption.value);
                });
            } 
            else if(fieldName === 'leader') {
                let newStaffList = [...this.state.staffList];
                let newStaffAttendeesList = [...this.state.formData.staffAttendees];
                for (let i in newStaffList) {
                    if(newStaffList[i].value === selectedOption.value) {
                        newStaffList[i].disabled = (newStaffList[i].value === selectedOption.value);
                        if(this.state.formData.staffAttendees.includes(selectedOption.value)) {
                            newStaffAttendeesList = newStaffAttendeesList.filter(staffId => staffId !== selectedOption.value);
                        }
                    } else {
                        newStaffList[i].disabled = false;
                    }
                }
                this.setState({
                    staffList: newStaffList,
                    formData: {
                        ...this.state.formData,
                        staffAttendees: newStaffAttendeesList
                    }
                });
            }
            else if(fieldName === "meetingWith") {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        customerAttendees: [],
                        supplierAttendees: []
                    }
                })
            }
        });
    };
    
    render = () => {
        const { formData, formErrors, isLoading } = this.state;
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <form noValidate autoComplete="off">
                    <Box pb={1}>
                        <FormControlLabel
                            control={
                                <Switch name="eventAllDay"
                                        checked={this.state.formData.eventAllDay}
                                        onChange={this.handleCheckChange('eventAllDay')}
                                        value={true}
                                        color="primary"
                                        margin="normal" />
                            }
                            label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Is this an all day event?</span>}
                        />
                        <FormControlLabel
                            control={
                                <Switch name="email"
                                        disabled={this.props.id && this.state.wasEmailed}
                                        checked={this.state.formData.email}
                                        onChange={this.handleCheckChange('email')}
                                        value={true}
                                        color="primary"
                                        margin="normal" />
                            }
                            disabled={this.props.id && this.state.wasEmailed}
                            label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Email invitations to attendees?</span>}
                        />
                    </Box>
                    <TextField
                        id="name"
                        name="name"
                        label="Meeting Name"
                        error={formErrors && formErrors['name'] && true}
                        helperText={formErrors && formErrors['name']}
                        value={this.state.formData.name}
                        onChange={this.handleChange}
                        fullWidth
                    />  
                    <Box pt={1}>
                        <DatePicker
                            type={this.state.formData.eventAllDay ? 'date' : 'datetime'}
                            id="eventStart"
                            name="eventStart"
                            label={(!this.state.formData.eventAllDay && "Event Start *") || "Event Date *"}
                            disablePast={true}
                            value={this.state.formData.eventStart}
                            errorText={formErrors && formErrors['eventStart']}
                            onChange={this.handleDateChange('eventStart')}
                            autoOk={true}
                        />
                    </Box>
                    {!this.state.formData.eventAllDay && (
                        <Box pt={2}>
                            <DatePicker
                                type={this.state.formData.eventAllDay ? 'date' : 'datetime'}
                                id="eventFinish"
                                name="eventFinish"
                                label="Event Finish *"
                                disablePast={true}
                                value={this.state.formData.eventFinish}
                                errorText={formErrors && formErrors['eventFinish']}
                                onChange={this.handleDateChange('eventFinish')}
                                autoOk={true}
                            />
                        </Box>
                    )}
                    <Box pt={2}>
                        <AutoCompleteSelect 
                            options={this.state.categoryList} 
                            label='Category *'
                            value={this.state.formData.category}
                            onChange={this.handleSelectChange('category')}
                            error={formErrors && formErrors['category'] && true}
                            errorText={formErrors && formErrors['category'] && formErrors['category']}
                            noClear
                        />
                    </Box>
                    <AutoCompleteSelect
                        disabled={!this.state.formData.category || this.state.formData.category === ""}
                        options={this.state.subCategoryList} 
                        label='Sub Category *'
                        value={this.state.formData.subCategory}
                        onChange={this.handleSelectChange('subCategory')}
                        error={formErrors && formErrors['subCategory'] && true}
                        errorText={formErrors && formErrors['subCategory'] && formErrors['subCategory']}
                        noClear
                    />
                    <AutoCompleteSelect 
                        options={this.state.leaderStaffList} 
                        label='Leader *'
                        value={this.state.formData.leader}
                        onChange={this.handleSelectChange('leader')}
                        error={formErrors && formErrors['leader'] && true}
                        errorText={formErrors && formErrors['leader']}
                        noClear
                    />
                    {this.state.formData.category && this.state.formData.category === 1 && (
                        <>
                            <AutoCompleteSelect 
                                options={[
                                {value: 'internal', label: 'Internal'},
                                {value: 'supplier', label: 'Supplier'},
                                {value: 'customer', label: 'Customer'}
                                ]} 
                                label='Meeting With *'
                                value={this.state.formData.meetingWith}
                                onChange={this.handleSelectChange('meetingWith')}
                                error={formErrors && formErrors['meetingWith'] && true}
                                errorText={formErrors && formErrors['meetingWith']}
                            />
                            {this.state.formData.meetingWith && this.state.formData.meetingWith === 'supplier' && (
                                <>
                                    <AutoCompleteSelect 
                                        options={this.state.supplierList} 
                                        label='Supplier *'
                                        value={this.state.formData.supplier}
                                        onChange={this.handleSelectChange('supplier')}
                                        error={formErrors && formErrors['supplier'] && true}
                                        errorText={formErrors && formErrors['supplier'] && formErrors['supplier']}
                                    />
                                    <AutoCompleteMultiSelect 
                                        disabled={!this.state.formData.supplier || this.state.formData.supplier === ""}
                                        options={this.state.supplierContactList} 
                                        label='Supplier Attendees *'
                                        value={this.state.formData.supplierAttendees}
                                        onChange={this.handleMultiSelectChange('supplierAttendees')}
                                        error={formErrors && formErrors['supplierAttendees'] && true}
                                        errorText={formErrors && formErrors['supplierAttendees']}
                                    />
                                </>
                            )}    
                            {this.state.formData.meetingWith && this.state.formData.meetingWith === 'customer' && (
                                <>
                                    <AutoCompleteSelect 
                                        options={this.state.customerList}
                                        label='Customer *'
                                        value={this.state.formData.customer}
                                        onChange={this.handleSelectChange('customer')}
                                        error={formErrors && formErrors['customer'] && true}
                                        errorText={formErrors && formErrors['customer'] && formErrors['customer']}
                                        noOptionsMessage={({inputValue}) => _.isEmpty(inputValue) ? 'Start typing to search customers...' : 'No customers found'}
                                    />
                                    <AutoCompleteMultiSelect 
                                        disabled={!this.state.formData.customer || this.state.formData.customer === ""}
                                        options={this.state.customerContactList} 
                                        label='Customer Attendees *'
                                        value={this.state.formData.customerAttendees}
                                        onChange={this.handleMultiSelectChange('customerAttendees')}
                                        error={formErrors && formErrors['customerAttendees'] && true}
                                        errorText={formErrors && formErrors['customerAttendees']}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <AutoCompleteMultiSelect 
                        options={this.state.staffList} 
                        label='Staff Attendees'
                        value={this.state.formData.staffAttendees}
                        onChange={this.handleMultiSelectChange('staffAttendees')}
                        error={formErrors && formErrors['staffAttendees'] && true}
                        errorText={formErrors && formErrors['staffAttendees']}
                    />
                    <Box pt={1}>
                        <Textarea
                            id="notes"
                            name="notes"
                            label="Notes"
                            value={this.state.formData.notes}
                            onChange={this.handleChange}
                            variant="outlined"
                        />
                    </Box>
                    <div className='buttonRow'>
                        <Button 
                            onClick={() => this.props.deployConfirmation(`Are you sure you want to ${this.props.id ? `update` : `add`} this calendar event?`, this.handleSubmit)}
                            variant="text"
                            color="primary"
                            disabled={Object.values(formData).every(x => (x === null || x === ""))}
                        >
                            <FAIcon icon="check" button size={15} />
                            {this.props.id ? `Update Event` : `Add Event`}
                        </Button>
                    </div>
                </form>
            )
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success, cancel = false, successText = null, cancelText = null, successOnly = false) => dispatch(deployConfirmation(message, success, cancel, successText, cancelText, successOnly)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    }
)

export default connect(null, mapDispatchToProps)(CalendarEventForm);