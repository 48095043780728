import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import MeetingInviteForm from 'Components/Meetings/MeetingInviteForm/MeetingInviteForm';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleChange, handleCheckedChange, handleSelectChange } from 'Functions/FormFunctions';

const initialState = {
    formData: {
        meetingName: '',
        inviteType: null,
        staffContact: null,
        supplier: null,
        customer: null,
        contact: null,
        sendSmsInvite: false,
        smsName: '',
        smsNumber: '',
        smsBody: 'Please click the following link to join your Advanced Access meeting:',
        sendEmailInvite: false,
        emailName: '',
        emailAddress: '',
        emailBody: 'Please click the following link to join your Advanced Access meeting:'
    },
    formErrors: [],
    isLoading: false,
}

class AdditionalInvite extends React.Component {
    constructor(props) {
        super(props);        
        this.state = initialState;
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    updateFormData = formData => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...formData
            }
        })
    }

    handleSubmit = () => {
        API.put('/meetings/' + this.props.meetingId + '/invite', this.state.formData)
        .then(result => {
            if(result?.data) {
                if(result.data.errors) {
                    this.setState({
                        isLoading: false,
                        formErrors: formatValidationErrors(result.data.errors)
                    });
                } else {
                    this.props.deploySnackBar("success", "The new invite was sent successfully");
                    this.props.callback();
                }
            }
        });
    };

    render() {
        const { closeDialog, deployConfirmation, meetingName } = this.props;
        const { formData, formErrors, isLoading } = this.state;
        return (
            <Box mt={1}>
                {(isLoading && (
                    <LoadingCircle />
                )) || (
                    <>
                        <Box mb={1}>
                            <Typography variant="caption" color="textSecondary">
                                Meeting
                            </Typography>
                            <Typography variant="body1">
                                {meetingName}
                            </Typography>
                        </Box>
                        <MeetingInviteForm
                            formData={formData}
                            formErrors={formErrors}
                            handleChange={this.handleChange}
                            handleCheckedChange={this.handleCheckedChange}
                            handleSelectChange={this.handleSelectChange}
                            updateFormData={this.updateFormData}
                        />
                        <div className="buttonRow">
                            <Button 
                                onClick={closeDialog} 
                                variant="text" 
                            >
                                Cancel
                            </Button>
                            <Button 
                                color="primary"
                                onClick={() => deployConfirmation("Are you sure you want to send this invite?", this.handleSubmit)}
                                variant="text"
                            >
                                Send Invite
                            </Button>
                        </div>
                    </>
                )}
            </Box>
        )
    }
}

export default AdditionalInvite;