import React from 'react';
import { useHistory } from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { isTablet, hex2rgba } from 'Functions/MiscFunctions';

const Tile = ({color, content, icon, iconType, title, noPaper, onClick, small, textClass}) => {
    let tile = <TileContent color={color} content={content} icon={icon} iconType={iconType} onClick={onClick} noPaper={noPaper} small={small} textClass={textClass} title={title} />
    if(!noPaper) {
        tile = (
            <Paper>
                {tile}
            </Paper>
        )
    }
    return (
        tile
    )
}


const TileContent = ({color, content, icon, iconType, noPaper, onClick, small, textClass, title}) => {

    const history = useHistory();
    
    const handleClick = onClick => {
        if(typeof onClick === "function") {
            onClick();
        } else {
            history.push(onClick);
        }
    }

    return (
        (noPaper && (
            <Grid container spacing={small ? 1 : 2} alignItems='center' className={`${onClick ? ' link' : ''}`} onClick={() => onClick && handleClick(onClick)}>      
                {icon && (
                    <Grid item>
                        <FAIcon type={iconType ?? 'thin'} icon={icon} style={{color: color, paddingTop: small ? 2 : 0}} size={small ? 20 : (isTablet() ? 25 : 35)} noMargin />
                    </Grid>
                )}
                <Grid item xs>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} align='left'>
                            <Typography variant={small ? 'body2' : 'h5'} className={`textDefault ${textClass ? textClass : ``}`}>
                                {content}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align='left'>
                            <Typography variant="caption">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>  
            </Grid>
        )) || (
            <Grid container className={`tileContainer${onClick ? ' link' : ''}`} onClick={() => onClick && handleClick(onClick)}>      
                {icon && (
                    <Grid item container alignItems='center' justify='center' className="tileSquare" style={{backgroundColor: !noPaper ? hex2rgba(color, 0.75) : undefined, padding: noPaper ? 0 : undefined}}>
                        <FAIcon type={iconType ?? 'thin'} icon={icon} className={!noPaper ? 'tileIcon' : ''} style={{color: noPaper ? color : undefined}} size={isTablet() ? 25 : 35} noMargin />
                    </Grid>
                )}
                <Grid item className="tileContent" style={{padding: noPaper ? 0 : undefined}}>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} align='left'>
                            <Typography variant='h5' className="textDefault">
                                {content}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align='left'>
                            <Typography variant="caption">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>  
            </Grid>
        )
    )
}

export default Tile;