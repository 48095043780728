import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleChange, handleChangeUc, handleCheckedChange, handleFloat } from 'Functions/FormFunctions';
import { currencyFormat } from 'Functions/MiscFunctions';

const initialState = () => ({ 
    access: {
        paymentTerms: false,
    },
    customer: {},
    formData: {
        accountNumber: '',
        legalStatus: '',
        companyRegistrationNumber: '',
        companyVATNumber: '',
        vatType: '',
        paymentTerm: 1,
        poRequired: 0,
        creditLimit: "0.00",
        eulerCover: "0.00",
        emailCredit: 0,
        emailCreditName: '',
        emailCreditAddress: ''
    },
    formErrors: [],
    isLoading: true,
    activating: false,
    paymentTermsList: [],
    vatList: []
})

class CreateAccount extends React.Component {
    constructor(props) {
        super(props);
        this.customerId = this.props.match?.params?.id ?? this.props.id;
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleChangeUc = handleChangeUc.bind(this);
        this.handleFloat = handleFloat.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
    }

    componentDidMount = () => {

        Promise.all([
            API.access('convert-prospect:payment-terms'),
            API.get(`/customers/${this.customerId}`, { params: { suggestAccNo: true }}),
            API.get('/accounts/vatRates/byType'),
            API.get('/accounts/paymentTerms'),
        ])
        .then(([ptAccessRes, cus, vatRes, paymentTermRes]) => {

            let customer = cus.data,
                paymentTermsList = [],
                vatList = [];

            if(vatRes.data){
                vatRes.data.forEach((type) => {
                    let currentValue = _.find(type.vat_values, {current: true});
                    if(currentValue) {
                        vatList.push({
                            value: currentValue.vat_value_type_id,
                            label: type.vat_type_name + ' (' + currentValue.vat_value_percentage + '%)',
                        });
                    }
                });
            }

            if(paymentTermRes.data){
                paymentTermsList = _.map(_.filter(paymentTermRes.data, _el => _el.pt_name !== "As Agreed"), el => {
                    return _.assign({
                        value: el.pt_id,
                        label: el.pt_name,
                        days: el.pt_due_days
                    });
                });
            }

            this.setState({
                access: {
                    ...this.state.access,
                    paymentTerms: ptAccessRes.data.has_access ?? false,
                },
                customer,
                formData: {
                    ...this.state.formData,
                    accountNumber: customer.cust_suggested_acc,
                    legalStatus: customer.cust_legal_status,
                    companyRegistrationNumber: customer.cust_reg_number,
                    companyVATNumber: customer.cust_vat_number,
                    paymentTerm: (customer.payment_terms && customer.payment_terms.pt_id) || "",
                    vatType: customer.cust_vat_type_id,
                    emailCreditName: customer.cust_company_name,
                    emailCreditAddress: customer.cust_email,
                },
                paymentTermsList,
                vatList,
                isLoading: false
            })

        });
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value,
            }
        }, () => {
            if(fieldName === 'paymentTerm'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        creditLimit: selectedOption?.days > 0 ? "" : initialState().formData.creditLimit,
                        eulerCover: selectedOption?.days > 0 ? "" : initialState().formData.eulerCover,
                        emailCredit: selectedOption?.days > 0 ? 1 : 0,
                    }
                });
            }
        });
    } 

    handleSubmit = () => {
        this.setState({
            isLoading: true,
            activating: true,
        }, () => {
            API.put(`/customers/prospects/${this.customerId}/convert`, this.state.formData)
            .then(result => {
                if(result?.data?.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false,
                        activating: false
                    }, () => {
                        if(_.size(result.data.errors) === 1 && result.data.errors?.[0]?.msg === "RED could not communicate with Sage") {
                            this.props.deploySnackBar("error", "RED cannot open this account right now, please contact the help desk")
                        }
                    })
                } else {
                    if(result?.data?.duplicate) {
                        this.props.deploySnackBar("warning", "An account has already been opened for this prospect - please refresh any open pages!");
                    } else {
                        this.props.deploySnackBar("success", "You have successfully opened an account for this prospect")
                    }
                    this.props.closeDialog();
                    this.props.history.push(`/customers/${this.customerId}`)
                }
            });
        })
    }
    
    render = () => {
        const { access, activating, customer, formData, formErrors, isLoading, paymentTermsList, vatList } = this.state;
        const ptDays = paymentTermsList?.[_.findIndex(paymentTermsList, {value: formData.paymentTerm})]?.days;
        const isCredit = ptDays > 0;
        return (
            <Grid container spacing={3} className="pt-3">
                {(isLoading && (
                    <Grid item xs={12} align='center'>
                        <LoadingCircle />
                        {activating && (
                            <Typography variant="h6" paragraph>
                                Opening Account
                            </Typography>
                        )}
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                Account Status
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="accountNumber"
                                                name="accountNumber"
                                                label={`Account Number`}
                                                value={formData.accountNumber}
                                                error={(formData.accountNumber === '' || formErrors?.['accountNumber']) && true}
                                                helperText={formErrors?.['accountNumber']}
                                                onChange={this.handleChangeUc}
                                                margin="none"
                                                inputProps={{
                                                    maxLength: 8
                                                }}
                                                fullWidth
                                                autoFocus
                                                required
                                            />
                                            <AutoCompleteSelect 
                                                options={vatList}
                                                label='VAT Type *'
                                                value={formData.vatType}
                                                onChange={this.handleSelectChange('vatType')}
                                                error={formErrors && formErrors['vatType'] && true}
                                                errorText={formErrors && formErrors['vatType']}
                                                noClear
                                            />
                                            <AutoCompleteSelect
                                                disabled={!access.paymentTerms}
                                                options={paymentTermsList}
                                                label='Payment Terms *'
                                                value={formData.paymentTerm}
                                                onChange={this.handleSelectChange('paymentTerm')}
                                                error={formErrors && formErrors['paymentTerm'] && true}
                                                errorText={formErrors && formErrors['paymentTerm']}
                                                noClear
                                            />
                                            {isCredit && (
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} lg={6}>
                                                        <TextField
                                                            id="creditLimit"
                                                            name="creditLimit"
                                                            label="Credit Limit"
                                                            value={formData.creditLimit}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <FAIcon icon="pound-sign" size={15} type="thin" noMargin />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            error={(formData.creditLimit === '' || formErrors?.['creditLimit']) && true}
                                                            helperText={formErrors && formErrors['creditLimit']}
                                                            onChange={this.handleChange}
                                                            type="number"
                                                            onBlur={this.handleFloat}
                                                            margin="none"
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <TextField
                                                            id="eulerCover"
                                                            name="eulerCover"
                                                            label="Euler Insured Limit"
                                                            value={formData.eulerCover}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <FAIcon icon="pound-sign" size={15} type="thin" noMargin />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            error={(formData.eulerCover === '' || formErrors?.['eulerCover']) && true}
                                                            helperText={formErrors && formErrors['eulerCover']}
                                                            onChange={this.handleChange}
                                                            type="number"
                                                            onBlur={this.handleFloat}
                                                            margin="none"
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            className="pb-1"
                                                            control={
                                                                <Switch color="primary" checked={formData.emailCredit} onChange={this.handleCheckedChange} value="1" name="emailCredit" />
                                                            }
                                                            label={<><Typography className="fw-400 pb-1" variant="body2">Send Credit Application Successful E-mail</Typography>
                                                            <TextField
                                                                placeholder="Contact Name"
                                                                name="emailCreditName"
                                                                value={formData.emailCreditName}
                                                                error={formErrors && formErrors['emailCreditName'] && true}
                                                                helperText={formErrors && formErrors['emailCreditName']}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FAIcon icon="user" size={15} type="thin" noMargin />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                size="small"
                                                                margin="none"
                                                                fullWidth
                                                                required
                                                                disabled={formData.emailCredit === 0}
                                                            />
                                                            <TextField
                                                                className="pt-1"
                                                                placeholder="Email Address"
                                                                name="emailCreditAddress"
                                                                value={formData.emailCreditAddress}
                                                                error={formErrors && formErrors['emailCreditAddress'] && true}
                                                                helperText={formErrors && formErrors['emailCreditAddress']}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FAIcon icon="at" size={15} type="thin" noMargin />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                size="small"
                                                                margin="none"
                                                                fullWidth
                                                                required
                                                                disabled={formData.emailCredit === 0}
                                                            /></>}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                Company Details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'Limited Company', label: 'Limited Company'},
                                                        {value: 'Public Limited Company', label: 'Public Limited Company'},
                                                        {value: 'Sole Trader', label: 'Sole Trader'},
                                                        {value: 'Partnership', label: 'Partnership'},
                                                        {value: 'Consumer', label: 'Consumer'},
                                                        {value: 'Other', label: 'Other'}
                                                    ]} 
                                                    label='Legal Entity'
                                                    value={formData.legalStatus}
                                                    onChange={this.handleSelectChange('legalStatus')}
                                                    error={formErrors && formErrors['legalStatus'] && true}
                                                    errorText={formErrors && formErrors['legalStatus']}
                                                />
                                            </FormControl>
                                            <TextField
                                                id="companyRegistrationNumber"
                                                name="companyRegistrationNumber"
                                                label="Registration Number"
                                                value={formData.companyRegistrationNumber}
                                                error={formErrors && formErrors['companyRegistrationNumber'] && true}
                                                helperText={formErrors && formErrors['companyRegistrationNumber']}
                                                onChange={this.handleChange}
                                                margin="none"
                                                fullWidth
                                            />
                                            <TextField
                                                id="companyVATNumber"
                                                name="companyVATNumber"
                                                label="VAT Number"
                                                value={formData.companyVATNumber}
                                                error={formErrors && formErrors['companyVATNumber'] && true}
                                                helperText={formErrors && formErrors['companyVATNumber']}
                                                onChange={this.handleChange}
                                                margin="none"
                                                type="number"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            GB
                                                        </InputAdornment>
                                                    )
                                                }}
                                                fullWidth
                                            />
                                            <FormControl fullWidth>
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {
                                                            label: "Yes",
                                                            value: 1
                                                        },
                                                        {
                                                            label: "No",
                                                            value: 0
                                                        }
                                                    ]}
                                                    label='PO Required'
                                                    value={formData.poRequired}
                                                    onChange={this.handleSelectChange('poRequired')}
                                                    error={formErrors && formErrors['poRequired'] && true}
                                                    errorText={formErrors && formErrors['poRequired']}
                                                    noClear
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button 
                                            onClick={this.props.closeDialog} 
                                            variant="text"
                                        >
                                            <FAIcon icon="times" size={15} button />
                                            Cancel
                                        </Button>
                                        <Button 
                                            color="primary"
                                            onClick={() => this.props.deployConfirmation((
                                                <>
                                                    <Typography variant="h6">
                                                        Review and Confirm
                                                    </Typography>
                                                    <Box pt={2}>
                                                        <Alert severity={isCredit ? 'warning' : 'success'}>
                                                            <Typography variant="body2" className="fw-400">
                                                                {isCredit ? `Credit` : `Credit Card`} Account
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                You are about to create a <strong>{isCredit ? `credit` : `credit card`} account</strong> in Sage for <strong>{customer.cust_company_name}</strong>{isCredit && formData.creditLimit !== '' ? <span>, with a credit limit of <strong>{currencyFormat.format(formData.creditLimit)}</strong></span> : ``}{isCredit ? <span>, due <strong>{ptDays} days</strong> end-of-month. Please ensure you have all required documentation and completed the necessary checks before proceeding.</span> : ` - all sales orders will require pre-payment.`}
                                                            </Typography>
                                                        </Alert>
                                                        {isCredit && (
                                                            <Alert className="mt-3" severity={formData.emailCredit === 0 ? 'warning' : 'success'}>
                                                                <Typography variant="body2" className="fw-400">
                                                                    Credit Application Successful E-mail
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    {(formData.emailCredit === 0 && (
                                                                        `No e-mail will be sent to the customer notifying them of this.`
                                                                    )) || (
                                                                        <span>The credit application success e-mail will be automatically sent to:<br /><strong>{formData.emailCreditName} - {formData.emailCreditAddress}</strong></span>
                                                                    )}
                                                                </Typography>
                                                            </Alert>
                                                        )}
                                                    </Box>
                                                    <Typography variant="body2" className="fw-400 pt-3">
                                                        Are you sure you want to continue?
                                                    </Typography>
                                                </>
                                            ), this.handleSubmit)} 
                                            variant="text" 
                                            disabled={formData.accountNumber === '' || formData.creditLimit === '' || formData.eulerCover === '' || (formData.emailCredit === 1 && (formData.emailCreditName === '' || formData.emailCreditAddress === ''))}
                                        >
                                            <FAIcon icon="check" size={15} button />
                                            Create Account
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(closeDialog()),
    deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
})

export default connect(null, mapDispatchToProps)(CreateAccount);