import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const InactiveCustomers = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/inactiveCustomers',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.sgr,
                        dataRef: 'sgr',
                        important: true,
                        sizeToContent: true
                    },
                    // {
                    //     heading: 'Credit Limit',
                    //     field: rowData => rowData.cl,
                    //     fieldFormat: 'currency',
                    //     dataRef: 'cl',
                    //     important: true,
                    //     sizeToContent: true
                    // },
                    // {
                    //     heading: 'Balance',
                    //     field: rowData => rowData.cb,
                    //     fieldFormat: 'currency',
                    //     dataRef: 'cb',
                    //     important: true,
                    //     sizeToContent: true
                    // },
                    {
                        heading: 'Last Invoice Date',
                        field: rowData => rowData.lid,
                        dataRef: 'lid',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Turnover YTD',
                        field: rowData => rowData.tytd,
                        fieldFormat: 'currency',
                        dataRef: 'tytd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.cac,
                        dataRef: 'cac',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Type',
                        field: rowData => rowData.at,
                        dataRef: 'at',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Proactive Responsibility',
                        field: rowData => rowData.s > 0 ? <StaffChip staff={staff?.[rowData?.s]} /> : '-',
                        fieldFormat: 'staff',
                        dataRef: 's',
                        important: true,
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'inactive-customers',
                noResultsText: 'Sorry, no inactive customers to show within since your chosen date',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Customers'],
                pdf: true,
                persistenceId: 'inactiveCustomers',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: true,
                showDateRange: false,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Inactive Customers Report'
            }}
        />
    )
}

export default InactiveCustomers;