import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
// import { bankHolidays } from 'Components/Common/DatePickers/BankHolidays';

const datePickerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#ef3340'
        },
        secondary: {
            main: '#D61F26'
        },
        error: {
            main: '#d9534f'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                '&:nth-child(2)': {
                    color: "white",
                    backgroundColor: '#ef3340',
                }
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                },
                '&:after': {
                    borderBottom: '2px solid #ef3340'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #ef3340'
                }
            }
        },
        MuiFilledInput: {
            root: {
                border: '1px solid',
                borderColor: '#e0e0e0',
                borderBottom: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                marginTop: 0,
                marginBottom: 0,
            },
            adornedStart: {
                backgroundColor: '#fff!important',
                borderBottom: 'none'
            },
            input: {
                caretColor: '#ef3340',
                padding: '12px 12px 10px',
                backgroundColor: '#fff',
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:after': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:hover:not($disabled):not($error):before': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:focus': {
                    borderBottom: '1px solid #e0e0e0'
                }
            }
        },
        MuiInputAdornment: {
            root: {
                marginRight: 4
            }
        }
    }
});

const _DatePicker = ({
    autoOk, 
    disabled, 
    disableFuture, 
    disablePast, 
    endAdornment,
    errorText,
    id, 
    inputVariant, 
    label, 
    name, 
    minDate,
    maxDate,
    minDateMessage,
    maxDateMessage,
    noAdornment, 
    onChange, 
    placeholder, 
    type, 
    value
}) => {

    const ElementTag = type === 'datetime' ? DateTimePicker : DatePicker;

    // const handleDisabledDates = date => {
    //     let d   = moment(date),
    //         day = d?.day?.() ?? 0;

    //     if(day === 0 || day === 6 || bankHolidays?.includes?.(d.format('YYYY-MM-DD'))) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    return (
        <FormControl fullWidth margin="none" error={!!errorText}>
            <MuiThemeProvider theme={datePickerTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ElementTag
                        ampm={false}
                        autoOk={autoOk}
                        disabled={disabled}
                        disableFuture={disableFuture}
                        disablePast={disablePast}
                        format={type === 'datetime' ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
                        id={id}
                        InputLabelProps={{
                            error: !!errorText,
                            style: {
                                fontWeight: 300
                            }
                        }}
                        InputProps={{
                            error: !!errorText,
                            startAdornment: !noAdornment && (
                                <InputAdornment>
                                    <FAIcon type="thin" icon='calendar-alt' disabled={disabled} size={17.5} />
                                </InputAdornment>
                            ),
                            endAdornment: endAdornment ?? false
                        }}
                        inputVariant={inputVariant ?? undefined}
                        label={label}
                        name={name}
                        onChange={onChange}
                        placeholder={placeholder}
                        // shouldDisableDate={handleDisabledDates}
                        minDate={minDate}
                        maxDate={maxDate}
                        minDateMessage={disabled ? null : (minDateMessage ?? "This date is in the past")}
                        maxDateMessage={disabled ? null : (maxDateMessage ?? "This date is too far in the future")}
                        value={value}
                    />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
            {typeof errorText === 'string' &&
                <FormHelperText>{errorText}</FormHelperText>
            }
        </FormControl>
    )
}

export default _DatePicker;