import moment from 'moment';
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { useDispatch, useSelector } from "react-redux";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import API from "API";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { closeDialog } from "Redux/Actions/Dialog/Dialog";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import StackTable from 'Components/Common/DataTables/StackTable';

const ReviewTrade = ({ callback, id, request }) => {

    const dispatch = useDispatch();

    const history = useHistory();
    
    const staff = useSelector(state => state.notifications.status);

    const staffMember = useSelector(state => state.staffAuth.staff.name);

    const [isLoading, setIsLoading] = useState(false);

    const handleViewCustomer = () => {
        history.push(`/${request?.customer?.cust_account_number ? `customers` : `new-business`}/${request.rao_cust_id}`)
        dispatch(closeDialog())
    }
    
    const handleDecline = () => {
        setIsLoading(true);
        API.put(`/accounts/accountOpening/requests/${id}/decline`)
        .then(res => {
            if(res?.data) {
                dispatch(deploySnackBar(`success`, `You have successfully declined this account opening request`))
                callback?.();
            }
            setIsLoading(false);
        })
    }

    const handlePrint = () => {
        const title = `Account Application Form`;
        window.open('/print/' + btoa(JSON.stringify({...request.request, title, staffMember})), 'Form', 'width=1280,height=720');
    }

    return (
        isLoading ? (
            <LoadingCircle />
        ) : (
            <Box mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <StackTable
                                    elevation={0}
                                    padding={0}
                                    rows={[
                                        {
                                            heading: 'Company Name',
                                            value: request?.customer?.cust_company_name ?? ''
                                        },
                                        {
                                            heading: 'Request Source',
                                            value: request.rao_trr_id > 0 ? `Direct - Installer Portal Trade Account Application` : `RED`
                                        },
                                        {
                                            heading: 'Requested Account',
                                            value: `${request.rao_prepay ? `Prepay` : `Credit`} Account`
                                        },
                                        {
                                            heading: 'Requested Credit Limit',
                                            value: request?.request?.data?.creditLimit ? `£${request?.request?.data?.creditLimit}` : ' Not Specified',
                                            disabled: request.rao_prepay
                                        },
                                        {
                                            heading: 'Requested By',
                                            value: staff?.[request.rao_staff_id]?.name ?? ''
                                        },
                                        {
                                            heading: 'Requested On',
                                            value: moment(request.rao_created_datetime).format('DD/MM/YYYY HH:mm')
                                        },
                                        {
                                            heading: 'Notes',
                                            value: request.rao_notes ? request.rao_notes.split?.('\n').map((item, key) => (
                                                <Typography 
                                                    key={key} 
                                                    variant="caption"
                                                    component="div" 
                                                    paragraph
                                                >
                                                    {item}
                                                </Typography>
                                            )) :  'No notes provided'
                                        },
                                    ]}
                                />
                            </Grid>
                            {!request.rao_actioned ? (
                                <Grid item xs={12}>
                                    <Box className="content-light-grey mt-2 mb-2 p-2">
                                        <Typography variant="body2" className="fw-400" gutterBottom>
                                            &bull; To accept this request, please View Prospect and open an account using the standard process - this will automatically mark this request as accepted.
                                        </Typography>
                                        <Typography variant="body2" className="fw-400">
                                            &bull; To decline this request, please use the Decline button below{request.rao_trr_id && !request.rao_prepay ? ` - the customer will receive an e-mail with the option to apply for a prepay proforma account which will create a prepay account opening request (if accepted), please do not open the account as a prepay account now instead.` : !request?.rao_trr_id ? ` - this will notify the requester.` : '.'}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ) : null}
                            <Grid item xs>
                                <Grid container>
                                    <Grid item>
                                        <Button
                                            onClick={() => dispatch(closeDialog())}
                                            variant="text"
                                        >
                                            <FAIcon icon="times-circle" size={15} button />
                                            Close
                                        </Button>
                                    </Grid>
                                    {request?.request?.data ? (
                                        <Grid item>
                                            <Button
                                                onClick={() => handlePrint()}
                                                variant="text"
                                            >
                                                <FAIcon icon="print" size={15} button />
                                                Print
                                            </Button>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                            {!request.rao_actioned ? (
                                <>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item>
                                                <Button
                                                    onClick={() => dispatch(deployConfirmation(`Are you sure you want to decline this account opening request?${request?.trr_id && !request?.rao_prepay ? ` The customer will receive an e-mail with the option to apply for a prepay proforma account` : ``}.`, handleDecline))}
                                                    variant="text"
                                                >
                                                    <FAIcon icon="exclamation-circle" size={15} button />
                                                    Decline
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => handleViewCustomer()}
                                                    variant="text"
                                                >
                                                    <FAIcon icon="file-lines" size={15} button />
                                                    View Prospect
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                request?.rao_cust_id ? (
                                    <Grid item>
                                        <Button
                                            onClick={() => handleViewCustomer()}
                                            variant="text"
                                        >
                                            <FAIcon icon="file-lines" size={15} button />
                                            {request?.rao_accepted ? 'View Customer' : 'View Prospect'}
                                        </Button>
                                    </Grid>
                                ) : null
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    )
}

export default ReviewTrade;