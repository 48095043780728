import React, { useState }from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';

import Search from 'Components/Common/Search/Search';

import API from 'API';
import StaffChip from 'Components/Common/Chips/StaffChip';
import ReviewAccountOpening from './ReviewAccountOpening';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';

const AccountOpeningRequests = ({credit, prepay, pageTitle}) => {
    
    const [key, setKey] = useState(uuidv4());

    const dispatch = useDispatch(); 

    const staff = useSelector(state => state.notifications.status);

    const handleReviewRequest = (id) => {
        API.get(`/accounts/accountOpening/requests/${id}`)
        .then(res => {
            if(res?.data?.rao_id) {
                const callback = () => {
                    dispatch(closeDialog());
                    setKey(uuidv4());
                }
                dispatch(deployDialog(<ReviewAccountOpening id={id} callback={callback} request={res.data} />, false, `Review Account Opening`, 'standard', 'md'))
            }
        })
    }

    const handleInProgressUser = (id, ps) => {
        dispatch(
            deployConfirmation(`Are you sure you want to ${ps > 0 ? `remove the review flag from this request?` : `mark this request as under review?`}`, () => {
                API.put(`/accounts/accountOpening/requests/${id}/pending`).then((res) => {
                    if (res?.data?.success) {
                        dispatch(deploySnackBar(`success`, `You have successfully ${ps > 0 ? `cleared the review flag for this request` : `marked this request as under review`}`));
                        setKey(uuidv4());
                    }
                });
            })
        );
    };

    return (
        <Search
            key={`${prepay ? 'prepay' : 'credit'}-acc-op-req`}
            innerKey={key}
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiParams: {
                    credit: credit,
                    prepay: prepay
                },
                apiRoute: '/accounts/accountOpening/requests',
                columns: [
                    {
                        heading: 'Actioned',
                        field: rowData => rowData.cp,
                        fieldFormat: 'boolean',
                        important: true,
                        alignment: 'center',
                        sizeToContent: true,
                    },
                    {
                        heading: 'Accepted',
                        field: rowData => rowData.ac,
                        fieldFormat: 'boolean',
                        important: true,
                        alignment: 'center',
                        sizeToContent: true,
                    },
                    {
                        heading: 'Requested',
                        field: rowData => rowData.ca,
                        fieldFormat: 'datetime',
                        sizeToContent: true,
                    },
                    {
                        heading: 'Type',
                        field: rowData => rowData.ty ? `Direct - Installer Portal` : `RED`,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Customer',
                        field: rowData => rowData.cn ?? '-',
                        main: true,
                    },
                    {
                        heading: 'Requested By',
                        field: rowData => rowData.rs === 0 ? '-' : <StaffChip staff={staff[rowData.rs]} />,
                        fieldFormat: 'staff',
                    },
                    {
                        heading: 'Under Review By',
                        field: rowData => rowData.ps === 0 ? '-' : <StaffChip staff={staff[rowData.ps]} />,
                        fieldFormat: 'staff',
                    },
                    {
                        heading: 'Actioned By',
                        field: rowData => rowData.cs === 0 ? '-' : <StaffChip staff={staff[rowData.cs]} />,
                        fieldFormat: 'staff',
                    },
                    {
                        actions: rowData => {
                            return [
                                { name: rowData.ps > 0 ? "Remove Review Flag" : "Mark as Under Review", icon: rowData.ps > 0 ? "user-slash" : "user", onClick: (rowData) => handleInProgressUser(rowData.id, rowData.ps), disabled: rowData.cp === 1},
                                {name: 'Review', icon: 'binoculars', onClick: () => handleReviewRequest(rowData.id)},
                            ]
                        }
                    }
                ],
                dataRef: false,
                excel: false,
                key: 'id',
                name: 'acc-op-req',
                pagination: true,
                pageTitleData: [0, 'Finance', 'Account Opening Requests', credit ? `Credit Accounts` : `Prepay Accounts`],
                pdf: false,
                persistenceId: null,
                placeholderData: false,
                print: false,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showActioned: true,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Account Opening Requests'
            }}
        />
    )
}

export default AccountOpeningRequests;