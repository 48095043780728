import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import API from 'API';
import DateSelect from 'Components/Common/Selects/DateSelect';
import SearchWarehouseTable from './SearchWarehouseTable';
import { capitalize } from 'Functions/MiscFunctions';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        viewOrder: false
    },
    dataLoading: true,
    date: 'threeMonths',
    searchResults: [],
})

class SearchWarehouse extends React.Component {
    constructor(props) {
        super(props);
        
        const {
            type
        } = this.props;

        this.persistenceId = `warehouse:${type}`;
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = null;
    }

    componentDidMount = () => {
        
        const {
            type
        } = this.props;

        this.props.pageTitle?.([1, 'Warehouse', capitalize(type)])

        if(!this.hasPageState()) {
            Promise.all([
                API.access('view-order')
            ])
            .then(([viewOrder]) =>  {
                this.setState({
                    access: {
                        viewOrder: viewOrder?.data?.has_access ?? false,
                    }
                }, () => {
                    this.getSearchData();
                })
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        
        if((this.props.location?.pathname !== prevProps.location?.pathname) || (this.props.type !== prevProps.type)) {
            
            const {
                type
            } = this.props;

            this.props.pageTitle?.([1, 'Warehouse', capitalize(type)])

            this.persistenceId = `warehouse:${type}`;

            if(this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(initialState())
                });
            } else {
                this.setState({
                    date: 'threeMonths',
                }, () => {
                    this.getSearchData();
                })

            }
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout)
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.getSearchData();
        });
    }

    getSearchData = () => {

        let params = {
            d: this.state.date,
            type: this.props.type
        }

        this.setState({
            dataLoading: true
        }, () => {
            API.get('/warehouse', {
                props: {
                    cancellation: true
                },
                params
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    }, () => {
                        this.savePageState();
                    })
                } 
            })
        })
    }

    resetSearch = () => {
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access
            }, () => {
                this.getSearchData();
            })
        }, 250);
    }

    render = () => {
        const { type } = this.props;
        let { access, dataLoading, date, searchResults } = this.state;
        return (
            <Grid container spacing={1}>  
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} sm={4} md={4} xl={2} style={{marginBottom: 12}} className="pr-3">
                        <DateSelect
                            onChange={this.handleSelectChange('date')}
                            value={date}
                        />
                    </Grid>           
                </Grid>           
                <Grid item xs={12}>
                    <Paper>
                        <SearchWarehouseTable 
                            access={access}
                            dataLoading={dataLoading} 
                            persistenceId={this.persistenceId}
                            resetSearch={this.resetSearch}
                            rows={searchResults} 
                            type={type}
                        />
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence,
})

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchWarehouse);