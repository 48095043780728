import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { 
    Box,
    Button, 
    Grid,
    InputAdornment, 
    TextField,
    Typography
} from '@material-ui/core';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { isNumeric } from 'Functions/MiscFunctions';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import Alert from 'Components/Common/Alert/Alert';

const ModifyPurchaseOrderPricing = ({callback, id}) => {

    const [isLoading, setLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [po, setPurchaseOrder]  = useState(null);

    const dispatch = useDispatch();

    const loadComponentData = () => {
        API.get(`/purchaseOrders/${id}`)
        .then(res => {
            if(res?.data) {
                
                let idx = 0,
                    newFormData = [];

                _.forEach(res.data.products, pop => {
                    newFormData.push({
                        idx: idx++,
                        id: pop.popId,
                        code: pop.productCode,
                        name: pop.productName,
                        quantity: pop.quantity,
                        buyPrice: pop.cost,
                        totalBuyPrice: pop.totalCost,
                        status: pop.pop_status
                    })
                })

                setPurchaseOrder(res.data);
                setFormData(newFormData);
                setLoading(false);

            }
        })
    }

    const handleClose = () => {
        dispatch(closeDialog())
    }

    const handleChange = (idx, price) => {
        let newFormData = [...formData];
        newFormData[idx].buyPrice = price;
        setFormData(newFormData);
    }

    const handleFloat = (idx) => {
        let newFormData = [...formData];
        newFormData[idx].buyPrice = parseFloat(newFormData[idx].buyPrice).toFixed(2);
        newFormData[idx].totalBuyPrice = (parseInt(newFormData[idx].quantity) * parseFloat(newFormData[idx].buyPrice)).toFixed(2);
        setFormData(newFormData);
    }

    const handleConfirmation = () => {
        dispatch(deployConfirmation(`Are you sure you want to update the prices for this purchase order?`, handleSubmit))
    }
 
    const handleSubmit = () => {
        setLoading(true);
        API.put(`/purchaseOrders/${id}/priceChange`, formData)
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `The purchase order pricing has been successfully updated`))
                callback();
            } else {
                dispatch(deploySnackBar(`error`, `Something went wrong, please try again...`))
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        loadComponentData();
    }, []); /* eslint-disable-line */

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Alert severity="info">
                        <Typography variant="body2" className="fw-400">
                            Please modify the buy prices as required below - there will be no impact to fulfilment using this form.<br />
                            After updating, if you need to send this to <em>{po.supplier?.supp_company_name}</em> you can either use <em>Re-send Submission E-mail</em> in the menu or download the latest <em>AA-Purchase-Order-Request-{po.po_reference}.pdf</em> file from the Files tab and e-mail it direct to: <em>{po.supplier?.supp_email}</em>
                        </Typography>
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        config={{
                            key: 'idx',
                            alternatingRowColours: true,
                            noRowHover: true,
                            inline: true,
                            showFullTotals: true
                        }}
                        columns={[
                            {
                                heading: 'Part Code',
                                field: rowData => rowData.code,
                                showTotalLabel: true,
                            },
                            {
                                heading: 'Description',
                                field: rowData => rowData.name,
                            },
                            {
                                heading: 'Quantity',
                                field: rowData => rowData.quantity,
                            },
                            {
                                heading: 'Buy Price',
                                field: rowData => (
                                    <Box width={150}>
                                        <TextField
                                            margin="none" 
                                            autoComplete="off"
                                            value={rowData.buyPrice}
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        £
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    border: rowData.buyPrice === '' && rowData.apply === 1 ? '2px solid #ef3340' : undefined
                                                }
                                            }}  
                                            onChange={e => handleChange(rowData.idx, e?.target?.value)}
                                            onBlur={() => handleFloat(rowData.idx)}
                                            disabled={rowData.status === 'Cancelled' || rowData.status === 'Invoiced'}
                                            required
                                            variant="filled"
                                        />
                                    </Box>
                                ),
                                sizeToContent: true
                            },
                            {
                                heading: 'Total Buy Price',
                                field: rowData => rowData.totalBuyPrice,
                                fieldFormat: 'currency',
                                showTotal: true
                            },
                        ]}
                        rows={formData}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} justify="flex-end">
                        <Grid item>        
                            <Button 
                                variant="text" 
                                onClick={handleClose}
                            >
                                <FAIcon
                                    icon="times"
                                    button
                                    size={15}
                                />
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>        
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleConfirmation}
                                disabled={_.some(formData, el => el.buyPrice === '' || !isNumeric(el.buyPrice))}
                            >
                                <FAIcon
                                    icon="check"
                                    button
                                    size={15}
                                    disabled={_.some(formData, el => el.buyPrice === '' || !isNumeric(el.buyPrice))}
                                />
                                Update Pricing
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

export default ModifyPurchaseOrderPricing;