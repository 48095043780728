import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { handleChange, handleCheckedChange, handleSelectChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { Alert } from '@material-ui/lab';

const initialState = (roles) => ({
    formData: {
        platform: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        position: '',
        password: '',
        role: roles?.length ? _.find(roles, el => el.global)?.value ?? 0 : 0,
        admin: roles?.length ? 0 : 1,
        optIn: 0,
        noe: 0,
        ndn: 0,
        sendPassword: 0,
        active: 1
    },
    formErrors: {},
    positionList: [],
})

class TradeZoneUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(this.props.roles);
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {
        API.get(`/misc/contacts/positions`)
        .then(res => {
            if(res?.data) {
                let positionList = _.map(res.data, position => (
                    _.assign({
                        label: position,
                        value: position
                    })
                ))
                if(this.props.user?.p) {
                    if(!_.find(positionList, el => el.value === this.props.user.p)) {
                        positionList.push({
                            label: this.props.user.p,
                            value: this.props.user.p
                        })
                    }
                }
                this.setState({
                    positionList
                }, this.loadComponentData)
            }
        });
    }

    loadComponentData = () => {        
        const {
            user
        } = this.props;

        if(user) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    platform: user.l,
                    firstName: user.fn,
                    lastName: user.ln,
                    email: user.em,
                    phone: user.ph,
                    position: user.p,
                    role: user.r,
                    admin: user.ga,
                    optIn: user.optIn,
                    noe: user.noe,
                    ndn: user.ndn,
                    active: user.s === 'active' ? 1 : 0
                }
            })
        }        
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.formData.admin !== this.state.formData.admin) {
            if(this.state.formData.admin) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        role: this.props.roles?.length ? _.find(this.props.roles, el => el.global === 1)?.value ?? 0 : 0
                    }
                })
            }
        }
    }

    generatePassword = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                password: Math.random().toString(36).slice(-8)
            }
        })
    }

    handleSubmit = () => {
        const {
            callback,
            customer,
            deploySnackBar,
            user
        } = this.props;

        const {
            formData
        } = this.state;

        let apiRoute = `/customers/${customer}/tradeZone/users`;

        if(user) {
            apiRoute += `/${user.i}`;
        }

        API.post(apiRoute, formData)
        .then(res => {
            if(res?.data) {
                if(res.data?.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors)
                    });
                } else {
                    this.setState(initialState(), () => {
                        deploySnackBar("success", `The Portal User was successfully ${user ? 'updated' : 'created'}`)
                        callback?.();
                    })
                }
            }
        })
    }
    
    render() {
        const { closeDialog, deployConfirmation, roles, user } = this.props;
        const { formData, formErrors, positionList } = this.state;
        return (
            <Paper>
                <Box p={3}>
                    <Typography variant="h6" paragraph>
                        {user ? 'Update' : 'New'} Portal User
                    </Typography>
                    <form noValidate autoComplete="off">
                        {!roles.length && (
                            <Alert severity="info" style={{ marginBottom: 16 }}>
                                <Typography variant="body2" className="fw-400">
                                    The first user created for this account will be auto-assigned as the Portal Administrator
                                </Typography>
                            </Alert>   
                        )}
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstName"
                                    label="First Name *"
                                    margin="none" 
                                    autoComplete="off"
                                    error={formErrors && formErrors['firstName'] && true}
                                    helperText={formErrors && formErrors['firstName']}
                                    value={formData.firstName || ''}
                                    onChange={this.handleChange} 
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastName"
                                    label="Last Name *"
                                    margin="none"
                                    autoComplete="off"
                                    error={formErrors && formErrors['lastName'] && true}
                                    helperText={formErrors && formErrors['lastName']}
                                    value={formData.lastName || ''}
                                    onChange={this.handleChange} 
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={formData.platform ? 12 : 6}>  
                                <TextField
                                    name="email"
                                    label="Email Address *"
                                    margin="none"
                                    autoComplete="off"
                                    error={formErrors && formErrors['email'] && true}
                                    helperText={formErrors && formErrors['email']}
                                    value={formData.email || ''}
                                    onChange={this.handleChange} 
                                    fullWidth
                                />
                            </Grid>
                            {!formData.platform ? (
                                <>
                                    <Grid item xs={12} sm={6}>  
                                        <TextField
                                            name="phone"
                                            label="Mobile Number"
                                            margin="none"
                                            autoComplete="off"
                                            value={formData.phone || ''}
                                            onChange={this.handleChange} 
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>  
                                        <AutoCompleteSelect
                                            creatable={true}
                                            creatableMessage={userInput => `Add Position: ${userInput}`}
                                            options={positionList}
                                            name="position"
                                            placeholder={" "}
                                            label="Position"
                                            value={formData.position}
                                            onChange={v => this.handleSelectChange('position', v, true)}
                                            margin="none"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>  
                                        <AutoCompleteSelect
                                             options={roles?.length ? roles :  [
                                                { value: 0, label: 'Global Administrator' },
                                            ]}
                                            label='Role *'
                                            value={formData.role}
                                            onChange={v => this.handleSelectChange('role', v)}
                                            disabled={!roles?.length || formData.admin}
                                            noClear
                                        />
                                    </Grid>
                                </>
                            ) : null}
                            <Grid item xs={12}>
                                <TextField
                                    name="password"
                                    label={`${user ? 'Reset ' : ''}Password ${user ? '' : '*'}`}
                                    margin="none"
                                    autoComplete="off"
                                    error={formErrors && formErrors['password'] && true}
                                    helperText={formErrors && formErrors['password']}
                                    value={formData.password || ''}
                                    onChange={this.handleChange} 
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <Tooltip title='Generate Password'><InputAdornment position="end"><IconButton onClick={this.generatePassword}><FAIcon type="light" icon="key" size={15} noMargin button /></IconButton></InputAdornment></Tooltip>
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            {user && (
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="active" checked={formData.active === 1} color="primary" onChange={this.handleCheckedChange}/>}
                                        label={<Typography variant="body2">Account active</Typography>}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox name="sendPassword" checked={formData.sendPassword === 1} color="primary" onChange={this.handleCheckedChange}/>}
                                    disabled={user && formData.password === ''}
                                    label={<Typography variant="body2">E-mail new login credentials</Typography>}
                                />
                            </Grid>
                        </Grid>
                        {!formData.platform ? (
                            <Grid container>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="ndn" checked={formData.ndn === 1} color="primary" onChange={this.handleCheckedChange}/>}
                                        label={<Typography variant="body2">No delivery notifications</Typography>}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="noe" checked={formData.noe === 1} color="primary" onChange={this.handleCheckedChange}/>}
                                        label={<Typography variant="body2">No order update e-mails</Typography>}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="optIn" checked={formData.optIn === 1} color="primary" onChange={this.handleCheckedChange}/>}
                                        label={<Typography variant="body2">Opt-In to mailing list</Typography>}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        disabled={!roles?.length || this.props.user?.ga}
                                        control={<Checkbox name="admin" checked={formData.admin === 1} color="primary" onChange={this.handleCheckedChange}/>}
                                        label={<Typography variant="body2">Set as Portal Administrator</Typography>}
                                    />
                                </Grid>
                            </Grid>
                        ) : null}
                    </form>
                    <div className="buttonRow">
                        <Button 
                            onClick={closeDialog} 
                            variant="text" 
                        >
                            <FAIcon icon="times" size={15} button />
                            Cancel
                        </Button>
                        <Button 
                            color="primary"
                            disabled={_.some(user ? [formData.firstName, formData.lastName, formData.email] : [formData.firstName, formData.lastName, formData.email, formData.password], el => _.isEmpty(el))}
                            onClick={() => deployConfirmation(`Are you sure you want to ${user ? `update` : `create`} this Portal User?`, this.handleSubmit)} 
                            variant="text" 
                        >
                            <FAIcon icon="check" size={15} button />
                            {user ? 'Update' : 'Create'} User
                        </Button>
                    </div>
                </Box>
            </Paper>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(TradeZoneUserForm);