import React from 'react';

import Grid from '@material-ui/core/Grid';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const Unauthorized = ({handleReload, status}) => (
    <Grid container justify="center" alignItems="center" style={{height: `calc(100vh - 310px)`}}>
        <Grid item xs={12} lg={6}>
            <Grid container spacing={1} style={{padding: '100px 24px 100px 24px'}}>
                <Grid item xs={12} align='center'>
                    <FAIcon type="light" 
                        icon="exclamation-triangle"
                        size="xlarge"
                        noMargin
                        heading
                    />
                </Grid>
                <Grid item xs={12} align='center'>
                    <Typography variant="h6" gutterBottom>
                        You're not authorised to process orders that are {status}
                    </Typography>
                    <Typography variant="body2" component="div" gutterBottom>
                        Please wait whilst an authorised person completes this action
                    </Typography>  
                    <Typography variant="body2" component="div" gutterBottom>
                        You can leave this page and return to this order later
                    </Typography>  
                </Grid>
                <Grid item xs={12} align='center'>
                    <LoadingCircle />
                </Grid>
            </Grid>       
        </Grid>
    </Grid>
)

export default Unauthorized;