import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Products from 'Components/RMA/RMAForm/Products';

const useStyles = makeStyles({
    tab: {
        color: '#fff',
        opacity: 1
    },
    td: {
        border: 'none',
        padding: 4
    }
})

const ManageRMA = ({access, callback, rma, handleManageRMAChange, handleSubmitRMAChanges, rmaModified, isLoading}) => { 
    const classes = useStyles();
    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <Products
                access={access}
                classes={classes}
                callback={callback}
                handleChange={handleManageRMAChange}
                handleSubmit={handleSubmitRMAChanges}
                rma={rma}
                rmaModified={rmaModified}
                manage
                rows={rma.products}
            />
        )
    );
}

export default ManageRMA;