import React from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar'
import NominalCodeList from './NominalCodeList';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = {
    access: {
        addNominalCategory: false,
        addNominalCode: false
    },
    categories: {},
    categoryList: [],
    codes: {},
    confirmationOpen: {
        addCategory: false,
        addNominalCode: false
    },
    formData: {
        addCategory: {
            name: ''
        },
        addNominalCode: {
            code: '',
            name: ''
        }
    },
    formErrors: {
        addCategory: {},
        addNominalCode: {}
    },
    isLoading: true,
    snackbarOpen: {
        addCategory: false,
        addNominalCode: false
    }
}

class NominalCodes extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getCategories();
        this.getNominalCodes();
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/nominal-codes:add-category'), 
            API.get('/staff/my/access/check/nominal-codes:add-code'),
        ])
        .then(([categoryRes, codeRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    addNominalCategory: categoryRes.data ? categoryRes.data.has_access : false,
                    addNominalCode: codeRes.data ? codeRes.data.has_access : false,
                },
                isLoading: false
            });
        });
    }

    getCategories = () => {
        API.get('/accounts/nominalCodes/categories/all').then(result => {
            if(result.data) {
                let categoryList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.nom_cat_id,
                        label: el.nom_cat_name
                    });
                });
                this.setState({
                    categories: result.data,
                    categoryList: categoryList
                });
            }
        });
    }

    getNominalCodes = () => {
        API.get('/accounts/nominalCodes/byCategory')
        .then(result => {
            if(result.data) {
                this.setState({
                    codes: result.data
                });
            }
        });
    }
    
    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                addNominalCode: {
                    ...this.state.formData.addNominalCode,
                    [name]: res && res.value,
                }
            }
        });
    };

    /*
     * Add Category
     */
    addCategory = () => {
        API.post('/accounts/nominalCodes/categories', this.state.formData.addCategory).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addCategory: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addCategory: true
                    }
                });
                this.checkAccess();
                this.getCategories();
                this.getNominalCodes();
            }
            this.props.scrollToTop();
        });
    }

    handleAddCategoryChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addCategory: {
                    ...this.state.formData.addCategory,
                    [e.target.name]: e.target.value
                }
            }
        });
    }

    handleAddCategorySnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addCategory: false
            }
        });
    }

    handleAddCategoryConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: true
            }
        });
    }

    handleAddCategoryConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: false
            }
        });
    }

    handleAddCategoryConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: false
            }
        });
        this.addCategory();
    }

    /*
     * Add Nominal Code
     */
    addNominalCode = () => {
        API.post('/accounts/nominalCodes', this.state.formData.addNominalCode).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addNominalCode: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    currentTab: 1,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addNominalCode: true
                    }
                });
                this.checkAccess();
                this.getCategories();
                this.getNominalCodes();
            }
            this.props.scrollToTop();
        });
    }

    handleAddNominalCodeChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addNominalCode: {
                    ...this.state.formData.addNominalCode,
                    [e.target.name]: e.target.value
                }
            }
        });
    }

    handleNominalCodeSnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addNominalCode: false
            }
        });
    }

    handleAddNominalCodeConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addNominalCode: true
            }
        });
    }

    handleAddNominalCodeConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addNominalCode: false
            }
        });
    }

    handleAddNominalCodeConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addNominalCode: false
            }
        });
        this.addNominalCode();
    }

    render() {
        const { access, codes, formData, formErrors, isLoading, snackbarOpen, confirmationOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    {(isLoading && (
                        <PaddedPaper>
                            <LoadingCircle />
                        </PaddedPaper>
                    )) || (
                        <NominalCodeList codes={codes} />
                    )}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        {(access.addNominalCategory || access.addNominalCode) && (
                            <>
                                {access.addNominalCode && (
                                    <Grid item xs={12} xl={6}>
                                        <PaddedPaper>
                                            <Typography variant="h6" paragraph>
                                                Add Nominal Code
                                            </Typography>
                                            <form onChange={this.handleAddNominalCodeChange} noValidate autoComplete="off">
                                                <AutoCompleteSelect 
                                                    options={this.state.categoryList} 
                                                    label='Nominal Category *'
                                                    error={formErrors?.addNominalCode?.['category'] && true}
                                                    errorText={formErrors?.addNominalCode['category']}
                                                    onChange={this.handleSelectChange('category')}
                                                />
                                                <TextField
                                                    id="name"
                                                    name="name"
                                                    label="Nominal Description *"
                                                    value={this.state.formData.addNominalCode.name}
                                                    error={formErrors.addNominalCode && formErrors.addNominalCode['name'] && true}
                                                    helperText={formErrors.addNominalCode && formErrors.addNominalCode['name']}
                                                    fullWidth
                                                />
                                                <TextField
                                                    id="code"
                                                    name="code"
                                                    label="Nominal Code *"
                                                    value={this.state.formData.addNominalCode.code}
                                                    error={formErrors.addNominalCode && formErrors.addNominalCode['code'] && true}
                                                    helperText={formErrors.addNominalCode && formErrors.addNominalCode['code']}
                                                    type="number"
                                                    inputProps={{
                                                        size: 4,
                                                        pattern: '[0-9]*',
                                                        maxLength: 4,
                                                    }}
                                                    fullWidth
                                                />
                                                <div className='buttonRow'>
                                                    <Button 
                                                        onClick={this.handleAddNominalCodeConfirmationOpen}
                                                        variant="text" 
                                                        color="primary" 
                                                        disabled={!this.state.formData.addNominalCode.name && !this.state.formData.addNominalCode.category}
                                                    >
                                                        <FAIcon icon="check" size={15} button />
                                                        Add
                                                    </Button>
                                                </div>
                                                <ConfirmationDialog 
                                                    open={confirmationOpen.addNominalCode} 
                                                    success={this.handleAddNominalCodeConfirmationSuccess} 
                                                    close={this.handleAddNominalCodeConfirmationClose} 
                                                    message="Are you sure you want to add a new Nominal Code?"
                                                />
                                                <SnackBar
                                                    variant="success"
                                                    anchorOriginVertical='bottom'
                                                    anchorOriginHorizontal='right'
                                                    open={snackbarOpen.addNominalCode}
                                                    onClose={this.handleNominalCodeSnackbarClose}
                                                    message="You have successfully added a new Nominal Code"
                                                />
                                            </form>
                                        </PaddedPaper>
                                    </Grid>
                                )}
                                {access.addNominalCategory && (
                                    <Grid item xs={12} xl={6}>
                                        <PaddedPaper>
                                            <Typography variant="h6">
                                                Add Nominal Category
                                            </Typography>
                                            <form onChange={this.handleAddCategoryChange} noValidate autoComplete="off">
                                                <TextField
                                                    id="name"
                                                    name="name"
                                                    label="Category Description *"
                                                    value={formData.addCategory.name}
                                                    error={formErrors.addCategory && formErrors.addCategory['name'] && true}
                                                    helperText={formErrors.addCategory && formErrors.addCategory['name']}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <div className='buttonRow'>
                                                    <Button 
                                                        onClick={this.handleAddCategoryConfirmationOpen}
                                                        variant="text" 
                                                        color="primary" 
                                                        disabled={!this.state.formData.addCategory.name}
                                                    >
                                                        <FAIcon icon="check" size={15} button />
                                                        Add
                                                    </Button>
                                                </div>
                                                <ConfirmationDialog 
                                                    open={confirmationOpen.addCategory} 
                                                    success={this.handleAddCategoryConfirmationSuccess} 
                                                    close={this.handleAddCategoryConfirmationClose} 
                                                    message="Are you sure you want to add a new Nominal Category?"
                                                />
                                                <SnackBar
                                                    variant="success"
                                                    anchorOriginVertical='bottom'
                                                    anchorOriginHorizontal='right'
                                                    open={snackbarOpen.addCategory}
                                                    onClose={this.handleAddCategorySnackbarClose}
                                                    message="You have successfully added a new Nominal Category"
                                                />
                                            </form>
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default NominalCodes;