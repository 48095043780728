import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { TabPanel, handleTabChange } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

import QuoteTracker from 'Components/Reporting/Sales/QuoteTracker/QuoteTracker';
import ReturnSummary from 'Components/Reporting/Sales/ReturnSummary/ReturnSummary';
import SalesAccTurnover from 'Components/Reporting/Sales/SalesAccTurnover/SalesAccTurnover';
import SalesOrderMargin from 'Components/Reporting/Sales/SalesOrderMargin/SalesOrderMargin';
import CallOffOrders from 'Components/Reporting/Sales/CallOffOrders/CallOffOrders';
import NewAndLapsedOrders from 'Components/Reporting/Sales/NewAndLapsedOrders/NewAndLapsedOrders';
import Quotations from 'Components/Reporting/Sales/Quotations/Quotations';
import QuotationsOverDiscountThreshold from 'Components/Reporting/Sales/QuotationsOverDiscountThreshold/QuotationsOverDiscountThreshold';
import OrdersOverDiscountThreshold from 'Components/Reporting/Sales/OrdersOverDiscountThreshold/OrdersOverDiscountThreshold';
import CompletedWHOrders from 'Components/Reporting/Sales/CompletedWHOrders/CompletedWHOrders';

const initialState = () => ({
    access: {
        quoteTracker: false,
        returnSummary: false,
        salesAccTurnover: false,
        salesOrderMargin: false,
        completedWhOrders: false,
        highDiscountOrders: false,
        highDiscountQuotes: false,
        newAndLapsedOrders: false,
        callOffOrders: false
    },
    currentTab: 6,
    isLoading: true,
    tabs: {
        0: "Quote Tracker Report",
        1: "Returns Summary Report",
        2: "Sales Account Turnover Analysis Report",
        3: "Sales Order Margin Report",
        4: "Quotation Report",
        5: "Shipped Orders",
        6: "High Discount Quotes Report",
        7: "High Discount Sales Orders Report",
        8: "New and Lapsed Order Report",
        9: "Call Off Order Report"
    },
    tabText: {
        0: "Live data on high value quotes",
        1: "Historic data on customer returns",
        2: "Historic data on supplier financial year sales",
        3: "Historic data on sales order margins",
        4: "Historic data on quotations by Sales Team",
        5: "Historic data on shipped warehouse sales order deliveries",
        6: "Quotes that exceeded the discount notification threshold",
        7: "Sales Orders that exceeded the discount notification threshold",
        8: "Customers who placed their first order; or first order in the last 12 months",
        9: "Upcoming call off orders based on customer requested date and each delivery requested date"
    },
})

class ReportingSales extends React.Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Reporting", "Sales"])
        this.loadComponentData()
    }

    loadComponentData = () => {
        API.multiAccess([
            'return-summary',
            'sa-turnover-report',
            'so-margin-report',
            'quotation-report',
            'completed-orders-wh-report',
            'high-discount-quote-report',
            'high-discount-order-report',
            'nl-order-report',
            'call-off-report'
        ])
        .then(([
            returnSummary,
            salesAccTurnover,
            salesOrderMargin,
            quotationReport,
            completedWhOrders,
            highDiscountQuotes,
            highDiscountOrders,
            newAndLapsedOrders,
            callOffOrders
        ]) => {     
            this.setState({
                access: {
                    ...this.state.access,
                    returnSummary,
                    salesAccTurnover,
                    salesOrderMargin,
                    quotationReport,
                    completedWhOrders,
                    highDiscountQuotes,
                    highDiscountOrders,
                    newAndLapsedOrders,
                    callOffOrders
                },
                isLoading: false
            })
        })
    }

    render = () => {

        const { 
            classes, 
            ui 
        } = this.props;

        const { 
            access, 
            currentTab, 
            isLoading, 
            tabs, 
            tabText 
        } = this.state;

        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabs[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        {access.quoteTracker && (
                                            <TabPanel value={currentTab} index={0}>
                                                <QuoteTracker />
                                            </TabPanel>
                                        )}
                                        {access.returnSummary && (
                                            <TabPanel value={currentTab} index={1}>
                                                <ReturnSummary />
                                            </TabPanel>
                                        )}
                                        {access.salesAccTurnover && (
                                            <TabPanel value={currentTab} index={2}>
                                                <SalesAccTurnover />
                                            </TabPanel>
                                        )}
                                        {access.salesOrderMargin && (
                                            <TabPanel value={currentTab} index={3}>
                                                <SalesOrderMargin />
                                            </TabPanel>
                                        )}
                                        {access.quotationReport && (
                                            <TabPanel value={currentTab} index={4}>
                                                <Quotations />
                                            </TabPanel>
                                        )}
                                        {access.completedWhOrders && (
                                            <TabPanel value={currentTab} index={5}>
                                                <CompletedWHOrders />
                                            </TabPanel>
                                        )}
                                        {access.highDiscountOrders && (
                                            <TabPanel value={currentTab} index={6}>
                                                <QuotationsOverDiscountThreshold />
                                            </TabPanel>
                                        )}
                                        {access.highDiscountQuotes && (
                                            <TabPanel value={currentTab} index={7}>
                                                <OrdersOverDiscountThreshold />
                                            </TabPanel>
                                        )}
                                        {access.newAndLapsedOrders && (
                                            <TabPanel value={currentTab} index={8}>
                                                <NewAndLapsedOrders />
                                            </TabPanel>
                                        )}
                                        {access.callOffOrders && (
                                            <TabPanel value={currentTab} index={9}>
                                                <CallOffOrders />
                                            </TabPanel>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={classes.side}
                            style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}
                        >
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {access.highDiscountQuotes && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={6} 
                                        label="High Discount Quotes" 
                                    />
                                )}
                                {access.highDiscountOrders && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={7} 
                                        label="High Discount Orders" 
                                    />
                                )}
                                {access.newAndLapsedOrders && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={8} 
                                        label="New and Lapsed Orders" 
                                    />
                                )}
                                {access.quotationReport && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={4} 
                                        label="Quotes" 
                                    />
                                )}
                                {access.quoteTracker && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={0} 
                                        label="Quote Tracker" 
                                    />
                                )}
                                {access.returnSummary && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={1} 
                                        label="Return Summary" 
                                    />
                                )}
                                {access.salesAccTurnover && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={2} 
                                        label="Sales Account Turnover" 
                                    />
                                )}
                                {access.salesOrderMargin && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={3} 
                                        label="Sales Order Margins" 
                                    />
                                )}
                                {access.callOffOrders && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={9} 
                                        label="Call Off Orders" 
                                    />
                                )}
                                {access.completedWhOrders && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={5} 
                                        label="Shipped Orders" 
                                    />
                                )}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps)(withStyles(ViewPage)(ReportingSales));