import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Avatar, Hidden } from '@material-ui/core';
import API from 'API';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import ActivityLog from 'Components/Common/Activity/ActivityLog';
import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import SendSMSForm from 'Components/Common/Activity/SendSMSForm';
import TaskForm from 'Components/Common/Activity/TaskForm';
import ViewActions from 'Components/Common/Activity/ViewActions';
import ViewAddresses from 'Components/Common/Activity/ViewAddresses';
import ViewCallLogs from 'Components/Common/Activity/ViewCallLogs';
import ViewContacts from 'Components/Common/Activity/ViewContacts';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import Alert from 'Components/Common/Alert/Alert';
import ActionChip from 'Components/Common/Chips/ActionChip';
import InsightChip from 'Components/Common/Chips/InsightChip';
import DataTable from 'Components/Common/DataTables/DataTable';
import AddressForm from 'Components/Common/Forms/AddressForm';
import ContactForm from 'Components/Common/Forms/ContactForm';
import Heading from 'Components/Common/Heading/Heading';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ViewPage from 'Components/Common/Styles/ViewPage';
import Tile from 'Components/Common/Tiles/Tile';
import EmailLog from 'Components/Communication/Communication/EmailLog';
import SmsLog from 'Components/Communication/Communication/SmsLog';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import ProductSearch from 'Components/Products/ProductSearch/ProductSearch';
import SearchPurchaseOrders from 'Components/Purchases/SearchPurchaseOrders/SearchPurchaseOrders';
import AccountsOverview from 'Components/Suppliers/Misc/AccountsOverview';
import CarriageForm from 'Components/Suppliers/Misc/CarriageForm';
import DiscountForm from 'Components/Suppliers/Misc/DiscountForm';
import UpdateSupplierCategories from 'Components/Suppliers/Misc/UpdateSupplierCategories';
import UploadSupplierLogoForm from 'Components/Suppliers/Misc/UploadSupplierLogoForm';
import UploadSupplierLogoMissingForm from 'Components/Suppliers/Misc/UploadSupplierLogoMissingForm';
import ViewCarriages from 'Components/Suppliers/Misc/ViewCarriages';
import ViewDiscounts from 'Components/Suppliers/Misc/ViewDiscounts';
import { CLOUDFRONT_URL, VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from 'Constants';
import { currencyFormat, getLeadTime, handleTabChange, TabPanel } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

import WildixContext from "Context/WildixContext";

const initialState = () => ({
    access: {
        addAddress: false,
        addContact: false,
        addCallLog: false,
        addNote: false,
        deleteAddress: false,
        deleteContact: false,
        modifyAddress: false,
        modifyContact: false,
        updateSupplier: false,
        addDiscount: false,
        modifyDiscount: false,
        deleteDiscount: false,
        addCarriage: false,
        modifyCarriage: false,
        deleteCarriage: false,
        newPo: false,
        accounts: false,
        deleteSupplier: false,
        updateCategories: false,
        uploadLogo: false,
    },
    charts: {
        spend: {}
    },
    currentTab: 0,
    key: uuidv4(),
    sageLoading: true,
    supplierId: '',
    supplier: {
        supp_company_name: '',
        supp_trading_name: '',
        supp_phone: '',
        supp_email: '',
        supp_website: '',
        contacts: [],  
        supp_payment_terms: '',
        supp_account_number: '',
        supp_min_order_value: '',
        supp_notes: '',         
        supp_logo: '',         
    },
    smsContactList: [],
    isLoading: true,
    viewing: {
        list: [],
        update: false,
    }
})

class ViewSupplier extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props);    
        this.mainContentArea = React.createRef();
        this.persistenceId = `supplier:${this.props.id ?? this.props.match?.params?.id}`;
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.handleTabChange = handleTabChange.bind(this);
        this.poll = false;
    }

    componentDidMount = () => {
        
        if(!this.hasPageState()) {

            API.multiAccess([
                'update-supplier',
                'view-supplier:add-note',
                'view-supplier:add-call',
                'view-supplier:send-sms',
                'view-supplier:add-address',
                'view-supplier:add-contact',
                'view-supplier:delete-address',
                'view-supplier:delete-contact',
                'view-supplier:modify-address',
                'view-supplier:modify-contact',
                'view-supplier:add-discount',
                'view-supplier:modify-discount',
                'view-supplier:delete-discount',
                'view-supplier:add-carriage',
                'view-supplier:modify-carriage',
                'view-supplier:delete-carriage',
                'view-supplier:upload-logo',
                'add-po',
                'view-supplier:accounts',
                'delete-supplier',
                'supplier-categories'
            ])
            .then(([
                updateSupplier, 
                addNote, 
                addCallLog, 
                sendSms, 
                addAddress, 
                addContact, 
                deleteAddress, 
                deleteContact, 
                modifyAddress, 
                modifyContact, 
                addDiscount, 
                modifyDiscount, 
                deleteDiscount, 
                addCarriage, 
                modifyCarriage, 
                deleteCarriage,
                uploadLogo,
                newPo,
                accounts,
                deleteSupplier,
                updateCategories
            ]) => {     
                const supplierId = this.props.id ? this.props.id : this.props.match.params.id
                this.setState({
                    access: {
                        updateSupplier,
                        addNote,
                        addCallLog,
                        sendSms,
                        addAddress,
                        addContact, 
                        deleteAddress,
                        deleteContact,
                        modifyAddress,
                        modifyContact,
                        addDiscount,
                        modifyDiscount,
                        deleteDiscount,
                        addCarriage,
                        modifyCarriage,
                        deleteCarriage,
                        uploadLogo,
                        newPo,
                        accounts,
                        deleteSupplier,
                        updateCategories,
                    },
                    supplierId
                }, () => {
                    this.poll = setInterval(this.handlePoll, 5000);
                    this.loadComponentData(true);
                })
            })

        } else {
            this.props.pageTitle?.([1, 'Suppliers', this.state.supplier?.supp_company_name ?? 'Unknown'])
            this.loadComponentData(true)
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match?.params?.id !== this.props.match?.params?.id) {

            const supplierId = this.props.match.params.id;

            this.persistenceId = `supplier:${supplierId}`;

            if(this.hasPageState()) {
                this.setState({
                    ...this.getInitialState(initialState())
                }, () => {
                    this.props.pageTitle?.([1, 'Suppliers', this.state.supplier?.supp_company_name ?? 'Unknown'])
                });
            } else {
                this.setState({
                    currentTab: 0,
                    supplierId
                }, () => {            
                    this.setState({
                        isLoading: true
                    }, () => {
                        this.loadComponentData(true);
                    })
                });
            }
        }
    }

    componentWillUnmount = () => {
        if(this.poll) {
            clearInterval(this.poll);
        }
    }

    handlePoll = () => {

        if(!document.hidden) {

            API.post(`/staff/my/view`, 
                { 
                    supp: this.state.supplierId,
                    type: 'View'
                }, 
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true
                    }
                }
            )
            .then(res => {
                const { viewing } = this.state;
                if(res?.data) {
                    const list = res.data;
                    if(JSON.stringify(list) !==  JSON.stringify(viewing.list)) {
                        const update = _.some(list, el => el.u === 1);
                        this.setState({
                            viewing: {
                                list,
                                update
                            }
                        }, () => {
                            if(viewing.update && !update) {
                                const view = _.find(viewing.list, el => el.u === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished updating this supplier`);
                                }
                                this.loadComponentData();
                            }
                        })
                    }
                }
            })

        }
    }

    loadComponentData = (initial = false) => {
        API.get(`/suppliers/${this.state.supplierId}`)
        .then(result => {
            if(result?.data) {

                if(result.data.errors) {
                    this.props.history.push('/suppliers')
                } else {

                    let supplier        = result.data,
                        spendData       = result.data.spend,
                        spend           = {},
                        labels          = [],
                        data            = [],
                        cutOffData      = null,
                        smsContactList  = [];

                    _.each(supplier.contacts, el => {
                        if(el.contact_telephone) {
                            smsContactList.push({
                                id: el.contact_id,
                                value: el.contact_mobile + ':tel',
                                label: el.contact_first_name + ' ' + el.contact_last_name + ' (Tel) - ' + el.contact_telephone
                            })
                        }
                        if(el.contact_mobile) {
                            smsContactList.push({
                                id: el.contact_id,
                                value: el.contact_mobile + ':mob',
                                label: el.contact_first_name + ' ' + el.contact_last_name + ' (Mob) - ' + el.contact_mobile
                            })
                        }
                    });

                    if(!_.isEmpty(supplier.supp_cut_off_data)) {

                        let cutOff = JSON.parse(result.data.supp_cut_off_data), cutOffVaries = 0, time;

                        Object.keys(cutOff).forEach((key, idx) => {
                            if(idx === 0) {
                                time = cutOff[key];
                            } else {
                                if(cutOff[key] !== time) {
                                    cutOffVaries = 1;
                                }
                            }
                        })

                        if(cutOffVaries === 1) {
                            cutOffData = [
                                {
                                    label: 'Mon',
                                    time: cutOff?.mon ?? '-'
                                },
                                {
                                    label: 'Tue',
                                    time: cutOff?.tue ?? '-'
                                },
                                {
                                    label: 'Wed',
                                    time: cutOff?.wed ?? '-'
                                },
                                {
                                    label: 'Thu',
                                    time: cutOff?.thu ?? '-'
                                },
                                {
                                    label: 'Fri',
                                    time: cutOff?.fri ?? '-'
                                },
                            ];
                        } else {
                            cutOffData = [
                                {
                                    label: 'Everyday',
                                    time: cutOff?.mon ?? '-'
                                }
                            ];
                        }

                        supplier = {
                            ...supplier, 
                            cutOffData
                        }

                    }
                    
                    supplier = {
                        ...supplier,
                        supp_sage_data: JSON.parse(supplier.supp_sage_data)
                    }

                    if(spendData) {

                        for (let i = 0, j = 11; i <= j; i++) {
                            data.push(spendData[i].net)
                            labels.push(spendData[i].label)
                        }
        
                        spend = {
                            labels,
                            datasets: [
                                {
                                    lineTension: 0.1,
                                    backgroundColor: 'transparent',
                                    borderColor: '#FFCDD2',
                                    borderCapStyle: 'butt',
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: 'miter',
                                    pointBorderColor: '#EF3340',
                                    pointBackgroundColor: '#fff',
                                    pointBorderWidth: 4,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: '#fff',
                                    pointHoverBorderColor: '#C62828',
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 2,
                                    pointHitRadius: 10,
                                    data
                                }
                            ]
                        }
        
                    }

                    this.setState({
                        charts: {
                            spend
                        },
                        key: uuidv4(),
                        smsContactList,
                        supplier,
                        isLoading: false
                    }, () => {

                        const { access } = this.state;

                        this.props.pageTitle([1, 'Suppliers', this.state.supplier.supp_company_name])

                        if(initial && access.accounts) {

                            if(!_.isEmpty(this.state.supplier.supp_account_number)) {
                                this.getSageData();
                            } else {
                                this.setState({
                                    sageLoading: false
                                })
                            }
                        }

                        this.savePageState();

                    });
                }

            }
        });
    }

    getSageData = () => {
        this.setState({
            sageLoading: true
        }, () => {
            API.get(`/suppliers/${this.state.supplier?.supp_id}/sage`)
            .then(res => {
                if(res.data) {
                    if(res.data.success) {
                        this.setState({
                            supplier: {
                                ...this.state.supplier,
                                ...res.data.supplier,
                                supp_sage_data: JSON.parse(res.data.supplier.supp_sage_data)
                            },
                            sageLoading: false
                        })
                    } else {
                        this.setState({
                            sageLoading: false
                        }, () => {
                            this.savePageState();
                        })
                    }
                }
            })        
        })          
    }

    handleContactSms = (contactId, number) => {
        const { smsContactList, supplierId } = this.state;
        this.props.deployDialog(<SendSMSForm 
                                    id={supplierId} 
                                    contacts={smsContactList} 
                                    relation="supplier" 
                                    customNumber={true} 
                                    contactId={contactId} 
                                    number={`${number}:mob`} 
                                    toggleDialog={this.props.closeDialog}
                                />, true, "", "standard", "xs")
    }

    handleDeleteSupplier = () => {
        const { supplierId } = this.state;
        API.put(`/suppliers/${supplierId}/delete`)
        .then(res => {
            if (res?.data) {
                this.props.deploySnackBar("success", `The supplier has been successfully deleted`);
                this.props.history.push(`/suppliers`);
            }
        });
        
    }

    handleDeployAddCarriage = () => {
        const { supplier } = this.state;
        this.props.deployDialog(<CarriageForm
                                    id={supplier.supp_id}
                                    callback={() => { this.props.closeDialog(); this.loadComponentData();}}
                                    cancel={this.props.closeDialog}
                                />
                                , false, "Add Delivery Method", "standard", "xs")
    }

    handleDeployAddDiscount = () => {
        const { supplier } = this.state;
        this.props.deployDialog(<DiscountForm
                                    id={supplier.supp_id}
                                    callback={() => { this.props.closeDialog(); this.loadComponentData();}}
                                    cancel={this.props.closeDialog}
                                />
                                , false, "Add Discount", "standard", "xs")
    }
    
    handleDeployAddCall = () => this.props.deployDialog(
        <AddCallLogForm
            id={this.state.supplierId}
            relation="supplier"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","xs", false, true);

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.supplierId}
            relation="supplier"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
            standardOnly
        />, true, "", "standard","sm", false, true);

    handleDeployAddSms = () => this.props.deployDialog(
        <SendSMSForm
            id={this.state.supplierId}
            relation="supplier"
            contacts={this.state.smsContactList}
            customNumber={true}
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","xs", false, true);

    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            id={this.state.supplierId}
            relation="supplier"
            defaultOption={{label: this.state.supplier.supp_company_name, value: this.state.supplier.supp_id, subtitle1: `${this.state.supplier.trading_address?.l1}, ${this.state.supplier.trading_address?.to}, ${this.state.supplier.trading_address?.po}`, s:1}}
            defaultSearch={this.state.supplier.supp_company_name}
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","sm", false, true);

    handleDeploySendEmail = () => {
        let defaultFormData = {}
        if(!_.isEmpty(this.state.supplier?.supp_email)) {
            defaultFormData = {
                ...defaultFormData,
                emailTo: {
                    label: this.state.supplier.supp_email,
                    value: this.state.supplier.supp_email
                }
            }
        }
        this.props.deployDialog(
            <EmailDialog
                callback={this.loadComponentData}
                id={this.state.supplierId}
                type='supplier'
                success='The e-mail has been successfully sent'
                defaultFormData={defaultFormData}
            />, false, "Compose E-mail", "standard","md"
        );
    }

    handleDeployUploadLogo = () => this.props.deployDialog(
        <UploadSupplierLogoForm 
            supplierId={this.state.supplierId} 
            current={this.state.supplier.supp_logo} 
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }} 
        />, false, "Upload Supplier Logo", "standard","xs", false, true);

    handleDeployUploadLogoMissing = () => this.props.deployDialog(
        <UploadSupplierLogoMissingForm 
            supplierId={this.state.supplierId} 
            current={this.state.supplier.supp_logo_missing} 
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }} 
        />, false, "Upload Supplier Missing Image", "standard","xs", false, true);

        
    render() {
        const { classes, closeDialog, deployConfirmation, deployDialog, deploySnackBar, staff, ui} = this.props;
        const { access, charts, currentTab, isLoading, key, sageLoading, supplier, viewing } = this.state;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
    
        const canDial = this.context?.dial === null;

        let tradingAddress = false;

        if(!isLoading && supplier.trading_address) {
            tradingAddress = [
                supplier.supp_company_name,
                supplier.trading_address.n,
                supplier.trading_address.l1,
                supplier.trading_address.l2,
                supplier.trading_address.l3,
                supplier.trading_address.to,
                supplier.trading_address.county?.county_name ?? 'Unknown',
                supplier.trading_address.po,
                supplier.trading_address.country?.country_name ?? 'Unknown',
            ];
        }

        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Avatar 
                                                            alt={supplier.supp_company_name} 
                                                            src={supplier.supp_logo ? `${CLOUDFRONT_URL}${supplier.supp_logo}` : null} 
                                                            style={{border: '1px solid #ddd', width: 40, height: 40}} 
                                                            variant="square" 
                                                        >
                                                            {!supplier.supp_logo ? supplier.supp_company_name.charAt(0) : null}
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            {supplier.supp_company_name}
                                                        </Typography> 
                                                    </Grid>  
                                                    {supplier.supp_phone.length > 0 && canDial ? (
                                                        <Grid item>
                                                            <Tooltip title="Call Supplier">
                                                                <IconButton onClick={() => this.context.useDial(supplier.supp_phone)} size="small">
                                                                    <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={27.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Add Action" placement="top">
                                                    <IconButton onClick={() => this.handleDeployAddTask()}>
                                                        <FAIcon type="light" icon="layer-plus" size={17.5} button noMargin />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon type="light" icon="comment-alt-plus" size={17.5} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.sendSms && (
                                                <Grid item>
                                                    <Tooltip title="Compose SMS" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddSms()}>
                                                            <FAIcon type="light" icon="sms" size={17.5} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addCallLog && (
                                                <Grid item>
                                                    <Tooltip title="Log Call" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddCall()}>
                                                            <FAIcon type="light" icon="phone-plus" size={17.5} button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.updateSupplier && (
                                                <Grid item>
                                                    <Tooltip title="Modify" placement="top">
                                                        <IconButton disabled={viewing.update} onClick={() => this.props.history.push(`/suppliers/update/${supplier.supp_id}`)}>
                                                            <FAIcon type="light" icon="pencil" size={17.5} button noMargin disabled={viewing.update} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "layer-plus",
                                                            label: "Add Action",
                                                            onClick: () => this.handleDeployAddTask(),
                                                            display: true
                                                        },
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote
                                                        },
                                                        {
                                                            icon: "envelope-open-text",
                                                            label: "Compose E-mail",
                                                            onClick: () => this.handleDeploySendEmail(),
                                                            display: true,
                                                            disabled: supplier.supp_deleted === 1
                                                        },
                                                        {
                                                            icon: "sms",
                                                            label: "Compose SMS",
                                                            onClick: () => this.handleDeployAddSms(),
                                                            display: access.sendSms,
                                                            disabled: supplier.supp_deleted === 1
                                                        },
                                                        {
                                                            icon: "phone-plus",
                                                            label: "Log Call",
                                                            onClick: () => this.handleDeployAddCall(),
                                                            display: access.addCallLog,
                                                            divider: true
                                                        },
                                                        {
                                                            icon: "box-check",
                                                            label: "Add Carriage",
                                                            onClick: () => this.handleDeployAddCarriage(),
                                                            display: access.addCarriage,
                                                            disabled: viewing.update || supplier.supp_deleted === 1
                                                        },
                                                        {
                                                            icon: "percent",
                                                            label: "Add Discount",
                                                            onClick: () => this.handleDeployAddDiscount(),
                                                            display: access.addDiscount,
                                                            disabled: viewing.update || supplier.supp_deleted === 1
                                                        },
                                                        {
                                                            icon: "trash-alt",
                                                            label: "Delete Supplier",
                                                            onClick: () => this.props.deployConfirmation(`Are you sure you want to delete this supplier?`, this.handleDeleteSupplier),
                                                            display: access.deleteSupplier,
                                                            disabled: viewing.update || supplier.supp_deleted === 1
                                                        },
                                                        {
                                                            icon: "pencil",
                                                            label: "Modify Supplier",
                                                            onClick: () => this.props.history.push(`/suppliers/update/${supplier.supp_id}`),
                                                            display: access.updateSupplier,
                                                            disabled: viewing.update || supplier.supp_deleted === 1
                                                        },
                                                        {
                                                            icon: "portrait",
                                                            label: "Upload Logo",
                                                            onClick: () => this.handleDeployUploadLogo(),
                                                            display: true,
                                                            disabled: !access.uploadLogo
                                                        },
                                                        {
                                                            icon: "portrait",
                                                            label: "Upload Missing Image",
                                                            onClick: () => this.handleDeployUploadLogoMissing(),
                                                            display: true,
                                                            disabled: !access.uploadLogo
                                                        },
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item xs={10} md={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="user"
                                                                label={`A/C: ${supplier.supp_account_number}`}
                                                            />
                                                        </Grid>
                                                        {!_.isEmpty(supplier.supp_trading_name) && (
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="info-circle"
                                                                    label={`Trading As: ${supplier.supp_trading_name}`}
                                                                />
                                                            </Grid>
                                                        )}
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="calendar"
                                                                label={`Created ${moment(supplier.supp_created_datetime, 'YYYY-MM-DD HH:mm').format("MM/YYYY")}`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="purchase"
                                                                label={`Last Purchase Order: ${supplier.lastPo ?? 'Never'}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={6}>
                                                        <Grid container spacing={2} justify="flex-end">
                                                            <Grid item>
                                                                <ActionChip action={{u: 0, p: 0}} staff={staff[this.props.loggedInStaff.id]} page="supplier" />
                                                            </Grid>
                                                            {_.map(viewing.list, (view, idx) => {
                                                                if(staff[view.i]) {
                                                                    return (
                                                                        <Grid item key={idx}>
                                                                            <ActionChip action={view} staff={staff[view.i]} page="supplier" />
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Grid item xs={2} align='right'>
                                                        <ActionChip staff={{name: `${(_.size(viewing.list) + 1)} viewing`}} page="supplier" />
                                                    </Grid>
                                                </Hidden>
                                            </Grid> 
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    {supplier.supp_deleted === 1 && (
                                        <Grid item xs={12}>
                                            <Box borderBottom="1px solid #ddd">
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <strong>Supplier Deleted</strong> - Please contact Sales Admin to re-instate this supplier...
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={7}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={12} md={6}>
                                                                <Grid container spacing={3}>
                                                                    {tradingAddress && (
                                                                        <Grid item xs={12}>
                                                                            <PaddedPaper>
                                                                                <Box pb={1.5}><Typography variant="h6">Company Details</Typography></Box>
                                                                                <Grid container className="pb-1">
                                                                                    <Grid item>
                                                                                        <FAIcon icon="map-marker" size={12} />
                                                                                    </Grid>
                                                                                    <Grid item xs>
                                                                                        {_.map(tradingAddress, (line, idx) => {
                                                                                            if(line?.length > 0) {
                                                                                                return (
                                                                                                    <Typography variant='body2' key={idx} component='div' gutterBottom>
                                                                                                        {line}
                                                                                                    </Typography>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {supplier.supp_phone.length > 0 && (
                                                                                    <Typography variant="body2" component="div" className="pb-1">
                                                                                        <FAIcon icon="phone" size={12} />
                                                                                        {canDial ? (
                                                                                        <Link className="textDefault cursorPointer" onClick={() => this.context.useDial(supplier.supp_phone)}>
                                                                                            {supplier.supp_phone}
                                                                                            <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                                                                        </Link>
                                                                                        ) : supplier.supp_phone}
                                                                                    </Typography>
                                                                                )}
                                                                                {supplier.supp_email.length > 0 && (
                                                                                    <Typography variant="body2" component="div" className="pb-1">
                                                                                        <FAIcon icon="envelope" size={12} />
                                                                                        <Link className="textDefault" href={`mailto:${supplier.supp_email}`}>{supplier.supp_email}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                )}
                                                                                {supplier.supp_website.length > 0 && (
                                                                                    <Typography variant="body2" component="div">
                                                                                        <FAIcon icon="globe" size={12} />
                                                                                        <Link className="textDefault" href={`${!supplier.supp_website.match(/^[a-zA-Z]+:\/\//) ? 'http://' : ''}${supplier.supp_website}`} target="_blank">
                                                                                            {supplier.supp_website}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                )}
                                                                            </PaddedPaper>
                                                                        </Grid>     
                                                                    )}
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Supplier Details" />
                                                                            {!_.isEmpty(supplier.aliases) && (
                                                                                <>
                                                                                    <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                        <FAIcon type="light" style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="user" size="small" />
                                                                                        Aliases
                                                                                    </Typography> 
                                                                                    {_.map(supplier.aliases, (alias, idx) => (
                                                                                        <Typography variant="body2" paragraph key={idx}>
                                                                                            {alias.alias_name}
                                                                                        </Typography>
                                                                                    ))}
                                                                                </>
                                                                            )}
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="users-class" size="small" />
                                                                                Supplier Type
                                                                            </Typography> 
                                                                            <Typography variant="body2" paragraph>
                                                                                {supplier.type?.type_name ?? 'Unknown'}
                                                                            </Typography>
                                                                            {!_.isEmpty(supplier.supp_legal_status) && (
                                                                                <>
                                                                                    <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                        <FAIcon type="light" style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="landmark" size="small" />
                                                                                        Company Legal Status
                                                                                    </Typography> 
                                                                                    <Typography variant="body2" paragraph>
                                                                                        {supplier.supp_legal_status} {supplier.supp_reg_number && supplier.supp_reg_number.length > 0 && ` (${supplier.supp_reg_number})`}
                                                                                    </Typography>    
                                                                                </>  
                                                                            )} 
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" icon="file-contract" size="small" />
                                                                                Payment Terms
                                                                            </Typography>    
                                                                            <Typography variant="body2" paragraph>
                                                                                {supplier?.payment_terms?.pt_name ?? 'Unknown'}
                                                                            </Typography>    
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="receipt" size="small" />
                                                                                VAT Number
                                                                            </Typography> 
                                                                            <Typography variant="body2">
                                                                                {!_.isEmpty(supplier.supp_vat_number) ? supplier.supp_vat_number : '-'}
                                                                            </Typography>    
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Grid container spacing={3}>
                                                                    {supplier.primary_contact && (
                                                                        <Grid item xs={12}>
                                                                            <PaddedPaper>
                                                                                <Box pb={1.5}><Typography variant="h6">Primary Contact</Typography></Box>
                                                                                <Typography variant="body2" className='pb-1'><FAIcon icon="user" size={12} />{supplier.primary_contact.contact_first_name} {supplier.primary_contact.contact_last_name}</Typography>
                                                                                {supplier.primary_contact.contact_mobile.length > 0 && (
                                                                                    <Typography variant="body2" component="div" className="pb-1"><FAIcon icon="mobile" size={12} />
                                                                                        {canDial ? (
                                                                                            <Link className="textDefault cursorPointer" onClick={() => this.context.useDial(supplier.primary_contact.contact_mobile)}>
                                                                                                {supplier.primary_contact.contact_mobile}
                                                                                                <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                                                                            </Link>
                                                                                        ) : supplier.primary_contact.contact_mobile}
                                                                                    </Typography>
                                                                                )}
                                                                                {supplier.primary_contact.contact_telephone.length > 0 && (
                                                                                    <Typography variant="body2" component="div" className="pb-1">
                                                                                        <FAIcon icon="phone" size={12} />
                                                                                        {canDial ? (
                                                                                            <Link className="textDefault cursorPointer" onClick={() => this.context.useDial(supplier.primary_contact.contact_telephone)}>
                                                                                                {supplier.primary_contact.contact_telephone}
                                                                                                <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                                                                            </Link>
                                                                                        ) : supplier.primary_contact.contact_telephone}
                                                                                    </Typography>
                                                                                )}
                                                                                {supplier.primary_contact.contact_email.length > 0 && (
                                                                                    <Typography variant="body2" component="div">
                                                                                        <FAIcon icon="envelope" size={12} />
                                                                                        <Link className="textDefault" href={`mailto:${supplier.primary_contact.contact_email}`}>
                                                                                            {supplier.primary_contact.contact_email}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                )}
                                                                            </PaddedPaper>
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Ordering Details" />
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0,0,0,0.54)'}} icon="clock" size="small" />
                                                                                Cut Off Time
                                                                            </Typography>   
                                                                            {(supplier.cutOffData && (
                                                                                <>
                                                                                    {_.map(supplier.cutOffData, (co, idx) => (
                                                                                        <Typography variant="body2" className={_.size(supplier.cutOffData) === (idx + 1) ? 'mb-2' : undefined} key={idx}>
                                                                                            {co.label} - {co.time}
                                                                                        </Typography>    
                                                                                    ))}
                                                                                </>  
                                                                            )) || (
                                                                                <Typography variant="body2" paragraph>Not configured</Typography>
                                                                            )}
                                                                            {supplier.supp_foc_min_order_value !== "0.00" && (
                                                                                <>
                                                                                    <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                        <FAIcon type="light" style={{color: 'rgba(0,0,0,0.54)'}} icon="coins" size="small" />
                                                                                        Free Delivery On Purchase Orders Over
                                                                                    </Typography>   
                                                                                    <Typography variant="body2" paragraph>
                                                                                        {currencyFormat.format(supplier.supp_foc_min_order_value)}
                                                                                    </Typography>    
                                                                                </>
                                                                            )}
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0,0,0,0.54)'}} icon="calendar" size="small" />
                                                                                Lead Time
                                                                            </Typography>   
                                                                            <Typography variant="body2" paragraph>
                                                                                {getLeadTime(supplier.supp_lead_time)}
                                                                            </Typography>   
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0,0,0,0.54)'}} icon="coins" size="small" />
                                                                                Minimum Order Value
                                                                            </Typography>   
                                                                            <Typography variant="body2" className={parseInt(supplier.supp_min_order_value) === 0 ? '' : 'textError'} paragraph>
                                                                                {currencyFormat.format(supplier.supp_min_order_value)}
                                                                            </Typography> 
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon icon="file-contract" size="small" />
                                                                                PO Required
                                                                            </Typography>    
                                                                            <Typography variant="body2" className={!supplier.supp_po_required ? '' : 'textError'} paragraph>
                                                                                {supplier.supp_po_required ? 'Yes' : 'No'}
                                                                            </Typography>  
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0,0,0,0.54)'}} icon="building" size="small" />
                                                                                Ship Here Preferred
                                                                            </Typography>   
                                                                            <Typography variant="body2" className={!supplier.supp_ship_here ? '' : 'textError'} paragraph>
                                                                                {supplier.supp_ship_here ? 'Yes' : 'No'}
                                                                            </Typography>   
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0,0,0,0.54)'}} icon="envelope" size="small" />
                                                                                E-mail Direct Despatch Notes
                                                                            </Typography>   
                                                                            <Typography variant="body2" className={!supplier.supp_send_despatch_note ? '' : 'textError'} paragraph>
                                                                                {supplier.supp_send_despatch_note ? 'Yes' : 'No'}
                                                                            </Typography>  
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0,0,0,0.54)'}} icon="envelope" size="small" />
                                                                                Purchase Order Copy E-mail Addresses
                                                                            </Typography>   
                                                                            <Typography variant="body2" className={_.isEmpty(supplier.po_emails) ? '' : 'textError'}>
                                                                                {_.isEmpty(supplier.po_emails) ? 'No' : supplier.po_emails.map(el => el.spoea_email_address).join(', ')}
                                                                            </Typography>  
                                                                            
                                                                        </PaddedPaper>
                                                                    </Grid>                   
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={5}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Supplier Insight" />
                                                                    <Grid container spacing={3}>    
                                                                        <Grid item xs={12} lg={6}>                                                        
                                                                            <Tile
                                                                                noPaper
                                                                                icon="purchase"
                                                                                title="Purchase Orders"
                                                                                content={supplier.latest_purchase_orders_count}
                                                                                onClick={() => this.handleTabChange(4)}
                                                                            />
                                                                        </Grid>    
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="tag"
                                                                                title="Brands"
                                                                                content={_.size(supplier.brands)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>                                                        
                                                                            <Tile
                                                                                noPaper
                                                                                icon="box-check"
                                                                                title="Products"
                                                                                content={supplier.products_count}
                                                                            />
                                                                        </Grid>    
                                                                        <Grid item xs={12} lg={6}>                                                        
                                                                            <Tile
                                                                                noPaper
                                                                                icon="list"
                                                                                title="Ranges"
                                                                                content={supplier.ranges_count}
                                                                            />
                                                                        </Grid> 
                                                                        <Grid item xs={12} lg={6}>                                            
                                                                            <Tile
                                                                                noPaper
                                                                                icon="badge-percent"
                                                                                title="Preset Discounts"
                                                                                content={_.size(supplier.discounts)}
                                                                            />
                                                                        </Grid> 
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="chart-network"
                                                                                title="Last Activity"
                                                                                content={supplier.lastActivity}
                                                                                onClick={() => this.handleTabChange(8)}
                                                                            />
                                                                        </Grid> 
                                                                    </Grid>    
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography variant="h6" paragraph>
                                                                        Net Supplier Spend
                                                                    </Typography>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <Box maxWidth='100%'>
                                                                                <Line 
                                                                                    data={charts.spend} 
                                                                                    options={{
                                                                                        legend: {
                                                                                            display: false,
                                                                                        },
                                                                                        scales: {
                                                                                            xAxes: [{
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                }
                                                                                            }],
                                                                                            yAxes: [{
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                },
                                                                                                ticks: {
                                                                                                    callback: (value, index, values) => {
                                                                                                        return value.toLocaleString("en-GB", {
                                                                                                            style: "currency",
                                                                                                            currency: "GBP"
                                                                                                        });
                                                                                                    },
                                                                                                    beginAtZero: true,
                                                                                                    min: 0
                                                                                                }
                                                                                            }]
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <ViewDiscounts 
                                                    access={access}
                                                    discounts={supplier.discounts}
                                                    callback={this.loadComponentData}
                                                    DiscountForm={<DiscountForm
                                                                    id={supplier.supp_id}
                                                                    callback={() => { this.props.closeDialog(); this.loadComponentData();}}
                                                                    cancel={this.props.closeDialog}
                                                                />
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <ViewContacts 
                                                    access={access} 
                                                    callback={this.loadComponentData} 
                                                    contacts={supplier.contacts} 
                                                    handleContactSms={this.handleContactSms} 
                                                    name={supplier.supp_company_name} 
                                                    ContactForm={
                                                        <ContactForm
                                                            id={supplier.supp_id}
                                                            type='supplier'
                                                            callback={() => { this.props.closeDialog(); this.loadComponentData();}}
                                                            cancel={this.props.closeDialog}
                                                        />
                                                    }
                                                    forSupplier
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <ViewNotes notes={supplier.notes} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <SearchPurchaseOrders
                                                    // poStatus="supplier"
                                                    supplierId={supplier.supp_id}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item sm={4}>
                                                                <TextField 
                                                                    fullWidth
                                                                    placeholder='RMA Number:'
                                                                    variant='filled'
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                                                    }}
                                                                /> 
                                                            </Grid>  
                                                            <Grid item sm={4}>
                                                                <AutoCompleteSelect 
                                                                    options={[{label: 'Product ABC', value: 1}, {label: 'Product 123', value: 2} ]}
                                                                    placeholder='Containing Product:'
                                                                    variant='filled'
                                                                /> 
                                                            </Grid> 
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Paper>
                                                            <DataTable  
                                                                config={{
                                                                    key: 'rma_id',
                                                                    pagination: true,
                                                                    isLoading: isLoading,
                                                                    alternatingRowColours: true,
                                                                    responsiveImportance: true,
                                                                    plainPagination: true,
                                                                    options: {
                                                                        dataRef: true,
                                                                        reset: this.resetSearch,
                                                                        export: {
                                                                            title: `Returns`,
                                                                            name: `Returns`,
                                                                            excel: true,
                                                                            pdf: true,
                                                                            print: true
                                                                        }
                                                                    }
                                                                }}
                                                                columns={[  
                                                                    {
                                                                        heading: 'RMA Number',
                                                                        field: rowData => rowData.rma_number,
                                                                        dataRef: 'rma_number',
                                                                        main: true,
                                                                        sizeToContent: true
                                                                    },   
                                                                    {
                                                                        heading: 'Date',
                                                                        field: rowData => rowData.rma_date,
                                                                        fieldFormat: 'date',
                                                                        dataRef: 'rma_date',
                                                                        sizeToContent: true
                                                                    },  
                                                                    {
                                                                        heading: 'Status',
                                                                        field: rowData => rowData.rma_status,
                                                                        dataRef: 'rma_status',
                                                                        sizeToContent: true
                                                                    },                           
                                                                    {
                                                                        heading: 'Printed',
                                                                        field: rowData => rowData.rma_printed,
                                                                        dataRef: 'rma_printed',
                                                                        fieldFormat: 'boolean',
                                                                        alignment: 'center',
                                                                        sizeToContent: true
                                                                    },                 
                                                                    {
                                                                        heading: 'Emailed',
                                                                        field: rowData => rowData.rma_emailed,
                                                                        dataRef: 'rma_emailed',
                                                                        fieldFormat: 'boolean',
                                                                        alignment: 'center',
                                                                        sizeToContent: true
                                                                    },         
                                                                    {
                                                                        actions: rowData => {
                                                                            return [
                                                                                {name: 'View', icon: 'binoculars',  link: '/returns/'+rowData.rma_id},
                                                                                {name: 'Update', icon: 'pencil',  link: '/returns/'+rowData.rma_id}                                                                        
                                                                            ]
                                                                        }
                                                                    }
                                                                ]}
                                                                rows={[]}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={6}>
                                                <ViewCallLogs calls={supplier.call_logs} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={7}>
                                                <ViewAddresses 
                                                    access={access}
                                                    callback={this.loadComponentData} 
                                                    addresses={supplier.addresses} 
                                                    name={supplier.supp_company_name} 
                                                    AddressForm={
                                                        <AddressForm
                                                            id={supplier.supp_id}
                                                            type='supplier'
                                                            noRegistered
                                                            noTrading
                                                            callback={() => { this.props.closeDialog(); this.loadComponentData();}}
                                                            cancel={this.props.closeDialog}
                                                        />
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={8}>
                                                <ActivityLog apiRoute={`/suppliers/${supplier.supp_id}/activity`} fullPage />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={9}>
                                                <EmailLog
                                                    key={key}
                                                    filter="supplier"
                                                    filterId={supplier.supp_id}
                                                    staff={staff}
                                                    deployConfirmation={deployConfirmation}
                                                    deploySnackBar={deploySnackBar}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    sendEmail={this.handleDeploySendEmail}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={10}>
                                                <SmsLog
                                                    key={key}
                                                    filter="supplier"
                                                    filterId={supplier.supp_id}
                                                    staff={staff}
                                                    deployConfirmation={deployConfirmation}
                                                    deploySnackBar={deploySnackBar}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    sendSms={access.sendSms ? this.handleDeployAddSms : undefined}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={11}>
                                                <ViewActions
                                                    key={key}
                                                    filter="supplier"
                                                    filterId={supplier.supp_id}
                                                    scheduled={supplier.scheduled_actions}
                                                    staff={staff}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    callback={this.loadComponentData}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={12}>
                                                <ViewCarriages
                                                    access={access}
                                                    callback={this.loadComponentData}
                                                    carriage={supplier.carriage}
                                                    CarriageForm={<CarriageForm
                                                                    id={supplier.supp_id}
                                                                    callback={() => { this.props.closeDialog(); this.loadComponentData();}}
                                                                    cancel={this.props.closeDialog}
                                                                />
                                                    }
                                                    defaultOpt={supplier.supp_def_supp_carr_id}
                                                    defaultFoc={supplier.supp_def_foc_supp_carr_id}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={14}>
                                                <ProductSearch
                                                    supplierId={supplier.supp_id}
                                                    hideAddProduct={true}
                                                />
                                            </TabPanel>
                                            {access.accounts && (
                                                <TabPanel value={currentTab} index={13}>
                                                    <AccountsOverview
                                                        callback={this.getSageData}
                                                        sageLoading={sageLoading}
                                                        supplier={supplier}
                                                        // handleDeployCreditAccount={this.handleDeployCreditAccount}
                                                    />
                                                </TabPanel>
                                            )}
                                            <TabPanel value={currentTab} index={15}>
                                                {(_.isEmpty(supplier.brands) && (
                                                    <Typography variant="h5" className="fw-400" align='center'>
                                                        No brands have been added for this supplier
                                                    </Typography>
                                                )) || (
                                                    <Paper>
                                                        <DataTable  
                                                            config={{
                                                                key: 'rowIdx',
                                                                alternatingRowColours: true,
                                                                pagination: false,
                                                                options: {
                                                                    dataRef: true,
                                                                    export: {
                                                                        pdf: true,
                                                                        excel: true,
                                                                        print: true,
                                                                        title: supplier.supp_company_name + ' - Brands',
                                                                        name: 'Brands'
                                                                    }
                                                                }
                                                            }}
                                                            columns={[
                                                                {
                                                                    heading: 'Brand Name',
                                                                    field: rowData => rowData.brand_name,
                                                                    dataRef: 'brand_name',
                                                                    important: true,
                                                                    main: true,
                                                                },   
                                                                {
                                                                    heading: 'Products Assigned',
                                                                    field: rowData => rowData.products_count ?? 0,
                                                                    dataRef: 'products_count',
                                                                    sizeToContent: true,
                                                                    alignment: 'right'
                                                                },
                                                            ]}
                                                            rows={supplier.brands}
                                                        />
                                                    </Paper>
                                                )}
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={16}>
                                                {(_.isEmpty(supplier.ranges) && (
                                                    <Typography variant="h5" className="fw-400" align='center'>
                                                        No ranges have been added for this supplier
                                                    </Typography>
                                                )) || (
                                                    <Paper>
                                                        <DataTable  
                                                            config={{
                                                                key: 'rowIdx',
                                                                alternatingRowColours: true,
                                                                pagination: false,
                                                                options: {
                                                                    dataRef: true,
                                                                    export: {
                                                                        pdf: true,
                                                                        excel: true,
                                                                        print: true,
                                                                        title: supplier.supp_company_name + ' - Ranges',
                                                                        name: 'Ranges'
                                                                    }
                                                                }
                                                            }}
                                                            columns={[
                                                                {
                                                                    heading: 'Range Name',
                                                                    field: rowData => rowData.range_name,
                                                                    dataRef: 'range_name',
                                                                    important: true,
                                                                    main: true,
                                                                },
                                                                {
                                                                    heading: 'Products Assigned',
                                                                    field: rowData => rowData.products_count ?? 0,
                                                                    dataRef: 'products_count',
                                                                    sizeToContent: true,
                                                                    alignment: 'right'
                                                                },
                                                            ]}
                                                            rows={supplier.ranges}
                                                        />
                                                    </Paper>
                                                )}
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={17}>
                                                <UpdateSupplierCategories id={supplier.supp_id} />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                    {access.accounts && !_.isEmpty(supplier.supp_account_number) && (
                                        <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={13} label="Finance" />
                                    )}
                                    {access.accounts && !_.isEmpty(supplier.supp_account_number) && (
                                        <Divider />
                                    )}
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={7} label="Addresses" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Contacts" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={12} label="Carriage" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Discounts" />
                                    <Divider />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={17} label="Categories" disabled={!access.updateCategories} />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={15} label="Brands" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={16} label="Ranges" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={14} label="Products" />
                                    <Divider />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} label="Purchase Orders" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={5} label="Returns" disabled />
                                    <Divider />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={8} label="Timeline" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={11} label="Actions" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={6} label="Calls" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="Notes" />
                                    <Divider />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={9} label="E-mails" />
                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={10} label="SMS" />
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        loggedInStaff: state.staffAuth.staff,
        statePersistence: state.statePersistence,
        ui: state.ui
    }
)

const mapDispatchToProps = dispatch => (
    {
        closeDialog: () => dispatch(closeDialog()),
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullScreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullScreen, disableExit)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        setPersistence: (key, state) => dispatch(setPersistence(key, state))
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ViewSupplier));