import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import AdditionalInvite from 'Components/Meetings/AdditionalInvite/AdditionalInvite';
import NewMeeting from 'Components/Meetings/NewMeeting/NewMeeting';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { SHORTLINK_BASE_URL } from 'Constants';
import StatusChip from 'Components/Common/Chips/StatusChip';

const initialState = () => ({
    access: {
        newMeeting: false,
        finishMeeting: false,
        sendInvite: false
    },
    isLoading: true,
    searchResults: {},
    searchString: ''
})

class MeetingSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.timeout = false;
    }

    componentDidMount = () => {
        API.multiAccess([
            'new-meeting',
            'meeting-search:finish',
            'meeting-search:invite'
        ])
        .then(([
            newMeeting,
            finishMeeting,
            sendInvite
        ]) => {
            this.setState({
                access: {
                    newMeeting,
                    finishMeeting,
                    sendInvite
                }
            }, () => {
                this.props.pageTitle([1, 'Meetings', 'Search']);
                this.getSearchData();
            });
        })
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    callback = () => {
        this.props.closeDialog();
        this.getSearchData();
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/meetings/all', {
                props: {
                    cancellation: true
                },
                params: {
                    searchString: this.state.searchString
                }
            })
            .then(result => {
                if(result?.data) {
                    result.data.forEach(meeting => {
                        if(meeting.status === 'Active') {
                            meeting.status_value = 'Active';
                        }
                        else {
                            meeting.status_value = 'Finished';
                        }
                    });
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    });
                }
            });
        })
    }

    handleDeployAdditionalInvite = meeting => {
        const { closeDialog, deployConfirmation, deploySnackBar } = this.props;
        this.props.deployDialog(
            <AdditionalInvite
                callback={this.callback}
                closeDialog={closeDialog}
                meetingName={meeting.name}
                meetingId={meeting.id}
                deployConfirmation={deployConfirmation}
                deploySnackBar={deploySnackBar}
            />
        , false, "Additional Invitation", "standard", "sm", false, true);

        
    }

    handleDeployNewMeeting = () => {
        const { closeDialog, deployConfirmation, deploySnackBar } = this.props;
        this.props.deployDialog(
            <NewMeeting
                callback={this.callback}
                closeDialog={closeDialog}
                deployConfirmation={deployConfirmation}
                deploySnackBar={deploySnackBar}
            />
        , false, "New Meeting", "standard", "sm", false, true);
    }

    handleFinishMeeting = id => {
        API.post(`/meetings/${id}/finish`)
        .then((result) => {
            if(result?.data) {
                if(result.data?.errors) {
                    this.props.deploySnackBar("error", "Unable to finish meeting");
                } else {
                    this.getSearchData();
                    this.props.deploySnackBar("success", "The meeting was successfully finished");
                }
            }
        });
    }

    onSearchStringChange = e => {
        this.setState({
            searchString: e.target.value
        }, this.setSearch);
    }

    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData();
        });
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    render() {
        const { access, isLoading, searchResults, searchString } = this.state;
        const { deployConfirmation, staff } = this.props;
        return (
            <Grid container spacing={1}>      
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                        <TextField 
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder='Search:'
                            value={searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                            }}
                        /> 
                    </Grid>      
                    {access.newMeeting && (
                        <Grid item xs={12} sm={7} md={8} xl={9} align='right'> 
                            <Button 
                                color="primary" 
                                size="small"
                                variant="text"
                                onClick={this.handleDeployNewMeeting}
                            >   
                                <FAIcon type="light" icon="plus-circle" size="small" button />
                                Create Meeting
                            </Button>
                        </Grid>
                    )}
                </Grid>           
                <Grid item xs={12}>
                    <Paper>
                        <DataTable  
                            config={{
                                key: 'id',
                                pagination: true,
                                isLoading: isLoading,
                                alternatingRowColours: true,
                                responsiveImportance: true,
                                options: {
                                    dataRef: true,
                                    reset: this.resetSearch,
                                    export: {
                                        title: `Meetings`,
                                        name: `meetings`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Meeting Name',
                                    dataRef: 'name',
                                    field: rowData => (
                                        <>
                                            <Typography variant="body1">
                                                {rowData.name}
                                            </Typography>
                                            {rowData.cust && (
                                                <Typography variant="caption" className="fw-400">
                                                    Customer Meeting | {rowData.cust}
                                                </Typography>
                                            )}
                                            {rowData.supp && (
                                                <Typography variant="caption" className="fw-400">
                                                    Supplier Meeting | {rowData.supp}
                                                </Typography>
                                            )}
                                        </>
                                    ),
                                    main: true,
                                    important: true
                                },
                                {
                                    heading: 'Meeting Host',
                                    dataRef: 'sid',
                                    field: rowData => staff?.[rowData?.sid ?? 0]?.name ?? '-',
                                },
                                {
                                    heading: 'Attended',
                                    dataRef: 'allJoined',
                                    field: rowData => rowData.joined > 0,
                                    fieldFormat: 'boolean',
                                    alignment: 'center',
                                    tooltip: rowData => `${rowData.joined}/${rowData.invited} invitees attended`
                                },
                                {
                                    heading: 'Invitations',
                                    dataRef: 'invited',
                                    field: rowData => rowData.invited,
                                    main: true,
                                    important: true,
                                    alignment: 'center',
                                    tooltip: rowData => (!_.isEmpty(rowData.inv) && (
                                        <>
                                            {_.map(rowData.inv, (invite, idx) => (
                                                <div className={idx > 0 ? 'mt-1' : undefined}>
                                                    <span className="fw-400">{invite.n} ({invite.t}):</span><br />
                                                    {invite.c && (
                                                        <>
                                                            {SHORTLINK_BASE_URL}{invite.c}<br />
                                                        </>
                                                    )}
                                                    {invite.j === 1 ? 'Joined' : 'Not Joined'}
                                                </div>
                                            ))}
                                        </>
                                    )) || 'No Invitations Sent'
                                },
                                {
                                    heading: 'Created On',
                                    dataRef: 'created',
                                    field: rowData => rowData.created,
                                    fieldFormat: 'datetime',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Status',
                                    dataRef: 'status',
                                    field: rowData => <StatusChip status={rowData.status} />,
                                    important: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Join Meeting', icon: 'sign-in', linkExternal: rowData.link, disabled: (!rowData.link || rowData.status_value === 'Finished')},
                                            {name: 'Invite', icon: 'plus-circle', onClick: () => this.handleDeployAdditionalInvite(rowData), disabled: (rowData.status_value === 'Finished')},
                                            {name: 'Finish', icon: 'times-circle', onClick: () => deployConfirmation("Are you sure you want to finish this meeting?", () => this.handleFinishMeeting(rowData.id)), disabled: (rowData.status_value === 'Finished')},
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </Paper>
                </Grid>               
            </Grid>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, de)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(MeetingSearch);