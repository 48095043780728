import React from 'react';
import _ from 'lodash';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

class ProductCategoriesList extends React.PureComponent {

    render() {
        const { categories } = this.props;
        return (
                _.map(categories, (category, idx) => (
                    <ExpansionPanel defaultExpanded key={idx}>
                        <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6">{category.category_name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Update">
                                        <IconButton onClick={e => { e.stopPropagation(); this.props.handleDialogOpen(category.category_id)}}>
                                            <FAIcon type="light" icon="pencil" size="small" button noMargin />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {(_.size(category.children) > 0 && (
                                <DataTable  
                                    idx={idx}
                                    config={{
                                        key: 'category_id',
                                        pagination: true,
                                        plainPagination: true,
                                        options: {
                                            minimalPadding: true,
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: 'Name',
                                            field: rowData => rowData.category_name,
                                            important: true
                                        },
                                        {
                                            heading: 'Products',
                                            field: rowData => rowData.products_count,
                                            alignment: 'center',
                                            important: true
                                        },    
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Update', icon: 'pencil',  onClick: () => this.props.handleDialogOpen(rowData.category_id)},
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={category.children}
                                />
                            )) || (
                                <List>
                                    <ListItem>
                                        <ListItemText primary={<em>No child product categories to show</em>} />
                                    </ListItem>
                                </List>
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))
        )
    }
}

export default ProductCategoriesList;