import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const initialState = {
    courier: {},
    isLoading: true,
}

class CourierConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {   
        this.loadComponentData();    
    }

    loadComponentData = () => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/couriers/' + this.props.id),
            ])
            .then(([result]) => {
                if(result.data.errors) {
                    this.props.history.push('/couriers')
                } else {
                    this.setState({
                        courier: result.data,
                        isLoading: false
                    });
                }
            });
        });
    }

    render() {
        const { access } = this.props;
        const { courier, isLoading} = this.state;
        const HelpAndSupport = () => (
            <>
                <Typography variant="h6" paragraph>
                    <FAIcon type="light" icon="life-ring" style={{color: '#ef3340'}} />
                    Help and Support
                </Typography>              
                <Typography color="primary" variant="body2"> 
                    Enabled
                </Typography>
                <Typography variant="caption" paragraph>
                The delivery method can be used for Sales orders
                </Typography>      
                <Typography color="primary" variant="body2"> 
                    Order Cut-Off
                </Typography>
                <Typography variant="caption" paragraph>
                    RED knows the cut off times for orders dispatched using this delivery method
                </Typography>      
                <Typography color="primary" variant="body2"> 
                    Requires Approval
                </Typography>
                <Typography variant="caption">
                    Orders and Quotes will be sent for approval if this method is used
                </Typography>  
            </>
        )
        return (  
            <Grid container spacing={3}>
                {(isLoading && (                  
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <LoadingCircle />
                        </PaddedPaper>
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Paper>                 
                                        <DataTable  
                                            config={{
                                                key: 'courier_conf_id',
                                                responsiveImportance: true,
                                                isLoading: this.state.isLoading,
                                                pagination: true,
                                                alternatingRowColours: true,
                                                noResultsText: 'No delivery methods have been added for this courier',
                                                options: {
                                                    dataRef: true,
                                                    reset: this.loadComponentData,
                                                    helpAndSupport: <HelpAndSupport />,
                                                    export: {
                                                        title: `${courier.courier_name} Delivery Rates`,
                                                        name: `${courier.courier_name}Rates`,
                                                        excel: true,
                                                        pdf: true,
                                                        print: true
                                                    }
                                                }
                                            }}
                                            columns={[    
                                                {
                                                    heading: 'Service',
                                                    field: rowData => `${courier.courier_name} ${rowData.courier_conf_service}`,
                                                    dataRef: 'courier_conf_service',
                                                    main: true,
                                                    important: true,
                                                },      
                                                {
                                                    heading: 'Delivery Charge',
                                                    field: rowData =>   rowData.courier_conf_custom_charge === 'false' ? 
                                                                            (rowData.courier_conf_charge === '0.00' ? 
                                                                                <Typography variant="body2" className="textSuccess">Free Delivery</Typography> 
                                                                                : 
                                                                                `£${rowData.courier_conf_charge}`) 
                                                                            : 
                                                                            <Typography variant="body2" className="textError">Varies Per Order</Typography>,
                                                    dataRef: 'delivery',
                                                    important: true,
                                                    sizeToContent: true
                                                },      
                                                {
                                                    heading: 'Enabled',
                                                    field: rowData => rowData.courier_conf_active,                                                                       
                                                    fieldFormat: 'boolean',           
                                                    dataRef: 'courier_conf_active',
                                                    alignment: 'center',
                                                    important: true,
                                                    sizeToContent: true
                                                },      
                                                {
                                                    heading: 'Cut-Off Configured',
                                                    field: rowData => rowData.courier_conf_cut_off_known,                           
                                                    fieldFormat: 'boolean',           
                                                    dataRef: 'courier_conf_cut_off_known',
                                                    alignment: 'center',
                                                    sizeToContent: true
                                                },     
                                                {
                                                    heading: 'Requires Approval',
                                                    field: rowData => rowData.courier_conf_auth_required,
                                                    fieldFormat: 'boolean',                   
                                                    dataRef: 'courier_conf_auth_required',
                                                    alignment: 'center',
                                                    sizeToContent: true
                                                },   
                                                {
                                                    heading: 'Order Quantity',
                                                    field: rowData => rowData.orders_count,
                                                    dataRef: 'orders_count',
                                                    alignment: 'right',
                                                    sizeToContent: true
                                                },    
                                                {
                                                    heading: 'Last Updated',
                                                    field: rowData => rowData.courier_conf_updated_datetime,
                                                    fieldFormat: 'datetime',
                                                    dataRef: 'courier_conf_updated_datetime',
                                                    alignment: 'right',
                                                    sizeToContent: true
                                                },                    
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'Update', icon: 'pencil', onClick: () => this.props.toggleDialog('updateConfiguration', rowData.courier_conf_id), disabled: !access.updateConfiguration}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={courier.configuration}
                                        />                                         
                                    </Paper> 
                                </Grid>
                            </Grid>    
                        </Grid>
                    </>         
                )}               
            </Grid>
        )
    }
}

export default CourierConfiguration;