import React from 'react';
import Qr from 'react-qr-reader'

const QrReader = ({className, delay, facingMode, handleScan, showViewFinder, width}) => {
    const handleError = err => {
        console.error(err)
    }
    return (
        <Qr
            className={className}
            delay={delay ?? 250}
            facingMode={facingMode ?? 'environment'}
            onError={handleError}
            onScan={handleScan}
            showViewFinder={showViewFinder ?? false}
            style={{width: width ?? '100%' }}
        />
    );
}

export default QrReader;