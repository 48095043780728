import React from 'react';
import Tile from 'Components/Common/Tiles/Tile';

const BooleanTile = ({content, title, isTrue, noPaper, onClick, small}) => {
    
    const icon = isTrue ? 'check-circle' : 'exclamation-circle';
    const color = isTrue ? '#4CAF50' : '#ef3340';

    return (
        <Tile
            icon={icon}
            color={color}
            title={title}
            content={content}
            noPaper={noPaper}
            onClick={onClick}
            small={small}
        />
    )
}

export default BooleanTile;