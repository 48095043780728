import React, { useEffect, useState }from 'react';
import { useDispatch } from 'react-redux';
import uuidv4 from 'uuid/v4';

import Search from 'Components/Common/Search/Search';

import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import API from 'API';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';

const ProfileUpdate = ({pageTitle, forAccounts, forAdmin}) => {
    
    const [key, setKey] = useState(uuidv4());

    const dispatch = useDispatch(); 

    const handleAcceptOrReject = (id, accept) => {
        API.put(`/customers/profileUpdates/${id}`, {accept})
        .then(res => {
            if(res?.data?.success) {
                dispatch(deploySnackBar(`success`, `The change was ${accept ? 'accepted and applied to the customer' : 'rejected'} successfully`));
            } else {
                dispatch(deploySnackBar(`error`, `There was an error ${accept ? 'accepting' : 'rejecting'} the change - please try again`));
            }
            setKey(uuidv4());
        })
    }

    useEffect(() => {
        setKey(uuidv4());
    }, [forAccounts, forAdmin]);

    return (
        <Search
            key={forAccounts ? 'cpu-accounts' : 'cpu-admin'}
            innerKey={key}
            pageTitle={pageTitle}
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/customers/profileUpdates',
                apiParams: !forAdmin ? {} : {
                    forAdmin: 1
                },
                columns: [
                    {
                        heading: 'Customer',
                        field: rowData => rowData.an,
                        important: true,
                    },
                    {
                        heading: 'Account',
                        field: rowData => rowData.ac,
                        important: true,
                    },
                    {
                        heading: 'Requester',
                        field: rowData => rowData.us,
                        important: true,
                    },
                    {
                        heading: 'Data Type',
                        field: rowData => rowData.fd,
                        important: true,
                    },
                    {
                        heading: 'Current Value',
                        field: rowData => rowData.cv,
                        important: true,
                        cellProps: () => ({
                            style: {
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                overflow: 'hidden',
                            }
                        }),
                    },
                    {
                        heading: 'New Value',
                        field: rowData => rowData.nv,
                        important: true,
                        cellProps: () => ({
                            style: {
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                overflow: 'hidden',
                            }
                        }),
                    },
                    {
                        heading: 'Notes',
                        field: rowData => rowData.nt || '-',
                        important: true,
                        cellProps: () => ({
                            style: {
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                overflow: 'hidden',
                            }
                        }),
                    },
                    {
                        heading: 'Requested',
                        field: rowData => rowData.ca,
                        fieldFormat: 'datetime',
                        important: true,
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'Accept', disabled: rowData.fd === 'Credit Limit' || rowData.fd === 'Contact', hideIfDisabled: true, icon: 'check', onClick: () => handleAcceptOrReject(rowData.id, true)},
                                {name: rowData.fd === 'Credit Limit' || rowData.fd === 'Contact' ? 'Acknowledge' : 'Reject', icon: rowData.fd === 'Credit Limit' || rowData.fd === 'Contact' ? 'user-check' : 'times', onClick: rowData.fd === 'Credit Limit' ? () => dispatch(deployConfirmation(`Please review the request and amend the credit limit using the normal process (if accepted)`, () => handleAcceptOrReject(rowData.id, false))) : rowData.fd === 'Contact' ? () => dispatch(deployConfirmation(`Please make contact with the customer to discuss (if required)`, () => handleAcceptOrReject(rowData.id, false))) : () => handleAcceptOrReject(rowData.id, false)},
                            ]
                        }
                    }
                ],
                dataRef: false,
                excel: false,
                key: 'id',
                name: 'profile-updates',
                pagination: true,
                pageTitleData: forAdmin ? [0, 'Customers', 'Profile Update Requests'] : [0, 'Finance', 'Profile Update Requests'],
                pdf: false,
                persistenceId: null,
                placeholderData: false,
                print: false,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 10,
                showCustomer: true,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showStaff: false,
                showTotals: false,
                title: 'Customer Profile Update Requests'
            }}
        />
    )
}

export default ProfileUpdate;