import React from 'react';
import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
import StaffChip from 'Components/Common/Chips/StaffChip';

const CompletedWhOrders = () => {
    const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/completedOrdersAA',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Order Date',
                        field: rowData => rowData.d,
                        fieldFormat: 'date',
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'SO Number',
                        field: rowData => rowData.on,
                        dataRef: 'on',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Acc Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Acc Number',
                        field: rowData => rowData.cr,
                        dataRef: 'cr',
                        sizeToContent: true
                    },
                    {
                        heading: 'Acc Code',
                        field: rowData => rowData.cac,
                        dataRef: 'cac',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Items Net',
                        field: rowData => rowData.in,
                        fieldFormat: 'currency',
                        dataRef: 'in',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Despatch Date',
                        field: rowData => rowData.dd,
                        fieldFormat: 'date',
                        dataRef: 'dd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status',
                        field: rowData => rowData.st,
                        dataRef: 'st',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Quoted By',
                        field: rowData => rowData?.qb > 0 ? <StaffChip staff={staff?.[rowData?.qb]} /> : 'N/A',
                        fieldFormat: 'staff',
                        dataRef: 'qb',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Placed By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.tb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'tb',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Picked By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.pb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'pb',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Checked By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.cb]} />,
                        fieldFormat: 'staff',
                        dataRef: 'cb',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Despatched By',
                        field: rowData => <StaffChip staff={staff?.[rowData?.db]} />,
                        fieldFormat: 'staff',
                        dataRef: 'db',
                        important: true,
                        sizeToContent: true,
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'completed-orders-warehouse',
                noResultsText: 'Sorry, no orders have been completed',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Sales'],
                pdf: true,
                persistenceId: 'completedWhOrders',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: true,
                showDate: false,
                showDateRange: true,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showTotals: false,
                title: 'Completed Warehouse Orders Report'
            }}
        />
    )
}

export default CompletedWhOrders;