import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";

import Logo from "Assets/Images/no-img.jpg";
import Alert from "Components/Common/Alert/Alert";
import InsightChip from "Components/Common/Chips/InsightChip";
import StaffChip from "Components/Common/Chips/StaffChip";
import DataTable from "Components/Common/DataTables/DataTable";
import StackTable from "Components/Common/DataTables/StackTable";
import Products from "Components/Common/Forms/QuoteOrderForm/Products";
import Totals from "Components/Common/Forms/QuoteOrderForm/Totals";
import Heading from "Components/Common/Heading/Heading";
import Highlight from "Components/Common/Highlight/Highlight";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import StaffTile from "Components/Common/Tiles/StaffTile";
import Tile from "Components/Common/Tiles/Tile";

import API from "API";
import DatePicker from "Components/Common/DatePickers/DatePicker";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import InvoiceDelivery from "Components/Sales/Misc/InvoiceDelivery";
import { CLOUDFRONT_URL } from "Constants";
import { getPresignedUrl } from "Functions/S3Functions";
import { pdfFromBase64 } from "Helpers/PDFHelper";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import CheckDetails from "./DetailedView/CheckDetails";
import DespatchDetails from "./DetailedView/DespatchDetails";
import PickDetails from "./DetailedView/PickDetails";
import ResolveIssue from "./DetailedView/ResolveIssue";

import { isCrushedIce } from 'Functions/AuthFunctions';
import { isTablet, momentDiffForHumans, momentFormatDate, momentFormatDateTime } from "Functions/MiscFunctions";
import { InputAdornment } from "@material-ui/core";

const ChangePickingDate = ({ callback, id, od, order, status, currentDate }) => {
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        API.put(`/sales/delivery/${id}/changePickingDate`, { date }).then((res) => {
            if (res?.data) {
                if (res.data?.errors) {
                    dispatch(deploySnackBar("error", "Something went wrong, please try again"));
                } else {
                    dispatch(deploySnackBar("success", "The despatch date for this delivery has been successfully been changed"));
                }

                callback?.();
                dispatch(closeDialog());
            }
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} align="center">
                <Box pt={4}>
                    <FAIcon icon="calendar-check" type="thin" size="xlarge" noMargin />
                </Box>
            </Grid>
            <Grid item xs={12} align="center">
                <Typography variant="h6">{status === "Awaiting Picking" ? `Change Despatch Date` : `Change Expected Despatch Date`}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box ml={4} mr={4} mt={2} mb={3}>
                    <Box className="content-light-grey" p={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} align="center">
                                <Typography variant="h6" paragraph>
                                    Delivery #{od}
                                </Typography>
                                {order.order_requested_delivery_date !== "0000-00-00" && (
                                    <Typography variant="body2" className="textError fw-400" paragraph>
                                        Customer requested date: 
                                        <br />
                                        {moment(order.order_requested_delivery_date).format("DD/MM/YYYY")}
                                    </Typography>
                                )}
                                <Typography variant="body2" className="fw-400">
                                    {status === 'Awaiting Picking' ? 'Despatching on: ' : 'Expected despatch date: '}
                                    <br /> 
                                    {(order.order_requested_delivery_date !== "0000-00-00" && (
                                        (moment(currentDate).isAfter(moment(order.order_requested_delivery_date)) && (
                                            moment(currentDate).format("DD/MM/YYYY")
                                        )) || (
                                            moment(order.order_requested_delivery_date).format("DD/MM/YYYY")
                                        )
                                    )) || (
                                        moment(currentDate).format("DD/MM/YYYY")
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <FormControl fullWidth style={{ width: 165 }}>
                                    <DatePicker
                                        type="date" 
                                        value={date} 
                                        onChange={(date) => setDate(moment(date).format("YYYY-MM-DD"))} 
                                        minDate={order.order_requested_delivery_date !== "0000-00-00" ? order.order_requested_delivery_date : undefined} 
                                        inputVariant="filled"
                                        disablePast
                                        autoOk 
                                        endAdornment={date && (
                                            <InputAdornment>
                                                <Tooltip title="Clear Date">
                                                    <IconButton onClick={(e) => { e?.stopPropagation?.(); setDate(null);}} size="small">
                                                        <FAIcon icon="times" size={15} type="solid" disabled noMargin button />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <Button onClick={() => dispatch(closeDialog())} variant="text">
                            <FAIcon icon="times" size={15} button />
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() =>
                                dispatch(
                                    deployConfirmation(
                                        <>
                                            <Typography variant="body2">Are you sure you want to update the despatch date?</Typography>
                                        </>,
                                        () => handleSubmit()
                                    )
                                )
                            }
                            variant="text"
                            color="primary"
                            disabled={date === null}
                        >
                            <FAIcon icon="check" size={15} button />
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const PartShipDelivery = ({ callback, id, reference, od }) => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        setIsLoading(true);
        API.put(`/sales/delivery/${id}/partShipCustom`, { products }).then((res) => {
            if (res?.data) {
                if (res.data?.errors) {
                    dispatch(deploySnackBar("error", "Something went wrong, please try again"));
                } else {
                    dispatch(deploySnackBar("success", "This delivery has been successfully part-shipped"));
                }

                callback?.();
                dispatch(closeDialog());
            }
        });
    };

    const handleChange = (index, value) => {
        let newProducts = [...products];
        if (parseInt(value) >= newProducts[index].min && parseInt(value) <= newProducts[index].max) {
            newProducts[index].ship = value;
        } else {
            newProducts[index].ship = "";
        }
        setProducts(newProducts);
    };

    useEffect(() => {
        let newProducts = [];
        _.each(od.products, (odp) => {
            newProducts.push({
                odp: odp,
                id: odp.odp_id,
                code: odp.productCode,
                name: odp.productName,
                img: odp.image,
                min: 0,
                max: parseInt(odp.qtyAllocated),
                ship: parseInt(odp.qtyAllocated),
            });
        });
        if (!_.isEmpty(newProducts)) {
            setProducts(newProducts);
        } else {
            dispatch(deploySnackBar("error", "Sorry, no product lines are eligible to ship now"));
            dispatch(closeDialog());
        }
    }, [od, dispatch]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box p={3} pb={0} pl={0}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <FAIcon icon="split" type="thin" size={45} noMargin />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h6">Part Ship Management</Typography>
                            <Typography variant="body2">Sales Order Delivery #{reference}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {(isLoading && (
                <Grid item xs={12}>
                    <Box pt={10} pb={10}>
                        <LoadingCircle size={50} />
                        <Typography variant="body2" align="center" className="fw-400">
                            Please wait whilst this delivery is split
                        </Typography>
                    </Box>
                </Grid>
            )) || (
                <>
                    {od.purchase_order?.po_ship_aa_only === 1 && (
                        <Grid item xs={12}>
                            <Box pt={2}>
                                <Alert severity="error">
                                    <strong>Purchase Order #{od.purchase_order.po_reference}</strong>
                                    <br />
                                    This will convert this purchase order into a stock order and detach it from the sales order
                                </Alert>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <DataTable
                            config={{
                                key: "id",
                                alternatingRowColours: true,
                                pagination: false,
                                noRowHover: true,
                            }}
                            columns={[
                                {
                                    heading: "Product",
                                    field: (rowData) => {
                                        const src = rowData.img ? `${CLOUDFRONT_URL}${rowData.img}` : Logo;
                                        return (
                                            (rowData.productId <= 0 && (
                                                <Box ml={3}>
                                                    <FAIcon type="light" icon="text" size={30} noMargin />
                                                </Box>
                                            )) || <Avatar variant="square" alt={rowData.name ?? "Product Image"} src={src} style={{ width: 40, height: 40 }} />
                                        );
                                    },
                                    important: true,
                                    hideInResponsiveDialog: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "",
                                    field: (rowData) => {
                                        return (
                                            <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                <Grid item>
                                                    <Typography variant="body2" className="fw-400">
                                                        {rowData.code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" component="div">
                                                        {rowData.name.substr(0, 65)}
                                                        {rowData.name.length > 65 ? "..." : ""}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        );
                                    },
                                    important: true,
                                },
                                {
                                    heading: "QTY",
                                    field: (rowData) => <Highlight variant="success">{rowData.odp.quantity}</Highlight>,
                                    sizeToContent: true,
                                    alignment: "center",
                                },
                                {
                                    heading: "ALLOC",
                                    field: (rowData) => <Highlight variant={rowData?.odp?.qtyAllocated === rowData?.odp?.quantity ? "success" : "warning"}>{rowData?.odp?.qtyAllocated}</Highlight>,
                                    sizeToContent: true,
                                    alignment: "center",
                                },
                                {
                                    heading: "POP",
                                    field: (rowData) => <Highlight variant={rowData?.odp?.qtyPreOrdered > 0 ? "warning" : "success"}>{rowData?.odp?.qtyPreOrdered}</Highlight>,
                                    sizeToContent: true,
                                    alignment: "center",
                                },
                                {
                                    heading: "SHIP NOW",
                                    field: (rowData) => (
                                        <Box p={1} width={_.some(products, (el) => el.max > 10) ? 250 : 100}>
                                            <TextField
                                                margin="none"
                                                autoComplete="off"
                                                value={rowData.ship}
                                                onChange={(e) => handleChange(rowData.rowIdx, e.target.value)}
                                                InputProps={{
                                                    endAdornment: <Typography color="textSecondary">{` / ${rowData.max}`}</Typography>,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                disabled={rowData.max === 0}
                                                fullWidth
                                            />
                                        </Box>
                                    ),
                                    sizeToContent: true,
                                },
                            ]}
                            rows={products}
                        />
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Grid container spacing={2}>
                            <Grid item xs />
                            <Grid item>
                                <Button onClick={() => dispatch(closeDialog())} variant="text">
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() =>
                                        dispatch(
                                            deployConfirmation(
                                                <>
                                                    <Typography variant="body2">Are you sure you want to part ship this delivery?</Typography>
                                                </>,
                                                () => handleSubmit()
                                            )
                                        )
                                    }
                                    variant="text"
                                    color="primary"
                                    disabled={_.some(products, (p) => p.ship === "") || _.every(products, (p) => p.ship === 0)}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const getLegacyOrderDeliveryInvoicePDF = (deliveryId) => {
    API.get("/sales/delivery/" + deliveryId + "/legacy/pdf", { params: { invoice: true } }).then((result) => {
        if (result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    });
};

const useStyles = makeStyles({
    tab: {
        color: "#fff",
        opacity: 1,
    },
    td: {
        border: "none",
        padding: 4,
    },
});

const DeliverySummary = ({ access, basic, callback, idx, inDialog, od, order }) => {
    const classes = useStyles(),
        history = useHistory(),
        dispatch = useDispatch(),
        ui = useSelector((state) => state.ui),
        staff = useSelector((state) => state.notifications.status);

    const deployDetailedView = (type, idx) => {
        let Component = null;

        switch (type) {
            case "pick":
                Component = PickDetails;
                break;
            case "check":
                Component = CheckDetails;
                break;
            case "despatch":
                Component = DespatchDetails;
                break;
            default:
                return;
        }

        if (Component) {
            dispatch(deployDialog(<Component closeDialog={() => dispatch(closeDialog())} od={od} idx={idx ?? undefined} />, true, "", "standard", "md", false, true));
        } else {
            return;
        }
    };

    const handleChangeDeliveryDate = () => {
        dispatch(deployDialog(<ChangePickingDate callback={callback} id={od.od_id} od={`${od.od_reference}/${od.od_idx}`} order={order} status={od.od_status} currentDate={od.od_schedule_date} />, false, "", "standard", "xs"));
    };

    const despatchToday = () => {
        API.put(`/sales/delivery/${od?.od_id}/despatchNow`).then((res) => {
            if (res?.data) {
                if (res.data?.errors) {
                    callback?.();
                    dispatch(deploySnackBar("error", "Something went wrong, please try again"));
                } else {
                    callback?.();
                    dispatch(deploySnackBar("success", "The despatch date for this delivery has been successfully changed to today - this delivery can now be picked"));
                }
            }
        });
    };

    const getOrderDeliveryDespatchNote = () => {
        API.get(`/sales/delivery/${od.od_id}/despatchNote`)
        .then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    };

    const handleLockOrderDelivery = () => {
        if(od.od_type === 'PO' && od?.pod?.pod_ship_aa_only === 0) {
            dispatch(deployConfirmation(`Do you want to hold this direct despatch delivery with the supplier?`, () => lockOrderDelivery(1), lockOrderDelivery)) 
        } else {
            lockOrderDelivery();
        }
    }

    const lockOrderDelivery = (ddStop = 0) => {
        API.put(`/sales/delivery/${od.od_id}/lock`, { ddStop })
        .then((result) => {
            callback?.();
            if (result?.data?.success) {
                dispatch(deploySnackBar("success", "You have successfully locked this delivery"));
            } else {
                dispatch(deploySnackBar("error", "Something went wrong, please try again"));
            }
        });
    }

    const releaseOrderDelivery = () => {
        API.put(`/sales/delivery/${od.od_id}/release`)
        .then((result) => {
            callback?.();
            if (result?.data?.success) {
                dispatch(deploySnackBar("success", "You have successfully released this delivery"));
            } else {
                dispatch(deploySnackBar("error", "Something went wrong, please try again"));
            }
        });
    }

    const holdOrderDeliveryWithSupplier = () => {
        API.put(`/sales/delivery/${od.od_id}/holdWithSupplier`)
        .then((result) => {
            callback?.();
            if (result?.data?.success) {
                dispatch(deploySnackBar("success", "You have requested this to be held with the supplier"));
            } else {
                dispatch(deploySnackBar("error", "Something went wrong, please try again"));
            }
        });
    }

    const partShipOrderDelivery = () => {
        dispatch(deployDialog(<PartShipDelivery callback={callback} id={od.od_id} reference={`${od.od_reference}/${od.od_idx}`} od={od} />, false, "", "standard", "md"));
    };

    const markOrderDeliveryAsDespatched = () => {
        API.put(`/sales/${od?.od_order_id}/status/${od?.od_id}`, { status: "Despatched" }).then((res) => {
            if (res?.data) {
                if (res.data?.errors) {
                    callback?.();
                    dispatch(deploySnackBar("error", "Something went wrong, please try again"));
                } else {
                    callback?.();
                    dispatch(deploySnackBar("success", "This delivery has been marked as despatched"));
                }
            }
        });
    };

    let customerAddress = [od.od_address_line_one, od.od_address_line_two, od.od_address_line_three, od.od_address_town, od.od_address_county, od.od_address_postcode, od.od_address_country],
        deliveryAddress = [od.od_delivery_address_line_one, od.od_delivery_address_line_two, od.od_delivery_address_line_three, od.od_delivery_address_town, od.od_delivery_address_county, od.od_delivery_address_postcode, od.od_delivery_address_country],
        customerAddressEmpty = true,
        deliveryAddressEmpty = true;

    _.each(deliveryAddress, (line) => {
        if (line && line.length > 0) {
            deliveryAddressEmpty = false;
            return false;
        }
    });

    _.each(customerAddress, (line) => {
        if (line && line.length > 0) {
            customerAddressEmpty = false;
            return false;
        }
    });

    let desp = !_.isEmpty(od?.despatch?.desp_api_json) ? JSON.parse(od?.despatch?.desp_api_json) : null;

    desp = desp ? desp?.shipment?.response?.data?.consignmentDetail[0] : null;

    const handlePopUpToPage = () => {
        history.push(`/sales/${od.od_order_id}:${od.od_id}`)
        dispatch(closeDialog())        
    }

    const orderCallOff = order?.order_requested_delivery_date !== "0000-00-00";

    const hasConsolidation = od?.consolidation?.length;

    const hasParent = od?.parent?.length;

    const children = [];
    if(hasConsolidation) {
        _.forEach(od?.consolidation, (el) => {
            let _childOd = _.find(order.deliveries, _el => _el.od_id === el.odc_child_id);
            if(_childOd) {
                children.push(_childOd);
            }
        });
    }

    let parent = null;
    if(hasParent) {
        parent = _.find(order.deliveries, el => el.od_id === od?.parent[0]?.odc_parent_id);
    }
    
    let despatchDate = od.od_schedule_date;
    let despatchText = 'Despatching';
    let isCheck = false;
    if(od.pod && !od?.pod?.pod_delivered) {
        if(od.pod.pod_despatch_date !== '0000-00-00') {
            despatchDate = od.pod.pod_despatch_date;
            despatchText = 'Despatched';
        } else if(od.pod.pod_check_date !== '0000-00-00') {
            despatchDate = od.pod.pod_check_date;
            isCheck = true;
        } else {
            if(!od.od_confirmed) {
                despatchText = 'Requested despatch'
                despatchDate = od.pod.pod_req_despatch_date;
            } else {
                despatchDate = od.pod.pod_expected_despatch_date;
            }
        }
    } else {
        if(order.order_requested_delivery_date !== '0000-00-00') {
            if(moment(despatchDate, "YYYY-MM-DD").isBefore(moment(order.order_requested_delivery_date, "YYYY-MM-DD"))) {
                despatchDate = order.order_requested_delivery_date;
            }
        }
    }
    if(od.despatch && (od.od_status === 'Despatched' || od.od_status === 'Invoiced')) {
        despatchDate = od.despatch.desp_shipping_date;
        despatchText = 'Despatched';
    }
    return (
        <>
            <Grid container spacing={3} className={inDialog && 'pt-1'}>
                <Grid item className="pb-0" xs={12} lg>
                    <Typography variant="h6" className="fw-400">
                        {inDialog ? `Sales Order Delivery #${od.od_reference}/${od.od_idx}` : `Delivery ${idx} ${!basic ? `of ${_.size(order.deliveries)}` : ``}`}{isCrushedIce() ? ` [ci:od_id:${od.od_id}] [ci:od_idx:${od.od_idx}]` : ``}
                    </Typography>
                </Grid>
                <Grid item className="pb-0">
                    <InsightChip icon="check" label={od.od_status} paddingLeft={8} marginRight={8} large />
                </Grid>
                <Grid item className="pb-0">
                    <InsightChip icon="truck-loading" label={od?.purchase_order?.po_collection === 1 ? "Direct Collection" : od.od_type === "WH" ? (od?.purchase_order ? `Ship Here` : `Warehouse Shipment`) : `Direct Despatch`} paddingLeft={8} large />
                </Grid>
                {!basic && od.purchase_order && (
                    <Grid item className="pb-0">
                        <InsightChip icon="external-link" label={`Purchase Order #${od.purchase_order.po_reference}`} onClick={() => inDialog ? window.open(`/purchase-orders/${od.purchase_order.po_id}`) : history.push(`/purchase-orders/${od.purchase_order.po_id}`)} paddingLeft={8} large />
                    </Grid>
                )}
                {desp && (
                    <Grid item className="pb-0">
                        <InsightChip icon="location" label={`Track Delivery`} onClick={() => window.open(`https://track.dpdlocal.co.uk/search?reference=${desp?.consignmentNumber}&postcode=${od?.od_delivery_address_postcode.replace(" ", "").toLowerCase()}`)} paddingLeft={8} large />
                    </Grid>
                )}
                {!basic && !inDialog && access.processOrder && od.od_status === "Awaiting Stock" && _.some(od.products, (el) => parseInt(el.qtyAllocated) > 0) && _.every(od.products, (el) => el?.parent === 0) && (
                    <Grid item className="pb-0">
                        <InsightChip color="#ef3340" icon="split" label={`Part Ship`} textDefault onClick={() => partShipOrderDelivery()} paddingLeft={8} large />
                    </Grid>
                )}
                {od.od_status !== "Invoiced" && od.od_status !== "Cancelled" && !hasParent && (
                    (od.od_on_hold_lock === 1 && (
                        ((access.releaseDelivery && od?.lock?.ol_reason === 'Manual') || (access.releaseDeliveryAccounts && od?.lock?.ol_reason !== 'Manual')) && (
                            <Grid item className="pb-0">
                                <InsightChip icon="lock-open" label={`Release Delivery`} textDefault onClick={() => dispatch(deployConfirmation(`Are you sure you want to release this delivery?`, releaseOrderDelivery))} paddingLeft={8} large />
                            </Grid>
                        )
                    )) || (
                        access.lockDelivery && (od.od_on_hold === 0 || (od.od_on_hold === 1 && od.od_on_hold_datetime === '0000-00-00 00:00:00')) &&(
                            <Grid item className="pb-0">
                                <InsightChip icon="lock" label={`Lock Delivery`} textDefault onClick={() => dispatch(deployConfirmation(`Are you sure you want to lock this delivery?`, handleLockOrderDelivery))} paddingLeft={8} large />
                            </Grid>
                        )
                    )
                )}
                {(!basic && !inDialog && od.od_status === "Despatched" && parseFloat(od.od_total_net) - parseFloat(od.od_total_carriage) !== 0 && (
                    <Grid item className="pb-0"> 
                        <InsightChip
                            icon="check"
                            label={`Generate Invoice`}
                            textDefault
                            onClick={() =>
                                dispatch(
                                    deployConfirmation(`Are you sure you want to invoice sales order delivery #${od.od_reference}/${od.od_idx}?`, () =>
                                        dispatch(
                                            deployDialog(
                                                <InvoiceDelivery
                                                    callback={callback}
                                                    delivery={`${od.od_reference}/${od.od_idx}`}
                                                    order={order?.order_id}
                                                    despatchedOn={momentFormatDate(od.od_updated_datetime)}
                                                    despatchDue={momentFormatDate(od.od_schedule_date)}
                                                    net={od.od_total_net}
                                                    carriage={od.od_total_carriage}
                                                    od={od.od_id}
                                                />,
                                                true,
                                                "",
                                                "standard",
                                                "xs",
                                                false,
                                                true
                                            )
                                        )
                                    )
                                )
                            }
                            paddingLeft={8}
                            disabled={od.od_on_hold === 1}
                            large
                        />
                    </Grid>
                )) ||
                    (!basic && od.od_status === "Invoiced" && order.order_legacy !== 2 && (
                        <Grid item className="pb-0">
                            <InsightChip
                                icon="file-pdf"
                                label={`Download Invoice`}
                                disabled={parseFloat(od.od_total_net) - parseFloat(od.od_total_carriage) === 0}
                                color={parseFloat(od.od_total_net) - parseFloat(od.od_total_carriage) === 0 ? "#eee" : undefined}
                                textDefault
                                onClick={() => (od.od_legacy === 1 || !od.invoice || od?.invoice?.inv_legacy === 1 ? getLegacyOrderDeliveryInvoicePDF(od.od_id) : getPresignedUrl(od?.invoice?.inv_pdf_filename))}
                                paddingLeft={8}
                                large
                            />
                        </Grid>
                    ))}
                {!basic && !hasParent && !hasConsolidation && (
                    <Grid item className="pb-0">
                        <InsightChip icon="file-pdf" label={`Download Despatch Note`} textDefault onClick={() => getOrderDeliveryDespatchNote()} paddingLeft={8} large />
                    </Grid>
                )}
                {inDialog && (
                    <>
                        <Grid item className="pb-0 pt-05">
                            <Tooltip title="Open Delivery">
                                <IconButton
                                    onClick={handlePopUpToPage}
                                >
                                    <FAIcon icon="binoculars" size={19} button noMargin />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item className="pb-0">
                            <Box width={25} />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} className="pt-05">
                    <Typography variant="body2" className="fw-400">
                        Fulfilled{" "}
                        {od.purchase_order
                            ? od.purchase_order?.po_ship_aa_only === 1 && od.purchase_order?.po_ifm_modified === 1
                                ? `by Advanced Access`
                                : od.purchase_order?.po_ship_aa_only === 1 && od.purchase_order?.po_ifm_modified === 0
                                ? `via Advanced Access by ${od.purchase_order?.supplier?.supp_company_name}`
                                : `direct from ${od.purchase_order?.supplier?.supp_company_name}`
                            : "by Advanced Access"} {!hasConsolidation ? `| ${despatchText}${od?.pod?.pod_ship_aa_only ? ` to here` : ``} on ${momentFormatDate(despatchDate)}${isCheck ? ` (Check Date)` : ``}` : ` | Waiting on other deliveries before despatch`}
                    </Typography>
                    <Typography variant="body2" className="fw-400">
                        Estimated weight: {parseFloat(od.od_total_weight).toFixed(2)}kg
                    </Typography>
                </Grid>
                {hasConsolidation ? (
                    <Grid item xs={12}>
                        <Box className="content-light-white">
                            <Alert severity="error" style={{border: 0}} className="content-light-error">
                                <Typography component="div" className="fw-400" variant="body2">
                                    Ship Complete - Despatch Blocked ({od.od_status === `Awaiting Allocation` ? `Allocation Required` : <>{_.size(children)} additional {_.size(children) === 1 ? 'delivery' : 'deliveries'}</>})
                                </Typography>
                                <Typography component="div" className="fw-300" variant="body2">
                                    An automated consolidation will initiate after the last dependant delivery is booked in, which will send all goods for picking unless this delivery is locked or restricted by a customer requested date.
                                </Typography>
                            </Alert>
                        </Box>
                    </Grid>
                ) : hasParent ? (
                    <Grid item xs={12}>
                        <Box className="content-light-white">
                            <Alert severity="error" style={{border: 0}} className="content-light-error">
                                <Typography component="div" className="fw-400" variant="body2">
                                    Ship Complete - Pending Consolidation (#{parent?.od_reference}/{parent?.od_idx})
                                </Typography>
                                <Typography component="div" className="fw-300" variant="body2">
                                    The order confirmation will show lines from this delivery as consolidated into #{parent?.od_reference}/{parent?.od_idx} - this will not be shipped separately.
                                </Typography>
                            </Alert>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        {((od.od_sys_hold === 1 || (od.od_on_hold === 1 && od.od_on_hold_datetime !== "0000-00-00 00:00:00")) &&
                            ((od?.pod?.pod_ship_aa_only === 1 && (od?.od_type === "PO" || od?.pod?.pod_issue === 1) && <React.Fragment />) || (
                                (od?.od_on_hold_lock === 1 && (
                                    <>
                                        <Box width="100%">
                                            <Alert severity="warning">
                                                <strong>Pending Release - {od?.lock?.ol_reason} ({momentFormatDateTime(od?.od_on_hold_datetime)})</strong>
                                                <br />
                                                This delivery has been locked by {staff?.[od?.lock?.ol_lock_staff_id ?? -1]?.name ?? 'the system'} and cannot be despatched until it is released
                                            </Alert>
                                        </Box>
                                        {od?.od_type === "PO" && (
                                            <Box p={1} pb={0.1} style={{background: '#d9534f'}}>
                                                <Typography variant="h6" gutterBottom className="textLight">
                                                    This delivery is shipping direct - {od?.lock?.ol_dd_stop === 1 ? `sales admin requested to hold` : od?.lock?.ol_dd_stop === 0 ? `no supplier hold requested` :  `please review and make a decision by 11am`}
                                                </Typography>
                                            </Box>   
                                        )}
                                    </>
                                )) || (
                                    <Box width="100%">
                                        <Alert severity="warning">
                                            <strong>On Hold - Subject To Change</strong>
                                            <br />
                                            {(od.od_sys_hold === 1 && `This delivery is currently on hold as the sales order has been modified`) || `This delivery is currently on hold and requires review from Sales Admin`}
                                        </Alert>
                                    </Box>
                                )
                            ))) ||
                            (order.order_legacy === 2 && od.od_status === "Invoiced" && (
                                <Box width="100%">
                                    <Alert severity="success">
                                        <strong>Complete - Customer Free Gift</strong>
                                        <br />
                                        This sales order delivery has been completed
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_status === "Invoiced" && (
                                <Box width="100%">
                                    <Alert severity="success">
                                        {(parseFloat(od.od_total_net) - parseFloat(od.od_total_carriage) <= 0 && (
                                            <>
                                                <strong>Complete - Not Invoiceable (Zero Value)</strong>
                                                <br />
                                                This sales order delivery has been completed but cannot be invoiced as the net item total is &pound;0.00
                                            </>
                                        )) || (
                                            <>
                                                <strong>
                                                    Complete - Invoiced
                                                    {!_.isEmpty(od?.invoice?.inv_reference) ? ` - Invoice Number: ${od?.invoice?.inv_reference} (Due: ${momentFormatDate(od?.invoice?.inv_due_date)}) ` : ` - Invoice Number: TBC`}
                                                </strong>
                                                <br />
                                                This sales order delivery has been completed and was despatched on {momentFormatDate(despatchDate)}
                                            </>
                                        )}
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_status === "Despatched" && (
                                <Box width="100%">
                                    <Alert severity="success">
                                        <strong>Despatched</strong>
                                        <br />
                                        This delivery has been despatched {od.od_type === "PO" ? `by ${od?.purchase_order?.supplier?.supp_company_name}` : ""} and is now ready to be invoiced
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_status === "Awaiting Technical" && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Awaiting Technical</strong>
                                        <br />
                                        This delivery picked and checked and is waiting for release by technical before being despatched
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_status === "Ready for Collection" && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Ready for Collection</strong>
                                        <br />
                                        This delivery has been prepared and is ready for collection by the customer
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_status === "Cancelled" && (
                                <Box width="100%">
                                    <Alert severity="error">
                                        <strong>Cancelled</strong>
                                        <br />
                                        This delivery has been cancelled
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "WH" && od.od_status === "Awaiting Technical" && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Awaiting Technical</strong>
                                        <br />
                                        Stock has been picked, checked and is awaiting release by technical before being despatched
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "WH" && od.od_status === "Awaiting Despatch" && od?.pod?.pod_delivered === 1 && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Awaiting Despatch - Warehouse</strong>
                                        <br />
                                        Stock has been shipped here for this delivery from {od?.purchase_order?.supplier?.supp_company_name} and is waiting to be despatched
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "WH" && od.od_status === "Awaiting Check" && od?.pod?.pod_delivered === 1 && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Awaiting Check - Warehouse</strong>
                                        <br />
                                        Stock has been shipped here for this delivery from {od?.purchase_order?.supplier?.supp_company_name} and is waiting to be checked
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "WH" && od.od_status === "Awaiting Picking" && od?.pod?.pod_delivered === 1 && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Awaiting Picking - Warehouse</strong>
                                        <br />
                                        Stock has been shipped here for this delivery from {od?.purchase_order?.supplier?.supp_company_name} and is waiting to be picked
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "WH" && od?.pod?.pod_status === "Despatched" && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Purchase Order #{od?.pod?.pod_reference} - Ship Here - Awaiting Delivery</strong>
                                        <br />
                                        {od?.purchase_order?.supplier?.supp_company_name} despatched this order to here on {momentFormatDate(despatchDate)}
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "WH" && od?.pod?.pod_status === "Awaiting Despatch" && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Purchase Order #{od?.pod?.pod_reference} - Ship Here - Awaiting Despatch</strong>
                                        <br />
                                        {od?.purchase_order?.supplier?.supp_company_name} has not yet despatched this order
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "PO" && od.od_confirmed === 1 && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>
                                            Purchase Order #{od?.pod?.pod_reference} ({od.pod?.pod_ship_aa_only === 1 ? `Ship Here` : `Direct Despatch`}) - Awaiting Supplier Despatch
                                        </strong>
                                        <br />
                                        {od?.purchase_order?.supplier?.supp_company_name} has confirmed this delivery - expected despatch date{od?.pod?.pod_ship_aa_only ? ` to here` : ``}: {momentFormatDate(despatchDate)}{isCheck ? ` (Check Date)` : ``}
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "PO" && od.od_confirmed === 0 && od.purchase_order?.po_status === "Awaiting Submission" && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Purchase Order #{od?.pod?.pod_reference} - Awaiting Submission</strong>
                                        <br />
                                        Purchase Order #{od?.purchase_order?.po_reference} has not been submitted to {od?.purchase_order?.supplier?.supp_company_name} yet
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "PO" && od.od_confirmed === 0 && (
                                <Box width="100%">
                                    <Alert severity="warning">
                                        <strong>Purchase Order #{od?.pod?.pod_reference} - Awaiting Confirmation</strong>
                                        <br />
                                        {od?.purchase_order?.supplier?.supp_company_name} has not acknowledged the Purchase Order yet
                                    </Alert>
                                </Box>
                            )) ||
                            (od.od_type === "WH" &&
                                (((od.od_status === "Awaiting Check" || od.od_status === "Awaiting Despatch" || od.od_status === "Despatched" || od.od_status === "Invoiced") && (
                                    <Box width="100%">
                                        <Alert severity="warning">
                                            <strong>{od.od_status}</strong>
                                            <br />
                                            Stock for this delivery has been picked
                                            {od.od_status === "Despatched" || od.od_status === "Invoiced" ? `, checked and despatched` : od.od_status === "Awaiting Check" ? ` and is currently waiting to be checked` : `, checked and is currently waiting to be despatched`}
                                        </Alert>
                                    </Box>
                                )) || (
                                    <Box width="100%">
                                        <Alert severity="warning">
                                            <strong>{od.od_status}</strong>
                                            <br />
                                            {(od.od_status === "Awaiting Allocation" && `This delivery is currently unallocated, please review the allocation tab to progress this delivery`) || `This delivery has been allocated and is ${od.od_status?.toLowerCase?.()}`}
                                        </Alert>
                                    </Box>
                                ))) ||
                            (od.od_status === "Awaiting Supplier" && (
                                <Box width="100%">
                                    <Alert severity="error">
                                        <strong>Awaiting Supplier</strong>
                                        <br />
                                        This delivery is waiting for the supplier
                                    </Alert>
                                </Box>
                            )) || (
                                <Box width="100%">
                                    <Alert severity="error">
                                        <strong>Awaiting Allocation</strong>
                                        <br />
                                        This delivery is currently unallocated, please review the allocation tab to progress this delivery
                                    </Alert>
                                </Box>
                            )}
                    </Grid>
                )}
                {!_.isEmpty(od.locks) && (
                    <Grid item xs={12}>
                        <ExpansionPanel
                            style={{ background: "#fff" }}
                            defaultExpanded={od.lock?.ol_released === 0}
                        >
                            <ExpansionPanelSummary
                                style={{ opacity: 1 }}
                                expandIcon={
                                    <FAIcon
                                        type="light"
                                        icon="chevron-down"
                                        size="small"
                                        noMargin
                                        button
                                    />
                                }
                            >
                                <Box pt={1} pb={1}>
                                    <Grid container alignItems='center' spacing={1}>
                                        <Grid item>
                                            <FAIcon
                                                type="light"
                                                icon={od.lock?.ol_released === 1 ? 'info-circle' : 'exclamation-circle'}
                                                className={od.lock?.ol_released === 1 ? 'textInfo' : 'textError'}
                                                size={20}
                                                button
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                            >
                                                Delivery Releases ({_.size(od.locks)})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <DataTable
                                    config={{
                                        key: "ol_id",
                                        alternatingRowColours: true,
                                        inline: true,
                                        noRowHover: true,
                                        rowProps: rowData => ({
                                            className: rowData.ol_released === 0 ? `textError` : undefined
                                        })
                                    }}
                                    columns={[
                                        {
                                        heading: "Released",
                                        field: rowData => rowData.ol_released,
                                        fieldFormat: 'boolean',
                                        alignment: 'center',
                                        sizeToContent: true
                                        },
                                        {
                                        heading: "Date / Time",
                                        field: rowData => rowData.ol_created_datetime,
                                        fieldFormat: 'datetime',
                                        },
                                        {
                                        heading: "Reason",
                                        field: rowData => rowData.ol_reason,
                                        },
                                        {
                                        heading: "Gross Total",
                                        field: rowData => rowData.ol_gross_total,
                                        fieldFormat: 'currency',
                                        },
                                        {
                                        heading: "Credit Limit",
                                        field: rowData => rowData.ol_credit_limit,
                                        fieldFormat: 'currency',
                                        },
                                        {
                                        heading: "Credit Balance",
                                        field: rowData => rowData.ol_credit_balance,
                                        fieldFormat: 'currency',
                                        },
                                        {
                                        heading: "Credit Remaining",
                                        field: rowData => rowData.ol_credit_available,
                                        fieldFormat: 'currency',
                                        },
                                        {
                                        heading: "Locked By",
                                        field: rowData => rowData.ol_lock_staff_id > 0 ? <StaffChip staff={staff?.[rowData.ol_lock_staff_id]} /> : '-',
                                        fieldFormat: 'staff',
                                        },
                                        {
                                        heading: "Released By",
                                        field: rowData => rowData.ol_release_staff_id > 0 ? <StaffChip staff={staff?.[rowData.ol_release_staff_id]} /> : '-',
                                        fieldFormat: 'staff',
                                        },
                                        {
                                            actions: rowData => ([
                                                {
                                                    icon: 'times',
                                                    name: 'Hold With Supplier',
                                                    disabled: !access.releaseDelivery || rowData.ol_released === 1 || rowData.ol_dd_stop !== null,
                                                    hideIfDisabled: true,
                                                    onClick: () => dispatch(deployConfirmation(`Are you sure you want to hold this delivery with the supplier?`, holdOrderDeliveryWithSupplier))
                                                },
                                                {
                                                    icon: 'check',
                                                    name: 'Release Delivery',
                                                    disabled: (!access.releaseDelivery && rowData.ol_reason === 'Manual') || (!access.releaseDeliveryAccounts && rowData.ol_reason !== 'Manual')  || rowData.ol_released === 1,
                                                    onClick: () => dispatch(deployConfirmation(`Are you sure you want to release this delivery?`, releaseOrderDelivery))
                                                }
                                            ])
                                        }
                                    ]}
                                    rows={od.locks}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                )}
                {!od?.consolidation?.length && !basic ? (
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {od.purchase_order && (
                                <>
                                    {od?.od_sys_hold !== 1 && od?.od_on_hold === 1 && od?.od_on_hold_datetime !== "0000-00-00 00:00:00" && (od?.pod?.pod_issue === 1 || (od?.od_type === "PO" && od?.pod?.pod_ship_aa_only === 1)) && (
                                        <Grid item xs={12}>
                                            <Box mt={-3}>
                                                <Alert severity="error">
                                                    <strong>Sales Order Delivery Delayed</strong>
                                                    <br />
                                                    Purchase order delivery {od?.pod?.pod_reference}/{od?.pod?.pod_idx} is on hold due a goods received discrepancy, this order will be despatched once replacement goods arrive
                                                    {/* {pod.od && (
                                                            <InsightChip 
                                                                disabled={pod.od?.od_status === 'Cancelled'}
                                                                icon="external-link"
                                                                label={`#${pod.od?.od_reference}`}
                                                                onClick={() => history.push(`/sales/${pod.od?.od_order_id}:${pod.od?.od_id}`)}
                                                                paddingLeft={8}
                                                                marginLeft={8}
                                                            />
                                                        )} */}
                                                    <Box mt={1}>
                                                        <Button onClick={() => dispatch(deployConfirmation(`Are you sure you want to mark this delivery as despatched?`, markOrderDeliveryAsDespatched))}>
                                                            <FAIcon icon="exclamation-triangle" button size={15} />
                                                            Mark as Despatched (Manually Resolved)
                                                        </Button>
                                                    </Box>
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                    {od.purchase_order && (
                                        <>
                                            <Grid item xs={12} lg={4}>
                                                <PaddedPaper style={{ height: "100%" }}>
                                                    <Heading secondary text={od?.purchase_order?.supplier?.supp_company_name} />
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Tile noPaper color={"#000"} icon={"phone"} title="Phone Number" content={od?.purchase_order?.supplier?.supp_phone ?? "-"} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Tile noPaper color={"#000"} icon={"at"} title="E-mail Address" content={od?.purchase_order?.supplier?.supp_email ?? "-"} />
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <PaddedPaper style={{ height: "100%" }}>
                                                    <Heading secondary text={`Purchase Order #${od?.purchase_order?.po_reference}`} />
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Tile
                                                                noPaper
                                                                color={od?.purchase_order?.po_submission_date === "0000-00-00" ? "#f44336" : "#4CAF50"}
                                                                icon={od?.purchase_order?.po_submission_date === "0000-00-00" ? "times" : "check"}
                                                                title="Submitted"
                                                                content={od?.purchase_order?.po_submission_date === "0000-00-00" ? "No" : "Yes"}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <StaffTile minimal noPaper title={"Raised By"} staffId={od?.purchase_order?.po_staff_id ?? null} missingText="N/A" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Tile
                                                                noPaper
                                                                color={od?.purchase_order?.po_confirmation_date === "0000-00-00" ? "#f44336" : "#4CAF50"}
                                                                icon={od?.purchase_order?.po_confirmation_date === "0000-00-00" ? "times" : "check"}
                                                                title="Confirmed"
                                                                content={od?.purchase_order?.po_confirmation_date === "0000-00-00" ? "No" : "Yes"}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Tile noPaper color={"#4CAF50"} icon={"check"} title="Ship Here" content={od?.purchase_order?.po_ship_aa_only === 1 ? "Yes" : "No"} />
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            {(od.pod && (
                                                <Grid item xs={12} lg={4}>
                                                    <PaddedPaper style={{ height: "100%" }}>
                                                        <Heading secondary text={`PO Delivery #${od?.pod?.pod_reference}/${od?.pod?.pod_idx}`} />
                                                        <Grid container spacing={3}>
                                                            {(od?.purchase_order?.po_confirmation_date === "0000-00-00" && (
                                                                <Grid item xs={12}>
                                                                    <Box mb={2} align="center">
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item xs>
                                                                                <FAIcon type="thin" icon="question-circle" size={30} className="mb-1" noMargin />
                                                                                <Typography variant="h6" className="fw-400">
                                                                                    Not Confirmed Yet
                                                                                </Typography>
                                                                                <Typography variant="body1" className="fw-400">
                                                                                    This delivery is subject to supplier confirmation
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            )) || (
                                                                <>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Box mt={-0.75} mb={-2}>
                                                                            <Tile noPaper color={"#000"} icon={"info-circle"} content={od?.pod?.pod_status} />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile
                                                                            noPaper
                                                                            color={od?.pod?.pod_check_date !== "0000-00-00" ? "#f44336" : "#4CAF50"}
                                                                            icon={od?.pod?.pod_check_date !== "0000-00-00" ? "exclamation-triangle" : "check"}
                                                                            title="Check Date"
                                                                            content={od?.pod?.pod_check_date !== "0000-00-00" ? momentFormatDate(od?.pod?.pod_check_date) : "No"}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile
                                                                            noPaper
                                                                            color={od?.pod?.pod_expected_despatch_date === "0000-00-00" ? "#f44336" : "#4CAF50"}
                                                                            icon={od?.pod?.pod_expected_despatch_date === "0000-00-00" ? "exclamation-triangle" : "check"}
                                                                            title={isTablet() ? "Expected" : "Expected Despatch"}
                                                                            content={od?.pod?.pod_expected_despatch_date === "0000-00-00" ? "TBC" : momentFormatDate(od?.pod?.pod_expected_despatch_date)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile
                                                                            noPaper
                                                                            color={od?.pod?.pod_despatch_date === "0000-00-00" ? "#f44336" : "#4CAF50"}
                                                                            icon={od?.pod?.pod_despatch_date === "0000-00-00" ? "exclamation-triangle" : "check"}
                                                                            title="Despatch Date"
                                                                            content={od?.pod?.pod_despatch_date === "0000-00-00" ? "N/A" : momentFormatDate(od?.pod?.pod_despatch_date)}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            )) || <Grid item xs={false} lg={4} />}
                                        </>
                                    )}
                                </>
                            )}
                            {od.od_type === "WH" && !hasParent && (
                                <>
                                    {!inDialog && od?.od_sys_hold !== 1 && od?.od_on_hold === 1 && od?.od_on_hold_lock === 0 && od?.od_on_hold_datetime !== "0000-00-00 00:00:00" && (
                                        <Grid item xs={12}>
                                            <ResolveIssue callback={callback} canCancel={access?.cancelOrder ?? false} canResolve={access?.processProcessing ?? false} od={od} type={od.check?.check_status === "On Hold" ? "Check" : "Pick"} />
                                        </Grid>
                                    )}
                                    {(od.od_manual_despatch && (
                                        <Grid item xs={12}>
                                            <Box width="100%">
                                                <Alert className="content-light-white textDefault" severity="info">
                                                    <strong>Manually Despatched</strong>
                                                    <br />
                                                    This delivery was manually despatched - no pick, check or tracking details are available
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (
                                        <>
                                            <Grid item xs={12} lg={4}>
                                                <PaddedPaper style={{ height: "100%" }}>
                                                    <Grid container spacing={3} alignItems="center">
                                                        <Grid item xs={7}>
                                                            <Heading secondary text={`Warehouse Pick`} noMargin />
                                                        </Grid>
                                                        {(!od?.pick &&
                                                            ((order.order_legacy === 2 && (
                                                                <Grid item xs={12}>
                                                                    <Box mb={2} align="center">
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item xs>
                                                                                <FAIcon type="thin" icon="gift" size={30} className="mb-1" noMargin />
                                                                                <Typography variant="h6" className="fw-400">
                                                                                    Picked By The Elves
                                                                                </Typography>
                                                                                <Typography variant="body1" className="fw-400">
                                                                                    Delivered direct from the North Pole
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            )) ||
                                                                ((od.od_type === "PO" || (od?.purchase_order?.po_ship_here_order === 1 && od?.od_status !== "Awaiting Picking")) && (
                                                                    <Grid item xs={12}>
                                                                        <Box mb={2} align="center">
                                                                            <Grid container spacing={1} alignItems="center">
                                                                                <Grid item xs>
                                                                                    <FAIcon type="thin" icon="check-circle" size={30} className="mb-1" noMargin />
                                                                                    <Typography variant="h6" className="fw-400">
                                                                                        Pick Not Required
                                                                                    </Typography>
                                                                                    <Typography variant="body1" className="fw-400">
                                                                                        This was a Ship Here Purchase Order
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                )) ||
                                                                (!od?.pick && od?.check && (
                                                                    <Grid item xs={12}>
                                                                        <Box mb={2} align="center">
                                                                            <Grid container spacing={1} alignItems="center">
                                                                                <Grid item xs>
                                                                                    <FAIcon type="thin" icon="check-circle" size={30} className="mb-1" noMargin />
                                                                                    <Typography variant="h6" className="fw-400">
                                                                                        Pick Not Required
                                                                                    </Typography>
                                                                                    <Typography variant="body1" className="fw-400">
                                                                                        This was a Ship Here Purchase Order
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                )) ||
                                                                (od.od_status !== "Awaiting Picking" && order.order_legacy === 0 && (
                                                                    <Grid item xs={12}>
                                                                        <Box mb={2} align="center">
                                                                            <Grid container spacing={1} alignItems="center">
                                                                                <Grid item xs>
                                                                                    <FAIcon type="thin" icon="check-circle" size={30} className="mb-1" noMargin />
                                                                                    <Typography variant="h6" className="fw-400">
                                                                                        Pick Not Required
                                                                                    </Typography>
                                                                                    <Typography variant="body1" className="fw-400">
                                                                                        This order has no products to pick
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                )) || (
                                                                    <Grid item xs={12}>
                                                                        <Box mb={2} align="center">
                                                                            <Grid container spacing={1} alignItems="center">
                                                                                <Grid item xs>
                                                                                    <FAIcon type="thin" icon="check-circle" size={30} className="mb-1" noMargin />
                                                                                    <Typography variant="h6" className="fw-400">
                                                                                        {od?.od_legacy === 1 || (od?.od_legacy === 0 && (od?.od_status === "Despatched" || od?.od_status === "Invoiced")) ? "No Data Available" : "Not Picked Yet"}
                                                                                    </Typography>
                                                                                    <Typography variant="body1" className="fw-400">
                                                                                        {od?.od_legacy === 1 || (od?.od_legacy === 0 && (od?.od_status === "Despatched" || od?.od_status === "Invoiced"))
                                                                                            ? "Legacy delivery pick data is not available"
                                                                                            : od?.od_status === "Awaiting Stock"
                                                                                            ? `This delivery is currently awaiting stock`
                                                                                            : `This delivery is queued for picking`}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                ))) ||
                                                            (_.size(od.picks) > 1 && (
                                                                <Grid item xs={12}>
                                                                    <DataTable
                                                                        config={{
                                                                            key: "od_id",
                                                                            alternatingRowColours: true,
                                                                            inline: true,
                                                                            noRowHover: true,
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading: "Pick",
                                                                                field: (rowData) => (
                                                                                    <Box pt={1} pb={1}>
                                                                                        <Typography variant="body2" className="fw-400">
                                                                                            {`Pick ${rowData.rowNumber}`}
                                                                                        </Typography>
                                                                                        <Typography variant="caption" component="div">
                                                                                            {`Picked by ${staff?.[rowData.pick_staff_id]?.name ?? "Unknown"}`}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                ),
                                                                                truncate: true,
                                                                            },
                                                                            {
                                                                                heading: "Completed On",
                                                                                field: (rowData) => rowData.pick_completed_datetime,
                                                                                fieldFormat: "datetime",
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                actions: (rowData) => {
                                                                                    return [{ name: "View Pick", icon: "eye", onClick: () => deployDetailedView("pick", rowData.rowIdx), doubleClick: true }];
                                                                                },
                                                                            },
                                                                        ]}
                                                                        rows={od.picks}
                                                                    />
                                                                </Grid>
                                                            )) || (
                                                                <>
                                                                    <Grid item xs={5} align="right">
                                                                        <Button size="small" variant="text" onClick={() => deployDetailedView("pick")}>
                                                                            <FAIcon icon="eye" size={15} button />
                                                                            {ui?.device?.isTablet ? `Details` : `Detailed View`}
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile noPaper color={!od?.pick ? "#f44336" : "#4CAF50"} icon={!od?.pick ? "times" : "check"} title="Pick Completed" content={!od?.pick ? "No" : "Yes"} />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <StaffTile minimal noPaper title="Picked By" staffId={od?.pick?.pick_staff_id ?? null} missingText="N/A" />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile noPaper color={od?.pick?.pick_successful === 0 ? "#f44336" : "#4CAF50"} icon={od?.pick?.pick_successful === 0 ? "times" : "check"} title="Pick Successful" content={od?.pick?.pick_successful === 0 ? "No" : "Yes"} />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile noPaper color={"#000"} icon={"clock"} title="Pick Time" content={momentDiffForHumans({ a: od?.pick?.pick_started_datetime, b: od?.pick?.pick_completed_datetime, short: false })} />
                                                                    </Grid>
                                                                </>
                                                            )}
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <PaddedPaper style={{ height: "100%" }}>
                                                    <Grid container spacing={3} alignItems="center">
                                                        <Grid item xs={7}>
                                                            <Heading secondary text={`Warehouse Check`} noMargin />
                                                        </Grid>
                                                        {(!od?.check &&
                                                            ((order.order_legacy === 2 && (
                                                                <Grid item xs={12}>
                                                                    <Box mb={2} align="center">
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item xs>
                                                                                <FAIcon type="thin" icon="gift" size={30} className="mb-1" noMargin />
                                                                                <Typography variant="h6" className="fw-400">
                                                                                    Checked By The Elves
                                                                                </Typography>
                                                                                <Typography variant="body1" className="fw-400">
                                                                                    Delivered direct from the North Pole
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            )) || 
                                                            ((od.od_status !== "Awaiting Check" && order.order_legacy === 0 && (
                                                                <Grid item xs={12}>
                                                                    <Box mb={2} align="center">
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item xs>
                                                                                <FAIcon type="thin" icon="check-circle" size={30} className="mb-1" noMargin />
                                                                                <Typography variant="h6" className="fw-400">
                                                                                    Check Not Required
                                                                                </Typography>
                                                                                <Typography variant="body1" className="fw-400">
                                                                                    This order has no products to check
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            )) || (
                                                                // {((!od?.check || (od?.check && (od.od_status === 'Awaiting Despatch' || od.od_status === 'Awaiting Picking'))) && (
                                                                <Grid item xs={12}>
                                                                    <Box mb={2} align="center">
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item xs>
                                                                                <FAIcon type="thin" icon="check-circle" size={30} className="mb-1" noMargin />
                                                                                <Typography variant="h6" className="fw-400">
                                                                                    {od?.od_legacy === 1 || (od?.od_legacy === 0 && (od?.od_status === "Despatched" || od?.od_status === "Invoiced")) ? "No Data Available" : "Not Checked Yet"}
                                                                                </Typography>
                                                                                <Typography variant="body1" className="fw-400">
                                                                                    {od?.od_legacy === 1 || (od?.od_legacy === 0 && (od?.od_status === "Despatched" || od?.od_status === "Invoiced"))
                                                                                        ? "Legacy delivery check data is not available"
                                                                                        : od?.od_status !== "Awaiting Despatch"
                                                                                        ? `This delivery has not been picked yet`
                                                                                        : `This delivery is queued for checking`}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            )))) ||
                                                            (_.size(od.checks) > 1 && (
                                                                <Grid item xs={12}>
                                                                    <DataTable
                                                                        config={{
                                                                            key: "od_id",
                                                                            alternatingRowColours: true,
                                                                            inline: true,
                                                                            noRowHover: true,
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading: "Check",
                                                                                field: (rowData) => (
                                                                                    <Box pt={1} pb={1}>
                                                                                        <Typography variant="body2" className="fw-400">
                                                                                            {`Check ${rowData.rowNumber}`}
                                                                                        </Typography>
                                                                                        <Typography variant="caption" component="div">
                                                                                            {`Picked by ${staff?.[rowData.check_staff_id]?.name ?? "Unknown"}`}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                ),
                                                                                truncate: true,
                                                                            },
                                                                            {
                                                                                heading: "Completed On",
                                                                                field: (rowData) => rowData.check_completed_datetime,
                                                                                fieldFormat: "datetime",
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                actions: (rowData) => {
                                                                                    return [{ name: "View Check", icon: "eye", onClick: () => deployDetailedView("check", rowData.rowIdx), doubleClick: true }];
                                                                                },
                                                                            },
                                                                        ]}
                                                                        rows={od.checks}
                                                                    />
                                                                </Grid>
                                                            )) || (
                                                                <>
                                                                    <Grid item xs={5} align="right">
                                                                        <Button size="small" variant="text" onClick={() => deployDetailedView("check")}>
                                                                            <FAIcon icon="eye" size={15} button />
                                                                            {ui?.device?.isTablet ? `Details` : `Detailed View`}
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile noPaper color={!od?.check ? "#f44336" : "#4CAF50"} icon={!od?.check ? "times" : "check"} title="Check Completed" content={!od?.check ? "No" : "Yes"} />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <StaffTile minimal noPaper title="Checked By" staffId={od?.check?.check_staff_id ?? null} missingText="N/A" />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile
                                                                            noPaper
                                                                            color={od?.check?.check_successful === 0 || !od?.check ? "#f44336" : "#4CAF50"}
                                                                            icon={od?.check?.check_successful === 0 || !od?.check ? "times" : "check"}
                                                                            title="Check Successful"
                                                                            content={od?.check?.check_successful === 0 || !od?.check ? "No" : "Yes"}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tile noPaper color={"#000"} icon={"clock"} title="Check Time" content={momentDiffForHumans({ a: od?.check?.check_started_datetime, b: od?.check?.check_completed_datetime, short: false })} />
                                                                    </Grid>
                                                                </>
                                                            )}
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <PaddedPaper style={{ height: "100%" }}>
                                                    <Grid container spacing={3} alignItems="center">
                                                        <Grid item xs={7}>
                                                            <Heading secondary text={`Warehouse Despatch`} noMargin />
                                                        </Grid>
                                                        {(!od?.despatch &&
                                                            ((od.od_status !== "Awaiting Despatch" && order.order_legacy === 0 && (
                                                                <Grid item xs={12}>
                                                                    <Box mb={2} align="center">
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item xs>
                                                                                <FAIcon type="thin" icon="check-circle" size={30} className="mb-1" noMargin />
                                                                                <Typography variant="h6" className="fw-400">
                                                                                    Despatch Not Required
                                                                                </Typography>
                                                                                <Typography variant="body1" className="fw-400">
                                                                                    This order has no products to despatch
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            )) || (
                                                                <Grid item xs={12}>
                                                                    <Box mb={2} align="center">
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item xs>
                                                                                <FAIcon type="thin" icon="check-circle" size={30} className="mb-1" noMargin />
                                                                                <Typography variant="h6" className="fw-400">
                                                                                    {od?.od_legacy === 1 || (od?.od_legacy === 0 && (od?.od_status === "Despatched" || od?.od_status === "Invoiced")) ? "No Data Available" : !od?.od_check ? `Not Despatched Yet` : `Despatching Now`}
                                                                                </Typography>
                                                                                <Typography variant="body1" className="fw-400">
                                                                                    {od?.od_legacy === 1 || (od?.od_legacy === 0 && (od?.od_status === "Despatched" || od?.od_status === "Invoiced"))
                                                                                        ? "Legacy delivery despatch data is not available"
                                                                                        : od?.od_status !== "Awaiting Despatch"
                                                                                        ? `This delivery has not been picked yet`
                                                                                        : !od?.od_check
                                                                                        ? `This delivery has not been checked yet`
                                                                                        : `This delivery is being despatched now`}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            ))) || (
                                                            <>
                                                                <Grid item xs={5} align="right">
                                                                    <Button size="small" variant="text" onClick={() => deployDetailedView("despatch")}>
                                                                        <FAIcon icon="eye" size={15} button />
                                                                        {ui?.device?.isTablet ? `Details` : `Detailed View`}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Tile noPaper color={!od?.despatch ? "#f44336" : "#4CAF50"} icon={!od?.despatch ? "times" : "check"} title={od?.despatch?.desp_collection === 0 ? "Despatch Completed" : "Ready for Collection"} content={!od?.despatch ? "No" : "Yes"} />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <StaffTile minimal noPaper title={od?.despatch?.desp_collection === 0 ? "Despatched By" : "Prepared By"} staffId={od?.despatch?.desp_staff_id ?? null} missingText="N/A" />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Tile
                                                                        noPaper
                                                                        color={od?.despatch?.desp_collection === 0 ? "#000" : !od?.collection ? "#f44336" : "#4CAF50"}
                                                                        icon={od?.despatch?.desp_collection === 0 ? "calendar-alt" : !od?.collection ? "times" : "check"}
                                                                        title={od?.despatch?.desp_collection === 0 ? "Shipping Date" : "Collected"}
                                                                        content={od?.despatch?.desp_collection === 0 ? moment(od?.despatch?.desp_shipping_date, "YYYY-MM-DD").format("DD/MM/YYYY") : !od?.collection ? "No" : "Yes"}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Tile noPaper color={"#000"} icon={"box-check"} title="Courier" content={od?.despatch?.courier?.courier_name === "Advanced Access" ? "Collection" : od?.despatch?.courier?.courier_name ?? "-"} />
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <PaddedPaper elevation={basic ? 0 : undefined}>
                        <Heading text="Delivery Details" />
                        <Grid container justify="flex-end">
                            <Grid item xs={12} lg={4}>
                                <StackTable
                                    elevation={0}
                                    padding={0}
                                    rows={[
                                        {
                                            heading: "Company Name",
                                            value: order?.customer?.cust_company_name ?? "-",
                                        },
                                        {
                                            heading: "Order Contact",
                                            disabled: !od.od_contact_name,
                                            value: (
                                                <>
                                                    <Typography variant="body2" gutterBottom>
                                                        {od.od_contact_name ?? "Not Set"}
                                                    </Typography>
                                                    {!_.isEmpty(od.customer_contact?.contact_telephone) && (
                                                        <Typography variant="caption" component="div" gutterBottom>
                                                            <FAIcon icon="phone" size={12} />
                                                            {od.customer_contact?.contact_telephone}
                                                        </Typography>
                                                    )}
                                                    {!_.isEmpty(od.customer_contact?.contact_mobile) && (
                                                        <Typography variant="caption" component="div" gutterBottom>
                                                            <FAIcon icon="mobile" size={12} />
                                                            {od.customer_contact?.contact_mobile}
                                                        </Typography>
                                                    )}
                                                    {!_.isEmpty(od.customer_contact?.contact_email) && (
                                                        <Typography variant="caption" component="div">
                                                            <FAIcon icon="envelope" size={12} />
                                                            {od.customer_contact?.contact_email}
                                                        </Typography>
                                                    )}
                                                </>
                                            ),
                                        },
                                        {
                                            heading: "Billing Address",
                                            disabled: customerAddressEmpty,
                                            value: _.map(customerAddress, (line, idx) => {
                                                if (line?.length > 0) {
                                                    return (
                                                        <Typography variant="body2" key={idx} component="div">
                                                            {line}
                                                        </Typography>
                                                    );
                                                }
                                            }),
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <StackTable
                                    elevation={0}
                                    padding={0}
                                    rows={[
                                        {
                                            heading: "Delivery Company",
                                            disabled: !od.od_delivery_company,
                                            value: od.od_delivery_company,
                                        },
                                        {
                                            heading: "Delivery Contact",
                                            disabled: !od.od_delivery_contact_name,
                                            value: (
                                                <>
                                                    <Typography variant="body2" gutterBottom>
                                                        {od.od_delivery_contact_name ?? "Not Set"}
                                                    </Typography>
                                                    {!_.isEmpty(od.delivery_contact?.contact_telephone) && (
                                                        <Typography variant="caption" component="div" gutterBottom>
                                                            <FAIcon icon="phone" size={12} />
                                                            {od.delivery_contact?.contact_telephone}
                                                        </Typography>
                                                    )}
                                                    {!_.isEmpty(od.delivery_contact?.contact_mobile) && (
                                                        <Typography variant="caption" component="div" gutterBottom>
                                                            <FAIcon icon="mobile" size={12} />
                                                            {od.delivery_contact?.contact_mobile}
                                                        </Typography>
                                                    )}
                                                    {!_.isEmpty(od.delivery_contact?.contact_email) && (
                                                        <Typography variant="caption" component="div">
                                                            <FAIcon icon="envelope" size={12} />
                                                            {od.delivery_contact?.contact_email}
                                                        </Typography>
                                                    )}
                                                </>
                                            ),
                                        },
                                        {
                                            heading: "Delivery Address",
                                            disabled: deliveryAddressEmpty,
                                            value: _.map(deliveryAddress, (line, idx) => {
                                                if (line?.length > 0) {
                                                    return (
                                                        <Typography variant="body2" key={idx} component="div">
                                                            {line}
                                                        </Typography>
                                                    );
                                                }
                                            }),
                                        },
                                        {
                                            heading: "Delivery Instructions",
                                            disabled: _.isEmpty(od.od_delivery_address_notes),
                                            value: (
                                                <Typography variant="body2" component="div">
                                                    {od.od_delivery_address_notes}
                                                </Typography>
                                            ),
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <StackTable
                                    noBg
                                    elevation={0}
                                    padding={0}
                                    rows={[
                                        {
                                            heading: "Customer Requested Date",
                                            value: !orderCallOff ? "N/A" : moment(order.order_requested_delivery_date).format("DD/MM/YYYY"),
                                        },
                                        {
                                            heading: `${od.od_status === "Awaiting Picking" ? `Despatching On` : `Estimated Despatch Date`}`,
                                            value:
                                                od.od_schedule_date === "0000-00-00" ? (
                                                    "-"
                                                ) : (
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <span className={od.od_status !== "Invoiced" && od.od_status !== "Despatched" && od.od_status !== "Cancelled" && moment(od.od_schedule_date).isBefore(moment()) ? "fw-400 textError" : undefined}>{orderCallOff ? (moment(od.od_schedule_date).isAfter(moment(order.order_requested_delivery_date)) ? moment(od.od_schedule_date).format("DD/MM/YYYY") : moment(order.order_requested_delivery_date).format("DD/MM/YYYY")) : 'ASAP'}</span>
                                                        </Grid>
                                                        {orderCallOff && access.processOrder && (od.od_status === "Awaiting Picking" || od.od_status === "Awaiting Allocation" || od.od_status === "Awaiting Stock") && (
                                                            <>
                                                                <Grid item>
                                                                    <InsightChip icon="calendar-day" label={`Change Date`} color="#ef3340" textDefault onClick={handleChangeDeliveryDate} paddingLeft={8} />
                                                                </Grid>
                                                                {od.od_status === "Awaiting Picking" && moment(order.order_requested_despatch_date).isAfter(moment()) && (
                                                                    <Grid item>
                                                                        <InsightChip
                                                                            icon="check"
                                                                            label={`Despatch Now`}
                                                                            color="#ef3340"
                                                                            textDefault
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    deployConfirmation(
                                                                                        <>
                                                                                            <Typography variant="body2" className="fw-400">
                                                                                                Are you sure you want to pick and despatch this delivery today?
                                                                                            </Typography>
                                                                                            <Typography variant="body2">The forward-date may have been requested by the customer</Typography>
                                                                                        </>,
                                                                                        () => despatchToday()
                                                                                    )
                                                                                )
                                                                            }
                                                                            paddingLeft={8}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                ),
                                        },
                                        {
                                            heading: "Delivery Method",
                                            disabled: !od.carriage,
                                            value: `${od.carriage?.courier?.courier_name} ${od.carriage?.courier_conf_service} (£${od.od_total_carriage})`,
                                        },
                                        {
                                            heading: "Order E-mail Address",
                                            value: order?.order_email ?? "-",
                                        },
                                        {
                                            heading: "Order Phone",
                                            value: order?.order_phone ?? "-",
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} justify="flex-end">
                                    <Grid item xs={12}>
                                        <Products
                                            access={access}
                                            basic
                                            classes={classes}
                                            inline={true}
                                            rows={od.products}
                                            discountTotal={od.od_total_discount}
                                            subTotal={od.od_total_sub}
                                            netTotal={od.od_total_net}
                                            carriageTotal={od.od_total_carriage}
                                            vatTotal={od.od_total_vat}
                                            grossTotal={od.od_total_gross}
                                            od={od}
                                            readOnly
                                            status={od.od_status}
                                            hasDeliveries={!_.isEmpty(order.deliveries)}
                                            showAllocationStatus={basic ? false : true}
                                            showDespatchedAndInvoiced={basic ? false : true}
                                        />
                                    </Grid>
                                    {!_.isEmpty(od.od_notes) && (
                                        <Grid item xs={12}>
                                            <Box pt={2} pl={2} pr={2}>
                                                {od.od_notes.split("\n").map((item, key) => (
                                                    <Typography variant="body2" key={key} align="center" className="fw-400" gutterBottom>
                                                        {item}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} lg={4}>
                                        <Totals classes={classes} subTotal={od.od_total_sub} discountTotal={od.od_total_discount} carriageTotal={od.od_total_carriage} netTotal={od.od_total_net} vatTotal={od.od_total_vat} grossTotal={od.od_total_gross} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
            {/* {children?.length ? (
                _.map(children, (child, idx) => (
                    <DeliverySummary access={access} callback={callback} od={child} order={order} idx={(idx + 1)} />
                ))
            ) : null} */}
        </>
    );
};

export default DeliverySummary;
