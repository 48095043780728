import React from 'react';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionChip from 'Components/Common/Chips/ActionChip';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import ActivityLog from 'Components/Common/Activity/ActivityLog';
import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import CancelPurchaseOrder from 'Components/Purchases/Actions/CancelPurchaseOrder';
import DataTable from 'Components/Common/DataTables/DataTable';
import DocumentForm from 'Components/Common/Forms/DocumentForm';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import NotificationDialog from 'Components/Common/Dialogs/NotificationDialog';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import ModifyPurchaseOrderPricing from 'Components/Purchases/Misc/ModifyPurchaseOrderPricing';
import PODeliverySelectionDialog from 'Components/Purchases/Misc/PODeliverySelectionDialog';
import PodInvoiceDetails from 'Components/Purchases/Misc/DetailedView/PodInvoiceDetails';
import PurchaseOrderSummary from 'Components/Purchases/Misc/PurchaseOrderSummary';
import PurchaseOrderDeliverySummary from 'Components/Purchases/Misc/PurchaseOrderDeliverySummary';
import PurchaseOrderMultiInvoice from 'Components/Purchases/Actions/PurchaseOrderMultiInvoice';
import StaffChip from 'Components/Common/Chips/StaffChip';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import StatusChip from 'Components/Common/Chips/StatusChip';
import TaskForm from 'Components/Common/Activity/TaskForm';
import Tile from 'Components/Common/Tiles/Tile';
import ViewActions from 'Components/Common/Activity/ViewActions';
import ViewCallLogs from 'Components/Common/Activity/ViewCallLogs';
import ViewDocuments from 'Components/Common/Activity/ViewDocuments';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import ViewPage from 'Components/Common/Styles/ViewPage';
import ViewProduct from 'Components/Products/ViewProduct/ViewProduct';
import WildixContext from "Context/WildixContext";
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { currencyFormat, isNumeric, TabPanel } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';
import { isTablet } from 'Functions/MiscFunctions';
import { Hidden } from '@material-ui/core';
import { isCrushedIce } from 'Functions/AuthFunctions';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getLegacyPurchaseOrderPDF = id => {
    API.get('/purchaseOrders/' + id + '/legacy/pdf')
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const initialState = {
    access: {
        submitPo: false,
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        updatePo: false,
        processPo: false,
        reOrder: false,
        viewSupplier: false,
        cancelOrder: false,
        cancelDespatch: false,
        shippedDirect: false
    },
    initialAccess: {
        submitPo: false,
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        updatePo: false,
        processPo: false,
        reOrder: false,
        viewSupplier: false,
        cancelOrder: false,
        cancelDespatch: false,
        shippedDirect: false
    },
    currentTab: 0,
    po: {},
    poId: 0,
    isLoading: true,
    key: uuidv4(),
    pdf: {
        numPages: '',
        pageNumber: 1,
        scale: isTablet() ? 0.75 : 1.5
    },
    revision: false,
    userIsCrushedIce: isCrushedIce(),
    viewing: {
        list: [],
        process: false,
        update: false,
        received: false
    },
}

class ViewPurchaseOrder extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props);
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.poll = false;
    }

    componentDidMount = () => {
        API.multiAccess([
            'view-po:submit',
            'view-po:add-call',
            'view-po:add-note',     
            'view-po:add-document',     
            'view-po:modify-document',     
            'view-po:delete-document',
            'update-po',
            'process-po',
            'replicate-po',
            'view-supplier',
            'view-po:cancel',
            'pod-cancel-despatch',
            'pod-shp-dir'
        ])
        .then(([
            submitPo, 
            addCallLog, 
            addNote, 
            uploadDoc, 
            modifyDoc, 
            deleteDoc, 
            updatePo,
            processPo,
            reOrder,
            viewSupplier,
            cancelOrder,
            cancelDespatch,
            shippedDirect
        ]) =>  {
            const poId = this.props.id ? this.props.id : this.props.match.params.id;
            this.setState({ 
                access: {
                    submitPo,
                    addCallLog,
                    addNote,
                    uploadDoc,
                    modifyDoc,
                    deleteDoc,
                    updatePo,
                    processPo,
                    reOrder,
                    viewSupplier,
                    cancelOrder,
                    cancelDespatch,
                    shippedDirect
                },
                initialAccess: {
                    submitPo,
                    addCallLog,
                    addNote,
                    uploadDoc,
                    modifyDoc,
                    deleteDoc,
                    updatePo,
                    processPo,
                    reOrder,
                    viewSupplier,
                    cancelOrder,
                    cancelDespatch,
                    shippedDirect
                },
                poId
            }, () => {
                this.poll = setInterval(this.handlePoll, 5000);
                this.loadComponentData(true)
            });
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match?.params?.id !== this.props.match?.params?.id) {
            this.setState({
                currentTab: 0,
                poId: this.props.match.params.id
            }, () => {            
                this.setState({
                    isLoading: true
                }, () => {
                    this.loadComponentData(true);
                })
            });
        }
    }

    componentWillUnmount = () => {
        if(this.poll) {
            clearInterval(this.poll);
        }
    }

    handlePoll = () => {

        if(!document.hidden) {

            API.post(`/staff/my/view`, 
                { 
                    po: this.state.poId,
                    type: 'View'
                }, 
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true
                    }
                }
            )
            .then(res => {
                const { viewing } = this.state;
                if(res?.data) {
                    const list = res.data;
                    if(JSON.stringify(list) !==  JSON.stringify(viewing.list)) {
                        const process = _.some(list, el => el.p === 1);
                        const update = _.some(list, el => el.u === 1);
                        const received = _.some(list, el => el.re === 1);
                        this.setState({
                            viewing: {
                                list,
                                update,
                                process,
                                received
                            }
                        }, () => {
                            if(viewing.process && !process) {
                                const view = _.find(viewing.list, el => el.p === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished processing this purchase order`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.update && !update) {
                                const view = _.find(viewing.list, el => el.u === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished updating this purchase order`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.received && !received) {
                                const view = _.find(viewing.list, el => el.re === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished booking in a delivery`);
                                }
                                this.loadComponentData();
                            }
                        })
                    }
                }
            })

        }
    }

    loadComponentData = (initial = false) => { 

        let fromNewPoPage   = this.props.location?.state?.new ?? false,
            fromIfmPoPage   = this.props.location?.state?.ifm ?? false,
            params          = {},
            poId            = this.state.poId,
            currentTab      = initial ? 0 : this.state.currentTab,
            key             =   uuidv4();

        if(initial) {

            if(isNaN(poId) && poId.includes(":")) {
                poId        = this.state.poId.split(":");
                currentTab  = parseInt(poId[1]);
                poId        = parseInt(poId[0]);
            } else {
                currentTab  = 0;
            }

            if(fromNewPoPage) {
                this.props.history.replace({state: { new: false }})
                params = {
                    fromNewPoPage: true
                }
            }

            if(fromIfmPoPage) {
                this.props.history.replace({state: { ifm: false }})
                params = {
                    fromNewPoPage: true
                }
            }

        }
       
        API.get(`/purchaseOrders/${poId}`, { params })
        .then(result =>  {

            if(result?.data && !result?.data?.errors) {

                let   { initialAccess }   =   this.state,
                        isLoading           =   false,
                        po                  =   result.data,
                        revision            =   result?.data?.po_latest === 1 ? false : true;
                    
                if(initial && currentTab > 0) {
                    currentTab = _.findIndex(po.deliveries, el => el.pod_id === currentTab);
                    if(currentTab !== -1) {
                        currentTab = (currentTab + 10)
                    }
                }

                po = {
                    ...po,
                    rma: _.some(po.deliveries, el => el.pod_rma),
                }

                this.setState({
                    access: {
                        ...this.state.access,
                        submitPo: revision ? false : initialAccess.submitPo,
                        addCallLog: revision ? false : initialAccess.addCallLog,
                        addNote: revision ? false : initialAccess.addNote,
                        uploadDoc: revision ? false : initialAccess.uploadDoc,
                        modifyDoc: revision ? false : initialAccess.modifyDoc,
                        deleteDoc: revision ? false : initialAccess.deleteDoc,
                        updatePo: revision ? false : initialAccess.updatePo,
                        cancelOrder: revision ? false : initialAccess.cancelOrder,
                        processPo: revision ? false : initialAccess.processPo
                    },
                    currentTab,
                    isLoading,
                    key,
                    revision,
                    po,
                    poId
                }, () => {
                    
                    if(result?.data?.accessToSubmit && result?.data?.po_status === 'Awaiting Submission') {
                        const success = () => this.props.history.push(`/purchase-orders/process/${result.data.po_id}`)
                        const status = result.data.po_status.toLowerCase();
                        this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">This purchase order is {status}</Typography><Typography variant="body2">Would you like to process it now?</Typography></>, success)
                    } else if(result?.data?.accessToSubmit) {
                        const success = () => this.handleDeploySubmission();
                        this.props.deployConfirmation(<><Box align='center' pb={1.5}><FAIcon icon="exclamation-circle" className="textError" size={80} noMargin /></Box><Typography variant="h6" className="fw-400" align='center' gutterBottom>Purchase Order Modified</Typography><Typography variant="body2" className="fw-400" align='center'>Do you want to e-mail the revised purchase order to the supplier?</Typography></>, success)
                    }
                    
                    if(this.props.pageTitle)
                        this.props.pageTitle([1, "Purchase Orders", result?.data?.supplier?.supp_company_name, `Purchase Order #${result.data.po_reference}`])
                
                    if(currentTab > 0)
                        window.history.replaceState(null, "RED | Advanced Access", `/purchase-orders/${poId}`)

                })
                
            } else {
                if(this.props.history) {
                    this.props.history.push('/purchase-orders');
                }
            }

        })
    }

    handleCrushedIceAction = (action, podId) => {

        const { 
            poId
        } = this.state;

        API.put(`/crushedIce/purchaseOrders/${poId}/deliveries/${podId}/${action}`)
        .then(res => {

            if (res?.data?.success) {

                switch(action) {

                    case 'cancelDespatch':
                        this.handleCrushedIceActionResult("success", "This delivery has been successfully moved back to Awaiting Despatch");
                    break;

                    case 'completeDelivery':
                        this.handleCrushedIceActionResult("success", "This delivery has been successfully moved to Invoiced");
                    break;

                    case 'shippedDirect':
                        this.handleCrushedIceActionResult("success", "This delivery has been successfully processed as shipped direct");
                    break;

                    case 'skipGoodsReceived':
                        this.handleCrushedIceActionResult("success", "This delivery has been successfully marked as despatched and delivered");
                    break;
                    
                    default:
                        this.handleCrushedIceActionResult("error", "Error: invalid action type");
                    break;

                }

            } else {
                this.handleCrushedIceActionResult("error", "Something went wrong, please try again...");
            }

        });

    }
    
    handleCrushedIceActionResult = (snackBarType, snackBarMsg) => {
        
        const { 
            closeDialog, 
            deploySnackBar 
        } = this.props;

        this.loadComponentData();

        deploySnackBar(snackBarType, snackBarMsg);

        closeDialog();

    }

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.poId}
            relation="po"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
            standardOnly
            withFao
            />, true, "", "standard","sm", false, true);

    handleDeployAddCall = () => this.props.deployDialog(
        <AddCallLogForm
            id={this.state.poId}
            relation="po"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","xs", false, true);

    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            id={this.state.poId}
            relation="po"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","sm", false, true);

    handleDeployCrushedIceDialog = (action) => this.props.deployDialog(
        <PODeliverySelectionDialog
            action={action}
            handleSubmit={this.handleCrushedIceAction}
            po={this.state.po}
        />, false, "Purchase Order Management", "standard","xs", false, true);
    
    handleDeployModifyPurchaseOrderPricing = () => this.props.deployDialog(
        <ModifyPurchaseOrderPricing
            callback={() => {
                this.loadComponentData();
                this.props.closeDialog();
            }}
            id={this.state.poId}
        />, false, `Purchase Order #${this.state.po.po_reference} - Buy Prices`, "standard","lg", false, true);
    
    handleProductInfoOpen = productId => this.props.deployDialog(
        <ViewProduct id={productId} inDialog />, 
        false, 
        "Product Information", 
        "standard", 
        "xl",
        true
    )

    handleDeployInvoice = (invoice, pod, idx) => this.props.deployDialog(
        <PodInvoiceDetails 
            closeDialog={this.props.closeDialog}
            invoice={invoice}
            pod={pod}
        />
        , 
        true, 
        '', 
        'standard', 
        'md',
        false,
        true
    )

    handleDeployCancelOrder = () => {

        this.loadComponentData();
        
        const {
            closeDialog,
            deployDialog,
            deployConfirmation,
            deploySnackBar
        } = this.props;

        const { 
            po 
        } = this.state;

        if(po.po_type === 'Sales Order') {
            deployDialog(
                <NotificationDialog
                    text={`This purchase order was generated to fulfil the above sales order, please cancel the appropriate sales order deliveries and this purchase order will be automatically cancelled.`}
                    onClick={closeDialog}
                    btnText='Close'
                    variant="error"
                />,
                false,
                `Sales Order #${po?.deliveries?.[0]?.od?.od_reference} Fulfilment`,
                "error",
                "sm",
                false,
                true
            )
        } else {
            deployDialog(
                <CancelPurchaseOrder
                    id={this.state.poId}
                    po={this.state.po}
                    callback={this.loadComponentData}
                    closeDialog={closeDialog}
                    deployConfirmation={deployConfirmation}
                    deploySnackBar={deploySnackBar}
                />, true, "", "standard", _.isEmpty(po.deliveries) ? 'sm' : 'lg', false, true);
        }

    }

    handleDeployDetachFromSalesOrder = () => {

        const { 
            po 
        } = this.state;

        const {
            deployConfirmation,
        } = this.props;

        deployConfirmation(
            <Box textAlign='center'>
                <Box p={3} textAlign='center'>
                    <FAIcon icon="exclamation-circle" type="thin" className="textError" size={60} noMargin />
                </Box>
                <Typography variant="h4" className="fw-400 textError" paragraph>
                    Detach from Sales Order #{po?.so?.order_reference}
                </Typography>
                <Typography variant="body2" className="fw-400" paragraph>
                    This will convert this purchase order into a stock order.
                </Typography>
                <Typography variant="body2"className="fw-400" paragraph>
                    This is useful if you are keeping parts of the PO but cancelling it from the SO; if you want additional control over the stock and deliveries; or for resolving issues with ship here deliveries.<br /><br/>
                    If the sales order is modified or fulfilment is changed, then it will show deliveries from this PO as being "Fulfilled by Advanced Access"; and any changes to the SO will not reflect in this PO automatically.
                </Typography>
                <Typography variant="body2"className="fw-400" paragraph>
                    No changes to allocation will be made.
                </Typography>
            </Box>,
            this.handleDetachFromSalesOrder,
            undefined,
            "Detach", 
            "Cancel"
        )
    }

    handleDetachFromSalesOrder = () => {
        this.setState({isLoading: true}, () => {
            API.put(`/purchaseOrders/${this.state.po.po_id}/detach`)
            .then(res => {
                if(res?.data) {
                    this.loadComponentData();
                    if(res?.data?.errors) {
                        this.props.deploySnackBar("error", "Something went wrong, please try again");
                    } else {
                        this.props.deploySnackBar("success", "You have successfully detached this purchase order");
                    }
                }
            });
        });
    }

    handleDeployReInstatePurchaseOrder = () => {

        const { 
            po 
        } = this.state;

        const {
            deployConfirmation,
        } = this.props;

        deployConfirmation(
            <Box textAlign='center'>
                <Box p={3} textAlign='center'>
                    <FAIcon icon="exclamation-circle" type="thin" className="textError" size={60} noMargin />
                </Box>
                <Typography variant="h4" className="fw-400 textError" paragraph>
                    Detach from Sales Order #{po?.so?.order_reference}
                </Typography>
                <Typography variant="body2" className="fw-400" paragraph>
                    This will convert this purchase order into a stock order.
                </Typography>
                <Typography variant="body2"className="fw-400" paragraph>
                    This is useful if you are keeping parts of the PO but cancelling it from the SO; if you want additional control over the stock and deliveries; or for resolving issues with ship here deliveries.<br /><br/>
                    If the sales order is modified or fulfilment is changed, then it will show deliveries from this PO as being "Fulfilled by Advanced Access"; and any changes to the SO will not reflect in this PO automatically.
                </Typography>
                <Typography variant="body2"className="fw-400" paragraph>
                    No changes to allocation will be made.
                </Typography>
            </Box>,
            this.handleDetachFromSalesOrder,
            undefined,
            "Detach", 
            "Cancel"
        )
    }

    handleReInstatePurchaseOrder = () => {
        this.setState({isLoading: true}, () => {
            API.put(`/purchaseOrders/${this.state.po.po_id}/detach`)
            .then(res => {
                if(res?.data) {
                    this.loadComponentData();
                    if(res?.data?.errors) {
                        this.props.deploySnackBar("error", "Something went wrong, please try again");
                    } else {
                        this.props.deploySnackBar("success", "You have successfully detached this purchase order");
                    }
                }
            });
        });
    }

    handleDeployFileUpload = () => this.props.deployDialog(
        <DocumentForm 
            id={this.state.poId} 
            type="po" 
            category="Purchase Orders"
            callback={this.loadComponentData} 
            cancel={this.props.closeDialog} 
        />, false, "File Upload", "standard","xs", false, true);


    handleDeployInvoicePurchaseOrder = () => this.props.deployDialog(
        <PurchaseOrderMultiInvoice
            content={`Despatched On ${moment(this.state.po.po_despatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`}
            closeDialog={this.props.closeDialog}
            deployDialog={this.props.deployDialog}
            deployConfirmation={this.props.deployConfirmation}
            handleSubmit={this.handleSubmitPurchaseOrderInvoice}
            id={this.state.po.po_id}
            po={this.state.po}
            reference={`Purchase Order #${this.state.po.po_reference}`}
        />, false, "", "standard", "xs", false, true);
    
    handleSubmitPurchaseOrderInvoice = (id, formData) => {
        this.setState({isLoading: true}, () => {
            API.post(`/purchaseOrders/${id}/invoice`, formData)
            .then(res => {
                if(res?.data?.errors) {
                    this.props.deploySnackBar("error", "Something went wrong, please try again");
                } else {
                    this.props.deploySnackBar("success", "You have successfully invoiced this purchase order");
                    this.props.closeDialog();
                    this.loadComponentData();
                }
            });
        });
    }

    handleDeploySubmission = (manual = false) => {
        let defaultFormData = {
            emailSubject: `${!manual ? `REVISED - ` : ``}Purchase Order #${this.state.po.po_reference}`,
        }
        if(!_.isEmpty(this.state.po?.supplier?.supp_email)) {
            defaultFormData = {
                ...defaultFormData,
                emailTo: {
                    label: this.state.po.supplier.supp_email,
                    value: this.state.po.supplier.supp_email
                }
            }
        }
        if(!_.isEmpty(this.state.po?.supplier?.po_emails)) {
            let emailCc = [];
            this.state.po.supplier.po_emails.forEach((email) => {
                emailCc.push({
                    label: email.spoea_email_address,
                    value: email.spoea_email_address
                })
            });
            defaultFormData = {
                ...defaultFormData,
                emailCc
            }
        }
        this.props.deployDialog(
            <EmailDialog
                id={this.state.poId}
                type='po'
                action='resubmission'
                defaultFormData={defaultFormData}
                success={`The purchase order was re-${manual ? `sent` : `submitted`} to the supplier successfully`}
                confirmation={`Are you sure you want to re-${manual ? `send` : `submit`} this purchase order?`}
                send={manual ? `Send` : `Submit`}
            />, false, "E-mail Purchase Order", "standard","md", false, true
        );
    }

    handleDeploySendEmail = () => {
        let defaultFormData = {
            emailSubject: `Advanced Access | Purchase Order #${this.state.po.po_reference}`,
        }
        if(!_.isEmpty(this.state.po?.supplier?.supp_email)) {
            defaultFormData = {
                ...defaultFormData,
                emailTo: {
                    label: this.state.po.supplier.supp_email,
                    value: this.state.po.supplier.supp_email
                }
            }
        }
        this.props.deployDialog(
            <EmailDialog
                callback={this.loadComponentData}
                id={this.state.poId}
                type='po'
                success='The purchase order has been successfully sent'
                defaultFormData={defaultFormData}
            />, false, "E-mail Purchase Order", "standard","md", false, true
        );
    }

    handleDeployViewSupplier = () => this.props.history.push(`/suppliers/${this.state.po?.supplier?.supp_id}`);
    
    handleTabChange = currentTab => this.setState({
        currentTab
    }, () => {
        if(this.mainContentArea && this.mainContentArea.current) 
            this.mainContentArea.current.scrollTop = 0;
    })
    
    getSalesAdminTasks = (podData = null) => {

        let { po }  =   this.props,
            actions =   [];

        _.each(podData ? [podData] : po.deliveries, (pod, idx) => {
            if(pod.pod_status === "Awaiting Despatch") {
                if(moment(pod.pod_check_date, "YYYY-MM-DD").isBefore(moment())) {
                    actions.push(
                        {
                            id: pod.pod_id,
                            idx: (idx + 1),
                            ref: `${pod.pod_reference}/${pod.pod_idx}`,
                            heading: 'Check Date',
                            content: `This purchase order delivery requires a review as the check date ${moment(pod.pod_check_date, "YYYY-MM-DD").isSame(moment().format("YYYY-MM-DD")) ? `is set for today` : `was set for ${moment(pod.pod_check_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`}`,
                            type: 'warning',
                        }
                    )
                }
            } else if(pod.pod_status === "Despatched") {
                actions.push(
                    {
                        id: pod.pod_id,
                        idx: (idx + 1),
                        ref: `${pod.pod_reference}/${pod.pod_idx}`,
                        heading: 'Ready to Invoice',
                        content: 'This purchase order delivery has been despatched and is now ready for invoicing',
                        type: 'info',
                    }
                )
            }
        })

        if(podData && _.isEmpty(actions)) {
            if(podData.pod_status === "Awaiting Despatch") {
                if(podData.pod_check_date === "0000-00-00") {
                    actions.push(
                        {
                            id: podData.pod_id,
                            ref: `${podData.pod_reference}/${podData.pod_idx}`,
                            heading: 'Despatch Confirmed',
                            content: `This delivery will be automatically marked as despatched on ${moment(podData.pod_expected_despatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`,
                            type: 'success',
                        }
                    )
                } else {
                    actions.push(
                        {
                            id: podData.pod_id,
                            ref: `${podData.pod_reference}/${podData.pod_idx}`,
                            heading: 'Check Date Set',
                            content: `This despatch status for this delivery will be checked on ${moment(podData.pod_check_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`,
                            type: 'warning',
                        }
                    )
                }
            } else if(podData.pod_status === "Invoiced") {
                actions.push(
                    {
                        id: podData.pod_id,
                        ref: `${podData.pod_reference}/${podData.pod_idx}`,
                        heading: 'Invoiced',
                        content: 'This purchase order delivery has been invoiced and completed',
                        type: 'success',
                    }
                )
            }
        }

        if(!_.isEmpty(actions)) {
            return _.map(actions, (action, idx) => (
                <Box pt={3} pb={podData ? 1 : undefined} width="100%">
                    <Alert severity={action.type} onClick={() => this.props.handleTabChange(action.idx, action.id)}>
                        <strong>{!podData && `Delivery #${action.ref} - `}{action.heading}</strong><br />
                        {action.content}
                    </Alert>
                </Box>
            ));
        } else {
            return <Box pb={podData ? 1 : undefined} />
        }
    }
    
    getProcessName = () => {
        const { po } = this.state;
        let text = null;
        switch(po.po_status) {
            case "Awaiting Submission":
                text = 'Submit Purchase Order'
            break;
            case "Awaiting Confirmation":
                text = 'Confirm Purchase Order'
            break;
            case "Awaiting Despatch":
                text = 'Manage Check / Despatch Date'
            break;
            case "Despatched":
            case "Invoiced":
                text = 'Invoice Delivery'
            break;
            default:
                text = 'Process PO'
            break;
        }
        return text;
    }

    handlePdfLoad = ({numPages}) => {
        this.setState({
            pdf: {
                ...this.state.pdf,
                numPages
            }
        })
    }

    handlePdfPagination = offset => {
        this.setState({
            pdf: {
                ...this.state.pdf,
                pageNumber: ((this.state.pdf?.pageNumber ?? 1) + offset)
            }
        })
    }

    handlePdfScale = zoom => {
        let scale = this.state.pdf?.scale ?? 1;
        if(isNumeric(zoom)) {
            scale = zoom;
        } else {
            if(zoom) {
                scale = (parseFloat(scale) + parseFloat(0.25)).toFixed(2)
            } else {
                scale = (parseFloat(scale) - parseFloat(0.25)).toFixed(2)
            }
        }
        this.setState({
            pdf: {
                ...this.state.pdf,
                scale
            }
        })
    }

    handleCiReConfirmPO = () => {
        API.put(`/crushedIce/purchaseOrders/${this.state.poId}/reConfirmPurchaseOrder`)
        .then(res => {
            if (res?.data?.success) {
                this.props.deploySnackBar("success", "The purchase order has been moved back to Awaiting Confirmation");
                this.props.history.push(`/purchase-orders/process/${this.state.poId}`);
            }
        });
    }
    
    handleCiRegeneratePdfs = () => {
        API.put(`/crushedIce/purchaseOrders/${this.state.poId}/regeneratePdfs`)
        .then(res => {
            if (res?.data?.success) {
                this.props.deploySnackBar("success", "The purchase order PDFs have been re-generated");
                this.loadComponentData();
            }
        });
    }
    
    render = () => {        
        const { classes, history, staff, ui } = this.props;
        const { access, currentTab, isLoading, key, pdf, po, revision, userIsCrushedIce, viewing } = this.state;
        const canDial = this.context?.dial === null;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        const tabs = [
            {
                label: `Overview`,
                delivery: null,
                index: 0
            },
            {
                label: `Order Summary`,
                delivery: null,
                index: 1
            },
            {
                label: `PDF Document`,
                delivery: null,
                index: 2,
                disabled: po.po_legacy === 1,
            },
            {
                divider: true
            }
        ];

        if(!_.isEmpty(po.deliveries)) {
            tabs.push(
                {
                    label: `All Deliveries`,
                    delivery: null,
                    index: 8,
                    disabled: !po.deliveries
                }
            )
            _.each(po.deliveries, (el, idx) => {
                tabs.push(
                    {
                        label: `Delivery #${el.pod_reference}/${el.pod_idx}`,
                        delivery: el.pod_id,
                        index: (idx + 10),
                        idx
                    }
                )
            })
            tabs.push(
                {
                    divider: true,
                }
            )
        }

        if(!revision) {
                tabs.push(
                {
                    label: <Badge color="primary" className="badge" badgeContent={po.all_files_require_action_count ?? po.files_require_action_count ?? 0} showZero={false}>Files</Badge>,
                    delivery: null,
                    disabled: _.isEmpty(po.documents),
                    index: 5,
                }
            )
        }

        tabs.push(
            {
                label: `Invoicing`,
                delivery: null,
                index: 6,
                disabled: _.isEmpty(_.filter(po.deliveries, el => el.invoice)) && _.isEmpty(po.invoices)
            }
        );
        
        if(!revision) {
            tabs.push(
                {
                    divider: true
                },
                {
                    label: `Actions ${po.actions_count > 0 ? `(${po.actions_count})` : ``}`,
                    delivery: null,
                    index: 9,
                },
                {
                    label: `Timeline ${_.size(po.activity) > 0 ? `(${_.size(po.activity)})` : ``}`,
                    delivery: null,
                    index: 3,
                },
                {
                    label: `Calls ${_.size(po.call_logs) > 0 ? `(${_.size(po.call_logs)})` : ``}`,
                    delivery: null,
                    index: 4,
                },
                {
                    label: `Notes ${_.size(po.notes) > 0 ? `(${_.size(po.notes)})` : ``}`,
                    delivery: null,
                    index: 7,
                }
            )
        }

        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" component={po.po_status === 'Cancelled' || revision ? 'strike' : undefined} className="textDefault">
                                                            Purchase Order #{po.po_reference}{!_.isEmpty(po.po_supp_reference) ? ` (${po.po_supp_reference})` : ''}
                                                            {/* Purchase Order #{po.po_reference}/{po.po_revision}{!_.isEmpty(po.po_supp_reference) ? ` (${po.po_supp_reference})` : ''} */}
                                                        </Typography>   
                                                    </Grid>
                                                    {po.supplier?.supp_phone?.length > 0 && canDial ? (
                                                        <Grid item>
                                                            <Tooltip title={`Call ${po.supplier.supp_company_name}`}>
                                                                <IconButton onClick={() => this.context.useDial(po.supplier.supp_phone)} size="small">
                                                                    <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={27.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>  
                                            </Grid>
                                            {!revision && (
                                                <Grid item>
                                                    <Tooltip title="Add Action" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddTask()}>
                                                            <FAIcon type="light" icon="layer-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon type="light" icon="comment-alt-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addCallLog && (
                                                <Grid item>
                                                    <Tooltip title="Log Call" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddCall()}>
                                                            <FAIcon type="light" icon="phone-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.uploadDoc && (
                                                <Grid item>
                                                    <Tooltip title="Upload File" placement="top">
                                                        <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                            <FAIcon type="light" icon="file-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {/* {!revision && (
                                                <Grid item>
                                                    <Tooltip title="E-mail" placement="top">
                                                        <IconButton onClick={() => this.handleDeploySendEmail()}>
                                                            <FAIcon type="light" icon="envelope-open-text" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )} */}
                                            <Grid item>
                                                <Tooltip title="Download PDF" placement="top">
                                                    <IconButton onClick={() => po.po_legacy === 1 ? getLegacyPurchaseOrderPDF(po.po_id) : getPresignedUrl(po.po_pdf_filename)}>
                                                        <FAIcon type="light" icon="file-pdf" style={{color:'#d32f2f'}} button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {((!revision && po.po_status !== 'Invoiced' && po.po_status !== 'Cancelled') || (po.po_status === 'Invoiced' && _.some(po.products, el => el.quantity !== el.quantityInvoiced))) && access.processPo && (
                                                <Grid item>
                                                    <Tooltip title={this.getProcessName()} placement="top">
                                                        <IconButton disabled={(po.po_status === "Awaiting Confirmation" && po?.deliveries?.[0]?.od?.od_sys_hold === 1) || _.some(po?.deliveries, el => ((po.po_type === 'Sales Order' && (po.po_status === 'Awaiting Confirmation')) && (el?.od?.od_on_hold === 1 || el?.od?.od_on_hold_lock === 1))) || viewing.update || viewing.process || viewing.received} onClick={() => this.props.history.push(`/purchase-orders/process/${po.po_id}`)}>
                                                            <FAIcon type="light" icon="check" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "layer-plus",
                                                            label: "Add Action",
                                                            onClick: () => this.handleDeployAddTask(),
                                                            display: !revision
                                                        },
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote
                                                        },
                                                        {
                                                            icon: "phone-plus",
                                                            label: "Log Call",
                                                            onClick: () => this.handleDeployAddCall(),
                                                            display: access.addCallLog
                                                        },
                                                        {
                                                            icon: "file-plus",
                                                            label: "Upload File",
                                                            onClick: () => this.handleDeployFileUpload(),
                                                            display: access.uploadDoc
                                                        },
                                                        {
                                                            icon: "file-pdf",
                                                            label: "Download PDF",
                                                            onClick: () => po.po_legacy === 1 ? getLegacyPurchaseOrderPDF(po.po_id) : getPresignedUrl(po.po_pdf_filename),
                                                            display: true,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: "Invoice Purchase Order",
                                                            onClick: this.handleDeployInvoicePurchaseOrder,
                                                            disabled: po.po_status === 'Invoiced',
                                                            display: access.processPo && _.some(po.products, el => parseInt(el.quantityDelivered) > 0)
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: this.getProcessName(),
                                                            onClick: () => this.props.history.push(`/purchase-orders/process/${po.po_id}`),
                                                            display: ((po.po_status !== 'Invoiced' && po.po_status !== 'Cancelled') || (po.po_status === 'Invoiced' && _.some(po.products, el => el.quantity !== el.quantityInvoiced))) && access.processPo,
                                                            disabled: _.some(po.deliveries, el => el.pod_issue === 1 || ((po.po_type === 'Sales Order' && (po.po_status === 'Awaiting Confirmation')) && (el?.od?.od_on_hold === 1 || el?.od?.od_on_hold_lock === 1))) || (po.po_status === "Awaiting Confirmation" && po?.deliveries?.[0]?.od?.od_sys_hold === 1) || viewing.update || viewing.process || viewing.received
                                                        },
                                                        // { 
                                                        //     icon: "coins",
                                                        //     label: "Modify Buy Prices",
                                                        //     onClick: this.handleDeployModifyPurchaseOrderPricing,
                                                        //     display: access.updatePo,
                                                        //     disabled: po.po_status === 'Cancelled' || po.po_status === 'Invoiced'
                                                        // },
                                                        {
                                                            icon: "pencil",
                                                            label: "Modify Purchase Order",
                                                            onClick: () => this.props.history.push(`/purchase-orders/${po.po_status !== "Awaiting Submission" ? `live-` : ``}update/${po.po_id}`),
                                                            display: access.updatePo && po?.po_type === 'Stock' && po.po_ship_here_order !== 1,
                                                            disabled: po.rma || _.some(po.deliveries, el => el.pod_issue === 1) || po.po_status === 'Cancelled' || po.po_status === 'Invoiced' || (po.po_status === 'Despatched' && po.po_ship_aa_only === 1 && po.po_delivered === 1) || viewing.update || viewing.process || viewing.received
                                                        },
                                                        {
                                                            icon: "times",
                                                            label: "Cancel Purchase Order",
                                                            onClick: this.handleDeployCancelOrder,
                                                            display: access.cancelOrder && po?.po_type === 'Stock' && po.po_ship_here_order !== 1,
                                                            disabled: po.rma || _.some(po.deliveries, el => el.pod_issue === 1) || po.po_status === 'Cancelled' || po.po_status === 'Invoiced' || (po.po_status === 'Despatched' && po.po_ship_aa_only === 1 && po.po_delivered === 1) || viewing.update || viewing.process || viewing.received
                                                        },
                                                        {
                                                            icon: "redo",
                                                            label: "Re-order",
                                                            onClick: () => this.props.history.push(`/purchase-orders/replicate/${po.po_id}`),
                                                            display: access.reOrder && po.po_type === "Stock" && !po.rma && po.po_ship_here_order === 0 && po.po_order_id === 0
                                                        },
                                                        {
                                                            icon: "cogs",
                                                            label: "Detach from Sales Order",
                                                            onClick: () => this.handleDeployDetachFromSalesOrder(),
                                                            disabled: po.rma,
                                                            display: access.processPo && po.po_type === "Stock" && po.po_ship_here_order === 1 && po.po_delivered === 0 && po.po_status !== "Invoiced"
                                                        },
                                                        {
                                                            icon: "envelope-open-text",
                                                            label: "Re-send Submission E-mail",
                                                            onClick: () => this.handleDeploySubmission(true),
                                                            display: access.submitPo,
                                                            disabled: po.po_status === 'Awaiting Submission' || po.po_status === 'Invoiced',
                                                            divider: true
                                                        },
                                                        {
                                                            icon: "undo",
                                                            label: `Cancel Despatch${po.po_type !== 'Stock' || (po.po_order_id > 0 && po.po_delivered) ? ` (Cancel in Sales Order)` : ``}`,
                                                            onClick: () => this.handleDeployCrushedIceDialog('cancelDespatch'),
                                                            display: true,
                                                            disabled: !access.cancelDespatch || po.rma || (po.po_status === "Invoiced" || po.po_status === "Cancelled") || po.po_type !== 'Stock' || (po.po_order_id > 0 && po.po_delivered) || _.isEmpty(po.deliveries) || _.every(po.deliveries, el => _.every(el.products, _el => _el.quantityDelivered > 0) || el.pod_status !== 'Despatched')
                                                        },
                                                        {
                                                            icon: "truck-fast",
                                                            label: `Shipped Direct`,
                                                            onClick: () => this.handleDeployCrushedIceDialog('shippedDirect'),
                                                            display: true,
                                                            disabled: !access.shippedDirect || po.rma || (po.po_status !== "Awaiting Despatch" && po.po_status !== "Despatched") || po.po_ship_here_order === 0 || _.isEmpty(po.deliveries) // || _.every(po.deliveries, el => _.every(el.products, _el => _el.quantityDelivered > 0))
                                                        },
                                                        {
                                                            icon: "hat-wizard",
                                                            label: "CI: Complete Delivery",
                                                            onClick: () => this.handleDeployCrushedIceDialog('completeDelivery'),
                                                            display: userIsCrushedIce,
                                                            disabled: po.rma || po.po_status === "Invoiced" || po.po_status === "Cancelled" || _.isEmpty(po.deliveries) || _.every(po.deliveries, el => el.pod_status !== 'Despatched')
                                                        },
                                                        {
                                                            icon: "hat-wizard",
                                                            label: `CI: Skip Goods Received`,
                                                            onClick: () => this.handleDeployCrushedIceDialog('skipGoodsReceived'),
                                                            display: userIsCrushedIce,
                                                            disabled: po.rma || (po.po_status !== "Awaiting Despatch" && po.po_status !== "Despatched") || po.po_ship_aa_only === 0 || _.isEmpty(po.deliveries) // || _.every(po.deliveries, el => _.every(el.products, _el => _el.quantityDelivered > 0))
                                                        },
                                                        {
                                                            icon: "hat-wizard",
                                                            label: "CI: Re-confirmation Required",
                                                            onClick: () => this.props.deployConfirmation(`Are you sure you want to move this Purchase Order to Awaiting Confirmation?`, this.handleCiReConfirmPO),
                                                            display: userIsCrushedIce,
                                                            disabled: po.rma || po.po_status === "Awaiting Submission" || po.po_status === "Awaiting Confirmation" || po.po_status === "Cancelled" || po.po_status === "Invoiced" || _.every(po.deliveries, el => el.pod_status === "Despatched" || el.pod_status === "Invoiced")
                                                        },
                                                        {
                                                            icon: "hat-wizard",
                                                            label: "CI: Re-generate PDFs",
                                                            onClick: () => this.props.deployConfirmation(`Are you sure you want to re-generate PDFs for this Purchase Order?`, this.handleCiRegeneratePdfs),
                                                            display: userIsCrushedIce,
                                                        }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item xs={10} md={8}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InsightChip 
                                                                disabled={!access.viewSupplier}
                                                                icon="external-link"
                                                                label={`Supplier: ${po.supplier?.supp_company_name}`}
                                                                onClick={() => this.handleDeployViewSupplier()}
                                                                paddingLeft={8}
                                                                tooltip="View Supplier"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="info-circle"
                                                                label={`Status: ${revision ? 'Revised' : po.po_status}`}
                                                            />
                                                        </Grid>
                                                        {!ui?.device?.isPortrait && (
                                                            <>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="truck"
                                                                        label={`Type: ${po.po_collection === 1 ? `Sales Order (Direct Collection)` : (po.po_ship_aa_only === 1 ? ((po.po_type === 'Stock' && po.po_order_id === 0) || (po.po_type === 'Stock' && po.po_order_id > 0 && po.po_ifm_modified === 1) ? `Warehouse Stock` : `Sales Order (Ship Here)`) : `Sales Order (Direct Despatch)`)}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="pound-sign"
                                                                        label={`Net Total: ${currencyFormat.format(po.po_total_net)}`}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )}
                                                        {!ui?.device?.isTablet && (
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="pound-sign"
                                                                    label={`Gross Total: ${currencyFormat.format(po.po_total_gross)}`}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={2} justify="flex-end">
                                                            <Grid item>
                                                                <ActionChip action={{u: 0, p: 0}} staff={staff[this.props.loggedInStaff.id]} page="purchase order" />
                                                            </Grid>
                                                            {_.map(viewing.list, (view, idx) => {
                                                                if(staff[view.i]) {
                                                                    return (
                                                                        <Grid item key={idx}>
                                                                            <ActionChip action={view} staff={staff[view.i]} page="purchase order" />
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Grid item xs={2} align='right'>
                                                        <ActionChip staff={{name: `${(_.size(viewing.list) + 1)} viewing`}} page="purchase order" />
                                                    </Grid>
                                                </Hidden>
                                            </Grid> 
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid> 
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    {(viewing.received && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <strong>Goods Received In Progress - </strong>{staff[_.find(viewing.list, el => el.re === 1)?.i]?.name} is currently booking in a delivery for this purchase order
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (_.some(po.deliveries, el => el.pod_issue === 1 && !el.goods_received) && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <strong>Manual Verification Required - This imported purchase order requires manual verification</strong>
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (_.some(po.deliveries, el => el.pod_issue === 1) && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <strong>Urgent Action Required - There is a goods received discrepancy that requires resolution, please review the deliveries for more information</strong>
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (po.po_ship_here_order === 1 && po.po_ifm_modified === 1 && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}><strong>Note - </strong>This back-to-back ship here purchase order has been modified directly and may contain additional stock</Alert>
                                            </Box>
                                        </Grid>
                                    )) || (po.rma && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}><strong>No Longer Required - </strong>Stock to be returned on receipt to the supplier as the sales order was cancelled</Alert>
                                            </Box>
                                        </Grid>
                                    )) || (po.reason && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}><strong>{po.po_status !== 'Cancelled' ? ` Partially` : ``} Cancelled - </strong>{po?.reason?.or_reason}</Alert>
                                            </Box>
                                        </Grid>
                                    )) || (po.po_status === 'Cancelled' && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}><strong>{po.po_status !== 'Cancelled' ? ` Partially` : ``} Cancelled - </strong>No reason given</Alert>
                                            </Box>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={8}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={12} md={4}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                        <Heading text="Financials" />
                                                                            <Grid container spacing={3}>   
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Box pt={1.05} pb={1.05}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="pound-sign"
                                                                                            title="Total Buy Price"
                                                                                            content={`${currencyFormat.format(po.po_total_cost)}`}
                                                                                        />
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Box pt={1.05} pb={1.05}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="plus"
                                                                                            title="Delivery"
                                                                                            content={`${currencyFormat.format(po.po_total_carriage)}`}
                                                                                        />
                                                                                    </Box>
                                                                                </Grid> 
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Box pt={1.05} pb={1.05}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="pound-sign"
                                                                                            title="Net Total"
                                                                                            content={`${currencyFormat.format(po.po_total_net)}`}
                                                                                        />
                                                                                    </Box>
                                                                                </Grid> 
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Box pt={1.05} pb={1.05}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="plus"
                                                                                            title="VAT"
                                                                                            content={`${currencyFormat.format(po.po_total_vat)}`}
                                                                                        />
                                                                                    </Box>
                                                                                </Grid> 
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Box pt={1.05} pb={1.05}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="pound-sign"
                                                                                            title="Gross Total"
                                                                                            content={`${currencyFormat.format(po.po_total_gross)}`}
                                                                                        />
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Order Status" />
                                                                            <Grid container spacing={3}>    
                                                                                <Grid item xs={12} lg={6}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={po.po_status === "Awaiting Submission" || po.po_status === 'Awaiting Confirmation' ? '#f44336' : '#4CAF50'}
                                                                                        icon={po.po_status === "Awaiting Submission" || po.po_status === 'Awaiting Confirmation' ? 'exclamation-triangle' : 'check'}
                                                                                        title="Status"
                                                                                        content={po.po_status}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>   
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={po.revisions?.[0].po_revision !== po.po_revision ? '#f44336' : '#4CAF50'}
                                                                                        icon={po.revisions?.[0].po_revision !== po.po_revision ? 'exclamation-triangle' : 'check'}
                                                                                        title="Latest Revision"
                                                                                        content={po.revisions?.[0].po_revision}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>    
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={'#4CAF50'}
                                                                                        content={po.po_collection === 1 ? `Direct Collection` : (po.po_ship_aa_only === 1 ? `Ship Here` : `Direct Delivery`)}
                                                                                        icon={'check'}
                                                                                        title="Despatch"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>    
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={po.po_delivered === 0 ? '#f44336' : '#4CAF50'}
                                                                                        content={po.po_delivered === 0 ? "No" : "Yes"}
                                                                                        icon={po.po_delivered === 0 ? 'times' : 'check'}
                                                                                        title={po.po_collection === 1 ? `Ready to Collect` : `Delivered`}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Order Processing" />
                                                                            <Grid container spacing={3}>    
                                                                                <Grid item xs={12} lg={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={po.po_submission_date === '0000-00-00' ? '#f44336' : '#4CAF50'}
                                                                                        content={po.po_submission_date === '0000-00-00' ? 'N/A' : moment(po.po_submission_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                        icon={po.po_submission_date === '0000-00-00' ? 'times' : 'check'}
                                                                                        title="Submitted"
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={po.po_confirmation_date === '0000-00-00' ? '#f44336' : '#4CAF50'}
                                                                                        content={po.po_confirmation_date === '0000-00-00' ? 'N/A' : moment(po.po_confirmation_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                        icon={po.po_confirmation_date === '0000-00-00' ? 'times' : 'check'}
                                                                                        title="Confirmed"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={po.po_despatched_date === '0000-00-00' ? '#f44336' : '#4CAF50'}
                                                                                        content={po.po_despatched_date === '0000-00-00' ? 'N/A' : moment(po.po_despatched_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                        icon={po.po_despatched_date === '0000-00-00' ? 'times' : 'check'}
                                                                                        title="Despatched"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        color={po.po_invoiced_date === '0000-00-00' ? '#f44336' : '#4CAF50'}
                                                                                        content={po.po_invoiced_date === '0000-00-00' ? 'N/A' : moment(po.po_invoiced_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                        icon={po.po_invoiced_date === '0000-00-00' ? 'times' : 'check'}
                                                                                        title="Invoiced"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Insight" />
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="chart-network"
                                                                                        title="Last Activity"
                                                                                        content={po.activity?.[0]?.d ? moment(po.activity?.[0]?.d).format("DD/MM/YY HH:mm") : '-'}
                                                                                        onClick={() => this.handleTabChange(3)}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>                                                        
                                                                                    <StaffTile
                                                                                        noPaper
                                                                                        title="Ordered By"
                                                                                        staffId={po.po_staff_id}
                                                                                    />
                                                                                </Grid> 
                                                                            </Grid>    
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Grid container spacing={1} alignItems='center'>
                                                                        <Grid item xs>
                                                                            <Typography variant="h6">
                                                                                {(po.po_type === 'Stock' && po.po_order_id === 0 && (
                                                                                    `Warehouse Stock`
                                                                                )) || (po.po_type === 'Stock' && po.po_order_id > 0 && po.po_ship_here_order === 1 && (
                                                                                    `${po?.so?.order_reference ? `Sales Order #${po.so.order_reference} (Ship Here)` : `Ship Here`}`
                                                                                )) || (po.po_type === 'Stock' && po.po_order_id > 0 && (
                                                                                    `${po?.so?.order_reference ? `Sales Order #${po.so.order_reference} (Detached)` : `Detached`}`
                                                                                )) || (
                                                                                    `${po?.so?.order_reference ? `Sales Order #${po?.so?.order_reference}` : 'Sales Order'} (Direct Despatch)`
                                                                                )}
                                                                            </Typography>    
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {(po?.deliveries?.[0]?.od?.od_sys_hold === 1 && (
                                                                                <Box mb={-1.5} mt={-1}>
                                                                                    <Alert severity="warning" variant="outlined">
                                                                                        <Typography variant="body2">
                                                                                            The sales order has been modified and is on hold
                                                                                        </Typography>
                                                                                    </Alert>
                                                                                </Box>
                                                                            )) || (
                                                                                <Typography variant="body2" paragraph={po.po_type === 'Sales Order' && po?.so}>
                                                                                    <FAIcon icon='info-circle' size={12.5} className="mr-05" />
                                                                                    {(po.po_status === 'Cancelled' && (
                                                                                        `This purchase order has been cancelled${po.po_type === 'Sales Order' && po?.so && po?.so?.order_status !== 'Cancelled' ? ` (fulfilment changed)` : ``}`
                                                                                    )) || ((po.po_type === 'Stock' && po.po_order_id === 0) && (
                                                                                        `This purchase order was raised to replenish warehouse stock`
                                                                                    )) || (po.po_type === 'Stock' && po.po_order_id > 0 && po.po_ship_here_order === 1 && (
                                                                                        `This purchase order was raised to fulfil the above sales order, which is shipping here for onwards delivery to the customer`
                                                                                    )) || (po.po_type === 'Stock' && po.po_order_id > 0 && (
                                                                                        `This purchase order was raised to fulfil the above sales order and has been detached`
                                                                                    )) || (
                                                                                        `This purchase order was raised to fulfil the above sales order and is shipping direct from supplier to the customer's delivery address`
                                                                                    )}
                                                                                </Typography>
                                                                            )}
                                                                            {po?.so && (
                                                                                <Box mt={2}>
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => history.push(`/sales/${po?.so?.order_id}`)}
                                                                                    >
                                                                                        <FAIcon icon="binoculars" size={13.5} button />
                                                                                        View Sales Order
                                                                                    </Button>
                                                                                </Box>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Supplier Details" />
                                                                    <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                        <FAIcon type="light" style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="building" size="small" />
                                                                        Supplier
                                                                    </Typography> 
                                                                    <Typography variant="body2" paragraph>
                                                                        {po.supplier?.supp_company_name}
                                                                    </Typography>    
                                                                    <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                        <FAIcon type="light" style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="phone" size="small" />
                                                                        Phone
                                                                    </Typography> 
                                                                    <Typography variant="body2" paragraph={po.supplier?.supp_email?.length > 0}>
                                                                        {!_.isEmpty(po.supplier?.supp_phone) ? po.supplier?.supp_phone : 'Unknown'}
                                                                    </Typography>    
                                                                    {po.supplier?.supp_email?.length > 0 && (
                                                                        <>
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                <FAIcon type="light" style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="envelope" size="small" />
                                                                                E-mail
                                                                            </Typography> 
                                                                            <Typography variant="body2">
                                                                                <Link href={`mailto:${po.supplier?.supp_email}`}>{po.supplier?.supp_email}</Link>
                                                                            </Typography>    
                                                                        </>
                                                                    )}  
                                                                </PaddedPaper>
                                                            </Grid>
                                                            {!_.isEmpty(po.revisions) && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item xs>
                                                                                <Typography variant="h6">
                                                                                    Revisions
                                                                                </Typography>    
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <DataTable
                                                                                    config={{
                                                                                        key: 'quote_id',
                                                                                        inline: true,
                                                                                    }}
                                                                                    columns={[
                                                                                        {
                                                                                            heading: 'Revision',
                                                                                            field: rowData => `${rowData.po_revision}`,
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.po_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            heading: 'Created By',
                                                                                            field: rowData => staff?.[rowData?.po_staff_id ?? 0]?.name ?? 'Unknown',
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.po_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            heading: 'Created On',
                                                                                            field: rowData => moment(rowData.po_created_datetime).format("DD/MM/YYYY"),
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.po_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            actions: rowData => {
                                                                                                return [
                                                                                                    {name: 'View', icon: 'binoculars', link: '/purchase-orders/' + rowData.po_id, disabled: rowData.po_id === po.po_id}
                                                                                                ]
                                                                                            }
                                                                                        }
                                                                                    ]}
                                                                                    rows={po.revisions}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <PurchaseOrderSummary po={po} ui={ui} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <Box m={-3}>
                                                    <Paper style={{width: '100%', background: '#F5F5F5', height: 47, borderBottom: '1px solid #ddd', borderRadius: 0}} elevation={0}>
                                                        <Box p={0.5}>
                                                            <Grid container spacing={1} justify='center' alignItems='center'>
                                                                <Grid item xs>
                                                                    <Typography variant="body2" className="ml-2">
                                                                        {`Pages: ${pdf.numPages ?? 1}`}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(1.5)}
                                                                            >
                                                                                <FAIcon type="light" icon="search" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(false)}
                                                                                disabled={pdf.scale === "0.75"}
                                                                            >
                                                                                <FAIcon type="light" icon="search-minus" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(true)}
                                                                                disabled={pdf.scale === "2.00"}
                                                                            >
                                                                                <FAIcon type="light" icon="search-plus" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                    <Grid container justify="center" style={{height: 'calc(100% - 47px)', background: '#777', position: 'relative', textAlign: 'center', overflowX: 'hidden', overflowY: 'auto'}}>
                                                        <Grid item>
                                                            <Box p={3}>
                                                                <Document
                                                                    file={po.signedPdf}
                                                                    onLoadSuccess={this.handlePdfLoad}
                                                                    loading={(
                                                                        <>
                                                                        </>
                                                                    )}
                                                                >
                                                                    {Array.apply(null, Array(pdf.numPages))
                                                                            .map((x, i)=>i+1)
                                                                            .map(page => (
                                                                                <Page 
                                                                                    pageNumber={page} 
                                                                                    loading={(
                                                                                        <LoadingCircle />
                                                                                    )}
                                                                                    renderAnnotationLayer={false}
                                                                                    scale={pdf.scale}
                                                                                    style={{
                                                                                        width: '100%', 
                                                                                        position: 'absolute',
                                                                                    }}
                                                                                />
                                                                            )
                                                                    )}
                                                                </Document>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <ActivityLog activity={po.activity} fullPage />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <ViewCallLogs calls={po.call_logs} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <ViewDocuments 
                                                            access={access}
                                                            callback={this.loadComponentData}
                                                            category="Purchase Orders"
                                                            documents={po.documents}
                                                            uploadFile={this.handleDeployFileUpload} 
                                                            legacyDocBox={`C:/DATA/Advance/Documents/POPDocs/POP${po.po_reference}`}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Paper>
                                                            <Box p={3} pb={2}>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Purchase Order Invoices
                                                                </Typography>
                                                                {(!_.isEmpty(_.filter(po.invoices, el => el.poi_pod_id === 0)) && (
                                                                    <Box pt={1}>
                                                                        <DataTable  
                                                                            config={{
                                                                                key: 'pod_id',
                                                                                alternatingRowColours: true,
                                                                                // responsiveImportance: true,
                                                                                pagination: true,
                                                                                rowsPerPage: 10,
                                                                                inline: true,
                                                                                plainPagination: true
                                                                            }}
                                                                            columns={[  
                                                                                {
                                                                                    heading: 'Group Invoice',
                                                                                    field: rowData => (
                                                                                        <Box pt={1} pb={1}>
                                                                                            <Typography variant="body2" className="fw-400">
                                                                                                Group Invoice {rowData.rowNumber} of {_.size(_.filter(po.invoices, el => el.poi_pod_id === 0))}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )
                                                                                }, 
                                                                                {
                                                                                    heading: 'Invoiced By',
                                                                                    field: rowData => <StaffChip staff={staff?.[rowData.poi_staff_id]} />,
                                                                                },      
                                                                                {
                                                                                    heading: 'On',
                                                                                    field: rowData => rowData.poi_created_datetime,
                                                                                    fieldFormat: 'datetime',
                                                                                },    
                                                                                {
                                                                                    heading: 'Expected Net Total',
                                                                                    field: rowData => rowData.poi_pod_total_net,
                                                                                    fieldFormat: 'currency',
                                                                                    sizeToContent: true
                                                                                }, 
                                                                                {
                                                                                    heading: 'Supplier Net Total',
                                                                                    field: rowData => rowData.poi_supp_total_net,
                                                                                    fieldFormat: 'currency',
                                                                                    sizeToContent: true
                                                                                },
                                                                                {
                                                                                    heading: 'Expected Gross Total',
                                                                                    field: rowData => rowData.poi_pod_total_gross,
                                                                                    fieldFormat: 'currency',
                                                                                    sizeToContent: true
                                                                                },    
                                                                                {
                                                                                    heading: 'Supplier Gross Total',
                                                                                    field: rowData => rowData.poi_supp_total_gross,
                                                                                    fieldFormat: 'currency',
                                                                                    sizeToContent: true
                                                                                },     
                                                                                {
                                                                                    heading: 'Matches',
                                                                                    field: rowData => parseFloat(rowData.poi_pod_total_gross) === parseFloat(rowData.poi_supp_total_gross),
                                                                                    fieldFormat: 'boolean',
                                                                                    alignment: 'right',
                                                                                    sizeToContent: true
                                                                                },     
                                                                                {
                                                                                    heading: 'Accepted',
                                                                                    field: rowData => rowData.poi_disputed === 0,
                                                                                    fieldFormat: 'boolean',
                                                                                    alignment: 'right',
                                                                                    sizeToContent: true
                                                                                },  
                                                                                {
                                                                                    actions: rowData => {
                                                                                        return [
                                                                                            {name: 'View Invoice Details', icon: 'binoculars', onClick: () => this.handleDeployInvoice(rowData, null, null), doubleClick: true},
                                                                                            {name: 'Download Invoice', icon: 'file-pdf', color: !_.isEmpty(rowData.poi_pdf_filename) ? 'error' : undefined,  onClick: () => getPresignedUrl(rowData.poi_pdf_filename), disabled: _.isEmpty(rowData.poi_pdf_filename)}
                                                                                        ]
                                                                                    }
                                                                                }
                                                                            ]}
                                                                            rows={_.filter(po.invoices, el => el.poi_pod_id === 0)}
                                                                        />
                                                                    </Box>
                                                                )) || (
                                                                    <Alert severity="success" variant="outlined">
                                                                        <strong>None - </strong>No grouped invoices have been processed for this purchase order
                                                                    </Alert>
                                                                )}
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Paper>
                                                            <Box p={3} pb={2}>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Purchase Order Delivery Invoices
                                                                </Typography>
                                                                {(!_.isEmpty(_.filter(po.deliveries, el => el?.invoices?.length > 0)) && (
                                                                    <Box pt={1}>
                                                                        <DataTable  
                                                                            config={{
                                                                                key: 'pod_id',
                                                                                alternatingRowColours: true,
                                                                                // responsiveImportance: true,
                                                                                pagination: true,
                                                                                rowsPerPage: 10,
                                                                                inline: true,
                                                                                plainPagination: true
                                                                            }}
                                                                            columns={[
                                                                                {
                                                                                    heading: 'Delivery',
                                                                                    field: rowData => (
                                                                                        <Box pt={1} pb={1}>
                                                                                            <Typography variant="body2" className="fw-400">
                                                                                                Delivery #{rowData.pod_reference}/{rowData.pod_idx}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )
                                                                                },      
                                                                                {
                                                                                    heading: 'Invoiced By',
                                                                                    field: rowData => <StaffChip staff={staff?.[rowData.invoice?.poi_staff_id]} />,
                                                                                },      
                                                                                {
                                                                                    heading: 'Invoiced On',
                                                                                    field: rowData => rowData.invoice?.poi_created_datetime,
                                                                                    fieldFormat: 'datetime',
                                                                                },    
                                                                                {
                                                                                    heading: 'Expected Net Total',
                                                                                    field: rowData => rowData.invoice?.poi_pod_total_net,
                                                                                    fieldFormat: 'currency',
                                                                                    sizeToContent: true
                                                                                }, 
                                                                                {
                                                                                    heading: 'Supplier Net Total',
                                                                                    field: rowData => rowData.invoice?.poi_supp_total_net,
                                                                                    fieldFormat: 'currency',
                                                                                    sizeToContent: true
                                                                                },
                                                                                {
                                                                                    heading: 'Expected Gross Total',
                                                                                    field: rowData => rowData.invoice?.poi_pod_total_gross,
                                                                                    fieldFormat: 'currency',
                                                                                    sizeToContent: true
                                                                                },    
                                                                                {
                                                                                    heading: 'Supplier Gross Total',
                                                                                    field: rowData => rowData.invoice?.poi_supp_total_gross,
                                                                                    fieldFormat: 'currency',
                                                                                    sizeToContent: true
                                                                                },     
                                                                                {
                                                                                    heading: 'Matches',
                                                                                    field: rowData => parseFloat(rowData.invoice?.poi_pod_total_gross) === parseFloat(rowData.invoice?.poi_supp_total_gross),
                                                                                    fieldFormat: 'boolean',
                                                                                    alignment: 'right',
                                                                                    sizeToContent: true
                                                                                },     
                                                                                {
                                                                                    heading: 'Accepted',
                                                                                    field: rowData => rowData.invoice?.poi_disputed === 0,
                                                                                    fieldFormat: 'boolean',
                                                                                    alignment: 'right',
                                                                                    sizeToContent: true
                                                                                },  
                                                                                {
                                                                                    actions: rowData => {
                                                                                        return [
                                                                                            {name: 'View Invoice Details', icon: 'binoculars', onClick: () => this.handleDeployInvoice(null, rowData, rowData.rowIdx), doubleClick: true},
                                                                                            {name: 'Download Invoice', icon: 'file-pdf', color: !_.isEmpty(rowData.invoice?.poi_pdf_filename) ? 'error' : undefined,  onClick: () => getPresignedUrl(rowData.invoice?.poi_pdf_filename), disabled: _.isEmpty(rowData.invoice?.poi_pdf_filename)}
                                                                                        ]
                                                                                    }
                                                                                }
                                                                            ]}
                                                                            rows={_.flatMap(_.filter(po.deliveries, el => el?.invoices?.length > 0), _el => _.map(_el.invoices, _invoice => ({..._el, invoice: _invoice})))}
                                                                        />
                                                                    </Box>
                                                                )) || (
                                                                    <Alert severity="success" variant="outlined">
                                                                        <strong>None - </strong>No individual delivery invoices have been processed for this purchase order
                                                                    </Alert>
                                                                )}
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={7}>
                                                <ViewNotes notes={po.notes} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={8}>
                                                <Paper>
                                                    <DataTable  
                                                        config={{
                                                            key: 'pod_id',
                                                            alternatingRowColours: true,
                                                            // responsiveImportance: true,
                                                            pagination: true,
                                                            rowsPerPage: 10,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    title: `Purchase Order Deliveries`,
                                                                    name: `po-deliveries`,
                                                                    excel: true,
                                                                    pdf: true,
                                                                    print: true
                                                                }
                                                            }
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Delivery',
                                                                field: rowData => (
                                                                    <Box pt={1} pb={1}>
                                                                        <Typography variant="body2" className="fw-400">
                                                                            Delivery {rowData.pod_idx} of {_.size(po.deliveries)}
                                                                        </Typography>
                                                                        <Typography variant="caption">
                                                                            #{rowData.pod_reference}/{rowData.pod_idx}
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                            },       
                                                            {
                                                                heading: 'Net Total',
                                                                field: rowData => rowData.pod_total_net,
                                                                fieldFormat: 'currency',
                                                                sizeToContent: true
                                                            },  
                                                            {
                                                                heading: 'Check Date',
                                                                field: rowData => rowData.pod_check_date,
                                                                fieldFormat: 'date',
                                                            },   
                                                            {
                                                                heading: `Expected ${po.po_collection === 1 ? `Ready To Collect` : `Despatch`}`,
                                                                field: rowData => rowData.pod_expected_despatch_date,
                                                                fieldFormat: 'date',
                                                            },     
                                                            {
                                                                heading: `${po.po_collection === 1 ? `Ready To Collect On` : `Despatched On`}`,
                                                                field: rowData => rowData.pod_despatch_date,
                                                                fieldFormat: 'date',
                                                            },   
                                                            {
                                                                heading: 'Direct Collection',
                                                                field: rowData => rowData.pod_collection === 1,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },   
                                                            {
                                                                heading: 'Direct Delivery',
                                                                field: rowData => rowData.pod_ship_aa_only === 0 && rowData.pod_collection === 0,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },  
                                                            {
                                                                heading: 'Ship Here',
                                                                field: rowData => rowData.pod_ship_aa_only === 1,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },  
                                                            {
                                                                heading: po.po_collection === 1 ? `Ready To Collect` : `Delivered`,
                                                                field: rowData => rowData.pod_delivered === 1,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                                sizeToContent: true
                                                            },      
                                                            {
                                                                heading: po.po_status === 'Awaiting Confirmation' ? 'Requested By' : 'Confirmed By',
                                                                field: rowData => <StaffChip staff={staff?.[rowData.pod_staff_id]} />,
                                                                fieldFormat: 'staff'
                                                            },
                                                            {
                                                                heading: 'Status',
                                                                field: rowData => <StatusChip status={po?.po_status === 'Awaiting Submission' ? 'Awaiting Submission' : (rowData.pod_status ?? '-')} />,
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'View Delivery', icon: 'binoculars', onClick: () => this.handleTabChange(tabs[_.findIndex(tabs, el => el.delivery === rowData.pod_id)].index), doubleClick: true}
                                                                    ]
                                                                }
                                                            }
                                                        ]}
                                                        rows={po.deliveries}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={9}>
                                                <ViewActions
                                                    key={key}
                                                    filter="po"
                                                    filterId={po.po_id}
                                                    staff={staff}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    callback={this.loadComponentData}
                                                    noPagination
                                                />
                                            </TabPanel>
                                            {_.map(po.deliveries, (pod, idx) => (
                                                <TabPanel value={currentTab} index={(idx + 10)}>
                                                    <PurchaseOrderDeliverySummary 
                                                        access={access}
                                                        callback={this.loadComponentData}
                                                        po={po} 
                                                        pod={pod} 
                                                        ui={ui} 
                                                    />
                                                </TabPanel>
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    {_.map(tabs, (tab, idx) => (
                                        (tab.divider && (
                                            <Divider key={idx} />
                                        )) || (
                                            <Tab 
                                                classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                                disabled={tab.disabled}
                                                index={tab.index}
                                                value={tab.index}
                                                key={idx} 
                                                label={tab.label} 
                                                onClick={() => this.handleTabChange(tab.index, tab.delivery ?? false)} 
                                            />
                                        )
                                    ))}
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        loggedInStaff: state.staffAuth.staff,
        ui: state.ui
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success, cancel = false, successText = null, cancelText = null, successOnly = false, disableExit = false) => dispatch(deployConfirmation(message, success, cancel, successText, cancelText, successOnly, disableExit)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, de)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(withRouter(ViewPurchaseOrder)));