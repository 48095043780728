import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { clearPersistence, setPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistence/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        viewCustomer: false
    },
    showActioned: false,
    searchResults: {},
    searchString: '',
    isLoading: true
})

class CreditReportRequests extends React.Component {
    constructor(props) {
        super(props);
        this.persistenceId = 'creditReportRequests';
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }

    componentDidMount(){
        this.props.pageTitle([0, 'Finance', 'Credit Checks', 'Requested Credit Checks']);
        if(!this.hasPageState()) {
            API.multiAccess([
                'view-customer'
            ])
            .then(([
                viewCustomer
            ]) => {
                this.setState({
                    access: {
                        viewCustomer
                    }
                }, () => this.getSearchData());
            })
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getSearchData = (isLoading = true) => {
        const { searchString } = this.state;
        
        let params = { 
            searchString,
        }

        if(this.state.showActioned) {
            params = {
                ...params,
                showActioned: true
            }
        }
        this.setState({
            isLoading
        }, () => {
            API.get('/accounts/creditReports/requests', 
                {
                    params,
                    props: { 
                        cancellation: true 
                    }
                }
            )
            .then(result => {
                if(result?.data) {
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    }, this.savePageState);
                }
            });
        })
    }

    onSearchStringChange = event => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }
    
    resetSearch = () => {
        this.clearPageState();
        this.timeout = setTimeout(() => {
            this.setState({
                ...this.getInitialState(initialState()),
                access: this.state.access
            }, () => {
                this.getSearchData();
            })
        }, 250);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getSearchData(true), 200);
    }

    toggleShowActioned = () => {
        this.setState({
            showActioned: !this.state.showActioned
        }, () => this.getSearchData())
    }

    render() {
        const { staff, ui } = this.props;
        const { access, isLoading, searchString, searchResults, showActioned } = this.state;

        const cols = [ 
            {
                heading: 'Customer',
                field: rowData => rowData.customer?.cust_company_name ?? '-',
                dataRef: 'customer.cust_company_name',
                main: true,
                important: true,
                cellProps: rowData => ({
                    style: {
                        textDecoration: rowData.rc_actioned === 1 ? 'line-through' : undefined
                    }
                })
            },
            {
                heading: 'Account Number',
                field: rowData => rowData.customer?.cust_account_number ?? '-',
                dataRef: 'customer.cust_account_number',
                important: true,
                cellProps: rowData => ({
                    style: {
                        textDecoration: rowData.rc_actioned === 1 ? 'line-through' : undefined
                    }
                })
            },
            {
                heading: 'Credit Limit',
                field: rowData => rowData.customer?.ccl,
                fieldFormat: 'currency',
                dataRef: 'customer.ccl',
                alignment: 'right',
                important: true,
                sizeToContent: true,
            },
            {
                heading: 'Credit Balance',
                field: rowData => rowData.customer?.ccb,
                fieldFormat: 'currency',
                dataRef: 'customer.ccb',
                alignment: 'right',
                important: true,
                sizeToContent: true,
            },
            {
                heading: 'Credit Available',
                field: rowData => rowData.customer?.cca,
                fieldFormat: 'currency',
                dataRef: 'customer.cca',
                alignment: 'right',
                important: true,
                sizeToContent: true,
                cellProps: rowData => ({
                    style: {
                        color: rowData.customer?.cca < 0 ? '#ef3340' : '#4CAF50'
                    }
                })
            },
            {
                heading: 'Credit Utilisation',
                field: rowData => rowData.customer?.ccuf,
                dataRef: 'customer.ccuf',
                fieldFormat: 'percentage',
                alignment: 'right',
                important: true,
                sizeToContent: true,
                cellProps: rowData => ({
                    style: {
                        color: rowData.customer?.ccuf > 100 ? '#ef3340' : '#4CAF50'
                    }
                })
            },
            {
                heading: 'Euler Cover',
                field: rowData => rowData.customer?.eul ?? "0.00",
                fieldFormat: 'currency',
                dataRef: 'customer.eul',
                alignment: 'right',
                important: true,
                sizeToContent: true,
            },
            {
                heading: 'Payment Terms',
                field: rowData => rowData.customer?.pt,
                dataRef: 'customer.pt',
                important: true,
                sizeToContent: true,
            },
            {
                heading: 'Request Type',
                field: rowData => rowData.type,
                dataRef: 'type',
                important: true,
                cellProps: rowData => ({
                    style: {
                        textDecoration: rowData.rc_actioned === 1 ? 'line-through' : undefined
                    }
                })
            },
            {
                heading: 'Requested By',
                field: rowData => <StaffChip staff={staff?.[rowData.rc_staff_id] ?? 0} />,
                fieldFormat: 'staff',
                dataRef: 'rc_staff_id',
            },
            {
                heading: 'Requested On',
                field: rowData => rowData.rc_created_datetime,
                fieldFormat: 'datetime',
                dataRef: 'rc_created_datetime',
            }
        ];

        if(showActioned) {
            cols.push(
                {
                    heading: 'Status',
                    field: rowData => rowData.rc_status,
                    dataRef: 'rc_status',
                    sizeToContent: true,
                },
                {
                    heading: 'Actioned By',
                    field: rowData => rowData.rc_actioned === 0 ? '-' : (rowData.rc_actioned_staff_id > 0 ? staff?.[rowData.rc_actioned_staff_id]?.name ?? '-' : `Sales Order | Delivery #${rowData?.delivery?.od_ref ?? 'Unknown'}`),
                    dataRef: 'rc_actioned_staff_id',
                    fieldFormat: 'staff',
                    sizeToContent: true,
                },
                {
                    heading: 'Actioned',
                    field: rowData => rowData.rc_actioned === 1 ? moment(rowData.rc_actioned_datetime).format("HH:mm [on] DD/MM/YYYY") : '-',
                    dataRef: 'rc_actioned_datetime',
                    sizeToContent: true,
                    fieldFormat: 'datetime',
                }
            )
        }
        
        cols.push(
            {
                actions: rowData => {
                    return [
                        {name: 'View Request', icon: 'binoculars', onClick: () => this.props.history.push({pathname: `/customers/${rowData.rc_cust_id}`, state: { currentTab: 16 } }), disabled: !access.viewCustomer && true, doubleClick: true},
                    ]
                }
            }
        )

        return (
            <>
                <Grid container spacing={1}>      
                    <Grid container item xs={12} alignItems='center'>   
                        <Grid item xs={8} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                            <TextField 
                                fullWidth
                                className="pr-2"
                                onChange={this.onSearchStringChange}
                                placeholder='Search:'
                                value={searchString} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                }}
                                autoFocus={!ui?.device?.isTablet}
                            /> 
                        </Grid>    
                        <Grid item xs={2} sm={2} md={2} xl={2} style={{marginBottom: 12}}>
                            <Tooltip title={`Show ${showActioned ? 'outstanding' : 'actioned'} requests`}>
                                <IconButton
                                    onClick={this.toggleShowActioned}
                                >
                                    <FAIcon icon={showActioned ? 'eye' : 'eye-slash'} type="light" color={showActioned === 1 ? 'primary' : undefined} noMargin />
                                </IconButton>
                            </Tooltip>
                        </Grid>  
                    </Grid>           
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'rc_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: isLoading,
                                    responsiveImportance: true,
                                    persistenceId: this.persistenceId,
                                    options: {
                                        dataRef: true,
                                        reset: this.resetSearch,
                                        export: {
                                            title: `Credit Report Requests`,
                                            name: `credit-report-requests`,
                                            excel: true,
                                            pdf: true,
                                            print: true
                                        }
                                    }
                                }}
                                columns={cols}
                                rows={searchResults}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => ({
        staff: state.notifications.status,
        statePersistence: state.statePersistence,
        ui: state.ui
})

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence()),
    setPersistence: (key, state) => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditReportRequests);