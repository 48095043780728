import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const style = () => ({
    zone: {
        borderTop: '1px solid #ddd',
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        textAlign: 'center',
    },
    hover: {
        color: '#fff',
        backgroundColor: '#ef3340'
    },  
    input: {
        display: 'none'
    }
});

const initialState = {
    hover: false
}

class DragFileInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.input = React.createRef();
    }

    handleChange = (drop, name, e) => {
        if(drop) e.preventDefault();     
        let files = drop === true ? e.dataTransfer.files : e.target.files;
        this.setState({
            hover: false
        }, () => this.props.onChange(files));
    }

    handleDragOver = e => {
        e.preventDefault();        
        this.setState({
            hover: true 
        });
    }

    handleDragLeave = () => {
        this.setState({
            hover: false
        });
    }
    
    handleOpenDialog = () => {
        this.input.current.click();
    }

    render() {
        const { hover } = this.state;
        const { classes, files, id, name } = this.props;
        return (
            <div
                className={`${classes.zone} ${hover ? classes.hover : ''}`}
                onDragOver={this.handleDragOver}
                onDragLeave={this.handleDragLeave}
                onDrop={e => this.handleChange(true, name, e)} 
            >
                <div onClick={this.handleOpenDialog} className="link" style={{paddingTop: 12, paddingBottom: 8}}>
                    <input
                        className={classes.input}
                        file={files}
                        id={id}
                        name={name}
                        onChange={e => this.handleChange(false, name, e)}
                        ref={this.input}
                        type="file"
                        multiple
                    />
                    {(hover && (
                        <>
                            <FAIcon type="light" icon="cloud-upload" size={20} buttonPrimary noMargin />
                            <Typography variant="body2" className="textLight">
                                Drop file to attach it
                            </Typography>
                        </>
                    )) || (
                        <>
                            <Grid container spacing={1} justify='center' alignItems='center'>
                                <Grid item>
                                    <FAIcon type="thin" icon="file-excel" size={20} button noMargin />
                                </Grid>
                                <Grid item>
                                    <FAIcon type="thin" icon="file-video" size={20} button noMargin />
                                </Grid>
                                <Grid item>
                                    <FAIcon type="thin" icon="file-image" size={20} button noMargin />
                                </Grid>
                                <Grid item>
                                    <FAIcon type="thin" icon="file-pdf" size={20} button noMargin />
                                </Grid>
                                <Grid item>
                                    <FAIcon type="thin" icon="file-csv"  size={20}button noMargin />
                                </Grid>
                            </Grid>
                            <Typography variant="body2">
                                Attach a file or drag it here.
                            </Typography>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default withStyles(style)(DragFileInput);