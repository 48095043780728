import { Box, Button, Grid, InputAdornment, Paper, TextField, Typography } from "@material-ui/core";
import API from "API";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

const initialState = () => ({
    formData: {},
    isLoading: true,
    preset: '',
    suppliers: []
})

class StaffDiscounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        API.get(`/suppliers/all`, {
            params: {
                hasProducts: true,
                forList: true,
                forType: 2,
            },
        })
        .then(res => {
            if(res?.data) { 
                let suppliers = _.map(res.data, (el) => {
                    return _.assign({
                        value: el.v,
                        label: el.l,
                    });
                });
                this.setState({
                    suppliers
                }, this.loadComponentData)
            }
        })
    }

    loadComponentData = () => {
        const {
            id
        } = this.props;
        API.get(`/staff/${id}/discounts`)
        .then(res => {
            if(res?.data) {
                let formData = {}
                res.data.forEach((el) => {
                    formData[el.id] = el.disc;
                });
                this.setState({
                    formData,
                    suppliers: _.orderBy(this.state.suppliers, [el => formData?.[el.value] ? 1 : 0, 'label'], ['desc', 'asc']),
                    isLoading: false
                })
            }
        })
    }

    handleChange = (supplier, value) => {
        let v = !_.isNumber(value) ? '' : parseFloat(parseFloat(value).toFixed(2));
        if(v > 100 || v < 0) v = '';
        this.setState({
            formData: {
                ...this.state.formData,
                [supplier]: v
            }
        })
    }

    handlePresetChange = (value) => {
        let v = !_.isNumber(value) ? '' : parseFloat(parseFloat(value).toFixed(2));
        if(v > 100 || v < 0) v = '';
        this.setState({
            preset: v
        })
    }

    handleClear = () => {
        this.setState({
            formData: initialState().formData
        })
    }

    handleLoadPreset = () => {
        let preset = !_.isNumber(this.state.preset) ? '' : parseFloat(parseFloat(this.state.preset).toFixed(2));
        if(preset === '' || preset > 100 || preset < 0) return;
        let formData = {};
        this.state.suppliers.forEach((el) => {
            formData[el.value] = preset;
        });
        this.setState({
            formData,
            preset: ''
        });
    }

    handleSubmit = () => {

        const {
            formData
        } = this.state;

        const {
            id,
            deploySnackbar
        } = this.props;

        this.setState({
            isLoading: true
        }, () => {

            API.put(`/staff/${id}/discounts`, { discounts: formData })
            .then (res => {
                if(res?.data?.success) {
                    deploySnackbar("success", "Discounts saved successfully");
                    this.loadComponentData();
                }
            });

        })
    }

    render = () => {
        const { formData, isLoading, preset, suppliers } = this.state;
        return (
            <Paper>
                <Box p={3}>
                    <Grid container spacing={3} justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6" gutterBottom>
                                Authorised Manual Discounts by Supplier
                            </Typography>
                            <Typography variant="body2">
                                <FAIcon icon="info-circle" size={12.5} />
                                The user may need to refresh RED to see changes made here.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3} justify="flex-end" alignItems="center">
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <TextField
                                                style={{
                                                    width: 175
                                                }}
                                                className="pr-2"
                                                onChange={e => this.handlePresetChange(e?.target?.value ? parseFloat(e.target.value) : '')}
                                                placeholder='None'
                                                value={preset}
                                                variant="filled"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="center"><div className="pl-2">%</div></InputAdornment>
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                color="primary"
                                                variant="text"
                                                onClick={this.handleLoadPreset}
                                                disabled={isLoading}
                                            >
                                                <FAIcon type="light" icon="badge-percent" size="small" button disabled={isLoading} />
                                                Apply Preset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        color="primary"
                                        variant="text"
                                        onClick={this.handleClear}
                                        disabled={isLoading}
                                    >
                                        <FAIcon type="light" icon="times-circle" size="small" button disabled={isLoading} />
                                        Clear
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        color="primary"
                                        variant="text"
                                        onClick={this.loadComponentData}
                                        disabled={isLoading}
                                    >
                                        <FAIcon type="light" icon="times-circle" size="small" button disabled={isLoading} />
                                        Discard
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        color="primary"
                                        variant="contained"
                                        onClick={this.handleSubmit}
                                        disabled={isLoading}
                                    >
                                        <FAIcon type="light" icon="check-circle" size="small" button disabled={isLoading} />
                                        Save Changes
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DataTable
                        config={{
                            alternatingRowColours: true,
                            isLoading: isLoading,
                            key: 'value',
                            responsiveImportance: true,
                        }}
                        columns={[
                            {
                                heading: 'Supplier',
                                field: rowData => rowData.label,
                                important: true, 
                                dataRef: 'label',
                                sizeToContent: true
                            },
                            {
                                heading: 'Authorised Discount',
                                field: rowData => (
                                    <TextField
                                        fullWidth
                                        className="pr-2"
                                        onChange={e => this.handleChange(rowData.value, e?.target?.value ? parseFloat(e.target.value) : '')}
                                        placeholder='None'
                                        value={formData?.[rowData.value] || ''} 
                                        variant="filled"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="center"><div className="pl-2">%</div></InputAdornment>
                                        }}
                                        error={formData?.[rowData.value] > 100 || formData?.[rowData.value] < 0}
                                        helperText={formData?.[rowData.value] > 100 || formData?.[rowData.value] < 0 ? 'Discount must be between 0 and 100' : ''}
                                        type="number"
                                    />
                                ),
                                important: true, 
                                sizeToContent: true
                            },
                        ]}
                        rows={suppliers}
                    />
                </Box>
            </Paper>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    deploySnackbar: (type, message) => dispatch(deploySnackBar(type, message)),
});

export default connect(null, mapDispatchToProps)(StaffDiscounts);