import React from 'react';

import Search from 'Components/Common/Search/Search';

const ProductBinLocations = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/productBinLocations',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Bin Location',
                        field: rowData => rowData.bin,
                        dataRef: 'bin',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Size',
                        field: rowData => rowData.sz,
                        dataRef: 'sz',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Product Code',
                        field: rowData => rowData.pc,
                        dataRef: 'pc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Description',
                        field: rowData => rowData.desc,
                        dataRef: 'desc',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.an,
                        dataRef: 'an',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Stock Count',
                        field: rowData => rowData.sc,
                        alignment: 'right',
                        dataRef: 'sc',
                        sizeToContent: true
                    },
                    {
                        heading: 'Product Weight (kg)',
                        field: rowData => rowData.kg,
                        dataRef: 'kg',
                        alignment: 'right',
                        important: true,
                        sizeToContent: true
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'product-bin-locations',
                noResultsText: 'Sorry, no stocked products to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Products'],
                pdf: true,
                persistenceId: 'productBinLocations',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showTotals: false,
                title: 'Product Bin Locations Report'
            }}
        />
    )
}

export default ProductBinLocations;