import React from 'react';

import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import Tile from 'Components/Common/Tiles/Tile';
import { momentFormatDateTime } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';
import DataTable from 'Components/Common/DataTables/DataTable';
import BooleanChip from 'Components/Common/Chips/BooleanChip';

const CheckDetails = ({closeDialog, od, idx}) => { 
    let check = od?.check;
    if(typeof idx !== "undefined") {
        check = od?.checks?.[idx] ?? null;
    }
    return (
        <Box className="content-light-grey">
            <DialogTitle disableTypography style={{paddingBottom: 0}}>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs>
                        <Typography variant="h6">
                            Check Details
                        </Typography>
                        <Typography variant="body1" className="fw-400">
                            Sales Order Delivery #{od?.od_reference}/{od?.od_idx}
                        </Typography>
                    </Grid>
                    <Grid item align='right'>
                        <Tooltip title="Download Check Note">
                            <IconButton aria-label="Download" onClick={() => getPresignedUrl(check.check_pdf_filename)}>
                                <FAIcon type="light" icon='file-pdf' className="textError" button noMargin />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Close">
                            <IconButton aria-label="Close" onClick={closeDialog}>
                                <FAIcon type="light" icon='times' button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Box p={3} pt={0}>
                <Box pt={3} pb={3}>
                    {(check.check_status === "On Hold" && (
                        <Alert severity={'error'} elevation={0}>
                            This delivery is currently on hold due to stock availability issues
                        </Alert>
                    )) || (
                        <Alert severity={check.check_successful === 1 ? 'success' : 'warning'} elevation={0}>
                            {check.check_successful === 1 ? `This check was completed successfully` : `There were stock availability issues with this check`}
                        </Alert>
                    )}
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box p={3} pt={3} pb={3} className="content-light-white">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'clock'}
                                        title="Check Started"
                                        content={momentFormatDateTime(check.check_started_datetime, true)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Tile
                                        noPaper
                                        color={'#000'}
                                        icon={'clock'}
                                        title="Check Finished"
                                        content={momentFormatDateTime(check.check_completed_datetime, true)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <StaffTile
                                        noPaper
                                        title="Checked By"
                                        staffId={check.check_staff_id ?? null}
                                        missingText='N/A'
                                    />
                                </Grid> 
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={3} pt={3} pb={3} className="content-light-white">
                            <Typography variant="h6" gutterBottom>
                                Check List
                            </Typography>
                            <DataTable  
                                config={{
                                    key: 'cp_id',
                                    alternatingRowColours: true,
                                    // responsiveImportance: true,
                                    inline: true,
                                }}
                                columns={[
                                    {
                                        heading: 'Product',
                                        field: rowData => (
                                            <>
                                                <Typography variant="body2" className="fw-400">
                                                    {rowData?.cp_product_code ?? '-'}
                                                </Typography>
                                                <Typography variant="caption">
                                                    {rowData?.cp_product_name ?? '-'}
                                                </Typography>
                                            </>
                                        ),
                                        truncate: true
                                    }, 
                                    {
                                        heading: 'Expected',
                                        field: rowData => rowData.cp_expected_qty,
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }, 
                                    {
                                        heading: 'Present',
                                        field: rowData => rowData.cp_check_qty,
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }, 
                                    {
                                        heading: 'Check Time',
                                        field: rowData => momentFormatDateTime(rowData.cp_check_datetime, true),
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => <BooleanChip success={rowData.cp_error === 0} label={rowData.cp_error === 0 ? `Checked` : `Discrepancy`} />,
                                        sizeToContent: true
                                    }
                                ]}
                                rows={check.products}
                            />
                        </Box>
                    </Grid>
                    {check.note && (
                        <Grid item xs={12}>
                            <Box p={3} pt={3} pb={1} className="content-light-white">
                                <Typography variant="h6" gutterBottom>
                                    Check Notes
                                </Typography>
                                {check.note?.note_value?.split?.('\n').map((item, key) => (
                                    <Typography 
                                        key={key} 
                                        variant="caption"
                                        component="div" 
                                        paragraph
                                    >
                                        {item}
                                    </Typography>
                                ))}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}

export default CheckDetails;