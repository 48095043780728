import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import uuidv4 from 'uuid';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import Alert from 'Components/Common/Alert/Alert';
import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import DocumentForm from 'Components/Common/Forms/DocumentForm';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TaskForm from 'Components/Common/Activity/TaskForm';
import ViewPage from 'Components/Common/Styles/ViewPage';

import AwaitingApproval from 'Components/Sales/Actions/AwaitingApproval';
import AwaitingPayment from 'Components/Sales/Actions/AwaitingPayment';
import AwaitingAuthorisation from 'Components/Sales/Actions/AwaitingAuthorisation';
import Details from 'Components/Sales/Actions/Details';
import Processing from 'Components/Sales/Actions/Processing';
import Tabs from 'Components/Sales/Actions/Tabs';
import Unauthorized from 'Components/Sales/Actions/Unauthorized';
import WildixContext from "Context/WildixContext";

import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { currencyFormat, TabPanel } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

const getLegacyOrderPDF = orderId => {
    API.get('/sales/' + orderId + '/legacy/pdf')
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

function RedStepIcon(props) {
    const { active, completed } = props;
    return (
        (completed && ( 
            <FAIcon type='light' icon="check" size={17.5} className="textSuccess" />
        )) || (active && (
            <FAIcon type='light' icon="exclamation-circle" size={17.5} className="textError" />
        )) || (
            <FAIcon type='light' icon="circle" size={17.5} style={{color: 'rgba(0, 0, 0, 0.54)'}} />
        )
    );
}

const RedStepConnector = withStyles({
    active: {
        '& $line': {
            borderColor: '#000',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#000',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 1,
        borderRadius: 1,
    }
})(StepConnector);

const initialState = {
    access: {
        awaitingApproval: false,
        awaitingPayment: false,
        awaitingAuthorisation: false,
        processing: false,
        poSubmission: false,
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        viewCustomer: false
    },
    currentStep: null,
    currentTab: null,
    delivery: null,
    id: 0,
    initialLoading: true,
    order: {},
    processingType: null,
    tabs: [],
    uuid: uuidv4()
}

class ProcessSalesOrder extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props)
        this.state = initialState
        this.mainContentArea = React.createRef();
        this.timeout = false;
        this.poll = false;
        this.containerBottom = React.createRef()
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                ...initialState
            }, () => this.loadComponentData());
        }
    }

    componentWillUnmount = () => {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        if(this.poll) {
            clearInterval(this.poll);
        }
    }
    
    handlePoll = (initial = false) => {
        API.post(`/staff/my/view`, 
            { 
                order: this.state.id,
                type: 'Process'
            }, 
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res?.data) {
                if(initial) {
                    const   update      = _.some(res.data, el => el.u === 1),
                            process     = _.some(res.data, el => el.p === 1),
                            pick        = _.some(res.data, el => el.pi ===1),
                            despatch    = _.some(res.data, el => el.di ===1);

                    if(!_.isEmpty(res.data) && (update || process || pick || despatch)) {
                        this.props.deploySnackBar("error", `Sorry, another user is currently ${update ? 'modifying' : (process ? 'processing' : (pick ? 'picking' : 'despatching'))} this delivery`);
                        this.props.history.push(`/sales/${this.state.id}`)
                    }
                } else {
                    if(this.state.initialLoading) {
                        this.setState({
                            initialLoading: false
                        })
                    }
                }
            }
        })
    }

    loadComponentData = () => {

        let id          =   this.props.id ?? this.props.match?.params?.id ?? false,
            currentTab  =   null;

        if(isNaN(id) && id.includes(":")) {
            id = id.split(":");
            currentTab = parseInt(id[1]);
            id = parseInt(id[0]);
        } else {
            currentTab = null;
        }

        if(id) {
            API.multiAccess([
                'process-order:awaiting-approval',
                'process-order:awaiting-payment',
                'process-order:awaiting-authorisation',
                'process-order:processing',
                'process-po:awaiting-submission',
                'view-order:add-call',
                'view-order:add-note',   
                'view-order:add-document',     
                'view-customer'  
            ])
            .then(([
                awaitingApproval, 
                awaitingPayment, 
                awaitingAuthorisation,
                processing,
                poSubmission,
                addCallLog,
                addNote,
                uploadDoc,
                viewCustomer
            ]) => {
                this.setState({
                    ...this.state,
                    access: {
                        awaitingApproval, 
                        awaitingPayment, 
                        awaitingAuthorisation,
                        processing,
                        poSubmission,
                        addCallLog,
                        addNote,
                        uploadDoc,
                        viewCustomer
                    },
                    currentTab,
                    id
                }, () => this.getData());
            });
        }
    }

    getData = (changeUuid = true, defaultTab = undefined) => {
        const { id } = this.state;
        const { deploySnackBar, history, pageTitle } = this.props;
        API.get(`/sales/${id}`, 
            { 
                params: { 
                    withDefaultDate: true
                },
                props: { 
                    noLoading: true 
                } 
            }
        )
        .then(result => {

            const existingStatus = this.state.order.order_status;

            if(result?.data) {
                let order       = result.data,
                    uuid        = changeUuid ? uuidv4() : this.state.uuid,
                    currentTab  = defaultTab ? defaultTab : (this.state.currentTab ?? _.isEmpty(result?.data?.notes) ? 1 : 0);

                const creditData = JSON.parse(order.customer.cust_credit_data);
                
                order = {
                    ...order,
                    customer: {
                        ...order.customer,
                        cust_credit_data: creditData,
                        credit_data: creditData
                    }
                }
                
                if(order.order_status === 'Cancelled') {
                    deploySnackBar("error", `Sorry, this order has been cancelled`);
                    history.push(`/sales/${id}`);
                }
                
                this.setState({
                    currentTab,
                    order,
                    uuid
                }, () => {


                    if (
                        (existingStatus === 'Awaiting Approval' && (order.order_status !== 'Awaiting Payment' || order.order_status !== 'Processing')) ||
                        (existingStatus === 'Awaiting Payment' && order.order_status !== 'Processing')
                    ) { 
                        history.push(`/sales/${order.order_id}`)
                    } else {

                        let additional = '';
                        if(order.order_status === 'Awaiting Approval') {
                            additional = order?.approvals_outstanding?.[0]?.oa_reason ?? null;
                        }

                        if(pageTitle && (order.order_status === 'Awaiting Approval' || order.order_status === 'Awaiting Payment' || order.order_status === 'Awaiting Authorisation' || order.order_status === 'Processing')) {
                            if(order.order_status === "Processing" && order.order_ifm === 1) {
                                pageTitle([1, "Sales Orders", order?.customer?.cust_company_name, `Sales Order #${order.order_reference}`, `Re-processing`])
                            } else {
                                pageTitle([1, "Sales Orders", order?.customer?.cust_company_name, `Sales Order #${order.order_reference}`, `${order?.order_status} ${additional ? ` - ${additional}` : ''}`])
                            }
                        } else {
                            pageTitle([1, "Sales Orders", order?.customer?.cust_company_name, `Sales Order #${order.order_reference}`, `Change Fulfilment`])
                        }

                        if(!this.poll) {
                            this.handlePoll(true);
                            this.poll = setInterval(this.handlePoll, 3000);
                        }

                    }

                })

            }
        })
    }

    getSteps = () => {
        const { staff } = this.props;
        const { order } = this.state;
        return [`Order Placed by ${staff?.[order.order_staff_id]?.name ?? '-'}`, 'Awaiting Approval','Awaiting Payment','Awaiting Authorisation','Processing'];
    }

    getComponent = () => {

        let { 
            closeDialog, 
            deployConfirmation, 
            deployDialog, 
            deploySnackBar, 
            history,
            scrollToTop, 
            staff, 
            ui 
        } = this.props;

        let { 
            access,
            currentTab, 
            id, 
            order, 
            processingType, 
            uuid 
        } = this.state;
        
        let callback = defaultTab => this.getData(true, defaultTab);
        let authorised = false;
        let currentStep = null;
        let component = <Unauthorized status={order?.order_status} />
        let tabs = [];
        let noNotes = _.isEmpty(order.notes);

        switch(order?.order_status) {
            case "Awaiting Approval":
                currentStep = 1;
                if(access.awaitingApproval) {
                    authorised = true;
                    tabs = [
                        {
                            label: 'Notes',
                            index: 0,
                            disabled: noNotes
                        },
                        {
                            label: 'Sales Order',
                            index: 1
                        },
                        {
                            label: 'Approval Details',
                            index: 2
                        },
                    ];
                    component = <AwaitingApproval 
                                    id={id} 
                                    key={uuid}
                                    callback={callback}
                                    contentHeight={ui.contentHeight}
                                    currentTab={currentTab}
                                    deployConfirmation={deployConfirmation} 
                                    deploySnackBar={deploySnackBar}
                                    order={order}
                                    handleButtonChange={this.handleButtonChange}
                                    handleTabChange={this.handleTabChange}
                                    history={history}
                                    scrollToBottom={this.scrollToBottom}
                                />
                }
            break;
            case "Awaiting Payment":
                currentStep = 2;
                if(access.awaitingPayment) {
                    authorised = true;
                    tabs = [
                        {
                            label: 'Notes',
                            index: 0,
                            disabled: noNotes
                        },
                        {
                            label: 'Sales Order',
                            index: 1
                        },
                        {
                            label: 'Process Payment',
                            index: 2
                        }
                    ];
                    component = <AwaitingPayment 
                                    id={id} 
                                    key={uuid}
                                    callback={callback}
                                    contentHeight={ui.contentHeight}
                                    currentTab={currentTab}
                                    closeDialog={closeDialog}
                                    deployConfirmation={deployConfirmation}
                                    deployDialog={deployDialog}
                                    deploySnackBar={deploySnackBar} 
                                    order={order}
                                    handleButtonChange={this.handleButtonChange}
                                    handleTabChange={this.handleTabChange}
                                    history={history}
                                    scrollToTop={scrollToTop}
                                />
                }
            break;
            case "Awaiting Authorisation":
                currentStep = 3;
                if(access.awaitingAuthorisation) {
                    authorised = true;
                    tabs = [
                        {
                            label: 'Notes',
                            index: 0,
                            disabled: noNotes
                        },
                        {
                            label: 'Sales Order',
                            index: 1
                        },
                        {
                            label: 'Card Order',
                            index: 2
                        }
                    ];
                    component = <AwaitingAuthorisation 
                                    id={id} 
                                    key={uuid}
                                    callback={callback}
                                    contentHeight={ui.contentHeight}
                                    currentTab={currentTab}
                                    closeDialog={closeDialog}
                                    deployConfirmation={deployConfirmation}
                                    deployDialog={deployDialog}
                                    deploySnackBar={deploySnackBar} 
                                    order={order}
                                    handleButtonChange={this.handleButtonChange}
                                    handleTabChange={this.handleTabChange}
                                    history={history}
                                    scrollToTop={scrollToTop}
                                    staff={staff}
                                />
                }
            break;
            default: 
                currentStep = 4;
                if(access.processing) {
                    authorised = true;
                    tabs = [
                        {
                            label: 'Notes',
                            index: 0,
                            disabled: noNotes || currentStep === 5,
                        },
                        {
                            label: 'Sales Order',
                            index: 1,
                            disabled: currentStep === 5
                        },
                        {
                            label: 'Processing Option',
                            index: 5,
                            disabled: currentStep === 5 || processingType === 'CF' || !_.isEmpty(order.deliveries),
                            hide: processingType === 'CF' || !_.isEmpty(order.deliveries)
                        },
                        {
                            label: 'Fulfilment',
                            index: 2,
                            disabled: currentStep === 5 || !processingType
                        },
                        {
                            label: 'Deliveries',
                            index: 3,
                            disabled: currentStep === 5 || !processingType
                        },
                        {
                            label: 'Review',
                            index: 4,
                            disabled: currentStep === 5 || !processingType
                        },
                    ];
                    component = <Processing
                                    id={id} 
                                    key={uuid}
                                    access={access}
                                    callback={callback}
                                    contentHeight={ui.contentHeight}
                                    closeDialog={closeDialog}
                                    currentTab={currentTab}
                                    deployConfirmation={deployConfirmation} 
                                    deployDialog={deployDialog}
                                    deploySnackBar={deploySnackBar} 
                                    order={order}
                                    handleButtonChange={this.handleButtonChange}
                                    handleProcessingTypeChange={this.handleProcessingTypeChange}
                                    handleTabChange={this.handleTabChange}
                                    history={history}
                                    processingComplete={this.processingComplete}
                                    processingType={processingType}
                                    scrollToTop={scrollToTop}
                                    staff={staff}
                                />
                }
            break;
        }
        if(!authorised || currentStep === null) {

            switch(this.state.currentStep) {
                case 1:
                    history.push(`/sales/awaiting-approval`)
                break;
                case 2:
                case 3:
                default:
                    history.push(`/sales/${id}`)
                break;
            }

            return null;

        } else {
            
            if(this.state.currentStep !== 5) {
                if(this.state.currentStep !== currentStep || JSON.stringify(this.state.tabs) !== JSON.stringify(tabs)) {

                    if(isNaN(this.props.match?.params?.id) && this.props.match?.params?.id.includes(":")) {
                        let { id, currentTab }  =   this.state,
                            delivery            =   currentTab;

                        currentTab = _.findIndex(tabs, el => el.delivery === currentTab)
                        if(currentTab === -1) {
                            currentTab = noNotes ? 0 : 1;
                        }
                        this.setState({
                            currentTab,
                            currentStep,
                            delivery,
                            tabs
                        }, () => window.history.replaceState(null, "RED | Advanced Access", `/sales/process/${id}`))
                    } else {
                        currentTab    =   this.state.currentStep !== currentStep ? (noNotes ? (currentStep === 2 ? 2 : 1) : 0) : this.state.currentTab;
                        this.setState({
                            currentTab,
                            currentStep,
                            tabs
                        })
                    }

                }

            }

            return component;

        }

    }

    handleProcessingTypeChange = processingType => this.setState({ processingType });
    
    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            id={this.state.order?.order_id}
            relation="order"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
        />, true, "", "standard","sm", false, true);

    handleDeployAddCall = () => this.props.deployDialog(
        <AddCallLogForm
            id={this.state.order?.order_id}
            relation="order"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
        />, true, "", "standard","xs", false, true);

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.order?.order_id}
            relation="order"
            toggleDialog={() => { this.getData(false); this.props.closeDialog(); }}
            standardOnly
            withFao
        />, true, "", "standard","sm", false, true);


    handleDeployFileUpload = () => this.props.deployDialog(
        <DocumentForm 
            id={this.state.order?.order_id}
            type="order"
            category="Sales Orders"
            callback={() => this.getData(false)} 
            cancel={this.props.closeDialog} 
        />, false, "File Upload", "standard","xs", false, true);

    handleInProgressUser = () => {

        const {
            deployConfirmation,
            deploySnackBar
        } = this.props;

        const {
            order
        } = this.state;

        deployConfirmation(`Are you sure you want to ${order.order_processing_staff_id > 0 ? `remove the review flag from this sales order?` : `mark this sales order as under review?`}`, () => {
                
            let route = `/sales/${order.order_id}/processing/user/${order.order_processing_staff_id > 0 ? `clear` : `set`}`;
    
            API.put(route)
            .then(res => {
                if(res?.data?.success) {
                    this.setState({
                        order: {
                            ...this.state.order,
                            'order_processing_staff_id': res?.data?.staffId
                        }
                    }, () => {
                        deploySnackBar(`success`, `You have successfully ${order.order_processing_staff_id > 0 ? `cleared the review flag for this sales order` : `marked this sales order as under review`}`)
                    });
                }
            })

        })

    }

    handleTabChange = (currentTab, delivery = null, confirmation = false) => {

        let changeTab = true;

        if(!confirmation) {
            if(this.state.currentStep === 4) {
                if(currentTab !== 0 && currentTab !== 1 && currentTab !== 5 && (this.state.currentTab === 3 || this.state.currentTab === 4) && (currentTab !== 3 && currentTab !== 4)) {
                    changeTab = false;
                    this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to change fulfilment?</Typography><Typography variant="body2">You will lose any changes made to deliveries</Typography></>, () => this.handleTabChange(currentTab, this.state.delivery, true));
                }
                if(currentTab === 5 && this.state.processingType !== null) {
                    changeTab = false;
                    this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to change processing option?</Typography><Typography variant="body2">You will lose all changes made</Typography></>, () => this.handleTabChange(currentTab, this.state.delivery, true));
                }
            }
        }

        if(changeTab) {
            this.setState({
                currentTab,
                delivery
            }, () => {
                if(this.mainContentArea && this.mainContentArea.current) 
                    this.mainContentArea.current.scrollTop = 0;
            })
        }
    }

    processingComplete = () => {
        this.setState({
            currentStep: 5
        })
    }

    scrollToBottom = () => {
        this.containerBottom?.current?.scrollIntoView?.({ behavior: 'smooth' })
    }

    render() {        
        const { classes, history, staff, ui } = this.props;
        const { access, currentStep, currentTab, initialLoading, order, tabs } = this.state;     
        const canDial = this.context?.dial === null;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(initialLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            Sales Order #{order.order_reference}{!_.isEmpty(order.order_customer_reference) ? ` (${order.order_customer_reference})` : ''}
                                                        </Typography>  
                                                    </Grid>                                                    
                                                    {order?.order_phone?.length > 0 && canDial ? (
                                                        <Grid item>
                                                            <Tooltip title="Call Customer">
                                                                <IconButton onClick={() => this.context.useDial(order.order_phone)} size="small">
                                                                    <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={27.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                            {order.order_status === "Awaiting Approval" && (
                                                <Grid item>
                                                    <Tooltip title={order.order_processing_staff_id > 0 ? 'Clear Review Flag' : 'Mark as Under Review'} placement="top">
                                                        <IconButton onClick={() => this.handleInProgressUser()}>
                                                            <FAIcon type="light" icon={order.order_processing_staff_id > 0 ? 'user-slash' : 'user'} button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title="Add Action" placement="top">
                                                    <IconButton onClick={() => this.handleDeployAddTask()}>
                                                        <FAIcon type="light" icon="layer-plus" button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon type="light" icon="comment-alt-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addCallLog && (
                                                <Grid item>
                                                    <Tooltip title="Log Call" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddCall()}>
                                                            <FAIcon type="light" icon="phone-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.uploadDoc && (
                                                <Grid item>
                                                    <Tooltip title="Upload File" placement="top">
                                                        <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                            <FAIcon type="light" icon="file-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title="Download PDF" placement="top">
                                                    <IconButton onClick={() => order.order_legacy ? getLegacyOrderPDF(order.order_id) : getPresignedUrl(order.order_pdf_filename)}>
                                                        <FAIcon type="light" icon="file-pdf" style={{color:'#d32f2f'}} button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {/* {access.viewCustomer && (
                                                <Grid item>
                                                    <Tooltip title="View Customer" placement="top">
                                                        <IconButton onClick={() => history.push(`/customers/${order.order_customer_id}`)}>
                                                            <FAIcon type="light" icon="user" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )} */}
                                            <Grid item>
                                                <Tooltip title="View Order" placement="top">
                                                    <IconButton onClick={() => history.push(`/sales/${order.order_id}`)}>
                                                        <FAIcon type="light" icon="binoculars" button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "layer-plus",
                                                            label: "Add Action",
                                                            onClick: () => this.handleDeployAddTask(),
                                                            display: true
                                                        },
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote
                                                        },
                                                        {
                                                            icon: "phone-plus",
                                                            label: "Log Call",
                                                            onClick: () => this.handleDeployAddCall(),
                                                            display: access.addCallLog
                                                        },
                                                        {
                                                            icon: "file-plus",
                                                            label: "Upload File",
                                                            onClick: () => this.handleDeployFileUpload(),
                                                            display: access.uploadDoc
                                                        },
                                                        {
                                                            icon: "file-pdf",
                                                            label: "Download PDF",
                                                            onClick: () => order.order_legacy ? getLegacyOrderPDF(order.order_id) : getPresignedUrl(order.order_pdf_filename),
                                                            display: true,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: order.order_processing_staff_id > 0 ? 'user-slash' : 'user',
                                                            label: order.order_processing_staff_id > 0 ? 'Clear Review Flag' : 'Mark as Under Review',
                                                            onClick: () => this.handleInProgressUser(),
                                                            display: order.order_status === "Awaiting Approval"
                                                        },
                                                        // {
                                                        //     icon: "binoculars",
                                                        //     label: "View Customer",
                                                        //     onClick: () => this.props.history.push(`/customers/${order.order_customer_id}`),
                                                        //     display: access.viewCustomer
                                                        // },
                                                        {
                                                            icon: "binoculars",
                                                            label: "View Sales Order",
                                                            onClick: () => this.props.history.push(`/sales/${order.order_id}`),
                                                            display: true
                                                        }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={0.5}>
                                                <Grid container justify="space-between" alignItems='center'>
                                                    <Grid item xs={12} lg={8}>
                                                        <Stepper activeStep={currentStep} classes={{root: classes.stepper}} connector={<RedStepConnector />}>
                                                            {this.getSteps().map(label => (
                                                                <Step key={label}>
                                                                    <StepLabel classes={{label: classes.stepLabel}} StepIconComponent={RedStepIcon}>{label}</StepLabel>
                                                                </Step>
                                                            ))}
                                                        </Stepper>
                                                    </Grid>
                                                </Grid> 
                                            </Box>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>   
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    {order.order_status === "Awaiting Approval" && order.order_processing_staff_id > 0 && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <strong>Important -</strong> This Sales Order is currently under review by {staff?.[order.order_processing_staff_id]?.name}
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                    {currentStep === 4 && order?.order_requested_delivery_date !== "0000-00-00" && (
                                        <Grid item xs={12}>
                                             <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <Typography variant="body2" className="fw-400">
                                                        Important - This sales order has a customer requested date of <span className="fw-600">{moment(order?.order_requested_delivery_date).format('DD/MM/YYYY')}</span>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <em>Warehouse shipments will be available for picking on this date; or on a future date if set within the Deliveries tab.</em>
                                                    </Typography>
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                    {currentStep === 4 && order?.delivery_carriage?.courier_conf_default !== 1 && order?.delivery_carriage?.courier_conf_free_default !== 1 && (
                                        <Grid item xs={12}>
                                             <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <Typography variant="body2" className="fw-400">
                                                        Important - The delivery method for this sales order is <span className="fw-600">{order?.delivery_carriage?.courier?.courier_name} {order?.delivery_carriage?.courier_conf_service} ({order.order_total_carriage === "0.00" ? 'Free' : currencyFormat.format(order.order_total_carriage)})</span>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                    <em>Use the Review tab to configure Purchase Order carriage methods to match this delivery method (if applicable).</em>
                                                    </Typography>
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel>
                                                {this.getComponent()}
                                                <div ref={this.containerBottom} />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{position: 'relative', height: (contentHeight - 90)}}>
                                <Tabs
                                    classes={classes}
                                    currentTab={currentTab}
                                    order={order}
                                    tabs={tabs}
                                    currentStep={currentStep}
                                    handleTabChange={this.handleTabChange}
                                />
                                <Box style={{marginTop: _.size(order.deliveries) > 5 ? 24 : undefined, position: _.size(order.deliveries) > 5 ? 'relative' : 'absolute', bottom: 24}}>
                                    <Details
                                        classes={classes}
                                        history={this.props.history}
                                        order={order}
                                    />
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        staff: state.notifications.status,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, yes, no, yesText = null, noText = null) => dispatch(deployConfirmation(message, yes, no, yesText, noText)), 
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, de = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, de)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ProcessSalesOrder));