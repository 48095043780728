import React  from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { handleChange, validateEmail } from 'Functions/FormFunctions';

const initialState = () => ({
    confirmation: {
        cancel: false
    },
    formData: {
        id: '',
        emailAddress: '',
    },
    formErrors: {}
})

class InvoiceEmailDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount = () => {
        this.configureComponent();
    }
   
    componentDidUpdate = prevProps => {
        if(JSON.stringify(this.props.formData) !== JSON.stringify(prevProps.formData)) {
            this.configureComponent();
        }
    }

    configureComponent = () => {
        const { formData, formErrors } = this.props;
        this.setState({
            formData: {
                ...this.state.formData,
                ...(formData || {}),
            },
            formErrors: formErrors ?? initialState().formErrors
        })
    }

    cancel = () => {
        this.props.handleCancel(true)
    }
    
    submit = () => {
        this.props.handleSubmit(this.state.formData)
    }

    render() {
        const { open } = this.props;
        const { formData } = this.state;
        const emailNotValid = formData.emailAddress !== '' && formData.emailAddress?.includes('@') ? !validateEmail(formData.emailAddress) : false;
        return (
            <Dialog
                disableEscapeKeyDown
                disableBackdropClick
                open={open}
                maxWidth='sm' 
                scroll="body"
            >               
                <DialogTitle disableTypography>
                    <Typography variant="h6">
                        {this.props.formData?.emailAddress !== '' ? 'Update' : 'New'} Copy {this.props.forPurchaseOrder ? `Purchase Order` : `Invoice`} E-mail Address
                    </Typography>
                </DialogTitle>
                <DialogContent style={{padding: '12px 24px 24px 24px', minWidth: 400, maxWidth: 500}}>
                    <form noValidate autoComplete="off" onSubmit={this.submit}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    id="emailAddress"
                                    name="emailAddress"
                                    label="E-mail Address"
                                    value={formData.emailAddress}
                                    error={emailNotValid}
                                    helperText={emailNotValid ? 'Please enter a valid e-mail address' : undefined}                                               
                                    onChange={this.handleChange}
                                    margin="none"
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button 
                                    onClick={this.cancel} 
                                    variant="text" 
                                    color="primary"
                                >
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                                <Button 
                                    disabled={formData?.emailAddress === '' || !validateEmail(formData.emailAddress)} 
                                    variant="text" 
                                    type="submit" 
                                    color="primary"
                                >
                                    <FAIcon icon="check" size={15} button />
                                    {this.props.formData?.emailAddress !== '' ? 'Update' : 'Add'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>      
        )
    }
}

export default InvoiceEmailDialog;