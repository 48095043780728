import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    formData: {
        reason: '',
    },
    updateData: {
        id: '',
        reason: '',
    },
    formErrors:[],
    updateErrors: [],
    dialogOpen: false,
    rows: [],
    confirmationOpen: {
        submit: false,
        update: false,
    },
    snackbarOpen: {
        submit: false,
        update: false,
    },
    isLoading: true,
    updateLoading: true
}

class QuoteReasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getQuoteReasons();
    }

    submit = () => {
        API.post('/quotes/cancellation/reasons', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        update: false,
                        submit: true
                    }
                });
                this.getQuoteReasons();
            }
            this.props.scrollToTop();
        });
    }

    update = () => {
        API.put('/quotes/cancellation/reasons/'+this.state.updateData.id, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        update: true,
                        submit: false
                    }
                });
                this.getQuoteReasons();
            }
            this.props.scrollToTop();
        });
    }

    getQuoteReasons = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/quotes/cancellation/reasons')
            .then((result) => {
                this.setState({
                    ...this.state,
                    rows: result.data,
                    isLoading: false
                });
            })
        })
    }
    
    getUpdateData = () => {
        API.get('/quotes/cancellation/reasons/'+this.state.updateData.id)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose();
            } else {                
                this.setState({
                    updateLoading: false,
                    updateErrors: [],
                    updateData: {
                        ...this.state.updateData,
                        reason: result.data.qr_reason,
                    }
                });
            }
        })
    }
    
    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    
    handleUpdateChange = e => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                [e.target.name]: e.target.value
            }
        });
    }
    
    handleSnackbarClose = (type) => {
        this.setState({ 
            snackbarOpen: {
            ...this.state.snackbarOpen,
            [type]: false,
            } 
        });
    };

    handleConfirmationOpen = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: true,
            } 
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        }, () => {
            if(type === 'submit') {
                this.submit();
            } else {
                this.update();
            }
        });
    }

    handleDialogOpen = (id) => {
        this.setState({
            dialogOpen: true,
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                id
            }
        }, () => this.getUpdateData());
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpen: false,
            updateLoading: true,
            updateData: {
                ...initialState.updateData
            }
        });
    }

    render() {
        const { formData, formErrors, isLoading, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Lost Opportunities
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'qr_id',
                                pagination: true,
                                isLoading: isLoading,
                                plainPagination: true,
                                options: {
                                    minimalPadding: true
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Reason',
                                    field: rowData => rowData.qr_reason,
                                    main: true
                                },      
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil',  onClick: () => this.handleDialogOpen(rowData.qr_id)},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.rows}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Lost Opportunity
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                name="reason"
                                label="Reason *"
                                value={formData.reason}
                                error={formErrors && formErrors['reason'] && true}
                                helperText={formErrors && formErrors['reason']}
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                            <div className='buttonRow'>
                                <Button 
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="text" 
                                    color="primary" 
                                    disabled={!this.state.formData.reason}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Add
                                </Button>
                            </div>
                        </form>
                        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={this.state.dialogOpen} onClose={this.handleDialogClose} fullWidth={true} maxWidth="sm">
                            <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Lost Opportunity</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            name="reason"
                                            label="Reason *"
                                            value={updateData.reason}
                                            error={updateErrors && updateErrors['reason'] && true}
                                            helperText={updateErrors && updateErrors['reason']}
                                            onChange={this.handleUpdateChange}
                                            margin="none"
                                            fullWidth
                                        />
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text"><FAIcon icon="check" size={15} button />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            title="Update lost opportunity reason?" 
                            message="Are you sure you want to update this lost opportunity reason?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title="Add a new lost opportunity reason?" 
                            message="Are you sure you want to add a new lost opportunity reason?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the lost opportunity reason'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new lost opportunity reason'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

export default QuoteReasons;