import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import uuidv4 from "uuid/v4";

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Switch from "@material-ui/core/Switch";
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Divider } from '@material-ui/core';
import Alert from 'Components/Common/Alert/Alert';
import InsightChip from 'Components/Common/Chips/InsightChip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ProductSearchSelector from 'Components/Products/Misc/ProductSearchSelector';
import { isNumeric } from 'Functions/MiscFunctions';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import API from 'API';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      flexBasis: '33.33%',
      color: '#000',
      fontWeight: 400,
      flexShrink: 0,
    },
    secondaryHeading: {
      lineHeight: 2,
      fontSize: theme.typography.pxToRem(15),
      color: '#333',
      flexGrow: 1
    },
}));

const initialState = () => ({
    delivery: false,
    all: false,
    templates: false,
    category: false,
    supplier: false,
    brand: false,
    range: false,
    product: false,
})

const DiscountConfiguration = ({
    formData,
    formErrors,
    lists,
    innerKey,
    handleSelectChange,
    handleDiscountChange,
    handleDiscountPercentChange,
    handleDiscountPercentFloat,
    handleDiscountTemplateChange,
    handleNumberChange,
    handleDiscountAddRow,
    handleDiscountRemoveRow,
    handleRemoveDiscount,
    handleDiscountMinQtyChange,
    handleDiscountMinQtyFloat,
    handleFloat,
    isCustomerForm,
    setState
}) => {

    const classes = useStyles();

    const [expanded, setExpanded] = useState(initialState());

    const dispatch = useDispatch();

    const _deployConfirmation = (...props) => dispatch(deployConfirmation(...props));

    const handleAccordionChange = panel => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    }

    const handleTestDiscountRow = (discountData) => {
        API.post('/products/discounts/test', { ...discountData })
        .then(res => {
            if(res?.data) {
                let text;
                if(res.data.length > 0) {
                    text = (
                        <>
                            <Typography variant="body2" className="textDefault fw-400">
                                The following are discountable with this discount configuration:
                            </Typography>
                            <ul className="textDefault fw-300 pt-05">
                                {res.data.map(product => (
                                    <li key={product.i}>{product.c}</li>
                                ))}
                            </ul>
                        </>
                    );
                } else {
                    text = (
                        <Typography variant="body2" className="textDefault">
                            No products are discountable with this discount configuration.
                        </Typography>
                    );
                }

                dispatch(deployConfirmation(text, false, false, 'OK', null, true));
            }
        });
    }

    const hasExclusions = discountData => {
        return Object.keys(discountData).some(key => Array.isArray(discountData[key]) && discountData[key].length > 0);
    }

    const isInclusion = discountData => {
        return discountData?.isInclusion === 1;
    }
    
    const discountPriorityAlert = () => (
        <Grid item xs={12}>
                <Alert severity="info" className="content-light-white">
                    <Typography variant="body1" className="textDefault fw-400">
                        Discount Priority
                    </Typography>
                    <Typography variant="body2" className="textDefault fw-300 pt-05">
                        The first discount that applies to a product will be applied, based on the following order:
                    </Typography>
                    <Typography variant="body2" className="textDefault fw-300 pt-05">
                        Product Discounts
                        &#8594; Range Discounts
                        &#8594; Brand Discounts
                        &#8594; Supplier Discounts
                        &#8594; Category Discounts
                        &#8594; Across Board Discount
                    </Typography>
                </Alert>
            </Grid>
    )

    return (
        <Grid container spacing={3}>
            {isCustomerForm ? discountPriorityAlert() : null}
            <Grid item xs={12}>
                <div className={classes.root}>
                    {isCustomerForm && (
                        <>
                            <ExpansionPanel 
                                expanded={expanded.delivery} 
                                onChange={handleAccordionChange('delivery')}
                                TransitionProps={{ unmountOnExit: true }}
                            >
                                <ExpansionPanelSummary expandIcon={<FAIcon icon="chevron-down" button />}>
                                    <Typography className={classes.heading}>
                                        Free Delivery
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        {!_.isEmpty(formData.focShipping) ? (formData.focShipping === "0.00" || formData.focShipping === "0" ? `All sales orders are eligible for free delivery`: `Sales orders with a net total over £${parseFloat(formData.focShipping).toFixed(2)} are eligible for free delivery`) : 'No sales orders are eligible for free delivery'}
                                        {formData.focShippingMethod !== '' && formData.focShippingMethod > 0 && ` via ${_.find(lists.delivery, { value: formData.focShippingMethod })?.label ?? ''}`}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <Box width={250}>
                                                        <TextField
                                                            id="focShipping"
                                                            name="focShipping"
                                                            label="Free Delivery Spend Threshold"
                                                            value={formData.focShipping}
                                                            onChange={handleNumberChange}
                                                            onBlur={e => { if(formData.focShipping < 0) { setState({formData: {...formData, focShipping: ""}}) } else { if(!_.isEmpty(formData.focShipping)) { handleFloat(e) } }}}
                                                            margin="none"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size={15} noMargin /></InputAdornment>
                                                            }}
                                                            inputProps={{
                                                                step: 1,
                                                                min: 0
                                                            }}
                                                            type="number"
                                                            fullWidth
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box width={375}>
                                                        <AutoCompleteSelect
                                                            key={uuidv4()} 
                                                            options={lists.delivery}
                                                            label='Free Delivery Method'
                                                            value={formData.focShippingMethod}
                                                            onChange={v => handleSelectChange('focShippingMethod', v)}
                                                            error={formErrors?.['focShippingMethod'] && true}
                                                            errorText={formErrors?.['focShippingMethod']}
                                                            placeholder="Default Free Delivery Method"
                                                            adornment="truck"
                                                            margin
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel 
                                expanded={expanded.all} 
                                onChange={handleAccordionChange('all')}
                                TransitionProps={{ unmountOnExit: true }}
                            >
                                <ExpansionPanelSummary expandIcon={<FAIcon icon="chevron-down" button />}>
                                    <Typography className={classes.heading}>
                                        Across Board Discount
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        {formData?.discounts?.all?.percent !== '' ? formData?.discounts?.all?.percent !== "0.00" ? `${formData?.discounts?.all?.percent}% across the board discount ${hasExclusions(formData?.discounts?.all) ? ` with ${isInclusion(formData?.discounts?.all) ? `inclusions` : ` exclusions`}` : ``}` : 'No across board discount' : <span className="textError"><FAIcon icon="exclamation-triangle" size={15} />Please enter the across board discount percentage</span>}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={3} alignItems='flex-start'>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Box width={150} mb={-1.7}>
                                                        <TextField
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end"><FAIcon type="light" className={formData?.discounts?.all?.percent === '' ? 'textError fw-400' : undefined} icon={formData?.discounts?.all?.percent === '' ? 'exclamation-triangle' : 'percent'} size={formData?.discounts?.all?.percent === '' ? 17.5 : 15} noMargin /></InputAdornment>
                                                            }}
                                                            inputProps={{
                                                                step: 0.01,
                                                                min: 0,
                                                                max: 100,
                                                            }}
                                                            label='Discount Percent *'
                                                            margin="none"
                                                            onBlur={() => handleDiscountPercentFloat('all', 0)}
                                                            onChange={e => handleDiscountPercentChange('all', 0, e?.target?.value ?? 0)}
                                                            type="number"
                                                            value={formData?.discounts?.all?.percent}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs align="right">
                                                    <Tooltip title='Check Applicable Products'>
                                                        <IconButton
                                                            disabled={formData?.discounts?.all?.percent === '' || formData?.discounts?.all?.percent === '0.00'}
                                                            onClick={() => handleTestDiscountRow(formData?.discounts?.all)}
                                                        >
                                                            <FAIcon icon='search' button noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>                                       
                                        {formData?.discounts?.all?.percent !== "0.00" && (
                                            <>
                                                <Grid item xs={12} lg={6} xl={3}>
                                                    <Box p={2} className="content-light-error">
                                                        <Box>
                                                            <Typography variant="caption" color="textSecondary">
                                                                Exclude Category
                                                            </Typography>
                                                            <Box mt={-1.5} mb={-0.5}>
                                                                <AutoCompleteSelect
                                                                    key={uuidv4()} 
                                                                    options={lists.categories}
                                                                    isOptionDisabled={option => _.some(formData.discounts?.all?.excludedCategories, el => el.value === option.value || el.value === option.parent)}
                                                                    onChange={v => handleDiscountChange('all', 'excludedCategories', 0, v ?? '')}
                                                                    value={null}
                                                                    isGrouped
                                                                    placeholder={`Select Category to Exclude`}
                                                                    noClear
                                                                    shrink
                                                                />
                                                            </Box>
                                                        </Box>
                                                        {_.map(formData.discounts?.all?.excludedCategories, (cat, cidx) => (
                                                            <InsightChip
                                                                icon="boxes-alt"
                                                                key={cidx}
                                                                label={cat.label}
                                                                onDelete={() => handleRemoveDiscount('all', 'excludedCategories', 0, cidx)}
                                                                marginRight={12}
                                                            />
                                                        ))}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={6} xl={3}>
                                                    <Box p={2} className="content-light-error">
                                                        <Box>
                                                            <Typography variant="caption" color="textSecondary">
                                                                Exclude Supplier
                                                            </Typography>
                                                            <Box mt={-1.5} mb={-0.5}>
                                                                <AutoCompleteSelect
                                                                    key={uuidv4()} 
                                                                    options={lists.suppliers}
                                                                    isOptionDisabled={option => _.some(formData.discounts?.all?.excludedSuppliers, el => el.value === option.value)}
                                                                    onChange={v => handleDiscountChange('all', 'excludedSuppliers', 0, v ?? '')}
                                                                    value={null}
                                                                    noClear
                                                                    shrink
                                                                />
                                                            </Box>
                                                        </Box>
                                                        {_.map(formData.discounts?.all?.excludedSuppliers, (supp, ridx) => (
                                                            <InsightChip
                                                                icon="boxes-alt"
                                                                key={ridx}
                                                                label={supp.label}
                                                                onDelete={() => handleRemoveDiscount('all', 'excludedSuppliers', 0, ridx)}
                                                                marginRight={12}
                                                            />
                                                        ))}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={6} xl={3}>
                                                    <Box p={2} className="content-light-error">
                                                        <Box>
                                                            <Typography variant="caption" color="textSecondary">
                                                                Exclude Brand
                                                            </Typography>
                                                            <Box mt={-1.5} mb={-0.5}>
                                                                <AutoCompleteSelect
                                                                    key={uuidv4()} 
                                                                    options={lists.brands}
                                                                    isOptionDisabled={option => _.some(formData.discounts?.all?.excludedBrands, el => el.value === option.value)}
                                                                    onChange={v => handleDiscountChange('all', 'excludedBrands', 0, v ?? '')}
                                                                    value={null}
                                                                    noClear
                                                                    shrink
                                                                />
                                                            </Box>
                                                        </Box>
                                                        {_.map(formData.discounts?.all?.excludedBrands, (brand, bidx) => (
                                                            <InsightChip
                                                                icon="boxes-alt"
                                                                key={bidx}
                                                                label={brand.label}
                                                                onDelete={() => handleRemoveDiscount('all', 'excludedBrands', 0, bidx)}
                                                                marginRight={12}
                                                            />
                                                        ))}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={6} xl={3}>
                                                    <Box p={2} className="content-light-error">
                                                        <Box>
                                                            <Typography variant="caption" color="textSecondary">
                                                                Exclude Range
                                                            </Typography>
                                                            <Box mt={-1.5} mb={-0.5}>
                                                                <AutoCompleteSelect
                                                                    key={uuidv4()} 
                                                                    options={lists.ranges}
                                                                    isOptionDisabled={option => _.some(formData.discounts?.all?.excludedRanges, el => el.value === option.value)}
                                                                    onChange={v => handleDiscountChange('all', 'excludedRanges', 0, v ?? '')}
                                                                    value={null}
                                                                    isGrouped
                                                                    noClear
                                                                    shrink
                                                                />
                                                            </Box>
                                                        </Box>
                                                        {_.map(formData.discounts?.all?.excludedRanges, (rng, ridx) => (
                                                            <InsightChip
                                                                icon="boxes-alt"
                                                                key={ridx}
                                                                label={rng.label}
                                                                onDelete={() => handleRemoveDiscount('all', 'excludedRanges', 0, ridx)}
                                                                marginRight={12}
                                                            />
                                                        ))}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={12} xl={6}>
                                                    <Box p={2} className="content-light-error">
                                                        <Box mb={1.5}>
                                                            <ProductSearchSelector
                                                                basic
                                                                label="Exclude Product"
                                                                handleAction={v => handleDiscountChange('all', 'excludedProducts', 0, v ?? '')}
                                                                hideStock
                                                                key={innerKey.all}
                                                            />
                                                        </Box>
                                                        {_.map(formData.discounts?.all?.excludedProducts, (prd, pidx) => (
                                                            <InsightChip
                                                                icon="box-check"
                                                                key={pidx}
                                                                label={prd.label}
                                                                onDelete={() => handleRemoveDiscount('all', 'excludedProducts', 0, pidx)}
                                                                marginRight={12}
                                                            />
                                                        ))}
                                                    </Box>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel 
                                expanded={expanded.templates} 
                                onChange={handleAccordionChange('templates')}
                                TransitionProps={{ unmountOnExit: true }}
                            >
                                <ExpansionPanelSummary expandIcon={<FAIcon icon="chevron-down" button />}>
                                    <Typography className={classes.heading}>
                                        Discount Templates
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        {_.size(formData.discountTemplates) > 0 ? `${_.size(formData.discountTemplates)} templates applied` : '-'}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={4} xl={3}>
                                            <AutoCompleteSelect
                                                key={uuidv4()}
                                                label="Apply Discount Template" 
                                                options={lists.discountTemplates}
                                                isOptionDisabled={option => formData?.discountTemplates?.includes?.(option.value) || option?.disabled}
                                                onChange={v => handleDiscountTemplateChange(v?.value)}
                                                value={null}
                                                noMargin
                                                noClear
                                                shrink
                                            />
                                        </Grid>
                                        {!_.isEmpty(formData.discountTemplates) && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" gutterBottom>
                                                    Applied Discount Templates
                                                </Typography>
                                                <Grid container>
                                                    {_.map(_.filter(lists.discountTemplates, el => formData.discountTemplates?.includes?.(el.value)), (d, idx) => (
                                                        <Grid item xs={12} key={idx}>
                                                            <InsightChip
                                                                icon="check-circle"
                                                                label={d.label}
                                                                onDelete={() => handleDiscountTemplateChange(d.value)}
                                                                marginRight={12}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </>
                    )}
                    <ExpansionPanel 
                        expanded={expanded.category} 
                        onChange={handleAccordionChange('category')}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <ExpansionPanelSummary expandIcon={<FAIcon icon="chevron-down" button />}>
                            <Typography className={classes.heading}>
                                Category Discount
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                {_.some(formData.discounts.category, el => el.percent === '' || el.category === '') ? <span className="textError"><FAIcon icon="exclamation-triangle" size={15} /> Some discounts are not configured correctly</span> : _.isEmpty(formData.discounts.category) ? '-' : `${_.size(formData.discounts.category)} category discounts`}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Box>
                                {!_.isEmpty(formData.discounts.category) && (
                                    <Grid container spacing={3} className="mb-2">
                                        {_.map(formData.discounts.category, (disc, idx) => {
                                            const label = disc?.isInclusion === 1 ? 'Only where' : 'Exclude',
                                                className = disc?.isInclusion === 1 ? 'content-light-success' : 'content-light-error';
                                            return (
                                                <Grid item xs={12} key={idx}>
                                                    <Grid container spacing={3} alignItems='flex-start' className="mb-2">
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3} alignItems='center' justify='space-between'>
                                                                <Grid item>
                                                                    <Box width={150} mb={-1.7}>
                                                                        <TextField
                                                                            fullWidth
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="end"><FAIcon type="light" className={disc.percent === '' ? 'textError fw-400' : undefined} icon={disc.percent === '' ? 'exclamation-triangle' : 'percent'} size={disc.percent === '' ? 17.5 : 15} noMargin /></InputAdornment>
                                                                            }}
                                                                            inputProps={{
                                                                                step: 0.01,
                                                                                min: 0,
                                                                                max: 100,
                                                                            }}
                                                                            label='Discount Percent *'
                                                                            margin="none"
                                                                            onBlur={() => handleDiscountPercentFloat('category', idx)}
                                                                            onChange={e => handleDiscountPercentChange('category', idx, e?.target?.value ?? 0)}
                                                                            type="number"
                                                                            value={disc.percent}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Box width={350} mb={-1.7}>
                                                                        <AutoCompleteSelect
                                                                            key={uuidv4()} 
                                                                            label='Category *'
                                                                            options={lists.categories}
                                                                            // isOptionDisabled={option => _.some(formData.discounts?.category, el => el.category === option.value || el.category === option.parent)}
                                                                            onChange={v => handleDiscountChange('category', 'category', idx, v ?? '')}
                                                                            value={disc.category}
                                                                            isGrouped
                                                                            noClear
                                                                            shrink
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={disc?.isInclusion === 1} onChange={() => handleDiscountChange('category', 'isInclusion', idx, disc?.isInclusion === 1 ? 0 : 1)} value="1" name="isInclusion" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">Configure as inclusion</Typography>}
                                                                        disabled={disc?.excludedProducts?.length > 0}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={disc?.incNoDiscount === 1} onChange={() => handleDiscountChange('category', 'incNoDiscount', idx, disc?.incNoDiscount === 1 ? 0 : 1)} value="1" name="incNoDiscount" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">Include non-discountable products</Typography>}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title='Check Applicable Products'>
                                                                        <IconButton
                                                                            disabled={!isNumeric(disc.category)}
                                                                            onClick={() => handleTestDiscountRow(disc)}
                                                                        >
                                                                            <FAIcon icon='search' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title='Delete Discount'>
                                                                        <IconButton
                                                                            onClick={() => _deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to remove this discount?</Typography></>, () => handleDiscountRemoveRow('category', idx))}
                                                                        >
                                                                            <FAIcon icon='trash-alt' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {isNumeric(disc.category) && (
                                                            <>
                                                                {disc.isParent && (
                                                                    <Grid item xs={12} lg={6} xl={3}>
                                                                        <Box p={1} className={className}>
                                                                            <Box>
                                                                                <Typography variant="caption" color="textSecondary">
                                                                                    {label} Sub-Category
                                                                                </Typography>
                                                                                <Box mt={-1.5} mb={-0.5}>
                                                                                    <AutoCompleteSelect
                                                                                        key={uuidv4()} 
                                                                                        options={_.filter(_.find(lists.categories, el => el.value === disc.category)?.options ?? [], _el => _el.value !== disc.category)}
                                                                                        isOptionDisabled={option => _.some(disc.excludedCategories, el => el.value === option.value)}
                                                                                        onChange={v => handleDiscountChange('category', 'excludedCategories', idx, v ?? '')}
                                                                                        value={null}
                                                                                        noClear
                                                                                        shrink
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                            {_.map(disc.excludedCategories, (cat, cidx) => (
                                                                                <InsightChip
                                                                                    icon="boxes-alt"
                                                                                    key={cidx}
                                                                                    label={cat.label}
                                                                                    onDelete={() => handleRemoveDiscount('category', 'excludedCategories', idx, cidx)}
                                                                                    marginRight={12}
                                                                                />
                                                                            ))}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={12} lg={6} xl={3}>
                                                                    <Box p={1} className={className}>
                                                                        <Box>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                {label} Supplier
                                                                            </Typography>
                                                                            <Box mt={-1.5} mb={-0.5}>
                                                                                <AutoCompleteSelect
                                                                                    key={uuidv4()} 
                                                                                    options={lists.suppliers}
                                                                                    isOptionDisabled={option => _.some(disc.excludedSuppliers, el => el.value === option.value)}
                                                                                    onChange={v => handleDiscountChange('category', 'excludedSuppliers', idx, v ?? '')}
                                                                                    value={null}
                                                                                    noClear
                                                                                    shrink
                                                                                />
                                                                                
                                                                            </Box>
                                                                        </Box>
                                                                        {_.map(disc.excludedSuppliers, (supp, sidx) => (
                                                                            <InsightChip
                                                                                icon="boxes-alt"
                                                                                key={sidx}
                                                                                label={supp.label}
                                                                                onDelete={() => handleRemoveDiscount('category', 'excludedSuppliers', idx, sidx)}
                                                                                marginRight={12}
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12} lg={3}>
                                                                    <Box p={1} className={className}>
                                                                        <Box>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                {label} Brand
                                                                            </Typography>
                                                                            <Box mt={-1.5} mb={-0.5}>
                                                                                <AutoCompleteSelect
                                                                                    key={uuidv4()}
                                                                                    options={lists.brands}
                                                                                    isOptionDisabled={option => _.some(disc.excludedBrands, el => el.value === option.value)}
                                                                                    onChange={v => handleDiscountChange('category', 'excludedBrands', idx, v ?? '')}
                                                                                    value={null}
                                                                                    noClear
                                                                                    shrink
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        {_.map(disc.excludedBrands, (brand, bidx) => (
                                                                            <InsightChip
                                                                                icon="boxes-alt"
                                                                                key={bidx}
                                                                                label={brand.label}
                                                                                onDelete={() => handleRemoveDiscount('category', 'excludedBrands', idx, bidx)}
                                                                                marginRight={12}
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12} lg={3}>
                                                                    <Box p={1} className={className}>
                                                                        <Box>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                {label} Range
                                                                            </Typography>
                                                                            <Box mt={-1.5} mb={-0.5}>
                                                                                <AutoCompleteSelect
                                                                                    key={uuidv4()} 
                                                                                    options={lists.ranges}
                                                                                    isOptionDisabled={option => _.some(disc.excludedRanges, el => el.value === option.value)}
                                                                                    onChange={v => handleDiscountChange('category', 'excludedRanges', idx, v ?? '')}
                                                                                    value={null}
                                                                                    noClear
                                                                                    shrink
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        {_.map(disc.excludedRanges, (rng, ridx) => (
                                                                            <InsightChip
                                                                                icon="boxes-alt"
                                                                                key={ridx}
                                                                                label={rng.label}
                                                                                onDelete={() => handleRemoveDiscount('category', 'excludedRanges', idx, ridx)}
                                                                                marginRight={12}
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                </Grid>
                                                                {disc?.isInclusion !== 1 && (
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box p={1} className={className}>
                                                                            <Box mb={1.5}>
                                                                                <ProductSearchSelector
                                                                                    basic
                                                                                    label={`${label} Product`}
                                                                                    handleAction={v => handleDiscountChange('category', 'excludedProducts', idx, v ?? '')}
                                                                                    hideStock
                                                                                    category={disc.category}
                                                                                    key={innerKey.category}
                                                                                />
                                                                            </Box>
                                                                            {_.map(disc.excludedProducts, (prd, pidx) => (
                                                                                <InsightChip
                                                                                    icon="box-check"
                                                                                    key={pidx}
                                                                                    label={prd.label}
                                                                                    onDelete={() => handleRemoveDiscount('category', 'excludedProducts', idx, pidx)}
                                                                                    marginRight={12}
                                                                                />
                                                                            ))}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Divider />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                                <Box>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="text"
                                        size="large"
                                        className="content-light-grey content-light-error-clickable"
                                        onClick={() => handleDiscountAddRow('category')}
                                    >
                                        <FAIcon icon="plus-circle" size={15} button />
                                        Additional Category Discount
                                    </Button>
                                </Box>
                            </Box>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel 
                        expanded={expanded.supplier} 
                        onChange={handleAccordionChange('supplier')}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <ExpansionPanelSummary expandIcon={<FAIcon icon="chevron-down" button />}>
                            <Typography className={classes.heading}>
                                Supplier Discount
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                {_.some(formData.discounts.supplier, el => el.percent === '' || el.supplier === '') ? <span className="textError"><FAIcon icon="exclamation-triangle" size={15} /> Some discounts are not configured correctly</span> : _.isEmpty(formData.discounts.supplier) ? '-' : `${_.size(formData.discounts.supplier)} supplier discounts`}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Box>
                                {!_.isEmpty(formData.discounts.supplier) && (
                                    <Grid container spacing={3} className="mb-2">
                                        {_.map(formData.discounts.supplier, (disc, idx) => {                                                                
                                            const label = disc?.isInclusion === 1 ? 'Only where' : 'Exclude',
                                                className = disc?.isInclusion === 1 ? 'content-light-success' : 'content-light-error';
                                            return (
                                                <Grid item xs={12} key={idx}>
                                                    <Grid container spacing={3} alignItems='flex-start' className="mb-2">
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3} alignItems='center' justify='space-between'>
                                                                <Grid item>
                                                                    <Box width={150} mb={-1.7}>
                                                                        <TextField
                                                                            fullWidth
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="end"><FAIcon type="light" className={disc.percent === '' ? 'textError fw-400' : undefined} icon={disc.percent === '' ? 'exclamation-triangle' : 'percent'} size={disc.percent === '' ? 17.5 : 15} noMargin /></InputAdornment>
                                                                            }}
                                                                            inputProps={{
                                                                                step: 0.01,
                                                                                min: 0,
                                                                                max: 100,
                                                                            }}
                                                                            label='Discount Percent *'
                                                                            margin="none"
                                                                            onBlur={() => handleDiscountPercentFloat('supplier', idx)}
                                                                            onChange={e => handleDiscountPercentChange('supplier', idx, e?.target?.value ?? 0)}
                                                                            type="number"
                                                                            value={disc.percent}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Box width={350} mb={-1.7}>
                                                                        <AutoCompleteSelect
                                                                            key={uuidv4()} 
                                                                            options={lists.suppliers}
                                                                            isOptionDisabled={option => _.some(formData.discounts?.supplier, el => el.supplier === option.value)}
                                                                            label='Supplier *'
                                                                            value={disc.supplier}
                                                                            onChange={v => handleDiscountChange('supplier', 'supplier', idx, v ?? '')}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={disc?.isInclusion === 1} onChange={() => handleDiscountChange('supplier', 'isInclusion', idx, disc?.isInclusion === 1 ? 0 : 1)} value="1" name="isInclusion" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">Configure as inclusion</Typography>}
                                                                        disabled={disc?.excludedProducts?.length > 0}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={disc?.incNoDiscount === 1} onChange={() => handleDiscountChange('supplier', 'incNoDiscount', idx, disc?.incNoDiscount === 1 ? 0 : 1)} value="1" name="incNoDiscount" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">Include non-discountable products</Typography>}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title='Check Applicable Products'>
                                                                        <IconButton
                                                                            disabled={!isNumeric(disc.supplier)}
                                                                            onClick={() => handleTestDiscountRow(disc)}
                                                                        >
                                                                            <FAIcon icon='search' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title='Delete Discount'>
                                                                        <IconButton
                                                                            onClick={() => _deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to remove this discount?</Typography></>, () => handleDiscountRemoveRow('supplier', idx))}
                                                                        >
                                                                            <FAIcon icon='trash-alt' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {isNumeric(disc.supplier) && (
                                                            <>
                                                                <Grid item xs={12} lg={6} xl={3}>
                                                                    <Box p={1} className={className}>
                                                                        <Box>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                {label} Category
                                                                            </Typography>
                                                                            <Box mt={-1.5} mb={-0.5}>
                                                                                <AutoCompleteSelect
                                                                                    key={uuidv4()} 
                                                                                    options={lists.categories}
                                                                                    isOptionDisabled={option => _.some(disc.excludedCategories, el => el.value === option.value || el.value === option.parent)}
                                                                                    onChange={v => handleDiscountChange('supplier', 'excludedCategories', idx, v ?? '')}
                                                                                    value={null}
                                                                                    isGrouped
                                                                                    noClear
                                                                                    shrink
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        {_.map(disc.excludedCategories, (cat, cidx) => (
                                                                            <InsightChip
                                                                                icon="boxes-alt"
                                                                                key={cidx}
                                                                                label={cat.label}
                                                                                onDelete={() => handleRemoveDiscount('supplier', 'excludedCategories', idx, cidx)}
                                                                                marginRight={12}
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                </Grid>
                                                                {_.find(lists.brands, el => el.id === disc.supplier) && (
                                                                    <Grid item xs={12} lg={6} xl={3}>
                                                                        <Box p={1} className={className}>
                                                                            <Box>
                                                                                <Typography variant="caption" color="textSecondary">
                                                                                    {label} Brand
                                                                                </Typography>
                                                                                <Box mt={-1.5} mb={-0.5}>
                                                                                    <AutoCompleteSelect
                                                                                        key={uuidv4()} 
                                                                                        options={_.find(lists.brands, el => el.id === disc.supplier)?.options}
                                                                                        isOptionDisabled={option => _.some(disc.excludedBrands, el => el.value === option.value)}
                                                                                        onChange={v => handleDiscountChange('supplier', 'excludedBrands', idx, v ?? '')}
                                                                                        value={null}
                                                                                        noClear
                                                                                        shrink
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                            {_.map(disc.excludedBrands, (brand, bidx) => (
                                                                                <InsightChip
                                                                                    icon="boxes-alt"
                                                                                    key={bidx}
                                                                                    label={brand.label}
                                                                                    onDelete={() => handleRemoveDiscount('supplier', 'excludedBrands', idx, bidx)}
                                                                                    marginRight={12}
                                                                                />
                                                                            ))}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                                {_.find(lists.ranges, el => el.id === disc.supplier) && (
                                                                    <Grid item xs={12} lg={6} xl={3}>
                                                                        <Box p={1} className={className}>
                                                                            <Box>
                                                                                <Typography variant="caption" color="textSecondary">
                                                                                    {label} Range
                                                                                </Typography>
                                                                                <Box mt={-1.5} mb={-0.5}>
                                                                                    <AutoCompleteSelect
                                                                                        key={uuidv4()} 
                                                                                        options={_.find(lists.ranges, el => el.id === disc.supplier)?.options}
                                                                                        isOptionDisabled={option => _.some(disc.excludedRanges, el => el.value === option.value)}
                                                                                        onChange={v => handleDiscountChange('supplier', 'excludedRanges', idx, v ?? '')}
                                                                                        value={null}
                                                                                        noClear
                                                                                        shrink
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                            {_.map(disc.excludedRanges, (rng, ridx) => (
                                                                                <InsightChip
                                                                                    icon="boxes-alt"
                                                                                    key={ridx}
                                                                                    label={rng.label}
                                                                                    onDelete={() => handleRemoveDiscount('supplier', 'excludedRanges', idx, ridx)}
                                                                                    marginRight={12}
                                                                                />
                                                                            ))}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                                {disc?.isInclusion !== 1 && (
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box p={1} className={className}>
                                                                            <Box mb={1.5}>
                                                                                <ProductSearchSelector
                                                                                    basic
                                                                                    label={`${label} Product`}
                                                                                    handleAction={v => handleDiscountChange('supplier', 'excludedProducts', idx, v ?? '')}
                                                                                    hideStock
                                                                                    key={innerKey.supplier}
                                                                                    supplier={disc.supplier}
                                                                                />
                                                                            </Box>
                                                                            {_.map(disc.excludedProducts, (prd, pidx) => (
                                                                                <InsightChip
                                                                                    icon="box-check"
                                                                                    key={pidx}
                                                                                    label={prd.label}
                                                                                    onDelete={() => handleRemoveDiscount('supplier', 'excludedProducts', idx, pidx)}
                                                                                    marginRight={12}
                                                                                />
                                                                            ))}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>                                                    
                                                    <Divider />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                                <Box>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="text"
                                        size="large"
                                        className="content-light-grey content-light-error-clickable"
                                        onClick={() => handleDiscountAddRow('supplier')}
                                    >
                                        <FAIcon icon="plus-circle" size={15} button />
                                        Additional Supplier Discount
                                    </Button>
                                </Box>
                            </Box>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel 
                        expanded={expanded.brand} 
                        onChange={handleAccordionChange('brand')}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <ExpansionPanelSummary expandIcon={<FAIcon icon="chevron-down" button />}>
                            <Typography className={classes.heading}>
                                Brand Discount
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                {_.some(formData.discounts.brand, el => el.percent === '' || el.brand === '') ? <span className="textError"><FAIcon icon="exclamation-triangle" size={15} /> Some discounts are not configured correctly</span> : _.isEmpty(formData.discounts.brand) ? '-' : `${_.size(formData.discounts.brand)} brand discounts`}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Box>
                                {!_.isEmpty(formData.discounts.brand) && (
                                    <Grid container spacing={3} className="mb-2">
                                        {_.map(formData.discounts.brand, (disc, idx) => {
                                            const label = disc?.isInclusion === 1 ? 'Only where' : 'Exclude',
                                                className = disc?.isInclusion === 1 ? 'content-light-success' : 'content-light-error';
                                            return (
                                                <Grid item xs={12} key={idx}>                                                 
                                                    <Grid container spacing={3} alignItems='flex-start' className="mb-2">
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3} alignItems='center' justify='space-between'>
                                                                <Grid item>
                                                                    <Box width={150} mb={-1.7}>
                                                                        <TextField
                                                                            fullWidth
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="end"><FAIcon type="light" className={disc.percent === '' ? 'textError fw-400' : undefined} icon={disc.percent === '' ? 'exclamation-triangle' : 'percent'} size={disc.percent === '' ? 17.5 : 15} noMargin /></InputAdornment>
                                                                            }}
                                                                            inputProps={{
                                                                                step: 0.01,
                                                                                min: 0,
                                                                                max: 100,
                                                                            }}
                                                                            label='Discount Percent *'
                                                                            margin="none"
                                                                            onBlur={() => handleDiscountPercentFloat('brand', idx)}
                                                                            onChange={e => handleDiscountPercentChange('brand', idx, e?.target?.value ?? 0)}
                                                                            type="number"
                                                                            value={disc.percent}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Box width={350} mb={-1.7}>
                                                                        <AutoCompleteSelect
                                                                            key={uuidv4()} 
                                                                            label='Brand *'
                                                                            options={lists.brands}
                                                                            isOptionDisabled={option => _.some(formData.discounts?.brand, el => el.brand === option.value)}
                                                                            onChange={v => handleDiscountChange('brand', 'brand', idx, v ?? '', v?.supplier ?? 0)}
                                                                            value={disc.brand}
                                                                            isGrouped
                                                                            noClear
                                                                            shrink
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={disc?.isInclusion === 1} onChange={() => handleDiscountChange('brand', 'isInclusion', idx, disc?.isInclusion === 1 ? 0 : 1)} value="1" name="isInclusion" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">Configure as inclusion</Typography>}
                                                                        disabled={disc?.excludedProducts?.length > 0}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={disc?.incNoDiscount === 1} onChange={() => handleDiscountChange('brand', 'incNoDiscount', idx, disc?.incNoDiscount === 1 ? 0 : 1)} value="1" name="incNoDiscount" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">Include non-discountable products</Typography>}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title='Check Applicable Products'>
                                                                        <IconButton
                                                                            disabled={!isNumeric(disc.brand)}
                                                                            onClick={() => handleTestDiscountRow(disc)}
                                                                        >
                                                                            <FAIcon icon='search' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title='Delete Discount'>
                                                                        <IconButton
                                                                            onClick={() => _deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to remove this discount?</Typography></>, () => handleDiscountRemoveRow('brand', idx))}
                                                                        >
                                                                            <FAIcon icon='trash-alt' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {isNumeric(disc.brand) && (
                                                            <>
                                                                <Grid item xs={12} lg={6} xl={3}>
                                                                    <Box p={1} className={className}>
                                                                        <Box>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                {label} Category
                                                                            </Typography>
                                                                            <Box mt={-1.5} mb={-0.5}>
                                                                                <AutoCompleteSelect
                                                                                    key={uuidv4()} 
                                                                                    options={lists.categories}
                                                                                    isOptionDisabled={option => _.some(disc.excludedCategories, el => el.value === option.value || el.value === option.parent)}
                                                                                    onChange={v => handleDiscountChange('brand', 'excludedCategories', idx, v ?? '')}
                                                                                    value={null}
                                                                                    isGrouped
                                                                                    noClear
                                                                                    shrink
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        {_.map(disc.excludedCategories, (cat, cidx) => (
                                                                            <InsightChip
                                                                                icon="boxes-alt"
                                                                                key={cidx}
                                                                                label={cat.label}
                                                                                onDelete={() => handleRemoveDiscount('brand', 'excludedCategories', idx, cidx)}
                                                                                marginRight={12}
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                </Grid>
                                                                {_.find(lists.ranges, el => el.id === disc.supplier) && (
                                                                    <Grid item xs={12} lg={6} xl={3}>
                                                                        <Box p={1} className={className}>
                                                                            <Box>
                                                                                <Typography variant="caption" color="textSecondary">
                                                                                    {label} Range
                                                                                </Typography>
                                                                                <Box mt={-1.5} mb={-0.5}>
                                                                                    <AutoCompleteSelect
                                                                                        key={uuidv4()}
                                                                                        options={_.find(lists.ranges, el => el.id === disc.supplier)?.options}
                                                                                        isOptionDisabled={option => _.some(disc.excludedRanges, el => el.value === option.value)}
                                                                                        onChange={v => handleDiscountChange('brand', 'excludedRanges', idx, v ?? '')}
                                                                                        value={null}
                                                                                        noClear
                                                                                        shrink
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                            {_.map(disc.excludedRanges, (rng, ridx) => (
                                                                                <InsightChip
                                                                                    icon="boxes-alt"
                                                                                    key={ridx}
                                                                                    label={rng.label}
                                                                                    onDelete={() => handleRemoveDiscount('brand', 'excludedRanges', idx, ridx)}
                                                                                    marginRight={12}
                                                                                />
                                                                            ))}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                                {disc?.isInclusion !== 1 && (
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box p={1} className={className}>
                                                                            <Box mb={1.5}>
                                                                                <ProductSearchSelector
                                                                                    basic
                                                                                    label={`${label} Product`}
                                                                                    handleAction={v => handleDiscountChange('brand', 'excludedProducts', idx, v ?? '')}
                                                                                    hideStock
                                                                                    key={innerKey.brand}
                                                                                    brand={disc.brand}
                                                                                />
                                                                            </Box>
                                                                            {_.map(disc.excludedProducts, (prd, pidx) => (
                                                                                <InsightChip
                                                                                    icon="box-check"
                                                                                    key={pidx}
                                                                                    label={prd.label}
                                                                                    onDelete={() => handleRemoveDiscount('brand', 'excludedProducts', idx, pidx)}
                                                                                    marginRight={12}
                                                                                />
                                                                            ))}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>                                                                                                    
                                                    <Divider />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                                <Box>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="text"
                                        size="large"
                                        className="content-light-grey content-light-error-clickable"
                                        onClick={() => handleDiscountAddRow('brand')}
                                    >
                                        <FAIcon icon="plus-circle" size={15} button />
                                        Additional Brand Discount
                                    </Button>
                                </Box>
                            </Box>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel 
                        expanded={expanded.range} 
                        onChange={handleAccordionChange('range')}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <ExpansionPanelSummary expandIcon={<FAIcon icon="chevron-down" button />}>
                            <Typography className={classes.heading}>
                                Range Discount
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                {_.some(formData.discounts.range, el => el.percent === '' || el.range === '') ? <span className="textError"><FAIcon icon="exclamation-triangle" size={15} /> Some discounts are not configured correctly</span> : _.isEmpty(formData.discounts.range) ? '-' : `${_.size(formData.discounts.range)} range discounts`}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Box>
                                {!_.isEmpty(formData.discounts.range) && (
                                    <Grid container spacing={3} className="mb-2">
                                        {_.map(formData.discounts.range, (disc, idx) => {                                                                
                                            const label = disc?.isInclusion === 1 ? 'Only where' : 'Exclude',
                                                className = disc?.isInclusion === 1 ? 'content-light-success' : 'content-light-error';
                                            return (
                                                <Grid item xs={12} key={idx}>
                                                    <Grid container spacing={3} alignItems='flex-start' className="mb-2">
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3} alignItems='center' justify='space-between'>
                                                                <Grid item>
                                                                    <Box width={150} mb={-1.7}>
                                                                        <TextField
                                                                            fullWidth
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="end"><FAIcon type="light" className={disc.percent === '' ? 'textError fw-400' : undefined} icon={disc.percent === '' ? 'exclamation-triangle' : 'percent'} size={disc.percent === '' ? 17.5 : 15} noMargin /></InputAdornment>
                                                                            }}
                                                                            inputProps={{
                                                                                step: 0.01,
                                                                                min: 0,
                                                                                max: 100,
                                                                            }}
                                                                            label='Discount Percent *'
                                                                            margin="none"
                                                                            onBlur={() => handleDiscountPercentFloat('range', idx)}
                                                                            onChange={e => handleDiscountPercentChange('range', idx, e?.target?.value ?? 0)}
                                                                            type="number"
                                                                            value={disc.percent}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Box width={350} mb={-1.7}>
                                                                        <AutoCompleteSelect
                                                                            key={uuidv4()} 
                                                                            label='Range *'
                                                                            options={lists.ranges}
                                                                            // isOptionDisabled={option => _.some(formData.discounts?.range, el => el.range === option.value)}
                                                                            onChange={v => handleDiscountChange('range', 'range', idx, v ?? '', v?.supplier ?? 0)}
                                                                            value={disc.range}
                                                                            isGrouped
                                                                            noClear
                                                                            shrink
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Box width={150} mb={-1.7}>
                                                                        <TextField
                                                                            fullWidth
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            inputProps={{
                                                                                step: 1,
                                                                                min: 0,
                                                                            }}
                                                                            label='Minimum Quantity *'
                                                                            margin="none"
                                                                            onChange={e => handleDiscountChange('range', 'minQty', idx, e?.target?.value ?? 0)}
                                                                            type="number"
                                                                            value={disc?.minQty ?? 0}
                                                                        />
                                                                    </Box> 
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={disc?.isInclusion === 1} onChange={() => handleDiscountChange('range', 'isInclusion', idx, disc?.isInclusion === 1 ? 0 : 1)} value="1" name="isInclusion" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">Configure as inclusion</Typography>}
                                                                        disabled={disc?.excludedProducts?.length > 0}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch color="primary" checked={disc?.incNoDiscount === 1} onChange={() => handleDiscountChange('range', 'incNoDiscount', idx, disc?.incNoDiscount === 1 ? 0 : 1)} value="1" name="incNoDiscount" />
                                                                        }
                                                                        label={<Typography variant="body2" className="fw-400">Include non-discountable products</Typography>}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title='Check Applicable Products'>
                                                                        <IconButton
                                                                            disabled={!isNumeric(disc.supplier) || !isNumeric(disc.range)}
                                                                            onClick={() => handleTestDiscountRow(disc)}
                                                                        >
                                                                            <FAIcon icon='search' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title='Delete Discount'>
                                                                        <IconButton
                                                                            onClick={() => _deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to remove this discount?</Typography></>, () => handleDiscountRemoveRow('range', idx))}
                                                                        >
                                                                            <FAIcon icon='trash-alt' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {isNumeric(disc.supplier) && isNumeric(disc.range) && (
                                                            <>
                                                                <Grid item xs={12} lg={6} xl={3}>
                                                                    <Box p={1} className={className}>
                                                                        <Box>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                {label} Category
                                                                            </Typography>
                                                                            <Box mt={-1.5} mb={-0.5}>
                                                                                <AutoCompleteSelect
                                                                                    key={uuidv4()} 
                                                                                    options={lists.categories}
                                                                                    isOptionDisabled={option => _.some(disc.excludedCategories, el => el.value === option.value || el.value === option.parent)}
                                                                                    onChange={v => handleDiscountChange('range', 'excludedCategories', idx, v ?? '')}
                                                                                    value={null}
                                                                                    isGrouped
                                                                                    noClear
                                                                                    shrink
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        {_.map(disc.excludedCategories, (cat, cidx) => (
                                                                            <InsightChip
                                                                                icon="boxes-alt"
                                                                                key={cidx}
                                                                                label={cat.label}
                                                                                onDelete={() => handleRemoveDiscount('range', 'excludedCategories', idx, cidx)}
                                                                                marginRight={12}
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                </Grid>
                                                                {disc?.isInclusion !== 1 && (
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box p={1} className={className}>
                                                                            <Box mb={1.5}>
                                                                                <ProductSearchSelector
                                                                                    basic
                                                                                    label={`${label} Product`}
                                                                                    handleAction={v => handleDiscountChange('range', 'excludedProducts', idx, v ?? '')}
                                                                                    hideStock
                                                                                    key={innerKey.range}
                                                                                    range={disc.range}
                                                                                />
                                                                            </Box>
                                                                            {_.map(disc.excludedProducts, (prd, pidx) => (
                                                                                <InsightChip
                                                                                    icon="box-check"
                                                                                    key={pidx}
                                                                                    label={prd.label}
                                                                                    onDelete={() => handleRemoveDiscount('range', 'excludedProducts', idx, pidx)}
                                                                                    marginRight={12}
                                                                                />
                                                                            ))}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>                                                
                                                    <Divider />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                                <Box>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="text"
                                        size="large"
                                        className="content-light-grey content-light-error-clickable"
                                        onClick={() => handleDiscountAddRow('range')}
                                    >
                                        <FAIcon icon="plus-circle" size={15} button />
                                        Additional Range Discount
                                    </Button>
                                </Box>
                            </Box>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel 
                        expanded={expanded.product} 
                        onChange={handleAccordionChange('product')} 
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <ExpansionPanelSummary expandIcon={<FAIcon icon="chevron-down" button />}>
                            <Typography className={classes.heading}>
                                Product Discount
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                {_.some(formData.discounts.product, el => el.percent === '' || el.minQty === '' || el.product?.length === 0) ? <span className="textError"><FAIcon icon="exclamation-triangle" size={15} /> Some discounts are not configured correctly</span> : _.isEmpty(formData.discounts.product) ? '-' : `${_.size(formData.discounts.product)} product discounts`}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Box>
                                {!_.isEmpty(formData.discounts.product) && (
                                    <Grid container spacing={3} className="mb-2">
                                        {_.map(formData.discounts.product, (disc, idx) => (
                                            <Grid item xs={12} lg={6} key={idx}>
                                                <Grid container spacing={3} alignItems='flex-start' className="mb-2">
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3} alignItems='center' justify='space-between'>
                                                            <Grid item>
                                                                <Box width={150} mb={-1.7}>
                                                                    <TextField
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end"><FAIcon type="light" className={disc.percent === '' ? 'textError fw-400' : undefined} icon={disc.percent === '' ? 'exclamation-triangle' : 'percent'} size={disc.percent === '' ? 17.5 : 15} noMargin /></InputAdornment>
                                                                        }}
                                                                        inputProps={{
                                                                            step: 0.01,
                                                                            min: 0,
                                                                            max: 100,
                                                                        }}
                                                                        label='Discount Percent *'
                                                                        margin="none"
                                                                        onBlur={() => handleDiscountPercentFloat('product', idx)}
                                                                        onChange={e => handleDiscountPercentChange('product', idx, e?.target?.value ?? 0)}
                                                                        type="number"
                                                                        value={disc.percent}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Box width={150} mb={-1.7}>
                                                                    <TextField
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        inputProps={{
                                                                            step: 1,
                                                                            min: 0,
                                                                        }}
                                                                        label='Minimum Quantity *'
                                                                        margin="none"
                                                                        onBlur={() => handleDiscountMinQtyFloat(idx)}
                                                                        onChange={e => handleDiscountMinQtyChange(idx, e?.target?.value ?? 0)}
                                                                        type="number"
                                                                        value={disc.minQty}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item>
                                                                <Box pb={1.5}>
                                                                    <Tooltip title='Delete Discount'>
                                                                        <IconButton
                                                                            onClick={() => _deployConfirmation(<><Typography variant="body2" className="fw-400">Are you sure you want to remove this discount?</Typography></>, () => handleDiscountRemoveRow('product', idx))}
                                                                        >
                                                                            <FAIcon icon='trash-alt' button noMargin />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box p={1} className="content-light-success">
                                                            <Box mb={1.5}>
                                                                <ProductSearchSelector
                                                                    basic
                                                                    label="Add Product"
                                                                    handleAction={v => handleDiscountChange('product', 'product', idx, v ?? '')}
                                                                    hideStock
                                                                    key={innerKey.product}
                                                                    menuPlacement='top'
                                                                />
                                                            </Box>
                                                            {_.map(disc.product, (prd, pidx) => (
                                                                <InsightChip
                                                                    icon="box-check"
                                                                    key={pidx}
                                                                    label={prd.label}
                                                                    onDelete={() => handleRemoveDiscount('product', 'product', idx, pidx)}
                                                                    marginRight={12}
                                                                />
                                                            ))}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                                <Box>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="text"
                                        size="large"
                                        className="content-light-grey content-light-error-clickable"
                                        onClick={() => handleDiscountAddRow('product')}
                                    >
                                        <FAIcon icon="plus-circle" size={15} button />
                                        Additional Product Discount
                                    </Button>
                                </Box>
                            </Box>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </Grid>
            {!isCustomerForm ? discountPriorityAlert() : null}
        </Grid>
    )
}

export default DiscountConfiguration;