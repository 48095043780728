import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AddressLookup from 'Components/Common/AddressLookup/AddressLookup';
import Alert from 'Components/Common/Alert/Alert';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { handleChange, handleConfirmationClose, handleConfirmationOpen, handleConfirmationSuccess, handleSelectChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import FAIcon from '../Icons/FontAwesome/FAIcon';

const initialState = () => ({
    confirmation: {
        submit: false
    },
    countyList: [],
    countryList: [],
    formData: {
        id: '',
        type: '',
        name: '',
        addressType: '',
        addressOne: '',
        addressTwo:  '',
        addressThree:  '',
        town:  '',
        county: '',
        country:  234,
        postcode: '',
        notes: '',
        showAddress: false,
        showAddressLookup: true,
        addressComplete: false,
    },
    formErrors: {},
    isLoading: true,
    type: null
})

class AddressForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState()
        this.handleChange = handleChange.bind(this)
        this.handleSelectChange = handleSelectChange.bind(this)
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.formData.country !== this.state.formData.country) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    county: ''
                }
            })
        }
    }
    
    loadComponentData = () => {
        if(this.props.updateId) {
            Promise.all([
                API.get(`/misc/addresses/${this.props.updateId}`),
                API.get('/misc/countries/all'),
                API.get('/misc/counties/all'),
            ])
            .then(([result, countryRes, countyRes]) => {

                let countyList = [],
                     countryList = [];
                        
                if(countyRes.data){
                    countyList = _.map(countyRes.data, el => {
                        return _.assign({
                            value: el.county_id,
                            label: el.county_name,
                            dc: el.county_del_conf_id
                        });
                    });
                }

                if(countryRes.data){
                    countryList = _.map(countryRes.data, el => {
                        return _.assign({
                            value: el.country_id,
                            label: el.country_name,
                        });
                    });
                }
                this.setState({
                    countyList,
                    countryList,
                    formData: {
                        ...this.state.formData,
                        id: this.props.updateId,
                        name: result.data.address_name,
                        addressType: result.data.address_type,
                        addressOne: result.data.address_line_one,
                        addressTwo:  result.data.address_line_two,
                        addressThree:  result.data.address_line_three,
                        town:  result.data.address_town,
                        county: result.data.address_county_id,
                        country: result.data.address_country_id,
                        postcode: result.data.address_postcode,
                        notes: result.data.address_notes,
                        showAddress: true,
                        showAddressLookup: false,
                        addressComplete: true
                    },
                    isLoading: false,
                    type: result.data.address_type
                })
            })
        } else {
            Promise.all([
                API.get('/misc/countries/all'),
                API.get('/misc/counties/all'),
            ])
            .then(([countryRes, countyRes]) => {

                let countyList = [],
                     countryList = [];
                        
                if(countyRes.data){
                    countyList = _.map(countyRes.data, el => {
                        return _.assign({
                            value: el.county_id,
                            label: el.county_name,
                            dc: el.county_del_conf_id
                        });
                    });
                }

                if(countryRes.data){
                    countryList = _.map(countryRes.data, el => {
                        return _.assign({
                            value: el.country_id,
                            label: el.country_name,
                        });
                    });
                }

                this.setState({
                    countyList,
                    countryList,
                    formData: {
                        ...this.state.formData,
                        id: this.props.id,
                        type: this.props.type,
                        addressType: this.props.addressType ?? '',
                    },
                    isLoading: false
                })
            })
        }
    }
   
    handleManuallyEnterAddress = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                showAddress:        this.state.formData.showAddress && this.state.formData.showAddressLookup ? true : !this.state.formData.showAddress,
                showAddressLookup:  this.state.formData.showAddress && this.state.formData.showAddressLookup ? false : !this.state.formData.showAddressLookup,
                addressOne:         this.state.formData.showAddress ? '' : this.state.formData.addressOne,
                addressTwo:         this.state.formData.showAddress ? '' : this.state.formData.addressTwo,
                addressThree:       this.state.formData.showAddress ? '' : this.state.formData.addressThree,
                town:               this.state.formData.showAddress ? '' : this.state.formData.town,
                county:             this.state.formData.showAddress ? '' : this.state.formData.county,
                postcode:           this.state.formData.showAddress ? '' : this.state.formData.postcode,
                country:            initialState().formData.country,
            }
        });
    }

    handleSelectedAddress = e => {
        if(e && e.value) {
            const countyId = _.find(this.state.countyList, {label: e.value.county})?.value ?? '';
            this.setState({
                formData: {
                    ...this.state.formData,
                    addressOne: e.value.line1,
                    addressTwo:  e.value.line2,
                    addressThree:  e.value.line3,
                    town:  e.value.town,
                    county: countyId,
                    postcode: e.value.postcode,
                    selectedAddress: e,
                    addressComplete: true,
                    showAddressLookup: false,
                    showAddress: true
                }
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    selectedAddress: null,
                    addressComplete: false,
                    showAddressLookup: true
                }
            });
        }
    }

    handleSubmit = e => {
        e?.preventDefault?.();
        this.handleConfirmationOpen('submit');
    }

    cancel = () => {
        this.setState({
            formData: {
                ...initialState().formData,
                id: this.props.id,
                type: this.props.type,
                name: '',
                addressType: this.props.addressType ?? '',
                addressOne: '',
                addressTwo:  '',
                addressThree:  '',
                town:  '',
                county: '',
                country:  234,
                postcode: '',
                notes: '',
                showAddress: false,
                showAddressLookup: true,
                addressComplete: false
            }
        }, () => {
            if(this.props.cancel) {
                this.props.cancel();
            }
        })
    }
    
    submit = () => {
        if(this.props.updateId) {
            API.put(`/misc/addresses/${this.props.updateId}`, { formData: this.state.formData, type: this.props.type, id: this.props.id } )
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                            formData: {
                                ...this.state.formData,
                                showAddress: true,
                                showAddressLookup: false,
                            }
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                                type: this.props.type,
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully updated the address");
                            if(this.props.callback) 
                                this.props.callback();
                        })
                    }
                }
            })
        } else {
            API.post(`/misc/addresses`, this.state.formData)
            .then(res => {
                if(res.data) {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                            formData: {
                                ...this.state.formData,
                                showAddress: true,
                                showAddressLookup: false,
                            }
                        })
                    } else {
                        this.setState({
                            formData: { 
                                ...initialState().formData,
                                id: this.props.id,
                                type: this.props.type,
                            }
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully added a new address");
                            if(this.props.callback) 
                                this.props.callback();
                        })
                    }
                }
            })
        }
    }

    render() {
        const { updateId, noTrading, noRegistered } = this.props;
        const { countyList, countryList, formErrors, formData, isLoading, type } = this.state;
        const types = [
            {
                label: 'Trading',
                value: 'Trading',
                disabled: noTrading
            },
            {
                label: 'Registered',
                value: 'Registered',
                disabled: noRegistered
            },
            {
                label: 'Accounts',
                value: 'Accounts',
                disabled: ((this.props.updateId && formData.addressType === "Trading") || (this.props.updateId && formData.addressType === "Registered"))
            },
            {
                label: 'Delivery',
                value: 'Delivery',
                disabled: ((this.props.updateId && formData.addressType === "Trading") || (this.props.updateId && formData.addressType === "Registered"))
            }
        ];
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                    {type === 'Trading' && (
                        <Alert severity="info" variant="outlined">
                            The associated accounts, delivery and registered address will also be updated when you modify the trading address
                        </Alert>
                    )}
                    <Grid container className="pt-1">
                        <Grid item xs={12}>
                            <TextField
                                id="name"
                                name="name"
                                value={formData.name}
                                label="Address name / reference"
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {(formData.country === 234 && formData.showAddressLookup && (// United Kingdom
                                <>
                                    <AddressLookup       
                                        handleSelectedAddress={this.handleSelectedAddress}
                                        inlineLayout={false}
                                        value={formData.selectedAddress}
                                        visible={formData.showAddressLookup}
                                        manualEntry={
                                            <Box pb={2} textAlign='center' onClick={this.handleManuallyEnterAddress}>
                                                <Typography variant="body2" className='blueLink'>
                                                    {formData.showAddressLookup ? 'Manually enter address': 'Use postcode lookup (UK Only)' }
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </>
                            )) || (
                                (formData.country !== 234 || (formData.country === 234 && formData.showAddress) || formData.showAddress) && (
                                    <>
                                        <Box pb={2} textAlign='center' onClick={this.handleManuallyEnterAddress}>
                                            <Typography variant="body2" className='blueLink'>
                                                Use address lookup (UK Only)
                                            </Typography>
                                        </Box>
                                        <TextField
                                            id="addressOne"
                                            name="addressOne"
                                            label="Address Line 1 *"
                                            value={formData.addressOne}
                                            error={formErrors && formErrors['addressOne'] && true}
                                            helperText={formErrors && formErrors['addressOne']}
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        <TextField
                                            id="addressTwo"
                                            name="addressTwo"
                                            label="Address Line 2"
                                            value={formData.addressTwo}
                                            error={formErrors && formErrors['addressTwo'] && true}
                                            helperText={formErrors && formErrors['addressTwo']}
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        <TextField
                                            id="addressThree"
                                            name="addressThree"
                                            label="Address Line 3"
                                            value={formData.addressThree}
                                            error={formErrors && formErrors['addressThree'] && true}
                                            helperText={formErrors && formErrors['addressThree']}
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        <TextField
                                            id="town"
                                            name="town"
                                            label="City / Town *"
                                            value={formData.town}
                                            error={formErrors && formErrors['town'] && true}
                                            helperText={formErrors && formErrors['town']}
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        <TextField
                                            id="postcode"
                                            name="postcode"
                                            label="Postcode *"
                                            value={formData.postcode}
                                            error={formErrors && formErrors['postcode'] && true}
                                            helperText={formErrors && formErrors['postcode']}
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        {formData.country === 234 && (                                          
                                            <AutoCompleteSelect 
                                                options={countyList} 
                                                label='County *'
                                                onChange={v => this.handleSelectChange('county', v)}
                                                error={formErrors && formErrors['county'] && true}
                                                errorText={formErrors && formErrors['county']}
                                                value={formData.county}
                                                noClear
                                            />
                                        )}
                                        <FormControl fullWidth margin="none">
                                            <AutoCompleteSelect 
                                                options={countryList} 
                                                label='Country'
                                                onChange={v => this.handleSelectChange('country', v)}
                                                error={formErrors && formErrors['country'] && true}
                                                errorText={formErrors && formErrors['country']}
                                                value={formData.country}
                                                noClear
                                            />
                                        </FormControl>
                                    </>
                                ))}
                            </Grid>
                            {!this.props.addressType && !this.props.updateId && (
                                <Grid item xs={12}>
                                    <AutoCompleteSelect 
                                        options={types} 
                                        label='Address Type *'
                                        onChange={v => this.handleSelectChange('addressType', v)}
                                        error={formErrors && formErrors['addressType'] && true}
                                        errorText={formErrors && formErrors['addressType']}
                                        value={formData.addressType}
                                    />
                                </Grid>
                            )}
                            {formData.showAddress && (
                                <Grid item xs={12}>
                                    <Box mt={1.5}>
                                        <TextField
                                            id="notes"
                                            name="notes"
                                            value={formData.notes}
                                            label="Delivery Instructions"
                                            onChange={this.handleChange}
                                            margin="none"
                                            variant="outlined"
                                            inputProps={{
                                                maxLength: 50
                                            }}
                                            fullWidth
                                        />
                                        <Box mt={1.5}>
                                            <Typography variant="caption">
                                                <FAIcon icon="info-circle" size={12.5} />
                                                Max length 50 characters
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12} align="right">
                                <Box pt={1}>
                                    <Button
                                        onClick={this.cancel} 
                                        variant="text" 
                                        color="primary"
                                    >
                                        <FAIcon icon="times" size={15} button />
                                        {updateId ? 'Cancel' : 'Discard'}                           
                                    </Button>
                                    <Button 
                                        disabled={formData.addressType === ''} 
                                        variant="text" 
                                        color="primary"
                                        type="submit"
                                    >
                                        <FAIcon icon="check" size={15} button />
                                        {updateId ? 'Update' : 'Add Address'}
                                    </Button>
                                </Box>
                            </Grid>  
                        </Grid>   
                        <ConfirmationDialog 
                            open={this.state.confirmation.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            message={`Are you sure you want to ${updateId ? 'update': 'add'} this address?`}
                        />
                </form>
            )
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(AddressForm);