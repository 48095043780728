import React from 'react';

import Search from 'Components/Common/Search/Search';

const InStockOrShouldBe = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/inStockOrShouldBe',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Stock Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        important: true,
                        sizeToContent: true,   
                    },
                    {
                        heading: 'Purchase Ref',
                        field: rowData => rowData.pr,
                        dataRef: 'pr',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'In Stock',
                        field: rowData => rowData.s,
                        alignment: 'right',
                        dataRef: 's',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'Available',
                        field: rowData => rowData.sav,
                        alignment: 'right',
                        dataRef: 'sav',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'Allocated',
                        field: rowData => rowData.sal,
                        alignment: 'right',
                        dataRef: 'sal',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'On Order',
                        field: rowData => rowData.soo,
                        alignment: 'right',
                        dataRef: 'soo',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'Pre-ordered',
                        field: rowData => rowData.spo,
                        alignment: 'right',
                        dataRef: 'spo',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'Re-Order Level',
                        field: rowData => rowData.ro,
                        alignment: 'right',
                        dataRef: 'ro',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Consignment',
                        field: rowData => rowData.c,
                        alignment: 'center',
                        fieldFormat: 'boolean',
                        dataRef: 'c',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'Bin Location',
                        field: rowData => rowData.b,
                        dataRef: 'b',
                        important: true,
                        sizeToContent: true,
                    },
                    {
                        heading: 'We Need',
                        field: rowData => rowData.wn,
                        alignment: 'right',
                        dataRef: 'wn',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    },
                    {
                        heading: 'We Need Live',
                        field: rowData => rowData.wnl,
                        alignment: 'right',
                        dataRef: 'wnl',
                        important: true,
                        sizeToContent: true,
                        showTotal: true
                    }
                ],
                dataRef: true,
                excel: true,
                key: 'sc',
                name: 'instock-or-should-be',
                noResultsText: 'There are no stocked items to display',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Stock'],
                pdf: true,
                persistenceId: 'inStockOrShouldBe',
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCategory: true,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showStockStatus: true,
                showStockType: true,
                showSupplier: true,
                showTotals: false,
                title: 'In Stock Or Should Be Report'
            }}
        />
    )
}

export default InStockOrShouldBe;