import React from 'react';
import GoogleMapReact from 'google-map-react';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { GOOGLE_MAPS_API_KEY } from 'Constants';

const CustomToolTipStyles = {
    position: 'absolute',
    width: 60,
    height: 60,
    left: -30,
    top: -30,
    backgroundColor: 'transparent',
    textAlign: 'center',
    color: '#ef3340',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4
}
 
const CustomTooltip = ({content}) => (
    <div style={CustomToolTipStyles}>
        {content}
    </div>
)
 
const Map = ({width, height, lat, long, content, controllable, zoom}) => (
    <div style={{height: height ?? '100vh', width: width ?? '100%', cursor: 'default'}}>
        <GoogleMapReact
            bootstrapURLKeys={{ 
                key: GOOGLE_MAPS_API_KEY
            }}
            defaultCenter={{
                lat: lat ? parseFloat(lat) : 59.95,
                lng: long ? parseFloat(long) : 30.33
            }}
            defaultZoom={zoom ?? 11}
            options={() => ({
                clickableIcons: controllable ?? false,
                disableDoubleClickZoom: controllable ?? true,
                disableDefaultUi: controllable ?? true,
                draggable: controllable ?? false,
                fullscreenControl: controllable ?? false,
                restriction: controllable ?? true,
                zoomControl: controllable ?? false,
                draggableCursor: 'default'
            })}
            yesIWantToUseGoogleMapApiInternals
        >
            {content}
            <CustomTooltip
                lat={lat}
                lng={long}
                content={(
                    <FAIcon icon="map-marker" type="solid" noMargin size={25} />
                )}
            />
        </GoogleMapReact>
    </div>
)
 
export default Map;