import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewPage from 'Components/Common/Styles/ViewPage';
import { TabPanel, handleTabChange } from 'Functions/MiscFunctions';
import { VIEW_PAGE_APP_BAR_HEIGHT , VIEW_PAGE_OFFSET } from 'Constants';

import ProductBinLocations from 'Components/Reporting/Products/ProductBinLocations/ProductBinLocations';
import ProductsBySupplier from 'Components/Reporting/Products/ProductsBySupplier/ProductsBySupplier';
import ProductSales from 'Components/Reporting/Products/ProductSales/ProductSales';
import ProductCheckPriceAndAvailability from 'Components/Reporting/Products/ProductCheckPriceAndAvailability/ProductCheckPriceAndAvailability';
import ProductNoDiscount from 'Components/Reporting/Products/ProductNoDiscount/ProductNoDiscount';
import ProductGrouping from 'Components/Reporting/Products/ProductGrouping/ProductGrouping';
import ProductPricing from 'Components/Reporting/Products/ProductPricing/ProductPricing';

const initialState = () => ({
    access: {
        prodLocations: false,
        prodBySupplier: false,
        prodSales: false,
        prodCheckPrice: false,
        prodNoDiscount: false,
        prodGrouping: false,
        prodPrice: false
    },
    currentTab: 0,
    isLoading: true,
    tabs: {
        0: "Product Bin Locations Report",
        1: "Products By Supplier Report",
        2: "Product Sales Report",
        3: "Check Price and Availability Products Report",
        4: "Non-Discountable Products Report",
        5: "Product Grouping Report",
        6: "Product Price Report"
    },
    tabText: {
        0: "Live data on product warehouse bin locations",
        1: "Live data on products by supplier",
        2: "Historic data on product sales",
        3: "Live products which are currently set to check price and availability",
        4: "Live products which are currently set to not be discountable",
        5: "Live data on product brand, category, range and supplier configuration",
        6: "View current product prices by customer"
    },
})

class ReportingProducts extends React.Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this)
    }

    componentDidMount = () => {
        this.props.pageTitle([0, "Reporting", "Products"])
        this.loadComponentData()
    }

    loadComponentData = () => {
        API.multiAccess([
            'wh-bin-report',
            'prd-by-supp-report',
            'prd-sales-report',
            'chk-price-report',
            'no-disc-report',
            'prd-grp-report',
            'prd-price-report'
        ])
        .then(([
            prodLocations,
            prodBySupplier,
            prodSales,
            prodCheckPrice,
            prodNoDiscount,
            prodGrouping,
            prodPrice
        ]) => {     
            this.setState({
                access: {
                    prodLocations,
                    prodBySupplier,
                    prodSales,
                    prodCheckPrice,
                    prodNoDiscount,
                    prodGrouping,
                    prodPrice
                },
                isLoading: false
            })
        })
    }

    render = () => {

        const { 
            classes, 
            ui 
        } = this.props;

        const { 
            access, 
            currentTab, 
            isLoading, 
            tabs, 
            tabText 
        } = this.state;

        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        
        return (
            <Grid container style={{margin: -24, width: 'initial'}}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                <Box pt={1} pr={1} pb={1} pl={3}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Typography variant="h4" className="textDefault">
                                                        {tabs[currentTab]}
                                                    </Typography>  
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{height: 41.49}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={0.5}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item>
                                                    <InsightChip
                                                        icon="info-circle"
                                                        label={tabText[currentTab]}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    </Grid>
                                </Box>
                            </AppBar>
                        </Grid>   
                        <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <Box p={3}>
                                        {access.prodLocations && (
                                            <TabPanel value={currentTab} index={0}>
                                                <ProductBinLocations />
                                            </TabPanel>
                                        )}
                                        {access.prodBySupplier && (
                                            <TabPanel value={currentTab} index={1}>
                                                <ProductsBySupplier />
                                            </TabPanel>
                                        )}
                                        {access.prodSales && (
                                            <TabPanel value={currentTab} index={2}>
                                                <ProductSales />
                                            </TabPanel>
                                        )}
                                        {access.prodCheckPrice && (
                                            <TabPanel value={currentTab} index={3}>
                                                <ProductCheckPriceAndAvailability />
                                            </TabPanel>
                                        )}
                                        {access.prodNoDiscount && (
                                            <TabPanel value={currentTab} index={4}>
                                                <ProductNoDiscount />
                                            </TabPanel>
                                        )}
                                        {access.prodGrouping && (
                                            <TabPanel value={currentTab} index={5}>
                                                <ProductGrouping />
                                            </TabPanel>
                                        )}
                                        {access.prodPrice && (
                                            <TabPanel value={currentTab} index={6}>
                                                <ProductPricing />
                                            </TabPanel>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={classes.side}
                            style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}
                        >
                            <Tabs
                                color="primary"
                                onChange={this.handleTabChange}
                                orientation="vertical"
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}
                                value={currentTab}
                                variant="scrollable"
                            >
                                {access.prodLocations && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={0} 
                                        label="Product Bin Locations" 
                                    />
                                )}
                                {access.prodBySupplier && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={1} 
                                        label="Products By Supplier" 
                                    />
                                )}
                                {access.prodSales && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={2} 
                                        label="Product Sales" 
                                    />
                                )}
                                {access.prodCheckPrice && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={3} 
                                        label="Check P&A Products" 
                                    />
                                )}
                                {access.prodNoDiscount && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={4} 
                                        label="Non-Discountable Products" 
                                    />
                                )}
                                {access.prodGrouping && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={5} 
                                        label="Product Grouping" 
                                    />
                                )}
                                {access.prodPrice && (
                                    <Tab 
                                        classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                        value={6} 
                                        label="Product Pricing" 
                                    />
                                )}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    ui: state.ui
})

export default connect(mapStateToProps)(withStyles(ViewPage)(ReportingProducts));