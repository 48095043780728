import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import ProductCategoriesList from './ProductCategoriesList';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import { DraggablePaper } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {  
        apiRoute: '/products',
        confirmationOpen: {
            submit: false,
            update: false,
        },     
        formData: {
            name: '',
            parentId: 0
        },
        updateData: {
            id: 0,
            name: '',
            parentId: 0
        },
        formErrors:[],
        updateErrors: [],
        dialogOpen: false,
        parentList: [],
        categories: [],
        snackbarOpen: {
            submit: false,
            update: false,
        },
        isLoading: true,
        updateLoading: true
    }

class ProductCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        if(this.props.forEcommerce) {
            this.setState({
                apiRoute: '/eCommerce'
            }, this.getProductCategories);
        } else {
            this.getProductCategories();
        }
    }

    submit = () => {
        API.post(`${this.state.apiRoute}/categories`, this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    apiRoute: this.state.apiRoute,
                    snackbarOpen: {
                        update: false,
                        submit: true
                    }
                });
                this.getProductCategories();
            }
            this.props.scrollToTop();
        });
    }

    update = () => {
        API.put(`${this.state.apiRoute}/categories/${this.state.updateData?.id}`, this.state.updateData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    updateErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    apiRoute: this.state.apiRoute,
                    snackbarOpen: {
                        update: true,
                        submit: false
                    }
                });
                this.getProductCategories();
            }
            this.props.scrollToTop();
        });
    }

    getProductCategories = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`${this.state.apiRoute}/categories${this.props.forEcommerce ? `/all` : ``}`)
            .then((result) => {
                let parentList = []
                if(result.data) {
                    parentList = _.map(result.data, el => {
                        if(el.category_parent_id === 0) {
                            return _.assign({
                                value: el.category_id,
                                label: el.category_name,
                            });
                        }
                    });
                }
                this.setState({
                    ...this.state,
                    categories: result.data,
                    parentList,
                    isLoading: false
                });

            })
        })
    }
     
    getUpdateData = () => {
        API.get(`${this.state.apiRoute}/categories/${this.state.updateData.id}`)
        .then((result) => {
            if(result.data.errors) {
                this.handleDialogClose();
            } else {
                this.setState({
                    updateLoading: false,
                    updateErrors: [],
                    updateData: {
                        ...this.state.updateData,
                        name: result.data.category_name,
                        parentId: result.data.category_parent_id,
                    }
                });
            }
        })
    }
    
    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleUpdateChange = e => {
        this.setState({
            updateData: {
                ...this.state.updateData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        selectedOption = selectedOption === null ? '' : selectedOption
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    handleUpdateSelectChange = fieldName => selectedOption => {
        selectedOption = selectedOption === null ? '' : selectedOption
        this.setState({
            updateData: {
                ...this.state.updateData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };
    
    handleSnackbarClose = (type) => {
        this.setState({ 
            snackbarOpen: {
            ...this.state.snackbarOpen,
            [type]: false,
            } 
        });
    };

    handleConfirmationOpen = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: true,
            } 
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({ 
            confirmationOpen: {
                ...this.state.confirmationOpen,
                [type]: false,
            } 
        }, () => {
            if(type === 'submit') {
                this.submit();
            } else {
                this.update();
            }
        });
    }

    handleDialogOpen = (id) => {
        this.setState({
            dialogOpen: true,
            updateLoading: true,
            updateData: {
                ...initialState.updateData,
                id: id
            }
        }, () => this.getUpdateData());
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpen: false,
            updateLoading: true,
            updateData: {
                ...initialState.updateData
            }
        });
    }

    render() {
        const { formData, formErrors, isLoading, parentList, categories, updateData, updateErrors, updateLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    {(isLoading && (
                        <PaddedPaper>
                            <LoadingCircle />
                        </PaddedPaper>
                    )) || (
                        <ProductCategoriesList categories={categories} handleDialogOpen={this.handleDialogOpen} />
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant="h6" paragraph>
                            Add Category
                        </Typography>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                name="name"
                                label="Name *"
                                value={formData.name}
                                error={formErrors && formErrors['name'] && true}
                                helperText={formErrors && formErrors['name']}
                                onChange={this.handleChange}
                                margin="none"
                                fullWidth
                            />
                            <Box pt={1}>
                                <AutoCompleteSelect 
                                    options={[{ label: "Create Top Level Category", options: [{ label: "None", value: 0 }]}, { label: "Existing Top Level Categories", options: [...parentList]},]}
                                    label='Parent Category *'
                                    onChange={this.handleSelectChange('parentId')}
                                    error={formErrors && formErrors['parentId'] && true}
                                    errorText={formErrors && formErrors['parentId']}
                                    value={this.state.formData.parentId}
                                />
                            </Box>
                            <div className='buttonRow'>
                                <Button 
                                    type='submit'
                                    onClick={() => this.handleConfirmationOpen('submit')}
                                    variant="text" 
                                    color="primary" 
                                    disabled={!this.state.formData.name}
                                >
                                    <FAIcon icon="check" size={15} button />
                                    Add Category
                                </Button>
                            </div>
                        </form>
                        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} PaperComponent={DraggablePaper} open={this.state.dialogOpen} onClose={this.handleDialogClose} fullWidth={true} maxWidth="sm">
                            <DialogTitle id="draggable-control" style={{cursor: 'move'}} color="secondary">Update Product Category</DialogTitle>
                            <DialogContent>
                                {(updateLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            name="name"
                                            label="Name *"
                                            value={updateData.name}
                                            error={updateErrors && updateErrors['name'] && true}
                                            helperText={updateErrors && updateErrors['name']}
                                            onChange={this.handleUpdateChange}
                                            margin="none"
                                            fullWidth
                                        />
                                        {updateData.parentId !== 0 && (
                                            <AutoCompleteSelect 
                                                options={parentList}
                                                label='Parent Category *'
                                                onChange={this.handleUpdateSelectChange('parentId')}
                                                error={updateErrors && updateErrors['parentId'] && true}
                                                errorText={updateErrors && updateErrors['parentId']}
                                                value={updateData.parentId}
                                                noClear
                                            />
                                        )}
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} variant="text" color="default"><FAIcon icon="times" size={15} button />Cancel</Button>
                                <Button onClick={() => this.handleConfirmationOpen('update')} color="primary" autoFocus variant="text"><FAIcon icon="check" size={15} button />Update</Button>
                            </DialogActions>
                        </Dialog>
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.update} 
                            success={() => this.handleConfirmationSuccess('update')} 
                            close={() => this.handleConfirmationClose('update')} 
                            title="Update product category?" 
                            message="Are you sure you want to update this product category?"
                        />
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title="Add a new product category?" 
                            message="Are you sure you want to add a new product category?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.update}
                            onClose={() => this.handleSnackbarClose('update')}
                            message='You have successfully updated the product category'
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen.submit}
                            onClose={() => this.handleSnackbarClose('submit')}
                            message='You have successfully added a new product category'
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

export default ProductCategories;