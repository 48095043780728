import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import ViewAction from 'Components/ActionCenter/ViewAction';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import StaffChip from '../Chips/StaffChip';
import TeamChip from '../Chips/TeamChip';
import { putActionStatus } from "Redux/Actions/Notifications/Notifications";
import { updateAction } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = {
    access: {
        manageActions: false,
        editActions: false,
        deleteActions: false
    },
    actions: {},
    dataLoading: true,
    isLoading: true
}

class ViewActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = null;
    }

    componentDidMount = () => {
        Promise.all([
            API.access("view-staff:manage-actions"),
            API.access("actions:edit"),
            API.access("actions:delete")
        ])
        .then(([
            manageActions,
            editActions,
            deleteActions
        ]) => {
            this.setState(
                {
                    access: {
                        ...this.state.access,
                        deleteActions: deleteActions?.data?.has_access ?? false,
                        editActions: editActions?.data?.has_access ?? false,
                        manageActions: manageActions?.data?.has_access ?? false,
                    },
                },
                () => this.loadComponentData(true)
            );
        })
    }

    componentWillUnmount = () => {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    loadComponentData = dataLoading => {

        const {
            status,
            teams 
        } = this.props;
        
        let params = {
            filter: this.props.filter,
            filterId: this.props.filterId,
        }
        if(this.props.outstanding) {
            params = {
                ...params,
                outstanding: true
            }
        }
        if(this.props.completed) {
            params = {
                ...params,
                completed: true
            }
        }
        this.setState({
            dataLoading,
        }, () => {
            API.get('/misc/actions/filtered', {
                props: {
                    cancellation: true
                },
                params
            })
            .then(result => {
                if(result?.data) {

                    let actions = this.props.noGrid ? _.filter(result.data, el => el.t !== "Account Review") : result.data;
                    
                    let tmpActions = [];

                    _.each(actions, (action) => {
                        const relation = this.getRelation(action.r, action.d);

                        let act = {
                            ...action,
                            at:
                                action?.tt > 0
                                    ? teams[action.tt]?.name ?? "-"
                                    : action?.x > 0
                                    ? status[action.x]?.name ?? "-"
                                    : "-",
                            fr: relation.ref,
                            sfr: relation.subRef,
                        };
                        tmpActions.push(act);
                    });

                    actions = tmpActions;

                    this.setState({
                        dataLoading: false,
                        isLoading: false,
                        actions,
                    });
                }
            });
        });
    }

    getRelation = (relation, data) => {
        let icon, prefix, ref, subRef;
        switch (relation) {
            case "contact":
                icon = "user";
                prefix = "Contact";
                ref = `${data.contact_first_name} ${data.contact_last_name} ${data.contact_mobile?.length > 0 ? `| ${data.contact_mobile} ` : (data.contact_telephone > 0 ? `| ${data.contact_telephone} ` : '')}`
                subRef = `${data.customer?.cust_company_name ?? data.supplier?.supp_company_name ?? 'Unknown'}`
                break;
            case "customer":
                icon = "users";
                prefix = "Customer";
                ref = data?.cust_company_name ?? "Unknown";
                break;
            case "supplier":
                icon = "box-check";
                prefix = "Supplier";
                ref = data?.supp_company_name ?? "Unknown";
                break;
            case "product":
                icon = "boxes";
                prefix = "Product";
                ref = data?.default_code?.code_value ?? data.product_name ?? "Unknown";
                ref = `#${ref}`;
                break;
            case "po":
                icon = "purchase";
                prefix = "Purchase Order";
                ref = data?.po_reference ?? "Unknown";
                ref = `Purchase Order #${ref}`;
                subRef = data?.supplier?.supp_company_name ?? null;
                break;
            case "quote":
                icon = "quote";
                prefix = "Quote";
                ref = data?.quote_reference ?? "Unknown";
                ref = `Quote #${ref}`;
                subRef = data?.customer?.cust_company_name ?? null;
                break;
            case "order":
                icon = "order";
                prefix = "Order";
                ref = data?.order_reference ?? "Unknown";
                ref = `Sales Order #${ref}`;
                subRef = data?.customer?.cust_company_name ?? null;
                break;
            case "rma":
                icon = "exchange-alt";
                prefix = "RMA";
                ref = data?.rma_reference ?? "Unknown";
                ref = `RMA #${ref}`;
                subRef = data?.customer?.cust_company_name ?? null;
                break;
            default:
                return false;
        }
        return {
            icon,
            prefix,
            ref,
            subRef,
        };
    };
    
    getTableViewColumns = () => {
        const { access, completed } = this.state;

        const { loggedInStaff, outstanding, status, teams } = this.props;

        const staffId = loggedInStaff?.id ?? null;

        const cols = [];

        cols.push(
            {
                heading: "Date / Time",
                field: (rowData) => rowData.wc,
                fieldFormat: 'datetime',
                sizeToContent: true,
                dataRef: "wc",
            },
            {
                heading: "Action",
                field: (rowData) => rowData.t,
                important: true,
                sizeToContent: true,
                main: true,
                dataRef: "t",
            },
            {
                heading: 'Notes',
                field: rowData => (
                         _.isEmpty(rowData.c) ? 
                            `No notes added for this action`
                        : (
                            rowData.c?.split?.('\n').map((item, key) => (
                                <Box pb={key !== (_.size(rowData.c?.split?.('\n')) - 1) ? 1 : undefined} key={key}>
                                    {item}
                                </Box>
                            ))
                        )
                ),
                cellProps: () => ({
                    style: {
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        overflow: 'hidden',
                    }
                }),
                dataRef: 'c',
                important: true,
            },
            {
                heading: "For",
                field: (rowData) => {
                    return (
                        (rowData.fr && (
                            <>
                                {rowData.fr}
                                {rowData.sfr && (
                                    <Typography component="div" variant="caption">
                                        {rowData.sfr}
                                    </Typography>
                                )}
                            </>
                        )) ||
                        "-"
                    );
                },
                important: true,
                sizeToContent: true,
                dataRef: "fr",
            },
            {
                heading: "Due",
                field: (rowData) =>
                    (!rowData.w && `-`) || (
                        <>
                            {rowData.w}
                            {rowData.q && (
                                <Typography component="div" variant="caption">
                                    (Every {rowData.q} {rowData.u}
                                    {rowData.q > 1 ? `s` : ``})
                                </Typography>
                            )}
                        </>
                    ),
                sizeToContent: true,
                dataRef: "w",
            }
        );

        if (!outstanding) {
            cols.push({
                heading: "Completed",
                field: (rowData) => rowData.z,
                fieldFormat: 'datetime',
                sizeToContent: true,
                dataRef: "z",
            });
        }

        cols.push(
            {
                heading: "Created By",
                field: (rowData) => (status?.[rowData.f]?.name ? <StaffChip staff={status?.[rowData.f]} /> : `-`),
                fieldFormat: "staff",
                important: true,
                sizeToContent: true,
                dataRef: "f",
            },
            {
                heading: "Assigned To",
                field: (rowData) => (
                    <>
                        {rowData?.tt > 0 && <TeamChip team={teams?.[rowData?.tt]} style={{ marginLeft: -2 }} />}
                        {rowData?.x > 0 && rowData.tt <= 0 && (
                            <StaffChip staff={status?.[rowData.x ?? 0] ?? false} style={{ marginLeft: -2 }} />
                        )}
                    </>
                ),
                important: true,
                sizeToContent: true,
                dataRef: "at",
            }
        );
        
        if(!outstanding) {
            cols.push(
                {
                    heading: 'Completed',
                    field: rowData => rowData.z !== "0000-00-00 00:00:00",
                    fieldFormat: 'boolean',
                    sizeToContent: true,
                    alignment: 'center'
                }
            )
        }

        if (completed) {
            cols.push({
                actions: (rowData) => {
                    return [
                        {
                            name: "View",
                            icon: "binoculars",
                            onClick: () => this.handleDeployViewAction(rowData.i),
                            doubleClick: true,
                        },
                    ];
                },
            });
        } else {
            cols.push({
                actions: (rowData) => {
                    return [
                        { 
                            name: "Mark as Completed", 
                            icon: "check", 
                            onClick: () => this.handleTaskComplete(rowData.i),
                            disabled: rowData.z !== "0000-00-00 00:00:00" || (!access.manageActions && (staffId !== rowData.f && staffId !== rowData.x)),
                        },
                        {
                            name: "Edit",
                            icon: "pencil-alt",
                            onClick: () => this.handleTaskUpdate(rowData.i),
                            disabled: rowData.z !== "0000-00-00 00:00:00" || (!access.editActions && (staffId !== rowData.f && staffId !== rowData.x)),
                        },
                        {
                            name: "Delete",
                            icon: "trash",
                            onClick: () => this.handleTaskDelete(rowData.i),
                            disabled: rowData.z !== "0000-00-00 00:00:00" || (!access.deleteActions && (staffId !== rowData.f && staffId !== rowData.x)),
                        },
                        {
                            name: "View",
                            icon: "binoculars",
                            onClick: () => this.handleDeployViewAction(rowData.i),
                            disabled: (!access.manageActions && staffId !== rowData?.f && rowData?.x !== staffId && rowData.tt === 0),
                            doubleClick: true,
                        },
                    ];
                },
            });
        }

        return cols;
    };

    handleDeployViewAction = id => {
        this.props.deployDialog(<ViewAction id={id} callback={this.props.callback} closeDialog={this.props.closeDialog} inDialog />, false, "Action Details", "standard", "xs")
    }

    handleTaskComplete = id => {
        this.props.putActionStatus?.(id);
        this.props.deploySnackBar("success", "The action was successfully marked as completed")
        this.timeout = setTimeout(() => this.loadComponentData(), 250);
    };

    handleTaskDelete = id => {
        this.props.putActionStatus?.(id, true);
        this.props.deploySnackBar("success", "The action was successfully deleted")
        this.timeout = setTimeout(() => this.loadComponentData(), 250);
    };

    handleTaskUpdate = id => {
        this.props.updateAction?.(id);
    };

    render = () => {
        const { completed, dashboard, noPagination, noGrid, outstanding } = this.props;
        const { dataLoading, isLoading, actions } = this.state;

        return (
            !isLoading && (!noGrid || (noGrid && !_.isEmpty(actions))) && (
                (noGrid && (
                    <Grid item xs={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<FAIcon type="light" icon="chevron-down" size="small" noMargin button />}>
                                <Typography variant="h6">
                                    <FAIcon icon="exclamation-circle" type="light" className="textWarning mr-1" size={20} button />
                                    Outstanding Actions ({_.size(actions)})
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <DataTable
                                    config={{
                                        key: "i",
                                        pagination: dashboard || noPagination ? false : true,
                                        isLoading: dataLoading,
                                        alternatingRowColours: true,
                                        responsiveImportance: true,
                                        plainPagination: true,
                                        rowsPerPage: noPagination ? undefined : (dashboard ? 100 : 10),
                                        noResultsText: !completed
                                            ? "There are no outstanding actions to display"
                                            : "There are no actions to display",
                                        inline: true
                                    }}
                                    columns={this.getTableViewColumns()}
                                    rows={actions}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                )) || (
                    <Grid container={noGrid ? undefined : true} item={noGrid ? true : undefined} xs={noGrid ? 12 : undefined} spacing={3}>  
                        <Grid item xs={12}>
                            {(_.isEmpty(actions) && (
                                (!dashboard && (
                                    <Typography variant="h5" className="fw-400" align='center'>There are no {completed ? 'completed ' : (outstanding ? 'outstanding ' : '')}actions to show</Typography>
                                )) || (
                                    <React.Fragment />
                                )
                            )) || (
                                <Paper>
                                    <DataTable
                                        config={{
                                            key: "i",
                                            pagination: dashboard || noPagination ? false : true,
                                            isLoading: dataLoading,
                                            alternatingRowColours: true,
                                            responsiveImportance: true,
                                            plainPagination: true,
                                            rowsPerPage: noPagination ? undefined : (dashboard ? 100 : 10),
                                            noResultsText: !completed
                                                ? "There are no outstanding actions to display"
                                                : "There are no actions to display",
                                            options: {
                                                dataRef: true,
                                                export: {
                                                    excel: true,
                                                    name: `Actions`,
                                                    pdf: true,
                                                    print: true,
                                                    title: `Actions`,
                                                },
                                            },
                                        }}
                                        columns={this.getTableViewColumns()}
                                        rows={actions}
                                    />
                                </Paper>
                            )}
                        </Grid>    
                    </Grid>
                )
            )
        )
    }
}

const mapStateToProps = state => ({
    loggedInStaff: state.staffAuth.staff,
    status: state.notifications.status,
    teams: state.notifications.teams,
})

const mapDispatchToProps = dispatch => (
    {
        closeDialog: () => dispatch(closeDialog()),
        deploySnackBar: (type, message) => dispatch(deploySnackBar(type, message)),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
        updateAction: (id) => dispatch(updateAction(id)),        
        putActionStatus: (id, del = false) => dispatch(putActionStatus(id, del)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(ViewActions);