import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';

const PODeliverySelectionDialog = ({action, handleSubmit, po}) => {

    const [ podId, setPodId ] = useState(null);

    const [ deliveries, setDeliveries ] = useState([]);

    const [ isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleSelectDelivery = id => {
        setPodId(id);
    }
    
    const handleClose = () => {
        dispatch(closeDialog());
    }

    const handleSubmitForm = () => {
        setIsLoading(true);
        handleSubmit(action, podId);
    }

    const welcomeText = () => {
        switch(action) {
            case 'cancelDespatch':
                return 'Cancel Despatch'
            case 'completeDelivery':
                return 'Complete Delivery'
            case 'skipGoodsReceived':
                return 'Skip Goods Received'
            case 'shippedDirect':
                return 'Shipped Direct'
            default: 
                return 'Invalid action type - this will not work... hmm :|';
        }
    }

    const descriptionText = () => {
        switch(action) {
            case 'cancelDespatch':
                return 'This is useful if delivery has been marked as Despatched but hasn\'t actually been shipped by the supplier. You can move back deliveries which are for stock or ship here / complete; and a check date will be auto-set for today for further action.'
            case 'shippedDirect':
                return 'This is useful if the delivery was setup as a ship here and has actually shipped direct (or if the delivery is for digital goods). This will remove stock, adjust stock activity, mark the delivery as delivered, update line quantities for delivered and despatch the sales order delivery.'
            case 'completeDelivery':
                return 'This is useful if you want to move the delivery into Invoiced (and if applicable, will also move the PO into Invoiced); which may be the case if no further invoices are expected to be received - this will not change the quantity invoiced for any line. This delivery must be marked as "Delivered" to enable this functionality - else you need to cancel off outstanding items first!'
            case 'skipGoodsReceived':
                return 'This is useful if you need to mark a delivery as despatched and delivered (fully received) without going through the GRP on the PDA. Ship here sales orders can be manually despatched following this from the sales order menu (if you need to complete both PO / SO). Any stock still on order will be "booked in" and available after using this tool - if stock needs adjusting AA will need to manually adjust out or you can remove stock items / create activity in the DB.'
              default: 
                return '';
        }
    }

    const getExpectedStatus = () => {
        switch(action) {
            case 'cancelDespatch':
                return ['Despatched']
            case 'skipGoodsReceived':
                return ['Despatched']
            case 'completeDelivery':
                return ['Despatched']
            case 'shippedDirect':
                return ['Awaiting Despatch', 'Despatched']
            default: 
                return '';
        }
    }

    useEffect(() => {

        const newDeliveries = [];
        
        const status = getExpectedStatus();

        _.forEach(po.deliveries, pod => {
            if(!status || (status && status.includes(pod.pod_status))) {

                if(action === 'cancelDespatch' && _.some(pod.products, p => p.quantityDelivered > 0)) {
                    return;
                }

                if(action === 'completeDelivery' && pod.pod_delivered === 0) {
                    return;
                }

                if(action === 'skipGoodsReceived' && (pod.pod_ship_aa_only === 0 || pod.pod_delivered === 1)) {
                    return;
                }

                if(action === 'shippedDirect' && (po.po_ship_here_order === 0)) {
                    return;
                }

                newDeliveries.push(_.assign({
                    label: `${pod.pod_reference}/${pod.pod_idx} (${pod.pod_status})`,
                    value: pod.pod_id
                }));

            }
        })

        setDeliveries(newDeliveries);

    }, []); /*eslint-disable-line*/
    
    return (
        <Box mt={1}>
            {(isLoading && (
                <LoadingCircle />
            )) || (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box className="bg-error textLight" p={2} borderRadius={4}>
                            <Typography variant="body2" gutterBottom>
                                <strong>{welcomeText()}</strong>
                            </Typography>
                            <Typography variant="caption">
                                {descriptionText()}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {(_.isEmpty(deliveries) && (
                            <Box className="content-light-grey" p={2}>
                                <Typography variant="body1">
                                    <FAIcon icon="exclamation-circle" type="light" size={17.5}/>
                                    No deliveries are eligible for this action
                                </Typography>
                            </Box>  
                        )) || (
                            <List disablePadding style={{border: '1px solid #ddd'}}>
                                {_.map(deliveries, (pod, idx) => (
                                    <ListItem 
                                        key={idx} 
                                        role={undefined}
                                        dense
                                        button 
                                        style={{background: podId === pod.value ? '#eee' : '#fafafa'}}
                                        onClick={() => handleSelectDelivery(pod.value)}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                color="primary"
                                                checked={podId === pod.value}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={pod.label} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Button
                                    color="primary"
                                    variant="text"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    <FAIcon icon="times" button size={15} />
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Button
                                    className={`btn ${podId === null || _.isEmpty(deliveries) ? 'btn-disabled' : 'btn-success'}`}
                                    color="primary"
                                    fullWidth
                                    onClick={handleSubmitForm}
                                    disabled={!podId || _.isEmpty(deliveries)}
                                >
                                    <FAIcon icon="check" button size={15} />
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Box>
    );

}

export default PODeliverySelectionDialog;