import React from "react";
import { useSelector } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

import AdminActions from "Components/Layout/AdminLayout/AdminActions";
import AdminCallBacks from "Components/Layout/AdminLayout/AdminCallBacks";
import AdminMenu from "Components/Layout/AdminLayout/AdminMenu";
import AdminNotifications from "Components/Layout/AdminLayout/AdminNotifications";
import AdminSmartSearch from "Components/Layout/AdminLayout/AdminSmartSearch";
import Chat from "Components/Layout/Common/Chat";
import LoadingBar from "Components/Layout/Common/LoadingBar";
import User from "Components/Layout/Common/User";
import UserDetails from "Components/Layout/Common/UserDetails";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import logo from "Assets/Images/logo.svg";
import WildixVoip from 'Components/Voip/WildixVoip/WildixVoip';
import { isTablet } from "Functions/MiscFunctions";
import { HEADER_COLOR } from "Constants";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "fixed",
        top: 0,
        webkitOverflowScrolling: "touch",
        webkitTransform: "translateZ(0)",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: "0 4px 2px -2px rgba(0,0,0,0.2)",
    },
    toolbar: {
        height: isTablet() ? 42.5 : 47.5,
        minHeight: isTablet() ? 42.5 : 47.5,
        paddingTop: "env(safe-area-inset-top)",
        paddingRight: theme.spacing(3),
        backgroundColor: HEADER_COLOR,
    },
    logo: {
        width: 65,
        height: "auto",
        marginTop: 6,
        marginLeft: theme.spacing(0.5),
        marginRight: isTablet() ? 50 : 77.5,
    },
    iconContainer: {
        marginLeft: isTablet() ? theme.spacing(.5) : theme.spacing(.75),
        marginRight: isTablet() ? 0 : theme.spacing(.75),
    },
    searchContainer: {
        marginRight: theme.spacing(1),
    },
    menuButton: {
        marginLeft: 2,
    },
    [theme.breakpoints.down("xs")]: {
        iconContainer: {
            display: "none",
        },
        toolbar: {
            paddingRight: theme.spacing(1),
        },
        logo: {
            width: 182,
        },
    },
}));

const AdminHeader = ({ toggleDrawer }) => {
    const classes = useStyles(),
        device = useSelector((state) => state.ui?.device),
        dialog = useSelector((state) => state?.dialog);
    return (
        <AppBar position="absolute" className={classes.appBar} style={{zIndex: dialog?.dialogOpen && !dialog?.dialogFullScreen ? 1500 : undefined}}>
            <Toolbar disableGutters={true} className={classes.toolbar}>
                <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>
                        <IconButton
                            color="inherit"
                            onClick={() => toggleDrawer("nav")}
                            className={`${classes.menuButton} ${device?.isTablet ? `ml-1 mr-1` : `ml-2 mr-2`}`}
                            size={"small"}
                        >
                            <FAIcon
                                type="light"
                                icon="bars"
                                buttonPrimary
                                size={device?.isTablet ? 17.5 : 20}
                                noMargin
                            />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <img
                            alt="RED"
                            className={classes.logo}
                            src={logo}
                            style={{ width: device?.isTablet ? 57.5 : undefined }}
                        />
                    </Grid>
                    <Grid item xs align="right">
                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                            <Grid item align="right" className={classes.searchContainer}>
                                <AdminSmartSearch device={device} />
                            </Grid>
                            <Grid item align="right" className={classes.iconContainer} xs>
                                <WildixVoip />
                            </Grid>
                            <Grid item align="right" className={classes.iconContainer}>
                                <AdminActions device={device} />
                            </Grid>
                            <Grid item align="right" className={classes.iconContainer}>
                                <AdminCallBacks device={device} />
                            </Grid>
                            <Grid item align="right" className={classes.iconContainer}>
                                <AdminNotifications device={device} />
                            </Grid>
                            <Grid item align="right" className={classes.iconContainer}>
                                <Chat device={device} toggleDrawer={() => toggleDrawer("chat")} />
                            </Grid>
                            <Grid item align="right" className={classes.iconContainer}>
                                <User device={device} />
                            </Grid>
                            <Grid item className={`${classes.iconContainer} ml-1`}>
                                <UserDetails device={device} />
                            </Grid>
                            <Grid item align="left" className={classes.iconContainer}>
                                <AdminMenu device={device} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <LoadingBar />
        </AppBar>
    );
};

export default AdminHeader;
