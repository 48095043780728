import _ from 'lodash';
import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddressLookup from 'Components/Common/AddressLookup/AddressLookup';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { handleChange, handleSelectChange } from 'Functions/FormFunctions';

const initialState = () => ({
    confirmation: {
        cancel: false
    },
    formData: {
        id: '',
        name: '',
        addressOne: '',
        addressTwo:  '',
        addressThree:  '',
        town: '',
        county: '',
        country: 234,
        postcode: '',
        notes: '',
        showAddress: false,
        showAddressLookup: true,
        addressComplete: false
    },
    formErrors: {}
})

class AddressDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {
        this.configureComponent();
    }
   
    componentDidUpdate = (prevProps, prevState) => {
        if(JSON.stringify(this.props.formData) !== JSON.stringify(prevProps.formData)) {
            this.configureComponent();
        }
        if(prevState.formData.country !== this.state.formData.country) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    county: ''
                }
            })
        }
    }

    configureComponent = () => {
        const { formData, formErrors } = this.props;
        this.setState({
            formData: {
                ...this.state.formData,
                ...(formData || {}),
            },
            formErrors: formErrors ?? initialState().formErrors
        })
    }
    
    handleManuallyEnterAddress = () => {
        const {
            formData
        } = this.state;
        this.setState({
            formData: {
                ...formData,
                addressComplete:    formData.showAddress ? false : true,
                showAddress:        formData.showAddress ? false : true,
                showAddressLookup:  formData.showAddress ? true : false,
                addressOne:         formData.showAddress ? '' : formData.addressOne,
                addressTwo:         formData.showAddress ? '' : formData.addressTwo,
                addressThree:       formData.showAddress ? '' : formData.addressThree,
                town:               formData.showAddress ? '' : formData.town,
                county:             formData.showAddress ? '' : formData.county,
                postcode:           formData.showAddress ? '' : formData.postcode,
                country:            initialState().formData.country,
            }
        })
    }

    handleSelectedAddress = e => {
        if(e?.value) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    addressOne: e.value.line1,
                    addressTwo:  e.value.line2,
                    addressThree:  e.value.line3,
                    town:  e.value.town,
                    county: _.find(this.props.countyList, {label: e.value.county})?.value ?? '',
                    postcode: e.value.postcode,
                    selectedAddress: e,
                    addressComplete: true,
                    showAddress: true,
                    showAddressLookup: false
                }
            }, () => this.submit());
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    selectedAddress: null,
                    addressComplete: false,
                    showAddress: false,
                    showAddressLookup: true
                }
            });
        }
    }

    cancel = () => {
        this.props.handleCancel(true)
    }
    
    submit = () => {
        this.props.handleSubmit(this.state.formData)
    }

    render() {
        const { countyList, countryList, open, title } = this.props;
        const { formData, formErrors } = this.state
        return (
            <Dialog 
                disableEscapeKeyDown 
                disableBackdropClick 
                open={open}
                maxWidth='xs' 
                scroll="body"
            >               
                <DialogTitle disableTypography>
                    <Typography variant="h6">
                        {title} Address
                    </Typography>
                </DialogTitle>
                <DialogContent style={{padding: '0 24px 24px 24px', minWidth: 400, maxWidth: 500}}>
                    <form noValidate autoComplete="off" onSubmit={this.submit}> 
                        <Grid container spacing={3}>
                            <Grid item xs={12} align='center'>
                                <TextField
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    label="Address name / reference"
                                    onChange={this.handleChange}
                                    margin="none"
                                    fullWidth
                                    autoFocus
                                />
                                {(formData.country === 234 && formData.showAddressLookup && (// United Kingdom
                                    <>
                                        <AddressLookup                                     
                                            handleSelectedAddress={this.handleSelectedAddress}
                                            inlineLayout={false}
                                            value={formData.selectedAddress}
                                            visible={formData.showAddressLookup}
                                            manualEntry={
                                                <Box pb={2} textAlign='center' onClick={this.handleManuallyEnterAddress}>
                                                    <Typography variant="body2" className='blueLink'>
                                                        {formData.showAddressLookup ? 'Manually enter address': 'Use postcode lookup (UK Only)' }
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </>
                                )) || (
                                    (formData.country !== 234 || (formData.country === 234 && formData.showAddress) || formData.showAddress) && (
                                        <>
                                            <Box pb={2} textAlign='center' onClick={this.handleManuallyEnterAddress}>
                                                <Typography variant="body2" className='blueLink'>
                                                    Use address lookup (UK Only)
                                                </Typography>
                                            </Box>
                                            <TextField
                                                id="addressOne"
                                                name="addressOne"
                                                label="Address Line 1 *"
                                                value={formData.addressOne}
                                                error={formErrors && formErrors['addressOne'] && true}
                                                helperText={formErrors && formErrors['addressOne']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="addressTwo"
                                                name="addressTwo"
                                                label="Address Line 2"
                                                value={formData.addressTwo}
                                                error={formErrors && formErrors['addressTwo'] && true}
                                                helperText={formErrors && formErrors['addressTwo']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="addressThree"
                                                name="addressThree"
                                                label="Address Line 3"
                                                value={formData.addressThree}
                                                error={formErrors && formErrors['addressThree'] && true}
                                                helperText={formErrors && formErrors['addressThree']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="town"
                                                name="town"
                                                label="City / Town *"
                                                value={formData.town}
                                                error={formErrors && formErrors['town'] && true}
                                                helperText={formErrors && formErrors['town']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="postcode"
                                                name="postcode"
                                                label="Postcode *"
                                                value={formData.postcode}
                                                error={formErrors && formErrors['postcode'] && true}
                                                helperText={formErrors && formErrors['postcode']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            {formData.country === 234 && (
                                                <AutoCompleteSelect 
                                                    options={countyList} 
                                                    label='County *'
                                                    onChange={v => this.handleSelectChange('county', v)}
                                                    error={formErrors && formErrors['county'] && true}
                                                    errorText={formErrors && formErrors['county']}
                                                    value={formData.county}
                                                />
                                            )}
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={countryList} 
                                                    label='Country *'
                                                    onChange={v => this.handleSelectChange('country', v)}
                                                    error={formErrors && formErrors['country'] && true}
                                                    errorText={formErrors && formErrors['country']}
                                                    value={formData.country}
                                                    noClear
                                                />
                                            </FormControl>
                                        </>
                                    ))}
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button 
                                    onClick={this.cancel} 
                                    variant="text" 
                                    color="primary"
                                >
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                                <Button 
                                    disabled={formData.addressOne === '' || formData.town === '' || formData.postcode === '' || (formData.country === 234 && formData.county === '') || formData.country === ''} 
                                    variant="text" 
                                    color="primary"
                                    type="submit"
                                >
                                    <FAIcon icon="check" size={15} button />
                                    {!_.isEmpty(this.props.formData?.addressOne) ? 'Update Address ' : 'Add Address'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>         
        )
    }
}

export default AddressDialog;