import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import EmailTemplateForm from 'Components/Communication/Misc/EmailTemplateForm';

const initialState = {
    access: {
        addEmailTemplate: false,
        deleteEmailTemplate: false,
        updateEmailTemplate: false,
    },
    searchResults: {},
    searchString: '',
    dataLoading: true,
}

class EmailTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentDidMount = () => {
        Promise.all([
            API.access('email-templates:add'),
            API.access('email-templates:delete'),
            API.access('email-templates:update')
        ])
        .then(([addEmailTemplate, deleteEmailTemplate, updateEmailTemplate]) => {
            this.setState({
                access: {
                    addEmailTemplate: addEmailTemplate?.data?.has_access ?? false,
                    deleteEmailTemplate: deleteEmailTemplate?.data?.has_access ?? false,
                    updateEmailTemplate: updateEmailTemplate?.data?.has_access ?? false,
                }
            }, () => {
                this.getEmailTemplates(true);
            })
        })
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getEmailTemplates = dataLoading => {
        this.setState({
            dataLoading,
        }, () => {
            API.get('/email/templates', {
                params: { 
                    searchString: this.state.searchString
                } 
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    });
                }
            });
        });
    }

    handleDeleteEmailTemplate = id => {
        API.post(`/email/templates/${id}/delete`)
        .then(res => {
            if(res?.data) {
                this.getEmailTemplates(false);
                this.props.deploySnackBar("success", "You have successfully deleted the e-mail template")
            }
        })
    }

    handleDeployEmailTemplate = id => this.props.deployDialog(
        <EmailTemplateForm
            id={id}
            callback={this.getEmailTemplates}
            closeDialog={this.props.closeDialog}
            deployConfirmation={this.props.deployConfirmation}
            deploySnackBar={this.props.deploySnackBar}
        />, false, `${id ? 'Update' : 'Add'} E-mail Template`, "standard", "md", false, true);

    onSearchStringChange = event => {
        this.setState({
            searchString: event?.target?.value ?? ''
        },
        () => {
            if(this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => this.getEmailTemplates(true), 200);
        })
    }

    render() {
        const { access, dataLoading, searchResults, searchString } = this.state;
        return (
            <Grid container spacing={1}>      
                <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                        <TextField
                            fullWidth
                            onChange={this.onSearchStringChange}
                            placeholder='Search:'
                            value={searchString} 
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                            }}
                        />
                    </Grid>   
                    {access.addEmailTemplate && (
                    <Grid item xs={12} sm={7} md={8} xl={9} align='right'>
                            <Button 
                                color="primary"
                                size="small"
                                variant="text"
                                onClick={() => this.handleDeployEmailTemplate(false)}
                            >
                                <FAIcon type="light" icon="plus-circle" size="small" button />
                                Add Template
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <DataTable
                            config={{
                                key: 'et_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                rowsPerPage: 100,
                                options: {
                                    dataRef: true,
                                    export: {
                                        title: `E-mail Templates`,
                                        name: `E-mail-Templates`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.et_name,
                                    dataRef: 'et_name',
                                    important: true,
                                    main: true
                                },
                                {
                                    heading: 'Default Subject',
                                    field: rowData => rowData.et_subject,
                                    dataRef: 'et_subject',
                                    important: true,
                                    main: true
                                },
                                {
                                    heading: 'Default Sender Address',
                                    field: rowData => rowData.et_send_from,
                                    dataRef: 'et_send_from',
                                },
                                {
                                    heading: 'Applicable To',
                                    field: rowData => rowData.et_type,
                                    dataRef: 'et_type',
                                },
                                {
                                    heading: 'Required',
                                    field: rowData => rowData.et_system,
                                    fieldFormat: 'boolean',
                                    dataRef: 'et_system',
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Modify', icon: 'pencil', onClick: rowData => this.handleDeployEmailTemplate(rowData.et_id), doubleClick: true, disabled: !access.updateEmailTemplate},
                                            {name: 'Delete', icon: 'trash-alt', onClick: rowData => this.props.deployConfirmation("Are you sure you want to delete this e-mail template?", () => this.handleDeleteEmailTemplate(rowData.et_id)), disabled: !access.deleteEmailTemplate || rowData.et_system === 1}
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default EmailTemplates;