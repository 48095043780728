import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { useDispatch, useSelector } from 'react-redux';
import { updateStaffConfig } from 'Redux/Actions/Notifications/Notifications';

const WildixMenu = ({ handleClose, handleDisconnect, handleDeployStatusChange, handleToggleCallCentre }) => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.notifications.config);
    const toggleCallNotificationPopOut = () => {
        dispatch(updateStaffConfig(
            {...config, 
                voip: { 
                    ...config?.voip,
                    popOut: (config?.voip?.popOut ?? 0) === 1 ? 0 : 1
                }
            }
        ))
    }
    const toggleCallDesktopNotification = () => {
        dispatch(updateStaffConfig(
            {...config, 
                voip: { 
                    ...config?.voip,
                    disableDesktop: (config?.voip?.disableDesktop ?? 0) === 1 ? 0 : 1
                }
            }
        ))
    }
    return (
        <>  
            <Box align="left" mb={3}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            className="m-0"
                            control={
                                <Switch color="primary" checked={config?.voip?.popOut === 1} onChange={toggleCallNotificationPopOut} value="1" />
                            }
                            label={<Typography variant="body2" className="fw-400">Pop Out Call Notifications</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            className="m-0"
                            control={
                                <Switch color="primary" checked={config?.voip?.disableDesktop === 1} onChange={toggleCallDesktopNotification} value="1" />
                            }
                            label={<Typography variant="body2" className="fw-400">Disable Desktop Notifications</Typography>}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <List component="nav">
                <ListItem button onClick={handleToggleCallCentre}>
                    <ListItemIcon>
                        <FAIcon type="light" icon="user-headset" button size={15} /> 
                    </ListItemIcon>
                    <ListItemText primary="Queued Calls" />
                </ListItem>
                <ListItem button onClick={handleDeployStatusChange}>
                    <ListItemIcon>
                        <FAIcon type="light" icon="user" button size={15} /> 
                    </ListItemIcon>
                    <ListItemText primary="Change Status" />
                </ListItem>
                <ListItem button onClick={() => handleDisconnect(true)}>
                    <ListItemIcon>
                        <FAIcon type="light" icon="sign-out-alt" button size={15} /> 
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </ListItem>
                <ListItem button onClick={handleClose}>
                    <ListItemIcon>
                        <FAIcon type="light" icon="times" button size={15} /> 
                    </ListItemIcon>
                    <ListItemText primary="Close" />
                </ListItem>
            </List>     
        </>                 
    );
}

export default WildixMenu;
