import React from 'react';
import InsightChip from 'Components/Common/Chips/InsightChip';

const ProductStockChip = ({basedOnStatus, bundle, bundleText, deleted, qty, rep, status, stocked, stockAvailable, stockOnOrderAvailable}) => {
    return (
    (bundle && (
        <InsightChip 
            background={status === 'IS' ? '#4CAF50' : (status === 'OOS' ? '#ef3340' : (status === 'PRE' ? '#F57F17' : '#673AB7'))}
            color='#fff'
            icon={status === 'IS' ? 'check' : (status === 'OOS' ? 'exclamation-triangle' : (status === 'PRE' ? 'info-circle' : 'times'))}
            label={status === 'IS' ? `${bundleText ?? ''} In Stock - Available` : (status === 'OOS' ? `${bundleText ?? ''} Out Of Stock` : (status === 'PRE' ? `${bundleText ?? ''} Pre-order` : `${bundleText ?? ''} ${status}`))}
            marginRight={8}
            paddingLeft={8}
            paddingRight={4}
        />
    )) || (basedOnStatus && (
        <InsightChip 
            background={status === 'TU' ? '#673AB7' : (status === 'IS' ? '#4CAF50' : (status === 'OOS' ? '#ef3340' : (status === 'NS' ? '#039BE5' : (status === 'PRE' ? '#F57F17' : '#673AB7'))))}
            color='#fff'
            icon={status === 'TU' ? 'exclamation-triangle' : (status === 'IS' ? 'check' : (status === 'OOS' ? 'exclamation-triangle' : (status === 'NS' ? 'info-circle' : (status === 'PRE' ? 'info-circle' : 'times'))))}
            label={status === 'TU' ? 'Temporarily Unavailable' : (status === 'IS' ? 'In Stock - Available' : (status === 'OOS' ? 'Out Of Stock' : (status === 'NS' ? 'Not Stocked' : (status === 'PRE' ? 'Pre-order' : (deleted ? 'Deleted Product' : status)))))}
            marginRight={8}
            paddingLeft={8}
            paddingRight={4}
        />
    )) || (
        <InsightChip 
            background={status === 'Active' ? (rep > 0 ? '#673AB7' : ((stocked ? (stockAvailable > 0 ? '#4CAF50' : (stockOnOrderAvailable > 0 ? '#F57F17' : '#ef3340')) : '#039BE5'))) : '#673AB7'}
            color='#fff'
            icon={status === 'Active' ? (rep > 0 ? 'exclamation-triangle' : ((stocked ? (stockAvailable > 0 ? 'check' : (stockOnOrderAvailable > 0 ? 'info-circle' : 'exclamation-triangle')) : 'info-circle'))) : 'times'}
            label={status === 'Active' ? (rep > 0 ? 'Temporarily Unavailable' : ((stocked ? (stockAvailable > 0 ? `In Stock - ${stockAvailable} Available` : (stockOnOrderAvailable > 0 ? 'Pre-order' : 'Out Of Stock')) : 'Not Stocked'))) : (deleted ? 'Deleted Product' : status)}
            marginRight={8}
            paddingLeft={8}
            paddingRight={4}
        />
    )
)
    }

export default ProductStockChip;