import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Alert from 'Components/Common/Alert/Alert';
import API from 'API';
import AppButton from 'Components/Common/Buttons/AppButton';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import QrReaderDialog from 'Components/Common/Qr/QrReaderDialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { playSound } from 'Redux/Actions/Sounds/Sounds';

/* PO Status will be the prop unless the user selects one from the dropdown menu when the state value is used */
const initialState = () => ({
    dataLoading: true,
    searchString: '',
    searchResults: [],
    type: 'POD'
})

class Incoming extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.pulseResults = null;
    }

    componentDidMount = () => {
        const {
            type
        } = this.props;
        this.setState({
            type
        }, () => {
            this.props.pageTitle?.([1, 'Warehouse', `Goods In - ${type === 'POD' ? 'Purchase Orders' : 'RMA'}`])
            this.getSearchData();
            this.pulseResults = setInterval(() => this.getSearchData(true), 7500);
            this.timeout = null;
        });
    }

    componentWillUnmount = () => {
        if(this.pulseResults)
            clearInterval(this.pulseResults);
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    getSearchData = (noLoading = false) => {

        const { 
            type
        } = this.state;

        let poParams = {
            all: false,
            d: 'allTime',
            due: true,
            shipHere: true,
            status: 'Despatched',
            searchString: this.state.searchString,
            warehouse: true
        }

        let rmaParams = {
            d: 'allTime',
            searchString: this.state.searchString,
            warehouse: true
        }

        let apiCall = null;

        if(type === "POD") {
            apiCall = API.get('/purchaseOrders', {
                params: {
                    ...poParams
                },
                props: {
                    noLoading,
                    cancellation: true
                }               
            });
        } else {
            apiCall = API.get('/rma', {
                params: {
                    ...rmaParams
                },
                props: {
                    noLoading,
                    cancellation: true
                }               
            })
        }

        this.setState({
            dataLoading: noLoading ? false : true
        }, () => {

            apiCall.then(result =>  {

                const searchResults = result?.data ?? [],
                        dataLoading = false;

                this.setState({
                    searchResults,
                    dataLoading
                })

            })

        })

    }

    deployQrDialog = () => {

        const {
            deployDialog,
            ui
        } = this.props;

        deployDialog(
            <QrReaderDialog
                app
                delay={0}
                isPDA={ui?.device?.isPDA}
                handleScan={this.qrReaderScan}
                text='Please scan the RMA QR code'
            />,
            false,
            'RMA Received',
            'standard',
            'sm'
        )
    }

    qrReaderScan = scanData => {
        if(scanData !== null) {
            const { searchResults } = this.state;
            const { closeDialog, deploySnackBar, history, playSound } = this.props;
            
            let rma = _.find(searchResults, el => el.ref === scanData);
            if(rma) {
                playSound('pickSuccess', true);
                deploySnackBar("success", `Found RMA #${rma.ref}`);
                history.push({
                    pathname: `/warehouse/incoming/rma/${rma.id}`,
                    state: { qrScanned: 1 }
                })
                closeDialog();
            } else {
                playSound('pickError', true);
                deploySnackBar("error", "The scanned RMA could not be found, please try again");
            }
        }
    }

    handleReset = () => {
        this.setState({
            searchString: initialState().searchString
        },
        () => {
            this.setSearch();
        });
    }
    
    onSearchStringChange = e => {
        this.setState({
            searchString: e.target.value
        },
        () => {
            this.setSearch();
        });
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    render() {
        const { warehouseMode } = this.props;
        let { dataLoading, searchResults, searchString, type } = this.state;
        return (
            <Grid container spacing={warehouseMode ? 3 : 1}>      
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        onChange={this.onSearchStringChange}
                        placeholder='Search:'
                        value={searchString} 
                        variant="filled"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>,
                            endAdornment: <InputAdornment position="start"><IconButton onClick={this.handleReset} disabled={searchString === ''}><FAIcon type="light" icon="times" size="small" button noMargin /></IconButton></InputAdornment>
                        }}
                    /> 
                </Grid>
                {(type === 'POD' || (type === 'RMA' && _.isEmpty(searchResults))) && (
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                )}
                {(dataLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        {(_.isEmpty(searchResults) && (
                            <Grid item xs={12}>
                                <Alert severity="success" border>
                                    <span className="fw-400">
                                        No {type === `POD` ? `purchase order deliveries` : `returns`} were found
                                    </span>
                                </Alert>
                                <Box pt={3}>
                                    <AppButton
                                        onClick={() => this.props.history.push('/dashboard')}
                                        text={`Main Menu`}
                                        back
                                    />
                                </Box>
                            </Grid>
                        )) || (
                            <>
                                {type === "RMA" && (
                                    <>
                                        <Grid item xs={12}>
                                            <AppButton
                                                className='btn btn-success'
                                                icon='qrcode'
                                                onClick={this.deployQrDialog}
                                                primary
                                                text={`Scan RMA QR Code`}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </>
                                )}
                                {_.map(searchResults, (row, idx) => (
                                    <Grid item xs={12} key={idx}>
                                        <Card onClick={() => this.props.history.push(`/warehouse/incoming/${type === 'POD' ? `purchase-order` : `rma`}/${row.id}`)} className="link">
                                            <Box p={2}>
                                                <Grid container alignItems='center' justify='space-between'>
                                                    <Grid item xs={12}>
                                                        <Box pb={0.5}>
                                                            <Typography variant="body1" className="fw-400 textDefault">
                                                                {type === "POD" ? `Purchase Order Delivery` : `RMA`} #{row.ref}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption">
                                                            {row.despatched ? `Despatched ${row.despatched}` : `Created ${row.created}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption">
                                                            {row.supplier ?? row.customer?.cust_company_name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <AppButton
                                        onClick={() => this.props.history.push('/dashboard')}
                                        back
                                        text={`Main Menu`}
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    ui: state.ui,
})

const mapDispatchToProps = dispatch => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen)),
        playSound: (type, state = false) => dispatch(playSound(type, state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Incoming);