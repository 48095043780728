import React from 'react';
// import { useSelector } from 'react-redux';

import Search from 'Components/Common/Search/Search';
// import StaffChip from 'Components/Common/Chips/StaffChip';

const AverageTimeToPay = () => {
    // const staff = useSelector(state => state.notifications.status);
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/averageTimeToPay',
                noAutoLoad: true,
                columns: [
                    {
                        heading: 'Account Name',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.sgr,
                        dataRef: 'sgr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Average Days To Pay',
                        field: rowData => rowData.d,
                        dataRef: 'd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Last Payment Date',
                        field: rowData => rowData.lp,
                        fieldFormat: 'date',
                        dataRef: 'lp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.cac,
                        dataRef: 'cac',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Type',
                        field: rowData => rowData.at,
                        dataRef: 'at',
                        important: true,
                        sizeToContent: true
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'average-time-to-pay',
                noResultsText: 'Sorry, no average payment data is available to view',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Customers'],
                pdf: true,
                persistenceId: 'averageTimeToPay',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: true,
                showSupplier: false,
                showTotals: false,
                title: 'Average Time To Pay Report'
            }}
        />
    )
}

export default AverageTimeToPay;