import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MaterialLink from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import NotificationBadge from 'Components/Common/Badges/NotificationBadge';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { updateStaffConfig } from 'Redux/Actions/Notifications/Notifications';
import { clearPersistence } from 'Redux/Actions/StatePersistence/StatePersistence';

const styles = theme => ({
    link: {
        marginLeft: 0,
        textDecoration: 'none!important',
        color: '#000'
    },
    subLink: {
        textDecoration: 'none!important',
        color: '#000'
    },
});

const initialState = {
    open: false,
}
class AdminNavItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        if(this.props.navId === this.props.selectedNavId){
            this.setState({
                open: true,
            });
        } else {
            if(this.props.selectedNavId === false){
                if(typeof this.props.submenu !== "undefined"){ 
                    this.props.submenu.forEach(menu => {
                        if(menu.access_route.length > 0 && this.props.pagePath.startsWith(menu.access_route)){
                            this.props.onClick(this.props.navId);
                        }
                    })
                }
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.selectedNavId !== this.props.selectedNavId){
            if(this.props.navId === this.props.selectedNavId) {
                this.setState({
                    open: true,
                });
            } else {
                if(this.state.open === true){
                    this.setState({
                        open: false,
                    });
                }
            }
        }
    }

    checkExpand() {
        if(typeof(this.props.link) === "undefined") {
            return(
                <>
                    {this.state.open ? <FAIcon type="light" size="small" icon="chevron-up" noMargin button /> : <FAIcon type="light" size="small" icon="chevron-down" noMargin button />}
                </>
            );
        }
    }

    checkNavOpen() {
        if(this.props.navOpen === false) {
            return false
        } else {
            return this.state.open
        }
    }

    render() {

        const { classes, config, notifications, updateStaffConfig, ui } = this.props;
        let subNotifications = 0, 
            toggleButton = null;

        if(typeof(this.props.submenu) !== undefined) {
            _.each(this.props.submenu, sub => {
                let subRef = sub.access_reference;
                if(subRef === 'awaiting-approval-quotes' && config?.badges?.showAllQuotes === 0) {
                    subRef = 'my-awaiting-approval-quotes'
                }
                if(subRef === 'draft-quotes' && config?.badges?.showAllQuotes === 0) {
                    subRef = 'my-draft-quotes'
                }
                if(notifications[subRef] && notifications[subRef] > 0) {
                    subNotifications = (subNotifications + notifications[subRef])
                }
            })
        }

        if(subNotifications === 0) {
            subNotifications = notifications[this.props.reference] ?? 0
        }

        if(this.props?.reference === 'action-center') {
            let now = moment();
            subNotifications = _.size(_.filter(this.props.actions, el => (el.w && moment(el.w, "DD/MM/YYYY HH:mm").isBefore(now)) || !el.w))
        }

        if(this.props?.reference === 'quotes') {
            toggleButton = (
                <Tooltip title={(config?.badges?.showAllQuotes ?? 1) === 1 ? `Showing All Quotes` : `Showing My Quotes`}>
                    <Switch
                        color="primary"
                        checked={(config?.badges?.showAllQuotes ?? 1) === 1}
                        onClick={
                            e => { 
                                e?.stopPropagation?.(); 
                                updateStaffConfig(
                                    {...config, 
                                        badges: { 
                                            ...config?.badges,
                                            showAllQuotes: (config?.badges?.showAllQuotes ?? 1) === 1 ? 0 : 1 ?? 1
                                        }
                                    }
                                )
                            }
                        }
                        size="small"
                    />
                </Tooltip>
            )
        }

        if(this.props?.reference === 'customer-search') {
            toggleButton = (
                <Tooltip title={(config?.badges?.showAllCustomers ?? 1) === 1 ? `Showing All Customers` : `Showing My Customers`}>
                    <Switch
                        color="primary"
                        checked={(config?.badges?.showAllCustomers ?? 1) === 1}
                        onClick={
                            e => { 
                                e?.stopPropagation?.(); 
                                updateStaffConfig(
                                    {...config, 
                                        badges: { 
                                            ...config?.badges,
                                            showAllCustomers: (config?.badges?.showAllCustomers ?? 1) === 1 ? 0 : 1 ?? 1
                                        }
                                    }
                                )
                            }
                        }
                        size="small"
                    />
                </Tooltip>
            )
        }

        if(this.props?.reference === 'new-business') {
            toggleButton = (
                <Tooltip title={(config?.badges?.showAllProspects ?? 1) === 1 ? `Showing All New Business Accounts` : `Showing My New Business Accounts`}>
                    <Switch
                        color="primary"
                        checked={(config?.badges?.showAllProspects ?? 1) === 1}
                        onClick={
                            e => { 
                                e?.stopPropagation?.(); 
                                updateStaffConfig(
                                    {...config, 
                                        badges: { 
                                            ...config?.badges,
                                            showAllProspects: (config?.badges?.showAllProspects ?? 1) === 1 ? 0 : 1 ?? 1
                                        }
                                    }
                                )
                            }
                        }
                        size="small"
                    />
                </Tooltip>
            )
        }

        return (
            <>
                <div id={`adminNavItem-${this.props.navId}`}>
                    {(typeof(this.props.link) !== "undefined" && ( 
                        (this.props.link.startsWith('http://') && (
                            <MaterialLink 
                                className={classes.link} 
                                href={this.props.link} 
                                style={{color: (((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath)) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? '#ef3340': 'inherit'}}
                                target='_blank' 
                            > 
                                <ListItem 
                                    button 
                                    onClick={() => { typeof this.props.submenu !== "undefined" && this.props.onClick(this.props.navId) }} 
                                    style={{
                                        maxHeight: ui?.device?.isTablet ? 30 : 36,
                                        paddingTop: 5, 
                                        paddingBottom: 5, 
                                        borderLeft: `2px solid #${(((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath)) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? 'ef3340' : 'fff'}`
                                    }}
                                >
                                    <ListItemIcon> 
                                        <FAIcon 
                                            icon={this.props.icon} 
                                            type="light" 
                                            size={17.5}
                                            button 
                                            fixedWidth 
                                            noMargin 
                                        />
                                    </ListItemIcon>
                                    <ListItemText 
                                        disableTypography
                                        primary={<Typography variant="body2" style={{color: (((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath)) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? '#ef3340': 'inherit'}}>{this.props.name}{toggleButton}</Typography>} 
                                    />
                                    {<NotificationBadge variant={this.props.navOpen ? `standard` : `dot`} color="error" badgeContent={subNotifications} style={{position: 'absolute', right: this.props.navOpen ? 50 : 9, top: '50%'}} />}
                                    {this.checkExpand()} 
                                </ListItem>
                            </MaterialLink>
                        )) || (
                            <Link 
                                className={classes.link} 
                                style={{color: this.state.open === true ? '#ef3340': undefined}}
                                to={this.props.link} 
                                onClick={this.props.clearPersistence}
                            > 
                                <ListItem 
                                    button 
                                    onClick={() => { typeof this.props.submenu !== "undefined" && this.props.onClick(this.props.navId) }} 
                                    style={{
                                        maxHeight: ui?.device?.isTablet ? 30 : 36,
                                        paddingTop: 5, 
                                        paddingBottom: 5, 
                                        borderLeft: `2px solid #${(((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath)) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? 'ef3340' : 'fff'}`
                                    }}
                                >
                                    <ListItemIcon> 
                                        <FAIcon 
                                            icon={this.props.icon} 
                                            type="light" 
                                            size={17.5}
                                            button 
                                            fixedWidth 
                                            noMargin 
                                        />
                                    </ListItemIcon>
                                    <ListItemText 
                                        disableTypography
                                        primary={<Typography variant="body2" style={{color: (((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath)) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? '#ef3340': 'inherit'}}>{this.props.name}{toggleButton}</Typography>} 
                                    />
                                    {<NotificationBadge variant={this.props.navOpen ? `standard` : `dot`} color="error" badgeContent={subNotifications} style={{position: 'absolute', right: this.props.navOpen ? 50 : 9, top: '50%'}} />}
                                    {this.checkExpand()} 
                                </ListItem>
                            </Link>
                        )
                    )) || (
                        <ListItem 
                            button 
                            onClick={() => { typeof this.props.submenu !== "undefined" && this.props.onClick(this.props.navId) }} 
                            style={{
                                maxHeight: ui?.device?.isTablet ? 30 : 36,
                                paddingTop: 5, 
                                paddingBottom: 5, 
                                borderLeft: `2px solid #${(((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath)) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? 'ef3340' : 'fff'}`
                            }}
                        >
                            <ListItemIcon> 
                                <FAIcon 
                                    icon={this.props.icon} 
                                    type="light" 
                                    size={17.5}
                                    button 
                                    fixedWidth 
                                    noMargin 
                                />
                            </ListItemIcon>
                            <ListItemText 
                                disableTypography
                                primary={<Typography variant="body2" style={{color: (((this.state.open && _.some(this.props.submenu, el => el.access_route === this.props.pagePath)) || (this.props.pagePath === this.props.link || (this.props?.link?.match(/\//g)?.length > 1 && this.props.pagePath.startsWith(this.props.link))))) ? '#ef3340': 'inherit'}}>{this.props.name}{toggleButton}</Typography>} 
                            />
                            {<NotificationBadge variant={this.props.navOpen ? `standard` : `dot`} color="error" badgeContent={subNotifications} style={{position: 'absolute', right: this.props.navOpen ? 50 : 9, top: '50%'}} />}
                            {this.checkExpand()} 
                        </ListItem>
                    )}
                    {typeof this.props.submenu !== "undefined" ? this.props.submenu.map(menu => {
                        let badgeRef = menu.access_reference;
                        if(badgeRef === 'awaiting-approval-quotes' && config?.badges?.showAllQuotes === 0) {
                            badgeRef = 'my-awaiting-approval-quotes'
                        }
                        if(badgeRef === 'draft-quotes' && config?.badges?.showAllQuotes === 0) {
                            badgeRef = 'my-draft-quotes'
                        }
                        return (menu.access_display_on_nav && (
                            (menu.access_reference === 'my-quotes' && config?.badges?.showAllQuotes === 0 && (
                                <React.Fragment />
                            )) || (
                                <React.Fragment 
                                    key={menu.access_id}
                                >
                                    <Collapse 
                                        in={this.checkNavOpen()} 
                                        unmountOnExit
                                    >
                                        <List component="div" disablePadding>
                                            {(menu.access_route.startsWith('http') && (
                                                <MaterialLink 
                                                    className={classes.subLink} 
                                                    href={menu.access_route} 
                                                    target="_blank"
                                                >
                                                    <ListItem 
                                                        button 
                                                        style={{paddingTop: 5, paddingBottom: 5, maxHeight: ui?.device?.isTablet ? 30 : 36}}
                                                    >
                                                        <ListItemText 
                                                            disableTypography
                                                            inset
                                                            primary={<Typography variant="caption">{menu.access_name}</Typography>} 
                                                        />
                                                    </ListItem>
                                                    {menu.access_display_divider === 1 &&
                                                        <Divider />
                                                    }
                                                </MaterialLink>
                                            )) || (
                                                <Link 
                                                    className={classes.subLink} 
                                                    to={menu.access_route}
                                                    onClick={this.props.clearPersistence}
                                                >
                                                    <ListItem 
                                                        button 
                                                        style={{paddingTop: 5, paddingBottom: 5, maxHeight: ui?.device?.isTablet ? 30 : 36}}
                                                    >
                                                        <ListItemText 
                                                            disableTypography
                                                            inset
                                                            primary={
                                                                <Typography variant="caption" component="div" style={{position: 'relative', color: this.props.color}}>
                                                                    {(this.props.pagePath === menu.access_route || (menu.access_route.match(/\//g).length > 1 && this.props.pagePath.startsWith(menu.access_route))) && (
                                                                        <div style={{position: 'absolute', top: -2, left: -24}}><FAIcon type='solid' icon="circle" style={{color: this.props.color, height: 7, width: 7}} noMargin /></div>
                                                                    )}
                                                                    {(menu.access_reference === 'search-quotes' && config?.badges?.showAllQuotes === 0 && (
                                                                        'My Quotes'
                                                                    )) || (
                                                                        menu.access_name
                                                                    )}
                                                                    {<NotificationBadge color="error" badgeContent={notifications?.[badgeRef] ?? 0} style={{position: 'absolute', right: 6, top: '50%'}} />}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                    {menu.access_display_divider === 1 &&
                                                        <Divider />
                                                    }
                                                </Link>
                                            )}
                                        </List>
                                    </Collapse>
                                </React.Fragment>  
                            )
                        )) || (
                            null
                        );
                    }) : null}                        
                    {this.props.access_display_divider === 1 &&
                        <Divider />
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    actions: state.notifications.actions,
    config: state.notifications.config,
    ui: state.ui
})

const mapDispatchToProps = dispatch => ({
    updateStaffConfig: config => dispatch(updateStaffConfig(config)),
    clearPersistence: () => dispatch(clearPersistence())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminNavItem));