import React from 'react';

import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const Button = ({disabled, submit, text}) => (
    <Box pt={3} pl={3} pr={3}>
        <MuiButton
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => submit()}
            disabled={disabled}
        >
            <FAIcon icon="check" disabled={disabled} size={15} buttonPrimary />
            {text}
        </MuiButton>
    </Box>
)

export default Button;