import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { handleChange, handleSelectChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { closeDialog } from 'Redux/Actions/Dialog/Dialog';

const initialState = (loggedInUser) => ({
    contacts: [],
    formData: {
        contact: '',
        staff: loggedInUser.id,
        notes: '',
        date: null,
    },
    formErrors: [],
    isLoading: false
})

class VisitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(this.props.loggedInUser);
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {
        
        const {
            customer,
            visit
        } = this.props;

        API.post(`/misc/calls/contacts`, {
            callRel: 'customer',
            callRelId: customer
        })
        .then(res => {
            if(res?.data) {
                this.setState({
                    contacts: _.map(res.data, c => _.assign({ value: c.contact_id, label: `${c.contact_first_name} ${c.contact_last_name}` }))
                }, () => {
                    if(visit) {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                contact: visit.ci,
                                staff: visit.s,
                                notes: visit.n === '-' ? '' : visit.n,
                                date: visit.d,
                            }
                        })
                    }
                })
            }
        })
                
    }

    handleSubmit = () => {

        this.setState({
            isLoading: true
        }, () => {
            
            const {
                callback,
                customer,
                deploySnackBar,
                loggedInUser,
                visit,
            } = this.props;

            const {
                formData
            } = this.state;

            let apiRoute = `/customers/${customer}/visits`;

            if(visit) {
                apiRoute += `/${visit.i}`;
            }

            API.post(apiRoute, formData)
            .then(res => {
                if(res?.data) {
                    if(res.data?.errors) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                            isLoading: false
                        });
                    } else {
                        this.setState(initialState(loggedInUser), () => {
                            deploySnackBar("success", `The visit was successfully ${visit ? 'updated' : 'recorded'}`)
                            callback?.();
                        })
                    }
                }
            })
            
        })
    }
    
    render = () => {

        const { 
            closeDialog, 
            deployConfirmation, 
            staffList, 
            visit 
        } = this.props;
        
        const { 
            contacts, 
            formData, 
            formErrors, 
            isLoading 
        } = this.state;

        return (
            <Paper>
                <Box p={3}>
                    <Typography variant="h6" paragraph>
                        {visit ? 'Update' : 'Record'} Visit
                    </Typography>
                    {(isLoading && (
                        <Box py={16}>
                            <LoadingCircle />
                        </Box>
                    )) || (
                        <>
                            <form noValidate autoComplete="off">
                                <Box mb={2}>
                                    <DatePicker
                                        type='date'
                                        name="date"
                                        label="Visit Date *"
                                        value={formData.date}
                                        errorText={formErrors && formErrors['date']}
                                        onChange={date => this.handleChange({ target: { name: 'date', value: date ? moment(date).format('YYYY-MM-DD') : null } })}
                                        autoOk
                                    />
                                </Box>
                                <AutoCompleteSelect
                                    options={staffList}
                                    value={formData.staff}
                                    onChange={o => this.handleSelectChange('staff', o)}
                                    noClear
                                    noDefaultSort
                                    label="Staff *"
                                />
                                <AutoCompleteSelect
                                    options={contacts}
                                    value={formData.contact}
                                    onChange={o => this.handleSelectChange('contact', o)}
                                    noDefaultSort
                                    label="Contact"
                                />
                                <Box mt={2}>
                                    <Textarea
                                        name="notes"
                                        label="Notes"
                                        margin="none" 
                                        autoComplete="off"
                                        error={formErrors && formErrors['notes'] && true}
                                        helperText={formErrors && formErrors['notes']}
                                        value={formData.notes}
                                        onChange={this.handleChange} 
                                        fullWidth
                                        rows={3}
                                        variant="outlined"
                                    />
                                </Box>
                            </form>
                            <div className="buttonRow">
                                <Button 
                                    onClick={closeDialog} 
                                    variant="text" 
                                >
                                    <FAIcon icon="times" size={15} button />
                                    Cancel
                                </Button>
                                <Button 
                                    color="primary"
                                    disabled={formData.date === null || formData.staff === ''}
                                    onClick={() => deployConfirmation(`Are you sure you want to ${visit ? `update` : `record`} this visit?`, this.handleSubmit)} 
                                    variant="text" 
                                >
                                    <FAIcon 
                                        icon="check" 
                                        size={15} 
                                        disabled={formData.date === null || formData.staff === ''}
                                        button
                                    />
                                    {visit ? 'Update' : 'Record'} Visit
                                </Button>
                            </div>
                        </>
                    )}
                </Box>
            </Paper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.staffAuth.staff,
        staffList: state.notifications.staffList,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitForm);