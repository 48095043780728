import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Switch from "@material-ui/core/Switch";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Alert from 'Components/Common/Alert/Alert';
import DataTable from 'Components/Common/DataTables/DataTable';
import AddressDialog from 'Components/Common/Dialogs/AddressDialog';
import AliasDialog from 'Components/Common/Dialogs/AliasDialog';
import ContactDialog from 'Components/Common/Dialogs/ContactDialog';
import InvoiceEmailDialog from 'Components/Common/Dialogs/InvoiceEmailDialog';
import UpdateDuplicateAddresses from 'Components/Common/Forms/UpdateDuplicateAddresses';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Form from 'Components/Common/Styles/Form';
import DiscountConfiguration from 'Components/Customers/Misc/DiscountConfiguration';
import { handleChange, handleChangeUc, handleCheckedChange, handleFloat, handleNumberChange, handleSelectChange } from 'Functions/FormFunctions';
import { handleTabChange, isNumeric, TabPanel } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { clearPersistenceKey } from 'Redux/Actions/StatePersistence/StatePersistence';

const initialState = () => ({ 
    access: {
        settings: false,
        discounts: false,
        finance: false
    },
    currentTab: 0,
    dialog: {
        addresses: {
            open: false,
            fieldName: "",
            idx: 0
        },
        aliases: {
            open: false,
            fieldName: "",
            idx: 0
        },
        contacts: {
            open: false,
            fieldName: "",
            idx: 0
        },
        invoiceEmails: {
            open: false,
            fieldName: "",
            idx: 0
        },
        submit: false,
        cancel: false
    },
    displayAddAddressMenu: false,
    formId: uuidv4(), 
    formData: {
        customerType: '',
        customerName: '',
        tradingName: '',
        accountNumber: '',
        status: '',
        legalStatus: '',
        companyRegistrationNumber: '',
        companyVATNumber: '',
        referralSource: '',
        phone: '',
        email: '',
        accountsEmail: '',
        website: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedIn: '',
        youtube: '',
        vatType: '',
        poRequired: '',
        monitored: 0,
        simpleProforma: 0,
        trackedPriceList: 0,
        paxHwEx: 0,
        paymentTerm: 1,
        focShipping: '',
        focShippingMethod: '',
        eulerCover: '',
        quoteApproval: '',
        tradingAddresses: [],
        registeredAddresses: [],
        accountsAddresses: [],
        deliveryAddresses: [],
        customerAliases: [],
        customerInvoiceEmails: [],
        customerContacts: [],
        discounts: {
            all: {
                percent: '0.00',
                excludedCategories: [],
                excludedSuppliers: [],
                excludeBrands: [],
                excludedRanges: [],
                excludedProducts: []
            },
            category: [],
            supplier: [],
            brand: [],
            range: [],
            product: []
        },
        discountTemplates: [],
        accountManagerStaff: '',
        request: 0
    },
    formDefaults: {
        alias: {
            name: ""
        },
        email: {
            emailAddress: ""
        },
        address: {
            idx: 0,
            id: '',
            name: '',
            addressOne: '',
            addressTwo:  '',
            addressThree:  '',
            town:  '',
            county: '',
            country:  234,
            postcode: '',
            notes: '',
            showAddress: false,
            showAddressLookup: true,
            addressComplete: false
        },
        contact: {
            id: '',
            firstName: '',
            lastName: '',
            position: '',
            telephone: '',
            mobile: '',
            email: '',
            primaryContact: 0,
            accounts: 0,
            gdprOptOut: 0,
            mailshotBar: 0,
            noDelUpdates: 0,
            noOrderEmails: 0,
            prefEmail: 0,
            prefMob: 0,
            prefTel: 0,
            notes: '',
        },
        discounts: {
            category: {
                percent: '',
                category: '',
                isParent: false,
                excludedCategories: [],
                excludedSuppliers: [],
                excludedBrands: [],
                excludedRanges: [],
                excludedProducts: []
            },
            supplier: {
                percent: '',
                supplier: '',
                excludedCategories: [],
                excludedBrands: [],
                excludedRanges: [],
                excludedProducts: []
            },
            brand: {
                percent: '',
                brand: '',
                supplier: '',
                excludedCategories: [],
                excludedRanges: [],
                excludedProducts: []
            },
            range: {
                percent: '',
                minQty: 0,
                range: '',
                supplier: '',
                excludedCategories: [],
                excludedProducts: []
            },
            product: {
                percent: '',
                minQty: 0,
                product: []
            }
        }
    },
    formErrors: [],
    id: null,
    initialFormData: {
        customerType: '',
        customerName: '',
        tradingName: '',
        accountNumber: '',
        status: '',
        legalStatus: '',
        companyRegistrationNumber: '',
        companyVATNumber: '',
        referralSource: '',
        phone: '',
        email: '',
        accountsEmail: '',
        website: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedIn: '',
        youtube: '',
        vatType: '',
        poRequired: '',
        monitored: 0,
        simpleProforma: 0,
        trackedPriceList: 0,
        paxHwEx: 0,
        paymentTerm: 1,
        focShipping: '',
        focShippingMethod: '',
        eulerCover: '',
        quoteApproval: '',
        tradingAddresses: [],
        registeredAddresses: [],
        accountsAddresses: [],
        deliveryAddresses: [],
        customerAliases: [],
        customerInvoiceEmails: [],
        customerContacts: [],
        discounts: {
            all: {
                percent: '0.00',
                excludedCategories: [],
                excludedSuppliers: [],
                excludeBrands: [],
                excludedRanges: [],
                excludedProducts: []
            },
            category: [],
            supplier: [],
            brand: [],
            range: [],
            product: []
        },
        discountTemplates: [],
        accountManagerStaff: '',
        request: 0
    },
    isLoading: true,
    isProspect: true,
    key: {
        brand: uuidv4(),
        category: uuidv4(),
        discount: uuidv4(),
        product: uuidv4(),
        range: uuidv4(),
        supplier: uuidv4(),
    },
    lists: {
        brands: [],
        categories: [],
        counties: [],
        countries: [],
        discountTemplates: [],
        paymentTerms: [],
        referralSources: [],
        positions: [],
        ranges: [],
        statuses: [],
        suppliers: [],
        types: [],
        vat: [],
    }
})

class CustomerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.addAddressBtn = React.createRef();
        this.mainContentArea = React.createRef();
        this.handleChange = handleChange.bind(this);
        this.handleChangeUc = handleChangeUc.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleFloat = handleFloat.bind(this);
        this.handleNumberChange = handleNumberChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
        this.poll = null;
    }

    componentDidMount = () => {

        const request = this.props.match?.params?.requestId ?? 0;

        const {
            forProspect
        } = this.props;

        API.multiAccess([
            'customer-settings',
            'customer-finance-data',
            'customer-discount-data',
            'new-biz-discount-data'
        ])
        .then(([
            settings,
            finance,
            customerDiscounts,
            newBizDiscounts
        ]) => {
            this.setState({ 
                access: {
                    ...this.state.access,
                    settings,
                    finance,
                    discounts: !!forProspect ? newBizDiscounts : customerDiscounts
                },
                formData: {
                    ...this.state.formData,
                    request
                },
                initialFormData: {
                    ...this.state.initialFormData,
                    request
                },
                isProspect: !!forProspect
            }, this.loadComponentData);
        });
        
    }

    componentDidUpdate = () => {
        if(JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if(!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if(window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    }

    componentWillUnmount = () => {
        if(window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
        if(this.poll) {
            clearInterval(this.poll);
        }
    } 
    
    handlePoll = (initial = false) => {

        const {
            id,
            isProspect
        } = this.state;

        if(id) {

            API.post(`/staff/my/view`, 
                { 
                    cust: id,
                    type: 'Update'
                }, 
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true
                    }
                }
            )
            .then(res => {
                if(res?.data) {
                    if(initial) {
                        if(!_.isEmpty(res.data) && _.some(res.data, el => el.u === 1)) {
                            this.props.deploySnackBar(`error`, `Sorry, another user is currently modifying this ${isProspect ? 'prospect' : 'customer'}`);
                            this.props.history.push(`/${isProspect ? `new-business` : `customers`}/${id}`)
                        } else {
                            this.setState({
                                isLoading: false
                            })
                        }
                    }
                }
            })

        }

    }

    loadComponentData = () => {

        const id = this.props.match?.params?.id ?? this.props.id ?? null;
        
        Promise.all([
            API.get('/misc/counties/all'),
            API.get('/misc/countries/all'),
            API.get('/accounts/vatRates/byType'),
            API.get('/accounts/paymentTerms'),
            API.get('/customers/types'),
            API.get('/customers/referralSources'),
            API.get(`/misc/contacts/positions`),
            API.get('/suppliers/all', { params: { active: true, forType: 2, withBrands: true, withRanges: true } }),
            API.get('/smartSearch/categories', { params: { type: 'select' } }),
            API.get(`/couriers/carriageOptions`, {
                params: {
                    forQuoteOrderForm: true,
                },
            }),
            API.get('/settings/discountTemplates', {
                params: {
                    forCustomerForm: true
                }
            })
        ])
        .then(([
            counties,
            countries, 
            vat, 
            paymentTerms, 
            types, 
            referralSources, 
            positions, 
            suppliers,
            categories,
            carriage,
            discountTemplates
        ]) => {

            let lists = {
                brands: [],
                categories: [],
                counties: [],
                countries: [],
                delivery: [],
                discountTemplates: [],
                paymentTerms: [],
                referralSources: [],
                positions: [],
                ranges: [],
                rangesBySupplier: {},
                suppliers: [],
                types: [],
                vat: [],
            }

            if(counties?.data) {
                lists.counties = _.map(counties.data , el => (
                    _.assign({
                        value: el.county_id,
                        label: el.county_name
                    })
                ));
            }

            if(countries?.data) {
                lists.countries = _.map(countries.data , el => (
                    _.assign({
                        value: el.country_id,
                        label: el.country_name
                    })
                ));
            }

            if (carriage?.data) {
                _.each(carriage.data, (el) => {
                    _.each(_.filter(el.configuration, _el => _el.courier_conf_charge === '0.00' && _el.courier_conf_custom_charge === 'false'), (_el) => {
                        lists.delivery.push({
                            label: `${el.courier_name} ${_el.courier_conf_service} ${_el.courier_conf_charge !== "0.00" ? ` - £${_el.courier_conf_charge}` : `- Free`}`,
                            value: _el.courier_conf_id,
                            default: _el.courier_conf_free_default === 1,
                        });
                    });
                });

            }

            if(discountTemplates?.data) {
                lists.discountTemplates = discountTemplates.data;
            }
                
            if(paymentTerms?.data) {
                lists.paymentTerms = _.map(paymentTerms.data, el => (
                    _.assign({
                        value: el.pt_id,
                        label: el.pt_name
                    })
                ));
            }

            if(positions?.data) {
                lists.positions = _.map(positions.data, position => (
                    _.assign({
                        label: position,
                        value: position
                    })
                ))
            }

            if(categories?.data) {
                lists.categories = categories.data;
                lists.categories.forEach(category => {
                    category.options.unshift({
                        label: `All ${category.label}`,
                        value: category.value
                    })   
                })
            }

            if(referralSources?.data) {
                lists.referralSources = _.map(referralSources.data, el => (
                    _.assign({
                        value: el.crs_id,
                        label: el.crs_name
                    })
                ));
            }

            if(suppliers?.data) {

                _.each(suppliers.data, supp => {
                    if(!_.isEmpty(supp.brands)) {
                        lists.brands.push({
                            id: supp.i,
                            label: supp.an,
                            options: _.map(supp.brands, brand => _.assign({
                                label: brand.brand_name,
                                value: brand.brand_id,
                                supplier: supp.i
                            }))             
                        })
                    }
                    
                    if(!_.isEmpty(supp.ranges)) {
                        lists.ranges.push({
                            id: supp.i,
                            label: supp.an,
                            options: _.map(supp.ranges, range => _.assign({
                                label: range.range_name,
                                value: range.range_id,
                                supplier: supp.i
                            }))             
                        })
                    }
                });

                lists.suppliers = _.map(suppliers.data, el => (
                    _.assign({
                        label: el.an,
                        value: el.i
                    })
                ));

            }

            if(types?.data) {
                lists.types = _.map(types.data, el => (
                    _.assign({
                        value: el.type_id,
                        label: el.type_name
                    })
                ));
            }

            if(vat?.data) {
                vat.data.forEach((type) => {
                    let currentValue = _.find(type.vat_values, {current: true});
                    if(currentValue) {
                        lists.vat.push({
                            value: currentValue.vat_value_type_id,
                            label: type.vat_type_name + ' (' + currentValue.vat_value_percentage + '%)'
                        });
                    }
                });
            }

            this.setState({
                id: id ?? null,
                initialDiscounts: JSON.stringify(initialState().discounts),
                isLoading: id ? true : false,
                lists
            }, () => {

                const {
                    isProspect
                } = this.state;

                if(id) {
                    this.loadUpdateData();
                } else if(this.state.formData.request > 0) {
                    this.props.pageTitle([1, `Customers`, `Customer Onboarding`, `Open Account`]);
                    this.loadPrefilledData();
                } else {
                    this.props.pageTitle([1, isProspect ? `New Business` : `Customers`, `New ${isProspect ? 'Prospective' : 'Customer'} Account`]);
                    this.loadStatusOptions(true);
                }
            })

        });
    }

    loadUpdateData = () => {

        const { 
            id 
        } = this.state;

        API.get(`/customers/${id}`, 
            {
                params: {
                    forUpdate: true
                }
            }
        )
        .then(result => {

            if(result?.data) {
                
                let customer = result.data,
                    tradingAddresses = [],
                    registeredAddresses = [],
                    accountsAddresses = [],
                    deliveryAddresses = [],
                    customerContacts = [],
                    customerAliases = [],
                    customerInvoiceEmails = [],
                    discountTemplates = [];

                if(customer.discount_templates) {
                    discountTemplates = customer.discount_templates.map(el => el.id);
                }

                if(customer.trading_address) {
                    tradingAddresses.push(
                        {
                            ...initialState().formDefaults.address,
                            idx: 0,
                            id: customer.trading_address.address_id,
                            name: customer.trading_address.address_name,
                            addressOne: customer.trading_address.address_line_one,
                            addressTwo: customer.trading_address.address_line_two,
                            addressThree: customer.trading_address.address_line_three,
                            town: customer.trading_address.address_town,                            
                            county: customer.trading_address.address_county_id,
                            country: customer.trading_address.address_country_id,
                            postcode: customer.trading_address.address_postcode,
                            notes: customer.trading_address.address_notes,
                            showAddress: true,
                            showAddressLookup: false,
                            addressComplete: true
                        }
                    )
                }

                if(customer.registered_address) {
                    registeredAddresses.push(
                        {
                            ...initialState().formDefaults.address,
                            idx: 0,
                            id: customer.registered_address.address_id,
                            name: customer.registered_address.address_name,
                            addressOne: customer.registered_address.address_line_one,
                            addressTwo: customer.registered_address.address_line_two,
                            addressThree: customer.registered_address.address_line_three,
                            town: customer.registered_address.address_town,                            
                            county: customer.registered_address.address_county_id,
                            country: customer.registered_address.address_country_id,
                            postcode: customer.registered_address.address_postcode,
                            notes: customer.registered_address.address_notes,
                            showAddress: true,
                            showAddressLookup: false,
                            addressComplete: true
                        }
                    )
                }
                
                if(!_.isEmpty(customer.account_addresses)) {
                    _.each(customer.account_addresses, (address, idx) => {
                        accountsAddresses.push(
                            {
                                ...initialState().formDefaults.address,
                                idx: idx,
                                id: address.address_id,
                                name: address.address_name,
                                addressOne: address.address_line_one,
                                addressTwo: address.address_line_two,
                                addressThree: address.address_line_three,
                                town: address.address_town,                            
                                county: address.address_county_id,
                                country: address.address_country_id,
                                postcode: address.address_postcode,
                                notes: address.address_notes,
                                showAddress: true,
                                showAddressLookup: false,
                                addressComplete: true
                            }
                        )
                    })
                }
                
                if(!_.isEmpty(customer.delivery_addresses)) {
                    _.each(customer.delivery_addresses, (address, idx) => {
                        deliveryAddresses.push(
                            {
                                ...initialState().formDefaults.address,
                                idx: idx,
                                id: address.address_id,
                                name: address.address_name,
                                addressOne: address.address_line_one,
                                addressTwo: address.address_line_two,
                                addressThree: address.address_line_three,
                                town: address.address_town,                        
                                county: address.address_county_id,
                                country: address.address_country_id,
                                postcode: address.address_postcode,
                                notes: address.address_notes,
                                showAddress: true,
                                showAddressLookup: false,
                                addressComplete: true
                            }
                        )
                    })
                }

                if(!_.isEmpty(customer.contacts)) {
                    _.each(customer.contacts, contact => {
                        customerContacts.push(
                            {
                                ...initialState().formDefaults.contact,
                                id: contact.i,
                                firstName: contact.fn,
                                lastName: contact.ln,
                                position: contact.po,
                                telephone: contact.t,
                                mobile: contact.m,
                                email: contact.e,
                                notes: contact.n,
                                primaryContact: contact.p,
                                accounts: contact.a,
                                mailshotBar: contact.msb,
                                prefEmail: contact.pre,
                                prefMob: contact.prm,
                                prefTel: contact.prt,
                                noDelUpdates: contact.ndu,
                                noOrderEmails: contact.noe,
                                gdprOptOut: contact.goi === 1 ? 0 : 1 //inverted in db
                            }
                        )
                    })
                }

                if(!_.isEmpty(customer.aliases)) {
                    _.each(customer.aliases, alias => {
                        customerAliases.push(
                            {
                                ...initialState().formDefaults.alias,
                                id: alias.alias_id,
                                name: alias.alias_name,
                            }
                        )
                    })
                }

                if(!_.isEmpty(customer.invoice_emails)) {
                    _.each(customer.invoice_emails, email => {
                        customerInvoiceEmails.push(
                            {
                                ...initialState().formDefaults.email,
                                id: email.ie_id,
                                emailAddress: email.ie_email_address,
                            }
                        )
                    })
                }

                /* Discounts */
                let discounts = customer.cust_discount_data ? JSON.parse(customer.cust_discount_data) : null;

                if(discounts === null) {
                    discounts = {
                        all: {
                            percent: '0.00',
                            excludedCategories: [],
                            excludedSuppliers: [],
                            excludedBrands: [],
                            excludedRanges: [],
                            excludedProducts: []
                        },
                        category: [],
                        supplier: [],
                        brand: [],
                        range: [],
                        product: []
                    }
                }

                if(!discounts.all) {
                    discounts.all = {
                        percent: '0.00',
                        excludedCategories: [],
                        excludedSuppliers: [],
                        excludedBrands: [],
                        excludedRanges: [],
                        excludedProducts: []
                    }
                } else {
                    if(discounts.all.percent === 0) {
                        discounts.all.percent = '0.00';
                    }
                }

                if(!discounts.all.excludedCategories) {
                    discounts.all.excludedCategories = [];
                }

                if(!discounts.all.excludedSuppliers) {
                    discounts.all.excludedSuppliers = [];
                }

                if(!discounts.all.excludedBrands) {
                    discounts.all.excludedBrands = [];
                }

                if(!discounts.all.excludedRanges) {
                    discounts.all.excludedRanges = [];
                }

                if(!discounts.all.excludedProducts) {
                    discounts.all.excludedProducts = [];
                }

                if(!discounts.category) {
                    discounts.category = []
                } else {
                    discounts.category.forEach((category) => {
                        if(!category.excludedCategories) {
                            category.excludedCategories = [];
                        }
                        if(!category.excludedSuppliers) {
                            category.excludedSuppliers = [];
                        }
                        if(!category.excludedBrands) {
                            category.excludedBrands = [];
                        }
                        if(!category.excludedRanges) {
                            category.excludedRanges = [];
                        }
                        if(!category.excludedProducts) {
                            category.excludedProducts = [];
                        }
                    });
                }

                if(!discounts.supplier) {
                    discounts.supplier = []
                } else {
                    discounts.supplier.forEach((supplier) => {
                        if(!supplier.excludedCategories) {
                            supplier.excludedCategories = [];
                        }
                        if(!supplier.excludedBrands) {
                            supplier.excludedBrands = [];
                        }
                        if(!supplier.excludedRanges) {
                            supplier.excludedRanges = [];
                        }
                        if(!supplier.excludedProducts) {
                            supplier.excludedProducts = [];
                        }
                    });
                }

                if(!discounts.brand) {
                    discounts.brand = []
                } else {
                    discounts.brand.forEach((brand) => {
                        if(!brand.excludedCategories) {
                            brand.excludedCategories = [];
                        }
                        if(!brand.excludedRanges) {
                            brand.excludedRanges = [];
                        }
                        if(!brand.excludedProducts) {
                            brand.excludedProducts = [];
                        }
                    });
                }

                if(!discounts.range) {
                    discounts.range = []
                } else {
                    discounts.range.forEach((range) => {
                        if(!range.excludedCategories) {
                            range.excludedCategories = [];
                        }
                        if(!range.excludedProducts) {
                            range.excludedProducts = [];
                        }
                    });
                }

                if(!discounts.product) {
                    discounts.product = []
                }

                let initialDiscounts = JSON.stringify(discounts);

                const isProspect = customer.cust_prospect === 1;

                this.setState({
                    formData: {
                        ...this.state.formData,
                        customerName: customer.cust_company_name,
                        tradingName: customer.cust_trading_name,
                        accountNumber: customer.cust_account_number,
                        customerType: customer.cust_type_id,
                        vatType: customer.cust_vat_type_id,
                        phone: customer.cust_phone,
                        email: customer.cust_email,
                        accountsEmail: customer.cust_email_accounts,
                        website: customer.cust_website,
                        facebook: customer.cust_facebook,
                        twitter: customer.cust_twitter,
                        instagram: customer.cust_instagram,
                        linkedIn: customer.cust_linkedin,
                        youtube: customer.cust_youtube,
                        referralSource: customer.cust_crs_id,
                        legalStatus: customer.cust_legal_status,
                        companyRegistrationNumber: customer.cust_reg_number,
                        companyVATNumber: customer.cust_vat_number,
                        status: customer.cust_status_id,
                        poRequired: customer.cust_po_required,
                        trackedPriceList: customer?.cust_track_price_list ?? 0,
                        monitored: customer?.cust_monitored ?? 0,
                        paxHwEx: customer?.cust_pax_excl_hw ?? 0,
                        simpleProforma: customer?.cust_simple_proforma ?? 0,
                        paymentTerm: (customer.payment_terms && customer.payment_terms.pt_id) || "",
                        focShipping: customer.cust_foc_shipping ?? '',
                        focShippingMethod: customer.cust_foc_shipping_method ?? '',
                        eulerCover: customer.cust_euler,
                        quoteApproval: customer.cust_quote_approval,
                        accountManagerStaff: customer.cust_account_manager_staff_id,
                        tradingAddresses,
                        registeredAddresses,
                        accountsAddresses,
                        deliveryAddresses,
                        customerContacts,
                        customerAliases,
                        customerInvoiceEmails,
                        discounts,
                        discountTemplates,
                    },
                    initialFormData: {
                        ...this.state.formData,
                        customerName: customer.cust_company_name,
                        tradingName: customer.cust_trading_name,
                        accountNumber: customer.cust_account_number,
                        customerType: customer.cust_type_id,
                        vatType: customer.cust_vat_type_id,
                        phone: customer.cust_phone,
                        email: customer.cust_email,
                        accountsEmail: customer.cust_email_accounts,
                        website: customer.cust_website,
                        facebook: customer.cust_facebook,
                        twitter: customer.cust_twitter,
                        instagram: customer.cust_instagram,
                        linkedIn: customer.cust_linkedin,
                        youtube: customer.cust_youtube,
                        referralSource: customer.cust_crs_id,
                        legalStatus: customer.cust_legal_status,
                        companyRegistrationNumber: customer.cust_reg_number,
                        companyVATNumber: customer.cust_vat_number,
                        status: customer.cust_status_id,
                        poRequired: customer.cust_po_required,
                        trackedPriceList: customer?.cust_track_price_list ?? 0,
                        monitored: customer?.cust_monitored ?? 0,
                        paxHwEx: customer?.cust_pax_excl_hw ?? 0,
                        simpleProforma: customer?.cust_simple_proforma ?? 0,
                        paymentTerm: (customer.payment_terms && customer.payment_terms.pt_id) || "",
                        focShipping: customer.cust_foc_shipping ?? '',
                        focShippingMethod: customer.cust_foc_shipping_method ?? '',
                        eulerCover: customer.cust_euler,
                        quoteApproval: customer.cust_quote_approval,
                        accountManagerStaff: customer.cust_account_manager_staff_id,
                        tradingAddresses,
                        registeredAddresses,
                        accountsAddresses,
                        deliveryAddresses,
                        customerContacts,
                        customerAliases,
                        customerInvoiceEmails,
                        discounts,
                        discountTemplates,
                    },
                    initialDiscounts,
                    isProspect
                }, () => {
                    this.props.pageTitle([1, isProspect ? `New Business` : `Customers`, customer?.cust_company_name ?? 'Unknown', 'Update']);
                    this.loadStatusOptions(!!customer.cust_prospect)
                    this.handlePoll(true);
                    this.poll = setInterval(this.handlePoll, 3000);
                })
            }
        })
    }

    loadPrefilledData = () => {

        const { 
            formData: {
                request
            }
        } = this.state;

        API.get(`/customers/onboarding/${request}`)
        .then(result => {

            if(result?.data) {

                const data = result.data;
                const form = data.data;

                if(data?.customer?.cust_id) {
                    this.props.history.push(`/customers/${data.customer.cust_id}`)
                } else if(data.trr_actioned) {
                    this.props.history.push(`/customers/onboarding`)
                }

                const tradingAddresses = [],
                    registeredAddresses = [],
                    accountsAddresses = [],
                    deliveryAddresses = [],
                    customerContacts = [];

                const diffRegisteredAddress = form?.ltdRegOffice.postcode === '' ? false : 
                        form?.address1 !== form?.ltdRegOffice.address1 ||
                        form?.address2 !== form?.ltdRegOffice.address2 || 
                        form?.address3 !== form?.ltdRegOffice.address3 ||
                        form?.town !== form?.ltdRegOffice.town || 
                        form?.county !== form?.ltdRegOffice.county || 
                        form?.postcode !== form?.ltdRegOffice.postcode || 
                        form?.country !== form?.ltdRegOffice.country;

                const tradingAddress = {
                    ...initialState().formDefaults.address,
                    idx: 0,
                    id: '',
                    name: '',
                    addressOne: form?.address1 ?? '',
                    addressTwo:  form?.address2 ?? '',
                    addressThree:  form?.address3 ?? '',
                    town: form?.town ?? '',
                    county: _.find(this.state.lists.counties, { label: form?.county ?? 0 })?.value ?? 0,
                    country: _.find(this.state.lists.countries, { label: form?.country ?? 0 })?.value ?? 234,
                    postcode: form?.postcode ?? '',
                    notes: '',
                    showAddress: true,
                    showAddressLookup: false,
                    addressComplete: true
                }

                tradingAddresses.push({...tradingAddress});
                accountsAddresses.push({...tradingAddress});
                deliveryAddresses.push({...tradingAddress});

                const registeredAddress = !diffRegisteredAddress ? {...tradingAddress} : {
                    ...initialState().formDefaults.address,
                    idx: 0,
                    id: '',
                    name: '',
                    addressOne: form?.ltdRegOffice?.address1 ?? '',
                    addressTwo:  form?.ltdRegOffice?.address2 ?? '',
                    addressThree:  form?.ltdRegOffice?.address3 ?? '',
                    town: form?.ltdRegOffice?.town ?? '',
                    county: _.find(this.state.lists.counties, { label: form?.ltdRegOffice?.county ?? 0 })?.value ?? 0,
                    country: _.find(this.state.lists.countries, { label: form?.ltdRegOffice?.country ?? 0 })?.value ?? 234,
                    postcode: form?.ltdRegOffice?.postcode ?? '',
                    notes: '',
                    showAddress: true,
                    showAddressLookup: false,
                    addressComplete: true
                }

                registeredAddresses.push({...registeredAddress});
                
                _.each(form?.directors ?? [], (el, idx) => {
                    if(el?.firstName?.length && el?.lastName?.length) {
                        customerContacts.push({
                            ...initialState().formDefaults.contact,
                            id: '',
                            firstName: el.firstName,
                            lastName: el.lastName,
                            position: el?.position?.length ? el.position : 'Director',
                            telephone: el.phone,
                            mobile: '',
                            email: el.email,
                            notes: '',
                            primaryContact: idx === 0 ? 1 : 0,
                            accounts: 0,
                            gdprOptOut: el?.newsletter ? 1 : 0,
                            mailshotBar: el?.newsletter ? 0 : 1,
                            noDelUpdates: 0,
                            noOrderEmails: 0,
                            prefEmail: 0,
                            prefMob: 0,
                            prefTel: 0
                        })
                    }
                })

                _.each(form?.accountsContacts ?? [], (el, idx) => {
                    if(el?.firstName?.length && el?.lastName?.length) {
                        customerContacts.push({
                            ...initialState().formDefaults.contact,
                            id: '',
                            firstName: el.firstName,
                            lastName: el.lastName,
                            position: el?.position?.length ? el.position : 'Accounts',
                            telephone: el.phone,
                            mobile: '',
                            email: el.email,
                            notes: '',
                            primaryContact: 0,
                            accounts: idx === 0 ? 1 : 0,
                            gdprOptOut: el?.newsletter ? 1 : 0,
                            mailshotBar: el?.newsletter ? 0 : 1,
                            noDelUpdates: 0,
                            noOrderEmails: 0,
                            prefEmail: 0,
                            prefMob: 0,
                            prefTel: 0
                        })
                    }
                })
                
                _.each(form?.contacts ?? [], (el, idx) => {
                    if(el?.firstName?.length && el?.lastName?.length) {
                        customerContacts.push({
                            ...initialState().formDefaults.contact,
                            id: '',
                            firstName: el.firstName,
                            lastName: el.lastName,
                            position: el?.position?.length ? el.position : '',
                            telephone: el.phone,
                            mobile: '',
                            email: el.email,
                            notes: '',
                            primaryContact: 0,
                            accounts: 0,
                            gdprOptOut: el?.newsletter ? 1 : 0,
                            mailshotBar: el?.newsletter ? 0 : 1,
                            noDelUpdates: 0,
                            noOrderEmails: 0,
                            prefEmail: 0,
                            prefMob: 0,
                            prefTel: 0
                        })
                    }
                })

                let customerType = '';
                if(!_.isEmpty(form?.ctype)) {
                    customerType = _.find(this.state.lists.types, { label: form.ctype })?.value ?? '';
                }

                let formData = {
                    ...this.state.formData,
                    customerType,
                    customerName: form?.companyName ?? '',
                    tradingName: form?.tradingName ?? '',
                    accountNumber: '',
                    status: '',
                    legalStatus: form?.type ?? '',
                    companyRegistrationNumber: form?.ltdRegNumber ?? '',
                    companyVATNumber: form?.vatNumber ?? '',
                    referralSource: 6,
                    phone: form?.companyPhone ?? '',
                    email: form?.companyEmail ?? '',
                    accountsEmail: form?.accountsEmail ?? '',
                    website: form?.website ?? '',
                    facebook: '',
                    twitter: '',
                    instagram: '',
                    linkedIn: '',
                    youtube: '',
                    vatType: '',
                    poRequired: '',
                    monitored: 0,
                    simpleProforma: 0,
                    trackedPriceList: 0,
                    paxHwEx: 0,
                    paymentTerm: 1,
                    focShipping: '',
                    focShippingMethod: '',
                    eulerCover: '',
                    quoteApproval: '',
                    tradingAddresses,
                    registeredAddresses,
                    accountsAddresses,
                    deliveryAddresses,
                    customerContacts
                }

                const initialFormData = {
                    ...formData
                }

                this.setState({
                    formData,
                    initialFormData
                }, () => {
                    this.loadStatusOptions(true, form?.region ?? null)
                })
            }
        })
    }

    /* 
     * Statuses 
    */
    loadStatusOptions = (isProspect, region = null) => {


        let statusParams = {};
        if(isProspect) {
            statusParams = {
                ...statusParams,
                prospect: true
            }
        } else {
            statusParams = {
                ...statusParams,
                open: true
            }
        }

        API.get('/customers/statuses', { params: statusParams })
        .then(res => {
            if(res?.data) {
                const statuses = _.map(res.data, el => (
                                    _.assign({
                                        label: el.status_name,
                                        options: _.map(el.children, _el => {
                                            return _.assign({
                                                value: _el.status_id,
                                                label: `${_el.status_code} - ${_el.status_name}`
                                            });
                                        })
                                    })
                                ));
                                    
                this.setState({
                    lists: {
                        ...this.state.lists,
                        statuses
                    }
                }, () => {
                    if(isProspect && region) {
                        let statusCode = _.find(this.state.lists.statuses, { label: region })?.options[0]?.value ?? '';
                        if(statusCode) {
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    status: statusCode
                                }
                            })
                        }
                    }
                })
                
            }
        })

    }

    /*
     * Addresses
     */
   handleAddAddressMenuToggle = () => {
        this.setState({
            displayAddAddressMenu: !this.state.displayAddAddressMenu
        })
    }

    handleShowAllAddresses = addresses => {
        addresses.forEach((add, idx) => {
            addresses[idx] = {
                ...addresses[idx],
                showAddress: true,
                showAddressLookup: false
            }
        })
        return addresses
    } 

    /*
     * Dialogs
     */
    handleDialogClose = (type, exec) => {
        if(exec) {
            this.setState({
                dialog: {
                    ...this.state.dialog,
                    [type]: {
                        ...initialState().dialog[type],
                    }
                }
            })
        }
    }

    handleDialogToggle = (type, fieldName, idx) => {  
        let submit =  _.curry(this.handleRowAdd);
        let cancel =  _.curry(this.handleDialogClose);
        submit = submit(fieldName, idx);
        cancel = cancel(type);
        if(type === "addresses" && this.state.displayAddAddressMenu && idx === -1) {
            this.handleAddAddressMenuToggle();
        }
        this.setState({
            dialog: {
                ...this.state.dialog,
                [type]: {
                    ...initialState().dialog[type],
                    open: true,
                    fieldName,
                    idx
                },
                submit: submit,
                cancel: cancel
            }
        });
    }

    /*
     * Datasets (Addresses, Aliases, Invoice Emails Contacts)
     */
    handleRowAdd = (fieldName, idx, postData, duplicateCheck = true) => {

        let rows = !_.isEmpty(this.state.formData[fieldName]) ? [...this.state.formData[fieldName]] : []

        if(fieldName === 'customerContacts' && postData.primaryContact === 1) {
            rows.forEach((row, ridx) => {
                if(row.primaryContact === 1 && idx !== ridx) {
                    rows[ridx].primaryContact = 0
                }
            })
        }

        if(fieldName === 'customerContacts' && postData.accounts === 1) {
            rows.forEach((row, ridx) => {
                if(row.accounts === 1 && idx !== ridx) {
                    rows[ridx].accounts = 0
                }
            })
        }

        let currentValue = null;

        if(rows && rows[idx]) {
            currentValue = {...rows[idx]}
            rows[idx] = postData
        } else {
            rows.push(postData)
        }

        if(fieldName === 'customerContacts' && postData.primaryContact === 1) {
            rows = _.orderBy(rows, ['primaryContact', 'accounts'], ['desc'])
        }

        _.each(rows, (row, idx) => {
            rows[idx].idx = idx
        })
        
        this.setState({
            dialog: initialState().dialog,
            formData:{
                ...this.state.formData,
                [fieldName]: rows
            }
        }, () => {

            if(duplicateCheck && currentValue && (fieldName === 'tradingAddresses' || fieldName === 'accountsAddresses' || fieldName === 'registeredAddresses' || fieldName === 'deliveryAddresses')) {
                
                const {
                    formData,
                    lists
                } = this.state;

                const duplicates = [];

                _.each([
                    ...(_.map(formData.tradingAddresses,    (el, idx) => ({...el, idx, t: 'Trading',    type: 'tradingAddresses'}))), 
                    ...(_.map(formData.registeredAddresses, (el, idx) => ({...el, idx, t: 'Registered', type: 'registeredAddresses'}))), 
                    ...(_.map(formData.accountsAddresses,   (el, idx) => ({...el, idx, t: 'Accounts',   type: 'accountsAddresses'}))), 
                    ...(_.map(formData.deliveryAddresses,   (el, idx) => ({...el, idx, t: 'Delivery',   type: 'deliveryAddresses'}))), 
                ], addr => {
                    if(JSON.stringify({
                        addressOne: currentValue.addressOne,
                        addressTwo: currentValue.addressTwo,
                        addressThree: currentValue.addressThree,
                        town: currentValue.town,
                        county: currentValue.county,
                        postcode: currentValue.postcode,
                        country: currentValue.country,
                    }) === JSON.stringify({
                        addressOne: addr.addressOne,
                        addressTwo: addr.addressTwo,
                        addressThree: addr.addressThree,
                        town: addr.town,
                        county: addr.county,
                        postcode: addr.postcode,
                        country: addr.country,
                    })) {
                        duplicates.push({fieldName: addr.type, idx: addr.idx, update: 1, addr})
                    }
                });

                if(!_.isEmpty(duplicates)) {
                    this.props.deployDialog(
                        <UpdateDuplicateAddresses
                            counties={lists.counties}
                            countries={lists.countries}
                            duplicates={duplicates}
                            handleRowAdd={this.handleRowAdd}
                            postData={postData}
                        />,
                        false,
                        "Update Existing Addresses", 
                        "standard",
                        "lg"
                    )
                }

            }
        })
    }
  
    handleRowRemove = (fieldName, idx) => {
        const rows = [...this.state.formData[fieldName]]
        rows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: rows
            }
        })
    }

    /*
     * Discounts
     */
    handleDiscountTemplateChange = (id) => {
        let discountTemplates = [...this.state.formData.discountTemplates];
        if(discountTemplates.includes(id)) {
            discountTemplates = _.filter(discountTemplates, (el) => el !== id);
        } else {
            discountTemplates.push(id);
        }
        this.setState({
            formData: {
                ...this.state.formData,
                discountTemplates
            }
        })
    }

    handleDiscountAddRow = (type) => {
        let discounts = [...this.state.formData.discounts?.[type]];
        discounts.push({...initialState().formDefaults.discounts?.[type]});
        this.setState({
            formData: {
                ...this.state.formData,
                discounts: {
                    ...this.state.formData.discounts,
                    [type]: discounts
                }
            }
        })
    }

    handleDiscountRemoveRow = (type, idx) => {
        let discounts = [...this.state.formData.discounts?.[type]];
        discounts.splice(idx, 1);
        this.setState({
            formData: {
                ...this.state.formData,
                discounts: {
                    ...this.state.formData.discounts,
                    [type]: discounts
                }
            }
        })
    }

    handleDiscountPercentChange = (type, idx, p) => {
        let discounts, percent = p;
        if(type === "all") {
            discounts = {...this.state.formData.discounts.all};
            discounts = {
                ...discounts,
                percent
            }
        } else {
            discounts = [...this.state.formData.discounts?.[type]];
            discounts[idx] = {
                ...discounts[idx],
                percent
            }
        }
        this.setState({
            formData: {
                ...this.state.formData,
                discounts: {
                    ...this.state.formData.discounts,
                    [type]: discounts
                }
            }
        })
    }

    handleDiscountPercentFloat = (type, idx) => {
        let discounts;
        if(type === "all") {
            discounts = {...this.state.formData.discounts.all};
            discounts = {
                ...discounts,
                percent: !isNumeric(discounts.percent) || discounts.percent > 100 ? '' : parseFloat(discounts.percent).toFixed(2)
            }
            if(discounts.percent === "0.00") {
                discounts = {
                    ...discounts,
                    excludedSuppliers: [],
                    excludedProducts: [],
                    excludedRanges: []
                }
            }
        } else {
            discounts = [...this.state.formData.discounts?.[type]];
            discounts[idx] = {
                ...discounts[idx],
                percent: !isNumeric(discounts[idx].percent) || discounts[idx].percent > 100 ? '' : parseFloat(discounts[idx].percent).toFixed(2)
            }
        }
        this.setState({
            formData: {
                ...this.state.formData,
                discounts: {
                    ...this.state.formData.discounts,
                    [type]: discounts
                }
            }
        })
    }

    handleDiscountMinQtyChange = (idx, minQty) => {
        let discounts = [...this.state.formData.discounts?.product];
        discounts[idx] = {
            ...discounts[idx],
            minQty
        }
        this.setState({
            formData: {
                ...this.state.formData,
                discounts: {
                    ...this.state.formData.discounts,
                    product: discounts
                }
            }
        })
    }

    handleDiscountMinQtyFloat = idx => {
        let discounts = [...this.state.formData.discounts?.product];
        discounts[idx] = {
            ...discounts[idx],
            minQty: !isNumeric(discounts[idx].minQty) || discounts[idx].minQty < 0 ? 0 : discounts[idx].minQty
        }
        this.setState({
            formData: {
                ...this.state.formData,
                discounts: {
                    ...this.state.formData.discounts,
                    product: discounts
                }
            }
        })
    }

    handleDiscountChange = (type, fieldName, idx, selectedOption, supplierOption = null) => {

        let acrossBoard = type === "all",
            discounts;

        if(acrossBoard) {
            discounts = {...this.state.formData.discounts?.all}
        } else {
            discounts = [...this.state.formData.discounts?.[type]];
        }

        if(type === "product" && fieldName === "product") {
            if(selectedOption && !_.isEmpty(selectedOption)) {
                let products = discounts[idx].product;
                if(_.findIndex(products, el => el.value === selectedOption?.value) === -1) {
                    products.push(
                        {
                            label: selectedOption?.code,
                            value: selectedOption?.value
                        }
                    )
                    if(acrossBoard) {
                        discounts = {
                            ...discounts,
                            [fieldName]: products
                        }
                    } else {
                        discounts[idx] = {
                            ...discounts[idx],
                            [fieldName]: products
                        }
                    }
                }
            }
        } else if(fieldName === "isInclusion" || fieldName === "incNoDiscount" || fieldName === "minQty") {
            discounts[idx] = {
                ...discounts[idx],
                [fieldName]: selectedOption
            }
        } else if(type === fieldName) {
            if(!acrossBoard) {
                discounts[idx] = {
                    ...initialState().formDefaults.discounts[type],
                    [fieldName]: selectedOption?.value ?? '',
                    label: selectedOption?.label ?? '',
                    percent: discounts[idx].percent,
                    isInclusion: discounts[idx]?.isInclusion ?? 0
                }
                if(type === "category") {
                    if(selectedOption?.value && _.find(this.state.lists.categories, el => el.value === selectedOption?.value)) {
                        discounts[idx].isParent = true;
                    }
                }
                if(supplierOption) {
                    discounts[idx] = {
                        ...discounts[idx],
                        supplier: supplierOption
                    }
                }
            }
        } else {
            if(selectedOption && !_.isEmpty(selectedOption)) {
                let excluded = acrossBoard ? [...discounts[fieldName]] : [...discounts[idx][fieldName]];
                if(_.findIndex(excluded, el => el.value === selectedOption?.value) === -1) {
                    excluded.push(
                        {
                            label: selectedOption?.code ?? selectedOption?.label,
                            value: selectedOption?.value
                        }
                    )
                    if(acrossBoard) {
                        discounts = {
                            ...discounts,
                            [fieldName]: excluded
                        }
                    } else {
                        discounts[idx] = {
                            ...discounts[idx],
                            [fieldName]: excluded
                        }
                    }
                }
            }
        }

        this.setState({
            formData: {
                ...this.state.formData,
                discounts: {
                    ...this.state.formData.discounts,
                    [type]: discounts
                }
            },
            key: {
                ...this.state.key,
                [type]: uuidv4(),
                discount: uuidv4()
            }
        })

    }

    handleRemoveDiscount = (type, fieldName, idx, pidx) => {
        let discounts, rows;
        if(type === "all") {
            discounts = {...this.state.formData.discounts?.all}
            rows = [...discounts?.[fieldName]];
            rows.splice(pidx, 1);
            discounts = {
                ...discounts,
                [fieldName]: rows
            }
        } else {
            discounts = [...this.state.formData.discounts?.[type]];
            rows = [...discounts?.[idx]?.[fieldName]];
            rows.splice(pidx, 1);
            discounts[idx] = {
                ...discounts[idx],
                [fieldName]: rows
            }
        }
        this.setState({
            formData: {
                ...this.state.formData,
                discounts: {
                    ...this.state.formData.discounts,
                    [type]: discounts
                }
            },
            key: {
                ...this.state.key,
                discount: uuidv4()
            }
        })
    }

    /*
     * Form Submission
     */
    handleSubmit = () => {

        const { 
            formData, 
            id 
        } = this.state;
        
        let error = false;

        if(_.isEmpty(formData.tradingAddresses)) {
           this.handleTabChange(1);
           this.props.deploySnackBar("error", "Please enter a trading address for this account");
            error = true
        }
        
        const discPercent = [];
        if(!_.isEmpty(formData.discounts?.all?.percent)) {
            discPercent.push(formData.discounts.all.percent);
        }

        _.each(formData.discounts?.category, disc => {
            if(disc.category === "" || !isNumeric(disc.category)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all category discounts have a category selected");
                error = true
            }
            if(!error && disc?.isInclusion === 1 && _.isEmpty(disc?.excludedCategories) && _.isEmpty(disc?.excludedSuppliers) && _.isEmpty(disc?.excludedBrands) && _.isEmpty(disc?.excludedRanges)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all category discount inclusions have at least one where selected");
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(formData.discounts?.supplier, disc => {
            if(disc.supplier === "" || !isNumeric(disc.supplier)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all supplier discounts have a supplier selected");
                error = true
            }
            if(!error && disc?.isInclusion === 1 && _.isEmpty(disc?.excludedCategories) && _.isEmpty(disc?.excludedBrands) && _.isEmpty(disc?.excludedRanges)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all supplier discount inclusions have at least one where selected");
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(formData.discounts?.brand, disc => {
            if(disc.brand === "" || !isNumeric(disc.brand)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all brand discounts have a brand selected");
                error = true
            }
            if(!error && disc?.isInclusion === 1 && _.isEmpty(disc?.excludedCategories) && _.isEmpty(disc?.excludedRanges)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all brand discount inclusions have at least one where selected");
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(formData.discounts?.range, disc => {
            if(disc.supplier === "" || !isNumeric(disc.supplier) || disc.range === "" || !isNumeric(disc.range)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all range discounts have a supplier and range selected");
                error = true
            }
            if(!error && disc?.isInclusion === 1 && _.isEmpty(disc?.excludedCategories)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all range discount inclusions have at least one where selected");
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(formData.discounts?.product, disc => {
            if(_.isEmpty(disc.product)) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all product discounts contain at least one product");
                error = true
            }
            discPercent.push(disc.percent);
        })

        _.each(discPercent, p => {
            if(_.isEmpty(p) || !isNumeric(p) || p > 100) {
                this.handleTabChange(4);
                this.props.deploySnackBar("error", "Please ensure all discounts have a valid discount percentage");
                error = true
            }
        })

        if(!error) {

            this.setState({
                isLoading: true
            },
            () => {
                if(id) {
                    this.submitUpdateRecord()
                } else {
                    this.submitAddRecord()
                }
            });

        }

    }

    submitAddRecord = () => {

        let {
            formData
        } = this.state;

        const { 
            isProspect
        } = this.state;
        
        if(isProspect) {
            if(_.isEmpty(formData.accountsAddresses)) {
                formData.accountsAddresses.push(formData.tradingAddresses[0])
            }
            if(_.isEmpty(formData.registeredAddresses)) {
                formData.registeredAddresses.push(formData.tradingAddresses[0])
            }
            if(_.isEmpty(formData.deliveryAddresses)) {
                formData.deliveryAddresses.push(formData.tradingAddresses[0])
            }
        }

        API.post(`/customers${isProspect ? `/prospects` : ``}`, formData)
        .then(result => {

            if(result?.data?.errors) {

                let tradingAddresses    = this.handleShowAllAddresses([...formData.tradingAddresses]),
                    registeredAddresses = this.handleShowAllAddresses([...formData.registeredAddresses]),
                    accountsAddresses   = this.handleShowAllAddresses([...formData.accountsAddresses]),
                    deliveryAddresses   = this.handleShowAllAddresses([...formData.deliveryAddresses]);
               
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false,
                    formData: {
                        ...this.state.formData,
                        tradingAddresses,
                        registeredAddresses,
                        accountsAddresses,
                        deliveryAddresses
                    }
                }, () => {
                    this.handleTabChange(0)
                    if(_.size(result.data.errors) === 1 && result.data.errors?.[0]?.msg === "RED could not communicate with Sage") {
                        this.props.deploySnackBar("error", "RED cannot create this account right now, please contact the help desk")
                    }
                })

            } else {
                this.props.deploySnackBar("success", `You have successfully added a new ${isProspect ? "prospective" : "customer"} account`)
                this.props.history.push({
                    pathname: `/${isProspect ? `new-business` : `customers`}/${result.data.id}`,
                    state: { new: true }
                })
            }
        });
    }

    submitUpdateRecord = () => {

        let {
            formData
        } = this.state;

        const { 
            id,
            initialFormData,
            isProspect
        } = this.state;

        formData = {
            ...formData,
            initialFormData,
            discountChanged: JSON.stringify(formData.discounts) !== this.state.initialDiscounts ? 1 : 0
        }

        API.put(`/customers/${isProspect ? `prospects/` : ``}${id}`, formData)
        .then(result => {

            if(result?.data?.errors) {
                
                let tradingAddresses    = this.handleShowAllAddresses([...formData.tradingAddresses]),
                    registeredAddresses = this.handleShowAllAddresses([...formData.registeredAddresses]),
                    accountsAddresses   = this.handleShowAllAddresses([...formData.accountsAddresses]),
                    deliveryAddresses   = this.handleShowAllAddresses([...formData.deliveryAddresses]);

                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false,
                    formData: {
                        ...this.state.formData,
                        tradingAddresses,
                        registeredAddresses,
                        accountsAddresses,
                        deliveryAddresses
                    }
                }, () => {
                    this.handleTabChange(0)
                })

            } else {
                this.props.clearPersistenceKey(`customer:${id}`);
                this.props.deploySnackBar(`success`, `You have successfully modified this ${isProspect ? "prospective" : "customer"} account`)
                this.props.history.push(`/${isProspect ? `new-business` : `customers`}/${id}`)
            }

        });
    }

    /*
     * Render
     */
    render = () => {

        const { 
            classes,
            staffList
        } = this.props;

        const { 
            access, 
            currentTab, 
            dialog, 
            displayAddAddressMenu, 
            formData, 
            formErrors, 
            id, 
            initialFormData, 
            isLoading, 
            isProspect,
            key, 
            lists 
        } = this.state;

        return (
            <Grid container className={classes.container}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Prompt
                            when={JSON.stringify(formData) !== JSON.stringify(initialFormData)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                        <Grid item ref={this.mainContentArea} className={classes.main}>
                            <TabPanel value={currentTab} index={0}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">
                                                                Customer Details
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="customerName"
                                                                name="customerName"
                                                                label="Customer Name *"
                                                                value={formData.customerName}
                                                                error={formErrors?.['customerName'] && true}
                                                                helperText={formErrors?.['customerName']}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                                autoFocus
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="tradingName"
                                                                name="tradingName"
                                                                label="Trading Name"
                                                                value={formData.tradingName}
                                                                error={formErrors?.['tradingName'] && true}
                                                                helperText={formErrors?.['tradingName']}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="phone"
                                                                name="phone"
                                                                label="Phone Number"
                                                                value={formData.phone}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="email"
                                                                name="email"
                                                                label="E-mail Address"
                                                                value={formData.email}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <FormControl fullWidth>
                                                                <AutoCompleteSelect 
                                                                    options={lists.types}
                                                                    label='Account Type *'
                                                                    value={formData.customerType}
                                                                    onChange={v => this.handleSelectChange('customerType', v)}
                                                                    error={formErrors?.['customerType'] && true}
                                                                    errorText={formErrors?.['customerType']}
                                                                    noClear
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <FormControl fullWidth>
                                                                <AutoCompleteSelect 
                                                                    options={lists.referralSources}
                                                                    label='Referral Source *'
                                                                    value={formData.referralSource}
                                                                    onChange={v => this.handleSelectChange('referralSource', v)}
                                                                    error={formErrors?.['referralSource'] && true}
                                                                    errorText={formErrors?.['referralSource']}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        {!isProspect && (
                                                            <>  
                                                                <Grid item xs={12} lg={6}>
                                                                    <FormControl fullWidth>
                                                                        <AutoCompleteSelect 
                                                                            options={[
                                                                                {
                                                                                    label: "Required",
                                                                                    value: 1
                                                                                },
                                                                                {
                                                                                    label: "Not Required",
                                                                                    value: 0
                                                                                }
                                                                            ]}
                                                                            label='PO Number *'
                                                                            value={formData.poRequired}
                                                                            onChange={v => this.handleSelectChange('poRequired', v)}
                                                                            error={formErrors?.['poRequired'] && true}
                                                                            errorText={formErrors?.['poRequired']}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <FormControl fullWidth>
                                                                        <AutoCompleteSelect 
                                                                            options={[
                                                                                {value: 'Limited Company', label: 'Limited Company'},
                                                                                {value: 'Public Limited Company', label: 'Public Limited Company'},
                                                                                {value: 'Sole Trader', label: 'Sole Trader'},
                                                                                {value: 'Partnership', label: 'Partnership'},
                                                                                {value: 'Consumer', label: 'Consumer'},
                                                                                {value: 'Other', label: 'Other'}
                                                                            ]} 
                                                                            label='Legal Entity'
                                                                            value={formData.legalStatus}
                                                                            onChange={v => this.handleSelectChange('legalStatus', v)}
                                                                            error={formErrors?.['legalStatus'] && true}
                                                                            errorText={formErrors?.['legalStatus']}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <TextField
                                                                        id="companyRegistrationNumber"
                                                                        name="companyRegistrationNumber"
                                                                        label="Registration Number"
                                                                        value={formData.companyRegistrationNumber}
                                                                        error={formErrors?.['companyRegistrationNumber'] && true}
                                                                        helperText={formErrors?.['companyRegistrationNumber']}
                                                                        onChange={this.handleChange}
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <TextField
                                                                        id="companyVATNumber"
                                                                        name="companyVATNumber"
                                                                        label="VAT Number"
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    GB
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        value={formData.companyVATNumber}
                                                                        error={formErrors?.['companyVATNumber'] && true}
                                                                        helperText={formErrors?.['companyVATNumber']}
                                                                        onChange={this.handleChange}
                                                                        margin="none"
                                                                        type="number"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">
                                                                Social Media
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                id="website"
                                                                name="website"
                                                                label="Website"
                                                                value={formData.website}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="globe" size={15} noMargin /></InputAdornment>
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                name="facebook"
                                                                label="Facebook Profile"
                                                                value={formData.facebook}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start"><FAIcon type="brand" icon="facebook" size={15} noMargin /></InputAdornment>
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                name="twitter"
                                                                label="Twitter Profile"
                                                                value={formData.twitter}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start"><FAIcon type="brand" icon="twitter" size={15} noMargin /></InputAdornment>
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                name="instagram"
                                                                label="Instagram Profile"
                                                                value={formData.instagram}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start"><FAIcon type="brand" icon="instagram" size={15} noMargin /></InputAdornment>
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                name="linkedIn"
                                                                label="LinkedIn Profile"
                                                                value={formData.linkedIn}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start"><FAIcon type="brand" icon="linkedin" size={15} noMargin /></InputAdornment>
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                name="youtube"
                                                                label="YouTube Channel"
                                                                value={formData.youtube}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start"><FAIcon type="brand" icon="youtube" size={15} noMargin /></InputAdornment>
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">
                                                                Account Status
                                                            </Typography>
                                                        </Grid>
                                                        {!isProspect && access.settings && (
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch color="primary" checked={formData.monitored} onChange={this.handleCheckedChange} value="1" name="monitored" />
                                                                            }
                                                                            label={<Typography variant="body2" className="fw-400">Enable Monitoring</Typography>}
                                                                        />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch color="primary" checked={formData.simpleProforma} onChange={this.handleCheckedChange} value="1" name="simpleProforma" />
                                                                            }
                                                                            label={<Typography variant="body2" className="fw-400">Enable Simple Proforma <Tooltip title="This will prevent contact details from the order being displayed on any Proforma PDF for this company"><span><FAIcon icon="info-circle" size={15} button type="solid" /></span></Tooltip> </Typography>}
                                                                        />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch color="primary" checked={formData.trackedPriceList} onChange={this.handleCheckedChange} value="1" name="trackedPriceList" />
                                                                            }
                                                                            label={<Typography variant="body2" className="fw-400">Enable Tracked Price List</Typography>}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch color="primary" checked={formData.paxHwEx} onChange={this.handleCheckedChange} value="1" name="paxHwEx" />
                                                                }
                                                                label={<Typography variant="body2" className="fw-400">Paxton Hardware Excluded</Typography>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={!isProspect && access.settings && id ? 5 : 6}>
                                                            <FormControl fullWidth>
                                                                <AutoCompleteSelect 
                                                                    options={lists.statuses}
                                                                    label='Status Code *'
                                                                    isGrouped={true}
                                                                    value={formData.status}
                                                                    onChange={v => this.handleSelectChange('status', v)}
                                                                    error={formErrors?.['status'] && true}
                                                                    errorText={formErrors?.['status']}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={!isProspect && access.settings && id ? 4 : 6}>
                                                            <AutoCompleteSelect 
                                                                options={staffList} 
                                                                label='Proactive Responsibility'
                                                                value={this.state.formData.accountManagerStaff}
                                                                onChange={v => this.handleSelectChange('accountManagerStaff', v)}
                                                            />
                                                        </Grid>
                                                        {!isProspect && access.settings && id && (
                                                            <Grid item xs={12} lg={3}>
                                                                <TextField
                                                                    id="quoteApproval"
                                                                    name="quoteApproval"
                                                                    label="Quote Approval"
                                                                    value={formData.quoteApproval}
                                                                    onBlur={this.handleFloat}
                                                                    onChange={this.handleNumberChange}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size={15} noMargin /></InputAdornment>
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1,
                                                                        min: 0
                                                                    }}
                                                                    type="number"
                                                                    margin="none"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            {!isProspect && access.finance && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" gutterBottom>
                                                                        Finance
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <TextField
                                                                        id="accountNumber"
                                                                        name="accountNumber"
                                                                        label="Account Number *"
                                                                        value={formData.accountNumber}
                                                                        error={formErrors?.['accountNumber'] && true}
                                                                        helperText={formErrors?.['accountNumber']}
                                                                        onChange={this.handleChangeUc}
                                                                        margin="none"
                                                                        inputProps={{
                                                                            maxLength: 8
                                                                        }}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <FormControl fullWidth>
                                                                        <AutoCompleteSelect 
                                                                            disabled
                                                                            options={lists.paymentTerms}
                                                                            label='Payment Terms'
                                                                            value={formData.paymentTerm}
                                                                            onChange={v => this.handleSelectChange('paymentTerm', v)}
                                                                            error={formErrors?.['paymentTerm'] && true}
                                                                            errorText={formErrors?.['paymentTerm']}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <FormControl fullWidth>
                                                                        <AutoCompleteSelect 
                                                                            options={lists.vat}
                                                                            label='VAT Type *'
                                                                            value={formData.vatType}
                                                                            onChange={v => this.handleSelectChange('vatType', v)}
                                                                            error={formErrors?.['vatType'] && true}
                                                                            errorText={formErrors?.['vatType']}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                {id && (
                                                                    <Grid item xs={12} lg={6}>
                                                                        <TextField
                                                                            id="eulerCover"
                                                                            name="eulerCover"
                                                                            label="Euler Insured Limit"
                                                                            value={formData.eulerCover}
                                                                            onBlur={this.handleFloat}
                                                                            onChange={this.handleNumberChange}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="pound-sign" size={15} noMargin /></InputAdornment>
                                                                            }}
                                                                            inputProps={{
                                                                                step: 1,
                                                                                min: 0
                                                                            }}
                                                                            type="number"
                                                                            margin="none"
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={12} lg={6}>
                                                                    <TextField
                                                                        id="accountsEmail"
                                                                        name="accountsEmail"
                                                                        label="Primary Invoice E-mail Address"
                                                                        value={formData.accountsEmail}
                                                                        onChange={this.handleChange}
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Grid container spacing={1} alignItems='center'>
                                                                <Grid item>
                                                                    <Typography variant="h6">
                                                                        Copy Invoice E-mail Addresses
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Tooltip title="Sales Order Invoices will be copied to these e-mail addresses">
                                                                        <span>
                                                                            <FAIcon type="solid" icon="info-circle" size={12.5} />
                                                                        </span>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        size="small"
                                                                        variant="text"
                                                                        onClick={() => this.handleDialogToggle('invoiceEmails', 'customerInvoiceEmails', -1)}
                                                                    >
                                                                        <FAIcon icon="plus" size={15} button />
                                                                        Add CC
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {(!_.isEmpty(formData.customerInvoiceEmails) && (
                                                                        <Box pt={1}>
                                                                            <DataTable  
                                                                                config={{
                                                                                    key: 'rowIdx',
                                                                                    alternatingRowColours: true,
                                                                                    // responsiveImportance: true,
                                                                                    pagination: false,
                                                                                    inline: true,
                                                                                    options: {
                                                                                        dataRef: true,
                                                                                    }
                                                                                }}
                                                                                columns={[
                                                                                    {
                                                                                        heading: 'E-mail Address',
                                                                                        field: rowData => rowData.emailAddress,
                                                                                        dataRef: 'emailAddress',
                                                                                        important: true,
                                                                                        main: true
                                                                                    },
                                                                                    {
                                                                                        actions: rowData => {
                                                                                            return [
                                                                                                {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('invoiceEmails', 'customerInvoiceEmails', rowData.rowIdx)},
                                                                                                {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('customerInvoiceEmails', rowData.rowIdx)}
                                                                                            ]
                                                                                        }
                                                                                    }
                                                                                ]}
                                                                                rows={formData.customerInvoiceEmails}
                                                                            />
                                                                        </Box>
                                                                    )) || (
                                                                        <Alert severity="success" variant="outlined">
                                                                            <strong>None</strong>
                                                                        </Alert>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={currentTab} index={1}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            pagination: false,
                                            alternatingRowColours: true,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="exclamation-circle" size={25} className="textError mr-1" />Please add a <span className="linkUnderline" onClick={() => this.handleDialogToggle('addresses', 'tradingAddresses', -1)}>trading address</span> for this account</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <>
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            variant="text"
                                                            ref={this.addAddressBtn}
                                                            onClick={this.handleAddAddressMenuToggle}
                                                            aria-controls={displayAddAddressMenu ? 'addBtnMenu' : undefined}
                                                            aria-expanded={displayAddAddressMenu ? 'true' : undefined}
                                                            aria-haspopup="menu"
                                                        >
                                                            <FAIcon icon="plus-circle" size={15} button />
                                                            Add New Address
                                                        </Button>
                                                        <Menu 
                                                            id="addBtnMenu"
                                                            anchorEl={this.addAddressBtn.current}
                                                            open={displayAddAddressMenu}
                                                            onClose={this.handleAddAddressMenuToggle}
                                                            style={{marginTop: 50}}
                                                        >
                                                            {(_.isEmpty(formData.tradingAddresses) && (
                                                                <MenuItem onClick={() => this.handleDialogToggle('addresses', 'tradingAddresses', -1)}>
                                                                    <ListItemIcon>
                                                                        <FAIcon type="light" icon="briefcase" size={15} button noMargin />
                                                                    </ListItemIcon>
                                                                    <ListItemText>
                                                                        <Typography variant="caption">
                                                                            Trading Address
                                                                        </Typography>
                                                                    </ListItemText>
                                                                </MenuItem>
                                                            )) || (
                                                                <>
                                                                    {_.isEmpty(formData.registeredAddresses) && (
                                                                        <MenuItem onClick={() => this.handleDialogToggle('addresses', 'registeredAddresses', -1)}>
                                                                            <ListItemIcon>
                                                                                <FAIcon type="light" icon="briefcase" size={15} button noMargin />
                                                                            </ListItemIcon>
                                                                            <ListItemText>
                                                                                <Typography variant="caption">
                                                                                    Registered Address
                                                                                </Typography>
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    )}
                                                                    {_.isEmpty(formData.registeredAddresses) && (
                                                                        <MenuItem onClick={() => this.handleDialogToggle('addresses', 'accountsAddresses', -1)}>
                                                                            <ListItemIcon>
                                                                                <FAIcon type="light" icon="coins" size={15} button noMargin />
                                                                            </ListItemIcon>
                                                                            <ListItemText>
                                                                                <Typography variant="caption">
                                                                                    Accounts Address
                                                                                </Typography>
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    )}
                                                                    <MenuItem onClick={() => this.handleDialogToggle('addresses', 'deliveryAddresses', -1)}>
                                                                        <ListItemIcon>
                                                                            <FAIcon type="light" icon="box-check" size={15} button noMargin />
                                                                        </ListItemIcon>
                                                                        <ListItemText>
                                                                            <Typography variant="caption">
                                                                                Delivery Address
                                                                            </Typography>
                                                                        </ListItemText>
                                                                    </MenuItem>
                                                                </>
                                                            )}
                                                        </Menu>
                                                    </>
                                                ),
                                                dataRef: true,
                                            },
                                            rowProps: rowData => ({
                                                className: (formErrors?.[`${rowData.type}|addressOne|${rowData.idx}`] || formErrors?.[`${rowData.type}|town|${rowData.idx}`] || formErrors?.[`${rowData.type}|postcode|${rowData.idx}`]) ? 'textError fw-400' : undefined
                                            })
                                        }}
                                        columns={[
                                            {
                                                heading: '',
                                                field: rowData => (
                                                    ((formErrors?.[`${rowData.type}|addressOne|${rowData.idx}`] || formErrors?.[`${rowData.type}|town|${rowData.idx}`] || formErrors?.[`${rowData.type}|postcode|${rowData.idx}`]) && (
                                                        <FAIcon type="solid" icon="exclamation-triangle" className="textError" />
                                                    )) || (
                                                        <FAIcon icon={rowData.type === 'tradingAddresses' ? 'star' : 'check'} noMargin />
                                                    )
                                                ),
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Address Line 1',
                                                field: rowData => rowData?.addressOne || '-',
                                                dataRef: 'addressOne',
                                            },
                                            {
                                                heading: 'Address Line 2',
                                                field: rowData => rowData?.addressTwo || '-',
                                                dataRef: 'addressTwo',
                                            },
                                            {
                                                heading: 'Town',
                                                field: rowData => rowData?.town || '-',
                                                dataRef: 'town',
                                            },
                                            {
                                                heading: 'County',
                                                field: rowData => lists.counties?.[_.findIndex(lists.counties, el => el.value === rowData.county)]?.label ?? '-',
                                                dataRef: 'county',
                                            },
                                            {
                                                heading: 'Postcode',
                                                field: rowData => rowData?.postcode || '-',
                                                dataRef: 'postcode',
                                            },
                                            {
                                                heading: 'Country',
                                                field: rowData => lists.countries?.[_.findIndex(lists.countries, el => el.value === rowData.country)]?.label ?? '-',
                                                dataRef: 'country',
                                            },
                                            {
                                                heading: 'Type',
                                                field: rowData => rowData.t,
                                                dataRef: 't',          
                                            },
                                            {
                                                actions: rowData => ([
                                                    {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle("addresses", rowData.type, rowData.idx)},
                                                    {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove(rowData.type, rowData.idx), disabled: rowData.id, hideIfDisabled: true}
                                                ])
                                            }
                                        ]}
                                        rows={
                                            [
                                                ...(_.map(formData.tradingAddresses,    el => ({...el, t: 'Trading',    type: 'tradingAddresses'}))), 
                                                ...(_.map(formData.registeredAddresses, el => ({...el, t: 'Registered', type: 'registeredAddresses'}))), 
                                                ...(_.map(formData.accountsAddresses,   el => ({...el, t: 'Accounts',   type: 'accountsAddresses'}))), 
                                                ...(_.map(formData.deliveryAddresses,   el => ({...el, t: 'Delivery',   type: 'deliveryAddresses'}))), 
                                            ]
                                        }
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={currentTab} index={2}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            alternatingRowColours: true,
                                            pagination: false,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="check-circle" size={25} className="textSuccess mr-1" />No aliases have been added for this account</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => this.handleDialogToggle('aliases', 'customerAliases', -1)}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Add New Alias
                                                    </Button>
                                                ),
                                                dataRef: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Company Name',
                                                field: rowData => rowData.name,
                                                dataRef: 'name',
                                                important: true,
                                                main: true,
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('aliases', 'customerAliases', rowData.rowIdx)},
                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('customerAliases', rowData.rowIdx), disabled: rowData.id, hideIfDisabled: true}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={formData.customerAliases}
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={currentTab} index={3}>
                                <Paper>
                                    <DataTable  
                                        config={{
                                            key: 'rowIdx',
                                            pagination: false,
                                            alternatingRowColours: true,
                                            noResultsText: <Box pt={2} pb={2}><Typography variant="h4" className="fw-300"><FAIcon icon="exclamation-circle" size={25} className="textWarning mr-1" />Please add a <span className="linkUnderline" onClick={() => this.handleDialogToggle('contacts', 'customerContacts', -1)}>primary contact</span> for this account</Typography></Box>,
                                            options: {
                                                headingInput: (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => this.handleDialogToggle('contacts', 'customerContacts', -1)}
                                                    >
                                                        <FAIcon icon="plus-circle" size={15} button />
                                                        Add New Contact
                                                    </Button>
                                                ),
                                                dataRef: true,
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: 'Primary',
                                                field: rowData => <FAIcon type="thin" icon={rowData.primaryContact === 1 ? 'check' : 'times'} noMargin />,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Accounts',
                                                field: rowData => <FAIcon type="thin" icon={rowData.accounts === 1 ? 'check' : 'times'} noMargin />,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Name',
                                                field: rowData => `${rowData?.firstName ?? ''} ${rowData?.lastName ?? ''}`,
                                                dataRef: 'firstName',
                                                important: true,
                                                main: true,
                                            },
                                            {
                                                heading: 'Position',
                                                field: rowData => rowData?.position || 'Customer Contact',
                                                dataRef: 'position',
                                            },
                                            {
                                                heading: 'Telephone',
                                                field: rowData => rowData?.telephone || '-',
                                                dataRef: 'telephone',
                                            },
                                            {
                                                heading: 'Mobile',
                                                field: rowData => rowData?.mobile || '-',
                                                dataRef: 'mobile',
                                            },
                                            {
                                                heading: 'Email',
                                                field: rowData => rowData?.email?.length > 0 ? <Link href={`mailto:${rowData.email}`}>{`${rowData.email}`}</Link> : '-',
                                                dataRef: 'email',
                                            },
                                            {
                                                heading: 'Order Emails',
                                                field: rowData => rowData?.noOrderEmails === 0 ? true : false,
                                                dataRef: 'noOrderEmails',
                                                fieldFormat: 'boolean',
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'Delivery Notifications',
                                                field: rowData => rowData?.noDelUpdates === 0 ? true : false,
                                                dataRef: 'noDelUpdates',
                                                fieldFormat: 'boolean',
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'GDPR Opt Out',
                                                field: rowData => !rowData.gdprOptOut, //inverted as db is opt in
                                                dataRef: 'gdprOptOut',
                                                fieldFormat: 'boolean',
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'Mailshot Bar',
                                                field: rowData => rowData.mailshotBar,
                                                dataRef: 'mailshotBar',
                                                fieldFormat: 'boolean',
                                                alignment: 'center'
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Modify', icon: 'pencil', onClick: () => this.handleDialogToggle('contacts', 'customerContacts', rowData.rowIdx)},
                                                        {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleRowRemove('customerContacts', rowData.rowIdx), disabled: id, hideIfDisabled: true}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={formData.customerContacts}
                                    />
                                </Paper>
                            </TabPanel>
                            <TabPanel value={currentTab} index={4}>
                                <DiscountConfiguration
                                    formData={formData}
                                    formErrors={formErrors}
                                    lists={lists}
                                    innerKey={key}
                                    handleSelectChange={this.handleSelectChange}
                                    handleDiscountChange={this.handleDiscountChange}
                                    handleDiscountPercentChange={this.handleDiscountPercentChange}
                                    handleDiscountPercentFloat={this.handleDiscountPercentFloat}
                                    handleDiscountTemplateChange={this.handleDiscountTemplateChange} 
                                    handleNumberChange={this.handleNumberChange}
                                    handleDiscountAddRow={this.handleDiscountAddRow}
                                    handleDiscountRemoveRow={this.handleDiscountRemoveRow}
                                    handleRemoveDiscount={this.handleRemoveDiscount}
                                    handleDiscountMinQtyChange={this.handleDiscountMinQtyChange}
                                    handleDiscountMinQtyFloat={this.handleDiscountMinQtyFloat}
                                    handleFloat={this.handleFloat}
                                    setState={this.setState}
                                    isCustomerForm
                                />
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12} lg={2} className={`${classes.side} h-100`}>
                            <Grid container className={`${classes.relative} h-100`}>
                                <Grid item xs={12}>
                                    <Tabs
                                        color="primary"
                                        onChange={this.handleTabChange}
                                        orientation="vertical"
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none'
                                            }
                                        }}
                                        value={currentTab}
                                    >
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={0} 
                                            label="Details" 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={1}
                                            label="Addresses" 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={2} 
                                            label={(
                                                <Grid container spacing={1} justify="center">
                                                    <Grid item>
                                                        <Box ml={3.5}>
                                                            Aliases
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Aliases allow you specify additional company names that are searchable">
                                                            <span>
                                                                <FAIcon type="solid" icon="info-circle" size={12.5} />
                                                            </span>    
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            )} 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={3} 
                                            label="Contacts" 
                                        />
                                        <Tab 
                                            classes={{root: classes.tabs, selected: classes.tabSelected}} 
                                            value={4} 
                                            label="Discounts" 
                                            disabled={!access.discounts}
                                        />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12} className={`${classes.absoluteBottom} ${id ? classes.updateCustomerTotals: classes.newCustomerTotals} w-100`}>
                                    <Box mb={2}>
                                        <Grid container spacing={3} justify="center">
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    disabled={isLoading || currentTab === 0}
                                                    onClick={() => this.handleTabChange((currentTab - 1))}
                                                    variant="text"
                                                >
                                                    <FAIcon icon="arrow-left" disabled={isLoading} size={15} button />
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.handleTabChange((currentTab + 1))}
                                                    disabled={isLoading || currentTab === 4}
                                                    variant="text"
                                                >
                                                    <FAIcon icon="arrow-right" size={15} button />
                                                    Next
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} className="pt-0">
                                                <Grid container spacing={3} justify="center">
                                                    <Grid item>
                                                        <Button 
                                                            onClick={() => this.props.deployConfirmation(`Are you sure you want to ${id ? 'update' : 'create'} this ${isProspect ? `prospective` : `customer`} account?`, this.handleSubmit)}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isLoading}
                                                        >
                                                            <FAIcon icon="check" size={15} buttonPrimary />
                                                            {id ? `Update` : `Add`} {isProspect ? 'Prospect' : 'Customer'}
                                                        </Button>
                                                        {id && (
                                                            <Box mt={1.25} align='center'>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => this.props.deployConfirmation(`Are you sure you want to cancel changes?`, () => this.props.history.push(`/${isProspect ? 'new-business' : 'customers'}/${id}`))}
                                                                    variant="text"
                                                                >
                                                                    <FAIcon icon="times" size={15} button />
                                                                    Cancel
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {dialog.addresses.open && (
                            <AddressDialog
                                countyList={lists.counties}
                                countryList={lists.countries}
                                open={dialog.addresses.open}
                                idx={dialog.addresses.idx}
                                formData={(formData[dialog.addresses.fieldName] && formData[dialog.addresses.fieldName][dialog.addresses.idx]) ? formData[dialog.addresses.fieldName][dialog.addresses.idx] : initialState().formDefaults.address}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                                title={dialog.addresses.fieldName === 'tradingAddresses' ? 'Trading' : (dialog.addresses.fieldName === 'registeredAddresses' ? 'Registered' : (dialog.addresses.fieldName === 'accountsAddresses' ? 'Accounts' : 'Delivery'))}
                            />
                        )}
                        {dialog.aliases.open && (
                            <AliasDialog
                                open={dialog.aliases.open}
                                idx={dialog.aliases.idx}
                                formData={(formData[dialog.aliases.fieldName] && formData[dialog.aliases.fieldName][dialog.aliases.idx]) ? formData[dialog.aliases.fieldName][dialog.aliases.idx] : initialState().formDefaults.alias}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                            />
                        )}
                        {dialog.contacts.open && (
                            <ContactDialog
                                open={dialog.contacts.open}
                                idx={dialog.contacts.idx}
                                firstContact={_.isEmpty(formData.customerContacts)}
                                formData={(formData[dialog.contacts.fieldName] && formData[dialog.contacts.fieldName][dialog.contacts.idx]) ? formData[dialog.contacts.fieldName][dialog.contacts.idx] : initialState().formDefaults.contact}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                                positionList={lists.positions}
                                customerForm
                            />
                        )}
                        {dialog.invoiceEmails.open && (
                            <InvoiceEmailDialog
                                open={dialog.invoiceEmails.open}
                                idx={dialog.invoiceEmails.idx}
                                formData={(formData[dialog.invoiceEmails.fieldName] && formData[dialog.invoiceEmails.fieldName][dialog.invoiceEmails.idx]) ? formData[dialog.invoiceEmails.fieldName][dialog.invoiceEmails.idx] : initialState().formDefaults.email}
                                formErrors={formErrors}
                                handleSubmit={dialog.submit}
                                handleCancel={dialog.cancel}
                            />
                        )}
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    staffList: state.notifications.staffList
})

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, yes, no = false) => dispatch(deployConfirmation(message, yes, no)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    closeDialog: () => dispatch(closeDialog()),
    deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen)),
    clearPersistenceKey: (key) => dispatch(clearPersistenceKey(key))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(Form)(CustomerForm)));