import React from 'react';

import Search from 'Components/Common/Search/Search';

const StaffActionsReport = () => {
    return (
        <Search
            configuration={{
                alternatingRowColours: true,
                apiRoute: '/reporting/staffActions',
                noAutoLoad: false,
                columns: [
                    {
                        heading: 'ID',
                        field: rowData => rowData.id,
                        dataRef: 'id',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Type',
                        field: rowData => rowData.ty,
                        dataRef: 'ty',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Message',
                        field: rowData => rowData.ms,
                        dataRef: 'ms',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Repeat',
                        field: rowData => rowData.rp,
                        dataRef: 'rp',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Created By',
                        field: rowData => rowData.cb,
                        dataRef: 'cb',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Assigned To (Staff)',
                        field: rowData => rowData.ats,
                        dataRef: 'ats',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Assigned To (Team)',
                        field: rowData => rowData.att,
                        dataRef: 'att',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Proactive Responsibility',
                        field: rowData => rowData.pr,
                        dataRef: 'pr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.ac,
                        dataRef: 'ac',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.acd,
                        dataRef: 'acd',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Supplier',
                        field: rowData => rowData.su,
                        dataRef: 'su',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Contact',
                        field: rowData => rowData.con,
                        dataRef: 'con',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Quote Ref',
                        field: rowData => rowData.qr,
                        dataRef: 'qr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Order Ref',
                        field: rowData => rowData.or,
                        dataRef: 'or',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'RMA Ref',
                        field: rowData => rowData.rr,
                        dataRef: 'rr',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'PO Ref',
                        field: rowData => rowData.por,
                        dataRef: 'por',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Reminder Date/Time',
                        field: rowData => rowData.re,
                        dataRef: 're',
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Created Date/Time',
                        field: rowData => rowData.ca,
                        dataRef: 'ca',
                        important: true,
                        sizeToContent: true
                    },
                ],
                dataRef: true,
                excel: true,
                key: 'i',
                name: 'staff-actions-report',
                noResultsText: 'No staff actions are currently outstanding',
                pagination: true,
                pageTitleData: [0, 'Reporting', 'Customers'],
                pdf: true,
                persistenceId: 'staffActions',
                placeholderData: false,
                print: true,
                pulseResults: 0,
                responsiveImportance: true,
                rowsPerPage: 50,
                showCustomer: false,
                showDate: false,
                showDateRange: false,
                showFullTotals: false,
                showSearch: false,
                showSupplier: false,
                showTotals: false,
                title: 'Staff Actions Report'
            }}
        />
    )
}

export default StaffActionsReport;