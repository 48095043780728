import React from 'react';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { isTouchEnabled } from 'Functions/MiscFunctions';

const AppButton = ({back, badge, border, color, className, disabled, icon, light, onClick, onTouch, primary, small, text, type}) => (
        <Button
            className={`${primary ? 'p-3' : (small ? 'p-1' : 'p-2')} ${className ?? ''} ${light ? 'fw-400' : 'fw-600'}`}
            color={color}
            disabled={disabled}
            onClick={onTouch && isTouchEnabled() ? undefined : onClick}
            onTouchStart={onTouch && isTouchEnabled() ? onClick : undefined}
            variant={back ? undefined : border ? 'outlined' : 'contained'}
            style={{
                backgroundColor: back ? '#eee' : undefined
            }}
            type={type ?? undefined}
            fullWidth
        >
            <Badge badgeContent={badge ?? 0} color="primary">
                <Grid container alignItems='center'>
                    <Grid item>
                        {icon && (
                            <FAIcon icon={icon} type={light ? 'light' : 'solid'} size={15} style={{color}} button />
                        )}
                    </Grid>
                    <Grid item xs>
                        {text}
                    </Grid>
                </Grid>
            </Badge>
        </Button>
)

export default AppButton;