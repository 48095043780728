import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import API from "API";
import Alert from "Components/Common/Alert/Alert";
import DataTable from "Components/Common/DataTables/DataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import IconText from "Components/Common/Text/IconText";
import ProductStockChip from "Components/Common/Chips/ProductStockChip";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import logo from "Assets/Images/no-img.jpg";
import { CLOUDFRONT_URL } from "Constants";
import { currencyFormat } from "Functions/MiscFunctions";
import { closeDialog } from "Redux/Actions/Dialog/Dialog";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

const ConfigureProductBundle = ({ addBundleToForm, handleProductAdd, handleProductRemove, addProductToForm, bundle, bundleData, customer, product, line }) => {
    const initialState = () => ({
        isConfigured: false,
        isLoading: true,
        data: null,
        financial: {
            tp: "0.00",
            cp: "0.00",
            co: "0.00",
            bp: "0.00",
            dc: "0.00",
            dp: "0.00",
            mr: "0.0",
        },
        formData: null,
        currentTp: null,
        kitContents: [],
        uiTextType: null
    });

    const [state, setState] = useState(initialState());

    const dispatch = useDispatch();

    const handleAddProductToForm = () => {
        addProductToForm();
        dispatch(closeDialog());
    };

    const handleDeBundleToForm = () => {
        handleProductRemove(line.rowIdx);

        _.each(data.cats, (c, idx) => {
            const product = _.find(c.p, (el) => el.id === state.formData?.[c.id]);
            if (product) {
                API.get(`/products/${product.id}`, {
                    params: {
                        forCustomer: customer,
                        forQuoteOrderForm: true,
                    },
                }).then((res) => {
                    if (res?.data) {
                        let discountPercent = product.nd === 1 ? "0.00" : financial.dp,
                            discount = parseFloat((parseFloat(product.tp) / 100) * discountPercent).toFixed(2),
                            subTotal = (parseFloat(product.tp) - parseFloat(discount)).toFixed(2),
                            discountTotal = (parseFloat(discount) * parseFloat(c.qty)).toFixed(2),
                            total = (parseFloat(subTotal) * parseFloat(c.qty)).toFixed(2);

                        handleProductAdd({
                            parent: 0,
                            bundleId: null,
                            productId: res.data.product_id,
                            productName: res.data.product_name,
                            productCode: res.data.product_code || "-",
                            quantity: c.qty,
                            cost: product.bp,
                            price: product.tp,
                            discountId: "0",
                            subTotal,
                            discount,
                            discountPercent,
                            discountTotal,
                            total,
                            image: res.data.product_img,
                            text: "",
                            cdbSite: "",
                            cdbIntRangeStart: "",
                            cdbIntRangeEnd: "",
                            cdbExtRangeStart: "",
                            cdbExtRangeEnd: "",
                            cdbComments: "",
                            cdbOrderNo: "",
                            cdbType: "",
                            cdbFormat: "",
                            cdbProgrammer: "",
                            productData: res.data,
                            option: {},
                        });
                    }
                });
            }
        });

        dispatch(deploySnackBar(`success`, `${bundleData?.b?.nm} has been split into individual products`));
        dispatch(closeDialog());
    };

    const handleAddBundleToForm = () => {
        const { tp, cp, co, bp, dc, dp, mr } = financial;

        let desc = "";

        let bundleData = {
            b: {
                ...bundle,
                uuid: data.uuid,
                nm: data.nm,
                ty: data.ty,
                fp: data.fp,
                vf: data.vf,
                vt: data.vt,
                im: data.im,
                lv: data.lv,
                st: data.st,
                dt: data.dt,
                mr: data.minMargin,
                tp: data.ty === "Fixed Kit" ? `£${data.fp}` : `£${data.minPrice} - £${data.maxPrice}`
            },
            f: {
                tp,
                cp,
                co,
                bp,
                dc,
                dp,
                mr,
            },
            p: [],
        };

        let bundleItems = [];
        let reqTech = 0;

        data.cats.forEach((c, idx) => {
            const product = _.find(c.p, (el) => el.id === state.formData?.[c.id]);
            if (product) {
                if(data.ty === "Fixed Kit") {
                    desc += `${c.qty} x ${product.dc}\n${product.nm}\n`;
                } else {
                    desc += `${c.qty} x ${c.text} - ${product.dc}\n${product.nm}\n`;
                }
                bundleItems.push({
                    c: {
                        id: c.id,
                        qt: c.qty,
                        nm: c.text,
                    },
                    p: {
                        id: product.id,
                        dc: product.dc,
                        nm: product.nm,
                        bp: product.bp,
                        tp: product.tp,
                        nd: product.nd,
                        cp: product.yp,
                        sp: product.sp,
                        rt: product?.rt ?? 0,
                    },
                });
                if (product?.rt === 1) {
                    reqTech = 1;
                }
            }
        });

        bundleData = {
            ...bundleData,
            p: bundleItems,
        };

        let formData = null;

        formData = {
            ...line,
            bundleId: bundle.id,
            bundleData: bundleData,
            productId: null,
            productName: desc,
            productCode: `${data.pc} - ${data.nm}`,
            quantity: 1,
            cost: financial.co,
            price: financial.tp,
            subTotal: financial.bp,
            discount: financial.dc,
            discountId: "0",
            discountPercent: financial.dp,
            discountTotal: financial.dc,
            total: financial.bp,
            image: data.im,
            text: "",
            cdbSite: "",
            cdbIntRangeStart: "",
            cdbIntRangeEnd: "",
            cdbExtRangeStart: "",
            cdbExtRangeEnd: "",
            cdbComments: "",
            cdbOrderNo: "",
            cdbType: "",
            cdbFormat: "",
            cdbProgrammer: "",
            reqTech,
            productData: {},
            option: {},
        };

        addBundleToForm(formData);

        dispatch(deploySnackBar(`success`, `The bundle has been successfully ${bundleData ? `updated` : `added`}`));

        dispatch(closeDialog());
    };

    const handleFinancialChange = (e) => {
        const {
            target: { name, value },
        } = e;

        let newFinancial = {
            ...state.financial,
            [name]: parseFloat(value),
        };

        let { tp, dp, dc, bp, co, mr } = newFinancial;

        switch (name) {
            case "dc":
                if (value !== "") {
                    dc = parseFloat(value);
                    dp = parseFloat((dc / parseFloat(tp)) * 100).toFixed(2);
                    bp = parseFloat(parseFloat(tp) - dc).toFixed(2);
                } else {
                    bp = "";
                    dc = "";
                    dp = "";
                }
                break;
            case "dp":
                if (value !== "") {
                    dp = parseFloat(value);
                    dc = parseFloat((parseFloat(tp) / 100) * dp).toFixed(2);
                    bp = parseFloat(parseFloat(tp) - parseFloat(dc)).toFixed(2);
                } else {
                    bp = "";
                    dc = "";
                    dp = "";
                }
                break;
            default:
                if (value !== "") {
                    bp = parseFloat(value);
                    dc = parseFloat(parseFloat(tp) - parseFloat(bp)).toFixed(2);
                    dp = parseFloat((parseFloat(dc) / tp) * 100).toFixed(2);
                } else {
                    bp = "";
                    dc = "";
                    dp = "";
                }
                break;
        }

        mr = isNaN(bp) || bp === "" ? "" : parseFloat(((parseFloat(bp) - parseFloat(co)) / parseFloat(bp)) * 100).toFixed(2);

        newFinancial = {
            ...newFinancial,
            dp,
            dc,
            bp,
            mr,
        };

        setState((state) => ({
            ...state,
            financial: newFinancial,
            // currentTp: null
        }));
    };

    const handleFinancialFloat = (e) => {
        const {
            target: { name, value },
        } = e;

        let newFinancial = {
            ...state.financial,
            [name]: !_.isEmpty(value) && value !== "" ? parseFloat(value).toFixed(2) : "",
        };

        setState((state) => ({
            ...state,
            financial: newFinancial,
        }));
    };

    const updateBundlePricing = (apply) => {
        let newFinancial = {
            ...financial,
        };

        if (apply) {
            let dp = parseFloat(financial.dp),
                dc = parseFloat((parseFloat(currentTp) / 100) * dp).toFixed(2),
                bp = parseFloat(parseFloat(currentTp) - parseFloat(dc)).toFixed(2),
                tp = currentTp,
                mr;
            
            if(data.ty === 'Fixed Kit') {
                bp = parseFloat(data.fp).toFixed(2);
                dc = parseFloat(parseFloat(tp) - parseFloat(bp)).toFixed(2);
                dp = parseFloat((parseFloat(dc) / tp) * 100).toFixed(2);
            }
            
            mr = isNaN(bp) || bp === "" ? "" : parseFloat(((parseFloat(bp) - parseFloat(financial.co)) / parseFloat(bp)) * 100).toFixed(2);

            newFinancial = {
                ...newFinancial,
                dp,
                dc,
                bp,
                tp,
                mr,
            };
        }

        setState((state) => ({
            ...state,
            financial: newFinancial,
            currentTp: null,
        }));
    };

    const handleBundleSelectionChange = (catIdx, value) => {
        let newFormData = {
            ...state.formData,
            [catIdx]: value,
        };

        let newIsConfigured = _.every(data?.cats, (cat) => (cat.req === 1 ? newFormData[cat.id] && newFormData[cat.id] !== "" : true));

        let newFinancial = {
            ...state.financial,
        };

        if (newIsConfigured) {
            let tp = "0.00",
                cp = "0.00",
                co = "0.00",
                bp = newFinancial.bp,
                dp = newFinancial.dp,
                dc = "0.00",
                mr = "0.0";

            data?.cats.forEach((cat) => {
                if (newFormData?.[cat.id] && newFormData?.[cat.id] !== "") {
                    let p = _.find(cat.p, (el) => el.id === newFormData[cat.id]);
                    if (p) {
                        tp = (parseFloat(tp) + parseFloat(parseInt(cat.qty) * parseFloat(p.tp))).toFixed(2);
                        co = (parseFloat(co) + parseFloat(parseInt(cat.qty) * parseFloat(p.bp))).toFixed(2);
                        cp = (parseFloat(cp) + parseFloat(parseInt(cat.qty) * parseFloat(p.yp))).toFixed(2);
                    }
                }
            });

            bp = cp;
            dc = parseFloat(parseFloat(tp) - parseFloat(bp)).toFixed(2);
            dp = parseFloat((parseFloat(dc) / tp) * 100).toFixed(2);

            if(data.ty === 'Fixed Kit') {
                bp = parseFloat(data.fp).toFixed(2);
                dc = parseFloat(parseFloat(tp) - parseFloat(bp)).toFixed(2);
                dp = parseFloat((parseFloat(dc) / tp) * 100).toFixed(2);
            }

            mr = parseFloat(((parseFloat(bp) - parseFloat(co)) / parseFloat(bp)) * 100).toFixed(2);

            newFinancial = {
                ...newFinancial,
                bp,
                dc,
                dp,
                co,
                tp,
                cp,
                mr,
            };
        }

        setState((state) => ({
            ...state,
            isConfigured: newIsConfigured,
            financial: newFinancial,
            formData: newFormData,
            currentTp: null,
        }));
    };

    useEffect(() => {
        API.get(`/products/bundles/${bundle.id}`, {
            params: {
                forCustomer: customer,
            },
        }).then((res) => {
            if (res?.data) {
                let newFormData = { ...state.formData },
                    newFinancial = { ...state.financial };

                let isConfigured = false,
                    currentTp = null;

                if (bundleData) {
                    isConfigured = true;

                    currentTp = "0.00";

                    _.each(res.data.cats, (cat) => {
                        let val = "",
                            idx = _.findIndex(bundleData.p, (el) => parseInt(el?.c?.id) === parseInt(cat.id));
                        if (idx !== -1) {
                            let productIdx = _.findIndex(cat.p, (el) => parseInt(el.id) === parseInt(bundleData?.p?.[idx]?.p?.id));
                            if (productIdx !== -1) {
                                currentTp = (parseFloat(currentTp) + parseFloat(parseInt(cat.qty) * parseFloat(cat.p?.[productIdx]?.tp))).toFixed(2);
                                val = bundleData?.p?.[idx]?.p?.id;
                            } else {
                                isConfigured = false;
                            }
                        } else {
                            if (cat.req === 1) {
                                isConfigured = false;
                            }
                        }
                        newFormData = {
                            ...newFormData,
                            [cat.id]: val,
                        };
                    });

                    newFinancial = {
                        ...newFinancial,
                        ...bundleData.f,
                    };

                    currentTp = parseFloat(currentTp).toFixed(2);
                    if (parseFloat(newFinancial.tp) === parseFloat(currentTp)) {
                        currentTp = null;
                        // } else if(line && parseFloat(line?.price).toFixed(2) === currentTp) {

                        //     /* already accept the increase due to quote / order latest price on replication */
                        //     let dp = line.discountPercent,
                        //         dc = line.discount,
                        //         tp = line.price,
                        //         bp = line.subTotal,
                        //         mr = parseFloat(((parseFloat(bp) - parseFloat(newFinancial.co)) / parseFloat(bp)) * 100).toFixed(2);

                        //     newFinancial = {
                        //         ...newFinancial,
                        //         dp,
                        //         dc,
                        //         bp,
                        //         tp,
                        //         mr
                        //     }

                        //     currentTp = null;
                    }

                    if (line.discountPercent !== newFinancial?.dp) {
                        let dp = parseFloat(line.discountPercent).toFixed(2),
                            dc = line.discount,
                            bp = line.subTotal,
                            mr = parseFloat(((parseFloat(bp) - parseFloat(newFinancial.co)) / parseFloat(bp)) * 100).toFixed(2);

                        newFinancial = {
                            ...newFinancial,
                            dp,
                            dc,
                            bp,
                            mr,
                        };
                    }
                } else {
                    let setDefault = false;

                    isConfigured = true;

                    _.each(res.data.cats, (cat) => {
                        let val = "",
                            idx = _.findIndex(cat.p, (el) => parseInt(el.id) === parseInt(product.product_id));
                        if (idx !== -1 && !setDefault) {
                            val = product.product_id;
                            setDefault = true;
                        } else if (cat.p.length === 1) {
                            if (cat.req === 1) {
                                val = cat.p[0].id;
                            }
                        } else {
                            isConfigured = false;
                        }
                        newFormData = {
                            ...newFormData,
                            [cat.id]: val,
                        };                        
                    });

                    if (isConfigured) {
                        let tp = "0.00",
                            cp = "0.00",
                            co = "0.00",
                            bp = newFinancial.bp,
                            dp = newFinancial.dp,
                            dc = "0.00",
                            mr = "0.0";
            
                        res.data.cats.forEach((cat) => {
                            if (newFormData?.[cat.id] && newFormData?.[cat.id] !== "") {
                                let p = _.find(cat.p, (el) => el.id === newFormData[cat.id]);
                                if (p) {
                                    tp = (parseFloat(tp) + parseFloat(parseInt(cat.qty) * parseFloat(p.tp))).toFixed(2);
                                    co = (parseFloat(co) + parseFloat(parseInt(cat.qty) * parseFloat(p.bp))).toFixed(2);
                                    cp = (parseFloat(cp) + parseFloat(parseInt(cat.qty) * parseFloat(p.yp))).toFixed(2);
                                }
                            }
                        });
            
                        bp = cp;
            
                        if (dp > 0) {
                            dp = dp === "" ? "0.00" : dp;
                            dc = parseFloat((parseFloat(tp) / 100) * dp).toFixed(2);
                            bp = parseFloat(parseFloat(tp) - parseFloat(dc)).toFixed(2);
                        } else if (dp === "" && tp !== bp) {
                            dc = parseFloat(parseFloat(tp) - parseFloat(bp)).toFixed(2);
                            dp = parseFloat((parseFloat(dc) / tp) * 100).toFixed(2);
                        }
    
                        if(res.data.ty === 'Fixed Kit') {
                            bp = parseFloat(res.data.fp).toFixed(2);
                            dc = parseFloat(parseFloat(tp) - parseFloat(bp)).toFixed(2);
                            dp = parseFloat((parseFloat(dc) / tp) * 100).toFixed(2);
                        }
            
                        mr = parseFloat(((parseFloat(bp) - parseFloat(co)) / parseFloat(bp)) * 100).toFixed(2);
            
                        newFinancial = {
                            ...newFinancial,
                            bp,
                            dc,
                            dp,
                            co,
                            tp,
                            cp,
                            mr,
                        };
                    }

                }

                let kitContents = [];
                if(res.data.ty === "Fixed Kit") {
                    kitContents = _.map(res.data.cats, (c) => (
                        _.assign({
                            ...c.p[0],
                            qty: c.qty,
                            cid: c.id
                        })
                    ))
                }

                let uiTextType = res.data.ty === "Fixed Kit" ? "Kit" : "Bundle";

                setState((state) => ({
                    ...state,
                    isConfigured,
                    isLoading: false,
                    data: res.data,
                    formData: newFormData,
                    financial: newFinancial,
                    currentTp,
                    kitContents,
                    uiTextType
                }));
            }
        }); /* eslint-disable-next-line */
    }, []);

    const { data, financial, formData, isConfigured, isLoading, currentTp, kitContents, uiTextType } = state;

    return (
        (isLoading && (
            <Box pt={10} pb={10}>
                <LoadingCircle />
            </Box>
        )) || (
            <Grid container spacing={3} className="pt-1">
                <Grid item xs={12} lg={8} xl={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box style={{ maxHeight: 600, overflowY: "auto", overflowX: "hidden" }} p={2} pt={1} pl={0} pr={0}>
                                <Box mr={2}>
                                    <Grid container spacing={3}>
                                        {bundleData && !isConfigured && addBundleToForm && (
                                            <Grid item xs={12}>
                                                <Alert severity="error">Some items are no longer available, please re-select affected categories</Alert>
                                            </Grid>
                                        )}
                                        {currentTp && (
                                            <Grid item xs={12}>
                                                <Alert
                                                    action={
                                                        addBundleToForm ? (
                                                            <>
                                                                <Button onClick={() => updateBundlePricing(true)} color="inherit" size="small">
                                                                    <FAIcon icon="check" size={15} button style={{ color: "inherit" }} />
                                                                    Apply Price Changes
                                                                </Button>
                                                                <Button onClick={() => updateBundlePricing(false)} color="inherit" size="small" className="ml-1">
                                                                    <FAIcon icon="times" size={15} button style={{ color: "inherit" }} />
                                                                    Dismiss
                                                                </Button>
                                                            </>
                                                        ) : null
                                                    }
                                                    severity={addBundleToForm ? "error" : "warning"}
                                                >
                                                    The trade price has changed since the bundle was created to {currencyFormat.format(currentTp)}
                                                </Alert>
                                            </Grid>
                                        )}
                                        {(data.ty === "Optional Bundle" && (
                                            _.map(data.cats, (c, idx) => (
                                                <Grid item xs={12} key={idx}>
                                                    <Typography variant="body1" className="fw-400">
                                                        {c.text}
                                                        {c.req === 0 ? " (Optional)" : ""} x {c.qty}
                                                    </Typography>
                                                    <DataTable
                                                        config={{
                                                            key: "id",
                                                            pagination: false,
                                                            alternatingRowColours: true,
                                                            noResultsText: "No products for this category",
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: "Selected",
                                                                field: (rowData) =>
                                                                    (c.req === 1 && (
                                                                        <Radio
                                                                            color="primary"
                                                                            checked={formData?.[c.id] === rowData.id}
                                                                            disabled={!addBundleToForm && formData?.[c.id] !== rowData.id}
                                                                            onChange={() => handleBundleSelectionChange(c.id, rowData.id)}
                                                                        />
                                                                    )) || (
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={formData?.[c.id] === rowData.id}
                                                                            disabled={!addBundleToForm && formData?.[c.id] !== rowData.id}
                                                                            onChange={() => handleBundleSelectionChange(c.id, formData?.[c.id] === rowData.id ? "" : rowData.id)}
                                                                        />
                                                                    ),
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Product",
                                                                field: (rowData) => <Avatar variant="square" alt={rowData.nm} src={(rowData.im && `${CLOUDFRONT_URL}${rowData.im}`) || logo} />,
                                                                sizeToContent: true,
                                                                important: true,
                                                            },
                                                            {
                                                                heading: "",
                                                                field: (rowData) => (
                                                                    <>
                                                                        <Typography variant="body2" className="fw-400">
                                                                            {rowData.dc}
                                                                        </Typography>
                                                                        <Typography variant="caption">
                                                                            {rowData.nm}
                                                                        </Typography>
                                                                        {rowData.nd === 1 && (
                                                                            <Box pt={0.5} pb={0.5}>
                                                                                <IconText icon="exclamation-circle" iconClass="textError" textVariant="caption" textClass="fw-400">
                                                                                    This product should not be discounted
                                                                                </IconText>
                                                                            </Box>
                                                                        )}
                                                                        <Box pt={1} pb={0.5}>
                                                                            {rowData.sp === 0 && (
                                                                                <ProductStockChip
                                                                                    status={rowData.sa === 0 && rowData.oo === 0 ? `OOS` : rowData.sa === 0 && rowData.oo > 0 ? "PRE" : "IS"}
                                                                                    basedOnStatus
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    </>
                                                                ),
                                                                truncate: true,
                                                            },
                                                            {
                                                                heading: "Trade Price",
                                                                field: (rowData) => {
                                                                    const product =
                                                                        bundleData && currentTp
                                                                            ? _.find(bundleData.p, (el) => parseInt(el?.c?.id) === parseInt(c.id) && parseFloat(el?.p?.id) === parseInt(rowData.id))
                                                                            : null;
                                                                    return (
                                                                        (product &&
                                                                            ((product?.p?.tp === rowData.tp && currencyFormat.format(rowData.tp)) ||
                                                                                (product?.p?.tp && (
                                                                                    <>
                                                                                        <Typography variant="body2" className="textError">
                                                                                            {currencyFormat.format(product?.p?.tp)}
                                                                                        </Typography>
                                                                                        <Typography variant="caption" component="div">
                                                                                            Now: {currencyFormat.format(rowData.tp)}
                                                                                        </Typography>
                                                                                    </>
                                                                                )) ||
                                                                                currencyFormat.format(rowData.tp))) ||
                                                                        currencyFormat.format(rowData.tp)
                                                                    );
                                                                },
                                                                sizeToContent: true,
                                                                alignment: "right",
                                                            },
                                                            {
                                                                heading: "Customer Price",
                                                                field: (rowData) => rowData.yp,
                                                                fieldFormat: "currency",
                                                                sizeToContent: true,
                                                                alignment: "right",
                                                            },
                                                            {
                                                                heading: "Available",
                                                                field: (rowData) => (rowData.sp === 1 ? `-` : rowData.sa),
                                                                alignment: "right",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "On Order",
                                                                field: (rowData) => (rowData.sp === 1 ? `-` : rowData.oo),
                                                                alignment: "right",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Bin Location",
                                                                field: (rowData) => (rowData.sp === 1 ? `-` : rowData.wb),
                                                                sizeToContent: true,
                                                            },
                                                        ]}
                                                        rows={c.p}
                                                    />
                                                </Grid>
                                            ))
                                        )) || (
                                            <Grid item xs={12}>
                                                <DataTable
                                                    config={{
                                                        key: "id",
                                                        pagination: false,
                                                        alternatingRowColours: true,
                                                        noResultsText: "No products for this kit",
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: "Quantity",
                                                            field: (rowData) => rowData.qty,
                                                            cellProps: () => ({
                                                                className: 'fw-400'
                                                            }),
                                                            sizeToContent: true,
                                                            alignment: "center",
                                                        },
                                                        {
                                                            heading: "Product",
                                                            field: (rowData) => <Avatar variant="square" alt={rowData.nm} src={(rowData.im && `${CLOUDFRONT_URL}${rowData.im}`) || logo} />,
                                                            sizeToContent: true,
                                                            important: true,
                                                        },
                                                        {
                                                            heading: "",
                                                            field: (rowData) => (
                                                                <>
                                                                    <Typography variant="body2" className="fw-400">
                                                                        {rowData.dc}
                                                                    </Typography>
                                                                    <Typography variant="caption">{rowData.nm}</Typography>
                                                                    <Box pt={1} pb={0.5}>
                                                                        {rowData.sp === 0 && (
                                                                            <ProductStockChip
                                                                                status={rowData.sa === 0 && rowData.oo === 0 ? `OOS` : rowData.sa === 0 && rowData.oo > 0 ? "PRE" : "IS"}
                                                                                basedOnStatus
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </>
                                                            ),
                                                            truncate: true,
                                                        },
                                                        {
                                                            heading: "Trade Price",
                                                            field: (rowData) => {
                                                                const product =
                                                                    bundleData && currentTp
                                                                        ? _.find(bundleData.p, (el) => parseInt(el?.c?.id) === parseInt(rowData.cid) && parseFloat(el?.p?.id) === parseInt(rowData.id))
                                                                        : null;
                                                                return (
                                                                    (product &&
                                                                        ((product?.p?.tp === rowData.tp && currencyFormat.format(rowData.tp)) ||
                                                                            (product?.p?.tp && (
                                                                                <>
                                                                                    <Typography variant="body2" className="textError">
                                                                                        {currencyFormat.format(product?.p?.tp)}
                                                                                    </Typography>
                                                                                    <Typography variant="caption" component="div">
                                                                                        Now: {currencyFormat.format(rowData.tp)}
                                                                                    </Typography>
                                                                                </>
                                                                            )) ||
                                                                            currencyFormat.format(rowData.tp))) ||
                                                                    currencyFormat.format(rowData.tp)
                                                                );
                                                            },
                                                            sizeToContent: true,
                                                            alignment: "right",
                                                        },
                                                        {
                                                            heading: "Customer Price",
                                                            field: (rowData) => rowData.yp,
                                                            fieldFormat: "currency",
                                                            sizeToContent: true,
                                                            alignment: "right",
                                                        },
                                                        {
                                                            heading: "Available",
                                                            field: (rowData) => (rowData.sp === 1 ? `-` : rowData.sa),
                                                            alignment: "right",
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: "On Order",
                                                            field: (rowData) => (rowData.sp === 1 ? `-` : rowData.oo),
                                                            alignment: "right",
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: "Bin Location",
                                                            field: (rowData) => (rowData.sp === 1 ? `-` : rowData.wb),
                                                            sizeToContent: true,
                                                        },
                                                    ]}
                                                    rows={kitContents}
                                                />
                                            </Grid>
                                        )}                                        
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        {isConfigured && (
                            <Grid item xs={12}>
                                <Box className="content-light-grey" p={3}>
                                    <Grid container spacing={3}>
                                        <Grid item style={{ width: "16.6%" }}>
                                            <TextField
                                                fullWidth
                                                label="Buy Price"
                                                value={financial.co}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item style={{ width: "16.6%" }}>
                                            <TextField
                                                fullWidth
                                                label="Trade Price"
                                                value={financial.tp}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item style={{ width: "16.6%" }}>
                                            <TextField
                                                fullWidth
                                                name="dp"
                                                label="Discount"
                                                value={financial.dp}
                                                error={financial.dp > 100}
                                                type="number"
                                                onChange={handleFinancialChange}
                                                onBlur={handleFinancialFloat}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                                inputProp={{
                                                    min: 0,
                                                    max: 100,
                                                    step: 0.5,
                                                }}
                                                className="mb-0"
                                                disabled={!addBundleToForm || data.ty === 'Fixed Kit'}
                                            />
                                        </Grid>
                                        <Grid item style={{ width: "16.6%" }}>
                                            <TextField
                                                fullWidth
                                                name="dc"
                                                label="Discount"
                                                value={financial.dc}
                                                type="number"
                                                onChange={handleFinancialChange}
                                                onBlur={handleFinancialFloat}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                                disabled={!addBundleToForm || data.ty === 'Fixed Kit'}
                                            />
                                        </Grid>
                                        <Grid item style={{ width: "16.6%" }}>
                                            <TextField
                                                fullWidth
                                                name="bp"
                                                label="Bundle Price"
                                                value={financial.bp}
                                                onChange={handleFinancialChange}
                                                onBlur={handleFinancialFloat}
                                                type="number"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                                disabled={!addBundleToForm || data.ty === 'Fixed Kit'}
                                            />
                                        </Grid>
                                        <Grid item style={{ width: "16.6%" }}>
                                            <TextField
                                                fullWidth
                                                label="Margin"
                                                value={financial.mr}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                    <Box style={{ maxHeight: 750, overflowY: "auto", overflowX: "hidden" }} p={2} pt={1} pl={0} pr={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="fw-400" paragraph>
                                    {uiTextType} Contents
                                </Typography>
                                {_.map(data.cats, (c, idx) => {
                                    const product = _.find(c.p, (el) => el.id === formData?.[c.id]);
                                    return (
                                        (product && (
                                            <Box key={idx} mb={2}>
                                                <Typography variant="body2" className={`fw-400`}>
                                                    {c.qty} x {c.text} {c.req === 0 ? " (Optional)" : ""}
                                                </Typography>
                                                {data.ty === "Option Kit" && (
                                                    <Typography variant="caption" component="div" className="fw-400">
                                                        {product.dc}
                                                    </Typography>
                                                )}
                                                <Typography variant="caption" className="fw-300">
                                                    {product.nm}
                                                </Typography>
                                            </Box>
                                        )) || (
                                            <Box key={idx} mb={1.5}>
                                                <Typography variant="body2" className={`fw-400 ${c.req === 1 ? `textError` : ``}`}>
                                                    {c.qty} x {c.text} {c.req === 0 ? " (Optional)" : ""}
                                                </Typography>
                                                <Typography variant="caption" className={`fw-300 ${c.req === 1 ? `textError` : ``}`}>
                                                    Not Selected {c.req === 1 ? " (Required)" : ""}
                                                </Typography>
                                            </Box>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid item xs={12}>
                                <Box pb={0.5}>
                                    <Divider />
                                </Box>
                            </Grid>
                            {isConfigured && (
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {currentTp && (
                                            <Grid item xs={12} align="right">
                                                <Typography variant="body2" className="fw-400">
                                                    New Trade Price: <span className="fw-300">{currencyFormat.format(currentTp)}</span>
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} align="right">
                                            <Typography variant="body2" className="fw-400">
                                                {currentTp ? `Existing ` : ``}Trade Price: <span className="fw-300">{currencyFormat.format(financial.tp)}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} align="right">
                                            <Typography variant="body2" className="fw-400">
                                                Customer Price: <span className="fw-300">{currencyFormat.format(financial.cp)}</span>
                                            </Typography>
                                        </Grid>
                                        {financial.bp !== "" && (
                                            <>
                                                <Grid item xs={12} align="right">
                                                    <Typography variant="h6" className="fw-400">
                                                        {uiTextType} Price: <span className="fw-300">{financial.bp !== "" && currencyFormat.format(financial.bp)}</span>
                                                    </Typography>
                                                </Grid>
                                                {(parseFloat(financial.bp) > parseFloat(financial.cp) && uiTextType === "Optional Bundle" && (
                                                    <Grid item xs={12}>
                                                        <Box py={0.5}>
                                                            <Alert severity="warning">
                                                                <Typography variant="caption">Bundle price higher than customer price!</Typography>
                                                            </Alert>
                                                        </Box>
                                                    </Grid>
                                                )) || (
                                                    <Grid item xs={12} align="right">
                                                        <Typography variant="body2" className="fw-400 textError">
                                                            Savings:{" "}
                                                            <span className="fw-300">{financial.dc !== "" && `${currencyFormat.format(financial.dc)} (${parseFloat(financial.dp).toFixed(2)}%)`}</span>
                                                            {currentTp && <IconText>This is based on the existing trade price</IconText>}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {addBundleToForm && (
                                <Grid item xs={12}>
                                    <Button
                                        className={`btn ${isConfigured && financial.bp !== "" ? `btn-success` : `btn-disabled`}`}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to ${bundleData ? `modify` : `add`} this ${uiTextType.toLowerCase()}?`, handleAddBundleToForm))}
                                        size="large"
                                        disabled={!isConfigured || financial.bp === ""}
                                    >
                                        <FAIcon icon="check" disabled={!isConfigured || financial.bp === ""} size={15} buttonPrimary />
                                        {bundleData ? `Modify` : `Add`} {uiTextType}
                                    </Button>
                                </Grid>
                            )}
                            {handleProductAdd && handleProductRemove && bundleData && uiTextType === "Optional Bundle" && (
                                <Grid item xs={12}>
                                    <Button
                                        className={`btn ${isConfigured && financial.bp !== "" ? `btn-warning` : `btn-disabled`}`}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to de-bundle these products?`, handleDeBundleToForm))}
                                        size="large"
                                        disabled={!isConfigured || financial.bp === ""}
                                    >
                                        <FAIcon icon="check" disabled={!isConfigured || financial.bp === ""} size={15} button />
                                        De-bundle Items
                                    </Button>
                                </Grid>
                            )}
                            {(addProductToForm && (
                                <Grid item xs={12}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        fullWidth
                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to add only the product?`, handleAddProductToForm))}
                                        size="large"
                                    >
                                        <FAIcon icon="basket-shopping" size={15} button />
                                        Standalone Purchase
                                    </Button>
                                </Grid>
                            )) ||
                                (addBundleToForm && (
                                    <Grid item xs={12}>
                                        <Button variant="text" color="primary" fullWidth onClick={() => dispatch(closeDialog())} size="large">
                                            <FAIcon icon="times" size={15} button />
                                            Cancel
                                        </Button>
                                    </Grid>
                                ))}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    );
};

export default ConfigureProductBundle;
