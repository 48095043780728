import React from 'react';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import ColorAvatar from 'Components/Common/Avatars/Color';
import { toggleDialog } from 'Functions/MiscFunctions';

const initialState = {
    access: {
        viewTeam: false,
    },
    isLoading: true,
    teams: [],
    searchString: ''
}

class TeamSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState; 
        this.toggleDialog = toggleDialog.bind(this)
        this.timeout = false
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Teams']);
        this.checkAccess();
        this.getTeams(true);
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    checkAccess = () => {
        Promise.all([
            API.access('add-team'),
            API.access('view-team'),
        ])
        .then(([addRes, viewRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    viewTeam: (viewRes.data && viewRes.data.has_access) || false
                }
            });
        })
    }

    getTeams = isLoading => {
        this.setState({
            isLoading
        }, () => {
            API.get('/teams', { 
                props: {
                    cancellation: true
                },
                params: { 
                    searchString: this.state.searchString 
                } 
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        ...this.state,
                        teams: result.data,
                        isLoading: false
                    });
                }
            })
        })
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getTeams(true);
        });
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getTeams(true), 250);
    }

    render() {
        const { access, isLoading } = this.state;
        return (
            <>
                <Grid container spacing={1}>      
                    <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={12} sm={5} md={4} xl={3} style={{marginBottom: 12}}>
                            <TextField 
                                fullWidth
                                onChange={this.onSearchStringChange}
                                placeholder='Search: '
                                value={this.state.searchString} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="thin" icon="search" size="small" /></InputAdornment>
                                }}
                            /> 
                        </Grid>      
                    </Grid>           
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'team_id',
                                    pagination: true,
                                    isLoading: isLoading,
                                    alternatingRowColours: true,
                                    responsiveImportance: true,
                                    options: {
                                        defaultSort: 'team_name',
                                        dataRef: true,
                                        reset: this.resetSearch,
                                        export: {
                                            title: `Teams`,
                                            name: `Teams`,
                                            excel: true,
                                            pdf: true,
                                            print: true
                                        }
                                    }
                                }}
                                columns={[  
                                    {
                                        field: rowData => <ColorAvatar alt={rowData.team_name} size="sm" color={rowData.team_color} />,
                                        sizeToContent: true
                                    },     
                                    {
                                        heading: 'Team',
                                        field: rowData => `${rowData.team_name} Team`,
                                        dataRef: 'team_name',
                                        main: true
                                    },     
                                    {
                                        heading: 'E-mail Address',
                                        field: rowData => rowData.team_email,
                                        dataRef: 'team_email',
                                        main: true
                                    },                             
                                    {
                                        heading: 'Leaders',
                                        field: rowData => rowData.leaders_count,
                                        dataRef: 'leaders_count',
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Members',
                                        field: rowData => rowData.members_count,
                                        dataRef: 'members_count',
                                        alignment: 'center'
                                    },    
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'View', icon: 'binoculars',  link: '/teams/' + rowData.team_id, disabled: !access.viewTeam},
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.teams}
                            />
                        </Paper>
                    </Grid>               
                </Grid>
            </>
        );
    }
}

export default TeamSearch;