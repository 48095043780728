import decode from 'jwt-decode';
import * as authActionTypes from 'Redux/Actions/Auth/Types/Types';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { loadStaffConfig, loadStaffStatus, loadTeams } from 'Redux/Actions/Notifications/Notifications';
import API from 'API';

export function authStaff(token) {
    return (dispatch) => {

        dispatch(deploySnackBar("success", ""))

        API.get('/staff/my/access')
        .then(result => {
            if(result?.data){
                
                dispatch(loadStaffConfig())
                dispatch(loadStaffStatus())
                dispatch(loadTeams())
                const user = decode(token);

                dispatch({
                    type: authActionTypes.EXTRACT_TOKEN,
                    payload: {
                        token: token,
                        exp: user.exp,
                        id: user.id,
                        email: user.email,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        name: `${user.firstName} ${user.lastName}`,
                        picture: user.picture,
                        teams: user.teams
                    }
                })

                dispatch({
                    type: authActionTypes.ACCESS,
                    payload: result.data
                })

            }
        })
    }
}

export function refreshAccess() {
    return dispatch => {
        API.get('/staff/my/access')
        .then(result => {
            if (result) {
                dispatch({
                    type: authActionTypes.ACCESS,
                    payload: result.data
                })
            }
        })
    }
}

export function updateProfilePhoto(payload) {
    return (dispatch) => {
        dispatch({
            type: authActionTypes.UPDATE_PICTURE,
            payload
        })
    }
}